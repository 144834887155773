/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import React, { useEffect } from "react";
import { FaCogs, FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHttp } from "../../../../../../hooks/http";
import {
  actionVoucher,
  searchSelector,
} from "../../../../../../redux/slices/voucher";
import { axios, getToken, moment, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import ImageItem from "../../components/ImageItem";

const limit = 10;

const Index = ({ match, history }) => {
  const reduxDispatch = useDispatch();
  const { setSearch, clear } = actionVoucher;
  const search = useSelector(searchSelector);
  const { query, page, status } = search;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/setting/master/voucher`,
      token: true,
      params: {
        query: query,
        limit,
        offset: (page - 1) * limit,
        status,
      },
    },
    [page, status, query]
  );

  useEffect(() => {
    return () =>
      !_.split(history.location.pathname, "/setting/master/voucher/")[1] &&
      reduxDispatch(clear());
  }, []);

  if (error) return <Alert type="error" message={error} />;

  const handleChangeStatus = (value, id, name) => {
    const source = axios.CancelToken.source();
    const action_text = value ? "เปิด" : "ปิด";
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: `คุณต้องการ${action_text}ใช้งานบัตรกำนัล ${name} หรือไม่`,
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            baseURL: URL_API,
            url: `/setting/master/change-status`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              id,
              status: value ? "active" : "inactive",
              type: "voucher",
            },
            cancelToken: source.token,
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: `${action_text}การใช้งานบัตรกำนัลเรียบร้อย`,
              });
              reload();
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {
        source.cancel();
      },
    });
  };

  return (
    <Panel>
      <PanelHeader>บัตรกำนัล</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col md={20}>
            <Input.Search
              allowClear
              placeholder="รหัสบัตรกำนัล, ชื่อบัตรกำนัล"
              onChange={(e) =>
                reduxDispatch(setSearch({ query: e.target.value, page: 1 }))
              }
            />
          </Col>
          <Col md={4}>
            <Select
              className="w-100"
              value={status}
              onChange={(value) => {
                reduxDispatch(setSearch({ status: value, page: 1 }));
              }}
            >
              <Select.Option value="active">ใช้งาน</Select.Option>
              <Select.Option value="inactive">ไม่ใช้งาน</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={16} className="mb-2">
          <Col>
            <Link to={`${match.path}/0`}>
              <Button type="primary">เพิ่มบัตรกำนัล</Button>
            </Link>
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={data ? data.data : []}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            total: data?.total,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showLessItems: true,
            onChange: (currPage) =>
              reduxDispatch(setSearch({ page: currPage })),
          }}
        >
          <Table.Column
            key="row_id"
            dataIndex="row_id"
            title="#"
            align="center"
          />
          <Table.Column
            key="azure_url"
            dataIndex="azure_url"
            title="รูป"
            align="center"
            width={150}
            render={(text) => <ImageItem azureUrl={text} />}
          />
          <Table.Column title="รหัสบัตรกำนัล" dataIndex="code" align="center" />
          <Table.Column
            key="name"
            dataIndex="name"
            className="position-relative min-width-150 max-width-600"
            title={
              <div className="ant-table-absolute-title-center">
                ชื่อบัตรกำนัล
              </div>
            }
          />
          <Table.Column
            key="voucher_type_name"
            dataIndex="voucher_type_name"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">ประเภท</div>
            }
          />
          <Table.Column
            key="create_date"
            dataIndex="create_date"
            title="วันที่สร้าง"
            align="center"
            render={(text) =>
              text ? moment(text).format("DD/MM/YYYY HH:mm:ss") : text
            }
          />
          {/* <Table.Column
            key="start_date"
            dataIndex="start_date"
            title="วันที่เริ่มต้น"
            align="center"
            render={(text) =>
              text ? moment(text).format("DD/MM/YYYY HH:mm:ss") : text
            }
          /> */}
          <Table.Column
            key="end_date"
            dataIndex="end_date"
            title="วันที่สิ้นสุด"
            align="center"
            render={(text) =>
              text ? moment(text).format("DD/MM/YYYY HH:mm:ss") : "ตลอดไป"
            }
          />
          <Table.Column
            key="status"
            dataIndex="status"
            title="สถานะ"
            align="center"
            render={(text, { id, name }) => (
              <Switch
                checked={text?.toLowerCase() === "active"}
                onChange={(e) => handleChangeStatus(e, id, name)}
              />
            )}
          />
          <Table.Column
            key="action"
            title={<FaCogs />}
            align="center"
            render={({ id }) => (
              <Link to={`${match.path}/${id}`}>
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-pv border-pv"
                >
                  <FaPencilAlt />
                </Button>
              </Link>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};
export default Index;
