/* eslint-disable no-unused-vars */
import { Col, Input, Row } from "antd";
import React from "react";
import TableRemark from "../Table/TableRemark";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";

export default function RemarkPanel() {
  
  const dispatch = useDispatch();
  const remark = useSelector((state) => state.ethics.remark, shallowEqual);

  const { SET_REMARK } = actionEthics;
  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col md={24}>
          <Input placeholder="ระบุหมายเหตุ" onChange={(e) => {dispatch(SET_REMARK(e.target.value))}}/>
        </Col>
      </Row>
      <TableRemark />
    </>
  );
}
