/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import Layout from '../components/Layout';
import ControlPanel from '../components/ControlPanel';
import numeral from 'numeral';
import _ from 'lodash';
import { useHttp } from '../../../../hooks/http';

export default () => {
    const teamList = [
        'Clover'
        , 'Clover_Think'
        , 'Dreams'
        , 'Ever'
        , 'Givers'
        , 'Life'
        , 'Mind'
        , 'Real'
        , 'Together'
        , 'Victors'
        , 'Zeeds'
    ];
    const branchList = [
        'สาทร'
        , 'Jas ศรีนครินทร์'
        , 'รังสิต'
        , 'บางกะปิ'
        , 'เชียงใหม่'
        , 'นครสวรรค์'
        , 'ขอนแก่น'
        , 'ศรีสะเกษ'
        , 'ชลบุรี'
        , 'ระยอง'
        , 'นครปฐม'
        , 'เพชรบุรี'
        , 'นครศรีธรรมราช'
        , 'หาดใหญ่'
        , 'ยะลา'
        , 'ออนไลน์'
        , 'คอลเซ็นเตอร์'
        , 'Line Order'
        , 'My Mart'
        , 'เคลื่อนที่ 2'
    ];

    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [loading, data, error] = useHttp({
        method: 'post',
        // url: `${API_URL}/boss/reports/team/sale`,
        data: { dataSource }
    }, [dataSource]);

    const rederSource = () => {
        let newData = [];

        newData = branchList.map(m => {
            let obj = {};

            obj.key = _.uniqueId('branch-');
            obj.branch = m;
            teamList.forEach(n => {
                obj[n] = Math.floor(Math.random() * 10000) * 1000;
            })

            return obj;
        })

        let total = {};
        teamList.forEach(m => total[m] = _.reduce(newData, (sum, n) => sum + n[m], 0));

        newData.push({
            key: _.uniqueId('branch-'),
            branch: 'Total',
            ...total
        })

        setDataSource(newData);
    }

    const grandTotal = record => {
        let sum = 0;
        for (let key in _.omit(record, ['key', 'branch'])) {
            if (record.hasOwnProperty(key)) {
                sum += parseFloat(record[key]);
            }
        }
        return sum;
    }

    useEffect(() => {
        let newColumns = [];

        newColumns.push({
            title: 'Branch',
            className: 'text-center text-nowrap',
            dataIndex: 'branch',
            key: 'branch',
            render: text => <p className="m-0 text-left">{text}</p>
        });

        teamList.forEach(n => {
            newColumns.push({
                title: n,
                className: 'text-center text-nowrap',
                dataIndex: n,
                key: n,
                render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
            });
        })

        newColumns.push({
            title: 'Grand Total',
            className: 'text-center text-nowrap',
            key: 'grand_total',
            render: (text, record) => <p className="m-0 text-right">{numeral(grandTotal(record)).format('0,0')}</p>
        });

        setColumns(newColumns);

        if (dataSource.length === 0) {
            rederSource();
        }

        console.log(loading, data, error)
    }, []);

    return (
        <Layout
            // breadcrumb={breadcrumb}
            titlePage="รายงานยอดขายหน้าร้าน"
            subTitlePage=""
        >
            <ControlPanel titlePanel="รายงานยอดขายหน้าร้าน">
                {/* <Row>
                    <Col md={12}><Chart type="line" options={lineChart.options} series={lineChart.series} /></Col>
                    <Col md={12}>
                        <Chart type="pie" options={pieChart.options} series={pieChart.series} />
                    </Col>
                </Row> */}
                <Table
                    bordered
                    size="small"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: true }}
                />
            </ControlPanel>
        </Layout>

    )
}
