import React from "react";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import MainPage from "./components/main.page";
import { CommissionMutateProvider } from "./contexts/commission.context";

const CommissionPage = () => {
  return (
    <CommissionMutateProvider>
      <Panel>
        <PanelHeader>คอมมิชชั่น</PanelHeader>
        <PanelBody>
          <MainPage />
        </PanelBody>
      </Panel>
    </CommissionMutateProvider>
  );
};

export default CommissionPage;
