const classConstants = {
  category: {
    width: 300,
    height: 500,
    ratio: '1:1',
  },
  subCategory: {
    width: 600,
    height: 600,
    ratio: '1:1',
  },
  classThumbnail: {
    width: 600,
    height: 300,
  },
  tableSize: 10,
  tableSize2: 20,
};

export default classConstants;
