import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const legacyRewardEventFormDefaultValues = {
  imageUrl: '',
  title: '',
  description: '',
};

export const legacyRewardEventFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      imageUrl: yup.string().defined('กรุณากรอกข้อมูล'),
      title: yup.string().required('กรุณากรอกข้อมูล'),
      description: yup.string().defined('กรุณากรอกข้อมูล'),
    })
  );
};

export const legacyRewardEventListFormDefaultValues = {
  isJoinEvent: false,
  isRewardRight: false,
  rewardTitle: '',
  isGetReward: false,
};

export const legacyRewardEventListFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      isJoinEvent: yup.boolean().defined(),
      isRewardRight: yup.boolean().defined(),
      rewardTitle: yup.string().defined(),
      isGetReward: yup.boolean().defined(),
    })
  );
};
