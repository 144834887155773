import { Col, Typography } from "antd";
import React from "react";
import { _ } from "../../../../../../utils";

const { Text } = Typography;

const Amount = ({ data }) => (
  <Col span={24} className="d-flex align-items-center">
    <div className="width-60 height-60 d-flex justify-content-center align-items-center border rounded-lg">
      <Text strong>{data.source_key}</Text>
    </div>
    <Text className="ml-3">{`${
      _.includes(["price", "percent"], data.source_key)
        ? "ส่วนลด"
        : `คะแนน ${data.source_key.toUpperCase()} `
    }จำนวน ${data.qty} ${
      data.source_key === "price"
        ? "บาท"
        : data.source_key === "percent"
        ? "เปอร์เซ็นต์"
        : "คะแนน"
    }`}</Text>
  </Col>
);

export default Amount;
