import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import ClassCategoryForm from '../form/ClassCategoryForm';
import { classCategoryFormDefaultValues } from '../dto/class';
import { useQueryClient } from '@tanstack/react-query';

const ClassCategoryModal = ({ open, handleClose }) => {
  const { watch, handleSubmit, reset } = useFormContext();
  const queryClient = useQueryClient();

  const { execute: createClassCategory } = useAsync(
    fitStudioService.createClassCategory,
    {
      onSuccess: () => {
        AlertSuccess('เพิ่มหมวดหมู่คลาสเรียนสำเร็จ');
        reset(classCategoryFormDefaultValues);
        handleClose();
        queryClient.invalidateQueries({
          queryKey: ['get-class-category'],
          refetchType: 'all',
        });
      },
    }
  );

  const { execute: editClassCategory } = useAsync(
    fitStudioService.editClassCategory,
    {
      onSuccess: () => {
        AlertSuccess('แก้ไขหมวดหมู่คลาสเรียนสำเร็จ');
        reset(classCategoryFormDefaultValues);
        handleClose();
        queryClient.invalidateQueries({
          queryKey: ['get-class-category'],
          refetchType: 'all',
        });
      },
    }
  );

  const _HandleSubmit = handleSubmit((data) => {
    const classCategoryData = {
      title: data.title,
      thumbnailImageUrl: data.thumbnailImageUrl,
    };
    if (watch('formType') === 'create') {
      createClassCategory(classCategoryData);
    } else if (watch('formType') === 'edit') {
      editClassCategory({ id: data.id, data: classCategoryData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'สร้างหมวดหมู่คลาสเรียน'
          : 'แก้ไขหมวดหมู่คลาสเรียน'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <ClassCategoryForm />
    </Modal>
  );
};

export default ClassCategoryModal;
