import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { _ } from "../../../../../utils";

const BomDetail = ({ list = [], qty = 1 }) => {
  const [expand, setExpand] = useState(true);
  if (!list.length) return;
  return (
    <>
      <p className="m-0 pl-3">
        <span
          className="cursor-pointer"
          onClick={() => setExpand(expand ? false : true)}
        >
          ประกอบด้วย {expand ? <FaAngleUp /> : <FaAngleDown />}
        </span>
      </p>
      {expand &&
        _.map(list, (n, i) => (
          <p className="m-0 pl-3" key={n.product_code}>
            {`${i + 1}.  ${n.product_name} (${n.qty * qty})`}
          </p>
        ))}
    </>
  );
};

export default React.memo(BomDetail);
