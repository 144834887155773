import { Input, Typography } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaPen, FaSave } from 'react-icons/fa';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { registerPapersUpdateNoteFormSchema } from '../dto/register-paper';

const RegisterPaperNote = ({ paper, getPapers }) => {
  const [edit, setEdit] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      note: paper.note,
    },
    resolver: registerPapersUpdateNoteFormSchema(),
  });

  const { execute: updateRegisterPaperNote } = useAsync(
    fitStudioService.updateRegisterPaperNote,
    {
      onSuccess: () => {
        setEdit(false);
        getPapers();
      },
    }
  );

  const _HandleSave = handleSubmit((data) => {
    updateRegisterPaperNote({
      id: paper.id,
      note: data.note,
    });
  });

  if (edit) {
    return (
      <Controller
        name="note"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input value={value} onChange={onChange} />
                <div
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  onClick={_HandleSave}
                >
                  <FaSave />
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </div>
          );
        }}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography.Text style={{ minWidth: 50 }}>{paper.note}</Typography.Text>
      <div
        style={{ cursor: 'pointer', marginLeft: 10 }}
        onClick={() => {
          setEdit(true);
        }}
      >
        <FaPen />
      </div>
    </div>
  );
};

export default RegisterPaperNote;
