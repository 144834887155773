import React from "react";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import Criteria from "./components/Criteria";
import TableBanner from "./components/TableBanner";

const BannerList = ({ history, match }) => {
  return (
    <Panel>
      <PanelHeader>จัดการแบนเนอร์</PanelHeader>
      <PanelBody>
        <Criteria />
        <TableBanner />
      </PanelBody>
    </Panel>
  );
};

export default BannerList;
