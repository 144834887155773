/* eslint-disable no-unused-vars */
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";
import SelectOption from "../../pos/components/SelectOption";

const SelectYear = ({
  withAll = false,
  yearFrom = 2014,
  previousYear,
  ...props
}) => {
  const [yearFilter, setYearFilter] = useState(moment().format("YYYY"));

  const [yearLoading, yearData, yearError, yearReload] = useHttp(
    {
      url: `${URL_API}/boss/report-component/get-year`,
      token: true,
      params: { yearFrom: yearFrom },
    },
    []
  );

  useEffect(() => {
    setYearFilter(yearData ? yearData.data : moment().format("YYYY"));
  }, [yearData]);

  const yearOptions = [
    ..._.map(yearFilter, (year) => {
      return { value: year.period_year, text: year.period_year };
    }),
  ];

  return (
    <SelectOption
      withAll={withAll}
      className="w-100"
      options={yearOptions}
      {...props}
    />
  );
};

export default SelectYear;
