import React, { useState, useEffect } from 'react'
import SelectOption from '../components/SelectOption'
import { useHttp } from '../../../../hooks/http';
import { URL_API } from '../../../../utils';

const SelectBuyerOther = ({ ...props }) => {
    const [options, setOptions] = useState([]);
    const [loading, data, error] = useHttp({
        url: `${URL_API}/pos/master/buyer_other`
    });

    useEffect(() => {
        let newData = [];

        if (data && data.data) {
            newData = data.data.map(n => ({ key: n.id, value: n.code, text: n.name }));
        }

        setOptions(newData);
    }, [data])

    if (error) return <div>{error}</div>

    return (
        <SelectOption
            loading={loading}
            options={options}
            {...props}
        />
    )
}

export default SelectBuyerOther;
