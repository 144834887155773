/* eslint-disable no-unused-vars */
import React from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Table } from 'antd';
import usePaginationHook from '../../../../utils/usePagination';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import fitQuizServices from '../../../../services/fit-quiz.services';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { FiArrowLeft } from 'react-icons/fi';

dayjs.locale('th');

const pageSize = 10;

const LegacyQuizCategoryProductQuestionAnswerPage = () => {
  const params = useParams();
  const history = useHistory();

  const {
    data: legacyQuizCategoryProductQuestionAnswerLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();

  fitQuizServices.useQueryGetLegacyQuizCategoryProductQuestionById(
    { take: pageSize, page, quizCategoryProductQuestionId: params.questionId },
    (data) => {
      console.log(data);
      setData(data.answers);
      setTotal(data.meta.total);
    }
  );

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'ภาพประกอบคำตอบ',
      dataIndex: 'iconImageUrl',
      key: 'iconImageUrl',
      render: (_, record) => (
        <div>
          {record.iconImageUrl && (
            <img
              alt={record.title}
              src={record.iconImageUrl}
              style={{
                width: 50,
                height: 50,
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'คำตอบ',
      dataIndex: 'answer',
      key: 'answer',
      render: (_, record) => <span>{record.answer}</span>,
    },
    {
      title: 'ผลลัพธ์',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => (
        <span>{record.isCorrect ? 'ถูกต้อง' : 'ไม่ถูกต้อง'}</span>
      ),
    },
    {
      title: 'จำนวนทั้งหมด (ครั้ง)',
      dataIndex: 'userSelectAnswerCount',
      key: 'userSelectAnswerCount',
      render: (_, record) => <span>{record.userSelectAnswerCount || 0}</span>,
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
      </div>
      <Panel>
        <PanelHeader>คำตอบ</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            dataSource={legacyQuizCategoryProductQuestionAnswerLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {},
              };
            }}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default LegacyQuizCategoryProductQuestionAnswerPage;
