import React from 'react'

const Table = ({ columns = [], dataSource = [], className = "", ...props }) => {
    const renderData = () => {
        let tr = [];
        dataSource.forEach((n, i) => {
            tr.push(
                <tr className="bg-transparent" key={i}>
                    {
                        columns.map((m, j) => <td className="border-0 px-4" key={j}>{m.render ? m.render(n[m.dataIndex], n, i) : n[m.dataIndex]}</td>)
                    }
                    {/* <td className="border-0 px-4 pl-5 text-right">{i + 1}</td>
                    <td className="border-0 px-4 d-flex">
                        {n.sku} {n.product_name}
                        <span className="d-flex justify-content-center align-items-center bg-pv text-white rounded-circle p-5 ml-2">
                            <Icon type="eye" style={{ fontSize: '14px' }} />
                        </span>
                        <span className="d-flex justify-content-center align-items-center bg-gray-60 text-white rounded-circle p-5 ml-2">
                            <Icon type="qrcode" style={{ fontSize: '14px' }} />
                        </span>
                        <span className="d-flex justify-content-center align-items-center bg-red text-white rounded-circle p-5 ml-2">
                            <Icon type="notification" style={{ fontSize: '14px' }} />
                        </span>
                    </td>
                    <td className="border-0 px-4 text-right text-nowrap">{n.d_pv}</td>
                    <td className="border-0 px-4 text-right text-nowrap">{n.d_price}</td>
                    <td className="border-0 px-4 text-nowrap d-flex">
                        <ButtonIcon><Icon type="minus" /></ButtonIcon>
                        <InputNumber />
                        <ButtonIcon><Icon type="plus" /></ButtonIcon>
                    </td>
                    <td className="border-0 px-4 text-right text-nowrap">43,500</td>
                    <td className="border-0 px-4 pr-5 text-center"><Icon type="delete" style={{ fontSize: '16px' }} /></td> */}
                </tr>
            )
        })
        
        return tr;
    }

    return (
        <table className={`table table-sm m-0 ${className}`}>
            <thead>
                <tr className="bg-gray-60 text-white text-center">
                    {columns.map((n, i) => <th className={`font-weight-normal px-4 ${n.className}`} key={i}>{n.title}</th>)}
                </tr>
            </thead>
            <tbody>
                {renderData()}
            </tbody>
        </table>
    )
}

export default Table;
