import { Alert, Table } from "antd";
import React, { useContext } from "react";
import UserContext from "../../../../../../contexts/user.context";
import { useApi } from "../../../../../../hooks/http2";

const RoleList = () => {
    const { state, dispatch } = useContext(UserContext);
    const { loading, fetch, error } = useApi(
        {
            url: "/setting/permission/role",
            token: true,
        },
        []
    );

    if (error) {
        return (
            <Alert
                message="ผิดพลาด"
                type="error"
                showIcon
                description={error}
            />
        );
    }

    const handleChangeSelect = (selectedRowKeys) => {
        dispatch({
            type: "SET_FORM_VALUE",
            payload: { ...state.form, roles: selectedRowKeys },
        });
    };

    return (
        <Table
            loading={loading}
            dataSource={
                fetch?.data
                    ? fetch?.data?.map((n, i) => ({ ...n, key: i }))
                    : []
            }
            size="small"
            bordered
            pagination={false}
            rowKey="id"
            rowSelection={{
                selectedRowKeys: state.form.roles,
                onChange: handleChangeSelect,
            }}
        >
            <Table.Column title="บทบาท" dataIndex="name" />
        </Table>
    );
};

export default RoleList;
