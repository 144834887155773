/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectMasterType from "../../../../../components/SelectMasterType";
import ValidateError from "../../../../../components/ValidateError";
import { actionClaim } from "../../../../../redux/slices/claim";

const { Text } = Typography;

const { setFormValue } = actionClaim;

const ClaimType = ({ disabled }) => {
  const dispatch = useDispatch();
  const claim_type_text = useSelector(
    (state) => state.claim.documentDetail.claim_type_text
  );
  const claim_type = useSelector(
    (state) => state.claim.documentDetail.claim_type
  );
  const claim_type_error = useSelector((state) => state.claim.error.claim_type);

  return (
    <>
      <Row gutter={[8, 8]} className="my-3">
        <Col span={6} className="text-right">
          <Text strong>ประเภท</Text>
        </Col>
        <Col span={18}>
          {disabled ? (
            <Text>{claim_type_text}</Text>
          ) : (
            <div className={claim_type_error && "has-error"}>
              <SelectMasterType
                size="small"
                placeholder="เลือกประเภทการเคลม"
                processType="claim_type"
                value={claim_type || undefined}
                onChange={(claim_type) =>
                  dispatch(
                    setFormValue({
                      name: "documentDetail",
                      value: {
                        claim_type,
                      },
                      error_name: "claim_type",
                    })
                  )
                }
              />
            </div>
          )}
          <ValidateError error={claim_type_error} />
        </Col>
      </Row>
      {!!claim_type && (
        <Row gutter={[8, 8]} className="my-3">
          <Col span={6} className="text-right">
            <Text strong>เงื่อนไขข้อตกลง</Text>
          </Col>
          <Col span={18}>
            <Text>
              {claim_type === "satisfied"
                ? "ไม่สามารถซื้อสินค้าที่เคลมได้เป็นระยะเวลา 1 ปี บิลต้องไม่เกิน 30 วัน"
                : "สินค้าที่นำมาเคลมจะต้องไม่หมดอายุ"}
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
};

export default memo(ClaimType);
