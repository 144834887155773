/* eslint-disable no-unused-vars */
import { Breadcrumb } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import { useApi } from "../../../hooks/http2";
import { searchSelector } from "../../../redux/slices/blacklist";
import { URL_API } from "../../../utils/index";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import BlackListFilter from "./components/BlackListFilter";
import BlackListTable from "./components/BlackListTable";
export default function BlackList() {
  const [listData, setListData] = useState([]) 
  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>BlackList</Breadcrumb.Item>
      </Breadcrumb>
      <Panel>
        <PanelHeader>BlackList</PanelHeader>
        <PanelBody>
          <BlackListFilter dataSource={listData}  />
          <BlackListTable setListData={setListData} />
        </PanelBody>
      </Panel>
    </>
  );
}
