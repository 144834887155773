/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { classScheduleFormDefaultValues } from '../dto/class';

const ClassReservationForm = () => {
  const {
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    reset(classScheduleFormDefaultValues);
  }, []);

  return (
    <div>
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ชื่อ</Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="memberNo"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>รหัสสมาชิก</Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัสสมาชิก`"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="memberNo"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default ClassReservationForm;
