/* eslint-disable no-unused-vars */
import { Col, DatePicker, notification, Row, Table, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
// import XLSX from "xlsx";
import * as XLSX from "xlsx-js-style";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import { memberTypeOptions } from "../../components/memberTypeOptions";
const { Text } = Typography;

const user = getUser();

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const toDay = moment().format("DD/MM/YYYY");

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState("");
  const [sendDate, setSendDate] = useState(["", ""]);

  let limit = 20;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-check-document`,
      token: true,
      params: {
        lim: limit,
        offset: limit * (page - 1),
        userId: user.id,
        memberTypeFilter: memberTypeFilter,
        teamFilter: teamFilter,
        statusFilter: partnerStatusFilter,
        ...state.filter,
        submission_date_from: sendDate[0],
        submission_date_to: sendDate[1],
        review_date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        review_date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page]
  );

  console.log(data);

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-filter`,
      token: true,
    },
    []
  );

  const exportDataData = data ? [data?.data] : [];

  useEffect(() => {
    setDataSource(data ? data : []);
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);

  const partnerStatusOptions = [
    ..._.map(filterData?.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  const exportToExcel = () => {
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานการตรวจสอบเอกสาร"],
        [
          `ประเภทสมาชิก : ${
            memberTypeFilter === "" ? "ทั้งหมด" : memberTypeFilter
          }`,
          "",
          `สถานะสมาชิก : ${
            partnerStatusFilter === "" ? "ทั้งหมด" : partnerStatusFilter
          }`,
          "",
          `Team : ${teamFilter === "" ? "ทั้งหมด" : teamFilter}`,
          `วันที่ตรวจสอบเอกสาร :  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล : ${moment().format("DD/MM/YYYY , HH:mm:ss")}`,
        ],
        [],
        [
          "ลำดับที่",
          "ประเภทสมาชิก",
          "Team",
          "รหัสสมาชิก",
          "ชื่อสมาชิก",
          "เอกสาร",
          "วันที่ส่งเอกสาร",
          "ผู้ตรวจสอบ",
          "วันที่ตรวจสอบ",
          "การตรวจสอบ",
          "หมายเหตุ",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.member_type === "Distributor"
              ? "นักธุรกิจ"
              : n.member_type === "Member"
              ? "VIP MEMBER"
              : "",
            n.team_name,
            n.member_type === "Distributor"
              ? n.partner_code
              : n.member_type === "Member"
              ? n.member_code
              : "",
            n.first_name_th + " " + n.sur_name_th,
            n.document,
            moment(n.document_date).format("DD/MM/YYYY"),
            n.update_by,
            moment(n.update_date).format("DD/MM/YYYY"),
            n.document_status,
            n.remark,
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 10 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 35 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;
      ws["I4"].s = centerStyles;
      ws["J4"].s = centerStyles;
      ws["K4"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานการตรวจสอบเอกสาร");
      XLSX.writeFile(wb, "รายงานการตรวจสอบเอกสาร.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานการตรวจสอบเอกสาร</PanelHeader>
        <PanelBody>
          <FilterList
            placeholder="รหัสสมาชิก, ชื่อสมาชิก, ชื่อพนักงาน"
            showBranch={false}
            showReportSpan={8}
            topicSpan={2}
            Topic={"วันที่ตรวจสอบเอกสาร"}
            topicAlign={"center"}
            reload={() => {
              reload();
            }}
            exportToExcel={exportToExcel}
          />
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                className="w-100"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>สถานะสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={partnerStatusFilter}
                options={partnerStatusOptions}
                onChange={(e) => {
                  setPartnerStatusFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>Team</Text>
            </Col>
            <Col span={4}>
              <SelectTeam
                withAll
                defaultValue={teamFilter}
                onChange={(e) => {
                  setTeamFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>วันที่ส่งเอกสาร</Text>
            </Col>
            <Col span={5}>
              <DatePicker.RangePicker
                className="w-100"
                // placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) => {
                  let sendDateForm = moment(e[0]).format();
                  let sendDateTo = moment(e[1]).format();
                  setSendDate([sendDateForm, sendDateTo]);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="ประเภทสมาชิก"
              dataIndex="member_type"
              align="center"
              render={(text) =>
                text === "Distributor"
                  ? "นักธุรกิจ"
                  : text === "Member"
                  ? "VIP MEMBER"
                  : ""
              }
            />

            <Table.Column title="Team" dataIndex="team_name" align="center" />
            <Table.Column
              title="รหัสสมาชิก"
              align="center"
              render={(_, record) =>
                record.member_type === "Distributor"
                  ? record.partner_code
                  : record.member_type === "Member"
                  ? record.member_code
                  : ""
              }
            />
            <Table.Column
              title="ชื่อสมาชิก"
              align="left"
              render={(_, record) =>
                record.first_name_th + " " + record.sur_name_th
              }
            />
            <Table.Column title="เอกสาร" dataIndex="document" align="left" />
            <Table.Column
              title="วันที่ส่งเอกสาร"
              dataIndex="document_date"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="ผู้ตรวจสอบ"
              dataIndex="update_by"
              align="center"
            />
            <Table.Column
              title="วันที่ตรวจสอบ"
              dataIndex="update_date"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="การตรวจสอบ"
              dataIndex="document_status"
              align="center"
            />
            <Table.Column title="หมายเหตุ" dataIndex="remark" align="left" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
