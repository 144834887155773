import { notification, Select } from "antd";
import React from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";

const SelectDocumentType = (props) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/ethics/document-type`,
      token: true,
    },
    []
  );

  if (error) {
    notification.error({
      description: error,
      placement: "bottomRight",
    });
  }

  return (
    <>
      <Select placeholder="ใบสมัคร" size="small" loading={loading} {...props}>
        <Select.Option value="">ทั้งหมด</Select.Option>
        {data &&
          data.data &&
          data.data.map((n, i) => (
            <Select.Option key={i} value={n.code}>
              {n.name}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};
export default SelectDocumentType;
