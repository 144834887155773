/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Typography } from 'antd'
import '../../../../../../scss/promotion.scss'

const Tabs = ({ active, onClick }) => {
    const list = [
        { title: 'Highest Pin', value: 'Highest Pin' },
        { title: 'Actual Pin', value: 'Actual Pin' },
        { title: 'Current Pin', value: 'Current Pin' },
    ]

    return (
        <ul className="honor-tabs">
            {
                list.map((n, i) => (
                    <li key={i} className={`${active === n.value ? 'active' : ''}`} onClick={() => onClick(n.value)}>
                        <a>{n.title}</a>
                    </li>
                ))
            }
        </ul>
    )
}

export default Tabs