import { Tabs } from "antd";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  documentsListSelector,
  actionMember,
  documentActiveSelector,
} from "../../../../redux/slices/member";
import DocumentFile from "./DocumentFile";
export default function DocumentPanel() {
  const dispatch = useDispatch();
  const document_list = useSelector(documentsListSelector);
  const active = useSelector(documentActiveSelector);
  const { SET_DOCUMENT_ACTIVE } = actionMember;
  return (
    <Tabs
      size="small"
      tabPosition="left"
      activeKey={active}
      onChange={(e) => dispatch(SET_DOCUMENT_ACTIVE(e))}
    >
      {document_list.length > 0 &&
        document_list.map((value, key) => (
          <Tabs.TabPane tab={value.name} key={value.code}>
            <DocumentFile />
          </Tabs.TabPane>
        ))}
    </Tabs>
  );
}
