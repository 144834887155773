import { DatePicker, Typography, Row, Col } from "antd";
import React, { memo } from "react";
import { moment } from "../utils";
import { disabledEndDateSplit } from "../views/util/helper";
const { Text } = Typography;

const SearchDate = ({
  title = "วันที่",
  start,
  end,
  onChangeStart,
  onChangeEnd,
}) => (
  <Row gutter={8} type="flex" className="align-items-center">
    <Col span={6} className="text-right">
      <Text>{title}</Text>
    </Col>
    <Col span={9}>
      <DatePicker
        format="DD/MM/YYYY"
        className="w-100"
        placeholder="วันที่เริ่มต้น"
        value={start && moment(start)}
        onChange={onChangeStart}
      />
    </Col>
    <Col span={9}>
      <DatePicker
        format="DD/MM/YYYY"
        className="w-100"
        placeholder="วันที่สิ้นสุด"
        value={end && moment(end)}
        disabledDate={(current) => disabledEndDateSplit(current, start)}
        onChange={onChangeEnd}
      />
    </Col>
  </Row>
);

export default memo(SearchDate);
