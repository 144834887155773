import React from "react";
import { Switch, Route } from "react-router-dom";

// Pages
// import MemberList from ". ./member/MemberList";
import BlackList from './BlackList'
// Not found
import Error404 from "../Error404";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={BlackList} />

      <Route component={Error404} />
    </Switch>
  );
};
