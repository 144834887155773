import React from "react";
import { useRouteMatch } from "react-router";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import BalanceDetailLot from "./components/BalanceDetailLot";
import BalanceDetailSet from "./components/BalanceDetailSet";

const BalanceDetail = () => {
  const match = useRouteMatch();

  return (
    <Panel>
      <PanelHeader>
        {match.params.type === "set" ? "ตรวจสอบชุดเซ็ต" : "ตรวจสอบรายการย่อย"}
      </PanelHeader>
      <PanelBody>
        {match.params.type === "set" ? (
          <BalanceDetailSet />
        ) : (
          <BalanceDetailLot />
        )}
      </PanelBody>
    </Panel>
  );
};

export default BalanceDetail;
