/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
  Table,
} from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API, axios } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
// import Summary from '../components/Summary';
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import { ImPriceTag } from "react-icons/im";

const { Search } = Input;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [receiptNo, setReceiptNo] = useState("");
  const [findTeam, setFindTeam] = useState("");
  const limit = 20;
  const user = getUser();
  const toDay = moment().format("DD/MM/YYYY");
  const [allBranch, setAllBranch] = useState([]);
  const [openModalVocher, setOpenModalVoucher] = useState(false);
  const [totalVoucher, setTotalVoucher] = useState(0);
  const [modalVoucherData, setModalVoucherData] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-account`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        receiptNo: receiptNo,
        findTeam: findTeam,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );
  useEffect(() => {
    setDataSource(data ? data : []);
    if (data) {
      let total_voucher = _.sum(data?.voucherList?.map((n) => n.value));
      setTotalVoucher(total_voucher);
    }
  }, [data]);
  async function loadBranch() {
    try {
      const response = await axios.get(`${URL_API}/boss/inventory/all-branch`);
      let branch = response.data?.data?.all_branch;
      setAllBranch(branch);
    } catch (error) {
      console.error(error);
    }
  }

  const teamOptions = _.map(data && data.teamData, (data) => {
    return { value: data.team_name, text: data.team_name };
  });

  const exportToExcel = () => {
    let _branch = _.find(
      allBranch,
      (b) => b.code === state.filter.branch
    )?.name;
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานฝ่ายบัญชี"],
        [
          `สาขา : ${state.filter.branch === "" ? "ทั้งหมด" : _branch}`,
          "",
          "",
          `วันที่ใบเสร็จ : จาก ${moment(state.filter.date_from).format(
            "YYYY-MM-DD"
          )} ถึง ${moment(state.filter.date_to).format("YYYY-MM-DD")}`,
          "",
          "",
          `Team : ${findTeam === "" ? "ทั้งหมด" : findTeam}`,
          "",
          "",
          `วันที่ดึงข้อมูล : ${moment().format("DD/MM/YYYY , HH:mm:ss")}`,
        ],
        [""],
        [
          "ลำดับ",
          "วันที่ใบเสร็จ",
          "เลขที่ใบเสร็จ",
          "เลขที่ใบรับคืน",
          "รหัสสาขา",
          "ชื่อสาขา",
          "ชื่อสาขารับสินค้า",
          "รหัสสินค้า",
          "ชื่อสินค้า",
          "ประเภทสินค้า",
          "จำนวน",
          "ราคาต่อหน่วย",
          "ราคารวม",
          "ส่วนลดต่อบิล",
          "บัตรกำนัลต่อบิล",
          "Vat",
          "ราคารวมต่อบิล",
          "ชื่อผู้ทำรายการ",
          "รหัสสมาชิก",
          "ชื่อสมาชิก",
          "องค์กร",
          "PV ที่ซื้อ",
          "EP ที่ซื้อ",
          "RP ที่ซื้อ",
          "RP ที่แลก",
          "น้ำหนักต่อบิล",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            { v: moment(n.document_date).format("DD/MM/YYYY") },
            n.document_no,
            n.rt_document_no === "" ? "" : n.rt_document_no,
            n.branch_code,
            n.bill_location,
            n.receipt_branch_name,
            n.product_code,
            n.product_name,
            n.product_type_set,
            {
              v: n.qty === "" || n.qty === null ? 0 : n.qty,
              t: "n",
              s: { numFmt: "#,##0", alignment: { horizontal: "right" } },
            },
            {
              v:
                n.unit_price === "" || n.unit_price === null ? 0 : n.unit_price,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v: n.amount === "" || n.amount === null ? 0 : n.amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v: n.discount_amount ? n.discount_amount : 0,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v:
                n.voucher_amount === "" || n.voucher_amount === null
                  ? 0
                  : n.voucher_amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v:
                n.vat_amount === "" || n.vat_amount === null ? 0 : n.vat_amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v:
                n.grand_total_amount === "" || n.grand_total_amount === null
                  ? 0
                  : n.grand_total_amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            n.cashier_name,
            n.partner_code,
            n.partner_name,
            n.team_name === null ? "-" : n.team_name,
            {
              v: n.pv === "" || n.pv === null ? 0 : n.pv * n.qty,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v: n.ep ? n.ep * n.qty : 0,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v: n.rp === "" || n.rp === null ? 0 : n.rp * n.qty,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v:
                n.redeem_point === "" || n.redeem_point === null
                  ? 0
                  : n.redeem_point,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            {
              v: n.weight === "" || n.weight === null ? 0 : n.weight,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
        { s: { r: 1, c: 9 }, e: { r: 1, c: 11 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
      ];

      // var wsrows = [
      //   { hpx: 18 }, // "pixels"
      //   { hpx: 18 }, // "pixels"
      //   { hpx: 18 }, // "pixels"
      //   { hpx: 24 }, // "pixels"
      // ];

      // const centerStyles = {
      //   alignment: {
      //     vertical: "center",
      //     horizontal: "center",
      //   },
      //   border: {
      //     top: { style: "thin", color: { rgb: "000000" } },
      //     bottom: { style: "thin", color: { rgb: "000000" } },
      //     left: { style: "thin", color: { rgb: "000000" } },
      //     right: { style: "thin", color: { rgb: "000000" } },
      //   },
      //   fill: { fgColor: { rgb: "E9E9E9" } },
      // };

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      // const ws = XLSX.utils.json_to_sheet(excelData); //------old version

      ws["!cols"] = wscols;
      //ws["!rows"] = wsrows;
      ws["!merges"] = merge;

      // ws["A4"].s = centerStyles;
      // ws["B4"].s = centerStyles;
      // ws["C4"].s = centerStyles;
      // ws["D4"].s = centerStyles;
      // ws["E4"].s = centerStyles;
      // ws["F4"].s = centerStyles;
      // ws["G4"].s = centerStyles;
      // ws["H4"].s = centerStyles;
      // ws["I4"].s = centerStyles;
      // ws["J4"].s = centerStyles;
      // ws["K4"].s = centerStyles;
      // ws["L4"].s = centerStyles;
      // ws["M4"].s = centerStyles;
      // ws["N4"].s = centerStyles;
      // ws["O4"].s = centerStyles;
      // ws["P4"].s = centerStyles;
      // ws["Q4"].s = centerStyles;
      // ws["R4"].s = centerStyles;
      // ws["S4"].s = centerStyles;
      // ws["T4"].s = centerStyles;
      // ws["U4"].s = centerStyles;
      // ws["V4"].s = centerStyles;
      // ws["w4"].s = centerStyles;
      // ws["x4"].s = centerStyles;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานฝ่ายบัญชี");
      XLSX.writeFile(wb, "รายงานฝ่ายบัญชี.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานฝ่ายบัญชี</PanelHeader>
        <PanelBody>
          <Row className="mb-2">
            <Col span={6}>
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, ชื่อนักธุรกิจ, เลขที่ใบเสร็จ, ชื่อพนักงาน"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                onSearch={reload}
                onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={2} className="mb-2">
              <Text
                className="text-center d-block mr-3"
                strong
                style={{ lineHeight: "32px", float: "right" }}
              >
                วันที่เปิดบิล
              </Text>
            </Col>
            <Col span={6}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col span={6} push={4}>
              <Row gutter={8} className="ml-4">
                <Col span={12} className="mb-2">
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      loadBranch();
                      reload();
                    }}
                    block
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col span={12} className="mb-2">
                  <Button
                    type="primary"
                    icon="file-excel"
                    onClick={exportToExcel}
                    block
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={2}>
              <Text
                className="text-center d-block mr-3"
                strong
                style={{ lineHeight: "32px", float: "right" }}
              >
                สาขาที่เปิดบิล
              </Text>
            </Col>
            <Col span={4}>
              <SelectBranch
                withAll
                userLogin={true}
                allowDisableBranch
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100 p-r-5"
                onChange={(e) => {
                  loadBranch();
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
            <Col span={2}>
              <Text
                className="text-center d-block mr-3"
                strong
                style={{ lineHeight: "32px", float: "right" }}
              >
                องค์กร
              </Text>
            </Col>
            <Col span={3}>
              <SelectTeam
                withAll
                defaultValue=""
                placeholder="องค์กร"
                onChange={(e) => {
                  setFindTeam(e);
                  console.log(e);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data ? data.data : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data.data.length : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="row_id"
              key="index"
              align="center"
            />
            <Table.Column
              title="วันที่"
              render={({ document_date }) => (
                <>{moment(document_date).format("DD/MM/YYYY")}</>
              )}
            />

            <Table.Column
              title="เลขที่ใบเสร็จ"
              dataIndex="document_no"
              align="left"
            />
            <Table.Column
              title="เลขที่ใบรับคืน"
              dataIndex="rt_document_no"
              align="left"
              render={(text) => {
                if (text === null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="รหัสสาขา"
              dataIndex="branch_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสาขา"
              dataIndex="bill_location"
              align="left"
            />
            <Table.Column
              title="ชื่อสาขารับสินค้า"
              dataIndex="receipt_branch_name"
              align="left"
            />
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name"
              align="left"
            />
            <Table.Column
              title="ประเภทสินค้า"
              dataIndex="product_type_set"
              align="left"
              // render={(text) =>
              //   text === "Kit/Package" ? "สินค้าชุด" : "สินค้าเดี่ยว"
              // }
            />
            <Table.Column
              title="จำนวน"
              dataIndex="qty"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="ราคาต่อหน่วย"
              dataIndex="unit_price"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ราคารวม"
              dataIndex="amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ส่วนลดต่อบิล"
              dataIndex="discount_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            {/* <Table.Column
              title="บัตรกำนัล"
              dataIndex="voucher_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            /> */}
            <Table.Column
              title="บัตรกำนัลต่อบิล"
              dataIndex="voucher_amount"
              // render={(text, record) => {
              //   if (record.ref_code !== null && record.ref_code !== "") {
              //     return numeral(text).format("0,0.00");
              //   } else {
              //     return "0.0";
              //   }
              // }}

              // render={(text, record) => {
              //   const { row_id, document_group, document_no, partner_code } = record
              //   const voucherList = dataSource?.voucherList
              //   const voucher = _.filter(voucherList, (n) => n.document_group === document_group && n.document_no === document_no && n.partner_code === partner_code)
              //   const value_voucher = _.sum(voucher.map(v => v.value)) // only one row
              //   if (row_id !== 'รวมจำนวนเงินทั้งหมด') {
              //     if (value_voucher > 0) {
              //       return <div className="flex items-center">
              //         <span>{numeral(value_voucher).format("0,0.00")}</span>
              //         <span className="ml-2 cursor-pointer p-2" onClick={() => {
              //           setModalVoucherData(voucher || [])
              //           setOpenModalVoucher(true)
              //         }}>
              //           <ImPriceTag />
              //         </span>
              //       </div>
              //     }
              //     return " - "
              //   } else {
              //     if (totalVoucher > 0) {
              //       return <div className="flex items-center">
              //         <span>{numeral(totalVoucher).format("0,0.00")}</span>
              //         <span className="ml-2 cursor-pointer p-2" onClick={() => {
              //           setModalVoucherData(voucherList || [])
              //           setOpenModalVoucher(true)
              //         }}>
              //           <ImPriceTag />
              //         </span>
              //       </div>
              //     }
              //     return numeral(totalVoucher).format("0,0.00");
              //   }
              // }}
              align="right"
            />

            {/* <Table.Column
              title="ส่วนต่างบัตรกำนัล"
              dataIndex="redeem_dif"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            /> */}
            <Table.Column
              title="Vat"
              dataIndex="vat_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ราคารวมต่อบิล"
              dataIndex="grand_total_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ชื่อผู้ทำรายการ"
              dataIndex="cashier_name"
              align="left"
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสมาชิก"
              dataIndex="partner_name"
              align="left"
            />
            <Table.Column
              title="องค์กร"
              dataIndex="team_name"
              align="right"
              render={(text) => {
                if (text === "" || text === null) {
                  text = "-";
                }
                return text;
              }}
            />
            <Table.Column
              title="PV ที่ซื้อ"
              dataIndex="pv"
              align="right"
              render={(text, record) => {
                if (text === "") {
                  return " ";
                }
                return numeral(record.pv * record.qty).format("0,0.00");
              }}
            />
            <Table.Column
              title="EP ที่ซื้อ"
              dataIndex="ep"
              align="right"
              render={(text, record) => {
                if (text === "") {
                  return " ";
                }
                return numeral(record.ep * record.qty).format("0,0.00");
              }}
            />
            <Table.Column
              title="RP ที่ซื้อ"
              dataIndex="rp"
              align="right"
              render={(text, record) => {
                if (text === "") {
                  return " ";
                }
                return numeral(record.rp * record.qty).format("0,0.00");
              }}
            />
            <Table.Column
              title="RP แลก"
              dataIndex="redeem_point"
              align="right"
              render={(text, record) => {
                if (text === "") {
                  return "";
                }
                return numeral(record.redeem_point).format("0,0.00");
              }}
            />
            <Table.Column
              title="น้ำหนักต่อบิล"
              dataIndex="weight"
              align="right"
              render={(text, record) => {
                if (text === "") {
                  return "";
                }
                return numeral(record.weight).format("0,0.00");
              }}
            />
            {/* <Table.Column
              title="เลขที่บัตรกำนัล"
              dataIndex="ref_code"
              render={(text) => {
                if (text === null) {
                  return "-";
                }
                return text;
              }}
              align="right"
            /> */}
          </Table>
        </PanelBody>
      </Panel>

      <Modal
        title="รายการบัตรกำนัล"
        visible={openModalVocher}
        footer={[]}
        onOk={() => setOpenModalVoucher(false)}
        onCancel={() => setOpenModalVoucher(false)}
      >
        <Table
          dataSource={modalVoucherData}
          pagination={false}
          bordered
          rowKey="row_id"
        >
          <Table.Column
            title="รหัสบัตรกำนัล"
            dataIndex="code"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
          <Table.Column
            title="ชื่อบัตรกำนัล"
            dataIndex="name"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
          <Table.Column
            title="มูลค่า"
            dataIndex="value"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
        </Table>
      </Modal>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
