/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, notification, Row, Spin, Typography } from "antd";
import numeral from "numeral";
import React, { useContext, useEffect, useState } from "react";
import { axios, URL_API, _ } from "../../../../../../utils";
import { resCatch } from "../../../../../util/helper";
import PreOrderContext from "../../../reducer";
import Item from "./Item";

const { Text } = Typography;

const Index = ({
  reserveRedeem,
  disabled,
  data,
  balance,
  totalPayment,
  buyerCode,
  buyerType,
}) => {
  const {
    state: {
      billModal: { reserve_no, partner_code, rp_hold, rp_topup },
    },
    dispatch,
  } = useContext(PreOrderContext);
  const [loading, setLoading] = useState(false);
  const title_text = reserveRedeem ? "รายละเอียดการตัดคะแนน" : "รายการชำระเงิน";

  useEffect(() => {
    reserve_no && loadData();
  }, [reserve_no]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res_payment = await axios({
        baseURL: URL_API,
        url: "/pos/operation/reserve/payment",
        params: {
          reserve_no,
        },
      });
      dispatch({
        type: "LOAD_PAYMENT",
        payload: res_payment.data.data,
      });

      if (reserveRedeem) {
        const res_point = await axios({
          baseURL: URL_API,
          url: "/pos/master/member/point",
          params: {
            partner_code,
          },
        });
        if (res_point.data.data) {
          const { rp_hold, rp_topup } = res_point.data.data;
          dispatch({
            type: "SET_GLOBAL_STATE",
            payload: { name: "billModal", value: { rp_hold, rp_topup } },
          });
        }
      }
      setLoading(false);
    } catch (err) {
      resCatch(err);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row className="bg-gray-60 py-1 px-2">
        <Col
          span={24}
          className="d-flex align-items-center justify-content-between"
        >
          <Text className="text-white">{title_text}</Text>
          {!disabled && !reserveRedeem && (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                if (balance === 0) {
                  notification.warning({
                    message: "ไม่สามารถเพิ่มวิธีชำระได้",
                    description: `${
                      reserveRedeem ? "คะแนนที่หัก" : "จำนวนเงิน"
                    }ครบแล้ว`,
                  });
                  return;
                }
                if (_.some(data, (n) => !n.commit)) {
                  notification.warning({
                    message: "ไม่สามารถเพิ่มวิธีชำระได้",
                    description: "กรุณาทำรายการบันทึกรับ",
                  });
                  return;
                }
                let payload = { reserve_no };
                if (reserveRedeem) {
                  payload = {
                    ...payload,
                    payment_type: "redeem",
                    payment_type_name: "RP",
                  };
                }
                dispatch({
                  type: "ADD_PAYMENT",
                  payload,
                });
              }}
            >
              Add Payment
            </Button>
          )}
        </Col>
      </Row>

      {reserveRedeem ? (
        <Row className="bg-gray-40 py-1 px-2">
          <Col span={12}>
            <Text className="mr-2" strong>
              คะแนนพร้อมใช้
            </Text>
            <Text>{numeral(rp_topup).format("0,0.00")}</Text>
          </Col>
          <Col span={12}>
            <Text className="mr-2" strong>
              คะแนนพร้อมโอน
            </Text>
            <Text>{numeral(rp_hold).format("0,0.00")}</Text>
          </Col>
        </Row>
      ) : (
        <>
          <Row gutter={8} className="my-2">
            <Col span={5} className="text-center">
              <Text strong>วันที่และเวลาชำระ</Text>
            </Col>
            <Col span={3} className="text-center">
              <Text strong>ประเภทการชำระ</Text>
            </Col>
            <Col span={5} className="text-center">
              <Text strong>ธนาคาร</Text>
            </Col>
            <Col span={4} className="text-center">
              <Text strong>เลขที่อ้างอิง</Text>
            </Col>
            <Col span={4} className="text-center">
              <Text strong>จำนวน</Text>
            </Col>
          </Row>

          {_.map(data, (n, i) => (
            <Item
              key={`payment-${i}`}
              data={n}
              disabled={disabled}
              reserveRedeem={reserveRedeem}
              reloadPayment={loadData}
              balance={balance}
              // allPoint={rp_hold + rp_topup}
              // reserveNo={reserve_no}
              buyerCode={buyerCode}
              buyerType={buyerType}
            />
          ))}
        </>
      )}

      <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
        <Col className="pl-4 text-right" span={21}>
          <Text strong className="text-dark mr-3">
            {reserveRedeem ? "คะแนน RP จองแลก" : "คงเหลือ"}
          </Text>
        </Col>
        <Col className="pr-4 text-right" span={3}>
          <Text strong className="text-dark">
            {numeral(balance).format("0,0.00")}
          </Text>
        </Col>
      </Row>

      <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
        <Col className="pl-4 text-right" span={21}>
          <Text strong className="text-dark mr-3">
            {reserveRedeem ? "คะแนน RP พร้อมใช้คงเหลือ" : "รวมเงินที่รับชำระ"}
          </Text>
        </Col>
        <Col className="pr-4 text-right" span={3}>
          <Text strong className="text-dark">
            {numeral(reserveRedeem ? rp_topup - balance : totalPayment).format(
              "0,0.00"
            )}
          </Text>
        </Col>
      </Row>
    </Spin>
  );
};

export default Index;
