/* eslint-disable no-unused-vars */
import { Button, Col, Input, notification, Row, Table } from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import SelectTeam from "../../components/SelectTeam";
import * as XLSX from "xlsx-js-style";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const limit = 50;
  const [partnerFilter, setPartnerFilter] = useState("");
  const [team, setTeam] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [periodFilter, setPeriodFilter] = useState({
    periodFrom: "",
    periodTo: "",
  });

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/commission/commission-partnercode`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        partnerFilter: partnerFilter,
        team: team,
        periodFrom: periodFilter.periodFrom,
        periodTo: periodFilter.periodTo,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานโบนัสคอมมิชชั่น"],
        [
          `รอบคำนวณ : จาก ${periodFilter.periodFrom} ถึง ${periodFilter.periodTo}`,
          "",
          "",
          `Team : ${team === "" ? "ทั้งหมด" : team }`,
          `วันที่ดึงข้อมูล : ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [""],
        [
          "ลำดับ",
          "รอบคำนวณ",
          "รหัส",
          "ชื่อ-นามสกุล",
          "ชื่อนิติบุคคล",
          "Team",
          "ค่าแนะนำ",
          "บริหารทีม",
          "แมชชิ่ง",
          "พิเศษ",
          "โบนัสส่วนบุคคล",
          "ส่วนต่างเงินคืน",
          "รวมโบนัส",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.period,
            n.partner_code,
            n.partner_name,
            n.company_name,
            n.team_name,
            {
              v:
                n.ref_amount === ""
                  ? 0
                  : numeral(n.ref_amount).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.manage_amount === ""
                  ? 0
                  : numeral(n.manage_amount).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.matching_amount === ""
                  ? 0
                  : numeral(n.matching_amount).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.special_bonus === ""
                  ? 0
                  : numeral(n.special_bonus).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              // ไม่มีข้อมูล โบนัสส่วนบุคคล
              v: "-" === "" ? 0 : numeral(0).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },

            {
              // ไม่มีข้อมูล ส่วนต่างเงินคืน
              v: "-" === "" ? 0 : numeral(0).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.ref_amount +
                  n.manage_amount +
                  n.matching_amount +
                  n.special_bonus ===
                ""
                  ? 0
                  : numeral(
                      n.ref_amount +
                        n.manage_amount +
                        n.matching_amount +
                        n.special_bonus
                    ).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },

            // {v: n.amount === "none" ? "" : numeral(n.amount).format("0,0.00"), s: { alignment: { horizontal: "right" } } },
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 6 } },
      ];

      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 35 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      var wsrows = [
        { hpx: 18 }, // "pixels"
        { hpx: 18 }, // "pixels"
        { hpx: 18 }, // "pixels"
        { hpx: 24 }, // "pixels"
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      // const ws = XLSX.utils.json_to_sheet(excelData); //------old version

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;
      ws["I4"].s = centerStyles;
      ws["J4"].s = centerStyles;
      ws["K4"].s = centerStyles;
      ws["L4"].s = centerStyles;
      ws["M4"].s = centerStyles;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานคอมมิชชั่นตามรหัสสมาชิก");
      XLSX.writeFile(wb, "รายงานคอมมิชชั่นตามรหัสสมาชิก.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  console.log(data);

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const periodFilterOptions = _.map(dataSource.periodData, (data) => ({
    value: data.period,
    text: data.period,
  }));

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานคอมมิชชั่นตามรหัสสมาชิก</PanelHeader>

        <PanelBody>
          <Row align="middle" justify="center">
            <Col span={3} className="pt-2">
              <Text strong>รหัสสมาชิก, ชื่อสมาชิก :</Text>
            </Col>
            <Col span={6} className=" p-b-15">
              <Input
                placeholder="รหัสสมาชิก , ชื่อสมาชิก"
                className="w-100 p-r-5 "
                onChange={(e) => {
                  setPartnerFilter(e.target.value);
                }}
              />
            </Col>
            <Col span={1} className="pt-2" push={1}>
              <Text strong>Team :</Text>
            </Col>
            <Col span={6} className="p-r-10 p-b-15" push={1}>
              <SelectTeam
                withAll
                placeholder="Team"
                className="w-100 p-r-5 "
                onChange={(e) => {
                  setTeam(e);
                }}
              />
            </Col>
            <Row gutter={8} type="flex" justify="end">
              <Col>
                <Button type="primary" onClick={reload}>
                  แสดงรายงาน
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="text-white d-flex align-items-center"
                  icon="file-excel"
                  onClick={exportToExcel}
                >
                  Export Excel
                </Button>
              </Col>
            </Row>
          </Row>

          <Row align="middle" justify="center">
            <Col span={2} className="pt-2">
              <Text strong>รอบคำนวณ</Text>
            </Col>
            <Col span={1} className="pt-2">
              <Text strong>จาก :</Text>
            </Col>
            <Col span={3} className="p-b-15">
              <SelectOption
                className="w-100"
                options={periodFilterOptions}
                placeholder="จาก"
                onChange={(e) => {
                  setPeriodFilter({
                    periodFrom: e,
                    periodTo: periodFilter.periodTo,
                  });
                }}
              />
            </Col>

            <Col span={1} className="pt-2" push={1}>
              <Text strong>ถึง :</Text>
            </Col>
            <Col span={3} className="p-b-15" push={1}>
              <SelectOption
                className="w-100"
                options={periodFilterOptions}
                placeholder="ถึง"
                onChange={(e) => {
                  setPeriodFilter({
                    periodTo: e,
                    periodFrom: periodFilter.periodFrom,
                  });
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              // total: newData.newData.length ,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" key={true} dataIndex="row_id" />
            <Table.Column title="รอบคำนวณ" dataIndex="period" align="center" />
            <Table.Column title="รหัส" dataIndex="partner_code" align="right" />
            <Table.Column
              title="ชื่อสมาชิก"
              dataIndex="partner_name"
              align="right"
            />
            <Table.Column
              title="ชื่อนิติบุคคล"
              dataIndex="company_name"
              align="right"
            />
            <Table.Column title="Team" dataIndex="team_name" align="right" />
            <Table.Column
              title="ค่าแนะนำ"
              dataIndex="ref_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="บริหารทีม"
              dataIndex="manage_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="แมชชิ่ง"
              dataIndex="matching_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="พิเศษ"
              dataIndex="special_bonus"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="โบนัสส่วนบุคคล"
              dataIndex=""
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ส่วนต่างเงินคืน"
              dataIndex=""
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="รวมโบนัส"
              align="right"
              render={({
                ref_amount,
                matching_amount,
                manage_amount,
                special_bonus,
              }) => {
                const text =
                  ref_amount + matching_amount + manage_amount + special_bonus; //+ personalBonus + cashbackdif
                return numeral(text).format("0,0.00");
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
