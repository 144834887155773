/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../../../../components/TextEditor";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";

function LSP_Hilight({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);

  const [contentTextEditorHilight, setContentTextEditorHilight] = useState("");
  const [contentTextEditorFooter, setContentTextEditorFooter] = useState("");

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      for (let i in fileListDesktop) {
        if (values.desktop_img?.fileList) {
          formData.append(
            "desktop_img",
            values.desktop_img?.fileList[i].originFileObj
          );
        }
      }

      if (values.mobile_img?.file?.originFileObj)
        formData.append("mobile_img", values.mobile_img?.file?.originFileObj);

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img"]),
          content: JSON.stringify({
            content_hilight: contentTextEditorHilight,
            content_footer: contentTextEditorFooter,
          }),
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path =
                  "/manage-website/ecosystem/legacy-space/lsp-hilight/update")
              : (url_path =
                  "/manage-website/ecosystem/legacy-space/lsp-hilight");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = async (e) => {
    //------------------------------------check delete[index] preview
    let deleteIndex = e.uid?.toString();
    for (let i in fileListDesktop) {
      let checkIndex = fileListDesktop[i].uid.toString();
      if (deleteIndex === checkIndex) {
        console.log(fileListDesktop[i].uid);
        fileListDesktop.splice(i, 1);
        setFileListDesktop([...fileListDesktop]);
      }
    }

    //------------------------------------check delete[index] db
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleRemoveMobile = (e) => {
    setFileListMobile([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleChangeUploadDesktop = async (e) => {
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      if (fileListDesktop.length < 3) {
        setFileListDesktop([
          ...fileListDesktop,
          {
            ...e.file,
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      }
    }
  };
  const handleChangeUploadMobile = async (e) => {
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));

      setFileListMobile([
        {
          uid: file.uid,
          status: "done",
          name: file.name,
          url: img_url,
        },
      ]);
    }
  };
  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/legacy-space/lsp-hilight",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);

        let loadContent = JSON.parse(data.content);
        if (loadContent) {
          setContentTextEditorHilight(loadContent.content_hilight);
          setContentTextEditorFooter(loadContent.content_footer);
        }

        let { loadMobileImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListMobile(loadMobileImg);
          }
        }

        let loadDesktopImg = _.map(
          _.filter(data.files || [], (n) => {
            return n.document_type == "34";
          }),
          (n) => {
            return {
              uid: n.id,
              status: "done",
              name: n.file_name,
              url: n.azure_url,
            };
          }
        );

        setFileListDesktop(loadDesktopImg);

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>LEGACY SPACE Hilight</PanelHeader>
        <PanelBody>
          <Divider orientation="left">Hilight Title</Divider>
          <Form.Item layout="vertical">
            <Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item label="อัพโหลด ( 3 รูป ) : " name="desktop_img">
                    {getFieldDecorator("desktop_img", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveDesktop}
                        onChange={handleChangeUploadDesktop}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>
            <Row className="mb-3">
              <Form.Item label="Title :">
                {getFieldDecorator("title", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ Title" }],
                })(<Input />)}
              </Form.Item>
            </Row>
            <Row className="mb-3">
              <p>Content Hilight:</p>
              <TextEditor
                authToken={getToken()}
                value={contentTextEditorHilight}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setContentTextEditorHilight(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Content Type ( Desktop ) :">
                  {getFieldDecorator("target_tab", {
                    initialValue: "text_left",
                  })(
                    <Radio.Group>
                      <Radio value={"text_left"}>Text Left</Radio>
                      <Radio value={"text_right"}>Text Left</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left">Footer Hilight</Divider>

            <Row gutter={24} className="mb-2">
              <Col md={12} className="mb-2">
                <Form.Item label="อัพโหลดรูปภาพ : " name="mobile_img">
                  {getFieldDecorator("mobile_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListMobile}
                      customRequest={dummyRequest}
                      beforeUpload={beforeUploadImg}
                      onRemove={handleRemoveMobile}
                      onChange={handleChangeUploadMobile}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-3 ">
              <p>Content Footer:</p>
              <TextEditor
                authToken={getToken()}
                value={contentTextEditorFooter}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setContentTextEditorFooter(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-lsp-hilight")(LSP_Hilight);
