/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ErrorMessage } from '@hookform/error-message';
import { Col, Row, Typography, Input, Modal, Button } from 'antd';
import { Upload } from 'antdV4';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import fitnessBranchConstants from '../../constants/fitnessBranchConstants';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertConfirm, AlertSuccess } from '../../../../util/Alert';
import { FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

const FitnessBranchForm = () => {
  const {
    watch,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: 'images',
    control,
  });

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [uploadedImageIndex, setUploadedImageIndex] = useState(0);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
  };

  const { execute: uploadFile, data: uploadedImageData } = useAsync(
    fitStudioService.uploadFile,
    {}
  );

  useEffect(() => {
    if (uploadedImageData) {
      setValue(
        `images.${uploadedImageIndex}.imageUrl`,
        uploadedImageData.resourceUrl
      );
    }
  }, [uploadedImageData]);

  const handleChange = (file) => {
    const formData = new FormData();
    formData.append('image', file.originFileObj);
    uploadFile(formData);
  };

  const { execute: insertFitnessBranch } = useAsync(
    fitStudioService.insertFitnessBranch,
    {
      onSuccess: (data) => {
        AlertSuccess('บันทึกข้อมูลสาขาสำเร็จ');
        reset(data);
      },
    }
  );

  const _HandleSubmit = handleSubmit((data) => {
    insertFitnessBranch(data);
  });

  return (
    <div>
      <Row className="align-items-center  mb-2">
        <Col span={4}>
          <Typography.Text>สาขา</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{watch('branchMaster.Name')}</Typography.Text>
        </Col>
      </Row>
      <Row className="align-items-center  mb-2">
        <Col span={4}>
          <Typography.Text>รูปภาพบรรยากาศสาขา</Typography.Text>
        </Col>
        <Col span={20}>
          <FaPlus
            className="mr-2"
            onClick={() => {
              append({
                imageUrl: '',
                description: '',
              });
            }}
          />
        </Col>
      </Row>
      {fields.map((item, index) => {
        return (
          <Row key={index} className="align-items-center  mb-2">
            <Col span={8}>
              <Controller
                name={`images.${index}.imageUrl`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Upload.Dragger
                    name="file"
                    multiple={false}
                    onPreview={handlePreview}
                    onChange={(e) => {
                      setUploadedImageIndex(index);
                      handleChange(e.file);
                    }}
                    maxCount="1"
                    onRemove={() => {
                      onChange('');
                    }}
                    showUploadList={false}
                  >
                    {value ? (
                      <img
                        src={value}
                        alt={value}
                        style={{
                          width:
                            fitnessBranchConstants.fitnessBranchImage.width / 3,
                          height:
                            fitnessBranchConstants.fitnessBranchImage.height /
                            3,
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <div>
                        <p className="ant-upload-drag-icon"></p>
                        <p className="ant-upload-text">
                          คลิ๊ก หรือ ดร็อป ไฟล์รูปภาพ
                        </p>
                        <p className="ant-upload-hint">
                          ขนาดรูปภาพควรมีขนาดอัตราส่วน{' '}
                          {fitnessBranchConstants.fitnessBranchImage.ratio}
                        </p>
                        <p className="ant-upload-hint">
                          แนะนำขนาดที่{' '}
                          {fitnessBranchConstants.fitnessBranchImage.width} x
                          {fitnessBranchConstants.fitnessBranchImage.height}
                        </p>
                      </div>
                    )}
                  </Upload.Dragger>
                )}
              />
            </Col>
            <Col span={15} offset={1}>
              <Controller
                name={`images.${index}.description`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <Typography.Text>คำบรรยาย</Typography.Text>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input value={value} onChange={onChange} />
                        <MdDelete
                          size={20}
                          className="ml-2"
                          onClick={() => {
                            remove(index);
                            reset(getValues());
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>
        );
      })}
      <Controller
        name="googleMapUrl"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>Google Map ลิงค์</Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: 10,
        }}
      >
        <Button
          className="bg-pv border-pv text-white"
          onClick={async () => {
            const confirm = await AlertConfirm('คุณต้องการบันทึก ใช่หรือไม่');
            if (confirm) _HandleSubmit();
          }}
        >
          บันทึก
        </Button>
      </div>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img
          alt="previewImage"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default FitnessBranchForm;
