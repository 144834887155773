import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import LegacyNotificationForm from '../form/LegacyNotificationForm';
import fitLegacyServices from '../../../../../services/fit-legacy.services';

const LegacyNotificationModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLob } =
    fitLegacyServices.useMutationCreateLegacyNotification(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-notification'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    });

  const { mutate: editLob } =
    fitLegacyServices.useMutationUpdateLegacyNotification(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-notification'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    });

  const _HandleSubmit = handleSubmit((data) => {
    const legaynotificationData = {
      title: data.title,
      description: data.description,
      sub_description: data.sub_description,
      thumbnail_img_url: data.thumbnail_img_url,
      link: data.link,
      link_type: data.link_type,
      notification_code: data.notification_code,
    };

    if (watch('formType') === 'create') {
      createLob(legaynotificationData);
    } else if (watch('formType') === 'edit') {
      editLob({ id: data.id, data: legaynotificationData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่ม Legacy Notification'
          : 'แก้ Legacy Notification'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyNotificationForm />
    </Modal>
  );
};

export default LegacyNotificationModal;
