/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Modal, notification, Skeleton } from 'antd';
import { default as Axios } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../../../../hooks/dom';
import { actionLegal, bankSelector, typeSelector } from '../../../../../redux/slices/legal';
import styles from '../../../../../scss/module/legal.module.scss';
import { URL_API } from '../../../../../utils';
import LegalPanel from '../../components/LegalPanel';
import MultipleRecord from '../../components/MultipleRecord';
import PageMenuTab from '../../components/PageMenuTab';
import ProfileMenu from '../components/ProfileMenu';
import { axios } from '../../../../../utils/useAxios';

function mapBank(data) {
  let bank = [];
  data.forEach(item => {
    bank = [...bank, [
      {
        name: 'ธนาคาร',
        value: item.bank_name
      },
      {
        name: 'ชื่อบัญชี',
        value: item.account_name
      },
      {
        name: 'เลขที่บัญชี',
        value: item.account_number
      },
      {
        name: 'สาขา',
        value: item.bank_branch
      },
      {
        name: 'สถานะ',
        value: item?.status?.toLowerCase() === 'active' ? 'ใช้งานปกติ' : 'ยกเลิกใช้งาน'
      },
    ]]
  });
  return bank;
}

const Index = ({ history }) => {
  const query = useQuery();
  const id = query.get('id');
  const [source] = useState(Axios.CancelToken.source());
  const dispatch = useDispatch();
  const { SET_BANK } = actionLegal;
  const { person, company } = useSelector(bankSelector);
  const [personBank, setPersonBank] = useState([]);
  const [companyBank, setCompanyBank] = useState([]);
  const [loading, setLoading] = useState(null);
  const member_type = useSelector(typeSelector);

  useEffect(() => {
    return () => source.cancel('cancel by user (unmounted)');
  }, [source]);

  useEffect(() => {
    if (person.length > 0 || company.length > 0) {
      setPersonBank([...mapBank(person)]);
      setCompanyBank([...mapBank(company)]);
    }
  }, [person, company])

  useEffect(() => {
    if (person.length === 0 && company.length === 0 && id !== null && member_type.type === 'distributor') {
      setLoading(true);
      axios({
        url: `${URL_API}/legal/profile/bank`,
        params: {
          id
        },
        cancelToken: source.token
      }).then((res) => {
        if (res.data.data.person.length > 0 || res.data.data.company.length > 0) {
          dispatch(SET_BANK({ ...res.data.data }));
        } else {
          Modal.warning({
            title: "ไม่พบข้อมูล",
            content: `นักธุรกิจ id : ${id}`,
            okText: "ตกลง",
            onOk: () => {
              history.push('/legal');
            },
          });
        }
        setLoading(false);
      }).catch((err) => {
        if (err.message !== 'cancel by user (unmounted)') {
          notification.error({
            message: "ตรวจพบสิ่งผิดพลาด",
            description:
              err.response && err.response.data
                ? err.response.data.message
                : err.message,
            placement: "topRight",
          });
          setLoading(false);
        }
      });
    }
  }, [person, company, id, source, dispatch, SET_BANK]);

  return (
    <LegalPanel>
      <div className={styles.profile}>
        <ProfileMenu />
        {
          loading ? (
            <Skeleton active className={styles.skeleton} />
          ) : (
              <>
                <MultipleRecord title="บัญชีธนาคาร" data={personBank} divider />
                <MultipleRecord title="บัญชีธนาคารสำหรับนิติบุคคล" data={companyBank} />
              </>
            )
        }
      </div>
    </LegalPanel>
  )
}

export default Index
