/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Typography } from "antd";
import React, { useContext, useState } from "react";
import DistributorContext from "../contexts/distributor-context";
import MemberForm from "./MemberForm";
import MemberMarriedForm from "./MemberMarriedForm";
import SponsorForm from "./SponsorForm";
import UpLineForm from "./UpLineForm";

const Tabs = ({ active, onClick, list, checkMarried }) => {
  const checkMemberMarried = (tabs) => {
    if (
      tabs === "marriedBuyer" &&
      (checkMarried === undefined || checkMarried === null)
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <ul className="full-tabs" style={{ justifyContent: "start" }}>
      {list.map((n, i) => (
        <li
          key={i}
          className={`${active === n.value ? "active" : ""} mr-4`}
          onClick={() => onClick(n.value)}
        >
          <Typography.Title level={4}>
            <a
              style={
                n.value === "marriedBuyer"
                  ? checkMarried == null
                    ? { fontSize: "13px", opacity: "0.3" }
                    : { fontSize: "13px", color: "#97d700" }
                  : { fontSize: "13px" }
              }
            >
              {n.title}
            </a>
          </Typography.Title>
        </li>
      ))}
    </ul>
  );
};

const BuyerInfo = ({
  handleChange,
  Member,
  setMember,
  handleChangeMarried,
  errors,
  loadingMember,
}) => {
  const { state, dispatch } = useContext(DistributorContext);
  const { ActiveTabs } = state;
  const list = [
    { title: "ข้อมูลผู้สมัครหลัก", value: "buyerInfo" },
    { title: "ข้อมูลผู้สมัครร่วม", value: "marriedBuyer" },
    { title: "ผู้แนะนำ", value: "sponsor" },
    { title: "อัพไลน์", value: "upline" },
  ];
  const [activeTab, setActiveTab] = useState("buyerInfo");

  return (
    <>
      <Tabs
        active={activeTab}
        onClick={(e) => {
          setActiveTab(e);
          dispatch({
            type: "SET_ACTIVE_TAB",
            payload: { active_tab_name: e },
          });
        }}
        list={list}
        checkMarried={Member?.married.ref_partner_code}
      />
      <div className="container shadow-sm p-15 mb-4 bg-white rounded">
        {activeTab === "buyerInfo" ? (
          //  <>
          //    {
          //                       loadingMember ?
          //                           <Skeleton active />
          //                           :
          //                           <MemberForm
          //                               Member={Member}
          //                               errors={errors}
          //                               setMember={setMember}
          //                               HaveMarried={Member?.married.ref_partner_code}
          //                               handleChange={handleChange}
          //                           />
          //                   }
          //   {(Member?.main_partner_code == null &&
          //     Member?.partner_code_married == null) ||
          //     Member?.main_partner_code === Member?.partner_code ? (
          <MemberForm
            Member={Member}
            errors={errors}
            setMember={setMember}
            HaveMarried={Member?.married.ref_partner_code}
            handleChange={handleChange}
            setActiveTab={setActiveTab}
          />
        ) : //   ) : (
        //      <MemberMarriedForm
        //       Member={Member}
        //       errors={errors}
        //       setMember={setMember}
        //       HaveMarried={Member?.married.ref_partner_code}
        //       handleChangeMarried={handleChangeMarried}
        //       setActiveTab={setActiveTab}
        //     />
        //   )}
        // </>
        activeTab === "marriedBuyer" ? (
          // <>
          //   {(Member?.main_partner_code == null &&
          //     Member?.partner_code_married == null) ||
          //     Member?.main_partner_code !==
          //     Member?.married.partner_code_married ? (
          <MemberMarriedForm
            Member={Member}
            errors={errors}
            setMember={setMember}
            HaveMarried={Member?.married.ref_partner_code}
            handleChangeMarried={handleChangeMarried}
            setActiveTab={setActiveTab}
          />
        ) : //   ) : (
        //     <MemberForm
        //       Member={Member}
        //       errors={errors}
        //       setMember={setMember}
        //       HaveMarried={Member?.married.ref_partner_code}
        //       handleChange={handleChange}
        //       setActiveTab={setActiveTab}
        //     />
        //   )}
        // </>
        activeTab === "sponsor" ? (
          <SponsorForm Member={Member?.sponser} />
        ) : (
          <UpLineForm Member={Member?.upline} />
        )}
      </div>
    </>
  );
};

export default BuyerInfo;
