import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import fitLegacyServices from '../../../../../services/fit-legacy.services';
import LegacyRewardEventListForm from '../form/LegacyRewardEventListForm';

const LegacyRewardEventListModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: editLegacyRewardEventList } =
    fitLegacyServices.useMutationUpdateLegacyRewardEventList(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-reward-event-list'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    });

  const _HandleSubmit = handleSubmit((data) => {
    const legayRewardEventListData = {
      isJoinEvent: data.isJoinEvent,
      isRewardRight: data.isRewardRight,
      rewardTitle: data.rewardTitle,
      isGetReward: data.isGetReward,
    };

    if (watch('formType') === 'create') {
    } else if (watch('formType') === 'edit') {
      editLegacyRewardEventList({
        id: data.id,
        data: legayRewardEventListData,
      });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่ม Legacy Reward Event List'
          : 'แก้ Legacy Reward Event List'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyRewardEventListForm />
    </Modal>
  );
};

export default LegacyRewardEventListModal;
