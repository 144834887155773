/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { FaCogs, FaEye, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import { MdFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import { fixDepartment } from "../../../config/fix-value";
import { useHttp } from "../../../hooks/http";
import { actionInventoryCount } from "../../../redux/slices/inventory-count";
import { actionUser } from "../../../redux/slices/user";
import { getInventoryCountByDocumentNo } from "../../../redux/thunk/inventory-count";
import {
  axios,
  getToken,
  getUserObj,
  moment,
  URL_API,
  _,
} from "../../../utils";
import { resCatch, resCatchModal } from "../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import SelectBranch from "../pos/components/SelectBranch";
import SelectMasterAllType from "../../../components/SelectMasterAllType";

const { Column } = Table;

const { Text } = Typography;

const typeList = ["daily", "cycle"];

const { setGlobalState, clear, clearStock } = actionInventoryCount;
const { clearPermission } = actionUser;

const getClassNameStatus = (status) => {
  switch (status) {
    case "draft":
      return "text-yellows";
    case "return":
      return "text-nn";
    case "pending":
      return "text-rp";
    case "completed":
      return "text-sp";
    default:
      return "";
  }
};

const mapExcelData = (inventoryCount, wh, property) => {
  const is_primary = _.includes(["primary_set", "primary"], property);
  const { itemKeys, itemByKey } = inventoryCount[property];
  let merge = [];
  const result = _.map(itemKeys, (s_key, sr_index) =>
    _.reduce(
      wh[property],
      (res, n) => {
        const data = itemByKey[s_key];
        const er_index = _.findLastIndex(
          itemKeys,
          (e_key) => data.product_id === itemByKey[e_key].product_id
        );
        if (sr_index !== er_index) {
          const sr = sr_index + 1;
          const er = er_index + 1;
          let option = [];
          if (is_primary) {
            option = [
              ...option,
              { s: { r: sr, c: 3 }, e: { r: er, c: 3 } },
              { s: { r: sr, c: 9 }, e: { r: er, c: 9 } },
              { s: { r: sr, c: 10 }, e: { r: er, c: 10 } },
            ];
          }
          merge = [
            ...merge,
            ...option,
            { s: { r: sr, c: 0 }, e: { r: er, c: 0 } },
            { s: { r: sr, c: 1 }, e: { r: er, c: 1 } },
            { s: { r: sr, c: 2 }, e: { r: er, c: 2 } },
            { s: { r: sr, c: 4 }, e: { r: er, c: 4 } },
            { s: { r: sr, c: 5 }, e: { r: er, c: 5 } },
          ];
        }
        if (_.includes(["mfg_date", "exp_date"], n.key))
          return {
            ...res,
            [n.name]: data[n.key] && moment(data[n.key]).format("DD/MM/YYYY"),
          };
        return {
          ...res,
          [n.name]: data[n.key],
        };
      },
      {}
    )
  );
  return { result, merge };
};

const Index = ({ match, history }) => {
  const user = getUserObj();
  const dispatch = useDispatch();
  const [exportDocumentNo, setExportDocumentNo] = useState(null);
  const [isPlaning, setIsPlaning] = useState(false);
  const {
    warehouse,
    searchDocument: { branch_code, query, status, date, page, limit },
    user: { permission_status },
    fetch,
    ...inventoryCount
  } = useSelector((state) => state.inventoryCount);
  const type_params = match.params.type;
  const type_text = type_params === "daily" ? "ประจำวัน" : "ประจำรอบ";

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/inventory-count`,
      params: {
        count_type: type_params,
        branch_code,
        query,
        status,
        date: date ? moment(date).toDate() : "",
        limit: 10,
        offset: (page - 1) * limit,
      },
      token: true,
    },
    [type_params, branch_code, query, status, date, page]
  );

  const dataSource = data?.data.record || [];

  useEffect(() => {
    return () => {
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      const type = arr[2];
      if (path !== "inventory-count" || type !== type_params) {
        dispatch(clearPermission());
        dispatch(clear());
      }
    };
  }, []);

  useEffect(() => {
    if (data?.data.user) {
      dispatch(setGlobalState({ name: "user", value: { ...data.data.user } }));
    }
  }, [data?.data.user]);

  useEffect(() => {
    !_.includes(typeList, type_params) &&
      history.push({ pathname: "/inventory-count/daily" });
  }, [type_params]);

  useEffect(() => {
    if (exportDocumentNo && !fetch.document.loading) {
      if (fetch.document.error) {
        resCatch(fetch.document.error);
      } else {
        handleExportExcel();
      }
      setExportDocumentNo(null);
    }
  }, [fetch.document.loading, fetch.document.error, exportDocumentNo]);

  useEffect(() => {
    setIsPlaning(user.department_id === fixDepartment.operation_support);
  }, [user.department_id]);

  const handleExportExcel = () => {
    // map data column key property
    const product = [
      {
        name: "รหัสสินค้า",
        key: "product_code",
        wch: 10,
      },
      {
        name: "ชื่อสินค้า",
        key: "product_name",
        wch: 60,
      },
    ];
    const total = [
      {
        name: "ยอดรวม",
        key: "total_count",
        wch: 10,
      },
      {
        name: "ผลต่าง",
        key: "difference",
        wch: 10,
      },
    ];
    const remark = {
      name: "หมายเหตุ",
      key: "remark",
      wch: 40,
    };
    let wh = {
      primary_set: [
        ...product,
        {
          name: "คลังดี",
          key: "main",
          wch: 10,
        },
        {
          name: "ค้างจ่าย",
          key: "arrange",
          wch: 10,
        },
        {
          name: "ยอดจอง",
          key: "reserve",
          wch: 10,
        },
        {
          name: "รวม",
          key: "total_warehouse",
          wch: 10,
        },
        {
          name: "หน้าร้าน",
          key: "shelf",
          wch: 10,
        },
        {
          name: "ชั้นพัก",
          key: "storage_shelf",
          wch: 10,
        },
        {
          name: "ในคลัง",
          key: "warehouse",
          wch: 10,
        },
        ...total,
      ],
      primary: [],
      back: [
        ...product,
        {
          name: warehouse.itemByKey.back.name,
          key: "total_warehouse",
          wch: 10,
        },
        {
          name: "ยอดนับได้",
          key: "total_count",
          wch: 10,
        },
        ...total,
      ],
      distribute: [
        ...product,
        {
          name: warehouse.itemByKey.distribute.name,
          key: "total_warehouse",
          wch: 10,
        },
        {
          name: "ยอดนับได้",
          key: "total_count",
          wch: 10,
        },
        ...total,
      ],
      broken: [
        ...product,
        {
          name: warehouse.itemByKey.broken.name,
          key: "total_warehouse",
          wch: 10,
        },
        {
          name: "ยอดนับได้",
          key: "total_count",
          wch: 10,
        },
        ...total,
      ],
    };

    wh.primary = [...wh.primary_set];
    if (type_params === "cycle") {
      const cycle = [
        {
          name: "Lot.",
          key: "lot",
          wch: 10,
        },
        {
          name: "วันผลิต",
          key: "mfg_date",
          wch: 10,
        },
        {
          name: "วันหมดอายุ",
          key: "exp_date",
          wch: 10,
        },
      ];
      wh.primary = [...wh.primary, ...cycle];
      wh.back = [...wh.back, ...cycle];
      wh.distribute = [...wh.distribute, ...cycle];
      wh.broken = [...wh.broken, ...cycle];
    }
    wh.primary_set = [...wh.primary_set, remark];
    wh.primary = [...wh.primary, remark];
    wh.back = [...wh.back, remark];
    wh.distribute = [...wh.distribute, remark];
    wh.broken = [...wh.broken, remark];

    // filter excel data
    const primary_set = mapExcelData(inventoryCount, wh, "primary_set");
    const primary = mapExcelData(inventoryCount, wh, "primary");
    const back = mapExcelData(inventoryCount, wh, "back");
    const distribute = mapExcelData(inventoryCount, wh, "distribute");
    const broken = mapExcelData(inventoryCount, wh, "broken");

    // create excel sheet
    const wb = XLSX.utils.book_new();
    const primary_set_ws = XLSX.utils.json_to_sheet(primary_set.result);
    const primary_ws = XLSX.utils.json_to_sheet(primary.result);
    const back_ws = XLSX.utils.json_to_sheet(back.result);
    const distribute_ws = XLSX.utils.json_to_sheet(distribute.result);
    const broken_ws = XLSX.utils.json_to_sheet(broken.result);

    // merge row
    primary_set_ws["!merges"] = primary_set.merge;
    primary_ws["!merges"] = primary.merge;
    back_ws["!merges"] = back.merge;
    distribute_ws["!merges"] = distribute.merge;
    broken_ws["!merges"] = broken.merge;

    // set column width
    primary_set_ws["!cols"] = _.map(wh.primary_set, ({ wch }) => ({ wch }));
    primary_ws["!cols"] = _.map(wh.primary, ({ wch }) => ({ wch }));
    back_ws["!cols"] = _.map(wh.back, ({ wch }) => ({ wch }));
    distribute_ws["!cols"] = _.map(wh.distribute, ({ wch }) => ({ wch }));
    broken_ws["!cols"] = _.map(wh.broken, ({ wch }) => ({ wch }));

    // add data to sheet
    XLSX.utils.book_append_sheet(
      wb,
      primary_set_ws,
      warehouse.itemByKey.primary_set.name
    );
    XLSX.utils.book_append_sheet(
      wb,
      primary_ws,
      warehouse.itemByKey.primary.name
    );
    XLSX.utils.book_append_sheet(wb, back_ws, warehouse.itemByKey.back.name);
    XLSX.utils.book_append_sheet(
      wb,
      distribute_ws,
      warehouse.itemByKey.distribute.name
    );
    XLSX.utils.book_append_sheet(
      wb,
      broken_ws,
      warehouse.itemByKey.broken.name
    );

    // response file download
    XLSX.writeFile(
      wb,
      `inventory-count${moment().format("YYYY-MM-DD_HH-mm-ss")}.xlsx`
    );

    // clear data
    dispatch(clearStock());
  };

  if (error) return <Alert type="error" message={error} showIcon />;

  const renderEdit = ({ status, document_no }) => {
    // const user = getUserObj();
    
    if (!permission_status[status]) return;
    // disable status draft and return when user is operation support
    // if (
    //   _.includes(["draft", "return"], status) &&
    //   user.department_id === fixDepartment.operation_support
    // )
    //   return;
    if (status === "draft") {
      return (
        <>
          <Link to={`${type_params}/${document_no}`}>
            <Button
              size="small"
              shape="circle"
              className="bg-yellows border-yellows text-white mr-2"
            >
              <FaPencilAlt />
            </Button>
          </Link>
          <Button
            size="small"
            shape="circle"
            className="bg-nn border-nn text-white mr-2"
            onClick={() => {
              let source = axios.CancelToken.source();
              Modal.confirm({
                title: "ยืนยันการทำรายการ",
                content: (
                  <div>
                    <Text>คุณต้องการ</Text>
                    <Text underline className="mx-1 text-nn">
                      ลบใบนับ
                    </Text>
                    <Text strong className="mr-1">
                      {document_no}
                    </Text>
                    <Text>หรือไม่</Text>
                  </div>
                ),
                okText: "ยืนยัน",
                cancelText: "ยกเลิก",
                zIndex: 1080,
                onOk() {
                  return new Promise((resolve, reject) => {
                    axios({
                      method: "post",
                      url: `${URL_API}/inventory-count/cancel`,
                      headers: { Authorization: "Bearer " + getToken() },
                      data: {
                        document_no,
                        document_status: status,
                      },
                      cancelToken: source.token,
                    })
                      .then(() => {
                        resolve();
                        reload();
                        notification.success({
                          message: "Success",
                          description: `ลบใบนับ ${document_no} เรียบร้อย`,
                        });
                      })
                      .catch((e) => reject(e));
                  }).catch((e) => {
                    resCatchModal(e);
                  });
                },
                onCancel() {
                  source.cancel("cancel");
                },
              });
            }}
          >
            <FaTrash />
          </Button>
        </>
      );
    }
    if (status === "pending") {
      return (
        <Link to={`${type_params}/${document_no}`}>
          <Button
            size="small"
            shape="circle"
            className="bg-rp border-rp text-white mr-2"
          >
            <IoMdDocument />
          </Button>
        </Link>
      );
    }
    if (status === "return") {
      return (
        <Link to={`${type_params}/${document_no}`}>
          <Button
            size="small"
            shape="circle"
            className="bg-nn border-nn text-white mr-2"
          >
            <FaPencilAlt />
          </Button>
        </Link>
      );
    }
  };

  return (
    <Panel>
      <PanelHeader>การนับคลังสินค้า ({type_text})</PanelHeader>
      <PanelBody>
        {/* {!isPlaning && ( */}
          <Row>
            <Col span={24} className="text-right">
              <Link to={`${type_params}/0`}>
                <Button
                  type="primary"
                  disabled={branch_code === ""}
                  loading={branch_code === null}
                >
                  {branch_code !== null && <FaPlus className="mr-2" />}
                  สร้างใบนับ{type_text}
                </Button>
              </Link>
            </Col>
          </Row>
        {/* )} */}
        <Row gutter={8} type="flex" className="my-3 align-items-center">
          <Col span={24} md={12} xl={9}>
            <Input.Search
              placeholder="รหัสพนักงาน, ชื่อพนักงาน, เลขที่เอกสาร"
              onChange={(e) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: { query: e.target.value, page: 1 },
                  })
                )
              }
              allowClear
            />
          </Col>
          <Col span={0} xl={1} className="text-right">
            <Text className="text-nowrap">สาขา</Text>
          </Col>
          <Col span={24} md={12} xl={4} className="d-flex align-items-center">
            <SelectBranch
              placeholder="เลือกสาขา"
              withAll={true}
              userLogin={!isPlaning}
              value={branch_code ?? undefined}
              className="w-100"
              callBackData={() => {
                if (branch_code !== null) return;
                // planning default all
                if (isPlaning)
                  return dispatch(
                    setGlobalState({
                      name: "searchDocument",
                      value: {
                        branch_code: "",
                        branch_name: null,
                        page: 1,
                      },
                    })
                  );
                const user = getUserObj();
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      branch_code: user.branch_code,
                      branch_name: user.branch_name,
                      page: 1,
                    },
                  })
                );
              }}
              onChange={(branch_code, e) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      branch_code,
                      branch_name: e.props.children,
                      page: 1,
                    },
                  })
                )
              }
            />
          </Col>
          <Col span={0} xl={1} className="text-right">
            <Text className="text-nowrap">สถานะ</Text>
          </Col>
          <Col span={24} md={12} xl={4} className="d-flex align-items-center">
            <SelectMasterAllType
              processType="inventory_status"
              placeholder="เลือกสถานะ"
              withAll={true}
              value={status ?? undefined}
              callBackData={() => {
                if (status !== null) return;
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      status: "",
                      page: 1,
                    },
                  })
                );
              }}
              onChange={(e) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      status: e,
                      page: 1,
                    },
                  })
                )
              }
            />
          </Col>
          <Col span={0} xl={1} className="text-right">
            <Text className="text-nowrap">วันที่</Text>
          </Col>
          <Col span={24} md={12} xl={4} className="d-flex align-items-center">
            <DatePicker
              className="w-100 min-width-0"
              placeholder="เลือกวันที่"
              format="DD/MM/YYYY"
              value={date}
              onChange={(e) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      date: e,
                      page: 1,
                    },
                  })
                )
              }
            />
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={dataSource}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            total: data?.data.total || 0,
            showLessItems: true,
            hideOnSinglePage: true,
            onChange: (currPage) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: { page: currPage },
                })
              ),
          }}
        >
          <Column
            width={60}
            title="#"
            key="row_id"
            dataIndex="row_id"
            align="center"
          />
          <Column
            width={140}
            title="เลขที่เอกสาร"
            key="document_no"
            dataIndex="document_no"
            align="center"
          />
          {type_params === "daily" ? (
            <Column
              width={120}
              title="การนับประจำวันที่"
              key="document_date"
              dataIndex="document_date"
              align="center"
              render={(data) => <>{moment(data).format("DD/MM/YYYY")}</>}
            />
          ) : (
            <Column
              width={120}
              title="รอบการนับ"
              key="document_date"
              dataIndex="document_date"
              align="center"
              render={(data) => <>{moment(data).format("DD/MM/YYYY")}</>}
            />
          )}
          <Column
            width={180}
            className="position-relative"
            title={<div className="ant-table-absolute-title-center">สาขา</div>}
            key="branch_name"
            dataIndex="branch_name"
          />
          <Column
            width={dataSource.length === 0 ? 150 : undefined}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                ผู้ส่งรายงาน
              </div>
            }
            key="send_report_by_name"
            dataIndex="send_report_by_name"
            render={(text, { status }) => (
              <div className="min-width-100 max-width-400">
                <Text>{status !== "draft" ? text : "-"}</Text>
              </div>
            )}
          />
          <Column
            width={170}
            title="วันที่เวลาบันทึกข้อมูล"
            key="modify_date"
            dataIndex="modify_date"
            align="center"
            render={(text) => (
              <>{text && moment(text).format("DD/MM/YYYY HH:mm:ss")}</>
            )}
          />
          <Column
            width={120}
            className="position-relative"
            title={<div className="ant-table-absolute-title-center">สถานะ</div>}
            key="status_text"
            dataIndex="status_text"
            render={(text, { status }) => (
              <Text className={getClassNameStatus(status)}>{text}</Text>
            )}
          />
          <Column
            width={dataSource.length === 0 ? 150 : undefined}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">ผู้ตรวจสอบ</div>
            }
            key="consider_by_name"
            dataIndex="consider_by_name"
            render={(text, { status }) => (
              <div className="min-width-100 max-width-400">
                <Text className={getClassNameStatus(status)}>
                  {_.includes(["completed", "return"], status) ? text : "-"}
                </Text>
              </div>
            )}
          />
          <Column
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                <FaCogs />
              </div>
            }
            align="right"
            width={150}
            render={({ status, document_no }) => (
              <>
                {renderEdit({ status, document_no })}
                <Button
                  size="small"
                  shape="circle"
                  className="bg-tp border-tp text-white mr-2"
                  onClick={() =>
                    history.push({
                      pathname: `${type_params}/${document_no}`,
                      search: "?preview=1",
                    })
                  }
                >
                  <FaEye />
                </Button>
                <Button
                  size="small"
                  shape="circle"
                  loading={exportDocumentNo === document_no}
                  className="bg-sp border-sp text-white "
                  onClick={() => {
                    dispatch(
                      getInventoryCountByDocumentNo({
                        document_no,
                        is_export: true,
                      })
                    );
                    setExportDocumentNo(document_no);
                  }}
                >
                  {exportDocumentNo !== document_no && <MdFileDownload />}
                </Button>
              </>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default Index;
