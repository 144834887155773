/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Table } from "antd";
import React, { useContext, useEffect } from "react";
import BranchContext from "../../../../../../contexts/branch.context";
import { useApi } from "../../../../../../hooks/http2";
import { FaCog, FaCogs } from "react-icons/fa";

const TableBranch = () => {
    const { state, dispatch } = useContext(BranchContext);
    const { loading, fetch, error, reload } = useApi(
        {
            url: "/setting/master/branch",
            params: {
                ...state.filter,
                offset: (state.filter.page - 1) * state.filter.limit,
            },
        },
        [state.filter]
    );

    useEffect(() => {
        if (state.reload === true) {
            reload();
            dispatch({ type: "SET_RELOAD", payload: false });
        }
    }, [state.reload]);

    if (error) {
        return <Alert type="error" description={error} message="ผิดพลาด" />;
    }

    return (
        <Table
            loading={loading}
            dataSource={
                fetch?.data
                    ? fetch?.data?.map((n, i) => ({ ...n, key: i }))
                    : []
            }
            size="small"
            pagination={{
                size: "default",
                current: state.filter.page,
                pageSize: state.filter.limit,
                showTotal: (total, range) =>
                    `${range[0]} - ${range[1]} จาก ${total}`,
                onChange: (page) =>
                    dispatch({ type: "SET_FILTER_VALUE", payload: { page } }),
                showLessItems: true,
            }}
            bordered
        >
            <Table.Column title="#" dataIndex="row_id" align="center" />
            <Table.Column title="รหัสสาขา" dataIndex="code" align="center" />
            <Table.Column title="ชื่อสาขา" dataIndex="name" />
            <Table.Column
                title="ประเภท"
                dataIndex="branch_type"
                align="center"
                render={(text) => (text === "normal" ? "ปกติ" : "Pick and Pay")}
            />
            <Table.Column
                title={<FaCogs />}
                align="center"
                render={(text, { code }) => (
                    <Button
                        size="small"
                        shape="circle-outline"
                        type="primary"
                        onClick={() =>
                            dispatch({
                                type: "SET_EDIT",
                                payload: { code },
                            })
                        }
                    >
                        <FaCog />
                    </Button>
                )}
            />
        </Table>
    );
};

export default TableBranch;
