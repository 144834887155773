import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { fixBranch } from "../../../../../config/fix-value";
import { _ } from "../../../../../utils";
import AuthorityDocument from "./AuthorityDocument";
import BranchDocument from "./BranchDocument";
import ClaimType from "./ClaimType";
import Remark from "./Remark";
import SelectMember from "./SelectMember";

const Index = () => {
  const isOnlineWait = useSelector((state) => {
    const { user_branch_code, status } = state.claim.documentDetail;
    return (
      _.includes(fixBranch.online, user_branch_code) &&
      _.includes([null, "draft"], status)
    );
  });
  const disabled = useSelector(
    (state) =>
      state.claim.isPreview ||
      !_.includes([null, "draft", "waiting"], state.claim.documentDetail.status)
  );

  return (
    <Row gutter={[8, 8]} type="flex">
      <Col
        span={24}
        order={2}
        md={{ order: 1, span: 12 }}
        xxl={{ order: 1, span: 8 }}
      >
        <SelectMember
          type="claimBy"
          title="ผู้ทำรายการเคลม"
          disabled={disabled}
        />
        <AuthorityDocument disabled={disabled} />
        <Remark disabled={disabled} isOnlineWait={isOnlineWait} />
      </Col>
      <Col
        span={24}
        order={3}
        md={{ order: 3, span: 12 }}
        xxl={{ order: 2, span: 8 }}
      >
        <SelectMember
          type="partner"
          title="เจ้าของใบเสร็จ"
          disabled={disabled}
        />
        <ClaimType disabled={disabled} />
      </Col>
      <Col
        span={24}
        order={1}
        md={{ order: 2, span: 12 }}
        xxl={{ order: 3, span: 8 }}
      >
        <BranchDocument disabled={disabled} isOnlineWait={isOnlineWait} />
      </Col>
    </Row>
  );
};

export default Index;
