/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Spin,
  Statistic,
  Table,
  Typography,
  notification,
} from "antd";
import numeral from "numeral";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { FaCogs, FaEye, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { useApi } from "../../../../hooks/http2";
import {
  actionStoreQrTransfer,
  formSelector,
} from "../../../../redux/slices/store-qr/transfer";
import {
  URL_API,
  _,
  axios,
  getToken,
  getUser,
  moment,
} from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import SelectBranch from "../components/SelectBranch";

const TransferForm = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [main_qr, setMainQr] = useState("");
  const [qr, setQr] = useState("");
  const [view, setView] = useState(true);
  const dispatch = useDispatch();
  const form = useSelector(formSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    CLEAR_FORM,
    SET_FORM,
    SELECT_QR,
    LOAD_FORM,
    DELETE_FROM_LIST,
    CLEAR_FROM_LIST,
  } = actionStoreQrTransfer;
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const limit = 10;

  const loadData = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: `store-qr/transfer/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        let user = getUser();
        if (res.data.data.branch_code != user.branch_code) {
          setView(true);
        }
        dispatch(LOAD_FORM(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadProducts = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/set/items`,
      token: true,
      params: {
        qr_code: main_qr,
        branch_code: form.from_branch_code,
      },
    },
    [main_qr]
  );

  const showModal = (main_qr) => {
    setMainQr(main_qr);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOnSubmit = (type) => {
    if (form.from_branch_code === "") {
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณาเลือกรับจากสาขา",
      });
    }
    if (form.list.itemKeys.length == 0) {
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณาเพิ่มรายการชุดเซ็ตที่จะทำการโอน",
      });
    }

    let user = getUser();
    console.log(user.branch_code);
    Modal.confirm({
      title: "ยืนยัน",
      content:
        type === "draft"
          ? "คุณต้องการบันทึกเอกสารร่างหรือไม่"
          : "คุณต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          let data = {
            ...form,
            list: _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]),
            status: type,
            to_branch_code: user.branch_code,
            to_branch_name: user.branch_name,
          };

          axios({
            method: "post",
            baseURL: URL_API,
            url: `store-qr/transfer${
              match.params.id === "0" ? "" : `/${match.params.id}`
            }`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
          })
            .then((res) => {
              resolve("success");
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกเรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.push(`/store-qr/transfer`);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleKeyUpQR = (e) => {
    if (form.from_branch_code === "") {
      return Modal.warning({
        title: "ไม่สามารถทำรายการได้",
        content: "กรุณาเลือกรับจากสาขา",
        okText: "ปิด",
        onOk() {
          setQr("");
        },
      });
    }

    if (e.keyCode === 13) {
      axios({
        method: "get",
        baseURL: URL_API,
        url: `store-qr/set/qr-code`,
        headers: { Authorization: "Bearer " + getToken() },
        params: { qr_code: qr },
      })
        .then((res) => {
          if (res.data.transferData) {
            if (
              res.data.transferData.branch_code != form.from_branch_code &&
              res.data.transferData.status == "active"
            ) {
              return notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: "QR Code นี้ไม่ได้อยู่ในสาขาที่เลือก",
              });
            }
            if (res.data.transferData.status == "draft") {
              return notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: `QR Code นี้บันทึกเป็นเอกสารร่างของสาขา ${res.data.transferData.branch_name}`,
              });
            }
          }

          if (res.data.data) {
            if (res.data.data.branch_code != form.from_branch_code) {
              return notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: "QR Code นี้ไม่ได้อยู่ในสาขาที่เลือก",
              });
            }

            let params = qs.parseUrl(qr);
            let data = res.data.data;

            let list = _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]);

            let find_dup = _.find(
              list,
              (n) => n.main_qr === qr && (params.query?.LgcSN || "") !== ""
            );
            if (find_dup) {
              notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: "QR Code นี้เคยถูกสแกนแล้ว",
              });
            } else {
              dispatch(SELECT_QR(data));
            }
          } else {
            Modal.warning({
              title: "ผิดพลาด",
              content: "ไม่พบ QR Code นี้ในฐานข้อมูล หรือ ชุดเซ็ตถูกขายไปแล้ว",
              cancelText: "ปิด",
            });
          }

          setQr("");
        })
        .catch((err) => {
          Modal.error({
            title: "ผิดพลาด",
            content:
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message,
            cancelText: "ปิด",
          });
        });
    }
  };
  const handleSelectBranch = (branch_code) => {
    dispatch(SET_FORM({ from_branch_code: branch_code }));
    let user = getUser();
    if (branch_code == user.branch_code) {
      dispatch(
        CLEAR_FORM({
          create_date: moment().format("YYYY-MM-DD HH:mm"),
          create_by: user.name,
          branch_name: user.branch_name,
        })
      );
      notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "ไม่สามารถโอนย้ายภายในสาขาเดียวกันได้",
      });
    }
    dispatch(CLEAR_FROM_LIST());
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันการลบข้อมูล",
      content: "คุณต้องการที่จะลบข้อมูลการโอนชุดเซ็ตนี้หรือไม่?",
      okText: "ลบข้อมูล",
      cancelText: "ปิด",

      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "delete",
            baseURL: URL_API,
            url: `store-qr/transfer/${id}`,
            headers: { Authorization: "Bearer " + getToken() },
          })
            .then((res) => {
              resolve(console.log(res.mes));
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบเรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.push(`/store-qr/transfer`);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(
    () => {
      let user = getUser();
      if (match.params.id != "0") {
        loadData(match.params.id);
      } else {
        dispatch(
          CLEAR_FORM({
            create_date: moment().format("YYYY-MM-DD HH:mm"),
            create_by: user.name,
            branch_name: user.branch_name,
          })
        );
        setLoading(false);
      }
      setView(match.params.type == "view");
    },
    [match.params.id],
    [match.params.type]
  );

  return (
    <Panel>
      <PanelHeader>โอนย้ายสินค้าจัดเซ็ต</PanelHeader>
      <PanelBody>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "24px 0px",
            }}
          >
            <Spin tip="Loading" size="small" />
          </div>
        ) : (
          <>
            <Row gutter={16} className="d-flex align-items-center mb-2">
              <Col md={2} className="text-right">
                <Typography.Text>รับจากสาขา:</Typography.Text>
              </Col>
              <Col md={4}>
                {!view ? (
                  <SelectBranch
                    className="w-100"
                    withAll
                    allText="เลือกรับจากสาขา"
                    value={form.from_branch_code}
                    onChange={(e) => handleSelectBranch(e)}
                  />
                ) : (
                  <SelectBranch
                    className="w-100"
                    withAll
                    allText="เลือกรับจากสาขา"
                    value={form.from_branch_code}
                    onChange={(e) => handleSelectBranch(e)}
                    disabled
                  />
                )}
              </Col>
              <Col md={18} className="text-right">
                <Typography.Text strong>ผู้รับสินค้า:</Typography.Text>
                <Typography.Text className="ml-2 mr-3">
                  {form.create_by}
                </Typography.Text>
                <Typography.Text strong>สาขาที่รับ:</Typography.Text>
                <Typography.Text className="ml-2 mr-3">
                  {form.branch_name}
                </Typography.Text>
                <Typography.Text strong>วันที่รับ:</Typography.Text>
                <Typography.Text className="ml-2">
                  {moment(form.create_date).format("DD/MM/YYYY HH:mm")}
                </Typography.Text>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col md={16}>
                <Row gutter={8} className="d-flex align-items-center mb-2">
                  <Col md={15}>
                    {!view && (
                      <Input
                        className="w-100"
                        placeholder="สแกน QR Code ชุดหลัก"
                        onKeyUp={handleKeyUpQR}
                        value={qr}
                        onChange={(e) =>
                          setQr(e.target.value.split(" ").join(""))
                        }
                        allowClear
                      />
                    )}
                  </Col>
                </Row>
                <Row gutter={8} className="d-flex align-items-center mb-2">
                  <Table
                    size="small"
                    bordered
                    dataSource={_.map(form.list.itemKeys, (n) => ({
                      ...form.list.itemByKey[n],
                      key: n,
                    }))}
                    pagination={false}
                  >
                    <Table.Column
                      title="No."
                      align="right"
                      key="no"
                      render={(text, record, index) => index + 1}
                    />
                    <Table.Column
                      title="รหัสสินค้า"
                      align="center"
                      dataIndex="product_code"
                    />
                    <Table.Column
                      title="ชื่อชุดเซ็ต"
                      align="center"
                      dataIndex="product_name"
                    />
                    <Table.Column
                      title="Running (หลัก)"
                      align="center"
                      dataIndex="main_running"
                    />
                    <Table.Column
                      title="สินค้าที่หมดอายุเร็วที่สุด"
                      align="center"
                      dataIndex="min_exp"
                    />
                    <Table.Column
                      title={<FaCogs />}
                      align="center"
                      className="text-nowrap"
                      key="action"
                      render={(text, record) => (
                        <>
                          <Button
                            type="primary"
                            size="small"
                            shape="circle-outline"
                            onClick={() => showModal(record.main_qr)}
                          >
                            <FaEye />
                          </Button>
                          {!view && (
                            <Button
                              size="small"
                              type="danger"
                              shape="circle-outline"
                              className="ml-2"
                              onClick={() =>
                                dispatch(DELETE_FROM_LIST(record.key))
                              }
                            >
                              <FaTrashAlt />
                            </Button>
                          )}
                        </>
                      )}
                    />
                  </Table>
                  <Modal
                    title="รายการสินค้าในชุดเซ็ค"
                    onOk={handleOk}
                    onCancel={handleCancel}
                    visible={isModalVisible}
                    footer={null}
                    width={1000}
                  >
                    <Table
                      bordered
                      size="small"
                      loading={loadProducts.loading}
                      //dataSource={loadProducts.fetch?.data || []}
                      dataSource={_.map(
                        loadProducts.fetch?.data || [],
                        (n) => ({
                          ...n,
                          key: _.uniqueId("product-"),
                        })
                      )}
                      scroll={{ x: "max-content" }}
                      pagination={{
                        current: page,
                        pageSize: limit,
                        total: loadProducts.fetch?.data
                          ? loadProducts.fetch?.data.length
                          : 10,
                        size: "default",
                        onChange: (currPage) => setPage(currPage),
                      }}
                    >
                      <Table.Column
                        title="ลำดับ"
                        dataIndex="row_id"
                        align="center"
                      />
                      <Table.Column
                        title="รหัสสินค้า"
                        dataIndex="product_code"
                        align="center"
                      />
                      <Table.Column
                        title="ชื่อสินค้า"
                        dataIndex="product_name"
                        align="center"
                      />
                      <Table.Column
                        title="Running"
                        dataIndex="running"
                        align="center"
                      />
                      <Table.Column
                        title="Lot."
                        dataIndex="lot"
                        align="center"
                      />
                      <Table.Column
                        title="วันที่ผลิตสินค้า"
                        dataIndex="production_date"
                        align="center"
                        render={(text) => moment(text).format("DD/MM/YYYY")}
                      />
                      <Table.Column
                        title="วันหมดอายุสินค้า"
                        dataIndex="expiration_date"
                        align="center"
                        render={(text) => moment(text).format("DD/MM/YYYY")}
                      />
                    </Table>
                  </Modal>
                </Row>
              </Col>
              <Col md={8}>
                <Row gutter={8} className="d-flex align-items-center mb-2">
                  <Col>
                    <Typography.Title level={3} className="mb-0">
                      สรุปจำนวนการรับ
                    </Typography.Title>
                  </Col>
                </Row>
                <Table
                  size="small"
                  bordered
                  dataSource={_.map(
                    _.uniq(
                      _.map(
                        form.list.itemKeys,
                        (n) => form.list.itemByKey[n]?.product_code
                      )
                    ),
                    (n) => {
                      let list = _.map(
                        form.list.itemKeys,
                        (m) => form.list.itemByKey[m]
                      );

                      let filter_Product = _.filter(
                        list,
                        (m) => m.product_code === n
                      );

                      return {
                        product_code: filter_Product[0]?.product_code,
                        product_name: filter_Product[0]?.product_name,
                        qty: filter_Product.length,
                        key: _.uniqueId("summary-"),
                      };
                    }
                  )}
                  pagination={false}
                >
                  <Table.Column
                    title="รหัสสินค้า"
                    dataIndex="product_code"
                    align="center"
                  />
                  <Table.Column title="ชื่อชุดเซ็ต" dataIndex="product_name" />
                  <Table.Column title="จำนวน" dataIndex="qty" align="right" />
                </Table>

                <Row gutter={16} className="my-3">
                  <Col className="text-center">
                    <Statistic
                      title="จำนวนรวม"
                      formatter={(value) => numeral(value).format()}
                      value={form.list.itemKeys.length}
                      suffix={` เซ็ต`}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  {!view && (
                    <Col className="text-left">
                      {form.status == "draft" && (
                        <Button
                          type="danger"
                          className="mr-4"
                          onClick={() => handleDelete(match.params.id)}
                        >
                          ลบข้อมูล
                        </Button>
                      )}
                      <Button
                        type="dashed"
                        className="mr-2"
                        onClick={() => handleOnSubmit("draft")}
                      >
                        เอกสารร่าง
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleOnSubmit("active")}
                      >
                        ยืนยันยอดรับ
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>

            <Divider />
          </>
        )}
      </PanelBody>
    </Panel>
  );
};

export default TransferForm;
