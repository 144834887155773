/* eslint-disable no-unused-vars */
import { Table } from "antd";
import moment from "moment";
import React, { useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import FilterList from "../../components/FilterList";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const limit = 20;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-payment`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page, state.filter]
  );

  let newData = data ? data : [];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสรุปคะแนน</PanelHeader>

        <PanelBody>
          <FilterList
            /*exportToExcel={""}*/ placeholder="คะแนน"
            reload={reload}
          />
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={newData.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              // total: newData.newData.length ,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="เดือน" dataIndex="" align="center" />
            <Table.Column title="คะแนนที่เกิด" dataIndex="" align="right" />
            <Table.Column title="คะแนนพิเศษ" dataIndex="" align="right" />
            <Table.Column title="คะแนนยกเลิก" dataIndex="" align="right" />
            <Table.Column title="คะแนนคงเหลือ" dataIndex="" align="right" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
