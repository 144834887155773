import React from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../Error404";

import Award from "./award/routes";
import BannerRoutes from "./banner/routes";
import BannerProductRoutes from "./banner-product-category/routes";
import BS from "./benefits-and-subscriptions/routes";
import Contact_Legacy from "./contact-legacy/routes";
import EcoSystem from "./ecosystem/routes";
import EthicsForm from "./ethics/EthicsForm";
import FAQ from "./faq/routes";
import Home from "./home/routes";
import LegacyMusic from "./legacy-music/LegacyMusic";
import News from "./news/routes";
import ManageFile from "./manage-file/routes";
// import HomeBannerPage from './pages/home/HomeBannerPage';

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/award`} component={Award} />
      <Route path={`${match.path}/home`} component={Home} />
      {/* <Route path={`${match.path}/home-banner`} component={HomeBannerPage} /> */}
      <Route path={`${match.path}/benefits-and-subscriptions`} component={BS} />
      <Route path={`${match.path}/contact-legacy`} component={Contact_Legacy} />
      <Route path={`${match.path}/ecosystem`} component={EcoSystem} />
      <Route path={`${match.path}/ethics`} component={EthicsForm} />
      <Route path={`${match.path}/faq`} component={FAQ} />
      <Route path={`${match.path}/news`} component={News} />
      <Route path={`${match.path}/legacy-music`} component={LegacyMusic} />

      <Route path={`${match.path}/banner`} component={BannerRoutes} />
      <Route
        path={`${match.path}/banner-product-category`}
        component={BannerProductRoutes}
      />
      <Route path={`${match.path}/manage-file`} component={ManageFile} />
      <Route component={Error404} />
    </Switch>
  );
};
