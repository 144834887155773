/* eslint-disable no-unused-vars */
import { Button, Modal, notification, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { initClaimError } from "../../../../../redux/logic/claim";
import { actionClaim } from "../../../../../redux/slices/claim";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";

const { Text } = Typography;

const { setGlobalState, setState } = actionClaim;

const ManagerConsider = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const receiptType = useSelector((state) => state.claim.receiptType);
  const document_no = useSelector(
    (state) => state.claim.documentDetail.document_no
  );
  const loadingStock = useSelector(
    (state) => state.claim.fetch.stock_balance.loading
  );
  const document_status = useSelector(
    (state) => state.claim.documentDetail.status
  );
  const remark = useSelector((state) => state.claim.remark.manager);
  const claimOutProduct = useSelector((state) => {
    const { itemKeys, itemByKey } = state.claim.claimOutProduct;
    return _.map(itemKeys, (key) => itemByKey[key]);
  });
  const claimOutVoucher = useSelector((state) => {
    const { itemKeys, itemByKey } = state.claim.claimOutVoucher;
    return _.map(itemKeys, (key) => itemByKey[key]);
  });
  const isSomeSoldOut = useSelector((state) => {
    const { itemKeys, itemByKey } = state.claim.claimOutProduct;
    return _.some(itemKeys, (key) => itemByKey[key].stock_balance <= 0);
  });
  const handleSubmit = (consider_status) => {
    let data_text, text_style;
    switch (consider_status) {
      case "approve":
        data_text = "อนุมัติเคลมสินค้า";
        text_style = "text-pv";
        break;
      case "reject":
        data_text = "ไม่อนุมัติเคลมสินค้า";
        text_style = "text-nn";
        break;
      default:
        return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(setState({ name: "loadingSubmit", value: true }));
        axios({
          method: "post",
          url: `${URL_API}/claim/consider/manager`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            status: "considered",
            consider_status,
            document_no,
            document_status,
            receipt: receiptType,
            // remark: remark?.trim() || null,
            remark: remark?.remark === null ? null : remark,
            products: claimOutProduct,
            vouchers: claimOutVoucher,
          },
        })
          .then(() => {
            notification.success({
              message: `บันทึก${data_text}แล้ว`,
            });
            history.push({
              pathname: `/${_.split(match.path, "/")[1]}/${
                match.params.process
              }`,
            });
          })
          .catch((e) => resCatchModal(e))
          .finally(() =>
            dispatch(setState({ name: "loadingSubmit", value: false }))
          );
      },
    });
  };

  const handleClickSubmit = (state) => {
    if (state === "approve" && claimOutProduct.length > 0) {
      console.log("claimOutProduct", claimOutProduct);
      // ดึงรายการสินค้าทั้งหมดใน claimOutProduct มาเช็ค stock
      const stock_item = [];
      for (let i = 0; i < claimOutProduct.length; i++) {
        const { product_code, bom, stock_balance, qty } = claimOutProduct[i];
        if (bom.length > 0) {
          for (let j = 0; j < bom.length; j++) {
            stock_item.push({
              product_code: bom[j].product_code,
              qty: bom[j].claim_qty,
              stock_balance: bom[j].stock_balance,
            });
          }
        } else {
          stock_item.push({
            product_code,
            qty,
            stock_balance,
          });
        }
      }
      console.log("stock_item", stock_item);
      // ถ้า product_code ใน stock_item ซ้ำกัน ให้ sum qty
      const group_product_code = Object.values(
        stock_item.reduce(
          (accumulator, { product_code, qty, stock_balance }) => {
            accumulator[product_code] = accumulator[product_code] || {
              product_code,
              qty: 0,
              stock_balance,
            };
            accumulator[product_code].qty += qty;
            return accumulator;
          },
          {}
        )
      );
      // ถ้า not_enough !== undefined แสดงว่ามีสินค้าไม่พอจำนวนที่เคลม
      const not_enough = _.find(
        group_product_code,
        (n) => n.stock_balance !== 0 && n.stock_balance < n.qty
      );

      let bomStockList = claimOutProduct.map(
        (n) => n.bom.length > 0 && n.bom.map((m) => m.stock_balance)
      );
      let someBomSoldOut = _.includes(bomStockList, 0);
      let ce = {
        ...initClaimError,
      };
      if (!receiptType.req_receipt_type)
        ce.req_receipt_type_error = "กรุณาเลือกประเภทการรับของ";
      if (
        receiptType.req_receipt_type === "Branch" &&
        !receiptType.receipt_branch_code
      )
        ce.receipt_branch_code = "กรุณาเลือกสาขาที่รับ";
      if (
        receiptType.req_receipt_type === "Transport" &&
        _.includes([null, undefined], receiptType.ship_to_id)
      )
        ce.ship_to_id = "กรุณาเลือกที่อยู่";
      dispatch(setGlobalState({ name: "error", value: ce }));
      let description = null;
      for (const key in ce) {
        const e = ce[key];
        if (e) {
          description = "กรุณาตรวจสอบรายการ";
          break;
        }
      }
      if (!description) {
        if (loadingStock) {
          description = "กำลังโหลดข้อมูลสินค้าที่ได้รับ";
        } else if (not_enough !== undefined) {
          description = `รายการสินค้า ${not_enough?.product_code} คงเหลือ ${not_enough?.stock_balance} ชิ้น`;
        }
        // else if (isSomeSoldOut) {
        //   description = "ไม่มีสินค้าที่ได้รับบางรายการ (sold out)";
        // } else if (someBomSoldOut) {
        //   description = "ไม่มีสินค้าย่อยที่ได้รับบางรายการ (sold out)";
        // }
      }
      if (description)
        return notification.warning({
          message: "ไม่สามารถดำเนินการได้",
          description,
        });
    }
    handleSubmit(state);
  };

  return (
    <>
      <Button
        className="bg-nn border-nn text-white width-100"
        onClick={() => handleClickSubmit("reject")}
      >
        ไม่อนุมัติ
      </Button>
      <Button
        type="primary"
        className="width-100 ml-3"
        onClick={() => handleClickSubmit("approve")}
      >
        อนุมัติ
      </Button>
    </>
  );
};

export default ManagerConsider;
