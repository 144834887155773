/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Button, Modal, Table, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { FaCog, FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useApi } from "../../../../hooks/http2";
import {
  actionBlackList,
  searchSelector,
} from "../../../../redux/slices/blacklist";
import { getToken } from "../../../../utils";
import { URL_API } from "../../../../utils/index";
import { AlertError, AlertSuccess } from "../../../util/Alert.js";
import { useHttp } from "../../../../hooks/http.js";
export default function BlackListTable({ setListData }) {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const search = useSelector(searchSelector);
  const { SET_SEARCH, SET_OPEN_MODAL } = actionBlackList;
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/blacklist`,
      params: {
        ...search,
        date_from : search.date_from ?  moment(search.date_from).format("YYYY-MM-DD") : null,
        date_to : search.date_to ? moment(search.date_to).format("YYYY-MM-DD") : null,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );
  useEffect(() => {
    if (data) {
      setListData(data.data);
    }
  }, [data]);
  const hiddleEdit = async (id) => {
    const result = await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/blacklist/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    });
    if (result.data.status == 200) {
      dispatch(SET_OPEN_MODAL(result.data.data));
    }
  };
  const hiddleDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันบันทึกข้อมูล",
      content: "ต้องการลบ BlackList",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        axios({
          method: "POST",
          baseURL: URL_API,
          url: `/blacklist/delete-blacklist/${id}`,
          headers: { Authorization: "Bearer " + getToken() },
        })
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              AlertSuccess(res.data.message);
              history.push(match.url);
            } else {
              AlertError({ title: "เกิดข้อผิดพลาด", text: res.data.message });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  };
  return (
    <>
      <Table
        bordered
        size="small"
        loading={loading}
        dataSource={data?.data || []}
        pagination={{
          current: search.page,
          pageSize: search.limit,
          total: data?.total || 0,
          showLessItems: true,
          size: "default",
          onChange: (page) => dispatch(SET_SEARCH({ page: page })),
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
        }}
      >
        <Table.Column title="No" key="no" align="right" dataIndex="RowId" />
        <Table.Column title="ชื่อ-นามสกุล" dataIndex="Fullname" />
        <Table.Column
          title="ประเภท"
          render={({ MemberType }) =>
            MemberType == null
              ? ""
              : MemberType === "staff"
              ? "พนักงาน"
              : "สมาชิกเลกาซี"
          }
        />
        <Table.Column title="รหัสบัตรประชาชน / Passport" dataIndex="IDCard" />
        <Table.Column
          title="วันที่ดำเนินการ"
          render={({ StartDate }) =>
            StartDate && moment(StartDate).format("DD/MM/YYYY")
          }
        />
        <Table.Column
          title="วันที่สมัครใหม่ได้"
          render={({ EndDate }) =>
            EndDate && moment(EndDate).format("DD/MM/YYYY")
          }
        />
        <Table.Column
          title="ระยะเวลา"
          render={({ StartDate,EndDate }) =>
          moment(EndDate).startOf('day')
          .diff(moment(StartDate).startOf('day'), "days")+"วัน"
            // moment(EndDate).diff(moment().startOf("day"), "days") + " วัน"
          }
        />
        <Table.Column
          title={<FaCog />}
          align="center"
          render={({ Id }) => (
            <>
              <Tooltip title="แก้ไขข้อมูล">
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-tp border-tp mr-1"
                  onClick={() => hiddleEdit(Id)}
                >
                  <FaEdit />
                </Button>
              </Tooltip>
              <Tooltip title="ลบข้อมูล">
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-nn border-nn mr-1"
                  onClick={() => hiddleDelete(Id)}
                >
                  <FaTrash />
                </Button>
              </Tooltip>
            </>
          )}
        />
      </Table>
    </>
  );
}
