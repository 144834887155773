/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { memo } from "react";
import { FaCogs, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { moment, _ } from "../../../../utils";
import { enterFocusNext } from "../../../util/helper";
import { numInt } from "../../../util/helper/map-data";

const { Column } = Table;
const { Text } = Typography;

const { setProduct, addProductLot, delProductLot, setGlobalState } =
  actionTransfer;

const isDisable = ({ disabled, is_transfer, record }) => {
  const { new_receive_qty, diff_new_receive } = record;
  return disabled || (!is_transfer && !new_receive_qty && !diff_new_receive);
};

const ProductTable = ({ disabled }) => {
  const dispatch = useDispatch();
  const { is_some, selected, dataSource } = useSelector((state) => {
    const { itemKeys, itemByKey, is_some, selected } = state.transfer.product;
    return {
      is_some,
      selected,
      dataSource: _.map(itemKeys, (key) => itemByKey[key]),
    };
  });
  const { type: type_params, process: process_params } = useParams();
  const is_transfer = type_params === "transfer";
  const is_doc_complete = process_params === "complete";

  const handleChange = (name, value, key) => {
    dispatch(
      setProduct({
        name,
        value,
        key,
      })
    );
  };

  return (
    <>
      {!disabled && (
        <Row gutter={[8, 8]} className="mt-2">
          <Col span={24} className="text-right">
            {/* {!is_transfer && (
              <>
                <Text className="mr-3" strong>
                  รับบางส่วน
                </Text>
                <Switch
                  checked={is_some}
                  onChange={(is_some) =>
                    dispatch(
                      setGlobalState({
                        name: "product",
                        value: { is_some, selected: [] },
                      })
                    )
                  }
                />
              </>
            )} */}
            {is_transfer && (
              <Button
                type="primary"
                className="width-150 ml-3"
                onClick={() =>
                  dispatch(
                    setGlobalState({
                      name: "productModal",
                      value: { visible: true },
                    })
                  )
                }
              >
                <FaPlus className="mr-2" />
                เพิ่มสินค้า
              </Button>
            )}
          </Col>
        </Row>
      )}
      <Row className="my-3">
        <Col span={24}>
          <Table
            bordered
            size="small"
            dataSource={dataSource}
            rowKey="key"
            className="ant-table-pagination-center table-very-small with-input"
            scroll={{ x: "max-content" }}
            pagination={false}
            rowSelection={
              is_some
                ? {
                    selectedRowKeys: selected,
                    onChange: (selectedRowKeys) =>
                      dispatch(
                        setGlobalState({
                          name: "product",
                          value: { selected: selectedRowKeys },
                        })
                      ),
                    getCheckboxProps: (record) => ({
                      disabled: isDisable({ disabled, is_transfer, record }),
                    }),
                  }
                : null
            }
          >
            <Column
              width={70}
              title="รหัสสินค้า"
              key="product_code"
              dataIndex="product_code"
              align="center"
              render={(text, { line_number }) =>
                line_number === 1 && <Text>{text}</Text>
              }
            />
            <Column
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ชื่อสินค้า
                </div>
              }
              key="product_name"
              dataIndex="product_name"
              render={(text, { line_number }) =>
                line_number === 1 && <Text>{text}</Text>
              }
            />
            {is_transfer ? (
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    จำนวนคงเหลือ
                  </div>
                }
                key="balance"
                dataIndex="balance"
                align="right"
                render={(text, { line_number }) =>
                  line_number === 1 && <Text strong>{numInt(text)}</Text>
                }
              />
            ) : (
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    ยอดรวมโอน
                  </div>
                }
                key="total_qty"
                dataIndex="total_qty"
                align="right"
                render={(text, record) => {
                  const obj = {
                    children: <Text strong>{numInt(text)}</Text>,
                    props: {},
                  };
                  if (record.line_number === 1) {
                    obj.props.rowSpan = _.filter(
                      dataSource,
                      (n) => n.product_code === record.product_code
                    ).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                }}
              />
            )}
            {!is_transfer && (
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">ยอดโอน</div>
                }
                key="qty"
                dataIndex="qty"
                align="right"
              />
            )}
            {!disabled && is_transfer && (
              <Column
                title={<FaCogs />}
                align="center"
                width={30}
                key="action-add"
                render={(_text, { product_code, line_number, key }) =>
                  line_number === 1 && (
                    <Popconfirm
                      title={`ต้องการเพิ่ม Lot รหัสสินค้า ${product_code} หรือไม่`}
                      onConfirm={() => dispatch(addProductLot(key))}
                      okText="ตกลง"
                      cancelText="ยกเลิก"
                      placement="top"
                      zIndex={1080}
                    >
                      <Button size="small" ghost type="primary">
                        <FaPlus size={10} />
                      </Button>
                    </Popconfirm>
                  )
                }
              />
            )}
            {is_transfer && (
              <Column
                width={80}
                className="px-1"
                title="จำนวนโอน"
                key="qty"
                dataIndex="qty"
                align="center"
                render={(text, { key }) => (
                  <InputNumber
                    size="small"
                    disabled={disabled}
                    min={1}
                    value={text}
                    onFocus={(e) => e.target.select()}
                    onKeyUp={enterFocusNext}
                    onChange={(value) =>
                      !_.isNaN(+value) && handleChange("qty", value, key)
                    }
                  />
                )}
              />
            )}
            <Column
              width={150}
              className="px-1"
              title="Lot."
              key="lot"
              dataIndex="lot"
              align="center"
              render={(text, { key, ...record }) => (
                <Input
                  size="small"
                  autoComplete="off"
                  disabled={isDisable({ disabled, is_transfer, record })}
                  value={text}
                  maxLength={100}
                  onKeyUp={enterFocusNext}
                  onChange={(e) => handleChange("lot", e.target.value, key)}
                />
              )}
            />
            <Column
              width={100}
              className="px-1"
              title="วันผลิต"
              key="mfg_date"
              dataIndex="mfg_date"
              align="center"
              render={(text, { key, ...record }) => (
                <DatePicker
                  size="small"
                  placeholder=""
                  format="DD/MM/YYYY"
                  disabled={isDisable({ disabled, is_transfer, record })}
                  value={text ? moment(text) : null}
                  onChange={(value) => handleChange("mfg_date", value, key)}
                />
              )}
            />
            <Column
              width={100}
              className="px-1"
              title="วันหมดอายุ"
              key="exp_date"
              dataIndex="exp_date"
              align="center"
              render={(text, { key, ...record }) => (
                <DatePicker
                  size="small"
                  placeholder=""
                  format="DD/MM/YYYY"
                  disabled={isDisable({ disabled, is_transfer, record })}
                  value={text ? moment(text) : null}
                  onChange={(value) => handleChange("exp_date", value, key)}
                />
              )}
            />
            {!is_transfer && !is_doc_complete && (
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">รับแล้ว</div>
                }
                key="receive_qty"
                dataIndex="receive_qty"
                align="right"
                render={(text) => <Text>{numInt(text)}</Text>}
              />
            )}
            {is_transfer ? (
              <Column
                width={80}
                className="position-relative px-1"
                title={
                  <div className="ant-table-absolute-title-center">ยอดรวม</div>
                }
                key="total_qty"
                dataIndex="total_qty"
                align="right"
                render={(text, record) => {
                  const obj = {
                    children: <Text strong>{numInt(text)}</Text>,
                    props: {},
                  };
                  if (record.line_number === 1) {
                    obj.props.rowSpan = _.filter(
                      dataSource,
                      (n) => n.product_code === record.product_code
                    ).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                }}
              />
            ) : (
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">รับจริง</div>
                }
                key="new_receive_qty"
                dataIndex="new_receive_qty"
                align="right"
                render={(text, { key, ...record }) => (
                  <InputNumber
                    size="small"
                    disabled={isDisable({ disabled, is_transfer, record })}
                    min={1}
                    max={record.qty - record.receive_qty}
                    value={text}
                    onFocus={(e) => e.target.select()}
                    onKeyUp={enterFocusNext}
                    onChange={(value) =>
                      !_.isNaN(+value) &&
                      handleChange("new_receive_qty", value, key)
                    }
                  />
                )}
              />
            )}

            {!is_transfer && (
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    รับจริงรวม
                  </div>
                }
                key="total_new_receive_qty"
                dataIndex="total_new_receive_qty"
                align="right"
                render={(text, record) => {
                  const obj = {
                    children: <Text strong>{numInt(text)}</Text>,
                    props: {},
                  };
                  if (record.line_number === 1) {
                    obj.props.rowSpan = _.filter(
                      dataSource,
                      (n) => n.product_code === record.product_code
                    ).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                }}
              />
            )}

            {!is_transfer && !is_doc_complete && (
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">ผลต่าง</div>
                }
                key="total_diff_new_receive"
                dataIndex="total_diff_new_receive"
                align="right"
                render={(text, record) => {
                  const obj = {
                    children: (
                      <Text
                        strong
                        className={`${
                          text > 0 ? "text-tp" : text < 0 ? "text-reds" : ""
                        }`}
                      >
                        {numInt(text)}
                      </Text>
                    ),
                    props: {},
                  };
                  if (record.line_number === 1) {
                    obj.props.rowSpan = _.filter(
                      dataSource,
                      (n) => n.product_code === record.product_code
                    ).length;
                  } else {
                    obj.props.rowSpan = 0;
                  }
                  return obj;
                }}
              />
            )}

            <Column
              className="px-1"
              title="หมายเหตุ"
              key="remark"
              dataIndex="remark"
              align="center"
              width={150}
              render={(text, { key, ...record }) => (
                <Input
                  size="small"
                  autoComplete="off"
                  disabled={isDisable({ disabled, is_transfer, record })}
                  value={text}
                  maxLength={500}
                  onKeyUp={enterFocusNext}
                  onChange={(e) => handleChange("remark", e.target.value, key)}
                />
              )}
            />
            {!disabled && is_transfer && (
              <Column
                title={<FaCogs />}
                align="center"
                width={30}
                key="action-del"
                render={(_text, { key }) => (
                  <Popconfirm
                    title="ต้องการลบรายการนี้หรือไม่"
                    onConfirm={() => dispatch(delProductLot(key))}
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                    placement="topRight"
                    zIndex={1080}
                  >
                    <Button size="small" ghost type="danger">
                      <FaTrashAlt size={10} />
                    </Button>
                  </Popconfirm>
                )}
              />
            )}
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default memo(ProductTable);
