import { Alert, Skeleton } from "antd";
import React from "react";
import { useQuery } from "../../../../hooks/dom";
import { useHttp } from "../../../../hooks/http";
import styles from "../../../../scss/module/legal.module.scss";
import { URL_API } from "../../../../utils";
import DocumentData from "./components/DocumentData";
import { useSelector } from "react-redux";
import { typeSelector } from "../../../../redux/slices/legal";

const DocumentList = ({ onSelect, activeId }) => {
  const query = useQuery();
  const id = query.get("id");
  const member_type = useSelector(typeSelector);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/legal/document`,
      params: {
        id,
        member_type : member_type.type
      },
    },
    [id,member_type]
  );

  if (error) return <Alert type="error" message={error} />;

  return (
    <div className={styles.list}>
      {loading && <Skeleton active />}
      {data?.data &&
        data?.data.map((n, i) => (
          <DocumentData
            key={i}
            data={n}
            activeId={activeId}
            onSelect={onSelect}
            disableDivider={i === data.data.length - 1}
          />
        ))}
    </div>
  );
};

export default DocumentList;
