import { List } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  addressActiveSelector,
  addressSelector,
} from "../../../../redux/slices/member";

export default function ListAddress() {
  const active = useSelector(addressActiveSelector);
  const address = useSelector(addressSelector);

  return (
    <List
      size="small"
      dataSource={_.filter(
        address || [],
        (n) => n.address_type?.toString() === active
      )}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            //   avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
            title={item.ship_to + " " + item.ship_to_mobile}
            description={item.address_text}
          />
        </List.Item>
      )}
    />
  );
}
