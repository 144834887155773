/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import PageIndex from "./index";

import Error404 from "../Error404";
import { PageSettings } from "../../../config/page-settings";
import { connect } from "react-redux";
import { PosDataAction } from "./ducks/data-api";
import { Creators } from "./ducks/operation-form";

const Index = ({ match, clear, clearData }) => {
  const contextType = useContext(PageSettings);
  useEffect(() => {
    if (contextType.pageHeader || contextType.pageSidebar) {
      contextType.handleSetPageHeader(false);
      contextType.handleSetPageSidebar(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (window.location.pathname.split("/")[1] !== "pos") {
        if (!contextType.pageHeader && !contextType.pageSidebar) {
          contextType.handleSetPageHeader(true);
          contextType.handleSetPageSidebar(true);
        }
        clear();
        clearData();
      }
    };
  }, []);

  if (contextType.pageHeader || contextType.pageSidebar) return null;

  return (
    <Switch>
      <Route path={`${match.path}/:type?/:process?`} component={PageIndex} />
      <Route component={Error404} />
    </Switch>
  );
};

export default connect(null, {
  clear: Creators.clear,
  clearData: PosDataAction.clear,
})(Index);
