import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { FaCheckCircle, FaFileAlt, FaIdCard, FaMoneyCheck } from 'react-icons/fa';


const IconFile = ({file, setImageId, setPreview}) => {

    const Document = file?.filter((i) => i.document_type === '1')
    const IDCard = file?.filter((i) => i.document_type === '2')
    const BookBank = file?.filter((i) => i.document_type === '3')

    return (
        <Row type="flex" justify="start" align="top" className="text-center" gutter={8}>
            {Document.length > 0 &&<Row className="p-2">
                <Col>
                    {
                        Document.length > 0 &&
                        <Tooltip title="ใบสมัคร">
                            <FaFileAlt color="#CCE2A3" onClick={() => {setImageId(Document[0].id); setPreview(true);}} className="fa-2x" />
                        </Tooltip>
                    }
                </Col>
                <Col style={{ marginTop: '-7px' }}>
                    {
                        file?.filter((i) => i.document_type === '1' && i.document_status === 'approve').length > 0 &&
                        <FaCheckCircle color="green" />
                    }
                </Col>
            </Row>}
            {IDCard.length > 0 &&<Row className="p-2">
                <Col>
                    {
                        IDCard.length > 0 &&
                        <Tooltip title="สำเนาบัตรประชาชน">
                            <FaIdCard color="#CCE2A3" onClick={() => {setImageId(IDCard[0].id); setPreview(true);}} className="fa-2x" />
                        </Tooltip>
                    }
                </Col>
                <Col style={{ marginTop: '-7px' }}>
                    {
                        file?.filter((i) => i.document_type === '2' && i.document_status === 'approve').length > 0 &&
                        <FaCheckCircle color="green" />
                    }
                </Col>
            </Row>}
            {BookBank.length > 0 &&<Row className="p-2">
                <Col>
                    {
                        BookBank.length > 0 &&
                        <Tooltip title="สำเนาบัญชีธนาคาร">
                            <FaMoneyCheck color="#CCE2A3" onClick={() => {setImageId(BookBank[0].id); setPreview(true);}} className="fa-2x" />
                        </Tooltip>
                    }
                </Col>
                <Col style={{ marginTop: '-7px' }}>
                    {
                        file?.filter((i) => i.document_type === '3' && i.document_status === 'approve').length > 0 &&
                        <FaCheckCircle color="green" />
                    }
                </Col>
            </Row>}
        </Row>
    )
}
export default IconFile
