import { Button, Tooltip } from "antd";
import React from "react";
import { FaQrcode } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";

const QrButton = ({
  onClick,
  qrWarning = false,
  disabled = false,
  tooltip = false,
}) => (
  <Tooltip title={tooltip ? "สแกน QR Code" : null}>
    <Button
      type="link"
      size="small"
      disabled={disabled}
      className="p-0 ml-2"
      onClick={onClick}
    >
      <div className="position-relative">
        <span className="d-flex justify-content-center align-items-center bg-gray-60 text-white rounded-circle p-md-1">
          <FaQrcode />
        </span>
        {qrWarning && (
          <TiWarningOutline
            size={16}
            className="position-absolute text-nn"
            style={{
              top: "-10px",
              right: "-10px",
            }}
          />
        )}
      </div>
    </Button>
  </Tooltip>
);

export default QrButton;
