/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Divider, Modal, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../hooks/dom";
import { actionClaim } from "../../../redux/slices/claim";
import {
  getClaimSelect,
  getConsiderPermission,
} from "../../../redux/thunk/claim";
import { getUserObj, _ } from "../../../utils";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import DocumentHeader from "./components/DocumentHeader/index";
import DocumentIn from "./components/DocumentIn/index";
import DocumentFooter from "./components/DocumentFooter/index";
const { clear, setGlobalState, setState, clearCreate } = actionClaim;

const Manage = ({ match, history }) => {
  const query = useQuery();
  const user = getUserObj();
  const dispatch = useDispatch();
  const loadingSubmit = useSelector((state) => state.claim.loadingSubmit);
  const { loading, error } = useSelector(
    (state) => state.claim.fetch.claim_select
  );
  const consider_permission_loading = useSelector(
    (state) => state.claim.fetch.consider_permission.loading
  );
  const document_status = useSelector(
    (state) => state.claim.documentDetail.status
  );
  const consider = useSelector((state) => state.claim.consider);
  const disabled = useSelector((state) =>
    _.includes([null, "draft", "waiting"], state.claim.documentDetail.status)
  );
  const { process: process_params, document_no: document_no_params } =
    match.params;

  useEffect(() => {
    return () => {
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      if (path !== "claim") {
        dispatch(clear());
      } else {
        dispatch(clearCreate());
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getConsiderPermission());
    dispatch(
      setState({ name: "isPreview", value: query.get("preview") === "1" })
    );
  }, []);

  useEffect(() => {
    if (document_no_params) {
      if (document_no_params === "0") {
        dispatch(
          setGlobalState({
            name: "documentDetail",
            value: {
              user_branch_code: user.branch_code,
              user_branch_name: user.branch_name,
              document_date: new Date(),
            },
          })
        );
      } else {
        dispatch(getClaimSelect(document_no_params));
      }
    }
  }, [document_no_params]);

  useEffect(() => {
    if (error === "notfound") handleCatchURl("ไม่พบข้อมูลเคลมสินค้า");
  }, [error]);

  useEffect(() => {
    if (
      document_no_params &&
      (document_no_params === "0" || (document_status && consider.branch))
    ) {
      const error = checkUrlPermission();
      if (error) handleCatchURl(error);
    }
  }, [document_no_params, document_status, consider.branch]);

  const checkUrlPermission = () => {
    if (process_params !== "pending" && document_no_params === "0")
      return "ไม่สามารถสร้างยื่นเรื่องเคลมได้";
  };

  const handleCatchURl = (content) => {
    dispatch(clear());
    const base_path = `/${_.split(match.path, "/")[1]}`;
    const sub_path = content === "URL ไม่ถูกต้อง" ? "wait" : process_params;
    history.push({
      pathname: `${base_path}/${sub_path}`,
    });
    Modal.error({
      title: "ไม่สามารถดำเนินการได้",
      content,
      zIndex: 1080,
    });
  };

  // const handleClickTab = useCallback((e) => setActiveTab(e), []);

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Panel>
      <PanelHeader>ใบลดหนี้</PanelHeader>
      <PanelBody>
        <LoadingFullScreen
          loading={loadingSubmit}
          text={`กำลังบันทึกข้อมูล...`}
        />
        <Spin spinning={loading || consider_permission_loading}>
          <DocumentHeader />
          <DocumentIn />
          {!disabled &&
            (process_params === "pending") && (
              <>
                <Divider />
              </>
            )}
          <DocumentFooter />
        </Spin>
      </PanelBody>
    </Panel>
  );
};

export default Manage;
