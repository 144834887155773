import { Col, Input, Row, Typography } from "antd";
import React from "react";
const Remark = () => {
  return (
    <Row gutter={[8, 8]} type="flex" className="mb-2">
      <Col span={6} className="text-right">
        <Typography.Text strong className="text-nowrap">
          หมายเหตุ
        </Typography.Text>
      </Col>
      <Col span={18}>
        <Input.TextArea rows={2} />
      </Col>
    </Row>
  );
};
export default Remark;
