const myFocusConstants = {
  subTagThumbnail: {
    width: 600,
    height: 600,
    ratio: '1:1',
  },
  videoThumbnail: {
    width: 800,
    height: 450,
    ratio: '16:9',
  },
  tableSize: 10,
};

export default myFocusConstants;
