import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";
import moment from "moment";
import { getUser } from "../../../../utils";

export const SaleSummaryContext = createContext();
let user = getUser();
export const initState = {
  filter: {
    date_from: moment(),
    date_to: moment(),
    branch: user ? user.branch_code : "",
    branch_name : user ? user.branch_name : "",
    query: "",
    page: 1,
    limit: 10,
    buyer_type: "",
  },
  summaryModal: {
    visible: false,
  },
};
export const reducer = createReducer(initState, {
  setFilter: (state, { payload }) => {
    state.filter = { ...state.filter, ...payload };
  },
  setSummaryModal: (state, { payload }) => {
    state.summaryModal = { ...state.summaryModal, ...payload };
  },
});
export default SaleSummaryContext;
