import { Empty, Skeleton } from "antd";
import React, { useContext } from "react";
import { FaAddressCard } from "react-icons/fa";
import CardInfo from "../CardInfo";
import InfoContext from "./reducer";

const MemberCard = () => {
  const {
    state: { loading, member },
  } = useContext(InfoContext);

  if (loading) return <Skeleton active />;

  if (member?.length === 0) return <Empty className="mb-5" />;

  return <CardInfo data={member} icon={<FaAddressCard />} />;
};

export default MemberCard;
