import { Modal, notification, Button, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { actionInventoryCount } from "../../../../redux/slices/inventory-count";
import { getToken, _, URL_API, axios } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import { mapFileList } from "../../../util/helper/file";

const { Text } = Typography;

const { clearStock } = actionInventoryCount;

const ButtonReport = ({ type }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { type: type_params } = useParams();
  const {
    warehouse,
    document: { document_no, document_status },
    media,
    remark,
    ...inventoryCount
  } = useSelector((state) => state.inventoryCount);
  const disabledSendReport = _.some(
    warehouse.itemKeys,
    (key) => warehouse.itemByKey[key].status !== "pending"
  );

  const handleClickReport = () => {
    // check must upload image when have product
    const wh_arr = ["primary_set", "primary", "back", "distribute", "broken"];
    if (
      _.some(
        wh_arr,
        (wh) =>
          inventoryCount[wh].itemKeys.length > 0 && media.itemKeys.length === 0
      )
    )
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณาอัปโหลดใบนับคลังสินค้า",
      });

    let data_text, text_style, status;
    switch (type) {
      case "retry":
        data_text = "ตรวจสอบนับคลังสินค้าใหม่";
        text_style = "text-rp";
        status = "return";
        break;
      case "approve":
        data_text = "อนุมัตินับคลังสินค้า";
        text_style = "text-pv";
        status = "completed";
        break;
      case "report":
        data_text = "ส่งรายงานนับคลังสินค้า";
        text_style = "text-pv";
        status = "pending";
        break;
      default:
        return;
    }
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          const back_url = `/${_.split(match.path, "/")[1]}/${type_params}`;
          const data = {
            ...mapFileList(media),
            document_no,
            document_status,
            status,
            remark: remark.new_remark,
          };
          axios({
            method: "post",
            url: `${URL_API}/inventory-count/report`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
            cancelToken: source.token,
          })
            .then(() => {
              resolve();
              dispatch(clearStock());
              history.push({ pathname: back_url });
              notification.success({
                message: "Success",
                description: `บันทึกข้อมูลส่งรายงาน${data_text}เรียบร้อย`,
              });
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };
  if (type === "retry")
    return (
      <Button
        className="bg-rp border-rp text-white mr-3"
        onClick={handleClickReport}
      >
        ตรวจสอบใหม่
      </Button>
    );
  if (type === "approve")
    return (
      <Button type="primary" className="width-100" onClick={handleClickReport}>
        อนุมัติ
      </Button>
    );
  if (type === "report")
    return (
      <Button
        disabled={disabledSendReport}
        type="primary"
        className="width-100"
        onClick={handleClickReport}
      >
        ส่งรายงาน
      </Button>
    );
  return null;
};

export default memo(ButtonReport);
