import { Table } from "antd";
import React from "react";

export default function TableImportRegisterFile({ data }) {
  return (
    <Table
      size="small"
      bordered
      scroll={{
        x: "max-content",
      }}
      dataSource={data || []}
      rowKey={(record, index) => record.id || index}
    >
      <Table.Column
        title="ticket_no"
        key="ticket_no"
        align="left"
        render={(data) => data.ticket_no}
      />
      <Table.Column
        title="id_card"
        key="id_card"
        align="left"
        render={(data) => data.id_card}
      />
      <Table.Column
        title="full_name"
        key="full_name"
        align="left"
        render={(data) => data.full_name}
      />
      <Table.Column
        title="mobile"
        key="mobile"
        align="left"
        render={(data) => data.mobile}
      />
      <Table.Column
        title="event_Name"
        key="event_Name"
        align="left"
        render={(data) => data.event_Name}
      />
      <Table.Column
        title="createDate"
        key="createDate"
        align="center"
        render={(data) => data.createDate}
      />
    </Table>
  );
}