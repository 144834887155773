/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Card, Carousel, Col, Input, Modal, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../hooks/http2";
import { actionEthics } from "../../../../../redux/slices/ethics";
import { URL_API } from "../../../../../utils";
import { axios } from "../../../../../utils/useAxios";
import ImgBlock from "../ImgBlock";
import UploadDocumentModal from "../Modal/UploadDocumentModal";
import SelectUserDocument from "../SelectUserDocument";
import { useHttp } from "../../../../../hooks/http";
import _ from "lodash";

export default function UploadPanel() {
  const dispatch = useDispatch();
  const { id, type } = useSelector((state) => state.ethics.form, shallowEqual);
  const { active } = useSelector(
    (state) => state.ethics.form.upload,
    shallowEqual
  );
  const remarkRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);

  const { SET_FORM_UPLOAD, OPEN_MODAL } = actionEthics;

  const handleAction = (action, image_id) => {
    Modal.confirm({
      title: `${action === "approve" ? "อนุมัติ" : "ไม่อนุมัติ"}เอกสาร`,
      content: (
        <Input.TextArea ref={remarkRef} rows={3} placeholder="หมายเหตุ" />
      ),
      okText: action === "approve" ? "อนุมัติ" : "ไม่อนุมัติ",
      cancelText: `ยกเลิก`,
      okButtonProps: { type: action === "approve" ? "primary" : "danger" },
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            const remark = remarkRef.current?.state?.value || "";

            const reponse = await axios.post(
              `/pos/${type === "member" ? "vip-member" : "ethics"}/${action}`,
              {
                comment: remark,
                remark,
                id: image_id,
                member_id: id,
              }
            );
            dispatch(SET_FORM_UPLOAD({ active: null }));
            resolve(reponse.data);
          } catch (error) {
            reject(error);
          }
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ทำรายการเรียบร้อย",
              okText: "ปิด",
              onOk() {
                //dispatch(SET_FORM_UPLOAD({ reload: true , load_document:false}));
                dispatch(SET_FORM_UPLOAD({ active: null }));
                reload();
              },
            });
          })
          .catch((reason) =>
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            })
          );
      },
    });
  };

  const document = useApi(
    {
      method: "get",
      url: `${URL_API}/pos/${
        type === "member" ? "vip-member" : "ethics"
      }/${id}/image`,
      token: true,
    },
    [id, type, active]
  );

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/ethics/user-document?userID=${id}&userType=${type}`,
      method: "GET",
      token: true,
    },
    [id, type, active]
  );

  useEffect(() => {
    dispatch(SET_FORM_UPLOAD({ uploaded_document: document.fetch?.data }));
    setDataSource(
      _.filter(document.fetch?.data || [], (n) => n.document_type === active)
    );
  }, [document.fetch?.data, active]);

  useEffect(() => {
    dispatch(SET_FORM_UPLOAD({ document_list: data?.data }));
    if (active == null && data?.data?.uploaded?.length > 0) {
      dispatch(SET_FORM_UPLOAD({ active: data?.data?.uploaded[0].code }));
    }
  }, [data, active]);
  return (
    <>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col md={6}>
          <SelectUserDocument
            size="small"
            className="w-100"
            loading={loading}
            value={active}
            dataSource={data?.data}
            onChange={(document_type) => {
              dispatch(SET_FORM_UPLOAD({ active: document_type }));
            }}
          />
        </Col>
        <Col md={6} offset={12} className="text-right">
          <Button
            size="small"
            type="primary"
            shape="round"
            block
            onClick={() => {
              dispatch(SET_FORM_UPLOAD({ active: null }));
              dispatch(
                OPEN_MODAL({
                  name: "document",
                  props: {
                    loading: false,
                    search: {
                      query: "",
                      document_type: "",
                      status: "",
                      page: 1,
                      limit: 10,
                    },
                    source: { data: [], total: 0 },
                  },
                })
              );
            }}
          >
            Upload
          </Button>
        </Col>
      </Row>
      <Row>
        {/* <ImgBlock /> */}
        {dataSource.length === 1 && (
          <Card>
            <Row>
              <Col>
                <img
                  style={{ backgroundColor: "#ffffff" }}
                  src={dataSource[0]?.azure_url}
                  alt="document-file"
                  className="w-75 d-block my-3 mx-auto"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={24} className="text-center">
                {!dataSource[0]?.document_status && (
                  <Button
                    size="small"
                    shape="round"
                    type="primary"
                    ghost
                    className="mr-2"
                    onClick={() => handleAction("approve", dataSource[0].id)}
                  >
                    อนุมัติ
                  </Button>
                )}
                {dataSource[0]?.document_status !== "reject" && (
                  <Button
                    size="small"
                    shape="round"
                    type="danger"
                    ghost
                    onClick={() => handleAction("reject", dataSource[0].id)}
                  >
                    ปฏิเสธ
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        )}
        {dataSource.length > 1 && (
          <Carousel
            arrows
            style={{ backgroundColor: "#858585", padding: "25px" }}
          >
            {dataSource.map((image, index) => (
              <Card key={index}>
                <Row>
                  <Col>
                    <img
                      style={{ backgroundColor: "#ffffff" }}
                      src={image?.azure_url}
                      alt="document-file"
                      className="w-75 d-block my-3 mx-auto"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={24} className="text-center">
                    {!image?.document_status && (
                      <Button
                        size="small"
                        shape="round"
                        type="primary"
                        ghost
                        className="mr-2"
                        onClick={() => handleAction("approve", image.id)}
                      >
                        อนุมัติ
                      </Button>
                    )}
                    {image?.document_status !== "reject" && (
                      <Button
                        size="small"
                        shape="round"
                        type="danger"
                        ghost
                        onClick={() => handleAction("reject", image.id)}
                      >
                        ปฏิเสธ
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card>
            ))}
          </Carousel>
        )}
      </Row>
      <UploadDocumentModal />
    </>
  );
}
