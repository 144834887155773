/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  DatePicker,
  Radio,
  Input,
  Button,
  InputNumber,
  notification,
  Upload,
  Modal,
} from "antd";
import { PanelHeader, Panel, PanelBody } from "../../../../util/panel";
import UploadBanner from "../../home/Banner/components/UploadBanner";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../util/helper";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { AiOutlineUpload } from "react-icons/ai";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import reactImageSize from "react-image-size";
import mwEcosysConstants from "../../utils/constants/mwEcosysConstants";
import { DivStyles, ImgStyles } from "../../components/mwStyles";
import useUploadFile from "../../components/useUploadFile";
import { dummyRequest } from "../../components/dummyRequest";

function EcoBanner({ history, match, form, ...props }) {
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);

  const [fileDelete, setFileDelete] = useState([]);
  const [loadValue, setLoadValue] = useState("");

  let { onUpload, onRemoveUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();
      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      if (fileListMobile[0]?.file) {
        formData.append("mobile_img", fileListMobile[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img"]),
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != ""
              ? (url_path = "/manage-website/ecosystem/banner/update")
              : (url_path = "/manage-website/ecosystem/banner");
            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveMobile = (e) => {
    setFileListMobile([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleUploadDesktop = async (e) => {
    let isFile = await onUpload(
      e,
      mwEcosysConstants.banner_page.desktop_img.width,
      mwEcosysConstants.banner_page.desktop_img.height
    );
    if (isFile) {
      setFileListDesktop(isFile);
    }
  };

  const handleUploadMobile = async (e) => {
    let isFile = await onUpload(
      e,
      mwEcosysConstants.banner_page.mobile_img.width,
      mwEcosysConstants.banner_page.mobile_img.height
    );
    if (isFile) {
      setFileListMobile(isFile);
    }
  };

  const loadContent = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/banner/",
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);
        let { start_date, end_date, loadDesktopImg, loadMobileImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListMobile(loadMobileImg);
          }
        }

        form.setFieldsValue({
          ...data,
          start_date: start_date == null ? null : moment(start_date),
          end_date: end_date == null ? null : moment(end_date),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>ECOSYSTEM Banner</PanelHeader>
        <PanelBody>
          <Form layout="vertical">
            <Row gutter={24}>
              <Col md={12}>
                {fileListDesktop.length != 0 ? (
                  <Row>
                    <div style={DivStyles}>
                      <img
                        src={fileListDesktop[0].url}
                        alt="desktop_img"
                        style={ImgStyles}
                      />
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
              <Col md={12}>
                {fileListMobile.length != 0 ? (
                  <div style={DivStyles}>
                    <img
                      src={fileListMobile[0].url}
                      alt="mobile_img"
                      style={ImgStyles}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item
                  label={`Desktop = ${mwEcosysConstants.banner_page.desktop_img.width} x ${mwEcosysConstants.banner_page.desktop_img.height}`}
                >
                  {getFieldDecorator("desktop_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListDesktop}
                      customRequest={dummyRequest}
                      beforeUpload={beforeUploadImg}
                      onChange={handleUploadDesktop}
                      onRemove={handleRemoveDesktop}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={`Mobile = ${mwEcosysConstants.banner_page.mobile_img.width} x ${mwEcosysConstants.banner_page.mobile_img.height}`}
                >
                  {getFieldDecorator("mobile_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListMobile}
                      customRequest={dummyRequest}
                      beforeUpload={beforeUploadImg}
                      onChange={handleUploadMobile}
                      onRemove={handleRemoveMobile}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item label="Title">
                {getFieldDecorator("title", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ Title" }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Content">
                {getFieldDecorator("content", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ content" }],
                })(<TextArea />)}
              </Form.Item>
            </Row>

            {/* แสดงการตั้งเวลา แสดงตอน Phase 2 */}
            {/* <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Start Date">
                  {getFieldDecorator("start_date", {
                    initialValue: null,
                    rules: [
                      { required: true, message: "กรุณาระบุ Start Date" },
                    ],
                  })(
                    <DatePicker
                      showTime
                      className="w-100"
                      format="DD/MM/YYYY HH:mm"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="End Date">
                  {getFieldDecorator("end_date", {
                    initialValue: null,
                    rules: [{ required: true, message: "กรุณาระบุ End Date" }],
                  })(
                    <DatePicker
                      showTime
                      className="w-100"
                      format="DD/MM/YYYY HH:mm"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={16}>
              <Col md={24}>
                {/* <Button
                  className="bg-nn border-nn text-white mr-3"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  CANCEL
                </Button> */}
                <Button type="primary" onClick={() => handleClickSubmit()}>
                  {loadValue != "" ? "Update" : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-banner")(EcoBanner);
