import {
  Button,
  Col,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import React, { useContext } from "react";
import { FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from "react-icons/fa";
import { _ } from "../../../../../../../utils";
import { PromotionContext } from "../../reducers";

const Item = ({
  type,
  dataSource,
  dataKey,
  disabledAdd,
  disabledChange,
  refSourceOption,
  qtyTextSuffix,
}) => {
  const {
    state: {
      data: { collect_point },
    },
    dispatch,
  } = useContext(PromotionContext);

  return (
    <Table
      size="small"
      dataSource={dataSource}
      rowKey="key"
      scroll={{ x: "max-content" }}
      pagination={false}
    >
      <Table.Column
        key="key"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">ลำดับ</div>}
        align="right"
        width={50}
        render={(_text, _record, index) => index + 1}
      />
      <Table.Column
        key="product_code"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">รหัส</div>}
        width={80}
        render={({ product_code, code }) => (
          <Typography.Text>{product_code || code}</Typography.Text>
        )}
      />
      <Table.Column
        className="position-relative min-width-150 max-width-600"
        title={<div className="ant-table-absolute-title-center">รายการ</div>}
        render={({ product_name_local, name, source, visible, key }) => (
          <>
            {source ? (
              <>
                <Row>
                  <Col
                    span={24}
                    className="cursor-pointer"
                    onClick={() =>
                      dispatch({
                        type: "SET_PROMOTION_DATA_ITEM_VALUE",
                        payload: {
                          type,
                          data_key: dataKey,
                          item_key: key,
                          visible: !visible,
                        },
                      })
                    }
                  >
                    <Typography.Text className="mr-2">{name}</Typography.Text>
                    {visible ? <FaAngleUp /> : <FaAngleDown />}
                  </Col>
                </Row>
                {visible &&
                  _.map(source, (n, i) => (
                    <Row key={i} className="ml-3">
                      <Col span={6} xxl={3}>
                        <Typography.Text>{n.product_code}</Typography.Text>
                      </Col>
                      <Col span={18} xxl={19}>
                        <Typography.Text>
                          {n.product_name_local}
                        </Typography.Text>
                      </Col>
                    </Row>
                  ))}
              </>
            ) : (
              <Row>
                <Col span={24}>
                  <Typography.Text className="mr-2">
                    {product_name_local}
                  </Typography.Text>
                </Col>
              </Row>
            )}
          </>
        )}
      />
      {type === "receive" && (
        <Table.Column
          key="point_01"
          dataIndex="point_01"
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">PV/ชิ้น</div>}
          width={80}
          align="right"
          render={(text, record) => (
            <InputNumber
              className="width-70"
              size="small"
              value={collect_point && text}
              disabled={!collect_point || disabledChange}
              min={0}
              onFocus={(e) => e.target.select()}
              onChange={(value) =>
                dispatch({
                  type: "SET_PROMOTION_DATA_ITEM_VALUE",
                  payload: {
                    type,
                    data_key: dataKey,
                    item_key: record.key,
                    point_01: value,
                  },
                })
              }
            />
          )}
        />
      )}
      {type === "receive" && (
        <Table.Column
          key="point_02"
          dataIndex="point_02"
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">RP/ชิ้น</div>}
          width={80}
          align="right"
          render={(text, record) => (
            <InputNumber
              className="width-70"
              size="small"
              value={collect_point && text}
              disabled={!collect_point || disabledChange}
              min={0}
              onFocus={(e) => e.target.select()}
              onChange={(value) =>
                dispatch({
                  type: "SET_PROMOTION_DATA_ITEM_VALUE",
                  payload: {
                    type,
                    data_key: dataKey,
                    item_key: record.key,
                    point_02: value,
                  },
                })
              }
            />
          )}
        />
      )}
      {type === "receive" && (
        <Table.Column
          key="point_03"
          dataIndex="point_03"
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">CV/ชิ้น</div>}
          width={80}
          align="right"
          render={(text, record) => (
            <InputNumber
              className="width-70"
              size="small"
              value={collect_point && text}
              disabled={!collect_point || disabledChange}
              min={0}
              onFocus={(e) => e.target.select()}
              onChange={(value) =>
                dispatch({
                  type: "SET_PROMOTION_DATA_ITEM_VALUE",
                  payload: {
                    type,
                    data_key: dataKey,
                    item_key: record.key,
                    point_03: value,
                  },
                })
              }
            />
          )}
        />
      )}
      {type === "receive" && (
        <Table.Column
          key="unit_price"
          dataIndex="unit_price"
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ราคา/ชิ้น</div>
          }
          width={80}
          align="right"
          render={(text, record) => (
            <InputNumber
              className="width-70"
              size="small"
              value={text}
              min={0}
              onFocus={(e) => e.target.select()}
              disabled={disabledChange}
              onChange={(value) =>
                dispatch({
                  type: "SET_PROMOTION_DATA_ITEM_VALUE",
                  payload: {
                    type,
                    data_key: dataKey,
                    item_key: record.key,
                    unit_price: value,
                  },
                })
              }
            />
          )}
        />
      )}
      <Table.Column
        key="qty"
        dataIndex="qty"
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">
            จำนวน{qtyTextSuffix}
          </div>
        }
        width={80}
        align="right"
        render={(text, record) => (
          <InputNumber
            className="width-70"
            size="small"
            value={text}
            min={record.min || 1}
            onFocus={(e) => e.target.select()}
            disabled={disabledChange}
            onChange={(value) =>
              dispatch({
                type: "SET_PROMOTION_DATA_ITEM_VALUE",
                payload: {
                  type,
                  data_key: dataKey,
                  item_key: record.key,
                  qty: value,
                },
              })
            }
          />
        )}
      />

      <Table.Column
        key="action"
        title={
          <Button
            type="primary"
            ghost
            size="small"
            disabled={disabledAdd}
            onClick={() =>
              dispatch({
                type: "SET_MODAL_VISIBLE",
                payload: {
                  name: "product",
                  visible: true,
                  active_by: type,
                  data_key: dataKey,
                  ref_source_option: refSourceOption || null,
                },
              })
            }
          >
            <FaPlus />
          </Button>
        }
        align="center"
        width={60}
        render={(record) => (
          <Popconfirm
            title="ต้องการลบรายการนี้หรือไม่"
            disabled={record.disabled_del}
            onConfirm={() =>
              dispatch({
                type: "DEL_PROMOTION_DATA_ITEM",
                payload: {
                  type,
                  data_key: dataKey,
                  item_key: record.key,
                },
              })
            }
            okText="ลบ"
            cancelText="ไม่ลบ"
          >
            <Button
              type="danger"
              ghost
              size="small"
              disabled={record.disabled_del}
            >
              <FaTrashAlt />
            </Button>
          </Popconfirm>
        )}
      />
    </Table>
  );
};

export default Item;
