/* eslint-disable no-unused-vars */
import numeral from "numeral";
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { connect } from "react-redux";
import { _ } from "../../../../utils";
import { getSummaryTotalPayment } from "./logic";

const BillFooter = (props) => {
  const [showPoint, setShowPoint] = useState(true);
  const {
    delivery,
    total,
    discount,
    voucher,
    point,
    summary_total,
    order_voucher_total,
  } = props.summary;
  const points = props.is_vip_member
    ? [
        {
          title: "RP",
          point: numeral(point.rp).format("0,0.00"),
        },
      ]
    : [
        {
          title: "PV",
          point: numeral(point.pv).format("0,0.00"),
        },
        {
          title: "RP",
          point: numeral(point.rp).format("0,0.00"),
        },
        // {
        //   title: "RA",
        //   point: numeral(point.ra).format("0,0.00"),
        // },
        // {
        //   title: "BP",
        //   point: numeral(point.bp).format("0,0.00"),
        // },
        {
          title: "EP",
          point: numeral(point.ep).format("0,0.00"),
        },
        {
          title: "TP",
          point: numeral(point.tp).format("0,0.00"),
        },
      ];

  return (
    <>
      <table>
        <tbody>
          {props.isNormal && (
            <tr style={{ backgroundColor: "#c9c9c9" }}>
              <td className="w-100 pr-2 pl-4">
                <strong>ค่าจัดส่ง</strong>
              </td>
              <td className="text-nowrap pl-2 pr-4 text-right">
                <strong>{numeral(delivery).format("0,0.00")}</strong>
              </td>
            </tr>
          )}
          <tr style={{ backgroundColor: "#c9c9c9" }}>
            <td className="w-100 pr-2 pl-4">
              <strong>รวม{props.isNormal ? "เงิน" : "คะแนน"}</strong>
            </td>
            <td className="text-nowrap pl-2 pr-4 text-right">
              <strong>{numeral(total).format("0,0.00")}</strong>
            </td>
          </tr>
          {props.isNormal && (
            <>
              <tr style={{ backgroundColor: "#c9c9c9" }}>
                <td className="w-100 pr-2 pl-4">
                  <strong>รวมมูลค่าบัตรกำนัล</strong>
                </td>
                <td className="text-nowrap pl-2 pr-4 text-right">
                  <strong>
                    {numeral(order_voucher_total).format("0,0.00")}
                  </strong>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#c9c9c9" }}>
                <td className="w-100 pr-2 pl-4">
                  <strong>รวมส่วนลด</strong>
                </td>
                <td className="text-nowrap pl-2 pr-4 text-right">
                  <strong>
                    {discount !== 0 && "-"}
                    {numeral(discount).format("0,0.00")}
                  </strong>
                </td>
              </tr>
              {!props.is_staff && (
                <>
                  <tr className="bg-gray-40">
                    <td
                      colSpan="2"
                      className="w-100 px-4"
                      style={{ verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() => {
                        if (showPoint) {
                          setShowPoint(false);
                        } else {
                          setShowPoint(true);
                        }
                      }}
                    >
                      <strong>คะแนน </strong>
                      {showPoint ? <FaAngleUp /> : <FaAngleDown />}
                    </td>
                  </tr>
                  {showPoint &&
                    _.map(points, (n, i) => (
                      <tr className="bg-gray-40" key={i}>
                        <td className="w-100 pr-2 pl-4">
                          <strong>{n.title}</strong>
                        </td>
                        <td className="text-nowrap pl-2 pr-4 text-right">
                          <strong>{n.point}</strong>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td className="w-100 pr-2 pl-4">
              <h2 className="m-0">รวม</h2>
            </td>
            <td className="text-nowrap pl-2 pr-4 text-right">
              <h2 className="m-0">{numeral(summary_total).format("0,0.00")}</h2>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => {
  const { orders, payment, buyer_type } = state.pos.operation;
  const activeKey =
    payment.activeKey !== "" ? payment.activeKey : orders.itemKeys[0];
  const { summary_list } = getSummaryTotalPayment(orders);
  return {
    is_staff: buyer_type?.toLowerCase() === "staff",
    is_vip_member:
      orders.itemByKey[activeKey]?.partner_type?.toLowerCase() === "member",
    summary: _.find(summary_list, (order) => order.key === activeKey) || {
      delivery: 0,
      total: 0,
      discount: 0,
      voucher: 0,
      order_voucher_total: 0,
      point: { pv: 0, rp: 0, tp: 0, cv: 0, bp: 0, cd: 0,ep:0 },
      summary_total: 0,
    },
  };
};

export default connect(mapStateToProps)(BillFooter);
