import { Col, Row, Typography } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";
const { Text } = Typography;

const QrBarcode = ({ type }) => {
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const product_bom_key = useSelector(
    (state) => state.claim.productBomModal.product_bom_key
  );
  const data = useSelector((state) => {
    if (!product_key || !product_bom_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom.itemByKey[product_bom_key][type];
  });
  const title = type === "qr_running" ? "Qr Running" : "Barcode";
  return (
    <Row gutter={[8, 8]}>
      <Col span={6} className="text-right">
        <Text>{title}</Text>
      </Col>
      <Col span={18}>
        <Text>{data ?? "-"}</Text>
      </Col>
    </Row>
  );
};

export default memo(QrBarcode);
