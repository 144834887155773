import { message } from "antd";
import _ from "lodash";
import React, { forwardRef } from "react";
import SelectOption from "../../../../../../components/SelectOption";
import { useApi } from "../../../../../../hooks/http2";

const SelectDepartment = ({ ...props }) => {
  const { loading, fetch, error } = useApi(
    {
      url: "/setting/master/department",
    },
    []
  );

  if (error) {
    message.error(error);
  }

  return (
    // <Select {...props} loading={loading}>
    //   {withAll && <Select.Option value={allValue}>{allText}</Select.Option>}
    //   {fetch?.data &&
    //     fetch?.data?.map((n, i) => (
    //       <Select.Option value={n.id} key={i}>
    //         {n.name_local}
    //       </Select.Option>
    //     ))}
    // </Select>
    <SelectOption
      loading={loading}
      data={_.map(fetch?.data || [], (n) => ({
        value: n.id,
        text: n.name_local,
      }))}
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectDepartment {...props} forwardRef={ref} />;
});
