/* eslint-disable no-unused-vars */
import React from 'react';
import { Tooltip, OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';

const WrapPopover = styled.div`
display:inline;
.bs-popover-auto[x-placement^=top]>.arrow::after, .bs-popover-top>.arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #4c4c4c;
}
`


const mql = window.matchMedia(`(min-width: 800px)`);
const pc = mql.matches;



const FormGroup = (props) => {
	const {
		label = '',
		labelCol = 'col-md-4 col-xs-12',
		labelfontSize = 14,
		textRight,
		divCol = 'col-md-8 col-xs-12',
		icon,
		detail = 'รายละเอียด',
		value = '',
		width = (pc) ? 200 : '100%',
		comment,
	} = props;

	const popover = (
		<Popover id="popover-basic" >
			{/* <Popover.Title as="h3">Popover right</Popover.Title> */}
			<Popover.Content >
				{detail}
			</Popover.Content>
		</Popover>

	);
	return (
		<div className="form-group row">
			<label
				className={`${labelCol} text-black col-form-label ${(textRight) ? 'text-right' : ''}`}
				style={{ fontSize: labelfontSize }}
			>
				{label}
				{(icon) &&
					// <OverlayTrigger
					// 	key={1}
					// 	placement={'top'}
					// 	overlay={
					// 		<Tooltip id={`tooltip-${1}`} html={true}>
					// 			<span style={{fontSize:14}}>{detail}</span>
					// 		</Tooltip>
					// 	}
					// >
					// 	<i style={{ fontSize: labelfontSize, color: '#b47e11',cursor:'pointer' }} className="fas fa-exclamation-circle ml-1" />
					// </OverlayTrigger>
					// <WrapPopover>
					// 	<OverlayTrigger trigger="hover" placement="top" overlay={popover}>
					// 		<i style={{ fontSize: labelfontSize, color: '#b47e11', cursor: 'pointer' }} className="fas fa-exclamation-circle ml-1" />
					// 	</OverlayTrigger>
					// </WrapPopover>
					<OverlayTrigger trigger="hover" placement="top" overlay={popover}>
						<i style={{ fontSize: labelfontSize, color: '#b47e11', cursor: 'pointer' }} className="fas fa-exclamation-circle ml-1" />
					</OverlayTrigger>


				}
			</label>

			<div className={divCol}>
				<input value={value} readOnly style={{ width: width, fontSize: labelfontSize }} className="input-maldives form-control form-control-sm" />
				{comment && comment}
			</div>
		</div>
	)
}
export default FormGroup;