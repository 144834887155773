import { Button, Col, Row, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { PromotionContext } from "../../reducers";

const ItemList = ({ title, type, onShowHonor }) => {
  const {
    state: { honorSource },
  } = useContext(PromotionContext);
  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col span={24} className="d-flex justify-content-between">
          <Typography.Text>{title}</Typography.Text>
          <Tooltip title="กำหนดตำแหน่ง">
            <Button
              size="small"
              shape="circle"
              type="primary"
              className="bg-pv border-pv mr-2"
              onClick={onShowHonor}
            >
              <FaPencilAlt />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {honorSource[type].itemKeys.map((key) => (
        <Row gutter={16} key={key} className="mb-2">
          <Col span={23} offset={1}>
            <Typography.Text>
              {honorSource[type].itemByKey[key].honor_name}
            </Typography.Text>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default ItemList;
