/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Popconfirm, Spin, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { FaCheck, FaEye, FaTimes } from "react-icons/fa";
import { AiOutlineFile } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import img from "../../assets/img/pos/no-img.jpg";
import styles from "../../scss/module/upload.module.scss";
import { axios, getToken, URL_API } from "../../utils";
import { resCatchModal } from "../../views/util/helper";

const BlockItem = ({
  size,
  dataSource,
  disabled,
  onDelete,
  onPreview,
  itemKey,
  withDefault,
  onChangeDefault,
}) => {
  const [errImg, setErrImg] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const data = { ...dataSource, key: itemKey };
  const commitStyle = !data.temp && styles.commit;
  const defaultStyle = data.is_default && styles.default;

  const handleDelete = useCallback(() => {
    if (!data.temp) return onDelete(data.key);
    setDeleting(true);
    axios({
      method: "post",
      url: `${URL_API}/file/del`,
      headers: { Authorization: "Bearer " + getToken() },
      data: { id: data.id },
      cancelToken: source.token,
    })
      .catch((e) => resCatchModal(e))
      .finally(() => {
        onDelete(data.key);
        setSource(axios.CancelToken.source());
        setDeleting(false);
      });
  }, [data.temp, data.id, data.key, source.token]);

  return (
    <Col
      span={24}
      className={`${styles.block} ${styles.img} ${commitStyle} ${defaultStyle}`}
    >
      {deleting ? (
        <>
          <div className={styles.loading}>
            <Spin size="small" />
            {size !== "small" && (
              <Typography.Text className="mt-2">deleting...</Typography.Text>
            )}
          </div>
          <div className={styles.delete}>
            <FaTimes
              className={styles.icon}
              onClick={() => {
                source.cancel("cancel");
                setSource(axios.CancelToken.source());
              }}
            />
          </div>
        </>
      ) : (
        <>
          {size !== "small" && !!~data.file_type?.indexOf("image") ? (
            <img
              className="object-fit-cover w-100 h-100"
              src={data.azure_url}
              alt={data.key}
              onError={() => setErrImg(img)}
            />
          ) : (
            <div className={styles.file}>
              {size !== "small" && (
                <AiOutlineFile className={styles["icon-in"]} />
              )}
              <div className={styles.name}>
                <span>{data.file_name}</span>
              </div>
            </div>
          )}
          <div className={styles.delete}>
            {!errImg && (
              <FaEye
                className={styles.icon}
                onClick={() =>
                  !~data.file_type.indexOf("pdf")
                    ? onPreview({
                        azure_url: data.azure_url,
                        file_type: data.file_type,
                      })
                    : window.open(
                        `${URL_API}/file/https-stream/${data.id}`,
                        "_blank"
                      )
                }
              />
            )}
            {withDefault && !data.is_default && (
              <FaCheck
                className={styles.icon}
                onClick={() => onChangeDefault(data.key)}
              />
            )}
            {!disabled && (
              <Popconfirm
                placement="topRight"
                title={`ต้องการลบรายการนี้หรือไม่`}
                onConfirm={handleDelete}
                okText="ลบ"
                cancelText="ไม่ลบ"
                zIndex={1081}
              >
                <MdDelete className={styles.icon} />
              </Popconfirm>
            )}
          </div>
        </>
      )}
    </Col>
  );
};

export default React.memo(BlockItem);
