import React from "react";
import { Route, Switch } from "react-router-dom";


import Error404 from "../../Error404";
import EcoBanner from "./eco-banner/EcoBanner";
import EcoCard from "./eco-card/EcoCard";
import EcoFooter from "./eco-footer/EcoFooter";
import EcoIntro from "./eco-intro/EcoIntro";

import A8_Aw from "./another8/a8-artwork/A8_Aw";
import A8_DidYouKnow from "./another8/a8-didyouknow/A8_DidYouKnow";
import A8_Hilight from "./another8/a8-hilight/A8_Hilight";
import A8_Intro from "./another8/a8-intro/A8_Intro";

import HF_Aw from "./healthy-food/hf-artwork/HF_Aw";
import HF_Detail from "./healthy-food/hf-detail/HF_Detail";
import HF_DetailList from "./healthy-food/hf-detail/HF_DetailList";
import HF_DidYouKnow from "./healthy-food/hf-didyouknow/HF_DidYouKnow";
import HF_Icon from "./healthy-food/hf-icon/HF_Icon";
import HF_Intro from "./healthy-food/hf-intro/HF_Intro";
import HF_Nutrition from "./healthy-food/hf-nutrition/HF_Nutrition";
import HF_NutritionList from "./healthy-food/hf-nutrition/HF_NutritionList";

import FS_Aw from "./fit-studio/fs-artwork/FS_Aw";
import FS_Equipment from "./fit-studio/fs-equipment/FS_Equipment";
import FS_FitFooter from "./fit-studio/fs-fit-footer/FS_FitFooter";
import FS_GalleryDetail from "./fit-studio/fs-gallery-detail/FS_GalleryDetail";
import FS_Gallery from "./fit-studio/fs-gallery/FS_Gallery";
import FS_Hilight from "./fit-studio/fs-hilight/FS_Hilight";
import FS_Intro from "./fit-studio/fs-intro/FS_Intro";

import LSP_Aw from "./legacy-space/lsp-artwork/LSP_Aw";
import LSP_DidYouKnow from "./legacy-space/lsp-didyouknow/LSP_DidYouKnow";
import LSP_Hilight from "./legacy-space/lsp-hilight/LSP_Hilight";
import LSP_Intro from "./legacy-space/lsp-intro/LSP_Intro";

import LST_Aw from "./legacy-studio/lst-artwork/LST_Aw";
import LST_Intro from "./legacy-studio/lst-intro/LST_Intro";
import LST_LifeStyle from "./legacy-studio/lst-lifestyle/LST_LifeStyle";

import RP_Description from "./reward-point/rp-description/RP_Description";
import RP_Footer from "./reward-point/rp-footer/RP_Footer";
import RP_HowToUse from "./reward-point/rp-howtouse/RP_HowToUse";
import RP_Intro from "./reward-point/rp-intro/RP_Intro";
import RP_Review from "./reward-point/rp-review/RP_Review";
import RP_ReviewIcon from "./reward-point/rp-review/RP_ReviewIcon";
import RP_ReviewReview from "./reward-point/rp-review/RP_ReviewReview";
import RP_Title from "./reward-point/rp-title/RP_Title";




export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/banner`} exact component={EcoBanner} />
      <Route path={`${match.path}/banner/:id`} exact component={EcoBanner} />
      <Route path={`${match.path}/intro`} exact component={EcoIntro} />
      <Route path={`${match.path}/card`} exact component={EcoCard} />
      <Route path={`${match.path}/footer`} exact component={EcoFooter} />
      
      <Route path={`${match.path}/another8/a8-aw`} exact component={A8_Aw} />
      <Route path={`${match.path}/another8/a8-didyouknow`} exact component={A8_DidYouKnow} />
      <Route path={`${match.path}/another8/a8-hilight`} exact component={A8_Hilight} />
      <Route path={`${match.path}/another8/a8-intro`} exact component={A8_Intro} />
      
      <Route path={`${match.path}/healthy-food/hf-nutrition`} exact component={HF_NutritionList} />
      <Route path={`${match.path}/healthy-food/hf-nutrition/:id`} exact component={HF_Nutrition} />
      
      <Route path={`${match.path}/healthy-food/hf-aw`} exact component={HF_Aw} />
      <Route path={`${match.path}/healthy-food/hf-didyouknow`} exact component={HF_DidYouKnow} />
      <Route path={`${match.path}/healthy-food/hf-detail`} exact component={HF_DetailList} />
      <Route path={`${match.path}/healthy-food/hf-detail/:id`} exact component={HF_Detail} />
      <Route path={`${match.path}/healthy-food/hf-icon`} exact component={HF_Icon} />
      <Route path={`${match.path}/healthy-food/hf-intro`} exact component={HF_Intro} />
      
      <Route path={`${match.path}/fit-studio/fs-aw`} exact component={FS_Aw} />
      <Route path={`${match.path}/fit-studio/fs-equipment`} exact component={FS_Equipment} />
      <Route path={`${match.path}/fit-studio/fs-fit-footer`} exact component={FS_FitFooter} />
      <Route path={`${match.path}/fit-studio/fs-gallery`} exact component={FS_Gallery} />
      <Route path={`${match.path}/fit-studio/fs-gallery-detail`} exact component={FS_GalleryDetail} />
      <Route path={`${match.path}/fit-studio/fs-hilight`} exact component={FS_Hilight} />
      <Route path={`${match.path}/fit-studio/fs-intro`} exact component={FS_Intro} />
      
      <Route path={`${match.path}/legacy-space/lsp-aw`} exact component={LSP_Aw} />
      <Route path={`${match.path}/legacy-space/lsp-didyouknow`} exact component={LSP_DidYouKnow} />
      <Route path={`${match.path}/legacy-space/lsp-hilight`} exact component={LSP_Hilight} />
      <Route path={`${match.path}/legacy-space/lsp-intro`} exact component={LSP_Intro} />
      
      <Route path={`${match.path}/legacy-studio/lst-aw`} exact component={LST_Aw} />
      <Route path={`${match.path}/legacy-studio/lst-intro`} exact component={LST_Intro} />
      <Route path={`${match.path}/legacy-studio/lst-lifestyle`} exact component={LST_LifeStyle} />


      <Route path={`${match.path}/reward-point/rp-description`} exact component={RP_Description} />
      <Route path={`${match.path}/reward-point/rp-footer`} exact component={RP_Footer} />
      <Route path={`${match.path}/reward-point/rp-howtouse`} exact component={RP_HowToUse} />
      <Route path={`${match.path}/reward-point/rp-intro`} exact component={RP_Intro} />
      <Route path={`${match.path}/reward-point/rp-review`} exact component={RP_Review} />
      <Route path={`${match.path}/reward-point/rp-review/icon/:id`} exact component={RP_ReviewIcon} />
      <Route path={`${match.path}/reward-point/rp-review/review/:id`} exact component={RP_ReviewReview} />
      <Route path={`${match.path}/reward-point/rp-title`} exact component={RP_Title} />

      <Route component={Error404} />
    </Switch>
  );
};
