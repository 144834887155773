/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, notification, Row, Typography } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaEye } from "react-icons/fa";
import { IoIosGift } from "react-icons/io";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { axios, moment, URL_API, getToken } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { isBirthDate } from "../../../util/helper/map-data";
import EmailConfirm from "../components/EmailConfirm";
import MemberProfilePopup from "../components/MemberProfilePopup";
import MobileConfirm from "../components/MobileConfirm";
import SelectSearch from "../components/SelectSearch";
import WarningExpireDate from "../components/WarningExpireDate";
import { PosDataAction } from "../ducks/data-api";
import { Creators } from "../ducks/operation-form";
import CameraUpload from "./CameraUpload";
import ProfileCD from "./ProfileCd";
import ProfileRalc from "./ProfileRalc";
import SelectBuyerOther from "./SelectBuyerOther";
import SelectBuyerType from "./SelectBuyerType";
const PageOperator = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const btnSubmitSelfRef = useRef(null);
  const btnSubmitOtherRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Member");
  const [query, setQuery] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [operator, setOperator] = useState({});
  const [buyer, setBuyer] = useState({});
  const [loadList, setLoadList] = useState([]);
  const [source, setSource] = useState(axios.CancelToken.source());
  const [status, setStatus] = useState(null);
  const [textStatus, setTextStatus] = useState("");
  const [textStatusBuyer, setTextStatusBuyer] = useState("");
  const [permission, setPermission] = useState(true);
  const [permissionBuyer, setPermissionBuyer] = useState(true);
  const [warnBirthDate, setWarnBirthDate] = useState(false);
  const [warnExpireDate, setWarnExpireDate] = useState(null);
  const [mobileToVerify, setMobileToVerify] = useState(null);
  const [emailToVerify, setEmailToVerify] = useState(null);
  const [paymentReg, setPaymentReg] = useState(0);
  const [ralc, setRalc] = useState(null);
  const [registerRenewEndDate, setRegisterRenewEndDate] = useState(null);
  const typeTextBtn = useMemo(() => {
    const type = match.params.type;

    let text = "ดำเนินการ";
    switch (type) {
      case "redeem":
        text = "แลก";
        break;
      case "subscription":
        text = "จองซื้อ";
        break;
      case "reserve":
        text = "จองแลก";
        break;
      case "pre-sale":
        text = "สั่งซื้อ";
        break;
      case "pre-redeem":
        text = "สั่งแลก";
        break;
      default:
        text = "ซื้อ";
        break;
    }
    return text;
  }, [match.params.type]);

  useEffect(() => {
    return () => source.cancel("unmounted");
  }, []);

  useEffect(() => {
    !props.buyer_type.data.length && props.loadBuyerType();
    !props.report_parameter && props.loadReportParameter("receipt");
    document.getElementById("selectId").click();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setOperator((prevOperator) => {
      return {
        ...prevOperator,
        [name]: value,
      };
    });
  };

  const handleClickSearch = async () => {
    setLoading(true);
    setMobileToVerify(null);
    setEmailToVerify(null);
    setPaymentReg(1);
    setOperator((prevOperator) => {
      return {
        buyer_img: prevOperator.buyer_img,
      };
    });
    let found = false;
    await axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        type,
        query: query?.trim(),
      },
      cancelToken: source.token,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            let data = res.data.data[0];
            const is_staff = data.member_type?.toLowerCase() === "staff";
            const status = data.status?.toLowerCase();
            setMobileToVerify(data.mobile_to_verify);
            setEmailToVerify(data.verified_email);
            setPaymentReg(data.payment_register || 0);
            setOperator((prevOperator) => {
              return {
                ...prevOperator,
                buyer_code: data.code,
                buyer_name: `${data.first_name_th} ${data.sur_name_th}`,
                buyer_mobile: data.mobile,
                approve_id_card: data.approve_id_card,
                buyer_email: data.email,
                buyer_type: data.member_type_name,
                team_name: data.team_name,
                allow_renew: data.allow_renew === 1,
                allow_item:
                  is_staff || ["active", "pending", "waiting"].includes(status),
              };
            });
            setBuyer({
              member_id: data.member_id,
              partner_code: data.code,
              partner_name: `${data.first_name_th} ${data.sur_name_th}`,
              partner_mobile: data.mobile,
              approve_id_card: data.approve_id_card,
              partner_type: data.member_type_name,
              partner_type_code: data.member_type,
              team_name: data.team_name,
              allow_renew: data.allow_renew === 1,
              allow_item:
                is_staff || ["active", "pending", "waiting"].includes(status),
              is_married: data.is_married,
              register_date: data.register_date,
              is_freeze: data.is_freeze,
            });
            setQuery(data.code);
            if (type === "Member") {
              setStatus(status);
              setTextStatus(data.text_status);
              let day_leave = null;
              if (data.expire_date) {
                const exp_date = moment(data.expire_date);
                const current_date = moment().startOf("day");
                day_leave = exp_date.diff(current_date, "days");
              }
              setRegisterRenewEndDate(data.register_renew_end_date);
              setWarnExpireDate(day_leave);
              setWarnBirthDate(isBirthDate(data.birth_date));
              if (
                !["active", "pending", "waiting", "expired"].includes(status)
              ) {
                setPermission(false);
                Modal.warning({
                  title: "ไม่สามารถดำเนินการได้",
                  content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                  okText: "ตกลง",
                });
              } else {
                setPermission(true);
                setPermissionBuyer(true);
                btnSubmitSelfRef.current.focus();
              }
            } else {
              setStatus(null);
              setTextStatus("");
              setWarnExpireDate(null);
              setWarnBirthDate(false);
              setRegisterRenewEndDate(null);
              btnSubmitSelfRef.current.focus();
            }
            found = true;
          } else {
            setLoadList(res.data.data);
            document.getElementById("selectId").click();
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        err.message !== "cancel search" && resCatch(err);
      });
    return found;
  };

  const handleClickSubmitSelf = async () => {
    const { buyer_code } = operator;
    if (buyer_code) {
      if (permission) {
        if (permissionBuyer) {
          // only phase one don't verify, but verify in case redeem only
          // if (mobileToVerify)
          //   return Modal.warning({
          //     title: "ไม่สามารถดำเนินการได้",
          //     content: "กรุณายืนยันเบอร์โทรศัพท์",
          //     okText: "ตกลง",
          //     zIndex: 1080,
          //   });
          if (
            match.params.type === "redeem" &&
            mobileToVerify &&
            operator.buyer_mobile !== "" &&
            !emailToVerify
          )
            return Modal.warning({
              title: "ไม่สามารถดำเนินการได้",
              content: "กรุณายืนยันเบอร์โทรศัพท์ / Email",
              okText: "ตกลง",
              zIndex: 1080,
            });
          if (
            match.params.type === "redeem" &&
            !operator.approve_id_card &&
            operator.buyer_type === "Member"
          ) {
            return Modal.warning({
              title: "ไม่สามารถ Redeem ได้",
              content: "เนื่องจากยังไม่ส่งเอกสารสำเนาบัตรประชาชน",
              okText: "ตกลง",
              zIndex: 1080,
            });
          }
          if (match.params.type === "redeem" && !paymentReg)
            return Modal.warning({
              title: "ไม่สามารถดำเนินการได้",
              content:
                "เนื่องจากยังไม่ได้ทำการจ่ายค่าสมัครสมาชิก และยังไม่ได้ซื้อสินค้าในครั้งแรก",
              okText: "ตกลง",
              zIndex: 1080,
            });
          props.setOperatorValue(operator);
          props.changeStep(2);
          props.addBill({
            ...buyer,
            ralc,
          });
          history.replace(`/pos/${match.params.type}/order`);
        } else {
          Modal.warning({
            title: "ไม่สามารถดำเนินการได้",
            content: `รหัสนักธุรกิจ ${buyer.partner_code} มีสถานะ ${textStatusBuyer}`,
            okText: "ตกลง",
            zIndex: 1080,
          });
        }
      } else {
        Modal.warning({
          title: "ไม่สามารถดำเนินการได้",
          content: `รหัสนักธุรกิจ ${buyer_code} มีสถานะ ${textStatus}`,
          okText: "ตกลง",
          zIndex: 1080,
        });
      }
    } else if (query) {
      const isFound = await handleClickSearch();
      if (isFound) {
        btnSubmitSelfRef.current.click();
      }
    } else {
      document.getElementById("selectId").click();
    }
  };

  const handleClickSubmitOther = async () => {
    const { buyer_code, buyer_name, buyer_mobile, buyer_type } = operator;
    if (operator.buyer_code && buyer_name && buyer_mobile && buyer_type) {
      const check_buyer = await handleCheckBuyer();
      if (permission && check_buyer.status) {
         await setOperator({
          ...operator,
          buyer_code: check_buyer.partner_code || buyer_code,
          buyer_name: check_buyer.partner_name || buyer_name,
          buyer_mobile: check_buyer.partner_mobile || buyer_mobile,
        });
        await props.setOperatorValue({
          ...operator,
          buyer_code: check_buyer.partner_code || buyer_code,
          buyer_name: check_buyer.partner_name || buyer_name,
          buyer_mobile: check_buyer.partner_mobile || buyer_mobile,
        }
      );
        await props.changeStep(2);
        history.replace(`/pos/${match.params.type}/buyer`);
      } else if (!permission) {
        Modal.warning({
          title: "ไม่สามารถดำเนินการได้",
          content: `รหัสนักธุรกิจ ${buyer_code} มีสถานะ ${textStatus}`,
          okText: "ตกลง",
        });
      }
    } else if (query) {
      const isFound = await handleClickSearch();
      if (isFound) {
        btnSubmitOtherRef.current.click();
      }
    } else {
      Modal.warning({
        title: "ไม่สามารถดำเนินการได้",
        content: `กรุณากรอกข้อมูลในครบ`,
        okText: "ตกลง",
      });
    }
    // } else {
    //   document.getElementById("selectId").click();
    // }
  };

  const selectSearchOnClick = () => {
    query && handleClickSearch();
  };

  const selectSearchCancelOnClick = () => {
    source.cancel("cancel search");
    setSource(axios.CancelToken.source());
  };

  const selectSearchOnChange = () => {
    btnSubmitSelfRef.current.focus();
  };

  const selectSearchOnInputKeyDown = (e) => {
    let key = e.key;
    if (key === "Enter" && loadList.length === 0 && query) {
      handleClickSearch();
    } else if (key === "Backspace" || key === "Delete") {
      setQuery("");
      setMobileToVerify(null);
      setEmailToVerify(null);
      setOperator((prevOperator) => {
        return {
          buyer_img: prevOperator.buyer_img,
        };
      });
      setBuyer({});
      setLoadList([]);
    }
  };

  const selectSearchOnSearch = (e) => {
    if (e) {
      setQuery(e.trim());
      setMobileToVerify(null);
      setEmailToVerify(null);
      setOperator((prevOperator) => {
        return {
          buyer_img: prevOperator.buyer_img,
        };
      });
      setBuyer({});
      setLoadList([]);
    }
  };

  const selectSearchOnSelect = (i) => {
    const n = { ...loadList[+i] };
    const partner_main = loadList.find(
      (item) => item.text_suffix !== "คู่ร่วม"
    );
    const is_staff = n.member_type?.toLowerCase() === "staff";
    const status = n.status?.toLowerCase();
    setMobileToVerify(n.mobile_to_verify);
    setEmailToVerify(n.verified_email);
    setOperator((prevOperator) => {
      return {
        ...prevOperator,
        buyer_code: n.code,
        buyer_name: `${n.first_name_th} ${n.sur_name_th}`,
        buyer_mobile: n.mobile,
        approve_id_card: n.approve_id_card,
        buyer_email: n.email,
        buyer_type: n.member_type_name,
        team_name: n.team_name,
      };
    });
    setBuyer({
      member_id: n.member_id,
      partner_code: n.code,
      partner_name: n.partner_name_th, // `${n.first_name_th} ${n.sur_name_th}`,
      partner_mobile: n.mobile,
      approve_id_card: n.approve_id_card,
      partner_type: n.member_type_name,
      partner_type_code: n.member_type,
      team_name: n.team_name,
      allow_renew: n.allow_renew === 1,
      allow_item: is_staff || ["active", "pending", "waiting"].includes(status),
      is_married: n.is_married,
      is_freeze: n.is_freeze,
      register_date: n.register_date,
    });
    if (type === "Member") {
      setStatus(n.status);
      setTextStatus(n.text_status);
      setTextStatusBuyer(n.text_status);
      let day_leave = null;
      if (n.expire_date) {
        const exp_date = moment(n.expire_date);
        const current_date = moment().startOf("day");
        day_leave = exp_date.diff(current_date, "days");
      }
      setWarnExpireDate(day_leave);
      setWarnBirthDate(isBirthDate(n.birth_date));
      setRegisterRenewEndDate(n.register_renew_end_date);
      if (
        !["active", "pending", "waiting", "expired"].includes(
          n.status?.toLowerCase()
        )
      ) {
        setPermission(false);
        Modal.warning({
          title: "ไม่สามารถดำเนินการได้",
          content: `รหัสนักธุรกิจ ${n.code} มีสถานะ ${n.text_status}`,
          okText: "ตกลง",
        });
      } else if (
        !["active", "pending", "waiting", "expired"].includes(status)
      ) {
        setPermission(true);
        setPermissionBuyer(false);
      } else {
        setPermission(true);
        setPermissionBuyer(true);
        btnSubmitSelfRef.current.focus();
      }
    } else {
      setStatus(null);
      setTextStatus("");
      setTextStatusBuyer("");
      setWarnExpireDate(null);
      setWarnBirthDate(false);
      setRegisterRenewEndDate(null);
      btnSubmitSelfRef.current.focus();
    }
  };

  const onSelectBuyerType = useCallback((e) => {
    setType(e);
    setLoadList([]);
    setMobileToVerify(null);
    setEmailToVerify(null);
    setPermission(true)
    setOperator((prevState) => {
      return {
        buyer_img: prevState.buyer_img,
        buyer_document: prevState.buyer_document,
      };
    });
  }, []);
  const handleCheckBuyer = async () => {
    const { buyer_code } = operator;
    const buyer_status = {
      status: true,
      partner_code: "",
      partner_mobile: "",
      partner_name: "",
    };
    try {
      const res = await axios({
        url: `${URL_API}/pos/member-profile/check/buyer`,
        params: {
          buyer_code: buyer_code,
        },
        headers: { Authorization: "Bearer " + getToken() },
      });
      let data_buyer = res.data.data;
      if (["terminated", "legal"].includes(data_buyer?.status.toLowerCase())) {
        Modal.warning({
          title: "ไม่สามารถดำเนินการได้",
          content: `รหัสบัตรประชาชน ${buyer_code} มีสถานะ ${data_buyer.status_text}`,
          okText: "ตกลง",
        });
        buyer_status.status = false
      } else if (
        ["active", "pending", "waiting", "void",'expired'].includes(
          data_buyer?.status.toLowerCase()
        )) {
        buyer_status.partner_code = data_buyer.partner_code
        buyer_status.partner_name = data_buyer.partner_name
        buyer_status.partner_mobile = data_buyer.mobile
      }
      return buyer_status;
    } catch (err) {
      console.log(err);
      // return false;
    }
  };
  return (
    <>
      <div className="pos-full-page pos-operator d-flex justify-content-center">
        <Row
          className="mb-5 w-100"
          style={{ marginTop: "100px", maxWidth: "1920px" }}
        >
          <Col md={{ span: 14, offset: 1 }} xxl={{ span: 12, offset: 2 }}>
            <Typography.Title
              level={1}
              className={`${
                type.toLowerCase() === "other" ? "text-muted" : ""
              } mb-5`}
            >
              ผู้ดำเนินการ
            </Typography.Title>
            <Row type="flex" align="middle" className="my-4">
              <Col md={10}>
                <Typography.Title level={3} className="m-0">
                  ประเภทผู้ดำเนินการ
                </Typography.Title>
              </Col>
              <Col md={14}>
                <SelectBuyerType
                  className="w-100"
                  value={type}
                  disabled={loading}
                  onChange={onSelectBuyerType}
                />
              </Col>
            </Row>

            {type.toLowerCase() === "other" ? (
              <>
                <Row type="flex" align="middle" className="my-4">
                  <Col md={{ span: 14, offset: 10 }}>
                    <SelectBuyerOther
                      className="w-100"
                      value={operator.buyer_type}
                      onChange={(e) =>
                        setOperator((prevState) => {
                          return {
                            ...prevState,
                            buyer_type: e,
                            buyer_type_text: "",
                          };
                        })
                      }
                    />
                  </Col>
                </Row>
                {operator.buyer_type &&
                  operator.buyer_type.toLowerCase() === "other" && (
                    <Row type="flex" align="middle" className="my-4">
                      <Col md={10}>
                        <Typography.Title level={3} className="m-0">
                          ระบุ
                        </Typography.Title>
                      </Col>
                      <Col md={14}>
                        <Input
                          name="buyer_type_text"
                          value={operator.buyer_type_text}
                          onChange={handleOnChange}
                        />
                      </Col>
                    </Row>
                  )}
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      ชื่อ นามสกุล
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    <Input
                      name="buyer_name"
                      value={operator.buyer_name}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Row>
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      เลขบัตรประชาชน
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    <Input
                      name="buyer_code"
                      value={operator.buyer_code}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Row>
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      โทรศัพท์
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    <Input
                      name="buyer_mobile"
                      value={operator.buyer_mobile}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      รหัส
                      {type.toLowerCase() === "member" ? "สมาชิก" : "พนักงาน"}
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    <SelectSearch
                      placeholder={
                        type.toLowerCase() === "staff"
                          ? "รหัสพนักงาน"
                          : "รหัสสมาชิก, เลขบัตรประชาชน, ชื่อ, อีเมล, เบอร์โทร"
                      }
                      loadList={loadList}
                      loading={loading}
                      disabled={loading}
                      value={
                        operator.buyer_code
                          ? operator.buyer_code
                          : query
                          ? query
                          : undefined
                      }
                      onClickSearch={selectSearchOnClick}
                      onClickCancel={selectSearchCancelOnClick}
                      onChange={selectSearchOnChange}
                      onInputKeyDown={selectSearchOnInputKeyDown}
                      onSearch={selectSearchOnSearch}
                      onSelect={selectSearchOnSelect}
                    />
                  </Col>
                </Row>

                {operator.buyer_code && operator.buyer_code !== "" && (
                  <>
                    <Row type="flex" align="middle" className="mb-4">
                      <Col md={10}>
                        <Typography.Title level={3} className="m-0">
                          ชื่อ นามสกุล
                        </Typography.Title>
                      </Col>
                      <Col md={14}>
                        <Typography.Title
                          level={4}
                          className="m-0 d-flex align-items-center font-weight-normal"
                        >
                          {operator.buyer_name && (
                            <>
                              {operator.buyer_name}
                              {type.toLowerCase() === "member" && (
                                <Button
                                  type="link"
                                  size="small"
                                  className="p-0 ml-2"
                                  onClick={() => setShowProfile(true)}
                                >
                                  <span className="d-flex align-items-center bg-pv p-4 text-white rounded-circle">
                                    <FaEye />
                                  </span>
                                </Button>
                              )}
                            </>
                          )}
                        </Typography.Title>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle" className="mb-4">
                      <Col md={10}>
                        <Typography.Title level={3} className="m-0">
                          โทรศัพท์
                        </Typography.Title>
                      </Col>
                      <Col md={14}>
                        {operator.buyer_name && (
                          <Typography.Title
                            level={4}
                            className="m-0 font-weight-normal"
                          >
                            {operator.buyer_mobile}
                          </Typography.Title>
                        )}
                        {mobileToVerify && (
                          <MobileConfirm
                            mobile={mobileToVerify}
                            partnerCode={operator.buyer_code}
                            partnerType={operator.buyer_type}
                            setMobileToVerify={setMobileToVerify}
                          />
                        )}
                      </Col>
                    </Row>
                    {operator.buyer_name && operator.buyer_email && (
                      <Row type="flex" align="middle" className="mb-4">
                        <Col md={10}>
                          <Typography.Title level={3} className="m-0">
                            Email
                          </Typography.Title>
                        </Col>
                        <Col md={14}>
                          <Typography.Title
                            level={4}
                            className="m-0 font-weight-normal"
                          >
                            {operator.buyer_email}
                          </Typography.Title>
                          {!emailToVerify && (
                            <EmailConfirm
                              email={operator.buyer_email}
                              partnerCode={operator.buyer_code}
                              partnerType={operator.buyer_type}
                              emailVerify={operator.email_verify}
                              setEmailToVerify={setEmailToVerify}
                            />
                          )}
                        </Col>
                      </Row>
                    )}
                    <Row type="flex" align="middle" className="mb-4">
                      <Col md={10}>
                        <Typography.Title level={3} className="m-0">
                          องค์กร
                        </Typography.Title>
                      </Col>
                      <Col md={14} className="d-flex align-items-center">
                        <Typography.Title
                          level={4}
                          className="m-0 font-weight-normal"
                        >
                          {operator.team_name}
                        </Typography.Title>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle" className="mb-4">
                      <Col md={10}>
                        <Typography.Title level={3} className="m-0">
                          สถานะ
                        </Typography.Title>
                      </Col>
                      <Col md={14}>
                        <div className="d-flex align-items-center">
                          {textStatus && (
                            <>
                              <Typography.Title
                                level={4}
                                className="m-0 font-weight-normal"
                              >
                                {textStatus}
                              </Typography.Title>
                              {warnBirthDate && (
                                <>
                                  <IoIosGift className="text-rp ml-3 mr-2" />
                                  <Typography.Title
                                    level={4}
                                    className="m-0 font-weight-normal text-rp"
                                  >
                                    Happy Birthday
                                  </Typography.Title>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {buyer?.partner_type?.toLowerCase() ==
                          "distributor" && (
                          <>
                            {status == "pending" && (
                              <>
                                <Typography.Text className="m-0 font-weight-normal">
                                  {`(จะปรับเป็นสถานะ 'โมฆะ' วันที่ : ${moment(
                                    buyer?.register_date
                                  )
                                    .add(60, "days")
                                    .format("DD/MM/YYYY")})`}
                                </Typography.Text>
                                <br />
                                <Typography.Text className="m-0 font-weight-normal text-red">
                                  {`(วันสุดท้ายที่สามารถส่งเอกสารได้ ก่อนถูกปรับเป็นสถานะ 'ยกเลิก' คือ `}
                                  <br />
                                  {`วันที่ : ${moment(buyer?.register_date)
                                    .add(120, "days")
                                    .format("DD/MM/YYYY")} ก่อนเวลา 23:59 น.)`}
                                </Typography.Text>
                              </>
                            )}
                            {status == "void" && (
                              <Typography.Text className="m-0 font-weight-normal text-red">
                                {`(วันสุดท้ายที่สามารถส่งเอกสารได้ ก่อนถูกปรับเป็นสถานะ 'ยกเลิก' คือ `}
                                <br />
                                {`วันที่ : ${moment(buyer?.register_date)
                                  .add(120, "days")
                                  .format("DD/MM/YYYY")} ก่อนเวลา 23:59 น.)`}
                              </Typography.Text>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                    <WarningExpireDate
                      status={status}
                      warnExpireDate={warnExpireDate}
                      registerRenewEndDate={registerRenewEndDate}
                    />
                    {warnBirthDate && (
                      <Row type="flex" align="middle" className="mb-4">
                        <Col md={{ span: 14, offset: 10 }}>
                          <Typography.Title
                            level={4}
                            className="m-0 font-weight-normal"
                          >
                            {warnBirthDate}
                          </Typography.Title>
                        </Col>
                      </Row>
                    )}
                    <ProfileCD partnerCode={operator.buyer_code} />
                    <ProfileRalc
                      partnerCode={operator.buyer_code}
                      setRalc={setRalc}
                    />
                  </>
                )}
              </>
            )}
          </Col>
          <Col
            md={{ span: 7, offset: 1 }}
            xxl={{ span: 6, offset: 2 }}
            className="text-center pt-4 d-flex align-items-center flex-column"
          >
            <CameraUpload
              buyer={buyer}
              operator={operator}
              setOperator={setOperator}
            />
            <Typography.Paragraph className="d-flex justify-content-center mt-5 w-100">
              {type.toLocaleLowerCase() === "member" ||
              type.toLocaleLowerCase() === "staff" ? (
                <>
                  <button
                    ref={btnSubmitSelfRef}
                    type="button"
                    className="pos-btn pos-btn-lg bg-pv text-white text-nowrap mr-3"
                    onClick={handleClickSubmitSelf}
                    onKeyUp={(e) =>
                      e.key === "ArrowRight" &&
                      btnSubmitOtherRef.current.focus()
                    }
                    tabIndex={2}
                  >
                    {typeTextBtn}เอง
                  </button>
                  <button
                    ref={btnSubmitOtherRef}
                    type="button"
                    className="pos-btn pos-btn-lg bg-black text-white text-nowrap"
                    onClick={handleClickSubmitOther}
                    onKeyUp={(e) =>
                      e.key === "ArrowLeft" && btnSubmitSelfRef.current.focus()
                    }
                    tabIndex={3}
                  >
                    {typeTextBtn}ให้
                  </button>
                </>
              ) : (
                <button
                  ref={btnSubmitOtherRef}
                  type="button"
                  className="pos-btn pos-btn-lg bg-pv text-white text-nowrap"
                  onClick={() => handleClickSubmitOther()}
                  onKeyUp={(e) =>
                    e.key === "ArrowLeft" && btnSubmitSelfRef.current.focus()
                  }
                  tabIndex={3}
                >
                  ตกลง
                </button>
              )}
            </Typography.Paragraph>
          </Col>
        </Row>
      </div>

      {type.toLowerCase() === "member" && (
        <MemberProfilePopup
          visible={showProfile}
          setVisible={setShowProfile}
          partnerCode={operator.buyer_code}
          refId={buyer.member_id}
          refType={buyer.partner_type}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    buyer_type: state.pos.data.buyer_type,
    report_parameter: state.pos.data.report_parameter,
  };
};

export default connect(mapStateToProps, {
  setOperatorValue: Creators.setOperatorValue,
  changeStep: Creators.changeStep,
  addBill: Creators.addBill,
  loadReportParameter: PosDataAction.loadReportParameter,
  loadBuyerType: PosDataAction.loadBuyerType,
})(PageOperator);
