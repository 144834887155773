/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Modal,
  notification,
  Row,
  Spin,
} from "antd";
import axios from "axios";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useReducer, useState } from "react";
import { _, getToken, moment, URL_API } from "../../../../../../utils";
import { resCatch } from "../../../../../util/helper";
import { mapFileList } from "../../../../../util/helper/file";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import UsageStatus from "../../components/UsageStatus";
import { initState, reducer, VoucherContext } from "../reducers";
import Category from "./components/Category";
import CollectPoint from "./components/CollectPoint";
import Detail from "./components/Detail";
import ImageUpload from "./components/ImageUpload";
import Type from "./components/Type";
import VoucherPlatform from "./components/VoucherPlatform";

const VOUCHER_PLATFORM = {
  all: "all",
  shopping: "shopping",
  pos: "pos",
};

const Index = ({
  match: { params },
  history,
  form: {
    getFieldDecorator,
    getFieldValue,
    getFieldsValue,
    setFieldsValue,
    validateFields,
  },
}) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const categoryGroup = state.categoryGroup;
  const [countDays, setCountDays] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [isUsed, setIsUsed] = useState(0);

  const _handlePlatform = (shopping, pos) => {
    if (shopping && pos) {
      return VOUCHER_PLATFORM.all;
    } else if (shopping && !pos) {
      return VOUCHER_PLATFORM.shopping;
    } else if (!shopping && pos) {
      return VOUCHER_PLATFORM.pos;
    } else {
      return null;
    }
  };

  const loadExpireType = (type) => {
    return type === 1
      ? "expire_date"
      : type === 2
      ? "count_day"
      : type === 3
      ? "none_expire"
      : null;
  };
  const postExpireType = (type) => {
    return type === "expire_date"
      ? 1
      : type === "count_day"
      ? 2
      : type === "none_expire"
      ? 3
      : null;
  };

  const loadDetail = () => {
    dispatch({ type: "SET_LOADING", payload: true });
    axios({
      method: "get",
      baseURL: URL_API,
      url: `/setting/master/voucher/${params.id}`,
    })
      .then(async (res) => {
        if (res.data.total === 0) {
          history.push("/setting/master/voucher");
          Modal.error({
            title: "ไม่สามารถดำเนินการได้",
            content: "ไม่พบข้อมูลบัตรกำนัล",
            okText: "ตกลง",
            zIndex: 1080,
          });
        } else {
          const { id, media, description, category_group, ...data } =
            res.data.data;
          dispatch({ type: "SET_CATEGORY_GROUP", payload: category_group });
          setFieldsValue({
            ...data,
            shopping:
              data.platform === VOUCHER_PLATFORM.all ||
              data.platform === VOUCHER_PLATFORM.shopping,
            pos:
              data.platform === VOUCHER_PLATFORM.all ||
              data.platform === VOUCHER_PLATFORM.pos,
            status: data.status?.toLowerCase() === "active",
            collect_point: !!data.collect_point,
            coordinate: !!data.coordinate,
            lifetime: !data.end_date,
            start_date: data.start_date ? moment(data.start_date) : undefined,
            end_date: data.end_date ? moment(data.end_date) : undefined,
            voucher_type: data.voucher_type,
            expire_type: loadExpireType(data.expire_type),
            expire_date:
              data.expire_type === "expire_date"
                ? moment(data?.expire_date)
                : null,
            count_day: data?.count_day,
          });
          setCountDays(data?.count_day);
          setExpireDate(data?.expire_date);
          setIsUsed(data.is_used);
          dispatch({
            type: "SET_LOAD_SUCCESS",
            payload: { id, media, description },
          });
        }
      })
      .catch((e) => {
        history.push("/setting/master/voucher");
        resCatch(e);
      });
  };

  const onFinish = (values) => {
    let source = axios.CancelToken.source();
    if (values?.category_type === "custom" && categoryGroup.length <= 0) {
      message.open({
        type: "error",
        content: "กรุณาเลือกกลุ่มสินค้าอย่างน้อย 1 รายการ!",
      });
      return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          const data = {
            ..._.omit(values, ["id", "ref_type"]),
            ...mapFileList(state.media),
            description: state.data.description
              ? draftToHtml(
                  convertToRaw(state.data.description.getCurrentContent())
                )
              : null,
            status: values.status ? "active" : "inactive",
            collect_point: values.collect_point ? 1 : 0,
            // --- ใน phase แรก ยังไม่ต้องใช้งาน [Coordinate] module promotion ---
            // coordinate: values.coordinate ? 1 : 0,
            coordinate: 0, // set 0 ไปก่อนใน phase แรก , 0 = ไม่ใช้ร่วมกับโปรโมชั่นอื่น
            start_date: values.start_date
              ? moment(values.start_date).toDate()
              : null,
            end_date: values.end_date ? moment(values.end_date).toDate() : null,
            voucher_type: values.voucher_type,
            expire_type: postExpireType(values.expire_type),
            expire_date:
              values.expire_type === "expire_date" ? values?.expire_date : null,
            count_day:
              values.expire_type === "count_day" ? values?.count_day : null,
            category_group: categoryGroup,
            platform: _handlePlatform(values?.shopping, values?.pos),
          };

          axios({
            method: "post",
            baseURL: URL_API,
            url: `/setting/master/voucher${
              params.id === "0" || state.isTemplate ? "" : `/${params.id}`
            }`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
            cancelToken: source.token,
          })
            .then(() => {
              resolve();
              notification.success({
                message: "Success",
                description: "บันทึกข้อมูลบัตรกำนัลเรียบร้อย",
              });
              history.push("/setting/master/voucher");
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {
        source.cancel("Operation canceled by the user.");
      },
    });
  };

  const handleClickCopy = () => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content:
        "คุณต้องการสร้างรายการใหม่โดยใช้ข้อมูลตามโปรโมชันนี้หรือไม่ การแก้ไขรายการล่าสุดจะไม่ถูกบันทึก",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch({ type: "SET_TEMPLATE" });
      },
    });
  };

  useEffect(() => {
    if (Number.isNaN(+params.id)) {
      history.push("/setting/master/voucher");
    } else if (params.id !== "0") {
      loadDetail();
    }
  }, []);

  const lifetime = getFieldsValue().lifetime;

  useEffect(() => {
    if (lifetime && !state.loading) {
      setFieldsValue({
        end_date: null,
      });
    }
  }, [lifetime]);

  return (
    <VoucherContext.Provider value={{ state, dispatch }}>
      <Spin spinning={state.loading}>
        <Panel>
          <PanelHeader>บัตรกำนัล</PanelHeader>
          <PanelBody>
            <Row gutter={16} className="mb-3">
              <Col span={12}>
                {params.id !== "0" && !state.isTemplate && (
                  <Button
                    type="primary"
                    className="mr-4"
                    onClick={handleClickCopy}
                  >
                    สร้างรายการใหม่ (คัดลอก)
                  </Button>
                )}
              </Col>
              <Col span={12} className="text-right">
                <Button
                  type="primary"
                  className="mr-4"
                  onClick={() => {
                    validateFields((err, values) => {
                      if (!err) {
                        onFinish(values);
                      }
                    });
                  }}
                >
                  บันทึก
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    history.push("/setting/master/voucher");
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
            </Row>
            <Form layout="vertical">
              <Row gutter={32}>
                <Col md={16}>
                  <Detail getFieldDecorator={getFieldDecorator} />

                  <Divider className="py-3">ประเภท</Divider>
                  <Type
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    type={getFieldsValue().type}
                    expire_type={getFieldsValue().expire_type}
                    expire_date={expireDate}
                    count_day={countDays}
                    is_used={isUsed}
                  />
                  <Divider className="py-3">กลุ่มสินค้า</Divider>
                  <Category
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    setFieldsValue={setFieldsValue}
                  />
                  <Divider className="py-3">อัปโหลดรูปบัตรกำนัล</Divider>
                  <ImageUpload />
                </Col>
                <Col md={8}>
                  <Divider>สถานะการใช้งาน</Divider>
                  <UsageStatus getFieldDecorator={getFieldDecorator} />

                  {/* ------- #754 : ยังไม่มีต้องใช้งานในตอนนี้ ------------ */}
                  {/* <Divider>วันเวลา</Divider>
                  <Duration
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                  /> */}

                  <Divider>การได้รับคะแนน</Divider>
                  <CollectPoint getFieldDecorator={getFieldDecorator} />

                  <Divider>ช่องทางการใช้งาน</Divider>
                  <VoucherPlatform
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    setFieldsValue={setFieldsValue}
                  />

                  {/* --- ใน phase แรก ยังไม่ต้องใช้งาน [Coordinate] module promotion --- */}
                  {/* <Divider>ใช้ร่วมกับโปรโมชันอื่น</Divider>
                  <Coordinate getFieldDecorator={getFieldDecorator} /> */}
                </Col>
              </Row>
            </Form>
          </PanelBody>
        </Panel>
      </Spin>
    </VoucherContext.Provider>
  );
};

const FormWrapped = Form.create()(Index);

export default FormWrapped;
