import { Button, Card, Tabs } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";
import UploadPanel from "../Panel/UploadPanel";

export default function UploadTabs() {
  const dispatch = useDispatch();
  const active = useSelector(
    (state) => state.ethics.form.active_tab.upload,
    shallowEqual
  );
  const { OPEN_MODAL, SET_ACTIVE_TAB } = actionEthics;

  return (
      <Card
        bordered={false}
        size="small"
        className="mb-3"
        style={{ boxShadow: "0px 2px 4px #ccc" }}
      >
        <Tabs
          size="small"
          activeKey={active}
          onChange={(e) => dispatch(SET_ACTIVE_TAB({ key: "upload", value: e }))}
          tabBarGutter={0}
          tabBarExtraContent={
            <Button
              size="small"
              type="link"
              onClick={() =>
                dispatch(
                  OPEN_MODAL({
                    name: "upload",
                    props: {
                      loading: false,
                      search: {
                        query: "",
                        document_type: "",
                        status: "",
                        page: 1,
                        limit: 10,
                      },
                      source: { data: [], total: 0 },
                    },
                  })
                )
              }
            >
              History
            </Button>
          }
        >
          <Tabs.TabPane tab="เอกสาร" key="document">
            {/* <FormMember /> */}
            <UploadPanel />
          </Tabs.TabPane>
        </Tabs>
      </Card>

  );
}
