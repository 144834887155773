/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import PromotionData from "../components/PromotionData";
import { PromotionContext } from "../reducers";

const Amount = () => {
  const { dispatch } = useContext(PromotionContext);
  useEffect(() => {
    dispatch({
      type: "SET_DATA",
      payload: { condition_type: "item", receive_type: "item" },
    });
  }, []);
  return <PromotionData type="receive" itemType="item" />;
};

export default Amount;
