/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Row } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabUnderLine from "../../../components/TabUnderLine";
import { useHttp } from "../../../hooks/http";
import { actionRequisition } from "../../../redux/slices/requisition";
import { getUserPermission } from "../../../redux/thunk/requisition";
import { moment, URL_API, _ } from "../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import DocumentTable from "./components/DocumentTable";
import SearchDocument from "./components/SelectSearchDocument";

const tabList = [
  { title: "รออนุมัติเอกสาร", value: "pending" },
  { title: "ดำเนินการเรียบร้อย", value: "complete" },
];

const { clearGlobalState, setGlobalState, clear } = actionRequisition;

const Index = ({ match, history }) => {
  const dispatch = useDispatch();
  const { process: process_params } = match.params;
  const {
    query,
    start_date,
    end_date,
    requisition_type,
    warehouse_code,
    limit,
    page,
    department_id,
  } = useSelector((state) => state.requisition.searchDocument);
  const fetch_permission = useSelector(
    (state) => state.requisition.fetch.permission
  );
  const allow_all_branch = useSelector(
    (state) => state.requisition.user.allow_all_branch
  );
  const user_id = useSelector((state) => state.requisition.user.user_id);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/requisition`,
      initialLoad: false,
      params: {
        query,
        limit,
        offset: (page - 1) * limit,
        start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : "",
        end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : "",
        requisition_type,
        warehouse_code,
        process_params,
        allow_all_branch,
        department_id,
      },
      token: true,
    },
    []
  );

  useEffect(() => {
    if (allow_all_branch) reload();
  }, [
    page,
    query,
    start_date,
    end_date,
    requisition_type,
    warehouse_code,
    process_params,
    allow_all_branch,
    department_id,
  ]);

  useEffect(() => {
    return () => {
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      if (path !== "requisition") dispatch(clear());
    };
  }, []);

  useEffect(() => {
    if (user_id === null) dispatch(getUserPermission());
  }, [user_id]);

  useEffect(() => {
    if (data?.data.user) {
      dispatch(setGlobalState({ name: "user", value: { ...data.data.user } }));
    }
  }, [data?.data.user]);

  const handleClickProcess = useCallback((pathname) => {
    dispatch(clearGlobalState("searchDocument"));
    history.push({ pathname });
  }, []);

  if (error || fetch_permission.error)
    return (
      <Alert type="error" showIcon message={error || fetch_permission.error} />
    );

  return (
    <Panel>
      <PanelHeader>เบิกสินค้า</PanelHeader>
      <PanelBody>
        <Row className="mb-4 mt-3">
          <Col span={18} offset={3} xxl={{ span: 12, offset: 6 }}>
            <TabUnderLine
              active={process_params}
              list={tabList}
              onClick={handleClickProcess}
            />
          </Col>
        </Row>
        <SearchDocument />
        <DocumentTable
          data={data?.data.record}
          total={data?.data.total}
          loading={fetch_permission.loading || loading}
        />
      </PanelBody>
    </Panel>
  );
};

export default Index;
