/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button } from 'antdV4';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { Table } from 'antd';
import useAsync from '../../../../utils/useAsync';
import fitStudioService from '../../../../services/fit-studio.service';
import { FormProvider, useForm } from 'react-hook-form';
import {
  classCategoryFormDefaultValues,
  classCategoryFormSchema,
} from '../components/dto/class';
import ClassCategoryModal from '../components/modal/ClassCategoryModal';
import { AlertConfirm } from '../../../util/Alert';
import moment from 'moment';
import { useHistory } from 'react-router';
import { MdDelete } from 'react-icons/md';
import fitClassServices from '../../../../services/fit-class.services';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import classConstants from '../constants/classConstants';

const ClassCategoryPage = () => {
  const history = useHistory();
  const [classCategoryFormOpen, setClassCategoryFormOpen] = useState(false);
  const {
    data: classCategoryLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  fitClassServices.useQueryGetClassCategory(
    {
      page,
      take: classConstants.tableSize,
    },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const classCategoryForm = useForm({
    defaultValues: classCategoryFormDefaultValues,
    resolver: classCategoryFormSchema(),
  });

  const _HandleClassCategoryFormOpen = (formType) => {
    classCategoryForm.setValue('formType', formType);
    setClassCategoryFormOpen(true);
  };

  const _HandleClassCategoryFormClose = () => {
    setClassCategoryFormOpen(false);
  };

  const { execute: setClassCategoryEnable } = useAsync(
    fitStudioService.setClassCategoryEnable,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ['get-class-category'],
          refetchType: 'all',
        });
      },
    }
  );
  const { execute: deleteClassCategory } = useAsync(
    fitStudioService.deleteClassCategory,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ['get-class-category'],
          refetchType: 'all',
        });
      },
    }
  );

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrl}
          src={record.thumbnailImageUrl}
          alt={record.thumbnailImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'หัวข้อ',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'enable',
      key: 'enable',
      render: (_, record) => <span>{record.enable ? 'เปิด' : 'ปิด'}</span>,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.enable ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการมองเห็นหมวดหมู่');
                if (confirm)
                  setClassCategoryEnable({
                    id: record.id,
                    isEnable: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการมองเห็นหมวดหมู่');
                if (confirm)
                  setClassCategoryEnable({
                    id: record.id,
                    isEnable: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              classCategoryForm.setValue('id', record.id);
              _HandleClassCategoryFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบหมวดหมู่');
              if (confirm) deleteClassCategory({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <Panel>
      <PanelHeader>หมวดหมู่คลาสเรียน</PanelHeader>
      <PanelBody>
        <div className="d-flex justify-content-end mb-2">
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleClassCategoryFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มหมวดหมู่
          </Button>
        </div>
        <Table
          scroll={{ x: true }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`classcategory/${record.id}`);
              }, // click row
            };
          }}
          columns={columns}
          dataSource={classCategoryLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize: classConstants.tableSize,
            total: total,
          }}
        />
      </PanelBody>
      <FormProvider {...classCategoryForm}>
        <ClassCategoryModal
          open={classCategoryFormOpen}
          handleClose={_HandleClassCategoryFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default ClassCategoryPage;
