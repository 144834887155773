import { useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const fitRewardServices = {
  useQueryGetRewardById({ rewardId }, onSuccess) {
    return useQuery(
      ['get-fit-reward-by-id', { rewardId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/fitness-reward/${queryKey[1].rewardId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        enabled: !!rewardId,
        onSuccess,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetUserRewardByRewardId({ rewardId, page, take, isMax }, onSuccess) {
    return useQuery(
      ['get-fit-user-reward-by-reward-id', { rewardId, page, take, isMax }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/fitness-user-reward/get-user-reward-by-reward-id/${queryKey[1].rewardId}`,
            {
              params: {
                page,
                take,
                isMax: isMax ? 'true' : 'false',
              },
            }
          );

          return res.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        enabled: !!rewardId,
        onSuccess,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
};

export default fitRewardServices;
