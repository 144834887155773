import { Col, Input, Row } from 'antd'
import React from 'react'

const UpLineForm = ({Member}) => {
    return (
        <>
            <Row className="mb-2">
                <Col md={6} className="p-3">
                    <label>รหัสนักธุรกิจ</label>
                    <Input size="small" className="f-s-12" disabled value={Member?.up_line_code}/>
                </Col>
                <Col md={6} className="p-3">
                    <label>ชื่อ</label>
                    <Input size="small" className="f-s-12" disabled value={Member?.first_name}/>
                </Col>
                <Col md={6} className="p-3">
                    <label>นามสกุล</label>
                    <Input size="small" className="f-s-12" disabled value={Member?.sur_name}/>
                </Col>
                <Col md={6} className="p-3">
                    <label>เบอร์โทรศัพท์</label>
                    <Input size="small" className="f-s-12" disabled value={Member?.mobile}/>
                </Col>
                <Col md={6} className="p-3">
                    <label>ทีมงานด้าน</label>
                    <Input size="small" className="f-s-12" disabled value={Member?.chart_type !== 'R'? 'ซ้าย' : 'ขวา'}/>
                </Col>
            </Row>
        </>
    )
}
export default UpLineForm