import { Col, Row } from "antd";
import React, { useContext } from "react";
import Radio from "../../../Radio";
import InfoContext from "./reducer";

const SelectMemberType = () => {
  const {
    state: { memberType },
    dispatch,
  } = useContext(InfoContext);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "setState",
      payload: { name, value },
    });
  };

  return (
    <Row gutter={[8, 8]} type="flex">
      <Col span={24} className="d-flex">
        <Radio
          id="member"
          name="memberType"
          spanChild="ผู้สมัครหลัก"
          value="member"
          checked={memberType === "member"}
          onChange={handleOnChange}
        />
        <Radio
          id="married"
          name="memberType"
          spanChild="ผู้สมัครร่วม"
          value="married"
          checked={memberType === "married"}
          onChange={handleOnChange}
        />
      </Col>
    </Row>
  );
};

export default SelectMemberType;
