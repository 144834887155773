/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Input, Radio, Row } from "antd";
import _ from "lodash";
import React, { forwardRef, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectAddressMaster from "../../../../../components/SelectAddressMaster";
import { actionEthics } from "../../../../../redux/slices/ethics";

function FormAddress({ form, address_type }) {
  const dispatch = useDispatch();
  const { address, married, is_company, member, type } = useSelector(
    (state) => state.ethics.form,
    shallowEqual
  );

  const { SET_FORM_ADDRESS } = actionEthics;
  const secondatyCode = married?.member_code;

  const infoAddress = useSelector(
    (state) => state.ethics.findMarried,
    shallowEqual
  )?.infoAddress;

  const findMarried = useSelector(
    (state) => state.ethics.findMarried,
    shallowEqual
  );
  useEffect(() => {
    form.resetFields();
    if (infoAddress && address_type == 5) {
      form.setFieldsValue({
        address: infoAddress?.address,
        province_id: infoAddress?.province_id,
        country: infoAddress?.country,
        district_id: infoAddress?.district_id,
        sub_district_id: infoAddress?.sub_district_id,
        post_code: infoAddress?.post_code,
      });
    } else if (address) {
      const _data = _.find(address, (n) => n.address_type == address_type);
      if (_data)
        form.setFieldsValue({
          address: _data.address,
          province_id: _data.province_id,
          country: _data.country,
          district_id: _data.district_id,
          sub_district_id: _data.sub_district_id,
          post_code: _data.post_code,
        });
    }
  }, [infoAddress, address, address_type]);

  const { getFieldDecorator, getFieldValue } = form;
  return (
    <>
      <Form layout="vertical" labelAlign="right" className="form-small">
        <Row gutter={8}>
          <Col md={24}>
            <Form.Item label="ที่อยู่" className="mb-0">
              {getFieldDecorator("country", {
                initialValue: null,
                rules: [
                  {
                    required:
                      (!married && address_type == 5) || type === "member"
                        ? false
                        : true,
                    message: "กรุณาระบุที่อยู่",
                  },
                ],
                onChange: (e, option) => {
                  form.setFieldsValue({
                    country: e.target.value,
                    province_id: undefined,
                    province: undefined,
                    district_id: undefined,
                    district: undefined,
                    sub_district_id: undefined,
                    sub_district: undefined,
                    post_code: undefined,
                  });
                  dispatch(
                    SET_FORM_ADDRESS({
                      country: e.target.value,
                      province_id: undefined,
                      province: undefined,
                      district_id: undefined,
                      district: undefined,
                      sub_district_id: undefined,
                      sub_district: undefined,
                      post_code: undefined,
                      address_type,
                    })
                  );

                  // if (address_type == 1 && member?.is_company == 0) {
                  //   dispatch(
                  //     SET_FORM_ADDRESS({
                  //       country: e.target.value,
                  //       province_id: undefined,
                  //       province: undefined,
                  //       district_id: undefined,
                  //       district: undefined,
                  //       sub_district_id: undefined,
                  //       sub_district: undefined,
                  //       post_code: undefined,
                  //       address_type: 4,
                  //     })
                  //   );
                  // }
                },
              })(
                <Radio.Group
                  size="small"
                  className="font-size-12"
                  disabled={
                    (secondatyCode || findMarried) && address_type === 5
                  }
                >
                  <Radio value={"ไทย"}>ไทย</Radio>
                  <Radio value={"ต่างประเทศ"}>ต่างประเทศ</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            ฺ
            <Form.Item className="mb-0">
              {getFieldDecorator("address", {
                initialValue: null,
                rules: [
                  {
                    required: secondatyCode || findMarried ? false : true,
                    asyncValidator: (rule, value) => {
                      return new Promise((resolve, reject) => {
                        if (address_type === 2 && type === "member") {
                          resolve();
                          return;
                        }
                        if (
                          (!value || value.trim() === "") &&
                          type !== "member"
                        ) {
                          reject("กรุณาระบุที่อยู่");
                        } else {
                          resolve();
                        }
                        const regex =
                          // /^(?!.*[฿๏ํฺ])[\u0E00-\u0E7Fa-zA-Z0-9\s\/\.\-่-๋็-ํ]+$/u;

                          /^(?!.*[฿๏ํฺ])(?:(?![\n\r]).)[\u0E00-\u0E7Fa-zA-Z0-9\s\/\.\-่-๋็-ํ]+$/u;

                    
                        if (!regex.test(value)) {
                          reject(
                            "ที่อยู่สามารถใส่ได้เฉพาะภาษาไทย ภาษาอังกฤษ ตัวเลข เว้นวรรค และอักขระ . / - เท่านั้น"
                          );
                        }
                        resolve();
                      });
                    },
                  },
                ],
                onChange: (e) => {
                  dispatch(
                    SET_FORM_ADDRESS({
                      address: e.target.value,
                      address_type: address_type,
                    })
                  );
                },
              })(
                <Input.TextArea
                  disabled={
                    (secondatyCode || findMarried) && address_type === 5
                  }
                  size="small"
                  rows={3}
                  placeholder="ที่อยู่"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); 
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col md={6}>
            <Form.Item
              label="จังหวัด"
              className="mb-0"
              style={
                getFieldValue("country") === "ไทย" ? "" : { display: "none" }
              }
            >
              {getFieldDecorator("province_id", {
                initialValue: null,
                rules: [
                  {
                    required:
                      getFieldValue("country") === "ไทย" &&
                      type === "distributor",
                    message: "กรุณาระบุจังหวัด",
                  },
                ],
                onChange: (e, option) => {
                  form.setFieldsValue({
                    province_id: e,
                    province: option.props.source.name,
                    district_id: undefined,
                    district: undefined,
                    sub_district_id: undefined,
                    sub_district: undefined,
                    post_code: undefined,
                  });
                  dispatch(
                    SET_FORM_ADDRESS({
                      province_id: e,
                      province: option.props.source.name,
                      district_id: undefined,
                      district: undefined,
                      sub_district_id: undefined,
                      sub_district: undefined,
                      post_code: undefined,
                      address_type,
                    })
                  );
                  if (address_type == 1 && member?.is_company == 0) {
                    dispatch(
                      SET_FORM_ADDRESS({
                        province_id: e,
                        province: option.props.source.name,
                        district_id: undefined,
                        district: undefined,
                        sub_district_id: undefined,
                        sub_district: undefined,
                        post_code: undefined,
                        address_type: 4,
                      })
                    );
                  }
                },
              })(
                <SelectAddressMaster
                  disabled={
                    (secondatyCode || findMarried) && address_type === 5
                  }
                  size="small"
                  className="w-100 font-size-12"
                  type="province"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.indexOf(input.toLowerCase()) >= 0
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item
              label="อำเภอ/เขต"
              className="mb-0"
              style={
                getFieldValue("country") === "ไทย" ? "" : { display: "none" }
              }
            >
              {getFieldDecorator("district_id", {
                initialValue: null,
                rules: [
                  {
                    required:
                      getFieldValue("country") === "ไทย" &&
                      type === "distributor",
                    message: "กรุณาระบุอำเภอ/เขต",
                  },
                ],
                onChange: (e, option) => {
                  form.setFieldsValue({
                    district_id: e,
                    district: option.props.source.name,
                    sub_district_id: undefined,
                    sub_district: undefined,
                    post_code: undefined,
                  });
                  dispatch(
                    SET_FORM_ADDRESS({
                      district_id: e,
                      district: option.props.source.name,
                      sub_district_id: undefined,
                      sub_district: undefined,
                      post_code: undefined,
                      address_type,
                    })
                  );
                  if (address_type == 1 && member?.is_company == 0) {
                    dispatch(
                      SET_FORM_ADDRESS({
                        district_id: e,
                        district: option.props.source.name,
                        sub_district_id: undefined,
                        sub_district: undefined,
                        post_code: undefined,
                        address_type: 4,
                      })
                    );
                  }
                },
              })(
                <SelectAddressMaster
                  disabled={
                    (secondatyCode || findMarried) && address_type === 5
                  }
                  size="small"
                  className="w-100 font-size-12"
                  type="district"
                  showSearch
                  province_id={form.getFieldValue("province_id")}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item
              label="ตำบล/แขวง"
              className="mb-0"
              style={
                getFieldValue("country") === "ไทย" ? "" : { display: "none" }
              }
            >
              {getFieldDecorator("sub_district_id", {
                initialValue: null,
                rules: [
                  {
                    required:
                      getFieldValue("country") === "ไทย" &&
                      type === "distributor",
                    message: "กรุณาระบุตำบล/แขวง",
                  },
                ],
                onChange: (e, option) => {
                  form.setFieldsValue({
                    sub_district_id: e,
                    sub_district: option.props.source.name,
                    post_code: option.props.source.post_code,
                  });
                  dispatch(
                    SET_FORM_ADDRESS({
                      sub_district_id: e,
                      sub_district: option.props.source.name,
                      post_code: option.props.source.post_code,
                      address_type,
                    })
                  );
                  if (address_type == 1 && is_company == 0) {
                    dispatch(
                      SET_FORM_ADDRESS({
                        sub_district_id: e,
                        sub_district: option.props.source.name,
                        post_code: option.props.source.post_code,
                        address_type: 4,
                      })
                    );
                  }
                },
              })(
                <SelectAddressMaster
                  disabled={
                    (secondatyCode || findMarried) && address_type === 5
                  }
                  size="small"
                  className="w-100 font-size-12"
                  type="subdistrict"
                  showSearch
                  district_id={form.getFieldValue("district_id")}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item
              label="รหัสไปรษณีย์"
              className="mb-0"
              style={
                getFieldValue("country") === "ไทย" ? "" : { display: "none" }
              }
            >
              {getFieldDecorator("post_code", {
                initialValue: null,
                rules: [
                  {
                    required:
                      getFieldValue("country") === "ไทย" &&
                      type === "distributor",
                    message: "กรุณาระบุรหัสไปรษณีย์",
                  },
                ],
                onChange: () => {},
              })(
                <Input
                  disabled={
                    (secondatyCode || findMarried) && address_type === 5
                  }
                  size="small"
                  placeholder="รหัสไปรษณีย์"
                  className="font-size-12"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Form.create("ethics-address")(forwardRef(FormAddress));
