/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Modal, Table, Tooltip } from "antd";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { FaCog, FaCogs, FaPenAlt, FaTrashAlt } from "react-icons/fa";
import { Link, useRouteMatch } from "react-router-dom";
import RoleContext from "../../../../../../contexts/role.context";
import { getCancelToken, useApi } from "../../../../../../hooks/http2";
import { getToken, URL_API } from "../../../../../../utils";

const TableRole = () => {
  const match = useRouteMatch();
  const { state, dispatch } = useContext(RoleContext);
  const { loading, fetch, error, reload } = useApi(
    {
      url: "setting/permission/role",
      token: true,
      params: {
        limit: state.filter.limit,
        offset: (state.filter.page - 1) * state.filter.limit,
        query: state.filter.query,
      },
    },
    [state.filter]
  );

  let source = getCancelToken();

  const handleOnConfirmDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการยกเลิกสิทธิ์การใช้งานนี้หรือไม่",
      okText: "ลบ",
      cancelText: "ยกเลิก",
      okType: "danger",
      onOk() {
        return new Promise((resolve, reject) => {
          source = getCancelToken();

          axios({
            method: "post",
            baseURL: URL_API,
            url: `setting/permission/role/${id}/delete`,
            headers: { Authorization: "Bearer " + getToken() },
            cancelToken: source.token,
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            Modal.success({
              title: "สำเร็จ",
              content: "ยกเลิกสิทธิ์การใช้งานเรียบร้อย",
              okText: "ปิด",
              onOk() {
                reload();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    if (state.searching) {
      dispatch({ type: "SET_SEARCHING", payload: false });
      reload();
    }
  }, [state.searching]);

  if (error) {
    return <Alert type="error" message={error} />;
  }

  return (
    <Table
      size="small"
      bordered
      loading={loading}
      dataSource={
        fetch?.data ? fetch?.data?.map((n, i) => ({ ...n, key: i })) : []
      }
      pagination={{
        pageSize: state.filter.limit,
        current: state.filter.page,
        size: "default",
        total: fetch?.total ? fetch?.total : 0,
        onChange: (page) => dispatch({ type: "SET_PAGE", payload: page }),
        showLessItems: true,
      }}
    >
      <Table.Column title="ชื่อ" dataIndex="name" />
      <Table.Column title="จำนวนผู้ใช้งาน" dataIndex="users" align="right" />
      <Table.Column title="จำนวน Module" dataIndex="modules" align="right" />
      <Table.Column
        title={<FaCogs />}
        align="center"
        render={(text, record) => (
          <>
            <Tooltip title="กำหนดสิทธิ์">
              <Link to={`${match.path}/${record.id}`}>
                <Button size="small" type="primary" className="mr-2">
                  <FaCog />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip title="แก้ไขชื่อ">
              <Button
                size="small"
                type="primary"
                ghost
                className="mr-2"
                onClick={() =>
                  dispatch({
                    type: "SET_MODAL",
                    payload: {
                      visible: true,
                      title: "แก้ชื่อสิทธิ์การใช้งาน",
                      data: record,
                    },
                  })
                }
              >
                <FaPenAlt />
              </Button>
            </Tooltip>
            <Tooltip title="ยกเลิก">
              <Button
                size="small"
                type="danger"
                onClick={() => handleOnConfirmDelete(record.id)}
              >
                <FaTrashAlt />
              </Button>
            </Tooltip>
          </>
        )}
      />
    </Table>
  );
};

export default TableRole;
