/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API, axios, getToken } from "../../../../utils";
import SelectOption from "../../pos/components/SelectOption";
import { Select, Spin } from "antd";
//import { Option } from "antdV4/lib/mentions";

export default function SelectProduct({ ...props }) {
  //const [filterDataSource, setFilterDataSource] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [fetching, setFetching] = useState(false)
  // const [filterLoading, filterData, filterError, filterReload] = useHttp(
  //   {
  //     url: `${URL_API}/boss/inventory/inventory-transfer-filter`,
  //     token: true,
  //   },
  //   []
  // );
  // useEffect(() => {
  //   //setFilterDataSource(filterData ? filterData : []);
  //   setProductOptions( [
  //     ..._.map(filterData?.dataProduct || [], (product) => {
  //       return { value: product.product_code, text: product.product_name_local };
  //     }),
  //   ])
  // }, [filterData]);

  
  async function getProduct(product_code){
    setProductOptions([]);
    setFetching(true);
    await axios({
      method: "get",
      baseURL: URL_API,
      url: `boss/inventory/inventory-transfer-filter-product`,
      headers: { Authorization: "Bearer " + getToken() },
      params: { product_code},
    }).then((res)=>{
      console.log(res)
      
      const data = res.data.dataProduct.map(product => ({
        text: product.product_name_en,
        value: product.product_code,
      }));
      setProductOptions(data);
      setFetching(false)
    })
    
  }
  return (
    <Select
      // filterOption={(input, option) =>
      //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      //   option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      filterOption = {false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      onSearch={getProduct}
      className="w-100"
      placeholder="รหัสสินค้า,ชื่อสินค้า,SKU"
      //options={productOptions}
      {...props}
    >
      {productOptions.map(d => (
          <Select.Option key={d.value}>{d.text}</Select.Option>
        ))}
    </Select>
  );
}
