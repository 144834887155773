/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, DatePicker, Input, Select, Table, Typography } from 'antd';
import { FaDownload } from 'react-icons/fa';
import usePaginationHook from '../../../../utils/usePagination';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import fitQuizServices from '../../../../services/fit-quiz.services';
import { userQueryDefaultValues } from '../components/dto/legacy-quiz-form.dto';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import _ from 'lodash';
import { FiArrowLeft } from 'react-icons/fi';
import LegacyQuizUserModal from '../components/modal/LegacyQuizUserModal';

dayjs.locale('th');

const pageSize = 10;

const LegacyQuizUserReportPage = () => {
  const history = useHistory();
  const [loading2, setLoading2] = useState(false);
  const {
    data: legacyQuizUserLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const [quizUser, setQuizUser] = useState(undefined);
  const [legacyQuizUserFormOpen, setLegacyQuizUserFormOpen] = useState(false);

  const _HandleLegacyQuizUserFormOpen = (formType) => {
    setLegacyQuizUserFormOpen(true);
  };

  const _HandleLegacyQuizUserFormClose = () => {
    setQuizUser(undefined);
    setLegacyQuizUserFormOpen(false);
  };

  const { control, watch } = useForm({
    defaultValues: userQueryDefaultValues,
  });

  fitQuizServices.useQueryGetLegacyQuizUsers(
    {
      take: -1,
      page: 1,
      memberCode: watch('memberCode'),
      memberType: watch('memberType'),
      isPartner: watch('isPartner'),
      selectedDate: watch('selectedDate').toDate(),
      maxReceived: watch('maxReceived') ? watch('maxReceived') : 0,
    },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'memberCode',
      key: 'memberCode',
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'memberCode',
      key: 'memberCode',
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => (
        <span>{record.user.FirstNameTh + ' ' + record.user.SurNameTh}</span>
      ),
    },
    {
      title: '',
      dataIndex: 'isPartner',
      key: 'isPartner',
      render: (_, record) => (
        <span>{record.isPartner ? 'รหัสร่วม' : 'รหัสหลัก'}</span>
      ),
    },
    {
      title: 'จำนวนเหรียญทองทั้งหมด',
      dataIndex: 'max',
      key: 'max',
      render: (_, record) => {
        const length = record.userRewards.filter((i) => {
          return i.isMax;
        }).length;
        return <span>{length}</span>;
      },
    },
  ];

  const { mutate: downloadLegacyQuizUserListsExcel } =
    fitQuizServices.useMutationDownloadLegacyQuizUserListsExcel(() => {
      setTimeout(() => {
        setLoading2(false);
      }, 600);
    });

  return (
    <Panel>
      <PanelHeader>สรุปการทำแบบทดสอบ</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <Controller
            name="memberCode"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัสนักธุรกิจ"
                  className="mr-2"
                  style={{ flex: 1 }}
                />
              );
            }}
          />
          <Controller
            name="isPartner"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  value={value}
                  style={{ flex: 1, marginRight: 10 }}
                  onChange={onChange}
                  placeholder="ประเภทรหัสนักธุรกิจ"
                >
                  <Select.Option value={undefined}>--</Select.Option>
                  <Select.Option value={false}>รหัสนักธุรกิจหลัก</Select.Option>
                  <Select.Option value={true}>รหัสนักธุรกิจคู่</Select.Option>
                </Select>
              );
            }}
          />
          <Controller
            name="maxReceived"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  value={value}
                  style={{ flex: 1, marginRight: 10 }}
                  onChange={onChange}
                  placeholder="จำนวนเหรียญทองที่ได้รับ"
                >
                  <Select.Option value={undefined}>--</Select.Option>
                  <Select.Option value={1}>
                    รางวัลเหรียญทอง 1-10 เหรียญ
                  </Select.Option>
                  <Select.Option value={2}>
                    รางวัลเหรียญทอง 11-20 เหรียญ
                  </Select.Option>
                  <Select.Option value={3}>
                    รางวัลเหรียญทอง 21-30 เหรียญ
                  </Select.Option>
                  <Select.Option value={4}>
                    รางวัลเหรียญทอง 30 เหรียญขึ้นไป
                  </Select.Option>
                </Select>
              );
            }}
          />
          <Controller
            name="selectedDate"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography.Text className="mr-2">
                    เดือน/ปี ที่ได้รับเหรียญ:
                  </Typography.Text>
                  <DatePicker.MonthPicker
                    format="MMM/YYYY"
                    value={value}
                    onChange={(date, dateString) => {
                      onChange(moment(date));
                    }}
                    className="mr-2"
                  />
                </div>
              );
            }}
          />
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              setLoading2(true);
              downloadLegacyQuizUserListsExcel({
                take: -1,
                page: 1,
                memberCode: watch('memberCode'),
                memberType: watch('memberType'),
                isPartner: watch('isPartner'),
                selectedDate: watch('selectedDate').toDate(),
                maxReceived: watch('maxReceived') ? watch('maxReceived') : 0,
              });
            }}
            loading={loading2}
          >
            <FaDownload />
            ดาวน์โหลด Excel
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={legacyQuizUserLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setQuizUser(record);
                _HandleLegacyQuizUserFormOpen();
              },
            };
          }}
        />
      </PanelBody>
      {quizUser && (
        <LegacyQuizUserModal
          open={legacyQuizUserFormOpen}
          handleClose={_HandleLegacyQuizUserFormClose}
          quizUser={quizUser}
        />
      )}
    </Panel>
  );
};

export default LegacyQuizUserReportPage;
