import React, { useEffect } from "react";
import { Modal } from "antd";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";
const ModalPromotionRemark = ({ visible,partner_code, onCancel }) => {
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/member-profile/promotion-remark`,
      token: true,
      initialLoad: false,
      params: {
        partner_code,
      },
    },
    []
  );
  useEffect(()=>{
    if(visible == true)reload();
  },[visible])
  return (
    <Modal
      title="ตรวจสอบโปรโมชั่น"
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <div dangerouslySetInnerHTML={{ __html: data?.data?.remark }} />
    </Modal>
  );
};

export default ModalPromotionRemark;
