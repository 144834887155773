import React from 'react';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import moment from 'moment';
import { AlertConfirm } from '../../../util/Alert';
import { FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import publicPlaylistConstants from '../constants/publicPlaylistConstants';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  playlistFormDefaultValues,
  playlistFormSchema,
} from '../components/dto/public-playlist';
import { FormProvider, useForm } from 'react-hook-form';
import PublicPlaylistModal from '../components/modal/PublicPlaylistModal';

const PublicPlaylistPage = () => {
  const [page, setPage] = useState(1);
  const [playlistFormOpen, setPlaylistFormOpen] = useState(false);

  const playlistForm = useForm({
    defaultValues: playlistFormDefaultValues,
    resolver: playlistFormSchema(),
  });

  const _HandlePlaylistFormOpen = (formType) => {
    playlistForm.setValue('formType', formType);
    setPlaylistFormOpen(true);
  };

  const _HandlePlaylistFormClose = () => {
    playlistForm.reset(playlistFormDefaultValues);
    setPlaylistFormOpen(false);
  };

  const { data: publicPlaylistLists, execute: getPublicPlaylist } = useAsync(
    fitStudioService.getPublicPlaylist,
    {
      immediate: false,
    }
  );

  const { data: test, execute: deletePublicPlaylistById } = useAsync(
    fitStudioService.deletePublicPlaylistById,
    {}
  );

  useEffect(() => {
    getPublicPlaylist({
      page: page,
      take: publicPlaylistConstants.tableSize,
    });
  }, [page, getPublicPlaylist, test]);

  const columns = [
    {
      title: 'รูป Thumbnail',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrl}
          src={record.thumbnailImageUrl}
          alt={record.thumbnailImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'รูป Cover',
      dataIndex: 'coverImageUrl',
      key: 'coverImageUrl',
      render: (_, record) => (
        <img
          key={record.coverImageUrl}
          src={record.coverImageUrl}
          alt={record.coverImageUrl}
          style={{
            width: 150,
            height: 100,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'หัวข้อ',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              playlistForm.setValue('id', record.id);
              _HandlePlaylistFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบ Playlist');
              if (confirm) deletePublicPlaylistById({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <Panel>
      <PanelHeader>Public Playlist</PanelHeader>
      <PanelBody>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: 10,
          }}
        >
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandlePlaylistFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มเพลย์ลิสท์
          </Button>
        </div>
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => {
          //       history.push(`public-playlist/${record.id}`);
          //     }, // click row
          //   };
          // }}
          columns={columns}
          dataSource={
            publicPlaylistLists?.data?.length > 0
              ? publicPlaylistLists?.data
              : []
          }
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize: publicPlaylistConstants.tableSize,
            total: publicPlaylistLists?.meta?.total || 0,
          }}
        />
      </PanelBody>
      <FormProvider {...playlistForm}>
        <PublicPlaylistModal
          open={playlistFormOpen}
          handleClose={_HandlePlaylistFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default PublicPlaylistPage;
