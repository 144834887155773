import React, { useEffect, useState } from "react";
import { Alert, Select } from "antd";
import { useHttp } from "../hooks/http";
import { URL_API, _ } from "../utils";

const SelectMasterAllType = ({
  processType,
  className = "",
  withAll = false,
  callBackData,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/global/master/all-type`,
      token: true,
      params: { process_type: processType },
    },
    [processType]
  );

  useEffect(() => {
    let newData = [];
    if (withAll) {
      newData = [{ value: "", name: "ทั้งหมด" }];
    }
    if (data && data.data.length > 0) {
      newData = [
        ...newData,
        ..._.map(data.data, (n) => ({ value: n.code, name: n.name })),
      ];
      callBackData && callBackData(data);
    }
    setOptions(newData);
  }, [data, withAll]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Alert type="error" message={error} showIcon />;

  return (
    <Select loading={loading} className={`w-100 ${className}`} {...props}>
      {_.map(options, (n, i) => (
        <Select.Option key={i} value={n.value}>
          {n.name}
        </Select.Option>
      ))}
    </Select>
  );
};
export default SelectMasterAllType;
