/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import { useHistory } from 'react-router';
import {
  legacyQuizCategoryProductFormDefaultValues,
  legacyQuizCategoryProductFormSchema,
} from '../components/dto/legacy-quiz-form.dto';
import fitQuizServices from '../../../../services/fit-quiz.services';
import LegacyQuizCategoryProductModal from '../components/modal/LegacyQuizCategoryProductModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { FiArrowLeft } from 'react-icons/fi';

dayjs.locale('th');

const pageSize = 10;

const LegacyQuizCategoryProductPage = () => {
  const params = useParams();
  const [
    legacyQuizCategoryProductFormOpen,
    setLegacyQuizCategoryProductFormOpen,
  ] = useState(false);
  const {
    data: legacyQuizCategoryProductLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();

  const legacyQuizCategoryProductForm = useForm({
    defaultValues: legacyQuizCategoryProductFormDefaultValues,
    resolver: legacyQuizCategoryProductFormSchema(),
  });

  const _HandleLegacyQuizCategoryProductFormOpen = (formType) => {
    legacyQuizCategoryProductForm.setValue('formType', formType);
    setLegacyQuizCategoryProductFormOpen(true);
  };

  const _HandleLegacyQuizCategoryProductFormClose = () => {
    legacyQuizCategoryProductForm.reset(
      legacyQuizCategoryProductFormDefaultValues
    );
    setLegacyQuizCategoryProductFormOpen(false);
  };

  fitQuizServices.useQueryGetLegacyQuizCategoryProductLists(
    { take: pageSize, page, cageoryId: params.categoryId },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyQuizCategoryProduct } =
    fitQuizServices.useMutationDeleteLegacyQuizCategoryProduct(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category-product'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyQuizCategoryProductDisplay } =
    fitQuizServices.useMutationUpdateLegacyQuizCategoryProductDisplay(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category-product'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <div>
          <img
            alt={record.title}
            src={record.imageUrl}
            style={{
              width: 50,
              height: 50,
            }}
          />
        </div>
      ),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'display',
      key: 'display',
      render: (_, record) => <span>{record.display ? 'เปิด' : 'ปิด'}</span>,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.display ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm(
                  'ปิดการแสดง Legacy Quiz Product'
                );
                if (confirm)
                  updateLegacyQuizCategoryProductDisplay({
                    id: record.id,
                    display: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm(
                  'เปิดการแสดง Legacy Quiz Product'
                );
                if (confirm)
                  updateLegacyQuizCategoryProductDisplay({
                    id: record.id,
                    display: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyQuizCategoryProductForm.setValue('id', record.id);
              _HandleLegacyQuizCategoryProductFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyQuizCategoryProduct({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
      </div>
      <Panel>
        <PanelHeader>Legacy Quiz Product</PanelHeader>
        <PanelBody>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="mb-2"
          >
            <div style={{ flex: 1 }} />
            <Button
              className="bg-pv border-pv text-white"
              onClick={() => {
                _HandleLegacyQuizCategoryProductFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่มผลิตภัณฑ์
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={legacyQuizCategoryProductLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(
                    `/questionaire/quiz/quiz-category/${params.categoryId}/quiz-product/${record.id}`
                  );
                },
              };
            }}
          />
        </PanelBody>
        <FormProvider {...legacyQuizCategoryProductForm}>
          <LegacyQuizCategoryProductModal
            open={legacyQuizCategoryProductFormOpen}
            handleClose={_HandleLegacyQuizCategoryProductFormClose}
          />
        </FormProvider>
      </Panel>
    </>
  );
};

export default LegacyQuizCategoryProductPage;
