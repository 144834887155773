/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Row } from "antd";
import React, { useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import SearchDate from "../../../../../components/SearchDate";
import SelectLayout from "../../../../../components/SelectLayout";
import SelectMasterType from "../../../../../components/SelectMasterType";
import { actionClaim } from "../../../../../redux/slices/claim";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectStatus from "./SelectStatus";

const { setGlobalState } = actionClaim;

const Index = ({ match }) => {
  const process_params = match.params.process;
  const dispatch = useDispatch();
  const {
    query,
    start_date,
    end_date,
    user_branch_code,
    branch_code,
    claim_type,
  } = useSelector((state) => state.claim.searchDocument);

  const handleChangeStart = useCallback((start_date) => {
    dispatch(
      setGlobalState({
        name: "searchDocument",
        value: {
          start_date,
          end_date: null,
          page: 1,
        },
      })
    );
  }, []);

  const handleChangeEnd = useCallback((end_date) => {
    dispatch(
      setGlobalState({
        name: "searchDocument",
        value: {
          end_date,
          page: 1,
        },
      })
    );
  }, []);

  return (
    <>
      <Row gutter={[8, 8]} type="flex">
        <Col
          span={24}
          order={2}
          md={{ order: 2, span: 12 }}
          xxl={{ order: 1, span: 12 }}
        >
          <Input.Search
            allowClear
            placeholder="รหัสนักธุรกิจ, ชื่อนักธุรกิจ, ชื่อพนักงาน, เลขที่ใบเคลม"
            autoComplete="off"
            value={query}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: { query: e.target.value, page: 1 },
                })
              )
            }
          />
        </Col>
        <Col
          span={24}
          order={3}
          md={{ order: 3, span: 12 }}
          xxl={{ order: 2, span: 9 }}
        >
          <SearchDate
            title="วันที่แจ้งเรื่องเคลม"
            start={start_date}
            end={end_date}
            onChangeStart={handleChangeStart}
            onChangeEnd={handleChangeEnd}
          />
        </Col>
        {process_params === "wait" && (
          <Col
            span={24}
            order={1}
            xxl={{ order: 3, span: 3 }}
            className="text-right"
          >
            <Link to={`${process_params}/0`}>
              <Button type="primary">
                <FaPlus className="mr-2" />
                ยื่นเรื่องเคลม
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} className="mt-2 mb-3">
        <Col span={24} md={12} xxl={6}>
          <SelectLayout
            title={process_params === "pending" ? "ผู้พิจาราณา" : "สถานะ"}
          >
            <SelectStatus />
          </SelectLayout>
        </Col>
        <Col span={24} md={12} xxl={6}>
          <SelectLayout title="สาขารับเรื่อง">
            <SelectBranch
              withAll
              className="w-100"
              value={user_branch_code}
              onChange={(user_branch_code) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      user_branch_code,
                      page: 1,
                    },
                  })
                )
              }
            />
          </SelectLayout>
        </Col>
        <Col span={24} md={12} xxl={6}>
          <SelectLayout title="สาขารับเคลม">
            <SelectBranch
              withAll
              className="w-100"
              value={branch_code}
              onChange={(branch_code) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      branch_code,
                      page: 1,
                    },
                  })
                )
              }
            />
          </SelectLayout>
        </Col>
        <Col span={24} md={12} xxl={6}>
          <SelectLayout title="ประเภทการเคลม">
            <SelectMasterType
              processType="claim_type"
              withAll
              value={claim_type}
              onChange={(claim_type) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      claim_type,
                      page: 1,
                    },
                  })
                )
              }
            />
          </SelectLayout>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Index);
