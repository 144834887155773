/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Typography } from "antd";
import React, { useState } from "react";
import BookBankHistory from "./BookBankHistory";
import CompanyBankForm from "./CompanyBankForm";
import PersonBankForm from "./PersonBankForm";

const BuyerBookBank = ({
  handleChange,
  setMember,
  Member,
  errors,
  loadingBank,
}) => {
  const [show, setShow] = useState(false);
  const list = [
    { title: "บัญชีธนาคาร", value: "personBank" },
    { title: "บัญชีธนาคารสำหรับนิติบุคคล", value: "companyBank" },
  ];
  const [activeTab, setActiveTab] = useState("personBank");

  return (
    <>
      <ul className="custom-tabs">
        <div className="d-flex">
          {list.map((n, i) => (
            <li
              key={i}
              className={`${activeTab === n.value ? "active" : ""} mr-4`}
              onClick={() => setActiveTab(n.value)}
            >
              <Typography.Title level={4}>
                <a style={{ fontSize: "13px" }}>{n.title}</a>
              </Typography.Title>
            </li>
          ))}
        </div>
        <div className="d-flex">
          <Button
            size="small"
            className="m-1 d-flex align-items-center"
            icon="history"
            shape="round"
            onClick={() => setShow(true)}
          >
            History
          </Button>
        </div>
      </ul>
      <div className="container shadow-sm p-15 mb-4 bg-white rounded">
        {activeTab === "personBank" ? (
          <PersonBankForm
            setMember={setMember}
            Member={Member}
            errors={errors}
            handleChange={handleChange}
          />
        ) : (
          <CompanyBankForm
            setMember={setMember}
            Member={Member}
            errors={errors}
            handleChange={handleChange}
          />
        )}

        {Member?.partner_code && (
          <BookBankHistory
            show={show}
            onCancel={() => setShow(false)}
            code={Member.partner_code}
          />
        )}
      </div>
    </>
  );
};
export default BuyerBookBank;
