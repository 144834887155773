import { Col, Row, Typography } from "antd";
import React, { useContext } from "react";
import ArrangeContext from "../reducer";

const BillHeader = () => {
  const {
    state: {
      bill: { ref_document_no = "", partner_code = "", partner_name = "" },
    },
  } = useContext(ArrangeContext);
  return (
    <Row gutter={[8, 8]}>
      <Col span={24} className="bg-gray-60 px-3 ">
        <Typography.Paragraph className="mb-0 text-white">
          รายการสินค้า
        </Typography.Paragraph>
      </Col>
      <Col span={24} className="bg-gray-40 font-size-12 px-3">
        <Typography.Text strong className="text-dark">
          {`เลขที่บิล ${ref_document_no} - ${partner_code} ${partner_name}`}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default BillHeader;
