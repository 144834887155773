import { Col, DatePicker, Row, Switch, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionProduct,
  formSelector,
  orderSourceSelector,
} from "../../../../../../../redux/slices/product";
import {
  disabledEndDateSplit,
  disabledEndTimeSplit,
} from "../../../../../../util/helper";

const OtherSource = ({ type }) => {
  const dispatch = useDispatch();
  const { order_source } = useSelector(formSelector);
  const orderSource = useSelector(orderSourceSelector);

  const { SELECT_ORDER_SOURCE, SET_ORDER_SOURCE } = actionProduct;

  const active = order_source.includes(type);
  const title = type === "online" ? "Shopping Online" : "Mobile";

  const { itemKeys, itemByKey } = orderSource[type];
  const { start_date, end_date } = itemByKey[itemKeys[0]];
  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col span={24} className="d-flex justify-content-between">
          <Typography.Text>{title}</Typography.Text>
          <Switch
            checked={active}
            onClick={(checked) =>
              dispatch(SELECT_ORDER_SOURCE({ type, checked }))
            }
          />
        </Col>
      </Row>
      {active && (
        <Row gutter={16} className="mb-2">
          <Col span={11} offset={1}>
            <DatePicker
              size="small"
              value={start_date}
              className="w-100 min-width-0"
              placeholder="วันที่เริ่มต้น"
              format="DD/MM/YYYY HH:mm:ss"
              showTime={true}
              defaultPickerValue={moment("00:00:00", "HH:mm:ss")}
              onChange={(e) =>
                dispatch(
                  SET_ORDER_SOURCE({
                    type,
                    branch_key: "branch-1",
                    start_date: e,
                    end_date: null,
                  })
                )
              }
            />
          </Col>
          <Col span={11} offset={1}>
            <DatePicker
              size="small"
              value={end_date}
              className="w-100 min-width-0"
              placeholder="วันที่สิ้นสุด"
              format="DD/MM/YYYY HH:mm:ss"
              showTime={true}
              defaultPickerValue={moment("23:59:59", "HH:mm:ss")}
              disabledDate={(current) =>
                disabledEndDateSplit(current, start_date)
              }
              disabledTime={(current) =>
                disabledEndTimeSplit(current, start_date)
              }
              onChange={(e) => {
                const start = moment(start_date).add(1, "s");
                if (+moment(start).format("DD") === +moment(e).format("DD")) {
                  const current_hour = +moment(e).format("HH");
                  const start_hour = +moment(start).format("HH");
                  if (
                    current_hour === start_hour &&
                    current_hour !== +moment(end_date).format("HH")
                  ) {
                    e = moment(e).set({
                      m: +moment(start).format("mm"),
                      s: +moment(start).format("ss"),
                    });
                  }
                }
                dispatch(
                  SET_ORDER_SOURCE({
                    type,
                    branch_key: "branch-1",
                    end_date: e,
                  })
                );
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default OtherSource;
