/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Button, Col, notification, Row, Table, Typography } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import SelectMonth, { monthOptions } from "../../components/SelectMonth";
import SelectYear from "../../components/SelectYear";
import * as XLSX from "xlsx-js-style";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import { dayOptions } from "../../components/SelectDay";

const { Text } = Typography;

const user = getUser();

const viewOptions = [
  { value: "Y", text: "รูปแบบรายปี" },
  {
    value: "M",
    text: "รูปแบบรายเดือน",
  },
];

const memberTypeOptions = [
  { value: "Distributor", text: "นักธุรกิจ" },
  { value: "Member", text: "VIP MEMBER" },
];

const filterInitState = {
  monthViewFilter: {
    year: moment().format("YYYY"),
    monthFrom: moment({ month: "0" }).format("MM"),
    monthTo: moment({ month: "11" }).format("MM"),
  },
  yearViewFilter: {
    yearFrom: moment("2014", "YYYY").format("YYYY"),
    yearTo: moment().format("YYYY"),
  },
};

const filterReducer = (state, action) => {
  switch (action.type) {
    case "setMonthYear":
      return {
        ...state,
        monthViewFilter: { ...state.monthViewFilter, year: action.payload },
      };
    case "setMonthFrom":
      return {
        ...state,
        monthViewFilter: {
          ...state.monthViewFilter,
          monthFrom: action.payload,
        },
      };
    case "setMonthTo":
      return {
        ...state,
        monthViewFilter: { ...state.monthViewFilter, monthTo: action.payload },
      };
    case "setYearFrom":
      return {
        ...state,
        yearViewFilter: { ...state.yearViewFilter, yearFrom: action.payload },
      };
    case "setYearTo":
      return {
        ...state,
        yearViewFilter: { ...state.yearViewFilter, yearTo: action.payload },
      };
    default:
      return filterInitState;
  }
};

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  //View
  const [view, setView] = useState("M");

  //Filter
  const [filter, setFilter] = useReducer(filterReducer, filterInitState);
  const [memberTypeFilter, setMemberTypeFilter] = useState("Distributor");

  let monthTableDataDisplay = [];
  let tableDataDisplay = [];
  let limit = 32;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-register`,
      token: true,
      params: {
        lim: limit * page,
        offset: limit * (page - 1),
        view: view,
        userId: user.id,
        monthYear: filter.monthViewFilter.year,
        monthFrom: filter.monthViewFilter.monthFrom,
        monthTo: filter.monthViewFilter.monthTo,
        yearFrom: filter.yearViewFilter.yearFrom,
        yearTo: filter.yearViewFilter.yearTo,
        monthInclude: monthTableDataDisplay,
        memberTypeFilter: memberTypeFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      // initialLoad: false,
    },
    [
      view,
      filter,
      memberTypeFilter,
      filter.monthViewFilter.year,
      filter.monthViewFilter.monthFrom,
      filter.monthViewFilter.monthTo,
      filter.yearViewFilter.yearFrom,
      filter.yearViewFilter.yearTo,
    ]
  );

  useEffect(() => {
    setDataSource(data ? data.data : []);
  }, [data]);

  for (
    let i = filter.yearViewFilter.yearFrom;
    i <= filter.yearViewFilter.yearTo;
    i++
  ) {
    tableDataDisplay.push(i.toString());
  }

  for (
    let i = parseInt(moment(filter.monthViewFilter.monthFrom).format("MM"));
    i <= parseInt(moment(filter.monthViewFilter.monthTo).format("MM"));
    i++
  ) {
    monthTableDataDisplay.push(i);
  }

  const exportDataData = data ? [data.data] : [];

  //-------------header table for excel ----------
  const headerM1 = [
    "วันที่",
    `ข้อมูลการสมัครของปี ค.ศ ${filter?.monthViewFilter?.year}`,
  ];
  const headerM2 = [""];
  monthOptions.map((n) => {
    if (n.th !== "") {
      if (n.th !== "ธันวาคม") {
        headerM1.push("");
      } else {
        headerM1.push("รวม");
      }
      headerM2.push(n.th);
    }
  });
  const headerY1 = ["เดือน", "ปี ค.ศ."];
  const headerY2 = [""];
  for (let i = 0; i < tableDataDisplay.length; i++) {
    headerY2.push(tableDataDisplay[i]);
    if (i !== tableDataDisplay.length - 1) {
      headerY1.push("");
    } else {
      headerY1.push("รวม");
    }
  }

  const exportToExcel = () => {
    if (data?.data?.length > 0) {
      let rangeMForm = moment(filter.monthViewFilter.monthFrom).format("MMMM");
      let rangeMTo = moment(filter.monthViewFilter.monthTo).format("MMMM");
      let excelData = [
        ["รายงานยอดการสมัคร"],
        [
          `รูปแบบการแสดงผล : ${
            view === "M" ? "รายเดือน" : view === "Y" ? "รายปี" : "-"
          }`,
          "",
          `ประเภทสมาชิก ${
            memberTypeFilter === "" ? "ทั้งหมด" : memberTypeFilter
          }`,
          "",
          `${
            view === "M"
              ? `ปี : ${filter.monthViewFilter.year} - ช่วงเดือน : ${rangeMForm} ถึง ${rangeMTo}`
              : view === "Y"
              ? `ช่วงปี : ${filter.yearViewFilter.yearFrom} ถึง ${filter.yearViewFilter.yearTo}`
              : ""
          }`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [""],
      ];

      if (view === "M") {
        excelData.push(headerM1);
        excelData.push(headerM2);
      } else if (view === "Y") {
        excelData.push(headerY1);
        excelData.push(headerY2);
      }

      exportDataData[0].forEach((n) => {
        let mTable = [
          {
            v: n.day,
            s: { alignment: { horizontal: "center" } },
          },
          n.jan === null ? 0 : n.jan,
          n.feb === null ? 0 : n.mar,
          n.apr === null ? 0 : n.feb,
          n.mar === null ? 0 : n.apr,
          n.may === null ? 0 : n.may,
          n.jun === null ? 0 : n.jun,
          n.jul === null ? 0 : n.jul,
          n.aug === null ? 0 : n.aug,
          n.sep === null ? 0 : n.sep,
          n.oct === null ? 0 : n.oct,
          n.nov === null ? 0 : n.nov,
          n.dec === null ? 0 : n.dec,
          n.total_regist === null ? "0" : n.total_regist,
        ];

        console.log("month_date:", n.month_date);
        let yTable = [
          n.month_date === "รวม"
            ? "รวม"
            : monthOptions[_.toNumber(n.month_date) - 1]?.th,
        ];

        let checkPush = 1;
        for (let i = 0; i < 2; i++) {
          if (checkPush === 1) {
            headerY2.includes("2014") &&
              yTable.push(numeral(n.y_2014).format("0,0"));
            headerY2.includes("2015") &&
              yTable.push(numeral(n.y_2015).format("0,0"));
            headerY2.includes("2016") &&
              yTable.push(numeral(n.y_2016).format("0,0"));
            headerY2.includes("2017") &&
              yTable.push(numeral(n.y_2017).format("0,0"));
            headerY2.includes("2018") &&
              yTable.push(numeral(n.y_2018).format("0,0"));
            headerY2.includes("2019") &&
              yTable.push(numeral(n.y_2019).format("0,0"));
            headerY2.includes("2020") &&
              yTable.push(numeral(n.y_2020).format("0,0"));
            headerY2.includes("2021") &&
              yTable.push(numeral(n.y_2021).format("0,0"));
            headerY2.includes("2022") &&
              yTable.push(numeral(n.y_2022).format("0,0"));
            checkPush = checkPush + 1;
          } else if (checkPush === 2) {
            yTable.push(numeral(n.total_regist).format("0,0"));
            checkPush = 0;
          }
        }

        excelData =
          view === "M"
            ? [...excelData, mTable]
            : view === "Y"
            ? [...excelData, yTable]
            : [];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 9 } },
        { s: { r: 1, c: 10 }, e: { r: 1, c: 12 } },
        // manage : header table
        {
          s: { r: 3, c: 1 },
          e: {
            r: 3,
            c:
              view === "M"
                ? headerM2.length - 1
                : view === "Y"
                ? headerY2.length - 1
                : 0,
          },
        },

        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
        {
          s: {
            r: 3,
            c:
              view === "M"
                ? headerM2.length
                : view === "Y"
                ? headerY2.length
                : 0,
          },
          e: {
            r: 4,
            c:
              view === "M"
                ? headerM2.length
                : view === "Y"
                ? headerY2.length
                : 0,
          },
        },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      // word space in column [of index]
      var wscols = [];
      for (let i = 0; i < 34; i++) {
        wscols.push({ wch: 15 });
      }

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานยอดการสมัคร");
      XLSX.writeFile(wb, "รายงานยอดการสมัคร.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานยอดการสมัคร</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={3}>
              <Text strong>รูปแบบการแสดงผล</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                options={viewOptions}
                className="w-100"
                defaultValue={view}
                onChange={(e) => {
                  setView(e);
                  setDataSource([]);
                }}
              />
            </Col>
            <Col span={17}>
              <Row type="flex" justify="end">
                <Col align="right" className="mr-2">
                  <Button type="primary" onClick={reload}>
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col align="right">
                  <Button type="primary" onClick={exportToExcel}>
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={3}>
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={8}>
              <SelectOption
                className="w-50"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>

            {view === "M" && (
              <React.Fragment>
                <Col span={1} align="center">
                  <Text strong>ปี</Text>
                </Col>
                <Col span={2}>
                  <SelectYear
                    value={filter.monthViewFilter.year}
                    yearFrom="2014"
                    onChange={(e) => {
                      setFilter({
                        type: "setMonthYear",
                        payload: e,
                      });
                    }}
                  />
                </Col>
                <Col span={2} align="center">
                  <Text strong>
                    {view === "Y" ? "ระบุช่วงปี" : "ช่วงเดือน"}
                  </Text>
                </Col>
                <Col span={3}>
                  <SelectMonth
                    value={filter.monthViewFilter.monthFrom}
                    onChange={(e) => {
                      setFilter({
                        type: "setMonthFrom",
                        payload: e,
                      });
                    }}
                  />
                </Col>

                <Col span={1} align="center">
                  <Text strong>ถึง</Text>
                </Col>
                <Col span={3}>
                  <SelectMonth
                    value={filter.monthViewFilter.monthTo}
                    onChange={(e) => {
                      setFilter({
                        type: "setMonthTo",
                        payload: e,
                      });
                    }}
                  />
                </Col>
              </React.Fragment>
            )}
            {view === "Y" && (
              <React.Fragment>
                <Col span={2} align="center">
                  <Text strong>
                    {view === "Y" ? "ระบุช่วงปี" : "ช่วงเดือน"}
                  </Text>
                </Col>
                <Col span={2}>
                  <SelectYear
                    value={filter.yearViewFilter.yearFrom}
                    yearFrom="2014"
                    onChange={(e) => {
                      setFilter({
                        type: "setYearFrom",
                        payload: e,
                      });
                    }}
                  />
                </Col>

                <Col span={1} align="center">
                  <Text strong>ถึง</Text>
                </Col>
                <Col span={2}>
                  <SelectYear
                    value={filter.yearViewFilter.yearTo}
                    yearFrom="2014"
                    onChange={(e) => {
                      setFilter({
                        type: "setYearTo",
                        payload: e,
                      });
                    }}
                  />
                </Col>
              </React.Fragment>
            )}
          </Row>

          {view === "Y" && (
            <Table
              bordered
              size="small"
              loading={loading}
              dataSource={loading ? [] : dataSource}
              rowKey="month_date"
              scroll={{ x: "max-content" }}
              pagination={{
                current: page,
                pageSize: limit,
                total: dataSource ? dataSource.length : 100,
                // showLessItems: true,
                size: "default",
                onChange: (currPage) => setPage(currPage),
              }}
            >
              {/* <Table.Column
                title="ลำดับ"
                width={100}
                dataIndex="month_date"
                align="center"
              /> */}

              <Table.Column
                title="เดือน"
                dataIndex="month_date"
                align="center"
                width={200}
                render={(text) => {
                  console.log("text", text);
                  return text === "รวม"
                    ? "รวม"
                    : text === null
                    ? "-"
                    : moment({ month: text - 1 }).format("MMMM");
                }}
              />

              <Table.ColumnGroup title="ปี ค.ศ.">
                {tableDataDisplay.includes("2014") && (
                  <Table.Column
                    title="2014"
                    dataIndex="y_2014"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2015") && (
                  <Table.Column
                    title="2015"
                    dataIndex="y_2015"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2016") && (
                  <Table.Column
                    title="2016"
                    dataIndex="y_2016"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2017") && (
                  <Table.Column
                    title="2017"
                    dataIndex="y_2017"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2018") && (
                  <Table.Column
                    title="2018"
                    dataIndex="y_2018"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2019") && (
                  <Table.Column
                    title="2019"
                    dataIndex="y_2019"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2020") && (
                  <Table.Column
                    title="2020"
                    dataIndex="y_2020"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2021") && (
                  <Table.Column
                    title="2021"
                    dataIndex="y_2021"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                {tableDataDisplay.includes("2022") && (
                  <Table.Column
                    title="2022"
                    dataIndex="y_2022"
                    align="center"
                    render={(text) => {
                      if (text == null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                )}
                <Table.Column
                  title="รวม"
                  dataIndex="total_regist"
                  align="center"
                  render={(text) => {
                    if (text == null) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            </Table>
          )}

          {view === "M" && (
            <Table
              bordered
              size="small"
              loading={loading}
              dataSource={dataSource || []}
              rowKey="day"
              scroll={{ x: "max-content" }}
              pagination={{
                current: page,
                pageSize: limit,
                total: dataSource.length,
                size: "default",
                onChange: (currPage) => setPage(currPage),
              }}
            >
              <Table.Column
                title="วันที่"
                dataIndex="day"
                align="center"
                width={100}
              />

              <Table.ColumnGroup
                title={`ข้อมูลการสมัครของปี ค.ศ. ${filter.monthViewFilter.year}`}
              >
                <Table.Column
                  title="มกราคม"
                  dataIndex="jan"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="กุมภาพันธุ์"
                  dataIndex="feb"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="มีนาคม"
                  dataIndex="mar"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="เมษายน"
                  dataIndex="apr"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="พฤษภาคม"
                  dataIndex="may"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="มิถุนายน"
                  dataIndex="jun"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="กรกฎาคม"
                  dataIndex="jul"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สิงหาคม"
                  dataIndex="aug"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="กันยายน"
                  dataIndex="sep"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="ตุลาคม"
                  dataIndex="oct"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="พฤศจิกายน"
                  dataIndex="nov"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="ธันวาคม"
                  dataIndex="dec"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="total_regist"
                  align="center"
                  render={(text) => {
                    if (text == null) return 0;
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            </Table>
          )}
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
