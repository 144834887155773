import React from 'react'
import { Col, Row, Typography } from 'antd';
import styles from '../../../../scss/module/legal.module.scss';
const title_color = {
    color : "#40a5a0"
  }
const MultipleRecord = ({ data = [], title, divider, type = '' }) => {
    return (
        <div className={`${styles.row} ${divider ? styles.divider : ''}`}>
            {
                title && (
                    <Row className="mb-2">
                        <Col span={24}>
                            <Typography.Text strong>{title}</Typography.Text>
                        </Col>
                    </Row>
                )
            }
            {
                data.map((item, index) => (
                    <Row key={index} className="mb-2">
                        {
                            item.map((n, i) => (
                                <Col span={12} lg={type === 'address' && i === 0 ? 12 : 6} xl={type === 'address' && i === 0 ? 8 : 4} className="d-flex flex-column" key={i}>
                                    <Typography.Text style={title_color}>{n.name}</Typography.Text>
                                    <Typography.Text>{n.value}</Typography.Text>
                                </Col>
                            ))
                        }
                    </Row>
                ))
            }
        </div>
    )
}

export default MultipleRecord
