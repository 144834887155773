import { Descriptions } from "antd";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export default function UplinePanel() {
  const upline = useSelector((state) => state.ethics.form.upline, shallowEqual);

  return (
    <Descriptions size="small" bordered>
      <Descriptions.Item label="รหัสสมาชิก" span={2}>
        {upline?.upline_code === "" ? "-" : upline?.upline_code}
      </Descriptions.Item>
      <Descriptions.Item label="ทีมงานด้าน">
        {upline?.chart_type?.toLowerCase() === "l"
          ? "ซ้าย"
          : upline?.chart_type?.toLowerCase() === "r"
          ? "ขวา"
          : ""}
      </Descriptions.Item>
      <Descriptions.Item label="ชื่อสมาชิก" span={2}>
        {upline?.upline_name === "" ? "-" : upline?.upline_name}
      </Descriptions.Item>
      <Descriptions.Item label="โทรศัพท์">
        {upline?.upline_mobile === "" ? "-" : upline?.upline_mobile}
      </Descriptions.Item>
    </Descriptions>
  );
}
