import { createReducer } from "@reduxjs/toolkit";

import { createContext } from "react";

export const ProductContext = createContext();

export const initState = {
  saving: false,
  loading: false,
  error: null,
  modal_visible: false,
  data: {
    id: 0,
    product_code: "",
    backend_sku_code: "",
    product_name_local: "",
    product_name_en: "",
    product_title_local: "",
    product_title_en: "",
    product_tagline_local: "",
    product_tagline_en: "",
    product_manual_local: "",
    product_manual_en: "",
    product_description_local: "",
    product_description_en: "",
    product_type_sale: 0,
    product_type: "",
    product_type_set: "",
    product_class: "",
    logistic_type: "Internal",
    size_length: 0,
    size_width: 0,
    size_height: 0,
    weight: 0,
    color: "",
    taste: "",
    smell: "",
    temperature: "",
    quantity: 0,
    unit: "",
    bar_code: "",
    fda: "",
    category: [],
    item_reborn: 0,
    retail_price: 0,
    member_price: 0,
    member: [],
    distributor_price: 0,
    staff_price: 0,
    member_redeem: 0,
    distributor_redeem: 0,
    buyer: [],
    distribution: [],
    //distribution_type: [],
    distribution_type: "",
    point_01: 0,
    point_02: 0,
    point_03: 0,
    point_04: 0,
    point_05: 0,
    point_06: 0,
    point_07: 0,
    point_08: 0,
    point_09: 0,
    point_10: 0,
    r_point_01: 0,
    r_point_02: 0,
    r_point_03: 0,
    r_point_04: 0,
    r_point_05: 0,
    r_point_06: 0,
    r_point_07: 0,
    r_point_08: 0,
    r_point_09: 0,
    r_point_10: 0,
    point_01_qty: 0,
    point_02_qty: 0,
    point_03_qty: 0,
    point_04_qty: 0,
    point_05_qty: 0,
    point_06_qty: 0,
    point_07_qty: 0,
    point_08_qty: 0,
    point_09_qty: 0,
    point_10_qty: 0,
    point_01_rate: 0,
    point_02_rate: 0,
    include_vat: 1,
    start_date: "",
    end_date: "",
    status: "Active",
    relation: [],
    images: [],
    videos: [],
  },
  product_list: { page: 1 },
};

export const reducer = createReducer(initState, {
  CLEAR: (state) => {
    state = { ...initState };
  },
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_ERROR: (state, { payload }) => {
    state.error = payload;
  },
  SET_DATA: (state, { payload }) => {
    state.data = { ...state.data, ...payload };
  },
  SET_FIELD_DATA: (state, { payload: { name, value } }) => {
    state.data[name] = value;
  },
  SET_PRODUCT_LIST_PAGE: (state, { payload }) => {
    state.product_list = { ...state.product_list, ...payload };
  },
});

export default ProductContext;
