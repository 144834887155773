import { Button, Col, Divider, Popconfirm, Row, Typography } from "antd";
import React, { useContext, useMemo } from "react";
import { FaTimes } from "react-icons/fa";
import { useRouteMatch } from "react-router-dom";
import { _ } from "../../../../../../../utils";
import { PromotionContext } from "../../reducers";
import UploadImgData from "../UploadImgData";
import Amount from "./Amount";
import Item from "./Item";
import Voucher from "./Voucher";

const isItem = (data) =>
  data.itemKeys.length > 0 &&
  _.every(
    data.itemKeys,
    (data_key) => data.itemByKey[data_key].itemKeys.length > 0
  );

const PromotionData = ({
  type,
  itemType,
  receiveType,
  refSourceOption,
  qtyTextSuffix = "",
}) => {
  const match = useRouteMatch();
  const { state, dispatch } = useContext(PromotionContext);
  const type_params = match.params.type;
  const {
    data: { is_step, is_extend },
    [type]: { itemKeys, itemByKey },
    error,
  } = state;
  const title_text =
    type_params === "amount"
      ? "รายการ"
      : type === "condition"
      ? "เงื่อนไข"
      : "สิ่งที่ได้รับ";

  const handleAdd = () => {
    dispatch({
      type: "ADD_PROMOTION_DATA",
      payload: { type, refSourceOption },
    });
  };

  const sourceOptionKey = useMemo(
    () =>
      type === "receive" && is_step === 1
        ? _.filter(
            itemKeys,
            (data_key) =>
              itemByKey[data_key].refSourceOption === refSourceOption
          )
        : itemKeys,
    [type, is_step, itemKeys, itemByKey, refSourceOption]
  );

  const allowAdd = useMemo(() => {
    let res = false;
    if (is_extend === 1) {
      const con_length = state.condition.itemKeys.length;
      if (type === "condition") {
        if (
          con_length === 0 ||
          (con_length === 1 && isItem(state.condition) && isItem(state.receive))
        ) {
          res = true;
        }
      } else {
        if (
          refSourceOption === "1" ||
          (refSourceOption === "0" && con_length === 1)
        ) {
          res = true;
        }
      }
    } else {
      res = true;
    }
    return res;
  }, [is_extend, type, state.condition, state.receive, refSourceOption]);

  return (
    <>
      {_.map(sourceOptionKey, (data_key, data_index) => {
        let data_source = _.map(
          itemByKey[data_key].itemKeys,
          (item_key) => itemByKey[data_key].itemByKey[item_key]
        );
        let disabled_add = false;
        let disabled_change = false;
        let disabled_del = false;
        if (
          is_extend === 1 &&
          state.condition.itemKeys.length === 2 &&
          ((type === "condition" && data_index === 0) ||
            (type === "receive" && refSourceOption === "0"))
        ) {
          disabled_add = true;
          disabled_change = true;
          data_source = _.map(data_source, (n) => ({
            ...n,
            disabled_del: true,
          }));
        }
        disabled_del =
          type === "condition" && data_index === 1
            ? false
            : _.some(data_source, (n) => n.disabled_del);
        return (
          <>
            <div
              key={data_key}
              className="border rounded-lg d-flex flex-column mb-4 px-3 py-3"
            >
              <Row className="mb-3">
                <Col span={24} className="d-flex justify-content-between">
                  <Typography.Text>{title_text}</Typography.Text>
                  <Popconfirm
                    title="ต้องการลบรายการนี้หรือไม่"
                    placement="rightTop"
                    disabled={disabled_del}
                    onConfirm={() =>
                      dispatch({
                        type: "DEL_PROMOTION_ITEM",
                        payload: {
                          type,
                          data_key,
                        },
                      })
                    }
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                  >
                    <Button
                      type="danger"
                      ghost
                      size="small"
                      disabled={disabled_del}
                    >
                      <FaTimes />
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
              <Row gutter={8} type="flex" className="align-items-center mb-3">
                <Col span={24} className="d-flex flex-column">
                  {itemType === "item" ? (
                    <Item
                      dataSource={data_source}
                      disabledAdd={disabled_add}
                      disabledChange={disabled_change}
                      type={type}
                      dataKey={data_key}
                      refSourceOption={refSourceOption}
                      qtyTextSuffix={qtyTextSuffix}
                    />
                  ) : itemType === "amount" ? (
                    <Amount
                      dataSource={data_source}
                      disabledAdd={disabled_add}
                      disabledChange={disabled_change}
                      type={type}
                      dataKey={data_key}
                      refSourceOption={refSourceOption}
                    />
                  ) : (
                    itemType === "voucher" && (
                      <Voucher
                        dataSource={data_source}
                        disabledAdd={disabled_add}
                        disabledChange={disabled_change}
                        type={type}
                        dataKey={data_key}
                        refSourceOption={refSourceOption}
                      />
                    )
                  )}
                  {data_source.length === 0 &&
                    _.some(error.item, (n) => n === `${type}_item`) && (
                      <Typography.Text className="text-red">
                        {error.rule[`${type}_item`]}
                      </Typography.Text>
                    )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UploadImgData type={type} dataKey={data_key} />
                </Col>
              </Row>
              {type === "condition" && is_step === 1 && receiveType && (
                <>
                  <Divider className="py-3">สิ่งที่ได้รับ</Divider>
                  <PromotionData
                    type="receive"
                    itemType={receiveType}
                    refSourceOption={data_index.toString()}
                  />
                </>
              )}
            </div>
            {data_index !== sourceOptionKey.length - 1 && (
              <Divider>หรือ</Divider>
            )}
          </>
        );
      })}
      {allowAdd && (
        <Row>
          <Col
            span={24}
            className="d-flex flex-column justify-content-center align-items-center height-150"
          >
            <Button id={`add-${type}`} type="primary" ghost onClick={handleAdd}>
              เพิ่ม{title_text}
            </Button>
            {itemKeys.length === 0 &&
              !!_.find(error.item, (n) => n === type) && (
                <Typography.Text className="text-red">
                  {error.rule[type]}
                </Typography.Text>
              )}
          </Col>
        </Row>
      )}
    </>
  );
};
export default PromotionData;
