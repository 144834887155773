import { Col, Row } from "antd";
import React from "react";
import { FaCheck } from "react-icons/fa";
import ImgError from "../../../../../components/ImgError";
import styles from "../../../../../scss/module/promotion.module.scss";
import { _ } from "../../../../../utils";
import Free from "./Free";
import Source from "./Source";

const CardCondition = ({
  active,
  listIndex,
  azureUrl,
  list,
  conditionType,
  onSelect,
  disabledAddItem,
  allItems,
}) => {
  const disabled = conditionType
    ? _.some(
        list,
        (n) => !n.order_qty || n.order_qty < 0 || n.order_qty < n.qty
      )
    : _.some(list, (n) => n.banned || n.sold_out);
  const card_class = disabled
    ? `${styles.disabled}`
    : active
    ? `${styles.active}`
    : "";

  return (
    <Row
      className={`${styles["rec-promotion"]} ${styles["card-condition"]} ${card_class}`}
    >
      <Col span={6}>
        <div
          className={`${styles.img} ${
            disabled
              ? `${styles.disabled}`
              : !onSelect
              ? `${styles.default}`
              : ""
          }`}
          onClick={() => !disabled && onSelect?.(list, listIndex)}
        >
          <ImgError
            src={azureUrl}
            alt={_.uniqueId("condition-")}
            className="w-100 h-100 object-fit-cover"
          />
          {active && (
            <div className={styles.success}>
              <FaCheck size={18} />
            </div>
          )}
        </div>
      </Col>
      <Col span={18} className={styles.content}>
        {conditionType ? (
          <>
            <Source
              disabledAddItem={disabledAddItem}
              list={list}
              conditionType={conditionType}
              allItems={allItems}
            />
          </>
        ) : (
          <Free list={list} />
        )}
      </Col>
    </Row>
  );
};

export default React.memo(CardCondition);
