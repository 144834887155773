/* eslint-disable eqeqeq */
import { Button, Col, Form, Modal, notification, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../../../../components/TextEditor";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import {
  base64toFile,
  fileToBase64,
  resizeFile,
} from "../../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";

function A8_DidYouKnow({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");
  const [fileListDesktop, setFileListDesktop] = useState([]);

  const [fileDelete, setFileDelete] = useState([]);

  const [contentTextEditor, setContentTextEditor] = useState();

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (values.desktop_img?.file?.originFileObj)
        formData.append("desktop_img", values.desktop_img?.file?.originFileObj);

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
          content: contentTextEditor,
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path =
                  "/manage-website/ecosystem/another8/a8-didyouknow/update")
              : (url_path = "/manage-website/ecosystem/another8/a8-didyouknow");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleChangeUploadDesktop = async (e) => {
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));

      setFileListDesktop([
        {
          uid: file.uid,
          status: "done",
          name: file.name,
          url: img_url,
        },
      ]);
    }
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/another8/a8-didyouknow",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);
        if (res.data.data) {
          setContentTextEditor(res.data.data.content);
        }

        let { loadDesktopImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>DID YOU KNOW</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item label="Artwork ( 1 รูป ) : " name="desktop_img">
                    {getFieldDecorator("desktop_img", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop}
                        customRequest={dummyRequest}
                        onRemove={handleRemoveDesktop}
                        onChange={handleChangeUploadDesktop}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>

            <Row className="mb-3">
              <p>Content :</p>
              <TextEditor
                authToken={getToken()}
                value={contentTextEditor}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setContentTextEditor(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-a8-didyouknow")(A8_DidYouKnow);
