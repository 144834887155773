/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Col, Row, Typography, Skeleton } from "antd";
import moment from "moment";
import ArrangeContext from "../reducer";

const { Text } = Typography;

const BillHeaderInfo = () => {
  const {
    state: { bill },
  } = useContext(ArrangeContext);
  return (
    <>
      <Row gutter={4} className="my-2" style={{ fontSize: "12px" }}>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            เลขที่ใบจัด
          </Text>
          <Text className="text-dark">{bill.document_no}</Text>
        </Col>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            วันที่จัดสินค้า
          </Text>
          <Text className="text-dark">
            {moment(bill.document_date).format("DD/MM/YYYY")}
          </Text>
        </Col>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            แหล่งที่มา
          </Text>
          <Text className="text-dark">{bill.bill_branch_name}</Text>
        </Col>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            ชื่อเจ้าหน้าที่
          </Text>
          <Text className="text-dark">{bill.cashier_name}</Text>
        </Col>
      </Row>
      <Row gutter={4} className="my-2" style={{ fontSize: "12px" }}>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            ประเภทผู้ดำเนินการ
          </Text>
          <Text className="text-dark">{bill.buyer_type}</Text>
        </Col>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            ผู้ดำเนินการ
          </Text>
          <Text className="text-dark">{bill.buyer_name}</Text>
        </Col>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            เบอร์โทร
          </Text>
          <Text className="text-dark">{bill.buyer_mobile}</Text>
        </Col>
        <Col span={6} className="px-3">
          <Text strong className="mr-2 text-dark">
            ประเภทการซื้อ
          </Text>
          <Text className="text-dark">{bill.order_type}</Text>
        </Col>
      </Row>
    </>
  );
};

export default BillHeaderInfo;
