/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Switch, Radio, Checkbox } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaPencilAlt } from "react-icons/fa";
import { Panel, PanelHeader, PanelBody } from "../../../../util/panel";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { Link } from "react-router-dom";
import { useApi } from "../../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../../redux/slices/marketing/product";
import useAsync from "../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../services/manage-website.service";
import { notFoundImg } from "../../utils/constants/mwHomeConstants";

export default function BestSeller({ history, match }) {
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const filterProducts = 0;
  const [amountSeq, setAmountSeq] = useState(0);

  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;
  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/home/best-seller",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
        filter: filterProducts,
      },
      token: true,
    },
    [search, filterProducts]
  );



  const handleChangeStatus = (checked, id) => {
    getBestSellerAll()
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/home/best-seller/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "Active" : "In-Active",
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelect = async (e, id) => {
    updateBestSellerCheckBox({
      selected: e.target.checked ? 1 : 0,
      ref_id: id,
    });
  };

  const { execute: getBestSellerAll } = useAsync(
    manageWebsiteService.getBestSellerAll,
    {
      onSuccess: (data) => {
        let seq = data.lenght + 1
        setAmountSeq(seq)
      },
    }
  );

  // const { execute: updateBestSellerSeqById } = useAsync(
  //   manageWebsiteService.updateBestSellerSeqById,
  //   {
  //     immediate:true,
  //     onSuccess: (data) => {
  //       console.log(data);
  //     },
  //   }
  // );

  const { execute: updateBestSellerCheckBox } = useAsync(
    manageWebsiteService.updateBestSellerCheckBox,
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const { execute: getBestSellerBySelected } = useAsync(
    manageWebsiteService.getBestSellerBySelected,
    {
      onSuccess: (data) => {
        let loadData = Object.values(data.data);
        setData(loadData);
        console.log(loadData);
      },
    }
  );

  useEffect(() => {
    getBestSellerBySelected();
  }, []);

  return (
    <>
      <Panel>
        <PanelHeader>สินค้าขายดี</PanelHeader>
        <PanelBody>
          <Table
            bordered
            size="small"
            loading={list?.loading}
            dataSource={list?.fetch?.data || []}
            rowKey="id"
            pagination={{
              size: "default",
              showLessItems: true,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              total: list?.fetch?.total || 0,
              current: search.page,
              pageSize: search.limit,
              onChange: (page) => dispatch(SET_SEARCH({ page: page })),
            }}
          >
            <Table.Column
              title="No."
              key="no"
              align="center"
              dataIndex="row_id"
            />
            <Table.Column
              title="รูปสินค้า"
              key="image_url"
              align="center"
              dataIndex="image_url"
              render={(text) => (
                <Row justify="center" type="flex">
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      overflow: "hidden",
                      border: "1px solid #BFBFBF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={text == null ? notFoundImg : text}
                      alt="img_product"
                      width="80px"
                      height="80px"
                    />
                  </div>
                </Row>
              )}
            />
            <Table.Column
              title="SKU"
              key="backend_sku_code"
              align="center"
              dataIndex="backend_sku_code"
              render={(text) => text}
            />
            <Table.Column
              title="ชื่อสินค้า"
              key="product_name_local"
              dataIndex="product_name_local"
              align="left"
              render={(text) => text}
            />

            <Table.Column
              title="Public Status"
              key="public_status"
              dataIndex="status"
              align="center"
              render={(text, { id }) => (
                <Switch
                  defaultChecked={text == "Active"}
                  onClick={(checked) => handleChangeStatus(checked, id)}
                />
              )}
            />

            <Table.Column
              title="Update Date"
              key="update_date"
              dataIndex="update_date"
              align="center"
              render={(text) =>
                text ? moment(text).format("DD/MM/YYYY HH:mm") : ""
              }
            />

            <Table.Column
              title="เลือกสินค้า"
              key="selected_product"
              dataIndex="selected_product"
              align="center"
              render={(text, { id }) => (
                <Checkbox
                  defaultChecked={text == 1}
                  onClick={(checked) => handleSelect(checked, id)}
                ></Checkbox>
              )}
            />

            {/* <Table.Column
              title="Manage"
              key="manage"
              align="center"
              render={(text, { id }) => (
                <Link to={`${match.path}/${id}`}>
                  <Button type="primary">
                    <FaPencilAlt />
                  </Button>
                </Link>
              )}
            /> */}
          </Table>

          <Row className="mt-5">
            <Button
              className="bg-nn border-nn text-white mr-3"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
            {/* <Button type="primary" onClick={() => handleClickSubmit()}>
              บันทึก
            </Button> */}
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
