import { Col, Input, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../../redux/slices/claim";

const { setClaimItemValue } = actionClaim;

const InputLot = ({ disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const lot = useSelector(
    (state) =>
      state.claim.billProduct.itemByKey[product_key]?.claim_item.itemByKey[
        state.claim.billProduct.itemByKey[product_key].claim_item.activeKey
      ].lot
  );

  return (
    <Row gutter={[8, 8]}>
      <Col span={6} className="text-right">
        <Typography.Text>Lot.</Typography.Text>
      </Col>
      <Col span={18}>
        <Input
          size="small"
          autoComplete="off"
          placeholder="ล็อตการผลิต"
          maxLength={50}
          value={lot}
          disabled={disabled}
          onChange={(e) => dispatch(setClaimItemValue({ lot: e.target.value }))}
        />
      </Col>
    </Row>
  );
};

export default memo(InputLot);
