import { Table } from "antd";
import moment from "moment";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export default function TableRemark() {
  const remark = useSelector((state) => state.ethics.form.remark, shallowEqual);

  return (
    <Table
      size="small"
      bordered={false}
      dataSource={remark || []}
      rowKey="id"
      pagination={false}
    >
      <Table.Column
        title="#"
        align="right"
        dataIndex="row_id"
        className="font-size-12"
      />
      <Table.Column
        title="หมายเหตุ"
        dataIndex="remark"
        className="font-size-12"
      />
      <Table.Column
        title="ผู้เขียน"
        align="center"
        dataIndex="create_by"
        className="font-size-12"
      />
      <Table.Column
        title="วันที่"
        align="center"
        dataIndex="create_date"
        render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
        className="font-size-12"
      />
    </Table>
  );
}
