/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Empty, Skeleton } from 'antd'

const Table = ({ columns, dataSource, className, loading }) => {
    return (
        <table className={`w-100 ${className}`}>
            <thead>
                <tr className="bg-gray-60 text-white">
                    {columns.map(n => <th key={n.key} className="text-center font-weight-normal py-1">{n.title}</th>)}
                </tr>
            </thead>
            <tbody>
                {
                    loading ?
                        <tr>
                            <td colSpan={columns.length || 1}><Skeleton active /></td>
                        </tr>
                        :
                        dataSource.length === 0 ?
                            <tr>
                                <td colSpan={columns.length || 1}><Empty /></td>
                            </tr>
                            :
                            dataSource.map((n, i) => {
                                return (
                                    <tr key={i}>
                                        {
                                            columns.map((m, j) => {
                                                return (
                                                    <td className="py-1 px-2" key={j}>
                                                        {m.render ? m.render(n[m.dataIndex], n, i) : n[m.dataIndex]}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                }
            </tbody>
        </table>
    )
}

export default Table
