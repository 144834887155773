/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import Table from "../../components/Table";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import PreviewFile from "./PreviewFile";

const Model = {
  query: "",
  documentType: "",
  status: "",
};
const UploadHistory = ({ reloadImge, id, show, ...props }) => {
  const [previewFile, setpreviewFile] = useState(false);
  const [imageId, setImageId] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 5;
  const [totalPage, setTotalPage] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [criteria, setCriteria] = useState(Model);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/vip-member/${id}/upload-history`,
      params: {
        limit,
        offset: (page - 1) * limit,
        ...criteria,
        documentType: criteria.documentType ? criteria.documentType : "",
        status: criteria.status ? criteria.status : "",
      },
      token: true,
    },
    [page, reloadImge, criteria]
  );

  if (error) {
    notification.error({
      description: error,
      placement: "bottomRight",
    });
  }
  useEffect(() => {
    let newData = [];
    let total = 0;
    if (data?.data && data?.data) {
      newData = data?.data.map((n) => ({ ...n, key: n.id }));
      total = data.total;
    }
    setDataSource(newData);
    setTotalPage(total);
  }, [data]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      dataIndex: "row_id",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "",
      key: "",
      render: (text, record) => (
        <div style={{ position: "relative", textAlign: "center" }}>
          <a
            onClick={() => {
              setpreviewFile(true);
              setImageId(record.id);
            }}
          >
            <img
              src={`${URL_API}/file/${record.id}`}
              width={50}
              height={50}
              style={{
                borderRadius: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          </a>
          {/* <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: `translate(-50%, -50%)`,
                color: 'white',
                zIndex: 1
            }}>
                <a><Icon type="search" /></a>
            </div> */}
        </div>
      ),
    },
    {
      title: "เอกสาร",
      key: "name",
      dataIndex: "name",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "สถานะ",
      key: "document_status",
      dataIndex: "document_status",
      render: (text) => (
        <p className="m-0 text-center">
          {text === "" || text === null ? null : text === "approve" ? (
            <Tag color="green">Approve</Tag>
          ) : (
            <Tag color="red">Reject</Tag>
          )}
        </p>
      ),
    },
    {
      title: "วันที่ทำรายการ",
      key: "create_date",
      dataIndex: "create_date",
      render: (text) => (
        <p className="m-0 text-center">
          {moment(text).format("DD/MM/YYYY hh:mm")}
        </p>
      ),
    },
    {
      title: "พนักงาน",
      key: "update_by",
      dataIndex: "update_by",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "หมายเหตุ",
      key: "remark",
      dataIndex: "remark",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
  ];
  return (
    <>
      <Modal
        width={700}
        style={{ top: 20 }}
        visible={show}
        zIndex={2000}
        {...props}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="ปิด"
      >
        <Typography.Title level={4}>ประวัติการอัพโหลด</Typography.Title>
        <Row gutter={8} className="my-3">
          <Col md={5}>
            <Input
              size="small"
              className="f-s-12"
              placeholder="ชื่อพนักงาน,หมายเหตุ"
              onChange={(e) =>
                setCriteria({ ...criteria, query: e.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <Select
              size="small"
              placeholder="เอกสาร"
              className="w-100 f-s-12"
              allowClear
              dropdownStyle={{ zIndex: "2010" }}
              defaultValue={criteria.documentType}
              onChange={(value) =>
                setCriteria({ ...criteria, documentType: value })
              }
            >
              <Select.Option value="">ทั้งหมด</Select.Option>
              <Select.Option value="1">ใบสมัคร</Select.Option>
              <Select.Option value="2">สำเนาบัตรประชาชน</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            <Select
              size="small"
              placeholder="สถานะ"
              className="w-100 f-s-12"
              allowClear
              dropdownStyle={{ zIndex: "2010" }}
              defaultValue={criteria.status}
              onChange={(value) => setCriteria({ ...criteria, status: value })}
            >
              <Select.Option value="">ทั้งหมด</Select.Option>
              <Select.Option value="approve">approve</Select.Option>
              <Select.Option value="reject">reject</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            <Button
              type="primary"
              ghost
              size="small"
              icon="search"
              onClick={reload}
            />
          </Col>
        </Row>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data?.data}
          className="mt-2 f-s-12"
        />
        {totalPage === 0 ? null : (
          <Pagination
            size="small"
            pageSize={limit}
            current={page}
            total={totalPage}
            className="text-center"
            onChange={(e) => setPage(e)}
          />
        )}
        <PreviewFile
          visible={previewFile}
          footer={null}
          onCancel={() => setpreviewFile(false)}
          imageId={imageId}
        />
      </Modal>
    </>
  );
};
export default UploadHistory;
