/* eslint-disable no-loop-func */
import { Modal } from "antd";
import { forEach } from "lodash";
import { _ } from "../../../../utils";
import {
  addPromotions,
  delOrderPromotionFromItem,
  delOrderPromotionItem,
  isQRBomOverError,
  removeItemForPromotionAmount,
  setOrderPromotionItemValue,
} from "./logic";
import {
  checkFreeAddedDisableItem,
  checkItemAddedDisableItem,
} from "./logic/data-check-stock";

const TypePrefix = "pos/operation/";

const Types = {
  CLEAR: TypePrefix + "CLEAR",
  CHANGE_STEP: TypePrefix + "CHANGE_STEP",
  SET_OPERATION_VALUE: TypePrefix + "SET_OPERATION_VALUE",
  ADD_ORDER_ITEM: TypePrefix + "ADD_ORDER_ITEM",
  ADD_ORDER_ITEM_LIST: TypePrefix + "ADD_ORDER_ITEM_LIST",
  SET_ORDER_ITEM_VALUE: TypePrefix + "SET_ORDER_ITEM_VALUE",
  SET_ORDER_ITEM: TypePrefix + "SET_ORDER_ITEM",
  DEL_ORDER_ITEM: TypePrefix + "DEL_ORDER_ITEM",
  ADD_ORDER_PROMOTION: TypePrefix + "ADD_ORDER_PROMOTION",
  SET_ORDER_PROMOTION_ITEM_VALUE: TypePrefix + "SET_ORDER_PROMOTION_ITEM_VALUE",
  DEL_ORDER_PROMOTION_ALL: TypePrefix + "DEL_ORDER_PROMOTION_ALL",
  DEL_ORDER_PROMOTION_ITEM: TypePrefix + "DEL_ORDER_PROMOTION_ITEM",
  SET_PROMOTION_COLLECT: TypePrefix + "SET_PROMOTION_COLLECT",
  ADD_BILL: TypePrefix + "ADD_BILL",
  SET_BILL: TypePrefix + "SET_BILL",
  SET_BILL_VALUE: TypePrefix + "SET_BILL_VALUE",
  SET_BILL_ACTIVE: TypePrefix + "SET_BILL_ACTIVE",
  SET_PAYMENT_ACTIVE: TypePrefix + "SET_PAYMENT_ACTIVE",
  ADD_PAYMENT: TypePrefix + "ADD_PAYMENT",
  SET_PAYMENT_VALUE: TypePrefix + "SET_PAYMENT_VALUE",
  SET_PAYMENT_REDEEM: TypePrefix + "SET_PAYMENT_REDEEM",
  DEL_PAYMENT: TypePrefix + "DEL_PAYMENT",
  DEL_BILL: TypePrefix + "DEL_BILL",
  ADD_DISCOUNT: TypePrefix + "ADD_DISCOUNT",
  ADD_BILL_DISCOUNT: TypePrefix + "ADD_BILL_DISCOUNT",
  DEL_DISCOUNT: TypePrefix + "DEL_DISCOUNT",
  ADD_VOUCHER: TypePrefix + "ADD_VOUCHER",
  DEL_VOUCHER: TypePrefix + "DEL_VOUCHER",
  CLEAR_PAYMENT_IGNORE_ACTIVE_KEY:
    TypePrefix + "CLEAR_PAYMENT_IGNORE_ACTIVE_KEY",
  CANCEL_PAYMENT: TypePrefix + "CANCEL_PAYMENT",
  SET_MATCHING_PROMOTION_ITEM: TypePrefix + "SET_MATCHING_PROMOTION_ITEM",
  ADD_ORDER_ITEM_FROM_PROMOTION: TypePrefix + "ADD_ORDER_ITEM_FROM_PROMOTION",
  SET_QR_WARNING: TypePrefix + "SET_QR_WARNING",
  ADD_ORDER_ITEM_BOM_QR: TypePrefix + "ADD_ORDER_ITEM_BOM_QR",
  DEL_ORDER_ITEM_BOM_QR: TypePrefix + "DEL_ORDER_ITEM_BOM_QR",
  SET_LOADING: TypePrefix + "SET_LOADING",
  SET_PRINTFORM: TypePrefix + "SET_PRINTFORM",
  SET_RALC: TypePrefix + "SET_RALC",
};

const order_obj = {
  allow_item: false,
  allow_renew: false,
  allow_qr: false,
  collect_point: true,
  showItems: false,
  partner_code: "",
  partner_name: "",
  partner_type: "",
  order_type: "",
  req_bag: null,
  req_receipt: null,
  req_receipt_type: "",
  receipt_branch_code: "",
  receipt_branch_name: "",
  receipt_branch_type: null,
  more_info: null,
  items: {
    itemKeys: [],
    itemByKey: {},
  },
  promotions: {
    promotion_list: [],
    item_list: [],
    itemKeys: [],
    itemByKey: {},
  },
  discount: {
    itemKeys: [],
    itemByKey: {},
  },
  voucher: {
    activeKey: "",
    itemKeys: [],
    itemByKey: {},
  },
  remark: "",
  printform: {
    queue: "",
    form: "",
  },
};

const INIT_STATE = {
  step: 1,
  buyer_type: "",
  buyer_code: "",
  buyer_name: "",
  buyer_mobile: "",
  buyer_doc: "0",
  promotion_collect: [],
  ralc: null,
  orders: {
    activeKey: "",
    itemKeys: [],
    itemByKey: {},
    branch: {},
  },
  payment: {
    activeKey: "",
    itemKeys: [],
    itemByKey: {},
  },
  discount: {
    itemKeys: [],
    itemByKey: {},
  },
  voucher: {
    itemKeys: [],
    itemByKey: {},
  },
  matching_promotion_item: {
    loading: false,
    allow_show_recommend: false,
    product_code: null,
    data: [],
    link_bom: {},
  },
  loading: {
    check_stock_balance: false,
    delivery: false,
    pick_and_pay: false,
  },
  remark: "",
  printform: {
    queue: "",
    form: "receipt",
  },
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case Types.CLEAR:
      return {
        ...INIT_STATE,
      };

    case Types.CHANGE_STEP:
      return {
        ...state,
        step: payload,
      };

    case Types.SET_OPERATION_VALUE:
      return {
        ...state,
        ...payload,
      };

    case Types.ADD_ORDER_ITEM:
      if (checkItemAddedDisableItem(state, payload)) return state;

      let newItemKeys = [
        ...state.orders.itemByKey[state.orders.activeKey].items.itemKeys,
      ];
      let newItemByKey = {
        ...state.orders.itemByKey[state.orders.activeKey].items.itemByKey,
      };
      let key = _.uniqueId(`${state.orders.activeKey}-items-`);

      let findKey = _.find(
        newItemKeys.map((n) => ({ ...newItemByKey[n], key: n })),
        (n) => n.product_code === payload.product_code
      );

      if (findKey) {
        newItemByKey = {
          ...newItemByKey,
          [findKey.key]: {
            ...newItemByKey[findKey.key],
            bom: _.map(newItemByKey[findKey.key].bom, (n) => ({
              ...n,
              qr: [
                ...(n.qr || []),
                ...(_.find(
                  payload.bom,
                  (m) => m.ref_product_code === n.ref_product_code
                ).qr || []),
              ],
            })),
            qty: parseFloat(newItemByKey[findKey.key].qty) + 1,
            qr: [
              ...(newItemByKey[findKey.key].qr || []),
              ...(payload.qr || []),
            ],
            barcode: newItemByKey[findKey.key].barcode || payload.barcode,
          },
        };
      } else {
        newItemKeys.push(key);

        newItemByKey = {
          ...newItemByKey,
          [key]: { ..._.omit(payload, ["check_stock"]), qty: 1 },
        };
      }

      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                itemKeys: newItemKeys,
                itemByKey: newItemByKey,
              },
              promotions: findKey
                ? delOrderPromotionFromItem(state, findKey.key)
                : state.orders.itemByKey[state.orders.activeKey].promotions,
            },
          },
        },
      };

    case Types.ADD_ORDER_ITEM_FROM_PROMOTION:
      newItemKeys = [
        ...state.orders.itemByKey[state.orders.activeKey].items.itemKeys,
      ];
      newItemByKey = {
        ...state.orders.itemByKey[state.orders.activeKey].items.itemByKey,
      };

      findKey = _.find(
        newItemKeys,
        (item_key) =>
          newItemByKey[item_key].product_code === payload.product_code
      );

      if (findKey) {
        newItemByKey = {
          ...newItemByKey,
          [findKey]: {
            ...newItemByKey[findKey],
            qty: newItemByKey[findKey].qty + payload.qty,
          },
        };
      } else {
        key = _.uniqueId(`${state.orders.activeKey}-items-`);
        newItemKeys.push(key);
        newItemByKey = {
          ...newItemByKey,
          [key]: payload,
        };
      }

      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                itemKeys: newItemKeys,
                itemByKey: newItemByKey,
              },
            },
          },
        },
      };

    case Types.ADD_ORDER_PROMOTION:
      if (checkFreeAddedDisableItem(state, payload)) return state;
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [payload.order_key]: {
              ...state.orders.itemByKey[payload.order_key],
              items:
                payload.promotion_type === "amount"
                  ? removeItemForPromotionAmount(state, payload)
                  : state.orders.itemByKey[payload.order_key].items,
              promotions: addPromotions(state, payload),
            },
          },
        },
      };

    case Types.SET_ORDER_PROMOTION_ITEM_VALUE:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              promotions: setOrderPromotionItemValue(state, payload),
            },
          },
        },
      };

    case Types.SET_PROMOTION_COLLECT:
      return {
        ...state,
        promotion_collect: payload,
      };

    case Types.ADD_ORDER_ITEM_LIST:
      newItemKeys =
        state.orders.itemByKey[state.orders.activeKey].items.itemKeys;
      newItemByKey =
        state.orders.itemByKey[state.orders.activeKey].items.itemByKey;

      // order item
      for (let i = 0; i < payload.order_item.length; i++) {
        const item = payload.order_item[i];
        let key = _.find(
          newItemKeys,
          (n) => newItemByKey[n].product_code === item.product_code
        );
        if (key) {
          newItemByKey = {
            ...newItemByKey,
            [key]: {
              ...newItemByKey[key],
              qty: newItemByKey[key].qty + item.qty,
            },
          };
        } else {
          key = _.uniqueId(`${state.orders.activeKey}-items-`);
          newItemKeys.push(key);
          newItemByKey = {
            ...newItemByKey,
            [key]: payload.order_item[i],
          };
        }
      }

      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                itemKeys: newItemKeys,
                itemByKey: newItemByKey,
              },
              promotions: addPromotions(state, payload.promotion),
            },
          },
        },
      };

    case Types.SET_ORDER_ITEM_VALUE:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                ...state.orders.itemByKey[state.orders.activeKey].items,
                itemByKey: {
                  ...state.orders.itemByKey[state.orders.activeKey].items
                    .itemByKey,
                  [payload.key]: {
                    ...state.orders.itemByKey[state.orders.activeKey].items
                      .itemByKey[payload.key],
                    ...payload.obj,
                  },
                },
              },
              promotions: delOrderPromotionFromItem(state, payload.key),
            },
          },
        },
      };

    case Types.SET_ORDER_ITEM:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                ...state.orders.itemByKey[state.orders.activeKey].items,
                itemByKey: {
                  ...payload,
                },
              },
            },
          },
        },
      };

    case Types.DEL_ORDER_ITEM:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                itemKeys: _.filter(
                  state.orders.itemByKey[state.orders.activeKey].items.itemKeys,
                  (n) => n !== payload
                ),
                itemByKey: _.omit(
                  state.orders.itemByKey[state.orders.activeKey].items
                    .itemByKey,
                  [payload]
                ),
              },
              promotions: delOrderPromotionFromItem(state, payload),
            },
          },
        },
      };

    case Types.DEL_ORDER_PROMOTION_ALL:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              promotions: _.cloneDeep(order_obj.promotions),
            },
          },
        },
        matching_promotion_item: {
          ...state.matching_promotion_item,
          allow_show_recommend: true,
        },
      };

    case Types.DEL_ORDER_PROMOTION_ITEM:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              promotions: delOrderPromotionItem(state, payload),
            },
          },
        },
      };

    case Types.ADD_BILL:
      let newOrderKeys = [...state.orders.itemKeys];
      let newOrderByKey = { ...state.orders.itemByKey };
      const order_key = `${payload.partner_code}-${newOrderKeys.length + 1}`;

      newOrderKeys.push(order_key);

      newOrderByKey = {
        ...newOrderByKey,
        [order_key]: {
          ..._.cloneDeep(order_obj),
          ...payload,
        },
      };

      return {
        ...state,
        orders: {
          ...state.orders,
          activeKey: order_key,
          itemKeys: newOrderKeys,
          itemByKey: newOrderByKey,
        },
      };

    case Types.SET_BILL:
      return {
        ...state,
        orders: {
          ...state.orders,
          ...payload,
        },
      };

    case Types.SET_BILL_ACTIVE:
      return {
        ...state,
        orders: {
          ...state.orders,
          activeKey: state.orders.itemKeys.includes(payload)
            ? payload
            : state.orders.itemKeys[0],
        },
      };

    case Types.SET_BILL_VALUE:
      const { activeKey = state.orders.activeKey, ...data } = payload;
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [activeKey]: {
              ...state.orders.itemByKey[activeKey],
              ...data,
            },
          },
        },
      };

    case Types.SET_PAYMENT_ACTIVE:
      return {
        ...state,
        payment: {
          ...state.payment,
          activeKey: payload,
        },
      };

    case Types.ADD_PAYMENT:
      let newPaymentKeys = [...state.payment.itemKeys];
      let newPaymentByKey = { ...state.payment.itemByKey };

      let findCashKey = _.find(
        newPaymentKeys,
        (n, i) => newPaymentByKey[n].pay_in_type.toLowerCase() === "cash"
      );

      if (payload.pay_in_type.toLowerCase() !== "cash" || !findCashKey) {
        let paymentKey = _.uniqueId("payment-");
        newPaymentKeys.push(paymentKey);

        newPaymentByKey = {
          ...newPaymentByKey,
          [paymentKey]: payload,
        };
      } else {
        newPaymentByKey = {
          ...newPaymentByKey,
          [findCashKey]: {
            ...newPaymentByKey[findCashKey],
            amount:
              parseFloat(newPaymentByKey[findCashKey].amount) +
              parseFloat(payload.amount),
          },
        };
      }

      // if (payload.pay_in_type.toLowerCase() === 'cash') {
      //   if (findCashKey) {
      //     newPaymentByKey = {
      //       ...newPaymentByKey,
      //       [findCashKey]: {
      //         ...newPaymentByKey[findCashKey],
      //         amount:
      //           parseFloat(newPaymentByKey[findCashKey].amount) +
      //           parseFloat(payload.amount),
      //       },
      //     };
      //   } else {

      //   }
      // } else {
      //   let paymentKey = _.uniqueId("payment-");
      //   newPaymentKeys.push(paymentKey);

      //   newPaymentByKey = {
      //     ...newPaymentByKey,
      //     [paymentKey]: payload,
      //   };
      // }

      return {
        ...state,
        payment: {
          ...state.payment,
          itemKeys: newPaymentKeys,
          itemByKey: newPaymentByKey,
        },
      };

    case Types.SET_PAYMENT_VALUE:
      return {
        ...state,
        payment: {
          ...state.payment,
          itemByKey: {
            ...state.payment.itemByKey,
            [payload.key]: {
              ...state.payment.itemByKey[payload.key],
              ...payload.obj,
            },
          },
        },
      };

    case Types.SET_PAYMENT_REDEEM:
      newItemKeys = state.payment.itemKeys;
      newItemByKey = state.payment.itemByKey;
      if (payload) {
        if (newItemKeys.length === 0) {
          const key = _.uniqueId("payment-");
          newItemKeys = [key];
          newItemByKey = {
            [key]: {
              pay_in_type: "redeem",
              pay_in_type_name: "RP",
              amount: payload,
            },
          };
        } else {
          const key = newItemKeys[0];
          newItemByKey[key].amount = payload;
        }
      } else {
        newItemKeys = [];
        newItemByKey = {};
      }
      return {
        ...state,
        payment: {
          ...state.payment,
          itemKeys: newItemKeys,
          itemByKey: newItemByKey,
        },
      };

    case Types.DEL_PAYMENT:
      return {
        ...state,
        payment: {
          ...state.payment,
          itemKeys: _.filter(state.payment.itemKeys, (n) => n !== payload),
          itemByKey: _.omit(state.payment.itemByKey, [payload]),
        },
      };

    case Types.DEL_BILL:
      return {
        ...state,
        orders: {
          ...state.orders,
          activeKey: _.filter(state.orders.itemKeys, (n) => n !== payload)[0],
          itemKeys: _.filter(state.orders.itemKeys, (n) => n !== payload),
          itemByKey: _.omit(state.orders.itemByKey, [payload]),
        },
      };

    case Types.ADD_DISCOUNT:
      let disActiveKey =
        state.payment.activeKey !== ""
          ? state.payment.activeKey
          : state.orders.itemKeys[0];
      let disItemKeys = [
        ...state.orders.itemByKey[disActiveKey].discount.itemKeys,
      ];
      let disItemByKey = {
        ...state.orders.itemByKey[disActiveKey].discount.itemByKey,
      };

      for (let i = 0; i < payload.length; i++) {
        let discount = payload[i];
        let chkVoucher = false;
        // forEach(state.orders.itemKeys, (item) => {
        forEach(
          state.orders.itemByKey[disActiveKey].discount.itemKeys,
          (disc) => {
            if (
              state.orders.itemByKey[disActiveKey].discount.itemByKey[disc]
                .discount_type === discount.discount_type
            ) {
              chkVoucher = true;
            }
          }
        );
        // });

        if (discount.discount_amount > 0 && chkVoucher === false) {
          disItemKeys.push(discount.discount_type);
          disItemByKey[discount.discount_type] = discount;
        }
        // if (disItemKeys.includes(discount.voucher_no) === false) {
        //   disItemKeys.push(discount.voucher_no);
        //   disItemByKey[discount.voucher_no] = discount;
        // }
      }

      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [disActiveKey]: {
              ...state.orders.itemByKey[disActiveKey],
              collect_point:
                disItemKeys
                  .map((disc_key) => disItemByKey[disc_key])
                  .find((item) => item.collect_point === 0) === undefined &&
                state.orders.itemByKey[disActiveKey].voucher.itemKeys
                  .map(
                    (voc_key) =>
                      state.orders.itemByKey[disActiveKey].voucher.itemByKey[
                        voc_key
                      ]
                  )
                  .find((item) => item.collect_point === 0) === undefined,
              discount: {
                itemKeys: disItemKeys,
                itemByKey: disItemByKey,
              },
            },
          },
        },
        // discount: {
        //   itemKeys: disItemKeys,
        //   itemByKey: disItemByKey,
        // },
      };

    case Types.ADD_BILL_DISCOUNT:
      let disBillItemByKey = { ...state.orders.itemByKey };
      const billKeys = _.uniq(_.map(payload, (d) => d.activeKey));

      for (let b = 0; b < billKeys.length; b++) {
        const billActive = billKeys[b];

        let dcItemKeys = [
          ...state.orders.itemByKey[billActive].discount.itemKeys,
        ];
        let dcItemByKey = {
          ...state.orders.itemByKey[billActive].discount.itemByKey,
        };

        const dcList = _.filter(payload, (d) => d.activeKey === billActive);
        for (let i = 0; i < dcList.length; i++) {
          let discount = dcList[i];
          let chkVoucher = false;
          // forEach(state.orders.itemKeys, (item) => {
          forEach(
            state.orders.itemByKey[billActive].discount.itemKeys,
            (disc) => {
              if (
                state.orders.itemByKey[billActive].discount.itemByKey[disc]
                  .discount_type === discount.discount_type
              ) {
                chkVoucher = true;
              }
            }
          );
          // });

          if (discount.discount_amount > 0 && chkVoucher === false) {
            dcItemKeys.push(discount.discount_type);
            dcItemByKey[discount.discount_type] = discount;
          }
          // if (disItemKeys.includes(discount.voucher_no) === false) {
          //   disItemKeys.push(discount.voucher_no);
          //   disItemByKey[discount.voucher_no] = discount;
          // }
        }

        disBillItemByKey = {
          ...disBillItemByKey,
          [billActive]: {
            ...disBillItemByKey[billActive],
            collect_point:
              dcItemKeys
                .map((disc_key) => dcItemByKey[disc_key])
                .find((item) => item.collect_point === 0) === undefined &&
              disBillItemByKey[billActive].voucher.itemKeys
                .map(
                  (voc_key) =>
                    disBillItemByKey[billActive].voucher.itemByKey[voc_key]
                )
                .find((item) => item.collect_point === 0) === undefined,
            discount: {
              itemKeys: dcItemKeys,
              itemByKey: dcItemByKey,
            },
          },
        };
      }

      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            ...disBillItemByKey,
          },
        },
      };

    case Types.DEL_DISCOUNT:
      const delDiscItemKeys = _.filter(
        state.orders.itemByKey[payload.activeKey].discount.itemKeys,
        (n) => n !== payload.discount_type
      );
      const delDiscItemByKey = _.omit(
        state.orders.itemByKey[payload.activeKey].discount.itemByKey,
        [payload.discount_type]
      );
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [payload.activeKey]: {
              ...state.orders.itemByKey[payload.activeKey],
              collect_point:
                delDiscItemKeys
                  .map((del_voc_key) => delDiscItemByKey[del_voc_key])
                  .find((item) => item.collect_point === 0) === undefined &&
                state.orders.itemByKey[payload.activeKey].voucher.itemKeys
                  .map(
                    (voc_key) =>
                      state.orders.itemByKey[payload.activeKey].voucher
                        .itemByKey[voc_key]
                  )
                  .find((item) => item.collect_point === 0) === undefined,
              discount: {
                itemKeys: delDiscItemKeys,
                itemByKey: delDiscItemByKey,
              },
            },
          },
        },
        // ...state,
        // discount: {
        //   ...state.discount,
        //   itemKeys: _.filter(state.discount.itemKeys, (n) => n !== payload),
        //   itemByKey: _.omit(state.discount.itemByKey, [payload]),
        // },
      };

    case Types.ADD_VOUCHER:
      const vocActiveKey = state.payment.activeKey || state.orders.itemKeys[0];
      let vocItemKeys = [
        ...state.orders.itemByKey[vocActiveKey].voucher.itemKeys,
      ];
      let vocItemByKey = {
        ...state.orders.itemByKey[vocActiveKey].voucher.itemByKey,
      };

      for (let i = 0; i < payload.length; i++) {
        let voucher = payload[i];

        if (
          !_.find(state.orders.itemKeys, (order_key) =>
            _.find(
              state.orders.itemByKey[order_key].voucher.itemKeys,
              (voucher_key) =>
                state.orders.itemByKey[order_key].voucher.itemByKey[voucher_key]
                  .id === voucher.id
            )
          )
        ) {
          vocItemKeys = [...vocItemKeys, voucher.id];
          vocItemByKey = {
            ...vocItemByKey,
            [voucher.id]: {
              ...voucher,
            },
          };
        }
      }
      let orderVoucher = _.orderBy(
        vocItemByKey,
        [(obj) => !!obj.custom],
        ["desc"]
      );
      let priorityVoucherList = _.map(orderVoucher, (n) => n.id);
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [vocActiveKey]: {
              ...state.orders.itemByKey[vocActiveKey],
              collect_point: !_.find(
                vocItemKeys,
                (voucher_key) => vocItemByKey[voucher_key].collect_point === 0
              ),
              voucher: {
                ...state.orders.itemByKey[vocActiveKey].voucher,
                itemKeys: priorityVoucherList,
                itemByKey: vocItemByKey,
              },
            },
          },
        },
      };

    case Types.DEL_VOUCHER:
      const delVocItemKeys = _.filter(
        state.orders.itemByKey[payload.activeKey].voucher.itemKeys,
        (n) => n !== payload.id
      );
      const delVocItemByKey = _.omit(
        state.orders.itemByKey[payload.activeKey].voucher.itemByKey,
        [payload.id]
      );

      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [payload.activeKey]: {
              ...state.orders.itemByKey[payload.activeKey],
              collect_point: !_.find(
                delVocItemKeys,
                (voucher_key) =>
                  delVocItemByKey[voucher_key].collect_point === 0
              ),
              voucher: {
                ...state.orders.itemByKey[payload.activeKey].voucher,
                itemKeys: delVocItemKeys,
                itemByKey: delVocItemByKey,
              },
            },
          },
        },
      };

    case Types.CLEAR_PAYMENT_IGNORE_ACTIVE_KEY:
      return {
        ...state,
        payment: {
          ...INIT_STATE.payment,
          activeKey: state.payment.activeKey,
        },
      };

    case Types.SET_MATCHING_PROMOTION_ITEM:
      return {
        ...state,
        matching_promotion_item: {
          ...state.matching_promotion_item,
          ...payload,
        },
      };

    case Types.CANCEL_PAYMENT:
      let initial_discount = {};
      state.orders.itemKeys.forEach((item) => {
        initial_discount = {
          ...initial_discount,
          [item]: {
            ...state.orders.itemByKey[item],
            collect_point: true,
            more_info: null,
            discount: {
              ...INIT_STATE.discount,
            },
            voucher: {
              ...INIT_STATE.voucher,
            },
          },
        };
      });
      return {
        ...state,
        payment: {
          ...INIT_STATE.payment,
        },
        orders: {
          ...state.orders,
          itemByKey: {
            ...initial_discount,
          },
        },
      };

    case Types.SET_QR_WARNING:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [payload.order_key]: {
              ...state.orders.itemByKey[payload.order_key],
              items: {
                ...state.orders.itemByKey[payload.order_key].items,
                itemByKey: {
                  ...state.orders.itemByKey[payload.order_key].items.itemByKey,
                  [payload.item_key]: {
                    ...state.orders.itemByKey[payload.order_key].items
                      .itemByKey[payload.item_key],
                    qr_warning: payload.qr_warning,
                  },
                },
              },
            },
          },
        },
      };

    case Types.ADD_ORDER_ITEM_BOM_QR:
      // only phase one
      if (isQRBomOverError(state, payload)) {
        Modal.warning({
          title: `QR running ${payload.qr.qr_running} ไม่ถูกเพิ่ม`,
          content: `รหัสสินค้า ${payload.product_code} มี QR running แล้วบางส่วน`,
          okText: "ตกลง",
          zIndex: 1080,
        });
        return state;
      }
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                ...state.orders.itemByKey[state.orders.activeKey].items,
                itemByKey: {
                  ...state.orders.itemByKey[state.orders.activeKey].items
                    .itemByKey,
                  [payload.item_key]: {
                    ...state.orders.itemByKey[state.orders.activeKey].items
                      .itemByKey[payload.item_key],
                    bom: _.reduce(
                      state.orders.itemByKey[state.orders.activeKey].items
                        .itemByKey[payload.item_key].bom,
                      (res, n) =>
                        n.ref_product_code === payload.product_code
                          ? [
                              ...res,
                              {
                                ...n,
                                barcode: payload.barcode,
                                // qr: _.orderBy(
                                //   n.qr ? [...n.qr, payload.qr] : [payload.qr],
                                //   "ref_store_qr_id",
                                //   "asc"
                                // ),
                                qr: _.orderBy(
                                  Array.isArray(n.qr)
                                    ? [...n.qr, payload.qr].filter(Boolean)
                                    : [payload.qr].filter(Boolean),
                                  "ref_store_qr_id",
                                  "asc"
                                ),
                              },
                            ]
                          : [...res, n],
                      []
                    ),
                  },
                },
              },
            },
          },
        },
      };

    // case Types.DEL_ORDER_ITEM_BOM_QR:
    //   return {
    //     ...state,
    //     orders: {
    //       ...state.orders,
    //       itemByKey: {
    //         ...state.orders.itemByKey,
    //         [state.orders.activeKey]: {
    //           ...state.orders.itemByKey[state.orders.activeKey],
    //           items: {
    //             ...state.orders.itemByKey[state.orders.activeKey].items,
    //             itemByKey: {
    //               ...state.orders.itemByKey[state.orders.activeKey].items
    //                 .itemByKey,
    //               [payload.item_key]: {
    //                 ...state.orders.itemByKey[state.orders.activeKey].items
    //                   .itemByKey[payload.item_key],
    //                 bom: _.reduce(
    //                   state.orders.itemByKey[state.orders.activeKey].items
    //                     .itemByKey[payload.item_key].bom,
    //                   (res, n) => [
    //                     ...res,
    //                     {
    //                       ...n,
    //                       qr: _.filter(n.qr, (qr) =>
    //                         _.every(payload.bom_qr, (m) => qr.qr_running !== m)
    //                       ),
    //                     },
    //                   ],
    //                   []
    //                 ),
    //               },
    //             },
    //           },
    //         },
    //       },
    //     },
    //   };

    case Types.DEL_ORDER_ITEM_BOM_QR:
      return {
        ...state,
        orders: {
          ...state.orders,
          itemByKey: {
            ...state.orders.itemByKey,
            [state.orders.activeKey]: {
              ...state.orders.itemByKey[state.orders.activeKey],
              items: {
                ...state.orders.itemByKey[state.orders.activeKey].items,
                itemByKey: {
                  ...state.orders.itemByKey[state.orders.activeKey].items
                    .itemByKey,
                  [payload.item_key]: {
                    ...state.orders.itemByKey[state.orders.activeKey].items
                      .itemByKey[payload.item_key],
                    bom: _.reduce(
                      state.orders.itemByKey[state.orders.activeKey].items
                        .itemByKey[payload.item_key].bom,
                      (res, n) => [
                        ...res,
                        {
                          ...n,
                          qr: Array.isArray(n.qr)
                            ? (() => {
                                let removed = false; // ตัวแปรสำหรับติดตามว่าลบไปแล้วหรือยัง
                                return _.filter(n.qr, (qr) => {
                                  if (
                                    !removed &&
                                    (qr.qr_running === "" ||
                                      _.some(
                                        payload.bom_qr,
                                        (m) => qr.qr_running === m
                                      ))
                                  ) {
                                    removed = true; // ทำการลบเฉพาะรายการแรกที่ตรงเงื่อนไข
                                    return false;
                                  }
                                  return true;
                                });
                              })()
                            : [], // ให้ qr เป็น array ว่างหาก n.qr ไม่มีค่า
                        },
                      ],
                      []
                    ),
                  },
                },
              },
            },
          },
        },
      };

    case Types.SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...payload,
        },
      };

    case Types.SET_PRINTFORM:
      return {
        ...state,
        printform: {
          ...state.printform,
          ...payload,
        },
      };

    case Types.SET_RALC:
      return {
        ...state,
        ralc: payload,
      };

    default:
      return state;
  }
};

export const Creators = {
  clear: () => {
    return {
      type: Types.CLEAR,
    };
  },
  setOperatorValue: (value) => {
    return {
      type: Types.SET_OPERATION_VALUE,
      payload: value,
    };
  },
  addOrderItem: (obj) => {
    return {
      type: Types.ADD_ORDER_ITEM,
      payload: obj,
    };
  },
  addOrderItemFromPromotion: (obj) => {
    return {
      type: Types.ADD_ORDER_ITEM_FROM_PROMOTION,
      payload: obj,
    };
  },
  setOrderItemValue: (key, obj) => {
    return {
      type: Types.SET_ORDER_ITEM_VALUE,
      payload: {
        key,
        obj,
      },
    };
  },
  setOrderItem: (obj) => {
    return {
      type: Types.SET_ORDER_ITEM,
      payload: obj,
    };
  },
  delOrderItem: (key) => {
    return {
      type: Types.DEL_ORDER_ITEM,
      payload: key,
    };
  },
  addOrderPromotion: (obj) => {
    return {
      type: Types.ADD_ORDER_PROMOTION,
      payload: obj,
    };
  },
  setOrderPromotionItemValue: (
    promotion_id,
    source_option,
    product_code,
    data
  ) => {
    return {
      type: Types.SET_ORDER_PROMOTION_ITEM_VALUE,
      payload: {
        promotion_id,
        source_option,
        product_code,
        data,
      },
    };
  },
  delOrderPromotionAll: () => {
    return {
      type: Types.DEL_ORDER_PROMOTION_ALL,
    };
  },
  delOrderPromotionItem: (obj) => {
    return {
      type: Types.DEL_ORDER_PROMOTION_ITEM,
      payload: obj,
    };
  },
  setPromotionCollect: (obj) => {
    return {
      type: Types.SET_PROMOTION_COLLECT,
      payload: obj,
    };
  },
  changeStep: (step) => {
    return {
      type: Types.CHANGE_STEP,
      payload: step,
    };
  },
  addBill: (obj) => {
    return {
      type: Types.ADD_BILL,
      payload: obj,
    };
  },
  setBill: (obj) => {
    return {
      type: Types.SET_BILL,
      payload: obj,
    };
  },
  setBillActive: (key) => {
    return {
      type: Types.SET_BILL_ACTIVE,
      payload: key,
    };
  },
  setBillValue: (obj) => {
    return {
      type: Types.SET_BILL_VALUE,
      payload: obj,
    };
  },
  setPaymentActive: (key) => {
    return {
      type: Types.SET_PAYMENT_ACTIVE,
      payload: key,
    };
  },
  addPayment: (obj) => {
    return {
      type: Types.ADD_PAYMENT,
      payload: obj,
    };
  },
  setPaymentValue: (key, obj) => {
    return {
      type: Types.SET_PAYMENT_VALUE,
      payload: {
        key,
        obj,
      },
    };
  },
  setPaymentRedeem: (payload) => {
    return {
      type: Types.SET_PAYMENT_REDEEM,
      payload,
    };
  },
  delPayment: (key) => {
    return {
      type: Types.DEL_PAYMENT,
      payload: key,
    };
  },
  delBill: (key) => {
    return {
      type: Types.DEL_BILL,
      payload: key,
    };
  },
  addDiscount: (value) => {
    return {
      type: Types.ADD_DISCOUNT,
      payload: value,
    };
  },
  addBillDiscount: (value) => {
    return {
      type: Types.ADD_BILL_DISCOUNT,
      payload: value,
    };
  },
  delDiscount: (discount_type, activeKey) => {
    return {
      type: Types.DEL_DISCOUNT,
      payload: { discount_type, activeKey },
    };
  },
  addVoucher: (value) => {
    return {
      type: Types.ADD_VOUCHER,
      payload: value,
    };
  },
  delVoucher: (id, activeKey) => {
    return {
      type: Types.DEL_VOUCHER,
      payload: { id, activeKey },
    };
  },
  clearPaymentIgnoreActiveKey: () => {
    return {
      type: Types.CLEAR_PAYMENT_IGNORE_ACTIVE_KEY,
    };
  },
  cancelPayment: () => {
    return {
      type: Types.CANCEL_PAYMENT,
    };
  },
  setMatchingPromotionItem: (value) => {
    return {
      type: Types.SET_MATCHING_PROMOTION_ITEM,
      payload: value,
    };
  },
  addOrderItemList: (value) => {
    return {
      type: Types.ADD_ORDER_ITEM_LIST,
      payload: value,
    };
  },
  setQrWarning: (value) => {
    return {
      type: Types.SET_QR_WARNING,
      payload: value,
    };
  },
  addOrderItemBomQr: (value) => {
    return {
      type: Types.ADD_ORDER_ITEM_BOM_QR,
      payload: value,
    };
  },
  delOrderItemBomQr: (value) => {
    return {
      type: Types.DEL_ORDER_ITEM_BOM_QR,
      payload: value,
    };
  },
  setLoading: (value) => {
    return {
      type: Types.SET_LOADING,
      payload: value,
    };
  },
  changePrintform: (value) => {
    return {
      type: Types.SET_PRINTFORM,
      payload: value,
    };
  },
  setRalc: (value) => {
    return {
      type: Types.SET_RALC,
      payload: value,
    };
  },
};
