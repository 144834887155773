/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";
import reactImageSize from "react-image-size";
import TextEditor from "../../../../../components/TextEditor";
import { DivStyles, ImgStyles } from "../../components/mwStyles";
import useUploadFile from "../../components/useUploadFile";
import { dummyRequest } from "../../components/dummyRequest";

function DealerSuccess({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");

  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);

  const [textEditorTitle, setTextEditorTitle] = useState("");
  const [textEditorContent, setTextEditorContent] = useState("");

  const [fileDelete, setFileDelete] = useState([]);
  let { onUpload, onRemoveUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      if (fileListMobile[0]?.file) {
        formData.append("mobile_img", fileListMobile[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img", "normal_video"]),
          title: textEditorTitle,
          content: textEditorContent,
          file_delete: fileDelete,
        })
      );

      console.log(formData);

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != ""
              ? (url_path = "/manage-website/home/dealer-success/update")
              : (url_path = "/manage-website/home/dealer-success");
            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveMobile = (e) => {
    setFileListMobile([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleUploadDesktop = async (e) => {
    let isFile = await onUpload(e, 2000, 600);
    if (isFile) {
      setFileListDesktop(isFile);
    }
  };

  const handleUploadMobile = async (e) => {
    let isFile = await onUpload(e, 375, 375);
    if (isFile) {
      setFileListMobile(isFile);
    }
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/home/dealer-success",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);
        setTextEditorTitle(data.title);
        setTextEditorContent(data.content);

        let { loadDesktopImg, loadMobileImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListMobile(loadMobileImg);
          }
        }

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;
  return (
    <>
      <Panel>
        <PanelHeader>ความสำเร็จนักธุรกิจ</PanelHeader>
        <PanelBody>
          <Row gutter={24}>
            <Col md={12}>
              {fileListDesktop.length != 0 ? (
                <Row>
                  <div style={DivStyles}>
                    <img
                      src={fileListDesktop[0].url}
                      alt="desktop_img"
                      style={ImgStyles}
                    />
                  </div>
                </Row>
              ) : (
                ""
              )}
            </Col>
            <Col md={12}>
              {fileListMobile.length != 0 ? (
                <div style={DivStyles}>
                  <img
                    src={fileListMobile[0].url}
                    alt="mobile_img"
                    style={ImgStyles}
                  />
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Row gutter={24} className="mb-2">
              <Col md={12} className="mb-2">
                <Form.Item
                  label="Desktop = 2000 x 600 px : "
                  name="desktop_img"
                >
                  {getFieldDecorator("desktop_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListDesktop}
                      customRequest={dummyRequest}
                      onRemove={handleRemoveDesktop}
                      onChange={handleUploadDesktop}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
              <Col md={12} className="mb-2">
                <Form.Item label="Mobile = 375 x 375 px : " name="mobile_img">
                  {getFieldDecorator("mobile_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListMobile}
                      customRequest={dummyRequest}
                      beforeUpload={beforeUploadImg}
                      onRemove={handleRemoveMobile}
                      onChange={handleUploadMobile}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12}>
                <h5>title</h5>
                <TextEditor
                  selector="textarea"
                  plugins="textcolor "
                  toolbar="forecolor"
                  authToken={getToken()}
                  value={textEditorTitle}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setTextEditorTitle(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Col>
              <Col md={12}>
                <h5>content</h5>
                <TextEditor
                  selector="textarea"
                  plugins="textcolor "
                  toolbar="forecolor"
                  authToken={getToken()}
                  value={textEditorContent}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setTextEditorContent(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Item label="Button Name ( CTA ) :">
                {getFieldDecorator("cta_name", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุ Button Name ( CTA )",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="CTA Link :">
                {getFieldDecorator("target_link", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ CTA Link" }],
                })(<Input />)}
              </Form.Item>
            </Row>
            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != "" ? "Update" : "Save"}
              </Button>
            </Row>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("home-dealer-success")(DealerSuccess);
