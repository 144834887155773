import React, { useEffect, useState } from "react";
import { Alert, Select } from "antd";
import { useHttp } from "../../../../hooks/http";
import { getUserObj, URL_API, _ } from "../../../../utils";

const SelectWarehouse = ({
  className,
  withAll,
  userLogin,
  userOnlyLogin,
  branchCode,
  notIn,
  value,
  ...props
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/transfer-receive/master/warehouse`,
      params: {
        option: userLogin ? "user-login" : "",
      },
      token: true,
    },
    [userLogin]
  );

  useEffect(() => {
    let res = [];
    if (data) {
      res = [...data.data];
      if (notIn) {
        res = _.filter(res, (n) =>
          _.every(notIn, (m) => m !== n.warehouse_code)
        );
      }
      if (branchCode) {
        res = _.filter(
          res,
          (n) => _.split(n.warehouse_code, "-")[0] === branchCode
        );
      }
      if (userOnlyLogin) {
        const { branch_code } = getUserObj();
        res = _.filter(
          res,
          (n) => _.split(n.warehouse_code, "-")[0] === branch_code
        );
      }
    }
    setDataSource(res);
  }, [data, branchCode, notIn, userOnlyLogin]);

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select
      loading={loading}
      className={`w-100 ${className || ""}`}
      value={loading ? undefined : value}
      {...props}
    >
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {_.map(dataSource, (n) => (
        <Select.Option key={n.warehouse_code} value={n.warehouse_code}>
          {branchCode ? n.wh_type_name : n.location}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectWarehouse;
