import { combineReducers } from "redux";

import lot from "./lot";
import set from "./set";
import balance from "./balance";
import transfer from "./transfer";
import bill from "./bill";

export default combineReducers({
  lot,
  set,
  balance,
  transfer,
  bill,
});
