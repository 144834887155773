import React, { forwardRef } from "react";
import { useApi } from "../hooks/http2";
import { URL_API, _ } from "../utils";
import SelectOption from "./SelectOption";

const SelectAllType = ({
  process_type = "",
  labelIndex = "name",
  valueIndex = "code",
  addMember = false,
  delCloverThink = false,
  addStaff = false,
  addCustomer = false,
  ...props
}) => {
  let dataSource = []
  const list = useApi(
    {
      url: `${URL_API}/system/master/${process_type}`,
      token: true,
    },
    [process_type]
  );

  dataSource = list.fetch?.data || []

  if(addMember){
    if(!dataSource.some(e => e.code === 'member')){
      dataSource.push({code: 'member', name: 'VIP Member', seq: 0})
    }
  }
  if(addStaff){
    if(!dataSource.some(e => e.code === 'Staff')){
      dataSource.push({code: 'Staff', name: 'Staff', seq: 0})
    }
  }
  if(delCloverThink){
    if(dataSource.some(e => e.name === 'CloverThink')){
      dataSource = dataSource.filter(e=>e.name !== 'CloverThink')
    }
  }
  if(addCustomer){
    if(!dataSource.some(e => e.code === 'customer')){
      dataSource.push({code: 'customer', name: 'customer', seq: 0})
    }
  }
  return (
    <SelectOption
      loading={list.loading}
      data={_.map(
        _.filter(dataSource || [], (n) => {
          if (process_type === "barcode_type") {
            if (props.division_id !== "17") {
              return n.code !== "international";
            } else {
              return true;
            }
          } else {
            return true;
          }
        }),
        (n) => ({
          value: n[valueIndex]?.toString(),
          text: n[labelIndex],
        })
      )}
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectAllType forwardRef={ref} {...props} />;
});
