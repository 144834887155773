import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const registerPaperQueryDefaultValues = {
  idCard: '',
  fitNo: '',
};

export const registerPaperQueryFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      idCard: yup.string(),
      fitNo: yup.string(),
      startDate: yup.date(),
      endDate: yup.date(),
    })
  );
};

export const registerPaperUpdateDefaultValues = {
  startDate: moment(new Date()),
  endDate: moment(new Date()).add('d', 3),
};

export const registerPaperUpdateFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      startDate: yup.date(),
      endDate: yup.date(),
    })
  );
};

export const registerPapersUpdateDefaultValues = {
  endDate: 1,
};

export const registerPapersUpdateFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      endDate: yup.number().min(1),
    })
  );
};

export const registerPaperUpdateNoteFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      startDate: yup.date(),
      endDate: yup.date(),
    })
  );
};

export const registerPapersUpdateNoteFormSchema = () => {
  return yupResolver(
    yup.object().shape({
     note: yup.string()
    })
  );
};