import { createReducer } from "@reduxjs/toolkit";
import React, { createContext, useContext, useReducer } from "react";

const EventContext = createContext();
const initState = {
  loading: false,
  exporting: false,
  search: {
    page: 1,
    limit: 10,
    event_id: "",
    query: "",
    status: "",
    honor_code: "",
    team_name: "",
  },
  fetch: {
    data: [],
    total: 0,
    count: {
      total: 0,
      registered: 0,
      available: 0,
      newBieUsed: 0,
      newBieRegistered: 0
    },
  },
  setStorage: false
};
const reducer = createReducer(initState, {
  CLEAR: (state) => {
    state.loading = false;
    state.search = { ...initState.search };
    state.fetch = { ...initState.fetch };
  },
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_SEARCH: (state, { payload }) => {
    state.search = { ...state.search, ...payload };
    state.setStorage = true
  },
  SET_SEARCH_SUCCESS: (state, { payload }) => {
    state.loading = false;
    state.fetch = {
      ...state.fetch,
      ...payload,
    };
  },
  SET_EXPORTING: (state, { payload }) => {
    state.exporting = payload;
  },
});

export const EventMutateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <EventContext.Provider value={{ state, dispatch }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventMutate = () => {
  const context = useContext(EventContext);
  if (!context)
    throw new Error(
      "Event Mutate Context must be used within Event Mutate Provider"
    );
  return context;
};
