import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";

export const ProductCategoryContext = createContext();

export const initState = {
  division: null,
  department: null,
  category: null,
  modal: {
    visible: false,
    name: "",
  },
  form: {
    category: {
      id: "",
      code: "",
      name: "",
    },
    sub_category: {
      id: "",
      name: "",
    },
  },
  reload: {
    category: "",
    sub_category: "",
  },
};

export const reducer = createReducer(initState, {
  SET_DIVISION: (state, { payload }) => {
    state.division = payload;
    state.department = null;
    state.category = null;
  },
  SET_DEPARTMENT: (state, { payload }) => {
    state.department = payload;
    state.category = null;
  },
  SET_CATEGORY: (state, { payload }) => {
    state.category = payload;
  },
  OPEN_MODAL: (state, { payload }) => {
    state.modal = {
      visible: true,
      name: payload,
    };
  },
  CLOSE_MODAL: (state) => {
    state.modal = { ...initState.modal };
    state.form = { ...initState.form };
  },
  EDIT_CATEGORY: (state, { payload }) => {
    state.modal = {
      visible: true,
      name: "category",
    };
    state.form.category = {
      id: payload.id,
      code: payload.code,
      name: payload.name_local,
    };
  },
  EDIT_SUB_CATEGORY: (state, { payload }) => {
    state.modal = {
      visible: true,
      name: "sub_category",
    };
    state.form.sub_category = {
      id: payload.id,
      name: payload.name_local,
    };
  },
  CHANGE_FORM_VALUE: (state, { payload }) => {
    state.form[payload.form][payload.name] = payload.value;
  },
  RELOAD: (state, { payload }) => {
    state.reload[payload] = Date.now();
  },
});

export default ProductCategoryContext;
