/* eslint-disable no-unused-vars */
import { Skeleton } from "antd";
import React, { useContext, useState } from "react";
import Tabs from "../../components/Tabs";
import VipMemberContext from "../contexts/vip-member-context";
import MemberForm from "./MemberForm";
import SponsorForm from "./SponsorForm";

const Member = ({ loadingMember, Member, errors, setMember, handleChange }) => {
  const { state, dispatch } = useContext(VipMemberContext);
  const { ActiveTabs } = state;
  const list = [
    { title: "ข้อมูลผู้สมัครหลัก", value: "Member" },
    { title: "ผู้แนะนำ", value: "Sponsor" },
  ];
  const [activeTab, setActiveTab] = useState("Member");
  return (
    <>
      <Tabs
        active={activeTab}
        onClick={(e) => {
          setActiveTab(e);
          dispatch({
            type: "SET_ACTIVE_TAB",
            payload: { active_tab_name: e },
          });
        }}
        list={list}
      />
      <div className="container shadow-sm p-15 mb-4 bg-white rounded">
        {activeTab === "Member" ? (
          <>
            {loadingMember ? (
              <Skeleton active />
            ) : (
              <MemberForm
                Member={Member}
                setMember={setMember}
                errors={errors}
                handleChange={handleChange}
              />
            )}
          </>
        ) : (
          <SponsorForm
            Member={Member?.sponsor}
            Members={Member}
            setMember={setMember}
          />
        )}
      </div>
    </>
  );
};
export default Member;
