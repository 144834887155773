import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

//redux
import Store from './redux/store';

// css
// import 'antd/dist/antd.css'; //import
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

import './scss/custom/custom.scss';
import './assets/css/main.css';
import 'react-rangeslider/lib/index.css';
import './scss/utility.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import dotenv from 'dotenv';
import dotenvExpand from 'dotenv-expand';

import './utils/dayjsUtil';

var myEnv = dotenv.config();
dotenvExpand(myEnv);

// ========================================

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
