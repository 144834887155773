/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, Modal, notification, Row, Typography, Button } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { FaAngleDown, FaAngleUp, FaGift } from "react-icons/fa";
import InfiniteScroller from "react-infinite-scroller";
import { AiOutlineFileSearch } from "react-icons/ai";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fixBranch,
  fixProductType,
  fixProductTypeSet,
} from "../../../../config/fix-value";
import { axios, getUserObj, URL_API, _ } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { Creators } from "../ducks/operation-form";
import ItemCard from "./ItemCard";
import ItemDetailPopup from "./ItemDetailPopup";
import PromotionList from "./PromotionList";
import SelectSubCategory from "./SelectSubCategory";
import ModalPromotionRemark from "./ModalPromotionRemark";

const init_state = {
  loading: false,
  hasMore: true,
  data: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setValue":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

const limit = 20;
let freeze;
const PosOrderSearch = ({ match, ...props }) => {
  const scrollRef = useRef(null);
  const searchRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [showPromotionList, setShowPromotionList] = useState(false);
  const [showPromotionRemark, setShowPromotionRemark] = useState(false);
  const [source, setSource] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [code, setCode] = useState("");
  const [category, setCategory] = useState("");
  const type_params = match.params.type;
  const [state, dispatch] = useReducer(reducer, init_state);
  const [initialLoad, setInitialLoad] = useState(true);
  const [visible, setVisible] = useState(false);
  const [ProductSelect, setProductSelect] = useState(null);
  const [categoryValue, setCategoryValue] = useState([0]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [watchVipAutoAdd, setWatchVipAutoAdd] = useState(false);
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );
  const isPreProduct = _.includes(["pre-sale", "pre-redeem"], type_params);
  const isDelivery = _.includes(["Transport", "Lowcost"], props.req_receipt_type);
  useEffect(() => {
    setLoaded(true);
    return () => source?.cancel("unmounted");
  }, []);

  const color = useMemo(() => {
    let res = "";
    switch (type_params) {
      case "redeem":
        res = "rp";
        break;

      case "subscription":
        res = "tp";
        break;

      case "reserve":
        res = "cv";
        break;

      case "pre-sale":
        res = "sp";
        break;

      case "pre-redeem":
        res = "nn";
        break;

      default:
        res = "pv";
        break;
    }
    return res;
  }, [type_params]);

  // useEffect(() => {
  //   // on change receipt type or branch code, load product
  //   if ((props.req_receipt_type === "Transport" || props.req_receipt_type === "Lowcost") || props.receipt_branch_code) {
  //     source && source.cancel("cancel");
  //     setSource(axios.CancelToken.source());
  //   }
  // }, [props.receipt_branch_code, props.req_receipt_type]);
  useEffect(()=>{
    if(props.req_receipt_type){
      source && source.cancel("cancel");
      setSource(axios.CancelToken.source());
      setReloadFlag(true);
    }
  },[props.req_receipt_type])

  useEffect(() => {
    if (source) {
      if (reloadFlag) {
        setReloadFlag(false);
        loadProduct(1);
      } else {
        loadProduct(1);
      }
    }
  }, [source]);
  useEffect(() => {
    if (
      props.vip_member_starter_first_key[props.partner_code] === props.order_key
    ) {
      axios({
        url: `${URL_API}/pos/master/item/vip-member-starter`,
        params: { member_code: props.partner_code },
      })
        .then((res) => {
          if (res.data.data === "paid") return;
          if (res.data.data) {
            setLoadingSearch(true);
            const user = getUserObj();
            let option = {};
            if (!_.includes(fixBranch.online, user.branch_code)) {
              option = {
                receipt_branch_code: user.branch_code,
                receipt_branch_name: user.branch_name,
              };
            }
            props.setBillValue({
              ...option,
              showItems: false,
              req_bag: "1",
              req_receipt: "1",
              req_receipt_type: "Branch",
              ship_to_id: null,
              ship_address_no: null,
              ship_sub_district: null,
              ship_district: null,
              ship_province: null,
              ship_post_code: null,
              ship_country: null,
              ship_to: null,
              ship_mobile: null,
              total_delivery: 0,
            });
            props.delOrderPromotionAll();
            setWatchVipAutoAdd(true);
            return setCode(res.data.data.product_code);
          }
          Modal.error({
            title: "ไม่สามารถเพิ่มชุดสมัคร VIP Member ได้",
            content: "ไม่พบข้อมูลชุดสมัคร",
            okText: "ตกลง",
          });
        })
        .catch((e) => resCatch(e));
    }
  }, [props.vip_member_starter_first_key[props.partner_code], props.order_key]);

  useEffect(() => {
    if (watchVipAutoAdd && code) {
      setWatchVipAutoAdd(false);
      handleSearch({ barcode: code, auto_add: true });
    }
  }, [watchVipAutoAdd, code]);

  const renderWarning = (description) => {
    setCode("");
    searchRef.current.focus();
    setLoadingSearch(false);
    notification.warning({
      message: "ไม่สามารถดำเนินการได้",
      description,
    });
  };

  const loadProduct = async (page) => {
    if(!source) return
    // if (source) {
      if (code !== "") props.setBillValue({ showItems: true });
      dispatch({
        type: "setValue",
        payload: { loading: true, hasMore: false },
      });
      setInitialLoad(false);
      try {
        let p = page - 1;
        if (!p || p < 0) p = 0;
        let response = await axios({
          url: `${URL_API}/pos/master/item/load`,
          params: {
            offset: p * limit,
            limit,
            category,
            type_params,
            code: code.trim(),
            partner_type: props.partner_type,
            product_type: isNormal ? "normal" : "redeem",
            req_receipt_type: props.req_receipt_type, 
            receipt_branch_code: props.receipt_branch_code,
            is_pre_order: isPreProduct ? 1 : 0,
          },
          cancelToken: source?.token,
        });

        let hasMore = false,
          fetch = p === 0 ? [] : state.data;
        if (response && response.data) {
          fetch = fetch.concat(response.data.data);
          if (response.data.total && fetch.length < response.data.total)
            hasMore = true;
        }

        dispatch({
          type: "setValue",
          payload: { loading: false, hasMore, data: fetch },
        });
      } catch (error) {
        dispatch({
          type: "setValue",
          payload: { loading: false, hasMore: false },
        });
      }
    // }
  };

  const handleSearch = async ({ barcode, qr_running, auto_add, qr }) => {
    let product = null;
    let qr_data = null;
    let product_code = null;
    try {
      const user = auto_add && getUserObj();

      let code_search = auto_add ? barcode : code;
      if (qr_running) {
        const res = await axios({
          url: `${URL_API}/pos/qr`,
          params: {
            qr_running,
            barcode,
            partner_type: props.partner_type,
            sales_type: type_params,
            qr,
            branch: auto_add ? user.branch_code : props.receipt_branch_code,
          },
        });
        const {
          warn,
          // failed,
          data,
        } = res.data;
        if (!data.product_code) return renderWarning("ไม่พบข้อมูลสินค้า");
        if (warn) return renderWarning(warn);
        // if (failed.qr) return renderWarning("qr running ถูกใช้งานแล้ว");
        // if (failed.product) return renderWarning("พบสินค้าหลายรายการ");
        product_code = data?.product_code
        const qr_used = _.some(
          props.qrUsedList[data.product_code],
          (n) => n === qr_running 
        );
        console.log(props)
        // const barcode_used = _.some(
        //   props.qrUsedList[barcode],
        //   (n) => n === qr_running
        // );
        const foundKey = _.findKey(props.order_item.itemByKey, (item) => item.product_code === process.env.REACT_APP_WHEY_PROTEIN_SKU);
        const foundObject = props.order_item.itemByKey[foundKey];

        const used_running = _.some(foundObject?.qr, { qr_running: qr_running });
        console.log(used_running,qr_used,data.product_code)
        

        if (qr_used && data?.product_code !== process.env.REACT_APP_WHEY_PROTEIN_SKU) {
          return renderWarning("qr running ถูกใช้งานในรายการสั่งซื้อแล้ว");
        }

        if (used_running && data?.product_code === process.env.REACT_APP_WHEY_PROTEIN_SKU) {
          setCode("");
          setLoadingSearch(false);
          return notification.warning({
            message: "ไม่สามารถดำเนินการได้",
            description : "qr running ถูกใช้งานในรายการสั่งซื้อแล้ว"
          });
        }
        
        
        qr_data = res.data;
        if (data.main_product) code_search = data.main_product.barcode;
      }
      const res = await axios({
        url: `${URL_API}/pos/master/item${auto_add ? "/starter" : ""}`,
        params: {
          type_params,
          code: code_search,
          partner_type: props.partner_type,
          product_type: isNormal ? "normal" : "redeem",
          req_receipt_type: auto_add ? "Branch" : props.req_receipt_type,
          receipt_branch_code: auto_add
            ? user.branch_code
            : props.receipt_branch_code,
          is_pre_order: isPreProduct ? 1 : 0,
        },
      });
      product = res.data.data;
    } catch (e) {
      resCatch(e);
    }

    // && auto_add #392  && (auto_add || qr)
    if (product) {
      if (qr_running && qr_data.data.product_code !== product.product_code)
        return renderWarning("สินค้าที่เลือกไม่ถูกต้อง");

      const { stock_balance, product_type_set } = product;
      if (
        !_.includes(
          [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
          product_type_set
        ) &&
        stock_balance <= 0
      )
        return renderWarning("ไม่มีสินค้าใน stock");

      if (product.product_type_set === fixProductTypeSet.kit_pack) {
        // if (qr_running && qr_data.failed.store_qr)
        //   return renderWarning("ไม่พบข้อมูล store qr");
        const { bom } = product;
        const bom_qr = qr_running
          ? _.reduce(
              bom,
              (res, n) => {
                const { ref_product_code } = n;
                const qr_filter = _.filter(
                  qr_data.data.bom,
                  (n) => n.product_code === ref_product_code
                );
                return [
                  ...res,
                  {
                    ...n,
                    barcode: qr_filter[0].barcode,
                    qr: _.map(qr_filter, (n) => ({
                      ref_product_code: qr_data.data.main_product.product_code,
                      ref_store_qr_id: qr_data.data.main_product.id,
                      ref_store_qr_type: "sub",
                      qr_running: n.qr_running,
                    })),
                  },
                ];
              },
              []
            )
          : bom;
        props.addOrderItem({
          check_stock: !isPreProduct,
          product_id: product.id,
          product_code: product.product_code,
          product_name: product.product_name_en,
          product_type: product.product_type,
          product_type_set: product.product_type_set,
          promotion_id: 0,
          bom: bom_qr,
          sku: product.backend_sku_code,
          weight: product.weight,
          unit_price: product.unit_price,
          cv: product.point_03,
          pv: product.point_01,
          rp: product.point_02,
          rtb: product.point_04,
          bp: product.point_05,
          ra: product.point_06,
          tp: product.point_07,
          cd: product.d_point_08,
          ep: product.point_09,
          qty_rate: product.point_01_rate,
          stock_balance: product.stock_balance,
          qr: qr_running
            ? [
                {
                  ref_product_code: null,
                  ref_store_qr_id: qr_data.data.main_product.id,
                  ref_store_qr_type: "main",
                  qr_running: qr_data.data.main_product.main_running,
                },
              ]
            : [],
          barcode: qr_running ? qr_data.data.main_product.barcode : barcode,
          category_parent: product.category_parent || [],
          course_ralc: product.course_ralc,
        });
      } else {
        props.addOrderItem({
          check_stock: !isPreProduct,
          product_id: product.id,
          product_code: product.product_code,
          product_name: product.product_name_en,
          product_type: product.product_type,
          product_type_set: product.product_type_set,
          promotion_id: 0,
          bom: product.bom,
          sku: product.backend_sku_code,
          weight: product.weight,
          unit_price: product.unit_price,
          cv: product.point_03,
          pv: product.point_01,
          rp: product.point_02,
          rtb: product.point_04,
          bp: product.point_05,
          ra: product.point_06,
          tp: product.point_07,
          cd: product.d_point_08,
          ep: product.point_09,
          qty_rate: product.point_01_rate,
          stock_balance: product.stock_balance,
          qr: qr_running
            ? [
                {
                  ref_product_code: null,
                  ref_store_qr_id: qr_data.data.main_product?.id || null,
                  ref_store_qr_type: qr_data.data.main_product?.id
                    ? "main"
                    : null,
                  qr_running,
                },
              ]
            : [],
          barcode,
          auto_add,
          category_parent: product.category_parent || [],
          course_ralc: product.course_ralc,
        });
      }
      setCode("");
      searchRef.current.focus();
    } else if (auto_add) {
      Modal.warning({
        title: "ไม่สามารถเพิ่มชุดสมัคร VIP อัตโนมัติได้",
        content: "ไม่พบข้อมูลชุดสมัคร",
        okText: "ตกลง",
        zIndex: 1080,
      });
      setCode("");
      searchRef.current.focus();
    }
    setLoadingSearch(false);

    // reload
    dispatch({
      type: "setValue",
      payload: { data: [], hasMore: true },
    });
    if (scrollRef?.current) scrollRef.current.pageLoaded = 0;
    props.setBillValue({ showItems: true });
    setInitialLoad(true);
    return product_code
  };

  const handleSearchValidate = async () => {
    clearFreeze();
    setLoadingSearch(true);
    const qr_running = code.split("LgcSN=")[1]?.split("&")[0];
    const barcode = code.split("LgcSKU=")[1]?.split("&")[0];
    // if (qr_running && !props.allow_qr)
    //   return renderWarning("Qr Code มี qr running");

    if (!qr_running) {
      return handleSearch({
        barcode,
        qr: code?.toLowerCase().indexOf("lgcsku=") !== -1 ? code : null,
      });
    }

    let product_code = await handleSearch({ barcode, qr_running, qr: code });
   
    const qr_used = _.some(props.qrUsedList[barcode], (n) => n === qr_running);
    if (qr_used && product_code && product_code !== process.env.REACT_APP_WHEY_PROTEIN_SKU ) {
      return renderWarning("qr running ถูกใช้งานในรายการสั่งซื้อแล้ว");
    }
  };

  const onSelectProduct = useCallback(
    (e) => {
      if (isPreProduct && props.order_item.itemKeys.length === 1) {
        const { itemKeys, itemByKey } = props.order_item;
        if (e.product_code !== itemByKey[itemKeys[0]].product_code)
          return notification.warning({
            message: "ไม่สามารถดำเนินการได้",
            description: "Pre Order สินค้าได้ ครั้งละ 1 รายการ",
          });
      }
      let find_product = _.find(
        props.order_item.itemByKey,
        (n) => n.product_code === e.product_code
      );
      if (find_product) {
        if (e.product_type === "PT04") {
          return notification.warning({
            message: "ไม่สามารถเพิ่มจำนวนสินค้า",
            description: "ต่ออายุซื้อได้แค่ 1 ชุดเท่านั้น",
          });
        }
        if (e.course_ralc) {
          return notification.warning({
            message: "ไม่สามารถเพิ่มจำนวนสินค้า",
            description: "Course RALC ซื้อได้แค่ 1 Course เท่านั้น",
          });
        }
      }
      setCode("");
      if (e) {
        props.addOrderItem({
          check_stock: !isPreProduct,
          product_id: e.id,
          product_code: e.product_code,
          product_name: e.product_name_en,
          product_type: e.product_type,
          product_type_set: e.product_type_set,
          promotion_id: 0,
          bom: e.bom,
          sku: e.backend_sku_code,
          weight: e.weight,
          // promotion: e.product_id != null,
          // unit_price: e[`${props.partner_type}_price`],
          unit_price: e.unit_price,
          cv: e.point_03,
          pv: e.point_01,
          rp: e.point_02,
          rtb: e.point_04,
          bp: e.point_05,
          ra: e.point_06,
          tp: e.point_07,
          cd: e.d_point_08,
          ep: e.point_09,
          img_url: e.img_url,
          qty_rate: e.point_01_rate,
          stock_balance: e.stock_balance,
          category_parent: e.category_parent || [],
          course_ralc: e.course_ralc,
        });
        props.setMatchingPromotionItem({
          allow_show_recommend: true,
        });
        searchRef.current.focus();
      }
    },
    [
      searchRef.current,
      props.addOrderItem,
      props.setMatchingPromotionItem,
      props.order_item?.itemKeys,
    ]
  );

  const onClickProduct = useCallback((e) => {
    setProductSelect(e);
    setVisible(true);
  }, []);

  const clearFreeze = () => {
    if (freeze) clearTimeout(freeze);
  };

  useEffect(() => {
    if (loaded) {
      if (freeze) clearFreeze();

      freeze = setTimeout(() => {
        loadProduct(1);
        clearFreeze();
      }, 1500);
    }
  }, [code]);

  const disabled =
    !props.order_type ||
    !props.req_receipt_type ||
    (props.req_receipt_type === "Branch" && !props.receipt_branch_code);

  return (
    <>
      <div
        className={`pos-order-search bg-${color} ${
          props.showItems ? "show-items" : ""
        }`}
      >
        <Row
          className="mb-2 d-flex align-items-center"
          style={{ fontSize: "14px" }}
          gutter={16}
        >
          <Col span={8} className="d-flex align-items-center">
            <Typography.Text
              className="text-white white-space-nowrap"
              style={{ fontSize: "16px" }}
              strong
            >
              ค้นหา/สแกน
            </Typography.Text>
            <Input.Search
              loading={loadingSearch}
              className="ml-3"
              placeholder="Start typing Item Name or scan Barcode..."
              value={code}
              disabled={disabled}
              onChange={(e) => setCode(e.target.value)}
              onSearch={handleSearchValidate}
              ref={searchRef}
            />
          </Col>
          {!disabled && (
            <Col
              span={16}
              className="d-flex align-items-center text-white white-space-nowrap"
            >
              {type_params === "sale" && (
                <button
                  type="button"
                  className="pos-btn bg-reds text-white width-auto mr-3"
                  onClick={() => setShowPromotionList(true)}
                >
                  <FaGift className="mr-2" /> รายการโปรโมชัน
                </button>
              )}
              <Button
                size="large"
                shape="circle"
                style={{
                  backgroundColor: "#003A8C",
                  fontSize: 20,
                  color: "#FFFFFF",
                }}
                className="mr-1"
                onClick={() => setShowPromotionRemark(true)}
              >
                <AiOutlineFileSearch />
              </Button>
             <ModalPromotionRemark 
              visible={showPromotionRemark}
              partner_code={props?.partner_code}
              onCancel={() => setShowPromotionRemark(false)} 
              />

              <strong
                className="cursor-pointer"
                onClick={() => {
                  if (props.showItems) {
                    props.setBillValue({ showItems: false });
                  } else {
                    props.setBillValue({ showItems: true });
                  }
                }}
              >
                {props.showItems ? (
                  <FaAngleUp size="1.5em" />
                ) : (
                  <FaAngleDown size="1.5em" />
                )}
                ดูสินค้าทั้งหมด
              </strong>

              {props.showItems && (
                <div className="pl-3 ml-3 border-left border-white">
                  <strong>หมวดสินค้า</strong>
                  <SelectSubCategory
                    typeParams={type_params}
                    className="ml-2 width-200"
                    value={categoryValue}
                    allowClear={categoryValue[0] !== 0}
                    onChange={(e, data) => {
                      setCategory(data[data.length - 1]?.value || "");
                      setCategoryValue(e.length !== 0 ? e : [0]);
                      dispatch({
                        type: "setValue",
                        payload: { data: [], hasMore: true },
                      });
                      scrollRef.current.pageLoaded = 0;
                      setInitialLoad(true);
                    }}
                  />
                </div>
              )}
            </Col>
          )}
        </Row>

        <Row className="custom-scroll scroll-white pos-order-show-items">
          {props.showItems && (
            <InfiniteScroller
              pageStart={0}
              initialLoad={initialLoad}
              loadMore={loadProduct}
              hasMore={!state.loading && state.hasMore}
              useWindow={false}
              // loader={
              //   <Spin style={{ color: "red" }} key={_.uniqueId("spin-")} />
              // }
              ref={scrollRef}
            >
              {state.data.map((n, i) => (
                <Col
                  key={i}
                  span={3}
                  md={3}
                  lg={3}
                  xl={2}
                  className="px-2 py-2"
                >
                  <ItemCard
                    source={n}
                    checkStock={!isPreProduct}
                    onClick={onClickProduct}
                    onSelect={onSelectProduct}
                  />
                </Col>
              ))}
            </InfiniteScroller>
          )}
        </Row>
      </div>

      <ItemDetailPopup
        visible={visible}
        product_code={ProductSelect?.product_code}
        DataSelectProduct={ProductSelect}
        setShowDetailProduct={setVisible}
        onCancel={() => {
          setProductSelect(null);
          setVisible(false);
        }}
      />

      <PromotionList
        visible={showPromotionList}
        onCancel={() => setShowPromotionList(false)}
        onSelect={async (data) => {
          setShowPromotionList(false);
          let { source, free, promotion_type, condition_option, free_option } =
            data;
          let order_item = [];
          let promotion_item = [];
          let max_price = 0;

          // map condition source
          for (
            let index_source = 0;
            promotion_type === "giveaway" && index_source < source.length;
            index_source++
          ) {
            const { product_code, qty } = source[index_source];
            const result = await axios({
              url: `${URL_API}/pos/master/item/load`,
              params: {
                offset: 0,
                limit,
                category,
                type_params,
                code: product_code,
                partner_type: props.partner_type,
                product_type: isNormal ? "normal" : "redeem",
                req_receipt_type: props.req_receipt_type,
                receipt_branch_code: props.receipt_branch_code,
                is_pre_order: isPreProduct ? 1 : 0,
              },
              cancelToken: source.token,
            });
            if (result.data.data?.length !== 1)
              return notification.warning({
                message: "แจ้งเตือน",
                description: `ไม่พบรายการ รหัสสินค้า ${data.source_value}`,
              });
            const e = result.data.data[0];
            order_item = [
              ...order_item,
              {
                product_id: e.id,
                product_code: e.product_code,
                product_name: e.product_name_local,
                product_type: e.product_type,
                product_type_set: e.product_type_set,
                promotion_id: 0,
                bom: e.bom,
                sku: e.backend_sku_code,
                weight: e.weight,
                unit_price: e.unit_price,
                cv: e.point_03,
                pv: e.point_01,
                rp: e.point_02,
                rtb: e.point_04,
                sp: e.point_05,
                tp: e.point_07,
                cd: e.d_point_08,
                ep: e.point_09,
                img_url: e.img_url,
                qty_rate: e.point_01_rate,
                stock_balance: e.stock_balance,
                qty,
              },
            ];
          }

          if (free[0].source_key === "item") {
            for (let index_free = 0; index_free < free.length; index_free++) {
              const data = free[index_free];
              const result = await axios({
                url: `${URL_API}/pos/master/item/load`,
                params: {
                  offset: 0,
                  limit,
                  category,
                  type_params,
                  code: data.source_value,
                  partner_type: props.partner_type,
                  product_type: isNormal ? "normal" : "redeem",
                  req_receipt_type: props.req_receipt_type,
                  receipt_branch_code: props.receipt_branch_code,
                  is_pre_order: isPreProduct ? 1 : 0,
                },
                cancelToken: source.token,
              });
              if (result.data.data?.length !== 1)
                return notification.warning({
                  message: "แจ้งเตือน",
                  description: `ไม่พบรายการ รหัสสินค้า ${data.source_value}`,
                });
              const e = {
                ...result.data.data[0],
                qty: +data.source_amount,
                point_01: +data.point_01 || 0,
                point_02: +data.point_01 || 0,
                point_03: +data.point_01 || 0,
              };
              promotion_item = [
                ...promotion_item,
                {
                  product_id: e.id,
                  product_code: e.product_code,
                  product_name: e.product_name_local,
                  product_type: e.product_type,
                  product_type_set: e.product_type_set,
                  bom: e.bom || [],
                  sku: e.backend_sku_code,
                  weight: e.weight,
                  promotion_id: data.promotion_id,
                  qty: e.qty,
                  unit_price: 0,
                  cv: e.point_03,
                  pv: e.point_01,
                  rp: e.point_02,
                  rtb: e.point_04,
                  sp: e.point_05,
                  tp: e.point_07,
                  cd: e.d_point_08,
                  ep: e.point_09,
                  source_key: data.source_key,
                  qty_rate: e.point_01_rate,
                  stock_balance: e.stock_balance,
                  promotion_item_type: "item",
                },
              ];
            }
          } else if (free[0].source_key === "voucher") {
            for (let index_free = 0; index_free < free.length; index_free++) {
              let data = free[index_free];
              const result = await axios({
                method: "get",
                baseURL: URL_API,
                url: `/setting/master/voucher/${data.source_value}`,
                cancelToken: source.token,
              });
              if (!result.data.data)
                return notification.warning({
                  message: "แจ้งเตือน",
                  description: `ไม่พบรายการ บัตรกำนัล id ${data.source_value}`,
                });
              data = {
                ...result.data.data,
                ...data,
              };
              promotion_item = [
                ...promotion_item,
                {
                  product_code: data.source_key,
                  product_name: `${data.promotion_name} : ${data.name} `, // `${data.promotion_name} : บัตรกำนัล${data.name} `
                  promotion_id: data.promotion_id,
                  qty: 1,
                  id: data.id,
                  code: data.code,
                  name: data.name,
                  type: data.type,
                  value: data.value,
                  keep: data.source_amount === 1,
                  source_key: data.source_key,
                  promotion_item_type: "voucher",
                },
              ];
            }
          } else {
            // calculate max price of condition source
            if (
              _.some(free, (n) =>
                _.includes(["price", "percent"], n.source_key)
              )
            ) {
              for (
                let source_index = 0;
                source_index < source.length;
                source_index++
              ) {
                const { product_code, qty } = source[source_index];
                const order_item_index = _.findIndex(
                  order_item,
                  (ele) => ele.product_code === product_code
                );
                max_price += order_item[order_item_index].unit_price * qty;
              }
            }

            let max_price_temp = max_price;
            for (let index_free = 0; index_free < free.length; index_free++) {
              const data = free[index_free];
              let price = 0;
              if (_.includes(["price", "percent"], data.source_key)) {
                if (max_price_temp > 0) {
                  const qty = +data.source_value;
                  const value =
                    data.source_key === "price"
                      ? qty
                      : (qty / 100) * max_price_temp;
                  if (max_price_temp - value >= 0) {
                    price = value;
                    max_price_temp -= value;
                  } else {
                    price = max_price_temp;
                    max_price_temp = 0;
                  }
                }
              }
              let amount =
                data.source_key === "price"
                  ? {
                      product_code: data.source_key,
                      product_name: `${data.promotion_name} : ส่วนลดจำนวน ${data.source_value} บาท`,
                      promotion_id: data.promotion_id,
                      qty: 1,
                      price,
                      source_key: data.source_key,
                      promotion_item_type: "amount",
                    }
                  : data.source_key === "percent"
                  ? {
                      product_code: data.source_key,
                      product_name: `${data.promotion_name} : ส่วนลดจำนวน ${data.source_value} เปอร์เซ็นต์`,
                      promotion_id: data.promotion_id,
                      qty: 1,
                      price,
                      source_key: data.source_key,
                      promotion_item_type: "amount",
                    }
                  : {
                      product_code: data.source_key,
                      product_name: `${
                        data.promotion_name
                      } : คะแนน ${data.source_key.toUpperCase()} จำนวน ${
                        data.source_value
                      } คะแนน`,
                      promotion_id: data.promotion_id,
                      qty: 1,
                      [data.source_key]: +data.source_value,
                      source_key: data.source_key,
                      promotion_item_type: "amount",
                    };
              promotion_item = [...promotion_item, amount];
            }
          }
          // promotion_item = {
          //   ...promotion_item,
          //   source: _.map(order_item, (n) => ({
          //     product_code: n.product_code,
          //     qty: n.qty,
          //   })),
          // };
          // console.log(order_item);
          // console.log(promotion_item);
          const promotion = {
            promotion_type,
            condition_source_option: condition_option,
            free_source_option: free_option,
            condition_type: "item",
            order_key: props.order_key,
            source: order_item,
            free: promotion_item,
            add_qty: 1,
          };
          props.addOrderItemList({ order_item, promotion });
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    orders: { activeKey, itemKeys, itemByKey },
  } = state.pos.operation;

  // const qrUsedList = _.reduce(
  //   itemKeys,
  //   (res, order_key) => {
  //     const {
  //       items,
  //       promotions: { promotion_list },
  //     } = itemByKey[order_key];
  //     const qr_item = _.reduce(
  //       items.itemKeys,
  //       (res, item_key) => {
  //         const { qr = [], bom } = items.itemByKey[item_key];
  //         const qr_bom = _.reduce(
  //           bom,
  //           (res, n) => (n.qr ? [...res, ...n.qr] : res),
  //           []
  //         );
  //         return [...res, ...qr, ...qr_bom];
  //       },
  //       []
  //     );
  //     const qr_promotion = _.reduce(
  //       promotion_list,
  //       (res, n) => [...res, ...(n.qr || [])],
  //       []
  //     );
  //     return [...res, ...qr_item, ...qr_promotion];
  //   },
  //   []
  // );

  let used = {};
  _.forEach(itemKeys, (orderKey) => {
    const { items } = itemByKey[orderKey];
    _.forEach(items.itemKeys, (key) => {
      const pd = items.itemByKey[key];
      if (pd) {
        let _qr = used[pd?.barcode] || [];

        _.forEach(pd.qr || [], (qrData) => {
          _qr.push(qrData.qr_running);
        });

        used[pd?.barcode] = _qr;
      }
    });
  });

  return {
    qrUsedList: used,
    order_key: activeKey,
    partner_code: itemByKey[activeKey]?.partner_code || null,
    partner_type: itemByKey[activeKey]?.partner_type.toLowerCase() || null,
    showItems: itemByKey[activeKey]?.showItems || false,
    req_receipt_type: itemByKey[activeKey]?.req_receipt_type || null,
    receipt_branch_code: itemByKey[activeKey]?.receipt_branch_code || null,
    order_type: itemByKey[activeKey]?.order_type || null,
    order_item: itemByKey[activeKey]?.items || null,
    // allow_qr: itemByKey[activeKey]?.allow_qr,
    vip_member_starter_first_key: _.reduce(
      itemKeys,
      (res, key) => {
        const order = itemByKey[key];
        if (order.partner_type.toLowerCase() !== "member") return res;
        const is_item_starter_added = _.some(
          order.items.itemKeys,
          (item_key) =>
            order.items.itemByKey[item_key]?.product_type ===
            fixProductType.starter
        );
        if (is_item_starter_added) return res;

        const other_keys = _.filter(
          itemKeys,
          (other_key) =>
            key !== other_key &&
            itemByKey[other_key].partner_code === order.partner_code
        );

        const is_other_added = _.some(other_keys, (other_key) =>
          _.some(
            itemByKey[other_key].items.itemKeys,
            (item_key) =>
              itemByKey[other_key].items.itemByKey[item_key].product_type ===
              fixProductType.starter
          )
        );
        if (is_other_added) return res;

        const is_first_key_added = _.some(
          _.keys(res),
          (partner_code) => partner_code === order.partner_code
        );
        if (is_first_key_added) return res;
        return { ...res, [order.partner_code]: key };
      },
      {}
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addOrderItem: Creators.addOrderItem,
    setBillValue: Creators.setBillValue,
    setMatchingPromotionItem: Creators.setMatchingPromotionItem,
    addOrderItemList: Creators.addOrderItemList,
    delOrderPromotionAll: Creators.delOrderPromotionAll,
  })(PosOrderSearch)
);
