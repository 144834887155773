/* eslint-disable no-unused-vars */
import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import ControlPanel from '../../../../../../components/ControlPanel';
import Layout from '../../../../../../components/Layout';
import ElementSetA from '../components/ElementSetA';
import ElementSetB from '../components/ElementSetB';
import LayoutBox from '../components/LayoutBox';
import ProductList from '../components/ProductList';
import PromotionDate from '../components/PromotionDate';
import PromotionDetail from '../components/PromotionDetail';
import PromotionDistribution from '../components/PromotionDistribution';
import PromotionJoinPro from '../components/PromotionJoinPro';
import PromotionMemberStatus from '../components/PromotionMemberStatus';
import PromotionPayment from '../components/PromotionPayment';
import PromotionFree from './PromotionFree';
import PromotionSource from './PromotionSource';

const Index = (props) => {
    const id = Number(props.match.params.id) || 0;
    const [show, setShow] = useState(false)
    return (
        <Layout
            titlePage="โปรโมชั่นราคาขาย"
            subTitlePage=""
        >
            <ControlPanel className="bg-transparent" titlePanel="โปรโมชั่นราคาขาย">
                <Row type="flex" justify="end" gutter={14} className="mb-2">
                    <Col><Button type="primary">Save</Button></Col>
                    <Col><Button type="danger" onClick={() => props.history.replace('/setting/master/promotion')}>Cancel</Button></Col>
                </Row>

                <Row gutter={24}>
                    <Col md={16}>
                        <LayoutBox id="promotion-detail" title="รายละเอียดโปรโมชั่น" content={<PromotionDetail />} />
                        <LayoutBox id="promotion-source" title="เงื่อนไขการซื้อ"
                            componentTopRight={<Button shape="circle" type="primary" icon="plus" onClick={() => setShow(true)} />}
                            content={<PromotionSource />}
                        />
                        <LayoutBox id="promotion-free" title="สิ่งที่ได้รับ"
                            componentTopRight={<Button shape="circle" type="primary" icon="plus" onClick={() => setShow(true)}/>}
                            content={<PromotionFree />}
                        />
                    </Col>
                    <Col md={8}>
                        <LayoutBox id="promotion-date" title="วันเวลา" content={<PromotionDate />} />
                        <LayoutBox id="promotion-distribution" title="รับโปรโมชั่นผ่านช่องทางการซื้อ" content={<PromotionDistribution />} />
                        <ElementSetA />
                        <ElementSetB />
                        <LayoutBox id="promotion-payment" title="รับโปรโมชั่นตามประเภทการชำระเงิน" content={<PromotionPayment />} />
                        <LayoutBox id="promotion-member-status" title="สถานะที่ได้รับสิทธิ์" content={<PromotionMemberStatus />} />
                        <LayoutBox id="promotion-join-pro" title="ใช้ร่วมกับโปรโมชั่นอื่น" content={<PromotionJoinPro />} />
                    </Col>
                </Row>

                <ProductList
                    title="เลือกสินค้า"
                    visible={show}
                    width={900}
                    onCancel={() => setShow(false)}
                    onOk={() => setShow(false)}
                />
            </ControlPanel>

        </Layout>
    )
}
export default Index
