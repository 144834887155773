import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
  loading: false,
  page: "",
  current: 1,
  limit: 10,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState: INIT_STATE,
  reducers: {
    SET_LOADING: (state, { payload }) => {
      state.loading = payload;
    },
    SET_PAGE: (state, { payload }) => {
      state.page = payload;
    },
    SET_CURRENT: (state, { payload }) => {
      state.current = payload;
    },
  },
});

export const actionMWBanner = bannerSlice.actions;

const bannerReducer = bannerSlice.reducer;

export default bannerReducer;
