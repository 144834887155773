/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadFile from "../../../../../components/UploadFile";
import { actionCreditNote } from "../../../../../redux/slices/credit-note";

const UploadDocument = () => {
  const { SET_DOCUMENT, CLEAR_DOCUMENT } = actionCreditNote;
  const dispatch = useDispatch();
  const document = useSelector((state) => state.creditNote.document);
  const handleSelectAuthDoc = useCallback(
    (value) => dispatch(SET_DOCUMENT(value)),
    []
  );
  const handleDeleteAuthDoc = useCallback(() => {
    dispatch(CLEAR_DOCUMENT());
  },[]);
  return (
    <Row gutter={[8, 8]} type="flex" className=" mb-2">
      <Col span={6} className="text-right">
        <Typography.Text strong className="text-nowrap">
          แนบเอกสาร
        </Typography.Text>
      </Col>
      <Col span={18}>
        <UploadFile
          camera
          //   disabled={disabled}
          acceptPDF
          file={document}
          //   hasError={!!authority_error}
          onChange={handleSelectAuthDoc}
          onDelete={handleDeleteAuthDoc}
        />
        {/* <ValidateError error={authority_error} /> */}
      </Col>
    </Row>
  );
};
export default memo(UploadDocument);
