/* eslint-disable eqeqeq */
import { _ } from "../../../../../utils";
import { fixDisCount } from "../../../../../config/fix-value";
export function getSummaryTotalPayment(orders, payment) {
  let summary_list = [];
  let summary_total = 0;
  let payment_total = 0;
  let cash_total = 0;
  let voucher_total = 0;
  let point_total = {
    pv: 0,
    rp: 0,
    tp: 0,
    cv: 0,
    ra: 0,
    bp: 0,
    cd: 0,
    ep: 0,
  };
  let voucher_payment = [];
  _.forEach(orders.itemKeys, (order_key) => {
    const {
      promotions,
      collect_point,
      total_delivery: delivery,
      items,
      voucher,
      discount,
      partner_type,
    } = orders.itemByKey[order_key];
    const is_vip_member = partner_type?.toLowerCase() === "member";
    let total = 0;
    let delivery_discount_total = 0;
    let order_discount_total = 0;
    let order_voucher = 0;
    let category_parent_total = {};
    let product_total = {};
    let product_temp = [];
    let voucher_list_order = [];
    let point = {
      pv: 0,
      rp: 0,
      tp: 0,
      cv: 0,
      ra: 0,
      bp: 0,
      cd: 0,
      ep: 0,
    };
    // item
    _.forEach(items.itemKeys, (item_key) => {
      const {
        unit_price,
        qty,
        pv,
        rp,
        tp,
        cv,
        ra,
        bp,
        cd,
        ep,
        category_parent,
        product_id,
      } = orders.itemByKey[order_key].items.itemByKey[item_key];
      if (collect_point) {
        if (!is_vip_member) {
          point_total.pv += pv * qty;
          point_total.tp += tp * qty;
          point_total.cv += cv * qty;
          point_total.ra += ra * qty;
          point_total.bp += bp * qty;
          point_total.cd += cd * qty;
          point_total.ep += ep * qty;
          point.pv += pv * qty;
          point.tp += tp * qty;
          point.cv += cv * qty;
          point.ra += ra * qty;
          point.bp += bp * qty;
          point.cd += cd * qty;
          point.ep += ep * qty;
        }
        point_total.rp += rp * qty;
        point.rp += rp * qty;
      }
      total += unit_price * qty;
      product_total[product_id] = unit_price * qty;
      product_temp.push({
        ...orders.itemByKey[order_key].items.itemByKey[item_key],
        balance: unit_price * qty,
      });
      _.forEach(category_parent, (value, key) => {
        if (!category_parent_total[value]) {
          category_parent_total[value] = 0;
        }
        category_parent_total[value] += unit_price * qty;
      });
    });

    //Voucher ที่ใช้ได้ในสินค้านั้น
    _.forEach(product_temp,(value) =>{
      value.voucher_in_product =[]
      _.forEach(voucher.itemKeys, (voucher_key) => {
        const item_voucher = voucher.itemByKey[voucher_key];
        if(item_voucher?.custom != null){
         const  find_product = !!_.find(item_voucher?.custom, {
            ref_id: value.product_id,
            ref_type:'product',
          });
          const find_category = _.some(item_voucher?.custom, (category) => {
            return _.includes(value.category_parent, category.ref_id);
          })
          if(find_product || find_category ){
            value.voucher_in_product = [...value.voucher_in_product,item_voucher]
          }
        }
        else{
          value.voucher_in_product = [...value.voucher_in_product,item_voucher]
        }
      })
    })
    //discount
    _.forEach(discount.itemKeys, (discount_key) => {
      if (discount.itemByKey[discount_key].discount_type === fixDisCount.delivery) {
        if (discount.itemByKey[discount_key].discount_amount > delivery) {
          delivery_discount_total += delivery;
        } else {
          delivery_discount_total +=
            discount.itemByKey[discount_key].discount_amount;
        }
      } else {
        order_discount_total +=
          orders.itemByKey[order_key].discount.itemByKey[discount_key]
            .discount_amount;
      }
    });

    // map all promotion
    let free_voucher = [];
    _.forEach(promotions.promotion_list, (item) => {
      const { promotion_item_type: target } = item;
      if (target === "item") {
        const { unit_price, qty, pv, rp, tp, cv, ra, bp, cd,ep } = item;
        if (collect_point) {
          if (!is_vip_member) {
            point_total.pv += pv * qty;
            point_total.tp += tp * qty;
            point_total.cv += cv * qty;
            point_total.ra += ra * qty;
            point_total.bp += bp * qty;
            point_total.cd += cd * qty;
            point_total.ep += ep * qty;
            point.pv += pv * qty;
            point.tp += tp * qty;
            point.cv += cv * qty;
            point.ra += ra * qty;
            point.bp += bp * qty;
            point.cd += cd * qty;
            point.ep += ep * qty;
          }
          point_total.rp += rp * qty;
          point.rp += rp * qty;
        }
        total += unit_price * qty;
      } else if (target === "voucher" && item.keep === false) {
        free_voucher = [...free_voucher, item];
      } else if (target === "amount") {
        if (_.includes(["price", "percent"], item.source_key)) {
          order_discount_total += item.price * item.qty;
        } else {
          point_total[item.source_key] += item[item.source_key] * item.qty;
          point[item.source_key] += item[item.source_key] * item.qty;
        }
      }
    });
    const order_total = _.max([0, total - order_discount_total]);

    // declare temp for calculate discount from  voucher
    let order_total_temp = order_total;
    // let delivery_temp = delivery-_.max([0, total - order_discount_total]);;
    let delivery_temp = _.max([0, delivery - delivery_discount_total]);

    // voucher promotion
    _.forEach(free_voucher, (item) => {
      let amount = 0;
      let pay_in_type_name = item.code;
      if (item.type === "3") {
        pay_in_type_name += " บัตรกำนัลส่วนลดค่าจัดส่ง";
        const voucher_amount = item.value * item.qty;
        // delivery_discount_total += voucher_amount;
        delivery_discount_total +=
          delivery_temp > voucher_amount ? voucher_amount : delivery_temp;
        if (delivery_temp > 0) {
          if (delivery_temp - voucher_amount >= 0) {
            amount = voucher_amount;
            delivery_temp -= voucher_amount;
          } else {
            amount = delivery_temp;
            delivery_temp = 0;
          }
        }
      } else if (item.type === "2") {
        pay_in_type_name += " บัตรกำนัลส่วนลดเปอร์เซ็น";
        const voucher_amount = (item.value / 100) * order_total_temp * item.qty;
        // order_voucher_total += voucher_amount;
        if (order_total_temp > 0) {
          if (order_total_temp - voucher_amount >= 0) {
            amount = voucher_amount;
            order_total_temp -= voucher_amount;
          } else {
            amount = voucher_amount;
            order_total_temp = 0;
          }
        }
      } else {
        pay_in_type_name += " บัตรกำนัลส่วนลดเงินสด";
        const voucher_amount = item.value * item.qty;
        // order_voucher_total += voucher_amount;
        if (order_total_temp > 0) {
          if (order_total_temp - voucher_amount >= 0) {
            amount = voucher_amount;
            order_total_temp -= voucher_amount;
          } else {
            amount = voucher_amount;
            order_total_temp = 0;
          }
        }
      }
      voucher_total += amount;
      voucher_payment = [
        ...voucher_payment,
        {
          promotion_id: item.promotion_id,
          source_option: item.source_option,
          ref_code: item.code,
          amount,
          pay_in_type: "Voucher",
          pay_in_type_name,
        },
      ];
    });

    // voucher
    // console.log(delivery_temp)
    _.forEach(voucher.itemKeys, (voucher_key) => {
      const item = voucher.itemByKey[voucher_key];
      const filter_category = _.filter(
        item.custom,
        (n) => n?.ref_type === "category"
      );
      const filter_products = _.filter(
        item.custom,
        (n) => n?.ref_type === "product"
      );
      let amount = 0;
      if (
        (order_total_temp <= 0 && item.type !== "3") ||
        (delivery_temp <= 0 && item.type === "3")
      )
        return; //กรณีที่ค่าจัดส่ง น้อยกว่า0 และราคาน้อยกว่า0 จะไม่บันทึกว่า Voucher
      if (item.type === "3") {
        // delivery voucher
        const voucher_amount = item.value;
        delivery_discount_total +=
          delivery_temp > voucher_amount ? voucher_amount : delivery_temp;
        if (delivery_temp > 0) {
          if (delivery_temp - voucher_amount >= 0) {
            amount = voucher_amount;
            delivery_temp -= voucher_amount;
          } else {
            amount = delivery_temp;
            delivery_temp = 0;
          }
        }
      } else if (item.type === "2") {
        // percent voucher (total | order_total_temp)
        const voucher_amount = (item.value / 100) * order_total_temp;
        if (order_total_temp > 0) {
          if (order_total_temp - voucher_amount >= 0) {
            amount = voucher_amount;
            order_total_temp -= voucher_amount;
          } else {
            amount = voucher_amount;
            order_total_temp = 0;
          }
        }
      } else {
        // amount voucher
        let voucher_amount = item.value;
        if (order_total_temp > 0) {
          if (voucher_amount > 0) {
            _.forEach(_.orderBy(product_temp, [product => product.voucher_in_product.length], ['asc']), (product) => {
              const price = product.balance; // ราคาสินค้าที่ใช้หักส่วนลด
              const amountDiscount =
                voucher_amount > price ? price : voucher_amount; // ราคาบัตรกำบัลที่ใช้
              if (item.custom != null) {
                const find_product = !!_.find(filter_products, {
                  ref_id: product.product_id,
                });
                const find_category = _.some(filter_category, (category) => {
                  return _.includes(product.category_parent, category.ref_id);
                });
                const can_use = find_product || find_category;
                if (can_use) {
                  if (order_total_temp - amountDiscount >= 0) {
                    voucher_amount = voucher_amount - amountDiscount;
                    product.balance = product.balance - amountDiscount;
                    amount += amountDiscount;
                    order_total_temp -= amountDiscount;
                  } else {
                    // กรณีที่จำนวนที่จ่ายน้อยกว่าจำนวนส่วนลดที่ใช้
                    voucher_amount = voucher_amount - amountDiscount;
                    product.balance = product.balance - amountDiscount;
                    amount += order_total_temp; // หักส่วนจำนวนเงินคงเหลือ
                    order_total_temp = 0;
                  }
                }
              } else {
                if (order_total_temp - amountDiscount >= 0) {
                  voucher_amount = voucher_amount - amountDiscount;
                  product.balance = product.balance - amountDiscount;
                  amount += amountDiscount;
                  order_total_temp -= amountDiscount;
                } else {
                  amount += order_total_temp; // หักส่วนจำนวนเงินคงเหลือ
                  order_total_temp = 0;
                  voucher_amount = voucher_amount - amountDiscount;
                  product.balance = product.balance - amountDiscount;
                }
              }
            });
          }
        }
        //     if (order_total_temp - voucher_amount >= 0) {
        //       amount = voucher_amount;
        //       // order_voucher += voucher_amount;
        //       order_total_temp -= voucher_amount;
        //     } else {
        //       // order_voucher = order_total_temp;
        //       amount = order_total_temp;
        //       order_total_temp = 0;
        //     }
        //   }
      }
      if (amount != 0) { // กรณีที่ราคา Voucher =0 จะบันทึกว่าเป็นการไม่ใช้งาน
        voucher_payment = [
          ...voucher_payment,
          {
            id: item.id,
            ref_code: item.code,
            amount,
            pay_in_type: item.voucher_type === "voucher" ? "Voucher" : "Coupon",
            pay_in_type_name: item.code + " บัตรกำนัล" + item.type_text,
          },
        ];
        voucher_list_order = [...voucher_payment];
      }

      if (item.voucher_type === "voucher") {
        voucher_total += amount;
        order_voucher += amount;
      } else if (item.type !== "3") {
        order_discount_total += amount;
      }
    });
    const delivery_total = delivery || 0;
    delivery_discount_total =
      delivery_discount_total > delivery_total
        ? delivery_total
        : delivery_discount_total;
    // console.log(total,order_discount_total,delivery_total,delivery_discount_total,voucher_delivery)
    // console.log(total,order_discount_total,delivery_discount_total,delivery_total)
    // summary
    const summary_order_total =
      // _.max([0, total - order_discount_total-delivery_discount_total]) + delivery_total; //order_total
      total + delivery_total - order_discount_total - delivery_discount_total;
    summary_total += summary_order_total;
    summary_list = [
      ...summary_list,
      {
        key: order_key,
        delivery,
        total,
        voucher: voucher_total, // summary_list total voucher
        voucher_list: voucher_payment,
        voucher_list_order: voucher_list_order,
        order_voucher_total: order_voucher, // total voucher order,
        discount: order_discount_total + delivery_discount_total,
        point,
        summary_total: summary_order_total,
      },
    ];
  });

  // calculate payment
  if (payment) {
    _.forEach(payment.itemKeys, (payment_key) => {
      const { pay_in_type, amount } = payment.itemByKey[payment_key];
      payment_total += +amount;
      cash_total += pay_in_type.toLowerCase() === "cash" ? +amount : 0;
    });
  }
  return {
    summary_list,
    summary_total,
    payment_total,
    cash_total,
    voucher_total,
    point_total,
    voucher_payment,
  };
}
