const newsConstants = {
  category: {
    width: 300,
    height: 300,
  },
  newsThumbnail: {
    width: 900,
    height: 900,
  },
  newsContentImage: {
    width: 900,
    height: 900,
  },
  tableSize: 10,
};

export default newsConstants;
