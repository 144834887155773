import { Alert, Select } from "antd";
import React, { useEffect } from "react";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";

// For discountType in Voucher
const { Option } = Select;
const SelectVoucher = ({
  callBackData,
  voucher_type = "",
  ...props
}) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/type`,
      params: {
        process_type: "voucher_type",
      },
    },
    []
  );

  useEffect(() => {
    data && callBackData && callBackData(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Alert type="error" message={error} />;

  return (
    <Select loading={loading} {...props}>
      {data?.data.map(
        (n) =>
          n.code !== "2" &&
          n.code !== (voucher_type === "voucher" ? "3" : "") && ( // n.code === '2' for use in phase 2
            <Option key={n.code} value={n.code}>
              {n.name}
            </Option>
          )
      )}
    </Select>
  );
};

export default React.memo(SelectVoucher);
