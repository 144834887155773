/* eslint-disable no-unused-vars */
import { Col, Row, Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import numeral from "numeral";
import React, { useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [productGroupFilter, setProductGroupFilter] = useState("");
  const [partnerGroupFilter, setPartnerGroupFilter] = useState("");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-partner-month`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        productTypeFilter: productTypeFilter,
        productGroupFilter: productGroupFilter,
        partnerGroupFilter: partnerGroupFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const productTypeOption = [
    { value: "Single", text: "สินค้าเดี่ยว" },
    { value: "BOM", text: "สินค้าชุด" },
  ];

  const productGroupOption = [
    { value: "sale", text: "ขาย" },
    { value: "redeem", text: "แลก" },
  ];

  const partnerGroupOption = [
    { value: "Givers", text: "Givers" },
    { value: "Zeeds", text: "Zeeds" },
    { value: "Together", text: "Together" },
    { value: "Ever", text: "Ever" },
    { value: "Life", text: "Life" },
    { value: "Victor", text: "Victor" },
    { value: "Clover Think", text: "Clover Think" },
    { value: "Real", text: "Real" },
    { value: "Dreams", text: "Dreams" },
    { value: "Mind", text: "Mind" },
    { value: "Clover", text: "Clover" },
  ];

  const handleProductTypeChange = (e) => {
    setProductTypeFilter(e);
  };

  const handleProductGroupChange = (e) => {
    setProductGroupFilter(e);
  };

  const HandlePartnerGroupChange = (e) => {
    setPartnerGroupFilter(e);
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสรุปยอดขายตามกลุ่มนักธุรกิจ (ตามเดือน)</PanelHeader>
        <PanelBody>
          <FilterList
            className="p-b-20"
            //   exportToExcel={exportToExcel}
            placeholder="ประเภทการซื้อ"
            reload={reload}
            showSearchBox={false}
            showBranch={true}
            Topic="วันที่เปิดบิล"
            withAll={user.branch_count > 1}
          />
          <Row
            gutter={3}
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} className="p-b-10">
              <Text strong>เลือกประเภทสินค้า</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                className="w-50"
                withAll
                options={productTypeOption}
                defaultValue={""}
                onChange={handleProductTypeChange}
              />
            </Col>

            <Col span={2}>
              <Text strong>เลือกหมวดสินค้า</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                className="w-50"
                withAll
                options={productGroupOption}
                disabled
                defaultValue={""}
                onChange={handleProductGroupChange}
              />
            </Col>

            <Col span={2}>
              <Text strong>เลือกกลุ่มนักธุรกิจ</Text>
            </Col>
            <Col span={4}>
              <SelectTeam
                className="w-50"
                withAll
                options={partnerGroupOption}
                defaultValue={""}
                onChange={HandlePartnerGroupChange}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data ? data.data : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data.data.length : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="กลุ่มนักธุรกิจ"
              dataIndex="team_name"
              align="left"
            />

            <Table.ColumnGroup
              title={`ม.ค. ${moment(state.filter.date_from)
                .add(543, "year")
                .format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="jan_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="jan_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="jan_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`ก.พ. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="feb_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="feb_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="feb_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`มี.ค. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="mar_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="mar_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="mar_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`เม.ย. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="apr_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="apr_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="apr_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`พ.ค. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="may_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="may_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="may_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`มิ.ย. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="jun_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="jun_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="jun_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`ก.ค. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="jul_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="jul_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="jul_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`ส.ค. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="aug_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="aug_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="aug_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`ก.ย. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="sep_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="sep_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="sep_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`ต.ค. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="oct_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="oct_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="oct_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`พ.ย. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="nov_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="nov_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="nov_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>

            <Table.ColumnGroup
              title={`ธ.ค. ${moment().add(543, "year").format("YY", "LLLL")}`}
            >
              <Table.Column
                title="Mini Topping"
                dataIndex="dec_mini_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Reborn"
                dataIndex="dec_reborn"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
              <Table.Column
                title="Topping"
                dataIndex="dec_topping"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
                align="left"
              />
            </Table.ColumnGroup>
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
