import React from "react";
import { Select } from "antd";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";

const SelectPaymentType = ({ className = "", ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/payin`,
    },
    []
  );

  if (error) return <div>{error}</div>;
  return (
    <Select
      className={`w-100 ${className}`}
      dropdownMatchSelectWidth={false}
      loading={loading}
      {...props}
    >
      {data && data.data
        ? data.data.map((n, i) => (
            <Select.Option key={i} value={n.code}>
              {n.name}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

export default SelectPaymentType;
