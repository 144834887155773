import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const fitQuizServices = {
  useQueryGetLegacyQuizCategoryLists({ take, page }, onSuccess) {
    return useQuery(
      ['get-legacy-quiz-category', { take, page }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-quiz-category`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyQuizCategoryById({ quizCategoryId }, onSuccess) {
    return useQuery(
      ['get-legacy-quiz-category-by-id', { quizCategoryId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-category/${queryKey[1].quizCategoryId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!quizCategoryId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyQuizCategory(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-quiz-category`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizCategory(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-category/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizCategoryDisplay(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-category/${data.id}/display`,
            {
              display: data.display,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyQuizCategory(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-quiz-category/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyQuizRewardLists({ take, page, onlyActive }, onSuccess) {
    return useQuery(
      ['get-legacy-quiz-reward', { take, page, onlyActive }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-quiz-reward`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              onlyActive: queryKey[1].onlyActive,
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyQuizRewardById({ quizRewardId }, onSuccess) {
    return useQuery(
      ['get-legacy-quiz-reward-by-id', { quizRewardId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-reward/${queryKey[1].quizRewardId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!quizRewardId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useQueryGetLegacyQuizUserRewards(
    { quizRewardId, memberCode, memberType, isPartner, isMax, page, take },
    onSuccess
  ) {
    return useQuery(
      [
        'get-legacy-quiz-user-rewards',
        { quizRewardId, memberCode, memberType, isPartner, isMax, page, take },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-quiz-user-reward`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              rewardId: queryKey[1].quizRewardId,
              ...(queryKey[1].memberType && {
                memberType: queryKey[1].memberType,
              }),
              ...(queryKey[1].memberCode && {
                memberCode: queryKey[1].memberCode,
              }),
              ...(typeof queryKey[1].isMax === 'boolean' && {
                isMax: queryKey[1].isMax,
              }),
              ...(typeof queryKey[1].isPartner === 'boolean' && {
                isPartner: queryKey[1].isPartner,
              }),
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!quizRewardId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationDownloadLegacyQuizUserRewardListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async (data) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-user-reward/download-excel/by-filter`,
            {
              params: {
                page: data.page,
                take: data.take,
                rewardId: data.quizRewardId,
                ...(data.memberType && {
                  memberType: data.memberType,
                }),
                ...(data.memberCode && {
                  memberCode: data.memberCode,
                }),
                ...(typeof data.isMax === 'boolean' && {
                  isMax: data.isMax,
                }),
                ...(typeof data.isPartner === 'boolean' && {
                  isPartner: data.isPartner,
                }),
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'user-reward-list.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
  useMutationCreateLegacyQuizReward(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(`/legacy-quiz-reward`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizReward(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-reward/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizRewardDisplay(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-reward/${data.id}/display`,
            {
              display: data.display,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyQuizReward(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-quiz-reward/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyQuizCategoryProductLists(
    { take, page, cageoryId },
    onSuccess
  ) {
    return useQuery(
      ['get-legacy-quiz-category-product', { take, page, cageoryId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-category-product`,
            {
              params: {
                take: queryKey[1].take,
                page: queryKey[1].page,
                cageoryId: queryKey[1].cageoryId,
              },
            }
          );

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyQuizCategoryProductById(
    { quizCategoryProductId },
    onSuccess
  ) {
    return useQuery(
      ['get-legacy-quiz-category-product-by-id', { quizCategoryProductId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-category-product/${queryKey[1].quizCategoryProductId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!quizCategoryProductId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyQuizCategoryProduct(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-quiz-category-product`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizCategoryProduct(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-category-product/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizCategoryProductDisplay(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-category-product/${data.id}/display`,
            {
              display: data.display,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyQuizCategoryProduct(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-quiz-category-product/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyQuizCategoryProductQuestionLists(
    { take, page, productId },
    onSuccess
  ) {
    return useQuery(
      ['get-legacy-quiz-category-product-question', { take, page, productId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-category-product-question`,
            {
              params: {
                take: queryKey[1].take,
                page: queryKey[1].page,
                productId: queryKey[1].productId,
              },
            }
          );

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyQuizCategoryProductQuestionById(
    { quizCategoryProductQuestionId },
    onSuccess
  ) {
    return useQuery(
      [
        'get-legacy-quiz-category-product-question-by-id',
        { quizCategoryProductQuestionId },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-category-product-question/${queryKey[1].quizCategoryProductQuestionId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!quizCategoryProductQuestionId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyQuizCategoryProductQuestion(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-quiz-category-product-question`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizCategoryProductQuestion(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-category-product-question/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyQuizCategoryProductQuestionActive(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-quiz-category-product-question/${data.id}/active`,
            {
              active: data.active,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyQuizCategoryProductQuestion(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-quiz-category-product-question/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDownloadLegacyQuizCategoryProductQuestionListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async (data) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-category-product-question/download-excel/by-filter`,
            {
              params: {
                page: data.page,
                take: data.take,
                productId: data.productId,
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'question-list.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
  useQueryGetLegacyQuizUsers(
    {
      memberCode,
      memberType,
      isPartner,
      page,
      take,
      selectedDate,
      maxReceived,
    },
    onSuccess
  ) {
    return useQuery(
      [
        'get-legacy-quiz-users',
        {
          memberCode,
          memberType,
          isPartner,
          page,
          take,
          selectedDate,
          maxReceived,
        },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-quiz-user`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              selectedDate: queryKey[1].selectedDate,
              maxReceived: queryKey[1].maxReceived,
              ...(queryKey[1].memberType && {
                memberType: queryKey[1].memberType,
              }),
              ...(queryKey[1].memberCode && {
                memberCode: queryKey[1].memberCode,
              }),
              ...(typeof queryKey[1].isMax === 'boolean' && {
                isMax: queryKey[1].isMax,
              }),
              ...(typeof queryKey[1].isPartner === 'boolean' && {
                isPartner: queryKey[1].isPartner,
              }),
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationDownloadLegacyQuizUserListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async (data) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-quiz-user/download-excel/by-filter`,
            {
              params: {
                page: data.page,
                take: data.take,
                selectedDate: data.selectedDate,
                maxReceived: data.maxReceived,
                ...(data.memberType && {
                  memberType: data.memberType,
                }),
                ...(data.memberCode && {
                  memberCode: data.memberCode,
                }),
                ...(typeof data.isPartner === 'boolean' && {
                  isPartner: data.isPartner,
                }),
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'user-list.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
};

export default fitQuizServices;
