/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Row,
  Table,
  Switch,
  Select,
  Modal,
  List,
  Icon,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../hooks/http2";
import ReactDragListView from "react-drag-listview";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../../redux/slices/marketing/product";
import { Link } from "react-router-dom";
import { FaPlus, FaPencilAlt } from "react-icons/fa";
import useAsync from "../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../services/manage-website.service";

export default function NewsPinList({ history, match }) {
  const [filterCategory, setFilterCategory] = useState("ทั้งหมด");
  const [seq, setSeq] = useState([]);
  const [data, setData] = useState([]);
  const headData = [
    {
      no: "No.",
      title: "Title",
      category: "Category",
      tag: "Tag",
      pin: "Pin Status",
      update: "Update Date",
    },
  ];

  const { execute: updateNewsPinListSeqById } = useAsync(
    manageWebsiteService.updateNewsPinListSeqById,
    {
      onSuccess: async () => {
        await loadContent();
      },
    }
  );

  const onDragEnd = async (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    await setData(items);
    for (let i = 0; i < items.length; i++) {
      let x = i + 1;
      let myId = _.toNumber(items[i].id);
      if (items[i].row_id != x) {
        updateNewsPinListSeqById({ myId, x });
      }
    }
    setTimeout(() => {
      message.success({ content: "Saved!", duration: 0.45 });
    }, 180);
  };

  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;

  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/news/pin-list",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
        filter: filterCategory,
      },
      token: true,
    },
    [search, filterCategory]
  );

  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/news/news/${id}/pin-status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        target_tab: checked ? "pin" : "unpin",
      },
    })
      .then(() => {
        Modal.success({
          title: "UNPIN SUCCESS !",
          content: "pin status : UNPIN ",
          okText: "ปิด",
          onOk() {
            history.go(0);
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/news/pin-list",
    })
      .then((res) => {
        console.log(res);
        let loadData = res.data?.data;
        console.log(loadData);
        setData(loadData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <Panel>
        <PanelHeader>Pin List</PanelHeader>
        <PanelBody>
          <Row>
            <List
              size="small"
              bordered
              dataSource={headData}
              renderItem={(item) => {
                const draggble = item.title !== "XXX";
                return (
                  <List.Item className={draggble ? "draggble" : ""}>
                    <List.Item.Meta
                      title={item.no}
                      style={{ maxWidth: "80px" }}
                    />
                    <List.Item.Meta title={item.title} />
                    <List.Item.Meta title={item.category} />
                    <List.Item.Meta title={item.tag} />
                    <List.Item.Meta title={item.pin} />
                    <List.Item.Meta title={item.update} />
                  </List.Item>
                );
              }}
            ></List>
          </Row>
          <Row>
            <ReactDragListView
              nodeSelector=".ant-list-item.draggble"
              onDragEnd={onDragEnd}
            >
              <List
                style={{ cursor: "grab" }}
                size="small"
                bordered
                dataSource={data}
                pagination={{
                  size: "default",
                  showLessItems: true,
                  showTotal: (total, range) =>
                    `${range[0]} - ${range[1]} of ${total}`,
                  total: list?.fetch?.total || 0,
                  current: search.page,
                  pageSize: search.limit,
                  onChange: (page) => dispatch(SET_SEARCH({ page: page })),
                }}
                renderItem={(item) => {
                  const draggble = item.title !== "XXX";
                  return (
                    <List.Item
                      // actions={["x"]}
                      className={draggble ? "draggble" : ""}
                    >
                      <List.Item.Meta
                        title={item.row_id}
                        style={{ maxWidth: "80px" }}
                        // description={<TestModal />}
                      />
                      <List.Item.Meta title={item.title} />
                      <List.Item.Meta title={item.content_type} />
                      <List.Item.Meta title={item.target_link} />
                      <List.Item.Meta
                        title={
                          <Switch
                            defaultChecked={item.target_tab === "pin"}
                            onClick={(checked) =>
                              handleChangeStatus(checked, item.id)
                            }
                          />
                        }
                      />
                      <List.Item.Meta
                        title={moment(item.update_date).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      />
                    </List.Item>
                  );
                }}
              />
            </ReactDragListView>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
