import { Col, Empty, Row, Typography, Button } from "antd";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import AddVoucherModal from "./AddVoucherModal";
import VoucherTable from "./VoucherTable";

const { setGlobalState } = actionClaim;

const Index = () => {
  const dispatch = useDispatch();
  const isEmpty = useSelector(
    (state) => state.claim.claimOutVoucher.itemKeys.length === 0
  );
  const isWaitManager = useSelector(
    (state) => state.claim.documentDetail.status === "wait-manager"
  );
  const disabled = useSelector(
    (state) =>
      state.claim.isPreview ||
      state.claim.documentDetail.status !== "wait-manager"
  );

  return (
    <>
      <Row className="bg-gray-60">
        <Col
          span={24}
          className="d-flex justify-content-between align-items-center px-2 py-2"
        >
          <Typography.Text
            strong
            style={{ fontSize: "1.5em" }}
            className="text-white"
          >
            บัตรกำนัลที่ได้รับ
          </Typography.Text>
          {!disabled && isWaitManager && (
            <>
              <Button
                type="primary"
                className="width-150"
                onClick={() =>
                  dispatch(
                    setGlobalState({
                      name: "addVoucherModal",
                      value: { visible: true },
                    })
                  )
                }
              >
                <FaPlus className="mr-2" />
                เพิ่มบัตรกำนัล
              </Button>
              <AddVoucherModal />
            </>
          )}
        </Col>
      </Row>
      <Row className="bg-gray-20 mb-3">
        <Col span={24} className="px-4 py-2">
          {isEmpty ? (
            <Empty className="py-3" />
          ) : (
            <VoucherTable disabled={disabled} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Index;
