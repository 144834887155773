/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, notification, Row, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHttp } from "../../../hooks/http";
import { axios, getToken, moment, URL_API } from "../../../utils";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import HeaderList from "./components/HeaderList";
import { FaCheck, FaEye, FaTimes } from "react-icons/fa";
import PreviewModal from "./components/PreviewModal";
import { resCatch } from "../../util/helper";

const getTextMenu = (processType) => {
  switch (processType) {
    case "inventory":
      return "รายการสินค้า";
    case "adjustment":
      return "ปรับปรุงยอดสินค้าคงคลัง";
    case "cash-refund":
      return "เคลมสินค้า-ยกเลิกใบเสร็จ";
    case "cash-sales":
      return "รายการขาย";
    case "commission":
      return "คอมมิชชั่น";
    case "credit-memo":
      return "ใบลดหนี้";
    case "item-fulfillment":
      return "เติมสินค้าที่จอง";
    case "item-receipt":
      return "การรับสินค้าจากการซื้อ";
    case "member":
      return "นักธุรกิจ";
    case "sales-order":
      return "การจอง";
    case "stock":
      return "สินค้าคงคลัง";
    case "transfer-in":
      return "การส่งสินค้าระหว่างสาขา";
    case "transfer-out":
      return "การส่งสินค้าจากคลังใหญ่ไปสาขา";
    case "inventory-adjustment":
      return "ใบเบิกสินค้า";
    case "cashback":
      return "รายได้ค้าปลีก";
    default:
      return "";
  }
};

const limit = 10;
const Index = ({ match }) => {
  const { process: processType } = match.params;
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("success");
  const [textMenu, setTextMenu] = useState("");
  const [loadTransfer, setLoadTransfer] = useState(false);
  const [preview, setPreview] = useState(null);
  const [loading, data, error, reload] = useHttp(
    {
      method: "get",
      url: `${URL_API}/interface/log/${processType}?result=${result}`,
      token: true,
      params: {
        limit,
        offset: (page - 1) * limit,
        query,
      },
    },
    [page, query, result]
  );

  useEffect(() => {
    if (processType !== "") setTextMenu(getTextMenu(processType));
  }, [processType]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      switch (name) {
        case "result":
          setResult(value);
          break;
        default:
          setQuery(value);
          break;
      }
      setPage(1);
    },
    [setResult, setQuery]
  );

  const handleTransfer = useCallback(
    (e, params) => {
      setLoadTransfer(true);
      axios({
        url: `${URL_API}/interface/${e}`,
        method: "post",
        headers: { Authorization: "Bearer " + getToken() },
        params,
      })
        .then(() =>
          notification.success({
            message: `Success`,
            description: textMenu,
          })
        )
        .catch((e) => resCatch(e))
        .finally(() => {
          reload();
          setLoadTransfer(false);
        });
    },
    [reload, textMenu]
  );

  if (error) return <Alert message={error} type="error" showIcon />;

  return (
    <Panel>
      <PanelHeader>{textMenu}</PanelHeader>
      <PanelBody>
        <LoadingFullScreen
          loading={loadTransfer}
          text={`[${textMenu}] Transfer Data...`}
        />
        <HeaderList
          handleChange={handleChange}
          handleTransfer={handleTransfer}
          processType={processType}
          result={result}
        />

        <Table
          bordered
          size="small"
          rowKey="row_id"
          loading={loading}
          dataSource={data?.data?.record || []}
          pagination={{
            current: page,
            pageSize: limit,
            total: data?.data?.total || 0,
            showLessItems: true,
            hideOnSinglePage: true,
            size: "default",
            className: "d-flex justify-content-center w-100",
            onChange: (currPage) => setPage(currPage),
          }}
        >
          <Table.Column
            title="วันที่และเวลา"
            key="create_date"
            dataIndex="create_date"
            align="center"
            width="10vw"
            render={(text) => moment(text).format("DD/MM/YY HH:mm")}
          />
          <Table.Column
            title={
              <div className="text-center" style={{ width: "40vw" }}>
                URL
              </div>
            }
            key="end_point"
            dataIndex="end_point"
            width="41vw"
          />
          <Table.Column
            title="จำนวนข้อมูล"
            key="total_record"
            dataIndex="total_record"
            align="center"
            width="9vw"
          />
          <Table.Column
            title="ผลลัพธ์"
            key="result"
            dataIndex="result"
            align="center"
            render={(text) => (
              <Row gutter={8} type="flex" className="align-items-center">
                <Col md={4} className="text-right">
                  <Button
                    shape="circle"
                    className={`${text === "success" ? "bg-pv" : "bg-reds"}`}
                    size="small"
                  >
                    {text === "success" ? (
                      <FaCheck className="text-white" />
                    ) : (
                      <FaTimes className="text-white" />
                    )}
                  </Button>
                </Col>
                <Col md={20} className="text-left">
                  <div className="ml-2">
                    {text === "success" ? "สำเร็จ" : "ผิดพลาด"}
                  </div>
                </Col>
              </Row>
            )}
          />
          <Table.Column
            title=""
            key="action"
            width="4vw"
            render={({ request_body, response_json, id }) => (
              <Row gutter={8} type="flex" className="align-items-center">
                <Col md={24} className="w-100 text-center">
                  <FaEye
                    className="cursor-pointer"
                    onClick={() =>
                      setPreview({ request_body, response_json, id })
                    }
                  />
                </Col>
              </Row>
            )}
          />
        </Table>
        <PreviewModal
          textMenu={textMenu}
          preview={preview}
          setPreview={setPreview}
        />
      </PanelBody>
    </Panel>
  );
};

export default Index;
