/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Card, Tabs } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";
import FormAddress from "../Form/FormAddress";
import TableDeliveryAddress from "../Table/TableDeliveryAddress";

function AddressTabs({ ...props }, ref) {
  const dispatch = useDispatch();
  const active = useSelector(
    (state) => state.ethics.form.active_tab.address,
    shallowEqual
  );
  const active_info = useSelector(
    (state) => state.ethics.form.active_tab.info,
    shallowEqual
  );
  const { married } = useSelector((state) => state.ethics.form, shallowEqual);
  const { OPEN_MODAL, SET_ACTIVE_TAB } = actionEthics;

  const idRef = useRef(null);
  const invRef = useRef(null);
  const currRef = useRef(null);
  const idmarriedRef = useRef(null);

  const marriedCode = married?.member_code || "";

  console.log(married);

  useEffect(() => {
    if (active_info == "married") {
      dispatch(SET_ACTIVE_TAB({ key: "address", value: "id_married" }));
    } else {
      dispatch(SET_ACTIVE_TAB({ key: "address", value: "id_card" }));
    }
  }, [active_info]);

  const submit = async (form) => {
    try {
      switch (form) {
        case "id_card":
          return (
            idRef.current && (await idRef.current.validateFieldsAndScroll())
          );
        case "invoice":
          return (
            invRef.current && (await invRef.current.validateFieldsAndScroll())
          );
        case "current":
          return (
            currRef.current && (await currRef.current.validateFieldsAndScroll())
          );
        case "id_married":
          return (
            idmarriedRef.current &&
            married &&
            (await idmarriedRef.current.validateFieldsAndScroll())
          );
        default:
          return false;
      }
    } catch (error) {
      return error;
    }
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const validate_id_card = await submit("id_card");
      const validate_invoice = await submit("invoice");
      const validate_current = await submit("current");
      const validate_married = await submit("id_married");

      return {
        id_card: validate_id_card,
        invoice: validate_invoice,
        current: validate_current,
        id_married: validate_married,
      };
    },
  }));

  return (
    <>
      <Card
        bordered={false}
        size="small"
        className="mb-3"
        style={{ boxShadow: "0px 2px 4px #ccc" }}
      >
        <Tabs
          size="small"
          activeKey={active}
          onChange={(e) =>
            dispatch(SET_ACTIVE_TAB({ key: "address", value: e }))
          }
          tabBarGutter={0}
          tabBarExtraContent={
            active_info !== "married" || !marriedCode ? (
              <Button
                size="small"
                type="link"
                onClick={() => dispatch(OPEN_MODAL({ name: "address" }))}
              >
                History
              </Button>
            ) : null
          }
        >
          {active_info == "member" && (
            <Tabs.TabPane tab="ที่อยู่ตามบัตรประชาชน" key="id_card">
              <FormAddress ref={idRef} address_type={1} />
            </Tabs.TabPane>
          )}

          {active_info == "married" && !marriedCode && (
            <Tabs.TabPane
              tab="ที่อยู่ตามบัตรประชาชน(ผู้สมัครร่วม)"
              key="id_married"
            >
              <FormAddress ref={idmarriedRef} address_type={5} />
            </Tabs.TabPane>
          )}

          {active_info == "member" && (
            <Tabs.TabPane tab="ที่อยู่สำหรับออกใบกำกับภาษี" key="invoice">
              <FormAddress ref={invRef} address_type={4} />
            </Tabs.TabPane>
          )}

          {active_info == "member" && (
            <Tabs.TabPane tab="ที่อยู่ปัจจุบัน" key="current">
              <FormAddress ref={currRef} address_type={2} />
            </Tabs.TabPane>
          )}

          {active_info == "member" && (
            <Tabs.TabPane tab="ที่อยู่จัดส่ง" key="delivery">
              <TableDeliveryAddress />
            </Tabs.TabPane>
          )}
        </Tabs>

        {/* {active_info == 'married' &&
          <Tabs
            size="small"
            activeKey={active}
            onChange={(e) => dispatch(SET_ACTIVE_TAB({ key: "address", value: e }))}
            tabBarGutter={0}
            tabBarExtraContent={
              <Button
                size="small"
                type="link"
                onClick={() => dispatch(OPEN_MODAL({ name: "address" }))}
              >
                History
              </Button>
            }
          >
            <Tabs.TabPane
              tab="ที่อยู่ผู้สมัครร่วม"
              key="married"
            //disabled={married?false:true}
            >
              <FormAddress forceRender={true} ref={marriedRef} address_type={5} />
            </Tabs.TabPane>

          </Tabs>
        } */}
      </Card>
    </>
  );
}

export default forwardRef(AddressTabs);
