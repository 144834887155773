/* eslint-disable no-unused-vars */
import { Button, Descriptions, Input, Modal } from "antd";
import { Space } from "antdV4";
import Search from "antdV4/lib/transfer/search";
import React, { useState, useEffect } from "react";
import { FaEdit, FaRegSave, FaExclamationTriangle } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../hooks/http2";
import { URL_API } from "../../../../../utils";
import { axios } from "../../../../../utils/useAxios";
import { actionEthics } from "../../../../../redux/slices/ethics";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
export default function SponsorPanel() {
  const history = useHistory();
  const sponsor = useSelector(
    (state) => state.ethics.form.sponsor,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { SET_SPONSOR, SET_FORM_MEMBER } = actionEthics;
  const match = useRouteMatch();

  const [isDisabled, setDisabled] = useState(true);
  const [inputError, setInputError] = useState(false);
  const [clearValue, setClearValue] = useState(false);

  const findSponsor = (sponsor_code) => {
    axios.get(`${URL_API}/ethics/sponsor?id=${sponsor_code}`).then((res) => {
      if (res.data !== "") {
        dispatch(
          SET_SPONSOR({
            sponsor_code: res.data.partner_code,
            sponsor_name: `${res.data.first_name_th} ${res.data.sur_name_th}`,
            sponsor_mobile: res.data.mobile,
          })
        );
        dispatch(
          SET_FORM_MEMBER({
            sponsor_code: res.data.partner_code,
            sponsor_name: `${res.data.first_name_th} ${res.data.sur_name_th}`,
            sponsor_mobile: res.data.mobile,
          })
        );
        setSponsorCodeInput(res.data.partner_code);
        setInputError(false);
      } else {
        Modal.error({
          title: "ผิดพลาด",
          content: "ไม่พบรหัสผู้แนะนำที่ค้นหา",
          okText: "ปิด",
        });
      }
    });
  };

  const handelEvent = async () => {
    if (sponsorCodeInput.trim() === "" || !sponsor?.sponsor_code) {
      setInputError(true);
      return;
    }

    Modal.confirm({
      title: "ยืนยันบันทึกข้อมูลผู้แนะนำของ VIP Member",
      content:
        'หลังจากกด "ยืนยัน" ระบบจะแจ้งเตือนให้นักธุรกิจผู้แนะนำทราบทันทีว่ามี VIP Member ใหม่',
      okText: "ยืนยัน",
      cancelText: "ปิด",
      onOk: async () => {
        try {
          const { id, type } = match.params;
          const partnerCode = sponsorCodeInput;
          const response = await axios.post(`/ethics/updatesponsor`, {
            partner_code: partnerCode,
            member_id: id,
          });
          if (response.data.error) {
            throw new Error(response.data.error);
          }

          Modal.success({
            title: "สำเร็จ",
            content: "บันทึกข้อมูลเรียบร้อย",
            okText: "ปิด",
            onOk() {
              history.goBack();
            },
          });
        } catch (error) {
          Modal.error({
            title: "ผิดพลาด",
            content: error.response?.data || error.message,
            okText: "ปิด",
          });
        }
      },
    });
  };

  const handleCancel = () => {
    if (!clearValue) {
      dispatch(
        SET_SPONSOR({
          sponsor_code: sponsor?.sponsor_code,
          sponsor_name: sponsor?.sponsor_name,
          sponsor_mobile: sponsor?.sponsor_mobile,
        })
      );
    } else {
      dispatch(
        SET_SPONSOR({
          sponsor_code: "",
          sponsor_name: "",
          sponsor_mobile: "",
        })
      );
      setSponsorCodeInput("");
    }
    setDisabled(true);
    setInputError(false);
  };
  const [sponsorCodeInput, setSponsorCodeInput] = useState(
    sponsor?.sponsor_code
  );
  useEffect(() => {
    if (sponsor) {
      setSponsorCodeInput(sponsor?.sponsor_code);
    }
  }, [sponsor]);
  return (
    <>
      <Descriptions size="small" bordered>
        <Descriptions.Item label="รหัสสมาชิก" span={3}>
          <div className="flex items-center">
            <Input.Search
              style={{ width: "50%" }}
              placeholder="รหัสสมาชิก"
              value={sponsorCodeInput}
              disabled={isDisabled}
              onChange={(e) => {
                setClearValue(true);
                setSponsorCodeInput(e.target.value);
                if (e.target.value.trim() === "") {
                  setInputError(true);
                } else {
                  setInputError(false);
                }
              }}
              onSearch={(e) => {
                if (e.trim() === "") {
                  setInputError(true);
                  return;
                }
                findSponsor(e);
              }}
            />
            {match.params.type === "member" && inputError && (
              <p className="ml-2 text-xs" style={{ color: "red" }}>
                กรุณากรอกรหัสสมาชิกผู้แนะนำหรือกดปุ่มค้นหา
              </p>
            )}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="ชื่อสมาชิก" span={2}>
          {sponsor?.sponsor_name === "" ? "-" : sponsor?.sponsor_name}
        </Descriptions.Item>
        <Descriptions.Item label="โทรศัพท์">
          {sponsor?.sponsor_mobile === "" ? "-" : sponsor?.sponsor_mobile}
        </Descriptions.Item>
        <Descriptions.Item>
          {match.params.type === "member" && isDisabled && (
            <div className="flex">
              <Button
                className="text-center mr-4"
                type="primary"
                onClick={() => {
                  setDisabled(!isDisabled);
                }}
              >
                <FaEdit className="text-center mr-1" /> แก้ไข
              </Button>
            </div>
          )}

          {match.params.type === "member" && !isDisabled && (
            <div className="flex">
              <Button
                className="text-center mr-4"
                type="primary"
                onClick={handelEvent}
              >
                <FaRegSave className="text-center mr-1" />
                บันทึกผู้แนะนำ
              </Button>

              <Button
                className="text-center"
                type="danger"
                onClick={handleCancel}
              >
                <div className="text-center mr-1">X ยกเลิก </div>
              </Button>
              <p className="mt-1 text-sm" style={{ color: "red" }}>
                <FaExclamationTriangle /> เมื่อกดปุ่ม "บันทึกผู้แนะนำ"
                ระบบจะแจ้งเตือนให้ผู้แนะนำทราบผ่าน Application ว่ามี VIP ใหม่
              </p>
            </div>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}
