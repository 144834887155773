/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Spin, Typography } from "antd";
import axios from "axios";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MEMBER_API } from "../../../../../utils";

function PanelOTP(
  {
    form: { getFieldDecorator, validateFields, resetFields },
    visible,
    onSuccess,
    mobile,
    email,
    duration,
  },
  ref
) {
  const inputRef = useRef(null);
  const [disableRequest, setDisableRequest] = useState(false);
  const [refKey, setRefKey] = useState("");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [currMobile, setCurrMobile] = useState("");

  useEffect(() => {
    currMobile && requestOtp();
  }, [currMobile]);

  useEffect(() => {
    visible && setCurrMobile(mobile);
  }, [visible, mobile]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      clearState();
    },
    request: () => {
      clearState();
      requestOtp();
    },
  }));

  function clearState() {
    setDisableRequest(false);
    setRefKey("");
    setSaving(false);
    setError("");
    setLoadingRequest(false);
    setCurrMobile("");
  }

  async function requestOtp() {
    setLoadingRequest(true);
    setError("");
    setRefKey("");
    resetFields();
    inputRef.current.focus();
    try {
      const { data } = await axios.post(`${MEMBER_API}/otp/send`, {
        mobile: mobile || "",
        email: email || "",
      });
      setDisableRequest(true);
      setRefKey(data.verify_key);
    } catch (error) {
      setDisableRequest(false);
      setError(error.response?.data.error_message || error.message);
    }
    setLoadingRequest(false);
  }

  function confirmOtp(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (err) return;
      setSaving(true);
      setError("");
      try {
        await axios.post(`${MEMBER_API}/otp/verify`, {
          verify_key: refKey,
          verify_value: values.code,
        });
        clearState();
        onSuccess();
      } catch (error) {
        setSaving(false);
        setError(error.response?.data.error_message || error.message);
      }
    });
  }

  return (
    <Spin spinning={loadingRequest}>
      <Form name="otp" onSubmit={confirmOtp}>
        <Row gutter={[8, 8]} className="mb-3">
          <Col
            span={24}
            className="text-center"
            style={{ borderBottom: "3px solid #5fbfec" }}
          >
            <Typography.Title strong level={4}>
              ยืนยันเบอร์มือถือผ่าน OTP
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[4, 4]}>
          <Col span={24} className="text-center">
            <Typography.Text className="mr-2">
              รหัส OTP ได้ส่งไปยังเบอร์มือถือ
            </Typography.Text>
            <Typography.Text strong>{mobile}</Typography.Text>
          </Col>
          <Col span={24} className="text-center">
            <Typography.Text>
              กรุณากรอก OTP ที่นักธุรกิจได้รับ (Ref : {refKey})
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={[4, 4]}>
          <Col span={24}>
            <Form.Item className="mb-0">
              {getFieldDecorator("code", {
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกรหัส OTP",
                  },
                  {
                    pattern: /^.{6,}$/,
                    message: "กรุณากรอกรหัส OTP ให้ครบถ้วน",
                  },
                ],
              })(<Input autoComplete="off" disabled={saving} ref={inputRef} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[4, 4]}>
          {error && (
            <Col span={24}>
              <Typography.Text className="text-nn my-1">
                {error}
              </Typography.Text>
            </Col>
          )}
          <Col span={24}>
            {disableRequest ? (
              <RetryCountdown
                minute={duration}
                onTimer={() => setDisableRequest(false)}
              />
            ) : (
              <Typography.Text
                underline
                className="text-tp cursor-pointer"
                onClick={requestOtp}
              >
                ส่งรหัสใหม่อีกครั้ง
              </Typography.Text>
            )}
          </Col>
        </Row>
        <Row gutter={[8, 8]} className="mt-3">
          <Col span={24} className="text-center">
            <Button
              htmlType="submit"
              disabled={!disableRequest}
              className={disableRequest && "bg-tp border-tp text-white"}
              loading={saving}
            >
              ยืนยัน OTP
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

function RetryCountdown({ minute, onTimer }) {
  const refTimer = useRef();
  const [timer, setTimer] = useState(-1);
  const [text, setText] = useState("");

  function decreaseTimer() {
    setTimer((value) => {
      return value - 1;
    });
  }

  function clearTimer() {
    if (refTimer.current) {
      clearInterval(refTimer.current);
      refTimer.current = null;
    }
  }

  useEffect(() => {
    setTimer(minute * 60);

    if (refTimer.current) {
      clearInterval(refTimer.current);
    }

    refTimer.current = setInterval(() => {
      decreaseTimer();
    }, 1000);

    return () => {
      clearTimer();
    };
  }, [minute]);

  useEffect(() => {
    if (timer >= 0) {
      var minutes, seconds;

      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setText(minutes + ":" + seconds);
    }

    if (timer == 0) {
      clearTimer();

      if (onTimer) {
        onTimer();
      }
    }
  }, [timer]);

  return <span>กรุณาลองใหม่อีกครั้งใน {text}</span>;
}

export default Form.create()(forwardRef(PanelOTP));
