/* eslint-disable no-unused-vars */
import { Button, Col, notification, Row, Table } from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx-js-style";
import numeral from "numeral";
import React, { useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const limit = 50;

  const [periodFilter, setPeriodFilter] = useState([]);
  const [periodSelected, setPeriodSelected] = useState("");
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/commission/commission-bonus`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        periodFilter: periodFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  let newData = data ? data : [];

  const periodFilterOptions = _.map(newData.periodData, (data) => ({
    value: data.period,
    text: data.period,
  }));

  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานโบนัสคอมมิชชั่น"],
        [
          `รอบคำนวณ : ${periodSelected}`,
          "",
          `วันที่ดึงข้อมูล : ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [""],
        [
          "NO.",
          "Batch ID",
          "รหัส",
          "ชื่อ-นามสกุล",
          "ชื่อบริษัท",
          "Type",
          "IsVAT",
          "ค่าแนะนำ",
          "บริหารทีม",
          "แมชชิ่ง",
          "โบนัสส่วนบุคคล",
          "พิเศษ",
          "ส่วนต่างเงินคืน",
          "รวมเงินไทย",
          "อัตราแลกเปลี่ยน",
          "รวมเงินต่างชาติ",
          "สัญชาติ",
          "Team",
          "สถานะ",
          "รอบ",
          "วันที่สมัคร",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.batch_id,
            n.partner_code,
            n.partner_name,
            n.company_name,
            n.legal_type,
            n.is_vat,
            {
              v:
                n.ref_amount === ""
                  ? 0
                  : numeral(n.ref_amount).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.manage_amount === ""
                  ? 0
                  : numeral(n.manage_amount).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.matching_amount === ""
                  ? 0
                  : numeral(n.matching_amount).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              // ไม่มีข้อมูล โบนัสส่วนบุคคล
              v: "-" === "" ? 0 : numeral(0).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.special_bonus === ""
                  ? 0
                  : numeral(n.special_bonus).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              // ไม่มีข้อมูล ส่วนต่างเงินคืน
              v: "-" === "" ? 0 : numeral(0).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v:
                n.ref_amount +
                  n.manage_amount +
                  n.matching_amount +
                  n.special_bonus ===
                ""
                  ? 0
                  : numeral(
                      n.ref_amount +
                        n.manage_amount +
                        n.matching_amount +
                        n.special_bonus
                    ).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              // ไม่มีข้อมูล อัตราแลกเปลี่ยน
              v: "-" === "" ? 0 : numeral(0).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              // ไม่มีข้อมูล รวมเงินต่างชาติ
              v: "-" === "" ? 0 : numeral(0).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            n.nationality,
            n.team_name === "" ? "-" : n.team_name,
            n.status,
            moment(n.period).format("DD/MM/YYYY"),
            moment(n.register_date).format("DD/MM/YYYY"),

            // {v: n.amount === "none" ? "" : numeral(n.amount).format("0,0.00"), s: { alignment: { horizontal: "right" } } },
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 4 } },
      ];

      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 20 },
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
      ];

      var wsrows = [
        { hpx: 18 }, // "pixels"
        { hpx: 18 }, // "pixels"
        { hpx: 18 }, // "pixels"
        { hpx: 24 }, // "pixels"
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      // const ws = XLSX.utils.json_to_sheet(excelData); //------old version

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;
      ws["I4"].s = centerStyles;
      ws["J4"].s = centerStyles;
      ws["K4"].s = centerStyles;
      ws["L4"].s = centerStyles;
      ws["M4"].s = centerStyles;
      ws["N4"].s = centerStyles;
      ws["O4"].s = centerStyles;
      ws["P4"].s = centerStyles;
      ws["Q4"].s = centerStyles;
      ws["R4"].s = centerStyles;
      ws["S4"].s = centerStyles;
      ws["T4"].s = centerStyles;
      ws["U4"].s = centerStyles;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานโบนัสคอมมิชชั่น");
      XLSX.writeFile(wb, "รายงานโบนัสคอมมิชชั่น.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
 

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานโบนัสคอมมิชชั่น</PanelHeader>

        <PanelBody>
          <Row align="middle" className="mb-2 mt-2">
            <Col span={1} className="pt-2">
              <Text strong>รอบคำนวณ :</Text>
            </Col>
            <Col span={6} className=" pl-3 p-r-10 p-b-15">
              <SelectOption
                className="w-50"
                placeholder="ทั้งหมด"
                withAll
                options={periodFilterOptions}
                onChange={(e) => {
                  setPeriodFilter(e);
                  setPeriodSelected(e === "" ? "ทั้งหมด" : e);
                }}
              />
            </Col>
            <Row gutter={8} type="flex" justify="end">
              <Col>
                <Button type="primary" onClick={reload}>
                  แสดงรายงาน
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="text-white d-flex align-items-center"
                  icon="file-excel"
                  onClick={exportToExcel}
                >
                  Export Excel
                </Button>
              </Col>
            </Row>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={newData.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              // total: newData.newData.length ,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="NO." dataIndex="row_id" align="center" />
            <Table.Column
              title="Batch ID"
              dataIndex="batch_id"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "-";
                }
                return text;
              }}
            />
            <Table.Column title="รหัส" dataIndex="partner_code" align="right" />
            <Table.Column
              title="ชื่อ-นามสกุล"
              dataIndex="partner_name"
              align="left"
            />
            <Table.Column
              title="ชื่อบริษัท"
              dataIndex="company_name"
              align="left"
            />
            <Table.Column
              title="Legal Type"
              dataIndex="legal_type"
              align="left"
            />
            <Table.Column title="IsVAT" dataIndex="is_vat" align="right" />
            <Table.Column
              title="ค่าแนะนำ"
              dataIndex="ref_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="บริหารทีม"
              dataIndex="manage_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="แมชชิ่ง"
              dataIndex="matching_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="โบนัสส่วนบุคคล"
              dataIndex=""
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="พิเศษ"
              dataIndex="special_bonus"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ส่วนต่างเงินคืน"
              dataIndex=""
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="รวมเงินไทย"
              dataIndex=""
              align="right"
              render={({
                ref_amount,
                manage_amount,
                matching_amount,
                special_bonus,
              }) => {
                let text =
                  ref_amount + manage_amount + matching_amount + special_bonus; // + personal_bonus + payback_dif
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="อัตราแลกเปลี่ยน"
              dataIndex=""
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="รวมเงินต่างชาติ"
              dataIndex=""
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "0";
                }
                return numeral(text).format("0,0.00");
              }}
            />

            {/* <Table.Column title="อัตราแลกเปลี่ยน" dataIndex="" align="right" render={(text) => {
                        if(text === ""){
                          text = "0"
                        }
                        return numeral(text).format('0,0.00');
                      }}/> */}
            {/* <Table.Column title="รวมเงินต่างชาติ" dataIndex="" align="right" render={(text) => {
                        if(text === ""){
                          text = "0"
                        }
                        return numeral(text).format('0,0.00');
                      }}/> */}
            <Table.Column
              title="สัญชาติ"
              dataIndex="nationality"
              align="right"
            />
            <Table.Column
              title="Team"
              dataIndex="team_name"
              align="right"
              render={(text) => {
                if (text === "") {
                  text = "-";
                }
                return text;
              }}
            />
            <Table.Column title="สถานะ" dataIndex="status" align="right" />
            <Table.Column title="รอบ" dataIndex="period" align="right" />
            <Table.Column
              title="วันที่สมัคร"
              dataIndex="register_date"
              align="right"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
