/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Row, Select, Switch } from "antd";
import React, { useContext } from "react";
import NormalProcess from "../components/Process/NormalProcess";
import StepProcess from "../components/Process/StepProcess";
import { PromotionContext } from "../reducers";

const Giveaway = ({ form }) => {
  const { getFieldDecorator } = form;
  const { state, dispatch } = useContext(PromotionContext);
  return (
    <>
      <Row gutter={16} className="mb-3 d-flex align-items-center">
        <Col span={24}>
          <label className="mb-0">เงื่อนไข</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("condition_type", {
              onChange: (value) =>
                dispatch({
                  type: "CHANGE_CONDITION_RECEIVE_TYPE",
                  payload: { type: "condition", value },
                }),
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกประเภทเงื่อนไขการซื้อ",
                },
              ],
            })(
              <Select className="width-200" placeholder="เลือกประเภท">
                <Select.Option value="item">เมื่อซื้อสินค้า</Select.Option>
                <Select.Option value="item-amount">
                  เมื่อซื้อสินค้าครบ
                </Select.Option>
                <Select.Option value="item-every-amount">
                  เมื่อซื้อสินค้าครบทุกๆ
                </Select.Option>
                {state.data.collect !== 1 && (
                  <Select.Option value="amount">เมื่อซื้อครบ</Select.Option>
                )}
                {state.data.collect !== 1 && state.data.is_step !== 1 && (
                  <Select.Option value="every-amount">
                    เมื่อซื้อครบทุกๆ
                  </Select.Option>
                )}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      {state.data.is_step === 1 && (
        <Row gutter={16} className="mb-3 d-flex align-items-center">
          <Col span={24}>
            <label className="mb-0">สิ่งที่ได้รับ</label>
            <Form.Item className="mb-0">
              {getFieldDecorator("receive_type", {
                onChange: (value) =>
                  dispatch({
                    type: "CHANGE_CONDITION_RECEIVE_TYPE",
                    payload: { type: "receive", value },
                  }),
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกประเภทสิ่งที่ได้รับ",
                  },
                ],
              })(
                <Select className="width-200" placeholder="เลือกประเภท">
                  <Select.Option value="item">สินค้า</Select.Option>
                  <Select.Option value="amount">คะแนน/ส่วนลด</Select.Option>
                  <Select.Option value="voucher">บัตรกำนัล</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}

      {state.data.is_step === 1 ? (
        <StepProcess form={form} />
      ) : (
        <NormalProcess form={form} />
      )}
    </>
  );
};

export default Giveaway;
