import { Badge, Descriptions } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { memberSelector } from "../../../../redux/slices/member";
import { setFormat } from "../../../../utils";

export default function MemberPanel() {
  const member = useSelector(memberSelector);
  return (
    <Descriptions size="small" bordered>
      <Descriptions.Item label="รหัสสมาชิก">
        {member.member_code}
      </Descriptions.Item>
      <Descriptions.Item label="วันที่สมัคร">
        {member.register_date &&
          moment(member.register_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="วันที่หมดอายุ">
        {member.expire_date && moment(member.expire_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="ชื่อ (ไทย)" span={3}>
        {member.id && member.first_name_th + " " + member.sur_name_th}
      </Descriptions.Item>
      <Descriptions.Item label="ชื่อ (อังกฤษ)" span={3}>
        {member.id && member.first_name_en + " " + member.sur_name_en}
      </Descriptions.Item>
      <Descriptions.Item label="วันเกิด">
        {member.birth_date && moment(member.birth_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="อายุ">
        {member.birth_date && moment().diff(member.birth_date, "years")}
      </Descriptions.Item>
      <Descriptions.Item label="เพศ">{member.gender_text}</Descriptions.Item>
      <Descriptions.Item label="สัญชาติ" span={3}>
        {member.nationality_text}
      </Descriptions.Item>
      <Descriptions.Item label="บัตรประชาชน" span={2}>
        {setFormat("id_card", member.id_card)}
      </Descriptions.Item>
      <Descriptions.Item label="วันหมดอายุ">
        {member.id_card_expire_date &&
          moment(member.id_card_expire_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="พาสปอร์ต" span={2}>
        {member.passport_id}
      </Descriptions.Item>
      <Descriptions.Item label="วันหมดอายุ">
        {member.passport_expire_date &&
          moment(member.passport_expire_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="โทรศัพท์">
        {setFormat("mobile", member.mobile)}
      </Descriptions.Item>
      <Descriptions.Item label="อีเมล" span={2}>
        {member.email}
      </Descriptions.Item>
      <Descriptions.Item label="Line ID">{member.line_id}</Descriptions.Item>
      <Descriptions.Item label="Facebook" span={2}>
        {member.facebook}
      </Descriptions.Item>
      <Descriptions.Item label="สถานะ" span={3}>
        <Badge status="success" text={member.status_text} />
      </Descriptions.Item>
      {(member.is_company || 0) === 1 && (
        <>
          <Descriptions.Item label="ประเภทธุรกิจ">
            {member.company_type_text}
          </Descriptions.Item>
          <Descriptions.Item label="เลขประจำตัวผู้เสียภาษี">
            {member.company_tax}
          </Descriptions.Item>
          <Descriptions.Item label="ภาษีมูลค่าเพิ่ม">
            {member.company_register_vat === 1 ? "มี" : "ไม่มี"}
          </Descriptions.Item>
          <Descriptions.Item label="ชื่อนิติบุคคล (ไทย)" span={3}>
            {member.company_name}
          </Descriptions.Item>
          <Descriptions.Item label="ชื่อนิติบุคคล (อังกฤษ)" span={3}>
            {member.company_name_en}
          </Descriptions.Item>
        </>
      )}
    </Descriptions>
  );
}
