import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const legacyNotificationFormDefaultValues = {
  title: '',
  description: '',
  sub_description: '',
  thumbnail_img_url: '',
  link: '',
  link_type: 0,
  notification_code: 'new-activity',
};

export const legacyNotificationLinkType = [
  {
    label: 'ข้อความ',
    value: 0,
  },
  {
    label: 'กิจกรรม',
    value: 1,
  },
  {
    label: 'เปิดลิงค์ URL',
    value: 2,
  },
];

export const legacyNotificationCode = [
  {
    label: 'แจ้งเตือนคำสั่งซื้อ',
    value: 'order',
  },
  {
    label: 'แจ้งเตือนข่าวสารโปรโมชัน',
    value: 'new-promotion',
  },
  {
    label: 'แจ้งเตือนข่าวสารกิจกรรม',
    value: 'new-activity',
  },
  {
    label: 'แจ้งเตือนต่ออายุสมาชิก/นักธุรกิจเลกาซี',
    value: 'renew',
  },
  {
    label: 'แจ้งเตือนรักษายอด',
    value: 'allocate',
  },
  {
    label: 'แจ้งเตือนนักธุรกิจใหม่ (สายเลือด)',
    value: 'new-child',
  },
  {
    label: 'แจ้งเตือนสมาชิกใหม่ (VIP MEMBER)',
    value: 'new-member',
  },
];

export const legacyNotificationFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      title: yup.string().required('กรุณากรอกข้อมูล'),
      description: yup.string().required('กรุณากรอกข้อมูล'),
      sub_description: yup.string().defined(),
      thumbnail_img_url: yup.string().defined(),
      link: yup.string().defined(),
      link_type: yup.number().required('กรุณาเลือก'),
      notification_code: yup
        .mixed()
        .oneOf(Object.values(legacyNotificationCode.map((item) => item.value)))
        .required('กรุณากรอกข้อมูล'),
    })
  );
};

export const legacyNotificationSendFormDefaultValues = {
  effective_date: moment(new Date()),
  record: undefined,
  file: undefined,
};

export const legacyNotificationSendFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      effective_date: yup.date().required(),
    })
  );
};

export const legacyNotificationPartnerFormDefaultValues = {
  title: '',
  partnerList: '',
  legacyNotificationId: '',
};

export const legacyNotificationPartnerListFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      title: yup.string().required('กรุณากรอกข้อมูล'),
      partnerList: yup.string().required('กรุณากรอกข้อมูล'),
      legacyNotificationId: yup.string().required('กรุณากรอกข้อมูล'),
    })
  );
};
