/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography,  } from 'antdV4';
import { Row, Col } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { classCategoryFormDefaultValues } from '../dto/class';
import classConstants from '../../constants/classConstants';
import ImageUpload from '../upload/ImageUpload';

const ClassCategoryForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();
  const { execute: getClassCategoryById } = useAsync(
    fitStudioService.getClassCategoryById,
    {
      onSuccess: (data) => {
        reset({
          ...data,
          formType: 'edit',
        });
      },
    }
  );

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getClassCategoryById(watch('id'));
    } else {
      reset(classCategoryFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={classConstants.category.width}
                  height={classConstants.category.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อหมวดหมู่ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default ClassCategoryForm;
