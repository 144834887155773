import React, { memo } from "react";
import { Input } from "antd";
import { FaPlus, FaMinus } from "react-icons/fa";
import { _ } from "../../../../../utils";

const InputNumberWithButton = ({ value = 0, onChange, min = 0, max = 0 }) => {
  const handleOnChange = (valueChange) => {
    let returnValue = valueChange;
    if (valueChange < min) {
      returnValue = min;
    } else if (valueChange > max) {
      returnValue = max;
    }
    onChange(returnValue);
  };
  return (
    <>
      <button className="btn-circle" onClick={() => handleOnChange(+value - 1)}>
        <FaMinus size={10} />
      </button>
      <Input
        size="small"
        className="text-center mx-1 width-50 font-size-12 px-0"
        value={value}
        // onSelect={(e) => e.target.select()}
        onChange={(e) =>
          handleOnChange(_.isNaN(+e.target.value) ? min : +e.target.value)
        }
      />
      <button className="btn-circle" onClick={() => handleOnChange(+value + 1)}>
        <FaPlus size={10} />
      </button>
    </>
  );
};

export default memo(InputNumberWithButton);
