/* eslint-disable no-unused-vars */
import {
  Button,
  Row,
  Col,
  notification,
  Typography,
  Table,
  Modal,
  Input,
} from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Panel, PanelHeader, PanelBody } from "../../util/panel";
import _ from "lodash";
import axios from "axios";
import { getToken, URL_API } from "../../../utils";
import { useHttp } from "../../../hooks/http";
import { Link, useRouteMatch } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { MdBookmarkBorder, MdBookmark } from "react-icons/md";
import parse from "html-react-parser";
import SelectOption from "../pos/components/SelectOption";
import "./New-list.css";

const { Text } = Typography;

const Index = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({ title: "", description: "" });
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [page, setPage] = useState(1);
  const limit = 20;

  const match = useRouteMatch();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/news/news/get-news`,
      token: true,
      params: { filterData: filterData, filterSearch: filterSearch },
      initialLoad: true,
    },
    [filterData]
  );

  // console.log(data ? data : []);
  useEffect(() => {
    reload();
    setPage(1);
  }, [filterSearch]);

  useEffect(() => {
    setDataFilter(
      _.filter(data && data.dataFilter, (data) => {
        if (match.path === "/news") {
          return !["legal", "announce"].includes(data.value);
        }
      })
    );
  }, [data, match.path]);

  const deleteNewsHandler = (id) => {
    try {
      setIsDeleting(true);
      axios({
        method: "post",
        url: `${URL_API}/news/news/news-delete`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          id: id,
        },
      });
      notification.success({
        message: "Success",
        description: "The data have been deleted",
        top: 60,
      });
      setTimeout(() => {
        reload();
        setIsDeleting(false);
      }, 500);
    } catch (e) {
      notification.warning({
        message: "Error",
        description: e.response
          ? e.response.data.message
          : "Cannot delete data",
        top: 60,
      });
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pinClickHandler = async (id, e) => {
    let swapPin;
    if (e === "0") {
      swapPin = "1";
    } else {
      swapPin = "0";
    }
    try {
      await axios({
        method: "post",
        url: `${URL_API}/news/news/news-pin`,
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          id: id,
          pin: swapPin,
        },
      });
      reload();
    } catch (e) {
      notification.warning({
        message: "Error",
        description: e.response ? e.response.data.message : "Cannot Pin News",
        top: 60,
      });
    }
  };

  const newsFilterOptions = [
    _.map(dataFilter, (data) => {
      return { value: data.value, text: data.text };
    }),
  ];

  return (
    <Panel>
      <PanelHeader>ข่าวสาร</PanelHeader>
      <PanelBody>
        <Row align="middle" gutter={24}>
          <Col span={6}>
            <SelectOption
              className="w-100"
              defaultValue={filterData}
              withAll
              options={newsFilterOptions[0]}
              placeholder={"ประเภทประกาศ"}
              onChange={(e) => {
                setFilterData(e);
              }}
            />
          </Col>
          <Col span={8}>
            <Input.Search
              className="w-100"
              placeholder="ค้นหา"
              allowClear
              onSearch={(text) => {
                setFilterSearch(text);
                //reload()
              }}
            />
          </Col>
          <Row type="flex" className="flex" justify="end">
            <Col className="pr-3">
              <Link to={`${match.path}/news-create`}>
                <Button type="primary">+ เพิ่มข่าวสาร</Button>
              </Link>
            </Col>
          </Row>
        </Row>

        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={data ? data.data : []}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          className="mt-3 table-text-top"
          pagination={{
            current: page,
            pageSize: limit,
            total: data ? data.data.length : 0,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showLessItems: true,
            size: "default",
            onChange: (currPage) => setPage(currPage),
          }}
        >
          <Table.Column title="#" dataIndex="row_id" align="center" />
          <Table.Column
            title="Pin"
            dataIndex="is_pin"
            align="center"
            render={(text, r, i) => {
              if (text === 1) {
                return (
                  <Button
                    type="link"
                    onClick={() => {
                      pinClickHandler(data.data[i].id, text);
                    }}
                  >
                    <MdBookmark size={35} />
                  </Button>
                );
              } else {
                text = "0";
                return (
                  <Button
                    type="link"
                    onClick={() => {
                      pinClickHandler(data.data[i].id, text);
                    }}
                  >
                    <MdBookmarkBorder style={{ color: "#d9d9d9" }} size={35} />
                  </Button>
                );
              }
            }}
          />
          <Table.Column title="ประเภทประกาศ" dataIndex="announce_text" />

          <Table.Column
            title="Cover"
            dataIndex="azure_url"
            align="left"
            render={(text) => {
              if (text === null || text === undefined) {
                return "No Cover";
              }
              return <img src={text} alt="Cover" width="128" height="128" />;
            }}
          />
          <Table.Column title="หัวข้อ" dataIndex="title" width={800} />

          <Table.Column
            title="วันที่ประกาศ"
            dataIndex=""
            render={({ start_date, end_date, status }) => {
              return (
                <div>
                  <Text>
                    สถานะ :&nbsp;
                    <Text
                      style={{
                        color: `${status === "active" ? "#a0d911" : "#ff4d4f"}`,
                      }}
                    >
                      {_.capitalize(status)}
                    </Text>
                  </Text>
                  <br />
                  <Text>เริ่ม : </Text>
                  <br />
                  <Text>
                    {start_date == null
                      ? "-"
                      : moment(start_date)
                          // .locale("en")
                          .format("DD/MM/YYYY HH:mm")}
                  </Text>
                  <br />
                  <Text>สิ้นสุด : </Text>
                  <br />
                  <Text>
                    {end_date == null
                      ? "-"
                      : moment(end_date)
                          // .locale("en")
                          .format("DD/MM/YYYY HH:mm")}
                  </Text>
                </div>
              );
            }}
            align="left"
          />

          <Table.Column
            title=""
            align="center"
            render={({ id }) => {
              return (
                <div>
                  <Link to={`${match.path}/news-create/news/${id}`}>
                    <Button size="small">
                      <AiOutlineEdit />
                    </Button>
                  </Link>{" "}
                  <Button
                    size="small"
                    onClick={() => {
                      if (!isDeleting) {
                        deleteNewsHandler(id);
                      }
                    }}
                  >
                    <AiOutlineDelete />
                  </Button>
                </div>
              );
            }}
          />
        </Table>
      </PanelBody>
      <Modal
        title={modalData.title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
        footer={[
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Text key={0}>{parse(modalData.description)}</Text>
      </Modal>
    </Panel>
  );
};

export default Index;
