/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, Divider, Row, Typography } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { URL_API, getToken, moment } from "../../../../utils";
import { Creators } from "../ducks/operation-form";
const ProfileRalc = ({ partnerCode, setRalc, ...props }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (partnerCode) {
      axios({
        method: "GET",
        baseURL: URL_API,
        url: `/pos/member-profile/ralc`,
        params: {
          partner_code: partnerCode,
          course: "ralc",
        },
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          setData(res.data.data);
          setRalc(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [partnerCode]);
  if (!(data?.ralc)) return <div></div>;
  return (
    <>
      <Row type="flex" align="middle" className="mt-4">
        <Col md={10}>
          <Typography.Title level={3}>สถานะ RALC</Typography.Title>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="mb-2">
        <Col md={10}>
          <Typography.Title level={4} className="m-0">
            แพลน :
          </Typography.Title>
        </Col>
        <Col md={14} className="d-flex align-items-center">
          <Typography.Title
            className="m-0 font-weight-normal"
            style={{ fontSize: "18px" }}
          >
            {data?.ralc?.product_name}
          </Typography.Title>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="mb-2">
        <Col md={10}>
          <Typography.Title level={4} className="m-0">
            วันที่สมัคร :
          </Typography.Title>
        </Col>
        <Col md={14} className="d-flex align-items-center">
          <Typography.Title
            className="m-0 font-weight-normal"
            style={{ fontSize: "18px" }}
          >
            {moment(data?.ralc?.document_date).format("DD/MM/YYYY")}
          </Typography.Title>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="mb-2">
        <Col md={10}>
          <Typography.Title level={4} className="m-0">
            วันที่หมดอายุ :
          </Typography.Title>
        </Col>
        <Col md={14} className="d-flex align-items-center">
          <Typography.Title
            className="m-0 font-weight-normal"
            style={{ fontSize: "18px" }}
          >
            {moment(data?.ralc?.expire_date).format("DD/MM/YYYY")}
          </Typography.Title>
        </Col>
      </Row>
    </>
  );
};

// export default connect(mapStateToProps, {
//   // setRalc: Creators.setRalc,
//   addBill: Creators.addBill,
// })(ProfileRalc);
export default ProfileRalc;
