/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ValidateError from "../../../../../components/ValidateError";
import { actionClaim } from "../../../../../redux/slices/claim";

const { Text } = Typography;

const { setFormValue } = actionClaim;

const Remark = ({ disabled, isOnlineWait }) => {
  const dispatch = useDispatch();
  const remark = useSelector((state) => state.claim.documentDetail.remark);
  const remark_error = useSelector((state) => state.claim.error.remark);

  return (
    <Row gutter={[8, 8]} className="my-3">
      <Col span={6} className="text-right">
        <Text strong>หมายเหตุ</Text>
      </Col>
      <Col span={18}>
        {disabled ? (
          <Text>{remark}</Text>
        ) : (
          <div className={remark_error && "has-error"}>
            <Input
              disabled={isOnlineWait}
              value={remark}
              name="remark"
              autoComplete="off"
              placeholder="หมายเหตุการส่งเรื่องต่อ"
              size="small"
              className="mr-2 w-100"
              onChange={(e) =>
                dispatch(
                  setFormValue({
                    name: "documentDetail",
                    value: { remark: e.target.value },
                    error_name: "remark",
                  })
                )
              }
            />
          </div>
        )}
        <ValidateError error={remark_error} />
      </Col>
    </Row>
  );
};

export default memo(Remark);
