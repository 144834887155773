import React from 'react'
import { connect } from 'react-redux'
import { _ } from '../../../../utils'

const BillHeader = (props) => {
    return (
        <div className="pos-payment-bill-header bg-gray-60 text-white px-4 py-2">
            <div className="row d-flex">
                <div className="col text-nowrap">{`บิลที่ ${props.indexKey + 1} - ${props.partner_code} ${props.partner_name}`}</div>
                <div className="col text-nowrap">ประเภทการสั่งซื้อ <small>{` ${props.order_type}`}</small></div>
                <div className="col text-nowrap">ใบเสร็จ <small>{` ${props.req_receipt === '1' ? 'รับใบเสร็จ' : 'ไม่รับใบเสร็จ'}`}</small></div>
            </div>

            <div className="row d-flex">
                <div className="col">ประเภทการรับของ <small>{` ${props.req_receipt_type === 'Branch' ? 'รับที่สาขา' : 'จัดส่ง'}`}</small></div>
                {
                    props.req_receipt_type === 'Branch' ?
                        (
                            <>
                                <div className="col text-nowrap">สาขาที่รับ <small>{` ${props.receipt_branch_name}`}</small></div>
                                <div className="col text-nowrap"></div>
                            </>
                        ) : (
                            <>
                                <div className="col text-nowrap">ชื่อผู้รับ <small>{` ${props.ship_to}`}</small></div>
                                <div className="col text-nowrap">เบอร์โทรศัพท์ <small>{` ${props.ship_mobile}`}</small></div>
                            </>
                        )
                }
            </div>

            { (_.includes(['Transport','Lowcost'], props.req_receipt_type ) ) &&
                    (
                        <div className="row">
                            <div className="col">
                                ที่อยู่จัดส่ง
                                <small>
                                    {` ${props.ship_address_no} ${props.ship_sub_district} ${props.ship_district} ${props.ship_province} ${props.ship_post_code}`}
                                </small>
                            </div>
                        </div>
                    ) 
            }

        </div>
    )
}

const mapStateToProps = state => {
    const { orders, payment } = state.pos.operation;

    return {
        indexKey: orders.itemKeys.indexOf(payment.activeKey !== "" ? payment.activeKey : orders.itemKeys[0]),
        ..._.omit(orders.itemByKey[payment.activeKey !== "" ? payment.activeKey : orders.itemKeys[0]], ['items'])
    };
}

export default connect(mapStateToProps)(BillHeader);
