import { Button, notification } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import BranchConsiderModal from "./BranchConsiderModal";

const { clickBranchConsider } = actionClaim;

const BranchConsider = () => {
  const dispatch = useDispatch();
  const loadingStock = useSelector(
    (state) => state.claim.fetch.stock_balance.loading
  );
  return (
    <>
      <Button
        type="primary"
        className="width-100"
        onClick={() => {
          if (loadingStock)
            return notification.warning({
              message: "ไม่สามารถดำเนินการได้",
              description: "กำลังโหลดข้อมูลสินค้าที่ได้รับ",
            });
          dispatch(clickBranchConsider());
        }}
      >
        ดำเนินการ
      </Button>
      <BranchConsiderModal />
    </>
  );
};

export default BranchConsider;
