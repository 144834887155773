import { Badge, Descriptions } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { marriedSeleector } from "../../../../redux/slices/member";
import { setFormat } from "../../../../utils";

export default function MarriedPanel() {
  const married = useSelector(marriedSeleector);

  return (
    <Descriptions size="small" bordered>
      <Descriptions.Item label="รหัสสมาชิก">
        {married?.member_code}
      </Descriptions.Item>
      <Descriptions.Item label="วันที่สมัคร">
        {married?.register_date &&
          moment(married?.register_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="วันที่หมดอายุ">
        {married?.expire_date &&
          moment(married?.expire_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="ชื่อ (ภาษาไทย)" span={3}>
        {married && married?.first_name_th + " " + married?.sur_name_th}
      </Descriptions.Item>
      <Descriptions.Item label="ชื่อ (ภาษาอังกฤษ)" span={3}>
        {married && married?.first_name_en + " " + married?.sur_name_en}
      </Descriptions.Item>
      <Descriptions.Item label="วันเกิด">
        {married?.birth_date &&
          moment(married?.birth_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="อายุ">
        {married?.birth_date && moment().diff(married?.birth_date, "years")}
      </Descriptions.Item>
      <Descriptions.Item label="เพศ">{married?.gender_text}</Descriptions.Item>
      <Descriptions.Item label="สัญชาติ" span={3}>
        {married?.nationality_text}
      </Descriptions.Item>
      <Descriptions.Item label="บัตรประชาชน" span={2}>
        {setFormat("id_card", married?.id_card)}
      </Descriptions.Item>
      <Descriptions.Item label="วันหมดอายุ">
        {married?.id_card_expire_date &&
          moment(married?.id_card_expire_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="พาสปอร์ต" span={2}>
        {married?.passport_id}
      </Descriptions.Item>
      <Descriptions.Item label="วันหมดอายุ">
        {married?.passport_expire_date &&
          moment(married?.passport_expire_date).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="โทรศัพท์">
        {setFormat("mobile", married?.mobile)}
      </Descriptions.Item>
      <Descriptions.Item label="อีเมล" span={2}>
        {married?.email}
      </Descriptions.Item>
      <Descriptions.Item label="Line ID">{married?.line_id}</Descriptions.Item>
      <Descriptions.Item label="Facebook" span={2}>
        {married?.facebook}
      </Descriptions.Item>
      <Descriptions.Item label="สถานะ" span={3}>
        <Badge status="success" text={married?.status} />
      </Descriptions.Item>
    </Descriptions>
  );
}
