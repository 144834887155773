import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios, getToken, URL_API } from "../../utils";

export const getBillSelect = createAsyncThunk(
  "get/claim/bill/document_no",
  async (document_no, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/claim/bill/${document_no}`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const getClaimSelect = createAsyncThunk(
  "get/claim/document_no",
  async (document_no, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/claim/${document_no}`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getConsiderPermission = createAsyncThunk(
  "get/claim/consider/permission",
  async (_data, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/claim/consider/permission`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getStockBalance = createAsyncThunk(
  "get/claim/product/stock-balance",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/claim/product/stock-balance`,
        headers: { Authorization: "Bearer " + getToken() },
        params,
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
