/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Table,
  Typography,
  Upload,
  notification,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BiExport, BiImport } from "react-icons/bi";
import { BsCircleFill } from "react-icons/bs";
import * as XLSX from "xlsx-js-style";
import SelectVoucherList from "../../../components/SelectVoucherList";
import { useHttp } from "../../../hooks/http";
import { URL_API, axios, getToken } from "../../../utils";
import { numInt } from "../../util/helper/map-data";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import { dummyRequest } from "../manage-website/components/dummyRequest";
import SelectOption from "../pos/components/SelectOption";

const { Text } = Typography;
const { Search } = Input;

const Index = () => {
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [voucherTypeFilter, setVoucherTypeFilter] = useState("");
  const [discountTypeFilter, setDiscountTypeFilter] = useState("");
  const [statusTypeFilter, setStatusTypeFilter] = useState("");
  const [query, setQuery] = useState("");
  const [voucherId, setVoucherId] = useState(0);

  const [visible, setVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const [excelData, setExcelData] = useState([]);

  const limit = 30;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(limit);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/voucher-details`,
      token: true,
      params: {
        limit: limit,
        offset: limit * (page - 1),
        member_type: memberTypeFilter,
        voucher_type: voucherTypeFilter,
        discount_type: discountTypeFilter,
        status_type: statusTypeFilter,
        query,
      },
      // initialLoad: false,
    },
    [
      page,
      memberTypeFilter,
      voucherTypeFilter,
      discountTypeFilter,
      statusTypeFilter,
      query,
    ]
  );

  console.log("data.total:", data?.total);

  const handleSubmit = (e) => {
    if (voucherId === 0) {
      notification.warning({
        message: "ข้อมูลไม่ถูกต้อง",
        description: "กรุณาเลือกบัตรกำนัลสำหรับรายการนี้",
        top: 60,
      });
      return;
    } else if (excelData.length <= 0) {
      notification.warning({
        message: "ไม่สามารถดำเนินการได้",
        description: "ไม่พบข้อมูล กรุณาไฟล์ใหม่เพื่อดำเนินการ",
        top: 60,
      });
      return;
    }
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          const data = {
            excel_data: excelData,
            voucher_id: voucherId,
          };
          axios({
            method: "post",
            baseURL: URL_API,
            url: `/voucher-details`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
            cancelToken: source.token,
          })
            .then((res) => {
              let _error = res?.data?.personError;
              let _success = res?.data?.personSuccess;
              resolve();
              if (_error?.length <= 0) {
                notification.success({
                  message: "Success",
                  description: `บันทึกข้อมูลบัตรกำนัลเรียบร้อย ${_success?.length} รายการ`,
                });
              } else if (_success?.length <= 0) {
                Modal.warning({
                  title: "มีรายการไม่ถูกต้อง",
                  content: `รหัสสมาชิกไม่ถูกต้อง ${_error?.length} รายการ [${_error}]`,
                  okText: "ตกลง",
                  zIndex: 1080,
                });
              } else {
                Modal.success({
                  title: "บันทึกข้อมูลบัตรกำนัลเรียบร้อย",
                  content: `สำเร็จ ${_success?.length} รายการ , ไม่สำเร็จ ${_error?.length} รายการ - [${_error}]`,
                  okText: "ตกลง",
                  zIndex: 1080,
                });
              }

              setVisible(false);
              setExcelData([]);
              setFileName("");
              reload();
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {
        source.cancel("Operation canceled by the user.");
      },
    });
  };

  const handleCancel = (e) => {
    setVisible(false);
    setExcelData([]);
    setFileName("");
  };

  const showModal = (e) => {
    setVisible(true);
  };

  const handleFileUpload = (file) => {
    setFileName(file?.name);
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(worksheet, {
        header: ["member_code", "first_name", "last_name", "qty_voucher"],
      });
      const slicedData = sheetData.slice(1); // exclude first item
      setExcelData(slicedData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDelete = (record) => {
    const { id, ref_type, ref_code, voucher_code } = record;
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: `คุณต้องการยกเลิกใช้งาน ${id}: ${voucher_code} ของสมาชิก ${ref_code} หรือไม่`,
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            baseURL: URL_API,
            url: `/voucher-details/cancel/${id}`,
            headers: { Authorization: "Bearer " + getToken() },
          })
            .then((res) => {
              if (res.status === 200) {
                notification.success({
                  message: "Success",
                  description: `ยกเลิกใช้งานบัตรกำนัลเรียบร้อย`,
                });
              }
              resolve();
              reload();
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {},
    });
  };

  const exportToExcel = () => {
    if (true) {
      let excelData = [["รหัสสมาชิก", "ชื่อ", "นามสกุล", "จำนวนบัตรกำนัล"]];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      var wscols = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
      var wsrows = [
        { hpx: 20 }, // "pixels"
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;
      //   ws["!merges"] = merge;

      ws["A1"].s = centerStyles;
      ws["B1"].s = centerStyles;
      ws["C1"].s = centerStyles;
      ws["D1"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รูปแบบบัตรกำนัล");
      XLSX.writeFile(wb, "รูปแบบบัตรกำนัล.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  const memberTypeOptions = [
    { value: "Distributor", text: "นักธุรกิจ" },
    { value: "Member", text: "VIP MEMBER" },
  ];
  const voucherTypeOptions = [
    { value: "voucher", text: "Voucher" },
    { value: "coupon", text: "Coupon" },
  ];
  const discountTypeOptions = [
    { value: 1, text: "ส่วนลดเงินสด" },
    // { value: 2, text: "ส่วนลดเปอร์เซ็น" }, // without this phase
    { value: 3, text: "ส่วนลดค่าจัดส่ง" },
  ];
  const statusTypeOptions = [
    // { value: -1, text: "ลบแล้ว" },
    // { value: 0, text: "ไม่ให้ใช้งาน" },
    { value: 1, text: "ยังไม่ได้ใช้งาน" },
    { value: 2, text: "ถูกใช้งานไปแล้ว" },
  ];

  const columns = [
    {
      title: "รหัสสมาชิก",
      dataIndex: "member_code",
      key: "member_code",
    },
    {
      title: "ชื่อ",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "นามสกุล",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "จำนวนบัตรกำนัล",
      dataIndex: "qty_voucher",
      key: "qty_voucher",
    },
  ];

  return (
    <Panel>
      <PanelHeader>รายการบัตรกำนัล</PanelHeader>
      <PanelBody>
        <Row gutter={12} type="flex" className="align-items-center mb-3">
          <Col span={16}>
            <Search
              loading={loading}
              placeholder="ค้นหาจาก รหัสสมาชิก , รหัสบัตรกำนัล , ชื่อบัตรกำนัล "
              allowClear
              onSearch={(value) => {
                setQuery(value);
                setPage(1);
              }}
              style={{
                width: "100%",
              }}
            />
          </Col>
          <Col span={4}>
            <Button type="dashed" onClick={showModal} style={{ width: "100%" }}>
              <div className="flex justify-center items-center">
                <span className="mr-2">
                  <BiImport size={12} />
                </span>
                <span>Import</span>
              </div>
            </Button>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={exportToExcel}
              style={{ width: "100%" }}
            >
              <div className="flex justify-center items-center">
                <span className="mr-2">
                  <BiExport size={12} />
                </span>
                <span>Export Template</span>
              </div>
            </Button>
          </Col>
        </Row>
        <Row
          gutter={12}
          type="flex"
          align="middle"
          className="align-items-center mb-3"
        >
          <Col>
            <Text strong>ประเภทสมาชิก</Text>
          </Col>
          <Col span={4}>
            <SelectOption
              withAll
              className="w-100"
              defaultValue={memberTypeFilter}
              options={memberTypeOptions}
              onChange={(e) => {
                setMemberTypeFilter(e);
                setPage(1);
              }}
            />
          </Col>
          <Col>
            <Text strong>ประเภทบัตรกำนัล</Text>
          </Col>
          <Col span={4}>
            <SelectOption
              withAll
              className="w-100"
              defaultValue={voucherTypeFilter}
              options={voucherTypeOptions}
              onChange={(e) => {
                setVoucherTypeFilter(e);
                setPage(1);
              }}
            />
          </Col>
          <Col>
            <Text strong>ประเภทส่วนลด</Text>
          </Col>
          <Col span={4}>
            <SelectOption
              withAll
              className="w-100"
              defaultValue={discountTypeFilter}
              options={discountTypeOptions}
              onChange={(e) => {
                setDiscountTypeFilter(e);
                setPage(1);
              }}
            />
          </Col>
          <Col>
            <Text strong>สถานะ</Text>
          </Col>
          <Col span={4}>
            <SelectOption
              withAll
              className="w-100"
              defaultValue={statusTypeFilter}
              options={statusTypeOptions}
              onChange={(e) => {
                setStatusTypeFilter(e);
                setPage(1);
              }}
            />
          </Col>
        </Row>

        <Row gutter={12} type="flex" className="align-items-center mb-4"></Row>

        <Table
          bordered
          size="medium"
          loading={loading}
          dataSource={data?.data || []}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            total: data?.total,
            showLessItems: false,
            size: "default",
            onChange: (currPage) => setPage(currPage),
          }}
        >
          <Table.Column title="No" dataIndex="row_id" />
          <Table.Column
            title="ประเภทสมาชิก"
            dataIndex="ref_type"
            render={(text) =>
              text === "Distributor" ? "นักธุรกิจ" : "VIP Member"
            }
          />
          <Table.Column title="รหัสสมาชิก" dataIndex="ref_code" />
          <Table.Column title="ชื่อ" dataIndex="first_name_th" />
          <Table.Column title="นามสกุล" dataIndex="sur_name_th" />
          <Table.Column
            title="ประเภทบัตรกำนัล"
            dataIndex="voucher_type"
            render={(text) => _.toUpper(text)}
          />
          <Table.Column title="รหัสบัตรกำนัล" dataIndex="voucher_code" />
          <Table.Column title="ชื่อบัตรกำนัล" dataIndex="voucher_name" />
          <Table.Column
            title="ประเภทส่วนลด"
            dataIndex="type"
            render={(text) =>
              _.toNumber(text) === 1
                ? "ส่วนลดเงินสด"
                : _.toNumber(text) === 2
                ? "ส่วนลดเปอร์เซ็น"
                : _.toNumber(text) === 3
                ? "ส่วนลดค่าจัดส่ง"
                : "-"
            }
          />
          <Table.Column
            title="มูลค่าบัตรกำนัล"
            dataIndex="value"
            align="right"
            render={(text) => numInt(text)}
          />
          <Table.Column
            title="การได้รับคะแนน"
            dataIndex="collect_point"
            align="left"
            render={(text) =>
              text === 0 ? "ไม่มีคะแนน" : text === 1 ? "มีคะแนน" : "-"
            }
          />
          <Table.Column
            title="เวลาเริ่มต้น"
            dataIndex="start_date"
            render={(text) =>
              text !== null ? moment(text).format("DD / MMMM / YYYY") : "-"
            }
          />
          <Table.Column
            title="เวลาสิ้นสุด"
            dataIndex="end_date"
            render={(text) =>
              text !== null ? (
                <div>
                  <span className="mr-2">
                    {moment(text).format("DD / MMMM / YYYY")}
                  </span>
                  <span className="text-reds">
                    {moment(text) < moment().endOf("day")
                      ? `(สิ้นสุดแล้ว!)`
                      : ""}
                  </span>
                </div>
              ) : (
                "ไม่มีวันหมดอายุ"
              )
            }
          />
          <Table.Column
            title="ยกเลิกใช้งาน"
            align="center"
            render={
              (_, record) => (
                <Button
                  type="danger"
                  icon="delete"
                  size="large"
                  disabled={record?.status === 2}
                  onClick={() => {
                    handleDelete(record);
                  }}
                />
              )
              //  <RiDeleteBinLine />
            }
          />
          <Table.Column
            title="สถานะ"
            dataIndex="status"
            fixed="right"
            render={(text) =>
              text === 0 ? (
                <div className="flex justify-center items-center">
                  <BsCircleFill size={8} color="#DCA001" className="mr-2" />
                  ไม่ให้ใช้งาน
                </div>
              ) : text === 1 ? (
                <div className="flex justify-center items-center">
                  <BsCircleFill size={8} color="#039D46" className="mr-2" />
                  ยังไม่ได้ใช้งาน
                </div>
              ) : text === 2 ? (
                <div className="flex justify-center items-center">
                  <BsCircleFill size={8} color="#CF1D27" className="mr-2" />
                  ถูกใช้งานไปแล้ว
                </div>
              ) : (
                "-"
              )
            }
          />
        </Table>
        {/* ---------- MODAL IMORT EXCEL --------- */}
        <Modal
          title="อัพโหลดรายการบัตรกำนัล"
          width={600}
          visible={visible}
          onCancel={handleCancel}
          footer={[
            <Button onClick={handleCancel}>Cancel</Button>,
            <Button type="primary" onClick={handleSubmit}>
              Upload
            </Button>,
          ]}
        >
          <Row gutter={16}>
            <Col span={18}>
              <SelectVoucherList
                placeholder="เลือกบัตรกำนัล"
                className="w-100"
                onChange={(e) => {
                  setVoucherId(e);
                }}
              />
            </Col>
            <Col span={6}>
              <Upload
                beforeUpload={handleFileUpload}
                customRequest={dummyRequest}
                showUploadList={false}
                accept=".xlsx,.csv,.numbers,.xls,.xlt,.xml"
              >
                <Button>
                  <Icon type="upload" /> เลือกไฟล์
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row gutter={16} className="mt-2">
            <span className="mr-2 ml-2">
              {fileName.length > 0 && <AiOutlinePaperClip />}
            </span>
            <span>{fileName}</span>
          </Row>
          {excelData.length > 0 ? (
            <Table
              className="mt-4"
              bordered
              dataSource={excelData}
              columns={columns}
              rowKey="member_code"
              pagination={{ pageSize: 20 }}
            />
          ) : (
            <div
              style={{
                widht: "100%",
                display: "flex",
                justifyContent: "center",
                opacity: "0.4",
                margin: "40px 0px",
              }}
            >
              {fileName.length > 0 && `- ไม่พบข้อมูล -`}
            </div>
          )}
        </Modal>
      </PanelBody>
    </Panel>
  );
};

export default Index;
