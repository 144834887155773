import { Select } from "antd";
import React, { forwardRef } from "react";

const SelectOption = ({
  data = [],
  withAll = false,
  allValue = "",
  allText = "ทั้งหมด",
  ...props
}) => {
  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      dropdownMatchSelectWidth={false}
      {...props}
    >
      {withAll && <Select.Option value={allValue}>{allText}</Select.Option>}
      {data.map((n, i) => (
        <Select.Option key={i} value={n.value} source={n}>
          {n.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default forwardRef((props, ref) => {
  return <SelectOption forwardRef={ref} {...props} />;
});
