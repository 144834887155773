/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import SelectPriority from "../../../../../components/SelectPriority";
import { initClaimError } from "../../../../../redux/logic/claim";
import { actionClaim } from "../../../../../redux/slices/claim";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";

const { Text } = Typography;

const { setGlobalState, clearGlobalState, setState } = actionClaim;

const BranchConsiderModal = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const [priorityError, setPriorityError] = useState(false);
  const { document_no, document_status, remark, manager_priority, option } =
    useSelector((state) => state.claim.branchConsiderModal);
  const { receiptType, documentDetail } = useSelector((state) => state.claim);
  const disableConsider = useSelector(
    (state) =>
      state.claim.documentDetail.claim_type === "satisfied" &&
      _.some(state.claim.billProduct.itemKeys, (product_key) => {
        const { itemKeys, itemByKey } =
          state.claim.billProduct.itemByKey[product_key].claim_item;
        _.some(itemKeys, (item_key) => {
          const { bom } = itemByKey[item_key];
          const isBomLessHalf = _.some(bom.itemKeys, (bom_key) => {
            const { itemKeys, itemByKey } = bom.itemByKey[bom_key].claim_item;
            return _.some(itemKeys, (sub_item_key) =>
              _.includes(["0", "25"], itemByKey[sub_item_key].amount_type)
            );
          });
          return (
            _.includes(["0", "25"], itemByKey[item_key].amount_type) ||
            isBomLessHalf
          );
        });
      })
  );
  const isClaimOutProductEmpty = useSelector(
    (state) => state.claim.claimOutProduct.itemKeys.length === 0
  );
  const claimOutProduct = useSelector((state) => {
    const { itemKeys, itemByKey } = state.claim.claimOutProduct;
    return _.map(itemKeys, (key) => itemByKey[key]);
  });
  const isSomeSoldOut = useSelector((state) => {
    const { itemKeys, itemByKey } = state.claim.claimOutProduct;
    return _.some(itemKeys, (key) => itemByKey[key].stock_balance <= 0);
  });

  const handleClickSubmit = (e) => {
    let data_text, text_style, status, consider_status;
    switch (e) {
      case "approve":
        if (!isClaimOutProductEmpty) {
          let bomStockList = claimOutProduct.map(
            (n) => n.bom.length > 0 && n.bom.map((m) => m.stock_balance)
          );
          let someBomSoldOut = _.includes(bomStockList, 0);

          let ce = {
            ...initClaimError,
          };
          if (!receiptType.req_receipt_type)
            ce.req_receipt_type_error = "กรุณาเลือกประเภทการรับของ";
          if (
            receiptType.req_receipt_type === "Branch" &&
            !receiptType.receipt_branch_code
          )
            ce.receipt_branch_code = "กรุณาเลือกสาขาที่รับ";
          if (
            receiptType.req_receipt_type === "Transport" &&
            _.includes([null, undefined], receiptType.ship_to_id)
          )
            ce.ship_to_id = "กรุณาเลือกที่อยู่";
          dispatch(setGlobalState({ name: "error", value: ce }));
          let description = null;
          for (const key in ce) {
            const e = ce[key];
            if (e) {
              description = "กรุณาตรวจสอบรายการ";
              break;
            }
          }
          if (!description && isSomeSoldOut)
            description = "ไม่มีสินค้าที่ได้รับบางรายการ (sold out)";
          else if (someBomSoldOut) {
            description = "ไม่มีสินค้าย่อยที่ได้รับบางรายการ (sold out)";
          }
          if (description) {
            notification.warning({
              message: "ไม่สามารถดำเนินการได้",
              description,
            });
            return dispatch(clearGlobalState("branchConsiderModal"));
          }
        }
        status = "considered";
        consider_status = e;
        data_text = "อนุมัติเคลมสินค้า";
        text_style = "text-pv";
        break;
      case "reject":
        status = "considered";
        consider_status = e;
        data_text = "ไม่อนุมัติเคลมสินค้า";
        text_style = "text-nn";
        break;
      case "wait-manager":
        if (!manager_priority) return setPriorityError(true);
        status = e;
        consider_status = null;
        data_text = "ส่งเรื่องให้ Manager ";
        text_style = "text-rp";
        break;
      default:
        return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text.trim()}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(setState({ name: "loadingSubmit", value: true }));
        axios({
          method: "post",
          url: `${URL_API}/claim/consider/branch`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            status,
            document_no,
            document_status,
            manager_priority,
            consider_status,
            receipt: {
              ...receiptType,
              ship_to:
                receiptType.req_receipt_type === "Branch"
                  ? documentDetail.ship_to
                  : receiptType.ship_to,
              ship_mobile:
                receiptType.req_receipt_type === "Branch"
                  ? documentDetail.ship_mobile
                  : receiptType.ship_mobile,
            },
            remark: remark?.trim() || null,
          },
        })
          .then(() => {
            dispatch(clearGlobalState("branchConsiderModal"));
            notification.success({
              message: `บันทึก${data_text}แล้ว`,
            });
            history.push({
              pathname: `/${_.split(match.path, "/")[1]}/${
                match.params.process
              }`,
            });
          })
          .catch((e) => resCatchModal(e))
          .finally(() =>
            dispatch(setState({ name: "loadingSubmit", value: false }))
          );
      },
    });
  };

  return (
    <Modal
      title={`ยืนยันดำเนินการเลขที่ใบเคลม ${document_no}`}
      visible={!!document_no}
      footer={false}
      zIndex={1080}
      width={600}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={() => dispatch(clearGlobalState("branchConsiderModal"))}
    >
      <Row gutter={[8, 8]} type="flex" className="align-items-center mb-3">
        <Col span={5} className="text-right">
          <Text>ดำเนินการ</Text>
        </Col>
        <Col span={19}>
          <Select
            allowClear
            placeholder="เลือกการดำเนินการ"
            className="w-100"
            value={option ?? undefined}
            dropdownStyle={{ zIndex: 1080 }}
            onChange={(option) =>
              dispatch(
                setGlobalState({
                  name: "branchConsiderModal",
                  value: { option, manager_priority: null, remark: null },
                })
              )
            }
          >
            {!disableConsider && (
              <Select.Option value="consider">พิจารณา</Select.Option>
            )}
            <Select.Option value="wait-manager">
              ส่งเรื่องให้ Manager
            </Select.Option>
          </Select>
        </Col>
        {option === "wait-manager" && (
          <>
            <Col span={5} className="text-right">
              <Text>ลำดับความสำคัญ</Text>
            </Col>
            <Col span={19}>
              <div className={priorityError && "has-error"}>
                <SelectPriority
                  allowClear
                  value={manager_priority ?? undefined}
                  onChange={(manager_priority) => {
                    setPriorityError(!manager_priority);
                    dispatch(
                      setGlobalState({
                        name: "branchConsiderModal",
                        value: { manager_priority },
                      })
                    );
                  }}
                />
              </div>
              {priorityError && (
                <Text className="text-nn py-1">*กรุณาเลือกลำดับความสำคัญ</Text>
              )}
            </Col>
          </>
        )}
        {option && (
          <>
            <Col span={5} className="text-right">
              <Text>หมายเหตุ</Text>
            </Col>
            <Col span={19}>
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                maxLength={500}
                value={remark}
                onChange={(e) =>
                  dispatch(
                    setGlobalState({
                      name: "branchConsiderModal",
                      value: { remark: e.target.value },
                    })
                  )
                }
              />
            </Col>
          </>
        )}
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24} className="text-center">
          {option === "consider" ? (
            <div>
              <Button
                className="bg-nn border-nn text-white width-100"
                onClick={() => handleClickSubmit("reject")}
              >
                ไม่อนุมัติ
              </Button>
              <Button
                type="primary"
                className="width-100 ml-3"
                onClick={() => handleClickSubmit("approve")}
              >
                อนุมัติ
              </Button>
            </div>
          ) : (
            option === "wait-manager" && (
              <Button
                className="bg-rp border-rp text-white width-100"
                onClick={() => handleClickSubmit("wait-manager")}
              >
                ส่งเรื่อง
              </Button>
            )
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default BranchConsiderModal;
