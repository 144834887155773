import { Col, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectAllType from "../../../../components/SelectAllType";
import {
  actionMember,
  activeSelector,
  searchSelector
} from "../../../../redux/slices/member";

export default function MemberFilter() {
  const dispatch = useDispatch();

  const active = useSelector(activeSelector);
  const search = useSelector(searchSelector);
  const { SET_SEARCH, SET_ACTIVE } = actionMember;

  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(search.query);
  }, [active, search.query]);

  return (
    <Row gutter={16} className="mb-3">
      <Col md={4}>
        {/* <Radio.Group
          value={active}
          onChange={(e) => dispatch(SET_ACTIVE(e.target.value))}
        >
          <Radio value="distributor">นักธุรกิจ</Radio>
          <Radio value="member">VIP Member</Radio>
        </Radio.Group> */}
        <Select
          className="w-100"
          value={active}
          onChange={(e) => dispatch(SET_ACTIVE(e))}
        >
          <Select.Option value="distributor">นักธุรกิจ</Select.Option>
          <Select.Option value="member">VIP Member</Select.Option>
        </Select>
      </Col>
      <Col md={4}>
        <SelectAllType
          className="w-100"
          process_type={
            active === "member" ? "member_status_local" : "partner_status_local"
          }
          withAll
          allText="ทั้งหมด"
          allowClear
          value={search.status}
          onChange={(e) =>
            dispatch(
              SET_SEARCH({
                status: e,
                page: 1,
              })
            )
          }
        />
      </Col>
      <Col md={16}>
        <Input.Search
          allowClear
          placeholder="รหัสสมาชิก, เลขบัตรประชาชน, ชื่อสมาชิก, เบอร์โทร, E-mail"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onSearch={(e) => {
            dispatch(
              SET_SEARCH({
                query: e,
                page: 1,
              })
            );
          }}
        />
      </Col>
    </Row>
  );
}
