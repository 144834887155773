import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import ButtonIcon from "./ButtonIcon";
import InputNumber from "./InputNumber";

const style = { height: "2.5vh" };
const onFocus = (e) => e.target.select();

const PosInputNumber = ({
  value,
  disabled,
  disabledPlus,
  disabledMinus,
  handleChangeQty,
  handleClickPlus,
  handleClickMinus,
}) => (
  <div className="d-flex">
    <ButtonIcon
      className={`${disabledMinus ? "cursor-no-drop" : ""}`}
      onClick={handleClickMinus}
      disabled={disabledMinus}
    >
      <FaMinus className={`${disabledMinus ? "text-gray-40" : ""}`}/>
    </ButtonIcon>
    <InputNumber
      disabled={disabled}
      value={value}
      onFocus={onFocus}
      onChange={handleChangeQty}
      style={style}
    />
    <ButtonIcon
      className={`${disabledPlus ? "cursor-no-drop" : ""}`}
      onClick={handleClickPlus}
      disabled={disabledPlus}
    >
      <FaPlus className={`${disabledPlus ? "text-gray-40" : ""}`}/>
    </ButtonIcon>
  </div>
);

export default PosInputNumber;
