import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";

import { UpdateAuth } from "../../../../redux/actions/auth-action";
import { setToken, setUser, getUser } from "../../../../utils";
import { Link } from "react-router-dom";

class DropdownProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  logout = async () => {
    await this.props.UpdateAuth(null);
    await setToken(null);
    await setUser(null);
  };

  render() {
    const user = getUser();
    const username = user ? user.name : "";
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="dropdown navbar-user"
        tag="li"
      >
        <DropdownToggle tag="a">
          <div className="image image-icon bg-black text-grey-darker">
            <i className="fa fa-user" />
          </div>
          <span className="d-none d-md-inline"></span> {username}
          <b className="caret" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          {/* <DropdownItem>Edit Profile</DropdownItem>
          <DropdownItem>
            <span className="badge badge-danger pull-right">2</span> Inbox
          </DropdownItem>
          <DropdownItem>Calendar</DropdownItem>
          <DropdownItem>Setting</DropdownItem>
          <div className="dropdown-divider" /> */}

          {/* <DropdownItem href="/reset-password">เปลี่ยนรหัสผ่าน</DropdownItem> */}
          <DropdownItem>
            <Link
              to="/reset-password"
              style={{ textDecoration: "none", color: "#404953" }}
            >
              เปลี่ยนรหัสผ่าน
            </Link>
          </DropdownItem>

          <DropdownItem
            style={{ textDecoration: "none", color: "#404953" }}
            onClick={this.logout}
          >
            ออกจากระบบ
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.authenticate,
  username: state.auth.name,
});

export default connect(mapStateToProps, { UpdateAuth })(DropdownProfile);
