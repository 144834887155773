/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Button, Col, Row, Typography, Input, Form, Modal, message, Select } from 'antd';
import { Panel, PanelBody, PanelHeader } from "./panel";
import { CustomTiTle } from "./TitleStyle";
import { axios, getUser } from "../../../../../utils";
import { useHistory } from "react-router-dom";
import { createReducer } from "@reduxjs/toolkit";
import { eventRequest } from "../../../../../utils/useAxios";
import { AutoComplete } from "antdV4";

const { Option } = AutoComplete;

const initState = {
  loading: false,
  data: {
    id: 0,
    code: "",
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    order_type: ["hold", "regular"],
    order_source: ["pos", "online", "mobile"],
    status: "active",
    condition: { itemKeys: [], itemByKey: {} },
    receive: { itemKeys: [], itemByKey: {} },
  },
  modal: {
    product: {
      visible: false,
      active_by: null,
    },
  },
};

const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  }
});

const RegisterInfo = ({ form }) => {
  const { getFieldDecorator, validateFields } = form;
  const history = useHistory();

  const [state, dispatch] = useReducer(reducer, initState);
  const [registerData, setRegisterData] = useState(null);
  const [searchMember, setSearchMember] = useState("");
  const [searchMemberP, setSearchMemberP] = useState("");
  const [searchRef, setSearchRef] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [selectMember, setSelectmember] = useState(0);
  const [onSearchMember, setOnSelectmember] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [honorList, setHonorList] = useState([]);
  // const [userType, setUserType] = useState("notLogin");
  const [regId, setRegId] = useState(null);
  // const [serialNo, setSerialNo] = useState(null);
  // const [eventId, setEventId] = useState(null);
  const [ticketData, setTicketData] = useState(null)

  const loadDataCallback = useCallback(async () => {
    // const queryString = window.location;
    // const pathname = queryString.pathname.split("/")
    // const id = pathname[3]
    const searchParams = new URLSearchParams(window.location.search);
    const serial = searchParams.get("serial");
    const registerId = searchParams.get("register_id");
    const eventId = searchParams.get("event_id");
    setRegId(registerId)
    // setSerialNo(serial)
    // setEventId(eventId)
    if (registerId) {
      try {
        dispatch({ type: "SET_LOADING", payload: true });
        const data = await eventRequest.get(`/register-event/${registerId}`, {});
        setRegisterData(data.data.data)
        let oldRegister = data.data.data
        if (oldRegister) {
          form.setFieldsValue({
            first_name: oldRegister.FirstName,
            last_name: oldRegister.LastName,
            personal_no: oldRegister.PersonalNo,
            phone_no: oldRegister.PhoneNo,
            serial_no: oldRegister.TicketEvent[0].Serial,
            team_name: oldRegister.TeamName,
            honor_code: oldRegister.HonorCode,
            remark: oldRegister.TicketEvent[0].Remark
          });

          if (oldRegister.MemberCode) {
            form.setFieldsValue({
              member_code: oldRegister.MemberCode
            })
            if (oldRegister.MemberCode.length == 9) {
              setSearchMember(oldRegister.MemberCode)
              loadMemberDataCallback(oldRegister.MemberCode)
            }
          }

          if (oldRegister.RefName) {
            form.setFieldsValue({
              ref_code: oldRegister.RefCode
            })
          }
        }
        dispatch({ type: "SET_LOADING", payload: false });
      } catch (error) {
        dispatch({ type: "SET_LOADING", payload: false });
        message.error(error.message);
      }
    }
    else {
      try {
        dispatch({ type: "SET_LOADING", payload: true });
        const data = await eventRequest.get(`/tickets-event/serial/${serial}/event/${eventId}`, {});
        setTicketData(data?.data?.data)

        dispatch({ type: "SET_LOADING", payload: false });
      } catch (error) {
        dispatch({ type: "SET_LOADING", payload: false });
        message.error(error.message);
      }
      form.setFieldsValue({
        serial_no: serial,
      });
    }
  }, [dispatch]);

  const loadMemberDataCallback = useCallback(async (memberCode) => {
    setMemberList([])
    setSelectmember(0)
    try {
      const data = await eventRequest.get(`/members-master/${memberCode}`, {});
      let list = []
      if (data) {
        let member = {
          firstName: data.data[0].NameLocal,
          lastName: data.data[0].SurnameLocal,
          personalNo: data.data[0].IdentityCardNo,
          phoneNo: data.data[0].Mobile,
          teamName: data.data[0].TeamName,
          honorCode: data.data[0].HonorCode,
          refCode: data.data[0].SponsorMemberCode,
        }
        let memberMarried = {
          firstName: data.data[0].MarriedNameLocal,
          lastName: data.data[0].MarriedSurnameLocal,
          personalNo: data.data[0].MarriedIdentityCard,
          phoneNo: data.data[0].MarriedMobile,
          teamName: data.data[0].TeamName,
          honorCode: data.data[0].HonorCode,
          refCode: data.data[0].SponsorMemberCode,
        }
        list.push(member)
        list.push(memberMarried)
        // setUserType('myself')
      }
      setMemberList(list)
    } catch (error) {
      message.error(error.message);
    }
  }, [dispatch]);

  const loadMemberDataWithPersonalCallback = useCallback(async (idCard) => {
    try {
      const data = await eventRequest.get(`/members-master/personal/${idCard}`, {});
      if (data.data) {
        form.setFieldsValue({
          member_code: data.data.code,
          first_name: data.data.firstName,
          last_name: data.data.lastName,
          phone_no: data.data.phone,
          team_name: data.data.teamName,
          honor_code: data.data.honorCode,
          ref_code: data.data.refCode,
        });
        // setUserType(data.data.userType)
        setMemberList([])
        setSelectmember(0)
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [dispatch]);

  const loadRefDataCallback = useCallback(async (memberCode, oldRegister) => {
    try {
      const data = await eventRequest.get(`/members-master/${memberCode}`, {});
      if (data) {
        form.setFieldsValue({
          team_name: oldRegister?.TeamName ? oldRegister?.TeamName : data.data[0].TeamName,
          // honor_code: oldRegister?.HonorCode ? oldRegister?.HonorCode : data.data[0].HonorCode,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [dispatch]);

  const loadTeamDataCallback = useCallback(async () => {
    setTeamList([])
    try {
      const data = await eventRequest.get(`/members-master/master/team`, {});
      let list = []
      if (data) {
        for (const team of data.data.items) {
          list.push(team.TeamName)
        }
      }
      setTeamList(list)
    } catch (error) {
      message.error(error.message);
    }
  }, [dispatch]);

  const loadHonorDataCallback = useCallback(async () => {
    setHonorList([])
    try {
      const data = await eventRequest.get(`/members-master/master/honor`, {});
      let list = []
      if (data) {
        for (const honor of data.data.items) {
          list.push(honor.HonorCode)
        }
      }
      list.push('Member', 'BB', 'BO');
      setHonorList(list)
    } catch (error) {
      message.error(error.message);
    }
  }, [dispatch]);

  const onFinish = (values) => {
    const user = getUser()
    let data = {
      registerId: regId,
      ticketId: registerData?.TicketId ? registerData?.TicketId : ticketData.Id,
      eventId: registerData?.EventId ? registerData?.EventId : ticketData.EventId,
      // userType: registerData?.UserType ? registerData?.UserType : userType,
      userType: 'backOffice',
      memberCode: values.member_code,
      fName: values.first_name,
      lName: values.last_name,
      phoneNo: values.phone_no,
      personalNo: values.personal_no,
      teamName: values.team_name,
      honorCode: values.honor_code,
      // updatedBy: user.user_code,
      // updatedByName: user.name,
      remark: values.remark,
      // createFromUpdate: true
    }
    if (values.ref_code) {
      data.refCode = values.ref_code
    }
    if (!registerData) {
      // data.createFromUpdate = true
      data.createdBy = user.user_code
      data.createdByName = user.name
    }
    else {
      data.updatedBy = user.user_code
      data.updatedByName = user.name
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการแก้ไขอมูลการลงทะเบียนนี้หรือไม่?",
      okText: "ยืนยัน",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          registerData ?
            axios({
              method: "patch",
              baseURL: process.env.REACT_APP_EVENT_URL_API,
              url: "register-event/update",
              data,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              }) :
            (!registerData && data.memberCode) ?
              axios({
                method: "post",
                baseURL: process.env.REACT_APP_EVENT_URL_API,
                url: "register-event/staff",
                data,
              })
                .then((res) => {
                  console.log(res);
                  resolve("success");
                })
                .catch((err) => {
                  console.log(err);
                  reject(
                    err && err.response
                      ? err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                      : err.message
                  );
                }) :
              axios({
                method: "post",
                baseURL: process.env.REACT_APP_EVENT_URL_API,
                url: "register-event/not-login",
                data,
              })
                .then((res) => {
                  console.log(res);
                  resolve("success");
                })
                .catch((err) => {
                  console.log(err);
                  reject(
                    err && err.response
                      ? err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                      : err.message
                  );
                })
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "แก้ไขการลงทะเบียนเรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.push('/events/ticket', { from: history.location.pathname });
                // window.location.href = `/events/ticket`
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleChange = (select) => {
    setSelectmember(select.key)
    setOnSelectmember(true)
    // if (select.key === 1) {
    //   setUserType('ourselves')
    // }
  }

  useEffect(() => {
    loadDataCallback()
    loadTeamDataCallback()
    loadHonorDataCallback()
  }, [loadDataCallback, loadTeamDataCallback, loadHonorDataCallback]);

  useEffect(() => {
    if (searchMember?.length == 9) {
      loadMemberDataCallback(searchMember)
    }
  }, [searchMember]);

  useEffect(() => {
    if (searchMemberP?.length >= 9) {
      loadMemberDataWithPersonalCallback(searchMemberP)
    }
  }, [searchMemberP]);

  useEffect(() => {
    if (searchRef?.length == 9) {
      loadRefDataCallback(searchRef, registerData)
    }
  }, [searchRef]);

  useEffect(() => {
    if (memberList.length && onSearchMember) {
      form.setFieldsValue({
        first_name: memberList[selectMember].firstName,
        last_name: memberList[selectMember].lastName,
        personal_no: memberList[selectMember].personalNo,
        phone_no: memberList[selectMember].phoneNo,
        team_name: memberList[selectMember].teamName,
        honor_code: memberList[selectMember].honorCode,
        ref_code: memberList[selectMember].refCode,
      });
    }
  }, [memberList, selectMember, onSearchMember]);

  const handleSeachMember = (e) => {
    setSearchMember(e.target.value)
    setOnSelectmember(true)
  }

  const handleSeachMemberByPersonal = (e) => {
    setSearchMemberP(e.target.value)
  }

  const handleSeachRef = (e) => {
    setSearchRef(e.target.value)
  }

  return (
    <>
      <Form onSubmit={onFinish}>
        <Typography style={{
          fontWeight: 600,
          fontSize: '28px',
          lineHeight: '42px',
          color: 'rgba(0, 0, 0, 0.85)'
        }}>แก้ไขข้อมูลการลงทะเบียน</Typography>
        <Panel>
          <PanelHeader>รายละเอียดการลงทะเบียน</PanelHeader>
          <PanelBody>
            <Row gutter={[48, 16]} className="mb-3">
              <Col md={12} style={{ height: '82px' }}>
                <CustomTiTle title={'รหัสสมาชิก'} notReq={true} />
                <Form.Item>
                  {getFieldDecorator("member_code", {
                  })(
                    <Input placeholder="รหัสสมาชิก" allowClear onChange={(e) => handleSeachMember(e)} onPressEnter={(e) => handleSeachMember(e)} />
                  )}
                </Form.Item>
              </Col>

              <Col md={12}>
                {memberList.length && searchMember ?
                  <>
                    <CustomTiTle title={'เลือกสมาชิก'} notReq={true} />
                    <Select
                      labelInValue
                      defaultValue={{ key: 0 }}
                      onChange={(e) => handleChange(e)}
                      style={{
                        marginTop: '4px',
                        marginBottom: '4px'
                      }}
                    >
                      {memberList.map((member, index) =>
                        <Option key={index} value={index}>{member.firstName} {member.lastName}</Option>
                      )}
                    </Select>
                  </>
                  : <></>
                }

              </Col>
            </Row>
            <Row gutter={[48, 16]} className="mb-3">
              <Col md={12}>
                <CustomTiTle title={'ชื่อ'} />
                <Form.Item>
                  {getFieldDecorator("first_name", {
                    rules: [{
                      required: true,
                      message: "กรุณาระบุชื่อ",
                    }],
                  })(
                    <Input placeholder="ชื่อ" allowClear />
                  )}
                </Form.Item>

                <CustomTiTle title={'นามสกุล'} />
                <Form.Item>
                  {getFieldDecorator("last_name", {
                    rules: [{
                      required: true,
                      message: "กรุณาระบุนามสกุล",
                    }],
                  })(
                    <Input placeholder="นามสกุล" allowClear />
                  )}
                </Form.Item>

                <CustomTiTle title={'เลขบัตรประจำตัวประชาชน/Passport No.'} />
                <Form.Item>
                  {getFieldDecorator("personal_no", {
                    rules: [{
                      required: true,
                      message: "กรุณาระบุเลขบัตรประจำตัวประชาชน/Passport No.",
                    }],
                  })(
                    <Input placeholder="เลขบัตรประจำตัวประชาชน/Passport No." allowClear onChange={(e) => handleSeachMemberByPersonal(e)} onPressEnter={(e) => handleSeachMemberByPersonal(e)} />
                  )}
                </Form.Item>

                <CustomTiTle title={'เบอร์โทรศัพท์'} />
                <Form.Item>
                  {getFieldDecorator("phone_no", {
                    rules: [{
                      required: true,
                      message: "กรุณาระบุเบอร์โทรศัพท์",
                    }],
                  })(
                    <Input placeholder="เบอร์โทรศัพท์" allowClear />
                  )}
                </Form.Item>
              </Col>

              <Col md={12} >
                <CustomTiTle title={'Team Name'} notReq={true} />
                <Form.Item>
                  {getFieldDecorator("team_name", {
                  })(
                    <Select
                      showSearch
                      placeholder="Select Team Name"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        teamList.length && teamList.map((teamName, index) =>
                          <Option key={index} value={teamName}>{teamName}</Option>
                        )
                      }
                    </Select>
                  )}
                </Form.Item>

                <CustomTiTle title={'Honor Code'} notReq={true} />
                <Form.Item>
                  {getFieldDecorator("honor_code", {
                  })(
                    <Select
                      showSearch
                      placeholder="Select Honor Code"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        honorList.length && honorList.map((honorCode, index) =>
                          <Option key={index} value={honorCode}>{honorCode}</Option>
                        )
                      }
                    </Select>
                  )}
                </Form.Item>

                <CustomTiTle title={'รหัสผู้แนะนำ'} />
                <Form.Item>
                  {getFieldDecorator("ref_code", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาระบุรหัสผู้แนะนำ",
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (!value) {
                            callback();
                            return;
                          }

                          // Check if the value is a number
                          if (!/^\d+$/.test(value)) {
                            callback("กรุณาระบุเป็นตัวเลขเท่านั้น");
                            return;
                          }

                          // Check if it starts with "110" and has exactly 6 additional digits
                          if (!/^110\d{6}$/.test(value)) {
                            callback("รูปแบบรหัสผู้แนะนำไม่ถูกต้อง");
                            return;
                          }

                          callback(); // Validation passed
                        },
                      },
                    ],
                  })(
                    <Input placeholder="110xxxxxx" allowClear onChange={(e) => handleSeachRef(e)} onPressEnter={(e) => handleSeachRef(e)} />
                  )}
                </Form.Item>

                <CustomTiTle title={'รหัสบัตรงาน'} notReq={true} />
                <Form.Item>
                  {getFieldDecorator("serial_no", {
                  })(
                    <Input placeholder="รหัสบัตรงาน" disabled />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <CustomTiTle title={'หมายเหตุ'} notReq={true} />
            <Form.Item>
              {getFieldDecorator("remark", {
              })(
                <Input placeholder="เพิ่มหมายเหตุ" allowClear />
              )}
            </Form.Item>
          </PanelBody>
        </Panel>

        <Form.Item >
          <Row gutter={[24, 16]} type="flex" justify="center">
            <Col >
              <Button type="danger" ghost onClick={() => {
                history.push('/events/ticket', { from: history.location.pathname });
              }}
                style={{
                  width: '140px',
                  height: '47px',
                  padding: '10px 35px',
                  borderRadius: '12px',
                }}
              >
                ยกเลิก
              </Button>
            </Col>

            <Col >
              <Button type="primary" onClick={() => {
                validateFields((err, values) => {
                  if (!err) {
                    onFinish(values);
                  }
                });
              }}
                style={{
                  width: '140px',
                  height: '47px',
                  padding: '10px 35px',
                  borderRadius: '12px',
                }}
              >บันทึก</Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}

export default Form.create("edit-register")(RegisterInfo);