/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Skeleton,
  Typography,
} from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fixDepartment } from "../../../config/fix-value";
import { useQuery } from "../../../hooks/dom";
import { actionTransfer } from "../../../redux/slices/transfer";
import { actionUser } from "../../../redux/slices/user";
import { getTransferById } from "../../../redux/thunk/transfer";
import { getUserPermission } from "../../../redux/thunk/user";
import { getUserObj, _ } from "../../../utils";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import CancelModal from "./components/CancelModal";
import ManageFooter from "./components/ManageFooter";
import ManageHeader from "./components/ManageHeader";
import ProductAddTable from "./components/ProductAddTable";
import ProductModal from "./components/ProductModal";
import ProductTable from "./components/ProductTable";
import SubmitConsider from "./components/SubmitConsider";
import SubmitReceive from "./components/SubmitReceive";
import SubmitTransfer from "./components/SubmitTransfer";

const { Text } = Typography;

const { clearCreate, clear, setGlobalState } = actionTransfer;
const { clearPermission } = actionUser;

const Manage = ({ history, match }) => {
  const dispatch = useDispatch();
  const user = getUserObj();
  const query = useQuery();
  const document = useSelector((state) => state.transfer.document);
  const { status, error } = useSelector((state) => state.transfer.getById);
  const user_permission = useSelector((state) => state.user.permission);
  const loading_submit = useSelector(
    (state) => state.transfer.searchDocument.loading_submit
  );
  const {
    type: type_params,
    process: process_params,
    id: id_params,
  } = match.params;
  const is_transfer = type_params === "transfer";
  const is_preview = query.get("preview") === "1";
  const receive_type = query.get("receive_type");
  const is_doc_pending = document.status === "pending";
  const is_doc_wait_add = document.status === "wait-add";
  const is_planning = user.department_id === fixDepartment.operation_support;
  let back_url = `/${
    _.split(match.path, "/")[1]
  }/${type_params}/${process_params}`;

  useEffect(() => {
    if (document.status && type_params && !is_preview) {
      dispatch(
        getUserPermission({
          document_type: type_params,
          document_status: document.status,
          user_id: user.id,
        })
      );
    }
  }, [document.status, type_params, is_preview]);

  useEffect(() => {
    return () => {
      dispatch(clearPermission());
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      if (path !== "transfer-receive") {
        dispatch(clear());
      } else {
        dispatch(clearCreate());
      }
    };
  }, []);

  useEffect(() => {
    if (id_params === "0") {
      if (!is_transfer) {
        handleCatchURl("เมนูรับสินค้าไม่สามารถสร้างเอกสารได้");
      } else {
        dispatch(
          setGlobalState({
            name: "document",
            value: {
              from_branch_code: user.branch_code,
              from_wh_code: `${user.branch_code}-00`,
            },
          })
        );
      }
    } else if (_.includes(["receive", "receive-in"], receive_type)) {
      dispatch(
        getTransferById({
          id: id_params,
          type:
            type_params === "receive" && process_params === "complete"
              ? "receive"
              : receive_type === "receive-in"
              ? "receive-in"
              : "transfer",
        })
      );
    }
  }, [id_params, process_params, type_params, receive_type]);

  useEffect(() => {
    if (status === null) {
      if (!_.includes(["transfer", "receive"], type_params)) {
        back_url = `/${_.split(match.path, "/")[1]}/transfer/wait-add`;
        handleCatchURl("URL ไม่ถูกต้อง");
      } else if (
        !_.includes(
          ["wait-add", "pending", "complete", "cancel"],
          process_params
        )
      ) {
        back_url = `/${_.split(match.path, "/")[1]}/${type_params}/wait-add`;
        handleCatchURl("URL ไม่ถูกต้อง");
      } else if (
        id_params !== "0" &&
        !_.includes(["receive", "receive-in"], receive_type)
      ) {
        handleCatchURl("URL ไม่ถูกต้อง");
      } else if (
        _.includes(["complete", "cancel"], process_params) &&
        !is_preview
      ) {
        handleCatchURl("ไม่สามารถจัดการข้อมูลได้");
      }
    } else if (status === "success") {
      if (process_params !== "pending" && document.status !== process_params) {
        handleCatchURl("สถานะของรายการที่เลือกไม่ถูกต้อง");
      } else if (
        process_params === "pending" &&
        !_.includes(
          ["draft", "return", "pending", "wait-receive", "wait-some"],
          document.status
        )
      ) {
        handleCatchURl("สถานะของรายการที่เลือกไม่ถูกต้อง");
      } else if (
        process_params === "pending" &&
        _.includes(["complete", "cancel"], document.status)
      ) {
        handleCatchURl("รายการที่เลือกดำเนินการเสร็จสินแล้ว");
      } else if (
        type_params === "receive" &&
        process_params === "complete" &&
        !document.receive_date
      ) {
        handleCatchURl("ไม่มีข้อมูลการรับสินค้า");
      }
    } else if (status === "notfound") {
      handleCatchURl("ไม่พบข้อมูลโอนย้ายสินค้า");
    }
  }, [
    id_params,
    is_preview,
    process_params,
    document.status,
    type_params,
    document.receive_date,
    status,
    receive_type,
  ]);

  useEffect(() => {
    if (
      id_params !== "0" &&
      !is_preview &&
      document.status &&
      user_permission !== null
    ) {
      if (
        !user_permission ||
        (document.status !== "pending" &&
          (is_transfer
            ? !_.includes(["draft", "wait-add", "return"], document.status)
            : !_.includes(
                ["wait-receive", "wait-some", "wait-return"],
                document.status
              )))
      )
        handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูล");
    }
  }, [document.status, is_preview, id_params, user_permission, is_transfer]);

  useEffect(() => {
    if (
      id_params !== "0" &&
      !is_preview &&
      !is_planning &&
      document.from_branch_code &&
      document.to_branch_code &&
      ((is_transfer && document.from_branch_code !== user.branch_code) ||
        (!is_transfer && document.to_branch_code !== user.branch_code))
    ) {
      handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูลต่างสาขา");
    }
  }, [
    id_params,
    is_preview,
    is_planning,
    document.from_branch_code,
    document.to_branch_code,
    is_transfer,
  ]);

  const handleCatchURl = (content) => {
    dispatch(clear());
    history.push({ pathname: back_url });
    Modal.error({
      title: "ไม่สามารถดำเนินการได้",
      content,
      zIndex: 1080,
    });
  };

  const setLoadingSubmit = useCallback(
    (loading_submit) =>
      dispatch(
        setGlobalState({
          name: "searchDocument",
          value: { loading_submit },
        })
      ),
    []
  );

  const handleClickBack = () => {
    if (is_preview) {
      dispatch(clearCreate());
      history.push({ pathname: back_url });
      return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1">
            ย้อนกลับ
          </Text>
          <Text>สู่หน้าหลักโอนย้ายสินค้าหรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(clearCreate());
        history.push({ pathname: back_url });
      },
    });
  };

  const renderSubmit = () => {
    if (is_preview) return;
    if (is_transfer) {
      if (is_doc_pending)
        return (
          <SubmitConsider
            backUrl={back_url}
            setLoadingSubmit={setLoadingSubmit}
          />
        );
      else
        return (
          <SubmitTransfer
            backUrl={back_url}
            setLoadingSubmit={setLoadingSubmit}
          />
        );
    } else
      return (
        <SubmitReceive backUrl={back_url} setLoadingSubmit={setLoadingSubmit} />
      );
  };

  return (
    <Panel>
      <PanelHeader>
        {type_params === "transfer" ? "โอนย้ายสินค้า" : "รับสินค้า"}
      </PanelHeader>
      <PanelBody>
        <LoadingFullScreen
          loading={loading_submit}
          text="กำลังบันทึกข้อมูล..."
        />
        {status === "failed" ? (
          <Alert type="error" message={error} showIcon />
        ) : status === "loading" ? (
          <Skeleton active />
        ) : (
          <>
            <ManageHeader
              disabled={is_preview || is_doc_pending || !is_transfer}
            />
            <ProductTable
              disabled={is_preview || is_doc_pending}
              productType="product"
            />
            {!is_transfer &&
              (is_preview
                ? document.status === "complete"
                : _.includes(
                    ["wait-some", "wait-receive"],
                    document.status
                  )) && (
                <>
                  <Divider className="mt-4">คำขอโอนเพิ่ม</Divider>
                  {document.receive_type === "receive" ? (
                    <ProductAddTable
                      disabled={document.status === "complete"}
                    />
                  ) : (
                    <Row gutter={[8, 8]} className="mb-3">
                      <Col
                        span={24}
                        className="text-center py-3 bg-gray-40 border"
                      >
                        <Text strong>
                          รายการรับสินค้าจากสำนักงานใหญ่
                          ไม่สามารถเพิ่มรายการสินค้าได้
                        </Text>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            <ManageFooter
              isPreview={is_preview}
              isPending={is_doc_pending}
              isTransfer={is_transfer}
            />

            <Row gutter={[8, 8]} className="mt-3">
              <Col span={6} className="d-flex flex-column">
                <Text>เจ้าหน้าที่ : {user.name}</Text>
                <Text>สาขา : {user.branch_name}</Text>
              </Col>
              <Col span={18} className="text-right">
                <Button
                  type="primary"
                  ghost
                  className="width-150"
                  onClick={handleClickBack}
                >
                  ย้อนกลับ
                </Button>
                {renderSubmit()}
              </Col>
            </Row>
          </>
        )}

        {!is_preview && !is_doc_pending && <ProductModal />}

        {is_doc_wait_add && <CancelModal setLoadingSubmit={setLoadingSubmit} />}
      </PanelBody>
    </Panel>
  );
};

export default Manage;
