/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Modal, notification, Skeleton } from 'antd';
import { default as Axios, default as axios } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../../../../hooks/dom';
import { actionLegal, addressSelector, searchSelector, typeSelector } from '../../../../../redux/slices/legal';
import styles from '../../../../../scss/module/legal.module.scss';
import { URL_API } from '../../../../../utils';
import LegalPanel from '../../components/LegalPanel';
import MultipleRecord from '../../components/MultipleRecord';
import PageMenuTab from '../../components/PageMenuTab';
import ProfileMenu from '../components/ProfileMenu';
import { useApi } from '../../../../../hooks/http2';

function mapAddress(data) {
  let address = [];
  data.forEach(item => {
    address = [...address, [
      {
        name: 'ที่อยู่',
        value: item.address
      },
      {
        name: 'จังหวัด',
        value: item.province
      },
      {
        name: 'อำเภอ / เขต',
        value: item.district
      },
      {
        name: 'ตำบล / แขวง',
        value: item.sub_district
      },
      {
        name: 'รหัสไปรษณีย์',
        value: item.post_code
      },
    ]]
  });
  return address;
}

const Index = ({ history }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { SET_ADDRESS } = actionLegal;
  const [loading, setLoading] = useState(true);
  const address = useSelector(addressSelector);
  const id = query.get('id');
  const type = query.get('membertype');
  const [idCardAddress, setIdCardAddress] = useState([]);
  const [taxAddress, setTaxAddress] = useState([]);
  const [currentAddress, setCurrentAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [source] = useState(Axios.CancelToken.source());


  useEffect(() => {
    return () => source.cancel('cancel by user (unmounted)');
  }, [source]);

  useEffect(() => {
    const {
      id_card,
      tax,
      current,
      delivery
    } = address;
    if (id_card.length > 0 || tax.length > 0 || current.length > 0 || delivery.length > 0) {
      setIdCardAddress([...mapAddress(id_card)]);
      setTaxAddress([...mapAddress(tax)]);
      setCurrentAddress([...mapAddress(current)]);
      setDeliveryAddress([...mapAddress(delivery)]);
    }
  }, [address])

  const list  = useApi(
    {
      url: `${URL_API}/legal/profile/address`,
      token: true,
      params: {
        id,
        member_type: type,
      },
    },
    []
  );
  useEffect(() => {
    if (list?.fetch?.data != null) {
      dispatch(SET_ADDRESS({ ...list?.fetch?.data}));
    }
    setLoading(false);
  }, [list.fetch, dispatch, SET_ADDRESS]);
  

  // useEffect(() => {
  //   const {
  //     id_card,
  //     tax,
  //     current,
  //     delivery
  //   } = address;
  //   if (id_card.length === 0 && tax.length === 0 && current.length === 0 && delivery.length === 0 && id !== null) {
  //     setLoading(true);
  //     axios({
  //       baseURL: URL_API,
  //       url: '/legal/profile/address',
  //       params: {
  //         id,
  //         member_type : member_type?.type
  //       },
  //       cancelToken: source.token
  //     }).then((res) => {
  //       if (res.data.data.id_card.length > 0 || res.data.data.tax.length > 0 || res.data.data.current.length > 0 || res.data.data.delivery.length > 0) {
  //         dispatch(SET_ADDRESS({ ...res.data.data }));
  //       } else {
  //         Modal.warning({
  //           title: "ไม่พบข้อมูล",
  //           content: `${member_type.type} id : ${id}`,
  //           okText: "ตกลง",
  //           onOk: () => {
  //             //history.push('/legal');
  //           },
  //         });
  //       }
  //       setLoading(false);
  //     }).catch((err) => {
  //       if (err.message !== 'cancel by user (unmounted)') {
  //         notification.error({
  //           message: "ตรวจพบสิ่งผิดพลาด",
  //           description:
  //             err.response && err.response.data
  //               ? err.response.data.message
  //               : err.message,
  //           placement: "topRight",
  //         });
  //         setLoading(false);
  //       }
  //     });
  //   }
  // }, [source, address, id, dispatch, SET_ADDRESS]);

  return (
    <LegalPanel>
      <div className={styles.profile}>
        <ProfileMenu />
        {
          loading ? (
            <Skeleton active className={styles.skeleton} />
          ) : (
              <>
                <MultipleRecord type="address" divider title="ที่อยู่ตามบัตรประชาชน" data={idCardAddress} />
                <MultipleRecord type="address" divider title="ที่อยู่สำหรับออกใบกำกับภาษี" data={taxAddress} />
                <MultipleRecord type="address" divider title="ที่อยู่ปัจจุบัน" data={currentAddress} />
                <MultipleRecord type="address" title="ที่อยู่จัดส่ง" data={deliveryAddress} />
              </>
            )
        }
      </div>
    </LegalPanel>
  )
}

export default Index
