/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';
import { axios } from '../utils';
import moment from 'moment';

const fitLegacyServices = {
  useQueryGetLegacyPopUpLists({ take, page }, onSuccess) {
    return useQuery(
      ['get-legacy-pop-up', { take, page }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-pop-up`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyPopUpById({ legacyPopUpId }, onSuccess) {
    return useQuery(
      ['get-legacy-pop-up-by-id', { legacyPopUpId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-pop-up/${queryKey[1].legacyPopUpId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!legacyPopUpId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyPopUp(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(`/legacy-pop-up`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPopUp(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-pop-up/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPopUpActive(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-pop-up/${data.id}/active`,
            {
              active: data.active,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyPopUp(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-pop-up/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyPopUpPartnerLists(
    { take, page, legacyPopUpId, memberCode },
    onSuccess
  ) {
    return useQuery(
      [
        'get-legacy-pop-up-partner-list',
        { take, page, legacyPopUpId, memberCode },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-pop-up-partner-list`,
            {
              params: {
                take: queryKey[1].take,
                page: queryKey[1].page,
                legacyPopUpId: queryKey[1].legacyPopUpId,
                ...(memberCode && { memberCode: queryKey[1].memberCode }),
              },
            }
          );

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!legacyPopUpId,
      }
    );
  },
  useQueryGetLegacyPopUpPartnerListById({ legacyPopUpId }, onSuccess) {
    return useQuery(
      ['get-legacy-pop-up-partner-list-by-id', { legacyPopUpId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-pop-up-partner-list/${queryKey[1].legacyPopUpId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!legacyPopUpId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyPopUpPartnerList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-pop-up-partner-list`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPopUpPartnerList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-pop-up-partner-list/${data.id}`,
            {
              display: data.display,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyPopUpPartnerList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-pop-up-partner-list/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUploadLegacyPopUpPartnerListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async ({ data, legacyPopUpId }) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-pop-up-partner-list/upload-excel/${legacyPopUpId}`,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
  useQueryGetLegacyNotificationLists(
    { take, page, notification_code },
    onSuccess
  ) {
    return useQuery(
      ['get-legacy-notification', { take, page, notification_code }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-notification`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              notification_code: queryKey[1].notification_code,
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyNotificationById({ legacyNotificationId }, onSuccess) {
    return useQuery(
      ['get-legacy-notification-by-id', { legacyNotificationId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-notification/${queryKey[1].legacyNotificationId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!legacyNotificationId,
      }
    );
  },
  useMutationCreateLegacyNotification(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(`/legacy-notification`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyNotification(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-notification/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyNotification(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-notification/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationSendLegacyNotification(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const sendData = {
            title: data.title,
            description: data.description,
            sub_description: data.sub_description,
            thumbnail_img_url: data.thumbnail_img_url,
            ...(data.link && { link: data.link }),
            link_type: data.link_type,
            effective_date: moment(data.effective_date).toDate(),
            receive_users: data.receive_users,
            send_all: data.send_all,
            notification_code: data.notification_code,
          };

          const res = await axios.post(
            `${
              process.env.REACT_APP_URL_API_MEMBER ||
              'https://dev-lcmember-api.azurewebsites.net'
            }/notification`,
            // 'https://legacy-member-api.azurewebsites.net/notification',
            sendData,
            {
              headers: {
                Authorization: `Bearer fb97a56ceea3f0cd11abca7ebdfe7c60`,
              },
            }
          );

          return true;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyRewardEventLists({ take, page }, onSuccess) {
    return useQuery(
      ['get-legacy-reward-event', { take, page }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-reward-event`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyRewardEventById({ legacyRewardEventId }, onSuccess) {
    return useQuery(
      ['get-legacy-reward-event-by-id', { legacyRewardEventId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-reward-event/${queryKey[1].legacyRewardEventId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!legacyRewardEventId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyRewardEvent(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(`/legacy-reward-event`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyRewardEvent(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-reward-event/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyRewardEventList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/legacy-reward-event-list/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyRewardEvent(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-reward-event/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyRewardEventPartnerLists(
    {
      take,
      page,
      legacyRewardEventId,
      searchText,
      isRewardRight,
      rewardTitle,
      isGetReward,
      isJoinEvent,
    },
    onSuccess
  ) {
    return useQuery(
      [
        'get-legacy-reward-event-list',
        {
          take,
          page,
          legacyRewardEventId,
          searchText,
          isRewardRight,
          rewardTitle,
          isGetReward,
          isJoinEvent,
        },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/legacy-reward-event-list`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              eventId: queryKey[1].legacyRewardEventId,
              ...(queryKey[1].searchText && {
                searchText: queryKey[1].searchText,
              }),
              ...(queryKey[1].isRewardRight && {
                isRewardRight: queryKey[1].isRewardRight === 1 ? true : false,
              }),
              ...(queryKey[1].rewardTitle && {
                rewardTitle: queryKey[1].rewardTitle,
              }),
              ...(queryKey[1].isGetReward && {
                isGetReward: queryKey[1].isGetReward === 1 ? true : false,
              }),
              ...(queryKey[1].isJoinEvent && {
                isJoinEvent: queryKey[1].isJoinEvent === 1 ? true : false,
              }),
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!legacyRewardEventId,
      }
    );
  },
  useQueryGetLegacyRewardEventListById({ legacyRewardEventListId }, onSuccess) {
    return useQuery(
      ['get-legacy-reward-event-list-by-id', { legacyRewardEventListId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-reward-event-list/${queryKey[1].legacyRewardEventListId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!legacyRewardEventListId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyRewardEventList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-reward-event-list`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyRewardEventList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-reward-event-list/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUploadLegacyRewardEventListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async ({ data, legacyRewardEventId }) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-reward-event-list/upload-excel/${legacyRewardEventId}`,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
  useMutationDownloadLegacyRewardEventListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async (data) => {
        try {
          const res = await fitStudioRequest.get(
            `/legacy-reward-event-list/download-excel/by-filter`,
            {
              params: {
                eventId: data.legacyRewardEventId,
                ...(data.searchText && {
                  searchText: data.searchText,
                }),
                ...(data.isRewardRight && {
                  isRewardRight: data.isRewardRight === 1 ? true : false,
                }),
                ...(data.rewardTitle && {
                  rewardTitle: data.rewardTitle === 1 ? true : false,
                }),
                ...(data.isGetReward && {
                  isGetReward: data.isGetReward === 1 ? true : false,
                }),
                ...(data.isJoinEvent && {
                  isJoinEvent: data.isJoinEvent === 1 ? true : false,
                }),
                page: data.page,
                take: data.take,
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'list-papers.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
  useMutationCreateLegacyNotificationPartnerList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/legacy-notification-partner-list`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyNotificationPartnerList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/legacy-notification-partner-list/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
};

export default fitLegacyServices;
