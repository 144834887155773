import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const userQueryDefaultValues = {
  idCard: '',
  fitNo: '',
  name: '',
};

export const userQueryFormSchema = () => {
  return yupResolver(
    yup
      .object()
      .shape(Object.assign({}))
      .test('selectedOnce', 'Error', function (value) {
        const { createError } = this;
        if (
          value.idCard ||
          value.fitNo ||
          value.name ||
          value.legacyPartnerCode
        ) {
          return true;
        } else {
          return createError({
            path: 'searchError',
            message: 'กรุณาใส่ข้อมูลอย่างใดอย่างหนึ่ง',
          });
        }
      })
  );
};

export const userPasswordDefaultValues = {
  password: '',
};

export const userPasswordFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      password: yup.string().min(4, 'อย่างน้อย 4 ตัวอักษร'),
    })
  );
};

export const userUpdateDefaultValues = {
  firstName: '',
  lastName: '',
  birthDate: moment()
};

export const userUpdateFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      firstName:yup.string().required('กรุณาใส่ชื่อ'),
      lastName:yup.string().required('กรุณาใส่ชื่อนามสกุล'),
      birthDate:yup.string().required('กรุณาใส่วันเกิด'),
    })
  );
};