import React, { createContext, useContext, useRef } from "react";

const PaymentContext = createContext();

export const usePaymentMutate = () => {
  return useContext(PaymentContext);
};

export const PaymentMutateProvider = ({ children }) => {
  const kPaymentRef = useRef(null);

  return (
    <PaymentContext.Provider value={{ kPaymentRef }}>
      {children}
    </PaymentContext.Provider>
  );
};
