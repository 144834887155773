import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const legacyQuizCategoryFormDefaultValues = {
  title: '',
  imageUrl: '',
  display: true,
};

export const legacyQuizCategoryFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      title: yup.string().required('กรุณากรอกข้อมูล'),
      imageUrl: yup.string().required('กรุณากรอกข้อมูล'),
      display: yup.boolean().defined(),
    })
  );
};

export const legacyQuizCategoryProductFormDefaultValues = {
  title: '',
  imageUrl: '',
  display: true,
  rewardId: '',
};

export const legacyQuizCategoryProductFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      title: yup.string().required('กรุณากรอกข้อมูล'),
      imageUrl: yup.string().required('กรุณากรอกข้อมูล'),
      display: yup.boolean().defined(),
      rewardId: yup.string().required('กรุณากรอกข้อมูล'),
    })
  );
};

export const legacyQuizCategoryProductQuestionFormDefaultValues = {
  question: '',
  imageUrl: '',
  active: true,
  answers: [
    {
      answer: '',
      iconImageUrl: '',
      isCorrect: false,
    },
    {
      answer: '',
      iconImageUrl: '',
      isCorrect: false,
    },
    {
      answer: '',
      iconImageUrl: '',
      isCorrect: false,
    },
  ],
};

export const legacyQuizCategoryProductQuestionFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      question: yup.string().required('กรุณากรอกข้อมูล'),
      imageUrl: yup.string().defined(),
      active: yup.boolean().defined(),
      answers: yup
        .array(
          yup.object().shape({
            answer: yup.string().required('กรุณากรอกข้อมูล'),
            iconImageUrl: yup.string().defined(),
            isCorrect: yup.boolean().defined(),
          })
        )
        .min(1)
        .required(),
    })
  );
};

export const legacyQuizRewardFormDefaultValues = {
  title: '',
  description: '',
  frontImageUrl: '',
  greyFrontImageUrl: '',
  max: 0,
  startDate: moment(),
  endDate: moment(),
  display: true,
};

export const legacyQuizRewardFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      title: yup.string().required('กรุณากรอกข้อมูล'),
      description: yup.string().defined('กรุณากรอกข้อมูล'),
      frontImageUrl: yup.string().required('กรุณากรอกข้อมูล'),
      greyFrontImageUrl: yup.string().required('กรุณากรอกข้อมูล'),
      max: yup.number().min(1).required('กรุณากรอกข้อมูล'),
      startDate: yup.date().required('กรุณากรอกข้อมูล'),
      endDate: yup.date().required('กรุณากรอกข้อมูล'),
      display: yup.boolean().defined(),
    })
  );
};

export const userRewardQueryDefaultValues = {
  memberCode: '',
  memberType: '',
  isPartner: undefined,
  isMax: undefined,
};

export const userQueryDefaultValues = {
  memberCode: '',
  memberType: '',
  isPartner: undefined,
  selectedDate: moment(),
  maxReceived: undefined,
};
