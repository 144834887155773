/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { Button, Col, Radio, Row, Space } from "antdV4";
import _ from "lodash";
import numeral from "numeral";
import React, { useContext, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import useModalHook from "../../../../../../../hooks/useModalHook";
import { VoucherContext } from "../../reducers";
import CategoryModal from "./CategoryModal";

const CATEGORY_TYPE = {
  all: "all",
  custom: "custom",
};

const CategoryCard = ({
  rowNumber = 1,
  category = "Category name",
  onDelete = () => {},
}) => {
  return (
    <div
      style={{
        height: "42px",
        border: "1px solid #97D700",
        borderRadius: "6px",
        fontSize: "16px",
      }}
      className="w-100 mb-2 d-flex align-items-center overflow-hidden"
    >
      <div
        style={{
          minWidth: "60px",
          width: "60px",
          backgroundColor: "#CBEB80",
        }}
        className="h-100 d-flex justify-content-center align-items-center overflow-hidden cursor-default"
      >
        {numeral(rowNumber).format("0,0")}
      </div>
      <div
        style={{
          width: "100%",
          padding: "8px",
          display: "grid",
          gridTemplateColumns: "1fr 50px",
          gap: "8px",
        }}
      >
        <div className="d-flex align-items-center text-truncate">
          {category}
        </div>
        <Button type="danger" onClick={onDelete}>
          <RiDeleteBin6Line size={18} />
        </Button>
      </div>
    </div>
  );
};

// settig voucher category
const Category = ({ getFieldDecorator, getFieldsValue, setFieldsValue }) => {
  const { state, dispatch } = useContext(VoucherContext);
  const categoryGroup = state.categoryGroup;
  const fields = getFieldsValue();

  const {
    open: openCategory,
    handleOpen: _handleOpenCategory,
    handleClose: _handleCloseCategory,
  } = useModalHook();

  useEffect(() => {
    if (!fields.category_type) {
      setFieldsValue({ category_type: CATEGORY_TYPE.all });
    }
  }, [fields]);

  return (
    <>
      <Form.Item className="mb-0">
        {getFieldDecorator("category_type", {
          initialValue: CATEGORY_TYPE.all,
          onChange: (e) => {
            setFieldsValue({ category_type: e.target.value });
          },
        })(
          <Radio.Group>
            <Space direction="vertical">
              <Radio className="mb-2" value={CATEGORY_TYPE.all}>
                ทุกกลุ่มสินค้า
              </Radio>
              <Radio value={CATEGORY_TYPE.custom}>กำหนดกลุ่มสินค้า</Radio>
            </Space>
          </Radio.Group>
        )}
      </Form.Item>
      {fields?.category_type == CATEGORY_TYPE.custom && (
        <>
          <Row className="my-4">
            <Button type="primary" size="large" onClick={_handleOpenCategory}>
              + เพิ่มกลุ่มสินค้า
            </Button>
          </Row>
          <Row>
            <Col span={24}>
              {categoryGroup.map((item, index) => (
                <CategoryCard
                  key={`category-${index}`}
                  rowNumber={index + 1}
                  category={item?.name}
                  onDelete={() => {
                    const filter = _.filter(
                      categoryGroup,
                      (beforeItem) => beforeItem != item
                    );
                    dispatch({
                      type: "REMOVE_CATEGORY_GROUP",
                      payload: filter,
                    });
                  }}
                />
              ))}
            </Col>
          </Row>
        </>
      )}
      <CategoryModal
        open={openCategory}
        onCancel={_handleCloseCategory}
        getFieldDecorator={getFieldDecorator}
        getFieldsValue={getFieldsValue}
        setFieldsValue={setFieldsValue}
      />
    </>
  );
};

export default Category;
