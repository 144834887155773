import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Error404 from '../../Error404';
import Maldives from './maldives'

export default ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.path}/maldives/:code`} component={Maldives} />
            <Route component={Error404} />
        </Switch>
    )
}