/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Spin } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { PageSettings } from "../../../config/page-settings";
import { _ } from "../../../utils";

const SidebarRender = memo(({ data, active, expanded }) => {
  const [expand, setExpand] = useState([]);

  const icon = data.icon && <i className={data.icon} />;
  const img = data.img && (
    <div className="icon-img">
      <img src={data.img} alt="" />
    </div>
  );
  const caret = data.children && !data.badge && <b className="caret" />;
  const label = data.label && (
    <span className="label label-theme m-l-5">{data.label}</span>
  );
  const badge = data.badge && (
    <span className="badge pull-right">{data.badge}</span>
  );
  const title = data.title && (
    <span>
      {data.title} {label}
    </span>
  );

  const handleExpand = (id) => {
    let newExpand = [...expand];
    if (_.includes(newExpand, id)) _.remove(newExpand, (n) => n === id);
    else newExpand = [...newExpand, id];
    setExpand(newExpand);
  };

  useEffect(() => {
    setExpand(expanded);
  }, [expanded]);

  return (
    <PageSettings.Consumer>
      {({
        handleSidebarOnMouseOver,
        handleSidebarOnMouseOut,
        pageSidebarMinified,
      }) => (
        <li
          className={
            (_.includes(active, data.id) ? "active " : "") +
            (data.children && _.includes(expand, data.id)
              ? "expand "
              : "closed") +
            (data.children ? "has-sub " : "")
          }
        >
          {data.children ? (
            <a
              onMouseOver={(e) => handleSidebarOnMouseOver(e, data)}
              onMouseOut={(e) => handleSidebarOnMouseOut(e, data)}
              onClick={() => handleExpand(data.id)}
            >
              {caret} {img} {icon} {badge} {title}
            </a>
          ) : (
            <Link to={data.path}>
              {caret} {img} {icon} {badge} {title}
            </Link>
          )}
          {data.children && (
            <ul
              className={
                "sub-menu " +
                (_.includes(expand, data.id) && !pageSidebarMinified
                  ? "d-block"
                  : "d-none")
              }
            >
              {_.map(data.children, (n, i) => (
                <SidebarRender
                  key={i}
                  data={n}
                  active={active}
                  expanded={expanded}
                />
              ))}
            </ul>
          )}
        </li>
      )}
    </PageSettings.Consumer>
  );
});

const Sidebar = () => {
  const location = useLocation();
  const [active, setActive] = useState([]);
  const [expand, setExpand] = useState([]);
  const menus = useSelector((state) => state.menu.menus);
  const { loading, error } = useSelector((state) => state.menu.fetch);

  const handleActiveExpend = (menu, keys = []) =>
    _.forEach(menu, (n) => {
      if (n.children) handleActiveExpend(n.children, [...keys, n.id]);
      else if (n.path === location.pathname) setActive([...keys, n.id]);
    });

  useEffect(() => {
    menus && handleActiveExpend(menus);
  }, [menus, location]);

  useEffect(() => {
    if (expand.length === 0) setExpand(active);
  }, [active]);

  if (error) return <Alert icon="error" type="error" message={error} />;

  if (loading)
    return (
      <div className="w-100 text-center">
        <Spin spinning className="my-3" size="small" />
      </div>
    );

  return (
    <ul className="nav">
      <li className="nav-header">เมนู</li>
      {_.map(menus, (n) => (
        <SidebarRender key={n.id} data={n} active={active} expanded={expand} />
      ))}
    </ul>
  );
};

export default Sidebar;
