import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

const RenderBomDetail = ({ list, qty = 1 }) => {
    const [expand, setExpand] = useState(true);
    return (
        <>
            <p className="m-0 pl-3">
                <span className="cursor-pointer" onClick={() => setExpand(expand ? false : true)}>
                    ประกอบด้วย {expand ? <FaAngleUp /> : <FaAngleDown />}
                </span>
            </p>
            {
                expand && list.map((n, i) => (
                    <p className="m-0 pl-3" key={i}>
                        {`${i + 1}.  ${n.ref_product_name ? n.ref_product_name : n.product_name ? n.product_name : n.ref_product_code ? n.ref_product_code : '-'} (${n.qty * qty})`}
                        {/* {`${i + 1}.  ${n.ref_product_name ? n.ref_product_name : n.ref_product_code ? n.ref_product_code : n.product_name} (${n.qty * qty})`} */}
                    </p>
                ))
            }
        </>
    );
};

export default React.memo(RenderBomDetail);
