import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { classScheduleFormDefaultValues } from '../dto/class';
import { useParams } from 'react-router';
import ClassScheduleForm from '../form/ClassScheduleForm';
import { useQueryClient } from '@tanstack/react-query';

const ClassScheduleModal = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit, reset } = useFormContext();
  const queryClient = useQueryClient();

  const { execute: createClassSchedule } = useAsync(
    fitStudioService.createClassSchedule,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['get-class-schedule-by-class-id'],
          refetchType: 'all',
        });
        AlertSuccess('เพิ่มตารางเรียนสำเร็จ');
        reset(classScheduleFormDefaultValues);
        handleClose();
      },
    }
  );

  const { execute: editClassSchedule } = useAsync(
    fitStudioService.editClassSchedule,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['get-class-schedule-by-class-id'],
          refetchType: 'all',
        });
        AlertSuccess('แก้ไขตารางเรียนสำเร็จ');
        reset(classScheduleFormDefaultValues);
        handleClose();
      },
    }
  );

  const _HandleSubmit = handleSubmit((data) => {
    const classScheduleData = {
      classId: params.classId,
      trainerId: data.trainerId,
      title: data.title,
      imageUrl: data.imageUrl,
      description: data.description,
      max: data.max,
      start_date: new Date(data.start_date),
      end_date: new Date(data.end_date),
    };

    if (watch('formType') === 'create') {
      createClassSchedule(classScheduleData);
    } else if (watch('formType') === 'edit') {
      editClassSchedule({ id: data.id, data: classScheduleData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create' ? 'สร้างตารางเรียน' : 'แก้ไขตารางเรียน'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <ClassScheduleForm />
    </Modal>
  );
};

export default ClassScheduleModal;
