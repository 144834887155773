/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useReducer, useState } from "react";
import { FaCog, FaCogs } from "react-icons/fa";
import styled from "styled-components";
import { useHttp } from "../../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import ImagePreview from "../components/ImagePreview";
import ImageItem from "./components/ImageItem";
import SelectBank from "./components/SelectBank";
import Tablebranch from "./components/Tablebranch";
import Tablebranchadd from "./components/Tablebranchadd";
import { BankContext, initState, reducer } from "./reducers";
const limit = 10;
const initialPreview = {
  id: null,
  visible: false,
};
const initState1 = {
  id: null,
  code: null,
  name_th: "",
  name_en: "",
  is_active: 1,
  color: null,
  file_name: null,
};

const initStateBranch = {
  id: null,
  code: null,
  name: "",
};

// const initcolor = ['B80000', 'DB3E00', 'FCCB00', '008B02', '006B76', '1273DE', '004DCF', '5300EB', 'EB9694', 'FAD0C3', 'FEF3BD', 'C1E1C5', 'BEDADC', 'C4DEF6', 'BED3F3', 'D4C4FB']

const Index = ({
  form: { getFieldDecorator, setFieldsValue, validateFields, getFieldsValue },
  match: { params },
}) => {
  const [showPreview, setShowPreview] = useState(initialPreview);
  const [page, setPage] = useState(1);
  const [pageEdit, setPageEdit] = useState(1);
  const [dataForm, setDataForm] = useState(initState1);
  const [dataFormAddBranch, setDataFormAddBranch] = useState(initStateBranch);
  const [search, setSearch] = useState("");
  const [searchEdit, setSearchEdit] = useState("");
  const [status, setStatus] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  /*const [bankcode, setBankcode] = useState([]);*/
  const [showModal, setShowModal] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  //const [showModalAddBranch, setShowModalAddBranch] = useState(false)
  const [branch, setBranch] = useState([]);
  const [firstB, setFirstB] = useState([]);
  //const [branchAdd, setBranchAdd] = useState([])
  const [lastdata, setLastdata] = useState(null);
  const [code, setCode] = useState(0);
  const [confirmAddBranch, setConfirmAddBranch] = useState(false);
  const [confirmAddBranch2, setConfirmAddBranch2] = useState(false);
  const [changeadd, setChangeAdd] = useState(false);
  const [codebank, setCodeBank] = useState("");
  const [state, dispatch] = useReducer(reducer, initState);

  const StyledBadge = styled(Select)`
    .ant-select-selection {
      background-color: #${dataForm.color};
    }
  `;
  const StyledCard = styled(Card)`
    .ant-card-body {
      background-color: #${dataForm.color};
      padding: 0;
    }
  `;
  const [loading, data, error, reload] = useHttp(
    {
      method: "get",
      url: `${URL_API}/setting/master/bank`,
      token: true,
      params: {
        limit,
        offset: (page - 1) * limit,
        search,
        status,
      },
    },
    [page, status, search]
  );

  // const [loading1, data1, error1, reload1] = useHttp(
  //   {
  //     method: "get",
  //     url: `${URL_API}/setting/master/bankbranch`,
  //     token: true,
  //     params: {
  //       limit,
  //       offset: (pageEdit - 1) * limit,
  //       search: searchEdit,
  //       bankcode: code,
  //     },
  //   },
  //   [pageEdit, code, searchEdit]
  // );

  const getData = () => {
    axios({
      method: "get",
      url: `${URL_API}/setting/master/bankall`,
      token: true,
    })
      .then((res) => {
        setDataSource(res.data.data);
      })
      .catch((err) => {
        message.error(
          err && err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.response.data
            : err.message
        );
      });
  };

  const handleClickSave1 = (data) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            headers: { Authorization: "Bearer " + getToken() },
            url: `${URL_API}/setting/master/bankadd`,
            data: {
              ...data,
            },
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                setConfirmAddBranch2(!confirmAddBranch2);
                // setChangeAdd(!changeadd)
                // setShowModalAdd(false)
                setShowModal(false);
                setCodeBank("");
                getData();
                setDataForm(initState1);
                reload();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };
  const handleClickSaveAdd = (data) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            headers: { Authorization: "Bearer " + getToken() },
            url: `${URL_API}/setting/master/bankadd`,
            data: {
              ...data,
            },
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                setConfirmAddBranch(!confirmAddBranch);
                setChangeAdd(!changeadd);
                setShowModalAdd(false);
                setCodeBank("");
                getData();
                setDataForm(initState1);
                reload();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    getData();
  }, [search, status]);
  // const handleClickSave = (values) => {
  //   let source = axios.CancelToken.source();
  //   Modal.confirm({
  //     title: "ยืนยันการทำรายการ",
  //     content: "ต้องการบันทึกข้อมูลหรือไม่",
  //     okText: "บันทึก",
  //     cancelText: "ยกเลิก",
  //     onOk() {
  //       return new Promise((resolve, reject) => {
  //         let formData = new FormData();
  //         formData.append("media", state.media.imageurl.file);
  //         let data = values;
  //         data = {
  //           ...data,
  //           description: state.data.description
  //             ? draftToHtml(
  //               convertToRaw(state.data.description.getCurrentContent())
  //             )
  //             : null,
  //           status: data.status ? "active" : "inactive",
  //           collect_point: data.collect_point ? 1 : 0,
  //           coordinate: data.coordinate ? 1 : 0,
  //           start_date: data.start_date
  //             ? moment(data.start_date).format("YYYY-MM-DD HH:mm:ss")
  //             : data.start_date,
  //           end_date: data.end_date
  //             ? moment(data.end_date).format("YYYY-MM-DD HH:mm:ss")
  //             : data.end_date,
  //           media_delate: [...state.media.delete],
  //           media_temp: [],
  //         };
  //         if (state.isTemplate) {
  //           data = {
  //             ...data,
  //             id_temp: state.data.id,
  //             media_temp: _.map(
  //               _.filter(
  //                 _.map(state.media.imageurl, (n) => state.media.imageurl),
  //                 (n) => n.file === undefined
  //               ),
  //               (m) => m.id
  //             ),
  //           };
  //         }
  //         console.log(data);
  //         let dataform = [...dataForm]
  //         formData.append("dataform", JSON.stringify(dataform));
  //         formData.append("data", JSON.stringify(data));
  //         axios({
  //           method: "post",
  //           baseURL: URL_API,
  //           url: `/setting/master/bank${dataForm.id === "0" || state.isTemplate ? "" : `/${dataForm.id}`
  //             }`,
  //           headers: { Authorization: "Bearer " + getToken() },
  //           data: formData,
  //           cancelToken: source.token,
  //         })
  //           .then((res) => {
  //             resolve(res.data);
  //           })
  //           .catch((err) => {
  //             reject(
  //               err && err.response
  //                 ? err.response.data.message
  //                   ? err.response.data.message
  //                   : err.response.data
  //                 : err.message
  //             );
  //           });
  //       })
  //         .then((value) => {
  //           setShowModalAdd(false)
  //           setShowModal(false)
  //           Modal.success({
  //             title: "สำเร็จ",
  //             content: "บันทึกข้อมูลเรียบร้อย",
  //             okText: "ปิด",
  //             onOk() {
  //               setDataForm(initState1)
  //               reload()
  //             },
  //           });
  //         })
  //         .catch((reason) => {
  //           Modal.error({
  //             title: "ผิดพลาด",
  //             content: reason,
  //             okText: "ปิด",
  //           });
  //         });
  //     },
  //   });
  // }

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
      reload();
    }
  }, [search, searchEdit, status]);

  useEffect(() => {
    reload();
  }, [status, branch]);

  useEffect(() => {
    if (pageEdit !== 1) {
      setPageEdit(1);
      //reload1();
    }
  }, [searchEdit]);

  /*useEffect(() => {
    reload1();
  }, [code])*/

  const lifetime = getFieldsValue().lifetime;

  useEffect(() => {
    if (lifetime) {
      setFieldsValue({
        end_date: null,
      });
    }
  }, [lifetime]);

  data && console.log(data);
  //data && console.log("data1", data1)
  data && console.log("code", code);
  data && console.log("dataForm", dataForm);
  data && console.log("branch", branch);
  data && console.log("databranch", dataFormAddBranch);
  console.log("dataall", dataSource);

  // const handlesetCode = (data) => {
  //   setCode(data)
  //   const sampled = [...data1]
  //   setDataSource([...dataSource, sampled])
  //   console.log(dataSource)
  // }

  const handleEng = (e) => {
    let datavalid = e;
  };

  if (error) return <Alert type="error" message={error} />;

  return (
    <BankContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>ธนาคาร</PanelHeader>
        <PanelBody>
          <Row gutter={16} className="mb-2">
            <Col md={10}>
              <Input.Search
                allowClear
                placeholder="ชื่อธนาคาร, รหัสธนาคาร"
                onSearch={(value) => setSearch(value?.trim() || "")}
              />
            </Col>
            <Col md={10}></Col>
            <Col md={4}>
              <Select
                defaultValue={status}
                className="w-100"
                onChange={(e) => setStatus(e)}
              >
                <Select.Option value={1}>ใช้งาน</Select.Option>
                <Select.Option value={0}>ไม่ใช้งาน</Select.Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={16} className="mb-2">
            <Col>
              <Button
                type="primary"
                onClick={(e) => {
                  setShowModalAdd(true);
                  //let sample = [...data2]
                  console.log("dataSource", dataSource);
                  const result = dataSource.map(function (data) {
                    return data.id;
                  });
                  const result2 = dataSource.map(function (data) {
                    return data.code;
                  });
                  setLastdata(result[result.length - 1] + 1);

                  const zeroPad = (num, places) =>
                    String(num).padStart(places, "0");
                  let last = zeroPad(
                    parseInt(result2[result2.length - 1]) + 1,
                    3
                  );
                  console.log("last", last);
                  setCodeBank(last);
                  //setDataForm({ ...dataForm, code: last })
                }}
              >
                เพิ่มธนาคาร
              </Button>
            </Col>
          </Row>
          <Table
            size="small"
            bordered
            rowKey="row_id"
            loading={loading}
            dataSource={data ? data.data : []}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              hideOnSinglePage: true,
              showSizeChanger: false,
              showLessItems: true,
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="#" dataIndex="row_number" align="center" />
            <Table.Column
              title="รูป"
              dataIndex="file_name"
              align="center"
              width={150}
              render={(text, color) => (
                <ImageItem mediaId={text} color={color} />
              )}
            />
            <Table.Column title="รหัสธนาคาร" dataIndex="code" align="center" />
            <Table.Column
              className="position-relative"
              title="ชื่อธนาคาร"
              dataIndex="name_th"
            />
            <Table.Column
              className="position-relative"
              title="ชื่อธนาคาร(ภาษาอังกฤษ)"
              dataIndex="name_en"
            />
            <Table.Column
              title="สถานะ"
              dataIndex="is_active"
              align="center"
              render={(_text, _record, index) => (
                <Typography.Text className="mr-2">
                  {_text === 1 ? "ใช้งาน" : "ไม่ใช้งาน"}
                </Typography.Text>
              )}
            />
            <Table.Column
              title={<FaCogs />}
              align="center"
              render={({
                code,
                color,
                file_name,
                is_active,
                name_en,
                name_th,
                row_id,
                branch,
              }) => (
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-pv border-pv"
                  onClick={() => {
                    setShowModal(true);
                    setBranch(branch);
                    setFirstB(branch);
                    console.log("branch", branch);
                    setDataForm({
                      ...dataForm,
                      id: row_id,
                      code,
                      name_th,
                      name_en,
                      is_active,
                      color,
                      file_name,
                    });
                    setCode(code); //handlesetCode(code)
                  }}
                >
                  <FaCog />
                </Button>
              )}
            />
          </Table>
          <Modal
            title={"แก้ไขธนาคาร"}
            visible={showModal}
            onCancel={() => {
              setShowModal(false);
              setDataForm(initState1);
              setPageEdit(1);
              setCode(0);
              setChangeAdd(!changeadd);
            }}
            width={800}
            footer={false}
          >
            <Row gutter={[16, 16]}>
              <Col md={12}>
                <Row>
                  <Col>
                    <label>ชื่อธนาคาร</label>
                    <Input
                      value={dataForm.name_th}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, name_th: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>รหัสธนาคาร</label>
                    <Input
                      disabled
                      value={dataForm.code}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, code: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16}></Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Col>
                    <label>ชื่อธนาคาร (ภาษาอังกฤษ)</label>
                    <Input
                      value={dataForm.name_en}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, name_en: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} mr={0}>
                    <label className="w-100">สถานะ</label>
                    <Row gutter={16}>
                      <Col md={24}>
                        <Select
                          defaultValue={status}
                          className={`w-100`}
                          onChange={(e) => {
                            setDataForm({ ...dataForm, is_active: e });
                          }}
                        >
                          <Select.Option value={1}>ใช้งาน</Select.Option>
                          <Select.Option value={0}>ไม่ใช้งาน</Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} ml={0}>
                    <label className="w-100">โลโก้</label>
                    <Row gutter={17}>
                      <Col md={24}>
                        {showModal && (
                          <>
                            <SelectBank
                              callfront={(e) => {
                                console.log("ธนาคาร", e);
                              }}
                              withIcon
                              filterOption={(input, option) =>
                                option.props.children.indexOf(
                                  input.toLowerCase()
                                ) >= 0
                              }
                              onChange={(e, option) => {
                                console.log(e);
                                setDataForm({
                                  ...dataForm,
                                  file_name: option.props.source.file_name,
                                  color: option.props.source.color,
                                });
                              }}
                              reloadselect={reload}
                              statusselect={status}
                              imgsrc={dataForm.file_name}
                              imgcode={dataForm.code}
                              form={dataForm}
                              visible={showModal}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[8, 16]}>
              <Col md={8} mt={3} className="d-flex justify-content-end"></Col>
              {/* <Divider className="py-3">อัปโหลดรูปธนาคาร</Divider> */}
              {/* <StyledCard md={4} className="d-inline-block justify-content-start p-0 w-0"> */}
              {/* <ImageUpload data={data?.data} color={dataForm.color} /> */}
              {/* </StyledCard> */}
              {/* <img alt="null"src={state.media.imageurl}></img> */}
            </Row>
            <Tablebranch
              code={code}
              searchEdit={searchEdit}
              setSearchEdit={setSearchEdit}
              branch={branch}
              visible={showModal}
              changeadd={changeadd}
              reloads={reload}
              setBranch={setBranch}
              firstB={firstB}
              confirm={confirmAddBranch2}
            />
            <Row className="p-5" gutter={16} type="flex" justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    if (dataForm.code === null) {
                      message.warning("กรุณากรอกรหัสธนาคาร");
                    } else if (!dataForm.name_th.match(/^[ก-๏\s]+$/)) {
                      message.warning("กรุณากรอกชื่อให้ถูกต้อง");
                    } else if (!dataForm.name_en.match(/^[a-zA-Z\s]+$/)) {
                      message.warning("กรุณากรอกชื่อให้ถูกต้อง");
                    } else {
                      handleClickSave1(dataForm);
                    }
                  }}
                >
                  บันทึก
                </Button>
              </Col>
              <Col>
                <Button
                  type="danger"
                  onClick={() => {
                    setShowModal(false);
                    setChangeAdd(!changeadd);
                    setPageEdit(1);
                    setCode(0);
                    setDataForm(initState1);
                    setSearchEdit("");
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            title={"เพิ่มธนาคาร"}
            visible={showModalAdd}
            onCancel={() => {
              setShowModalAdd(false);
              setDataForm(initState1);
              setChangeAdd(!changeadd);
              setCodeBank(
                ""
              ) /*dispatch({ type: "DEL_MEDIA", payload: { media_id: data.media_id } })*/;
            }}
            //onOk={() => { handleClickSave(dataForm) }}
            width={800}
            footer={false}
          >
            <Row gutter={[16, 16]}>
              <Col md={12}>
                <Row>
                  <Col>
                    <label>ชื่อธนาคาร</label>
                    <Input
                      value={dataForm.name_th}
                      onChange={(e) => {
                        // const { value } = e.target;
                        // const reg = /^[ก-๏\s]+$/;
                        // if (value.match(reg)) {
                        //   setDataForm({ ...dataForm, name_th: value })
                        // } else {
                        //   setDataForm({ ...dataForm, name_th: dataForm.name_th+'' })
                        // }
                        setDataForm({ ...dataForm, name_th: e.target.value });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>รหัสธนาคาร</label>
                    <Input
                      required
                      type="number"
                      value={dataForm.code}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, code: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Col>
                    <label>ชื่อธนาคาร (ภาษาอังกฤษ)</label>
                    <Input
                      value={dataForm.name_en}
                      onChange={(e) => {
                        // const { value } = e.target;
                        // const reg = /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/;
                        // if (value.match(reg)) {
                        //   setDataForm({ ...dataForm, name_en: value })
                        // } else {
                        //   setDataForm({ ...dataForm, name_en: '' })
                        // }
                        setDataForm({ ...dataForm, name_en: e.target.value });
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} mr={0}>
                    <label className="w-90">สถานะ</label>
                    <Row gutter={16}>
                      <Col md={24}>
                        <Select
                          defaultValue={status}
                          className={`w-100`}
                          onChange={(e) => {
                            setDataForm({ ...dataForm, is_active: e });
                          }}
                        >
                          <Select.Option value={1}>ใช้งาน</Select.Option>
                          <Select.Option value={0}>ไม่ใช้งาน</Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} ml={0}>
                    <label className="w-90">โลโก้</label>
                    <Row gutter={17}>
                      <Col className="t1" md={24}>
                        {showModalAdd && (
                          <>
                            <SelectBank
                              callfront={(e) => {
                                console.log("ธนาคาร", e);
                              }}
                              withIcon
                              filterOption={(input, option) =>
                                option.props.children.indexOf(
                                  input.toLowerCase()
                                ) >= 0
                              }
                              onChange={(e, option) => {
                                console.log(e);
                                setDataForm({
                                  ...dataForm,
                                  file_name: option.props.source.file_name,
                                  color: option.props.source.color,
                                });
                              }}
                              reloadselect={reload}
                              statusselect={status}
                              form={dataForm}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Tablebranchadd
              code={code}
              searchEdit={searchEdit}
              lastdata={lastdata}
              confirm={confirmAddBranch}
              changeadd={changeadd}
              codebank={codebank}
              form={dataForm}
            />

            <Row className="p-5" gutter={16} type="flex" justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    // validateFields((err, values) => {
                    //   if (!err) {
                    //     handleClickSave(values);
                    //   }
                    // });
                    // (!dataForm.code === null) ?
                    //   handleClickSaveAdd(dataForm) : message.warning('กรุณากรอกรหัสธนาคาร');
                    if (dataForm.code === null) {
                      message.warning("กรุณากรอกรหัสธนาคาร");
                    } else if (!dataForm.name_th.match(/^[ก-๏\s]+$/)) {
                      message.warning("กรุณากรอกชื่อให้ถูกต้อง");
                    } else if (!dataForm.name_en.match(/^[a-zA-Z\s]+$/)) {
                      message.warning("กรุณากรอกชื่อให้ถูกต้อง");
                    } else {
                      handleClickSaveAdd(dataForm);
                    }
                  }}
                >
                  บันทึก
                </Button>
              </Col>
              <Col>
                <Button
                  type="danger"
                  onClick={() => {
                    setShowModalAdd(false);
                    setDataForm(initState1);
                    setChangeAdd(!changeadd);
                    setCodeBank("");
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
            </Row>
          </Modal>
          <ImagePreview
            {...showPreview}
            onCancel={() => setShowPreview(initialPreview)}
          />
        </PanelBody>
      </Panel>
    </BankContext.Provider>
  );
};

const FormWrapped = Form.create()(Index);
//export default Index
export default FormWrapped;
