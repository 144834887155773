/* eslint-disable no-unused-vars */
import { Button, Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useApi } from "../../../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector
} from "../../../../../../redux/slices/marketing/product";
import { URL_API } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { ImgStyles } from "../../../components/mwStyles";

export default function CardList({ history, match }) {
  const myCard = [1, 2, 3, 4];
  const notFoundImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBTEghTW2XOwmp-PbaaC76eKvC7jH61oZfDQ&usqp=CAU";

  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;
  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/home/ecosystem/card-ecosystem",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  console.log(list?.fetch?.data);
  const CardColStyles = {
    margin: "8px",
    padding: "16px",
    overflow: "hidden",
    flexDirection: "column",
    display: "flex",
    width: "280px",
    minWidth: "280px",
    height: "440px",
    backgroundColor: "white",
  };

  const CardRowStyles = {
    width: "100%",
    height: "200px",
    overflow: "hidden",
    justifyContent: "center",
    display: "flex",
    border: "1px solid #E8E8E8",
    borderRadius: "8px",
  };

  const CardTitleStyles = {
    textAlign: "center",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    width: "100%",
    height: "48px",
    overflow: "hidden",
  };

  const CardContentStyles = {
    textAlign: "center",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    width: "100%",
    height: "75px",
    overflow: "hidden",
  };

  function CardComponent(props) {
    return (
      <Row>
        <Col style={CardColStyles}>
          <Row style={CardRowStyles}>
            {props.data ? (
              list?.fetch?.data[props.index].desktop_url != null ? (
                <img
                  src={`${list?.fetch?.data[props.index].desktop_url}`}
                  alt={`${list?.fetch?.data[props.index].desktop_url}`}
                  style={ImgStyles}
                />
              ) : (
                ""
              )
            ) : (
              "ไม่พบข้อมูล"
            )}
          </Row>
          <Row className="mt-3">
            <div style={CardTitleStyles}>
              <h4>
                {props.data ? (
                  list?.fetch?.data[props.index].title != null ? (
                    `${list?.fetch?.data[props.index].title}`
                  ) : (
                    <p style={{ color: "#d7d7d7" }}>ไม่มี title </p>
                  )
                ) : (
                  "ไม่พบข้อมูล"
                )}
              </h4>
            </div>
          </Row>
          <Row>
            <div style={CardContentStyles}>
              {props.data ? (
                list?.fetch?.data[props.index].content != null ? (
                  `${list?.fetch?.data[props.index].content}`
                ) : (
                  <p style={{ color: "#d7d7d7" }}>ไม่มี content </p>
                )
              ) : (
                "ไม่พบข้อมูล"
              )}
            </div>
          </Row>
          <Link
            to={`${match.path}/${
              props.data ? list?.fetch?.data[props.index].id : "add"
            }`}
          >
            <Button className="mt-2" type="link" block onClick={() => {}}>
              {props.data ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
            </Button>
          </Link>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeader>Card Ecosystem</PanelHeader>
        <PanelBody>
          <Row style={{ width: "100%", overflow: "hidden" }}>
            <Row
              md={24}
              gutter={16}
              style={{
                backgroundColor: "#F0F0F0",
                padding: "16px",
              }}
            >
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={0}
                  data={list?.fetch?.data.length > 0 ? true : false}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={1}
                  data={list?.fetch?.data.length > 1 ? true : false}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={2}
                  data={list?.fetch?.data.length > 2 ? true : false}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={3}
                  data={list?.fetch?.data.length > 3 ? true : false}
                />
              </Col>
            </Row>
            <Row
              md={24}
              gutter={16}
              style={{
                backgroundColor: "#F0F0F0",
                padding: "16px",
              }}
            >
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={4}
                  data={list?.fetch?.data.length > 4 ? true : false}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={5}
                  data={list?.fetch?.data.length > 5 ? true : false}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={6}
                  data={list?.fetch?.data.length > 6 ? true : false}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <CardComponent
                  index={7}
                  data={list?.fetch?.data.length > 7 ? true : false}
                />
              </Col>
            </Row>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
