/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axios, getToken, URL_API } from "../utils";

export const getCancelToken = () => {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
};

export const useApi = (
  {
    method = "get",
    baseURL = URL_API,
    url = "",
    token = false,
    params = {},
    data = null,
    onSuccess = null,
    initialLoad = true,
  },
  dependencies = []
) => {
  const [loading, setLoading] = useState(false);
  const [fetch, setFetch] = useState(null);
  const [error, setError] = useState(null);

  const source = getCancelToken();

  const reload = () => {
    setLoading(true);

    axios({
      method,
      baseURL,
      url,
      headers: token ? { Authorization: "Bearer " + getToken() } : null,
      params,
      data,
      cancelToken: source.token,
    })
      .then((res) => {
        setLoading(false);
        if (onSuccess) {
          setFetch(onSuccess(res.data));
        } else {
          setFetch(res.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setLoading(false);
          setError(
            error && error.response
              ? error.response.data.message
                ? error.response.data.message
                : error.response.data
              : error.message
          );
        }
      });
  };

  useEffect(() => {
    if (initialLoad) {
      reload();
    }

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, dependencies);

  return {
    loading,
    fetch,
    error,
    reload,
  };
};
