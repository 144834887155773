import React, { useEffect, useReducer, useState } from "react";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { getUser, URL_API } from "../../../../../utils";
import { useHttp } from "../../../../../hooks/http";
import { FaRegCheckCircle } from "react-icons/fa";
import numeral from "numeral";
import * as XLSX from "xlsx-js-style";
const CheckboxGroup = Checkbox.Group;
const { Search } = Input;

const Index = () => {
  const { Text } = Typography;
  const toDay = moment().format("DD/MM/YYYY");
  const user = getUser();
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: user ? [user.branch_code] : [""],
      query: "",
      page: 1,
      limit: 10,
    },
  };

  const [page, setPage] = useState(1);
  const limit = 50;

  const projectOption = [
    { label: "RTB", value: "RTB" },
    { label: "CD", value: "CD" },
    { label: "CB", value: "CB" },
  ];
  const defaultProject = ["RTB", "CD", "CB"];

  const eftTypeOption = [
    { label: "บุคคลทั่วไป", value: "0" },
    { label: "นิติบุคคล", value: "1" },
  ];
  const defaultEftType = ["0", "1"];

  const [state, dispatch] = useReducer(reducer, initState);

  const [checkedProjectType, setCheckedProjectType] = useState(defaultProject);
  const [checkedEftType, setCheckedcheckedEftType] = useState(defaultEftType);

  const onChangeProjectList = (list) => {
    setCheckedProjectType(list);
  };
  const onChangeEftType = (list) => {
    setCheckedcheckedEftType(list);
  };
  const getLabelFromValueEft = (value) => {
    const option = eftTypeOption.find((option) => option.value === value);
    return option ? option.label : null;
  };
  const getLabelFromValueProject = (value) => {
    const option = projectOption.find((option) => option.value === value);
    return option ? option.label : null;
  };
  const [totalRtb, setTotalRtb] = useState(0);
  const [totalCd, setTotalCd] = useState(0);
  const [totalCb, setTotalCb] = useState(0);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/commission/commission-income`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        projectType: checkedProjectType,
        eftType: checkedEftType,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );
  useEffect(() => {
    setTotalCd(data?.data ? data.total_cd : 0);
    setTotalRtb(data?.data ? data.total_rtb : 0);
    setTotalCb(data?.data ? data.total_cb : 0);
  }, [data]);

  const exportToExcel = () => {
    const datasource = data ? data?.data : [];

    if (datasource.length === 0) {
      return message.warning("ไม่พบข้อมูล");
    } else {
      const title_table = [
        "ลำดับ",
        "วันที่บิล",
        "เลขที่บิล",
        "สถานะใบเสร็จ",
        "วันที่เกิดรายได้",
        "รหัสสมาชิก",
        "ชื่อ - สกุล",
        "เลขประจำตัวผู้เสียภาษี",
        "สถานะสมาชิก",
        "ชื่อบริษัท",
        "Vat",
        "ประเภทรายได้",
        "",
        "",
        "รวมรายได้",
      ];

      let excel_data = [
        ["รายงานรายได้ที่เกิดขึ้น"],
        [
          `ช่วงวันที่เกิดรายได้  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `ประเภทรายได้: ${
            checkedProjectType === ""
              ? "ทั้งหมด"
              : checkedProjectType.map((value) =>
                  getLabelFromValueProject(value)
                )
          }`,
          "",
          "",
          `ประเภทบัญชี: ${
            checkedEftType === ""
              ? "ทั้งหมด"
              : checkedEftType.map((value) => getLabelFromValueEft(value))
          }`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
          "",
          "",
        ],
        [],
        title_table,
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          `CD (รวม ${numeral(totalCd).format("0,0.00")})`,
          `RTB (รวม ${numeral(totalRtb).format("0,0.00")})`,
          `CB (รวม ${numeral(totalCb).format("0,0.00")})`,
          "",
        ],
      ];
      datasource.forEach((n) => {
        excel_data = [
          ...excel_data,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            {
              v: moment(n.document_date).format("DD/MM/YYYY"),
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.document_no === null ? "" : n.document_no,
              s: { alignment: { horizontal: "left" } },
            },
            { v: n.bill_status, s: { alignment: { horizontal: "left" } } },
            {
              v: moment(n.trans_date).format("DD/MM/YYYY"),
              s: { alignment: { horizontal: "center" } },
            },
            { v: n.partner_code, s: { alignment: { horizontal: "left" } } },
            { v: n.partner_name, s: { alignment: { horizontal: "left" } } },
            {
              v: n.id_card ? n.id_card : "-",
              s: { alignment: { horizontal: "center" } },
            },
            { v: n.status, s: { alignment: { horizontal: "center" } } },
            {
              v: n.company_name ? n.company_name : "-",
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.is_vat ? "✓" : "",
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.cd,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.rtb,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.cb,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.total_amount,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
          ],
        ];
      });

      const merge = [
        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
        { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
        { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },
        { s: { r: 3, c: 4 }, e: { r: 4, c: 4 } },
        { s: { r: 3, c: 5 }, e: { r: 4, c: 5 } },
        { s: { r: 3, c: 6 }, e: { r: 4, c: 6 } },
        { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
        { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
        { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },
        { s: { r: 3, c: 10 }, e: { r: 4, c: 10 } },
        { s: { r: 3, c: 11 }, e: { r: 3, c: 13 } },
        { s: { r: 3, c: 14 }, e: { r: 4, c: 14 } },

        { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
        { s: { r: 1, c: 9 }, e: { r: 1, c: 11 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 25 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
      ];
      const ceter_style = {
        alignment: { horizontal: "center", vertical: "center" },
        fill: {
          fgColor: { rgb: "e5e5e5" }, // สีพื้นหลังเป็นสีเหลือง (รหัสสี RGB)
        },
        // border: {
        //   top: { style: "thin", color: { rgb: "000000" } },
        //   bottom: { style: "thin", color: { rgb: "000000" } },
        //   left: { style: "thin", color: { rgb: "000000" } },
        //   right: { style: "thin", color: { rgb: "000000" } },
        // },
      };

      const ws = XLSX.utils.aoa_to_sheet(excel_data);

      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = ceter_style;
      ws["B4"].s = ceter_style;
      ws["C4"].s = ceter_style;
      ws["D4"].s = ceter_style;
      ws["E4"].s = ceter_style;
      ws["F4"].s = ceter_style;
      ws["G4"].s = ceter_style;
      ws["H4"].s = ceter_style;
      ws["I4"].s = ceter_style;
      ws["J4"].s = ceter_style;
      ws["K4"].s = ceter_style;
      ws["L4"].s = ceter_style;
      ws["N4"].s = ceter_style;
      ws["O4"].s = ceter_style;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(
        wb,
        `รายงานรายได้ที่เกิดขึ้น_${moment().format("YYMMDD_HHmm")}.xlsx`
      );
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานรายได้ที่เกิดขึ้น</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} align="right">
              <Text strong>ค้นหา :</Text>
            </Col>
            <Col span={6}>
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, เลขที่บิล"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                // onSearch={reload}
                // onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={2} align="right">
              <Text strong>จากวันที่ :</Text>
            </Col>
            <Col span={3} className=" p-r-15">
              <DatePicker
                className="w-100"
                placeholder={toDay}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from:
                        moment(e).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                    },
                  })
                }
              />
            </Col>
            <Col align="right">
              <Text strong>ถึงวันที่ :</Text>
            </Col>
            <Col span={3} className=" p-r-15">
              <DatePicker
                className="w-100"
                placeholder={toDay}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_to:
                        moment(e).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                    },
                  })
                }
              />
            </Col>
            <Col span={6}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} align="right">
              <Text strong>ประเภทรายได้ :</Text>
            </Col>
            <Col span={6} className=" p-l-15">
              <CheckboxGroup
                options={projectOption}
                value={checkedProjectType}
                onChange={onChangeProjectList}
              />
            </Col>
            <Col span={2} align="right">
              <Text strong>ประเภทบัญชี :</Text>
            </Col>
            <Col span={6} className=" p-l-15">
              <CheckboxGroup
                options={eftTypeOption}
                value={checkedEftType}
                onChange={onChangeEftType}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={error ? [] : data?.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              //total: data.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="row_id"
              width={50}
              align="center"
              fixed="left"
            />
            <Table.Column
              title="วันที่บิล"
              dataIndex="document_date"
              align="center"
              render={(text) => {
                return moment(text).format("DD/MM/YYYY");
              }}
            />
            <Table.Column
              title="เลขที่บิล"
              dataIndex="document_no"
              align="center"
            />
            <Table.Column
              title="สถานะใบเสร็จ"
              dataIndex="bill_status"
              align="center"
            />
            <Table.Column
              title="วันที่เกิดรายได้"
              dataIndex="trans_date"
              align="center"
              render={(text) => {
                return moment(text).format("DD/MM/YYYY");
              }}
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="center"
            />
            <Table.Column
              title="ชื่อ - สกุล"
              dataIndex="partner_name"
              align="center"
            />
            <Table.Column
              title="เลขประจำตัวผู้เสียภาษี"
              dataIndex="id_card"
              align="center"
            />
            <Table.Column
              title="สถานะสมาชิก"
              dataIndex="status"
              align="center"
            />
            <Table.Column
              title="ชื่อบริษัท"
              dataIndex="company_name"
              align="center"
              render={(text) => {
                return text ? text : "-";
              }}
            />
            <Table.Column
              title="Vat"
              dataIndex="is_vat"
              align="center"
              render={(text, record) => {
                return record.is_vat ? (
                  <FaRegCheckCircle className="fa-lg" color="#97d700" />
                ) : (
                  ""
                );
              }}
            />
            <Table.ColumnGroup title="ประเภทรายได้">
              <Table.Column
                title={
                  <>
                    <div className="row">
                      <div className="col">CD</div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {" "}
                        (รวม {numeral(totalCd).format("0,0.00")})
                      </div>
                    </div>
                  </>
                }
                dataIndex="cd"
                align="center"
                render={(text) => {
                  return text > 0 ? numeral(text).format("0,0.00") : "-";
                }}
              />
              <Table.Column
                title={
                  <>
                    <div className="row">
                      <div className="col">RTB</div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {" "}
                        (รวม {numeral(totalRtb).format("0,0.00")})
                      </div>
                    </div>
                  </>
                }
                dataIndex="rtb"
                align="center"
                render={(text) => {
                  return text > 0 ? numeral(text).format("0,0.00") : "-";
                }}
              />
              <Table.Column
                title={
                  <>
                    <div className="row">
                      <div className="col">CB</div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {" "}
                        (รวม {numeral(totalCb).format("0,0.00")})
                      </div>
                    </div>
                  </>
                }
                dataIndex="cb"
                align="center"
                render={(text) => {
                  return text > 0 ? numeral(text).format("0,0.00") : "-";
                }}
              />
            </Table.ColumnGroup>
            <Table.Column
              title="รวมรายได้"
              dataIndex="total_amount"
              align="center"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "-";
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
