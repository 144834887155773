/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DigitalProductProjectPage from './pages/DigitalProductProjectPage';
import DigitalProductPage from './pages/DigitalProductPage';
import DigitalProductVotePage from './pages/DigitalProductVotePage';

const queryClient = new QueryClient();

export default ({ match }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route
          path={`${match.path}`}
          exact
          children={<DigitalProductProjectPage />}
        />
        <Route
          path={`${match.path}/:digitalProductProjectId`}
          exact
          children={<DigitalProductPage />}
        />
        <Route
          path={`${match.path}/:digitalProductProjectId/:digitalProductId`}
          exact
          children={<DigitalProductVotePage />}
        />
      </Switch>
    </QueryClientProvider>
  );
};
