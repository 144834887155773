import { Empty, Skeleton } from "antd";
import React from "react";

const TableAddressForm = ({ data, loading }) => {
  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : data.length !== 0 ? (
        <table className="w-100">
          <tbody>
            <tr className="text-gray-60 pb-3 text-center">
              <th>ชื่อ</th>
              <th>เบอร์โทร</th>
              <th>ที่อยู่</th>
              <th>ตำบล / แขวง</th>
              <th>อำเภอ / เขต</th>
              <th>จังหวัด</th>
              <th>รหัสไปรษณีย์</th>
            </tr>
            {data.map((n, i) => {
              return (
                <tr key={i} style={{ backgroundColor: "#E3E3E3" }}>
                  <td>{n.ship_to}</td>
                  <td>{n.ship_to_mobile}</td>
                  <td>{n.address}</td>
                  <td>{n.sub_district}</td>
                  <td>{n.district}</td>
                  <td>{n.province}</td>
                  <td className="text-center">{n.post_code}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Empty />
      )}
    </>
  );
};
export default TableAddressForm;
