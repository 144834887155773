import moment from "moment";
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const RenderBomDetail = ({ list, qty = 1 }) => {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <tr>
        <td></td>
        <td></td>
        <td>
          <span className="cursor-pointer" onClick={() => setExpand(!expand)}>
            ประกอบด้วย {expand ? <FaAngleUp /> : <FaAngleDown />}
          </span>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>

      {expand ? (
        <>
          <tr>
            <td></td>
            <td></td>
            <td>
              {list.map((n, i) => (
                <p className="m-0 pl-3" key={i}>
                  {n.qty
                    ? `${n.product_name} (${n.qty * qty} ชิ้น)`
                    : `${
                        n.ref_product_name
                          ? n.ref_product_name
                          : n.product_name
                          ? n.product_name
                          : n.ref_product_code
                          ? n.ref_product_code
                          : "-"
                      }`}
                </p>
              ))}
            </td>
            <td className="text-center">
              {list.map((n, i) => (
                <p className="m-0 pl-3" key={i}>
                  {`${n.running ? n.running : "-"}`}
                </p>
              ))}
            </td>
            <td>
              {list.map((n, i) => (
                <p className="m-0 pl-3" key={i}>
                  {`${n.lot ? n.lot : "-"}`}
                </p>
              ))}
            </td>
            <td className="text-center">
              {list.map((n, i) => (
                <p className="m-0 pl-3" key={i}>
                  {`${
                    n.production_date
                      ? moment(n.production_date).format("DD/MM/YYYY")
                      : "-"
                  }`}
                </p>
              ))}
            </td>
            <td className="text-center">
              {list.map((n, i) => (
                <p className="m-0 pl-3" key={i}>
                  {`${
                    n.expiration_date
                      ? moment(n.expiration_date).format("DD/MM/YYYY")
                      : "-"
                  }`}
                </p>
              ))}
            </td>
          </tr>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(RenderBomDetail);
