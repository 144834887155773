import { Button, Modal, notification, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initRequisitionError } from "../../../../../redux/logic/requisition";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import { axios, getToken, moment, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import { mapFileList } from "../../../../util/helper/file";

const { Text } = Typography;

const { setGlobalState, setState } = actionRequisition;

const UserSave = ({ handleBack }) => {
  const dispatch = useDispatch();
  const documentDetail = useSelector(
    (state) => state.requisition.documentDetail
  );
  const product = useSelector((state) => state.requisition.product);
  const file = useSelector((state) => state.requisition.file);

  const handleSubmit = () => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1 text-pv">
            บันทึกข้อมูล
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(setState({ name: "loadingSubmit", value: true }));
        const { itemKeys, itemByKey } = product;
        const { requisition_type } = documentDetail;
        let status;
        switch (requisition_type) {
          case "marketing":
            status = "wait-manager-marketing";
            break;
          case "benefit":
            status = "wait-manager-benefit";
            break;
          case "satisfied":
            status = "wait-manager-satisfied";
            break;
          case "another-8":
            status = "wait-manager-another-8";
            break;
          case "healthy-food":
            status = "wait-manager-healthy-food";
            break;
          case "finance":
            status = "wait-manager-finance";
            break;
          default:
            status = "wait-manager-other";
            break;
        }
        const data = {
          ...documentDetail,
          ...mapFileList(file),
          remark: documentDetail.remark ?? "",
          status,
          requisition_date: moment(documentDetail.requisition_date).toDate(),
          document_date: moment(documentDetail.document_date).toDate(),
          document_status: documentDetail.status,
          products: _.map(itemKeys, (key) =>
            _.omit(itemByKey[key], ["row_id"])
          ),
        };
        const url_option =
          documentDetail.status === "return" ? `/${data.document_no}` : "";
        axios({
          method: "post",
          url: `${URL_API}/requisition${url_option}`,
          headers: { Authorization: "Bearer " + getToken() },
          data,
        })
          .then(() => {
            handleBack();
            notification.success({ message: "บันทึกข้อมูลแล้ว" });
          })
          .catch((e) => resCatchModal(e))
          .finally(() =>
            dispatch(setState({ name: "loadingSubmit", value: false }))
          );
      },
    });
  };

  const handleClickSave = () => {
    let re = {
      ...initRequisitionError,
    };
    if (!documentDetail.requisition_type) {
      re.requisition_type = "กรุณาเลือกประเภทการเบิก";
    } else if (
      !_.includes(
        [
          "benefit",
          "delivery",
          "marketing",
          "material",
          "product-development",
          "satisfied",
          "another-8",
          "healthy-food",
          "finance",
          "activity",
        ],
        documentDetail.requisition_type
      )
    ) {
      re.requisition_type = "ประเภทการเบิกนี้ไม่สามารถดำเนินการได้";
    }
    if (!documentDetail.warehouse_code) {
      re.warehouse_code = "กรุณาเลือกคลังสินค้า";
    }
    if (!documentDetail.requisition_class) {
      re.requisition_class = "กรุณาเลือก Class";
    }
    if (!documentDetail.requisition_date) {
      re.requisition_date = "กรุณาเลือกวันที่ต้องการสินค้า";
    }
    if (!documentDetail.reason) {
      re.reason = "กรุณาระบุวัตถุประสงค์การเบิก";
    }
    if (product.itemKeys.length === 0) {
      re.product = "กรุณาเลือกสินค้าอย่างน้อย 1 รายการ";
    }
    dispatch(setGlobalState({ name: "error", value: re }));
    for (const key in re) {
      const e = re[key];
      if (e)
        return notification.warning({
          message: "ไม่สามารถดำเนินการได้",
          description: "กรุณาตรวจสอบรายการ",
        });
    }
    handleSubmit();
  };

  return (
    <Button type="primary" className="width-100" onClick={handleClickSave}>
      บันทึก
    </Button>
  );
};

export default memo(UserSave);
