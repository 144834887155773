/* eslint-disable no-unused-vars */
import { Button, message, Switch } from "antd";
import { Col, List, Row } from "antdV4";
import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../../redux/slices/marketing/product";
import manageWebsiteService from "../../../../../services/manage-website.service";
import { axios, getToken, moment, URL_API, _ } from "../../../../../utils";
import useAsync from "../../../../../utils/useAsync";
import { AlertConfirm } from "../../../../util/Alert";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import { notFoundImg } from "../../utils/constants/mwHomeConstants";

export default function BestSellerList({ history, match }) {
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;

  const [filterCategory, setFilterCategory] = useState("ทั้งหมด");
  const [seq, setSeq] = useState([]);

  const [data, setData] = useState([]);

  const headData = [
    {
      no: "No.",
      img: "รูปสินค้า",
      sku: "SKU",
      name: "ชื่อสินค้า",
      status: "Status",
      update: "Update Date",
      check: "นำสินค้าออก",
    },
  ];

  const { execute: updateBestSellerSeqById } = useAsync(
    manageWebsiteService.updateBestSellerSeqById,
    {
      onSuccess: async () => {
        await loadContent();
      },
    }
  );

  const onDragEnd = async (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    setData(items);
    for (let i = 0; i < items.length; i++) {
      let x = i + 1;
      let myId = _.toNumber(items[i].id);
      updateBestSellerSeqById({ myId, x });
    }
    setTimeout(() => {
      message.success({ content: "Saved!", duration: 0.8 });
    }, 180);
  };

  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/home/best-seller-list",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/home/best-seller/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "Active" : "In-Active",
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelect = async (id) => {
    updateBestSellerCheckBox({
      selected: 0,
      ref_id: id,
    });
  };

  const { execute: updateBestSellerCheckBox } = useAsync(
    manageWebsiteService.updateBestSellerCheckBox,
    {
      onSuccess: async (data) => {
        await loadContent();
      },
    }
  );

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/home/best-seller-list",
      params: {
        filter: 1,
      },
    })
      .then((res) => {
        let loadData = res.data?.data;
        setData(loadData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <Panel>
        <PanelHeader>สินค้าขายดี</PanelHeader>
        <PanelBody>
          <Row type="flex" justify="end" gutter={16} className="mb-4">
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => history.push(`${match.path}/add`)}
                >
                  <FaPlus />
                  เลือกสินค้า
                </Button>
              </Col>
            </div>
          </Row>
          <Row>
            <List
              size="small"
              bordered
              dataSource={headData}
              renderItem={(item) => {
                const draggble = item.title !== "XXX";
                return (
                  <List.Item className={draggble ? "draggble" : ""}>
                    <List.Item.Meta title={item.no} />
                    <List.Item.Meta title={item.img} />
                    <List.Item.Meta title={item.sku} />
                    <List.Item.Meta title={item.name} />
                    <List.Item.Meta
                      title={
                        <div
                          style={{
                            width: "150px",
                            display: "flex",
                            justifyContent: "center",
                            overflow: "hidden",
                          }}
                        >
                          {item.status}
                        </div>
                      }
                    />
                    <List.Item.Meta title={item.update} />
                    <List.Item.Meta
                      title={
                        <div
                          style={{
                            width: "150px",
                            display: "flex",
                            justifyContent: "center",
                            overflow: "hidden",
                          }}
                        >
                          {item.check}
                        </div>
                      }
                    />
                  </List.Item>
                );
              }}
            ></List>
          </Row>
          <Row>
            <ReactDragListView
              nodeSelector=".ant-list-item.draggble"
              onDragEnd={onDragEnd}
            >
              <List
                style={{ cursor: "grab" }}
                size="small"
                bordered
                dataSource={data}
                pagination={{
                  size: "default",
                  showLessItems: true,
                  showTotal: (total, range) =>
                    `${range[0]} - ${range[1]} of ${total}`,
                  total: list?.fetch?.total || 0,
                  current: search.page,
                  pageSize: search.limit,
                  onChange: (page) => dispatch(SET_SEARCH({ page: page })),
                }}
                renderItem={(item) => {
                  const draggble = item.title !== "XXX";
                  return (
                    <List.Item
                      // actions={["x"]}
                      className={draggble ? "draggble" : ""}
                    >
                      <List.Item.Meta
                        title={item.row_id}
                        // description={<TestModal />}
                      />
                      <List.Item.Meta
                        title={
                          <>
                            <div
                              style={{
                                objectFit: "contain",
                                width: "100px",
                                height: "100px",
                                overflow: "hidden",
                                border: "1px solid #BFBFBF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "8px",
                              }}
                            >
                              <img
                                src={
                                  item.image_url == null
                                    ? notFoundImg
                                    : item.image_url
                                }
                                alt="img_product"
                                width="80px"
                                height="80px"
                              />
                            </div>
                          </>
                        }
                      />
                      <List.Item.Meta title={item.backend_sku_code} />
                      <List.Item.Meta title={item.product_name_local} />
                      <List.Item.Meta
                        title={
                          <div
                            style={{
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            <Switch
                              checked={item.status === "Active"}
                              //   defaultChecked={
                              //     item.status === "Active" ? true : false
                              //   }
                              onClick={(checked) =>
                                handleChangeStatus(checked, item.id)
                              }
                            />
                          </div>
                        }
                      />
                      <List.Item.Meta
                        title={moment(item.update_date).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      />
                      <List.Item.Meta
                        title={
                          <div
                            style={{
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            <Row>
                              <Button
                                className="btn-danger ml-1"
                                onClick={async () => {
                                  const confirm = await AlertConfirm(
                                    "นำรายการที่เลือกออก"
                                  );
                                  if (confirm) {
                                    handleSelect(item.id);
                                  }
                                }}
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </Row>
                          </div>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </ReactDragListView>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
