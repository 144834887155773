import React, { useEffect, useState } from "react";
import { useQuery } from "../../../../hooks/dom";
import styles from "../../../../scss/module/legal.module.scss";
import LegalPanel from "../components/LegalPanel";
import DocumentList from "./DocumentList";
import DocumentPreview from "./DocumentPreview";

const Index = ({ history }) => {
  const [imgSelect, setImgSelect] = useState(null);
  const query = useQuery();
  const id = query.get("id");
  useEffect(() => {
    !+id && history.push("/legal");
  }, [id, history]);

  const handleSelectDocument = (data) => {
    setImgSelect({ ...data });
  };
  return (
    <LegalPanel>
      <div className={styles.document}>
        <DocumentList
          onSelect={handleSelectDocument}
          activeId={imgSelect?.id}
        />
        <DocumentPreview
          src={imgSelect?.img_url ? `${imgSelect?.img_url}` : null}
        />
      </div>
    </LegalPanel>
  );
};

export default Index;
