/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import {  Typography, } from 'antdV4';
import {  Row, Col } from 'antd';
import bannerConstants from '../../constants/bannerConstants';
import { ErrorMessage } from '@hookform/error-message';
import { bannerFormDefaultValues } from '../dto/banner';
import { DatePicker } from 'antd';
import moment from 'moment';
import ImageUpload from '../upload/ImageUpload';

const BannerForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const { execute: getBannerById } = useAsync(fitStudioService.getBannerById, {
    onSuccess: (data) => {
      reset({
        ...data,
        formType: 'edit',
        start_date: moment(data.start_date),
        end_date: moment(data.end_date),
      });
    },
  });

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getBannerById(watch('id'));
    } else {
      reset(bannerFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Controller
        name="imageUrl"
        control={control}
        render={({ field: { value,onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
              <ImageUpload
                        value={value}
                        onChange={onChange}
                        width={ bannerConstants.home.width}
                        height={ bannerConstants.home.height}
                        errors={errors}
                      />
              
              </Col>
            </Row>
          );
        }}
      />
      {/* <Controller
        name="callBackUrlType"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  ประเภทลิงค์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Select defaultValue="class" onChange={onChange}>
                  <Select.Option value="class">คลาสเรียน</Select.Option>
                  <Select.Option value="blog">บล็อค</Select.Option>
                  <Select.Option value="url">ลิงค์เว็บไซต์</Select.Option>
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="callBackUrlType"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="callBackUrl"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ลิงค์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ลิงค์ หรือ blog id หรือ class id"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="callBackUrl"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      /> */}
      <Controller
        name="start_date"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่เริ่มต้น <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่เริ่มต้น"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="end_date"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่สิ้นสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default BannerForm;
