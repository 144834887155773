/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Input, Select, Table } from 'antd';
import { FaDownload, FaPen, FaPlus, FaSearch, FaUpload } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import fitLegacyServices from '../../../../services/fit-legacy.services';
import { useParams } from 'react-router';
import {
  legacyRewardEventListFormDefaultValues,
  legacyRewardEventListFormSchema,
} from '../components/dto/legacy-reward-event.dto';
import LegacyRewardEventListModal from '../components/modal/LegacyRewardEventListModal';

dayjs.locale('th');

const pageSize = 50;

const LegacyRewardEventListPage = () => {
  const params = useParams();
  const {
    data: legacyRewardEventLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [legacyRewardEventListFormOpen, setLegacyRewardEventListFormOpen] =
    useState(false);

  const legacyRewardEventListForm = useForm({
    defaultValues: legacyRewardEventListFormDefaultValues,
    resolver: legacyRewardEventListFormSchema(),
  });

  const _HandleLegacyRewardEventListFormOpen = (formType) => {
    legacyRewardEventListForm.setValue('formType', formType);
    setLegacyRewardEventListFormOpen(true);
  };

  const _HandleLegacyRewardEventListFormClose = () => {
    legacyRewardEventListForm.reset(legacyRewardEventListFormDefaultValues);
    setLegacyRewardEventListFormOpen(false);
  };

  const { control, watch } = useForm({
    defaultValues: {
      searchText: '',
      isRewardRight: undefined,
      rewardTitle: '',
      isGetReward: undefined,
      isJoinEvent: undefined,
    },
  });

  const { refetch: getLegacyRewardEventLists } =
    fitLegacyServices.useQueryGetLegacyRewardEventPartnerLists(
      {
        take: pageSize,
        page,
        legacyRewardEventId: params.legacyRewardEventId,
        searchText: watch('searchText'),
        isRewardRight: watch('isRewardRight'),
        rewardTitle: watch('rewardTitle'),
        isGetReward: watch('isGetReward'),
        isJoinEvent: watch('isJoinEvent'),
      },
      (data) => {
        setData(data.data);
        setTotal(data.meta.total);
      }
    );

  const { mutate: uploadLegacyRewardEventListsExcel } =
    fitLegacyServices.useMutationUploadLegacyRewardEventListsExcel((data) => {
      setTimeout(() => {
        inputRef.current.value = null;
        queryClient.invalidateQueries({
          queryKey: ['get-legacy-reward-event-list'],
          refetchType: 'all',
        });

        setLoading(false);
      }, 600);
    });

  const { mutate: downloadLegacyRewardEventListsExcel } =
    fitLegacyServices.useMutationDownloadLegacyRewardEventListsExcel(() => {
      setTimeout(() => {
        setLoading2(false);
      }, 600);
    });

  const _HandleSubmit = () => {
    getLegacyRewardEventLists();
  };

  const _HandleUploadLegacyRewardEventListsExcel = (data) => {
    setLoading(true);
    uploadLegacyRewardEventListsExcel({
      data,
      legacyRewardEventId: params.legacyRewardEventId,
    });
  };

  const { mutate: deleteLegacyRewardEvent } =
    fitLegacyServices.useMutationDeleteLegacyRewardEventList(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-reward-event-list'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'memberCode',
      key: 'memberCode',
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: 'ชื่อผู้สมัครหลัก',
      dataIndex: 'partnerName',
      key: 'partnerName',
      render: (_, record) => <span>{record.partnerName}</span>,
    },
    {
      title: 'ชื่อผู้สมัครร่วม',
      dataIndex: 'coPartnerName',
      key: 'coPartnerName',
      render: (_, record) => <span>{record.coPartnerName}</span>,
    },
    {
      title: 'การลงทะเบียน',
      dataIndex: 'isJoinEvent',
      key: 'isJoinEvent',
      render: (_, record) => (
        <span>{record.isJoinEvent ? 'เรียบร้อย' : 'ยังไม่ลงทะเบียน'}</span>
      ),
    },
    {
      title: 'วันเวลาลงทะเบียน',
      dataIndex: 'isJoinEvent',
      key: 'isJoinEvent',
      render: (_, record) => (
        <span>
          {record.joinEventDate
            ? dayjs(record.joinEventDate).format('YYYY-MM-DD HH:mm')
            : ''}
        </span>
      ),
    },
    {
      title: 'สิทธิ์รับรางวัล',
      dataIndex: 'isRewardRight',
      key: 'isRewardRight',
      render: (_, record) => (
        <span>{record.isRewardRight ? 'มีสิทธิ์' : 'ไม่มีสิทธิ์'}</span>
      ),
    },
    {
      title: 'ชื่อรางวัล',
      dataIndex: 'rewardTitle',
      key: 'rewardTitle',
      render: (_, record) => <span>{record.rewardTitle}</span>,
    },
    {
      title: 'การรับรางวัล',
      dataIndex: 'isGetReward',
      key: 'isGetReward',
      render: (_, record) => (
        <span>{record.isGetReward ? 'รับแล้ว' : 'ยังไม่ได้รับ'}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyRewardEventListForm.setValue('id', record.id);
              _HandleLegacyRewardEventListFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyRewardEvent({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Legacy Reward Event รายชื่อ</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <Controller
            name="searchText"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ค้นหาด้วยชื่อ / รหัสนักธุรกิจ "
                  className="mr-2"
                />
              );
            }}
          />
          <Controller
            name="isJoinEvent"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                  placeholder="ลงทะเบียน"
                >
                  {[
                    { label: 'ทั้งหมด', value: undefined },
                    { label: 'เรียบร้อย', value: 1 },
                    { label: 'ยังไม่ลงทะเบียน', value: 2 },
                  ].map((item) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              );
            }}
          />
          <input
            type="file"
            id={'patient'}
            onChange={(e) => {
              if (e.target.files?.length > 0) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                _HandleUploadLegacyRewardEventListsExcel(formData);
              }
            }}
            style={{ display: 'none' }}
            ref={inputRef}
          />
          <div style={{ cursor: 'pointer', marginLeft: 10 }}></div>
          <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={() => {
              setLoading2(true);
              downloadLegacyRewardEventListsExcel({
                take: -1,
                page: 1,
                legacyRewardEventId: params.legacyRewardEventId,
                searchText: watch('searchText'),
                isRewardRight: watch('isRewardRight'),
                rewardTitle: watch('rewardTitle'),
                isGetReward: watch('isGetReward'),
                isJoinEvent: watch('isJoinEvent'),
              });
            }}
            loading={loading2}
            disabled={legacyRewardEventLists.length > 0 ? false : true}
          >
            <FaDownload className="mr-2" />
            ดาวน์โหลด Excel
          </Button>
          <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
            loading={loading}
          >
            <FaUpload className="mr-2" />
            อัปโหลด excel
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <Controller
            name="isRewardRight"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                  placeholder="สิทธิ์การรับรางวัล"
                  className="mr-2"
                >
                  {[
                    { label: 'ทั้งหมด', value: undefined },
                    { label: 'มีสิทธิ์', value: 1 },
                    { label: 'ไม่มีสิทธิ์', value: 2 },
                  ].map((item) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              );
            }}
          />
          <Controller
            name="rewardTitle"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ชื่อรางวัล"
                  className="mr-2"
                />
              );
            }}
          />
          <Controller
            name="isGetReward"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                  placeholder="การรับรางวัล"
                  className="mr-2"
                >
                  {[
                    { label: 'ทั้งหมด', value: undefined },
                    { label: 'รับแล้ว', value: 1 },
                    { label: 'ยังไม่ได้รับ', value: 2 },
                  ].map((item) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              );
            }}
          />
        </div>
        <Table
          columns={columns}
          dataSource={legacyRewardEventLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
        />
      </PanelBody>
      <FormProvider {...legacyRewardEventListForm}>
        <LegacyRewardEventListModal
          open={legacyRewardEventListFormOpen}
          handleClose={_HandleLegacyRewardEventListFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default LegacyRewardEventListPage;
