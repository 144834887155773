/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Alert, Button, Col, Input, message, Modal, Row, Select, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp, FaCogs, FaPlus } from "react-icons/fa";
import { useHttp } from "../../../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../../../utils";
import { initState, PromotionCategoryContext, reducer } from "../reducers";
const limit = 10;
const number = typeof 10;
let turnon = false;
const mockdata = {
  backend_sku_code: '',
  point_01: null,
  point_02: null,
  point_03: null,
  product_code: '',
  product_name_en: '',
  product_name_local: '',
  row_id: null,
  row_number: '',
  unit: '',
}
const ModalProduct = ({ visible, onCancel, idfromindex, linenumber,
  statesetproduct,
  searchindex,
  productcategory, stategetdatafrommodalpc, setAddfrommodalproduct, addfrommodalproduct,
  setProductfirstcome, confirmdelete, confirmdelete2,statesetconfirmadd,confirmadd,setConfirmAdd2,confirmadd2,activeadd
}) => {
  //const { state, dispatch } = useContext(PromotionCategoryContext);
  const [dataForm, setDataForm] = useState(mockdata)
  const [dataFormtouse, setDataFormtouse] = useState([])
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [disabledProductCode, setdisabledProductCode] = useState("");
  const [productType, setProductType] = useState("item");
  const [showSource, setShowSource] = useState(false);
  const [startsetdatatouse, setDatatouse] = useState(false)
  const [startsetdatatouse2, setDatatouse2] = useState([])
  const [checkcopy, setCheckcopy] = useState(false)
  const [linenumberplus,setLineNumberPlus]=useState(false)
  const [loading, data, error, reload] = useHttp(
    {
      method: "get",
      url: `${URL_API}/system/products`,
      params: {
        productType,
        limit,
        offset: (page - 1) * limit,
        search,
        disabledProductCode
      },
      token: true,
    },
    [page, productType,disabledProductCode]
  );
  // const [dataSource, setDataSource] =useState(data)
  const [state, dispatch] = useReducer(reducer, initState);
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
      reload()
    } else {
      reload();
    }
  }, [search, disabledProductCode]);

  useEffect(() => {
    const producttodrop=productcategory.map((item)=>
      item.product_code
    ).join(",")
    console.log("producttodrop",producttodrop)
    setdisabledProductCode(producttodrop)
    //reload()
  },[productcategory,visible])

  useEffect(()=>{
     reload()
  },[disabledProductCode])


  useEffect(() => {
     setDataFormtouse([])
  }, [addfrommodalproduct])

  useEffect(() => {

    console.log("dataForm", dataForm)
    console.log("dataFormtouse", dataFormtouse)
    statesetproduct([...productcategory, ...dataFormtouse])
    //reload()
    setDataFormtouse([])
    //handlereModalproduct();
  }, [startsetdatatouse2]);


  /*const handlereModalproduct = (data) => {

  }*/

  useEffect(() => {
    if(confirmadd2)
      handleClickAdd()
      console.log("confirmdelete", confirmdelete)
  }, [activeadd]);

  useEffect(() => {
    if(confirmdelete)
     handleClickAdd()
     console.log("confirmdelete", confirmdelete)
 }, [confirmdelete2]);

  const onSelect = async(data) => {
    console.log("data", data)
    /*Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการเพิ่มข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      zIndex: "1080",*/
      /*onOk() {*/
        const check = productcategory.filter((item) =>
          item.product_code !== data.product_code
        )
        const checkdata2 = dataFormtouse.find((item) =>
          item.product_code === data.product_code
        )
        const sampled = await [...productcategory]
        console.log("onSelectcheck", check)
        console.log("onSelect", sampled)
        console.log("onSelectcheckdata2", checkdata2)
        if (check.length === sampled.length) {
          setDataFormtouse([...dataFormtouse, {
            ...dataForm, row_id: data.id,
            backend_sku_code: data.backend_sku_code,
            point_01: data.point_01,
            point_02: data.point_02,
            point_03: data.point_03,
            product_code: data.product_code,
            product_name_en: data.product_name_en,
            product_name_local: data.product_name_local,
            row_number: sampled.length + 1 + dataFormtouse.length,
            unit: data.unit,

          }])
          setDatatouse2([...productcategory, ...dataFormtouse])
          setDatatouse(!startsetdatatouse);
          //reload()
        }
     /* }*/
   /* })*/
  };

  /*const onSelect2 = (data) => {

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการเพิ่มข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      zIndex: "1080",
      onOk() {

      }
    })
  }*/


  const handleClickAdd = (data) => {
    const preparetoadd = productcategory.filter((item) =>
      typeof item.row_number == number
    )
    console.log("preparetoadd", preparetoadd.length)

    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < preparetoadd.length; i++) {
        console.log("dataindeli", preparetoadd[i])
        await axios({
          method: "post",
          headers: { Authorization: "Bearer " + getToken() },
          url: `${URL_API}/setting/master/promotioncategory/add`,
          data: {
            product_id: preparetoadd[i]?.row_id,
            category_id: idfromindex,
            line_number: linenumber-1
          },
        })
          .then((res) => {
            console.log("res.data_fromadd", res.data)
          })
          .catch((err) => {
            reject(
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message
            );
          })
      } resolve();console.log("บันทึกจริง")
    })
  };




  console.log("productcategory", productcategory);
  console.log("dataForm", dataForm)
  console.log("number", number)
  data && console.log("data", data.data)
  const handleChekdump = (datavalue) => {


    const result = productcategory.map((item1) => (item1.row_id))
    const dump = data.data.filter((item, i) => (item.id !== 72))

    console.log("result", result)
    console.log("result", dump)
  }

  const handleChangeProductType = (value) => {
    setProductType(value);
    setPage(1);
  };

  return (
    <PromotionCategoryContext.Provider value={{ state, dispatch }}>
      <Modal
        visible={visible}
        title="เลือกสินค้า"
        onCancel={() => {
          setPage(1);
          setSearch("");
          onCancel();
        }}
        footer={false}
        width={800}
        zIndex={1080}
      >
        <Row gutter={16} className="mb-2">
          <Col span={18}>
            <Input.Search
              placeholder="รหัสสินค้า, ชื่อสินค้า"
              onSearch={(value) => setSearch(value?.trim() || "")}
              allowClear
            />
          </Col>

          <Col span={6}>
            <Select
              className="w-100 hide"
              value={productType}
              onChange={handleChangeProductType}
              dropdownStyle={{ zIndex: 1080 }}
            >
              <Select.Option value="item">สินค้าปกติ</Select.Option>
              <Select.Option value="category">กลุ่มสินค้า</Select.Option>
            </Select>
          </Col>
        </Row>
        {error ? (
          <Alert type="error" />
        ) : (
          <Table
            size="small"
            loading={loading}
            dataSource={data?.data || []}
            rowKey="row_id"
            pagination={{
              current: page,
              size: "default",
              pageSize: limit,
              showSizeChanger: false,
              showLessItems: true,
              onChange: (curr) => setPage(curr),
              total: data?.total || 0,
            }}
          >
            <Table.Column
              className="position-relative"
              title={<div className="ant-table-absolute-title-center">ลำดับ</div>}
              width={70}
              dataIndex="row_id"
              align="left"
            />
            <Table.Column
              className="position-relative"
              width={100}
              title={
                <div className="ant-table-absolute-title-center">รหัสสินค้า</div>
              }
              dataIndex={productType === "item" ? "product_code" : "code"}
            />
            {productType === "item" ? (
              <Table.Column
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    ชื่อสินค้า
                  </div>
                }
                dataIndex="product_name_local"
              />
            ) : (
              <Table.Column
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    ชื่อกลุ่มสินค้า
                  </div>
                }
                render={({ name, source }) => (
                  <>
                    <Row>
                      <Col
                        span={24}
                        className="cursor-pointer"
                        onClick={() => setShowSource((prevState) => !prevState)}
                      >
                        <Typography.Text className="mr-2">{name}</Typography.Text>
                        {showSource ? <FaAngleUp /> : <FaAngleDown />}
                      </Col>
                    </Row>
                    {showSource &&
                      source.map((n, i) => (
                        <Row key={i}>
                          <Col className="ml-3">
                            <Typography.Text className="mr-3">
                              {n.product_code}
                            </Typography.Text>
                            <Typography.Text>
                              {n.product_name_local}
                            </Typography.Text>
                          </Col>
                        </Row>
                      ))}
                  </>
                )}
              />
            )}
            <Table.Column
              title={<FaCogs />}
              align="center"
              width={60}
              render={(data) => (
                <Tooltip title="เลือกสินค้า">
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    onClick={() => { onSelect(data); /*onSelect2([data])*/; handleChekdump() }}
                  >
                    <FaPlus />
                  </Button>
                </Tooltip>
              )}
            />
          </Table>
        )}
      </Modal>
    </PromotionCategoryContext.Provider>
  );
};

export default ModalProduct


