/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { _ } from "../../../../utils";
import { FaShippingFast, FaPlus, FaTimes } from "react-icons/fa";

const Tabs = ({
  data,
  active,
  onClick,
  onNewTab,
  onCloseTab,
  canNewTab = false,
  canCloseTab = false,
}) => {
  return (
    <div className="pos-tabs">
      {data.map((n, i) => {
       return (
          <div
            key={_.uniqueId("tab-")}
            className={`pos-tab-item ${n.key === active ? "active" : ""}`}
            onClick={() => onClick(n.key)}
            style={{ position: "relative" }}
          >
            {n.title.split('-')[0]}{" "} 
            { (_.includes(['Transport','Lowcost'], n.req_receipt_type ) ) ? (
              <FaShippingFast className="ml-2" />
            ) : null}
            {canCloseTab && (
              <div
                className="text-white d-flex align-items-center"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => onCloseTab(n.key)}
              >
                <a>
                  <FaTimes />
                </a>
              </div>
            )}
          </div>
        );
      })}
      {canNewTab ? (
        <div
          className="text-white d-flex align-items-center px-2"
          onClick={onNewTab}
        >
          <a>
            <FaPlus />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default Tabs;
