import { createReducer } from "@reduxjs/toolkit";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
} from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useReducer } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { useRouteMatch } from "react-router";
import SelectMonth from "../../../../../../components/SelectMonth";
import { getToken, URL_API } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import ModalProduct from "../components/ModalProduct";

const initState = {
  loading: false,
  data: {
    id: 0,
    code: "",
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    order_type: ["hold", "regular"],
    order_source: ["pos", "online", "mobile"],
    status: "active",
    condition: { itemKeys: [], itemByKey: {} },
    receive: { itemKeys: [], itemByKey: {} },
  },
  modal: {
    product: {
      visible: false,
      active_by: null,
    },
  },
};

const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_MODAL_VISIBLE: (state, { payload: { name, visible, active_by } }) => {
    state.modal[name].visible = visible;
    state.modal[name].active_by = visible ? active_by : null;
  },
  SELECT_ITEM: (state, { payload }) => {
    let selItemKeys = [...state.data[state.modal.product.active_by].itemKeys];
    let selItemByKey = {
      ...state.data[state.modal.product.active_by].itemByKey,
    };

    selItemKeys.push(payload.id);
    selItemByKey = { ...selItemByKey, [payload.id]: payload };

    state.data[state.modal.product.active_by].itemKeys = selItemKeys;
    state.data[state.modal.product.active_by].itemByKey = selItemByKey;

    state.modal.product.visible = false;
    state.modal.product.active_by = null;
  },
  SELECT_ORDER_TYPE: (state, { payload: { type, checked } }) => {
    let newOrderType = [...state.data.order_type];

    if (checked) {
      newOrderType.push(type);
    } else {
      newOrderType = _.filter(newOrderType, (n) => n !== type);
    }

    state.data.order_type = newOrderType;
  },
  SELECT_ORDER_SOURCE: (state, { payload: { type, checked } }) => {
    let newOrderSource = [...state.data.order_source];

    if (checked) {
      newOrderSource.push(type);
    } else {
      newOrderSource = _.filter(newOrderSource, (n) => n !== type);
    }

    state.data.order_source = newOrderSource;
  },
  SET_ITEM_VALUE: (state, { payload: { type, key, name, value } }) => {
    state.data[type].itemByKey[key][name] = value;
  },
});

const GiveAway = (props) => {
  const match = useRouteMatch();
  const [state, dispatch] = useReducer(reducer, initState);

  const onFinish = (values) => {
    console.log(values, match);

    axios({
      method: "post",
      baseURL: URL_API,
      url: `/setting/master/promotion/giveaway${
        match.params.id === "0" ? "" : `/${match.params.id}`
      }`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        ...state.data,
        condition: state.data.condition.itemKeys.map(
          (n) => state.data.condition.itemByKey[n]
        ),
        receive: state.data.receive.itemKeys.map(
          (n) => state.data.receive.itemByKey[n]
        ),
        ...values,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { getFieldDecorator } = props.form;

  return (
    <>
      <Panel>
        <PanelHeader>โปรโมชั่นของแถม</PanelHeader>
        <PanelBody>
          <Row gutter={16}>
            <Col className="text-right">
              <Button
                type="primary"
                onClick={() => {
                  props.form.validateFields((err, values) => {
                    if (!err) {
                      onFinish(values);
                    }
                  });
                }}
              >
                บันทึก
              </Button>
            </Col>
          </Row>
          <Form layout="vertical">
            <Row gutter={32}>
              <Col md={16}>
                <Row gutter={16}>
                  <Col md={12}>
                    <label className="mb-0">รหัสโปรโมชั่น</label>
                    <Form.Item className="mb-0">
                      {getFieldDecorator("code", {
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุรหัสโปรโมขั่น",
                          },
                        ],
                      })(<Input placeholder="รหัสโปรโมชั่น" />)}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <label className="mb-0">ชื่อโปรโมชั่น</label>
                    <Form.Item className="mb-0">
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุชื่อโปรโมชั่น",
                          },
                        ],
                      })(<Input placeholder="ชื่อโปรโมชั่น" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={24}>
                    <label className="mb-0">รายละเอียดโปรโมชั่น</label>
                    <Form.Item className="mb-0">
                      {getFieldDecorator(
                        "description",
                        {}
                      )(
                        <Input.TextArea
                          placeholder="รายละเอียดโปรโมชั่น"
                          rows={4}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Divider>เงื่อนไขการซื้อ</Divider>
                <Table
                  size="small"
                  dataSource={state.data.condition.itemKeys.map(
                    (n) => state.data.condition.itemByKey[n]
                  )}
                  rowKey="id"
                  pagination={false}
                >
                  <Table.Column
                    title="ลำดับ"
                    align="right"
                    render={(text, record, index) => index + 1}
                  />
                  <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
                  <Table.Column
                    title="ชื่อสินค้า"
                    dataIndex="product_name_local"
                  />
                  <Table.Column
                    title="จำนวน"
                    dataIndex="qty"
                    align="right"
                    render={(text, { id }) => (
                      <InputNumber
                        size="small"
                        value={text}
                        onChange={(value) =>
                          dispatch({
                            type: "SET_ITEM_VALUE",
                            payload: {
                              type: "condition",
                              key: id,
                              name: "qty",
                              value,
                            },
                          })
                        }
                      />
                    )}
                  />
                  <Table.Column
                    title={
                      <Tooltip title="เลือกสินค้า">
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_VISIBLE",
                              payload: {
                                name: "product",
                                visible: true,
                                active_by: "condition",
                              },
                            })
                          }
                        >
                          <FaPlus />
                        </Button>
                      </Tooltip>
                    }
                    align="center"
                    render={() => (
                      <Tooltip title="ลบสินค้า">
                        <Button
                          type="danger"
                          ghost
                          size="small"
                          // onClick={() =>
                          //   dispatch({
                          //     type: "SET_MODAL_VISIBLE",
                          //     payload: {
                          //       name: "product",
                          //       visible: true,
                          //       active_by: "condition",
                          //     },
                          //   })
                          // }
                        >
                          <FaTrashAlt />
                        </Button>
                      </Tooltip>
                    )}
                  />
                </Table>
                <Divider>สิ่งที่ได้รับ</Divider>
                <Row gutter={16} className="mb-2">
                  <Col md={24}>
                    <label className="mb-0 mr-2">ประเภท</label>
                    <Select style={{ width: "200px" }}>
                      <Select.Option value="item">สินค้า</Select.Option>
                      <Select.Option value="voucher">ส่วนลด</Select.Option>
                      <Select.Option value="point">คะแนน</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Table
                  size="small"
                  dataSource={state.data.receive.itemKeys.map(
                    (n) => state.data.receive.itemByKey[n]
                  )}
                  rowKey="id"
                  pagination={false}
                >
                  <Table.Column
                    title="ลำดับ"
                    align="right"
                    render={(text, record, index) => index + 1}
                  />
                  <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
                  <Table.Column
                    title="ชื่อสินค้า"
                    dataIndex="product_name_local"
                  />
                  <Table.Column
                    title="จำนวน"
                    dataIndex="qty"
                    align="right"
                    render={(text, { id }) => (
                      <InputNumber
                        size="small"
                        value={text}
                        onChange={(value) =>
                          dispatch({
                            type: "SET_ITEM_VALUE",
                            payload: {
                              type: "receive",
                              key: id,
                              name: "qty",
                              value,
                            },
                          })
                        }
                      />
                    )}
                  />
                  <Table.Column
                    title={
                      <Tooltip title="เลือกสินค้า">
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_VISIBLE",
                              payload: {
                                name: "product",
                                visible: true,
                                active_by: "receive",
                              },
                            })
                          }
                        >
                          <FaPlus />
                        </Button>
                      </Tooltip>
                    }
                    align="center"
                    render={() => (
                      <Tooltip title="ลบสินค้า">
                        <Button
                          type="danger"
                          ghost
                          size="small"
                          // onClick={() =>
                          //   dispatch({
                          //     type: "SET_MODAL_VISIBLE",
                          //     payload: {
                          //       name: "product",
                          //       visible: true,
                          //       active_by: "condition",
                          //     },
                          //   })
                          // }
                        >
                          <FaTrashAlt />
                        </Button>
                      </Tooltip>
                    )}
                  />
                </Table>
              </Col>
              <Col md={8}>
                <Row gutter={16}>
                  <Col md={12}>
                    <label className="mb-0">วันที่เริ่มต้น</label>
                    <Form.Item className="mb-0">
                      {getFieldDecorator("start_date", {
                        rules: [
                          {
                            required: true,
                            message: "กรุณาเลือกวันที่เริ่มต้น",
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder="วันที่เริ่มต้น"
                          format="DD/MM/YYYY"
                          showToday={false}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <label className="mb-0">วันที่สิ้นสุด</label>
                    <Form.Item className="mb-0">
                      {getFieldDecorator(
                        "end_date",
                        {}
                      )(
                        <DatePicker
                          placeholder="วันที่สิ้นสุด"
                          format="DD/MM/YYYY"
                          showToday={false}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Divider>ประเภทการชำระเงิน</Divider>
                <Row gutter={16}>
                  <Col md={12} className="d-flex justify-content-between">
                    <label className="mb-0">Hold</label>
                    <Switch
                      checked={state.data.order_type.includes("hold")}
                      onClick={(checked) =>
                        dispatch({
                          type: "SELECT_ORDER_TYPE",
                          payload: { type: "hold", checked },
                        })
                      }
                    />
                  </Col>
                  <Col md={12} className="d-flex justify-content-between">
                    <label className="mb-0">Regular</label>
                    <Switch
                      checked={state.data.order_type.includes("regular")}
                      onClick={(checked) =>
                        dispatch({
                          type: "SELECT_ORDER_TYPE",
                          payload: { type: "regular", checked },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Divider>ช่องทางการสั่งซื้อ</Divider>
                <Row gutter={16} className="mb-2">
                  <Col md={24} className="d-flex justify-content-between">
                    <label className="mb-0">POS</label>
                    <Switch
                      checked={state.data.order_source.includes("pos")}
                      onClick={(checked) =>
                        dispatch({
                          type: "SELECT_ORDER_SOURCE",
                          payload: { type: "pos", checked },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-2">
                  <Col md={24} className="d-flex justify-content-between">
                    <label className="mb-0">Shopping Online</label>
                    <Switch
                      checked={state.data.order_source.includes("online")}
                      onClick={(checked) =>
                        dispatch({
                          type: "SELECT_ORDER_SOURCE",
                          payload: { type: "online", checked },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={24} className="d-flex justify-content-between">
                    <label className="mb-0">Mobile</label>
                    <Switch
                      checked={state.data.order_source.includes("mobile")}
                      onClick={(checked) =>
                        dispatch({
                          type: "SELECT_ORDER_SOURCE",
                          payload: { type: "mobile", checked },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Divider>การได้รับคะแนน</Divider>
                <Row gutter={16}>
                  <Col md={24} className="d-flex justify-content-between">
                    <label className="mb-0">ได้รับคะแนน</label>
                    <Switch
                    // checked={state.data.order_source.includes("mobile")}
                    // onClick={(checked) =>
                    //   // dispatch({
                    //   //   type: "SELECT_ORDER_SOURCE",
                    //   //   payload: { type: "mobile", checked },
                    //   // })
                    // }
                    />
                  </Col>
                </Row>
                <Divider>เดือนเกิด</Divider>
                <Row gutter={16}>
                  <Col md={24} className="d-flex justify-content-between">
                    <label className="mb-0">โปรโมชั่นตามเดือนเกิด</label>
                    <Switch
                    // checked={state.data.order_source.includes("mobile")}
                    // onClick={(checked) =>
                    //   // dispatch({
                    //   //   type: "SELECT_ORDER_SOURCE",
                    //   //   payload: { type: "mobile", checked },
                    //   // })
                    // }
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mt-2">
                  <Col
                    md={24}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <label className="mb-0">ระบุเดือน</label>

                    <SelectMonth />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </PanelBody>
      </Panel>
      <ModalProduct
        visible={state.modal.product.visible}
        onSelect={(item) => dispatch({ type: "SELECT_ITEM", payload: item })}
        onCancel={() =>
          dispatch({ type: "SET_MODAL_VISIBLE", payload: { visible: false } })
        }
      />
    </>
  );
};

const FormWrapped = Form.create()(GiveAway);

export default FormWrapped;
