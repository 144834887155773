/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Modal, Table, message } from "antd";
import { Button, Col, Radio, Row } from "antdV4";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { IoMdAdd, IoMdSettings } from "react-icons/io";
import { useApi } from "../../../../../../../hooks/http2";
import { URL_API } from "../../../../../../../utils";
import SelectCategoryLevel from "../../../../../pos/components/SelectSubCategory";
import { VoucherContext } from "../../reducers";
const { Search } = Input;

const REF_TYPE = {
  product: "product",
  category: "category",
};

const CategoryModal = ({
  open,
  onCancel = () => {},
  getFieldDecorator,
  getFieldsValue,
  setFieldsValue,
}) => {
  const limit = 5;
  const { state, dispatch } = useContext(VoucherContext);
  const categoryGroup = state.categoryGroup;
  const fields = getFieldsValue();
  const [categoryItem, setCategoryItem] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const productList = useApi(
    {
      baseURL: URL_API,
      url: `/system/products`,
      params: {
        search,
        limit,
        page,
        offset: (page - 1) * limit,
        status: "Active",
        distributionType: "Sale",
        category: 0,
        page_type: "product",
      },
      token: true,
    },
    [search, page]
  );

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "row_id",
      key: "row_id",
      align: "center",
      width: 80,
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "product_code",
      key: "product_code",
      width: 100,
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "product_name_local",
      key: "product_name_local",
    },
    {
      title: <IoMdSettings size={20} />,
      key: "id",
      width: 50,
      align: "center",
      render: (text, record) => {
        const { id, product_code, product_name_local } = record;
        return (
          <Button
            type="primary"
            onClick={() => {
              const duplicate = _.find(categoryGroup, {
                ref_id: id,
                ref_type: REF_TYPE.product,
              });
              if (duplicate) {
                message.open({
                  type: "error",
                  content: "กลุ่มสินค้านี้ถูกเลือกไปแล้ว!",
                });
              } else {
                const item = {
                  ref_id: id,
                  ref_code: product_code,
                  ref_type: REF_TYPE.product,
                  category: null,
                  name: product_name_local,
                };
                dispatch({
                  type: "ADD_CATEGORY_GROUP",
                  payload: item,
                });
                onCancel();
              }
            }}
          >
            <IoMdAdd size={18} />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (!fields.ref_type) {
      setFieldsValue({ ref_type: REF_TYPE.category });
    }
  }, [fields]);

  return (
    <Modal
      title="เพิ่มกลุ่มสินค้า"
      width={640}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Form.Item className="mb-0">
            {getFieldDecorator("ref_type", {
              initialValue: REF_TYPE.category,
              onChange: (e) => {
                setFieldsValue({ ref_type: e.target.value });
              },
            })(
              <Radio.Group className="mb-4">
                <Radio value={REF_TYPE.category}>เลือกจากกลุ่มสินค้า</Radio>
                <Radio value={REF_TYPE.product}>เลือกจากรายสินค้า</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          {fields?.ref_type == REF_TYPE.category ? (
            <div
              className="mb-4"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 60px",
                gap: "8px",
              }}
            >
              <SelectCategoryLevel
                className="w-100"
                withAll={false}
                value={categoryValue}
                placeholder="เลือกหมวดหมู่สินค้า"
                onChange={(value, selectedOptions) => {
                  const categoryId = value[_.size(value) - 1];
                  const categoryName = selectedOptions
                    ?.map((n) => n?.label)
                    .join(" / ");
                  const item = {
                    ref_id: categoryId,
                    ref_code: null,
                    ref_type: REF_TYPE.category,
                    category: value,
                    name: categoryName,
                  };
                  setCategoryValue(value);
                  setCategoryItem(item);
                }}
              />
              <Button
                type="primary"
                block
                disabled={categoryItem == null}
                onClick={() => {
                  const duplicate = _.find(categoryGroup, {
                    ref_id: categoryItem.ref_id,
                    ref_type: REF_TYPE.category,
                  });
                  if (duplicate) {
                    message.open({
                      type: "error",
                      content: "กลุ่มสินค้านี้ถูกเลือกไปแล้ว!",
                    });
                  } else {
                    dispatch({
                      type: "ADD_CATEGORY_GROUP",
                      payload: categoryItem,
                    });
                    setCategoryItem(null);
                    setCategoryValue(null);
                    onCancel();
                  }
                }}
              >
                <IoMdAdd size={18} />
              </Button>
            </div>
          ) : (
            <Row>
              <Col span={24}>
                <Search
                  className="mb-2"
                  placeholder="ค้นหาจาก รหัสสินค้า / ชื่อสินค้า"
                  allowClear
                  onSearch={(e) => {
                    const searchText = e.trim();
                    setSearch(searchText);
                    setPage(1);
                  }}
                />
                <Table
                  bordered
                  columns={columns}
                  dataSource={productList?.fetch?.data || []}
                  loading={productList?.loading}
                  pagination={{
                    total: productList?.fetch?.total,
                    current: page,
                    onChange: (page) => {
                      setPage(page);
                    },
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default CategoryModal;
