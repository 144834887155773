import React from "react";
import { Switch, Route } from "react-router-dom";

import Module from "./module";
import Role from "./role";
import User from "./user/routes";

import Error404 from "../../Error404";
import PermissionProductRequest from "./PermissionProductRequest";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/module`} component={Module} />
      <Route path={`${match.path}/role`} component={Role} />
      <Route path={`${match.path}/user`} component={User} />
      <Route
        path={`${match.path}/product-request`}
        component={PermissionProductRequest}
      />
      <Route component={Error404} />
    </Switch>
  );
};
