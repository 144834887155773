/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Row } from "antd";
import React, { useCallback, useEffect, useReducer } from "react";
import TabUnderLine from "../../../components/TabUnderLine";
import { useHttp } from "../../../hooks/http";
import { moment, URL_API } from "../../../utils";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import BillPreview from "../pos/components/BillPreview";
import BillModal from "./components/BillModal";
import DocumentTable from "./components/DocumentTable";
import SearchDocument from "./components/SearchDocument";
import BillContext, { initState, reducer } from "./reducer";

const Index = ({ match, history }) => {
  const { process: process_params } = match.params;
  const [state, dispatch] = useReducer(reducer, initState);
  const {
    listTab,
    searchDocument: { page, limit, start_date, end_date, branch_code, query },
    billPreview: { id },
    info: { loading_submit },
  } = state;
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/bill`,
      params: {
        limit,
        offset: (page - 1) * limit,
        query,
        start_date: start_date && moment(start_date).format('YYYY-MM-DD'),
        end_date: end_date && moment(end_date).format('YYYY-MM-DD'),
        branch_code,
        process_params,
      },
      token: true,
    },
    [page, start_date, end_date, branch_code, query]
  );

  useEffect(() => {
    if (data?.data.user) {
      dispatch({
        type: "setGlobalState",
        payload: {
          name: "user",
          value: { ...data.data.user },
        },
      });
    }
  }, [data?.data.user]);

  useEffect(() => {
    dispatch({
      type: "setGlobalState",
      payload: {
        name: "searchDocument",
        value: {
          start_date: moment(), // process_params === "pending" ? null : moment(),
          end_date: moment(), // process_params === "pending" ? null : moment(),
          // branch_code: permission_status[`wait-operation`]
          //   ? ""
          //   : user.branch_code,
        },
      },
    });
  }, [process_params]);

  const handleClickProcess = useCallback((e) => {
    history.push({ pathname: `/bill/${e}` });
  }, []);

  const setPreview = useCallback(() => {
    dispatch({ type: "clearGlobalState", payload: "billPreview" });
  }, []);

  return (
    <BillContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>ยกเลิกใบเสร็จ</PanelHeader>
        <PanelBody>
          <LoadingFullScreen
            loading={loading_submit}
            text={`กำลังบันทึกข้อมูล...`}
          />
          <Row className="mb-4 mt-3">
            <Col span={18} offset={3}>
              <TabUnderLine
                active={process_params}
                list={listTab}
                onClick={handleClickProcess}
              />
            </Col>
          </Row>
          <SearchDocument />
          {error ? (
            <Alert showIcon type="error" message={error} />
          ) : (
            <DocumentTable
              processParams={process_params}
              dataSource={data?.data.record || []}
              total={data?.data.total || 0}
              loading={loading}
            />
          )}
          <BillModal reload={reload} processParams={process_params} />
          {process_params === "request" && (
            <BillPreview id={id} preview={!!id} setPreview={setPreview} />
          )}
        </PanelBody>
      </Panel>
    </BillContext.Provider>
  );
};

export default Index;
