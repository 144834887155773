import { axios, URL_API, _ } from "../../../../utils";
import { resCatch } from "../../../util/helper";

const TypePrefix = "pos/data/";

const Types = {
  CLEAR: TypePrefix + "CLEAR",
  BUYER_TYPE: TypePrefix + "BUYER_TYPE",
  REPORT_PARAMETER: TypePrefix + "REPORT_PARAMETER",
  REDEEM_POINT: TypePrefix + "REDEEM_POINT",
  CLEAR_REDEEM_POINT: TypePrefix + "CLEAR_REDEEM_POINT",
};

const INIT_STATE = {
  report_parameter: "",
  redeem_point: {
    rp_topup: 0,
    rp_hold: 0,
    notfound: null,
  },
  buyer_type: {
    loading: true,
    data: [],
  },
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case Types.CLEAR:
      return {
        ...INIT_STATE,
      };

    case Types.CLEAR_REDEEM_POINT:
      return {
        ...state,
        redeem_point: { ...INIT_STATE.redeem_point },
      };

    case Types.REPORT_PARAMETER:
      return {
        ...state,
        report_parameter: payload,
      };

    case Types.REDEEM_POINT:
      return {
        ...state,
        redeem_point: {
          ...state.redeem_point,
          ...payload,
        },
      };

    case Types.BUYER_TYPE:
      return {
        ...state,
        buyer_type: {
          ...state.buyer_type,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export const PosDataAction = {
  clear: () => {
    return {
      type: Types.CLEAR,
    };
  },
  clearRedeemPoint: () => ({
    type: Types.CLEAR_REDEEM_POINT,
  }),
  loadReportParameter: (report_name) => async (dispatch) => {
    try {
      const res = await axios({
        url: `${URL_API}/pos/master/report_parameter`,
        params: {
          report_name,
        },
      });
      dispatch({
        type: Types.REPORT_PARAMETER,
        payload: res.data.data[0]?.prameter_url || "",
      });
    } catch (err) {
      resCatch(err);
    }
  },
  loadRedeemPoint: (partner_code) => async (dispatch) => {
    try {
      const res = await axios({
        url: `${URL_API}/pos/master/member/point`,
        params: {
          partner_code,
        },
      });
      const payload = res.data.data
        ? {
            rp_topup: res.data.data.rp_topup,
            rp_hold: res.data.data.rp_hold,
            notfound: false,
          }
        : {
            rp_topup: 0,
            rp_hold: 0,
            notfound: true,
          };
      dispatch({
        type: Types.REDEEM_POINT,
        payload,
      });
    } catch (err) {
      resCatch(err);
    }
  },
  loadBuyerType: () => async (dispatch) => {
    try {
      const res = await axios.get(`${URL_API}/pos/master/buyer`);
      const data = _.map(res.data.data, (n) => ({
        key: n.id,
        value: n.code,
        text: n.name,
      }));
      dispatch({
        type: Types.BUYER_TYPE,
        payload: {
          loading: false,
          data,
        },
      });
    } catch (e) {
      resCatch(e);
    }
  },
};
