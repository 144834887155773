import React from 'react';
import { Row, Col, Input } from 'antd';
export default function PromotionFree() {
    return (
        <>
            <table className="table table-bordered mb-5">
                <thead>
                    <tr style={{ backgroundColor: '#bcbcbc' }}>
                        <th colSpan={6} className="text-center">รายการสินค้าสำหรับเลือกภายหลัง</th>
                    </tr>
                    <tr className="text-center">
                        <th width="5%">ลำดับ</th>
                        <th width="20%">รหัสสินค้า</th>
                        <th>ชื่อสินค้า</th>
                        <th>ราคา</th>
                        <th>จำนวน</th>
                        <th width="10%"><i className="fa fa-cogs" /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <td colSpan={6}>-- ไม่มีรายการสินค้า --</td>
                    </tr>

                </tbody>
            </table>
            <div className="p-20 mb-5" style={{ backgroundColor: '#f1f5f8', fontSize: 14, borderRadius: 5 }}>


                <Row>
                    <Col>
                        <h4 className="pull-left">รายการที่ {1}</h4>
                        <i className="fas fa-times pull-right" style={{ cursor: 'pointer' }} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={2}>
                        <b className="pull-left">ชื่อสินค้า :</b>
                    </Col>
                    <Col md={10}>
                        <span style={{ color: '#b3b3b3' }}>Cafe' 7 Gold Brand</span>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={2}>
                        <b className="pull-left">รหัสสินค้า :</b>
                    </Col>
                    <Col md={10}>
                        <span style={{ color: '#b3b3b3' }}>TH1025</span>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={2}>
                        <b className="pull-left">ราคา :</b>
                    </Col>
                    <Col md={10}>
                        <span style={{ color: '#b3b3b3' }}>250.00</span>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={2}>
                        <b className="pull-left">Point :</b>
                    </Col>
                    <Col>
                        <Row type="flex" gutter={6}>
                            <Col md={3}><label>PV</label><Input /></Col>
                            <Col md={3}><label>RP</label><Input /></Col>
                            <Col md={3}><label>CV</label><Input /></Col>
                            <Col md={3}><label>TP</label><Input /></Col>
                            <Col md={3}><label>SP</label><Input /></Col>
                            <Col md={3}><label>RP</label><Input /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={2}>
                        <b className="pull-left">จำนวน :</b>
                    </Col>
                    <Col md={10}>
                        <Input />
                    </Col>
                </Row>
            </div>
        </>
    )
}
