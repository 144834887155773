import React, { useEffect, useReducer, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import _ from "lodash";
import XLSX from "xlsx";
import numeral from "numeral";
import { getUser, URL_API, moment, axios } from "../../../../../utils";
import FilterList from "../../components/FilterList";
import { createReducer } from "@reduxjs/toolkit";
// import SaleSummaryContext, {
//   initState,
//   reducer,
// } from "../../contexts/summary-context";
import {
  Col,
  notification,
  Row,
  Popover,
  Table,
  typography,
  Input,
  Button,
  Icon,
  Popconfirm,
} from "antd";
import SelectOption from "../../../pos/components/SelectOption";
import { useHttp } from "../../../../../hooks/http";
import SelectTeam from "../../components/SelectTeam";
import SelectSearch from "../../../pos/components/SelectSearch";
import { resCatch } from "../../../../util/helper";
import { FaTrashAlt, FaInfo } from "react-icons/fa";
const { Text, Title } = typography;
const INIT_STATE = {
  sponsor: {
    page: 1,
    limit: 10,
    data: [],
    data_excel: [],
  },
  binary: {
    page: 1,
    limit: 10,
    data: [],
    data_excel: [],
  },
  search: "",
};
const reducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase("CLEAR", (state) => {
      state = INIT_STATE;
    })
    .addCase("SET_SEARCH", (state, { payload }) => {
      state[payload.type] = {
        ...state[payload.type],
        ...payload,
        // data_excel: paginateData(
        //   payload.page,
        //   payload.limit,
        //   state[payload.type].data
        // ),
      };
    })
    .addCase("LOAD_SUCCESS", (state, { payload }) => {
      state.sponsor.data_excel = payload.data_sponsor;
      state.sponsor.limit = 10;
      state.sponsor.page = 1;
      state.sponsor.data = payload.data_sponsor;
      state.binary.data_excel = payload.data_binary;
      state.binary.limit = 10;
      state.binary.page = 1;
      state.binary.data = payload.data_binary;
    });
});
function paginateData(pageNumber, pageSize, data) {
  let mainData = [];
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const mainItems = data;
  for (let i in mainItems) {
    const main = mainItems[i];
    if (i >= startIndex && i < endIndex) {
      mainData.push(main);
    }
  }
  return mainData;
}
const Index = () => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const [teamFilter, setTeamFilter] = useState("");
  const [source, setSource] = useState(axios.CancelToken.source());
  const [search, setSearch] = useState("");
  const [partnerName, setPartnerName] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [loadingMember, setLoadingMember] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("");
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-descendant`,
      token: true,
      params: {
        partnerCode: partnerCode,
        status: partnerStatusFilter,
        teamName: teamFilter,
        Search: "",
      },
    },
    []
  );
  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-filter`,
      token: true,
    },
    []
  );
  function filterStatus(data) {
    const status_text = _.map(data, (item) => ({
      ...item,
      status_text:
        partnerStatusOptions.find(({ value }) => value === item.status)?.text ||
        "",
      total_current:
        item.current_pv_1 + item.current_pv_2 + item.current_pv_3 || 0,
      total_weak: item.leg_weak_1 + item.leg_weak_2 + item.leg_weak_3 || 0,
    }));
    return status_text;
  }
  useEffect(() => {
    dispatch({
      type: "LOAD_SUCCESS",
      payload: {
        // data: data ? data : [],
        data_sponsor: filterStatus(data?.data_sponsor),
        data_binary: filterStatus(data?.data_binary),
        // data_sponsor: paginateData(
        //   state.sponsor.page,
        //   state.sponsor.limit,
        //   filterStatus(data?.data_sponsor)
        // ),
        // data_binary: paginateData(
        //   state.binary.page,
        //   state.binary.limit,
        //   filterStatus(data?.data_binary)
        // ),
      },
    });
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);
  const partnerStatusOptions = [
    ..._.map(filterDataSource.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];
  const columns = [
    {
      title: "ขั้นที่",
      dataIndex: "level_id",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "รหัสนธก.",
      dataIndex: "partner_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "partner_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "กลุ่มองค์กร",
      dataIndex: "team_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "สถานะ",
      dataIndex: "status_text",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "Honor Pin",
      dataIndex: "honor_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "รักษายอด",
      dataIndex: "total_current",
      align: "left",
      render: (text, record) => (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "4px" }}
        >
          <p className="m-0 text-left">{text}</p>
          <Popover
            content={
              <div>
                <p>เดือนที่ 1 : {record.current_pv_1} </p>
                <p>เดือนที่ 2 : {record.current_pv_2}</p>
                <p>เดือนที่ 3 : {record.current_pv_3}</p>
              </div>
            }
            title="รักษายอด"
            trigger="click"
          >
            <Button
              size="small"
              className=" text-black"
              shape="circle"
              icon="info"
            ></Button>
          </Popover>
        </div>
      ),
    },
    {
      title: "คะแนนทีมอ่อน",
      dataIndex: "total_weak",
      align: "left",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className="m-0 text-left">{text}</p>
          <Popover
            content={
              <div>
                <p>เดือนที่ 1 : {record.leg_weak_1} </p>
                <p>เดือนที่ 2 : {record.leg_weak_2}</p>
                <p>เดือนที่ 3 : {record.leg_weak_3}</p>
              </div>
            }
            title="คะแนนทีมอ่อน"
            trigger="click"
          >
            <Button
              size="small"
              className=" text-black"
              shape="circle"
              icon="info"
            ></Button>
          </Popover>
        </div>
      ),
    },
    {
      title: "รหัสผู้แนะนำ",
      dataIndex: "sponsor_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ชื่อ-นามสกุล ผู้แนะนำ",
      dataIndex: "sponsor_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ผู้คีย์สมัคร",
      dataIndex: "partner_code_form",
      render: (text, record) => (
        <>
          รหัส: <strong>{text ? text : "-"}</strong>
          <br />
          ชื่อ: <strong>{record.partner_code_form_name ? record.partner_code_form_name : "-"}</strong>
        </>
      ),
    }
  ];
  const monthsBefore = (number) => {
    return moment().subtract(number, "months").format("MMMM");
  };
  const handleExportExcel = async () => {

    if (state?.sponsor?.data.length !== 0 || state?.binary?.data.length) {
      let coloumData = [
        ["รหัสสมาชิก : ", partnerCode, "ชื่อสมาชิก : ", partnerName],
        [
          "สถานะ : ",filterDataSource.dataPartnerStatus.find((value) => value.status === partnerStatusFilter)?.name || "ทั้งหมด",
          "Team : ",teamFilter || "ทั้งหมด",
        ],
        [" "],
        [
          "ขั้นที่",
          "รหัสนธก",
          "ชื่อนามสกุล",
          "กลุ่มองค์กร",
          "สถานะ",
          "Honor Pin",
          "รวมรักษายอด",
          "รวมคะแนนทีมอ่อน",
          `PV ${monthsBefore(1)}`,
          `คะแนนทีมอ่อน เดือน${monthsBefore(1)}`,
          `PV ${monthsBefore(2)}`,
          `คะแนนทีมอ่อน เดือน${monthsBefore(2)}`,
          `PV ${monthsBefore(3)}`,
          `คะแนนทีมอ่อน เดือน${monthsBefore(3)}`,
          "รหัสผู้แนะนำ",
          "ชื่อ-นามสกุล ผู้แนะนำ",
        ],
      ];
      let wscols = [
        { wch: 10 },
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 },
        { wch: 20 },
        { wch: 30 },
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
      ];
      const dataExcel = {
        sponsor: state.sponsor.data_excel,
        binary: state.binary.data_excel,
      };
      const createSheetData = (dataExcel, key) => {
        const sheetData = [...coloumData];
        dataExcel[key].forEach((n) => {
          sheetData.push([
            n.level_id,
            n.partner_code || "",
            n.partner_name || "",
            n.team_name || "",
            n.status_text || "",
            n.honor_code || "",
            n.total_current || 0,
            n.total_weak || 0,
            n.current_pv_1 || 0,
            n.leg_weak_1 || 0,
            n.current_pv_2 || 0,
            n.leg_weak_2 || 0,
            n.current_pv_3 || 0,
            n.leg_weak_3 || 0,
            n.sponsor_code || "",
            n.sponsor_name || "",
          ]);
        });
        return sheetData;
      };

      const wb = XLSX.utils.book_new();
      ["sponsor", "binary"].forEach((key, index) => {
        const sheetData = createSheetData(dataExcel, key);
        const ws = XLSX.utils.aoa_to_sheet(sheetData);
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(
          wb,
          ws,
          index === 0 ? "สายเลือด" : "สายงาน"
        );
      });
      XLSX.writeFile(wb, `รายงานสายเลือด - สายงาน.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  const handleClickSearch = () => {
    setLoadingMember(true);
    axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        query: search.trim(),
      },
      cancelToken: source.token,
    })
      .then((res) => {
        setLoadingMember(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            let data = res.data.data[0];
            setMemberList([]);

            setPartnerCode(data?.code);
            setPartnerName(data?.partner_name_th);
          } else {
            setMemberList(res.data.data);
            document.getElementById("memberId").click();
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        setLoadingMember(false);
        resCatch(err);
      });
  };
  const onRow = (record, index) => {
    const highlight_row =
      record.honor_code === "RD" && record.status === "Active";
    return {
      style: {
        backgroundColor: highlight_row ? "rgba(151, 215, 0, 0.2)" : "",
        fontWeight: highlight_row ? "bold" : "",
      },
    };
  };
  return (
    <Panel>
      <PanelHeader>รายงานสายเลือด - สายงาน</PanelHeader>
      <PanelBody>
        <Row gutter={16}>
          {!partnerCode ? (
            <Col span={16} className="mb-2">
              <SelectSearch
                selectId="memberId"
                placeholder="รหัสสมาชิก, ชื่อสมาชิก หรือ ชื่อผู้สมัครร่วม"
                loading={loadingMember}
                disabled={loadingMember}
                loadList={memberList}
                value={search || undefined}
                onClickCancel={() => {
                  source.cancel("cancel");
                  setSource(axios.CancelToken.source());
                }}
                onClickSearch={() => {
                  search && handleClickSearch();
                }}
                onInputKeyDown={(e) => {
                  const key = e.key;
                  if (key === "Enter" && memberList.length === 0 && search) {
                    handleClickSearch();
                  } else if (key === "Backspace" || key === "Delete") {
                    setMemberList([]);
                  }
                }}
                onSearch={(e) => {
                  if (e) {
                    setMemberList([]);
                    setSearch(e);
                  }
                }}
                onSelect={(i) => {
                  const data = memberList[+i];
                  setPartnerCode(data?.code);
                  setPartnerName(data?.partner_name_th);
                  setMemberList([]);
                }}
              />
            </Col>
          ) : (
            <>
              <Col span={8} className="d-flex flex-column">
                <div>
                  <Text className="mr-2">รหัสสมาชิก :</Text>
                  <Text>{partnerCode}</Text>
                </div>
                <div className="my-2">
                  <Text className="mr-2">ชื่อสมาชิก :</Text>
                  <Text>{partnerName}</Text>
                </div>
              </Col>
              <Col span={2} className="text-right">
                <Popconfirm
                  title="ต้องการลบรายการนี้หรือไม่"
                  onConfirm={() => {
                    setMemberList([]);
                    setPartnerCode(null);
                    setPartnerName(null);
                  }}
                  okText="ลบ"
                  cancelText="ไม่ลบ"
                  placement="topRight"
                  zIndex={1080}
                >
                  <Button size="small" ghost type="danger">
                    <FaTrashAlt size={10} />
                  </Button>
                </Popconfirm>
              </Col>
              <Col span={6} />
            </>
          )}
          <Col span={4} className="mb-2">
            <Button type="primary" onClick={reload} block>
              แสดงรายงาน
            </Button>
          </Col>
          <Col span={4} className="mb-2">
            <Button
              type="primary"
              icon="file-excel"
              onClick={handleExportExcel}
              block
            >
              Export Excel
            </Button>
          </Col>
        </Row>
        <Row
          gutter={12}
          type="flex"
          align="middle"
          className="align-items-center p-b-15 p-t-10 align-items-sm-center pl-2"
        >
          <Col>
            <Text strong>สถานะ</Text>
          </Col>
          <Col span={4}>
            <SelectOption
              withAll
              className="w-100"
              placeholder="สถานะ"
              defaultValue={partnerStatusFilter}
              options={partnerStatusOptions}
              onChange={(e) => {
                setPartnerStatusFilter(e);
              }}
            />
          </Col>
          <Col>
            <Text strong>Team</Text>
          </Col>
          <Col span={4}>
            <SelectTeam
              withAll
              defaultValue={teamFilter}
              onChange={(e) => {
                setTeamFilter(e);
              }}
            />
          </Col>
        </Row>
        <Title level={4} className="mb-2">
          สายเลือด
        </Title>
        <Table
          columns={columns}
          bordered
          size="default"
          className="p-b-15"
          scroll={{ x: "max-content" }}
          onRow={onRow}
          loading={loading}
          dataSource={state.sponsor.data}
          rowKey="row_id"
          pagination={{
            pageSize: state.sponsor.limit,
            current: parseInt(state.sponsor.page),
            showTotal: (total, range) =>
              `${numeral(range[0]).format()} - ${numeral(
                range[1]
              ).format()} of ${numeral(total).format()}`,
            onChange: (currPage, pageSize) => {
              dispatch({
                type: "SET_SEARCH",
                payload: {
                  page: currPage,
                  limit: pageSize,
                  type: "sponsor",
                },
              });
            },
            // onShowSizeChange: (current, size) => {
            //   dispatch({
            //     type: "SET_SEARCH",
            //     payload: {
            //       page: 1,
            //       limit: size,
            //       type: "sponsor",
            //     },
            //   });
            // },
          }}
        />
        <Title level={4} className="mb-2">
          สายงาน
        </Title>
        <Table
          columns={columns}
          bordered
          size="default"
          scroll={{ x: "max-content" }}
          loading={loading}
          dataSource={state.binary.data}
          rowKey="row_id"
          onRow={onRow}
          pagination={{
            pageSize: state.binary.limit,
            current: parseInt(state.binary.page),
            showTotal: (total, range) =>
              `${numeral(range[0]).format()} - ${numeral(
                range[1]
              ).format()} of ${numeral(total).format()}`,
            onChange: (currPage, pageSize) => {
              dispatch({
                type: "SET_SEARCH",
                payload: {
                  page: currPage,
                  limit: pageSize,
                  type: "binary",
                },
              });
            },
          }}
        />
      </PanelBody>
    </Panel>
  );
};

export default Index;
