/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { numInt } from "../../../../util/helper/map-data";

const ClaimBomDetail = ({
  list = [],
  qty_balance = 0,
  main_cliam_qty = 0,
  main_qty = 0,
  bom_used = [],
}) => {
  //---------------log data-------
  // _.map(list, (n) =>
  //   console.log({
  //     list: list,
  //     qty_balance: qty_balance,
  //     main_cliam_qty: main_cliam_qty,
  //     main_qty: main_qty,
  //     bom_used: bom_used,
  //     n_claim_qty: n.claim_qty,
  //     n_qty: n.qty,
  //     n_claim_price: n.claim_price,
  //     n_sum_price: (n.claim_qty - main_cliam_qty * n.qty) * n.claim_price,
  //   })
  // );

  return (
    <>
      {list?.map((n, i) => (
        <tr key={i} style={{ opacity: "0.6" }}>
          <td></td>
          <td className="text-center">{n.product_code}</td>
          <td>{`${n.product_name} (${n.qty})`}</td>
          <td className="text-center">{numInt(n.unit_price)}</td>
          <td className="text-center">{numInt(n.pv)}</td>
          <td className="text-center">{numInt(n.qty * main_qty)}</td>
          <td className="text-center">{numInt(n.unit_price * n.qty)}</td>
          <td className="text-center">-</td>
          {/* <td className="text-center">{numInt(n.pv * n.qty)}</td> */}
          {/* <td className="text-center">
              {numInt(
                n.qty * main_qty - (bom_used?.length > 0 ? bom_used[i] : 0)
              )}
            </td> */}
          {/* <td className="text-center">
              {n.qty_balance === undefined
                ? numInt(
                    n.qty * main_qty - (bom_used?.length > 0 ? bom_used[i] : 0)
                  )
                : numInt(n.balance)}
            </td> */}
          <td className="text-center">
            {
              n.qty_balance !== undefined
                ? numInt(n.qty_balance) // display on => รอพิจารณา
                : numInt(n.balance) // display on => ยื่นเคลม
            }
          </td>
          <td className="text-center">{numInt(n.claim_qty)}</td>
          <td className="text-center">
            {numInt((n.claim_qty - main_cliam_qty * n.qty) * n.claim_price)}
          </td>
          {/* <td className="text-center">{numInt(n.claim_price * n.claim_qty)}</td> */}
        </tr>
      ))}
    </>
  );
};

export default React.memo(ClaimBomDetail);
