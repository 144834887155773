import React, { useContext } from "react";
import { Row, Col, Typography } from "antd";
import BillContext from "../../reducer";
import { _ } from "../../../../../utils";

const { Text } = Typography;

const HeaderDetail = () => {
  const {
    state: { billHeader, billDetail },
  } = useContext(BillContext);

  return (
    <Row gutter={[8, 8]}>
      {_.map(billHeader, (n, i) => (
        <Col span={6} key={`header-${i}`}>
          <Text strong>{n.title} : </Text>
          <Text
            className={
              n.value === "clarify_text"
                ? billDetail[n.value] === "แจงแล้ว"
                  ? "text-nn"
                  : billDetail[n.value] === "ยังไม่แจง"
                  ? "text-pv"
                  : ""
                : ""
            }
          >
            {billDetail[n.value]}
          </Text>
        </Col>
      ))}
    </Row>
  );
};

export default HeaderDetail;
