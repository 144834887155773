/* eslint-disable no-unused-vars */
import { Button, Col, DatePicker, Row, Input } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useContext } from "react";
import SaleSummaryContext from "../contexts/summary-context";
import SelectBranch from "../../pos/components/SelectBranch";
import { getUser } from "../../../../utils";
import SelectAllType from "../../../../components/SelectAllType";
const { Search } = Input;

const FilterList = ({
  exportToExcel,
  reload,
  placeholder = "ค้นหา",
  inputSpan = 6,
  showReportSpan = 6,
  Topic,
  topicSpan = 2,
  showBuyerSpan = 2,
  defaultBranchAll = false,
  showBranch = false,
  withAll,
  showDatePicker = true,
  showSearchBox = true,
  showReportPush,
  allowDisableBranch = false,
  topicAlign,
  showBuyerType = false,
}) => {
  const { state, dispatch } = useContext(SaleSummaryContext);
  const toDay = moment().format("DD/MM/YYYY");
  const user = getUser();

  return (
    // <Row gutter={10} type="flex" className="mb-2 align-items-center ">
    // {showSearchBox && (
    //   <Col span={inputSpan}>
    //     <Input
    //       className="w-100 "
    //       placeholder={placeholder}
    //       onChange={(e) =>
    //         dispatch({
    //           type: "setFilter",
    //           payload: { query: e.target.value },
    //         })
    //       }
    //     />
    //   </Col>
    // )}
    // {Topic !== "" && (
    //   <Col span={topicSpan} align={topicAlign}>
    //     <Text strong>{Topic}</Text>
    //   </Col>
    // )}

    // {showDatePicker && (
    //   <Col span={6}>
    //     <DatePicker.RangePicker
    //       className="w-100"
    //       placeholder={[toDay, toDay]}
    //       format="DD/MM/YYYY"
    //       onChange={(e) =>
    //         dispatch({
    //           type: "setFilter",
    //           payload: {
    //             date_from: e[0] || moment(),
    //             date_to: e[1] || moment(),
    //           },
    //         })
    //       }
    //     />
    //   </Col>
    // )}

    // {showBranch && (
    //   <Col>
    //     {" "}
    //     <Text strong>สาขา</Text>{" "}
    //   </Col>
    // )}
    // {showBranch && (
    //   <Col span={4}>
    //     <SelectBranch
    //       withAll={withAll}
    //       userLogin={true}
    //       allowDisableBranch={allowDisableBranch}
    //       defaultValue={defaultBranchAll ? "" : user.branch_code}
    //       placeholder="เลือกสาขา"
    //       className="w-100 p-r-5"
    //       onChange={(e) => {
    //         dispatch({
    //           type: "setFilter",
    //           payload: { branch: e },
    //         });
    //       }}
    //     />
    //   </Col>
    // )}
    //   {showPartnerType && (
    //     <>

    //     </>
    //   )}

    //   {/* <Col span={6}>
    //             <SelectBranch
    //                 placeholder="เลือกสาขา"
    //                 withAll
    //                 className="w-100"
    //                 onChange={(e) => dispatch({
    //                     type: "setFilter",
    //                     payload: { branch: e }
    //                 })}
    //             />
    //         </Col> */}
    //   <Col span={showReportSpan} push={showReportPush}>
    //     <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
    //       <Col>
    //         <Button type="primary" onClick={reload}>
    //           แสดงรายงาน
    //         </Button>
    //       </Col>
    //       <Col>
    //         <Button
    //           type="primary"
    //           className="text-white d-flex align-items-center"
    //           icon="file-excel"
    //           onClick={exportToExcel}
    //         >
    //           Export Excel
    //         </Button>
    //       </Col>
    //     </Row>
    //   </Col>
    // </Row>
    <Row gutter={16}>
      {showSearchBox && (
        <>
        <Col span={inputSpan} className="mb-2">
          {/* <Input
            className="w-100 "
            placeholder={placeholder}
            onChange={(e) =>
              dispatch({
                type: "setFilter",
                payload: { query: e.target.value },
              })
            }
          /> */}
          <Search
            className="w-100 "
            placeholder={placeholder}
            onChange={(e) =>
              dispatch({
                type: "setFilter",
                payload: { query: e.target.value },
              })
            }
            onSearch={reload}
            onPressEnter={reload}
            allowClear
          />
        </Col>
        </>
      )}
      {Topic !== "" && (
        <Col span={topicSpan} align={topicAlign} className="mb-2">
          <Text
            className="text-right d-block"
            strong
            style={{ lineHeight: "32px" }}
          >
            {Topic}
          </Text>
        </Col>
      )}
      {showDatePicker && (
        <Col span={4} className="mb-2">
          <DatePicker.RangePicker
            className="w-100"
            placeholder={[toDay, toDay]}
            format="DD/MM/YYYY"
            onChange={(e) =>
              dispatch({
                type: "setFilter",
                payload: {
                  date_from: e[0] || moment(),
                  date_to: e[1] || moment(),
                },
              })
            }
          />
        </Col>
      )}

      {showBranch && (
        <>
          <Col span={1} className="mb-2">
            <Text
              className="text-right d-block"
              strong
              style={{ lineHeight: "32px" }}
            >
              สาขา
            </Text>
          </Col>
          <Col span={2} className="mb-2">
            <SelectBranch
              withAll={withAll}
              userLogin={true}
              allowDisableBranch={allowDisableBranch}
              defaultValue={defaultBranchAll ? "" : user.branch_code}
              placeholder="เลือกสาขา"
              className="w-100 p-r-5"
              onChange={(e,v) => {
                console.log(v)
                dispatch({
                  type: "setFilter",
                  payload: { branch: e, branch_name:v.props.children },
                });
              }}
            />
          </Col>
        </>
      )}
      {showBuyerType && (
        <>
          <Col span={2} className="mb-2">
            <Text
              className="text-right d-block"
              strong
              style={{ lineHeight: "32px" }}
            >
              ประเภทผู้ซื้อ
            </Text>
          </Col>
          <Col span={showBuyerSpan} className="mb-2">
            <SelectAllType
              withAll
              process_type="buyer_type"
              labelIndex="code"
              defaultValue=""
              placeholder="เลือกประเภทผู้ซื้อ"
              className="w-100 p-r-5"
              onChange={(e) => {
                dispatch({
                  type: "setFilter",
                  payload: { buyer_type: e },
                });
              }}
            />
          </Col>
        </>
      )}
      
      <Col span={showReportSpan / 2} push={showReportPush} className="mb-2">
        <Button type="primary" onClick={reload} block>
          แสดงรายงาน
        </Button>
      </Col>
      <Col span={showReportSpan / 2} push={showReportPush} className="mb-2">
        <Button type="primary" icon="file-excel" onClick={exportToExcel} block>
          Export Excel
        </Button>
      </Col>
    </Row>
  );
};
export default FilterList;
