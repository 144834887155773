import React from "react";
import { Route, Switch } from "react-router-dom";
import RequestForm from "./RequestForm";
import RequestList from "./RequestList";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={match.path} exact children={<RequestList />} />
      <Route path={`${match.path}/:id`} exact children={<RequestForm />} />
    </Switch>
  );
};
