/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import React, { memo, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ValidateError from "../../../../../components/ValidateError";
import { fixAcceptPartner } from "../../../../../config/fix-value";
import { actionClaim } from "../../../../../redux/slices/claim";
import { axios, URL_API, _ } from "../../../../../utils";
import { resCatch } from "../../../../util/helper";
import SelectSearch from "../../../pos/components/SelectSearch";

const { Text } = Typography;

const { clearBill, clearGlobalState, setFormValue } = actionClaim;

const SelectMember = ({ title, type, disabled }) => {
  const code_property = `${_.snakeCase(type)}_code`;
  const name_property = `${_.snakeCase(type)}_name`;
  const mobile_property = `${_.snakeCase(type)}_mobile`;
  const id_card_property = `${_.snakeCase(type)}_id_card`;
  const dispatch = useDispatch();
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const { code, name, mobile, id_card } = useSelector(
    (state) => state.claim[type]
  );
  const doc_status = useSelector((state) => state.claim.documentDetail.status)
  const code_error = useSelector((state) => state.claim.error[code_property]);
  const name_error = useSelector((state) => state.claim.error[name_property]);
  const mobile_error = useSelector((state) =>
    type === "claimBy" ? state.claim.error[mobile_property] : null
  );
  const id_card_error = useSelector((state) =>
    type === "claimBy" ? state.claim.error[id_card_property] : null
  );

  const handleClickSearch = () => {
    setLoading(true);
    axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        query: name.trim(),
      },
      cancelToken: source.token,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            let data = res.data.data[0];
            const status = data.status?.toLowerCase();
            setMemberList([]);
            let check_status =
              type === "claimBy"
                ? fixAcceptPartner.claim_by
                : type === "partner"
                  ? fixAcceptPartner.claim_partner
                  : [];
            if (!_.includes(check_status, status)) {
              // permission = false
              Modal.warning({
                title: "ไม่สามารถดำเนินการได้",
                content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                okText: "ตกลง",
              });
              dispatch(clearGlobalState(type));
            } else {
              // permission true
              dispatch(
                setFormValue({
                  name: type,
                  value: {
                    id: data.member_id,
                    type: data.member_type,
                    code: data.code,
                    name: `${data.first_name_th} ${data.sur_name_th}`,
                    mobile: data.mobile,
                    id_card: data.id_card,
                  },
                  error_name: [
                    name_property,
                    mobile_property,
                    id_card_property,
                  ],
                })
              );
            }
          } else {
            setMemberList(res.data.data);
            document.getElementById(type).click();
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        resCatch(err);
      });
  };

  return (
    <Row gutter={[8, 8]} type="flex" className="mb-2">
      <Col span={6} className="text-right">
        <Text strong>{title}</Text>
      </Col>
      <Col span={18} className="d-flex flex-column">
        {disabled || code ? (
          <Row gutter={[8, 8]} type="flex">
            <Col span={22} className="d-flex flex-column">
              <div>
                <Text className="mr-2">รหัสสมาชิก :</Text>
                <Text>{code}</Text>
              </div>
              <div className="my-2">
                <Text className="mr-2">ชื่อสมาชิก :</Text>
                <Text>{name}</Text>
              </div>
              <div className="my-2">
                <Text className="mr-2">เลขบัตรประชาชน :</Text>
                <Text>{id_card}</Text>
              </div>
              <div>
                <Text className="mr-2">เบอร์โทร :</Text>
                <Text>{mobile}</Text>
              </div>
            </Col>
            {!disabled && (
              <Col span={2} className="text-right">
                <Popconfirm
                  title="ต้องการลบรายการนี้หรือไม่"
                  onConfirm={() => {
                    console.log('type:', type)
                    dispatch(clearGlobalState(type));
                    dispatch(clearBill());
                    setMemberList([]);
                  }}
                  okText="ลบ"
                  cancelText="ไม่ลบ"
                  placement="topRight"
                  zIndex={1080}
                >
                  {doc_status !== 'draft' &&
                    <Button size="small" ghost type="danger">
                      <FaTrashAlt size={10} />
                    </Button>
                  }

                </Popconfirm>
              </Col>
            )}
          </Row>
        ) : (
          <>
            <div className={name_error && "has-error"}>
              <SelectSearch
                size="small"
                selectId={type}
                placeholder="รหัสสมาชิก, เลขบัตรประชาชน, ชื่อ, อีเมล, เบอร์โทร"
                loadList={memberList}
                loading={loading}
                disabled={loading}
                value={name || undefined}
                onClickSearch={() => name && handleClickSearch()}
                onClickCancel={() => {
                  source.cancel("cancel");
                  setSource(axios.CancelToken.source());
                }}
                onInputKeyDown={(e) => {
                  const key = e.key;
                  if (key === "Enter" && memberList.length === 0 && name) {
                    handleClickSearch();
                  } else if (key === "Backspace" || key === "Delete") {
                    setMemberList([]);
                    dispatch(clearGlobalState(type));
                  }
                }}
                onSearch={(e) => {
                  if (e) {
                    setMemberList([]);
                    dispatch(
                      setFormValue({
                        name: type,
                        value: {
                          name: e.trim(),
                        },
                        error_name: name_property,
                      })
                    );
                  }
                }}
                onSelect={(i) => {
                  const data = memberList[+i];
                  const status = data.status?.toLowerCase();
                  setMemberList([]);
                  if (!_.includes(fixAcceptPartner.claim_partner, status)) {
                    // permission false
                    Modal.warning({
                      title: "ไม่สามารถดำเนินการได้",
                      content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                      okText: "ตกลง",
                    });
                    dispatch(clearGlobalState(type));
                  } else {
                    // permission true
                    dispatch(
                      setFormValue({
                        name: type,
                        value: {
                          id: data.member_id,
                          type: data.member_type,
                          code: data.code,
                          name: `${data.first_name_th} ${data.sur_name_th}`,
                          mobile: data.mobile,
                          id_card: data.id_card,
                        },
                        error_name: [
                          name_property,
                          mobile_property,
                          code_property,
                          id_card_property,
                        ],
                      })
                    );
                  }
                }}
              />
            </div>
            <ValidateError error={name_error} />
            {type === "claimBy" && (
              <>
                <div className={id_card_error && "has-error"}>
                  <Input
                    autoComplete="off"
                    placeholder="เลขบัตรประชาชน"
                    size="small"
                    className="w-100 mt-2"
                    value={id_card}
                    onChange={(e) => {
                      dispatch(
                        setFormValue({
                          name: type,
                          value: { id_card: e.target.value },
                          error_name: id_card_property,
                        })
                      );
                    }}
                  />
                </div>
                <ValidateError error={code_error} />
                <div className={mobile_error && "has-error"}>
                  <Input
                    autoComplete="off"
                    placeholder="เบอร์โทร"
                    size="small"
                    className="w-100 mt-2"
                    value={mobile}
                    onChange={(e) =>
                      dispatch(
                        setFormValue({
                          name: type,
                          value: { mobile: e.target.value },
                          error_name: mobile_property,
                        })
                      )
                    }
                  />
                </div>
                <ValidateError error={mobile_error} />
              </>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default memo(SelectMember);
