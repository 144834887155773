import { Button, Col, Modal, notification, Row } from "antd";
import numeral from "numeral";
import React, { useContext } from "react";
import { axios, getToken, getUserObj, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import PreOrderContext from "../../reducer";

const Consider = ({ onComplete, balance, data: { have_product } }) => {
  const {
    state: {
      billModal: { reserve_order_no, id },
    },
    dispatch,
  } = useContext(PreOrderContext);
  const have_product_text =
    have_product === 1 ? "มีสินค้า" : have_product === 0 ? "ไม่มีสินค้า" : "";

  if (!have_product_text) return null;

  return (
    <Row>
      <Col span={24} className="text-center">
        {have_product === 1 ? (
          <Button
            type="primary"
            className="width-150"
            onClick={() => {
              if (balance) {
                notification.warning({
                  message: "กรุณาตรวจสอบยอดรับชำระ",
                  description: `ยอดรับชำระคงเหลือ ${numeral(balance).format(
                    "0,0.00"
                  )} บาท`,
                });
                return;
              }
              Modal.confirm({
                title: "ยืนยันอนุมัติ",
                content: `เลขที่การจอง ${reserve_order_no}`,
                okText: "ยืนยัน",
                cancelText: "ยกเลิก",
                zIndex: 1080,
                onOk() {
                  dispatch({ type: "SET_LOADING", payload: true });
                  const user = getUserObj();
                  axios({
                    method: "post",
                    url: `${URL_API}/pos/operation/reserve/${id}/confirm`,
                    data: {
                      reserve_order_no,
                      bill_branch_code: user.branch_code,
                      bill_branch_name: user.branch_name,
                    },
                    headers: { Authorization: "Bearer " + getToken() },
                  })
                    .then(() => {
                      notification.success({
                        message: "Success",
                        description: "บันทึกรายการเรียบร้อย",
                        placement: "topRight",
                      });
                      onComplete();
                    })
                    .catch((e) => {
                      resCatchModal(e);
                    })
                    .finally(() =>
                      dispatch({ type: "SET_LOADING", payload: false })
                    );
                },
              });
            }}
          >
            เปิดบิล
          </Button>
        ) : (
          <Button
            type="primary"
            className="width-150"
            onClick={() => {
              Modal.confirm({
                title: "ยืนยันแจ้งนักธุรกิจแล้ว",
                content: `เลขที่การจอง ${reserve_order_no}`,
                okText: "ยืนยัน",
                cancelText: "ยกเลิก",
                zIndex: 1080,
                onOk() {
                  dispatch({ type: "SET_LOADING", payload: true });
                  const user = getUserObj();
                  axios({
                    method: "post",
                    url: `${URL_API}/pre-order/flow/cancel`,
                    data: {
                      id,
                      reserve_order_no,
                      branch_code: user.branch_code,
                    },
                    headers: { Authorization: "Bearer " + getToken() },
                  })
                    .then(() => {
                      notification.success({
                        message: "Success",
                        description: "บันทึกรายการเรียบร้อย",
                        placement: "topRight",
                      });
                      onComplete();
                    })
                    .catch((e) => {
                      resCatchModal(e);
                    })
                    .finally(() =>
                      dispatch({ type: "SET_LOADING", payload: false })
                    );
                },
              });
            }}
          >
            แจ้งนักธุรกิจแล้ว
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default Consider;
