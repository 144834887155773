import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jwt from 'jsonwebtoken'
import { setToken, setUser } from "../../utils";
import { UpdateAuth } from '../../redux/actions/auth-action'

export default function (ComposedComponent) {
  class Authenticate extends Component {
    componentDidMount() {
      this.authenticate(this.props);
    }
    componentDidUpdate() {
      this.authenticate(this.props);
    }

    async authenticate(props) {
      const path = window.location.pathname;
      let isExpired = false;

      if (props.isAuthenticated) {
        let decoded = jwt.decode(props.isAuthenticated, { complete: true })
        let now = new Date();
        if (decoded.payload.exp < Math.floor(now.getTime() / 1000)) {
          isExpired = true;
          await this.props.UpdateAuth(null);
          await setToken(null);
          await setUser(null);
        }
      }

      if (!props.isAuthenticated || (props.isAuthenticated && isExpired)) {
        if (path !== "/") {
          // path other
          props.history.replace("/");
        }
      } else {
        if (path === "/") {
          // path login
          props.history.replace("/dashboard");
        }
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  const mapStateToProps = state => ({
    isAuthenticated: state.auth.authenticate
  });

  const mapDispatchToProps = { UpdateAuth };

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Authenticate)
  );
}

// export default Authenticate
