import React, { useContext } from "react";
import { Button, Modal, notification, Typography } from "antd";
import { axios, getToken, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import BillContext from "../../reducer";

const { Text } = Typography;

const SendSubmit = ({ reload }) => {
  const {
    state: {
      billDetail: { id, status: document_status, is_consider },
      info: { send_remark, consider_remark },
    },
    dispatch,
  } = useContext(BillContext);

  const handleClose = () => dispatch({ type: "clearCreate" });

  const setLoading = (loading_submit) =>
    dispatch({
      type: "setGlobalState",
      payload: { name: "info", value: { loading_submit } },
    });

  const handleClickSubmit = (status) => {
    let data_text, text_style, submit_type;
    switch (status) {
      case "wait-operation":
        data_text = "ส่งพิจารณาคำขอ";
        text_style = "text-rp";
        submit_type = "send";
        break;
      case "cancel":
        data_text = "ยกเลิกคำขอ";
        text_style = "text-gray-60";
        submit_type = "send";
        break;
      case "approve":
        data_text = "อนุมัติ";
        text_style = "text-nn";
        submit_type = "consider";
        break;
      case "reject":
        data_text = "ไม่อนุมัติ";
        text_style = "text-gray-60";
        submit_type = "consider";
        break;
      default:
        return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        setLoading(true);
        let data = {
          document_id: id,
          document_status,
          status,
          send_remark,
        };
        if (submit_type === "send") {
          data = { ...data, send_remark };
        } else {
          data = { ...data, consider_remark };
        }
        axios({
          method: "post",
          url: `${URL_API}/bill/${submit_type}`,
          headers: {
            Authorization: "Bearer " + getToken(),
          },
          data,
        })
          .then(() => {
            reload();
            handleClose();
            notification.success({
              message: "บันทึกรายการแล้ว",
            });
          })
          .catch((e) => resCatchModal(e))
          .finally(() => setLoading(false));
      },
    });
  };

  return is_consider ? (
    <>
      <Button
        className="bg-gray-60 border-gray-60 text-white mr-3"
        onClick={() => handleClickSubmit("reject")}
      >
        ไม่อนุมัติ
      </Button>
      <Button
        className="bg-nn border-nn text-white"
        onClick={() => handleClickSubmit("approve")}
      >
        อนุมัติ
      </Button>
    </>
  ) : (
    <>
      <Button
        className="bg-gray-60 border-gray-60 text-white mr-3"
        onClick={() => handleClickSubmit("cancel")}
      >
        ยกเลิกคำขอ
      </Button>
      <Button
        className="bg-rp border-rp text-white"
        onClick={() => handleClickSubmit("wait-operation")}
      >
        ส่งพิจารณาคำขอ
      </Button>
    </>
  );
};

export default SendSubmit;
