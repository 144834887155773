/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Descriptions,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import numeral from "numeral";
import React, { useEffect, useRef, useState } from "react";
import xlsx from "xlsx";
import SelectBatchId from "../../../components/SelectBatchId";
import { useHttp } from "../../../hooks/http";
import { axios, getToken, URL_API, _ } from "../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import SendInterfaceModal from "./components/SendInterfaceModal";

const { Option } = Select;

const Index = () => {
  const [period, setPeriod] = useState("");
  const [batchId, setBatchId] = useState("");
  // const [status, setStatus] = useState("0");
  const [sending, setSending] = useState(false);
  const [show, setShow] = useState(false);
  const batchRef = useRef(null);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/commission/item`,
      token: true,
      params: {
        period,
        batch_id: batchId,
      },
      initialLoad: false,
    },
    []
  );

  const [loadingSummary, dataSummary, errorSummary] = useHttp(
    {
      url: `${URL_API}/commission/item/summary`,
      token: true,
      params: {
        period,
        batch_id: batchId,
      },
    },
    [period, batchId]
  );

  const handleClickExport = () => {
    if ((data?.data || []).length > 0) {
      let source = data.data.map((n) => {
        return {
          รหัสนักธุรกิจ: n.partner_code,
          ชื่อนักธุรกิจ: n.partner_name,
          โบนัสค่าแนะนำ: n.fs,
          โบนัสค่าบริหารทีม: n.ws,
          โบนัสแมชชิ่ง: n.mc,
          โบนัสส่วนบุคคล: n.rebase,
          โบนัสพิเศษ: n.special_bonus,
          จำนวนเงิน: n.total_amount,
          Batch: n.batch_id,
        };
      });

      const ws = xlsx.utils.json_to_sheet(source);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws);
      xlsx.writeFile(wb, `Commission_${batchId}.xlsx`);
    } else {
      message.warning("ไม่มีข้อมูล");
    }
  };

  const handleClickInterface = () => {
    if (period === "") {
      return message.warning("กรุณาเลือกรอบ Commission");
    }
    // setShow(true)

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <Descriptions>
          <Descriptions.Item label="ยังไม่ส่งทั้งหมด" span={3}>
            {`${
              _.find(
                dataSummary?.data?.send,
                (n) => n.oracle_interface_flag === 0
              )?.total || 0
            } รายการ`}
          </Descriptions.Item>
          <Descriptions.Item label="ยังไม่ส่งเป็นเงิน" span={3}>
            {`${numeral(
              _.find(
                dataSummary?.data?.send,
                (n) => n.oracle_interface_flag === 0
              )?.total_amount ||
                0 ||
                0
            ).format("0,0.00")} บาท`}
          </Descriptions.Item>
        </Descriptions>
        // <>
        //   <Row>
        //     <Col>
        //       <Typography.Text strong>ยังไม่ได้ส่ง:</Typography.Text>
        //       <Typography.Text>{` ${
        //         dataSummary?.data.not_send || 0
        //       } รายการ`}</Typography.Text>
        //     </Col>
        //     <Col>
        //       <Typography.Text strong>รวมเป็นเงิน:</Typography.Text>
        //       <Typography.Text>{` ${numeral(
        //         dataSummary?.data.not_send_price
        //       ).format("0,0.00")} บาท`}</Typography.Text>
        //     </Col>
        //   </Row>
        // </>
      ),
      onOk() {
        return new Promise(async (resolve, reject) => {
          axios({
            method: "get",
            url: `${URL_API}/interface/commission`,
            headers: { Authorization: "Bearer " + getToken() },
            params: {
              period,
            },
          })
            .then((res) => {
              resolve();
              setSending(false);
              Modal.success({
                title: "ทำรายการสำเร็จ",
                onOk() {
                  reload();
                  if (batchRef.current) {
                    batchRef.current.reload();
                  }
                },
                okText: "ปิด",
              });
            })
            .catch((err) => {
              reject(err);
              setSending(false);
              reload();
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.message,
            okText: "ตกลง",
          });
        });
      },
      okText: "ส่งข้อมูล",
      cancelText: "ยกเลิก",
    });
    // setSending(true);

    // axios({
    //   method: "get",
    //   url: `${URL_API}/interface/commission`,
    //   headers: { Authorization: "Bearer " + getToken() },
    //   params: {
    //     period,
    //   },
    // })
    //   .then((res) => {
    //     setSending(false);
    //     reload();
    //   })
    //   .catch((err) => {
    //     setSending(false);
    //     message.error(
    //       err && err.response
    //         ? err.response.data.message
    //           ? err.response.data.message
    //           : err.response.data
    //         : err.message
    //     );
    //     reload();
    //   });
  };

  useEffect(() => {
    if (period !== "") {
      reload();
    }
  }, [period, batchId]);

  useEffect(() => {
    setBatchId("");
  }, [period]);

  if (error) {
    message.error(error);
  }

  if (errorSummary) {
    message.error(errorSummary);
  }

  return (
    <Panel>
      <PanelHeader>คอมมิชชั่น</PanelHeader>
      <PanelBody>
        <Row type="flex" gutter={16} className="mb-3">
          <Col md={5}>
            <Select
              placeholder="เลือก Period"
              value={period}
              className="w-100"
              onChange={(value) => setPeriod(value)}
            >
              <Option value="20200601">20200601</Option>
              <Option value="20200616">20200616</Option>
              <Option value="20200701">20200701</Option>
              <Option value="20200716">20200716</Option>
              <Option value="20200801">20200801</Option>
              <Option value="20200816">20200816</Option>
              <Option value="20200901">20200901</Option>
              <Option value="20200916">20200916</Option>
              <Option value="20201001">20201001</Option>
              <Option value="20201016">20201016</Option>
              <Option value="20201101">20201101</Option>
              <Option value="20201116">20201116</Option>
              <Option value="20201201">20201201</Option>
              <Option value="20201216">20201216</Option>
            </Select>
          </Col>
          <Col md={5}>
            {/* <SelectOracleInterfaceStatus
              className="w-100"
              value={status}
              onChange={(value) => setStatus(value)}
            /> */}
            <SelectBatchId
              className="w-100"
              period={period}
              value={batchId}
              onChange={(value) => setBatchId(value)}
              ref={batchRef}
            />
          </Col>
          <Col
            md={{ span: 9, offset: 5 }}
            className="d-flex justify-content-end"
          >
            <Button
              type="dashed"
              loading={sending}
              onClick={handleClickExport}
              className="mr-2"
            >
              Export Excel
            </Button>
            <Button
              type="primary"
              loading={sending}
              onClick={handleClickInterface}
            >
              ส่งข้อมูลหา Oracle
            </Button>
          </Col>
        </Row>

        <Spin spinning={loadingSummary}>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Period" span={3}>
              {period || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="จำนวนทั้งหมด">
              {`${dataSummary?.data?.total?.total || 0} รายการ`}
            </Descriptions.Item>
            <Descriptions.Item label="รวมเป็นเงินทั้งหมด" span={2}>
              {`${numeral(dataSummary?.data?.total?.total_amount || 0).format(
                "0,0.00"
              )} บาท`}
            </Descriptions.Item>
            <Descriptions.Item label="ส่งแล้วทั้งหมด">
              {`${
                _.find(
                  dataSummary?.data?.send,
                  (n) => n.oracle_interface_flag === 1
                )?.total || 0
              } รายการ`}
            </Descriptions.Item>
            <Descriptions.Item label="ส่งแล้วเป็นเงิน" span={2}>
              {`${numeral(
                _.find(
                  dataSummary?.data?.send,
                  (n) => n.oracle_interface_flag === 1
                )?.total_amount ||
                  0 ||
                  0
              ).format("0,0.00")} บาท`}
            </Descriptions.Item>
            <Descriptions.Item label="ยังไม่ส่งทั้งหมด">
              {`${
                _.find(
                  dataSummary?.data?.send,
                  (n) => n.oracle_interface_flag === 0
                )?.total || 0
              } รายการ`}
            </Descriptions.Item>
            <Descriptions.Item label="ยังไม่ส่งเป็นเงิน" span={2}>
              {`${numeral(
                _.find(
                  dataSummary?.data?.send,
                  (n) => n.oracle_interface_flag === 0
                )?.total_amount ||
                  0 ||
                  0
              ).format("0,0.00")} บาท`}
            </Descriptions.Item>
            <Descriptions.Item label="Batch" span={3}>
              {batchId || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="จำนวนทั้งหมด">
              {`${dataSummary?.data?.batch?.total || 0} รายการ`}
            </Descriptions.Item>
            <Descriptions.Item label="รวมเป็นเงิน" span={2}>
              {`${numeral(dataSummary?.data?.batch?.total_amount || 0).format(
                "0,0.00"
              )} บาท`}
            </Descriptions.Item>
          </Descriptions>
        </Spin>

        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={data?.data || []}
          pagination={false}
          rowKey="row_id"
          className="mt-3"
        >
          <Table.Column title="#" dataIndex="row_id" align="right" />
          <Table.Column
            title="รหัสนักธุรกิจ"
            dataIndex="partner_code"
            align="center"
          />
          <Table.Column title="ชื่อนักธุรกิจ" dataIndex="partner_name" />
          <Table.Column
            title="โบนัสค่าแนะนำ"
            dataIndex="fs"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="โบนัสค่าบริหารทีม"
            dataIndex="ws"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="โบนัสแมชชิ่ง"
            dataIndex="mc"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="โบนัสส่วนบุคคล"
            dataIndex="rebase"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="โบนัสพิเศษ"
            dataIndex="special_bonus"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="จำนวนเงิน"
            dataIndex="total_amount"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column title="Batch ID" dataIndex="batch_id" align="center" />
        </Table>

        <SendInterfaceModal
          title="รายละเอียด"
          visible={show}
          zIndex={2000}
          onCancel={() => setShow(false)}
          footer={null}
        />
      </PanelBody>
    </Panel>
  );
};

export default Index;
