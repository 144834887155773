/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import { Button } from "antd";
import axios from "axios";
import React, { Component } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { FaMinus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getToken, URL_API, _ } from "../../../../../utils";
import ModalDefault from "../../../../util/ModalDefault";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import UserListRole from "./UserListRole";

export default class RoleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: [],
      roleID: this.props.match.params.id,
      name: "",
      users: [],
      usersFilter: [],
      userHasIn: [],
      keyword: "",
      checked: [],
      expanded: [],
      roles: [],
    };
    this.onCheck = this.onCheck.bind(this);
    this.onExpand = this.onExpand.bind(this);
  }

  _listRoles = async () => {
    await axios({
      method: "GET",
      baseURL: URL_API,
      url: "/system/roles",
      data: this.state,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        this.setState({ roles: res.data.data });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };

  _getNodes = async (id) => {
    await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/system/roles/${id}/menus`,
      data: this.state,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        this.setState({ nodes: res.data.data.menus });
        this.setState({
          checked: res.data.data.checked.map((n) => parseInt(n)),
        });
        // this.setState({ expanded: res.data.data.checked});
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };
  _get = async (id) => {
    await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/system/roles/${id}`,
      data: this.state,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        this.setState({ userHasIn: res.data.data.users });
        this.setState({ name: res.data.data.name });
        this.setState({ roleID: res.data.data.id });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };

  _listUsers = async () => {
    await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/system/users/`,
      data: this.state,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        this.setState({ users: res.data.data, usersFilter: res.data.data });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };

  async _saveRole() {
    await axios({
      method: "POST",
      baseURL: URL_API,
      url: `/system/roles/${this.state.roleID}`,
      data: {
        name: this.state.name,
        modules: this.state.checked.map((n) => parseInt(n)),
        users: this.state.userHasIn,
      },
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {})
      .catch((err) => {
        console.log("err", err.response);
      });
  }

  addUser = (id) => {
    let _addUsers = this.state.userHasIn;
    _addUsers.push(id);
    this.setState({ userHasIn: _addUsers });
    this._saveRole();
  };

  removeUser = (id) => {
    let userHasTemp = this.state.userHasIn;
    let index = userHasTemp.indexOf(id);
    if (index > -1) {
      userHasTemp.splice(index, 1);
      this.setState({ userHasIn: userHasTemp });
      this._saveRole();
    }
  };

  async onCheck(checked, e) {
    console.log({ checked, e });
    let { treeDepth, parent } = e;
    let half = [...checked.map((n) => parseInt(n))];
    if (e.checked && treeDepth !== 0) {
      half.push(parent.value);
      if (treeDepth === 2) {
        let find = this.state.nodes.find((n) =>
          n.children?.find((m) => {
            console.log(m);
            return (m.value = parent.value);
          })
        ).value;
        if (find) {
          half.push(find);
        }
      }
    }

    console.log({ checked, half: _.uniq(half) });
    await this.setState({ checked: _.uniq(half) });
    await this._saveRole();
  }

  async onExpand(expanded) {
    await this.setState({ expanded });
  }

  componentDidMount() {
    this._get(this.state.roleID);
    this._getNodes(this.state.roleID);
    this._listUsers();
    this._listRoles();
  }

  handleChangeFilter = (e) => {
    let format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    this.setState({ keyword: e.target.value });
    let u = this.state.usersFilter.filter((item) => {
      if (this.state.userHasIn.includes(item.id)) {
        if (!format.test(e.target.value)) {
          return (
            item.code.search(e.target.value) !== -1 ||
            item.first_name
              .toLowerCase()
              .search(e.target.value.toLowerCase()) !== -1 ||
            item.last_name
              .toLowerCase()
              .search(e.target.value.toLowerCase()) !== -1 ||
            (item.department != null ? item.department : "")
              .toLowerCase()
              .search(e.target.value.toLowerCase()) !== -1
          );
        }
      }
    });
    this.setState({ users: u });
  };
  modalUserRole = async (e) => {
    this.setState({ keyword: "" });
    await this._listUsers();
    this.setState({ show: true });
  };
  isClosed = () => {
    this.setState({ show: false });
  };

  changeRole = (e) => {
    this.setState({ roleID: e.target.value });
    this._get(e.target.value);
    this._getNodes(e.target.value);
    this._listUsers();
    this._listRoles();
  };

  render() {
    return (
      <>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/">หน้าแรก</Link>
          </li>
          <li className="breadcrumb-item active">การตั้งค่า</li>
          <li className="breadcrumb-item active">
            <Link to="/setting/permission/role">กำหนดสิทธ์</Link>
          </li>
          <li className="breadcrumb-item active">ผู้ใช้งาน</li>
        </ol>
        <h1 className="page-header">ตั้งค่า สิทธิ์การใช้งาน</h1>
        <div className="row">
          <div className="col">
            <Panel>
              <PanelHeader>Role</PanelHeader>
              <PanelBody>
                <label className="col-form-label">ชื่อ</label>

                <select
                  className="form-control"
                  value={this.state.roleID}
                  onChange={(e) => this.changeRole(e)}
                >
                  {this.state.roles.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <label className="col-form-label">Modules</label>
                <CheckboxTree
                  nodes={this.state.nodes}
                  checked={this.state.checked}
                  expanded={this.state.expanded}
                  onCheck={this.onCheck}
                  onExpand={this.onExpand}
                  showExpandAll={true}
                />
              </PanelBody>
            </Panel>
          </div>
          <div className="col">
            <Panel>
              <PanelHeader>ผู้ใช้งาน</PanelHeader>
              <PanelBody>
                <div className="row">
                  <div className="col text-right">
                    <button
                      type="button"
                      onClick={(e) => this.modalUserRole(e)}
                      className="btn btn-green"
                    >
                      เพิ่ม
                    </button>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <input
                      type="text"
                      value={this.state.keyword}
                      className="form-control"
                      placeholder="Filter"
                      onPaste={this.handleChangeFilter}
                      onKeyPress={this.handleChangeFilter}
                      onChange={this.handleChangeFilter}
                    />

                    <div className="table-responsive mt-2">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="text-center">
                            <th>รหัส</th>
                            <th>ชื่อ</th>
                            <th>นามสกุล</th>
                            <th>แผนก</th>
                            <th>ตำแหน่ง</th>
                            <th>นำออก</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.users.map((item, index) => {
                            if (this.state.userHasIn.includes(item.id)) {
                              return (
                                <tr key={index}>
                                  <td> {item.code} </td>
                                  <td>{item.first_name} </td>
                                  <td>{item.last_name} </td>
                                  <td>{item.department_name}</td>
                                  <td>{item.position_name}</td>
                                  <td className="text-center">
                                    <Button
                                      size="small"
                                      shape="circle"
                                      type="danger"
                                      onClick={(e) => this.removeUser(item.id)}
                                    >
                                      <FaMinus />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
        <ModalDefault
          title="เพิ่ม User"
          size="lg"
          close={() => this.isClosed()}
          show={this.state.show}
          content={
            <UserListRole
              addUser={(id) => this.addUser(id)}
              users={this.state.users}
              userHasIn={this.state.userHasIn}
            />
          }
        />
      </>
    );
  }
}
