/* eslint-disable no-unused-vars */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Button, Typography, } from 'antdV4';
import { Row, Col } from 'antd';
import authscreenConstants from '../../constants/authscreenConstants';
import { AlertSuccess, AlertConfirm } from '../../../../util/Alert';
import { authscreenDefaultValues, authscreenSchema } from '../dto/authscreen';
import ImageUpload from '../upload/ImageUpload';

const AuthscreenForm = ({ getAuthscreenById }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: authscreenDefaultValues,
    resolver: authscreenSchema(),
  });


  const { execute: editAuthscreenById } = useAsync(
    fitStudioService.editAuthscreenById,
    {
      onSuccess: () => {
        AlertSuccess('แก้ไข Wallpaper สำเร็จ');
        getAuthscreenById('1');
        reset(authscreenDefaultValues);
      },
    }
  );

  const _HandleSubmit = handleSubmit(async (data) => {
    const confirm = await AlertConfirm();
    if (confirm) {
      editAuthscreenById({
        id: '1',
        data
      });
    } else {
      return;
    }
  });

  return (
    <div>
      <Controller
        name="imageUrl"
        control={control}
        render={({ field: { value,onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <Typography.Text className="mb-2">
                  แก้ไขรูปภาพ Wallpaper
                </Typography.Text>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={authscreenConstants.width}
                  height={authscreenConstants.height}
                  errors={errors}
                />
              
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className="mt-1"
                >
                  <Button type="primary" onClick={_HandleSubmit}>
                    บันทึก
                  </Button>
                </div>
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
};

export default AuthscreenForm;
