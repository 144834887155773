import { Row } from "antd";
import React from "react";
import { _ } from "../../../../../../utils";
import Amount from "./Amount";
import Item from "./Item";
import Voucher from "./Voucher";

const Index = ({ list }) => (
  <Row gutter={[16, 16]}>
    {_.map(list, (n) =>
      n.source_key === "item" ? (
        <Item data={n} key={`${n.source_key}-${n.source_option}`} />
      ) : n.source_key === "voucher" ? (
        <Voucher data={n} key={`${n.source_key}-${n.source_option}`} />
      ) : (
        <Amount data={n} key={`${n.source_key}-${n.source_option}`} />
      )
    )}
  </Row>
);

export default Index;
