import { Button, Col, Modal, notification, Row, Typography } from "antd";
import axios from "axios";
import React, { useContext } from "react";
import { getToken, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import PreOrderContext from "../../reducer";
import CancelByMember from "./CancelByMember";

const { Text } = Typography;

const SendPO = ({ onComplete }) => {
  const {
    state: {
      billModal: { reserve_order_no, status },
    },
    dispatch,
  } = useContext(PreOrderContext);

  const handleSubmit = () =>
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกดำเนินการเรียบร้อยหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch({ type: "SET_LOADING", payload: true });
        axios({
          method: "post",
          url: `${URL_API}/pre-order/flow/po`,
          headers: { Authorization: "Bearer " + getToken() },
          data: { reserve_order_no, document_status: status },
        })
          .then(() => {
            notification.success({
              message: "Success",
              description: `บันทึกข้อมูลเรียบร้อย`,
            });
            onComplete();
          })
          .catch((e) => {
            resCatchModal(e);
          })
          .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
      },
    });

  return (
    <>
      <Row className="bg-gray-60 py-1 px-2 mb-3">
        <Col span={24}>
          <Text strong className="text-white">
            ดำเนินการ
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="text-center">
          <CancelByMember onComplete={onComplete} />
          <Button type="primary" className="width-150" onClick={handleSubmit}>
            ดำเนินการเรียบร้อย
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SendPO;
