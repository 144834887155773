/* eslint-disable no-unused-vars */
import { Button, Col, Input, Modal, Row } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectAllType from "../../../../../components/SelectAllType";
import SelectOption from "../../../../../components/SelectOption";
import { actionEthics } from "../../../../../redux/slices/ethics";
import TableUploadHistory from "../Table/TableUploadHistory";
import SelectDocumentHistory from "../SelectDocumentHistory";

export default function UploadHistoryModal() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.ethics.modal, shallowEqual);
  const { id, type } = useSelector((state) => state.ethics.form, shallowEqual);
  const { CLOSE_MODAL, SET_MODAL_SEARCH } = actionEthics;

  const closeModal = () => {
    dispatch(CLOSE_MODAL());
  };

  return (
    <Modal
      visible={modal.name === "upload"}
      onCancel={closeModal}
      title="ประวัติการอัปโหลด"
      width={800}
      destroyOnClose
      footer={[
        <Button type="default" key={'close'} onClick={closeModal}>
          ปิด
        </Button>,
      ]}
    >
      <Row gutter={16} className="mb-3">
        <Col md={12}>
        <Input.Search size="small" placeholder="ชื่อพนักงาน,หมายเหตุ"
            onSearch={(e)=>
              dispatch(SET_MODAL_SEARCH({query : e, page : 1}))
            }
          />
        </Col>
        <Col md={6}>
          {/* <SelectAllType
            className="w-100"
            process_type="document_type_local"
            withAll
            size="small"
            value={modal.props.search?.document_type}
            onChange={(e) =>
              dispatch(
                SET_MODAL_SEARCH({
                  document_type: e,
                  page: 1,
                })
              )
            }
          /> */}

          <SelectDocumentHistory
          className="w-100"
          member_id= {id}
          withAll
          size="small"
          value={modal.props.search?.document_type}
          onChange={(e) =>
            dispatch(
              SET_MODAL_SEARCH({
                document_type: e,
                page: 1,
              })
            )
          }
          />
        </Col>
        <Col md={6}>
          <SelectOption
            className="w-100"
            withAll
            size="small"
            data={[
              { value: "approve", text: "Approve" },
              { value: "reject", text: "Reject" },
            ]}
            value={modal.props.search?.status}
            onChange={(e) =>
              dispatch(
                SET_MODAL_SEARCH({
                  status: e,
                  page: 1,
                })
              )
            }
          />
        </Col>
      </Row>
      <TableUploadHistory />
    </Modal>
  );
}
