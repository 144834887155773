export const fitnessBranchFormDefaultValues = {
  id: '',
  branchMasterId: null,
  branchMaster: {
    id: null,
    Code: '',
    Name: '',
    Prefix: '',
    Status: '',
    WHLogisticCoreProduct: '',
    WHLogisticHibrid: '',
    WHCode: '',
    WHLogisticRedeem: '',
    WHClaim: '',
    BranchType: '',
    ShowShopOnline: 0,
    ShowMobile: 0,
    ShowPOS: 0,
    ShowFitStudio: 0,
    AddressNo: null,
    SubDistrict: null,
    District: null,
    Province: null,
    PostCode: null,
    Country: null,
    Tel: null,
    Fax: null,
    GoogleMapPlaceId: null,
    Latitude: null,
    Longitude: null,
    OldBranchCode: null,
    SubsidiaryBranch: null,
    OfficeHourLocal: null,
    OfficeHourEN: null,
    ContactLocal: null,
    ContactEN: null,
    ReceiptBranchCode: '',
  },
  images: [
    {
      imageUrl: '',
      description: '',
    },
  ],
  googleMapUrl: '',
};

export const fitnessTrainerFormDefaultValues = {
  id: '',
  profileImageUrl: '',
  firstName: '',
  lastName: '',
  displayName: '',
  phoneNumber: '',
  formType: 'create',
};
