import React, { memo, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const RenderBomDetail = ({ list, productCode }) => {
  const [expand, setExpand] = useState(true);
  return (
    <>
      <p className="m-0 pl-3">
        <span
          className="cursor-pointer"
          onClick={() => setExpand((prevState) => !prevState)}
        >
          ประกอบด้วย {expand ? <FaAngleUp /> : <FaAngleDown />}
        </span>
      </p>
      {expand &&
        list.map((n, i) => (
          <p
            className={`m-0 pl-4 ${
              n.item_code === productCode ? "text-pv" : ""
            }`}
            key={n.item_code}
          >
            {`${i + 1}.  ${n.product_name_local} (${n.qty})`}
          </p>
        ))}
    </>
  );
};

export default memo(RenderBomDetail);
