import { createReducer } from "@reduxjs/toolkit";
import React from "react";
import { _ } from "../../../../../../utils";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

export const initState = {
  isTemplate: false,
  loading: false,
  data: {
    id: 0,
    description: null,
  },
  media: {
    itemDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  categoryGroup: [],
};

export const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_TEMPLATE: (state) => {
    state.isTemplate = true;
  },
  SET_DATA: (state, { payload }) => {
    state.data = {
      ...state.data,
      ...payload,
    };
  },
  SET_LOAD_SUCCESS: (state, { payload: { id, media, description } }) => {
    state.loading = false;
    state.data.id = id;
    _.forEach(media, (n) => {
      const key = _.uniqueId(`voucher-`);
      state.media.itemKeys = [...state.media.itemKeys, key];
      state.media.itemByKey = {
        ...state.media.itemByKey,
        [key]: n,
      };
    });
    const description_content_bock = description
      ? htmlToDraft(description)
      : null;
    state.data = {
      ...state.data,
      description: description_content_bock
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              description_content_bock.contentBlocks
            )
          )
        : null,
    };
  },
  ADD_MEDIA: (state, { payload }) => {
    const key = _.uniqueId(`voucher-`);
    state.media.itemKeys = [...state.media.itemKeys, key];
    state.media.itemByKey = {
      ...state.media.itemByKey,
      [key]: payload,
    };
  },
  DEL_MEDIA: (state, { payload }) => {
    const { id, temp } = state.media.itemByKey[payload];
    if (!temp) {
      state.media.itemDel = [...state.media.itemDel, id];
    }
    state.media.itemKeys = _.filter(state.media.itemKeys, (n) => n !== payload);
    state.media.itemByKey = {
      ..._.omit(state.media.itemByKey, [payload]),
    };
  },
  SET_CATEGORY_GROUP: (state, { payload }) => {
    state.categoryGroup = payload;
  },
  ADD_CATEGORY_GROUP: (state, { payload }) => {
    state.categoryGroup = [...state.categoryGroup, payload];
  },
  REMOVE_CATEGORY_GROUP: (state, { payload }) => {
    state.categoryGroup = payload;
  },
  CLEAR_CATEGORY_GROUP: (state) => {
    state.categoryGroup = [];
  },
});

export const VoucherContext = React.createContext();
