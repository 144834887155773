/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumb, Button, Card, Col, message, Modal, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { actionEthics } from "../../../redux/slices/ethics";
import { axios } from "../../../utils/useAxios";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import AddressHistoryModal from "./components/Modal/AddressHistoryModal";
import BankHistoryModal from "./components/Modal/BankHistoryModal";
import MemberHistoryModal from "./components/Modal/MemberHistoryModal";
import PreviewDocumentModal from "./components/Modal/PreviewDocumentModal";
import UploadHistoryModal from "./components/Modal/UploadHistoryModal";
import AddressTabs from "./components/Tabs/AddressTabs";
import BankTabs from "./components/Tabs/BankTabs";
import InfoTabs from "./components/Tabs/InfoTabs";
import RemarkTabs from "./components/Tabs/RemarkTabs";
import UploadTabs from "./components/Tabs/UploadTabs";

export default function EthicsInfo() {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.ethics.form.loading,
    shallowEqual
  );
  const { member, married, address, bank } = useSelector(
    (state) => state.ethics.form,
    shallowEqual
  );
  const getDate = member?.register_date;
  const statusProfile = member?.status;

  const formatDatePending = moment(getDate).add(120, "days").calendar();
  const formatDateVacated = moment(getDate).add(60, "days").calendar();

  const active_info = useSelector(
    (state) => state.ethics.form.active_tab.info,
    shallowEqual
  );

  const findMarried = useSelector((state) => state.ethics.findMarried)?.info;

  const infoAddress = useSelector(
    (state) => state.ethics.findMarried
  )?.infoAddress;

  const remark = useSelector((state) => state.ethics.remark, shallowEqual);
  const {
    CLEAR_FORM,
    SET_FORM,
    SET_LOAD_FORM,
    SET_LOAD_FORM_SUCCESS,
    SET_IS_COMPANY,
    SET_REMARK,
  } = actionEthics;

  const infoRef = useRef();
  const addressRef = useRef();
  const bankRef = useRef();

  const handleProfileObject = (_data) => {
    return {
      ..._data,
      birth_date: _data.birth_date
        ? moment(_data.birth_date).format("YYYY-MM-DD")
        : null,
      id_card_expire_date: _data.id_card_expire_date
        ? moment(_data.id_card_expire_date).format("YYYY-MM-DD")
        : null,
      passport_expire_date: _data.passport_expire_date
        ? moment(_data.passport_expire_date).format("YYYY-MM-DD")
        : null,
      company_type: _data.is_company == 1 ? _data.company_type : null,
      company_name: _data.is_company == 1 ? _data.company_name : null,
      company_name_th: _data.is_company == 1 ? _data.company_name_th : null,
      company_name_en: _data.is_company == 1 ? _data.company_name_en : null,
      company_tax: _data.is_company == 1 ? _data.company_tax : null,

      sur_name_th:
        _data.nationality != "TH" &&
        (_data.sur_name_th == "" || _data.sur_name_th == null)
          ? _data.sur_name_en
          : _data.sur_name_th,

      first_name_th:
        _data.nationality != "TH" &&
        (_data.first_name_th == "" || _data.first_name_th == null)
          ? _data.first_name_en
          : _data.first_name_th,
      main:
        _data.is_main === 0 && member?.member_code
          ? member?.member_code
          : findMarried?.partner_code || married?.member_code,
      not_require_id_card: !_data.not_require_id_card
        ? false
        : _data.not_require_id_card,
    };
  };

  const handleSubmit = async () => {
    const _info = await infoRef?.current?.submit();
    const _address = await addressRef?.current?.submit();
    const _bank = await bankRef?.current?.submit();

    let warning_text = "";
    if (_info?.member?.errors) {
      warning_text = "ข้อมูลผู้สมัคร";
    } else if (_info?.married?.errors) {
      warning_text = "ข้อมูลผู้สมัครร่วม";
    } else if (_address?.id_card?.errors) {
      warning_text = "ที่อยู่ตามบัตรประชาชน";
    } else if (_address?.invoice?.errors) {
      warning_text = "ที่อยู่สำหรับออกใบกำกับภาษี";
    } else if (_address?.current?.errors) {
      warning_text = "ที่อยู่ปัจจุบัน";
    } else if (_address?.id_married?.errors) {
      warning_text = "ที่อยู่ตามบัตรประชาชนผู้สมัครร่วม";
    } else if (_bank?.person?.errors) {
      warning_text = "ข้อมูลบัญชีธนาคาร";
    } else if (_bank?.company?.errors) {
      warning_text = "ข้อมูลบัชชีธนาคารสำหรับนิติบุคคล";
    }

    if (
      _info?.member?.errors ||
      _info?.married?.errors ||
      _address?.id_card?.errors ||
      _address?.invoice?.errors ||
      _address?.current?.errors ||
      _address?.id_married?.errors ||
      _bank?.person?.errors ||
      _bank?.company?.errors
    )
      return Modal.warning({
        title: "ไม่สามารถทำรายการได้",
        content: `กรุณาตรวจสอบ ${warning_text} ใหม่อีกครั้ง`,
        okText: "ปิด",
      });

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ปิด",
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            const { id, type } = match?.params;

            const response = await axios.post(`/ethics/${id}/${type}`, {
              member: {
                ...member,
                ...handleProfileObject(_info?.member),
              },
              married: _info?.married
                ? {
                    ...married,
                    ...findMarried,
                    ...handleProfileObject(_info?.married),
                  }
                : null,
              address: address,
              addressMarried: infoAddress,
              findMarried: findMarried ? findMarried : "",

              bank: bank,

              remark: remark === "" ? null : remark,
            });
            if (response.data.error) {
              reject(response.data.error);
            } else {
              resolve(response.data);
            }
          } catch (error) {
            reject(error.response?.data || error.message);
          }
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.goBack();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    const { id, type } = match.params;
    dispatch(CLEAR_FORM());
    dispatch(SET_REMARK(null));
    dispatch(
      SET_FORM({
        id,
        type,
      })
    );

    const loadData = async () => {
      try {
        await dispatch(SET_LOAD_FORM(true));

        const { data } = await axios.get(`/ethics/${id}/${type}`);

        await dispatch(SET_LOAD_FORM_SUCCESS(data));

        await dispatch(SET_IS_COMPANY(data?.member.is_company));
      } catch (error) {
        await dispatch(SET_LOAD_FORM(false));
        message.error(error.message);
      }
    };

    loadData();
  }, [
    match.params,
    match.params.id,
    match.params.type,
    dispatch,
    CLEAR_FORM,
    SET_FORM,
    SET_LOAD_FORM,
    SET_LOAD_FORM_SUCCESS,
  ]);

  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/ethics">ตรวจสอบเอกสาร</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{member?.member_code}</Breadcrumb.Item>
      </Breadcrumb>
      <Panel>
        <PanelHeader>ตรวจสอบเอกสาร</PanelHeader>
        <PanelBody>
          <Spin spinning={loading}>
            {/* EthicsInfo, {match.params.id}, {match.params.type} */}
            <Card
              bordered={false}
              size="small"
              className="mb-3"
              style={{ boxShadow: "0px 2px 4px #aaa" }}
            >
              <Row gutter={16}>
                <Col
                  md={24}
                  className="d-flex justify-content-between align-items-center"
                >
                  {(statusProfile == "Pending" || statusProfile == "Void") &&
                    match?.params.type == "distributor" && (
                      <div className="text-left">
                        <ul>
                          {statusProfile == "Pending" && (
                            <li className="text-black">
                              จะปรับเป็นสถานะ 'โมฆะ' วันที่: {formatDateVacated}
                            </li>
                          )}
                          <li className="text-red">
                            วันสุดท้ายที่สามารถส่งเอกสารได้ ก่อนถูกปรับเป็นสถานะ
                            'ยกเลิก' คือ วันที่ {formatDatePending} ก่อนเวลา
                            23.59 น.
                          </li>
                        </ul>
                      </div>
                    )}

                  <div className="ml-auto">
                    <Button type="primary" onClick={handleSubmit}>
                      บันทึก
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>

            <Row gutter={16}>
              <Col md={12}>
                <InfoTabs ref={infoRef} />
                {(active_info === "member" || active_info === "married") && (
                  <AddressTabs ref={addressRef} />
                )}
                {match.params.type === "distributor" &&
                  active_info === "member" && <BankTabs ref={bankRef} />}
              </Col>
              <Col md={12}>
                <UploadTabs />
                <RemarkTabs />
              </Col>
            </Row>
          </Spin>
        </PanelBody>
      </Panel>
      <BankHistoryModal />
      <AddressHistoryModal />
      <UploadHistoryModal />
      <PreviewDocumentModal />
      <MemberHistoryModal />
    </>
  );
}
