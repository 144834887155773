import { _, moment } from "../../../../../../../utils";

function orderSourceByKey(data, initialData, key) {
  let itemKeys = [...initialData.itemKeys];
  let itemByKey = { ...initialData.itemByKey };
  if (key === "pos") {
    _.forEach(
      _.filter(data, (n) => n.order_source_key === key),
      (n) => {
        const branch_key = _.uniqueId("branch-");
        itemKeys = [...itemKeys, branch_key];
        itemByKey = {
          ...itemByKey,
          [branch_key]: {
            code: n.code,
            name: n.name,
            start_date: n.start_date ? moment(n.start_date) : n.start_date,
            end_date: n.end_date ? moment(n.end_date) : n.end_date,
            branch_key,
          },
        };
      }
    );
  } else {
    const n = _.find(data, (n) => n.order_source_key === key);
    if (n) {
      const branch_key = itemKeys[0];
      itemByKey = {
        [branch_key]: {
          code: n.code,
          start_date: n.start_date ? moment(n.start_date) : n.start_date,
          end_date: n.end_date ? moment(n.end_date) : n.end_date,
          branch_key,
        },
      };
    }
  }
  return { itemKeys, itemByKey };
}

function honorSourceByKey(data, key) {
  let itemKeys = [];
  let itemByKey = {};
  _.forEach(
    _.filter(data, (n) => n.honor_source_key === key),
    (n) => {
      const honor_key = _.uniqueId("honor-");
      itemKeys = [...itemKeys, honor_key];
      itemByKey = {
        ...itemByKey,
        [honor_key]: {
          honor_code: n.honor_code,
          honor_name: n.honor_name,
          honor_key,
        },
      };
    }
  );
  return { itemKeys, itemByKey };
}

export function orderSourceItemMapping(data, initial) {
  return {
    pos: orderSourceByKey(data, initial.pos, "pos"),
    online: orderSourceByKey(data, initial.online, "online"),
    mobile: orderSourceByKey(data, initial.mobile, "mobile"),
  };
}

export function honorSourceItemMapping(data) {
  return {
    honor_code: honorSourceByKey(data, "honor_code"),
    highest_pin: honorSourceByKey(data, "highest_pin"),
    current_pin: honorSourceByKey(data, "current_pin"),
  };
}

export function changeConditionReceiveType(state, type) {
  let mediaDel = [...state[type].mediaDel];
  _.forEach(state[type].itemKeys, (n) => {
    const media = state[type].itemByKey[n].media;
    if (media && !media.temp) {
      mediaDel = [...mediaDel, media.id];
    }
  });
  return {
    mediaDel,
    itemKeys: [],
    itemByKey: {},
  };
}
