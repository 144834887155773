import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Error404 from "../Error404";
//sale
import SaleSummary from "./sale/sale-summary";
import SaleSummaryBranch from "./sale/sale-summary-branch";
import SaleSummaryProduct from "./sale/sale-summary-product";
import SalePayment from "./sale/sale-summary-payment";
import SaleReceiptCancel from "./sale/sale-summary-receipt-cancel";
import SaleDeptReduction from "./sale/sale-summary-dept-reduction";
import SaleDailyRp from "./sale/sale-daily-rp";

import SaleHibridProducts from "./sale/sale-hibrid-products";
import SaleMemberOrder from "./sale/sale-member-order";

//account
import SaleAccount from "./account/sale-account-summary";
import Voucher from "./account/voucher";

//commission
import CommissionSummary from "./commission/commission-summary";
import CommissionBonus from "./commission/commission-bonus";
import CommissionPartnerCode from "./commission/comission-partner-code";
import CommissionIncome from "./commission/commission-income";
//point
import PointSummary from "./point/point-summary";
import RaRemain from "./point/ra-remain";
import RpRemain from "./point/rp-remain";
//Inventory
import StockProductBranch from "./inventory/stock-product-branch";
import StockProductMonth from "./inventory/stock-product-month";
import StockPartner from "./inventory/stock-partner";
import StockPartnerMonth from "./inventory/stock-partner-month";
import StockBranchTransfer from "./inventory/stock-branch-transfer";
import StockProductTransfer from "./inventory/stock-product-transfer";
import StockRemain from "./inventory/stock-current-remain";
import StockInventoryTransfer from "./inventory/stock-inventory-transfer";
import StockReserve from "./inventory/stock-reserve";
import StockLotPeriod from "./inventory/stock-lot-period";
import StockSaleDetail from "./inventory/stock-sale-detail";
import Requisition from "./inventory/requisition";
import StockTransferLowCost from "./inventory/stock-transfer-low-cost";
import StockDeliveryBill from "./inventory/stock-delivery-bill";
import StockTransferWarehouseToBranch from "./inventory/stock-transfer-warehouse-to-branch";
//Partner
import PartnerProfile from "./partner/partner-profile";
import PartnerTax from "./partner/partner-tax";
import PartnerCompany from "./partner/partner-add-company";
import PartnerRegister from "./partner/partner-total-register";

import PartnerCheckDocuments from "./partner/partner-check-documents";
import PartnerRenewMemebership from "./partner/partner-renew-membership";
import PartnerInformationApply from "./partner/partner-information-apply";
import PartnerChangeApplicant from "./partner/partner-change-applicant";
import PartnerRecommend from "./partner/partner-recommend";
import PartnerRemainingPoints from "./partner/partner-remianing-points";
import PartnerDocumentHistory from "./partner/partner-document-history";
import PartnerBirthday from "./partner/partner-birthday";
import PartnerMemberStatus from "./partner/partner-member-status";
import PartnerPvDetail from "./partner/partner-pv-detail";
import PartnerOnRenewMember from "./partner/partner-on-renew-membership";
import PartnerDescendant from "./partner/partner-descendant";
import SurveyPurchaseChannel from "./survey/survey-purchase-channel";

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/sale/sale_summary`} />
      </Route>
      <Route path={`${match.path}/sale/sale_summary`} component={SaleSummary} />
      <Route
        path={`${match.path}/sale/sale_summary_product`}
        component={SaleSummaryProduct}
      />

      <Route
        path={`${match.path}/sale/sale_branch`}
        component={SaleSummaryBranch}
      />
      <Route
        path={`${match.path}/sale/sale_summary_payment`}
        component={SalePayment}
      />
      <Route
        path={`${match.path}/sale/sale_receipt_cancel`}
        component={SaleReceiptCancel}
      />
      <Route
        path={`${match.path}/sale/sale_dept_reduction`}
        component={SaleDeptReduction}
      />
      <Route
        path={`${match.path}/sale/sale-daily-rp`}
        component={SaleDailyRp}
      />
      <Route
        path={`${match.path}/sale/sale-hibrid-products`}
        component={SaleHibridProducts}
      />
      <Route
        path={`${match.path}/sale/sale-member-order`}
        component={SaleMemberOrder}
      />

      <Route
        path={`${match.path}/account/sale_account`}
        component={SaleAccount}
      />
      <Route path={`${match.path}/account/voucher`} component={Voucher} />

      <Route
        path={`${match.path}/commission/commission_summary`}
        component={CommissionSummary}
      />
      <Route
        path={`${match.path}/commission/commission_bonus`}
        component={CommissionBonus}
      />
      <Route
        path={`${match.path}/commission/commission_partnercode`}
        component={CommissionPartnerCode}
      />
      <Route
        path={`${match.path}/commission/commission_income`}
        component={CommissionIncome}
      />
      <Route path={`${match.path}/point-summary`} component={PointSummary} />
      <Route path={`${match.path}/ra-remain`} component={RaRemain} />
      <Route path={`${match.path}/rp-remain`} component={RpRemain} />
      <Route
        path={`${match.path}/inventory/stock-product-branch`}
        component={StockProductBranch}
      />
      <Route
        path={`${match.path}/inventory/stock-product-month`}
        component={StockProductMonth}
      />
      <Route
        path={`${match.path}/inventory/stock-partner-first`}
        component={StockPartner}
      />
      <Route
        path={`${match.path}/inventory/stock-partner-second`}
        component={StockPartnerMonth}
      />
      <Route
        path={`${match.path}/inventory/stock-branch-transfer`}
        component={StockBranchTransfer}
      />
      <Route
        path={`${match.path}/inventory/stock-product-transfer`}
        component={StockProductTransfer}
      />
      <Route
        path={`${match.path}/inventory/stock-current-remain`}
        component={StockRemain}
      />
      <Route
        path={`${match.path}/inventory/stock-inventory-transfer`}
        component={StockInventoryTransfer}
      />
      <Route
        path={`${match.path}/inventory/stock-reserve`}
        component={StockReserve}
      />

      <Route
        path={`${match.path}/inventory/stock-lot-period`}
        component={StockLotPeriod}
      />
      <Route
        path={`${match.path}/inventory/stock-sale-detail`}
        component={StockSaleDetail}
      />
      <Route
        path={`${match.path}/inventory/requisition`}
        component={Requisition}
      />
      <Route
        path={`${match.path}/inventory/stock-transfer-low-cost`}
        component={StockTransferLowCost}
      />
      <Route
        path={`${match.path}/inventory/stock-delivery-bill`}
        component={StockDeliveryBill}
      />
      <Route
        path={`${match.path}/inventory/stock-transfer-warehouse-to-branch`}
        component={StockTransferWarehouseToBranch}
      />
      <Route
        path={`${match.path}/partner/partner-profile`}
        component={PartnerProfile}
      />
      <Route
        path={`${match.path}/partner/partner-tax`}
        component={PartnerTax}
      />
      <Route
        path={`${match.path}/partner/partner-company`}
        component={PartnerCompany}
      />
      <Route
        path={`${match.path}/partner/partner-register`}
        component={PartnerRegister}
      />
      <Route
        path={`${match.path}/partner/partner-check-documents`}
        component={PartnerCheckDocuments}
      />
      <Route
        path={`${match.path}/partner/partner-renew-membership`}
        component={PartnerRenewMemebership}
      />
      <Route
        path={`${match.path}/partner/partner-information-apply`}
        component={PartnerInformationApply}
      />
      <Route
        path={`${match.path}/partner/partner-change-applicant`}
        component={PartnerChangeApplicant}
      />
      <Route
        path={`${match.path}/partner/partner-recommend`}
        component={PartnerRecommend}
      />
      <Route
        path={`${match.path}/partner/partner-remaining-points`}
        component={PartnerRemainingPoints}
      />
      <Route
        path={`${match.path}/partner/partner-document-history`}
        component={PartnerDocumentHistory}
      />
      <Route
        path={`${match.path}/partner/partner-birthday`}
        component={PartnerBirthday}
      />
      <Route
        path={`${match.path}/partner/partner-member-status`}
        component={PartnerMemberStatus}
      />
      <Route
        path={`${match.path}/partner/partner-pv-detail`}
        component={PartnerPvDetail}
      />
      <Route
        path={`${match.path}/partner/partner_on_renew_membership`}
        component={PartnerOnRenewMember}
      />
      <Route
        path={`${match.path}/partner/partner-descendant`}
        component={PartnerDescendant}
      />
      <Route
        path={`${match.path}/survey/survey-purchase-channel`}
        component={SurveyPurchaseChannel}
      />
      <Route component={Error404} />
    </Switch>
  );
};

export default Routes;
