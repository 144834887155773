import { Button, Col, DatePicker, Input, Row, Typography } from "antd";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { GoLinkExternal } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { fixDepartment } from "../../../../../config/fix-value";
import { actionTransfer } from "../../../../../redux/slices/transfer";
import { getUserObj, _ } from "../../../../../utils";
import {
  disabledEndDateSplit,
  disabledStartDateSplit,
} from "../../../../util/helper";
import SelectWarehouse from "../SelectWarehouse";
import SelectStatus from "./SelectStatus";

const { Text } = Typography;
const { setGlobalState } = actionTransfer;

const Index = ({ match, history }) => {
  const user = getUserObj();
  const process_params = match.params.process;
  const type_params = match.params.type;
  const dispatch = useDispatch();
  const {
    query,
    doc_start_date,
    doc_end_date,
    modify_start_state,
    modify_end_date,
    from_wh_code,
    to_wh_code,
    status,
  } = useSelector((state) => state.transfer.searchDocument);
  const is_planning = user.department_id === fixDepartment.operation_support;
  const is_transfer = type_params === "transfer";
  const is_pending = process_params === "pending";
  const is_finish = _.includes(["complete", "cancel"], process_params);
  const is_wait_add = process_params === "wait-add";

  return (
    <>
      <Row gutter={[8, 8]} type="flex">
        <Col span={24} order={2} xxl={{ span: 12, order: 1 }}>
          <Input.Search
            placeholder={`เลขที่ใบโอนย้าย, ${
              !is_transfer && process_params === "complete"
                ? "เลขที่ใบรับสินค้า, "
                : ""
            }ชื่อพนักงาน`}
            autoComplete="off"
            value={query}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: { query: e.target.value, page: 1 },
                })
              )
            }
          />
        </Col>
        {is_transfer && is_pending && (
          <Col
            span={24}
            order={1}
            xxl={{ span: 12, order: 2 }}
            className="text-right"
          >
            <Link to={`${process_params}/0`}>
              <Button type="primary">
                <FaPlus className="mr-2" />
                สร้างเอกสารใบโอนย้ายสินค้า
              </Button>
            </Link>
          </Col>
        )}
        {user.department_id === 1 && is_transfer && is_wait_add && (
          <Col
            span={24}
            order={1}
            xxl={{ span: 12, order: 2 }}
            className="text-right"
          >
            <Button
              type="primary"
              onClick={() => history.push("/transfer-receive/receive-in")}
            >
              <GoLinkExternal className="mr-2" />
              รับสินค้าจากสำนักงานใหญ่
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} type="flex" className="mt-1 mb-3">
        {!is_wait_add && (
          <Col span={24} md={12} xxl={6} className="d-flex align-items-center">
            <div className="width-100 d-flex justify-content-end mr-2">
              <Text>วันที่โอน</Text>
            </div>
            <DatePicker
              className="mr-2"
              placeholder="วันที่เริ่มต้น"
              value={doc_start_date}
              disabledDate={(current) =>
                disabledStartDateSplit(current, doc_end_date)
              }
              onChange={(doc_start_date) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      doc_start_date,
                      page: 1,
                    },
                  })
                )
              }
            />
            <DatePicker
              placeholder="วันที่สิ้นสุด"
              value={doc_end_date}
              disabledDate={(current) =>
                disabledEndDateSplit(current, doc_start_date)
              }
              onChange={(doc_end_date) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      doc_end_date,
                      page: 1,
                    },
                  })
                )
              }
            />
          </Col>
        )}
        {is_finish && (
          <Col span={24} md={12} xxl={6} className="d-flex align-items-center">
            <div className="width-100 d-flex justify-content-end mr-2">
              <Text>
                {process_params === "complete"
                  ? "วันที่รับยอดโอน"
                  : "วันที่ยกเลิก"}
              </Text>
            </div>
            <DatePicker
              className="mr-2"
              placeholder="วันที่เริ่มต้น"
              value={modify_start_state}
              disabledDate={(current) =>
                disabledStartDateSplit(current, modify_end_date)
              }
              onChange={(modify_start_state) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      modify_start_state,
                      page: 1,
                    },
                  })
                )
              }
            />
            <DatePicker
              placeholder="วันที่สิ้นสุด"
              value={modify_end_date}
              disabledDate={(current) =>
                disabledEndDateSplit(current, modify_start_state)
              }
              onChange={(modify_end_date) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: {
                      modify_end_date,
                      page: 1,
                    },
                  })
                )
              }
            />
          </Col>
        )}
        <Col span={24} md={12} xxl={6} className="d-flex align-items-center">
          <div className="width-100 d-flex justify-content-end mr-2">
            <Text>โอนจากคลัง</Text>
          </div>
          <SelectWarehouse
            showSearch
            withAll
            userOnlyLogin={is_transfer && !is_planning}
            filterOption={(input, option) =>
              option.props.children.indexOf(input.toLowerCase()) >= 0
            }
            notIn={[to_wh_code]}
            value={from_wh_code}
            onChange={(from_wh_code) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: { from_wh_code, page: 1 },
                })
              )
            }
          />
        </Col>
        <Col span={24} md={12} xxl={6} className="d-flex align-items-center">
          <div className="width-100 d-flex justify-content-end mr-2">
            <Text>คลังที่รับ</Text>
          </div>
          <SelectWarehouse
            showSearch
            withAll
            userOnlyLogin={!is_transfer && !is_planning}
            filterOption={(input, option) =>
              option.props.children.indexOf(input.toLowerCase()) >= 0
            }
            notIn={[from_wh_code]}
            value={to_wh_code}
            onChange={(to_wh_code) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: { to_wh_code, page: 1 },
                })
              )
            }
          />
        </Col>
        {is_pending && (
          <Col span={24} md={12} xxl={6} className="d-flex align-items-center">
            <div className="width-100 d-flex justify-content-end mr-2">
              <Text>สถานะ</Text>
            </div>
            <SelectStatus
              withAll
              value={status}
              onlyPending={true}
              typeParams={type_params}
              onChange={(status) =>
                dispatch(
                  setGlobalState({
                    name: "searchDocument",
                    value: { status, page: 1 },
                  })
                )
              }
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default withRouter(Index);
