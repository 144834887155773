import { Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectMasterType from "../SelectMasterType"
import { actionRequisition } from "../../../../../redux/slices/requisition";
import Layout from "./Layout";

const { setFormValue } = actionRequisition;

const RequisitionClass = ({ disabled }) => {
  const dispatch = useDispatch();
  const requisition_class = useSelector(
    (state) => state.requisition.documentDetail.requisition_class
  );
  const requisition_class_error = useSelector(
    (state) => state.requisition.error.requisition_class
  );

  return (
    <Layout title="Class :" error={requisition_class_error}>
      {disabled ? (
        <Typography.Text>{requisition_class}</Typography.Text>
      ) : (
        <SelectMasterType
          processType="requisition_class"
          placeholder="เลือก Class"
          value={requisition_class ?? undefined}
          dropdownMatchSelectWidth={false}
          onChange={(requisition_class) =>
            dispatch(
              setFormValue({
                name: "documentDetail",
                value: {
                  requisition_class,
                },
                error_name: "requisition_class",
              })
            )
          }
        />
      )}
    </Layout>
  );
};

export default memo(RequisitionClass);
