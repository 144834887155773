import React from "react";
import BranchModal from "./BranchModal";
import OtherSource from "./OtherSource";
import ShopSource from "./ShopSource";

const OrderSource = () => {
  return (
    <>
      <ShopSource />
      <OtherSource type="online" />
      <OtherSource type="mobile" />
      <BranchModal />
    </>
  );
};

export default OrderSource;
