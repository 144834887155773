/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { Col, Row, Tooltip } from "antd";
import { useEthicsMutate } from "../../../../contexts/ethics.context";

export default function ShowDocumentImg({ url }) {
  const { dispatch } = useEthicsMutate();

  const handleShowPreview = (src) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: { name: "document-icon", props: { src } },
    });
  };

  return (
    <>
      <Row
        type="flex"
        justify="start"
        align="top"
        className="text-center"
        gutter={8}
      >
        {url ?
          <img
            src={url}
            width="50px"
            onClick={() => {
              handleShowPreview(url);
            }}
          /> :
          <img />

        }

      </Row>
    </>

  )

}