/* eslint-disable no-unused-vars */
import { Col, Divider, Row, Typography } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import imgCheck from "../../../../../src/assets/img/pos/checked.png";
import imgUnCheck from "../../../../../src/assets/img/pos/uncheck.png";
import { URL_API, getToken, moment } from "../../../../utils";
const ProfileCD = ({ partnerCode, ...props }) => {
  const [data, setData] = useState(null);
  const [pvPoint, setPVPoint] = useState(0);
  useEffect(() => {
    if (partnerCode) {
      axios({
        method: "GET",
        baseURL: URL_API,
        url: `/pos/member-profile/cd`,
        params: {
          partner_code: partnerCode,
        },
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [partnerCode]);
  useEffect(() => {
    if (data) {
      const registerDate = new Date(data.cd_register_date);
      const expireDate = new Date(data.cd_expire_date);
      const currentDate = new Date();

      // Compare the year and month of cd_register_date with the current date
      if (
        registerDate.getFullYear() >= currentDate.getFullYear() &&
        expireDate >= currentDate
      ) {
        if (registerDate.getMonth() === currentDate.getMonth()) {
          setPVPoint(data.current_pv);
        } else if (registerDate.getMonth() < currentDate.getMonth()) {
          setPVPoint(data.previous_current_pv);
        }
      } else if (registerDate.getFullYear() < currentDate.getFullYear()) {
        setPVPoint(data.previous_current_pv);
      }
    }
  }, [data]);

  if (!data) return <div></div>;
  return (
    <>
      <Divider />
      <Row type="flex" align="middle" className="">
        <Col md={10}>
          <Typography.Title level={3}>สถานะ Certified Dealer</Typography.Title>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="mb-2">
        <Col md={1} className="mr-3">
          <img
            src={data.is_cd ? imgCheck : imgUnCheck}
            alt=""
            width={30}
            height={30}
          ></img>
        </Col>
        <Col md={22} className="d-flex align-items-center">
          <Typography.Title
            className="m-0 font-weight-normal"
            style={{ fontSize: "18px" }}
          >
            ซื้อ Certified Dealer Pack ตามเงื่อนไข
          </Typography.Title>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="mb-2">
        <Col md={1} className="mr-3">
          <img
            src={pvPoint >= 1000 && data.is_cd ? imgCheck : imgUnCheck}
            alt=""
            width={30}
            height={30}
          ></img>
        </Col>
        <Col md={22} className="d-flex align-items-center">
          <Typography.Title
            className="m-0 font-weight-normal"
            style={{ fontSize: "18px" }}
          >
            การได้รับเงินคืนในเดือนปัจจุบัน
          </Typography.Title>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="mb-2">
        <Col md={1} className="mr-3">
          <img
            src={data.current_pv >= 1000 ? imgCheck : imgUnCheck}
            alt=""
            width={30}
            height={30}
          ></img>
        </Col>
        <Col md={22} className="d-flex align-items-center">
          <Typography.Title
            className="m-0 font-weight-normal"
            style={{ fontSize: "18px" }}
          >
            การรักษายอดเดือนปัจจุบัน &nbsp;
          </Typography.Title>
          <Typography.Title
            className="m-0 font-weight-normal "
            style={{
              fontSize: "18px",
              color: `${data.current_pv >= 1000 ? "#97D700" : "#EF4444"}`,
            }}
          >
            {data.current_pv}
          </Typography.Title>
          <Typography.Title
            className="m-0 font-weight-normal"
            style={{ fontSize: "18px" }}
          >
            &nbsp; / 1000
          </Typography.Title>
        </Col>
      </Row>
    </>
  );
};

export default ProfileCD;
