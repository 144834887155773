import { Modal } from 'antd';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { AlertSuccess } from '../../../../util/Alert';
import { fitnessTrainerFormDefaultValues } from '../dto/fitnessbranch';
import TrainerForm from '../form/TrainerForm';

const TrainerModal = ({ open, handleClose, branchId }) => {
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createTrainer } = useAsync(fitStudioService.createTrainer, {
    onSuccess: () => {
      AlertSuccess('เพิ่มครูผู้สอนสำเร็จ');
      reset(fitnessTrainerFormDefaultValues);
      handleClose();
    },
  });

  const { execute: editTrainer } = useAsync(fitStudioService.editTrainer, {
    onSuccess: () => {
      AlertSuccess('แก้ไขครูผู้สอนสำเร็จ');
      reset(fitnessTrainerFormDefaultValues);
      handleClose();
    },
  });

  const _HandleSubmit = handleSubmit((data) => {
    const trainerData = {
      displayName: data.displayName,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      profileImageUrl: data.profileImageUrl,
    };
    if (watch('formType') === 'create') {
      createTrainer({ branchId, data: trainerData });
    } else if (watch('formType') === 'edit') {
      editTrainer({ id: data.id, data: trainerData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create' ? 'เพิ่มครูผู้สอน' : 'แก้ไขครูผู้สอน'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <TrainerForm />
    </Modal>
  );
};

export default TrainerModal;
