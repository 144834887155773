import delivery from "./delivery";
import pickAndPay from "./pick-and-pay";
import checkStockBalance from "./check-stock-balance";
import checkRedeemPoint from "./check-redeem-point";
import CheckCashCard from "./check-cash-card";
export const useWatcher = () => {
  delivery();
  pickAndPay();
  checkStockBalance();
  checkRedeemPoint();
  CheckCashCard();
};
