/* eslint-disable no-unused-vars */
import { Button, Row, Switch, Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col } from "reactstrap";
import { useApi } from "../../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../../redux/slices/marketing/product";
import { axios, getToken, moment, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import { FaPlus, FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import { AlertConfirm, AlertError, AlertSuccess } from "../../../../util/Alert";
import useAsync from "../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../services/manage-website.service";

export default function BenefitsList({ history, match }) {
  const notFoundImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBTEghTW2XOwmp-PbaaC76eKvC7jH61oZfDQ&usqp=CAU";

  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;
  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/home/benefits",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  const { execute: deleteHomeBenefitsById } = useAsync(
    manageWebsiteService.deleteHomeBenefitsById,
    {
      onSuccess: async () => {
        await AlertSuccess("ลบข้อมูลสำเร็จ");
        list.reload();
    
      },
      onFailed: () => {
        AlertError();
      },
    }
  );


  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/home/benefits/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "active" : "inactive",
      },
    })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Panel>
        <PanelHeader>สิทธิประโยชน์</PanelHeader>
        <PanelBody>
          <div
            style={{ display: "flex", justifyContent: "end" }}
            className="mb-5"
          >
            <Button
              type="primary"
              onClick={() => history.push(`${match.path}/add`)}
            >
              <FaPlus />
            </Button>
          </div>

          <Table
            bordered
            size="small"
            loading={list?.loading}
            dataSource={list?.fetch?.data || []}
            rowKey="id"
            pagination={{
              size: "default",
              showLessItems: true,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              total: list?.fetch?.total || 0,
              current: search.page,
              pageSize: search.limit,
              onChange: (page) => dispatch(SET_SEARCH({ page: page })),
            }}
          >
            <Table.Column
              title="No."
              key="no"
              align="center"
              dataIndex="row_id"
            />
            <Table.Column
              title="title"
              key="title"
              align="center"
              dataIndex="title"
              render={(text) => {
                return <div dangerouslySetInnerHTML={{ __html: text }} />;
              }}
            />

            <Table.Column
              title="Public Status"
              key="public_status"
              dataIndex="status"
              align="center"
              render={(text, { id }) => (
                <Switch
                  defaultChecked={text === "active"}
                  onClick={(checked) => handleChangeStatus(checked, id)}
                />
              )}
            />

            <Table.Column
              title="Update Date"
              key="update_date"
              dataIndex="update_date"
              align="center"
              render={(text) =>
                text ? moment(text).format("DD/MM/YYYY HH:mm") : ""
              }
            />
            <Table.Column
              title="Manage"
              key="manage"
              align="center"
              render={(text, { id }) => (
                <Row>
                  <Link to={`${match.path}/${id}`}>
                    <Button type="primary">
                      <FaPencilAlt />
                    </Button>
                  </Link>
                  <Button
                    className="btn-danger ml-1"
                    onClick={async () => {
                      const confirm = await AlertConfirm("ลบข้อมูล");
                      if (confirm) {
                        deleteHomeBenefitsById(id);
                      }
                    }}
                  >
                    <FaRegTrashAlt />
                  </Button>
                </Row>
              )}
            />
          </Table>
        </PanelBody>
      </Panel>
    </>
  );
}
