const publicPlaylistConstants = {
  thumbnail: {
    width: 600,
    height: 600,
  },
  cover: {
    width: 600,
    height: 300,
  },
  video: {
    width: 800,
    height: 450,
  },
  tableSize: 10,
};

export default publicPlaylistConstants;
