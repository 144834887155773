/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, Upload, Button, Icon } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import * as xlsx from 'xlsx';

const LegacyNotificationPartnerListForm = () => {
  const {
    setValue,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const props = {
    onRemove: (file) => {
      setValue('file', undefined);
    },
    beforeUpload: (file) => {
      setValue('file', file);

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        const partnerCodeLists = json
          .map((item) => Object.values(item))
          .map((item) => item[0]);

        setValue('partnerList', partnerCodeLists.join(','));
      };
      reader.readAsBinaryString(file);

      return false;
    },
  };

  return (
    <div>
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อรายการ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="หัวข้อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Upload
        {...props}
        multiple={false}
        accept=".xlsx"
        fileList={watch('file') ? [watch('file')] : []}
      >
        <Button>
          <Icon type="upload" /> เลือกไฟล์ xlsx
        </Button>
      </Upload>
      <Typography.Text>{watch('partnerList')}</Typography.Text>
    </div>
  );
};

export default LegacyNotificationPartnerListForm;
