/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Input, Select, Table, Typography } from 'antd';
import { FaDownload, FaPen, FaPlus, FaSearch, FaUpload } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { lobDefaultValues, lobFormSchema } from '../components/dto/lob.dto';
import lobServices from '../../../../services/lob.services';
import LodModal from '../components/modal/LodModal';
import 'dayjs/locale/th';
import { FaCaretUp } from 'react-icons/fa';
import { FaCaretDown } from 'react-icons/fa';

dayjs.locale('th');

const pageSize = 50;

const LobPage = () => {
  const [lobFormOpen, setLobFormOpen] = useState(false);
  const {
    data: lobLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const lobForm = useForm({
    defaultValues: lobDefaultValues,
    resolver: lobFormSchema(),
  });

  const _HandleLobFormOpen = (formType) => {
    lobForm.setValue('formType', formType);
    setLobFormOpen(true);
  };

  const _HandleLobFormClose = () => {
    lobForm.reset(lobDefaultValues);
    setLobFormOpen(false);
  };

  const { control, watch, setValue } = useForm({
    defaultValues: {
      searchText: '',
      memberCode: '',
      expiredDate: '',
      lobNo: '',
      StatusText: '',
    },
  });

  const { refetch: getLob } = lobServices.useQueryGetLob(
    {
      take: pageSize,
      page,
      searchText: watch('searchText'),
      memberCode: watch('memberCode'),
      expiredDate: watch('expiredDate'),
      lobNo: watch('lobNo'),
      StatusText: watch('StatusText'),
    },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
      setLoading3(false);
    }
  );

  const _HandleSubmit = () => {
    getLob();
  };

  const { mutate: deleteLob } = lobServices.useMutationDeleteLob(() => {
    queryClient.invalidateQueries({
      queryKey: ['get-lobs'],
      refetchType: 'all',
    });
  });

  const { mutate: downloadLodListsExcel } =
    lobServices.useMutationDownloadLodListsExcel(() => {
      setTimeout(() => {
        setLoading2(false);
      }, 600);
    });

  const columns = [
    {
      title: () => {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            รหัสนักธุรกิจ
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => {
                  setValue('memberCode', 'DESC');
                  setValue('expiredDate', '');
                  setValue('lobNo', '');
                }}
              >
                <FaCaretUp
                  style={{
                    color: watch('memberCode') === 'DESC' ? '#97D700' : '#000',
                    marginBottom: -10,
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setValue('memberCode', 'ASC');
                  setValue('expiredDate', '');
                  setValue('lobNo', '');
                }}
              >
                <FaCaretDown
                  style={{
                    color: watch('memberCode') === 'ASC' ? '#97D700' : '#000',
                    marginTop: -10,
                  }}
                />
              </div>
            </div>
          </span>
        );
      },
      dataIndex: 'memberCode',
      key: 'memberCode',
      // sorter: (a, b) => parseInt(a.memberCode) - parseInt(b.memberCode),
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>{record.firstName + ' ' + record.lastName}</span>
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'memberStatus',
      key: 'memberStatus',
      render: (_, record) => <span>{record?.memberProfile?.StatusText}</span>,
    },
    {
      title: () => {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            วันหมดอายุนธก.
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => {
                  setValue('memberCode', '');
                  setValue('expiredDate', 'DESC');
                  setValue('lobNo', '');
                }}
              >
                <FaCaretUp
                  style={{
                    color: watch('expiredDate') === 'DESC' ? '#97D700' : '#000',
                    marginBottom: -10,
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setValue('memberCode', '');
                  setValue('expiredDate', 'ASC');
                  setValue('lobNo', '');
                }}
              >
                <FaCaretDown
                  style={{
                    color: watch('expiredDate') === 'ASC' ? '#97D700' : '#000',
                    marginTop: -10,
                  }}
                />
              </div>
            </div>
          </span>
        );
      },
      dataIndex: 'expiredMemberDate',
      key: 'expiredMemberDate',
      render: (_, record) => (
        <span>
          {record?.memberProfile?.ExpireDate &&
            dayjs(record?.memberProfile?.ExpireDate).format('DD/MMM/BBBB')}
        </span>
      ),
    },
    {
      title: () => {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            รหัส LOD
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => {
                  setValue('memberCode', '');
                  setValue('expiredDate', '');
                  setValue('lobNo', 'DESC');
                }}
              >
                <FaCaretUp
                  style={{
                    color: watch('lobNo') === 'DESC' ? '#97D700' : '#000',
                    marginBottom: -10,
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setValue('memberCode', '');
                  setValue('expiredDate', '');
                  setValue('lobNo', 'ASC');
                }}
              >
                <FaCaretDown
                  style={{
                    color: watch('lobNo') === 'ASC' ? '#97D700' : '#000',
                    marginTop: -10,
                  }}
                />
              </div>
            </div>
          </span>
        );
      },
      dataIndex: 'lobNo',
      key: 'lobNo',
      render: (_, record) => <span>{record.lobNo}</span>,
      // sorter: (a, b) => a.lobNo - b.lobNo,
    },
    {
      title: 'ชื่อร้านค้า',
      dataIndex: 'storeName',
      key: 'storeName',
      render: (_, record) => (
        <span>
          {record.links.map((item) => {
            return (
              <div sx={{ width: '100%' }}>
                {item.platform.toLocaleUpperCase() + ' : ' + item.url}
              </div>
            );
          })}
        </span>
      ),
    },
    {
      title: 'วันหมดอายุ LOD',
      dataIndex: 'expiredDate2',
      key: 'expiredDate2',
      render: (_, record) => (
        <span>
          {dayjs(record.expiredDate).subtract(7, 'hours').format('DD/MMM/BBBB')}
        </span>
      ),
      // sorter: (a, b) =>
      // dayjs(a.expiredDate).diff(dayjs(b.expiredDate), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/BBBB')}</span>
      ),
      // sorter: (a, b) =>
      //   dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              lobForm.setValue('id', record.id);
              _HandleLobFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLob({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  const { mutate: uploadPatientExcel } = lobServices.useMutationUploadLodExcel(
    (data) => {
      setTimeout(() => {
        inputRef.current.value = null;

        setLoading(false);
      }, 600);
    }
  );

  const _HandleUploadPatientExcel = (data) => {
    setLoading(true);
    uploadPatientExcel(data);
  };

  return (
    <Panel>
      <PanelHeader>Legacy Online Distributor</PanelHeader>
      <PanelBody>
        <div
          style={{ display: 'flex', alignItems: 'flex-end' }}
          className="mb-2"
        >
          <Controller
            name="searchText"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ค้นหาด้วยชื่อ-สกุล, รหัส LOD, ชื่อร้านค้า หรือ รหัสนักธุรกิจ"
                  className="mr-2"
                />
              );
            }}
          />
          <div style={{ width: 200 }}>
            <Typography.Text>สถานะ</Typography.Text>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <Controller
                name="StatusText"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      style={{ width: 200, marginRight: 10 }}
                      onChange={(status) => {
                        setLoading3(true);
                        onChange(status);
                      }}
                      placeholder="สถานะ"
                    >
                      <Select.Option value={''}>ทั้งหมด</Select.Option>
                      {[
                        'ปกติ',
                        'รอเอกสารหลัก',
                        'รอสมุดบัญชี',
                        'โมฆะ',
                        'ผิดจรรยาบรรณ',
                        'ช่วงต่ออายุสาย 90 วัน',
                        'ลาออก',
                        'สิ้นสุดสมาชิกภาพ',
                        'หมดอายุ',
                        'ยกเลิก',
                      ].map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  );
                }}
              />
            </div>
          </div>
          <input
            type="file"
            id={'patient'}
            onChange={(e) => {
              if (e.target.files?.length > 0) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                _HandleUploadPatientExcel(formData);
              }
            }}
            style={{ display: 'none' }}
            ref={inputRef}
          />
          <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={_HandleSubmit}
          >
            <FaSearch className="mr-2" />
            ค้นหา
          </Button>
          <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={() => {
              setLoading2(true);
              downloadLodListsExcel({
                take: -1,
                page: 1,
                searchText: watch('searchText'),
                memberCode: watch('memberCode'),
                expiredDate: watch('expiredDate'),
                lobNo: watch('lobNo'),
              });
            }}
            loading={loading2}
          >
            <FaDownload className="mr-2" />
            โหลด excel
          </Button>
          <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
            loading={loading}
          >
            <FaUpload className="mr-2" />
            อัพโหลด excel
          </Button>
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleLobFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่ม LOD
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={lobLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
          loading={loading3}
        />
      </PanelBody>
      <FormProvider {...lobForm}>
        <LodModal open={lobFormOpen} handleClose={_HandleLobFormClose} />
      </FormProvider>
    </Panel>
  );
};

export default LobPage;
