import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import TripRoute from './trip/routes'
import Error404 from '../Error404';
export default withRouter(({ match }) => {
    return (
        <Switch>
            <Route path={`${match.path}/trip`} component={TripRoute} />
            <Route component={Error404} />
        </Switch>
    )
})
