/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Row } from "antd";
import React, { memo, useEffect, useReducer } from "react";
import { axios, getToken, URL_API } from "../../../../../../../utils";
import ContactCard from "./ContactCard";
import MemberCard from "./MemberCard";
import MemberSummaryCard from "./MemberSummaryCard";
import Profile from "./Profile";
import ProfileCard from "./ProfileCard";
import InfoContext, { initState, reducer } from "./reducer";
import Remark from "./Remark";
import SelectMemberType from "./SelectMemberType";

const Index = ({ partnerCode, refId, refType, hiding }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const handleLoadProfile = async () => {
    dispatch({
      type: "setState",
      payload: { name: "loading", value: true },
    });
    try {
      const { partner_code, ref_id, ref_type } = state.data;
      const res = await axios({
        url: `${URL_API}/pos/member-profile${
          ref_type === "Member" ? "/vip" : ""
        }`,
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          partner_code,
          ref_id,
        },
      });
      if (!res.data?.data) return;
      dispatch({
        type: "loadInfo",
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: "setState",
        payload: {
          name: "error",
          value: e.response?.data?.message || e.message,
        },
      });
    }
    dispatch({
      type: "setState",
      payload: { name: "loading", value: false },
    });
  };

  useEffect(() => {
    if (state.data.partner_code) handleLoadProfile();
  }, [state.data.partner_code]);

  useEffect(() => {
    if (partnerCode && refId && refType)
      dispatch({
        type: "initData",
        payload: {
          partner_code: partnerCode,
          ref_id: refId,
          ref_type: refType,
        },
      });
  }, [partnerCode, refId, refType]);

  if (state.error) return <Alert type="error" message={state.error} showIcon />;

  return (
    <InfoContext.Provider value={{ state, dispatch }}>
      <Row gutter={[8, 8]} type="flex">
        <Col span={6}>
          <Profile />
        </Col>
        <Col span={18}>
          {!hiding &&
            state.data.ref_type === "Distributor" &&
            !state.loading && <SelectMemberType />}
          <Row gutter={[8, 8]} type="flex" className="mt-5 align-items-center">
            <Col span={12}>
              <MemberCard />
              {
            state.data.ref_type !== "Distributor" &&
            !state.loading && <MemberSummaryCard/>}
            </Col>
            <Col span={12}>
              <ProfileCard />
              <ContactCard />
            </Col>
          </Row>
          <Remark />
        </Col>
      </Row>
    </InfoContext.Provider>
  );
};

export default memo(Index);
