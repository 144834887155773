import { Col, Divider, Empty, Row, Typography } from 'antd';
import React from 'react';
import img from '../../../../../assets/img/pos/no-img.jpg';
import styles from '../../../../../scss/module/legal.module.scss';
import { bytesToSize } from '../../../../util/helper';

const DocumentData = ({ data = {}, disableDivider, onSelect, activeId }) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Text strong>{data.name}</Typography.Text>
        </Col>
      </Row>
      <Row>
        {
          (data.item && data.item.length > 0) ? data.item.map((n, i) => (
            <Col key={i} span={12} xl={8} xxl={6}>
              <div className={styles['data-content']}>
                <div className={`${styles['img-preview']} ${activeId === n.id ? styles.active : ''}`} onClick={() => onSelect({ img_url: n.azure_url, id: n.id })}>
                  <img className="w-100 h-100 object-fit-cover" src={n.azure_url ? `${n.azure_url}` : img} alt='document' />
                </div>
                <Typography.Text className="text-overflow w-100">{n.file_name}</Typography.Text>
                <Typography.Text className="text-gray-60">{bytesToSize(n.file_size)}</Typography.Text>
              </div>
            </Col>
          )) : (
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <Empty />
              </Col>
            )
        }
      </Row>
      {
        disableDivider ?
          <div className="mb-3"></div>
          : <Divider />
      }
    </>
  )
}

export default DocumentData
