import React from 'react';
import { Col, Row, Typography } from 'antd';

const RowRecord = ({ dataSource = [], row }) => (
    <div>
        {
            dataSource.length > 0 && (
                dataSource.map((item, i) => item.length === 3 || row === 3 ? (
                    <Row gutter={8} type="flex" className="mb-2 mt-3 align-items-center" key={i}>
                        <Col md={3}>
                            <Typography.Text strong align="right">{item[0]?.title}</Typography.Text>
                        </Col>
                        <Col md={5}>
                            <Typography.Text>{item[0]?.value}</Typography.Text>
                        </Col>
                        <Col md={3}>
                            <Typography.Text strong align="right">{item[1]?.title}</Typography.Text>
                        </Col>
                        <Col md={5}>
                            <Typography.Text>{item[1]?.value}</Typography.Text>
                        </Col>
                        <Col md={3}>
                            <Typography.Text strong align="right">{item[2]?.title}</Typography.Text>
                        </Col>
                        <Col md={5}>
                            <Typography.Text>{item[2]?.value}</Typography.Text>
                        </Col>
                    </Row>
                ) : item.length === 2 || row === 2 ? (
                    <Row gutter={8} type="flex" className="mb-2 mt-3 align-items-center" key={i}>
                        <Col md={5}>
                            <Typography.Text strong align="right">{item[0]?.title}</Typography.Text>
                        </Col>
                        <Col md={7}>
                            <Typography.Text>{item[0]?.value}</Typography.Text>
                        </Col>
                        <Col md={5}>
                            <Typography.Text strong align="right">{item[1]?.title}</Typography.Text>
                        </Col>
                        <Col md={7}>
                            <Typography.Text>{item[1]?.value}</Typography.Text>
                        </Col>
                    </Row>
                ) : item.length === 1 || row === 1? (
                    <Row gutter={8} type="flex" className="mb-2 mt-3 align-items-center" key={i}>
                        <Col md={5}>
                            <Typography.Text strong align="right">{item[0]?.title}</Typography.Text>
                        </Col>
                        <Col md={19}>
                            <Typography.Text>{item[0]?.value}</Typography.Text>
                        </Col>
                    </Row>
                ) : null)
            )
        }
    </div>
)

export default RowRecord
