import React from "react";
import { Select } from "antd";

const List = [
  { name: "ซื้อ", value: "pre-sale" },
  { name: "แลก", value: "pre-redeem" },
];

const SelectReserveType = ({ className, withAll, ...props }) => {
  return (
    <Select className={`w-100 ${className || ""}`} {...props}>
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {List.map((n) => (
        <Select.Option key={n.value} value={n.value}>
          {n.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectReserveType;
