/* eslint-disable no-unused-vars */
import { Button, Col, DatePicker, Input, Row, Typography } from "antd";
import React, { useContext } from "react";
import { getUser } from "../../../../../utils";
import SelectBranch from "../../../pos/components/SelectBranch";
import PreOrderContext from "../../reducer";
import SelectReserveType from "./SelectReserveType";
import SelectStatus from "./SelectStatus";

const { Text } = Typography;

const Index = ({ onClickExport }) => {
  const {
    state: {
      search: { query, status, type, date, branch_code },
    },
    dispatch,
  } = useContext(PreOrderContext);

  return (
    <>
      <Row gutter={[16, 16]} type="flex">
        <Col
          span={24}
          order={2}
          md={{ span: 18, order: 1 }}
          xl={{ span: 12, order: 1 }}
        >
          <Input.Search
            placeholder="เลขที่ชุดจอง/เลขที่ใบจอง/รหัสนักธุรกิจ/ผู้ทำรายการจอง/ผู้ดำเนินการ/พนักงาน"
            value={query}
            onChange={(e) =>
              dispatch({
                type: "SET_GLOBAL_STATE",
                payload: {
                  name: "search",
                  value: {
                    query: e.target.value,
                    page: 1,
                  },
                },
              })
            }
          />
        </Col>
        <Col
          span={24}
          order={1}
          md={{ span: 6, order: 2 }}
          xl={{ span: 12, order: 2 }}
          className="text-right"
        >
          <Button type="primary" onClick={onClickExport}>
            Export Excel
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} type="flex" className="my-2 align-items-center">
        <Col span={0} xl={1} className="text-right">
          <Text className="text-nowrap">สาขา</Text>
        </Col>
        <Col span={24} md={12} xl={5}>
          <SelectBranch
            placeholder="เลือกสาขา"
            withAll={true}
            userLogin={true}
            value={branch_code ?? undefined}
            className="w-100"
            onChange={(branch_code, e) =>
              dispatch({
                type: "SET_GLOBAL_STATE",
                payload: {
                  name: "search",
                  value: {
                    branch_code,
                    branch_name: e.props.children,
                    page: 1,
                  },
                },
              })
            }
          />
        </Col>
        <Col span={0} xl={1} className="text-right">
          <Text className="text-nowrap">ประเภท</Text>
        </Col>
        <Col span={24} md={12} xl={5}>
          <SelectReserveType
            withAll
            value={type}
            onChange={(type, e) =>
              dispatch({
                type: "SET_GLOBAL_STATE",
                payload: {
                  name: "search",
                  value: {
                    type,
                    type_name: e.props.children,
                    page: 1,
                  },
                },
              })
            }
          />
        </Col>
        <Col span={0} xl={1} className="text-right">
          <Text className="text-nowrap">สถานะ</Text>
        </Col>
        <Col span={24} md={12} xl={5}>
          <SelectStatus
            withAll
            value={status}
            onChange={(status, e) =>
              dispatch({
                type: "SET_GLOBAL_STATE",
                payload: {
                  name: "search",
                  value: {
                    status,
                    status_name: e.props.children,
                    page: 1,
                  },
                },
              })
            }
          />
        </Col>
        <Col span={0} xl={1} className="text-right">
          <Text className="text-nowrap">วันที่</Text>
        </Col>
        <Col span={24} md={12} xl={5}>
          <DatePicker
            className="w-100 min-width-0"
            placeholder="เลือกวันที่"
            format="DD/MM/YYYY"
            value={date}
            onChange={(date) =>
              dispatch({
                type: "SET_GLOBAL_STATE",
                payload: {
                  name: "search",
                  value: {
                    date,
                    page: 1,
                  },
                },
              })
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Index;
