/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { axios, getToken } from "../utils";

export const getHttpCancelTokenSource = () => {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
};

export const useHttp = (
  {
    url = "",
    method = "get",
    params = {},
    data = null,
    token = false,
    cancelToken = null,
    onSuccess = null,
    initialLoad = true,
  },
  dependencies = []
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [error, setError] = useState(null);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const reload = () => {
    setIsLoading(true);
    setError(null);

    axios({
      url,
      method: method.toLowerCase(),
      params,
      data,
      headers: token ? { Authorization: "Bearer " + getToken() } : null,
      cancelToken: source.token,
    })
      .then((res) => {
        setIsLoading(false);
        if (onSuccess) {
          setFetchedData(onSuccess(res.data));
        } else {
          setFetchedData(res.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          // handle error
          console.log(error.response);
          setIsLoading(false);
          setError(
            error && error.response
              ? error.response.data.message
              : error.message
          );
        }
      });
  };

  useEffect(() => {
    if (initialLoad) {
      reload();
    }

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, dependencies);

  return [isLoading, fetchedData, error, reload];
};

export async function Http({
  url = "",
  method = "get",
  data = null,
  token = false,
}) {
  let response = null;
  await axios({
    url,
    method: method.toLowerCase(),
    data,
    headers: token ? { Authorization: "Bearer " + getToken() } : null,
  })
    .then((res) => {
      response = res;
    })
    .catch((error) => {
      response = error.response;
    });

  return response;
}
