/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  notification,
  Row,
  Table,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { axios, getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectCategory from "../../components/SelectCategory";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import SelectAllType from "../../../../../components/SelectAllType";
import Search from "antd/lib/input/Search";

const { Text } = Typography;

const Index = () => {
  const limit = 50;
  const user = getUser();
  const toDay = moment().format("DD/MM/YYYY");

  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: [user.branch_code],
      query: "",
      page: 1,
      limit: 50,
      category: "",
      subCategory: "",
      group: "",
      buyer_type: "",
    },
    summaryModal: {
      visible: false,
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const [checkBranch, setCheckBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([user.branch_name]);
  const [displayBranch, setDisplayBranch] = useState([
    user.branch_name,
    "total",
  ]);
  // const [branchGroup, setBranchGroup] = useState([]);
  const [page, setPage] = useState(1);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [CategoryText, setCategoryText] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [SubCategoryText, setSubCategoryText] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [GroupText, setGroupText] = useState("");

  const branchFilter = _.join(
    _.reduce(state.filter.branch, (res, key) => [...res, key], []),
    ","
  );

  // const { execute: getAllBranch } = useAsync(
  //   manageWebsiteService.getAllBranch,
  //   {
  //     immediate: true,
  //     onSuccess: (data) => {
  //       setCheckBranch(data);
  //     },
  //   }
  // );

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-product`,
      token: true,
      params: {
        ...state.filter,
        limit: limit,
        offset: limit * (page - 1),
        branchFilter: branchFilter,
        category: selectedCategory,
        subCategory: selectedSubCategory,
        group: selectedGroup,
        userId: user.id,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );
  const newData = data ? data?.data : [];

  async function loadBranch() {
    try {
      let branchList = await axios.get(`${URL_API}/pos/master/branch/user`, {
        params: {
          id: user?.id,
        },
      });

      setCheckBranch(branchList.data?.data);
    } catch (error) {
      console.error(error);
    }
  }

  function handleDisplay() {
    if (state.filter.branch.includes("")) {
      setSelectedBranch(["ทั้งหมด"]);
      let display = _.map(checkBranch, (n) =>
        n.name.toLowerCase().replaceAll(" ", "_")
      );
      setDisplayBranch(display.concat(["total"]));
    } else {
      let branch = _.map(state.filter.branch, (b) =>
        checkBranch
          .find((n) => n.code === b)
          .name.toLowerCase()
          .replaceAll(" ", "_")
      );
      setSelectedBranch(branch);
      setDisplayBranch(branch.concat(["total"]));
    }
  }

  useEffect(() => {
    loadBranch();
  }, []);

  //---------------------------------------------------------

  const exportToExcel = () => {
    let title_table = [
      "ลำดับ",
      "Category",
      "Sub Category",
      "Group",
      "รหัสสินค้า",
      "ชื่อสินค้า",
    ];
    let subtitle_table = ["", "", "", "", "", ""];
    let subtitle_table_in_excel = ["", "", "", "", "", ""];

    //--------------------สร้าง column group ใน excel
    for (let i in displayBranch) {
      title_table.push(displayBranch[i]);
      title_table.push("");
      title_table.push("");
    }
    //--------------------สร้าง column sub group หา key
    for (let i in displayBranch) {
      subtitle_table.push(`${displayBranch[i]}_qty`);
      subtitle_table.push(`${displayBranch[i]}_amount`);
      subtitle_table.push(`${displayBranch[i]}_pv`);
    }
    //--------------------สร้าง column sub group ใน excel
    for (let i in displayBranch) {
      subtitle_table_in_excel.push(`จำนวนขาย`);
      subtitle_table_in_excel.push(`ยอดขาย`);
      subtitle_table_in_excel.push(`PV ขาย`);
    }

    if (newData.length > 0) {
      const color_cell = {
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      let excelData = [
        ["รายงานยอดขายตามรหัสสินค้า"],
        [
          `สาขา : ${
            state.filter.branch === ""
              ? "ทั่งหมด"
              : selectedBranch.map((name) => name)
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY เวลา HH:mm:ss")}`,
        ],
        [
          `แสดงเฉพาะสินค้า : ${state.filter.query}`,
          "",
          "",
          "",
          `ประเภทผู้ซื้อ : ${
            state.filter.buyer_type.includes("")
              ? "ทั้งหมด"
              : state.filter.buyer_type
          }`,
        ],
        [
          `Category : ${selectedCategory === "" ? "ทั้งหมด" : CategoryText}`,
          "",
          `Sub Category : ${
            selectedSubCategory === "" ? "ทั้งหมด" : SubCategoryText
          }`,
          "",
          `Group : ${selectedGroup === "" ? "ทั้งหมด" : GroupText}`,
        ],
        title_table.map((i) => {
          return {
            v: i
              .toLowerCase()
              .replace(/_/g, " ")
              .replace(/(?: |\b)(\w)/g, function (key, p1) {
                return key.toUpperCase();
              }),
            s: {
              alignment: { horizontal: "center" },
              border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
              },
              fill: { fgColor: { rgb: "E9E9E9" } },
            },
          };
        }),
        subtitle_table_in_excel.map((i) => {
          return {
            v: i,
            s: {
              alignment: { horizontal: "right" },
              border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
              },
              fill: { fgColor: { rgb: "E9E9E9" } },
            },
          };
        }),
      ];

      newData.forEach((n) => {
        //---------------------หา key ของ values
        let arr_title = subtitle_table.filter((item) => item !== "");
        //---------------------ดึง values ออกมา ( เรียงตาม key )
        // let values_branch = arr_title.map(
        //   (branch) => _.values(_.pickBy(n, (value, key) => branch === key))[0]
        // );
        // console.log("Values :", values_branch);

        //-------------------------------จัด format number -------------
        let values_branch = arr_title.map((branch) => {
          let obj_branch = _.pickBy(n, (value, key) => branch === key);
          let a = _.map(obj_branch, (item, key) => {
            //ถ้า column ที่มีคำว่า _qty ไม่ต้องจัด format
            return key.indexOf("_qty") !== -1
              ? {
                  v: item ? item : 0,
                  t: "n",
                  s: {
                    numFmt: "#,##0",
                    alignment: { horizontal: "right" },
                  },
                }
              : {
                  v: item ? item : 0,
                  t: "n",
                  s: {
                    numFmt: "#,##0.00",
                    alignment: { horizontal: "right" },
                  },
                };
          });

          return a[0]
            ? a[0]
            : {
                v: 0,
                t: "n",
                s: {
                  numFmt: "#,##0",
                  alignment: { horizontal: "right" },
                },
              };
        });
        //------------------------------- -------------

        //--------------------ชุดข้อมูลที่แสดงใน excel
        let data_table = [
          {
            v: n.row_id === data?.total ? "รวมทั้งหมด" : n.row_id,
            s: {
              alignment: { horizontal: "center" },
            },
          },
          n.category,
          n.sub_category,
          n.group,
          n.product_code === "รวมทั้งหมด" ? "" : n.product_code,
          n.product_name,
        ];
        //--------------------push data เข้าใน column ใน excel
        values_branch.map((i) => data_table.push(i));

        excelData = [...excelData, data_table];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },

        { s: { r: 2, c: 0 }, e: { r: 2, c: 3 } },
        { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 1 } },
        { s: { r: 3, c: 2 }, e: { r: 3, c: 3 } },
        { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } },

        { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
        { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
        { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
        { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
        { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },
        { s: { r: 4, c: 5 }, e: { r: 5, c: 5 } },
      ];

      //-------------------------merge column branch ------
      let c1 = 6;
      let c2 = 8;
      let loop_count = (subtitle_table.length - 4) / 3;
      _.map(subtitle_table, (_, i) => {
        if (i > 3) {
          if (loop_count > 0) {
            merge.push({ s: { r: 4, c: c1 }, e: { r: 4, c: c2 } });
            c1 = c1 + 3;
            c2 = c2 + 3;
            loop_count = loop_count - 1;
          }
        }
      });

      //------------------------width size of column-----
      var wscols = _.map(subtitle_table, (_, i) => {
        return i === 3 ? { wch: 35 } : { wch: 18 };
      });

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A5"].s = centerStyles;
      ws["B5"].s = centerStyles;
      ws["C5"].s = centerStyles;
      ws["D5"].s = centerStyles;
      ws["E5"].s = centerStyles;
      ws["F5"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานยอดขายตามรหัสสินค้า");
      XLSX.writeFile(wb, "รายงานยอดขายตามรหัสสินค้า.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานยอดขายตามรหัสสินค้า</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center align-items-sm-center p-b-10"
          >
            <Col span={6} className="pr-4">
              <Search
                className="w-100 "
                placeholder="รหัสสินค้า, ชื่อสินค้า"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                onSearch={reload}
                onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={1}>
              <Text className="text-right d-block" strong>
                วันที่
              </Text>
            </Col>

            <Col span={4}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>

            <Col span={2}>
              <Text className="text-right d-block" strong>
                ประเภทผู้ซื้อ
              </Text>
            </Col>
            <Col span={6}>
              <SelectAllType
                withAll
                process_type="buyer_type"
                labelIndex="code"
                defaultValue={[""]}
                placeholder="เลือกประเภทผู้ซื้อ"
                className="w-100 p-r-5"
                mode="multiple"
                addCustomer
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { buyer_type: e },
                  });
                }}
              />
            </Col>

            <Col span={5}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                      handleDisplay();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center align-items-sm-center p-b-10"
          >
            <Col>
              <Text strong>สาขา</Text>
            </Col>
            <Col span={6}>
              <SelectBranch
                userLogin
                allowDisableBranch
                mode="multiple"
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100"
                withAll={user.branch_count > 1}
                onChange={(value, option) => {
                  dispatch({
                    type: "setFilter",
                    payload: {
                      branch: value, // ค่าที่เลือก
                    },
                  });
                }}
              />
            </Col>
            <Col>
              <Text className="text-right d-block pl-3" strong>
                Category
              </Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category="category"
                withAll
                defaultValue={state.filter.category}
                onChange={(value, e) => {
                  setSelectedCategory(value);
                  setSelectedSubCategory("");
                  setSelectedGroup("");
                  setCategoryText(e.props.children);
                }}
              />
            </Col>
            <Col>
              <Text className="text-right d-block pl-3" strong>
                Sub Category
              </Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category={`subcategory?CategoryId=${selectedCategory}`}
                withAll
                defaultValue={state.filter.subCategory}
                value={selectedSubCategory}
                onChange={(value, e) => {
                  setSelectedSubCategory(value);
                  setSelectedGroup("");
                  setSubCategoryText(e.props.children);
                }}
              />
            </Col>
            <Col>
              <Text className="text-right d-block pl-3" strong>
                Group
              </Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category={`group?SubCategoryId=${selectedSubCategory}`}
                withAll
                defaultValue={state.filter.group}
                value={selectedGroup}
                onChange={(value, e) => {
                  setSelectedGroup(value);
                  setGroupText(e.props.children);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="medium"
            loading={loading}
            dataSource={data?.data || []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="No"
              rowKey="row_id"
              dataIndex="row_id"
              //fixed="left"
              render={(text) => (text === data?.total ? "รวมทั้งหมด" : text)}
            />
            <Table.Column
              title="Category"
              rowKey="category"
              dataIndex="category"
              //fixed="left"
            />
            <Table.Column
              title="Sub Category"
              rowKey="sub_category"
              dataIndex="sub_category"
              //fixed="left"
            />
            <Table.Column
              title="Group"
              rowKey="group"
              dataIndex="group"
              //fixed="left"
            />
            <Table.Column
              title="รหัสสินค้า"
              rowKey="product_code"
              dataIndex="product_code"
              //fixed="left"
              //render={(text) => (text === "รวมทั้งหมด" ? "" : text)}
            />
            <Table.Column
              title="ชื่อสินค้า"
              rowKey="product_name"
              dataIndex="product_name"
              //fixed="left"
            />

            {_.map(displayBranch, (branch, i) => {
              return (
                <Table.ColumnGroup
                  key={i}
                  title={branch
                    .toLowerCase()
                    .replace(/_/g, " ")
                    .replace(/(?: |\b)(\w)/g, function (key, p1) {
                      return key.toUpperCase();
                    })}
                >
                  <Table.Column
                    key={`${branch}_qty`}
                    title="จำนวนขาย"
                    dataIndex={`${branch
                      .toLowerCase()
                      .replaceAll(" ", "_")}_qty`}
                    align="right"
                    render={(text) => numeral(text).format("0,0")}
                  />
                  <Table.Column
                    key={`${branch}_amount`}
                    title="ยอดขาย"
                    dataIndex={`${branch
                      .toLowerCase()
                      .replaceAll(" ", "_")
                      .toLowerCase()
                      .replaceAll(" ", "_")}_amount`}
                    align="right"
                    render={(text) => numeral(text).format("0,0.00")}
                  />
                  <Table.Column
                    key={`${branch}_pv`}
                    title="PV ขาย"
                    dataIndex={`${branch
                      .toLowerCase()
                      .replaceAll(" ", "_")}_pv`}
                    align="right"
                    render={(text) => numeral(text).format("0,0.00")}
                  />
                </Table.ColumnGroup>
              );
            })}
          </Table>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={_.map(data?.data_payment || [], (n) => ({
              ...n,
              key: _.uniqueId("payment-"),
            }))}
            scroll={{ x: "max-content" }}
            pagination={false}
            className="mt-5"
          >
            <Table.ColumnGroup title="รวมจำนวนเงินแบ่งตามประเภท" align="left">
              <Table.Column
                title="ประเภทการชำระเงิน"
                dataIndex="pay_in_type"
                render={(text) => (text === "RP" ? "มูลค่า Redeem" : text)}
              />
              <Table.Column title="ธนาคาร" dataIndex="bank_name" />
              <Table.Column
                title="จำนวน"
                dataIndex="amount"
                render={(text) => numeral(text).format("0,0.00")}
              />
            </Table.ColumnGroup>
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
