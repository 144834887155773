import { Alert, Select } from "antd";
import React from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API, _ } from "../../../../utils";

const SelectOracleCategory = ({ withAll, categoryId, ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/setting/master/oracle-sub-category`,
      token: true,
      params: { category_id: categoryId },
    },
    [categoryId]
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select loading={loading} dropdownStyle={{ zIndex: "1080" }} {...props}>
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {_.map(data?.data, (n) => (
        <Select.Option key={n.id} value={n.id} source={n}>
          {n.name_local}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectOracleCategory;
