import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Error404 from '../Error404';
import AuthScreenPage from './pages/AuthScreenPage';
import BannerPage from './pages/BannerPage';
import BodyFocusSubTagPage from './pages/BodyFocusSubTagPage';
import BodyFocusTagPage from './pages/BodyFocusTagPage';
import BodyFocusVideoPage from './pages/BodyFocusVideoPage';
import BodyFoucModelPage from './pages/BodyFoucModelPage';
import ClassCategoryPage from './pages/ClassCategoryPage';
import ClassPage from './pages/ClassPage';
import ClassReservationPage from './pages/ClassReservationPage';
import ClassSchedulePage from './pages/ClassSchedulePage';
import FitnessBranchPage from './pages/FitnessBranchPage';
import NewsPage from './pages/NewsPage';
import PTRegisterPaperPage from './pages/PTRegisterPaperPage';
import PublicPlaylistPage from './pages/PublicPlaylistPage';
import PublicPlaylistVideoPage from './pages/PublicPlaylistVideoPage';
import RegisterPaperPage from './pages/RegisterPaperPage';
import RewardPage from './pages/RewardPage';
import TrainerRegisterPaperPage from './pages/TrainerRegisterPaperPage';
import UserPage from './pages/UserPage';
import VistiFitStudioPage from './pages/VistiFitStudioPage';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import RewardReportPage from './pages/RewardReportPage';

const queryClient = new QueryClient()

export default ({ match }) => {
  return (
    <QueryClientProvider client={queryClient}>
    <Switch>
      <Redirect path={match.path} exact to={`${match.path}/banner`} />
      <Route path={`${match.path}/banner`} exact children={<BannerPage />} />
      <Route
        path={`${match.path}/wallpaper/authscreen`}
        exact
        children={<AuthScreenPage />}
      />
      <Route
        path={`${match.path}/wallpaper/model-body-focus`}
        exact
        children={<BodyFoucModelPage />}
      />
      <Route path={`${match.path}/news`} exact children={<NewsPage />} />
      <Route
        path={`${match.path}/classcategory`}
        exact
        children={<ClassCategoryPage />}
      />
      <Route
        path={`${match.path}/classcategory/:classCategoryId`}
        exact
        children={<ClassPage />}
      />
      <Route
        path={`${match.path}/classcategory/:classCategoryId/class/:classId`}
        exact
        children={<ClassSchedulePage />}
      />
      <Route
        path={`${match.path}/classcategory/:classCategoryId/class/:classId/classschedule/:classScheduleId`}
        exact
        children={<ClassReservationPage />}
      />
      <Route path={`${match.path}/reward/:rewardId`} exact children={<RewardReportPage />} />
      <Route path={`${match.path}/reward`} exact children={<RewardPage />} />
      <Route
        path={`${match.path}/branch`}
        exact
        children={<FitnessBranchPage />}
      />
      <Route path={`${match.path}/user`} exact children={<UserPage />} />
      <Route
        path={`${match.path}/register-paper`}
        exact
        children={<RegisterPaperPage />}
      />
      <Route
        path={`${match.path}/trainer-register-paper`}
        exact
        children={<TrainerRegisterPaperPage />}
      />
      <Route
        path={`${match.path}/pt-register-paper`}
        exact
        children={<PTRegisterPaperPage />}
      />
      <Route
        path={`${match.path}/body-focus/:tagId`}
        exact
        children={<BodyFocusSubTagPage />}
      />
      <Route
        path={`${match.path}/body-focus/:tagId/sub-tag/:subTagId`}
        exact
        children={<BodyFocusVideoPage />}
      />
      <Route
        path={`${match.path}/body-focus`}
        exact
        children={<BodyFocusTagPage />}
      />
      <Route
        path={`${match.path}/public-playlist`}
        exact
        children={<PublicPlaylistPage />}
      />
      <Route
        path={`${match.path}/public-playlist/:playlistId`}
        exact
        children={<PublicPlaylistVideoPage />}
      />
      <Route
        path={`${match.path}/visit-fit-studio`}
        exact
        children={<VistiFitStudioPage />}
      />
      <Route component={Error404} />
    </Switch>
    </QueryClientProvider>
  );
};
