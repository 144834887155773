import { Col, Typography } from "antd";
import React from "react";
import { withRouter } from "react-router";

const Amount = ({ data, conditionType, match }) => {
  const { type } = match.params;
  const type_text =
    type === "sale"
      ? "ซื้อ"
      : type === "redeem"
      ? "แลก"
      : type === "subscription"
      ? "จองซื้อ"
      : "จองแลก";
  const unit = data.source_key === "price" ? "บาท" : "คะแนน";
  const every_text = conditionType === "every-amount" ? "ทุกๆ" : "";
  return (
    <Col span={24} className="d-flex align-items-center">
      <div className="width-60 height-60 d-flex justify-content-center align-items-center border rounded-lg">
        <Typography.Text strong>{data.source_key}</Typography.Text>
      </div>
      <div className="ml-3">
        <Typography.Text>{`${type_text}สินค้าครบ `}</Typography.Text>
        <Typography.Text strong>
          {every_text} {data.order_qty}
        </Typography.Text>
        <Typography.Text>{`/${data.qty} ${unit}`}</Typography.Text>
      </div>
    </Col>
  );
};

export default withRouter(Amount);
