import { Alert, Button, Table } from "antd";
import React, { useContext } from "react";
import UserContext from "../../../../../../contexts/user.context";
import { useApi } from "../../../../../../hooks/http2";

const BranchList = () => {
  const { state, dispatch } = useContext(UserContext);
  const { loading, fetch, error } = useApi(
    {
      url: "/setting/master/branch",
    },
    []
  );

  if (error) {
    return (
      <Alert message="ผิดพลาด" type="error" showIcon description={error} />
    );
  }

  const handleChangeSelect = (selectedRowKeys, selectedRows) => {
    dispatch({
      type: "SET_FORM_VALUE",
      payload: {
        ...state.form,
        branch: selectedRows.map((n) => ({
          branch_code: n.code,
          branch_name: n.name,
          is_default:
            state.form.branch.find((m) => m.branch_code === n.code)
              ?.is_default || 0,
        })),
      },
    });
  };

  return (
    <Table
      loading={loading}
      dataSource={
        fetch?.data ? fetch?.data?.map((n, i) => ({ ...n, key: i })) : []
      }
      size="small"
      bordered
      pagination={false}
      rowKey="code"
      rowSelection={{
        selectedRowKeys: state.form.branch.map((n) => n.branch_code),
        onChange: handleChangeSelect,
      }}
    >
      <Table.Column title="ชื่อสาขา" dataIndex="name" />
      <Table.Column
        dataIndex="code"
        align="center"
        render={(text) =>
          state.form.branch.map((n) => n.branch_code).includes(text) ? (
            state.form.branch.find((n) => n.branch_code === text).is_default ===
            1 ? (
              "สาขาตั้งต้น"
            ) : (
              <Button
                size="small"
                type="link"
                onClick={() =>
                  dispatch({
                    type: "SET_DEFAULT_BRANCH",
                    payload: text,
                  })
                }
              >
                ตั้งเป็นสาขาตั้งต้น
              </Button>
            )
          ) : (
            ""
          )
        }
      />
    </Table>
  );
};

export default BranchList;
