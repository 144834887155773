import { Select } from "antd";
import React, { forwardRef, useImperativeHandle } from "react";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";

const SelectBatchId = ({ period,withAll = false, ...props }, ref) => {
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/commission/item/batch-id`,
      params: {
        period,
      },
      token: true,
    },
    [period]
  );

  useImperativeHandle(ref, () => ({
    reload: () => {
      return reload();
    },
  }));

  if (error) return <div>{error}</div>;

  return (
    <Select loading={loading} dropdownMatchSelectWidth={false} {...props}>
      {
                withAll ? <Select.Option value="">ทั้งหมด</Select.Option> : null
            }
      {data && data.data
        ? data.data.map((n, i) => (
            <Select.Option key={i} value={n.batch_id} source={n}>
              {n.batch_id}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

export default forwardRef(SelectBatchId);
