/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Skeleton,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import React, { useContext, useEffect, useRef, useState } from "react";
import QrModal from "../../../../../components/QrModal";
import { axios, getToken, getUserObj, URL_API, _ } from "../../../../../utils";
import { resCatch, resCatchModal } from "../../../../util/helper";
import ArrangeContext from "../../reducer";
import BillCanceledButton from "../BillCanceledButton";
import BillHeader from "../BillHeader";
import BillHeaderInfo from "../BillHeaderInfo";
import BillItem from "./BillItem";
import { FaQrcode } from "react-icons/fa";
import { fixProductTypeSet } from "../../../../../config/fix-value";

const Index = ({ reload, setPromotionValue = () => null }) => {
  const user = getUserObj();
  const {
    state: {
      bill,
      selected,
      qrProductKeySelect,
      items: { itemKeys, itemByKey },
    },
    dispatch,
  } = useContext(ArrangeContext);

  let partnerType = bill.partner_type;
  let salesType = bill.sales_type;
  let documentType = bill.document_type;

  const searchRef = useRef(null);
  const [isProduct, setIsProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const is_cancel = bill.arrange_cancel_status === 1;

  const onCancel = () =>
    dispatch({
      type: "clearCreate",
    });

  useEffect(() => {
    if (selected.key) {
      setLoading(true);
      axios({
        url: `${URL_API}/arrange/${selected.type}/${selected.key}`,
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          branch_code: user.branch_code,
          document_type: selected.type,
        },
      })
        .then((res) => {
          const { data } = res.data;
          if (!data) {
            onCancel();
            Modal.error({
              title: "ไม่สามารถดำเนินการได้",
              content: "ไม่พบข้อมูลบิล",
              zIndex: 1080,
            });
          }
          dispatch({ type: "loadBillData", payload: data });
        })
        .catch((e) => {
          onCancel();
          resCatchModal(e);
        })
        .finally(() => setLoading(false));
    }
  }, [selected.key]);
  const sum_qty = _.sum(_.map(itemKeys, (n) => itemByKey[n].qty));
  const product = qrProductKeySelect && {
    ...itemByKey[qrProductKeySelect],
    qty: itemByKey[qrProductKeySelect].qty_balance,
    key: qrProductKeySelect,
  };

  const qrCloseModal = () =>
    dispatch({
      type: "setState",
      name: "qrProductKeySelect",
      payload: null,
    });

  const qrValidateUsed = (qr_running) => {
    const qrUsedList = _.reduce(
      itemKeys,
      (res, item_key) => {
        const { qr = [], bom } = itemByKey[item_key];
        const qr_bom = _.reduce(
          bom,
          (res, n) => (n.qr ? [...res, ...n.qr] : res),
          []
        );
        return [...res, ...qr, ...qr_bom];
      },
      []
    );


    return _.some(
      qrUsedList,
      (n) => n.qr_running && n.qr_running === qr_running && n.qr_running !== ""
    );
  };

  const qrSetItemValue = (payload) => {
    dispatch({ type: "setItemValueQr", payload });
  };
  const qrSetItemValueBom = (payload) => {
    dispatch({ type: "setItemValueBomQr", payload });
  };
  const qrDeleteItemBom = (payload) => {
    dispatch({ type: "delItemBomQr", payload });
  };

  const renderWarning = (description) => {
    setLoading(false);
    notification.warning({
      message: "ไม่สามารถดำเนินการได้",
      description,
    });
  };

  const handleAddQr = async (qr_running, barcode, data, _product) => {
    const {
      promotion_id,
      source_option,
      product_code,
      qr: curr_qr = [],
    } = _product;
    const key = _product?.product_code;

    if (_product.product_type_set === fixProductTypeSet.kit_pack) {
      const { bom } = data;
      for (let i = 0; i < bom.length; i++) {
        const { product_code, barcode, qr_running } = bom[i];
        qrSetItemValueBom({
          product_code,
          barcode,
          qr: {
            ref_product_code: data?.main_product?.product_code,
            ref_store_qr_id: data?.main_product?.id,
            ref_store_qr_type: "sub",
            qr_running,
          },
          item_key: key,
        });
      }
      if (promotion_id) {
        setPromotionValue({
          promotion_id,
          source_option,
          product_code,
          data: {
            qr: [
              ...curr_qr,
              {
                ref_product_code: null,
                ref_store_qr_id: data.main_product.id,
                ref_store_qr_type: "main",
                qr_running: data.main_product.main_running,
              },
            ],
            barcode: data.main_product.barcode,
          },
        });
      } else {
        qrSetItemValue({
          key,
          value: {
            qr: [
              ...curr_qr,
              {
                ref_product_code: null,
                ref_store_qr_id: data.main_product.id,
                ref_store_qr_type: "main",
                qr_running: data.main_product.main_running,
              },
            ],
            barcode: data.main_product.barcode,
            qr_warning: false,
          },
        });
      }
    } else {
      const res_qr = [
        ...curr_qr,
        {
          ref_product_code: null,
          ref_store_qr_id: null,
          ref_store_qr_type: null,
          qr_running,
        },
      ];
      if (_product.promotion_id) {
        setPromotionValue({
          promotion_id,
          source_option,
          product_code,
          data: {
            qr: res_qr,
            barcode,
            qr_warning: false,
          },
        });
      } else {
        qrSetItemValue({
          key,
          value: {
            qr: res_qr,
            barcode,
            qr_warning: false,
          },
        });
      }
    }
    searchRef.current.input.state.value = "";
    searchRef.current.focus();
  };

  const handleSearch = async (code) => {
    if (!code) return;
    const isPhaseOne = true;
    const qr_running = code.split("LgcSN=")[1]?.split("&")[0];
    const barcode = code.split("LgcSKU=")[1]?.split("&")[0];
    // if (qr_running == null) return renderWarning("ไม่พบข้อมูล qr running");
    let res = null;
    try {
      res = await axios({
        url: `${URL_API}/pos/qr`,
        params: {
          qr_running,
          barcode: barcode || code,
          partner_type: partnerType,
          sales_type: salesType,
          document_type: documentType,
          qr: barcode ? code : null,
          branch: user.branch_code,
        },
      });
    } catch (e) {
      return resCatch(e);
    }
    const {
      //failed,
      warn,
      data,
    } = res.data;
    const _checkProduct = _.includes(itemKeys, data?.product_code);
    const _product = _.find(
      itemByKey,
      (o) => o.product_code === `${data?.product_code}`
    );
    searchRef.current.input.state.value = "";
    searchRef.current.focus();

    // only phase one
    if (isPhaseOne) {
      let phase_one_add_some_sub_item = null;
      if (!data.product_code) return renderWarning("ไม่พบข้อมูลสินค้า");
      // if (!data?.product_code) return renderWarning("ไม่พบข้อมูลสินค้า");
      // if (data?.product_code !== _product?.product_code) {
      //   if (_product?.product_type_set !== fixProductTypeSet?.kit_pack)
      //     return renderWarning("สินค้าที่เลือกไม่ถูกต้อง");
      //   phase_one_add_some_sub_item = true;
      // }
      // if (failed?.qr) return renderWarning("qr running ถูกใช้งานแล้ว");
      // if (failed?.product) return renderWarning("พบสินค้าหลายรายการ");
      // if (failed.store_qr) {
      //   return renderWarning("ไม่พบข้อมูล QR Code !");
      // }

      if (warn) return renderWarning(warn);

      if (phase_one_add_some_sub_item) {
        qrSetItemValueBom({
          product_code: data?.product_code,
          barcode,
          qr: {
            ref_product_code: data?.product_code,
            ref_store_qr_id: null,
            ref_store_qr_type: null,
            qr_running,
          },
          item_key: _product?.key,
        });
        return;
      }
    } else {
      if (!data?.product_code) return renderWarning("ไม่พบข้อมูลสินค้า");
      if (data?.product_code !== product?.product_code)
        return renderWarning("สินค้าที่เลือกไม่ถูกต้อง");
      // if (failed?.qr) return renderWarning("qr running ถูกใช้งานแล้ว");
      // if (failed?.product) return renderWarning("พบสินค้าหลายรายการ");
      // if (
      //   _product?.product_type_set === fixProductTypeSet?.kit_pack &&
      //   failed.store_qr
      // )
      //   return renderWarning("ไม่พบข้อมูล store qr");
      if (warn) return renderWarning(warn);
    }
    
    if (_checkProduct) {
      setIsProduct(_product);
      // check duplicate & used !
      if (_product?.qty === _product?.qr?.length) {
        return renderWarning("จำนวนสินค้าที่เลือกครบแล้ว");
      }
      const qr_used = qrValidateUsed(qr_running);
      if (qr_used) {
        return renderWarning("qr running ถูกใช้งานในรายการสั่งซื้อแล้ว");
      }

      await handleAddQr(qr_running, barcode, data, _product);
    }
  };

  return (
    <>
      <Modal
        visible={!!selected.key}
        onCancel={onCancel}
        destroyOnClose={true}
        maskClosable={false}
        footer={false}
        zIndex={1080}
        width={1000}
        bodyStyle={{ paddingTop: "50px" }}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Row style={{ margin: "16px 0px" }}>
              <Col span={14} push={10}>
                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col span={5}>
                    <FaQrcode className="mr-2" />
                    <Text>Qr Tracking</Text>
                  </Col>
                  <Col span={19}>
                    <Input.Search
                      allowClear
                      loading={loading}
                      // disabled={disabled}
                      ref={searchRef}
                      className="ml-2 mr-3"
                      placeholder="Start typing or scan Qr code..."
                      onSearch={handleSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <BillHeaderInfo />
            <BillHeader />
            <BillItem isCancel={is_cancel} />
            <Row>
              <Col>
                <Divider className="mt-2 mb-2" />
                <Typography.Text strong className="text-dark mr-3 ">
                  หมายเหตุการจัดสินค้า
                </Typography.Text>
                <Input.TextArea
                  rows={3}
                  className="mt-1"
                  onChange={(e) =>
                    dispatch({
                      type: "setRemarkDocument",
                      payload: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-center">
                {is_cancel ? (
                  <BillCanceledButton
                    reload={reload}
                    onCancel={onCancel}
                    type="bill"
                  />
                ) : (
                  <>
                    <Button
                      type="primary"
                      disabled={bill.total_qty_delivery < 1}
                      // disabled={bill.total_qty_delivery < sum_qty}
                      onClick={() =>
                        dispatch({
                          type: "setState",
                          name: "previewSummary",
                          payload: true,
                        })
                      }
                    >
                      สรุป
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
      </Modal>

      <QrModal
        disabled={is_cancel}
        product={product}
        documentType={bill.document_type}
        partnerType={bill.partner_type}
        salesType={bill.sales_type}
        receipt_branch_code={bill.branch_code}
        onCancel={qrCloseModal}
        validateUsed={qrValidateUsed}
        setItemValue={qrSetItemValue}
        setItemValueBom={qrSetItemValueBom}
        deleteItemBom={qrDeleteItemBom}
      />
    </>
  );
};

export default Index;
