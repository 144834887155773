/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { fixProductTypeSet } from "../../config/fix-value";
import { _ } from "../../utils";
import { getThunkError } from "../../views/util/helper";
import { mapDataToItemKey } from "../../views/util/helper/map-data";
import {
  getClaimSelectBillProduct,
  initClaimError,
  initClaimItemValue,
  mapBomClaimItemAll,
} from "../logic/claim";
import { initFetch } from "../logic/initial-value";
import {
  getBillSelect,
  getClaimSelect,
  getConsiderPermission,
  getStockBalance,
} from "../thunk/claim";

const initClaimItem = {
  activeKey: null,
  itemKeys: [],
  itemByKey: {},
};

const initClaimRemark = {
  create_by: null,
  create_date: null,
  remark: null,
};

const initialState = {
  isPreview: false,
  loadingSubmit: false,
  searchDocument: {
    query: null,
    start_date: null,
    end_date: null,
    consider_status: null,
    status: "",
    user_branch_code: "",
    branch_code: "",
    claim_type: "",
    limit: 10,
    page: 1,
  },
  documentDetail: {
    document_no: null,
    document_date: null,
    branch_code: null,
    branch_name: null,
    user_branch_code: null,
    user_branch_name: null,
    branch_priority: null,
    manager_priority: null,
    remark: null,
    claim_type: null,
    status: null,
    consider_status: null,
    // claim_type: "quality",
  },
  receiptType: {
    req_receipt_type: null,
    receipt_branch_code: null,
    receipt_branch_name: null,
    ship_to: null,
    ship_mobile: null,
    ship_name: null,
    ship_to_id: null,
    ship_address_no: null,
    ship_sub_district: null,
    ship_district: null,
    ship_province: null,
    ship_post_code: null,
    ship_country: null,
  },
  claimBy: {
    id: null,
    type: "Other",
    code: null,
    name: null,
    mobile: null,
    id_card: null,
  },
  partner: {
    id: null,
    type: null,
    code: null,
    // code: 110000001,
    name: null,
    mobile: null,
    id_card: null,
  },
  authority: {
    id: null,
    id_del: null,
    file_type: null,
    file_name: null,
    file_size: null,
    azure_url: null,
    temp: null,
  },
  bill: {
    buyer_id: null,
    buyer_code: null,
    buyer_mobile: null,
    buyer_name: null,
    buyer_type: null,
    buyer_id_card: null,
    document_date: null,
    document_no: null,
    order_type: null,
    receipt_branch_name: null,
    req_receive_text: null,
    req_receive_type_text: null,
    ship_address: null,
    ship_mobile: null,
    ship_to: null,
  },
  billProduct: {
    itemKeys: [],
    itemByKey: {},
  },
  claimOutProduct: {
    itemKeys: [],
    itemByKey: {},
  },
  claimOutVoucher: {
    itemKeys: [],
    itemByKey: {},
  },
  billModal: {
    visible: false,
    query: null,
    page: 1,
    limit: 10,
  },
  productModal: {
    visible: false,
    product_key: null,
    // product_code: null,
    // product_name: null,
    // qty_balance: null,
  },
  productBomModal: {
    visible: false,
    product_bom_key: null,
  },
  claimModal: {
    document_no: null,
    is_preview: true,
    data: null,
    active_menu: null,
  },
  cancelModal: {
    document_no: null,
    remark: null,
  },
  historyModal: {
    document_no: null,
    limit: 10,
  },
  branchConsiderModal: {
    document_no: null,
    document_status: null,
    manager_priority: null,
    remark: null,
    option: null,
  },
  addProductModal: {
    visible: null,
    limit: 10,
    page: 1,
    query: null,
  },
  addVoucherModal: {
    visible: false,
    value: 0,
    source: null,
  },
  remark: {
    cancel: { ...initClaimRemark },
    branch: { ...initClaimRemark },
    manager: { ...initClaimRemark },
    complete: { ...initClaimRemark },
  },
  consider: {
    branch: null,
    manager: null,
  },
  error: { ...initClaimError },
  fetch: {
    bill_select: { ...initFetch },
    claim_select: { ...initFetch },
    consider_permission: { ...initFetch },
    stock_balance: { ...initFetch },
  },
  maxTabs: 1
};

const claimSlice = createSlice({
  name: "claim",
  initialState: _.cloneDeep(initialState),
  reducers: {
    setState: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    setGlobalState: (state, { payload: { name, value } }) => {
      state[name] = {
        ...state[name],
        ...value,
      };
    },
    clearGlobalState: (state, { payload }) => {
      state[payload] = _.cloneDeep(initialState[payload]);
    },
    clear: (state) => {
      state.isPreview = initialState.isPreview;
      state.loadingSubmit = initialState.loadingSubmit;
      state.searchDocument = { ...initialState.searchDocument };
      state.documentDetail = { ...initialState.documentDetail };
      state.receiptType = { ...initialState.receiptType };
      state.claimBy = { ...initialState.claimBy };
      state.partner = { ...initialState.partner };
      state.authority = { ...initialState.authority };
      state.bill = { ...initialState.bill };
      state.billProduct = _.cloneDeep(initialState.billProduct);
      state.claimOutProduct = _.cloneDeep(initialState.claimOutProduct);
      state.claimOutVoucher = _.cloneDeep(initialState.claimOutVoucher);
      state.productModal = { ...initialState.productModal };
      state.productBomModal = { ...initialState.productBomModal };
      state.cancelModal = { ...initialState.cancelModal };
      state.branchConsiderModal = { ...initialState.branchConsiderModal };
      state.addProductModal = { ...initialState.addProductModal };
      state.addVoucherModal = { ...initialState.addVoucherModal };
      state.remark = _.cloneDeep(initialState.remark);
      state.consider = { ...initialState.consider };
      state.error = { ...initialState.error };
      state.fetch = _.cloneDeep(initialState.fetch);
    },
    clearCreate: (state) => {
      state.isPreview = initialState.isPreview;
      state.loadingSubmit = initialState.loadingSubmit;
      state.documentDetail = { ...initialState.documentDetail };
      state.receiptType = { ...initialState.receiptType };
      state.claimBy = { ...initialState.claimBy };
      state.partner = { ...initialState.partner };
      state.authority = { ...initialState.authority };
      state.bill = { ...initialState.bill };
      state.billProduct = _.cloneDeep(initialState.billProduct);
      state.claimOutProduct = _.cloneDeep(initialState.claimOutProduct);
      state.claimOutVoucher = _.cloneDeep(initialState.claimOutVoucher);
      state.productModal = { ...initialState.productModal };
      state.productBomModal = { ...initialState.productBomModal };
      state.cancelModal = { ...initialState.cancelModal };
      state.branchConsiderModal = { ...initialState.branchConsiderModal };
      state.addProductModal = { ...initialState.addProductModal };
      state.addVoucherModal = { ...initialState.addVoucherModal };
      state.remark = _.cloneDeep(initialState.remark);
      state.consider = { ...initialState.consider };
      state.error = { ...initialState.error };
      state.fetch = _.cloneDeep(initialState.fetch);
    },
    clearBill: (state) => {
      state.bill = { ...initialState.bill };
      state.billProduct = _.cloneDeep(initialState.billProduct);
    },
    setFormValue: (state, { payload: { name, value, error_name } }) => {
      state[name] = {
        ...state[name],
        ...value,
      };
      if (_.isArray(error_name)) {
        for (let i = 0; i < error_name.length; i++) {
          const property = error_name[i];
          if (state.error[property] === null) continue;
          state.error[property] = null;
        }
      } else if (state.error[error_name]) {
        state.error[error_name] = null;
      }
    },
    changePieceSelect: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const {
        bom,
        sub_item_used,
        claim_item: { activeKey },
      } = state.billProduct.itemByKey[product_key];

      const sub_item_used_select = _.filter(
        sub_item_used,
        (n) => n.ref_piece === payload
      );

      // convert to key item
      let itemKeys = [];
      let itemByKey = {};
      _.forEach(bom, (bom_item) => {
        const { product_code, ...n } = bom_item;
        const key = _.uniqueId("bom-");
        const qty_balance =
          n.qty -
          (_.find(sub_item_used_select, (m) => product_code === m.product_code)
            ?.qty || 0);
        itemKeys = [...itemKeys, key];
        itemByKey = {
          ...itemByKey,
          [key]: {
            ...n,
            product_code,
            qty_balance,
            ref_piece: payload,
            claim_item: mapBomClaimItemAll({
              qty_balance,
              product_code,
            }),
          },
        };
      });

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].piece = payload;
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom = {
        itemKeys,
        itemByKey,
      };
    },
    changeIsAllBom: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;
      const { bom } = itemByKey[activeKey];
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey = _.reduce(
        bom.itemKeys,
        (res, key) => {
          const { qty_balance, product_code, ...n } = bom.itemByKey[key];

          return {
            ...res,
            [key]: {
              ...n,
              qty_balance,
              product_code,
              claim_item: payload
                ? mapBomClaimItemAll({
                  qty_balance,
                  product_code,
                })
                : _.cloneDeep(initClaimItem),
            },
          };
        },
        {}
      );
    },
    setClaimItemActive: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      state.billProduct.itemByKey[product_key].claim_item.activeKey = payload;
    },
    addClaimItem: (state) => {
      const product_key = state.productModal.product_key;
      const { itemKeys, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;

      const key = _.uniqueId("item-");
      state.billProduct.itemByKey[product_key].claim_item.activeKey = key;
      state.billProduct.itemByKey[product_key].claim_item.itemKeys = [
        ...itemKeys,
        key,
      ];
      state.billProduct.itemByKey[product_key].claim_item.itemByKey = {
        ...itemByKey,
        [key]: {
          ..._.cloneDeep(initClaimItemValue),
          product_code: state.billProduct.itemByKey[product_key].product_code,
          piece:
            state.billProduct.itemByKey[product_key].product_type_set ===
              fixProductTypeSet.kit_pack
              ? null
              : state.billProduct.itemByKey[product_key].claim_item.itemKeys
                .length,
        },
      };
    },
    setClaimItemValue: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const key = state.billProduct.itemByKey[product_key].claim_item.activeKey;
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[key] = {
        ...state.billProduct.itemByKey[product_key].claim_item.itemByKey[key],
        ...payload,
      };
    },
    delClaimItem: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      state.billProduct.itemByKey[product_key].claim_item.itemKeys = _.filter(
        state.billProduct.itemByKey[product_key].claim_item.itemKeys,
        (n) => n !== payload
      );
      state.billProduct.itemByKey[product_key].claim_item.itemByKey = _.omit(
        state.billProduct.itemByKey[product_key].claim_item.itemByKey,
        [payload]
      );
      if (
        state.billProduct.itemByKey[product_key].claim_item.activeKey ===
        payload
      ) {
        state.billProduct.itemByKey[product_key].claim_item.activeKey =
          state.billProduct.itemByKey[product_key].claim_item.itemKeys[0] ||
          null;
      }
    },
    addClaimItemMedia: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;
      const key = _.uniqueId("media-");
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].media.itemKeys = [...itemByKey[activeKey].media.itemKeys, key];
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].media.itemByKey = {
        ...itemByKey[activeKey].media.itemByKey,
        [key]: {
          ...payload,
        },
      };
    },
    delClaimItemMedia: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;
      const { id, temp } = itemByKey[activeKey].media.itemByKey[payload];
      if (!temp) {
        state.billProduct.itemByKey[product_key].claim_item.itemByKey[
          activeKey
        ].media.itemDel = [...itemByKey[activeKey].media.itemDel, id];
      }
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].media.itemKeys = _.filter(
        itemByKey[activeKey].media.itemKeys,
        (n) => n !== payload
      );
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].media.itemByKey = _.omit(itemByKey[activeKey].media.itemByKey, [
        payload,
      ]);
    },
    setMaxTabs: (state, { payload }) => {
      state.maxTabs = payload
    },
    copyClaimItem: (state) => {
      const product_key = state.productModal.product_key;
      const {
        claim_item: { activeKey, itemKeys, itemByKey },
      } = state.billProduct.itemByKey[product_key];
      const { bom, ...item } = itemByKey[activeKey];

      let maxPiece = state.maxTabs
      if (itemKeys.length >= maxPiece)
        return notification.warning({
          message: "ไม่สามารถเพิ่มจำนวนสินค้าได้",
          description: `จำนวนที่เคลมได้สูงสูด ${maxPiece} ชิ้น`,
        });

      const key = _.uniqueId("item-");

      state.billProduct.itemByKey[product_key].claim_item.activeKey = key;
      state.billProduct.itemByKey[product_key].claim_item.itemKeys = [
        ...itemKeys,
        key,
      ];
      state.billProduct.itemByKey[product_key].claim_item.itemByKey = {
        ...itemByKey,
        [key]: {
          ...item,
          qr_running: null,
          bom: {
            itemKeys: [],
            itemByKey: {},
          },
          media: {
            itemDel: [],
            itemKeys: [],
            itemByKey: {},
          },
          product_code: state.billProduct.itemByKey[product_key].product_code,
          piece:
            state.billProduct.itemByKey[product_key].product_type_set ===
              fixProductTypeSet.kit_pack
              ? null
              : state.billProduct.itemByKey[product_key].claim_item.itemKeys
                .length,
        },
      };
    },
    setClaimItemActiveBom: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const product_bom_key = state.productBomModal.product_bom_key;
      const { activeKey } = state.billProduct.itemByKey[product_key].claim_item;
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.activeKey = payload;
    },
    addClaimItemBom: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const product_bom_key = state.productBomModal.product_bom_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;

      const { itemKeys: itemKeysBom, itemByKey: itemByKeyBom } =
        itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;

      const key = _.uniqueId("sub_item-");
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.activeKey = key;
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemKeys = [
          ...itemKeysBom,
          key,
        ];
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey = {
        ...itemByKeyBom,
        [key]: {
          ..._.cloneDeep(initClaimItemValue),
          // ref_product_code:
          //   state.billProduct.itemByKey[product_key].claim_item.itemByKey[
          //     activeKey
          //   ].product_code,
          // ref_piece:
          //   state.billProduct.itemByKey[product_key].claim_item.itemByKey[
          //     activeKey
          //   ].piece,
          product_code:
            state.billProduct.itemByKey[product_key].claim_item.itemByKey[
              activeKey
            ].bom.itemByKey[product_bom_key].product_code,
          piece:
            state.billProduct.itemByKey[product_key].claim_item.itemByKey[
              activeKey
            ].bom.itemByKey[product_bom_key].claim_item.itemKeys.length,
        },
      };
    },
    setClaimItemValueBom: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const product_bom_key = state.productBomModal.product_bom_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;
      const key =
        itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item
          .activeKey;
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey[key] = {
        ...itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item
          .itemByKey[key],
        ...payload,
      };
    },
    delClaimItemBom: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const product_bom_key = state.productBomModal.product_bom_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;

      const {
        activeKey: activeKeyBom,
        itemKeys: itemKeysBom,
        itemByKey: itemByKeyBom,
      } = itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemKeys = _.filter(
        itemKeysBom,
        (n) => n !== payload
      );

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey = _.omit(
        itemByKeyBom,
        [payload]
      );

      if (activeKeyBom === payload) {
        state.billProduct.itemByKey[product_key].claim_item.itemByKey[
          activeKey
        ].bom.itemByKey[product_bom_key].claim_item.activeKey =
          state.billProduct.itemByKey[product_key].claim_item.itemByKey[
            activeKey
          ].bom.itemByKey[product_bom_key].claim_item.itemKeys[0] || null;
      }
    },
    addClaimItemMediaBom: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const product_bom_key = state.productBomModal.product_bom_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;

      const { activeKey: activeKeyBom, itemByKey: itemByKeyBom } =
        itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;

      const key = _.uniqueId("media-bom-");

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey[
        activeKeyBom
      ].media.itemKeys = [...itemByKeyBom[activeKeyBom].media.itemKeys, key];

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey[
        activeKeyBom
      ].media.itemByKey = {
        ...itemByKeyBom[activeKeyBom].media.itemByKey,
        [key]: {
          ...payload,
        },
      };
    },
    delClaimItemMediaBom: (state, { payload }) => {
      const product_key = state.productModal.product_key;
      const product_bom_key = state.productBomModal.product_bom_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;
      const { activeKey: activeKeyBom, itemByKey: itemByKeyBom } =
        itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;

      const {
        itemDel,
        itemKeys: itemKeysMedia,
        itemByKey: itemByKeyMedia,
      } = itemByKeyBom[activeKeyBom].media;

      const { id, temp } = itemByKeyMedia[payload];
      if (!temp) {
        state.billProduct.itemByKey[product_key].claim_item.itemByKey[
          activeKey
        ].bom.itemByKey[product_bom_key].claim_item.itemByKey[
          activeKeyBom
        ].media.itemDel = [...itemDel, id];
      }

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey[
        activeKeyBom
      ].media.itemKeys = _.filter(itemKeysMedia, (n) => n !== payload);

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey[
        activeKeyBom
      ].media.itemByKey = _.omit(itemByKeyMedia, [payload]);
    },
    copyClaimItemBom: (state) => {
      const product_key = state.productModal.product_key;
      const product_bom_key = state.productBomModal.product_bom_key;
      const { activeKey, itemByKey } =
        state.billProduct.itemByKey[product_key].claim_item;
      const {
        claim_item: {
          activeKey: activeKeyBom,
          itemKeys: itemKeysBom,
          itemByKey: itemByKeyBom,
        },
        qty_balance,
      } = itemByKey[activeKey].bom.itemByKey[product_bom_key];

      if (itemKeysBom.length >= qty_balance)
        return notification.warning({
          message: "ไม่สามารถเพิ่มจำนวนสินค้าได้",
          description: `จำนวนที่เคลมได้สูงสูด ${qty_balance} ชิ้น`,
        });

      const key = _.uniqueId("sub_item-");

      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.activeKey = key;
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemKeys = [
          ...itemKeysBom,
          key,
        ];
      state.billProduct.itemByKey[product_key].claim_item.itemByKey[
        activeKey
      ].bom.itemByKey[product_bom_key].claim_item.itemByKey = {
        ...itemByKeyBom,
        [key]: {
          ..._.cloneDeep(itemByKeyBom[activeKeyBom]),
          qr_running: null,
          bom: {
            itemKeys: [],
            itemByKey: {},
          },
          media: {
            itemDel: [],
            itemKeys: [],
            itemByKey: {},
          },
          piece: itemByKeyBom[activeKeyBom].piece + 1,
        },
      };
    },
    addClaimOutProduct: (state, { payload }) => {
      const key = _.uniqueId("claim-out-product-");
      state.claimOutProduct.itemKeys = [...state.claimOutProduct.itemKeys, key];
      state.claimOutProduct.itemByKey = {
        ...state.claimOutProduct.itemByKey,
        [key]: {
          ..._.omit(payload, ["row_id"]),
          min_qty: 1,
          qty: 1,
          status: 1,
        },
      };
    },
    setClaimOutProduct: (state, { payload: { key, value } }) => {
      state.claimOutProduct.itemByKey[key] = {
        ...state.claimOutProduct.itemByKey[key],
        ...value,
      };
    },
    delClaimOutProduct: (state, { payload }) => {
      state.claimOutProduct.itemKeys = _.filter(
        state.claimOutProduct.itemKeys,
        (key) => key !== payload
      );
      state.claimOutProduct.itemByKey = _.omit(
        state.claimOutProduct.itemByKey,
        [payload]
      );
    },
    setClaimOutProductBom: (state, { payload: { key, index, value } }) => {
      state.claimOutProduct.itemByKey[key].bom[index] = {
        ...state.claimOutProduct.itemByKey[key].bom[index],
        ...value,
      };
    },
    clickBranchConsider: (state) => {
      state.branchConsiderModal = {
        ...state.branchConsiderModal,
        document_no: state.documentDetail.document_no,
        document_status: state.documentDetail.status,
      };
    },
    addClaimOutVoucher: (state) => {
      const key = _.uniqueId("claim-out-voucher-");
      state.claimOutVoucher.itemKeys = [...state.claimOutVoucher.itemKeys, key];
      state.claimOutVoucher.itemByKey = {
        ...state.claimOutVoucher.itemByKey,
        [key]: {
          ...state.addVoucherModal.source,
          value: state.addVoucherModal.value,
          status: 1,
        },
      };
      state.addVoucherModal = { ...initialState.addVoucherModal };
    },
    delClaimOutVoucher: (state, { payload }) => {
      state.claimOutVoucher.itemKeys = _.filter(
        state.claimOutVoucher.itemKeys,
        (n) => n !== payload
      );
      state.claimOutVoucher.itemByKey = _.omit(
        state.claimOutVoucher.itemByKey,
        [payload]
      );
    },
    delAuthority: (state) => {
      state.authority = {
        ...initialState.authority,
        id_del: state.authority.temp ? null : state.authority.id,
      };
    },
  },
  extraReducers: {
    [getBillSelect.pending]: (state) => {
      state.fetch.bill_select = {
        ...initFetch,
        loading: true,
      };
      state.billModal = _.cloneDeep(initialState.billModal);
    },
    [getBillSelect.fulfilled]: (state, { payload }) => {
      const { items, ...bill } = payload;

      let itemKeys = [];
      let itemByKey = {};
      _.forEach(items, (n) => {
        const key = _.uniqueId("product-");
        itemKeys = [...itemKeys, key];
        itemByKey = {
          ...itemByKey,
          [key]: {
            ...n,
            claim_item: _.cloneDeep(initClaimItem),
            // piece_used: _.map(
            //   _.keys(_.groupBy(n.sub_item_used, "ref_piece")),
            //   (n) => +n
            // ),
          },
        };
      });

      state.billProduct.itemKeys = itemKeys;
      state.billProduct.itemByKey = itemByKey;

      state.bill = {
        ..._.cloneDeep(initialState.bill),
        ...bill,
      };

      state.fetch.bill_select = { ...initFetch };
    },
    [getBillSelect.rejected]: (state, { payload }) => {
      state.fetch.bill_select = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
    [getClaimSelect.pending]: (state) => {
      state.fetch.claim_select = {
        ...initFetch,
        loading: true,
      };
    },
    [getClaimSelect.fulfilled]: (state, { payload }) => {
      if (payload === null) {
        state.fetch.claim_select = {
          loading: false,
          error: "notfound",
        };
        return;
      }
      const {
        document,
        authority,
        bill,
        claim_out_product,
        claim_out_voucher,
        claim_remark,
        ...bill_product
      } = payload;
      const {
        document_no,
        document_date,
        branch_code,
        branch_name,
        user_branch_code,
        user_branch_name,
        branch_priority,
        manager_priority,
        remark,
        claim_type,
        claim_type_text,
        status,
        consider_status,
        // - claim by
        claim_by_id,
        claim_by_type,
        claim_by_code,
        claim_by_name,
        claim_by_mobile,
        claim_by_id_card,
        // - partner
        partner_id,
        partner_type,
        partner_code,
        partner_name,
        partner_mobile,
        partner_id_card,
        // - buyer
        buyer_code,
        buyer_mobile,
        buyer_name,
        buyer_type,
        buyer_id_card,
        // receipt type
        req_receipt_type,
        receipt_branch_code,
        receipt_branch_name,
        ship_to,
        ship_mobile,
        ship_name,
        ship_to_id,
        ship_address_no,
        ship_sub_district,
        ship_district,
        ship_province,
        ship_post_code,
        ship_country,
        // - bill
        // ref_document_date,
        // ref_document_no,
        // order_type,
        // receipt_branch_name,
        // req_receive_text,
        // req_receive_type_text,
        // ship_address,
        // ship_mobile,
        // ship_to,
      } = document;
      state.documentDetail = {
        ...initialState.documentDetail,
        document_no,
        document_date,
        branch_code,
        branch_name,
        user_branch_code,
        user_branch_name,
        branch_priority,
        manager_priority,
        remark,
        claim_type,
        claim_type_text,
        status,
        consider_status,
        ship_to,
        ship_mobile,
      };
      state.receiptType = {
        ...initialState.receiptType,
        req_receipt_type,
        receipt_branch_code,
        receipt_branch_name,
        ship_to,
        ship_mobile,
        ship_name,
        ship_to_id,
        ship_address_no,
        ship_sub_district,
        ship_district,
        ship_province,
        ship_post_code,
        ship_country,
      };
      state.claimBy = {
        ...initialState.claimBy,
        id: claim_by_id,
        type: claim_by_type,
        code: claim_by_code,
        name: claim_by_name,
        mobile: claim_by_mobile,
        id_card: claim_by_id_card,
      };
      state.partner = {
        ...initialState.partner,
        id: partner_id,
        type: partner_type,
        code: partner_code,
        name: partner_name,
        mobile: partner_mobile,
        id_card: partner_id_card,
      };
      state.authority = {
        ...initialState.authority,
        ...authority,
      };
      state.bill = {
        ...initialState.bill,
        ...bill,
        buyer_code,
        buyer_mobile,
        buyer_name,
        buyer_type,
      };
      state.billProduct = {
        ...initialState.billProduct,
        ...getClaimSelectBillProduct({
          ...bill_product,
          document_status: status,
        }),
      };
      state.claimOutProduct = {
        ...initialState.claimOutProduct,
        ...mapDataToItemKey(claim_out_product, "claim-out-product"),
      };
      state.claimOutVoucher = {
        ...initialState.claimOutVoucher,
        ...mapDataToItemKey(claim_out_voucher, "claim-out-voucher"),
      };
      state.remark = {
        ...state.remark,
        ..._.reduce(
          claim_remark,
          (res, n) => ({
            ...res,
            [n.status_type]: {
              create_by: n.create_by,
              create_date: n.create_date,
              remark: n.remark,
            },
          }),
          {}
        ),
      };
      state.fetch.claim_select = { ...initFetch };
    },
    [getClaimSelect.rejected]: (state, { payload }) => {
      state.fetch.claim_select = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
    [getConsiderPermission.pending]: (state) => {
      state.fetch.consider_permission = {
        ...initFetch,
        loading: true,
      };
    },
    [getConsiderPermission.fulfilled]: (state, { payload }) => {
      state.consider = {
        ...initialState.consider,
        ...payload,
      };
      state.fetch.consider_permission = { ...initFetch };
    },
    [getConsiderPermission.rejected]: (state, { payload }) => {
      state.fetch.consider_permission = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
    [getStockBalance.pending]: (state) => {
      state.fetch.stock_balance = {
        ...initFetch,
        loading: true,
      };
    },
    [getStockBalance.fulfilled]: (state, { payload }) => {
      const { itemKeys, itemByKey } = state.claimOutProduct;
      state.claimOutProduct.itemByKey = _.reduce(
        itemKeys,
        (res, key) => ({
          ...res,
          [key]: {
            ...itemByKey[key],
            stock_balance:
              _.find(
                payload,
                (n) => n.product_code === itemByKey[key].product_code
              )?.stock_balance || 0,
            bom: _.map(
              _.find(
                payload,
                (n) => n.product_code === itemByKey[key].product_code
              )?.bom,
              (x, i) =>
                x.product_code === itemByKey[key].bom[i].product_code && {
                  ...itemByKey[key].bom[i],
                  stock_balance: x.stock_balance || 0,
                }
            ),
          },
        }),
        {}
      );

      state.fetch.stock_balance = { ...initFetch };
    },
    [getStockBalance.rejected]: (state, { payload }) => {
      state.fetch.stock_balance = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
  },
});

// action
export const actionClaim = claimSlice.actions;

// reducer
const ClaimReducer = claimSlice.reducer;
export default ClaimReducer;
