import { Form, Select } from "antd";
import React, { useContext } from "react";
import BranchContext from "../../../../../../contexts/branch.context";

const FormBranch = (props) => {
    const { state } = useContext(BranchContext);

    let { getFieldDecorator } = props.form;

    return (
        <Form wrapperCol={{ md: 14 }} labelCol={{ md: 10 }}>
            <Form.Item label="รหัสสาขา" className="mb-0">
                {state.form.code}
            </Form.Item>
            <Form.Item label="ชื่อสาขา" className="mb-0">
                {state.form.name}
            </Form.Item>
            <Form.Item label="ประเภท" className="mb-0">
                {getFieldDecorator("branch_type", {
                    rules: [{ required: true, message: "กรุณาเลือกประเภท" }],
                    initialValue: state.form.branch_type,
                })(
                    <Select>
                        <Select.Option value="normal">ปกติ</Select.Option>
                        <Select.Option value="pick and pay">
                            Pick and Pay
                        </Select.Option>
                    </Select>
                )}
            </Form.Item>
        </Form>
    );
};

export default Form.create("branch")(FormBranch);
