/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, DatePicker, Input, Row, Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import TabUnderLine from "../../../components/TabUnderLine";
import "../../../scss/delivery.scss";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import SelectBranch from "../pos/components/SelectBranch";
import Arrange from "./Arrange";
import ArrangeContext, { initState, reducer } from "./reducer";
import Waiting from "./Waiting";
import { getUser } from "../../../utils";

const Index = ({ match, history }) => {
  const { process: process_params } = match.params;
  const [state, dispatch] = useReducer(reducer, initState);
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const {
    listTab,
    search: { date_from, date_to, bill_branch_code },
  } = state;
  const user = getUser();
  
  useEffect(() => {
    let res =
      "เลขที่บิล, รหัสนักธุรกิจ, ชื่อนักธุรกิจ, เลขที่ชุดจอง, เลขที่จองย่อย, เลขที่ชุดชำระเงิน";
    if (process_params !== "wait") res += ", เลขที่ใบจัด";
    setSearchPlaceHolder(res);

    if (process_params === "complete") {
      dispatch({
        type: "setGlobalState",
        name: "search",
        payload: {
          date_from: moment(),
          date_to: moment(),
          page: 1,
        },
      });
    }
  }, [process_params]);

  const handleClickProcess = useCallback((e) => {
    history.push({ pathname: `/arrange/${e}` });
  }, []);

  return (
    <ArrangeContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>การจัดสินค้า</PanelHeader>
        <PanelBody>
          <Row className="mb-4 mt-3">
            <Col span={18} offset={3}>
              <TabUnderLine
                active={process_params}
                list={listTab}
                onClick={handleClickProcess}
              />
            </Col>
          </Row>
          <Row gutter={8} type="flex" className="mb-2 align-items-center">
            <Col span={2} className="text-right">
              <Typography.Text strong className="text-nowrap">
                แหล่งที่มา
              </Typography.Text>
            </Col>
            <Col span={6}>
              <SelectBranch
                withAll
                size="small"
                className="w-100"
                value={bill_branch_code}
                // disableOnlineBranch
                onChange={(value) =>
                  dispatch({
                    type: "setGlobalState",
                    name: "search",
                    payload: {
                      bill_branch_code: value,
                      page: 1,
                    },
                  })
                }
              />
            </Col>
            <Col span={2} className="text-right">
              <Typography.Text strong className="text-nowrap">
                วันที่
              </Typography.Text>
            </Col>
            <Col span={6}>
              <DatePicker.RangePicker
                size="small"
                className="w-100"
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="DD/MM/YYYY"
                value={[date_from, date_to]}
                onChange={(e) =>
                  dispatch({
                    type: "setGlobalState",
                    name: "search",
                    payload: {
                      date_from: e[0] || null,
                      date_to: e[1] || null,
                      page: 1,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={8} type="flex" className="mb-2 align-items-center">
            <Col span={2}></Col>
            <Col span={14}>
              <Input.Search
                allowClear
                placeholder={searchPlaceHolder}
                className="w-100"
                size="small"
                onSearch={(query) =>
                  dispatch({
                    type: "setGlobalState",
                    name: "search",
                    payload: { query, page: 1 },
                  })
                }
              />
            </Col>
          </Row>

          {process_params === "wait" ? (
            <Waiting />
          ) : (
            <Arrange status={process_params} />
          )}
        </PanelBody>
      </Panel>
    </ArrangeContext.Provider>
  );
};

export default Index;
