import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const fitUserServices = {
  useQueryGetUsers({ query, page, take }, onSuccess) {
    return useQuery(
      ['get-fit-users', { query, page, take }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/users`, {
            params: {
              ...queryKey[1].query,
              page: queryKey[1].page,
              take: queryKey[1].take,
            },
          });

          return res.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetUserById({ userId }, onSuccess) {
    return useQuery(
      ['get-fit-user-by-id', { userId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/users/${queryKey[1].userId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        enabled: !!userId,
        onSuccess,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useMutationUpdateUser(onSuccess, onError) {
    return useMutation(
      async ({ userId, data }) => {
        try {
          const res = await fitStudioRequest.patch(`/users/${userId}`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useQueryGetUserByMemberCode({ memberCode }, onSuccess) {
    return useQuery(
      ['get-user-by-member-code', { memberCode }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/users/user-by-member-code/${queryKey[1].memberCode}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        enabled: !!memberCode,
        onSuccess,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
};

export default fitUserServices;
