import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const trainerRegisterPaperQueryDefaultValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

export const trainerRegisterPaperQueryFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      firstName: yup.string(),
      lastName: yup.string(),
      phoneNumber: yup.string(),
    })
  );
};
