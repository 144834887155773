/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Modal } from "antd";
import React, { useEffect, useState, useReducer } from "react";
import { useHttp } from "../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import { AlertConfirm, AlertError, AlertSuccess } from "../../../util/Alert";
import LayoutInfo from "../components/LayoutInfo";
import Members from "./components/Member";
import MemberAddress from "./components/MemberAddress";
import MemberFile from "./components/MemberFile";
import { objVipMember } from "../vipmember-state";
import VipMemberContext, * as VipMember from "./contexts/vip-member-context";
import MemberRemark from "./components/MemberRemark";

const Index = (props) => {
  const id = Number(props.match.params.id) || 0;

  const [state, dispatch] = useReducer(
    VipMember.reducer,
    VipMember.initialState
  );
  const { ActiveTabs } = state;
  const [Member, setMember] = useState(objVipMember);
  const [errors, setErrors] = useState({});

  const [loadingMember, dataMember, errorMember] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/vip-member/${id}`,
    token: true,
  });
  const [loadingAddress, dataAddress, errorAddress] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/vip-member/${id}/address`,
    token: true,
  });

  const [loadingImage, dataImage, errorImage, reloadImge] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/vip-member/${id}/image`,
    token: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["first_name_en", "sur_name_en", "passport_id"].includes(name)) {
      setMember({
        ...Member,
        [name]: value.toUpperCase(),
      });
    } else {
      setMember({ ...Member, [name]: value });
    }
  };
  const handleChangeAddress = (e, group) => {
    const { name, value } = e.target;
    setMember({
      ...Member,
      [group]: {
        ...Member[group],
        [name]: value,
      },
      [`${group}Update`]: {
        ...Member[group],
        [name]: value,
      },
    });
  };

  const sendSubmit = async () => {
    const invalid = await validation();
    if (invalid) return;
    const confirm = await AlertConfirm();
    console.log("Submitted", Member);
    if (confirm) {
      await axios({
        method: "post",
        url: `${URL_API}/pos/vip-member/${id}`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          member: Member,
        },
      })
        .then((res) => {
          const success = AlertSuccess("แก้ไขสำเร็จ");
          if (success) props.history.goBack(); //props.history.replace('/ethics')
        })
        .catch((err) => {
          console.log(err.response.data);
          Modal.warning({
            title: "คำเตือน",
            content: `${err.response.data.message}`,
            okText: "ปิด",
          });
        });
    }
  };

  const checkID = (id) => {
    let sum = 0;
    if (id?.length !== 13) {
      return false;
    }
    for (let i = 0; i < 12; i++) {
      sum += parseFloat(id.charAt(i)) * (13 - i);
    }
    if ((11 - (sum % 11)) % 10 !== parseFloat(id.charAt(12))) {
      return false;
    } else {
      return true;
    }
  };

  const validation = async () => {
    let err = {};
    const notEmpty = [
      "first_name_th",
      "sur_name_th",
      // 'mobile',
    ];
    for (let name in Member) {
      if (notEmpty.includes(name)) {
        if (Member[name] === "" || Member[name] == null) {
          err[name] = " *";
        }
      }
    }
    //check mobile
    // if(!Member.mobiles || !Member.mobiles.length){
    //     err.mobiles = ' *'
    // }

    if (
      Member.address?.length > 0 ||
      Member.province_id != null ||
      Member.district_id != null ||
      Member.sub_district_id != null
    ) {
      if (Member.address === "" || Member.address == null)
        err["address"] = " *";
      if (Member.province_id == null) err["province"] = " *";
      if (Member.district_id == null) err["district"] = " *";
      if (Member.sub_district_id == null) err["sub_district"] = " *";
    }

    if (Member.id_card?.length > 0) {
      if (Member?.nationality === "TH") {
        if (!checkID(Member.id_card)) {
          //console.log("รหัสบัตรประชาชนของผู้สมัครหลักไม่ถูกต้อง!!");
          err["id_card"] = "*เลขบัตรไม่ถูกต้อง";
        }
      } else {
        if (Member.id_card === "" || Member.id_card == null) {
          err["id_card"] = "*เลขพาสปอร์ตไม่ถูกต้อง";
        }
      }
    }

    if (Member.passport_id?.length > 0) {
      if (Member?.nationality === "TH") {
        if (!checkID(Member.passport_id)) {
          err["passport_id"] = "*เลขพาสปอร์ตไม่ถูกต้อง";
        } else if (Member.passport_id === Member.id_card) {
          err["passport_id"] = "*เลขซ้ำกับเลขประจำตัวประชาชน";
        }
      }
    }

    if (Member?.is_company === 1) {
      if (Member?.company_name_th === "" || Member?.company_name_th == null) {
        err["company_name_th"] = " *";
      }
      if (Member?.company_type === "" || Member?.company_type == null) {
        err["company_type"] = " *";
      }
      if (Member?.company_register_vat == null) {
        err["company_register_vat"] = " *";
      }
      if (Member?.company_register_vat === 0) {
        if (Member?.company_tax === "" || Member?.company_tax == null) {
          err["company_tax"] = " *";
        }
      }
    }
    setErrors(err);
    console.log(errors);

    for (let name in Member) {
      if (err[name]) {
        const error = await AlertError({ title: "กรุณากรอกข้อมูลให้ครบ" });
        if (error) return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setMember({
      ...Member,
      //---------------Company------------------
      is_company: dataMember?.data.is_company,
      company_name_th: dataMember?.data.company_name,
      company_name_en: dataMember?.data.company_name_en,
      company_type: dataMember?.data.company_type,
      company_tax: dataMember?.data.company_tax,
      company_register_vat: dataMember?.data.company_register_vat,

      //----------register and expire date--------------------
      register_date: dataMember?.data.register_date,
      expire_date: dataMember?.data.expire_date,

      //---------data member----------------------------
      id: dataMember?.data.id,
      partner_code: dataMember?.data.member_code,
      prefix: dataMember?.data.prefix,
      first_name_th: dataMember?.data.first_name_th,
      first_name_en: dataMember?.data.first_name_en,
      sur_name_th: dataMember?.data.sur_name_th,
      sur_name_en: dataMember?.data.sur_name_en,
      nationality: dataMember?.data.nationality,
      origin: dataMember?.data.origin,
      id_card: dataMember?.data.id_card,
      member_type: dataMember?.data.member_type,
      mobile: dataMember?.data.mobile,
      email: dataMember?.data.email,
      birth_date: dataMember?.data.birth_date,
      gender: dataMember?.data.gender,
      phone: dataMember?.data.phone,
      line_id: dataMember?.data.line_id,
      facebook: dataMember?.data.facebook,
      mobiles: dataMember?.data.mobiles,
      passport_id: dataMember?.data.passport_id,
      passport_expire_date: dataMember?.data.passport_expire_date,
      phone_code: dataMember?.data.phone_code,
      mobile_prefix: dataMember?.data?.mobile_prefix,

      sponsor: {
        first_name: dataMember?.data.first_name_sponsor,
        sur_name: dataMember?.data.sur_name_sponsor,
        sponsor_code: dataMember?.data.sponsor_code,
        mobile: dataMember?.data.mobile_sponsor,
      },

      idAddress: {
        address: dataAddress?.data?.id_address.address,
        sub_district: dataAddress?.data?.id_address.sub_district,
        district: dataAddress?.data?.id_address.district,
        province: dataAddress?.data?.id_address.province,
        post_code: dataAddress?.data?.id_address.post_code,
        sub_district_id: dataAddress?.data?.id_address.sub_district_id,
        district_id: dataAddress?.data?.id_address.district_id,
        province_id: dataAddress?.data?.id_address.province_id,
      },
      taxAddress: {
        address: dataAddress?.data?.tax_address.address,
        sub_district: dataAddress?.data?.tax_address.sub_district,
        district: dataAddress?.data?.tax_address.district,
        province: dataAddress?.data?.tax_address.province,
        post_code: dataAddress?.data?.tax_address.post_code,
        sub_district_id: dataAddress?.data?.tax_address.sub_district_id,
        district_id: dataAddress?.data?.tax_address.district_id,
        province_id: dataAddress?.data?.tax_address.province_id,
      },
      currentAddress: {
        address: dataAddress?.data?.current_address.address,
        sub_district: dataAddress?.data?.current_address.sub_district,
        district: dataAddress?.data?.current_address.district,
        province: dataAddress?.data?.current_address.province,
        post_code: dataAddress?.data?.current_address.post_code,
        sub_district_id: dataAddress?.data?.current_address.sub_district_id,
        district_id: dataAddress?.data?.current_address.district_id,
        province_id: dataAddress?.data?.current_address.province_id,
      },
    });
    if (errorMember) AlertError({ text: errorMember });
  }, [
    dataMember,
    errorMember,
    dataAddress,
    errorAddress,
    dataImage,
    errorImage,
  ]);

  return (
    <>
      <VipMemberContext.Provider value={{ state, dispatch }}>
        <Panel className="bg-transparent">
          <PanelHeader>
            <div className="d-flex justify-content-between">
              ตรวจเอกสาร{" "}
              <span className="pr-2">
                สถานะ: {dataMember?.data.name_status}{" "}
              </span>
            </div>
          </PanelHeader>
          <PanelBody>
            <Row gutter={20}>
              <Col md={12}>
                <LayoutInfo
                  content={
                    <Members
                      loadingMember={loadingMember}
                      Member={Member}
                      errors={errors}
                      setMember={setMember}
                      handleChange={handleChange}
                    />
                  }
                />
                <LayoutInfo
                  content={
                    ["Member"].includes(ActiveTabs.active_tab_name) && (
                      <MemberAddress
                        loadingAddress={loadingAddress}
                        id={id}
                        Member={Member}
                        setMember={setMember}
                        errors={errors}
                        handleChange={handleChangeAddress}
                      />
                    )
                  }
                />
              </Col>
              <Col md={12}>
                <LayoutInfo
                  content={
                    <MemberFile
                      loadingImage={loadingImage}
                      id={id}
                      dataImage={dataImage}
                      reloadImge={reloadImge}
                    />
                  }
                />

                <LayoutInfo
                  content={
                    <MemberRemark
                      ref_id={id}
                      handleChange={(e) => setMember({ ...Member, remark: e })}
                    />
                  }
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                className="m-2"
                onClick={() => sendSubmit()}
              >
                บันทึก
              </Button>
              <Button
                type="danger"
                className="m-2"
                onClick={() => props.history.goBack()}
              >
                ยกเลิก
              </Button>
            </div>
          </PanelBody>
        </Panel>
      </VipMemberContext.Provider>
    </>
  );
};
export default Index;
