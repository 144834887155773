/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  notification,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import * as XLSX from "xlsx-js-style";

const Index = () => {
  const { Text } = Typography;

  const { Option } = Select;

  const toDay = moment().format("DD/MM/YYYY");
  const user = getUser();

  
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);

  //Data
  const [dataSource, setDataSource] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState("");

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [productFilter, setProductFilter] = useState("");
  const [channelFilter, setChannelFilter] = useState("");
  const [channelFilterText, setChannelFilterText] = useState("ทั้งหมด");
  const [branchReceiveFilter, setBranchReceiveFilter] = useState(
    user.branch_code
  );
  const [branchReceiveFilterText, setBranchReceiveFilterText] = useState(
    user.branch_name
  );

  const limit = 50;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-reserve`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        productFilter: productFilter,
        channelFilter: channelFilter,
        branchReceiveFilter: branchReceiveFilter,

        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-reserve-filter`,
      token: true,
      params: {
        userId: user.id,
      },
    },
    []
  );
  useEffect(() => {}, [filterData]);
  useEffect(() => {
    setFilterDataSource(filterData ? filterData : []);
  }, [filterData]);
  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const closeModal = () => {
    setPreviewModal(false);
  };

  const showModalData = (
    branchNameReceive,
    branchName,
    productCode,
    receiveDate,
    set_type,
    ref_product_code = null
  ) => {
    console.log(branchNameReceive,
      branchName,
      productCode,
      receiveDate,
      set_type,
      ref_product_code
      )
    let showData = []
    if(set_type === 'สินค้าชุดย่อย'){
      showData = dataSource.previewData.filter(
        (data) =>
          data.branch_name_receive === branchNameReceive &&
          data.branch_name === branchName &&
          data.product_code === productCode &&
          moment(data.document_date).format("DD/MM/YYYY") ===
            moment(receiveDate).format("DD/MM/YYYY")
          && data.set_type === set_type
          && data.ref_product_code === ref_product_code
      );
    }else{
      showData = dataSource.previewData.filter(
        (data) =>
          data.branch_name_receive === branchNameReceive &&
          data.branch_name === branchName &&
          data.product_code === productCode &&
          moment(data.document_date).format("DD/MM/YYYY") ===
            moment(receiveDate).format("DD/MM/YYYY")
          && data.set_type === set_type
      );
    }
    
    setPreviewModal(true);
    setPreviewData(showData);
  };

  const productOptions = [
    ..._.map(filterDataSource.dataProduct, (product) => {
      return { value: product.product_code, text: product.product_name_en };
    }),
  ];

  const receiveBranchOptions = [
    ..._.map(filterDataSource.dataReceiveBranch, (data) => {
      return {
        value: data.branch_code,
        text: data.branch_name,
      };
    }),
  ];

  const reserveChannelOptions = [
    ..._.map(filterDataSource.dataReserveChannel, (data) => {
      return { value: data.code, text: data.branch_name };
    }),
  ];
  const exportToExcel = () => {
    let title_table = [
      "ลำดับ",
      "วันที่จอง",
      "สาขาที่รับ",
      "ช่องทางการจอง",
      "รหัสสินค้า",
      "ชื่อสินค้า",
      "ประเภทสินค้า",
      "จำนวน (ชิ้น)",
    ];

    let productName = _.map(productFilter, (code) => {
      let data = _.find(
        filterDataSource.dataProduct,
        (p) => code === p.product_code
      );

      return data.product_name_en;
    });

    let reduceProductName = _.join(
      _.reduce(productName, (res, key) => [...res, key], []),
      ","
    );

    const exportData = data?.data ? data?.data : [];
    if (exportData.length !== 0) {
      let excelData = [
        ["รายงานยอดการจอง"],
        [
          
          `รายการสินค้า  ${productFilter.length === 0 ? "" : reduceProductName}`,
          "",
          "",
          "",
          "",
          `วันที่  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,

          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
        ],
        [
          `สาขาที่รับ ${branchReceiveFilterText ? branchReceiveFilterText : "ทั้งหมด"}`,
          "",
          "",
          `ช่องทางการจอง ${channelFilterText ? channelFilterText : "ทั้งหมด"}`,
          "",
        ],
        [],
        title_table,
      ];

      exportData.forEach((n) => {
        let row_data = [
          { v: n.row_id, s: { alignment: { horizontal: "center" } } },
          {
            v: n.document_date
              ? moment(n.document_date).format("DD/MM/YYYY")
              : "",
          },
          { v: n.branch_name_receive ? n.branch_name_receive : "" },
          { v: n.branch_name ? n.branch_name : "" },
          { v: n.product_code ? n.product_code : "" },
          { v: n.product_name_en ? n.product_name_en : "" },
          { v: n.set_type ? n.set_type : "" },
          {
            v: n.qty ? n.qty : "-",
            s: { alignment: { horizontal: "center" } },
          },
        ];

        excelData = [...excelData, row_data];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
        { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
      ];
      const wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 40 },
        { wch: 20 },
        { wch: 20 },

      ];
      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานยอดการจอง");
      XLSX.writeFile(wb, "รายงานยอดการจอง.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานยอดการจอง</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={2} align="left">
              <Text strong>รายการสินค้า</Text>
            </Col>
            <Col span={6} align="left">
              <Select
                className="w-100"
                mode="multiple"
                placeholder="ชื่อสินค้า"
                onChange={(e) => {
                  setProductFilter(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productOptions.map((product, i) => {
                  return (
                    <Option key={i.toString()} value={product.value}>
                      {product.text}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={2} align="middle">
              <Text strong>ช่วงวันที่ขาย</Text>
            </Col>
            <Col span={6} align="middle">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col span={8}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      reload();
                      // exportReload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={1} align="left">
              <Text strong>สาขาที่รับ</Text>
            </Col>

            <Col span={4} align="left" push={1}>
              <SelectOption
                className="w-100"
                withAll={user.branch_count > 1}
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                options={receiveBranchOptions}
                onChange={(e,n) => {
                  setBranchReceiveFilterText(n.props.children)
                  setBranchReceiveFilter(e);
                }}
              />
            </Col>

            <Col span={2} align="middle" push={3}>
              <Text strong>ช่องทางการจอง</Text>
            </Col>

            <Col span={4} align="left" push={3}>
              <SelectOption
                className="w-100"
                withAll={user.branch_count > 1}
                defaultValue={""}
                options={reserveChannelOptions}
                onChange={(e,n) => {
                  setChannelFilterText(n.props.children)
                  setChannelFilter(e);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับที่" dataIndex="row_id" align="center" />
            <Table.Column
              title="วันที่จอง"
              dataIndex="document_date"
              align="center"
              render={(text) => {
                return moment(text).format("DD/MM/YYYY");
              }}
            />
            <Table.Column
              title="สาขาที่รับ"
              dataIndex="branch_name_receive"
              align="center"
              render={(text) => {
                if (text === null) {
                  return (text = "-");
                }
                return text;
              }}
            />
            <Table.Column
              title="ช่องทางการจอง"
              dataIndex="branch_name"
              align="center"
            />
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              align="center"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name_en"
              align="left"
            />
            <Table.Column
              title="ประเภทสินค้า"
              dataIndex="set_type"
              align="left"
              render={(text) => {
                if (text === null) {
                  return (text = "-");
                }
                return text;
              }}
            />
            <Table.Column
              title="จำนวน"
              dataIndex="qty"
              align="center"
              render={(text, i) => {
                return (
                  <Text
                    onClick={() => {
                      console.log(i)
                      showModalData(
                        i.branch_name_receive,
                        i.branch_name,
                        i.product_code,
                        i.document_date,
                        i.set_type,
                        i.set_type === 'สินค้าชุดย่อย' ? i.ref_product_code : null
                      );
                    }}
                  >
                    {numeral(text).format("0,00")} <FaEye />
                  </Text>
                );
              }}
            />
          </Table>
          <Modal
            width={800}
            title="รายละเอียดการจอง"
            visible={previewModal}
            footer={[]}
            onOk={closeModal}
            onCancel={closeModal}
          >
            <Table dataSource={previewData} pagination={false} rowKey="reserve_order_no">
              <Table.Column title="เลขที่การจอง" dataIndex="reserve_order_no" />
              <Table.Column
                title="สาขาที่รับ"
                dataIndex="branch_name_receive"
              />
              <Table.Column title="ช่องทางการจอง" dataIndex="branch_name" />
              <Table.Column title="รหัสสมาชิก" dataIndex="buyer_code" />
              <Table.Column title="ชื่อสมาชิก" dataIndex="buyer_name" />
              <Table.Column title="จำนวน" dataIndex="qty" />
            </Table>
          </Modal>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
