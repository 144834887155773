/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import ControlPanel from '../components/ControlPanel'
import { Table } from 'antd'
import _ from 'lodash'
import numeral from 'numeral'

export default () => {
    const months = [
        { key: 1, short_name: 'ม.ค', long_name: 'มกราคม' },
        { key: 2, short_name: 'ก.พ.', long_name: 'กุมภาพันธ์' },
        { key: 3, short_name: 'มี.ค.', long_name: 'มีนาคม' },
        { key: 4, short_name: 'เม.ย.', long_name: 'เมษายน' },
        { key: 5, short_name: 'พ.ค.', long_name: 'พฤษภาคม' },
        { key: 6, short_name: 'มิ.ย.', long_name: 'มิถุนายน' },
        { key: 7, short_name: 'ก.ค.', long_name: 'กรกฎาคม' },
        { key: 8, short_name: 'ส.ค.', long_name: 'สิงหาคม' },
        { key: 9, short_name: 'ก.ย.', long_name: 'กันยายน' },
        { key: 10, short_name: 'ต.ค.', long_name: 'ตุลาคม' },
        { key: 11, short_name: 'พ.ย.', long_name: 'พฤศจิกายน' },
        { key: 12, short_name: 'ธ.ค.', long_name: 'ธันวาคม' }
    ];
    const teamList = [
        'Clover'
        , 'Clover_Think'
        , 'Dreams'
        , 'Ever'
        , 'Givers'
        , 'Life'
        , 'Mind'
        , 'Real'
        , 'Together'
        , 'Victors'
        , 'Zeeds'
    ];
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const renderData = () => {
        let newData = [];

        newData = months.map(m => {
            let obj = {};

            obj.key = _.uniqueId('tr-');
            obj.month = m.short_name;
            teamList.forEach(n => {
                obj[n] = Math.floor(Math.random() * 10000) * 1000;
            })

            return obj;
        })

        setDataSource(newData);
    }

    useEffect(() => {
        let newColumns = [];

        newColumns.push({
            title: 'Month',
            className: 'text-center',
            key: 'month',
            dataIndex: 'month'
        });

        teamList.forEach(n => {
            newColumns.push({
                title: n,
                className: 'text-center text-capitalize',
                key: n,
                dataIndex: n,
                render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
            });
        })

        newColumns.push({
            title: 'Grand Total',
            className: 'text-center',
            key: 'grand_total',
            render: (text, record) => <p className="m-0 text-right">{numeral(_.reduce(_.values(_.omit(record, ['key', 'month'])), (sum, n) => sum + n, 0)).format('0,0')}</p>
        });

        setColumns(newColumns);
    }, [dataSource]);

    useEffect(() => {
        renderData();
    }, []);

    return (
        <Layout
            // breadcrumb={breadcrumb}
            titlePage="รายงานยอดขายสินค้า Reborn"
            subTitlePage=""
        >
            <ControlPanel titlePanel="รายงานยอดขายสินค้า Reborn">
                {/* <Row>
                    <Col md={12}><Chart type="line" options={lineChart.options} series={lineChart.series} /></Col>
                    <Col md={12}>
                        <Chart type="pie" options={pieChart.options} series={pieChart.series} />
                    </Col>
                </Row> */}
                <Table
                    bordered
                    size="small"
                    // loading={true}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: true }}
                />
            </ControlPanel>
        </Layout>
    )
}
