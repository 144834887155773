import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class ModalDefault extends Component {

	constructor(props) {
		super(props);
		this.state={

		}
	}


	render() {
		let { title, show, close, content, size = "md", backdrop } = this.props;
		return (

			<>
				<Modal isOpen={show} toggle={() => backdrop ? close(true) : close(false)} size={size} >
					<ModalHeader toggle={() => close(false)} >{title}</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col">
								{content}
							</div>
						</div>

					</ModalBody>
				</Modal>

			</>
		)
	}
}
