import { createSlice } from "@reduxjs/toolkit";
import { fixProductTypeSet } from "../../config/fix-value";
import { _ } from "../../utils";
import { getThunkError } from "../../views/util/helper";
import { initFetch } from "../logic/initial-value";
import {
  getInventoryCountByDocumentNo,
  getInventoryCountStock,
} from "../thunk/inventory-count";

const initCountForm = {
  shelf: 0,
  storage_shelf: 0,
  warehouse: 0,
  total_count: 0,
  total_all_count: 0,
  difference: 0,
  lot: null,
  mfg_date: null,
  exp_date: null,
  remark: null,
};

const initEachWarehouse = {
  search: {
    limit: 50,
    page: 1,
    query: null,
    oracle_category: "",
    oracle_sub_category: "",
    status: "",
  },
  itemKeys: [],
  itemByKey: {},
};

const initialState = {
  isNotFound: false,
  searchDocument: {
    query: null,
    status: null,
    branch_code: null,
    branch_name: null,
    date: null,
    page: 1,
    limit: 10,
  },
  modalDifference: {
    visible: false,
    warehouse_name: null,
    page: 1,
    limit: 10,
  },
  warehouse: {
    activeKey: "primary_set",
    itemKeys: ["primary_set", "primary", "back", "distribute", "broken"],
    itemByKey: {
      primary_set: {
        key: "primary_set",
        name: "ชุดเซ็ต (คลังดี)",
        status: null,
      },
      primary: {
        key: "primary",
        name: "คลังดี",
        status: null,
      },
      back: {
        key: "back",
        name: "คลังคืน",
        status: null,
      },
      distribute: {
        key: "distribute",
        name: "คลังคัดแยก",
        status: null,
      },
      broken: {
        key: "broken",
        name: "คลังเสีย",
        status: null,
      },
    },
  },
  primary: _.cloneDeep(initEachWarehouse),
  primary_set: _.cloneDeep(initEachWarehouse),
  back: _.cloneDeep(initEachWarehouse),
  distribute: _.cloneDeep(initEachWarehouse),
  broken: _.cloneDeep(initEachWarehouse),
  document: {
    document_no: null,
    document_date: null,
    document_status: null,
    document_remark: null,
  },
  stock: {
    itemKeys: [],
    itemByKey: {},
  },
  media: {
    itemDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  // remark: {
  //   itemKeys: [],
  //   itemByKeys: {},
  //   new_remark: null,
  // },
  remark: {
    primary: {
      remark:""
    },
    back: {
      remark:""
    },
    distribute: {
      remark:""
    },
    broken: {
      remark:""
    },
  },
  user: {
    user_id: null,
    permission_status: {},
  },
  fetch: {
    stock: { ...initFetch },
    document: { ...initFetch },
  },
  excelData: [],
};

const inventoryCountSlice = createSlice({
  name: "inventoryCount",
  initialState,
  reducers: {
    setExcelData: (state, { payload }) => {
      state.excelData = payload;
    },
    setGlobalState: (state, { payload: { name, value } }) => {
      state[name] = {
        ...state[name],
        ...value,
      };
    },
    clearGlobalState: (state, { payload }) => {
      state[payload] = _.cloneDeep(initialState[payload]);
    },
    clearModalDifference: (state) => {
      state.modalDifference = _.cloneDeep(initialState.modalDifference);
    },
    clear: (state) => {
      state.isNotFound = initialState.isNotFound;
      state.searchDocument = { ...initialState.searchDocument };
      state.modalDifference = { ...initialState.modalDifference };
      state.warehouse = _.cloneDeep(initialState.warehouse);
      state.primary = _.cloneDeep(initialState.primary);
      state.primary_set = _.cloneDeep(initialState.primary_set);
      state.back = _.cloneDeep(initialState.back);
      state.distribute = _.cloneDeep(initialState.distribute);
      state.broken = _.cloneDeep(initialState.broken);
      state.document = { ...initialState.document };
      state.stock = _.cloneDeep(initialState.stock);
      state.media = _.cloneDeep(initialState.media);
      state.remark = _.cloneDeep(initialState.remark);
      state.user = _.cloneDeep(initialState.user);
      state.fetch = _.cloneDeep(initialState.fetch);
    },
    setWarehouseItemValue: (
      state,
      { payload: { type, key, name, prev, ...data } }
    ) => {
      if (type === "primary_set" && data.total_count !== undefined) {
        // case change add count of primary set
        const { bom } = state.primary_set.itemByKey[key];
        for (let i = 0; i < bom.length; i++) {
          const item = bom[i];
          const primary_key = _.find(state.primary.itemKeys, (n) => {
            const { product_id, is_first } = state.primary.itemByKey[n];
            return is_first && product_id === item.product_id;
          });
          const add_value = (data[name] - prev) * item.qty;
          if (state.primary.itemByKey[primary_key].is_primary_set) {
            //case update
            if (
              state.primary.itemByKey[primary_key].total_count + add_value ===
              0
            ) {
              const { total_all_count, difference, ...primary_set_data } =
                state.primary.itemByKey[primary_key];
              const new_total_all_count = total_all_count + add_value;
              const new_difference = difference - add_value;
              const new_primary_key = _.find(state.primary.itemKeys, (n) => {
                const { product_id, is_first } = state.primary.itemByKey[n];
                return !is_first && product_id === item.product_id;
              });
              state.primary.itemKeys = _.filter(
                state.primary.itemKeys,
                (key) => key !== primary_key
              );
              state.primary.itemByKey = _.omit(state.primary.itemByKey, [
                primary_key,
              ]);
              state.primary.itemByKey[new_primary_key] = {
                ...primary_set_data,
                ...state.primary.itemByKey[new_primary_key],
                is_first: 1,
                total_all_count: new_total_all_count,
                difference: new_difference,
              };
            } else {
              state.primary.itemByKey[primary_key][name] += add_value;
              state.primary.itemByKey[primary_key].total_count += add_value;
              state.primary.itemByKey[primary_key].total_all_count += add_value;
              state.primary.itemByKey[primary_key].difference += add_value;
            }
          } else {
            // case add
            let { itemKeys, itemByKey } = state.primary;
            const { product_code, total_warehouse, ...primary_first } =
              itemByKey[primary_key];
            const add_key = _.uniqueId(`${product_code}-`);
            const total_all_count =
              _.reduce(
                _.filter(
                  state.primary.itemKeys,
                  (n) =>
                    state.primary.itemByKey[n].product_id === item.product_id
                ),
                (res, key) => res + state.primary.itemByKey[key].total_count,
                0
              ) + add_value;
            itemKeys = [...itemKeys, add_key];
            itemByKey = {
              ...itemByKey,
              [primary_key]: {
                ..._.omit(itemByKey[primary_key], [
                  "total_all_count",
                  "difference",
                ]),
                is_first: 0,
              },
              [add_key]: {
                product_code,
                total_warehouse,
                ...primary_first,
                ...initCountForm,
                [name]: add_value,
                total_count: add_value,
                total_all_count,
                difference: total_all_count - total_warehouse,
                is_first: 1,
                is_default: 0,
                is_primary_set: 1,
                key: add_key,
              },
            };
            state.primary.itemKeys = _.map(
              _.orderBy(
                _.map(itemKeys, (key) => itemByKey[key]),
                ["product_id", "is_first", "is_primary_set"],
                ["asc", "desc", "desc"]
              ),
              (n) => n.key
            );
            state.primary.itemByKey = itemByKey;
          }
        }
      }
      state[type].itemByKey[key] = {
        ...state[type].itemByKey[key],
        ...data,
      };
    },
    addWarehouseItemLot: (state, { payload: { type, key } }) => {
      let { itemKeys, itemByKey } = state[type];
      const { product_id, product_code } = itemByKey[key];
      const add_key = _.uniqueId(`${product_code}-`);
      itemKeys = [...itemKeys, add_key];
      itemByKey = {
        ...itemByKey,
        [add_key]: {
          ...initCountForm,
          product_id,
          product_code,
          key: add_key,
          is_first: 0,
          is_default: 0,
          is_primary_set: 0,
        },
      };
      state[type].itemKeys = _.map(
        _.orderBy(
          _.map(itemKeys, (key) => itemByKey[key]),
          ["product_code", "is_first", "is_primary_set"],
          ["asc", "desc", "desc"]
        ),
        (n) => n.key
      );
      state[type].itemByKey = itemByKey;
    },
    delWarehouseItemLot: (state, { payload: { type, key } }) => {
      const { product_id } = state[type].itemByKey[key];
      const first_key = _.find(
        state[type].itemKeys,
        (n) =>
          state[type].itemByKey[n].product_id === product_id &&
          state[type].itemByKey[n].is_first === 1
      );
      state[type].itemByKey[first_key].total_all_count -=
        state[type].itemByKey[key].total_count;
      state[type].itemKeys = _.filter(state[type].itemKeys, (n) => n !== key);
      state[type].itemByKey = _.omit(state[type].itemByKey, [key]);
      state[type].itemByKey[first_key].difference =
        state[type].itemByKey[first_key].total_all_count -
        state[type].itemByKey[first_key].total_warehouse;
    },
    setSearchWarehouse: (state, { payload }) => {
      const type = state.warehouse.activeKey;
      state[type].search = {
        ...state[type].search,
        ...payload,
      };
    },
    setRemarkWarehouse: (state, { payload }) => {
      const type = state.warehouse.activeKey;
      state.remark[type].remark = payload
    },
    clearCurrentAdd: (state) => {
      const { activeKey } = state.warehouse;
      if (activeKey === "primary") {
        for (let i = 0; i < state.primary_set.itemKeys.length; i++) {
          const key = state.primary_set.itemKeys[i];
          const { total_warehouse } = state.primary_set.itemByKey[key];
          state.primary_set.itemByKey[key] = {
            ...state.primary_set.itemByKey[key],
            ...initCountForm,
            is_first: 1,
            is_default: 1,
            is_primary_set: 0,
            difference: -total_warehouse,
          };
        }
      }

      let delKey = [];
      for (let i = 0; i < state[activeKey].itemKeys.length; i++) {
        const key = state[activeKey].itemKeys[i];
        const { is_default, total_warehouse } = state[activeKey].itemByKey[key];
        if (!!is_default) {
          state[activeKey].itemByKey[key] = {
            ...state[activeKey].itemByKey[key],
            ...initCountForm,
            is_first: 1,
            is_default: 1,
            is_primary_set: 0,
            difference: -total_warehouse,
          };
          continue;
        }
        delKey = [...delKey, key];
      }
      if (delKey.length) {
        state[activeKey].itemKeys = _.filter(
          state[activeKey].itemKeys,
          (key) => !_.includes(delKey, key)
        );
        state[activeKey].itemByKey = _.omit(state[activeKey].itemByKey, delKey);
      }
    },
    selectStock: (state, { payload }) => {
      state.stock.document_no = payload;
      state.stock.document_status = "create";
    },
    clearStock: (state) => {
      state.isNotFound = initialState.isNotFound;
      state.modalDifference = { ...initialState.modalDifference };
      state.warehouse = _.cloneDeep(initialState.warehouse);
      state.primary = _.cloneDeep(initialState.primary);
      state.primary_set = _.cloneDeep(initialState.primary_set);
      state.back = _.cloneDeep(initialState.back);
      state.distribute = _.cloneDeep(initialState.distribute);
      state.broken = _.cloneDeep(initialState.broken);
      state.document = { ...initialState.document };
      state.stock = _.cloneDeep(initialState.stock);
      state.media = _.cloneDeep(initialState.media);
      state.remark = _.cloneDeep(initialState.remark);
      state.fetch = _.cloneDeep(initialState.fetch);
    },
    addStockImage: (state, { payload }) => {
      const key = _.uniqueId("document-img-");
      state.media.itemKeys = [...state.media.itemKeys, key];
      state.media.itemByKey = {
        ...state.media.itemByKey,
        [key]: {
          ...payload,
          key,
        },
      };
    },
    delStockImage: (state, { payload }) => {
      const { id, temp } = state.media.itemByKey[payload];
      if (!temp) {
        state.media.itemDel = [...state.media.itemDel, id];
      }
      state.media.itemKeys = _.filter(
        state.media.itemKeys,
        (key) => key !== payload
      );
      state.media.itemByKey = _.omit(state.media.itemByKey, [payload]);
    },
  },
  extraReducers: {
    [getInventoryCountStock.pending]: (state) => {
      state.fetch.stock = {
        ...initFetch,
        loading: true,
      };
    },
    [getInventoryCountStock.fulfilled]: (state, { payload }) => {
      let warehouse = {
        primary_set: {
          itemKeys: [],
          itemByKey: {},
        },
        primary: {
          itemKeys: [],
          itemByKey: {},
        },
        back: {
          itemKeys: [],
          itemByKey: {},
        },
        distribute: {
          itemKeys: [],
          itemByKey: {},
        },
        broken: {
          itemKeys: [],
          itemByKey: {},
        },
      };
      let itemKeys = [];
      let itemByKey = {};
      _.forEach(payload, (item) => {
        const key = _.uniqueId(`${item.product_code}-`);
        itemKeys = [...itemKeys, key];
        itemByKey = { ...itemByKey, [key]: item };
        const {
          main,
          arrange,
          reserve,
          back,
          distribute,
          broken,
          product_type_set,
          requisition,
        } = item;
        const total_warehouse = main + reserve + requisition; // #923 - ตัด arrange (ค้างจ่าย) ออก
        // const total_warehouse = main + arrange + reserve;
        if (main || arrange || reserve) {
          if (product_type_set === fixProductTypeSet.kit_pack) {
            warehouse.primary_set.itemKeys = [
              ...warehouse.primary_set.itemKeys,
              key,
            ];
            warehouse.primary_set.itemByKey = {
              ...warehouse.primary_set.itemByKey,
              [key]: {
                ...item,
                ...initCountForm,
                is_first: 1,
                is_default: 1,
                is_primary_set: 0,
                total_warehouse,
                difference: -total_warehouse,
                key,
              },
            };
          } else {
            warehouse.primary.itemKeys = [...warehouse.primary.itemKeys, key];
            warehouse.primary.itemByKey = {
              ...warehouse.primary.itemByKey,
              [key]: {
                ...item,
                ...initCountForm,
                is_first: 1,
                is_default: 1,
                is_primary_set: 0,
                total_warehouse,
                difference: -total_warehouse,
                key,
              },
            };
          }
        }
        if (back) {
          warehouse.back.itemKeys = [...warehouse.back.itemKeys, key];
          warehouse.back.itemByKey = {
            ...warehouse.back.itemByKey,
            [key]: {
              ...item,
              ...initCountForm,
              is_first: 1,
              is_default: 1,
              is_primary_set: 0,
              total_warehouse: back,
              difference: -back,
              key,
            },
          };
        }
        if (distribute) {
          warehouse.distribute.itemKeys = [
            ...warehouse.distribute.itemKeys,
            key,
          ];
          warehouse.distribute.itemByKey = {
            ...warehouse.distribute.itemByKey,
            [key]: {
              ...item,
              ...initCountForm,
              is_first: 1,
              is_default: 1,
              is_primary_set: 0,
              total_warehouse: distribute,
              difference: -distribute,
              key,
            },
          };
        }
        if (broken) {
          warehouse.broken.itemKeys = [...warehouse.broken.itemKeys, key];
          warehouse.broken.itemByKey = {
            ...warehouse.broken.itemByKey,
            [key]: {
              ...item,
              ...initCountForm,
              is_first: 1,
              is_default: 1,
              is_primary_set: 0,
              total_warehouse: broken,
              difference: -broken,
              key,
            },
          };
        }
      });
      state.primary_set.itemKeys = warehouse.primary_set.itemKeys;
      state.primary_set.itemByKey = warehouse.primary_set.itemByKey;
      state.primary.itemKeys = warehouse.primary.itemKeys;
      state.primary.itemByKey = warehouse.primary.itemByKey;
      state.back.itemKeys = warehouse.back.itemKeys;
      state.back.itemByKey = warehouse.back.itemByKey;
      state.distribute.itemKeys = warehouse.distribute.itemKeys;
      state.distribute.itemByKey = warehouse.distribute.itemByKey;
      state.broken.itemKeys = warehouse.broken.itemKeys;
      state.broken.itemByKey = warehouse.broken.itemByKey;
      state.document.document_date = new Date();
      state.stock.itemKeys = itemKeys;
      state.stock.itemByKey = itemByKey;
      state.fetch.stock = { ...initFetch };
    },
    [getInventoryCountStock.rejected]: (state, { payload }) => {
      state.fetch.stock = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
    [getInventoryCountByDocumentNo.pending]: (state) => {
      state.fetch.document = {
        ...initFetch,
        loading: true,
      };
    },
    [getInventoryCountByDocumentNo.fulfilled]: (state, { payload }) => {
      // notfound
      if (payload.data === null) {
        state.isNotFound = true;
        return;
      }

      const { document, stock, product, media, remark } = payload.data;
      console.log(remark);
      // split all product with  warehouse
      let pf = {
        primary_set: [],
        primary: [],
        back: [],
        distribute: [],
        broken: [],
      };
      _.forEach(product, (item) => {
        const { warehouse_type, ...data } = item;
        pf[warehouse_type] = [
          ...pf[warehouse_type],
          {
            ...data,
            total_count:
              data.shelf + data.storage_shelf + data.warehouse + data.count,
          },
        ];
      });

      // map all data to itemKeys
      let warehouse = {
        primary_set: {
          itemKeys: [],
          itemByKey: {},
        },
        primary: {
          itemKeys: [],
          itemByKey: {},
        },
        back: {
          itemKeys: [],
          itemByKey: {},
        },
        distribute: {
          itemKeys: [],
          itemByKey: {},
        },
        broken: {
          itemKeys: [],
          itemByKey: {},
        },
      };
      _.forEach(stock, (stock_item) => {
        const {
          product_id,
          product_code,
          main,
          arrange,
          reserve,
          back,
          distribute,
          broken,
          product_type_set,
          requisition,
        } = stock_item;
        const key = _.uniqueId(`${product_code}-`);
        if (main || arrange || reserve) {
          const total_warehouse = main + reserve + requisition; // #923 - ตัด arrange (ค้างจ่าย) ออก
          // const total_warehouse = main + arrange + reserve;
          if (product_type_set === fixProductTypeSet.kit_pack) {
            const main_data = _.find(
              pf.primary_set,
              (n) => n.is_first && n.product_code === product_code
            ) || {
              total_count: 0,
              is_default: 1,
              is_first: 1,
              is_primary_set: 0,
            };
            const other_data = _.filter(
              pf.primary_set,
              (n) => !n.is_first && n.product_code === stock_item.product_code
            );

            // main data
            const total_all_count =
              main_data.total_count +
              _.reduce(other_data, (res, n) => res + n.total_count, 0);
            warehouse.primary_set.itemKeys = [
              ...warehouse.primary_set.itemKeys,
              key,
            ];
            warehouse.primary_set.itemByKey = {
              ...warehouse.primary_set.itemByKey,
              [key]: {
                ...initCountForm,
                ...stock_item,
                ...main_data,
                total_warehouse,
                total_all_count,
                difference: total_all_count - total_warehouse,
                key,
              },
            };

            // other data
            _.forEach(other_data, (n) => {
              const key = _.uniqueId(`${n.product_code}-`);
              warehouse.primary_set.itemKeys = [
                ...warehouse.primary_set.itemKeys,
                key,
              ];
              warehouse.primary_set.itemByKey = {
                ...warehouse.primary_set.itemByKey,
                [key]: {
                  ...initCountForm,
                  ...n,
                  key,
                  product_id,
                  product_code,
                },
              };
            });
          } else {
            const main_data = _.find(
              pf.primary,
              (n) => n.is_first && n.product_code === product_code
            ) || {
              total_count: 0,
              is_default: 1,
              is_first: 1,
              is_primary_set: 0,
            };
            const other_data = _.filter(
              pf.primary,
              (n) => !n.is_first && n.product_code === product_code
            );

            // main data
            const total_all_count =
              main_data.total_count +
              _.reduce(other_data, (res, n) => res + n.total_count, 0);
            warehouse.primary.itemKeys = [...warehouse.primary.itemKeys, key];
            warehouse.primary.itemByKey = {
              ...warehouse.primary.itemByKey,
              [key]: {
                ...initCountForm,
                ...stock_item,
                ...main_data,
                total_warehouse,
                total_all_count,
                difference: total_all_count - total_warehouse,
                key,
              },
            };

            // other data
            _.forEach(other_data, (n) => {
              const key = _.uniqueId(`${n.product_code}-`);
              warehouse.primary.itemKeys = [...warehouse.primary.itemKeys, key];
              warehouse.primary.itemByKey = {
                ...warehouse.primary.itemByKey,
                [key]: {
                  ...initCountForm,
                  ...n,
                  key,
                  product_id,
                  product_code,
                },
              };
            });

            // case once from primary set
            if (main_data.is_primary_set === 1 && other_data.length === 0) {
              const key = _.uniqueId(`${product_code}-`);
              warehouse.primary.itemKeys = [...warehouse.primary.itemKeys, key];
              warehouse.primary.itemByKey = {
                ...warehouse.primary.itemByKey,
                [key]: {
                  ...initCountForm,
                  is_default: 1,
                  is_first: 0,
                  is_primary_set: 0,
                  key,
                  product_id,
                  product_code,
                },
              };
            }
          }
        }
        if (back) {
          const main_data = _.find(
            pf.back,
            (n) => n.is_first && n.product_code === product_code
          ) || {
            total_count: 0,
            is_default: 1,
            is_first: 1,
            is_primary_set: 0,
          };
          const other_data = _.filter(
            pf.back,
            (n) => !n.is_first && n.product_code === product_code
          );

          // main data
          const total_warehouse = back;
          const total_all_count =
            main_data.total_count +
            _.reduce(other_data, (res, n) => res + n.total_count, 0);
          warehouse.back.itemKeys = [...warehouse.back.itemKeys, key];
          warehouse.back.itemByKey = {
            ...warehouse.back.itemByKey,
            [key]: {
              ...initCountForm,
              ...stock_item,
              ...main_data,
              total_warehouse,
              total_all_count,
              difference: total_all_count - total_warehouse,
              key,
            },
          };

          // other data
          _.forEach(other_data, (n) => {
            const key = _.uniqueId(`${n.product_code}-`);
            warehouse.back.itemKeys = [...warehouse.back.itemKeys, key];
            warehouse.back.itemByKey = {
              ...warehouse.back.itemByKey,
              [key]: {
                ...initCountForm,
                ...n,
                key,
                product_id,
                product_code,
              },
            };
          });
        }
        if (distribute) {
          const main_data = _.find(
            pf.distribute,
            (n) => n.is_first && n.product_code === product_code
          ) || {
            total_count: 0,
            is_default: 1,
            is_first: 1,
            is_primary_set: 0,
          };
          const other_data = _.filter(
            pf.distribute,
            (n) => !n.is_first && n.product_code === product_code
          );

          // main data
          const total_warehouse = distribute;
          const total_all_count =
            main_data.total_count +
            _.reduce(other_data, (res, n) => res + n.total_count, 0);
          warehouse.distribute.itemKeys = [
            ...warehouse.distribute.itemKeys,
            key,
          ];
          warehouse.distribute.itemByKey = {
            ...warehouse.distribute.itemByKey,
            [key]: {
              ...initCountForm,
              ...stock_item,
              ...main_data,
              total_warehouse,
              total_all_count,
              difference: total_all_count - total_warehouse,
              key,
            },
          };

          // other data
          _.forEach(other_data, (n) => {
            const key = _.uniqueId(`${n.product_code}-`);
            warehouse.distribute.itemKeys = [
              ...warehouse.distribute.itemKeys,
              key,
            ];
            warehouse.distribute.itemByKey = {
              ...warehouse.distribute.itemByKey,
              [key]: {
                ...initCountForm,
                ...n,
                key,
                product_id,
                product_code,
              },
            };
          });
        }
        if (broken) {
          const main_data = _.find(
            pf.broken,
            (n) => n.is_first && n.product_code === product_code
          ) || {
            total_count: 0,
            is_default: 1,
            is_first: 1,
            is_primary_set: 0,
          };
          const other_data = _.filter(
            pf.broken,
            (n) => !n.is_first && n.product_code === product_code
          );

          // main data
          const total_warehouse = broken;
          const total_all_count =
            main_data.total_count +
            _.reduce(other_data, (res, n) => res + n.total_count, 0);
          warehouse.broken.itemKeys = [...warehouse.broken.itemKeys, key];
          warehouse.broken.itemByKey = {
            ...warehouse.broken.itemByKey,
            [key]: {
              ...initCountForm,
              ...stock_item,
              ...main_data,
              total_warehouse,
              total_all_count,
              difference: total_all_count - total_warehouse,
              key,
            },
          };

          // other data
          _.forEach(other_data, (n) => {
            const key = _.uniqueId(`${n.product_code}-`);
            warehouse.broken.itemKeys = [...warehouse.broken.itemKeys, key];
            warehouse.broken.itemByKey = {
              ...warehouse.broken.itemByKey,
              [key]: {
                ...initCountForm,
                ...n,
                key,
                product_id,
                product_code,
              },
            };
          });
        }
      });

      // media
      let media_item = {
        itemKeys: [],
        itemByKey: {},
      };
      _.forEach(media, (item) => {
        const key = _.uniqueId("document-img-");
        media_item.itemKeys = [...media_item.itemKeys, key];
        media_item.itemByKey = { ...media_item.itemByKey, [key]: { ...item } };
      });

      _.forEach(remark, (item) => {
        state.remark = {
          ... state.remark,
          [item.warehouse_type] : {...item},
        };
      });

      state.primary_set = {
        ..._.cloneDeep(initialState.primary_set),
        ...warehouse.primary_set,
      };
      state.primary = {
        ..._.cloneDeep(initialState.primary),
        ...warehouse.primary,
      };
      state.back = {
        ..._.cloneDeep(initialState.back),
        ...warehouse.back,
      };
      state.distribute = {
        ..._.cloneDeep(initialState.distribute),
        ...warehouse.distribute,
      };
      state.broken = {
        ..._.cloneDeep(initialState.broken),
        ...warehouse.broken,
      };
      state.media = {
        ..._.cloneDeep(initialState.media),
        ...media_item,
      };
     

      state.warehouse.itemByKey.primary_set.status =
        document.primary_set_status;
      state.warehouse.itemByKey.primary.status = document.primary_status;
      state.warehouse.itemByKey.back.status = document.back_status;
      state.warehouse.itemByKey.distribute.status = document.distribute_status;
      state.warehouse.itemByKey.broken.status = document.broken_status;

      state.document.document_no = document.document_no;
      state.document.document_date = document.document_date;
      state.document.document_status = document.status;
      state.document.document_remark = document.remark;

      if (!payload.is_export) {
        state.searchDocument.branch_code = document.branch_code;
        state.searchDocument.branch_name = document.branch_name;
      }

      state.fetch.document = { ...initFetch };
    },
    [getInventoryCountByDocumentNo.rejected]: (state, { payload }) => {
      state.fetch.document = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
  },
});

export const actionInventoryCount = inventoryCountSlice.actions;

const InventoryCountReducer = inventoryCountSlice.reducer;
export default InventoryCountReducer;
