import { Col, Empty, Modal, Row, Typography } from "antd";
import parse from "html-react-parser";
import React, { memo } from "react";
import ImgError from "../../../../../components/ImgError";

const PromotionDetailModal = ({ onCancel, dataSource = {} }) => {
  const { pro_id, name, description, azure_url } = dataSource;
  return (
    <Modal
      visible={!!pro_id}
      destroyOnClose
      footer={false}
      zIndex={1080}
      width={900}
      onCancel={onCancel}
    >
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>รายละเอียดโปรโมชัน</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>{name}</Typography.Text>
        </Col>
      </Row>
      <Row type="flex" className="mt-3 align-items-center">
        <Col span={6} className="d-flex align-items-center border-right">
          <ImgError src={azure_url} alt="promotion" className="w-100" />
        </Col>
        <Col span={18} className="px-3">
          <div>{description ? parse(description) : <Empty />}</div>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(PromotionDetailModal);
