import { Alert } from "antd";
import React, { memo } from "react";
import SelectOption from "../../../../components/SelectOption";
import { useHttp } from "../../../../hooks/http";
import { URL_API, _ } from "../../../../utils";

const SelectWarehouse = ({ userLogin, ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/global/master/warehouse`,
      params: { user_login: userLogin ? "1" : null },
      token: true,
    },
    [userLogin]
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    // <Select
    //   loading={loading}
    //   className={`w-100 ${className || ""}`}
    //   dropdownStyle={{ zIndex: 1080 }}
    //   {...props}
    // >
    //   {withAll && <Option value="">ทั้งหมด</Option>}
    //   {_.map(data?.data, (n) => (
    //     <Option key={n.code} value={n.code} source={n}>
    //       {n.name}
    //     </Option>
    //   ))}
    // </Select>
    <SelectOption
      loading={loading}
      data={_.map(data?.data || [], (n) => ({
        ...n,
        value: n.code,
        text: n.name,
      }))}
      {...props}
    />
  );
};

export default memo(SelectWarehouse);
