/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API, _ } from "../../../../utils";
import SelectOption from "../../pos/components/SelectOption";


//---------------------------------Function => return data ( HonorFilter(); )
export const HonorFilter = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/honor-filter`,
      token: true,
      params: {},
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data.data : []);
  }, [data]);

  const filter = [
    ..._.map(dataSource, (data) => {
      return {
        honor_index: data.honor_index,
        honor_code: data.honor_code,
        honor_name: data.honor_name,
      };
    }),
  ];
  return filter;
};

//--------------------------------Selection Honor ::
export const SelectHonor = ({
  withAll = false,
  placeholder = "",
  ...props
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/honor-filter`,
      token: true,
      params: {},
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data.data : []);
  }, [data]);

  const honorOptions = [
    ..._.map(dataSource, (data) => {
      return {
        value: data.honor_index,
        text: data.honor_code,
        honor_name: data.honor_name,
      };
    }),
  ];

  return (
    <SelectOption
      withAll={withAll}
      className="w-100"
      defaultValue=""
      placeholder={placeholder}
      options={honorOptions}
      {...props}
    />
  );
};
