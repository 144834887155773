/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp, FaCogs, FaPlus } from "react-icons/fa";
import { useHttp } from "../../../../../../hooks/http";
import { URL_API, _ } from "../../../../../../utils";
import { PromotionContext } from "../reducers";

const limit = 10;
const ModalProduct = () => {
  const {
    state: {
      modal: {
        product: { visible, active_by },
      },
    },
    dispatch,
  } = useContext(PromotionContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [productType, setProductType] = useState("item");
  const [dataSource, setDataSource] = useState([]);
  const [loading, data, error] = useHttp(
    {
      method: "get",
      url: `${URL_API}/system/products`,
      params: {
        productType,
        limit,
        offset: (page - 1) * limit,
        search,
      },
      token: true,
    },
    [page, productType, search]
  );

  useEffect(() => {
    if (data?.data) {
      setDataSource(data.data);
    }
  }, [data]);

  const handleChangeProductType = (value) => {
    setProductType(value);
    setPage(1);
  };

  const onCancel = () => {
    setPage(1);
    setSearch("");
    setProductType("item");
    dispatch({
      type: "SET_MODAL_VISIBLE",
      payload: { visible: false, name: "product" },
    });
  };

  return (
    <Modal
      visible={visible}
      title="เลือกสินค้า"
      onCancel={onCancel}
      footer={false}
      width={800}
      zIndex={1080}
      destroyOnClose={true}
    >
      <Row gutter={16} className="mb-2">
        <Col span={active_by === "condition" ? 18 : 24}>
          <Input.Search
            placeholder="รหัส/ชื่อสินค้า"
            onSearch={(value) => {
              setPage(1);
              setSearch(value.trim());
            }}
            allowClear
          />
        </Col>
        {active_by === "condition" && (
          <Col span={6}>
            <Select
              className="w-100"
              value={productType}
              onChange={handleChangeProductType}
              dropdownStyle={{ zIndex: 1080 }}
            >
              <Select.Option value="item">สินค้าปกติ</Select.Option>
              <Select.Option value="category">กลุ่มสินค้า</Select.Option>
            </Select>
          </Col>
        )}
      </Row>
      {error ? (
        <Alert type="error" />
      ) : (
        <Table
          size="small"
          loading={loading}
          dataSource={dataSource}
          rowKey="row_id"
          pagination={{
            current: page,
            size: "default",
            pageSize: limit,
            showSizeChanger: false,
            showLessItems: true,
            onChange: (curr) => setPage(curr),
            total: data?.total || 0,
          }}
        >
          <Table.Column
            className="position-relative"
            title={<div className="ant-table-absolute-title-center">ลำดับ</div>}
            width={70}
            dataIndex="row_id"
            align="right"
          />
          <Table.Column
            className="position-relative"
            width={100}
            title={<div className="ant-table-absolute-title-center">รหัส</div>}
            dataIndex={productType === "item" ? "product_code" : "code"}
          />
          {productType === "item" ? (
            <Table.Column
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ชื่อสินค้า
                </div>
              }
              dataIndex="product_name_local"
            />
          ) : (
            <Table.Column
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ชื่อกลุ่มสินค้า
                </div>
              }
              render={({ name, source, visible, id }) => (
                <>
                  <Row>
                    <Col
                      span={24}
                      className="cursor-pointer"
                      onClick={() =>
                        setDataSource((prevState) =>
                          _.map(prevState, (n) => ({
                            ...n,
                            visible: n.id === id ? !n.visible : n.visible,
                          }))
                        )
                      }
                    >
                      <Typography.Text className="mr-2">{name}</Typography.Text>
                      {visible ? <FaAngleUp /> : <FaAngleDown />}
                    </Col>
                  </Row>
                  {visible &&
                    _.map(source, (n, i) => (
                      <Row key={i}>
                        <Col className="ml-3">
                          <Typography.Text className="mr-3">
                            {n.product_code}
                          </Typography.Text>
                          <Typography.Text>
                            {n.product_name_local}
                          </Typography.Text>
                        </Col>
                      </Row>
                    ))}
                </>
              )}
            />
          )}
          <Table.Column
            title={<FaCogs />}
            align="center"
            width={60}
            render={(item, _record, index) => (
              <Button
                id={`product-${index}`}
                type="primary"
                ghost
                size="small"
                onClick={() =>
                  dispatch({
                    type: "ADD_PROMOTION_DATA_ITEM",
                    payload: {
                      ...item,
                      unit_price: 0,
                      item_type: productType,
                      modal_name: "product",
                    },
                  })
                }
              >
                <FaPlus />
              </Button>
            )}
          />
        </Table>
      )}
    </Modal>
  );
};

export default ModalProduct;
