import React, { Component } from 'react'

export class Checkbox extends Component {
    render() {
        const {
            id = 'checkbox',
            name = 'checkbox',
            value='',
            label = '',
            checked = false,
            disabled=false,
            validate,
            inline,
            className,
            onChange,
        } = this.props;
        return (
            <>
                <div className={`${className}  custom-control custom-checkbox ${(inline) ? 'custom-control-inline' : ''} mb-1`}>
                    <input
                        type="checkbox"
                        id={id}
                        name={name}
                        value={value}
                        checked={checked}
                        disabled={disabled}
                        onChange={(e) => onChange(e)}
                        className={`custom-control-input ${(validate) ? 'is-invalid' : ''}`}
                    />
                    <label className="custom-control-label" htmlFor={id}>{label}</label>
                    <div className="invalid-feedback">{validate}</div>
                </div>
            </>

        )
    }
}

export default Checkbox
