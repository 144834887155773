/* eslint-disable no-unused-vars */
import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectAddressMaster from "../../../../components/SelectAddressMaster";
import { _ } from "../../../../utils";
import { Creators } from "../ducks/operation-form";

const initialState = {
  ship_to: "",
  ship_mobile: "",
  ship_address_no: "",
  ship_province_id: null,
  ship_province: "",
  ship_district_id: null,
  ship_district: "",
  ship_sub_district_id: null,
  ship_sub_district: "",
  ship_post_code: "",
};

const ModalAddress = ({
  visible = true,
  form = {},
  setShowAddAddress = () => {},
  ...props
}) => {
  const [state, setState] = useState(initialState);
  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    resetFields,
    setFieldsValue,
  } = form;
  const {
    ship_to,
    ship_mobile,
    ship_address_no,
    ship_province_id,
    ship_district_id,
    ship_sub_district_id,
    ship_post_code,
  } = state;

  return (
    <Modal
      visible={visible}
      title="ระบุที่อยู่จัดส่ง"
      onOk={() => {
        validateFieldsAndScroll((err) => {
          if (!err) {
            props.setBillValue(state);
            setShowAddAddress(false);
          }
        });
      }}
      onCancel={() => {
        props.setBillValue({
          ship_to_id: null,
        });
        setShowAddAddress(false);
        setState(initialState);
        resetFields();
      }}
    >
      <Row>
        <Col>
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="ชื่อผู้รับ" className="mb-0">
              {getFieldDecorator("ship_to", {
                initialValue: ship_to,
                onChange: (e) =>
                  setState({ ...state, ship_to: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกชื่อผู้รับ",
                  },
                ],
                normalize : (value) => {
                  const regex = /[^\u0E00-\u0E7Fa-zA-Z\s]|[\u0E3F\u0E4D\u0E3A]/g;
                  const normalizedValue = value.replace(regex, ""); // Replace any character that is not allowed
                  return normalizedValue;
                },
              })(<Input maxLength={255} />)}
            </Form.Item>
            <Form.Item label="เบอร์โทรศัพท์" className="mb-0">
              {getFieldDecorator("ship_mobile", {
                initialValue: ship_mobile,
                onChange: (e) =>
                  setState({ ...state, ship_mobile: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกเบอร์โทรศัพท์",
                  },
                  {
                    pattern:
                      /^[+]?[(]?[0-9]{2,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/,
                    message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="ที่อยู่" className="mb-0">
              {getFieldDecorator("ship_address_no", {
                initialValue: ship_address_no,
                onChange: (e) =>
                  setState({ ...state, ship_address_no: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกที่อยู่",
                  },
                ],
                normalize : (value) => {
                  const validCharsRegex = /[\u0E00-\u0E7Fa-zA-Z0-9\s\.\-\/]/g;
                  let sanitizedValue = value.match(validCharsRegex)?.join("") || "";
                  sanitizedValue = sanitizedValue.replace(/฿/g, ""); // Remove ฿
                  sanitizedValue = sanitizedValue.replace(/ํ/g, ""); // Remove นฤคหิต ( " ํ ")
                  sanitizedValue = sanitizedValue.replace(/ฺ/g, ""); // Remove พินทุ ( " ฺ ")
                  return sanitizedValue;
                },
              })(<Input.TextArea rows={3} maxLength={500} />)}
            </Form.Item>
            <Form.Item label="จังหวัด" className="mb-0">
              {getFieldDecorator("ship_province_id", {
                initialValue: ship_province_id,
                onChange: (e, option) => {
                  setFieldsValue({
                    ship_district_id: null,
                    ship_sub_district_id: null,
                    ship_post_code: "",
                  });
                  setState({
                    ...state,
                    ship_province_id: e,
                    ship_province: option.props.source.name,
                    ship_district_id: null,
                    ship_sub_district_id: null,
                    ship_post_code: "",
                  });
                },
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกจังหวัด",
                  },
                ],
              })(<SelectAddressMaster className="w-100" type="province" />)}
            </Form.Item>
            <Form.Item label="อำเภอ" className="mb-0">
              {getFieldDecorator("ship_district_id", {
                initialValue: ship_district_id,
                onChange: (e, option) => {
                  setFieldsValue({
                    ship_district_id: null,
                    ship_sub_district_id: null,
                    ship_post_code: "",
                  });
                  setState({
                    ...state,
                    ship_district_id: e,
                    ship_district: option.props.source.name,
                    ship_sub_district_id: null,
                    ship_post_code: "",
                  });
                },
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกอำเภอ",
                  },
                ],
              })(
                <SelectAddressMaster
                  className="w-100"
                  type="district"
                  province_id={state.ship_province_id}
                />
              )}
            </Form.Item>
            <Form.Item label="ตำบล" className="mb-0">
              {getFieldDecorator("ship_sub_district_id", {
                initialValue: ship_sub_district_id,
                onChange: (e, option) => {
                  setFieldsValue({
                    ship_post_code: option.props.source.post_code,
                  });
                  setState({
                    ...state,
                    ship_sub_district_id: e,
                    ship_sub_district: option.props.source.name,
                    ship_post_code: option.props.source.post_code,
                  });
                },
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกตำบล",
                  },
                ],
              })(
                <SelectAddressMaster
                  className="w-100"
                  type="subdistrict"
                  district_id={state.ship_district_id}
                />
              )}
            </Form.Item>
            <Form.Item label="รหัสไปรษณีย์" className="mb-0">
              {getFieldDecorator("ship_post_code", {
                initialValue: ship_post_code,
                value: ship_post_code,
                readOnly: true,
                disabled: true,
                onChange: (e) =>
                  setState({ ...state, ship_post_code: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกรหัสไปรษณีย์",
                  },
                  {
                    pattern: /^[0-9]{5}?$/,
                    message: "รูปแบบไม่ถูกต้อง",
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

function mapStateToProps(state) {
  const orders = state.pos.operation.orders;
  const { activeKey, itemByKey } = orders;
  return {
    total_weight:
      itemByKey[activeKey] &&
      itemByKey[activeKey].items &&
      _.reduce(
        itemByKey[activeKey].items.itemByKey,
        (sum, n) => (sum = sum + (n.weight || 0) * (n.qty || 0)),
        0
      ),
    total_pv:
      itemByKey[activeKey] &&
      itemByKey[activeKey].items &&
      _.reduce(
        itemByKey[activeKey].items.itemByKey,
        (sum, n) => (sum = sum + (n.pv || 0) * (n.qty || 0)),
        0
      ),
  };
}

export default Form.create()(
  connect(mapStateToProps, { setBillValue: Creators.setBillValue })(
    ModalAddress
  )
);
