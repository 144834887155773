/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { memo, useEffect, useRef, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BiImport } from "react-icons/bi";
import { fixProductTypeSet } from "../../config/fix-value";
import styles from "../../scss/module/pos.module.scss";
import { _, axios, URL_API } from "../../utils";
import { resCatch } from "../../views/util/helper";
import * as XLSX from "xlsx-js-style";
import BomDetail from "./BomDetail";
import { dummyRequest } from "../../views/pages/manage-website/components/dummyRequest";

const { Text } = Typography;

/*
  deleteItemBom => ({ item_key = "", bom_qr = [] })
  setPromotionValue => ({
    promotion_id = 0,
    source_option = "",
    product_code = "",
    value = {},
  })
  setItemValue => ({ key = "", value = {} }),
  setItemValueBom => ({
    item_key = "",
    product_code = "",
    barcode = "",
    qr = {
      ref_product_code: null,
      ref_store_qr_id: null,
      ref_store_qr_type: null,
      qr_running: null,
    },
  })
*/

/*
  documentType is used in case arrange (bill or requisition)
*/

const Index = ({
  disabled = null,
  product = null,
  partnerType = null,
  salesType = null,
  documentType = null,
  receipt_branch_code = null,
  validateUsed = () => null,
  onCancel = () => null,
  deleteItemBom = () => null,
  setPromotionValue = () => null,
  setItemValue = () => null,
  setItemValueBom = () => null,
}) => {
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    product && handleChangeItem();
  }, [product]);

  const renderWarning = (description) => {
    setLoading(false);
    notification.warning({
      message: "ไม่สามารถดำเนินการได้",
      description,
    });
  };
  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const {
        key,
        promotion_id,
        source_option,
        product_code,
        qr: curr_qr,
      } = product;
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(worksheet, {
        header: ["number", "code"],
      });
      const slicedData = sheetData.slice(1).filter((item) => item.code);
      if (product.product_type_set === fixProductTypeSet.kit_pack) {
        const sumProductBom =
          product.bom.reduce(
            (accumulator, currentValue) => accumulator + currentValue.qty,
            0
          ) * product.qty_balance;
        if (slicedData.length > sumProductBom) {
          return renderWarning("จำนวน QR ที่ import เกินจำนวนสินค้า");
        }
      } else {
        if (slicedData.length > product.qty_balance) {
          return renderWarning("จำนวน QR ที่ import เกินจำนวนสินค้า");
        }
      }
      setLoading(true);
      let res = null;
      try {
        res = await axios({
          method: "post",
          url: `${URL_API}/pos/qr`,
          data: {
            partner_type: partnerType,
            sales_type: salesType,
            document_type: documentType,
            qr: slicedData,
            branch: receipt_branch_code,
          },
        });
      } catch (error) {
        setLoading(false);
        return resCatch(error);
      }
      const { data: data_qr } = res.data;
      const filter_warn = data_qr
        .filter((item) => item.warn !== null) // กรองเฉพาะที่ warn ไม่เป็น null
        .map((item) => item.warn) // ดึงข้อความจาก warn
        .join("<br>"); // เชื่อมข้อความด้วย <br>
      const check_qr_same = new Set(data_qr.qr_running).size !== data_qr.length;
      console.log(check_qr_same);
      if (filter_warn) {
        Modal.error({
          title: "ไม่สามารถดำเนินการได้",
          content: <div dangerouslySetInnerHTML={{ __html: filter_warn }} />,
          zIndex: 1080,
        });
        setLoading(false);
        return;
      }
      let res_qr = [];
      for (const item of data_qr) {
        if (product.product_type_set === fixProductTypeSet.kit_pack) {
          setItemValueBom({
            product_code: item.data.product_code,
            barcode: item.barcode,
            qr: {
              ref_product_code: item.data.product_code,
              ref_store_qr_id: null,
              ref_store_qr_type: null,
              qr_running: item.qr_running,
            },
            item_key: key,
          });
        } else {
          if (!item.data.product_code)
            return renderWarning("ไม่พบข้อมูลสินค้า");
          if (item.data.product_code !== product.product_code)
            return renderWarning("สินค้าที่เลือกไม่ถูกต้อง");
          res_qr = [
            ...res_qr,
            {
              ref_product_code: null,
              ref_store_qr_id: null,
              ref_store_qr_type: null,
              qr_running: item.qr_running,
            },
          ];
        }
      }
      if (product.product_type_set !== fixProductTypeSet.kit_pack) {
        setItemValue({
          key,
          value: {
            qr: res_qr,
            barcode: data_qr[0].barcode,
            qr_warning: false,
          },
        });
      }
      setLoading(false);
    };
    reader.onerror = (error) => {
      console.error("FileReader error: ", error);
    };
    reader.readAsArrayBuffer(file);
  };
  const handleChangeItem = () => {
    const { qr = [], qty, product_name, barcode, bom } = product;
    let data_filter = [];
    let countItem = {};
    for (let i = 0; i < qty; i++) {
      let bom_filter = [];
      for (let j = 0; j < bom.length; j++) {
        const {
          ref_product_code,
          ref_product_name,
          qr: bom_qr = [],
          barcode: bom_barcode,
          qty: bom_qty,
        } = bom[j];
        for (let k = 0; k < bom_qty; k++) {
          let check_qr = _.isObject(bom_qr[countItem[ref_product_code] || 0]);
          bom_filter = [
            ...bom_filter,

            {
              ref_product_code,
              line_number: countItem[ref_product_code] || 0,
              ref_product_name,
              barcode: bom_barcode,
              qr_running: check_qr
                ? bom_qr[countItem[ref_product_code] || 0]?.qr_running ||
                  "No Running"
                : bom_qr[countItem[ref_product_code] || 0] == ""
                ? "No Running"
                : bom_qr[countItem[ref_product_code] || 0],
            },
          ];
          countItem = {
            ...countItem,
            [ref_product_code]: (countItem[ref_product_code] || 0) + 1,
          };
        }
      }
      data_filter = [
        ...data_filter,
        {
          bom: bom_filter,
          product_name,
          barcode,
          qr_running: qr[i]?.qr_running || null,
        },
      ];
    }

    setDataSource(data_filter);
  };

  const handleDelete = (qr_running, bom, i, n) => {
    const { key, promotion_id, source_option, product_code, qty } = product;
    let qr = _.cloneDeep(product.qr);
    const index = i; //_.findIndex(qr, (n) => n.qr_running === qr_running);

    // only phase one check matching
    if (qr && qr[index]) qr.splice(index, 1);

    const bom_i = _.map(bom, (n) => n.line_number);
    const bom_product = _.uniq(_.map(bom, (n) => n.ref_product_code));
    if (bom_i.length > 0 && bom_product.length > 0) {
      deleteItemBom({ item_key: key, bom_i, bom_product });
    }

    if (product.promotion_id) {
      setPromotionValue({
        promotion_id,
        source_option,
        product_code,
        data: { qr },
      });
    } else {
      const res_qty = qty; //- 1;
      setItemValue({ key, value: { qr, qty: res_qty } });
    }
  };

  const handleDelSomeBom = (i, bom_i, running, bom_product) => {
    const { key, promotion_id, source_option, product_code, qty } = product;
    let qr = _.cloneDeep(product.qr);

    const bom_qr = [running];
    const bom_index = [bom_i];
    if (bom_qr.length > 0) {
      deleteItemBom({ item_key: key, bom_qr, bom_i: bom_index, bom_product });
    }

    if (product.promotion_id) {
      setPromotionValue({
        promotion_id,
        source_option,
        product_code,
        data: { qr },
      });
    } else {
      const res_qty = qty; //- 1;
      // console.log(qr)
      // setItemValueBomQr({ item_key: key, value: { qr, qty: res_qty } });
      setItemValueBom({
        item_key: key,
        product_code: bom_product,
        // qr:qr,
      });
      // setItemValue({ key, value: { qr, qty: res_qty } });
    }
  };

  const handleAddQr = async (qr_running, barcode, data) => {
    const {
      key,
      promotion_id,
      source_option,
      product_code,
      qr: curr_qr = [],
    } = product;

    if (product.product_type_set === fixProductTypeSet.kit_pack) {
      const { bom } = data;
      for (let i = 0; i < bom.length; i++) {
        const { product_code, barcode, qr_running } = bom[i];
        setItemValueBom({
          product_code,
          barcode,
          qr: {
            ref_product_code: data.main_product.product_code,
            ref_store_qr_id: data.main_product.id,
            ref_store_qr_type: "sub",
            qr_running,
          },
          item_key: product.key,
        });
      }
      if (promotion_id) {
        setPromotionValue({
          promotion_id,
          source_option,
          product_code,
          data: {
            qr: [
              ...curr_qr,
              {
                ref_product_code: null,
                ref_store_qr_id: data.main_product.id,
                ref_store_qr_type: "main",
                qr_running: data.main_product.main_running,
              },
            ],
            barcode: data.main_product.barcode,
          },
        });
      } else {
        setItemValue({
          key,
          value: {
            qr: [
              ...curr_qr,
              {
                ref_product_code: null,
                ref_store_qr_id: data.main_product.id,
                ref_store_qr_type: "main",
                qr_running: data.main_product.main_running,
              },
            ],
            barcode: data.main_product.barcode,
            qr_warning: false,
          },
        });
      }
    } else {
      const res_qr = [
        ...curr_qr,
        {
          ref_product_code: null,
          ref_store_qr_id: null,
          ref_store_qr_type: null,
          qr_running,
        },
      ];
      if (product.promotion_id) {
        setPromotionValue({
          promotion_id,
          source_option,
          product_code,
          data: {
            qr: res_qr,
            barcode,
            qr_warning: false,
          },
        });
      } else {
        setItemValue({
          key,
          value: {
            qr: res_qr,
            barcode,
            qr_warning: false,
          },
        });
      }
    }

    searchRef.current.input.state.value = "";
    searchRef.current.focus();
    setLoading(false);
  };

  const handleSearch = async (code) => {
    if (!code) return;
    const isPhaseOne = true;
    const qr_running = code.split("LgcSN=")[1]?.split("&")[0];
    const barcode = code.split("LgcSKU=")[1]?.split("&")[0];
    // if (qr_running == null) return renderWarning("ไม่พบข้อมูล qr running");
    // if (product.qty_balance === product.qr?.length) {
    if(product.qty === product.qr?.length) {
      return renderWarning("จำนวนสินค้าที่เลือกครบแล้ว");
    }
    const qr_used = validateUsed(qr_running);
    if (qr_used) {
      return renderWarning("qr running ถูกใช้งานในรายการสั่งซื้อแล้ว");
    }
    setLoading(true);

    let res = null;
    try {
      res = await axios({
        url: `${URL_API}/pos/qr`,
        params: {
          qr_running,
          barcode: barcode || code,
          partner_type: partnerType,
          sales_type: salesType,
          document_type: documentType,
          qr: barcode ? code : null,
          branch: receipt_branch_code,
        },
      });
    } catch (e) {
      setLoading(false);
      return resCatch(e);
    }

    const {
      warn,
      // failed,
      data,
    } = res.data;
    if (product.product_type_set === "Kit/Package" && data) {
      const index = _.find(
        product.bom,
        (n) => n.ref_product_code == data.product_code
      );
      if (index?.qty * product.qty_balance <= index?.qr?.length)
        return renderWarning("จำนวนสินค้าที่เลือกครบแล้ว");
    }
    // only phase one
    if (isPhaseOne) {
      let phase_one_add_some_sub_item = null;
      if (!data.product_code) return renderWarning("ไม่พบข้อมูลสินค้า");
      if (data.product_code !== product.product_code) {
        if (product.product_type_set !== fixProductTypeSet.kit_pack)
          return renderWarning("สินค้าที่เลือกไม่ถูกต้อง");
        phase_one_add_some_sub_item = true;
      }
      // if (failed.qr) return renderWarning("qr running ถูกใช้งานแล้ว");
      // if (failed.product) return renderWarning("พบสินค้าหลายรายการ");
      // if (failed.store_qr) {
      //   return renderWarning("ไม่พบข้อมูล QR Code !");
      // }

      // if (!data.product_code) return renderWarning("ไม่พบข้อมูลสินค้า");
      if (warn) return renderWarning(warn);
      // if (failed.qr) return renderWarning("qr running ถูกใช้งานแล้ว");
      // if (failed.product) return renderWarning("พบสินค้าหลายรายการ");

      // qr_data = res.data;
      // if (data.main_product) code_search = data.main_product.barcode;

      if (phase_one_add_some_sub_item) {
        setItemValueBom({
          product_code: data.product_code,
          barcode,
          qr: {
            ref_product_code: data.product_code,
            ref_store_qr_id: null,
            ref_store_qr_type: null,
            qr_running: qr_running,
          },
          item_key: product.key,
        });
        searchRef.current.input.state.value = "";
        searchRef.current.focus();
        setLoading(false);
        return;
      }
    } else {
      if (!data.product_code) return renderWarning("ไม่พบข้อมูลสินค้า");
      if (data.product_code !== product.product_code)
        return renderWarning("สินค้าที่เลือกไม่ถูกต้อง");
      // if (failed.qr) return renderWarning("qr running ถูกใช้งานแล้ว");
      // if (failed.product) return renderWarning("พบสินค้าหลายรายการ");
      // if (
      //   product.product_type_set === fixProductTypeSet.kit_pack &&
      //   failed.store_qr
      // )
      // return renderWarning("ไม่พบข้อมูล store qr");

      if (warn) return renderWarning(warn);
    }

    await handleAddQr(qr_running, barcode, data);
  };
  return (
    <Modal
      className={styles["qr-pop-up"]}
      visible={!!product}
      width={1000}
      zIndex={1080}
      destroyOnClose={true}
      footer={false}
      onCancel={onCancel}
    >
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <div className={styles.tab}>
              <Text>{product?.product_code}</Text>
            </div>
            <div className={styles.bar}>
              {!disabled && (
                <div className={styles.input}>
                  <Upload
                    beforeUpload={handleFileUpload}
                    customRequest={dummyRequest}
                    showUploadList={false}
                    accept=".xlsx,.csv,.numbers,.xls,.xlt,.xml"
                  >
                    <Button shape="round" size="small">
                      Import
                      <BiImport />
                    </Button>
                  </Upload>
                </div>
              )}
              <div className={styles.input}>
                <Text>Qr Tracking : </Text>
                <Input.Search
                  allowClear
                  loading={loading}
                  disabled={disabled}
                  ref={searchRef}
                  className="ml-2 mr-3"
                  placeholder="Start typing or scan Qr code..."
                  onSearch={handleSearch}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="bg-gray-20 pt-3 pb-3 px-4">
          {dataSource.length > 0 ? (
            <>
              <Row className={styles["block-head"]}>
                <Col span={15} offset={1} className="text-center">
                  <Text strong>ชื่อ</Text>
                </Col>
                <Col span={5} className="text-center">
                  <Text strong>Qr Running</Text>
                </Col>
                <Col span={3} className="text-center">
                  <Text strong>{`จำนวนทั้งหมด ${dataSource.length}`}</Text>
                </Col>
              </Row>
              <div className={styles["block-scroll"]}>
                {dataSource.map((n, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Row>
                        <Col span={1}>
                          <Text>{i + 1}</Text>
                        </Col>
                        <Col span={15} className="d-flex flex-column">
                          <Text>{n.product_name}</Text>
                        </Col>
                        <Col span={5} className="text-center">
                          <Text>
                            {/* {n.barcode ? (n.qr_running ? n.qr_running : "No Running") : "-"} */}
                            {/* product?.qr[i]?.qr_running !== undefined ----> เช็คที่หน้า "รอจัดสินค้า" */}
                            {/* product?.qr[i] !== undefined ----> เช็คที่หน้า "พร้อมจ่าย" */}
                            {/* i.qr_running ----> เช็คที่หน้า "ขายสินค้า" */}

                            {n?.qr_running ||
                              (product?.qr &&
                                (_.isObject(product?.qr[i])
                                  ? product?.qr[i]?.qr_running || "No Running"
                                  : product?.qr[i])) ||
                              "-"}

                            {/* {product?.qr[i]?.qr_running !== undefined
                            ? `${product?.qr[i]?.qr_running}`
                            : product?.qr[i] !== undefined
                            ? `${product?.qr[i]}`
                            : `-`} */}
                            {/* ----------------before ( errors )------------------ */}
                            {/* {product?.qr?.length > 0 ? product?.qr[i] : "-"} */}
                          </Text>
                        </Col>
                        <Col span={3} className="text-center">
                          {!disabled && (
                            <Popconfirm
                              placement="right"
                              title={`ต้องการลบรายการนี้หรือไม่`}
                              onConfirm={() =>
                                handleDelete(n.qr_running, n.bom, i, n)
                              }
                              okText="ลบ"
                              cancelText="ไม่ลบ"
                              zIndex={1080}
                            >
                              <Text>
                                <RiDeleteBin5Line
                                  size={20}
                                  className="cursor-pointer"
                                />
                              </Text>
                            </Popconfirm>
                          )}
                        </Col>
                      </Row>
                      {n.bom.length > 0 && (
                        <BomDetail
                          list={n.bom}
                          disabled={disabled}
                          dataProduct={product}
                          handleDelete={(running, bom_i, bom_product) =>
                            handleDelSomeBom(i, bom_i, running, bom_product)
                          }
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          ) : (
            <Empty className="pt-3" />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(Index);
