/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Input, Table } from 'antd';
import { FaEye, FaEyeSlash, FaUpload } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { Controller, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import fitLegacyServices from '../../../../services/fit-legacy.services';
import { useParams } from 'react-router';

dayjs.locale('th');

const pageSize = 50;

const LegacyPopUpPartnerListPage = () => {
  const params = useParams();
  const {
    data: legacyPopUpLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const { control, watch } = useForm({
    defaultValues: {
      memberCode: '',
    },
  });

  const { refetch: getLegacyPopUpPartnerLists } =
    fitLegacyServices.useQueryGetLegacyPopUpPartnerLists(
      {
        take: pageSize,
        page,
        legacyPopUpId: params.legacyPopUpId,
        memberCode: watch('memberCode'),
      },
      (data) => {
        setData(data.data);
        setTotal(data.meta.total);
      }
    );
  const { mutate: uploadLegacyPopUpPartnerListsExcel } =
    fitLegacyServices.useMutationUploadLegacyPopUpPartnerListsExcel((data) => {
      setTimeout(() => {
        inputRef.current.value = null;

        queryClient.invalidateQueries({
          queryKey: ['get-legacy-pop-up-partner-list'],
          refetchType: 'all',
        });

        setLoading(false);
      }, 600);
    });

  const _HandleSubmit = () => {
    getLegacyPopUpPartnerLists();
  };

  const _HandleUploadLegacyPopUpPartnerListsExcel = (data) => {
    setLoading(true);
    uploadLegacyPopUpPartnerListsExcel({
      data,
      legacyPopUpId: params.legacyPopUpId,
    });
  };

  const { mutate: deleteLegacyPopUp } =
    fitLegacyServices.useMutationDeleteLegacyPopUpPartnerList(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-pop-up-partner-list'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyPopUp } =
    fitLegacyServices.useMutationUpdateLegacyPopUpPartnerList(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-pop-up-partner-list'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'memberCode',
      key: 'memberCode',
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: 'สถานะ',
      dataIndex: 'display',
      key: 'display',
      render: (_, record) => (
        <span>{record?.display ? 'แสดง' : 'ไม่แสดง'}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.display ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการแสดง Pop up');
                if (confirm)
                  updateLegacyPopUp({ id: record.id, display: false });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการแสดง Pop up');
                if (confirm)
                  updateLegacyPopUp({ id: record.id, display: true });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyPopUp({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Legacy Pop Up รายชื่อ</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <Controller
            name="memberCode"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ค้นหาด้วยรหัสนักธุรกิจ"
                  className="mr-2"
                />
              );
            }}
          />
          <input
            type="file"
            id={'patient'}
            onChange={(e) => {
              if (e.target.files?.length > 0) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                _HandleUploadLegacyPopUpPartnerListsExcel(formData);
              }
            }}
            style={{ display: 'none' }}
            ref={inputRef}
          />
          <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
            loading={loading}
          >
            <FaUpload className="mr-2" />
            อัพโหลด excel
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={legacyPopUpLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
        />
      </PanelBody>
    </Panel>
  );
};

export default LegacyPopUpPartnerListPage;
