import React from 'react'
import styled from 'styled-components'
import DateTime from 'react-datetime';
import TextError from '../TextError';

const CustomDateTime = styled.div`
.rdtPicker {
    margin-left: -90%;
}
`

export default function DateTimePicker(props) {
    let { label, custom, className, validate, inputProps, ...rest } = props;
    inputProps.className = `form-control form-control-sm ${(validate) ? 'is-invalid' : ''} ${inputProps.className} `;
    return (
        <>
            <label>{label}</label>
            {
                (custom)
                    ?
                    <CustomDateTime>
                        <DateTime
                            {...rest}
                            inputProps={inputProps}
                        />
                    </CustomDateTime>
                    :
                    <DateTime
                        {...rest}
                        inputProps={inputProps}
                    />
            }
            <TextError validate={validate} />
        </>
    )
}
