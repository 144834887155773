import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import NewsCategoryForm from '../form/NewsCategoryForm';
import { newsCategoryFormDefaultValues } from '../dto/news';

const NewsCategoryModal = ({ open, handleClose }) => {
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createNewsCategory } = useAsync(
    fitStudioService.createNewsCategory,
    {
      onSuccess: () => {
        AlertSuccess('เพิ่มแบนเนอร์สำเร็จ');
        reset(newsCategoryFormDefaultValues);
        handleClose();
      },
    }
  );

  const { execute: editNewsCategory } = useAsync(
    fitStudioService.editNewsCategory,
    {
      onSuccess: () => {
        AlertSuccess('แก้ไขแบนเนอร์สำเร็จ');
        reset(newsCategoryFormDefaultValues);
        handleClose();
      },
    }
  );

  const _HandleSubmit = handleSubmit((data) => {
    const bannerData = {
      title: data.title,
      thumbnailImageUrl: data.thumbnailImageUrl,
    };
    if (watch('formType') === 'create') {
      createNewsCategory(bannerData);
    } else if (watch('formType') === 'edit') {
      editNewsCategory({ id: data.id, data: bannerData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'สร้างหมวดหมู่ข่าวสาร'
          : 'แก้ไขหมวดหมู่ข่าวสาร'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <NewsCategoryForm />
    </Modal>
  );
};

export default NewsCategoryModal;
