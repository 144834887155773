import { Button, Table } from "antd";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { FaCheck, FaEye, FaTimes } from "react-icons/fa";
import { useApi } from "../../../../../hooks/http2";
import { URL_API } from "../../../../../utils";
import { useCommissionMutate } from "../contexts/commission.context";

const LogTable = () => {
  const {
    state: { filter },
    dispatch,
  } = useCommissionMutate();

  const { loading, fetch } = useApi(
    {
      baseURL: URL_API,
      url: `/interface/log/commission`,
      token: true,
      params: {
        ...filter,
        offset: (filter.page - 1) * filter.limit,
        query: "",
      },
    },
    [filter]
  );

  return (
    <Table
      bordered
      size="small"
      rowKey="row_id"
      loading={loading}
      dataSource={fetch?.data?.record || []}
      pagination={{
        current: filter.page,
        pageSize: filter.limit,
        total: fetch?.data?.total || 0,
        showLessItems: true,
        hideOnSinglePage: true,
        size: "default",
        className: "d-flex justify-content-center w-100",
        onChange: (page) => dispatch({ type: "SET_FILTER", payload: { page } }),
      }}
    >
      <Table.Column
        key="create_date"
        title="วันที่และเวลา"
        dataIndex="create_date"
        align="center"
        render={(text) => moment(text).format("DD/MM/YY HH:mm")}
      />
      <Table.Column key="end_point" title="URL" dataIndex="end_point" />
      <Table.Column
        key="total_record"
        title="จำนวนข้อมูล"
        dataIndex="total_record"
        align="center"
        render={(value) => numeral(value).format()}
      />
      <Table.Column
        key="result"
        title="ผลลัพธ์"
        dataIndex="result"
        render={(text) => {
          return (
            <>
              <Button
                shape="circle"
                className={`${text === "success" ? "bg-pv" : "bg-reds"} mr-2`}
                size="small"
              >
                {text === "success" ? (
                  <FaCheck className="text-white" />
                ) : (
                  <FaTimes className="text-white" />
                )}
              </Button>
              <span className="">
                {text === "success" ? "สำเร็จ" : "ผิดพลาด"}
              </span>
            </>
          );
        }}
      />
      <Table.Column
        key="action"
        title=""
        dataIndex="id"
        align="center"
        render={(id) => {
          return (
            <FaEye
              className="cursor-pointer"
              onClick={() =>
                dispatch({ type: "SHOW_PREVIEW", payload: { id } })
              }
            />
          );
        }}
      />
    </Table>
  );
};

export default LogTable;
