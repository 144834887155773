import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { AlertError, AlertSuccess } from '../../../../util/Alert';
import digitalProductServices from '../../../../../services/digital-product.services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DigitalProductForm from '../form/DigitalProductForm';

const DigitalProductModal2 = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createDigitalProductProject } =
    digitalProductServices.useMutationCreateDigitalProduct(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-digital-products'],
          refetchType: 'all',
        });
        AlertSuccess('เพิ่มข้อมูลสำเร็จ');
        handleClose();
      },
      (err) => {
        AlertError({ text: err });
      }
    );

  const { mutate: editDigitalProductProject } =
    digitalProductServices.useMutationUpdateDigitalProduct(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-digital-products'],
          refetchType: 'all',
        });
        AlertSuccess('แก้ไขข้อมูลสำเร็จ');
        handleClose();
      },
      (err) => {
        AlertError({ text: err });
      }
    );

  const _HandleSubmit = handleSubmit((data) => {
    const data2 = {
      name: data.name,
      description: data.description,
      memberCode: data.memberCode,
      memberType: data.memberType,
      isPartner: data.isPartner,
      image: data.image,
      requestOrder: data.requestOrder,
      phoneNumber: data.phoneNumber,
    };

    if (watch('formType') === 'create') {
      createDigitalProductProject({
        digitalProductProjectId: params.digitalProductProjectId,
        data: data2,
      });
    } else if (watch('formType') === 'edit') {
      editDigitalProductProject({ id: data.id, data: data2 });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่ม Digital Project'
          : 'แก้ Digital Project'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <DigitalProductForm />
    </Modal>
  );
};

export default DigitalProductModal2;
