/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Divider, Pagination } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHttp } from "../../../../../../../hooks/http";
import { moment, URL_API } from "../../../../../../../utils";
import Table from "../Table";
import InfoContext from "./reducer";

const columns = [
  {
    title: "หมายเหตุ",
    dataIndex: "remark",
    width: "60%",
  },
  {
    title: "ผู้เขียน",
    dataIndex: "create_by",
    width: "20%",
  },
  {
    title: "วันที่",
    dataIndex: "create_date",
    width: "20%",
    align: "center",
    render: (text) => text && moment(text).format("DD/MM/YYYY HH:mm"),
  },
];

const limit = 5;
const Remark = () => {
  const {
    state: {
      data: { ref_id, ref_type },
    },
  } = useContext(InfoContext);
  const [page, setPage] = useState(1);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/member-profile/remark`,
      token: true,
      initialLoad: false,
      params: {
        ref_id,
        ref_type,
        limit,
        offset: (page - 1) * limit,
      },
    },
    []
  );

  useEffect(() => {
    if (page && ref_id && ref_type) reload();
  }, [page, ref_id, ref_type]);

  if (error) return <Alert type="error" message={error} showIcon />;

  return (
    <>
      <Divider>หมายเหตุ</Divider>
      <Table
        dataSource={data?.data || []}
        columns={columns}
        loading={loading}
      />
      {data && data.total !== 0 && (
        <Pagination
          size="small"
          hideOnSinglePage
          pageSize={limit}
          current={page}
          total={data.total}
          onChange={(e) => setPage(e)}
          className="text-center mt-2"
        />
      )}
    </>
  );
};

export default Remark;
