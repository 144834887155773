/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import { Row, Col, Button, Upload, Table, notification, message } from "antd";
import axios from "axios";
import { URL_API, getToken } from "../../../utils";
import { useHttp } from "../../../hooks/http";
import _ from "lodash";
import { AiOutlineDelete } from "react-icons/ai";

const containerName = "operations";

const Index = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState("");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/upload-partner/partner/get-partner-file`,
      token: true,
      params: {},
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const handleUpload = (e) => {
    const file = e.file;
    if (file.type !== "application/pdf")
      return message.warning("อัพโหลดได้เฉพาะไฟล์ PDF เท่านั้น");

    setIsLoading(true);
    const data = new FormData();
    data.append("file", e.file);
    data.append("container_name", containerName);
    //Filelimit 20MB
    axios({
      method: "post",
      url: `${URL_API}/file/upload/upload-partner`,
      headers: { Authorization: "Bearer " + getToken() },
      params: {
        ref_id: 0,
        ref_code: "",
        ref_type: "legal-form",
        // document_type: null,
      },
      data,
    })
      .catch((e) => {
        notification.open({
          message: "Error",
          description: e.message ? e.message : "ส่งข้อมูลไม่สำเร็จ",
        });
      })
      .finally(() => {
        reload();
        setIsLoading(false);
      });
  };

  const deleteFileHandler = async (id) => {
    try {
      await axios({
        method: "post",
        url: `${URL_API}/upload-partner/partner/del-partner-file`,
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          id,
        },
      });
      reload();
    } catch (e) {
      notification.open({
        message: "Error",
        description: e.message ? e.message : "ส่งข้อมูลไม่สำเร็จ",
      });
    }
  };

  return (
    <Panel>
      <PanelHeader>Upload</PanelHeader>
      <PanelBody>
        <Row type="flex" justify="end" className="m-20">
          <Col span={2}>
            <Upload
              multiple
              showUploadList={false}
              customRequest={handleUpload}
              accept=".pdf"
            >
              <Button className="w-100" type="primary">
                Upload
              </Button>
            </Upload>
          </Col>
        </Row>
        <Table
          showHeader={false}
          dataSource={dataSource.data}
          rowKey="row_id"
          pagination={false}
          bordered
          size="small"
          loading={loading}
        >
          <Table.Column dataIndex="row_id" width={100} align="center" />
          <Table.Column dataIndex="file_name" align="left" colSpan="12" />
          <Table.Column
            dataIndex=""
            width={100}
            align="center"
            render={({ id }) => {
              return (
                <Button
                  onClick={() => {
                    deleteFileHandler(id);
                  }}
                >
                  <AiOutlineDelete />
                </Button>
              );
            }}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default Index;
