import React, { Component } from 'react'

export class ButtonSave extends Component {
    render() {
        const { type = "button", name = 'บันทึก', className = "", disabled = false, onClick } = this.props;
        return (
            <>
                <button
                    type={type}
                    className={`${className} btn btn-sm btn-lime`}
                    onClick={() => {
                        if (onClick) onClick();
                    }}
                    disabled={disabled}
                >
                    <i className="fas fa-save mr-1" />
                    {name}
                </button>
            </>
        )
    }
}

export default ButtonSave
