import { Col, Modal, Row } from "antd";
import React, { memo, useCallback, useState } from "react";
import TabUnderLine from "../../../../../../components/TabUnderLine";
import Free from "./Free";
import Promotion from "./Promotion";
import Source from "./Source";

const listTab = [
  { title: "โปรโมชัน", value: "promotion" },
  { title: "เงื่อนไข", value: "source" },
  { title: "สิ่งที่ได้รับ", value: "free" },
];

const Index = ({ onCancel, dataSource = {} }) => {
  const { pro_id, source_azure_url, free_azure_url } = dataSource;
  const [tabActive, setTabActive] = useState("promotion");

  const handleClickTeb = useCallback((e) => {
    setTabActive(e);
  }, []);

  return (
    <Modal
      visible={!!pro_id}
      destroyOnClose
      footer={false}
      zIndex={1080}
      width={900}
      onCancel={() => {
        setTabActive("promotion");
        onCancel();
      }}
    >
      <Row className="my-3">
        <Col>
          <TabUnderLine
            active={tabActive}
            list={listTab}
            onClick={handleClickTeb}
          />
        </Col>
      </Row>
      {tabActive === "promotion" ? (
        <Promotion dataSource={dataSource[tabActive]} />
      ) : tabActive === "source" ? (
        <Source list={dataSource[tabActive]} azureUrl={source_azure_url} />
      ) : (
        <Free list={dataSource[tabActive]} azureUrl={free_azure_url} />
      )}
      {/* <Row>
        <Col span={24}>
          <Typography.Title level={4}>รายละเอียดโปรโมชัน</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>{name}</Typography.Text>
        </Col>
      </Row>
      <Row type="flex" className="mt-3 align-items-center">
        <Col span={8} className="d-flex align-items-center border-right">
          <img
            src={imgErr ? imgErr : azure_url}
            alt="product"
            className="w-100"
            onError={() => setImgErr(img)}
          />
        </Col>
        <Col span={16} className="px-3">
          <div>{description ? parse(description) : <Empty />}</div>
        </Col>
      </Row> */}
    </Modal>
  );
};

export default memo(Index);
