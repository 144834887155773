import { Col, Modal, Row, Table } from "antd";
import React from "react";

const PromotionDisableItemPopup = ({
  sourceDisableItem,
  freeDisableItem,
  onCancel,
}) => {
  return (
    <Modal
      visible={
        !!sourceDisableItem.banned.length ||
        !!sourceDisableItem.sold_out.length ||
        !!freeDisableItem.banned.length ||
        !!freeDisableItem.sold_out.length
      }
      destroyOnClose
      footer={false}
      zIndex={1080}
      onCancel={onCancel}
    >
      {!!sourceDisableItem.banned.length && (
        <Row className="mt-4">
          <Col span={24}>
            <Table
              bordered
              size="small"
              rowKey="product_id"
              pagination={false}
              dataSource={sourceDisableItem.banned}
            >
              <Table.Column
                key="product_name_local"
                dataIndex="product_name_local"
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    รายการสินค้าโปรโมชันไม่สามารถซื้อได้
                  </div>
                }
              />
            </Table>
          </Col>
        </Row>
      )}
      {!!sourceDisableItem.sold_out.length && (
        <Row className="mt-4">
          <Col span={24}>
            <Table
              bordered
              size="small"
              rowKey="product_id"
              pagination={false}
              dataSource={sourceDisableItem.sold_out}
            >
              <Table.Column
                key="product_name_local"
                dataIndex="product_name_local"
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    รายการสินค้าโปรโมชันหมด
                  </div>
                }
              />
            </Table>
          </Col>
        </Row>
      )}
      {!!freeDisableItem.banned.length && (
        <Row className="mt-4">
          <Col span={24}>
            <Table
              bordered
              size="small"
              rowKey="product_id"
              pagination={false}
              dataSource={freeDisableItem.banned}
            >
              <Table.Column
                key="product_name_local"
                dataIndex="product_name_local"
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    รายการสินค้าของแถมไม่สามารถซื้อได้
                  </div>
                }
              />
            </Table>
          </Col>
        </Row>
      )}
      {!!freeDisableItem.sold_out.length && (
        <Row className="mt-4">
          <Col span={24}>
            <Table
              bordered
              size="small"
              rowKey="product_id"
              pagination={false}
              dataSource={freeDisableItem.sold_out}
            >
              <Table.Column
                key="product_name_local"
                dataIndex="product_name_local"
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    รายการสินค้าของแถมหมด
                  </div>
                }
              />
            </Table>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default PromotionDisableItemPopup;
