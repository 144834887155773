/* eslint-disable jsx-a11y/alt-text */
import { Modal } from "antd";
import React from "react";

const PreviewFile = (props) => {
  return (
    <>
      <Modal zIndex={2001} {...props}>
        <img
          //   src={`${URL_API}/system/files/${props.imageId}`}
          src={props.src}
          style={{ width: "100%" }}
        />
      </Modal>
    </>
  );
};
export default PreviewFile;
