import { Table } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { remarkSelector } from "../../../../redux/slices/member";

export default function TableRemark() {
  const remark = useSelector(remarkSelector);

  return (
    <Table size="small" dataSource={remark} pagination={false} rowKey="id">
      <Table.Column title="#" align="right" dataIndex="row_id" />
      <Table.Column title="หมายเหตุ" dataIndex="remark" />
      <Table.Column title="ผู้เขียน" dataIndex="create_by" />
      <Table.Column
        title="วันที่"
        align="center"
        dataIndex="create_date"
        render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
      />
    </Table>
  );
}
