/* eslint-disable no-unused-vars */
import {
  Button,
  DatePicker,
  Form,
  Row,
  Col,
  Checkbox,
  Radio,
  notification,
  Typography,
  Input,
  Affix,
} from "antd";
import moment from "moment";
import React, { useReducer, useState, useEffect } from "react";
import { Panel, PanelHeader, PanelBody } from "../../util/panel";
import _ from "lodash";
import axios from "axios";
import { getToken, URL_API } from "../../../utils";
import { AiOutlineLoading } from "react-icons/ai";
import { Link } from "react-router-dom";
import TextEditor from "../../../components/TextEditor";
import { useParams, useHistory } from "react-router-dom";
import UploadFileList from "../../../components/UploadFileList";
import TextArea from "antd/lib/input/TextArea";
import { mapFileList } from "../../util/helper/file";
import { mapDataToItemKey } from "../../util/helper/map-data";
import "./New-list.css";

const { Text } = Typography;

const cbTypeOptions = [
  {
    label: "นักธุรกิจ",
    value: "dis",
  },
  {
    label: "VIP",
    value: "vip",
  },
  {
    label: "Customer",
    value: "cust",
  },
];
const initialState = {
  title: "",
  description: "",
  start_date: moment(),
  end_date: moment(),
  type: [
    ..._.map(cbTypeOptions, (options) => {
      return options.value;
    }),
  ],
  status: "active",
  announcement: "notice",
  media: { itemDel: [], itemKeys: [], itemByKey: {} },
  cover: { itemDel: [], itemKeys: [], itemByKey: {} },
  link: "",
};

function formReducer(state, action) {
  switch (action.type) {
    case "setTitle":
      return { ...state, title: action.payload };
    case "setDescription":
      return { ...state, description: action.payload };
    case "setStartDate":
      return { ...state, start_date: action.payload };
    case "setEndDate":
      return { ...state, end_date: action.payload };
    case "setType":
      return { ...state, type: action.payload };
    case "setStatus":
      return { ...state, status: action.payload };
    case "setAnnouncement":
      return { ...state, announcement: action.payload };
    case "setLink":
      return { ...state, link: action.payload };
    case "setMedia":
      const key = _.uniqueId("media-");
      return {
        ...state,
        media: {
          ...state.media,
          itemKeys: [...state.media.itemKeys, key],
          itemByKey: { ...state.media.itemByKey, [key]: { ...action.payload } },
        },
      };

    case "setCover":
      const keyCov = _.uniqueId("cover-");
      return {
        ...state,
        cover: {
          ...state.cover,
          itemKeys: [...state.cover.itemKeys, keyCov],
          itemByKey: {
            ...state.cover.itemByKey,
            [keyCov]: { ...action.payload },
          },
        },
      };

    case "setDeleteMedia":
      const { id, temp } = state.media.itemByKey[action.payload];
      let itemDel = state.media.itemDel;
      if (!temp) {
        itemDel = [...state.media.itemDel, id];
      }
      return {
        ...state,
        media: {
          ...state.media,
          itemDel,
          itemKeys: _.filter(state.media.itemKeys, (n) => n !== action.payload),
          itemByKey: _.omit(state.media.itemByKey, [action.payload]),
        },
      };
    case "setDeleteCover":
      const { id: idCov, temp: tempCov } =
        state.cover.itemByKey[action.payload];
      let itemDelCov = state.cover.itemDel;
      if (!tempCov) {
        itemDelCov = [...state.cover.itemDel, idCov];
      }
      return {
        ...state,
        cover: {
          ...state.cover,
          itemDel: itemDelCov,
          itemKeys: _.filter(state.cover.itemKeys, (n) => n !== action.payload),
          itemByKey: _.omit(state.cover.itemByKey, [action.payload]),
        },
      };

    case "setClearState":
      return {
        title: initialState.title,
        description: initialState.description,
        start_date: initialState.start_date,
        end_date: initialState.end_date,
        type: initialState.type,
        status: initialState.status,
        announcement: initialState.announcement,
      };

    case "setAllState":
      return {
        title: action.payload.title,
        description: action.payload.description,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        type: action.payload.type,
        status: action.payload.status,
        announcement: action.payload.announcement,
      };

    case "setMediaList":
      return {
        ...state,
        media: {
          ...state.media,
          ...mapDataToItemKey(action.payload),
          itemDel: [],
        },
      };

    case "setCoverList":
      return {
        ...state,
        cover: {
          ...state.cover,
          ...mapDataToItemKey(action.payload),
          itemDel: [],
        },
      };

    default:
      throw new Error();
  }
}

const Index = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImageId, setUploadImageId] = useState([]);
  const [initDesc, setinitDesc] = useState("");
  const disabledEndDateSplit = (current, start) => {
    return !state.start_date || state.start_date <= state.end_date;
  };
  const [announcementFilter, setAnnouncementFilter] = useState([]);

  if (moment(state.end_date) < moment(state.start_date)) {
    dispatch({
      type: "setEndDate",
      payload: state.start_date,
    });
  }
  const history = useHistory();

  const params = useParams();
  const newsId = params.newsId;
  const newsType = params.newsType;
  // console.log(state.cover);

  useEffect(() => {
    if (newsType === "legal") {
      dispatch({ type: "setAnnouncement", payload: "legal" });
    }
  }, [newsType]);

  useEffect(() => {
    axios({
      url: `${URL_API}/news/news/get-news`,
      headers: { Authorization: "Bearer " + getToken() },
      params: {
        id: newsId,
      },
    })
      .then((res) => {
        if (newsId) {
          dispatch({
            type: "setAllState",
            payload: {
              title: res.data.data[0].title,
              description: res.data.data[0].description,
              start_date: res.data.data[0].start_date,
              end_date: res.data.data[0].end_date,
              type: [
                res.data.data[0].show_customer === 1 && "cust",
                res.data.data[0].show_vip === 1 && "vip",
                res.data.data[0].show_distributor === 1 && "dis",
              ],
              status: res.data.data[0].status,
              announcement: res.data.data[0].type,
              link: res.data.data[0].link_url,
            },
          });
          dispatch({
            type: "setMediaList",
            payload: res.data.dataImage.filter((n) => {
              return n.document_type === "29";
            }),
          });

          dispatch({
            type: "setCoverList",
            payload: res.data.dataImage.filter((n) => {
              return n.document_type === "30";
            }),
          });
          setinitDesc(res.data.data[0].description);
        }

        setAnnouncementFilter(
          _.filter(res.data.dataFilter, (data) => {
            if (newsType === "legal") {
              return ["legal", "announce"].includes(data.value);
            } else {
              return !["legal", "announce"].includes(data.value);
            }
          })
        );
      })
      .catch((e) => {
        notification.warning({
          message: e.name ? e.name : "Error",
          description: e.response ? e.response.data.message : "No Data Found",
          top: 60,
        });
      });
  }, [newsId, newsType]);

  const postData = async () => {
    setIsLoading(true);
    try {
      console.log(state.cover);
      const coverMap = mapFileList(state.cover);
      console.log(coverMap);
      await axios({
        method: "post",
        url: `${URL_API}/news/news/${newsId ? "news-edit" : "news-create"}`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          id: newsId ? newsId : null,
          title: state.title,
          description: state.description,
          start_date: state.start_date,
          end_date: state.end_date,
          type: state.type,
          status: state.status,
          announcement: state.announcement,
          ...mapFileList(state.media),
          coverFile: coverMap.file,
          coverFileDel: coverMap.file_del,
          link: state.link,
        },
      });
      setIsLoading(false);
      if (newsType === "legal") {
        history.push("/legal-form/legal-list");
      } else {
        history.push("/news");
      }
      notification.success({
        message: "✔️ Success",
        description: `${newsId ? "แก้ไขสำเร็จ" : "ส่งข้อมูลสำเร็จ"}`,
      });
    } catch (e) {
      notification.warning({
        message: e.name ? e.name : "❌ เกิดข้อผิดพลาด",
        description: e.response
          ? e.response.data.message
          : "ส่งข้อมูลไม่สำเร็จ",
        top: 60,
      });
    }
  };

  const submitFormHandler = (e) => {
    postData();
    dispatch({ type: "setClearState" });
  };

  const handleChangeUpload = (value) => {
    dispatch({
      type: "setMedia",
      payload: value,
    });
  };

  const handleDeleteUpload = (value) => {
    console.log("up: " + value);
    dispatch({ type: "setDeleteMedia", payload: value });
  };

  const handleCoverUpload = (value) => {
    dispatch({
      type: "setCover",
      payload: value,
    });
  };

  const handleDeleteCover = (value) => {
    console.log("cov: " + value);
    dispatch({ type: "setDeleteCover", payload: value });
  };

  return (
    <Panel>
      <PanelHeader>สร้างประกาศ</PanelHeader>
      <PanelBody>
        <Row gutter={24}>
          <Col span={16}>
            <div
              style={{
                border: "solid 1px",
                borderRadius: "5px",
                padding: "0 1rem 0 10px",
              }}
            >
              {isLoading && <AiOutlineLoading />}
              <Form className="ml-10 form-font-size" type="submit">
                <Form.Item label="Title">
                  <TextArea
                    rows={3}
                    value={state.title}
                    onChange={(e) => {
                      dispatch({
                        type: "setTitle",
                        payload: e.target.value,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item label="Description">
                  <TextEditor
                    authToken={getToken()}
                    height={800}
                    initialValue={newsId ? initDesc : ""}
                    onInit={(evt, event) => {}}
                    onEditorChange={(e) => {
                      dispatch({
                        type: "setDescription",
                        payload: e,
                      });
                    }}
                    onUploadSuccess={(json) => {
                      setUploadImageId((prevData) => {
                        return [
                          ...prevData,
                          { id: json.id, url: json.azure_url },
                        ];
                      });
                    }}
                    postBody={{
                      container_name: "operations",
                      ref_code: "0",
                      document_type: "31",
                      ref_type: "Sys_Master_News",
                    }}
                    urlUpload={`${URL_API}/file/upload/upload-text-editor`}
                  />
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                border: "solid 1px",
                borderRadius: "5px",
                padding: "0 1rem 0 10px",
              }}
            >
              <Affix offsetTop={50}>
                <Row
                  gutter={12}
                  type="flex"
                  justify="end"
                  className="mb-2 mt-3 "
                >
                  <Col>
                    <Button type="primary" onClick={submitFormHandler}>
                      บันทึก
                    </Button>
                  </Col>
                  <Col>
                    {/* <Link
                      to={`${
                        newsType === "news" ? "/news" : "/legal-form/legal-list"
                      }`}
                    > */}
                    <Button type="primary" onClick={() => history.goBack()}>
                      ยกเลิก
                    </Button>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Affix>
              <Form className=" p-10" type="submit">
                <Form.Item
                  colon={false}
                  label={
                    <>
                      <Text strong>Thumbnail : </Text>
                      <Text>(ขนาดภาพ 1,200x1,200 px)</Text>
                    </>
                  }
                >
                  <UploadFileList
                    limit={1}
                    limitFileSize={1}
                    acceptImage
                    withCamera
                    files={state.cover}
                    limitWidth={1200}
                    limitHeight={1200}
                    onChange={handleCoverUpload}
                    onDelete={handleDeleteCover}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label={
                    <>
                      <Text strong>แกลลอรี่ : </Text>
                      <Text>(ขนาดภาพ 1,200x1,200 px)</Text>
                    </>
                  }
                >
                  <UploadFileList
                    acceptImage
                    limitFileSize={1}
                    withCamera
                    files={state.media}
                    limitWidth={1200}
                    limitHeight={1200}
                    onChange={handleChangeUpload}
                    onDelete={handleDeleteUpload}
                  />
                </Form.Item>
                <Form.Item label="Start Date">
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY HH:mm"
                    className="w-80"
                    defaultValue={
                      state.start_date ? moment(state.start_date) : null
                    }
                    value={state.start_date ? moment(state.start_date) : null}
                    onChange={(e) => {
                      dispatch({
                        type: "setStartDate",
                        payload: e,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item label="End Date">
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY HH:mm"
                    defaultValue={
                      state.end_date ? moment(state.end_date) : null
                    }
                    value={state.end_date ? moment(state.end_date) : null}
                    onChange={(e) => {
                      dispatch({
                        type: "setEndDate",
                        payload: e,
                      });
                    }}
                    disabledDate={(current) =>
                      state.start_date && current < state.start_date
                    }
                  />
                </Form.Item>
                <Form.Item label="กลุ่มที่จะแสดง">
                  <Checkbox.Group
                    options={cbTypeOptions}
                    value={state.type}
                    onChange={(e) => {
                      dispatch({
                        type: "setType",
                        payload: e,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  colon={false}
                  label={
                    <>
                      <Text strong>ประเภทประกาศ : </Text>
                      {!newsType && (
                        <Text style={{ color: "#ff3333" }}>
                          (ข่าวกิจกรรม และโปรโมชัน จะมี Noti แจ้งสมาชิก)
                        </Text>
                      )}
                    </>
                  }
                >
                  <Radio.Group
                    onChange={(e) => {
                      dispatch({
                        type: "setAnnouncement",
                        payload: e.target.value,
                      });
                    }}
                    defaultValue={state.announcement}
                    value={state.announcement}
                  >
                    {_.map(announcementFilter, (data, i) => {
                      return (
                        <Radio key={i} value={data.value}>
                          {data.text}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>

                {state.announcement === "flash" && (
                  <Form.Item label="Link Url">
                    <Input
                      onChange={(e) => {
                        dispatch({
                          type: "setLink",
                          payload: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item label="Status">
                  <Radio.Group
                    onChange={(e) => {
                      dispatch({
                        type: "setStatus",
                        payload: e.target.value,
                      });
                    }}
                    defaultValue={state.status}
                    value={state.status}
                  >
                    <Radio value={"active"}>Active</Radio>
                    <Radio value={"inactive"}>Inactive</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </PanelBody>
    </Panel>
  );
};

export default Index;
