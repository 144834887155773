/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Table, Typography, Select } from 'antd';
import fitRewardServices from '../../../../services/fit-reward.services';
import userConstants from '../constants/userConstants';
import usePaginationHook from '../../../../utils/usePagination';
import { Controller, useForm } from 'react-hook-form';
import { FaUserAlt } from 'react-icons/fa';

const RewardReportPage = () => {
  const params = useParams();
  const history = useHistory();

  const { control, watch } = useForm({
    defaultValues: {
      isMax: false,
    },
  });

  const {
    page,
    setPage,
    data: userRewardLists,
    setData,
    total,
    setTotal,
  } = usePaginationHook();

  const { data: rewardData } = fitRewardServices.useQueryGetRewardById({
    rewardId: params.rewardId,
  });

  fitRewardServices.useQueryGetUserRewardByRewardId(
    {
      rewardId: params.rewardId,
      isMax: watch('isMax'),
      page,
      take: userConstants.tableSize,
    },
    (data) => {
      setTotal(data.meta.total);
      setData(data.data);
    }
  );

  const columns = [
    {
      title: 'RFS Member ID',
      dataIndex: 'user.fitNo',
      key: 'fitNo',
    },
    {
      title: 'รหัสสมาชิก',
      dataIndex: 'user.legacyPartnerCode',
      key: 'legacyPartnerCode',
    },
    {
        title: 'รูปภาพ',
        dataIndex: 'thumbnailImageUrl',
        key: 'thumbnailImageUrl',
        render: (_, record) => {
          if (record.profileImageUrl) {
            return (
              <img
                key={record.user.profileImageUrl}
                src={record.user.profileImageUrl}
                alt={record.user.profileImageUrl}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: 'cover',
                  borderRadius: 25,
                }}
              />
            );
          }
  
          return (
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: '#e0e0e0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FaUserAlt />
            </div>
          );
        },
      },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record.user.firstName} {record.user.lastName}
        </span>
      ),
    },
    {
      title: 'รหัสบัตรประชาชน',
      dataIndex: 'idCard',
      key: 'idCard',
      render: (_, record) => <span>{record.user.idCard}</span>,
    },
    {
      title: 'จำนวนเหรียญ',
      dataIndex: 'max',
      key: 'max',
      render: (_, record) => (
        <span
          style={{
            color: record.time >= record.reward.max ? '#97d700' : 'black',
          }}
        >
          {record.time >= record.reward.max ? record.reward.max : record.time}/
          {record.reward.max}
        </span>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{rewardData?.title}/</h3>
      </div>
      <Panel>
        <PanelHeader>ผู้ใช้งาน</PanelHeader>
        <PanelBody>
          <div>
            <Typography.Text>ตัวกรอง</Typography.Text>
            <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
              <Controller
                name="isMax"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      style={{ flex: 0.5, marginRight: 10 }}
                      onChange={onChange}
                      placeholder="ประเภทรางวัล"
                    >
                      <Select.Option value={false}>
                        รางวัลเหรียญอย่างน้อย 1 เหรียญ
                      </Select.Option>
                      <Select.Option value={true}>
                        รางวัลเหรียญทอง
                      </Select.Option>
                    </Select>
                  );
                }}
              />
            </div>
          </div>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={userRewardLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize: userConstants.tableSize,
              total,
            }}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default RewardReportPage;
