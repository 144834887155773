import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { playlistFormDefaultValues } from '../dto/public-playlist';
import PublicPlaylistForm from '../form/PublicPlaylistForm';

const PublicPlaylistModal = ({ open, handleClose }) => {
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createPublicPlaylist } = useAsync(
    fitStudioService.createPublicPlaylist,
    {
      onSuccess: () => {
        AlertSuccess('เพิ่มเพลย์ลิสท์สำเร็จ');
        reset(playlistFormDefaultValues);
        handleClose();
      },
    }
  );

  const { execute: editPublicPlaylist } = useAsync(
    fitStudioService.editPublicPlaylist,
    {
      onSuccess: () => {
        AlertSuccess('แก้ไขเพลย์ลิสท์สำเร็จ');
        reset(playlistFormDefaultValues);
        handleClose();
      },
    }
  );

  const _HandleSubmit = handleSubmit((data) => {
    const videoes = data.videoes.map((item) => {
      if (item.id) {
        return item;
      } else {
        const video = item;
        delete video.id;
        return video;
      }
    });

    const playlistData = {
      title: data.title,
      description: data.description,
      thumbnailImageUrl: data.thumbnailImageUrl,
      coverImageUrl: data.coverImageUrl,
      videoes: videoes,
    };
    if (watch('formType') === 'create') {
      createPublicPlaylist(playlistData);
    } else if (watch('formType') === 'edit') {
      editPublicPlaylist({ id: data.id, data: playlistData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create' ? 'สร้างคลาสเรียน' : 'แก้ไขคลาสเรียน'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <PublicPlaylistForm />
    </Modal>
  );
};

export default PublicPlaylistModal;
