import { Col, Row } from "antd";
import React from "react";
import SelectMember from "./SelectMember";
import SelectBranch from "./SelectBranch";
import UploadDocument from "./UploadDocument";
import Remark from "./Remark";
const Index = () => {
  return (
    <Row gutter={[8, 8]} type="flex">
      <Col
        span={24}
        order={1}
        md={{ order: 1, span: 12 }}
        xxl={{ order: 1, span: 6 }}
      >
        <SelectMember/>
      </Col>
      <Col
        span={24}
        order={2}
        md={{ order: 2, span: 12 }}
        xxl={{ order: 2, span: 6 }}
      >
        <SelectBranch/>
      </Col>
      <Col
        span={24}
        order={3}
        md={{ order: 3, span: 12 }}
        xxl={{ order: 3, span: 6 }}
      >
       <UploadDocument/>
      </Col>
      <Col
        span={24}
        order={4}
        md={{ order: 4, span: 12 }}
        xxl={{ order: 4, span: 6 }}
      >
       <Remark/>
      </Col>
    </Row>
  );
};

export default Index;
