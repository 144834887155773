/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, Input, Modal, Row, Table } from "antd";
import React, { useContext, useState } from "react";
import { FaCogs, FaPlus } from "react-icons/fa";
import { useHttp } from "../../../../../../hooks/http";
import { URL_API, _ } from "../../../../../../utils";
import { PromotionContext } from "../reducers";

const limit = 10;
const ModalVoucher = () => {
  const {
    state: {
      modal: {
        voucher: { visible, active_by, data_key },
      },
      [active_by]: data_type,
    },
    dispatch,
  } = useContext(PromotionContext);
  const disabledId = _.join(
    _.map(
      data_type?.itemByKey[data_key].itemKeys,
      (item_key) => data_type.itemByKey[data_key].itemByKey[item_key].id
    ),
    ","
  );
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [loading, data, error, reload] = useHttp(
    {
      method: "get",
      url: `${URL_API}/system/voucher`,
      params: {
        limit,
        offset: (page - 1) * limit,
        query,
        disabledId,
      },
      token: true,
    },
    [page, disabledId, query]
  );

  const onCancel = () => {
    setPage(1);
    setQuery("");
    dispatch({
      type: "SET_MODAL_VISIBLE",
      payload: {
        name: "voucher",
        visible: false,
        active_by: null,
        data_key: null,
        ref_source_option: null,
      },
    });
  };

  return (
    <Modal
      visible={visible}
      title="เลือกบัตรกำนัล"
      onCancel={onCancel}
      footer={false}
      width={800}
      zIndex={1080}
      destroyOnClose={true}
    >
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <Input.Search
            allowClear
            placeholder="รหัส/ชื่อบัตรกำนัล"
            onSearch={(value) => {
              setPage(1);
              setQuery(value.trim());
            }}
          />
        </Col>
      </Row>
      {error ? (
        <Alert type="error" />
      ) : (
        <Table
          size="small"
          loading={loading}
          dataSource={data?.data || []}
          rowKey="row_id"
          pagination={{
            current: page,
            size: "default",
            pageSize: limit,
            showSizeChanger: false,
            showLessItems: true,
            onChange: (curr) => setPage(curr),
            total: data?.total || 0,
          }}
        >
          <Table.Column
            key="row_id"
            dataIndex="row_id"
            className="position-relative"
            title={<div className="ant-table-absolute-title-center">ลำดับ</div>}
            width={70}
            align="right"
          />
          <Table.Column
            key="code"
            dataIndex="code"
            className="position-relative"
            width={100}
            title={<div className="ant-table-absolute-title-center">รหัส</div>}
          />
          <Table.Column
            key="name"
            dataIndex="name"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                ชื่อบัตรกำนัล
              </div>
            }
          />
          <Table.Column
            title={<FaCogs />}
            align="center"
            width={60}
            render={(item, _record, index) => (
              <Button
                id={`voucher-${index}`}
                type="primary"
                ghost
                size="small"
                onClick={() =>
                  dispatch({
                    type: "ADD_PROMOTION_DATA_ITEM",
                    payload: {
                      ...item,
                      keep: "0",
                      item_type: "voucher",
                      modal_name: "voucher",
                    },
                  })
                }
              >
                <FaPlus />
              </Button>
            )}
          />
        </Table>
      )}
    </Modal>
  );
};

export default ModalVoucher;
