/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, Row, Spin, Typography } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import BillPreview from "../../../../../components/BillPreview";
import Capture from "../../../../../components/Capture";
import SummaryPreview from "../../../../../components/SummaryPreview";
import { useQuery } from "../../../../../hooks/dom";
import { getUserObj, MEMBER_API, URL_API, _ } from "../../../../../utils";
import { resCatch } from "../../../../util/helper";
import PreOrderContext from "../../reducer";
import CancelModal from "./CancelModal";
import Consider from "./Consider";
import Payment from "./Payment";
import PendingDetail from "./PendingDetail";
import SendPending from "./SendPending";
import SendPO from "./SendPO";
import SendPR from "./SendPR";

const { Text } = Typography;

const Index = ({ onComplete }) => {
  const query = useQuery();
  const history = useHistory();
  const previewRef = useRef(null);
  const [dataSource, setDataSource] = useState({});
  const [isNormal, setIsNormal] = useState({});
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const {
    state: {
      payment,
      billModal: {
        visible,
        id,
        is_used,
        division_id,
        reserve_order_no,
        status,
        disabled,
      },
      user: { permission_status },
    },
    dispatch,
  } = useContext(PreOrderContext);
  const payment_data = _.map(payment.itemKeys, (key) => payment.itemByKey[key]);
  const total_payment = _.reduce(
    payment_data,
    (res, item) => res + (item.price || 0),
    0
  );
  const balance = order ? order.grand_total_amount - total_payment : 0;
  const reserveId = query.get("reserve-id");

  useEffect(() => {
    axios({ url: `${MEMBER_API}/kbank/config` })
      .then((res) =>
        dispatch({
          type: "SET_GLOBAL_STATE",
          payload: { name: "paymentGateWay", value: res.data },
        })
      )
      .catch((e) => resCatch(e));
  }, []);

  useEffect(() => {
    order &&
      axios({
        url: `${URL_API}/pos/master/member/customer-id`,
        params: {
          partner_code: order.buyer_code,
          partner_type: order.buyer_type,
        },
      })
        .then((res) =>
          dispatch({
            type: "SET_GLOBAL_STATE",
            payload: { name: "kBankCustomerId", value: res.data.data || "" },
          })
        )
        .catch((e) => resCatch(e));
  }, [order]);

  const handleClose = () => {
    if (reserveId) {
      history.push({ pathname: "/pre-order" });
    } else {
      dispatch({
        type: "CLEAR_GLOBAL_STATE",
        payload: "billModal",
      });
    }
  };

  const handleCatchURl = (content) => {
    handleClose();
    Modal.error({
      title: "ไม่สามารถดำเนินการได้",
      content,
      zIndex: 1080,
    });
  };

  const loadBillSelect = async () => {
    setLoading(true);
    let res = null;
    try {
      res = await axios({
        url: `${URL_API}/pos/operation/reserve/${id}`,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleClose();
      return resCatch(e);
    }

    if (!res.data.data) return handleCatchURl("ไม่พบข้อมูล");
    if (res.data.data.orders[0].status !== status)
      return handleCatchURl("บิลที่เลือกถูกดำเนินการไปแล้ว");
    if (!disabled) {
      let err_permission = false;
      if (status === "pending") {
        // call center, Line Order
        err_permission = !permission_status[status];
      } else {
        if (is_used === 0) {
          // New Product: agency (Division)
          const user = getUserObj();
          err_permission = division_id !== user.division_id;
        } else if (is_used === 1) {
          // Sale Product: planning
          err_permission = !permission_status[status];
        }
      }
      if (err_permission) return handleCatchURl("ผู้ใช้งานไม่มีสิทธิดำเนินการ");
    }

    setDataSource(res.data.data);
    setIsNormal(
      !_.includes(["reserve", "pre-redeem"], res.data.data.reserve_type)
    );
    setOrder(
      _.find(
        res.data.data.orders,
        (n) => n.reserve_order_no === reserve_order_no
      )
    );
  };

  useEffect(() => {
    if (id && reserve_order_no && !_.isEmpty(permission_status))
      loadBillSelect();
  }, [id, reserve_order_no, permission_status]);

  const renderFlow = () => {
    if (disabled) return <Capture previewRef={previewRef} />;
    switch (status) {
      case "create":
        return <SendPR onComplete={onComplete} />;
      case "pr":
        return <SendPO onComplete={onComplete} />;
      case "po":
        return <SendPending onComplete={onComplete} />;
      case "pending":
        return (
          <Consider
            onComplete={onComplete}
            data={order?.pre_order || {}}
            balance={balance}
          />
        );
      default:
        return <Capture previewRef={previewRef} />;
    }
  };

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      footer={false}
      zIndex={1080}
      width={1000}
      closeIcon={<FaTimes size={10} />}
      bodyStyle={{ paddingTop: "50px" }}
      destroyOnClose={true}
      onCancel={handleClose}
    >
      <Spin spinning={loading}>
        <div ref={previewRef} className="bg-white">
          <Row className="mb-2 px-2 font-size-12">
            <Col span={6}>
              <Text strong className="mr-2">
                เลขที่ชุดจอง
              </Text>
              <Text>{dataSource.document_no}</Text>
            </Col>
            <Col span={6}>
              <Text strong className="mr-2">
                ผู้ดำเนินการ
              </Text>
              <Text>{dataSource.buyer_name}</Text>
            </Col>
            <Col span={6}>
              <Text strong className="mr-2">
                เบอร์โทร
              </Text>
              <Text>{dataSource.buyer_mobile}</Text>
            </Col>
            <Col span={6}>
              <Text strong className="mr-2">
                ประเภทผู้ดำเนินการ
              </Text>
              <Text>{dataSource.buyer_type}</Text>
            </Col>
          </Row>
          <Row className="bg-gray-60 py-1 px-2">
            <Col span={24}>
              <Text className="text-white">รายการสินค้า</Text>
            </Col>
          </Row>
          {order && (
            <>
              <BillPreview data={order} isNormal={isNormal} />
              <SummaryPreview data={[order]} isNormal={isNormal} />
              <Payment
                reserveRedeem={!isNormal}
                disabled={
                  disabled ||
                  status !== "pending" ||
                  order?.pre_order.have_product !== 1
                }
                data={payment_data}
                balance={balance}
                totalPayment={total_payment}
                buyerCode={order.buyer_code}
                buyerType={order.buyer_type}
              />
            </>
          )}
          {order?.pre_order.have_product === 1 && (
            <PendingDetail preOrder={order?.pre_order} />
          )}
        </div>
        {renderFlow()}
        {!disabled && <CancelModal onComplete={onComplete} />}
      </Spin>
    </Modal>
  );
};

export default Index;
