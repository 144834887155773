/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col, Typography } from "antd";
import ItemBillPreview from "./ItemBillPreview";
import ItemBillPreviewRedeem from "./ItemBillPreviewRedeem";
import { moment, _ } from "../utils";
import { fixDisCount } from "../config/fix-value";
const BillPreview = ({ data = {}, isNormal = true, isStaff = false }) => {
  // let totalDiscount = data.discount?.reduce(
  //   (sum, item) => sum + item.discount_amount,
  //   0
  // );

  let discount = 0;
  let delivery = 0;
  _.forEach(data.discount, (value, key) => {
    if (value.discount_type === fixDisCount.delivery) {
      if (value.discount_amount > data.delivery_amount) {
        delivery += data.delivery_amount;
      } else {
        delivery += value.discount_amount;
      }
    } else {
      discount += value.discount_amount;
    }
  });
  _.forEach(data.voucher_payment, (value, key) => {
    if (value.type === "3") {
      delivery += value.price;
    } else {
      discount += value.price;
    }
    // totalDiscount +=  value.price
  });
  delivery = delivery > data.delivery_amount ? data.delivery_amount : delivery;
  // console.log(totalDiscount,discount,delivery)
  return (
    <>
      <Row gutter={8} style={{ fontSize: "12px" }}>
        <Col>
          <Row className="bg-gray-40 py-1">
            <Col span={9} className="pl-4">
              <Typography.Text
                strong
                className="text-dark"
              >{`${data.reserve_order_no} - ${data.partner_code} ${data.partner_name}`}</Typography.Text>
            </Col>
            <Col span={3} className="text-center">
              <Typography.Text strong className="text-dark">
                ประเภทการรับของ
              </Typography.Text>
            </Col>
            <Col span={2} className="text-center">
              <Typography.Text className="text-dark">
                {data.receive_type.toLowerCase() === "branch" &&
                data.transport_code == null
                  ? `รับที่สาขา`
                  : `จัดส่ง`}
              </Typography.Text>
            </Col>
            <Col span={3} className="text-center">
              <Typography.Text strong className="text-dark">
                ประเภทการสั่งซื้อ
              </Typography.Text>
            </Col>
            <Col span={2} className="text-center">
              <Typography.Text className="text-dark">
                {data.order_type}
              </Typography.Text>
            </Col>
            <Col span={2} className="text-center">
              <Typography.Text strong className="text-dark">
                ใบเสร็จ
              </Typography.Text>
            </Col>
            <Col span={3} className="text-center">
              <Typography.Text className="text-dark">
                {data.req_receipt === 1 ? `รับใบเสร็จ` : `ไม่รับใบเสร็จ`}
              </Typography.Text>
            </Col>
          </Row>
          {data.ar_document_no && (
            <Row className="bg-gray-40 py-1">
              <Col span={3} className="pl-4">
                <Typography.Text strong className="text-dark">
                  เลขที่ใบเสร็จ
                </Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text className="text-dark">
                  {data.ar_document_no}
                </Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text strong className="text-dark">
                  วันที่ออกใบเสร็จ
                </Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text className="text-dark">
                  {data.ar_document_date &&
                    moment(data.ar_document_date).format("DD/MM/YYYY HH:mm:ss")}
                </Typography.Text>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row gutter={8} className="py-1" style={{ fontSize: "10px" }}>
        <Col span={9}>
          <Typography.Text strong className="text-dark pl-4 mr-2">
            ชื่อผู้รับ
          </Typography.Text>
          <Typography.Text className="text-dark">
            {data.ship_to ? data.ship_to : data.partner_name}
          </Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text strong className="text-dark mr-2">
            เบอร์โทรผู้รับ
          </Typography.Text>
          <Typography.Text className="text-dark">
            {data.ship_mobile}
          </Typography.Text>
        </Col>
        <Col span={10}>
          <Typography.Text strong className="text-dark mr-2">
            {data.receive_type.toLowerCase() === "branch" &&
            data.transport_code == null
              ? `สาขาที่รับ`
              : `ที่อยู่จัดส่ง`}
          </Typography.Text>
          <Typography.Text className="text-dark">
            {data.receive_type.toLowerCase() === "branch" &&
            data.transport_code == null
              ? data.branch_name_receive
              : `${data.ship_address_no} ${data.ship_sub_district} ${data.ship_district} ${data.ship_province} ${data.ship_post_code}`}
          </Typography.Text>
        </Col>
      </Row>

      {isNormal ? (
        <ItemBillPreview
          list={data.items}
          deliveryAmount={data.delivery_amount}
          remark={data.remark}
          totalDiscount={data.totalDiscount}
          isStaff={isStaff}
          partner_type={data.partner_type}
        />
      ) : (
        <ItemBillPreviewRedeem
          list={data.items}
          deliveryAmount={data.delivery_amount}
          remark={data.remark}
          totalDiscount={data.totalDiscount}
        />
      )}
    </>
  );
};

export default BillPreview;
