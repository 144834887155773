/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useState } from 'react';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { FiArrowLeft } from 'react-icons/fi';
import { FaCheck, FaPlus, FaTimes, FaUserAlt } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router';
import classConstants from '../constants/classConstants';
import { Button, Table } from 'antd';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import ClassReservationModal from '../components/modal/ClassReservationModal';
import {
  classReservationFormDefaultValues,
  classReservationFormSchema,
} from '../components/dto/class-reservation';
import { AlertConfirm } from '../../../util/Alert';
import fitClassServices from '../../../../services/fit-class.services';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';

const ClassReservationPage = () => {
  const params = useParams();
  const history = useHistory();
  const [classReservationFormOpen, setClassReservationFormOpen] =
    useState(false);
  const {
    data: classReservationLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();

  const classReservationForm = useForm({
    defaultValues: classReservationFormDefaultValues,
    resolver: classReservationFormSchema(),
  });

  const _HandleClassReservationFormOpen = (formType) => {
    classReservationForm.setValue('formType', formType);
    setClassReservationFormOpen(true);
  };

  const _HandleClassReservationFormClose = () => {
    classReservationForm.reset(classReservationFormDefaultValues);
    setClassReservationFormOpen(false);
  };

  const { data: categoryData } = fitClassServices.useQueryGetClassCategoryById({
    classCategoryId: params.classCategoryId,
  });

  const { data: classData } = fitClassServices.useQueryGetClassById({
    classId: params.classId,
  });

  const { data: classScheduleData } =
    fitClassServices.useQuerygetClassScheduleById({
      classScheduleId: params.classScheduleId,
    });

  const { mutate: presentClassReservation } =
    fitClassServices.useMutationPresentClassReservation(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-class-reservation-by-class-schedule-id'],
        refetchType: 'all',
      });
    });

  const { mutate: cancelClassReservation } =
    fitClassServices.useMutationCancelClassReservation(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-class-reservation-by-class-schedule-id'],
        refetchType: 'all',
      });
    });

  const {
    execute: downloadFitnessClassReservationExcelByFitnessClassReservationId,
  } = useAsync(
    fitStudioService.downloadFitnessClassReservationExcelByFitnessClassReservationId,
    {}
  );


    fitClassServices.useQueryGetClassReservationByClassScheduleId(
      {
        classScheduleId: params.classScheduleId,
        page: page,
        take: classConstants.tableSize,
      },
      (data) => {
        setData(data.data);
        setTotal(data.meta.total);
      }
    );


  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'profileImageUrl',
      key: 'profileImageUrl',
      render: (_, record) => {
        if (record?.user?.profileImageUrl) {
          return (
            <img
              key={record.user.profileImageUrl}
              src={record.user.profileImageUrl}
              alt={record.user.profileImageUrl}
              style={{
                width: 50,
                height: 50,
                objectFit: 'cover',
                borderRadius: 25,
              }}
            />
          );
        }

        return (
          <div
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              backgroundColor: '#e0e0e0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FaUserAlt />
          </div>
        );
      },
    },
    {
      title: 'รหัสสมาชิก',
      dataIndex: 'memberNo',
      key: 'memberNo',
      render: (_, record) => {
        return <span>{record.memberNo}</span>;
      },
    },
    {
      title: 'ผู้จอง',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return <span>{record.name}</span>;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'จองโดย',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => {
        return <span>{record.type}</span>;
      },
    },
    {
      title: 'จองเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
  
              <div style={{ display: 'flex', justifyContent: 'center' }}>
              {classScheduleData?.status === 'processing' &&
            record.status === 'reserved' && (
                <div
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  onClick={async () => {
                    const confirm = await AlertConfirm('ปรับสถานะเข้าคลาส');
                    if (confirm) {
                      presentClassReservation({
                        classReservationId: record.id,
                      });
                    }
                  }}
                >
                  <FaCheck />
                </div>  )}
                <div
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  onClick={async () => {
                    const confirm = await AlertConfirm('ปรับสถานะยกเลิกการจองคลาส');
                    if (confirm) {
                      cancelClassReservation({
                        classReservationId: record.id,
                      });
                    }
                  }}
                >
                  <FaTimes />
                </div>
              </div>
          
      ),
    },
  ];
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{categoryData?.title}/</h3>
        <h3>{classData?.title}</h3>
      </div>
      <Panel>
        <PanelHeader>การจองเรียน</PanelHeader>
        <PanelBody>
          <div className="d-flex justify-content-end mb-2">
            <Button
              type="primary"
              className="text-white d-flex align-items-center mr-2"
              icon="file-excel"
              onClick={() => {
                downloadFitnessClassReservationExcelByFitnessClassReservationId(
                  params.classScheduleId
                );
              }}
            >
              Export Excel
            </Button>
            <Button
              type="primary"
              className="text-white d-flex align-items-center"
              onClick={_HandleClassReservationFormOpen}
            >
              <FaPlus className="mr-2" />
              จองคลาสเรียน
            </Button>
          </div>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={classReservationLists}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // history.push(
                  //   `/fit-studio/classcategory/${params.classCategoryId}/classsubcategory/${params.classSubCategoryId}/class/${record.id}`
                  // );
                },
              };
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize: classConstants.tableSize,
              total: total,
            }}
          />
        </PanelBody>
      </Panel>
      <FormProvider {...classReservationForm}>
        <ClassReservationModal
          open={classReservationFormOpen}
          handleClose={_HandleClassReservationFormClose}
        />
      </FormProvider>
    </>
  );
};

export default ClassReservationPage;
