/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, Upload } from 'antdV4';
import fitStudioService from '../../../../../services/fit-studio.service';
import { useState } from 'react';
import publicPlaylistConstants from '../../constants/publicPlaylistConstants';
import useAsync from '../../../../../utils/useAsync';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { useBreakpoint } = Grid;

const VideoUpload = ({ value, onChange }) => {
  const [loading, setLoading] = useState(false);
  const { execute: uploadFile } = useAsync(fitStudioService.uploadDoc, {
    onSuccess: (data) => {
      onChange(data.resourceUrl);
      setLoading(false);
    },
  });
  const screens = useBreakpoint();

  return (
    <Upload.Dragger
      name="file"
      multiple={false}
      beforeUpload={() => false}
      onChange={async (e) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('doc', e.file);
        uploadFile(formData);
      }}
      showUploadList={false}
    >
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <div>
            <Spin indicator={antIcon} />
          </div>
        ) : value ? (
          <video
            src={value}
            alt={value}
            style={{
              width: !!screens.md ? '50%' : '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        ) : (
          <div>
            <p className="ant-upload-text">คลิ๊ก หรือ ดร็อป ไฟล์วิดีโอ</p>
            <p className="ant-upload-text" style={{ color: 'red' }}>
              *อัพโหลดได้เฉพาะชื่อไฟล์ภาษาอังกฤษเท่านั้น*
            </p>
            <p className="ant-upload-text" style={{ color: 'red' }}>
              (ห้ามมีอีกขระพิเศษ)
            </p>
          </div>
        )}
      </div>
    </Upload.Dragger>
  );
};

export default VideoUpload;
