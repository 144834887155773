/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, } from 'react';
import moment from 'moment'

import Layout from '../../../../../../components/Layout';
import ControlPanel from '../../../../../../components/ControlPanel';
import LayoutHead from './layout-head';
import LayoutMenu from './layout-menu';
import LayoutContent from './layout-content';
import LayoutContentLeft from './layout-content-left';
import LayoutContentRight from './layout-content-right';

//
import { objPromotion } from '../state';
import { AlertError, AlertSuccess, AlertConfirm } from '../../../../../util/Alert';
import LoadMasterAllType from '../../../../../util/data/LoadMasterAllType';
import { Http, useHttp } from '../../../../../../hooks/http';
import { URL_API } from '../../../../../../utils';
import { LoadingScreen } from '../../../../../util/Loading';
import { Panel, PanelHeader, PanelBody } from '../../../../../util/panel';
import { Skeleton } from 'antd';

let objMasterData = {
    honor: true,
    branch: true,
    promotion_condition_unit: true,
    promotion_condition_type: true,
    payin_type: true,
    status: true,
    month: true,
    category_type: true,
};
export default (props) => {
    const id = Number(props.match.params.id) || 0;
    const breadcrumb = [
        { link: '/', title: 'หน้าหลัก' },
        { link: null, title: 'ตั้งค่า' },
        { link: null, title: 'กำหนดค่าเริ่มต้น' },
        { link: null, title: 'โปรโมชั่น' },
    ]
    const [errors, setErrors] = useState({});
    const [promotion, setPromotion] = useState(objPromotion);

    const [masterHonor, setMasterHonor] = useState([]);
    const [masterBranch, setMasterBranch] = useState([]);
    const [masterConUnit, setMasterConUnit] = useState([]);
    const [masterConType, setMasterConType] = useState([]);
    const [masterPaymentType, setMasterPaymentType] = useState([]);
    const [masterStatus, setMasterStatus] = useState([]);
    const [masterMonth, setMasterMonth] = useState([]);
    const [masterCategory, setMasterCategory] = useState([]);
    const [loading, setLoading] = useState(false);



    //load Promotion
    const [loadingPromotion, dataPromotion, errorPromotion] = useHttp({
        method: 'GET',
        url: `${URL_API}/system/promotion/${id}`,
        token: true
    }, []);

    useEffect(() => {

        if (dataPromotion && id !== 0) {

            let promotion = dataPromotion.data;

            let source_list = promotion.source_list;
            let free_list = promotion.free_list;
            let product_choice = promotion.product_choice;
            let product_choice_free = promotion.product_choice_free;

            source_list.forEach((item, i) => {
                const { ref_condition_id, number } = item;
                item.id = `${ref_condition_id}-${number}` //-- ใช้อ้างอิงในตารางเลือกสินค้าภายหลัง
                item.errors = {}
                delete item.ref_condition_id;
                source_list.splice(i, 1, item);
            });

            free_list.forEach((item, i) => {
                let { ref_condition_id, number } = item;
                item.id = `${ref_condition_id}-${number}` //-- ใช้อ้างอิงในตารางเลือกสินค้าภายหลัง
                item.errors = {}
                free_list.splice(i, 1, item);
            });
            product_choice.forEach((item, i) => {
                let { ref_condition_id, ref_number } = item;
                item.id = `${ref_condition_id}-${ref_number}` //-- ใช้อ้างอิงในตารางเลือกสินค้าภายหลัง
                product_choice.splice(i, 1, item);
            });
            product_choice_free.forEach((item, i) => {
                let { ref_condition_id, ref_number } = item;
                item.id = `${ref_condition_id}-${ref_number}` //-- ใช้อ้างอิงในตารางเลือกสินค้าภายหลัง
                product_choice_free.splice(i, 1, item);
            });


            promotion = {
                ...promotion,
                source_list,
                free_list,
                product_choice,
                product_choice_free,
                start_date: (promotion.start_date) ? moment(new Date(promotion.start_date)) : '',
                end_date: (promotion.start_date) ? moment(new Date(promotion.end_date)) : ''
            }
            console.log('promotion', promotion)
            setPromotion(promotion)
        }

        if (errorPromotion) {
            console.log('errorPromotion', errorPromotion)
            AlertError({ text: errorPromotion }).then((res) => {
                if (res === true) props.history.replace('/setting/master/promotion');
            });

        };
    }, [dataPromotion, errorPromotion]);

    // LoadMasterData
    let masterData = LoadMasterAllType(objMasterData);
    useEffect(() => {
        if (masterData.honor) {
            if (masterData.honor[1]) setMasterHonor(masterData.honor[1].data);
            if (masterData.honor[2]) AlertError({ text: masterData.honor[2] });
        }
        if (masterData.branch) {
            if (masterData.branch[1]) setMasterBranch(masterData.branch[1].data);
            if (masterData.branch[2]) AlertError({ text: masterData.branch[2] });
        }
        if (masterData.promotion_condition_unit) {
            if (masterData.promotion_condition_unit[1]) setMasterConUnit(masterData.promotion_condition_unit[1].data);
            if (masterData.promotion_condition_unit[2]) AlertError({ text: masterData.promotion_condition_unit[2] });
        }
        if (masterData.promotion_condition_type) {
            if (masterData.promotion_condition_type[1]) setMasterConType(masterData.promotion_condition_type[1].data);
            if (masterData.promotion_condition_type[2]) AlertError({ text: masterData.promotion_condition_type[2] });
        }
        if (masterData.payin_type) {
            if (masterData.payin_type[1]) setMasterPaymentType(masterData.payin_type[1].data);
            if (masterData.payin_type[2]) AlertError({ text: masterData.payin_type[2] });
        }
        if (masterData.status) {
            if (masterData.status[1]) setMasterStatus(masterData.status[1].data);
            if (masterData.status[2]) AlertError({ text: masterData.status[2] });
        }
        if (masterData.month) {
            if (masterData.month[1]) setMasterMonth(masterData.month[1].data);
            if (masterData.month[2]) AlertError({ text: masterData.month[2] });
        }
        if (masterData.category_type) {
            if (masterData.category_type[1]) setMasterCategory(masterData.category_type[1].data);
            if (masterData.category_type[2]) AlertError({ text: masterData.category_type[2] });
        }
    }, [masterData]);



    const handleChange = async (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'deadline':
                let num_value = parseInt(value);
                if (num_value === 0) { //เปิด-ปิด ใช้วันที่เรื่มต้น-สิ้นสุด
                    setPromotion({ ...promotion, [name]: num_value, start_date: '', end_date: '' })
                } else {
                    setPromotion({ ...promotion, [name]: num_value })
                }
                break;

            case 'promotion_type':
                setPromotion({ ...promotion, [name]: value, promotion_code: `${value}-xxxxx` })
                delete errors['promotion_code'];
                break;

            default:

                setPromotion({ ...promotion, [name]: value })
                break;
        }

        if (name === "distributor_sale" || name === "member_sale" || name === "staff_sale") delete errors["sale"];
        if (name === "amount") delete errors.chkAmount;

        delete errors[name];
        setErrors(errors)

    }
    const savePromotion = async () => {
        const invalid = await validation();

        if (invalid) return;
        const confirm = await AlertConfirm();

        if (confirm) {
            setLoading(true);
            const res = await Http({
                method: 'POST',
                url: `${URL_API}/system/promotion/${(id === 0) ? '' : id}`,
                data: promotion,
                token: true
            });
            setLoading(false);
            if (res.status === 200) {
                const success = await AlertSuccess('บันทึกข้อมูลสำเร็จ');
                setLoading(false);
                if (success) props.history.replace('/setting/master/promotion');
            } else {
                const error = await AlertError({ text: res.data.message })
                if (error) {
                    //...
                }
            }
        }
    }
    const validation = async () => {
        console.log(promotion)

        let result = false;
        let err = {}; //

        let verify = {
            empty: [ // ตรวจสอบค่าว่าง
                //รายละเอียดโปรโมชั่น
                'promotion_code',
                'promotion_name',
                'promotion_type',

                //วัน-เวลา
                'start_date',
                'end_date',

                //เงื่อนไขโปรโมชั่น
                'amount_unit',
                'discription',
                'type',
            ],
            zero: [
                'amount'
            ],
            exist: [ //  ตรวจสอบรายการที่ต้องที่ต้องเลือกข้อมูล
                'honor',
                'order_type',
                'distribution',
                'payment_type',
                // 'status_member',
                'free_list',
                'source_list',
            ],

        }

        let newSource = [];
        let newFree = [];


        // เพิ่มรายการตรวจสอบ
        switch (promotion.amount_unit) {
            case 'piece':
                // รายการสินค้า
                verify.exist.push('source_list');
                break;
            case 'bath':

                break;

            case 'pv':
                break;


            case 'rp':
                break;

            default:
                break;
        }


        for (let name in promotion) {
            if (verify.empty.includes(name)) { // รายการที่ต้องตรวจสอบค่าว่าง

                if (promotion[name] === '') { // รายการที่เป็นค่าว่าง
                    err[name] = '*กรุณากรอกข้อมูล';
                }

            }
            if (verify.zero.includes(name)) { // รายการที่ต้องตรวจสอบ != 0
                if (Number(promotion[name]) <= 0) {
                    err[name] = '*กรุณาระบุข้อมูลทีมากกว่า 0';
                }
            }
            if (verify.exist.includes(name)) { // รายการที่ต้องที่ต้องเลือกข้อมูล

                switch (name) {
                    case 'source_list': // เงื่อนไขที่ต้องทำ
                        if (promotion[name].length === 0) {
                            err[name] = '*กรุณาเลือกสินค้า';
                        } else {
                            // ตรวจสอบสินค้าที่ ไม่ระบุภายหลัง และ ไม่มีการเลือกสินค้า
                            // let newSource = [];
                            promotion.source_list.forEach((item) => {
                                const { id, choice, product_code, qty } = item;

                                if (choice === 0) { //  ไม่เลือก ระบุสินค้าภายหลัง
                                    if (!product_code || product_code === '') {
                                        item = { ...item, error: '*กรุณาเลือกสินค้า' };
                                        err[name] = '*กรุณาเลือกสินค้า';
                                    } else {
                                        if (Number(qty) < 1) {
                                            item = { ...item, errorQty: '*กรุณาระบุจำนวนสินค้ามากกว่า 0' }
                                            err[name] = '*กรุณาเลือกสินค้า'; // add err source_list
                                        }
                                    }
                                } else { // ระบุสินค้าภายหลัง
                                    const productChoices = promotion.product_choice.filter(c => { return c.id === id });  // product
                                    if (productChoices.length === 0) { // ไม่มีรายการสินค้าสำหรับ ระบุภายหลัง
                                        item = { ...item, error: '*กรุณาเลือกสินค้า' };
                                        err[name] = '*กรุณาเลือกสินค้า';
                                    } else {
                                        if (Number(qty) < 1) {
                                            item = { ...item, errorQty: '*กรุณาระบุจำนวนสินค้ามากกว่า 0' }
                                            err[name] = '*กรุณาเลือกสินค้า'; // add err source_list
                                        }
                                    }
                                }

                                newSource.push(item);
                            })
                        }
                        break;

                    case 'free_list':

                        if (promotion[name].length === 0) {
                            err[name] = '*กรุณาเลือกสินค้า'; // // add err free_list
                        } else {
                            // ตรวจสอบสินค้าที่ ไม่ระบุภายหลัง และ ไม่มีการเลือกสินค้า
                            // let newFree = [];
                            promotion.free_list.forEach((item, i) => {
                                let newItem = item;
                                let { id, choice, product_code, get_product, get_discount, get_point, get_redeem, discount, discount_unit, discount_type, qty } = newItem;

                                if (get_product === 1) { // เลือกได้รับสินค้า
                                    if (choice === 1) { //เลือกระบุสินค้าภายหลัง
                                        const productChoices = promotion.product_choice_free.filter(c => { return c.id === id });  // product
                                        if (productChoices.length === 0) { // ไม่มีรายการสินค้าสำหรับ ระบุภายหลัง
                                            newItem.errors = { ...newItem.errors, product: '*กรุณาเลือกสินค้า' }
                                            err[name] = '*กรุณาเลือกสินค้า'; // add err free_list
                                        }
                                    } else {
                                        if (!product_code || product_code === '') { //ไม่เลือกสินค้า
                                            newItem.errors = { ...newItem.errors, product: '*กรุณาเลือกสินค้า' }
                                            err[name] = '*กรุณาเลือกสินค้า'; // add err free_list
                                        }
                                    }

                                    if (Number(qty) < 1) {
                                        newItem.errors = { ...newItem.errors, qty: '*กรุณาระบุจำนวนสินค้ามากกว่า 0' }
                                        err[name] = '*กรุณาเลือกสินค้า'; // add err free_list
                                    }

                                }
                                if (get_discount === 1) { // เลือกได้รับส่วนลด
                                    if (Number(discount) === 0) {
                                        newItem.errors = { ...newItem.errors, discount: '*กรุณาระบุจำนวน' }
                                        err[name] = '*กรุณาเลือกสินค้า'; // add err free_list
                                    }
                                    if (discount_unit === '') {
                                        newItem.errors = { ...newItem.errors, discount_unit: '*กรุณาเลือกข้อมูล' }
                                        err[name] = '*กรุณาเลือกสินค้า'; // add err free_list
                                    }
                                    if (discount_type === '') {
                                        newItem.errors = { ...newItem.errors, discount_type: '*กรุณาเลือกข้อมูล' }
                                        err[name] = '*กรุณาเลือกสินค้า'; // add err free_list
                                    }
                                }
                                if (get_point === 1) { // เลือกได้รับคะแนน

                                }
                                if (get_redeem === 1) { // เลือกได้รับแลกซื้อ

                                }
                                newFree.push(newItem);
                            })
                        }

                        break;
                    default:
                        if (promotion[name].length === 0) {
                            err[name] = '*กรุณาเลือกข้อมูล';
                        }
                        break;
                }


            }
        }

        // if (promotion.amount_unit === 'piece') {
        //     let sumQty = 0;
        //     for (let index = 0; index < promotion.source_list.length; index++) {
        //         const { qty } = promotion.source_list[index];
        //         sumQty = Number(sumQty) + Number(qty);
        //     }
        //     if (Number(sumQty) !== Number(promotion.amount)) err.chkAmount = '*กรุณาระบุจำนวนสินค้าให้เท่ากัน'
        // }


        if (newSource.length !== 0 && newFree.length !== 0) {
            setPromotion({ ...promotion, source_list: newSource, free_list: newFree })
        } else if (newSource.length !== 0) {
            setPromotion({ ...promotion, source_list: newSource })
        } else if (newFree.length !== 0) {
            setPromotion({ ...promotion, free_list: newFree })
        }

        if (promotion.start_date > promotion.end_date) { //ตรวจสอบความถูกต้องการเลือกวันเริ่มต้น-สิ้นสุดโปรโมชัน
            err.end_date = '*กรุณาระบุวันเริ่มสิ้นสุดหลังวันเริ่มต้น'
        }

        setErrors(err);
        const existErrors = Object.keys(err).length !== 0;

        if (existErrors) {
            const error = await AlertError({ title: 'กรุณากรอกข้อมูลให้ครบ'})
            if (error) result = true;
        }
        return result;
    }
    return (
        <>
            <Layout
                titlePage="โปรโมชั่น"
                subTitlePage=""
                breadcrumb={breadcrumb}
            >
                <LoadingScreen
                    loading={loading}
                    logoSrc=''
                    text='กำลังบันทึกข้อมูล...'
                >
                    <div></div>
                </LoadingScreen>
                {
                    loadingPromotion ? (
                        <Panel>
                            <PanelHeader>Promotion</PanelHeader>
                            <PanelBody><Skeleton active /></PanelBody>
                        </Panel>
                    ) : <ControlPanel className="bg-body" titlePanel="Promotion">
                            <LayoutHead save={savePromotion} />
                            <LayoutMenu />
                            <LayoutContent>
                                <LayoutContentLeft
                                    handleChange={handleChange}
                                    promotion={promotion}
                                    errors={errors}
                                    masterHonor={masterHonor}
                                    masterConUnit={masterConUnit}
                                    masterConType={masterConType}
                                    masterMonth={masterMonth}
                                    masterCategory={masterCategory}
                                />
                                <LayoutContentRight
                                    handleChange={handleChange}
                                    promotion={promotion}
                                    errors={errors}
                                    masterHonor={masterHonor}
                                    masterBranch={masterBranch}
                                    masterPaymentType={masterPaymentType}
                                    masterStatus={masterStatus}
                                />
                            </LayoutContent>
                        </ControlPanel>
                }
            </Layout>
        </>
    )
}