import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { PageSettings } from "../../../config/page-settings.js";

import KPaymentButton from "../../../components/CreditCardPayment/KPaymentButton.js";
import {
  PaymentMutateProvider,
  usePaymentMutate,
} from "../../../contexts/payment.context.jsx";
import { MEMBER_API, axios } from "../../../utils/index.js";
import Routes from "../../pages/routes";

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [],
    };

    this.kPaymentRef = React.createRef(null);
  }
  componentDidMount() {
    this.isMount = true;
  }
  componentWillUnmount() {
    this.isMount = false;
  }

  render() {
    return (
      <PaymentMutateProvider>
        <PageSettings.Consumer>
          {({
            pageContentFullWidth,
            pageContentClass,
            pageContentInverseMode,
          }) => (
            <div
              className={
                "content " +
                (pageContentFullWidth ? "content-full-width " : "") +
                (pageContentInverseMode ? "content-inverse-mode " : "") +
                pageContentClass
              }
            >
              {/* <RoutesPage /> */}
              <Routes />
              <KButtonWrapper />
              {/* <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={
                    route.path === "/" || route.path.includes("/public", 0)
                      ? route.component // login
                      : requireAuth(route.component)
                  }
                />
              ))}
            </Switch> */}
            </div>
          )}
        </PageSettings.Consumer>
      </PaymentMutateProvider>
    );
  }
}

const KButtonWrapper = () => {
  // const kPaymentRef = useRef();
  const [kConfig, setKConfig] = useState(null);
  const { kPaymentRef } = usePaymentMutate();

  const loadKConfig = useCallback(async () => {
    try {
      const { data } = await axios.get(`/kbank/config`, {
        baseURL: MEMBER_API,
      });
      setKConfig(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    loadKConfig();
  }, [loadKConfig]);

  return kConfig ? (
    <KPaymentButton
      ref={kPaymentRef}
      debug={kConfig.debug}
      hidden
      // action={
      //   state.pay_config.method == "qr"
      //     ? "/payment-completed?order_code=" + router.query.order
      //     : process.env.NEXT_PUBLIC_URL_API +
      //       "/kbank/charge?code=" +
      //       router.query.order
      // }
      // amount={numeral(state.data?.order.grand_total_amount).format(
      //   "0.00"
      // )}
      merchantName={kConfig.merchant_name}
      // merchantId={
      //   state.pay_config.term == "0" || state.pay_config.method == "qr"
      //     ? state.bank_config.merchant_id
      //     : state.bank_config.merchant_id_installment
      // }
      publicKey={kConfig.public_key}
      // onError={(error) => message.error(error)}
      // paymentMethods={state.pay_config.method}
      // smartPayId={state.pay_config.term == "0" ? "" : "0001"}
      // term={state.pay_config.term}
      // customerId={auth?.kbank_customer_id}
    />
  ) : null;
};

export default withRouter(Content);
