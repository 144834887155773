import { Button, Col, message, Modal, Row, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { FaCogs, FaEdit, FaTrash } from "react-icons/fa";
import { useEventMutate } from "../../../../../contexts/events.context";
import { displayTotal } from "../../../../../utils/helper";
import { eventRequest } from "../../../../../utils/useAxios";
import { Link } from "react-router-dom";
import { axios, getUser } from "../../../../../utils";

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export default function TableEvent() {
  const {
    state: { search, fetch, loading, setStorage },
    dispatch,
  } = useEventMutate();

  const loadDataCallback = useCallback(async () => {
    try {
      source.cancel("cancel token");
      source = CancelToken.source();
      dispatch({ type: "SET_LOADING", payload: true });
      const data = await eventRequest.get("/events", {
        params: {
          search: search.query,
          eventId: search.event_id,
          status: search.status,
          eventType: search.event_type,
          ticketType: search.ticket_type,
          page: search.page,
          pageLimit: search.limit,
        },
        cancelToken: source.token,
      });
      dispatch({
        type: "SET_SEARCH_SUCCESS",
        payload: {
          data: data.data?.result?.items || [],
          total: data.data?.result?.pages?.total || 0,
          count: data.data?.result?.count || undefined,
        },
      });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      if (!axios.isCancel(error)) {
        message.error(error.message);
      }
    }
  }, [search, dispatch]);

  const handleDelete = (id) => {
    const user = getUser();
    const data = {
      eventId: id,
      updatedBy: user.user_code,
      updatedByName: user.name,
    };
    Modal.confirm({
      type: "warning",
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการลบงานอีเวนท์นี้หรือไม่?",
      okText: "ลบ",
      okType: "danger",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          eventRequest
            .patch(`/events/delete`, data)
            .then(() => resolve())
            .catch((err) =>
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              )
            );
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ยกเลิกการลงทะเบียนเรียบร้อย",
              okText: "ปิด",
              onOk() {
                loadDataCallback();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback, setStorage]);
  return (
    <Table
      size="small"
      bordered
      loading={loading}
      scroll={{
        x: "max-content",
      }}
      dataSource={fetch.data || []}
      rowKey="Id"
      pagination={{
        total: fetch.total || 0,
        current: search.page,
        showLessItems: true,
        size: "defaule",
        showTotal: displayTotal,
        onChange: (page) => dispatch({ type: "SET_SEARCH", payload: { page } }),
      }}
    >
      <Table.Column
        title="#"
        key="no"
        align="right"
        render={(text, record, index) =>
          (search.page - 1) * search.limit + index + 1
        }
      />
      <Table.Column title="ชื่องาน" key="event_name" dataIndex="EventName" />
      {/* <Table.Column
        title="Banner"
        key="banner"
        align="center"
        dataIndex="EventBanner"
        render={(text, { EventBanner }) => EventBanner &&
          (
            <img src={EventBanner} width={'100px'} height={'35px'} />
          )
        }
      /> */}
      <Table.Column
        title="รูปแบบโปรเจ็กต์"
        key="event_type"
        dataIndex="EventType"
        render={(text, { EventType }) =>
          EventType === "ONLINE" ? "งานออนไลน์" : "งานออฟไลน์"
        }
      />
      <Table.Column
        title="Prefix Serial"
        key="prefix_serial"
        dataIndex="PrefixSerial"
      />
      <Table.Column
        title="ประเภทบัตรงาน"
        key="ticket_type"
        dataIndex="TicketType"
        render={(text, { TicketType }) =>
          TicketType === "FREE" ? "ไม่มีค่าใช้จ่าย" : "บัตรมีราคา"
        }
      />
      <Table.Column
        title="ระยะเวลาการจัดงาน"
        key="event_date_type"
        dataIndex="EventDateType"
        render={(text, { EventDateType }) =>
          EventDateType === "ONE_DAY" ? "วันเดียว" : "มากกว่า 1 วัน"
        }
      />
      <Table.Column
        title="วันที่เริ่มงาน"
        key="start_date"
        align="center"
        dataIndex="StartDate"
        render={(text, { StartDate }) =>
          moment(StartDate).format("DD/MM/YYYY HH:mm")
        }
      />
      <Table.Column
        title="วันที่สิ้นสุดงาน"
        key="end_date"
        align="center"
        dataIndex="EndDate"
        render={(text, { EndDate }) =>
          moment(EndDate).format("DD/MM/YYYY HH:mm")
        }
      />

      <Table.Column
        title="ระยะเวลาการลงทะเบียน"
        key="reg_date_type"
        dataIndex="RegDateType"
        render={(text, { RegDateType }) =>
          RegDateType === "ONE_DAY" ? "วันเดียว" : "มากกว่า 1 วัน"
        }
      />
      <Table.Column
        title="วันที่เริ่มการลงทะเบียน"
        key="reg_start_date"
        align="center"
        dataIndex="RegStartDate"
        render={(text, { RegStartDate }) =>
          moment(RegStartDate).format("DD/MM/YYYY HH:mm")
        }
      />
      <Table.Column
        title="วันที่สิ้นสุดการลงทะเบียน"
        key="reg_end_date"
        align="center"
        dataIndex="RegEndDate"
        render={(text, { RegEndDate }) =>
          moment(RegEndDate).format("DD/MM/YYYY HH:mm")
        }
      />
      <Table.Column
        title="สถานะการลงทะเบียน"
        key="reg_status"
        align="center"
        render={(text, { RegStartDate, RegEndDate }) =>
          new Date(RegStartDate) <= new Date() &&
          new Date(RegEndDate) >= new Date() ? (
            <Tag color="green">เปิดลงทะเบียน</Tag>
          ) : (
            <Tag color="red">ปิดลงทะเบียน</Tag>
          )
        }
      />
      <Table.Column
        title="วันที่เริ่มแสดงผลรายงาน"
        key="rep_start_date"
        align="center"
        dataIndex="ReportStartDate"
        render={(text, { ReportStartDate }) =>
          moment(ReportStartDate).format("DD/MM/YYYY HH:mm")
        }
      />
      <Table.Column
        title="วันที่สิ้นสุดแสดงผลรายงาน"
        key="rep_end_date"
        align="center"
        dataIndex="ReportEndDate"
        render={(text, { ReportEndDate }) =>
          moment(ReportEndDate).format("DD/MM/YYYY HH:mm")
        }
      />
      {/* <Table.Column
        title="สถานะ"
        key="status"
        dataIndex="Status"
        align="center"
        render={(text, { Status }) => Status === 'ACTIVE' ?
          (
            <Tag color="green">ACTIVE</Tag>
          ) : (
            <Tag color="red">DELETED</Tag>
          )
        }
      /> */}
      <Table.Column
        title="สถานะรายงาน"
        key="status"
        dataIndex="status"
        align="center"
        render={(text, { ReportStartDate, ReportEndDate }) =>
          new Date(ReportStartDate) <= new Date() &&
          new Date(ReportEndDate) >= new Date() ? (
            <Tag color="green">แสดงผล</Tag>
          ) : (
            <Tag color="red">ไม่แสดงผล</Tag>
          )
        }
      />
      <Table.Column
        title="ชื่อผู้แก้ไข"
        key="updated_by"
        align="center"
        render={(text, { CreatedByName, UpdatedByName }) =>
          UpdatedByName ? UpdatedByName : CreatedByName
        }
      />
      <Table.Column
        title="วันที่แก้ไขล่าสุด"
        key="updated_at"
        align="center"
        dataIndex="UpdatedAt"
        render={(text, { UpdatedAt }) =>
          moment(UpdatedAt).format("DD/MM/YYYY HH:mm")
        }
      />
      <Table.Column
        title={<FaCogs />}
        key="action"
        align="center"
        render={(text, { Status, Id }) => {
          return (
            Status === "ACTIVE" && (
              <Row gutter={8}>
                <Col span={12}>
                  <Tooltip title="แก้ไขงานอีเวนท์">
                    <Link to={`/events/edit-event/${Id}`}>
                      <Button size="small" type="primary" ghost>
                        <FaEdit />
                      </Button>
                    </Link>
                  </Tooltip>
                </Col>

                <Col span={12}>
                  <Tooltip title="ลบงานอีเวนท์">
                    <Button
                      size="small"
                      type="danger"
                      ghost
                      onClick={() => handleDelete(Id)}
                    >
                      <FaTrash />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            )
          );
        }}
      />
    </Table>
  );
}
