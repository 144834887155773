/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Divider,
  Empty,
  Modal,
  Row,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { fixProductTypeSet } from "../../../../../config/fix-value";
import { URL_API, _, axios } from "../../../../../utils";
import { resCatch, resCatchModal } from "../../../../util/helper";
import { numDec, numInt } from "../../../../util/helper/map-data";
import { Creators } from "../../ducks/operation-form";
import BomDetail from "./BomDetail";
import PromotionDetail from "./PromotionDetail";

const Index = ({ productCode, setVisible, record }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const orderData = useSelector((state) => {
    const {
      orders: { activeKey, itemByKey },
    } = state.pos.operation;
    if (!activeKey) return {};
    const {
      partner_code,
      partner_type,
      order_type,
      req_receipt_type,
      receipt_branch_code,
    } = itemByKey[activeKey];
    return {
      partner_code,
      partner_type,
      order_type,
      req_receipt_type,
      receipt_branch_code,
    };
  });
  const { addOrderItem, delOrderItem } = Creators;

  const type_params = match.params.type;
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );
  const isPreProduct = _.includes(["pre-sale", "pre-redeem"], type_params);

  useEffect(() => {
    productCode && loadLinkBom();
  }, [productCode]);

  const loadLinkBom = async () => {
    setLoading(true);
    try {
      const res = await axios({
        url: `${URL_API}/pos/master/promotion/link`,
        params: {
          ...orderData,
          product_code: productCode,
          order_source: "pos",
        },
      });
      setDataSource(res.data.data);
    } catch (e) {
      resCatchModal(e);
    }
    setLoading(false);
  };

  const renderWarning = (description) => {
    // setLoadingSearch(false);
    notification.warning({
      message: "ไม่สามารถดำเนินการได้",
      description,
    });
  };

  const handlePlaceItem = async (n) => {
    setLoading(true);
    let product = null;
    try {
      const { data } = await axios.get(`/pos/master/item`, {
        baseURL: URL_API,
        params: {
          type_params,
          code: n.barcode,
          partner_type: orderData.partner_type,
          product_type: isNormal ? "normal" : "redeem",
          req_receipt_type: orderData.req_receipt_type,
          receipt_branch_code: orderData.receipt_branch_code,
          is_pre_order: isPreProduct ? 1 : 0,
        },
      });
      product = data?.data;
    } catch (error) {
      setLoading(false);
      resCatch(error);
    }

    if (product) {
      const { stock_balance, product_type_set } = product;
      if (
        !_.includes(
          [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
          product_type_set
        ) &&
        stock_balance <= 0
      ) {
        setLoading(false);

        return renderWarning("ไม่มีสินค้าใน stock");
      }

      if (product.product_type_set === fixProductTypeSet.kit_pack) {
        // if (qr_running && qr_data.failed.store_qr)
        //   return renderWarning("ไม่พบข้อมูล store qr");
        const { bom } = product;
        const bom_qr = bom;
        dispatch(
          addOrderItem({
            check_stock: !isPreProduct,
            product_id: product.id,
            product_code: product.product_code,
            product_name: product.product_name_local,
            product_type: product.product_type,
            product_type_set: product.product_type_set,
            promotion_id: 0,
            bom: bom_qr,
            sku: product.backend_sku_code,
            weight: product.weight,
            unit_price: product.unit_price,
            cv: product.point_03,
            pv: product.point_01,
            rp: product.point_02,
            tp: product.point_04,
            sp: product.point_05,
            ra: product.point_06,
            qty_rate: product.point_01_rate,
            stock_balance: product.stock_balance,
            qr: [],
            barcode: n.barcode,
          })
        );
      } else {
        dispatch(
          addOrderItem({
            check_stock: !isPreProduct,
            product_id: product.id,
            product_code: product.product_code,
            product_name: product.product_name_local,
            product_type: product.product_type,
            product_type_set: product.product_type_set,
            promotion_id: 0,
            bom: product.bom,
            sku: product.backend_sku_code,
            weight: product.weight,
            unit_price: product.unit_price,
            cv: product.point_03,
            pv: product.point_01,
            rp: product.point_02,
            tp: product.point_04,
            sp: product.point_05,
            ra: product.point_06,
            qty_rate: product.point_01_rate,
            stock_balance: product.stock_balance,
            qr: [],
            barcode: n.barcode,
          })
        );
      }

      dispatch(delOrderItem(record?.key));
      setVisible(null);
    } else {
      notification.warning({
        message: "ไม่พบข้อมูลสินค้าตามเงื่อนไข",
        description: "กรุณาตรวจสอบข้อมูลที่หน้าตั้งค่าสินค้า",
      });
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={!!productCode}
      destroyOnClose
      footer={false}
      zIndex={1080}
      width={600}
      onCancel={() => {
        setVisible(null);
        setDataSource([]);
      }}
      title={`(${productCode}) สินค้าจัดเซ็ต`}
    >
      <Spin spinning={loading}>
        <div className="height-600 overflow-y-scroll">
          {!!dataSource.length ? (
            _.map(dataSource, (n, i) => (
              <Row key={n.product_code}>
                {!!i && (
                  <Col span={24}>
                    <Divider />
                  </Col>
                )}
                <Col
                  span={24}
                  className="set-link-bom"
                  onClick={() => handlePlaceItem(n)}
                >
                  <div className="d-flex justify-content-between">
                    <div className="product-name">
                      <Typography.Text strong>
                        {n.product_name_local}
                      </Typography.Text>
                    </div>

                    <div className="product-detail text-right">
                      <strong className="d-block">{`ราคา ${numInt(
                        n.price
                      )} บาท`}</strong>
                      <div
                        className="product-point d-flex align-items-baseline"
                        style={{ gap: "4px" }}
                      >
                        <small className="bg-pv px-1 rounded rounded-pill text-white">{`${numInt(
                          n.point_01
                        )} PV`}</small>
                        <small className="bg-rp px-1 rounded rounded-pill text-white">{`${numInt(
                          n.point_02
                        )} RP`}</small>
                        <small className="bg-ra px-1 rounded rounded-pill text-white">{`${numDec(
                          n.point_06
                        )} RA`}</small>
                      </div>
                    </div>
                  </div>

                  <BomDetail list={n.bom} productCode={productCode} />
                  {!!n.promotion.length && (
                    <PromotionDetail list={n.promotion} />
                  )}
                </Col>
              </Row>
            ))
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default memo(Index);
