/* eslint-disable react-hooks/exhaustive-deps */
import { createReducer } from "@reduxjs/toolkit";
import {
  Button,
  Col,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useReducer } from "react";
import { axios, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";

const loadItem = (data) => {
  return {
    key: data?.id ? data.id : _.uniqueId("delivery-"),
    point_start: 0,
    point_end: 0,
    weight: 0,
    delivery_amount: 0,
    overweight: 0,
    is_edit: false,
    ...data,
  };
};

const initState = {
  delivery_zone: 1,
  loading: false,
  dataSource: {
    itemKeys: [],
    itemByKey: {},
  },
};

const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_LOAD_SUCCESS: (state, { payload }) => {
    let loadData = _.map(payload, (n) => loadItem(n));
    let loadItemKeys = _.map(loadData, "key");
    let loadItemByKey = _.keyBy(loadData, "id");

    state.loading = false;
    state.dataSource.itemKeys = loadItemKeys;
    state.dataSource.itemByKey = loadItemByKey;
  },
  SET_DELIVERY_ZONE: (state, { payload }) => {
    state.delivery_zone = payload;
    state.loading = true;
  },
  ADD_ITEM: (state) => {
    let newItemKeys = [...state.dataSource.itemKeys];
    let newItemByKey = { ...state.dataSource.itemByKey };

    let newItem = loadItem({ is_edit: true });
    newItemKeys.push(newItem.key);
    newItemByKey[newItem.key] = newItem;

    state.dataSource.itemKeys = newItemKeys;
    state.dataSource.itemByKey = newItemByKey;
  },
  SET_ITEM_VALUE: (state, { payload: { key, ...value } }) => {
    state.dataSource.itemByKey[key] = {
      ...state.dataSource.itemByKey[key],
      ...value,
    };
  },
  DEL_ITEM: (state, { payload }) => {
    let delItemKeys = [...state.dataSource.itemKeys];
    let delItemByKey = { ...state.dataSource.itemByKey };

    delItemKeys = _.filter(delItemKeys, (n) => n !== payload);
    delItemByKey = _.omit(delItemByKey, [payload]);

    state.dataSource.itemKeys = delItemKeys;
    state.dataSource.itemByKey = delItemByKey;
  },
});

const Delivery = () => {
  const [state, dispatch] = useReducer(reducer, initState);

  const handleChangeZone = (delivery_zone) => {
    dispatch({ type: "SET_DELIVERY_ZONE", payload: delivery_zone });

    axios({
      method: "get",
      url: `${URL_API}/setting/master/delivery`,
      params: { delivery_zone },
    })
      .then((res) => {
        dispatch({ type: "SET_LOAD_SUCCESS", payload: res.data.data });
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING", payload: false });
        message.error(
          err && err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.response.data
            : err.message
        );
      });
  };

  const handleClickSave = (key) => {
    let data = state.dataSource.itemByKey[key];
    if (data) {
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ยกเลิก",
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/setting/master/delivery${
                data.id ? `/${data.id}` : ""
              }`,
              data: {
                ...data,
                delivery_zone: state.delivery_zone,
              },
            })
              .then((res) => {
                resolve(res.data);
              })
              .catch((err) => {
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then((value) => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกข้อมูลเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  handleChangeZone(state.delivery_zone);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    } else {
      message.warning("ไม่พบข้อมูลที่ต้องการบันทึก กรุณาทำรายการใหม่อีกครั้ง");
    }
  };

  useEffect(() => {
    handleChangeZone(state.delivery_zone);
  }, []);

  return (
    <Panel>
      <PanelHeader>ค่าจัดส่ง</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col>
            <Select
              value={state.delivery_zone}
              onChange={(e) => handleChangeZone(e)}
              style={{ width: "200px" }}
            >
              <Select.Option value={1}>กรุงเทพฯและปริมณฑล</Select.Option>
              <Select.Option value={0}>ต่างจังหวัด</Select.Option>
            </Select>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          loading={state.loading}
          dataSource={state.dataSource.itemKeys.map(
            (n) => state.dataSource.itemByKey[n]
          )}
          pagination={false}
        >
          <Table.ColumnGroup title="คะแนน PV">
            <Table.Column
              align="center"
              title="จาก"
              dataIndex="point_start"
              render={(value, { is_edit, key }) =>
                is_edit ? (
                  <InputNumber
                    min={0}
                    step={1}
                    value={value}
                    className="w-100"
                    onChange={(e) => {
                      dispatch({
                        type: "SET_ITEM_VALUE",
                        payload: { key, point_start: e },
                      });
                    }}
                  />
                ) : (
                  value
                )
              }
            />
            <Table.Column
              align="center"
              title="ถึง"
              dataIndex="point_end"
              render={(value, { is_edit, key }) =>
                is_edit ? (
                  <InputNumber
                    min={0}
                    step={1}
                    value={value}
                    className="w-100"
                    onChange={(e) => {
                      dispatch({
                        type: "SET_ITEM_VALUE",
                        payload: { key, point_end: e },
                      });
                    }}
                  />
                ) : (
                  value
                )
              }
            />
          </Table.ColumnGroup>
          <Table.Column
            align="center"
            title="ราคา"
            dataIndex="delivery_amount"
            render={(value, { is_edit, key }) =>
              is_edit ? (
                <InputNumber
                  min={0}
                  step={1}
                  value={value}
                  className="w-100"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_ITEM_VALUE",
                      payload: { key, delivery_amount: e },
                    });
                  }}
                />
              ) : (
                value
              )
            }
          />
          <Table.Column
            align="center"
            title={
              <>
                น้ำหนักไม่เกิน
                <br />
                (กิโลกรัม)
              </>
            }
            dataIndex="weight"
            render={(value, { is_edit, key }) =>
              is_edit ? (
                <InputNumber
                  min={0}
                  step={1}
                  value={value}
                  className="w-100"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_ITEM_VALUE",
                      payload: { key, weight: e },
                    });
                  }}
                />
              ) : (
                value
              )
            }
          />
          <Table.Column
            align="center"
            title={
              <>
                น้ำหนักส่วนเกิน
                <br />
                (บาท/กิโลกรัม)
              </>
            }
            dataIndex="overweight"
            render={(value, { is_edit, key }) =>
              is_edit ? (
                <InputNumber
                  min={0}
                  step={1}
                  value={value}
                  className="w-100"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_ITEM_VALUE",
                      payload: { key, overweight: e },
                    });
                  }}
                />
              ) : (
                value
              )
            }
          />
          <Table.Column
            align="center"
            title={
              <Button onClick={() => dispatch({ type: "ADD_ITEM" })}>
                เพิ่ม
              </Button>
            }
            dataIndex="key"
            className="text-nowrap"
            render={(value, { is_edit, id }) =>
              is_edit ? (
                <>
                  <Button
                    className="bg-pv border-pv text-white"
                    onClick={() => handleClickSave(value)}
                  >
                    บันทึก
                  </Button>
                  <Button
                    className="bg-reds border-reds text-white ml-2"
                    onClick={() => {
                      if (id) {
                        dispatch({
                          type: "SET_ITEM_VALUE",
                          payload: { key: value, is_edit: false },
                        });
                      } else {
                        dispatch({ type: "DEL_ITEM", payload: value });
                      }
                    }}
                  >
                    ยกเลิก
                  </Button>
                </>
              ) : (
                <Button
                  className="bg-rp border-rp text-white"
                  onClick={() =>
                    dispatch({
                      type: "SET_ITEM_VALUE",
                      payload: { key: value, is_edit: true },
                    })
                  }
                >
                  แก้ไข
                </Button>
              )
            }
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default Delivery;
