import { Col, Row, Typography, Divider } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { _, moment } from "../../../../../utils";
import { mapItemKeyToArray } from "../../../../util/helper/map-data";

const { Text } = Typography;

const HistoryConsider = () => {
  const considerHistory = useSelector((state) =>
    mapItemKeyToArray(state.requisition.historyConsider)
  );
  if (considerHistory.length === 0) return null;
  return (
    <>
      <Divider />
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Text strong className="font-size-14">
            ประวัติผู้อนุมัติ
          </Text>
        </Col>
      </Row>
      {_.map(considerHistory, (n) => (
        <Row gutter={[8, 8]} key={n.key}>
          <Col span={24} xxl={18}>
            <Row gutter={[8, 8]}>
              <Col span={2} className="text-right">
                <Text strong>{n.consider_user_type} : </Text>
              </Col>
              <Col span={4}>
                <Text>{n.create_by}</Text>
              </Col>
              <Col span={2} className="text-right">
                <Text>รายละเอียด : </Text>
              </Col>
              <Col span={8}>
                <Text>{n.consider_remark}</Text>
              </Col>
              <Col span={2} className="text-right">
                <Text>ผลพิจารณา : </Text>
              </Col>
              <Col span={2}>
                <Text>{n.consider_status_text}</Text>
              </Col>
              <Col span={1} className="text-right">
                <Text>วันที่ : </Text>
              </Col>
              <Col span={3}>
                <Text>
                  {moment(n.create_date).format("DD/MM/YYYY HH:mm:ss")}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default HistoryConsider;
