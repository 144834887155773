import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from 'antdV4';
import { Row, Col, Input } from 'antd';
import { ErrorMessage } from '@hookform/error-message';

const RegisterPapersForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Controller
        name="endDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={6}>
                <Typography.Text>จำนวนวันที่สิ้นสุด</Typography.Text>
              </Col>
              <Col span={14}>
                <Input value={value} onChange={onChange} />
              </Col>
              <Col span={4}>
                <Typography.Text className="ml-2">วัน</Typography.Text>
              </Col>
              <ErrorMessage
                errors={errors}
                name="endDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default RegisterPapersForm;
