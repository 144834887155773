import { useState } from 'react';

const usePaginationHook =() => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  return { page, setPage, total, setTotal, loading, setLoading, data, setData };
};

export default usePaginationHook;
