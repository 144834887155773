/* eslint-disable no-unused-vars */
import { Col, Modal, Row, Spin, Typography } from "antd";
import numeral from "numeral";
import React, { useRef,useState } from "react";
import { FaTimes } from "react-icons/fa";
import { _ } from "../utils";
import PaymentItem from "../views/pages/reserve/components/PaymentItem";
import BillPreview from "./BillPreview";
import Capture from "./Capture";
import SummaryPreview from "./SummaryPreview";

const Preview = ({
  visible = true,
  loading = false,
  width = 1000,
  onCancel,
  data = {},
  ...props
}) => {
  // console.log(data)
  const previewRef = useRef(null);
  const isStaff = data.buyer_type?.toLowerCase() === "staff";
  const reserveRedeem = _.includes(
    ["reserve", "pre-redeem"],
    data.reserve_type
  );
  const isNormal = _.includes(
    ["sale", "subscription", "pre-sale"],
    data.reserve_type
  );
 
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={false}
      zIndex={1081}
      width={width}
      closeIcon={<FaTimes size={10} />}
      bodyStyle={{ paddingTop: "50px" }}
      destroyOnClose={true}
      {...props}
    >
      <Spin spinning={loading}>
        <div ref={previewRef} className="bg-white">
          <Row gutter={8} style={{ fontSize: "12px" }} className="mb-2">
            <Col>
              <Typography.Text
                strong
                className="text-dark pl-4"
              >{`เลขที่ชุดจอง ${data.document_no}`}</Typography.Text>
            </Col>
          </Row>
          <Row gutter={8} style={{ fontSize: "12px" }} className="mb-2">
            <Col span={3}>
              <Typography.Text strong className="text-dark pl-4">
                ผู้ดำเนินการ
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Typography.Text className="text-dark">
                {data.buyer_name}
              </Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text strong className="text-dark">
                เบอร์โทร
              </Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text className="text-dark">
                {data.buyer_mobile}
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Typography.Text strong className="text-dark">
                ประเภทผู้ดำเนินการ
              </Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text className="text-dark">
                {data.buyer_type}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col>
              <Typography.Paragraph
                style={{ fontSize: "14px" }}
                className="bg-gray-60 text-white py-1 px-4 mb-0"
              >
                รายการสินค้า
              </Typography.Paragraph>
            </Col>
          </Row>

          {_.map(data.orders, (n, i) => (
            <BillPreview
              key={i}
              data={n}
              isStaff={isStaff}
              isNormal={isNormal}
            />
          ))}

          <SummaryPreview
            data={data.orders || []}
            reserveRedeem={reserveRedeem}
            isNormal={isNormal}
          
          />

          <Payment
            orders={data.orders}
            payments={data.payments}
            document_status={data.document_status}
            payments_voucher={data.payments_voucher}
            reserveRedeem={reserveRedeem}
          />

          {data.document_status === "Cancel" && (
            <CancelDetail
              remarkText={data.remark_text}
              description={data.description}
            />
          )}
        </div>
        <Capture previewRef={previewRef} />
      </Spin>
    </Modal>
  );
};

const Payment = ({ orders, payments,document_status,payments_voucher, reserveRedeem }) => {
  const total_amount = _.reduce(
    orders,
    (sum, n) => sum + n.total_amount,
    0
    );
  let totalDiscount = orders?.reduce(
    (sum, item) => sum + (item.totalDiscount-item.delivery_amount),
    0
  );
  const total_payment = _.reduce(payments_voucher, (sum, n) => sum + n.price, 0);
  const max_now_amount =total_amount-totalDiscount-total_payment;
   let dataPayments = _.includes(
    ["Pending"],
    document_status
  );
  return (
    <>
      <Row gutter={8} className="mt-3">
        <Col>
          <Typography.Paragraph
            style={{ fontSize: "16px" }}
            className="bg-pv text-white py-1 px-4 mb-0"
          >
            {reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน"}
          </Typography.Paragraph>
        </Col>
      </Row>

      <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
        <Col className="pl-4 text-right" span={21}>
          <Typography.Text strong className="text-dark mr-3">
            รวมทุกบิล
          </Typography.Text>
        </Col>
        <Col className="pr-4 text-right" span={3}>
          <Typography.Text strong className="text-dark">
            {numeral(total_amount-totalDiscount).format("0,0.00")}
          </Typography.Text>
        </Col>
      </Row>

      <Row gutter={8} className="px-4 mb-2" style={{ fontSize: "14px" }}>
        <Col span={5} className="text-center">
          <Typography.Text strong>วันที่และเวลาชำระ</Typography.Text>
        </Col>
        <Col span={3} className="text-center">
          <Typography.Text strong>ประเภทการชำระ</Typography.Text>
        </Col>
        <Col span={5} className="text-center">
          <Typography.Text strong>ธนาคาร</Typography.Text>
        </Col>
        <Col span={4} className="text-center">
          <Typography.Text strong>เลขที่อ้างอิง</Typography.Text>
        </Col>
        <Col span={4} className="text-center">
          <Typography.Text strong>จำนวน</Typography.Text>
        </Col>
      </Row>

      {_.map(!dataPayments? payments : payments_voucher, (n, i) => (
        <PaymentItem key={i} data={{ ...n, commit: true }} status="complete" />
      ))}

      <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
        <Col className="pl-4 text-right" span={21}>
          <Typography.Text strong className="text-dark mr-3">
            คงเหลือ
          </Typography.Text>
        </Col>
        <Col className="pr-4 text-right" span={3}>
          <Typography.Text strong className="text-dark">
            {numeral(max_now_amount).format("0,0.00")}
          </Typography.Text>
        </Col>
      </Row>

      <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
        <Col className="pl-4 text-right" span={21}>
          <Typography.Text strong className="text-dark mr-3">
            {reserveRedeem ? "รวมคะแนนที่หัก" : "รวมเงินที่รับชำระ"}
          </Typography.Text>
        </Col>
        <Col className="pr-4 text-right" span={3}>
          <Typography.Text strong className="text-dark">
            {numeral(total_payment).format("0,0.00")}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

const CancelDetail = ({ remarkText, description }) => (
  <>
    <Row gutter={8} className="mt-3">
      <Col>
        <Typography.Paragraph
          style={{ fontSize: "16px" }}
          className="bg-nn text-white py-1 px-4 mb-0"
        >
          เหตุผลการยกเลิก
        </Typography.Paragraph>
      </Col>
    </Row>
    <Row gutter={8} className="mt-2">
      <Col span={24} className="px-4">
        <Typography.Text strong className="mr-2">
          หมายเหตุ:
        </Typography.Text>
        <Typography.Text>{remarkText}</Typography.Text>
      </Col>
    </Row>
    <Row gutter={8} className="mb-3">
      <Col span={24} className="px-4">
        <Typography.Text className="font-size-12">
          {description}
        </Typography.Text>
      </Col>
    </Row>
  </>
);

export default Preview;
