import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertError, AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import fitQuizServices from '../../../../../services/fit-quiz.services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import LegacyQuizCategoryProductQuestionForm from '../form/LegacyQuizCategoryProductQuestionForm';

const LegacyQuizCategoryProductQuestionModal = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLegacyQuizCategoryProductQuestion } =
    fitQuizServices.useMutationCreateLegacyQuizCategoryProductQuestion(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category-product-question'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    });

  const { mutate: editLegacyQuizCategoryProductQuestion } =
    fitQuizServices.useMutationUpdateLegacyQuizCategoryProductQuestion(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category-product-question'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    });

  const _HandleSubmit = handleSubmit(async (data) => {
    const checkedAnswer = data.answers.some((item) => item.isCorrect);
    if (!checkedAnswer) {
      await AlertError({ title: 'กรุณาเลือกคำตอบที่ถูกต้อง' });
    } else {
      const legayQuizCategoryyProductQuestionData = {
        question: data.question,
        imageUrl: data.imageUrl,
        active: data.active,
        productId: params.productId,
        answers: data.answers,
      };

      if (watch('formType') === 'create') {
        createLegacyQuizCategoryProductQuestion(
          legayQuizCategoryyProductQuestionData
        );
      } else if (watch('formType') === 'edit') {
        editLegacyQuizCategoryProductQuestion({
          id: data.id,
          data: legayQuizCategoryyProductQuestionData,
        });
      }
    }
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'เพิ่มคำถาม' : 'แก้ไขคำถาม'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyQuizCategoryProductQuestionForm />
    </Modal>
  );
};

export default LegacyQuizCategoryProductQuestionModal;
