/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, DatePicker, Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import fitLegacyServices from '../../../../../services/fit-legacy.services';
import ImageUpload from '../../../fit-studio/components/upload/ImageUpload';
import {
  legacyPopUpCallBackUrlType,
  legacyPopUpType,
} from '../dto/legacy-pop-up.dto';

const LegacyPopUpForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  fitLegacyServices.useQueryGetLegacyPopUpById(
    { legacyPopUpId: watch('id') },
    (data) => {
      reset({
        ...data,
        startDate: moment(data.startDate),
        endDate: moment(data.endDate),
        formType: 'edit',
      });
    }
  );

  return (
    <div>
      <Controller
        name="imageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={450}
                  height={600}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="type"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  ประเภท Pop Up<span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {legacyPopUpType.map((item) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="type"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="callBackUrlType"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  ประเภท Action<span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {legacyPopUpCallBackUrlType.map((item) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="callBackUrlType"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="callBackUrl"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ลิงก์ปลายทาง</Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="legacyapp://home"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="callBackUrl"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่เริ่มต้น <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่เริ่มต้น"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="endDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่สิ้นสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default LegacyPopUpForm;
