import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
  loading: false,
  page: "product",
  current: 1,
  limit: 10,
};

const bannerProductCategorySlice = createSlice({
  name: "bannerProductCategory",
  initialState: INIT_STATE,
  reducers: {
    SET_LOADING: (state, { payload }) => {
      state.loading = payload;
    },
    SET_PAGE: (state, { payload }) => {
      state.page = payload.page;
      state.current = payload.current;
    },
    SET_CURRENT: (state, { payload }) => {
      state.current = payload;
    },
  },
});

export const actionMWBannerProduct = bannerProductCategorySlice.actions;

const bannerProductReducer = bannerProductCategorySlice.reducer;

export default bannerProductReducer;
