/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, message, Modal } from "antd";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import RoleContext from "../../../../../../contexts/role.context";
import { getCancelToken } from "../../../../../../hooks/http2";
import { getToken, URL_API } from "../../../../../../utils";

const ModalRoleName = ({ form }) => {
  const { state, dispatch } = useContext(RoleContext);
  
  let source = getCancelToken();

  const submit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        dispatch({ type: "SET_SAVING", payload: true });

        axios({
          baseURL: URL_API,
          url: `setting/permission/role/${state.modal.data ? state.modal.data.id : ""}`,
          method: "post",
          headers: { Authorization: "Bearer " + getToken() },
          cancelToken: source.token,
          data: {
            ...values,
          },
        })
          .then((res) => {
            dispatch({ type: "SET_SAVING", payload: false });
            dispatch({ type: "SET_SEARCHING", payload: true });
            closeModal();
          })
          .catch((err) => {
            dispatch({ type: "SET_SAVING", payload: false });
            message.error(
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message
            );
          });
      }
    });
  };

  const closeModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: { visible: false, title: "", data: null },
    });
  };

  useEffect(() => {
    form.resetFields();
    if (state.modal.visible && state.modal.data && form) {
      form.setFieldsValue({
        ...state.modal.data,
      });
    }

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [state.modal.visible]);

  const { getFieldDecorator } = form;
  return (
    <Modal
      title={state.modal.title}
      visible={state.modal.visible}
      onOk={submit}
      maskClosable={false}
      confirmLoading={state.saving}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onCancel={closeModal}
    >
      <Form wrapperCol={{ md: 18 }} labelCol={{ md: 6 }}>
        <Form.Item label="ชื่อ">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "กรุณาระบุชื่อสิทธิ์การใช้งาน",
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const WrapperForm = Form.create({ name: "form_role" })(ModalRoleName);

export default WrapperForm;
