/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { useCallback, useEffect } from "react";
import { FaDownload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import SelectMasterAllType from "../../../components/SelectMasterAllType";
import UploadFileList from "../../../components/UploadFileList";
import { fixDepartment } from "../../../config/fix-value";
import { useQuery } from "../../../hooks/dom";
import { actionInventoryCount } from "../../../redux/slices/inventory-count";
import { actionUser } from "../../../redux/slices/user";
import {
  getInventoryCountByDocumentNo,
  getInventoryCountStock,
} from "../../../redux/thunk/inventory-count";
import { getUserPermission } from "../../../redux/thunk/user";
import { getUserObj, moment, _ } from "../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import ButtonClear from "./components/ButtonClear";
import ButtonReport from "./components/ButtonReport";
import ButtonSave from "./components/ButtonSave";
import Count from "./components/Count";
import InventoryTab from "./components/InventoryTab";
import ModalDifference from "./components/ModalDifference";
import SelectOracleCategory from "./components/SelectOracleCategory";
import SelectOracleSubCategory from "./components/SelectOracleSubCategory";

const { Text } = Typography;

const mapExcelData = (data, property) =>
  _.map(data, (item) =>
    _.reduce(property, (res, n) => ({ ...res, [n.name]: item[n.key] }), {})
  );

const {
  setGlobalState,
  clear,
  clearStock,
  setSearchWarehouse,
  addStockImage,
  delStockImage,
  setRemarkWarehouse,
} = actionInventoryCount;
const { clearPermission } = actionUser;

const Manage = ({ match, history }) => {
  const user = getUserObj();
  const type_params = match.params.type;
  const document_no_params = match.params.document_no;
  const dispatch = useDispatch();
  const query = useQuery();
  const {
    warehouse,
    document: { document_no, document_date, document_status },
    searchDocument: { branch_code, branch_name },
    media,
    remark,
    isNotFound,
    excelData,
    fetch: {
      stock: { loading: stock_loading, error: stock_error },
      document: { loading: document_loading, error: document_error },
    },
    ...inventoryCount
  } = useSelector((state) => state.inventoryCount);
  const user_permission = useSelector((state) => state.user.permission);
  const warehouseData = inventoryCount[warehouse.activeKey];
  const back_url = `/${_.split(match.path, "/")[1]}/${type_params}`;
  const type_text = type_params === "daily" ? "ประจำวัน" : "ประจำรอบ";
  const warehouse_text = _.includes(
    ["primary_set", "primary"],
    warehouse.activeKey
  )
    ? "คลังดี"
    : warehouse.itemByKey[warehouse.activeKey].name;
  const disabledDraft =
    warehouse.itemByKey[warehouse.activeKey].status === "pending";
  const is_preview = query.get("preview") === "1";
  const document_remark = remark[warehouse.activeKey]?.remark || '' ;
  useEffect(() => {
    return () => {
      dispatch(clearPermission());
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      const type = arr[2];
      const id = arr[3];
      if (path !== "inventory-count" || type !== type_params) {
        dispatch(clear());
      } else if (id === undefined) {
        dispatch(clearStock());
      }
    };
  }, []);

  useEffect(() => {
    if (document_status && !is_preview) {
      dispatch(
        getUserPermission({
          document_type: "inventory-count",
          document_status: document_status,
          user_id: user.id,
        })
      );
    }
  }, [document_status, is_preview]);

  const handleCatchURl = (content) => {
    dispatch(clearStock());
    history.push({ pathname: back_url });
    Modal.error({
      title: "ไม่สามารถดำเนินการได้",
      content,
      zIndex: 1080,
    });
  };

  useEffect(() => {
    if (isNotFound) handleCatchURl("ไม่พบข้อมูลการนับคลังสินค้า");
  }, [isNotFound]);

  useEffect(() => {
    if (
      document_no_params !== "0" &&
      !is_preview &&
      document_status &&
      user_permission !== null
    ) {
      if (!user_permission)
        handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูล");
    }
  }, [document_status, is_preview, document_no_params, user_permission]);

  // useEffect(() => {
  //   // disable status draft and return when user is operation support
  //   if (
  //     !is_preview &&
  //     _.includes(["draft", "return"], document_status) &&
  //     user.department_id === fixDepartment.operation_support
  //   )
  //     handleCatchURl(
  //       "ผู้ใช้งานแผนก operation support ไม่มีสิทธิ์ในการจัดการข้อมูล"
  //     );
  // }, [document_status, is_preview]);

  useEffect(() => {
    if (document_no_params === "0") {
      if (!branch_code) {
        history.push({ pathname: back_url });
      } else {
        // load stock create
        dispatch(getInventoryCountStock({ branch_code }));
      }
    }
  }, [branch_code, document_no_params]);

  useEffect(() => {
    if (document_no_params !== "0") {
      // load document
      dispatch(
        getInventoryCountByDocumentNo({ document_no: document_no_params })
      );
    }
  }, [document_no_params]);

  const handleClickExportExcel = () => {
    const { itemKeys, itemByKey } = warehouseData;
    let init_count = {
      shelf: "",
      storage_shelf: "",
      warehouse: "",
      remark: "",
    };
    let property_data = [
      {
        name: "รหัสสินค้า",
        key: "product_code",
        wch: 8,
      },
      {
        name: "ชื่อสินค้า",
        key: "product_name",
        wch: 50,
      },
      {
        name: "หน้าร้าน",
        key: "shelf",
        wch: 8,
      },
      {
        name: "ชั้นพัก",
        key: "storage_shelf",
        wch: 8,
      },
      {
        name: "ในคลัง",
        key: "warehouse",
        wch: 8,
      },
    ];
    if (type_params === "cycle") {
      init_count = {
        ...init_count,
        lot: "",
        mfg_date: "",
        exp_date: "",
      };
      property_data = [
        ...property_data,
        {
          name: "Lot.",
          key: "lot",
          wch: 10,
        },
        {
          name: "วันผลิต",
          key: "mfg_date",
          wch: 10,
        },
        {
          name: "วันหมดอายุ",
          key: "exp_date",
          wch: 10,
        },
      ];
    }
    property_data = [
      ...property_data,
      {
        name: "หมายเหตุ",
        key: "remark",
        wch: 20,
      },
    ];
    // const data = _.reduce(
    //   itemKeys,
    //   (res, key) => {
    //     const { product_code, product_name } = itemByKey[key];
    //     return itemByKey[key].is_default === 1
    //       ? [
    //           ...res,
    //           {
    //             product_code,
    //             product_name,
    //             ...init_count,
    //           },
    //         ]
    //       : res;
    //   },
    //   []
    // );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(mapExcelData(excelData, property_data));
    ws["!cols"] = _.map(property_data, ({ wch }) => ({ wch }));
    XLSX.utils.book_append_sheet(wb, ws, warehouse_text);
    XLSX.writeFile(
      wb,
      `inc-${type_params}-${warehouse.activeKey}-${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.xlsx`
    );
  };

  const handleClickBack = () => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1">
            กลับสู่หน้าหลักนับคลังสินค้า
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        history.push({ pathname: back_url });
      },
    });
  };

  const handleChangeImage = useCallback(
    (value) => dispatch(addStockImage(value)),
    []
  );
  const handleDeleteImage = useCallback(
    (value) => dispatch(delStockImage(value)),
    []
  );

  if (stock_error || document_error)
    return (
      <Alert type="error" showIcon message={stock_error || document_error} />
    );

  return (
    <Panel>
      <PanelHeader>การนับคลังสินค้า ({type_text})</PanelHeader>
      <PanelBody>
        <Spin spinning={stock_loading || document_loading}>
          <InventoryTab
            loading={warehouseData.status === "loading"}
            dataSource={_.map(
              warehouse.itemKeys,
              (key) => warehouse.itemByKey[key]
            )}
            value={warehouse.activeKey}
            onChange={(activeKey) =>
              dispatch(
                setGlobalState({ name: "warehouse", value: { activeKey } })
              )
            }
          />
          <Row gutter={[8, 8]} type="flex" className="mt-2">
            <Col
              span={24}
              order={2}
              xl={{ span: 14, order: 1 }}
              xxl={{ span: 16, order: 1 }}
              className="d-flex"
            >
              <Text className="mr-2">ใบนับคลังสินค้า</Text>
              <UploadFileList
                size="small"
                acceptPDF
                disabled={is_preview || document_status === "completed"}
                files={media}
                onChange={handleChangeImage}
                onDelete={handleDeleteImage}
              />
            </Col>
            <Col
              span={24}
              order={1}
              xl={{ span: 10, order: 2 }}
              xxl={{ span: 8, order: 2 }}
              className="d-flex justify-content-end"
            >
              <Button
                type="primary"
                className="mr-3"
                onClick={handleClickExportExcel}
              >
                <FaDownload className="mr-2" />
                Template
              </Button>

              <div>
                <div className="d-flex">
                  <div className="mr-3">
                    <Text strong className="mr-1">
                      สาขา :
                    </Text>
                    <Text>{branch_name}</Text>
                  </div>
                  <div>
                    <Text strong className="mr-1">
                      เลขที่เอกสาร :
                    </Text>
                    <Text>{document_no}</Text>
                  </div>
                </div>
                <div>
                  <Text strong className="mr-1">
                    วันที่ :
                  </Text>
                  <Text>
                    {document_date &&
                      moment(document_date).format("DD/MM/YYYY HH:mm:ss")}
                  </Text>
                </div>
              </div>
            </Col>
          </Row>
          <Divider className="my-2" />
          <Row gutter={[8, 8]} type="flex" className="align-items-center mb-2">
            <Col span={24} xl={8}>
              <Input.Search
                size="small"
                allowClear
                placeholder="รหัสสินค้า, ชื่อสินค้า"
                value={warehouseData.search.query}
                onChange={(e) =>
                  dispatch(
                    setSearchWarehouse({
                      query: e.target.value,
                      page: 1,
                    })
                  )
                }
              />
            </Col>
            <Col span={0} xl={1} className="text-right">
              <Text className="text-nowrap">Category</Text>
            </Col>
            <Col span={24} md={8} xl={4} className="d-flex align-items-center">
              <SelectOracleCategory
                withAll
                size="small"
                className="w-100"
                value={warehouseData.search.oracle_category}
                onChange={(oracle_category) =>
                  dispatch(
                    setSearchWarehouse({
                      oracle_category,
                      oracle_sub_category: "",
                      page: 1,
                    })
                  )
                }
              />
            </Col>
            <Col span={0} xl={2} className="text-right">
              <Text className="text-nowrap">Sub Category</Text>
            </Col>
            <Col span={24} md={8} xl={4} className="d-flex align-items-center">
              <SelectOracleSubCategory
                withAll
                size="small"
                className="w-100"
                categoryId={warehouseData.search.oracle_category}
                value={warehouseData.search.oracle_sub_category}
                onChange={(oracle_sub_category) =>
                  dispatch(
                    setSearchWarehouse({
                      oracle_sub_category,
                      page: 1,
                    })
                  )
                }
              />
            </Col>
            <Col span={0} xl={1} className="text-right">
              <Text className="text-nowrap">สถานะ</Text>
            </Col>
            <Col span={24} md={8} xl={4} className="d-flex align-items-center">
              <SelectMasterAllType
                withAll
                size="small"
                processType="product_status"
                value={warehouseData.search.status}
                onChange={(status) =>
                  dispatch(
                    dispatch(
                      setSearchWarehouse({
                        status,
                        page: 1,
                      })
                    )
                  )
                }
              />
            </Col>
          </Row>

          <Count />
          { warehouse.activeKey !== "primary_set" && (
            <Row className="mb-2">
              <Col span={24}>
              <Text>หมายเหตุ</Text>
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  maxLength={500}
                  value={document_remark}
                  disabled={disabledDraft}
                  onChange={(e) =>dispatch(setRemarkWarehouse(e.target.value)) }
                />
              </Col>
            </Row>
          )}
          
          {warehouse.activeKey !== "primary_set" && (
            <Row className="mt-2">
              <Col span={24} className="d-flex justify-content-between">
                <Button
                  className="bg-tp border-tp text-white"
                  onClick={() =>
                    dispatch(
                      setGlobalState({
                        name: "modalDifference",
                        value: {
                          visible: true,
                          warehouse_name:
                            warehouse.itemByKey[warehouse.activeKey].name,
                        },
                      })
                    )
                  }
                >
                  ยอดผลต่าง ({warehouse_text})
                </Button>
                {!is_preview &&
                  _.includes([null, "draft", "return"], document_status) && (
                    <>
                      {disabledDraft ? (
                        <ButtonSave type="edit" />
                      ) : (
                        <ButtonSave type="confirm" />
                      )}
                    </>
                  )}
              </Col>
            </Row>
          )}

          {_.map(remark.itemKeys, (key) => (
            <Row gutter={[16, 16]} key={key}>
              <Col span={24} xl={12} className="d-flex">
                <Text strong className="mr-2">
                  หมายเหตุ
                </Text>
                <Text>{remark.itemByKey[key].remark}</Text>
              </Col>
              <Col span={24} xl={6}>
                <Text strong className="mr-2">
                  ผู้ตรวจสอบ :
                </Text>
                <Text>{remark.itemByKey[key].create_by}</Text>
              </Col>
              <Col span={24} xl={6}>
                <Text strong className="mr-2">
                  วันที่ :
                </Text>
                <Text>
                  {remark.itemByKey[key].create_date &&
                    moment(remark.itemByKey[key].create_date).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                </Text>
              </Col>
            </Row>
          ))}

          {!is_preview && document_status === "pending" && (
            <Row className="mt-3">
              <Col span={24}>
                <Text>หมายเหตุ</Text>
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  maxLength={500}
                  value={remark.new_remark}
                  onChange={(e) =>
                    dispatch(
                      setGlobalState({
                        name: "remark",
                        value: { new_remark: e.target.value },
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )}

          <Row className="mt-2">
            <Col span={24} className="text-center">
              <Button
                ghost
                type="primary"
                className="width-100 mr-3"
                onClick={handleClickBack}
              >
                ย้อนกลับ
              </Button>
              {warehouse.activeKey === "primary_set" && (
                <Button
                  type="primary"
                  className="width-100"
                  onClick={() =>
                    dispatch(
                      setGlobalState({
                        name: "warehouse",
                        value: { activeKey: "primary" },
                      })
                    )
                  }
                >
                  ถัดไป
                </Button>
              )}
              {!is_preview && warehouse.activeKey !== "primary_set" && (
                <>
                  {_.includes([null, "draft", "return"], document_status) && (
                    <ButtonClear disabled={disabledDraft} />
                  )}
                  {document_status === "pending" ? (
                    <ButtonReport type="retry" />
                  ) : (
                    _.includes([null, "draft", "return"], document_status) && (
                      <ButtonSave type="draft" />
                    )
                  )}
                  {document_status === "pending" && (
                    <ButtonReport type="approve" />
                  )}

                  {_.includes([null, "draft", "return"], document_status) && (
                    <ButtonReport type="report" />
                  )}
                </>
              )}
            </Col>
          </Row>
          <ModalDifference />
        </Spin>
      </PanelBody>
    </Panel>
  );
};

export default Manage;
