/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Button, Col, Empty, Row, Spin, Typography, notification } from "antd";
import { Space } from "antdV4";
import parse from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import img from "../../../../assets/img/pos/no-img.jpg";
import UploadFile from "../../../../components/UploadFile";
import { URL_API, _, axios } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { numDec, numInt } from "../../../util/helper/map-data";
import { Creators } from "../ducks/operation-form";
import FullTabs from "./FullTabs";
import ModalControl from "./ModalControl";

const Profile = ({ data }) => {
  if (!data) {
    return <Empty />;
  }

  return (
    <div>
      <Typography.Title level={3} className="m-0">
        {data.product_name_en}
      </Typography.Title>
      <Typography.Title level={4} className="m-0">
        {data.product_name_local ? `(${data.product_name_local})` : ""}
      </Typography.Title>

      <p className="mt-3">
        รหัส {data.product_code}
        <br />
        หมวดสินค้า {data.category_name}
      </p>

      <u>ปริมาณ</u>
      <p>{data.volume}</p>

      <u>เลขที่ใบจดแจ้ง</u>
      <p>{data.fda}</p>

      <u>หมายเลขฮาลาล</u>
      <p>{data.halal}</p>
    </div>
  );
};

const Price = ({ data }) => {
  if (!data) {
    return <Empty />;
  }

  return (
    <div className="pr-5">
      <div className="px-3 pt-3 pb-3 mb-3 bg-gray-20 rounded rounded-lg">
        <p className="bg-gray-60 text-center rounded rounded-pill text-white">
          <strong>ราคา</strong>
        </p>

        <table className="w-100 text-dark">
          <tbody>
            <tr className="border-bottom">
              <td className="pl-2 py-1 w-100">
                <strong>ราคาปลีก</strong>
              </td>
              <td className="bg-gray-40 text-right pr-2 pl-5 text-nowrap">
                <strong>{`${numDec(data.retail_price)} บาท`}</strong>
              </td>
            </tr>
            <tr className="border-bottom">
              <td className="pl-2 py-1 w-100">
                <strong>ราคาสมาชิกนักธุรกิจ</strong>
              </td>
              <td className="bg-gray-40 text-right pr-2 pl-5 text-nowrap">
                <strong>{`${numDec(data.distributor_price)} บาท`}</strong>
              </td>
            </tr>
            <tr className="border-bottom">
              <td className="pl-2 py-1 w-100">
                <strong>ราคาสมาชิก VIP Member</strong>
              </td>
              <td className="bg-gray-40 text-right pr-2 pl-5 text-nowrap">
                <strong>{`${numDec(data.member_price)} บาท`}</strong>
              </td>
            </tr>
            <tr className="border-bottom">
              <td className="pl-2 py-1 w-100">
                <strong>ราคาควบคุม</strong>
              </td>
              <td className="bg-gray-40 text-right pr-2 pl-5 text-nowrap">
                <strong>{`${numDec(data.control_price)} บาท`}</strong>
              </td>
            </tr>
            <tr className="border-bottom">
              <td className="pl-2 py-1 w-100">
                <strong>ราคาแนะนำ</strong>
              </td>
              <td className="bg-gray-40 text-right pr-2 pl-5 text-nowrap">
                <strong>{`${numDec(data.cost_price)} บาท`}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="px-3 pt-3 pb-3 mb-2 bg-pv-light rounded rounded-lg">
        <p className="bg-pv text-center rounded rounded-pill text-white">
          <strong>คะแนนสะสม</strong>
        </p>
        <Row gutter={16}>
          <Col span={12}>
            <table className="w-100 text-dark">
              <tbody>
                <tr className="border-bottom border-dark">
                  <td className="pl-2 py-1">
                    <span className="circle-point bg-pv text-white">PV</span>
                  </td>
                  <td className="text-right pr-2">
                    <strong>{numDec(data.point_01)}</strong>
                  </td>
                </tr>
                <tr className="border-bottom border-dark">
                  <td className="pl-2 py-1">
                    <span className="circle-point bg-rp text-white">RP</span>
                  </td>
                  <td className="text-right pr-2">
                    <strong>{numDec(data.point_02)}</strong>
                  </td>
                </tr>
                <tr className="border-bottom border-dark">
                  <td className="pl-2 py-1">
                    <span
                      className="circle-point text-white"
                      style={{ background: "#04C8F8" }}
                    >
                      EP
                    </span>
                  </td>
                  <td className="text-right pr-2">
                    <strong>{numDec(data.point_09)}</strong>
                  </td>
                </tr>
                {/* <tr className="border-bottom border-dark">
                  <td className="pl-2 py-1">
                    <span className="circle-point bg-ra text-white">RA</span>
                  </td>
                  <td className="text-right pr-2">
                    <strong>{numDec(data.point_06)}</strong>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </Col>
          <Col span={12}>
            <table className="w-100 text-dark">
              <tbody>
                {/* <tr className="border-bottom border-dark">
                  <td className="pl-2 py-1">
                    <span
                      className="circle-point text-white"
                      style={{ background: "#FF3FBE" }}
                    >
                      BP
                    </span>
                  </td>
                  <td className="text-right pr-2">
                    <strong>{numDec(data.point_05)}</strong>
                  </td>
                </tr> */}
                <tr className="border-bottom border-dark">
                  <td className="pl-2 py-1">
                    <span
                      className="circle-point text-white"
                      style={{ background: "#7856FF" }}
                    >
                      TP
                    </span>
                  </td>
                  <td className="text-right pr-2">
                    <strong>{numDec(data.point_07)}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const Property = ({ data }) => {
  if (!data) {
    return <Empty />;
  }

  return (
    <div>
      <u>รายละเอียดผลิตภัณฑ์</u>
      <p>{parse(data.product_description_local)}</p>

      <u>วิธีการทาน / วิธีการใช้</u>
      <p>{data.product_manual_local}</p>

      <u>ส่วนประกอบ</u>
      <p>{data.product_ingredient}</p>

      <u>คำเตือน</u>
      <p>{data.caution}</p>

      <u>ข้อมูลอื่นๆ</u>
      <p>{data.fyi}</p>

      <u>ลิงก์ที่เกี่ยวข้อง</u>
      <p>{data.link}</p>

      <u>คู่มือ</u>
      {data.manual?.id && (
        <p className="mt-1">
          <UploadFile disabled file={data.manual} />
        </p>
      )}
    </div>
  );
};

const Stock = ({ data }) => {
  return (
    <table className="w-100 text-dark">
      <thead>
        <tr className="bg-gray-60">
          <th className="text-center text-white font-weight-normal">สาขา</th>
          <th className="text-center text-white font-weight-normal">
            พร้อมขาย
          </th>
          <th className="text-center text-white font-weight-normal">ยอดจอง</th>
        </tr>
      </thead>
      <tbody>
        {data.stock.map((n, i) => (
          <tr key={i} className={`${i % 2 === 1 ? "bg-gray-20" : ""}`}>
            <td className="text-left py-1 px-4">{n.branch_name}</td>
            <td className="text-right py-1 px-4">{numInt(n.quantity)}</td>
            <td className="text-right py-1 px-4">
              {numInt(n.reserve_quantity)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ItemDetailPopup = ({
  product_code,
  visible = false,
  onCancel,
  DataSelectProduct,
  setShowDetailProduct = () => {},
  ...props
}) => {
  const [tabActive, setTabActive] = useState("profile");
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState(null);
  const [imgErr, setImgErr] = useState(null);
  const [selectIndex, setSelectIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const type_params = props.match.params.type;
  const isPreProduct = _.includes(["pre-sale", "pre-redeem"], type_params);
  useEffect(() => {
    if (visible) {
      axios({
        url: `${URL_API}/pos/master/item/detail`,
        params: { product_code },
      })
        .then((res) => setDetail(res.data.data))
        .catch((err) => resCatch(err))
        .finally(() => setLoading(false));
      setSelectIndex(0);
      setHoverIndex(-1);
    }
  }, [visible, product_code]);
  const onSelectProduct = (e) => {
    const { itemKeys, itemByKey } = props.order_item;
    if (isPreProduct && itemKeys.length === 1) {
      if (
        DataSelectProduct.product_code !== itemByKey[itemKeys[0]].product_code
      )
        return notification.warning({
          message: "ไม่สามารถดำเนินการได้",
          description: "Pre Order สินค้าได้ ครั้งละ 1 รายการ",
        });
    }
    let find_product = _.find(
      props.order_item.itemByKey,
      (n) => n.product_code === DataSelectProduct.product_code
    );
    if (find_product) {
      if (DataSelectProduct.product_type === "PT04") {
        return notification.warning({
          message: "ไม่สามารถเพิ่มจำนวนสินค้า",
          description: "ต่ออายุซื้อได้แค่ 1 ชุดเท่านั้น",
        });
      }
      if (DataSelectProduct?.course_ralc) {
        return notification.warning({
          message: "ไม่สามารถเพิ่มจำนวนสินค้า",
          description: "Course RALC ซื้อได้แค่ 1 Course เท่านั้น",
        });
      }
    }
    if (DataSelectProduct) {
      props.addOrderItem({
        check_stock: !isPreProduct,
        product_id: DataSelectProduct.id,
        product_code: DataSelectProduct.product_code,
        product_name: DataSelectProduct.product_name_en,
        product_type: DataSelectProduct.product_type,
        product_type_set: DataSelectProduct.product_type_set,
        promotion_id: 0,
        bom: DataSelectProduct.bom,
        sku: DataSelectProduct.backend_sku_code,
        weight: DataSelectProduct.weight,
        unit_price: DataSelectProduct.unit_price,
        cv: DataSelectProduct.point_03,
        pv: DataSelectProduct.point_01,
        rp: DataSelectProduct.point_02,
        rtb: DataSelectProduct.point_04,
        bp: DataSelectProduct.point_05,
        ra: DataSelectProduct.point_06,
        tp: DataSelectProduct.point_07,
        ep: DataSelectProduct.point_09,
        img_url: DataSelectProduct.img_url,
        qty_rate: DataSelectProduct.point_01_rate,
        stock_balance: DataSelectProduct.stock_balance,
        category_parent: DataSelectProduct.category_parent || [],
        course_ralc: DataSelectProduct.course_ralc,
      });
      setShowDetailProduct(false);
    }
  };
  return (
    <ModalControl
      loading={loading}
      visible={visible}
      footerLine={false}
      width={1000}
      destroyOnClose={true}
      onCancel={() => {
        setTabActive("profile");
        setLoading(true);
        setDetail(null);
        setImgErr(null);
        onCancel();
      }}
      {...props}
    >
      <Spin spinning={loading}>
        <Row>
          <Col className="px-5">
            <FullTabs
              list={[
                { value: "profile", title: "โปรไฟล์" },
                { value: "price", title: "ราคา" },
                { value: "property", title: "คุณสมบัติ" },
                { value: "stock", title: "Stock" },
              ]}
              active={tabActive}
              onClick={(e) => setTabActive(e)}
            />
          </Col>
        </Row>
        <Row gutter={16} type="flex" className="mt-5 pr-5">
          <Col
            md={3}
            className="d-flex align-items-center border-right"
            align="middle"
          >
            <Space direction="vertical">
              {detail?.images.map((item, index) => (
                <img
                  src={imgErr ? imgErr : item?.azure_url || img}
                  alt={`product-${index}`}
                  className="w-100 mb-2"
                  onMouseOver={() => {
                    setSelectIndex(index);
                    setHoverIndex(index);
                  }}
                  onMouseOut={() => {
                    setHoverIndex(-1);
                  }}
                  onClick={() => setSelectIndex(index)}
                  style={{
                    border: "1px solid",
                    borderRadius: "8px",
                    borderColor: `${
                      selectIndex == index ? "#4b5563" : "#e5e7eb"
                    }`,
                  }}
                />
              ))}
            </Space>
          </Col>
          <Col
            md={7}
            className="d-flex align-items-center border-right"
            align="middle"
          >
            <Space direction="vertical">
              <img
                src={
                  imgErr
                    ? imgErr
                    : detail?.images[selectIndex]?.azure_url || img
                }
                alt="product"
                className="w-100"
                onError={() => setImgErr(img)}
              />
              <Button
                className="text-white width-150"
                onClick={onSelectProduct}
                type="primary"
              >
                + เพิ่มสินค้า
              </Button>
            </Space>
          </Col>
          <Col md={14} className="pl-5">
            {tabActive === "profile" ? (
              <Profile data={detail} />
            ) : tabActive === "price" ? (
              <Price data={detail} />
            ) : tabActive === "property" ? (
              <Property data={detail} />
            ) : tabActive === "stock" ? (
              <Stock data={detail} />
            ) : null}
          </Col>
        </Row>
      </Spin>
    </ModalControl>
  );
};
const mapStateToProps = (state) => {
  const {
    orders: { activeKey, itemByKey },
  } = state.pos.operation;
  return {
    order_item: itemByKey[activeKey]?.items || null,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    addOrderItem: Creators.addOrderItem,
    setMatchingPromotionItem: Creators.setMatchingPromotionItem,
  })(ItemDetailPopup)
);
// export default ItemDetailPopup;
