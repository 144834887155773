/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Table } from 'antd';
import useAsync from '../../../../utils/useAsync';
import fitStudioService from '../../../../services/fit-studio.service';
import { useHistory } from 'react-router';

const BodyFocusTagPage = () => {
  const history = useHistory();

  const { data: bodyFocusTagLists } = useAsync(
    fitStudioService.getAllBodyFocusTag,
    {
      immediate: true,
    }
  );

  const columns = [
    // {
    //   title: 'รูปภาพ',
    //   dataIndex: 'thumbnailImageUrl',
    //   key: 'thumbnailImageUrl',
    //   render: (_, record) => (
    //     <img
    //       key={record.thumbnailImageUrl}
    //       src={record.thumbnailImageUrl}
    //       alt={record.thumbnailImageUrl}
    //       style={{
    //         width: 50,
    //         height: 50,
    //         objectFit: 'cover',
    //         borderRadius: 10,
    //       }}
    //     />
    //   ),
    // },
    {
      title: 'หัวข้อ',
      dataIndex: 'title',
      key: 'title',
    },
    // {
    //   title: 'สร้างเมื่อ',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (_, record) => (
    //     <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
    //   ),
    // },
    // {
    //   title: 'อัพเดทเมื่อ',
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (_, record) => (
    //     <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
    //   ),
    // },
    // {
    //   title: 'การมองเห็น',
    //   dataIndex: 'enable',
    //   key: 'enable',
    //   render: (_, record) => <span>{record.enable ? 'เปิด' : 'ปิด'}</span>,
    // },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (_, record) => (
    //     <div
    //       style={{ display: 'flex', justifyContent: 'center' }}
    //       onClick={(e) => {
    //         e.stopPropagation();
    //       }}
    //     >
    //       {record.enable ? (
    //         <div
    //           style={{ cursor: 'pointer', marginLeft: 10 }}
    //           onClick={async () => {
    //             const confirm = await AlertConfirm('ปิดการมองเห็นหมวดหมู่');
    //             if (confirm)
    //               setClassCategoryEnable({
    //                 id: record.id,
    //                 isEnable: false,
    //               });
    //           }}
    //         >
    //           <FaEyeSlash />
    //         </div>
    //       ) : (
    //         <div
    //           style={{ cursor: 'pointer', marginLeft: 10 }}
    //           onClick={async () => {
    //             const confirm = await AlertConfirm('เปิดการมองเห็นหมวดหมู่');
    //             if (confirm)
    //               setClassCategoryEnable({
    //                 id: record.id,
    //                 isEnable: true,
    //               });
    //           }}
    //         >
    //           <FaEye />
    //         </div>
    //       )}
    //       <div
    //         style={{ cursor: 'pointer', marginLeft: 10 }}
    //         onClick={() => {
    //           classCategoryForm.setValue('id', record.id);
    //           _HandleClassCategoryFormOpen('edit');
    //         }}
    //       >
    //         <FaPen />
    //       </div>
    //       <div
    //         style={{ cursor: 'pointer', marginLeft: 10 }}
    //         onClick={async () => {
    //           const confirm = await AlertConfirm('ลบหมวดหมู่');
    //           if (confirm) deleteClassCategory({ id: record.id });
    //         }}
    //       >
    //         <MdDelete />
    //       </div>
    //     </div>
    //   ),
    //   width: 150,
    // },
  ];

  return (
    <Panel>
      <PanelHeader>Body Focus Tag</PanelHeader>
      <PanelBody>
        {/* <div className="d-flex justify-content-end mb-2">
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleClassCategoryFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มหมวดหมู่
          </Button>
        </div> */}
        <Table
          scroll={{ x: true }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`body-focus/${record.id}`);
              }, // click row
            };
          }}
          columns={columns}
          dataSource={bodyFocusTagLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          pagination={false}
        />
      </PanelBody>
    </Panel>
  );
};

export default BodyFocusTagPage;
