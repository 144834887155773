/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, notification, Row, Skeleton, Typography } from "antd";
import React, { memo, useCallback, useEffect, useState } from "react";
import { MdImage } from "react-icons/md";
import UploadFileList from "../../../../../components/UploadFileList";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import { mapFileList } from "../../../../util/helper/file";
import { mapDataToItemKey } from "../../../../util/helper/map-data";

const { Text } = Typography;

const initFile = { itemDel: [], itemKeys: [], itemByKey: {} };

const UploadBtn = ({ refId, disabled = false, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(_.cloneDeep(initFile));
  const is_change =
    file.itemDel.length > 0 ||
    _.some(file.itemKeys, (key) => file.itemByKey[key].temp);

  const handleClose = () => {
    setVisible(false);
    setLoading(false);
    setFile(_.cloneDeep(initFile));
  };

  const loadMediaFile = async () => {
    setLoading(true);
    try {
      const res = await axios({
        url: `${URL_API}/pos/operation/reserve/payment/img`,
        params: { ref_id: refId },
      });
      if (res.data.data.length)
        setFile({
          ...mapDataToItemKey(res.data.data),
          itemDel: [],
        });
      else setFile(_.cloneDeep(initFile));
    } catch (e) {
      setVisible(false);
      resCatchModal(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible && refId) loadMediaFile();
  }, [refId, visible]);

  const handleChangeImg = useCallback((value) => {
    const key = _.uniqueId("file-");
    setFile((prevState) => ({
      ...prevState,
      itemKeys: [...prevState.itemKeys, key],
      itemByKey: { ...prevState.itemByKey, [key]: { ...value } },
    }));
  }, []);

  const handleDeleteImg = useCallback(
    (value) =>
      setFile((prevState) => {
        const { id, temp } = prevState.itemByKey[value];
        let itemDel = [...prevState.itemDel];
        if (!temp) itemDel = [...itemDel, id];
        const itemKeys = _.filter(prevState.itemKeys, (key) => key !== value);
        const itemByKey = _.omit(prevState.itemByKey, [value]);
        return {
          itemDel,
          itemKeys,
          itemByKey,
        };
      }),
    []
  );

  const handleOk = () => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1 text-pv">
            ยืนยัน
          </Text>
          <Text>แนบรูปหลักฐานการชำระเงินหรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1081,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/pos/operation/reserve/payment/img`,
            headers: {
              Authorization: "Bearer " + getToken(),
            },
            data: { ...mapFileList(file), ref_id: refId },
          })
            .then(() => {
              resolve();
              handleClose();
              notification.success({
                message: "บันทึกแนบรูปหลักฐานการชำระเงินแล้ว",
              });
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  const handleCancel = () => {
    if (!is_change) return handleClose();
    Modal.confirm({
      title: "ตรวจพบการแก้ไข",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1 text-nn">
            ยกเลิก
          </Text>
          <Text>แนบรูปหลักฐานการชำระเงินหรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1081,
      onOk() {
        handleClose();
      },
    });
  };

  return (
    <>
      <MdImage
        size={16}
        className="cursor-pointer mr-2"
        onClick={() => setVisible(true)}
      />
      <Modal
        title="แนบรูปหลักฐานการชำระเงิน"
        visible={visible}
        width={600}
        destroyOnClose
        zIndex={1081}
        closable={false}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        okButtonProps={{ disabled: !is_change }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <UploadFileList
                withCamera
                files={file}
                disabled={disabled}
                onChange={handleChangeImg}
                onDelete={handleDeleteImg}
                {...props}
              />
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default memo(UploadBtn);
