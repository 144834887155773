import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import fitQuizServices from '../../../../../services/fit-quiz.services';
import LegacyQuizCategoryProductForm from '../form/LegacyQuizCategoryProductForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const LegacyQuizCategoryProductModal = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLegacyQuizCategoryProduct } =
    fitQuizServices.useMutationCreateLegacyQuizCategoryProduct(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category-product'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    });

  const { mutate: editLegacyQuizCategoryProduct } =
    fitQuizServices.useMutationUpdateLegacyQuizCategoryProduct(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category-product'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    });

  const _HandleSubmit = handleSubmit((data) => {
    const legayQuizCategoryProductData = {
      imageUrl: data.imageUrl,
      title: data.title,
      display: data.display,
      rewardId: data.rewardId,
      categoryId: params.categoryId,
    };

    if (watch('formType') === 'create') {
      createLegacyQuizCategoryProduct(legayQuizCategoryProductData);
    } else if (watch('formType') === 'edit') {
      editLegacyQuizCategoryProduct({
        id: data.id,
        data: legayQuizCategoryProductData,
      });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create' ? 'เพิ่มผลิตภัณฑ์' : 'แก้ไขผลิตภัณฑ์'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyQuizCategoryProductForm />
    </Modal>
  );
};

export default LegacyQuizCategoryProductModal;
