import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../../../Error404";
import ProductContext, * as product_context from "./context/product-context";
import PrintBarcode from "./PrintBarcode";
import ProductForm from "./ProductForm";
import ProductList from "./ProductList";

export default ({ match }) => {
  const [stateProduct, dispatchProduct] = useReducer(
    product_context.reducer,
    product_context.initState
  );

  return (
    <Switch>
      <ProductContext.Provider value={{ stateProduct, dispatchProduct }}>
        <Route exact path={match.path} component={ProductList} />
        {/* <Route exact path={`${match.path}/old`} component={ProductLists} /> */}
        <Route
          exact
          path={`${match.path}/barcode/:barcode`}
          component={PrintBarcode}
        />
        <Route exact path={`${match.path}/:id`} component={ProductForm} />
        {/* <Route path={`${match.path}/:id/old`} component={Manage} /> */}
        {/* <Route path={`${match.path}/manage`} component={ManageProduct} /> */}
      </ProductContext.Provider>
      <Route component={Error404} />
    </Switch>
  );
};
