import { Col, Row, Typography } from 'antd';
import React from 'react';
import styles from '../../../../../scss/module/legal.module.scss';
const title_color = {
    color : "#40a5a0"
}
const RegisterRecord = ({ data = [], title, divider }) => {
    return (
        <div className={`${styles.row} ${divider ? styles.divider : ''}`}>
            {
                title && (
                    <Row className="mb-2">
                        <Col span={24}>
                            <Typography.Text strong >{title}</Typography.Text>
                        </Col>
                    </Row>
                )
            }
            <Row>
                {
                    data.map((item, index) => (
                        <Col key={index} span={12} lg={6} xl={3} className="d-flex flex-column">
                            <Typography.Text style={title_color}>{item.name}</Typography.Text>
                            <Typography.Text>{item.value}</Typography.Text>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}

export default RegisterRecord;