/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import { useHistory } from 'react-router';
import fitPrizesServices from '../../../../services/fit-prizes.services';
import LegacyPrizesModal from '../components/modal/LegacyPrizesModal';
import {
  legacyPrizesFormDefaultValues,
  legacyPrizesFormSchema,
} from '../components/dto/legacy-prizes.dto';

dayjs.locale('th');

const pageSize = 10;

const LegacyPrizesPage = () => {
  const [legacyPrizesFormOpen, setLegacyPrizesFormOpen] = useState(false);
  const {
    data: legacyPrizesLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();

  const legacyPrizesForm = useForm({
    defaultValues: legacyPrizesFormDefaultValues,
    resolver: legacyPrizesFormSchema(),
  });

  const _HandleLegacyPrizesFormOpen = (formType) => {
    legacyPrizesForm.setValue('formType', formType);
    setLegacyPrizesFormOpen(true);
  };

  const _HandleLegacyPrizesFormClose = () => {
    legacyPrizesForm.reset(legacyPrizesFormDefaultValues);
    setLegacyPrizesFormOpen(false);
  };

  fitPrizesServices.useQueryGetLegacyPrizesLists(
    { take: pageSize, page },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyPrizes } =
    fitPrizesServices.useMutationDeleteLegacyPrizes(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-prizes'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyyPrizesActive } =
    fitPrizesServices.useMutationUpdateLegacyPrizesActive(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-prizes'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <div>
          <img
            alt={record.title}
            src={record.imageUrl}
            style={{
              width: 100,
              height: 100,
            }}
          />
        </div>
      ),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => <span>{record?.type}</span>,
    },
    {
      title: 'สถานะ',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => <span>{record?.active ? 'แสดง' : 'ไม่แสดง'}</span>,
    },
    {
      title: 'วันที่เริ่ม',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => (
        <span>{dayjs(record.startDate).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.startDate).diff(dayjs(b.startDate), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record) => (
        <span>{dayjs(record.endDate).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.endDate).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => <span>{record.active ? 'เปิด' : 'ปิด'}</span>,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.active ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการแสดง Pop up');
                if (confirm)
                  updateLegacyyPrizesActive({ id: record.id, active: false });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการแสดง Pop up');
                if (confirm)
                  updateLegacyyPrizesActive({ id: record.id, active: true });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyPrizesForm.setValue('id', record.id);
              _HandleLegacyPrizesFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyPrizes({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Legacy Prizes</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <div style={{ flex: 1 }} />
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleLegacyPrizesFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่ม Legacy Prizes
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={legacyPrizesLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(
                  `/notification-setting/legacy-prizes/${record.id}`
                );
              },
            };
          }}
        />
      </PanelBody>
      <FormProvider {...legacyPrizesForm}>
        <LegacyPrizesModal
          open={legacyPrizesFormOpen}
          handleClose={_HandleLegacyPrizesFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default LegacyPrizesPage;
