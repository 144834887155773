/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, notification, Row, Tabs, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadFileList from "../../../../../components/UploadFileList";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";
import BomTable from "./BomTable";
import HeaderDetail from "./HeaderDetail";
import ProductDetail from "./ProductDetail";
import ProductHeader from "./ProductHeader";
import ProductUsedBy from "./ProductUsedBy";
import SelectPiece from "./SelectPiece";

const {
  clearGlobalState,
  addClaimItem,
  delClaimItem,
  setClaimItemActive,
  addClaimItemMedia,
  delClaimItemMedia,
  setMaxTabs
} = actionClaim;

const Index = () => {
  const dispatch = useDispatch();
  const [errorPiece, setErrorPiece] = useState([]);
  const { visible, product_key } = useSelector(
    (state) => state.claim.productModal
  );
  const { activeKey, itemKeys, itemByKey } = useSelector(
    (state) => state.claim.billProduct.itemByKey[product_key]?.claim_item || {}
  );
  const qty_balance = useSelector(
    (state) => state.claim.billProduct.itemByKey[product_key]?.qty_balance || 0
  );
  const draft = useSelector(
    (state) => state.claim.billProduct.itemByKey[product_key] // for draft
  );
  const bom = useSelector(
    (state) => state.claim.billProduct.itemByKey[product_key]?.bom || []
  );

  const type_set = useSelector(
    (state) => state.claim.billProduct.itemByKey[product_key]?.product_type_set.toLowerCase()
  );

  const piece = useSelector((state) => {
    if (!product_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    if (!activeKey) return null;
    return itemByKey[activeKey].piece;
  })

  const piece_list = useSelector((state) => {
    if (!product_key) return null;
    const {
      bom,
      sub_item_used,
      qty,
      balance,
      bill_type,
      qty_balance,
      product_type_set,
      claim_item: { activeKey, itemKeys, itemByKey },
    } = state.claim.billProduct.itemByKey[product_key];

    let all_piece = [];
    for (let i = 1; i <= qty; i++) all_piece = [...all_piece, i];
    const other_piece_used = _.reduce(
      itemKeys,
      (res, key) => (key === activeKey ? res : [...res, itemByKey[key].piece]),
      []
    );

    let master_qty_set = _.sum(_.map(bom, (set) => set.qty)) // จำนวนรวมสินค้าย่อยใน 1 ชิ้น
    let piece_amount = _.map(all_piece, (p, i) => {
      let bom_items = _.map(bom, (n, j) => {
        // หา qty ของแต่ละสินค้าย่อย โดยแยกตามจำนวนชิ้น
        let _piece = _.filter(sub_item_used, { 'ref_product_code': n?.ref_product_code, 'product_code': n?.product_code, 'ref_piece': p })
        let sum = _.sumBy(_piece, 'qty')
        return sum
      })
      return _.sum(bom_items) < master_qty_set ? p : 0 // เช็คว่าใน 1 ชิ้นเคลมครบหรือยัง
    })
    let avalible_piece = piece_amount.filter(piece => piece != 0) // จำนวนชิ้นที่สามารถเคลมได้อีก

    const piece_balance = _.reduce(
      avalible_piece,
      (res, piece) => {
        if (_.some(other_piece_used, (n) => piece === n)) return res;
        const sub_item_used_select = _.filter(
          sub_item_used,
          (n) => n.ref_piece === piece
        );
        const has_qty_balance = _.some(bom, (n) => {
          const claim_qty =
            _.find(
              sub_item_used_select,
              (m) => n.product_code === m.product_code
            )?.qty || 0;
          return n.qty - claim_qty > 0;
        });
        return has_qty_balance ? [...res, piece] : res;
      },
      []
    );
    return product_type_set.toLowerCase() === 'kit/package' ? piece_balance : (bill_type === 'draft' ? balance : qty_balance)
  });

  if (piece_list) {
    if (type_set.toLowerCase() === 'kit/package') {
      dispatch(setMaxTabs(piece_list.length + itemKeys.filter(key => itemByKey[key]?.piece != null && key != activeKey)?.length))
    } else {
      dispatch(setMaxTabs(piece_list))
    }
  }

  const disabled = useSelector(
    (state) =>
      state.claim.isPreview ||
      !_.includes([null, "draft", "waiting"], state.claim.documentDetail.status)
  );

  const handleEditTab = (e, process) => {
    if (process === "add") {
      let all_piece = piece_list.length + itemKeys.filter(key => itemByKey[key]?.piece != null && key != activeKey)?.length; // for kit/package
      if (type_set === 'kit/package') {
        if (itemKeys.length >= all_piece) {
          return notification.warning({
            message: "ไม่สามารถเพิ่มจำนวนสินค้าได้",
            description: `จำนวนที่เคลมได้สูงสูด ${all_piece} ชิ้น`,
          });
        }
      } else {
        if (
          itemKeys.length >=
          (draft?.bill_type === "draft" ? draft?.balance : qty_balance)
        ) {
          return notification.warning({
            message: "ไม่สามารถเพิ่มจำนวนสินค้าได้",
            description: `จำนวนที่เคลมได้สูงสูด ${draft?.bill_type === "draft" ? draft?.balance : qty_balance
              } ชิ้น`,
            // description: `จำนวนที่เคลมได้สูงสูด ${qty_balance} ชิ้น`,
          });
        }
      }
      dispatch(addClaimItem());
    } else {
      const index = _.findIndex(itemKeys, (n) => n === e);
      Modal.confirm({
        title: "ยืนยันทำรายการ",
        content: `ยกเลิกเคลมสินค้า ชิ้นที่ - ${index + 1} ?`,
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          dispatch(delClaimItem(e));
        },
      });
    }
  };

  const handleClickTab = (key) => dispatch(setClaimItemActive(key));

  const handleSubmit = () => {
    const no_piece = _.filter(itemKeys, (key) => !itemByKey[key].piece);
    const no_bom =
      bom.length > 0 &&
      _.find(itemKeys, (key) =>
        _.every(
          itemByKey[key].bom.itemKeys,
          (sub_item_key) =>
            itemByKey[key].bom.itemByKey[sub_item_key].claim_item.itemKeys
              .length === 0
        )
      );
    setErrorPiece(no_piece);
    const error_key = no_piece[0] || no_bom;
    if (error_key) return dispatch(setClaimItemActive(error_key));
    dispatch(clearGlobalState("productModal"));
  };

  const handleChangeUpload = useCallback(
    (value) => dispatch(addClaimItemMedia(value)),
    []
  );
  const handleDeleteUpload = useCallback(
    (value) => dispatch(delClaimItemMedia(value)),
    []
  );
  const handleClearErrorPiece = useCallback(
    (key) => setErrorPiece(_.filter(errorPiece, (n) => key !== n)),
    [errorPiece]
  );

  return (
    <Modal
      className="modal-with-tab"
      visible={visible}
      footer={false}
      width={1000}
      zIndex={1080}
      destroyOnClose={true}
      maskClosable={false}
      closable={false}
    >
      <HeaderDetail disabled={disabled} />
      <Tabs
        activeKey={activeKey}
        type={disabled ? "card" : "editable-card"}
        onEdit={handleEditTab}
        onTabClick={handleClickTab}
      >
        {_.map(itemKeys, (key, i) => (
          <Tabs.TabPane
            closable
            key={key}
            tab={`ชิ้นที่ - ${i + 1}`}
            className="px-3"
          >
            <ProductHeader disabled={disabled} />

            {bom.length > 0 && (
              <SelectPiece
                itemKey={key}
                disabled={disabled}
                hasError={_.some(errorPiece, (e) => e === key)}
                clearError={handleClearErrorPiece}
              />
            )}

            {/* <ProductTypeTab active={itemByKey[key].product_type_active} /> */}

            {!!piece && (
              <>
                <ProductUsedBy
                  code={itemByKey[key].used_by_code}
                  name={itemByKey[key].used_by_name}
                  mobile={itemByKey[key].used_by_mobile}
                  disabled={disabled}
                />

                <ProductDetail disabled={disabled} />

                <Row gutter={[8, 8]} className="mb-3">
                  <Col span={3}>
                    <Typography.Text className="text-nowrap">
                      แนบรูปหรือวิดีโอ
                    </Typography.Text>
                  </Col>
                  <Col span={21}>
                    <UploadFileList
                      withCamera
                      acceptVideo
                      disabled={disabled}
                      files={itemByKey[key].media}
                      onChange={handleChangeUpload}
                      onDelete={handleDeleteUpload}
                    />
                  </Col>
                </Row>
                {bom.length > 0 && <BomTable disabled={disabled} />}
              </>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Row gutter={[16, 16]} className="my-3">
        <Col span={24} className="text-center">
          <Button type="primary" onClick={handleSubmit}>
            ตกลง
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Index;
