/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Spin,
  Switch,
} from "antd";
import axios from "axios";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch } from "react-redux";
import ImagePreview from "../../../../../../components/ImagePreview";
import { actionPromotionList } from "../../../../../../redux/slices/promotion-list";
import { getToken, moment, URL_API, _ } from "../../../../../../utils";
import {
  disabledEndDateSplit,
  disabledEndTimeSplit,
} from "../../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import HonorSource from "../components/HonorSource";
import ImgUpload from "../components/ImgUpload";
import MemberAge from "../components/MemberAge";
import ModalAmount from "../components/ModalAmount";
import ModalProduct from "../components/ModalProduct";
import ModalVoucher from "../components/ModalVoucher";
import OrderSource from "../components/OrderSource";
import OrderType from "../components/OrderType";
import Amount from "./Amount";
import SwitchCondition from "../components/SwitchCondition";
import { initState, PromotionContext, reducer } from "../reducers";
import Giveaway from "./Giveaway";
import OrderLimit from "../components/OrderLimit";
// import ConditionSetting from "../components/ConditionSetting";
// import Collect from "../components/Collect";

const orderSourceItemMapping = (data, key) =>
  _.map(data.itemKeys, (n) => {
    const { start_date, end_date, code } = data.itemByKey[n];
    return {
      order_source_key: key,
      code,
      start_date: start_date ? moment(start_date).toDate() : null,
      end_date: end_date ? moment(end_date).toDate() : null,
    };
  });

const honorSourceItemMapping = (data, key) =>
  _.map(data.itemKeys, (n) => {
    return {
      honor_source_key: key,
      honor_code: data.itemByKey[n].honor_code,
    };
  });

const Index = ({ match: { params }, history, form }) => {
  const { getFieldDecorator, getFieldsValue, setFieldsValue, validateFields } =
    form;
  const type_params = params.type;
  const [state, dispatch] = useReducer(reducer, initState);
  const [endDate, setEndDate] = useState(null);
  const reduxDispatch = useDispatch();
  const { clear } = actionPromotionList;
  const { lifetime, start_date, end_date } = getFieldsValue();
  const title =
    type_params === "giveaway" ? "โปรโมชันของแถม" : "โปรโมชันราคาขาย";

  useEffect(() => {
    return () =>
      _.split(history.location.pathname, "/setting/master/")[1] !==
        "promotion" && reduxDispatch(clear());
  }, []);

  useEffect(() => {
    if (_.isNaN(+params.id)) {
      history.push("/setting/master/promotion");
    } else if (params.id !== "0") {
      loadDetail();
    }
  }, []);

  useEffect(() => {
    if (lifetime) {
      setFieldsValue({
        end_date: null,
      });
    }
  }, [lifetime]);

  useEffect(() => {
    if (start_date && endDate) {
      setFieldsValue({
        end_date: null,
      });
    }
  }, [start_date, end_date]);

  useEffect(() => {
    if (start_date && end_date) {
      const start = moment(start_date).add(1, "s");
      let e = end_date;
      if (+moment(start).format("DD") === +moment(e).format("DD")) {
        const current_hour = +moment(e).format("HH");
        const start_hour = +moment(start).format("HH");
        if (
          current_hour === start_hour &&
          current_hour !== +moment(endDate).format("HH")
        ) {
          e = moment(e).set({
            m: +moment(start).format("mm"),
            s: +moment(start).format("ss"),
          });
        }
        setEndDate(e);
        setFieldsValue({
          end_date: e,
        });
      }
    }
  }, [end_date, start_date]);

  const loadDetail = () => {
    dispatch({ type: "SET_LOADING", payload: true });
    axios({
      method: "get",
      baseURL: URL_API,
      url: `/setting/master/promotion/${type_params}/${params.id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        if (res.data.total === 0) {
          Modal.error({
            title: "ไม่พบข้อมูลโปรโมชัน",
            content: "กรุณาเลือกโปรโมชันให้ถูกต้อง",
            okText: "ตกลง",
            zIndex: 1080,
            onOk() {
              history.push("/setting/master/promotion");
            },
          });
        } else {
          const payload = res.data.data;
          dispatch({ type: "SET_LOAD_SUCCESS", payload });
          setFieldsValue({
            code: payload.code,
            name: payload.name,
            condition_type: payload.condition_type,
            receive_type: payload.receive_type,
            lifetime: !payload.end_date,
            start_date: payload.start_date
              ? moment(payload.start_date)
              : undefined,
            end_date: payload.end_date ? moment(payload.end_date) : undefined,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING", payload: false });
        message.error(err.message);
      });
  };

  const onFinish = (values) => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          const media_item_key = _.find(
            state.media.itemKeys,
            (n) => state.media.itemByKey[n].is_default
          );
          const data = {
            ..._.omit(state.data, [
              "member_age_year",
              "member_age_month",
              "member_age_date",
              "description",
              "lifetime",
              "id",
            ]),
            ...values,
            status: state.data.status === 1 ? "active" : "inactive",
            condition: _.reduce(
              state.condition.itemKeys,
              (r, condition_key) => [
                ...r,
                _.map(state.condition.itemByKey[condition_key].itemKeys, (n) =>
                  _.omit(
                    state.condition.itemByKey[condition_key].itemByKey[n],
                    ["visible", "disabled_del", "min"]
                  )
                ),
              ],
              []
            ),
            receive: _.reduce(
              state.receive.itemKeys,
              (r, receive_key) => [
                ...r,
                _.map(state.receive.itemByKey[receive_key].itemKeys, (n) =>
                  _.omit(state.receive.itemByKey[receive_key].itemByKey[n], [
                    "disabled_del",
                    "min",
                  ])
                ),
              ],
              []
            ),
            description: state.data.description
              ? draftToHtml(
                  convertToRaw(state.data.description.getCurrentContent())
                )
              : null,
            member_start: state.data.member_start
              ? moment(state.data.member_start).toDate()
              : null,
            member_end: state.data.member_end
              ? moment(state.data.member_end).toDate()
              : null,
            start_date: values.start_date
              ? moment(values.start_date).toDate()
              : null,
            end_date: values.end_date ? moment(values.end_date).toDate() : null,
            order_source_item: _.reduce(
              _.map(Object.keys(state.orderSource), (n) =>
                orderSourceItemMapping(state.orderSource[n], n)
              ),
              (r, list) => [...r, ...list],
              []
            ),
            honor_source_item: _.reduce(
              _.map(Object.keys(state.honorSource), (n) =>
                honorSourceItemMapping(state.honorSource[n], n)
              ),
              (r, list) => [...r, ...list],
              []
            ),
            promotion_file_default: media_item_key
              ? state.media.itemByKey[media_item_key].id
              : "",
            promotion_file: _.join(
              _.reduce(
                state.media.itemKeys,
                (res, n) =>
                  state.media.itemByKey[n].temp
                    ? [...res, state.media.itemByKey[n].id]
                    : res,
                []
              ),
              ","
            ),
            condition_file_option: _.reduce(
              state.condition.itemKeys,
              (res, key, index) => {
                const media = state.condition.itemByKey[key].media;
                return media?.temp
                  ? [
                      ...res,
                      {
                        id: media.id,
                        source_option: index,
                      },
                    ]
                  : res;
              },
              []
            ),
            receive_file_option: _.reduce(
              state.receive.itemKeys,
              (res, key, index) => {
                const media = state.receive.itemByKey[key].media;
                return media?.temp
                  ? [
                      ...res,
                      {
                        id: media.id,
                        source_option: index,
                      },
                    ]
                  : res;
              },
              []
            ),
            file_delete: _.join(
              [
                ...state.media.itemDel,
                ...state.condition.mediaDel,
                ...state.receive.mediaDel,
              ],
              ","
            ),
          };

          axios({
            method: "post",
            baseURL: URL_API,
            url: `/setting/master/promotion/${type_params}${
              params.id === "0" || state.isTemplate ? "" : `/${params.id}`
            }`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
            cancelToken: source.token,
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: "บันทึกข้อมูลโปรโมชันเรียบร้อย",
              });
              resolve();
              history.push("/setting/master/promotion");
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  const handleClickCopy = () => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content:
        "คุณต้องการสร้างรายการใหม่โดยใช้ข้อมูลตามโปรโมชันนี้หรือไม่ การแก้ไขรายการล่าสุดจะไม่ถูกบันทึก",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        setFieldsValue({
          code: null,
        });
        dispatch({ type: "SET_TEMPLATE" });
      },
    });
  };

  const handleClickSave = () => {
    let error_item = [];
    if (type_params === "giveaway") {
      if (state.condition.itemKeys.length === 0) {
        error_item = [...error_item, "condition"];
      } else if (
        _.find(
          state.condition.itemKeys,
          (n) => state.condition.itemByKey[n].itemKeys.length === 0
        )
      ) {
        error_item = [...error_item, "condition_item"];
      }
    }
    if (state.receive.itemKeys.length === 0) {
      error_item = [...error_item, "receive"];
    } else if (
      _.find(
        state.receive.itemKeys,
        (n) => state.receive.itemByKey[n].itemKeys.length === 0
      )
    ) {
      error_item = [...error_item, "receive_item"];
    }
    dispatch({ type: "SET_ERROR_ITEM", payload: error_item });
    validateFields((err, values) => {
      if (!err && error_item.length === 0) {
        onFinish(values);
      } else {
        notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }
    });
  };

  const handleCancelPreview = useCallback(() => {
    dispatch({
      type: "SET_MODAL_VISIBLE",
      payload: {
        name: "img_preview",
        id: null,
        src: null,
        visible: false,
      },
    });
  }, [dispatch]);

  return (
    <PromotionContext.Provider value={{ state, dispatch }}>
      <Spin spinning={state.loading}>
        <Panel>
          <PanelHeader>{title}</PanelHeader>
          <PanelBody>
            <Row gutter={3} className="mb-3">
              <Col span={12}>
                {params.id !== "0" && !state.isTemplate && (
                  <Button
                    type="primary"
                    className="mr-4"
                    onClick={handleClickCopy}
                  >
                    สร้างรายการใหม่ (คัดลอก)
                  </Button>
                )}
              </Col>
              <Col span={12} className="text-right">
                <Button
                  id="master-pro-save"
                  type="primary"
                  className="mr-4"
                  onClick={handleClickSave}
                >
                  บันทึก
                </Button>
                <Button
                  id="master-pro-cancel"
                  type="danger"
                  onClick={() => {
                    history.push("/setting/master/promotion");
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
            </Row>
            <Form layout="vertical">
              <Row gutter={32}>
                <Col md={16}>
                  <Row gutter={16}>
                    <Col md={12}>
                      <label className="mb-0">รหัสโปรโมชัน</label>
                      <Form.Item className="mb-0">
                        {getFieldDecorator("code", {
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุรหัสโปรโมขั่น",
                            },
                          ],
                        })(
                          <Input
                            autoComplete="off"
                            placeholder="รหัสโปรโมชัน"
                            maxLength={50}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <label className="mb-0">ชื่อโปรโมชัน</label>
                      <Form.Item className="mb-0">
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุชื่อโปรโมชัน",
                            },
                          ],
                        })(
                          <Input
                            autoComplete="off"
                            placeholder="ชื่อโปรโมชัน"
                            maxLength={255}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={24}>
                      <label className="mb-0">รายละเอียดโปรโมชัน</label>
                      <Editor
                        editorClassName="border"
                        editorState={state.data.description}
                        onEditorStateChange={(e) =>
                          dispatch({
                            type: "SET_DATA",
                            payload: { description: e },
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Divider className="py-3">อัปโหลดรูป</Divider>
                  <Row gutter={16} className="mb-2">
                    <Col md={24}>
                      <ImgUpload />
                    </Col>
                  </Row>

                  {type_params === "giveaway" ? (
                    <>
                      <Divider className="py-3">เงื่อนไขการซื้อ</Divider>
                      <Giveaway form={form} />
                    </>
                  ) : (
                    <>
                      <Divider className="py-3">รายการโปรโมชัน</Divider>
                      <Amount />
                    </>
                  )}
                </Col>
                <Col md={8}>
                  <Divider>สถานะการใช้งาน</Divider>
                  <SwitchCondition name="status" title="ใช้งาน" />

                  <Divider>วันเวลา</Divider>
                  <Row gutter={16} className="mb-2">
                    <Col md={24} className="d-flex justify-content-between">
                      <label className="mb-0">ตลอดไป</label>
                      <Form.Item className="mb-0">
                        {getFieldDecorator("lifetime", {
                          valuePropName: "checked",
                          initialValue: false,
                        })(<Switch />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={12}>
                      <Form.Item className="mb-0">
                        {getFieldDecorator("start_date", {
                          onChange: (start_date) => {
                            if (start_date || start_date === null) {
                              dispatch({
                                type: "SET_DEFAULT_DURATION",
                                payload: {
                                  type: "start_date",
                                  value: start_date,
                                },
                              });
                            }
                          },
                          rules: [
                            {
                              required: true,
                              message: "กรุณาเลือกวันที่เริ่มต้น",
                            },
                          ],
                        })(
                          <DatePicker
                            className="w-100 min-width-0"
                            placeholder="วันที่เริ่มต้น"
                            format="DD/MM/YYYY HH:mm:ss"
                            showTime={true}
                            defaultPickerValue={moment("00:00:00", "HH:mm:ss")}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item className="mb-0">
                        {getFieldDecorator("end_date", {
                          onChange: (start_date) => {
                            if (start_date || start_date === null) {
                              if (end_date || end_date === null) {
                                dispatch({
                                  type: "SET_DEFAULT_DURATION",
                                  payload: {
                                    type: "end_date",
                                    value: end_date,
                                  },
                                });
                              }
                            }
                          },
                          rules: lifetime
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "กรุณาเลือกวันที่สิ้นสุด",
                                },
                              ],
                        })(
                          <DatePicker
                            className="w-100 min-width-0"
                            placeholder="วันที่สิ้นสุด"
                            format="DD/MM/YYYY HH:mm:ss"
                            showTime={true}
                            defaultPickerValue={moment("23:59:59", "HH:mm:ss")}
                            disabled={lifetime}
                            disabledDate={(current) =>
                              disabledEndDateSplit(current, start_date)
                            }
                            disabledTime={(current) =>
                              disabledEndTimeSplit(current, start_date)
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider>ช่องทางการสั่งซื้อ</Divider>
                  <OrderSource />

                  <Divider>ประเภทผู้ซื้อ</Divider>
                  <SwitchCondition
                    name="distributor_sale"
                    title="สินค้าขายสำหรับนักธุรกิจ"
                  />
                  <SwitchCondition
                    name="member_sale"
                    title="สินค้าขายสำหรับ VIP Member"
                    className="my-2"
                  />
                  <SwitchCondition
                    name="staff_sale"
                    title="สินค้าขายสำหรับพนักงาน"
                  />

                  {/* {type_params === "giveaway" && (
                    <>
                      <Divider>กำหนดเงื่อนไข</Divider>
                      <ConditionSetting form={form} />
                    </>
                  )} */}

                  {/* <Divider>สะสม</Divider>
                  <Collect form={form} /> */}

                  <Divider>สิทธิการซื้อ</Divider>
                  <OrderLimit />

                  {/* <Divider>เดือนเกิด</Divider>
                  <SwitchCondition
                    name="birth_date"
                    title="โปรโมชันตามเดือนเกิด"
                  /> */}

                  <Divider>อายุสมาชิก</Divider>
                  <MemberAge />

                  <Divider>ประเภทการสั่งซื้อ</Divider>
                  <OrderType />

                  <Divider>ตามตำแหน่งของนักธุรกิจ</Divider>
                  <HonorSource />

                  {/* <Divider>ตามรหัสนักธุรกิจ</Divider>
  
                    <Divider>ตามการรักษายอด</Divider>
                    <Form.Item className="mb-0">
                      {getFieldDecorator(
                        "point",
                        {}
                      )(<InputNumber className="w-100" />)}
                    </Form.Item> */}
                </Col>
              </Row>
            </Form>
          </PanelBody>
        </Panel>
      </Spin>
      {(state.data.condition_type === "item" ||
        state.data.condition_type === "item-amount" ||
        state.data.receive_type === "item") && <ModalProduct />}
      {_.includes(
        [state.data.condition_type, state.data.receive_type],
        "voucher"
      ) && <ModalVoucher />}
      <ImagePreview
        {...state.modal.img_preview}
        onCancel={handleCancelPreview}
      />
      <ModalAmount />
      {/* <ModalAddData /> */}
    </PromotionContext.Provider>
  );
};

const FormWrapped = Form.create()(Index);

export default FormWrapped;
