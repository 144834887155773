import { message } from "antd";
import { REPORT_API } from ".";
import _ from "lodash";
export const printform = (name, params) => {

  if (
    [
      "receipt",
      "preview",
      "another8",
      "requisition",
      "transfer_in",
      "transfer_out",
      "customer",
      "logistic",
      'delivery',
      'redeem'
    ].includes(name)
  ) {
    if (!params.report_id) return message.warning("ไม่สามารถออกเอกสารได้");
    if (_.isArray(params.report_id)){
      _.forEach(params.report_id, (reportId,index) => {
        const url = `${REPORT_API}/${name}?id=${reportId}`;
        setTimeout(() => {
          window.open(url, "_blank");
      }, index * 800); // ตั้งค่าเวลาให้เริ่มเปิดหน้าต่างใหม่ทีละรายการทุกๆ 1 วินาที (1000 มิลลิวินาที)
      })
    }else{
      const url = `${REPORT_API}/${name}?id=${params.report_id}`;
      window.open(url, "_blank");
    }
  } else {
  }
};
