
import { Button, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import TextEditor from '../../../../../components/TextEditor';
import { axios, getToken, URL_API } from '../../../../../utils';
import { Panel, PanelBody, PanelHeader } from '../../../../util/panel';

function BS_TitleVip({ history, form, ...props }) {
    const [contentTextEditor, setContentTextEditor] = useState();
  
    const handleClickSubmit = () => {
      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              baseURL: URL_API,
              url: "/manage-website/benefits-and-subscriptions/bs-title-vip",
              headers: { Authorization: "Bearer " + getToken() },
              data: {
                content: contentTextEditor,
              },
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    };
  
    const loadContent = () => {
      axios({
        method: "get",
        baseURL: URL_API,
        url: "/manage-website/benefits-and-subscriptions/bs-title-vip",
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            setContentTextEditor(res.data.data.content);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      loadContent();
    }, []);
  
    return (
      <>
        <Panel>
          <PanelHeader>TITLE VIP MEMBER</PanelHeader>
          <PanelBody>
            <Row className="mb-3">
              <TextEditor
                authToken={getToken()}
                value={contentTextEditor}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setContentTextEditor(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>
            <Row>
              <Button type="primary" onClick={() => handleClickSubmit()}>
                SAVE
              </Button>
            </Row>
          </PanelBody>
        </Panel>
      </>
    );
  }
  export default (BS_TitleVip);
  