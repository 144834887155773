/* eslint-disable no-unused-vars */
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import SelectVoucher from "../../../../../../../components/SelectVoucher";

const Type = ({
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  type,
  expire_type,
  expire_date,
  count_day,
  is_used,
}) => {
  const [expireType, setExpireType] = useState("");

  useEffect(() => {
    setExpireType(expire_type);
  }, [expire_type, expire_date, count_day]);

  const voucher_type = getFieldValue("voucher_type");

  // useEffect(() => {
  //   setFieldsValue({ type: "" });
  // }, [voucher_type, setFieldsValue]);

  return (
    <Row>
      <Row gutter={16} style={{ marginBottom: "32px" }}>
        <Col md={12}>
          <Form.Item className="mb-0">
            {getFieldDecorator("voucher_type", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกประเภทบัตรกำนัล",
                },
              ],
              onChange: (e) => {
                setFieldsValue({ voucher_type: e, type: undefined });
              },
            })(
              <Radio.Group disabled={is_used === 1}>
                <Radio className="mb-1" value="voucher">
                  Voucher (คำนวณ vat ก่อนหักส่วนลด)
                </Radio>
                <Radio value="coupon">Coupon (คำนวณ vat หลังหักส่วนลด)</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col md={12}>
          <label className="mb-0">ประเภทบัตรกำนัล</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("type", {
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกประเภทบัตรกำนัล",
                },
              ],
            })(
              <SelectVoucher
                placeholder="เลือกประเภทบัตรกำนัล"
                voucher_type={voucher_type}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="mb-0">จำนวน</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("value", {
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุจำนวน",
                },
              ],
            })(
              <InputNumber
                className="w-100"
                max={type === "2" ? 100 : undefined}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col md={12}>
          <label className="mb-0">วันหมดอายุ</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("expire_type", {
              initialValue: expireType,
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกประเภทวันหมดอายุ",
                },
              ],
            })(
              <Select
                placeholder="กรุณาเลือกประเภทวันหมดอายุ"
                onChange={(e) => setExpireType(e)}
              >
                <Select.Option value="expire_date">
                  ระบุวันหมดอายุ
                </Select.Option>
                <Select.Option value="count_day">
                  นับจากวันที่ได้รับ
                </Select.Option>
                <Select.Option value="none_expire">
                  ไม่มีวันหมดอายุ
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          {expireType === "expire_date" && (
            <Form.Item className="mb-0">
              {getFieldDecorator("expire_date", {
                initialValue: expire_date === null ? null : moment(expire_date),
                rules: [
                  {
                    required: true,
                    message: "กรุณาระบุวันหมดอายุ",
                  },
                ],
              })(
                <DatePicker
                  placeholder="-กรุณาระบุวันหมดอายุ-"
                  format={"DD-MM-YYYY"}
                  className="w-100"
                />
              )}
            </Form.Item>
          )}
          {expireType === "count_day" && (
            <Form.Item className="mb-0">
              {getFieldDecorator("count_day", {
                initialValue: count_day,
                rules: [
                  {
                    required: true,
                    message: "กรุณาระบุจำนวน",
                  },
                  {
                    validator: async (rule, value) => {
                      if (value <= 0) {
                        throw new Error("นับจากวันที่ได้รับต้องมากกว่า 0 วัน");
                      }
                    },
                  },
                ],
              })(
                <Input
                  min={1}
                  // defaultValue={count_day}
                  type={"number"}
                  placeholder="-ระบุจำนวนวัน-"
                  suffix="วัน"
                />
              )}
            </Form.Item>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default Type;
