import { Button, Col, Input, Modal, notification, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { actionTransfer } from "../../../../../redux/slices/transfer";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import SelectReason from "./SelectReason";

const { Text } = Typography;

const { setGlobalState, clearGlobalState } = actionTransfer;

const Index = ({ reload, setLoadingSubmit }) => {
  const dispatch = useDispatch();
  const {
    visible,
    cancel_type,
    remark,
    document_no,
    document_status,
    receive_type,
  } = useSelector((state) => state.transfer.cancelModal);
  const match = useRouteMatch();
  const history = useHistory();
  const {
    type: type_params,
    process: process_params,
    id: id_params,
  } = useParams();

  const handleClickSubmit = () => {
    if (!cancel_type)
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณาเลือกเหตุผลการยกเลิก",
      });

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1 text-nn">
            ยกเลิกโอนย้ายสินค้า
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        setLoadingSubmit(true);
        axios({
          method: "post",
          url: `${URL_API}/transfer-receive/${
            receive_type === "receive-in" ? "receive-in" : "transfer"
          }/consider`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            document_no,
            cancel_type,
            status: "cancel",
            remark: remark.trim(),
            document_status: document_status,
          },
        })
          .then(() => {
            dispatch(clearGlobalState("cancelModal"));
            if (id_params) {
              const back_url = `/${
                _.split(match.path, "/")[1]
              }/${type_params}/${process_params}`;
              history.push({ pathname: back_url });
            } else {
              reload && reload();
            }
          })
          .catch((e) => resCatchModal(e))
          .finally(() => setLoadingSubmit(false));
      },
    });
  };

  const handleClickClose = () => dispatch(clearGlobalState("cancelModal"));

  return (
    <Modal
      title={`ยืนยันยกเลิกใบโอนย้ายเลขที่ ${document_no}`}
      visible={visible}
      footer={false}
      zIndex={1080}
      width={800}
      destroyOnClose={true}
      maskClosable={false}
      closable={false}
    >
      <Row gutter={[8, 8]}>
        <Col span={4} className="text-right">
          เหตุผลการยกเลิก
        </Col>
        <Col span={20}>
          <SelectReason
            placeholder="เลือกเหตุผลการยกเลิก"
            value={cancel_type || undefined}
            onChange={(cancel_type) =>
              dispatch(
                setGlobalState({
                  name: "cancelModal",
                  value: { cancel_type },
                })
              )
            }
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="mb-3">
        <Col span={4} className="text-right">
          หมายเหตุ
        </Col>
        <Col span={20}>
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 6 }}
            maxLength={500}
            value={remark}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "cancelModal",
                  value: { remark: e.target.value },
                })
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="text-center">
          <div>
            <Button
              className="width-100 mr-3"
              type="primary"
              ghost
              onClick={handleClickClose}
            >
              ย้อนกลับ
            </Button>
            <Button
              className="width-100"
              type="danger"
              onClick={handleClickSubmit}
            >
              ยืนยัน
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(Index);
