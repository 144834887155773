/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Button, Col, Input, Modal, Row, Table, Typography, notification } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { FaCogs, FaEye } from "react-icons/fa";
import { useRouteMatch } from "react-router";
import { useApi } from "../../../../../hooks/http2";
import { URL_API } from "../../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  actionStoreQrBalance,
  activeSelector,
  searchSelector,
} from "../../../../../redux/slices/store-qr/balance";
import * as XLSX from "xlsx-js-style";

const BalanceDetailSet = () => {
  const match = useRouteMatch();
  const [showDetail, setShowDetail] = useState(false);
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const { SET_SEARCH } = actionStoreQrBalance;
  const search = useSelector(searchSelector);

  const detail = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/balance/${match.params.type}/${match.params.id}/${match.params.branch_code}`,
      token: true,
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
    },
    [match.params, search]
  );

  const dataExcel = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/balance/${match.params.type}/${match.params.id}/${match.params.branch_code}`,
      token: true,
      params: {
        ...search,
        offset: 0,
        limit: 0
      },
    },
    [match.params, search]
  );

  const lot = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/set/${selected}`,
      token: true
    },
    [selected]
  );


  const exportToExcel = () => {
    if (dataExcel?.fetch?.list.length != 0) {

      let excelData = [
        ["รายงาน",
          "สินค้าจัดเซ็ตคงเหลือ-ชุดเซ็ต(รายงานแสดงเฉพาะรายการสินค้าและสาขาที่ระบุเท่านั้น)",
          "", "", "",
          "วันที่ดึงข้อมูล", `${moment(new Date()).format("DD/MM/YYYY HH:mm")}`],
        [],
        [
          "แสดงเฉพาะรายการ", `${search.query}`
        ],
        [
          `รหัสสินค้า:`, ` ${detail.fetch.data.product_code}`,
          "", "", "",
          `จำนวนคงเหลือ(เซ็ต): `, ` ${Number(detail.fetch.data.balance).toLocaleString()}`,

        ],
        [
          `ชื่อชุดเซ็ต:`, `${detail.fetch.data.product_name}`,
          "", "", "",
          `สาขา :`, ` ${detail.fetch.data.branch_name}`,
        ],
        [],
        [{ v: "ลำดับ", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "Running หลัก", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "สินค้าหมดอายุเร็วสุด", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "อายุสินค้าคงเหลือ (วัน)", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "ผู้เก็บข้อมูล", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "วันที่เก็บข้อมูล", t: "s", s: { alignment: { horizontal: "center" } } },
        ],
      ];
      for (let i = 0; i < dataExcel.fetch?.list.length; i++) {
        let n = dataExcel.fetch?.list[i];
        let exp = moment(n.expiration_date);
        let now = moment();
        let duration = moment.duration(exp.diff(now));
        let days = duration.asDays();

        excelData = [...excelData,
        [{ v: n.row_id, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.main_running, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: moment(n.expiration_date).format("DD/MM/YYYY"), t: "s", s: { alignment: { horizontal: "center" } } },
        { v: numeral(days < 0 ? 0 : days).format("0,0"), t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.create_by, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: moment(n.create_date).format("DD/MM/YYYY HH:mm"), t: "s", s: { alignment: { horizontal: "center" } } }
        ]];
      }

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 2, c: 1 }, e: { r: 2, c: 3 } },
        { s: { r: 4, c: 1 }, e: { r: 4, c: 3 } },

      ];

      var wscols = [{ wch: 20 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ข้อมูลจัดเซ็ตคงเหลือ-เซ็ตสินค้า");
      XLSX.writeFile(wb, `ข้อมูลจัดเซ็ตคงเหลือ-เซ็ตสินค้า.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };


  useEffect(() => {
    dispatch(SET_SEARCH({ query: "", page: 1 }))
  }, [match.params])

  return (
    <>
      <Row gutter={16} className="mb-3">
        <Col md={20}>
          <Input.Search
            placeholder="Lot., QR Code หลัก, QR Code ย่อย"
            allowClear
            onSearch={(value) =>
              dispatch(SET_SEARCH({ query: value, page: 1 }))
            }
          />
        </Col>
        <Col md={4}>
          <Button
            type="primary"
            icon="file-excel"
            style={{ width: "100%" }}
            onClick={() => exportToExcel()}
          >
            Export Excel
          </Button>
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col md={2} className="text-right">
          <Typography.Text strong>รหัสสินค้า:</Typography.Text>
        </Col>
        <Col md={4}>
          <Typography.Text strong>
            {detail.fetch?.data?.product_code}
          </Typography.Text>
        </Col>
        <Col md={2} className="text-right">
          <Typography.Text strong>ชื่อชุดเซ็ต:</Typography.Text>
        </Col>
        <Col md={4}>
          <Typography.Text strong>
            {detail.fetch?.data?.product_name}
          </Typography.Text>
        </Col>
        <Col md={3} className="text-right">
          <Typography.Text strong>จำนวนคงเหลือ (ชิ้น):</Typography.Text>
        </Col>
        <Col md={3}>
          <Typography.Text strong>
            {numeral(detail.fetch?.data?.balance).format()}
          </Typography.Text>
        </Col>
        <Col md={2} className="text-right">
          <Typography.Text strong>สาขา:</Typography.Text>
        </Col>
        <Col md={4}>
          <Typography.Text strong>
            {detail.fetch?.data?.branch_name}
          </Typography.Text>
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        loading={detail.loading}
        dataSource={detail.fetch?.list.map((item, index) => ({ ...item, key: index + 1 })) || []}
        pagination={false}
      >
        <Table.Column title="No." dataIndex="row_id" />
        <Table.Column title="Running (หลัก)" dataIndex="main_running" />
        <Table.Column
          title="สินค้าที่หมดอายุเร็วที่สุด"
          dataIndex="expiration_date"
          align="center"
          render={(text) => moment(text).format("DD/MM/YYYY")}
        />
        <Table.Column
          title="อายุสินค้าคงเหลือ (วัน)"
          dataIndex="expiration_date"
          key="count_expiration_date"
          align="right"
          render={(text) => {
            let exp = moment(text);
            let now = moment();

            let duration = moment.duration(exp.diff(now));
            let days = duration.asDays();
            return numeral(days < 0 ? 0 : days).format("0,0");
          }}
        />
        <Table.Column title="ผู้เก็บข้อมูล" dataIndex="create_by" />
        <Table.Column
          title="วันที่เก็บข้อมูล"
          dataIndex="create_date"
          align="center"
          render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
        />
        <Table.Column
          title={<FaCogs />}
          align="center"
          dataIndex="id"
          render={(text) => (
            <Button
              type="primary"
              size="small"
              shape="circle-outline"
              onClick={() => {
                setSelected(text);
                setShowDetail(true);
              }}
            >
              <FaEye />
            </Button>
          )}
        />
      </Table>

      <Modal
        visible={showDetail}
        title="รายละเอียดสินค้าประกอบ"
        width={800}
        footer={false}
        onCancel={() => setShowDetail(false)}
      >
        <Table
          size="small"
          bordered
          loading={lot.loading}
          dataSource={lot.fetch?.data?.list?.map((item, index) => ({ ...item, key: index + 1 })) || []}
          pagination={false}
        >
          <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
          <Table.Column title="ชื่อสินค้า" dataIndex="product_name" />
          <Table.Column title="Lot." dataIndex="lot" />
          <Table.Column
            title="วันผลิตสินค้า"
            dataIndex="production_date"
            align="center"
            render={(text) => moment(text).format("DD/MM/YYYY")}
          />
          <Table.Column
            title="วันหมดอายุสินค้า"
            dataIndex="expiration_date"
            align="center"
            render={(text) => moment(text).format("DD/MM/YYYY")}
          />
        </Table>
      </Modal>
    </>
  );
};
export default BalanceDetailSet;
