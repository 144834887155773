import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const Layout = ({ breadcrumb = [], titlePage, subTitlePage, titlePanel, children, ...props }) => {
    return (
        <div>
            <ol className="breadcrumb float-xl-right">
                {
                    breadcrumb.map(n => {
                        return (
                            <li className={`breadcrumb-item ${n.link ? '' : 'active'}`} key={_.uniqueId('breadcrumb-')}>
                                {
                                    n.link ?
                                        <Link to={n.link}>{n.title}</Link>
                                        :
                                        n.title
                                }
                            </li>
                        )
                    })
                }
                {/* <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Active Page</li> */}
            </ol>
            <h1 className="page-header">{titlePage} <small>{subTitlePage}</small></h1>
            {children}
        </div>
    )
}

export default Layout;
