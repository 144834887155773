/* eslint-disable jsx-a11y/anchor-is-valid */
import { Empty, Pagination, Spin } from "antd";
import React from "react";

const Table = ({
  columns = [],
  dataSource = [],
  className,
  loading = false,
  page,
  setPage,
  total,
}) => {
  return (
    <Spin spinning={loading}>
      <table className={`w-100 ${className}`}>
        <thead>
          <tr className="bg-gray-60 text-white">
            {columns.map((n, i) => (
              <th key={i} className="text-center font-weight-normal py-1">
                {n.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {
          // loading ? (
          //   <tr>
          //     <td colSpan={columns.length || 1}>
          //       <Skeleton active />
          //     </td>
          //   </tr>
          // ) : 
          dataSource.length === 0 ? (
            <tr>
              <td colSpan={columns.length || 1}>
                <Empty className="my-2" />
              </td>
            </tr>
          ) : (
            dataSource.map((n, i) => {
              return (
                <tr key={i}>
                  {columns.map((m, j) => {
                    return (
                      <td className="py-1 px-2" key={j}>
                        {m.render
                          ? m.render(n[m.dataIndex], n, i)
                          : n[m.dataIndex]}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      {dataSource.length > 0 ? (
        <Pagination
          hideOnSinglePage
          current={page}
          total={total}
          onChange={(e) => setPage(e)}
          className="text-center mt-3"
          showLessItems={true}
        />
      ) : null}
    </Spin>
  );
};

export default Table;
