/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Modal, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../hooks/dom";
import { actionRequisition } from "../../../redux/slices/requisition";
import { actionUser } from "../../../redux/slices/user";
import {
  getRequisitionSelect,
  getUserDepartmentCode,
  getUserPermissionManagerOtherAmd,
} from "../../../redux/thunk/requisition";
import { getUserPermission } from "../../../redux/thunk/user";
import { getUserObj, _ } from "../../../utils";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import ProductTable from "./components/ProductTable";
import Remark from "./components/Remark";
import RequisitionFooter from "./components/RequisitionFooter";
import RequisitionHeader from "./components/RequisitionHeader";
import UploadFileImage from "./components/UploadFileImage";

const { setGlobalState, clearCreate, clear } = actionRequisition;
const { clearPermission } = actionUser;

const Manage = ({ match, history }) => {
  const query = useQuery();
  const is_preview = query.get("preview") === "1";
  const dispatch = useDispatch();
  const disabled =
    useSelector(
      (state) =>
        !_.includes([null, "return"], state.requisition.documentDetail.status)
    ) || is_preview;
  const loadingSubmit = useSelector((state) => state.requisition.loadingSubmit);
  const user_permission = useSelector((state) => state.user.permission);
  const documentDetail = useSelector(
    (state) => state.requisition.documentDetail
  );
  const permissionManagerOtherAmd = useSelector(
    (state) => state.requisition.permissionManagerOtherAmd
  );
  const error = useSelector(
    (state) => state.requisition.fetch.requisition_select.error
  );
  const loading = useSelector((state) => {
    const {
      requisition_select: { loading: document_loading },
      department_code: { loading: get_department_loading },
      permission_manager_other_amd: { loading: manager_other_amd_loading },
    } = state.requisition.fetch;
    const { loading: user_loading } = state.user.fetch.permission;
    return (
      document_loading ||
      get_department_loading ||
      manager_other_amd_loading ||
      user_loading
    );
  });
  const { document_no, process: process_params } = match.params;

  useEffect(() => {
    return () => {
      dispatch(clearPermission());
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      if (path !== "requisition") {
        dispatch(clear());
      } else {
        dispatch(clearCreate());
      }
    };
  }, []);

  const handleCatchURl = (content, redirectProcess) => {
    const back_url = `/${_.split(match.path, "/")[1]}/${
      redirectProcess || process_params
    }`;
    dispatch(clear());
    history.push({ pathname: back_url });
    Modal.error({
      title: "ไม่สามารถดำเนินการได้",
      content,
      zIndex: 1080,
    });
  };

  useEffect(() => {
    if (document_no === "0") {
      if (process_params !== "pending") {
        handleCatchURl("เมนูดำเนินการเรียบร้อยไม่สามารถสร้างเอกสารได้");
      } else {
        const user = getUserObj();
        dispatch(getUserDepartmentCode(user.department_id));
        dispatch(
          setGlobalState({
            name: "documentDetail",
            value: {
              document_date: new Date(),
              requisition_by_id: user.user_id,
              requisition_by_code: user.user_code,
              requisition_by_name: user.name,
              requisition_by_department: user.department_name,
              requisition_by_department_id: user.department_id,
              requisition_by_branch_code: user.branch_code,
              requisition_by_branch_name: user.branch_name,
              division_name: user.division_name,
              division_id: user.division_id,
            },
          })
        );
      }
    } else if (document_no) {
      dispatch(getRequisitionSelect(document_no));
    }
  }, [document_no]);

  useEffect(() => {
    if (!_.includes(["pending", "complete"], process_params)) {
      handleCatchURl("URL ไม่ถูกต้อง", "pending");
    } else if (process_params === "complete" && !is_preview) {
      handleCatchURl("ไม่สามารถจัดการข้อมูลได้");
    }
  }, [process_params, is_preview]);

  useEffect(() => {
    if (documentDetail.status && !is_preview) {
      const user = getUserObj();
      dispatch(
        getUserPermission({
          document_type: "requisition",
          document_status: documentDetail.status,
          user_id: user.id,
        })
      );
      if (_.includes(["wait-manager-other", "wait-amd"], documentDetail.status))
        dispatch(
          getUserPermissionManagerOtherAmd({
            department_code: documentDetail.requisition_by_department_code,
            status: documentDetail.status,
            user_id: user.id,
          })
        );
    }
  }, [
    documentDetail.status,
    documentDetail.requisition_by_department_code,
    is_preview,
  ]);

  useEffect(() => {
    if (document_no !== "0" && !is_preview) {
      if (documentDetail.status && user_permission === false)
        handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูล");
      else if (
        documentDetail.status === "wait-manager-other" &&
        permissionManagerOtherAmd === false
      )
        handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูล");
      else if (documentDetail.status === "wait-amd") {
        if (documentDetail.amd_consider_id) {
          const user = getUserObj();
          if (user.user_id !== documentDetail.amd_consider_id)
            handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูล");
        } else if (permissionManagerOtherAmd === false)
          handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูล");
      } else if (documentDetail.status === "return") {
        const user = getUserObj();
        if (user.user_id !== documentDetail.requisition_by_id)
          handleCatchURl("ผู้ใช้งานไม่มีสิทธิ์ในการจัดการข้อมูล");
      }
    }
  }, [
    documentDetail.status,
    is_preview,
    document_no,
    user_permission,
    permissionManagerOtherAmd,
    documentDetail.amd_consider_id,
    documentDetail.requisition_by_id,
  ]);

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Panel>
      <PanelHeader>เบิกสินค้า</PanelHeader>
      <PanelBody>
        <LoadingFullScreen
          loading={loadingSubmit}
          text={`กำลังบันทึกข้อมูล...`}
        />
        <Spin spinning={loading}>
          <RequisitionHeader disabled={disabled} />
          <ProductTable disabled={disabled} />
          <Remark disabled={disabled} />
          <UploadFileImage disabled={disabled} />
          <RequisitionFooter isPreview={is_preview} />
        </Spin>
      </PanelBody>
    </Panel>
  );
};

export default Manage;
