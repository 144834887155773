import { Col, InputNumber, Row } from "antd";
import React, { useContext } from "react";
import { PromotionContext } from "../reducers";
import SwitchCondition from "./SwitchCondition";

const OrderLimit = () => {
  const {
    state: {
      data: { order_limit, order_limit_qty },
    },
    dispatch,
  } = useContext(PromotionContext);
  return (
    <>
      <SwitchCondition
        name="order_limit"
        title={
          <>
            <span>จำกัดสิทธิการซื้อต่อสมาชิก 1 คน</span>
            <small className="text-red d-block">
              (*ไม่นับรวมสิทธิในช่วงเวลาของโปรโมชั่นที่ต่างกัน)
            </small>
          </>
        }
      />
      {order_limit === 1 && (
        <Row gutter={16} className="mt-2">
          <Col
            span={23}
            offset={1}
            className="d-flex justify-content-between align-items-center"
          >
            <label className="mb-0">จำนวนสิทธิ</label>
            <InputNumber
              value={order_limit_qty}
              min={1}
              onFocus={(e) => e.target.select()}
              onChange={(e) =>
                dispatch({
                  type: "SET_DATA",
                  payload: {
                    order_limit_qty: e,
                  },
                })
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default OrderLimit;
