import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import Criteria from "./components/Criteria";
import TableBanner from "./components/TableBanner";

const BannerList = ({ history, match }) => {
  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>จัดการแบนเนอร์หมวดหมู่สินค้า</Breadcrumb.Item>
      </Breadcrumb>
      <Panel>
        <PanelHeader>จัดการแบนเนอร์หมวดหมู่สินค้า</PanelHeader>
        <PanelBody>
          <Criteria />
          <TableBanner />
        </PanelBody>
      </Panel>
    </>
  );
};

export default BannerList;
