/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LegacyQuizCategoryPage from './pages/LegacyQuizCategoryPage';
import LegacyQuizRewardPage from './pages/LegacyQuizRewardPage';
import LegacyQuizCategoryProductPage from './pages/LegacyQuizCategoryProductPage';
import LegacyQuizCategoryProductQuestionPage from './pages/LegacyQuizCategoryProductQuestionPage';
import LegacyQuizRewardReportPage from './pages/LegacyQuizRewardReportPage';
import LegacyQuizCategoryProductQuestionAnswerPage from './pages/LegacyQuizCategoryProductQuestionAnswerPage';
import LegacyQuizUserReportPage from './pages/LegacyQuizUserReportPage';

const queryClient = new QueryClient();

export default ({ match }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route
          path={`${match.path}/quiz/quiz-category`}
          exact
          children={<LegacyQuizCategoryPage />}
        />
        <Route
          path={`${match.path}/quiz/quiz-category/:categoryId`}
          exact
          children={<LegacyQuizCategoryProductPage />}
        />
        <Route
          path={`${match.path}/quiz/quiz-category/:categoryId/quiz-product/:productId`}
          exact
          children={<LegacyQuizCategoryProductQuestionPage />}
        />
        <Route
          path={`${match.path}/quiz/quiz-category/:categoryId/quiz-product/:productId/quiz-question/:questionId`}
          exact
          children={<LegacyQuizCategoryProductQuestionAnswerPage />}
        />
        <Route
          path={`${match.path}/quiz/quiz-reward/:rewardId`}
          exact
          children={<LegacyQuizRewardReportPage />}
        />
        <Route
          path={`${match.path}/quiz/quiz-reward`}
          exact
          children={<LegacyQuizRewardPage />}
        />
        <Route
          path={`${match.path}/quiz/quiz-user`}
          exact
          children={<LegacyQuizUserReportPage />}
        />
      </Switch>
    </QueryClientProvider>
  );
};
