/* eslint-disable no-unused-vars */
import { Col, DatePicker, InputNumber, Row, Switch, Typography } from "antd";
import React, { useContext } from "react";
import SelectMonth from "../../../../../../components/SelectMonth";
import { PromotionContext } from "../reducers";
import { moment } from "../../../../../../utils";
import {
  disabledEndDateSplit,
  disabledEndTimeSplit,
} from "../../../../../util/helper";
import SwitchCondition from "./SwitchCondition";

const MemberAge = () => {
  const {
    state: {
      data: {
        member_age,
        member_start,
        member_end,
        member_day,
        member_month,
        member_year,
      },
    },
    dispatch,
  } = useContext(PromotionContext);

  return (
    <>
      <SwitchCondition name="member_age" title="โปรโมชันตามอายุสมาชิก" />
      {!!member_age && (
        <>
          <Row gutter={16}>
            <Col span={11} offset={1}>
              <Typography.Text>สมัครสมาชิกภายในวันที่</Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="mb-2">
            <Col span={11} offset={2}>
              <DatePicker
                size="small"
                value={member_start}
                className="w-100 min-width-0"
                placeholder="วันที่เริ่มต้น"
                format="DD/MM/YYYY HH:mm:ss"
                showTime={true}
                defaultPickerValue={moment("00:00:00", "HH:mm:ss")}
                onChange={(e) =>
                  dispatch({
                    type: "SET_DATA",
                    payload: {
                      member_start: e,
                      member_end: null,
                    },
                  })
                }
              />
            </Col>
            <Col span={11}>
              <DatePicker
                size="small"
                value={member_end}
                className="w-100 min-width-0"
                placeholder="วันที่สิ้นสุด"
                format="DD/MM/YYYY HH:mm:ss"
                showTime={true}
                defaultPickerValue={moment("23:59:59", "HH:mm:ss")}
                disabledDate={(current) =>
                  disabledEndDateSplit(current, member_start)
                }
                disabledTime={(current) =>
                  disabledEndTimeSplit(current, member_start)
                }
                onChange={(e) => {
                  const start = moment(member_start).add(1, "s");
                  if (+moment(start).format("DD") === +moment(e).format("DD")) {
                    const current_hour = +moment(e).format("HH");
                    const start_hour = +moment(start).format("HH");
                    if (
                      current_hour === start_hour &&
                      current_hour !== +moment(member_end).format("HH")
                    ) {
                      e = moment(e).set({
                        m: +moment(start).format("mm"),
                        s: +moment(start).format("ss"),
                      });
                    }
                  }
                  dispatch({
                    type: "SET_DATA",
                    payload: {
                      member_end: e,
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={11} offset={1}>
              <Typography.Text>ระยะเวลาสมัครสมาชิก</Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="mt-2">
            <Col
              span={22}
              offset={2}
              className="d-flex justify-content-between align-items-center"
            >
              <label className="mb-0">จำนวนวัน</label>
              <InputNumber
                value={member_day}
                min={0}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  dispatch({
                    type: "SET_DATA",
                    payload: {
                      member_day: e,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={16} className="mt-2">
            <Col
              span={22}
              offset={2}
              className="d-flex justify-content-between align-items-center"
            >
              <label className="mb-0">จำนวนเดือน</label>
              <InputNumber
                value={member_month}
                min={0}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  dispatch({
                    type: "SET_DATA",
                    payload: {
                      member_month: e,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={16} className="mt-2">
            <Col
              span={22}
              offset={2}
              className="d-flex justify-content-between align-items-center"
            >
              <label className="mb-0">จำนวนปี</label>
              <InputNumber
                value={member_year}
                min={0}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  dispatch({
                    type: "SET_DATA",
                    payload: {
                      member_year: e,
                    },
                  })
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default MemberAge;
