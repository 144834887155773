/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Alert, Button, Table } from "antd";
import ReceiveContext from "../reducer";
import { bytesToSize } from "../../../../util/helper";
import { moment, URL_API } from "../../../../../utils";
import { FaCogs, FaEye } from "react-icons/fa";
import { useHttp } from "../../../../../hooks/http";

const { Column } = Table;

const DocumentTable = () => {
  const {
    state: {
      searchDocument: { page, limit, query },
      result: { reload: trigger_reload },
    },
    dispatch,
  } = useContext(ReceiveContext);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/transfer-receive/receive-in`,
      params: {
        query,
        limit,
        offset: (page - 1) * limit,
      },
      token: true,
    },
    [page, query]
  );

  useEffect(() => {
    if (trigger_reload) {
      reload();
      dispatch({
        type: "setGlobalState",
        payload: {
          name: "searchDocument",
          value: {
            reload: false,
          },
        },
      });
    }
  }, [trigger_reload]);

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Table
      loading={loading}
      bordered
      dataSource={data?.data || []}
      size="small"
      rowKey="document_no"
      scroll={{ x: "max-content" }}
      pagination={{
        current: page,
        pageSize: limit,
        total: data?.total || 0,
        showLessItems: true,
        hideOnSinglePage: true,
        onChange: (page) =>
          dispatch({
            type: "setGlobalState",
            payload: {
              name: "searchDocument",
              value: {
                page,
              },
            },
          }),
      }}
    >
      <Column
        width={150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">เลขที่เอกสาร</div>
        }
        key="document_no"
        dataIndex="document_no"
      />
      <Column
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">ชื่อไฟล์</div>}
        key="file_name"
        dataIndex="file_name"
        render={(text) => (
          <div className="min-width-100 max-width-600">{text}</div>
        )}
      />
      <Column
        width={150}
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">ขนาดไฟล์</div>}
        key="file_size"
        dataIndex="file_size"
        align="right"
        render={(text) => bytesToSize(text)}
      />
      <Column
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">ผู้นำเข้า</div>}
        key="create_by"
        dataIndex="create_by"
        render={(text) => (
          <div className="min-width-100 max-width-600">{text}</div>
        )}
      />
      <Column
        width={150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">วันที่นำเข้า</div>
        }
        key="create_date"
        dataIndex="create_date"
        render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
      />
      <Column
        width={60}
        title={<FaCogs />}
        key="action"
        align="center"
        render={({ document_no }) => (
          <Button
            type="primary"
            shape="circle"
            size="small"
            onClick={() =>
              dispatch({
                type: "setGlobalState",
                payload: {
                  name: "item",
                  value: { document_no, loading: true },
                },
              })
            }
          >
            <FaEye />
          </Button>
        )}
      />
    </Table>
  );
};

export default DocumentTable;
