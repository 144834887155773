/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, notification, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ImgError from "../../../../../../components/ImgError";
import { fixProductTypeSet } from "../../../../../../config/fix-value";
import { axios, URL_API, _ } from "../../../../../../utils";
import { resCatch } from "../../../../../util/helper";
import { Creators } from "../../../ducks/operation-form";
import { isProItemWithAmount } from "../logic/validate";

const Item = ({
  match,
  data,
  disabledAddItem,
  conditionType,
  allItems,
  ...props
}) => {
  const [source] = useState(axios.CancelToken.source());
  const type_params = match.params.type;
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );
  const isPreProduct = _.includes(["pre-sale", "pre-redeem"], type_params);

  useEffect(() => {
    return () => source.cancel("unmounted");
  }, []);

  const handleAdd = async () => {
    try {
      const result = await axios({
        url: `${URL_API}/pos/master/item/load`,
        params: {
          offset: 0,
          limit: 10,
          category: "",
          type_params,
          code: data.product_code,
          partner_type: props.partner_type,
          product_type: isNormal ? "normal" : "redeem",
          req_receipt_type: props.req_receipt_type,
          receipt_branch_code: props.receipt_branch_code,
          is_pre_order: isPreProduct ? 1 : 0,
        },
        cancelToken: source.token,
      });
      if (result.data.data?.length !== 1)
        return notification.warning({
          message: "ไม่สามารถเลือกสินค้านี้ได้",
          description: `ไม่พบรายการ รหัสสินค้า ${data.product_code}`,
        });
      const e = result.data.data[0];

      if (
        !_.includes(
          [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
          e.product_type_set
        ) &&
        (!e.stock_balance ||
          e.stock_balance < 1 + (allItems[e.product_code]?.qty || 0))
      )
        return notification.warning({
          message: "ไม่สามารถเลือกสินค้านี้ได้",
          description: "ไม่มีสินค้าใน stock",
        });

      props.addOrderItemFromPromotion({
        product_id: e.id,
        product_code: e.product_code,
        product_name: e.product_name_local,
        product_type: e.product_type,
        product_type_set: e.product_type_set,
        promotion_id: 0,
        bom: e.bom,
        sku: e.backend_sku_code,
        weight: e.weight,
        unit_price: e.unit_price,
        cv: e.point_03,
        pv: e.point_01,
        rp: e.point_02,
        tp: e.point_04,
        sp: e.point_05,
        ra: e.point_06,
        img_url: e.img_url,
        qty_rate: e.point_01_rate,
        stock_balance: e.stock_balance,
        qty: 1,
        // qty: data.qty - data.order_qty,
      });
    } catch (e) {
      resCatch(e);
    }
  };

  return (
    <Col span={24} className="d-flex align-items-center">
      <ImgError
        src={data.azure_url}
        alt={_.uniqueId("product-")}
        className="width-60 height-60 border rounded-lg object-fit-cover"
      />
      <div className="ml-3">
        <Typography.Text>{data.product_name_local} </Typography.Text>
        <Typography.Text strong>{data.order_qty}</Typography.Text>
        <Typography.Text>
          /{data.qty} {isProItemWithAmount(conditionType) ? "บาท" : "ชิ้น"}
        </Typography.Text>
        {!disabledAddItem && (
          <>
            {props.loading ? (
              <Spin spinning size="small" className="ml-2" />
            ) : (
              <Button
                ghost
                type="primary"
                size="small"
                className="ml-3"
                onClick={handleAdd}
              >
                เลือก
              </Button>
            )}
          </>
        )}
      </div>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const {
    orders: { activeKey, itemByKey },
    matching_promotion_item: { loading },
  } = state.pos.operation;

  return {
    loading,
    partner_type: itemByKey[activeKey]?.partner_type.toLowerCase() || null,
    req_receipt_type: itemByKey[activeKey]?.req_receipt_type || null,
    receipt_branch_code: itemByKey[activeKey]?.receipt_branch_code || null,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addOrderItemFromPromotion: Creators.addOrderItemFromPromotion,
    setMatchingPromotionItem: Creators.setMatchingPromotionItem,
  })(Item)
);
