/* eslint-disable no-unused-vars */
import { Col, Row, Typography } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { moment, _ } from "../../../../../utils";
import AddProduct from "./AddProduct";
import Reason from "./Reason";
import RequisitionBy from "./RequisitionBy";
import RequisitionClass from "./RequisitionClass";
import RequisitionDate from "./RequisitionDate";
import RequisitionType from "./RequisitionType";
import WarehouseCode from "./WarehouseCode";

const { Text } = Typography;

const Index = ({ disabled }) => {
  const document_no = useSelector(
    (state) => state.requisition.documentDetail.document_no
  );
  const document_date = useSelector(
    (state) => state.requisition.documentDetail.document_date
  );
  return (
    <Row gutter={[8, 8]}>
      <Col span={20}>
        <RequisitionBy />
        <Row gutter={[8, 8]}>
          <RequisitionType disabled={disabled} />
          <WarehouseCode disabled={disabled} />
          <RequisitionClass disabled={disabled} />
          <RequisitionDate disabled={disabled} />
        </Row>
        <Reason disabled={disabled} />
      </Col>
      <Col span={4} className="d-flex flex-column align-items-end">
        <Text>เลขที่เอกสาร: {document_no}</Text>
        <Text>วันที่เอกสาร: {moment(document_date).format("DD/MM/YYYY")}</Text>
        {!disabled && <AddProduct />}
      </Col>
    </Row>
  );
};

export default memo(Index);
