/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Divider, Modal, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../hooks/dom";
import { actionClaim } from "../../../redux/slices/claim";
import {
  getClaimSelect,
  getConsiderPermission,
} from "../../../redux/thunk/claim";
import { getUserObj, _ } from "../../../utils";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import ClaimFooter from "./components/ClaimFooter";
import ClaimHeader from "./components/ClaimHeader";
import ClaimIn from "./components/ClaimIn";
import ClaimOutProduct from "./components/ClaimOutProduct";
import ClaimOutVoucher from "./components/ClaimOutVoucher";
import ClaimConsiderRemark from "./components/ClaimConsiderRemark";

const { clear, setGlobalState, setState, clearCreate } = actionClaim;

const Manage = ({ match, history }) => {
  const query = useQuery();
  const user = getUserObj();
  const dispatch = useDispatch();
  const isPreview = useSelector((state) => state.claim.isPreview);
  const loadingSubmit = useSelector((state) => state.claim.loadingSubmit);
  const { loading, error } = useSelector(
    (state) => state.claim.fetch.claim_select
  );
  const consider_permission_loading = useSelector(
    (state) => state.claim.fetch.consider_permission.loading
  );
  const document_status = useSelector(
    (state) => state.claim.documentDetail.status
  );
  const claim_type = useSelector(
    (state) => state.claim.documentDetail.claim_type
  );
  const consider_status = useSelector(
    (state) => state.claim.documentDetail.consider_status
  );
  const branch_code = useSelector(
    (state) => state.claim.documentDetail.branch_code
  );
  const consider = useSelector((state) => state.claim.consider);
  const disabled = useSelector((state) =>
    _.includes([null, "draft", "waiting"], state.claim.documentDetail.status)
  );
  const { process: process_params, document_no: document_no_params } =
    match.params;

  useEffect(() => {
    return () => {
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      if (path !== "claim") {
        dispatch(clear());
      } else {
        dispatch(clearCreate());
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getConsiderPermission());
    dispatch(
      setState({ name: "isPreview", value: query.get("preview") === "1" })
    );
  }, []);

  useEffect(() => {
    if (document_no_params) {
      if (document_no_params === "0") {
        dispatch(
          setGlobalState({
            name: "documentDetail",
            value: {
              user_branch_code: user.branch_code,
              user_branch_name: user.branch_name,
              document_date: new Date(),
            },
          })
        );
      } else {
        dispatch(getClaimSelect(document_no_params));
      }
    }
  }, [document_no_params]);

  useEffect(() => {
    if (error === "notfound") handleCatchURl("ไม่พบข้อมูลเคลมสินค้า");
  }, [error]);

  useEffect(() => {
    if (
      document_no_params &&
      (document_no_params === "0" || (document_status && consider.branch))
    ) {
      const error = checkUrlPermission();
      if (error) handleCatchURl(error);
    }
  }, [document_no_params, document_status, consider.branch]);

  const checkUrlPermission = () => {
    if (process_params !== "wait" && document_no_params === "0")
      return "ไม่สามารถสร้างยื่นเรื่องเคลมได้";
    let err = {
      process_params: null,
      document_status: null,
      consider_status: null,
      user_permission: null,
    };

    switch (process_params) {
      case "wait":
        err.document_status = !_.includes(
          [null, "draft", "waiting"],
          document_status
        );
        break;
      case "pending":
        err.document_status = !_.includes(
          ["wait-branch", "wait-manager"],
          document_status
        );
        if (err.document_status || isPreview) break;
        if (document_status === "wait-branch") {
          err.user_permission = _.every(
            consider.branch,
            (n) => n.user_id !== user.user_id || n.branch_code !== branch_code
          );
        } else {
          err.user_permission = _.every(
            consider.manager,
            (n) => n.user_id !== user.user_id || n.claim_type !== claim_type
          );
        }
        break;
      case "result":
        err.document_status =
          document_status !== "considered" ||
          !_.includes(["approve", "reject"], consider_status);
        break;
      case "history":
        err.document_status =
          !_.includes(["complete", "cancel"], document_status) || !isPreview;
        break;
      default:
        err.process_params = true;
        break;
    }
    if (err.process_params) return "URL ไม่ถูกต้อง";
    if (err.document_status)
      return "สถานะรายการเคลมไม่ถูกต้อง หรือถูกดำเนินการไปแล้ว";
    if (err.user_permission) return "ผู้ใช้งานไม่มีสิทธิในการดำเนินการ";
  };

  const handleCatchURl = (content) => {
    dispatch(clear());
    const base_path = `/${_.split(match.path, "/")[1]}`;
    const sub_path = content === "URL ไม่ถูกต้อง" ? "wait" : process_params;
    history.push({
      pathname: `${base_path}/${sub_path}`,
    });
    Modal.error({
      title: "ไม่สามารถดำเนินการได้",
      content,
      zIndex: 1080,
    });
  };

  // const handleClickTab = useCallback((e) => setActiveTab(e), []);

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Panel>
      <PanelHeader>เคลมสินค้า</PanelHeader>
      <PanelBody>
        <LoadingFullScreen
          loading={loadingSubmit}
          text={`กำลังบันทึกข้อมูล...`}
        />
        <Spin spinning={loading || consider_permission_loading}>
          <ClaimHeader />
          <ClaimIn />
          <ClaimConsiderRemark />
          {!disabled &&
            (process_params === "pending" || consider_status === "approve") && (
              <>
                <Divider />
                <ClaimOutProduct />
                <ClaimOutVoucher />
              </>
            )}
          <ClaimFooter />
        </Spin>
      </PanelBody>
    </Panel>
  );
};

export default Manage;
