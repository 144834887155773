/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  Col,
  message,
  Row,
  Spin,
  Statistic,
  Table,
  Typography,
} from "antd";
import numeral from "numeral";
import React from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../hooks/http2";
import { URL_API, _ } from "../../utils";

export default function Home() {
  const { loading, fetch, error } = useApi({
    url: `${URL_API}/home/dashboard`,
    token: true,
  });

  if (error) {
    message.error(error);
  }

  return (
    <div></div>
    // <Spin spinning={loading}>
    //   <Row gutter={32}>
    //     <Col md={8}>
    //       <Card bordered={false}>
    //         <Statistic
    //           title="ยอดขายวันนี้ (ทุกสาขา)"
    //           value={fetch?.grand_total_amount || 0}
    //           suffix="บาท"
    //         />
    //       </Card>
    //     </Col>
    //     <Col md={8}>
    //       <Card bordered={false}>
    //         <Statistic
    //           title="จำนวนบิลวันนี้ (ทุกสาขา)"
    //           value={fetch?.total_bill || 0}
    //           suffix="ใบ"
    //         />
    //       </Card>
    //     </Col>
    //     <Col md={8}>
    //       <Card bordered={false}>
    //         <Statistic
    //           title={`ยอดขาย/จำนวนบิลวันนี้ (${fetch?.my_branch?.branch_name})`}
    //           value={fetch?.my_branch?.grand_total_amount || 0}
    //           suffix={`/ ${fetch?.my_branch?.total_bill || 0}`}
    //         />
    //       </Card>
    //     </Col>
    //   </Row>
    //   <Row className="mt-4" gutter={32}>
    //     <Col md={12}>
    //       <Card bordered={false}>
    //         <Typography.Title level={4}>
    //           ยอดขายวันนี้ (แยกตามสาขา)
    //         </Typography.Title>
    //         <Table
    //           pagination={false}
    //           size="small"
    //           rowKey="branch_code"
    //           dataSource={fetch?.branch_sale || []}
    //         >
    //           <Table.Column title="สาขา" dataIndex="branch_name" />
    //           <Table.Column
    //             title="ยอดขาย (บาท)"
    //             align="right"
    //             dataIndex="grand_total_amount"
    //             render={(text) => (text ? numeral(text).format("0,0") : "-")}
    //           />
    //           <Table.Column
    //             title="จำนวนบิล (ใบ)"
    //             align="right"
    //             dataIndex="total_bill"
    //             render={(text) => (text ? numeral(text).format("0,0") : "-")}
    //           />
    //         </Table>
    //       </Card>
    //     </Col>
    //     <Col md={12}>
    //       <Card bordered={false} className="mb-4">
    //         <Typography.Title level={4}>
    //           รายการเอกสารรอดำเนินการ
    //         </Typography.Title>
    //         <Table
    //           pagination={false}
    //           size="small"
    //           dataSource={(fetch?.flow || []).map((n) => ({
    //             ...n,
    //             key: _.uniqueId("flow-"),
    //           }))}
    //         >
    //           <Table.Column
    //             title="ประเภทเอกสาร"
    //             dataIndex="document_name"
    //             render={(text, record) => `${text} #${record.subject}`}
    //           />
    //           <Table.Column
    //             key="link"
    //             title="รายละเอียด"
    //             align="center"
    //             render={(text, record) => (
    //               <Link to={`${record.url}/${record.id}`} target="_blank">
    //                 <Button type="link" size="small">
    //                   รายละเอียด
    //                 </Button>
    //               </Link>
    //             )}
    //           />
    //         </Table>
    //       </Card>
    //       <Card bordered={false}>
    //         <Typography.Title level={4}>
    //           10 อันดับสินค้าขายดีประจำวัน
    //         </Typography.Title>
    //         <Table
    //           pagination={false}
    //           size="small"
    //           rowKey="product_code"
    //           dataSource={fetch?.top_sale || []}
    //         >
    //           <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
    //           <Table.Column title="ชื่อสินค้า" dataIndex="product_name" />
    //           <Table.Column
    //             title="ยอดขาย (ชิ้น)"
    //             align="right"
    //             dataIndex="qty"
    //           />
    //         </Table>
    //       </Card>
    //     </Col>
    //   </Row>
    // </Spin>
  );
}
