import { Col, Input, Row, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionTransfer } from "../../../../../redux/slices/transfer";
import { moment, _ } from "../../../../../utils";
//import SelectBranch from "../../../pos/components/SelectBranch";
import SelectWarehouse from "../SelectWarehouse";
import SelectReason from "./SelectReason";
import SelectBranch from "./SelectBranch";

const { Text } = Typography;

const { setGlobalState } = actionTransfer;

const Manage = () => {
  const dispatch = useDispatch();
  const {
    document_no,
    document_date,
    from_branch_code,
    from_wh_code,
    to_branch_code,
    to_wh_code,
    reason,
    bill_ref_document_no,
    other_reason,
    detail,
    gen_from_receive,
  } = useSelector((state) => state.transfer.document);

  return (
    <Row gutter={[8, 8]} type="flex">
      <Col span={24} order={2} md={12} xxl={{ span: 9, order: 1 }}>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={4} className="text-right">
            <Text>โอนจากสาขา</Text>
          </Col>
          <Col span={20}>
            <SelectBranch
              className="w-100"
              userLogin={true}
              value={from_branch_code}
              disabled={!!document_no}
              onChange={(from_branch_code) => {
                dispatch(
                  setGlobalState({
                    name: "document",
                    value: { from_branch_code, from_wh_code: null },
                  })
                );
                dispatch(
                  setGlobalState({
                    name: "product",
                    value: { itemKeys: [], itemByKey: {} },
                  })
                );
              }}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={4} className="text-right">
            <Text>โอนไปสาขา</Text>
          </Col>
          <Col span={20}>
            <SelectBranch
              className="w-100"
              value={to_branch_code}
              disabled={!!document_no}
              onChange={(to_branch_code) =>
                dispatch(
                  setGlobalState({
                    name: "document",
                    value: { to_branch_code, to_wh_code: null },
                  })
                )
              }
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={4} className="text-right">
            <Text>เหตุผลการโอน</Text>
          </Col>
          <Col span={20}>
            <SelectReason
              value={reason}
              disabled={gen_from_receive !== null}
              notIn={gen_from_receive === null ? ["04", "05", "06"] : []}
              onChange={(reason) =>
                dispatch(
                  setGlobalState({
                    name: "document",
                    value: {
                      reason,
                      bill_ref_document_no: reason === "00" ? "" : null,
                      other_reason: _.includes(["03", "04"], reason)
                        ? ""
                        : null,
                    },
                  })
                )
              }
            />
          </Col>
        </Row>
        {reason === "00" ? (
          <Row gutter={[8, 8]} type="flex" className="align-items-center">
            <Col span={4} className="text-right">
              <Text>เลขที่บิล</Text>
            </Col>
            <Col span={20}>
              <Input
                autoComplete="off"
                value={bill_ref_document_no}
                maxLength={50}
                onChange={(e) =>
                  dispatch(
                    setGlobalState({
                      name: "document",
                      value: { bill_ref_document_no: e.target.value },
                    })
                  )
                }
              />
            </Col>
          </Row>
        ) : (
          _.includes(["03", "04"], reason) && (
            <Row gutter={[8, 8]} type="flex" className="align-items-center">
              <Col span={4} className="text-right">
                <Text>ระบุ</Text>
              </Col>
              <Col span={20}>
                <Input
                  autoComplete="off"
                  value={other_reason}
                  maxLength={200}
                  onChange={(e) =>
                    dispatch(
                      setGlobalState({
                        name: "document",
                        value: { other_reason: e.target.value },
                      })
                    )
                  }
                />
              </Col>
            </Row>
          )
        )}
      </Col>
      <Col span={24} order={3} md={12} xxl={{ span: 9, order: 2 }}>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={4} className="text-right">
            <Text>จากคลัง</Text>
          </Col>
          <Col span={20}>
            <SelectWarehouse
              branchCode={from_branch_code}
              disabled={!from_branch_code || !!document_no}
              value={from_wh_code}
              onChange={(from_wh_code, e) => {
                dispatch(
                  setGlobalState({
                    name: "document",
                    value: { from_wh_code },
                  })
                );
                dispatch(
                  setGlobalState({
                    name: "product",
                    value: { itemKeys: [], itemByKey: {} },
                  })
                );
              }}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={4} className="text-right">
            <Text>ไปคลัง</Text>
          </Col>
          <Col span={20}>
            <SelectWarehouse
              branchCode={to_branch_code}
              disabled={!to_branch_code || !!document_no}
              value={to_wh_code}
              notIn={[from_wh_code]}
              onChange={(to_wh_code) =>
                dispatch(
                  setGlobalState({
                    name: "document",
                    value: { to_wh_code },
                  })
                )
              }
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={4} className="text-right">
            <Text>ระบุรายละเอียด</Text>
          </Col>
          <Col span={20}>
            <Input
              autoComplete="off"
              value={detail}
              maxLength={500}
              onChange={(e) =>
                dispatch(
                  setGlobalState({
                    name: "document",
                    value: { detail: e.target.value },
                  })
                )
              }
            />
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        order={1}
        xxl={{ span: 6, order: 3 }}
        className="d-flex flex-column align-items-end"
      >
        <Row gutter={[8, 8]} className="mb-1">
          <Col span={24} className="d-flex flex-column align-items-end">
            <div>
              <Text strong className="mr-2">
                เลขที่ใบโอนย้าย :
              </Text>
              <Text>{document_no}</Text>
            </div>
            <div>
              <Text strong className="mr-2">
                วันที่ออก :
              </Text>
              <Text>
                {moment(document_date || new Date()).format("DD/MM/YYYY")}
              </Text>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Manage;
