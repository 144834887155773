import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import NewsForm from '../form/NewsForm';
import { newsFormDefaultValues } from '../dto/news';

const NewsModal = ({ open, handleClose, categoryId }) => {
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createNews } = useAsync(fitStudioService.createNews, {
    onSuccess: () => {
      AlertSuccess('เพิ่มข่าวสารสำเร็จ');
      reset(newsFormDefaultValues);
      handleClose();
    },
  });

  const { execute: editNews } = useAsync(fitStudioService.editNews, {
    onSuccess: () => {
      AlertSuccess('แก้ไขข่าวสารสำเร็จ');
      reset(newsFormDefaultValues);
      handleClose();
    },
  });

  const _HandleSubmit = handleSubmit((data) => {
    if (watch('formType') === 'create') {
      const newsContentBoxes = data.newsContentBoxes.map((item) => {
        const result = item;
        delete result.id;
        return result;
      });
      const newsData = {
        title: data.title,
        thumbnailImageUrl: data.thumbnailImageUrl,
        subTitle: data.subTitle,
        categoryId: categoryId,
        newsContentBoxes,
      };
      createNews(newsData);
    } else if (watch('formType') === 'edit') {
      const newsData = {
        title: data.title,
        thumbnailImageUrl: data.thumbnailImageUrl,
        subTitle: data.subTitle,
        categoryId: categoryId,
        newsContentBoxes: data.newsContentBoxes,
      };
      editNews({ id: data.id, data: newsData });
    }
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'สร้างข่าวสาร' : 'แก้ไขข่าวสาร'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <NewsForm />
    </Modal>
  );
};

export default NewsModal;
