/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import numeral from "numeral";
import React, { memo, useEffect, useState } from "react";
import { FaCogs, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ValidateError from "../../../../../components/ValidateError";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatch } from "../../../../util/helper";
import SelectColumn from "./SelectColumn";
import { numDec, numInt } from "../../../../util/helper/map-data";

const { Column } = Table;
const { Text } = Typography;

const { setProduct, delProduct } = actionRequisition;

const Index = ({ disabled }) => {
  const [sourceCode, setSourceCode] = useState([]);
  const [eventProject, setEventProject] = useState([]);
  const [loadingSelectColumn, setLoadingSelectColumn] = useState(false);
  const dispatch = useDispatch();
  const dataSource = useSelector((state) => {
    const { itemKeys, itemByKey } = state.requisition.product;
    return _.map(itemKeys, (key) => ({ ...itemByKey[key], key }));
  });
  const product_error = useSelector((state) => state.requisition.error.product);

  const loadSelectColumn = async () => {
    setLoadingSelectColumn(true);
    let res = null;
    try {
      const req1 = axios({
        url: `${URL_API}/setting/master/source-code/active`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      const req2 = axios({
        url: `${URL_API}/setting/master/event-project/active`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      res = await Promise.all([req1, req2]);
    } catch (e) {
      resCatch(e);
    }
    setSourceCode(res[0].data.data);
    setEventProject(res[1].data.data);
    setLoadingSelectColumn(false);
  };

  useEffect(() => {
    if (!disabled) loadSelectColumn();
  }, [disabled]);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Table
            bordered
            size="small"
            dataSource={dataSource}
            rowKey="key"
            scroll={{ x: "max-content" }}
            pagination={false}
            footer={() => (
              <>
                <p className="mb-0">
                  จำนวนรวม:{" "}
                  {numInt(
                    _.reduce(
                      dataSource || [],
                      (sum, n) => (sum += n.qty || 0),
                      0
                    )
                  )}{" "}
                  ชิ้น
                </p>
                <p className="mb-0">
                  ราคารวม:{" "}
                  {numDec(
                    _.reduce(
                      dataSource || [],
                      (sum, n) => (sum += (n.qty || 0) * (n.distributor_price || 0)),
                      0
                    )
                  )}{" "}
                  บาท
                </p>
              </>
            )}
          >
            <Column
              width={50}
              title="#"
              key="row_id"
              dataIndex="row_id"
              align="center"
              render={(_text, _record, index) =>
                numeral(index + 1).format("0,0")
              }
            />
            <Column
              width={80}
              title="รหัสสินค้า"
              key="product_code"
              dataIndex="product_code"
              align="center"
            />
            <Column
              className="position-relative min-width-150 max-width-600"
              title={
                <div className="ant-table-absolute-title-center">
                  ชื่อสินค้า
                </div>
              }
              key="product_name"
              dataIndex="product_name"
            />
            <Column
              width={100}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">จำนวน</div>
              }
              key="qty"
              dataIndex="qty"
              align="right"
              render={(text, { key, stock_balance }) =>
                disabled ? (
                  <Text>{numeral(text).format("0,0")}</Text>
                ) : (
                  <InputNumber
                    size="small"
                    min={1}
                    max={stock_balance}
                    value={text}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      const qty = e ?? 1;
                      dispatch(
                        setProduct({
                          key,
                          value: { qty },
                        })
                      );
                    }}
                  />
                )
              }
            />
            <Column
              width={100}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">ราคา</div>
              }
              key="distributor_price"
              dataIndex="distributor_price"
              align="right"
              render={(text) => numeral(text).format()}
            />
            <Column
              width={100}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">หน่วย</div>
              }
              key="unit"
              dataIndex="unit"
            />
            <Column
              width={200}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  Source Code
                </div>
              }
              key="source_code"
              render={({ source_code, source_name, key }) =>
                disabled ? (
                  source_name
                ) : (
                  <SelectColumn
                    data={sourceCode}
                    loading={loadingSelectColumn}
                    value={source_code}
                    onChange={(source_code, option) =>
                      dispatch(
                        setProduct({
                          key,
                          value: {
                            source_code,
                            source_name: option?.props.children || null,
                          },
                        })
                      )
                    }
                  />
                )
              }
            />
            <Column
              width={200}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  Event/Project
                </div>
              }
              key="event_project_code"
              render={({ event_project_name, event_project_code, key }) =>
                disabled ? (
                  event_project_name
                ) : (
                  <SelectColumn
                    data={eventProject}
                    loading={loadingSelectColumn}
                    value={event_project_code}
                    onChange={(event_project_code, option) =>
                      dispatch(
                        setProduct({
                          key,
                          value: {
                            event_project_code,
                            event_project_name: option?.props.children || null,
                          },
                        })
                      )
                    }
                  />
                )
              }
            />
            <Column
              width={200}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">หมายเหตุ</div>
              }
              key="remark"
              dataIndex="remark"
              render={(text, { key }) =>
                disabled ? (
                  text
                ) : (
                  <Input
                    size="small"
                    autoComplete="off"
                    maxLength={500}
                    value={text}
                    onChange={(e) =>
                      dispatch(
                        setProduct({ key, value: { remark: e.target.value } })
                      )
                    }
                  />
                )
              }
            />
            {!disabled && (
              <Column
                title={<FaCogs />}
                align="center"
                width={50}
                key="action-del"
                render={(_text, { key }) => (
                  <Popconfirm
                    title="ต้องการลบรายการนี้หรือไม่"
                    onConfirm={() => dispatch(delProduct(key))}
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                    placement="topRight"
                    zIndex={1080}
                  >
                    <Button size="small" ghost type="danger">
                      <FaTrashAlt size={10} />
                    </Button>
                  </Popconfirm>
                )}
              />
            )}
          </Table>
        </Col>
      </Row>
      <ValidateError error={product_error} />
    </>
  );
};

export default memo(Index);
