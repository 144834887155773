import { Col, Row, Typography, Upload } from "antd";
import React, { useCallback, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { RiFileUploadLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  actionProduct,
  // formSelector,
  mediaSelector,
} from "../../../../../../../redux/slices/product";
import styles from "../../../../../../../scss/module/claim.module.scss";
import { _ } from "../../../../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../../../util/helper";
import BlockItem from "./BlockItem";
import ImagePreview from "./ImagePreview";

const initialPreview = {
  id: null,
  src: null,
  visible: false,
};

const Index = () => {
  const dispatch = useDispatch();
  const { itemKeys, itemByKey } = useSelector(mediaSelector);
  // const { status } = useSelector(formSelector);
  const { ADD_MEDIA, SET_MEDIA_DEFAULT } = actionProduct;

  const [showPreview, setShowPreview] = useState(initialPreview);

  const upload = 
  _.orderBy(
  _.map(
    itemKeys, (key) => itemByKey[key]),
    ['is_default', 'index_img','id'],
    ['desc', 'asc','asc']
  );
  // const upload = _.map(itemKeys, (key) => itemByKey[key]);
  const media_default = _.find(upload || [], (n) => n.is_default);

  const handleChange = async (data) => {
    let file = data.file.originFileObj;
    if (file) {
      let img_url = "";
      if (data.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }

      dispatch(ADD_MEDIA({ img_url, file }));
    }
  };

  const handleCancel = useCallback(() => {
    setShowPreview(initialPreview);
  }, []);

  return (
    <>
      {media_default && (
        <Row gutter={16} className="mb-3">
          <Col span={4} md={3} xxl={2}>
            <Typography.Text>รูปตั้งต้น</Typography.Text>
          </Col>
          <Col span={20} md={21} xxl={22}>
            <div className={styles["new-upload"]}>
              <BlockItem
                data={media_default}
                onPreview={() =>
                  setShowPreview({
                    id: media_default.id,
                    src: media_default.img_url,
                    visible: true,
                  })
                }
              />
            </div>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={4} md={3} xxl={2}>
          <Typography.Text>รูปทั้งหมด</Typography.Text>
        </Col>
        <Col span={20} md={21} xxl={22}>
          <div className={styles["new-upload"]}>
            {/* {["", "draft"].includes(status) && ( */}
            <div className={`${styles.block} ${styles.btn}`}>
              <AiOutlineUpload className="mb-2" />
              <Typography.Text>อัปโหลด</Typography.Text>
              <div className={styles.add}>
                <Upload
                  name="claim-img"
                  showUploadList={false}
                  beforeUpload={beforeUploadImg}
                  onChange={handleChange}
                  customRequest={() => {}}
                >
                  <RiFileUploadLine className={styles.icon} />
                </Upload>
                {/* <MdPhotoCamera
            className={styles.icon}
              onClick={() =>
                dispatch({
                  type: "setCameraModal",
                  payload: { visible: true },
                })
              }
          /> */}
              </div>
              {/* <CameraModal
            visible={cameraModal.visible}
            allowChangeCamera
            onSubmit={handleSubmitCamera}
            onCancel={handleCloseCamera}
          /> */}
            </div>
            {/* )} */}
            {upload.map((n, i) => (
              <BlockItem
                key={i}
                data={n}
                onPreview={() =>
                  setShowPreview({
                    id: n.id,
                    src: n.img_url,
                    visible: true,
                  })
                }
                onSetDefault={() =>
                  dispatch(SET_MEDIA_DEFAULT({ media_key: n.media_key }))
                }
              />
            ))}
            <ImagePreview {...showPreview} onCancel={handleCancel} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Index;
