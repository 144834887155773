/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Col,
  Empty,
  Modal,
  notification,
  Row,
  Skeleton,
  Typography,
} from "antd";
import numeral from "numeral";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import NoImg from "../../../../assets/img/pos/no-img.jpg";
import img from "../../../../assets/img/pos/user-default.png";
import ImagePreview from "../../../../components/ImagePreview";
import UploadFileButtonModal from "../../../../components/UploadFileButtonModal";
import styles from "../../../../scss/module/claim.module.scss";
import { axios, getToken, moment, URL_API } from "../../../../utils";
import { resCatch, resCatchModal } from "../../../util/helper";
import RowRecord from "../../claim/components/RowRecord";
import BomDetail from "./BomDetail";

const qrColumns = [
  {
    title: "",
  },
  {
    title: "บาร์โค้ด",
  },
  {
    title: "รหัสสินค้า",
  },
  {
    title: "Qr Running",
  },
  {
    title: "รหัสนักธุรกิจ",
  },
  {
    title: "ชื่อ-นามสกุล",
  },
];

const tabList = [
  { title: "ใบเสร็จ", value: "bill" },
  { title: "ที่อยู่จัดส่ง", value: "address" },
  { title: "Qr Tracking", value: "qr" },
  { title: "เอกสารมอบอำนาจ", value: "doc" },
  { title: "สถานะสินค้า", value: "ship" },
];

const BillPreview = ({ id, preview, setPreview }) => {
  const [bill, setBill] = useState([]);
  const [billItem, setBillItem] = useState([]);
  const [billQr, setBillQr] = useState([]);
  const [moreInfo, setMoreInfo] = useState(null);
  const [address, setAddress] = useState({});
  const [imgSrc, setImgSrc] = useState(img);
  const [operator, setOperator] = useState({});
  const [tabActive, setTabActive] = useState("bill");
  const [authorityDoc, setAuthorityDoc] = useState(null);
  const [documentNo, setDocumentNo] = useState(null);
  const [ship, setShip] = useState({});
  const [warehouse, setWarehouse] = useState([]);
  const [reload, setReload] = useState(false);
  const [billColumns, setBillColumns] = useState([]);
  const [salesType, setSalesType] = useState("");
  // const [otherData, setOtherData] = useState({});

  useEffect(() => {
    if (id && preview) {
      axios({
        baseURL: URL_API,
        url: `/pos/master/bill/${id}`,
      })
        .then((res) => {
          if (res.data.data === null) {
            handleCancel();
            return Modal.error({
              title: "ไม่สามารดำเนินการได้",
              content: `ไม่พบข้อมูลใบเสร็จ id ${id}`,
            });
          }
          const {
            document_date = new Date(),
            document_no = "",
            document_status = "",
            partner_code = "",
            partner_name = "",
            req_receipt = "",
            req_receipt_type_text = "",
            sales_type = "",
            sales_type_text = "",
            ship_status = "",
            consignment = "",
            transport_name = "",
            buyer_name = "",
            buyer_mobile = "",
            bill_location = "",
            order_type = "",
            items = [],
            qr = [],
            buyer_img = "",
            authority_doc = [],
            hold_expire_date = "",
            ship_to = "",
            ship_mobile = "",
            ship_address_no = "",
            ship_sub_district = "",
            ship_district = "",
            ship_province = "",
            ship_post_code = "",
            ref_document_no = "",
            ref_document_date = "",
            partner_remark = "",
            // delivery_amount = "",
            // total_amount = 0,
            total_redeem = 0,
            // discount_amount = 0,
            // cv = 0,
            // tp = 0,
            more_info = "",
            pv = 0,
            rp = 0,
            grand_total_amount = 0,
            partner_type = "",
            effective_date = "",
            pre_product = [],
          } = res.data.data;

          const dataBill = [
            [
              {
                title: "วันที่ออกใบเสร็จ",
                value: moment(document_date).format("DD/MM/YYYY HH:mm"),
              },
              {
                title: "เลขที่ใบเสร็จ",
                value: document_no,
              },
            ],
            [
              {
                title: "วันที่จอง",
                value: ref_document_date
                  ? moment(ref_document_date).format("DD/MM/YYYY HH:mm")
                  : "",
              },
              {
                title: "เลขที่ใบจอง",
                value: ref_document_no,
              },
            ],
            [
              {
                title: "รหัสสมาชิก",
                value: partner_code,
              },
              {
                title: "ชื่อสมาชิก",
                value: partner_name,
              },
            ],
            [
              {
                title: "สถานะใบเสร็จ",
                value: document_status,
              },
              {
                title: "สาขา",
                value: bill_location,
              },
            ],
            [
              {
                title: "ประเภทการซื้อ",
                value: order_type,
              },
              {
                title:
                  partner_type?.toLowerCase() === "distributor"
                    ? "แจงยอดภายใน"
                    : "คะแนนเข้าระบบวันที่",
                value: (
                  <span className="text-red">
                    {partner_type?.toLowerCase() === "distributor" &&
                    hold_expire_date
                      ? moment(hold_expire_date).format("DD/MM/YYYY")
                      : partner_type?.toLowerCase() === "member" &&
                        effective_date
                      ? moment(effective_date).format("DD/MM/YYYY")
                      : ""}
                  </span>
                ),
              },
            ],
            [
              {
                title: "การรับสินค้า",
                value: req_receipt_type_text,
              },
              {
                title: "ใบเสร็จ",
                value: req_receipt,
              },
            ],
            [
              {
                title: "ประเภทใบเสร็จ",
                value: sales_type_text,
              },
              {
                title:
                  sales_type?.toLowerCase() === "redeem"
                    ? "คะแนนที่ใช้รวม"
                    : "ราคารวม (Vat)",
                value: numeral(
                  sales_type?.toLowerCase() === "redeem"
                    ? total_redeem
                    : grand_total_amount
                ).format("0,0.00"),
              },
            ],
            [
              {
                title: "คะแนนรวม PV",
                value: numeral(pv).format("0,0"),
              },
              {
                title: "คะแนนรวม RP",
                value: numeral(rp).format("0,0"),
              },
            ],
          ];
          const address = {
            ship_to,
            ship_mobile,
            ship_address_no,
            ship_sub_district,
            ship_district,
            ship_province,
            ship_post_code,
          };
          setOperator({
            buyer_name,
            buyer_mobile,
          });
          setAuthorityDoc({
            partner_remark,
            authority_doc,
          });
          setDocumentNo(document_no);
          setAddress(address);
          setBill(dataBill);
          setBillItem(items);
          setBillQr(qr);
          setMoreInfo(more_info);
          setImgSrc(buyer_img?.azure_url || img);
          setShip({
            transport_name,
            consignment,
            status: ship_status,
          });
          setWarehouse(pre_product)
          let columns = [];
          if (sales_type?.toLowerCase() === "redeem") {
            columns = [
              {
                title: "",
              },
              {
                title: "รหัสสินค้า",
              },
              {
                title: "ชื่อสินค้า",
              },
              {
                title: "คะแนนแลก",
              },
              {
                title: "จำนวน",
              },
              {
                title: "คะแนนแลกรวม",
              },
            ];
          } else {
            columns = [
              {
                title: "",
              },
              {
                title: "รหัสสินค้า",
              },
              {
                title: "ชื่อสินค้า",
              },
              {
                title: "ราคาสินค้า",
              },
              {
                title: "คะแนน/หน่วย",
              },
              {
                title: "จำนวน",
              },
              {
                title: "คะแนนรวม",
              },
              {
                title: "เงินรวม",
              },
            ];
          }

          setBillColumns(columns);
          setSalesType(sales_type);
          // setOtherData({
          //   delivery_amount,
          //   total_amount,
          //   total_redeem,
          //   discount_amount,
          //   cv,
          //   pv,
          //   tp,
          //   rp,
          //   grand_total_amount,
          // });
        })
        .catch((err) => {
          resCatch(err);
        });
    }
  }, [id, preview, reload]);

  const handleCancel = useCallback(() => {
    setBill([]);
    setBillItem([]);
    setAddress({});
    setImgSrc(img);
    setOperator({});
    setTabActive("bill");
    setAuthorityDoc("");
    setPreview(false);
  }, []);

  return (
    <Modal
      visible={preview}
      footer={false}
      destroyOnClose={true}
      width={1000}
      zIndex={1080}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={7}>
          <div className="width-200 height-200 overflow-hidden rounded-circle mb-3 shadow">
            <img
              src={imgSrc}
              className="w-100 h-100 object-fit-cover"
              alt="Profile"
            />
          </div>
          <Row>
            <Col span={7} className="d-flex flex-column">
              <Typography.Text className="mb-2" strong>
                ผู้ดำเนินการ
              </Typography.Text>
              <Typography.Text className="mb-2" strong>
                เบอร์โทร
              </Typography.Text>
            </Col>
            <Col span={17} className="d-flex flex-column">
              <Typography.Text className="mb-2">
                {operator.buyer_name}
              </Typography.Text>
              <Typography.Text className="mb-2">
                {operator.buyer_mobile}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={17} className="pt-2">
          {bill.length === 0 && preview && <Skeleton />}
          <RowRecord dataSource={bill} row={2} />
        </Col>
      </Row>

      <Row className="mb-4 mt-4">
        <Col span={22} offset={1}>
          <ul className="full-tabs">
            {tabList.map((n, i) => (
              <li
                key={i}
                className={`${tabActive === n.value ? "active" : ""}`}
                onClick={() => {
                  setTabActive(n.value);
                }}
              >
                <Typography.Title level={4}>
                  <a>{n.title}</a>
                </Typography.Title>
              </li>
            ))}
          </ul>
        </Col>
      </Row>

      {tabActive === "bill" ? (
        <Bill
          data={billItem}
          moreInfo={moreInfo}
          billColumns={billColumns}
          salesType={salesType}
        />
      ) : tabActive === "address" ? (
        <Address data={address} />
      ) : tabActive === "qr" ? (
        <QrTracking data={billQr} />
      ) : tabActive === "doc" ? (
        <AuthorityDocument
          partnerRemark={authorityDoc?.partner_remark}
          document={authorityDoc?.authority_doc}
          documentNo={documentNo}
          setReload={setReload}
        />
      ) : (
        <ShipStatus data={ship} warehouse={warehouse} />
      )}
    </Modal>
  );
};

const Bill = React.memo(({ data, moreInfo, billColumns = [], salesType }) => (
  <>
    <Row className="bg-gray-60 mt-3">
      <Col className="px-4 d-flex align-items-center">
        <Typography.Text strong className="my-2 text-white">
          รายการสินค้า
        </Typography.Text>
      </Col>
    </Row>
    <Row className="bg-gray-20">
      <Col className="px-4 py-3">
        <table className="w-100">
          <thead>
            <tr>
              {billColumns.map((n, i) => (
                <td key={i} className="text-center text-bolder">
                  {n.title}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <React.Fragment key={i}>
                <tr>
                  <td>{i + 1}</td>
                  <td className="text-center">{item.product_code}</td>
                  <td>{item.product_name}</td>
                  <td className="text-center">
                    {numeral(
                      salesType?.toLowerCase() === "redeem"
                        ? item.redeem_point_item
                        : item.unit_price
                    ).format() || "-"}
                  </td>
                  {salesType?.toLowerCase() !== "redeem" && (
                    <td className="text-center">{item.pv}</td>
                  )}
                  <td className="text-center">{item.qty}</td>
                  {salesType?.toLowerCase() !== "redeem" && (
                    <td className="text-center">{item.total_pv}</td>
                  )}
                  <td className="text-center">
                    {numeral(
                      salesType?.toLowerCase() === "redeem"
                        ? item.redeem_point
                        : item.total_price
                    ).format() || "-"}
                  </td>
                </tr>
                {item.bom && item.bom.length > 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <BomDetail list={item.bom} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>

    <Row className="bg-gray-60 mt-3">
      <Col span={24} className="px-4 d-flex align-items-center">
        <Typography.Text strong className="my-2 text-white">
          ข้อมูลเพิ่มเติม
        </Typography.Text>
      </Col>
    </Row>
    <Row className="bg-gray-20 pt-3 pb-3">
      <Col span={24} className="px-4 d-flex flex-column">
        {moreInfo ? <Typography.Text>{moreInfo}</Typography.Text> : <Empty />}
      </Col>
    </Row>
    {/* <Row className="bg-gray-20 pt-3 pb-3">
      <Col span={4} className="px-4 d-flex flex-column">
        <Typography.Text className="mb-2" strong>
          ค่าจัดส่ง
        </Typography.Text>
        <Typography.Text className="mb-2" strong>
          รวมเงิน
        </Typography.Text>
        <Typography.Text className="mb-2" strong>
          รวมส่วนลด
        </Typography.Text>
        <Typography.Text className="mb-2" strong>
          คะแนน CV
        </Typography.Text>
        <Typography.Text className="mb-2" strong>
          คะแนน PV
        </Typography.Text>
        <Typography.Text className="mb-2" strong>
          คะแนน TP
        </Typography.Text>
        <Typography.Text className="mb-2" strong>
          คะแนน RP
        </Typography.Text>
        <Typography.Text strong>รวม</Typography.Text>
      </Col>
      <Col span={20} className="px-4 d-flex flex-column">
        <Typography.Text className="mb-2">
          {otherData.delivery_amount}
        </Typography.Text>
        <Typography.Text className="mb-2">
          {otherData.total_amount || otherData.total_redeem}
        </Typography.Text>
        <Typography.Text className="mb-2">
          {otherData.discount_amount}
        </Typography.Text>
        <Typography.Text className="mb-2">{otherData.cv}</Typography.Text>
        <Typography.Text className="mb-2">{otherData.pv}</Typography.Text>
        <Typography.Text className="mb-2">{otherData.tp}</Typography.Text>
        <Typography.Text className="mb-2">{otherData.rp}</Typography.Text>
        <Typography.Text>{otherData.grand_total_amount}</Typography.Text>
      </Col>
    </Row> */}
  </>
));

const Address = React.memo(({ data }) => {
  const [haveData, setHaveData] = useState(false);
  useEffect(() => {
    let result = false;
    for (const property in data) {
      if (data[property] !== null && data[property] !== undefined) {
        result = true;
        break;
      }
    }
    setHaveData(result);
  }, [data]);
  return (
    <>
      <Row className="bg-gray-60 mt-3">
        <Col span={24} className="px-4 d-flex align-items-center">
          <Typography.Text strong className="my-2 text-white">
            รายละเอียดที่อยู่จัดส่ง
          </Typography.Text>
        </Col>
      </Row>
      <Row className="bg-gray-20 pt-3 pb-3">
        {haveData ? (
          <>
            <Col span={4} className="px-4 d-flex flex-column">
              <Typography.Text className="mb-2" strong>
                ชื่อผู้รับ
              </Typography.Text>
              <Typography.Text className="mb-2" strong>
                เบอร์โทร
              </Typography.Text>
              <Typography.Text className="mb-2" strong>
                ที่อยู่
              </Typography.Text>
              <Typography.Text className="mb-2" strong>
                ตำบล
              </Typography.Text>
              <Typography.Text className="mb-2" strong>
                อำเภอ
              </Typography.Text>
              <Typography.Text className="mb-2" strong>
                จังหวัด
              </Typography.Text>
              <Typography.Text strong>รหัสไปรษณีย์</Typography.Text>
            </Col>
            <Col span={20} className="px-4 d-flex flex-column">
              <Typography.Text className="mb-2">{data.ship_to}</Typography.Text>
              <Typography.Text className="mb-2">
                {data.ship_mobile}
              </Typography.Text>
              <Typography.Text className="mb-2">
                {data.ship_address_no}
              </Typography.Text>
              <Typography.Text className="mb-2">
                {data.ship_sub_district}
              </Typography.Text>
              <Typography.Text className="mb-2">
                {data.ship_district}
              </Typography.Text>
              <Typography.Text className="mb-2">
                {data.ship_province}
              </Typography.Text>
              <Typography.Text>{data.ship_post_code}</Typography.Text>
            </Col>
          </>
        ) : (
          <Empty />
        )}
      </Row>
    </>
  );
});

const QrTracking = React.memo(({ data }) => (
  <>
    <Row className="bg-gray-60 mt-3">
      <Col span={24} className="px-4 d-flex align-items-center">
        <Typography.Text strong className="my-2 text-white">
          รายการติดตามสินค้า ผ่าน Qr Code
        </Typography.Text>
      </Col>
    </Row>
    <Row>
      <Col
        span={24}
        className="d-flex justify-content-center bg-gray-20 px-4 pb-3"
      >
        {data.length > 0 ? (
          <table className="w-100">
            <thead>
              <tr>
                {qrColumns.map((n, i) => (
                  <td key={i} className="text-center text-bolder">
                    {n.title}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <React.Fragment key={i}>
                  <tr>
                    <td>{i + 1}</td>
                    <td className="text-center">{item.bar_code}</td>
                    <td className="text-center">{item.product_code}</td>
                    <td className="text-center">{item.qr_running}</td>
                    <td className="text-center">{item.partner_code_to}</td>
                    <td>{item.partner_name_to}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        ) : (
          <Empty className="pt-3" />
        )}
      </Col>
    </Row>
  </>
));

const AuthorityDocument = React.memo(
  ({ document = [], partnerRemark, documentNo, setReload }) => {
    const uploadRef = useRef(null);

    const handleSubmit = useCallback(
      (file) => {
        let source = axios.CancelToken.source();
        Modal.confirm({
          title: "ยืนยันการทำรายการ",
          content: "คุณต้องการบันทึกรายการหรือไม่ ?",
          okText: "ตกลง",
          cancelText: "ยกเลิก",
          zIndex: 1080,
          onOk() {
            return new Promise((resolve, reject) => {
              axios({
                method: "post",
                url: `${URL_API}/global/authority-doc`,
                data: {
                  id: file.id,
                  ref_code: documentNo,
                  ref_type: "Bill",
                  document_type: "16",
                },
                headers: { Authorization: "Bearer " + getToken() },
                cancelToken: source.token,
              })
                .then(() => {
                  resolve();
                  setReload((prevReload) => !prevReload);
                  uploadRef.current.clear();
                  notification.success({ message: "บันทึกเอกสารมอบอำนาจแล้ว" });
                })
                .catch((err) => reject(err));
            }).catch((e) => resCatchModal(e));
          },
          onCancel() {
            source.cancel("cancel");
          },
        });
      },
      [uploadRef.current, documentNo]
    );
    return (
      <>
        <Row className="bg-gray-60 mt-3">
          <Col
            span={24}
            className="px-4 d-flex align-items-center justify-content-between"
          >
            <Typography.Text strong className="my-2 text-white">
              เอกสารมอบอำนาจ
            </Typography.Text>
            <UploadFileButtonModal
              ref={uploadRef}
              title="แนบรูป"
              camera
              handleSubmit={handleSubmit}
            />
          </Col>
        </Row>
        {document.length === 0 && !partnerRemark ? (
          <Row className="bg-gray-20 px-3 py-3">
            <Col span={24}>
              <Empty />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="bg-gray-20 px-4 pt-3">
              <Col span={4}>
                <Typography.Text strong>รายละเอียด</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{partnerRemark}</Typography.Text>
              </Col>
            </Row>
            <Row className="bg-gray-20 px-4 pt-2 pb-3">
              <Col span={4}>
                <Typography.Text strong>เอกสาร</Typography.Text>
              </Col>
              <Col span={20} className={styles["new-upload"]}>
                {document.map((file, i) => (
                  <BlockImgBillDoc file={file} key={i} />
                ))}
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
);

const BlockImgBillDoc = React.memo(({ file }) => {
  const [showImage, setShowImage] = useState(false);
  const handleCancel = useCallback(() => {
    setShowImage(false);
  }, []);
  const [errImg, setErrImg] = useState(null);

  return (
    <Col span={24} className={`${styles.block} ${styles.img} ${styles.commit}`}>
      <img
        className="object-fit-cover w-100 h-100"
        src={errImg ? errImg : file.azure_url}
        onError={() => setErrImg(NoImg)}
        alt="authority-doc"
      />
      {!errImg && (
        <div className={`${styles.loading} ${styles.delete}`}>
          <FaEye className={styles.icon} onClick={() => setShowImage(true)} />
        </div>
      )}
      <ImagePreview
        src={file.azure_url}
        visible={showImage}
        onCancel={handleCancel}
      />
    </Col>
  );
});

const ShipStatus = React.memo(({ data, warehouse }) => (
  <>
    <Row className="bg-gray-60 mt-3">
      <Col span={24} className="px-4 d-flex align-items-center">
        <Typography.Text strong className="my-2 text-white">
          สถานะการจัดส่ง
        </Typography.Text>
      </Col>
    </Row>
    <Row className="bg-gray-20 pt-3 pb-3">
      {data.status ? (
        <>
          <Col span={4} className="px-4 d-flex flex-column">
            <Typography.Text strong>ผู้จัดส่งสินค้า</Typography.Text>
            <Typography.Text strong>เลขติดตามพัสดุ</Typography.Text>
            <Typography.Text strong>สถานะ</Typography.Text>
          </Col>
          <Col span={20} className="px-4 d-flex flex-column">
            <Typography.Text>{data.transport_name || "-"}</Typography.Text>
            <Typography.Text>{data.consignment || "-"}</Typography.Text>
            <Typography.Text>{data.status}</Typography.Text>
          </Col>
          {warehouse.map((item, i) => (
            <React.Fragment key={i}>
              <Col span={4} className="px-4 d-flex flex-column">
                <Typography.Text strong>วันที่ส่งกลับคลัง</Typography.Text>
              </Col>
              <Col span={20} className="px-4 d-flex flex-column">
                <Typography.Text>{moment(item.warehouse_to_date).format("DD/MM/YYYY HH:mm")}</Typography.Text>
              </Col>
            </React.Fragment>
          ))}
        </>
      ) : (
        <Empty />
      )}
    </Row>
  </>
));

export default React.memo(BillPreview);
