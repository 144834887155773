/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Empty,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import styles from "../../../../../scss/module/pos.module.scss";
import { URL_API, _ } from "../../../../../utils";
import { resCatch } from "../../../../util/helper";
import BomItem from "./BomItem";

const { Text } = Typography;

const renderWarning = (description) =>
  notification.warning({
    message: "ไม่สามารถดำเนินการได้",
    description,
  });

const QrCodeModal = ({ visible, onCancel, onDelete, product }) => {
  const searchRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    !!product && handleChangeItem();
  }, [product]);

  const handleChangeItem = () => {
    const { qr, qty, product_name, bom } = product;
    let data_filter = [];
    for (let i = 0; i < qty; i++) {
      let bom_filter = [];
      for (let j = 0; j < bom.length; j++) {
        const { qr, qty, product_name } = bom[j];
        for (let k = 0; k < qty; k++) {
          bom_filter = [
            ...bom_filter,
            {
              product_name,
              qr_running: qr[k] || null,
            },
          ];
        }
      }
      data_filter = [
        ...data_filter,
        {
          bom: bom_filter,
          product_name,
          qr_running: qr[i] || null,
        },
      ];
    }
    setDataSource(data_filter);
  };

  const handleAddQr = async (code, qr_running) => {
    let res = null;
    try {
      // res = await axios({
      //   url: `${URL_API}/pos/master/item`,
      //   params: {
      //     code,
      //     partner_type: props.partner_type,
      //     product_type: _.includes(
      //       ["sale", "subscription", "pre-sale"],
      //       match.params.type
      //     )
      //       ? "normal"
      //       : "redeem",
      //     req_receipt_type: props.req_receipt_type,
      //     receipt_branch_code: props.receipt_branch_code,
      //     is_pre_order: _.includes(
      //       ["pre-sale", "pre-redeem"],
      //       match.params.type
      //     )
      //       ? 1
      //       : 0,
      //   },
      // });
    } catch (e) {
      return resCatch(e);
    }

    const { data } = res.data;
    if (!data) {
      return renderWarning("ไม่พบข้อมูลสินค้า");
    }

    // const barcode = code.split("LgcSKU=")[1]?.split("&")[0];
    if (data.product_code !== product.product_code) {
      return renderWarning("สินค้าที่เลือกไม่ถูกต้อง");
    }
    if (data.bom.length > 0) {
      // store qr of bom
      let res = null;
      try {
        res = await axios({
          url: `${URL_API}/pos/master/store-qr`,
          params: {
            qr_running,
          },
        });
      } catch (e) {
        return resCatch(e);
      }
      const { data: store_data } = res.data;
      if (!store_data) {
        return renderWarning("ไม่พบข้อมูล store qr");
      }
      if (store_data.bom) {
        // case bom qr code
        if (data.product_code !== store_data.ref_item_code) {
          return renderWarning("ข้อมูล store qr ไม่ถูกต้อง");
        }
        // for (let i = 0; i < store_data.bom.length; i++) {
        //   props.addOrderItemBomQr({
        //     ...store_data.bom[i],
        //     item_key: productSelected.key,
        //   });
        // }
        // if (promotionId) {
        //   props.setOrderPromotionItemValue(
        //     promotionId,
        //     sourceOption,
        //     data.product_code,
        //     {
        //       qr: [qr],
        //       barcode,
        //     }
        //   );
        // } else {
        //   props.setOrderItemValue(productSelected.key, {
        //     qr: [...productSelected.qr, qr],
        //     barcode,
        //     qr_warning: false,
        //   });
        // }
      } else {
        // case item of bom qr code
        if (
          data.product_code !== store_data.ref_item_code ||
          _.every(data.bom, (n) => n.product_code !== store_data.item_code)
        ) {
          return renderWarning("ข้อมูล store qr ไม่ถูกต้อง");
        }
        // props.addOrderItemBomQr({
        //   ...store_data,
        //   item_key: productSelected.key,
        // });
      }
    } else {
      // if (promotionId) {
      //   props.setOrderPromotionItemValue(
      //     promotionId,
      //     sourceOption,
      //     data.product_code,
      //     {
      //       qr: [qr],
      //       barcode,
      //     }
      //   );
      // } else {
      //   const res_qr = [...productSelected.qr, qr];
      //   props.setOrderItemValue(productSelected.key, {
      //     qr: res_qr,
      //     barcode,
      //     qr_warning: false,
      //     // qr_warning: res_qr.length !== productSelected.product.qty,
      //   });
      // }
    }

    searchRef.current.input.state.value = "";
    searchRef.current.focus();
  };

  const handleSearch = async (code) => {
    if (!code) return;
    const qr_running = code.split("LgcSN=")[1]?.split("&")[0];
    if (!qr_running) return renderWarning("ไม่พบข้อมูล qr running");
    if (product.qty === product.qr.length)
      return renderWarning("จำนวนสินค้าที่เลือกครบแล้ว");
    let is_used =
      _.some(product.qr, (item) => qr_running === item) ||
      _.some(product.bom, (sub_item) =>
        _.some(sub_item.qr, (item) => qr_running === item)
      );
    if (!is_used) {
      try {
        const res = await axios({
          url: `${URL_API}/pos/master/check/qr`,
          params: {
            qr_running,
          },
        });
        is_used = res.data.data.used;
      } catch (e) {
        return resCatch(e);
      }
    }
    if (is_used) return renderWarning("Qr Code นี้ถูกใช้งานแล้ว");
    await handleAddQr(code, qr_running);
  };

  return (
    <Modal
      visible={visible}
      width={1000}
      destroyOnClose={true}
      footer={false}
      onCancel={onCancel}
    >
      <Row type="flex" className="bg-gray-60 align-items-center mt-4 height-50">
        <Col span={8}>
          <Text strong className="text-white ml-2">
            {product?.product_code}
          </Text>
        </Col>
        <Col span={16} className="px-2">
          <Input.Search
            allowClear
            disabled={false}
            ref={searchRef}
            placeholder="Start typing or scan Qr code..."
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="bg-gray-20 pt-3 pb-3 px-4">
          {dataSource.length > 0 ? (
            <>
              <Row gutter={[8, 8]}>
                <Col span={10} offset={1} className="text-center">
                  <Text strong>ชื่อ</Text>
                </Col>
                <Col span={5} className="text-center">
                  <Text strong>Barcode</Text>
                </Col>
                <Col span={5} className="text-center">
                  <Text strong>Qr Running</Text>
                </Col>
                <Col span={3} className="text-center">
                  <Text strong>{`จำนวนทั้งหมด ${dataSource.length}`}</Text>
                </Col>
              </Row>
              <div className={styles["block-scroll"]}>
                {_.map(dataSource, (n, i) => (
                  <React.Fragment key={i}>
                    <Row gutter={[8, 8]}>
                      <Col span={1}>
                        <Text>{i + 1}</Text>
                      </Col>
                      <Col span={10} className="d-flex flex-column">
                        <Text>{n.product_name}</Text>
                      </Col>
                      <Col span={5} className="text-center">
                        <Text>{n.qr_running ? n.barcode : null}</Text>
                      </Col>
                      <Col span={5} className="text-center">
                        <Text>{n.qr_running}</Text>
                      </Col>
                      <Col span={3} className="text-center">
                        {!n.qr_running && (
                          <Popconfirm
                            placement="right"
                            title="ต้องการลบ Qr Running นี้หรือไม่"
                            onConfirm={() => onDelete(n.qr_running)}
                            okText="ลบ"
                            cancelText="ไม่ลบ"
                          >
                            <RiDeleteBin5Line
                              size={20}
                              className="cursor-pointer"
                            />
                          </Popconfirm>
                        )}
                      </Col>
                    </Row>
                    {n.bom.length > 0 && <BomItem list={n.bom} />}
                  </React.Fragment>
                ))}
              </div>
            </>
          ) : (
            <Empty className="pt-3" />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default QrCodeModal;
