import { Button, Col, Row, Switch, Table } from "antd";
import moment from "moment";
import React from "react";
import { FaPencilAlt, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApi } from "../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector
} from "../../../../redux/slices/marketing/product";
import manageWebsiteService from "../../../../services/manage-website.service";
import { axios, getToken, URL_API } from "../../../../utils";
import useAsync from "../../../../utils/useAsync";
import { AlertConfirm, AlertError, AlertSuccess } from "../../../util/Alert";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";

export default function FAQList({ history, match }) {
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;

  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/faq",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  const { execute: deleteFAQById } = useAsync(
    manageWebsiteService.deleteFAQById,
    {
      onSuccess: async () => {
        await AlertSuccess("ลบข้อมูลสำเร็จ");
        history.go(0);
      },
      onFailed: () => {
        AlertError();
      },
    }
  );

  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/faq/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "active" : "inactive",
      },
    })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Panel>
        <PanelHeader>FAQ</PanelHeader>
        <PanelBody>
          <Row type="flex" justify="end" gutter={16} className="mb-4">
            <Col>
              <Button
                type="primary"
                onClick={() => history.push(`${match.path}/add`)}
              >
                <FaPlus className="mr-2" />
                เพิ่มข้อมูล
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={list?.loading}
            dataSource={list?.fetch?.data || []}
            rowKey="id"
            pagination={{
              size: "default",
              showLessItems: true,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              total: list?.fetch?.total || 0,
              current: search.page,
              pageSize: search.limit,
              onChange: (page) => dispatch(SET_SEARCH({ page: page })),
            }}
          >
            <Table.Column
              title="No."
              key="no"
              align="center"
              dataIndex="row_id"
            />

            <Table.Column
              title="Create Date"
              key="create_date"
              dataIndex="create_date"
              align="center"
              render={(text) =>
                text ? moment(text).format("DD/MM/YYYY HH:mm") : ""
              }
            />

            <Table.Column
              title="Update Date"
              key="update_date"
              dataIndex="update_date"
              align="center"
              render={(text) =>
                text ? moment(text).format("DD/MM/YYYY HH:mm") : ""
              }
            />

            <Table.Column
              title="Public Status"
              key="public_status"
              dataIndex="status"
              align="center"
              render={(text, { id }) => (
                <Switch
                  defaultChecked={text === "active"}
                  onClick={(checked) => handleChangeStatus(checked, id)}
                />
              )}
            />

            <Table.Column
              title="Manage"
              key="manage"
              align="center"
              render={(text, { id }) => (
                <Row>
                  <Link to={`${match.path}/${id}`}>
                    <Button type="primary">
                      <FaPencilAlt />
                    </Button>
                  </Link>
                  <Button
                    className="btn-danger ml-1"
                    onClick={async () => {
                      const confirm = await AlertConfirm("ลบข้อมูล");
                      if (confirm) {
                        deleteFAQById(id);
                      }
                    }}
                  >
                    <FaRegTrashAlt />
                  </Button>
                </Row>
              )}
            />
          </Table>
        </PanelBody>
      </Panel>
    </>
  );
}
