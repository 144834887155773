import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Error404 from '../Error404';
import TicketList from './ticket/TicketList';
import EventList from './event-list/EventList';
import CreateEvent from './create-event/CreateEvent';
import EditEvent from './edit-event/EditEvent';
import EditRegister from './edit-register/EditRegister';
import EventCalendar from './event-calendar/EventCalendar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import EventCalendarAnnounce from './event-calendar-announce/EventCalendarAnnounce';

const queryClient = new QueryClient();

export default ({ match }) => {
  const location = useLocation();
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route path={`${match.path}/ticket`} exact children={<TicketList />} />
        {/* <Route path={`${match.path}/event-list`} exact children={<><EventList /> <FileList /></>} /> */}
        <Route
          path={`${match.path}/event-list`}
          exact
          children={<EventList />}
        />
        <Route
          path={`${match.path}/create-event`}
          exact
          children={<CreateEvent />}
        />
        <Route
          path={`${match.path}/edit-event/:id`}
          exact
          children={<EditEvent />}
        />
        <Route path={`${match.path}/edit-register`} exact>
          <EditRegister location={location} />
        </Route>
        <Route
          path={`${match.path}/event-calendar/announce`}
          exact
          children={<EventCalendarAnnounce />}
        />
        <Route
          path={`${match.path}/event-calendar/event`}
          exact
          children={<EventCalendar />}
        />
        <Route component={Error404} />
      </Switch>
    </QueryClientProvider>
  );
};
