import { Button, Col, Row, Switch, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { PromotionContext } from "../../reducers";
import { moment } from "../../../../../../../utils";

const type = "pos";

const ShopSource = ({ onShowBranch }) => {
  const {
    state: {
      orderSource,
      data: { order_source },
    },
    dispatch,
  } = useContext(PromotionContext);

  const active = order_source.includes(type);

  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col span={24} className="d-flex justify-content-between">
          <Typography.Text>Legacy Shop</Typography.Text>
          <div>
            <Tooltip title={`${active ? "กำหนดสาขา" : ""}`}>
              <Button
                size="small"
                shape="circle"
                type="primary"
                className={`${active ? "bg-pv border-pv" : ""} mr-3`}
                disabled={!active}
                onClick={onShowBranch}
              >
                <FaPencilAlt />
              </Button>
            </Tooltip>
            <Switch
              checked={active}
              onClick={(checked) =>
                dispatch({
                  type: "SELECT_ORDER_SOURCE",
                  payload: { type, checked },
                })
              }
            />
          </div>
        </Col>
      </Row>
      {active &&
        orderSource[type].itemKeys.map((key) => (
          <Row gutter={16} key={key} className="mb-2">
            <Col span={8} offset={1}>
              <Typography.Text>
                {orderSource[type].itemByKey[key].name}
              </Typography.Text>
            </Col>
            <Col span={15}>
              {orderSource[type].itemByKey[key].start_date && (
                <Typography.Text className="mr-2">
                  {moment(orderSource[type].itemByKey[key].start_date).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </Typography.Text>
              )}
              {orderSource[type].itemByKey[key].end_date && (
                <>
                  <Typography.Text className="mr-2">-</Typography.Text>
                  <Typography.Text>
                    {moment(orderSource[type].itemByKey[key].end_date).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </Typography.Text>
                </>
              )}
            </Col>
          </Row>
        ))}
    </>
  );
};

export default ShopSource;
