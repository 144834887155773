import { Modal } from "antd";
import reactImageSize from "react-image-size";
import { base64toFile, fileToBase64, resizeFile } from "../../../../util/helper";

const useUploadFile = () => {
  async function onRemoveUpload(e, ...fileDelete) {
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      let isDelete = [...fileDelete, e.uid];
      return isDelete;
    }
  }

  async function onUpload(e, _width, _height) {
    let file = e.file.originFileObj;
    // console.log('file ', file);
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      try {
        const { width, height } = await reactImageSize(img_url);
        let dataFile = [];
        if (
          (width === _width && height === _height) ||
          (true === _width && true === _height)
        ) {
          dataFile = [
            {
              file: { ...e.file },
              uid: file.uid,
              status: "done",
              name: file.name,
              url: img_url,
            },
          ];
          return dataFile;
        } else {
          Modal.error({
            title: "ขนาดรูปภาพไม่ถูกต้อง",
            content: `รูปที่อัพโหลดมาคือ ${width} x ${height} px`,
            okText: "ปิด",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  return { onUpload, onRemoveUpload };
};
export default useUploadFile;
