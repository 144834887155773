import React from "react";
import { Switch, Route } from "react-router-dom";

// Pages
import MemberList from "./MemberList"
import MemberInfo from "../member/MemberInfo"

// Not found
import Error404 from "../Error404";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={MemberList} />
      <Route path={`${match.path}/:id/:member_type`} component={MemberInfo} />

      <Route component={Error404} />
    </Switch>
  );
};
