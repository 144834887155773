/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Row,
    Table,
    Tooltip,
    Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { axios, getToken, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectAddressMaster from "./components/SelectAddressMaster";

const initState = {
  province_id: null,
  district_id: null,
  sub_district_id: null,
  province: "",
  district: "",
  sub_district: "",
  province_en: "",
  district_en: "",
  sub_district_en: "",
  post_code: "",
};
const SearchState = {
  id: 52,
  province_id: 52,
  district_id: null,
  sub_district_id: null,
  province: "กรุงเทพมหานคร",
  district: "",
  sub_district: "",
  post_code: "",
};
const SearchState2 = {
  id: null,
  name: "",
  name_en: "",
  post_code: "",
};
const SearchState3 = {
  id: null,
  name: "",
  name_en: "",
  post_code: "",
};
const RefidState = 0;
const PostCodeState = "";
const limit = 10;
const Index = ({ form: { getFieldDecorator } }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalDistrict, setShowModalDistrict] = useState(false);
  const [showModalSubDistrict, setShowModalSubDistrict] = useState(false);
  const [dataForm, setDataForm] = useState(initState);
  const [search, setSearch] = useState(SearchState);
  const [search2, setSearch2] = useState(SearchState2);
  const [search3, setSearch3] = useState(SearchState3);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([search]);
  const [dataSource2, setDataSource2] = useState([search2]);
  const [dataSource3, setDataSource3] = useState([search3]);
  const [dataP, setDataP] = useState("");
  const [dataD, setDataD] = useState("");
  const [dataS, setDataS] = useState("");
  const [dataPp, setDataPp] = useState("");
  const [dataDd, setDataDd] = useState("");
  const [dataSs, setDataSs] = useState("");
  const [show, setShow] = useState(false);
  const [refid, setRefId] = useState(RefidState);
  const [postCode, setPostCode] = useState(PostCodeState);
  const [reload, setReload] = useState(false);

  const handleClickSave = (data) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/setting/master/address`,
            data: {
              ...data,
            },
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            setDataForm(initState);
            setSearch(SearchState);
            setDataSource2(dataDd);
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                // getData(type);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleClickAdd = (data) => {
    console.log("บันทึก", data);
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            headers: { Authorization: "Bearer " + getToken() },
            url: `${URL_API}/setting/master/address/add`,
            data: {
              name: data.name,
              type: data.type,
              ref_id: data.ref,
              post_code: dataForm.post_code,
            },
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            /*setDataForm(initState)
                        setSearch(SearchState)
                        setDataSource2(dataDd)*/
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                setDataForm(initState);
                setSearch(SearchState);
                setDataSource2(dataDd);
                setShowModal(false);
                setShowModalDistrict(false);
                setShowModalSubDistrict(false);
                setPostCode("");
                setReload(!reload);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleEdit = () => {
    if (
      search.district_id &&
      search.sub_district_id &&
      search.sub_district_id
    ) {
      setDataForm({ ...dataForm, ...search });
    } else {
      message.warning("กรุณากรอกข้อมูลจังหวัด/อำเภอ/ตำบล");
    }
  };
  const handleAdd = (data) => {
    console.log("data", data);
    let pattern = /^[ก-๏0-9@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-\s]+$/i;
    if (data.match(pattern)) {
      if (dataForm.province) {
        console.log("บันทึกจังหวัด");
        const chksameprovince = dataPp.filter((i) => i.name === data);
        if (chksameprovince.length === 1) {
          message.warning("ชื่อซ้ำ");
        } else {
          console.log("บันทึกจังหวัดได้");
          handleClickAdd({ name: data, type: "Province", ref: 0 });
          //ด้านล่างค่อยเอาไปใส่ตอนเพิ่มจริง
          /*setDataForm(initState)
                    setSearch(SearchState)
                    setDataSource2(dataDd)
                    setShowModal(false)*/
        }
        //setDataForm({ ...dataForm, ...search })
        //setShowModal(true)
      }
      if (dataForm.district) {
        console.log("บันทึกอำเภอ", data);
        const chksamedistrict = dataDd.filter((i) => i.name === data);
        if (chksamedistrict.length === 1) {
          message.warning("ชื่อซ้ำ");
        } else {
          console.log("บันทึกอำเภอได้");
          handleClickAdd({
            name: data,
            type: "District",
            ref: search.province_id,
          });
        }
      }
      if (dataForm.sub_district) {
        console.log("บันทึกตำบล", data);
        const chksamesubdistrict = dataSs.filter((i) => i.name === data);
        if (chksamesubdistrict.length === 1) {
          message.warning("ชื่อซ้ำ");
        } else {
          console.log("บันทึกตำบลได้");
          handleClickAdd({
            name: data,
            type: "SubDistrict",
            ref: search.district_id,
          });
          /*setDataForm(initState)
                    setSearch(SearchState)
                    setDataSource2(dataDd)
                    setShowModalSubDistrict(false)*/
        }
      }
    } else {
      message.warning("กรุณากรอกชื่อให้ถูกต้อง");
    }
  };

  console.log("dataSource", dataSource);
  console.log("dataForm", dataForm);
  console.log("dataPp", dataPp);
  console.log("dataDd", dataDd);
  console.log("dataSs", dataSs);
  console.log("dataSource2", dataSource2);

  useEffect(() => {
    console.log("search", search);
    setDataSource([search]);
  }, [search, dataSource2]);

  return (
    <Panel>
      <PanelHeader>ที่อยู่</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col md={6} mr={0}>
            <label className="w-100">เลือกจังหวัด</label>
            <Row gutter={16}>
              <Col md={18}>
                <SelectAddressMaster
                  callfront={(e) => {
                    console.log("callfront", e);
                    setDataPp(e);
                    setDataSource2(e);
                  }}
                  className="w-100 f-s-12"
                  type="province"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.indexOf(input.toLowerCase()) >= 0
                  }
                  value={search.province_id}
                  onChange={(e, option) => {
                    setSearch({
                      ...search,
                      province_id: e,
                      province: option.props.source.name,
                      province_en: option.props.source.name_en,
                      district: undefined,
                      district_id: undefined,
                      sub_district: undefined,
                      sub_district_id: undefined,
                      post_code: undefined,
                    });
                    setDataSource([search]);
                  }}
                  reloadselect={reload}
                />
              </Col>
              <Col md={1}>
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    /*handleAdd()*/ setShowModal(true);
                    setDataForm(initState);
                  }}
                >
                  <FaPlus />
                </Button>
              </Col>
            </Row>
          </Col>

          <Col md={6} ml={0}>
            <label className="w-100">เลือกอำเภอ/เขต</label>
            <Row gutter={16}>
              <Col md={18}>
                <SelectAddressMaster
                  callfront={(e) => {
                    console.log("อำเภอ", e);
                    setDataDd(e);
                    setDataSource2(e);
                  }}
                  className="w-100 f-s-12"
                  type="district"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.indexOf(input.toLowerCase()) >= 0
                  }
                  value={search.district_id}
                  province_id={search.province_id}
                  onChange={(e, option) => {
                    setSearch({
                      ...search,
                      district_id: e,
                      district: option.props.source.name,
                      district_en: option.props.source.name_en,
                      sub_district: undefined,
                      sub_district_id: undefined,
                      post_code: undefined,
                    });
                    setDataSource([search]);
                    setDataSource2([
                      {
                        ...search2,
                        id: e,
                        name: option.props.source.name,
                        name_en: undefined,
                        post_code: undefined,
                      },
                    ]);
                    console.log(option.props.source.name);
                    setDataD(option.props.source.name);
                  }}
                  reloadselect={reload}
                />
              </Col>
              <Col md={1}>
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setShowModalDistrict(true);
                    setDataForm(initState);
                  }}
                >
                  <FaPlus />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <label>เลือกตำบล/แขวง</label>
            <Row gutter={16}>
              <Col md={18}>
                <SelectAddressMaster
                  callfront={(e) => {
                    console.log("ตำบล", e);
                    setDataSs(e);
                    setDataSource3(e);
                  }}
                  className="w-100 f-s-12"
                  type="subdistrict"
                  value={search.sub_district_id}
                  district_id={search.district_id}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e, option) => {
                    setSearch({
                      ...search,
                      sub_district_id: e,
                      sub_district: option.props.source.name,
                      sub_district_en: option.props.source.name_en,
                      post_code: option.props.source.post_code,
                    });
                    setDataSource([search]);
                    setDataSource3([
                      {
                        ...search3,
                        id: e,
                        name: option.props.source.name,
                        name_en: undefined,
                        post_code: option.props.source.post_code,
                      },
                    ]);
                  }}
                  reloadselect={reload}
                />
              </Col>
              <Col md={1}>
                <Tooltip title={dataSs.length !== 0 ? null : "กรุณาเลือกอำเภอ"}>
                  <Button
                    type="primary"
                    ghost
                    disabled={dataSs.length !== 0 ? false : true}
                    onClick={() => {
                      dataSs.length !== 0
                        ? setDataForm(initState) ||
                          setShowModalSubDistrict(true)
                        : message.warning("กรุณาเลือกอำเภอ");
                    }}
                  >
                    <FaPlus />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <label></label>
          <Col className="p-5  d-flex justify-content-end">
            <Button
              type="primary"
              onClick={() => {
                handleEdit();
              }}
            >
              แก้ไขข้อมูล
            </Button>
          </Col>
        </Row>
        <Row gutter={16} className="mb-2" style={{ padding: 8 }}>
          <Col md={6} style={{ padding: 0 }}>
            <Table
              width="100%"
              className="mb-2"
              bordered
              size="small"
              loading={loading}
              dataSource={dataSource ? dataSource : []}
              rowKey="province_id"
              pagination={false}
            >
              <Table.Column width="25%" title="จังหวัด" dataIndex="province" />
              {/* <Table.Column
                        align="center"
                        title={<FaCogs />}
                        width="5%"
                        height="1%"
                        render={({ province }) =>
                        (
                            <>
                                <Button
                                    size="small"
                                    shape="circle-outline"
                                    type="primary"
                                    onClick={() => {setShowModal(true);console.log("province",province)
                                    }}
                                >
                                    <FaCog />
                                </Button>
                                 <Button
                                    size="small"
                                    shape="circle-outline"
                                    type="danger"
                                    className="mr-3"
                                    onClick={() => {
                                        handleClickDelete({ ...dataForm, id: row_id, code, name, status })
                                    }}
                                >
                                    <FaTrash />
                                </Button> 
                            </>
  
                        )}
                                />*/}
            </Table>
          </Col>
          <Col md={6} style={{ padding: 0 }}>
            <Table
              width="100%"
              className="mb-2"
              bordered
              size="small"
              loading={loading}
              dataSource={dataSource2 ? dataSource2 : []}
              rowKey="id"
              pagination={{
                hideOnSinglePage: true,
                pageSize: limit,
              }}
            >
              <Table.Column width="25%" title="อำเภอ" dataIndex="name" />
            </Table>
          </Col>
          <Col md={12} style={{ padding: 0 }}>
            <Table
              width="100%"
              className="mb-2"
              bordered
              size="small"
              loading={loading}
              dataSource={dataSource3 ? dataSource3 : []}
              rowKey="id"
              pagination={{
                hideOnSinglePage: true,
                pageSize: limit,
              }}
            >
              <Table.Column width="25%" title="ตำบล" dataIndex="name" />
              <Table.Column
                width="25%"
                title="ไปรษณีย์"
                dataIndex="post_code"
              />
            </Table>
          </Col>
        </Row>
        {dataForm.province && dataForm.district && dataForm.sub_district && (
          <>
            <Divider />
            <Row gutter={[16, 16]}>
              <Col md={12}>
                <Typography.Text>ภาษาไทย</Typography.Text>
                <Row>
                  <Col>
                    <label>จังหวัด</label>
                    <Input
                      value={dataForm.province}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, province: e.target.value })
                      }
                    />
                  </Col>
                  <Col>
                    <label>อำเภอ</label>
                    <Input
                      value={dataForm.district}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, district: e.target.value })
                      }
                    />
                  </Col>
                  <Col>
                    <label>ตำบล</label>
                    <Input
                      value={dataForm.sub_district}
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          sub_district: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <label>รหัสไปรษณีย์</label>
                    <Input
                      value={dataForm.post_code}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, post_code: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Typography.Text>English</Typography.Text>
                <Row>
                  <Col>
                    <label>Province</label>
                    <Input
                      value={dataForm.province_en}
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          province_en: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <label>District</label>
                    <Input
                      value={dataForm.district_en}
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          district_en: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <label>SubDistrict</label>
                    <Input
                      value={dataForm.sub_district_en}
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          sub_district_en: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <label>PostCode</label>
                    <Input
                      value={dataForm.post_code}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, post_code: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="p-5" gutter={16} type="flex" justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => handleClickSave(dataForm)}
                >
                  บันทึก
                </Button>
              </Col>
              <Col>
                <Button type="danger" onClick={() => setDataForm(initState)}>
                  ยกเลิก
                </Button>
              </Col>
            </Row>
            <Divider />
          </>
        )}

        <Modal
          title={"เพิ่มจังหวัด"}
          visible={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          footer={false}
          //width={800}
        >
          <Row gutter={[16, 16]}>
            <Col md={24}>
              {/* <Typography.Text>ภาษาไทย</Typography.Text> */}
              <Row>
                <Col>
                  <label>จังหวัด</label>
                  <Input
                    placeholder="ชื่อจังหวัดภาษาไทย"
                    value={dataForm.province}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, province: e.target.value })
                    }
                  />
                  {/* <Form.Item className="mb-0">
                                    {getFieldDecorator("Alphabet", {
                                        rules: [
                                            {
                                                required: true,
                                                pattern: new RegExp(/^[a-zA-Zก-๏@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i),
                                                message: "ตัวอักษรเท่านั้น",
                                            },
                                        ],
                                    })(<Input placeholder="โปรดใส่ชื่อจังหวัด" setfieldsvalue={dataForm.province} onChange={(e) =>  setDataForm({ ...dataForm, province: e.target.value }) } />)}
                                    </Form.Item> */}
                </Col>
              </Row>
            </Col>
            {/* <Col md={12}>
                            <Typography.Text>English</Typography.Text>
                            <Row>
                                <Col>
                                    <label>Province</label>
                                    <Input value={dataForm.province_en} onChange={(e) => setDataForm({ ...dataForm, province_en: e.target.value })} />
                                </Col>
                            </Row>
                        </Col> */}
          </Row>

          <Row className="p-5" gutter={16} type="flex" justify="end">
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  dataForm.province
                    ? handleAdd(dataForm.province)
                    : message.warning("กรุณาใส่ชื่อจังหวัด");
                }}
              >
                บันทึก
              </Button>
            </Col>
            <Col>
              <Button
                type="danger"
                onClick={() => {
                  setDataForm(initState);
                  setShowModal(false);
                }}
              >
                ยกเลิก
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={`เพิ่มอำเภอ/เขต ใน${search.province}`}
          visible={showModalDistrict}
          onCancel={() => {
            setShowModalDistrict(false);
          }}
          footer={false}
          //width={800}
        >
          <Row gutter={[16, 16]}>
            <Col md={24}>
              {/* <Typography.Text>ภาษาไทย</Typography.Text> */}
              <Row>
                <Col>
                  <label>อำเภอ/เขต</label>
                  <Input
                    placeholder="ชื่ออำเภอ/เขตภาษาไทย"
                    value={dataForm.district}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, district: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col md={12}>
                            <Typography.Text>English</Typography.Text>
                            <Row>
                                <Col>
                                    <label>Province</label>
                                    <Input value={dataForm.province_en} onChange={(e) => setDataForm({ ...dataForm, province_en: e.target.value })} />
                                </Col>
                            </Row>
                        </Col> */}
          </Row>

          <Row className="p-5" gutter={16} type="flex" justify="end">
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  dataForm.district
                    ? handleAdd(dataForm.district)
                    : message.warning("กรุณาใส่ชื่ออำเภอ/เขต");
                }}
              >
                บันทึก
              </Button>
            </Col>
            <Col>
              <Button
                type="danger"
                onClick={() => {
                  setDataForm(initState);
                  setShowModalDistrict(false);
                }}
              >
                ยกเลิก
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={`เพิ่มตำบล/แขวง ใน${dataD}`}
          visible={showModalSubDistrict}
          onCancel={() => {
            setShowModalSubDistrict(false);
          }}
          footer={false}
          //width={800}
        >
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Row>
                <Col>
                  <label>ตำบล/แขวง</label>
                  <Input
                    placeholder="ชื่อตำบล/แขวงภาษาไทย"
                    value={dataForm.sub_district}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, sub_district: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Row>
                <Col>
                  <label>ไปรษณีย์</label>
                  <Input
                    placeholder="รหัสไปรษณีย์"
                    type="number"
                    value={dataForm.post_code}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, post_code: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col md={12}>
                            <Typography.Text>English</Typography.Text>
                            <Row>
                                <Col>
                                    <label>Province</label>
                                    <Input value={dataForm.province_en} onChange={(e) => setDataForm({ ...dataForm, province_en: e.target.value })} />
                                </Col>
                            </Row>
                        </Col> */}
          </Row>

          <Row className="p-5" gutter={16} type="flex" justify="end">
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  dataForm.sub_district && dataForm.post_code
                    ? setPostCode(dataForm.post_code) ||
                      handleAdd(dataForm.sub_district) ||
                      console.log("dataForm.post_code", dataForm.post_code)
                    : message.warning("กรุณาใส่ชื่อตำบล/แขวง และไปรษณีย์");
                }}
              >
                บันทึก
              </Button>
            </Col>
            <Col>
              <Button
                type="danger"
                onClick={() => {
                  setDataForm(initState);
                  setShowModalSubDistrict(false);
                  setPostCode("");
                }}
              >
                ยกเลิก
              </Button>
            </Col>
          </Row>
        </Modal>
      </PanelBody>
    </Panel>
  );
};
const FormWrapped = Form.create()(Index);
//export default Index
export default FormWrapped;
