import { createSlice } from "@reduxjs/toolkit";
import { _ } from "../../../utils/index";

const initListState = {
  product_code: "",
  product_name: "",
  running: "",
  qr_code: "",
  lot: "",
  production_date: null,
  expiration_date: null,
  lot_detail_id: 0,
};

const initState = {
  search: {
    query: "",
    branch: "",
    start_date: null,
    end_date: null,
    page: 1,
    limit: 10,
  },
  list: {
    itemKeys: [],
    itemByKey: {},
  },
  form: {
    id: 0,
    product_code: "",
    product_name_local: "",
    main_qr: "",
    main_running: "",
    list: {
      itemKeys: [],
      itemByKey: {},
    },
  },
};

const storeQrSetSlice = createSlice({
  name: "set",
  initialState: initState,
  reducers: {
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    CLEAR_FORM: (state) => {
      state.form = { ...initState.form };
    },
    LOAD_FORM: (state, { payload }) => {
      const { list = [], ...data } = payload;

      let loadItemKeys = [];
      let loadItemByKey = {};

      _.forEach(list, (n) => {
        let loadKey = _.uniqueId("bom-");
        loadItemKeys.push(loadKey);
        loadItemByKey[loadKey] = n;
      });

      state.form = {
        ...initState.form,
        ...data,
        list: {
          itemKeys: loadItemKeys,
          itemByKey: loadItemByKey,
        },
      };
    },
    SET_FORM: (state, { payload }) => {
      state.form = { ...state.form, ...payload };
    },
    SELECT_PRODUCT: (state, { payload: { bom, ...form } }) => {
      let selectProductItemKeys = [];
      let selectProductItemByKey = {};

      _.forEach(bom, (n) => {
        for (let i = 0; i < n.qty; i++) {
          let selectProductKey = _.uniqueId("bom-");

          selectProductItemKeys.push(selectProductKey);
          selectProductItemByKey[selectProductKey] = {
            ...initListState,
            product_code: n.product_code,
            product_name: n.product_name_local,
          };
        }
      });

      state.form = {
        ...state.form,
        ...form,
        list: {
          itemKeys: selectProductItemKeys,
          itemByKey: selectProductItemByKey,
        },
      };
    },
    SELECT_QR: (state, { payload }) => {
      const list = _.map(state.form.list.itemKeys, (n) => ({
        ...state.form.list.itemByKey[n],
        key: n,
      }));

      let find_product = _.find(
        list,
        (n) => n.product_code === payload.product_code && n.qr_code === ""
      );

      if (find_product) {
        state.form.list.itemByKey[find_product.key] = {
          ...state.form.list.itemByKey[find_product.key],
          ..._.omit(payload, ["product_code", "id"]),
          lot_detail_id: payload.id,
        };
      }
    },
    CLEAR_QR: (state, { payload }) => {
      let data = { ...state.form.list.itemByKey[payload] };

      state.form.list.itemByKey[payload] = {
        ...initListState,
        ..._.pick(data, ["product_code", "product_name"]),
      };
    },
  },
});

export const actionStoreQrSet = storeQrSetSlice.actions;

export const searchSelector = (state) => state.storeQR.set.search;
export const formSelector = (state) => state.storeQR.set.form;

const StoreQrSetReducer = storeQrSetSlice.reducer;

export default StoreQrSetReducer;
