/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Col,
  Input,
  Radio,
  Row,
  Typography,
  Dropdown,
  Menu,
  List,
  notification,
  Modal,
  DatePicker,
} from "antd";
import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import { FaPen } from "react-icons/fa";
//import Th_flag from '../../../../../assets/img/flag/th.png'
import img from "../../../../../assets/img/pos/user-default.png";
import TextValidation from "../../../../../components/TextValidation";
import { axios, getToken, URL_API } from "../../../../../utils";
import SelectGanger from "../../components/SelectGender";
import SelectNationality from "../../components/SelectNationality";
import SelectPrefixEn from "../../components/SelectPrefixEn";
import SelectPrefixTh from "../../components/SelectPrefixTh";
import { SyncOutlined } from "@ant-design/icons";
import InputMobile from "../../components/InputMobile";
import SelectCallingCode from "../../../../../components/SelectCallingCode";

const InfoMarriedForm = ({
  Member,
  setMember,
  handleChangeMarried,
  errors,
  HaveMarried,
  setActiveTab,
}) => {
  const [query, setQuery] = useState("");
  const [loadList, setLoadList] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showPassportDate, setShowPassportDate] = useState(false);

  const data = Member?.married;

  let toDay = moment();
  let birthDay = moment(data.birth_date_married, "YYYY");
  let Age = toDay.diff(birthDay, "years");
  const [open, setOpen] = useState(false);
  const switchSelect = (e) => {
    if (e === true)
      setMember({
        ...Member,
        married: { ...Member.married, is_company_married: 1 },
      });
    else
      setMember({
        ...Member,
        married: { ...Member.married, is_company_married: 0 },
      });
  };

  const handleClickSearch = async () => {
    if (query !== "" && query !== Member.partner_code) {
      setLoading(true);
      axios({
        url: `${URL_API}/pos/ethics/member`,
        params: {
          query,
          gender: Member.gender,
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.data.data && res.data.data.length > 0) {
            if (res.data.data.length === 1) {
              let data = res.data.data[0];
              setMember({
                ...Member,
                main_partner_code: Member?.partner_code,
                married: {
                  ...Member.married,
                  id_married: data.member_id,
                  partner_code_married: data.code,
                  first_name_th_married: data.first_name_th,
                  first_name_en_married: data.first_name_en,
                  sur_name_th_married: data.sur_name_th,
                  sur_name_en_married: data.sur_name_en,
                  prefix_married: data.prefix,
                  gender_married: data.gender,
                  nationality_married: data.nationality,
                  id_card_married: data.id_card,
                  member_type_married: data.member_type,
                  mobile_married: data.mobile,
                  phone_married: data.phone,
                  email_married: data.email,
                  birth_date_married: data.birth_date,
                  line_id_married: data.line_id,
                  facebook_married: data.facebook,
                  ref_partner_code: Member?.partner_code,
                  register_date_married: data.register_date,
                  expire_date_married: data.expire_date,
                  passport_id_married: data.passport_id,
                  passport_expire_date_married: data.passport_expire_date,
                  phone_code_married: data.partner_code,
                },
              });
              setQuery(data.code);
            } else {
              setLoadList(res.data.data);
              setShowDropdown(true);
            }
          } else {
            switch (res.data.text) {
              case "main":
                notification.warning({
                  message: "เป็นผู้สมัครหลัก",
                  description: res.data.description,
                  placement: "topRight",
                  top: 60,
                });
                break;
              case "married":
                notification.warning({
                  message: "เป็นผู้สมัครร่วม",
                  description: res.data.description,
                  placement: "topRight",
                  top: 60,
                });
                break;
              case "same gender":
                notification.warning({
                  message: "ข้อมูลสมาชิกไม่ถูกต้อง",
                  description: res.data.description,
                  placement: "topRight",
                  top: 60,
                });
                break;
              default:
                notification.warning({
                  message: "ไม่พบข้อมูล",
                  description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
                  placement: "topRight",
                  top: 60,
                });
                break;
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          notification.error({
            message: "ตรวจพบสิ่งผิดพลาด",
            description:
              err.response && err.response.data
                ? err.response.data.message
                : err.message,
            placement: "topRight",
          });
        });
    } else {
      notification.warning({
        message: "รหัสสมาชิกไม่ถูกต้อง",
        description: "กรุณากรอกรหัสสมาชิกใหม่อีกครั้ง",
        placement: "topRight",
        top: 60,
      });
    }
  };
  const handleDelete = async () => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/pos/ethics/delete-married`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              ref_code: Member?.partner_code,
            },
          })
            .then((res) => {
              resolve();
              Modal.success({
                title: "สำเร็จ",
                content: "ทำรายการเรียบร้อย",
              });
              setMember({
                ...Member,
                main_partner_code: null,
                married: {
                  ...Member.married,
                  id_married: null,
                  partner_code_married: null,
                  first_name_th_married: null,
                  first_name_en_married: null,
                  sur_name_th_married: null,
                  sur_name_en_married: null,
                  prefix_married: null,
                  gender_married: null,
                  nationality_married: null,
                  origin_married: null,
                  id_card_married: null,
                  member_type_married: null,
                  mobile_married: null,
                  phone_married: null,
                  email_married: null,
                  birth_date_married: null,
                  line_id_married: null,
                  facebook_married: null,
                  ref_partner_code: null,
                  register_date_married: null,
                  expire_date_married: null,
                  passport_id_married: null,
                  passport_expire_date_married: null,
                  phone_code_married: null,
                },
              });
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        });
      },
    });
  };

  const setSwap = (e) => {
    setMember({ ...Member, main_partner_code: e.target.value });
    if (Member?.main_partner_code !== data?.partner_code_married) {
      setActiveTab("buyerInfo");
    } else {
      setActiveTab("marriedBuyer");
    }
  };

  const [formatD, setFormat] = useState("DD/MM/YYYY");
  useEffect(() => {
    if (open) {
      setFormat("DDMMYYYY");
    } else {
      setFormat("DD/MM/YYYY");
    }
  }, [open]);

  return (
    <>
      {data.partner_code_married == null && data.ref_partner_code == null && (
        <Row type="flex" className="mb-4">
          <Col md={10} className="pr-2">
            <Input.Search
              placeholder="ค้นหารหัสสมาชิก"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onSearch={() => handleClickSearch()}
              size="small"
            />
          </Col>
          <Col
            md={1}
            className="d-flex justify-content-center align-items-center"
          >
            {loading && <SyncOutlined spin />}
          </Col>
        </Row>
      )}
      <Row
        type="flex"
        justify="space-between"
        className="align-items-center mb-4"
      >
        {HaveMarried && (
          <>
            <Col md={8}>
              {data.partner_code_married && (
                <>
                  <div>
                    <Typography.Text className="pr-2">
                      รหัสนักธุรกิจ:
                    </Typography.Text>
                    <Typography.Text strong>
                      {data.partner_code_married}
                    </Typography.Text>
                  </div>
                  <div className="d-flex mb-2">
                    <Typography.Text className="pr-2">
                      ประเภทสมาชิก:
                    </Typography.Text>
                    <Typography.Text strong>
                      {data.member_type_married}
                    </Typography.Text>
                  </div>
                </>
              )}
            </Col>
            <Col
              md={8}
              className="d-flex justify-content-center align-items-center"
            >
              {/* <div>
                {data.partner_code_married && (
                  <Radio.Group
                    value={
                      Member?.main_partner_code === data?.partner_code_married
                        ? data?.partner_code_married
                        : Member?.partner_code
                    }
                    size="small"
                    onChange={(e) => setSwap(e)}
                  >
                    <Radio.Button value={data?.partner_code_married}>
                      หลัก
                    </Radio.Button>
                    <Radio.Button value={Member?.partner_code}>
                      รอง
                    </Radio.Button>
                  </Radio.Group>
                )}
              </div> */}
            </Col>
            <Col md={8} style={{ textAlign: "right" }}>
              {data.partner_code_married && (
                <>
                  <div>
                    <Typography.Text className="pr-2">
                      วันที่สมัคร:
                    </Typography.Text>
                    <Typography.Text strong>
                      {data?.register_date_married
                        ? moment(data?.register_date_married).format(
                            "DD/MM/YYYY"
                          )
                        : "ไม่มีข้อมูล"}
                    </Typography.Text>
                  </div>
                  <div>
                    <Typography.Text className="pr-2">
                      วันที่หมดอายุ:
                    </Typography.Text>
                    <Typography.Text strong>
                      {data?.expire_date_married
                        ? moment(data?.expire_date_married).format("DD/MM/YYYY")
                        : "ไม่มีข้อมูล"}
                    </Typography.Text>
                  </div>
                </>
              )}
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <div>
            <Row className="text-center">
              {/* <Col className="d-flex">
                                {
                                    data.origin_married === 'TH' &&
                                    <img src={Th_flag} width={20} className="mt-2" />
                                }
                            </Col> */}
              <Col>
                <img
                  src={img}
                  className="rounded-circle"
                  alt="Profile"
                  width={110}
                />
              </Col>
              <Col style={{ marginTop: "-15px" }}>
                <Button
                  size="small"
                  type="link"
                  className="rounded-circle bg-pv text-white"
                >
                  <FaPen fontSize="0.8em" />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={18}>
          <Row className="mb-2" gutter={8}>
            <Col md={8}>
              <label>คำนำหน้า</label>
              <TextValidation validate={errors.prefix_married} />
              <SelectPrefixTh
                className="w-100 f-s-12"
                size="small"
                allowClear
                value={data.prefix_married}
                onChange={(e) =>
                  setMember({
                    ...Member,
                    married: { ...Member.married, prefix_married: e },
                  })
                }
              />
            </Col>
            <Col md={8}>
              <label>ชื่อ</label>
              <TextValidation validate={errors.first_name_th_married} />
              <Input
                name="first_name_th_married"
                size="small"
                className="f-s-12"
                value={data.first_name_th_married}
                onChange={handleChangeMarried}
              />
            </Col>
            <Col md={8}>
              <label>นามสกุล</label>
              <TextValidation validate={errors.sur_name_th_married} />
              <Input
                name="sur_name_th_married"
                size="small"
                className="f-s-12"
                value={data.sur_name_th_married}
                onChange={handleChangeMarried}
              />
            </Col>
          </Row>
          <Row className="mb-2" gutter={8}>
            <Col md={8}>
              <label>Prefix Name</label>
              <TextValidation validate={errors.prefix_married} />
              <SelectPrefixEn
                className="w-100 f-s-12"
                size="small"
                allowClear
                value={data.prefix_married}
                onChange={(e) =>
                  setMember({
                    ...Member,
                    married: { ...Member.married, prefix_married: e },
                  })
                }
              />
            </Col>
            <Col md={8}>
              <label>First Name</label>
              <Input
                name="first_name_en_married"
                size="small"
                className="f-s-12"
                value={data.first_name_en_married}
                onChange={handleChangeMarried}
              />
            </Col>
            <Col md={8}>
              <label>Last Name</label>
              <Input
                name="sur_name_en_married"
                size="small"
                className="f-s-12"
                value={data.sur_name_en_married}
                onChange={handleChangeMarried}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-2" gutter={8}>
        <Col md={6}>
          <label>วัน/เดือน/ปีค.ศ. เกิด</label>
          {/* {
            Member?.married.birth_date_married && showDate === false ?
              <Input size="small" value={moment(Member?.married.birth_date_married).format('DD/MM/YYYY')} onFocus={() => setShowDate(true)} />
              :
              <DatePicker
                name="birth_date_married"
                size="small"
                className="f-s-12"
                value={
                  Member?.married.birth_date_married
                    ? moment(Member?.married.birth_date_married)
                    : null
                }
                onChange={(value) => {
                  setShowDate(false)
                  let e = { target: { name: "birth_date_married", value } };
                  handleChangeMarried(e);
                }}
                placeholder="วันเดือนปี(ค.ศ.)"
                format="DDMMYYYY"
                showToday={false}
                onBlur={() => setShowDate(false)}
              />
          } */}
          <DatePicker
            name="birth_date"
            size="small"
            className="f-s-12 w-100"
            value={
              Member?.married.birth_date_married
                ? moment(Member?.married.birth_date_married)
                : null
            }
            onChange={(value) => {
              let e = { target: { name: "birth_date_married", value } };
              handleChangeMarried(e);
            }}
            placeholder="วันเดือนปี(ค.ศ.)"
            format={formatD}
            onOpenChange={(isOpen) =>
              isOpen
                ? setTimeout(() => {
                    const startInput = document.querySelector(
                      ".ant-calendar-input"
                    );
                    console.log(
                      "startInputonopen  ",
                      startInput.value.includes("/")
                    );
                    setOpen(true);
                  }, 100)
                : setOpen(false)
            }
            onFocus={() => setFormat("DDMMYYYY")}
            showToday={false}
          />
        </Col>
        <Col md={6}>
          <label>อายุ</label>
          <Input
            type="number"
            size="small"
            className="f-s-12"
            value={parseInt(Age) || 0}
            disabled
          />
        </Col>
        <Col md={6}>
          <label>สัญชาติ</label>
          <SelectNationality
            className="w-100 f-s-12"
            size="small"
            allowClear
            value={data.nationality_married}
            showSearch
            filterOption={(input, option) =>
              option.props.children.indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              setMember({
                ...Member,
                married: {
                  ...Member.married,
                  nationality_married: e,
                  passport_id_married: "",
                  passport_expire_date_married: "",
                },
              })
            }
          />
        </Col>
        <Col md={6}>
          {["TH", "none", "no_status"].includes(data?.nationality_married) ? (
            <>
              <label>{`${
                data?.nationality_married === "TH"
                  ? "เลขบัตรประชาชน"
                  : "เลขประจำตัว"
              }`}</label>
              <Input
                name="id_card_married"
                size="small"
                className="f-s-12"
                onChange={handleChangeMarried}
                value={data?.id_card_married}
              />
              <TextValidation validate={errors.id_card_married} />
            </>
          ) : (
            <>
              <label>เลขพาสปอร์ต</label>
              <Input
                name="passport_id_married"
                size="small"
                className="f-s-12"
                onChange={handleChangeMarried}
                value={data?.passport_id_married}
              />
              <TextValidation validate={errors.passport_id_married} />
            </>
          )}
          {/* {
            data?.nationality_married === 'TH' ?
              <label>เลขบัตรประชาชน</label>
              :
              data?.nationality_married === 'none' || data?.nationality_married === 'no_status' ?
                <label>เลขประจำตัว</label>
                :
                <label>เลขพาสปอร์ต</label>
          }
          <Input
            name="id_card_married"
            size="small"
            className="f-s-12"
            onChange={handleChangeMarried}
            value={data.id_card_married}
          />
          <TextValidation validate={errors.id_card_married} /> */}
        </Col>
      </Row>
      <Row className="mb-2" gutter={8}>
        {data?.nationality_married === "TH" && (
          <>
            <Col md={6}>
              <label>เลขพาสปอร์ต</label>
              <Input
                name="passport_id_married"
                size="small"
                className="f-s-12"
                onChange={handleChangeMarried}
                value={data?.passport_id_married}
              />
              <TextValidation validate={errors.passport_id_married} />
            </Col>
            <Col md={6}>
              <label>วันหมดอายุ</label>
              {data?.passport_expire_date_married &&
              showPassportDate === false ? (
                <Input
                  size="small"
                  value={moment(data?.passport_expire_date_married).format(
                    "DD/MM/YYYY"
                  )}
                  onFocus={() => setShowPassportDate(true)}
                />
              ) : (
                <DatePicker
                  name="passport_expire_date_married"
                  size="small"
                  className="f-s-12 w-100"
                  value={
                    data?.passport_expire_date_married
                      ? moment(data?.passport_expire_date_married)
                      : null
                  }
                  onChange={(value) => {
                    let e = {
                      target: { name: "passport_expire_date_married", value },
                    };
                    handleChangeMarried(e);
                  }}
                  placeholder="วันเดือนปี(ค.ศ.)"
                  format="DDMMYYYY"
                  showToday={false}
                  onBlur={() => setShowPassportDate(false)}
                />
              )}
            </Col>
          </>
        )}
        <Col md={6}>
          <label>เพศ</label>
          <TextValidation validate={errors.gender_married} />
          <SelectGanger
            className="w-100 f-s-12"
            size="small"
            allowClear
            value={data.gender_married}
            onChange={(e) =>
              setMember({
                ...Member,
                married: { ...Member.married, gender_married: e },
              })
            }
          />
        </Col>
        {/* <Col md={6}>
          <label>โทรศัพท์</label>
          <Input
            name="phone_married"
            size="small"
            className="f-s-12"
            value={data.phone_married}
            onChange={handleChangeMarried}
          />
        </Col>
        <Col md={6}>
          <label>มือถือ</label>
          <TextValidation validate={errors.mobile_married} />
          <Input
            name="mobile_married"
            size="small"
            className="f-s-12"
            value={data.mobile_married}
            onChange={handleChangeMarried}
          />
        </Col> */}
        <Col md={6}>
          <label>อีเมล</label>
          <Input
            type="email"
            name="email_married"
            size="small"
            className="f-s-12"
            value={data.email_married}
            onChange={handleChangeMarried}
          />
        </Col>
        <Col md={6}>
          <label>LineID</label>
          <Input
            name="line_id_married"
            size="small"
            className="f-s-12"
            value={data.line_id_married}
            onChange={handleChangeMarried}
          />
        </Col>
        <Col md={6}>
          <label>Facebook</label>
          <Input
            name="facebook_married"
            size="small"
            className="f-s-12"
            value={data.facebook_married}
            onChange={handleChangeMarried}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col md={24}>
          <label>มือถือ</label>
          <TextValidation validate={errors.mobile_marrieds} />
          <InputMobile
            className="mb-2"
            value={data.mobile_marrieds}
            onChange={(e) => {
              setMember((prevMember) => {
                return {
                  ...prevMember,
                  married: { ...prevMember.married, mobile_marrieds: e },
                };
              });
            }}
          />
        </Col>
      </Row> */}
      <Row className="mb-2" gutter={8}>
        <Col md={12}>
          <label>มือถือ</label>
          <Row className="mb-2" gutter={8}>
            <Col md={12}>
              <SelectCallingCode
                name="calling-code"
                className="w-100 f-s-12"
                size="small"
                value={data?.phone_code_married}
                onChange={(e) =>
                  setMember({
                    ...Member,
                    married: { ...Member.married, phone_code_married: e },
                  })
                }
                placeholder="เลือกรหัสประเทศ"
              />
            </Col>
            <Col md={12}>
              <TextValidation validate={errors.mobile} />
              <Input
                name="mobile_married"
                size="small"
                className="f-s-12"
                value={data?.mobile_married}
                onChange={handleChangeMarried}
                placeholder="เบอร์โทร"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <div>
                <Row type="flex" justify="space-between" gutter={8}>
                    <Col md={24}>
                        <div className="mr-2 mt-3 mb-2">
                            <Typography.Text strong className="f-s-14 pr-2">นิติบุคคล</Typography.Text>
                            <Switch checked={data.is_company_married === 1? true : false} onChange={e => switchSelect(e)} />
                        </div>
                    </Col>
                    {
                        data.is_company_married === 1 &&
                        <>
                            <Col md={12}>
                                <label>ชื่อนิติบุคคล (ภาษาไทย)</label>
                                <Input
                                    name=""
                                    size="small"
                                    className="f-s-12"
                                    onChange={handleChangeMarried}
                                />
                            </Col>
                            <Col md={12}>
                                <label>ชื่อนิติบุคคล (ภาษาอังกฤษ)</label>
                                <Input
                                    name=""
                                    size="small"
                                    className="f-s-12"
                                    onChange={handleChangeMarried}
                                />
                            </Col>
                            <Col>
                                <Radio.Group style={{ padding: "8px 8px" }}>
                                    <Radio value={1} className="f-s-12 text-dark">จดทะเบียนนิติบุคคล แบบ<span style={{ textDecoration: "underline" }}>มี</span>ภาษีมูลค่าเพิ่ม</Radio>
                                    <Radio value={2} className="f-s-12 text-dark">จดทะเบียนนิติบุคคล แบบ<span style={{ textDecoration: "underline" }}>ไม่มี</span>มีภาษีมูลค่าเพิ่ม</Radio>
                                </Radio.Group>
                            </Col>
                            <Col md={12}>
                                <label>เลขประจำตัวผู้เสียภาษี</label>
                                <Input
                                    name=""
                                    size="small"
                                    className="f-s-12"
                                    onChange={handleChangeMarried}
                                />
                            </Col>
                        </>
                    }
                </Row>
            </div> */}
      {(data.partner_code_married == null ||
        Member.main_partner_code !== data.partner_code_married) && (
        <div className="d-flex justify-content-end">
          <Button size="small" type="danger" onClick={() => handleDelete()}>
            ลบข้อมูล
          </Button>
        </div>
      )}
    </>
  );
};
export default InfoMarriedForm;
