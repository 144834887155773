/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Table } from "antd";
import numeral from "numeral";
import React, { useContext, useEffect, useState } from "react";
import { axios, getToken, URL_API } from "../../../../../utils";
import { resCatch } from "../../../../util/helper";
import ReceiveContext from "../reducer";

const { Column } = Table;

const ItemModal = () => {
  const {
    state: {
      item: { document_no, page, limit, data, loading },
    },
    dispatch,
  } = useContext(ReceiveContext);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (document_no) {
      setSource(axios.CancelToken.source());
    } else {
      source && source.cancel("cancel");
      setSource(null);
    }
  }, [document_no]);

  useEffect(() => {
    if (document_no && source) {
      axios({
        url: `${URL_API}/transfer-receive/receive-in/item/${document_no}`,
        headers: { Authorization: "Bearer " + getToken() },
        cancelToken: source.token,
      })
        .then((res) => {
          const { data } = res.data;
          dispatch({
            type: "setGlobalState",
            payload: {
              name: "item",
              value: {
                data,
                page: 1,
                loading: false,
                total: data.length,
              },
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: "setGlobalState",
            payload: { name: "item", value: { loading: false } },
          });
          resCatch(e);
        });
    }
  }, [document_no, source]);

  const handleClose = () =>
    dispatch({
      type: "clearGlobalState",
      payload: "item",
    });

  return (
    <Modal
      visible={!!document_no}
      title={document_no}
      footer={false}
      width={1000}
      zIndex={1080}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleClose}
    >
      <Table
        loading={loading}
        bordered
        dataSource={data}
        size="small"
        rowKey="internal_id"
        scroll={{ x: "max-content" }}
        pagination={{
          current: page,
          pageSize: limit,
          total: data?.total || 0,
          showLessItems: true,
          hideOnSinglePage: true,
          onChange: (page) =>
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "result",
                value: {
                  page,
                },
              },
            }),
        }}
      >
        <Column
          width={100}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">Internal Id</div>
          }
          key="internal_id"
          dataIndex="internal_id"
        />
        <Column
          width={150}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">Item Code</div>
          }
          key="sku"
          dataIndex="sku"
        />
        <Column
          width={200}
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">คลัง</div>}
          key="warehouse_name"
          dataIndex="warehouse_name"
        />
        <Column
          width={150}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">รหัสสินค้า</div>
          }
          key="product_code"
          dataIndex="product_code"
        />
        <Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ชื่อสินค้า</div>
          }
          key="product_name"
          dataIndex="product_name"
          render={(text) => (
            <div className="min-width-100 max-width-600">{text}</div>
          )}
        />
        <Column
          width={100}
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">จำนวน</div>}
          key="quantity"
          dataIndex="quantity"
          render={(text) => numeral(text).format("0,0")}
          align="right"
        />
      </Table>
    </Modal>
  );
};

export default ItemModal;
