/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";
import moment from "moment/moment";
import { Select } from "antd";

const SelectPeriod = ({ range = 12, withAll = false, ...props }) => {
  const [dataSource, setDataSource] = useState([]);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/period`,
      token: true,
      params: { range },
    },
    []
  );
  useEffect(() => {
    const _data = data?.data.map((data_period) => ({
      text: `${moment(data_period.period_month).format("MMM YYYY")} (${
        data_period.period_type
      })`,
      value: [data_period.period_month, data_period.period_type],
    }));

    setDataSource(_data || []);
  }, [data]);

  return (
    <Select {...props}>
      <Select.Option value={` , `}>{"เลือกรอบการจ่าย"}</Select.Option>
      {dataSource.map((d) => (
        <Select.Option key={d.value}>{d.text}</Select.Option>
      ))}
    </Select>
  );
};

export default SelectPeriod;
