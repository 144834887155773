import React, { useContext } from 'react';
import { PageSettings } from '../../../config/page-settings';
import { URL_API } from '../../../utils';

export default () => {
    const contextType = useContext(PageSettings);
    if (contextType.pageHeader) {
        contextType.handleSetPageHeader(false);
        contextType.handleSetPageSidebar(false);
        contextType.handleSetBodyWhiteBg(true);
    }

    
    console.log(URL_API);

    return (
        <>
            test
        </>
    )
}
