import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import LegacyPopUpForm from '../form/LegacyPopUpForm';
import moment from 'moment';
import fitLegacyServices from '../../../../../services/fit-legacy.services';

const LegacyPopUpModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLob } = fitLegacyServices.useMutationCreateLegacyPopUp(
    () => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-pop-up'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    }
  );

  const { mutate: editLob } = fitLegacyServices.useMutationUpdateLegacyPopUp(
    () => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-pop-up'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    }
  );

  const _HandleSubmit = handleSubmit((data) => {
    const legayPopUpData = {
      imageUrl: data.imageUrl,
      title: data.title,
      type: data.type,
      callBackUrlType: data.callBackUrlType,
      callBackUrl: data.callBackUrl,
      startDate: moment(data.startDate).toDate(),
      endDate: moment(data.endDate).toDate(),
    };

    if (watch('formType') === 'create') {
      createLob(legayPopUpData);
    } else if (watch('formType') === 'edit') {
      editLob({ id: data.id, data: legayPopUpData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่ม Legacy Pop Up'
          : 'แก้ Legacy Pop Up'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyPopUpForm />
    </Modal>
  );
};

export default LegacyPopUpModal;
