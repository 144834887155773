/* eslint-disable no-unused-vars */
import { createReducer } from "@reduxjs/toolkit";
import React from "react";
import { _, moment } from "../../../../../../utils";
//ค่า state เริ่มต้น
export const initState = {
  loading: false,
  data: {
    id: 0,
    description: null,
    province_id: undefined,
    district_id: undefined,
    sub_district_id: undefined,
  },
  product: {
  backend_sku_code: '',
  point_01: null,
  point_02: null,
  point_03: null,
  product_code: '',
  product_name_en: '',
  product_name_local: '',
  row_id: null,
  row_number: '',
  unit: '',
  }
};

export const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_LOAD_SUCCESS: (state, { payload }) => {
    const { id, province_id, district_id, sub_district_id, description } =
      payload;
    state.loading = false;
  },
  SET_DATA: (state, { payload }) => {
    state.data = {
      ...state.data,
      ...payload,
    };
    state.product ={
      ...state.product,
      ...payload,
    }
  },
  SET_DATAPRO: (state, { payload }) => {
    state.product = {
      ...state.product,
      ...payload,
    };
  },
  SET_CONTACT: (state, { payload: { contact_key, ...data } }) => {
    state.contact.itemByKey[contact_key] = {
      ...state.contact.itemByKey[contact_key],
      ...data,
    };
  },
});

export const PromotionCategoryContext = React.createContext();