import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import LegacyNotificationPartnerListForm from '../form/LegacyNotificationPartnerListForm';
import fitLegacyServices from '../../../../../services/fit-legacy.services';
import { useQueryClient } from '@tanstack/react-query';

const LegacyNotificationPartnerListModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLegacyNotificationPartnerList } =
    fitLegacyServices.useMutationCreateLegacyNotificationPartnerList(() => {
      queryClient.invalidateQueries({
        queryKey: [
          'get-legacy-notification-by-id',
          { legacyNotificationId: watch('legacyNotificationId') },
        ],
        refetchType: 'all',
      });
      handleClose();
    });

  const _HandleSubmit = handleSubmit((data) => {
    const legaynotificationData = {
      title: data.title,
      partnerList: data.partnerList,
      legacyNotificationId: data.legacyNotificationId,
    };

    createLegacyNotificationPartnerList(legaynotificationData);
  });

  return (
    <Modal
      title="ส่ง Legacy Notification"
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
    >
      <LegacyNotificationPartnerListForm />
    </Modal>
  );
};

export default LegacyNotificationPartnerListModal;
