import { Form, Input, Radio } from "antd";
import React, { useContext } from "react";
import SelectAllType from "../../../../../../components/SelectAllType";
import UserContext from "../../../../../../contexts/user.context";
import { _ } from "../../../../../../utils/index";
import SelectDepartments from "./SelectDepartments";
import SelectDivision from "./SelectDivision";
import SelectPosition from "./SelectPosition";

const FormUser = (props) => {
  const { state, dispatch } = useContext(UserContext);

  const { getFieldDecorator } = props.form;

  return (
    <Form
      wrapperCol={{ md: 14 }}
      labelCol={{ md: 10 }}
      labelAlign="right"
      autoComplete="off"
    >
      <Form.Item label="รหัสผนักงาน" className="mb-0">
        {getFieldDecorator("code", {
          rules: [
            {
              required: true,
              message: "กรุณากรอกรหัสผนักงาน",
            },
          ],
          initialValue: state.form.code,
        })(<Input placeholder="รหัสผนักงาน" />)}
      </Form.Item>
      <Form.Item label="ชื่อผู้ใช้งาน" className="mb-0">
        {getFieldDecorator("user_name", {
          rules: [
            {
              required: true,
              message: "กรุณากรอกชื่อผู้ใช้งาน",
            },
          ],
          initialValue: state.form.user_name,
        })(<Input placeholder="ชื่อผู้ใช้งาน" />)}
      </Form.Item>
      <Form.Item label="รหัสผ่าน" className="mb-0">
        {getFieldDecorator("password", {
          rules: [
            {
              required: true,
              message: "กรุณากรอกรหัสผ่าน",
            },
          ],
          initialValue: state.form.password,
        })(<Input.Password placeholder="รหัสผ่าน" />)}
      </Form.Item>
      <Form.Item label="ชื่อ" className="mb-0">
        {getFieldDecorator("first_name", {
          rules: [
            {
              required: true,
              message: "กรุณากรอกชื่อ",
            },
          ],
          initialValue: state.form.first_name,
        })(<Input placeholder="ชื่อ" />)}
      </Form.Item>
      <Form.Item label="นามสกุล" className="mb-0">
        {getFieldDecorator("last_name", {
          rules: [
            {
              required: true,
              message: "กรุณากรอกนามสกุล",
            },
          ],
          initialValue: state.form.last_name,
        })(<Input placeholder="นามสกุล" />)}
      </Form.Item>
      <Form.Item label="อีเมล" className="mb-0">
        {getFieldDecorator("email", {
          initialValue: state.form.email,
        })(<Input placeholder="อีเมล" />)}
      </Form.Item>
      <Form.Item label="ตำแหน่ง" className="mb-0">
        {getFieldDecorator("position", {
          initialValue: state.form.position,
        })(
          <SelectPosition
            showSearch
            placeholder="-- เลือกตำแหน่ง --"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
        )}
      </Form.Item>
      <Form.Item label="สาขา" className="mb-0">
        {/* {getFieldDecorator("department", {
          initialValue: state.form.department,
        })(<SelectDepartment placeholder="-- เลือกแผนก --" />)} */}
        {
          _.find(state.form.branch || [], (n) => n.is_default === 1)
            ?.branch_name
        }
      </Form.Item>
      <Form.Item label="ฝ่าย" className="mb-0">
        {getFieldDecorator("division_id", {
          initialValue: state.form.division_id,
          onChange: (e) => {
            dispatch({ type: "SET_FORM_VALUE", payload: { division_id: e } });
            props.form.setFieldsValue({ department_id: "" });
          },
        })(<SelectDivision placeholder="-- เลือกฝ่าย --" />)}
      </Form.Item>
      <Form.Item label="แผนก" className="mb-0">
        {getFieldDecorator("department_id", {
          initialValue: state.form.department_id,
        })(
          <SelectDepartments
            division_id={state.form.division_id}
            placeholder="-- เลือกแผนก --"
          />
        )}
      </Form.Item>
      <Form.Item label="ระดับ" className="mb-0">
        {getFieldDecorator("rank", {
          initialValue: state.form.rank,
        })(
          <SelectAllType
            process_type="user_rank"
            className="w-100"
            withAll
            allText="-- เลือกระดับ --"
          />
        )}
      </Form.Item>
      <Form.Item label="สถานะ" className="mb-0">
        {getFieldDecorator("status", {
          initialValue: state.form.status ? state.form.status : "active",
        })(
          <Radio.Group>
            <Radio value="active">ใช้งาน</Radio>
            <Radio value="inactive">งดใช้งาน</Radio>
          </Radio.Group>
        )}
      </Form.Item>
    </Form>
  );
};

export default Form.create("user-form")(FormUser);
