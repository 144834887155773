import moment from "moment";
import React from "react";
import SelectOption from "../../pos/components/SelectOption";

export const monthOptions = [
  { value: "01", text: moment("01").format("MMMM", "LLLL"), th: "มกราคม" },
  { value: "02", text: moment("02").format("MMMM", "LLLL"), th: "กุมภาพันธุ์" },
  { value: "03", text: moment("03").format("MMMM", "LLLL"), th: "มีนาคม" },
  { value: "04", text: moment("04").format("MMMM", "LLLL"), th: "เมษายน" },
  { value: "05", text: moment("05").format("MMMM", "LLLL"), th: "พฤษภาคม" },
  { value: "06", text: moment("06").format("MMMM", "LLLL"), th: "มิถุนายน" },
  { value: "07", text: moment("07").format("MMMM", "LLLL"), th: "กรกฎาคม" },
  { value: "08", text: moment("08").format("MMMM", "LLLL"), th: "สิงหาคม" },
  { value: "09", text: moment("09").format("MMMM", "LLLL"), th: "กันยายน" },
  { value: "10", text: moment("10").format("MMMM", "LLLL"), th: "ตุลาคม" },
  { value: "11", text: moment("11").format("MMMM", "LLLL"), th: "พฤศจิกายน" },
  { value: "12", text: moment("12").format("MMMM", "LLLL"), th: "ธันวาคม" },
];

const SelectMonth = ({ ...props }) => {
  let monthOptions = [
    { value: "01", text: moment("01").format("MMMM", "LLLL") },
    { value: "02", text: moment("02").format("MMMM", "LLLL") },
    { value: "03", text: moment("03").format("MMMM", "LLLL") },
    { value: "04", text: moment("04").format("MMMM", "LLLL") },
    { value: "05", text: moment("05").format("MMMM", "LLLL") },
    { value: "06", text: moment("06").format("MMMM", "LLLL") },
    { value: "07", text: moment("07").format("MMMM", "LLLL") },
    { value: "08", text: moment("08").format("MMMM", "LLLL") },
    { value: "09", text: moment("09").format("MMMM", "LLLL") },
    { value: "10", text: moment("10").format("MMMM", "LLLL") },
    { value: "11", text: moment("11").format("MMMM", "LLLL") },
    { value: "12", text: moment("12").format("MMMM", "LLLL") },
  ];

  return (
    <SelectOption
      className="w-100"
      //defaultValue={moment().format("MM")}
      options={monthOptions}
      {...props}
    />
  );
};

export default SelectMonth;
