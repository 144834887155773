import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Input, Select, Table, Typography } from 'antd';
import {
  FaCaretDown,
  FaCaretUp,
  FaDownload,
  FaEye,
  FaPen,
} from 'react-icons/fa';
import usePaginationHook from '../../../../utils/usePagination';
import dayjs from 'dayjs';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import DigitalProductModal from '../components/modal/DigitalProductModal';
import digitalProductServices from '../../../../services/digital-product.services';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { FiArrowLeft } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { useQueryClient } from '@tanstack/react-query';
import { AlertConfirm } from '../../../util/Alert';
import DigitalProductModal2 from '../components/modal/DigitalProductModal2';
import {
  digitalProductDefaultValues,
  digitalProductFormSchema,
} from '../components/dto/digital-product.dto';

dayjs.locale('th');

const pageSize = 10;

const DigitalProductPage = () => {
  const params = useParams();
  const history = useHistory();
  const [digitalProductFormOpen, setDigitalProductFormOpen] = useState(false);
  const {
    data: digitalProductLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const [digitalProductId, setDigitalProductId] = useState(undefined);
  const queryClient = useQueryClient();
  const [loading2, setLoading2] = useState(false);
  const [digitalProductFormOpen2, setDigitalProductFormOpen2] = useState(false);

  const digitalProductForm = useForm({
    defaultValues: digitalProductDefaultValues,
    resolver: digitalProductFormSchema(),
  });

  const _HandleDigitalProductFormOpen2 = (formType) => {
    digitalProductForm.setValue('formType', formType);
    setDigitalProductFormOpen2(true);
  };

  const _HandleDigitalProductFormClose2 = () => {
    digitalProductForm.reset(digitalProductDefaultValues);
    setDigitalProductFormOpen2(false);
  };

  const { control, watch, setValue } = useForm({
    defaultValues: {
      searchText: '',
      status: '',
      vote: 'DESC',
      requestOrder: '',
    },
  });

  const { mutate: deleteDigitalProduct } =
    digitalProductServices.useMutationDeleteDigitalProduct(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-digital-products'],
        refetchType: 'all',
      });
    });

  const { data } = digitalProductServices.useQueryGetDigitalProductProjectById({
    digitalProductProjectId: params.digitalProductProjectId,
  });

  const { mutate: downloadDigitalProductListsExcel } =
    digitalProductServices.useMutationDownloadDigitalProductListsExcel(() => {
      setTimeout(() => {
        setLoading2(false);
      }, 600);
    });

  digitalProductServices.useQueryGetDigitalProducts(
    {
      take: pageSize,
      page,
      memberCode: watch('searchText'),
      digitalProductProjectId: params.digitalProductProjectId,
      status: watch('status'),
      vote: watch('vote'),
      requestOrder: watch('requestOrder'),
    },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const _HandleDigitalProductFormOpen = () => {
    setDigitalProductFormOpen(true);
  };

  const _HandleDigitalProductFormClose = () => {
    setDigitalProductId(undefined);
    setDigitalProductFormOpen(false);
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'waiting_submit':
        return 'รอตรวจสอบผลงาน';
      case 'submited':
        return 'อนุมัติ';
      case 'rejected':
        return 'ปฏิเสธ';
      default:
        return '';
    }
  };

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => (
        <img
          src={record.image}
          style={{
            width: data?.width / 10 || 100,
            height: data?.height / 10 || 100,
          }}
          alt={record.title}
        />
      ),
    },
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'memberCode',
      key: 'memberCode',
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: '',
      dataIndex: 'isPartner',
      key: 'isPartner',
      render: (_, record) => (
        <span>{record.isPartner ? 'รหัสร่วม' : 'รหัสหลัก'}</span>
      ),
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record?.creater?.firstName
            ? record?.creater?.firstName
            : '' + ' ' + record?.creater?.lastName
            ? record?.creater?.lastName
            : ''}
        </span>
      ),
    },
    {
      title: 'ชื่อผลงาน',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.name}</span>,
    },
    {
      title: 'ประสงค์ผลิตสินค้า',
      dataIndex: 'requestOrder',
      key: 'requestOrder',
      render: (_, record) => (
        <span>{record?.requestOrder ? 'ต้องการ' : 'ไม่ต้องการ'}</span>
      ),
    },
    {
      title: 'เบอร์โทรติดต่อกลับ',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (_, record) => <span>{record?.phoneNumber}</span>,
    },
    {
      title: () => {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            โหวต
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => {
                  setValue('vote', 'DESC');
                }}
              >
                <FaCaretUp
                  style={{
                    color: watch('vote') === 'DESC' ? '#97D700' : '#000',
                    marginBottom: -10,
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setValue('vote', 'ASC');
                }}
              >
                <FaCaretDown
                  style={{
                    color: watch('vote') === 'ASC' ? '#97D700' : '#000',
                    marginTop: -10,
                  }}
                />
              </div>
            </div>
          </span>
        );
      },
      dataIndex: 'vote',
      key: 'vote',
      // sorter: (a, b) => parseInt(a.memberCode) - parseInt(b.memberCode),
      render: (_, record) => <span>{record.voteQuantity}</span>,
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <span>{renderStatus(record?.status)}</span>,
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.createdAt).format('DD/MMM/BBBB')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              setDigitalProductId(record.id);
              _HandleDigitalProductFormOpen();
            }}
          >
            <FaEye />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              digitalProductForm.setValue('id', record.id);
              _HandleDigitalProductFormOpen2('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteDigitalProduct({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{data?.title}</h3>
      </div>
      <Panel>
        <PanelHeader>Digital Product</PanelHeader>
        <PanelBody>
          <div
            style={{ display: 'flex', alignItems: 'flex-end' }}
            className="mb-2"
          >
            <Controller
              name="searchText"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ค้นหาด้วยรหัสนักธุรกิจ"
                    className="mr-2"
                    style={{ flex: 1 }}
                  />
                );
              }}
            />
            <div
              style={{ display: 'flex', flexDirection: 'column', flex: 0.5 }}
            >
              <Typography.Text>ประสงค์ผลิตสินค้า</Typography.Text>
              <Controller
                name="requestOrder"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      style={{ flex: 0.5 }}
                      onChange={onChange}
                      placeholder="ประสงค์ผลิตสินค้า"
                      className="mr-2"
                    >
                      <Select.Option value="">ทั้งหมด</Select.Option>
                      <Select.Option value={true}>ต้องการ</Select.Option>
                      <Select.Option value={false}>ไม่ต้องการ</Select.Option>
                    </Select>
                  );
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', flex: 0.5 }}
            >
              <Typography.Text>สถานะ</Typography.Text>
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      style={{}}
                      onChange={onChange}
                      placeholder="สถานะ"
                      className="mr-2"
                    >
                      <Select.Option value="">ทั้งหมด</Select.Option>
                      <Select.Option value="waiting_submit">
                        รอการอนุมัติ
                      </Select.Option>
                      <Select.Option value="submited">อนุมัติ</Select.Option>
                      <Select.Option value="rejected">ปฏิเสธ</Select.Option>
                    </Select>
                  );
                }}
              />
            </div>
            {/* <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={_HandleSubmit}
            >
              <FaSearch className="mr-2" />
              ค้นหา
            </Button> */}
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={() => {
                setLoading2(true);
                downloadDigitalProductListsExcel({
                  take: -1,
                  page: 1,
                  searchText: watch('searchText'),
                  digitalProductProjectId: params.digitalProductProjectId,
                  status: watch('status'),
                  vote: watch('vote'),
                  requestOrder: watch('requestOrder'),
                });
              }}
              loading={loading2}
            >
              <FaDownload className="mr-2" />
              โหลด excel
            </Button>
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={() => {
                _HandleDigitalProductFormOpen2('create');
              }}
            >
              เพิ่มผลงาน
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={digitalProductLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(
                    `/digital-product/${params.digitalProductProjectId}/${record.id}`
                  );
                },
              };
            }}
          />
        </PanelBody>
        <DigitalProductModal
          open={digitalProductFormOpen}
          handleClose={_HandleDigitalProductFormClose}
          digitalProductId={digitalProductId}
          width={data?.width || 500}
          height={data?.height || 500}
        />
        <FormProvider {...digitalProductForm}>
          <DigitalProductModal2
            open={digitalProductFormOpen2}
            handleClose={_HandleDigitalProductFormClose2}
          />
        </FormProvider>
      </Panel>
    </>
  );
};

export default DigitalProductPage;
