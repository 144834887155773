/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import DocumentTable from "./components/DocumentTable";
import ItemModal from "./components/ItemModal";

const { Option } = Select;

const limit = 10;
const Index = ({ match, history }) => {
  const { type: type_params } = match.params;
  const [status, setStatus] = useState("active");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [sourceSelect, setSourceSelect] = useState(null);
  const title = type_params === "source-code" ? "Source Code" : "Event Project";

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/setting/master/${type_params}`,
      params: {
        query,
        status,
        limit,
        offset: limit * (page - 1),
      },
      token: true,
    },
    [query, page, status]
  );

  useEffect(() => {
    if (!_.includes(["source-code", "event-project"], type_params)) {
      history.push({
        pathname: "/setting/master/requisition-source/source-code",
      });
      Modal.error({
        title: "ไม่สามารถดำเนินการได้",
        content: "Requisition source type isn't incorrect",
        zIndex: 1080,
        okText: "ตกลง",
      });
    }
  }, [type_params]);

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Panel>
      <PanelHeader>{title}</PanelHeader>
      <PanelBody>
        <Row gutter={[8, 8]} className="mb-3">
          <Col md={8}>
            <Input.Search
              placeholder="ค้นหารหัส, ชื่อ"
              allowClear
              onSearch={(e) => {
                setPage(1);
                setQuery(e);
                if (e === query) reload();
              }}
            />
          </Col>
          <Col md={4}>
            <Select
              value={status}
              className="width-125"
              onChange={(e) => {
                setPage(1);
                setStatus(e);
              }}
            >
              <Option value="active">ใช้งาน</Option>
              <Option value="inactive">ไม่ใช้งาน</Option>
            </Select>
          </Col>
          <Col md={12} className="text-right">
            <Button type="primary" onClick={() => setVisible(true)}>
              เพิ่ม {title}
            </Button>
          </Col>
        </Row>
        <DocumentTable
          loading={loading}
          dataSource={data?.data.record || []}
          total={data?.data.total || 0}
          page={page}
          limit={limit}
          setPage={setPage}
          setVisible={setVisible}
          setSourceSelect={setSourceSelect}
        />
        <ItemModal
          title={title}
          visible={visible}
          sourceSelect={sourceSelect}
          reload={reload}
          onCancel={() => {
            setVisible(false);
            setSourceSelect(null);
          }}
        />
      </PanelBody>
    </Panel>
  );
};

export default Index;
