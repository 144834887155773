/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Button, Col, Divider, Row, Statistic, Table, Typography, notification  } from "antd";
import numeral from "numeral";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useApi } from "../../../../../hooks/http2";
import { moment, URL_API, _ } from "../../../../../utils/index";
import * as XLSX from "xlsx-js-style";

const BalanceDetailLot = () => {
  const match = useRouteMatch();
  const form = { list: { itemKeys: [], itemByKey: {} } };
  const detail = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/balance/${match.params.type}/${match.params.id}`,
      token: true,
    },
    [match.params]
  );
  const exportToExcel = () => {
    if (detail.fetch.data.list.length != 0) {

      let excelData = [
        ["รายงาน",
         "สินค้าจัดเซ็ตคงเหลือ-รายการย่อย(รายงานแสดงเฉพาะ Lot. และ รหัสสินค้าที่ระบุ)",
         "","","",
        "วันที่ดึงข้อมูล", `${moment(new Date()).format("DD/MM/YYYY HH:mm")}`],
        [],
        [
          `รหัสสินค้า`,` ${detail.fetch.data.product_code}`,
          "","","",
          `Lot.`,` ${detail.fetch.data.lot}`, 
        ],
        [
          `ชื่อสินค้า`,` ${detail.fetch.data.product_name}`,
          "","","",
          `สาขา`,`${detail.fetch.data.branch_name}`
        ],
        [
          `วันที่ผลิต:`,` ${detail.fetch?.data?.production_date
            ? moment(detail.fetch?.data?.production_date).format(
                "DD/MM/YYYY"
              )
            : "-"}`,
            "","","",
          `วันที่หมดอายุ:`,` ${detail.fetch?.data?.expiration_date
              ? moment(detail.fetch?.data?.expiration_date).format(
                  "DD/MM/YYYY"
                )
            : "-"}`,
        ],
        [ "","","","","",
        `จำนวนรวม:`,` ${new Intl.NumberFormat().format(detail.fetch?.data?.list.length)}`        
        ],
        [],
        [{ v: "ลำดับ", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "QR CODE", t: "s", s: { alignment: { horizontal: "center" } } },
        "","","",
        { v: "Running", t: "s", s: { alignment: { horizontal: "center" } } },
        ],
      ];
      let i = 1
      let row = 8
      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 2 } },
        { s: { r: 7, c: 1 }, e: { r: 7, c: 4 } },
      ];

      detail.fetch.data.list.forEach((n) => {
        excelData = [...excelData,
        [{ v: i++, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.qr_code, t: "s", s: { alignment: { horizontal: "left" } } },
        { v: "", t: "s", s: { alignment: { horizontal: "left" } } },
        { v: "", t: "s", s: { alignment: { horizontal: "left" } } },
        { v: "", t: "s", s: { alignment: { horizontal: "left" } } },
        { v: n.running, t: "s", s: { alignment: { horizontal: "center" } } },
        ]];
        merge.push( { s: { r: row, c: 1 }, e: { r: row, c: 4 } })
        row++
      });

      

      var wscols = [{ wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 },];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "สินค้าจัดเซ็ตคงเหลือ-รายการย่อย");
      XLSX.writeFile(wb, `สินค้าจัดเซ็ตคงเหลือ-รายการย่อย.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col md={16}>
          <Row gutter={8} className="mb-3">
            <Col md={3}>
              <Typography.Text>รหัสสินค้า:</Typography.Text>
            </Col>
            <Col md={5}>
              <Typography.Text>
                {detail.fetch?.data?.product_code}
              </Typography.Text>
            </Col>
            <Col md={3}>
              <Typography.Text>ชื่อสินค้า:</Typography.Text>
            </Col>
            <Col md={5}>
              <Typography.Text>
                {detail.fetch?.data?.product_name}
              </Typography.Text>
            </Col>
            <Col md={3}>
              <Typography.Text>สาขา:</Typography.Text>
            </Col>
            <Col md={5}>
              <Typography.Text>
                {detail.fetch?.data?.branch_name}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={8} className="mb-3">
            <Col md={3}>
              <Typography.Text>Lot.:</Typography.Text>
            </Col>
            <Col md={5}>
              <Typography.Text>{detail.fetch?.data?.lot}</Typography.Text>
            </Col>
            <Col md={3}>
              <Typography.Text>วันที่ผลิต:</Typography.Text>
            </Col>
            <Col md={5}>
              <Typography.Text>
                {detail.fetch?.data?.production_date
                  ? moment(detail.fetch?.data?.production_date).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </Typography.Text>
            </Col>
            <Col md={3}>
              <Typography.Text>วันที่หมดอายุ:</Typography.Text>
            </Col>
            <Col md={5}>
              <Typography.Text>
                {detail.fetch?.data?.expiration_date
                  ? moment(detail.fetch?.data?.expiration_date).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col md={3} className="text-center">
          <Statistic
            title="จำนวนรวม"
            formatter={(value) => numeral(value).format()}
            value={(detail.fetch?.data?.list || []).length}
          />
        </Col>
        <Col md={4}>
        <Button
              type="primary"
              icon="file-excel"
              style={{width:"100%"}}
              onClick={() => exportToExcel()}
            >
              Export Excel
          </Button>
        </Col>
        
      </Row>
      <Divider />
      <Table
        size="small"
        bordered
        loading={detail.loading}
        dataSource={detail.fetch?.data?.list.map((item, index) => ({ ...item, key: index + 1 })) || []}
        pagination={false}
      >
        <Table.Column
          title="No."
          align="right"
          key="no"
          render={(text, record, index) => index + 1}
        />
        <Table.Column title="QR Code" dataIndex="qr_code" />
        <Table.Column
          title="Running"
          dataIndex="running"
          align="center"
          render={(text) => (text === "" ? "No Running" : text)}
        />
      </Table>
    </>
  );
};

export default BalanceDetailLot;
