/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import img from "../../../../../../assets/img/pos/no-img.jpg";
import styles from "../../../../../../scss/module/claim.module.scss";
import { URL_API, _ } from "../../../../../../utils";

const ImageItem = ({ mediaId, color }) => {
  const [imgUrl, setImgUrl] = useState(
    `${URL_API}/pos/master/image/compress/${mediaId}?res_file=1`
  );

  return (
    <div className={`${styles["new-upload"]} d-flex justify-content-center`}>
      <div className={`${styles.block} ${styles.img} ${styles.commit}`}>
        {mediaId ? (
          <>
            <div style={{ backgroundColor: `#${color ? color.color : "fff"}` }}>
              <img
                className="object-fit-cover w-100 h-100"
                src={`${URL_API}/public/${
                  mediaId ? `banks-logo/${mediaId}.svg` : "images/no-img.jpg"
                }`}
                alt="bank-icon"
              />
            </div>
          </>
        ) : (
          <>
            <img
              className="object-fit-cover w-100 h-100"
              src={img}
              alt={_.uniqueId("bank-")}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ImageItem;
