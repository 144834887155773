import moment from "moment";
import numeral from "numeral";

export const getStringLiteral = (...target) => {
  return target.filter(Boolean).join(" ");
};

export const getNumeral = (text, format = "0,0") => {
  return text ? numeral(text).format(format) : "-";
};

export const getMoment = (text, format = "DD/MM/YYYY") => {
  return text ? moment(text).format(format) : "-";
};

export const displayTotal = (total, range) => {
  return `${numeral(range[0]).format()} - ${numeral(
    range[1]
  ).format()} of ${numeral(total).format()}`;
};
