import React from "react";
import { Route, Switch } from "react-router-dom";

// routes

import ImportCoin from "./import-coin/routes"

// error page
import Error404 from "../Error404";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/import-coin`} component={ImportCoin} />

      <Route component={Error404} />

    </Switch>
  );
};
