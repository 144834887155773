/* eslint-disable no-unused-vars */
import { Table, Button } from "antd";
import React, { memo, useCallback, useState } from "react";
import { FaCogs, FaCog } from "react-icons/fa";
import ItemModal from "./ItemModal";

const { Column } = Table;

const DocumentTable = ({
  loading,
  dataSource,
  total,
  page,
  limit,
  setPage,
  setVisible,
  setSourceSelect,
}) => (
  <Table
    size="small"
    bordered
    loading={loading}
    dataSource={dataSource}
    rowKey="row_id"
    scroll={{ x: "max-content" }}
    pagination={{
      current: page,
      pageSize: limit,
      total,
      showLessItems: true,
      hideOnSinglePage: true,
      onChange: (e) => setPage(e),
    }}
  >
    <Column
      width={60}
      title="#"
      key="row_id"
      dataIndex="row_id"
      align="center"
    />
    <Column
      width={100}
      title="รหัส"
      key="code"
      dataIndex="code"
      align="center"
    />
    <Column
      className="position-relative"
      title={<div className="ant-table-absolute-title-center">ชื่อ</div>}
      key="name"
      dataIndex="name"
    />
    <Column
      width={100}
      title="Internal ID"
      key="internal_id"
      dataIndex="internal_id"
      align="center"
    />
    <Column
      width={100}
      title="สถานะ"
      key="status"
      dataIndex="status"
      align="center"
      render={(e) => (e === "active" ? "ใช้งาน" : "ไม่ใช้งาน")}
    />
    <Column
      width={60}
      title={<FaCogs />}
      key="action"
      align="center"
      render={(record) => (
        <Button
          size="small"
          shape="circle-outline"
          type="primary"
          className="mr-2"
          onClick={() => {
            setVisible(true);
            setSourceSelect(record);
          }}
        >
          <FaCog />
        </Button>
      )}
    />
  </Table>
);

export default memo(DocumentTable);
