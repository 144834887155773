/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectApiData from "../../../../../../components/SelectApiData";
import { actionClaim } from "../../../../../../redux/slices/claim";

const apiParams = { process_type: "claim_item_amount" };

const { setClaimItemValue } = actionClaim;

const SelectAmount = ({ disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const amount_type = useSelector(
    (state) =>
      state.claim.billProduct.itemByKey[product_key]?.claim_item.itemByKey[
        state.claim.billProduct.itemByKey[product_key].claim_item.activeKey
      ].amount_type
  );

  const handleChange = useCallback(
    (amount_type) => dispatch(setClaimItemValue({ amount_type })),
    []
  );

  return (
    <Row gutter={[8, 8]}>
      <Col span={6} className="text-right">
        <Typography.Text>ปริมาณคงเหลือ</Typography.Text>
      </Col>
      <Col span={18}>
        <SelectApiData
          placeholder="เลือกปริมาณคงเหลือ"
          size="small"
          className="mr-2 w-100"
          apiPath="/pos/master/type"
          params={apiParams}
          disabled={disabled}
          value={amount_type ?? undefined}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
};

export default memo(SelectAmount);
