import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const classReservationFormDefaultValues = {
  id: '',
  formType: 'create',
  name: '',
  memberNo: '',
};

export const classReservationFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      name: yup.string().required('กรุณากรอกชื่อผู้จอง'),
      memberNo: yup.string().required('กรุณากรอกรหัสสมาชิก'),
    })
  );
};
