import React, { Component } from 'react'
import { PageSettings } from '../../../config/page-settings'
import Routes from './routes'


export default class Index extends Component {

	static contextType = PageSettings;

	constructor(props) {
		super(props);
		this.state = {

		}
	}
	componentDidMount() {
		this.context.handleSetPageHeader(false);
		this.context.handleSetPageSidebar(false);
	}

	componentWillUnmount() {
		this.context.handleSetPageSidebar(true);
		this.context.handleSetPageHeader(true);
	}
	render() {
		return (
			<>
				<Routes/>
			</>
		)
	}
}
