import { Col, Row } from "antd";
import React, { memo } from "react";
import ImgError from "../../../../../../components/ImgError";
import styles from "../../../../../../scss/module/promotion.module.scss";
import { _ } from "../../../../../../utils";
import Item from "./Item";

const Source = ({ list, azureUrl, disabled }) => {
  const card_class = disabled ? `${styles.disabled}` : "";
  return (
    <Row
      className={`${styles["promotion-list2"]} ${styles["card-condition"]} ${card_class}`}
    >
      <Col span={6}>
        <div className={`${styles.img}`}>
          <ImgError
            src={azureUrl}
            alt={_.uniqueId("condition-")}
            className="w-100 h-100"
          />
        </div>
      </Col>
      <Col span={18} className={styles.content}>
        {_.map(list, (n) => (
          <Item key={`${n.source_key}-${n.source_option}`} data={n} />
        ))}
      </Col>
    </Row>
  );
};

export default memo(Source);
