import { _ } from "../../utils";

export const initClaimItemValue = {
  used_by_code: null,
  used_by_name: null,
  used_by_mobile: null,
  mfg_date: null,
  exp_date: null,
  lot: null,
  amount_type: null,
  description: null,
  qr_running: null,
  barcode: null,
  // product_type_active: "item",
  // piece_select: null,
  // piece_used: [],
  bom: {
    itemKeys: [],
    itemByKey: {},
  },
  media: {
    itemDel: [],
    itemKeys: [],
    itemByKey: {},
  },
};

export const initClaimError = {
  claim_by_code: null,
  claim_by_name: null,
  claim_by_mobile: null,
  partner_name: null,
  claim_type: null,
  branch_code: null,
  remark: null,
  branch_priority: null,
  authority: null,
  req_receipt_type_error: null,
  receipt_branch_code: null,
  ship_to_id: null,
};

const mapMedia = (prefix, media, refNo) => {
  let itemKeys = [];
  let itemByKey = {};
  _.forEach(
    _.filter(
      _.filter(media, (n) => _.split(n.ref_no, "-")[0] === prefix),
      (n) => n.ref_no === refNo
    ),
    (n) => {
      const key = _.uniqueId(`${prefix}-media-`);
      itemKeys = [...itemKeys, key];
      itemByKey = { ...itemByKey, [key]: _.omit(n, ["ref_no"]) };
    }
  );
  return {
    itemDel: [],
    itemKeys,
    itemByKey,
  };
};

export const getClaimSelectBillProduct = (payload) => {
  const {
    document_status,
    media,
    bill_item,
    claim_in,
    claim_in_item,
    claim_in_sub_item,
    claim_in_sub_item_item,
  } = payload;
  let itemKeys = [];
  let itemByKey = {};
  const is_wait_process = _.includes(
    [null, "draft", "waiting"],
    document_status
  );
  for (let i = 0; i < bill_item.length; i++) {
    const { id, product_code, sub_item_used, bom, qty_balance, ...product } =
      bill_item[i];
    const claim_item = _.filter(
      claim_in_item,
      // (n) => product_code === n.product_code
      (n) => id === n.ref_document_item_id
    );

    // break when is't wait process and no claim item
    if (!is_wait_process && claim_item.length === 0) continue;
    const key = _.uniqueId("product-");

    // const claim = _.find(claim_in, (n) => product_code === n.product_code);
    const claim = _.find(claim_in, (n) => id === n.ref_document_item_id);
    let claimItemKeys = [];
    let claimItemByKey = {};
    _.forEach(claim_item, (item, piece) => {
      const item_key = _.uniqueId("item-");
      let bomItemKeys = [];
      let bomItemByKey = {};
      _.forEach(bom, (n) => {
        const bom_key = _.uniqueId("bom-");
        const claim_sub = _.filter(
          claim_in_sub_item,
          (m) => product_code === m.ref_product_code &&
            item.piece === m.ref_piece &&
            n.product_code === m.product_code &&
            item.id === m.claim_in_item_id
        );

        const claim_sub_item = _.filter(
          claim_in_sub_item_item,
          (m) => product_code === m.ref_product_code &&
            item.piece === m.ref_piece &&
            n.product_code === m.product_code &&
            _.includes(claim_sub.map(n => n.id), m.claim_in_sub_item_id)
        );

        const qty_all_claim = _.reduce(
          _.filter(
            sub_item_used,
            (m) =>
              product_code === m.ref_product_code &&
              item.piece === m.ref_piece &&
              n.product_code === m.product_code
          ),
          (res, m) => res + m.qty,
          0
        );
        let claimSubItemKeys = [];
        let claimSubItemByKey = {};
        _.forEach(claim_sub_item, (sub_item, sub_piece) => {
          const sub_item_key = _.uniqueId("sub_item-");
          claimSubItemKeys = [...claimSubItemKeys, sub_item_key];
          claimSubItemByKey = {
            ...claimSubItemByKey,
            [sub_item_key]: {
              ..._.omit(sub_item, [
                "ref_document_no",
                "ref_product_code",
                "ref_piece",
              ]),
              product_type_active: "item",
              media: mapMedia(
                "sub_item",
                media,
                `sub_item-${sub_item.product_code}-${sub_piece + 1}-${sub_item.ref_piece
                }`
              ),
              bom: [],
              // piece_select: null,
            },
          };
        });
        const claimSubActiveKey = claimSubItemKeys[0];
        bomItemKeys = [...bomItemKeys, bom_key];
        bomItemByKey = {
          ...bomItemByKey,
          [bom_key]: {
            ...n,
            ref_piece: item.piece,
            qty_balance: n.qty - (qty_all_claim - (claim_sub?.qty || 0)),
            claim_item: {
              activeKey: claimSubActiveKey,
              itemKeys: claimSubItemKeys,
              itemByKey: claimSubItemByKey,
            },
          },
        };
      });
      claimItemKeys = [...claimItemKeys, item_key];
      claimItemByKey = {
        ...claimItemByKey,
        [item_key]: {
          ..._.omit(item, ["ref_document_no"]),
          product_type_active: "item",
          media: mapMedia(
            "item",
            media,
            `item-${item.product_code}-${item.piece}`
            // `item-${item.product_code}-${piece + 1}`
          ),
          // piece_select: claim_item[0]?.piece || null,
          bom: {
            itemKeys: bomItemKeys,
            itemByKey: bomItemByKey,
          },
        },
      };
    });
    const claimActiveKey = claimItemKeys[0];
    itemKeys = [...itemKeys, key];
    itemByKey = {
      ...itemByKey,
      [key]: {
        ...product,
        ref_key: id,
        product_code,
        sub_item_used,
        bom,
        qty_balance: qty_balance + (claim?.qty || 0),
        // piece_used: _.map(
        //   _.keys(_.groupBy(sub_item_used, "ref_piece")),
        //   (n) => +n
        // ),
        claim_item: {
          activeKey: claimActiveKey,
          itemKeys: claimItemKeys,
          itemByKey: claimItemByKey,
        },
      },
    };
  }
  return { itemKeys, itemByKey };
};

export const mapBomClaimItemAll = ({ qty_balance, product_code }) => {
  let itemKeys = [];
  let itemByKey = {};
  for (let i = 0; i < qty_balance; i++) {
    const key = _.uniqueId("sub_item-");
    itemKeys = [...itemKeys, key];
    itemByKey = {
      ...itemByKey,
      [key]: {
        ..._.cloneDeep(initClaimItemValue),
        product_code,
        piece: itemKeys.length,
      },
    };
  }
  return {
    activeKey: itemKeys[0] || null,
    itemKeys,
    itemByKey,
  };
};
