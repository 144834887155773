import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios, getToken, URL_API } from "../../utils";

export const getInventoryCountStock = createAsyncThunk(
  "get/inventory-count/stock",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/inventory-count/stock`,
        params,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getInventoryCountByDocumentNo = createAsyncThunk(
  "get/inventory-count/document_no",
  async ({ document_no, is_export = false }, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/inventory-count/${document_no}`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return { data: res.data.data, is_export };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
