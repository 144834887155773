import { Alert, Select } from "antd";
import React from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, _ } from "../../../../../utils";

const SelectStatus = ({ className, withAll, ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master`,
      params: {
        process_type: "pre-order",
      },
    },
    []
  );
  if (error) return <Alert type="error" showIcon message={error} />;
  return (
    <Select className={`w-100 ${className || ""}`} loading={loading} {...props}>
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {_.map(data?.data, (n) => (
        <Select.Option key={n.code} value={n.code}>
          {n.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectStatus;
