import { Alert, Select } from "antd";
import React, { useEffect, memo } from "react";
import { useHttp } from "../hooks/http";
import { URL_API, _ } from "../utils";

const SelectApiData = ({
  callBackData,
  withAll = false,
  apiPath = "",
  token = false,
  params = {},
  ...props
}) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}${apiPath}`,
      params,
      token,
    },
    []
  );

  useEffect(() => {
    data && callBackData && callBackData(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select loading={loading} dropdownStyle={{ zIndex: 1080 }} {...props}>
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {_.map(data?.data, (n) => (
        <Select.Option key={n.code} value={n.code}>
          {n.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default memo(SelectApiData);
