const fixBranch = {
  online: ["200", "201", "202", "203"],
  no_prefix: ["300", "400", "500", "600", "700", "701", "800", "801"],
  disabled: ["500"], // "000", "300", "400"
  call_center_line: ["201", "202"],
  transfer_code: "300",
  disabled_master_promotion: ["400", "500", "203", "200"],
};

const fixAcceptPartner = {
  // --- ถ้ามีใน Array สามารถดำเนินการได้ ---
  claim_by: [
    "active",
    "pending",
    "void",
    "cancel",
    "expired",
    "vacated",
    "resigned",
    "waiting",
  ],
  claim_partner: [
    "active",
    "pending",
    "void",
    "cancel",
    "expired",
    "vacated",
    "resigned",
    "legal",
    "waiting",
  ],
  cliam_used_by: [
    "active",
    "pending",
    "void",
    "cancel",
    "expired",
    "vacated",
    "terminated",
    "resigned",
    "legal",
    "waiting",
  ],
};

const fixDepartment = {
  operation_support: 1,
  operation_center: 18,
  customer_care_unit: 19,
  customer_experience_management: 20,
};

const fixRole = {
  branch_manager: 3,
};

const fixProductTypeSet = {
  none_inventory: "None Inventory Item",
  service: "Service",
  kit_pack: "Kit/Package",
};

const fixDisCount = {
  starter_vip_user: process.env.REACT_APP_MEMBER_DISCOUNT, // "SFNSV000007", // "SOSSV000037",
  renew_vip_user: process.env.REACT_APP_MEMBER_DISCOUNT,// "SFNSV000007" // "SOSSV000037",
  delivery: process.env.REACT_APP_MEMBER_DISCOUNT_DELIVERY,// "SFNSV000005" // "SOSSV000035",
  cash_card: process.env.REACT_APP_MEMBER_CASHCARD,// '0004' // 'TH0007'
};
const fixProductType = {
  starter: "PT03",
  renew: "PT04",
};

export {
  fixBranch,
  fixAcceptPartner,
  fixDepartment,
  fixRole,
  fixProductTypeSet,
  fixDisCount,
  fixProductType,
};
