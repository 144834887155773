/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Typography,
  Select,
} from "antd";
import Table from "../../components/Table";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";

const Model = {
  query: "",
  type: "",
};
const AddressHistory = ({ show, ...props }) => {
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 5;
  const [totalPage, setTotalPage] = useState(0);
  const [criteria, setCriteria] = useState(Model);

  const [loading, data, error, reload] = useHttp(
    {
      method: "GET",
      url: `${URL_API}/pos/vip-member/address-history`,
      params: {
        code: props.code,
        limit,
        offset: (page - 1) * limit,
        ...criteria,
        type: criteria.type ? criteria.type : "",
      },
      token: true,
    },
    [page, criteria]
  );

  if (error) {
    notification.error({
      description: error,
      placement: "bottomRight",
    });
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      dataIndex: "row_id",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "ประเภทที่อยู่",
      key: "address_type",
      dataIndex: "address_type",
      render: (text) => (
        <p className="m-0 text-center">
          {text === 1
            ? "ที่อยู่ตามบัตรประชาชน"
            : text === 2
            ? "ที่อยู่ปัจจุบัน"
            : text === 3
            ? "ที่อยู่จัดส่ง"
            : text === 4
            ? "ที่อยู่สำหรับออกใบกำกับภาษี"
            : "ไม่มีข้อมูล"}
        </p>
      ),
    },
    {
      title: "ที่อยู่",
      key: "address",
      dataIndex: "address",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "จังหวัด",
      key: "province",
      dataIndex: "province",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "อำเภอ",
      key: "district",
      dataIndex: "district",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "ตำบล",
      key: "sub_district",
      dataIndex: "sub_district",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "รหัสไปรษณีย์",
      key: "post_code",
      dataIndex: "post_code",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "วันที่แก้ไข",
      key: "modifydate",
      dataIndex: "modify_date",
      render: (text) => (
        <p className="m-0 text-center">
          {text ? moment(text).format("DD/MM/YYYY hh:mm") : "ไม่มีข้อมูล"}
        </p>
      ),
    },
  ];

  useEffect(() => {
    let newData = [];
    let total = 0;
    if (data?.data && data?.data) {
      newData = data?.data.map((n) => ({ ...n, key: n.id }));
      total = data.total;
    }
    setDataSource(newData);
    setTotalPage(total);
  }, [data]);
  return (
    <>
      <Modal
        width={700}
        style={{ top: 20 }}
        visible={show}
        zIndex={2000}
        {...props}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="ปิด"
      >
        <Typography.Title level={4}>ประวัติที่อยู่</Typography.Title>
        <Row gutter={8}>
          <Col md={7}>
            <Input
              size="small"
              placeholder="ที่อยู่,จังหวัด,อำเภอ,ตำบล"
              className="f-s-12"
              onChange={(e) =>
                setCriteria({ ...criteria, query: e.target.value })
              }
            />
          </Col>
          <Col md={7}>
            <Select
              size="small"
              placeholder="ประเภทที่อยู่"
              className="w-100 f-s-12"
              allowClear
              dropdownStyle={{ zIndex: "2010" }}
              defaultValue={criteria.type}
              onChange={(value) => setCriteria({ ...criteria, type: value })}
            >
              <Select.Option value="">ทั้งหมด</Select.Option>
              <Select.Option value="1">ที่อยู่ตามบัตรประชาชน</Select.Option>
              <Select.Option value="2">ที่อยู่ปัจจุบัน</Select.Option>
              <Select.Option value="3">ที่อยู่จัดส่ง</Select.Option>
              <Select.Option value="4">ที่อยู่ใบกำกับภาษี</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            <Button
              type="primary"
              ghost
              size="small"
              icon="search"
              onClick={reload}
            />
          </Col>
        </Row>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data?.data}
          className="mt-2 f-s-12"
        />
        {totalPage === 0 ? null : (
          <Pagination
            size="small"
            pageSize={limit}
            current={page}
            total={data && data.total ? data.total : 0}
            className="text-center"
            onChange={(e) => setPage(e)}
          />
        )}
      </Modal>
    </>
  );
};
export default AddressHistory;
