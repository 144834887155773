import {QuestionCircleOutlined} from '@ant-design/icons'
import { Modal, Input, Typography } from 'antd'
import React from 'react'

const Remark = ({handleChange, show, ...props }) => {
    const { TextArea } = Input
    return (
        <>
            <Modal
                okText= 'ไม่อนุมัติ'
                cancelText='ยกเลิก'
                width={500}
                visible={show}
                zIndex={2000}
                {...props}
            >
                <QuestionCircleOutlined className="pr-2" style={{color: '#faad14', fontSize: '22px', float: 'left',}}/>
                <Typography.Title style={{fontSize: '16px', fontWeight: '500'}}>ยืนยันไม่อนุมัติเอกสาร</Typography.Title>
                <label>หมายเหตุ</label>
                <TextArea
                    name="remark"
                    size="small"
                    rows={4}
                    onChange={handleChange}
                />
            </Modal>
        </>
    )
}
export default Remark