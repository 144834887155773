/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaDownload, FaSearch } from 'react-icons/fa';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { AlertConfirm } from '../../../util/Alert';
import { Input, Table } from 'antdV4';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';
import registerPaperConstants from '../constants/registerPaperConstants';
import { Controller, useForm } from 'react-hook-form';
import {
  ptRegisterPaperQueryDefaultValues,
  ptRegisterPaperQueryFormSchema,
} from '../components/dto/pt-register-paper';
import DownloadModal from '../components/modal/DownloadModal';

const PTRegisterPaperPage = () => {
  const [page, setPage] = useState(0);
  const [id, setId] = useState('');
  const [isOnSearch, setIsOnSearch] = useState(false);
  const [registerPaperDownloadOpen, setRegisterPaperDownloadOpen] =
    useState(false);
  const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);

  const _HandleRegisterPaperDownloadOpen = () => {
    setRegisterPaperDownloadOpen(true);
  };

  const _HandleRegisterPaperDownloadClose = () => {
    setRegisterPaperDownloadOpen(false);
  };

  const { execute: downloadPTRegisterPaperById } = useAsync(
    fitStudioService.downloadPTRegisterPaperById,
    {}
  );

  const columns = [
    {
      title: 'ชื่อ-นามสกุล สมาชิก',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record.memberFirstName} {record.memberLastName}
        </span>
      ),
    },
    {
      title: 'ชื่อ-นามสกุล PT',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record.ptFirstName} {record.ptLastName}
        </span>
      ),
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              setId(record.id);
              _HandleRegisterPaperDownloadOpen();
            }}
          >
            <FaDownload />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบใบสมัคร');
              if (confirm) deletePTRegisterPaper({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  const { execute: downloadPTRegisterPaperExcelByFilter } = useAsync(
    fitStudioService.downloadPTRegisterPaperExcelByFilter,
    {
      onSuccess: () => {
        setTimeout(() => {
          setExcelDownloadLoading(false);
        }, 3000);
      },
    }
  );

  useEffect(() => {
    getPTRegisterPapers({
      page: page + 1,
      take: registerPaperConstants.tableSize,
    });
  }, [page]);

  const { execute: getPTRegisterPapers, data: registerPaperLists } = useAsync(
    fitStudioService.getPTRegisterPapers,
    {}
  );

  const { data: deletedRes, execute: deletePTRegisterPaper } = useAsync(
    fitStudioService.deletePTRegisterPaper,
    {}
  );

  useEffect(() => {
    getPTRegisterPapers({
      page: page + 1,
      take: registerPaperConstants.tableSize,
    });
  }, [deletedRes]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: ptRegisterPaperQueryDefaultValues,
    resolver: ptRegisterPaperQueryFormSchema(),
  });

  const _HandleSubmit = handleSubmit((data) => {
    setIsOnSearch(true);
    getPTRegisterPapers({
      query: {
        ...(data.memberFirstName && { memberFirstName: data.memberFirstName }),
        ...(data.memberLastName && { memberLastName: data.memberLastName }),
        ...(data.ptFirstName && { ptFirstName: data.ptFirstName }),
        ...(data.ptLastName && { ptLastName: data.ptLastName }),
      },
      take: -1,
      page: 1,
    });
  });

  const _HandleExportExcel = handleSubmit((data) => {
    setExcelDownloadLoading(true);
    if (isOnSearch) {
      downloadPTRegisterPaperExcelByFilter({
        query: {
          ...(data.memberFirstName && {
            memberFirstName: data.memberFirstName,
          }),
          ...(data.memberLastName && { memberLastName: data.memberLastName }),
          ...(data.ptFirstName && { ptFirstName: data.ptFirstName }),
          ...(data.ptLastName && { ptLastName: data.ptLastName }),
        },
        take: -1,
        page: 1,
      });
    } else {
      downloadPTRegisterPaperExcelByFilter({
        page: page + 1,
        take: registerPaperConstants.tableSize,
      });
    }
  });

  const _HandleCancel = () => {
    reset(ptRegisterPaperQueryDefaultValues);
    setIsOnSearch(false);
    getPTRegisterPapers({
      page: page + 1,
      take: registerPaperConstants.tableSize,
    });
  };

  return (
    <Panel>
      <PanelHeader>ใบสมัคร PT</PanelHeader>
      <PanelBody>
        <div
          style={{
            marginBottom: 10,
            border: '1px solid #f2f3f4',
            borderRadius: 5,
            padding: 10,
          }}
        >
          <Typography.Text>สมาชิก</Typography.Text>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <Controller
              name="memberFirstName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ชื่อ"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="memberLastName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="นามสกุล"
                    className="mr-2"
                  />
                );
              }}
            />
          </div>
          <Typography.Text>PT</Typography.Text>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <Controller
              name="ptFirstName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ชื่อ"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="ptLastName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="นามสกุล"
                    className="mr-2"
                  />
                );
              }}
            />
          </div>
          <div
            className="mb-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={_HandleSubmit}
            >
              <FaSearch className="mr-2" />
              ค้นหา
            </Button>
            <Button
              className="bg-pv border-pv text-white"
              onClick={_HandleCancel}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
        <Row className="mb-2">
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              if (
                registerPaperLists?.data &&
                registerPaperLists?.data.length > 0
              ) {
                setExcelDownloadLoading(true);
                _HandleExportExcel();
              }
            }}
            loading={excelDownloadLoading}
            disabled={
              registerPaperLists && registerPaperLists.data.length > 0
                ? false
                : true
            }
          >
            Export Excel
          </Button>
        </Row>
        {isOnSearch ? (
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={
              registerPaperLists?.data && registerPaperLists?.data.length > 0
                ? registerPaperLists.data
                : []
            }
            onChange={({ current }) => {
              setPage(current - 1);
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={{
              pageSize: registerPaperConstants.tableSize,
              total: registerPaperLists?.meta?.total || 0,
            }}
          />
        ) : (
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={
              registerPaperLists?.data && registerPaperLists?.data.length > 0
                ? registerPaperLists.data
                : []
            }
            onChange={({ current }) => {
              setPage(current - 1);
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={{
              pageSize: registerPaperConstants.tableSize,
              total: registerPaperLists?.meta?.total || 0,
            }}
          />
        )}
      </PanelBody>
      <DownloadModal
        open={registerPaperDownloadOpen}
        handleClose={_HandleRegisterPaperDownloadClose}
        title="คุณต้องการดาวน์โหลดเอกสารใบสมัครใช่หรือไม่"
        handleDownload={(id) => {
          downloadPTRegisterPaperById(id);
        }}
        registerPaperId={id}
      />
    </Panel>
  );
};

export default PTRegisterPaperPage;
