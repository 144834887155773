import React from 'react'

const Radio = ({ id, name, value, className, spanChild, defaultChecked = false, checked = false, onChange, disabled = false, ...props }) => {
    return (
        <>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                className={`pos-radio ${className}`}
                // defaultChecked={defaultChecked}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <label htmlFor={id}>
                <span children={spanChild} className="text-nowrap" />
                <i />
            </label>
        </>
    )
}

export default Radio;
