import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import MemberFilter from "./components/MemberFilter";
import MemberTable from "./components/MemberTable";

export default function MemberList() {
  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ข้อมูลสมาชิก</Breadcrumb.Item>
      </Breadcrumb>
      
      <Panel>
        <PanelHeader>ข้อมูลสมาชิก</PanelHeader>
        <PanelBody>
          <MemberFilter />
          <MemberTable />
        </PanelBody>
      </Panel>
    </>
  );
}
