import React, { Component } from 'react'

export class Textbox extends Component {
    render() {
        const {
            type = "text",
            name = "",
            value = "",
            validate,
            size="sm", // lg,md,sm
            className = "",
            placeholder = "",
            disabled=false,
            onChange
        } = this.props;
        return (
            <>
                <input
                    type={type}
                    name={name}
                    value={value}
                    onKeyPress={(e)=>{
                        const invalidChars = [
                            "-",
                            "+",
                            "e",
                        ];
                        if(type==='number'){
                            if (invalidChars.includes(e.key)) {
                                e.preventDefault();
                            }
                        }
                        
                    }}
                    onChange={(e)=>onChange(e)}
                    className={`form-control form-control-${size} ${(validate) ? 'is-invalid' : ''} ${className}`}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                <div className="invalid-feedback">{validate}</div>
            </>
        )
    }
}

export default Textbox
