import { Col, Row } from "antd";
import React from "react";
import InputDescription from "./InputDescription";
import InputLot from "./InputLot";
import SelectAmount from "./SelectAmount";
import SelectDate from "./SelectDate";

const Index = ({ disabled }) => (
  <>
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <SelectDate type="mfg_date" disabled={disabled} />
      </Col>
      <Col span={12}>
        <SelectDate type="exp_date" disabled={disabled} />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <InputLot disabled={disabled} />
      </Col>
      <Col span={12}>
        <SelectAmount disabled={disabled} />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <InputDescription disabled={disabled} />
      </Col>
    </Row>
  </>
);

export default Index;
