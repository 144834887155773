/* eslint-disable no-unused-vars */
import {
  notification,
  Table,
  Button,
  Col,
  DatePicker,
  Row,
  message,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { useApi } from "../../../../../hooks/http2";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import FilterList from "../../components/FilterList";
import { axios } from "../../../../../utils/useAxios";
import SaleSummaryContext, {
  //initState,
  reducer,
} from "../../contexts/summary-context";
import Search from "antd/lib/input/Search";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectAllType from "../../../../../components/SelectAllType";

const Index = () => {
  const { Text } = Typography;
  const user = getUser();
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: user ? user?.branch_code : "",
      branch_name: user ? user?.branch_name : "",
      query: "",
      page: 1,
      limit: 10,
      buyer_type: [""],
    },
    summaryModal: {
      visible: false,
    },
  };

  const [page, setPage] = useState(1);
  const limit = 50;
  const toDay = moment().format("DD/MM/YYYY");

  const [state, dispatch] = useReducer(reducer, initState);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary`,
      token: true,
      params: {
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportToExcel = () => {
    if ((data?.data?.amount || []).length !== 0) {
      let excelData = [
        ["รายงานสรุปยอดขาย"],
        [
          `สาขา ${
            state.filter.branch === "" ? "ทั้งหมด" : state.filter.branch_name
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `ประเภทผู้ซื้อ ${
            state.filter.buyer_type.includes("")
              ? "ทั้งหมด"
              : state.filter.buyer_type.join(", ")
          }`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY HH:mm")} น.`,
        ],
        ["ลำดับที่", "พนักงาน", "รายละเอียด"],
        ["", "", "ประเภทการชำระเงิน", "ธนาคาร", "จำนวนเงิน(บาท)"],
      ];

      (data?.data?.amount || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.cashier_name,
            n.pay_in_type,
            n.bank_name,
            {
              v: n.amount === "" || n.amount === null ? 0 : n.amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
          ],
        ];
      });

      excelData = [...excelData, [""], ["รวมจำนวนเงินแบ่งตามประเภท"]];
      (data?.data?.payment || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            n.pay_in_type,
            n.bank_name,
            {
              v: n.amount === "" || n.amount === null ? 0 : n.amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
          ],
        ];
      });
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
        { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } },
        { s: { r: 2, c: 1 }, e: { r: 3, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 4 } },
      ];

      var wscols = [
        { wch: 20 },
        { wch: 30 },
        { wch: 25 },
        { wch: 35 },
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 10 },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(excelData);

      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A3"].s = centerStyles;
      ws["B3"].s = centerStyles;
      ws["C3"].s = centerStyles;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานสรุปยอดขาย");
      XLSX.writeFile(wb, "รายงานสรุปยอดขาย.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสรุปยอดขาย</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={6} className="pr-4">
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, ชื่อสมาชิก, เลขที่ใบเสร็จ, ชื่อพนักงาน"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                onSearch={reload}
                onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={1}>
              <Text className="text-right d-block" strong>
                วันที่เปิดบิล
              </Text>
            </Col>

            <Col span={4}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col span={1} align="center">
              <Text className="text-right d-block" strong>
                สาขา
              </Text>
            </Col>
            <Col span={2}>
              <SelectBranch
                userLogin
                allowDisableBranch
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100"
                withAll={user.branch_count > 1}
                onChange={(value, option) => {
                  dispatch({
                    type: "setFilter",
                    payload: {
                      branch: value, // ค่าที่เลือก
                      branch_name: option?.props.children || "ทั้งหมด", // ชื่อสาขาจาก children
                    },
                  });
                }}
              />
            </Col>
            <Col span={2}>
              <Text className="text-right d-block" strong>
                ประเภทผู้ซื้อ
              </Text>
            </Col>
            <Col span={4}>
              <SelectAllType
                withAll
                process_type="buyer_type"
                labelIndex="code"
                defaultValue={[""]}
                placeholder="เลือกประเภทผู้ซื้อ"
                className="w-100 p-r-5"
                mode="multiple"
                addCustomer
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { buyer_type: e },
                  });
                }}
              />
            </Col>

            <Col span={4}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Table
            dataSource={_.map(data?.data?.amount || [], (n) => ({
              ...n,
              key: _.uniqueId("amount-"),
            }))}
            bordered
            size="small"
            loading={loading}
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.data.length,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="right" />
            <Table.Column title="พนักงาน" dataIndex="cashier_name" />
            <Table.ColumnGroup title="รายละเอียด">
              <Table.Column
                title="ประเภทชำระเงิน"
                dataIndex="pay_in_type"
                render={(text) =>
                  text === "รวมทั้งหมด" || text === "รวมหลังหักส่วนลด" ? (
                    <div
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      {text}
                    </div>
                  ) : (
                    text
                  )
                }
              />
              <Table.Column title="ธนาคาร" dataIndex="bank_name" />
              <Table.Column
                title="จำนวนเงิน (บาท)"
                dataIndex="amount"
                align="right"
                render={(text) =>
                  text === "none" ? "" : numeral(text).format("0,0.00")
                }
              />
            </Table.ColumnGroup>
          </Table>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={_.map(data?.data?.payment || [], (n) => ({
              ...n,
              key: _.uniqueId("payment-"),
            }))}
            scroll={{ x: "max-content" }}
            pagination={false}
            className="mt-5"
          >
            <Table.ColumnGroup title="รวมจำนวนเงินแบ่งตามประเภท" align="left">
              <Table.Column
                title="ประเภทการชำระเงิน"
                dataIndex="pay_in_type"
                render={(text) => (text === "RP" ? "มูลค่า Redeem" : text)}
              />
              <Table.Column title="ธนาคาร" dataIndex="bank_name" />
              <Table.Column
                title="จำนวน"
                dataIndex="amount"
                render={(text) => numeral(text).format("0,0.00")}
              />
            </Table.ColumnGroup>
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
