/* eslint-disable react-hooks/exhaustive-deps */
import { createReducer } from "@reduxjs/toolkit";
import { Button, Col, DatePicker, Row, Table, Typography, message } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer } from "react";
import { FaFileExcel } from "react-icons/fa";
import SelectAllType from "../../../../../components/SelectAllType";
import SelectWarehouse from "../../../requisition/components/SelectWarehouse";
import { useApi } from "../../../../../hooks/http2";
import { displayTotal } from "../../../../../utils/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectDepartment from "../../../setting/permission/user/components/SelectDepartment";
import { getUser } from "../../../../../utils";
import { axios } from "../../../../../utils/useAxios";
import xlsx from "xlsx";
import _ from "lodash";

const INIT_STATE = {
  loaded: false,
  exporting: false,
  search: {
    page: 1,
    limit: 10,
    start_date: moment(),
    end_date: moment(),
    department_id: "",
    requisition_type: "",
    requisition_class: "",
    department_name :"",
    requisition_type_name:"",
    wh_code: "",
  },
};

const reducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase("SET_EXPORTING", (state, { payload }) => {
      state.exporting = payload;
    })
    .addCase("SET_SEARCH", (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    })
    .addCase("CLEAR", (state, { payload }) => {
      state.exporting = INIT_STATE.exporting;
      state.search = { ...INIT_STATE.search, ...payload };
    })
    .addCase("SET_LOADED", (state) => {
      state.loaded = true;
    })
    .addDefaultCase(() => {});
});

export default function Requisition() {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const { search } = state;

  const data = useApi(
    {
      url: `/boss/inventory/requisition`,
      token: true,
      params: {
        limit: search.limit,
        offset: search.limit * (search.page - 1),
        start_date: search.start_date
          ? moment(search.start_date).format("YYYY-MM-DD")
          : null,
        end_date: search.end_date
          ? moment(search.end_date).format("YYYY-MM-DD")
          : null,
        department_id: search.department_id,
        requisition_class: search.requisition_class,
        requisition_type: search.requisition_type,
        wh_code: search.wh_code,
      },
      initialLoad: false,
    },
    []
  );

  const whareHouse = useApi(
    {
      url: `/global/master/warehouse`,
      params: { user_login: "1" },
      token: true,
    },
    []
  );

  const exportExcel = async () => {
    try {
      const res = await axios.get(`/boss/inventory/requisition`, {
        params: {
          start_date: search.start_date
            ? moment(search.start_date).format("YYYY-MM-DD")
            : null,
          end_date: search.end_date
            ? moment(search.end_date).format("YYYY-MM-DD")
            : null,
          department_id: search.department_id,
          requisition_class: search.requisition_class,
          requisition_type: search.requisition_type,
          wh_code: search.wh_code,
        },
      });

      
      let _WhName = _.find(
        whareHouse.fetch?.data,
        (b) => b.code === search.wh_code
      )?.name;
      

      if ((res?.data?.total || 0) === 0) {
        return message.warning("ไม่พบข้อมูล");
      }

      let excelData = [
        ["รายงานการเบิกสินค้า"],
        [
          `คลังสินค้า : ${search.wh_code === "" ? "ทั้งหมด" : _WhName}`,
          "",
          "",
          `Class : ${
            search.requisition_class === ""
              ? "ทั้งหมด"
              : search.requisition_class
          }`,
          "",
          
          `วันที่ :  ${
            search.start_date
              ? moment(search.start_date).format("DD/MM/YYYY")
              : "-"
          } `,
          "",
          
          `ถึงวันที่ : ${
            search.end_date ? moment(search.end_date).format("DD/MM/YYYY") : "-"
          }`,
          "",
          "",
          
          `วันที่ดึงข้อมูล: ${moment().format("LLL")} น.`,
          "",
          "",
          "",
        ],
        [
          `ประเภทการเบิก : ${search.requisition_type === "" ? 'ทั้งหมด' : search.requisition_type_name}`,
          "",
          "",
          `แผนก : ${search.department_id === ''? 'ทั้งหมด': search.department_name}`
        ],
        [""],
        [
          "ลำดับ",
          "เลขที่ใบเบิก",
          "วันที่เบิก",
          "วันที่ต้องการสินค้า",
          "ประเภทการเบิก",
          "คลังสินค้า",
          "class",
          "ผู้เบิก",
          "แผนก",
          "รหัสสินค้า",
          "ชื่อสินค้า",
          "Source Code",
          "Event/Project",
          "จำนวน",
          "วัตถุประสงค์การเบิก"
        ]
      

      ];
      const export_data = res?.data?.items || [];
      
      export_data.forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
          n.document_no,
          moment(n.document_date).format("DD/MM/YYYY"),
          moment(n.requisition_date).format("DD/MM/YYYY"),
          n.requisition_type_name,
          n.warehouse_name,
          n.requisition_class,
          n.requisition_by_name,
          n.requisition_by_department,
          n.product_code,
          n.product_name,
          n.source_name,
          n.event_project_name,
          n.qty,
          n.reason,
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },
        { s: { r: 1, c: 10 }, e: { r: 1, c: 11 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
        { s: { r: 2, c: 3 }, e: { r: 2, c: 4 } },
        
      ];
      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 25 },
        { wch: 20 },
        
      ];
      

      const ws = xlsx.utils.aoa_to_sheet(excelData);
      ws["!merges"] = merge;
      ws["!cols"] = wscols;

      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws);
      xlsx.writeFile(
        wb,
        `รายงานการเบิกสินค้า_${moment().format("YYMMDD_HHmm")}.xlsx`
      );
    } catch (error) {
      message.error(error?.message);
    }
  };

  useEffect(() => {
    if (state.loaded) {
      data.reload();
    } else {
      dispatch({ type: "SET_LOADED" });
    }
  }, [search.page]);

  useEffect(() => {
    const user = getUser();

    dispatch({
      type: "CLEAR",
      payload: {
        wh_code: user?.branch_code + "-00",
      },
    });
  }, []);

  return (
    <Panel>
      <PanelHeader>รายงานการเบิกสินค้า</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-3 d-flex align-items-center">
          <Col md={2} className="text-left">
            <Typography.Text strong>วันที่เบิก :</Typography.Text>
          </Col>
          <Col md={5}>
            <DatePicker.RangePicker
              allowClear
              format={"DD/MM/YYYY"}
              value={[search.start_date, search.end_date]}
              placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
              onChange={(e) =>
                dispatch({
                  type: "SET_SEARCH",
                  payload: { start_date: e[0], end_date: e[1] },
                })
              }
            />
          </Col>
          <Col md={2} className="text-right">
            <Typography.Text strong>แผนก :</Typography.Text>
          </Col>
          <Col md={5}>
            <SelectDepartment
              className="w-100"
              value={search.department_id}
              withAll
              allowClear
              onChange={(e,value) =>{
                dispatch({
                  type: "SET_SEARCH",
                  payload: { department_id: e || "", department_name: value.props.children},
                })
 
              }
                             }
            />
          </Col>
          <Col md={4} offset={2}>
            <Button
              type="primary"
              block
              loading={data.loading}
              onClick={() => {
                if (search.page === 1) {
                  data.reload();
                } else {
                  dispatch({ type: "SET_SEARCH", payload: { page: 1 } });
                }
              }}
            >
              แสดงรายงาน
            </Button>
          </Col>
          <Col md={4}>
            <Button
              type="primary"
              block
              loading={state.exporting}
              onClick={exportExcel}
            >
              <FaFileExcel className="mr-2" /> Export Excel
            </Button>
          </Col>
        </Row>
        <Row gutter={16} className="mb-3 d-flex align-items-center">
          <Col md={3} className="text-left">
            <Typography.Text strong>ประเภทการเบิก :</Typography.Text>
          </Col>
          <Col md={4}>
            <SelectAllType
              process_type="requisition_type"
              className="w-100"
              withAll
              allowClear
              value={search.requisition_type}
              onChange={(e,value) =>{
                dispatch({
                  type: "SET_SEARCH",
                  payload: { requisition_type: e || "", requisition_type_name : value.props.children },
                })
              }
                
              }
            />
          </Col>
          <Col md={2} className="text-right">
            <Typography.Text strong>คลังสินค้า :</Typography.Text>
          </Col>
          <Col md={5}>
            <SelectWarehouse
              className="w-100"
              value={search.wh_code}
              userLogin={true}
              withAll
              allowClear
              onChange={(e, value) => {
                dispatch({ type: "SET_SEARCH", payload: { wh_code: e || "" } });
              }}
            />
          </Col>
          <Col md={2} className="text-right">
            <Typography.Text strong>Class :</Typography.Text>
          </Col>
          <Col md={3}>
            <SelectAllType
              process_type="requisition_class"
              className="w-100"
              withAll
              allowClear
              value={search.requisition_class}
              onChange={(e) => {
                dispatch({
                  type: "SET_SEARCH",
                  payload: { requisition_class: e || "" },
                });
              }}
            />
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          loading={data.loading}
          dataSource={data.fetch?.items || []}
          scroll={{
            x: "max-content",
          }}
          rowKey="row_id"
          pagination={{
            size: "default",
            current: search.page,
            pageSize: search.limit,
            showLessItems: true,
            total: data.fetch?.total || 0,
            showTotal: displayTotal,
            onChange: (page) => {
              dispatch({ type: "SET_SEARCH", payload: { page } });
            },
          }}
        >
          <Table.Column
            title="ลำดับ"
            key="row_id"
            dataIndex="row_id"
            align="right"
          />
          <Table.Column
            title="เลขที่ใบเบิก"
            key="document_no"
            dataIndex="document_no"
            align="center"
          />
          <Table.Column
            title="วันที่เบิก"
            key="document_date"
            dataIndex="document_date"
            align="center"
            render={(text) => moment(text).format("DD/MM/YYYY")}
          />
          <Table.Column
            title="วันที่ต้องการสินค้า"
            key="requisition_date"
            dataIndex="requisition_date"
            align="center"
            render={(text) => moment(text).format("DD/MM/YYYY")}
          />
          <Table.Column
            title="ประเภทการเบิก"
            key="requisition_type_name"
            dataIndex="requisition_type_name"
            align="center"
          />
          <Table.Column
            title="คลังสินค้า"
            key="warehouse_name"
            dataIndex="warehouse_name"
            align="center"
          />
          <Table.Column
            title="Class"
            key="requisition_class"
            dataIndex="requisition_class"
            align="center"
          />
          <Table.Column
            title="ผู้เบิกสินค้า"
            key="requisition_by_name"
            dataIndex="requisition_by_name"
            align="center"
          />
          <Table.Column
            title="แผนก"
            key="requisition_by_department"
            dataIndex="requisition_by_department"
            align="center"
          />
          <Table.Column
            title="รหัสสินค้า"
            key="product_code"
            dataIndex="product_code"
            align="center"
          />
          <Table.Column
            title="ชื่อสินค้า"
            key="product_name"
            dataIndex="product_name"
          />
          <Table.Column
            title="Source Code"
            key="source_name"
            dataIndex="source_name"
          />
          <Table.Column
            title="Event/Project"
            key="event_project_name"
            dataIndex="event_project_name"
          />
          <Table.Column
            title="จำนวน"
            key="qty"
            dataIndex="qty"
            align="right"
            render={(text) => numeral(text).format()}
          />
          <Table.Column
            title="วัตถุประสงค์การเบิก"
            key="reason"
            dataIndex="reason"
          />
        </Table>
      </PanelBody>
    </Panel>
  );
}
