import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const digitalProductServices = {
  useQueryGetDigitalProductProjects({ take, page, searchText }, onSuccess) {
    return useQuery(
      ['get-digital-product-projects', { take, page, searchText }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/digital-product-project`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              ...(queryKey[1].searchText && {
                searchText: queryKey[1].searchText,
              }),
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetDigitalProductProjectById({ digitalProductProjectId }, onSuccess) {
    return useQuery(
      ['get-digital-product-project-by-id', { digitalProductProjectId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/digital-product-project/${queryKey[1].digitalProductProjectId}`
          );

          return res.data.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!digitalProductProjectId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateDigitalProductProject(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/digital-product-project`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateDigitalProductProject(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/digital-product-project/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateDigitalProductProjectStatus(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/digital-product-project/status/${data.id}`,
            { status: data.status }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateDigitalProductProjectDisplay(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/digital-product-project/display/${data.id}`,
            { display: data.display }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteDigitalProductProject(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/digital-product-project/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateDigitalProductStatus(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/digital-product/status/${data.id}`,
            {
              status: data.status,
              ...(data.rejectedReason && {
                rejectedReason: data.rejectedReason,
              }),
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCreateDigitalProduct(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/digital-product/${data.digitalProductProjectId}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateDigitalProduct(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/digital-product/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteDigitalProduct(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/digital-product/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetDigitalProducts(
    {
      take,
      page,
      memberCode,
      digitalProductProjectId,
      status,
      vote,
      requestOrder,
    },
    onSuccess
  ) {
    return useQuery(
      [
        'get-digital-products',
        {
          take,
          page,
          memberCode,
          digitalProductProjectId,
          status,
          vote,
          requestOrder,
        },
      ],
      async ({ queryKey }) => {
        try {
          const params = {
            take: queryKey[1].take,
            page: queryKey[1].page,
            digitalProductProjectId: queryKey[1].digitalProductProjectId,
            ...(queryKey[1].memberCode && {
              memberCode: queryKey[1].memberCode,
            }),
            ...(queryKey[1].status && { status: queryKey[1].status }),
            vote: queryKey[1].vote,
            ...(queryKey[1].requestOrder !== '' && {
              requestOrder: queryKey[1].requestOrder,
            }),
          };

          const res = await fitStudioRequest.get(`/digital-product`, {
            params,
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetDigitalProductById({ digitalProductId }, onSuccess) {
    return useQuery(
      ['get-digital-product-by-id', { digitalProductId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/digital-product/${queryKey[1].digitalProductId}`
          );

          return res.data.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!digitalProductId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useQueryGetDigitalProductVoteByDigitalProductId(
    { digitalProductId, take, page, memberCode },
    onSuccess
  ) {
    return useQuery(
      [
        'get-digital-product-vote-by-digital-product-id',
        { digitalProductId, take, page, memberCode },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/digital-product/digital-product-vote/${queryKey[1].digitalProductId}`,
            {
              params: {
                take: queryKey[1].take,
                page: queryKey[1].page,
                ...(queryKey[1].memberCode && {
                  memberCode: queryKey[1].memberCode,
                }),
              },
            }
          );

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!digitalProductId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationDownloadDigitalProductListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async ({
        take,
        page,
        searchText,
        digitalProductProjectId,
        status,
        vote,
        requestOrder,
      }) => {
        try {
          const res = await fitStudioRequest.get(
            `/digital-product/download-excel/by-filter`,
            {
              params: {
                take,
                page,
                digitalProductProjectId,
                ...(searchText && {
                  searchText: searchText,
                }),
                ...(status && { status: status }),
                vote: vote,
                ...(requestOrder !== '' && {
                  requestOrder: requestOrder,
                }),
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'digital-product-list.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
  useMutationDownloadDigitalProductVoteListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async ({ take, page, digitalProductId, memberCode }) => {
        try {
          const res = await fitStudioRequest.get(
            `/digital-product/digital-product-vote/download-excel/by-filter/${digitalProductId}`,
            {
              params: {
                take,
                page,
                memberCode,
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'digital-product-vote-list.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
};

export default digitalProductServices;
