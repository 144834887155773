/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Modal,
  Input,
  notification,
} from "antd";
import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axios, getToken, URL_API } from "../../../../../../utils";
import { resCatchModal } from "../../../../../util/helper";

const { Item } = Form;
const { Option } = Select;

const ItemModal = ({
  form,
  title,
  visible,
  sourceSelect,
  onCancel,
  reload,
}) => {
  const { type: type_params } = useParams();
  const { getFieldDecorator, validateFields, setFieldsValue } = form;

  useEffect(() => {
    if (sourceSelect) {
      const { internal_id, name, status } = sourceSelect;
      setFieldsValue({
        internal_id,
        name,
        status,
      });
    }
  }, [sourceSelect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, res) => {
      if (err) return;
      let source = axios.CancelToken.source();
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "คุณต้องการบันทึกรายการนี้หรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/setting/master/${type_params}`,
              headers: { Authorization: "Bearer " + getToken() },
              data: { ...res, code: sourceSelect?.code || null },
              cancelToken: source.token,
            })
              .then(() => {
                reload();
                resolve();
                notification.success({
                  message: "Success",
                  description: `บันทึกข้อมูลเรียบร้อย`,
                });
                onCancel();
              })
              .catch((e) => {
                reject(e);
              });
          }).catch((e) => {
            resCatchModal(e);
          });
        },
        onCancel() {
          source.cancel("cancel");
        },
      });
    });
  };

  return (
    <Modal
      title={`${title} ${sourceSelect?.code}`}
      visible={visible}
      footer={false}
      zIndex={1080}
      width={600}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <Form
        name="source-code"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onSubmit={handleSubmit}
      >
        <Item label="Internal ID" className="mb-0">
          {getFieldDecorator("internal_id", {
            initialValue: "",
          })(<Input maxLength={10} autoComplete="off" />)}
        </Item>
        <Item label="ชื่อ" className="mb-0">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "กรุณาระบุชื่อ",
              },
            ],
          })(<Input maxLength={200} autoComplete="off" />)}
        </Item>
        <Item label="สถานะ" className="mb-0">
          {getFieldDecorator("status", {
            initialValue: "active",
            rules: [
              {
                required: true,
                message: "กรุณาเลือกสถานะ",
              },
            ],
          })(
            <Select className="width-125" dropdownStyle={{ zIndex: 1080 }}>
              <Option value="active">ใช้งาน</Option>
              <Option value="inactive">ไม่ใช้งาน</Option>
            </Select>
          )}
        </Item>
        <Row gutter={[8, 8]} className="mt-3">
          <Col span={24} className="text-center">
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create()(ItemModal);
