import { Button, Modal, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { actionInventoryCount } from "../../../../redux/slices/inventory-count";

const { Text } = Typography;

const { clearCurrentAdd } = actionInventoryCount;

const ButtonClear = ({ disabled }) => {
  const dispatch = useDispatch();

  const handleClickClear = () => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1 text-cv">
            เคลียร์ข้อมูล
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(clearCurrentAdd());
      },
    });
  };

  return (
    <Button
      className={`${
        disabled ? "" : "bg-cv border-cv text-white "
      }width-100 mr-3`}
      disabled={disabled}
      onClick={handleClickClear}
    >
      เคลียร์ข้อมูล
    </Button>
  );
};

export default memo(ButtonClear);
