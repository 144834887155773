import axios from "axios";
import _ from "lodash";
// import moment from "moment";
import moment from "moment";

const STORAGE_TOKEN_KEY = process.env.REACT_APP_STORAGE_TOKEN_KEY;
const STORAGE_USER = process.env.REACT_APP_STORAGE_USER;

const URL_API = process.env.REACT_APP_URL_API;
const MEMBER_API = process.env.REACT_APP_URL_API_MEMBER;
const REPORT_API = process.env.REACT_APP_URL_API_PRINT_FORM;
const EDITOR_API_KEY = process.env.REACT_APP_EDITOR_TINYMCE_API_KEY;
const MEMBER_URL = process.env.REACT_APP_MEMBER_URL;

function getToken() {
  return localStorage.getItem(STORAGE_TOKEN_KEY);
}

function setToken(value) {
  if (value == null) {
    localStorage.removeItem(STORAGE_TOKEN_KEY);
  } else {
    localStorage.setItem(STORAGE_TOKEN_KEY, value);
  }
}

function getUser() {
  return JSON.parse(localStorage.getItem(STORAGE_USER));
}

function setUser(value) {
  if (value == null) {
    localStorage.removeItem(STORAGE_USER);
  } else {
    localStorage.setItem(STORAGE_USER, JSON.stringify(value));
  }
}

function getUserObj() {
  return getUser() || {};
}

function setFormat(type = "", value = "") {
  let new_value = value;
  let cleaned = ("" + value).replace(/\D/g, "");

  if (type === "mobile") {
    let match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    if (match) {
      new_value = match.slice(1).join("-"); //match[1] + "-" + match[2] + "-" + match[3];
    }
  } else if (type === "id_card") {
    let match = cleaned.match(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/);
    if (match) {
      new_value = match.slice(1).join("-");
    }
  }

  return new_value;
}

export {
  _,
  axios,
  URL_API,
  MEMBER_API,
  REPORT_API,
  EDITOR_API_KEY,
  MEMBER_URL,
  getToken,
  setToken,
  moment,
  getUser,
  setUser,
  getUserObj,
  setFormat,
};
