/* eslint-disable no-unused-vars */
import { Button, Col, Empty, Input, Modal, Pagination, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHttp } from '../../../../../../hooks/http';
import { URL_API } from '../../../../../../utils';
import { useSelector, useDispatch } from 'react-redux'
import { actionPromotion, proGiveAwaySelector } from '../../../../../../redux/slices/promotion';

export default function ProductList({ ...props }) {
    const dispatch = useDispatch()
    const state = useSelector(proGiveAwaySelector)
    const { ADD_PRODUCT_SOURCE } = actionPromotion

    const limit = 5;
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [search, setSearch] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [loading, data, error] = useHttp({
        method: 'get',
        url: `${URL_API}/system/products`,
        params: {
            limit,
            offset: (page - 1) * limit,
            search
        },
        token: true
    }, [search, page]);

    useEffect(() => {
        let newData = [];
        let total = 0;
        if (data && data.data) {
            newData = data.data.map(n => ({ ...n, key: n.id }))
            total = data.total
        }
        setDataSource(newData)
        setTotalPage(total)
    }, [data, page])

    const addProduct = (record) => {
        dispatch(ADD_PRODUCT_SOURCE({
            product_code: record.product_code,
            product_name: record.product_name_local,
            // qty: record.qty,
            // price: record.price,
        }))
    }
    return (
        <>
            <Modal
                {...props}
            >
                <Row className="mb-3" type="flex">
                    <Col md={10}>
                        <Input.Search
                            placeholder="ชื่อสินค้า,SKU"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table className="w-100">
                            <thead>
                                <tr style={{ backgroundColor: '#bcbcbc' }}>
                                    <th colSpan={11} className="text-center">รายการสินค้า</th>
                                </tr>
                                <tr className="text-center">
                                    <th width="5%">ลำดับ</th>
                                    <th width="15%">รหัสสินค้า</th>
                                    <th>ชื่อสินค้า</th>
                                    <th width="15%">Item Code</th>
                                    <th width="5%"><i className="fa fa-cogs" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading === true ?
                                        <tr className="text-center">
                                            <td colSpan={9}><Skeleton active /></td>
                                        </tr>
                                        :
                                        dataSource.length === 0 ?
                                            <tr className="text-center">
                                                <td colSpan={9}><Empty /></td>
                                            </tr>
                                            :
                                            dataSource.map((n, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{n.row_id}</td>
                                                        <td>{n.backend_sku_code}</td>
                                                        <td>{n.product_name_local}</td>
                                                        <td>{n.product_code}</td>
                                                        <td>
                                                            <Button type="primary" onClick={() => addProduct(n)}>เลือก</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                }
                            </tbody>
                        </table>
                        {
                            totalPage === 0 ?
                                null
                                :
                                <Pagination current={page} total={totalPage} pageSize={limit} className="text-center" onChange={e => setPage(e)} />
                        }
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
