/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, DatePicker } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import ImageUpload from '../../../fit-studio/components/upload/ImageUpload';
import fitQuizServices from '../../../../../services/fit-quiz.services';
import moment from 'moment';

const LegacyQuizRewardForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  fitQuizServices.useQueryGetLegacyQuizRewardById(
    { quizRewardId: watch('id') },
    (data) => {
      reset({
        ...data,
        startDate: moment(data.startDate),
        endDate: moment(data.endDate),
        formType: 'edit',
      });
    }
  );

  return (
    <div>
      <Controller
        name="frontImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  รูปภาพเหรียญทอง <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={200}
                  height={200}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="greyFrontImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  รูปภาพเหรียญเงิน <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={200}
                  height={200}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ผลิตภัณฑ์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="max"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คะแนนเป้าหมาย <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="จำนวนเต็ม"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="max"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่เริ่มต้น <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่เริ่มต้น"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="endDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่สิ้นสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default LegacyQuizRewardForm;
