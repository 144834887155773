import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { bankSelector } from "../../../../redux/slices/member";

export default function BankPanel() {
  const bank = useSelector(bankSelector);
  return (
    <Table size="small" dataSource={bank} rowKey="id" pagination={false}>
      <Table.Column
        title="เลขบัญชี"
        dataIndex="account_number"
        align="center"
      />
      <Table.Column title="ชื่อบัญชี" dataIndex="account_name" />
      <Table.Column title="ธนาคาร" dataIndex="bank_name" />
      <Table.Column
        title="ประเภทบัญชี"
        dataIndex="eft_type"
        align="center"
        render={(text) =>
          text?.toLowerCase() === "person" ? "บุคคลธรรมดา" : "นิติบุคคล"
        }
      />
    </Table>
  );
}
