/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, DatePicker, Switch, Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import digitalProductServices from '../../../../../services/digital-product.services';
import ImageUpload from '../../../fit-studio/components/upload/ImageUpload';

const DigitalProductForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  digitalProductServices.useQueryGetDigitalProductById(
    { digitalProductId: watch('id') },
    (data) => {
      reset({
        id: watch('id'),
        formType: watch('formType'),
        ...data,
      });
    }
  );

  console.log(errors);

  return (
    <div>
      <Controller
        name="image"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่องาน <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={2}
                  value={value}
                  onChange={onChange}
                  placeholder="ชื่องาน"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>คำบรรยาย</Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={4}
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยาย"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="memberType"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ประเภทนักธุรกิจ</Typography.Text>
              </Col>
              <Col span={20}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {[
                    {
                      name: 'นักธุรกิจเลกาซี',
                      value: 'Distributor',
                    },
                    {
                      name: 'VIP Member',
                      value: 'Member',
                    },
                  ].map((item) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="memberCode"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  รหัสนักธุรกิจ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัสนักธุรกิจ"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="memberCode"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="isPartner"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>รหัสร่วม</Typography.Text>
              </Col>
              <Col span={20}>
                <Switch checked={value} onChange={onChange} />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="requestOrder"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ต้องการสั่งซื้อหรือไม่</Typography.Text>
              </Col>
              <Col span={20}>
                <Switch checked={value} onChange={onChange} />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>เบอร์โทรติดต่อกลับ</Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="เบอร์โทรติดต่อกลับ"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="phoneNumber"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default DigitalProductForm;
