/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LobPage from './pages/LobPage';

const queryClient = new QueryClient();

export default ({ match }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route path={`${match.path}`} exact children={<LobPage />} />
      </Switch>
    </QueryClientProvider>
  );
};
