import { Button, Typography } from "antd";
import React, { useState } from "react";
import ModalOTP from "./ModalOTP";

const Index = ({
  mobile = "",
  email = "",
  partnerCode = "",
  partnerType = "",
  setMobileToVerify = () => null,
}) => {
  const [visible, setVisible] = useState(false);
  const disabled = !mobile || mobile === "invalid mobile number";

  return (
    <>
      <Typography.Text className="mr-2">
        ({partnerCode} : {mobile})
      </Typography.Text>
      <Button
        disabled={disabled}
        size="small"
        className={!disabled ? "bg-nn border-nn text-white" : ""}
        onClick={() => setVisible(true)}
      >
        ยืนยัน
      </Button>
      {!disabled && (
        <ModalOTP
          visible={visible}
          data={{
            mobile,
            email,
            partner_code: partnerCode,
            partner_type: partnerType,
          }}
          onCancel={() => setVisible(false)}
          onOk={() => {
            setVisible(false);
            setMobileToVerify(null);
          }}
        />
      )}
    </>
  );
};

export default Index;
