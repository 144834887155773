/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Table,
  typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
// import XLSX from "xlsx";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import SelectBranch from "../../../pos/components/SelectBranch";

const { Text } = typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [billSelectedId, setBillSelectedId] = useState(null);
  const [preview, setPreview] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();
  const toDay = moment().format("DD/MM/YYYY");

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [branch, setBranch] = useState("");
  const [branchName, setBranchName] = useState("ทั้งหมด");
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("Active");
  const [teamFilter, setTeamFilter] = useState("");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-renew-membership`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        teamName: teamFilter,
        memberType: memberTypeFilter,
        ...state.filter,
        branch : branch,
        branch_name : branchName,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const exportDataData = data ? [data.data] : [];

  const exportToExcel = () => {
    // exportDataData.length !== 0
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานต่ออายุสมาชิก"],
        [
          `สาขา ${
            branchName
          }`,
          "",
          `ประเภทสมาชิก ${
            memberTypeFilter === "" ? "ทั้งหมด" : memberTypeFilter
          }`,
          "",
          "",
          `Team ${teamFilter === "" ? "ทั้งหมด" : teamFilter} `,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [""],
        [
          "ลำดับที่",
          "วันที่สมัครสมาชิก",
          "วันที่ต่ออายุสมาชิก",
          "วันที่ครบอายุสมาชิก",
          "รหัสนักธุรกิจ",
          "ชื่อ-นามสกุล ผู้สมัครหลัก",
          "เลขประจำตัวประชาชน ผู้สมัครหลัก",
          "วัน/เดือน/ปี เกิด ผู้สมัครหลัก",
          "อายุ ผู้สมัครหลัก",
          "Location Base",
          "สัญชาติ",
          "ชื่อ-นามสกุล ผู้สมัครร่วม",
          "เลขประจำตัวประชาชน ผู้สมัครร่วม",
          "วัน/เดือน/ปี เกิด ผู้สมัครร่วม",
          "อายุ",
          "Location Base",
          "สัญชาติ",
          "ใบสมัคร",
          "บัตรประชาชน",
          "สถานะ",
          //"Team",
          //"ชื่อผู้ทำรายการ",
          //"ประเภทการจ่าย",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            n.row_id,
            // n.member_type === "Distributor"
            //   ? "นักธุรกิจ"
            //   : n.member_type === "Member"
            //   ? "VIP MEMBER"
            //   : "",
            moment(n.register_date).format("DD/MM/YYYY"),
            moment(n.document_date).format("DD/MM/YYYY"),
            moment(n.expiredate).format("DD/MM/YYYY"),
            //n.document_no,
            //n.bill_location,
            n.partner_code,
            n.first_name_th + " " + n.sur_name_th,
            n.id_card,
            moment(n.birth_date).format("DD/MM/YYYY"),
            n.age_years,
            n.location_base,
            n.nationality,
            n.married_first_name_th === null
              ? "-"
              : n.married_first_name_th + " " + n.married_sur_name_th,
            n.married_id_card,
            n.married_birth_date === null
              ? ""
              : moment(n.married_birth_date).format("DD/MM/YYYY"),
            n.married_age_years,
            n.married_location_base,
            n.married_nationality,
            n.approve_document === null ? '' : n.approve_document === true ? 'Approve' : 'Reject',
            n.approve_id_card === null ? '' : n.approve_document === true ? 'Approve' : 'Reject',
            n.status_text,
            // n.team_name,
            // n.buyer_name,
            // n.pay_in_type,
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },
        { s: { r: 1, c: 10 }, e: { r: 1, c: 12 } },
        // manage : header table
        // { s: { r: 3, c: 4 }, e: { r: 3, c: 10 } },
        // { s: { r: 3, c: 11 }, e: { r: 3, c: 16 } },

        // { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
        // { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
        // { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
        // { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },
        // { s: { r: 3, c: 17 }, e: { r: 4, c: 17 } },
        // { s: { r: 3, c: 18 }, e: { r: 4, c: 18 } },
        // { s: { r: 3, c: 19 }, e: { r: 4, c: 19 } },
        // { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },

        // { s: { r: 3, c: 18 }, e: { r: 4, c: 18 } },
        // { s: { r: 3, c: 19 }, e: { r: 4, c: 19 } },
        // { s: { r: 3, c: 20 }, e: { r: 4, c: 20 } },
        // { s: { r: 3, c: 21 }, e: { r: 4, c: 21 } },
      ];

      // word space in column [of index]
      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
      //ws["E3"].s = centerStyles;
      //ws["L3"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานต่ออายุสมาชิก");
      XLSX.writeFile(wb, "รายงานต่ออายุสมาชิก.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  const memberTypeOptions = [
    { value: "", text: "ทั้งหมด" },
    { value: "Distributor", text: "นักธุรกิจ" },
    { value: "Member", text: "VIP MEMBER" },
  ];

  const partnerStatusOptions = [
    ..._.map(filterDataSource.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานต่ออายุสมาชิก</PanelHeader>
        <PanelBody>
          <Row gutter={8} type="flex" className="mb-4 mt-2 align-items-center">
            <Col span={8} className=" p-r-15">
              <Input
                placeholder="รหัสสมาชิก, ชื่อสมาชิก, ชื่อพนักงาน"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  });
                }}
              />
            </Col>
            <Col align="center" className="ml-3">
              <Text strong>วันที่ต่ออายุสมาชิก</Text>
            </Col>
            <Col span={4} className=" p-r-15">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from:
                        moment(e[0]).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                      date_to:
                        moment(e[1]).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                    },
                  })
                }
              />
            </Col>
            <Col align="center" className="ml-3">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={2}>
              <SelectBranch
                defaultValue=""
                placeholder="เลือกสาขา"
                className="w-100 "
                withAll
                onChange={(e,v) => {
                  setBranch(e);
                  setBranchName(v.props.children)

                  // dispatch({
                  //   type: "setFilter",
                  //   payload: { branch: e },
                  // });
                }}
              />
            </Col>
            <Col span={6}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={8} type="flex" className="mb-4 mt-2 align-items-center">
            <Col>
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                className="w-100"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>
            <Col className="ml-3">
              <Text strong>Team</Text>
            </Col>
            <Col span={4}>
              <SelectTeam
                withAll
                defaultValue={teamFilter}
                onChange={(e) => {
                  let change = e === "ทั้งหมด" ? "" : e;
                  setTeamFilter(change);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.data ? dataSource.data.length : 50,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            {/* <Table.Column
              title="ประเภทสมาชิก"
              dataIndex="member_type"
              align="center"
              render={(text) => {
                if (text === "Distributor") {
                  return "นักธุรกิจ";
                } else if (text === "Member") {
                  return "VIP MEMBER";
                } else {
                  return text;
                }
              }}
            /> */}
            <Table.Column
              title="วันที่สมัครสมาชิก"
              dataIndex="register_date"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="วันที่ต่ออายุสมาชิก"
              dataIndex="document_date"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="วันที่ครบอายุสมาชิก"
              dataIndex="expiredate"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            {/* <Table.Column
              title="เลขที่ใบเสร็จ"
              dataIndex="document_no"
              align="center"
            /> */}
            {/* <Table.Column
              title="สาขาเปิดบิล"
              dataIndex="bill_location"
              align="center"
            /> */}
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="center"
            />
            <Table.ColumnGroup title="ข้อมูลผู้สมัครหลัก">
              <Table.Column
                title="ชื่อ"
                dataIndex="first_name_th"
                align="left"
              />
              <Table.Column title="สกุล" dataIndex="sur_name_th" align="left" />
              <Table.Column
                title="เลขประจำตัวประชาชน"
                dataIndex="id_card"
                align="center"
              />
              <Table.Column
                title="วัน/เดือน/ปี เกิด"
                dataIndex="birth_date"
                align="center"
                render={(text) => moment(text).format("DD/MM/YYYY")}
              />
              <Table.Column title="อายุผู้สมัครหลัก" dataIndex="age_years" align="center" />
              <Table.Column
                title="Location Base"
                dataIndex="location_base"
                align="center"
              />
              <Table.Column
                title="สัญชาติ"
                dataIndex="nationality"
                align="center"
              />
            </Table.ColumnGroup>
            <Table.ColumnGroup title="ข้อมูลผู้สมัครร่วม">
              <Table.Column
                title="ชื่อ"
                dataIndex="married_first_name_th"
                align="left"
              />
              <Table.Column
                title="สกุล"
                dataIndex="married_sur_name_th"
                align="left"
              />
              <Table.Column
                title="เลขประจำตัวประชาชน"
                dataIndex="married_id_card"
                align="center"
              />
              <Table.Column
                title="วัน/เดือน/ปี เกิด"
                dataIndex="married_birth_date"
                align="center"
                render={(text) => {
                  if (text === null) {
                    return "";
                  }
                  return moment(text).format("DD/MM/YYYY");
                }}
              />
              <Table.Column
                title="อายุ"
                dataIndex="married_age_years"
                align="center"
              />
               <Table.Column
                title="Location Base"
                dataIndex="married_location_base"
                align="center"
              />
              <Table.Column
                title="สัญชาติ"
                dataIndex="married_nationality"
                align="center"
              />
            </Table.ColumnGroup>
            <Table.Column
              title="ใบสมัคร"
              dataIndex="approve_document"
              align="center"
              render={(status)=>{
                if(status !== null && status !== ''){
                  return status === true ? "Approve" : "Reject"
                }else{
                  return ""
                }
              }}
            />
            <Table.Column
              title="บัตรประชาชน"
              dataIndex="approve_id_card"
              align="center"
              render={(status)=>{
                if(status !== null  && status !== ''){
                  return status === true ? "Approve" : "Reject"
                }else{
                  return ""
                }
                
              }}
            />
            <Table.Column
              title="สถานะ"
              dataIndex="status_text"
              align="center"
            />
            {/* <Table.Column title="Team" dataIndex="team_name" align="center" />
            <Table.Column
              title="ชื่อผู้ทำรายการ"
              dataIndex="buyer_name"
              align="center"
            />
            <Table.Column
              title="ประเภทการจ่าย"
              dataIndex="pay_in_type"
              align="center"
            /> */}
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
