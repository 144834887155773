import React from 'react';
import { Modal } from 'antd';
import QRCode from 'react-qr-code';

const ClassScheduleQrCodeModal = ({ open, handleClose, classSchedule }) => {
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById('qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');

    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `qr_code.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <Modal
      title={'Qr code'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ปิด"
      onOk={downloadQRCode}
      width={'30vw'}
    >
      <div>
        <QRCode
          size={256}
          id="qrCodeEl"
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          title={classSchedule.title}
          value={classSchedule.id}
          viewBox={`0 0 128 128`}
        />
      </div>
    </Modal>
  );
};

export default ClassScheduleQrCodeModal;
