/* eslint-disable no-unused-vars */
import { Button, Col, Row, Tooltip, Typography } from "antd";
import React from "react";
import { FaCheck, FaEye, FaTimes } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import ImgError from "../../../../../components/ImgError";
import styles from "../../../../../scss/module/promotion.module.scss";

const CardPromotion = ({
  active,
  proId,
  azureUrl,
  name,
  description,
  proIndex,
  isBanned,
  isSoldOut,
  onSelect,
  onPreview,
  onViewDisableItem,
}) => {
  const card_class = active ? styles.active : "";
  return (
    <Row
      type="flex"
      className={`${styles["rec-promotion"]} ${styles["card-promotion"]} ${card_class}`}
    >
      <Col span={24}>
        <div className={styles.img}>
          <ImgError
            className="w-100 h-100 object-fit-cover"
            src={azureUrl}
            alt={`pro-img-${proId}`}
            onClick={() => onSelect(proIndex)}
          />
          {active && (
            <div className={styles.success}>
              <FaCheck size={18} />
            </div>
          )}
          {(isBanned || isSoldOut) && (
            <Tooltip
              placement="right"
              title={
                isBanned
                  ? "สินค้าบางรายการไม่สามารถซื้อได้"
                  : "สินค้าบางรายการหมด"
              }
            >
              <div
                className={isBanned ? styles.failed : styles.warn}
                onClick={() => onViewDisableItem(proIndex)}
              >
                {isBanned ? (
                  <FaTimes size={18} />
                ) : (
                  <TiWarningOutline size={18} />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </Col>
      <Col span={24} className={styles.content}>
        <Row type="flex" gutter={8} className="align-items-center">
          <Col span={21} className="text-overflow">
            <Typography.Text strong className="user-select-none">
              {name}
            </Typography.Text>
          </Col>
          <Col span={3} className="text-center">
            <Button
              shape="circle"
              type="primary"
              size="small"
              onClick={() =>
                onPreview({
                  pro_id: proId,
                  description,
                  name,
                  azure_url: azureUrl,
                })
              }
            >
              <FaEye />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default React.memo(CardPromotion);
