/* eslint-disable no-unused-vars */
import { Col, Row, Select } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { actionMWBannerProduct } from "../../../../../redux/slices/manage-website/banner-product-category.slice";

const Criteria = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state.mw.banner_product_category,
    shallowEqual
  );
  const { SET_PAGE } = actionMWBannerProduct;

  return (
    <Row gutter={16} className="align-items-center mb-3 d-flex">
      <Col md={2} className="text-right">
        <span>ประเภท:</span>
      </Col>
      <Col md={6}>
        <Select
          className="w-100"
          value={state.page}
          onChange={(e) =>
            dispatch(
              SET_PAGE({
                page: e,
                current:1,
              })
            )
          }
        >
          <Select.Option value="product">Product</Select.Option>
          <Select.Option value="redeem">Redeem</Select.Option>
        </Select>
      </Col>
    </Row>
  );
};

export default Criteria;
