import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../views/util/panel';

const ControlPanel = ({ titlePanel, children, ...props }) => {
    return (
        <Panel className={props.className}>
            <PanelHeader>
                {titlePanel}
            </PanelHeader>
            <PanelBody children={children} />
        </Panel>
    )
}

export default ControlPanel;
