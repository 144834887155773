import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Error404 from "../Error404";
import PageIndex from "./index";
import Manage from "./Manage";
export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <Redirect to={`${match.path}/pending`} />
    </Route>
    <Route exact path={`${match.path}/:process`} component={PageIndex} />
    <Route
      exact
      path={`${match.path}/:process/:document_no`}
      component={Manage}
    />
    <Route component={Error404} />
  </Switch>
);
