import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Error404 from "../Error404";
import PageIndex from "./index";

const routes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/request`} />
      </Route>
      <Route exact path={`${match.path}/:process`} component={PageIndex} />
      <Route component={Error404} />
    </Switch>
  );
};

export default routes;
