/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Panel, PanelHeader, PanelBody } from "../../util/panel";
import { Row, Col, Button, Input, Select, Typography, DatePicker } from "antd";

import "../../../scss/delivery.scss";
import Tabs from "./components/Tabs";
import Waiting from "./Waiting";
import Complete from "./Complete";
import Confirm from "./Confirm";
import SelectSource from "../../../components/SelectSource";
import moment from "moment";

const Model = {
  date_from: null,
  date_to: null,
  query: "", //   รหัสนักธุรกิจ,ชื่อนักธุรกิจ
  query_bill: "", // เลขที่บิล 
  source: "", // แหล่งที่มา
};

const Index = () => {
  const [activeTab, setActiveTab] = useState("wait");
  const [criteria, setCriteria] = useState(Model);
  const [source, setSource] = useState("");
  const [sendCriteria, setSendCriteria] = useState(Model);
  useEffect(() => {
    if (activeTab === "complete") {
      let _model = {
        ...sendCriteria,
        date_from: moment(),
        date_to: moment(),
      }
      setCriteria(_model)
      setSendCriteria(_model)
    } else {
      setCriteria(Model)
      setSendCriteria(Model)
    }
  }, [activeTab]);

  return (
    <Panel>
      <PanelHeader>จัดส่งสินค้า</PanelHeader>
      <PanelBody>
        <Row className="mb-4 mt-3">
          <Col span={18} offset={3}>
            <Tabs active={activeTab} onClick={(e) => setActiveTab(e)} />
          </Col>
        </Row>
        <Row gutter={8} type="flex" className="mb-2 align-items-center">
          <Col span={2} className="text-right">
            <Typography.Text strong className="text-nowrap">
              แหล่งที่มา
            </Typography.Text>
          </Col>
          <Col span={6}>
            <SelectSource
              withAll
              value={source}
              size="small"
              onChange={(value) => {
                setSource(value);
                setCriteria({ ...criteria, source: value });
              }}
            />
          </Col>
          <Col span={2} className="text-right">
            <Typography.Text strong className="text-nowrap">
              วันที่
            </Typography.Text>
          </Col>
          <Col span={6}>
            <DatePicker.RangePicker
              size="small"
              className="w-100"
              value={[criteria?.date_from, criteria?.date_to]}
              placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
              format="DD/MM/YYYY"
              onChange={(e) => {
                setCriteria({
                  ...criteria,
                  date_from: e[0] || null,
                  date_to: e[1] || null,
                })
              }
              }
            />
          </Col>
          <Col span={6}>
          <Input
              className="w-100"
              size="small"
              placeholder="รหัสนักธุรกิจ, ชื่อนักธุรกิจ"
              value={criteria.query}
              onChange={(e) =>
                setCriteria({ ...criteria, query: e.target.value })
              }
              allowClear={true}
            />
          </Col>
        </Row>
        <Row gutter={8} type="flex" className="mb-2 align-items-center">
          <Col span={2}></Col>
          <Col span={20}>
            <Input
              className="w-100"
              size="small"
              placeholder="เลขที่บิล"
              value={criteria.query_bill}
              onChange={(e) =>
                setCriteria({ ...criteria, query_bill: e.target.value })
              }
              allowClear={true}
            />
          </Col>
          <Col span={2}>
            <Button
              type="default"
              size="small"
              onClick={() => setSendCriteria(criteria)}
            >
              ค้นหา
            </Button>
          </Col>
        </Row>
        {activeTab === "complete" ? (
          <Complete className="mb-2" criteria={sendCriteria} />
        ) : activeTab === "confirm" ? (
          <Confirm className="mb-2" criteria={sendCriteria} />
        ) : (
          <Waiting className="mb-2" criteria={sendCriteria} />
        )}
      </PanelBody>
    </Panel>
  );
};

export default Index;
