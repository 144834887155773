/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import F from '../../../../../assets/img/legacy-trip/maldives/W.png';
import M from '../../../../../assets/img/legacy-trip/maldives/M.png';
import imgCountry from '../../../../../assets/img/legacy-trip/maldives/flag.png';
import Slider from 'react-rangeslider'
import { FormatNumber } from './ShareFunction';


// const imgCountry = 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Maldives.svg/450px-Flag_of_Maldives.svg.png'
const green = '#7ac943';


const TargetScore = (props) => {
    const { score = 12000000, maxscore = 20000000, gender } = props;
    const WrapperSlider = styled.div`

.rangeslider__handle-label{
   
    color:#fff;
    font-size: 24px;
    margin-left: -150px;
    margin-top:15px;
    width: 150px;
}

.rangeslider-horizontal {
    height: 40px;
    border-radius: 20px;
}
.rangeslider-horizontal .rangeslider__fill {
    border-radius: 20px;
    background-color: ${green};
}



.rangeslider-horizontal .rangeslider__handle:after{
    display:none;
}
.rangeslider-horizontal .rangeslider__handle {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
}
.rangeslider .rangeslider__handle {
    background-image: url(${(gender === 'M') ? M : F});
    border: 0px solid ${green};
    background-color:${green};
    box-shadow: none;
    z-index:2;
}


.rangeslider__handle{
    background-color:green;
    // background-image: url();
}

.rangeslider, .rangeslider .rangeslider__fill { // ลบเงา
    box-shadow: none;
}
`
    return (
        <>
            <div className="row mb-2 mt-2">
                <div className="col-md-10 col-xs-12">
                    <div className="row">
                        <div style={{ display: 'inline', width: '95%' }}>
                            <WrapperSlider>
                                <Slider
                                    value={Number(score)}
                                    min={0}
                                    max={maxscore}
                                    tooltip={false}
                                    step={1}
                                    handleLabel={FormatNumber(score)}
                                    onChange={(value) => { }}
                                />
                            </WrapperSlider>
                        </div>
                        <img
                            src={imgCountry}
                            alt="Avatar"
                            style={{ width: 80, height: 80, borderRadius: '50%', marginLeft: -75, zIndex: 1 }}
                        />



                    </div>
                    <b className="" style={{ fontSize: 20, color: green }}>คะแนนที่ทำได้ปัจจุบัน</b>
                </div>
                <div className="col-md-2 col-xs-12 text-center d-flex align-items-center">
                    <b>
                        <span style={{ color: 'red', fontSize: 16 }}>คะแนนเป้าหมาย</span>
                        <br />
                        <span style={{ color: 'red', fontSize: 24 }}>{FormatNumber(maxscore)}</span>
                    </b>

                </div>
            </div>
        </>
    )
}

export default TargetScore;