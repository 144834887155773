/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Col, message, notification, Row, Table, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx-js-style";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
const { Text } = Typography;

const user = getUser();

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState("");

  let limit = 20;

  const [filterLoading, filterData, filterError, FilterReload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-filter`,
      token: true,
      params: {},
    },
    []
  );

  useEffect(() => {
    setFilterDataSource(filterData ? filterData : []);
  }, [filterData]);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-tax`,
      token: true,
      params: {
        limit: limit,
        offset: limit * (page - 1),
        userId: user.id,
        memberTypeFilter,
        partnerStatusFilter,
        teamFilter,
        query: state.filter.query,
        // ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page]
  );



  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานการจดภาษีมูลค่าเพิ่ม"],
        [
          `ประเภทสมาชิก : ${
            memberTypeFilter === ""
              ? "ทั้งหมด"
              : memberTypeFilter == 1
              ? "นิติบุคคล"
              : memberTypeFilter == 0
              ? "บุคคลธรรมดา"
              : memberTypeFilter
          }`,
          "",
          "",
          `สถานะสมาชิก : ${
            partnerStatusFilter === "" ? "ทั้งหมด" : partnerStatusFilter
          }`,
          "",
          `Team : ${teamFilter === "" ? "ทั้งหมด" : teamFilter}`,
          `วันที่สมัคร : ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล : ${moment().format("DD/MM/YYYY , H:mm:ss")}`,
        ],
        [""],
        [
          "ลำดับที่",
          "ประเภทสมาชิก",
          "Location Base",
          "Team",
          "ข้อมูลผู้สมัครหลัก",
          "",
          "",
          "",
          "",
          "",
          "",
          "ข้อมูลผู้สมัครร่วม",
          "",
          "",
          "",
          "วันที่จดภาษีมูลค่าเพิ่ม",
          "ชื่อบริษัท",
          "เลขที่ผู้เสียภาษี",
          "ที่อยู่จัดส่งเอกสารใบกำกับภาษี",
          "สถานะ",
        ],
        [
          "",
          "",
          "",
          "",
          "รหัสนักธุรกิจ",
          "ชื่อ - นามสกุล",
          "เลขบัตรประชาชน",
          "Passport",
          "สัญชาติ",
          "วันที่สมัครสมาชิก",
          "วันที่ลาออก",
          "รหัสนักธุรกิจ",
          "ชื่อ - นามสกุล",
          "เลขบัตรประชาชน",
          "สัญชาติ",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.is_company === 1
              ? "นิติบุคคล"
              : n.is_company === 0
              ? "บุคคลธรรมดา"
              : "",
            n.location_base,
            n.team_name,
            n.partner_code,
            n.full_name,
            n.id_card,
            n.passport_id,
            n.origin,
            moment(n.register_date).format("DD/MM/YYYY"),
            n.resign_date == null
              ? "-"
              : moment(n.resign_date).format("DD/MM/YYYY"),
            n.join_partner_code,
            n.join_full_name,
            n.join_id_card,
            n.join_origin,
            " - ", // วันที่จดภาษีมูลค่าเพื่ม ไม่มี table ใน db
            n.company_name,
            n.company_tax,
            n.company_address,
            n.status,
            // {v: n.amount === "none" ? "" : numeral(n.amount).format("0,0.00"), s: { alignment: { horizontal: "right" } } },
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 7 } },
        { s: { r: 1, c: 8 }, e: { r: 1, c: 10 } },
        // manage : header table
        { s: { r: 3, c: 4 }, e: { r: 3, c: 10 } },
        { s: { r: 3, c: 11 }, e: { r: 3, c: 14 } },

        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
        { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
        { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },
        { s: { r: 3, c: 15 }, e: { r: 4, c: 15 } },
        { s: { r: 3, c: 16 }, e: { r: 4, c: 16 } },
        { s: { r: 3, c: 17 }, e: { r: 4, c: 17 } },
        { s: { r: 3, c: 18 }, e: { r: 4, c: 18 } },
        { s: { r: 3, c: 19 }, e: { r: 4, c: 19 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 20 },
        { wch: 10 },
      ];

      var wsrows = [
        { hpx: 16 }, // "pixels"
        { hpx: 16 }, // "pixels"
        { hpx: 16 }, // "pixels"
        { hpx: 24 }, // "pixels"
        { hpx: 24 }, // "pixels"
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        // fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      // const ws = XLSX.utils.json_to_sheet(excelData); //------old version

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;

      ws["P4"].s = centerStyles;
      ws["Q4"].s = centerStyles;
      ws["R4"].s = centerStyles;
      ws["S4"].s = centerStyles;
      ws["T4"].s = centerStyles;

      ws["E4"].s = centerStyles;
      ws["L4"].s = centerStyles;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานการจดภาษีมูลค่าเพิ่ม");
      XLSX.writeFile(wb, "รายงานการจดภาษีมูลค่าเพิ่ม.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const memberTypeOptions = [
    { value: "1", text: "นิติบุคคล" },
    { value: "0", text: "บุคคลธรรมดา" },
  ];

  const partnerStatusOptions = [
    ..._.map(filterDataSource.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานการจดภาษีมูลค่าเพิ่ม</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center  align-items-sm-center pl-2"
          >
            <FilterList
              placeholder="รหัสสมาชิก, ชื่อสมาชิก"
              Topic={"วันที่สมัคร"}
              showReportSpan={8}
              topicSpan={2}
              inputSpan={8}
              reload={reload}
              exportToExcel={exportToExcel}
            />
          </Row>

          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>สถานะสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={partnerStatusFilter}
                options={partnerStatusOptions}
                onChange={(e) => {
                  setPartnerStatusFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>Team</Text>
            </Col>
            <Col span={4}>
              <SelectTeam
                withAll
                defaultValue={teamFilter}
                onChange={(e) => {
                  setTeamFilter(e);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit + page * 1,
              total: data?.total,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="ประเภท"
              dataIndex="is_company"
              align="center"
              render={(text) => {
                if (text === 1) {
                  return "นิติบุคคล";
                } else if (text === 0) {
                  return "บุคคลธรรมดา";
                } else {
                  return text;
                }
              }}
            />
            <Table.Column
              title="Location Base"
              dataIndex="location_base"
              align="center"
            />
            <Table.Column title="Team" dataIndex="team_name" align="center" />
            <Table.ColumnGroup title="ข้อมูลผู้สมัครหลัก">
              <Table.Column
                title="รหัสนักธุรกิจ"
                dataIndex="partner_code"
                align="center"
              />
              <Table.Column
                title="ชื่อ-สกุล"
                dataIndex="full_name"
                align="left"
              />
              <Table.Column
                title="เลขบัตรประชาชน"
                dataIndex="id_card"
                align="center"
              />
              <Table.Column
                title="Passport"
                dataIndex="passport_id"
                align="center"
              />
              <Table.Column title="สัญชาติ" dataIndex="origin" align="center" />
              <Table.Column
                title="วันที่สมัครสมาชิก"
                dataIndex="register_date"
                align="center"
                render={(text) => moment(text).format("DD/MM/YYYY")}
              />
              <Table.Column
                title="วันที่ลาออก"
                dataIndex="resign_date"
                align="center"
                render={(text) => {
                  if (text === null) {
                    return "-";
                  }
                  return moment(text).format("DD/MM/YYYY");
                }}
              />
            </Table.ColumnGroup>
            <Table.ColumnGroup title="ข้อมูลผู้สมัครร่วม">
              <Table.Column
                title="รหัสสมาชิก"
                dataIndex="join_partner_code"
                align="center"
              />
              <Table.Column
                title="ชื่อ-สกุล"
                dataIndex="join_full_name"
                align="left"
              />
              <Table.Column
                title="เลขบัตรประชาชน"
                dataIndex="join_id"
                align="center"
              />
              <Table.Column
                title="สัญชาติ"
                dataIndex="join_origin"
                align="center"
              />
            </Table.ColumnGroup>

            <Table.Column
              title="วันที่จดภาษีมูลค่าเพื่ม"
              dataIndex=""
              align="center"
              render={(text) => {
                return "No Data";
              }}
            />
            <Table.Column
              title="ชื่อบริษัท"
              dataIndex="company_name"
              align="center"
            />
            <Table.Column
              title="เลขที่ผู้เสียภาษี"
              dataIndex="company_tax"
              align="center"
            />
            <Table.Column
              title="ที่อยู่จัดส่งเอกสารใบกำกับภาษี"
              dataIndex="company_address"
              align="center"
            />
            <Table.Column title="สถานะ" dataIndex="status" align="center" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
