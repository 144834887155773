//component เขียนไว้สำหรับหน้า สร้างใบโอนย้ายสินค้า 
//มีการแกชื่อสาขา จัดส่ง เป็น คลังบางนา

import React from "react";
import { Select } from "antd";
import _ from "lodash";

const SelectOption = ({ options = [], withAll = false, ...props }) => {
  return (
    <Select dropdownMatchSelectWidth={false} {...props}>
      {withAll ? <Select.Option value="">ทั้งหมด</Select.Option> : null}
      {options.map((n, i) => (
        <Select.Option
          key={i}
          value={n.value}
          {..._.omit(n, ["text", "value"])}
        >
          {n.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectOption;
