/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Empty, List, Row, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import ProductCategoryContext from "../../../../contexts/product-category.context";
import { useApi } from "../../../../hooks/http2";
import { URL_API } from "../../../../utils";

export default function SubCategoryList() {
  const { state, dispatch } = useContext(ProductCategoryContext);

  const sub_categories = useApi(
    {
      baseURL: URL_API,
      url: `/setting/master/sub-group`,
      params: {
        group_id: state.category?.id || 0,
      },
      token: true,
    },
    [state.category?.id]
  );

  useEffect(() => {
    if (state.reload.sub_category !== "") sub_categories.reload();
  }, [state.reload.sub_category]);

  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col md={24}>
          <Typography.Title level={4} className="mb-0">
            กลุ่มสินค้าย่อย
          </Typography.Title>
        </Col>
      </Row>
      {state.category ? (
        <>
          <Row gutter={16} className="mb-2 d-flex align-items-end">
            <Col md={12}>
              <Typography.Text className="mb-0 font-size-14">
                <strong className="mr-2">กลุ่มสินค้าหลัก:</strong>
                {state.category?.name_local}
              </Typography.Text>
            </Col>
            <Col md={12} className="text-right">
              <Button
                type="primary"
                size="small"
                onClick={() =>
                  dispatch({ type: "OPEN_MODAL", payload: "sub_category" })
                }
              >
                เพิ่ม
              </Button>
            </Col>
          </Row>
          <List
            bordered
            size="small"
            loading={sub_categories.loading}
            dataSource={sub_categories.fetch?.data || []}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                extra={
                  // <Button
                  //   type="link"
                  //   size="small"
                  //   onClick={() => handleClickDel(item.id)}
                  // >
                  //   ลบ
                  // </Button>
                  <Button
                    type="link"
                    size="small"
                    onClick={() =>
                      dispatch({ type: "EDIT_SUB_CATEGORY", payload: item })
                    }
                  >
                    แก้ไข
                  </Button>
                }
              >
                {item.name_local}
              </List.Item>
            )}
          />
        </>
      ) : (
        <Empty description="กรุณาเลือกกลุ่มสินค้าหลัก" />
      )}
    </>
  );
}
