/* eslint-disable no-unused-vars */
import { Button, Table } from "antd";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { FaCog, FaUserAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useApi } from "../../../../hooks/http2";
import {
  actionMember,
  activeSelector,
  searchSelector,
} from "../../../../redux/slices/member";
import { URL_API } from "../../../../utils";

export default function MemberTable() {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const active = useSelector(activeSelector);
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMember;
  const member = useApi(
    {
      baseURL: URL_API,
      url: `/member`,
      token: true,
      params: {
        ...search,
        member_type: active,
        offset: (search.page - 1) * search.limit,
      },
    },
    [search, active]
  );
  return (
    <Table
      size="small"
      bordered
      loading={member.loading}
      dataSource={member.fetch?.data || []}
      pagination={{
        current: parseInt(search.page),
        pageSize: search.limit,
        total: member.fetch?.total || 0,
        showLessItems: true,
        size: "default",
        pageSizeOptions: ["10", "25", "50", "100"],
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${numeral(range[0]).format()} - ${numeral(
            range[1]
          ).format()} of ${numeral(total).format()}`,
        onChange: (currPage, pageSize) =>
          dispatch(
            SET_SEARCH({
              page: currPage,
              limit: pageSize,
            })
          ),
        onShowSizeChange: (current, size) =>
          dispatch(
            SET_SEARCH({
              page: 1,
              limit: size,
            })
          ),
      }}
    >
      <Table.Column
        title="รหัสสมาชิก"
        dataIndex="member_code"
        align="center"
        className="font-size-12"
      />
      <Table.Column
        title="ชื่อสมาชิก"
        dataIndex="member_name"
        className="font-size-12"
        render={(text, { member_name_en }) => (
          <>
            ภาษาไทย: <strong>{text}</strong>
            <br />
            ภาษาอังกฤษ: <strong>{member_name_en}</strong>
          </>
        )}
      />
      <Table.Column
        title="ผู้แนะนำ"
        dataIndex="sponsor_code"
        className="font-size-12"
        render={(text, { sponsor_name }) => (
          <>
            รหัส: <strong>{text === "" ? "-" : text}</strong>
            <br />
            ชื่อ: <strong>{sponsor_name}</strong>
          </>
        )}
      />
      <Table.Column
        title="โทรศัพท์"
        dataIndex="mobile"
        align="center"
        className="font-size-12"
      />
      <Table.Column
        title="อีเมล"
        dataIndex="email"
        align="center"
        className="font-size-12"
      />
      <Table.Column
        title="เวลาที่สมัคร"
        align="center"
        className="font-size-12"
        render={({ register_date }) =>
          register_date && moment(register_date).format("DD/MM/YYYY")
        }
      />
      <Table.Column
        title="สถานะ"
        dataIndex="status_text"
        align="center"
        className="font-size-12"
      />
      <Table.Column
        title={<FaCog />}
        align="center"
        className="font-size-12"
        render={({ id}) => (
          <>
            <Button
              size="small"
              shape="circle"
              type="primary"
              className="bg-pv border-pv mr-1"
              onClick={() => history.push(`${match.path}/${id}/${active}`)}
            >
              <FaUserAlt />
            </Button>
          </>
        )}
      />
    </Table>
  );
}
