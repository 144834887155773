import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { _ } from "../../utils";
import { getThunkError } from "../../views/util/helper";
import { mapDataToItemKey } from "../../views/util/helper/map-data";
import { initFetch } from "../logic/initial-value";
import { initRequisitionError } from "../logic/requisition";
import {
  getRequisitionSelect,
  getUserDepartmentCode,
  getUserPermissionManagerOtherAmd,
  getUserPermission,
} from "../thunk/requisition";

// state
const initialState = {
  permissionManagerOtherAmd: null,
  loadingSubmit: false,
  searchDocument: {
    query: null,
    start_date: null,
    end_date: null,
    requisition_type: "",
    warehouse_code: "",
    department_id: "",
    limit: 10,
    page: 1,
  },
  documentDetail: {
    document_no: null,
    document_date: null,
    requisition_type: null,
    requisition_type_name: null,
    requisition_class: 'Trading', // default
    requisition_date: null,
    branch_code: null,
    branch_name: null,
    warehouse_code: null,
    requisition_by_id: null,
    requisition_by_code: null,
    requisition_by_name: null,
    requisition_by_department: null,
    requisition_by_department_id: null,
    requisition_by_department_code: null,
    requisition_by_branch_code: null,
    requisition_by_branch_name: null,
    reason: null,
    remark: null,
    status: null,
  },
  product: {
    itemKeys: [],
    itemByKey: {},
  },
  addProductModal: {
    visible: false,
    limit: 10,
    page: 1,
    query: null,
  },
  file: {
    itemDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  historyConsider: {
    itemKeys: [],
    itemByKey: {},
  },
  consider: {
    remark: null,
  },
  user: {
    user_id: null,
    allow_all_branch: null,
    permission_status: {},
    permission_manager_other: {},
    permission_amd: {},
  },
  error: { ...initRequisitionError },
  fetch: {
    requisition_select: { ...initFetch },
    department_code: { ...initFetch },
    permission_manager_other_amd: { ...initFetch },
    permission: { ...initFetch },
  },
};

const requisitionSlice = createSlice({
  name: "requisition",
  initialState: _.cloneDeep(initialState),
  reducers: {
    setState: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    setGlobalState: (state, { payload: { name, value } }) => {
      state[name] = {
        ...state[name],
        ...value,
      };
    },
    clearGlobalState: (state, { payload }) => {
      state[payload] = _.cloneDeep(initialState[payload]);
    },
    clear: (state) => {
      state.permissionManagerOtherAmd = initialState.permissionManagerOtherAmd;
      state.loadingSubmit = initialState.loadingSubmit;
      state.searchDocument = { ...initialState.searchDocument };
      state.documentDetail = { ...initialState.documentDetail };
      state.product = { ...initialState.product };
      state.addProductModal = { ...initialState.addProductModal };
      state.file = { ...initialState.file };
      state.historyConsider = _.cloneDeep(initialState.historyConsider);
      state.consider = { ...initialState.consider };
      state.user = _.cloneDeep(initialState.user);
      state.error = _.cloneDeep(initialState.error);
      state.fetch = _.cloneDeep(initialState.fetch);
    },
    clearCreate: (state) => {
      state.permissionManagerOtherAmd = initialState.permissionManagerOtherAmd;
      state.loadingSubmit = initialState.loadingSubmit;
      state.documentDetail = { ...initialState.documentDetail };
      state.product = { ...initialState.product };
      state.addProductModal = { ...initialState.addProductModal };
      state.file = { ...initialState.file };
      state.historyConsider = _.cloneDeep(initialState.historyConsider);
      state.consider = { ...initialState.consider };
      state.user = _.cloneDeep(initialState.user);
      state.error = _.cloneDeep(initialState.error);
      state.fetch = _.cloneDeep(initialState.fetch);
    },
    setFormValue: (state, { payload: { name, value, error_name } }) => {
      state[name] = {
        ...state[name],
        ...value,
      };
      if (_.isArray(error_name)) {
        for (let i = 0; i < error_name.length; i++) {
          const property = error_name[i];
          if (state.error[property] === null) continue;
          state.error[property] = null;
        }
      } else if (state.error[error_name]) {
        state.error[error_name] = null;
      }
    },
    addProduct: (state, { payload }) => {
      const key = _.uniqueId("product-");
      state.product.itemKeys = [...state.product.itemKeys, key];
      state.product.itemByKey = {
        ...state.product.itemByKey,
        [key]: {
          ...payload,
          qty: 1,
          source_code: null,
          source_name: null,
          event_project_code: null,
          event_project_name: null,
          remark: null,
        },
      };
      state.error.product = null;
    },
    setProduct: (state, { payload: { key, value } }) => {
      state.product.itemByKey[key] = {
        ...state.product.itemByKey[key],
        ...value,
      };
    },
    delProduct: (state, { payload }) => {
      state.product.itemKeys = _.filter(
        state.product.itemKeys,
        (n) => n !== payload
      );
      state.product.itemByKey = _.omit(state.product.itemByKey, [payload]);
    },
    addFile: (state, { payload }) => {
      const key = _.uniqueId("document-img-");
      state.file.itemKeys = [...state.file.itemKeys, key];
      state.file.itemByKey = {
        ...state.file.itemByKey,
        [key]: {
          ...payload,
        },
      };
    },
    delFile: (state, { payload }) => {
      const { id, temp } = state.file.itemByKey[payload];
      if (!temp) state.file.itemDel = [...state.file.itemDel, id];
      state.file.itemKeys = _.filter(
        state.file.itemKeys,
        (key) => key !== payload
      );
      state.file.itemByKey = _.omit(state.file.itemByKey, [payload]);
    },
  },
  extraReducers: {
    [getRequisitionSelect.pending]: (state) => {
      state.fetch.requisition_select = {
        ...initFetch,
        loading: true,
      };
    },
    [getRequisitionSelect.fulfilled]: (state, { payload }) => {
      const { document, products, history, file } = payload;
      const productKey = mapDataToItemKey(products, "product-");
      const historyKey = mapDataToItemKey(history, "history-consider-");

      state.documentDetail = {
        ...initialState.documentDetail,
        ...document,
      };
      state.file = {
        ..._.cloneDeep(initialState.file),
        ...mapDataToItemKey(file, "document-img-"),
      };
      state.product.itemKeys = productKey.itemKeys;
      state.product.itemByKey = productKey.itemByKey;
      state.historyConsider.itemKeys = historyKey.itemKeys;
      state.historyConsider.itemByKey = historyKey.itemByKey;
      state.fetch.requisition_select = { ...initFetch };
    },
    [getRequisitionSelect.rejected]: (state, { payload }) => {
      state.fetch.requisition_select = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
    [getUserDepartmentCode.pending]: (state) => {
      state.fetch.department_code = {
        ...initFetch,
        loading: true,
      };
    },
    [getUserDepartmentCode.fulfilled]: (state, { payload }) => {
      state.fetch.department_code = { ...initFetch };
      if (payload === null) {
        return notification.warning({
          message: "Department of user isn't found",
        });
      }
      const { code } = payload;
      state.documentDetail.requisition_by_department_code = code;
    },
    [getUserDepartmentCode.rejected]: (state, { payload }) => {
      state.fetch.department_code = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
    [getUserPermissionManagerOtherAmd.pending]: (state) => {
      state.fetch.permission_manager_other_amd = {
        ...initFetch,
        loading: true,
      };
    },
    [getUserPermissionManagerOtherAmd.fulfilled]: (state, { payload }) => {
      state.permissionManagerOtherAmd = payload;
      state.fetch.permission_manager_other_amd = { ...initFetch };
    },
    [getUserPermissionManagerOtherAmd.rejected]: (state, { payload }) => {
      state.fetch.permission_manager_other_amd = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
    [getUserPermission.pending]: (state) => {
      state.fetch.permission = {
        ...initFetch,
        loading: true,
      };
    },
    [getUserPermission.fulfilled]: (state, { payload }) => {
      const { user, allow_all_branch } = payload;
      state.user = { ...state.user, ...user, allow_all_branch };
      state.fetch.permission = { ...initFetch };
    },
    [getUserPermission.rejected]: (state, { payload }) => {
      state.fetch.permission = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
  },
});

export const actionRequisition = requisitionSlice.actions;

const RequisitionReducer = requisitionSlice.reducer;
export default RequisitionReducer;
