import { Button, Col, Divider, Modal, notification, Row } from "antd";
import React, { useContext } from "react";
import { axios, getToken, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import ArrangeContext from "../../reducer";

const ConfirmButton = ({ reload }) => {
  const {
    state: {
      bill: { document_no, document_type },
    },
    dispatch,
  } = useContext(ArrangeContext);

  const sendSubmit = () => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/arrange/${document_type}/confirm`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              document_no,
            },
          })
            .then(() => {
              resolve();
              reload();
              dispatch({ type: "clearCreate" });
              notification.success({ message: "จ่ายสินค้าสำเร็จ" });
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };
  return (
    <>
      <Divider />
      <Row>
        <Col className="text-center">
          <Button type="primary" onClick={sendSubmit}>
            ยืนยัน
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmButton;
