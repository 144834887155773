/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, notification, Row, Spin, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { axios, getToken, getUserObj, URL_API, _ } from "../../../../../utils";
import { resCatch, resCatchModal } from "../../../../util/helper";
import BillContext from "../../reducer";
import CancelContent from "./CancelContent";
import FooterInfo from "./FooterInfo";
import HeaderDetail from "./HeaderDetail";
import ItemTable from "./ItemTable";
import SendSubmit from "./SendSubmit";

const { Text } = Typography;

const renderError = (content) =>
  Modal.error({
    title: "ไม่สามารถดำเนินการได้",
    content,
    zIndex: 1080,
    okText: "ตกลง",
  });

const Index = ({ reload, processParams }) => {
  const billCancelRef = useRef(null);
  const {
    state: {
      billDetail: { id, is_preview, status, cancel_document_no },
      user: { permission_status },
    },
    dispatch,
  } = useContext(BillContext);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState(null);

  const handleClose = () => dispatch({ type: "clearCreate" });

  const loadBillSelect = async () => {
    setLoading(true);
    let res = null;
    try {
      const { id: user_id } = getUserObj();
      const req1 = axios({
        method: "get",
        url: `${URL_API}/bill/${id}`,
        params: {
          process_params: processParams,
          cancel_document_no: cancel_document_no ?? "",
        },
        headers: { Authorization: "Bearer " + getToken() },
        cancelToken: source.token,
      });
      const req2 = axios({
        url: `${URL_API}/setting/permission/user/${user_id}`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      res = await Promise.all([req1, req2]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleClose();
      return resCatch(e);
    }

    if (!res[0].data.data) {
      handleClose();
      return renderError("ไม่พบข้อมูล");
    }
    if (!is_preview && res[0].data.data.status !== status) {
      handleClose();
      return renderError("บิลที่เลือกถูกดำเนินการไปแล้ว");
    }
    if (!is_preview && status !== null) {
      if (!permission_status[status]) {
        handleClose();
        return renderError("ผู้ใช้งานไม่มีสิทธิดำเนินการ");
      }
    }

    dispatch({ type: "setBillSelect", payload: res[0].data.data });
  };

  useEffect(() => {
    if (id) {
      setSource(axios.CancelToken.source());
    } else if (source) {
      source.cancel("unmounted");
      setSource(null);
    }
  }, [id]);

  useEffect(() => {
    !!source && loadBillSelect();
  }, [source]);

  return (
    <Modal
      visible={!!id}
      footer={false}
      zIndex={1080}
      width={1000}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleClose}
    >
      <Spin spinning={loading}>
        <HeaderDetail />
        <ItemTable />
        {(status !== null || !is_preview) && (
          <CancelContent
            formRef={billCancelRef}
            disable={
              is_preview ||
              _.includes(["wait-branch", "wait-operation"], status)
            }
          />
        )}
        {status !== null && (status !== "wait-branch" || !is_preview) && (
          <FooterInfo />
        )}
        {!is_preview && (
          <Row gutter={[16, 16]}>
            <Col span={24} className="text-center">
              {status === null ? (
                <Button
                  className="bg-nn border-nn text-white"
                  onClick={() =>
                    billCancelRef.current.validateFieldsAndScroll(
                      (err, values) => {
                        if (err) return;
                        Modal.confirm({
                          title: "ยืนยันการทำรายการ",
                          content: (
                            <div>
                              <Text>คุณต้องการ</Text>
                              <Text underline className="mx-1 text-nn">
                                ส่งคำขอยกเลิก
                              </Text>
                              <Text>หรือไม่</Text>
                            </div>
                          ),
                          okText: "ยืนยัน",
                          cancelText: "ยกเลิก",
                          zIndex: 1080,
                          onOk() {
                            dispatch({
                              type: "setGlobalState",
                              payload: {
                                name: "info",
                                value: { loading_submit: true },
                              },
                            });
                            let data = {
                              ...values,
                              status: "wait-branch",
                              document_status: status,
                              document_id: id,
                              is_accept: values.is_accept ? 1 : 0,
                            };
                            axios({
                              method: "post",
                              url: `${URL_API}/bill`,
                              headers: {
                                Authorization: "Bearer " + getToken(),
                              },
                              data,
                            })
                              .then(() => {
                                reload();
                                handleClose();
                                notification.success({
                                  message: "บันทึกรายการแล้ว",
                                });
                              })
                              .catch((e) => resCatchModal(e))
                              .finally(() =>
                                dispatch({
                                  type: "setGlobalState",
                                  payload: {
                                    name: "info",
                                    value: { loading_submit: false },
                                  },
                                })
                              );
                          },
                        });
                      }
                    )
                  }
                >
                  ส่งคำขอยกเลิก
                </Button>
              ) : (
                <SendSubmit reload={reload} />
              )}
            </Col>
          </Row>
        )}
      </Spin>
    </Modal>
  );
};

export default Index;
