import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NewsForm from "../news/news-form";
import legalList from "./legal-list";
import LegalUpload from "./legal-upload";

const Index = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={legalList} exact />
      <Route path={`${match.path}/legal-list`} component={legalList} exact />
      <Route
        path={`${match.path}/legal-upload`}
        component={LegalUpload}
        exact
      />
      <Route
        path={`${match.path}/legal-list/news-create`}
        component={NewsForm}
        exact
      />
      <Route
        path={`${match.path}/legal-list/news-create/:newsType`}
        component={NewsForm}
        exact
      />
      <Route
        path={`${match.path}/legal-list/news-create/:newsType/:newsId`}
        component={NewsForm}
      />
    </Switch>
  );
};

export default Index;
