import React from 'react'
import ModalControl from './ModalControl'
import { Icon } from 'antd'

const Notification = ({ visible = true, ...props }) => {
    return (
        <ModalControl
            visible={visible}
            title="Noticification"
            bodyStyle={{ maxHeight: '300px', overflow: 'auto' }}
            footerLine={false}
            destroyOnClose={true}
            {...props}
        >
            <NoticificationItem />
            <NoticificationItem />
            <NoticificationItem />
            <NoticificationItem />

        </ModalControl>
    )
}

const NoticificationItem = ({ ...props }) => {
    return (
        <div className="border-bottom rounded-0 text-right mt-3">
            <div className="d-flex align-items-start text-left">
                <div className="bg-reds rounded-circle text-white d-flex align-items-center justify-content-center" style={{ minWidth: '50px', maxWidth: '50px', height: '50px' }}>
                    <Icon type="exclamation-circle" theme="filled" style={{ fontSize: '30px' }} />
                </div>
                <p className="m-0 w-100 px-4">
                    สมาชิก 110541864 ประดิษฐ์ กงสะเด็น ใกล้หมดอายุ <br />
                    หมดอายุวันที่ 30 ส.ค. 62
                </p>
                <div className="text-pv" >
                    <Icon type="form" style={{ fontSize: '20px' }} />
                </div>
            </div>
            <small className="text-gray-60">13/12/2019</small>
        </div>
    )
}

export default Notification;
