import { Icon, Upload, message } from "antd";
import React, { forwardRef, useState } from "react";
import { URL_API, axios, getToken } from "../../../../../utils";
import { beforeUploadImg } from "../../../../util/helper";

const UploadPreview = ({ file, multiple = false, ...props }) => {
  const [loading, setLoading] = useState(false);

  const customRequest = async (options) => {
    setLoading(true);
    const { onSuccess, onError } = options;
    try {
      const formData = new FormData();
      formData.append("file", options.file);
      formData.append("container_name", "web-content");
      const res = await axios.post(`/file/upload`, formData, {
        baseURL: URL_API,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      });

      onSuccess(res.data?.data);
      setLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      onError(error);
      setLoading(false);
    }
  };

  return (
    <Upload.Dragger
      {...props}
      showUploadList={false}
      accept=".png,.jpg"
      beforeUpload={beforeUploadImg}
      customRequest={customRequest}
      multiple={multiple}
    >
      {file && (file.azure_url || file.response) ? (
        <img
          src={file.azure_url || file.response.azure_url}
          alt="upload"
          style={{
            width: "auto",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "200px",
          }}
        />
      ) : (
        <>
          <Icon type={loading ? "loading" : "plus"} />
          <div className="ant-upload-text">Upload</div>
        </>
      )}
    </Upload.Dragger>
  );
};

export default forwardRef((props, ref) => {
  return <UploadPreview forwardRef={ref} {...props} />;
});
