import { Divider, Row, Col, Typography, Input } from "antd";
import React, { useContext } from "react";
import BillContext from "../../reducer";

const { Text } = Typography;

const FooterInfo = () => {
  const {
    state: {
      info: { consider_remark, send_remark },
      billDetail: { is_preview, is_consider, status },
    },
    dispatch,
  } = useContext(BillContext);

  return (
    <>
      <Divider />
      {is_preview ? (
        <Row gutter={[8, 8]}>
          <Col span={4}>
            <Text strong>ข้อมูลเพิ่มเติม</Text>
          </Col>
          <Col span={20} className="d-flex flex-column">
            <Text>{consider_remark}</Text>
            <Text>{send_remark}</Text>
          </Col>
        </Row>
      ) : (
        <>
          {status === "wait-operation" && (
            <Row gutter={[8, 8]} className="mb-3">
              <Col span={4}>
                <Text strong>ข้อมูลเพิ่มเติม</Text>
              </Col>
              <Col span={20} className="d-flex flex-column">
                <Text>{send_remark}</Text>
              </Col>
            </Row>
          )}
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Input.TextArea
                maxLength={500}
                placeholder="ระบุข้อมูลเพิ่มเติม"
                autoSize={{ minRows: 3, maxRows: 4 }}
                value={is_consider ? consider_remark : send_remark}
                onChange={(e) =>
                  dispatch({
                    type: "setGlobalState",
                    payload: {
                      name: "info",
                      value: {
                        [is_consider ? "consider_remark" : "send_remark"]:
                          e.target.value,
                      },
                    },
                  })
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default FooterInfo;
