/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, notification, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { axios, getToken, URL_API, _ } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import { mapFileList } from "../../../util/helper/file";

const { Text } = Typography;

const { clearCreate, setGlobalState } = actionTransfer;

const renderWarning = (description) =>
  notification.warning({
    message: "กรุณาตรวจสอบรายการ",
    description,
  });

const SubmitTransfer = ({ backUrl, setLoadingSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector((state) => state.transfer.product);
  const document = useSelector((state) => state.transfer.document);
  const mediaTransfer = useSelector((state) => state.transfer.mediaTransfer);
  const is_doc_wait_add = document.status === "wait-add";

  const handleSave = (status) => {
    let data_text, text_style;
    switch (status) {
      case "draft":
        data_text = "บันทึกฉบับร่าง";
        text_style = "text-rp";
        break;
      case "pending":
        data_text = is_doc_wait_add ? "ดำเนินการโอนเพิ่ม" : "ยืนยันโอน";
        text_style = "text-pv";
        break;
      default:
        return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        setLoadingSubmit(true);
        const { itemKeys, itemByKey } = product;
        const data = {
          ...document,
          ...mapFileList(mediaTransfer),
          document_status: document.status,
          status,
          items: _.map(itemKeys, (key) => itemByKey[key]),
        };
        axios({
          method: "post",
          url: `${URL_API}/transfer-receive/transfer`,
          headers: { Authorization: "Bearer " + getToken() },
          data,
        })
          .then(() => {
            dispatch(clearCreate());
            notification.success({
              message: "Success",
              description: `บันทึกข้อมูล${data_text}โอนย้ายสินค้าเรียบร้อย`,
            });
            history.push({ pathname: backUrl });
          })
          .catch((e) => resCatchModal(e))
          .finally(() => setLoadingSubmit(false));
      },
    });
  };

  const handleClickSave = (status) => {
    if (!document.to_branch_code)
      return renderWarning("กรุณาเลือกสาขาที่โอนไป");
    if (!document.to_wh_code) return renderWarning("กรุณาเลือกคลังที่โอนไป");
    if (status === "pending") {
      const { reason, bill_ref_document_no, other_reason } = document;
      if (!reason) return renderWarning("กรุณาเลือกเหตุผลการโอน");
      if (
        reason === "00" &&
        (bill_ref_document_no === null ||
          bill_ref_document_no === undefined ||
          bill_ref_document_no.trim() === "")
      )
        return renderWarning("กรุณากรอกเลขที่บิล");
      if (
        reason === "03" &&
        (other_reason === null ||
          other_reason === undefined ||
          other_reason.trim() === "")
      )
        return renderWarning("กรุณาระบุเหตุผลการโอน");
      if (product.itemKeys.length === 0)
        return renderWarning("ต้องมีรายการสินค้าอย่างน้อย 1 รายการ");
    }
    if (_.some(product.itemKeys, (key) => !product.itemByKey[key].qty))
      return renderWarning("กรุณาระบุจำนวนโอนให้ครบถ้วน");
    handleSave(status);
  };

  if (is_doc_wait_add)
    return (
      <>
        <Button
          className="bg-nn border-nn text-white width-150 ml-3"
          onClick={() =>
            dispatch(
              setGlobalState({
                name: "cancelModal",
                value: {
                  visible: true,
                  document_no: document.document_no,
                  document_status: document.status,
                  receive_type: document.receive_type,
                },
              })
            )
          }
        >
          ยกเลิกคำขอ
        </Button>
        <Button
          className="bg-rp border-rp text-white width-150 mx-3"
          onClick={() => handleClickSave("draft")}
        >
          บันทึกร่าง
        </Button>
        <Button
          className="bg-pv border-pv text-white width-150"
          onClick={() => handleClickSave("pending")}
        >
          ดำเนินการโอนเพิ่ม
        </Button>
      </>
    );

  return (
    <>
      <Button
        className="bg-rp border-rp text-white width-150 mx-3"
        onClick={() => handleClickSave("draft")}
      >
        บันทึกร่าง
      </Button>
      <Button
        className="bg-pv border-pv text-white width-150"
        onClick={() => handleClickSave("pending")}
      >
        ยืนยันโอน
      </Button>
    </>
  );
};

export default memo(SubmitTransfer);
