import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useRouteMatch } from "react-router-dom";

const checkEAN13 = (barcode) => {
  let even =
    (parseInt(barcode[1]) +
      parseInt(barcode[3]) +
      parseInt(barcode[5]) +
      parseInt(barcode[7]) +
      parseInt(barcode[9]) +
      parseInt(barcode[11])) *
    3;

  let odd =
    parseInt(barcode[0]) +
    parseInt(barcode[2]) +
    parseInt(barcode[4]) +
    parseInt(barcode[6]) +
    parseInt(barcode[8]) +
    parseInt(barcode[10]);

  let sum = even + odd;

  let mod = sum % 10;

  return (mod === 0 ? mod : 10 - mod)?.toString();
};

export default function PrintBarcode() {
  const match = useRouteMatch();
  const [value, setValue] = useState("");
  const [isEAN13, setIsEAN13] = useState(false);

  useEffect(() => {
    const barcode = match.params.barcode;

    const calculate_ean13 = (barcode) => {
      let result = false;
      if (barcode && barcode.length === 13) {
        let last_digit = checkEAN13(barcode.substr(0, 12));

        result = barcode.substr(12) === last_digit;
      }

      return result;
    };

    const ean13 = calculate_ean13(barcode);

    setValue(barcode);
    setIsEAN13(ean13);
  }, [match.params.barcode]);

  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value !== "" && (
        <Barcode
          value={value}
          format={isEAN13 ? "EAN13" : "CODE39"}
          renderer="img"
          height={50}
          width={isEAN13 ? 2 : 1}
        />
      )}
    </div>
  );
}
