const mwEcosysConstants = {
  banner_page: {
    desktop_img: {
      amount_img: 1,
      width: 2000,
      height: 650,
    },
    mobile_img: {
      amount_img: 1,
      width: 375,
      height: 400,
    },
  },

  eco_card_page: {
    desktop_img: {
      amount_img: 1,
      width: 269,
      height: 341,
    },
    mobile_img: {
      amount_img: 1,
      width: 144,
      height: 182,
    },
  },

  hf_aw_page: {
    desktop_img: {
      amount_img: 1,
      width: 2000,
      height: 650,
    },
    mobile_img: {
      amount_img: 1,
      width: 375,
      height: 375,
    },
  },

  hf_nutrition_page:{
    desktop_img: {
        amount_img: 1,
        width: 458,
        height: 330,
      },
      mobile_img: {
        amount_img: 1,
        width: 334,
        height: 241,
      },
  },

  hf_didyouknow_page: {
    desktop_img: {
      amount_img: 1,
      width: 369,
      height: 230,
    },
    mobile_img: {
      amount_img: 1,
      width: 299,
      height: 173,
    },
  },

  hf_detail_page: {
    desktop_img: {
      amount_img: 1,
      width: 675,
      height: 440,
    },
    mobile_img: {
      amount_img: 1,
      width: 334,
      height: 334,
    },
  },

  rp_howtouse_page: {
    desktop_img: {
      amount_img: 1,
      width: 299,
      height: 280,
    },
    mobile_img: {
      amount_img: 1,
      width: 334,
      height: 280,
    },
  },


};

export default mwEcosysConstants;
