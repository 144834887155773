import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const subTagFormDefaultValues = {
  id: '',
  formType: 'create',
  title: '',
  thumbnailImageUrlM: undefined,
  thumbnailImageUrlF: undefined,
  description: '',
};

export const subTagFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrlM: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      thumbnailImageUrlF: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่ชื่อหมวดหมู่'),
      description: yup.string().required('กรุณาใส่ชื่อหมวดหมู่'),
    })
  );
};

export const videoGender = ['ALL', 'M', 'F'];

export const videoFormDefaultValues = {
  id: '',
  formType: 'create',
  title: '',
  thumbnailImageUrl: undefined,
  videoUrl: '',
  description: '',
  gender: 'ALL',
};

export const videoFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่ชื่อวิดีโอ'),
      description: yup.string().required('กรุณาใส่คำบรรยาย'),
      videoUrl: yup.string().required('กรุณาอัพโหลดวิดีโอ'),
      gender: yup.string().required('กรุณาเลือกเพศ'),
    })
  );
};
