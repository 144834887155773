import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios, getToken, URL_API } from "../../utils";

export const getRequisitionSelect = createAsyncThunk(
  "get/requisition/document_no",
  async (document_no, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/requisition/${document_no}`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserDepartmentCode = createAsyncThunk(
  "get/global/user/department",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/global/user/department/${id}`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserPermissionManagerOtherAmd = createAsyncThunk(
  "get/global/user/permission/status",
  async ({ status, ...params }, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/global/user/permission/${status}`,
        headers: { Authorization: "Bearer " + getToken() },
        params,
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserPermission = createAsyncThunk(
  "get/requisition/user-permission",
  async (_e, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/requisition/user-permission`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
