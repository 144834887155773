import React from "react";
import { Route, Switch } from "react-router-dom";

import Error404 from "../../Error404";

import BillDetail from "./BillDetail";
import BillList from "./BillList";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} children={<BillList />} />
      <Route exact path={`${match.path}/:id`} children={<BillDetail />} />
      <Route component={Error404} />
    </Switch>
  );
};
