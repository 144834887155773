import React from "react";
import { Alert, Select } from "antd";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";

const SelectCallingCode = (props) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/calling-code`,
    },
    []
  );

  if (error) return <Alert type="error" message={error} />;

  return (
    <Select
      loading={loading}
      //dropdownMatchSelectWidth={false}
      {...props}
    >
      {/* {data && data.data.map((n, i) => (<Select.Option key={i} value={n.code}>{n.code} {n.name}</Select.Option>))} */}
      {data && data.data && data.data.map((n, i) => <Select.Option key={i} value={n.code} >{n.code} {n.name}</Select.Option>)}
    </Select>
  );
};
export default SelectCallingCode;
