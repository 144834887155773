import { Button } from "antd";
import React, { useState } from "react";
import { FaPrint } from "react-icons/fa";
import { printform } from "../utils/printforms";

const PrintButton = ({ record, reportName = "receipt", classsName = "" }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      shape="circle"
      size="small"
      className={`bg-rp border-rp text-white ${classsName}`}
      loading={loading}
      onClick={() => {
        console.log(reportName);
        setLoading(true);
        printform(reportName, record);
        // .finally(() => setLoading(false));
        setLoading(false);
      }}
    >
      {!loading && <FaPrint />}
    </Button>
  );
};

export default PrintButton;
