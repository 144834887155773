import React from "react";
import { Switch, Route } from "react-router-dom";
import Error404 from "../../Error404";

import News from "./news/News";
import NewsBanner from "./news-banner/NewsBanner";
import NewsCategory from "./news-category/NewsCategory";
import NewsCategoryList from "./news-category/NewsCategoryList"
import NewsFlashNews from "./news-flash-news/NewsFlashNews";
import NewsPinList from "./news-pin-list/NewsPinList";
import NewsTag from "./news-tag/NewsTag";
import NewsTagList from "./news-tag/NewsTagList";
import NewsList from "./news/NewsList";


export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/news`} exact component={NewsList} />
      <Route path={`${match.path}/news/:id`} exact component={News} />
      <Route path={`${match.path}/banner`} exact component={NewsBanner} />
      <Route path={`${match.path}/category`} exact component={NewsCategoryList} />
      <Route path={`${match.path}/category/:id`} exact component={NewsCategory} />
      <Route path={`${match.path}/flash-news`} exact component={NewsFlashNews} />
      <Route path={`${match.path}/pin-list`} exact component={NewsPinList} />
      <Route path={`${match.path}/tag`} exact component={NewsTagList} />
      <Route path={`${match.path}/tag/:id`} exact component={NewsTag} />

      <Route component={Error404} />
    </Switch>
  );
};
