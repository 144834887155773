import { Skeleton } from "antd";
import numeral from "numeral";
import React, { useContext, useEffect, useState } from "react";
import img from "../../../../../../../../assets/img/pos/user-default.png";
import { URL_API } from "../../../../../../../../utils";
import InfoContext from "../reducer";
import IconFile from "./IconFile";

const Index = () => {
  const {
    state: {
      loading,
      point,
      data: { ref_id, ref_type },
    },
  } = useContext(InfoContext);
  const [imgSrc, setImgSrc] = useState(img);
  useEffect(() => {
    ref_id &&
      ref_type &&
      setImgSrc(
        `${URL_API}/pos/master/image/profile?ref_id=${ref_id}&ref_type=${ref_type}`
      );
  }, [ref_id, ref_type]);


  return (
    <div className="py-3 d-flex flex-column align-items-center">
      <div className="width-200 height-200 overflow-hidden rounded-circle mb-3 shadow">
        <img
          src={imgSrc}
          className="w-100 h-100 object-fit-cover"
          alt="Profile"
          onError={() => setImgSrc(img)}
        />
      </div>

      {ref_type === "Distributor" &&
        (loading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="px-3 py-2 my-2 bg-pv-light rounded rounded-lg w-100">
              <p className="text-center text-white rounded-pill bg-pv">
                รักษายอดส่วนตัว
              </p>

              <table className="w-100 table-member-point">
                <tbody>
                  <tr>
                    <td className="pl-2 py-1">
                      <span className="circle-point bg-pv text-white">PV </span>
                    </td>
                    <td className="text-right pr-2">
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small></small>
                        <strong>
                          {numeral(point.current_pv).format("0,0.00")}
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-2 py-1" style={{ borderBottom: 0 }}>
                      <small>รักษายอดส่วนตัวสะสม</small>
                    </td>
                    <td className="text-right pr-2" style={{ borderBottom: 0 }}>
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small></small>
                        <strong>
                          {numeral(point.accumulate_pv).format("0,0.00")}
                        </strong>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="px-3 pt-2 pb-3 mb-2 bg-pv-light rounded rounded-lg w-100">
              <p className="text-center text-white rounded-pill bg-pv">
                คะแนนสะสมส่วนตัว
              </p>

              <table className="w-100 table-member-point">
                <tbody>
                  <tr>
                    <td className="pl-2 py-1">
                      <span className="circle-point bg-pv text-white">PV</span>
                    </td>
                    <td className="text-right pr-2">
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small>Hold</small>
                        <strong>
                          {numeral(point.pv_hold).format("0,0.00")}
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-2 py-1">
                      <span className="circle-point bg-rp text-white">RP</span>
                    </td>
                    <td className="text-right pr-2">
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small>พร้อมใช้</small>
                        <strong>
                          {numeral(point.reward_balance).format("0,0.00")}
                        </strong>
                      </p>
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small>พร้อมโอน</small>
                        <strong>
                          {numeral(point.rp_hold).format("0,0.00")}
                        </strong>
                      </p>
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small>จองแลก</small>
                        <strong>
                          {numeral(point.current_rp_reserve).format("0,0.00")}
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-2 py-1">
                      <span
                        className="circle-point text-white"
                        style={{ background: "#7856FF" }}
                      >
                        TP
                      </span>
                    </td>
                    <td className="text-right pr-2">
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small>คงเหลือ</small>
                        <strong>{numeral(point.tp).format("0,0.00")}</strong>
                      </p>
                    </td>
                  </tr>
                  {/* <tr>
                <td className="pl-2 py-1">
                  <span className="circle-point bg-tp text-white">TP</span>
                </td>
                <td className="text-right pr-2">
                  <strong>{numeral(point.current_tp).format("0,0")}</strong>
                </td>
              </tr> */}
                  {/* <tr>
                <td className="pl-2 py-1">
                  <span className="circle-point bg-cv text-white">CV</span>
                </td>
                <td className="text-right pr-2">
                  <strong>{numeral(point.current_cv).format("0,0")}</strong>
                </td>
              </tr> */}
                  {/* <tr>
                <td className="pl-2 py-1">
                  <span className="circle-point bg-ra text-white">RA</span>
                </td>
                <td className="text-right pr-2">
                  <strong>{numeral(point.current_ra).format("0,0")}</strong>
                </td>
              </tr> */}
                </tbody>
              </table>
            </div>
          </>
        ))}
      {ref_type === "Member" &&
        (loading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="px-3 pt-2 pb-3 mb-2 bg-pv-light rounded rounded-lg w-100">
              <p className="text-center text-white rounded-pill bg-pv">
                คะแนนสะสมส่วนตัว
              </p>
              <table className="w-100 table-member-point">
                <tbody>
                  <tr>
                    <td className="pl-2 py-1">
                      <span className="circle-point bg-rp text-white">RP</span>
                    </td>
                    <td className="text-right pr-2">
                      <p className="d-flex justify-content-between align-items-center m-0">
                        <small>พร้อมใช้</small>
                        <strong>
                          {numeral(point.reward_balance).format("0,0")}
                        </strong>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ))}
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="px-3 py-2 bg-gray-20 rounded rounded-lg w-100">
          <p className="text-center text-white rounded-pill bg-gray-60">
            เอกสาร
          </p>
          <IconFile />
        </div>
      )}
    </div>
  );
};

export default Index;
