import { Col, Row } from 'antd';
import React from 'react';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import AuthscreenForm from '../components/form/AuthscreenForm';
import authscreenConstants from '../constants/authscreenConstants';

const AuthScreenPage = () => {
  const { execute: getAuthscreenById, data: authscreenData } = useAsync(
    fitStudioService.getAuthscreenById,
    {
      immediate: true,
      args: '1',
    }
  );

  return (
    <Panel>
      <PanelHeader>Wallpaper หน้า Login</PanelHeader>
      <PanelBody>
        <Row>
          <Col span={12}>
            {authscreenData && (
              <img
                src={authscreenData.imageUrl}
                alt={authscreenData.imageUrl}
                style={{
                  width: authscreenConstants.width,
                  height: authscreenConstants.height,
                  objectFit: 'cover',
                }}
              />
            )}
          </Col>
          <Col span={12}>
            <AuthscreenForm getAuthscreenById={getAuthscreenById} />
          </Col>
        </Row>
      </PanelBody>
    </Panel>
  );
};

export default AuthScreenPage;
