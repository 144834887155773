import { Col, Input, Row } from "antd";
import React from "react";
import { axios, getToken, URL_API } from "../../../../../utils";

const SponsorForm = ({ Member, setMember, Members }) => {
  const handleChangeSponsor = (e) => {
    let value = e || "";
    if (value !== "") {
      axios({
        baseURL: URL_API,
        url: "/pos/ethics/sponsor",
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          partner_code: value,
        },
      })
        .then((res) => {
          if (res.data?.data) {
            let data = res.data?.data;
            setMember({
              ...Members,
              sponsor: {
                ...Member,
                sponsor_code: data?.partner_code || "",
                first_name: data?.first_name_th || "",
                sur_name: data?.sur_name_th || "",
                mobile: data?.mobile || "",
              },
            });
          }
        })
        .catch((err) => {
          setMember({
            ...Members,
            sponsor: {
              ...Member,
              sponsor_code: "",
              first_name: "",
              sur_name: "",
              mobile: "",
            },
          });
        });
    } else {
      setMember({
        ...Members,
        sponsor: {
          ...Member,
          sponsor_code: "",
          first_name: "",
          sur_name: "",
          mobile: "",
        },
      });
    }
  };

  return (
    <>
      <Row className="mb-2">
        <Col md={6} className="p-3">
          <label>รหัสนักธุรกิจ</label>
          <Input.Search
            size="small"
            className="f-s-12"
            // disabled={
            //   Member?.sponsor_code !== null && Member?.sponsor_code !== ""
            // }
            defaultValue={Member?.sponsor_code}
            onSearch={handleChangeSponsor}
          />
        </Col>
        <Col md={6} className="p-3">
          <label>ชื่อ</label>
          <Input
            size="small"
            className="f-s-12"
            disabled
            value={Member?.first_name}
          />
        </Col>
        <Col md={6} className="p-3">
          <label>นามสกุล</label>
          <Input
            size="small"
            className="f-s-12"
            disabled
            value={Member?.sur_name}
          />
        </Col>
        <Col md={6} className="p-3">
          <label>เบอร์โทรศัพท์</label>
          <Input
            size="small"
            className="f-s-12"
            disabled
            value={Member?.mobile}
          />
        </Col>
      </Row>
    </>
  );
};
export default SponsorForm;
