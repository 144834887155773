import { createReducer } from "@reduxjs/toolkit";
import React from "react";

export const initState = {
  isTemplate: false,
  loading: false,
  data: {
    id: 0,
    description: null,
    code: "",
    name: "",
    status: 1,
    // start_date: null,
    // end_date: null,
    // type: null,
    // value: null,
    // infinity: false,
    // collect_point: false,
    // coordinate: false,
  },
  media: {
    delete: null,
    imageurl: null,
    imagefile: null,
  },
};

export const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_TEMPLATE: (state) => {
    state.isTemplate = true;
  },
  SET_DATA: (state, { payload }) => {
    state.data = {
      ...state.data,
      ...payload,
    };
  },
  SET_LOAD_SUCCESS: (
    state,
    { payload: { id, media_id, description, payload } }
  ) => {
    state.loading = false;
    state.data.id = id;
  },
  ADD_MEDIA: (state, { payload }) => {
    state.media.imagefile = payload.file;
    state.media.imageurl = payload.img_url;
  },

  SET_MEDIA: (state, { payload }) => {
    state.media.imageurl = payload.img_url;
  },
  DEL_MEDIA: (state, { payload: { media_id } }) => {
    state.media.imageurl = null;
    state.media.imagefile = null;
    if (media_id) {
      state.media.delete = media_id;
    }
  },
});

export const BankContext = React.createContext();
