import React from "react";
import { Switch, Route } from "react-router-dom";
import Error404 from "../../Error404";

import Branch from "./branch/Branch";
import HeadOffice from "./head-office/HeadOffice";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/branch`} exact component={Branch} />
      <Route path={`${match.path}/head-office`} exact component={HeadOffice} />

      <Route component={Error404} />
    </Switch>
  );
};
