/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Typography, Upload } from "antd";
import React, { useCallback, useContext, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { RiFileUploadLine } from "react-icons/ri";
import styles from "../../../../../../../scss/module/claim.module.scss";
import { _ } from "../../../../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../../../util/helper";
import ImagePreview from "../../../../../../../components/ImagePreview";
import { VoucherContext } from "../../reducers";
import UploadFileList from "../../../../../../../components/UploadFileList";

const initialPreview = {
  id: null,
  src: null,
  visible: false,
};

const ImageUpload = () => {
  const {
    state: { media },
    dispatch,
  } = useContext(VoucherContext);

  const handleChangeUpload = useCallback(
    (payload) => dispatch({ type: "ADD_MEDIA", payload }),
    []
  );
  const handleDeleteUpload = useCallback(
    (payload) =>
      dispatch({
        type: "DEL_MEDIA",
        payload,
      }),
    []
  );

  return (
    <UploadFileList
      withCamera
      limit={1}
      files={media}
      onChange={handleChangeUpload}
      onDelete={handleDeleteUpload}
    />
  );
};

export default ImageUpload;
