/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "antd";
import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import AddModal from "./AddModal";
import UploadContext, { initState, reducer } from "./reducer";

const Index = (
  {
    size = "default",
    title = "อัปโหลด",
    acceptImage = true,
    acceptPDF = false,
    acceptVideo = false,
    camera = false,
    disabled = false,
    containerName = "operations",
    handleSubmit = () => null,
  },
  ref
) => {
  const [state, dispatch] = useReducer(reducer, initState);

  useImperativeHandle(
    ref,
    () => ({
      clear: () => dispatch({ type: "clear" }),
    }),
    []
  );

  useEffect(() => {
    dispatch({
      type: "setStateMulti",
      name: "fileOption",
      payload: { acceptImage, acceptPDF, acceptVideo },
    });
  }, [acceptImage, acceptPDF, acceptVideo]);

  const handleClickAdd = () =>
    dispatch({ type: "setState", name: "showAdd", payload: true });

  return (
    <UploadContext.Provider value={{ state, dispatch }}>
      <Button
        disabled={disabled}
        type="primary"
        size={size}
        onClick={handleClickAdd}
      >
        {title}
      </Button>
      <AddModal
        containerName={containerName}
        camera={camera}
        handleSubmit={handleSubmit}
      />
    </UploadContext.Provider>
  );
};

export default memo(forwardRef(Index));
