import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";

export const TravelPointContext = createContext();
export const initState = {
  redeem: {
    partner_code: "",
    partner_name : "",
    tp: "",
    remark: "",
    trip_package: "",
  },
  travel_point: {
    total_tp: "",
    redeem_tp: "",
    balance_tp: "",
  },
  filter: {
    partner_code: "",
  },
  page: 1,
};
export const reducer = createReducer(initState, {
  setRedeem: (state, { payload }) => {
    state.redeem = { ...state.redeem, ...payload };
  },
  setPoint: (state, { payload }) => {
    state.travel_point = { ...state.travel_point, ...payload };
  },
  setFilter: (state, { payload }) => {
    state.filter = { ...state.filter, ...payload };
  },
  setPage: (state, {payload}) => {
    state.page = payload;
  },
});
export default TravelPointContext;
