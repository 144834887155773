/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { AlertConfirm } from '../../../util/Alert';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import {
  EventCalendarStatus,
  eventCalendarConstants,
  eventCalendarFormDefaultValues,
  eventCalendarFormSchema,
} from './components/dto/event-calendar.dto';
import EventCalendarModal from './components/modal/EventCalendarModal';
import eventCalendarServices from '../../../../services/event-calendar.services';

const EventCalendar = () => {
  const [eventCalendarFormOpen, setEventCalendarFormOpen] = useState(false);
  const {
    data: eventCalendarLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  eventCalendarServices.useQueryGetAllEventCalendars(
    {
      take: eventCalendarConstants.tableSize,
      page,
    },
    (data) => {
      setTotal(data.meta.total);
      setData(data.data);
    }
  );

  const { mutate: deleteEventCalendar } =
    eventCalendarServices.useMutationDeleteEventCalendar(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-all-event-calendars'],
        refetchType: 'all',
      });
    });
  const { mutate: updateEventCalendarStatus } =
    eventCalendarServices.useMutationUpdateEventCalendarStatus(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-all-event-calendars'],
        refetchType: 'all',
      });
    });

  const eventCalendarForm = useForm({
    defaultValues: eventCalendarFormDefaultValues,
    resolver: eventCalendarFormSchema(),
  });

  const _HandleEventCalendarFormOpen = (formType) => {
    eventCalendarForm.setValue('formType', formType);
    setEventCalendarFormOpen(true);
  };

  const _HandleEventCalendarFormClose = () => {
    setEventCalendarFormOpen(false);
  };

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrl}
          src={record.thumbnailImageUrl}
          alt={record.thumbnailImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'ชื่องาน',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'คำบรรยาย',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <p style={{ whiteSpace: 'pre-wrap' }}>{record.description}</p>
      ),
    },
    {
      title: 'เวลาเริ่ม',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      render: (_, record) => (
        <p>{moment(record.startDate).format('DD/MMM/YYYY HH:mm')}</p>
      ),
    },
    {
      title: 'เวลาสิ้นสุด',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      render: (_, record) => (
        <p>{moment(record.endDate).format('DD/MMM/YYYY HH:mm')}</p>
      ),
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <span>{record.status === EventCalendarStatus[0] ? 'เปิด' : 'ปิด'}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.status === EventCalendarStatus[0] ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการมองเห็น');
                if (confirm) {
                  updateEventCalendarStatus({
                    eventCalendarId: record.id,
                    status: EventCalendarStatus[1],
                  });
                }
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการมองเห็น');
                if (confirm) {
                  updateEventCalendarStatus({
                    eventCalendarId: record.id,
                    status: EventCalendarStatus[0],
                  });
                }
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              eventCalendarForm.setValue('id', record.id);
              _HandleEventCalendarFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบงานอีเวนท์');
              if (confirm) {
                deleteEventCalendar({
                  eventCalendarId: record.id,
                });
              }
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <Panel>
      <PanelHeader>ปฏิทินงานอีเวนท์</PanelHeader>
      <PanelBody>
        <div className="d-flex justify-content-end mb-2">
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleEventCalendarFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มปฏิทินงานอีเวนท์
          </Button>
        </div>
        <Table
          scroll={{ x: true }}
          onRow={(record, rowIndex) => {
            return {
              //   onClick: (event) => {
              //     history.push(`classcategory/${record.id}`);
              //   }, // click row
            };
          }}
          columns={columns}
          dataSource={eventCalendarLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize: eventCalendarConstants.tableSize,
            total: total,
          }}
        />
      </PanelBody>
      <FormProvider {...eventCalendarForm}>
        <EventCalendarModal
          open={eventCalendarFormOpen}
          handleClose={_HandleEventCalendarFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default EventCalendar;
