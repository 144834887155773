import { Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Layout from "./Layout";

const { Text } = Typography;

const RequisitionBy = () => {
  const requisition_by_name = useSelector(
    (state) => state.requisition.documentDetail.requisition_by_name
  );
  const requisition_by_code = useSelector(
    (state) => state.requisition.documentDetail.requisition_by_code
  );
  const requisition_by_department = useSelector(
    (state) => state.requisition.documentDetail.requisition_by_department
  );
  const division_name = useSelector(
    (state) => state.requisition.documentDetail.division_name
  );
  return (
    <Row gutter={[8, 8]}>
      <Layout title="ผู้เบิกสินค้า :">
        <Text>{requisition_by_name}</Text>
      </Layout>
      <Layout title="รหัสพนักงาน :">
        <Text>{requisition_by_code}</Text>
      </Layout>
      <Layout title="แผนก :">
        <Text>{requisition_by_department}</Text>
      </Layout>
      <Layout title="ฝ่าย :">
        <Text>{division_name}</Text>
      </Layout>
    </Row>
  );
};

export default RequisitionBy;
