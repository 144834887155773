import { notification, Modal } from "antd";
import { range } from "lodash-es";
import Resizer from "react-image-file-resizer";
import { moment, _ } from "../../../utils";

export function base64toFile(base64, filename) {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function bytesToSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = ~~(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function resizeFile(file) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1980,
      1020,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
}

export function beforeUploadImg(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/svg+xml";
  if (!isJpgOrPng) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "รูปภาพต้องเป็นไฟล์ JPG PNG หรือ SVG เท่านั้น",
      placement: "topRight",
    });
  }
  const isLt32M = file.size / 1024 / 1024 < 32;
  if (!isLt32M) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "รูปภาพต้องมีขนาดไฟล์น้อยกว่า 32 MB",
      placement: "topRight",
    });
  }
  return isJpgOrPng && isLt32M;
}

export function beforeUploadImgPDF(file) {
  const isImg = file.type === "image/jpeg" || file.type === "image/png";
  const isPdf = file.type === "application/pdf";
  const checkType = isImg || isPdf;
  if (!checkType) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "ไฟล์ที่อัปโหลดต้องเป็นไฟล์ JPG, PNG หรือ PDF เท่านั้น",
      placement: "topRight",
    });
  }
  const isLt32M = file.size / 1024 / 1024 < 32;
  if (!isLt32M) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "ไฟล์ต้องมีขนาดไฟล์น้อยกว่า 32 MB",
      placement: "topRight",
    });
  }
  return checkType && isLt32M;
}

export function beforeUploadImgVideo(file) {
  const isMp4 = file.type === "video/mp4";
  const isJpgOrPngOrMp4 =
    file.type === "image/jpeg" || file.type === "image/png" || isMp4;
  if (!isJpgOrPngOrMp4) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "ไฟล์ที่อัปโหลดต้องเป็นไฟล์ JPG, PNG หรือ MP4 เท่านั้น",
      placement: "topRight",
    });
  }
  const limitSize = isMp4 ? 128 : 32;
  const isLessThanLimit = file.size / 1024 / 1024 < limitSize;
  if (!isLessThanLimit) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: `ไฟล์ต้องมีขนาดไฟล์น้อยกว่า ${limitSize} MB`,
      placement: "topRight",
    });
  }
  return isJpgOrPngOrMp4 && isLessThanLimit;
}

export function beforeUploadImgVideoPDF(file) {
  const isImg = file.type === "image/jpeg" || file.type === "image/png";
  const isMp4 = file.type === "video/mp4";
  const isPdf = file.type === "application/pdf";
  const checkType = isImg || isMp4 || isPdf;
  if (!checkType) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "ไฟล์ที่อัปโหลดต้องเป็นไฟล์ JPG, PNG, MP4 หรือ PDF เท่านั้น",
      placement: "topRight",
    });
  }
  const limitSize = isImg || isPdf ? 32 : 1024;
  const isLessThanLimit = file.size / 1024 / 1024 < limitSize;
  if (!isLessThanLimit) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: `ไฟล์ต้องมีขนาดไฟล์น้อยกว่า ${limitSize} MB`,
      placement: "topRight",
    });
  }
  return checkType && isLessThanLimit;
}

export function beforeUploadFile(file, option, limitFileSize) {
  let limitSize = limitFileSize || 32;
  let types = [];
  if (option.acceptImage) types = [...types, "image/jpeg", "image/png"];
  if (option.acceptPDF) types = [...types, "application/pdf"];
  if (option.acceptVideo) {
    types = [...types, "video/mp4"];
    limitSize = limitFileSize || 128;
  }
  const checkType = _.includes(types, file.type);
  if (!checkType)
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: `ไฟล์ต้องเป็น ${_.join(
        _.map(types, (n) => _.split(n, "/")[1]),
        ", "
      )} เท่านั้น`,
      placement: "topRight",
    });
  const isLessThanLimit = file.size / 1024 / 1024 < limitSize;
  if (!isLessThanLimit)
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: `ไฟล์ต้องมีขนาดไฟล์น้อยกว่า ${limitSize} MB`,
      placement: "topRight",
    });
  return checkType && isLessThanLimit;
}

export function beforeUploadExcel(file) {
  const isXlsx = _.includes(
    [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    file.type
  );
  if (!isXlsx) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "ไฟล์ที่อัปโหลดต้องเป็นไฟล์ .xls หรือ .xlsx เท่านั้น",
      placement: "topRight",
    });
  }
  const isLt32M = file.size / 1024 / 1024 < 32;
  if (!isLt32M) {
    notification.warning({
      message: "ไม่สามารถเลือกได้",
      description: "ไฟล์ต้องมีขนาดไฟล์น้อยกว่า 32 MB",
      placement: "topRight",
    });
  }
}

export function disabledStartDateSplit(current, end) {
  return (
    current &&
    end &&
    moment(current).format("YYYY-MM-DD") > moment(end).format("YYYY-MM-DD")
  );
}

export function disabledEndDateSplit(current, start) {
  return (
    current &&
    start &&
    moment(current).format("YYYY-MM-DD") < moment(start).format("YYYY-MM-DD")
  );
}

export function disabledEndTimeSplit(current, start) {
  start = moment(start).add(1, "s");
  if (+moment(start).format("DD") === +moment(current).format("DD")) {
    const start_hour = +moment(start).format("HH");
    const current_hour = +moment(current).format("HH");
    const start_minute = +moment(start).format("mm");
    const current_minute = +moment(current).format("mm");
    const start_millisecond = +moment(start).format("ss");
    return {
      disabledHours: () => range(0, start_hour),
      disabledMinutes: () =>
        start_hour === current_hour ? range(0, start_minute) : [],
      disabledSeconds: () =>
        start_minute === current_minute ? range(0, start_millisecond) : [],
    };
  } else {
    return undefined;
  }
}

export function resCatch(err) {
  if (!err) return;
  !_.includes(["unmounted", "cancel"], err.message) &&
    notification.error({
      message: "ตรวจพบสิ่งผิดพลาด",
      description: err.response?.data?.message || err.message,
    });
}

export function resCatchModal(err) {
  !_.includes(["unmounted", "cancel"], err.message) &&
    Modal.error({
      title: "ผิดพลาด",
      content: err.response?.data?.message || err.message,
      okText: "ตกลง",
      zIndex: 1082,
    });
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export function enterFocusNext(e) {
  if (e.key !== "Enter") return;
  const inputs = Array.prototype.slice.call(document.querySelectorAll("input"));
  const index = (inputs.indexOf(document.activeElement) + 1) % inputs.length;
  const input = inputs[index];
  input.focus();
  input.select();
}

export function getThunkError(payload) {
  return (
    payload.response?.data.message || payload.message || "ตรวจพบสิ่งผิดพลาด"
  );
}

export const getStringLiteral = (...target) => {
  return target.filter(Boolean).join(" ");
};
