import { Modal } from "antd";
import React, { useState } from "react";
import img from "../assets/img/pos/no-img.jpg";
import video from "../assets/img/pos/no-video.jpg";

const ImagePreviewModal = ({ azureUrl, fileType, onCancel }) => {
  const [errImg, setErrImg] = useState(null);
  return (
    <Modal
      zIndex={1081}
      footer={false}
      width={800}
      visible={!!azureUrl}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <div className="w-100 d-flex justify-content-center my-5">
        {!!errImg ? (
          <img
            src={errImg}
            className="w-100 h-100 object-fit-cover"
            alt="media-error"
          />
        ) : (
          <>
            {!!~fileType?.indexOf("video") ? (
              <video
                className="w-100 h-100 object-fit-cover"
                controls
                controlsList="nodownload"
                onError={() => setErrImg(video)}
              >
                <source src={azureUrl} type="video/mp4" />
              </video>
            ) : (
              <img
                src={azureUrl}
                className="w-100 h-100 object-fit-cover"
                alt="media"
                onError={() => setErrImg(img)}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(ImagePreviewModal);
