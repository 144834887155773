/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography,  } from 'antdV4';
import { Row, Col } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { rewardFormDefaultValues } from '../dto/reward';
import { DatePicker } from 'antd';
import moment from 'moment';
import rewardConstants from '../../constants/rewardConstants';
import ImageUpload from '../upload/ImageUpload';

const RewardForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const { execute: getRewardById } = useAsync(fitStudioService.getRewardById, {
    onSuccess: (data) => {
      reset({
        ...data,
        start_date: moment(data.start_date),
        end_date: moment(data.end_date),
        formType: 'edit',
      });
    },
  });

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getRewardById(watch('id'));
    } else {
      reset(rewardFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Controller
        name="frontImageUrl"
        control={control}
        render={({ field: { value,onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  ภาพด้านหน้า <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
              <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={rewardConstants.image.width}
                  height={rewardConstants.image.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="backImageUrl"
        control={control}
        render={({ field: { value,onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  ภาพด้านหลัง <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
              <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={rewardConstants.image.width}
                  height={rewardConstants.image.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="greyFrontImageUrl"
        control={control}
        render={({ field: { value,onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  ภาพเทาด้านหน้า <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
              <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={rewardConstants.image.width}
                  height={rewardConstants.image.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="greyBackImageUrl"
        control={control}
        render={({ field: { value,onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  ภาพเทาด้านหลัง <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
              <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={rewardConstants.image.width}
                  height={rewardConstants.image.height}
                  errors={errors}
                />
               
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อเหรียญ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำบรรยาย <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยาย"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="max"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  จำนวนสูงสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="0"
                  type="number"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="max"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="start_date"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่เริ่มต้น <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่เริ่มต้น"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="end_date"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่สิ้นสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default RewardForm;
