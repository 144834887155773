/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import DateTime from 'react-datetime';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import DateTimePicker from '../../../../../util/input-validation/DateTimePicker';
import Switcher from '../../../../../util/Switcher';

function PromotionDate(props) {
    const { handleChange, errors, promotion } = props;
    let minYesterday = DateTime.moment().subtract(1, 'day');

    const [setTime, setSetTime] = useState(1)

    // useEffect(() => {
    //     handleChange({
    //         target: {
    //             name: 'setTime',
    //             value: setTime
    //         }
    //     });
    // }, [setTime])

    const minDateChange = (value) => {
        handleChange({
            target: {
                name: 'start_date', value
            }
        })
    };
    const maxDateChange = (value) => {
        handleChange({
            target: {
                name: 'end_date', value
            }
        })
    };

    return (
        <>
            <Row className="mb-3 mt-3">
                <Col>
                    <Switcher 
                        label="กำหนดเวลา"
                        name="deadline"
                        checked={promotion.deadline === 1}
                        value={(promotion.deadline === 0) ? 1 : 0}
                        onChange={handleChange}
                        id="deadline"
                    />
                </Col>
            </Row>
            <Row hidden={promotion.deadline === 0}>
                <Col >
                    <div className="row row-space-10">
                        <div className="col-6">
                            <DateTimePicker
                                label="Start Date"
                                isValidDate={(current) => {
                                    return current.isAfter(minYesterday);
                                }}
                                inputProps={{
                                    placeholder: 'วันที่เริ่มต้น',
                                }}
                                closeOnSelect={true}
                                onChange={minDateChange}
                                value={promotion.start_date}
                                validate={errors.start_date}
                            />
                        </div>
                        <div className="col-6">
                            <DateTimePicker
                                label="End Date"
                                isValidDate={(current) => {
                                    return current.isAfter(minYesterday);
                                }}
                                inputProps={{ placeholder: 'วันที่สิ้นสุด', disabled: promotion.start_date === '' }}
                                closeOnSelect={true}
                                onChange={maxDateChange}
                                value={promotion.end_date}
                                validate={errors.end_date}
                                custom
                            />

                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(PromotionDate);