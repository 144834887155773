/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  notification,
  Popconfirm,
  Radio,
  Row,
  Switch,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
//import Th_flag from '../../../../../assets/img/flag/th.png'
import img from "../../../../../assets/img/pos/user-default.png";
import SelectAllType from "../../../../../components/SelectAllType";
import TextValidation from "../../../../../components/TextValidation";
import { useHttp } from "../../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../../utils";
import SelectCorporation from "../../components/SelectCorporation";
import SelectGanger from "../../components/SelectGender";
import SelectNationality from "../../components/SelectNationality";
import SelectPrefixEn from "../../components/SelectPrefixEn";
import SelectPrefixTh from "../../components/SelectPrefixTh";

const InfoForm = ({
  handleChange,
  Member,
  setMember,
  errors,
  HaveMarried,
  setActiveTab,
}) => {
  let toDay = moment();
  let birthDay = moment(Member?.birth_date, "YYYY");
  let Age = toDay.diff(birthDay, "years");
  const [ImageUrl, setImageUrl] = useState(img);
  const [showDate, setShowDate] = useState(false);
  const [showPassportDate, setShowPassportDate] = useState(false);
  const [open, setOpen] = useState(false);

  const [loadingProfileImage, dataProfileImage, errorProfileImage] = useHttp(
    {
      method: "GET",
      url: `${URL_API}/pos/master/member/image`,
      params: {
        ref_id: Member.id,
        ref_type: "Distributor",
        document_type: 14,
      },
      token: true,
    },
    [Member.id]
  );

  const switchSelect = (e) => {
    if (e === true) setMember({ ...Member, is_company: 1 });
    else setMember({ ...Member, is_company: 0 });
  };

  const setSwap = (e) => {
    setMember({ ...Member, main_partner_code: e.target.value });
    if (Member?.main_partner_code === Member?.partner_code) {
      setActiveTab("marriedBuyer");
    } else {
      setActiveTab("buyerInfo");
    }
  };

  const onChangePicture = async (file) => {
    if (file.file.status !== "uploading") {
      //console.log(file.file, file.fileList);
    }
    if (file.file.status === "done") {
      if (!file.file.url && !file.file.preview) {
        file.preview = await getBase64(file.file.originFileObj);
      }
      setImageUrl(file.preview);
    } else if (file.file.status === "error") {
      message.error(`${file.file.name} file upload failed.`);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.warning({
        message: "ไม่สามารถเลือกได้",
        description: "รูปภาพต้องเป็นไฟล์ JPG หรือ PNG เท่านั้น",
        placement: "topRight",
      });
    }
    const isLt3M = file.size / 1024 / 1024 < 4;
    if (!isLt3M) {
      notification.warning({
        message: "ไม่สามารถเลือกได้",
        description: "รูปภาพต้องมีขนาดไฟล์น้อยกว่า 3MB",
        placement: "topRight",
      });
    }
    return isJpgOrPng && isLt3M;
  };

  useEffect(() => {
    if (dataProfileImage && dataProfileImage.status === undefined) {
      axios
        .get(
          `${URL_API}/pos/master/member/image?ref_id=${Member.id}&ref_type=Distributor&document_type=14`
        )
        .then((res) => {
          setImageUrl(res.data);
        });
    } else {
      setImageUrl(img);
    }
  }, [dataProfileImage, Member.id]);

  const [formatD, setFormat] = useState("DD/MM/YYYY");
  useEffect(() => {
    if (open) {
      setFormat("DDMMYYYY");
    } else {
      setFormat("DD/MM/YYYY");
    }
  }, [open]);
  // useEffect(() => {
  //   if (Member.expire_date && Member.partner_code) {
  //     let diff = moment().diff(moment(Member.expire_date), "days");
  //     if (diff < 0 && diff >= -30) {
  //       Modal.warning({
  //         title: "คำเตือน",
  //         content: `สมาชิกหมายเลข ${Member?.partner_code
  //           } จะหมดอายุในอีก ${Math.abs(diff)} วัน`,
  //         okText: "ปิด",
  //       });
  //     } else if (diff > 0 && diff <= 90) {
  //       Modal.warning({
  //         title: "คำเตือน",
  //         content: `ระยะเวลาต่ออายุสาย คงเหลือ ${Math.abs(diff)} วัน`,
  //         okText: "ปิด",
  //       });
  //     }
  //   }
  // }, [Member.expire_date, Member.partner_code]);
  return (
    <>
      <Row type="flex" justify="space-between" className="mb-4">
        <Col md={8}>
          <div>
            <Typography.Text className="pr-2">รหัสนักธุรกิจ:</Typography.Text>
            <Typography.Text strong>{Member?.partner_code}</Typography.Text>
          </div>
          <div>
            <Typography.Text className="pr-2">ประเภทสมาชิก:</Typography.Text>
            <Typography.Text strong>{Member?.member_type}</Typography.Text>
          </div>
        </Col>
        <Col
          md={8}
          className="d-flex justify-content-center align-items-center"
        >
          {/* {Member?.married.partner_code_married && (
            <div>
              <Radio.Group
                value={
                  Member?.main_partner_code === Member?.partner_code
                    ? Member?.partner_code
                    : Member?.married.partner_code_married
                }
                size="small"
                onChange={(e) => setSwap(e)}
              >
                <Radio.Button value={Member?.partner_code}>หลัก</Radio.Button>
                <Radio.Button value={Member?.married.partner_code_married}>
                  รอง
                </Radio.Button>
              </Radio.Group>
            </div>
          )} */}
        </Col>
        <Col md={8} style={{ textAlign: "right" }}>
          <div>
            <Typography.Text className="pr-2">วันที่สมัคร:</Typography.Text>
            <Typography.Text strong>
              {Member?.register_date
                ? moment(Member?.register_date).format("DD/MM/YYYY")
                : "ไม่มีข้อมูล"}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="pr-2">วันที่หมดอายุ:</Typography.Text>
            <Typography.Text strong>
              {Member?.expire_date
                ? moment(Member?.expire_date).format("DD/MM/YYYY")
                : "ไม่มีข้อมูล"}
            </Typography.Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div>
            <Row className="text-center">
              <Col>
                <img
                  src={ImageUrl}
                  className="rounded-circle"
                  alt="Profile"
                  width={110}
                />
              </Col>
              <Col style={{ marginTop: "-20px" }}>
                <Upload
                  name="file"
                  beforeUpload={beforeUpload}
                  action={`${URL_API}/pos/ethics/${
                    Member.id
                  }/image/?type=${14}`}
                  headers={{
                    Authorization: "Bearer " + getToken(),
                  }}
                  onChange={onChangePicture}
                  showUploadList={false}
                >
                  <Button
                    size="small"
                    type="link"
                    className="rounded-circle bg-pv text-white"
                  >
                    <FaPen fontSize="0.8em" />
                  </Button>
                </Upload>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={18}>
          <Row className="mb-2" gutter={8}>
            <Col md={8}>
              <label>คำนำหน้า</label>
              <TextValidation validate={errors.prefix} />
              <SelectPrefixTh
                className="w-100 f-s-12"
                size="small"
                allowClear
                value={Member?.prefix}
                onChange={(e) => setMember({ ...Member, prefix: e })}
              />
            </Col>
            <Col md={8}>
              <label>ชื่อ</label>
              <TextValidation validate={errors.first_name_th} />
              <Input
                name="first_name_th"
                size="small"
                className="f-s-12"
                value={Member?.first_name_th}
                onChange={handleChange}
              />
            </Col>
            <Col md={8}>
              <label>นามสกุล</label>
              <TextValidation validate={errors.sur_name_th} />
              <Input
                name="sur_name_th"
                size="small"
                className="f-s-12"
                value={Member?.sur_name_th}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mb-2" gutter={8}>
            <Col md={8}>
              <label>Prefix Name</label>
              <TextValidation validate={errors.prefix} />
              <SelectPrefixEn
                className="w-100 f-s-12"
                size="small"
                allowClear
                value={Member?.prefix}
                onChange={(e) => setMember({ ...Member, prefix: e })}
              />
            </Col>
            <Col md={8}>
              <label>First Name</label>
              <TextValidation validate={errors.first_name_en} />
              <Input
                name="first_name_en"
                size="small"
                className="f-s-12"
                value={Member?.first_name_en}
                onChange={handleChange}
              />
            </Col>
            <Col md={8}>
              <label>Last Name</label>
              <TextValidation validate={errors.sur_name_en} />
              <Input
                name="sur_name_en"
                size="small"
                className="f-s-12"
                value={Member?.sur_name_en}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-2" gutter={8}>
        <Col md={6}>
          <label>วัน/เดือน/ปีค.ศ. เกิด</label>
          <TextValidation validate={errors.birth_date} />
          {/* {
            Member?.birth_date && showDate === false ?
              <Input size="small" value={moment(Member?.birth_date).format('DD/MM/YYYY')} onFocus={() => setShowDate(true)} />
              :
              <DatePicker
                name="birth_date"
                size="small"
                className="f-s-12"
                value={Member?.birth_date ? moment(Member?.birth_date) : null}
                onChange={(value) => {
                  setShowDate(false)
                  let e = { target: { name: "birth_date", value } };
                  handleChange(e);
                }}
                placeholder="วันเดือนปี(ค.ศ.)"
                format="DDMMYYYY"
                showToday={false}
                onBlur={() => setShowDate(false)}
              />
          } */}
          <DatePicker
            name="birth_date"
            size="small"
            className="f-s-12 w-100"
            value={Member?.birth_date ? moment(Member?.birth_date) : null}
            onChange={(value) => {
              let e = { target: { name: "birth_date", value } };
              handleChange(e);
            }}
            placeholder="วันเดือนปี(ค.ศ.)"
            format={formatD}
            onOpenChange={(isOpen) =>
              isOpen
                ? setTimeout(() => {
                    const startInput = document.querySelector(
                      ".ant-calendar-input"
                    );
                    console.log(
                      "startInputonopen  ",
                      startInput.value.includes("/")
                    );
                    setOpen(true);
                  }, 100)
                : setOpen(false)
            }
            onFocus={() => setFormat("DDMMYYYY")}
            showToday={false}
          />
          {/* <input
            name="birth_date"
            size="small"
            type="date"
            //format="DD/MM/YYYY"
            max={moment()}
            className="f-s-12 ant-calendar-picker-input ant-input ant-input-sm"
            value={moment(Member?.birth_date)}
            onChange={handleChange}
          /> */}
        </Col>
        <Col md={6}>
          <label>อายุ</label>
          <Input
            type="number"
            size="small"
            className="f-s-12"
            value={parseInt(Age) || 0}
            disabled
          />
        </Col>
        <Col md={6}>
          <label>สัญชาติ</label>
          <TextValidation validate={errors.nationality} />
          <SelectNationality
            className="w-100 f-s-12"
            size="small"
            name="nationality"
            allowClear
            value={Member?.nationality}
            showSearch
            filterOption={(input, option) =>
              option.props.children.indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              setMember({
                ...Member,
                nationality: e,
                passport_id: "",
                passport_expire_date: "",
              })
            }
          />
        </Col>
        <Col md={6}>
          {["TH", "none", "no_status"].includes(Member?.nationality) ? (
            <>
              <label>{`${
                Member?.nationality === "TH" ? "เลขบัตรประชาชน" : "เลขประจำตัว"
              }`}</label>
              <Input
                name="id_card"
                size="small"
                className="f-s-12"
                onChange={(e) => {
                  if (/^\d+$/.test(e.target.value)) {
                    handleChange(e);
                  } else {
                    notification.warning({
                      message: "ตรวจสอบข้อมูลอีกครั้ง",
                      description: "เลขบัตรประชาชนห้ามใส่อักขระพิเศษ",
                    });
                  }
                }}
                value={Member?.id_card}
              />
              <TextValidation validate={errors.id_card} />
            </>
          ) : (
            <>
              <label>เลขพาสปอร์ต</label>
              <Input
                name="passport_id"
                size="small"
                className="f-s-12"
                onChange={handleChange}
                value={Member?.passport_id}
              />
              <TextValidation validate={errors.passport_id} />
            </>
          )}
        </Col>
      </Row>
      <Row className="mb-2" gutter={8}>
        {Member?.nationality === "TH" && (
          <>
            <Col md={6}>
              <label>เลขพาสปอร์ต</label>
              <Input
                name="passport_id"
                size="small"
                className="f-s-12"
                onChange={handleChange}
                value={Member?.passport_id}
              />
              <TextValidation validate={errors.passport_id} />
            </Col>
            <Col md={6}>
              <label>วันหมดอายุ</label>
              {/* {
                Member?.passport_expire_date && showPassportDate === false ?
                  <Input size="small" value={moment(Member?.passport_expire_date).format('DD/MM/YYYY')} onFocus={() => setShowPassportDate(true)} />
                  :
                  <DatePicker
                    name="passport_expire_date"
                    size="small"
                    className="f-s-12"
                    value={Member?.passport_expire_date ? moment(Member?.passport_expire_date) : null}
                    onChange={(value) => {
                      let e = { target: { name: "passport_expire_date", value } };
                      handleChange(e);
                    }}
                    placeholder="วันเดือนปี(ค.ศ.)"
                    format="DDMMYYYY"
                    showToday={false}
                    onBlur={() => setShowPassportDate(false)}
                  />

              } */}
              <DatePicker
                name="birth_date"
                size="small"
                className="f-s-12 w-100"
                value={
                  Member?.passport_expire_date
                    ? moment(Member?.passport_expire_date)
                    : null
                }
                onChange={(value) => {
                  let e = { target: { name: "passport_expire_date", value } };
                  handleChange(e);
                }}
                placeholder="วันเดือนปี(ค.ศ.)"
                format={formatD}
                onOpenChange={(isOpen) =>
                  isOpen
                    ? setTimeout(() => {
                        const startInput = document.querySelector(
                          ".ant-calendar-input"
                        );
                        console.log(
                          "startInputonopen  ",
                          startInput.value.includes("/")
                        );
                        setOpen(true);
                      }, 100)
                    : setOpen(false)
                }
                onFocus={() => setFormat("DDMMYYYY")}
                showToday={false}
              />
            </Col>
          </>
        )}
        <Col md={6}>
          <label>เพศ</label>
          <TextValidation validate={errors.gender} />
          <SelectGanger
            className="w-100 f-s-12"
            size="small"
            allowClear
            value={Member?.gender}
            onChange={(e) => setMember({ ...Member, gender: e })}
          />
        </Col>
        {/* <Col md={6}>
          <label>โทรศัพท์</label>
          <TextValidation validate={errors.phone} />
          <Input
            //type="number"
            name="phone"
            size="small"
            className="f-s-12"
            value={Member?.phone}
            onChange={handleChange}
          />
        </Col> */}
        {/* <Col md={6}>
          <label>เลือกรหัสประเทศ</label>
          <SelectCallingCode
            name="calling-code"
            className="w-100 f-s-12"
            size="small"
            value={Member?.phone_code}
            onChange={(e) => setMember({ ...Member, phone_code: e })}
          />
        </Col>
        <Col md={6}>
          <label>มือถือ</label>
          <TextValidation validate={errors.mobile} />
          <Input
            name="mobile"
            size="small"
            className="f-s-12"
            value={Member?.mobile}
            onChange={handleChange}
          />
        </Col> */}
        <Col md={6}>
          <label>
            อีเมล{" "}
            {Member?.verified_email === 1 && (
              <strong className="text-pv">Verified</strong>
            )}
          </label>
          <TextValidation validate={errors.email} />
          <Input
            type="email"
            name="email"
            size="small"
            className="f-s-12"
            value={Member?.email}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <label>LineID</label>
          <Input
            name="line_id"
            size="small"
            className="f-s-12"
            value={Member?.line_id}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <label>Facebook</label>
          <Input
            name="facebook"
            size="small"
            className="f-s-12"
            value={Member?.facebook}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col md={24}>
          <label>มือถือ</label>
          <TextValidation validate={errors.mobiles} />
          <InputMobile
            value={Member?.mobiles}
            onChange={(e) => {
              setMember((prevMember) => {
                return { ...prevMember, mobiles: e };
              });
            }}
          />
        </Col>
      </Row> */}
      <Row className="mb-2" gutter={8}>
        <Col md={12}>
          <label>
            มือถือ{" "}
            {Member?.verified_mobile === 1 && (
              <strong className="text-pv">Verified</strong>
            )}
            {/* <strong className="text-pv">Verified</strong> */}
          </label>
          <Row className="mb-2" gutter={8}>
            <Col md={12}>
              {/* <SelectCallingCode
                name="calling-code"
                className="w-100 f-s-12"
                size="small"
                value={Member?.mobile_prefix}
                onChange={(e) => setMember({ ...Member, mobile_prefix: e })}
                placeholder="เลือกรหัสประเทศ"
              /> */}

              <SelectAllType
                process_type="mobile_prefix"
                name="calling-code"
                className="w-100 f-s-12"
                size="small"
                value={Member?.mobile_prefix}
                onChange={(e) => setMember({ ...Member, mobile_prefix: e })}
                placeholder="เลือกรหัสประเทศ"
              />
            </Col>
            <Col md={12}>
              <TextValidation validate={errors.mobile} />
              <Input
                name="mobile"
                size="small"
                className="f-s-12"
                value={Member?.mobile}
                onChange={handleChange}
                placeholder="เบอร์โทร"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div>
        <Row>
          <Col>
            <Radio.Group
              value={
                Member?.company_register_vat == null
                  ? 1
                  : Member?.company_register_vat
              }
              onChange={(e) =>
                setMember({ ...Member, company_register_vat: e.target.value })
              }
              style={{ padding: "8px 8px" }}
            >
              <Radio value={1} className="f-s-12 text-dark">
                คำนวณแบบ<span style={{ textDecoration: "underline" }}>มี</span>
                ภาษีมูลค่าเพิ่ม
                <TextValidation validate={errors.company_register_vat} />
              </Radio>
              <Radio value={0} className="f-s-12 text-dark">
                คำนวณแบบ
                <span style={{ textDecoration: "underline" }}>ไม่มี</span>
                ภาษีมูลค่าเพิ่ม
                <TextValidation validate={errors.company_register_vat} />
              </Radio>
            </Radio.Group>
          </Col>
        </Row>

        {/* {(parseInt(Age) || 0) < 20 && ( */}
        <Row gutter={16} className="mt-2">
          <Col>
            <Checkbox
              defaultChecked={Member?.status?.toLowerCase() === "active"}
              onClick={(e) =>
                setMember({ ...Member, manual_active: e.target.checked })
              }
            >
              เอกสารสมบูรณ์
            </Checkbox>
          </Col>
        </Row>
        {/* )} */}

        <Row type="flex" justify="space-between" gutter={8}>
          <Col md={24}>
            <div className="mr-2 mt-3 mb-2">
              <Typography.Text strong className="f-s-14 pr-2">
                นิติบุคคล
              </Typography.Text>
              <Popconfirm
                title="คุณต้องการเปลี่ยนแปลงข้อมูลหรือไม่"
                okText="ใช่"
                cancelText="ไม่ใช่"
                onConfirm={() =>
                  switchSelect(Member?.is_company !== 0 ? false : true)
                }
              >
                <Switch
                  checked={Member?.is_company !== 0 ? true : false}
                  // onChange={(e) => switchSelect(e)}
                />
              </Popconfirm>
            </div>
          </Col>
          {Member?.is_company === 1 && (
            <>
              <Row gutter={16}>
                <Col md={8}>
                  <label>ประเภทธุรกิจ</label>
                  <TextValidation validate={errors.company_type} />
                  <SelectCorporation
                    className="w-100 f-s-12"
                    size="small"
                    allowClear
                    value={Member?.company_type}
                    onChange={(e) => setMember({ ...Member, company_type: e })}
                  />
                </Col>
                <Col md={8}>
                  <label>ชื่อนิติบุคคล (ภาษาไทย)</label>
                  <TextValidation validate={errors.company_name_th} />
                  <Input
                    name="company_name_th"
                    size="small"
                    className="f-s-12"
                    value={Member?.company_name_th}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={8}>
                  <label>ชื่อนิติบุคคล (ภาษาอังกฤษ)</label>
                  <TextValidation validate={errors.company_name_en} />
                  <Input
                    name="company_name_en"
                    size="small"
                    className="f-s-12"
                    value={Member?.company_name_en}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mt-2 mb-2">
                <Col>
                  <label>เลขประจำตัวผู้เสียภาษี</label>
                  <TextValidation validate={errors.company_tax} />
                  <Input
                    name="company_tax"
                    size="small"
                    className="f-s-12"
                    value={Member?.company_tax}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </Row>
      </div>
    </>
  );
};
export default InfoForm;
