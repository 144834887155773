import axios from "axios";
import { getToken } from "../../../../utils";

const timeout = 15000;

const legacyRequest = axios.create({
  baseURL: process.env.REACT_APP_URL_API || "http://localhost:4001",
  headers: { Authorization: "Bearer " + getToken() },
  timeout,
});

export default legacyRequest;
