/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from 'antd';
import { Button } from 'antdV4';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPen, FaPlus } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { AlertConfirm } from '../../../util/Alert';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import moment from 'moment';
import { MdDelete } from 'react-icons/md';
import {
  videoFormDefaultValues,
  videoFormSchema,
} from '../components/dto/myfocus';
import BodyFocusVideoModal from '../components/modal/BodyFocusVideoModal';
import myFocusConstants from '../constants/myFocusConstants';

const BodyFocusVideoPage = () => {
  const params = useParams();
  const history = useHistory();
  const [videoFormOpen, setVideoFormOpen] = useState(false);
  const [page, setPage] = useState(0);

  const { data: tagData } = useAsync(fitStudioService.getBodyFocusTagById, {
    immediate: true,
    args: {
      tagId: params.tagId,
    },
  });

  const { execute: getBodyFocusVideoBySubTagId, data: videoLists } = useAsync(
    fitStudioService.getBodyFocusVideoBySubTagId,
    {}
  );

  const { data: subTagData } = useAsync(
    fitStudioService.getBodyFocusSubTagById,
    {
      immediate: true,
      args: {
        subTagId: params.subTagId,
      },
    }
  );

  const videoForm = useForm({
    defaultValues: videoFormDefaultValues,
    resolver: videoFormSchema(),
  });

  const _HandleVideoFormOpen = (formType) => {
    videoForm.setValue('formType', formType);
    setVideoFormOpen(true);
  };

  const _HandleVideoFormClose = () => {
    setVideoFormOpen(false);
  };

  const { data: test, execute: deleteBodyFocusVideoById } = useAsync(
    fitStudioService.deleteBodyFocusVideoById,
    {
      onSuccess: (data) => {
        getBodyFocusVideoBySubTagId({
          subTagId: params.subTagId,
          page: page + 1,
          take: myFocusConstants.tableSize,
        });
      },
    }
  );

  useEffect(() => {
    if (!videoFormOpen && params.subTagId) {
      getBodyFocusVideoBySubTagId({
        subTagId: params.subTagId,
        page: page + 1,
        take: myFocusConstants.tableSize,
      });
    }
  }, [params, page, test]);

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrl}
          src={record.thumbnailImageUrl}
          alt={record.thumbnailImageUrl}
          style={{
            width: 80,
            height: 45,
            objectFit: 'cover',
          }}
        />
      ),
    },
    {
      title: 'ชื่อคลาส',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              videoForm.setValue('id', record.id);
              _HandleVideoFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบวิดีโอ');
              if (confirm) deleteBodyFocusVideoById({ videoId: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{tagData?.title}/</h3>
        <h3>{subTagData?.title}</h3>
      </div>
      <Panel>
        <PanelHeader>วิดีโอ</PanelHeader>
        <PanelBody>
          <div className="d-flex justify-content-end">
            <Button
              className="bg-pv border-pv text-white mb-2"
              onClick={() => {
                _HandleVideoFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่มวิดีโอ
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={videoLists?.data.length > 0 ? videoLists?.data : []}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       history.push(
            //         `/fit-studio/classcategory/${params.classCategoryId}/class/${record.id}`
            //       );
            //     },
            //   };
            // }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current - 1);
            }}
            pagination={{
              pageSize: myFocusConstants.tableSize,
              total: videoLists?.meta?.total || 0,
            }}
          />
        </PanelBody>
        <FormProvider {...videoForm}>
          <BodyFocusVideoModal
            open={videoFormOpen}
            handleClose={_HandleVideoFormClose}
          />
        </FormProvider>
      </Panel>
    </>
  );
};

export default BodyFocusVideoPage;
