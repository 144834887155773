/* eslint-disable react-hooks/exhaustive-deps */
import { Col, DatePicker, Input, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getUserObj } from "../../../../utils";
import SelectBranch from "../../pos/components/SelectBranch";
import BillContext from "../reducer";

const SearchDocument = () => {
  const user = getUserObj();
  const {
    state: {
      searchDocument: { start_date, end_date, branch_code },
      // user: { permission_status },
    },
    dispatch,
  } = useContext(BillContext);
  const [branchLoaded, setBranchLoaded] = useState([]);

  useEffect(() => {
    if (branchLoaded.length > 0) {
      dispatch({
        type: "setGlobalState",
        payload: {
          name: "searchDocument",
          value: {
            branch_code: user.branch_code,
            // branch_code: permission_status[`wait-operation`]
            //   ? ""
            //   : user.branch_code,
          },
        },
      });
    }
  }, [
    branchLoaded.length,
    // , permission_status[`wait-operation`]
  ]);

  return (
    <Row gutter={[8, 8]} type="flex" className="align-items-center mb-3">
      <Col span={2} xxl={1} className="text-right">
        <Typography.Text>วันที่</Typography.Text>
      </Col>
      <Col span={6} xxl={3}>
        <DatePicker
          className="w-100"
          format="DD/MM/YYYY"
          placeholder="วันที่เริ่มต้น"
          value={start_date}
          onChange={(start_date) =>
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "searchDocument",
                value: {
                  start_date,
                  page: 1,
                },
              },
            })
          }
        />
      </Col>
      <Col span={6} xxl={3}>
        <DatePicker
          className="w-100"
          format="DD/MM/YYYY"
          placeholder="วันที่สิ้นสุด"
          value={end_date}
          onChange={(end_date) =>
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "searchDocument",
                value: {
                  end_date,
                  page: 1,
                },
              },
            })
          }
        />
      </Col>
      <Col span={2} xxl={1} className="text-right">
        <Typography.Text>สาขา</Typography.Text>
      </Col>
      <Col span={8} xxl={4}>
        <SelectBranch
          placeholder="เลือกสาขา"
          className="w-100"
          value={branch_code ?? undefined}
          userLogin
          // userLogin={permission_status[`wait-operation`]}
          withAll={branchLoaded.length > 1}
          callBackData={(data) => {
            setBranchLoaded(data);
          }}
          onChange={(branch_code) => {
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "searchDocument",
                value: {
                  branch_code,
                  page: 1,
                },
              },
            });
          }}
        />
        {/* <SelectSource
          placeholder="เลือกแหล่งที่มา"
          allowClear
          value={sale_source ?? undefined}
          onChange={(sale_source) =>
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "searchDocument",
                value: {
                  sale_source,
                  page: 1,
                },
              },
            })
          }
        /> */}
      </Col>
      <Col span={12} offset={2} xxl={{ span: 12, offset: 0 }}>
        <Input.Search
          allowClear
          autoComplete="off"
          placeholder="รหัสนักธุรกิจ, ชื่อนักธุรกิจ, เลขที่บิล, เลขที่ชุดการชำระ, เลขที่การจอง, ชื่อพนักงาน"
          onSearch={(query) =>
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "searchDocument",
                value: {
                  query,
                  page: 1,
                },
              },
            })
          }
        />
      </Col>
    </Row>
  );
};

export default SearchDocument;
