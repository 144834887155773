/* eslint-disable no-unused-vars */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//page
import PromotionLists from "./promotion-list";
import PromotionManage from "./promotion-manage";
import PromotionGiveAway from "./promotion-giveaway";
import PromotionGiveAwayBak from "./promotion-giveaway/index copy";
import PromotionPrice from "./promotion-price";
import PromotionBill from "./promotion-bill";
import Error404 from "../../../Error404";
import PromotionList from "./promotion-list";
import Detail from "./Detail";
import Manage from "./manage";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={PromotionList} />
      <Route path={`${match.path}/price`} component={PromotionPrice} />
      <Route
        path={`${match.path}/giveaway-bak`}
        component={PromotionGiveAwayBak}
      />
      <Route exact path={`${match.path}/:type/:id?`} component={Manage} />
      {/* <Route exact path={`${match.path}/:type/:id?`} component={Detail} /> */}
      {/* <Route path={`${match.path}/giveaway/:id?`} component={PromotionGiveAway} />
      <Route path={`${match.path}/giveaway-bak`} component={PromotionGiveAwayBak} />
      <Route path={`${match.path}/price`} component={PromotionPrice} />
      <Route path={`${match.path}/bill`} component={PromotionBill} /> */}
      {/* <Route path={`${match.path}/:id`} component={PromotionManage} /> */}

      <Route component={Error404} />
    </Switch>
  );
};
