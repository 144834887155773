/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, DatePicker, Form, Input, Row, Select, Radio } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { modalSelector } from "../../../../redux/slices/blacklist";
const FormBlacklist = ({ form }) => {
  const modal = useSelector(modalSelector);
  const checkTypeIdCard = modal.data?.IDCard.length;
  const current_date = moment().startOf("day");
  let day_leave = null;
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const [DateEnd, setDateEnd] = useState(null);
  const formItemProps = {
    labelCol: {
      md: 10,
      lg: 10,
    },
    wrapperCol: {
      md: 14,
      lg: 14,
    },
  };
  useEffect(() => {
    setFieldsValue({
      fullname: modal.data ? modal.data.Fullname : "",
      idcard: modal.data ? modal.data.IDCard : "",
      end_date: modal.data ? moment(modal.data.EndDate) : null,
      type_idcard:
        checkTypeIdCard === 13
          ? "idCard"
          : checkTypeIdCard !== undefined && checkTypeIdCard !== null
          ? "passport"
          : "idCard",
      member_type: modal.data?.MemberType,
    });
    day_leave = modal.data
      ? moment(modal.data.EndDate)
          .startOf("day")
          .diff(moment(modal.data.StartDate).startOf("day"), "days")
      : null;
    setDateEnd(day_leave);
  }, [modal.visible, setFieldsValue]);
  const hiddleDateEnd = (e) => {
    const end_date = moment(e);
    day_leave = end_date.diff(current_date, "days");
    setDateEnd(day_leave);
  };
  const hiddleIdCard = (e) => {
    setFieldsValue({ idcard: "" });
  };
  const disabledEndDateSplit = (current, start) => {
    return !start || current <= new Date();
  };
  function isValidCitizenCard(cardId) {
    if (cardId.length < 13) {
      return false;
    }

    var b = 0;
    var check = 0;
    var checksum = parseInt(cardId.substring(12, 13));

    for (var i = 0; i < 12; i++) {
      b = b + parseInt(cardId.substring(i, i + 1)) * (13 - i);
    }
    var tmp = 11 - (b % 11);
    switch (tmp) {
      case 11:
        check = 1;
        break;
      case 10:
        check = 0;
        break;
      default:
        check = tmp;
    }
    return check === checksum;
  }
  return (
    <>
      <Form>
        <Form.Item label="ชื่อ-นามสกุล" labelAlign="left" {...formItemProps}>
          {getFieldDecorator("fullname", {
            rules: [
              {
                required: true,
                message: "กรุณาระบุ",
              },
            ],
          })(<Input name="fullname" placeholder="ชื่อ-นามสกุล" />)}
        </Form.Item>
        <Form.Item label="ประเภท" labelAlign="left" {...formItemProps}>
          {getFieldDecorator("member_type", {
            rules: [
              {
                required: true,
                message: "กรุณาระบุ",
              },
            ],
          })(
            <Select placeholder="ประเภท">
              <Select.Option value="staff">พนักงาน</Select.Option>
              <Select.Option value="member">สมาชิกเลกาซี</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="บัตรประชาชน / Passport"
          labelAlign="left"
          {...formItemProps}
        >
          {getFieldDecorator("type_idcard", {
            rules: [
              {
                required: true,
                message: "กรุณาระบุ",
              },
            ],
          })(
            <Radio.Group onChange={hiddleIdCard}>
              <Radio value="idCard">รหัสบัตรประชาชน</Radio>
              <Radio value="passport">Passport</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {/* {getFieldValue("type_idcard") && ( */}
        <Form.Item
          label={
            getFieldValue("type_idcard") === "idCard"
              ? "รหัสบัตรประชาชน"
              : "Passport"
          }
          labelAlign="left"
          {...formItemProps}
        >
          {getFieldDecorator("idcard", {
            rules: [
              {
                required: true,
                message: "กรุณาระบุ",
              },
              {
                // validator: (rule, value, callback) => {
                //   console.log(value);
                //   const regex = /^[0-9]*$/;
                //   if (!regex.test(value)) {
                //     callback("กรุณากรอกเฉพาะตัวเลข");
                //   } else {
                //     callback();
                //   }
                // },
                validator: (r, value,callback) => {
                  if (value === "") {
                    return Promise.resolve();
                  }
                  if (getFieldValue("type_idcard") === "idCard") {
                    const is_valid = isValidCitizenCard(value);
                    if (is_valid) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("เลขบัตรประชาชนไม่ถูกต้อง")
                    );
                  } else {
                    const regex = /^[0-9]*$/;
                    if (!regex.test(value)) {
                      callback("กรุณากรอกเฉพาะตัวเลข");
                    } else {
                      callback();
                    }
                  }
                },
              },
            ],
          })(
            <Input
              name="idcard"
              maxLength={getFieldValue("type_idcard") === "idCard" ? 13 : null}
              allowClear
              placeholder={
                getFieldValue("type_idcard") === "idCard"
                  ? "รหัสบัตรประชาชน"
                  : "Passport"
              }
            />
          )}
        </Form.Item>
        {/* )} */}
        <Form.Item
          label="วันที่สมัครใหม่ได้"
          labelAlign="left"
          {...formItemProps}
        >
          {getFieldDecorator("end_date", {
            rules: [{ required: true, message: "กรุณาระบุ" }],
          })(
            <DatePicker
              name="end_date"
              className="w-100"
              format="DD/MM/YYYY"
              placeholder="วันที่สิ้นสุด"
              onChange={hiddleDateEnd}
              disabledDate={disabledEndDateSplit}
            />
          )}
        </Form.Item>
      </Form>
      <Row>
        <Col span={7}>
          <span className="text-sm" style={{ color: "#000000D9" }}>
            ระยะเวลา Blacklist :
          </span>
        </Col>
        {DateEnd != null && !isNaN(DateEnd) && (
          <Col span={17}>{DateEnd} วัน</Col>
        )}
      </Row>
    </>
  );
};

export default Form.create()(FormBlacklist);
