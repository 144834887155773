import { Col, Row, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import img from "../../../../../assets/img/pos/user-default.png";
import styles from "../../../../../scss/module/legal.module.scss";
const title_color = {
  color: "#40a5a0",
};
const MainData = ({ data }) => {
  const [dataSource, setDataSource] = useState([]);
    const {azure_url} = data
useEffect(() => {

    if (data?.id !== null) {
      const {
        birth_date,
        prefix_th,
        first_name_th,
        sur_name_th,
        prefix_en,
        first_name_en,
        sur_name_en,
        id_card,
        passport_id,
        nationality_text,
        gender_text,
        phone,
        mobile,
        email,
        line_id,
        facebook,
      } = data;
      setDataSource([
        [
          {
            name: "คำนำหน้า",
            value: prefix_th,
          },
          {
            name: "ชื่อ",
            value: first_name_th,
          },
          {
            name: "นามสกุล",
            value: sur_name_th,
          },
          {
            name: "Prefix Name",
            value: prefix_en,
          },
          {
            name: "First Name",
            value: first_name_en,
          },
          {
            name: "Last Name",
            value: sur_name_en,
          },
        ],
        [
            {
                name: 'วันเกิด',
                value: birth_date? moment(birth_date).format('DD/MM/YYYY'): '-'
            },
          {
            name: "อายุ",
            value: birth_date? moment().diff(moment(birth_date), 'years') : '-',
          },
          {
            name: "หมายเลขบัตรประชาชน",
            value: id_card,
          },
          {
            name: "หมายเลขพาสปอร์ด",
            value: passport_id,
          },
          {
            name: "สัญชาติ",
            value: nationality_text,
          },
          {
            name: "เพศ",
            value: gender_text,
          },
          {
            name: "โทรศัพท์",
            value: phone,
          },
          {
            name: "มือถือ",
            value: mobile,
          },
        ],
        [
          {
            name: "อีเมล",
            value: email,
          },
          {
            name: "Line ID",
            value: line_id,
          },
          {
            name: "Facebook",
            value: facebook,
          },
        ],
      ]);
    }
  }, [data]);
  return (
    <>
      <Row className="pb-3 pl-3 pt-3 pr-3">
        <Col span={24}>
          <Typography.Text strong>
            ข้อมูลนักธุรกิจ (ผู้สมัครหลัก)
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Row className="pl-3 pt-3 pr-3" >
          <Col span={3}>
            <div className="width-100 height-100 overflow-hidden rounded-circle shadow">
              <img
                src={azure_url ? azure_url  : img}
                alt="profile"
                className="w-100 h-100 object-fit-cover"
              />
            </div>
          </Col>
          <Col span={21} className="pt-4">

                {dataSource[0]?.map((item, index) => (
                  <Col
                    key={index}
                    span={24}
                    lg={5}
                    xl={3}
                    className="d-flex flex-column"
                  >
                    <Typography.Text style={title_color}>
                      {item.name}
                    </Typography.Text>
                    <Typography.Text>{item.value}</Typography.Text>
                  </Col>
                ))}

          </Col>
        </Row>
      </Row>
      <div className={`${styles.row}`}>
        <Row>
          {dataSource[1]?.map((item, index) => (
            <Col
              key={index}
              span={12}
              lg={6}
              xl={3}
              className="d-flex flex-column"
            >
              <Typography.Text style={title_color}>{item.name}</Typography.Text>
              <Typography.Text>{item.value}</Typography.Text>
            </Col>
          ))}
        </Row>
      </div>
      <div className={`${styles.row}`}>
        <Row>
          {dataSource[2]?.map((item, index) => (
            <Col
              key={index}
              span={12}
              lg={6}
              xl={3}
              className="d-flex flex-column"
            >
              <Typography.Text style={title_color}>{item.name}</Typography.Text>
              <Typography.Text>{item.value}</Typography.Text>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default MainData;
