import { Button, message, Table, Tag } from "antd";
import React, { useContext } from "react";
import { FaCogs, FaPen } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router";
import UserContext from "../../../../../../contexts/user.context";
import { useApi } from "../../../../../../hooks/http2";

const TableUser = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { state, dispatch } = useContext(UserContext);
  const { loading, fetch, error } = useApi(
    {
      url: "/setting/permission/user",
      token: true,
      params: {
        limit: state.filter.limit,
        offset: (state.filter.page - 1) * state.filter.limit,
        ...state.filter,
      },
    },
    [state.filter]
  );

  if (error) {
    message.error(error);
  }

  return (
    <Table
      loading={loading}
      size="small"
      bordered
      dataSource={
        fetch?.data ? fetch?.data?.map((n, i) => ({ ...n, key: i })) : []
      }
      pagination={{
        total: fetch?.total ? fetch?.total : 0,
        size: "default",
        showTotal: (total, range) =>
          `${range[0]} - ${range[1]} จาก ${total} รายการ`,
        pageSize: state.filter.limit,
        current: state.filter.page,
        onChange: (page) =>
          dispatch({ type: "SET_FILTER_VALUE", payload: { page } }),
        showLessItems: true,
      }}
    >
      <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
      <Table.Column title="ชื่อผู้ใช้งาน" dataIndex="user_name" />
      <Table.Column
        title="ชื่อ-นามสกุล"
        dataIndex="first_name"
        render={(text, { last_name }) => `${text} ${last_name}`}
      />
      <Table.Column title="ฝ่าย" dataIndex="division_name" />
      <Table.Column title="แผนก" dataIndex="department_name" />
      <Table.Column title="ระดับ" dataIndex="rank_name" />
      <Table.Column
        title="สถานะ"
        dataIndex="status"
        align="center"
        render={(text) =>
          text?.toLowerCase() === "active" ? (
            <Tag color="green">ใช้งาน</Tag>
          ) : (
            <Tag color="red">งดใช้งาน</Tag>
          )
        }
      />
      <Table.Column
        title={<FaCogs />}
        align="center"
        dataIndex="id"
        render={(text) => (
          <Button
            size="small"
            type="primary"
            shape="circle"
            onClick={() => history.push(`${match.path}/${text}`)}
          >
            <FaPen />
          </Button>
        )}
      />
    </Table>
  );
};

export default TableUser;
