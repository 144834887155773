import { Col, Row } from "antd";
import React, { useReducer } from "react";
import ProductCategoryContext, {
  initState,
  reducer,
} from "../../../contexts/product-category.context";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import CategoryList from "./components/CategoryList";
import SubCategoryList from "./components/SubCategoryList";
import SelectDivision from "../setting/permission/user/components/SelectDivision";
import SelectDepartments from "../setting/permission/user/components/SelectDepartments";
import ModalCategory from "./components/ModalCategory";
import ModalSubCategory from "./components/ModalSubCategory";

export default function ProductCategory() {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <ProductCategoryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>หมวดหมู่สินค้า</PanelHeader>
        <PanelBody>
          <Row gutter={16} className="mb-3">
            <Col md={4}>
              <SelectDivision
                className="w-100"
                value={state.division?.id || ""}
                onSelect={(value, option) =>
                  dispatch({
                    type: "SET_DIVISION",
                    payload: { id: value, name: option?.props?.children },
                  })
                }
              />
            </Col>
            <Col md={4}>
              <SelectDepartments
                className="w-100"
                division_id={state.division?.id}
                value={state.department?.id || ""}
                onSelect={(value, option) =>
                  dispatch({
                    type: "SET_DEPARTMENT",
                    payload: { id: value, name: option?.props?.children },
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <CategoryList />
            </Col>
            <Col md={12}>
              <SubCategoryList />
            </Col>
          </Row>
        </PanelBody>
      </Panel>
      <ModalCategory />
      <ModalSubCategory />
    </ProductCategoryContext.Provider>
  );
}
