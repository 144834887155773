/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { useContext, useState } from "react";
import { PromotionContext } from "../../reducers";
import BranchModal from "./BranchModal";
import ItemList from "./ItemList";
import OtherSource from "./OtherSource";
import ShopSource from "./ShopSource";

const initialBranch = {
  visible: false,
  type: null,
};

const Index = () => {
  const {
    state: {
      data: { order_source },
    },
  } = useContext(PromotionContext);
  const [showBranch, setShowBranch] = useState(initialBranch);
  // const order_source_list = [
  //   {
  //     active: order_source.includes("pos"),
  //     title: "Legacy Shop",
  //     type: "pos",
  //   },
  //   {
  //     active: order_source.includes("online"),
  //     title: "Shopping Online",
  //     type: "online",
  //   },
  //   {
  //     active: order_source.includes("mobile"),
  //     title: "Mobile",
  //     type: "mobile",
  //   },
  // ];
  return (
    <>
      {/* {order_source_list.map((n, i) => (
        <ItemList
          key={i}
          onShowBranch={() =>
            setShowBranch({ visible: true, ..._.omit(n, ["active"]) })
          }
          {...n}
        />
      ))} */}
      <ShopSource
        onShowBranch={() =>
          setShowBranch({ visible: true, title: "Legacy Shop", type: "pos" })
        }
      />
      <OtherSource type="online" />
      <OtherSource type="mobile" />
      <BranchModal
        {...showBranch}
        onCancel={() => setShowBranch(initialBranch)}
      />
    </>
  );
};
export default Index;
