import {
  Alert,
  Button,
  Col,
  DatePicker,
  Input,
  Pagination,
  Row,
  Tooltip,
  Typography,
} from "antd";
import React, { memo, useContext, useEffect, useState } from "react";
import { FaEye, FaPrint } from "react-icons/fa";
import { useHttp } from "../../../../../../hooks/http";
import { URL_API, moment } from "../../../../../../utils";
import { printform } from "../../../../../../utils/printforms";
import BillPreview from "../../BillPreview";
import Radio from "../../Radio";
import MemberProfileContext from "../reducer";
import Table from "./Table";

const { Text } = Typography;

// pos footer !isNormal order_type is regular by default
const initSearch = {
  query: "",
  date_from: moment(),
  date_to: moment(),
  bill_status: "normal",
  order_type: "regular",
};

const limit = 10;
const BillHistory = ({ salesType }) => {
  const {
    state: {
      data: { partner_code, ref_type: partner_type },
    },
  } = useContext(MemberProfileContext);
  const [search, setSearch] = useState(initSearch);
  const [page, setPage] = useState(1);
  const [billSelectId, setBillSelectId] = useState(0);
  const [preview, setPreview] = useState(false);

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/member-profile/bill-history`,
      token: true,
      params: {
        limit,
        offset: (page - 1) * limit,
        query: search.query,
        partner_code,
        partner_type,
        sales_type: salesType,
        order_type: search.order_type,
        bill_status: search.bill_status,
        date_from: search.date_from ? moment(search.date_from).format("YYYY-MM-DD") : null,
        date_to: search.date_to ? moment(search.date_to).format("YYYY-MM-DD") : null,
      },
    },
    [
      limit,
      page,
      search.query,
      partner_code,
      partner_type,
      salesType,
      search.order_type,
      search.bill_status,
      search.date_from,
      search.date_to,
    ]
  );

  useEffect(() => {
    if (salesType) {
      setSearch(initSearch);
      setPage(1);
      setBillSelectId(0);
      setPreview(false);
    }
  }, [salesType]);

  const columns = [
    {
      title: "เลขที่ใบเสร็จ",
      dataIndex: "document_no",
    },
    {
      title: "เลขที่ใบจอง",
      dataIndex: "ref_document_no",
    },
    {
      title: "วันที่",
      dataIndex: "document_date",
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "ใบเสร็จ",
      dataIndex: "req_receipt_text",
    },
    {
      title: "สถานะการจัดส่ง",
      dataIndex: "ship_status_text",
    },
    {
      title: "ประเภทการรับของ",
      dataIndex: "req_receipt_type_text",
    },
    {
      title: "",
      align: "right",
      render: (_text, record) => (
        <>
          <Tooltip title="ดูรายละเอียด">
            <Button
              type="link"
              size="small"
              className="text-dark"
              onClick={() => {
                setBillSelectId(record.id);
                setPreview(true);
              }}
            >
              <FaEye />
            </Button>
          </Tooltip>
          <Tooltip title="พิมพ์">
            <Button
              type="link"
              size="small"
              className="text-dark"
              onClick={() => {
                // handlePrint(record);
                // receipt(record?.ref_document_id);
                printform("receipt", record);
              }}
            >
              <FaPrint />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  if (error) return <Alert type="error" message={error} showIcon />;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setPage(1);
  };

  return (
    <>
      <Row gutter={[8, 8]} type="flex" className="align-items-center mb-1">
        <Col span={8}>
          <DatePicker.RangePicker
            value={[search.date_from, search.date_to]}
            onChange={(e) => {
              let date_from = null;
              let date_to = null;
              if (e.length > 0) {
                date_from = e[0];
                date_to = e[1];
              }
              setSearch((prevState) => ({
                ...prevState,
                date_from,
                date_to,
              }));
            }}
            format="DD/MM/YYYY"
            placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
          />
        </Col>
        <Col
          span={8}
          className="d-flex align-items-center justify-content-center"
        >
          <Text strong className="text-nowrap text-dark mr-3">
            สถานะใบเสร็จ
          </Text>
          <Radio
            id="normal"
            name="bill_status"
            spanChild="ปกติ"
            value="normal"
            checked={search.bill_status === "normal"}
            onChange={handleOnChange}
          />
          <Radio
            id="cancel"
            name="bill_status"
            spanChild="ยกเลิก"
            value="cancel"
            checked={search.bill_status === "cancel"}
            onChange={handleOnChange}
          />
        </Col>
        {salesType === "sale" && (
          <Col
            span={8}
            className="d-flex align-items-center justify-content-center"
          >
            <Text strong className="text-nowrap text-dark mr-3">
              ประเภทการซื้อ
            </Text>
            <Radio
              id="sale-regular"
              name="order_type"
              spanChild="Regular"
              value="regular"
              checked={search.order_type === "regular"}
              onChange={handleOnChange}
            />
            <Radio
              id="sale-hold"
              name="order_type"
              spanChild="Hold"
              value="hold"
              checked={search.order_type === "hold"}
              onChange={handleOnChange}
            />
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} className="mb-3">
        <Col span={24}>
          <Input.Search
            allowClear
            name="query"
            placeholder="เลขที่ใบเสร็จ/เลขที่ใบจอง/เลขที่ชุดใบเสร็จ/เลขที่ชุดใบจอง"
            value={search.query}
            onChange={handleOnChange}
            autoComplete="off"
          />
        </Col>
      </Row>

      <Table
        dataSource={data?.data || []}
        columns={columns}
        loading={loading}
      />
      {data && data.total !== 0 && (
        <Pagination
          size="small"
          hideOnSinglePage
          pageSize={limit}
          current={page}
          total={data.total}
          onChange={(e) => setPage(e)}
          className="text-center mt-2"
        />
      )}

      <BillPreview
        id={billSelectId}
        preview={preview}
        setPreview={setPreview}
      />
    </>
  );
};

export default memo(BillHistory);
