/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Modal, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabUnderLine from "../../../components/TabUnderLine";
import { fixDepartment } from "../../../config/fix-value";
import { useHttp } from "../../../hooks/http";
import { actionTransfer } from "../../../redux/slices/transfer";
import { getUserObj, moment, URL_API, _ } from "../../../utils";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import CancelModal from "./components/CancelModal";
import DocumentTable from "./components/DocumentTable";
import SearchDocument from "./components/SearchDocument";

const { clear, clearGlobalState, setGlobalState } = actionTransfer;

const Index = ({ match, history }) => {
  const user = getUserObj();
  const { type: type_params, process: process_params } = match.params;
  const dispatch = useDispatch();
  const {
    query,
    doc_start_date,
    doc_end_date,
    modify_start_state,
    modify_end_date,
    from_wh_code,
    to_wh_code,
    status,
    page,
    limit,
    loading_submit,
  } = useSelector((state) => state.transfer.searchDocument);
  const [listTab, setListTab] = useState([]);
  const is_planning = user.department_id === fixDepartment.operation_support;

  useEffect(() => {
    let data = [
      {
        title: "รอรับเข้า",
        value: "pending",
      },
    ];
    if (type_params === "transfer") {
      data = [
        {
          title: "ขอปรับปรุง",
          value: "wait-add",
        },
        {
          title: "รอโอนย้าย",
          value: "pending",
        },
      ];
    }
    data = [
      ...data,
      { title: "เสร็จสิ้นกระบวนการ", value: "complete" },
      { title: "ยกเลิก", value: "cancel" },
    ];
    setListTab(data);
  }, [type_params]);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/transfer-receive/${
        type_params === "receive" && process_params === "complete"
          ? "receive"
          : "transfer"
      }`,
      params: {
        query,
        doc_start_date: doc_start_date
          ? moment(doc_start_date).format("YYYY-MM-DD")
          : "",
        doc_end_date: doc_end_date
          ? moment(doc_end_date).format("YYYY-MM-DD")
          : "",
        modify_start_state: modify_start_state
          ? moment(modify_start_state).format("YYYY-MM-DD")
          : "",
        modify_end_date: modify_end_date
          ? moment(modify_end_date).format("YYYY-MM-DD")
          : "",
        from_wh_code,
        to_wh_code,
        status: process_params === "pending" ? status : process_params,
        limit,
        offset: (page - 1) * limit,
        from_branch_code:
          type_params === "transfer" && !is_planning ? user.branch_code : "",
        to_branch_code:
          type_params !== "transfer" && !is_planning ? user.branch_code : "",
        receive_option:
          type_params === "receive"
            ? process_params === "pending"
              ? "receive-pending"
              : process_params === "cancel" && "receive-cancel"
            : "",
      },
      token: true,
    },
    [
      query,
      doc_start_date,
      doc_end_date,
      modify_start_state,
      modify_end_date,
      from_wh_code,
      to_wh_code,
      status,
      page,
      limit,
      type_params,
      is_planning,
    ]
  );

  useEffect(() => {
    return () => {
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      const type = arr[2];
      if (path !== "transfer-receive" || type !== type_params)
        dispatch(clear());
    };
  }, []);

  useEffect(() => {
    if (data?.data.user) {
      dispatch(setGlobalState({ name: "user", value: { ...data.data.user } }));
    }
  }, [data?.data.user]);

  useEffect(() => {
    if (
      !_.includes(["transfer", "receive"], type_params) ||
      !_.includes(["wait-add", "pending", "complete", "cancel"], process_params)
    ) {
      dispatch(clear());
      history.push({
        pathname: `/${_.split(match.path, "/")[1]}/transfer/pending`,
      });
      Modal.error({
        title: "ไม่สามารถดำเนินการได้",
        content: "กรุณาเลือกข้อมูลจากหน้าหลัก",
        zIndex: 1080,
      });
    }
  }, [type_params, process_params]);

  const handleClickProcess = (e) => {
    dispatch(clearGlobalState("searchDocument"));
    history.push({ pathname: `/transfer-receive/${type_params}/${e}` });
  };

  const setLoadingSubmit = useCallback(
    (loading_submit) =>
      dispatch(
        setGlobalState({
          name: "searchDocument",
          value: { loading_submit },
        })
      ),
    []
  );

  return (
    <Panel>
      <PanelHeader>
        {type_params === "transfer" ? "โอนย้ายสินค้า" : "รับสินค้า"}
      </PanelHeader>
      <PanelBody>
        <LoadingFullScreen
          loading={loading_submit}
          text={`กำลังบันทึกข้อมูล...`}
        />
        <Row className="mb-4 mt-3">
          <Col span={18} offset={3}>
            <TabUnderLine
              active={process_params}
              list={listTab}
              onClick={handleClickProcess}
            />
          </Col>
        </Row>
        <SearchDocument />
        {error ? (
          <Alert type="error" showIcon message={error} />
        ) : (
          <DocumentTable data={data} loading={loading} />
        )}
        <CancelModal reload={reload} setLoadingSubmit={setLoadingSubmit} />
      </PanelBody>
    </Panel>
  );
};

export default Index;
