import { createReducer } from "@reduxjs/toolkit";
import { Button, Col, Input, Modal, Row, Table } from "antd";
import moment from "moment";
import React, { useReducer, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectAllType from "../../../../../components/SelectAllType";
import { useApi } from "../../../../../hooks/http2";
import { actionEthics } from "../../../../../redux/slices/ethics";
import { URL_API } from "../../../../../utils";

const initState = {
  search: {
    limit: 10,
    query: "",
    page: 1,
    address_type: "",
  },
};

const reducer = createReducer(initState, {
  CLEAR: (state) => {
    state.search = {
      ...initState.search,
    };
  },
  SET_VALUE: (state, { payload }) => {
    state.search = { ...state.search, ...payload };
  },
});

export default function AddressHistoryModal() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.ethics.modal, shallowEqual);
  const { id, type } = useSelector((state) => state.ethics.form, shallowEqual);
  const { CLOSE_MODAL } = actionEthics;

  const [query, setQuery] = useState("");
  const [state, inDispatch] = useReducer(reducer, initState);

  const address = useApi(
    {
      baseURL: URL_API,
      url: `/ethics/history/address`,
      token: true,
      params: {
        ...state.search,
        id,
        type,
        offset: (state.search.page - 1) * state.search.limit,
      },
    },
    [id, type, state.search]
  );


  const closeModal = () => {
    dispatch(CLOSE_MODAL());
  };

  return (
    <Modal
      visible={modal.name === "address"}
      onCancel={closeModal}
      title = "ประวัติที่อยู่"
      width={800}
      destroyOnClose
      footer={[
        <Button type="default" key={'close'} onClick={closeModal}>
          ปิด
        </Button>,
      ]}
    >

      <Row gutter={16} className="mb-3">
        <Col md={6}>
          <SelectAllType
            size="small"
            process_type="address_type"
            className="w-100 font-size-12"
            withAll
            allText="ทั้งหมด"
            value={state.search.address_type}
            onChange={(e) =>
              inDispatch({
                type: "SET_VALUE",
                payload: { page: 1, address_type: e || "" },
              })
            }
          />
        </Col>
        <Col md={18}>
          <Input.Search
            size="small"
            className="font-size-12"
            placeholder="ที่อยู่, จังหวัด, อำเภอ, ตำบล"
            allowClear
            value={query}
            onChange={(e) => setQuery(e.target.value || "")}
            onSearch={(e) =>
              inDispatch({
                type: "SET_VALUE",
                payload: { page: 1, query: e || "" },
              })
            }
          />
        </Col>
      </Row>
      <Table
        size="small"
        loading={address.loading}
        dataSource={address.fetch?.data.map((item, index) => ({ ...item, key: index + 1 })) || []}
        rowKey="row_id"
        pagination={{
          size: "default",
          showLessItems: true,
          total: address.fetch?.total || 0,
          pageSize: state.search.limit,
          current: state.search.page,
          onChange: (page) =>
            inDispatch({ type: "SET_VALUE", payload: { page } }),
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
        }}
      >
        <Table.Column
          title="ลำดับ"
          className="font-size-12"
          dataIndex="row_id"
          align="right"
        />
        <Table.Column
          title="ประเภทที่อยู่"
          className="font-size-12"
          dataIndex="address_type_text"
          align="center"
        />
        <Table.Column
          title="ที่อยู่"
          className="font-size-12"
          dataIndex="address"
        />
        <Table.Column
          title="จังหวัด"
          className="font-size-12"
          dataIndex="province"
          align="center"
        />
        <Table.Column
          title="อำเภอ"
          className="font-size-12"
          dataIndex="district"
          align="center"
        />
        <Table.Column
          title="ตำบล"
          className="font-size-12"
          dataIndex="sub_district"
          align="center"
        />
        <Table.Column
          title="รหัสไปรษณีย์"
          className="font-size-12"
          dataIndex="post_code"
          align="center"
        />
        <Table.Column
          title="วันที่แก้ไข"
          className="font-size-12"
          dataIndex="create_date"
          align="center"
          render={(text) =>
            text ? moment(text).format("DD/MM/YYYY HH:mm") : "-"
          }
        />
        <Table.Column
          title="แก้ไขโดย"
          className="font-size-12"
          dataIndex="create_by"
          align="center"
        />
      </Table>
    </Modal>
  );
}
