import { Button, Col, Input, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../../../hooks/http2";
import { URL_API } from "../../../../../utils";

const ModalProduct = ({ visible, onCancel, onSelect }) => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const products = useApi(
    {
      baseURL: URL_API,
      url: "/system/products",
      token: true,
      params: {
        limit,
        offset: (page - 1) * limit,
        search,
      },
    },
    [page, search]
  );

  useEffect(() => {
    setPage(1);
    setSearch("");
  }, [visible]);

  return (
    <Modal
      title="เลือกสินค้า"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <Row gutter={16} className="mb-2">
        <Col md={24}>
          <Input.Search
            placeholder="รหัสสินค้า, ชื่อสินค้า"
            onSearch={(value) => {
              setSearch(value);
              if (page !== 1) {
                setPage(1);
              }
            }}
            allowClear
          />
        </Col>
      </Row>
      <Table
        loading={products.loading}
        size="small"
        bordered
        dataSource={products.fetch?.data || []}
        rowKey="product_code"
        pagination={{
          size: "default",
          total: products.fetch?.total || 0,
          showLessItems: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
          current: page,
          onChange: (p) => setPage(p),
        }}
      >
        <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
        <Table.Column title="ชื่อสินค้า" dataIndex="product_name_en" />
        <Table.Column
          align="center"
          render={(text, record) => {
            return (
              <Button
                size="small"
                type="primary"
                ghost
                onClick={() => {
                  onSelect(record);
                }}
              >
                เลือก
              </Button>
            );
          }}
        />
      </Table>
    </Modal>
  );
};

export default ModalProduct;
