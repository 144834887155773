/* eslint-disable no-unused-vars */
import { Button, Col, Modal, notification, Row, Table, Typography } from "antd";
import axios from "axios";
import parse from "html-react-parser";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdBookmark, MdBookmarkBorder } from "react-icons/md";
import { Link, useRouteMatch } from "react-router-dom";
import { useHttp } from "../../../hooks/http";
import { getToken, URL_API } from "../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import "./Legal-list.css";

const { Text } = Typography;

const Index = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({ title: "", description: "" });
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [filterData, setFilterData] = useState("");

  const match = useRouteMatch();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/news/news/get-legal-form`,
      token: true,
      params: { filterData },
      initialLoad: true,
    },
    [filterData]
  );

  //   useEffect(() => {
  //     setDataFilter(
  //       _.filter(data && data.dataFilter, (data) => {
  //         return data.value === "legal";
  //       })
  //     );
  //   }, [data, match.path]);

  const deleteNewsHandler = (id) => {
    try {
      setIsDeleting(true);
      axios({
        method: "post",
        url: `${URL_API}/news/news/news-delete`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          id: id,
        },
      });
      notification.success({
        message: "Success",
        description: "The data have been deleted",
        top: 60,
      });
      setTimeout(() => {
        reload();
        setIsDeleting(false);
      }, 500);
    } catch (e) {
      notification.warning({
        message: "Error",
        description: e.response
          ? e.response.data.message
          : "Cannot delete data",
        top: 60,
      });
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pinClickHandler = async (id, e) => {
    let swapPin;
    if (e === "0") {
      swapPin = "1";
    } else {
      swapPin = "0";
    }
    try {
      await axios({
        method: "post",
        url: `${URL_API}/news/news/news-pin`,
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          id: id,
          pin: swapPin,
        },
      });
      reload();
    } catch (e) {
      notification.warning({
        message: "Error",
        description: e.response ? e.response.data.message : "Cannot Pin News",
        top: 60,
      });
    }
  };

  return (
    <Panel>
      <PanelHeader>ประกาศ</PanelHeader>
      <PanelBody>
        <Row align="middle">
          <Row type="flex" className="flex" justify="end">
            <Col className="pr-3">
              <Link to={`${match.path}/news-create/legal`}>
                <Button type="primary">+ เพื๋มประกาศ</Button>
              </Link>
            </Col>
          </Row>
        </Row>

        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={data ? data.data : []}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={false}
          className="mt-3 table-text-top"
        >
          <Table.Column title="#" dataIndex="row_id" align="center" />
          <Table.Column
            title="Pin"
            dataIndex="is_pin"
            align="center"
            render={(text, r, i) => {
              if (text === 1) {
                return (
                  <Button
                    type="link"
                    onClick={() => {
                      pinClickHandler(data.data[i].id, text);
                    }}
                  >
                    <MdBookmark size={35} />
                  </Button>
                );
              } else {
                text = "0";
                return (
                  <Button
                    type="link"
                    onClick={() => {
                      pinClickHandler(data.data[i].id, text);
                    }}
                  >
                    <MdBookmarkBorder style={{ color: "#d9d9d9" }} size={35} />
                  </Button>
                );
              }
            }}
          />
          <Table.Column title="ประเภทประกาศ" dataIndex="announce_text" />

          <Table.Column
            title="Cover"
            dataIndex="azure_url"
            align="left"
            render={(text) => {
              if (text === null || text === undefined) {
                return "No Cover";
              }
              return <img src={text} alt="Cover" width="128" height="128" />;
            }}
          />
          <Table.Column title="หัวข้อ" dataIndex="title" width={800} />

          <Table.Column
            title="วันที่ประกาศ"
            dataIndex=""
            render={({ start_date, end_date, status }) => {
              return (
                <div>
                  <Text>สถานะ :&nbsp;
                    <Text
                        style={{
                          color: `${
                            status === "active" ? "#a0d911" : "#ff4d4f"
                          }`,
                        }}
                      >
                        {_.capitalize(status)}
                      </Text>
                  </Text>
                  <br/>
                  <Text>เริ่ม : </Text>
                  <br/>
                  <Text>
                        {start_date == null
                          ? "-"
                          : moment(start_date)
                              // .locale("en")
                              .format("DD/MM/YYYY HH:mm")}
                  </Text>
                  <br/>
                  <Text>สิ้นสุด : </Text>
                  <br/>
                  <Text>
                        {end_date == null
                          ? "-"
                          : moment(end_date)
                              // .locale("en")
                              .format("DD/MM/YYYY HH:mm")}
                  </Text>

                </div>
              );
            }}
            align="left"
          />

          <Table.Column
            title=""
            align="center"
            render={({ id }) => {
              return (
                <div>
                  <Link to={`${match.path}/news-create/legal/${id}`}>
                    <Button size="small">
                      <AiOutlineEdit />
                    </Button>
                  </Link>{" "}
                  <Button
                    size="small"
                    onClick={() => {
                      if (!isDeleting) {
                        deleteNewsHandler(id);
                      }
                    }}
                  >
                    <AiOutlineDelete />
                  </Button>
                </div>
              );
            }}
          />
        </Table>
      </PanelBody>
      <Modal
        title={modalData.title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
        footer={[
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Text key={0}>{parse(modalData.description)}</Text>
      </Modal>
    </Panel>
  );
};

export default Index;
