import React from "react";
import { EventMutateProvider } from "../../../../contexts/events.context";
import RegisterInfo from "./components/RegisterInfo";

const EditRegister = () => {
  return (
    <EventMutateProvider>
      <RegisterInfo />
    </EventMutateProvider>
  );
};
export default EditRegister;
