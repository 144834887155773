import { Col, Divider, Modal, Row, Spin, Tooltip, Typography } from "antd";
import React from "react";
import { useApi } from "../../../../hooks/http2";
import { URL_API } from "../../../../utils";

const PreviewModal = ({
  textMenu = "",
  preview = null,
  setPreview = () => {},
}) => {
  const data = useApi(
    {
      baseURL: URL_API,
      url: `/interface/log/detail/${preview?.id || 0}`,
      token: true,
    },
    [preview?.id]
  );

  return (
    <Modal
      visible={!!preview}
      title={`รายละเอียด (${textMenu})`}
      width={1000}
      zIndex={1080}
      cancelText="ปิด"
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => setPreview(null)}
    >
      <Spin spinning={data.loading}>
        <Row>
          <Col md={4}>
            <Typography.Text strong>Request Body</Typography.Text>
          </Col>
          <Tooltip
            placement="right"
            title="Click to Copy Request Body"
            zIndex={1100}
          >
            <Col
              md={20}
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigator.clipboard.writeText(data?.fetch?.data?.request_body)
              }
            >
              {data?.fetch?.data?.request_body}
            </Col>
          </Tooltip>
        </Row>
        <Divider />
        <Row>
          <Col md={4}>
            <Typography.Text strong>Response Json</Typography.Text>
          </Col>
          <Tooltip
            placement="right"
            title="Click to Copy Response Json"
            zIndex={1100}
          >
            <Col
              md={20}
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigator.clipboard.writeText(
                  data?.fetch?.data?.error_message === ""
                    ? data?.fetch?.data?.response_json
                    : data?.fetch?.data?.error_message
                )
              }
            >
              {data?.fetch?.data?.error_message === ""
                ? data?.fetch?.data?.response_json
                : data?.fetch?.data?.error_message}
            </Col>
          </Tooltip>
        </Row>
      </Spin>
    </Modal>
  );
};

export default React.memo(PreviewModal);
