import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import React, { memo } from "react";
import { FaCogs, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { moment, _ } from "../../../../utils";
import { enterFocusNext } from "../../../util/helper";
import { numInt } from "../../../util/helper/map-data";

const { Column } = Table;
const { Text } = Typography;

const { setProductAdd, addProductAddLot, delProductAddLot, setGlobalState } =
  actionTransfer;

const ProductAddTable = ({ disabled }) => {
  const dispatch = useDispatch();
  const dataSource = useSelector((state) => {
    const { itemKeys, itemByKey } = state.transfer.productAdd;
    return _.map(itemKeys, (key) => itemByKey[key]);
  });

  const handleChange = (name, value, key) => {
    dispatch(
      setProductAdd({
        name,
        value,
        key,
      })
    );
  };

  return (
    <>
      {!disabled && (
        <Row gutter={[8, 8]}>
          <Col span={24} className="text-right">
            <Button
              type="primary"
              className="width-150 ml-3"
              onClick={() =>
                dispatch(
                  setGlobalState({
                    name: "productModal",
                    value: { visible: true },
                  })
                )
              }
            >
              <FaPlus className="mr-2" />
              เพิ่มสินค้า
            </Button>
          </Col>
        </Row>
      )}
      <Row className="my-3">
        <Col span={24}>
          <Table
            bordered
            size="small"
            dataSource={dataSource}
            rowKey="key"
            className="ant-table-pagination-center table-very-small with-input"
            scroll={{ x: "max-content" }}
            pagination={false}
          >
            <Column
              width={70}
              title="รหัสสินค้า"
              key="product_code"
              dataIndex="product_code"
              align="center"
              render={(text, { line_number }) =>
                line_number === 1 && <Text>{text}</Text>
              }
            />
            <Column
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ชื่อสินค้า
                </div>
              }
              key="product_name_en"
              dataIndex="product_name_en"
              render={(text, { line_number }) =>
                line_number === 1 && <Text>{text}</Text>
              }
            />
            <Column
              width={80}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  จำนวนคงเหลือ
                </div>
              }
              key="balance"
              dataIndex="balance"
              align="right"
              render={(text, { line_number }) =>
                line_number === 1 && <Text strong>{numInt(text)}</Text>
              }
            />
            {!disabled && (
              <Column
                title={<FaCogs />}
                align="center"
                width={30}
                key="action-add"
                render={(_text, { product_code, line_number, key }) =>
                  line_number === 1 && (
                    <Popconfirm
                      title={`ต้องการเพิ่ม Lot รหัสสินค้า ${product_code} หรือไม่`}
                      onConfirm={() => dispatch(addProductAddLot(key))}
                      okText="ตกลง"
                      cancelText="ยกเลิก"
                      placement="top"
                      zIndex={1080}
                    >
                      <Button size="small" ghost type="primary">
                        <FaPlus size={10} />
                      </Button>
                    </Popconfirm>
                  )
                }
              />
            )}
            <Column
              width={100}
              className="px-1"
              title="Lot."
              key="lot"
              dataIndex="lot"
              align="center"
              render={(text, { key }) => (
                <Input
                  size="small"
                  autoComplete="off"
                  disabled={disabled}
                  value={text}
                  maxLength={100}
                  onKeyUp={enterFocusNext}
                  onChange={(e) => handleChange("lot", e.target.value, key)}
                />
              )}
            />
            <Column
              width={100}
              className="px-1"
              title="วันผลิต"
              key="mfg_date"
              dataIndex="mfg_date"
              align="center"
              render={(text, { key }) => (
                <DatePicker
                  size="small"
                  placeholder=""
                  format="DD/MM/YYYY"
                  disabled={disabled}
                  value={text ? moment(text) : null}
                  onChange={(value) => handleChange("mfg_date", value, key)}
                />
              )}
            />
            <Column
              width={100}
              className="px-1"
              title="วันหมดอายุ"
              key="exp_date"
              dataIndex="exp_date"
              align="center"
              render={(text, { key }) => (
                <DatePicker
                  size="small"
                  placeholder=""
                  format="DD/MM/YYYY"
                  disabled={disabled}
                  value={text ? moment(text) : null}
                  onChange={(value) => handleChange("exp_date", value, key)}
                />
              )}
            />
            <Column
              width={80}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">รับจริง</div>
              }
              key="new_receive_qty"
              dataIndex="new_receive_qty"
              align="right"
              render={(text, { key }) => (
                <InputNumber
                  size="small"
                  min={1}
                  disabled={disabled}
                  value={text}
                  onFocus={(e) => e.target.select()}
                  onKeyUp={enterFocusNext}
                  onChange={(value) =>
                    !_.isNaN(+value) &&
                    handleChange("new_receive_qty", value, key)
                  }
                />
              )}
            />
            <Column
              width={80}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  รับจริงรวม
                </div>
              }
              key="total_new_receive_qty"
              dataIndex="total_new_receive_qty"
              align="right"
              render={(text, record) => {
                const obj = {
                  children: <Text strong>{numInt(text)}</Text>,
                  props: {},
                };
                if (record.line_number === 1) {
                  obj.props.rowSpan = _.filter(
                    dataSource,
                    (n) => n.product_code === record.product_code
                  ).length;
                } else {
                  obj.props.rowSpan = 0;
                }
                return obj;
              }}
            />
            <Column
              className="px-1"
              title="หมายเหตุ"
              key="remark"
              dataIndex="remark"
              align="center"
              width={150}
              render={(text, { key }) => (
                <Input
                  size="small"
                  autoComplete="off"
                  disabled={disabled}
                  value={text}
                  maxLength={500}
                  onKeyUp={enterFocusNext}
                  onChange={(e) => handleChange("remark", e.target.value, key)}
                />
              )}
            />
            {!disabled && (
              <Column
                title={<FaCogs />}
                align="center"
                width={30}
                key="action-del"
                render={(_text, { key }) => (
                  <Popconfirm
                    title="ต้องการลบรายการนี้หรือไม่"
                    onConfirm={() => dispatch(delProductAddLot(key))}
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                    placement="topRight"
                    zIndex={1080}
                  >
                    <Button size="small" ghost type="danger">
                      <FaTrashAlt size={10} />
                    </Button>
                  </Popconfirm>
                )}
              />
            )}
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default memo(ProductAddTable);
