import React from "react";
import { Switch, Route } from "react-router-dom";

import Error404 from "../../Error404";
import Product from "./product/routes";
import Promotion from "./promotion/routes";
import Branch from "./branch";
import Voucher from "./voucher/routes";
import Delivery from "./delivery";
import Address from "./address";
import Nationality from "./nationality";
import Prefix from "./prefix";
import PromotinCategory from "./promotion-category";
import Bank from "./bank";
import Supplier from "./supplier/routes";
import ProductRequest from "./product-request/routes";
import RequisitionSource from "./requisition-source";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/product`} component={Product} />
      <Route path={`${match.path}/promotion`} component={Promotion} />
      <Route path={`${match.path}/branch`} component={Branch} />
      <Route path={`${match.path}/voucher`} component={Voucher} />
      <Route path={`${match.path}/delivery`} component={Delivery} />
      <Route path={`${match.path}/address`} component={Address} />
      <Route path={`${match.path}/nationality`} component={Nationality} />
      <Route path={`${match.path}/prefix`} component={Prefix} />
      <Route
        path={`${match.path}/promotion-category`}
        component={PromotinCategory}
        exact
      />
      <Route path={`${match.path}/bank`} component={Bank} exact />
      <Route path={`${match.path}/supplier`} component={Supplier} />
      <Route
        path={`${match.path}/product-request`}
        component={ProductRequest}
      />
      <Route
        path={`${match.path}/requisition-source/:type`}
        component={RequisitionSource}
        exact
      />
      <Route component={Error404} />
    </Switch>
  );
};
