import React from 'react'
import { Element } from 'react-scroll'

const LayoutInfo = (props) => {
    const { content } = props
    return (
        <>
            <Element
                style={{ paddingRight: '5px' }}
            >
                {content}
            </Element>

        </>
    )


}
export default LayoutInfo