import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import RoleContext, {
  initState,
  reducer
} from "../../../../../contexts/role.context";
import Error404 from "../../../Error404";
import List from "./List";
import RoleDetail from "./RoleDetail";



export default ({ match }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <RoleContext.Provider value={{ state, dispatch }}>
      <Switch>
        <Route path={match.path} exact component={List} />
        <Route path={`${match.path}/:id`} component={RoleDetail} />
        <Route component={Error404} />
      </Switch>
    </RoleContext.Provider>
  );
};
