/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Col, Input, Modal, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaCogs, FaTrash } from "react-icons/fa";
import { useHttp } from "../../../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../../../utils";


const initStateBranch = {
  id: null,
  bank_code: null,
  branch_code: "",
  bank_name: "",
};
const initState = [
  {
    data: [
      {
        id: null,
        bank_code: null,
        code: null,
        name: "",
      },
    ],
  },
];
let limit = 10;
const Tablebranch = ({
  code,
  branch,
  visible,
  changeadd,
  setBranch,
  firstB,
  confirm,
}) => {
  const [searchEdit, setSearchEdit] = useState("");
  const [bankcodeindex, setBankcodeIndex] = useState(code);
  const [branchindex, setBranchindex] = useState(branch);
  const [firstbranch, setFirstBranch] = useState(branch);
  const [pageEdit, setPageEdit] = useState(1);
  const [dataSource, setDataSource] = useState(branch);
  const [dataSourcetofinal, setDataSourcetofinal] = useState([]);
  const [dataFormtouse, setDataFormtouse] = useState([]);
  const [start, setStart] = useState(false);
  const [value, setValue] = useState("");
  const [planB, setPlanB] = useState([]);
  const [discode, setdisCode] = useState("");
  const [delAll, setDelall] = useState([]);
  const [showModalAddBranch, setShowModalAddBranch] = useState(false);
  const [dataFormAddBranch, setDataFormAddBranch] = useState(initStateBranch);
  const [loading, data, error, reload] = useHttp(
    {
      method: "get",
      url: `${URL_API}/setting/master/bankbranch`,
      token: true,
      params: {
        limit,
        offset: (pageEdit - 1) * limit,
        search: searchEdit,
        bank_code: bankcodeindex,
        discode,
      },
    },
    [pageEdit, bankcodeindex, searchEdit]
  );

  const [loading1, data1, error1, reload1] = useHttp(
    {
      method: "get",
      url: `${URL_API}/setting/master/bankall`,
      token: true,
    },
    []
  );

  const handleClickDelete = (data) => {
    let indb = delAll.filter((n) => n.id !== null);
    let delreal = indb.filter(
      (e1) => !dataSource.map((e2) => e2.branch_code).includes(e1.branch_code)
    );
    console.log("ชุดสุดท้าย", indb);
    console.log("ลบจริง", delreal);
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i <= delreal.length; i++) {
        console.log("dataindeli", delreal[i]);
        await axios({
          method: "post",
          headers: { Authorization: "Bearer " + getToken() },
          url: `${URL_API}/setting/master/bankaddbranchdel`,
          data: {
            id: delreal[i]?.id,
          },
        })
          .then((res) => {
            console.log("res.data_fromdelete", res.data);
          })
          .catch((err) => {
            reject(
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message
            );
          });
      }
      resolve();
      console.log("ลบสำเร็จ");
    });
  };

  const handleClickAdd = (data) => {
    let indb = delAll.filter((n) => n.id !== null);
    let delreal = indb.filter(
      (e1) => !dataSource.map((e2) => e2.branch_code).includes(e1.branch_code)
    );
    console.log("ชุดสุดท้าย", indb);
    console.log("ลบจริง", delreal);
    let add = dataSource.filter(
      (e1) => !firstB.map((e2) => e2.branch_code).includes(e1.branch_code)
    );
    let add2 = dataSource.filter((n) => n.id === null || !n.id);
    let add3 = add2.filter((e1) =>
      dataSource.map((e2) => e2.branch_code).includes(e1.branch_code)
    );
    let add4 = firstB.filter((e1) =>
      add3.map((e2) => e2.branch_code).includes(e1.branch_code)
    );
    let add5 = add3.filter((e1) =>
      firstB.map((e2) => e2.branch_code).includes(e1.branch_code)
    );
    console.log("ที่เพิ่มมาใช้จริง", add);
    console.log("ที่edit", add2);
    console.log("ที่editแล้ว id ตรง datasource", add3);
    console.log("ที่editแล้ว id ตรง firstB", add5);
    console.log("เอา id", add4);
    const id = add4.map((n) => n.id);
    console.log("sample", id);

    const test = add5.map((item, i) => {
      const container = {};
      container.branch_code = item.branch_code;
      container.bank_name = item.bank_name;
      container.bank_code = item.bank_code;
      container.id = add4[i].id;

      return container;
    });
    console.log("test", test);
    const result = [...test, ...add];
    console.log("result", result);
    const zeroPad = (num, places) => String(num).padStart(places, "0");

    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < result.length; i++) {
        console.log("dataindeli", result[i]);
        await axios({
          method: "post",
          headers: { Authorization: "Bearer " + getToken() },
          url: `${URL_API}/setting/master/bankaddbranch`,
          data: {
            id: result[i]?.id,
            bank_code: result[i].bank_code,
            branch_code: result[i].branch_code,
            bank_name: result[i].bank_name,
          },
        })
          .then((res) => {
            console.log("res.data_fromadd", res.data);
          })
          .catch((err) => {
            reject(
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message
            );
          });
      }
      resolve();
      console.log("บันทึกสำเร็จ");
      handleClickDelete();
    });
  };

  useEffect(() => {
    console.log("value", value);
    console.log("planB", planB);
    // if(value){
    // if(planB!==[]){
    //   setDataSource(planB)
    //   setPlanB([])
    // }}
  }, [dataSource]);

  useEffect(() => {
    handleClickAdd();
    console.log("บันทึกใน add", confirm);
  }, [confirm]);

  useEffect(() => {
    if (visible) {
      setValue("");
      setDelall([]);
      setDataSource(branch);
    }
  }, [visible]);

  useEffect(() => {
    if (value === "") {
      setPlanB("");
    }
  }, [value]);
  // useEffect(() => {
  //   if (value!==''&&visible){
  //   setDataSource(branch)
  //   setValue('')}
  // }, [visible])

  useEffect(() => {
    if (pageEdit !== 1) {
      setPageEdit(1);
      reload();
    }
  }, [bankcodeindex]);

  useEffect(() => {
    setBankcodeIndex(code);
    setSearchEdit(searchEdit);
    reload();
  }, [code, searchEdit]);

  const onDelete = (data) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการลบข้อมูลหรือไม่",
      okText: "ลบ",
      cancelText: "ยกเลิก",
      onOk() {
        const sample = dataSource.filter((n) => n.branch_code !== data);
        const delall = dataSource.filter((n) => n.branch_code === data);
        setDelall([...delAll, ...delall]);
        setValue("");
        setFirstBranch(sample);
        setDataSource(sample);
        reload();
        console.log("sample", data);
        console.log("sample", sample);
      },
    });
  };

  useEffect(() => {
    if (start) {
      //setPlanB([...dataSource,...dataFormtouse])
      setDataSource([...dataSource, ...dataFormtouse]);
      setFirstBranch(dataSource);
      setDataFormtouse([]);
      setDataFormAddBranch([]);
    }
    setStart(false);
  }, [showModalAddBranch]);

  const onAddbranch = () => {
    setDataFormtouse([
      ...dataFormtouse,
      {
        ...dataFormAddBranch,
        bank_code: code,
      },
    ]);
    setStart(true);
    setShowModalAddBranch(false);
  };

  //console.log("ข้อมูลที่จะลบ", delAll);
  console.log("branchindex", branchindex);
  data && console.log("datatable", data);
  data && console.log("datatable1", data1);
  console.log("datasourcebranch", branch);
  console.log("datasource", dataSource);
  console.log("dataFormtouse", dataFormtouse);

  const FilterByNameInput = (
    <Input.Search
      placeholder="รหัสสาขา ชื่อสาขา"
      className="mb-2"
      value={value}
      onChange={(e) => {
        const currValue = e.target.value.toLocaleUpperCase();
        setValue(currValue);
        const filteredData = dataSource.filter(
          (entry) =>
            entry.bank_name.toLocaleUpperCase().includes(currValue) ||
            entry.branch_code.toLocaleUpperCase().includes(currValue)
        );
        //setDataSource(filteredData)
        setPlanB(filteredData);
        //setDataSource(filteredData)
      }}
      allowClear
    />
  );
  console.log("planb", planB);
  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col md={10}>{FilterByNameInput}</Col>
        <Col className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={() => {
              setShowModalAddBranch(true);
            }}
          >
            เพิ่มสาขา
          </Button>
        </Col>
      </Row>
      {dataSource && (
        <Table
          size="small"
          bordered
          rowKey="row_id"
          loading={loading}
          dataSource={value === "" ? dataSource : planB}
          pagination={{
            current: pageEdit,
            pageSize: limit,
            total: dataSource?.total,
            showSizeChanger: false,
            showLessItems: true,
            onChange: (currPage) => setPageEdit(currPage),
          }}
        >
          <Table.Column
            title="ลำดับ"
            key="index"
            render={(_text, _record, index) => (
              <Typography.Text>
                {index + 1 + (pageEdit - 1) * limit}
              </Typography.Text>
            )}
          />
          <Table.Column
            title="รหัสสาขา"
            dataIndex="branch_code"
            align="center"
          />
          <Table.Column
            className="position-relative"
            title="ชื่อสาขา"
            dataIndex="bank_name"
          />
          <Table.Column
            title={<FaCogs />}
            align="center"
            render={({ branch_code, bank_name }) => (
              <>
                <Button
                  size="small"
                  shape="circle-outline"
                  type="danger"
                  onClick={() => {
                    onDelete(branch_code);
                  }}
                >
                  <FaTrash />
                </Button>
                {/* <Button
                size="small"
                shape="circle-outline"
                type="primary"
                onClick={() => {
                  handleClickAdd()
                }}
              >
                <FaCog />
              </Button> */}
              </>
            )}
          />
        </Table>
      )}
      <Modal
        title={"เพิ่มสาขา"}
        visible={showModalAddBranch}
        onCancel={() => {
          setShowModalAddBranch(false);
          setDataFormAddBranch(initStateBranch);
          setStart(false);
        }}
        width={400}
        footer={false}
        zIndex={1080}
      >
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Row>
              <Col>
                <label>ชื่อสาขา</label>
                <Input
                  value={dataFormAddBranch.bank_name}
                  onChange={(e) =>
                    setDataFormAddBranch({
                      ...dataFormAddBranch,
                      bank_name: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>รหัสสาขา</label>
                <Input
                  type="number"
                  value={dataFormAddBranch.branch_code}
                  onChange={(e) =>
                    setDataFormAddBranch({
                      ...dataFormAddBranch,
                      branch_code: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="p-5" gutter={8} type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() => {
                onAddbranch(dataFormAddBranch);
              }}
            >
              บันทึก
            </Button>
          </Col>
          <Col>
            <Button
              type="danger"
              onClick={() => {
                setShowModalAddBranch(false);
                setDataFormAddBranch(initStateBranch);
                setStart(false);
              }}
            >
              ยกเลิก
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Tablebranch;
