import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import EventCalendarAnnounceForm from '../form/EventCalendarAnnounceForm';
import { eventCalendarAnnounceFormDefaultValues } from '../dto/event-calendar-announce';
import { AlertSuccess } from '../../../../../util/Alert';
import eventCalendarAnnounceServices from '../../../../../../services/event-calendar-announce.services';

const EventCalendarAnnounceModal = ({ open, handleClose }) => {
  const { watch, handleSubmit, reset } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createEventCalendar } =
    eventCalendarAnnounceServices.useMutationCreateEventCalendarAnnounce(() => {
      AlertSuccess('เพิ่มประกาศงานอีเวนท์สำเร็จ');
      onSuccess();
    });

  const { mutate: updateEventCalendar } =
    eventCalendarAnnounceServices.useMutationUpdateEventCalendarAnnounce(() => {
      AlertSuccess('แก้ไขประกาศงานอีเวนท์สำเร็จ');
      onSuccess();
    });

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['get-all-event-calendar-announces'],
      refetchType: 'all',
    });
    reset(eventCalendarAnnounceFormDefaultValues);
    handleClose();
  };

  const _HandleSubmit = handleSubmit((data) => {
    const eventCalendarAnnounceData = {
      title: data.title,
      description: data.description,
      thumbnailImageUrl: data.thumbnailImageUrl,
      announcedDate: new Date(data.announcedDate),
      status: data.status,
    };
    if (watch('formType') === 'create') {
      createEventCalendar(eventCalendarAnnounceData);
    } else if (watch('formType') === 'edit') {
      updateEventCalendar({
        eventCalendarAnnounceId: data.id,
        data: eventCalendarAnnounceData,
      });
    }
  });

  const _OnCancel = () => {
    reset(eventCalendarAnnounceFormDefaultValues);
    handleClose();
  };

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'สร้างประกาศงานอีเวนท์'
          : 'แก้ไขประกาศงานอีเวนท์'
      }
      visible={open}
      onCancel={_OnCancel}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <EventCalendarAnnounceForm />
    </Modal>
  );
};

export default EventCalendarAnnounceModal;
