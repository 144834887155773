import { Button } from "antd";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import AddProductModal from "./AddProductModal";

const { setGlobalState } = actionRequisition;

const AddProduct = () => {
  const dispatch = useDispatch();
  const branch_code = useSelector(
    (state) => state.requisition.documentDetail.branch_code
  );
  return (
    <>
      <Button
        disabled={!branch_code}
        type="primary"
        className="width-150 mt-3"
        onClick={() =>
          dispatch(
            setGlobalState({
              name: "addProductModal",
              value: { visible: true },
            })
          )
        }
      >
        <FaPlus className="mr-2" />
        เพิ่มสินค้า
      </Button>
      <AddProductModal />
    </>
  );
};

export default AddProduct;
