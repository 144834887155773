import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { FaPlus } from "react-icons/fa";
import { Button } from "antd";

export default class UserListRole extends Component {
  constructor(props) {
    super(props);

    let _users = [];
    let u = this.props.users;
    for (let i = 0; i < u.length; i++) {
      if (!this.props.userHasIn.includes(u[i].id)) {
        _users.push(u[i]);
      }
    }

    this.state = {
      users: _users,
    };

    this.columns = [
      {
        key: "code",
        text: "รหัส",
        className: "code",
        align: "center",
        sortable: true,
      },
      {
        key: "first_name",
        text: "ชื่อ",
        className: "name",
        align: "center",
        sortable: true,
      },
      {
        key: "last_name",
        text: "นามสกุล",
        className: "name",
        align: "center",
        sortable: true,
      },
      {
        key: "department_name",
        text: "แผนก",
        align: "center",
        className: "department",
        sortable: true,
      },
      {
        key: "position_name",
        text: "ตำแหน่ง",
        align: "center",
        className: "position",
        sortable: true,
      },
      {
        key: "action",
        text: "เพิ่ม",
        className: "action text-center",
        align: "center",
        sortable: false,
        cell: (record) => {
          return (
            <Button
              className="mx-auto"
              size="small"
              shape="circle"
              type="primary"
              onClick={async () => {
                await this.removeUser(record.id);
                this.props.addUser(record.id);
              }}
            >
              <FaPlus />
            </Button>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 30],
    };
  }

  removeUser(id) {
    let _users = [];
    let u = this.state.users;
    for (let i = 0; i < u.length; i++) {
      if (u[i].id !== id) {
        _users.push(u[i]);
      }
    }

    this.setState({
      users: _users,
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col">
            <ReactDatatable
              config={this.config}
              records={this.state.users}
              columns={this.columns}
            />
          </div>
        </div>
      </>
    );
  }
}
