import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const visitFitStudioPaperServices = {
  useQueryGetVisitFitStudioPapers(
    {
      formType,
      memberStatus,
      firstName,
      lastName,
      phoneNumber,
      idCard,
      legacyPartnerCode,
      legacyConsultantPartnerCode,
      from,
      to,
      take,
      page,
      fitnessBranchId,
    },
    onSuccess
  ) {
    return useQuery(
      [
        'get-visit-fit-studio-papers',
        {
          formType,
          memberStatus,
          firstName,
          lastName,
          phoneNumber,
          idCard,
          legacyPartnerCode,
          legacyConsultantPartnerCode,
          from,
          to,
          take,
          page,
          fitnessBranchId,
        },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get('/visit-fit-studio-paper', {
            params: {
              ...(queryKey[1].formType && { formType: queryKey[1].formType }),
              ...(queryKey[1].memberStatus && {
                memberStatus: queryKey[1].memberStatus,
              }),
              ...(queryKey[1].firstName && {
                firstName: queryKey[1].firstName,
              }),
              ...(queryKey[1].lastName && { lastName: queryKey[1].lastName }),
              ...(queryKey[1].phoneNumber && {
                phoneNumber: queryKey[1].phoneNumber,
              }),
              ...(queryKey[1].idCard && { idCard: queryKey[1].idCard }),
              ...(queryKey[1].legacyPartnerCode && {
                legacyPartnerCode: queryKey[1].legacyPartnerCode,
              }),
              ...(queryKey[1].legacyConsultantPartnerCode && {
                legacyConsultantPartnerCode:
                  queryKey[1].legacyConsultantPartnerCode,
              }),
              ...(queryKey[1].from && { from: queryKey[1].from }),
              ...(queryKey[1].to && { t: queryKey[1].to }),
              ...(queryKey[1].fitnessBranchId && {
                fitnessBranchId: queryKey[1].fitnessBranchId,
              }),
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          return res.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetVisitFitStudioPaperById({ visitFitStudioPaperId }, onSuccess) {
    return useQuery(
      ['get-visit-fit-studio-paper-by-id', { visitFitStudioPaperId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/visit-fit-studio-paper/${queryKey[1].visitFitStudioPaperId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        enabled: !!visitFitStudioPaperId,
        onSuccess,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useMutationUpdateVisitFitStudioPaper(onSuccess, onError) {
    return useMutation(
      async ({ id, data }) => {
        try {
          const res = await fitStudioRequest.patch(
            `/visit-fit-studio-paper/${id}`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteVisitFitStudioPaper(onSuccess, onError) {
    return useMutation(
      async ({ visitFitStudioPaperId }) => {
        try {
          const res = await fitStudioRequest.delete(
            `/visit-fit-studio-paper/${visitFitStudioPaperId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDownloadisitFitStudioPaper() {
    return useMutation(
      async ({
        formType,
        memberStatus,
        firstName,
        lastName,
        phoneNumber,
        idCard,
        legacyPartnerCode,
        legacyConsultantPartnerCode,
        from,
        to,
        take,
        page,
      }) => {
        try {
          const res = await fitStudioRequest.get(
            `/visit-fit-studio-paper/download-excel`,
            {
              params: {
                ...(formType && { formType }),
                ...(memberStatus && { memberStatus }),
                ...(firstName && { firstName }),
                ...(lastName && { lastName }),
                ...(phoneNumber && { phoneNumber }),
                ...(idCard && { idCard }),
                ...(legacyPartnerCode && { legacyPartnerCode }),
                ...(legacyConsultantPartnerCode && {
                  legacyConsultantPartnerCode,
                }),
                ...(from && { from }),
                ...(to && { to }),
                take,
                page,
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'visit-fit-studio.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }
        } catch (error) {
          throw error.response.data.message;
        }
      }
    );
  },
};

export default visitFitStudioPaperServices;
