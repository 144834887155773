/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import React, { memo, useContext, useEffect } from "react";
import XLSX from "xlsx";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import ReceiveContext from "../reducer";

const { Text } = Typography;

const SelectSheetModal = () => {
  const {
    state: {
      file: { data: file },
      sheet: { data: sheets, name: sheet_name, name_list: sheet_name_list },
    },
    dispatch,
  } = useContext(ReceiveContext);

  const onChange = (name, property, value) =>
    dispatch({
      type: "setGlobalState",
      payload: {
        name,
        value: {
          [property]: value,
        },
      },
    });

  const loadExcel = async () => {
    const res_reader = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });
    const data = new Uint8Array(res_reader);
    const wb = XLSX.read(data, { type: "array" });
    onChange("sheet", "data", wb.Sheets);
    onChange("sheet", "name", wb.SheetNames[0]);
    onChange("sheet", "name_list", wb.SheetNames);
    onChange("file", "loading", false);
  };

  useEffect(() => {
    if (file) loadExcel();
  }, [file]);

  const handleClose = () => dispatch({ type: "clearImport" });

  const handleSubmitImport = () => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการนำเข้าข้อมูล",
      content: file?.name,
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        onChange("result", "loading", true);
        const item = XLSX.utils.sheet_to_json(sheets[sheet_name], {
          raw: true,
          defval: null,
        });
        axios({
          method: "post",
          url: `${URL_API}/transfer-receive/receive-in`,
          data: {
            file_name: file.name,
            file_size: file.size,
            item,
          },
          headers: { Authorization: "Bearer " + getToken() },
          cancelToken: source.token,
        })
          .then((res) => {
            const { inserted } = res.data;
            let success_qty = 0;
            let failed_qty = 0;
            _.forEach(inserted, (n) => {
              if (n.status === "success") {
                success_qty += 1;
              } else {
                failed_qty += 1;
              }
            });
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "result",
                value: {
                  reload: true,
                  loading: false,
                  inserted,
                  success_qty,
                  failed_qty,
                },
              },
            });
            handleClose();
            notification.success({
              message: `ดำเนินการนำเข้าข้อมูล ${success_qty} รายการ`,
            });
          })
          .catch((e) => resCatchModal(e))
          .finally(() => onChange("result", "loading", false));
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  return (
    <Modal
      visible={!!file && !!sheet_name}
      title="นำเข้าข้อมูล รับสินค้าจากคลังสำหนักงานใหญ่ไปคลังสาขา"
      footer={false}
      width={600}
      zIndex={1080}
      destroyOnClose={true}
      maskClosable={false}
      closable={false}
    >
      <Row gutter={16} type="flex" className="align-items-center mb-3">
        <Col span={4} className="text-right">
          <Text>ชื่อไฟล์</Text>
        </Col>
        <Col span={20}>
          <Text>{file?.name}</Text>
        </Col>
      </Row>
      <Row gutter={16} type="flex" className="align-items-center mb-4">
        <Col span={4} className="text-right">
          <Text>Sheet</Text>
        </Col>
        <Col span={20}>
          <Select
            className="w-100"
            value={sheet_name}
            dropdownStyle={{ zIndex: 1080 }}
            onChange={(e) => onChange("sheet", "name", e)}
          >
            {sheet_name_list.map((n, i) => (
              <Select.Option key={i} value={n}>
                {n}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24} className="text-right">
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button type="primary" className="ml-3" onClick={handleSubmitImport}>
            ตกลง
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(SelectSheetModal);
