/* eslint-disable no-unused-vars */
import { Button, Col, Modal, Row, Skeleton, Typography } from "antd";
import numeral from "numeral";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import img from "../../../../assets/img/pos/user-default.png";
import noImg from "../../../../assets/img/pos/no-img.jpg";
import { useApi } from "../../../../hooks/http2";
import { URL_API, _, moment } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import BomDetail from "../components/BomDetail";
import { FaEye } from "react-icons/fa";
import { data } from "jquery";

const billColumns = [
  {
    title: "",
  },
  {
    title: "รหัสสินค้า",
  },
  {
    title: "ชื่อสินค้า",
  },
  {
    title: "Running",
  },
  {
    title: "Lot.",
  },
  {
    title: "วันที่ผลิตสินค้า",
  },
  {
    title: "วันที่หมดอายุสินค้า",
  },
];

const BillDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const match = useRouteMatch();

  let detail = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/bill/${match.params.id}`,
      token: true,
    },
    [match.params.id]
  );
  console.log(detail.fetch?.data.items)

  return (
    <>
      <Panel>
        <PanelHeader>รายการบิลขาย</PanelHeader>
        <PanelBody>
          <Skeleton loading={detail.loading} active avatar>
            <Row gutter={16}>
              <Col span={6}>
                <div className="width-200 height-200 overflow-hidden rounded-circle mb-3 shadow">
                  <img
                    src={img}
                    className="w-100 h-100 object-fit-cover"
                    alt="Profile"
                  />
                </div>
                <Row gutter={4} className="mb-2">
                  <Col span={7}>
                    <Typography.Text strong>ผู้ดำเนินการ</Typography.Text>
                  </Col>
                  <Col span={17}>
                    <Typography.Text>
                      {detail.fetch?.data?.buyer_name}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row gutter={4} className="mb-2">
                  <Col span={7}>
                    <Typography.Text strong>เบอร์โทร</Typography.Text>
                  </Col>
                  <Col span={17}>
                    <Typography.Text>
                      +66
                      {detail.fetch?.data?.buyer_mobile}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col span={18} className="pt-2" style={{ fontSize: "10px" }}>
                <Row
                  gutter={4}
                  type="flex"
                  className="mb-2 mt-3 align-items-center"
                >
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      วันที่ออกใบเสร็จ
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {moment(detail.fetch?.data?.document_date).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      เลขที่ใบเสร็จ
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.document_no}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      วันที่จอง
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.ref_document_date &&
                        moment(detail.fetch?.data?.ref_document_date).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      เลขที่ใบจอง
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.ref_document_no}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row
                  gutter={4}
                  type="flex"
                  className="mb-2 mt-3 align-items-center"
                  // key={i}
                >
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      รหัสสมาชิก
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.partner_code}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      ชื่อสมาชิก
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.partner_name}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      ประเภทใบเสร็จ
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.order_type}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      ราคารวม (Vat)
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {numeral(detail.fetch?.data?.total_amount).format(
                        "0,0.00"
                      )}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row
                  gutter={4}
                  type="flex"
                  className="mb-2 mt-3 align-items-center"
                  // key={i}
                >
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      สถานะใบเสร็จ
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.document_status}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      สาขา
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.bill_location}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      การรับสินค้า
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.req_receipt_type_text}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      ใบเสร็จ
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.req_receipt}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row
                  gutter={4}
                  type="flex"
                  className="mb-2 mt-3 align-items-center"
                  // key={i}
                >
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      ประเภทการซื้อ
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.sales_type}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      แจงยอดภายใน
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {detail.fetch?.data?.req_receipt}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      คะแนนรวม PV
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {numeral(detail.fetch?.data?.pv).format()}
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      คะแนนรวม RP
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      {numeral(detail.fetch?.data?.rp).format()}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row
                  gutter={4}
                  type="flex"
                  className="mb-2 mt-3 align-items-center"
                  // key={i}
                >
                  <Col md={3}>
                    <Typography.Text strong align="right">
                      เอกสารมอบอำนาจ
                    </Typography.Text>
                  </Col>
                  <Col md={3}>
                    <Typography.Text>
                      <Button
                        type="primary"
                        size="small"
                        shape="circle-outline"
                        onClick={() => {
                          setIsModalOpen(!isModalOpen);
                        }}
                      >
                        <FaEye />
                      </Button>
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="bg-gray-40 mt-3">
              <Col className="px-4 d-flex align-items-center">
                <Typography.Text strong className="my-2">
                  การรับสินค้า
                </Typography.Text>
              </Col>
            </Row>
            <Row className="bg-gray-20">
              <Col className="px-4 py-3">
                <div className="d-flex">
                  <Typography.Text className="text-nowrap" strong>
                    ชื่อผู้รับ
                  </Typography.Text>
                  <Typography.Text className="text-nowrap ml-2">
                    {detail.fetch?.data?.ship_to}
                  </Typography.Text>
                  <Typography.Text className="text-nowrap ml-3" strong>
                    เบอร์โทรผู้รับ
                  </Typography.Text>
                  <Typography.Text className="text-nowrap ml-2">
                    {detail.fetch?.data?.ship_mobile}
                  </Typography.Text>
                  <Typography.Text className="text-nowrap ml-3" strong>
                    ประเภทการรับของ
                  </Typography.Text>
                  <Typography.Text className="text-nowrap ml-2">
                    {detail.fetch?.data?.req_receipt_type_text}
                  </Typography.Text>
                  <Typography.Text className="text-nowrap ml-3" strong>
                    ที่อยู่จัดส่ง
                  </Typography.Text>
                  <Typography.Text className="w-100 ml-2">
                    {`${
                      detail.fetch?.data?.ship_address_no
                        ? detail.fetch?.data?.ship_address_no
                        : ""
                    } ${
                      detail.fetch?.data?.ship_sub_district
                        ? detail.fetch?.data?.ship_sub_district
                        : ""
                    } ${
                      detail.fetch?.data?.ship_district
                        ? detail.fetch?.data?.ship_district
                        : ""
                    } ${
                      detail.fetch?.data?.ship_province
                        ? detail.fetch?.data?.ship_province
                        : ""
                    } ${
                      detail.fetch?.data?.ship_post_code
                        ? detail.fetch?.data?.ship_post_code
                        : ""
                    }`}
                  </Typography.Text>
                </div>
              </Col>
            </Row>

            <Row className="bg-gray-60 mt-3">
              <Col className="px-4 d-flex align-items-center">
                <Typography.Text strong className="my-2 text-white">
                  รายการสินค้า
                </Typography.Text>
              </Col>
            </Row>
            <Row className="bg-gray-20">
              <Col className="px-4 py-3">
                <table className="w-100">
                  <thead>
                    <tr>
                      {billColumns.map((n, i) => (
                        <td key={i} className="text-center text-bolder">
                          {n.title}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(detail.fetch?.data?.items, (item, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td>{i + 1}</td>
                          <td className="text-center">{item.product_code}</td>
                          <td>
                            {item.qty
                              ? `${item.product_name}(${item.qty} ชิ้น)`
                              : `${item.product_name}`}
                          </td>
                          <td className="text-center">{item.running}</td>
                          <td className="text-center">
                            {item.ref_store_qr_type === "sub" ? item.lot : ""}
                          </td>
                          <td className="text-center">
                            {item.ref_store_qr_type === "sub"
                              ? moment(item.production_date).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </td>
                          <td className="text-center">
                            {item?.ref_store_qr_type === "sub"
                              ? moment(item.expiration_date).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </td>
                        </tr>
                        {item.product_type_set === 'Kit/Package' && (
                          <BomDetail list={item.bom} qty={item.qty} />
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Skeleton>
        </PanelBody>
      </Panel>

      <Modal
        visible={isModalOpen}
        title="เอกสารมอบอำนาจ"
        width={800}
        footer={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <Row className="bg-gray-20 px-4 pt-3">
          <Col span={4}>
            <Typography.Text strong>รายละเอียด</Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text>
              {detail.fetch?.data.partner_remark}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="bg-gray-20 px-4 pt-2 pb-3">
          <Col span={4}>
            <Typography.Text strong>เอกสาร</Typography.Text>
          </Col>
          <Col span={20}>
            <img
              src={
                detail.fetch?.data?.authority?.length > 0
                  ? detail.fetch?.data.authority[0].azure_url
                  : noImg
              }
              className="w-100 h-100 object-fit-cover"
              alt="Authority"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BillDetail;
