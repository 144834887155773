/* eslint-disable no-unused-vars */
import { Button, Col, DatePicker, Row, Table, message } from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import SelectAllType from "../../../../../components/SelectAllType";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, getUser } from "../../../../../utils";
import { axios } from "../../../../../utils/useAxios";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectCategory from "../../components/SelectCategory";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import Search from "antd/lib/input/Search";

const Index = () => {
  const user = getUser();

  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: [user ? user.branch_code : ""],
      query: "",
      page: 1,
      limit: 10,
    },
    summaryModal: {
      visible: false,
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  const [teamFilter, setTeamFilter] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [group, setGroup] = useState("");

  const toDay = moment().format("DD/MM/YYYY");
  const limit = 15;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-sale-detail`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user?.id,
        category,
        subCategory,
        teamFilter: teamFilter,
        group,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportExcel = () => {
    const datasource = data ? data?.data : [];

    if (datasource.length === 0) {
      return message.warning("ไม่พบข้อมูล");
    } else {
      const title_table = [
        "ลำดับ",
        "วันที่",
        "เลขที่ใบเสร็จ",
        "เลขที่ใบรับคืน",
        "รหัสสาขา",
        "ชื่อสาขา",
        "ช่องทางรับสินค้า",
        "รหัสสินค้า",
        "ชื่อสินค้า",
        "ประเภทสินค้า",
        "จำนวน",
        "ราคาต่อหน่วย",
        "ราคารวม",
        "บัตรกำนัล",
        "Vat ต่อบิล",
        "ราคารวมต่อบิล",
        "รหัสผู้ทำรายการ",
        "ชื่อผู้ทำรายการ",
        "รหัสสมาชิก",
        "ชื่อสมาชิก",
        "RP ที่ซื้อ",
        "RP แลก",
        "PV ที่ซื้อ",
        "EP ที่ซื้อ",
        "Category",
        "Sub Category",
        "Group",
        "องค์กร",
        "ประเภทผู้ซื้อ",
        //"PV Return",
        //"RP Return",
        "รหัสผู้ดำเนินการ",
        "ชื่อผู้ดำเนินการ",
        "รหัสผู้แนะนำ",
        "ชื่อผู้แนะนำ",
        "น้ำหนักต่อบิล",
        "ถุง",
        "ใบเสร็จ",
      ];
      let excel_data = [title_table];
      datasource.forEach((n) => {
        excel_data = [
          ...excel_data,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            {
              v: moment(n.document_date).format("DD/MM/YYYY"),
              s: { alignment: { horizontal: "center" } },
            },
            { v: n.document_no, s: { alignment: { horizontal: "left" } } },

            {
              v: n.rt_document_no === null ? "" : n.rt_document_no,
              s: { alignment: { horizontal: "left" } },
            },
            { v: n.branch_code, s: { alignment: { horizontal: "left" } } },
            { v: n.branch_name, s: { alignment: { horizontal: "left" } } },
            {
              v: n.receipt_branch_name,
              s: { alignment: { horizontal: "left" } },
            },
            { v: n.product_code, s: { alignment: { horizontal: "left" } } },
            { v: n.product_name, s: { alignment: { horizontal: "left" } } },
            { v: n.set_type, s: { alignment: { horizontal: "center" } } },
            {
              v: n.qty,
              t: "n",
              s: {
                numFmt: "#,##0",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.unit_price,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.total_amount,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v:
                n.voucher_amount === "" || n.voucher_amount === null
                  ? 0
                  : n.voucher_amount,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.vat_amount,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v:
                n.grand_total_amount === null || n.grand_total_amount === ""
                  ? 0
                  : n.grand_total_amount,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v:
                n.cashier_code === "" || n.cashier_code === null
                  ? ""
                  : n.cashier_code,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v:
                n.cashier_name === "" || n.cashier_name === null
                  ? ""
                  : n.cashier_name,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v:
                n.partner_code === "" || n.partner_code === null
                  ? ""
                  : n.partner_code,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v:
                n.partner_name === "" || n.partner_name === null
                  ? ""
                  : n.partner_name,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v: n.rp * n.qty,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.redeem_point,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.pv * n.qty,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.ep ? n.ep * n.qty : 0,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            { v: n.category, s: { alignment: { horizontal: "left" } } },
            { v: n.sub_category, s: { alignment: { horizontal: "left" } } },
            { v: n.groups, s: { alignment: { horizontal: "left" } } },
            {
              v: n.team_name === "" || n.team_name === null ? "" : n.team_name,
              s: { alignment: { horizontal: "left" } },
            },
            { v: n.partner_type, s: { alignment: { horizontal: "left" } } },
            // {
            //   v: n.rp_return,
            //   t: "n",
            //   s: {
            //     numFmt: "#,##0.00",
            //     alignment: { horizontal: "right" },
            //   },
            // },
            // {
            //   v: n.pv_return,
            //   t: "n",
            //   s: {
            //     numFmt: "#,##0.00",
            //     alignment: { horizontal: "right" },
            //   },
            // },
            {
              v:
                n.buyer_code === "" || n.buyer_code === null
                  ? ""
                  : n.buyer_code,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v:
                n.buyer_name === "" || n.buyer_name === null
                  ? ""
                  : n.buyer_name,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v:
                n.sponsor_code === "" || n.sponsor_code === null
                  ? ""
                  : n.sponsor_code,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v:
                n.sponsor_name === "" || n.sponsor_name === null
                  ? ""
                  : n.sponsor_name,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v: n.weight ? n.weight : 0,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.req_bag === 1 ? "รับ" : "ไม่รับ",
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.req_receipt === 1 ? "รับ" : "ไม่รับ",
              s: { alignment: { horizontal: "center" } },
            },
          ],
        ];
      });

      const ws = XLSX.utils.aoa_to_sheet(excel_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(
        wb,
        `รายงานรายละเอียดยอดขาย_${moment().format("YYMMDD_HHmm")}.xlsx`
      );
    }
  };

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานรายละเอียดยอดขาย</PanelHeader>
        <PanelBody>
          {/* <FilterList
            placeholder="รหัสสมาชิก, ชื่อสมาชิก, เลขที่ใบเสร็จ, ชื่อพนักงาน"
            Topic={"วันที่เปิดบิล"}
            showReportSpan={8}
            inputSpan={8}
            reload={reload}
            exportToExcel={exportExcel}
          /> */}
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={6} className="pr-4">
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, ชื่อสมาชิก, เลขที่ใบเสร็จ, ชื่อพนักงาน"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                onSearch={reload}
                onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={1} align="right">
              <Text strong>วันที่เปิดบิล</Text>
            </Col>

            <Col span={4} className="pl-3">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>

            <Col span={13}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={1}>
              <Text strong>สาขาเปิดบิล</Text>
            </Col>
            <Col span={3}>
              <SelectBranch
                withAll
                userLogin={true}
                allowDisableBranch
                defaultValue={user.branch_code}
                className="w-100"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
            <Col span={1} align="middle">
              <Text strong>Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                withAll
                category="category"
                className="w-100"
                defaultValue={category}
                onChange={(e) => {
                  setCategory(e);
                  setSubCategory("");
                }}
              />
            </Col>

            <Col span={2} align="middle">
              <Text strong>Sub Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                withAll
                category={`subcategory?CategoryId=${category}`}
                className="w-100"
                defaultValue={subCategory}
                onChange={(e) => {
                  setSubCategory(e);
                  setGroup("");
                }}
              />
            </Col>

            <Col span={1} align="middle">
              <Text strong>Group</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category={`group?SubCategoryId=${subCategory}`}
                withAll
                defaultValue={state.filter.group}
                value={group}
                onChange={(value, e) => {
                  setGroup(value);
                  //setGroupText(e.props.children)
                }}
              />
            </Col>
            <Col span={1} align="middle">
              <Text strong>องค์กร</Text>
            </Col>
            <Col span={3}>
              <SelectAllType
                withAll
                process_type="team"
                valueIndex="name"
                className="w-100"
                value={teamFilter}
                onChange={(e) => {
                  setTeamFilter(e);
                }}
              />
            </Col>
          </Row>

          <Table
            className="pb-5"
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            // pagination={false}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data.data.length : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="วันที่"
              dataIndex="document_date"
              align="center"
              render={(text) => {
                return moment(text).format("DD/MM/YYYY");
              }}
            />
            <Table.Column
              title="เลขที่ใบเสร็จ"
              dataIndex="document_no"
              align="center"
            />

            <Table.Column
              title="เลขที่ใบรับคืน"
              dataIndex="rt_document_no"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="รหัสสาขา"
              dataIndex="branch_code"
              align="center"
            />
            <Table.Column
              title="ชื่อสาขา"
              dataIndex="branch_name"
              align="center"
            />
            <Table.Column
              title="ช่องทางรับสินค้า"
              dataIndex="receipt_branch_name"
              align="center"
            />
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              align="center"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name"
              align="left"
            />
            <Table.Column
              title="ประเภทสินค้า"
              dataIndex="set_type"
              align="center"
            />
            <Table.Column
              title="จำนวน"
              dataIndex="qty"
              align="right"
              render={(text) => numeral(text).format()}
            />
            <Table.Column
              title="ราคาต่อหน่วย"
              dataIndex="unit_price"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ราคารวม"
              dataIndex="total_amount"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="บัตรกำนัล"
              dataIndex="voucher_amount"
              align="right"
              render={(text) => {
                return text ? numeral(text).format("0,0.00") : "-";
              }}
            />
            {/* <Table.Column
              title="ส่วนต่างบัตรกำนัล"
              dataIndex="voucher_dif"
              align="right"
              render={(text) => {
                return text ? numeral(text).format("0,0.00") : "-";
              }}
            /> */}
            <Table.Column
              title="Vat ต่อบิล"
              dataIndex="vat_amount"
              align="right"
              render={(text) => numeral(text).format("0,0.00")}
            />
            <Table.Column
              title="ราคารวมต่อบิล"
              dataIndex="grand_total_amount"
              align="right"
              render={(text) => numeral(text).format("0,0.00")}
            />
            <Table.Column
              title="รหัสผู้ทำรายการ"
              dataIndex="cashier_code"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="ชื่อผู้ทำรายการ"
              dataIndex="cashier_name"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="ชื่อสมาชิก"
              dataIndex="partner_name"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="RP ที่ซื้อ"
              dataIndex="rp"
              align="right"
              render={(text, record) =>
                numeral(record.rp * record.qty).format("0,0.00")
              }
            />
            <Table.Column
              title="RP แลก"
              dataIndex="redeem_point"
              align="right"
              render={(text) => numeral(text).format("0,0.00")}
            />
            <Table.Column
              title="PV ที่ซื้อ"
              dataIndex="pv"
              align="right"
              render={(text, record) =>
                numeral(record.pv * record.qty).format("0,0.00")
              }
            />
            <Table.Column
              title="EP ที่ซื้อ"
              dataIndex="ep"
              align="right"
              render={(text, record) =>
                numeral(record.ep * record.qty).format("0,0.00")
              }
            />
            <Table.Column
              title="Category"
              dataIndex="category"
              align="center"
            />
            <Table.Column
              title="Sub Category"
              dataIndex="sub_category"
              align="center"
            />
            <Table.Column title="Group" dataIndex="groups" align="center" />
            <Table.Column title="องค์กร" dataIndex="team_name" align="center" />
            {/* <Table.Column
              title="เลขที่บัตรกำนัล"
              dataIndex="voucher_code"
              align="center"
            /> */}
            <Table.Column
              title="ประเภทผู้ซื้อ"
              dataIndex="partner_type"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            {/* <Table.Column
              title="PV Return"
              dataIndex="rp_return"
              align="center"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="RP Return"
              dataIndex="pv_return"
              align="center"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            /> */}
            <Table.Column
              title="รหัสผู้ดำเนินการ"
              dataIndex="buyer_code"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="ชื่อผู้ดำเนินการ"
              dataIndex="buyer_name"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="รหัสผู้แนะนำ"
              dataIndex="sponsor_code"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="ชื่อผู้แนะนำ"
              dataIndex="sponsor_name"
              align="center"
              render={(text) => {
                if (text == null) {
                  return "";
                }
                return text;
              }}
            />
            <Table.Column
              title="น้ำหนักต่อบิล"
              dataIndex="weight"
              align="right"
              render={(text, record) => numeral(record.weight).format("0,0.00")}
            />
            <Table.Column
              title="ถุง"
              dataIndex="req_bag"
              align="center"
              render={(text) => {
                if (text === 1) {
                  return "รับ";
                } else {
                  return "ไม่รับ";
                }
              }}
            />
            <Table.Column
              title="ใบเสร็จ"
              dataIndex="req_receipt"
              align="center"
              render={(text) => {
                if (text === 1) {
                  return "รับ";
                } else  {
                  return "ไม่รับ";
                }
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
