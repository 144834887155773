import React from "react";
import { Typography } from "antd";

class CustomTiTle extends React.Component {
    render() {
        return (
            <>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {!this.props.notReq ?
                        <Typography style={{
                            fontSize: '14px',
                            marginBottom: '5px',
                            marginRight: '4px',
                            color: '#FF5B57'
                        }}>*</Typography> : <></>
                    }
                    <Typography style={{
                        fontSize: '14px',
                        marginBottom: '5px',
                        color: this.props.color === 'lime' ? '#97D700' : '#1D2226',
                    }}>{this.props.title}</Typography>
                </div>
                {
                    this.props.desc && !this.props.notReq &&
                    <Typography style={{
                        fontWeight: 300,
                        fontSize: '12px',
                        color: '#1D2226',
                        marginLeft: '10px'
                    }}>{this.props.desc}</Typography>
                }
                {
                    this.props.desc && this.props.notReq &&
                    <Typography style={{
                        fontWeight: 300,
                        fontSize: '12px',
                        color: '#1D2226',
                    }}>{this.props.desc}</Typography>
                }
            </>
        );
    }
};

export { CustomTiTle };