import Axios from 'axios';
import { getToken, URL_API } from '.';

const axios = Axios.create({
  baseURL: URL_API,
});

axios.interceptors.request.use((config) => {
  const token = getToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const eventRequest = Axios.create({
  baseURL: process.env.REACT_APP_EVENT_URL_API,
});

const checkingRunningRequest = Axios.create({
  baseURL: process.env.REACT_APP_CHECKINGRUNNING_URL,
});

export { axios, eventRequest, checkingRunningRequest };
