import { Button, Popconfirm, Switch, Table } from "antd";
import React, { useContext } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { PromotionContext } from "../../reducers";

const Voucher = ({
  type,
  dataSource,
  dataKey,
  disabledAdd,
  disabledChange,
  refSourceOption,
}) => {
  const { dispatch } = useContext(PromotionContext);
  return (
    <Table
      bordered
      size="small"
      dataSource={dataSource}
      rowKey="key"
      pagination={false}
    >
      <Table.Column
        key="no"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">ลำดับ</div>}
        align="right"
        width={50}
        render={(_text, _record, index) => index + 1}
      />
      <Table.Column
        key="code"
        dataIndex="code"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">รหัส</div>}
        width={100}
      />
      <Table.Column
        key="name"
        dataIndex="name"
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">ชื่อบัตรกำนัล</div>
        }
      />
      <Table.Column
        key="voucher_type"
        dataIndex="voucher_type"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">ประเภท</div>}
        width={120}
      />
      <Table.Column
        key="value"
        dataIndex="value"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">มูลค่า</div>}
        width={100}
        align="right"
      />
      <Table.Column
        key="keep"
        dataIndex="keep"
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">ใช้ครั้งถัดไป</div>
        }
        width={100}
        align="center"
        render={(text, record) => (
          <Switch
            checked={text === "1"}
            disabled={disabledChange}
            onClick={(checked) =>
              dispatch({
                type: "SET_PROMOTION_DATA_ITEM_VALUE",
                payload: {
                  type,
                  data_key: dataKey,
                  item_key: record.key,
                  keep: checked ? "1" : "0",
                },
              })
            }
          />
        )}
      />
      <Table.Column
        key="action"
        title={
          <Button
            type="primary"
            ghost
            size="small"
            disabled={disabledAdd}
            onClick={() =>
              dispatch({
                type: "SET_MODAL_VISIBLE",
                payload: {
                  name: "voucher",
                  visible: true,
                  active_by: type,
                  data_key: dataKey,
                  ref_source_option: refSourceOption || null,
                },
              })
            }
          >
            <FaPlus />
          </Button>
        }
        align="center"
        width={60}
        render={(record) => (
          <Popconfirm
            title="ต้องการลบรายการนี้หรือไม่"
            disabled={record.disabled_del}
            onConfirm={() =>
              dispatch({
                type: "DEL_PROMOTION_DATA_ITEM",
                payload: {
                  type,
                  data_key: dataKey,
                  item_key: record.key,
                },
              })
            }
            okText="ลบ"
            cancelText="ไม่ลบ"
          >
            <Button
              type="danger"
              ghost
              size="small"
              disabled={record.disabled_del}
            >
              <FaTrashAlt />
            </Button>
          </Popconfirm>
        )}
      />
    </Table>
  );
};

export default Voucher;
