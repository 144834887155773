import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import fitLegacyServices from '../../../../../services/fit-legacy.services';
import LegacyRewardEventForm from '../form/LegacyRewardEventForm';

const LegacyRewardEventModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLegacyRewardEvent } =
    fitLegacyServices.useMutationCreateLegacyRewardEvent(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-reward-event'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    });

  const { mutate: editLegacyRewardEvent } =
    fitLegacyServices.useMutationUpdateLegacyRewardEvent(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-reward-event'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    });

  const _HandleSubmit = handleSubmit((data) => {
    const legayPopUpData = {
      imageUrl: data.imageUrl,
      title: data.title,
      description: data.description,
    };

    if (watch('formType') === 'create') {
      createLegacyRewardEvent(legayPopUpData);
    } else if (watch('formType') === 'edit') {
      editLegacyRewardEvent({ id: data.id, data: legayPopUpData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่ม Legacy Reward Event'
          : 'แก้ไข Legacy Reward Event'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyRewardEventForm />
    </Modal>
  );
};

export default LegacyRewardEventModal;
