import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Error404 from "../Error404";
import PageIndex from "./index";
import Manage from "./Manage";

const routes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <Redirect to={`${match.path}/daily`} />
    </Route>
    <Route exact path={`${match.path}/:type`} component={PageIndex} />
    <Route exact path={`${match.path}/:type/:document_no`} component={Manage} />
    <Route component={Error404} />
  </Switch>
);

export default routes;
