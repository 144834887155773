/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import xlsx from "xlsx";
import SelectAllType from "../../../../../components/SelectAllType";
import SelectDepartment from "../../../../../components/SelectDapartment";
import TabUnderLine from "../../../../../components/TabUnderLine";
import { useApi } from "../../../../../hooks/http2";
import {
  actionProductRequest,
  listCheckSelector,
  searchSelector,
} from "../../../../../redux/slices/product-request";
import {
  axios,
  getToken,
  moment,
  URL_API,
  _,
} from "../../../../../utils/index";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectItemType from "./components/SelectItemType";

const RequestList = () => {
  const tabList = [
    {
      value: "draft",
      title: "เอกสารร่าง",
    },
    {
      value: "pending",
      title: "รออนุมัติเอกสาร",
    },
    {
      value: "completed",
      title: "อนุมัติเรียบร้อย",
    },
    {
      value: "cancel",
      title: "ยกเลิกเอกสาร",
    },
  ];

  const history = useHistory();
  const match = useRouteMatch();
  const [exporting, setExporting] = useState(false);

  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const selectedRowKeys = useSelector(listCheckSelector);
  const { SET_SEARCH, SELECT_ROW_KEYS } = actionProductRequest;

  const list = useApi(
    {
      baseURL: URL_API,
      url: `/setting/master/product-request`,
      params: {
        ...search,
        start_date: search?.start_date
          ? moment(search?.start_date).format("YYYY-MM-DD")
          : "",
        end_date: search?.end_date
          ? moment(search?.end_date).format("YYYY-MM-DD")
          : "",
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  const mapData = (data = []) => {
    return _.map(data || [], (n, i) => {
      return {
        "Internal ID": n.backend_internal_id,
        "ITEM NAME/NUMBER": n.main_sku || n.product_code,
        "OLD CODE": n.product_code,
        "DISPLAY NAME/CODE": n.product_name_en,
        "ITEM NAME (TH)": n.product_name_local,
        คำโปรย: n.product_tagline_local,
        DESCRIPTION: "",
        "SALES DESCRIPTION": n.sale_description,
        CLASS: n.department_id === 4 ? "Service" : "Trading",
        "ITEM TYPE": n.product_type_set,
        "TAX SCHEDULE": n.tax_schedule,
        "UNITS TYPE": n.unit_type_name,
        DEPARTMENT: n.department_name,
        CATEGORY: n.oracle_category_name,
        SUBCATEGORY: n.oracle_sub_category_name,
        GROUP: n.oracle_group_name,
        "FOR REDEEM":
          n.distribution_type?.toLowerCase() === "redeem" ? "Yes" : "No",
        "USE POS INTERFACE": "Yes",
        "ITEM CLASS": n.product_class_name,
        "UPC CODE": n.bar_code,
        // PRODUCT_CHEST: n.product_chest || 0,
        // PRODUCT_SHOULDER: n.product_shoulder || 0,
        // PRODUCT_WAIST: n.product_waist || 0,
        // PRODUCT_HIP: n.product_hip || 0,
        // PRODUCT_WIDTH: n.product_width || 0,
        // PRODUCT_HEIGHT: n.product_height || 0,
        // PRODUCT_LENGTH1: n.product_length_1 || 0,
        // PRODUCT_LENGTH2: n.product_length_2 || 0,
        // PRODUCT_DEPTH: n.product_depth || 0,
        SIZE: n.size,
        "WIDTH (CM)": n.size_width,
        "LENGTH (CM)": n.size_length,
        "HEIGHT (CM)": n.size_height,
        "ITEM WEIGHT": n.weight,
        "WEIGHT UNIT": "g",
        "DELIVERY TYPE": n.delivery_type_name,
        "ITEM COLOR": n.color_code || "",
        TASTE: n.taste_name || "",
        SMELL: n.smell_name || "",
        TEMPERATURE: n.temperature_name,
        "MEMBER PRICE (Include VAT)": n.distributor_price || 0,
        PV: n.point_01 || 0,
        RP: n.point_02 || 0,
        RA: n.point_06 || 0,
        CV: n.point_03 || 0,
        BP: n.point_05 || 0,
        TP: n.point_07 || 0,
        HP: n.point_07 || 0,
        EP: n.point_09 || 0,
        "VIP USER PRICE": n.member_price || 0,
        RPV: n.r_point_01 || 0,
        RRP: n.r_point_02 || 0,
        RCV: n.r_point_03 || 0,
        RBP: n.r_point_04 || 0,
        RRA: n.r_point_06 || 0,
        REP: n.r_point_09 || 0,
        D_PV: n.d_point_01 || 0,
        D_RP: n.d_point_02 || 0,
        D_CV: n.d_point_03 || 0,
        D_EP: n.d_point_09 || 0,
        "MEMBER (REDEEM + PRICE)": n.distributor_redeem_price || 0,
        "VIP USER (REDEEM + PRICE)": n.member_redeem_price || 0,
        "MEMBER (REDEEM + PRICE)_POINT": n.distributor_redeem_point || 0,
        "VIP USER (REDEEM + PRICE)_POINT": n.member_redeem_point || 0,
        "CLAIM PRICE": n.claim_price || 0,
        "RETAIL PRICE (Include VAT)": n.retail_price || 0,
        "STAFF PRICE (Include VAT)": n.staff_price || 0,
        "SPECIAL PRICE (Include VAT)": n.special_price || 0,
        REDEEM: n.distributor_redeem || 0,
        "VIP USER REDEEM": n.member_redeem || 0,
        "PV/TIME": n.point_time_01 || 0,
        "RP/TIME": n.point_time_02 || 0,
        "RA/TIME": n.point_time_06 || 0,
        "CV/TIME": n.point_time_02 || 0,
        "SP/TIME": n.point_time_05 || 0,
        "RPV/TIME": n.r_point_time_01 || 0,
        "RRP/TIME": n.r_point_time_02 || 0,
        "RCV/TIME": n.r_point_time_03 || 0,
        "RSP/TIME": n.r_point_time_05 || 0,
        "REDEEM/TIME": n.redeem_point_time,
        "PV QUANTITY": n.point_01_qty || 1,
        "SPLIT PV": (parseFloat(n.point_01_qty) || 1) > 1 ? "Yes" : "No",
        "SHELF LIFE IN DAYS": n.shelef_life,
        "SHIPPING RATE": n.shipping_rate || 0,
        REMARKS: "",
      };
      // return {
      //   ITEM: "",
      //   CATEGORY: n.category_name || "",
      //   SUBCATEGORY: n.sub_category_name || "",
      //   GROUP: "",
      //   UNIT: "",
      //   DELIVERY: "",
      //   TYPE: "",
      //   "SPLIT PV": (parseFloat(n.point_01_qty) || 1) > 1 ? "Yes" : "No",
      //   "SHELF LIFE IN DAYS": n.shelef_life,
      //   "SHIPPING RATE": n.shipping_rate || 0,
      //   REMARKS: "",
      //   "SALE TYPE": n.distribution_type,
      //   "PRODUCT TYPE": n.set_type_name,
      //   "DEALER CATEGORY": "",
      //   หมวดหมู่สินค้า_1: "",
      //   หมวดหมู่สินค้า_2: "",
      //   หมวดหมู่สินค้า_3: "",
      //   ราคาควบคุม: n.control_price || 0,
      //   "PRODUCT MANUAL": "",
      //   "ITEM IMAGE": "",
      // };
    });
  };

  const setCellToString = (ws, col) => {
    const range = xlsx.utils.decode_range(ws["!ref"]);
    // note: range.s.r + 1 skips the header row
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = xlsx.utils.encode_cell({ r: row, c: col });

      if (ws[ref]) {
        ws[ref].z = "@";

        xlsx.utils.format_cell(ws[ref]);
      }
    }
  };

  const exportMain = (type = "", data = []) => {
    const wb = xlsx.utils.book_new();
    const dataWS = xlsx.utils.json_to_sheet(mapData(data || []), {
      raw: false,
    });

    for (let col of [1, 19]) {
      setCellToString(dataWS, col);
    }

    xlsx.utils.book_append_sheet(wb, dataWS);

    xlsx.writeFile(
      wb,
      "ITEM_" +
        type?.replace(/ /g, "_")?.replace(/\//g, "_")?.toUpperCase() +
        "_" +
        moment().format("YYYYMMDD_HHmm") +
        ".xlsx"
    );
  };

  const exportKit = (bom = []) => {
    const wb = xlsx.utils.book_new();
    const dataWS = xlsx.utils.json_to_sheet(
      _.map(bom || [], (n) => {
        return {
          "Parent Item": n.product_code,
          Item: n.backend_internal_id,
          "Item Name": n.backend_sku_code + " " + n.product_name_en,
          Description: "", //n.product_description_local,
          Quantity: n.qty,
        };
      })
    );
    xlsx.utils.book_append_sheet(wb, dataWS);

    xlsx.writeFile(wb, "ITEM_KIT_" + moment().format("YYYYMMDD_HHmm") + ".csv");
  };

  const exportAssembly = (data = [], bom = []) => {
    const wb = xlsx.utils.book_new();
    const dataWS = xlsx.utils.json_to_sheet(
      _.map(data || [], (n) => {
        return {
          Name: n.main_name_local,
          "Restrict To Assemblies": n.main_sku,
          Memo: "",
          "Available For All Assemblies": "No",
          "Use Component Yield": "No",
          "Available For All Locations": "Yes",
        };
      })
    );
    xlsx.utils.book_append_sheet(wb, dataWS);

    xlsx.writeFile(wb, "BOM_NAME_" + moment().format("YYYYMMDD_HHmm") + ".csv");

    const wb1 = xlsx.utils.book_new();
    const dataWS1 = xlsx.utils.json_to_sheet(
      _.map(data || [], (n) => {
        return {
          "Bill Of Materials": n.product_name_local,
          Name: n.product_name_local,
          Memo: "",
          "Effective Start Date": "",
          "Effective End Date": "",
        };
      })
    );
    xlsx.utils.book_append_sheet(wb1, dataWS1);

    xlsx.writeFile(
      wb1,
      "BOM_REVISION_" + moment().format("YYYYMMDD_HHmm") + ".csv"
    );

    if (bom.length > 0) {
      const wb2 = xlsx.utils.book_new();
      const dataWS2 = xlsx.utils.json_to_sheet(
        _.map(bom || [], (n) => {
          return {
            Name: n.main_name_local,
            Item: n.backend_internal_id,
            "Item Name": n.backend_sku_code + " " + n.product_name_local,
            "Component Yield": "100%",
            "Bom Quantity": n.qty,
            "Item Source": "Stock",
          };
        })
      );
      xlsx.utils.book_append_sheet(wb2, dataWS2);

      xlsx.writeFile(
        wb2,
        "BOM_COMPONENT_" + moment().format("YYYYMMDD_HHmm") + ".csv"
      );
    }

    const wb3 = xlsx.utils.book_new();
    const dataWS3 = xlsx.utils.json_to_sheet(
      _.map(data || [], (n) => {
        return {
          "Parent Item": n.main_sku,
          Item: n.product_name_local,
        };
      })
    );
    xlsx.utils.book_append_sheet(wb3, dataWS3);

    xlsx.writeFile(
      wb3,
      "UPDATE_ASSEMBLY_" + moment().format("YYYYMMDD_HHmm") + ".csv"
    );
  };

  const handleClickExport = () => {
    if (selectedRowKeys.length <= 0) {
      return notification.warning({
        message: "คำเตือน!",
        description: "กรุณาเลือกรายการที่ต้องการ Export",
        placement: "topRight",
      });
    }

    setExporting(true);

    axios({
      method: "post",
      baseURL: URL_API,
      url: `/setting/master/product-request/export`,
      data: {
        id: selectedRowKeys,
      },
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        setExporting(false);

        let { data = [], bom = [] } = res.data;

        let find_type = _.uniq(_.map(data || [], (n) => n.product_type_set));

        for (let i = 0; i < find_type.length; i++) {
          let type = find_type[i];
          let filter_data = _.filter(
            data || [],
            (n) => n.product_type_set === type
          );

          // if (type === "Assembly") {
          //   filter_data = _.filter(
          //     filter_data,
          //     (n) => n.main_sku === null || n.main_sku === ""
          //   );
          // }

          if (filter_data.length > 0) exportMain(type, filter_data);

          if (type === "Kit/Package") {
            let bom_kit = _.filter(bom || [], (n) =>
              _.map(filter_data, (m) => m.product_code).includes(n.product_code)
            );

            exportKit(bom_kit);
          }

          if (type === "Assembly") {
            let bom_assembly = _.filter(bom || [], (n) => {
              return (
                _.map(filter_data, (m) => m.product_code).includes(
                  n.product_code
                ) &&
                n.main_sku !== null &&
                n.main_sku !== ""
              );
            });

            if (bom_assembly.length > 0) exportAssembly(data, bom_assembly);
          }
        }

        // const wb = xlsx.utils.book_new();
        // const dataWS = xlsx.utils.json_to_sheet(mapData(res.data?.data || []));
        // xlsx.utils.book_append_sheet(wb, dataWS);

        // xlsx.writeFile(
        //   wb,
        //   "ITEM_MASTER_" + moment().format("YYYYMMDD_HHmm") + ".csv"
        // );

        // if ((res.data?.bom || []).length > 0) {
        //   const wb = xlsx.utils.book_new();
        //   const dataWS = xlsx.utils.json_to_sheet(
        //     _.map(res.data?.bom || [], (n) => {
        //       return {
        //         "Parent Item": n.product_code,
        //         Item: n.backend_sku_code,
        //         Description: n.product_name_local,
        //         Quantity: n.qty,
        //       };
        //     })
        //   );
        //   xlsx.utils.book_append_sheet(wb, dataWS);

        //   xlsx.writeFile(
        //     wb,
        //     "ITEM_KIT_" + moment().format("YYYYMMDD_HHmm") + ".csv"
        //   );
        // }

        // let assembly = _.filter(
        //   res.data?.data || [],
        //   (n) => n.product_type_set?.toLowerCase() === "assembly"
        // );

        // if (assembly.length > 0) {
        //   const wb = xlsx.utils.book_new();
        //   const dataWS = xlsx.utils.json_to_sheet(
        //     _.map(assembly || [], (n) => {
        //       return {
        //         "Restrict To Assemblies": n.product_code,
        //         Memo: "",
        //         "Available For All Assemblies": "No",
        //         "Use Component Yield": "No",
        //         "Available For All Locations": "Yes",
        //       };
        //     })
        //   );
        //   xlsx.utils.book_append_sheet(wb, dataWS);

        //   xlsx.writeFile(
        //     wb,
        //     "Bill_of_Materials_" + moment().format("YYYYMMDD_HHmm") + ".csv"
        //   );

        //   const wb1 = xlsx.utils.book_new();
        //   const dataWS1 = xlsx.utils.json_to_sheet(
        //     _.map(assembly || [], (n) => {
        //       return {
        //         "Bill Of Materials": n.product_code,
        //         Name: n.product_name_local,
        //         Memo: "",
        //         "Effective Start Date": "",
        //         "Effective End Date": "",
        //       };
        //     })
        //   );
        //   xlsx.utils.book_append_sheet(wb1, dataWS1);

        //   xlsx.writeFile(
        //     wb,
        //     "Bill_of_Materials_Revision_" +
        //       moment().format("YYYYMMDD_HHmm") +
        //       ".csv"
        //   );

        //   let assembly_bom = _.filter(res.data?.bom || [], (n) =>
        //     _.map(assembly, (n) => n.product_code).includes(n.product_code)
        //   );

        //   if (assembly_bom.length > 0) {
        //     const wb = xlsx.utils.book_new();
        //     const dataWS = xlsx.utils.json_to_sheet(
        //       _.map(assembly_bom || [], (n) => {
        //         return {
        //           Name: n.product_code,
        //           Item: n.backend_sku_code,
        //           Item: n.product_name_local,
        //           "Component Yield": "100%",
        //           "Bom Quantity": n.qty,
        //           "Item Source": "Stock",
        //         };
        //       })
        //     );
        //     xlsx.utils.book_append_sheet(wb, dataWS);

        //     xlsx.writeFile(
        //       wb,
        //       "Bill_of_Materials_Revision_1_" +
        //         moment().format("YYYYMMDD_HHmm") +
        //         ".csv"
        //     );
        //   }
        // }
      })
      .catch((err) => {
        setExporting(false);
      });
  };

  useEffect(() => {
    dispatch(SELECT_ROW_KEYS([]));
  }, [search]);

  return (
    <Panel>
      <PanelHeader>รายการคำขอ</PanelHeader>
      <PanelBody>
        <Row className="mb-4 mt-3">
          <Col span={18} offset={3}>
            <TabUnderLine
              list={tabList}
              active={search?.active_tab || ""}
              onClick={(e) =>
                dispatch(
                  SET_SEARCH({
                    active_tab: e,
                    page: 1,
                    distribution_type: "",
                    product_type_set: "",
                    start_date: null,
                    end_date: null,
                    query: "",
                    department_id: "",
                  })
                )
              }
            />
          </Col>
        </Row>
        {/* {search?.active_tab === "completed" && ( */}
        <Row gutter={16} className="mb-2">
          <Col md={6}>
            <SelectDepartment
              className="w-100"
              withAll
              allText="แผนกทั้งหมด"
              value={search?.department_id}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    page: 1,
                    department_id: e || "",
                  })
                )
              }
              allowClear
            />
          </Col>
          <Col md={18}>
            <Input.Search
              className="w-100"
              placeholder="รหัสสินค้า, ชื่อสินค้า, Item Code"
              onSearch={(e) =>
                dispatch(
                  SET_SEARCH({
                    query: e || "",
                  })
                )
              }
              allowClear
            />
          </Col>
        </Row>
        <Row gutter={16} className="mb-2">
          <Col md={6}>
            <SelectAllType
              process_type="distribution_type"
              className="w-100"
              withAll
              allText="รูปแบบการขายทั้งหมด"
              value={search?.distribution_type}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    page: 1,
                    distribution_type: e || "",
                  })
                )
              }
              allowClear
            />
          </Col>
          <Col md={6}>
            <SelectItemType
              className="w-100"
              allText="รูปแบบสินค้าทั้งหมด"
              value={search?.product_type_set}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    page: 1,
                    product_type_set: e || "",
                  })
                )
              }
              allowClear
            />
          </Col>
          <Col md={6}>
            <DatePicker
              className="w-100"
              placeholder="วันที่ขอตั้งแต่"
              format="DD/MM/YYYY"
              value={search?.start_date}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    page: 1,
                    start_date: e,
                  })
                )
              }
            />
          </Col>
          <Col md={6}>
            <DatePicker
              className="w-100"
              placeholder="ถึงวันที่"
              format="DD/MM/YYYY"
              value={search?.end_date}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    page: 1,
                    end_date: e,
                  })
                )
              }
            />
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col md={8}>
            <Form.Item
              label="รูปแบบการขาย"
              labelCol={{ md: 8 }}
              wrapperCol={{ md: 16 }}
              className="mb-3"
            >
              <Select
                className="w-100"
                value={search?.distribution_type}
                onChange={(e) =>
                  dispatch(
                    SET_SEARCH({
                      page: 1,
                      distribution_type: e,
                    })
                  )
                }
              >
                <Select.Option value="">ทั้งหมด</Select.Option>
                <Select.Option value="sale">สินค้าสำหรับขาย</Select.Option>
                <Select.Option value="redeem">สินค้าสำหรับแลก</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="รูปแบบสินค้า"
              labelCol={{ md: 8 }}
              wrapperCol={{ md: 16 }}
              className="mb-3"
            >
              <SelectItemType
                className="w-100"
                allText="ทั้งหมด"
                value={search?.product_type_set}
                onChange={(e) =>
                  dispatch(
                    SET_SEARCH({
                      page: 1,
                      product_type_set: e,
                    })
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row> */}
        {/* )} */}
        <Row gutter={16} className="mb-2">
          <Col md={12}>
            {search?.active_tab === "draft" && (
              <Button
                type="primary"
                onClick={() => history.push(`${match.path}/0`)}
              >
                เพิ่มใหม่
              </Button>
            )}
          </Col>

          {search?.active_tab === "completed" && (
            <Col md={12} className="text-right">
              <Button
                type="dashed"
                onClick={handleClickExport}
                loading={exporting}
              >
                {`ส่งออกข้อมูลที่เลือก ${
                  selectedRowKeys.length > 0
                    ? `${selectedRowKeys.length} รายการ`
                    : ""
                }`}
              </Button>
            </Col>
          )}
        </Row>
        <Table
          size="small"
          bordered
          loading={list.loading}
          rowKey="id"
          dataSource={list.fetch?.data || []}
          pagination={{
            size: "default",
            current: search?.page,
            total: list?.fetch?.total || 0,
            onChange: (p) => dispatch(SET_SEARCH({ page: p })),
            pageSize: search.limit,
            showLessItems: true,
          }}
          rowSelection={
            search?.active_tab === "completed"
              ? {
                  selectedRowKeys,
                  onChange: (rowsSelected) =>
                    dispatch(SELECT_ROW_KEYS(rowsSelected)),
                }
              : null
          }
        >
          <Table.Column
            title="#"
            align="right"
            key="no"
            render={(text, record, index) => index + 1}
          />
          {search?.active_tab === "completed" ? (
            <Table.Column
              title="รหัสสินค้า Oracle"
              dataIndex="backend_sku_code"
              className="text-nowrap"
            />
          ) : null}
          {search?.active_tab === "completed" ? (
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              className="text-nowrap"
              render={(text, { backend_sku_code, interface_flag }) => (
                <>
                  {text}
                  {interface_flag === 0 && (
                    <small className="d-block">
                      <strong
                        className={
                          backend_sku_code ? "text-danger" : "ant-btn-link"
                        }
                      >
                        {backend_sku_code ? "EDIT" : "NEW"}
                      </strong>
                    </small>
                  )}
                </>
              )}
            />
          ) : null}
          <Table.Column
            title={
              <>
                ชื่อสินค้า
                <br />
                รูปแบบการขาย
              </>
            }
            dataIndex="product_name_local"
            width="50%"
            render={(text, record) => (
              <>
                {text}
                <br />
                <small>
                  รูปแบบการขาย:{" "}
                  <strong>{record?.distribution_type_name}</strong>
                </small>
              </>
            )}
          />
          <Table.Column
            title="วันที่"
            dataIndex="create_date"
            className="text-nowrap"
            render={(value, record) => (
              <>
                <small>
                  วันที่ขอ:{" "}
                  <strong>{moment(value).format("DD/MM/YYYY HH:mm")}</strong>
                </small>

                <br />
                <small>
                  วันที่อนุมัติ:{" "}
                  <strong className={record.approve_date ? "ant-btn-link" : ""}>
                    {record.approve_date
                      ? moment(record.approve_date).format("DD/MM/YYYY HH:mm")
                      : "-"}
                  </strong>
                </small>
              </>
            )}
          />
          <Table.Column
            title="ข้อมูลผู้ขอ"
            dataIndex="create_user"
            className="text-nowrap"
            render={(text, record) => (
              <>
                {text}
                <br />
                <small>
                  แผนก: <strong>{record?.department_name}</strong>
                </small>
              </>
            )}
          />
          <Table.Column
            title="สถานะ"
            align="center"
            dataIndex="status"
            render={(value, { flow_status, waiting, reject_date }) => {
              let tagConfig = {
                children: "เอกสารร่าง",
              };

              if (
                value?.toLowerCase() === "pending" &&
                flow_status?.toLowerCase() === "request"
              ) {
                tagConfig = {
                  color: "blue",
                  children: "รออนุมัติเอกสาร",
                };
              } else if (
                value?.toLowerCase() === "pending" &&
                flow_status?.toLowerCase() === "approve1"
              ) {
                tagConfig = {
                  color: "green",
                  children: "อนุมัติ 1",
                };
              } else if (
                value?.toLowerCase() === "pending" &&
                flow_status?.toLowerCase() === "approve2"
              ) {
                tagConfig = {
                  color: "orange",
                  children: "อนุมัติ 2",
                };
              } else if (
                value?.toLowerCase() === "pending" &&
                flow_status?.toLowerCase() === "approve3"
              ) {
                tagConfig = {
                  color: "orange",
                  children: "อนุมัติ 3",
                };
              } else if (
                value?.toLowerCase() === "completed" &&
                flow_status?.toLowerCase() === "approve"
              ) {
                tagConfig = {
                  color: "green",
                  children: "อนุมัติ",
                };
              } else if (
                value?.toLowerCase() === "completed" &&
                flow_status?.toLowerCase() === "reject"
              ) {
                tagConfig = {
                  color: "red",
                  children: "ไม่อนุมัติ",
                };
              } else if (value?.toLowerCase() === "cancel") {
                tagConfig = {
                  color: "red",
                  children: "ยกเลิกเอกสาร",
                };
              } else if (reject_date != null && value === "draft") {
                tagConfig = {
                  color: "red",
                  children: "ถูกปฎิเสธ",
                };
              }
              return (
                <>
                  <Tag {...tagConfig} />
                  {waiting && waiting.length > 0 && (
                    <p className="mb-0">
                      <small>
                        รอทำการอนุมัติโดย:{" "}
                        {_.map(
                          waiting,
                          (n) => n.first_name + " " + n.last_name
                        ).join(",")}
                      </small>
                    </p>
                  )}
                </>
              );
            }}
          />
          <Table.Column
            align="center"
            dataIndex="id"
            render={(value) => (
              <Button
                type="link"
                size="small"
                onClick={() => history.push(`${match.url}/${value}`)}
              >
                รายละเอียด
              </Button>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default RequestList;
