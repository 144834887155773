/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";
import _, { values } from "lodash";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectAllType from "../../../../../components/SelectAllType";
import { actionEthics, foundMarried } from "../../../../../redux/slices/ethics";
import { URL_API, getToken } from "../../../../../utils";
import SelectGender from "../SelectGender";
import SelectNationality from "../SelectNationality";
import SelectPrefixEn from "../SelectPrefixEn";
import SelectPrefixTh from "../SelectPrefixTh";
import { axios } from "../../../../../utils/useAxios";
import { useHistory } from "react-router-dom";

function FormMember({ form, data_type }, ref) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [DisableIdCard, setDisableIdCard] = useState(false);
  const { loading, member, married, id, type, is_company, address } =
    useSelector((state) => state.ethics.form, shallowEqual);
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
  const [isAvatar, setAvatar] = useState("");
  const [isIdCard, setIsIdCard] = useState(false);
  const marriedTempAvatar = useSelector(
    (state) => state.ethics.form.temp_url,
    shallowEqual
  );
  const partnerCodeMarried = useSelector(
    (state) => state.ethics.findMarried,
    shallowEqual
  );
  const firstName = partnerCodeMarried
    ? partnerCodeMarried.info.first_name_th
    : married?.first_name_th;
  const surName = partnerCodeMarried
    ? partnerCodeMarried.info.sur_name_th
    : married?.sur_name_th;
  const secondaryCode = partnerCodeMarried
    ? partnerCodeMarried.info.partner_code
    : married?.member_code;
  const main = married?.main_partner_code;
  const notRequireIdCard = member?.not_require_id_card
    ? member?.not_require_id_card
    : false;

  const {
    SET_FORM_MARRIED,
    SET_FORM_AVATAR,
    SET_MARRIED_STATUS,
    SET_IS_COMPANY,
    SET_MARRIED_TEMP_AVATAR,
    SET_LOAD_FORM_SUCCESS,
    SET_LOAD_FORM,
    CLEAR_FORM_MARRIED,
    CLEAR_FORM_ADDRESS,
    SET_FORM_MEMBER,
    DELETE_FORM_BANK,
    SET_FORM_ADDRESS,
    SET_COMPANY_ADDRESS,
    SET_SEARCH,
    SET_LOAD_LIST,
  } = actionEthics;

  const { getFieldDecorator, getFieldValue } = form;

  const handelCompany = async (e) => {
    if (
      e.target.value != 1 &&
      (member?.company_name ||
        member?.company_name_th ||
        member?.company_name_en ||
        member?.company_tax)
    ) {
      await Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "คุณต้องการลบข้อมูลนิติบุคคลหรือไม่",
        okText: "ตกลง",
        cancelText: "ยกเลิก",
        onOk() {
          dispatch(
            SET_FORM_MEMBER({
              ...member,
              company_name: undefined,
              company_name_th: undefined,
              company_name_en: undefined,
              company_register_vat: undefined,
              company_tax: undefined,
              company_type: "",
              company_type_text: undefined,
              is_company: e.target.value,
            })
          );
          dispatch(DELETE_FORM_BANK({ eft_type: "Company" }));
          dispatch(SET_COMPANY_ADDRESS());
          //dispatch(SET_IS_COMPANY(e.target.value));
          form.setFieldsValue({ is_company: e.target.value });
        },
        onCancel() {
          form.setFieldsValue({ is_company: 1 });
        },
      });
    } else {
      form.setFieldsValue({ is_company: e.target.value });
      dispatch(SET_FORM_MEMBER({ is_company: e.target.value }));
      dispatch(SET_IS_COMPANY(e.target.value));
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsIdCard(isChecked ? true : false);
    if (isChecked) {
      form.setFieldsValue({
        id_card: "",
        id_card_expire_date: "",
      });
      setIsIdCard(true);
    } else {
      form.setFieldsValue({
        ..._.pick(member, ["id_card", "id_card_expire_date"]),
        id_card_expire_date: member?.id_card_expire_date
          ? moment(member?.id_card_expire_date, "DD/MM/YYYY").isValid()
            ? moment(member?.id_card_expire_date, "DD/MM/YYYY")
            : null
          : null,
      });
      setIsIdCard(false);
    }
  };

  const handleDeleteMarried = async () => {
    if (!married.id) {
      return Modal.warning({
        title: "ไม่สามารถทำรายการได้",
        content: `ไม่มีข้อมูลผู้สมัครร่วม กรุณาเพิ่มข้อมูลแล้วทำการบันทึก`,
        okText: "ปิด",
      });
    }

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการยกเลิกข้อมูลผู้สมัครร่วมหรือไม่",
      okText: "ตกลง",
      cancelText: "ปิด",

      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await axios.post(`/ethics/deletemarried/`, {
              member: { ...member, type: type },
              married: married,
            });
            resolve(response.data);
          } catch (error) {
            reject(error.response?.data || error.message);
          }
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                //loadData();
                history.goBack();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    dispatch(actionEthics.RESET_FORM_STATE());
  }, [dispatch]);
  useEffect(() => {
    if (!loading) {
      form.resetFields();
      if (data_type === "member" && member != null) {
        form.setFieldsValue({
          ..._.pick(member, [
            "prefix",
            "first_name_th",
            "sur_name_th",
            "first_name_en",
            "sur_name_en",
            "nationality",
            "id_card",
            "passport_id",
            "gender",
            "mobile",
            "email",
            "facebook",
            "line_id",
            "is_company",
            "company_type",
            "company_name",
            "company_name_en",
            "company_tax",
            "birth_date",
            "id_card_expire_date",
            "passport_expire_date",
            "company_register_vat",
            "member_code",
            "is_main",
            "main",
            "not_require_id_card",
          ]),
          company_register_vat: member?.company_register_vat === 1,
          birth_date: member?.birth_date ? moment(member?.birth_date) : null,
          id_card_expire_date: member?.id_card_expire_date
            ? moment(member?.id_card_expire_date)
            : null,
          passport_expire_date: member?.passport_expire_date
            ? moment(member?.passport_expire_date)
            : null,
        });
      }
      if ((data_type === "married" && married != null) || isAvatar) {
        form.setFieldsValue({
          ..._.pick(married, [
            "prefix",
            "first_name_th",
            "sur_name_th",
            "first_name_en",
            "sur_name_en",
            "nationality",
            "id_card",
            "passport_id",
            "gender",
            "mobile",
            "email",
            "facebook",
            "line_id",
            "is_company",
            "company_type",
            "company_name",
            "company_name_en",
            "company_tax",
            "status",
            "birth_date",
            "id_card_expire_date",
            "passport_expire_date",
            "avatar",
            "member_code",
          ]),

          // company_register_vat: married.company_register_vat === 1,
          checked: married?.status
            ? married?.status == "Active"
              ? true
              : false
            : false,
          birth_date: married?.birth_date ? moment(married?.birth_date) : null,
          id_card_expire_date: married?.id_card_expire_date
            ? moment(married?.id_card_expire_date)
            : null,
          passport_expire_date: married?.passport_expire_date
            ? moment(married?.passport_expire_date)
            : null,
        });
      }
    }
  }, [loading, data_type, is_company]);

  useEffect(() => {
    if (getFieldValue("not_require_id_card")) {
      setIsIdCard(true);
    } else {
      setIsIdCard(false);
    }
    form.setFieldsValue({
      is_main: main === secondaryCode ? 1 : 0,
    });

    if (
      is_company != 1 &&
      (member?.company_name == "" ||
        member?.company_name == null ||
        member?.company_name == undefined)
    ) {
      dispatch(DELETE_FORM_BANK({ eft_type: "Company" }));
      // let member_address = _.find(address, (n) => n.address_type == "1")O;
      // if (member_address) {
      //   dispatch(
      //     SET_FORM_ADDRESS({
      //       ...member_address,
      //       address_type: 4,
      //       address_type_text: "ที่อยู่สำหรับออกใบกำกับภาษี",
      //     })
      //   );
      // }
    }
  }, [is_company, main, secondaryCode]);

  const normFile = (e) => {
    if (!e || !e.fileList) {
      return e;
    }

    if (Array.isArray(e)) {
      return e;
    }

    return e.fileList.slice(-1);
  };

  const onChangePicture = async (info) => {
    if (info.file.status === "done") {
      setIsAvatarLoaded(false);
      if (!married) {
        if (data_type == "married") {
          await dispatch(SET_MARRIED_TEMP_AVATAR(info.file?.response?.url));
        } else {
          dispatch(
            SET_FORM_AVATAR({ url: info.file?.response?.url, data_type })
          );
        }
      } else {
        dispatch(SET_FORM_AVATAR({ url: info.file?.response?.url, data_type }));
      }
    }
  };

  const selectMain = async (e, index) => {
    const partnerCode = e == 0 ? secondaryCode : getFieldValue("member_code");

    try {
      const response = await axios.post("/ethics/selectmain", {
        partner_code: partnerCode,
      });

      if (response.data === true) {
        Modal.error({
          title: "ผิดพลาด",
          content: "ไม่สามารถ Freeze รหัสที่มีคะแนนหรือสิทธิสมัครเหลืออยู่",
          okText: "ปิด",
        });
        form.setFieldsValue({ is_main: e == 0 ? 1 : 0 });
      }
    } catch (e) {
      message.error("ไม่พบข้อมูล");
    }
  };

  const handleAvatarLoad = () => {
    setIsAvatarLoaded(true);
  };
  const handelMarried = (e) => {
    if (e.target.checked) {
      dispatch(SET_MARRIED_STATUS("Active"));
    } else {
      dispatch(SET_MARRIED_STATUS("Pending"));
    }
  };

  const handleChangeForm = (values) => {
    if (values?.nationality) {
      setIsIdCard(false);
    }
    if (data_type === "married") {
      dispatch(SET_FORM_MARRIED(values));
    } else {
      dispatch(SET_FORM_MEMBER(values));
    }
  };

  const searchMarried = () => {
    setIsSearching(true);

    form
      .validateFields()
      .then((values) => {
        let married = values.partner_code;

        if (member?.status !== "Active") {
          Modal.error({
            title: "ผิดพลาด",
            content: "ไม่สามารถค้นหาได้เนื่องจากรหัสหลักสถานะไม่ใช่ Active",
            okText: "ปิด",
          });
          return;
        }

        if (married === member.member_code) {
          // message.error("ไม่สามารถค้นหารหัสสมาชิกตัวเองได้");
          Modal.error({
            title: "ผิดพลาด",
            content: "ไม่สามารถค้นหารหัสสมาชิกตัวเองได้",
            okText: "ปิด",
          });
          return;
        }

        findMarried(married);
      })
      .finally(() => {});
    setIsSearching(false);
  };
  const findMarried = async (married) => {
    try {
      const { data } = await axios.get("/ethics/findmarried", {
        params: {
          partner_code: married,
        },
      });

      if (data.statusProfile !== "Active") {
        Modal.error({
          title: "ผิดพลาด",
          content: "ไม่สามารถค้นหาได้เนื่องจากรหัสร่วมสถานะไม่ใช่ Active",
          okText: "ปิด",
        });
        return;
      } else if (data.find == 0) {
        Modal.error({
          title: "ผิดพลาด",
          content: "รหัสที่ค้นหามีคู่ร่วมอยู่แล้ว",
          okText: "ปิด",
        });
        return;
      }
      // else if (data.point == 0) {
      //   Modal.error({
      //     title: "ผิดพลาด",
      //     content:
      //       "รหัสที่ค้นหาอาจมียอดPVพร้อมโอน, สิทธิ์สมัคร หรือRPพร้อมโอนคงเหลือ",
      //     okText: "ปิด",
      //   });
      //   return;
      // }

      const info = data?.info[0];
      const infoAddress = data?.address[0];
      form.setFieldsValue({
        ..._.pick(info, [
          "prefix",
          "first_name_th",
          "sur_name_th",
          "first_name_en",
          "sur_name_en",
          "nationality",
          "id_card",
          "passport_id",
          "gender",
          "mobile",
          "email",
          "facebook",
          "line_id",
          "is_company",
          "company_type",
          "company_name",
          "company_name_en",
          "company_tax",
          "status",
          "birth_date",
          "id_card_expire_date",
          "passport_expire_date",
          "avatar",
          "id",
        ]),

        // company_register_vat: married.company_register_vat === 1,
        id: info.id,
        avatar: info.avatar,
        checked: info?.status
          ? info?.status == "Active"
            ? true
            : false
          : false,
        birth_date: info?.birth_date ? moment(info?.birth_date) : null,
        id_card_expire_date: info?.id_card_expire_date
          ? moment(info?.id_card_expire_date)
          : null,
        passport_expire_date: info?.passport_expire_date
          ? moment(info?.passport_expire_date)
          : null,
      });
      dispatch(actionEthics.SET_FOUND_MARRIED({ info, infoAddress }));
      setIsAvatarLoaded(false);
      setAvatar(info?.avatar);
    } catch (e) {
      console.log(e.message);
      // form.setFieldsValue({
      //   first_name_th: null,
      //   sur_name_th: null,
      //   first_name_en: null,
      //   sur_name_en: null,
      //   id_card: null,
      //   id_card_expire_date: null,
      //   passport_id: null,
      //   passport_expire_date: null,
      //   gender: null,
      //   mobile: null,
      //   email: null,
      //   facebook: null,
      //   line_id: null,
      // });
      // message.error("ไม่พบข้อมูล");
      // Modal.error({
      //   title: "ผิดพลาด",
      //   content: "ไม่พบข้อมูล",
      //   okText: "ปิด",
      // });
    }
  };

  return (
    <Form
      layout="horizontal"
      wrapperCol={{ md: 14 }}
      labelCol={{ md: 10 }}
      labelAlign="right"
      className="form-small"
      form={form}
    >
      <Form.Item label="รูปโปรไฟล์" className="mb-2">
        <div className="flex flex-row">
          {getFieldDecorator("avatar", {
            valuePropName: "fileList",
            getValueFromEvent: normFile,
          })(
            <div className="d-flex align-items-center">
              <Upload
                listType="picture-card"
                accept=".jpg,.png"
                showUploadList={false}
                action={`${URL_API}/pos/${
                  type === "member" ? "vip-member" : "ethics"
                }/${id}/image/?type=${data_type === "member" ? "14" : "42"}`}
                headers={{
                  Authorization: "Bearer " + getToken(),
                }}
                onChange={onChangePicture}
              >
                {isAvatar ? (
                  <img
                    src={isAvatar}
                    alt="avatar"
                    className="w-100"
                    onLoad={handleAvatarLoad}
                  />
                ) : data_type === "member" && member?.avatar ? (
                  <img
                    src={member?.avatar}
                    alt="avatar"
                    className="w-100"
                    onLoad={handleAvatarLoad}
                  />
                ) : data_type === "married" && married?.avatar ? (
                  <img
                    src={married?.avatar}
                    alt="avatar"
                    className="w-100"
                    onLoad={handleAvatarLoad}
                  />
                ) : data_type === "married" && marriedTempAvatar ? (
                  <img
                    src={marriedTempAvatar}
                    alt="avatar"
                    className="w-100"
                    onLoad={handleAvatarLoad}
                  />
                ) : (
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">Upload</div>
                  </div>
                )}
              </Upload>
              <div className="mb-0 ml-2">
                {((data_type !== "married" && married && secondaryCode) ||
                  (data_type !== "married" && secondaryCode)) && (
                  <Form.Item label="รหัสที่ดำเนินธุรกิจ" className="mb-2">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {getFieldDecorator("is_main", {
                        onChange: (e) => {
                          const selectedValue = e.target.value;
                          let valueToSend;
                          if (selectedValue === 0) {
                            valueToSend = getFieldValue("member_code");
                          } else if (selectedValue === 1) {
                            valueToSend = secondaryCode;
                          }
                          selectMain(selectedValue, valueToSend);
                          dispatch(SET_FORM_MEMBER({ main: valueToSend }));
                        },
                      })(
                        <Radio.Group>
                          <Radio value={0}>
                            {getFieldDecorator("member_code", {
                              initialValue: member?.member_code,
                            })(
                              <Input
                                size="small"
                                className="font-size-12 flex-grow"
                                style={{ width: "70%" }}
                              />
                            )}
                          </Radio>
                          <span className="ml-4">
                            {getFieldValue("first_name_th") +
                              " " +
                              getFieldValue("sur_name_th")}
                          </span>
                          <br></br>
                          <Radio value={1}>
                            <Input
                              size="small"
                              className="font-size-12 flex-grow"
                              name="secondary_code"
                              style={{ width: "70%" }}
                              value={secondaryCode}
                            />
                          </Radio>
                          <span className="ml-4">
                            {firstName + " " + surName}
                          </span>
                        </Radio.Group>
                      )}
                    </div>
                  </Form.Item>
                )}
              </div>
            </div>
          )}
        </div>
      </Form.Item>

      {data_type === "married" && (
        <Form.Item label="รหัสสมาชิกร่วม" className="mb-2 flex">
          {getFieldDecorator("partner_code", {
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (value && value.trim() !== "") {
                    if (!value) {
                      callback("ไม่สามารถค้นหารหัสตัวเองได้");
                    } else {
                      callback();
                    }
                  } else {
                    callback();
                  }
                },
              },
            ],
          })(
            <Input
              size="small"
              className="font-size-12 flex-grow w-50"
              onPressEnter={searchMarried}
            />
          )}
          <Button
            type="primary"
            htmlType="submit"
            onClick={searchMarried}
            className="ml-2"
          >
            ค้นหา
          </Button>
        </Form.Item>
      )}

      <Form.Item label="คำนำหน้า" className="mb-2">
        {getFieldDecorator("prefix", {
          initialValue: null,
          rules: [{ required: true, message: "กรุณาระบุคำนำหน้า" }],
          onChange: (value) => handleChangeForm({ prefix: value }),
        })(
          <SelectPrefixTh
            size="small"
            className="w-50 font-size-12"
            placeholder="คำนำหน้า"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="ชื่อ" className="mb-2">
        {getFieldDecorator("first_name_th", {
          initialValue: null,
          rules: [
            {
              required: getFieldValue("nationality") === "TH",
              asyncValidator: (rule, value) => {
                return new Promise((resolve, reject) => {
                  if (data_type === "married") {
                    resolve();
                    return;
                  }
                  if (!value || value.trim() === "") {
                    reject("กรุณาระบุชื่อ");
                  }

                  if (value.length > 64) {
                    reject("ต้องไม่เกิน 64 ตัวอักษร");
                  }

                  const regex = /^(?!.*[฿๏ํฺ])[\u0E00-\u0E7Fa-zA-Z\s]+$/g;
                  if (!regex.test(value)) {
                    reject(
                      "สามารถใส่ได้เฉพาะตัวอักษรภาษาไทย ภาษาอังกฤษ และเว้นวรรคเท่านั้น"
                    );
                  }

                  resolve();
                });
              },
            },
          ],

          onChange: (e) => handleChangeForm({ first_name_th: e.target.value }),
        })(
          <Input
            size="small"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="นามสกุล" className="mb-2">
        {getFieldDecorator("sur_name_th", {
          initialValue: null,
          rules: [
            {
              required: getFieldValue("nationality") === "TH",
              asyncValidator: (rule, value) => {
                return new Promise((resolve, reject) => {
                  if (data_type === "married") {
                    resolve();
                    return;
                  }
                  if (!value || value.trim() === "") {
                    reject("กรุณาระบุนามสกุล");
                    return;
                  }

                  if (value.length > 64) {
                    reject("ต้องไม่เกิน 64 ตัวอักษร");
                    return;
                  }
                  const regex = /^(?!.*[฿๏ํฺ])[\u0E00-\u0E7Fa-zA-Z\s]+$/g;
                  if (!regex.test(value)) {
                    reject(
                      "สามารถใส่ได้เฉพาะตัวอักษรภาษาไทย ภาษาอังกฤษ และเว้นวรรคเท่านั้น"
                    );
                    return;
                  }

                  resolve();
                });
              },
            },
          ],
          onChange: (e) => handleChangeForm({ sur_name_th: e.target.value }),
        })(
          <Input
            size="small"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>

      <Form.Item label="Prefix Name" className="mb-2">
        {getFieldDecorator("prefix", {
          rules: [
            {
              required:
                type === "member" && getFieldValue("nationality") !== "TH",
              message: "กรุณาระบุคำนำหน้า",
            },
          ],
          initialValue: null,
          onChange: (value) => handleChangeForm({ prefix: value }),
        })(
          <SelectPrefixEn
            size="small"
            className="w-50 font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="First Name" className="mb-2">
        {getFieldDecorator("first_name_en", {
          initialValue: null,
          rules: [
            {
              required: getFieldValue("nationality") !== "TH" && isSearching,
              message: "กรุณาระบุ First Name",
            },
            {
              asyncValidator: (rule, value) => {
                console.log(type)
                console.log(getFieldValue("nationality"))
                return new Promise((resolve, reject) => {
                  if (data_type === "married" || ( type ==='member' && getFieldValue("nationality") =="TH")) {
                    resolve();
                    return;
                  }
                  if (!value || value.trim() === "") {
                    reject("กรุณาระบุ First Name");
                  }

                  if (value.length > 64) {
                    reject("ต้องไม่เกิน 64 ตัวอักษร");
                  }

                  const regex = /^(?!.*[฿๏ํฺ])[a-zA-Z\s]+$/g;
                  if (!regex.test(value)) {
                    reject(
                      "สามารถใส่ได้เฉพาะตัวอักษรภาษาอังกฤษ และเว้นวรรคเท่านั้น"
                    );
                  }

                  resolve();
                });
              },
            },
          ],

          onChange: (e) => handleChangeForm({ first_name_en: e.target.value }),
        })(
          <Input
            size="small"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="Last Name" className="mb-2">
        {getFieldDecorator("sur_name_en", {
          initialValue: null,
          rules: [
            {
              required: getFieldValue("nationality") !== "TH" && isSearching,
              message: "กรุณาระบุ Last Name",
            },
            {
              asyncValidator: (rule, value) => {
                return new Promise((resolve, reject) => {
                  if (data_type === "married" || ( type ==='member' && getFieldValue("nationality") =="TH")) {
                    resolve();
                    return;
                  }
                  if (!value || value.trim() === "") {
                    reject("กรุณาระบุ Last Name");
                  }

                  if (value.length > 64) {
                    reject("ต้องไม่เกิน 64 ตัวอักษร");
                  }

                  const regex = /^(?!.*[฿๏ํฺ])[a-zA-Z\s]+$/g;
                  if (!regex.test(value)) {
                    reject(
                      "สามารถใส่ได้เฉพาะตัวอักษรภาษาอังกฤษ และเว้นวรรคเท่านั้น"
                    );
                  }

                  resolve();
                });
              },
            },
          ],
          onChange: (e) => handleChangeForm({ sur_name_en: e.target.value }),
        })(
          <Input
            size="small"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="วัน/เดือน/ปีค.ศ. เกิด" className="mb-2">
        {getFieldDecorator("birth_date", {
          initialValue: null,
          rules: [
            {
              required: isSearching && type === "distributor",
              message: "กรุณาระบุวันเกิด",
            },
          ],
          onChange: (e) =>
            handleChangeForm({ birth_date: e?.format("DD/MM/YYYY") }),
        })(
          <DatePicker
            size="small"
            placeholder="วันเดือนปี(ค.ศ.)"
            format="DD/MM/YYYY"
            showToday={false}
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="สัญชาติ" className="mb-2">
        {getFieldDecorator("nationality", {
          initialValue: null,
          rules: [
            { required: isSearching && true, message: "กรุณาระบุสัญชาติ" },
          ],
          onChange: (value) => {
            handleChangeForm({ nationality: value });
            form.setFieldsValue({ nationality: value });
          },
        })(
          <SelectNationality
            size="small"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      {getFieldValue("nationality") === "TH" && (
        <Form.Item label="เลขบัตรประชาชน" className="mb-2">
          {getFieldDecorator("id_card", {
            initialValue: null,
            onChange: (e) => handleChangeForm({ id_card: e.target.value }),
            rules: [
              {
                required: getFieldValue("nationality") === "TH",

                asyncValidator: (rule, value) => {
                  return new Promise((resolve, reject) => {
                    // if (isIdCard !== 0) {
                    //   resolve();
                    // }
                    if (!rule.required && value?.length === 0) {
                      resolve();
                    }
                    let sum = 0;
                    if (value?.length !== 13) {
                      reject("กรุณาระบุเลขบัตรประชาชน 13 หลัก");
                    }
                    for (let i = 0; i < 12; i++) {
                      sum += parseFloat(value.charAt(i)) * (13 - i);
                    }
                    if (
                      (11 - (sum % 11)) % 10 !==
                      parseFloat(value.charAt(12))
                    ) {
                      reject("เลขบัตรประชาชนไม่ถูกต้อง");
                    } else {
                      resolve();
                    }
                  });
                },
              },
            ],
          })(
            <Input
              size="small"
              placeholder="เลขบัตรประชาชน"
              className="font-size-12"
              maxLength={13}
              disabled={data_type === "married" && secondaryCode}
            />
          )}
        </Form.Item>
      )}
      {getFieldValue("nationality") !== "TH" && (
        <Form.Item label="เลขประจำตัว" className="mb-2">
          {getFieldDecorator("id_card", {
            onChange: (e) => handleChangeForm({ id_card: e.target.value }),
          })(
            <Input
              size="small"
              placeholder="เลขประจำตัว"
              className="font-size-12"
              maxLength={13}
              disabled={
                // getFieldValue("not_require_id_card") ||
                isIdCard || (data_type === "married" && secondaryCode)
              }
            />
          )}
        </Form.Item>
      )}

      <Form.Item label="วันหมดอายุ" className="mb-2">
        {getFieldDecorator("id_card_expire_date", {
          initialValue: null,
          // rules: [
          //   {
          //     required:
          //       getFieldValue("nationality") == "no_status" ||
          //       getFieldValue("nationality") == "none",
          // //     message: "กรุณาระบุวันหมดอายุบัตรประจำตัว",
          //   },
          // ],
          onChange: (value) =>
            handleChangeForm({
              id_card_expire_date: moment(value).format("DD/MM/YYYY"),
            }),
        })(
          <DatePicker
            size="small"
            format="DD/MM/YYYY"
            showToday={false}
            className="font-size-12"
            placeholder="วันหมดอายุบัตรประจำตัว"
            disabled={
              // getFieldValue("not_require_id_card") ||
              isIdCard || (data_type === "married" && secondaryCode)
              //  || isIdCard
            }
          />
        )}
      </Form.Item>
      {(getFieldValue("nationality") === "no_status" ||
        getFieldValue("nationality") === "none") &&
        data_type !== "married" && (
          <Form.Item label="ยังไม่ระบุข้อมูล" className="mb-2">
            {getFieldDecorator("not_require_id_card", {
              // rules: [
              //   {
              //     required:
              //       getFieldValue("nationality") === "no_status" ||
              //       getFieldValue("nationality") === "none",

              //     asyncValidator: (rule, value) => {
              //       return new Promise((resolve, reject) => {
              //         if (value) {
              //           resolve();
              //         } else {
              //           reject("กรุณาติ๊กยังไม่ระบุข้อมูล");
              //         }
              //       });
              //     },
              //   },
              // ],
              initialValue: null,
              valuePropName: "checked",
              className: "not_require",
              onChange: (e) => handleCheckboxChange(e),
            })(<Checkbox></Checkbox>)}
          </Form.Item>
        )}

      <Form.Item label="เลขพาสปอร์ต" className="mb-2">
        {getFieldDecorator("passport_id", {
          initialValue: null,
          rules: [
            {
              required:
                isSearching &&
                getFieldValue("nationality") !== "TH" &&
                getFieldValue("nationality") !== "no_status" &&
                getFieldValue("nationality") !== "none",

              message: "กรุณาระบุเลขพาสปอร์ต",
            },
          ],
          onChange: (e) => handleChangeForm({ passport_id: e.target.value }),
        })(
          <Input
            size="small"
            placeholder="เลขพาสปอร์ต"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="วันหมดอายุ" className="mb-2">
        {getFieldDecorator("passport_expire_date", {
          initialValue: null,
          // rules: [
          //   {
          //     required:
          //       (isSearching &&
          //         getFieldValue("nationality") !== "TH" &&
          //         getFieldValue("nationality") !== "no_status" &&
          //         getFieldValue("nationality") !== "none" &&
          //         type === "distributor") ||
          //       (getFieldValue("passport_idpassport_id")?.length > 0 &&
          //         type === "distributor"),
          //     message: "กรุณาระบุวันหมดอายุพาสปอร์ต",
          //   },
          // ],
          onChange: (value) =>
            handleChangeForm({
              passport_expire_date: value?.format("DD/MM/YYYY"),
            }),
        })(
          <DatePicker
            size="small"
            format="DD/MM/YYYY"
            showToday={false}
            className="font-size-12"
            placeholder="วันหมดอายุพาสปอร์ต"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="เพศ" className="mb-2">
        {getFieldDecorator("gender", {
          initialValue: null,
          rules: [
            {
              required: isSearching && type === "distributor",
              message: "กรุณาระบุเพศ",
            },
          ],
          onChange: (value) => handleChangeForm({ gender: value }),
        })(
          <SelectGender
            size="small"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="โทรศัพท์" className="mb-2">
        {getFieldDecorator("mobile", {
          initialValue: null,
          rules: [
            // {
            //   required: getFieldValue("nationality") === "TH",
            //   message: "กรุณาระบุโทรศัพท์",
            // },
            // {
            //   pattern: /^0([6,8-9][0-9]{8})?$/,
            //   message: "รูปแบบโทรศัพท์ไม่ถูกต้อง",
            // },
          ],
          onChange: (e) => handleChangeForm({ mobile: e.target.value }),
        })(
          <Input
            size="small"
            placeholder="เบอร์โทร"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="อีเมล" className="mb-2">
        {getFieldDecorator("email", {
          initialValue: null,
          rules: [
            {
              required:
                type === "member" && getFieldValue("nationality") !== "TH",
              message: "กรุณาระบุอีเมล",
            },
            {
              pattern: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}\s*$/,
              message: "รูปแบบอีเมลไม่ถูกต้อง",
            },
          ],
          onChange: (e) => {
            handleChangeForm({ email: e.target.value.trim() });
            form.setFieldsValue({ email: e.target.value.trim() });
          },
        })(
          <Input
            size="small"
            placeholder="อีเมล"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="Facebook" className="mb-2">
        {getFieldDecorator("facebook", {
          initialValue: null,
          onChange: (e) =>
            handleChangeForm({ facebook: e.target.value.trim() }),
        })(
          <Input
            size="small"
            placeholder="Facebook"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      <Form.Item label="Line" className="mb-2">
        {getFieldDecorator("line_id", {
          initialValue: null,
          onChange: (e) => handleChangeForm({ line_id: e.target.value.trim() }),
        })(
          <Input
            size="small"
            placeholder="Line ID"
            className="font-size-12"
            disabled={data_type === "married" && secondaryCode}
          />
        )}
      </Form.Item>
      {data_type === "married" && married && (
        // <Form.Item label="เอกสาร" name="married-document" className="mb-2">
        //   <Checkbox
        //     //style={{float:"right"}}
        //     defaultChecked={form.getFieldValue('status') == "Active"}
        //     onChange={(e) => handelMarried(e)}
        //   >
        //     เอกสารครบและผ่านการอนุมัติ
        //   </Checkbox>
        // </Form.Item>

        <Form.Item label="เอกสาร" name="married-document" className="mb-2">
          {getFieldDecorator("checked", {
            initialValue: null,
            valuePropName: "checked",
            onChange: (e) => handelMarried(e),
          })(
            <Checkbox disabled={data_type === "married" && secondaryCode}>
              เอกสารครบและผ่านการอนุมัติ
            </Checkbox>
          )}
        </Form.Item>
      )}
      {data_type === "married" && married && (
        <Button
          size="small"
          shape="round"
          type="danger"
          ghost
          style={{ float: "right" }}
          onClick={handleDeleteMarried}
        >
          ยกเลิก
        </Button>
      )}
      {data_type === "member" && (
        <>
          <Form.Item label="คำนวณภาษีมูลค่าเพิ่ม" className="mb-2">
            {getFieldDecorator("company_register_vat", {
              initialValue: null,
              valuePropName: "checked",
            })(<Switch />)}
          </Form.Item>
          <Form.Item label="ประเภท" className="mb-2">
            {getFieldDecorator("is_company")(
              <Radio.Group
                size="small"
                className="font-size-12"
                onChange={handelCompany}
              >
                <Radio value={0}>บุคคลธรรมดา</Radio>
                <Radio value={1}>นิติบุคคล</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {member?.is_company == 1 && (
            <>
              <Form.Item label="ประเภทธุรกิจ" className="mb-2">
                {getFieldDecorator("company_type", {
                  initialValue: null,
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุประเภทธุรกิจ",
                    },
                  ],
                  onChange: (e, option) => {
                    dispatch(
                      SET_FORM_MEMBER({
                        company_type: e,
                        company_type_text: option.props.children,
                      })
                    );
                    //handleChange();
                  },
                })(
                  <SelectAllType
                    size="small"
                    className="w-100 font-size-12"
                    process_type="corporation_type"
                    withAll
                    allText="เลือกประเภทธุรกิจ"
                    allValue={null}
                  />
                )}
              </Form.Item>
              <Form.Item label="ชื่อนิติบุคคล (ภาษาไทย)" className="mb-2">
                {getFieldDecorator("company_name", {
                  initialValue: null,
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุชื่อนิติบุคคล",
                    },
                  ],
                  onChange: (e, option) => {
                    dispatch(
                      SET_FORM_MEMBER({
                        company_name: e.target.value,
                        company_name_th: e.target.value,
                      })
                    );
                    //handleChange();
                  },
                })(
                  <Input
                    size="small"
                    className="font-size-12"
                    placeholder="ชื่อนิติบุคคล (ภาษาไทย)
              "
                  />
                )}
              </Form.Item>
              <Form.Item label="ชื่อนิติบุคคล (ภาษาอังกฤษ)" className="mb-2">
                {getFieldDecorator("company_name_en", {
                  initialValue: null,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "กรุณาระบุชื่อนิติบุคคล",
                  //   },
                  // ],
                  onChange: (e, option) => {
                    dispatch(
                      SET_FORM_MEMBER({
                        company_name_en: e.target.value,
                      })
                    );
                    //handleChange();
                  },
                })(
                  <Input
                    size="small"
                    className="font-size-12"
                    placeholder="ชื่อนิติบุคคล (ภาษาอังกฤษ)"
                  />
                )}
              </Form.Item>
              <Form.Item label="เลขประจำตัวผู้เสียภาษี" className="mb-2">
                {getFieldDecorator("company_tax", {
                  initialValue: null,
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุเลขประจำตัวผู้เสียภาษี",
                    },
                  ],
                  onChange: (e, option) => {
                    dispatch(
                      SET_FORM_MEMBER({
                        company_tax: e.target.value,
                      })
                    );
                    //handleChange();
                  },
                })(
                  <Input
                    size="small"
                    className="font-size-12"
                    placeholder="เลขประจำตัวผู้เสียภาษี"
                  />
                )}
              </Form.Item>
            </>
          )}
        </>
      )}
    </Form>
  );
}

export default Form.create("ethics-member")(forwardRef(FormMember));
