import { Breadcrumb, message } from "antd";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actionEthics } from "../../../redux/slices/ethics";
import { axios } from "../../../utils/useAxios";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import PreviewDocumentModal from "./components/Modal/PreviewDocumentModal";
import SearchPanel from "./components/Panel/SearchPanel";
import TableDistributor from "./components/Table/TableDistributor";
import TableMember from "./components/Table/TableMember";

export default function EthicsList() {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.ethics.search, shallowEqual);
  const { SET_LOAD_LIST, SET_LOAD_LIST_SUCCESS } = actionEthics;

  const loadDataCallback = useCallback(async () => {
    try {
      const url =
        search.member_type === "distributor"
          ? "/pos/ethics"
          : "/pos/vip-member";

      dispatch(SET_LOAD_LIST(true));
      const response = await axios.get(url, {
        params: {
          limit: search.limit,
          offset: (search.page - 1) * search.limit,
          status: search.status,
          display_type: search.display_type,
          query: search.query,
        },
      });

      dispatch(
        SET_LOAD_LIST_SUCCESS({
          data: response.data?.data || [],
          total: response.data?.total || 0,
        })
      );
    } catch (error) {
      message.error(error.response?.data || error.message);
      dispatch(SET_LOAD_LIST(false));
    }
  }, [dispatch, search, SET_LOAD_LIST, SET_LOAD_LIST_SUCCESS]);

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ตรวจสอบเอกสาร</Breadcrumb.Item>
      </Breadcrumb>
      <Panel>
        <PanelHeader>ตรวจสอบเอกสาร</PanelHeader>
        <PanelBody>
          <SearchPanel />
          {search.member_type === "distributor" ? (
            <TableDistributor />
          ) : (
            <TableMember />
          )}
        </PanelBody>
      </Panel>
      <PreviewDocumentModal />
    </>
  );
}
