import { _ } from "../../../../../../utils";
import { isProAmountOnly, isProWithEvery } from "./validate";

// const handlePromotion = async (
//   ids = [],
//   order_type = "",
//   req_receipt_type = "",
//   receipt_branch_code = "",
//   partner_code = "",
//   partner_type = "",
//   unit = {}
// ) => {
//   try {
//     const res = await axios({
//       method: "get",
//       baseURL: URL_API,
//       url: "/pos/master/promotion/detail",
//       params: {
//         id: _.join(ids, ","),
//         partner_code,
//         partner_type,
//         order_type,
//         order_source: "pos",
//         req_receipt_type,
//         receipt_branch_code,
//         ...unit,
//       },
//     });
//     return res.data.data;
//   } catch (err) {
//     resCatchModal(err);
//     return null;
//   }
// };

// const getUnit = ({ itemKeys, itemByKey }) =>
//   _.reduce(
//     itemKeys,
//     (sum, key) => ({
//       price: sum.price + itemByKey[key].qty * itemByKey[key].unit_price,
//       pv: sum.pv + itemByKey[key].qty * itemByKey[key].pv,
//       rp: sum.rp + itemByKey[key].qty * itemByKey[key].rp,
//     }),
//     {
//       price: 0,
//       pv: 0,
//       rp: 0,
//     }
//   );

// const calAmountUsing = (items) =>
//   _.reduce(
//     items,
//     (res, item) => {
//       const { condition_type, item_key, qty } = item;
//       return {
//         amount_used:
//           condition_type === "amount"
//             ? {
//                 ...res.amount_used,
//                 [item_key]: res.amount_used[item_key] + qty,
//               }
//             : res.amount_used,
//         every_amount_used:
//           condition_type === "every-amount"
//             ? {
//                 ...res.every_amount_used,
//                 [item_key]: res.every_amount_used[item_key] + qty,
//               }
//             : res.every_amount_used,
//       };
//     },
//     {
//       amount_used: { price: 0, pv: 0, rp: 0 },
//       every_amount_used: { price: 0, pv: 0, rp: 0 },
//     }
//   );

// async function handleFindMatchPromotion(orders, setMatchingPromotionItem) {
//   const { itemKeys, itemByKey } = orders;
//   let compare = [];
//   for (let i = 0; i < itemKeys.length; i++) {
//     const order = itemByKey[itemKeys[i]];

//     // check order have product item
//     const ids = _.map(
//       order.items.itemKeys,
//       (n) => order.items.itemByKey[n].product_code
//     );
//     if (ids.length === 0) continue;

//     // check coordinate
//     const disabled_promotion = _.some(
//       order.promotions.promotion_list,
//       (item) => item.coordinate === 0
//     );
//     if (disabled_promotion) continue;

//     // get price, pv, rp in order
//     const unit = getUnit(order.item);

//     // load promotion
//     setMatchingPromotionItem({ loading: true });
//     let ret = await handlePromotion(
//       ids,
//       order.order_type,
//       order.req_receipt_type,
//       order.receipt_branch_code,
//       order.partner_code,
//       order.partner_type,
//       unit
//     );
//     setMatchingPromotionItem({ loading: false });

//     if (!ret) continue;

//     // get amount and every amount is used in order
//     const { amount_used, every_amount_used } = calAmountUsing(
//       order.promotions.item_list
//     );

//     // add order qty in promotion condition source
//     _.forEach(ret, (promotion, index_promotion) => {
//       _.forEach(promotion.condition.source, (condition, index_condition) => {
//         _.forEach(condition, (source, index_source) => {
//           if (source.source_key === "item") {
//             const item_key = _.find(
//               order.items.itemKeys,
//               (item_key) =>
//                 order.items.itemByKey[item_key].product_code ===
//                 source.product_code
//             );
//             const order_qty = item_key
//               ? order.items.itemByKey[item_key].qty
//               : 0;
//             const order_used_qty = item_key
//               ? _.find(
//                   order.promotions.item_list,
//                   (item_used) =>
//                     item_used.condition_type === "item" &&
//                     item_used.item_key === source.product_code
//                 )?.qty || 0
//               : 0;
//             ret[index_promotion].condition.source[index_condition][
//               index_source
//             ] = {
//               ...source,
//               order_qty: order_qty - order_used_qty,
//             };
//           } else if (source.source_key === "category") {
//             const items = _.reduce(
//               source.items,
//               (items, item) => {
//                 const not_item = _.every(
//                   condition,
//                   (other_item) => other_item.product_code !== item.product_code
//                 );
//                 const item_key = not_item
//                   ? _.find(
//                       order.items.itemKeys,
//                       (item_key) =>
//                         order.items.itemByKey[item_key].product_code ===
//                         item.product_code
//                     )
//                   : null;
//                 const order_used_qty = item_key
//                   ? _.find(
//                       order.promotions.item_list,
//                       (item_used) =>
//                         item_used.condition_type === "item" &&
//                         item_used.item_key === item.product_code
//                     )?.qty || 0
//                   : 0;
//                 return [
//                   ...items,
//                   {
//                     ...item,
//                     qty:
//                       not_item && item_key
//                         ? order.items.itemByKey[item_key].qty - order_used_qty
//                         : 0,
//                   },
//                 ];
//               },
//               []
//             );
//             const order_qty = _.reduce(items, (res, item) => res + item.qty, 0);
//             ret[index_promotion].condition.source[index_condition][
//               index_source
//             ] = {
//               ...source,
//               items,
//               order_qty,
//             };
//           } else {
//             ret[index_promotion].condition.source[index_condition][
//               index_source
//             ] = {
//               ...source,
//               order_qty:
//                 unit[source.source_key] -
//                 amount_used[source.source_key] -
//                 every_amount_used[source.source_key],
//             };
//           }
//         });
//       });
//     });
//   }
// }

export const calculateQuantity = (condition) => {
  let { type, source } = condition;
  let result = 0;
  if (isProWithEvery(type)) {
    let count = 0;
    while (true) {
      let pass = true;
      for (let source_index = 0; source_index < source.length; source_index++) {
        const { order_qty, qty } = source[source_index];
        if (order_qty < qty) {
          if (pass === true) {
            pass = false;
            count++;
          }
        }
        source[source_index].order_qty -= qty;
      }
      if (count === source.length) break;
      if (pass) result++;
    }
  } else if (isProAmountOnly(type)) {
    let pass = true;
    for (let source_index = 0; source_index < source.length; source_index++) {
      const { order_qty, qty } = source[source_index];
      if (order_qty < qty) {
        pass = false;
        break;
      }
    }
    if (pass) result++;
  }
  return result;
};

export const orderCategoryItem = (items) => _.orderBy(items, "qty", "desc");

// const _orderCategoryItem1 = (items, conditionType) => {
//   const { in_use, not_in_use } = _.reduce(
//     items,
//     (res, item) =>
//       item.qty
//         ? {
//             ...res,
//             in_use: [...res.in_use, item],
//           }
//         : {
//             ...res,
//             not_in_use: [...res.not_in_use, item],
//           },
//     {
//       in_use: [],
//       not_in_use: [],
//     }
//   );
//   const order_type = conditionType === "item-amount" ? "desc" : "asc";
//   return [..._.orderBy(in_use, "unit_price", order_type), ...not_in_use];
// };

// const _orderCategoryItem2 = (items, conditionType) => {
//   const { in_use, not_use } = _.reduce(
//     items,
//     (res, item) =>
//       item.qty
//         ? {
//             ...res,
//             in_use: [...res.in_use, item],
//           }
//         : {
//             ...res,
//             not_use: [...res.not_use, item],
//           },
//     {
//       in_use: [],
//       not_use: [],
//     }
//   );
//   const order_type = conditionType === "item-amount" ? "desc" : "asc";
//   const in_used_order = _.orderBy(in_use, "unit_price", order_type);
//   return {
//     in_use: in_used_order,
//     not_use,
//     all: [...in_used_order, not_use],
//   };
// };
