/* eslint-disable no-unused-vars */
import { Avatar, Breadcrumb, Col, Divider, Row, Tabs } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import {
  actionMember,
  avatarSelector,
  marriedSeleector,
  memberSelector,
} from "../../../redux/slices/member";
import { URL_API, axios, getToken } from "../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import AddressPanel from "./components/AddressPanel";
import BankPanel from "./components/BankPanel";
import DocumentPanel from "./components/DocumentPanel";
import ListDetail from "./components/ListDetail";
import MarriedPanel from "./components/MarriedPanel";
import MemberPanel from "./components/MemberPanel";
import SponsorPanel from "./components/SponsorPanel";
import TableRemark from "./components/TableRemark";
import UplinePanel from "./components/UplinePanel";
export default function MemberInfo() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const avatar_src = useSelector(avatarSelector);
  const member = useSelector(memberSelector);
  const married = useSelector(marriedSeleector);
  const parts = match.url.split("/");
  const { SET_LOAD_DETAIL, SET_LOAD_DETAIL_SUCCESS, SET_DETAIL_ACTIVE } =
    actionMember;

  useMemo(() => {
    dispatch(SET_LOAD_DETAIL());

    const loadData = async () => {
      const { data } = await axios({
        baseURL: URL_API,
        url: `member/${match.params.id}`,
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          member_type: match.params.member_type,
        },
      });

      dispatch(
        SET_LOAD_DETAIL_SUCCESS({
          ...data.data,
        })
      );
    };

    loadData();
  }, [
    match.params.id,
    match.params.member_type,
    dispatch,
    SET_LOAD_DETAIL,
    SET_LOAD_DETAIL_SUCCESS,
  ]);
  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {parts[1] === "member" ? (
            <Link to="/member">ข้อมูลสมาชิก</Link>
          ) : (
            <Link to="/landing">แอบตาดู</Link>
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{member.member_code}</Breadcrumb.Item>
      </Breadcrumb>

      <Panel>
        <PanelHeader>{member.member_code}</PanelHeader>
        <PanelBody>
          <Row gutter={16}>
            <Col md={6} className="text-center">
              <Avatar size={200} shape="circle" src={avatar_src} icon="user" />

              <ListDetail />
            </Col>
            <Col md={18}>
              <Tabs
                className="mb-3"
                onChange={(e) => dispatch(SET_DETAIL_ACTIVE(e))}
              >
                <Tabs.TabPane tab="ผู้สมัครหลัก" key="member">
                  <MemberPanel />
                </Tabs.TabPane>
                {match.params.member_type === "distributor" && (
                  <Tabs.TabPane
                    tab="ผู้สมัครร่วม"
                    key="married"
                    disabled={married == null}
                  >
                    <MarriedPanel />
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="ผู้แนะนำ" key="sponsor">
                  <SponsorPanel />
                </Tabs.TabPane>
                {match.params.member_type === "distributor" && (
                  <Tabs.TabPane tab="อัพไลน์" key="upline">
                    <UplinePanel />
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="ที่อยู่" key="address">
                  <AddressPanel />
                </Tabs.TabPane>
                {match.params.member_type === "distributor" && (
                  <Tabs.TabPane tab="ธนาคาร" key="bank">
                    <BankPanel />
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="เอกสาร" key="document">
                  <DocumentPanel />
                </Tabs.TabPane>
              </Tabs>

              <Divider>หมายเหตุ</Divider>
              <TableRemark />
            </Col>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
