import { Button, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionMember,
  activeSelector,
  searchSelector,
} from "../../../../redux/slices/member";
import { useApi } from "../../../../hooks/http2";
import { URL_API, getUser } from "../../../../utils";
import numeral from "numeral";
import { FaCog, FaEye, FaUserLock, FaUserAlt } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";
import TierAdminModal from "./TierAdminModal";

export default function MemberTable() {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const active = useSelector(activeSelector);
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMember;
  const user = getUser();
  // const handleShowPreview = (src) => {
  //   console.log(src);
  //   dispatch({
  //     type: "SHOW_MODAL",
  //     payload: { name: "document-icon", props: { src } },
  //   });
  // };
  const member = useApi(
    {
      baseURL: URL_API,
      url: `/member`,
      token: true,
      params: {
        ...search,
        member_type: active,
        offset: (search.page - 1) * search.limit,
      },
    },
    [search, active]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const handleShowPreview = (id, member_code, member_type) => {
    setDataModal({ id, member_code, member_type });
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const landing = (id, member_code, member_type) => {
    const url = `${process.env.REACT_APP_MEMBER_URL}/landing/?user_id=${user.id}&member_code=${member_code}&member_type=${member_type}&token=`;
    // const url = `http://localhost:3000/landing/`;
    window.open(url, "_blank");
    // history.push(url, "_blank");
  };
  return (
    <>
      <Table
        size="small"
        bordered
        loading={member.loading}
        dataSource={member.fetch?.data || []}
        rowKey={"member_code"}
        pagination={{
          current: parseInt(search.page),
          pageSize: search.limit,
          total: member.fetch?.total || 0,
          showLessItems: true,
          size: "default",
          pageSizeOptions: ["10", "25", "50", "100"],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${numeral(range[0]).format()} - ${numeral(
              range[1]
            ).format()} of ${numeral(total).format()}`,
          onChange: (currPage, pageSize) =>
            dispatch(
              SET_SEARCH({
                page: currPage,
                limit: pageSize,
              })
            ),
          onShowSizeChange: (current, size) =>
            dispatch(
              SET_SEARCH({
                page: 1,
                limit: size,
              })
            ),
        }}
      >
        <Table.Column
          title="รหัสสมาชิก"
          dataIndex="member_code"
          align="center"
          className="font-size-12"
        />
        <Table.Column
          title="ชื่อสมาชิก"
          dataIndex="member_name"
          className="font-size-12"
          render={(text, { member_name_en }) => (
            <>
              ภาษาไทย: <strong>{text}</strong>
              <br />
              ภาษาอังกฤษ: <strong>{member_name_en}</strong>
            </>
          )}
        />
        <Table.Column
          title="ชื่อผู้สมัครร่วม"
          dataIndex="married_name"
          className="font-size-12"
          render={(text, { married_name_en }) => (
            <>
              ภาษาไทย: <strong>{text === '' ? '-' : text}</strong>
              <br />
              ภาษาอังกฤษ: <strong>{married_name_en === '' ? '-' : married_name_en}</strong>
            </>
          )}
        />
        <Table.Column
          title="ผู้แนะนำ"
          dataIndex="sponsor_code"
          className="font-size-12"
          render={(text, { sponsor_name }) => (
            <>
              รหัส: <strong>{text === "" ? "-" : text}</strong>
              <br />
              ชื่อ: <strong>{sponsor_name}</strong>
            </>
          )}
        />
        <Table.Column
          title="ผู้คีย์สมัคร"
          dataIndex="partner_code_form"
          className="font-size-12"
          render={(text, { partner_code_form_name }) => (
            <>
              รหัส: <strong>{text === "" ? "-" : text} </strong>
              <br />
              ชื่อ: <strong>{partner_code_form_name === "" ? "-" : partner_code_form_name}</strong>
            </>
          )}
        />
        <Table.Column
          title="โทรศัพท์"
          dataIndex="mobile"
          align="center"
          className="font-size-12"
        />
        <Table.Column
          title="อีเมล"
          dataIndex="email"
          align="center"
          className="font-size-12"
        />
        <Table.Column
          title="วันที่สมัคร"
          align="center"
          className="font-size-12"
          render={({ register_date }) =>
            register_date && moment(register_date).format("DD/MM/YYYY")
          }
        />
        <Table.Column
          title="วันที่หมดอายุ"
          align="center"
          className="font-size-12"
          render={({ expire_date }) =>
          expire_date && moment(expire_date).format("DD/MM/YYYY")
          }
        />
        <Table.Column
          title="สถานะ"
          dataIndex="status_text"
          align="center"
          className="font-size-12"
        />
        <Table.Column
          title={<FaCog />}
          align="center"
          className="font-size-12 text-nowrap"
          render={({ id, member_code, member_type }) => (
            <>
              <Tooltip title="ดูข้อมูล">
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-pv border-pv mr-1"
                  onClick={() => history.push(`${match.path}/${id}/${active}`)}
                >
                  <FaUserAlt />
                </Button>
              </Tooltip>
              <Tooltip title="Tier View">
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-rp border-rp mr-1"
                  onClick={() => landing(id, member_code, member_type)}
                >
                  <FaEye />
                </Button>
              </Tooltip>
              <Tooltip title="Tier Admin">
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-tp border-tp"
                  onClick={() =>
                    handleShowPreview(id, member_code, member_type)
                  }
                >
                  <FaUserLock />
                </Button>
              </Tooltip>
            </>
          )}
        />
      </Table>
      <TierAdminModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        dataModal={dataModal}
        handleCancel={handleCancel}
      />
    </>
  );
}
