import { Col, Row, Typography } from "antd";
import React, { memo } from "react";

const SelectLayout = ({ title, children }) => (
  <Row gutter={8} type="flex" className="align-items-center">
    <Col span={6} xxl={6} className="text-right">
      <Typography.Text>{title}</Typography.Text>
    </Col>
    <Col span={18} xxl={18}>
      {children}
    </Col>
  </Row>
);

export default memo(SelectLayout);
