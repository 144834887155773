/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row } from "antd";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReservePreview from "../../../../components/Preview";
import { URL_API, _, axios, getToken } from "../../../../utils";
import { printform } from "../../../../utils/printforms";
import { resCatchModal } from "../../../util/helper";
import { PosDataAction } from "../ducks/data-api";
import { getPromotionOrderLimit } from "../ducks/logic";
import { Creators } from "../ducks/operation-form";
import { getSummaryTotalPayment } from "./logic";

const Bill = ({
  orders,
  summary_list,
  is_staff: isStaff,
  is_normal: isNormal,
}) => {
  let ele = [];

  _.forEach(orders.itemKeys, (n, i) => {
    const data = orders.itemByKey[n];
    const is_vip_member = data.partner_type?.toLowerCase() === "member";
    const { total, delivery, discount, point, summary_total } = _.find(
      summary_list,
      (item) => item.key === n
    );
    const item_list = [
      ..._.map(data.items.itemKeys, (key) => data.items.itemByKey[key]),
      ...data.promotions.promotion_list,
    ];

    ele.push(
      <React.Fragment key={i}>
        <div className="mb-0 text-dark bg-gray-40 py-2 w-100">
          <Row gutter={8}>
            <Col span={8} className="pl-4">
              <strong>{`บิลที่ ${i + 1} - ${data.partner_code} ${
                data.partner_name
              }`}</strong>
            </Col>
            <Col span={6}>
              <strong className="mr-2">ประเภทการรับของ</strong>{" "}
              {data.req_receipt_type.toLowerCase() === "branch"
                ? "รับที่สาขา"
                : "จัดส่ง"}
            </Col>
            <Col span={6}>
              <strong className="mr-2">ประเภทการสั่งซื้อ</strong>{" "}
              {data.order_type}
            </Col>
            <Col span={4}>
              <strong className="mr-2">ใบเสร็จ</strong>{" "}
              {data.req_receipt === "1" ? "รับใบเสร็จ" : "ไม่รับใบเสร็จ"}
            </Col>
          </Row>
        </div>
        <div className="mb-0 text-dark py-2 w-100">
          <Row gutter={8}>
            <Col span={8} className="pl-4">
              <strong>ผู้รับ</strong> {data.ship_to || data.partner_name}
            </Col>
            <Col span={6}>
              <strong className="mr-2">เบอร์โทรศัพท์</strong>{" "}
              {data.ship_mobile || data.partner_mobile}
            </Col>
            <Col span={10}>
              <strong className="mr-2">
                {data.req_receipt_type.toLowerCase() === "branch"
                  ? "สาขาที่รับ"
                  : "ที่อยู่จัดส่ง"}
              </strong>
              {data.req_receipt_type.toLowerCase() === "branch"
                ? data.receipt_branch_name
                : `${data.ship_address_no} ${data.ship_sub_district} ${data.ship_district} ${data.ship_province} ${data.ship_post_code}`}
            </Col>
          </Row>
        </div>
        <div className="w-100">
          <Row gutter={8} className="text-gray-60 mx-0">
            <Col span={1} className="text-nowrap"></Col>
            <Col span={isNormal ? 8 : 16} className="text-nowrap">
              รายการสินค้า
            </Col>
            <Col span={3} className="text-nowrap text-right">
              {isNormal ? "ราคา" : "คะแนน"}/หน่วย
            </Col>
            <Col span={1} className="text-nowrap text-right">
              จำนวน
            </Col>
            <Col span={3} className="text-nowrap text-right">
              {isNormal ? "ราคา" : "คะแนน"}
            </Col>
            {!isStaff && isNormal && (
              <>
                <Col span={2} className="text-nowrap text-right">
                  {!is_vip_member && "PV"}
                </Col>
                <Col span={2} className="text-nowrap text-right">
                  RP
                </Col>
                <Col span={2} className="text-nowrap text-right">
                  {!is_vip_member && "EP"}
                </Col>
                <Col span={2} className="text-nowrap text-right">
                  {!is_vip_member && "TP"}
                </Col>
              </>
            )}
          </Row>
          {item_list.map((item, i) => (
            <Row gutter={8} key={i} className="text-dark mx-0">
              <Col span={1} className="text-nowrap text-center">
                {i + 1}
              </Col>
              <Col span={isNormal ? 8 : 16} className="text-nowrap">
                {item.product_name}
              </Col>
              <Col span={3} className="text-nowrap text-right">
                {numeral(item.unit_price).format("0,0.00")}
              </Col>
              <Col span={1} className="text-nowrap text-right">
                {numeral(item.qty).format("0,0")}
              </Col>
              <Col span={3} className="text-nowrap text-right">
                {numeral(item.unit_price * item.qty).format("0,0.00")}
              </Col>
              {!isStaff && isNormal && (
                <>
                  <Col span={2} className="text-nowrap text-right">
                    {!is_vip_member &&
                      numeral(data.collect_point && item.pv * item.qty).format(
                        "0,0.00"
                      )}
                  </Col>
                  <Col span={2} className="text-nowrap text-right">
                    {numeral(data.collect_point && item.rp * item.qty).format(
                      "0,0.00"
                    )}
                  </Col>
                  <Col span={2} className="text-nowrap text-right">
                    {!is_vip_member &&
                      numeral(data.collect_point && item.ep * item.qty).format(
                        "0,0.00"
                      )}
                  </Col>
                  <Col span={2} className="text-nowrap text-right">
                    {!is_vip_member &&
                      numeral(data.collect_point && item.tp * item.qty).format(
                        "0,0.00"
                      )}
                  </Col>
                </>
              )}
            </Row>
          ))}
          <Row gutter={8} className="text-dark border-top py-1 mx-0">
            <Col span={isNormal ? 12 : 20} className="text-nowrap text-right">
              {_.includes(
                ["transport", "lowcost"],
                data.req_receipt_type.toLowerCase()
              ) &&
                isNormal && <strong>ค่าจัดส่ง</strong>}
            </Col>
            <Col span={1}></Col>
            <Col span={3} className="text-right">
              {_.includes(
                ["transport", "lowcost"],
                data.req_receipt_type.toLowerCase()
              ) &&
                isNormal &&
                numeral(delivery).format("0,0.00")}
            </Col>
            {!isStaff && isNormal && <Col span={8} />}
          </Row>
          <Row gutter={8} className="text-dark py-1 mx-0">
            <Col span={isNormal ? 12 : 20} className="text-nowrap text-right">
              <strong>รวม{isNormal ? "ราคา" : "คะแนน"}สินค้า</strong>
            </Col>
            <Col span={1}></Col>
            <Col span={3} className="text-right">
              {numeral(total).format("0,0.00")}
            </Col>
            {!isStaff && isNormal && <Col span={8} />}
          </Row>
          <Row gutter={8} className="text-dark py-1 mx-0">
            <Col span={isNormal ? 12 : 20} className="text-nowrap text-right">
              <strong>รวมส่วนลด</strong>
            </Col>
            <Col span={1}></Col>
            <Col span={3} className="text-right">
              {discount !== 0 && "-"}
              {numeral(discount).format("0,0.00")}
            </Col>
            {!isStaff && isNormal && <Col span={8} />}
          </Row>
          <Row gutter={8} className="text-dark py-1 mx-0">
            <Col span={isNormal ? 12 : 20} className="text-nowrap text-right">
              <strong>รวมทั้งหมด</strong>
            </Col>
            <Col span={1}></Col>
            <Col span={3} className="text-right">
              {numeral(summary_total).format("0,0.00")}
            </Col>
            {!isStaff && isNormal && (
              <>
                <Col span={2} className="text-nowrap text-right">
                  {!is_vip_member && numeral(point.pv).format("0,0.00")}
                </Col>
                <Col span={2} className="text-nowrap text-right">
                  {numeral(point.rp).format("0,0.00")}
                </Col>
                <Col span={2} className="text-nowrap text-right">
                  {!is_vip_member && numeral(point.ep).format("0,0.00")}
                </Col>
                <Col span={2} className="text-nowrap text-right">
                  {!is_vip_member && numeral(point.tp).format("0,0.00")}
                </Col>
              </>
            )}
          </Row>
        </div>
        {/* <table className="w-100">
          <tbody>
            <tr className="text-gray-60">
              <td width="4%" />
              <td className="px-1" width="46%">
                รายการสินค้า
              </td>
              <td className="text-nowrap text-center" width="10%">
                {isNormal ? "ราคา" : "คะแนน"}/หน่วย
              </td>
              <td className="text-nowrap text-center" width="10%">
                จำนวน
              </td>
              <td className="text-nowrap text-center" width="10%">
                {isNormal ? "ราคา" : "คะแนน"}
              </td>
              {!isStaff && isNormal && (
                <>
                  {!is_vip_member ? (
                    <>
                      <td className="text-nowrap text-center" width="5%">
                        PV
                      </td>
                      <td className="text-nowrap text-center" width="5%">
                        RP
                      </td>
                      <td className="text-nowrap text-center" width="5%">
                        EP
                      </td>
                      <td className="text-nowrap text-center" width="5%">
                        TP
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="text-nowrap text-center" width="5%">
                        {" "}
                      </td>
                      <td className="text-nowrap text-center" width="5%">
                        RP
                      </td>
                      <td className="text-nowrap text-center" width="5%">
                        {" "}
                      </td>
                      <td className="text-nowrap text-center" width="5%">
                        {" "}
                      </td>
                    </>
                  )}
                </>
              )}
            </tr>
            {item_list.map((item, i) => (
              <tr className="text-dark" key={i}>
                <td className="text-nowra pl-2 pr-1 text-right" width="4%">
                  {i + 1}
                </td>
                <td className="px-1" width="46%">
                  {item.product_name}
                </td>
                <td className="text-nowrap pr-4 text-right" width="10%">
                  {numeral(item.unit_price).format("0,0.00")}
                </td>
                <td className="text-nowrap pr-4 text-right" width="10%">
                  {numeral(item.qty).format("0,0")}
                </td>
                <td className="text-nowrap pr-4 text-right" width="10%">
                  {numeral(item.unit_price * item.qty).format("0,0.00")}
                </td>
                {!isStaff && isNormal && (
                  <>
                    {!is_vip_member ? (
                      <>
                        <td className="text-nowrap px-2 text-right">
                          {numeral(
                            data.collect_point && item.pv * item.qty
                          ).format("0,0.00")}
                        </td>
                        <td className="text-nowrap px-2 text-right">
                          {numeral(
                            data.collect_point && item.rp * item.qty
                          ).format("0,0.00")}
                        </td>
                        <td className="text-nowrap px-2 text-right">
                          {numeral(
                            data.collect_point && item.ep * item.qty
                          ).format("0,0.00")}
                        </td>
                        <td className="text-nowrap px-2 text-right">
                          {numeral(
                            data.collect_point && item.tp * item.qty
                          ).format("0,0.00")}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="text-nowrap px-2 text-right" width="5%">
                          {" "}
                        </td>
                        <td className="text-nowrap px-2 text-right" width="5%">
                          {numeral(
                            data.collect_point && item.rp * item.qty
                          ).format("0,0.00")}
                        </td>
                        <td className="text-nowrap px-2 text-right" width="5%">
                          {" "}
                        </td>
                        <td className="text-nowrap px-2 text-right" width="5%">
                          {" "}
                        </td>
                      </>
                    )}
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table> */}
        {/* <table className="w-100">
          <tbody>
            <tr className="text-dark border-top">
              <td
                colSpan={3}
                className="text-nowrap text-right py-1"
                width="65%"
              >
                {_.includes(
                  ["transport", "lowcost"],
                  data.req_receipt_type.toLowerCase()
                ) &&
                  isNormal && <strong>ค่าจัดส่ง</strong>}
              </td>
              <td width="5%" className="border-top" />
              <td
                className="text-nowrap pr-4 text-right py-1 border-top"
                width="10%"
              >
                {_.includes(
                  ["transport", "lowcost"],
                  data.req_receipt_type.toLowerCase()
                ) &&
                  isNormal &&
                  numeral(delivery).format("0,0.00")}
              </td>
              {!isStaff && isNormal && (
                <>
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                </>
              )}
            </tr>
            <tr className="text-dark">
              <td
                colSpan={3}
                className="text-nowrap text-right py-1"
                width="65%"
              >
                <strong>รวม{isNormal ? "ราคา" : "คะแนน"}สินค้า</strong>
              </td>
              <td width="5%" />
              <td className="text-nowrap pr-4 text-right py-1" width="10%">
                {numeral(total).format("0,0.00")}
              </td>
              {!isStaff && isNormal && (
                <>
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                </>
              )}
            </tr>
            <tr className="text-dark">
              <td
                colSpan={3}
                className="text-nowrap text-right py-1"
                width="65%"
              >
                <strong>รวมส่วนลด</strong>
              </td>
              <td width="5%" />
              <td className="text-nowrap pr-4 text-right py-1" width="10%">
                {discount !== 0 && "-"}
                {numeral(discount).format("0,0.00")}
              </td>
              {!isStaff && isNormal && (
                <>
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                  <td className="text-nowrap px-2  py-1" width="5%" />
                </>
              )}
            </tr>
            <tr className="text-dark">
              <td
                colSpan={3}
                className="text-nowrap text-right py-1"
                width="65%"
              >
                <strong>รวมทั้งหมด</strong>
              </td>
              <td width="5%" />
              <td className="text-nowrap pr-4 text-right py-1">
                {numeral(summary_total).format("0,0.00")}
              </td>
              {!isStaff && isNormal && (
                <>
                  {!is_vip_member ? (
                    <>
                      <td className="text-nowrap px-2 text-right " width="5%">
                        {numeral(point.pv).format("0,0.00")}
                      </td>
                      <td className="text-nowrap px-2 text-right " width="5%">
                        {numeral(point.rp).format("0,0.00")}
                      </td>
                      <td className="text-nowrap px-2 text-right " width="5%">
                        {numeral(point.ep).format("0,0.00")}
                      </td>
                      <td className="text-nowrap px-2 text-right " width="5%">
                        {numeral(point.tp).format("0,0.00")}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="text-nowrap px-2  " width="5%" />
                      <td className="text-nowrap px-2 text-right " width="5%">
                        {numeral(point.rp).format("0,0.00")}
                      </td>
                      <td className="text-nowrap px-2  " width="5%" />
                      <td className="text-nowrap px-2  " width="5%" />
                    </>
                  )}
                </>
              )}
            </tr>
          </tbody>
        </table> */}
      </React.Fragment>
    );
  });

  return ele;
};

const Summary = ({
  summary_total,
  point_total,
  is_staff: isStaff,
  is_normal: isNormal,
  has_no_vip_member: hasNoVipMember,
}) => {
  return (
    <div className="w-100">
      {!isStaff && isNormal && (
        <>
          <Row gutter={8} className="text-dark bg-pv-light mx-0">
            <Col span={12} className="text-nowrap text-right my-1">
              <strong>คะแนนรวม</strong>
            </Col>
            <Col span={4} />
            <Col span={2} className="text-nowrap text-right my-1">
              <strong>
                {hasNoVipMember && numeral(point_total.pv).format("0,0.00")}
              </strong>
            </Col>
            <Col span={2} className="text-nowrap  text-right my-1">
              <strong>{numeral(point_total.rp).format("0,0.00")}</strong>
            </Col>
            <Col span={2} className="text-nowrap  text-right my-1">
              <strong>
                {hasNoVipMember && numeral(point_total.ep).format("0,0.00")}
              </strong>
            </Col>
            <Col span={2} className="text-nowrap  text-right my-1">
              <strong>
                {hasNoVipMember && numeral(point_total.tp).format("0,0.00")}
              </strong>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={8} className="text-dark bg-pv  mx-0 ">
        <Col span={isNormal ? 12 : 20} className="text-nowrap  text-right  my-2">
          <h4 className="mb-0">จำนวน{isNormal ? "เงิน" : "คะแนน"}รวม</h4>
        </Col>
        <Col span={1} />
        <Col span={3} className="text-nowrap  text-right  my-2 ">
          <h4 className="mb-0">{numeral(summary_total).format("0,0.00")}</h4>
        </Col>
        {!isStaff && isNormal && <Col span={8} />}
      </Row>
    </div>
    // <table className="w-100">
    //   <tbody>
    //     {!isStaff && isNormal && (
    //       <tr className="text-dark bg-pv-light">
    //         <td colSpan={3} className="text-nowrap text-right py-2" width="65%">
    //           <strong>คะแนนรวม</strong>
    //         </td>
    //         <td width="5%" />
    //         <td className="text-nowrap pr-4 text-right py-2" width="10%" />
    //         {hasNoVipMember ? (
    //           <>
    //             <td className="text-nowrap px-2 text-right py-1" width="5%">
    //               <strong>{numeral(point_total.pv).format("0,0.00")}</strong>
    //             </td>
    //             <td className="text-nowrap px-2 text-right py-1" width="5%">
    //               <strong>{numeral(point_total.rp).format("0,0.00")}</strong>
    //             </td>
    //             <td className="text-nowrap px-2 text-right py-1" width="5%">
    //               <strong>{numeral(point_total.ep).format("0,0.00")}</strong>
    //             </td>
    //             <td className="text-nowrap px-2 text-right py-1" width="5%">
    //               <strong>{numeral(point_total.tp).format("0,0.00")}</strong>
    //             </td>
    //           </>
    //         ) : (
    //           <>
    //             <td className="text-nowrap px-2 text-right py-1" width="5%" />
    //             <td className="text-nowrap px-2 text-right py-1" width="5%">
    //               <strong>{numeral(point_total.rp).format("0,0.00")}</strong>
    //             </td>
    //             <td className="text-nowrap px-2 text-right py-1" width="5%" />
    //             <td className="text-nowrap px-2 text-right py-1" width="5%" />
    //           </>
    //         )}
    //       </tr>
    //     )}
    //     <tr className="text-dark bg-pv">
    //       <td colSpan={3} className="text-nowrap text-right py-2" width="65%">
    //         <h4 className="mb-0">จำนวน{isNormal ? "เงิน" : "คะแนน"}รวม</h4>
    //       </td>
    //       <td width="5%" />
    //       <td className="text-nowrap pr-4 text-right py-2" width="10%">
    //         <h4 className="mb-0">{numeral(summary_total).format("0,0.00")}</h4>
    //       </td>
    //       {!isStaff && isNormal && (
    //         <>
    //           {/* <td colSpan={4} className="text-nowrap px-2 text-right py-2" width="25%" /> */}
    //           <td className="text-nowrap px-2 text-right py-1" width="5%" />
    //           <td className="text-nowrap px-2 text-right py-1" width="5%" />
    //           <td className="text-nowrap px-2 text-right py-1" width="5%" />
    //           <td className="text-nowrap px-2 text-right py-1" width="5%" />
    //         </>
    //       )}
    //     </tr>
    //   </tbody>
    // </table>
  );
};

const Payment = ({
  voucher_payment,
  payment,
  summary_total,
  payment_total,
  cash_total,
  balance_total,
}) => {
  return (
    <table className="mx-auto w-75 mt-3">
      <tbody>
        <tr className="text-gray-60">
          <td className="text-center">ประเภทการชำระเงิน</td>
          <td className="text-center">ธนาคาร</td>
          <td className="text-center">เลขที่อ้างอิง</td>
          <td className="text-center">จำนวนเงิน</td>
        </tr>
        {voucher_payment
          .filter((item) => item.pay_in_type?.toLowerCase() === "voucher")
          .map((n, i) => {
            return (
              <tr className="text-dark" key={i}>
                <td className="text-center py-1">{n.pay_in_type_name}</td>
                <td className="text-center py-1">-</td>
                <td className="text-center py-1">{n.ref_code}</td>
                <td className="text-center py-1">
                  {numeral(n.amount).format("0,0.00")}
                </td>
              </tr>
            );
          })}
        {payment.itemKeys.map((n, i) => {
          let data = payment.itemByKey[n];
          return (
            <tr className="text-dark" key={i}>
              <td className="text-center py-1">{data.pay_in_type_name}</td>
              <td className="text-center py-1">{data.bank_name}</td>
              <td className="text-center py-1">{data.ref_code}</td>
              <td className="text-center py-1">
                {/* { n.pay_in_type === "Cash" &&
                props.payment_total > props.balance_total
                ? props.cash_total + props.balance_total - props.payment_total
                : n.amount} */}
                {numeral(
                  data.pay_in_type === "Cash" && payment_total > balance_total
                    ? cash_total + balance_total - payment_total
                    : data.amount
                ).format("0,0.00")}
              </td>
            </tr>
          );
        })}
        <tr className="text-dark">
          <td className="text-center py-1" />
          <td className="text-center py-1" />
          <td className="text-center py-1" />
          <td className="text-center py-1">
            <strong>{numeral(summary_total).format("0,0.00")}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapDataTransactionLogistic = (data, auth) => {
  const { orders, buyer_code, buyer_name, buyer_type, buyer_mobile } = data;
  let logistic_data = [];
  for (let i = 0, arrI = orders.length; i < arrI; ++i) {
    logistic_data = [
      ...logistic_data,
      {
        prepare_no: null,
        document_date: new Date(),
        create_date: new Date(),
        officer: auth.name,
        staff: null,
        buyer_code: buyer_code,
        buyer_name: buyer_name,
        buyer_type: buyer_type,
        buyer_mobile: buyer_mobile,
        ship_to: orders[i].ship_to || null,
        ship_mobile: orders[i].ship_mobile || null,
        ship_address_no: orders[i].ship_address_no || null,
        ship_sub_district: orders[i].ship_sub_district || null,
        ship_district: orders[i].ship_district || null,
        ship_province: orders[i].ship_province || null,
        ship_post_code: orders[i].ship_post_code || null,
        ship_country: orders[i].ship_country || null,
        ship_address: orders[i].ship_address || null,
        ship_by: orders[i].ship_by || null,
        logistic_type: orders[i].req_receipt_type || null,
        qty: orders[i].items.length,
        consignment: null,
        description: null,
        status: "waiting",
      },
    ];
  }
  return logistic_data;
};

const Preview = ({ visible = false, onCancel, type, ...props }) => {
  // console.log(props)
  const [typeTextBtn, setTypeTextBtn] = useState("");
  const [showReservePreview, setShowReservePreview] = useState(false);
  const [reservePreviewData, setReservePreviewData] = useState({});
  const [source, setSource] = useState(axios.CancelToken.source());
  const [loading, setLoading] = useState(false);
  const isReserve = _.includes(
    ["subscription", "reserve", "pre-sale", "pre-redeem"],
    type
  );
  const isPreOrder = _.includes(["pre-sale", "pre-redeem"], type);
  const isNormal = _.includes(["sale", "pre-sale", "subscription"], type);

  useEffect(() => {
    visible && setSource(axios.CancelToken.source());
  }, [visible]);

  useEffect(() => {
    return () => {
      source.cancel("unmounted");
    };
  }, []);

  useEffect(() => {
    const type = props.match.params.type;
    if (type) {
      let text = "ดำเนินการ";
      if (type === "sale") {
        text = "ชำระเงิน";
      } else if (type === "redeem") {
        text = "แลก";
      } else if (type === "subscription") {
        text = "จองซื้อ";
      } else if (type === "reserve") {
        text = "จองแลก";
      } else if (type === "pre-sale") {
        text = "สั่งซื้อ";
      } else if (type === "pre-redeem") {
        text = "สั่งแลก";
      }
      setTypeTextBtn(text);
    }
  }, [props.match.params.type]);

  const loadPreviewData = async (id = 0) => {
    try {
      const res = await axios({
        url: `${URL_API}/pos/operation/reserve/${id}`,
      });
      setReservePreviewData(res.data.data);
      setShowReservePreview(true);
      onCancel();
    } catch (e) {
      resCatchModal(e);
    }
  };

  const handleCloseReservePreview = () => {
    setShowReservePreview(false);
    setReservePreviewData({});
    props.clear();
    props.clearRedeemPoint();
    props.history.replace(`/pos/${type}/operator`);
  };

  const sendData = async () => {
    setLoading(true);
    let data = _.omit(props.data, [
      "orders",
      "payment",
      "step",
      "discount",
      "voucher",
      "allow_item",
      "allow_renew",
      "matching_promotion_item",
      "buyer_img",
    ]);
    data.promotion_collect = props.promotion_collect;
    const orders = _.map(props.data.orders.itemKeys, (m) => {
      let voucher_list_order =
        _.find(props?.summary_list, (order) => order.key === m)
          ?.voucher_list_order || [];
      return {
        ..._.omit(props.data.orders.itemByKey[m], [
          "partner_document",
          "promotions",
          "allow_item",
          "allow_renew",
        ]),
        file: props.data.orders.itemByKey[m].partner_document?.id.toString(),
        partner_doc_key: m,
        sales_type: props.match.params.type,
        promotion_item_condition_source: _.filter(
          props.data.orders.itemByKey[m].promotions.item_list,
          (n) => n.condition_type === "item"
        ),
        promotion_order_limit: getPromotionOrderLimit(
          props.data.orders.itemByKey[m].promotions
        ),
        items: [
          ..._.map(props.data.orders.itemByKey[m].items.itemKeys, (n) =>
            _.omit(props.data.orders.itemByKey[m].items.itemByKey[n], [
              "qr_warning",
              "auto_add",
            ])
          ),
          ..._.map(
            _.filter(
              props.data.orders.itemByKey[m].promotions.promotion_list,
              (n) => n.promotion_item_type === "item"
            ),
            (n) =>
              _.omit(n, [
                "qr_warning",
                "promotion_item_type",
                "promotion_condition_type",
              ])
          ),
        ],
        free_point: [
          ..._.map(
            _.filter(
              props.data.orders.itemByKey[m].promotions.promotion_list,
              (n) =>
                n.promotion_item_type === "amount" &&
                _.includes(["pv", "rp", "cv"], n.source_key)
            ),
            (n) => ({
              promotion_id: n.promotion_id,
              source_key: n.source_key,
              source_value: n[n.source_key],
              qty: n.qty,
            })
          ),
        ],
        free_voucher: [
          ..._.map(
            _.filter(
              props.data.orders.itemByKey[m].promotions.promotion_list,
              (n) => n.promotion_item_type === "voucher"
            ),
            (n) => ({
              promotion_id: n.promotion_id,
              voucher_id: n.id,
              code: n.code,
              value: n.value,
              ref_id: props.data.orders.itemByKey[m].member_id,
              ref_type: props.data.orders.itemByKey[m].partner_type,
              status: n.keep ? 1 : 2,
              qty: n.qty,
            })
          ),
        ],
        free_discount: [
          ..._.map(
            _.filter(
              props.data.orders.itemByKey[m].promotions.promotion_list,
              (n) =>
                n.promotion_item_type === "amount" &&
                _.includes(["percent", "price"], n.source_key)
            ),
            (n) => ({
              promotion_id: n.promotion_id,
              discount_amount: n.price * n.qty,
            })
          ),
        ],
        discount: _.map(props.data.orders.itemByKey[m].discount.itemKeys, (n) =>
          _.omit(props.data.orders.itemByKey[m].discount.itemByKey[n], [
            "auto_add",
          ])
        ),
        voucher: _.map(
          _.filter(props.data.orders.itemByKey[m].voucher.itemKeys, (item) =>
            voucher_list_order.some((e) => e.id === item)
          ),
          (n) => ({
            id: props.data.orders.itemByKey[m].voucher.itemByKey[n].id,
          })
        ),
      };
    });
    data.orders = isPreOrder
      ? _.reduce(
          orders,
          (res_order, order) => [
            ...res_order,
            ..._.reduce(
              order.items,
              (res_item, item) => [...res_item, { ...order, items: [item] }],
              []
            ),
          ],
          []
        )
      : orders;
    data.payment = _.map(
      props.data.payment.itemKeys,
      (n) => {
        let ret = { ...props.data.payment.itemByKey[n] };

        if (
          ret.pay_in_type === "Cash" &&
          props.payment_total > props.balance_total
        ) {
          ret.amount =
            props.cash_total + props.balance_total - props.payment_total;
        }
        return ret;
      }
      // props.data.payment.itemByKey[n]
    );

    // n.pay_in_type === "Cash" &&
    // props.payment_total > props.balance_total
    // ? props.cash_total + props.balance_total - props.payment_total
    // : n.amount

    // data.discount = props.data.discount.itemKeys.map(
    //   (n) => props.data.discount.itemByKey[n]
    // );

    if (isReserve) {
      data = {
        ...data,
        reserve_type: type,
      };
    }

    data = {
      ...data,
      logistics: mapDataTransactionLogistic(data, props.auth),
      // free_price_payment: props.free_price_payment,
      // all_voucher_payment: props.all_voucher_payment,
      voucher_payment: props.voucher_payment,
      bill_branch_code: props.data.orders.branch.code,
      bill_branch_name: props.data.orders.branch.name,
      file: props.data.buyer_img?.id.toString(),
    };
    try {
      const res = await axios({
        method: "post",
        url: `${URL_API}/pos/operation${isReserve ? `/reserve` : ``}`,
        headers: { Authorization: "Bearer " + getToken() },
        data,
        cancelToken: source.token,
      });
      Modal.success({
        title: "สำเร็จ",
        content: "บันทึกข้อมูลเรียบร้อย",
        okText: "ตกลง",
        onOk() {
          if (
            type === "sale" ||
            type === "redeem" ||
            (type === "pre-sale" && data.payment.length > 0)
          ) {
            const { document_no_list } = res.data;
            for (let i = 0; i < document_no_list.length; i++) {
              // handlePrint(document_no_list[i]);
              printform(props.printform.form, document_no_list[i]);
            }
          }
          if (isReserve) return loadPreviewData(res.data.data.reserve_id);
          props.clear();
          props.clearRedeemPoint();
          props.history.replace(`/pos/${type}/operator`);
        },
      });
    } catch (e) {
      resCatchModal(e);
    }
    setLoading(false);
    setSource(axios.CancelToken.source());
  };

  const footer = (
    <Row type="flex" className="justify-content-center my-2">
      <Col span={4}>
        <Button
          block
          className="bg-nn border-nn text-white"
          onClick={() => {
            source.cancel("cancel");
            onCancel();
          }}
        >
          ยกเลิก
        </Button>
      </Col>
      <Col span={4} offset={1}>
        <Button block type="primary" loading={loading} onClick={sendData}>
          ยืนยัน{typeTextBtn}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      visible={visible}
      footerLine={true}
      footer={footer}
      closable={false}
      width={1000}
    >
      <div className="mb-0 text-dark py-2 w-100">
        <Row gutter={8}>
          <Col span={8} className="pl-4">
            <strong>ผู้ดำเนินการ</strong> {props.data.buyer_name}
          </Col>
          <Col span={6}>
            <strong className="mr-2">เบอร์โทรศัพท์</strong>{" "}
            {props.data.buyer_mobile}
          </Col>
          <Col span={10}>
            <strong className="mr-2">ประเภทผู้ดำเนินการ</strong>{" "}
            {props.data.buyer_type}
          </Col>
        </Row>
      </div>
      <h5 className="mb-0 px-4 py-2 text-white bg-gray-60">
        <strong>รายการสินค้า</strong>
      </h5>
      {Bill({
        orders: props.data.orders,
        summary_list: props.summary_list,
        is_staff: props.is_staff,
        is_normal: isNormal,
      })}
      {Summary({
        point_total: props.point_total,
        summary_total: props.summary_total,
        is_staff: props.is_staff,
        is_normal: isNormal,
        has_no_vip_member: _.some(
          props.data.orders.itemKeys,
          (order_key) =>
            props.data.orders.itemByKey[
              order_key
            ].partner_type?.toLowerCase() !== "member"
        ),
      })}
      {isNormal && (
        <>
          <h5 className="mb-0 px-4 py-2 text-white bg-gray-60">
            <strong>การชำระเงิน</strong>
          </h5>
          {Payment({
            voucher_payment: props.voucher_payment,
            payment: props.data.payment,
            summary_total: props.summary_total,
            payment_total: props.payment_total,
            cash_total: props.cash_total,
            balance_total: props.balance_total,
          })}
        </>
      )}
      <ReservePreview
        visible={showReservePreview}
        data={reservePreviewData}
        onCancel={handleCloseReservePreview}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { orders, payment, promotion_collect, buyer_type, printform } =
    state.pos.operation;
  const {
    summary_list,
    summary_total,
    payment_total,
    cash_total,
    point_total,
    voucher_total,
    voucher_payment,
  } = getSummaryTotalPayment(orders, payment);
  return {
    is_staff: buyer_type?.toLowerCase() === "staff",
    data: state.pos.operation,
    auth: state.auth,
    promotion_collect,
    summary_list,
    summary_total,
    payment_total,
    cash_total,
    point_total,
    voucher_total,
    voucher_payment,
    balance_total: summary_total - voucher_total,
    printform,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    clear: Creators.clear,
    clearRedeemPoint: PosDataAction.clearRedeemPoint,
  })(Preview)
);
