import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../../../../scss/module/inventory-count.module.scss";
import { _ } from "../../../../utils";

const InventoryTab = ({
  status,
  value,
  defaultValue,
  onChange,
  dataSource,
  loading,
}) => {
  const [active, setActive] = useState(defaultValue);

  useEffect(() => {
    value && setActive(value);
  }, [value]);

  return (
    <Row>
      <Col span={24}>
        <div className={styles.tab}>
          {_.map(dataSource, (n) => (
            <div
              key={n.key}
              className={`${styles.item} ${
                active === n.key ? styles.active : ""
              } ${loading ? styles.loading : ""}`}
              onClick={() => {
                if (loading) return;
                !value && setActive(n.key);
                onChange && onChange(n.key);
              }}
            >
              <div className={`${styles.radio} ${styles[n.status]}`} />
              <span>{n.name}</span>
              {n.key === active && (
                <Spin spinning={loading} size="small" className="ml-3" />
              )}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default InventoryTab;
