/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { videoFormDefaultValues, videoGender } from '../dto/myfocus';
import myFocusConstants from '../../constants/myFocusConstants';
import VideoUpload from '../upload/VideoUpload';
import ImageUpload from '../upload/ImageUpload';

const BodyFocusVideoForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const { execute: getBodyFocusVideoById } = useAsync(
    fitStudioService.getBodyFocusVideoById,
    {
      onSuccess: (data) => {
        reset({
          ...data,
          formType: 'edit',
        });
      },
    }
  );

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getBodyFocusVideoById({ videoId: watch('id') });
    } else {
      reset(videoFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={myFocusConstants.videoThumbnail.width}
                  height={myFocusConstants.videoThumbnail.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อวิดีโอ<span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำบรรยาย <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยาย"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="gender"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  เหมาะกับเพศ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {videoGender.map((item) => {
                    return (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="rewardId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Row className="align-items-center mb-2">
        <Col span={24}>
          <Controller
            name="videoUrl"
            control={control}
            render={({ field: { value, onChange } }) => {
              return <VideoUpload value={value} onChange={onChange} />;
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BodyFocusVideoForm;
