import { Col, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useQuery } from '../../../../../hooks/dom';
import styles from '../../../../../scss/module/legal.module.scss';

const ProfileMenu = ({ match }) => {
    const query = useQuery();
    const id = query.get('id');
    const type = query.get('membertype')
    const { path } = match;
    const [menu, setMenu] = useState('register');
    useEffect(() => {
        setMenu(path.split('/')[3]);
    }, [path]);
    return (
        <Row className={styles.menu}>
            <Link to={`/legal/profile/register?membertype=${type}&id=${id}`}>
                <Col span={type === 'distributor'? 6 : 8} className={`${styles.col} ${menu === 'register' ? styles.active : ''}`}>
                    <Typography.Text>ข้อมูลผู้สมัคร</Typography.Text>
                </Col>
            </Link>
            {type === 'distributor' && <Link to={`/legal/profile/married?membertype=${type}&id=${id}`}>
                <Col span={type === 'distributor'? 6 : 8} className={`${styles.col} ${menu === 'married' ? styles.active : ''}`}>
                    <Typography.Text>ข้อมูลผู้สมัครร่วม</Typography.Text>
                </Col>
            </Link>}
            
            <Link to={`/legal/profile/address?membertype=${type}&id=${id}`}>
                <Col span={type === 'distributor'? 6 : 8} className={`${styles.col} ${menu === 'address' ? styles.active : ''}`}>
                    <Typography.Text>ที่อยู่</Typography.Text>
                </Col>
            </Link>
            <Link to={`/legal/profile/bank?membertype=${type}&id=${id}`}>
                <Col span={type === 'distributor'? 6 : 8} className={`${styles.col} ${menu === 'bank' ? styles.active : ''}`}>
                    <Typography.Text>บัญชีธนาคาร</Typography.Text>
                </Col>
            </Link>
        </Row>
    )
}

export default withRouter(ProfileMenu);
