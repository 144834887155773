/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";
import moment from "moment";
import TextEditor from "../../../../../components/TextEditor";
import TextArea from "antd/lib/input/TextArea";
import { DivStyles, ImgStyles } from "../../components/mwStyles";
import useUploadFile from "../../components/useUploadFile";
import { dummyRequest } from "../../components/dummyRequest";

function News({ history, match, form, ...props }) {
  const [contentTextEditor, setContentTextEditor] = useState();
  const [loadValue, setLoadValue] = useState("");
  const [fileListDesktop, setFileListDesktop] = useState([]);

  const [fileDelete, setFileDelete] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  let { onUpload, onRemoveUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
          content: contentTextEditor,
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/news/news/" + match.params.id + "/update";
            } else {
              actionPathUrl = "/manage-website/news/news";
            }

            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleUploadDesktop = async (e) => {
    let isFile = await onUpload(e, true, true);
    if (isFile) {
      setFileListDesktop(isFile);
    }
  };

  const loadContent = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/news/news/" + match.params.id,
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        console.log(data);
        setLoadValue(data);

        setContentTextEditor(data.content);

        let { start_date, end_date, loadDesktopImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          }
        }

        form.setFieldsValue({
          ...data,
          start_date: start_date == null ? null : moment(start_date),
          end_date: end_date == null ? null : moment(end_date),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadCategory = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/news/category",
    })
      .then((res) => {
        console.log(res);
        let dataCategory = res.data?.data;

        for (let i in dataCategory) {
          setCategoryList((categoryList) => [
            ...categoryList,
            dataCategory[i].title,
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loadTag = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/news/tag",
    })
      .then((res) => {
        console.log(res);
        let dataTag = res.data?.data;

        for (let i in dataTag) {
          setTagList((tagList) => [...tagList, dataTag[i].title]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (match.params.id !== "add") {
      loadContent(match.params.id);
    }
    loadCategory();
    loadTag();
  }, [match.params.id]);

  const { getFieldDecorator } = form;

  return (
    <Panel>
      <PanelHeader></PanelHeader>
      <PanelBody>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Title">
                {getFieldDecorator("title", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ title" }],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item label="Brief Content">
              {getFieldDecorator("description", {
                initialValue: "",
                rules: [{ required: true, message: "กรุณาระบุ brief content" }],
              })(<TextArea />)}
            </Form.Item>
          </Row>

          <Row>
            <Row gutter={24} className="mb-2">
              <Col md={12} className="mb-2">
                <Form.Item label="Artwork ( 1 รูป ) : " name="desktop_img">
                  {getFieldDecorator("desktop_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListDesktop}
                      customRequest={dummyRequest}
                      onRemove={handleRemoveDesktop}
                      onChange={handleUploadDesktop}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
              <Col md={12}>
                {fileListDesktop.length != 0 ? (
                  <Row>
                    <div style={DivStyles}>
                      <img
                        src={fileListDesktop[0].url}
                        alt="desktop_img"
                        style={ImgStyles}
                      />
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Row>

          {/* <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="จำนวน content ">
                {getFieldDecorator("content_type", {
                  initialValue: 1,
                  rules: [
                    { required: true, message: "กรุณาระบุ จำนวน content" },
                  ],
                })(<InputNumber className="w-100" step="1" min={1} />)}
              </Form.Item>
            </Col>
          </Row> */}

          <Row className="mb-3">
            <TextEditor
              authToken={getToken()}
              value={contentTextEditor}
              onInit={(evt, event) => {}}
              onEditorChange={(e) => {
                setContentTextEditor(e);
              }}
              onUploadSuccess={(json) => {
                console.log(json);
              }}
              postBody={{ container_name: "web-content" }}
              urlUpload={`${URL_API}/file/upload`}
            />
          </Row>

          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Start Date">
                {getFieldDecorator("start_date", {
                  initialValue: null,
                  rules: [{ required: true, message: "กรุณาระบุ Start Date" }],
                })(
                  <DatePicker
                    showTime
                    className="w-100"
                    format="DD/MM/YYYY HH:mm"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="End Date">
                {getFieldDecorator("end_date", {
                  initialValue: null,
                  rules: [{ required: true, message: "กรุณาระบุ End Date" }],
                })(
                  <DatePicker
                    showTime
                    className="w-100"
                    format="DD/MM/YYYY HH:mm"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={6}>
              <Form.Item label="Category">
                {getFieldDecorator("content_type", {
                  initialValue: categoryList.length > 0 ? categoryList[0] : "",
                  rules: [{ required: true, message: "กรุณาระบุ category" }],
                })(
                  <Select
                    className="w-100"
                    allowClear
                    // value={e.target.value}
                    onChange={(e) => console.log(e)}
                  >
                    {categoryList.map((n) => (
                      <Select.Option key={n}>{n}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item label="Tag">
                {getFieldDecorator("target_link", {
                  initialValue: tagList.length > 0 ? tagList[0] : "",
                  rules: [{ required: true, message: "กรุณาระบุ tag" }],
                })(
                  <Select
                    className="w-100"
                    allowClear
                    // value={e.target.value}
                    onChange={(e) => console.log(e)}
                  >
                    {tagList.map((n) => (
                      <Select.Option key={n}>{n}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="PIN">
                {getFieldDecorator("target_tab", {
                  initialValue: "unpin",
                })(
                  <Radio.Group>
                    <Radio value={"unpin"}>UNPIN</Radio>
                    <Radio value={"pin"}>PIN</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24}>
              <Button
                className="bg-nn border-nn text-white mr-3"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={() => handleClickSubmit()}>
                {match.params.id !== "add" ? "Update" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}

export default Form.create("news-news")(News);
