/* eslint-disable no-unused-vars */
import { Checkbox, Col, Form, Input, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SelectApiData from "../../../../../../components/SelectApiData";
import { moment } from "../../../../../../utils";
import BillContext from "../../../reducer";

const { Text } = Typography;

const billCancelType = { process_type: "bill_cancel_type" };
const memberContact = { process_type: "member_contact" };

const Manage = ({ form }) => {
  const { getFieldDecorator } = form;
  const {
    state: {
      billDetail: { is_daily },
      acceptText,
    },
  } = useContext(BillContext);

  return (
    <Form name="bill-cancel">
      <Row gutter={[8, 8]}>
        <Col span={4}>
          <Text strong>ประเภทการยกเลิก</Text>
        </Col>
        <Col span={6}>
          <Form.Item className="mb-0">
            {getFieldDecorator("cancel_type", {
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกประเภทการยกเลิก",
                },
              ],
            })(
              <SelectApiData
                className="w-100"
                placeholder="เลือกประเภทการยกเลิก"
                apiPath="/pos/master/type"
                params={billCancelType}
                // value={cancel_type ?? undefined}
                // onChange={(cancel_type) =>
                //   dispatch({
                //     type: "setGlobalState",
                //     payload: { name: "data", value: { cancel_type } },
                //   })
                // }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={4}>
          <Text strong>เหตุผลการยกเลิก</Text>
        </Col>
        <Col span={20}>
          <Form.Item className="mb-0">
            {getFieldDecorator("reason", {
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุเหตุผลการยกเลิก",
                },
              ],
            })(
              <Input.TextArea
                maxLength={500}
                placeholder="เหตุผลการยกเลิก"
                autoSize={{ minRows: 3, maxRows: 5 }}
                // value={reason}
                // onChange={(e) =>
                //   dispatch({
                //     type: "setGlobalState",
                //     payload: {
                //       name: "data",
                //       value: { reason: e.target.value },
                //     },
                //   })
                // }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={20} offset={4}>
          <Form.Item className="mb-0">
            {getFieldDecorator("is_accept", {
              valuePropName: "checked",
              rules: [
                {
                  validator: (_rule, value, callback) =>
                    value
                      ? callback()
                      : callback("โปรดยอมรับข้อกำหนดและเงื่อนไข"),
                },
              ],
            })(
              <Checkbox
              // checked={is_accept}
              // onChange={(e) =>
              //   dispatch({
              //     type: "setGlobalState",
              //     payload: {
              //       name: "data",
              //       value: { is_accept: e.target.checked },
              //     },
              //   })
              // }
              >
                <Text className="font-size-10">
                  {is_daily ? acceptText.daily : acceptText.cycle}
                </Text>
              </Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={4}>
          <Text strong>ช่องทางติดต่อนักธุรกิจ</Text>
        </Col>
        <Col span={6}>
          <Form.Item className="mb-0">
            {getFieldDecorator("member_contact", {
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกช่องทางติดต่อนักธุรกิจ",
                },
              ],
            })(
              <SelectApiData
                className="w-100"
                placeholder="เลือกช่องทางติดต่อนักธุรกิจ"
                apiPath="/pos/master/type"
                params={memberContact}
                // value={member_contact ?? undefined}
                // onChange={(member_contact) =>
                //   dispatch({
                //     type: "setGlobalState",
                //     payload: { name: "data", value: { member_contact } },
                //   })
                // }
              />
            )}
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item className="mb-0">
            {getFieldDecorator("contact_detail", {
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุรายละเอียกการติดต่อเพิ่มเติม",
                },
              ],
            })(
              <Input
                maxLength={500}
                autoComplete="off"
                placeholder="รายละเอียกการติดต่อเพิ่มเติม"
                // value={contact_detail}
                // onChange={(e) =>
                //   dispatch({
                //     type: "setGlobalState",
                //     payload: {
                //       name: "data",
                //       value: { contact_detail: e.target.value },
                //     },
                //   })
                // }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create()(Manage);
