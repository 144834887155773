/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Modal, notification, Typography,message } from "antd";
import numeral from "numeral";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fixBranch } from "../../../../config/fix-value";
import { _, axios, getUserObj, URL_API } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { PosDataAction } from "../ducks/data-api";
import { Creators } from "../ducks/operation-form";
import MemberProfilePopup from "./MemberProfilePopup";
import ModalAddress from "./ModalAddress";
// import Notification from "./Notification";
import Radio from "./Radio";
import SelectAddress from "./SelectAddress";
import SelectBranch from "./SelectBranch";
import SelectSearch from "./SelectSearch";

const PosOrderHeader = ({ auth, match, ...props }) => {
  const user = getUserObj();
  const shipMobileRef = useRef(null);
  const [showProfile, setShowProfile] = useState(false);
  const [disableLowcost, setDisableLowcost] = useState(false);
  // const [showNoti, setShowNoti] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const [shipToList, setShipToList] = useState([]);
  const [shipToLoading, setShipToLoading] = useState(false);
  const type_params = match.params.type;
  const isNormal = _.includes(
    ["sale", "subscription", "pre-sale"],
    type_params
  );

  useEffect(() => {
    return () => source.cancel("unmounted");
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    props.setBillValue({ [name]: value });
    if (name === "order_type") props.delOrderPromotionAll();
  };

  useEffect(() => {
    if(props.partner_type === "Distributor"){
      if(props.total_pv >= 300){
        setDisableLowcost(true);
        if (props?.req_receipt_type === "Lowcost"){
          props.setBillValue({
            req_receipt_type: "Transport",
          });
          message.error('ไม่สามารถเลือกวิธีจัดส่งแบบ Low Cost Service ได้!');
        }
      }else{
        setDisableLowcost(false);
      }
    }else{
      if(props.total_unit_price >= 3000){
        setDisableLowcost(true);
        if (props?.req_receipt_type === "Lowcost"){
          props.setBillValue({
            req_receipt_type: "Transport",
          });
          message.error('ไม่สามารถเลือกวิธีจัดส่งแบบ Low Cost Service ได้!');
        }
      }else{
        setDisableLowcost(false);
      }
    }
  }, [props.req_receipt_type, props.total_pv]);
  // useEffect(() => {
  //   const receipt_branch_code = props.receipt_branch_code || auth.branch_code;
  //   const receipt_branch_name = props.receipt_branch_name || auth.branch_name;
  //   props.setBillValue({ receipt_branch_code, receipt_branch_name });
  // }, []);

  useEffect(() => {
    props.partner_code &&
      !isNormal &&
      props.loadRedeemPoint(props.partner_code);
  }, [props.partner_code, isNormal]);

  useEffect(() => {
    if (!props.req_receipt_type && !props.req_receipt) {
      if (type_params === "sale") {
        //sale
        props.setBillValue({ req_receipt: "1" });
      } else if (_.includes(["subscription", "pre-sale"], type_params)) {
        //booksale
        props.setBillValue({
          req_receipt_type: "Transport",
          req_receipt: null,
        });
      } else {
        let option = {};
        if (!_.includes(fixBranch.online, user.branch_code)) {
          //redeem
          option = {
            receipt_branch_code: user.branch_code,
            receipt_branch_name: user.branch_name,
          };
        }
        props.setBillValue({
          ...option,
          req_receipt_type: "Branch",
          req_receipt: "1",
          req_bag: "2",
        });
      }
    }
  }, [props.req_receipt_type, props.req_receipt, type_params]);

  useEffect(() => {
    if (props.partner_type === "Member") {
      props.setBillValue({ order_type: "Hold" });
    } else if (props.partner_type?.toLowerCase() === "staff") {
      props.setBillValue({ order_type: "Regular" });
    }
  }, [props.partner_type]);

  const color = useMemo(() => {
    let res = "";
    switch (type_params) {
      case "redeem":
        res = "rp";
        break;

      case "subscription":
        res = "tp";
        break;

      case "reserve":
        res = "cv";
        break;

      case "pre-sale":
        res = "sp";
        break;

      case "pre-redeem":
        res = "nn";
        break;

      default:
        res = "pv";
        break;
    }
    return res;
  }, [type_params]);

  const handleSelectAddress = (value, option) => {
    if (value === 0) {
      props.setBillValue({
        ship_to_id: value,
      });
      setShowAddAddress(true);
    } else {
      if (option.props.source.post_code === "")
        return notification.warning({
          message: "กรุณาเลือกที่อยู่ภายในประเทศ",
        });
      // axios({
      //   url: `${URL_API}/pos/operation/delivery`,
      //   params: {
      //     pv: props.total_pv,
      //     weight: props.total_weight,
      //     province_name: option.props.source.province,
      //   },
      //   cancelToken: source.token,
      // })
      //   .then((res) => {
      //     props.setBillValue({ total_delivery: res.data.data });
      //   })
      //   .catch((err) => {
      //     resCatch(err);
      //   });
      props.setBillValue({
        ship_to_id: value,
        ship_address_no: option.props.source.address,
        ship_sub_district: option.props.source.sub_district,
        ship_district: option.props.source.district,
        ship_province: option.props.source.province,
        ship_post_code: option.props.source.post_code,
        ship_country: option.props.source.country,
        ship_to: option.props.source.ship_to,
        ship_mobile: option.props.source.ship_to_mobile,
      });
    }
  };

  const handleChangeShip = (e) => {
    let { name, value } = e.target;
    if (name === "ship_mobile") {
      value = value.replace(/[^\d]+/g, "");
    }
    props.setBillValue({
      [name]: value,
    });
  };

  const handleClickSearch = async () => {
    setShipToLoading(true);
    let found = false;
    await axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        query: props.ship_to?.trim(),
      },
      cancelToken: source.token,
    })
      .then((res) => {
        setShipToLoading(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            let data = res.data.data[0];
            const status = data.status?.toLowerCase();
            if (!["active", "pending", "waiting", "expired"].includes(status)) {
              // permission = false
              Modal.warning({
                title: "ไม่สามารถดำเนินการได้",
                content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                okText: "ตกลง",
              });
              props.setBillValue({
                ship_to: null,
                ship_mobile: null,
              });
            } else {
              // permission true
              props.setBillValue({
                ship_to: `${data.first_name_th} ${data.sur_name_th}`,
                ship_mobile: data.mobile,
              });
              document.getElementById("ship_to_select_id").click();
              shipMobileRef.current.focus();
            }
            found = true;
          } else {
            setShipToList(res.data.data);
            document.getElementById("ship_to_select_id").click();
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        setShipToLoading(false);
        err.message !== "cancel search" && resCatch(err);
      });

    return found;
  };

  return (
    <>
      <div
        className={`pos-order-header bg-${color}-light`}
        style={{ overflowX: "scroll" }}
      >
        <div className="mb-2">
          <Typography.Text
            className="mr-4 text-dark"
            style={{ fontSize: "16px" }}
            strong
          >
            ผู้สั่งซื้อ
          </Typography.Text>

          <Typography.Text
            className="text-dark"
            strong
            style={{ fontSize: "14px" }}
          >
            รหัสสมาชิก
          </Typography.Text>
          <Typography.Text
            className="mx-3 text-dark"
            style={{ fontSize: "14px" }}
          >
            {props.partner_code}
          </Typography.Text>

          <Typography.Text
            className="text-dark"
            strong
            style={{ fontSize: "14px" }}
          >
            ชื่อ
          </Typography.Text>
          <Typography.Text
            className="mx-3 text-dark"
            style={{ fontSize: "14px" }}
          >
            {props.partner_name}
          </Typography.Text>

          {_.includes(["Distributor", "Member"], props.partner_type) && (
            <Button
              type="link"
              size="small"
              className="p-0"
              onClick={() => setShowProfile(true)}
            >
              <span className="d-flex align-items-center bg-pv p-4 text-white rounded-circle">
                <FaEye />
              </span>
            </Button>
          )}
          {/* <Button
            type="link"
            size="small"
            className="p-0 ml-2"
            onClick={() => setShowNoti(true)}
          >
            <span className="d-flex align-items-center bg-rp p-4 text-white rounded-circle">
              <FaRegBell />
            </span>
          </Button> */}
        </div>
        <div className="d-flex">
          <div>
            <div className="d-flex" style={{ lineHeight: "32px" }}>
              {isNormal && props.partner_type?.toLowerCase() !== "staff" && (
                <>
                  <div className="width-125">
                    <Typography.Text
                      className="text-dark"
                      style={{ fontSize: "16px" }}
                      strong
                    >
                      ประเภทการซื้อ
                    </Typography.Text>
                  </div>
                  <div className="width-125">
                    <span className="d-flex">
                      <Radio
                        id="regular"
                        name="order_type"
                        spanChild="Regular"
                        value="Regular"
                        checked={props.order_type === "Regular"}
                        onChange={handleOnChange}
                        className={`${
                          props.partner_type === "Member" ? "disabled" : ""
                        }`}
                        disabled={props.partner_type === "Member"}
                      />
                    </span>
                  </div>
                  <div className="width-125">
                    <span className="d-flex">
                      <Radio
                        id="hold"
                        name="order_type"
                        spanChild="Hold"
                        value="Hold"
                        checked={props.order_type === "Hold"}
                        onChange={handleOnChange}
                        className={`${
                          props.is_freeze ? "disabled" : ""
                        }`}
                        disabled={props.is_freeze}
                      />
                    </span>
                  </div>
                </>
              )}
              <div className="width-125">
                <Typography.Text
                  className="text-dark"
                  style={{ fontSize: "16px" }}
                  strong
                >
                  ประเภทการรับของ
                </Typography.Text>
              </div>
              <div className="width-125">
                <span className="d-flex">
                  <Radio
                    id="receive"
                    name="req_receipt_type"
                    spanChild="รับที่สาขา"
                    value="Branch"
                    checked={props.req_receipt_type === "Branch"}
                    onChange={(e) => {
                      let option = {};
                      if (!_.includes(fixBranch.online, user.branch_code)) {
                        option = {
                          receipt_branch_code: user.branch_code,
                          receipt_branch_name: user.branch_name,
                        };
                      }
                      const normal_only = isNormal
                        ? { req_bag: "1", req_receipt: "1" }
                        : {};
                      props.setBillValue({
                        ...option,
                        ...normal_only,
                        showItems: false,
                        req_receipt_type: e.target.value,
                        ship_to_id: null,
                        ship_address_no: null,
                        ship_sub_district: null,
                        ship_district: null,
                        ship_province: null,
                        ship_post_code: null,
                        ship_country: null,
                        ship_to: null,
                        ship_mobile: null,
                        total_delivery: 0,
                      });
                      props.delOrderPromotionAll();
                    }}
                  />
                </span>
              </div>
              <div className="width-200">
                <span className="d-flex">
                  <Radio
                    id="delivery"
                    name="req_receipt_type"
                    spanChild="จัดส่งโดยคลังบางนา"
                    value="Transport"
                    checked={props.req_receipt_type === "Transport"}
                    onChange={(e) => {
                      const normal_only = isNormal
                        ? { req_bag: null, req_receipt: null }
                        : {};
                      props.setBillValue({
                        ...normal_only,
                        allow_qr: false,
                        showItems: false,
                        req_receipt_type: e.target.value,
                        ship_address_no: null,
                        ship_sub_district: null,
                        ship_district: null,
                        ship_province: null,
                        ship_post_code: null,
                        ship_country: null,
                        ship_to_id: null,
                        ship_to: null,
                        ship_mobile: null,
                        ship_status: "0",
                        receipt_branch_code: null,
                        receipt_branch_name: null,
                        total_delivery: 0,
                      });
                      props.delOrderPromotionAll();
                    }}
                  />
                </span>
              </div>
              <div className="width-200">
                <span className="d-flex">
                  <Radio
                    id="lowcost"
                    name="req_receipt_type"
                    spanChild="จัดส่ง Low cost"
                    value="Lowcost"
                    disabled={disableLowcost || !isNormal}
                    className={`${disableLowcost || !isNormal ? "disabled" : ""}`}
                    checked={props.req_receipt_type === "Lowcost"}
                    onChange={(e) => {
                      const normal_only = isNormal
                        ? { req_bag: null, req_receipt: null }
                        : {};
                      props.setBillValue({
                        ...normal_only,
                        allow_qr: false,
                        showItems: false,
                        req_receipt_type: e.target.value,
                        ship_address_no: null,
                        ship_sub_district: null,
                        ship_district: null,
                        ship_province: null,
                        ship_post_code: null,
                        ship_country: null,
                        ship_to: null,
                        ship_to_id: null,
                        ship_mobile: null,
                        ship_status: "0",
                        receipt_branch_code: null,
                        receipt_branch_name: null,
                        total_delivery: 0,
                      });
                      props.delOrderPromotionAll();
                    }}
                  />
                </span>
              </div>
              
            </div>
            <div className="d-flex" style={{ lineHeight: "42px" }}>
              {_.includes(["sale", "subscription", "pre-sale"], type_params) ? (
                <>
                  <div className="width-125">
                    <Typography.Text
                      className="text-dark"
                      style={{ fontSize: "16px" }}
                      strong
                    >
                      ใบเสร็จ
                    </Typography.Text>
                  </div>
                  <div className="width-125">
                    <span className="d-flex">
                      <Radio
                        id="bill"
                        name="req_receipt"
                        spanChild="รับใบเสร็จ"
                        value="1"
                        checked={props.req_receipt === "1"}
                        onChange={handleOnChange}
                      />
                    </span>
                  </div>
                  <div className="width-125">
                    <span className="d-flex">
                      <Radio
                        id="un-bill"
                        name="req_receipt"
                        spanChild="ไม่รับใบเสร็จ"
                        value="2"
                        checked={props.req_receipt === "2"}
                        onChange={handleOnChange}
                      />
                    </span>
                  </div>
                  <div className="width-125">
                    <Typography.Text
                      className="text-dark"
                      style={{ fontSize: "16px" }}
                      strong
                    >
                      ประเภทการรับถุง
                    </Typography.Text>
                  </div>
                  <div className="width-125">
                    <span className="d-flex">
                      <Radio
                        id="bag"
                        name="req_bag"
                        spanChild="รับถุง"
                        value="1"
                        checked={props.req_bag === "1"}
                        onChange={handleOnChange}
                      />
                    </span>
                  </div>
                  <div className="width-125">
                    <span className="d-flex">
                      <Radio
                        id="un-bag"
                        name="req_bag"
                        spanChild="ไม่รับถุง"
                        value="2"
                        checked={props.req_bag === "2"}
                        onChange={handleOnChange}
                      />
                    </span>
                  </div>
                </>
              ) : (
                <div className="width-400">
                  <Typography.Text
                    strong
                    className="text-dark"
                    style={{ fontSize: "14px" }}
                  >
                    คะแนนพร้อมใช้
                  </Typography.Text>
                  <Typography.Text
                    className="text-dark mx-3"
                    style={{ fontSize: "14px" }}
                  >
                    {numeral(props.redeem_point.rp_topup).format("0,0.00")}
                  </Typography.Text>
                  <Typography.Text
                    strong
                    className="text-dark"
                    style={{ fontSize: "14px" }}
                  >
                    คะแนนพร้อมโอน
                  </Typography.Text>
                  <Typography.Text
                    className="text-dark mx-3"
                    style={{ fontSize: "14px" }}
                  >
                    {numeral(props.redeem_point.rp_hold).format("0,0.00")}
                  </Typography.Text>
                </div>
              )}
              {!!props.req_receipt_type && (
                  <>
                  <div className="width-80">
                    <Typography.Text
                      className="text-dark"
                      style={{ fontSize: "16px" }}
                      strong
                    >
                      {props.req_receipt_type === "Branch"
                        ? "สาขาที่รับ"
                        : "ที่อยู่"}
                    </Typography.Text>
                  </div>
                  <div className="width-200">
                    {props.req_receipt_type === "Branch" ? (
                      <SelectBranch
                        className="width-150"
                        placeholder="เลือกสาขา"
                        value={
                          props.receipt_branch_code
                            ? props.receipt_branch_code
                            : undefined
                        }
                        disableOnlineBranch
                        onChange={(value, option) => {
                          props.setBillValue({
                            receipt_branch_code: value,
                            receipt_branch_name: option.props.children,
                          });
                          props.delOrderPromotionAll();
                        }}
                      />
                    ) : (
                      <SelectAddress
                        member_id={
                          props.partner_type_code === "Staff"
                            ? 0
                            : props.member_id
                        }
                        member_type={props.partner_type}
                        placeholder="เลือกที่อยู่"
                        value={props.ship_to_id ? props.ship_to_id : undefined}
                        onChange={handleSelectAddress}
                        // style={{ maxWidth: "100%", width: "auto" }}
                        // selectDefaultValue={type_params === 'subscription'}
                      />
                    )}
                  </div>
                  </>
              )}
            </div>
          </div>

          {_.includes(["Transport", "Lowcost"], props.req_receipt_type) ? (
            <AddressDetail {...props} />
          ) : (
            props.req_receipt_type === "Branch" && (
              <div>
                <div className="d-flex mb-2">
                  <div className="width-100">
                    <Typography.Text
                      strong
                      className="text-dark"
                      style={{ fontSize: "14px" }}
                    >
                      ผู้รับ
                    </Typography.Text>
                  </div>
                  <div className="width-300">
                    <SelectSearch
                      size="small"
                      selectId="ship_to_select_id"
                      placeholder="รหัสสมาชิก, เลขบัตรประชาชน, ชื่อ, อีเมล, เบอร์โทร"
                      loadList={shipToList}
                      loading={shipToLoading}
                      disabled={shipToLoading}
                      value={props.ship_to || undefined}
                      onClickSearch={() => props.ship_to && handleClickSearch()}
                      onClickCancel={() => {
                        source.cancel("cancel search");
                        setSource(axios.CancelToken.source());
                      }}
                      onChange={() => shipMobileRef.current.focus()}
                      onInputKeyDown={(e) => {
                        const key = e.key;
                        if (
                          key === "Enter" &&
                          shipToList.length === 0 &&
                          props.ship_to
                        ) {
                          handleClickSearch();
                        } else if (key === "Backspace" || key === "Delete") {
                          setShipToList([]);
                          props.setBillValue({
                            ship_to: null,
                            ship_mobile: null,
                          });
                        }
                      }}
                      onSearch={(e) => {
                        if (e) {
                          props.setBillValue({
                            ship_to: e.trim().slice(0, 255),
                          });
                          setShipToList([]);
                        }
                      }}
                      onSelect={(i) => {
                        const data = shipToList[+i];
                        const status = data.status?.toLowerCase();
                        if (
                          !["active", "pending", "waiting", "expired"].includes(
                            status
                          )
                        ) {
                          // permission = false
                          Modal.warning({
                            title: "ไม่สามารถดำเนินการได้",
                            content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                            okText: "ตกลง",
                          });
                          props.setBillValue({
                            ship_to: null,
                            ship_mobile: null,
                          });
                        } else {
                          // permission true
                          props.setBillValue({
                            ship_to: `${data.first_name_th} ${data.sur_name_th}`,
                            ship_mobile: data.mobile,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="width-100">
                    <Typography.Text
                      strong
                      className="text-dark"
                      style={{ fontSize: "14px" }}
                    >
                      เบอร์โทรผู้รับ
                    </Typography.Text>
                  </div>
                  <div className="width-300">
                    <Input
                      ref={shipMobileRef}
                      style={{ maxWidth: "300px" }}
                      placeholder="เบอร์โทรผู้รับ"
                      size="small"
                      name="ship_mobile"
                      autoComplete="off"
                      maxLength={10}
                      value={props.ship_mobile}
                      onChange={handleChangeShip}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {_.includes(["Distributor", "Member"], props.partner_type) && (
        <MemberProfilePopup
          visible={showProfile}
          setVisible={setShowProfile}
          partnerCode={props.partner_code}
          refId={props.member_id}
          refType={props.partner_type}
        />
      )}

      {/* <Notification visible={showNoti} onCancel={() => setShowNoti(false)} /> */}

      <ModalAddress
        visible={showAddAddress}
        setShowAddAddress={setShowAddAddress}
      />
    </>
  );
};

const AddressDetail = (props) => (
  <div>
    {props.ship_to && props.ship_to_id !== undefined && (
      <div className="width-400">
        <Typography.Text
          strong
          className="text-dark"
          style={{ fontSize: "14px" }}
        >
          ผู้รับ
        </Typography.Text>
        <Typography.Text
          className="mx-3 text-dark"
          style={{ fontSize: "14px" }}
        >
          {props.ship_to}
        </Typography.Text>
      </div>
    )}
    {props.ship_mobile && props.ship_to_id !== undefined && (
      <div className="width-400">
        <Typography.Text
          strong
          className="text-dark"
          style={{ fontSize: "14px" }}
        >
          เบอร์โทรผู้รับ
        </Typography.Text>
        <Typography.Text
          className="mx-3 text-dark"
          style={{ fontSize: "14px" }}
        >
          {props.ship_mobile}
        </Typography.Text>
      </div>
    )}

    {props.ship_address_no && props.ship_to_id !== undefined && (
      <div className="width-400">
        <Typography.Text className="text-dark font-size-12">
          {`${props.ship_address_no} ${props.ship_sub_district} ${props.ship_district} ${props.ship_province} ${props.ship_post_code}`}
        </Typography.Text>
      </div>
    )}
  </div>
);

const mapStateToProps = (state) => {
  const orders = state.pos.operation.orders;
  const { activeKey, itemByKey } = orders;
  return {
    ..._.omit(itemByKey[activeKey], ["items"]),
    redeem_point: state.pos.data.redeem_point,
    order_item: itemByKey[activeKey]?.items || null,
    auth: state.auth,
    total_weight:
      itemByKey[activeKey] &&
      itemByKey[activeKey].items &&
      _.reduce(
        itemByKey[activeKey].items.itemByKey,
        (sum, n) => (sum = sum + (n.weight || 0) * (n.qty || 0)),
        0
      ),
    total_pv:
      itemByKey[activeKey] &&
      itemByKey[activeKey].items &&
      _.reduce(
        itemByKey[activeKey].items.itemByKey,
        (sum, n) => (sum = sum + (n.pv || 0) * (n.qty || 0)),
        0
      ),
    total_unit_price:
      itemByKey[activeKey] &&
      itemByKey[activeKey].items &&
      _.reduce(
        itemByKey[activeKey].items.itemByKey,
        (sum, n) => (sum = sum + (n.unit_price || 0) * (n.qty || 0)),
        0
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setBillValue: Creators.setBillValue,
    delOrderPromotionAll: Creators.delOrderPromotionAll,
    loadRedeemPoint: PosDataAction.loadRedeemPoint,
  })(PosOrderHeader)
);
