import React from "react";
import { Alert, Select } from "antd";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, _ } from "../../../../../utils";

const SelectStatus = ({
  className,
  withAll,
  onlyPending,
  typeParams,
  ...props
}) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/transfer-receive/master/status`,
      params: {
        type_params: typeParams,
        only_pending: onlyPending ? "1" : "",
      },
      token: true,
    },
    [onlyPending, typeParams]
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select loading={loading} className={`w-100 ${className || ""}`} {...props}>
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {_.map(data?.data, (n) => (
        <Select.Option key={n.code} value={n.code}>
          {n.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectStatus;
