/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Button, Col, Form, Modal, notification, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";

import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { dummyRequest } from "../../../components/dummyRequest";
import { DivStyles, ImgStyles } from "../../../components/mwStyles";
import useUploadFile from "../../../components/useUploadFile";

function RP_ReviewIcon({ history, match, form, ...props }) {
  const [contentTextEditor, setContentTextEditor] = useState();
  const [loadValue, setLoadValue] = useState("");
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);
  const [amountSeq, setAmountSeq] = useState(0);

  const desktopWidth = 1280;
  const desktopHeight = 150;

  let { onUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
          content: contentTextEditor,
          file_delete: fileDelete,
          seq: _.toNumber(amountSeq) * _.toNumber(-1),
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/ecosystem/reward-point/rp-review/icon/" +
                match.params.id +
                "/update";
            } else {
              actionPathUrl =
                "/manage-website/ecosystem/reward-point/rp-review/icon";
            }

            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleChangeUploadDesktop = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop(isFile);
      // ------------------------warning whern upload img another size and accept to upload::
      // const { width, height } = await reactImageSize(isFile[0].url);
      // console.log(width,height)
      // if (width !== desktopWidth || height !== desktopHeight) {
      //   Modal.warning({
      //     title: "ขนาดรูปภาพที่อัพโหลดมาไม่ถูกต้อง !",
      //     content: `ขนาดภาพที่อัพโหลดมา ${width} x ${height} px`,
      //   });
      // }
    }
  };

  const loadContent = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url:
        "/manage-website/ecosystem/reward-point/rp-review/icon/" +
        match.params.id,
    })
      .then((res) => {
        let data = res.data?.data;
        setLoadValue(data);

        let { loadDesktopImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSeq = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/reward-point/rp-review/icon",
    })
      .then((res) => {
        let data = res.data?.data;
        setAmountSeq(data.length + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadSeq();
    if (match.params.id !== "add") {
      loadContent(match.params.id);
    } else {
    }
  }, [match.params.id]);

  const { getFieldDecorator } = form;

  return (
    <Panel>
      <PanelHeader></PanelHeader>
      <PanelBody>
        <Form layout="vertical">
          <Row>
            <Row gutter={24} className="mb-2">
              <Col md={12} className="mb-2">
                <Row>
                  {fileListDesktop.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop[0].url}
                          alt="desktop_img"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>
                <Form.Item
                  label={`เพิ่มรูปภาพ Icons ขนาดแนะนำ ${desktopWidth} x ${desktopHeight} px`}
                >
                  {getFieldDecorator("desktop_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListDesktop}
                      customRequest={dummyRequest}
                      onRemove={handleRemoveDesktop}
                      onChange={handleChangeUploadDesktop}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Row>

          <Row gutter={16}>
            <Col md={24}>
              <Button
                className="bg-nn border-nn text-white mr-3"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={() => handleClickSubmit()}>
                {match.params.id !== "add" ? "Update" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}

export default Form.create("rp-review-icon")(RP_ReviewIcon);
