/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { useHttp } from '../../../../../../hooks/http';
import { URL_API } from '../../../../../../utils';
import MemberList from './MemberList';
import Tabs from "./Tabs";

const ElementSetA = ({ data, handleChange }) => {
    const [show, setShow] = useState(false)
    const [activeTab, setActiveTab] = useState('Highest Pin')
    const [checkedList, setCheckedList] = useState([]);
    const [plainOptions, setPlainOptions] = useState([]);

    const [loadingMasterHonor, masterHonor, errorMasterHonor] = useHttp({
        method: 'GET',
        url: `${URL_API}/system/master/honor`,
        token: true
    }, []);

    if (errorMasterHonor) {
        notification.error({
            message: 'error',
            description: errorMasterHonor,
        });
    }

    useEffect(() => {
        if (data.honor.length > 0) {
            setCheckedList(data.honor)
        }
    }, [data, plainOptions])

    useEffect(() => {
        let list = [];
        if (!loadingMasterHonor) {
            masterHonor && masterHonor.data.forEach(item => {
                const { honor_code } = item;
                if (honor_code) {
                    list.push({ label: item.honor_name, value: item.honor_code })
                }
            });
            setPlainOptions(list);
        }
    }, [masterHonor])

    const onChange = e => {
        setCheckedList(e)
        handleChange({
            target: {
                name: "honor",
                value: e
            }
        });
    }
    const onChangeMemberPosition = (e) => {
        setActiveTab(e)
        setCheckedList([]);
        handleChange({
            target: {
                name: "member_position",
                value: e
            }
        });
    }
    return (
        <>
            <Element
                style={{ paddingRight: '5px' }}
            >
                <div className="container shadow-sm p-15 mb-4 bg-white rounded">
                    <div className="pl-2 pr-2" style={{ borderLeft: '5px solid #97D700' }}>
                        <div style={{ fontSize: 18, fontStyle: 'bold' }}>
                            รับโปรโมชั่นตามตำแหน่งของนักธุรกิจ
                    </div>
                    </div>
                    <div className="pl-2 pr-2">
                        <Tabs active={activeTab} onClick={e => onChangeMemberPosition(e)} />
                        <div className="pl-5 mt-2 mb-2">
                            <Checkbox.Group
                                style={{ display: 'grid' }}
                                options={plainOptions}
                                value={checkedList}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="pl-2 pr-2" style={{ borderLeft: '5px solid #97D700' }}>
                        <div style={{ fontSize: 18, fontStyle: 'bold' }}>
                            รับโปรโมชั่นตามรหัสนักธุรกิจ
                     </div>
                    </div>
                    <div className="pl-2 pr-2">
                        <div className="text-center mt-2 mb-2">
                            <Button type="primary" onClick={() => setShow(true)}>เลือก</Button>
                        </div>
                    </div>
                    <div className="pl-2 pr-2" style={{ borderLeft: '5px solid #97D700' }}>
                        <div style={{ fontSize: 18, fontStyle: 'bold' }}>
                            รับโปรโมชั่นตามการรักษายอดของนักธุรกิจ
                    </div>
                    </div>
                    <div className="pl-2 pr-2">
                        <div className="d-flex align-items-md-baseline mt-2 mb-2 justify-content-md-center" >
                            <label className="f-s-13 pr-2">รักษายอดตั้งแต่</label>
                            <Input name="member_point" value={data.member_point} className="w-50" onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </Element>
            <MemberList
                title="เลือกสมาชิก"
                visible={show}
                width={900}
                onCancel={() => setShow(false)}
                onOk={() => setShow(false)}
            />
        </>

    )
}
export default ElementSetA;
