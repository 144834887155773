import { Col, Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const { Text } = Typography;

const fontStyle = { fontSize: "1.125rem" };

const Result = () => {
  const consider_status = useSelector(
    (state) => state.claim.documentDetail.consider_status
  );
  const status = useSelector((state) => state.claim.documentDetail.status);

  return (
    <Row gutter={[8, 8]}>
      <Col span={24} className="font-size-14">
        <Text strong className="mr-2" style={fontStyle}>
          ผลพิจารณา :
        </Text>

        {status === "cancel" ? (
          <Text strong className="text-cv" style={fontStyle}>
            ยกเลิก
          </Text>
        ) : consider_status === "approve" ? (
          <Text strong className="text-pv" style={fontStyle}>
            อนุมัติ
          </Text>
        ) : (
          consider_status === "reject" && (
            <Text strong className="text-nn" style={fontStyle}>
              ไม่อนุมัติ
            </Text>
          )
        )}
      </Col>
    </Row>
  );
};

export default Result;
