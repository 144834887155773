import { Button } from "antd";
import React, { useContext } from "react";
import PreOrderContext from "../../reducer";

const CancelByMember = () => {
  const { dispatch } = useContext(PreOrderContext);
  return (
    <Button
      className="bg-nn border-nn text-white mr-3 width-150"
      onClick={() =>
        dispatch({
          type: "SET_GLOBAL_STATE",
          payload: { name: "billModal", value: { cancel_modal_visible: true } },
        })
      }
    >
      ยกเลิกโดยนักธุรกิจ
    </Button>
  );
};

export default CancelByMember;
