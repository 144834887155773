/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, notification, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { axios, getToken, URL_API } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";

const { Text } = Typography;

const { clearCreate } = actionTransfer;

const SubmitConsider = ({ backUrl, setLoadingSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const document = useSelector((state) => state.transfer.document);

  const handleClickConsider = (status) => {
    let data_text, text_style;
    switch (status) {
      case "wait-receive":
        text_style = "text-pv";
        data_text = "อนุมัติ";
        break;
      case "return":
        text_style = "text-rp";
        data_text = "ตรวจสอบใหม่";
        break;
      default:
        return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        setLoadingSubmit(true);
        axios({
          method: "post",
          url: `${URL_API}/transfer-receive/transfer/consider`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            status,
            document_no: document.document_no,
            remark: document.remark,
            document_status: document.status,
          },
        })
          .then(() => {
            dispatch(clearCreate());
            notification.success({
              message: "Success",
              description: `บันทึกข้อมูล${data_text}โอนย้ายสินค้าเรียบร้อย`,
            });
            history.push({ pathname: backUrl });
          })
          .catch((e) => resCatchModal(e))
          .finally(() => setLoadingSubmit(false));
      },
    });
  };

  return (
    <>
      <Button
        className="bg-rp border-rp text-white width-150 mx-3"
        onClick={() => handleClickConsider("return")}
      >
        ตรวจสอบใหม่
      </Button>
      <Button
        className="bg-pv border-pv text-white width-150"
        onClick={() => handleClickConsider("wait-receive")}
      >
        อนุมัติ
      </Button>
    </>
  );
};

export default memo(SubmitConsider);
