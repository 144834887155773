import { Col, Row, Switch, Typography } from 'antd'
import React from 'react'
import { Element } from 'react-scroll'
const ElementSetB = ({ data, handleChange }) => {
    const orderTypeChange = async (value, name) => {
        handleChange({
            target: {
                name, value
            }
        })
    }
    return (
        <Element
            style={{ paddingRight: '5px' }}
        >
            <div className="container shadow-sm p-15 mb-4 bg-white rounded">
                <div className="pl-2 pr-2" style={{ borderLeft: '5px solid #97D700' }}>
                    <div style={{ fontSize: 18, fontStyle: 'bold' }}>
                        รับโปรโมชั่นตามประเภทการสั่งซื้อ
                    </div>
                </div>
                <div className="pl-2 pr-2">
                    <Row className="mb-3 mt-3" type="flex">
                        <Col md={12}>
                            <Typography.Text strong>Hold</Typography.Text>
                        </Col>
                        <Col md={12} style={{ textAlign: 'end' }}>
                            <Switch defaultChecked={data.hold} onChange={e => orderTypeChange(e, 'hold')} />
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-3" type="flex">
                        <Col md={12}>
                            <Typography.Text strong>Regular</Typography.Text>
                        </Col>
                        <Col md={12} style={{ textAlign: 'end' }}>
                            <Switch defaultChecked={data.regular} onChange={e => orderTypeChange(e, 'regular')} />
                        </Col>
                    </Row>

                </div>
                <div className="pl-2 pr-2" style={{ borderLeft: '5px solid #97D700' }}>
                    <div style={{ fontSize: 18, fontStyle: 'bold' }}>
                        การใช้สิทธิ์แลกซื้อ
                    </div>
                </div>
                <div className="pl-2 pr-2">
                    <Row className="mb-3 mt-3" type="flex">
                        <Col md={12}>
                            <Typography.Text strong>สามารถใช้ได้ครั้งต่อไป</Typography.Text>
                        </Col>
                        <Col md={12} style={{ textAlign: 'end' }}>
                            <Switch defaultChecked={data.redeem_next} onChange={e => orderTypeChange(e, 'redeem_next')} />
                        </Col>
                    </Row>
                </div>
            </div>
        </Element>
    )
}
export default ElementSetB;
