/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Table, Typography } from "antd";
import React from "react";
import { FaCogs, FaEye, FaPencilAlt, FaPrint } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { actionRequisition } from "../../../../redux/slices/requisition";
import { _, getUserObj, moment } from "../../../../utils";
import { printform } from "../../../../utils/printforms";

const { Text } = Typography;
const { Column } = Table;

const { setGlobalState } = actionRequisition;

const getStatusStyle = (status) => {
  let color = "bg-gray-40";
  switch (status) {
    case "wait-account":
      color = "bg-rp";
      break;
    case "wait-amd":
      color = "bg-sp";
      break;
    case "wait-md":
      color = "bg-nn";
      break;
    case "return":
      color = "bg-gray-80";
      break;
    default:
      if (
        _.includes(
          [
            "wait-manager-benefit",
            "wait-manager-marketing",
            "wait-manager-satisfied",
            "wait-manager-another-8",
            "wait-manager-healthy-food",
            "wait-manager-finance",
            "wait-manager-other",
          ],
          status
        )
      )
        color = "bg-tp";
      break;
  }
  return `text-white px-3 border-radius-16 ${color}`;
};

const getConsiderStatusStyle = (consider_status) => {
  let color = "bg-gray-40";
  switch (consider_status) {
    case "approve":
      color = "bg-pv";
      break;
    case "reject":
      color = "bg-nn";
      break;
    default:
      break;
  }
  return `text-white px-3 border-radius-16 ${color}`;
};

const DocumentTable = ({ loading, data = [], total = 0 }) => {
  const { process: process_params } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { permission_status, permission_manager_other, permission_amd } =
    useSelector((state) => state.requisition.user);
  const { page, limit } = useSelector(
    (state) => state.requisition.searchDocument
  );
  const isProcessPending = process_params === "pending";

  const renderEdit = ({
    document_no,
    status,
    department_code,
    amd_consider_id,
    requisition_by_id,
  }) => {
    if (process_params !== "pending") return;
    if (!permission_status[status]) return;
    if (status === "wait-manager-other") {
      if (!permission_manager_other[department_code]) return;
    } else if (status === "wait-amd") {
      if (amd_consider_id) {
        const user = getUserObj();
        if (user.user_id !== amd_consider_id) return;
      } else if (!permission_amd[department_code]) return;
    } else if (status === "return") {
      const user = getUserObj();
      if (user.user_id !== requisition_by_id) return;
    }
    return (
      <Button
        shape="circle"
        size="small"
        className="bg-tp border-tp text-white mr-2"
        onClick={() =>
          history.push({
            pathname: `${process_params}/${document_no}`,
          })
        }
      >
        <FaPencilAlt />
      </Button>
    );
  };

  return (
    <Table
      size="small"
      bordered
      loading={loading}
      dataSource={data}
      rowKey="row_id"
      scroll={{ x: "max-content" }}
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showLessItems: true,
        hideOnSinglePage: true,
        onChange: (page) =>
          dispatch(setGlobalState({ name: "searchDocument", value: { page } })),
      }}
    >
      <Column
        width={60}
        title="#"
        key="row_id"
        dataIndex="row_id"
        align="center"
      />
      <Column
        width={90}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">เลขที่ใบเบิก</div>
        }
        key="document_no"
        dataIndex="document_no"
      />
      <Column
        width={140}
        title="วันที่เอกสาร"
        align="center"
        key="document_date"
        render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
        dataIndex="document_date"
      />
      <Column
        width={100}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">รหัสพนักงาน</div>
        }
        key="requisition_by_code"
        dataIndex="requisition_by_code"
        render={(text) => <div className="min-width-100">{text}</div>}
      />
      <Column
        width={data.length === 0 ? 150 : undefined}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">ชื่อพนักงาน</div>
        }
        key="requisition_by_name"
        dataIndex="requisition_by_name"
        render={(text) => <div className="min-width-100">{text}</div>}
      />
      <Column
        width={200}
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">แผนก</div>}
        key="requisition_by_department"
        dataIndex="requisition_by_department"
      />
      <Column
        width={200}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">ประเภทการเบิก</div>
        }
        key="requisition_type_name"
        dataIndex="requisition_type_name"
      />
      <Column
        width={200}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">คลังสินค้า</div>
        }
        key="warehouse_name"
        dataIndex="warehouse_name"
      />
      <Column
        width={isProcessPending ? 240 : 150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center border-round">
            {isProcessPending ? "สถานะ" : "ผลพิจารณา"}
          </div>
        }
        key="status_text"
        render={({ status_text, status, consider_status, user_consider }) =>
          isProcessPending ? (
            <Row>
              <Col span={24} className="text-center">
                <Text className={getStatusStyle(status)}>{status_text}</Text>
              </Col>
              <Col span={24}>
                <Text className="font-size-10">
                  {`รอทำการ${status === "return" ? "แก้ไข" : "อนุมัติ"}โดย: `}
                  <Text strong>
                    {_.join(
                      _.map(user_consider, (n) => n.name),
                      ", "
                    )}
                  </Text>
                </Text>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={24} className="text-center">
                <Text className={getConsiderStatusStyle(consider_status)}>
                  {consider_status === "approve"
                    ? "อนุมัติ"
                    : consider_status === "reject"
                    ? "ไม่อนุมัติ"
                    : null}
                </Text>
              </Col>
            </Row>
          )
        }
      />

      <Column
        width={80}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">
            <FaCogs />
          </div>
        }
        key="action"
        align="right"
        render={(record) => {
          const {
            document_no,
            status,
            department_code,
            amd_consider_id,
            requisition_by_id,
          } = record;
          return (
            <>
              {renderEdit({
                document_no,
                status,
                department_code,
                amd_consider_id,
                requisition_by_id,
              })}
              <Button
                shape="circle"
                type="primary"
                size="small"
                onClick={() => {
                  history.push({
                    pathname: `${process_params}/${document_no}`,
                    search: "?preview=1",
                  });
                }}
              >
                <FaEye />
              </Button>
              {!isProcessPending && (
                <Button
                  shape="circle"
                  size="small"
                  className="bg-rp border-rp text-white ml-2"
                  onClick={() => {
                    // handlePrint(record)
                    printform("requisition", record);
                  }}
                >
                  <FaPrint />
                </Button>
              )}
            </>
          );
        }}
      />
    </Table>
  );
};

export default DocumentTable;
