/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import * as XLSX from "xlsx-js-style";
import SelectProduct from "../../components/SelectProduct";
import SelectReason from "../../../transfer-receive/components/ManageHeader/SelectReason";
const { Option } = Select;

const Index = () => {
  const user = getUser();

  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: [user ? user.branch_code : ""],
      query: "",
      page: 1,
      limit: 20,
    },
    summaryModal: {
      visible: false,
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);

  const [filterDataSource, setFilterDataSource] = useState([]);
  const [productFilter, setProductFilter] = useState([]);
  const [fromWhFilter, setFromWhFilter] = useState(
    `${getUser().branch_code}-00`
  );
  const [toWhFilter, setToWhFilter] = useState("");

  const [fromWhFilterText, setFromWhFilterText] = useState(
    `${getUser().branch_name} - ของดี`
  );
  const [toWhFilterText, setToWhFilterText] = useState("ทั้งหมด");



  const toDay = moment().format("DD/MM/YYYY");
  const limit = 50;



  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-transfer-warehouse-to-branch`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        fromWhFilter: fromWhFilter,
        toWhFilter: toWhFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/inventory-transfer-filter`,
      token: true,
      params: {
        userId: user.id,
      },
    },
    []
  );

  useEffect(() => {
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);


  const whOptions = [
    ..._.map(filterDataSource.dataWhCode, (wh) => {
      return { value: wh.warehouse_code, text: wh.location };
    }),
  ];

  const exportToExcel = () => {
    let title_table = [
      "Order Date",
      "Order ID",
      "customerId",
      "Name",
      "Phone",
      "customerAddress",
      "subDistric",
      "distric",
      "province",
      "zipcode",
      "BRANCH_CODE",
      "BRANCH_NAME",
      "paymentChannel",
      "totalAmount",
      "receiptNo",
      "deliveryDate",
      "deliveryNote",
      "Acount ID",
      "Product_ID",
      "Quantity",
      "unitPrice",
    ];



    const exportData = data?.data ? data?.data : [];
    if (exportData.length !== 0) {
      let excelData = [
        title_table,
      ];

      exportData.forEach((n) => {
        let row_data = [
          {
            v: n.order_date
              ? moment(n.order_date).format("DD/MM/YYYY")
              : "",
          },
          { v: n.order_id ? n.order_id : "" },
          { v: n.customer_id ? n.customer_id : "" },
          { v: n.name ? n.name : "" },
          { v: n.phone ? n.phone : "" },
          { v: n.customer_address ? n.customer_address : "" },
          { v: n.sub_district ? n.sub_district : "" },
          { v: n.district ? n.district : "" },
          { v: n.province ? n.province : "" },
          { v: n.zip_code ? n.zip_code : "" },
          { v: n.branch_code ? n.branch_code : "" },
          { v: n.branch_name ? n.branch_name : "" },
          { v: n.payment_channel ? n.payment_channel : "" },
          {
            v: n.total_amount ? n.total_amount : 0,
            s: { alignment: { horizontal: "right" } },
          },
          { v: n.receipt_no ? n.receipt_no : "" },
          { v: n.delivery_date ? n.delivery_date : "" },
          { v: n.delivery_note ? n.delivery_note : "" },
          { v: n.acount_id ? n.acount_id : "" },
          { v: n.product_code ? n.product_code : "" },
          {
            v: n.qty ? n.qty : 0,
            s: { alignment: { horizontal: "right" } },
          },
          { v: n.unit_price ? n.unit_price : "" },
        ];

        excelData = [...excelData, row_data];
      });

    //   const merge = [
    //     { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
    //     { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
    //     { s: { r: 1, c: 3 }, e: { r: 1, c: 5 } },
    //     { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
    //     { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
    //     { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
    //   ];
      const wscols = [
        { wch: 12 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 35 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      const ceter_style = {
        alignment: { horizontal: "center", vertical: "center" },
        fill: {
          fgColor: { rgb: "e5e5e5" }, 
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      //ws["!merges"] = merge;

      ws["A1"].s = ceter_style;
      ws["B1"].s = ceter_style;
      ws["C1"].s = ceter_style;
      ws["D1"].s = ceter_style;
      ws["E1"].s = ceter_style;
      ws["F1"].s = ceter_style;
      ws["G1"].s = ceter_style;
      ws["H1"].s = ceter_style;
      ws["I1"].s = ceter_style;
      ws["J1"].s = ceter_style;
      ws["K1"].s = ceter_style;
      ws["L1"].s = ceter_style;
      ws["M1"].s = ceter_style;
      ws["N1"].s = ceter_style;
      ws["O1"].s = ceter_style;
      ws["P1"].s = ceter_style;
      ws["Q1"].s = ceter_style;
      ws["R1"].s = ceter_style;
      ws["S1"].s = ceter_style;
      ws["T1"].s = ceter_style;
      ws["U1"].s = ceter_style;

     
      

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานแจ้งคลังเบิกสินค้าไปสาขา");
      XLSX.writeFile(wb, "รายงานแจ้งคลังเบิกสินค้าไปสาขา.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานแจ้งคลังเบิกสินค้าไปสาขา</PanelHeader>
        <PanelBody>
          <Row
            gutter={3}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} className="text-right pr-3 ">
              <Text strong>ค้นหา</Text>
            </Col>
            <Col span={4}>
              <Input
                placeholder="เลขที่ใบโอนย้าย, รหัสสินค้า"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  });
                }}
              />
            </Col>
            <Col span={2} className="text-right pr-3 ">
              <Text strong>ช่วงวันที่โอนย้าย</Text>
            </Col>
            <Col span={4}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>

            <Col span={12}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={3}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} className="text-right pr-3 ">
              <Text strong>โอนจากคลัง</Text>
            </Col>

            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                //defaultValue={`${user.branch_code}-00`}
                defaultValue={""}
                options={whOptions}
                onChange={(e, v) => {
                  setFromWhFilter(e);
                  setFromWhFilterText(v.props.children);
                }}
              />
            </Col>

            <Col span={2} className="text-right pr-3 ">
              <Text strong>คลังที่รับโอน</Text>
            </Col>

            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={""}
                options={whOptions}
                onChange={(e, v) => {
                  setToWhFilter(e);
                  setToWhFilterText(v.props.children);
                }}
              />
            </Col>
          </Row>
          <Table
            className="pb-5"
            bordered
            size="small"
            loading={loading}
            dataSource={data ? data.data : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.data?.length,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="#" dataIndex="row_id" align="center" />
            <Table.Column
              title="Order Date"
              dataIndex="order_date"
              align="left"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="Order ID"
              dataIndex="order_id"
              align="left"
            />
            <Table.Column
              title="customerId"
              dataIndex="customer_id"
              align="left"
            />
            <Table.Column
              title="Name"
              dataIndex="name"
              align="left"
            />
            <Table.Column
              title="Phone"
              dataIndex="phone"
              align="left"
            />
            <Table.Column
              title="customerAddress"
              dataIndex="customer_address"
              align="left"
            />
            <Table.Column
              title="subDistrict"
              dataIndex="sub_district"
              align="left"
            />
            <Table.Column title="district" dataIndex="district" align="left" />
            <Table.Column title="province" dataIndex="province" align="left" />
            <Table.Column
              title="zipcode"
              dataIndex="zip_code"
              align="left"
            />
            <Table.Column
              title="BRANCH_CODE"
              dataIndex="branch_code"
              align="left"
            />
            <Table.Column
              title="BRANCH_NAME"
              dataIndex="branch_name"
              align="left"
            />
            <Table.Column
              title="paymentChannel"
              dataIndex="payment_channel"
              align="left"
            />
            <Table.Column
              title="totalAmount"
              dataIndex="total_amount"
              align="right"
              render={(text) => numeral(text).format("0,0")}
            />
            <Table.Column
              title="receiptNo"
              dataIndex="receipt_no"
              align="left"
            />
            <Table.Column
              title="deliveryDate"
              dataIndex="delivery_date"
              align="left"
            />
            <Table.Column
              title="deliveryNote"
              dataIndex="delivery_note"
              align="left"
            />
            <Table.Column
              title="Acount ID"
              dataIndex="account_id"
              align="left"
            />
            <Table.Column
              title="Product_ID"
              dataIndex="product_code"
              align="left"
            />
            <Table.Column
              title="Quantity"
              dataIndex="qty"
              align="right"
              render={(text) => numeral(text).format("0,0")}
            />
            <Table.Column
              title="UnitPrice"
              dataIndex="unit_price"
              align="right"
              render={(text) => numeral(text).format("0,0")}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
