/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, notification, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { axios, getToken, URL_API, _ } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import { mapFileList } from "../../../util/helper/file";

const { Text } = Typography;

const { clearCreate } = actionTransfer;

const SubmitReceive = ({ backUrl, setLoadingSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector((state) => state.transfer.product);
  const productAdd = useSelector((state) => state.transfer.productAdd);
  const mediaReceive = useSelector((state) => state.transfer.mediaReceive);
  const document = useSelector((state) => state.transfer.document);
  const disable_some =
    !product.is_some ||
    (product.selected.length === 0 && productAdd.itemKeys.length === 0);
  const disabled_confirm =
    product.is_some ||
    _.some(
      product.itemKeys,
      (key) =>
        !product.itemByKey[key].new_receive_qty &&
        product.itemByKey[key].total_diff_new_receive !== 0
    );

  const handleClickReceive = (status) => {
    let data_text, text_style;
    switch (status) {
      case "wait-some":
        text_style = "text-tp";
        data_text = "รับบางส่วน";
        break;
      case "complete":
        text_style = "text-pv";
        data_text = "รับสินค้า";
        break;
      default:
        return;
    }
    const { itemKeys, itemByKey, selected } = product;
    // pull product key that already received
    const activeItemKeys = _.filter(itemKeys, (key) => {
      const { new_receive_qty, total_diff_new_receive } = itemByKey[key];
      return !!new_receive_qty || !!total_diff_new_receive;
    });
    const items = _.map(
      status === "complete" ? activeItemKeys : selected,
      (key) => itemByKey[key]
    );
    const items_add = _.map(
      productAdd.itemKeys,
      (key) => productAdd.itemByKey[key]
    );
    if (_.some([...items, ...items_add], (n) => !n.new_receive_qty))
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณากรอกจำนวนรับจริงที่เลือกให้ครบถ้วน",
      });

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        setLoadingSubmit(true);
        const { file } = mapFileList(mediaReceive);
        const data = {
          ...document,
          document_status: document.status,
          status,
          items,
          items_add,
          file,
          main_item: _.reduce(
            activeItemKeys,
            (res, key) =>
              // itemByKey[key].line_number === 1 &&
              !_.some(
                res,
                (n) => n.product_code === itemByKey[key].product_code
              ) &&
              _.some(
                items,
                (n) => itemByKey[key].product_code === n.product_code
              )
                ? [
                    ...res,
                    {
                      ...itemByKey[key],
                      qty: itemByKey[key].total_qty,
                      new_receive_qty: itemByKey[key].total_new_receive_qty,
                    },
                  ]
                : res,
            []
          ),
          main_item_add: _.reduce(
            productAdd.itemKeys,
            (res, key) =>
              productAdd.itemByKey[key].line_number === 1
                ? [...res, productAdd.itemByKey[key]]
                : res,
            []
          ),
        };

        axios({
          method: "post",
          url: `${URL_API}/transfer-receive/receive${
            document.receive_type === "receive-in" ? "/in" : ""
          }`,
          headers: { Authorization: "Bearer " + getToken() },
          data,
        })
          .then(() => {
            dispatch(clearCreate());
            notification.success({
              message: "Success",
              description: `บันทึกข้อมูล${data_text}โอนย้ายสินค้าเรียบร้อย`,
            });
            history.push({ pathname: backUrl });
          })
          .catch((e) => resCatchModal(e))
          .finally(() => setLoadingSubmit(false));
      },
    });
  };

  return (
    <>
      <Button
        className={`${
          disable_some ? "" : "bg-tp border-tp text-white"
        } width-150 mx-3 `}
        disabled={disable_some}
        onClick={() => handleClickReceive("wait-some")}
      >
        ยืนยันรับบางส่วน
      </Button>
      <Button
        className={`${
          disabled_confirm ? "" : "bg-pv border-pv text-white"
        } width-150`}
        disabled={disabled_confirm}
        onClick={() => handleClickReceive("complete")}
      >
        ยืนยันรับสินค้า
      </Button>
    </>
  );
};

export default memo(SubmitReceive);
