/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { axios, getToken, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import PreOrderContext from "../../reducer";

const CancelModal = ({ onComplete, form }) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const {
    state: {
      billModal: { cancel_modal_visible, id, reserve_order_no, status },
    },
    dispatch,
  } = useContext(PreOrderContext);

  const handleSubmit = () =>
    validateFieldsAndScroll((err, res) => {
      if (err) return;
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "คุณต้องการยกเลิกโดยนักธุรกิจหรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          dispatch({ type: "SET_LOADING", payload: true });
          axios({
            method: "post",
            url: `${URL_API}/pre-order/flow/cancel-by-member`,
            headers: { Authorization: "Bearer " + getToken() },
            data: { ...res, id, reserve_order_no, document_status: status },
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: `บันทึกข้อมูลเรียบร้อย`,
              });
              onComplete();
            })
            .catch((e) => {
              resCatchModal(e);
            })
            .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
        },
      });
    });

  return (
    <Modal
      visible={cancel_modal_visible}
      onCancel={() =>
        dispatch({
          type: "SET_GLOBAL_STATE",
          payload: {
            name: "billModal",
            value: { cancel_modal_visible: false },
          },
        })
      }
      maskClosable={false}
      footer={false}
      closeIcon={<FaTimes size={10} />}
      bodyStyle={{ paddingTop: "50px" }}
      destroyOnClose={true}
      zIndex={1080}
      width={600}
    >
      <Typography.Title level={3} className="text-center mb-4">
        ยกเลิกโดยนักธุรกิจ
      </Typography.Title>
      <Form
        name="cancel-by-member"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
      >
        <Row gutter={[8, 8]} type="flex">
          <Col span={24}>
            <Form.Item className="d-flex mb-0" label="หมายเหตุ">
              {getFieldDecorator("remark", {
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกหมายเหตุ",
                  },
                ],
              })(
                <Input.TextArea
                  autoSize={{ minRows: 5, maxRows: 9 }}
                  maxLength={500}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={8} className="my-3">
        <Col span={24} className="text-center">
          <Button className="bg-pv border-pv text-white" onClick={handleSubmit}>
            ตกลง
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Form.create()(CancelModal);
