/* eslint-disable no-unused-vars */
import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";
import { _ } from "../../../../../utils";

const initData = {
  partner_code: null,
  ref_id: null,
  ref_type: null,
};

export const initState = {
  tabActive: "info",
  listTab: [
    {
      title: "รายละเอียด",
      value: "info",
    },
    {
      title: "ข้อมูลรหัสเก่า",
      value: "member",
    },
    {
      title: "ประวัติการสั่งซื้อ",
      value: "sale",
    },
    {
      title: "ประวัติการแลก",
      value: "redeem",
    },
  ],
  data: {
    ...initData,
  },
  previewData: {
    ...initData,
  },
};

export const reducer = createReducer(initState, {
  setState: (state, { payload: { name, value } }) => {
    state[name] = value;
  },
  clear: (state) => {
    state.tabActive = initState.tabActive;
    state.data = { ...initData };
    state.previewData = { ...initData };
  },
  setGlobalState: (state, { payload: { name, value } }) => {
    state[name] = {
      ...state[name],
      ...value,
    };
  },
  clearGlobalState: (state, { payload }) => {
    state[payload] = _.cloneDeep(initState[payload]);
  },
});

const MemberProfileContext = createContext();
export default MemberProfileContext;
