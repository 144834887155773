/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Modal, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../utils";

import { Panel, PanelBody, PanelHeader } from "../../../util/panel";

import TextEditor from "../../../../components/TextEditor";

function FAQ({ history, form, match, ...props }) {
  const [contentTextEditorQ, setContentTextEditorQ] = useState();
  const [contentTextEditorA, setContentTextEditorA] = useState();
  const [loadValue, setLoadValue] = useState("");

  console.log(loadValue);

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
          content: JSON.stringify({
            question: contentTextEditorQ,
            answer: contentTextEditorA,
          }),
        })
      );

      console.log(formData);

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/faq/" + match.params.id + "/update";
            } else {
              actionPathUrl = "/manage-website/faq";
            }
            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const loadContent = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/faq/" + match.params.id,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        let data = res.data?.data;
        setLoadValue(data);
        let loadContent = JSON.parse(data.content);
        if (loadContent) {
          setContentTextEditorQ(loadContent.question);
          setContentTextEditorA(loadContent.answer);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (match.params.id !== "add") {
      loadContent(match.params.id);
    }
  }, [match.params.id]);

  return (
    <>
      <Panel>
        <PanelHeader>FAQ</PanelHeader>
        <PanelBody>
          <Row className="mb-3">
            <p>Question</p>
            <TextEditor
              authToken={getToken()}
              value={contentTextEditorQ}
              onInit={(evt, event) => {}}
              onEditorChange={(e) => {
                setContentTextEditorQ(e);
              }}
              onUploadSuccess={(json) => {
                console.log(json);
              }}
              postBody={{ container_name: "web-content" }}
              urlUpload={`${URL_API}/file/upload`}
            />
          </Row>
          <Row className="mb-3">
            <p>Answer</p>
            <TextEditor
              authToken={getToken()}
              value={contentTextEditorA}
              onInit={(evt, event) => {}}
              onEditorChange={(e) => {
                setContentTextEditorA(e);
              }}
              onUploadSuccess={(json) => {
                console.log(json);
              }}
              postBody={{ container_name: "web-content" }}
              urlUpload={`${URL_API}/file/upload`}
            />
          </Row>
          <Row className="mt-3 mb-3">
            <Button
              className="bg-nn border-nn text-white mr-3"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleClickSubmit();
              }}
            >
              {loadValue != (null || "") ? "Update" : "Save"}
            </Button>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("faq")(FAQ);
