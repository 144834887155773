/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Button, Col, Form, Modal, notification, Row, Upload } from "antd";
import Resizer from "react-image-file-resizer";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import TextEditor from "../../../../../../components/TextEditor";
import { axios, getToken, moment, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { dummyRequest } from "../../../components/dummyRequest";
import { DivStyles, ImgStyles } from "../../../components/mwStyles";
import useUploadFile from "../../../components/useUploadFile";
import reactImageSize from "react-image-size";

function RP_ReviewReview({ history, match, form, ...props }) {
  const [contentTextEditor, setContentTextEditor] = useState();
  const [loadValue, setLoadValue] = useState("");
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);

  const desktopWidth = 300;
  const desktopHeight = 300;

  const mobileWidth = 248;
  const mobileHeight = 248;

  let { onUpload, onRemoveUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      if (fileListMobile[0]?.file) {
        formData.append("mobile_img", fileListMobile[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
          description: contentTextEditor,
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/ecosystem/reward-point/rp-review/review/" +
                match.params.id +
                "/update";
            } else {
              actionPathUrl =
                "/manage-website/ecosystem/reward-point/rp-review/review";
            }

            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleChangeUploadDesktop = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      await setFileListDesktop(isFile);
      const { width, height } = await reactImageSize(isFile[0].url);
      console.log("desktop size :", width, height);
      //------------------------------------resize file mobile img :
      let fileMobile = e.file.originFileObj;
      new Promise(async (resolve) => {
        await Resizer.imageFileResizer(
          fileMobile,
          mobileWidth,
          mobileHeight,
          "JPEG",
          100,
          0,
          async (uri) => {
            resolve(uri);
            const { width, height } = await reactImageSize(uri);
            console.log("mobile size :", width, height);
            let dataFile = [
              {
                file: { ...e.file },
                uid: e.file.uid,
                status: "done",
                name: e.file.name,
                url: uri,
              },
            ];
            if (dataFile.length > 0) {
              setFileListMobile(dataFile);
            }
          },
          "base64"
        );
      });
    }
  };

  const loadContent = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url:
        "/manage-website/ecosystem/reward-point/rp-review/review/" +
        match.params.id,
    })
      .then((res) => {
        let data = res.data?.data;

        setLoadValue(data);

        setContentTextEditor(data.description);

        let { loadDesktopImg, loadMobileImg, title } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListMobile(loadMobileImg);
          }
        }

        form.setFieldsValue({
          title,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (match.params.id !== "/add") {
      loadContent(match.params.id);
    }
  }, [match.params.id]);

  const { getFieldDecorator } = form;

  return (
    <Panel>
      <PanelHeader></PanelHeader>
      <PanelBody>
        <Form layout="vertical">
          <Row>
            <Row gutter={24} className="mb-2">
              <Col md={12} className="mb-2">
                {`เพิ่มรูปภาพ Review ขนาดสำหรับ Desktop ${desktopWidth}x${desktopHeight}px (อัตราส่วน 1:1)`}
                <Row className="mt-2">
                  {fileListDesktop.length > 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop[0].url}
                          alt="desktop_img"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
              <Col md={12} className="mb-2">
                {fileListMobile.length > 0 ? (
                  <>
                    {`เพิ่มรูปภาพ Review ขนาดสำหรับ Mobile ${mobileWidth}x${mobileHeight}px (อัตราส่วน 1:1)`}
                    <Row className="mt-2">
                      <div style={DivStyles}>
                        <img
                          src={fileListMobile[0].url}
                          alt="mobile_img"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={24} className="mb-2">
              <Col md={12} className="mb-2">
                <Form.Item>
                  {getFieldDecorator("desktop_img", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="image/*"
                      multiple={false}
                      fileList={fileListDesktop}
                      customRequest={dummyRequest}
                      onChange={handleChangeUploadDesktop}
                      showUploadList={false}
                    >
                      <AiOutlineUpload className="mr-3" />
                      {`เพิ่มรูปภาพขนาด ${desktopWidth}x${desktopHeight}px (อัตราส่วน 1:1)`}
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Row>

          <Row>
            <Form.Item label="ชื่อผู้แลก">
              {getFieldDecorator("title", {
                initialValue: "",
                rules: [{ required: true, message: "กรุณาระบุ ชื่อผู้แลก" }],
              })(<TextArea />)}
            </Form.Item>
          </Row>

          <Row className="mb-3">
            <p>Description</p>
            <TextEditor
              authToken={getToken()}
              value={contentTextEditor}
              onInit={(evt, event) => {}}
              onEditorChange={(e) => {
                setContentTextEditor(e);
              }}
              onUploadSuccess={(json) => {
                console.log(json);
              }}
              postBody={{ container_name: "web-content" }}
              urlUpload={`${URL_API}/file/upload`}
            />
          </Row>

          <Row gutter={16}>
            <Col md={24}>
              <Button
                className="bg-nn border-nn text-white mr-3"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={() => handleClickSubmit()}>
                {match.params.id !== "add" ? "Update" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}

export default Form.create("rp-review-review")(RP_ReviewReview);
