import { Button, Col, Divider, Modal, notification, Row } from "antd";
import React, { useContext } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import ArrangeContext from "../../reducer";

const SummaryButton = ({ reload }) => {
  const {
    state: {
      bill,
      selected,
      items: { itemKeys, itemByKey },
    },
    dispatch,
  } = useContext(ArrangeContext);
  
  const sendSubmit = () => {
    const items = _.map(itemKeys, (key) => itemByKey[key]);
    const is_every = _.every(items, (n) => n.qty_delivery === n.qty_balance);
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          const sum_qty = _.reduce(
            items,
            (res, n) => ({
              qty: res.qty + n.qty_balance,
              qty_delivery: res.qty_delivery + n.qty_delivery,
              qty_remain: res.qty_remain + (n.qty_balance - n.qty_delivery),
            }),
            {
              qty: 0,
              qty_delivery: 0,
              qty_remain: 0,
            }
          );
          const data = {
            ...bill,
            ...sum_qty,
            is_every: is_every,
            items: _.map(items, (n) => ({
              ...n,
              bom: _.map(n.bom, (m) => ({
                product_code_ref: m.product_code_ref,
                product_code: m.ref_product_code,
                product_name: m.ref_product_name,
                qty: m.qty * n.qty_delivery,
                line_number: m.line_number,
                qr: m.qr || [],
                barcode: m.barcode,
              })),
              product_code: n.product_code,
              product_name: n.product_name,
              product_type_set: n.product_type_set,
              set_type: n.set_type,
              sku: n.sku,
              qty: n.qty_balance,
              qty_delivery: n.qty_delivery,
              qty_remain: n.qty_balance - n.qty_delivery,
              qty_arrange: n.qty_arrange + n.qty_delivery,
              unit_price: n.unit_price,
              barcode: n.barcode,
              qr: n.qr,
            })),
          };
          axios({
            method: "post",
            url: `${URL_API}/arrange/${selected.type}`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
          })
            .then(() => {
              resolve();
              reload();
              dispatch({ type: "clearCreate" });
              notification.success({ message: "จัดสินค้าสำเร็จ" });
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };
  return (
    <>
      <Divider />
      <Row>
        <Col className="text-center">
          <Button type="primary" onClick={sendSubmit}>
            บันทึก
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SummaryButton;
