/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";
import TextEditor from "../../../../../../components/TextEditor";
import reactImageSize from "react-image-size";
import useUploadFile from "../../../components/useUploadFile";
import { dummyRequest } from "../../../components/dummyRequest";
import { DivStyles, ImgStyles } from "../../../components/mwStyles";

function HF_Icon({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");
  const [fileListDesktop1, setFileListDesktop1] = useState([]);
  const [fileListDesktop2, setFileListDesktop2] = useState([]);
  const [fileListDesktop3, setFileListDesktop3] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);

  const [contentTextEditor1, setContentTextEditor1] = useState();
  const [contentTextEditor2, setContentTextEditor2] = useState();
  const [contentTextEditor3, setContentTextEditor3] = useState();

  const desktopWidth = 150;
  const desktopHeight = 150;

  let { onUpload, onRemoveUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (fileListDesktop1[0]?.file) {
        formData.append(
          "desktop_img1",
          fileListDesktop1[0]?.file?.originFileObj
        );
      }
      if (fileListDesktop2[0]?.file) {
        formData.append(
          "desktop_img2",
          fileListDesktop2[0]?.file?.originFileObj
        );
      }
      if (fileListDesktop3[0]?.file) {
        formData.append(
          "desktop_img3",
          fileListDesktop3[0]?.file?.originFileObj
        );
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img1", "desktop_img2", "desktop_img3"]),
          content: JSON.stringify({
            content1: contentTextEditor1,
            content2: contentTextEditor2,
            content3: contentTextEditor3,
          }),
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path =
                  "/manage-website/ecosystem/healthy-food/hf-icon/update")
              : (url_path = "/manage-website/ecosystem/healthy-food/hf-icon");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop1 = (e) => {
    setFileListDesktop1([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop2 = (e) => {
    setFileListDesktop2([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop3 = (e) => {
    setFileListDesktop3([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleUploadDesktop1 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop1(isFile);
    }
    
  };
  const handleUploadDesktop2 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop2(isFile);
    }
  };
  const handleUploadDesktop3 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop3(isFile);
    }
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/healthy-food/hf-icon",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        let loadText = JSON.parse(data.content);
        if (loadText) {
          setContentTextEditor1(loadText.content1);
          setContentTextEditor2(loadText.content2);
          setContentTextEditor3(loadText.content3);
        }

        let { loadDesktopImg } = data;

        for (let i in data.files) {
          console.log(data.files);
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            if (data.files[i].link_target == "desktop_img1") {
              setFileListDesktop1(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img2") {
              setFileListDesktop2(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img3") {
              setFileListDesktop3(loadDesktopImg);
            }
          }
        }

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>HF ICON</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Row className="mb-4">
              <Card type="inner" title="ICON 1">
                <Row md={24} gutter={24}>
                  <Col md={8}>
                    <Row>
                      {fileListDesktop1.length != 0 ? (
                        <Row>
                          <div style={DivStyles}>
                            <img
                              src={fileListDesktop1[0].url}
                              alt="desktop_img1"
                              style={ImgStyles}
                            />
                          </div>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Form.Item
                      label={`ขนาดรูปภาพที่แนะนำ ${desktopWidth} x ${desktopHeight} px`}
                    >
                      {getFieldDecorator("desktop_img1", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop1}
                          customRequest={dummyRequest}
                          beforeUpload={beforeUploadImg}
                          onRemove={handleRemoveDesktop1}
                          onChange={handleUploadDesktop1}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={16}>
                    <Row className="mb-3">
                      <p>Content 1 :</p>
                      <TextEditor
                        authToken={getToken()}
                        value={contentTextEditor1}
                        onInit={(evt, event) => {}}
                        onEditorChange={(e) => {
                          setContentTextEditor1(e);
                        }}
                        onUploadSuccess={(json) => {
                          console.log(json);
                        }}
                        postBody={{ container_name: "web-content" }}
                        urlUpload={`${URL_API}/file/upload`}
                      />
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Row>

            <Row className="mb-4">
              <Card type="inner" title="ICON 2">
                <Row md={24} gutter={24}>
                  <Col md={8}>
                    <Row>
                      {fileListDesktop2.length != 0 ? (
                        <Row>
                          <div style={DivStyles}>
                            <img
                              src={fileListDesktop2[0].url}
                              alt="desktop_img2"
                              style={ImgStyles}
                            />
                          </div>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Form.Item
                      label={`ขนาดรูปภาพที่แนะนำ ${desktopWidth} x ${desktopHeight} px`}
                    >
                      {getFieldDecorator("desktop_img2", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop2}
                          customRequest={dummyRequest}
                          beforeUpload={beforeUploadImg}
                          onRemove={handleRemoveDesktop2}
                          onChange={handleUploadDesktop2}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={16}>
                    <Row className="mb-3">
                      <p>Content 2 :</p>
                      <TextEditor
                        authToken={getToken()}
                        value={contentTextEditor2}
                        onInit={(evt, event) => {}}
                        onEditorChange={(e) => {
                          setContentTextEditor2(e);
                        }}
                        onUploadSuccess={(json) => {
                          console.log(json);
                        }}
                        postBody={{ container_name: "web-content" }}
                        urlUpload={`${URL_API}/file/upload`}
                      />
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Row>

            <Row className="mb-4">
              <Card type="inner" title="ICON 3">
                <Row md={24} gutter={24}>
                  <Col md={8}>
                    <Row>
                      {fileListDesktop3.length != 0 ? (
                        <Row>
                          <div style={DivStyles}>
                            <img
                              src={fileListDesktop3[0].url}
                              alt="desktop_img3"
                              style={ImgStyles}
                            />
                          </div>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Form.Item
                      label={`ขนาดรูปภาพที่แนะนำ ${desktopWidth} x ${desktopHeight} px`}
                    >
                      {getFieldDecorator("desktop_img3", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop3}
                          customRequest={dummyRequest}
                          beforeUpload={beforeUploadImg}
                          onRemove={handleRemoveDesktop3}
                          onChange={handleUploadDesktop3}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={16}>
                    <Row className="mb-3">
                      <p>Content 3 :</p>
                      <TextEditor
                        authToken={getToken()}
                        value={contentTextEditor3}
                        onInit={(evt, event) => {}}
                        onEditorChange={(e) => {
                          setContentTextEditor3(e);
                        }}
                        onUploadSuccess={(json) => {
                          console.log(json);
                        }}
                        postBody={{ container_name: "web-content" }}
                        urlUpload={`${URL_API}/file/upload`}
                      />
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-hf-icon")(HF_Icon);
