import { Modal, notification, Button, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { actionInventoryCount } from "../../../../redux/slices/inventory-count";
import { getInventoryCountByDocumentNo } from "../../../../redux/thunk/inventory-count";
import { axios, getToken, URL_API, _, moment } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import { mapFileList } from "../../../util/helper/file";

const { Text } = Typography;

const mapProduct = (data, only_change, isOtherWarehouse) => {
  const product_field = [
    "shelf",
    "storage_shelf",
    "warehouse",
    "count",
    "total_count",
    "lot",
    "mfg_date",
    "exp_date",
    "remark",
  ];
  const res = _.reduce(
    data.itemKeys,
    (res, key) => {
      const {
        product_code,
        shelf,
        storage_shelf,
        warehouse,
        total_count,
        lot,
        mfg_date,
        exp_date,
        remark,
        is_default,
        is_first,
        is_primary_set,
      } = data.itemByKey[key];
      if (
        only_change &&
        _.every(product_field, (field) => !data.itemByKey[key][field])
      )
        return res;
      return [
        ...res,
        {
          product_code,
          shelf,
          storage_shelf,
          warehouse,
          count: isOtherWarehouse ? total_count : 0,
          lot,
          mfg_date: mfg_date ? moment(mfg_date).toDate() : null,
          exp_date: exp_date ? moment(exp_date).toDate() : null,
          remark,
          is_default,
          is_first,
          is_primary_set,
        },
      ];
    },
    []
  );
  return res;
};

const { setGlobalState, clearStock } = actionInventoryCount;

const ButtonSave = ({ type }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { type: type_params, document_no: document_no_params } = useParams();
  const {
    warehouse,
    document: { document_no, document_date, document_status },
    stock,
    media,
    remark,
    searchDocument,
    ...inventoryCount
  } = useSelector((state) => state.inventoryCount);
  const document_remark = remark[warehouse.activeKey]?.remark || '' ;
  const warehouse_text = _.includes(
    ["primary_set", "primary"],
    warehouse.activeKey
  )
    ? "คลังดี"
    : warehouse.itemByKey[warehouse.activeKey].name;
  const disabledDraft =
    warehouse.itemByKey[warehouse.activeKey].status === "pending";

  const handleClickSave = () => {
    let data_text, text_style, status;
    switch (type) {
      case "draft":
        data_text = "บันทึกฉบับร่าง";
        text_style = "text-rp";
        status = "draft";
        break;
      case "edit":
        data_text = "แก้ไข";
        text_style = "text-nn";
        status = "draft";
        break;
      case "confirm":
        data_text = "ยืนยัน";
        text_style = "text-pv";
        status = "pending";
        break;
      default:
        return;
    }
    const process_text = `${data_text}${warehouse_text}`;
    const only_change = status !== "pending";
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {process_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          const back_url = `/${_.split(match.path, "/")[1]}/${type_params}`;
          const warehouse_type = warehouse.activeKey;
          let data = {
            ...mapFileList(media),
            document_no,
            remark:document_remark,
            document_status,
            warehouse_type,
            status,
          };
          if (_.includes(["primary_set", "primary"], warehouse_type)) {
            const { primary_set, primary } = inventoryCount;
            data = {
              ...data,
              primary_set: mapProduct(primary_set, only_change),
              primary: mapProduct(primary, only_change),
            };
          } else {
            data = {
              ...data,
              [warehouse_type]: mapProduct(
                inventoryCount[warehouse_type],
                only_change,
                true
              ),
            };
          }

          let sub_path = `/${document_no_params}`;
          if (document_no_params === "0") {
            sub_path = "";
            data = {
              ..._.omit(data, ["file_del"]),
              document: {
                document_date: document_date,
                remark: document_remark,
                branch_code: searchDocument.branch_code,
                branch_name: searchDocument.branch_name,
                count_type: type_params,
              },
              stock: _.map(stock.itemKeys, (key) => stock.itemByKey[key]),
            };
          }
          // console.log(data)
          // return
          axios({
            method: "post",
            url: `${URL_API}/inventory-count${sub_path}`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
            cancelToken: source.token,
          })
            .then((res) => {
              resolve();
              dispatch(clearStock());
              notification.success({
                message: "Success",
                description: `บันทึกข้อมูล${process_text}เรียบร้อย`,
              });
              if (data_text === "บันทึกฉบับร่าง") {
                history.push({ pathname: back_url });
              } else if (document_no_params === "0") {
                history.push({ pathname: `${back_url}/${res.data.data}` });
              } else {
                dispatch(
                  getInventoryCountByDocumentNo({
                    document_no: document_no_params,
                  })
                );
                dispatch(
                  setGlobalState({
                    name: "warehouse",
                    value: { activeKey: warehouse.activeKey },
                  })
                );
              }
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  if (type === "draft")
    return (
      <Button
        disabled={disabledDraft}
        className={`${
          disabledDraft ? "" : "bg-rp border-rp text-white "
        }width-100 mr-3`}
        onClick={handleClickSave}
      >
        บันทึกร่าง
      </Button>
    );

  if (type === "edit")
    return (
      <Button
        className="bg-nn border-nn text-white width-150"
        onClick={handleClickSave}
      >
        แก้ไข{warehouse_text}
      </Button>
    );
  if (type === "confirm")
    return (
      <Button type="primary" className="width-150" onClick={handleClickSave}>
        ยืนยัน{warehouse_text}
      </Button>
    );
  return null;
};

export default memo(ButtonSave);
