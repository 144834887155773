import React from "react";

const CardInfo = ({ data = [], className = "", icon = null }) => (
  <div className={`pos-card-icon ${className}`}>
    <span className="icon-top">{icon}</span>
    <table className="pos-table-icon">
      <tbody>
        {data.map((n, i) => (
          <tr key={i}>
            <td className="align-top">
              {n.title} {n.title ? " : " : ""}
            </td>
            <td className="align-top">{n.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default CardInfo;
