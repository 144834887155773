/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Modal, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHttp } from "../../../../../../../hooks/http";
import { URL_API, _ } from "../../../../../../../utils";
import { PromotionContext } from "../../reducers";

const HonorModal = ({ visible, type, title, onCancel }) => {
  const { id: id_params } = useParams();
  const {
    state: { honorSource },
    dispatch,
  } = useContext(PromotionContext);
  const [dataSource, setDataSource] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/system/master/honor`,
      token: true,
    },
    []
  );

  useEffect(() => {
    if (id_params === "0" && data?.data) {
      // default honor source all
      dispatch({
        type: "DEFAULT_HONOR_SOURCE_ALL",
        payload: data.data,
      });
    }
  }, [data?.data, id_params]);

  useEffect(() => {
    if (data?.data && honorSource[type]) {
      const honor_select = _.map(
        honorSource[type].itemKeys,
        (n) => honorSource[type].itemByKey[n]
      );
      const data_filter = _.reduce(
        data.data,
        (r, honor) => {
          const find = _.find(
            honor_select,
            (n) => n.honor_code === honor.honor_code
          );
          return [
            ...r,
            find ? { ...honor, ...find, checked: true } : { ...honor },
          ];
        },
        []
      );
      setDataSource(data_filter);
    }
  }, [data?.data, honorSource[type], type]);

  if (error) return <Alert type="error" message={error} />;

  return (
    <Modal
      visible={visible}
      title={`เลือกตำแหน่งนักธุรกิจ (${title})`}
      onCancel={onCancel}
      footer={false}
      zIndex={1080}
      destroyOnClose={true}
    >
      <Table
        bordered
        rowKey="honor_code"
        loading={loading}
        size="small"
        dataSource={dataSource}
        pagination={false}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: _.map(
            _.filter(dataSource, (n) => n.checked),
            (n) => n.honor_code
          ),
          onSelect: (value) => {
            dispatch({
              type: "ADD_HONOR_SOURCE",
              payload: {
                type,
                honor_code: value.honor_code,
                honor_name: value.honor_name,
              },
            });
          },
          onSelectAll: (selected, selected_row) => {
            dispatch({
              type: "ADD_HONOR_SOURCE_ALL",
              payload: { type, selected, data: selected_row },
            });
          },
        }}
      >
        <Table.Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">
              ตำแหน่งนักธุรกิจ
            </div>
          }
          key="honor_name"
          dataIndex="honor_name"
        />
      </Table>
    </Modal>
  );
};

export default HonorModal;
