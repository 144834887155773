import { Col, Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { moment, _ } from "../../../../../utils";

const { Text } = Typography;

const Detail = () => {
  const {
    document_no,
    document_date,
    reason,
    bill_ref_document_no,
    other_reason,
    detail,
    reason_text,
    from_wh_name,
    to_wh_name,
    transfer_by,
    modify_by,
    cancel_type_text,
    status,
  } = useSelector((state) => state.transfer.document);

  return (
    <Row gutter={[8, 8]} type="flex">
      <Col span={24} order={2} xl={{ span: 18, order: 1 }}>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={6} md={4} xl={3} xxl={2} className="text-right">
            <Text strong>โอนจากสาขา :</Text>
          </Col>
          <Col span={18} md={10} xl={8} xxl={6} className="text-overflow">
            <Text>{from_wh_name}</Text>
          </Col>
          {transfer_by && (
            <>
              <Col span={6} md={4} xl={3} xxl={2} className="text-right">
                <Text>ผู้ทำการโอน :</Text>
              </Col>
              <Col span={18} md={6} xl={10} xxl={14} className="text-overflow">
                <Text>{transfer_by}</Text>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={6} md={4} xl={3} xxl={2} className="text-right">
            <Text strong>โอนไปสาขา :</Text>
          </Col>
          <Col span={18} md={10} xl={8} xxl={6} className="text-overflow">
            <Text>{to_wh_name}</Text>
          </Col>
          {_.includes(["complete", "cancel"], status) && modify_by && (
            <>
              <Col span={6} md={4} xl={3} xxl={2} className="text-right">
                <Text>
                  {`ผู้${status === "complete" ? "รับยอดโอน" : "ยกเลิก"}`} :
                </Text>
              </Col>
              <Col span={18} md={6} xl={10} xxl={14} className="text-overflow">
                <Text>{modify_by}</Text>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col
            span={6}
            md={4}
            xl={3}
            xxl={2}
            className="text-right text-overflow"
          >
            <Text strong>เหตุผลการโอน :</Text>
          </Col>
          <Col span={18} md={20} xl={21} xxl={22}>
            <Text>{reason_text}</Text>
          </Col>
        </Row>
        {reason === "00" ? (
          <Row gutter={[8, 8]} type="flex" className="align-items-center">
            <Col span={6} md={4} xl={3} xxl={2} className="text-right">
              <Text>เลขที่บิล :</Text>
            </Col>
            <Col span={18} md={20} xl={21} xxl={22}>
              <Text>{bill_ref_document_no}</Text>
            </Col>
          </Row>
        ) : (
          reason === "03" && (
            <Row gutter={[8, 8]} type="flex" className="align-items-center">
              <Col span={6} md={4} xl={3} xxl={2} className="text-right">
                <Text>ระบุ :</Text>
              </Col>
              <Col span={18} md={20} xl={21} xxl={22}>
                <Text>{other_reason}</Text>
              </Col>
            </Row>
          )
        )}
        <Row gutter={[8, 8]} type="flex" className="align-items-center">
          <Col span={6} md={4} xl={3} xxl={2} className="text-right">
            <Text>รายละเอียด :</Text>
          </Col>
          <Col span={18} md={20} xl={21} xxl={22}>
            <Text>{detail}</Text>
          </Col>
        </Row>
        {status === "cancel" && (
          <Row gutter={[8, 8]} type="flex" className="align-items-center">
            <Col
              span={6}
              md={4}
              xl={3}
              xxl={2}
              className="text-right text-overflow"
            >
              <Text>เหตุผลการยกเลิก :</Text>
            </Col>
            <Col span={18} md={20} xl={21} xxl={22}>
              <Text>{cancel_type_text}</Text>
            </Col>
          </Row>
        )}
      </Col>
      <Col
        span={24}
        order={1}
        xl={{ span: 6, order: 3 }}
        className="d-flex flex-column align-items-end"
      >
        <Row gutter={[8, 8]} className="mb-1">
          <Col span={24} className="d-flex flex-column align-items-end">
            <div>
              <Text strong className="mr-2">
                เลขที่ใบโอนย้าย :
              </Text>
              <Text>{document_no}</Text>
            </div>
            <div>
              <Text strong className="mr-2">
                วันที่ออก :
              </Text>
              <Text>
                {moment(document_date || new Date()).format("DD/MM/YYYY")}
              </Text>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Detail;
