import { useMutation, useQuery } from '@tanstack/react-query';
import { eventRequest } from '../utils/useAxios';

const eventCalendarServices = {
  useQueryGetAllEventCalendars({ take, page }, onSuccess) {
    return useQuery(
      ['get-all-event-calendars', { take, page }],
      async ({ queryKey }) => {
        try {
          const res = await eventRequest.get(`/event-calendar`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetEventCalendarById({ eventCalendarId }, onSuccess) {
    return useQuery(
      ['get-event-calendar-by-id', { eventCalendarId }],
      async ({ queryKey }) => {
        try {
          const res = await eventRequest.get(
            `/event-calendar/${queryKey[1].eventCalendarId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!eventCalendarId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useMutationCreateEventCalendar(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await eventRequest.post(`/event-calendar`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateEventCalendar(onSuccess, onError) {
    return useMutation(
      async ({ eventCalendarId, data }) => {
        try {
          const res = await eventRequest.patch(
            `/event-calendar/${eventCalendarId}`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateEventCalendarStatus(onSuccess, onError) {
    return useMutation(
      async ({ eventCalendarId, status }) => {
        try {
          const res = await eventRequest.patch(
            `/event-calendar/${eventCalendarId}/status`,
            {
              status,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteEventCalendar(onSuccess, onError) {
    return useMutation(
      async ({ eventCalendarId }) => {
        try {
          const res = await eventRequest.delete(
            `/event-calendar/${eventCalendarId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default eventCalendarServices;
