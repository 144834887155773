import { Empty, Skeleton } from "antd";
import React, { useContext } from "react";
import { FaMailBulk } from "react-icons/fa";
import CardInfo from "../CardInfo";
import InfoContext from "./reducer";

const ContactCard = () => {
  const { state } = useContext(InfoContext);

  if (state.loading) return <Skeleton active />;

  if (state[`${state.memberType}Contact`]?.length === 0) return <Empty />;

  return (
    <CardInfo
      data={state[`${state.memberType}Contact`]}
      icon={<FaMailBulk />}
    />
  );
};

export default ContactCard;
