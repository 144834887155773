import React from 'react'
import { Modal, Divider } from 'antd'
import { FaTimes } from 'react-icons/fa'

const ModalControl = ({ children, title = '', footer = null, footerLine = true, bodyStyle = null, visible = true, onCancel, width = 520, destroyOnClose = false, ...props }) => {
    return (
        <Modal
            footer={false}
            visible={visible}
            // closeIcon={<Icon type="close" style={{ fontSize: '14px' }} />}
            closeIcon={<FaTimes />}
            onCancel={onCancel}
            width={width}
            maskClosable={false}
            destroyOnClose={destroyOnClose}
        >
            <h3 className="text-center">{title}</h3>
            <div style={{ ...bodyStyle, marginRight: '-14px', paddingRight: '14px' }} className="custom-scroll">
                {children}
            </div>
            {footerLine && <Divider className="my-3" />}
            {footer}
        </Modal>
    )
}

export default ModalControl;
