import React from 'react'
import { Col, Row, Switch, Typography } from 'antd'
const PromotionJoinPro = ({ join_pro, handleChange }) => {
    const joinProChange = async (value, name) => {
        handleChange({
            target: {
                name, value
            }
        })
    }
    return (
        <Row className="mb-3 mt-3" type="flex">
            <Col md={12}>
                <Typography.Text strong>ใช้งาน</Typography.Text>
            </Col>
            <Col md={12} style={{ textAlign: 'end' }}>
                <Switch defaultChecked={join_pro} onChange={e => joinProChange(e, 'join_pro')} />
            </Col>
        </Row>
    )
}
export default PromotionJoinPro;