import { Button, Col, Icon, Input, message, Row, Select, Switch, Table } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import numeral from 'numeral';
import { useApi } from "../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../redux/slices/marketing/product";
import { axios, getToken, URL_API } from "../../../../utils/index";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import SelectSubCategory from "../../pos/components/SelectSubCategory";

const COLUMN_KEYS = {
  mkt_member_price: 'mkt_member_price',
  mkt_member_text: 'ราคาสมาชิก',
  mkt_distributor_price: 'mkt_distributor_price',
  mkt_distributor_text: 'ราคานักธุรกิจ'
}

export default function MarketingProductList() {
  const dispatch = useDispatch();
  // const match = useRouteMatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;

  const [isLoading, setIsLoading] = useState(false)
  const [priceEditing, setPriceEding] = useState([]) // {id:number,key:string} - รายการที่กำลังแก้ไขอยู่
  const [tempPricing, setTempPriceing] = useState([]) // {id:number,key:string,beforeValue,newValue} - รายการที่อัปเดตค่าไปแล้ว
  const [activeFilter, setActiveFilter] = useState([search.category])
  const [activeFilterStatus, setActiveFilterStatus] = useState("all")

  const list = useApi(
    {
      baseURL: URL_API,
      url: "/marketing/product",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
        public_status: activeFilterStatus,
      },
      token: true,
    },
    [search, activeFilterStatus]
  );
  const handleCategory = async (arr) => {
    let lastItem;
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        lastItem = arr[i];
      }
    }

    dispatch(
      SET_SEARCH({
        category: lastItem || 0,
        page: 1,
      })
    );
  };
  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/marketing/product/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "active" : "inactive",
      },
    })
      .then(() => { })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdatePrice = ({ id, key, value = 0 }) => {
    setIsLoading(true)
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/marketing/product/${id}/update`,
      headers: { Authorization: "Bearer " + getToken() },
      data: { key, value },
    })
      .then((res) => {
        message.success("อัปเดตสำเร็จ!")
        setIsLoading(false)
      })
      .catch((err) => {
        message.error("อัปเดตไม่สำเร็จ!")
        setTempPriceing(_.reject(tempPricing, { id, key }))
        setIsLoading(false)
        console.log(err);
      });
  };


  const renderPricingColumn = ({ id, key, value, name, price_default }) => {
    // :: ON_EDIT - รายการที่เปิดการแก้ไขอยู่แต่ยังไม่กดบันทึก ::
    const data = { id, key }
    const isEditing = !!_.find(priceEditing, data); // รายการนี้กำลังแก้ไขอยู่หรือไม่
    const editRejected = _.reject(priceEditing, data); // ปิดการแก้ไขรายการนี้
    const addNewEdit = [...priceEditing, data]; // เปิดการแก้ไขรายการนี้

    // :: TEMP VALUE - รายการที่อัปเดตไปแล้วว ::
    const tempValue = _.find(tempPricing, data); // รายการที่ถูกแก้ไขไปแล้ว (temp)
    const tempBeforeItems = _.reject(tempPricing, data); // รายการที่ถูกแก้ไขก่อนหน้า ไม่รวมรายการนี้ (temp)
    const displayValue = ((!!tempValue && tempValue?.isSaved) ? tempValue?.newValue : value)  // ราคาที่แสดงหลังจากบันทึกหรือก่อนแก้ไข

    return <div>
      {isEditing ?
        <section>
          <div className="d-flex align-items-center">
            <Input
              defaultValue={displayValue}
              className="mr-2 flex-grow-1"
              type="number"
              step="1"
              min={0}
              onChange={(e) => {
                const newValue = e.target.value;
                const beforeValue = !!tempValue ? tempValue?.beforeValue : value;
                const tempUpdated = { id, key, beforeValue, newValue: newValue.length > 0 ? parseFloat(newValue) : null, isSaved: false }
                setTempPriceing([...tempBeforeItems, tempUpdated])
              }}
            />
            <Button type="primary" className="mr-2" onClick={() => {
              const tempNewValue = tempValue?.newValue
              if (!!tempValue ? tempNewValue == null : value == null) return message.warning("รูปแบบราคาไม่ถูกต้อง!")
              if (tempNewValue < 0) return message.warning("ไม่สามารถระบุราคาติดลบได้!")
              if (!!tempValue ? tempNewValue < price_default : displayValue < price_default) return message.warning("ราคาที่ระบุต้องมากว่าหรือเท่ากับราคาตั้งต้น!")
              if (tempValue?.newValue !== tempValue?.beforeValue) {
                handleUpdatePrice({ id, key, value: tempNewValue }) // FETCH API
              }
              const tempUpdated = { ...tempValue, beforeValue: tempNewValue, isSaved: true }
              setTempPriceing([...tempBeforeItems, tempUpdated])
              setPriceEding(editRejected) // Close Edit
            }}>
              <Icon type="check" />
            </Button>
            <Button type="danger" className="mr-2" onClick={() => setPriceEding(editRejected)}>
              <Icon type="close" />
            </Button>
          </div>
          <div className="d-flex align-items-center mt-1" style={{ opacity: 0.6 }}>
            <Icon type="info-circle" className="mr-2" style={{ fontSize: "12px" }} />
            <p className="m-0" style={{ fontSize: "12px" }}>{name} : {numeral(price_default).format('0,0.00')} บาท</p>
          </div>
        </section>
        :
        <section>
          <div className="d-flex align-items-center">
            <Icon type="edit" style={{ fontSize: '18px', color: '#97D700', }} className="mr-2 cursor-pointer" onClick={() => setPriceEding(addNewEdit)} />
            <p className="m-0">{displayValue == null ? "ยังไม่มีราคา" : numeral(displayValue).format('0,0.00')}</p>
          </div>
          {displayValue != null && displayValue < price_default && <div className="d-flex align-items-center mt-1" style={{ opacity: 0.6 }}>
            <Icon type="info-circle" className="mr-2" style={{ fontSize: "12px" }} />
            <p className="m-0" style={{ fontSize: "12px" }}>{name}ต่ำกว่าราคาตั้งต้น!</p>
          </div>}
        </section>
      }
    </div>
  }

  return (
    <>
      <Panel>
        <PanelHeader>เนื้อหาสินค้า</PanelHeader>
        <PanelBody>
          <Row gutter={16} className="mb-2">
            <Col md={10}>
              <Input.Search
                placeholder="SKU, รหัสสินค้า, ชื่อสินค้า"
                defaultValue={search.query}
                onSearch={(value) => {
                  if (priceEditing?.length > 0) {
                    message.warning("กรุณาบันทึกการแก้ไขก่อนดำเนินการ!")
                  } else {
                    dispatch(SET_SEARCH({ page: 1, query: value }))
                  }
                }}
              />
            </Col>
            <Col md={5}>
              <SelectSubCategory
                value={activeFilter}
                className="w-100"
                placeholder="เลือกหมวดหมู่สินค้า"
                onChange={(e) => {
                  if (priceEditing?.length > 0) {
                    message.warning("กรุณาบันทึกการแก้ไขก่อนดำเนินการ!")
                  } else {
                    setActiveFilter(e)
                    handleCategory(e)
                  }
                }}
                changeOnSelect
              />
            </Col>
            <Col md={5}>
              <Select
                value={activeFilterStatus}
                className="w-100"
                onChange={(e) => {
                  if (priceEditing?.length > 0) {
                    message.warning("กรุณาบันทึกการแก้ไขก่อนดำเนินการ!")
                  } else {
                    setActiveFilterStatus(e)
                    dispatch(SET_SEARCH({ page: 1 }))
                  }
                }}>
                <Select.Option value="all">ทั้งหมด</Select.Option>
                <Select.Option value="active">เปิด</Select.Option>
                <Select.Option value="inactive">ปิด</Select.Option>
              </Select>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            rowKey="id"
            loading={list?.loading || isLoading}
            dataSource={list?.fetch?.data || []}
            pagination={{
              current: search.page,
              pageSize: search.limit,
              total: list?.fetch?.total || 0,
              showLessItems: true,
              size: "default",
              onChange: (page) => {
                if (priceEditing?.length > 0) {
                  message.warning("กรุณาบันทึกการแก้ไขก่อนดำเนินการ!")
                } else {
                  dispatch(SET_SEARCH({ page: page }))
                }
              },
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
            }}
          >
            <Table.Column
              title="No."
              key="no"
              align="right"
              dataIndex="row_id"
            />
            <Table.Column
              title="Product Code"
              key="product_code"
              dataIndex="product_code"
            />
            <Table.Column
              title="Product Name"
              key="product_name"
              dataIndex="product_name_en"
            />
            <Table.Column
              title="ราคาสมาชิก"
              key={COLUMN_KEYS.mkt_member_price}
              dataIndex={COLUMN_KEYS.mkt_member_price}
              render={(value, { id, member_price }) => renderPricingColumn({
                id,
                key: COLUMN_KEYS.mkt_member_price,
                value,
                name: COLUMN_KEYS.mkt_member_text,
                price_default: member_price
              })}
            />
            <Table.Column
              title="ราคานักธุรกิจ"
              key={COLUMN_KEYS.mkt_distributor_price}
              dataIndex={COLUMN_KEYS.mkt_distributor_price}
              render={(value, { id, distributor_price }) => renderPricingColumn({
                id,
                key: COLUMN_KEYS.mkt_distributor_price,
                value,
                name: COLUMN_KEYS.mkt_distributor_text,
                price_default: distributor_price
              })}
            />
            <Table.Column
              title="Public Status"
              key="public_status"
              dataIndex="mkt_public_status"
              align="center"
              render={(text, { id }) => (
                <Switch
                  defaultChecked={text === "active"}
                  onClick={(checked) => handleChangeStatus(checked, id)}
                />
              )}
            />
          </Table>
        </PanelBody>
      </Panel>
    </>
  );
}
