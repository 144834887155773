import {
  Button,
  Col,
  DatePicker,
  Input,
  Radio,
  Row,
  Switch,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import img from "../../../../../assets/img/pos/user-default.png";
import SelectCallingCode from "../../../../../components/SelectCallingCode";
import TextValidation from "../../../../../components/TextValidation";
import SelectCorporation from "../../components/SelectCorporation";
import SelectGender from "../../components/SelectGender";
import SelectNationality from "../../components/SelectNationality";
import SelectPrefixEn from "../../components/SelectPrefixEn";
import SelectPrefixTh from "../../components/SelectPrefixTh";

const MemberForm = ({ Member, setMember, errors, handleChange }) => {
  let toDay = moment();
  let birthDay = moment(Member?.birth_date, "YYYY");
  let Age = toDay.diff(birthDay, "years");
  const [open, setOpen] = useState(false);
  const [openE, setOpenE] = useState(false);
  const switchSelect = (e) => {
    if (e === true) setMember({ ...Member, is_company: 1 });
    else setMember({ ...Member, is_company: 0 });
  };
  const [formatD, setFormat] = useState("DD/MM/YYYY");
  const [formatE, setFormatE] = useState("DD/MM/YYYY");
  useEffect(() => {
    if (open) {
      setFormat("DDMMYYYY");
    } else {
      setFormat("DD/MM/YYYY");
    }
  }, [open]);
  useEffect(() => {
    if (openE) {
      setFormatE("DDMMYYYY");
    } else {
      setFormatE("DD/MM/YYYY");
    }
  }, [openE]);

  //console.log("Member?.birth_date",Member?.birth_date);
  console.log("open", open);
  return (
    <>
      <Row type="flex" justify="space-between" className="mb-4">
        <Col span={12}>
          <div>
            <Typography.Text className="pr-2">รหัสนักธุรกิจ:</Typography.Text>
            <Typography.Text strong>{Member?.partner_code}</Typography.Text>
          </div>
          <div>
            <Typography.Text className="pr-2">ประเภทสมาชิก:</Typography.Text>
            <Typography.Text strong>VIP Member</Typography.Text>
          </div>
        </Col>
        <Col span={10} style={{ textAlign: "right" }}>
          <div>
            <Typography.Text className="pr-2">วันที่สมัคร:</Typography.Text>
            <Typography.Text strong>
              {Member?.register_date
                ? moment(Member?.register_date).format("DD/MM/YYYY")
                : "ไม่มีข้อมูล"}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="pr-2">วันที่หมดอายุ:</Typography.Text>
            <Typography.Text strong>
              {Member?.expire_date
                ? moment(Member?.expire_date).format("DD/MM/YYYY")
                : "ไม่มีข้อมูล"}
            </Typography.Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div>
            <Row className="text-center">
              <Col>
                <img
                  src={img}
                  className="rounded-circle"
                  alt="Profile"
                  width={110}
                />
              </Col>
              <Col style={{ marginTop: "-20px" }}>
                <Button
                  size="small"
                  type="link"
                  className="rounded-circle bg-pv text-white"
                >
                  <FaPen fontSize="0.8em" />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={18}>
          <Row className="mb-2" gutter={8}>
            <Col md={8}>
              <label>คำนำหน้า</label>
              <TextValidation validate={errors.prefix} />
              <SelectPrefixTh
                className="w-100 f-s-12"
                size="small"
                allowClear
                value={Member?.prefix}
                onChange={(e) => setMember({ ...Member, prefix: e })}
              />
            </Col>
            <Col md={8}>
              <label>ชื่อ</label>
              <TextValidation validate={errors.first_name_th} />
              <Input
                name="first_name_th"
                size="small"
                className="f-s-12"
                value={Member?.first_name_th}
                onChange={handleChange}
              />
            </Col>
            <Col md={8}>
              <label>นามสกุล</label>
              <TextValidation validate={errors.sur_name_th} />
              <Input
                name="sur_name_th"
                size="small"
                className="f-s-12"
                value={Member?.sur_name_th}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mb-2" gutter={8}>
            <Col md={8}>
              <label>Prefix Name</label>
              <TextValidation validate={errors.prefix} />
              <SelectPrefixEn
                className="w-100 f-s-12"
                size="small"
                allowClear
                value={Member?.prefix}
                onChange={(e) => setMember({ ...Member, prefix: e })}
              />
            </Col>
            <Col md={8}>
              <label>First Name</label>
              <TextValidation validate={errors.first_name_en} />
              <Input
                name="first_name_en"
                size="small"
                className="f-s-12"
                value={Member?.first_name_en}
                onChange={handleChange}
              />
            </Col>
            <Col md={8}>
              <label>Last Name</label>
              <TextValidation validate={errors.sur_name_en} />
              <Input
                name="sur_name_en"
                size="small"
                className="f-s-12"
                value={Member?.sur_name_en}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-2" gutter={8}>
        <Col md={6}>
          <label>วัน/เดือน/ปีค.ศ. เกิด</label>
          <TextValidation validate={errors.birth_date} />
          <DatePicker
            name="birth_date"
            size="small"
            className="f-s-12"
            value={Member?.birth_date ? moment(Member?.birth_date) : null}
            onChange={(value) => {
              let e = { target: { name: "birth_date", value } };
              handleChange(e);
            }}
            placeholder="วันเดือนปี(ค.ศ.)"
            format={formatD}
            onOpenChange={(isOpen) =>
              isOpen
                ? setTimeout(() => {
                    const startInput = document.querySelector(
                      ".ant-calendar-input"
                    );
                    console.log(
                      "startInputonopen  ",
                      startInput.value.includes("/")
                    );
                    setOpen(true);
                  }, 100)
                : setOpen(false)
            }
            onFocus={() => setFormat("DDMMYYYY")}
            showToday={false}
          />
        </Col>
        <Col md={6}>
          <label>อายุ</label>
          <Input
            type="number"
            size="small"
            className="f-s-12"
            value={parseInt(Age) || 0}
            disabled
          />
        </Col>
        <Col md={6}>
          <label>สัญชาติ</label>
          <TextValidation validate={errors.nationality} />
          <SelectNationality
            className="w-100 f-s-12"
            size="small"
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.props.children.indexOf(input.toLowerCase()) >= 0
            }
            value={Member?.nationality}
            onChange={(e) =>
              setMember({
                ...Member,
                nationality: e,
                passport_id: "",
                passport_expire_date: "",
              })
            }
          />
        </Col>
        <Col md={6}>
          {["TH", "none", "no_status"].includes(Member?.nationality) ? (
            <>
              <label>{`${
                Member?.nationality === "TH" ? "เลขบัตรประชาชน" : "เลขประจำตัว"
              }`}</label>
              <Input
                name="id_card"
                size="small"
                className="f-s-12"
                onChange={handleChange}
                value={Member?.id_card}
              />
              <TextValidation validate={errors.id_card} />
            </>
          ) : (
            <>
              <label>เลขพาสปอร์ต</label>
              <Input
                name="passport_id"
                size="small"
                className="f-s-12"
                onChange={handleChange}
                value={Member?.passport_id}
              />
              <TextValidation validate={errors.passport_id} />
            </>
          )}
          {/* {
            Member?.nationality === 'TH' ?
              <label>เลขบัตรประชาชน</label>
              :
              Member?.nationality === 'none' || Member?.nationality === 'no_status' ?
                <label>เลขประจำตัว</label>
                :
                <label>เลขพาสปอร์ต</label>
          }
          <Input
            name="id_card"
            size="small"
            className="f-s-12"
            value={Member?.id_card}
            onChange={handleChange}
          />
          <TextValidation validate={errors.id_card} /> */}
        </Col>
      </Row>
      <Row className="mb-2" gutter={8}>
        {Member?.nationality === "TH" && (
          <>
            <Col md={6}>
              <label>เลขพาสปอร์ต</label>
              <Input
                name="passport_id"
                size="small"
                className="f-s-12"
                onChange={handleChange}
                value={Member?.passport_id}
              />
              <TextValidation validate={errors.passport_id} />
            </Col>
            <Col md={6}>
              <label>วันหมดอายุ</label>
              {/* <DatePicker
                name="passport_expire_date"
                size="small"
                className="f-s-12"
                value={Member?.passport_expire_date ? moment(Member?.passport_expire_date) : null}
                onChange={(value) => {
                  let e = { target: { name: "passport_expire_date", value } };
                  handleChange(e);
                }}
                placeholder="วันเดือนปี(ค.ศ.)"
                format="DD/MM/YYYY"
                showToday={false}
              /> */}
              <DatePicker
                name="birth_date"
                size="small"
                className="f-s-12"
                value={
                  Member?.passport_expire_date
                    ? moment(Member?.passport_expire_date)
                    : null
                }
                onChange={(value) => {
                  let e = { target: { name: "passport_expire_date", value } };
                  handleChange(e);
                }}
                placeholder="วันเดือนปี(ค.ศ.)"
                format={formatE}
                onOpenChange={(isOpen) =>
                  isOpen
                    ? setTimeout(() => {
                        const startInput = document.querySelector(
                          ".ant-calendar-input"
                        );
                        console.log(
                          "startInputonopen  ",
                          startInput.value.includes("/")
                        );
                        setOpenE(true);
                      }, 100)
                    : setOpenE(false)
                }
                onFocus={() => setFormatE("DDMMYYYY")}
                showToday={false}
              />
            </Col>
          </>
        )}
        <Col md={6}>
          <label>เพศ</label>
          <TextValidation validate={errors.gender} />
          <SelectGender
            className="w-100 f-s-12"
            size="small"
            allowClear
            value={Member?.gender}
            onChange={(e) => setMember({ ...Member, gender: e })}
          />
        </Col>
        {/* <Col md={6}>
          <label>โทรศัพท์</label>
          <TextValidation validate={errors.phone} />
          <Input
            name="phone"
            size="small"
            className="f-s-12"
            value={Member?.phone}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <label>มือถือ</label>
          <TextValidation validate={errors.mobile} />
          <Input
            name="mobile"
            size="small"
            className="f-s-12"
            value={Member?.mobile}
            onChange={handleChange}
          />
        </Col> */}
        <Col md={6}>
          <label>อีเมล</label>
          <TextValidation validate={errors.email} />
          <Input
            type="email"
            name="email"
            size="small"
            className="f-s-12"
            value={Member?.email}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <label>LineID</label>
          <Input
            name="line_id"
            size="small"
            className="f-s-12"
            value={Member?.line_id}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <label>Facebook</label>
          <Input
            name="facebook"
            size="small"
            className="f-s-12"
            value={Member?.facebook}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col md={24}>
        <label>มือถือ</label>
        <TextValidation validate={errors.mobiles} />
            <InputMobile
              value={Member?.mobiles}
              onChange={(e) => {
                  setMember(prevMember => {
                    return {...prevMember, mobiles: e}
                  })
              }}
            />
        </Col>
      </Row> */}
      <Row className="mb-2" gutter={8}>
        <Col md={12}>
          <label>มือถือ</label>
          <TextValidation validate={errors.mobile} />
          <Row className="mb-2" gutter={8}>
            <Col md={12}>
              <SelectCallingCode
                name="calling-code"
                className="w-100 f-s-12"
                size="small"
                value={Member?.phone_code}
                onChange={(e) => setMember({ ...Member, phone_code: e })}
                placeholder="เลือกรหัสประเทศ"
              />
            </Col>
            <Col md={12}>
              <TextValidation validate={errors.mobile} />
              <Input
                name="mobile"
                size="small"
                className="f-s-12"
                value={Member?.mobile}
                onChange={handleChange}
                placeholder="เบอร์โทร"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Col>
          <Radio.Group
            value={
              Member?.company_register_vat == null
                ? 1
                : Member?.company_register_vat
            }
            onChange={(e) =>
              setMember({ ...Member, company_register_vat: e.target.value })
            }
            style={{ padding: "8px 8px" }}
          >
            <Radio value={0} className="f-s-12 text-dark">
              คำนวณแบบ
              <span style={{ textDecoration: "underline" }}>มี</span>
              ภาษีมูลค่าเพิ่ม
              <TextValidation validate={errors.company_register_vat} />
            </Radio>
            <Radio value={1} className="f-s-12 text-dark">
              คำนวณแบบ
              <span style={{ textDecoration: "underline" }}>ไม่มี</span>
              มีภาษีมูลค่าเพิ่ม
              <TextValidation validate={errors.company_register_vat} />
            </Radio>
          </Radio.Group>
        </Col>
        <Row type="flex" justify="space-between" gutter={8}>
          <Col md={24}>
            <div className="mr-2 mt-3 mb-2">
              <Typography.Text strong className="f-s-14 pr-2">
                นิติบุคคล
              </Typography.Text>
              <Switch
                checked={(Member?.is_company || 0) !== 0 ? true : false}
                onChange={(e) => switchSelect(e)}
              />
            </div>
          </Col>
          {Member?.is_company === 1 && (
            <>
              <Row gutter={16}>
                <Col md={8}>
                  <label>ประเภทธุรกิจ</label>
                  <TextValidation validate={errors.company_type} />
                  <SelectCorporation
                    className="w-100 f-s-12"
                    size="small"
                    allowClear
                    value={Member?.company_type}
                    onChange={(e) => setMember({ ...Member, company_type: e })}
                  />
                </Col>
                <Col md={8}>
                  <label>ชื่อนิติบุคคล (ภาษาไทย)</label>
                  <TextValidation validate={errors.company_name_th} />
                  <Input
                    name="company_name_th"
                    size="small"
                    className="f-s-12"
                    value={Member?.company_name_th}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={8}>
                  <label>ชื่อนิติบุคคล (ภาษาอังกฤษ)</label>
                  <TextValidation validate={errors.company_name_en} />
                  <Input
                    name="company_name_en"
                    size="small"
                    className="f-s-12"
                    value={Member?.company_name_en}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mt-2 mb-2">
                <Col>
                  <label>เลขประจำตัวผู้เสียภาษี</label>
                  <TextValidation validate={errors.company_tax} />
                  <Input
                    name="company_tax"
                    size="small"
                    className="f-s-12"
                    value={Member?.company_tax}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </Row>
      </div>
    </>
  );
};
export default MemberForm;
