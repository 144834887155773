/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import { Button, Col, Form, Input, Row, Table } from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { getNumeral } from "../../../../utils/helper";
import { URL_API, getToken } from "../../../../utils/index";
import { printform } from "../../../../utils/printforms";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import "./CustomTable.css";
import iconOpenInNew from "./images/icon_open_in_new.svg";
import { axios } from "../../../../utils/useAxios";

const RAPromotionList = ({ form }) => {
  const { getFieldDecorator, validateFields } = form;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const loadDataCallback = useCallback(
    async (code) => {
      setLoading(true);
      try {
        const data = await axios({
          method: "get",
          baseURL: URL_API,
          url: `/marketing/promotion-point/ra`,
          headers: { Authorization: "Bearer " + getToken() },
          params: {
            search: code,
            start_date: startDate,
            end_date: endDate,
          },
        }).catch((err) => {
          console.log(err);
        });
        setList(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [startDate, endDate]
  );

  const handleSeachMember = (e) => {
    loadDataCallback(e.target.value);
  };

  const onFinish = (values) => {
    loadDataCallback(values.member_code);
  };

  const handleClickDocNo = (rec) => {
    printform("receipt", rec);
  };

  const laodConfig = async () => {
    try {
      const { data } = await axios.get(`/boss/config/bo-ra`);
      setStartDate(data?.start_date);
      setEndDate(data?.end_date);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    laodConfig();
  }, []);

  return (
    <>
      <Form onSubmit={onFinish}>
        <Panel>
          <PanelHeader>
            ตรวจสอบคะแนน RA จากโปรโมชัน ระหว่างวันที่{" "}
            {startDate
              ? `${dayjs(startDate).format("DD MMM YYYY")} - ${dayjs(
                  endDate
                ).format("DD MMM YYYY")}`
              : ""}
          </PanelHeader>
          <PanelBody>
            <Row
              style={{
                paddingTop: "24px",
                paddingBottom: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Col
                span={0}
                md={8}
                style={{
                  backgroundColor: "white",
                  border: "1px dashed #888",
                  borderRadius: "6px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      marginRight: "12px",
                      marginBottom: 0,
                    }}
                  >
                    ค้นหา:{" "}
                  </p>
                  <Form.Item style={{ margin: 0, width: "100%" }}>
                    {getFieldDecorator("member_code", {
                      getValueFromEvent: (e) => {
                        // Allow only digits (0-9)
                        return e.target.value.replace(/[^0-9]/g, "");
                      },
                    })(
                      <Input
                        placeholder="รหัสสมาชิก"
                        allowClear
                        onPressEnter={(e) => handleSeachMember(e)}
                      />
                    )}
                  </Form.Item>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "8px",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      validateFields((err, values) => {
                        if (!err) {
                          onFinish(values);
                        }
                      });
                    }}
                    style={{
                      width: "200px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </div>
              </Col>
              <Col md={14}>
                <Row>
                  <Col span={0} md={8}>
                    <div
                      align={"center"}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        height: "40px",
                        border: "1px solid #888",
                        borderRadius: "6px 0px 0px 0px",
                        padding: "8px",
                      }}
                    >
                      RA/BP ทั้งหมดที่ได้รับ
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        borderRadius: "0px 0px 0px 6px",
                        borderRight: "1px solid #888",
                        borderBottom: "1px solid #888",
                        borderLeft: "1px solid #888",
                        padding: "10px",
                      }}
                    >
                      {list?.summary?.receive_point
                        ? list?.summary?.receive_point
                        : "-"}
                    </div>
                  </Col>
                  <Col span={0} md={8}>
                    <div
                      align={"center"}
                      style={{
                        backgroundColor: "#FFB800",
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        height: "40px",
                        borderTop: "1px solid #888",
                        borderBottom: "1px solid #888",
                        padding: "8px",
                      }}
                    >
                      RA/BP ที่รับของแถมแล้ว
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        borderBottom: "1px solid #888",
                        padding: "10px",
                      }}
                    >
                      {list?.summary?.redeem_point
                        ? list?.summary?.redeem_point
                        : "-"}
                    </div>
                  </Col>
                  <Col span={0} md={8}>
                    <div
                      align={"center"}
                      style={{
                        backgroundColor: "#97D700",
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        height: "40px",
                        border: "1px solid #888",
                        borderRadius: "0px 6px 0px 0px",
                        padding: "8px",
                      }}
                    >
                      RA/BP คงเหลือ
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        borderRadius: "0px 0px 6px 0px",
                        borderRight: "1px solid #888",
                        borderBottom: "1px solid #888",
                        borderLeft: "1px solid #888",
                        padding: "10px",
                      }}
                    >
                      {list?.summary?.balance_point
                        ? list?.summary?.balance_point
                        : "-"}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div
              style={{
                marginTop: "-24px",
                paddingBottom: "24px",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col
                md={0}
                align={"center"}
                style={{
                  backgroundColor: "white",
                  border: "1px dashed #888",
                  borderRadius: "6px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      marginRight: "12px",
                      marginBottom: 0,
                    }}
                  >
                    ค้นหา:{" "}
                  </p>
                  <Form.Item style={{ margin: 0, width: "100%" }}>
                    {getFieldDecorator("member_code", {
                      getValueFromEvent: (e) => {
                        // Allow only digits (0-9)
                        return e.target.value.replace(/[^0-9]/g, "");
                      },
                    })(
                      <Input
                        placeholder="รหัสสมาชิก"
                        allowClear
                        onPressEnter={(e) => handleSeachMember(e)}
                      />
                    )}
                  </Form.Item>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "8px",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      validateFields((err, values) => {
                        if (!err) {
                          onFinish(values);
                        }
                      });
                    }}
                    style={{
                      width: "200px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </div>
              </Col>
              <Col
                md={0}
                style={{
                  marginTop: "12px",
                }}
              >
                <Row>
                  <Col md={0}>
                    <div
                      align={"center"}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        height: "40px",
                        border: "1px solid #888",
                        borderRadius: "6px 6px 0px 0px",
                        padding: "8px",
                      }}
                    >
                      RA/BP ทั้งหมดที่ได้รับ
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        border: "1px solid #888",
                        borderRadius: "0px 0px 6px 6px",
                        padding: "10px",
                      }}
                    >
                      {list?.summary?.receive_point
                        ? list?.summary?.receive_point
                        : "-"}
                    </div>
                  </Col>
                  <Col md={0}>
                    <div
                      align={"center"}
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#FFB800",
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        height: "40px",
                        border: "1px solid #888",
                        borderRadius: "6px 6px 0px 0px",
                        padding: "8px",
                      }}
                    >
                      RA/BP ที่รับของแถมแล้ว
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        border: "1px solid #888",
                        borderRadius: "0px 0px 6px 6px",
                        padding: "10px",
                      }}
                    >
                      {list?.summary?.redeem_point
                        ? list?.summary?.redeem_point
                        : "-"}
                    </div>
                  </Col>
                  <Col md={0}>
                    <div
                      align={"center"}
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#97D700",
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        height: "40px",
                        border: "1px solid #888",
                        borderRadius: "6px 6px 0px 0px",
                        padding: "8px",
                      }}
                    >
                      RA/BP คงเหลือ
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        border: "1px solid #888",
                        borderRadius: "0px 0px 6px 6px",
                        padding: "10px",
                      }}
                    >
                      {list?.summary?.balance_point
                        ? list?.summary?.balance_point
                        : "-"}
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
            <div>
              <div
                style={{
                  fontWeight: 800,
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "black",
                }}
              >
                {list?.summary?.partner_name}
              </div>
              <Table
                className="custom-table"
                bordered
                size="small"
                rowKey="id"
                loading={loading}
                dataSource={list?.items || []}
                pagination={{
                  // current: search.page,
                  // pageSize: search.limit,
                  total: list?.items?.total || 0,
                  showLessItems: true,
                  showSizeChanger: true,
                  size: "default",
                  // onChange: (page) => dispatch(SET_SEARCH({ page: page })),
                  // onShowSizeChange: (current, size) => {
                  //   dispatch(
                  //     SET_SEARCH({
                  //       page: 1,
                  //       limit: size,
                  //     })
                  //   );
                  // },
                  showTotal: (total, range) =>
                    `${getNumeral(range[0])} - ${getNumeral(
                      range[1]
                    )} of ${getNumeral(total)}`,
                  pageSizeOptions: ["10", "25", "50", "100"],
                }}
              >
                <Table.Column
                  title="#"
                  key="row_id"
                  align="center"
                  // render={(text, record, index) =>
                  //   (search.page - 1) * pageSize + index + 1
                  // }
                />
                <Table.Column
                  align="center"
                  title="วันที่ดำเนินการ"
                  key="document_date"
                  render={(text, record) => {
                    if (record.document_type === "Cancel") {
                      return moment(record.create_date).format("DD/MM/YYYY");
                    } else {
                      return moment(record.document_date).format("DD/MM/YYYY");
                    }
                  }}
                />
                <Table.Column
                  align="center"
                  title="ประเภทใบเสร็จ"
                  key="document_type"
                  render={(text, record) => {
                    if (record.document_type === "Free") {
                      return (
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          ของแถม
                        </p>
                      );
                    } else if (record.document_type === "Bill") {
                      return (
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          บิล
                        </p>
                      );
                    } else {
                      return (
                        <p
                          style={{
                            marginBottom: 0,
                            color: "red",
                          }}
                        >
                          ยกเลิก
                        </p>
                      );
                    }
                  }}
                />
                <Table.Column
                  align="center"
                  title="เลขที่บิล"
                  key="document_no"
                  render={(text, record) => (
                    <div
                      onClick={() => {
                        if (record.report_id) handleClickDocNo(record);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        cursor: record.report_id && "pointer",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 0,
                          textDecoration: record.report_id && "underline",
                          marginRight: "5px",
                        }}
                      >
                        {record.document_no}
                      </p>
                      {record.report_id && (
                        <img
                          src={iconOpenInNew}
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      )}
                    </div>
                  )}
                />
                <Table.Column
                  align="center"
                  title="คะแนน RA/BP"
                  key="rabp_point"
                  render={(text, record) => {
                    if (record.total_point > 0) {
                      return record.total_point;
                    } else {
                      if (record.document_type === "Free") {
                        return (
                          <p
                            style={{
                              marginBottom: 0,
                              color: "red",
                              backgroundColor: "#FFF1CC",
                            }}
                          >
                            ({record.total_point})
                          </p>
                        );
                      } else {
                        return (
                          <p
                            style={{
                              marginBottom: 0,
                              color: "red",
                            }}
                          >
                            ({record.total_point})
                          </p>
                        );
                      }
                    }
                  }}
                />
              </Table>
            </div>
          </PanelBody>
        </Panel>
      </Form>
    </>
  );
};

export default Form.create("ra-promotion")(RAPromotionList);
