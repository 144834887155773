import { Button, Col, Divider, Modal, notification, Row } from "antd";
import React, { useContext } from "react";
import { axios, getToken, URL_API, _ } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import ArrangeContext from "../reducer";

const BillCanceledButton = ({ reload, onCancel, type }) => {
  const {
    state: {
      bill: { document_no, ref_document_no },
    },
  } = useContext(ArrangeContext);

  const sendSubmit = () => {
    if (!_.includes(["bill", "preview"], type)) return;
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรับทราบการยกเลิกหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          const is_bill = type === "bill";
          const sub_path = is_bill ? "/bill" : "";
          axios({
            method: "post",
            url: `${URL_API}/arrange${sub_path}/cancel-status`,
            headers: {
              Authorization: "Bearer " + getToken(),
            },
            data: { document_no: is_bill ? ref_document_no : document_no },
          })
            .then(() => {
              resolve();
              reload();
              onCancel();
              notification.success({
                message: "บันทึกรับทราบการยกเลิกแล้ว",
              });
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  return (
    <>
      <Divider />
      <Row>
        <Col className="text-center">
          <Button className="bg-nn border-nn text-white" onClick={sendSubmit}>
            รับทราบการยกเลิก
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BillCanceledButton;
