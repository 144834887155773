import React from "react";
import { EventMutateProvider } from "../../../../contexts/events.context";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import CriteriaTicket from "./components/CriteriaTicket";
import SummaryTicket from "./components/SummaryTicket";
import TableTicket from "./components/TableTicket";

export default function TicketList() {
  return (
    <EventMutateProvider>
      <Panel>
        <PanelHeader>ข้อมูลบัตรงาน</PanelHeader>
        <PanelBody>
          <SummaryTicket />
          <CriteriaTicket />
          <TableTicket />
        </PanelBody>
      </Panel>
    </EventMutateProvider>
  );
}
