import { Col, Row } from "antd";
import React, { useContext } from "react";
import ModalControl from "../../ModalControl";
import MemberProfileContext from "../reducer";
import Info from "./Info";

const PreviewModal = () => {
  const {
    state: {
      previewData: { partner_code, ref_id, ref_type },
    },
    dispatch,
  } = useContext(MemberProfileContext);
  return (
    <ModalControl
      title="ข้อมูลรหัสเก่า"
      visible={!!partner_code}
      width={1000}
      footerLine={false}
      destroyOnClose={true}
      onCancel={() =>
        dispatch({ type: "clearGlobalState", payload: "previewData" })
      }
    >
      <Row className="mt-3">
        <Col span={24}>
          <Info partnerCode={partner_code} refId={ref_id} refType={ref_type} />
        </Col>
      </Row>
    </ModalControl>
  );
};

export default PreviewModal;
