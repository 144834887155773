/* eslint-disable react-hooks/exhaustive-deps */
import { Progress, Typography, Upload, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { MdPhotoCamera } from "react-icons/md";
import { RiFileUploadLine } from "react-icons/ri";
import styles from "../../scss/module/upload.module.scss";
import { axios, getToken, URL_API, _ } from "../../utils";
import { beforeUploadFile, resCatchModal } from "../../views/util/helper";
import CameraModal from "../CameraModal";

const { Text } = Typography;

const UploadBtn = ({
  size,
  withCamera,
  acceptImage,
  acceptPDF,
  acceptVideo,
  limitFileSize,
  onChange,
  containerName,
  limitWidth,
  limitHeight,
}) => {
  const [upLoadProgress, setUpLoadProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const [showCamera, setShowCamera] = useState(false);
  const [fileAccept, setFileAccept] = useState("");
  useEffect(() => {
    return () => {
      source.cancel("unmounted");
    };
  }, []);

  useEffect(() => {
    let types = [];
    if (acceptImage) types = [...types, "image/jpeg", "image/png"];
    if (acceptPDF) types = [...types, "application/pdf"];
    if (acceptVideo) types = [...types, "video/mp4"];
    setFileAccept(_.join(types, ","));
  }, [acceptImage, acceptPDF, acceptVideo]);

  const beforeUpload = useCallback(
    (file) =>
      beforeUploadFile(
        file,
        { acceptImage, acceptPDF, acceptVideo },
        limitFileSize
      ),
    [acceptImage, acceptPDF, acceptVideo]
  );

  const handleUpload = useCallback(
    (e) => {
      setShowCamera(false);
      setLoading(true);
      const data = new FormData();
      data.append("file", e.file);
      data.append("container_name", containerName);
      const img = new Image();
      img.src = window.URL.createObjectURL(e.file);
      img.onload = function () {
        const validDimensions = img.width === limitWidth && img.height === limitHeight;
        if (!validDimensions && limitWidth !== null && limitHeight !== null) {
          notification.warning({
            message: "ไม่สามารถเลือกได้",
            description: `ไฟล์ต้องมีขนาดไฟล์เท่ากับ ${limitHeight} x ${limitWidth}px`,
            placement: "topRight",
          });
          setLoading(false);
        } else {
          axios({
            method: "post",
            url: `${URL_API}/file/upload`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
            cancelToken: source.token,
            onUploadProgress: (e) =>
              setUpLoadProgress(~~((e.loaded * 100) / e.total)),
          })
            .then((res) => onChange({ ...res.data.data, temp: true }))
            .catch((e) => resCatchModal(e))
            .finally(() => {
              setSource(axios.CancelToken.source());
              setLoading(false);
            });
        }
      };
    },
    [source.token]
  );

  const handleCloseCamera = useCallback(() => setShowCamera(false), []);

  return (
    <div className={`${styles.block} ${styles.btn}`}>
      {loading ? (
        <>
          <Progress
            size="small"
            percent={upLoadProgress}
            status="active"
            strokeColor="#97d700"
            className="px-1"
            showInfo={false}
          />
          <Text>{upLoadProgress}%</Text>
        </>
      ) : (
        <>
          <AiOutlineUpload className={styles["icon-in"]} />
          {size !== "small" && (
            <Typography.Text className="mt-2">อัปโหลด</Typography.Text>
          )}
        </>
      )}
      <div className={styles.add}>
        {loading ? (
          <>
            <FaTimes
              className={styles.icon}
              onClick={() => {
                source.cancel("cancel");
                setSource(axios.CancelToken.source());
              }}
            />
          </>
        ) : (
          <>
            <Upload
              accept={fileAccept}
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={handleUpload}
            >
              <RiFileUploadLine className={styles.icon} />
            </Upload>
            {acceptImage && withCamera && (
              <MdPhotoCamera
                className={styles.icon}
                onClick={() => setShowCamera(true)}
              />
            )}
          </>
        )}
      </div>
      {acceptImage && withCamera && (
        <CameraModal
          allowChangeCamera
          visible={showCamera}
          onSubmit={handleUpload}
          onCancel={handleCloseCamera}
        />
      )}
    </div>
  );
};

export default React.memo(UploadBtn);
