/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../../../../components/TextEditor";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import { beforeUploadImg } from "../../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";
import mwEcosysConstants from "../../../utils/constants/mwEcosysConstants";
import { dummyRequest } from "../../../components/dummyRequest";
import useUploadFile from "../../../components/useUploadFile";
import useAsync from "../../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../../services/manage-website.service";
import { AlertConfirm, AlertSuccess } from "../../../../../util/Alert";
import { DivStyles, ImgStyles } from "../../../components/mwStyles";

function RP_HowToUse({ history, form, ...props }) {
  let { onUpload } = useUploadFile();
  const [loadValue, setLoadValue] = useState("");

  const [fileListDesktop1, setFileListDesktop1] = useState([]);
  const [fileListDesktop2, setFileListDesktop2] = useState([]);
  const [fileListDesktop3, setFileListDesktop3] = useState([]);
  const [fileListDesktop4, setFileListDesktop4] = useState([]);

  const [fileListMobile1, setFileListMobile1] = useState([]);
  const [fileListMobile2, setFileListMobile2] = useState([]);
  const [fileListMobile3, setFileListMobile3] = useState([]);
  const [fileListMobile4, setFileListMobile4] = useState([]);

  const [fileDelete, setFileDelete] = useState([]);

  const [contentTextEditor1, setContentTextEditor1] = useState();
  const [contentTextEditor2, setContentTextEditor2] = useState();
  const [contentTextEditor3, setContentTextEditor3] = useState();
  const [contentTextEditor4, setContentTextEditor4] = useState();
  const [descriptionTextEditor, setDescriptionTextEditor] = useState();

  const desktopWidth = mwEcosysConstants.rp_howtouse_page.desktop_img.width;
  const desktopHeight = mwEcosysConstants.rp_howtouse_page.desktop_img.height;

  const mobileWidth = mwEcosysConstants.rp_howtouse_page.mobile_img.width;
  const mobileHeight = mwEcosysConstants.rp_howtouse_page.mobile_img.height;

  const { execute: updateEcosystemRPHowtouse } = useAsync(
    manageWebsiteService.updateEcosystemRPHowtouse,
    {
      onSuccess: async () => {
        await AlertSuccess("อัพเดตสถานะสำเร็จ");
        history.go(0);
      },
    }
  );

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      const formData = new FormData();
      if (fileListDesktop1[0]?.file) {
        formData.append(
          "desktop_img1",
          fileListDesktop1[0]?.file?.originFileObj
        );
      }
      if (fileListDesktop2[0]?.file) {
        formData.append(
          "desktop_img2",
          fileListDesktop2[0]?.file?.originFileObj
        );
      }
      if (fileListDesktop3[0]?.file) {
        formData.append(
          "desktop_img3",
          fileListDesktop3[0]?.file?.originFileObj
        );
      }
      if (fileListDesktop4[0]?.file) {
        formData.append(
          "desktop_img4",
          fileListDesktop4[0]?.file?.originFileObj
        );
      }
      if (fileListMobile1[0]?.file) {
        formData.append("mobile_img1", fileListMobile1[0]?.file?.originFileObj);
      }
      if (fileListMobile2[0]?.file) {
        formData.append("mobile_img2", fileListMobile2[0]?.file?.originFileObj);
      }
      if (fileListMobile3[0]?.file) {
        formData.append("mobile_img3", fileListMobile3[0]?.file?.originFileObj);
      }
      if (fileListMobile4[0]?.file) {
        formData.append("mobile_img4", fileListMobile4[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, [
            "desktop_img1",
            "desktop_img2",
            "desktop_img3",
            "desktop_img4",
            "mobile_img1",
            "mobile_img2",
            "mobile_img3",
            "mobile_img4",
          ]),
          content: JSON.stringify({
            content1: contentTextEditor1,
            content2: contentTextEditor2,
            content3: contentTextEditor3,
            content4: contentTextEditor4,
          }),
          file_delete: fileDelete,
          description: descriptionTextEditor,
        })
      );

      let url_path;
      loadValue != (null || "")
        ? (url_path =
            "/manage-website/ecosystem/reward-point/rp-howtouse/update")
        : (url_path = "/manage-website/ecosystem/reward-point/rp-howtouse");

      const myData = { url_path, formData };
      const confirm = await AlertConfirm();
      if (confirm) {
        message.loading({ content: "กำลังบันทึกข้อมูล..." });
        await updateEcosystemRPHowtouse(myData);
        console.log("posted!");
      }
    });
  };

  // const handleRemoveDesktop1 = async (e) => {
  //   setFileListDesktop1([]);
  //   //------------------------------------check delete[index] db
  //   if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
  //     setFileDelete([...fileDelete, e.uid]);
  //   }
  // };
  
  // const handleRemoveMobile1 = async (e) => {
  //   setFileListMobile1([]);
  //   //------------------------------------check delete[index] db
  //   if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
  //     setFileDelete([...fileDelete, e.uid]);
  //   }
  // };
  

  const handleUploadDesktop1 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop1(isFile);
    }
  };

  const handleUploadDesktop2 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop2(isFile);
    }
  };

  const handleUploadDesktop3 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop3(isFile);
    }
  };

  const handleUploadDesktop4 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop4(isFile);
    }
  };

  const handleUploadMobile1 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile1(isFile);
    }
  };

  const handleUploadMobile2 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile2(isFile);
    }
  };

  const handleUploadMobile3 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile3(isFile);
    }
  };

  const handleUploadMobile4 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile4(isFile);
    }
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/reward-point/rp-howtouse",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        let data = res.data?.data;
        setLoadValue(data);

        let loadContent = JSON.parse(data.content);
        if (loadContent) {
          setContentTextEditor1(loadContent.content1);
          setContentTextEditor2(loadContent.content2);
          setContentTextEditor3(loadContent.content3);
          setContentTextEditor4(loadContent.content4);
        }

        if (data.description) {
          setDescriptionTextEditor(data.description);
        }

        let { loadDesktopImg, loadMobileImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            if (data.files[i].link_target == "desktop_img1") {
              setFileListDesktop1(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img2") {
              setFileListDesktop2(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img3") {
              setFileListDesktop3(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img4") {
              setFileListDesktop4(loadDesktopImg);
            }
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            if (data.files[i].link_target == "mobile_img1") {
              setFileListMobile1(loadMobileImg);
            } else if (data.files[i].link_target == "mobile_img2") {
              setFileListMobile2(loadMobileImg);
            } else if (data.files[i].link_target == "mobile_img3") {
              setFileListMobile3(loadMobileImg);
            } else if (data.files[i].link_target == "mobile_img4") {
              setFileListMobile4(loadMobileImg);
            }
          }
        }

        // form.setFieldsValue({
        //   ...data,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>How to use RP</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Row>
              <Divider orientation="left" className="mb-4 mt-4">
                ขั้นตอนที่ 1
              </Divider>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop1.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop1[0].url}
                          alt="desktop_img1"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile1.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile1[0].url}
                        alt="mobile_img1"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Desktop ${desktopWidth} x ${desktopHeight} px`}
                  >
                    {getFieldDecorator("desktop_img1", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop1 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadDesktop1}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Mobile ${mobileWidth} x ${mobileHeight} px`}
                  >
                    {getFieldDecorator("mobile_img1", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile1 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadMobile1}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-3">
                <p>Content :</p>
                <TextEditor
                  authToken={getToken()}
                  value={contentTextEditor1}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setContentTextEditor1(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Row>

              <Divider orientation="left" className="mb-4 mt-4">
                ขั้นตอนที่ 2
              </Divider>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop2.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop2[0].url}
                          alt="desktop_img2"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile2.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile2[0].url}
                        alt="mobile_img2"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Desktop ${desktopWidth} x ${desktopHeight} px`}
                  >
                    {getFieldDecorator("desktop_img2", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop2 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadDesktop2}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Mobile ${mobileWidth} x ${mobileHeight} px`}
                  >
                    {getFieldDecorator("mobile_img2", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile2 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadMobile2}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-3">
                <p>Content :</p>
                <TextEditor
                  authToken={getToken()}
                  value={contentTextEditor2}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setContentTextEditor2(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Row>

              <Divider orientation="left" className="mb-4 mt-4">
                ขั้นตอนที่ 3
              </Divider>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop3.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop3[0].url}
                          alt="desktop_img3"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile3.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile3[0].url}
                        alt="mobile_img3"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Desktop ${desktopWidth} x ${desktopHeight} px`}
                  >
                    {getFieldDecorator("desktop_img3", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop3 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadDesktop3}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Mobile ${mobileWidth} x ${mobileHeight} px`}
                  >
                    {getFieldDecorator("mobile_img3", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile3 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadMobile3}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-3">
                <p>Content :</p>
                <TextEditor
                  authToken={getToken()}
                  value={contentTextEditor3}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setContentTextEditor3(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Row>

              <Divider orientation="left" className="mb-4 mt-4">
                ขั้นตอนที่ 4
              </Divider>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop4.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop4[0].url}
                          alt="desktop_img4"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile4.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile4[0].url}
                        alt="mobile_img4"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Desktop ${desktopWidth} x ${desktopHeight} px`}
                  >
                    {getFieldDecorator("desktop_img4", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop4 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadDesktop4}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label={`ขนาดสำหรับ Mobile ${mobileWidth} x ${mobileHeight} px`}
                  >
                    {getFieldDecorator("mobile_img4", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile4 || []}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onChange={handleUploadMobile4}
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพ
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-3">
                <p>Content :</p>
                <TextEditor
                  authToken={getToken()}
                  value={contentTextEditor4}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setContentTextEditor4(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Row>
            </Row>
            <Divider />
            <Row className="mb-3">
              <h4>Remark</h4>
              <TextEditor
                authToken={getToken()}
                value={descriptionTextEditor}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setDescriptionTextEditor(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-rp-howtouse")(RP_HowToUse);
