/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectPriority from "../../../../../components/SelectPriority";
import ValidateError from "../../../../../components/ValidateError";
import { actionClaim } from "../../../../../redux/slices/claim";
import { moment } from "../../../../../utils";
import SelectBranchCode from "./SelectBranchCode";

const { setFormValue } = actionClaim;

const { Text } = Typography;

const BranchDocument = ({ disabled, isOnlineWait }) => {
  const dispatch = useDispatch();
  const isWaitManager = useSelector(
    (state) => state.claim.documentDetail.status === "wait-manager"
  );
  const document_no = useSelector(
    (state) => state.claim.documentDetail.document_no
  );
  const document_date = useSelector(
    (state) => state.claim.documentDetail.document_date
  );
  const user_branch_name = useSelector(
    (state) => state.claim.documentDetail.user_branch_name
  );
  const branch_name = useSelector(
    (state) => state.claim.documentDetail.branch_name
  );
  const branch_priority = useSelector(
    (state) => state.claim.documentDetail.branch_priority
  );
  const manager_priority = useSelector(
    (state) => state.claim.documentDetail.manager_priority
  );
  const branch_priority_error = useSelector(
    (state) => state.claim.error.branch_priority
  );

  return (
    <>
      <Row gutter={8} type="flex" className="align-items-center mb-3">
        <Col span={8} className="text-right">
          <Text strong>สาขารับเรื่อง</Text>
        </Col>
        <Col span={16}>
          <Text>{user_branch_name}</Text>
        </Col>
      </Row>
      <Row gutter={8} type="flex" className="align-items-center mb-3">
        <Col span={8} className="text-right">
          <Text strong>เลขที่ใบรับคืน</Text>
        </Col>
        <Col span={16}>
          <Text>{document_no}</Text>
        </Col>
      </Row>
      <Row gutter={8} type="flex" className="align-items-center mb-3">
        <Col span={8} className="text-right">
          <Text strong>วันที่ออก</Text>
        </Col>
        <Col span={16}>
          <Text>
            {document_date && moment(document_date).format("DD/MM/YYYY")}
          </Text>
        </Col>
      </Row>
      <Row gutter={8} type="flex" className="align-items-center mb-3">
        <Col span={8} className="text-right">
          <Text strong>สาขารับเคลม</Text>
        </Col>
        <Col span={16}>
          {disabled ? <Text>{branch_name}</Text> : <SelectBranchCode />}
        </Col>
      </Row>
      <Row gutter={8} type="flex" className="align-items-center mb-3">
        <Col span={8} className="text-right">
          <Text strong>ลำดับความสำคัญสาขา</Text>
        </Col>
        <Col span={16}>
          {disabled ? (
            <>
              {branch_priority === 1 ? (
                <Text className="text-pv" strong>
                  ต่ำ
                </Text>
              ) : (
                branch_priority === 2 && (
                  <Text className="text-nn" strong>
                    สูง
                  </Text>
                )
              )}
            </>
          ) : (
            <div className={branch_priority_error && "has-error"}>
              <SelectPriority
                allowClear
                size="small"
                placeholder="เลือกลำดับความสำคัญ"
                disabled={isOnlineWait}
                value={branch_priority ?? undefined}
                onChange={(branch_priority) =>
                  dispatch(
                    setFormValue({
                      name: "documentDetail",
                      value: { branch_priority },
                      error_name: "branch_priority",
                    })
                  )
                }
              />
            </div>
          )}
          <ValidateError error={branch_priority_error} />
        </Col>
      </Row>
      {isWaitManager && (
        <Row gutter={8} type="flex" className="align-items-center mb-3">
          <Col span={8} className="text-right">
            <Text strong>ลำดับความสำคัญ Manager</Text>
          </Col>
          <Col span={16}>
            {manager_priority === 1 ? (
              <Text className="text-pv" strong>
                ต่ำ
              </Text>
            ) : (
              manager_priority === 2 && (
                <Text className="text-nn" strong>
                  สูง
                </Text>
              )
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default memo(BranchDocument);
