import React from 'react'
import { Form } from 'react-bootstrap';
import styled from 'styled-components'

const CustomColorCheck = styled.div`
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #97D700;
    background-color: #97D700;
}
`

export default function FormCheck(props) {
    const { type = "checkbox",inline, ...rest } = props;
    return (
        <CustomColorCheck>
            <Form.Check
                type={type}
                inline={inline}
                {...rest}
            />
        </CustomColorCheck>
    )
}
