/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography, } from 'antdV4';
import { Select, Row, Col } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import newsConstants from '../../constants/newsConstants';
import { FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { newsFormDefaultValues } from '../dto/news';
import ImageUpload from '../upload/ImageUpload';

const NewsForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: 'newsContentBoxes',
    control,
  });


  const { execute: getNewsById } = useAsync(fitStudioService.getNewsById, {
    onSuccess: (data) => {
      console.log(data);
      reset({
        ...data,
        categoryId: data.category.id,
        formType: 'edit',
      });
    },
  });

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getNewsById(watch('id'));
    } else {
      reset(newsFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={newsConstants.newsThumbnail.width}
                  height={newsConstants.newsThumbnail.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  หัวข้อ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="subTitle"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  หัวข้อรอง <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} />
              </Col>
              <ErrorMessage
                errors={errors}
                name="subTitle"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <div
        className="mb-2"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Text>
          กล่องเนื้อหา <span style={{ color: 'red' }}>*</span>
        </Typography.Text>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            append({
              id: '',
              title: '',
              description: '',
              imageUrl: '',
              imageDescription: '',
              imagePosition: '',
            });
          }}
        >
          <FaPlus />
        </div>
      </div>
      {fields.map((field, index) => {
        return (
          <div
            key={index}
            style={{
              padding: 10,
              border: '1px solid #b6c2c9',
              borderRadius: 5,
            }}
            className="mb-2"
          >
            <div
              className="mb-2"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography.Text>กล่องเนื้อหา {index + 1}</Typography.Text>
              {index > 0 && (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <MdDelete />
                </div>
              )}
            </div>
            <Controller
              name={`newsContentBoxes.${index}.title`}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Row className="align-items-center  mb-2">
                    <Col span={4}>
                      <Typography.Text>หัวข้อกล่องเนื้อหา</Typography.Text>
                    </Col>
                    <Col span={20}>
                      <Input value={value} onChange={onChange} />
                    </Col>
                  </Row>
                );
              }}
            />
            <Controller
              name={`newsContentBoxes.${index}.description`}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Row className="align-items-center  mb-2">
                    <Col span={4}>
                      <Typography.Text>เนื้อหา</Typography.Text>
                    </Col>
                    <Col span={20}>
                      <Input.TextArea value={value} onChange={onChange} />
                    </Col>
                  </Row>
                );
              }}
            />
            <Row className="align-items-center mb-2" gutter={10}>
              <Col span={12}>
                <Controller
                  name={`newsContentBoxes.${index}.imageUrl`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Row className="align-items-center mb-2">
                        <Col span={24}>
                          <ImageUpload
                            value={value}
                            onChange={onChange}
                            width={newsConstants.newsContentImage.width}
                            height={newsConstants.newsContentImage.height}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    );
                  }}
                />
              </Col>
              <Col span={12}>
                <Controller
                  name={`newsContentBoxes.${index}.imagePosition`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className="mb-2">
                        <Typography.Text>ตำแหน่งรูปภาพ</Typography.Text>
                        <Select
                          defaultValue="left"
                          value={value}
                          onChange={onChange}
                          style={{ width: 150 }}
                        >
                          <Select.Option value="left">ซ้าย</Select.Option>
                          <Select.Option value="center">ตรงกลาง</Select.Option>
                          <Select.Option value="right">ขวา</Select.Option>
                          <Select.Option value="full">
                            เต็มพื้นที่
                          </Select.Option>
                        </Select>
                      </div>
                    );
                  }}
                />
                <Controller
                  name={`newsContentBoxes.${index}.imageDescription`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className="mb-2">
                        <Typography.Text>คำบรรยายรูปภาพ</Typography.Text>
                        <Input.TextArea value={value} onChange={onChange} />
                      </div>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default NewsForm;
