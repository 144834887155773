/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  TimePicker,
  Typography,
} from "antd";
import { range } from "lodash-es";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import CreditCardPayment from "../../../../../components/CreditCardPayment";
import SelectBank from "../../../../../components/SelectBank";
import SelectPaymentType from "../../../../../components/SelectPaymentType";
import TextValidation from "../../../../../components/TextValidation";
import { axios, getToken, moment, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import UploadBtn from "./UploadBtn";

const disabledDateFn = (current) => current && current > moment();
const disabledHoursFn = () => range(+moment().format("HH") + 1, 24);
const disabledMinutesFn = () => range(+moment().format("mm") + 1, 60);

const Index = ({
  data,
  onChange = () => null,
  status = "",
  maxNowAmount = 0,
  reloadPayment,
  reserveRedeem,
  allPoint,
  documentNo,
  buyerType,
  buyerCode,
  paymentGateWay,
  kBankCustomerId,
  id,
}) => {
  const [errors, setErrors] = useState({});
  const [disabledMinutes, setDisabledMinutes] = useState(true);
  const [disabledHours, setDisabledHours] = useState(true);
  
  useEffect(() => {
    if (data.payment_type === "Cash") {
      onChange({ key: data.key, name: "bank", value: null });
      onChange({ key: data.key, name: "bank_name", value: null });
      onChange({ key: data.key, name: "ref_no", value: null });
    }
  }, [data.payment_type]);

  const commit = () => {
    const invalid = validation();
    if (!invalid) {
      let source = axios.CancelToken.source();
      Modal.confirm({
        title: "ยืนยันบันทึกข้อมูล",
        content: reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/pos/operation/reserve/payment`,
              data: {
                id: data.id,
                process: data.id ? "update" : "create",
                bank: data.bank,
                payment_date: new Date(data.payment_date),
                payment_type: data.payment_type,
                price: data.price,
                ref_no: data.ref_no,
                reserve_no: data.reserve_no,
                reward_id: data.reward_id,
              },
              headers: { Authorization: "Bearer " + getToken() },
              cancelToken: source.token,
            })
              .then(() => {
                onChange({ key: data.key, name: "commit", value: true });
                reloadPayment();
                notification.success({
                  message: "Success",
                  description: "บันทึกรายการเรียบร้อย",
                  placement: "topRight",
                });
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
          }).catch((e) => {
            resCatchModal(e);
          });
        },
        onCancel() {
          source.cancel("cancel");
        },
      });
    }
  };

  const unCommit = () => {
    Modal.confirm({
      title: "ยืนยันแก้ไขข้อมูล",
      content: reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        onChange({ key: data.key, name: "commit", value: false });
      },
    });
  };

  const deletePayment = () => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันลบข้อมูล",
      content: reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/pos/operation/reserve/payment`,
            data: {
              id: data.id,
              process: "delete",
              payment_type: data.payment_type,
              reward_id: data.reward_id,
            },
            headers: { Authorization: "Bearer " + getToken() },
            cancelToken: source.token,
          })
            .then(() => {
              reloadPayment();
              notification.success({
                message: "Success",
                description: "บันทึกรายการเรียบร้อย",
                placement: "topRight",
              });
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  const validation = () => {
    let err = {};
    let notEmpty = ["payment_date", "payment_type", "price"];
    if (!reserveRedeem) {
      notEmpty = [...notEmpty, "bank"];
    }
    for (let name in data) {
      if (notEmpty.includes(name)) {
        if (data.payment_type !== "Cash") {
          if (data[name] === null || data[name] === 0) {
            err[name] = "*กรุณากรอกข้อมูล";
          }
        } else if (name !== "bank") {
          if (data[name] === null || data[name] === 0) {
            err[name] = "*กรุณากรอกข้อมูล";
          }
        }
        if (name === "price") {
          if (maxNowAmount < 0) {
            err[name] = `*จำนวน${reserveRedeem ? "คะแนน" : "เงิน"}ไม่ถูกต้อง`;
          }
        }
      }
    }
    if (reserveRedeem) {
      if (data.price > allPoint && !err.price) {
        err.price = "*จำนวนคะแนนไม่พอ";
      }
    }
    setErrors(err);
    for (let name in data) {
      if (err[name]) {
        return true;
      }
    }
    return false;
  };

  const handleChangeTime = (e) => {
    const { key } = data;
    if (e) {
      const hour_res = moment(e).format("HH") === moment().format("HH");
      setDisabledMinutes(disabledHours && hour_res);
      const payment_date = moment(data.payment_date).format("YYYY-MM-DD");
      const payment_time = moment(e).format("HH:mm");
      const value = `${payment_date} ${payment_time}`;
      onChange({ key, name: "payment_date", value });
    } else {
      onChange({ key, name: "payment_date", value: e });
    }
  };

  const handleChangeDate = (key, value) => {
    onChange({ key, name: "payment_date", value });
    const date_res = moment(value).format("DD") === moment().format("DD");
    setDisabledMinutes(date_res);
    setDisabledHours(date_res);
  };

  return (
    <Row
      gutter={8}
      className="px-4 mb-2"
      style={{ fontSize: "12px", fontWeight: "normal" }}
    >
      <Col span={3} className="text-center">
        {data.commit ? (
          <Typography.Text className="text-dark">
            {data.payment_date
              ? moment(data.payment_date).format("DD/MM/YYYY")
              : ""}
          </Typography.Text>
        ) : (
          <>
            <DatePicker
              size="small"
              format="DD/MM/YYYY"
              className="w-100"
              placeholder=""
              popupStyle={{ zIndex: "1200", fontSize: "12px" }}
              value={data.payment_date ? moment(data.payment_date) : ""}
              onChange={(e) => handleChangeDate(data.key, e)}
              style={{ fontSize: "12px" }}
              showToday={false}
              disabledDate={disabledDateFn}
            />
            <TextValidation validate={errors.payment_date} />
          </>
        )}
      </Col>
      <Col span={2} className="text-center">
        {data.commit ? (
          <Typography.Text className="text-dark">
            {data.payment_date ? moment(data.payment_date).format("HH:mm") : ""}
          </Typography.Text>
        ) : (
          <TimePicker
            size="small"
            format="HH:mm"
            className="w-100"
            placeholder=""
            popupStyle={{ zIndex: "1200", fontSize: "12px" }}
            value={data.payment_date ? moment(data.payment_date) : ""}
            disabled={!data.payment_date}
            onChange={handleChangeTime}
            style={{ fontSize: "12px" }}
            disabledHours={disabledHours ? disabledHoursFn : undefined}
            disabledMinutes={disabledMinutes ? disabledMinutesFn : undefined}
          />
        )}
      </Col>
      <Col span={3} className="text-center">
        {data.commit || reserveRedeem ? (
          <Typography.Text className="text-dark">
            {data.payment_type_name || "-"}
          </Typography.Text>
        ) : (
          <>
            <SelectPaymentType
              size="small"
              style={{ fontSize: "12px" }}
              dropdownStyle={{ zIndex: "1200", fontSize: "12px" }}
              value={data.payment_type}
              onChange={(e, option) => {
                onChange({ key: data.key, name: "payment_type", value: e });
                onChange({
                  key: data.key,
                  name: "payment_type_name",
                  value: option.props.children,
                });
              }}
            />
            <TextValidation validate={errors.payment_type} />
          </>
        )}
      </Col>
      <Col span={5} className="text-center">
        {data.commit || reserveRedeem ? (
          <Typography.Text className="text-dark">
            {data.bank_name || "-"}
          </Typography.Text>
        ) : (
          <>
            <SelectBank
              showSearch
              disabled={data.payment_type === "Cash"}
              size="small"
              dropdownStyle={{ zIndex: "1200", fontSize: "12px" }}
              value={data.bank_name}
              withIcon={true}
              withCashCard={false}
              onChange={(e, option) => {
                onChange({ key: data.key, name: "bank", value: e });
                onChange({
                  key: data.key,
                  name: "bank_name",
                  value: option.props.source.name_th,
                });
              }}
              style={{ fontSize: "12px" }}
              filterOption={(input, option) =>
                option.props.source.name_th.indexOf(input.toLowerCase()) >= 0
              }
            />
            <TextValidation validate={errors.bank} />
          </>
        )}
      </Col>
      <Col span={4} className="text-center">
        {data.commit || reserveRedeem ? (
          <Typography.Text className="text-dark">
            {data.ref_no || "-"}
          </Typography.Text>
        ) : (
          <Input
            size="small"
            disabled={data.payment_type === "Cash"}
            value={data.ref_no || "-"}
            onChange={(e) =>
              onChange({
                key: data.key,
                name: "ref_no",
                value: e.target.value,
              })
            }
            style={{ fontSize: "12px" }}
          />
        )}
      </Col>

      <Col span={3} className="text-center">
        {data.commit ? (
          <Typography.Text className="text-dark">
            {numeral(data.price).format("0,0.00")}
          </Typography.Text>
        ) : (
          <>
            <InputNumber
              size="small"
              min={0}
              step={1}
              className="w-100"
              value={data.price}
              onChange={(e) => {
                if (e && !e.toString().match(/^[0-9]*\.?[0-9]{0,2}$/)) return;
                onChange({ key: data.key, name: "price", value: e });
              }}
              onFocus={(e) => e.target.select()}
              onKeyUp={(e) => e.key === "Enter" && commit()}
              style={{ fontSize: "12px" }}
              // formatter={(value) => numeral(value).format("0,0")}
              // parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
            <TextValidation validate={errors.price} />
          </>
        )}
      </Col>

      <Col span={4} className="text-right">
        {_.includes(
          ["complete", "waiting for confirm"],
          status.toLowerCase()
        ) || !!data.ref_payment_date ? (
          <>
            {_.includes(
              [
                "TP",
                "QRCR",
                "QR",
                "LP",
                "RC",
                "Other",
                "QR (Omise)",
                "Credit (Omise)",
              ],
              data.payment_type
            ) && <UploadBtn refId={data.id} disabled />}
            <FaCheck size={16} className="text-pv" />
          </>
        ) : data.commit ? (
          <>
            {_.includes(["CC", "DC", "INS"], data.payment_type) && data.id ? (
              <CreditCardPayment
                documentNo={documentNo}
                buyerCode={buyerCode}
                buyerType={buyerType}
                price={data.price}
                paymentType={data.payment_type}
                kBankCustomerId={kBankCustomerId}
                paymentGateWay={paymentGateWay}
                redirectUrl={`${window.location.origin}/reserve/payment-${data.id}-id`}
                paymentUrl={`${window.location.origin}/reserve?reserve-id=${id}`}
                paymentData={data}
                reloadPayment={reloadPayment}
              />
            ) : (
              _.includes(
                [
                  "TP",
                  "QRCR",
                  "QR",
                  "LP",
                  "RC",
                  "Other",
                  "QR (Omise)",
                  "Credit (Omise)",
                ],
                data.payment_type
              ) && <UploadBtn refId={data.id} />
            )}
            <MdEdit
              size={16}
              className="cursor-pointer mr-2"
              onClick={unCommit}
            />
            <MdDelete
              size={16}
              className="cursor-pointer"
              onClick={deletePayment}
            />
          </>
        ) : (
          <>
            <Button type="primary" size="small" onClick={commit}>
              <small>บันทึก</small>
            </Button>
            <Button
              size="small"
              className="bg-nn text-nn border-nn text-white ml-2"
              onClick={reloadPayment}
            >
              <small>ยกเลิก</small>
            </Button>
          </>
        )}
      </Col>
    </Row>
  );
};

export default Index;
