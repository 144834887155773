import { Col, Row, Tooltip } from "antd";
import React from "react";
import {
  FaCheckCircle,
  FaFileAlt,
  FaIdCard,
  FaMoneyCheck,
} from "react-icons/fa";
import { useEthicsMutate } from "../../../../contexts/ethics.context";

export default function ShowFileIcon({ files = [] }) {
  const { dispatch } = useEthicsMutate();
  const Document = files?.filter((i) => i.document_type === "1");
  const IDCard = files?.filter((i) => i.document_type === "2");
  const BookBank = files?.filter((i) => i.document_type === "3");
  const handleShowPreview = (src) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: { name: "document-icon", props: { src } },
    });
  };
  return (
    <Row
      type="flex"
      justify="start"
      align="top"
      className="text-center"
      gutter={8}
    >
      {Document.length > 0 && (
        <Row className="p-2">
          <Col>
            {Document.length > 0 && (
              <Tooltip title="ใบสมัคร">
                <FaFileAlt
                  color="#CCE2A3"
                  onClick={() => {
                    handleShowPreview(Document[0]?.azure_url);
                  }}
                  className="fa-2x cursor-pointer"
                />
              </Tooltip>
            )}
          </Col>
          <Col style={{ marginTop: "-7px" }}>
            {files?.filter(
              (i) => i.document_type === "1" && i.document_status === "approve"
            ).length > 0 && <FaCheckCircle color="green" />}
          </Col>
        </Row>
      )}
      {IDCard.length > 0 && (
        <Row className="p-2">
          <Col>
            {IDCard.length > 0 && (
              <Tooltip title="สำเนาบัตรประชาชน">
                <FaIdCard
                  color="#CCE2A3"
                  onClick={() => {
                    handleShowPreview(IDCard[0]?.azure_url);
                  }}
                  className="fa-2x cursor-pointer"
                />
              </Tooltip>
            )}
          </Col>
          <Col style={{ marginTop: "-7px" }}>
            {files?.filter(
              (i) => i.document_type === "2" && i.document_status === "approve"
            ).length > 0 && <FaCheckCircle color="green" />}
          </Col>
        </Row>
      )}
      {BookBank.length > 0 && (
        <Row className="p-2">
          <Col>
            {BookBank.length > 0 && (
              <Tooltip title="สำเนาบัญชีธนาคาร">
                <FaMoneyCheck
                  color="#CCE2A3"
                  onClick={() => {
                    handleShowPreview(BookBank[0]?.azure_url);
                  }}
                  className="fa-2x cursor-pointer"
                />
              </Tooltip>
            )}
          </Col>
          <Col style={{ marginTop: "-7px" }}>
            {files?.filter(
              (i) => i.document_type === "3" && i.document_status === "approve"
            ).length > 0 && <FaCheckCircle color="green" />}
          </Col>
        </Row>
      )}
    </Row>
  );
}
