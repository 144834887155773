/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, InputNumber, Modal, Row, Typography } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import SelectClaimVoucher from "./SelectClaimVoucher";

const { clearGlobalState, setGlobalState, addClaimOutVoucher } = actionClaim;

const AddVoucherModal = () => {
  const dispatch = useDispatch();
  const { visible, value, voucher_id } = useSelector(
    (state) => state.claim.addVoucherModal
  );

  const handleChangeVoucher = useCallback((voucher_id, option) => {
    dispatch(
      setGlobalState({
        name: "addVoucherModal",
        value: {
          voucher_id,
          value: 0,
          source: option ? { ...option.props.source } : null,
        },
      })
    );
  }, []);

  const handleAddVoucher = () => dispatch(addClaimOutVoucher());

  return (
    <Modal
      title="เพิ่มบัตรกำนัล"
      visible={visible}
      footer={false}
      zIndex={1080}
      destroyOnClose={true}
      onCancel={() => dispatch(clearGlobalState("addVoucherModal"))}
    >
      <Row gutter={[8, 8]} className="mb-3">
        <Col span={6} className="text-right">
          <Typography.Text strong>บัตรกำนัล</Typography.Text>
        </Col>
        <Col span={16}>
          <SelectClaimVoucher
            value={voucher_id}
            onChange={handleChangeVoucher}
          />
        </Col>
        <Col span={6} className="text-right">
          <Typography.Text strong>มูลค่า</Typography.Text>
        </Col>
        <Col span={16}>
          <InputNumber
            className="w-100"
            min={0}
            value={value}
            onFocus={(e) => e.target.select()}
            onKeyUp={(e) => e.key === "Enter" && handleAddVoucher()}
            onChange={(value) =>
              dispatch(
                setGlobalState({ name: "addVoucherModal", value: { value } })
              )
            }
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24} className="text-center">
          <Button
            type="primary"
            disabled={!voucher_id || !value}
            onClick={handleAddVoucher}
          >
            ตกลง
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddVoucherModal;
