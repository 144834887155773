/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaDownload, FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import { useHistory } from 'react-router';
import {
  legacyQuizCategoryProductQuestionFormDefaultValues,
  legacyQuizCategoryProductQuestionFormSchema,
} from '../components/dto/legacy-quiz-form.dto';
import fitQuizServices from '../../../../services/fit-quiz.services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import LegacyQuizCategoryProductQuestionModal from '../components/modal/LegacyQuizCategoryProductQuestionModal';
import { FiArrowLeft } from 'react-icons/fi';

dayjs.locale('th');

const pageSize = 10;

const LegacyQuizCategoryProductQuestionPage = () => {
  const params = useParams();
  const [
    legacyQuizCategoryProductQuestionFormOpen,
    setLegacyQuizCategoryProductQuestionFormOpen,
  ] = useState(false);
  const {
    data: legacyQuizCategoryProductQuestionLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [loading2, setLoading2] = useState(false);

  const { mutate: downloadLegacyQuizCategoryProductQuestionListsExcel } =
    fitQuizServices.useMutationDownloadLegacyQuizCategoryProductQuestionListsExcel(
      () => {
        setTimeout(() => {
          setLoading2(false);
        }, 600);
      }
    );

  const legacyQuizCategoryProductQuestionForm = useForm({
    defaultValues: legacyQuizCategoryProductQuestionFormDefaultValues,
    resolver: legacyQuizCategoryProductQuestionFormSchema(),
  });

  const _HandleLegacyQuizCategoryProductQuestionFormOpen = (formType) => {
    legacyQuizCategoryProductQuestionForm.setValue('formType', formType);
    setLegacyQuizCategoryProductQuestionFormOpen(true);
  };

  const _HandleLegacyQuizCategoryProductQuestionFormClose = () => {
    legacyQuizCategoryProductQuestionForm.reset(
      legacyQuizCategoryProductQuestionFormDefaultValues
    );
    setLegacyQuizCategoryProductQuestionFormOpen(false);
  };

  fitQuizServices.useQueryGetLegacyQuizCategoryProductQuestionLists(
    { take: pageSize, page, productId: params.productId },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyQuizCategoryProductQuestion } =
    fitQuizServices.useMutationDeleteLegacyQuizCategoryProductQuestion(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category-product'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyQuizCategoryProductQuestionActive } =
    fitQuizServices.useMutationUpdateLegacyQuizCategoryProductQuestionActive(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-legacy-quiz-category-product'],
          refetchType: 'all',
        });
      }
    );

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'ภาพประกอบคำถาม',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <div>
          {record.imageUrl && (
            <img
              alt={record.title}
              src={record.imageUrl}
              style={{
                width: 100,
                height: 100,
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'คำถาม',
      dataIndex: 'question',
      key: 'question',
      render: (_, record) => <span>{record.question}</span>,
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => <span>{record.active ? 'เปิด' : 'ปิด'}</span>,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.active ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการแสดงคำถาม');
                if (confirm)
                  updateLegacyQuizCategoryProductQuestionActive({
                    id: record.id,
                    active: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการแสดงคำถาม');
                if (confirm)
                  updateLegacyQuizCategoryProductQuestionActive({
                    id: record.id,
                    active: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyQuizCategoryProductQuestionForm.setValue('id', record.id);
              _HandleLegacyQuizCategoryProductQuestionFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm)
                deleteLegacyQuizCategoryProductQuestion({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
      </div>
      <Panel>
        <PanelHeader>คำถาม</PanelHeader>
        <PanelBody>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="mb-2"
          >
            <div style={{ flex: 1 }} />
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={() => {
                setLoading2(true);
                downloadLegacyQuizCategoryProductQuestionListsExcel({
                  take: -1,
                  page: 1,
                  productId: params.productId,
                });
              }}
              loading={loading2}
            >
              <FaDownload className="mr-2" />
              ดาวน์โหลด Excel ข้อมูลตอบคำถาม
            </Button>
            <Button
              className="bg-pv border-pv text-white"
              onClick={() => {
                _HandleLegacyQuizCategoryProductQuestionFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่มคำถาม
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={legacyQuizCategoryProductQuestionLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(
                    `/questionaire/quiz/quiz-category/${params.categoryId}/quiz-product/${params.productId}/quiz-question/${record.id}`
                  );
                },
              };
            }}
          />
        </PanelBody>
        <FormProvider {...legacyQuizCategoryProductQuestionForm}>
          <LegacyQuizCategoryProductQuestionModal
            open={legacyQuizCategoryProductQuestionFormOpen}
            handleClose={_HandleLegacyQuizCategoryProductQuestionFormClose}
          />
        </FormProvider>
      </Panel>
    </>
  );
};

export default LegacyQuizCategoryProductQuestionPage;
