/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table, Input } from 'antd';
import { FaEye, FaEyeSlash, FaUpload } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { Controller, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import { useHistory } from 'react-router';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import fitPrizesServices from '../../../../services/fit-prizes.services';

dayjs.locale('th');

const pageSize = 10;

const LegacyPrizesItemPartnerListPage = () => {
  const params = useParams();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    data: legacyPopUpLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { control, watch } = useForm({
    defaultValues: {
      searchText: '',
    },
  });

  fitPrizesServices.useQueryGetLegacyPrizesItemPartnerLists(
    {
      take: pageSize,
      page,
      giftEventItemId: params.giftEventItemId,
      searchText: watch('searchText'),
    },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyPrizesItemPartnerList } =
    fitPrizesServices.useMutationDeleteLegacyPrizesItemPartnerList(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-prizes-item-partner-list'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyPrizesItemPartnerListDisplay } =
    fitPrizesServices.useMutationUpdateLegacyPrizesItemPartnerListDisplay(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-legacy-prizes-item-partner-list'],
          refetchType: 'all',
        });
      }
    );

  const { mutate: uploadLegacyPrizesItemPartnerListsExcel } =
    fitPrizesServices.useMutationUploadLegacyPrizesItemPartnerListsExcel(
      (data) => {
        setTimeout(() => {
          inputRef.current.value = null;

          queryClient.invalidateQueries({
            queryKey: ['get-legacy-prizes-item-partner-list'],
            refetchType: 'all',
          });

          setLoading(false);
        }, 600);
      }
    );

  const _HandleUploadLegacyPopUpPartnerListsExcel = (data) => {
    setLoading(true);
    uploadLegacyPrizesItemPartnerListsExcel({
      data,
      giftEventItemId: params.giftEventItemId,
    });
  };

  const columns = [
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'memberCode',
      key: 'memberCode',
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <span>{record.name}</span>,
    },
    {
      title: 'ประเภท',
      dataIndex: 'isPartner',
      key: 'isPartner',
      render: (_, record) => (
        <span>{record.isPartner ? 'รหัสร่วม' : 'รหัสหลัก'}</span>
      ),
    },
    {
      title: 'การอ่าน',
      dataIndex: 'isRead',
      key: 'isRead',
      render: (_, record) => (
        <span>{record?.isRead ? 'อ่านแล้ว' : 'ยังไม่อ่าน'}</span>
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'display',
      key: 'display',
      render: (_, record) => (
        <span>{record?.display ? 'แสดง' : 'ไม่แสดง'}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.display ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการแสดง Legacy Prizes');
                if (confirm)
                  updateLegacyPrizesItemPartnerListDisplay({
                    id: record.id,
                    display: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการแสดง Legacy Prizes');
                if (confirm)
                  updateLegacyPrizesItemPartnerListDisplay({
                    id: record.id,
                    display: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyPrizesItemPartnerList({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
      </div>
      <Panel>
        <PanelHeader>Legacy Prizes Item รายชื่อ</PanelHeader>
        <PanelBody>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="mb-2"
          >
            <Controller
              name="searchText"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ค้นหาด้วยรหัสนักธุรกิจ หรือชื่อ-สกุล"
                    className="mr-2"
                  />
                );
              }}
            />
            <input
              type="file"
              id={'patient'}
              onChange={(e) => {
                if (e.target.files?.length > 0) {
                  const formData = new FormData();
                  formData.append('file', e.target.files[0]);
                  _HandleUploadLegacyPopUpPartnerListsExcel(formData);
                }
              }}
              style={{ display: 'none' }}
              ref={inputRef}
            />
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
              loading={loading}
            >
              <FaUpload className="mr-2" />
              อัพโหลด excel
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={legacyPopUpLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (record.type === 'group') {
                    history.push(
                      `/notification-setting/legacy-pop-up/${record.id}`
                    );
                  }
                },
              };
            }}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default LegacyPrizesItemPartnerListPage;
