import { Col, Input, Row, Typography } from 'antd';
import React from 'react';

const PromotionDetail = ({ promotion_code, promotion_name, handleChange }) => {
    return (
        <Row gutter={24}>
            <Col md={12}>
                <Typography.Text strong>Promotion Code</Typography.Text>
                <Input
                    name="promotion_code"
                    value={promotion_code}
                    className="mt-2"
                    onChange={handleChange}
                />
            </Col>
            <Col md={12}>
                <Typography.Text strong>Promotion Name</Typography.Text>
                <Input
                    name="promotion_name"
                    value={promotion_name}
                    className="mt-2"
                    onChange={handleChange}
                />
            </Col>
        </Row>
    )
}
export default PromotionDetail;
