/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Col, DatePicker, Row, Table, notification } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectCategory from "../../components/SelectCategory";
import SelectTeam from "../../components/SelectTeam";
import * as XLSX from "xlsx-js-style";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import Search from "antd/lib/input/Search";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectAllType from "../../../../../components/SelectAllType";
import { useApi } from "../../../../../hooks/http2";
import _ from "lodash";

const Index = () => {
  const toDay = moment().format("DD/MM/YYYY");
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);

  const limit = 50;
  const user = getUser();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [teamFilter, setTeamFilter] = useState([""]);
  const [dataSource, setDataSource] = useState([]);
  const [listTeam, setListTeam] = useState([]);
  const [branchName, setBranchName] = useState(user.branch_name);
  const [CategoryText, setCategoryText] = useState("")
  const [SubCategoryText, setSubCategoryText] = useState("")

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-partner`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        subCategory: subCategory,
        category: category,
        team: teamFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );
  const list = useApi(
    {
      url: `${URL_API}/system/master/team`,
      token: true,
    },
    []
  );
  useEffect(() => {
    let dataTeam = list.fetch?.data || [];
    dataTeam = dataTeam.filter(e=>e.name !== 'CloverThink')
    dataTeam.push({ code: "member", name: "VIP Member", seq: 0 });
    dataTeam.push({ code: "Staff", name: "Staff", seq: 0 });
    if (teamFilter.includes("")) {
      dataTeam = dataTeam.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      setListTeam(dataTeam);
    } else {
      dataTeam = _.filter(dataTeam || [], (n) => teamFilter.includes(n.name)).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      setListTeam(dataTeam);
    }
  }, [list.fetch?.data, teamFilter]);

 useEffect (() =>{
  dispatch({
    type: "setFilter",
    payload: { branch: user.branch_code },
  });
 },[user.branch_code])

  useEffect(() => {
    setDataSource(data ? data.data : []);
  }, [data]);

  const productTypeOption = [
    { value: "Single", text: "สินค้าเดี่ยว" },
    { value: "BOM", text: "สินค้าชุด" },
  ];

  const partnerGroupOption = [
    { value: "Clover", text: "Clover" },
    { value: "Dreams", text: "Dreams" },
    { value: "Ever", text: "Ever" },
    { value: "Givers", text: "Givers" },
    { value: "Life", text: "Life" },
    { value: "Mind", text: "Mind" },
    { value: "Real", text: "Real" },
    { value: "Together", text: "Together" },
    { value: "Victor", text: "Victor" },
    { value: "Zeeds", text: "Zeeds" },
  ];

 
  const exportToExcel = () => {
    let exportData = data?.data || [];
    let team = data?.teamData || []
    if(team.length > 0){
      team.push(
        {team_name: 'Total'})
    }
   
    let title_table = ["รหัสสินค้า", "ชื่อสินค้า", "Category", "Sub Category"];
    let subtitle_table = ["", "", "", ""];
    let subtitle_table_in_excel = ["", "", "", ""];

    //--------------------สร้าง column group ใน excel
    for (let i in team) {
      title_table.push(data?.teamData[i].team_name === 'Total'? 'รวม':data?.teamData[i].team_name);
      title_table.push("");
      
    }
    //--------------------สร้าง column sub group หา key
    for (let i in team) {
      subtitle_table.push(`${data?.teamData[i].team_name}Qty`);
      subtitle_table.push(`${data?.teamData[i].team_name}Amount`);
    }
    //--------------------สร้าง column sub group ใน excel
    for (let i in team) {
      subtitle_table_in_excel.push(`${data?.teamData[i].team_name === 'Total'? 'รวมยอดขายสินค้า':'ยอดขายสินค้า'}`);
      subtitle_table_in_excel.push(`${data?.teamData[i].team_name === 'Total'? 'รวมยอดเงิน':'ยอดเงิน'}`);
    }

    if (exportData.length > 0) {
      //   const color_cell = {
      //     border: {
      //       top: { style: "thin", color: { rgb: "000000" } },
      //       bottom: { style: "thin", color: { rgb: "000000" } },
      //       left: { style: "thin", color: { rgb: "000000" } },
      //       right: { style: "thin", color: { rgb: "000000" } },
      //     },
      //     fill: { fgColor: { rgb: "E9E9E9" } },
      //   };

      let excelData = [
        ["รายงานยอดขายตามกลุ่มนักธุรกิจ"],
        [
          `แสดงเฉพาะสินค้า : ${state.filter.query}`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `สาขา ${branchName === "" ? "ทั้งหมด" : branchName}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY เวลา HH:mm")}`,
        ],
        [
          `องค์กร : ${teamFilter.includes("") ? "ทั้งหมด": teamFilter}`,
          "",
          "",
          "",
          `Category : ${category === "" ? "ทั้งหมด" : CategoryText}`,
          "",
          `Sub Category : ${subCategory === "" ? "ทั้งหมด" : SubCategoryText}`,
        ],
        // [
        //   `Category : ${selectedCategory === "" ? "ทั้งหมด" : CategoryText}`,
        //   "",
        //   `Sub Category : ${
        //     selectedSubCategory === "" ? "ทั้งหมด" : SubCategoryText
        //   }`,
        //   "",
        //   `Group : ${selectedGroup === "" ? "ทั้งหมด" : GroupText}`,
        // ],
        [""],
        title_table.map((i) => {
          return {
            v: i,
            s: {
              alignment: { horizontal: "center" },
              border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
              },
              fill: { fgColor: { rgb: "E9E9E9" } },
            },
          };
        }),
        subtitle_table_in_excel.map((i) => {
          return {
            v: i,
            s: {
              alignment: { horizontal: "right" },
              border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
              },
              fill: { fgColor: { rgb: "E9E9E9" } },
            },
          };
        }),
      ];

      exportData.forEach((n) => {
        //---------------------หา key ของ values
        let arr_title = subtitle_table.filter((item) => item !== "");

        //---------------------ดึง values ออกมา ( เรียงตาม key )
        // let values_branch = arr_title.map(
        //   (branch) => _.values(_.pickBy(n, (value, key) => branch === key))[0]
        // );
        // console.log("Values :", values_branch);

        //-------------------------------จัด format number -------------
        let values_branch = arr_title.map((branch) => {
          let obj_branch = _.pickBy(n, (value, key) => branch === key);
          let a = _.map(obj_branch, (item, key) => {
            //ถ้า column ที่มีคำว่า _qty ไม่ต้องจัด format
            return key.indexOf("Qty") !== -1
              ? {
                  v: item,
                  t: "n",
                  s: {
                    numFmt: "#,##0",
                    alignment: { horizontal: "right" },
                  },
                }
              : {
                  v: item,
                  t: "n",
                  s: {
                    numFmt: "#,##0.00",
                    alignment: { horizontal: "right" },
                  },
                };
          });
          return a[0];
        });
        //------------------------------- -------------

        //--------------------ชุดข้อมูลที่แสดงใน excel
        let data_table = [
          n.ProductCode === "รวมทั้งหมด" ? "" : n.ProductCode,
          n.ProductName,
          n.Category,
          n.SubCategory,

        ];
        //--------------------push data เข้าใน column ใน excel
        values_branch.map((i) => data_table.push(i));

        excelData = [...excelData, data_table];
      });

        const merge = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
          { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
          { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
          { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },

          { s: { r: 2, c: 0 }, e: { r: 2, c: 3 } },
          { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
          { s: { r: 2, c: 6 }, e: { r: 2, c: 7 } },
          // { s: { r: 3, c: 2 }, e: { r: 3, c: 3 } },
          // { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } },

          { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
          { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
          { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
          { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
          // { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },
          // { s: { r: 4, c: 5 }, e: { r: 5, c: 5 } },
        ];

      //   //-------------------------merge column branch ------
        let c1 = 4;
        let c2 = 5;
        let loop_count = (subtitle_table.length - 4) / 2;
        _.map(subtitle_table, (_, i) => {
          if (i > 2) {
            if (loop_count > 0) {
              merge.push({ s: { r: 4, c: c1 }, e: { r: 4, c: c2 } });
              c1 = c1 + 2;
              c2 = c2 + 2;
              loop_count = loop_count - 1;
            }
          }
        });

        const wscols = [
          { wch: 20 },
          { wch: 35 },
          { wch: 20 },
          { wch: 25 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ]

      //   //------------------------width size of column-----
      //   var wscols = _.map(subtitle_table, (_, i) => {
      //     return i === 3 ? { wch: 35 } : { wch: 18 };
      //   });

      //   const centerStyles = {
      //     alignment: {
      //       vertical: "center",
      //       horizontal: "center",
      //     },
      //     border: {
      //       top: { style: "thin", color: { rgb: "000000" } },
      //       bottom: { style: "thin", color: { rgb: "000000" } },
      //       left: { style: "thin", color: { rgb: "000000" } },
      //       right: { style: "thin", color: { rgb: "000000" } },
      //     },
      //     fill: { fgColor: { rgb: "E9E9E9" } },
      //   };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      //   ws["A5"].s = centerStyles;
      //   ws["B5"].s = centerStyles;
      //   ws["C5"].s = centerStyles;
      //   ws["D5"].s = centerStyles;
      //   ws["E5"].s = centerStyles;
      //   ws["F5"].s = centerStyles;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "รายงานยอดขายตามกลุ่มนักธุรกิจ");
        XLSX.writeFile(wb, "รายงานยอดขายตามกลุ่มนักธุรกิจ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานยอดขายตามกลุ่มนักธุรกิจ</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={6}>
              <Search
                className="w-100 "
                placeholder="รหัสสินค้า, ชื่อสินค้า"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                onSearch={reload}
                onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={1}>
              <Text
                className="text-center d-block mr-3"
                strong
                style={{ lineHeight: "32px", float: "right" }}
              >
                วันที่
              </Text>
            </Col>
            <Col span={4}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col span={1}>
              <Text
                className="text-center d-block mr-3"
                strong
                style={{ lineHeight: "32px", float: "right" }}
              >
                สาขา
              </Text>
            </Col>
            <Col span={3}>
              <SelectBranch
                withAll={user.branch_count > 1}
                userLogin={true}
                allowDisableBranch
                defaultValue={user.branch_code}
                className="w-100"
                onChange={(e, v) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                  setBranchName(v.props.children);
                }}
              />
            </Col>
            <Col span={8}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col className="mr-2">
              <Text strong>องค์กร</Text>
            </Col>
            <Col span={6}>
              <SelectAllType
                withAll
                process_type="team"
                valueIndex="name"
                className="w-100"
                mode="multiple"
                value={teamFilter}
                addMember={true}
                addStaff={true}
                delCloverThink={true}
                onChange={(e, v) => {
                  setTeamFilter(e);
                }}
              />
            </Col>
            <Col className="mr-2 ml-5">
              <Text strong>Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                withAll
                category="category"
                className="w-100"
                defaultValue={category}
                onChange={(e,v) => {
                  setCategory(e);
                  setCategoryText(v.props.children)
                  setSubCategory("");
                  setSubCategoryText("ทั้งหมด")
                }}
              />
            </Col>

            <Col className="mr-2 ml-5">
              <Text strong>Sub Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                withAll
                category={`subcategory?CategoryId=${category}`}
                className="w-100"
                defaultValue={subCategory}
                value={subCategory}
                onChange={(e,v) => {
                  setSubCategory(e);
                  setSubCategoryText(v.props.children)
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource}
            rowKey="RowId"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data.data.length : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="ProductCode"
              align="center"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="ProductName"
              align="left"
            />
            <Table.Column title="Category" dataIndex="Category" align="left" />
            <Table.Column
              title="SubCategory"
              dataIndex="SubCategory"
              align="left"
            />
            {_.map(listTeam, (team, i) => {
              return (
                <Table.ColumnGroup title={team.name} key={i}>
                  <Table.Column
                    key={`${team.name}Qty`}
                    title="ยอดขายสินค้า"
                    dataIndex={`${team.name}Qty`}
                    align="right"
                    render={(text) => {
                      if (text === null) {
                        return "";
                      }
                      return numeral(text).format("0,0");
                    }}
                  />
                  <Table.Column
                    key={`${team.name}Amount`}
                    title="ยอดเงิน"
                    dataIndex={`${team.name}Amount`}
                    align="right"
                    render={(text) => {
                      if (text === null) {
                        return "";
                      }
                      return numeral(text).format("0,0.00");
                    }}
                  />
                </Table.ColumnGroup>
              );
            })}

            <Table.Column
              title="รวมยอดขายสินค้า"
              dataIndex="TotalQty"
              align="right"
              render={(text) => {
                if (text === null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="รวมยอดเงิน"
              dataIndex="TotalAmount"
              align="right"
              render={(text) => {
                if (text === null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
