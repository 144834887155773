/* eslint-disable no-unused-vars */
import { Button, Col, Input, Modal, Row, DatePicker, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import {
  actionBlackList,
  modalSelector,
  searchSelector,
} from "../../../../redux/slices/blacklist";
import ModalBlackList from "./ModalBlackList";
import { URL_API, _, axios, getToken, moment } from "../../../../utils";
export default function BlackListFilter({ dataSource }) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const { SET_OPEN_MODAL, SET_SEARCH } = actionBlackList;
  const modal = useSelector(modalSelector);
  const search = useSelector(searchSelector);
  const onClickExport = () => {
    Modal.confirm({
      title: "ยืนยันทำรายการ",
      content: "Export Excel",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        handleExportExcel();
      },
    });
  };
  const handleExportExcel = async () => {
    const result = await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/blacklist`,
      params: {
        query:search.query,
        date_from : search.date_from ?  moment(search.date_from).format("YYYY-MM-DD") : null,
        date_to : search.date_to ? moment(search.date_to).format("YYYY-MM-DD") : null,
      },
      headers: { Authorization: "Bearer " + getToken() },
    });
    let exportData = result.data.data
    if (exportData.length !== 0) {
    let excelData = [
      ["ข้อมูลBlackList"],
      ["แสดงเฉพาะข้อมูล : ",search?.query,
       "วันที่ดึงข้อมูล",moment().format("DD/MM/YYYY HH:mm")],
      ["วันที่เริ่มต้น : ",search.date_from? moment(search.date_from).format("DD/MM/YYYY"):"",
       "วันที่สิ้นสุด : ", search.date_to? moment(search.date_to).format("DD/MM/YYYY"):"",],
      [""],
      [
        "ลำดับ",
        "ชื่อ-นามสกุล",
        "ประเภท",
        "รหัสบัตรประชาชน / passport",
        "วันที่ดำเนินการ",
        "วันที่สมัครใหม่ได้",
        "ระยะเวลา",
      ],
    ];
    exportData.forEach((n,key) => {
      excelData = [
        ...excelData,
        [
          key+1,
          n.Fullname || "",
          n.MemberType == null
          ? ""
          : n.MemberType === "staff"
          ? "พนักงาน"
          : "สมาชิกเลกาซี",
          n.IDCard || "",
          moment(n.StartDate).format("DD/MM/YYYY") || "",
          moment(n.EndDate).format("DD/MM/YYYY") || "",
          moment(n.EndDate).startOf('day').diff(moment(n.StartDate).startOf('day'), "days") + "วัน",
        ],
      ];
    });
    var wscols = [
      { wch: 20 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet(excelData);
    ws["!cols"] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, "BlackList");
    XLSX.writeFile(
      wb,
      `BlackList-${moment().format("YYYY-MM-DD_HH-mm-ss")}.xlsx`
    );
    }else{
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  useEffect(() => {
    setQuery(search.query);
  }, [search.query]);
  return (
    <>
      <Row gutter={16} className="mb-3">
        <Col md={12}>
          <Input.Search
            allowClear
            placeholder="ชื่อ-นามสกุล บัตรประชาชน PassPort"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onSearch={(e) => {
              dispatch(
                SET_SEARCH({
                  query: e,
                  page: 1,
                })
              );
            }}
          />
        </Col>
        <Col md={6}>
          <DatePicker.RangePicker
            className="w-100"
            // value={[sele?.date_from, criteria?.date_to]}
            placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
            format="DD/MM/YYYY"
            onChange={(e) => {
              dispatch(
                SET_SEARCH({
                  date_from: e[0] || null,
                  date_to: e[1] || null,
                  page: 1,
                })
              );
            }}
          />
        </Col>
        <Col md={3}>
          <Button
            type="primary"
            className="w-100"
            onClick={(e) => dispatch(SET_OPEN_MODAL())}
          >
            + เพิ่มรายชื่อ
          </Button>
        </Col>
        <Col md={3}>
          <Button type="primary" className="w-100" onClick={onClickExport}>
          Export Excel
          </Button>
        </Col>
      </Row>
      <ModalBlackList visible={modal.visible} />
    </>
  );
}
