import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const bannerFormDefaultValues = {
  id: '',
  formType: 'create',
  type: 'home',
  callBackUrl: '',
  callBackUrlType: 'class',
  start_date: moment(new Date()),
  end_date: moment(new Date()).add('d', 3),
};

export const bannerFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      imageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      // callBackUrl: yup
      //   .string()
      //   .required('กรุณาใส่ลิงค์ หรือ blog id หรือ class id'),
      // callBackUrlType: yup.string().required('กรุณาเลือกประเภท'),
      start_date: yup.string().required('กรุณาใส่เลือกวันและเวลาเริ่ม'),
      end_date: yup.string().required('กรุณาใส่เลือกวันและเวลาจบ'),
    })
  );
};
