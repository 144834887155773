import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const EventCalendarStatus = ['display', 'hidden'];

export const eventCalendarFormDefaultValues = {
  formType: 'create',
  id: '',
  status: 'display',
  startDate: moment(new Date()),
  endDate: moment(new Date()).add('h', 3),
  title: '',
  description: '',
  thumbnailImageUrl: '',
  googleMapUrl: '',
  linkUrl: '',
};

export const eventCalendarFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      status: yup.mixed().oneOf(EventCalendarStatus).required(),
      startDate: yup.string().required(),
      endDate: yup.string().required(),
      title: yup.string().required(),
      description: yup.string().required(),
      thumbnailImageUrl: yup.string().required(),
      googleMapUrl: yup.string().nullable(),
      linkUrl: yup.string().nullable(),
    })
  );
};

export const eventCalendarConstants = {
  tableSize: 20,
  thumbnailImage: {
    width: 600,
    height: 600,
  },
};
