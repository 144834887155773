import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";

export const UserContext = createContext();

export const initState = {
  loading: false,
  saving: false,
  form: {
    id: null,
    code: "",
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    position: "",
    department: "",
    status: "",
    roles: [],
    branch: [],
    product_type: [],
    department_id: "",
    division_id: "",
  },
  filter: {
    status: "active",
    query: "",
    department: "",
    position: "",
    page: 1,
    limit: 20,
    department_id: "",
    division_id: "",
  },
};

export const reducer = createReducer(initState, {
  CLEAR: (state) => {
    state = initState;
  },
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_FORM_VALUE: (state, { payload }) => {
    state.form = { ...state.form, ...payload };
  },
  SET_FILTER_VALUE: (state, { payload }) => {
    state.filter = { ...state.filter, ...payload };
  },
  SET_DEFAULT_BRANCH: (state, { payload }) => {
    state.form.branch = state.form.branch.map((n) => ({
      ...n,
      is_default: n.branch_code === payload ? 1 : 0,
    }));
  },
});

export default UserContext;
