import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const playlistFormDefaultValues = {
  id: '',
  formType: 'create',
  title: '',
  thumbnailImageUrl: undefined,
  description: '',
  videoes: [
    {
      id: '',
      title: '',
      thumbnailImageUrl: '',
      videoUrl: '',
      description: '',
    },
  ],
};

export const playlistFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      coverImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่หัวข้อ'),
      description: yup.string(),
      videoes: yup.array().min(1, 'กรุณาเพิ่มวิดีโออย่างน้อย 1 อัน'),
    })
  );
};
