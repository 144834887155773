/* eslint-disable no-unused-vars */
import { Button, Modal, notification, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { fixBranch } from "../../../../../config/fix-value";
import { initClaimError } from "../../../../../redux/logic/claim";
import { actionClaim } from "../../../../../redux/slices/claim";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import { mapFileList } from "../../../../util/helper/file";

const { Text } = Typography;

const { setGlobalState, setState } = actionClaim;

const mapMember = (n) => {
  const p = _.keys(n)[0];
  return {
    [_.snakeCase(p + "Id")]: n[p].id,
    [_.snakeCase(p + "Type")]: n[p].type,
    [_.snakeCase(p + "Code")]: n[p].code,
    [_.snakeCase(p + "Name")]: n[p].name,
    [_.snakeCase(p + "Mobile")]: n[p].mobile,
    [_.snakeCase(p + "IdCard")]: n[p].id_card,
  };
};
const mapClaimItem = (n) => ({
  ...mapFileList(n.media),
  product_code: n.product_code,
  piece: n.piece,
  amount_type: n.amount_type,
  description: n.description,
  exp_date: n.exp_date,
  barcode: n.barcode,
  lot: n.lot,
  mfg_date: n.mfg_date,
  qr_running: n.qr_running,
  used_by_code: n.used_by_code,
  used_by_name: n.used_by_name,
  used_by_mobile: n.used_by_mobile,
  bom: [],
});

const UserSave = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const document_status = useSelector(
    (state) => state.claim.documentDetail.status
  );
  const isOnlineBranch = useSelector((state) =>
    _.includes(fixBranch.online, state.claim.documentDetail.user_branch_code)
  );
  const claimBy = useSelector((state) => state.claim.claimBy);
  const partner = useSelector((state) => state.claim.partner);
  const bill = useSelector((state) => state.claim.bill);
  const billProduct = useSelector((state) => state.claim.billProduct);
  const documentDetail = useSelector((state) => state.claim.documentDetail);
  const authority = useSelector((state) => state.claim.authority);

  if (!_.includes([null, "draft", "waiting"], document_status)) return null;

  const disableSave =
    billProduct.itemKeys.length === 0 ||
    _.every(
      billProduct.itemKeys,
      (product_key) =>
        billProduct.itemByKey[product_key].claim_item.itemKeys.length === 0
    );

  const handleSubmit = (e) => {
    let status, data_text, text_style;
    switch (e) {
      case "draft":
        data_text = "บันทึกฉบับร่าง";
        text_style = "text-rp";
        status = e;
        break;
      case "save":
        data_text = "บันทึกข้อมูล";
        text_style = "text-pv";
        status =
          _.includes([null, "draft"], documentDetail.status) && isOnlineBranch
            ? "waiting"
            : "wait-branch";
        break;
      default:
        return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(setState({ name: "loadingSubmit", value: true }));
        const {
          buyer_id,
          buyer_type,
          buyer_code,
          buyer_name,
          buyer_mobile,
          document_no: ref_document_no,
          document_date: ref_document_date,
        } = bill;
        const { itemKeys, itemByKey } = billProduct;
        const data = {
          ...documentDetail,
          ...mapMember({ claimBy }),
          ...mapMember({ partner }),
          document_status: documentDetail.status,
          authority_doc: authority.temp ? authority.id.toString() : null,
          authority_doc_del: authority.id_del?.toString(),
          status,
          buyer_id,
          buyer_type,
          buyer_code,
          buyer_name,
          buyer_mobile,
          ref_document_no,
          ref_document_date,
          products: _.reduce(
            itemKeys,
            (res, key) => {
              if (itemByKey[key].claim_item.itemKeys.length === 0) return res;
              const e = itemByKey[key];
              console.log("e:", e);
              let qty_balance =
                e?.bill_type === "draft" ? e?.balance : e?.qty_balance;
              let qty = 0;
              let claim_out_qty = 0;
              const is_bom = e.set_type.toLowerCase() === "bom";
              if (!is_bom) {
                qty = e.claim_item.itemKeys.length;
                claim_out_qty = e.claim_item.itemKeys.length;
              }
              const claim_item = _.map(e.claim_item.itemKeys, (item_key) => {
                const {
                  bom: { itemKeys, itemByKey },
                  ...n
                } = e.claim_item.itemByKey[item_key];

                if (is_bom) {
                  let is_claim_all_balance = true;
                  let is_claim_all_origin = true;
                  for (let i = 0; i < itemKeys.length; i++) {
                    const { qty, qty_balance, claim_item } =
                      itemByKey[itemKeys[i]];
                    if (qty_balance !== claim_item.itemKeys.length)
                      is_claim_all_balance = false;
                    if (qty !== claim_item.itemKeys.length)
                      is_claim_all_origin = false;
                    if (!is_claim_all_balance && !is_claim_all_origin) break;
                  }
                  if (is_claim_all_balance) qty += 1;
                  if (is_claim_all_origin) claim_out_qty += 1;
                }
                return {
                  ...mapClaimItem(n),
                  bom: _.reduce(
                    itemKeys,
                    (res, key) => {
                      if (itemByKey[key].claim_item.itemKeys.length === 0)
                        return res;
                      const e = itemByKey[key];
                      return [
                        ...res,
                        {
                          ref_product_code: e.ref_product_code,
                          ref_piece: e.ref_piece,
                          product_code: e.product_code,
                          product_name: e.product_name,
                          qty: e.claim_item.itemKeys.length,
                          qty_balance: e.balance,
                          line_number: e.line_number,
                          claim_item: _.map(
                            e.claim_item.itemKeys,
                            (item_key) => ({
                              ...mapClaimItem(e.claim_item.itemByKey[item_key]),
                              ref_product_code: e.ref_product_code,
                              ref_piece: e.ref_piece,
                            })
                          ),
                        },
                      ];
                    },
                    []
                  ),
                };
              });
              return [
                ...res,
                {
                  product_id: e.product_id,
                  id: e.id || e?.ref_key,
                  product_code: e.product_code,
                  product_name: e.product_name,
                  product_type_set: e.product_type_set,
                  sku: e.sku,
                  set_type: e.set_type,
                  qty,
                  qty_balance: qty_balance,
                  claim_out_qty,
                  unit_price: e.unit_price,
                  point_01: e.pv,
                  point_02: e.rp,
                  point_03: e.cv,
                  point_04: e.tp,
                  point_06: e.ra,
                  claim_item,
                },
              ];
            },
            []
          ),
        };
        axios({
          method: "post",
          url: `${URL_API}/claim`,
          headers: { Authorization: "Bearer " + getToken() },
          data,
        })
          .then(() => {
            notification.success({ message: "บันทึกข้อมูลแล้ว" });
            history.push({
              pathname: `/${_.split(match.path, "/")[1]}/${
                match.params.process
              }`,
            });
          })
          .catch((e) => resCatchModal(e))
          .finally(() =>
            dispatch(setState({ name: "loadingSubmit", value: false }))
          );
      },
    });
  };

  const handleClickSave = () => {
    let ce = {
      ...initClaimError,
    };
    if (!claimBy.name) {
      ce.claim_by_name = "กรุณาระบุชื่อ-นามสกุล / ค้นหา : เลือกผู้ทำรายการเคลม";
    }
    if (claimBy.type === "Other" && !claimBy.name) {
      ce.claim_by_code = "กรุณาระบุหมายเลขบัตรประชาชน";
    }
    if (!claimBy.code && !claimBy.mobile) {
      ce.claim_by_mobile = "กรุณาระบุเบอร์โทรผู้ทำรายการเคลม";
    }
    if (!partner.code) {
      ce.partner_name = "กรุณาเลือกเจ้าของใบเสร็จ";
    }
    if (!documentDetail.claim_type) {
      ce.claim_type = "กรุณาเลือกประเภทการเคลม";
    }
    if (!documentDetail.branch_code) {
      ce.branch_code = "กรุณาเลือกสาขาที่รับเคลม";
    }
    if (document_status === "waiting" || !isOnlineBranch) {
      if (!documentDetail.remark) {
        ce.remark = "กรุณาระบุหมายเหตุการส่งเรื่องต่อ";
      }
      if (!documentDetail.branch_priority) {
        ce.branch_priority = "กรุณาเลือกลำดับความสำคัญ";
      }
    }
    if (
      ((!claimBy.code && !partner.code) || claimBy.code !== partner.code) &&
      !authority.id
    ) {
      ce.authority = "กรุณาอัปโหลดเอกสารใบมอบอำนาจ";
    }
    dispatch(setGlobalState({ name: "error", value: ce }));
    for (const key in ce) {
      const e = ce[key];
      if (e)
        return notification.warning({
          message: "ไม่สามารถดำเนินการได้",
          description: "กรุณาตรวจสอบรายการ",
        });
    }
    handleSubmit("save");
  };

  return (
    <>
      {document_status !== "waiting" && (
        <Button
          className="bg-rp border-rp text-white width-100 mr-3"
          onClick={() => {
            if (!documentDetail.branch_code)
              return dispatch(
                setGlobalState({
                  name: "error",
                  value: { branch_code: "กรุณาเลือกสาขาที่รับเคลม" },
                })
              );
            handleSubmit("draft");
          }}
        >
          ฉบับร่าง
        </Button>
      )}
      <Button
        type="primary"
        className="width-100"
        disabled={disableSave}
        onClick={handleClickSave}
      >
        บันทึก
      </Button>
    </>
  );
};

export default UserSave;
