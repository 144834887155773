/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, Row, Upload } from "antd";
import React, { useEffect, useReducer } from "react";
import { getUserObj } from "../../../../utils";
import { beforeUploadExcel } from "../../../util/helper";
import { LoadingFullScreen } from "../../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import DocumentTable from "./components/DocumentTable";
import ItemModal from "./components/ItemModal";
import ResultModal from "./components/ResultModal";
import SelectSheetModal from "./components/SelectSheetModal";
import ReceiveContext, { initState, reducer } from "./reducer";

const Index = ({ history }) => {
  const user = getUserObj();
  const [state, dispatch] = useReducer(reducer, initState);
  const {
    file,
    result: { loading },
  } = state;

  const onFileChange = (data) =>
    dispatch({
      type: "setGlobalState",
      payload: {
        name: "file",
        value: {
          data: data.file.originFileObj,
          loading: true,
        },
      },
    });

  useEffect(() => {
    if (user.department_id !== 1) {
      handleBack();
      Modal.error({
        title: "ไม่สามารถดำเนินการได้",
        content: "ผู้ใช้งานไม่มีสิทธิในการดำเนินการ",
        zIndex: 1080,
      });
    }
  }, []);

  const handleBack = () => history.push("/transfer-receive/transfer/wait-add");

  return (
    <ReceiveContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รับสินค้าจากสำนักงานใหญ่</PanelHeader>
        <PanelBody>
          <LoadingFullScreen loading={loading} text={`กำลังนำเข้าข้อมูล...`} />
          <Row gutter={[8, 8]} className="mb-3">
            <Col span={24} lg={12}>
              <Input.Search
                allowClear
                autoComplete="off"
                placeholder="เลขที่เอกสาร, ชื่อไฟล์, ผู้นำเข้า"
                onSearch={(query) =>
                  dispatch({
                    type: "setGlobalState",
                    payload: {
                      name: "searchDocument",
                      value: {
                        query,
                        page: 1,
                      },
                    },
                  })
                }
              />
            </Col>
            <Col span={24} lg={12} className="text-right">
              <Button
                ghost
                type="primary"
                className="mr-3 width-150"
                onClick={handleBack}
              >
                ย้อนกลับ
              </Button>
              <Upload
                name="receive-in-excel"
                showUploadList={false}
                beforeUpload={beforeUploadExcel}
                onChange={onFileChange}
                customRequest={() => {}}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              >
                <Button
                  loading={file.loading}
                  type="primary"
                  className="width-150"
                >
                  Import Excel
                </Button>
              </Upload>
            </Col>
          </Row>
          <DocumentTable />
          <SelectSheetModal />
          <ResultModal />
          <ItemModal />
        </PanelBody>
      </Panel>
    </ReceiveContext.Provider>
  );
};

export default Index;
