import React from "react";
import SelectOption from "../../../../components/SelectOption";
import { useApi } from "../../../../hooks/http2";
import { URL_API } from "../../../../utils";

const SelectTransport = (props) => {
  const list = useApi(
    {
      url: `${URL_API}/pos/master/branch`,
      token: true,
    },
    []
  );

  return (
    <SelectOption
      loading={list.loading}
      data={[
        { value: "transport", text: "จัดส่ง" },
        { value: "branch", text: "รับที่สาขา" }
        // ..._.map(list.fetch?.data || [], (n) => ({
        //   value: n.code,
        //   text: n.name,
        // })),
      ]}
      {...props}
    />
  );
};

export default SelectTransport;
