import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { bannerFormDefaultValues } from '../dto/banner';
import { useParams } from 'react-router';
import ClassReservationForm from '../form/ClassReservationForm';
import { useQueryClient } from '@tanstack/react-query';

const ClassReservationModal = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit, reset } = useFormContext();
  const queryClient = useQueryClient();

  const { execute: bookFitnessClassReservationByAdmin } = useAsync(
    fitStudioService.bookFitnessClassReservationByAdmin,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['get-class-reservation-by-class-schedule-id'],
          refetchType: 'all',
        });
        AlertSuccess('จองคลาสสำเร็จ');
        reset(bannerFormDefaultValues);
        handleClose();
      },
    }
  );

  //   const { execute: editBanner } = useAsync(fitStudioService.editBanner, {
  //     onSuccess: () => {
  //       AlertSuccess('แก้ไขแบนเนอร์สำเร็จ');
  //       reset(bannerFormDefaultValues);
  //       handleClose();
  //     },
  //   });

  const _HandleSubmit = handleSubmit((data) => {
    const reservationData = {
      classScheduleId: params.classScheduleId,
      name: data.name,
      memberNo: data.memberNo,
    };
    if (watch('formType') === 'create') {
      bookFitnessClassReservationByAdmin(reservationData);
    }
    // else if (watch('formType') === 'edit') {
    //   editBanner({ id: data.id, data: bannerData });
    // }
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'สร้างแบนเนอร์' : 'แก้ไขแบนเนอร์'}
      visible={open}
      onCancel={handleClose}
      okText="จองคลาส"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <ClassReservationForm />
    </Modal>
  );
};

export default ClassReservationModal;
