import React, { useState } from "react";
import { Modal, Input, Button, message } from "antd";
import { axios } from "../../../../../utils/useAxios";
import { useStateValue } from "../StateContext";
import Search from "antd/lib/input/Search";

const ModalEvent = ({ visible, onCancel }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { state, dispatch } = useStateValue();

  const handleSave = async () => {
    if (!name.trim()) {
      message.error("กรุณากรอกชื่อ Event");
      return;
    }

    dispatch({ type: "SET_LOADING", payload: true });

    try {
      const response = await axios.post("/legacy-verse/import/event", {
        name,
        description,
      });

      if (response.status === 200) {
        message.success("✅ บันทึก Event สำเร็จ!");

        const res = await axios.get("/legacy-verse/import/import-coin", {
          params: { search: "" },
        });

        dispatch({
          type: "SET_EVENT_NAME",
          payload: res?.data?.event_name_list || [],
        });

        setName("");
        setDescription("");

        onCancel();
      } else {
        message.error("การบันทึก Event ล้มเหลว");
      }
    } catch (error) {
      message.error("เกิดข้อผิดพลาดในการส่งข้อมูล");
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  return (
    <Modal
      title="เพิ่ม Event"
      visible={visible}
      onCancel={!state.loading ? onCancel : undefined} 
      maskClosable={false} 
      closable={!state.loading} 
      footer={[
        <Button key="cancel" onClick={onCancel} disabled={state.loading}>
          ยกเลิก
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSave}
          loading={state.loading}
          disabled={state.loading}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Input
        placeholder="ชื่อ Event"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="mb-2"
        disabled={state.loading} 
      />
      <Input.TextArea
        placeholder="รายละเอียด Event"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={4}
        disabled={state.loading} 
      />
    </Modal>
  );
};

export default ModalEvent;
