/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon, Input, Popconfirm } from "antd";
import numeral from "numeral";
import React from "react";
import { FaCcMastercard, FaCcVisa, FaCoins } from "react-icons/fa";
import { connect } from "react-redux";
import { _ } from "../../../../utils";
import { Creators } from "../ducks/operation-form";
import { getSummaryTotalPayment } from "./logic";

const PaymentDetail = (props) => {
  const renderItem = () => {
    let arr = [];
    props.dataSource.forEach((n, i) => {
      arr.push(
        <tr key={i}>
          <td className="px-2 text-center align-items-center">
            {n.pay_in_type !== "Voucher" ? (
              <Popconfirm
                placement="top"
                title={`ต้องการลบรายการนี้หรือไม่`}
                onConfirm={() => props.delPayment(n.key)}
                okText="ลบ"
                cancelText="ไม่ลบ"
              >
                <a>
                  <Icon type="delete" style={{ fontSize: "20px" }} />
                </a>
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="top"
                title={`ต้องการลบรายการนี้หรือไม่`}
                onConfirm={() => {
                  if (n.id) {
                    props.delVoucher(n.id, props.activeKey);
                    props.clearPaymentIgnoreActiveKey();
                  } else {
                    props.delOrderPromotionItem({
                      promotion_id: n.promotion_id,
                      source_option: n.source_option,
                    });
                  }
                }}
                okText="ลบ"
                cancelText="ไม่ลบ"
              >
                <a>
                  <Icon type="delete" style={{ fontSize: "20px" }} />
                </a>
              </Popconfirm>
            )}
          </td>
          <td className="px-2">
            <span>
              {n.pay_in_type === "CM" ? (
                <FaCcMastercard size="1.2em" className="mr-2" />
              ) : n.pay_in_type === "CV" ? (
                <FaCcVisa size="1.2em" className="mr-2" />
              ) : (
                <FaCoins size="1.2em" className="mr-2" />
              )}
              {n.pay_in_type_name}
            </span>
          </td>
          <td className="px-2">
            <span>{n.bank_name}</span>
          </td>
          <td className="px-2">
            {!_.includes(["Cash", "Voucher"], n.pay_in_type) ? (
              <Input
                className="ml-auto"
                style={{ width: "50px" }}
                value={n.ref_code}
                onChange={(e) =>
                  props.setPaymentValue(n.key, { ref_code: e.target.value })
                }
              />
            ) : null}
          </td>
          <td className="px-2 text-right align-items-center">
            {numeral(
              n.pay_in_type === "Cash" &&
                props.payment_total > props.balance_total
                ? props.cash_total + props.balance_total - props.payment_total
                : n.amount
            ).format("0,0.00")}
          </td>
        </tr>
      );
    });

    return arr;
  };

  return props.dataSource.length > 0 ? (
    <div className="row text-white mb-4">
      <div className="col">
        <div className="bg-gray-60 py-3 px-3">
          <table>
            <thead>
              <tr>
                <th className="px-2 text-center text-nowrap">Delete</th>
                <th className="px-2 text-center">Type</th>
                <th className="px-2 text-center">Bank</th>
                <th className="px-2 text-center"></th>
                <th className="px-2 text-center text-nowrap">Amount</th>
              </tr>
            </thead>
            <tbody>{renderItem()}</tbody>
          </table>
        </div>
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  const { payment, orders } = state.pos.operation;
  const activeKey = payment.activeKey || orders.itemKeys[0];
  const {
    summary_total,
    payment_total,
    cash_total,
    voucher_total,
    voucher_payment,
  } = getSummaryTotalPayment(orders, payment);

  return {
    activeKey,
    dataSource: [
      ..._.filter(
        voucher_payment,
        (item) => item.pay_in_type?.toLowerCase() === "voucher"
      ),
      ..._.map(payment.itemKeys, (key) => ({
        ...payment.itemByKey[key],
        key,
      })),
    ],
    payment_total,
    cash_total,
    balance_total: summary_total - voucher_total,
  };
};

export default connect(mapStateToProps, {
  delPayment: Creators.delPayment,
  setPaymentValue: Creators.setPaymentValue,
  delVoucher: Creators.delVoucher,
  clearPaymentIgnoreActiveKey: Creators.clearPaymentIgnoreActiveKey,
  delOrderPromotionItem: Creators.delOrderPromotionItem,
})(PaymentDetail);
