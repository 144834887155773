const fitnessBranchConstants = {
  fitnessBranchImage: {
    width: 600,
    height: 600,
    ratio: '1:1',
  },
  fitnessTrainerImage: {
    width: 600,
    height: 600,
    ratio: '1:1',
  },
};

export default fitnessBranchConstants;
