import React from "react";
import { Switch, Route } from "react-router-dom";
import Error404 from "../../Error404";

import Banner from './Banner/BannerList'
import BannerContent from "./Banner/BannerContent";
import Intro from "./Intro/Intro";
import VTR from "./VTR/VTR";
import DealerSuccess from "./dealer-success/DealerSuccess";
import Benefits from "./benefits/Benefits";

import FooterInfoLeft from "./footer-info-left/FooterInfoLeft";
import FooterInfoRight from "./footer-info-right/FooterInfoRight";

import OurProducts from "./our-products/OurProducts";
import BestSeller from "./best-seller/BestSeller";
import BestSellerList from "./best-seller/BestSellerList";

import HomeEcosys from "./home-ecosys/routes";
import BenefitsList from "./benefits/BenefitsList";



export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/banner`} exact component={Banner} />
      <Route path={`${match.path}/banner/:id`} exact component={BannerContent} />
      
      <Route path={`${match.path}/intro`} component={Intro} />
      <Route path={`${match.path}/vtr`} component={VTR} />
      <Route path={`${match.path}/dealer-success`} component={DealerSuccess} />
      <Route path={`${match.path}/benefits`} exact component={BenefitsList} />
      <Route path={`${match.path}/benefits/:id`} component={Benefits} />
      <Route path={`${match.path}/footer-info-left`} component={FooterInfoLeft} />
      <Route path={`${match.path}/footer-info-right`} component={FooterInfoRight} />

      <Route path={`${match.path}/our-products`} component={OurProducts} />
      <Route path={`${match.path}/best-seller`} exact component={BestSellerList} />
      <Route path={`${match.path}/best-seller/:id`} component={BestSeller} />


      <Route path={`${match.path}/ecosystem`} component={HomeEcosys} />
      <Route component={Error404} />
    </Switch>
  );
};
