import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: {
    query: "",
    page: 1,
    status: "active",
  },
};

const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    setSearch: (state, { payload }) => {
      state.search = {
        ...state.search,
        ...payload,
      };
    },
    clear: (state) => {
      state.search = initialState.search;
    },
  },
});

// selector
export const searchSelector = (state) => state.voucher.search;

// action
export const actionVoucher = voucherSlice.actions;

// reducer
export default voucherSlice.reducer;
