import { getToken, axios, URL_API } from "../../utils";
import { resCatch } from "../../views/util/helper";
import { initFetch } from "../logic/initial-value";
import { Types } from "../types/menu-type";

export const actionMenu = {
  setPermission: (payload) => ({ type: Types.SET_PERMISSION, payload }),
  loadMenu: () => async (dispatch) => {
    dispatch({
      type: Types.SET_FETCH,
      payload: {
        ...initFetch,
        loading: true,
      },
    });
    try {
      const res = await axios({
        url: `${URL_API}/system/menus`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      dispatch({
        type: Types.LOAD_MENU,
        payload: res.data.data,
      });
    } catch (e) {
      resCatch(e);
      dispatch({
        type: Types.SET_FETCH,
        payload: {
          loading: false,
          error: e.response?.data?.message || e.message || "ตรวจพบสิ่งผิดพลาด",
        },
      });
    }
  },
};
