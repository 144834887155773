import React, { useEffect, useReducer, useState } from "react";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Table,
  TimePicker,
  Typography,
} from "antd";
import moment from "moment";
import { getUser, URL_API } from "../../../../../utils";
import { useHttp } from "../../../../../hooks/http";
import numeral from "numeral";
import * as XLSX from "xlsx-js-style";

const Index = () => {
  const { Text } = Typography;
  const toDay = moment().format("DD/MM/YYYY");
  const user = getUser();
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: user ? [user.branch_code] : [""],
      query: "",
      page: 1,
      limit: 10,
      category: "",
      subCategory: "",
      group: "",
    },
  };

  const [page, setPage] = useState(1);
  const limit = 50;

  const [state, dispatch] = useReducer(reducer, initState);
  const [timeFrom, setTimeFrom] = useState(moment("00:00", "HH:mm"));
  const [timeTo, setTimeTo] = useState(moment("00:00", "HH:mm"));

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/transfer-low-cost`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        //projectType: checkedProjectList,
        //personType: checkedPersonType,
        ...state.filter,
        start_date_time: moment(
          `${moment(state.filter.date_from)
            .format("YYYY-MM-DD")
            .toString()} ${moment(timeFrom ? timeFrom : "00:00")
            .format("HH:mm")
            .toString()}`
        ).format("YYYY-MM-DD HH:mm"),
        end_date_time: state.filter.date_to
          ? moment(
              `${moment(state.filter.date_to)
                .format("YYYY-MM-DD")
                .toString()} ${moment(timeTo ? timeTo : "00:00")
                .format("HH:mm")
                .toString()}`
            ).format("YYYY-MM-DD HH:mm")
          : null,
      },
      initialLoad: false,
    },
    []
  );
  useEffect(() => {
    if (timeFrom == null) {
      setTimeFrom(moment("00:00", "HH:mm"));
    }
    if (timeTo == null) {
      setTimeTo(moment("00:00", "HH:mm"));
    }
  }, [timeFrom, timeTo]);

  const exportToExcel = () => {
    const datasource = data ? data?.data : [];
    if (datasource.length === 0) {
      return message.warning("ไม่พบข้อมูล");
    } else {
      const title_table = [
        "ลำดับ",
        "วันที่สั่งซื้อ",
        "รหัสผู้สั่งซื้อ",
        "ชื่อผู้สั่งซื้อ",
        "ค่าจัดส่งที่รับชำระ",
        "คะแนน PV",
        "หมายเลขคำสั่งซื้ออีคอมเมิร์ซ",
        "น้ำหนักพัสดุ(กิโลกรัม)",
        "ชื่อสกุลผู้รับ",
        "เบอร์โทรศัพท์มือถือผู้รับ",
        "เบอร์สำนักงาน",
        "จังหวัดผู้รับ",
        "เขตอำเภอผู้รับ",
        "ตำบลปลายทาง",
        "รหัสไปรษณีย์ปลายทาง",
        "ที่อยู่ผู้รับ",
        "รายละเอียดพัสดุ",
        "มูลค่าพัสดุโดยประเมิน",
        "หมายเหตุ",
        "จำนวนเงินที่ชำระปลายทาง (COD)",
        "กว้าง(ซม.)",
        "ยาว(ซม.)",
        "สูง(ซม.)",
        "ค่าบรรจุภัณฑ์",
      ];
      let excel_data = [
        ["รายงานจัดส่ง Low Cost"],

        [
          `ค้นหา  ${state.filter.query}`,
          "",
          "",
          "",
          "",
          `ช่วงวันที่สั่งซื้อ  ${moment(state.filter.date_from).format(
            "YYYY-MM-DD"
          )}  เวลา ${moment(timeFrom ? timeFrom : "00:00").format(
            "HH:mm"
          )} น.  ถึง  ${moment(state.filter.date_to).format(
            "YYYY-MM-DD"
          )}  เวลา ${moment(timeTo ? timeTo : "00:00").format("HH:mm")} น.`,
          "",
          "",
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
          "",
          "",
        ],
        [],
        title_table,
      ];
      datasource.forEach((n) => {
        excel_data = [
          ...excel_data,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            {
              v: moment(n.document_date).format("DD/MM/YYYY HH:mm"),
              s: { alignment: { horizontal: "center" } },
            },
            { v: n.partner_code, s: { alignment: { horizontal: "center" } } },
            { v: n.partner_name, s: { alignment: { horizontal: "left" } } },
            {
              v: n.delivery_amount,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.pv,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.document_no === null ? "" : n.document_no,
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.weight,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            { v: n.ship_to, s: { alignment: { horizontal: "left" } } },
            { v: n.ship_mobile, s: { alignment: { horizontal: "left" } } },
            { v: n.office_mobile, s: { alignment: { horizontal: "left" } } },
            { v: n.ship_province, s: { alignment: { horizontal: "left" } } },
            { v: n.ship_district, s: { alignment: { horizontal: "left" } } },
            {
              v: n.ship_sub_district,
              s: { alignment: { horizontal: "left" } },
            },
            { v: n.ship_post_code, s: { alignment: { horizontal: "left" } } },
            { v: n.ship_address, s: { alignment: { horizontal: "left" } } },
            { v: n.package_detail, s: { alignment: { horizontal: "center" } } },
            { v: n.package_price, s: { alignment: { horizontal: "left" } } },
            { v: n.remark, s: { alignment: { horizontal: "left" } } },
            {
              v: n.package_price_destination,
              s: { alignment: { horizontal: "left" } },
            },
            { v: n.package_width, s: { alignment: { horizontal: "left" } } },
            { v: n.package_length, s: { alignment: { horizontal: "left" } } },
            { v: n.package_height, s: { alignment: { horizontal: "left" } } },
            { v: n.packaging_cost, s: { alignment: { horizontal: "left" } } },
          ],
        ];
      });
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 9 } },
        { s: { r: 1, c: 10 }, e: { r: 1, c: 12 } },
      ];
      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      const ceter_style = {
        alignment: { horizontal: "center", vertical: "center" },
        fill: {
          fgColor: { rgb: "e5e5e5" }, // สีพื้นหลังเป็นสีเหลือง (รหัสสี RGB)
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
      const ws = XLSX.utils.aoa_to_sheet(excel_data);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;
      ws["A4"].s = ceter_style;
      ws["B4"].s = ceter_style;
      ws["C4"].s = ceter_style;
      ws["D4"].s = ceter_style;
      ws["E4"].s = ceter_style;
      ws["F4"].s = ceter_style;
      ws["G4"].s = ceter_style;
      ws["H4"].s = ceter_style;
      ws["I4"].s = ceter_style;
      ws["J4"].s = ceter_style;
      ws["K4"].s = ceter_style;
      ws["L4"].s = ceter_style;
      ws["M4"].s = ceter_style;
      ws["N4"].s = ceter_style;
      ws["O4"].s = ceter_style;
      ws["P4"].s = ceter_style;
      ws["Q4"].s = ceter_style;
      ws["R4"].s = ceter_style;
      ws["S4"].s = ceter_style;
      ws["T4"].s = ceter_style;
      ws["U4"].s = ceter_style;
      ws["V4"].s = ceter_style;
      ws["W4"].s = ceter_style;
      ws["X4"].s = ceter_style;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(
        wb,
        `รายงานจัดส่ง_Low_Cost_${moment().format("YYMMDD_HHmm")}.xlsx`
      );
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานจัดส่ง Low Cost</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={10} className=" p-r-15">
              <Input
                placeholder="เลขที่บิล, รหัสผู้สั่งซื้อ, เบอร์โทรผู้รับ, ชื่อผู้สั่งซื้อ, ชื่อผู้รับ"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  });
                }}
              />
            </Col>

            <Col span={14}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} align="right">
              <Text strong>วันที่สั่งซื้อ :</Text>
            </Col>
            <Col span={4}>
              <DatePicker
                className="w-100"
                placeholder={toDay}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: moment(e).isValid()
                        ? moment(e).format("YYYYMMDD")
                        : moment().format("YYYYMMDD"),
                    },
                  })
                }
              />
            </Col>
            <Col span={1} align="right">
              <Text strong>เวลา :</Text>
            </Col>
            <Col span={2}>
              <TimePicker
                placeholder="00:00"
                format="HH:mm"
                //value={timeFrom}
                onChange={setTimeFrom}
              />
            </Col>
            <Col span={2} align="right">
              <Text strong>ถึงวันที่ :</Text>
            </Col>
            <Col span={4}>
              <DatePicker
                className="w-100"
                placeholder={toDay}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_to: moment(e).isValid()
                        ? moment(e).format("YYYYMMDD")
                        : moment().format("YYYYMMDD"),
                    },
                  })
                }
              />
            </Col>
            <Col span={1} align="right">
              <Text strong>เวลา :</Text>
            </Col>
            <Col span={2}>
              <TimePicker
                placeholder="00:00"
                format="HH:mm"
                //value={timeTo}
                onChange={setTimeTo}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={error ? [] : data?.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="row_id"
              width={50}
              align="center"
              fixed="left"
            />
            <Table.Column
              title="วันที่สั่งซื้อ"
              dataIndex="document_date"
              align="center"
              render={(text) => {
                return moment(text).format("DD/MM/YYYY HH:mm");
              }}
            />
            <Table.Column
              title="รหัสผู้สั่งซื้อ"
              dataIndex="partner_code"
              align="left"
            />
            <Table.Column
              title="ชื่อผู้สั่งซื้อ"
              dataIndex="partner_name"
              align="left"
            />
             <Table.Column
              title="ค่าจัดส่งที่รับชำระ"
              dataIndex="delivery_amount"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "-";
              }}
            />
            <Table.Column
              title="คะแนน PV"
              dataIndex="pv"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "-";
              }}
            />
            <Table.Column
              title="หมายเลขคำสั่งซื้ออีคอมเมิร์ซ"
              dataIndex="document_no"
              align="center"
            />
            <Table.Column
              title="น้ำหนักพัสดุ(กิโลกรัม)"
              dataIndex="weight"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "-";
              }}
            />
            <Table.Column title="ชื่อสกุลผู้รับ" dataIndex="ship_to" align="left" />
            <Table.Column
              title="เบอร์โทรศัพท์มือถือผู้รับ"
              dataIndex="ship_mobile"
              align="center"
            />
            <Table.Column
              title="เบอร์สำนักงาน"
              dataIndex="office_mobile"
              align="center"
            />
            <Table.Column
              title="จังหวัดผู้รับ"
              dataIndex="ship_province"
              align="left"
            />
            <Table.Column
              title="เขตอำเภอผู้รับ"
              dataIndex="ship_district"
              align="left"
            />
            <Table.Column
              title="ตำบลปลายทาง"
              dataIndex="ship_sub_district"
              align="left"
            />
            <Table.Column
              title="รหัสไปรษณีย์ปลายทาง"
              dataIndex="ship_post_code"
              align="left"
            />
            <Table.Column
              title="ที่อยู่ผู้รับ"
              dataIndex="ship_address"
              align="left"
            />
            <Table.Column
              title="รายละเอียดพัสดุ"
              dataIndex="package_detail"
              align="center"
            />
            <Table.Column
              title="มูลค่าพัสดุโดยประเมิน"
              dataIndex="package_price"
              align="center"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "";
              }}
            />
            <Table.Column title="หมายเหตุ" dataIndex="remark" align="center" />
            <Table.Column
              title="จำนวนเงินที่ชำระปลายทาง (COD)"
              dataIndex="package_price_destination"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "";
              }}
            />
            <Table.Column
              title="กว้าง(ซม.)"
              dataIndex="package_width"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "";
              }}
            />
            <Table.Column
              title="ยาว(ซม.)"
              dataIndex="package_length"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "";
              }}
            />
            <Table.Column
              title="สูง(ซม.)"
              dataIndex="package_height"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "";
              }}
            />
            <Table.Column
              title="ค่าบรรจุภัณฑ์"
              dataIndex="packaging_cost"
              align="right"
              render={(text) => {
                return text > 0 ? numeral(text).format("0,0.00") : "";
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
