import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Error404 from "../Error404";
import CommissionPage from "./commission";
import PageIndex from "./index";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/cash-sales`} />
      </Route>
      <Route
        exact
        path={`${match.path}/commission`}
        component={CommissionPage}
      />
      <Route exact path={`${match.path}/:process`} component={PageIndex} />
      <Route component={Error404} />
    </Switch>
  );
};
