import { createReducer } from "@reduxjs/toolkit";
import React, { createContext, useContext, useReducer } from "react";

const CommissionMutateContext = createContext();

const INIT_STATE = {
  loading: false,
  period: "",
  filter: {
    result: "success",
    page: 1,
    limit: 10,
  },
  progress: {
    loading: true,
    pending: 0,
    inprogress: 0,
    success: 0,
    error: 0,
  },
  preview: null,
};

const reducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase("SET_LOADING", (state, { payload }) => {
      state.loading = payload;
    })
    .addCase("SET_PERIOD", (state, { payload }) => {
      state.period = payload;
    })
    .addCase("SET_FILTER", (state, { payload }) => {
      state.filter = {
        ...state.filter,
        ...payload,
      };
    })
    .addCase("SHOW_PREVIEW", (state, { payload }) => {
      state.preview = { ...payload };
    })
    .addCase("HIDE_PREVIEW", (state) => {
      state.preview = null;
    })
    .addCase("LOAD_PROGRESS", (state) => {
      state.progress.loading = true;
    })
    .addCase("LOAD_PROGRESS_SUCCESS", (state, { payload }) => {
      state.loading = false;
      state.progress = {
        ...state.progress,
        ...payload,
        loading: false,
      };
    })
    .addCase("LOAD_PROGRESS_FAIL", (state) => {
      state.loading = false;
      state.progress.loading = false;
    })
    .addDefaultCase(() => {});
});

export const useCommissionMutate = () => {
  const context = useContext(CommissionMutateContext);
  if (!context)
    throw new Error(
      "Commission Mutate Context must be used within Commission Mutate Provider"
    );
  return context;
};

export const CommissionMutateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  return (
    <CommissionMutateContext.Provider value={{ state, dispatch }}>
      {children}
    </CommissionMutateContext.Provider>
  );
};
