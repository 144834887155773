/* eslint-disable no-unused-vars */
import { Col, notification, Row, Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import numeral from "numeral";
import React, { useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import FilterList from "../../components/FilterList";
import * as XLSX from "xlsx-js-style";
import SelectCategory from "../../components/SelectCategory";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [subCategory, setSubCategory] = useState("");
  const [page, setPage] = useState(1);

  const limit = 50;
  const user = getUser();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-movement-branch`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        subCategory: subCategory,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportDataData = data ? [data?.data] : [];

  const exportToExcel = () => {
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานความเคลื่อนไหวตามสาขา"],
        [
          `สาขา: ${
            state.filter.branch === "" ? "ทั้งหมด" : state.filter.branch
          }`,
          "",
          `Sub Category: ${subCategory === "" ? "ทั้งหมด" : subCategory}`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [],
        [
          "ลำดับที่",
          "รหัสสินค้า",
          "สินค้า",
          "ยอดยกมา",
          "ขารับ",
          "",
          "ขาออก",
          "",
          "",
          "จอง",
          "คงเหลือที่ควรเป็น",
          "คงเหลือระบบ",
          "ส่วนต่าง",
        ],
        [
          "",
          "",
          "",
          "",
          "สต๊อกตั่งต้น",
          "รับโอน",
          "ยอดขาย",
          "เบิก",
          "โอนออก",
          "",
          "",
          "",
          "",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.product_code,
            n.product_name_local,
            {
              v: numeral(n.bf).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v: numeral(n.default === null ? 0 : n.default).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v: n.receive,
              s: { alignment: { horizontal: "right" } },
            },

            {
              v: numeral(n.sell).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            n.requisition,
            n.transfer_qty,
            n.reserve,
            n.balance === null ? 0 : n.balance,
            n.balance_sys === null ? 0 : n.balance_sys,
            n.diff === null ? 0 : n.diff,
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },

        { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } },
        { s: { r: 3, c: 6 }, e: { r: 3, c: 8 } },

        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
        { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
        { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },

        { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },
        { s: { r: 3, c: 10 }, e: { r: 4, c: 10 } },
        { s: { r: 3, c: 11 }, e: { r: 4, c: 11 } },
        { s: { r: 3, c: 12 }, e: { r: 4, c: 12 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 35 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 18 },
        { wch: 15 },
        { wch: 15 },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;
      ws["I4"].s = centerStyles;
      ws["J4"].s = centerStyles;
      ws["K4"].s = centerStyles;
      ws["L4"].s = centerStyles;
      ws["M4"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานความเคลื่อนไหวตามสาขา");
      XLSX.writeFile(wb, "รายงานความเคลื่อนไหวตามสาขา.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานความเคลื่อนไหวตามสาขา</PanelHeader>
        <PanelBody>
          <FilterList
            className="p-b-20"
            exportToExcel={exportToExcel}
            placeholder="รหัสสินค้า"
            reload={reload}
            topicAlign={"center"}
            allowDisableBranch
            showSearchBox={false}
            showBranch={true}
            Topic="วันที่เปิดบิล"
            withAll={user.branch_count > 1}
          />
          <Row
            gutter={3}
            className="align-items-center p-b-15 align-items-sm-center"
            type="flex"
            align="middle"
          >
            <Col span={2} className="p-b-15">
              <Text strong>Sub Category</Text>
            </Col>

            <Col span={4} className="p-b-15 p-r-15">
              <SelectCategory
                className="w-100"
                defaultValue=""
                category="subcategory"
                withAll
                onChange={(e) => {
                  setSubCategory(e);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data ? data.data : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data.data.length : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับที่" dataIndex="row_id" align="center" />
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              align="center"
            />
            <Table.Column
              title="สินค้า"
              align="left"
              dataIndex="product_name_local"
            />
            <Table.Column
              title="ยอดยกมา"
              dataIndex="bf"
              align="center"
              render={(text) => {
                return numeral(text).format("0,0");
              }}
            />
            <Table.ColumnGroup title="ขารับ">
              <Table.Column title="สต๊อกตั่งต้น" dataIndex="" align="center" />
              <Table.Column
                title="รับโอน"
                dataIndex="receive"
                align="center"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
              />
            </Table.ColumnGroup>
            <Table.ColumnGroup title="ขาออก">
              <Table.Column
                title="ยอดขาย"
                dataIndex="sell"
                align="center"
                render={(text) => {
                  return numeral(text).format("0,0");
                }}
              />
              <Table.Column
                title="เบิก"
                dataIndex="requisition"
                align="center"
              />
              <Table.Column
                title="โอนออก"
                dataIndex="transfer_qty"
                align="center"
              />
            </Table.ColumnGroup>
            <Table.Column title="จอง" dataIndex="reserve" align="center" />
            <Table.Column
              title="คงเหลือที่ควรเป็น"
              dataIndex=""
              align="center"
            />
            <Table.Column title="คงเหลือระบบ" dataIndex="" align="center" />
            <Table.Column title="ส่วนต่าง" dataIndex="" align="center" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
