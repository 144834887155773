/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, Input, Row, Select, Typography } from "antd";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// const { setGlobalState } = actionRequisition;

const SearchDocument = () => {
  const { process: process_params } = useParams();
  const dispatch = useDispatch();
  const {
    query,
    start_date,
    end_date,
    requisition_type,
    warehouse_code,
    department_id,
  } = useSelector((state) => state.requisition.searchDocument);
  return (
    <>
      <Row gutter={[8, 8]} type="flex" className="align-items-center mb-3">
        <Col span={24} xl={10} xxl={10}>
          <Input.Search
            allowClear
            placeholder="เลขที่เอกสาร, รหัสพนักงาน, ชื่อพนักงาน"
            autoComplete="off"
            value={query}
            onChange={(e) => dispatch()}
          />
        </Col>
        <Col span={2} className="text-right">
          <Typography.Text strong className="text-nowrap ">
            วันที่ดำเนินการ
          </Typography.Text>
        </Col>
        <Col span={16} xl={9} xxl={9}>
          <DatePicker.RangePicker
            className="w-100"
            placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
            format="DD/MM/YYYY"
          />
        </Col>
        {process_params === "pending" && (
          <Col span={3} className="text-right">
            <Link to={`${process_params}/0`}>
              <Button type="primary">
                <FaPlus className="mr-2" />
                สร้างเอกสารใบลดหนี้
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} type="flex" className="align-items-center mb-3">
        <Col span={1} className="text-right">
          <Typography.Text strong className="text-nowrap ">
            สถานะ
          </Typography.Text>
        </Col>
        <Col span={4}>
          <Select className="w-100">
           
          </Select>
        </Col>
        <Col span={1} className="text-right">
          <Typography.Text strong className="text-nowrap ">
            สถานะ
          </Typography.Text>
        </Col>
        <Col span={4}>
          <Select className="w-100">
            
          </Select>
        </Col>
        <Col span={2} className="text-right">
          <Typography.Text strong className="text-nowrap ">
            สาขารับของคืน
          </Typography.Text>
        </Col>
        <Col span={8}>
          <Select className="w-100">
          
          </Select>
        </Col>
      </Row>
    </>
  );
};

export default SearchDocument;
