import React from 'react'
import styled from 'styled-components';

const WrapTable = styled.div`
table{
	td{
		white-space: nowrap;
	   }
	 tr{
		white-space: nowrap;
	   }
	  th{
		white-space: nowrap;
		min-width: 150px;
	   }
} 
`

const TablePoint = ({ data }) => {
	const { columns, rows } = data;
	return (
		<>
			<WrapTable>


				<div className="mb-3" style={{ borderRadius: 10, backgroundColor: '#f9f9f9', padding: 20 }} >
					<table className="table-responsive">
						<thead >
							<tr className="mb-1">
								{(columns.length !== 0) &&
									columns.map((item, i) => {
										const { title } = item;
										return (
											<th key={i} >{title}</th>
										)
									})
								}
							</tr>
						</thead>
						<tbody style={{ color: 'black' }}>
							{(rows.length !== 0) &&
								rows.map((item, i) => {
									const { c1, c2, c3, c4, c5, c6, c7, c8, } = item;
									return (
										<tr key={i}>

											<td style={{ padding: 10, }}>{c1}</td>
											<td>{c2}</td>
											<td>{c3}</td>
											<td>{c4}</td>
											<td>{c5}</td>
											<td>{c6}</td>
											<td>{c7}</td>
											<td>{c8}</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>
			</WrapTable>
		</>
	)
}

export default TablePoint;