/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Col,
  Empty,
  message,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import UploadFileList from "../../../../../components/UploadFileList";
import UploadFileButtonModal from "../../../../../components/UploadFileButtonModal";
import ArrangeContext from "../../reducer";
import { resCatchModal } from "../../../../util/helper";
import {
  axios,
  getToken,
  getUser,
  getUserObj,
  URL_API,
} from "../../../../../utils";

const AuthorityDocument = ({ hideAddAuthority }) => {
  const [permisDel, setPermisDel] = useState(false);
  const uploadRef = useRef(null);
  const {
    state: {
      authority,
      bill: { document_no, partner_remark },
    },
    dispatch,
  } = useContext(ArrangeContext);

  const handleSubmit = useCallback(
    (file) => {
      let source = axios.CancelToken.source();
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "คุณต้องการบันทึกรายการหรือไม่ ?",
        okText: "ตกลง",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/global/authority-doc`,
              data: {
                id: file.id,
                ref_code: document_no,
                ref_type: "Arrange",
                document_type: "32",
              },
              headers: { Authorization: "Bearer " + getToken() },
              cancelToken: source.token,
            })
              .then(() => {
                resolve();
                dispatch({ type: "setTagReload" });
                uploadRef.current.clear();
                notification.success({ message: "บันทึกเอกสารมอบอำนาจแล้ว" });
              })
              .catch((err) => reject(err));
          }).catch((e) => resCatchModal(e));
        },
        onCancel() {
          source.cancel("cancel");
        },
      });
    },
    [uploadRef.current, document_no]
  );

  const onDelete = (key) => {
    let deleteId = authority.itemByKey[key]?.id;
    axios({
      method: "post",
      url: `${URL_API}/file/del`,
      headers: { Authorization: "Bearer " + getToken() },
      data: { id: deleteId },
    })
      .catch((e) => resCatchModal(e))
      .finally(() => {
        notification.success({ message: "Delete success!" });
        dispatch({ type: "delAuthority", payload: key });
      });
  };

  useEffect(() => {
    let role = 68; // Operation Admin
    axios({
      method: "get",
      url: `${URL_API}/global/user/role/${role}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        let data = res.data?.data;
        let permis_del = data?.role_user;
        setPermisDel(permis_del);
      })
      .catch((e) => resCatchModal(e));
  }, []);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col
          span={24}
          className="bg-gray-60 px-3 d-flex align-items-center justify-content-between "
        >
          <Typography.Paragraph className="mb-0 text-white">
            เอกสารมอบอำนาจ
          </Typography.Paragraph>
          {!hideAddAuthority && (
            <UploadFileButtonModal
              ref={uploadRef}
              size="small"
              title="แนบรูป"
              camera
              handleSubmit={handleSubmit}
            />
          )}
        </Col>
      </Row>
      <Row className="pt-3">
        <Col span={4}>
          <Typography.Text strong>รายละเอียด :</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{partner_remark}</Typography.Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="pt-2 pb-3">
        <Col span={4}>
          <Typography.Text strong>เอกสาร :</Typography.Text>
        </Col>
        <Col span={20} className="text-center">
          {authority.itemKeys.length ? (
            // <UploadFileList disabled hideOnDisable files={authority} />
            <UploadFileList
              showUpload={false}
              files={authority}
              onDelete={onDelete}
              disabled={!permisDel} // permisDel is TRUE , disabled is FALSE
            />
          ) : (
            <Empty className="mt-3" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default memo(AuthorityDocument);
