import { Col, Row, Tooltip } from "antd";
import React, { useCallback, useContext, useState } from "react";
import {
  FaCheckCircle,
  FaFileAlt,
  FaIdCard,
  FaMoneyCheck,
} from "react-icons/fa";
import ImagePreviewModal from "../../../../../../../../components/ImagePreviewModal";
import InfoContext from "../reducer";

const initPreviewFile = {
  azure_url: null,
  file_type: null,
  is_approve: null,
};

const getIconClass = (e) =>
  `fa-2x  ${
    e ? "cursor-pointer text-pv-light" : "cursor-no-drop text-gray-50"
  }`;

const IconFile = () => {
  const {
    state: {
      data: { ref_type },
      file: { document, id_card, book_bank },
    },
  } = useContext(InfoContext);
  const [previewFile, setPreviewFile] = useState(initPreviewFile);

  const handleClosePreview = useCallback(
    () => setPreviewFile(initPreviewFile),
    []
  );

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24} className="d-flex justify-content-around">
          <div className="text-center">
            <Tooltip title="ใบสมัคร">
              <FaFileAlt
                onClick={() => setPreviewFile(document)}
                className={getIconClass(document.azure_url)}
                style={{ textShadow: "8px 8px #000" }}
              />
            </Tooltip>
            {document.is_approve && (
              <div style={{ marginTop: "-7px" }}>
                <FaCheckCircle color="green" />
              </div>
            )}
          </div>
          <div className="text-center">
            <Tooltip title="สำเนาบัตรประชาชน">
              <FaIdCard
                onClick={() => setPreviewFile(id_card)}
                className={getIconClass(id_card.azure_url)}
              />
            </Tooltip>
            {id_card.is_approve && (
              <div style={{ marginTop: "-7px" }}>
                <FaCheckCircle color="green" />
              </div>
            )}
          </div>
          {ref_type === "Distributor" && (
            <div className="text-center">
              <Tooltip title="สำเนาบัญชีธนาคาร">
                <FaMoneyCheck
                  onClick={() => setPreviewFile(book_bank)}
                  className={getIconClass(book_bank.azure_url)}
                />
              </Tooltip>
              {book_bank.is_approve && (
                <div style={{ marginTop: "-7px" }}>
                  <FaCheckCircle color="green" />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
      <ImagePreviewModal
        azureUrl={previewFile.azure_url}
        fileType={previewFile.file_type}
        onCancel={handleClosePreview}
      />
    </>
  );
};

export default IconFile;
