/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antdV4';
import React, { useEffect, useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { FormProvider, useForm } from 'react-hook-form';
import {
  rewardFormDefaultValues,
  rewardFormSchema,
} from '../components/dto/reward';
import RewardModal from '../components/modal/RewardModal';
import { Table } from 'antdV4';
import { AlertConfirm } from '../../../util/Alert';
import moment from 'moment';
import rewardConstants from '../constants/rewardConstants';
import useAsync from '../../../../utils/useAsync';
import fitStudioService from '../../../../services/fit-studio.service';
import { useHistory } from 'react-router-dom';

const RewardPage = () => {
  const history = useHistory();
  const [rewardFormOpen, setRewardFormOpen] = useState(false);
  const [page, setPage] = useState(0);

  const rewardForm = useForm({
    defaultValues: rewardFormDefaultValues,
    resolver: rewardFormSchema(),
  });

  const _HandleRewardFormOpen = (formType) => {
    rewardForm.setValue('formType', formType);
    setRewardFormOpen(true);
  };

  const _HandleRewardFormClose = () => {
    setRewardFormOpen(false);
  };

  const { execute: getAllReward, data: rewardLists } = useAsync(
    fitStudioService.getAllReward,
    {}
  );

  const { data: test, execute: deleteReward } = useAsync(
    fitStudioService.deleteReward,
    {}
  );

  useEffect(() => {
    if (!rewardFormOpen) {
      getAllReward({
        skip: page * rewardConstants.tableSize,
        take: rewardConstants.tableSize,
      });
    }
  }, [page, rewardFormOpen, test]);

  const columns = [
    {
      title: 'รูปภาพด้านหน้า',
      dataIndex: 'frontImageUrl',
      key: 'frontImageUrl',
      render: (_, record) => (
        <img
          key={record.frontImageUrl}
          src={record.frontImageUrl}
          alt={record.frontImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
      width: 50,
    },
    {
      title: 'รูปภาพด้านหลัง',
      dataIndex: 'backImageUrl',
      key: 'backImageUrl',
      render: (_, record) => (
        <img
          key={record.backImageUrl}
          src={record.backImageUrl}
          alt={record.backImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
      width: 50,
    },
    {
      title: 'รูปภาพด้านหน้า',
      dataIndex: 'greyFrontImageUrl',
      key: 'greyFrontImageUrl',
      render: (_, record) => (
        <img
          key={record.greyFrontImageUrl}
          src={record.greyFrontImageUrl}
          alt={record.greyFrontImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
      width: 50,
    },
    {
      title: 'รูปภาพด้านหลัง',
      dataIndex: 'greyBackImageUrl',
      key: 'greyBackImageUrl',
      render: (_, record) => (
        <img
          key={record.greyBackImageUrl}
          src={record.greyBackImageUrl}
          alt={record.greyBackImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
      width: 50,
    },
    {
      title: 'หัวข้อ',
      dataIndex: 'title',
      key: 'title',
      width: 200,
    },
    {
      title: 'คำบรรยาย',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: 'วันที่เริ่มต้น',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, record) => (
        <span>{moment(record.start_date).format('DD/MMM/YYYY')}</span>
      ),
      width: 100,
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, record) => (
        <span>{moment(record.end_date).format('DD/MMM/YYYY')}</span>
      ),
      width: 100,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              rewardForm.setValue('id', record.id);
              _HandleRewardFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบเหรียญรางวัล');
              if (confirm) deleteReward({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <Panel>
      <PanelHeader>เหรียญรางวัล</PanelHeader>
      <PanelBody>
        <div className="d-flex justify-content-end mb-2">
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              rewardForm.setValue('formType', 'create');
              _HandleRewardFormOpen();
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มเหรียญรางวัล
          </Button>
        </div>
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={
            rewardLists?.data && rewardLists?.data.length > 0
              ? rewardLists.data
              : []
          }
          onChange={({ current }) => {
            setPage(current - 1);
          }}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          pagination={{
            pageSize: rewardConstants.tableSize,
            total: rewardLists?.meta?.total || 0,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/fit-studio/reward/${record.id}`);
              },
            };
          }}
        />
      </PanelBody>
      <FormProvider {...rewardForm}>
        <RewardModal
          open={rewardFormOpen}
          handleClose={_HandleRewardFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default RewardPage;
