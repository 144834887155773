import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { classFormDefaultValues } from '../dto/class';
import { useParams } from 'react-router';
import BodyFocusSubTagForm from '../form/BodyFocusSubTagForm';

const BodyFocusSubTagModal = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createSubTag } = useAsync(fitStudioService.createSubTag, {
    onSuccess: () => {
      AlertSuccess('เพิ่ม Sub Tag สำเร็จ');
      reset(classFormDefaultValues);
      handleClose();
    },
  });

  const { execute: editSubTag } = useAsync(fitStudioService.editSubTag, {
    onSuccess: () => {
      AlertSuccess('แก้ไข Sub Tag สำเร็จ');
      reset(classFormDefaultValues);
      handleClose();
    },
  });

  const _HandleSubmit = handleSubmit((data) => {
    const subTagData = {
      title: data.title,
      thumbnailImageUrlM: data.thumbnailImageUrlM,
      thumbnailImageUrlF: data.thumbnailImageUrlF,
      description: data.description,
      tagId: params.tagId,
    };
    if (watch('formType') === 'create') {
      createSubTag(subTagData);
    } else if (watch('formType') === 'edit') {
      editSubTag({ id: data.id, data: subTagData });
    }
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'สร้าง Sub Tag' : 'แก้ไข Sub Tag'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <BodyFocusSubTagForm />
    </Modal>
  );
};

export default BodyFocusSubTagModal;
