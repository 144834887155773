import { Col, Row, Typography } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { moment } from "../../../../../utils";

const { Text } = Typography;

const getTitle = (type) => {
  switch (type) {
    case "cancel":
      return "ผู้ยกเลิก";
    case "branch":
      return "ผู้จัดการสาขา";
    case "manager":
      return "Manager";
    case "complete":
      return "ผู้รับทราบ";
    default:
      return "ผู้พิจารณา";
  }
};

const Detail = ({ type }) => {
  const { create_by, create_date, remark } = useSelector(
    (state) => state.claim.remark[type] || {}
  );

  if (!create_by) return null;

  return (
    <>
      <Row gutter={[8, 8]} type="flex">
        <Col
          span={24}
          md={{ span: 12, order: 1 }}
          xl={{ span: 8, order: 1 }}
          xxl={{ span: 6, order: 1 }}
        >
          <Text strong>{getTitle(type)} : </Text>
          <Text>{create_by}</Text>
        </Col>
        <Col
          span={24}
          md={{ span: 24, order: 3 }}
          xl={{ span: 10, order: 2 }}
          xxl={{ span: 14, order: 2 }}
        >
          <Text>หมายเหตุ : {remark}</Text>
        </Col>
        <Col
          span={24}
          md={{ span: 12, order: 2 }}
          xl={{ span: 6, order: 3 }}
          xxl={{ span: 4, order: 3 }}
        >
          <Text>
            วันที่ดำเนินการ : {moment(create_date).format("DD/MM/YYYY HH:mm")}
          </Text>
        </Col>
      </Row>
    </>
  );
};

export default memo(Detail);
