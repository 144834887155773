import { Col, Input, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../../redux/slices/claim";

const { setClaimItemValue } = actionClaim;

const InputDescription = ({ disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const description = useSelector(
    (state) =>
      state.claim.billProduct.itemByKey[product_key]?.claim_item.itemByKey[
        state.claim.billProduct.itemByKey[product_key].claim_item.activeKey
      ].description
  );

  return (
    <Row gutter={[8, 8]}>
      <Col span={3} className="text-right">
        <Typography.Text>รายละเอียด</Typography.Text>
      </Col>
      <Col span={21}>
        <Input.TextArea
          value={description}
          maxLength={500}
          placeholder="รายละเอียดการเคลมสินค้า"
          autoSize={{ minRows: 4, maxRows: 4 }}
          disabled={disabled}
          onChange={(e) =>
            dispatch(setClaimItemValue({ description: e.target.value }))
          }
        />
      </Col>
    </Row>
  );
};

export default memo(InputDescription);
