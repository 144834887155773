/* eslint-disable no-unused-vars */
import {
  Col,
  notification,
  Row,
  Table,
  typography,
  Spin,
  Radio,
  DatePicker,
  Button,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { AiOutlineTable } from "react-icons/ai";
import { FaRegChartBar } from "react-icons/fa";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import FilterList from "../../components/FilterList";
import SelectProductGroup, {
  ValueProductGroup,
} from "../../components/SelectProductGroup";
import SelectProductSubGroup, {
  ValueProductSubGroup,
} from "../../components/SelectProductSubGroup";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import { BarChart } from "./BarChart";
import useAsync from "../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../services/manage-website.service";
const { Text } = typography;

const Index = () => {
  const limit = 50;
  const user = getUser();
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: [user?.branch_code ? user.branch_code : ""],
      query: "",
      page: 1,
      limit: 50,
      category: "",
      subCategory: "",
      group: "",
      buyer_type: "",
    },
    summaryModal: {
      visible: false,
    },
  };

  const toDay = moment().format("DD/MM/YYYY");
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [checkBranch, setCheckBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);

  //Filter
  const [groupFilter, setGroupFilter] = useState("");
  const [subGroupFilter, setSubGroupFilter] = useState("");
  const [formatDisplay, setFormatDisplay] = useState("table");
  const [chartData, setChartData] = useState();
  let groupName = ValueProductGroup(groupFilter);
  let subGroupName = ValueProductSubGroup(subGroupFilter);

  const [loading, data, error, reload] = useHttp({
    url: `${URL_API}/boss/sales-report/summarybybrand`,
    token: true,
    params: {
      limit,
      offset: limit * (page - 1),
      userId: user.id,
      category: groupFilter,
      subCategory: subGroupFilter,
      branchCode: state.filter.branch,
      ...state.filter,
      dateFrom: state.filter.date_from
        ? moment(state.filter.date_from).format("YYYY-MM-DD")
        : null,
      dateTo: state.filter.date_to
        ? moment(state.filter.date_to).format("YYYY-MM-DD")
        : null,
    },
    initialLoad: false,
  });

  useEffect(() => {
    let newData = data?.data.map((obj) => {
      var sum = 0;
      for (var el in obj) {
        if (el !== "brand") {
          sum += obj[el];
        }
      }

      return {
        ...obj,
        Total: sum,
      };
    });

    setDataSource(newData ? newData : []);
    const total = _.filter(data?.data, { brand: "total" });
    const totalValue = _.omit(total[0], ["brand"]);
    let branchList = _.map(_.keys(totalValue), (name, index) => name);
    let dataList = _.map(_.values(totalValue), (name, index) => name);

    let myData1 = _.map(_.values(totalValue), (name, index) => {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return {
        label:
          branchList[index] === "mymart_shop_สาขา_jas_urban_srinakarin"
            ? "MYMART SHOP สาขา JAS URBAN SRINAKARIN"
            : branchList[index]
                .toLowerCase()
                .replace(/_/g, " ")
                .replace(/(?: |\b)(\w)/g, function (key, p1) {
                  return key.toUpperCase();
                }),
        data: [dataList[index]],
        backgroundColor: `#${randomColor}`,
      };
    });

    let valueChart = {
      labels: ["จำนวนทั้งหมด ( ชิ้น ) / สาขา"],
      datasets: myData1,
      //--------------------------- version2
      //   labels: branchList,
      //   datasets: [
      //     {
      //       label: ["Total"],
      //       data: dataList,
      //       backgroundColor: "#FBBC04",
      //     },
      //   ],
    };
    setChartData(valueChart);
  }, [data, groupFilter]);

  const { execute: getAllBranch } = useAsync(
    manageWebsiteService.getAllBranch,
    {
      immediate: true,
      onSuccess: (data) => {
        setCheckBranch(data);
      },
    }
  );

  useEffect(() => {
    let selected_branch = _.map(
      _.map(state.filter.branch, (i) =>
        _.find(checkBranch, (o) => o.code === i)
      ),
      (n) => (n?.name !== undefined ? n?.name : "ทั้งหมด")
    );
    setSelectedBranch(selected_branch);
  }, [state.filter.branch, checkBranch]);

  console.log(dataSource);
  //const exportDataData = data ? [data?.data] : [];
  const exportDataData = dataSource ? dataSource : [];
  const exportToExcel = () => {
    if (exportDataData.length > 0) {
      let headerTable = _.map(
        _.keys(dataSource[0]),
        (name, index) =>
          name === "brand"
            ? "Brand"
            : name === "mymart_shop_สาขา_jas_urban_srinakarin"
            ? "MYMART SHOP สาขา JAS URBAN SRINAKARIN"
            : name
                .toLowerCase()
                .replace(/_/g, " ")
                .replace(/(?: |\b)(\w)/g, function (key, p1) {
                  return key.toUpperCase();
                })

        // name.toLowerCase()
        // .replace(/_/g, " ")
        // .replace(/(?: |\b)(\w)/g, function (key, p1) {
        //   return key.toUpperCase();
        // })
      );
      let excelData = [
        ["รายงานยอดขายสินค้า Hibrid ตาม Brand"],
        [
          `สาขา : ${
            state.filter.branch === ""
              ? "ทั่งหมด"
              : selectedBranch.map((name) => name)
          }`,
        ],
        [
          `กลุ่มสินค้าหลัก : ${groupFilter === "" ? "ทั้งหมด" : groupName}`,
          "",
          `กลุ่มสินค้าย่อย : ${
            subGroupFilter === "" ? "ทั้งหมด" : subGroupName
          }`,
        ],
        [
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
          "",
        ],
        [],
        headerTable,
      ];

      //exportDataData[0].forEach((n) => {
      exportDataData.forEach((n) => {
        excelData = [
          ...excelData,
          _.map(_.values(n), (name, index) =>
            name === "total" ? "รวมทั้งสาขา (ชิ้น )" : name
          ),
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 4 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 1 } },
        { s: { r: 3, c: 2 }, e: { r: 3, c: 4 } },
      ];

      let wch_count = _.map(_.keys(data?.data[0]), () => {
        return { wch: 18 };
      });

      var wscols = wch_count;

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานยอดขายสินค้าHibrid");
      XLSX.writeFile(wb, "รายงานยอดขายสินค้าHibrid.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานยอดขายสินค้า Hibrid ตาม Brand</PanelHeader>
        <PanelBody>
          <Row gutter={16} type="flex" className="align-items-center mb-3">
            <Col span={1}>
              <Text strong>วันที่</Text>
            </Col>
            <Col span={6}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col span={1}>
              <Text className="text-right d-block" strong>
                สาขา
              </Text>
            </Col>
            <Col span={8}>
              <SelectBranch
                allowClear
                userLogin={true}
                defaultValue={[user.branch_code]}
                className="w-100"
                mode="multiple"
                allowDisableBranch
                withAll={user.branch_count > 1}
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
            <Col span={6} push={2}>
              <Col span={12}>
                <Button type="primary" onClick={reload} block>
                  แสดงรายงาน
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  icon="file-excel"
                  onClick={exportToExcel}
                  block
                >
                  Export Excel
                </Button>
              </Col>
            </Col>
          </Row>

          <Row gutter={16} type="flex" className="align-items-center">
            <Col span={1} align="left">
              <Text strong>Category</Text>
            </Col>
            <Col span={5}>
              <SelectProductGroup
                className="w-100"
                withAll
                defaultValue={""}
                value={state.filter.category}
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { category: e, subCategory: "" },
                  });
                  // setGroupFilter(e);
                  // setSubGroupFilter("");
                }}
              />
            </Col>
            <Col span={2}>
              <Text className="text-right d-block" strong>
                Sub Category
              </Text>
            </Col>
            <Col span={5}>
              <SelectProductSubGroup
                withAll
                groupId={groupFilter}
                className="w-100"
                defaultValue={""}
                value={state.filter.subCategory}
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { subCategory: e },
                  });
                }}
              />
            </Col>

            <Col span={6} push={5} align="right" className="pr-3">
              <Radio.Group
                value={formatDisplay}
                onChange={(e) => setFormatDisplay(e.target.value)}
              >
                <Radio.Button value="table">
                  <AiOutlineTable className="mr-2" />
                  แสดงตาราง
                </Radio.Button>
                <Radio.Button value="chart">
                  <FaRegChartBar className="mr-2" />
                  แสดง Chart
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

          {formatDisplay === "chart" && (
            <div
              style={{
                borderTop: "0.5px solid rgb(217,217,217)",
                margin: "24px 0px",
                paddingTop: "40px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Row style={{ width: "60%" }}>
                <Spin spinning={loading}>
                  <BarChart chartData={chartData} />
                </Spin>
              </Row>
            </div>
          )}

          {formatDisplay === "table" && (
            <Table
              style={{ marginTop: "24px" }}
              dataSource={dataSource}
              rowKey="brand"
              bordered
              loading={loading}
              scroll={{ x: "max-content" }}
              pagination={{
                current: page,
                pageSize: limit,
                total: dataSource.length !== 0 ? dataSource.length : 50,
                size: "default",
                onChange: (currPage) => setPage(currPage),
              }}
            >
              <Table.Column
                title="Brand"
                rowKey="brand"
                dataIndex="brand"
                render={(text) => (text === "total" ? "รวมทั้งสาขา" : text)}
              />
              {_.map(_.keys(_.omit(dataSource[0], ["brand"])), (n, i) => {
                return (
                  <Table.Column
                    key={i}
                    title={
                      n === "mymart_shop_สาขา_jas_urban_srinakarin"
                        ? "MYMART SHOP สาขา JAS URBAN SRINAKARIN"
                        : n
                            .toLowerCase()
                            .replace(/_/g, " ")
                            .replace(/(?: |\b)(\w)/g, function (key, p1) {
                              return key.toUpperCase();
                            })
                    }
                    dataIndex={n}
                  />
                );
              })}
            </Table>
          )}
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
