import { axios, getToken, URL_API } from "../utils";
export const onUploadFile = async ({
  file,
  setFileList,
  onChange = () => {},
}) => {
  try {
    const data = new FormData();
    data.append("file", file);
    data.append("container_name", "operations");
    const getFileObject = (media) => ({
      id: media?.id,
      name: file.name,
      uid: file.uid,
      status: "done",
      url: media?.azure_url,
      media: media,
    });
    await axios({
      method: "post",
      url: `${URL_API}/file/upload`,
      headers: { Authorization: "Bearer " + getToken() },
      data,
    })
      .then((res) => {
        const media = { ...res?.data?.data, temp: true };
        if (media) onChange(media);
        setFileList((prev) => {
          return [...prev, getFileObject(media)];
        });
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};
