import { Col, Input, Modal, Row } from "antd";
import React, { useContext } from "react";
import ProductCategoryContext from "../../../../contexts/product-category.context";
import { axios, getToken, URL_API } from "../../../../utils";

export default function ModalCategory() {
  const { state, dispatch } = useContext(ProductCategoryContext);

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch({
      type: "CHANGE_FORM_VALUE",
      payload: {
        form: "category",
        name,
        value,
      },
    });
  };

  const handleClickSave = () => {
    const { id, code, name } = state.form.category;

    if (code === "" || name === "") {
      return Modal.warning({
        title: "คุณกรอกข้อมูลไม่ครบถ้วน",
        content: "กรุณาตรวจสอบข้อมูลอีกครั้ง",
        cancelText: "ปิด",
      });
    }

    Modal.warning({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกข้อมูลหรือไม่",
      cancelText: "ปิด",
      okText: "บันทึก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "POST",
            baseURL: URL_API,
            url: `/setting/master/product-group${id === "" ? "" : `/${id}`}`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              code,
              department_id: state.division?.id,
              division_id: state.department?.id,
              name_en: name,
              name_local: name,
            },
          })
            .then((res) => {
              resolve("");
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((res) => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                dispatch({ type: "CLOSE_MODAL" });
                dispatch({ type: "RELOAD", payload: "category" });
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  return (
    <Modal
      title="กลุ่มสินค้าหลัก"
      visible={state.modal.visible && state.modal.name === "category"}
      cancelText="ปิด"
      okText="บันทึก"
      onCancel={() => {
        dispatch({ type: "CLOSE_MODAL" });
      }}
      onOk={handleClickSave}
    >
      <Row className="mb-2">
        <Col>
          <label>รหัส (ใช้สำหรับ Gen รหัสสินค้า)</label>
          <Input
            name="code"
            placeholder="รหัส"
            maxLength={1}
            value={state.form.category.code}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>ชื่อกลุ่มสินค้าหลัก</label>
          <Input
            placeholder="ชื่อกลุ่มสินค้าหลัก"
            name="name"
            value={state.form.category.name}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Modal>
  );
}
