import React, { forwardRef } from "react";
import { Select } from "antd";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";

const SelectBankBranch = ({
  className = "",
  bank_code,
  bank_branch,
  withAll = false,
  ...props
}, ref) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/bank-branch`,
      params: {
        bank_code,
      },
    },
    [bank_code]
  );
  if (error) return <div>{error}</div>;
  return (
    <Select
      loading={loading}
      className={`w-100 ${className}`}
      defaultValue={bank_branch}
      dropdownMatchSelectWidth={false}
      {...props}
      optionFilterProp="children"
        filterOption={(input, option) =>  
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
          || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
    >
      {withAll ? <Select.Option value="">ทั้งหมด</Select.Option> : null}
      {data && data.data
        ? data.data.map((n, i) => (
            <Select.Option key={i} value={n.branch_code}>
              {/* {`${n.branch_code} ${n.bank_name}`} */}
              {`${n.bank_name}`}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

export default forwardRef(SelectBankBranch);
