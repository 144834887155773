import { Button, Col, notification, Row, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaEye, FaTimes } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import ImgError from "../../../../../components/ImgError";
import {
  fixProductType,
  fixProductTypeSet,
} from "../../../../../config/fix-value";
import styles from "../../../../../scss/module/promotion.module.scss";
import { _ } from "../../../../../utils";

const initSourceFree = {
  data: [],
  banned: [],
  sold_out: [],
};

const PromotionItem = ({
  data: {
    promotion_id,
    condition_id,
    description,
    name: promotion_name,
    azure_url,
    source_azure_url,
    free_azure_url,
    condition_option,
    free_option,
    promotion_type,
    source = [],
    free = [],
  },
  onSelect,
  onPreview,
  allItems,
  allowItem,
  allowRenew,
  onViewDisableItem,
}) => {
  const [freeData, setFreeData] = useState(initSourceFree);
  const [sourceData, setSourceData] = useState(initSourceFree);
  const banned = !!sourceData.banned.length || !!freeData.banned.length;
  const sold_out = !!sourceData.sold_out.length || !!freeData.sold_out.length;
  const card_class = banned || sold_out ? styles.disabled : "";

  useEffect(() => {
    const res_source = _.reduce(
      source,
      (res, n) => {
        const banned =
          (n.product_type !== fixProductType.renew && allowItem === false) ||
          (n.product_type === fixProductType.renew && allowRenew === false);
        let sold_out = false;
        if (
          !banned &&
          !_.includes(
            [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
            n.product_type_set
          )
        ) {
          sold_out = !n.stock_balance;
          if (!sold_out) {
            const used_qty = allItems[n.source_value]?.qty || 0;
            const will_used =
              _.find(free, (m) => n.source_value === m.source_value)
                ?.source_amount || 0;
            sold_out =
              n.stock_balance < +n.source_amount + +will_used + used_qty;
          }
        }
        return {
          data: [...res.data, { ...n, banned, sold_out }],
          banned: banned ? [...res.banned, n] : res.banned,
          sold_out: sold_out ? [...res.sold_out, n] : res.sold_out,
        };
      },
      {
        data: [],
        banned: [],
        sold_out: [],
      }
    );
    const res_free = _.reduce(
      free,
      (res, n) => {
        if (n.source_key !== "item")
          return {
            ...res,
            data: [...res.data, n],
          };
        const banned =
          (n.product_type !== fixProductType.renew && allowItem === false) ||
          (n.product_type === fixProductType.renew && allowRenew === false);
        let sold_out = false;
        if (
          !banned &&
          !_.includes(
            [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
            n.product_type_set
          )
        ) {
          sold_out = !n.stock_balance;
          if (!sold_out) {
            const used_qty = allItems[n.source_value]?.qty || 0;
            const will_used =
              _.find(source, (m) => n.source_value === m.source_value)
                ?.source_amount || 0;
            sold_out =
              n.stock_balance < +n.source_amount + +will_used + used_qty;
          }
        }
        return {
          data: [...res.data, { ...n, banned, sold_out }],
          banned: banned ? [...res.banned, n] : res.banned,
          sold_out: sold_out ? [...res.sold_out, n] : res.sold_out,
        };
      },
      {
        data: [],
        banned: [],
        sold_out: [],
      }
    );
    setSourceData(res_source);
    setFreeData(res_free);
  }, [source, free, allItems, allowItem, allowRenew]);

  const handleSelect = () => {
    if (banned || sold_out)
      return notification.warning({
        message: "ไม่สามารถดำเนินการได้",
        description: banned
          ? "นักธุกิจไม่สามารถซื้อสินค้าบางรายการ"
          : "ไม่มีสินค้าใน stock บางรายการ",
      });
    onSelect({
      promotion_type,
      condition_option,
      free_option,
      source: _.reduce(
        source,
        (product, n) => [
          ...product,
          {
            product_code: n.source_value,
            qty: +n.source_amount,
          },
        ],
        []
      ),
      free: _.reduce(
        free,
        (product, n) => [
          ...product,
          {
            promotion_id,
            promotion_name,
            source_key: n.source_key,
            source_value: n.source_value,
            source_amount: +n.source_amount,
            source_option: n.source_option,
            point_01: n.point_01,
            point_02: n.point_02,
            point_03: n.point_03,
          },
        ],
        []
      ),
    });
  };

  return (
    <Row
      type="flex"
      className={`${styles["promotion-list2"]} ${styles["card-promotion"]} ${card_class}`}
    >
      <Col span={24}>
        <div
          className={`${styles.img} ${
            banned || sold_out ? styles.disabled : ""
          }`}
        >
          <ImgError
            onClick={handleSelect}
            className="w-100 h-100 object-fit-cover"
            src={source_azure_url}
            alt={`pro-con-${condition_id}`}
          />
          {(banned || sold_out) && (
            <Tooltip
              placement="right"
              title={
                banned
                  ? "สินค้าบางรายการไม่สามารถซื้อได้"
                  : "สินค้าบางรายการหมด"
              }
            >
              <div
                className={banned ? styles.failed : styles.warn}
                onClick={() => {
                  onViewDisableItem(
                    {
                      banned: sourceData.banned,
                      sold_out: sourceData.sold_out,
                    },
                    {
                      banned: freeData.banned,
                      sold_out: freeData.sold_out,
                    }
                  );
                }}
              >
                {banned ? (
                  <FaTimes size={18} />
                ) : (
                  <TiWarningOutline size={18} />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </Col>
      <Col span={24} className={styles.content}>
        <Row type="flex" gutter={8} className="align-items-center">
          <Col span={21} className="text-overflow">
            <Typography.Text strong className="user-select-none">
              {promotion_name}
            </Typography.Text>
          </Col>
          <Col span={3} className="text-center">
            <Button
              shape="circle"
              type="primary"
              size="small"
              onClick={() =>
                onPreview({
                  pro_id: promotion_id,
                  promotion: {
                    description,
                    azure_url,
                    name: promotion_name,
                  },
                  source: sourceData.data,
                  free: freeData.data,
                  source_azure_url,
                  free_azure_url,
                })
              }
            >
              <FaEye />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PromotionItem;
