/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, message, Row, Skeleton, Typography, Upload } from "antd";
import React, { useState } from "react";
import SelectAllType from "../../../../../components/SelectAllType";
import { getToken, URL_API } from "../../../../../utils";
import ShowImge from "./ShowImge";
import UploadHistory from "./UploadHistory";

const BuyerDocument = ({ id, dataImage, loadingImage, reloadImge }) => {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [img, setImg] = useState([]);

  const onChangePicture = async (file) => {
    if (file.file.status !== "uploading") {
      //console.log(file.file, file.fileList);
    }
    if (file.file.status === "done") {
      if (!file.file.url && !file.file.preview) {
        file.preview = await getBase64(file.file.originFileObj);
      }
      setImg([
        ...img,
        {
          files: file.preview,
          type: activeTab,
          id_img: file?.file?.response?.id,
        },
      ]);
    } else if (file.file.status === "error") {
      message.error(`${file.file.name} file upload failed.`);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const previewImage = img.filter((i) => i.type === activeTab); //image when upload
  const getImage = dataImage?.data.filter((i) => i.document_type === activeTab); //image get for db
  // console.log("dataImage",dataImage);
  // console.log("getImage",getImage);
  return (
    <>
      <ul className="full-tabs" style={{ justifyContent: "space-between" }}>
        <li className="active mr-4">
          <Typography.Title level={4}>
            <a style={{ fontSize: "13px" }}>อัพโหลดเอกสาร</a>
          </Typography.Title>
        </li>
        <Button
          size="small"
          className="m-1 d-flex align-items-center"
          icon="history"
          shape="round"
          onClick={() => setShow(true)}
        >
          History
        </Button>
      </ul>
      <div className="container shadow-sm p-15 mb-4 bg-white rounded">
        {loadingImage ? (
          <Skeleton active />
        ) : (
          <>
            <Row>
              <Col className="d-flex justify-content-between m-3">
                <SelectAllType
                  className="w-25"
                  process_type="document_type_local"
                  onChange={(e) => setActiveTab(e)}
                  defaultValue={activeTab}
                />
                {/* <SelectDocumentType
                className="w-25"
                dropdownClassName="w-25"
                onChange={(e) => setActiveTab(e)}
                defaultValue={activeTab}
              /> */}
                {dataImage?.data.filter((i) => i.document_type === activeTab)
                  .length <= 0 && (
                  <Upload
                    showUploadList={false}
                    id="UploadImd"
                    name="file"
                    // action="http://localhost:4001/system/files/upload"
                    action={`${URL_API}/pos/ethics/${id}/image/?type=${activeTab}`}
                    accept=".jpg,.png"
                    headers={{
                      Authorization: "Bearer " + getToken(),
                    }}
                    onChange={onChangePicture}
                    beforeUpload={beforeUpload}
                  >
                    <Button
                      type="primary"
                      size="small"
                      className="m-1 d-flex align-items-center"
                      icon="upload"
                      shape="round"
                    >
                      Upload
                    </Button>
                  </Upload>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <small className="text-danger">
                  * เฉพาะไฟล์ JPEG, PNG ขนาดไม่เกิน 1 MB เท่านั้น
                </small>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="text-center">
                    {dataImage?.data.filter(
                      (i) => i.document_type === activeTab
                    ) && (
                      <ShowImge
                        setImg={setImg}
                        src={previewImage[0]?.files}
                        id_image={previewImage[0]?.id_img}
                        filter={getImage}
                        reloadImge={reloadImge}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        member_id={id}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <UploadHistory
          id={id}
          show={show}
          onCancel={() => setShow(false)}
          reloadImge={reloadImge}
        />
      </div>
    </>
  );
};
export default BuyerDocument;
