/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Modal, notification } from "antd";
import numeral from "numeral";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useQuery } from "../../../../hooks/dom";
import { useHttp } from "../../../../hooks/http";
import { URL_API, _, axios, getToken, moment } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import ModalCancel from "../components/ModalCancel";
import ModalReserve from "../components/ModalReserve";
import Table from "../components/Table";
import ModalContext from "../contexts/modal-context";

const limit = 10;
const Waiting = ({ criteria, history }) => {
  const query = useQuery();
  const {
    state: {
      reserveModal: { showReserve },
      cancelModel: { showCancel },
    },
    dispatch: modalDispatch,
  } = useContext(ModalContext);
  //const [showCancel, setShowCancel] = useState(false)
  //const [showReserve, setShowReserve] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [page, setPage] = useState(1);
  const paymentId = _.split(
    _.split(window.location.pathname, "payment-")[1],
    "-id"
  )[0];
  const reserveId = query.get("reserve-id");
  const paymentCode = query.get("payment_code");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/operation/reserve`,
      params: {
        status: "pending",
        limit,
        offset: limit * (page - 1),
        ...criteria,
      },
    },
    [criteria, page]
  );

  useEffect(() => {
    setPage(1);
  }, [criteria]);

  useEffect(() => {
    if (reserveId && !_.isNaN(+reserveId)) {
      // display modal by id after update result or redirect from KPayment (cancel)
      modalDispatch({
        type: "setReserveModal",
        payload: { showReserve: true },
      });
      setActiveId(reserveId);
    }
  }, [reserveId]);

  useEffect(() => {
    if (paymentId && paymentCode && !_.isNaN(+paymentId)) {
      // redirect from KPayment (success) then update result
      axios({
        method: "post",
        url: `${URL_API}/pos/operation/reserve/k-payment`,
        data: { payment_code: paymentCode, payment_id: paymentId },
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          if (res.data.error) {
            history.replace({ pathname: "/reserve" });
            return Modal.error({
              title: "ไม่สามารถดำเนินการได้",
              content: res.data.error,
              zIndex: 1080,
            });
          }
          history.replace({
            pathname: "/reserve",
            search: `?reserve-id=${res.data.data.id}`,
          });
          notification.success({
            message: "ชำระเงินด้วยบัตรเครดิตเรียบร้อย",
            description: `payment code : ${paymentCode}`,
          });
        })
        .catch((e) => resCatchModal(e));
    }
  }, [paymentId, paymentCode]);

  const columns = [
    {
      title: "เลขที่ชุดจอง",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "วันที่",
      dataIndex: "document_date",
      render: (text) => (
        <p className="m-0 text-left">
          {moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "รหัสนักธุรกิจ",
      dataIndex: "buyer_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ผู้ดำเนินการ",
      dataIndex: "buyer_name",
      render: (text) => <p className="m-0">{text}</p>,
    },
    {
      title: "ผู้ทำรายการจอง",
      dataIndex: "cashier_name",
      render: (text) => <p className="m-0">{text}</p>,
    },
    // {
    //   title: "มีสินค้าในคลัง",
    //   dataIndex: "has_stock",
    //   render: (text) => (
    //     <p className="m-0 text-center">
    //       {text ? (
    //         <FaCheck className="text-pv" />
    //       ) : (
    //         <FaTimes className="text-reds" />
    //       )}
    //     </p>
    //   ),
    // },
    {
      title: "ยอดรวม",
      dataIndex: "total_price",
      render: (text) => (
        <p className="m-0 text-right">{numeral(text).format("0,0.00")}</p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <p className="m-0 text-right">
          <Button
            size="small"
            className="bg-pv text-white border-pv mr-2"
            onClick={() => {
              modalDispatch({
                type: "setReserveModal",
                payload: { showReserve: true },
              });
              setActiveId(record.id);
            }}
          >
            <small>แจ้งชำระเงิน</small>
          </Button>
          <Button
            size="small"
            className="bg-reds text-white border-reds"
            onClick={() => {
              modalDispatch({
                type: "setCancelModal",
                payload: { showCancel: true },
              });
              setActiveId(record.id);
            }}
          >
            <small>ยกเลิกการจอง</small>
          </Button>
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (data?.data.record) {
      setDataSource(data?.data.record);
    }
  }, [data]);

  if (error) return <Alert type="error" message={error} />;

  const toMainMenu = () => history.push({ pathname: "/reserve" });

  return (
    <>
      <Table
        page={page}
        setPage={setPage}
        columns={columns}
        total={data?.data?.total}
        dataSource={dataSource}
        loading={loading}
        className="font-size-12"
      />

      <ModalReserve
        toMainMenu={toMainMenu}
        visible={showReserve}
        reserveId={reserveId}
        onCancel={() => {
          if (reserveId) {
            toMainMenu();
          } else {
            modalDispatch({
              type: "setReserveModal",
              payload: { showReserve: false },
            });
            setActiveId(null);
          }
        }}
        clickCancel={() => {
          modalDispatch({
            type: "setReserveModal",
            payload: { showReserve: false },
          });
          modalDispatch({
            type: "setCancelModal",
            payload: { showCancel: true },
          });
        }}
        id={activeId}
        reload={reload}
        hasStock={true}
      />

      <ModalCancel
        visible={showCancel}
        toMainMenu={toMainMenu}
        onCancel={() => {
          if (reserveId) {
            toMainMenu();
          } else {
            modalDispatch({
              type: "setCancelModal",
              payload: { showCancel: false },
            });
            setActiveId(null);
          }
        }}
        reserveId={reserveId}
        id={activeId}
        reload={reload}
      />
    </>
  );
};

export default withRouter(Waiting);
