/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Spin, Tooltip, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { IoMdReverseCamera } from "react-icons/io";
import { MdCameraEnhance, MdPhotoCamera, MdSwitchCamera } from "react-icons/md";
import Webcam from "react-webcam";
import img from "../../../../assets/img/pos/user-default.png";
import { axios, getToken, moment, URL_API } from "../../../../utils";
import { base64toFile, resCatchModal } from "../../../util/helper";

const CameraUpload = ({ buyer, operator, setOperator }) => {
  const webcamRef = useRef(null);
  const [capture, setCapture] = useState(false);
  const [loadingCamera, setLoadingCamera] = useState(false);
  const [loadImg, setLoadImg] = useState(img);
  const [upLoadProgress, setUpLoadProgress] = useState(null);
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: "environment",
  });

  useEffect(() => {
    if (buyer.member_id) {
      setLoadImg(
        `${URL_API}/pos/master/image/profile?ref_id=${buyer.member_id}&ref_type=${buyer.partner_type}`
      );
    } else {
      setLoadImg(img);
    }
  }, [buyer.member_id, buyer.partner_type]);

  const handleCaptureImg = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) return;
    setLoadingCamera(true);
    const file = base64toFile(
      imageSrc,
      `buyer-${moment().format("YYYY-MM-DD-hh-mm-ss")}.jpg`
    );
    const data = new FormData();
    data.append("file", file);
    data.append("container_name", "operations");
    axios({
      method: "post",
      url: `${URL_API}/file/upload`,
      headers: { Authorization: "Bearer " + getToken() },
      data,
      onUploadProgress: (e) =>
        setUpLoadProgress(~~((e.loaded * 100) / e.total)),
    })
      .then((res) =>
        setOperator((prevOperator) => ({
          ...prevOperator,
          buyer_img: { ...res.data.data, temp: true },
        }))
      )
      .catch((e) => resCatchModal(e))
      .finally(() => {
        setUpLoadProgress(null);
        setLoadingCamera(false);
        setCapture(false);
      });
  };

  const handleDelete = () => {
    console.log(operator);
    if (operator.buyer_img?.temp) {
      setLoadingCamera(true);
      axios({
        method: "post",
        url: `${URL_API}/file/del`,
        headers: { Authorization: "Bearer " + getToken() },
        data: { id: operator.buyer_img.id },
      })
        .then(() =>
          setOperator((prevOperator) => ({
            ...prevOperator,
            buyer_img: null,
          }))
        )
        .catch((e) => resCatchModal(e))
        .finally(() => setLoadingCamera(false));
    } else {
      setOperator((prevOperator) => ({
        ...prevOperator,
        buyer_img: null,
      }));
    }
  };

  const handleOpenCamera = () => {
    setCapture(true);
    setLoadingCamera(true);
    handleDelete();
  };

  const handleCloseCamera = () => {
    setCapture(false);
    setLoadingCamera(false);
    handleDelete();
  };

  const handleChangeCamera = useCallback(() => {
    setLoadingCamera(true);
    setVideoConstraints((prevState) => ({
      facingMode: prevState.facingMode === "user" ? "environment" : "user",
    }));
  }, []);

  const onCameraOpened = useCallback(() => {
    setLoadingCamera(false);
  }, []);

  const onCameraError = useCallback((e) => {
    setLoadingCamera(false);
    setCapture(false);
    notification.error({
      message: "ตรวจพบสิ่งผิดพลาด",
      description: e.message,
    });
  }, []);

  return (
    <>
      <div className="width-300 height-300 overflow-hidden rounded-circle">
        {loadingCamera && (
          <div className="width-300 height-300 bg-black d-flex justify-content-center align-items-center">
            <Spin
              tip={
                upLoadProgress ? `uploading ${upLoadProgress}%` : "Loading..."
              }
            />
          </div>
        )}
        {capture ? (
          <Webcam
            height={400}
            ref={webcamRef}
            forceScreenshotSourceSize={true}
            className="width-300 height-300 bg-black object-fit-cover"
            screenshotFormat="image/jpeg"
            audio={false}
            videoConstraints={videoConstraints}
            onUserMedia={onCameraOpened}
            onUserMediaError={onCameraError}
          />
        ) : (
          <img
            src={operator.buyer_img?.azure_url || loadImg}
            onError={() => setLoadImg(img)}
            className="w-100 h-100 object-fit-cover"
            alt="Profile"
          />
        )}
      </div>
      <Typography.Paragraph className="mt-3">
        {capture ? (
          <>
            {!loadingCamera && (
              <>
                <Tooltip placement="bottom" title="เปลี่ยนกล้อง">
                  <MdSwitchCamera
                    fontSize="2em"
                    className="cursor-pointer mr-4"
                    onClick={handleChangeCamera}
                  />
                </Tooltip>
                <Tooltip placement="bottom" title="ถ่ายรูป">
                  <MdCameraEnhance
                    fontSize="3em"
                    className="cursor-pointer mr-4"
                    onClick={handleCaptureImg}
                  />
                </Tooltip>
              </>
            )}
            <Tooltip placement="bottom" title="ยกเลิก">
              <FaTimes
                fontSize={`${loadingCamera ? "3" : "2"}em`}
                className="cursor-pointer"
                onClick={handleCloseCamera}
              />
            </Tooltip>
          </>
        ) : operator.buyer_img ? (
          <>
            <Tooltip placement="bottom" title="ถ่ายใหม่">
              <IoMdReverseCamera
                fontSize="3em"
                className="cursor-pointer"
                onClick={handleOpenCamera}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="ยกเลิก">
              <FaTimes
                fontSize="3em"
                className="ml-4 cursor-pointer"
                onClick={handleCloseCamera}
              />
            </Tooltip>
          </>
        ) : (
          <Tooltip placement="bottom" title="เปิดกล้อง">
            <MdPhotoCamera
              fontSize="3em"
              className="cursor-pointer"
              onClick={handleOpenCamera}
            />
          </Tooltip>
        )}
      </Typography.Paragraph>
    </>
  );
};

export default CameraUpload;
