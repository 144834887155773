/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Table, Typography } from "antd";
import React from "react";
import { FaCogs, FaEye, FaListUl, FaPencilAlt, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import PrintButton from "../../../../components/PrintButton";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { moment, _ } from "../../../../utils";
import { displayTotal } from "../../../../utils/helper";

const { Column } = Table;
const { Text } = Typography;

const getClassNameStatus = (type_params, status) => {
  if (type_params === "receive") {
    switch (status) {
      case "wait-receive":
        return "text-rp";
      case "wait-some":
        return "text-tp";
      case "wait-return":
        return "text-sp";
      default:
        return "";
    }
  }
  switch (status) {
    case "draft":
      return "text-rp";
    case "wait-add":
      return "text-tp";
    case "pending":
      return "text-sp";
    case "wait-receive":
      return "text-pv";
    case "wait-some":
      return "text-pv";
    case "wait-return":
      return "text-pv";
    case "return":
      return "text-nn";
    default:
      return "";
  }
};

const { setGlobalState } = actionTransfer;

const DocumentTable = ({ match, history, loading, data }) => {
  const { type: type_params, process: process_params } = match.params;
  const dispatch = useDispatch();
  const permission_status = useSelector(
    (state) => state.transfer.user.permission_status
  );
  const { page, limit } = useSelector((state) => state.transfer.searchDocument);
  const back_url = `/${
    _.split(match.path, "/")[1]
  }/${type_params}/${process_params}`;
  const is_finish = _.includes(["complete", "cancel"], process_params);
  const is_transfer = type_params === "transfer";
  const is_wait_add = process_params === "wait-add";
  const dataSource = data?.data.record || [];

  const renderEdit = ({ status, document_no, receive_type }) => {
    if (!permission_status[status]) return;
    if (status === "pending")
      return (
        <Button
          shape="circle"
          size="small"
          className="bg-sp border-sp text-white mr-l"
          onClick={() =>
            history.push({
              pathname: `${back_url}/${document_no}`,
              search: `?receive_type=${receive_type}`,
            })
          }
        >
          <FaListUl />
        </Button>
      );
    if (
      (type_params === "transfer" &&
        _.includes(["draft", "wait-add", "return"], status)) ||
      (type_params === "receive" &&
        _.includes(["wait-receive", "wait-some", "wait-return"], status))
    )
      return (
        <Button
          shape="circle"
          size="small"
          className="bg-tp border-tp text-white ml-2"
          onClick={() =>
            history.push({
              pathname: `${back_url}/${document_no}`,
              search: `?receive_type=${receive_type}`,
            })
          }
        >
          <FaPencilAlt />
        </Button>
      );
  };

  const renderCancel = ({ status, document_no, receive_type }) => {
    if (
      !_.includes(
        ["complete", "cancel", "wait-receive", "wait-return", "wait-some"],
        status
      )
    ) {
      if (!permission_status[status]) return;
      return (
        <Button
          shape="circle"
          size="small"
          className="bg-nn border-nn text-white ml-2"
          onClick={() =>
            dispatch(
              setGlobalState({
                name: "cancelModal",
                value: {
                  visible: true,
                  document_no,
                  document_status: status,
                  receive_type,
                },
              })
            )
          }
        >
          <FaTimes />
        </Button>
      );
    }
  };

  const renderPrint = (record) => {
    const { status } = record;
    if (
      !_.includes(["cancel", "draft", "wait-add", "pending", "return"], status)
    ) {
      return <PrintButton classsName="ml-2" record={record} reportName={record.report_name} />;
    }
  };

  // wait-receive	รอรับเข้า
  // wait-some	รับเข้าแล้วบางส่วน
  // wait-return	รอรับยอดคืน
  // complete	เสร็จสิ้นกระบวนการ

  // draft	ฉบับร่าง
  // wait-add	คำขอโอนเพิ่ม
  // pending	รออนุมัติ
  // wait-receive	รอปลายทางรับเข้า
  // return	รอแก้ไข
  // complete	เสร็จสิ้นกระบวนการ
  // cancel	ยกเลิก
  // wait-return	รอปลายทางรับเข้า
  // wait-some	รอปลายทางรับเข้า

  const renderButton = (record) => {
    const { status, document_no, receive_type } = record;
    return (
      <>
        {renderEdit({ status, document_no, receive_type })}
        <Button
          shape="circle"
          size="small"
          type="primary"
          className="ml-2"
          onClick={() =>
            history.push({
              pathname: `${back_url}/${document_no}`,
              search: `?preview=1&receive_type=${receive_type}`,
            })
          }
        >
          <FaEye />
        </Button>
        {renderCancel({ status, document_no, receive_type })}
        {renderPrint(record)}
      </>
    );
  };

  return (
    <Row gutter={[16, 16]} type="flex">
      <Col span={24}>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={dataSource}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            size: "default",
            showTotal: displayTotal,
            total: data?.data.total || 0,
            showLessItems: true,
            hideOnSinglePage: true,
            onChange: (page) =>
              dispatch(
                setGlobalState({ name: "searchDocument", value: { page } })
              ),
          }}
        >
          <Column
            width={60}
            title="#"
            key="row_id"
            dataIndex="row_id"
            align="center"
          />
          {!is_transfer && process_params === "complete" && (
            <Column
              width={170}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  เลขที่ใบโอนย้าย
                </div>
              }
              key="ref_document_no"
              dataIndex="ref_document_no"
            />
          )}
          <Column
            width={170}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                {!is_transfer && process_params === "complete"
                  ? "เลขที่ใบรับสินค้า"
                  : "เลขที่ใบโอนย้าย"}
              </div>
            }
            key="document_no"
            dataIndex="document_no"
          />
          <Column
            width={240}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">โอนจากคลัง</div>
            }
            key="from_wh_name"
            dataIndex="from_wh_name"
          />
          <Column
            width={240}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">คลังที่รับ</div>
            }
            key="to_wh_name"
            dataIndex="to_wh_name"
          />
          {!is_wait_add && (
            <Column
              width={150}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  วันที่ เวลา โอน
                </div>
              }
              key="transfer_date"
              dataIndex="transfer_date"
              align="center"
              render={(text) => text && moment(text).format("DD/MM/YYYY HH:mm")}
            />
          )}
          {is_finish && (
            <Column
              width={150}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  {`วันที่ เวลา ${
                    process_params === "complete" ? "รับยอดโอน" : "ยกเลิก"
                  }`}
                </div>
              }
              key="modify_date"
              dataIndex="modify_date"
              align="center"
              render={(text) => text && moment(text).format("DD/MM/YYYY HH:mm")}
            />
          )}
          {!is_wait_add && (
            <Column
              width={dataSource.length === 0 ? 150 : undefined}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ผู้ทำการโอน
                </div>
              }
              key="transfer_by"
              dataIndex="transfer_by"
              render={(text) => <div className="min-width-100">{text}</div>}
            />
          )}
          {is_finish ? (
            <Column
              width={dataSource.length === 0 ? 150 : undefined}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  {`ผู้${
                    process_params === "complete" ? "รับยอดโอน" : "ยกเลิก"
                  }`}
                </div>
              }
              key="modify_by"
              dataIndex="modify_by"
              render={(text) => <div className="min-width-100">{text}</div>}
            />
          ) : (
            <Column
              width={200}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">สถานะ</div>
              }
              key="status_text"
              dataIndex="status_text"
              render={(text, { status }) => (
                <Text className={getClassNameStatus(type_params, status)}>
                  {text}
                </Text>
              )}
            />
          )}
          <Column
            width={is_finish ? 80 : 150}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                <FaCogs />
              </div>
            }
            key="action"
            align="right"
            fixed="right"
            render={renderButton}
          />
        </Table>
      </Col>
    </Row>
  );
};

export default withRouter(DocumentTable);
