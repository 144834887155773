import { Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import SelectAddressMaster from "../../../../../components/SelectAddressMaster";

const initialState = {
  ship_to: null,
  ship_mobile: null,
  ship_address_no: null,
  ship_province_id: null,
  ship_province: null,
  ship_district_id: null,
  ship_district: null,
  ship_sub_district_id: null,
  ship_sub_district: null,
  ship_post_code: null,
};

const AddressModal = ({
  visible = true,
  onOk = () => null,
  onCancel = () => null,
  form = {},
}) => {
  const [state, setState] = useState(initialState);
  const { getFieldDecorator, validateFieldsAndScroll, resetFields } = form;
  const {
    ship_to,
    ship_mobile,
    ship_address_no,
    ship_province_id,
    ship_district_id,
    ship_sub_district_id,
    ship_post_code,
  } = state;

  const handleClear = () => {
    setState(initialState);
    resetFields();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title="ระบุที่อยู่จัดส่ง"
      okText="ตกลง"
      cancelText="ยกเลิก"
      onOk={() => {
        validateFieldsAndScroll((err) => {
          if (!err) {
            const {
              ship_sub_district,
              ship_district,
              ship_province,
              ship_post_code,
            } = state;
            onOk({
              ship_to,
              ship_mobile,
              ship_address_no,
              ship_sub_district,
              ship_district,
              ship_province,
              ship_post_code,
              ship_country: null,
              ship_to_id: 0,
              ship_name: "ระบุที่อยู่จัดส่ง",
            });
            handleClear();
          }
        });
      }}
      onCancel={handleClear}
      closable={false}
    >
      <Row>
        <Col>
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item label="ชื่อผู้รับ" className="mb-0">
              {getFieldDecorator("ship_to", {
                initialValue: ship_to,
                onChange: (e) =>
                  setState({ ...state, ship_to: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกชื่อผู้รับ",
                  },
                ],
              })(<Input maxLength={255} autoComplete="off" />)}
            </Form.Item>
            <Form.Item label="เบอร์โทรศัพท์" className="mb-0">
              {getFieldDecorator("ship_mobile", {
                initialValue: ship_mobile,
                onChange: (e) =>
                  setState({ ...state, ship_mobile: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกเบอร์โทรศัพท์",
                  },
                  {
                    pattern:
                      /^[+]?[(]?[0-9]{2,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/,
                    message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
                  },
                ],
              })(<Input autoComplete="off" />)}
            </Form.Item>
            <Form.Item label="ที่อยู่" className="mb-0">
              {getFieldDecorator("ship_address_no", {
                initialValue: ship_address_no,
                onChange: (e) =>
                  setState({ ...state, ship_address_no: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกที่อยู่",
                  },
                ],
              })(<Input.TextArea rows={3} maxLength={500} />)}
            </Form.Item>
            <Form.Item label="จังหวัด" className="mb-0">
              {getFieldDecorator("ship_province_id", {
                initialValue: ship_province_id,
                onChange: (e, option) => {
                  setState({
                    ...state,
                    ship_province_id: e,
                    ship_province: option.props.source.name,
                    ship_district_id: null,
                    ship_district: null,
                    ship_sub_district_id: null,
                    ship_sub_district: null,
                    ship_post_code: null,
                  });
                  resetFields([
                    "ship_district_id",
                    "ship_sub_district_id",
                    "ship_post_code",
                  ]);
                },
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกจังหวัด",
                  },
                ],
              })(<SelectAddressMaster className="w-100" type="province" />)}
            </Form.Item>
            <Form.Item label="อำเภอ" className="mb-0">
              {getFieldDecorator("ship_district_id", {
                initialValue: ship_district_id,
                onChange: (e, option) => {
                  setState({
                    ...state,
                    ship_district_id: e,
                    ship_district: option.props.source.name,
                    ship_sub_district_id: null,
                    ship_sub_district: null,
                    ship_post_code: null,
                  });
                  resetFields(["ship_sub_district_id", "ship_post_code"]);
                },
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกอำเภอ",
                  },
                ],
              })(
                <SelectAddressMaster
                  className="w-100"
                  type="district"
                  province_id={state.ship_province_id}
                />
              )}
            </Form.Item>
            <Form.Item label="ตำบล" className="mb-0">
              {getFieldDecorator("ship_sub_district_id", {
                initialValue: ship_sub_district_id,
                value: ship_sub_district_id,
                onChange: (e, option) =>
                  setState({
                    ...state,
                    ship_sub_district_id: e,
                    ship_sub_district: option.props.source.name,
                    ship_post_code: option.props.source.post_code,
                  }),
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกตำบล",
                  },
                ],
              })(
                <SelectAddressMaster
                  className="w-100"
                  type="subdistrict"
                  district_id={state.ship_district_id}
                />
              )}
            </Form.Item>
            <Form.Item label="รหัสไปรษณีย์" className="mb-0">
              {getFieldDecorator("ship_post_code", {
                initialValue: ship_post_code,
                value: ship_post_code,
                onChange: (e) =>
                  setState({ ...state, ship_post_code: e.target.value }),
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกรหัสไปรษณีย์",
                  },
                ],
              })(<Input autoComplete="off" />)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default Form.create()(AddressModal);
