import { Button, Typography } from "antd";
import React, { useState } from "react";
import ModalOTP from "./ModalOTP";

const Index = ({
  mobile = "",
  email = "",
  partnerCode = "",
  partnerType = "",
  email_verify = null,
  setEmailToVerify = () => null,
}) => {
  const [visible, setVisible] = useState(false);
  console.log(email,email_verify)
  const disabled = !email && (email_verify!==0)

  return (
    <>
      <Typography.Text className="mr-2">
        ({partnerCode} : {email})
      </Typography.Text>
      <Button
        disabled={disabled}
        size="small"
        className={!disabled ? "bg-nn border-nn text-white" : ""}
        onClick={() => setVisible(true)}
      >
        ยืนยัน
      </Button>
      {!disabled && (
        <ModalOTP
          visible={visible}
          data={{
            mobile,
            email,
            partner_code: partnerCode,
            partner_type: partnerType,
          }}
          onCancel={() => setVisible(false)}
          onOk={() => {
            setVisible(false);
            setEmailToVerify(1);
          }}
        />
      )}
    </>
  );
};

export default Index;
