import {
  Button,
  Col,
  Popconfirm,
  Row,
  Select,
  Typography,
  notification
} from "antd";
import React, { memo, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionCreditNote } from "../../../../../redux/slices/credit-note";
import { URL_API, axios } from "../../../../../utils";
const SelectMember = () => {
  const { SET_PARTNER, CLEAR_PARTNER } = actionCreditNote;
  const partner = useSelector((state) => state.creditNote.partner);
  const dispatch = useDispatch();
  const [memberList, setMemberList] = useState([]);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickSearch = () => {
    setLoading(true);
    axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        query: value.trim(),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            let data = res.data.data[0];
            dispatch(SET_PARTNER(data));
            setMemberList([]);
          } else {
            setMemberList(res.data.data);
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return(
  <Row gutter={[8, 8]} type="flex" className="mb-2">
    <Col span={6}className="text-right">
      <Typography.Text strong className="text-nowrap">
        รหัสเจ้าของใบเสร็จ
      </Typography.Text>
    </Col>
    {partner ? (
      <Col span={18}>
        <Row gutter={[8, 8]} type="flex">
          <Col span={22} className="d-flex flex-column">
            <div>
              <Typography.Text className="text-nowrap ">
                รหัสสมาชิก : {partner.code}
              </Typography.Text>
              <Typography.Text></Typography.Text>
            </div>
            <div className="my-2">
              <Typography.Text className="text-nowrap ">
                ชื่อสมาชิก : {partner.first_name_th} {partner.sur_name_th}
              </Typography.Text>
              <Typography.Text></Typography.Text>
            </div>
          </Col>
          <Col span={2} className="text-right">
            <Popconfirm
              title="ต้องการลบรายการนี้หรือไม่"
              onConfirm={() => {
                dispatch(CLEAR_PARTNER());
                setMemberList([]);
              }}
              okText="ลบ"
              cancelText="ไม่ลบ"
              placement="topRight"
              zIndex={1080}
            >
              <Button size="small" ghost type="danger">
                <FaTrashAlt size={10} />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    ) : (
      <Col span={18}>
        <Select
          showSearch
          defaultActiveFirstOption={false}
          value={value || undefined}
          notFoundContent={null}
          size="small"
          loading={loading}
          className="w-100"
          placeholder="รหัสเจ้าของใบเสร็จ"
          onSearch={(e) => {
            if (e) {
              setMemberList([]);
              setValue(e);
            }
          }}
          onClickSearch={handleClickSearch}
          onInputKeyDown={(e) => {
            const key = e.key;
            if (key === "Enter") {
              handleClickSearch();
            } else if (key === "Backspace" || key === "Delete") {
              setMemberList([]);
            }
          }}
          onSelect={(n, i) => {
            const data = memberList[+i.key];
            dispatch(SET_PARTNER(data));
          }}
        >
          {memberList?.map((n, i) => (
            <Select.Option key={i} value={n.code}>
              {`(${n.code}) ${n.first_name_th} ${n.sur_name_th}`}
            </Select.Option>
          ))}
        </Select>
      </Col>
    )}
  </Row>
  )
};
export default memo(SelectMember);
