/* eslint-disable no-unused-vars */
import { Button, Col, Row, Select, Table, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectCategory from "../../components/SelectCategory";
import SelectYear from "../../components/SelectYear";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const { Text } = Typography;

const { Option } = Select;

const toDay = moment().format("DD/MM/YYYY");

const user = getUser();

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [productFilter, setProductFilter] = useState("");
  const [year, setYear] = useState(moment().format("YYYY"));

  const [subCategory, setSubCategory] = useState("");
  const [group, setGroup] = useState("");

  const limit = 50;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/daily-rp`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        productFilter: productFilter,
        year: year,
        subCategory,
        group,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      // initialLoad: false,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const productOptions = [
    ..._.map(dataSource.dataProduct, (product) => {
      return { value: product.product_code, text: product.product_name_local };
    }),
  ];

  // const periodOption = [
  //   ..._.map(dataSource.dataPeriod, (period) => {
  //     return {
  //       value: period.period,
  //       text:
  //         moment(period.period).format("MMMM", "LLLL") +
  //         " " +
  //         moment(period.period).format("YYYY"),
  //     };
  //   }),
  // ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานแลก RP รายวัน</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={2} align="left">
              <Text strong>รายการสินค้า</Text>
            </Col>
            <Col span={6} align="left">
              <Select
                className="w-100"
                mode="multiple"
                placeholder="ชื่อสินค้า"
                onChange={(e) => {
                  setProductFilter(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productOptions.map((product, i) => {
                  return (
                    <Option key={i.toString()} value={product.value}>
                      {product.text}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={2} align="middle">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={4} align="middle">
              <SelectBranch
                withAll={user.branch_count > 1}
                allowDisableBranch={true}
                defaultValue={state.filter.branch}
                className="w-100"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
            <Col span={9}>
              <Row gutter={8} type="flex" justify="end">
                <Col>
                  <Button type="primary" onClick={reload}>
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" onClick={reload}>
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={1} align="left">
              <Text strong>ปี</Text>
            </Col>

            <Col span={4} align="left">
              <SelectYear
                className="w-100"
                yearFrom={2014}
                value={year}
                onChange={(e) => {
                  setYear(e);
                }}
              />
            </Col>

            <Col span={2} align="middle">
              <Text strong>Sub Category</Text>
            </Col>

            <Col span={4} align="left">
              <SelectCategory
                className="w-100"
                defaultValue={subCategory}
                withAll
                category="subcategory"
                onChange={(e) => {
                  setSubCategory(e);
                }}
              />
            </Col>

            <Col span={1} align="middle">
              <Text strong>Group</Text>
            </Col>

            <Col span={4} align="left">
              <SelectCategory
                className="w-100"
                defaultValue={group}
                withAll
                category="group"
                onChange={(e) => {
                  setGroup(e);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="period_day"
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="วันที่"
              dataIndex="period_day"
              align="center"
            />
            <Table.Column
              title={"มกราคม "}
              dataIndex="jan_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="กุมภาพันธ์"
              dataIndex="feb_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="มีนาคม"
              dataIndex="mar_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="เมษายน"
              dataIndex="apr_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="พฤษภาคม"
              dataIndex="may_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="มิถุนายน"
              dataIndex="jun_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="กรกฎาคม"
              dataIndex="jul_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="สิงหาคม"
              dataIndex="aug_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="กันยายน"
              dataIndex="sep_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="ตุลาคม"
              dataIndex="oct_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="พฤศจิกายน"
              dataIndex="nov_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
            <Table.Column
              title="ธันวาคม"
              dataIndex="dec_total_redeem"
              align="center"
              render={(text) => {
                if (text == null || text === 0) {
                  return "";
                }
                return numeral(text).format("0,0");
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
