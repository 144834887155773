import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
const VisitFitStudioPaperForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data: branchLists } = useAsync(fitStudioService.getAllFitnessBranch, {
    immediate: true,
  });

  return (
    <div>
      <Controller
        name="formType"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  ประเภท <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  <Select.Option value="visit">เยี่ยมชม</Select.Option>
                  <Select.Option value="experiment">
                    ทดลองใช้บริการ
                  </Select.Option>
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="branchId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="memberStatus"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  สถานะสมาชิก <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  <Select.Option value="legacy_member">
                    เป็นสมาชิกเลกาซี
                  </Select.Option>
                  <Select.Option value="not_legacy_member_have_consultant">
                    ไม่ใช่สมาชิกเลกาซี แบบมีผู้แนะนำ
                  </Select.Option>
                  <Select.Option value="not_legacy_member_no_consultant">
                    ไม่ใช่สมาชิกเลกาซี แบบไม่มีผู้แนะนำ
                  </Select.Option>
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="branchId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="branchId"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  เลือกสาขา <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {branchLists &&
                    branchLists.length > 0 &&
                    branchLists.map((item) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.branchMaster.Name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="branchId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="legacyPartnerCode"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>รหัสสมาชิก</Typography.Text>
              </Col>
              <Col span={19}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัสสมาชิก"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="legacyConsultantPartnerCode"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>รหัสผู้แนะนำ</Typography.Text>
              </Col>
              <Col span={19}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัสผู้แนะนำ"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="idCard"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  รหัสบัตรประชาชน/passport{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัสบัตรประชาชน/passport"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="nameTitle"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  คำนำหน้าชื่อ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  <Select.Option value="mr">นาย</Select.Option>
                  <Select.Option value="mrs">นาง</Select.Option>
                  <Select.Option value="ms">นางสาว</Select.Option>
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="branchId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  ชื่อ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  นามสกุล <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="นามสกุล"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  เบอร์โทรศัพท์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="เบอร์โทรศัพท์"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default VisitFitStudioPaperForm;
