/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Row,
  Table,
  Typography,
  notification,
} from "antd";
import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx-js-style";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectOption from "../../../pos/components/SelectOption";
import SelectProduct from "../../components/SelectProduct";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import SelectCategory from "../../components/SelectCategory";
import useAsync from "../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../services/manage-website.service";

const { Text } = Typography;
const user = getUser();

const initState = {
  filter: {
    date_from: moment(),
    date_to: moment(),
    branch: [user ? user.branch_code : ""],
    query: "",
    category: "",
    subCategory: "",
    group: "",
    page: 1,
    limit: 10,
  },
  summaryModal: {
    visible: false,
  },
};

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [inventoryFilter, setInventoryFilter] = useState("");
  const [productFilter, setProductFilter] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [CategoryText, setCategoryText] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [SubCategoryText, setSubCategoryText] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [GroupText, setGroupText] = useState("");
  const [checkBranch, setCheckBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState([]);
  const limit = 50;
  const toDay = moment().format("DD/MM/YYYY");

  const reduceInventoryFilter = _.join(
    _.reduce(inventoryFilter, (res, key) => [...res, key], []),
    ","
  );

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-current-remain`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        inventoryFilter: reduceInventoryFilter,
        productFilter: productFilter,
        ...state.filter,
        category: selectedCategory,
        subCategory: selectedSubCategory,
        group: selectedGroup,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const inventoryOptions = [
    { value: "00", text: "คลังดี" },
    { value: "01", text: "คลังเสีย" },
    { value: "02", text: "พักคัดแยก" },
    { value: "03", text: "ของหาย" },
    { value: "04", text: "ของยืม" },
    { value: "05", text: "ของคืน" },
    { value: "06", text: "รอตรวจสอบ" },
    { value: "tf", text: "ระหว่างโอนย้าย" },
  ];

  // console.log(
  //   _.keys(
  //     _.omit((data?.data || [])[0], [
  //       "backend_sku_code",
  //       "product_code",
  //       "product_name_en",
  //       "product_name_local",
  //       "row_id",
  //     ])
  //   )
  // );
  useEffect(() => {
    let selected_branch = _.map(
      _.map(state.filter.branch, (i) =>
        _.find(checkBranch, (o) => o.code === i)
      ),
      (n) => (n?.name !== undefined ? n?.name : "ทั้งหมด")
    );

    setSelectedBranch(selected_branch);
  }, [state.filter.branch, checkBranch]);

  const { execute: getAllBranch } = useAsync(
    manageWebsiteService.getAllBranch,
    {
      immediate: true,
      onSuccess: (data) => {
        setCheckBranch(data);
      },
    }
  );

  const exportToExcel = () => {
    let title_table = ["ลำดับ", "รหัสสินค้า", "SKU", "สินค้า", "รวมทั้งหมด"];

    if (inventoryFilter === "" || inventoryFilter.includes("00")) {
      title_table.push("รวมคลังดี");
    }
    if (inventoryFilter === "" || inventoryFilter.includes("01")) {
      title_table.push("รวมคลังเสีย");
    }
    if (inventoryFilter === "" || inventoryFilter.includes("02")) {
      title_table.push("รวมพักคัดแยก");
    }
    if (inventoryFilter === "" || inventoryFilter.includes("03")) {
      title_table.push("รวมของหาย");
    }
    if (inventoryFilter === "" || inventoryFilter.includes("04")) {
      title_table.push("รวมของยืม");
    }
    if (inventoryFilter === "" || inventoryFilter.includes("05")) {
      title_table.push("รวมของคืน");
    }
    if (inventoryFilter === "" || inventoryFilter.includes("06")) {
      title_table.push("รวมรอตรวจสอบ");
    }
    if (inventoryFilter === "" || inventoryFilter.includes("tf")) {
      title_table.push("รวมระหว่างโอนย้าย");
    }

    _.map(
      _.keys(
        _.omit((data?.data || [])[0], [
          "backend_sku_code",
          "product_code",
          "product_name",
          "row_id",
          "total_qty",
          "ของดี",
          "ของเสีย",
          "พักคัดแยก",
          "ของหาย",
          "ของยืม",
          "ของคืน",
          "รอตรวจสอบ",
          "ระหว่างโอนย้าย",
        ])
      ),
      (n) => {
        title_table.push(
          n
            .toLowerCase()
            .replace(/_/g, " ")
            .replace(/(?: |\b)(\w)/g, function (key, p1) {
              return key.toUpperCase();
            })
        );
      }
    );

    const exportData = data?.data || [];
    if (exportData.length !== 0) {
      let excelData = [
        ["รายงานสินค้าคงเหลือ ณ ปัจจุบัน"],
        [
          `Category : ${selectedCategory === "" ? "ทั้งหมด" : CategoryText}`,
          "",
          `Sub Category : ${
            selectedSubCategory === "" ? "ทั้งหมด" : SubCategoryText
          }`,
          "",
          `Group : ${selectedGroup === "" ? "ทั้งหมด" : GroupText}`,
          "",
        ],
        [
          `รายการสินค้า ${productFilter}`,
          "",
          "",
          "",
          "",
          `สาขา ${
            state.filter.branch === ""
              ? "ทั่งหมด"
              : selectedBranch.map((name) => name)
          }`,
          "",
          "",
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
          "",
        ],
        [
          `คลัง ${
            inventoryFilter === ""
              ? "ทั้งหมด"
              : _.map(
                  _.map(inventoryFilter, (i) =>
                    _.find(inventoryOptions, (o) => o.value === i)
                  ),
                  (n) => (n?.text !== undefined ? n?.text : "ทั้งหมด")
                )
          }`,
          "",
          "",
          "",
          "",
          "",
        ],
        [],
        title_table,
      ];

      exportData.forEach((n) => {
        let row_data = [
          { v: n.row_id, s: { alignment: { horizontal: "center" } } },
          { v: n.product_code ? n.product_code : "" },
          { v: n.backend_sku_code ? n.backend_sku_code : "" },
          { v: n.product_name ? n.product_name : "" },
          {
            v: n.total_qty,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          },
        ];

        if (inventoryFilter === "" || inventoryFilter.includes("00")) {
          row_data.push({
            v: n.ของดี,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        if (inventoryFilter === "" || inventoryFilter.includes("01")) {
          row_data.push({
            v: n.ของเสีย,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        if (inventoryFilter === "" || inventoryFilter.includes("02")) {
          row_data.push({
            v: n.พักคัดแยก,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        if (inventoryFilter === "" || inventoryFilter.includes("03")) {
          row_data.push({
            v: n.ของหาย,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        if (inventoryFilter === "" || inventoryFilter.includes("04")) {
          row_data.push({
            v: n.ของยืม,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        if (inventoryFilter === "" || inventoryFilter.includes("05")) {
          row_data.push({
            v: n.ของคืน,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        if (inventoryFilter === "" || inventoryFilter.includes("06")) {
          row_data.push({
            v: n.รอตรวจสอบ,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        if (inventoryFilter === "" || inventoryFilter.includes("tf")) {
          row_data.push({
            v: n.ระหว่างโอนย้าย ? n.ระหว่างโอนย้าย : 0,
            t: "n",
            s: {
              numFmt: "0",
              alignment: { horizontal: "right" },
            },
          });
        }
        _.forEach(Object.keys(n), (branch) => {
          if (
            branch !== "backend_sku_code" &&
            branch !== "product_code" &&
            branch !== "product_name" &&
            branch !== "row_id" &&
            branch !== "total_qty" &&
            branch !== "ของดี" &&
            branch !== "ของเสีย" &&
            branch !== "พักคัดแยก" &&
            branch !== "ของหาย" &&
            branch !== "ของยืม" &&
            branch !== "ของคืน" &&
            branch !== "รอตรวจสอบ" &&
            branch !== "ระหว่างโอนย้าย"
          ) {
            row_data.push({
              v: n[branch],
              t: "n",
              s: {
                numFmt: "0",
                alignment: { horizontal: "right" },
              },
            });
          }
        });
        excelData = [...excelData, row_data];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },

        { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
        { s: { r: 2, c: 5 }, e: { r: 2, c: 9 } },
        { s: { r: 2, c: 10 }, e: { r: 2, c: 13 } },

        { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
      ];
      const wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานสินค้าคงเหลือ ณ ปัจจุบัน");
      XLSX.writeFile(wb, "รายงานสินค้าคงเหลือ ณ ปัจจุบัน.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสินค้าคงเหลือ ณ.ปัจจุบัน</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={1}>
              <Text strong>Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category="category"
                withAll
                defaultValue={state.filter.category}
                onChange={(value, e) => {
                  setSelectedCategory(value);
                  setSelectedSubCategory("");
                  setSelectedGroup("");
                  setCategoryText(e.props.children);
                }}
              />
            </Col>
            <Col span={2} align="center">
              <Text strong>Sub Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category={`subcategory?CategoryId=${selectedCategory}`}
                withAll
                defaultValue={state.filter.subCategory}
                value={selectedSubCategory}
                onChange={(value, e) => {
                  setSelectedSubCategory(value);
                  setSelectedGroup("");
                  setSubCategoryText(e.props.children);
                }}
              />
            </Col>
            <Col span={1} align="center">
              <Text strong>Group</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category={`group?SubCategoryId=${selectedSubCategory}`}
                withAll
                defaultValue={state.filter.group}
                value={selectedGroup}
                onChange={(value, e) => {
                  setSelectedGroup(value);
                  setGroupText(e.props.children);
                }}
              />
            </Col>

            <Col span={10}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      reload();
                      setPage(1);
                      // exportReload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} align="left">
              <Text strong>รายการสินค้า</Text>
            </Col>
            <Col span={6}>
              <SelectProduct
                //showSearch
                mode="multiple"
                defaultValue={productFilter}
                onChange={(e) => {
                  setProductFilter(e);
                }}
              />
            </Col>

            <Col span={1} align="rigth">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={12}>
              <SelectBranch
                allowClear
                userLogin={true}
                defaultValue={user.branch_code}
                className="w-100"
                mode="multiple"
                allowDisableBranch
                withAll={user.branch_count > 1}
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
          </Row>

          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={1}>
              <Text strong>คลัง</Text>
            </Col>
            <Col span={12}>
              <SelectOption
                allowClear
                className="w-100"
                withAll
                defaultValue={[""]}
                mode="multiple"
                options={inventoryOptions}
                onChange={(e) => {
                  setInventoryFilter(e);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data?.data || []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data?.data?.length : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column dataIndex="row_id" align="center" title="ลำดับที่" />
            <Table.Column
              title="รหัสสินค้า"
              align="center"
              dataIndex="product_code"
              //rowKey="product_code"
            />
            <Table.Column
              title="SKU"
              align="center"
              dataIndex="backend_sku_code"
              //rowKey="backend_sku_code"
            />
            <Table.Column
              title="สินค้า"
              align="left"
              dataIndex="product_name"
              //rowKey="product_name"
            />
            <Table.Column
              title="ยอดรวมทั้งหมด"
              align="center"
              dataIndex="total_qty"
              //rowKey="total_qty"
            />
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("00")) && (
              <Table.Column
                title="รวมคลังดี"
                align="center"
                dataIndex="ของดี"
                //rowKey="ของดี"
              />
            )}
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("01")) && (
              <Table.Column
                title="รวมคลังเสีย"
                align="center"
                dataIndex="ของเสีย"
                // rowKey="ของเสีย"
              />
            )}
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("02")) && (
              <Table.Column
                title="รวมพักคัดแยก"
                align="center"
                dataIndex="พักคัดแยก"
                //rowKey="พักคัดแยก"
              />
            )}
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("03")) && (
              <Table.Column
                title="รวมของหาย"
                align="center"
                dataIndex="ของหาย"
                //rowKey="ของหาย"
              />
            )}
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("04")) && (
              <Table.Column
                title="รวมของยืม"
                align="center"
                dataIndex="ของยืม"
                //rowKey="ของยืม"
              />
            )}
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("05")) && (
              <Table.Column
                title="รวมของคืน"
                align="center"
                dataIndex="ของคืน"
                //rowKey="ของคืน"
              />
            )}
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("06")) && (
              <Table.Column
                title="รวมรอตรวจสอบ"
                align="center"
                dataIndex="รอตรวจสอบ"
                //rowKey="รอตรวจสอบ"
              />
            )}
            {(inventoryFilter.includes("") ||
              inventoryFilter.includes("tf")) && (
              <Table.Column
                title="รวมระหว่างโอนย้าย"
                align="center"
                dataIndex="ระหว่างโอนย้าย"
                render={(text)=>{
                  return text ? text : 0
                }}
                //rowKey="ระหว่างโอนย้าย"
              />
            )}
            {_.map(
              _.keys(
                _.omit((data?.data || [])[0], [
                  "backend_sku_code",
                  "product_code",
                  "product_name",
                  "row_id",
                  "total_qty",
                  "ของดี",
                  "ของเสีย",
                  "พักคัดแยก",
                  "ของหาย",
                  "ของยืม",
                  "ของคืน",
                  "รอตรวจสอบ",
                  "ระหว่างโอนย้าย",
                ])
              ),
              (n) => {
                return (
                  <Table.Column
                    title={n
                      .toLowerCase()
                      .replace(/_/g, " ")
                      .replace(/(?: |\b)(\w)/g, function (key, p1) {
                        return key.toUpperCase();
                      })}
                    align="center"
                    key={n}
                    dataIndex={n}
                  />
                );
              }
            )}
            {
              // backend_sku_code
              // product_code
              // product_name_en
              // product_name_local
              // row_id
            }
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
