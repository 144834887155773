/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Pagination, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useHttp } from "../../../../../../hooks/http";
import { moment, URL_API } from "../../../../../../utils";
import MemberProfileContext from "../reducer";
import Table from "./Table";

const limit = 10;
const MemberHistory = () => {
  const {
    state: {
      data: { partner_code, ref_id, ref_type },
    },
    dispatch,
  } = useContext(MemberProfileContext);
  const [page, setPage] = useState(1);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/member-profile/member-history`,
      token: true,
      initialLoad: false,
      params: {
        limit,
        offset: (page - 1) * limit,
        ref_id,
        ref_type,
        partner_code,
      },
    },
    []
  );

  useEffect(() => {
    if (page && partner_code && ref_id && ref_type) reload();
  }, [page, partner_code, ref_id, ref_type]);

  if (error) return <Alert type="error" message={error} showIcon />;

  const columns = [
    {
      title: "รหัสนักธุรกิจ",
      dataIndex: "partner_code",
      width: "15%",
    },
    {
      title: "ชื่อนักธุรกิจ",
      dataIndex: "partner_name",
      width: "45%",
    },
    {
      title: "สถานะ",
      dataIndex: "status_text",
      width: "20%",
    },
    {
      title: "วันที่",
      dataIndex: "create_date",
      width: "15%",
      render: (text) => text && moment(text).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "",
      align: "right",
      render: (_text, { partner_code, ref_id, ref_type }) => (
        <Tooltip title="ดูรายละเอียด">
          <Button
            type="link"
            size="small"
            className="text-dark"
            onClick={() => {
              dispatch({
                type: "setGlobalState",
                payload: {
                  name: "previewData",
                  value: { partner_code, ref_id, ref_type },
                },
              });
            }}
          >
            <FaEye />
          </Button>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data?.data || []}
        columns={columns}
        loading={loading}
      />
      {data && data.total !== 0 && (
        <Pagination
          size="small"
          hideOnSinglePage
          pageSize={limit}
          current={page}
          total={data.total}
          onChange={(e) => setPage(e)}
          className="text-center mt-2"
        />
      )}
    </>
  );
};

export default MemberHistory;
