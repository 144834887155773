import { Button, Col, Icon, Modal, Row, Select, Table, Upload } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import xlsx from "xlsx";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import { createReducer } from "@reduxjs/toolkit";
import numeral from "numeral";
import { axios } from "../../../utils/useAxios";
import { useApi } from "../../../hooks/http2";
import { URL_API } from "../../../utils";
import dayjs from "dayjs";
import { FaEye } from "react-icons/fa";
const INIT_STATE = {
  reading: false,
  saving: false,
  // search:{
    limit: 10,
    page: 1,
  // },
  excel: {
    file_name: "",
    sheet: "",
  },
  data: [],
  modal: {
    show: false,
    detail: {
      file: null,
      data: [],
    },
  },
};
const reducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase("CLEAR", (state) => {
      state.reading = false;
      state.saving = false;
      state.page = 1;
      state.excel = {
        ...INIT_STATE.excel,
      };
      state.data = [];
    })
    .addCase("SET_SEARCH", (state, { payload }) => {
      state = Object.assign(state,payload);
    })
    .addCase("SET_READING", (state, { payload }) => {
      state.reading = payload;
    })
    .addCase("SET_READ_SUCCESS", (state, { payload }) => {
      state.reading = false;
      state.excel.sheet = payload.sheet;
      state.excel.file_name = payload.file_name;
      state.data = payload.data;
    })
    .addCase("SET_MODAL_SHOW", (state, { payload }) => {
      state.modal.show = true;
      state.modal.detail = payload;
    })
    .addCase("SET_MODAL_HIDE", (state) => {
      state.modal = { ...INIT_STATE.modal };
    })
    .addDefaultCase(() => {});
});

const PageIndex = () => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const list = useApi(
    {
      url: `/tracking`,
      baseURL: URL_API,
      token: true,
      params: {
        limit: state.limit,
        offset: (state.page - 1) * state.limit,
      },
    },
    [state.limit, state.page]
  );

  const handleImportExcel = (file) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    const file_name = file.name;
    reader.onload = () => {
      const data = new Uint8Array(reader.result);
      const wb = xlsx.read(data, { type: "array" });
      let sheet = wb.SheetNames[0];
      if (wb.SheetNames.length > 1) {
        return Modal.confirm({
          title: "กรุณาเลือก Sheet",
          content: (
            <>
              <Row gutter={16} className="mb-2">
                <Col>
                  เนื่องจากมี Sheet มากกว่า 1 Sheet
                  <br />
                  กรุณาเลือก Sheet ที่ต้องการ import
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    className="w-100"
                    defaultValue={sheet}
                    onChange={(e) => (sheet = e)}
                  >
                    {wb.SheetNames.map((n, i) => (
                      <Select.Option key={i} value={n}>
                        {n}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </>
          ),
          onOk: () => {
            readFileBySheet({ sheet, workbook: wb }, file_name);
          },
        });
      } else {
        readFileBySheet({ sheet, workbook: wb }, file_name);
      }
    };
  };

  const readFileBySheet = async ({ sheet, workbook }, file_name) => {
    dispatch({ type: "SET_READING", payload: true });
    const json = xlsx.utils.sheet_to_json(workbook.Sheets[sheet], {
      raw: true,
      defval: null,
    });
    dispatch({
      type: "SET_READ_SUCCESS",
      payload: {
        sheet,
        file_name,
        data: json,
      },
    });

    handleSendExcelData(json, file_name, sheet);
  };

  const handleSendExcelData = async (data, file_name, sheet) => {
    const column = [];
    for (const property in data[0]) {
      column.push({
        title: property,
        dataIndex: property,
        key: property,
      });
    }

    Modal.confirm({
      title: "นำเข้าข้ออมูล",
      width: 850,
      content: <TableDynamicCol dataSource={data} />,
      maskClosable: false,
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            const _res = await axios.post(`/tracking`, {
              file_name,
              sheet,
              data,
            });
            resolve(_res.data);
          } catch (error) {
            reject(error);
          }
        })
          .then((data) => {
            Modal.success({
              title: "ผลการนำเข้าข้อมูล",
              maskClosable: false,
              width: 850,
              content: (
                <>
                  <div className="d-flex justify-content-end pb-2">
                    {data.filter((n) => n.status !== "success").length > 0 && (
                      <Button
                        onClick={() =>
                          handleExportExcel(
                            data.filter((n) => n.status !== "success"),
                            file_name
                          )
                        }
                      >
                        Export Failed List
                      </Button>
                    )}
                  </div>

                  <TableDynamicCol dataSource={data} />
                  <div>
                    <Row gutter={16}>
                      <Col className="text-success">
                        นำเข้าสำเร็จ:{" "}
                        {data.filter((n) => n.status === "success").length}{" "}
                        รายการ
                      </Col>
                      <Col className="text-danger">
                        นำเข้าไม่สำเร็จ:{" "}
                        {data.filter((n) => n.status !== "success").length}{" "}
                        รายการ
                      </Col>
                    </Row>
                  </div>
                </>
              ),
              okText: "ปิด",
              onOk() {
                list.reload();
              },
            });
          })
          .catch((e) => {
            Modal.error({
              title: "ผิดพลาด",
              content: e.message,
              okText: "ตกลง",
            });
          });
      },
      onCancel() {},
      cancelText: "ยกเลิก",
      okText: "ยืนยัน",
    });
  };

  const handleExportExcel = (data_list, file_name) => {
    const ws = xlsx.utils.json_to_sheet(data_list);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "list");
    xlsx.writeFile(wb, "[failed] - " + file_name);
  };

  const handleShowDetail = async (id) => {
    try {
      const { data } = await axios.get(`/tracking/${id}`);
      console.log(data);
      dispatch({ type: "SET_MODAL_SHOW", payload: data });
    } catch (error) {}
  };

  return (
    <>
      <Panel>
        <PanelHeader>ข้อมูลการจัดส่ง</PanelHeader>
        <PanelBody>
          <Row gutter={16} className="mb-2">
            <Col md={24} className="text-right">
              <Upload
                accept=".xls,.xlsx"
                // customRequest={dummyRequest}
                beforeUpload={handleImportExcel}
                showUploadList={false}
              >
                <Button type="primary" ghost>
                  Import Excel
                </Button>
              </Upload>
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={list.loading}
            dataSource={list.fetch?.data || []}
            pagination={{
              size: "default",
              current: parseInt(state.page),
              pageSize: state.limit,
              total: list.fetch?.total || 0,
              onChange: (currPage, pageSize) =>
                // console.log(currPage),
                dispatch({
                  type: "SET_SEARCH",
                  payload:{
                    page: currPage,
                    limit: pageSize,
                  }
                }),
            }}
            rowKey={"id"}
          >
            <Table.Column title="#" key="no" dataIndex="row_id" align="right" />
            <Table.Column title="ไฟล์" key="file_name" dataIndex="file_name" />
            <Table.Column title="ชีท" key="sheet" dataIndex="sheet" />
            <Table.Column title="โดย" key="create_by" dataIndex="create_by" />
            <Table.Column
              title="วันที่"
              key="create_date"
              dataIndex="create_date"
              align="center"
              render={(text) => dayjs(text).format("DD/MM/YYYY HH:mm")}
            />
            <Table.Column
              title=""
              key="action"
              dataIndex="id"
              align="center"
              render={(id) => (
                <Button
                  type="primary"
                  shape="circle"
                  size="small"
                  onClick={() => handleShowDetail(id)}
                >
                  <FaEye />
                </Button>
              )}
            />
          </Table>
        </PanelBody>
      </Panel>
      <Modal
        visible={state.modal.show}
        title="รายละเอียด"
        footer={false}
        width={850}
        onCancel={() => dispatch({ type: "SET_MODAL_HIDE" })}
        destroyOnClose
      >
        <Row gutter={16}>
          <Col md={12}>
            <p className="mb-0">ไฟล์: {state.modal.detail?.file?.file_name}</p>
            <p>ชีท: {state.modal.detail?.file?.sheet}</p>
          </Col>
          <Col md={12} className="text-right">
            {(state.modal.detail?.data || []).filter(
              (n) => n.status !== "success"
            ).length > 0 && (
              <Button
                onClick={() =>
                  handleExportExcel(
                    (state.modal.detail?.data || []).filter(
                      (n) => n.status !== "success"
                    ),
                    state.modal.detail?.file?.file_name
                  )
                }
              >
                Export Failed List
              </Button>
            )}
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          dataSource={state.modal.detail?.data || []}
          rowKey="__rowNum__"
          scroll={{ x: "max-content" }}
          pagination={{
            size: "default",
            showLessItems: true,
            showTotal: (total, range) =>
              `${numeral(range[0]).format()} - ${numeral(
                range[1]
              ).format()} of ${numeral(total).format()}`,
          }}
        >
          <Table.Column
            title="No."
            key="no"
            align="right"
            render={(text, record, index) => index + 1}
          />
          <Table.Column
            title="Document No"
            dataIndex="document_no"
            align="center"
          />
          <Table.Column
            title="Consignment"
            dataIndex="consignment"
            align="center"
          />
          <Table.Column
            title="Transport"
            dataIndex="transport_name"
            align="center"
          />
          <Table.Column
            title="Status"
            dataIndex="status"
            align="center"
            render={(text) => (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {text === "success" ? (
                  <>
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#52c41a"
                    />
                    {` สำเร็จ`}
                  </>
                ) : (
                  <>
                    <Icon
                      type="close-circle"
                      theme="twoTone"
                      twoToneColor="#ff0000"
                    />
                    {` ไม่สำเร็จ`}
                  </>
                )}
              </span>
            )}
          />
        </Table>
        <Row gutter={16}>
          <Col className="text-success">
            นำเข้าสำเร็จ:{" "}
            {
              (state.modal.detail?.data || []).filter(
                (n) => n.status === "success"
              ).length
            }{" "}
            รายการ
          </Col>
          <Col className="text-danger">
            นำเข้าไม่สำเร็จ:{" "}
            {
              (state.modal.detail?.data || []).filter(
                (n) => n.status !== "success"
              ).length
            }{" "}
            รายการ
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const TableDynamicCol = ({ dataSource }) => {
  const [column, setColumn] = useState([]);

  useEffect(() => {
    const col = [];
    for (const property in dataSource[0]) {
      const colProps = {
        title: property,
        dataIndex: property,
        key: property,
      };

      if (property === "status") {
        colProps.render = (text) => (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {text === "success" ? (
              <>
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
                {` สำเร็จ`}
              </>
            ) : (
              <>
                <Icon
                  type="close-circle"
                  theme="twoTone"
                  twoToneColor="#ff0000"
                />
                {` ไม่สำเร็จ`}
              </>
            )}
          </span>
        );
      }

      col.push(colProps);
    }
    setColumn(col);
  }, [dataSource]);

  return (
    <Table
      bordered
      size="small"
      dataSource={dataSource}
      rowKey="__rowNum__"
      scroll={{ x: "max-content" }}
      columns={column}
      pagination={{
        size: "default",
        showLessItems: true,
        showTotal: (total, range) =>
          `${numeral(range[0]).format()} - ${numeral(
            range[1]
          ).format()} of ${numeral(total).format()}`,
      }}
    />
  );
};

export default PageIndex;
