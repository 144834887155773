/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Typography } from 'antd'

const Tabs = ({ active, onClick, list }) => {
    
    return (
        <ul className="full-tabs" style={{justifyContent: 'start'}}>
            {
                list.map((n, i) => (
                    <li key={i} className={`${active === n.value ? 'active' : ''} mr-4`} onClick={() => onClick(n.value)}>
                        <Typography.Title level={4}><a style={{fontSize: '13px'}}>{n.title}</a></Typography.Title>
                    </li>
                ))
            }
        </ul>
    )
}

export default Tabs