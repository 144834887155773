import React from "react";
import { EventMutateProvider } from "../../../../contexts/events.context";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import TableEvent from "./components/TableEvent";
import CriteriaEvent from "./components/CriteriaEvent";

const EventList = () => {
  return (
    <EventMutateProvider>
      <Panel>
        <PanelHeader>งานอีเวนท์</PanelHeader>
        <PanelBody>
          <CriteriaEvent />
          <TableEvent />
        </PanelBody>
      </Panel>
    </EventMutateProvider>
  );
};
export default EventList;
