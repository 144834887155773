/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row } from "antd";
import _ from "lodash";
import React, { forwardRef, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectBank from "../../../../../components/SelectBank";
import SelectBankBranch from "../../../../../components/SelectBankBranch";
import { actionEthics } from "../../../../../redux/slices/ethics";

function FormBank({ form, eft_type }) {
  // const bank = useSelector(
  //   (state) => state.ethics.form.bank,
  //   shallowEqual
  // );
  const dispatch = useDispatch();
  const [bankCode, setBankCode] = useState();

  const { member, bank } = useSelector(
    (state) => state.ethics.form,
    shallowEqual
  );

  const { SET_FORM_BANK, DELETE_FORM_BANK } = actionEthics;

  const handelBanAccountkName = (bank_code) => {
    let name = null;
    if (eft_type == "Person") {
      if (member?.nationality == "TH") {
        name = `${member?.first_name_th} ${member?.sur_name_th}`;
      } else {
        name = `${member?.first_name_en} ${member?.sur_name_en}`;
      }
    } else if (eft_type == "Company") {
      name = `${member?.company_name}`;
    }
    form.setFieldsValue({
      account_name: bank_code ? name : undefined,
    });
    dispatch(
      SET_FORM_BANK({
        eft_type: eft_type,
        bank_code: bank_code ? bank_code : undefined,
        partner_code: member?.member_code,
        account_name: bank_code ? name : undefined,
        account_number: undefined,
        bank_branch: undefined,
      })
    );
  };

  useEffect(() => {
    form.resetFields();

    if (bank) {
      const _data = _.find(bank, (n) => n.eft_type === eft_type);
      if (_data) {
        form.setFieldsValue({
          bank_code: _data.bank_code,
          account_name: _data.account_name,
          account_number: _data.account_number,
          bank_branch: _data.bank_branch,
        });
        setBankCode(_data.bank_code);
      }

      // handleChange();
    }
  }, [bank, eft_type]);

  const { getFieldDecorator } = form;

  return (
    <Form layout="vertical" labelAlign="right" className="form-small">
      <Row gutter={8}>
        <Col md={6}>
          <Form.Item label="ธนาคาร" className="mb-0">
            {getFieldDecorator("bank_code", {
              initialValue: null,
              //rules: [{ required:  true, message: "กรุณาระบุธนาคาร" }],
              onChange: (e, option) => {
                form.setFieldsValue({
                  bank_code: e,
                  bank_branch: "",
                  partner_code: member?.member_code,
                  account_number: "",
                });
                // if(eft_type == "Company"){
                //   dispatch(SET_FORM_BANK({
                //     eft_type : eft_type,
                //     bank_code : e,
                //     account_name : member.company_name,
                //     partner_code : member.member_code
                //   }))
                // }
                // if(eft_type == "Person"){
                //   dispatch(SET_FORM_BANK({
                //       eft_type : eft_type,
                //       bank_code : e,
                //       account_name : `${member.first_name_th } ${member.sur_name_th}`,
                //       partner_code : member.member_code
                //     }))
                // }

                handelBanAccountkName(e);
              },
            })(
              <SelectBank
                size="small"
                className="w-100 font-size-12"
                showSearch
                withIcon={true}
                filterOption={(input, option) =>
                  option.props.source.name_th.indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
            )}
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="ชื่อบัญชี" className="mb-0">
            {getFieldDecorator("account_name", {
              initialValue: null,
              rules: [
                {
                  required: form.getFieldValue("bank_code"),
                  message: "กรุณาระบุชื่อบัญชีของธนาคาร",
                },
              ],
              onChange: (e) => {
                dispatch(
                  SET_FORM_BANK({
                    eft_type: eft_type,
                    account_name: e.target.value,
                    bank_branch: undefined,
                    account_number: undefined,
                  })
                );
              },
            })(
              <Input
                size="small"
                placeholder="ชื่อบัญชี"
                className="font-size-12"
              />
            )}
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="เลขที่บัญชี" className="mb-0">
            {getFieldDecorator("account_number", {
              initialValue: null,
              rules: [
                {
                  required: form.getFieldValue("bank_code"),
                  message: "กรุณาระบุเลขที่บัญชีธนาคาร",
                },
              ],
              onChange: (e) => {
                dispatch(
                  SET_FORM_BANK({
                    eft_type: eft_type,
                    account_number: e.target.value,
                  })
                );
              },
            })(
              <Input
                size="small"
                placeholder="เลขที่บัญชี"
                className="font-size-12"
              />
            )}
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="สาขา" className="mb-0">
            {getFieldDecorator("bank_branch", {
              initialValue: null,
              rules: [
                {
                  required:
                    form.getFieldValue("bank_code") &&
                    form.getFieldValue("bank_code") != -1,
                  message: "กรุณาระบุสาขาของธนาคาร",
                },
              ],
              onChange: (e) => {
                dispatch(SET_FORM_BANK({ eft_type: eft_type, bank_branch: e }));
              },
            })(
              <SelectBankBranch
                showSearch
                bank_code={form.getFieldValue("bank_code")}
                size="small"
                className="font-size-12"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col className="text-right">
          <Button
            type="danger"
            ghost
            size="small"
            disabled={!form.getFieldValue("bank_code")}
            onClick={() => {
              dispatch(DELETE_FORM_BANK({ eft_type }));
              form.resetFields();
            }}
          >
            ลบข้อมูล
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default Form.create("ethics-bank")(forwardRef(FormBank));
