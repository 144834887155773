/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Tooltip,
  Typography
} from "antd";
import axios from "axios";
import React, { useCallback, useRef, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import SelectCallingCode from "../../../../components/SelectCallingCode";
import { URL_API } from "../../../../utils";

const InputMobile = ({ value = [], onChange = () => null, ...props }) => {
  const mobilRef = useRef(null);
  const [editId, setEditId] = useState();

  const handleSubmit = useCallback(() => {
    mobilRef.current.validateFieldsAndScroll(async (err, res) => {
      if (!err) {
        if (editId) {
          let temp = [...value];
          const index = temp.findIndex((n) => n.id === editId);
          if (~index) {
            temp[index] = { ...temp[index], ...res };
            onChange([...temp]);
            setEditId(null);
            mobilRef.current.resetFields();
          }
        } else {
          let find = value?.find(
            (n) => n.code === res.code && n.mobile === res.mobile
          );
          if (!find) {
            await axios({
              url: `${URL_API}/pos/master/check/mobile`,
              params: { ...res },
            })
              .then((res) => {
                find = res.data.data.used;
              })
              .catch((err) => message.error(err));
          }
          if (find) {
            notification.warning({
              message: "กรุณาตรวจสอบรายการ",
              description: "หมายเลขนี้ถูกใช้งานไปแล้ว",
            });
          } else {
            onChange([...(value || []), { ...res }]);
            mobilRef.current.resetFields();
          }
        }
      }
    });
  }, [mobilRef.current, value, editId]);

  const handleCancel = useCallback(() => {
    setEditId(null);
    mobilRef.current.resetFields();
  }, [mobilRef.current]);

  const handleDelete = (e) => {
    let temp = [...value];
    const index = temp.findIndex(
      (n) => n.code === e.code && n.mobile === e.mobile
    );
    if (~index) {
      temp.splice(index, 1);
      onChange(temp);
    }
  };

  const handleEdit = (e) => {
    setEditId(e.id);
    mobilRef.current.setFieldsValue(e);
  };

  return (
    <div {...props}>
      {value?.map((n, i) => (
        <Row gutter={8} key={i}>
          <Col span={6}>
            <Typography.Text>{n.code}</Typography.Text>
          </Col>
          <Col
            span={18}
            className="d-flex align-items-center justify-content-between"
          >
            <Typography.Text>{n.mobile}</Typography.Text>
            <Col>
              {n.id && (
                <Tooltip title="แก้ไข">
                  <FaPencilAlt
                    size={16}
                    className="cursor-pointer ml-2"
                    onClick={() => handleEdit(n)}
                  />
                </Tooltip>
              )}
              <Popconfirm
                placement="topRight"
                title={`ต้องการลบรายการนี้หรือไม่`}
                onConfirm={() => handleDelete(n)}
                okText="ลบ"
                cancelText="ไม่ลบ"
              >
                <Tooltip title="ลบ" placement="right">
                  <MdDelete size={16} className="cursor-pointer ml-2" />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Col>
        </Row>
      ))}
      <FormData
        ref={mobilRef}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        editId={editId}
      />
    </div>
  );
};

const FormData = React.memo(
  Form.create()(
    ({ form: { getFieldDecorator }, onSubmit, onCancel, editId }) => (
      <Form name="mobile" className="mb-0">
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item className="mb-0">
              {getFieldDecorator("code", {
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกโค้ดประเทศ",
                  },
                ],
              })(
                <SelectCallingCode
                  size="small"
                  name="calling-code"
                  className="width-100"
                  placeholder="เลือกรหัสประเทศ"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={18} className="d-flex align-items-center">
            <Form.Item className="mb-0 w-100">
              {getFieldDecorator("mobile", {
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกเบอร์โทรศัพท์",
                  },
                  {
                    pattern: /[0-9]{8,9}/,
                    message: "กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง",
                  },
                ],
              })(
                <Input
                  onKeyUp={(e) => e.key === "Enter" && onSubmit()}
                  maxLength={9}
                  autoComplete="off"
                  size="small"
                  placeholder="เบอร์โทร ตัวอย่าง 812345678"
                />
              )}
            </Form.Item>
            <Button className="bg-pv ml-2" size="small" onClick={onSubmit}>
              <Typography.Text className="text-white">
                {editId ? "บันทึก" : "เพิ่ม"}
              </Typography.Text>
            </Button>
            {editId && (
              <Button className="bg-reds ml-2" size="small" onClick={onCancel}>
                <Typography.Text className="text-white">ยกเลิก</Typography.Text>
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    )
  )
);

export default React.memo(InputMobile);
