/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Spin,
  Typography,
} from "antd";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { FaGift } from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fixProductTypeSet } from "../../../../config/fix-value";
import { axios, URL_API, _ } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { getDataCheckStock } from "../ducks/logic/data-check-stock";
import { Creators } from "../ducks/operation-form";
import RecPromotion from "./RecPromotion/index";
import { getBannedSoldOut } from "./RecPromotion/logic/data-check-stock";
import {
  isProAmount,
  isProItem,
  isProItemWithAmount,
  isProAmountOnly,
} from "./RecPromotion/logic/validate";

const getUnit = ({ itemKeys, itemByKey }) =>
  _.reduce(
    itemKeys,
    (sum, key) => ({
      price: sum.price + itemByKey[key].qty * itemByKey[key].unit_price,
      pv: sum.pv + itemByKey[key].qty * itemByKey[key].pv,
      rp: sum.rp + itemByKey[key].qty * itemByKey[key].rp,
    }),
    {
      price: 0,
      pv: 0,
      rp: 0,
    }
  );

const calAmountUsing = (items) => {
  let amount_used = { price: 0, pv: 0, rp: 0 };
  let every_amount_used = { price: 0, pv: 0, rp: 0 };
  for (let item_index = 0; item_index < items.length; item_index++) {
    const { condition_type, item_key, ...item } = items[item_index];
    if (condition_type === "amount") {
      amount_used[item_key] += item.qty;
    } else if (condition_type === "every-amount") {
      every_amount_used[item_key] += item.qty;
    }
  }
  return { amount_used, every_amount_used };
};

const initialPromotion = {
  visible: false,
  data: [],
  title: "",
};

const ButtonLoading = ({ title }) => (
  <button
    type="button"
    disabled
    className="pos-btn bg-gray-20 text-gray-60 w-100 cursor-no-drop"
    style={{ maxWidth: "200px" }}
  >
    <Spin spinning size="small" className="mr-2" />
    {title}
  </button>
);

const PosOrderFooter = ({ orders, match, ...props }) => {
  const type_params = match.params.type;
  const [showRecPromotion, setShowRecPromotion] = useState(initialPromotion);
  const [recPromotion, setRecPromotion] = useState([]);
  const [orderItem, setOrderItem] = useState({ itemKeys: [], itemByKey: {} });
  const [orderPromotionList, setOrderPromotionList] = useState([]);
  const [source, setSource] = useState(null);
  const promotion_amount = _.filter(
    recPromotion,
    (n) => isProAmount(n.condition.type) && n.order_key === props.activeKey
  );
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );
  const isPreProduct = _.includes(["pre-sale", "pre-redeem"], type_params);

  useEffect(() => {
    return () => source?.cancel("unmounted");
  }, []);

  // uncomment for test promotion only
  // useEffect(() => {
  //   props.setBillValue({
  //     showItems: true,
  //     req_receipt_type: "Transport",
  //     order_type: "Regular",
  //   });
  // }, []);

  useEffect(() => {
    if (props.req_receipt_type === "Branch" && props.total_delivery > 0) {
      props.setBillValue({ total_delivery: 0 });
    }
  }, [props.total_delivery, props.req_receipt_type]);

  useEffect(() => {
    // fix defect mutable order_item for dependency fetch promotion
    if (props.order_item) {
      const { itemKeys, itemByKey } = props.order_item;
      if (
        itemKeys.length !== orderItem.itemKeys.length ||
        _.some(
          itemKeys,
          (key) =>
            !orderItem.itemByKey[key] ||
            itemByKey[key].qty !== orderItem.itemByKey[key].qty
        )
      )
        setOrderItem(props.order_item);
    }
  }, [props.order_item, orderItem]);

  useEffect(() => {
    // fix defect mutable order_promotion for dependency fetch promotion
    if (props.order_promotion_list) {
      if (
        props.order_promotion_list.length !== orderPromotionList.length ||
        _.some(
          props.order_promotion_list,
          (n) =>
            n.qty !==
            (_.find(
              orderPromotionList,
              (m) =>
                n.promotion_id === m.promotion_id &&
                n.source_key === m.source_key &&
                n.source_option === m.source_option
            )?.qty || 0)
        )
      ) {
        setOrderPromotionList(props.order_promotion_list);
      }
    }
  }, [props.order_promotion_list, orderPromotionList]);

  useEffect(() => {
    // if (type_params === "sale") {
    source && source.cancel("cancel");
    setSource(axios.CancelToken.source());
    // }
  }, [
    props.order_type,
    props.req_receipt_type,
    props.receipt_branch_code,
    orderItem,
    orderPromotionList,
    type_params,
  ]);

  useEffect(() => {
    source && handleFindMatchPromotion();
  }, [source]);

  useEffect(() => {
    if (props.promotionProductCode) {
      let promotion_filter = [];
      _.forEach(
        _.filter(
          recPromotion,
          (pro) =>
            isProItem(pro.condition.type) && pro.order_key === props.activeKey
        ),
        (promotion) => {
          let source_temp = [];
          _.forEach(promotion.condition.source, (condition) => {
            const index = _.findIndex(condition, (source) =>
              source.product_code
                ? source.product_code === props.promotionProductCode
                : _.find(
                    source.items,
                    (item) => item.product_code === props.promotionProductCode
                  )
            );
            if (~index) {
              source_temp = [...source_temp, condition];
            }
          });
          if (source_temp.length > 0) {
            promotion_filter = [
              ...promotion_filter,
              {
                ...promotion,
                condition: {
                  ...promotion.condition,
                  source: [...source_temp],
                },
              },
            ];
          }
        }
      );
      setShowRecPromotion({
        visible: true,
        data: promotion_filter,
        title: "สินค้า",
      });
    }
  }, [props.promotionProductCode, recPromotion]);

  const handleFindMatchPromotion = async () => {
    props.setMatchingPromotionItem({ loading: true });
    let compare = [];
    let all_link_bom = {};
    // loop each bill
    for (let i = 0; i < orders.itemKeys.length; i++) {
      const order = orders.itemByKey[orders.itemKeys[i]];
      // if (!order) return;

      // check order have product item
      const ids = order.items.itemKeys.map(
        (n) => order.items.itemByKey[n].product_code
      );
      if (ids.length === 0) continue;

      // check coordinate
      const disabled_promotion = _.some(
        order.promotions.promotion_list,
        (item) => item.coordinate === 0
      );
      if (disabled_promotion) continue;

      // get price, pv, rp in order
      const unit = getUnit(order.items);

      // load promotion
      let result = await handlePromotion(
        ids,
        order.order_type,
        order.req_receipt_type,
        order.receipt_branch_code,
        order.partner_code,
        order.partner_type,
        unit
      );
      
      if (result === "error") return;
      if (!result || !result.ret) continue;

      let { ret, link_bom } = result;
      all_link_bom = { ...all_link_bom, ...link_bom };

      // get amount and every amount is used in order
      let { amount_used, every_amount_used } = calAmountUsing(
        order.promotions.item_list
      );

      // add order qty in promotion condition source
      _.forEach(ret, (promotion, index_promotion) => {
        _.forEach(promotion.condition.source, (condition, index_condition) => {
          _.forEach(condition, (source, index_source) => {
            if (source.source_key === "item") {
              let merge_add = {
                unit_price: 0,
                order_qty: 0,
              };
              const item_key = _.find(
                order.items.itemKeys,
                (item_key) =>
                  order.items.itemByKey[item_key].product_code ===
                  source.product_code
              );
              if (item_key) {
                const { unit_price, qty } = order.items.itemByKey[item_key];
                const used_qty =
                  _.find(
                    order.promotions.item_list,
                    (item_used) =>
                      item_used.condition_type === promotion.condition.type &&
                      item_used.item_key === source.product_code
                  )?.qty || 0;
                merge_add = {
                  unit_price,
                  order_qty:
                    (isProItemWithAmount(promotion.condition.type)
                      ? qty * unit_price
                      : qty) - used_qty,
                };
              }
              ret[index_promotion].condition.source[index_condition][
                index_source
              ] = {
                ...source,
                ...merge_add,
              };
            } else if (source.source_key === "category") {
              const merge_add = _.reduce(
                source.items,
                (items, item) => {
                  let item_merge_add = {
                    unit_price: 0,
                    qty: 0,
                    price: 0,
                  };
                  // check in sub item is same in item, (and condition) between item and category.
                  const not_item = _.every(
                    condition,
                    (other_item) =>
                      other_item.product_code !== item.product_code
                  );
                  if (not_item) {
                    const item_key = _.find(
                      order.items.itemKeys,
                      (item_key) =>
                        order.items.itemByKey[item_key].product_code ===
                        item.product_code
                    );
                    if (item_key) {
                      const { unit_price, qty } =
                        order.items.itemByKey[item_key];
                      const used_qty =
                        _.find(
                          order.promotions.item_list,
                          (item_used) =>
                            item_used.condition_type ===
                              promotion.condition.type &&
                            item_used.item_key === item.product_code
                        )?.qty || 0;
                      item_merge_add = {
                        unit_price,
                        qty:
                          (isProItemWithAmount(promotion.condition.type)
                            ? qty * unit_price
                            : qty) - used_qty,
                      };
                    }
                  }
                  return {
                    items: [
                      ...items.items,
                      {
                        ...item,
                        ...item_merge_add,
                      },
                    ],
                    order_qty: items.order_qty + item_merge_add.qty,
                  };
                },
                {
                  items: [],
                  order_qty: 0,
                }
              );
              ret[index_promotion].condition.source[index_condition][
                index_source
              ] = {
                ...source,
                ...merge_add,
              };
            } else {
              ret[index_promotion].condition.source[index_condition][
                index_source
              ] = {
                ...source,
                order_qty:
                  unit[source.source_key] -
                  amount_used[source.source_key] -
                  every_amount_used[source.source_key],
              };
            }
          });
        });
        _.forEach(promotion.condition.free, (condition, index_condition) => {
          _.forEach(condition, (free, index_free) => {
            if (free.source_key === "item") {
              ret[index_promotion].condition.free[index_condition][index_free] =
                {
                  ...free,
                  ...getBannedSoldOut(
                    free,
                    props.all_items,
                    props.allow_item,
                    props.allow_renew
                  ),
                };
            }
          });
        });
      });

      // remove condition source isn't match
      _.forEach(ret, (promotion, index_promotion) => {
        let temp = [];
        let temp_index = [];
        _.forEach(promotion.condition.source, (condition, index_source) => {
          const index = _.findIndex(
            condition,
            (source) => source.order_qty > 0
          );
          if (~index) {
            temp = [...temp, condition];
            temp_index = [...temp_index, index_source];
          }
        });
        // ret[index_promotion].condition.source = [...temp];
        ret[index_promotion].source_media = _.filter(
          ret[index_promotion].source_media,
          (n, i) => temp_index.includes(parseInt(i))
        );
        ret[index_promotion].condition.source = [...temp];
      });

      // remove promotion when source is empty
      ret = _.filter(ret, (pro) => pro.condition.source.length > 0);

      // get promotion type amount or item-amount (allow one time not every) used
      const buyer_with_amount = _.uniq(
        _.reduce(
          order.promotions.item_list,
          (res, item) =>
            isProAmountOnly(item.condition_type)
              ? [...res, item.promotion_id]
              : res,
          []
        )
      );

      // remove promotion type amount is used in order
      if (buyer_with_amount.length)
        ret = _.filter(ret, (n) => !_.includes(buyer_with_amount, n.id));

      // filter condition and free for step promotion
      _.forEach(ret, (promotion, index_promotion) => {
        if (promotion.is_step === 1) {
          const source_temp = [
            _.maxBy(
              _.filter(ret[index_promotion].condition.source, (list) =>
                _.some(
                  list,
                  (item) => item.qty > 0 && item.order_qty >= item.qty
                )
              ),
              (item) => +item[0].source_option
            ),
          ];
          const free_temp = _.filter(
            ret[index_promotion].condition.free,
            (list) =>
              list[0].ref_source_option === source_temp[0]?.[0].source_option
          );
          ret[index_promotion].condition.source = source_temp;
          ret[index_promotion].condition.free = free_temp;
        }
      });

      // remove promotion out order limit
      ret = _.reduce(
        ret,
        (res, pro) => {
          if (pro.condition.order_limit !== 1) return [...res, pro];
          const option_qty = _.reduce(
            order.promotions.itemByKey[pro.id]?.option.itemKeys,
            (res, option_key) =>
              res +
              order.promotions.itemByKey[pro.id].option.itemByKey[option_key]
                .qty,
            0
          );
          if (pro.condition.order_limit_qty === option_qty) return res;
          return [...res, pro];
        },
        []
      );

      compare = [
        ...compare,
        ..._.reduce(
          ret,
          (value, n) => [...value, { ...n, order_key: orders.itemKeys[i] }],
          []
        ),
      ];
    }

    setRecPromotion(compare);
    const new_promotion_item = _.filter(compare, (n) =>
      isProItem(n.condition.type)
    );
    let item_product_code = _.filter(
      _.uniqWith(
        _.reduce(
          new_promotion_item,
          (product, pro) => [
            ...product,
            ..._.map(
              _.filter(
                _.reduce(
                  pro.condition.source,
                  (condition, source) => [...condition, ...source],
                  []
                ),
                (source) => source.order_qty > 0
              ),
              (m) => ({
                order_key: pro.order_key,
                product_code: m.product_code,
                items: _.map(
                  _.filter(m.items, (o) => o.qty),
                  (o) => ({ product_code: o.product_code })
                ),
              })
            ),
          ],
          []
        ),
        _.isEqual
      ),
      (n) =>
        _.find(orders.itemByKey[n.order_key].items.itemByKey, (m) =>
          n.product_code
            ? n.product_code === m.product_code
            : _.find(n.items, (o) => m.product_code === o.product_code)
        )
    );
    props.setMatchingPromotionItem({
      data: item_product_code,
      link_bom: all_link_bom,
      loading: false,
    });

    if (!props.promotionShowRecommend) return;

    const previous_promotion_item = _.filter(recPromotion, (n) =>
      isProItem(n.condition.type)
    );
    let promotion_item_change =
      previous_promotion_item.length !== new_promotion_item.length;
    if (!promotion_item_change) {
      for (
        let new_index = 0;
        new_index < new_promotion_item.length;
        new_index++
      ) {
        // promotion id change
        if (
          previous_promotion_item[new_index].id !==
          new_promotion_item[new_index].id
        ) {
          promotion_item_change = true;
          break;
        }
        // promotion step option change
        if (
          new_promotion_item[new_index].is_step === 1 &&
          previous_promotion_item[new_index].condition.free[0][0]
            .ref_source_option !==
            new_promotion_item[new_index].condition.free[0][0].ref_source_option
        ) {
          promotion_item_change = true;
          break;
        }
        // add item from promotion
        if (!showRecPromotion.visible) continue;
        for (
          let option_index = 0;
          option_index < new_promotion_item[new_index].condition.source.length;
          option_index++
        ) {
          const option =
            new_promotion_item[new_index].condition.source[option_index];
          let find = false;
          for (let item_index = 0; item_index < option.length; item_index++) {
            if (
              previous_promotion_item[new_index].condition.source[option_index][
                item_index
              ].order_qty !== option[item_index].order_qty
            ) {
              find = true;
              break;
            }
          }
          if (find) {
            setShowRecPromotion({
              visible: true,
              data: new_promotion_item,
              title: "สินค้า",
            });
            return;
          }
        }
      }
    }
    if (promotion_item_change) {
      setShowRecPromotion({
        visible: !!new_promotion_item.length,
        data: new_promotion_item,
        title: "สินค้า",
      });
    } else {
      const new_promotion_amount = _.filter(
        compare,
        (n) => isProAmount(n.condition.type) && n.order_key === props.activeKey
      );
      let promotion_other_change = recPromotion.length !== compare.length;

      if (!promotion_other_change) {
        for (
          let index_new = 0;
          index_new < new_promotion_amount.length;
          index_new++
        ) {
          if (
            promotion_amount[index_new].id !==
            new_promotion_amount[index_new].id
          ) {
            promotion_other_change = true;
            break;
          }
        }
      }
      if (promotion_other_change) {
        setShowRecPromotion({
          visible: new_promotion_amount.length > 0,
          data: new_promotion_amount,
          title: "ท้ายบิล",
        });
      }
    }
  };

  const handlePromotion = async (
    ids = [],
    order_type = "",
    req_receipt_type = "",
    receipt_branch_code = "",
    partner_code = "",
    partner_type = "",
    unit = {}
  ) => {
    try {
      const res = await axios({
        method: "get",
        baseURL: URL_API,
        url: "/pos/master/promotion/detail",
        params: {
          id: ids.join(","),
          partner_code,
          partner_type,
          order_type,
          order_source: "pos",
          req_receipt_type,
          receipt_branch_code,
          ...unit,
        },
        cancelToken: source.token,
      });
      return res.data.data;
    } catch (err) {
      resCatch(err);
      return "error";
    }
  };
  const handleHardPromotion = async (
    items,
    partner_type = "",
  ) => {
    try {
      const res = await axios({
        method: "post",
        baseURL: URL_API,
        url: "/pos/master/promotion/detail/hard",
        data: {
          items:items,
          partner_type,
        },
        cancelToken: source.token,
      });
      return res.data.data;
    } catch (err) {
      resCatch(err);
      return "error";
    }
  };
  const handleClickPayment = async () => {
    if (!orders.itemKeys.length) return;
    for (
      let order_index = 0;
      order_index < orders.itemKeys.length;
      order_index++
    ) {
      const order = orders.itemByKey[orders.itemKeys[order_index]];
      if (isNormal) {
        if (!order.order_type) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "กรุณาเลือกประเภทการซื้อ",
          });
          return;
        }
        if (!order.req_receipt) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "กรุณาเลือกการรับใบเสร็จ",
          });
          return;
        }
        if (!order.req_bag) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "กรุณาเลือกประเภทการรับถุง",
          });
          return;
        }
        
      }
      if (!order.req_receipt_type) {
        notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณาเลือกประเภทการรับของ",
        });
        return;
      }
      if (_.includes(["Transport","Lowcost"],order.req_receipt_type)) {
        if (!order.ship_to_id && order.ship_to_id !== 0) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "กรุณาเลือกที่อยู่ในการจัดส่ง",
          });
          return;
        }
      } else {
        if (!order.receipt_branch_code) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "กรุณาเลือกสาขาที่รับ",
          });
          return;
        }
      }
      if (
        _.some(
          orders.itemKeys,
          (order_key) =>
            !orders.itemByKey[order_key].items.itemKeys.length &&
            !orders.itemByKey[order_key].promotions.promotion_list.length
        )
      ) {
        notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "ต้องมีรายการสินค้าอย่างน้อย 1 รายการ ในการสั่งซื้อ",
        });
        return;
      }

      if (
        !isPreProduct &&
        _.some(orders.itemKeys, (order_key) =>
          _.some(
            orders.itemByKey[order_key].items.itemKeys,
            (item_key) =>
              !_.includes(
                [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
                orders.itemByKey[order_key].items.itemByKey[item_key]
                  .product_type_set
              ) &&
              orders.itemByKey[order_key].items.itemByKey[item_key]
                .stock_balance <= 0
          )
        )
      ) {
        notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "ไม่มีสินค้าบางรายการ (sold out)",
        });
        return;
      }
      if (props.course_ralc > 1 ) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "กรุณาเลือก Course Ralc ได้แค่ 1 Course",
          });
          return;
      }
      const items = order?.items?.itemKeys.map(
        (n) => order?.items?.itemByKey[n]
      );
      let result_promotion = await handleHardPromotion(
        items,
        order.partner_type,
      );
      if (result_promotion) {
        const negativeBalancePromotions = _.filter(result_promotion, (n) => n.promotion_balance < 0);
        const positiveBalancePromotions = _.filter(result_promotion, (n) => n.promotion_balance > 0);
      
        if (negativeBalancePromotions.length && positiveBalancePromotions.length) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "รายการของแถมที่เลือกไม่ตรงกับโปรโมชั่น",
          });
          return;
        }
      
        if (negativeBalancePromotions.length) {
          notification.warning({
            message: "กรุณาปรับปรุงรายการของแถม",
            description: "จำนวนของแถมที่เลือกมาเกินจำนวนที่ได้รับจริง",
          });
          return;
        }
      
        if (positiveBalancePromotions.length) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "ระบุจำนวนของแถมไม่ครบ",
          });
          return;
        }
      }
      
    }

    let qr_warning = false;
    for (let i = 0; i < orders.itemKeys.length; i++) {
      const order_key = orders.itemKeys[i];
      const {
        items: { itemKeys, itemByKey },
        allow_qr,
        promotions: { promotion_list },
      } = orders.itemByKey[order_key];
      if (!allow_qr) continue;
      for (let j = 0; j < itemKeys.length; j++) {
        const item_key = itemKeys[j];
        const { qty, qr, bom = [] } = itemByKey[item_key];
        if (
          qty === (qr?.length || 0) &&
          (bom.length === 0 ||
            _.every(bom, (n) => n.qty * qty === n.qr?.length))
        ) {
          props.setQrWarning({ order_key, item_key, qr_warning: false });
        } else {
          props.setQrWarning({ order_key, item_key, qr_warning: true });
          qr_warning = true;
        }
      }
      for (let j = 0; j < promotion_list.length; j++) {
        const { qty, qr, bom, promotion_id, source_option, product_code } =
          promotion_list[j];
        if (
          qty === (qr?.length || 0) &&
          (bom?.length === 0 ||
            _.every(bom, (n) => n.qty * qty === n.qr?.length))
        ) {
          props.setOrderPromotionItemValue(
            promotion_id,
            source_option,
            product_code,
            {
              qr_warning: false,
            }
          );
        } else {
          props.setOrderPromotionItemValue(
            promotion_id,
            source_option,
            product_code,
            {
              qr_warning: true,
            }
          );
          qr_warning = true;
        }
      }
    }
    // if (qr_warning) {
    //   return notification.warning({
    //     message: "กรุณาตรวจสอบรายการ",
    //     description: "กรุณาสแกน Qr Code สินค้าให้ครบถ้วน",
    //   });
    // }

    if (
      _.some(
        recPromotion,
        (promotion) =>
          promotion.condition.type === "item" ||
          _.some(promotion.condition.source, (option) =>
            _.some(option, (item) => item.order_qty >= item.qty)
          )
      )
    ) {
      Modal.confirm({
        title: "ยืนยันชำระเงิน ?",
        content: "มีโปรโมชันแนะนำบางรายการยังไม่ถูกเลือก",
        onOk() {
          props.setPromotionCollect(
            _.filter(recPromotion, (n) => n.condition.collect === 1)
          );
          props.changeStep(3);
          props.history.replace(`/pos/${type_params}/payment`);
        },
        okText: "ตกลง",
        cancelText: "ยกเลิก",
      });
    } else {
      props.setPromotionCollect(
        _.filter(recPromotion, (n) => n.condition.collect === 1)
      );
      props.changeStep(3);
      props.history.replace(`/pos/${type_params}/payment`);
    }
  };

  const handleClickPromotion = () => {
    setShowRecPromotion({
      visible: true,
      data: promotion_amount,
      title: "ท้ายบิล",
    });
  };

  useEffect(() => {
    if (!isNormal) {
      props.setBillValue({ order_type: "Regular" });
    }
  }, [isNormal]);

  return (
    <>
      <div className="pos-order-footer bg-gray-40 d-flex align-items-start">
        <Row gutter={8} className="w-100">
          <Col span={8}>
            <Row>
              <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                <Typography.Text
                  strong
                  className="mb-3"
                  style={{ fontSize: "20px" }}
                >
                  หมายเหตุ
                </Typography.Text>
                {/* {!props.is_staff && isNormal && (
                  <Typography.Text strong>
                    รวม {props.is_vip_member ? "RP" : "PV"}
                  </Typography.Text>
                )} */}
              </Col>
              <Col span={16} xl={18} xxl={20} className="d-flex flex-column">
                <Input
                  size="small"
                  style={{ marginBottom: "20px" }}
                  value={props.remark}
                  onChange={(e) =>
                    props.setBillValue({ remark: e.target.value })
                  }
                />
                {/* {!props.is_staff && isNormal && (
                  <div>
                    <Typography.Text strong className="mr-5">
                      {numeral(
                        props[props.is_vip_member ? "total_rp" : "total_pv"]
                      ).format("0,0")}
                    </Typography.Text>
                    {!props.is_vip_member && (
                      <>
                        <Typography.Text strong className="mr-3">
                          รวม RP
                        </Typography.Text>
                        <Typography.Text strong>
                          {numeral(props.total_rp).format("0,0")}
                        </Typography.Text>
                      </>
                    )}
                  </div>
                )} */}
              </Col>
            </Row>
            {!props.is_staff && isNormal && (
              <>
                <Row className="d-flex">
                  {!props.is_vip_member && (
                    <>
                      <Col
                        span={8}
                        xl={6}
                        xxl={4}
                        className="d-flex flex-column"
                      >
                        <Typography.Text strong className="mr-3">
                          รวม PV
                        </Typography.Text>
                      </Col>
                      <Col
                        span={8}
                        xl={6}
                        xxl={4}
                        className="d-flex flex-column"
                      >
                        {numeral(props.total_pv).format("0,0.00")}
                      </Col>
                    </>
                  )}
                  <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                    <Typography.Text strong className="mr-3">
                      รวม RP
                    </Typography.Text>
                  </Col>
                  <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                    {numeral(props.total_rp).format("0,0.00")}
                  </Col>
                {!props.is_vip_member && (
                <>
                  <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                    <Typography.Text strong className="mr-3">
                      รวม TP
                    </Typography.Text>
                  </Col>
                  <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                    {numeral(props.total_tp).format("0,0.00")}
                  </Col>
                </>
                )}
                </Row>
                {!props.is_vip_member && (
                  <Row className="d-flex">
                    {/* <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                      <Typography.Text strong className="mr-3">
                        รวม RA
                      </Typography.Text>
                    </Col>
                    <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                      {numeral(props.total_ra).format("0,0.00")}
                    </Col>
                    <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                      <Typography.Text strong className="mr-3">
                        รวม BP
                      </Typography.Text>
                    </Col>
                    <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                      {numeral(props.total_bp).format("0,0.00")}
                    </Col> */}
                    <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                      <Typography.Text strong className="mr-3">
                        รวม EP
                      </Typography.Text>
                    </Col>
                    <Col span={8} xl={6} xxl={4} className="d-flex flex-column">
                      {numeral(props.total_ep).format("0,0.00")}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Col>
          <Col span={11}>
            <Row>
              <Col span={12} className="d-flex flex-column">
                <Row>
                  <Col span={14} className="d-flex flex-column align-items-end">
                    <Typography.Text strong className="mb-3">
                      น้ำหนักรวม (g)
                    </Typography.Text>
                    {isNormal && (
                      <Typography.Text strong>ค่าจัดส่ง</Typography.Text>
                    )}
                  </Col>
                  <Col span={10} className="d-flex flex-column align-items-end">
                    <Typography.Text strong className="mb-3">
                      {numeral(props.total_weight).format("0,0")}
                    </Typography.Text>
                    {isNormal && (
                      <Typography.Text strong>
                        {numeral(props.total_delivery).format("0,0")}
                      </Typography.Text>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={12} className="d-flex flex-column">
                <Row>
                  <Col span={14} className="d-flex flex-column align-items-end">
                    <Typography.Text strong className="mb-3">
                      จำนวนสินค้า
                    </Typography.Text>
                    <Typography.Text strong>{`รวม${
                      isNormal ? "เงิน" : "คะแนน"
                    }`}</Typography.Text>
                  </Col>
                  <Col span={10} className="d-flex flex-column align-items-end">
                    <Typography.Text strong className="mb-3">
                      {numeral(props.total_qty).format("0,0")}
                    </Typography.Text>
                    <Typography.Text strong>
                      {numeral(props.total_amount).format("0,0")}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <Row className="px-2">
              {!!promotion_amount.length && (
                <Col span={24} className="mb-2 text-right">
                  {props.promotionLoading ? (
                    <ButtonLoading title="โปรโมชันท้ายบิล" />
                  ) : (
                    <button
                      type="button"
                      disabled={recPromotion.length === 0}
                      className="pos-btn bg-reds text-white w-100"
                      style={{ maxWidth: "200px" }}
                      onClick={handleClickPromotion}
                    >
                      <FaGift className="mr-2" />
                      โปรโมชันท้ายบิล
                    </button>
                  )}
                </Col>
              )}
              <Col span={24} className="mb-2 text-right">
                {props.loading ? (
                  <ButtonLoading
                    title={
                      _.includes(["sale", "subscription"], type_params)
                        ? "ชำระเงิน"
                        : isPreProduct
                        ? "สั่ง"
                        : "แลก"
                    }
                  />
                ) : (
                  <button
                    type="button"
                    disabled={props.loading}
                    className="pos-btn bg-pv text-white w-100"
                    style={{ maxWidth: "200px" }}
                    onClick={handleClickPayment}
                  >
                    {_.includes(["sale", "subscription"], type_params)
                      ? "ชำระเงิน"
                      : isPreProduct
                      ? "สั่ง"
                      : "แลก"}
                  </button>
                )}
              </Col>
            </Row>
            {/* {
              type_params === 'sale' && (
                <button
                  type="button"
                  className="pos-btn pos-btn-lg bg-black text-white text-nowrap mt-2"
                  onClick={handleClickPromotion}
                >
                  โปรโมชั่น
                </button>
              )
            } */}
            {/* <Row className="mt-2">
              <Col>
                <Button
                  type="danger"
                  size="large"
                  onClick={() => setShowPromotion(true)}
                  className="bg-reds border-reds d-flex align-items-center"
                >
                  <FaGift className="mr-2" /> Promotion
                </Button>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>

      {/* <PromotionPopup
        visible={showPromotion}
        onCancel={() => setShowPromotion(false)}
      /> */}

      <RecPromotion
        dataSource={showRecPromotion}
        onCancel={() => {
          setShowRecPromotion(initialPromotion);
          props.setMatchingPromotionItem({
            product_code: null,
            allow_show_recommend: false,
          });
        }}
      />
    </>
  );
};

const getTotal = (data, type, withQty) => 
  _.reduce(
    data.items.itemByKey,
    (sum, n) => sum + (n[type] || 0) * (withQty ? n.qty || 0 : 1),
    0
  ) +
  _.reduce(
    data.promotions.promotion_list,
    (sum, n) => sum + (n[type] || 0) * (withQty ? n.qty || 0 : 1),
    0
  );

const mapStateToProps = (state) => {
  const {
    orders,
    buyer_type,
    matching_promotion_item: {
      product_code: promotionProductCode,
      allow_show_recommend: promotionShowRecommend,
      loading: promotionLoading,
    },
    loading: { check_stock_balance, delivery, pick_and_pay },
  } = state.pos.operation;
  const { activeKey, itemByKey } = orders;
  const dataCheckStock = getDataCheckStock(state.pos.operation.orders);
  // console.log(orders)
  return {
    ...dataCheckStock,
    activeKey,
    orders,
    loading:
      check_stock_balance || delivery || pick_and_pay || promotionLoading,
    is_staff: buyer_type?.toLowerCase() === "staff",
    is_vip_member:
      itemByKey[activeKey]?.partner_type?.toLowerCase() === "member",
    order_type: itemByKey[activeKey]?.order_type || null,
    req_receipt_type: itemByKey[activeKey]?.req_receipt_type || null,
    receipt_branch_code: itemByKey[activeKey]?.receipt_branch_code || null,
    order_item: itemByKey[activeKey]?.items || null,
    order_promotion_list:
      itemByKey[activeKey]?.promotions.promotion_list || null,
    total_amount:
      itemByKey[activeKey]?.items &&
      getTotal(itemByKey[activeKey], "unit_price", true),
    total_qty:
      itemByKey[activeKey]?.items &&
      _.reduce(
        itemByKey[activeKey]?.items.itemByKey,
        (sum, n) => sum + (n.qty || 0),
        0
      ) +
        _.reduce(
          _.filter(
            itemByKey[activeKey]?.promotions?.promotion_list || [],
            (n) => n.source_key === "item"
          ),
          (sum, n) => sum + (n.qty || 0),
          0
        ),
    // getTotal(itemByKey[activeKey], "qty", false),
    total_weight:
      itemByKey[activeKey]?.items &&
      getTotal(itemByKey[activeKey], "weight", true),
    total_delivery: itemByKey[activeKey]?.total_delivery || 0,
    total_pv:
      itemByKey[activeKey]?.items && getTotal(itemByKey[activeKey], "pv", true),
    total_rp:
      itemByKey[activeKey]?.items && getTotal(itemByKey[activeKey], "rp", true),
    total_bp:
      itemByKey[activeKey]?.items && getTotal(itemByKey[activeKey], "bp", true),
    total_ra:
      itemByKey[activeKey]?.items && getTotal(itemByKey[activeKey], "ra", true),
    total_tp:
      itemByKey[activeKey]?.items && getTotal(itemByKey[activeKey], "tp", true),
    total_ep:
      itemByKey[activeKey]?.items && getTotal(itemByKey[activeKey], "ep", true),
    remark: itemByKey[activeKey]?.remark,
    province_name: itemByKey[activeKey]?.ship_province || null,
    promotionProductCode,
    // switch to false for test promotion only
    // promotionShowRecommend: false,
    promotionShowRecommend,
    promotionLoading,
    course_ralc: 
      _.filter (
        itemByKey[activeKey]?.items.itemByKey, (m) => m.course_ralc === 1
      ).length
  };
};

export default withRouter(
  connect(mapStateToProps, {
    changeStep: Creators.changeStep,
    setBillValue: Creators.setBillValue,
    setMatchingPromotionItem: Creators.setMatchingPromotionItem,
    setPromotionCollect: Creators.setPromotionCollect,
    setQrWarning: Creators.setQrWarning,
    setOrderPromotionItemValue: Creators.setOrderPromotionItemValue,
  })(PosOrderFooter)
);
