import React from "react";
import { Switch, Route } from "react-router-dom";

import SupplierList from "./supplier-list";
import SupplierManage from "./supplier-manage";
import Error404 from "../../../Error404";

export default ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={SupplierList} />
    <Route path={`${match.path}/:id`} component={SupplierManage} />
    <Route component={Error404} />
  </Switch>
);
