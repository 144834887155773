import { Col, Row, Typography } from "antd";
import React, { memo } from "react";
import { moment } from "../../../../../utils";

const { Text } = Typography;

const PendingDetail = ({ preOrder = {} }) => (
  <>
    <Row className="bg-gray-60 py-1 px-2">
      <Col span={24}>
        <Text strong className="text-white">
          ดำเนินการ
        </Text>
      </Col>
    </Row>

    <Row className="bg-gray-40 py-1 px-2">
      <Col span={3} className="text-right font-size-12">
        <Text className="mr-2" strong>
          สินค้า
        </Text>
      </Col>
      <Col span={5} className="font-size-12">
        <Text>{preOrder.have_product_text}</Text>
      </Col>
      <Col span={3} className="text-right font-size-12">
        <Text className="mr-2" strong>
          หมายเลข PO
        </Text>
      </Col>
      <Col span={5} className="font-size-12">
        <Text>{preOrder.po_no}</Text>
      </Col>
      <Col span={3} className="text-right font-size-12">
        <Text className="mr-2" strong>
          S/N
        </Text>
      </Col>
      <Col span={5} className="font-size-12">
        <Text>{preOrder.sn}</Text>
      </Col>
    </Row>

    <Row className="bg-gray-40 py-1 px-2 mb-3">
      <Col span={3} className="text-right font-size-12">
        <Text className="mr-2" strong>
          วันที่ได้รับสินค้า
        </Text>
      </Col>
      <Col span={5} className="font-size-12">
        {preOrder.receive_date && (
          <Text>{moment(preOrder.receive_date).format("DD/MM/YYYY")}</Text>
        )}
      </Col>
      <Col span={3} className="text-right font-size-12">
        <Text className="mr-2" strong>
          เลขพัสดุจัดส่ง
        </Text>
      </Col>
      <Col span={5} className="font-size-12">
        <Text>{preOrder.tracking_no}</Text>
      </Col>
      <Col span={3} className="text-right font-size-12">
        <Text className="mr-2" strong>
          บริษัทขนส่ง
        </Text>
      </Col>
      <Col span={5} className="font-size-12">
        <Text>{preOrder.delivery_company}</Text>
      </Col>
    </Row>
  </>
);

export default memo(PendingDetail);
