/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Row } from "antd";
import React, { useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SearchDate from "../../../../../components/SearchDate";
import SelectLayout from "../../../../../components/SelectLayout";
import SelectMasterType from "../../../../../components/SelectMasterType";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import SelectWarehouse from "../SelectWarehouse";
import SelectDepartment from "../../../setting/permission/user/components/SelectDepartment";

const { setGlobalState } = actionRequisition;

const SearchDocument = () => {
  const { process: process_params } = useParams();
  const dispatch = useDispatch();
  const {
    query,
    start_date,
    end_date,
    requisition_type,
    warehouse_code,
    department_id,
  } = useSelector((state) => state.requisition.searchDocument);

  const handleChangeStart = useCallback((start_date) => {
    dispatch(
      setGlobalState({
        name: "searchDocument",
        value: {
          start_date,
          end_date: null,
          page: 1,
        },
      })
    );
  }, []);

  const handleChangeEnd = useCallback((end_date) => {
    dispatch(
      setGlobalState({
        name: "searchDocument",
        value: {
          end_date,
          page: 1,
        },
      })
    );
  }, []);

  return (
    <Row gutter={[8, 8]} type="flex" className="align-items-center mb-3">
      {process_params === "pending" && (
        <Col span={24} className="text-right">
          <Link to={`${process_params}/0`}>
            <Button type="primary">
              <FaPlus className="mr-2" />
              สร้างเอกสารเบิกสินค้า
            </Button>
          </Link>
        </Col>
      )}
      <Col span={24} xl={10} xxl={7}>
        <Input.Search
          allowClear
          placeholder="เลขที่เอกสาร, รหัสพนักงาน, ชื่อพนักงาน"
          autoComplete="off"
          value={query}
          onChange={(e) =>
            dispatch(
              setGlobalState({
                name: "searchDocument",
                value: { query: e.target.value, page: 1 },
              })
            )
          }
        />
      </Col>
      <Col span={24} xl={7} xxl={5}>
        <SelectLayout title="แผนก">
          <SelectDepartment
            withAll
            className="w-100"
            value={department_id}
            onChange={(department_id) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: {
                    department_id,
                    page: 1,
                  },
                })
              )
            }
          />
        </SelectLayout>
      </Col>
      <Col span={24} xl={7} xxl={5}>
        <SelectLayout title="คลังสินค้า">
          <SelectWarehouse
            withAll={true}
            userLogin={true}
            value={warehouse_code}
            className="w-100"
            onChange={(warehouse_code) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: {
                    warehouse_code,
                    page: 1,
                  },
                })
              )
            }
          />
        </SelectLayout>
      </Col>
      <Col span={24} xl={10} xxl={5}>
        <SelectLayout title="ประเภทการเบิก">
          <SelectMasterType
            processType="requisition_type"
            withAll
            value={requisition_type}
            onChange={(requisition_type) =>
              dispatch(
                setGlobalState({
                  name: "searchDocument",
                  value: {
                    requisition_type,
                    page: 1,
                  },
                })
              )
            }
          />
        </SelectLayout>
      </Col>
      <Col span={24} xl={14} xxl={7}>
        <SearchDate
          title="วันที่เอกสาร"
          start={start_date}
          end={end_date}
          onChangeStart={handleChangeStart}
          onChangeEnd={handleChangeEnd}
        />
      </Col>
    </Row>
  );
};

export default SearchDocument;
