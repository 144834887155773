/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fixProductType } from "../../../../config/fix-value";
import { axios, URL_API, _ } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { Creators } from "../ducks/operation-form";

const getTotal = (data, type, withQty) =>
  _.reduce(
    data.items.itemByKey,
    (sum, n) => sum + (n[type] || 0) * (withQty ? n.qty || 0 : 1),
    0
  ) +
  _.reduce(
    data.promotions.promotion_list,
    (sum, n) => sum + (n[type] || 0) * (withQty ? n.qty || 0 : 1),
    0
  );

const Delivery = () => {
  const dispatch = useDispatch();
  const [source, setSource] = useState(null);
  const {
    req_receipt_type,
    ship_to_id,
    order_item,
    total_weight,
    total_pv,
    province_name,
    register_qry,
    partner_type,
  } = useSelector((state) => {
    const {
      orders: { activeKey, itemByKey },
    } = state.pos.operation;

    return {
      req_receipt_type: itemByKey[activeKey]?.req_receipt_type || null,
      ship_to_id: itemByKey[activeKey]?.ship_to_id || null,
      order_item: itemByKey[activeKey]?.items || null,
      total_weight:
        itemByKey[activeKey]?.items &&
        getTotal(itemByKey[activeKey], "weight", true),
      total_pv:
        itemByKey[activeKey]?.items &&
        getTotal(itemByKey[activeKey], "pv", true),
      province_name: itemByKey[activeKey]?.ship_province || null,
      register_qry: _.reduce(
        itemByKey[activeKey]?.items.itemKeys,
        (res, key) =>
          itemByKey[activeKey]?.items.itemByKey[key].product_type ===
          fixProductType.starter
            ? res + itemByKey[activeKey]?.items.itemByKey[key].qty
            : res,
        0
      ),
      partner_type: itemByKey[activeKey]?.partner_type,
    };
  });
  const { type: type_params } = useParams();
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );

  const setBillValue = (e) => dispatch(Creators.setBillValue(e));
  const setLoading = (e) => dispatch(Creators.setLoading({ delivery: e }));

  const handleFetchDelivery = async () => {
    if (!isNormal) return;
    // if (register_qry >= 10) {
    //   // 10 register (PT03) free delivery
    //   return setBillValue({ total_delivery: 0 });
    // }
    if (!province_name) return setBillValue({ total_delivery: 0 });
    let res = null;
    setLoading(true);
    try {
      res = await axios({
        url: `${URL_API}/pos/operation/delivery`,
        method: "post",
        data: {
          items: _.map(order_item.itemKeys, (key) => order_item.itemByKey[key]),
          province_name,
          partner_type,
          provider: req_receipt_type === 'Lowcost' ? '300' : undefined,
        },
        cancelToken: source.token,
      });
      setBillValue({ total_delivery: res.data.data.total_delivery });
    } catch (e) {
      resCatch(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (req_receipt_type === "Transport" || req_receipt_type === "Lowcost") {
      source && source.cancel("cancel");
      setSource(axios.CancelToken.source());
    }
  }, [
    req_receipt_type,
    total_pv,
    total_weight,
    province_name,
    register_qry,
    ship_to_id,
  ]);

  useEffect(() => {
    source && handleFetchDelivery();
  }, [source]);
};

export default Delivery;
