import { _ } from "../../../../../../utils";

// main 2 type: item, amount
const isProItem = (e) =>
  _.includes(["item", "item-amount", "item-every-amount"], e);

const isProAmount = (e) => _.includes(["amount", "every-amount"], e);

const isProItemWithAmount = (e) =>
  _.includes(["item-amount", "item-every-amount"], e);

// allow one time time
const isProAmountOnly = (e) => _.includes(["amount", "item-amount"], e);

// allow one time many time
const isProWithEvery = (e) =>
  _.includes(["item", "item-every-amount", "every-amount"], e);

export {
  isProItem,
  isProAmount,
  isProItemWithAmount,
  isProAmountOnly,
  isProWithEvery,
};
