import { Types } from "../types/menu-type";
import { initFetch } from "../logic/initial-value";

const initialState = {
  user_id: null,
  permission: null,
  list: null,
  menus: null,
  fetch: {
    ...initFetch,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.LOAD_MENU:
      return {
        ...state,
        ...payload,
        fetch: { ...initFetch },
      };

    case Types.SET_FETCH:
      return {
        ...state,
        permission: false,
        fetch: { ...state.fetch, ...payload },
      };

    case Types.SET_PERMISSION:
      return {
        ...state,
        permission: payload,
      };

    default:
      return state;
  }
};
