import { Button } from "antd";
import React, { memo } from "react";
import { useState } from "react";
import { FaQrcode } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import QrCodeModal from "./QrCodeModal";

const Index = ({ record = {} }) => {
  const [showQrCode, setShowQrCode] = useState(false);

  return (
    <>
      <Button
        type="link"
        size="small"
        className="p-0 ml-2"
        onClick={() => setShowQrCode(true)}
      >
        <div className="position-relative">
          <span className="d-flex justify-content-center align-items-center bg-gray-60 text-white rounded-circle p-md-1">
            <FaQrcode />
          </span>
          {!record.qr_running && (
            <TiWarningOutline
              size={16}
              className="position-absolute text-nn"
              style={{
                top: "-10px",
                right: "-10px",
              }}
            />
          )}
        </div>
      </Button>
      <QrCodeModal
        visible={showQrCode}
        product={record}
        onDelete={() => null}
        onCancel={() => setShowQrCode(false)}
      />
    </>
  );
};

export default memo(Index);
