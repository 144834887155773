/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { PageSettings } from "../../config/page-settings";

export default () => {
  const contextType = useContext(PageSettings);

  //   useEffect(() => {
  //     contextType.handleSetPageSidebar(false);
  //     contextType.handleSetPageHeader(false);
  //     contextType.handleSetPageContentFullWidth(true);
  //     return () => {
  //       contextType.handleSetPageSidebar(true);
  //       contextType.handleSetPageHeader(true);
  //       contextType.handleSetPageContentFullWidth(false);
  //     };
  //   }, []);

  useEffect(() => {
    if (contextType.pageSidebar) {
      contextType.handleSetPageSidebar(false);
      contextType.handleSetPageHeader(false);
      contextType.handleSetPageContentFullWidth(true);
    }
    return () => {
      if (!contextType.pageSidebar) {
        contextType.handleSetPageSidebar(true);
        contextType.handleSetPageHeader(true);
        contextType.handleSetPageContentFullWidth(false);
      }
    };
  }, [contextType.pageSidebar]);

  return (
    <div className="error">
      <div className="error-code m-b-10">404</div>
      <div className="error-content">
        <div className="error-message">We couldn't find it...</div>
        <div className="error-desc m-b-30">
          The page you're looking for doesn't exist. <br />
          Perhaps, there pages will help find what you're looking for.
        </div>
        <div>
          <Link to="/" className="btn btn-success p-l-20 p-r-20">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
};
