/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Typography,
  Select,
  notification,
  DatePicker,
  Tooltip,
  Modal,
  Input,
  typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { BiEdit, BiSave } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { useQuery } from "../../../../../hooks/dom";
import moment from "moment";
import { axios } from "../../../../../utils/useAxios";
import { URL_API } from "../../../../../utils";
import { Col, Row } from "antdV4";

const { Option } = Select;
const { Text } = typography;

const ManageStatus = ({
  profile_status,
  expire_date,
  resign_date,
  register_new_date,
}) => {
  const query = useQuery();
  const id = parseInt(query.get("id"));

  const type = query.get("membertype");
  const [statusData, setStatusData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(profile_status);
  const [registerNewDate, setRegisterNewDate] = useState(
    moment(register_new_date).format("DD/MM/YYYY")
  );
  const [registerNewDay, setRegisterNewDay] = useState(
    resign_date &&
      register_new_date &&
      moment(register_new_date).diff(moment(resign_date), "days")
  );
  const [resignDate, setResignDate] = useState(resign_date);
  const today = new Date();

  useEffect(() => {
    axios({
      url: `${URL_API}/legal/master/partner/status`,
    })
      .then((res) => {
        setStatusData(res.data.data);
      })
      .catch((err) => {
        notification.error({
          message: "ตรวจพบสิ่งผิดพลาด",
          description:
            err.response && err.response.data
              ? err.response.data.message
              : err.message,
          placement: "topRight",
        });
      });
  }, []);

  const handleChangeDay = (event) => {
    setRegisterNewDay(event.target.value);

    if (event.target.value) {
      setRegisterNewDate(
        moment(resignDate).add(event.target.value, "days")
      );
    }
  };
  const handleChange = (value) => {
    setStatus(value);
    setRegisterNewDate(null);
    setResignDate(null);
  };
  const handleSubmitStatus = () => {
    if (status === "Resigned" && registerNewDate === null) {
      return Modal.warning({
        title: "คุณยังไม่เลือกวันที่สมัครใหม่",
        content: "กรุณาเลือกวันที่สามารถสมัครใหม่",
      });
    }
    if (status === "Void" && registerNewDate === null) {
      return Modal.warning({
        title: "คุณยังไม่เลือกวันที่สมัครใหม่",
        content: "กรุณาเลือกวันที่สามารถสมัครใหม่",
      });
    }
    if (status === "Resigned" && registerNewDate === null) {
      return Modal.warning({
        title: "คุณยังไม่เลือกวันที่สมัครใหม่",
        content: "กรุณาเลือกวันที่สามารถสมัครใหม่",
      });
    }
    if (status === "Resigned" && resignDate === null) {
      return Modal.warning({
        title: "คุณยังไม่เลือกวันที่มีผล",
        content: "กรุณาเลือกวันที่มีผล",
      });
    }
    if (status === "Legal" && resignDate === null) {
      return Modal.warning({
        title: "คุณยังไม่เลือกวันที่มีผล",
        content: "กรุณาเลือกวันที่มีผล",
      });
    }
    if (status === "Vacated" && registerNewDate === null) {
      return Modal.warning({
        title: "คุณยังไม่เลือกวันที่สมัครใหม่",
        content: "กรุณาเลือกวันที่สามารถสมัครใหม่",
      });
    }

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการเปลี่ยนแปลงสถานะหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url:
              type === "distributor"
                ? `${URL_API}/legal/master/partner/update-status`
                : `${URL_API}/legal/master/member/update-status`,
            data: {
              id,
              status,
              registerNewDate,
              resignDate,
            },
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            setEdit(false);
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {},
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleCancel = () => {
    setEdit(false);
    setStatus(profile_status);
    setRegisterNewDate(moment(register_new_date).format("DD/MM/YYYY"));
    setRegisterNewDay(
      resign_date &&
        register_new_date &&
        moment(register_new_date).diff(moment(resign_date), "days")
    );
    setResignDate(resign_date);
  };

  // useEffect(() => {
  //   switch (status) {
  //     case "Vacated":
  //       setRegisterNewDate(moment(today).add(1, "d"));
  //       break;
  //     case "Void":
  //       setRegisterNewDate(moment(today).add(31, "d"));
  //       break;
  //     default:
  //       setRegisterNewDate(register_new_date);
  //       break;
  //   }
  //   if(status !== "Resigned"){
  //     setResignDate(null)
  //   }
  // }, [status]);

  return (
    <div className="row justify-content-end mt-3 mr-3">
      <div className="col-2justify-content-end">
        <Tooltip title="สถานะ">
          <Select
            name="status"
            allowClear
            className="width-200"
            placeholder="เลือกสถานะนักธุรกิจ"
            disabled={!edit}
            value={status}
            onChange={handleChange}
          >
            {statusData.map((item) => (
              <Option key={item.id} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Tooltip>
      </div>
      <div className="col-auto align-items-end">
        {["Resigned", "Legal"].includes(status) && (
          <DatePicker
            placeholder={"เลือกวันที่มีผล"}
            format="DD/MM/YYYY"
            value={resignDate ? moment(resignDate) : null}
            disabled={!edit}
            onChange={(e) => {
              setResignDate(e);
              setRegisterNewDate(null);
              setRegisterNewDay(null);
            }}
          />
        )}
      </div>
      <div className="col-auto align-items-end">
        {["Vacated", "Void"].includes(status) && (
          <DatePicker
            placeholder={
              status === "Legal"
                ? "เลือกวันที่สถานะเป็นปกติ"
                : "เลือกวันที่สมัครใหม่"
            }
            format="DD/MM/YYYY"
            value={registerNewDate ? moment(registerNewDate) : null}
            disabled={!edit}
            onChange={(e) => setRegisterNewDate(e)}
          />
        )}

        {["Resigned"].includes(status) && (
          <div className="row">
            <div className="col">
              <div className="row">
                <Input
                  placeholder="ระบุจำนวนวัน(นับจากวันที่มีผล)"
                  suffix="วัน"
                  disabled={!edit}
                  value={registerNewDay}
                  onChange={(e) => handleChangeDay(e)}
                />
              </div>
              <div className="row">
                <Text strong>
                  วันที่สมัครใหม่ได้ :
                  {registerNewDate && registerNewDay
                    ? " " + moment(registerNewDate).format("DD/MM/YYYY")
                    : "-"}
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>

      {edit ? (
        <div className="col-autojustify-content-end">
          <div className="row">
            <div className="col-6">
              <Button className="bg-pv" onClick={handleSubmitStatus}>
                <BiSave className="text-white" />
                <Typography.Text className="text-white">บันทึก</Typography.Text>
              </Button>
            </div>
            <div className="col-6">
              <Button className="bg-reds" onClick={handleCancel}>
                <FaTimes className="text-white" />
                <Typography.Text className="text-white ">
                  ยกเลิก
                </Typography.Text>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-2justify-content-end">
          <Button className="bg-pv" onClick={() => setEdit(true)}>
            <BiEdit className="text-white" />
            <Typography.Text className="text-white ">แก้สถานะ</Typography.Text>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ManageStatus;
