/* eslint-disable react-hooks/exhaustive-deps */
// import { Select } from "antd";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ValidateError from "../../../../../components/ValidateError";
import { fixBranch } from "../../../../../config/fix-value";
import { useApi } from "../../../../../hooks/http2";
import { actionClaim } from "../../../../../redux/slices/claim";
import { getUserObj, URL_API, _ } from "../../../../../utils";
// import SelectOption from "../../../pos/components/SelectOption";

const { setFormValue } = actionClaim;

const SelectBranchCode = () => {
  const dispatch = useDispatch();
  const [branchCode, setBranchCode] = useState([]);
  const user_branch_code = useSelector(
    (state) => state.claim.documentDetail.user_branch_code
  );
  const branch = useSelector((state) => state.claim.consider.branch);
  const branch_code = useSelector(
    (state) => state.claim.documentDetail.branch_code
  );
  const branch_code_error = useSelector(
    (state) => state.claim.error.branch_code
  );

  const getBranch = useApi(
    {
      url: `${URL_API}/pos/master/branch`,
      token: true,
    },
    []
  );
  const allBranch = getBranch?.fetch?.data;
  let branchFilter = _.cloneDeep(allBranch);
  let options = _.map(branchFilter, (n) => ({
    value: n.code,
    text: n.name,
  }));

  useEffect(() => {
    const res = _.orderBy(
      _.reduce(
        branch,
        (res, { branch_code, branch_name }) =>
          _.some(res, (n) => branch_code === n.branch_code)
            ? res
            : [...res, { branch_code, branch_name }],
        []
      ),
      ["branch_code"],
      ["asc"]
    );
    setBranchCode(res);
  }, [branch]);

  useEffect(() => {
    if (
      !branch_code &&
      user_branch_code &&
      !_.includes(fixBranch.online, user_branch_code)
    ) {
      const user = getUserObj();
      dispatch(
        setFormValue({
          name: "documentDetail",
          value: {
            branch_code: user.branch_code,
            branch_name: user.branch_name,
          },
          error_name: "branch_code",
        })
      );
    }
  }, [branch_code, user_branch_code]);

  const defaultValue = _.find(options, (n) => n.value === branch_code)?.text;

  return (
    <>
      <div className={branch_code_error && "has-error"}>
        <Select
          // options={options}
          placeholder="เลือกสาขารับเคลม"
          size="small"
          className="w-100"
          // value={branch_code ?? undefined}
          value={defaultValue ?? undefined}
          dropdownStyle={{ zIndex: 1080 }}
          onChange={(value, option) => {
            dispatch(
              setFormValue({
                name: "documentDetail",
                value: {
                  branch_code: value,
                  branch_name: option.props.children,
                },
                error_name: "branch_code",
              })
            );
          }}
        >
          {_.map(branchCode, ({ branch_code, branch_name }) => (
            <Select.Option key={branch_code} value={branch_code}>
              {branch_name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <ValidateError error={branch_code_error} />
    </>
  );
};

export default SelectBranchCode;
