import { Col, Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const { Text } = Typography;

const ReceiptTransport = () => {
  const ship_name = useSelector((state) => state.claim.receiptType.ship_name);
  const ship_to = useSelector((state) => state.claim.receiptType.ship_to);
  const ship_mobile = useSelector(
    (state) => state.claim.receiptType.ship_mobile
  );
  const ship_address = useSelector((state) => {
    const {
      ship_address_no,
      ship_sub_district,
      ship_district,
      ship_province,
      ship_post_code,
    } = state.claim.receiptType;
    return `${ship_address_no} ${ship_sub_district} ${ship_district} ${ship_province} ${ship_post_code}`;
  });
  if (!ship_name) return null;

  return (
    <Row gutter={[8, 8]}>
      <Col span={6} className="text-right">
        <Text strong>ผู้รับ</Text>
      </Col>
      <Col span={18}>{ship_to}</Col>
      <Col span={6} className="text-right">
        <Text strong>เบอร์โทรผู้รับ</Text>
      </Col>
      <Col span={18}>{ship_mobile}</Col>
      <Col span={18} offset={6}>
        {ship_address}
      </Col>
    </Row>
  );
};

export default ReceiptTransport;
