/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Input, Row, Table, Typography } from "antd";
import React from "react";
import { useApi } from "../../../../../hooks/http2";
import { moment, URL_API } from "../../../../../utils";

export default function MemberRemark({
  ref_type = "member",
  ref_id = 0,
  handleChange,
}) {
  const remarks = useApi(
    {
      baseURL: URL_API,
      url: "/system/remark",
      token: true,
      params: {
        ref_type,
        ref_id,
      },
    },
    [ref_type, ref_id]
  );

  return (
    <>
      <ul className="full-tabs" style={{ justifyContent: "space-between" }}>
        <li className="active mr-4">
          <Typography.Title level={4}>
            <a style={{ fontSize: "13px" }}>หมายเหตุ</a>
          </Typography.Title>
        </li>
      </ul>
      <div className="container shadow-sm p-15 mb-4 bg-white rounded">
        <>
          <Row gutter={16} className="mb-2">
            <Col>
              <Input
                placeholder="เพิ่มหมายเหตุ"
                onChange={(e) => handleChange(e.target.value || "")}
                allowClear
              />
            </Col>
          </Row>
          <Table
            size="small"
            loading={remarks.loading}
            dataSource={remarks?.fetch?.data || []}
            pagination={{ size: "default", showLessItems: true }}
          >
            <Table.Column
              title="#"
              key="no"
              align="right"
              render={(text, record, index) => index + 1}
            />
            <Table.Column title="หมายเหตุ" dataIndex="remark" key="remark" />
            <Table.Column
              title="ผู้เขียน"
              dataIndex="create_by"
              key="create_by"
              align="center"
            />
            <Table.Column
              title="วันที่"
              dataIndex="create_date"
              key="create_date"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
            />
          </Table>
        </>
      </div>
    </>
  );
}
