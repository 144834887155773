import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

function LayoutHead(props) {
    const id = Number(props.match.params.id) || 0;
    return (
        <Row>
            <Col md={10}><h2>{id !== 0 ? 'แก้ไขโปรโมชั่น' : 'เพิ่มโปรโมชั่น'} </h2></Col>
            <Col md={2}>
                <Row>
                    <Col>
                        <button onClick={props.save} className="w-100 btn btn-sm btn-green-legacy mb-1">Save </button>
                    </Col>
                    <Col>
                        <button onClick={() => props.history.replace('/setting/master/promotion')} className="w-100 btn btn-sm btn-danger">Cancel</button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default withRouter(LayoutHead);
