import { notification, Select } from "antd";
import React, { forwardRef } from "react";
import { useHttp } from "../../../../../../hooks/http";
import { URL_API } from "../../../../../../utils";

const SelectProductType = (props) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/system/products/process`,
      token: true,
    },
    []
  );

  if (error) {
    notification.error({
      description: error,
      placement: "bottomRight",
    });
  }

  return (
    <Select loading={loading} placeholder="ประเภทสินค้า" {...props}>
      {data &&
        data.data &&
        data.data.map((n, i) => (
          <Select.Option key={i} value={n.code}>
            {n.name}
          </Select.Option>
        ))}
    </Select>
  );
};

export default forwardRef((props, ref) => {
  return <SelectProductType forwardRef={ref} {...props} />;
});
