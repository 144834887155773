/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PosDataAction } from "../ducks/data-api";
import { Creators } from "../ducks/operation-form";

const CheckRedeemPoint = () => {
  const { type: type_params } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const notfound = useSelector((state) => state.pos.data.redeem_point.notfound);

  useEffect(() => {
    if (notfound) {
      dispatch(Creators.clear());
      dispatch(PosDataAction.clearRedeemPoint());
      history.replace(`/pos/${type_params}/operator`);
      Modal.error({
        title: "ไม่สามารถดำเนินการได้",
        content: "ไม่พบข้อมูลคะแนนของนักธุรกิจ",
        okText: "ตกลง",
        zIndex: 1080,
      });
    }
  }, [notfound]);
};

export default CheckRedeemPoint;
