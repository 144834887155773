import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const fitClassServices = {
  useQueryGetClassCategory({ take, page }, onSuccess) {
    return useQuery(
      ['get-class-category', { take, page }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/fitness-class-category`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          if (res.status === 200) {
            return res.data
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetClassCategoryById({ classCategoryId }, onSuccess) {
    return useQuery(
      ['get-class-category-by-id', { classCategoryId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/fitness-class-category/${queryKey[1].classCategoryId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!classCategoryId,
      }
    );
  },
  useQueryGetClassById({ classId }, onSuccess) {
    return useQuery(
      ['get-class-by-id', { classId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/fitness-class/${queryKey[1].classId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!classId,
      }
    );
  },
  useQueryGetClassByClassCategoryId(
    { classCategoryId, take, page },
    onSuccess
  ) {
    return useQuery(
      ['get-class-by-class-category-id', { classCategoryId, take, page }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get('/fitness-class', {
            params: {
              classCategoryId: queryKey[1].classCategoryId,
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!classCategoryId,
      }
    );
  },
  useQuerygetClassScheduleById({ classScheduleId }, onSuccess) {
    return useQuery(
      ['get-class-schedule-by-id', { classScheduleId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/fitness-class-schedule/${queryKey[1].classScheduleId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!classScheduleId,
      }
    );
  },
  useQueryGetClassScheduleByClassId({ classId, take, page }, onSuccess) {
    return useQuery(
      ['get-class-schedule-by-class-id', { classId, take, page }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get('/fitness-class-schedule', {
            params: {
              classId: queryKey[1].classId,
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!classId,
      }
    );
  },
  useQueryGetClassReservationByClassScheduleId(
    { classScheduleId, take, page },
    onSuccess
  ) {
    return useQuery(
      [
        'get-class-reservation-by-class-schedule-id',
        { classScheduleId, take, page },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get('/fitness-class-reservation', {
            params: {
              classScheduleId: queryKey[1].classScheduleId,
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!classScheduleId,
      }
    );
  },
  useMutationPresentClassReservation(onSuccess, onError) {
    return useMutation(
      async ({ classReservationId }) => {
        try {
          const res = await fitStudioRequest.patch(
            `/fitness-class-reservation/present-class-reservation-by-admin/${classReservationId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCancelClassReservation(onSuccess, onError) {
    return useMutation(
      async ({ classReservationId }) => {
        try {
          const res = await fitStudioRequest.patch(
            `/fitness-class-reservation/visit-fit-studio-paper/${classReservationId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default fitClassServices;
