import React from 'react'
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const CustomColor = styled.div`
    .switcher input:checked+label:before {
    background: #97D700;
    border: 2px solid #97D700;
}
`


export default function Switcher(props) {
    const { label, id, ratio = [9, 3], ...rest } = props;
    return (
        <CustomColor>
            <Row className="mb-2">
                <Col md={ratio[0]}>
                    <label>{label}</label>
                </Col>
                <Col md={ratio[1]} className={`d-flex justify-content-center`}>
                    <div className="switcher">
                        <input type="checkbox"
                            id={id}
                            {...rest}
                        />
                        <label htmlFor={id}></label>
                    </div>
                </Col>
            </Row>
        </CustomColor>

    )
}
