/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Col, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHttp } from '../../../../../../hooks/http';
import { URL_API } from '../../../../../../utils';

const PromotionMemberStatus = ({ data, handleChange }) => {
    const [checkedList, setCheckedList] = useState([]);
    const [plainOptions, setPlainOptions] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const [loadingMasterStatus, masterStatus, errorMasterStatus] = useHttp({
        method: 'GET',
        url: `${URL_API}/system/master/partner_status_local`,
        token: true
    }, []);

    if (errorMasterStatus) {
        notification.error({
            message: 'error',
            description: errorMasterStatus,
        });
    }

    useEffect(() => {
        if (data.status.length > 0) {
            let length = data.status.length
            setIndeterminate(length > 0 && length < plainOptions.length)
            setCheckAll(length === plainOptions.length)
            setCheckedList(data.status)
        }
    }, [data, plainOptions])
 
    useEffect(() => {
        let list = [];
        if (!loadingMasterStatus) {
            masterStatus && masterStatus.data.forEach(item => {
                const { code } = item;
                if (code) {
                    list.push({ label: item.name, value: item.code })
                }
            });
            setPlainOptions(list);
        }
    }, [masterStatus])
   
    const onChange = e => {
        setCheckedList(e)
        setIndeterminate(e.length > 0 && e.length < plainOptions.length)
        setCheckAll(e.length === plainOptions.length)

        handleChange({
            target: {
                name: "status",
                value: e
            }
        });
    }
    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions.map(n => n.value) : [])
        setIndeterminate(false)
        setCheckAll(e.target.checked)

        handleChange({
            target: {
                name: "status",
                value: e.target.checked ? plainOptions.map(n => n.value) : []
            }
        });
    };
    return (
        <>
            <Row >
                <Col md={12} style={{ columnCount: 2, width: '100%' }}>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    >
                        ทุกสถานะ
                    </Checkbox>
                    <Checkbox.Group
                        style={{ display: 'grid'}}
                        options={plainOptions}
                        value={checkedList}
                        onChange={onChange}
                    />
                </Col>
            </Row>
        </>
    )
}
export default PromotionMemberStatus;
