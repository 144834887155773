import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const lobDefaultValues = {
  firstName: '',
  lastName: '',
  lobNo: '',
  storeName: '',
  memberCode: '',
  expiredDate: moment(),
  links: [
    {
      platform: 'Shopee',
      url: '',
    },
    {
      platform: 'Lazada',
      url: '',
    },
    {
      platform: 'Tiktok',
      url: '',
    },
  ],
  isPartner: false,
};

export const lobFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      firstName: yup.string().nullable().required('กรุณากรอกข้อมูล'),
      lastName: yup.string('กรุณากรอกข้อมูล').required('กรุณากรอกข้อมูล'),
      lobNo: yup.string('กรุณากรอกข้อมูล').required('กรุณากรอกข้อมูล'),
      memberCode: yup.string().defined(),
      storeName: yup.string().defined(),
      expiredDate: yup.date().defined(),
    })
  );
};
