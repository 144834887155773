/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Empty, List, Modal, Radio, Row, Typography } from "antd";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import ProductCategoryContext from "../../../../contexts/product-category.context";
import { useApi } from "../../../../hooks/http2";
import { URL_API } from "../../../../utils";

export default function CategoryList() {
  const { state, dispatch } = useContext(ProductCategoryContext);

  const categories = useApi(
    {
      baseURL: URL_API,
      url: `/setting/master/product-group`,
      params: {
        division_id: state.department?.id,
      },
      token: true,
    },
    [state.department?.id]
  );

  const handleClickDel = (id) => {
    Modal.confirm({
      title: "ยืนยัน",
      content: "คุณต้องการลบข้อมูลนี้หรือไม่",
      okText: "ลบ",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          resolve();
        })
          .then((res) => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                categories.reload();
              },
            });
          })
          .catch((reason) => {
            Modal.success({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    if (state.reload.category !== "") categories.reload();
  }, [state.reload.category]);

  return (
    <>
      <Row gutter={16} className="mb-2 d-flex align-items-end">
        <Col md={24}>
          <Typography.Title level={4} className="mb-0">
            กลุ่มสินค้าหลัก
          </Typography.Title>
        </Col>
      </Row>
      {state.department ? (
        <>
          <Row gutter={16} className="mb-2 d-flex align-items-end">
            <Col md={12}>
              <Typography.Text className="mb-0 font-size-14">
                <strong className="mr-2">แผนก:</strong>
                {state.department?.name}
              </Typography.Text>
            </Col>
            <Col md={12} className="text-right">
              <Button
                type="primary"
                size="small"
                onClick={() =>
                  dispatch({ type: "OPEN_MODAL", payload: "category" })
                }
              >
                เพิ่ม
              </Button>
            </Col>
          </Row>
          <Radio.Group
            className="w-100"
            value={state.category?.id}
            onChange={(e) =>
              dispatch({
                type: "SET_CATEGORY",
                payload: _.find(
                  categories.fetch?.data || [],
                  (n) => n.id === e.target.value
                ),
              })
            }
          >
            <List
              bordered
              size="small"
              loading={categories.loading}
              dataSource={categories.fetch?.data || []}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  extra={
                    // <Button
                    //   type="link"
                    //   size="small"
                    //   onClick={() => handleClickDel(item.id)}
                    // >
                    //   ลบ
                    // </Button>
                    <Button
                      type="link"
                      size="small"
                      onClick={() =>
                        dispatch({ type: "EDIT_CATEGORY", payload: item })
                      }
                    >
                      แก้ไข
                    </Button>
                  }
                >
                  <Radio value={item.id}>{item.name_local}</Radio>
                </List.Item>
              )}
            />
          </Radio.Group>
        </>
      ) : (
        <Empty description="กรุณาเลือกแผนก" />
      )}
    </>
  );
}
