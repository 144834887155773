/* eslint-disable no-unused-vars */
import { Table, Tag } from "antd";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";
import { getMoment, getNumeral } from "../../../../../utils/helper";
import { axios } from "../../../../../utils/useAxios";
import ShowDocumentImg from "../ShowDocumentImg";

export default function TableUploadHistory() {
  const dispatch = useDispatch();
  const { id, type } = useSelector((state) => state.ethics.form, shallowEqual);
  const modal = useSelector((state) => state.ethics.modal, shallowEqual);
  const { SET_MODAL_PROPS, SET_MODAL_SEARCH } = actionEthics;

  const handleShowPreview = (src) => {
    console.log("click")
    dispatch({
      type: "SHOW_MODAL",
      payload: { name: "document-icon", props: { src } },
    });
  };

  const loadDataCallback = useCallback(async () => {
    if (modal.props.search.page) {
      dispatch(
        SET_MODAL_PROPS({
          loading: true,
        })
      );
      // `/pos/${
      //   type === "member" ? "vip-member" : "ethics"
      // }/${id}/upload-history`,
      const response = await axios.get(`/ethics/history/upload`, {
        params: {
          limit: modal.props.search.limit,
          offset: (modal.props.search.page - 1) * modal.props.search.limit,
          status: modal.props.search.status,
          query: modal.props.search.query,
          document_type: modal.props.search.document_type,
          id,
          type,
        },
      });
      dispatch(
        SET_MODAL_PROPS({
          loading: false,
          list: {
            data: response.data?.data || [],
            total: response.data?.total || 0,
          },
        })
      );
    }
  }, [dispatch, id, SET_MODAL_PROPS, modal.props.search, type]);

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  return (
    <>
    <Table
      size="small"
      loading={modal.props.loading}
      //dataSource={modal.props.list.data || []}
      dataSource={modal.props.list.data.map((item, index) => ({ ...item, key: index + 1 })) || []}
      rowKey="row_id"
      rowClassName="f-s-12"
      pagination={{
        size: "default",
        current: modal.props.search.page,
        pageSize: modal.props.search.limit,
        total: modal.props.list.total || 0,
        showLessItems: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${getNumeral(range[0])} - ${getNumeral(range[1])} of ${getNumeral(
            total
          )}`,
        pageSizeOptions: ["10", "25", "50", "100"],
        onChange: (current, pageSize) =>
          dispatch(
            SET_MODAL_SEARCH({
              page: current,
              limit: pageSize,
            })
          ),
        onShowSizeChange: (current, size) =>
          dispatch(
            SET_MODAL_SEARCH({
              page: 1,
              limit: size,
            })
          ),
      }}
    >
      <Table.Column
        title="ลำดับ"
        dataIndex="row_id"
        align="right"
        className="f-s-12"
      />
      <Table.Column
        dataIndex="azure_url"
        align="center"
        className="f-s-12"
        render={(src)=><ShowDocumentImg url = {src}/>}    
      />
      <Table.Column title="เอกสาร" dataIndex="name" className="f-s-12" />
      <Table.Column
        title="สถานะ"
        dataIndex="document_status"
        align="center"
        className="f-s-12"
        render={(text) => {
          switch (text?.toLowerCase()) {
            case "approve":
              return <Tag color="green">อนุมัติ</Tag>;
            case "reject":
              return <Tag color="red">ปฏิเสธ</Tag>;
            default:
              return "-";
          }
        }}
      />
      <Table.Column
        title="วันที่อัพโหลดเอกสาร"
        dataIndex="create_date"
        align="center"
        className="f-s-12"
        render={(text) => getMoment(text, "DD/MM/YYYY HH:mm")}
      />
      <Table.Column
        title="วันที่ทำรายการ (Reject/Approve)"
        dataIndex="update_date"
        align="center"
        className="f-s-12"
        render={(text) => getMoment(text, "DD/MM/YYYY HH:mm")}
      />
      <Table.Column title="พนักงาน" dataIndex="update_by" className="f-s-12" />
      <Table.Column title="หมายเหตุ" dataIndex="remark" className="f-s-12" />
    </Table>
   </>
  );
}
