/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  message,
  notification,
  Row,
  Table,
  typography,
  Modal,
  Spin,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { useApi } from "../../../../../hooks/http2";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import { memberTypeOptions } from "../../components/memberTypeOptions";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
const { Text } = typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idCard, setIdCard] = useState();

  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  //Filter
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState("");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-information-apply`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        memberType: memberTypeFilter,
        teamName: teamFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const loadCountId = useApi(
    {
      baseURL: URL_API,
      url: `/boss/partner/partner-information-detail`,
      token: true,
      params: {
        idCard: idCard,
      },
    },
    [idCard]
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const exportDataData = data ? [data?.data] : [];

  const exportToExcel = () => {
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานข้อมูลการสมัครสมาชิก"],
        [
          `ประเภทสมาชิก ${
            memberTypeFilter === "" ? "ทั่งหมด" : memberTypeFilter
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
          "",
          `Team : ${teamFilter === "" ? "ทั้งหมด" : teamFilter}`,
        ],
        [],
        [
          "ลำดับที่",
          "รหัสสมาชิก",
          "ชื่อสมาชิก",
          "บัตรประชาชน",
          "รหัสอัพไลน์",
          "ชื่ออัพไลน์",
          "ChartType",
          "รหัสผู้แนะนำ",
          "ชื่อผู้แนะนำ",
          "วันที่สมัคร",
          "เวลาสมัคร",
          "Team",
          "รหัสนักธุรกิจที่เป็นผู้คีย์สมัคร",
          "ชื่อนักธุรกิจที่เป็นผู้คีย์สมัคร",
          "ประเภทสมาชิก",
          "เคยมีรหัสเก่า",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.partner_code,
            n.first_name_th + " " + n.sur_name_th,
            n.id_card,
            n.up_line_code,
            n.up_line_first_name_th + " " + n.up_line_sur_name_th,
            { v: n.chart_type, s: { alignment: { horizontal: "center" } } },
            n.sponsor_code,
            n.sponsor_name + " " + n.sponsor_sur_name_th,
            moment(n.register_date).format("DD/MM/YYYY"),
            moment(n.register_date).format("LT"),
            n.team_name,
            n.refaretner_code,
            n.refaretner_first_name_th + " " + n.refaretner_sur_name_th,
            n.member_type === "Distributor"
              ? "นักธุรกิจ"
              : n.member_type === "Member"
              ? "VIP MEMBER"
              : "",
            { v: n.count_id, s: { alignment: { horizontal: "center" } } },
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },

        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานข้อมูลการสมัครสมาชิก");
      XLSX.writeFile(wb, "รายงานข้อมูลการสมัครสมาชิก.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  const showModal = (id_card) => {
    setIdCard(id_card);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานข้อมูลการสมัครสมาชิก</PanelHeader>
        <PanelBody>
          <FilterList /*exportToExcel={""}*/
            withAll={user.branch_count > 1}
            allowDisableBranch
            reload={() => {
              reload();
            }}
            placeholder="รหัสสมาชิก ,ชื่อสมาชิก"
            showReportSpan={10}
            inputSpan={6}
            Topic={"วันที่สมัคร"}
            exportToExcel={exportToExcel}
          />
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                className="w-100"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>

            <Col>
              <Text strong>Team</Text>
            </Col>
            <Col span={4}>
              <SelectTeam
                withAll
                defaultValue={teamFilter}
                onChange={(e) => {
                  let change = e === "ทั้งหมด" ? "" : e;
                  setTeamFilter(change);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.data ? dataSource.data.length : 50,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="center"
            />
            <Table.Column
              title="ชื่อสมาชิก"
              align="left"
              render={(_, record) =>
                record.first_name_th + " " + record.sur_name_th
              }
            />

            <Table.Column
              title="เลขบัตรประชาชน"
              dataIndex="id_card"
              align="left"
            />
            <Table.Column
              title="รหัสอัพไลน์"
              dataIndex="up_line_code"
              align="center"
            />
            <Table.Column
              title="ชื่ออัพไลน์"
              align="left"
              render={(_, record) =>
                record.up_line_first_name_th + " " + record.up_line_sur_name_th
              }
            />

            <Table.Column
              title="ChartType"
              dataIndex="chart_type"
              align="center"
            />
            <Table.Column
              title="รหัสผู้แนะนำ"
              dataIndex="sponsor_code"
              align="center"
            />
            <Table.Column
              title="ชื่อผู้แนะนำ"
              align="left"
              render={(_, record) =>
                record.sponsor_name + " " + record.sponsor_sur_name_th
              }
            />
            <Table.Column
              title="วันที่สมัคร"
              dataIndex="register_date"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="เวลาที่สมัคร"
              align="center"
              render={(_, record) => moment(record.register_date).format("LT")}
            />
            <Table.Column title="Team" dataIndex="team_name" align="center" />
            <Table.Column
              title="รหัสนักธุรกิจที่เป็นผู้คีย์สมัคร"
              dataIndex="refaretner_code"
              align="center"
            />
            <Table.Column
              title="ชื่อนักธุรกิจที่เป็นผู้คีย์สมัคร"
              dataIndex="refaretner_first_name_th"
              align="left"
              render={(_, record) =>
                record.refaretner_first_name_th +
                " " +
                record.refaretner_sur_name_th
              }
            />

            <Table.Column
              title="ประเภทสมาชิก"
              dataIndex="member_type"
              align="center"
              render={(text) => {
                if (text === "Distributor") {
                  return "นักธุรกิจ";
                } else if (text === "Member") {
                  return "VIP MEMBER";
                } else {
                  return text;
                }
              }}
            />
            <Table.Column
              title="เคยมีรหัสเก่า"
              align="center"
              render={(_, record) => {
                return (
                  <Button onClick={() => showModal(record.id_card)}>
                    {record.count_id}
                  </Button>
                );
              }}
            />
          </Table>
          <Modal
            title="เคยมีรหัสเก่า"
            width={600}
            visible={isModalVisible}
            cancelText="ปิด"
            okButtonProps={{ style: { display: "none" } }}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Spin spinning={loadCountId.loading}>
              <Row className="mb-2">เลขประจำตัวประชาชน : {idCard}</Row>
              <Row>
                <Table
                  bordered
                  size="small"
                  loading={loadCountId.loading}
                  dataSource={loadCountId.fetch?.data || []}
                  rowKey="row_id"
                  scroll={{ x: "max-content" }}
                  pagination={{
                    current: page,
                    pageSize: limit,
                    total: loadCountId.fetch?.data
                      ? loadCountId.fetch?.data.length
                      : 10,
                    size: "default",
                    onChange: (currPage) => setPage(currPage),
                  }}
                >
                  <Table.Column
                    title="ลำดับ"
                    dataIndex="row_id"
                    align="center"
                  />
                  <Table.Column
                    title="ประเภทสมาชิก"
                    dataIndex="member_type"
                    align="center"
                    render={(text) => {
                      if (text === "Distributor") {
                        return "นักธุรกิจ";
                      } else if (text === "Member") {
                        return "VIP MEMBER";
                      } else {
                        return text;
                      }
                    }}
                  />
                  <Table.Column
                    title="รหัสสมาชิก"
                    dataIndex="partner_code"
                    align="center"
                  />
                  <Table.Column
                    title="ชื่อสมาชิก"
                    dataIndex="partner_name"
                    align="left"
                  />
                  <Table.Column
                    title="รหัสผู้แนะนำ"
                    dataIndex="sponsor_code"
                    align="center"
                  />
                  <Table.Column
                    title="ชื่อผู้แนะนำ"
                    dataIndex="sponsor_name"
                    align="left"
                  />
                  <Table.Column
                    title="วันที่สมัคร"
                    dataIndex="register_date"
                    align="center"
                  />
                  <Table.Column
                    title="วันที่สิ้นสุดสถานะสมาชิกำ"
                    dataIndex="expire_date"
                    align="center"
                  />
                  <Table.Column
                    title="สถานะ"
                    dataIndex="status"
                    align="center"
                  />
                </Table>
              </Row>
            </Spin>
          </Modal>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
