import { Button, Input, Modal } from "antd";
import numeral from "numeral";
import React, { memo, useEffect, useState } from "react";
import { FaCheckDouble, FaLink } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { usePaymentMutate } from "../../contexts/payment.context";
import { axios, getToken, MEMBER_API, MEMBER_URL, URL_API } from "../../utils";
import { resCatchModal } from "../../views/util/helper";

let source = axios.CancelToken.source();

const Index = ({
  price,
  documentNo,
  buyerCode,
  buyerType,
  paymentType,
  paymentUrl,
  redirectUrl,
  paymentGateWay,
  kBankCustomerId,
  paymentData,
  reloadPayment,
}) => {
  // const refBtn = useRef(null);
  const { kPaymentRef } = usePaymentMutate();
  const [chargeCode, setChargeCode] = useState(null);

  useEffect(() => {
    if (chargeCode && kPaymentRef.current && paymentGateWay) {
      setTimeout(() => {
        kPaymentRef.current.setProps({
          orderId: chargeCode,
          amount: numeral(price).format("0.00"),
          merchantId:
            paymentType === "INS"
              ? paymentGateWay.merchant_id_installment
              : paymentGateWay.merchant_id,
          publicKey: paymentGateWay.public_key,
          paymentMethods: paymentType === "QR" ? "QR" : "card",
          smartPayId: paymentType === "INS" ? "0001" : "",
          term: paymentType === "INS" ? "3" : "0",
          customerId: kBankCustomerId,
        });
        kPaymentRef.current.setAction(
          MEMBER_API + "/kbank/charge?code=" + chargeCode
        );
        kPaymentRef.current.show(chargeCode);
      }, 500);
    }
  }, [
    chargeCode,
    kPaymentRef,
    paymentGateWay,
    kBankCustomerId,
    paymentType,
    price,
  ]);

  useEffect(() => {
    return () => {
      setChargeCode(null);
    };
  }, []);

  const handleClickPayment = () => {
    setChargeCode(null);
    Modal.confirm({
      title: "ยืนยันบันทึกข้อมูล",
      content: "ชำระเงินด้วย Credit Card",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return sendTransactionPayment("pos").then((res) => {
          setChargeCode(res.data.id);
        });
      },
    });
  };

  const getPaymentLink = async () => {
    if (!paymentData.ref_payment_code) {
      sendTransactionPayment("pos-link").then((res) => {
        reloadPayment();
        showPaymentLink(res.data.id);
      });
    } else {
      showPaymentLink(paymentData.ref_payment_code);
    }
  };

  const showPaymentLink = (code) => {
    const link = `${MEMBER_URL}/payment-link?code=${code}`;
    Modal.success({
      title: "ลิงก์ชำระเงิน",
      content: (
        <>
          <Input value={link} className="d-block mb-2" readOnly />
          <Button
            type="primary"
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            คัดลอกลิงก์
          </Button>
        </>
      ),
      okText: "ปิด",
      okType: "default",
      zIndex: 1080,
    });
  };

  const sendTransactionPayment = (system_name = "pos") => {
    return new Promise(async (resolve, reject) => {
      try {
        source = axios.CancelToken.source();
        const payload = {
          payment_url: paymentUrl,
          redirect_url: redirectUrl,
          document_no: documentNo,
          amount: price,
          partner_code: buyerCode,
          partner_type: buyerType,
          system_name,
          payment_id: paymentData.id,
          term: paymentType === "INS" ? "3" : "0",
        };

        const { data } = await axios({
          method: "post",
          url: `${URL_API}/pos/operation/reserve/transaction-payment`,
          data: {
            ...payload,
          },
          headers: { Authorization: "Bearer " + getToken() },
          cancelToken: source.token,
        });

        resolve(data);
      } catch (error) {
        resCatchModal(error);
        reject(error);
      }
    });
  };

  const reconcilePayment = async () => {
    try {
      if (!paymentData.ref_payment_code) return;
      await axios.post(
        `/pos/operation/reserve/reconcile/${paymentData.id}`,
        {},
        {
          baseURL: URL_API,
          headers: { Authorization: "Bearer " + getToken() },
          cancelToken: source.token,
        }
      );

      reloadPayment();
    } catch (error) {
      resCatchModal(error);
    }
  };

  // const sendTransactionPayment = (system_name = "pos") => {
  //   let source = axios.CancelToken.source();
  //   //case card : system_name: 'pos'
  //   //case link : system_name: 'pos-link'
  //   setChargeCode(null);
  //   Modal.confirm({
  //     title: "ยืนยันบันทึกข้อมูล",
  //     content: "ชำระเงินด้วย Credit Card",
  //     okText: "ยืนยัน",
  //     cancelText: "ยกเลิก",
  //     zIndex: 1080,
  //     onOk() {
  //       return new Promise((resolve, reject) => {
  //         axios({
  //           method: "post",
  //           url: `${URL_API}/pos/operation/reserve/transaction-payment`,
  //           data: {
  //             payment_url: paymentUrl,
  //             redirect_url: redirectUrl,
  //             document_no: documentNo,
  //             amount: price,
  //             partner_code: buyerCode,
  //             partner_type: buyerType,
  //             system_name: "pos",
  //           },
  //           headers: { Authorization: "Bearer " + getToken() },
  //           cancelToken: source.token,
  //         })
  //           .then((res) => {
  //             resolve();
  //             setChargeCode(res.data.data.id);
  //             // refBtn.current.show();
  //           })
  //           .catch((e) => {
  //             reject(e);
  //           });
  //       }).catch((e) => {
  //         resCatchModal(e);
  //       });
  //     },
  //     onCancel() {
  //       source.cancel("cancel");
  //     },
  //   });
  // };

  useEffect(() => {
    return () => {
      source.cancel("cancel");
    };
  }, []);

  return (
    <>
      {/* {!!paymentGateWay ? (
        <KPaymentButton
          ref={refBtn}
          debug={paymentGateWay.debug}
          hidden
          orderId={chargeCode}
          action={MEMBER_API + "/kbank/charge?code=" + chargeCode}
          amount={numeral(price).format("0.00")}
          merchantName={paymentGateWay.merchant_name}
          merchantId={
            paymentType === "INS"
              ? paymentGateWay.merchant_id_installment
              : paymentGateWay.merchant_id
          }
          publicKey={paymentGateWay.public_key}
          onError={(error) => message.error(error)}
          paymentMethods={paymentType === "QR" ? "QR" : "card"}
          smartPayId={paymentType === "INS" ? "0001" : ""}
          term={paymentType === "INS" ? "3" : "0"}
          customerId={kBankCustomerId}
          onClose={() => {
            setChargeCode(null);
          }}
        />
      ) : null} */}
      <MdPayment
        size={16}
        className="cursor-pointer mx-2"
        onClick={handleClickPayment}
      />
      {/* <FaLink size={14} className="cursor-no-drop text-gray-50 mr-2" /> */}
      <FaLink
        size={14}
        className="cursor-pointer mr-2"
        onClick={getPaymentLink}
      />
      <FaCheckDouble
        size={14}
        className={`${
          paymentData.ref_payment_code ? "cursor-pointer" : "cursor-no-drop"
        } mr-2`}
        onClick={reconcilePayment}
      />
    </>
  );
};

export default memo(Index);
