import { Col, Input, Row } from 'antd'
import React from 'react'
import SelectAddressMaster from '../../../../../components/SelectAddressMaster'
import TextValidation from '../../../../../components/TextValidation'

const AddressForm = ({ handleChange, Member, setMember, errors }) => {
    return (
        <>
            <Row className="mb-2">
                <Col>
                    <label>ที่อยู่</label>
                    <TextValidation validate={errors.address} />
                    <Input
                        name="address"
                        size="small"
                        className="f-s-12"
                        onChange={(e) => handleChange(e, "idAddress")}
                        value={Member?.idAddress.address}
                    />
                </Col>
            </Row>
            <Row className="mb-2" gutter={8}>
                <Col md={6} >
                    <label>จังหวัด</label>
                    <TextValidation validate={errors.province} />
                    <SelectAddressMaster
                        size="small"
                        className="w-100 f-s-12"
                        type="province"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children.indexOf(input.toLowerCase()) >=
                            0
                        }
                        value={Member?.idAddress.province_id}
                        onChange={(e, option) => {
                            setMember({
                                ...Member,
                                idAddress: {
                                    ...Member.idAddress,
                                    province_id: e,
                                    province: option.props.source.name,
                                    district_id: undefined,
                                    sub_district_id: undefined,
                                    post_code: undefined
                                },
                                idAddressUpdate: {
                                    ...Member.idAddressUpdate,
                                    province_id: e,
                                    province: option.props.source.name,
                                    district_id: undefined,
                                    sub_district_id: undefined,
                                    post_code: undefined
                                }
                            })
                        }}
                    />
                </Col>
                <Col md={6} >
                    <label>อำเภอ/เขต</label>
                    <TextValidation validate={errors.district} />
                    <SelectAddressMaster
                        size="small"
                        className="w-100 f-s-12"
                        type="district"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children.indexOf(input.toLowerCase()) >=
                            0
                        }
                        value={Member.idAddress.district_id}
                        province_id={Member?.idAddress.province_id}
                        onChange={(e, option) =>
                            setMember({
                                ...Member,
                                idAddress: {
                                    ...Member.idAddress,
                                    district_id: e, district: option.props.source.name,
                                    sub_district_id: undefined,
                                    post_code: undefined
                                },
                                idAddressUpdate: {
                                    ...Member.idAddressUpdate,
                                    district_id: e, district: option.props.source.name,
                                    sub_district_id: undefined,
                                    post_code: undefined
                                }
                            })}
                    />
                </Col>
                <Col md={6} >
                    <label>ตำบล/แขวง</label>
                    <TextValidation validate={errors.sub_district} />
                    <SelectAddressMaster
                        size="small"
                        className="w-100 f-s-12"
                        type="subdistrict"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children.indexOf(input.toLowerCase()) >=
                            0
                        }
                        value={Member?.idAddress.sub_district_id}
                        district_id={Member.idAddress.district_id}
                        onChange={(e, option) =>
                            setMember({
                                ...Member,
                                idAddress: {
                                    ...Member.idAddress,
                                    sub_district_id: e,
                                    sub_district: option.props.source.name,
                                    post_code: option.props.source.post_code
                                },
                                idAddressUpdate: {
                                    ...Member.idAddressUpdate,
                                    sub_district_id: e,
                                    sub_district: option.props.source.name,
                                    post_code: option.props.source.post_code
                                }
                            })}
                    />
                </Col>
                <Col md={6} >
                    <label>รหัสไปรษณีย์</label>
                    <Input
                        size="small"
                        className="f-s-12"
                        value={Member?.idAddress.post_code}
                        disabled
                        onChange={e =>
                            setMember({
                                ...Member,
                                idAddress: {
                                    ...Member.idAddress,
                                    post_code: e.target.value
                                },
                                idAddressUpdate: {
                                    ...Member.idAddressUpdate,
                                    post_code: e.target.value
                                }
                            })}
                    />
                </Col>
            </Row>
        </>
    )
}
export default AddressForm