/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row, message, Typography, Breadcrumb } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { URL_API, axios, getToken } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import FormBanner from "./components/FormBanner";
const BannerContent = ({ history, match }) => {
  const formRef = useRef(null);
  const [onlineDel, setOnlineDel] = useState(null);
  const [mobileDel, setMobileDel] = useState(null);
  const [title, setTitle] = useState("");
  const type = match.params.type;
  const loadDataCallback = useCallback(async (id) => {
    try {
      const res = await axios.get(
        `/manage-website/banner-product-category/${id}/${type}`,
        {
          baseURL: URL_API,
          headers: { Authorization: "Bearer " + getToken() },
        }
      );

      const data = res.data;
      const values = {
        online_banner: _.find(data.files, (n) => n.document_type === "online"),
        mobile_banner: _.find(data.files, (n) => n.document_type === "mobile"),
      };
      console.log(res.data);
      setTitle(data?.category?.name);
      formRef.current.setFieldsValue({
        ...values,
      });
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    }
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.validateFieldsAndScroll((err, values) => {
        if (err) return;
        send(values);
      });
    }
  };
  const handleDelete = (type) => {
    if (type == "web") {
      setOnlineDel(formRef.current.getFieldsValue().online_banner?.id);
      formRef.current.setFieldsValue({
        online_banner: undefined,
      });
    } else {
      setMobileDel(formRef.current.getFieldsValue().mobile_banner?.id);
      formRef.current.setFieldsValue({
        mobile_banner: undefined,
      });
    }
  };
  const send = (values) => {
    Modal.confirm({
      type: "warning",
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกข้อมูลหรือไม่?",
      okText: "บันทึก",
      cancelText: "ปิด",
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            const payload = {
              ...values,
              del_online: onlineDel,
              del_mobile: mobileDel,
              online: values.online_banner,
              mobile: values.mobile_banner,
              online_banner: values.online_banner?.response,
              mobile_banner: values.mobile_banner?.response,
            };
            let url = `/manage-website/banner-product-category/${match.params.id}/${type}`;
            const res = await axios.post(url, payload, {
              baseURL: URL_API,
              headers: { Authorization: "Bearer " + getToken() },
            });
            resolve(res.data);
          } catch (error) {
            reject(error.response?.data?.message || error.message);
          }
        })
          .then((res) => {
            Modal.success({
              type: "success",
              title: "สำเร็จ",
              content: "บันทึกดข้อมูลเรียบร่้อย",
              okText: "ปิด",
              onOk() {
                history.push(`/manage-website/banner-product-category`);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              type: "error",
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    if (loadDataCallback && match.params.id !== "0") {
      loadDataCallback(match.params.id);
    }
  }, [match.params.id, loadDataCallback]);
  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/manage-website/banner-product-category">จัดการแบนเนอร์หมวดหมู่สินค้า</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <Panel>
        <PanelHeader>จัดการแบนเนอร์หมวดหมู่สินค้า</PanelHeader>
        <PanelBody>
          <Row gutter={16}>
            <Col md={10} className="text-left">
              <Typography.Title level={4}>หมวดหมู่ : {title}</Typography.Title>
            </Col>
            <Col md={14} className="text-right">
              <Button type="primary" className="mr-2" onClick={handleSubmit}>
                บันทึก
              </Button>
              <Button
                type="default"
                onClick={() =>
                  history.push(`/manage-website/banner-product-category`)
                }
              >
                ยกเลิก
              </Button>
            </Col>
          </Row>
          <FormBanner ref={formRef} handleDelete={handleDelete} />
        </PanelBody>
      </Panel>
    </>
  );
};

export default BannerContent;
