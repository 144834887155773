/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Input, Table } from 'antd';
import { FaDownload } from 'react-icons/fa';
import usePaginationHook from '../../../../utils/usePagination';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import digitalProductServices from '../../../../services/digital-product.services';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { FiArrowLeft } from 'react-icons/fi';

dayjs.locale('th');

const pageSize = 10;

const DigitalProductVotePage = () => {
  const params = useParams();
  const history = useHistory();
  const {
    data: digitalProductVoteLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const [loading2, setLoading2] = useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      searchText: '',
      status: '',
      vote: 'DESC',
      requestOrder: '',
    },
  });

  digitalProductServices.useQueryGetDigitalProductVoteByDigitalProductId(
    {
      take: pageSize,
      page,
      memberCode: watch('searchText'),
      digitalProductId: params.digitalProductId,
    },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { data } = digitalProductServices.useQueryGetDigitalProductProjectById({
    digitalProductProjectId: params.digitalProductProjectId,
  });

  const { data: data2 } = digitalProductServices.useQueryGetDigitalProductById({
    digitalProductId: params.digitalProductId,
  });

  const { mutate: downloadDigitalProductListsExcel } =
    digitalProductServices.useMutationDownloadDigitalProductVoteListsExcel(
      () => {
        setTimeout(() => {
          setLoading2(false);
        }, 600);
      }
    );

  const columns = [
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'memberCode',
      key: 'memberCode',
      render: (_, record) => <span>{record.memberCode}</span>,
    },
    {
      title: '',
      dataIndex: 'isPartner',
      key: 'isPartner',
      render: (_, record) => (
        <span>{record.isPartner ? 'รหัสร่วม' : 'รหัสหลัก'}</span>
      ),
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>{record?.voter?.firstName + ' ' + record?.voter?.lastName}</span>
      ),
    },
    {
      title: 'เบอร์โทรติดต่อกลับ',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (_, record) => <span>{record?.voter.mobile}</span>,
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.createdAt).format('DD/MMM/BBBB')}</span>
      ),
    },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (_, record) => (
    //     <div style={{ display: 'flex', justifyContent: 'center' }}>
    //       <div
    //         style={{ cursor: 'pointer', marginLeft: 10 }}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setDigitalProductId(record.id);
    //           _HandleDigitalProductFormOpen();
    //         }}
    //       >
    //         <FaEye />
    //       </div>
    //       <div
    //         style={{ cursor: 'pointer', marginLeft: 10 }}
    //         onClick={async (e) => {
    //           e.stopPropagation();
    //           const confirm = await AlertConfirm('ลบข้อมูล');
    //           if (confirm) deleteDigitalProduct({ id: record.id });
    //         }}
    //       >
    //         <MdDelete />
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{data?.title}</h3>
        <h3>/{data2?.name}</h3>
      </div>
      <Panel>
        <PanelHeader>Digital Product</PanelHeader>
        <PanelBody>
          <div
            style={{ display: 'flex', alignItems: 'flex-end' }}
            className="mb-2"
          >
            <Controller
              name="searchText"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ค้นหาด้วยรหัสนักธุรกิจ"
                    className="mr-2"
                    style={{ flex: 1 }}
                  />
                );
              }}
            />
            {/* <div
              style={{ display: 'flex', flexDirection: 'column', flex: 0.5 }}
            >
              <Typography.Text>ประสงค์ผลิตสินค้า</Typography.Text>
              <Controller
                name="requestOrder"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      style={{ flex: 0.5 }}
                      onChange={onChange}
                      placeholder="ประสงค์ผลิตสินค้า"
                      className="mr-2"
                    >
                      <Select.Option value="">ทั้งหมด</Select.Option>
                      <Select.Option value={true}>ต้องการ</Select.Option>
                      <Select.Option value={false}>ไม่ต้องการ</Select.Option>
                    </Select>
                  );
                }}
              />
            </div> */}
            {/* <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={_HandleSubmit}
            >
              <FaSearch className="mr-2" />
              ค้นหา
            </Button> */}
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={() => {
                setLoading2(true);
                downloadDigitalProductListsExcel({
                  take: -1,
                  page: 1,
                  memberCode: watch('searchText'),
                  digitalProductId: params.digitalProductId,
                });
              }}
              loading={loading2}
            >
              <FaDownload className="mr-2" />
              โหลด excel
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={digitalProductVoteLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default DigitalProductVotePage;
