import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import fitPrizesServices from '../../../../../services/fit-prizes.services';
import LegacyPrizesForm from '../form/LegacyPrizesForm';

const LegacyPrizesModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLegacyPrizes } =
    fitPrizesServices.useMutationCreateLegacyPrizes(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-prizes'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    });

  const { mutate: editLegacyPrizes } =
    fitPrizesServices.useMutationUpdateLegacyPrizes(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-prizes'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    });

  const _HandleSubmit = handleSubmit((data) => {
    const legayPrizesData = {
      imageUrl: data.imageUrl,
      title: data.title,
      type: data.type,
      active: data.active,
      startDate: moment(data.startDate).toDate(),
      endDate: moment(data.endDate).toDate(),
    };

    if (watch('formType') === 'create') {
      createLegacyPrizes(legayPrizesData);
    } else if (watch('formType') === 'edit') {
      editLegacyPrizes({ id: data.id, data: legayPrizesData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่ม Legacy Prizes'
          : 'แก้ Legacy Prizes'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyPrizesForm />
    </Modal>
  );
};

export default LegacyPrizesModal;
