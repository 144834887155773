import React from "react";
import PosOrderHeader from "../components/PosOrderHeader";
import PosOrderSearch from "../components/PosOrderSearch";
import PosOrderDetail from "../components/PosOrderDetail";
import PosOrderFooter from "../components/PosOrderFooter";
import { useWatcher } from "../hooks";

export default () => {
  useWatcher();
  return (
    <>
      <PosOrderHeader />
      <PosOrderSearch />
      <PosOrderDetail />
      <PosOrderFooter />
    </>
  );
};
