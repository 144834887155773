/* eslint-disable no-unused-vars */
import { Col, DatePicker, Row, Switch, Typography, Input } from 'antd';
import React, { useState } from 'react';

const PromotionDate = ({ forever, start_date, end_date, handleChange }) => {

    const startDateChange = (value) => {
        handleChange({
            target: {
                name: 'start_date', value
            }
        })
    }
    const endDateChange = (value) => {
        handleChange({
            target: {
                name: 'end_date', value
            }
        })
    }
    const foreverChange = (value) => {
        handleChange({
            target: {
                name: 'forever', value
            }
        })
    }
    return (
        <>
            <Row className="mb-3 mt-3" type="flex">
                <Col md={15}>
                    <Typography.Text strong>ตลอดไป</Typography.Text>
                </Col>
                <Col md={9} style={{ textAlign: 'end' }}>
                    <Switch onChange={(e) => foreverChange(e)} defaultChecked={forever} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col md={12}>
                    <DatePicker
                        style={{ minWidth: '50%' }}
                        showTime
                        format="DD/MM/YYYY hh:mm"
                        value={start_date}
                        placeholder="วันเริ่มต้น"
                        onChange={e => startDateChange(e)}
                    />
                </Col>
                <Col md={12}>
                    <DatePicker
                        style={{ minWidth: '50%' }}
                        showTime
                        format="DD/MM/YYYY hh:mm"
                        value={end_date}
                        placeholder="วันสิ้นสุด"
                        onChange={e => endDateChange(e)}
                        disabled={forever === true}
                    />
                </Col>
            </Row>
        </>
    )
}
export default PromotionDate;
