/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ControlPanel from '../components/ControlPanel';
import { Table } from 'antd';
import numeral from 'numeral';
import _ from 'lodash';
import { useHttp } from '../../../../hooks/http';

export default () => {
    const months = [
        { key: 1, short_name: 'ม.ค', long_name: 'มกราคม' },
        { key: 2, short_name: 'ก.พ.', long_name: 'กุมภาพันธ์' },
        { key: 3, short_name: 'มี.ค.', long_name: 'มีนาคม' },
        { key: 4, short_name: 'เม.ย.', long_name: 'เมษายน' },
        { key: 5, short_name: 'พ.ค.', long_name: 'พฤษภาคม' },
        { key: 6, short_name: 'มิ.ย.', long_name: 'มิถุนายน' },
        { key: 7, short_name: 'ก.ค.', long_name: 'กรกฎาคม' },
        { key: 8, short_name: 'ส.ค.', long_name: 'สิงหาคม' },
        { key: 9, short_name: 'ก.ย.', long_name: 'กันยายน' },
        { key: 10, short_name: 'ต.ค.', long_name: 'ตุลาคม' },
        { key: 11, short_name: 'พ.ย.', long_name: 'พฤศจิกายน' },
        { key: 12, short_name: 'ธ.ค.', long_name: 'ธันวาคม' }
    ];
    const [dataSource, setDataSource] = useState([]);
    const [loading, data, error] = useHttp({
        method: 'post',
        url: ``,
        data: dataSource
    }, [dataSource])

    const rederDataSource = () => {
        let newData = [];

        newData = months.map(m => {
            return {
                key: _.uniqueId('month-'),
                month: m.short_name,
                reborn_qty: Math.floor(Math.random() * 100),
                topping_qty: Math.floor(Math.random() * 100),
                topping_mini_qty: Math.floor(Math.random() * 100),
                reborn_amount: Math.floor(Math.random() * 100000),
                topping_amount: Math.floor(Math.random() * 100000),
                topping_mini_amount: Math.floor(Math.random() * 100000)
            }
        })

        setDataSource(newData);
    }

    useEffect(() => {
        rederDataSource();
        console.log(loading, data, error)
    }, []);

    const columns = [
        {
            title: 'เดือน',
            className: 'text-center text-nowrap',
            dataIndex: 'month',
            key: 'month'
        }, {
            title: 'จำนวน',
            className: 'text-center text-nowrap',
            children: [
                {
                    title: 'Reborn',
                    className: 'text-center text-nowrap',
                    dataIndex: 'reborn_qty',
                    key: 'reborn_qty',
                    render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
                }, {
                    title: 'Topping',
                    className: 'text-center text-nowrap',
                    dataIndex: 'topping_qty',
                    key: 'topping_qty',
                    render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
                }, {
                    title: 'Topping Mini',
                    className: 'text-center text-nowrap',
                    dataIndex: 'topping_mini_qty',
                    key: 'topping_mini_qty',
                    render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
                }
            ]
        }, {
            title: 'ราคา',
            className: 'text-center text-nowrap',
            children: [
                {
                    title: 'Reborn',
                    className: 'text-center text-nowrap',
                    dataIndex: 'reborn_amount',
                    key: 'reborn_amount',
                    render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
                }, {
                    title: 'Topping',
                    className: 'text-center text-nowrap',
                    dataIndex: 'topping_amount',
                    key: 'topping_amount',
                    render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
                }, {
                    title: 'Topping Mini',
                    className: 'text-center text-nowrap',
                    dataIndex: 'topping_mini_amount',
                    key: 'topping_mini_amount',
                    render: text => <p className="m-0 text-right">{numeral(text).format('0,0')}</p>
                }
            ]
        }, {
            title: 'Total',
            className: 'text-center text-nowrap',
            key: 'total',
            render: (text, record) => <p className="m-0 text-right">{numeral(record.reborn_amount + record.topping_amount + record.topping_mini_amount).format('0,0')}</p>
        }
    ]

    return (
        <Layout
            // breadcrumb={breadcrumb}
            titlePage="รายงานยอดขายสินค้า Reborn"
            subTitlePage=""
        >
            <ControlPanel titlePanel="รายงานยอดขายสินค้า Reborn">
                {/* <Row>
                    <Col md={12}><Chart type="line" options={lineChart.options} series={lineChart.series} /></Col>
                    <Col md={12}>
                        <Chart type="pie" options={pieChart.options} series={pieChart.series} />
                    </Col>
                </Row> */}
                <Table
                    bordered
                    size="small"
                    // loading={true}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: true }}
                />
            </ControlPanel>
        </Layout>
    )
}
