import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";
import { _ } from "../../../../../utils";

export const initState = {
  file: {
    data: null,
    loading: false,
  },
  sheet: {
    data: [],
    name: null,
    name_list: [],
  },
  result: {
    page: 1,
    limit: 10,
    inserted: null,
    failed_qty: 0,
    success_qty: 0,
    loading: false,
    reload: false,
  },
  searchDocument: {
    query: "",
    page: 1,
    limit: 10,
  },
  item: {
    document_no: null,
    loading: false,
    page: 1,
    limit: 0,
    total: 0,
    data: [],
  },
};

export const reducer = createReducer(initState, {
  setGlobalState: (state, { payload: { name, value } }) => {
    state[name] = {
      ...state[name],
      ...value,
    };
  },
  clearGlobalState: (state, { payload }) => {
    state[payload] = _.cloneDeep(initState[payload]);
  },
  clearImport: (state) => {
    state.file = _.cloneDeep(initState.file);
    state.sheet = _.cloneDeep(initState.sheet);
  },
  setItem: (state, { payload }) => {
    state.item.page = 1;
    state.item.data = payload;
  },
});

const ReceiveContext = createContext();
export default ReceiveContext;
