import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Error404 from "../Error404";
import PageIndex from "./index";
import Manage from "./Manage";
import ReceiveIn from "./receive-in";

const routes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/transfer/wait-add`} />
      </Route>
      <Route exact path={`${match.path}/receive-in`} component={ReceiveIn} />
      <Route
        exact
        path={`${match.path}/:type/:process`}
        component={PageIndex}
      />
      <Route
        exact
        path={`${match.path}/:type/:process/:id`}
        component={Manage}
      />
      <Route component={Error404} />
    </Switch>
  );
};

export default routes;
