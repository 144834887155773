/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, notification, Row } from "antd";
import React, { useEffect } from "react";
import SelectBank from "../../../../../components/SelectBank";
import SelectBankBranch from "../../../../../components/SelectBankBranch";
import TextValidation from "../../../../../components/TextValidation";

const CompanyBankForm = ({ Member, setMember, handleChange, errors }) => {
  useEffect(() => {
    if (Member.CompanyBank.bank_name) {
      setMember({
        ...Member,
        CompanyBank: {
          ...Member.CompanyBank,
          bank_account_name: Member.company_name_th,
        },
      });
    }
  }, [Member.CompanyBank.bank_name]);

  return (
    <Row className="mb-2" gutter={8}>
      <Col md={6}>
        <label>ธนาคาร</label>
        <TextValidation validate={errors.bank_name} />
        <SelectBank
          showSearch
          size="small"
          className="w-100 f-s-12"
          dropdownClassName="w-25"
          value={Member?.CompanyBank.bank_name}
          withIcon={true}
          filterOption={(input, option) =>
            option.props.source.name_th.indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value, option) =>
            setMember({
              ...Member,
              CompanyBank: {
                ...Member.CompanyBank,
                bank_code: value,
                bank_name: option.props.source.name_th,
              },
              CompanyBankUpdate: {
                ...Member.CompanyBankUpdate,
                bank_code: value,
                bank_name: option.props.source.name_th,
              },
            })
          }
        />
      </Col>
      <Col md={6}>
        <label>ชื่อบัญชี</label>
        <TextValidation validate={errors.bank_account_name} />
        <Input
          name="bank_account_name"
          size="small"
          className="f-s-12"
          value={Member?.CompanyBank.bank_account_name}
          onChange={(e) => handleChange(e, "CompanyBank")}
        />
      </Col>
      <Col md={6}>
        <label>เลขที่บัญชี</label>
        <TextValidation validate={errors.bank_account_number} />
        <Input
          name="bank_account_number"
          size="small"
          className="f-s-12"
          value={Member?.CompanyBank.bank_account_number}
          onChange={(e) => {
            if (/^\d+$/.test(e.target.value)) {
              handleChange(e, "CompanyBank");
            } else {
              notification.warning({
                message: "ตรวจสอบข้อมูลอีกครั้ง",
                description: "ข้อมูลเลขบัญชีธนาคารต้องเป็นตัวเลขเท่านั้น",
              });
            }
          }}
        />
      </Col>
      <Col md={6}>
        <label>สาขา</label>
        <TextValidation validate={errors.bank_branch} />
        <SelectBankBranch
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          bank_code={Member.CompanyBank.bank_code}
          size="small"
          className="f-s-12"
          value={Member?.CompanyBank.bank_branch}
          onChange={(e) =>
            setMember({
              ...Member,
              CompanyBank: {
                ...Member.CompanyBank,
                bank_branch: e,
              },
              CompanyBankUpdate: {
                ...Member.CompanyBankUpdate,
                bank_branch: e,
              },
            })
          }
        />
      </Col>
    </Row>
  );
};

export default CompanyBankForm;
