import { Col, Row } from "antd";
import React, { useContext } from "react";
import TravelPointContext from "../contexts/travel-point-context";
import numeral from "numeral";

export default function TravelPointCurrent() {
  const { state } = useContext(TravelPointContext);
  return (
    <>
      <Row
        //gutter={16}
        type="flex"
        align="middle"
        justify="center"
        // justify="space-around"
        className="justify-content-center"
        style={{
          border: "1px solid rgb(217,217,217)",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Col
          span={12}
          style={{
            paddingTop: "12px",
            borderRight: "1px solid rgb(217,217,217)",
            backgroundColor: "black",
            borderTopLeftRadius: "6px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              //fontWeight: "bold",
              display: "flex",
              color: "#FFFFFF",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            TP ทั้งหมดที่ได้รับ
          </p>
        </Col>
        <Col
          span={12}
          style={{
            paddingTop: "12px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            {state.travel_point?.total_tp !== ""
              ? numeral(state.travel_point.total_tp).format("#,#.00")
              : "-"}
          </p>
        </Col>
      </Row>

      <Row
        //gutter={16}
        type="flex"
        align="middle"
        justify="center"
        // justify="space-around"
        className="justify-content-center"
        style={{
          borderLeft: "1px solid rgb(217,217,217)",
          borderRight: "1px solid rgb(217,217,217)",
          borderBottom: "1px solid rgb(217,217,217)",
        }}
      >
        <Col
          span={12}
          style={{
            paddingTop: "12px",
            borderRight: "1px solid rgb(217,217,217)",
            backgroundColor: "#FFB800",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            TP ที่ใช้แลกแล้ว
          </p>
        </Col>
        <Col
          span={12}
          style={{
            paddingTop: "12px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            {state.travel_point?.redeem_tp !== ""
              ? numeral(state.travel_point.redeem_tp * -1).format("#,#.00")
              : "-"}
          </p>
        </Col>
      </Row>

      <Row
        //gutter={16}
        type="flex"
        align="middle"
        justify="center"
        // justify="space-around"
        className="justify-content-center"
        style={{
          borderLeft: "1px solid rgb(217,217,217)",
          borderRight: "1px solid rgb(217,217,217)",
          borderBottom: "1px solid rgb(217,217,217)",
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
        }}
      >
        <Col
          span={12}
          style={{
            paddingTop: "12px",
            borderRight: "1px solid rgb(217,217,217)",
            borderBottomLeftRadius: "6px",
            backgroundColor: "#97D700",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          type="flex"
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            TP คงเหลือ
          </p>
        </Col>
        <Col
          span={12}
          style={{
            paddingTop: "12px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            {state.travel_point?.balance_tp !== ""
              ? numeral(state.travel_point.balance_tp).format("#,#.00")
              : "-"}
          </p>
        </Col>
      </Row>
    </>
  );
}
