/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  message,
  notification,
  Row,
  Table,
  typography,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaCheck, FaPrint } from "react-icons/fa";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectBranch, {
  ValueAllBranch,
} from "../../../pos/components/SelectBranch";
import SelectShipStatus, {
  ValueShipStatus,
} from "../../components/SelectShipStatus";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import Search from "antd/lib/input/Search";
import { printform } from "../../../../../utils/printforms";
const { Text } = typography;

const Index = () => {
  const toDay = moment().format("DD/MM/YYYY");
  const [state, dispatch] = useReducer(reducer, initState);

  const [billSelectedId, setBillSelectedId] = useState(null);
  const [preview, setPreview] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  //Filter
  const [receiveฺBranchFilter, setReciveBranchFilter] = useState("");
  const [openBillBranchFilter, setOpenBillBranchFilter] = useState("");
  const [shipStatusFilter, setShipStatusFilter] = useState("");
  const valueShipStatue = ValueShipStatus(shipStatusFilter);
  const getAllBranch = ValueAllBranch();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales-report/member-orders`,
      token: true,
      params: {
        limit: 0,
        offset: 0, //limit * (page - 1),
        userId: user.id,
        query: state.filter.query,
        branch: openBillBranchFilter,
        receiptBranch: receiveฺBranchFilter,
        shipStatus: shipStatusFilter,
        dateFrom: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        dateTo: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  let openBillBranch_Name = _.find(
    getAllBranch,
    (branch) => branch?.code === openBillBranchFilter
  )?.name;

  let receiveBranch_Name = _.map(receiveฺBranchFilter, (n) => {
    let findCode = _.find(getAllBranch, (branch) => branch?.code === n);
    return findCode?.name;
  });

  const handlePreview = (id) => {
    setBillSelectedId(id);
    setPreview(true);
  };

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const exportDataData = data ? [data?.data] : [];

  const exportToExcel = () => {
    if (dataSource?.data?.length > 0) {
      let excelData = [
        ["รายงานการสั่งซื้อสินค้าของสมาชิก"],
        [
          `สาขาที่เปิดบิล: ${
            openBillBranchFilter === "" ? "ทั้งหมด" : openBillBranch_Name
          }`,
          "",
          `สาขาที่รับ: ${
            receiveฺBranchFilter === "" || receiveฺBranchFilter.includes("")
              ? "ทั้งหมด"
              : receiveBranch_Name
          }`,
          "",
          `สถานะสินค้า: ${
            shipStatusFilter === "" ? "ทั้งหมด" : valueShipStatue
          }`,
          "",
          `ช่วงวันที่  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
          "",
        ],
        [],
        [
          "ลำดับ",
          "เลขที่ใบเสร็จ",
          "เลขที่ชุดจอง",
          "วันที่ เวลา",
          "รหัสสมาชิก",
          "ชื่อสมาชิก",
          "ราคา",
          "คะแนน",
          "สถานที่รับ",
          "สถานะสินค้า",
          "สถานะบิล",
          "จำนวนสินค้า",
        ],
      ];
      function numFormat(data) {
        return {
          v: data,
          t: "n",
          s: {
            numFmt: "#,##0.00",
            alignment: { horizontal: "right" },
          },
        };
      }
      function qtyFormat(data) {
        return {
          v: data,
          t: "n",
          s: {
            numFmt: "#,##0",
            alignment: { horizontal: "center" },
          },
        };
      }

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.document_no,
            n.document_group,
            moment(n.document_date).format("DD/MM/YYYY - HH:mm"),
            n.partner_code,
            n.partner_name,
            numFormat(n.total_amount),
            numFormat(n.pv),
            n.receipt_branch_name,
            {
              v: n.ship_status_name,
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.document_status,
              s: { alignment: { horizontal: "center" } },
            },
            qtyFormat(n.total),
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
        { s: { r: 1, c: 9 }, e: { r: 1, c: 11 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานสั่งซื้อสินค้าของสมาชิก");
      XLSX.writeFile(wb, "รายงานสั่งซื้อสินค้าของสมาชิก.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานการสั่งซื้อสินค้าของสมาชิก</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={11}>
              <Search
                className="w-100 "
                placeholder="เลขที่บิล/เลขที่ชุดจอง/เลขที่ชุดใบเสร็จ/เลขที่จองย่อย/รหัสนักธุรกิจ/ชื่อนักธุรกิจ"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                onSearch={reload}
                onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={12}>
              <Row gutter={8} type="flex" justify="end">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      reload();
                      setPage(1);
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>ช่วงวันที่</Text>
            </Col>
            <Col span={5} className="mb-2">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col>
              <Text strong>สาขาที่เปิดบิล</Text>
            </Col>
            <Col span={4}>
              <SelectBranch
                allowClear
                userLogin={true}
                defaultValue={[""]}
                className="w-100"
                allowDisableBranch
                withAll={user.branch_count > 1}
                onChange={(e) => {
                  setOpenBillBranchFilter(e);
                }}
              />
            </Col>

            <Col>
              <Text strong>สาขาที่รับ</Text>
            </Col>
            <Col span={10}>
              <SelectBranch
                allowClear
                userLogin={true}
                defaultValue={[""]}
                className="w-100"
                mode="multiple"
                allowDisableBranch
                withAll={user.branch_count > 1}
                onChange={(e) => {
                  setReciveBranchFilter(e);
                }}
              />
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>สถานะสินค้า</Text>
            </Col>
            <Col span={5}>
              <SelectShipStatus
                withAll
                className="w-100"
                defaultValue={shipStatusFilter}
                onChange={(e) => {
                  setShipStatusFilter(e);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data?.data || []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.data.length,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="เลขที่ใบเสร็จ"
              dataIndex="document_no"
              align="left"
            />
            <Table.Column
              title="เลขที่ชุดจอง"
              dataIndex="document_group"
              align="left"
            />
            <Table.Column
              title="วันที่ เวลา"
              dataIndex="document_date"
              align="left"
              render={(text) => moment(text).format("DD/MM/YYYY - HH:mm")}
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสมาชิก"
              dataIndex="partner_name"
              align="left"
            />
            <Table.Column
              title="ราคา"
              dataIndex="total_amount"
              align="right"
              render={(text) => numeral(text).format("0,0.00")}
            />
            <Table.Column
              title="คะแนน"
              dataIndex="pv"
              align="right"
              render={(text) => numeral(text).format("0,0.00")}
            />
            <Table.Column
              title="สถานที่รับ"
              dataIndex="receipt_branch_name"
              align="center"
            />
            <Table.Column
              title="สถานะสินค้า"
              dataIndex="ship_status_name"
              align="center"
            />
            <Table.Column
              title="สถานะบิล"
              dataIndex="document_status"
              align="center"
            />
            <Table.Column
              title="ดูรายละเอียด"
              dataIndex="total"
              align="center"
              render={(text, record) => {
                return (
                  <Button onClick={() => handlePreview(record.document_id)}>
                    {`${text} รายการ`}
                  </Button>
                );
              }}
            />
            <Table.Column
              title=""
              dataIndex="print"
              align="center"
              render={(text, record) => {
                return (
                  <Button
                   title="พิมพ์ใบเสร็จ"
                    type="link"
                    size="small"
                    style={{ background: "#F6AB1B"}}
                    shape="circle-outline"
                    className="text-white"
                    onClick={() => {
                      // handlePrint(record)
                      // receipt(record?.ref_document_id);
                      printform("preview", record);
                    }}
                  >
                    <FaPrint />
                  </Button>
                );
              }}
            />
          </Table>

          <BillPreview
            id={billSelectedId}
            preview={preview}
            setPreview={setPreview}
          />
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
