/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Typography } from 'antdV4';
import { DatePicker, Button, Table } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import fitLegacyServices from '../../../../../services/fit-legacy.services';
import {
  legacyNotificationPartnerFormDefaultValues,
  legacyNotificationPartnerListFormSchema,
} from '../dto/legacy-notification.dto';
import LegacyNotificationPartnerListModal from '../modal/LegacyNotificationPartnerListModal';
import usePaginationHook from '../../../../../utils/usePagination';
import { MdDelete } from 'react-icons/md';
import { AlertConfirm } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import LegacyNotificationPartnerDisplayModal from '../modal/LegacyNotificationPartnerDisplayModal';

const LegacyNotificationSendForm = () => {
  const {
    watch,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useFormContext();
  const [
    legacyNotificationPartnerListFormOpen,
    setLegacyNotificationPartnerListFormOpen,
  ] = useState(false);
  const queryClient = useQueryClient();
  const [loading2, setLoading2] = useState(false);
  const [partner1, setPartner1] = useState([]);
  const [open, setOpen] = useState(false);

  const { mutate: sendLegacyNotification } =
    fitLegacyServices.useMutationSendLegacyNotification((data) => {
      setTimeout(() => {
        setLoading2(false);
      }, 600);
    });

  const {
    data: partnerList,
    setData: setPartnerList,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();

  fitLegacyServices.useQueryGetLegacyNotificationById(
    { legacyNotificationId: watch('record.id') },
    (data) => {
      setTotal(data.darftLists.length);
      setPartnerList(data.darftLists);
    }
  );

  const legacyNotificationPartnerListForm = useForm({
    defaultValues: {
      ...legacyNotificationPartnerFormDefaultValues,
      legacyNotificationId: watch('record.id'),
    },
    resolver: legacyNotificationPartnerListFormSchema(),
  });

  const _HandleLegacyNotificationPartnerListFormOpen = () => {
    setLegacyNotificationPartnerListFormOpen(true);
  };

  const _HandleLegacyNotificationPartnerListFormClose = () => {
    legacyNotificationPartnerListForm.reset({
      ...legacyNotificationPartnerFormDefaultValues,
      legacyNotificationId: watch('record.id'),
    });
    setLegacyNotificationPartnerListFormOpen(false);
  };

  const { mutate: deleteLegacyNotificationPartnerList } =
    fitLegacyServices.useMutationDeleteLegacyNotificationPartnerList(() => {
      queryClient.invalidateQueries({
        queryKey: [
          'get-legacy-notification-by-id',
          { legacyNotificationId: watch('record.id') },
        ],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
      width: '100%',
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            key="send_by_excel"
            type="primary"
            loading={loading2}
            className="mr-1"
            onClick={() => {
              setPartner1(record.partnerList.split(','));
              setOpen(true);
            }}
          >
            ดูรายการรหัสนักธุกิจ
          </Button>
          <Button
            key="send_by_excel"
            type="primary"
            loading={loading2}
            onClick={() => {
              setLoading2(true);
              sendLegacyNotification({
                ...watch('record'),
                effective_date: watch('effective_date').toDate(),
                receive_users: record.partnerList.split(','),
                send_all: null,
              });
            }}
          >
            ส่งตามรายการ
          </Button>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm)
                deleteLegacyNotificationPartnerList({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Controller
          name="effective_date"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="mr-2">
                    <Typography.Text>กำหนดเวลาส่ง</Typography.Text>
                  </div>
                  <DatePicker
                    format="DD/MM/YYYY HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    className="w-100"
                    style={{ flex: 1 }}
                    placeholder="กำหนดเวลาส่ง"
                    value={value}
                    allowClear={false}
                    onChange={(date, dateString) => {
                      onChange(moment(date));
                    }}
                  />
                </div>
                <span style={{ color: 'red' }}>
                  (หากต้องการส่งทันทีให้กำหนดเวลาปัจจุบัน)
                </span>
                <ErrorMessage
                  errors={errors}
                  name="effective_date"
                  render={({ message }) => (
                    <Typography.Text className="mb-2 text-danger">
                      {message}
                    </Typography.Text>
                  )}
                />
              </div>
            );
          }}
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 20,
          }}
        >
          <Button
            type="primary"
            onClick={_HandleLegacyNotificationPartnerListFormOpen}
          >
            เพิ่มรายการ
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={partnerList}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize: 10,
              total,
            }}
          />
        </div>
      </div>
      <LegacyNotificationPartnerDisplayModal
        partnerList={partner1}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
      <FormProvider {...legacyNotificationPartnerListForm}>
        <LegacyNotificationPartnerListModal
          open={legacyNotificationPartnerListFormOpen}
          handleClose={_HandleLegacyNotificationPartnerListFormClose}
        />
      </FormProvider>
    </div>
  );
};

export default LegacyNotificationSendForm;
