/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";

function LegacyMusic({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");
  const [fileDelete, setFileDelete] = useState([]);
  const [fileListMusic, setFileListMusic] = useState();

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      formData.append("mw_song", values.mw_song?.file?.originFileObj);

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
          file_delete: fileDelete,
        })
      );

      console.log(formData);

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path = "/manage-website/legacy-music/update")
              : (url_path = "/manage-website/legacy-music");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleChangeUpload = async (e) => {
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
    }
  };

  const handleRemoveMusic = (e) => {
    setFileListMusic([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleChangeUploadMusic = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }

      setFileListMusic([
        {
          uid: file.uid,
          status: "done",
          name: file.name,
          url: img_url,
        },
      ]);
    }
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/legacy-music",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);

        let { loadMusic } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "37") {
            loadMusic = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
          }
        }

        setFileListMusic(loadMusic);

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);
  
  const { getFieldDecorator } = form;
  return (
    <>
      <Panel>
        <PanelHeader>เพลงเลกาซี</PanelHeader>
        <PanelBody>
          <Row>
            <Row>
              <Form.Item label="Title :">
                {getFieldDecorator("title", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุ Title",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Row>
            <Row gutter={24} className="mb-2">
              <Col md={12} className="mb-2">
                <Form.Item label="เพิ่มเพลงเลกาซี : ">
                  {getFieldDecorator("mw_song", {
                    initialValue: null,
                  })(
                    <Upload.Dragger
                      accept="audio/*"
                      multiple={false}
                      fileList={fileListMusic}
                      customRequest={dummyRequest}
                      // beforeUpload={beforeUploadImg}
                      onChange={handleChangeUploadMusic}
                      onRemove={handleRemoveMusic}
                      listType="picture"
                    >
                      <AiOutlineUpload className="mr-3" />
                      Upload Audio
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                SAVE
              </Button>
            </Row>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}

export default Form.create("legacy-music")(LegacyMusic);
