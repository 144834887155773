import { Button, Col, Input, Modal, notification, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../redux/slices/claim";
import { axios, getToken, URL_API } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";

const { Text } = Typography;

const { setGlobalState, clearGlobalState, setState } = actionClaim;

const CancelModal = ({ reload = () => null }) => {
  const dispatch = useDispatch();
  const { remark, document_no, document_status } = useSelector(
    (state) => state.claim.cancelModal
  );

  const handleClickSubmit = () => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1 text-nn">
            ยกเลิกเคลมสินค้า
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(setState({ name: "loadingSubmit", value: true }));
        axios({
          method: "post",
          url: `${URL_API}/claim/cancel`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            document_no,
            document_status,
            remark: remark?.trim() || null,
          },
        })
          .then(() => {
            notification.success({ message: "บันทึกยกเลิกเคลมสินค้าแล้ว" });
            dispatch(clearGlobalState("cancelModal"));
            reload();
          })
          .catch((e) => resCatchModal(e))
          .finally(() =>
            dispatch(setState({ name: "loadingSubmit", value: false }))
          );
      },
    });
  };

  const handleClickClose = () => dispatch(clearGlobalState("cancelModal"));

  return (
    <Modal
      title={`ยืนยันยกเลิกเลขที่ใบเคลม ${document_no}`}
      visible={!!document_no}
      footer={false}
      zIndex={1080}
      width={800}
      destroyOnClose={true}
      maskClosable={false}
      closable={false}
    >
      <Row gutter={[8, 8]} className="mb-3">
        <Col span={3} className="text-right">
          หมายเหตุ
        </Col>
        <Col span={21}>
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 6 }}
            maxLength={500}
            value={remark}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "cancelModal",
                  value: { remark: e.target.value },
                })
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="text-center">
          <div>
            <Button
              className="width-100 mr-3"
              type="primary"
              ghost
              onClick={handleClickClose}
            >
              ย้อนกลับ
            </Button>
            <Button
              className="width-100"
              type="danger"
              onClick={handleClickSubmit}
            >
              ยืนยัน
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(CancelModal);
