import { Icon, Upload, message, notification } from "antd";
import React, { forwardRef, useState } from "react";
import { URL_API, axios, getToken } from "../../../../../utils";

const UploadPreview = ({
  file,
  multiple = false,
  fix_width = 0,
  fix_height = 0,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const customRequest = async (options) => {
    setLoading(true);
    const { onSuccess, onError } = options;
    try {
      const formData = new FormData();
      formData.append("file", options.file);
      formData.append("container_name", "web-content");
      const res = await axios.post(`/file/upload`, formData, {
        baseURL: URL_API,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      });

      onSuccess(res.data?.data);
      setLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      onError(error);
      setLoading(false);
    }
  };
  // ฟังก์ชันที่ใช้ในการดึงขนาดของไฟล์ภาพ
  const handleBeforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const img = new Image();

      reader.onload = (e) => {
        img.src = e.target.result;
      };

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (
          (width === fix_width && height === fix_height) ||
          (fix_width === 0 && fix_height === 0)
        ) {
          resolve(file); // อนุญาตให้อัปโหลด
        } else {
          //message.error(`ขนาดรูปภาพไม่ถูกต้อง ขนาดต้องเป็น ${fix_width}x${fix_height} แต่ขนาดที่อัปโหลดคือ ${width}x${height}`);
          notification.error({
            message: "ขนาดรูปภาพไม่ถูกต้อง",
            description: `กรุณาอัพโหลดรูปที่มีขนาด ${fix_width} x ${fix_height} `,
          });
          reject(); // ยกเลิกการอัปโหลด
        }
      };

      img.onerror = () => {
        notification.error({
          message: "ไม่สามารถอ่านไฟล์รูปภาพได้",
          description: `กรุณาอัพโหลดรูปใหม่อีกรอบ`,
        });
        //message.error('ไม่สามารถอ่านไฟล์รูปภาพได้');
        reject(); // ยกเลิกการอัปโหลด
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <Upload.Dragger
      {...props}
      showUploadList={false}
      accept=".png,.jpg"
      //beforeUpload={beforeUploadImg}
      beforeUpload={handleBeforeUpload}
      customRequest={customRequest}
      multiple={multiple}
    >
      {file && (file.azure_url || file.response) ? (
        <img
          src={file.azure_url || file.response.azure_url}
          alt="upload"
          style={{
            width: "auto",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "200px",
          }}
        />
      ) : (
        <>
          <Icon type={loading ? "loading" : "plus"} />
          <div className="ant-upload-text">Upload</div>
        </>
      )}
    </Upload.Dragger>
  );
};

export default forwardRef((props, ref) => {
  return <UploadPreview forwardRef={ref} {...props} />;
});
