import { Alert, Button, Col, Row, Table, Typography } from "antd";
import React, { useContext } from "react";
import { FaCogs, FaEye, FaHistory, FaPencilAlt, FaPrint } from "react-icons/fa";
import { getUserObj, moment, _ } from "../../../../utils";
import PreOrderContext from "../reducer";

const { Column } = Table;
const { Text } = Typography;

const getClassNameStatus = (status) => {
  switch (status) {
    case "create":
      return "text-tp";
    case "pr":
      return "text-yellows";
    case "po":
      return "text-rp";
    case "pending":
      return "text-cv";
    case "complete":
      return "text-pv";
    case "cancel":
      return "text-nn";
    default:
      return "";
  }
};

const ListTable = ({ responseData }) => {
  const {
    state: {
      search: { page, limit },
      user: { permission_status },
    },
    dispatch,
  } = useContext(PreOrderContext);
  const [loading, data, error] = responseData;

  if (error) return <Alert type="error" message={error} showIcon />;

  const renderFlowBtn = ({
    id,
    reserve_order_no,
    document_no,
    partner_code,
    is_used,
    status,
    division_id,
  }) => {
    if (!_.includes(["create", "pr", "po", "pending"], status)) return;
    if (status === "pending") {
      // call center, Line Order
      if (!permission_status[status]) return;
    } else {
      if (is_used === 0) {
        // New Product: agency (Division)
        const user = getUserObj();
        if (division_id !== user.division_id) return;
      } else if (is_used === 1) {
        // Sale Product: planning
        if (!permission_status[status]) return;
      }
    }
    return (
      <Button
        shape="circle"
        size="small"
        className="bg-tp border-tp text-white"
        onClick={() =>
          dispatch({
            type: "SET_GLOBAL_STATE",
            payload: {
              name: "billModal",
              value: {
                visible: true,
                id,
                is_used,
                division_id,
                reserve_order_no,
                reserve_no: document_no,
                partner_code,
                status,
                disabled: false,
              },
            },
          })
        }
      >
        <FaPencilAlt />
      </Button>
    );
  };

  return (
    <Row gutter={[16, 16]} type="flex">
      <Col span={24}>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={data?.data.record || []}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            size: "default",
            total: data?.data.total || 0,
            showLessItems: true,
            hideOnSinglePage: true,
            showTotal: (total, range) =>
              `${range[0]} - ${range[1]} of ${total}`,
            onChange: (currPage) =>
              dispatch({
                type: "SET_GLOBAL_STATE",
                payload: {
                  name: "search",
                  value: {
                    page: currPage,
                  },
                },
              }),
          }}
        >
          <Column
            width={150}
            title="เลขการจอง"
            key="reserve_order_no"
            dataIndex="reserve_order_no"
            align="center"
            fixed="left"
          />
          <Column
            width={170}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">ช่องทาง</div>
            }
            key="bill_branch_name"
            dataIndex="bill_branch_name"
          />
          <Column
            width={150}
            title="วันที่ดำเนินการ"
            key="document_date"
            dataIndex="document_date"
            align="center"
            render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
          />
          <Column
            width={100}
            title="รหัสนักธุรกิจ"
            key="partner_code"
            dataIndex="partner_code"
            align="center"
          />
          <Column
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                ชื่อผู้สั่งซื้อ
              </div>
            }
            key="partner_name"
            dataIndex="partner_name"
          />
          <Column
            width={80}
            title="ประเภท"
            key="reserve_type_text"
            dataIndex="reserve_type_text"
            align="center"
          />
          <Column
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                พนักงานรับเรื่อง
              </div>
            }
            key="cashier_name"
            dataIndex="cashier_name"
          />
          <Column
            width={150}
            className="position-relative"
            title={<div className="ant-table-absolute-title-center">สถานะ</div>}
            key="status_text"
            dataIndex="status_text"
            render={(text, { status }) => (
              <Text className={getClassNameStatus(status)}>{text}</Text>
            )}
          />
          <Column
            width={150}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                <FaCogs />
              </div>
            }
            key="action"
            align="right"
            fixed="right"
            render={({
              id,
              reserve_order_no,
              document_no,
              partner_code,
              status,
              item,
            }) => (
              <>
                {renderFlowBtn({
                  id,
                  reserve_order_no,
                  document_no,
                  partner_code,
                  status,
                  is_used: item.is_used,
                  division_id: item.division_id,
                })}
                <Button
                  shape="circle"
                  size="small"
                  className="bg-pv border-pv text-white mx-2"
                  onClick={() =>
                    dispatch({
                      type: "SET_GLOBAL_STATE",
                      payload: {
                        name: "billModal",
                        value: {
                          visible: true,
                          id,
                          division_id: item.division_id,
                          is_used: item.is_used,
                          reserve_order_no,
                          reserve_no: document_no,
                          partner_code,
                          status,
                          disabled: true,
                        },
                      },
                    })
                  }
                >
                  <FaEye />
                </Button>
                <Button
                  shape="circle"
                  size="small"
                  className="bg-rp border-rp text-white"
                >
                  <FaPrint />
                </Button>
                <Button
                  shape="circle"
                  size="small"
                  className="ml-2"
                  onClick={() =>
                    dispatch({
                      type: "SET_GLOBAL_STATE",
                      payload: {
                        name: "historyModal",
                        value: {
                          document_no: reserve_order_no,
                        },
                      },
                    })
                  }
                >
                  <FaHistory />
                </Button>
              </>
            )}
          />
        </Table>
      </Col>
    </Row>
  );
};

export default ListTable;
