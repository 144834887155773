/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Tooltip, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FaArchive, FaFileInvoiceDollar, FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { _ } from "../../../../utils";
import { PosDataAction } from "../ducks/data-api";
import { Creators } from "../ducks/operation-form";
import BillPopup from "./BillPopup";
import Notification from "./Notification";
// import SelectBranch from "./SelectBranch";

const PosTopMenu = ({
  withOperator,
  history,
  branch,
  auth,
  setBill,
  ...props
}) => {
  const [showBill, setShowBill] = useState(false);
  // const [showReserve, setShowReserve] = useState(false);
  const [showNoti, setShowNoti] = useState(false);

  // if (props.auth.branch_code == null || props.auth.branch_code === "") {
  //   // return Modal.warning({
  //   //     title: 'คำเตือน',
  //   //     content: 'ไม่พบสาขาของพนักงาน',
  //   //     okText: 'ตกลง',
  //   //     onOk() {
  //   //         history.replace(`/`)
  //   //     }
  //   // })
  //   return <Alert type="warning" message="ไม่พบสาขาของพนักงาน" />;
  // }

  // useEffect(() => {
  //   const handleChangeSize = () => {
  //     setCollapse(window.innerWidth < 1700);
  //   };
  //   handleChangeSize();
  //   window.addEventListener("resize", handleChangeSize);
  // }, []);

  useEffect(() => {
    const code = branch.code
      ? branch.code
      : auth.branch_code
      ? auth.branch_code
      : "";
    const name = branch.name
      ? branch.name
      : auth.branch_name
      ? auth.branch_name
      : "";
    setBill({ branch: { code, name } });
  }, [branch.code, branch.name, auth.branch_code, auth.branch_name, setBill]);

  const handleCloseBill = useCallback(() => {
    setShowBill(false);
  }, []);

  const handleCloseNoti = useCallback(() => {
    setShowNoti(false);
  }, []);

  return (
    <>
      <Row type="flex" className="pos-top-menu bg-gray-60 align-items-center">
        <Col
          sm={{ span: 24, order: 2 }}
          xxl={{ span: 14, order: 1 }}
          className="d-flex align-items-center"
        >
          {props.operator.buyer_code && (
            <>
              <Typography.Text
                className="mr-2 text-white"
                strong
                style={{ fontSize: "16px" }}
              >
                ผู้ดำเนินการ
              </Typography.Text>
              <Typography.Text
                className="mr-4 text-white"
                style={{ fontSize: "14px" }}
              >
                {props.operator.buyer_name}
              </Typography.Text>
              <Typography.Text
                className="mr-2 text-white"
                strong
                style={{ fontSize: "16px" }}
              >
                รหัสสมาชิก
              </Typography.Text>
              <Typography.Text
                className="mr-4 text-white"
                style={{ fontSize: "14px" }}
              >
                {props.operator.buyer_code}
              </Typography.Text>
              <Typography.Text
                className="mr-2 text-white"
                strong
                style={{ fontSize: "16px" }}
              >
                เบอร์โทร
              </Typography.Text>
              <Typography.Text
                className="mr-4 text-white"
                style={{ fontSize: "14px" }}
              >
                {props.operator.buyer_mobile}
              </Typography.Text>
              <Typography.Text
                className="mr-2 text-white"
                strong
                style={{ fontSize: "16px" }}
              >
                ประเภท
              </Typography.Text>
              <Typography.Text
                className="text-white"
                style={{ fontSize: "14px" }}
              >
                {props.operator.buyer_type}
              </Typography.Text>
            </>
          )}
        </Col>
        <Col
          sm={{ span: 24, order: 1 }}
          xxl={{ span: 10, order: 2 }}
          className="d-flex align-items-center justify-content-end"
        >
          <Button
            size="small"
            className="text-dark border-dark mr-2"
            onClick={() => history.replace(`/reserve`)}
          >
            <FaArchive className="mr-2" />
            <small>ข้อมูลการจอง</small>
          </Button>
          {/* <Button disabled size="small" className="text-dark border-dark mr-2">
            <FaReadme className="mr-2" />
            <small>ข้อมูลสินค้า</small>
          </Button>
          <Button disabled size="small" className="text-dark border-dark mr-2">
            <FaUserAlt className="mr-2" />
            <small>สมาชิก</small>
          </Button>
          <Button disabled size="small" className="text-dark border-dark mr-2">
            <FaChartLine className="mr-2" />
            <small>สรุปยอดขาย</small>
          </Button> */}
          <Button
            size="small"
            className="text-dark border-dark mr-2"
            onClick={() => setShowBill(true)}
          >
            <FaFileInvoiceDollar className="mr-2" />
            <small>ใบเสร็จ</small>
          </Button>
          {/* <SelectBranch
            size="small"
            className="mr-2 width-150"
            value={branch?.code}
            userLogin={true}
            onChange={(value, option) => {
              setBill({ branch: { code: value, name: option.props.children } });
            }}
          /> */}
          <Typography.Text
            className="mb-0 text-nowrap text-center text-white display-user-login mr-2"
            style={{ fontSize: "12px" }}
          >
            {auth.name} | {auth.branch_name}
          </Typography.Text>
          {/* <Tooltip title="แจ้งเตือน" placement="bottom">
            <Button
              type="link"
              size="small"
              className="p-0 mr-2"
              onClick={() => setShowNoti(true)}
            >
              <span className="d-flex justify-content-center align-items-center bg-rp text-white rounded-circle p-5 border border-white">
                <FaRegBell />
              </span>
            </Button>
          </Tooltip>
          <Tooltip title="จดหมาย" placement="bottom">
            <Button type="link" size="small" className="p-0 mr-2">
              <span className="d-flex justify-content-center align-items-center bg-pv text-white rounded-circle p-5 border border-white">
                <FaRegEnvelope />
              </span>
            </Button>
          </Tooltip> */}
          <Tooltip title="ออก" placement="bottom">
            <Button
              type="link"
              size="small"
              className="p-0"
              onClick={() => history.replace(`/`)}
            >
              <span className="d-flex justify-content-center align-items-center bg-black text-white rounded-circle p-5 border border-white">
                <FaTimes />
              </span>
            </Button>
          </Tooltip>
        </Col>
      </Row>

      <BillPopup visible={showBill} onCancel={handleCloseBill} />

      {/* <ReservePopUp visible={showReserve} onCancel={handleCloseReserve} /> */}

      <Notification visible={showNoti} onCancel={handleCloseNoti} />
    </>
  );
};

const mapStateToProps = (state) => {
  const orders = state.pos.operation.orders;
  return {
    branch: orders.branch,
    operator: _.omit(state.pos.operation, ["step", "orders", "payment"]),
    auth: state.auth,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setBill: Creators.setBill,
    loadReportParameter: PosDataAction.loadReportParameter,
  })(PosTopMenu)
);
