import { Button, Col, Row } from "antd";
import React from "react";
import { FaExchangeAlt } from "react-icons/fa";
import SelectPeriod from "../../../../../components/SelectPeriod";
import SelectOption from "../../../pos/components/SelectOption";
import { useCommissionMutate } from "../contexts/commission.context";

const Criteria = ({ onTransfer }) => {
  const {
    state: { loading, period, filter, progress },
    dispatch,
  } = useCommissionMutate();

  return (
    <Row gutter={16} type="flex" className="mb-3 align-items-center">
      <Col span={24} md={14} className="d-flex mb-2">
        <SelectPeriod
          placeholder="เลือก Period"
          className="mr-2 width-250"
          value={period ?? undefined}
          callbackData={(e) =>
            dispatch({
              type: "SET_PERIOD",
              payload: e[0]?.period || null,
            })
          }
          onSelect={(e) => dispatch({ type: "SET_PERIOD", payload: e })}
        />
        <SelectOption
          className="width-250"
          options={[
            {
              text: "Success",
              value: "success",
            },
            {
              text: "Error",
              value: "error",
            },
          ]}
          defaultValue={filter.result}
          onChange={(value) =>
            dispatch({ type: "SET_FILTER", payload: { result: value } })
          }
        />
      </Col>
      <Col span={24} md={10} className="d-flex justify-content-end mb-2">
        <Button
          type="primary"
          loading={loading}
          onClick={onTransfer}
          disabled={
            period === "" ||
            progress.loading ||
            progress.pending === 0 ||
            progress.inprogress > 0
          }
        >
          <FaExchangeAlt className="mr-2" /> Transfer Data
        </Button>
      </Col>
    </Row>
  );
};

export default Criteria;
