import React, { memo } from "react";
import { Row, Col, Typography } from "antd";
import numeral from "numeral";
import { _ } from "../utils";

const { Text } = Typography;

const RenderItem = memo(({ data, no }) => (
  <Row gutter={8} className="mt-1 font-size-12">
    <Col span={1} className="text-center">
      <Text className="text-dark">{no}</Text>
    </Col>
    <Col span={15}>
      <Text className="text-dark">{`${data.product_code} ${data.product_name_local}`}</Text>
    </Col>
    <Col span={3} className="text-center">
      <Text className="text-dark">
        {numeral(data.unit_price).format("0,0.00")}
      </Text>
    </Col>
    <Col span={2} className="text-center">
      <Text className="text-dark">{numeral(data.qty).format("0,0")}</Text>
    </Col>
    <Col span={3} className="text-center">
      <Text className="text-dark">
        {numeral(data.unit_price * data.qty).format("0,0.00")}
      </Text>
    </Col>
  </Row>
));

const ItemBillPreviewRedeem = ({
  list = [],
  deliveryAmount,
  remark = [],
  totalDiscount = 0,
}
) => (
  <>
    <Row gutter={8} className="mt-2 font-size-12">
      <Col span={15} offset={1}>
        <Text>รายการ</Text>
      </Col>
      <Col span={3} className="text-center">
        <Text>คะแนน/หน่วย</Text>
      </Col>
      <Col span={2} className="text-center">
        <Text>จำนวน</Text>
      </Col>
      <Col span={3} className="text-center">
        <Text>คะแนน</Text>
      </Col>
    </Row>

    {list.map((n, i) => (
      <RenderItem key={i} data={n} no={i + 1} />
    ))}

    <Row className="mt-2 font-size-12">
      <Col span={8} offset={16} className="border-top text-right pt-1" />
    </Row>
    <Row className="font-size-12">
      <Col span={3} offset={16} className="text-right">
        <Text strong className="text-dark">
          รวมคะแนนที่ใช้แลก
        </Text>
      </Col>
      <Col span={3} offset={2} className="text-center">
        <Text className="text-dark">
          {numeral(
            _.reduce(
              list,
              (sum, n) =>
                (sum =
                  parseFloat(sum) +
                  parseFloat(n.unit_price) * parseFloat(n.qty)),
              0
            )
          ).format("0,0.00")}
        </Text>
      </Col>
    </Row>
    <Row className="font-size-12">
      <Col span={3} offset={16} className="text-right">
        <Text strong className="text-dark">
          รวมส่วนลด
        </Text>
      </Col>
      <Col span={3} offset={2} className="text-center">
        <Text className="text-dark">
          {totalDiscount !== 0 && "-"}
          {numeral(totalDiscount).format("0,0.00")}
        </Text>
      </Col>
    </Row>
    <Row className="font-size-12">
      <Col span={3} offset={16} className="text-right">
        <Text strong className="text-dark">
          รวมทั้งหมด
        </Text>
      </Col>
      <Col span={3} offset={2} className="text-center">
        <Text className="text-dark">
          {numeral(
            _.reduce(
              list,
              (sum, n) =>
                (sum =
                  parseFloat(sum) +
                  parseFloat(n.unit_price) * parseFloat(n.qty)),
              0
            )
          ).format("0,0.00")}
        </Text>
      </Col>
    </Row>
    {remark.map((n, i) => (
      <Row
        key={i}
        className={`font-size-12 ${i === remark.length - 1 ? "mb-2" : ""}`}
      >
        <Col span={24}>
          <Text className="mr-2" strong>
            หมายเหตุ
          </Text>
          <Text>{n.remark}</Text>
        </Col>
      </Row>
    ))}
  </>
);
export default memo(ItemBillPreviewRedeem);
