import React from 'react'
import { Row, Col, Input } from 'antd'

const PanelSource = ({data, index}) => {
    return (
        <div className="p-20 mb-5" style={{ backgroundColor: '#f1f5f8', fontSize: 14, borderRadius: 5 }}>
            <Row>
                <Col>
                    <h4 className="pull-left">รายการที่ {index+1}</h4>
                    <i className="fas fa-times pull-right" style={{ cursor: 'pointer' }} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <b className="pull-left">ชื่อสินค้า :</b>
                </Col>
                <Col md={10}>
                    <span style={{ color: '#b3b3b3' }}>{data.product_name}</span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <b className="pull-left">รหัสสินค้า :</b>
                </Col>
                <Col md={10}>
                    <span style={{ color: '#b3b3b3' }}>{data.product_code}</span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <b className="pull-left">ราคา :</b>
                </Col>
                <Col md={10}>
                    <span style={{ color: '#b3b3b3' }}>250.00</span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <b className="pull-left">จำนวน :</b>
                </Col>
                <Col md={10}>
                    <Input />
                </Col>
            </Row>
        </div>
    )
}

export default PanelSource
