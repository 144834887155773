

export const DivStyles = {
  marginBottom: "16px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  overflow: "hidden",
  width: "100%",
  height: "220px",
  border: "1px solid #E8E8E8",
  borderRadius: "8px",
};

export const ImgStyles = {
  maxWidth: "100%",
  maxHeight: "100%",
  padding: "8px",
  objectFit: "cover",
};
