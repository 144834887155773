import { createSlice } from "@reduxjs/toolkit";

const initState = {
  active: "distributor",
  search: {
    query: "",
    display_type: "waiting",
    status: "Active",
    page: 1,
    limit: 10,
  },
  detail: {
    loading: false,
    active: "member",
    member: {
      avatar: "",
    },
    married: null,
    sponsor: {},
    upline: {},
    address: {
      active: "1",
      data: [],
    },
    bank: [],
    document: {
      active: "",
      documents: [],
      documents_list: [],
    },
    remark: [],
  },
};

const memberSlice = createSlice({
  name: "member",
  initialState: initState,
  reducers: {
    SET_ACTIVE: (state, { payload }) => {
      state.active = payload;
      state.search = {
        ...initState.search,
      };
    },
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    SET_DETAIL_ACTIVE: (state, { payload }) => {
      state.detail.active = payload;
    },
    SET_ADDRESS_ACTIVE: (state, { payload }) => {
      state.detail.address.active = payload;
    },
    SET_DOCUMENT_ACTIVE: (state, { payload }) => {
      state.detail.document.active = payload;
    },
    SET_LOAD_DETAIL: (state) => {
      state.detail = {
        ...initState.detail,
        loading: true,
      };
    },
    SET_LOAD_DETAIL_SUCCESS: (state, { payload }) => {
      state.detail = {
        ...initState.detail,
        ...payload,
        loading: false,
        address: {
          ...state.detail.address,
          data: payload.address,
        },
        married: payload.married,
        document: {
          active:payload.document_list.length > 0 ? payload.document_list[0].code : "",
          documents_data: payload.document,
          documents_list: payload.document_list,
        },
      };
    },
    SET_LOAD_DETAIL_FAIL: (state, { payload }) => {
      state.detail.loading = false;
    },
  },
});

export const actionMember = memberSlice.actions;

export const activeSelector = (state) => state.member.active;
export const searchSelector = (state) => state.member.search;

export const detailActiveSelector = (state) => state.member.detail.active;
export const addressActiveSelector = (state) =>
  state.member.detail.address.active;
export const documentActiveSelector = (state) =>
  state.member.detail.document.active;
export const avatarSelector = (state) => state.member.detail.member.avatar;

export const memberSelector = (state) => state.member.detail.member;
export const marriedSeleector = (state) => state.member.detail.married;
export const sponsorSelector = (state) => state.member.detail.sponsor;
export const uplineSelector = (state) => state.member.detail.upline;
export const addressSelector = (state) => state.member.detail.address.data;
export const bankSelector = (state) => state.member.detail.bank;
export const remarkSelector = (state) => state.member.detail.remark;
export const documentsSelector = (state) =>
  state.member.detail.document.documents_data;
export const documentsListSelector = (state) =>
  state.member.detail.document.documents_list;

const MemberReducer = memberSlice.reducer;

export default MemberReducer;
