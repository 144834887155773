import React from "react";
import { Route, Switch } from "react-router-dom";

// routes
import FileContent from "./FileContent";
import FileList from "./FileList";

// error page
import Error404 from "../../Error404";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}`} exact component={FileList} />
      <Route path={`${match.path}/:id`} exact component={FileContent} />
      <Route component={Error404} />
    </Switch>
  );
};
