/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Col,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fixDisCount, fixProductType } from "../../../../config/fix-value";
import { useHttp } from "../../../../hooks/http";
import { URL_API, _ } from "../../../../utils";
import { Creators } from "../ducks/operation-form";
import ModalControl from "./ModalControl";

const { Option } = Select;

const initDiscount = {
  discount_type: null,
  discount_name: null,
  discount_amount: 0,
  collect_point: 1,
  remark: "",
};

const DiscountPopUp = ({ visible, handleClose, ...props }) => {
  const [discount, setDiscount] = useState(initDiscount);
  const [discountData, setDiscountData] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/type`,
      params: {
        process_type: "discount_type",
      },
    },
    []
  );

  const handleChange = (name, value) => {
    const discount_name = data.data.find((n) => n.code === value)?.name || null;
    setDiscount((prevDiscount) => {
      return {
        ...prevDiscount,
        discount_name:
          name === "discount_type" && discount_name
            ? discount_name
            : prevDiscount.discount_name,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (props.vip_member_count_starter) {
      const discountVip = _.find(
        discountData,
        (n) => n.code === fixDisCount.starter_vip_user
      );
      if (discountVip) {
        // const discount_amount =
        //   props.vip_member_count_starter * props.item_starter_price;
        // if (discount_amount) {
        const billAdd = [];
        _.forEach(props.vip_member_count_starter, (n) => {
          billAdd.push({
            ...initDiscount,
            discount_type: discountVip.code,
            discount_name: discountVip.name,
            discount_amount: props.item_starter_price,
            auto_add: true,
            activeKey: n,
          });
        });
        props.addBillDiscount(billAdd);
        props.clearPaymentIgnoreActiveKey();
        // } else {
        //   notification.warning({
        //     message: "ไม่สามารถเพิ่มส่วนลดชุดสมัคร VIP Member อัตโนมัติได้",
        //     description: `ชุดสมัครมีราคาเป็น ${discount_amount}`,
        //   });
        // }
      }
    }
  }, [
    discountData,
    props.vip_member_count_starter.length,
    props.item_starter_price,
  ]);

  // * disabled auto add discount change to add voucher when complete order
  // useEffect(() => {
  //   if (props.vip_member_count_renew) {
  //     const discountVip = _.find(
  //       discountData,
  //       (n) => n.code === fixDisCount.renew_vip_user
  //     );
  //     if (discountVip) {
  //       // const discount_amount =
  //       //   props.vip_member_count_renew * props.item_renew_price;
  //       // if (discount_amount) {
  //       const billAdd = [];
  //       _.forEach(props.vip_member_count_renew, (n) => {
  //         billAdd.push({
  //           ...initDiscount,
  //           discount_type: discountVip.code,
  //           discount_name: discountVip.name,
  //           discount_amount: props.item_renew_price,
  //           auto_add: true,
  //           activeKey: n,
  //         });
  //       });
  //       props.addBillDiscount(billAdd);
  //       props.clearPaymentIgnoreActiveKey();
  //       // } else {
  //       //   notification.warning({
  //       //     message: "ไม่สามารถเพิ่มส่วนลดชุดต่ออายุ VIP Member อัตโนมัติได้",
  //       //     description: `ชุดสมัครมีราคาเป็น ${discount_amount}`,
  //       //   });
  //       // }
  //     }
  //   }
  // }, [
  //   discountData,
  //   props.vip_member_count_renew.length,
  //   props.item_renew_price,
  // ]);

  useEffect(() => {
    if (data && data.data[0]) {
      let data_filter = data.data;
      props.discounts.forEach((item) => {
        data_filter = data_filter.filter((n) => n.code !== item.discount_type && 
        ![
          fixDisCount.renew_vip_user,
          fixDisCount.starter_vip_user,
        ].includes(n.code));
      });
      setDiscountData(data_filter);
      // if(data_filter.length != 0 ){
      //   setDiscount((prevDiscount) => {
      //     return {
      //       ...prevDiscount,
      //       discount_name: data_filter[0].name,
      //       discount_type: data_filter[0].code,
      //     };
      //   });
      // }
    }
  }, [data, props.discounts.length,visible]);

  useEffect(() => {
    if (visible === false) {
      setDiscount(initDiscount);
    }
  }, [visible]);

  const handleClickOk = () => {
    let discountAmount = discount
    if (discount.discount_type === fixDisCount.delivery) {
      if(props.total_delivery <= 0 ){
        return notification.warning({
          message: "ไม่สามารถใช้ส่วนลดได้",
          description: "เนื่องจากไม่มีค่าจัดส่ง",
        });
      }
      if(discount.discount_amount > props.total_delivery){
        discountAmount = {
          ...discount,
          discount_amount : props.total_delivery,
        }
      }
      if(props.voucher_delivery >= props.total_delivery){
        return notification.warning({
          message: "ไม่สามารถใช้ส่วนลดได้",
          description: "เนื่องจากมีการใช้ส่วนลดค่าจัดส่งครบแล้ว",
        });
      }
    }else{
      if(discount.discount_amount > props.total_amount){
        discountAmount = {
          ...discount,
          discount_amount : props.total_amount,
        }
      }
    }
    if (!discount.discount_amount) {
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณากรอกจำนวน",
      });
    }
    if (!discount.discount_type) {
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณาเลือกประเภทส่วนลด",
      });
    }
    props.addDiscount([discountAmount]);
    props.clearPaymentIgnoreActiveKey();
    handleClose();
  };
  return (
    <ModalControl
      visible={visible}
      footerLine={false}
      destroyOnClose={true}
      width={800}
      onCancel={handleClose}
    >
      <Typography.Title level={3} className="text-center mb-4">
        ส่วนลด
      </Typography.Title>
      <Row gutter={16} className="mb-3">
        <Col span={7} className="text-right align-self-center">
          <Typography.Text strong>ประเภทส่วนลด</Typography.Text>
        </Col>
        <Col span={10}>
          {error ? (
            <Alert type="error" message={error} />
          ) : (
            <Select
              loading={loading}
              className="w-100"
              name="discount_type"
              placeholder="กรุณาเลือกประเภทส่วนลด"
              onChange={(e) => handleChange("discount_type", e)}
            >
              {discountData
                .filter(
                  (n) =>
                    ![
                      fixDisCount.renew_vip_user,
                      fixDisCount.starter_vip_user,
                    ].includes(n.code)
                )
                .map((n) => (
                  <Option key={n.code} value={n.code}>
                    {n.name}
                  </Option>
                ))}
            </Select>
          )}
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col span={7} className="text-right align-self-center">
          <Typography.Text strong>จำนวน</Typography.Text>
        </Col>
        <Col span={10}>
          <InputNumber
            allowClear
            autoComplete="off"
            className="w-100"
            name="discount_amount"
            onFocus={(e) => e.target.select()}
            value={discount.discount_amount}
            onChange={(value) => handleChange("discount_amount", value)}
            onKeyUp={(e) => e.key === "Enter" && handleClickOk()}
          />
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col span={7} className="text-right align-self-center">
          <Typography.Text strong>หมายเหตุ</Typography.Text>
        </Col>
        <Col span={10}>
          <Input
            allowClear
            autoComplete="off"
            name="remark"
            onChange={(e) => handleChange("remark", e.target.value)}
          />
        </Col>
      </Row>
      {/* <Row gutter={16} className="mb-5">
        <Col span={7} className="text-right align-self-center">
          <Typography.Text strong>เงื่อนไขการให้ PV</Typography.Text>
        </Col>
        <Col span={10}>
          <Radio.Group onChange={e => handleChange("collect_point", e.target.value)} value={discount.collect_point}>
            <Radio value={1}>ให้คะแนน</Radio>
            <Radio value={0}>ไม่ให้คะแนน</Radio>
          </Radio.Group>
        </Col>
      </Row> */}
      <Row className="mt-5">
        <Col className="text-center">
          <button
            type="button"
            className="pos-btn pos-btn bg-pv text-white text-nowrap"
            style={{ minWidth: "100px" }}
            onClick={handleClickOk}
          >
            ตกลง
          </button>
        </Col>
      </Row>
    </ModalControl>
  );
};

function mapStateToProps(state) {
  const { orders, payment } = state.pos.operation;
  const { itemKeys, itemByKey } = orders;
  const activeKey = payment.activeKey || itemKeys[0];
  const item_stater_renew_price = _.reduce(
    itemKeys,
    (res, key) => {
      if (res.item_starter_price !== 0 && res.item_renew_price !== 0)
        return res;
      const order = itemByKey[key];
      const item_starter_key = _.find(
        order.items.itemKeys,
        (item_key) =>
          order.items.itemByKey[item_key].product_type ===
          fixProductType.starter
      );
      const item_renew_key = _.find(
        order.items.itemKeys,
        (item_key) =>
          order.items.itemByKey[item_key].product_type === fixProductType.renew
      );
      return {
        item_starter_price:
          res.item_starter_price || !item_starter_key
            ? res.item_starter_price
            : order.items.itemByKey[item_starter_key].unit_price,
        item_renew_price:
          res.item_renew_price || !item_renew_key
            ? res.item_renew_price
            : order.items.itemByKey[item_renew_key].unit_price,
      };
    },
    {
      item_starter_price: 0,
      item_renew_price: 0,
    }
  );
  const vip_member_count_stater_renew = _.reduce(
    itemKeys,
    (res, key) => {
      const order = itemByKey[key];
      if (order.partner_type.toLowerCase() !== "member") return res;

      const has_item_starter = _.some(
        order.items.itemKeys,
        (item_key) =>
          order.items.itemByKey[item_key].product_type ===
          fixProductType.starter
      );

      const has_item_renew = _.some(
        order.items.itemKeys,
        (item_key) =>
          order.items.itemByKey[item_key].product_type === fixProductType.renew
      );

      return {
        vip_member_count_starter: has_item_starter
          ? [...res.vip_member_count_starter, key]
          : res.vip_member_count_starter,
        vip_member_count_renew: has_item_renew
          ? [...res.vip_member_count_renew, key]
          : res.vip_member_count_renew,
      };
    },
    {
      vip_member_count_starter: [],
      vip_member_count_renew: [],
    }
  );
  
  return {
    ...item_stater_renew_price,
    ...vip_member_count_stater_renew,
    discounts: itemByKey[activeKey]
      ? itemByKey[activeKey].discount.itemKeys.reduce(
          (discount_res, discount_key) => [
            ...discount_res,
            itemByKey[activeKey].discount.itemByKey[discount_key],
          ],
          []
        )
      : [],
    total_delivery: itemByKey[activeKey]
      ? itemByKey[activeKey].total_delivery
      : 0,
    total_amount :  itemByKey[activeKey] ? 
      _.reduce(itemByKey[activeKey].items.itemByKey, (sum, item) => {
          return sum + (item.unit_price * item.qty || 0);
      },0) : 0,
    voucher_delivery: itemByKey[activeKey] ?
      _.reduce(itemByKey[activeKey].voucher.itemByKey, (sum, voucher) => {
      if (voucher.type === "3") {
          return sum + voucher.value;
      }
        return sum;
      },0) : 0,
    
    // discounts: orders.itemKeys.reduce((order_res, order_key) => {
    //   const { discount } = orders.itemByKey[order_key];
    //   return [
    //     ...order_res,
    //     ...discount.itemKeys.reduce(
    //       (discount_res, discount_key) => [
    //         ...discount_res,
    //         discount.itemByKey[discount_key],
    //       ],
    //       []
    //     ),
    //   ];
    // }, []),
  };
}

export default connect(mapStateToProps, {
  addDiscount: Creators.addDiscount,
  addBillDiscount: Creators.addBillDiscount,
  clearPaymentIgnoreActiveKey: Creators.clearPaymentIgnoreActiveKey,
})(DiscountPopUp);
