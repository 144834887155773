/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, _ } from "../../../../../utils";
import AddressModal from "./AddressModal";

const Index = ({
  size,
  memberId,
  memberType,
  country,
  value,
  onChange,
  withAll,
  allowOther,
  onAddAddress,
}) => {
  const [options, setOptions] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/member_address`,
      params: {
        member_id: memberId,
        member_type: memberType,
        country,
      },
    },
    [memberId, memberType]
  );

  useEffect(() => {
    if (data && data.data) {
      let newData = _.map(data.data, (n) => ({
        value: n.id,
        text: n.name || "ที่อยู่",
        source: { ...n },
      }));
      if (allowOther) {
        newData = [
          ...newData,
          {
            value: 0,
            text: "ระบุที่อยู่จัดส่ง",
          },
        ];
      }
      setOptions(newData);
    }
  }, [data, allowOther]);

  if (error) return <Alert type="error" showIcon message={error} />;

  const handleChange = (value, option) => {
    if (value === 0) return setShowAddAddress(true);
    onChange(value, option);
  };

  return (
    <>
      <Select
        className="w-100"
        placeholder="เลือกที่อยู่"
        size={size}
        loading={loading}
        dropdownStyle={{ zIndex: 1080 }}
        dropdownMatchSelectWidth={false}
        value={value}
        onChange={handleChange}
        onSelect={(e) => e === 0 && setShowAddAddress(true)}
      >
        {withAll ? <Select.Option value="">ทั้งหมด</Select.Option> : null}
        {_.map(options, (n, i) => (
          <Select.Option key={i} value={n.value} source={n.source}>
            {n.text}
          </Select.Option>
        ))}
      </Select>
      {allowOther && (
        <AddressModal
          visible={showAddAddress}
          onOk={onAddAddress}
          onCancel={() => setShowAddAddress(false)}
        />
      )}
    </>
  );
};

export default memo(Index);
