import { createReducer } from "@reduxjs/toolkit";
import React, { createContext, useContext, useReducer } from "react";

const EthicsMutateContext = createContext(true);

const initState = {
  loading: false,
  search: {
    member_type: "distributor",
    query: "",
    status: "Active",
    display_type: "waiting",
    page: 1,
    limit: 10,
  },
  source: {
    data: [],
    total: 0,
  },
  modal: {
    name: "",
    props: {},
  },
};

const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  CLEAR_SEARCH: (state) => {
    state.search = {
      ...initState.search,
    };
  },
  SET_SEARCH: (state, { payload }) => {
    state.search = {
      ...state.search,
      ...payload,
    };
  },
  SET_MEMBER_TYPE: (state, { payload }) => {
    state.search = {
      ...initState.search,
      member_type: payload,
    };
  },
  CLEAR_SOURCE: (state) => {
    state.source = {
      ...initState.source,
    };
  },
  SET_SOURCE: (state, { payload }) => {
    state.source = {
      ...state.source,
      ...payload,
    };
  },
  SHOW_MODAL: (state, { payload }) => {
    state.modal = {
      ...state.modal,
      ...payload,
    };
  },
  HIDE_MODAL: (state) => {
    state.modal = {
      ...initState.modal,
    };
  },
});

export const EthicsMutateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <EthicsMutateContext.Provider value={{ state, dispatch }}>
      {children}
    </EthicsMutateContext.Provider>
  );
};

export const useEthicsMutate = () => {
  const context = useContext(EthicsMutateContext);
  if (!context)
    throw new Error(
      "Ethics Mutate Context must be used within Ethics Mutate Provider"
    );
  return context;
};
