import React from "react";
import { Switch, Route } from "react-router-dom";

import LotList from "./LotList";
import LotForm from "./LotForm";

import Error404 from "../../Error404";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} children={<LotList />} />
      <Route exact path={`${match.path}/:id/:type?`} children={<LotForm />} />
      <Route component={Error404} />
    </Switch>
  );
};
