/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select, DatePicker } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { classScheduleFormDefaultValues } from '../dto/class';
import classConstants from '../../constants/classConstants';
import moment from 'moment';
import ImageUpload from '../upload/ImageUpload';

const ClassScheduleForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const { execute: getClassScheduleById } = useAsync(
    fitStudioService.getClassScheduleById,
    {
      onSuccess: (data) => {
        reset({
          ...data,
          trainerId: data.trainer.id,
          branchId: data.trainer.fitnessBranch.id,
          formType: 'edit',
          start_date: moment(data.start_date),
          end_date: moment(data.end_date),
        });
      },
    }
  );

  const { data: branchLists } = useAsync(fitStudioService.getAllFitnessBranch, {
    immediate: true,
  });

  const { execute: getFitnessTrainersByFitnessBranchId, data: trainerLists } =
    useAsync(fitStudioService.getFitnessTrainersByFitnessBranchId, {});

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getClassScheduleById(watch('id'));
    } else {
      reset(classScheduleFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  useEffect(() => {
    if (watch('branchId')) {
      getFitnessTrainersByFitnessBranchId(watch('branchId'));
    }
  }, [watch('branchId')]);

  return (
    <div>
      <Controller
        name="imageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={classConstants.category.width}
                  height={classConstants.category.height}
                  errors={errors}
                  notRequired
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ชื่อคลาสเรียน</Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>คำบรรยาย</Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยาย"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="max"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  จำนวนนักเรียนสูงสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="10"
                  type="number"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="attribute_value"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="branchId"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  เลือกสาขา <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {branchLists &&
                    branchLists.length > 0 &&
                    branchLists.map((item) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.branchMaster.Name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="branchId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="trainerId"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  เลือกเทรนเนอร์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {trainerLists?.data &&
                    trainerLists.data.length > 0 &&
                    trainerLists.data.map((item) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.displayName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="trainerId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="start_date"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่เริ่มต้น <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  className="w-100"
                  placeholder="วันที่เริ่มต้น"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="end_date"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่สิ้นสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default ClassScheduleForm;
