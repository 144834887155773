/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageBuyer from "./buyer";
import PosLayout from "./components/PosLayout";
import { PosDataAction } from "./ducks/data-api";
import { Creators } from "./ducks/operation-form";
import PageIndex from "./operator";
import PageOrder from "./order";
import PagePayment from "./payment";

const Index = ({ match, history, buyer, bill, clear, clearRedeemPoint }) => {
  const { type, process } = match.params;

  // disable book redeem
  useEffect(() => {
    if (type === "reserve") history.replace(`/pos/sale/operator`);
  }, []);

  useEffect(() => {
    if (!type) {
      return history.replace(`/pos/sale/operator`);
    }
    if (buyer) {
      if (process !== "operator") {
        clear();
        clearRedeemPoint();
        return history.replace(`/pos/${type}/operator`);
      }
    } else if (bill) {
      if (process !== "buyer") {
        return history.replace(`/pos/${type}/buyer`);
      }
    }
  }, []);

  const renderPage = () => {
    switch (process) {
      case "buyer":
        return <PageBuyer />;

      case "order":
        return <PageOrder />;

      case "payment":
        return <PagePayment />;

      default:
        return <PageIndex />;
    }
  };
  return <PosLayout>{renderPage()}</PosLayout>;
};

const mapStateToProps = (state) => {
  return {
    buyer: state.pos.operation.buyer_code === "",
    bill:
      !state.pos.operation.orders.activeKey ||
      state.pos.operation.orders.activeKey === "",
  };
};

export default withRouter(
  connect(mapStateToProps, {
    clear: Creators.clear,
    clearRedeemPoint: PosDataAction.clearRedeemPoint,
  })(Index)
);
