import { Select } from "antd";
import React from "react";

const SelectPriority = (props) => (
  <Select
    placeholder="เลือกลำดับความสำคัญ"
    className="w-100"
    dropdownStyle={{ zIndex: 1080 }}
    {...props}
  >
    <Select.Option value={1}>ต่ำ</Select.Option>
    <Select.Option value={2}>สูง</Select.Option>
  </Select>
);

export default SelectPriority;
