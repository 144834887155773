import React, { forwardRef } from "react";
import { useApi } from "../hooks/http2";
import { URL_API, _ } from "../utils";
import SelectOption from "./SelectOption";

const SelectDepartment = ({ division_id, ...props }) => {
  const list = useApi({
    baseURL: URL_API,
    url: `/setting/master/department`,
    token: true,
  });

  return (
    <SelectOption
      loading={list.loading}
      data={_.map(list.fetch?.data || [], (n) => ({
        value: n.id,
        text: n.name_local,
      }))}
      allText={
        (list.fetch?.data || []).length > 0 ? "เลือกแผนก" : "-- ไม่มีข้อมูล --"
      }
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectDepartment forwardRef={ref} {...props} />;
});
