import React from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../Error404";
import PageIndex from "./index";

const routes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={PageIndex} />
    <Route component={Error404} />
  </Switch>
);

export default routes;
