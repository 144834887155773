import React from "react";
import Detail from "./Detail";
import Manage from "./Manage";
import { Row, Col } from "antd";

const Index = ({ disable, formRef }) => (
  <>
    <Row gutter={[8, 8]} className="bg-gray-60 my-2">
      <Col span={24} className="pt-2"></Col>
    </Row>
    {disable ? <Detail /> : <Manage ref={formRef} />}
  </>
);

export default Index;
