import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PermissionRoute from './permission/routes';
import MasterRoute from './master/routes';

import SystemStatus from "./system-status";

import Error404 from '../Error404';


export default ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.path}/permission`} component={PermissionRoute} />
            <Route path={`${match.path}/master`} component={MasterRoute} />
            <Route path={`${match.path}/system-status`} component={SystemStatus} />
            <Route component={Error404} />
        </Switch>
    )
}
