import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";
import { _ } from "../../utils";

export const initState = {
  file: {
    id: null,
    azure_url: null,
    file_type: null,
    file_name: null,
    file_size: 0,
    temp: true,
  },
  fileOption: {
    acceptImage: true,
    acceptPDF: false,
    acceptVideo: false,
  },
  upLoadProgress: 0,
  showAdd: false,
  showCamera: false,
  loading: false,
  deleting: false,
};

export const reducer = createReducer(initState, {
  setState: (state, { name, payload }) => {
    state[name] = payload;
  },
  setStateMulti: (state, { payload }) => {
    const keys = _.keys(payload);
    _.forEach(keys, (key) => {
      state[key] = payload[key];
    });
  },
  clearGlobalState: (state, { payload }) => {
    state[payload] = _.cloneDeep(initState[payload]);
  },
  clear: (state) => {
    state.file = { ...initState.file };
    state.fileOption = { ...initState.fileOption };
    state.upLoadProgress = initState.upLoadProgress;
    state.showAdd = initState.showAdd;
    state.showCamera = initState.showCamera;
    state.loading = initState.loading;
    state.deleting = initState.deleting;
  },
});

const UploadContext = createContext();
export default UploadContext;
