/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Table, Typography } from "antd";
import React from "react";
import { FaCogs, FaEye, FaHistory, FaPencilAlt, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import PrintButton from "../../../../components/PrintButton";
import { actionClaim } from "../../../../redux/slices/claim";
import { getUserObj, moment, _ } from "../../../../utils";

const { Text } = Typography;
const { Column } = Table;

const { setGlobalState } = actionClaim;

const DocumentTable = ({ loading, data, total }) => {
  const { process: process_params } = useParams();
  const user = getUserObj();
  const history = useHistory();
  const dispatch = useDispatch();
  const { page, limit } = useSelector((state) => state.claim.searchDocument);
  const consider = useSelector((state) => state.claim.consider);

  const renderEdit = ({ document_no, status, branch_code, claim_type }) => {
    const is_no_permission_branch =
      status === "wait-branch" &&
      _.every(
        consider.branch,
        (n) => n.user_id !== user.user_id || n.branch_code !== branch_code
      );
    const is_no_permission_manager =
      status === "wait-manager" &&
      _.every(
        consider.manager,
        (n) => n.user_id !== user.user_id || n.claim_type !== claim_type
      );
    if (
      process_params === "history" ||
      (process_params === "pending" &&
        (is_no_permission_branch || is_no_permission_manager))
    )
      return;

    return (
      <Button
        shape="circle"
        size="small"
        className="bg-tp border-tp text-white mr-2"
        onClick={() =>
          history.push({
            pathname: `${process_params}/${document_no}`,
          })
        }
      >
        <FaPencilAlt />
      </Button>
    );
  };

  const renderCancel = ({ document_no, status, branch_code, claim_type }) => {
    const is_no_permission_branch =
      status === "wait-branch" &&
      _.every(
        consider.branch,
        (n) => n.user_id !== user.user_id || n.branch_code !== branch_code
      );
    const is_no_permission_manager =
      status === "wait-manager" &&
      _.every(
        consider.manager,
        (n) => n.user_id !== user.user_id || n.claim_type !== claim_type
      );
    if (
      process_params === "history" ||
      (process_params === "pending" &&
        (is_no_permission_branch || is_no_permission_manager))
    )
      return;
    if (!_.includes(["wait", "pending"], process_params)) return;
    return (
      <Button
        shape="circle"
        size="small"
        className="bg-nn border-nn text-white mr-2"
        onClick={() =>
          dispatch(
            setGlobalState({
              name: "cancelModal",
              value: {
                document_no,
                document_status: status,
              },
            })
          )
        }
      >
        <FaTimes />
      </Button>
    );
  };

  const renderPrint = (record) => {
    // console.log('record:',record);
    if (!_.includes(["result", "history"], process_params)) return;
    return <PrintButton record={record} classsName="mr-2" />;
  };

  return (
    <Table
      size="small"
      bordered
      loading={loading}
      dataSource={data}
      rowKey="row_id"
      scroll={{ x: "max-content" }}
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showLessItems: true,
        hideOnSinglePage: true,
        onChange: (page) =>
          dispatch(setGlobalState({ name: "searchDocument", value: { page } })),
      }}
    >
      <Column
        width={60}
        title="#"
        key="row_id"
        dataIndex="row_id"
        align="center"
      />
      <Column
        width={150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">เลขที่ใบเคลม</div>
        }
        key="document_no"
        dataIndex="document_no"
      />
      <Column
        width={150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">เลขที่ใบเสร็จ</div>
        }
        key="ref_document_no"
        dataIndex="ref_document_no"
      />
      <Column
        width={data.length === 0 ? 150 : undefined}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">
            ชื่อผู้ทำรายการเคลม
          </div>
        }
        key="claim_by_name"
        dataIndex="claim_by_name"
        render={(text) => <div className="min-width-100">{text}</div>}
      />
      <Column
        width={data.length === 0 ? 150 : undefined}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">
            ชื่อเจ้าของใบเสร็จ
          </div>
        }
        key="partner_name"
        dataIndex="partner_name"
        render={(text) => <div className="min-width-100">{text}</div>}
      />
      <Column
        width={150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">สาขารับเรื่อง</div>
        }
        key="user_branch_name"
        dataIndex="user_branch_name"
      />
      <Column
        width={150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">สาขารับเคลม</div>
        }
        key="branch_name"
        dataIndex="branch_name"
      />
      <Column
        width={150}
        title="วันที่ เวลา"
        align="center"
        key="document_date"
        render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
        dataIndex="document_date"
      />
      <Column
        width={100}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">
            {process_params === "pending" ? "ผู้พิจารณา" : "สถานะ"}
          </div>
        }
        key="status"
        render={(
          _text,
          { status, status_text, consider_name, consider_status }
        ) => {
          let text_style, consider_status_text;
          switch (status) {
            case "draft":
              text_style = "text-rp";
              break;
            case "waiting":
              text_style = "text-tp";
              break;
            case "wait-branch":
              text_style = "text-sp";
              break;
            case "wait-manager":
              text_style = "text-nn";
              break;
            case "cancel":
              text_style = "text-cv";
              break;
            default:
              if (_.includes(["considered", "complete"], status)) {
                if (consider_status === "approve") {
                  text_style = "text-pv";
                  consider_status_text = "อนุมัติ";
                } else {
                  text_style = "text-nn";
                  consider_status_text = "ไม่อนุมัติ";
                }
              }
              break;
          }
          return (
            <Text className={`${text_style}`}>
              {process_params === "pending"
                ? consider_name
                : consider_status_text || status_text}
            </Text>
          );
        }}
      />
      <Column
        width={150}
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">
            <FaCogs />
          </div>
        }
        key="action"
        align="right"
        render={(record) => {
          const { document_no, status, branch_code, claim_type, consider_status } = record;
          console.log('record:', record)
          return (
            <>
              {renderEdit({ document_no, status, branch_code, claim_type })}
              <Button
                shape="circle"
                type="primary"
                size="small"
                className="mr-2"
                onClick={() => {
                  // dispatch(
                  //   setGlobalState({
                  //     name: "claimModal",
                  //     value: { document_no, is_preview: true },
                  //   })
                  // )
                  history.push({
                    pathname: `${process_params}/${document_no}`,
                    search: "?preview=1",
                  });
                }}
              >
                <FaEye />
              </Button>
              {renderCancel({ document_no, status, branch_code, claim_type })}
              {/* :: renderPrint :: */}
              {((status === "complete" || status === "considered") && consider_status === "approve") && renderPrint({ ...record, document_no: record.ref_document_no })}
              <Button
                shape="circle"
                size="small"
                onClick={() =>
                  dispatch(
                    setGlobalState({
                      name: "historyModal",
                      value: { document_no, page: 1 },
                    })
                  )
                }
              >
                <FaHistory />
              </Button>
            </>
          );
        }}
      />
    </Table>
  );
};

export default DocumentTable;
