import React from "react";
import { Alert, Select } from "antd";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, _ } from "../../../../../utils";

const SelectReason = ({ className, withAll, ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/transfer-receive/master`,
      params: {
        process_type: "transfer-cancel",
      },
      token: true,
    },
    []
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select
      loading={loading}
      dropdownStyle={{ zIndex: "1080" }}
      className={`w-100 ${className || ""}`}
      {...props}
    >
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {_.map(data?.data, (n) => (
        <Select.Option key={n.code} value={n.code}>
          {n.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectReason;
