/* eslint-disable no-unused-vars */
import { Button, Col, notification, Row, Table } from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import * as XLSX from "xlsx-js-style";
import React, { useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectYear from "../../components/SelectYear";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [yearFilter, setYearFilter] = useState("");
  const limit = 50;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/commission/commission-summary`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        year: yearFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  let newData = data ? data : [];

  const yearOp = [
    ..._.map(newData.yearData, (data) => {
      return { value: data.period_year, text: data.period_year };
    }),
  ];

  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานสรุปคอมมิชชั่นตามรอบคำนวณ"],
        [
          `ปี : ${yearFilter} `,
          "",
          "",
          `วันที่ดึงข้อมูล : ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [""],
        [
          "Period",
          "ยอดขายที่เกิดขึ้นในระบบ",
          "PV ยอดขายที่เกิดขึ้นในระบบ",
          "PV คอม",
          "SaleRelatedPV คอม",
          "โบนัสค่าแนะนำ",
          "โบนัสบริหารทีม",
          "โบนัสแมทชิ่ง",
          "RebaseAmount",
          "SpecialBonus",
          "CashBack",
          "Total commission",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            n.period,
            n.sale,
            n.pv_sale,
            n.pv_commission,
            numeral(n.pv_commission * ((6 * 100) / 107)).format("0,0.00"),
            numeral(n.suggest_amount).format("0,0.00"),
            numeral(n.team_manage_amount).format("0,0.00"),
            numeral(n.bonus_matching_amount).format("0,0.00"),
            numeral(n.rebase_amount).format("0,0.00"),
            numeral(n.special_bonus).format("0,0.00"),
            "", // cashback ไม่มีข้อมูลใน db
            numeral(
              n.suggest_amount +
                n.team_manage_amount +
                n.bonus_matching_amount +
                n.rebase_amount +
                n.special_bonus
            ).format("0,0.00"),

            // {v: n.amount === "none" ? "" : numeral(n.amount).format("0,0.00"), s: { alignment: { horizontal: "right" } } },
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },
      ];

      var wscols = [
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      var wsrows = [
        { hpx: 18 }, // "pixels"
        { hpx: 18 }, // "pixels"
        { hpx: 18 }, // "pixels"
        { hpx: 24 }, // "pixels"
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      // const ws = XLSX.utils.json_to_sheet(excelData); //------old version

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;
      ws["I4"].s = centerStyles;
      ws["J4"].s = centerStyles;
      ws["K4"].s = centerStyles;
      ws["L4"].s = centerStyles;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานสรุปคอมมิชชั่นตามรอบคำนวณ");
      XLSX.writeFile(wb, "รายงานสรุปคอมมิชชั่นตามรอบคำนวณ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสรุปคอมมิชชั่นตามรอบคำนวณ</PanelHeader>

        <PanelBody>
          <Row>
            <Col span={1} className="pt-2 p-l-10">
              <Text strong>ปี :</Text>
            </Col>
            <Col span={6} className="p-r-10 p-b-10">
              {/* <SelectOption
                placeholder="ปี"
                options={yearOp}
                className="w-100"
                onChange={(e) => {
                  setYearFilter(e);
                }}
              /> */}
              <SelectYear
                placeholder="กรุณาระบุปี"
                onChange={(e) => {
                  setYearFilter(e);
                }}
              />
            </Col>
            <Row gutter={8} type="flex" justify="end">
              <Col>
                <Button type="primary" onClick={reload}>
                  แสดงรายงาน
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="text-white d-flex align-items-center"
                  icon="file-excel"
                  onClick={exportToExcel}
                >
                  Export Excel
                </Button>
              </Col>
            </Row>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={newData.data}
            rowKey="period"
            scroll={{ x: "max-content" }}
            pagination={false}
          >
            <Table.Column title="Period" dataIndex="period" align="center" />
            <Table.Column
              title="ยอดขายที่เกิดขึ้นในระบบ"
              dataIndex="sale"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="PV ยอดขายที่เกิดขึ้นในระบบ"
              dataIndex="pv_sale"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="PV คอม"
              dataIndex="pv_commission"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="SaleRelatedPV คอม"
              align="right"
              render={({ pv_commission }) => {
                let text = pv_commission * ((6 * 100) / 107);
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="โบนัสค่าแนะนำ"
              dataIndex="suggest_amount"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="โบนัสบริหารทีม"
              dataIndex="team_manage_amount"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="โบนัสแมทชิ่ง"
              dataIndex="bonus_matching_amount"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="RebaseAmount"
              dataIndex="rebase_amount"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="SpecialBonus"
              dataIndex="special_bonus"
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="CashBack"
              dataIndex=""
              align="right"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Total commission"
              align="right"
              render={({
                suggest_amount,
                team_manage_amount,
                bonus_matching_amount,
                rebase_amount,
                special_bonus,
              }) => {
                let text =
                  suggest_amount +
                  team_manage_amount +
                  bonus_matching_amount +
                  rebase_amount +
                  special_bonus;
                return numeral(text).format("0,0.00");
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
