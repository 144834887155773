/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { createReducer, current } from "@reduxjs/toolkit";
import { notification } from "antd";
import { createContext } from "react";
import { _ } from "../../../../utils";
import { mapDataToItemKey } from "../../../util/helper/map-data";

export const initState = {
  tagReload: false,
  previewSummary: false,
  previewSummaryDocumentNo: null,
  selected: {
    key: null,
    type: null,
  },
  qrProductKeySelect: null,
  listTab: [
    { title: "รอจัดสินค้า", value: "wait" },
    { title: "พร้อมจ่าย", value: "pending" },
    { title: "สินค้าค้างรับคลังบางนา", value: "to-warehouse" },
    { title: "จ่ายสินค้าเรียบร้อย", value: "complete" },
  ],
  search: {
    query: "",
    limit: 10,
    page: 1,
    date_from: null,
    date_to: null,
    bill_branch_code: "",
    // branch_name: null,
  },
  bill: {
    consignment: "",
    total_qty_delivery: 0,
    remark: 0,
  },
  items: {
    itemKeys: [],
    itemByKey: {},
  },
  authority: {
    itemKeys: [],
    itemByKey: {},
  },
};

export const reducer = createReducer(initState, {
  setState: (state, { name, payload }) => {
    state[name] = payload;
  },
  setGlobalState: (state, { name, payload }) => {
    state[name] = {
      ...state[name],
      ...payload,
    };
  },
  clearGlobalState: (state, { payload }) => {
    state[payload] = _.cloneDeep(initState[payload]);
  },
  clearCreate: (state) => {
    state.previewSummary = initState.previewSummary;
    state.previewSummaryDocumentNo = initState.previewSummaryDocumentNo;
    state.qrProductKeySelect = initState.qrProductKeySelect;
    state.selected = { ...initState.selected };
    state.bill = { ...initState.bill };
    state.items = _.cloneDeep(initState.items);
    state.authority = _.cloneDeep(initState.authority);
  },
  clearPreviewSummary: (state) => {
    state.previewSummary = initState.previewSummary;
    state.previewSummaryDocumentNo = initState.previewSummaryDocumentNo;
    if (state.bill.document_no) {
      state.qrProductKeySelect = initState.qrProductKeySelect;
      state.selected = { ...initState.selected };
      state.bill = { ...initState.bill };
      state.items = _.cloneDeep(initState.items);
      state.authority = _.cloneDeep(initState.authority);
    }
  },
  loadBillData: (state, { payload }) => {
    const { items, ...bill } = payload;
    state.bill = { ...initState.bill, ...bill, document_date: new Date() };
    state.items = _.reduce(
      items,
      (res, n) => ({
        itemKeys: [...res.itemKeys, n.product_code],
        itemByKey: {
          ...res.itemByKey,
          [n.product_code]: { ...n, qty_delivery: 0, qr: [] },
        },
      }),
      { itemKeys: [], itemByKey: {} }
    );
  },
  loadData: (state, { payload }) => {
    const { authority_doc, items, ...bill } = payload;
    const { total_qty_delivery, ...itemData } = _.reduce(
      items,
      (res, n) => ({
        total_qty_delivery: res.total_qty_delivery + n.qty_delivery,
        itemKeys: [...res.itemKeys, n.product_code],
        itemByKey: {
          ...res.itemByKey,
          [n.product_code]: { ...n },
        },
      }),
      { itemKeys: [], itemByKey: {}, total_qty_delivery: 0 }
    );
    state.previewSummaryDocumentNo = bill.document_no;
    state.bill = { ...initState.bill, ...bill, total_qty_delivery };
    state.items = itemData;
    state.authority = mapDataToItemKey(authority_doc);
  },
  setDeliveryQty: (state, { payload }) => {
    const { key, value } = payload;
    if (value > state.items.itemByKey[key].qty_balance)
      return notification.warning({
        message: "ไม่สามารถดำเนินรายการได้",
        description: "จำนวนสินค้าไม่ถูกต้อง",
      });
    const prev_delivery = state.items.itemByKey[key].qty_delivery;
    const diff_change = value - prev_delivery;
    if (diff_change < 0) {
      const minTotalQrItem = _.minBy(
        state.items.itemByKey[key].bom,
        "total_qr"
      );
      const minTotalQrValue = minTotalQrItem ? minTotalQrItem.total_qr : null;
      if (
        state.items.itemByKey[key].product_type_set === "Kit/Package" &&
        minTotalQrValue
      ) {
        if (minTotalQrValue == prev_delivery) {
          return notification.warning({
            message: "ไม่สามารถดำเนินการได้",
            description: "กรุณาตรวจสอบข้อมูล qr code",
          });
        }
      } else {
        const qr = [...state.items.itemByKey[key].qr];
        if (value < qr.length) {
          return notification.warning({
            message: "ไม่สามารถดำเนินการได้",
            description: "กรุณาตรวจสอบข้อมูล qr code",
          });
          // const diff_qr_change = value - qr.length;
          // qr.splice(qr.length - diff_qr_change * -1, qr.length);
          // state.items.itemByKey[key].qr = qr;
        }
      }
    }
    state.items.itemByKey[key].qty_delivery = value;
    state.bill.total_qty_delivery += diff_change;
  },
  setItemValueQr: (state, { payload }) => {
    const {
      key,
      value: { qr, ...value },
    } = payload;
    let diff_change = 0;
    let qty_delivery = 0;
    const prev_delivery = state.items.itemByKey[key].qty_delivery; //จำนวนก่อนหน้า
    if (state.items.itemByKey[key].product_type_set === "Kit/Package") {
      for (let i = 0; i < state.items.itemByKey[key].bom.length; i++) {
        const bomItem = state.items.itemByKey[key].bom[i];
        if (bomItem.qr) {
          if (bomItem.qr.length % bomItem.qty === 0) {
            state.items.itemByKey[key].bom[i] = {
              ...state.items.itemByKey[key].bom[i],
              total_qr: bomItem.qr.length / bomItem.qty,
            };
          } else {
            state.items.itemByKey[key].bom[i] = {
              ...state.items.itemByKey[key].bom[i],
              total_qr: Math.floor(bomItem.qr.length / bomItem.qty),
            };
          }
        }
      }
      const minTotalQrItem = _.minBy(
        state.items.itemByKey[key].bom,
        "total_qr"
      );
      const minTotalQrValue = minTotalQrItem ? minTotalQrItem.total_qr : null;
      diff_change = minTotalQrValue - prev_delivery;
      qty_delivery = minTotalQrValue;
    } else {
      diff_change = qr.length - prev_delivery;
      qty_delivery = qr.length;
    }
    state.items.itemByKey[key] = {
      ...state.items.itemByKey[key],
      ...value,
      qr,
      qty_delivery: qty_delivery,
    };
    state.bill.total_qty_delivery += diff_change;
  },
  setItemValueBomQr: (state, { payload }) => {
    const { item_key, product_code, barcode, qr } = payload;
    const index = _.findIndex(
      state.items.itemByKey[item_key].bom,
      (n) => n.ref_product_code === product_code
    );
    if (!~index) return;
    if (
      state.items.itemByKey[item_key].bom[index].qty *
        state.items.itemByKey[item_key].qty_balance <=
      (state.items.itemByKey[item_key].bom[index]?.qr?.length || 0)
    ) {
      return  notification.warning({
        message: `สินค้า ${state.items.itemByKey[item_key].bom[index].ref_product_name}`,
        description: "มีจำนวน QR ที่ใส่เกินจำนวน",
      });
    }
    const curr_qr = state.items.itemByKey[item_key].bom[index].qr || [];
    state.items.itemByKey[item_key].bom[index].barcode = barcode;
    if (qr !== undefined) {
      state.items.itemByKey[item_key].bom[index].qr = _.orderBy(
        [...curr_qr, qr],
        "ref_store_qr_id",
        "asc"
      );
    }
    for (let i = 0; i < state.items.itemByKey[item_key].bom.length; i++) {
      const bomItem = state.items.itemByKey[item_key].bom[i];
      if (bomItem.qr) {
        // console.log(bomItem.qr.length / bomItem.qty)
        if (bomItem.qr.length % bomItem.qty === 0) {
          state.items.itemByKey[item_key].bom[i] = {
            ...state.items.itemByKey[item_key].bom[i],
            total_qr: bomItem.qr.length / bomItem.qty,
          };
        } else {
          // console.log(bomItem.qr.length / bomItem.qty) //0.33333
          state.items.itemByKey[item_key].bom[i] = {
            ...state.items.itemByKey[item_key].bom[i],
            total_qr: Math.floor(bomItem.qr.length / bomItem.qty),
            // console.log(Math.floor(bomItem.qr.length / bomItem.qty)); // 0
          };
        }
      }
    }
    const minTotalQrItem = _.minBy(
      state.items.itemByKey[item_key].bom,
      "total_qr"
    );
    const minTotalQrValue = minTotalQrItem ? minTotalQrItem.total_qr : null;
    const prev_delivery = state.items.itemByKey[item_key].qty_delivery;
    const diff_change = minTotalQrValue - prev_delivery;
    state.items.itemByKey[item_key].qty_delivery = minTotalQrValue;
    state.bill.total_qty_delivery += diff_change;
    // }
  },
  delItemBomQr: (state, { payload }) => {
    const { item_key, bom_qr, bom_i, bom_product } = payload;
    state.items.itemByKey[item_key].bom = _.reduce(
      state.items.itemByKey[item_key].bom,
      (res, n) => [
        ...res,
        {
          ...n,
          qr: _.filter(
            n.qr,
            (qr, key) => {
              return !(
                bom_i.includes(key) && bom_product.includes(qr.ref_product_code)
              );
            }
            // qr: _.filter(n.qr, (qr) =>
            // _.every(bom_qr, (m) => qr.qr_running !== m)
          ),
        },
      ],
      []
    );
  },
  setTagReload: (state) => {
    state.tagReload = !state.tagReload;
  },
  delAuthority: (state, { payload }) => {
    const newItemKeys = state.authority.itemKeys.filter((i) => i !== payload);
    state.authority.itemKeys = newItemKeys;
  },
  setRemarkDocument: (state, { payload }) => {
    state.bill = {
      ...state.bill,
      remark: payload,
    };
  },
});

const ArrangeContext = createContext();
export default ArrangeContext;
