import { Alert, Select } from "antd";
import React, { useEffect } from "react";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";

// All master Voucher
const { Option } = Select;
const SelectVoucherList = ({ callBackData, ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/setting/master/voucher?status=active`,
    },
    []
  );

  useEffect(() => {
    data && callBackData && callBackData(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Alert type="error" message={error} />;

  return (
    <Select loading={loading} {...props}>
      {data?.data.map((n) => (
        <Option key={n.id} value={n.id}>
          {`${n.code} - ${n.name}`}
        </Option>
      ))}
    </Select>
  );
};

export default React.memo(SelectVoucherList);
