/* eslint-disable no-unused-vars */
import {
  Button,
  DatePicker,
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Radio,
  notification,
  Typography,
  Table,
} from "antd";
import moment from "moment";
import React, { useReducer, useEffect, useState } from "react";
import { Panel, PanelHeader, PanelBody } from "../../util/panel";
import _ from "lodash";
import axios from "axios";
import { getToken, URL_API } from "../../../utils";
import { useHttp } from "../../../hooks/http";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { Link, Router, useRouteMatch, Switch, Route } from "react-router-dom";
import NewsList from "./news-list";
import NewsForm from "./news-form";

const { Text } = Typography;

const Index = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={NewsList} exact />
      <Route path={`${match.path}/news-create`} component={NewsForm} exact />
      <Route
        path={`${match.path}/news-create/:newsType`}
        component={NewsForm}
        exact
      />
      <Route
        path={`${match.path}/news-create/:newsType/:newsId`}
        component={NewsForm}
      />
    </Switch>
  );
};

export default Index;
