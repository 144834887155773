import React, { useContext } from "react";
import {
  Row,
  Col,
  Typography,
  DatePicker,
  Input,
  Form,
  Button,
  notification,
  Modal,
  Radio,
} from "antd";
import axios from "axios";
import { getToken, URL_API } from "../../../../../utils";
import PreOrderContext from "../../reducer";
import { resCatchModal } from "../../../../util/helper";
import CancelByMember from "./CancelByMember";

const { Text } = Typography;

const SendPending = ({ form, onComplete }) => {
  const { getFieldDecorator, getFieldsValue } = form;
  const {
    state: {
      billModal: { reserve_order_no, status },
    },
    dispatch,
  } = useContext(PreOrderContext);
  const { have_product } = getFieldsValue();

  const handleSubmit = () =>
    form.validateFieldsAndScroll((err, res) => {
      if (err) return;
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "คุณต้องการบันทึกรับเรื่องหรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          dispatch({ type: "SET_LOADING", payload: true });
          axios({
            method: "post",
            url: `${URL_API}/pre-order/flow/pending`,
            headers: { Authorization: "Bearer " + getToken() },
            data: { ...res, reserve_order_no, document_status: status },
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: `บันทึกข้อมูลเรียบร้อย`,
              });
              onComplete();
            })
            .catch((e) => {
              resCatchModal(e);
            })
            .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
        },
      });
    });

  return (
    <Form name="send-pending" labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
      <Row className="bg-gray-60 py-1 px-2 mb-3">
        <Col span={24}>
          <Text strong className="text-white">
            ดำเนินการ
          </Text>
        </Col>
      </Row>
      <Row gutter={8} type="flex">
        <Col span={24}>
          <Form.Item className="d-flex mb-0" label="สินค้า">
            {getFieldDecorator("have_product", { initialValue: 1 })(
              <Radio.Group>
                <Radio value={1}>มีสินค้า</Radio>
                <Radio value={0}>ไม่มีสินค้า</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {have_product === 1 && (
        <>
          <Row gutter={8} type="flex">
            <Col span={24}>
              <Form.Item className="d-flex mb-0" label="หมายเลข PO">
                {getFieldDecorator("po_no")(
                  <Input autoComplete="off" maxLength={50} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} type="flex">
            <Col span={24}>
              <Form.Item className="d-flex mb-0" label="S/N (ถ้ามี)">
                {getFieldDecorator("sn")(
                  <Input autoComplete="off" maxLength={50} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} type="flex">
            <Col span={24}>
              <Form.Item className="d-flex mb-0" label="วันที่ได้รับสินค้า">
                {getFieldDecorator("receive_date")(
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    className="w-100"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} type="flex">
            <Col span={24}>
              <Form.Item className="d-flex mb-0" label="เลขพัสดุจัดส่ง">
                {getFieldDecorator("tracking_no")(
                  <Input autoComplete="off" maxLength={50} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} type="flex">
            <Col span={24}>
              <Form.Item className="d-flex mb-0" label="บริษัทขนส่ง">
                {getFieldDecorator("delivery_company")(
                  <Input autoComplete="off" maxLength={50} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-3">
        <Col span={24}>
          <Form.Item className="d-flex mb-0" label="หมายเหตุ">
            {getFieldDecorator("remark")(
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={500}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="text-center">
          <CancelByMember onComplete={onComplete} />
          <Button type="primary" className="width-150" onClick={handleSubmit}>
            ดำเนินการเรียบร้อย
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create()(SendPending);
