import React, { useReducer } from "react";
import BranchContext, {
    initState,
    reducer,
} from "../../../../../contexts/branch.context";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import ModalBranch from "./components/ModalBranch";
import TableBranch from "./components/TableBranch";

const List = () => {
    const [state, dispatch] = useReducer(reducer, initState);
    return (
        <BranchContext.Provider value={{ state, dispatch }}>
            <Panel>
                <PanelHeader>สาขา</PanelHeader>
                <PanelBody>
                    <TableBranch />
                </PanelBody>
            </Panel>

            <ModalBranch />
        </BranchContext.Provider>
    );
};
export default List;
