/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Row, Col, Typography, Checkbox } from "antd";
import BillContext from "../../../reducer";
import { moment } from "../../../../../../utils";

const { Text } = Typography;

const Detail = () => {
  const {
    state: {
      acceptText,
      billDetail: { is_daily },
      content: {
        cancel_type_text,
        reason,
        is_accept,
        member_contact_text,
        contact_detail,
        request_date,
        request_by_name,
        forward_date,
        forward_by_name,
        consider_date,
        consider_by_name,
      },
    },
  } = useContext(BillContext);

  return (
    <>
      <Row gutter={[8, 8]} className="mb-3">
        {request_date && (
          <Col span={12}>
            <Text strong>ผู้ขอยกเลิก</Text>
            <Text className="ml-2 mr-3">{request_by_name}</Text>
            <Text strong>วันที่ขอ</Text>
            <Text className="ml-2">
              {moment(request_date).format("DD/MM/YYYY HH:mm")}
            </Text>
          </Col>
        )}
        {forward_date && (
          <Col span={12}>
            <Text strong>ผู้จัดการผู้ส่งเรื่อง</Text>
            <Text className="ml-2 mr-3">{forward_by_name}</Text>
            <Text strong>วันที่ส่งเรื่องต่อ</Text>
            <Text className="ml-2">
              {moment(forward_date).format("DD/MM/YYYY HH:mm")}
            </Text>
          </Col>
        )}
        {consider_date && (
          <Col span={12}>
            <Text strong>ผู้พิจารณา</Text>
            <Text className="ml-2 mr-3">{consider_by_name}</Text>
            <Text strong>วันที่พิจารณา</Text>
            <Text className="ml-2">
              {moment(consider_date).format("DD/MM/YYYY HH:mm")}
            </Text>
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={4}>
          <Text strong>ประเภทการยกเลิก</Text>
        </Col>
        <Col span={20}>
          <Text>{cancel_type_text}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={4}>
          <Text strong>เหตุผลการยกเลิก</Text>
        </Col>
        <Col span={20}>
          <Text>{reason}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={20} offset={4}>
          <Checkbox checked={is_accept} disabled>
            <Text className="font-size-10">
              {is_daily ? acceptText.daily : acceptText.cycle}
            </Text>
          </Checkbox>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={4}>
          <Text strong>ช่องการติดต่อนักธุรกิจ</Text>
        </Col>
        <Col span={20}>
          <Text>{member_contact_text}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={20} offset={4}>
          <Text>{contact_detail}</Text>
        </Col>
      </Row>
    </>
  );
};

export default Detail;
