/* eslint-disable no-unused-vars */
import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Row,
  Spin,
  Typography,
} from "antd";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import BillModal from "../BillModal";
import { actionCreditNote } from "../../../../../redux/slices/credit-note";
import { moment, _ } from "../../../../../utils";
import BillItemTable from "./BillItemTable";
const { Text } = Typography;
const Index = () => {
  const dispatch = useDispatch();
  const { SET_OPEN_MODAL_BILL } = actionCreditNote;
  const partner_code = useSelector((state) => state.creditNote.partner);
  const bill_modal = useSelector((state) => state.creditNote.bill_modal);
  const { document_no, req_receipt_type, ...bill } = useSelector(
    (state) => state.creditNote.bill
  );

  const loading = useSelector((state) => state.creditNote.fetch.loading);
  const list = [
    {
      title: "วันที่ออกใบเสร็จ",
      dataIndex: "document_date",
      is_date: true,
    },
    {
      title: "ประเภทการสั่งซื้อ",
      dataIndex: "order_type",
    },
    {
      title: "ผู้ดำเนินการ",
      dataIndex: "buyer_name",
    },
    {
      title: "เบอร์โทรผู้ดำเนินการ",
      dataIndex: "buyer_mobile",
    },
    {
      title: "ใบเสร็จ",
      dataIndex: "req_receipt_text",
    },
    {
      title: "ประเภทการรับของ",
      dataIndex: "req_receipt_type_text",
    },
    {
      title: "ชื่อผู้รับ",
      dataIndex: "ship_to",
    },
    {
      title: "เบอร์โทรผู้รับ",
      dataIndex: "ship_mobile",
    },
  ];
  return (
    <>
      <Row className="bg-gray-60">
        <Col
          span={24}
          className="d-flex justify-content-between align-items-center px-2 py-2"
        >
          <Text strong style={{ fontSize: "1.5em" }} className="text-white">
            เลขที่ใบเสร็จ : {document_no}
          </Text>
          <Button
            disabled={!partner_code}
            type="primary"
            className="width-150"
            onClick={() => dispatch(SET_OPEN_MODAL_BILL())}
          >
            <FaPlus className="mr-2" />
            เลือกใบเสร็จ
          </Button>
        </Col>
      </Row>
      <Row className="bg-gray-20 mb-3">
        <Col span={24} className="px-4 py-2">
          {!document_no ? (
            <Empty className="py-3" />
          ) : loading ? (
            <Spin spinning>
              <div className="height-100" />
            </Spin>
          ) : (
            <>
              <Row gutter={8} type="flex">
                {_.map(list, (n) => (
                  <React.Fragment key={n.dataIndex}>
                    <Col span={4} xl={2} className="text-right">
                      <Text strong>{n.title} :</Text>
                    </Col>
                    <Col span={8} xl={4}>
                      <Text>
                        {n.is_date && !!bill[n.dataIndex]
                          ? moment(bill[n.dataIndex]).format("DD/MM/YYYY HH:mm")
                          : bill[n.dataIndex]}
                      </Text>
                    </Col>
                  </React.Fragment>
                ))}
                <Col span={4} xl={2} className="text-right">
                  <Text strong></Text>
                </Col>
                <Col span={20}>
                  <Text></Text>
                </Col>
              </Row>
              <Divider />
              <BillItemTable />
            </>
          )}
        </Col>
      </Row>
      {partner_code && <BillModal />}
    </>
  );
};
export default Index;
