import { createSlice } from "@reduxjs/toolkit";
import { _ } from "../../utils";
import { getThunkError } from "../../views/util/helper";
import { getMediaDocumentType, orderProduct } from "../logic/transfer";
import { getTransferById } from "../thunk/transfer";

const initProduct = {
  product_id: null,
  product_code: null,
  product_name: null,
  product_name_en:null,
  lot: "",
  mfg_date: null,
  exp_date: null,
  remark: "",
  balance: 0,
  qty: "",
  receive_qty: 0,
  new_receive_qty: "",
  diff_new_receive: 0,
  total_qty: 0,
  total_receive_qty: 0,
  total_new_receive_qty: 0,
  total_diff_new_receive: 0,
};

const initialState = {
  getById: {
    status: null,
    error: null,
  },
  product: {
    is_some: false,
    selected: [],
    itemKeys: [],
    itemByKey: {},
  },
  productAdd: {
    is_some: false,
    selected: [],
    itemKeys: [],
    itemByKey: {},
  },
  productModal: {
    visible: false,
    limit: 10,
    page: 1,
    query: null,
  },
  document: {
    document_no: null,
    document_date: null,
    from_branch_code: null,
    from_wh_code: null,
    from_wh_name: null,
    to_branch_code: null,
    to_wh_code: null,
    to_wh_name: null,
    reason: null,
    detail: "",
    remark: "",
    bill_ref_document_no: null,
    other_reason: null,
    status: null,
    cancel_type_text: null,
    gen_from_receive: null,
    transfer_by: null,
    modify_by: null,
    receive_type: null,
    reason_text: null,
  },
  mediaTransfer: {
    itemDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  mediaReceive: {
    itemDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  searchDocument: {
    query: "",
    doc_start_date: null,
    doc_end_date: null,
    modify_start_date: null,
    modify_end_date: null,
    from_wh_code: "",
    to_wh_code: "",
    status: "",
    page: 1,
    limit: 10,
    loading_submit: false,
  },
  remark: {
    itemKeys: [],
    itemByKey: {},
  },
  cancelModal: {
    visible: false,
    cancel_type: null,
    remark: "",
    document_no: null,
    document_status: null,
    receive_type: null,
  },
  user: {
    user_id: null,
    permission_status: {},
  },
};

const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    setGlobalState: (state, { payload: { name, value } }) => {
      state[name] = {
        ...state[name],
        ...value,
      };
    },
    clear: (state) => {
      state.getById = { ...initialState.getById };
      state.productAdd = _.cloneDeep(initialState.productAdd);
      state.product = _.cloneDeep(initialState.product);
      state.productModal = { ...initialState.productModal };
      state.document = { ...initialState.document };
      state.mediaTransfer = _.cloneDeep(initialState.mediaTransfer);
      state.mediaReceive = _.cloneDeep(initialState.mediaReceive);
      state.remark = _.cloneDeep(initialState.remark);
      state.cancelModal = { ...initialState.cancelModal };
      state.user = _.cloneDeep(initialState.user);
      state.searchDocument = { ...initialState.searchDocument };
    },
    clearCreate: (state) => {
      state.getById = { ...initialState.getById };
      state.productAdd = _.cloneDeep(initialState.productAdd);
      state.product = _.cloneDeep(initialState.product);
      state.productModal = { ...initialState.productModal };
      state.document = { ...initialState.document };
      state.mediaTransfer = _.cloneDeep(initialState.mediaTransfer);
      state.mediaReceive = _.cloneDeep(initialState.mediaReceive);
      state.remark = _.cloneDeep(initialState.remark);
      state.cancelModal = { ...initialState.cancelModal };
      state.user = _.cloneDeep(initialState.user);
    },
    clearGlobalState: (state, { payload }) => {
      state[payload] = _.cloneDeep(initialState[payload]);
    },
    addProduct: (state, { payload: { property, ...payload } }) => {
      let { itemKeys, itemByKey } = state[property];
      const key = _.uniqueId(`${payload.product_code}-`);
      itemKeys = [...itemKeys, key];
      itemByKey = {
        ...itemByKey,
        [key]: {
          ..._.cloneDeep(initProduct),
          ...payload,
          line_number: 1,
          key,
        },
      };
      state[property].itemKeys = orderProduct(itemKeys, itemByKey);
      state[property].itemByKey = itemByKey;
    },
    setProduct: (state, { payload: { name, value, key } }) => {
      if (_.includes(["qty", "new_receive_qty"], name)) {
        const { product_code } = state.product.itemByKey[key];
        const first_key = _.find(
          state.product.itemKeys,
          (n) =>
            state.product.itemByKey[n].product_code === product_code &&
            state.product.itemByKey[n].line_number === 1
        );
        if (name === "qty") {
          const total_qty =
            state.product.itemByKey[first_key].total_qty -
            state.product.itemByKey[key].qty +
            (value || 0);
          if (total_qty > state.product.itemByKey[first_key].balance) return;
          state.product.itemByKey[first_key].total_qty = total_qty;
        } else {
          const total_new_receive_qty =
            state.product.itemByKey[first_key].total_new_receive_qty -
            state.product.itemByKey[key].new_receive_qty +
            (value || 0);
          state.product.itemByKey[first_key].total_new_receive_qty =
            total_new_receive_qty;
          state.product.itemByKey[first_key].total_diff_new_receive =
            state.product.itemByKey[first_key].total_receive_qty +
            state.product.itemByKey[first_key].total_new_receive_qty -
            state.product.itemByKey[first_key].total_qty;
        }
      }
      state.product.itemByKey[key][name] = value;
    },
    addProductLot: (state, { payload }) => {
      const { product_code } = state.product.itemByKey[payload];
      let { itemKeys, itemByKey } = state.product;
      const key = _.uniqueId(`${product_code}-`);
      const line_number =
        _.filter(itemKeys, (n) => itemByKey[n].product_code === product_code)
          .length + 1;
      itemKeys = [...itemKeys, key];
      itemByKey = {
        ...itemByKey,
        [key]: {
          ..._.cloneDeep(initProduct),
          product_code,
          line_number,
          key,
        },
      };
      state.product.itemKeys = orderProduct(itemKeys, itemByKey);
      state.product.itemByKey = itemByKey;
    },
    delProductLot: (state, { payload }) => {
      const { product_code } = state.product.itemByKey[payload];
      const first_key = _.find(
        state.product.itemKeys,
        (n) =>
        state.product.itemByKey[n].product_code === product_code &&
        state.product.itemByKey[n].line_number === 1
        ); //หาบรรทัดแรกของสินค้านั้น
      if (first_key === payload) { //ลบบรรทัดแรก
        const second_key = _.find(
          state.product.itemKeys,
          (n) =>
            state.product.itemByKey[n].product_code === product_code 
            &&
            state.product.itemByKey[n].line_number === 2
        ); //หาบรรทัดที่ 2 ของสินค้านั้น
        
        
        if (second_key) { //กรณีที่เจอบรรทัดที่ 2 ถ้าลบบรรทัดที่ 2ไปก็จะไม่เข้า
          const { total_qty, product_name, product_name_en,product_id, set_type, balance } =
            state.product.itemByKey[first_key]; // ดึงข้อมูลจากบรรทัดแรก
          state.product.itemByKey[second_key] = { //นำข้อมูลที่ดึงจากบรรทัดแรกมาใส่บรรทัดที่2
            ...state.product.itemByKey[second_key],
            total_qty: total_qty - state.product.itemByKey[payload].qty,
            product_id,
            product_name,
            product_name_en,
            set_type,
            balance,
          };
        }
        const other_key = _.filter( //ค้นหาผลที่ไม่ใช่ แถวที่ 1
          state.product.itemKeys,
          (n) =>
            state.product.itemByKey[n].product_code === product_code &&
            n !== first_key
        );
        for (let i = 0; i < other_key.length; i++) {
          const key = other_key[i];
          state.product.itemByKey[key].line_number -= 1;
        }
      } else {
        const other_key1 = _.filter( //ค้นหาผลที่ไม่ใช่ แถวที่ 1
        state.product.itemKeys,
        (n) =>
          state.product.itemByKey[n].product_code === product_code &&
          payload < state.product.itemByKey[n].key  
        );
        for (let i = 0; i < other_key1.length; i++) {
          const key = other_key1[i];
          state.product.itemByKey[key].line_number  -= 1;
        }
        state.product.itemByKey[first_key].total_qty -=
          state.product.itemByKey[payload].qty;
      }

      // state.product.itemKeys = _.map(
      //   _.orderBy(
      //     _.map(
      //       _.filter(state.product.itemKeys, (key) => key !== payload),
      //       (key) => state.product.itemByKey[key]
      //     ),
      //     ["product_code", "line_number"],
      //     ["asc", "asc"]
      //   ),
      //   (n) => n.key
      // );
      state.product.itemKeys = _.filter(state.product.itemKeys, (n) => n !== payload);
      state.product.itemByKey = _.omit(state.product.itemByKey, [payload]);
    },
    setProductAdd: (state, { payload: { name, value, key } }) => {
      if (name === "new_receive_qty") {
        const { product_code } = state.productAdd.itemByKey[key];
        const first_key = _.find(
          state.productAdd.itemKeys,
          (n) =>
            state.productAdd.itemByKey[n].product_code === product_code &&
            state.productAdd.itemByKey[n].line_number === 1
        );
        const total_new_receive_qty =
          state.productAdd.itemByKey[first_key].total_new_receive_qty -
          state.productAdd.itemByKey[key].new_receive_qty +
          (value || 0);
        state.productAdd.itemByKey[first_key].total_new_receive_qty =
          total_new_receive_qty;
      }
      state.productAdd.itemByKey[key][name] = value;
    },
    addProductAddLot: (state, { payload }) => {
      const { product_code } = state.productAdd.itemByKey[payload];
      let { itemKeys, itemByKey } = state.productAdd;
      const key = _.uniqueId(`${product_code}-`);
      const line_number =
        _.filter(itemKeys, (n) => itemByKey[n].product_code === product_code)
          .length + 1;
      itemKeys = [...itemKeys, key];
      itemByKey = {
        ...itemByKey,
        [key]: {
          ..._.cloneDeep(initProduct),
          product_code,
          line_number,
          key,
        },
      };
      state.productAdd.itemKeys = _.map(
        _.orderBy(
          _.map(itemKeys, (key) => itemByKey[key]),
          ["product_code", "line_number"],
          ["asc", "asc"]
        ),
        (n) => n.key
      );
      state.productAdd.itemByKey = itemByKey;
    },
    delProductAddLot: (state, { payload }) => {
      const { product_code } = state.productAdd.itemByKey[payload];
      const first_key = _.find(
        state.productAdd.itemKeys,
        (n) =>
          state.productAdd.itemByKey[n].product_code === product_code &&
          state.productAdd.itemByKey[n].line_number === 1
      );
      if (first_key === payload) {
        const second_key = _.find(
          state.productAdd.itemKeys,
          (n) =>
            state.productAdd.itemByKey[n].product_code === product_code &&
            state.productAdd.itemByKey[n].line_number === 2
        );
        if (second_key) {
          const { total_qty, product_name, product_id, set_type, balance } =
            state.productAdd.itemByKey[first_key];
          state.productAdd.itemByKey[second_key] = {
            ...state.productAdd.itemByKey[second_key],
            total_qty: total_qty - state.productAdd.itemByKey[payload].qty,
            product_id,
            product_name,
            set_type,
            balance,
          };
        }
        const other_key = _.filter(
          state.productAdd.itemKeys,
          (n) =>
            state.productAdd.itemByKey[n].product_code === product_code &&
            n !== first_key
        );
        for (let i = 0; i < other_key.length; i++) {
          const key = other_key[i];
          state.productAdd.itemByKey[key].line_number -= 1;
        }
      }

      state.productAdd.itemKeys = _.map(
        _.orderBy(
          _.map(
            _.filter(state.productAdd.itemKeys, (key) => key !== payload),
            (key) => state.productAdd.itemByKey[key]
          ),
          ["product_code", "line_number"],
          ["asc", "asc"]
        ),
        (n) => n.key
      );
      state.productAdd.itemByKey = _.omit(state.productAdd.itemByKey, [
        payload,
      ]);
    },
    addMedia: (state, { payload: { name, value } }) => {
      const key = _.uniqueId("document-img-");
      state[name].itemKeys = [...state[name].itemKeys, key];
      state[name].itemByKey = {
        ...state[name].itemByKey,
        [key]: {
          ...value,
          key,
        },
      };
    },
    delMedia: (state, { payload: { name, value } }) => {
      const { id, temp } = state[name].itemByKey[value];
      if (!temp) {
        state[name].itemDel = [...state[name].itemDel, id];
      }
      state[name].itemKeys = _.filter(
        state[name].itemKeys,
        (key) => key !== value
      );
      state[name].itemByKey = _.omit(state[name].itemByKey, [value]);
    },
  },
  extraReducers: {
    [getTransferById.pending]: (state) => {
      state.getById.status = "loading";
    },
    [getTransferById.fulfilled]: (state, { payload }) => {
      if (payload === null) {
        state.getById.status = "notfound";
        return;
      }

      const { document, product, product_add = [], media, remark } = payload;

      const product_item_key = _.reduce(
        product,
        (res, item) => {
          const key = _.uniqueId(`${item.product_code}-`);
          return {
            itemKeys: [...res.itemKeys, key],
            itemByKey: {
              ...res.itemByKey,
              [key]: { ..._.cloneDeep(initProduct), ...item, key },
            },
          };
        },
        {
          itemKeys: [],
          itemByKey: {},
        }
      );

      const product_add_item_key = _.reduce(
        product_add,
        (res, item) => {
          const key = _.uniqueId(`${item.product_code}-`);
          return {
            itemKeys: [...res.itemKeys, key],
            itemByKey: {
              ...res.itemByKey,
              [key]: { ..._.cloneDeep(initProduct), ...item, key },
            },
          };
        },
        {
          itemKeys: [],
          itemByKey: {},
        }
      );

      const remark_item_key = _.reduce(
        remark,
        (res, item) => {
          const key = _.uniqueId("remark-");
          return {
            itemKeys: [...res.itemKeys, key],
            itemByKey: { ...res.itemByKey, [key]: { ...item, key } },
          };
        },
        {
          itemKeys: [],
          itemByKey: {},
        }
      );

      const documentMedia = getMediaDocumentType(media);

      state.document = {
        ..._.cloneDeep(initialState.document),
        ...document,
      };
      state.product.itemKeys = product_item_key.itemKeys;
      state.product.itemByKey = product_item_key.itemByKey;
      state.productAdd.itemKeys = product_add_item_key.itemKeys;
      state.productAdd.itemByKey = product_add_item_key.itemByKey;
      state.remark.itemKeys = remark_item_key.itemKeys;
      state.remark.itemByKey = remark_item_key.itemByKey;
      state.mediaTransfer = {
        ..._.cloneDeep(initialState.mediaTransfer),
        itemKeys: documentMedia.transferKeys,
        itemByKey: documentMedia.transferByKey,
      };
      state.mediaReceive = {
        ..._.cloneDeep(initialState.mediaReceive),
        itemKeys: documentMedia.receiveKeys,
        itemByKey: documentMedia.receiveByKey,
      };
      state.getById.status = "success";
      state.getById.error = null;
    },
    [getTransferById.rejected]: (state, { payload }) => {
      state.getById.status = "failed";
      state.getById.error = getThunkError(payload);
    },
  },
});

// selector
export const productSelector = (state) => state.transfer.product;
export const productModalSelector = (state) => state.transfer.productModal;
export const transferSelector = (state) => state.transfer.transferDetail;

// action
export const actionTransfer = transferSlice.actions;

// reducer
const TransferReducer = transferSlice.reducer;
export default TransferReducer;
