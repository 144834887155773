import { _ } from "../../../utils";

export function mapFileList(file) {
  const { itemKeys = [], itemByKey = {}, itemDel = [] } = file;
  return {
    file: _.join(
      _.reduce(
        itemKeys,
        (res, m_key) =>
          itemByKey[m_key].temp ? [...res, itemByKey[m_key].id] : res,
        []
      ),
      ","
    ),
    file_del: _.join(itemDel, ","),
  };
}
