/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Button, Col, List, message, Modal, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../../../redux/slices/marketing/product";
import { axios, getToken, moment, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import ReactDragListView from "react-drag-listview";
import { FaPencilAlt, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import {
  AlertConfirm,
  AlertError,
  AlertSuccess,
} from "../../../../../util/Alert";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useAsync from "../../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../../services/manage-website.service";
import { notFoundImg } from "../../../utils/constants/mwHomeConstants";

export default function RP_ReviewReviewList({ history, form, ...props }) {
  const [seq, setSeq] = useState([]);
  const [data, setData] = useState([]);
  const headData = [
    {
      no: "No.",
      desktop_img: "รูปภาพ",
      title: "ชื่อผู้แลก",
      status: "Status",
      update: "Update Date",
      manage: "Manage",
    },
  ];
  const { execute: updateReviewReviewSeqById } = useAsync(
    manageWebsiteService.updateReviewReviewSeqById,
    {
      onSuccess: async () => {
        await loadContent();
      },
    }
  );

  const onDragEnd = async (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    await items.splice(toIndex, 0, item);
    await setData(items);
    for (let i = 0; i < items.length; i++) {
      let x = i + 1;
      let myId = _.toNumber(items[i].id);
      if (items[i].row_id != x) {
        updateReviewReviewSeqById({ myId, x });
      }
    }
    setTimeout(() => {
      message.success({ content: "Saved!", duration: 0.8 });
    }, 180);
  };

  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;

  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/ecosystem/reward-point/rp-review/review",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/ecosystem/reward-point/rp-review/review/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "active" : "inactive",
      },
    })
      .then(() => {
        loadContent();
        setTimeout(() => {
          message.success({ content: "Saved!", duration: 0.45 });
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/reward-point/rp-review/review",
    })
      .then((res) => {
        let loadData = res.data?.data;
        setData(loadData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { execute: deleteReviewReviewById } = useAsync(
    manageWebsiteService.deleteReviewReviewById,
    {
      onSuccess: async () => {
        await AlertSuccess("ลบข้อมูลสำเร็จ");
        loadContent();
      },
      onFailed: () => {
        AlertError();
      },
    }
  );

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <Row>
        <List
          size="small"
          bordered
          dataSource={headData}
          renderItem={(item) => {
            const draggble = item.title !== "XXX";
            return (
              <List.Item className={draggble ? "draggble" : ""}>
                <List.Item.Meta title={item.no} />
                <List.Item.Meta title={item.desktop_img} />
                <List.Item.Meta title={item.title} />
                <List.Item.Meta title={item.status} />
                <List.Item.Meta title={item.update} />
                <List.Item.Meta title={item.manage} />
              </List.Item>
            );
          }}
        ></List>
      </Row>
      <Row>
        <ReactDragListView
          nodeSelector=".ant-list-item.draggble"
          onDragEnd={onDragEnd}
        >
          <List
            style={{ cursor: "grab" }}
            size="small"
            bordered
            dataSource={data}
            pagination={{
              size: "default",
              showLessItems: true,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              total: list?.fetch?.total || 0,
              current: search.page,
              pageSize: search.limit,
              onChange: (page) => dispatch(SET_SEARCH({ page: page })),
            }}
            renderItem={(item) => {
              const draggble = item.title !== "XXX";
              return (
                <List.Item className={draggble ? "draggble" : ""}>
                  <List.Item.Meta title={item.row_id} />
                  <List.Item.Meta
                    title={
                      <>
                        <div
                          style={{
                            objectFit: "contain",
                            width: "100px",
                            height: "100px",
                            overflow: "hidden",
                            border: "1px solid #BFBFBF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            src={
                              item.image_url == null
                                ? notFoundImg
                                : item.image_url
                            }
                            alt="img_product"
                            width="80px"
                            height="80px"
                          />
                        </div>
                      </>
                    }
                  />
                  <List.Item.Meta title={item.title} />

                  <List.Item.Meta
                    title={
                      <Switch
                        checked={item.status === "active" ? true : false}
                        onClick={(checked) =>
                          handleChangeStatus(checked, item.id)
                        }
                      />
                    }
                  />
                  <List.Item.Meta
                    title={moment(item.update_date).format("DD/MM/YYYY HH:mm")}
                  />
                  <List.Item.Meta
                    title={
                      <>
                        <Link to={`${props.toLink}/review/${item.id}`}>
                          <Button type="primary">
                            <FaPencilAlt />
                          </Button>
                        </Link>
                        <Button
                          className="btn-danger ml-1"
                          onClick={async () => {
                            const confirm = await AlertConfirm("ลบข้อมูล");
                            if (confirm) {
                              deleteReviewReviewById(item.id);
                            }
                          }}
                        >
                          <FaRegTrashAlt />
                        </Button>
                      </>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </ReactDragListView>
      </Row>
      {/* <Row className="mt-5">
        <Button type="primary" onClick={() => handleClickSubmit()}>
          บันทึกลำดับ Review.
        </Button>
      </Row> */}
    </>
  );
}
