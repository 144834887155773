import { useQueryClient } from '@tanstack/react-query';
import { Col, Row, Typography, Modal, Button, Input } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AlertConfirm, AlertError } from '../../../../util/Alert';
import digitalProductServices from '../../../../../services/digital-product.services';

const RejectDigitalProductModal = ({
  open,
  handleClose,
  handleClose2,
  digitalProductId,
}) => {
  const queryClient = useQueryClient();
  const { watch, control } = useForm({
    defaultValues: {
      rejectedReason: '',
    },
  });

  const { mutate: updateDigitalProductStatus } =
    digitalProductServices.useMutationUpdateDigitalProductStatus(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-digital-product-by-id'],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: ['get-digital-products'],
          refetchType: 'all',
        });
        handleClose2();
      },
      () => {
        AlertError({
          title: 'ไม่สามารถดำเนินการได้',
          text: 'กรุณาลองใหม่หรือครั้ง',
        });
        handleClose2();
      }
    );

  const _HandleReject = async () => {
    const confirm = await AlertConfirm('ต้องการปฏิเสธผลงานใช่หรือไม่');
    if (confirm && digitalProductId) {
      updateDigitalProductStatus({
        id: digitalProductId,
        status: 'rejected',
        rejectedReason: watch('rejectedReason'),
      });
    }
  };

  return (
    <Modal
      title={'รายละเอียดผลงาน'}
      visible={open}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" onClick={_HandleReject}>
          ตกลง
        </Button>,
      ]}
      width={'50vw'}
    >
      <Controller
        name="rejectedReason"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  เหตุผล <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="เหตุผล" />
              </Col>
            </Row>
          );
        }}
      />
    </Modal>
  );
};

export default RejectDigitalProductModal;
