import { Button, Modal } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";
import TableBookBankHistory from "../Table/TableBookBankHistory";

export default function BankHistoryModal() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.ethics.modal, shallowEqual);
  const { CLOSE_MODAL } = actionEthics;

  const closeModal = () => {
    dispatch(CLOSE_MODAL());
  };

  return (
    <Modal
      visible={modal.name === "bank"}
      onCancel={closeModal}
      title="ประวัติบัญชีธนาคาร"
      width={800}
      footer={[
        <Button type="default" key={'close'} onClick={closeModal}>
          ปิด
        </Button>,
      ]}
    >
      <TableBookBankHistory />
    </Modal>
  );
}
