import { Button, Col, Modal, Row, Typography } from 'antd'
import numeral from 'numeral'
import React from 'react'

const SendInterfaceModal = ({ ...props }) => {
    return (
        <Modal
            maskClosable={false}
            {...props}
        >
            <Row>
                <Col>
                    <Typography.Text strong>ยังไม่ได้ส่ง:</Typography.Text>
                    <Typography.Text>{` ${0} รายการ`}</Typography.Text>
                </Col>
                <Col>
                    <Typography.Text strong>รวมเป็นเงิน:</Typography.Text>
                    <Typography.Text>{` ${numeral(0).format("0,0.00")} บาท`}</Typography.Text>
                </Col>
            </Row>

            <div className="d-flex justify-content-end">
                <Button type="primary" onClick={() => console.log()}>
                    ส่งข้อมูล
                </Button>
            </div>


        </Modal>
    )
}

export default SendInterfaceModal
