import { Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { FaCog } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { useStateValue } from "../StateContext";
import { URL_API, axios, getToken } from "../../../../../utils";

const TableFile = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { state, dispatch } = useStateValue();
  const { loading, data } = state;
  const { type } = data;

  const [fileList, setFileList] = useState([]);

  const loadDataCallback = useCallback(async (filter) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    try {
      const res = await axios.get("/manage-website/manage-upload", {
        baseURL: URL_API,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        params: {
          type: filter,
        },
      });
      setFileList(res?.data?.file_upload_list || []);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      message.error(error.message);
    }
  }, []);

  useEffect(() => {
    loadDataCallback("");
  }, []);

  useEffect(() => {
    loadDataCallback(type);
  }, [type]);

  return (
    <Table
      size="small"
      bordered
      loading={loading}
      rowKey="id"
      dataSource={fileList || []}
      scroll={{ x: "max-content" }}
      className="table-text-top"
    >
      <Table.Column
        title="#"
        dataIndex="row_id"
        align="center"
        render={(_id, _record, index) => index + 1}
        width={50}
      />
      <Table.Column
        title="ประเภท"
        dataIndex="name"
        align="center"
        render={(_id, record) => record.name}
        width={"30%"}
      />
      <Table.Column
        title="แก้ไขล่าสุด"
        dataIndex="update_date"
        align="center"
        render={(_id, record) =>
          moment(record.update_date || record.craete_date).format(
            "DD/MM/YYYY HH:mm"
          )
        }
        width={"30%"}
      />
      <Table.Column
        title="ผู้ดำเนินการ"
        dataIndex="update_by"
        align="center"
        render={(_id, record) => record.update_by || record.create_by}
        width={"30%"}
      />
      <Table.Column
        title={<FaCog />}
        dataIndex="id"
        align="center"
        render={(id) => (
          <AiOutlineEdit
            onClick={() => {
              history.push(`${match.url}/${id}`);
            }}
            style={{
              color: "#97D700",
              cursor: "pointer",
            }}
          />
        )}
        width={50}
      />
    </Table>
  );
};

export default TableFile;
