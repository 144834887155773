/* eslint-disable no-unused-vars */
import { Button, Col, Input, Row, Select } from "antd";
import React, { useContext, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router";
import UserContext from "../../../../../../contexts/user.context";
import SelectDepartments from "./SelectDepartments";
import SelectDivision from "./SelectDivision";

const FilterList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { state, dispatch } = useContext(UserContext);
  const [query, setQuery] = useState("");

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    dispatch({ type: "SET_FILTER_VALUE", payload: { [name]: value } });
  };

  return (
    <>
      {/* <Row gutter={16} className="mb-2">
                <Col md={6}>
                    <Radio.Group
                        value={state.filter.status}
                        onChange={handleOnChange}
                        name="status"
                        buttonStyle="solid"
                    >
                        <Radio.Button value="active">ใช้งาน</Radio.Button>
                        <Radio.Button value="inactive">งดใช้งาน</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col md={12}>
                    <Input.Search />
                </Col>
            </Row> */}
      <Row gutter={16}>
        <Col md={9}>
          <Input.Search
            name="query"
            placeholder="ชื่อผู้ใช้, ชื่อ, นามสกุล, อีเมล"
            onChange={(e) => setQuery(e.target.value)}
            // value={state.filter.query}
            value={query}
            onSearch={(e) =>
              dispatch({
                type: "SET_FILTER_VALUE",
                payload: { query: e },
              })
            }
          />
        </Col>

        <Col md={4}>
          <SelectDivision
            withAll
            defaultValue={state?.filter?.division_id}
            className="w-100"
            department_id={state?.filter?.department_id}
            onChange={(value) =>
              dispatch({
                type: "SET_FILTER_VALUE",
                payload: { division_id: value, department_id: "" },
              })
            }
          />
        </Col>
        <Col md={4}>
          <SelectDepartments
            withAll
            defaultValue={state?.filter?.department_id}
            division_id={state?.filter?.division_id}
            className="w-100"
            onChange={(value) =>
              dispatch({
                type: "SET_FILTER_VALUE",
                payload: { department_id: value },
              })
            }
          />
        </Col>
        <Col md={4}>
          <Select
            defaultValue={state?.filter?.status}
            className="w-100"
            onChange={(value) =>
              dispatch({
                type: "SET_FILTER_VALUE",
                payload: { status: value },
              })
            }
          >
            <Select.Option value="active">ใช้งาน</Select.Option>
            <Select.Option value="inactive">งดใช้งาน</Select.Option>
          </Select>
        </Col>
        <Col md={3}>
          <Button
            type="default"
            block
            onClick={() =>
              dispatch({
                type: "SET_FILTER_VALUE",
                payload: { query },
              })
            }
          >
            <FaSearch className="mr-1" />
            ค้นหา
          </Button>
        </Col>
      </Row>
      <Row gutter={16} className="mt-3">
        <Col md={3}>
          <Button
            type="primary"
            block
            onClick={() => history.push(`${match.path}/0`)}
          >
            <FaPlus className="mr-1" />
            สร้าง
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default FilterList;
