/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import { useHistory } from 'react-router';
import {
  legacyQuizCategoryFormDefaultValues,
  legacyQuizCategoryFormSchema,
} from '../components/dto/legacy-quiz-form.dto';
import fitQuizServices from '../../../../services/fit-quiz.services';
import LegacyQuizCategoryModal from '../components/modal/LegacyQuizCategoryModal';

dayjs.locale('th');

const pageSize = 10;

const LegacyQuizCategoryPage = () => {
  const [legacyQuizCategoryFormOpen, setLegacyQuizCategoryFormOpen] =
    useState(false);
  const {
    data: legacyQuizCategoryLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();

  const legacyQuizCategoryForm = useForm({
    defaultValues: legacyQuizCategoryFormDefaultValues,
    resolver: legacyQuizCategoryFormSchema(),
  });

  const _HandleLegacyQuizCategoryFormOpen = (formType) => {
    legacyQuizCategoryForm.setValue('formType', formType);
    setLegacyQuizCategoryFormOpen(true);
  };

  const _HandleLegacyQuizCategoryFormClose = () => {
    legacyQuizCategoryForm.reset(legacyQuizCategoryFormDefaultValues);
    setLegacyQuizCategoryFormOpen(false);
  };

  fitQuizServices.useQueryGetLegacyQuizCategoryLists(
    { take: pageSize, page },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyQuizCategory } =
    fitQuizServices.useMutationDeleteLegacyQuizCategory(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyQuizCategoryDisplay } =
    fitQuizServices.useMutationUpdateLegacyQuizCategoryDisplay(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-category'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <div>
          <img
            alt={record.title}
            src={record.imageUrl}
            style={{
              width: 200,
              height: 100,
            }}
          />
        </div>
      ),
    },
    {
      title: 'ชื่อหมวดหมู่',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'display',
      key: 'display',
      render: (_, record) => <span>{record.display ? 'เปิด' : 'ปิด'}</span>,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.display ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการแสดงหมวดหมู่คำถาม');
                if (confirm)
                  updateLegacyQuizCategoryDisplay({
                    id: record.id,
                    display: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการแสดงหมวดหมู่คำถาม');
                if (confirm)
                  updateLegacyQuizCategoryDisplay({
                    id: record.id,
                    display: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyQuizCategoryForm.setValue('id', record.id);
              _HandleLegacyQuizCategoryFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyQuizCategory({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Legacy Quiz Category</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <div style={{ flex: 1 }} />
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleLegacyQuizCategoryFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มหมวดหมู่ชุดคำถาม
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={legacyQuizCategoryLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/questionaire/quiz/quiz-category/${record.id}`);
              },
            };
          }}
        />
      </PanelBody>
      <FormProvider {...legacyQuizCategoryForm}>
        <LegacyQuizCategoryModal
          open={legacyQuizCategoryFormOpen}
          handleClose={_HandleLegacyQuizCategoryFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default LegacyQuizCategoryPage;
