/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Col, DatePicker, Form, Row, Switch } from "antd";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import SelectPage from "../../components/select/SelectPage";
import UploadPreview from "./UploadPreview";

const FormBanner = ({ form }, ref) => {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.file;
  };

  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;
  const [page, setPage] = useState("");
  const start_date = getFieldsValue().start_date;
  const end_date = getFieldsValue().end_date;
  const getPage = getFieldsValue().page;
  const handlePageChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    if (getPage) {
      setPage(getPage);
    }
  }, [getPage]);

  return (
    <Form colon={false}>
      <Row gutter={{ xs: 16, md: 32 }}>
        {page !== "web-menu" ? (
          <Col md={12}>
            <Form.Item label="Website: 1440 x 675 px">
              {getFieldDecorator("online_banner", {
                valuePropName: "file",
                getValueFromEvent: normFile,
              })(<UploadPreview fix_width={1440} fix_height={675} />)}
            </Form.Item>
            <Form.Item label="Mobile: 1440 x 768 px">
              {getFieldDecorator("mobile_banner", {
                valuePropName: "file",
                getValueFromEvent: normFile,
              })(<UploadPreview fix_width={1440} fix_height={768} />)}
            </Form.Item>
          </Col>
        ) : (
          <Col md={12}>
            <Form.Item label="Website: 2309 x 457 px">
              {getFieldDecorator("online_banner", {
                valuePropName: "file",
                getValueFromEvent: normFile,
              })(<UploadPreview fix_width={2309} fix_height={457} />)}
            </Form.Item>
          </Col>
        )}
        <Col md={12}>
          <Form.Item label="หน้าจอ">
            {getFieldDecorator("page", {
              initialValue: "",
              rules: [
                { required: true, message: "กรุณาเลือกหน้าจอแสดงแบนเนอร์" },
              ],
            })(
              <SelectPage
                withAll
                allText="เลือกหน้าจอ"
                onChange={handlePageChange}
              />
            )}
          </Form.Item>
          <Form.Item label="แสดงผล">
            {getFieldDecorator("show", {
              initialValue: [],
            })(
              <Checkbox.Group>
                <Checkbox value="online">Website</Checkbox>
                {page !== "web-menu" && (
                  <Checkbox value="mobile">Mobile</Checkbox>
                )}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item label="เริ่มต้น">
            {getFieldDecorator("start_date", {
              initialValue: moment().startOf("month"),
            })(
              <DatePicker
                showTime
                placeholder="เริ่มต้น"
                format="DD/MM/YYYY HH:mm:ss"
                disabledDate={(current) => end_date && current > end_date}
              />
            )}
          </Form.Item>
          <Form.Item label="สิ้นสุด">
            {getFieldDecorator("end_date", {
              initialValue: moment().endOf("month"),
            })(
              <DatePicker
                showTime
                placeholder="สิ้นสุด"
                format="DD/MM/YYYY HH:mm:ss"
                disabledDate={(current) => start_date && current < start_date}
              />
            )}
          </Form.Item>
          <Form.Item label="ใช้งาน">
            {getFieldDecorator("status", {
              initialValue: true,
              valuePropName: "checked",
            })(<Switch />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create("banner")(forwardRef(FormBanner));
