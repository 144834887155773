/* eslint-disable no-unused-vars */
import { Button, Col, Input, notification, Row, Table } from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import React, { useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import { memberTypeOptions } from "../../components/memberTypeOptions";
import SelectOption from "../../../pos/components/SelectOption";

const Index = () => {

  const limit = 50;
  const [state, dispatch] = useReducer(reducer, initState);
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState("");
  const [renewTypeFilter, setRenewTypeFilter] = useState("");
  const [page, setPage] = useState(1);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/account/renew_member`,
      token: true,
      params: {
        member_type: memberTypeFilter,
        team: teamFilter,
        renew_type: renewTypeFilter,
      },
      initialLoad: false,
    },
    []
  );

  const renewTypeOption = [
    { value: "", text: "ทั้งหมด" },
    { value: "before30", text: "ก่อนหมดอายุ 30 วัน" },
    { value: "after90", text: "หลังหมดอายุ 90 วัน" },
    { value: "expire", text: "ตรงวันหมดอายุ" },
  ];
  
  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานสมาชิกอยู่ในช่วงต่ออายุ"],
        [""],
        [
          "",
          "ประเภทสมาชิก",
          `${
            memberTypeFilter === ""
              ? "ทั้งหมด"
              : memberTypeOptions.find((e) => e.value === memberTypeFilter)
                  ?.text
          }`,
          "",
          "องค์กร",
          `${teamFilter === "" ? "ทั้งหมด" : teamFilter}`,
          "",
          "ช่วงต่ออายุ",
          `${
            renewTypeFilter === ""
              ? "ทั้งหมด"
              : renewTypeOption.find((e) => e.value === renewTypeFilter)?.text
          }`,
          "",
          "",
          `วันที่ดึงข้อมูล : ${moment().format("LLLL")}`,
        ],
        [""],
        [
          "ลำดับ",
          "รหัส",
          "ชื่อผู้สมัครหลัก",
          "ชื่อผู้สมัครร่วม",
          "เบอร์โทร",
          "วันที่สมัคร",
          "วันหมดอายุ",
          "Honer Pin",
          "ประเภทสมาชิก",
          "สถานะ",
          "หมายเหตุ",
          "องค์กร",
          "รหัสผู้แนะนำ",
          "ชื่อผู้แนะนำ",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.partner_code,
            n.partner_name,
            n.married_name ? n.married_name : "",
            n.mobile ? n.mobile : "",
            {
              v: n.register_date
                ? moment(n.register_date).format("DD/MM/YYYY")
                : "",
            },
            {
              v: n.expire_date
                ? moment(n.expire_date).format("DD/MM/YYYY")
                : "",
            },
            n.hornor_code ? n.hornor_code : "",
            n.member_type,
            n.status,
            n.remark,
            n.team_name,
            n.sponsor_code,
            n.sponsor_name,
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 25 },
      ];

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);

      ws["!cols"] = wscols;

      ws["!merges"] = merge;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานสมาชิกอยู่ในช่วงต่ออายุ");
      XLSX.writeFile(wb, "รายงานสมาชิกอยู่ในช่วงต่ออายุ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสมาชิกอยู่ในช่วงต่ออายุ</PanelHeader>
        <PanelBody>
          <Row gutter={8} type="flex" className="mb-3 mt-2 align-items-center">
            <Col span={2} style={{ textAlign: "right" }} className="mr-3">
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={3}>
              <SelectOption
                className="w-100"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>
            <Col span={2} style={{ textAlign: "right" }} className="mr-3">
              <Text strong>องค์กร</Text>
            </Col>
            <Col span={3}>
              <SelectTeam
                withAll
                defaultValue=""
                placeholder="องค์กร"
                onChange={(e) => {
                  setTeamFilter(e);
                }}
              />
            </Col>
            <Col span={2} style={{ textAlign: "right" }} className="mr-3">
              <Text strong>ช่วงต่ออายุ</Text>
            </Col>
            <Col span={3}>
              <SelectOption
                className="w-100"
                defaultValue={renewTypeFilter}
                options={renewTypeOption}
                onChange={(e) => {
                  setRenewTypeFilter(e);
                }}
              />
            </Col>

            <Col span={6} push={2}>
              <Row gutter={8}>
                <Col span={12}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                    block
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    icon="file-excel"
                    onClick={exportToExcel}
                    block
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data ? data.data : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data.data.length : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="row_id"
              key="index"
              align="center"
            />
            <Table.Column
              title="รหัส"
              dataIndex="partner_code"
              key="partner_code"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
            <Table.Column
              title="ชื่อผู้สมัครหลัก"
              dataIndex="partner_name"
              key="partner_name"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
            <Table.Column
              title="ชื่อผู้สมัครร่วม"
              dataIndex="married_name"
              key="married_name"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
            <Table.Column
              title="เบอร์โทร"
              dataIndex="mobile"
              key="mobile"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
            <Table.Column
              title="วันที่สมัคร"
              dataIndex="register_date"
              key="register_date"
              align="center"
              render={(e) => <>{moment(e).format("DD/MM/YYYY")}</>}
            />
            <Table.Column
              title="วันหมดอายุ"
              dataIndex="expire_date"
              key="expire_date"
              align="center"
              render={(e) => <>{moment(e).format("DD/MM/YYYY")}</>}
            />
            <Table.Column
              title="Hornor Pin"
              dataIndex="hornor_code"
              key="hornor_code"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
            <Table.Column
              title="ประเภทสมาชิก"
              dataIndex="member_type"
              key="member_type"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
            <Table.Column
              title="สถานะ"
              dataIndex="status"
              key="status"
              align="center"
            />
            <Table.Column
              title="หมายเหตุ"
              dataIndex="remark"
              key="remark"
              align="center"
            />
            <Table.Column
              title="องค์กร"
              dataIndex="team_name"
              key="team_name"
              align="center"
            />
            <Table.Column
              title="รหัสผู้แนะนำ"
              dataIndex="sponsor_code"
              key="sponsor_code"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
            <Table.Column
              title="ชื่อผู้แนะนำ"
              dataIndex="sponsor_name"
              key="sponsor_name"
              align="center"
              render={(e) => <div style={{ textAlign: "left" }}>{e}</div>}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
