/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Select, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";

const { Text } = Typography;
const { Option } = Select;

const { changePieceSelect } = actionClaim;

const SelectPiece = ({ itemKey, hasError, clearError, disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const piece = useSelector((state) => {
    if (!product_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].piece;
  });
  const piece_list = useSelector((state) => {
    if (!product_key) return null;
    const {
      bom,
      sub_item_used,
      qty,
      claim_item: { activeKey, itemKeys, itemByKey },
    } = state.claim.billProduct.itemByKey[product_key];
    let all_piece = [];
    for (let i = 1; i <= qty; i++) all_piece = [...all_piece, i];
    const other_piece_used = _.reduce(
      itemKeys,
      (res, key) => (key === activeKey ? res : [...res, itemByKey[key].piece]),
      []
    );

    let master_qty_set = _.sum(_.map(bom, (set) => set.qty)) // จำนวนรวมสินค้าย่อยใน 1 ชิ้น
    let piece_amount = _.map(all_piece, (p, i) => {
      let bom_items = _.map(bom, (n, j) => {
        // หา qty ของแต่ละสินค้าย่อย โดยแยกตามจำนวนชิ้น
        let _piece = _.filter(sub_item_used, { 'ref_product_code': n?.ref_product_code, 'product_code': n?.product_code, 'ref_piece': p })
        let sum = _.sumBy(_piece, 'qty')
        return sum
      })
      return _.sum(bom_items) < master_qty_set ? p : 0 // เช็คว่าใน 1 ชิ้นเคลมครบหรือยัง
    })

    let avalible_piece = piece_amount.filter(piece => piece != 0) // จำนวนชิ้นที่สามารถเคลมได้อีก

    const piece_balance = _.reduce(
      avalible_piece,
      (res, piece) => {
        if (_.some(other_piece_used, (n) => piece === n)) return res;
        const sub_item_used_select = _.filter(
          sub_item_used,
          (n) => n.ref_piece === piece
        );
        const has_qty_balance = _.some(bom, (n) => {
          const claim_qty =
            _.find(
              sub_item_used_select,
              (m) => n.product_code === m.product_code
            )?.qty || 0;
          return n.qty - claim_qty > 0;
        });
        return has_qty_balance ? [...res, piece] : res;
      },
      []
    );
    return piece_balance;
  });

  return (
    <Row gutter={[8, 8]} type="flex" className="align-items-center my-3">
      <Col span={3} className="text-right">
        <Text>รายการชิ้น</Text>
      </Col>
      <Col span={21}>
        <div className={hasError && "has-error"}>
          <Select
            size="small"
            placeholder="เลือกรายการชิ้นที่"
            allowClear
            className="w-100"
            dropdownStyle={{ zIndex: 1080 }}
            value={piece ?? undefined}
            disabled={disabled}
            onChange={(e) => {
              dispatch(changePieceSelect(e));
              hasError && clearError(itemKey);
            }}
          >
            {_.map(piece_list, (n) => (
              <Option key={n} value={n}>
                ชิ้นที่ - {n}
              </Option>
            ))}
          </Select>
        </div>
        {!!hasError && (
          <Text className="text-nn py-1">*กรุณาเลือกรายการชิ้นที่</Text>
        )}
      </Col>
    </Row>
  );
};

export default memo(SelectPiece);
