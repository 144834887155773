/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";
import TextEditor from "../../../../../../components/TextEditor";
import moment from "moment";
import mwEcosysConstants from "../../../utils/constants/mwEcosysConstants";
import { dummyRequest } from "../../../components/dummyRequest";
import { DivStyles, ImgStyles } from "../../../components/mwStyles";
import useUploadFile from "../../../components/useUploadFile";
// import FileResizer from "react-image-file-resizer";
import Resizer from "react-image-file-resizer";
import useAsync from "../../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../../services/manage-website.service";
function HF_Nutrition({ history, match, form, ...props }) {
  const [textEditorDescription, setTextEditorDescription] = useState("");
  const [textEditorDetail, setTextEditorDetail] = useState("");
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);
  const [loadValue, setLoadValue] = useState("");
  const [amountSeq, setAmountSeq] = useState(0);
  let { onUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      let formData = new FormData();
      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      if (fileListMobile[0]?.file) {
        formData.append("mobile_img", fileListMobile[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img"]),
          description: textEditorDescription,
          detail: textEditorDetail,
          file_delete: fileDelete,
          seq: _.toNumber(amountSeq) * -1,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/ecosystem/healthy-food/hf-nutrition/" +
                match.params.id +
                "/update";
            } else {
              actionPathUrl =
                "/manage-website/ecosystem/healthy-food/hf-nutrition/";
            }

            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  // const handleRemoveMobile = (e) => {
  //   setFileListMobile([]);
  //   if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
  //     setFileDelete([...fileDelete, e.uid]);
  //   }
  // };

  const handleUploadDesktop = async (e) => {
    let isFile = await onUpload(
      e,
      mwEcosysConstants.hf_nutrition_page.desktop_img.width,
      mwEcosysConstants.hf_nutrition_page.desktop_img.height
    );
    if (isFile) {
      await setFileListDesktop(isFile);
      //------------------------------------resize file mobile img :
      let fileMobile = e.file.originFileObj;
      new Promise(async (resolve) => {
        await Resizer.imageFileResizer(
          fileMobile,
          334,
          241,
          "JPEG",
          100,
          0,
          async (uri) => {
            resolve(uri);
            let dataFile = [
              {
                file: { ...e.file },
                uid: e.file.uid,
                status: "done",
                name: e.file.name,
                url: uri,
              },
            ];
            if (dataFile.length > 0) {
              setFileListMobile(dataFile);
            }
          },
          "base64"
        );
      });
    }
  };

  // const handleUploadMobile = async (e) => {
  //   let isFile = await onUpload(
  //     e,
  //     mwEcosysConstants.hf_nutrition_page.mobile_img.width,
  //     mwEcosysConstants.hf_nutrition_page.mobile_img.height
  //   );
  //   if (isFile) {
  //     setFileListMobile(isFile);
  //   }
  // };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url:
        "/manage-website/ecosystem/healthy-food/hf-nutrition/" +
        match.params.id,
    })
      .then((res) => {
        let data = res.data?.data;
        setLoadValue(data);
        if (data) {
          setTextEditorDescription(data.description);
          setTextEditorDetail(data.detail);
        }

        let { loadDesktopImg, loadMobileImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListMobile(loadMobileImg);
          }
        }
        let { start_date, end_date } = data;
        form.setFieldsValue({
          ...data,
          date_range: [moment(start_date), moment(end_date)],
          // start_date: start_date == null ? null : moment(start_date),
          // end_date: end_date == null ? null : moment(end_date),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { execute: updateNutritionSeqById } = useAsync(
    manageWebsiteService.updateNutritionSeqById,
    {
      onSuccess: async () => {
        await loadContent();
      },
    }
  );

  const loadSeq = async () => {
    await axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/healthy-food/hf-nutrition",
    })
      .then((res) => {
        let data = res.data?.data;
        console.log("load seq:", data);
        setAmountSeq(data.length + 1);
        for (let i = 0; i < data.length; i++) {
          let x = i + 1;
          let myId = _.toNumber(data[i].id);
          if (x != data[i].seq) {
            updateNutritionSeqById({ myId, x });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadSeq();
    if (match.params.id !== "add") {
      loadContent(match.params.id);
    }
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>เนื้อหาโภชนาการ</PanelHeader>
        <PanelBody>
          <Form layout="vertical">
            <Row>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop.length != 0 ? (
                    <Row>
                      {`การแสดงผล Desktop = ${mwEcosysConstants.hf_nutrition_page.desktop_img.width} x ${mwEcosysConstants.hf_nutrition_page.desktop_img.height} px`}
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop[0].url}
                          alt="desktop_img"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile.length != 0 ? (
                    <>
                      {`การแสดงผล Mobile = ${mwEcosysConstants.hf_nutrition_page.mobile_img.width} x ${mwEcosysConstants.hf_nutrition_page.mobile_img.height} px`}
                      <div style={DivStyles}>
                        <img
                          src={fileListMobile[0].url}
                          alt="mobile_img"
                          style={ImgStyles}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item>
                    {getFieldDecorator("desktop_img", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop}
                        customRequest={dummyRequest}
                        // onRemove={handleRemoveDesktop}
                        onChange={handleUploadDesktop}
                        listType="picture"
                        showUploadList={false}
                      >
                        <AiOutlineUpload className="mr-3" />
                        เพิ่มรูปภาพขนาด 458 x 330 px
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                {/* <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Mobile = ${mwEcosysConstants.hf_nutrition_page.mobile_img.width} x ${mwEcosysConstants.hf_nutrition_page.mobile_img.height}`}
                  >
                    {getFieldDecorator("mobile_img", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveMobile}
                        onChange={handleUploadMobile}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col> */}
              </Row>
            </Row>
            <Row>
              <Form.Item label="Menu Name :">
                {getFieldDecorator("title", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ Menu Name" }],
                })(<Input />)}
              </Form.Item>
            </Row>
            <Row className="mb-5 mt-3">
              <p>Description :</p>
              <TextEditor
                authToken={getToken()}
                value={textEditorDescription}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setTextEditorDescription(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>
            <Row className="mb-5 mt-5">
              <p>Detail :</p>
              <TextEditor
                authToken={getToken()}
                value={textEditorDetail}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setTextEditorDetail(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>

            <Row></Row>

            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="เวลาเริ่มต้น - เวลาสิ้นสุด">
                  {getFieldDecorator("date_range", {
                    initialValue: null,
                    rules: [
                      {
                        required: true,
                        message: "กรุณาระบุ เวลาเริ่มต้น - เวลาสิ้นสุด",
                      },
                    ],
                  })(<DatePicker.RangePicker format="DD/MM/YYYY" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col md={24}>
                <Button
                  className="bg-nn border-nn text-white mr-3"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  CANCEL
                </Button>
                <Button type="primary" onClick={() => handleClickSubmit()}>
                  {loadValue != "" ? "Update" : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-hf-nutrition")(HF_Nutrition);
