import { Modal, Table } from "antd";
import React, { memo, useEffect, useState } from "react";
import { axios, getToken, moment, URL_API } from "../utils";
import { resCatch } from "../views/util/helper";

const { Column } = Table;

const HistoryModal = ({
  limit = 10,
  documentNo,
  documentType,
  processType,
  onCancel,
}) => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (documentNo && documentType && processType) {
      setLoading(true);
      axios({
        url: `${URL_API}/global/flow/history`,
        headers: { Authorization: "Bearer " + getToken() },
        params: {
          limit,
          offset: (page - 1) * limit,
          document_no: documentNo,
          document_type: documentType,
          process_type: processType,
        },
      })
        .then((res) => {
          setData(res.data.data);
          setTotal(res.data.total);
        })
        .catch((e) => resCatch(e))
        .finally(() => setLoading(false));
    }
  }, [documentNo, documentType, processType, page, limit]);

  const handleClose = () => {
    setData(null);
    setPage(1);
    setTotal(0);
    onCancel();
  };

  return (
    <Modal
      title="ประวัติเจ้าหน้าที่ผู้ดำเนินการ"
      visible={!!documentNo}
      maskClosable={false}
      footer={false}
      zIndex={1080}
      width={900}
      destroyOnClose={true}
      onCancel={handleClose}
    >
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={data ?? []}
        rowKey="row_id"
        scroll={{ x: "max-content" }}
        pagination={{
          current: page,
          pageSize: limit,
          total,
          showLessItems: true,
          hideOnSinglePage: true,
          onChange: (page) => setPage(page),
        }}
      >
        <Column
          width={200}
          title="วันที่ดำเนินการ"
          key="create_date"
          dataIndex="create_date"
          align="center"
          render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
        />
        <Column
          width={200}
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">สถานะ</div>}
          key="status_type_text"
          dataIndex="status_type_text"
        />
        <Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ผู้ดำเนินการ</div>
          }
          key="user_name"
          dataIndex="user_name"
        />
      </Table>
    </Modal>
  );
};

export default memo(HistoryModal);
