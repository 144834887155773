/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import React, { useCallback, useEffect, useReducer } from "react";
import TabUnderLine from "../../../../../components/TabUnderLine";
import { _ } from "../../../../../utils";
import ModalControl from "../ModalControl";
import BillHistory from "./components/BillHistory";
import Info from "./components/Info";
import MemberHistory from "./components/MemberHistory";
import PreviewModal from "./components/PreviewModal";
import MemberProfileContext, { initState, reducer } from "./reducer";

const Index = ({ visible, setVisible, partnerCode, refId, refType }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const handleClickProcess = useCallback((e) => {
    dispatch({
      type: "setState",
      payload: { name: "tabActive", value: e },
    });
  }, []);

  const handleClose = () => {
    setVisible(false);
    dispatch({
      type: "clear",
    });
  };

  useEffect(() => {
    if (visible)
      dispatch({
        type: "setGlobalState",
        payload: {
          name: "data",
          value: {
            partner_code: partnerCode,
            ref_id: refId,
            ref_type: refType,
          },
        },
      });
  }, [visible, partnerCode, refId, refType]);

  return (
    <MemberProfileContext.Provider value={{ state, dispatch }}>
      <ModalControl
        visible={visible}
        width={1000}
        footerLine={false}
        destroyOnClose={true}
        onCancel={handleClose}
      >
        <TabUnderLine
          active={state.tabActive}
          list={state.listTab}
          onClick={handleClickProcess}
        />
        <Row className="mt-3">
          <Col span={24}>
            <div className={state.tabActive !== "info" && "d-none"}>
              <Info
                hiding={state.tabActive !== "info"}
                partnerCode={state.data.partner_code}
                refId={state.data.ref_id}
                refType={state.data.ref_type}
              />
            </div>

            <div className={state.tabActive !== "member" && "d-none"}>
              <MemberHistory />
            </div>

            {_.includes(["sale", "redeem"], state.tabActive) && (
              <BillHistory salesType={state.tabActive} />
            )}
          </Col>
        </Row>
        {state.tabActive === "member" && <PreviewModal />}
      </ModalControl>
    </MemberProfileContext.Provider>
  );
};

export default Index;
