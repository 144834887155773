/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaDownload, FaSearch } from 'react-icons/fa';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { AlertConfirm } from '../../../util/Alert';
import { Input, Table } from 'antdV4';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';
import registerPaperConstants from '../constants/registerPaperConstants';
import { Controller, useForm } from 'react-hook-form';
import {
  trainerRegisterPaperQueryDefaultValues,
  trainerRegisterPaperQueryFormSchema,
} from '../components/dto/trainer-register-paper';
import DownloadModal from '../components/modal/DownloadModal';

const TrainerRegisterPaperPage = () => {
  const [page, setPage] = useState(0);
  const [isOnSearch, setIsOnSearch] = useState(false);
  const [id,setId]=useState('')
  const [registerPaperDownloadOpen, setRegisterPaperDownloadOpen] =
    useState(false);
  const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);

  const _HandleRegisterPaperDownloadOpen = () => {
    setRegisterPaperDownloadOpen(true);
  };

  const _HandleRegisterPaperDownloadClose = () => {
    setRegisterPaperDownloadOpen(false);
  };

  const { execute: downloadTrainerRegisterPaperById } = useAsync(
    fitStudioService.downloadTrainerRegisterPaperById,
    {}
  );

  const columns = [
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record.nameTitle} {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              setId(record.id)
              _HandleRegisterPaperDownloadOpen();
            }}
          >
            <FaDownload />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบใบสมัคร');
              if (confirm) deleteTrainerRegisterPaper({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  const { execute: downloadTrainerRegisterPaperExcelByFilter } = useAsync(
    fitStudioService.downloadTrainerRegisterPaperExcelByFilter,
    {
      onSuccess: () => {
        setTimeout(() => {
          setExcelDownloadLoading(false);
        }, 3000);
      },
    }
  );

  useEffect(() => {
    getTrainerRegisterPapers({
      page: page + 1,
      take: registerPaperConstants.tableSize,
    });
  }, [page]);

  const { execute: getTrainerRegisterPapers, data: registerPaperLists } =
    useAsync(fitStudioService.getTrainerRegisterPapers, {});

  const { data: deletedRes, execute: deleteTrainerRegisterPaper } = useAsync(
    fitStudioService.deleteTrainerRegisterPaper,
    {}
  );

  useEffect(() => {
    getTrainerRegisterPapers({
      page: page + 1,
      take: registerPaperConstants.tableSize,
    });
  }, [deletedRes]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: trainerRegisterPaperQueryDefaultValues,
    resolver: trainerRegisterPaperQueryFormSchema(),
  });

  const _HandleSubmit = handleSubmit((data) => {
    setIsOnSearch(true);
    getTrainerRegisterPapers({
      query: {
        ...(data.firstName && { firstName: data.firstName }),
        ...(data.lastName && { lastName: data.lastName }),
        ...(data.phoneNumber && { phoneNumber: data.phoneNumber }),
      },
      take: -1,
      page: 1,
    });
  });

  const _HandleExportExcel = handleSubmit((data) => {
    setExcelDownloadLoading(true);
    if (isOnSearch) {
      downloadTrainerRegisterPaperExcelByFilter({
        query: {
          ...(data.firstName && { firstName: data.firstName }),
          ...(data.lastName && { lastName: data.lastName }),
          ...(data.phoneNumber && { phoneNumber: data.phoneNumber }),
        },
        take: -1,
        page: 1,
      });
    } else {
      downloadTrainerRegisterPaperExcelByFilter({
        page: page + 1,
        take: registerPaperConstants.tableSize,
      });
    }
  });

  const _HandleCancel = () => {
    reset(trainerRegisterPaperQueryDefaultValues);
    setIsOnSearch(false);
    getTrainerRegisterPapers({
      page: page + 1,
      take: registerPaperConstants.tableSize,
    });
  };

  return (
    <Panel>
      <PanelHeader>ใบสมัครเทรนเนอร์</PanelHeader>
      <PanelBody>
        <div
          style={{
            marginBottom: 10,
            border: '1px solid #f2f3f4',
            borderRadius: 5,
            padding: 10,
          }}
        >
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ชื่อ"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="นามสกุล"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="เบอร์โทรศัพท์"
                    className="mr-2"
                  />
                );
              }}
            />
          </div>
          <div
            className="mb-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={_HandleSubmit}
            >
              <FaSearch className="mr-2" />
              ค้นหา
            </Button>
            <Button
              className="bg-pv border-pv text-white"
              onClick={_HandleCancel}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
        <Row className="mb-2">
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              if (
                registerPaperLists?.data &&
                registerPaperLists?.data.length > 0
              ) {
                setExcelDownloadLoading(true);
                _HandleExportExcel();
              }
            }}
            loading={excelDownloadLoading}
            disabled={
              registerPaperLists && registerPaperLists.data.length > 0
                ? false
                : true
            }
          >
            Export Excel
          </Button>
        </Row>
        {isOnSearch ? (
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={
              registerPaperLists?.data && registerPaperLists?.data.length > 0
                ? registerPaperLists.data
                : []
            }
            onChange={({ current }) => {
              setPage(current - 1);
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={{
              pageSize: registerPaperConstants.tableSize,
              total: registerPaperLists?.meta?.total || 0,
            }}
          />
        ) : (
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={
              registerPaperLists?.data && registerPaperLists?.data.length > 0
                ? registerPaperLists.data
                : []
            }
            onChange={({ current }) => {
              setPage(current - 1);
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={{
              pageSize: registerPaperConstants.tableSize,
              total: registerPaperLists?.meta?.total || 0,
            }}
          />
        )}
      </PanelBody>
      <DownloadModal
        open={registerPaperDownloadOpen}
        handleClose={_HandleRegisterPaperDownloadClose}
        title="คุณต้องการดาวน์โหลดเอกสารใบสมัครใช่หรือไม่"
        handleDownload={(id) => {
          downloadTrainerRegisterPaperById(id);
        }}
        registerPaperId={id}
      />
    </Panel>
  );
};

export default TrainerRegisterPaperPage;
