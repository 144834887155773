import { Alert, Table } from "antd";
import React, { useContext } from "react";
import UserContext from "../../../../../../contexts/user.context";
import { useApi } from "../../../../../../hooks/http2";

const ProductTypeList = () => {
    const { state, dispatch } = useContext(UserContext);
    const { loading, fetch, error } = useApi(
        {
            url: "/system/master/product_type",
            token: true,
        },
        []
    );

    if (error) {
        return (
            <Alert
                message="ผิดพลาด"
                type="error"
                showIcon
                description={error}
            />
        );
    }

    const handleChangeSelect = (selectedRowKeys) => {
        dispatch({
            type: "SET_FORM_VALUE",
            payload: { ...state.form, product_type: selectedRowKeys },
        });
    };

    return (
        <Table
            loading={loading}
            dataSource={
                fetch?.data
                    ? fetch?.data?.map((n, i) => ({ ...n, key: i }))
                    : []
            }
            size="small"
            bordered
            pagination={false}
            rowKey="code"
            rowSelection={{
                selectedRowKeys: state.form.product_type,
                onChange: handleChangeSelect,
            }}
        >
            <Table.Column title="ประเภทสินค้า" dataIndex="name" />
        </Table>
    );
};

export default ProductTypeList;
