/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initState = {
  search: {
    query: "",
    display_type: "waiting",
    status: "Active",
    page: 1,
    limit: 10,
    member_type: "distributor",
  },
  list: {
    loading: false,
    data: [],
    total: 0,
  },
  form: {
    id: null,
    type: null,
    loading: false,
    active_tab: {
      info: "member",
      address: "id_card",
      bank: "person",
      upload: "document",
      remark: "remark",
    },
    member: null,
    married: null,
    address: null,
    bank: null,
    upload: {
      active: null,
      uploaded_document: [],
      document_list:[]
    },
    remark: [],
  },
  modal: {
    name: null,
    props: {
      loading: false,
      search: {},
      list: {
        data: [],
        total: 0,
      },
    },
  },
   findMarried: null,
   addressMarried:null,
  remark : null
  
};

const ethicsSlice = createSlice({
  name: "ethics",
  initialState: initState,
  reducers: {
    SET_ACTIVE: (state, { payload }) => {
      state.search = {
        ...initState.search,
        member_type: payload,
      };
    },
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    SET_MEMBER_TYPE: (state, { payload }) => {
      state.search = {
        ...initState.search,
        member_type: payload,
      };
    },
    CLEAR_LIST: (state) => {
      state.list = {
        ...initState.list,
      };
    },
    SET_LOAD_LIST: (state, { payload }) => {
      state.list.loading = payload;
    },
    SET_LOAD_LIST_SUCCESS: (state, { payload }) => {
      state.list = {
        ...state.list,
        ...payload,
        loading: false,
      };
    },
    OPEN_MODAL: (state, { payload }) => {
      state.modal.name = payload.name;
      state.modal.props = {
        ...state.modal.props,
        ...payload.props,
      };
    },
    SET_MODAL_PROPS: (state, { payload }) => {
      state.modal.props = { ...state.modal.props, ...payload };
    },
    SET_MODAL_SEARCH: (state, { payload }) => {
      state.modal.props.search = { ...state.modal.props.search, ...payload };
    },
    CLOSE_MODAL: (state) => {
      state.modal = {
        ...initState.modal,
      };
    },
    CLEAR_FORM: (state) => {
      state.form = { ...initState.form };
    },
    SET_FORM: (state, { payload }) => {
      state.form.id = payload.id;
      state.form.type = payload.type;
    },
    SET_LOAD_FORM: (state, { payload }) => {
      state.form.loading = payload;
    },
    SET_LOAD_FORM_SUCCESS: (state, { payload }) => {
      state.form.loading = false;
      state.form = {
        ...state.form,
        ...payload,
      };
    },
    SET_FORM_AVATAR: (state, { payload }) => {
      state.form[payload.data_type].avatar = payload.url;
    },
    SET_ACTIVE_TAB: (state, { payload }) => {
      state.form.active_tab[payload.key] = payload.value;
    },
    SET_LOAD_FILE: (state, { payload }) => {
      state.form.upload.loading = payload;
    },
    SET_LOAD_FILE_SUCCESS: (state, { payload }) => {
      //state.form.upload.loading = false;
      state.form.upload.list = payload;
      //state.form.upload.reload = false;
    },
    SET_FORM_UPLOAD: (state, { payload }) => {
      state.form.upload = {
        ...state.form.upload,
        ...payload,
      };
    },
    SET_MARRIED_STATUS : (state, {payload}) =>{
      state.form.married.status = payload
    },
    SET_IS_COMPANY : (state, {payload}) =>{
      state.form.is_company = payload
    },
    SET_MARRIED_TEMP_AVATAR : (state, {payload}) =>{
      state.form.temp_url = payload
    },
    SET_FORM_MARRIED : (state, {payload}) =>{
      state.form.married = {...state.form.married, ...payload}
    },
    SET_REMARK : (state, {payload}) =>{
      state.remark = payload
    },
    CLEAR_FORM_MARRIED : (state) => {
      state.form.married = { ...initState.form.married };
    },
    CLEAR_FORM_ADDRESS : (state) => {
      state.form.address = { ...initState.form.address };
    },
    SET_FORM_ADDRESS : (state, {payload}) =>{
      let index = _.findIndex(state.form.address, (n) =>payload.address_type === n.address_type)
      if(index != -1){
        state.form.address[index] = {...state.form.address[index],...payload}
      }else{
        let newAddress = [...state.form.address]
        newAddress.push({...payload})
        state.form.address = newAddress
      }
      
    },
    
    SET_FORM_MEMBER : (state, {payload}) => {
      state.form.member = {...state.form.member, 
                            ...payload
                          }
    },
    SET_FORM_BANK : (state, {payload}) =>{
      let index = _.findIndex(state.form.bank, (n) =>payload.eft_type === n.eft_type)
      if(index != -1){
        state.form.bank[index] = {...state.form.bank[index], 
          ...payload
        }
      }else{
        let newBank = [...state.form.bank]
        newBank.push({...payload})
        state.form.bank = newBank
      }
      
    },
    DELETE_FORM_BANK : (state, {payload}) =>{
      let newBank = _.filter(state.form.bank, (n) => n.eft_type != payload.eft_type)
      state.form.bank = newBank
    },
    SET_COMPANY_ADDRESS : (state) => {
      let personal_address =  _.find(state.form.address, (n) => n.address_type === 1)
      let new_address = _.filter(state.form.address, (n) => n.address_type != 4)
      personal_address = {...personal_address
                          , address_type : 4
                          , address_type_text : "ที่อยู่สำหรับออกใบกำกับภาษี"}

      new_address.push(personal_address)
      
      state.form.address = new_address
      
    },
    SET_SPONSOR : (state,{payload}) => {
      state.form.sponsor = payload 
    },
    SET_FOUND_MARRIED: (state, { payload }) => {
      state.findMarried = payload;
      state.addressMarried = payload;

    },

    RESET_FORM_STATE: (state) => {
      state.findMarried = null;
    },
    
  },
});

export const actionEthics = ethicsSlice.actions;

export const activeSelector = (state) => state.ethics.search.member_type;
export const searchSelector = (state) => state.ethics.search;

export const findMarried = (state) => state.ethics.findMarried;
export const addressMarried = (state) => state.ethics.addressMarried;

const EthicsReducer = ethicsSlice.reducer;

export default EthicsReducer;
