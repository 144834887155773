/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select, DatePicker } from 'antd';
import { ErrorMessage } from '@hookform/error-message';

import moment from 'moment';
import ImageUpload from '../../../../fit-studio/components/upload/ImageUpload';
import {
  EventCalendarAnnounceStatus,
  eventCalendarAnnounceConstants,
} from '../dto/event-calendar-announce';
import eventCalendarAnnounceServices from '../../../../../../services/event-calendar-announce.services';
import { URL_API, getToken } from '../../../../../../utils';
import TextEditor from '../../../../../../components/TextEditor';

const EventCalendarAnnounceForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  eventCalendarAnnounceServices.useQueryGetEventCalendarAnnounceById(
    { eventCalendarAnnounceId: watch('id') },
    (data) => {
      reset({
        ...data,
        formType: 'edit',
        announcedDate: moment(data.announcedDate),
      });
    }
  );

  return (
    <div>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={eventCalendarAnnounceConstants.thumbnailImage.width}
                  height={eventCalendarAnnounceConstants.thumbnailImage.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  หัวข้อประกาศ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="หัวข้อประกาศ"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำบรรยาย <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <TextEditor
                  authToken={getToken()}
                  value={value}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    onChange(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: 'web-content' }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="announcedDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันประกาศ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันประกาศ"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="startDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="status"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>การมองเห็น</Typography.Text>
              </Col>
              <Col span={20}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {EventCalendarAnnounceStatus.map((item) => {
                    return (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
};

export default EventCalendarAnnounceForm;
