import React, { useEffect, useState } from "react";
import { Modal, Button, Table, message } from "antd";
import dayjs from "dayjs";
import { axios } from "../../../../../utils/useAxios";
import ModalDetail from "./ModalDetail";
import { FaEye } from "react-icons/fa";


const ModalHistory = ({ visible, onCancel }) => {
  const [historyData, setHistoryData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const fetchHistoryData = async () => {
    try {
      const response = await axios.get("/legacy-verse/import/file/history");
      const fetchedData = response?.data || [];

      const formattedData = fetchedData.map((item, index) => ({
        key: index + 1, 
        fileName: item.FileName,
        sheetName: item.SheetName,
        totalRecord: item.TotalRecord,
        createDate: item.CreateDate,
        createBy: item.CreateBy,
        id: item.Id,
      }));

      setHistoryData(formattedData);
    } catch (error) {
      message.error("ไม่สามารถโหลดข้อมูลได้");
    }
  };

  useEffect(() => {
    if (visible) {
      fetchHistoryData();
    }
  }, [visible]);

  const handleOpenDetail = (id) => {
    setSelectedId(id);
    setIsDetailVisible(true);
  };

  return (
    <>
      <Modal
        title="ประวัติการอัปโหลด"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button key="close" onClick={onCancel}>
            ปิด
          </Button>,
        ]}
        width={1000}
      >
        <Table dataSource={historyData} bordered pagination={{ pageSize: 5 }}>
          <Table.Column title="ลำดับ" dataIndex="key" align="center" />
          <Table.Column title="ชื่อไฟล์" dataIndex="fileName" />
          <Table.Column title="ชื่อชีต" dataIndex="sheetName" />
          <Table.Column
            title="จำนวนเรคคอร์ด"
            dataIndex="totalRecord"
            align="center"
          />
          <Table.Column
            title="วันที่อัปโหลด"
            dataIndex="createDate"
            render={(date) => dayjs(date).format("DD-MM-YYYY HH:mm:ss")}
          />
          <Table.Column title="อัปโหลดโดย" dataIndex="createBy" />

          <Table.Column
            title="ดู"
            key="action"
            dataIndex="id"
            align="center"
            render={(id) => (
              <Button
                type="primary"
                shape="circle"
                size="small"
                onClick={() => handleOpenDetail(id)}
              >
                <FaEye />
              </Button>
            )}
          />
        </Table>
      </Modal>

      <ModalDetail
        visible={isDetailVisible}
        onCancel={() => setIsDetailVisible(false)}
        id={selectedId} 
      />
    </>
  );
};

export default ModalHistory;
