/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Modal,
  notification,
  Popconfirm,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import numeral from "numeral";
import React, { useCallback, useEffect, useState } from "react";
import {
  FaBullhorn,
  FaEye,
  FaLink,
  FaMinus,
  FaPlus,
  FaTrashAlt,
} from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import QrButton from "../../../../components/QrButton";
import QrModal from "../../../../components/QrModal";
import { fixProductTypeSet } from "../../../../config/fix-value";
import { _, axios, URL_API } from "../../../../utils";
import { getDataCheckStock } from "../ducks/logic/data-check-stock";
import { Creators } from "../ducks/operation-form";
import BomDetail from "./BomDetail";
import ButtonIcon from "./ButtonIcon";
import InputNumber from "./InputNumber";
import ItemDetailPopup from "./ItemDetailPopup";
import LinkBom from "./LinkBom";
import { isProItem } from "./RecPromotion/logic/validate";
import Table from "./Table";
import Tabs from "./Tabs";
import { resCatch } from "../../../util/helper";

const PosOrderDetail = ({ match, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [productCode, setProductCode] = useState(null);
  const [qrProductKeySelect, setQrProductKeySelect] = useState(null);
  const [linkBom, setLinkBom] = useState(null);
  const [branchType, setBranchType]=useState("");
  const type_params = match.params.type;
  const isNormal = _.includes(
    ["sale", "subscription", "pre-sale"],
    type_params
  );
  const isPreProduct = _.includes(["pre-sale", "pre-redeem"], type_params);


  useEffect(()=>{
    try {
      axios({
        url: `${URL_API}/pos/master/branch`,
      }).then((res)=>{
        const branch_detail = _.find(res.data?.data,{code : props.receipt_branch_code})
        if(branch_detail){
          setBranchType(branch_detail.branch_type)
        }
      })
      
    } catch (e) {
      return resCatch(e);
    }
  },[props.receipt_branch_code])
  
  useEffect(() => {
    if (props.activeKey === "")
      props.history.replace(`/pos/${type_params}/buyer`);
  }, []);

  const handleClickBtnQty = (process, record) => {
    // console.log(process,record)
    const {
      key,
      product_code,
      qty: curr_qty,
      stock_balance = 0,
      qr = [],
      product_type_set,
      product_type,
      course_ralc,
    } = record;
    let return_qty = 1;
    if(product_type === "PT04" && process === 'plus'){
      return notification.warning({
        message: "ไม่สามารถเพิ่มจำนวนสินค้า",
        description: "ต่ออายุซื้อได้แค่ 1 ชุดเท่านั้น",
      });
    }
    if(course_ralc && process === 'plus'){
      return notification.warning({
        message: "ไม่สามารถเพิ่มจำนวนสินค้า",
        description: "Course RALC ซื้อได้แค่ 1 Course เท่านั้น",
      });
    }
    if (process === "minus") return_qty = -1;
    let qty = curr_qty + return_qty;
    if (qty < 1)
      return Modal.confirm({
        title: "คุณต้องการลบรายการนี้หรือไม่?",
        content: "เนื่องจากจำนวนถูกลดเหลือ 0 คุณต้องการลบรายการนี้หรือไม่?",
        okText: "ลบ",
        cancelText: "ยังก่อน",
        onOk() {
          props.delOrderItem(key);
        },
      });
    if (
      !isPreProduct &&
      !_.includes(
        [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
        product_type_set
      )
    ) {
      const used_qty = props.all_items[product_code]?.qty || 0;
      if (!stock_balance || stock_balance < return_qty + used_qty)
        return notification.warning({
          message: "ไม่สามารถเพิ่มจำนวนสินค้า",
          description: "ไม่มีสินค้าที่เลือก (sold out)",
        });
    }
    let custom_qr = {};
    if (qty < qr.length) custom_qr = { qr: qr.slice(0, qr.length - 1) };
    props.setOrderItemValue(key, {
      ...custom_qr,
      qty,
    });
  };

  const handleChangeQty = (e, record) => {
    const {
      key,
      product_code,
      stock_balance,
      qr = [],
      product_type_set,
      qty,
      product_type,
      course_ralc,
    } = record;
    let { value } = e.target;
    let new_qr = [...qr];
    const return_qty = value - qty;
    value = +value || 0;
    if (value < 1)
      return Modal.confirm({
        title: "คุณต้องการลบรายการนี้หรือไม่?",
        content: "เนื่องจากจำนวนถูกลดเหลือ 0 คุณต้องการลบรายการนี้หรือไม่?",
        okText: "ลบ",
        cancelText: "ยังก่อน",
        onOk() {
          props.delOrderItem(key);
        },
      });
    if(product_type === "PT04"){
      return notification.warning({
        message: "ไม่สามารถเพิ่มจำนวนสินค้า",
        description: "ต่ออายุซื้อได้แค่ 1 ชุดเท่านั้น",
      });
    }  
    if(course_ralc){
      return notification.warning({
        message: "ไม่สามารถเพิ่มจำนวนสินค้า",
        description: "Course RALC ซื้อได้แค่ 1 Course เท่านั้น",
      });
    }
    const diff_length = new_qr.length - value;
    if (diff_length > 0)
      new_qr.splice(new_qr.length - diff_length, diff_length);
    if (
      !isPreProduct &&
      !_.includes(
        [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
        product_type_set
      )
    ) {
      const used_qty = props.all_items[product_code]?.qty || 0;
      if (!stock_balance || stock_balance < return_qty + used_qty) {
        value = stock_balance - (used_qty - qty);
        notification.warning({
          message: "ไม่สามารถเพิ่มจำนวนสินค้า",
          description: "ไม่มีสินค้าที่เลือก (sold out)",
        });
      }
    }
    props.setOrderItemValue(key, {
      qty: value,
      qr: new_qr,
    });
  };

  let columns = [
    {
      title: "",
      render: (_text, _record, index) => (
        <p className="m-0 text-right">{index + 1}</p>
      ),
    },
    {
      title: "รายการสินค้า",
      className: "w-100",
      dataIndex: "product_name",
      render: (text, record) => {
        const promotion_match = _.some(
          props.promotionItem,
          (n) =>
            n.order_key === props.activeKey &&
            (n.product_code === record.product_code ||
              _.some(n.items, (m) => m.product_code === record.product_code))
        );
        const is_order_item = !record.promotion_id;
        const is_item = is_order_item || record.promotion_item_type === "item";
        return (
          <>
            <p className="m-0 d-flex">
              {`${record.product_code} ${text}`}
              {!isPreProduct &&
                !_.includes(
                  [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
                  record.product_type_set
                ) &&
                record.stock_balance <= 0 && (
                  <Typography.Text className="ml-2 text-reds">
                    (sold out)
                  </Typography.Text>
                )}
              {record.keep && (
                <Typography.Text className="ml-2 text-reds">
                  (ใช้งานได้ครั้งถัดไป)
                </Typography.Text>
              )}
              {is_item && (
                <Tooltip title="รายละเอียดสินค้า">
                  <Button
                    type="link"
                    size="small"
                    className="p-0 ml-2"
                    onClick={() => {
                      setProductCode(record);
                      setVisible(true);
                    }}
                  >
                    <span className="d-flex justify-content-center align-items-center bg-pv text-white rounded-circle p-md-1">
                      <FaEye />
                    </span>
                  </Button>
                </Tooltip>
              )}
              {is_item && (
                <QrButton
                  tooltip
                  qrWarning={record.qr_warning}
                  onClick={() => setQrProductKeySelect(record.key)}
                />
              )}
              {props.promotionLinkBom[record.product_code] && (
                <Tooltip title="สินค้าจัดเซ็ต">
                  <Button
                    type="link"
                    size="small"
                    className="p-0 ml-2"
                    onClick={() => setLinkBom(record)}
                  >
                    <span className="d-flex justify-content-center align-items-center bg-rp text-white rounded-circle p-md-1">
                      <FaLink />
                    </span>
                  </Button>
                </Tooltip>
              )}
              {is_order_item &&
                (props.promotionLoading ? (
                  <Spin spinning size="small" className="ml-2 pt-1" />
                ) : (
                  <>
                    {promotion_match ? (
                      <Tooltip title="สินค้าโปรโมชัน">
                        <Button
                          type="link"
                          size="small"
                          className="p-0 ml-2"
                          onClick={() =>
                            props.setMatchingPromotionItem({
                              product_code: record.product_code,
                              allow_show_recommend: true,
                            })
                          }
                        >
                          <span className="d-flex justify-content-center align-items-center bg-reds text-white rounded-circle p-md-1">
                            <FaBullhorn />
                          </span>
                        </Button>
                      </Tooltip>
                    ) : (
                      _.some(
                        props.promotions_item_list,
                        (item) =>
                          isProItem(item.condition_type) &&
                          item.item_key === record.product_code
                      ) && (
                        <Button
                          type="link"
                          size="small"
                          className="p-0 ml-2"
                          disabled
                        >
                          <span className="d-flex justify-content-center align-items-center bg-gray-60 text-white rounded-circle p-md-1">
                            <FaBullhorn />
                          </span>
                        </Button>
                      )
                    )}
                  </>
                ))}
            </p>
            {record && record.bom?.length > 0 ? (
              <BomDetail list={record.bom} qty={record.qty} />
            ) : null}
          </>
        );
      },
    },
    {
      title: "PV/หน่วย",
      className: "text-nowrap",
      dataIndex: "pv",
      render: (text) => (
        <p className="m-0 text-right">{numeral(text).format("0,0.00")}</p>
      ),
    },
    {
      title: isNormal ? "ราคา/หน่วย" : "คะแนน/หน่วย",
      className: "text-nowrap",
      dataIndex: "unit_price",
      render: (text) => (
        <p className="m-0 text-right">{numeral(text).format("0,0")}</p>
      ),
    },
    {
      title: "จำนวน",
      className: "text-nowrap",
      dataIndex: "qty",
      render: (text, record) => {
        const is_order_item = !record.promotion_id && !record.auto_add;
        return (
          <p className="m-0 d-flex justify-content-center">
            {is_order_item && (
              <ButtonIcon onClick={() => handleClickBtnQty("minus", record)}>
                <FaMinus />
              </ButtonIcon>
            )}
            <InputNumber
              disabled={!is_order_item}
              value={text}
              onFocus={(e) => e.target.select()}
              onChange={(e) => handleChangeQty(e, record)}
              style={{ height: "2.5vh" }}
            />
            {is_order_item && (
              <ButtonIcon onClick={() => handleClickBtnQty("plus", record)}>
                <FaPlus />
              </ButtonIcon>
            )}
          </p>
        );
      },
    },
    {
      title: "น้ำหนัก",
      className: "text-nowrap",
      render: (text, record) => (
        <p className="m-0 text-right">
          {numeral(record.weight * (record.qty || 0)).format("0,0.00")}
        </p>
      ),
    },
    {
      title: "PV",
      className: "text-nowrap",
      render: (text, record) => (
        <p className="m-0 text-right">
          {numeral(record.pv * (record.qty || 0)).format("0,0.00")}
        </p>
      ),
    },
    {
      title: isNormal ? "ราคา" : "คะแนน",
      className: "text-nowrap",
      render: (text, record) => (
        <p className="m-0 text-right">
          {numeral(record.unit_price * (record.qty || 0)).format("0,0")}
        </p>
      ),
    },
    {
      title: "",
      render: (_text, { key, promotion_id, source_option, auto_add }) =>
        !auto_add && (
          <Popconfirm
            placement="topRight"
            title={`${
              !promotion_id
                ? "ต้องการลบรายการนี้หรือไม่"
                : "ยืนยันการลบโปรโมชัน"
            }`}
            onConfirm={() => {
              if (!promotion_id) {
                props.delOrderItem(key);
              } else {
                props.delOrderPromotionItem({ promotion_id, source_option });
              }
            }}
            okText="ลบ"
            cancelText="ไม่ลบ"
          >
            <Button type="link" size="small" className="p-0 text-dark">
              <FaTrashAlt />
            </Button>
          </Popconfirm>
        ),
    },
  ];

  if (props.is_staff || !isNormal || props.is_vip_member) {
    columns = _.filter(
      columns,
      (n) => !_.includes(["PV/หน่วย", "PV"], n.title)
    );
  }

  const qrCloseModal = useCallback(() => setQrProductKeySelect(null), []);
  const qrValidateUsed = useCallback(
    (qr_running) => {
     const inUse =  _.some(props.qrUsedList, (n) => n.qr_running === qr_running)
      if(branchType === 'pick and pay' && qr_running === ''){
        return false
      }else{
        return inUse
      }
    },[props.qrUsedList]
  );
  const qrSetPromotionValue = useCallback(
    ({ promotion_id, source_option, product_code, data }) =>
      props.setOrderPromotionItemValue(
        promotion_id,
        source_option,
        product_code,
        data
      ),
    []
  );
  const qrSetItemValue = useCallback(({ key, value }) => {
    const { qty } = value;
    if (qty === 0) return props.delOrderItem(key);
    props.setOrderItemValue(key, value);
  }, []);
  const qrSetItemValueBom = useCallback((e) => props.addOrderItemBomQr(e), []);
  const qrDeleteItemBom = useCallback((e) => props.delOrderItemBomQr(e), []);

  return (
    <div
      className={`pos-order-detail bg-gray-20 ${
        props.showItems ? "show-items" : ""
      }`}
    >
      <Tabs
        canNewTab={_.includes(["sale", "subscription"], type_params)}
        data={props.bills}
        active={props.activeKey}
        onNewTab={() => props.history.replace(`/pos/${type_params}/buyer`)}
        onClick={(e) => props.setBillActive(e)}
        canCloseTab={props.bills.length > 1}
        onCloseTab={(bill_key) => {
          props.delBill(bill_key);
        }}
      />
      <Table
        columns={columns}
        dataSource={props.dataSource}
        className="custom-scroll"
      />

      <ItemDetailPopup
        visible={visible}
        product_code={productCode?.product_code}
        DataSelectProduct={productCode}
        setShowDetailProduct = {setVisible}
        onCancel={() => {
          setProductCode(null);
          setVisible(false);
        }}
      />

      <QrModal
        disabled={!props.allow_qr}
        product={
          qrProductKeySelect &&
          _.find(props.dataSource, (n) => n.key === qrProductKeySelect)
        }
        receipt_branch_code={props.receipt_branch_code}
        partnerType={props.partner_type}
        salesType={type_params}
        onCancel={qrCloseModal}
        validateUsed={qrValidateUsed}
        setPromotionValue={qrSetPromotionValue}
        setItemValue={qrSetItemValue}
        setItemValueBom={qrSetItemValueBom}
        deleteItemBom={qrDeleteItemBom}
      />

      <LinkBom
        productCode={linkBom?.product_code}
        record={linkBom}
        setVisible={setLinkBom}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    buyer_type,
    orders: { itemByKey, itemKeys, activeKey },
    matching_promotion_item: {
      data: promotionItem,
      link_bom: promotionLinkBom,
      loading: promotionLoading,
    },
  } = state.pos.operation;
  let items = [];
  if (activeKey) {
    const promotion = _.map(
      itemByKey[activeKey].promotions.promotion_list,
      (n, i) => {
        const key = `data-pro-item-${i}`;
        return { ...n, key };
      }
    );
    items = _.map(itemByKey[activeKey].items.itemKeys, (n) => ({
      ...itemByKey[activeKey].items.itemByKey[n],
      key: n,
    }));
    items = [...items, ...promotion];
  }
  const qrUsedList = _.reduce(
    itemKeys,
    (res, order_key) => {
      const {
        items,
        promotions: { promotion_list },
      } = itemByKey[order_key];
      const qr_item = _.reduce(
        items.itemKeys,
        (res, item_key) => {
          // const { qr = [], bom } = items.itemByKey[item_key];
          const qr = items.itemByKey[item_key]?.qr || [];
          const bom = items.itemByKey[item_key]?.bom || [];
          const qr_bom = _.reduce(
            bom,
            (res, n) => (n.qr ? [...res, ...n.qr] : res),
            []
          );
          return [...res, ...qr, ...qr_bom];
        },
        []
      );
      const qr_promotion = _.reduce(
        promotion_list,
        (res, n) => [...res, ...(n.qr || [])],
        []
      );
      return [...res, ...qr_item, ...qr_promotion];
    },
    []
  );
  const dataCheckStock = getDataCheckStock(state.pos.operation.orders);

  return {
    ...dataCheckStock,
    promotionItem,
    promotionLinkBom,
    promotionLoading,
    qrUsedList,
    is_staff: buyer_type?.toLowerCase() === "staff",
    is_vip_member:
      itemByKey[activeKey]?.partner_type?.toLowerCase() === "member",
    bills: _.map(itemKeys, (n) => ({
      ...itemByKey[n],
      key: n,
      title: n,
    })),
    activeKey: activeKey,
    dataSource: items,
    allow_qr: itemByKey[activeKey]?.allow_qr,
    showItems: itemByKey[activeKey] ? itemByKey[activeKey].showItems : false,
    promotions_item_list: itemByKey[activeKey]?.promotions.item_list || [],
    partner_type: itemByKey[activeKey]?.partner_type.toLowerCase() || null,
    req_receipt_type: itemByKey[activeKey]?.req_receipt_type || null,
    receipt_branch_code: itemByKey[activeKey]?.receipt_branch_code || null,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    delOrderItem: Creators.delOrderItem,
    setBillActive: Creators.setBillActive,
    setOrderItemValue: Creators.setOrderItemValue,
    delBill: Creators.delBill,
    delOrderPromotionItem: Creators.delOrderPromotionItem,
    setMatchingPromotionItem: Creators.setMatchingPromotionItem,
    setOrderPromotionItemValue: Creators.setOrderPromotionItemValue,
    addOrderItemBomQr: Creators.addOrderItemBomQr,
    delOrderItemBomQr: Creators.delOrderItemBomQr,
  })(PosOrderDetail)
);
