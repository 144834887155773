/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Table, Typography } from "antd";
import React, { useContext } from "react";
import { FaCogs, FaPlus } from "react-icons/fa";
import { PromotionContext } from "../reducers";

const conditionOpt = [
  {
    title: "บาท",
    value: "price",
  },
  {
    title: "คะแนน PV",
    value: "pv",
  },
  {
    title: "คะแนน RP",
    value: "rp",
  },
];

const receiveOpt = [
  {
    title: "ส่วนลด บาท",
    value: "price",
  },
  {
    title: "ส่วนลด เปอร์เซ็นต์",
    value: "percent",
  },
  {
    title: "คะแนน PV",
    value: "pv",
  },
  {
    title: "คะแนน RP",
    value: "rp",
  },
  {
    title: "คะแนน CV",
    value: "cv",
  },
];

const ModalAmount = () => {
  const {
    state: {
      modal: {
        amount: { visible, active_by, data_key },
      },
    },
    dispatch,
  } = useContext(PromotionContext);
  const data_source = active_by === "condition" ? conditionOpt : receiveOpt;

  const onCancel = () => {
    dispatch({
      type: "SET_MODAL_VISIBLE",
      payload: { visible: false, name: "amount" },
    });
  };

  return (
    <Modal
      visible={visible}
      title="เลือกประเภทจำนวน"
      onCancel={onCancel}
      footer={false}
      width={800}
      zIndex={1080}
      destroyOnClose={true}
    >
      <Table
        size="small"
        dataSource={data_source}
        rowKey="value"
        pagination={false}
      >
        <Table.Column
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">ลำดับ</div>}
          width={70}
          align="right"
          render={(_text, _record, index) => (
            <Typography.Text>{index + 1}</Typography.Text>
          )}
        />
        <Table.Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ประเภทจำนวน</div>
          }
          dataIndex="title"
        />
        <Table.Column
          title={<FaCogs />}
          align="center"
          width={60}
          render={(item, _record, index) => (
            <Button
              id={`amount-${index}`}
              type="primary"
              ghost
              size="small"
              onClick={() =>
                dispatch({
                  type: "ADD_PROMOTION_DATA_ITEM",
                  payload: {
                    item_type: item.value,
                    title: item.title,
                    modal_name: "amount",
                  },
                })
              }
            >
              <FaPlus />
            </Button>
          )}
        />
      </Table>
    </Modal>
  );
};

export default ModalAmount;
