/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { FaCogs, FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEventMutate } from "../../../../../contexts/events.context";
import { displayTotal, getStringLiteral } from "../../../../../utils/helper";
import { eventRequest } from "../../../../../utils/useAxios";
import { axios, getUser } from "../../../../../utils";

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export default function TableTicket() {
  const {
    state: { search, fetch, loading, setStorage },
    dispatch,
  } = useEventMutate();

  const user = getUser();
  const loadDataCallback = useCallback(async () => {
    try {
      source.cancel("cancel token");
      source = CancelToken.source();
      dispatch({ type: "SET_LOADING", payload: true });
      const data = await eventRequest.get("/tickets-event", {
        params: {
          search: search.query,
          page: search.page,
          pageLimit: search.limit,
          eventId: search.event_id,
          status: search.status,
          honor_code: search.honor_code,
          team_name: search.team_name,
          serial: search.serial,
          member_code: search.member_code,
        },
        cancelToken: source.token,
      });
      dispatch({
        type: "SET_SEARCH_SUCCESS",
        payload: {
          data: data.data?.result?.items || [],
          total: data.data?.result?.pages?.total || 0,
          count: data.data?.result?.count || undefined,
        },
      });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      if (!axios.isCancel(error)) {
        message.error(error.message);
      }
    }
  }, [search, dispatch]);

  const handleCancelRegister = (id) => {
    Modal.confirm({
      type: "warning",
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการยกเลิกการลงทะเบียนหรือไม่?",
      okText: "ยืนยัน",
      okType: "danger",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          eventRequest
            .delete(`/register-event/${id}`)
            .then(() => resolve())
            .catch((err) =>
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              )
            );
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ยกเลิกการลงทะเบียนเรียบร้อย",
              okText: "ปิด",
              onOk() {
                loadDataCallback();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleDeleteTicket = (id) => {
    Modal.confirm({
      type: "warning",
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการลบบัตรงานหรือไม่?",
      okText: "ยืนยัน",
      okType: "danger",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          eventRequest
            .delete(`/tickets-event/${id}`)
            .then(() => resolve())
            .catch((err) =>
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              )
            );
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบบัตรงานเรียบร้อย",
              okText: "ปิด",
              onOk() {
                loadDataCallback();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleRemoveUsedAt = (data) => {
    let payload = {
      registerId: data.Id,
      updatedBy: user.user_code,
      updatedByName: user.name,
      usedType: "DELETE",
    };
    Modal.confirm({
      type: "warning",
      title: "ยืนยันการทำรายการ",
      content: `ลบวันที่เข้าร่วมงานของ ${data.FirstName} ${data.LastName} หรือไม่?`,
      okText: "ลบ",
      okType: "danger",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          eventRequest
            .patch(`histories-used-at`, payload)
            .then(() => resolve())
            .catch((err) =>
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              )
            );
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบวันที่เข้าร่วมงานเรียบร้อย",
              okText: "ปิด",
              onOk() {
                loadDataCallback();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleAddUsedAt = (data) => {
    let payload = {
      registerId: data.Id,
      updatedBy: user.user_code,
      updatedByName: user.name,
      usedType: "ADD",
    };
    Modal.confirm({
      type: "warning",
      title: "ยืนยันการทำรายการ",
      content: (
        <Typography style={{ fontSize: "16px" }}>
          ยืนยันวันที่เข้าร่วมงานของ <br />
          <b>
            {data.FirstName} {data.LastName}
          </b>{" "}
          หรือไม่?
        </Typography>
      ),
      okText: "ยืนยัน",
      okType: "success",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          eventRequest
            .patch(`histories-used-at`, payload)
            .then(() => resolve())
            .catch((err) =>
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              )
            );
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ยืนยันวันที่เข้าร่วมงานเรียบร้อย",
              okText: "ปิด",
              onOk() {
                loadDataCallback();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback, setStorage]);
  return (
    <Table
      size="small"
      bordered
      loading={loading}
      scroll={{
        x: "max-content",
      }}
      dataSource={fetch.data || []}
      rowKey="Id"
      pagination={{
        total: fetch.total || 0,
        current: search.page,
        showLessItems: true,
        size: "defaule",
        showTotal: displayTotal,
        onChange: (page) => dispatch({ type: "SET_SEARCH", payload: { page } }),
      }}
    >
      <Table.Column
        title="#"
        key="no"
        align="right"
        render={(text, record, index) =>
          (search.page - 1) * search.limit + index + 1
        }
      />
      <Table.Column
        title="เลขที่บิล"
        key="document_no"
        align="center"
        render={(text, { DocumentNo }) => (DocumentNo ? DocumentNo : "-")}
      />
      <Table.Column
        title="วันที่บิล"
        key="documented_at"
        align="center"
        render={(text, { DocumentedAt }) =>
          DocumentedAt ? moment(DocumentedAt).format("DD/MM/YYYY HH:mm") : "-"
        }
      />
      <Table.Column
        title="รหัสสินค้า"
        key="product_code"
        align="center"
        render={(text, { ProductCode }) => (ProductCode ? ProductCode : "-")}
      />
      <Table.Column
        title="รหัส-นักธุรกิจ (ผู้สั่งซื้อ)"
        key="ordered_by"
        align="center"
        render={(text, { OrderedBy }) => (OrderedBy ? OrderedBy : "-")}
      />
      <Table.Column
        title="ชื่อ-นักธุรกิจ (ผู้สั่งซื้อ)"
        key="ordered_by_name"
        align="center"
        render={(text, { OrderedByName }) =>
          OrderedByName ? OrderedByName : "-"
        }
      />
      <Table.Column
        title="ชื่องาน"
        key="event_name"
        render={(text, { Event }) => Event.EventName}
      />
      <Table.Column
        title="รหัสบัตรงาน"
        key="serial"
        dataIndex="Serial"
        align="center"
      />
      <Table.Column
        title="รหัสผู้ใช้บัตร"
        key="member_code"
        align="center"
        render={(text, { RegisterEvent }) => RegisterEvent?.MemberCode}
      />
      <Table.Column
        title="ชื่อผู้ใช้บัตร"
        key="member_name"
        render={(text, { RegisterEvent }) =>
          getStringLiteral(RegisterEvent?.FirstName, RegisterEvent?.LastName)
        }
      />
      <Table.Column
        title="บัตรประชาชนผู้ใช้บัตร"
        key="id_card"
        align="center"
        render={(text, { RegisterEvent }) => RegisterEvent?.PersonalNo}
      />
      <Table.Column
        title="เบอร์โทรศัพท์ผู้ใช้บัตร"
        key="mobile"
        align="center"
        render={(text, { RegisterEvent }) => RegisterEvent?.PhoneNo}
      />
      <Table.Column
        title="ตำแหน่ง"
        key="honor_code"
        align="center"
        render={(text, { RegisterEvent }) => RegisterEvent?.HonorCode}
      />
      <Table.Column
        title="องค์กร"
        key="team_name"
        align="center"
        render={(text, { RegisterEvent }) => RegisterEvent?.TeamName}
      />
      <Table.Column
        title="รหัสผู้แนะนำ"
        key="sponsor_code"
        align="center"
        render={(text, { RegisterEvent }) =>
          RegisterEvent?.RefName && RegisterEvent?.RefCode
        }
      />
      <Table.Column
        title="ชื่อผู้แนะนำ"
        key="sponsor_name"
        render={(text, { RegisterEvent }) => RegisterEvent?.RefName}
      />
      <Table.Column
        title="วันที่ลงทะเบียน"
        key="register_date"
        align="center"
        render={(text, { RegisterEvent, RegisterEventId }) =>
          RegisterEventId &&
          moment(RegisterEvent.CreatedAt).format("DD/MM/YYYY HH:mm")
        }
      />
      <Table.Column
        title="วันที่เข้าร่วมงาน"
        key="used_at"
        align="center"
        render={(text, { RegisterEvent }) =>
          RegisterEvent?.UsedAt ? (
            <Row gutter={8}>
              <Col span={19}>
                {moment(RegisterEvent.UsedAt).format("DD/MM/YYYY HH:mm")}
              </Col>
              <Col span={5}>
                <Tooltip title="ลบวันที่เข้าร่วมงาน">
                  <Button
                    size="small"
                    type="danger"
                    ghost
                    onClick={() => handleRemoveUsedAt(RegisterEvent)}
                  >
                    <FaTrash />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          ) : (
            RegisterEvent && (
              <Tooltip title="ยืนยันการเข้าร่วมงาน">
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => handleAddUsedAt(RegisterEvent)}
                >
                  <FaEdit />
                </Button>
              </Tooltip>
            )
          )
        }
      />
      <Table.Column
        title="สถานะ"
        key="status"
        dataIndex="status"
        align="center"
        render={(text, { UsedStatus }) =>
          UsedStatus ? (
            <Tag color="green">ลงทะเบียนแล้ว</Tag>
          ) : (
            <Tag color="red">ว่าง</Tag>
          )
        }
      />
      <Table.Column
        title="หมายเหตุ"
        key="remark"
        dataIndex="Remark"
        align="center"
      />
      <Table.Column
        title="ลิงก์"
        key="link"
        align="center"
        render={({ Serial, EventId }) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_EVENT_URL}/register?serial_no=${Serial}&event_id=${EventId}`}
          >
            Link
          </a>
        )}
      />
      <Table.Column
        title={<FaCogs />}
        key="action"
        align="center"
        render={(
          text,
          { Id, RegisterEventId, RegisterEvent, Serial, EventId }
        ) => {
          return !RegisterEvent?.UsedAt ? (
            <Row gutter={8}>
              <Col span={RegisterEventId ? 12 : 24}>
                <Tooltip title="แก้ไขการลงทะเบียน">
                  <Link
                    to={
                      RegisterEventId
                        ? `/events/edit-register?register_id=${RegisterEventId}`
                        : `/events/edit-register?event_id=${EventId}&serial=${Serial}`
                    }
                  >
                    <Button size="small" type="primary" ghost>
                      <FaEdit />
                    </Button>
                  </Link>
                </Tooltip>
              </Col>

              {RegisterEventId && (
                <Col span={12}>
                  <Tooltip
                    // title={RegisterEventId ? "ยกเลิกการลงทะเบียน" : "ลบบัตรงาน"}
                    title="ยกเลิกการลงทะเบียน"
                  >
                    <Button
                      size="small"
                      type="danger"
                      ghost
                      onClick={() => {
                        if (RegisterEventId) {
                          handleCancelRegister(RegisterEventId);
                        }
                        //  else {
                        //   handleDeleteTicket(Id);
                        // }
                      }}
                    >
                      <FaTrash />
                    </Button>
                  </Tooltip>
                </Col>
              )}
            </Row>
          ) : null;
        }}
      />
    </Table>
  );
}
