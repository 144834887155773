import React from "react";
import { Switch, Route } from "react-router-dom";
import Error404 from "../../Error404";

import FAQList from "./FAQList";
import FAQ from "./FAQ";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}`} exact component={FAQList} />
      <Route path={`${match.path}/:id`} exact component={FAQ} />
      <Route component={Error404} />
    </Switch>
  );
};
