import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import axios from "axios";
import React, { useContext } from "react";
import { getToken, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import PreOrderContext from "../../reducer";
import CancelByMember from "./CancelByMember";

const { Text } = Typography;

const SendPR = ({ form, onComplete }) => {
  const {
    state: {
      billModal: { reserve_order_no, status },
    },
    dispatch,
  } = useContext(PreOrderContext);
  const { getFieldDecorator } = form;
  const handleSubmit = () =>
    form.validateFieldsAndScroll((err, res) => {
      if (err) return;
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "คุณต้องการบันทึกรับเรื่องหรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          dispatch({ type: "SET_LOADING", payload: true });
          const { remark } = res;
          axios({
            method: "post",
            url: `${URL_API}/pre-order/flow/pr`,
            headers: { Authorization: "Bearer " + getToken() },
            data: { reserve_order_no, remark, document_status: status },
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: `บันทึกข้อมูลเรียบร้อย`,
              });
              onComplete();
            })
            .catch((e) => {
              resCatchModal(e);
            })
            .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
        },
      });
    });

  return (
    <>
      <Row className="bg-gray-60 py-1 px-2 mb-3">
        <Col span={24}>
          <Text strong className="text-white">
            ดำเนินการ
          </Text>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={22} offset={1}>
          <Form
            name="send-pr"
            labelAlign="left"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
          >
            <Form.Item className="d-flex mb-0" label="หมายเหตุ">
              {getFieldDecorator("remark")(
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  maxLength={500}
                />
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="text-center">
          <CancelByMember onComplete={onComplete} />
          <Button type="primary" className="width-150" onClick={handleSubmit}>
            รับเรื่อง
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Form.create()(SendPR);
