/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import noimg from "../../../../../../assets/img/pos/no-img.svg";
import { useHttp } from "../../../../../../hooks/http";
import { URL_API } from "../../../../../../utils";
const StyledBadge2 = styled(Select)`
  .ant-select-selection-selected-value {
    overflow: visible;
  }
  ,
  .ant-select {
  }
`;

const SelectBank = ({
  className = "",
  imgcode,
  withAll = false,
  withIcon = false,
  reloadselect,
  statusselect,
  imgsrc,
  form,
  props2,
  ...props
}) => {
  const [status, setStatus] = useState(1);
  const [code, setCode] = useState(imgcode);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/setting/master/bankselectadd`,
      // params: {
      //   status,
      // },
    },
    []
  );
  useEffect(() => {
    data && props.callfront(data.data);
  }, [data]);
  useEffect(() => {
    reload();
    setCode(imgcode);
  }, [imgcode]);
  console.log("imgcode", imgcode);
  if (error) return <div>{error}</div>;

  return (
    <StyledBadge2
      loading={loading}
      className={`w-100 ${className}`}
      defaultValue={form.file_name}
      dropdownStyle={{ backgroundColor: "#DAF7A6" }}
      dropdownMatchSelectWidth={false}
      {...props}
    >
      {withAll ? (
        <StyledBadge2.Option value={null}>ไม่มี</StyledBadge2.Option>
      ) : null}
      {data?.data.map((n, i) => (
        <StyledBadge2.Option
          key={i}
          value={n.file_name}
          source={n}
          className="d-flex align-items-center"
        >
          {withIcon && (
            <div
              style={{
                minWidth: "30px",
                width: "30px",
                height: "30px",
                marginRight: "6px",
                backgroundColor: `#${n.color ? n.color : "fff"}`,
                borderRadius: "50%",
              }}
            >
              <img
                className="w-100 h-100 object-fit-cover"
                src={
                  n.file_name
                    ? `${URL_API}/public/banks-logo/${n.file_name}.svg`
                    : noimg
                }
                alt={n.file_name}
              />
              <Typography.Text
                style={{
                  padding: "5px",
                  width: "30px",
                  marginRight: "8px",
                }}
              >
                {n.file_name === null ? "no-logo" : n.file_name}
              </Typography.Text>
            </div>
          )}
        </StyledBadge2.Option>
      ))}
    </StyledBadge2>
  );
};

export default SelectBank;
