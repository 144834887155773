/* eslint-disable no-unused-vars */
import { Button, Col, DatePicker, Input, Row, Table, Typography, notification } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaCogs, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useApi } from "../../../../hooks/http2";
import * as XLSX from "xlsx-js-style";
import {
  actionStoreQrBill,
  searchSelector,
} from "../../../../redux/slices/store-qr/bill";
import { getUser, URL_API } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import SelectBranch from "../components/SelectBranch";
import SelectTransport from "../components/SelectTransport";
import _ from "lodash";

const BillList = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const dispatch = useDispatch();

  const search = useSelector(searchSelector);

  const { SET_SEARCH } = actionStoreQrBill;


  const list = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/bill`,
      token: true,
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
        start_date: search.start_date
          ? new Date(moment(search.start_date).format("YYYY-MM-DD"))
          : null,
        end_date: search.end_date
          ? new Date(moment(search.end_date).format("YYYY-MM-DD"))
          : null,
      },
    },
    [search]
  );
  const list_branch = useApi(
    {
      baseURL: URL_API,
      url: `/boss/inventory/all-branch`
    }
  )

  const dataExcel = useApi(
    {
      baseURL: URL_API,
      url: `/store-qr/bill`,
      token: true,
      params: {
        ...search,
        limit : 0,
        offset : 0,
        start_date: search.start_date
          ? new Date(moment(search.start_date).format("YYYY-MM-DD"))
          : null,
        end_date: search.end_date
          ? new Date(moment(search.end_date).format("YYYY-MM-DD"))
          : null,
      },
    },
    [search]
  );

  useEffect(() => {
    let user = getUser();
    dispatch(SET_SEARCH({ branch: "", page: 1 }));
  }, [dispatch, SET_SEARCH]);



  const exportToExcel = () => {

    const exportData = dataExcel?.fetch.data
    const select_branch = list_branch.fetch?.data.all_branch.find(e => e.code === search.branch)

    if (dataExcel?.fetch.data.length > 0) {
      let excelData = [
        ["รายงานบิลขาย","","","",`วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY HH:MM")}`,],
        ["แสดงเฉพาะรายการค้นหา","", `${search.query}`],
        [
          `สาขาที่เปิดบิล: ${search.branch === "" ? "ทั้งหมด" : select_branch.name
          }`,
          "",
          `การรับสินค้า: ${search.req_receipt_type === "" ? "ทั้งหมด" : search.req_receipt_type.toLowerCase() === "branch" ? "รับที่สาขา" : "จัดส่ง"
          }`,
          "",
          `ช่วงวันที่  ${search.start_date ? moment(search.start_date).format("DD/MM/YYYY"): "-"} 
            ถึง ${search.end_date? moment(search.end_date).format("DD/MM/YYYY"):"-"}`,
          ""
        ],
        [],
        [
          { v: "ลำดับ", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "เลขที่ใบเสร็จ" , t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "วันที่เปิดบิล", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "รหัสผู้สั่งซื้อ", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "ชื่อผู้สั่งซื้อ", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "รหัสผู้เดำเนินการ", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "ชื่อผู้ดำเนินการ", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "สาขาที่เปิดบิล", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "การรับสินค้า", t: "s", s: { alignment: { horizontal: "center" } } },
        ],
      ];

      exportData.forEach((n) => {
        excelData = [...excelData, [
          { v: n.row_id, s: { alignment: { horizontal: "center" } } },
          { v:  n.document_no, s: { alignment: { horizontal: "center" } } },
          { v:  moment(n.document_date,).format("DD/MM/YYYY HH:MM"), s: { alignment: { horizontal: "center" } } },
          { v:  n.buyer_code, s: { alignment: { horizontal: "center" } } },
          n.buyer_name,
          { v: n.partner_code, s: { alignment: { horizontal: "center" } } },
          n.partner_name,
          { v: n.bill_location, s: { alignment: { horizontal: "center" } } },
          { v: n.req_receipt_type.toLowerCase() === "branch" ? "รับที่สาขา" : "จัดส่ง", s: { alignment: { horizontal: "center" } } }
        ]]
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
        { s: { r: 0, c: 4 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 4 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 3 } },
        { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
        
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานบิลขาย");
      XLSX.writeFile(wb, "รายงานบิลขาย.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <Panel>
      <PanelHeader>รายการบิลขาย</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-3">
          <Col md={9}>
            <Input.Search
              placeholder="เลขที่ใบเสร็จ , รหัสนักธุรกิจ , ชื่อนักธุรกิจ , QR Code หลัก , QR Code ย่อย"
              onSearch={(value) =>
                dispatch(SET_SEARCH({ query: value, page: 1 }))
              }
              allowClear
            />
          </Col>
          <Col md={5}>
            <DatePicker
              className="w-100"
              placeholder="วันที่ออกใบเสร็จเริ่มต้น"
              format="DD/MM/YYYY"
              value={search.start_date}
              onChange={(value) => dispatch(SET_SEARCH({ start_date: value, page : 1 }))}
            />
          </Col>
          <Col md={5}>
            <DatePicker
              className="w-100"
              placeholder="วันที่ออกใบเสร็จสิ้นสุด"
              format="DD/MM/YYYY"
              value={search.end_date}
              onChange={(value) => dispatch(SET_SEARCH({ end_date: value, page : 1 }))}
            />
          </Col>
          <Col md={3} offset={2}>
            <Button
              type="primary"
              className="text-white d-flex align-items-center"
              icon="file-excel"
              onClick={exportToExcel}
            >
              Export Excel
            </Button>
          </Col>
        </Row>
        <Row gutter={16} className="mb-3 d-flex">
          <Col md={2} className="align-self-center">
            <Typography.Text strong>สาขาที่เปิดบิล</Typography.Text>
          </Col>
          <Col md={5}>
            <SelectBranch
              className="w-100"
              withAll
              allText="สาขาทั้งหมด"
              placeholder="สาขาเปิดบิล"
              value={search.branch}
              onChange={(value) =>
                dispatch(SET_SEARCH({ branch: value, page: 1 }))
              }
            />
          </Col>
          <Col md={2} className="align-self-center">
            <Typography.Text strong>การรับสินค้า</Typography.Text>
          </Col>
          <Col md={5}>
            <SelectTransport
              className="w-100"
              withAll
              allText="ทั้งหมด"
              placeholder="การรับสินค้า"
              value={search.req_receipt_type}
              onChange={(value) =>
                dispatch(SET_SEARCH({ req_receipt_type: value, page: 1 }))
              }
            />
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          loading={list.loading}
          dataSource={list.fetch?.data || []}
          rowKey="row_id"
          pagination={{
            size: "default",
            current: search.page,
            pageSize: search.limit,
            total: list.fetch?.total || 0,
            showLessItems: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
            onChange: (p) => dispatch(SET_SEARCH({ page: p })),
          }}
        >
          <Table.Column title="No." align="right" dataIndex="row_id" />
          <Table.Column
            title="เลขที่ใบเสร็จ"
            align="center"
            dataIndex="document_no"
          />
          <Table.Column
            title="วันที่เปิดบิล"
            align="center"
            dataIndex="document_date"
            render={(text) => moment(text).format("DD/MM/YYYY")}
          />
          <Table.Column
            title="รหัสผู้สั่งซื้อ"
            align="center"
            dataIndex="partner_code"
          />
          <Table.Column title="ชื่อผู้สั่งซื้อ" dataIndex="partner_name" />
          <Table.Column
            title="รหัสผู้ดำเนินการ"
            align="center"
            dataIndex="buyer_code"
          />
          <Table.Column title="ชื่อผู้ดำเนินการ" dataIndex="buyer_name" />
          <Table.Column
            title="สาขาเปิดบิล"
            align="center"
            dataIndex="bill_location"
          />
          <Table.Column
            title="การรับสินค้า"
            align="center"
            dataIndex="req_receipt_type"
            render={(text) =>
              text?.toLowerCase() === "branch" ? "รับที่สาขา" : "จัดส่ง"
            }
          />
          <Table.Column
            title={<FaCogs />}
            align="center"
            dataIndex="id"
            render={(text) => (
              <Button
                type="primary"
                size="small"
                shape="circle-outline"
                onClick={() => history.push(`${match.path}/${text}`)}
              >
                <FaEye />
              </Button>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default BillList;
