import { Alert, Select } from "antd";
import React, { memo } from "react";
import { useHttp } from "../hooks/http";
import { URL_API, _ } from "../utils";

const { Option } = Select;

const SelectMasterType = ({ className, withAll, processType, ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/type`,
      params: { process_type: processType },
      token: true,
    },
    [processType]
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select
      loading={loading}
      className={`w-100 ${className || ""}`}
      dropdownStyle={{ zIndex: 1080 }}
      {...props}
    >
      {withAll && <Option value="">ทั้งหมด</Option>}
      {_.map(data?.data, (n) => (
        <Option key={n.code} value={n.code}>
          {n.name}
        </Option>
      ))}
    </Select>
  );
};

export default memo(SelectMasterType);
