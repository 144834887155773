import {createReducer} from '@reduxjs/toolkit';
import {createContext} from 'react';

export const initState = {
    reserveModal: {
        showReserve: false
    },
    cancelModel: {
        showCancel: false
    }
};
export const reducer = createReducer(initState,{
    setReserveModal: (state,action) => {state.reserveModal = {...state.reserveModal, ...action.payload}},
    setCancelModal: (state, action) => {state.cancelModel = {...state.cancelModel, ...action.payload}}
});

export const ModalContext = createContext();
export default ModalContext;