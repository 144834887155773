import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import MarketingProductList from "./product/MarketingProductList";
import MarkettingProductContent from "./product/MarkettingProductContent";

import Error404 from "../Error404";
import RAPromotionList from "./ra-promotion/RAPromotionList";

export default ({ match }) => {
  return (
    <Switch>
      <Redirect path={match.path} exact to={`${match.path}/product`} />
      <Route
        path={`${match.path}/product`}
        exact
        children={<MarketingProductList />}
      />
      <Route
        path={`${match.path}/product/:id`}
        exact
        children={<MarkettingProductContent />}
      />
        <Route
        path={`${match.path}/ra-promotion`}
        exact
        children={<RAPromotionList />}
      />
      <Route component={Error404} />
    </Switch>
  );
};
