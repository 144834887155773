/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Col, notification, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../../../hooks/http";
import { URL_API } from "../../../../../../utils";

const PromotionDistribution = ({ data, handleChange }) => {
  const [checkedList, setCheckedList] = useState([]);
  const [plainOptions, setPlainOptions] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isLGShop, setIsLGShop] = useState(false);

  const [loadingMasterBranch, masterBranch, errorMasterBranch] = useHttp(
    {
      method: "GET",
      url: `${URL_API}/system/master/branch`,
      token: true,
    },
    []
  );

  if (errorMasterBranch) {
    notification.error({
      message: "error",
      description: errorMasterBranch,
    });
  }

  useEffect(() => {
    let lg = [];
    if (!loadingMasterBranch) {
      masterBranch &&
        masterBranch.data.forEach((item) => {
          const { wh_code } = item;
          if (wh_code) {
            lg.push({ label: item.name, value: item.code });
          }
        });
      setPlainOptions(lg);
    }
  }, [masterBranch]);

  useEffect(() => {
    if (data.distribution.length > 0) {
      let length = data.distribution.length;
      setIsLGShop(true);
      setIndeterminate(length > 0 && length < plainOptions.length);
      setCheckAll(length === plainOptions.length);
      setCheckedList(data.distribution);
    }
  }, [data, plainOptions]);

  const onChange = (e) => {
    setCheckedList(e);
    setIndeterminate(e.length > 0 && e.length < plainOptions.length);
    setCheckAll(e.length === plainOptions.length);

    handleChange({
      target: {
        name: "distribution",
        value: e,
      },
    });
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions.map((n) => n.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    handleChange({
      target: {
        name: "distribution",
        value: e.target.checked ? plainOptions.map((n) => n.value) : [],
      },
    });
  };

  return (
    <>
      <Row className="mb-2">
        <Col md={15}>
          <label>Legacy Shop</label>
        </Col>
        <Col md={9} style={{ textAlign: "end" }}>
          <Switch
            onChange={() => setIsLGShop((check) => !check)}
            checked={isLGShop}
          />
        </Col>
      </Row>

      <Row hidden={isLGShop === false} className="pl-5">
        <Col style={{ fontSize: "12px" }}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            ทุกสาขา
          </Checkbox>
          <Checkbox.Group
            style={{ display: "grid" }}
            options={plainOptions}
            value={checkedList}
            onChange={onChange}
          />
        </Col>
      </Row>
    </>
  );
};
export default PromotionDistribution;
