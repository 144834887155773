/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import Tabs from "../components/Tabs";
import { Creators } from "../ducks/operation-form";
import Bill from "./Bill";
import Summary from "./Summary";

const Index = (props) => {
  useEffect(() => {
    props.setBillActive(props.activeKey);
  }, [props.activeKey]);
  return (
    <div className="pos-payment">
      <div className="pos-payment-inner">
        <Tabs
          data={props.bills}
          active={props.activeKey}
          onClick={(e) => {
            props.setPaymentActive(e);
            props.setBillActive(e);
          }}
        />
        <div className="pos-payment-detail">
          <Bill />
          <Summary />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { orders, payment } = state.pos.operation;
  const activeKey = payment.activeKey || orders.itemKeys[0];
  return {
    bills: orders.itemKeys.map((n) => ({
      ...orders.itemByKey[n],
      key: n,
      title: n,
    })),
    activeKey,
  };
};

export default connect(mapStateToProps, {
  setPaymentActive: Creators.setPaymentActive,
  setBillActive: Creators.setBillActive,
})(Index);
