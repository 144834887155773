import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const fitPrizesServices = {
  useQueryGetLegacyPrizesLists({ take, page }, onSuccess) {
    return useQuery(
      ['get-legacy-prizes', { take, page }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/gift-event`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyPrizesById({ giftEventId }, onSuccess) {
    return useQuery(
      ['get-legacy-prizes-by-id', { giftEventId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/gift-event/${queryKey[1].giftEventId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!giftEventId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyPrizes(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(`/gift-event`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPrizes(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/gift-event/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPrizesActive(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/gift-event/${data.id}/active`,
            {
              active: data.active,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyPrizes(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(`/gift-event/${data.id}`);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyPrizesItemLists({ take, page, giftEventId }, onSuccess) {
    return useQuery(
      ['get-legacy-prizes-item', { take, page, giftEventId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/gift-event-item`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              giftEventId: queryKey[1].giftEventId,
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyPrizesItemById({ giftEventItemId }, onSuccess) {
    return useQuery(
      ['get-legacy-prizes-item-by-id', { giftEventItemId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/gift-event-item/${queryKey[1].giftEventItemId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!giftEventItemId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyPrizesItem(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(`/gift-event-item`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPrizesItem(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/gift-event-item/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPrizesItemActive(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/gift-event-item/${data.id}/active`,
            {
              active: data.active,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyPrizesItem(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/gift-event-item/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLegacyPrizesItemPartnerLists(
    { take, page, giftEventItemId, searchText },
    onSuccess
  ) {
    return useQuery(
      [
        'get-legacy-prizes-item-partner-list',
        { take, page, giftEventItemId, searchText },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/gift-event-item-partner-list`,
            {
              params: {
                take: queryKey[1].take,
                page: queryKey[1].page,
                giftEventItemId: queryKey[1].giftEventItemId,
                ...(searchText && { searchText: queryKey[1].searchText }),
              },
            }
          );

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!giftEventItemId,
      }
    );
  },
  useQueryGetLegacyPrizesItemPartnerListById(
    { giftEventItemPartnerListId },
    onSuccess
  ) {
    return useQuery(
      [
        'get-legacy-prizes-item-partner-list-by-id',
        { giftEventItemPartnerListId },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/gift-event-item-partner-list/${queryKey[1].giftEventItemPartnerListId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!giftEventItemPartnerListId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationCreateLegacyPrizesItemPartnerList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/gift-event-item-partner-list`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUpdateLegacyPrizesItemPartnerListDisplay(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/gift-event-item-partner-list/${data.id}/display`,
            {
              display: data.display,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationDeleteLegacyPrizesItemPartnerList(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(
            `/gift-event-item-partner-list/${data.id}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUploadLegacyPrizesItemPartnerListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async ({ data, giftEventItemId }) => {
        try {
          const res = await fitStudioRequest.post(
            `/gift-event-item-partner-list/upload-excel/${giftEventItemId}`,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
};

export default fitPrizesServices;
