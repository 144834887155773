import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { FaExchangeAlt } from "react-icons/fa";
import SelectPeriod from "../../../../components/SelectPeriod";
import { _ } from "../../../../utils";
import SelectOption from "../../pos/components/SelectOption";

const resultOption = [
  {
    text: "Success",
    value: "success",
  },
  {
    text: "Error",
    value: "error",
  },
];

const disabledTransfer = [
  "transfer-in",
  "credit-memo",
  "cash-refund",
  "inventory-adjustment",
];

const HeaderList = ({
  result = null,
  placeholder = "ค้าหา",
  processType = "",
  handleChange = () => {},
  handleTransfer = () => {},
}) => {
  const [period, setPeriod] = useState(null);

  const handleClickTransfer = () => {
    if (processType === "commission") {
      handleTransfer(processType, { period });
    } else {
      handleTransfer(processType);
    }
  };
  return (
    <Row gutter={8} type="flex" className="mb-3 align-items-center">
      <Col md={14} className="d-flex flex-flex-row">
        {processType === "commission" && (
          <SelectPeriod
            placeholder="เลือก Period"
            value={period ?? undefined}
            callbackData={(e) => setPeriod(e[0]?.period || null)}
            onSelect={(e) => setPeriod(e)}
          />
        )}
        <Input.Search
          className="mx-2"
          name="search"
          placeholder={placeholder}
          allowClear={true}
          onSearch={(value) =>
            handleChange({ target: { name: "search", value } })
          }
        />
        <SelectOption
          className="mr-2 width-500"
          options={resultOption}
          defaultValue={result}
          onChange={(value) =>
            handleChange({ target: { name: "result", value } })
          }
        />
      </Col>
      {!_.includes(disabledTransfer, processType) && (
        <Col md={10} className="d-flex justify-content-end">
          <Button
            className="bg-pv text-white d-flex align-items-center"
            onClick={handleClickTransfer}
          >
            <FaExchangeAlt className="mr-2" /> Transfer Data
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default React.memo(HeaderList);
