import { Button, Col, Input, Modal, Row, typography, InputNumber, notification } from "antd";
import React, { useContext } from "react";
import TravelPointContext from "../contexts/travel-point-context";
import { URL_API, axios, getToken } from "../../../../utils";

const { Text, Title } = typography;

const TravelPointRedeem = ({reload}) => {
  const { state, dispatch } = useContext(TravelPointContext);
  const redeem_tp = () => {
    console.log(state.redeem);
    if (state.redeem.partner_code) {
      Modal.confirm({
        title: "ยืนยัน",
        content: `คุณต้องการแลกคะแนนของสมาชิก รหัส ${state.redeem.partner_code} `,
        okText: "ยืนยัน",
        cancelText: "ปิด",
        onOk (){
            axios({
                method: "post",
                baseURL: URL_API,
                url: `tp/travel-point/redeem-point`,
                headers: { Authorization: "Bearer " + getToken() },
                data: state.redeem,
              }).then((res)=>{
                if(res.status === 202){
                    Modal.error({
                        title: "ผิดพลาด",
                        content: res.data.message,
                        okText: "ปิด",
                      });
                }else{
                    notification.success({
                        message: "สำเร็จ",
                        description: "แลกคะแนนเรียบร้อย",
                    });
                    reload() 
                }
              })
        }
      });
      
    } else {
      Modal.error({
        title: "ผิดพลาด",
        content: "ไม่พบรหัสสมาชิกที่ต้องการแลก",
        okText: "ปิด",
      });
    }
  };
  return (
    <>
      <Row className=" pl-3 pt-1">
        <Col>
          <Title level={4}>แลกคะแนน</Title>
        </Col>
      </Row>

      <Row type="flex" align="middle" className="pl-3">
        <Col span={4} type="flex" align="end" className="pr-3">
          <Text strong>จำนวน :</Text>
        </Col>
        <Col span={7}>
          <InputNumber
            style={{
                width : '100%'
              }}
            allowClear
            placeholder="จำนวนคะแนน"
            value={state.redeem?.tp}
            onChange={(e) => {
              dispatch({
                type: "setRedeem",
                payload: { tp: e },
              });
            }}
          />
        </Col>
        <Col span={4} type="flex" align="end" className="pr-3">
          <Text strong>Description :</Text>
        </Col>
        <Col span={8}>
          <Input
            allowClear
            placeholder="ระบุรายละเอียดเพิ่มเติม"
            value={state.redeem?.remark}
            onChange={(e) => {
              dispatch({
                type: "setRedeem",
                payload: { remark: e.target.value },
              });
            }}
          />
        </Col>
      </Row>

      <Row
        type="flex"
        // justify="end"
        align="middle"
        className="pl-3 pt-3 pb-3"
      >
        <Col span={4} type="flex" align="end" className="pr-3">
          <Text strong>Packages :</Text>
        </Col>
        <Col span={7}>
          <Input
            allowClear
            placeholder="ระบุชื่อทริป"
            value={state.redeem?.trip_package}
            onChange={(e) => {
              dispatch({
                type: "setRedeem",
                payload: { trip_package: e.target.value },
              });
            }}
          />
        </Col>
        <Col span={12} type="flex" align="end">
          <Button
            type="primary"
            onClick={() => {
              //   setPage(1);
              redeem_tp();
            }}
          >
            ยืนยันแลก
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default TravelPointRedeem;
