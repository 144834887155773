/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,} from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography, } from 'antdV4';
import { Row, Col } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { playlistFormDefaultValues } from '../dto/public-playlist';
import publicPlaylistConstants from '../../constants/publicPlaylistConstants';
import VideoUpload from '../upload/VideoUpload';
import ImageUpload from '../upload/ImageUpload';

const PublicPlaylistForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: 'videoes',
    control,
  });



  const { execute: getPublicPlaylistById } = useAsync(
    fitStudioService.getPublicPlaylistById,
    {
      onSuccess: (data) => {
        reset({
          ...data,
          formType: 'edit',
        });
      },
    }
  );

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getPublicPlaylistById(watch('id'));
    } else {
      reset(playlistFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Typography.Text>
        ภาพ Thumbnail <span style={{ color: 'red' }}>*</span>
      </Typography.Text>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={publicPlaylistConstants.thumbnail.width}
                  height={publicPlaylistConstants.thumbnail.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Typography.Text>
        ภาพ Cover <span style={{ color: 'red' }}>*</span>
      </Typography.Text>
      <Controller
        name="coverImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={publicPlaylistConstants.cover.width}
                  height={publicPlaylistConstants.cover.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  หัวข้อ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำบรรยาย <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <div
        className="mb-2"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Text>
          วิดีโอ <span style={{ color: 'red' }}>*</span>
        </Typography.Text>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            append({
              id: '',
              title: '',
              description: '',
              imageUrl: '',
              imageDescription: '',
              imagePosition: '',
            });
          }}
        >
          <FaPlus />
        </div>
      </div>
      {fields.map((field, index) => {
        return (
          <div
            key={index}
            style={{
              padding: 10,
              border: '1px solid #b6c2c9',
              borderRadius: 5,
            }}
            className="mb-2"
          >
            <div
              className="mb-2"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography.Text>วิดีโอ {index + 1}</Typography.Text>
              {index > 0 && (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <MdDelete />
                </div>
              )}
            </div>
            <Controller
              name={`videoes.${index}.title`}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Row className="align-items-center  mb-2">
                    <Col span={4}>
                      <Typography.Text>
                        ชื่อวิดีโอ <span style={{ color: 'red' }}>*</span>
                      </Typography.Text>
                    </Col>
                    <Col span={20}>
                      <Input value={value} onChange={onChange} />
                    </Col>
                  </Row>
                );
              }}
            />
            <Controller
              name={`videoes.${index}.description`}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Row className="align-items-center  mb-2">
                    <Col span={4}>
                      <Typography.Text>คำบรรยาย</Typography.Text>
                    </Col>
                    <Col span={20}>
                      <Input.TextArea value={value} onChange={onChange} />
                    </Col>
                  </Row>
                );
              }}
            />
            <Row className="align-items-center mb-2" gutter={10}>
              <Col span={24}>
                <Controller
                  name={`videoes.${index}.thumbnailImageUrl`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ImageUpload
                        value={value}
                        onChange={onChange}
                        width={publicPlaylistConstants.thumbnail.width / 3}
                        height={publicPlaylistConstants.thumbnail.height / 3}
                        errors={errors}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <Controller
                  name={`videoes.${index}.videoUrl`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return <VideoUpload value={value} onChange={onChange} />;
                  }}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default PublicPlaylistForm;
