/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button } from 'antdV4';
import { FaPen, FaPlus } from 'react-icons/fa';
import { Table } from 'antd';
import useAsync from '../../../../utils/useAsync';
import fitStudioService from '../../../../services/fit-studio.service';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertConfirm } from '../../../util/Alert';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { MdDelete } from 'react-icons/md';
import { FiArrowLeft } from 'react-icons/fi';
import BodyFocusSubTagModal from '../components/modal/BodyFocusSubTagModal';
import {
  subTagFormDefaultValues,
  subTagFormSchema,
} from '../components/dto/myfocus';

const BodyFocusSubTagPage = () => {
  const history = useHistory();
  const params = useParams();
  const [subTagFormOpen, setSubTagFormOpen] = useState(false);
  const { execute: getBodyFocusSubTagByTagId, data: subTagLists } = useAsync(
    fitStudioService.getBodyFocusSubTagByTagId,
    {
      immediate: true,
      args: {
        tagId: params.tagId,
      },
    }
  );

  const { data: tagData } = useAsync(fitStudioService.getBodyFocusTagById, {
    immediate: true,
    args: {
      tagId: params.tagId,
    },
  });

  const subTagForm = useForm({
    defaultValues: subTagFormDefaultValues,
    resolver: subTagFormSchema(),
  });

  const _HandleSubTagFormOpen = (formType) => {
    subTagForm.setValue('formType', formType);
    setSubTagFormOpen(true);
  };

  const _HandleSubTagFormClose = () => {
    setSubTagFormOpen(false);
  };

  const { data: test, execute: deleteBodyFocusSubTagByTagId } = useAsync(
    fitStudioService.deleteBodyFocusSubTagByTagId,
    {}
  );

  useEffect(() => {
    if (!subTagFormOpen && params.tagId) {
      getBodyFocusSubTagByTagId({
        tagId: params.tagId,
      });
    }
  }, [subTagFormOpen, params, test]);

  const columns = [
    {
      title: 'รูปภาพชาย',
      dataIndex: 'thumbnailImageUrlM',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrlM}
          src={record.thumbnailImageUrlM}
          alt={record.thumbnailImageUrlM}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'รูปภาพหญิง',
      dataIndex: 'thumbnailImageUrlF',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrlF}
          src={record.thumbnailImageUrlF}
          alt={record.thumbnailImageUrlF}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'หัวข้อ',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              subTagForm.setValue('id', record.id);
              _HandleSubTagFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบ Sub tag');
              if (confirm)
                deleteBodyFocusSubTagByTagId({ subTagId: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{tagData?.title}</h3>
      </div>
      <Panel>
        <PanelHeader>Body Focus Sub Tag</PanelHeader>
        <PanelBody>
          <div className="d-flex justify-content-end mb-2">
            <Button
              className="bg-pv border-pv text-white"
              onClick={() => {
                _HandleSubTagFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่ม Sub Tag
            </Button>
          </div>
          <Table
            scroll={{ x: true }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(`${params.tagId}/sub-tag/${record.id}`);
                }, // click row
              };
            }}
            columns={columns}
            dataSource={subTagLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={false}
          />
        </PanelBody>
        <FormProvider {...subTagForm}>
          <BodyFocusSubTagModal
            open={subTagFormOpen}
            handleClose={_HandleSubTagFormClose}
          />
        </FormProvider>
      </Panel>
    </>
  );
};

export default BodyFocusSubTagPage;
