/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaEye } from "react-icons/fa";
import { IoIosGift } from "react-icons/io";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import img from "../../../../assets/img/pos/user-default.png";
import UploadFile from "../../../../components/UploadFile";
import styles from "../../../../scss/module/pos.module.scss";
import { axios, moment, URL_API } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { isBirthDate } from "../../../util/helper/map-data";
import MemberProfilePopup from "../components/MemberProfilePopup";
import MobileConfirm from "../components/MobileConfirm";
import SelectSearch from "../components/SelectSearch";
import WarningExpireDate from "../components/WarningExpireDate";
import { Creators } from "../ducks/operation-form";
import ProfileCD from "../operator/ProfileCd";
import ProfileRalc from "../operator/ProfileRalc";

const PageIndex = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const btnSubmitRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [buyer, setBuyer] = useState({});
  const [loadList, setLoadList] = useState([]);
  const [errImg, setErrImg] = useState(null);
  const [source, setSource] = useState(axios.CancelToken.source());
  const [status, setStatus] = useState(null);
  const [textStatus, setTextStatus] = useState("");
  const [permission, setPermission] = useState(true);
  const [type, setType] = useState("Member");
  const [warnBirthDate, setWarnBirthDate] = useState(false);
  const [warnExpireDate, setWarnExpireDate] = useState(null);
  const [mobileToVerify, setMobileToVerify] = useState(null);
  const [ralc, setRalc] = useState(null);
  const [registerRenewEndDate, setRegisterRenewEndDate] = useState(null);
  const type_params = match.params.type;

  useEffect(() => {
    document.getElementById("selectId").click();
  }, []);

  useEffect(() => {
    return () => source.cancel("unmounted");
  }, []);

  useEffect(() => {
    if (buyer.member_id === undefined) setTextStatus("");
  }, [buyer]);

  const handleClickSearch = async () => {
    setLoading(true);
    setMobileToVerify(null);
    setBuyer((prevBuyer) => {
      return {
        partner_document: prevBuyer.partner_document,
        partner_remark: prevBuyer.partner_remark,
      };
    });

    axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        type,
        query: query?.trim(),
        process: "partner",
      },
      cancelToken: source.token,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            let data = res.data.data[0];
            const is_staff = data.member_type?.toLowerCase() === "staff";
            const status = data.status?.toLowerCase();
            setMobileToVerify(data.mobile_to_verify);
            setBuyer((prevBuyer) => {
              return {
                ...prevBuyer,
                member_id: data.member_id,
                partner_code: data.code,
                partner_name: `${data.first_name_th} ${data.sur_name_th}`,
                partner_mobile: data.mobile,
                partner_type: data.member_type_name,
                partner_type_code: data.member_type,
                team_name: data.team_name,
                allow_renew: data.allow_renew === 1,
                allow_item:
                  is_staff || ["active", "pending", "waiting"].includes(status),
                is_married: data.is_married,
                is_freeze: data.is_freeze,
              };
            });
            if (type === "Member") {
              setStatus(status);
              setTextStatus(data.text_status);
              let day_leave = null;
              if (data.expire_date) {
                const exp_date = moment(data.expire_date);
                const current_date = moment().startOf("day");
                day_leave = exp_date.diff(current_date, "days");
              }
              setRegisterRenewEndDate(data.register_renew_end_date);
              setWarnExpireDate(day_leave);
              setWarnBirthDate(isBirthDate(data.birth_date));
              if (
                !["active", "pending", "waiting", "expired"].includes(status)
              ) {
                setPermission(false);
                Modal.warning({
                  title: "ไม่สามารถดำเนินการได้",
                  content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                  okText: "ตกลง",
                });
              } else {
                setPermission(true);
              }
            } else {
              setStatus(null);
              setTextStatus("");
              setWarnExpireDate(null);
              setWarnBirthDate(false);
              setRegisterRenewEndDate(null);
              btnSubmitRef.current.focus();
            }
          } else {
            setLoadList(res.data.data);
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "bottomRight",
          });
        }
      })
      .catch((err) => {
        err.message !== "cancel search" && resCatch(err);
        setLoading(false);
      });
  };

  const handleClickSubmit = async () => {
    const { partner_code, partner_document, partner_remark } = buyer;
    if (partner_code) {
      if (!permission) {
        Modal.error({
          title: "ไม่สามารถดำเนินการได้",
          content: `รหัสนักธุรกิจ ${partner_code} มีสถานะ ${textStatus}`,
          okText: "ตกลง",
        });
      } else if (
        props.buyer_code !== buyer.partner_code &&
        !partner_document &&
        !partner_remark &&
        type !== "Staff"
      ) {
        Modal.warning({
          title: "ไม่สามารถดำเนินการได้",
          content: `กรุณาอัพโหลดเอกสารใบมอบอำนาจหรือกรอกหมายเหตุ`,
          okText: "ตกลง",
        });
      } else {
        // only phase one don't verify, but verify in case redeem only
        // if (mobileToVerify)
        //   return Modal.warning({
        //     title: "ไม่สามารถดำเนินการได้",
        //     content: "กรุณายืนยันเบอร์โทรศัพท์",
        //     okText: "ตกลง",
        //     zIndex: 1080,
        //   });
        if (match.params.type === "redeem" && mobileToVerify)
          return Modal.warning({
            title: "ไม่สามารถดำเนินการได้",
            content: "กรุณายืนยันเบอร์โทรศัพท์",
            okText: "ตกลง",
            zIndex: 1080,
          });
        props.addBill({ ...buyer, ralc });
        history.replace(`/pos/${type_params}/order`);
      }
    } else {
      selectSearchOnClick();
    }
  };

  const selectSearchOnClick = () => {
    query && handleClickSearch();
  };

  const selectSearchCancelOnClick = () => {
    source.cancel("cancel search");
    setSource(axios.CancelToken.source());
  };

  const selectSearchOnChange = () => {
    btnSubmitRef.current.focus();
  };

  const selectSearchOnInputKeyDown = (e) => {
    let key = e.key;
    if (key === "Enter" && loadList.length === 0 && query) {
      handleClickSearch();
    } else if (key === "Backspace" || key === "Delete") {
      setQuery("");
      setMobileToVerify(null);
      setBuyer((prevBuyer) => {
        return {
          partner_document: prevBuyer.partner_document,
          partner_remark: prevBuyer.partner_remark,
        };
      });
      setLoadList([]);
    }
  };

  const selectSearchOnSearch = (e) => {
    if (e) {
      setQuery(e.trim());
      setMobileToVerify(null);
      setBuyer((prevBuyer) => {
        return {
          partner_document: prevBuyer.partner_document,
          partner_remark: prevBuyer.partner_remark,
        };
      });
      setLoadList([]);
    }
  };

  const selectSearchOnSelect = (i) => {
    const n = { ...loadList[+i] };
    const status = n.status?.toLowerCase();
    const is_staff = n.member_type?.toLowerCase() === "staff";
    setMobileToVerify(n.mobile_to_verify);
    setBuyer((prevBuyer) => {
      return {
        ...prevBuyer,
        member_id: n.member_id,
        partner_code: n.code,
        partner_name: `${n.first_name_th} ${n.sur_name_th}`,
        partner_mobile: n.mobile,
        partner_type: n.member_type_name,
        partner_type_code: n.member_type,
        team_name: n.team_name,
        allow_renew: n.allow_renew === 1,
        allow_item:
          is_staff || ["active", "pending", "waiting"].includes(status),
        is_married: n.is_married,
        is_freeze: n.is_freeze,
      };
    });
    if (type === "Member") {
      setStatus(n.status);
      setTextStatus(n.text_status);
      let day_leave = null;
      if (n.expire_date) {
        const exp_date = moment(n.expire_date);
        const current_date = moment().startOf("day");
        day_leave = exp_date.diff(current_date, "days");
      }
      setWarnExpireDate(day_leave);
      setWarnBirthDate(isBirthDate(n.birth_date));
      setRegisterRenewEndDate(n.register_renew_end_date);
      if (!["active", "pending", "waiting", "expired"].includes(status)) {
        setPermission(false);
        Modal.warning({
          title: "ไม่สามารถดำเนินการได้",
          content: `รหัสนักธุรกิจ ${n.code} มีสถานะ ${n.text_status}`,
          okText: "ตกลง",
        });
      } else {
        setPermission(true);
      }
    } else {
      setStatus(null);
      setTextStatus("");
      setWarnExpireDate(null);
      setWarnBirthDate(false);
      setRegisterRenewEndDate(null);
      btnSubmitRef.current.focus();
    }
  };

  const typeName = useMemo(
    () =>
      type_params === "sale"
        ? "ซื้อ"
        : type_params === "redeem"
        ? "แลก"
        : type_params === "subscription"
        ? "จองซื้อ"
        : type_params === "reserve"
        ? "จองแลก"
        : type_params === "pre-sale"
        ? "สั่งซื้อ"
        : type_params === "pre-redeem"
        ? "สั่งแลก"
        : "",
    [type_params]
  );

  const onFileChange = useCallback((partner_document) => {
    setBuyer((prevBuyer) => ({
      ...prevBuyer,
      partner_document,
    }));
  }, []);

  const onDeleteFile = useCallback(() => {
    setBuyer((prevBuyer) => ({
      ...prevBuyer,
      partner_document: undefined,
    }));
  }, []);

  return (
    <>
      <div className="pos-full-page d-flex justify-content-center">
        <Row
          className="mb-5 w-100"
          style={{ marginTop: "100px", maxWidth: "1920px" }}
        >
          <Col md={{ span: 14, offset: 1 }} xxl={{ span: 12, offset: 2 }}>
            <Typography.Title
              level={1}
              className="mb-5"
            >{`${typeName}ในรหัสของ`}</Typography.Title>
            {props.buyer_type?.toLowerCase() === "staff" && (
              <Row type="flex" align="middle" className="my-4">
                <Col md={10}>
                  <Typography.Title level={3} className="m-0">
                    ประเภทผู้ซื้อ
                  </Typography.Title>
                </Col>
                <Col md={14}>
                  <Select
                    className="w-100"
                    value={type}
                    disabled={loading}
                    onChange={(value) => {
                      setType(value);
                      setLoadList([]);
                      setMobileToVerify(null);
                      setBuyer((prevBuyer) => {
                        return {
                          partner_document: prevBuyer.partner_document,
                          partner_remark: prevBuyer.partner_remark,
                        };
                      });
                    }}
                  >
                    <Select.Option value="Staff">พนักงาน</Select.Option>
                    <Select.Option value="Member">สมาชิก</Select.Option>
                  </Select>
                </Col>
              </Row>
            )}
            <Row type="flex" align="middle" className="my-4">
              <Col md={10}>
                <Typography.Title level={3} className="m-0">
                  รหัส{type === "Staff" ? "พนักงาน" : "สมาชิก"}
                </Typography.Title>
              </Col>
              <Col md={14}>
                <SelectSearch
                  placeholder={
                    type === "Staff"
                      ? "รหัสพนักงาน"
                      : "รหัสสมาชิก, เลขบัตรประชาชน, ชื่อ, อีเมล, เบอร์โทร"
                  }
                  loadList={loadList}
                  loading={loading}
                  disabled={loading}
                  value={
                    buyer.partner_code
                      ? buyer.partner_code
                      : query
                      ? query
                      : undefined
                  }
                  onClickSearch={selectSearchOnClick}
                  onClickCancel={selectSearchCancelOnClick}
                  onChange={selectSearchOnChange}
                  onInputKeyDown={selectSearchOnInputKeyDown}
                  onSearch={selectSearchOnSearch}
                  onSelect={selectSearchOnSelect}
                />
              </Col>
            </Row>
            {/* <DocumentUpload
              fileName={buyer.partner_document?.name}
              fileSize={buyer.partner_document?.size}
              onFileChange={onFileChange}
              onDeleteFile={onDeleteFile}
              onShowCameraPopUp={handleShowCameraPopUp}
            /> */}
            <Row
              type="flex"
              align="middle"
              className={styles["document-upload"]}
            >
              <Col md={10}>
                <Typography.Title level={3} className="m-0">
                  เอกสารใบมอบอำนาจ
                </Typography.Title>
              </Col>
              <Col md={14}>
                <UploadFile
                  camera
                  acceptPDF
                  file={buyer.partner_document}
                  onChange={onFileChange}
                  onDelete={onDeleteFile}
                />
              </Col>
            </Row>
            <Row type="flex" align="middle" className="my-4">
              <Col md={10}>
                <Typography.Title level={3} className="m-0">
                  รายละเอียด
                </Typography.Title>
              </Col>
              <Col md={14}>
                <Input.TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  placeholder="รายละเอียดการมอบอำนาจ"
                  value={buyer?.partner_remark}
                  onChange={(e) =>
                    setBuyer({
                      ...buyer,
                      partner_remark: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            {buyer.partner_code && (
              <>
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      ชื่อ นามสกุล
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    <Typography.Title
                      level={4}
                      style={{ fontWeight: "normal" }}
                      className="m-0 d-flex align-items-center font-weight-normal"
                    >
                      {buyer.partner_name}
                      {type.toLowerCase() === "member" && (
                        <Button
                          type="link"
                          size="small"
                          className="p-0 ml-2"
                          onClick={() => setShowProfile(true)}
                        >
                          <span className="d-flex align-items-center bg-pv p-4 text-white rounded-circle">
                            <FaEye />
                          </span>
                        </Button>
                      )}
                    </Typography.Title>
                  </Col>
                </Row>
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      โทรศัพท์
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    <Typography.Title
                      level={4}
                      className="m-0 font-weight-normal"
                    >
                      {buyer.partner_mobile}
                    </Typography.Title>
                    {mobileToVerify && (
                      <MobileConfirm
                        mobile={mobileToVerify}
                        partnerCode={buyer.partner_code}
                        partnerType={buyer.partner_type}
                        setMobileToVerify={setMobileToVerify}
                      />
                    )}
                  </Col>
                </Row>
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      องค์กร
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    <Typography.Title
                      level={4}
                      className="m-0 font-weight-normal"
                    >
                      {buyer.team_name}
                    </Typography.Title>
                  </Col>
                </Row>
                <Row type="flex" align="middle" className="mb-4">
                  <Col md={10}>
                    <Typography.Title level={3} className="m-0">
                      สถานะ
                    </Typography.Title>
                  </Col>
                  <Col md={14}>
                    {textStatus && (
                      <>
                        <Typography.Title
                          level={4}
                          className="m-0 font-weight-normal"
                        >
                          {textStatus}
                        </Typography.Title>
                        {warnBirthDate && (
                          <>
                            <IoIosGift className="text-rp ml-3 mr-2" />
                            <Typography.Title
                              level={4}
                              className="m-0 font-weight-normal text-rp"
                            >
                              Happy Birthday
                            </Typography.Title>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                <WarningExpireDate
                  status={status}
                  warnExpireDate={warnExpireDate}
                  registerRenewEndDate={registerRenewEndDate}
                />
                <ProfileCD partnerCode={buyer.partner_code} />
                <ProfileRalc
                  partnerCode={buyer.partner_code}
                  setRalc={setRalc}
                />
              </>
            )}
          </Col>
          <Col
            md={{ span: 7, offset: 1 }}
            xxl={{ span: 6, offset: 2 }}
            className="d-flex flex-column align-items-center"
          >
            <div className="width-300 height-300 overflow-hidden rounded-circle">
              <img
                src={
                  errImg
                    ? errImg
                    : buyer.member_id
                    ? `${URL_API}/pos/master/image/profile?ref_id=${buyer.member_id}&ref_type=${buyer.partner_type}`
                    : img
                }
                onError={() => setErrImg(img)}
                className="w-100 h-100 object-fit-cover"
                alt="Profile"
              />
            </div>
            <button
              ref={btnSubmitRef}
              type="button"
              className="pos-btn pos-btn-lg bg-pv text-white text-nowrap mt-5"
              onClick={handleClickSubmit}
              tabIndex={2}
            >
              ตกลง
            </button>
          </Col>
        </Row>
      </div>

      {type.toLowerCase() === "member" && (
        <MemberProfilePopup
          visible={showProfile}
          setVisible={setShowProfile}
          partnerCode={buyer.partner_code}
          refId={buyer.member_id}
          refType={buyer.partner_type}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { operation } = state.pos;
  return {
    buyer_code: operation.buyer_code,
    buyer_type: operation.buyer_type,
  };
};

export default connect(mapStateToProps, { addBill: Creators.addBill })(
  PageIndex
);
