/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ErrorMessage } from '@hookform/error-message';
import { Button, Typography } from 'antd';
import { Input, Table } from 'antdV4';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FaPen, FaSearch, FaUserAlt } from 'react-icons/fa';
import { GrRefresh } from 'react-icons/gr';
import { MdDelete } from 'react-icons/md';
import fitStudioService from '../../../../services/fit-studio.service';
import fitUserServices from '../../../../services/fit-user.services';
import useAsync from '../../../../utils/useAsync';
import { AlertConfirm, AlertError } from '../../../util/Alert';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import useModalHook from '../../../util/useModalHook';
import {
  userQueryDefaultValues,
  userQueryFormSchema,
  userUpdateDefaultValues,
  userUpdateFormSchema,
} from '../components/dto/user';
import ChangeFitUserPassword from '../components/form/ChangeFitUserPassword';
import UserModal from '../components/modal/UserModal';
import userConstants from '../constants/userConstants';

const UserPage = () => {
  const [page, setPage] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const {
    open: userFormOpen,
    handleOpen: _HandleUserFormOpen,
    handleClose: _HandleUserFormClose,
  } = useModalHook();

  const userForm = useForm({
    defaultValues: userUpdateDefaultValues,
    resolver: userUpdateFormSchema(),
  });

  const columns = [
    {
      title: 'RFS Member ID',
      dataIndex: 'fitNo',
      key: 'fitNo',
    },
    {
      title: 'RFS Password',
      dataIndex: 'password',
      key: 'password',
      render: (_, record) => {
        return <ChangeFitUserPassword user={record} getUsers={getUsers} />;
      },
    },
    {
      title: 'รหัสสมาชิก',
      dataIndex: 'legacyPartnerCode',
      key: 'legacyPartnerCode',
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => {
        if (record.profileImageUrl) {
          return (
            <img
              key={record.profileImageUrl}
              src={record.profileImageUrl}
              alt={record.profileImageUrl}
              style={{
                width: 50,
                height: 50,
                objectFit: 'cover',
                borderRadius: 25,
              }}
            />
          );
        }

        return (
          <div
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              backgroundColor: '#e0e0e0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FaUserAlt />
          </div>
        );
      },
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'รหัสบัตรประชาชน',
      dataIndex: 'idCard',
      key: 'idCard',
      render: (_, record) => <span>{record.idCard}</span>,
    },
    {
      title: 'สถานะ',
      dataIndex: 'activeStatus',
      key: 'activeStatus',
      render: (_, record) => <span>{record.activeStatus}</span>,
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              userForm.setValue('id', record.id);
              _HandleUserFormOpen();
            }}
          >
            <FaPen />
          </div>
          {record.isDeleted ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('reactive ผู้ใช้งาน');
                if (confirm) {
                  undeleteUser({ userId: record.id });
                }
              }}
            >
              <GrRefresh />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ลบผู้ใช้งาน');
                if (confirm) {
                  deleteUser({ userId: record.id });
                }
              }}
            >
              <MdDelete />
            </div>
          )}
        </div>
      ),
    },
  ];

  const { execute: deleteUser, data: deleteUserData } = useAsync(
    fitStudioService.deleteUser,
    {}
  );

  const { execute: undeleteUser, data: undeleteUserData } = useAsync(
    fitStudioService.undeleteUser,
    {}
  );

  useEffect(() => {
    getUsers();
  }, [page, deleteUserData, undeleteUserData]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: userQueryDefaultValues,
    resolver: userQueryFormSchema(),
  });

  const { refetch: getUsers, data: userLists } =
    fitUserServices.useQueryGetUsers(
      {
        query: {
          ...(watch('idCard') && { idCard: watch('idCard') }),
          ...(watch('fitNo') && { fitNo: watch('fitNo') }),
          ...(watch('name') && { name: watch('name') }),
          ...(watch('legacyPartnerCode') && {
            legacyPartnerCode: watch('legacyPartnerCode'),
          }),
        },
        take: isFiltered ? -1 : userConstants.tableSize,
        page: isFiltered ? 1 : page + 1,
      },
      () => {}
    );

  const _HandleSubmit = handleSubmit((data) => {
    if (!data.idCard && !data.fitNo && !data.name && !data.legacyPartnerCode) {
      AlertError({
        title: 'ไม่สามารถค้นหาได้',
        text: 'กรุณากรอกข้อมูลอย่างใดอย่างหนึ่ง',
      });
    } else {
      setIsFiltered(true);
    }
  });

  const _HandleCancel = () => {
    reset(userQueryDefaultValues);
    setIsFiltered(false);
  };

  return (
    <Panel>
      <PanelHeader>ผู้ใช้งาน</PanelHeader>
      <PanelBody>
        <div>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <Controller
              name="fitNo"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="Fit No"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="legacyPartnerCode"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="รหัสสมาชิก"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="idCard"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="รหัสบัตรประชาชน"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ชื่อ หรือ นามสกุล"
                    className="mr-2"
                  />
                );
              }}
            />
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={_HandleSubmit}
            >
              <FaSearch className="mr-2" />
              ค้นหา
            </Button>
            <Button
              className="bg-pv border-pv text-white"
              onClick={_HandleCancel}
            >
              ยกเลิก
            </Button>
          </div>
          <ErrorMessage
            errors={errors}
            name="searchError"
            render={({ message }) => (
              <Typography.Text className="mb-2 text-danger">
                {message}
              </Typography.Text>
            )}
          />
        </div>

        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={
            userLists?.data && userLists?.data.length > 0 ? userLists.data : []
          }
          onChange={({ current }) => {
            setPage(current - 1);
          }}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          pagination={{
            pageSize: userConstants.tableSize,
            total: userLists?.meta?.total || 0,
          }}
        />
      </PanelBody>
      <FormProvider {...userForm}>
        <UserModal open={userFormOpen} handleClose={_HandleUserFormClose} />
      </FormProvider>
    </Panel>
  );
};

export default UserPage;
