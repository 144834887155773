import { Select } from "antd";
import React from "react";

const SelectMonth = ({ ...props }) => (
  <Select style={{ width: "150px" }} placeholder="ระบุเดือน" {...props}>
    <Select.Option value={1}>ม.ค.</Select.Option>
    <Select.Option value={2}>ก.พ.</Select.Option>
    <Select.Option value={3}>มี.ค.</Select.Option>
    <Select.Option value={4}>เม.ย.</Select.Option>
    <Select.Option value={5}>พ.ค.</Select.Option>
    <Select.Option value={6}>มิ.ย.</Select.Option>
    <Select.Option value={7}>ก.ค.</Select.Option>
    <Select.Option value={8}>ส.ค.</Select.Option>
    <Select.Option value={9}>ก.ย.</Select.Option>
    <Select.Option value={10}>ต.ค.</Select.Option>
    <Select.Option value={11}>พ.ย.</Select.Option>
    <Select.Option value={12}>ธ.ค.</Select.Option>
  </Select>
);

export default SelectMonth;
