import { Col, Modal, notification, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { IoMdReverseCamera } from "react-icons/io";
import { MdCameraEnhance, MdSwitchCamera } from "react-icons/md";
import Webcam from "react-webcam";
import img from "../assets/img/pos/no-img.jpg";
import { base64toFile } from "../views/util/helper";
import { moment } from "../utils";

const CameraModal = ({ visible, allowChangeCamera, onSubmit, onCancel }) => {
  const webcamRef = useRef(null);
  const [captureImg, setCaptureImg] = useState("");
  const [loadingCamera, setLoadingCamera] = useState(true);
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: "environment",
  });
  const [deviceId, setDeviceId] = useState([]);

  useEffect(() => {
    const supports = navigator.mediaDevices.getSupportedConstraints();
    if (!supports["facingMode"]) {
      notification.error({
        message: "แจ้งเตือน",
        description: "This browser does not support facingMode",
      });
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then((media_devices) => {
        const res_device_id = media_devices
          .filter((item) => item.kind === "videoinput")
          .map((item) => item.deviceId);
        if (res_device_id.length > 0) {
          setDeviceId(res_device_id);
        }
      })
      .catch((err) => {
        notification.error({
          message: "ตรวจพบสิ่งผิดพลาด",
          description:
            err.response && err.response.data
              ? err.response.data.message
              : err.message,
        });
      });
  }, []);

  const onCameraError = (e) => {
    setLoadingCamera(false);
    notification.error({
      message: "ตรวจพบสิ่งผิดพลาด",
      description: e.message,
    });
  };

  const handleClickChangeCamera = () => {
    setLoadingCamera(true);
    setVideoConstraints((prevState) => ({
      facingMode: prevState.facingMode === "user" ? "environment" : "user",
    }));
  };

  const handleClickCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptureImg(imageSrc);
  };

  const handleClickTryAgain = () => {
    setCaptureImg("");
    setLoadingCamera(true);
  };

  const handleClickSubmit = () => {
    onSubmit({
      base64: captureImg,
      file: base64toFile(
        captureImg,
        `${moment().format("YYYY-MM-DD-hh-mm-ss")}.jpg`
      ),
    });
    handleClickTryAgain();
  };

  const handleClickCancel = () => {
    onCancel();
    handleClickTryAgain();
  };

  return (
    <Modal
      visible={visible}
      width={700}
      footer={false}
      destroyOnClose={true}
      closable={false}
      zIndex={1081}
    >
      <Row className="mb-4">
        <Col span={24} className="w-100 d-flex justify-content-center">
          <div
            className="overflow-hidden"
            style={{ width: "600px", height: "450px" }}
          >
            {loadingCamera && (
              <div
                className="bg-black d-flex justify-content-center align-items-center"
                style={{ width: "600px", height: "450px" }}
              >
                <Spin tip="Loading..." />
              </div>
            )}
            {captureImg ? (
              <img
                src={captureImg ? captureImg : img}
                className="w-100 h-100 object-fit-cover"
                alt="document"
              />
            ) : (
              <Webcam
                width={600}
                height={450}
                ref={webcamRef}
                forceScreenshotSourceSize={true}
                className="bg-black object-fit-cover"
                screenshotFormat="image/jpeg"
                audio={false}
                videoConstraints={videoConstraints}
                onUserMedia={() => setLoadingCamera(false)}
                onUserMediaError={onCameraError}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col span={24} className="w-100 d-flex justify-content-center">
          <div className="d-flex justify-content-around align-items-center width-250">
            {captureImg ? (
              <>
                <Tooltip placement="bottom" title="ตกลง">
                  <FaCheck
                    fontSize="2em"
                    className="cursor-pointer"
                    onClick={handleClickSubmit}
                  />
                </Tooltip>
                <Tooltip placement="bottom" title="ถ่ายใหม่">
                  <IoMdReverseCamera
                    fontSize="3em"
                    className="cursor-pointer"
                    onClick={handleClickTryAgain}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                {allowChangeCamera && deviceId.length > 1 && !loadingCamera ? (
                  <Tooltip placement="bottom" title="เปลี่ยนกล้อง">
                    <MdSwitchCamera
                      fontSize="2em"
                      className="cursor-pointer"
                      onClick={handleClickChangeCamera}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip placement="bottom" title="ไม่สามารถสลับกล้องได้">
                    <MdSwitchCamera
                      fontSize="2em"
                      className="cursor-no-drop text-gray-40"
                    />
                  </Tooltip>
                )}
                {!loadingCamera ? (
                  <Tooltip placement="bottom" title="ถ่ายรูป">
                    <MdCameraEnhance
                      fontSize="3em"
                      className="cursor-pointer"
                      onClick={handleClickCapture}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip placement="bottom" title="ไม่สามรถถ่ายรูปได้">
                    <MdCameraEnhance
                      fontSize="3em"
                      className="cursor-no-drop text-gray-40"
                    />
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip placement="bottom" title="ยกเลิก">
              <FaTimes
                fontSize="2em"
                className="cursor-pointer"
                onClick={handleClickCancel}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default React.memo(CameraModal);
