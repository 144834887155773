import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";

export const BranchContext = createContext();

export const initState = {
    loading: false,
    reload: false,
    filter: {
        query: "",
        page: 1,
        limit: 10,
        status: "active",
    },
    form: {
        code: "",
        name: "",
        branch_type: "",
        status: "",
    },
    modal: {
        visible: false,
    },
};

export const reducer = createReducer(initState, {
    SET_LOADING: (state, { payload }) => {
        state.loading = payload;
    },
    SET_RELOAD: (state, { payload }) => {
        state.reload = payload;
    },
    SET_FILTER_VALUE: (state, { payload }) => {
        state.filter = { ...state.filter, ...payload };
    },
    SET_EDIT: (state, { payload }) => {
        state.form.code = payload.code;
        state.modal.visible = true;
    },
    SET_CANCEL: (state) => {
        state.form = initState.form;
        state.modal.visible = false;
    },
    SET_FORM_VALUE: (state, { payload }) => {
        state.form = { ...state.form, ...payload };
    },
});

export default BranchContext;
