/* eslint-disable no-unused-vars */
import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHttp } from "../../../../hooks/http";
import { axios, URL_API, _ } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { Creators } from "../ducks/operation-form";
import SelectOption from "./SelectOption";

const SelectAddress = ({
  member_id,
  member_type,
  selectDefaultValue,
  total_pv,
  total_weight,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/member_address`,
      params: { member_id, member_type },
    },
    [member_id, member_type]
  );

  useEffect(() => {
    if (data && data.data) {
      let newData = [];
      newData = data.data.map((n) => ({
        value: n.id,
        text: n.name || "ที่อยู่",
        source: { ...n },
      }));
      newData = [
        ...newData,
        {
          value: 0,
          text: "ระบุที่อยู่จัดส่ง",
        },
      ];
      if (selectDefaultValue && newData.length > 1) {
        const { value, source } = newData[0];
        // axios({
        //   url: `${URL_API}/pos/operation/delivery`,
        //   params: {
        //     pv: total_pv,
        //     weight: total_weight,
        //     province_name: source.province,
        //   },
        // })
        //   .then((res) => {
        //     props.setBillValue({ total_delivery: res.data.data });
        //   })
        //   .catch((err) => {
        //     notification.error({
        //       message: "ตรวจพบสิ่งผิดพลาด",
        //       description:
        //         err.response && err.response.data
        //           ? err.response.data.message
        //           : err.message,
        //     });
        //   });
        props.setBillValue({
          ship_to_id: value,
          ship_address_no: source.address,
          ship_sub_district: source.sub_district,
          ship_district: source.district,
          ship_province: source.province,
          ship_post_code: source.post_code,
          ship_country: source.country,
          ship_to: source.ship_to,
          ship_mobile: source.ship_to_mobile,
        });
      }
      setOptions(newData);
    }
  }, [data, selectDefaultValue, total_pv, total_weight]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <SelectOption
      loading={loading}
      style={{ minWidth: "150px", maxWidth: "100%" }}
      options={options}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  const orders = state.pos.operation.orders;
  const { activeKey, itemByKey } = orders;
  return {
    order_item: itemByKey[activeKey]?.items || null,
    total_weight:
      itemByKey[activeKey] &&
      itemByKey[activeKey].items &&
      _.reduce(
        itemByKey[activeKey].items.itemByKey,
        (sum, n) => (sum = sum + (n.weight || 0) * (n.qty || 0)),
        0
      ),
    total_pv:
      itemByKey[activeKey] &&
      itemByKey[activeKey].items &&
      _.reduce(
        itemByKey[activeKey].items.itemByKey,
        (sum, n) => (sum = sum + (n.pv || 0) * (n.qty || 0)),
        0
      ),
  };
};

export default connect(mapStateToProps, {
  setBillValue: Creators.setBillValue,
})(SelectAddress);
