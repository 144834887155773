/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SaleSummaryContext, {
  //initState,
  reducer,
} from "../../contexts/summary-context";
import Search from "antd/lib/input/Search";
import * as XLSX from "xlsx-js-style";

const { Text } = Typography;
const toDay = moment().format("DD/MM/YYYY");
const user = getUser();

const Index = () => {
  const initState = {
      filter: {
        date_from: moment(),
        date_to: moment(),
        branch: "",
        branch_name: "",
        query: "",
        page: 1,
        limit: 10,
      },
    };
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const limit = 50;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/survey/survey-purchese-channel`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportExcel = () => {
    const datasource = data ? data?.data : [];

    if (datasource.length === 0) {
      return message.warning("ไม่พบข้อมูล");
    } else {
      const title_table = [
        "ลำดับ",
        "สาขา",
        "วันที่",
        "รหัสสมาชิก",
        "ชื่อ-สกุล",
        "คะแนน",
        "รายละเอียด",
      ];
      let excel_data = [title_table];
      datasource.forEach((n) => {
        excel_data = [
          ...excel_data,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            {
              v: n.bill_location === null ? "" : n.bill_location,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v: moment(n.create_date).format("DD/MM/YYYY HH:mm"),
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.create_by_code === null ? "" : n.create_by_code,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v: n.create_by === null ? "" : n.create_by,
              s: { alignment: { horizontal: "left" } },
            },

            {
              v: n.satisfaction_point,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "center" },
              },
            },
            {
              v: n.suggestion === null ? "" : n.suggestion,
              s: { alignment: { horizontal: "left" } },
            },
          ],
        ];
      });
      const wscols = [
        { wch: 10 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 },
        { wch: 15 },
        { wch: 50 },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excel_data);
      ws["!cols"] = wscols;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(
        wb,
        `รายงานประเมินความพึงพอใจ_${moment().format(
          "YYMMDD_HHmm"
        )}.xlsx`
      );
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานประเมินความพึงพอใจ</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col span={7} className="pr-3">
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, ชื่อสมาชิก"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                onSearch={reload}
                onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={2} className="text-right pr-3 ">
              <Text strong>ช่วงวันที่ขาย</Text>
            </Col>

            <Col span={4} className="pr-3">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col span={1} align="right">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={4}>
              <SelectBranch
                withAll={true}
                //allowDisableBranch={true}
                //defaultValue={state.filter.branch}
                disaledTransport={true}
                defaultValue=""
                className="w-100"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
            <Col span={6}>
              <Row gutter={8} type="flex" justify="end">
                <Col>
                  <Button type="primary" onClick={reload}>
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" onClick={exportExcel}>
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data?.data ? data?.data : []}
            rowKey="row_id"
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.data?.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="#"
              dataIndex="row_id"
              align="center"
              width={50}
            />
            <Table.Column
              title="สาขา"
              dataIndex="bill_location"
              align="center"
              width={200}
              
            />
            <Table.Column
              title="วันที่"
              dataIndex="create_date"
              align="center"
              width={150}
              render={(text) => {
                return moment(text).format("DD/MM/YYYY HH:mm");
              }}
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="create_by_code"
              align="center"
              width={150}
            />
            <Table.Column
              title="ชื่อ-สกุล"
              dataIndex="create_by"
              align="center"
              width={250}
              render={(text) => <div style={{ textAlign: "left" }}>{text}</div>}
            />
            <Table.Column
              title="คะแนน"
              dataIndex="satisfaction_point"
              align="center"
              width={100}
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="รายละเอียด"
              dataIndex="suggestion"
              align="center"
              ellipsis={{
                showTitle: false,
              }}
              render={(text) => (
                <Tooltip title={text}>
                  <div style={{ textAlign: "left" }}>
                    {text?.length > 300
                      ? `${text?.substring(0, 300)}...`
                      : text}
                  </div>
                </Tooltip>
              )}
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
