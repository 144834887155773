/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import axios from "axios";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import numeral from "numeral";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import SelectAllType from "../../../../../components/SelectAllType";
import { useApi } from "../../../../../hooks/http2";
import {
  actionProductRequest,
  bomSelector,
  formSelector,
  historySelector,
  mediaSelector,
} from "../../../../../redux/slices/product-request";
import { getToken, getUser, moment, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import ImgUpload from "./components/ImgUpload";
import ModalCloneProduct from "./components/ModalCloneProduct";
import ModalProduct from "./components/ModalProduct";
import SelectBrand from "./components/SelectBrand";
import SelectCategory from "./components/SelectCategory";
import SelectColor from "./components/SelectColor";
import SelectDeliveryType from "./components/SelectDeliveryType";
import SelectItemType from "./components/SelectItemType";
import SelectOracleCategory from "./components/SelectOracleCategory";
import SelectOracleGroup from "./components/SelectOracleGroup";
import SelectOracleSubCategory from "./components/SelectOracleSubCategory";
import SelectPackaging from "./components/SelectPackaging";
import SelectProductCategory from "./components/SelectProductCategory";
import SelectShape from "./components/SelectShape";
import SelectSize from "./components/SelectSize";
import SelectSmell from "./components/SelectSmell";
import SelectSubCategory from "./components/SelectSubCategory";
import SelectTaste from "./components/SelectTaste";
import SelectVat from "./components/SelectVat";
import SelectVatRefund from "./components/SelectVatRefund";
import SelectTaxRefund from "./components/SelectTaxRefund";
import styles from "../../../../../scss/module/product.module.scss";
import {
  base64toFile,
  beforeUploadImgPDF,
  resizeFile,
} from "../../../../util/helper";

const RequestForm = (props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const [showClone, setShowClone] = useState(false);
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  const data = useSelector(formSelector);

  const media = useSelector(mediaSelector);
  const bom = useSelector(bomSelector);
  const flow_history = useSelector(historySelector);
  const {
    CLEAR_FORM,
    SET_FORM,
    LOAD_FORM,
    SET_MODAL_VALUE,
    SET_BOM_QTY,
    DEL_BOM,
    CLEAR_BOM,
  } = actionProductRequest;

  const fieldVisible = useApi(
    {
      baseURL: URL_API,
      url: `/setting/permission/field-visible/product-request`,
      token: true,
      params: {
        department_id: data?.department_id,
        category_id: data?.category,
      },
    },
    [data?.department_id, data?.category]
  );

  const handleClickSubmit = (type = "send") => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }
      if (
        ["kit/package", "assembly"].includes(
          values?.product_type_set?.toLowerCase()
        ) &&
        _.filter(
          _.map(bom.itemKeys, (n) => bom.itemByKey[n]),
          (n) => n.qty !== "" && parseInt(n.qty) > 0
        ).length === 0
      ) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description:
            "กรุณากรอกข้อมูลรายการสินค้าประกอบในเซ็ตอย่างน้อย 1 รายการ",
        });
      }

      Modal.confirm({
        title: "ยืนยัน",
        content:
          type === "send"
            ? "คุณต้องการส่งคำขอหรือไม่"
            : type === "cancel"
            ? "คุณต้องการยกเลิกเอกสารหรือไม่"
            : "คุณต้องการบันทึกเอกสารร่างหรือไม่",
        okText:
          type === "send" ? "ส่งคำขอ" : type === "cancel" ? "ยืนยัน" : "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let media_default;
            let formData = new FormData();
            _.forEach(
              _.filter(media.itemKeys, (n) => media.itemByKey[n].file),
              (n) => {
                const media_key = `media-key-${n}`;
                formData.append(`media-key-${n}`, media.itemByKey[n].file);
                if (media.itemByKey[n].is_default) {
                  media_default = {
                    media_key,
                  };
                }
              }
            );

            let obj = {
              ..._.omit(data, ["manual"]),
              ..._.omit(values, ["manual"]),
              product_description_local: data.product_description_local
                ? draftToHtml(
                    convertToRaw(
                      data.product_description_local.getCurrentContent()
                    )
                  )
                : "",
              // event_date: _.map(values.event_date, (n) =>
              //   moment(n).format("YYYY-MM-DD")
              // )?.join(","),
              event_date:
                values.event_start && values.event_end
                  ? moment(values.event_start).format("YYYY-MM-DD") +
                    "," +
                    moment(values.event_end).format("YYYY-MM-DD")
                  : "",
              status: type === "send" ? "pending" : type,
              flow_status: type === "send" ? "request" : null,
              media_default,
              media_temp: [],
              media_delate: [...media.delete],
              bom: _.map(bom.itemKeys, (n) => bom.itemByKey[n]),
            };

            formData.append("data", JSON.stringify(obj));
            if (values.manual?.file) {
              formData.append("manual", values.manual?.file?.originFileObj);
            }
            // console.log(obj)
            axios({
              method: "post",
              baseURL: URL_API,
              url: `setting/master/product-request${
                match.params.id === "0" ? "" : `/${match.params.id}`
              }`,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                resolve("success");
              })
              .catch((err) => {
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content:
                  type === "send"
                    ? "ส่งคำขอเรียบร้อย"
                    : type === "cancel"
                    ? "ยกเลิกเรียบร้อย"
                    : "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.push(`/setting/master/product-request`);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleClickApprove = (approve) => {
    let reason = "";
    Modal.confirm({
      title: "ยืนยัน",
      content: (
        <>
          <p>{`คุณต้องการ ${
            approve ? "อนุมัติ" : "ส่งกลับแก้ไข"
          } คำขอหรือไม่`}</p>
          {!approve && <Input onChange={(e) => (reason = e.target.value)} />}
        </>
      ),

      okText: approve ? "อนุมัติ" : "ส่งกลับแก้ไข",
      cancelText: "ยกเลิก",
      okType: approve ? "primary" : "danger",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            baseURL: URL_API,
            url: `setting/master/product-request/${match.params.id}/flow`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              flow_status: approve
                ? data?.flow_status === "request"
                  ? "approve1"
                  : data?.flow_status === "approve1"
                  ? "approve2"
                  : // : data?.flow_status === "approve2"
                    // ? "approve3"
                    "approve"
                : "reject",
              status:
                data?.flow_status === "approve2" && approve
                  ? "completed"
                  : !approve
                  ? "draft"
                  : data?.status,
              reason,
            },
          })
            .then((res) => {
              resolve("success");
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: `${approve ? "อนุมัติเรียบร้อย" : "ส่งกลับแก้ไขแล้ว"}`,
              okText: "ปิด",
              onOk() {
                history.push(`/setting/master/product-request`);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const loadData = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: `setting/master/product-request/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        dispatch(LOAD_FORM(res.data.data));
        props.form.setFieldsValue({
          ...res.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadProductData = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: `/system/products/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        let data = {
          ..._.omit(res.data?.data, ["id"]),
          status: "draft",
        };

        dispatch(LOAD_FORM(data));
        props.form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloneItem = (id, source) => {
    let url = "";
    let clone = "";
    if (source === "product") {
      url = `/system/products/${id}`;
      clone = "product";
    } else {
      url = `setting/master/product-request/${id}`;
      clone = "product-request";
    }

    axios({
      method: "get",
      baseURL: URL_API,
      url,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        let data = {
          ..._.omit(res.data?.data, [
            "id",
            "status",
            "product_code",
            "backend_sku_code",
          ]),
        };

        let _user = getUser();
        dispatch(
          LOAD_FORM({
            ...data,
            bar_code:"",
            barcode_type:"",
            division_id: _user?.division_id?.toString() || "",
            department_id: _user?.department_id?.toString() || "",
            clone_from: clone,
            clone_from_id: id,
            category: _user?.division_id == data?.division_id ? data?.category : "",
            point_03: data?.point_01 || 0,
          })
        );
        props.form.setFieldsValue({
          ...data,
          bar_code:"",
          barcode_type:"",
          division_id: _user?.division_id?.toString() || "",
          department_id: _user?.department_id?.toString() || "",
          category: _user?.division_id == data?.division_id ? data?.category : "",
          point_03: data?.point_01 || 0,
        });

        setShowClone(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUploadChange = (data) => {
    let file = data.file;

    dispatch(SET_FORM({ manual: file }));
  };

  const onChangeDistributionType = () => {
    dispatch(
      SET_FORM({
        point_01: 0,
        point_02: 0,
        point_03: 0,
        point_04: 0,
        point_05: 0,
        point_06: 0,
        point_07: 0,
        point_08: 0,
        point_09: 0,
        point_10: 0,
        point_11: 0,
        r_point_01: 0,
        r_point_02: 0,
        r_point_03: 0,
        r_point_04: 0,
        r_point_05: 0,
        r_point_06: 0,
        r_point_07: 0,
        r_point_08: 0,
        r_point_09: 0,
        r_point_10: 0,
        r_point_11: 0,
        d_point_01: 0,
        d_point_02: 0,
        d_point_03: 0,
        d_point_04: 0,
        d_point_05: 0,
        d_point_06: 0,
        d_point_07: 0,
        d_point_08: 0,
        d_point_09: 0,
        d_point_10: 0,
        d_point_11: 0,
        member_redeem: 0,
        distributor_redeem: 0,
      })
    );
  };

  useEffect(() => {
    let _user = getUser();
    setUser(_user);

    if (match.params.id !== "0") {
      loadData(match.params.id);
    } else {
      dispatch(CLEAR_FORM());
      props.form.resetFields();

      dispatch(
        SET_FORM({
          division_id: _user?.division_id?.toString() || "",
          department_id: _user?.department_id?.toString() || "",
        })
      );

      let url = history.location.search;
      if (url !== "") {
        let parseUrl = qs.parse(url);

        if (parseUrl.product_id) {
          loadProductData(parseUrl.product_id);
        }
      }
    }
  }, [match.params.id, history.location.search]);

  useEffect(() => {
    if (fieldVisible.fetch?.data) {
      // let _data = {
      //   ...initState.form,
      //   ..._.pick(data, [
      //     "id",
      //     "department_id",
      //     "division_id",
      //     "product_code",
      //     "backend_sku_code",
      //     "product_name_local",
      //     "product_name_en",
      //     "product_title_local",
      //     "product_title_en",
      //     "product_tagline_local",
      //     "product_tagline_en",
      //     "product_manual_local",
      //     "product_manual_en",
      //     "product_description_local",
      //     "product_description_en",
      //     "product_type",
      //     "product_group",
      //     "product_type_set",
      //     "product_class",
      //     "show_approve",
      //     "show_cancel",
      //     "show_save",
      //     "oracle_category",
      //     "oracle_sub_category",
      //     "oracle_group",
      //   ]),
      // };
      // console.log(_data);
      // dispatch(
      //   SET_FORM({
      //     ..._data,
      //   })
      // );
      // props.form.setFieldsValue({
      //   ..._data,
      // });
    }
  }, [fieldVisible.fetch?.data]);

  const { getFieldDecorator } = props.form;
  
  return (
    <>
      <Panel>
        <PanelHeader>รายละเอียดสินค้า</PanelHeader>
        <PanelBody>
          <Spin spinning={fieldVisible.loading}>
            <Form layout="vertical" className={styles.form}>
              {/* Breadcrumb */}
              <Row gutter={16} className="mb-2">
                <Col md={12}>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">หน้าหลัก</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/setting/master/product-request">ขอสินค้า</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {match.params.id === "0" ? "คำขอใหม่" : "รายละเอียดคำขอ"}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
                <Col md={12} className="text-right">
                  {match.params.id !== "0" && data?.show_approve === 1 ? (
                    <>
                      <Button
                        type="primary"
                        className="mr-2"
                        onClick={() => handleClickApprove(true)}
                      >
                        อนุมัติ
                      </Button>
                      <Button
                        type="danger"
                        onClick={() => handleClickApprove(false)}
                      >
                        ส่งกลับแก้ไข
                      </Button>
                    </>
                  ) : match.params.id === "0" || data?.show_save === 1 ? (
                    <>
                      {data?.status === "" && (
                        <Button
                          type="primary"
                          ghost
                          className="mr-3"
                          onClick={() => setShowClone(true)}
                        >
                          คัดลอกเอกสาร
                        </Button>
                      )}
                      {/* <Button
                        type="dashed"
                        className="mr-3"
                        onClick={() => handleClickSubmit("draft")}
                      >
                        เอกสารร่าง
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleClickSubmit("send")}
                      >
                        บันทึก
                      </Button> */}
                    </>
                  ) : null}
                  {data?.show_cancel === 1 ? (
                    <Button
                      type="danger"
                      className="ml-3"
                      onClick={() => handleClickSubmit("cancel")}
                    >
                      ยกเลิกเอกสาร
                    </Button>
                  ) : null}
                </Col>
              </Row>

              {/* Form */}
              <div>
                {(fieldVisible.fetch?.data || []).includes("product_code") && (
                  <Row gutter={16}>
                    <Col md={12}>
                      <Form.Item label="รหัสสินค้า">
                        {getFieldDecorator("product_code", {
                          initialValue: data?.product_code,
                        })(<Input disabled className="text-dark" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item label="Category">
                      {getFieldDecorator("oracle_category", {
                        initialValue: data?.oracle_category,
                        rules: [
                          { required: true, message: "กรุณาระบุ Category" },
                        ],
                        onChange: (value) => {
                          dispatch(
                            SET_FORM({
                              oracle_category: value,
                              oracle_sub_category: "",
                              oracle_group: "",
                            })
                          );

                          props.form.setFieldsValue({
                            oracle_sub_category: "",
                            oracle_group: "",
                          });
                        },
                      })(
                        <SelectOracleCategory
                          className="w-100 text-dark"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false ||
                            data?.product_code !== ""
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="Sub Category">
                      {getFieldDecorator("oracle_sub_category", {
                        initialValue: data?.oracle_sub_category,
                        rules: [
                          { required: true, message: "กรุณาระบุ Sub Category" },
                        ],
                        onChange: (value) => {
                          dispatch(
                            SET_FORM({
                              oracle_sub_category: value,
                              oracle_group: "",
                            })
                          );

                          props.form.setFieldsValue({
                            oracle_group: "",
                          });
                        },
                      })(
                        <SelectOracleSubCategory
                          category_id={data?.oracle_category}
                          className="w-100 text-dark"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false ||
                            data?.product_code !== ""
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="Group">
                      {getFieldDecorator("oracle_group", {
                        initialValue: data?.oracle_group,
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุ Group",
                          },
                        ],
                      })(
                        <SelectOracleGroup
                          className="w-100 text-dark"
                          sub_category_id={data?.oracle_sub_category}
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false ||
                            data?.product_code !== ""
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {/* <Col md={8}>
                    <Form.Item label="ประเภทสินค้า">
                      {getFieldDecorator("product_type", {
                        initialValue: data?.product_type,
                        rules: [
                          { required: true, message: "กรุณาระบุประเภทสินค้า" },
                        ],
                      })(
                        <SelectType
                          division_id={data?.division_id}
                          className="w-100"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col> */}
                  <Col md={8}>
                    <Form.Item label="กลุ่มสินค้าหลัก">
                      {getFieldDecorator("category", {
                        initialValue: data?.category,
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุกลุ่มสินค้าหลัก",
                          },
                        ],
                        onChange: (value) => {
                          dispatch(SET_FORM({ category: value }));
                          props.form.setFieldsValue({ sub_category: "" });
                        },
                      })(
                        <SelectCategory
                          division_id={data?.department_id}
                          className="w-100 text-dark"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false ||
                            data?.product_code !== ""
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="กลุ่มสินค้าย่อย">
                      {getFieldDecorator("sub_category", {
                        initialValue: data?.sub_category,
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุกลุ่มสินค้าย่อย",
                          },
                        ],
                      })(
                        <SelectSubCategory
                          className="w-100 text-dark"
                          group_id={data?.category}
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false ||
                            data?.product_code !== ""
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="เงื่อนไขระบบ">
                      {getFieldDecorator("product_type", {
                        initialValue: data?.product_type,
                        rules: [
                          { required: true, message: "กรุณาระบุเงื่อนไขระบบ" },
                        ],
                        onChange: (value) =>{
                          dispatch(SET_FORM({ product_type: value }));
                          onChangeDistributionType();
                        },
                      })(
                        <SelectAllType
                          process_type="product_type"
                          withAll
                          allText="เลือกเงื่อนไขระบบ"
                          className="w-100 text-dark"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item label="รูปแบบการขาย">
                      {getFieldDecorator("distribution_type", {
                        initialValue: data?.distribution_type,
                        rules: [
                          { required: true, message: "กรุณาระบุรูปแบบการขาย" },
                        ],
                        onChange: (value) => {
                          dispatch(SET_FORM({ distribution_type: value }));
                          onChangeDistributionType();
                        },
                      })(
                        <SelectAllType
                          process_type="distribution_type"
                          className="w-100 text-dark"
                          withAll
                          allText="เลือกรูปแบบการขาย"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false ||
                            data?.product_code !== ""
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="รูปแบบสินค้า">
                      {getFieldDecorator("product_type_set", {
                        initialValue: data?.product_type_set,
                        rules: [
                          { required: true, message: "กรุณาระบุรูปแบบสินค้า" },
                        ],
                        onChange: (value) => {
                          dispatch(SET_FORM({ product_type_set: value }));
                          dispatch(CLEAR_BOM());
                        },
                      })(
                        <SelectItemType
                          className="w-100 text-dark"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false ||
                            data?.product_code !== ""
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col md={8}>
                    <Form.Item label="Product Class">
                      {getFieldDecorator("product_class", {
                        initialValue: data?.product_class,
                      })(
                        <SelectProductClass
                          className="w-100"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col> */}
                </Row>
              </div>

              <hr />

              {/* BOM */}
              {/* {
                // SET[G02], Dealer[G03], Reborn[G07], Topping[G08], Mini Topping[G09]
                ["PT08", "C05", "PT02"].includes(data?.product_type) && ( */}
              {["Kit/Package", "Assembly"].includes(data?.product_type_set) && (
                <div>
                  <Divider>สินค้าชุดเซ็ต</Divider>

                  <Row gutter={16} type="flex" className="align-items-center">
                    <Col md={12}>
                      <h4 className="mb-0">รายการสินค้าประกอบในเซ็ต</h4>
                    </Col>
                    <Col md={12} className="text-right">
                      <Button
                        type="primary"
                        ghost
                        className="mb-2"
                        icon="plus"
                        onClick={() => {
                          dispatch(SET_MODAL_VALUE({ visible: true }));
                        }}
                        disabled={
                          (match.params.id === "0" || data?.show_save === 1) ===
                          false
                        }
                      >
                        เพิ่มสินค้าย่อย
                      </Button>
                    </Col>
                  </Row>
                  <Table
                    size="small"
                    bordered
                    dataSource={bom?.itemKeys.map((n) => ({
                      ...bom?.itemByKey[n],
                      key: n,
                    }))}
                    pagination={false}
                  >
                    <Table.Column
                      title="ลำดับ"
                      key="no"
                      align="right"
                      render={(text, record, index) => index + 1}
                    />
                    <Table.Column title="รหัสสินค้า" dataIndex="item_code" />
                    <Table.Column
                      title="ชื่อสินค้า"
                      dataIndex="product_name_local"
                    />
                    {/* <Table.Column title="ราคา/ชิ้น" />
                      <Table.Column
                        title="คะแนนพีวี/ชิ้น"
                        dataIndex="point_01"
                        align="right"
                      /> */}
                    <Table.Column
                      title="จำนวน"
                      align="right"
                      dataIndex="qty"
                      render={(text, record) => {
                        return (
                          <InputNumber
                            value={text}
                            min={0}
                            size="small"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                            onChange={(value) =>
                              dispatch(
                                SET_BOM_QTY({
                                  key: record.key,
                                  qty: value,
                                })
                              )
                            }
                          />
                        );
                      }}
                    />
                    {/* <Table.Column
                        title="พีวี"
                        key="pv"
                        align="right"
                        render={(text, record) =>
                          record.point_01 * record.qty || 0
                        }
                      />
                      <Table.Column title="ราคา" /> */}
                    <Table.Column
                      align="center"
                      dataIndex="key"
                      render={(text) => {
                        return (
                          <Button
                            type="danger"
                            ghost
                            size="small"
                            onClick={() => dispatch(DEL_BOM(text))}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          >
                            ลบ
                          </Button>
                        );
                      }}
                    />
                  </Table>
                </div>
              )}

              {/* )
              } */}

              {/* Product info */}
              <div>
                <Divider>ข้อมูลสินค้า</Divider>
                <Row gutter={16}>
                  {(fieldVisible.fetch?.data || []).includes(
                    "product_name_local"
                  ) && (
                    <Col md={12}>
                      <Form.Item label="ชื่อสินค้า (TH)">
                        {getFieldDecorator("product_name_local", {
                          initialValue: data?.product_name_local,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุชื่อสินค้า (TH)",
                            },
                          ],
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                  {(fieldVisible.fetch?.data || []).includes(
                    "product_name_en"
                  ) && (
                    <Col md={12}>
                      <Form.Item label="ชื่อสินค้า (EN)">
                        {getFieldDecorator("product_name_en", {
                          initialValue: data?.product_name_en,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุชื่อสินค้า (EN)",
                            },
                          ],
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                  {(fieldVisible.fetch?.data || []).includes("vat") && (
                    <Col md={12}>
                      <Form.Item
                        label={
                          <span>
                            การคำนวณภาษี{" "}
                            <small className="text-danger">
                              (ยกเว้นสินค้าทางการเกษตร เลือกเป็น
                              สินค้ายกเว้นภาษี)
                            </small>
                          </span>
                        }
                      >
                        {getFieldDecorator("tax_schedule", {
                          initialValue: data?.tax_schedule,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุการคำนวณภาษี",
                            },
                          ],
                          onChange: (value, option) => {
                            console.log(value, option);
                            // dispatch(
                            //   SET_FORM({ point_01: value, point_03: value })
                            // );
                            // props.form.setFieldsValue({ point_03: value });
                          },
                        })(
                          <SelectVat
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกการคำนวณภาษี"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>

                {(fieldVisible.fetch?.data || []).includes("tagline") && (
                  <Row gutter={16}>
                    <Col md={24}>
                      <Form.Item label="คำโปรย">
                        {getFieldDecorator("product_tagline_local", {
                          initialValue: data?.product_tagline_local,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {(fieldVisible.fetch?.data || []).includes("description") && (
                  <Row gutter={16}>
                    <Col md={24}>
                      <Form.Item
                        label={
                          <>
                            รายละเอียดสินค้า{" "}
                            <small>*ขนาดไฟล์รูปไม่เกิน 1 MB</small>
                          </>
                        }
                      >
                        <Editor
                          editorClassName="border"
                          editorState={data?.product_description_local}
                          onEditorStateChange={(e) =>
                            dispatch(
                              SET_FORM({
                                product_description_local: e,
                              })
                            )
                          }
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                          readOnly={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={16}>
                  {(fieldVisible.fetch?.data || []).includes("how_to") && (
                    <Col md={8}>
                      <Form.Item label="วิธีการทาน / วิธีการใช้">
                        {getFieldDecorator("product_manual_local", {
                          initialValue: data?.product_manual_local,
                        })(
                          <Input.TextArea
                            rows={4}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                  {(fieldVisible.fetch?.data || []).includes("ingredient") && (
                    <Col md={8}>
                      <Form.Item label="ส่วนประกอบ">
                        {getFieldDecorator("ingredient", {
                          initialValue: data?.ingredient,
                        })(
                          <Input.TextArea
                            rows={4}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                  {(fieldVisible.fetch?.data || []).includes("caution") && (
                    <Col md={8}>
                      <Form.Item label="คำเตือน">
                        {getFieldDecorator("caution", {
                          initialValue: data?.caution,
                        })(
                          <Input.TextArea
                            rows={4}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                  {/* {(fieldVisible.fetch?.data || []).includes("storage") && (
                    <Col md={12}>
                      <Form.Item label="การจัดเก็บ">
                        {getFieldDecorator("storage", {
                          initialValue: data?.storage,
                        })(
                          <Input.TextArea
                            rows={4}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )} */}
                </Row>

                {(fieldVisible.fetch?.data || []).includes("manual") && (
                  <Row gutter={16}>
                    <Col md={24}>
                      <Form.Item label="คู่มือ (รองรับ PDF, JPEG, PNG)">
                        {getFieldDecorator("manual", {
                          initialValue: data?.manual,
                        })(
                          <Upload
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            multiple={false}
                            accept=".png,.jpg,.pdf"
                            beforeUpload={beforeUploadImgPDF}
                            defaultFileList={data?.manual || []}
                            onChange={async (data) => {
                              let file = data.file.originFileObj;
                              if (!file) return;
                              let value = {
                                id: null,
                                type: null,
                                name: null,
                                file: null,
                                temp: true,
                              };

                              if (file.type === "application/pdf") {
                                value = {
                                  ...value,
                                  file,
                                  type: "application",
                                  name: file.name,
                                };
                              } else {
                                if (data.file.size / 1024 / 1024 >= 3) {
                                  const img_url = await resizeFile(file);
                                  file = await base64toFile(img_url, file.name);
                                }
                                value = {
                                  ...value,
                                  file,
                                  type: "image",
                                };
                              }

                              onUploadChange(value);
                            }}
                            customRequest={() => {}}
                          >
                            <Button type="dashed">เลือกไฟล์</Button>
                          </Upload>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={4}>
                  {(fieldVisible.fetch?.data || []).includes("barcode") && (
                    <>
                      <Col md={4}>
                        <Form.Item label="บาร์โค้ด">
                          {getFieldDecorator("barcode_type", {
                            initialValue: data?.barcode_type,
                            rules: [
                              {
                                required: true,
                                message: "กรุณาเลือกบาร์โค้ด",
                              },
                            ],
                            onChange: (e) =>
                              dispatch(
                                SET_FORM({ barcode_type: e, bar_code: "" })
                              ),
                          })(
                            <SelectAllType
                              process_type="barcode_type"
                              className="w-100 text-dark"
                              withAll
                              allText="เลือกบาร์โค้ด"
                              division_id={data?.division_id}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      {data?.barcode_type === "custom" && (
                        <Col md={8}>
                          <Form.Item label="กำหนดบาร์โค้ด">
                            {getFieldDecorator("bar_code", {
                              initialValue: data?.bar_code,
                              rules: [
                                {
                                  required: true,
                                  message: "กรุณากำหนดบาร์โค้ด",
                                },
                              ],
                            })(
                              <Input
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                                className="text-dark"
                              />
                            )}
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("fda") && (
                    <Col md={4}>
                      <Form.Item label="เลขที่ใบรับจดแจ้ง/เลขสารบบอาหาร">
                        {getFieldDecorator("fda", {
                          initialValue: data?.fda,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("halal") && (
                    <Col md={4}>
                      <Form.Item label="หมายเลขฮาลาล">
                        {getFieldDecorator("halal", {
                          initialValue: data?.halal,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes(
                    "product_category"
                  ) && (
                    <Col md={4}>
                      <Form.Item label="หมวดสินค้า">
                        {getFieldDecorator("product_category", {
                          initialValue: data?.product_category,
                        })(
                          <SelectProductCategory
                            className="w-100 text-dark"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("unit_type") && (
                    <Col md={4}>
                      <Form.Item label="หน่วยของสินค้า">
                        {getFieldDecorator("unit_type", {
                          initialValue: data?.unit_type,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุหน่วยของสินค้า",
                            },
                          ],
                        })(
                          <SelectAllType
                            process_type="product_unit_type"
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกหน่วยสินค้า"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("aging") && (
                    <Col md={4}>
                      <Form.Item label="อายุสินค้า (วัน)">
                        {getFieldDecorator("shelef_life", {
                          initialValue: data?.shelef_life,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("selling_age") && (
                    <Col md={6}>
                      <Form.Item
                        label={
                          <span>
                            อายุสินค้าที่ห้ามจำหน่าย (วัน){" "}
                            <Tooltip
                              placement="top"
                              title={
                                <p>
                                  <strong>
                                    <u>คำอธิบายเพิ่มเติม</u>
                                  </strong>
                                  <br />
                                  อายุสินค้าที่ห้ามจำหน่าย คือ
                                  การเก็บสินค้าออกจากชั้นวางเมื่อมีอายุคงเหลือตามที่กำหนด
                                  ตัวอย่างเช่น สินค้า Core Produt
                                  ที่จะหมดอายุในอีก 90 วัน
                                  ต้องเก็บออกจากชั้นวางขาย
                                  ห้ามมีสินค้าเหลือเวลาอายุสินค้าตามที่กำหนด
                                  วางขายหน้า Shelf โดยเด็ดขาด
                                </p>
                              }
                            >
                              <Icon type="info-circle" />
                            </Tooltip>
                          </span>
                        }
                      >
                        {getFieldDecorator("selling_age", {
                          initialValue: data?.selling_age,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step={1}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("storage") && (
                    <Col md={4}>
                      <Form.Item label="อุณหภูมิการจัดเก็บ">
                        {getFieldDecorator("storage", {
                          initialValue: data?.storage,
                        })(
                          <SelectAllType
                            process_type="product_temperature"
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกอุณหภูมิการจัดเก็บ"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("brand") && (
                    <Col md={4}>
                      <Form.Item label="แบรนด์">
                        {getFieldDecorator("brand", {
                          initialValue: data?.brand,
                        })(
                          <SelectBrand
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกแบรนด์"
                            division_id={data?.department_id}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("series") && (
                    <Col md={4}>
                      <Form.Item label="รุ่นสินค้า">
                        {getFieldDecorator("product_series", {
                          initialValue: data?.product_series,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("year") && (
                    <Col md={4}>
                      <Form.Item label="รุ่นปีที่ผลิต">
                        {getFieldDecorator("product_year", {
                          initialValue: data?.product_year,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("link") && (
                    <Col md={4}>
                      <Form.Item label="ลิงค์ที่เกี่ยวข้อง">
                        {getFieldDecorator("link", {
                          initialValue: data?.link,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("warranty") && (
                    <Col md={4}>
                      <Form.Item label="การรับประกันสินค้า">
                        {getFieldDecorator("warranty", {
                          initialValue: data?.warranty,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                  {(fieldVisible.fetch?.data || []).includes("size") && (
                    <Col md={4}>
                      <Form.Item label="Size">
                        {getFieldDecorator("size", {
                          initialValue: data?.size,
                        })(
                          <SelectSize
                            className="w-100 text-dark"
                            withAll
                            allText="เลือก Size"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("chest") && (
                    <Col md={4}>
                      <Form.Item label="รอบอก">
                        {getFieldDecorator("product_chest", {
                          initialValue: data?.product_chest,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("shoulder") && (
                    <Col md={4}>
                      <Form.Item label="ไหล่">
                        {getFieldDecorator("product_shoulder", {
                          initialValue: data?.product_shoulder,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("waist") && (
                    <Col md={4}>
                      <Form.Item label="เอว">
                        {getFieldDecorator("product_waist", {
                          initialValue: data?.product_waist,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("hip") && (
                    <Col md={4}>
                      <Form.Item label="สะโพก">
                        {getFieldDecorator("product_hip", {
                          initialValue: data?.product_hip,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("volume") && (
                    <Col md={4}>
                      <Form.Item label="ปริมาณ">
                        {getFieldDecorator("volume", {
                          initialValue: data?.volume,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("width") && (
                    <Col md={4}>
                      <Form.Item label="กว้าง">
                        {getFieldDecorator("product_width", {
                          initialValue: data?.product_width,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("length") && (
                    <Col md={4}>
                      <Form.Item label="ยาว">
                        {getFieldDecorator("product_length_1", {
                          initialValue: data?.product_length_1,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("height") && (
                    <Col md={4}>
                      <Form.Item label="สูง">
                        {getFieldDecorator("product_height", {
                          initialValue: data?.product_height,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("dept") && (
                    <Col md={4}>
                      <Form.Item label="ลึก">
                        {getFieldDecorator("product_depth", {
                          initialValue: data?.product_depth,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("weight") && (
                    <Col md={4}>
                      <Form.Item label="น้ำหนัก">
                        {getFieldDecorator("size_weight", {
                          initialValue: data?.size_weight,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("material") && (
                    <Col md={4}>
                      <Form.Item label="วัสดุ">
                        {getFieldDecorator("material", {
                          initialValue: data?.material,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("color") && (
                    <Col md={4}>
                      <Form.Item label="สี">
                        {getFieldDecorator("color", {
                          initialValue: data?.color,
                        })(
                          <SelectColor
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกสี"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("taste") && (
                    <Col md={4}>
                      <Form.Item label="รสชาติ">
                        {getFieldDecorator("taste", {
                          initialValue: data?.taste,
                        })(
                          <SelectTaste
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกรสชาติ"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("smell") && (
                    <Col md={4}>
                      <Form.Item label="กลิ่น">
                        {getFieldDecorator("smell", {
                          initialValue: data?.smell,
                        })(
                          <SelectSmell
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกกลิ่น"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("shape") && (
                    <Col md={4}>
                      <Form.Item label="รูปทรง">
                        {getFieldDecorator("product_shape", {
                          initialValue: data?.product_shape,
                        })(
                          <SelectShape
                            className="w-100 text-dark"
                            withAll
                            allText="เลือกรูปทรง"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("package") && (
                    <Col md={4}>
                      <Form.Item label="รูปแบบบรรจุภัณฑ์">
                        {getFieldDecorator("packaging", {
                          initialValue: data?.packaging,
                        })(
                          <SelectPackaging
                            className="w-100 text-dark"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("event_date") && (
                    <>
                      <Col md={4}>
                        <Form.Item label="เริ่มต้นวันที่จัดงาน">
                          {getFieldDecorator("event_start", {
                            initialValue: data?.event_start,
                            rules: [
                              {
                                required:
                                  data?.event_end != null &&
                                  data?.event_end !== undefined
                                    ? true
                                    : false,
                                message: "กรุณาระบุ เริ่มต้นวันที่จัดงาน",
                              },
                            ],
                          })(
                            <DatePicker
                              className="w-100 text-dark"
                              format="DD/MM/YYYY"
                              placeholder="เริ่มต้น"
                              disabledDate={(current) =>
                                current &&
                                data?.event_end &&
                                current > data?.event_end
                              }
                              onChange={(e) =>
                                dispatch(SET_FORM({ event_start: e }))
                              }
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item label="สิ้นสุดวันที่จัดงาน">
                          {getFieldDecorator("event_end", {
                            initialValue: data?.event_end,
                            rules: [
                              {
                                required:
                                  data?.event_start != null &&
                                  data?.event_start !== undefined
                                    ? true
                                    : false,
                                message: "กรุณาระบุ สิ้นสุดวันที่จัดงาน",
                              },
                            ],
                          })(
                            <DatePicker
                              className="w-100 text-dark"
                              format="DD/MM/YYYY"
                              placeholder="สิ้นสุด"
                              disabledDate={(current) =>
                                current &&
                                data?.event_start &&
                                current < data?.event_start
                              }
                              onChange={(e) =>
                                dispatch(SET_FORM({ event_end: e }))
                              }
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("energy") && (
                    <Col md={4}>
                      <Form.Item label="พลังงาน">
                        {getFieldDecorator("energy", {
                          initialValue: data?.energy,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("fat") && (
                    <Col md={4}>
                      <Form.Item label="ไขมัน">
                        {getFieldDecorator("fat", {
                          initialValue: data?.fat,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("sugar") && (
                    <Col md={4}>
                      <Form.Item label="คาร์โบไฮเดรต">
                        {getFieldDecorator("sugar", {
                          initialValue: data?.sugar,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("sodium") && (
                    <Col md={4}>
                      <Form.Item label="โปรตีน">
                        {getFieldDecorator("sodium", {
                          initialValue: data?.sodium,
                        })(
                          <Input
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            className="text-dark"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes(
                    "unit_per_package"
                  ) && (
                    <Col md={6}>
                      <Form.Item label="จำนวนบรรจุต่อ 1 ลัง">
                        {getFieldDecorator("unit_per_package", {
                          initialValue: data?.unit_per_package,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step={1}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes(
                    "package_width"
                  ) && (
                    <Col md={6}>
                      <Form.Item label="ความกว้างกล่องลังบรรจุ (ซม.)">
                        {getFieldDecorator("package_width", {
                          initialValue: data?.package_width,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step={0.01}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes(
                    "package_length"
                  ) && (
                    <Col md={6}>
                      <Form.Item label="ความยาวกล่องลังบรรจุ (ซม.)">
                        {getFieldDecorator("package_length", {
                          initialValue: data?.package_length,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step={0.01}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes(
                    "package_height"
                  ) && (
                    <Col md={6}>
                      <Form.Item label="ความสูงกล่องลังบรรจุ (ซม.)">
                        {getFieldDecorator("package_height", {
                          initialValue: data?.package_height,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step={0.01}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes(
                    "package_weight"
                  ) && (
                    <Col md={6}>
                      <Form.Item label="น้ำหนักสินค้ารวมกล่องลังบรรจุ (กรัม)">
                        {getFieldDecorator("package_weight", {
                          initialValue: data?.package_weight,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step={0.01}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {(fieldVisible.fetch?.data || []).includes("overlay") && (
                    <Col md={6}>
                      <Form.Item label="สินค้าวางซ้อนได้สูงสุดกี่ลัง">
                        {getFieldDecorator("overlay", {
                          initialValue: data?.overlay,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step={1}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </div>

              {/* cube  */}
              <div>
                <Divider>
                  ปริมาตรของกล่องสินค้า
                  <br />
                  <small>(ใช้ในการคำนวณเรื่องการจัดส่ง)</small>
                </Divider>

                <Row gutter={16}>
                  <Col md={6}>
                    <Form.Item label="กว้าง (ซม.)">
                      {getFieldDecorator("size_width", {
                        initialValue: data?.size_width,
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          step="0.01"
                          min={0}
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item label="ยาว (ซม.)">
                      {getFieldDecorator("size_length", {
                        initialValue: data?.size_length,
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          step="0.01"
                          min={0}
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item label="สูง (ซม.)">
                      {getFieldDecorator("size_height", {
                        initialValue: data?.size_height,
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          step="0.01"
                          min={0}
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      label="น้ำหนัก (กรัม)"
                      help={
                        ["Kit/Package", "Assembly"].includes(
                          data?.product_type_set
                        )
                          ? `น้ำหนักรวมปกติ ${numeral(
                              _.reduce(
                                bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                (sum, n) =>
                                  (sum += (n?.weight || 0) * (n?.qty || 0)),
                                0 + (data.product_type_set == "Kit/Package" ? 100 : 0)
                              )
                            ).format("0,0.00")} กรัม`
                          : false
                      }
                    >
                      {getFieldDecorator("weight", {
                        initialValue: data?.weight,
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุน้ำหนัก",
                          },
                        ],
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          step="0.01"
                          min={0}
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={6}>
                    <Form.Item label="การคำนวณค่าจัดส่ง">
                      {getFieldDecorator("delivery_type", {
                        initialValue: data?.delivery_type,
                        rules: [
                          {
                            required: true,
                            message: "กรุณาเลือกการคำนวณค่าจัดส่ง",
                          },
                        ],
                        onChange: (value) =>
                          dispatch(
                            SET_FORM({ delivery_type: value, shipping_rate: 0 })
                          ),
                      })(
                        <SelectDeliveryType
                          className="w-100 text-dark"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {data?.delivery_type === "other" && (
                    <Col md={6}>
                      <Form.Item label="ค่าจัดส่ง">
                        {getFieldDecorator("shipping_rate", {
                          initialValue: data?.shipping_rate,
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </div>

              {/* Point */}
              {["Sale", "Service", "Other"].includes(
                data?.distribution_type
              ) && (
                <div>
                  <Divider>ข้อมูลคะแนน</Divider>

                  <Typography.Text strong>
                    คะแนนที่นักธุรกิจและVIP Member จะได้รับเมื่อซื้อสินค้า
                    <Popover
                      content={
                        <p className="mb-0">
                          - กรณีนักธุรกิจสั่งซื้อ จะได้รับ คะแนน PV , CV , RP
                          และ RA (ถ้ามี) <br />- กรณี VIP Member สั่งซื้อ
                          จะได้รับคะแนน RP เท่านั้น
                        </p>
                      }
                    >
                      <FaInfoCircle className="ml-2 text-info" />
                    </Popover>
                  </Typography.Text>
                  <Row gutter={16}>
                    <Col md={4}>
                      <Form.Item label="PV">
                        {getFieldDecorator("point_01", {
                          initialValue: data?.point_01,
                          onChange: (target_value) => {
                            const value = target_value || 0; // หาก value เป็น "" หรือ null จะให้ค่าเป็น 0
                            dispatch(
                              SET_FORM({ point_01: value, point_03: value })
                            );
                            props.form.setFieldsValue({ point_03: value });
                          },
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className={`w-100 ${
                              parseFloat(data?.point_01) === 0
                                ? "bg-danger text-white"
                                : "text-dark"
                            }`}
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {/* <Col md={6}>
                      <Form.Item label="CV">
                        {getFieldDecorator("point_03", {
                          initialValue: data?.point_03,
                          onChange: (value) => {
                            dispatch(SET_FORM({ point_03: value }));
                          },
                        })(
                          <InputNumber
                            className={`w-100 ${
                              parseFloat(data?.point_03) === 0
                                ? "bg-danger text-white"
                                : ""
                            }`}
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col> */}
                    <Col md={4}>
                      <Form.Item label="RP">
                        {getFieldDecorator("point_02", {
                          initialValue: data?.point_02,
                          onChange: (value) => {
                            dispatch(SET_FORM({ point_02: value || 0 }));
                          },
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className={`w-100 ${
                              parseFloat(data?.point_02) === 0
                                ? "bg-danger text-white"
                                : "text-dark"
                            }`}
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item label="RA">
                        {getFieldDecorator("point_06", {
                          initialValue: data?.point_06,
                          onChange: (value) => {
                            dispatch(SET_FORM({ point_06: value || 0 }));
                          },
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className={`w-100 ${
                              parseFloat(data?.point_06) === 0
                                ? "bg-danger text-white"
                                : "text-dark"
                            }`}
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item label="BP">
                        {getFieldDecorator("point_05", {
                          initialValue: data?.point_05,
                          onChange: (value) => {
                            dispatch(SET_FORM({ point_05: value || 0 }));
                          },
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className={`w-100 ${
                              parseFloat(data?.point_05) === 0
                                ? "bg-danger text-white"
                                : "text-dark"
                            }`}
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {["PT08", "PT10"].includes(data?.product_type) && (
                    <Col md={4}>
                      <Form.Item label="TP">
                        {getFieldDecorator("point_07", {
                          initialValue: data?.point_07,
                          onChange: (value) => {
                            dispatch(SET_FORM({ point_07: value || 0 }));
                          },
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className={`w-100 ${
                              parseFloat(data?.point_07) === 0
                                ? "bg-danger text-white"
                                : "text-dark"
                            }`}
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    )}
                     <Col md={4}>
                      <Form.Item label="EP">
                        {getFieldDecorator("point_09", {
                          initialValue: data?.point_09,
                          onChange: (value) => {
                            dispatch(SET_FORM({ point_09: value || 0 }));
                          },
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className={`w-100 ${
                              parseFloat(data?.point_09) === 0
                                ? "bg-danger text-white"
                                : "text-dark"
                            }`}
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  {!["PT08", "PT10"].includes(data?.product_type) && (
                    <>
                      <Typography.Text strong>
                        คะแนนพิเศษที่นักธุรกิจผู้แนะนำจะได้รับจากการสั่งซื้อของ
                        VIP Member
                        <Popover
                          content={
                            <p className="mb-0">
                              กรณีที่ VIP Member มีการสั่งซื้อสินค้า
                              นักธุรกิจที่เป็นผู้แนะนำของ VIP Member ท่านนั้น
                              <br />
                              จะได้รับคะแนน PV และ CV ตามที่กำหนด
                              โดยคะแนนจะเข้าระบบให้ผู้แนะนำเป็นคะแนน Hold
                              <br />
                              ภายใน 7 วันหลังจากวันที่ VIP Member
                              มีการสั่งซื้อสินค้า
                            </p>
                          }
                        >
                          <FaInfoCircle className="ml-2 text-info" />
                        </Popover>
                      </Typography.Text>
                      <Row gutter={16}>
                        <Col md={6}>
                          <Form.Item label="PV">
                            {getFieldDecorator("r_point_01", {
                              initialValue: data?.r_point_01,
                              onChange: (target_value) => {
                                const value  = target_value || 0
                                dispatch(
                                  SET_FORM({
                                    r_point_01: value,
                                    r_point_03: value,
                                  })
                                );
                                props.form.setFieldsValue({
                                  r_point_03: value,
                                });
                              },
                              normalize : (value) => {
                                let sanitizedValue = value || 0
                                return sanitizedValue
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.r_point_01) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        {/* <Col md={6}>
                          <Form.Item label="CV">
                            {getFieldDecorator("r_point_03", {
                              initialValue: data?.r_point_03,
                              onChange: (value) => {
                                dispatch(SET_FORM({ r_point_03: value }));
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.r_point_03) === 0
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                                step="0.01"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col> */}
                        {/* <Col md={6}>
                          <Form.Item label="RP">
                            {getFieldDecorator("r_point_02", {
                              initialValue: data?.r_point_02,
                              onChange: (value) => {
                                dispatch(SET_FORM({ r_point_02: value }));
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.r_point_02) === 0
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                                step="0.01"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col> */}
                        <Col md={6}>
                          <Form.Item label="RA">
                            {getFieldDecorator("r_point_06", {
                              initialValue: data?.r_point_06,
                              onChange: (value) => {
                                dispatch(SET_FORM({ r_point_06: value || 0 }));
                              },
                              normalize : (value) => {
                                let sanitizedValue = value || 0
                                return sanitizedValue
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.r_point_06) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item label="BP">
                            {getFieldDecorator("r_point_05", {
                              initialValue: data?.r_point_05,
                              onChange: (value) => {
                                dispatch(SET_FORM({ r_point_05: value || 0 }));
                              },
                              normalize : (value) => {
                                let sanitizedValue = value || 0
                                return sanitizedValue
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.r_point_05) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item label="EP">
                            {getFieldDecorator("r_point_09", {
                              initialValue: data?.r_point_09,
                              onChange: (value) => {
                                dispatch(SET_FORM({ r_point_09: value || 0 }));
                              },
                              normalize : (value) => {
                                let sanitizedValue = value || 0
                                return sanitizedValue
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.r_point_09) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  {data?.product_type === "PT05" && (
                    <>
                      <Typography.Text strong>
                        คะแนนพิเศษที่นักธุรกิจ(ผู้แนะนำ)จะได้รับ เมื่อ VIP
                        Member ซื้อสินค้า Dealer Product แบบเดี่ยว
                        <Popover
                          content={
                            <p className="mb-0">
                              VIP Member จะไม่สามารถสั่งซื้อสินค้ากลุ่ม Dealer
                              Product แบบเป็นแพคได้
                              <br />
                              สินค้า Dealer Product
                              แบบแพคสงวนสิทธิ์จำหน่ายให้กับนักธุรกิจเท่านั้น
                              <br />
                              ดังนั้นเมื่อ VIP Member มีการสั่งซื้อสินค้ากลุ่ม
                              Dealer Product แบบกล่องเดี่ยว
                              <br />
                              นักธุรกิจที่เป็นผู้แนะนำของ VIP Member ท่านนั้น
                              จะได้รับคะแนน RP
                              ที่คำนวณออกมาเป็นยอดส่วนต่างจากการซื้อแบบแพค
                              <br />
                              แต่นักธุรกิจที่เป็นผู้แนะนำต้องมีประวัติการสั่งซื้อสินค้า
                              Dealer Product แบบแพคด้วย
                              ถึงจะได้รับคะแนนในส่วนนี้
                              <br />
                              ซึ่งคะแนน RP จะได้รับ ภายใน 7 วัน
                              <br />
                              หลังจากวันที่ VIP Member มีการสั่งซื้อสินค้า
                              โดยเข้าเป็นคะแนน RP พร้อมโอน
                            </p>
                          }
                        >
                          <FaInfoCircle className="ml-2 text-info" />
                        </Popover>
                      </Typography.Text>
                      <Row gutter={16}>
                        <Col md={6}>
                          <Form.Item label="PV">
                            {getFieldDecorator("d_point_01", {
                              initialValue: data?.d_point_01,
                              onChange: (target_value) => {
                                const value = target_value || 0
                                dispatch(
                                  SET_FORM({
                                    d_point_01: value,
                                    d_point_03: value,
                                  })
                                );
                                props.form.setFieldsValue({
                                  d_point_03: value,
                                });
                              },
                              normalize : (value) => {
                                let sanitizedValue = value || 0
                                return sanitizedValue
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.d_point_01) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        {/* <Col md={6}>
                          <Form.Item label="CV">
                            {getFieldDecorator("d_point_03", {
                              initialValue: data?.d_point_03,
                              onChange: (value) => {
                                dispatch(SET_FORM({ d_point_03: value }));
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.d_point_03) === 0
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                                step="0.01"
                                min={0}
                              />
                            )}
                          </Form.Item>
                        </Col> */}
                        <Col md={6}>
                          <Form.Item label="RP">
                            {getFieldDecorator("d_point_02", {
                              initialValue: data?.d_point_02,
                              onChange: (value) => {
                                dispatch(SET_FORM({ d_point_02: value || 0}));
                              },
                              normalize : (value) => {
                                let sanitizedValue = value || 0
                                return sanitizedValue
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.d_point_02) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item label="EP">
                            {getFieldDecorator("d_point_09", {
                              initialValue: data?.d_point_09,
                              onChange: (value) => {
                                dispatch(SET_FORM({ d_point_09: value || 0 }));
                              },
                              normalize : (value) => {
                                let sanitizedValue = value || 0
                                return sanitizedValue
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.d_point_09) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        {/* <Col md={6}>
                          <Form.Item label="CD">
                            {getFieldDecorator("d_point_08", {
                              initialValue: data?.d_point_08,
                              onChange: (value) => {
                                dispatch(SET_FORM({ d_point_08: value }));
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.d_point_08) === 0
                                    ? "bg-danger text-white"
                                    : "text-dark"
                                }`}
                                step="0.01"
                                min={0}
                              />
                            )}
                          </Form.Item>
                        </Col> */}
                        {/* <Col md={6}>
                          <Form.Item label="RA">
                            {getFieldDecorator("d_point_06", {
                              initialValue: data?.d_point_06,
                              onChange: (value) => {
                                dispatch(SET_FORM({ d_point_06: value }));
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.d_point_06) === 0
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                                step="0.01"
                                min={0}
                              />
                            )}
                          </Form.Item>
                        </Col> */}
                      </Row>
                    </>
                  )}
                  {["PT08", "PT10"].includes(data?.product_type) && (
                    <Row gutter={16}>
                      <Col md={8}>
                        <Form.Item label="จำนวนครั้งการแจง (ครั้ง)">
                          {getFieldDecorator("split_pv", {
                            initialValue: data?.split_pv,
                            rules: [
                              {
                                message: "กรุณาระบุจำนวนครั้งการแจง",
                                min: 1,
                                type: "number",
                              },
                            ],
                          })(
                            <InputNumber className="w-100 text-dark" min={0} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </div>
              )}

              {/* Price */}
              <div>
                <Divider>
                  ข้อมูลราคา
                  <br />
                  <small>
                    {["PT08", "C05", "PT02", "PT06"].includes(
                      data?.product_type
                    )
                      ? "(สินค้าชุดเซ็ต)"
                      : "(ราคาแบบสินค้าเดี่ยว)"}
                  </small>
                </Divider>

                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item
                      label={`${
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? "ราคาโปรโมชั่นสำหรับนักธุรกิจ"
                          : "ราคาสำหรับนักธุรกิจ"
                      } (บาท)`}
                      help={
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? `ราคารวมปกติ ${numeral(
                              _.reduce(
                                bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                (sum, n) =>
                                  (sum +=
                                    (n?.distributor_price || 0) *
                                    (n?.qty || 0)),
                                0
                              )
                            ).format("0,0.00")} บาท`
                          : false
                      }
                    >
                      {getFieldDecorator("distributor_price", {
                        initialValue: data?.distributor_price,
                        rules: [
                          {
                            message: `กรุณาระบุ${
                              ["PT08", "C05", "PT02", "PT06"].includes(
                                data?.product_type
                              )
                                ? "ราคาโปรโมชั่นสำหรับนักธุรกิจ"
                                : "ราคาสำหรับนักธุรกิจ"
                            }`,
                            min: 0,
                            type: "number",
                          },
                        ],
                        normalize : (value) => {
                          let sanitizedValue = value || 0
                          return sanitizedValue
                        },
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          min={0}
                          step="0.01"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {!["C05"].includes(data?.product_type) && (
                    <Col md={8}>
                      <Form.Item
                        label="ราคาสำหรับเคลม (บาท)"
                        help={
                          ["PT08", "C05", "PT02", "PT06"].includes(
                            data?.product_type
                          )
                            ? `ราคารวมปกติ ${numeral(
                                _.reduce(
                                  bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                  (sum, n) =>
                                    (sum +=
                                      (n?.claim_price || 0) * (n?.qty || 0)),
                                  0
                                )
                              ).format("0,0.00")} บาท`
                            : false
                        }
                      >
                        {getFieldDecorator("claim_price", {
                          initialValue: data?.claim_price,
                          rules: [
                            {
                              message: "กรุณาระบุราคาสำหรับเคลม",
                              min: 0,
                              type: "number",
                            },
                          ],
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  <Col md={8}>
                    <Form.Item
                      label="ราคาสำหรับขายปลีก (บาท)"
                      help={
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? `ราคารวมปกติ ${numeral(
                              _.reduce(
                                bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                (sum, n) =>
                                  (sum +=
                                    (n?.retail_price || 0) * (n?.qty || 0)),
                                0
                              )
                            ).format("0,0.00")} บาท`
                          : false
                      }
                    >
                      {getFieldDecorator("retail_price", {
                        initialValue: data?.retail_price,
                        rules: [
                          {
                            message: "กรุณาระบุราคาสำหรับขายปลีก",
                            min: 0,
                            type: "number",
                          },
                        ],
                        normalize : (value) => {
                          let sanitizedValue = value || 0
                          return sanitizedValue
                        },
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          min={0}
                          step="0.01"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                          help
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {!["PT08"].includes(data?.product_type) &&
                    data?.product_type !== "" && (
                      <Col md={8}>
                        <Form.Item
                          label={`${
                            ["PT08", "C05", "PT02", "PT06"].includes(
                              data?.product_type
                            )
                              ? "ราคาโปรโมชั่นสำหรับ VIP Member"
                              : "ราคาสำหรับ VIP Member"
                          } (บาท)`}
                          help={
                            ["PT08", "C05", "PT02", "PT06"].includes(
                              data?.product_type
                            )
                              ? `ราคารวมปกติ ${numeral(
                                  _.reduce(
                                    bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                    (sum, n) =>
                                      (sum +=
                                        (n?.member_price || 0) * (n?.qty || 0)),
                                    0
                                  )
                                ).format("0,0.00")} บาท`
                              : false
                          }
                        >
                          {getFieldDecorator("member_price", {
                            initialValue: data?.member_price,
                            rules: [
                              {
                                message: `กรุณาระบุ${
                                  ["PT08", "C05", "PT02", "PT06"].includes(
                                    data?.product_type
                                  )
                                    ? "ราคาโปรโมชั่นสำหรับ VIP Member"
                                    : "ราคาสำหรับ VIP Member"
                                }`,
                                min: 0,
                                type: "number",
                              },
                            ],
                            normalize : (value) => {
                              let sanitizedValue = value || 0
                              return sanitizedValue
                            },
                          })(
                            <InputNumber
                              className="w-100 text-dark"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                  <Col md={8}>
                    <Form.Item
                      label={`${
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? "ราคาควบคุมชุดโปรโมชั่น"
                          : "ราคาควบคุม"
                      } (บาท)`}
                      help={
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? `ราคารวมปกติ ${numeral(
                              _.reduce(
                                bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                (sum, n) =>
                                  (sum +=
                                    (n?.control_price || 0) * (n?.qty || 0)),
                                0
                              )
                            ).format("0,0.00")} บาท`
                          : false
                      }
                    >
                      {getFieldDecorator("control_price", {
                        initialValue: data?.control_price,
                        rules: [
                          {
                            message: `กรุณาระบุ${
                              ["PT08", "C05", "PT02", "PT06"].includes(
                                data?.product_type
                              )
                                ? "ราคาควบคุมชุดโปรโมชั่น"
                                : "ราคาควบคุม"
                            } `,
                            min: 0,
                            type: "number",
                          },
                        ],
                        normalize : (value) => {
                          let sanitizedValue = value || 0
                          return sanitizedValue
                        },
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          min={0}
                          step="0.01"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item
                      label={`${
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? "ราคาโปรโมชั่นสำหรับพนักงาน"
                          : "ราคาสำหรับพนักงาน"
                      } (บาท)`}
                      help={
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? `ราคารวมปกติ ${numeral(
                              _.reduce(
                                bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                (sum, n) =>
                                  (sum +=
                                    (n?.staff_price || 0) * (n?.qty || 0)),
                                0
                              )
                            ).format("0,0.00")} บาท`
                          : false
                      }
                    >
                      {getFieldDecorator("staff_price", {
                        initialValue: data?.staff_price,
                        rules: [
                          {
                            message: `กรุณาระบุ${
                              ["PT08", "C05", "PT02", "PT06"].includes(
                                data?.product_type
                              )
                                ? "ราคาโปรโมชั่นสำหรับพนักงาน"
                                : "ราคาสำหรับพนักงาน"
                            } `,
                            min: 0,
                            type: "number",
                          },
                        ],
                        normalize : (value) => {
                          let sanitizedValue = value || 0
                          return sanitizedValue
                        },
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          min={0}
                          step="0.01"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item
                      label="ราคาแนะนำ (บาท)"
                      help={
                        ["PT08", "C05", "PT02", "PT06"].includes(
                          data?.product_type
                        )
                          ? `ราคารวมปกติ ${numeral(
                              _.reduce(
                                bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                (sum, n) =>
                                  (sum += (n?.cost_price || 0) * (n?.qty || 0)),
                                0
                              )
                            ).format("0,0.00")} บาท`
                          : false
                      }
                    >
                      {getFieldDecorator("customer_price", {
                        initialValue: data?.customer_price,
                        normalize : (value) => {
                          let sanitizedValue = value || 0
                          return sanitizedValue
                        },
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          min={0}
                          step="0.01"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {((match.params.id !== "0" && parseFloat(user?.rank) >= 6) ||
                    match.params.id === "0" ||
                    user?.id === data?.create_by_id) && (
                    <>
                      <Col md={8}>
                        <Form.Item
                          label="ราคาต้นทุนไม่รวมภาษี (บาท)"
                          help={
                            ["PT08", "C05", "PT02", "PT06"].includes(
                              data?.product_type
                            )
                              ? `ราคารวมปกติ ${numeral(
                                  _.reduce(
                                    bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                    (sum, n) =>
                                      (sum +=
                                        (n?.cost_price || 0) * (n?.qty || 0)),
                                    0
                                  )
                                ).format("0,0.00")} บาท`
                              : false
                          }
                        >
                          {getFieldDecorator("cost_price", {
                            initialValue: data?.cost_price,
                            normalize : (value) => {
                              let sanitizedValue = value || 0
                              return sanitizedValue
                            },
                          })(
                            <InputNumber
                              className="w-100 text-dark"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item
                          label="ราคาต้นทุนรวมภาษี (บาท)"
                          help={
                            ["PT08", "C05", "PT02", "PT06"].includes(
                              data?.product_type
                            )
                              ? `ราคารวมปกติ ${numeral(
                                  _.reduce(
                                    bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                    (sum, n) =>
                                      (sum +=
                                        (n?.cost_price_inc_vat || 0) *
                                        (n?.qty || 0)),
                                    0
                                  )
                                ).format("0,0.00")} บาท`
                              : false
                          }
                        >
                          {getFieldDecorator("cost_price_inc_vat", {
                            initialValue: data?.cost_price_inc_vat,
                            normalize : (value) => {
                              let sanitizedValue = value || 0
                              return sanitizedValue
                            },
                          })(
                            <InputNumber
                              className="w-100 text-dark"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </div>

              <div>
                <Divider>ส่วนต่างเงินคืน</Divider>
                <Row gutter={16}>
                  <Col md={8}>
                      {data?.product_type == "PT05" ? 
                        <Form.Item label="เงินคืนที่นักธุรกิจจะได้รับ จากการซื้อในรหัสตนเอง (บาท)" >
                          {getFieldDecorator("d_point_08", {
                            initialValue: data?.d_point_08,
                            onChange: (value) => {
                              dispatch(SET_FORM({ d_point_08: value || 0 }));
                            },
                          })(
                            <InputNumber
                              className="w-100 text-dark"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                       : 
                        <Form.Item label="เงินคืนที่นักธุรกิจจะได้รับ จากการซื้อในรหัสตนเอง (บาท)" >
                          {getFieldDecorator("point_04", {
                            initialValue: data?.point_04,
                            onChange: (value) => {
                              dispatch(SET_FORM({ point_04: value || 0 }));
                            },
                            normalize : (value) => {
                              let sanitizedValue = value || 0
                              return sanitizedValue
                            },
                          })(
                            <InputNumber
                              className="w-100 text-dark"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      }
                     
                  </Col>
                  <Col md={8}>
                    <Form.Item label="เงินคืนที่นักธุรกิจผู้แนะนำจะได้รับจากการซื้อของ VIP Member (บาท)" >
                      {getFieldDecorator("r_point_10", {
                        initialValue: data?.r_point_10,
                        onChange: (value) => {
                          dispatch(SET_FORM({ r_point_10: value || 0 }));
                        },
                        normalize : (value) => {
                          let sanitizedValue = value || 0
                          return sanitizedValue
                        },
                      })(
                        <InputNumber
                          className="w-100 text-dark"
                          min={0}
                          step="0.01"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                    {/* <Form.Item label="% ค่าธรรมเนียม ค่าดำเนินการ" >
                      {getFieldDecorator("tax_rate", {
                        initialValue: data?.tax_rate,
                        onChange: (value) =>{
                          dispatch(SET_FORM({ tax_rate: value }));
                        },
                      })(
                      <SelectTaxRefund
                        className="w-100 text-dark"
                          disabled={
                          (match.params.id === "0" ||
                            data?.show_save === 1) === false
                        }
                      />
                      
                      )}
                    </Form.Item> */}
                  </Col>
                  <Col md={8}>
                    <Form.Item label="เงินคืนที่นักธุรกิจจะได้รับ จากลูกค้าที่ซื้อผ่านลิงก์ร้านค้า (บาท)" >
                        {getFieldDecorator("r_point_11", {
                          initialValue: data?.r_point_11,
                          onChange: (value) => {
                            dispatch(SET_FORM({ r_point_11: value || 0 }));
                          },
                          normalize : (value) => {
                            let sanitizedValue = value || 0
                            return sanitizedValue
                          },
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    {/* <Form.Item label="% ภาษีมูลค่าเพิ่ม" >
                      {getFieldDecorator("vat_rate", {
                        initialValue: data?.vat_rate,
                        onChange: (value) =>{
                          dispatch(SET_FORM({ vat_rate: value }));
                        },
                      })(
                      <SelectVatRefund
                        className="w-100 text-dark"
                        disabled={
                          (match.params.id === "0" ||
                            data?.show_save === 1) === false
                        }
                      />
                      
                      )}
                    </Form.Item> */}
                  </Col>
                </Row>
              </div>

              {/* Redeem */}
              {["Redeem"].includes(data?.distribution_type) && (
                <div>
                  <Divider>ข้อมูลคะแนนที่ใช้แลก</Divider>
                  <Typography.Text strong>กรณีแลกคะแนนทั้งหมด</Typography.Text>
                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item label="คะแนนที่ใช้แลกสำหรับ VIP Member (RP)">
                        {getFieldDecorator("member_redeem", {
                          initialValue: data?.member_redeem,
                          onChange: (value) => {
                            dispatch(SET_FORM({ member_redeem: value }));
                          },
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="คะแนนที่ใช้แลกสำหรับนักธุรกิจ (RP)">
                        {getFieldDecorator("distributor_redeem", {
                          initialValue: data?.distributor_redeem,
                          onChange: (value) => {
                            dispatch(SET_FORM({ distributor_redeem: value }));
                          },
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Typography.Text strong>กรณีแลกคะแนน + เงิน</Typography.Text>
                  <Row gutter={16}>
                    <Col md={24}>
                      <Typography.Text className="d-block">
                        สำหรับ VIP Member
                      </Typography.Text>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="คะแนน (RP)">
                        {getFieldDecorator("member_redeem_point", {
                          initialValue: data?.member_redeem_point,
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="เงิน (บาท)">
                        {getFieldDecorator("member_redeem_price", {
                          initialValue: data?.member_redeem_price,
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={24}>
                      <Typography.Text className="d-block">
                        สำหรับนักธุรกิจ
                      </Typography.Text>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="คะแนน (RP)">
                        {getFieldDecorator("distributor_redeem_point", {
                          initialValue: data?.distributor_redeem_point,
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="เงิน (บาท)">
                        {getFieldDecorator("distributor_redeem_price", {
                          initialValue: data?.distributor_redeem_price,
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row> */}
                </div>
              )}

              {/* Remark */}
              <div>
                <Divider className="py-3">หมายเหตุ</Divider>

                <Row gutter={16} className="mb-2">
                  <Col md={8}>
                    <Form.Item label="หมายเหตุ">
                      {getFieldDecorator("remark", {
                        initialValue: data?.remark,
                      })(
                        <Input.TextArea
                          rows={5}
                          className="text-dark"
                          disabled={
                            (match.params.id === "0" ||
                              data?.show_save === 1) === false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Upload */}
              {/* <div>
                <Divider className="py-3">อัปโหลดรูปและวิดีโอ</Divider>

                <Row gutter={16} className="mb-2">
                  <Col md={24}>
                    <ImgUpload />
                    <UploadFileList />
                  </Col>
                </Row>
              </div> */}

              {/* Draft, Send */}
              <Row gutter={16} className="mb-2">
                <Col md={24} className="text-right">
                  {match.params.id === "0" || data?.show_save === 1 ? (
                    <>
                      <Button
                        type="dashed"
                        className="mr-3"
                        onClick={() => handleClickSubmit("draft")}
                      >
                        เอกสารร่าง
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleClickSubmit("send")}
                      >
                        บันทึก
                      </Button>
                    </>
                  ) : null}
                </Col>
              </Row>

              {/* Request by, History */}
              {data?.status !== "" && (
                <div>
                  <Divider className="py-3">
                    ข้อมูลผู้ขอ และ ประวัติการอนุมัติ
                  </Divider>
                  <Row gutter={16} className="mb-2">
                    <Col md={6}>
                      ชื่อผู้ขอ: <strong>{data?.create_user}</strong>
                    </Col>
                    <Col md={6}>
                      แผนก: <strong>{data?.department_name}</strong>
                    </Col>
                    <Col md={6}>
                      ฝ่าย: <strong>{data?.division_name}</strong>
                    </Col>
                    <Col md={6}>
                      วันที่ขอ:{" "}
                      <strong>
                        {moment(data?.create_date).format("DD/MM/YYYY HH:mm")}
                      </strong>
                    </Col>
                  </Row>

                  <Table
                    size="small"
                    dataSource={flow_history || []}
                    pagination={false}
                  >
                    <Table.Column
                      title="วันที่"
                      dataIndex="approve_date"
                      align="center"
                      render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
                    />
                    <Table.Column title="โดย" dataIndex="approve_by" />
                    <Table.Column
                      title="สถานะ"
                      dataIndex="approve_type"
                      align="center"
                    />
                    <Table.Column title="หมายเหตุ" dataIndex="reason" />
                  </Table>
                </div>
              )}
            </Form>
          </Spin>
        </PanelBody>
      </Panel>
      <ModalProduct />
      <ModalCloneProduct
        visible={showClone}
        onCancel={() => setShowClone(false)}
        onSelect={handleCloneItem}
      />
    </>
  );
};

export default Form.create("product-request")(RequestForm);
