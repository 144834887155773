import React from 'react';
import { Route, Switch } from 'react-router-dom';
import requireAuth from '../layouts/authenticate.jsx';
import Arrange from './arrange2/routes';
// import Arrange from "./arrange";
import Bill from './bill2/routes';
import BossRoute from './boss/routes';
import ClaimRoute from './claim2/routes';
import Commission from './commission';
import Delivery from './delivery';
import Error404 from './Error404';
import Ethics from './ethics/routes';
import Home from './Home';
import InterfaceRoute from './interface/routes';
import InventoryCount from './inventory-count/routes';
import LegalRoute from './legal/routes';
import Login from './Login';
import PosRoute from './pos/routes';
import PublicRoute from './public';
import ReportRoute from './reports/routes';
import Reserve from './reserve';
import SettingRoute from './setting/routes';
import PreOrder from './pre-order/routes';
import TransferReceive from './transfer-receive/routes';
import StoreQrRoute from './store-qr/routes';
import RequisitionRoute from './requisition/routes';
import News from './news';
import MarketingRoute from './marketing/routes';
import ManageWebsite from './manage-website/routes';
import FitStudioRoute from './fit-studio/routes';
import ProductCategory from './product-category';
import MemberRoute from './member/routes';
import LandingRoute from './landing/routes.js';
import BlackListRoute from './blacklist/router.js';
import VoucherDetails from './voucher-details';
// import Upload from "./upload-partner";
import Upload from './legal-form';
import { useMunuGuard } from '../../hooks/menu-guard.js';
import { useSelector } from 'react-redux';
import { ResetPassword } from './ResetPassword.js';
// import Transfer from "./transfer/routes";
// import Receive from "./receive/routes";
// import DeliveryRoute from './delivery/routes';
// import Adjustment from "./adjustment/routes";
// import Warehouse from "./warehouse/routes";
import PaymentRoute from './payment/routes';
import EventsRoute from './events/routes';
import LobRoute from './lob/routes';
import DigitalProductRoute from './digital-product/routes';
import CreditNoteRoute from './credit-note/router.js';
import NotificationDettingRoute from './notification-setting/router.js';
import QueationaireDettingRoute from './queationaire/router.js';
import TravelPoint from './travel-point/travel-point.js';
import TrackingRoute from './tracking/routes';
import LegacyVerse from './legacy-verse/routes.js';


export default () => {
  const loading = useSelector((state) => state.menu.fetch.loading);
  const error = useSelector((state) => state.menu.fetch.error);
  const permission = useSelector((state) => state.menu.permission);
  useMunuGuard();
  if (loading || error) return null;
  if (!permission)
    return (
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/payment" component={PaymentRoute} />
        <Route path="/public" component={PublicRoute} />
      </Switch>
    );

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/payment" component={PaymentRoute} />
      <Route path="/public" component={PublicRoute} />
      <Route path="/reset-password" component={requireAuth(ResetPassword)} />
      <Route path="/dashboard" exact component={requireAuth(Home)} />
      <Route
        path="/setting/master/product-category"
        exact
        component={requireAuth(ProductCategory)}
      />
      <Route path="/setting" component={requireAuth(SettingRoute)} />
      <Route path="/pos" component={requireAuth(PosRoute)} />
      <Route path="/boss" component={requireAuth(BossRoute)} />
      <Route path="/delivery" component={requireAuth(Delivery)} />
      <Route path="/arrange" component={requireAuth(Arrange)} />
      <Route path="/reserve" component={requireAuth(Reserve)} />
      <Route path="/ethics" component={requireAuth(Ethics)} />
      <Route path="/claim" component={requireAuth(ClaimRoute)} />
      <Route path="/interface" component={requireAuth(InterfaceRoute)} />
      <Route path="/bill" component={requireAuth(Bill)} />
      <Route path="/report" component={requireAuth(ReportRoute)} />
      <Route path="/legal" component={requireAuth(LegalRoute)} />
      <Route path="/commission" component={requireAuth(Commission)} />
      <Route path="/inventory-count" component={requireAuth(InventoryCount)} />
      <Route path="/pre-order" component={requireAuth(PreOrder)} />
      <Route path="/store-qr" component={requireAuth(StoreQrRoute)} />
      <Route path="/requisition" component={requireAuth(RequisitionRoute)} />
      <Route
        path="/transfer-receive"
        component={requireAuth(TransferReceive)}
      />
      <Route path="/news" component={requireAuth(News)} />
      <Route path="/marketing" component={requireAuth(MarketingRoute)} />
      <Route path="/manage-website" component={requireAuth(ManageWebsite)} />
      <Route path="/fit-studio" component={requireAuth(FitStudioRoute)} />
      {/* <Route path="/upload" component={Upload} /> */}
      <Route path="/legal-form" component={requireAuth(Upload)} />
      <Route path="/member" component={requireAuth(MemberRoute)} />
      <Route path="/landing" component={requireAuth(LandingRoute)} />
      <Route path="/blacklist" component={requireAuth(BlackListRoute)} />
      <Route path="/events" component={requireAuth(EventsRoute)} />
      <Route path="/voucher-details" component={requireAuth(VoucherDetails)} />
      <Route path="/lob" component={requireAuth(LobRoute)} />
      <Route
        path="/digital-product"
        component={requireAuth(DigitalProductRoute)}
      />
      <Route path="/credit-note" component={requireAuth(CreditNoteRoute)} />
      <Route
        path="/notification-setting"
        component={requireAuth(NotificationDettingRoute)}
      />
      <Route
        path="/questionaire"
        component={requireAuth(QueationaireDettingRoute)}
      />
      <Route path="/travel-point" component={requireAuth(TravelPoint)} />
      {/* <Route path="/delivery" component={requireAuth(DeliveryRoute)} /> */}
      {/* <Route path="/adjust" component={requireAuth(Adjustment)} /> */}
      {/* <Route path="/warehouse" component={requireAuth(Warehouse)} /> */}
      <Route path="/tracking" component={requireAuth(TrackingRoute)} />
      <Route path="/legacy-verse" component={requireAuth(LegacyVerse)} />
      <Route component={Error404} />
    </Switch>
  );
};
