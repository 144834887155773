/* eslint-disable no-unused-vars */
import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";
import { _ } from "../../../../../../../utils";

const initFile = {
  azure_url: null,
  file_type: null,
  is_approve: null,
};

export const initState = {
  loading: false,
  error: null,
  memberType: "member",
  data: {
    partner_code: null,
    ref_id: null,
    ref_type: null,
  },
  member: [],
  memberProfile: [],
  memberContact: [],
  marriedProfile: [],
  marriedContact: [],
  point: {
    accumulate_pv: 0,
    current_pv: 0,
    current_rp_topup: 0,
    current_rp_hold: 0,
    current_rp_reserve: 0,
  },
  file: {
    document: { ...initFile },
    id_card: { ...initFile },
    book_bank: { ...initFile },
  },
  memberInfo : {}
};

export const reducer = createReducer(initState, {
  setState: (state, { payload: { name, value } }) => {
    state[name] = value;
  },
  initData: (state, { payload }) => {
    state.data = payload;
    state.loading = initState.loading;
    state.error = initState.error;
    state.memberType = initState.memberType;
    state.member = [];
    state.memberProfile = [];
    state.memberContact = [];
    state.marriedProfile = [];
    state.marriedContact = [];
    state.point = { ...initState.point };
    state.file = _.cloneDeep(initState.file);
    state.memberInfo = {}
  },
  loadInfo: (state, { payload }) => {
    const { member, member_contact, member_profile, file } = payload;
    if (state.data.ref_type === "Distributor") {
      const { point, married_contact, married_profile } = payload;
      state.point = point;
      state.marriedContact = married_contact;
      state.marriedProfile = married_profile;
    }
    if(state.data.ref_type === "Member"){
      const { point, member_info} = payload;
      state.point = point;
      state.memberInfo = member_info
    }
    state.loading = false;
    state.member = member;
    state.file = file;
    state.memberContact = member_contact;
    state.memberProfile = member_profile;
  },
});

const InfoContext = createContext();
export default InfoContext;
