/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { axios, URL_API, _ } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { Creators } from "../ducks/operation-form";

const CheckStockBalance = () => {
  const dispatch = useDispatch();
  const [source, setSource] = useState(null);
  const { type: type_params } = useParams();
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );
  const isPreProduct = _.includes(["pre-sale", "pre-redeem"], type_params);

  const { partner_type, req_receipt_type, receipt_branch_code, order_item } =
    useSelector((state) => {
      const {
        orders: { activeKey, itemByKey },
      } = state.pos.operation;
      return {
        partner_type: itemByKey[activeKey]?.partner_type.toLowerCase() || null,
        req_receipt_type: itemByKey[activeKey]?.req_receipt_type || null,
        receipt_branch_code: itemByKey[activeKey]?.receipt_branch_code || null,
        order_item: itemByKey[activeKey]?.items || null,
      };
    });

  const setOrderItem = (e) => dispatch(Creators.setOrderItem(e));
  const setLoading = (e) =>
    dispatch(Creators.setLoading({ check_stock_balance: e }));

  const handleCheckStockInOrder = async () => {
    if (!order_item) return;
    setLoading(true);
    try {
      const { itemKeys, itemByKey } = order_item;
      const ids = _.join(
        _.map(itemKeys, (key) => itemByKey[key].product_id),
        ","
      );
      const {
        data: { data },
      } = await axios({
        url: `${URL_API}/pos/master/item/check-stock-balance`,
        params: {
          ids,
          type_params,
          partner_type,
          req_receipt_type: req_receipt_type === 'Lowcost' ? 'Transport' : req_receipt_type,
          receipt_branch_code :  receipt_branch_code,
          product_type: isNormal ? "normal" : "redeem",
        },
        cancelToken: source.token,
      });
      const result = _.reduce(
        itemKeys,
        (res, key) => {
          const { product_id } = itemByKey[key];
          const stock_balance =
            _.find(data, (n) => n.id === product_id)?.stock_balance || 0;
          return {
            ...res,
            [key]: {
              ...itemByKey[key],
              stock_balance,
            },
          };
        },
        {}
      );
      setOrderItem(result);
    } catch (err) {
      resCatch(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      !isPreProduct &&
      (_.includes(["Transport","Lowcost"],req_receipt_type) || receipt_branch_code)
    ) {
      source && source.cancel("cancel");
      setSource(axios.CancelToken.source());
    }
  }, [isPreProduct, partner_type, req_receipt_type, receipt_branch_code]);

  useEffect(() => {
    source && handleCheckStockInOrder();
  }, [source]);
};

export default CheckStockBalance;
