import { Input, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import Layout from "./Layout";

const { setFormValue } = actionRequisition;

const Reason = ({ disabled }) => {
  const dispatch = useDispatch();
  const reason = useSelector(
    (state) => state.requisition.documentDetail.reason
  );
  const reason_error = useSelector((state) => state.requisition.error.reason);

  return (
    <Row gutter={[8, 8]}>
      <Layout title="วัตถุประสงค์การเบิก :" size="large" error={reason_error}>
        {disabled ? (
          <Typography.Text>{reason}</Typography.Text>
        ) : (
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 4 }}
            maxLength={500}
            value={reason}
            onChange={(e) =>
              dispatch(
                setFormValue({
                  name: "documentDetail",
                  value: { reason: e.target.value },
                  error_name: "reason",
                })
              )
            }
          />
        )}
      </Layout>
    </Row>
  );
};

export default memo(Reason);
