import React from 'react';
import { Modal } from 'antd';
import QRCode from 'qrcode.react';

const EventQrCodeModal = ({ open, handleClose, event }) => {
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById('qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');

    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `qr_code.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <Modal
      title={'Qr code'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ปิด"
      onOk={downloadQRCode}
      width={'30vw'}
    >
      <div>
        {event && (
          <QRCode
            id="qrCodeEl"
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            title={event.title}
            value={`https://www.legacy.co.th/th/event/?eventId=${event.id}`}
            viewBox={`0 0 128 128`}
          />
        )}
      </div>
    </Modal>
  );
};

export default EventQrCodeModal;
