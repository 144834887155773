import { Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getUserObj } from "../../../../utils";
import { Creators } from "../ducks/operation-form";

const PosSidebar = ({ ...props }) => {
  const user = getUserObj();

  const handleOnClickLink = ({ url = "", step = 1, clear = false }) => {
    if (clear) props.clear();

    props.changeStep(step);
    props.history.replace(url);
  };

  const handleClickMenu = () => {
    props.clear();
    props.changeStep(1);
  };

  let color = "pv";
  switch (props.match.params.type) {
    case "redeem":
      color = "rp";
      break;
    case "subscription":
      color = "tp";
      break;
    case "reserve":
      color = "cv";
      break;
    case "pre-sale":
      color = "sp";
      break;
    case "pre-redeem":
      color = "nn";
      break;
    default:
      color = "pv";
      break;
  }

  return (
    <div style={{ zIndex: 3 }}>
      <div
        className="bg-nn d-flex justify-content-center align-items-center"
        style={{
          height: "6vh",
          fontWeight: "bolder",
          fontSize: "18px",
          width: "calc(100vh / 6 + 40px)",
        }}
      >
        <span className="text-white">{user.branch_name}</span>
      </div>
      <div className="pos-sidebar">
        <Link to="/pos/sale/operator" replace onClick={handleClickMenu}>
          <button
            type="button"
            className={`btn-sidebar-sm d-flex justify-content-center align-items-center pv ${
              props.match.params.type === "sale" ? "active" : ""
            }`}
          >
            <Icon type="shopping-cart" /> <span>Sale</span>
          </button>
        </Link>
        <Link to="/pos/redeem/operator" replace onClick={handleClickMenu}>
          <button
            type="button"
            className={`btn-sidebar-sm d-flex justify-content-center align-items-center rp ${
              props.match.params.type === "redeem" ? "active" : ""
            }`}
          >
            <Icon type="gift" /> <span>Redeem</span>
          </button>
        </Link>
        <Link to="/pos/subscription/operator" replace onClick={handleClickMenu}>
          <button
            type="button"
            className={`btn-sidebar-sm d-flex justify-content-center align-items-center tp ${
              props.match.params.type === "subscription" ? "active" : ""
            }`}
          >
            <Icon type="shopping" /> <span>Book Sale</span>
          </button>
        </Link>
        {/* <Link to="/pos/reserve/operator" replace onClick={handleClickMenu}>
          <button
            type="button"
            className={`btn-sidebar-sm d-flex justify-content-center align-items-center cv ${
              props.match.params.type === "reserve" ? "active" : ""
            }`}
          >
            <Icon type="crown" /> <span>Book Redeem</span>
          </button>
        </Link> */}

        {/* comment for phase 1 */}
        {/* <Link to="/pos/pre-sale/operator" replace onClick={handleClickMenu}>
          <button
            type="button"
            className={`btn-sidebar-sm d-flex justify-content-center align-items-center sp ${
              props.match.params.type === "pre-sale" ? "active" : ""
            }`}
          >
            <i>
              <CgShoppingBag size={22} />
            </i>
            <span>Pre Sale</span>
          </button>
        </Link>
        <Link to="/pos/pre-redeem/operator" replace onClick={handleClickMenu}>
          <button
            type="button"
            className={`btn-sidebar-sm d-flex justify-content-center align-items-center nn ${
              props.match.params.type === "pre-redeem" ? "active" : ""
            }`}
          >
            <i>
              <MdRedeem />
            </i>
            <span>Pre Redeem</span>
          </button>
        </Link> */}

        <hr className="hr" />

        <button
          type="button"
          className={`btn-sidebar-lg ${color} ${
            props.step === 1 ? "active" : ""
          }`}
          onClick={() =>
            handleOnClickLink({
              url: `/pos/${props.match.params.type}`,
              clear: true,
            })
          }
        >
          <Icon type="user" style={{ fontSize: "3em" }} />
          <p>ผู้ดำเนินการ</p>
        </button>
        <button
          type="button"
          className={`btn-sidebar-lg ${color} ${
            props.step === 2 ? "active" : ""
          }`}
          disabled={props.step < 2}
          onClick={() =>
            handleOnClickLink({
              url: `/pos/${props.match.params.type}/buyer`,
              step: 2,
            })
          }
        >
          <Icon type="reconciliation" style={{ fontSize: "3em" }} />
          <p>สั่งซื้อ</p>
        </button>
        <button
          type="button"
          className={`btn-sidebar-lg ${color} ${
            props.step === 3 ? "active" : ""
          }`}
          disabled={props.step < 3}
          onClick={() =>
            handleOnClickLink({
              url: `/pos/${props.match.params.type}/payment`,
              step: 3,
            })
          }
        >
          <Icon type="credit-card" style={{ fontSize: "3em" }} />
          <p>ชำระเงิน</p>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    step: state.pos.operation.step,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    changeStep: Creators.changeStep,
    clear: Creators.clear,
  })(PosSidebar)
);
