import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import BannerForm from '../form/BannerForm';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { bannerFormDefaultValues } from '../dto/banner';

const BannerModal = ({ open, handleClose }) => {
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createBanner } = useAsync(fitStudioService.createBanner, {
    onSuccess: () => {
      AlertSuccess('เพิ่มแบนเนอร์สำเร็จ');
      reset(bannerFormDefaultValues);
      handleClose();
    },
  });

  const { execute: editBanner } = useAsync(fitStudioService.editBanner, {
    onSuccess: () => {
      AlertSuccess('แก้ไขแบนเนอร์สำเร็จ');
      reset(bannerFormDefaultValues);
      handleClose();
    },
  });

  const _HandleSubmit = handleSubmit((data) => {
    const bannerData = {
      callBackUrl: data.callBackUrl,
      callBackUrlType: data.callBackUrlType,
      imageUrl: data.imageUrl,
      type: data.type,
      start_date: new Date(data.start_date) ,
      end_date: new Date(data.end_date),
    };
    if (watch('formType') === 'create') {
      createBanner(bannerData);
    } else if (watch('formType') === 'edit') {
      editBanner({ id: data.id, data: bannerData });
    }
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'สร้างแบนเนอร์' : 'แก้ไขแบนเนอร์'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <BannerForm />
    </Modal>
  );
};

export default BannerModal;
