import { Input, notification } from "antd";
import numeral from "numeral";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SelectBank from "../../../../components/SelectBank";
import { _ } from "../../../../utils";
import SelectPayIn from "../components/SelectPayIn";
import { Creators } from "../ducks/operation-form";
import { getSummaryTotalPayment } from "./logic";

const PaymentAdd = (props) => {
  const amountRef = useRef(null);
  const [type, setType] = useState("");
  const [typeName, setTypeName] = useState("");
  const [amount, setAmount] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const balance = _.max([0, props.balance_total - props.payment_total]);
  const return_price = props.payment_total - props.balance_total;
  const type_params = props.match.params.type;
  const isRedeemReserve = _.includes(["reserve", "pre-redeem"], type_params);

  const handleClickAddPayment = () => {
    if (type !== "" && amount !== "") {
      const pay_in_type = type.toLowerCase();
      if (pay_in_type !== "cash" && bankCode?.trim() === "")
        return notification.warning({
          message: "ไม่สามารถเพิ่มรายการชำระได้",
          description: "กรุณาเลือกธนาคาร",
          placement: "bottomRight",
        });

      if ((pay_in_type !== "cash" && balance < +amount) || balance === 0) {
        notification.warning({
          message: "ไม่สามารถเพิ่มรายการชำระได้",
          description: "จำนวนเงินไม่ถูกต้อง",
          placement: "bottomRight",
        });
      } else {
        props.addPayment({
          pay_in_type: type,
          pay_in_type_name: typeName,
          amount,
          bank_code: bankCode,
          bank_name: bankName || "",
        });
        setType("");
        setBankCode("");
        setBankName("");
        setAmount("");
        amountRef.current.focus();
      }
    }
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-6 d-flex align-items-center">
          <h5 className="m-0 text-white">ประเภทการชำระเงิน</h5>
        </div>
        <div className="col-6">
          <SelectPayIn
            allowClear
            className="w-100"
            value={type ? type : undefined}
            onChange={(value, option) => {
              setType(value || "");
              setTypeName(option?.props.children || "");

              setBankCode("");
              setBankName("");
            }}
          />
        </div>
      </div>

      {type !== "Cash" && (
        <div className="row mb-2">
          <div className="col-6 d-flex align-items-center">
            <h5 className="m-0 text-white">ธนาคาร</h5>
          </div>
          <div className="col-6">
            <SelectBank
              showSearch
              allowClear
              withCashCard={false}
              value={bankName}
              className="w-100"
              onChange={(e, option) => {
                setBankCode(e || "");
                setBankName(option?.props.source.name_th);
              }}
              withIcon={true}
              filterOption={(input, option) =>
                option.props.source.name_th.indexOf(input.toLowerCase()) >= 0
              }
            />
          </div>
        </div>
      )}

      <div className="row mb-4">
        <div className="col-6 d-flex align-items-center">
          <h5 className="m-0 text-white">จำนวนเงิน</h5>
        </div>
        <div className="col-6">
          <Input
            allowClear
            value={amount}
            onChange={(e) => {
              // const value = +_.replace(e.target.value, /[^\d]+/, "");
              const { value } = e.target;
              if (!value.match(/^[0-9]*\.?[0-9]{0,2}$/)) return;
              if (!isRedeemReserve || +value <= props.balance_total) {
                setAmount(value || "");
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleClickAddPayment();
            }}
            ref={amountRef}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col text-right">
          <button
            type="button"
            className="pos-btn bg-black text-white text-nowrap"
            onClick={handleClickAddPayment}
          >
            Add Payment
          </button>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <h5 className="m-0 text-white">เงินทอน</h5>
        </div>
        <div className="col">
          <h3 className="m-0 text-right text-white">
            {/* {numeral(props.payment_total > props.summary_total && props.cash_total > (props.payment_total - props.summary_total) ? props.cash_total - (props.payment_total - props.summary_total) : 0).format('0,0.00')} */}
            {/* {numeral(Math.max(0, props.cash_total - (props.summary_total - props.payment_total))).format('0,0.00')} */}
            {numeral(
              Math.max(
                0,
                return_price > props.cash_total
                  ? props.cash_total
                  : return_price
              )
            ).format("0,0.00")}
          </h3>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col d-flex align-items-center">
          <h5 className="m-0 text-white">คงเหลือ</h5>
        </div>
        <div className="col">
          <h3 className="m-0 text-right text-white">
            {numeral(balance).format("0,0.00")}
          </h3>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { orders, payment } = state.pos.operation;
  const { summary_total, payment_total, cash_total, voucher_total } =
    getSummaryTotalPayment(orders, payment);
  return {
    payment_total,
    cash_total,
    balance_total: summary_total - voucher_total,
  };
};

export default withRouter(
  connect(mapStateToProps, { addPayment: Creators.addPayment })(PaymentAdd)
);
