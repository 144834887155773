/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Modal,
  Pagination,
  Row,
  Skeleton,
  Typography,
} from "antd";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaEye, FaTimes } from "react-icons/fa";
import PrintButton from "../../../components/PrintButton";
import { useHttp } from "../../../hooks/http";
import { moment, URL_API, _ } from "../../../utils";
import BillPreview from "../pos/components/BillPreview";
import InputNumberWithButton from "./components/InputNumberWithButton";
import ScanBarcode from "./components/ScanBarcode";
import Table from "./components/Table";

const init_state = {
  data: {
    itemKeys: [],
    itemByKey: {},
  },
  scan: {
    itemKeys: [],
    itemByKey: {},
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "loadData":
      let itemKeys = [];
      let itemByKey = {};

      action.payload.forEach((n) => {
        let billKey = _.uniqueId("bill-");
        itemKeys.push(billKey);

        let detailItemKeys = [];
        let detailItemByKey = {};
        n.items.forEach((m) => {
          let detailKey = _.uniqueId("item-");
          detailItemKeys.push(detailKey);
          detailItemByKey = {
            ...detailItemByKey,
            [detailKey]: {
              ...m,
              delivery_qty: 0,
            },
          };
        });

        itemByKey = {
          ...itemByKey,
          [billKey]: {
            ...n,
            items: {
              itemKeys: detailItemKeys,
              itemByKey: detailItemByKey,
            },
          },
        };
      });
      return {
        ...init_state,
        data: {
          itemKeys,
          itemByKey,
        },
      };
    case "setDeliveryQty":
      return {
        ...state,
        data: {
          ...state.data,
          itemByKey: {
            ...state.data.itemByKey,
            [action.payload.bill_key]: {
              ...state.data.itemByKey[action.payload.bill_key],
              items: {
                ...state.data.itemByKey[action.payload.bill_key].items,
                itemByKey: {
                  ...state.data.itemByKey[action.payload.bill_key].items
                    .itemByKey,
                  [action.payload.detail_key]: {
                    ...state.data.itemByKey[action.payload.bill_key].items
                      .itemByKey[action.payload.detail_key],
                    delivery_qty: action.payload.delivery_qty,
                  },
                },
              },
            },
          },
        },
      };

    case "setScanValue":
      return {
        ...state,
        scan: {
          itemKeys: action.payload.itemKeys,
          itemByKey: action.payload.itemByKey,
        },
      };

    default:
      return state;
  }
};

const PreviewModal = ({ visible, onCancel, dataSource, onOk }) => {
  const { data } = dataSource;

  const renderBill = (source) => {
    return source.itemKeys.map((n, i) => {
      let bill = source.itemByKey[n];
      return (
        <React.Fragment key={i}>
          <Row gutter={8}>
            <Col className="px-3 bg-gray-40" style={{ fontSize: "13px" }}>
              <Typography.Paragraph className="mb-0 text-dark">
                {`บิลที่ ${i + 1} ${bill.document_no} - ${bill.partner_code} ${
                  bill.partner_name
                }`}
              </Typography.Paragraph>
            </Col>
          </Row>
          <Row gutter={8} className="my-2" style={{ fontSize: "12px" }}>
            <Col span={7} className="px-3">
              <Typography.Text strong className="mr-2 text-dark">
                ชื่อผู้รับ
              </Typography.Text>
              <Typography.Text className="text-dark">
                {bill.ship_to}
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text strong className="mr-2 text-dark">
                เบอร์โทรผู้รับ
              </Typography.Text>
              <Typography.Text className="text-dark">
                {bill.ship_mobile}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong className="mr-2 text-dark">
                ที่อยู่จัดส่ง
              </Typography.Text>
              <Typography.Text className="text-dark">
                {`${bill.ship_address_no} ${bill.ship_sub_district} ${bill.ship_district} ${bill.ship_province} ${bill.ship_post_code}`}
              </Typography.Text>
            </Col>
          </Row>

          <table className="w-100 my-2" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th></th>
                <th className="font-weight-light text-center text-gray-60">
                  ชื่อ
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  ราคา/หน่วย
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  ราคา
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  จำนวนที่จ่าย
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bill.items.itemKeys.map((m, j) => {
                let detail = bill.items.itemByKey[m];
                if (detail.delivery_qty > 0) {
                  return (
                    <tr key={j} className="text-dark">
                      <td className="pl-3">{j + 1}</td>
                      <td>{`${detail.product_code} - ${detail.product_name}`}</td>
                      <td className="text-center">
                        {numeral(detail.unit_price).format("0,0.00")}
                      </td>
                      <td className="text-center">
                        {numeral(detail.unit_price * detail.qty).format(
                          "0,0.00"
                        )}
                      </td>
                      <td className="text-center">
                        <strong className="text-pv">
                          {numeral(detail.delivery_qty).format("0,0")}
                        </strong>
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
              <tr>
                <td colSpan={4} className="text-right">
                  <strong className="text-dark">รวม</strong>
                </td>
                <td className="text-center">
                  <strong className="text-pv">
                    {_.reduce(
                      bill.items.itemByKey,
                      (sum, n) => {
                        return sum + (parseFloat(n.delivery_qty) || 0);
                      },
                      0
                    )}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      width={800}
      footer={false}
      zIndex={1101}
      closeIcon={<FaTimes size={10} />}
      bodyStyle={{ paddingTop: "50px" }}
    >
      {renderBill(data)}

      <Divider />

      <Row>
        <Col className="text-center">
          <Button type="primary" onClick={onOk}>
            บันทึก
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const BillModal = ({ visible, id, onCancel, onOk }) => {
  const [state, dispatch] = useReducer(reducer, init_state);
  const [activeTab, setActiveTab] = useState("");
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/delivery/bill`,
      params: { id },
    },
    [id]
  );

  useEffect(() => {
    if (data && data.data) {
      dispatch({ type: "loadData", payload: data.data });
    }
  }, [data]);

  const renderBill = (source) => {
    return source.itemKeys.map((n, i) => {
      let bill = source.itemByKey[n];
      return (
        <React.Fragment key={i}>
          <Row gutter={8}>
            <Col className="px-3 bg-gray-40" style={{ fontSize: "13px" }}>
              <Typography.Paragraph className="mb-0 text-dark">
                {`บิลที่ ${i + 1} ${bill.document_no} - ${bill.partner_code} ${
                  bill.partner_name
                }`}
              </Typography.Paragraph>
            </Col>
          </Row>
          <Row gutter={8} className="my-2" style={{ fontSize: "12px" }}>
            <Col span={7} className="px-3">
              <Typography.Text strong className="mr-2 text-dark">
                ชื่อผู้รับ
              </Typography.Text>
              <Typography.Text className="text-dark">
                {bill.ship_to}
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text strong className="mr-2 text-dark">
                เบอร์โทรผู้รับ
              </Typography.Text>
              <Typography.Text className="text-dark">
                {bill.ship_mobile}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong className="mr-2 text-dark">
                ที่อยู่จัดส่ง
              </Typography.Text>
              <Typography.Text className="text-dark">
                {`${bill.ship_address_no} ${bill.ship_sub_district} ${bill.ship_district} ${bill.ship_province} ${bill.ship_post_code}`}
              </Typography.Text>
            </Col>
          </Row>

          <table className="w-100 my-2" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th></th>
                <th className="font-weight-light text-center text-gray-60">
                  ชื่อ
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  ราคา/หน่วย
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  ราคา
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  จำนวน
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  จำนวนค้างจ่าย
                </th>
                <th className="font-weight-light text-center text-gray-60">
                  จำนวนที่จ่าย
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bill.items.itemKeys.map((m, j) => {
                let detail = bill.items.itemByKey[m];
                return (
                  <tr key={j} className="text-dark">
                    <td className="pl-3">{j + 1}</td>
                    <td>{`${detail.product_code} - ${detail.product_name}`}</td>
                    <td className="text-center">
                      {numeral(detail.unit_price).format("0,0.00")}
                    </td>
                    <td className="text-center">
                      {numeral(detail.unit_price * detail.qty).format("0,0.00")}
                    </td>
                    <td className="text-center">
                      {numeral(detail.qty).format("0,0")}
                    </td>
                    <td className="text-center">
                      {numeral(detail.qty - detail.delivery_qty).format("0,0")}
                    </td>
                    <td className="text-center">
                      <InputNumberWithButton
                        value={detail.delivery_qty}
                        min={0}
                        max={detail.qty}
                        onChange={(e) => {
                          dispatch({
                            type: "setDeliveryQty",
                            payload: {
                              bill_key: n,
                              detail_key: m,
                              delivery_qty: e,
                            },
                          });
                        }}
                      />
                    </td>
                    <td className="text-center pr-3">
                      <a>
                        <FaEye />
                      </a>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={5}></td>
                <td className="text-center">
                  <Typography.Text strong className="text-dark">
                    รวม
                  </Typography.Text>
                </td>
                <td className="text-center">
                  <Typography.Text strong className="text-dark">
                    {_.reduce(
                      bill.items.itemByKey,
                      (sum, n) => {
                        return sum + (parseFloat(n.delivery_qty) || 0);
                      },
                      0
                    )}
                  </Typography.Text>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    let itemKeys = [];
    let itemByKey = {};

    state.data.itemKeys.forEach((n) => {
      let bill = state.data.itemByKey[n];
      let filter = _.filter(bill.items.itemByKey, (m) => m.delivery_qty > 0);
      if (filter.length > 0) {
        filter.forEach((m) => {
          if (_.indexOf(itemKeys, m.product_code) === -1) {
            itemKeys.push(m.product_code);
            itemByKey = {
              ...itemByKey,
              [m.product_code]: {
                max_qty: m.delivery_qty,
              },
            };
          } else {
            itemByKey = {
              ...itemByKey,
              [m.product_code]: {
                max_qty:
                  (itemByKey[m.product_code].max_qty || 0) + m.delivery_qty,
              },
            };
          }
        });
      }
    });

    dispatch({ type: "setScanValue", payload: { itemKeys, itemByKey } });
  }, [state.data]);

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        maskClosable={false}
        zIndex={1100}
        width={800}
        footer={false}
        closeIcon={<FaTimes size={10} />}
        bodyStyle={{ paddingTop: "50px" }}
      >
        {error ? (
          <Alert type="error" message={error} />
        ) : loading ? (
          <Skeleton active />
        ) : data && data.data ? (
          <>
            <Row gutter={8}>
              <Col className="bg-gray-60 px-3">
                <Typography.Paragraph className="mb-0 text-white">
                  รายการสินค้า
                </Typography.Paragraph>
              </Col>
            </Row>

            {renderBill(state.data)}

            <ScanBarcode
              list={state.scan.itemKeys}
              active={
                activeTab === "" ||
                _.indexOf(state.scan.itemKeys, activeTab) === -1
                  ? state.scan.itemKeys[0]
                  : activeTab
              }
              onClick={(e) => setActiveTab(e)}
            />

            <Row className="mt-3">
              <Col className="text-center">
                <Button type="primary" onClick={() => setVisiblePreview(true)}>
                  สรุป
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Empty />
        )}
      </Modal>

      <PreviewModal
        visible={visiblePreview}
        onCancel={() => setVisiblePreview(false)}
        dataSource={state}
        onOk={() => onOk(state)}
      />
    </>
  );
};

const Waiting = ({ criteria }) => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  // const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [preview, setPreview] = useState(false);
  const [billSelectedId, setBillSelectedId] = useState(null);
  const [loading, data, error] = useHttp(
    {
      // url: `${URL_API}/pos/delivery/transport`,
      url: `${URL_API}/pos/delivery/wait`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        ...criteria,
        date_from: criteria.date_from
          ? moment(criteria.date_from).format("YYYY-MM-DD")
          : null,
        date_to: criteria.date_to
          ? moment(criteria.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page, criteria]
  );
  useEffect(() => {
    setPage(1);
  }, [criteria]);
  // const [selectId, setSelectId] = useState([]);
  // const [sendId, setSendId] = useState([]);

  const columns = [
    {
      title: "เลขที่บิล",
      key: "document_no",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "วันที่สั่งซื้อ",
      key: "document_date",
      dataIndex: "document_date",
      render: (text) => (
        <p className="m-0 text-left">
          {text == null ? "" : moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    // {
    //     title: 'เลขที่ใบจอง',
    //     key: 'reserve_no',
    //     dataIndex: 'reserve_no',
    //     render: text => <p className="m-0 text-center">{text}</p>
    // },
    // {
    //     title: 'วันที่จอง',
    //     key: 'reserve_date',
    //     dataIndex: 'reserve_date',
    //     render: text => <p className="m-0 text-center">{text}</p>
    // },
    {
      title: "รหัสสมาชิก",
      key: "partner_code",
      dataIndex: "partner_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ชื่อสมาชิก",
      key: "partner_name",
      dataIndex: "partner_name",
    },
    {
      title: "ที่อยู่",
      key: "address",
      render: (text, record) => (
        <p className="m-0 text-left">{`${record.ship_address_no} ${record.ship_sub_district} ${record.ship_district}`}</p>
      ),
    },
    {
      title: "จังหวัด",
      key: "ship_province",
      dataIndex: "ship_province",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "แหล่งที่มา",
      key: "bill_location",
      dataIndex: "bill_location",
      render: (text) => <p className="m-0 text-left">{text}</p>,
      // render: (text, record) => <p className="m-0 text-center">{record.warehouse_code}</p>
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <p className="m-0 d-flex justify-content-end align-items-center">
          <Button
            shape="circle"
            size="small"
            type="primary"
            className="mr-2"
            onClick={() => {
              setBillSelectedId(record.id);
              setPreview(true);
            }}
          >
            <FaEye />
          </Button>
          <PrintButton record={record} reportName={record.report_name} />
        </p>
      ),
    },
  ];

  useEffect(() => {
    let newData = [];
    let total = 0;
    if (data && data.data) {
      newData = data.data.map((n) => ({ ...n, key: n.id }));
      total = data.meta.pagination.total;
    }
    setDataSource(newData);
    setTotalPage(total);
  }, [data]);

  // const sendSubmit = async ({ data }) => {
  //   console.log("data", data);
  //   let send = data.itemKeys.map((n) => {
  //     let bill = data.itemByKey[n];

  //     let items = bill.items.itemKeys.map((m) => bill.items.itemByKey[m]);

  //     bill.items = _.filter(items, (m) => m.delivery_qty > 0);

  //     return bill;
  //   });

  //   await axios({
  //     method: "post",
  //     url: `${URL_API}/pos/arrange`,
  //     headers: { Authorization: "Bearer " + getToken() },
  //     data: send,
  //   })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  if (error) {
    return <Alert type="error" message={error} />;
  }

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        className="mb-3"
      />
      {totalPage === 0 ? null : (
        <Pagination
          current={page}
          total={totalPage}
          className="text-center"
          onChange={(e) => setPage(e)}
        />
      )}

      {/* <BillModal
        // id={sendId}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={(e) => sendSubmit(e)}
        // onOk={() => setVisiblePreview(true)}
      /> */}

      <BillPreview
        id={billSelectedId}
        preview={preview}
        setPreview={setPreview}
      />
    </>
  );
};

export default Waiting;
