import { Empty, Spin } from "antd";
import React from "react";
import { _ } from "../../../../../../utils";

const getAlign = (e) =>
  e === "center" ? "text-center" : e === "right" ? "text-right" : "";

const renderData = (columns, dataSource) =>
  _.reduce(
    dataSource,
    (res, n, i) => [
      ...res,
      <tr className="bg-transparent" key={i}>
        {_.map(columns, (m, j) => (
          <td
            className={`border-0 px-1 py-1 ${getAlign(m.align)}`}
            key={j}
            width={m.width}
          >
            {m.render ? m.render(n[m.dataIndex], n, i) : n[m.dataIndex]}
          </td>
        ))}
      </tr>,
    ],
    []
  );

const Table = ({ columns = [], dataSource = [], loading = false }) => (
  <Spin spinning={loading}>
    <table className="table table-sm m-0">
      <thead>
        <tr className="bg-gray-60 text-white text-center">
          {_.map(columns, (n, i) => (
            <th
              className={`font-weight-normal px-1 py-1 ${n.className}`}
              key={i}
            >
              {n.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{dataSource.length > 0 && renderData(columns, dataSource)}</tbody>
    </table>
    {dataSource.length === 0 && <Empty className="mt-2" />}
  </Spin>
);

export default Table;
