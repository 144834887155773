/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Modal,
  Upload,
  Radio,
} from "antd";
import { PanelHeader, Panel, PanelBody } from "../../../../util/panel";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import TextEditor from "../../../../../components/TextEditor";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../util/helper";
import { AiOutlineUpload } from "react-icons/ai";
import { DivStyles, ImgStyles } from "../../components/mwStyles";

function Rank({ history, form, match, ...props }) {
  const [loadValue, setLoadValue] = useState("");

  const [selectType, setSelectType] = useState("frame");

  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();
      if (values.desktop_img?.file?.originFileObj)
        formData.append("desktop_img", values.desktop_img?.file?.originFileObj);
      if (values.mobile_img?.file?.originFileObj)
        formData.append("mobile_img", values.mobile_img?.file?.originFileObj);
      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img"]),
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/award/rank/" + match.params.id + "/update";
            } else {
              actionPathUrl = "/manage-website/award/rank";
            }

            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };
  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveMobile = (e) => {
    setFileListMobile([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleChangeUploadDesktop = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));

      setFileListDesktop([
        {
          uid: file.uid,
          status: "done",
          name: file.name,
          url: img_url,
        },
      ]);
    }
  };

  const handleChangeUploadMobile = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));

      setFileListMobile([
        {
          uid: file.uid,
          status: "done",
          name: file.name,
          url: img_url,
        },
      ]);
    }
  };
  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/award/rank/" + match.params.id,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);

        let { loadDesktopImg, loadMobileImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
          }
        }

        setFileListDesktop(loadDesktopImg);
        setFileListMobile(loadMobileImg);
        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (match.params.id !== "add") {
      loadContent(match.params.id);
    }
  }, [match.params.id]);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>ประกาศเกียรติคุณ RANK</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Row className="mb-3" gutter={16}>
              <Col md={12}>
                <Form.Item label="Name ( ชื่อของตำแหน่ง ) :">
                  {getFieldDecorator("title", {
                    initialValue: "",
                    rules: [
                      { required: true, message: "กรุณาระบุ ชื่อรหัสหลัก" },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Form.Item label="Type">
                {getFieldDecorator("content_type", {
                  initialValue: "frame",
                })(
                  <Radio.Group
                    onChange={(e) => {
                      setSelectType(e.target.value);
                    }}
                  >
                    <Radio value={"frame"}>Frame</Radio>
                    <Radio value={"normal"}>Normal</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              {selectType == "frame" ? (
                <Row className="mb-3">
                  <Row>
                    <Col md={12}>
                      {fileListDesktop.length != 0 ? (
                        <Row>
                          <div style={DivStyles}>
                            <img
                              src={fileListDesktop[0].url}
                              alt="desktop_img"
                              style={ImgStyles}
                            />
                          </div>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        {getFieldDecorator("desktop_img", {
                          initialValue: null,
                        })(
                          <Upload.Dragger
                            accept="image/*"
                            multiple={false}
                            fileList={fileListDesktop}
                            customRequest={dummyRequest}
                            beforeUpload={beforeUploadImg}
                            onRemove={handleRemoveDesktop}
                            onChange={handleChangeUploadDesktop}
                            listType="picture"
                          >
                            <AiOutlineUpload className="mr-3" />
                            Upload Frame
                          </Upload.Dragger>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Row>
              ) : (
                ""
              )}
            </Row>

            <Row>
              <Row>
                <Col md={12}>
                  {fileListMobile.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile[0].url}
                        alt="mobile_img"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item label="อัพโหลดเข็มนักธุรกิจ : ">
                    {getFieldDecorator("mobile_img", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveMobile}
                        onChange={handleChangeUploadMobile}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>

            <Row gutter={16}>
              <Col md={24}>
                <Button
                  className="bg-nn border-nn text-white mr-3"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={() => handleClickSubmit()}>
                  {match.params.id !== "add" ? "Update" : "Save"}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("award-award")(Rank);
