/* eslint-disable no-unused-vars */
import { Button, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import ControlPanel from '../../../../../../components/ControlPanel';
import Layout from '../../../../../../components/Layout';
import ElementSetA from '../components/ElementSetA';
import ElementSetB from '../components/ElementSetB';
import LayoutBox from '../components/LayoutBox';
import ProductList from '../components/ProductList';
import PromotionDate from '../components/PromotionDate';
import PromotionDistribution from '../components/PromotionDistribution';
import PromotionJoinPro from '../components/PromotionJoinPro';
import PromotionMemberStatus from '../components/PromotionMemberStatus';
import PromotionPayment from '../components/PromotionPayment';
import PromotionDetail from '../components/PromotionDetail';
import PromotionFree from './PromotionFree';
import PromotionSource from './PromotionSource';
import { useSelector, useDispatch } from 'react-redux'
import { actionPromotion, proGiveAwaySelector, proPriceSelector, proBillSelector } from '../../../../../../redux/slices/promotion'

const Index = (props) => {
    const id = Number(props.match.params.id) || 0;
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const promotion_give_away = useSelector(proGiveAwaySelector)
    const { SET_PRO_GIVE_AWAY } = actionPromotion

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "forever":
                if (value === true) {
                    dispatch(SET_PRO_GIVE_AWAY({
                        ...promotion_give_away,
                        [name]: value,
                        end_date: null
                    }))
                } else {
                    dispatch(SET_PRO_GIVE_AWAY({ ...promotion_give_away, [name]: value }))
                }
                break;
            default:
                dispatch(SET_PRO_GIVE_AWAY({ ...promotion_give_away, [name]: value }))
                break;
        }
    }
    console.log(promotion_give_away);
    return (
        <Layout
            titlePage="โปรโมชั่นของแถม"
            subTitlePage=""
        >
            <ControlPanel className="bg-transparent" titlePanel="โปรโมชั่นของแถม">
                <Row type="flex" justify="end" gutter={14} className="mb-2">
                    <Col>
                        <Button
                            type="primary"
                        >
                            Save
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="danger"
                            onClick={() => props.history.replace('/setting/master/promotion')}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col md={16}>
                        {/*-------------------------- left content -----------------------------*/}
                        <LayoutBox
                            id="promotion-detail"
                            title="รายละเอียดโปรโมชั่น"
                            content={
                                <PromotionDetail
                                    promotion_code={promotion_give_away.promotion_code}
                                    promotion_name={promotion_give_away.promotion_name}
                                    handleChange={handleChange}
                                />
                            }
                        />
                        <LayoutBox
                            id="promotion-source"
                            title="เงื่อนไขการซื้อ"
                            componentTopRight={
                                <Button
                                    shape="circle"
                                    type="primary"
                                    icon="plus"
                                    onClick={() => setShow(true)}
                                />
                            }
                            content={
                                <PromotionSource />
                            }
                        />
                        <LayoutBox
                            id="promotion-free"
                            title="สิ่งที่ได้รับ"
                            componentTopRight={
                                <Button
                                    shape="circle"
                                    type="primary"
                                    icon="plus"
                                    onClick={() => setShow(true)}
                                />
                            }
                            content={
                                <PromotionFree />
                            }
                        />
                    </Col>
                    {/*------------------------ right content ---------------------------------*/}
                    <Col md={8}>
                        <LayoutBox
                            id="promotion-date"
                            title="วันเวลา"
                            content={
                                <PromotionDate
                                    forever={promotion_give_away.forever}
                                    start_date={promotion_give_away.start_date}
                                    end_date={promotion_give_away.end_date}
                                    handleChange={handleChange}
                                />
                            }
                        />
                        <LayoutBox
                            id="promotion-distribution"
                            title="รับโปรโมชั่นผ่านช่องทางการซื้อ"
                            content={
                                <PromotionDistribution
                                    data={promotion_give_away}
                                    handleChange={handleChange}
                                />
                            } />
                        <ElementSetA
                            data={promotion_give_away}
                            handleChange={handleChange}
                        />
                        <ElementSetB
                            data={promotion_give_away}
                            handleChange={handleChange}
                        />
                        <LayoutBox
                            id="promotion-payment"
                            title="รับโปรโมชั่นตามประเภทการชำระเงิน"
                            content={
                                <PromotionPayment
                                    data={promotion_give_away}
                                    handleChange={handleChange}
                                />
                            }
                        />
                        <LayoutBox
                            id="promotion-member-status"
                            title="สถานะที่ได้รับสิทธิ์"
                            content={
                                <PromotionMemberStatus
                                    data={promotion_give_away}
                                    handleChange={handleChange}
                                />
                            }
                        />
                        <LayoutBox
                            id="promotion-join-pro"
                            title="ใช้ร่วมกับโปรโมชั่นอื่น"
                            content={
                                <PromotionJoinPro
                                    join_pro={promotion_give_away.join_pro}
                                    handleChange={handleChange}
                                />
                            }
                        />
                    </Col>
                </Row>

                <ProductList
                    title="เลือกสินค้า"
                    visible={show}
                    width={900}
                    onCancel={() => setShow(false)}
                    onOk={() => setShow(false)}
                />
            </ControlPanel>

        </Layout>
    )
}
export default Index
