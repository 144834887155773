/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Input, Select, Table } from 'antd';
import { FaDownload } from 'react-icons/fa';
import usePaginationHook from '../../../../utils/usePagination';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import fitQuizServices from '../../../../services/fit-quiz.services';
import { userRewardQueryDefaultValues } from '../components/dto/legacy-quiz-form.dto';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import _ from 'lodash';
import { FiArrowLeft } from 'react-icons/fi';

dayjs.locale('th');

const pageSize = 10;

const LegacyQuizRewardReportPage = () => {
  const params = useParams();
  const history = useHistory();
  const [loading2, setLoading2] = useState(false);
  const {
    data: legacyQuizUserRewardLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: userRewardQueryDefaultValues,
  });

  fitQuizServices.useQueryGetLegacyQuizUserRewards(
    {
      take: -1,
      page: 1,
      quizRewardId: params.rewardId,
      memberCode: watch('memberCode'),
      memberType: watch('memberType'),
      isPartner: watch('isPartner'),
      isMax: watch('isMax'),
    },
    (data) => {
      const sortData = data.data.sort((a, b) => {
        if (a.maxDate && b.maxDate && a.maxDate > b.maxDate) {
          return 1;
        } else if (a.maxDate && !b.maxDate) {
          return -1;
        } else if (!a.maxDate && b.maxDate) {
          return 1;
        }

        return 1;
      });
      setData(sortData);
      setTotal(data.meta.total);
    }
  );

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'รหัสนักธุรกิจ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.user.memberCode}</span>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => (
        <span>
          {record.user.user.FirstNameTh + ' ' + record.user.user.SurNameTh}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'isPartner',
      key: 'isPartner',
      render: (_, record) => (
        <span>{record.user.isPartner ? 'รหัสร่วม' : 'รหัสหลัก'}</span>
      ),
    },
    {
      title: 'วันที่ครบ',
      dataIndex: 'maxDate',
      key: 'maxDate',
      render: (_, record) => (
        <span>
          {record.maxDate && moment(record.maxDate).format('DD MMM YY')}
        </span>
      ),
    },
    {
      title: 'คะแนน',
      dataIndex: 'max',
      key: 'max',
      render: (_, record) => (
        <span
          style={{
            ...(record.isMax && { color: 'green' }),
          }}
        >
          {record.timeCount}/{record.reward.max}
        </span>
      ),
    },
  ];

  const { mutate: downloadLegacyQuizUserRewardListsExcel } =
    fitQuizServices.useMutationDownloadLegacyQuizUserRewardListsExcel(() => {
      setTimeout(() => {
        setLoading2(false);
      }, 600);
    });

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
      </div>
      <Panel>
        <PanelHeader>สรุปการทำแบบทดสอบ</PanelHeader>
        <PanelBody>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="mb-2"
          >
            <Controller
              name="memberCode"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="รหัสนักธุรกิจ"
                    className="mr-2"
                    style={{ flex: 1 }}
                  />
                );
              }}
            />
            <Controller
              name="isPartner"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    style={{ flex: 1, marginRight: 10 }}
                    onChange={onChange}
                    placeholder="ประเภทรหัสนักธุรกิจ"
                  >
                    <Select.Option value={undefined}>--</Select.Option>
                    <Select.Option value={false}>
                      รหัสนักธุรกิจหลัก
                    </Select.Option>
                    <Select.Option value={true}>รหัสนักธุรกิจคู่</Select.Option>
                  </Select>
                );
              }}
            />
            <Controller
              name="isMax"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    style={{ flex: 1, marginRight: 10 }}
                    onChange={onChange}
                    placeholder="ประเภทรางวัล"
                  >
                    <Select.Option value={undefined}>--</Select.Option>
                    <Select.Option value={false}>ผ่าน</Select.Option>
                    <Select.Option value={true}>ไม่ผ่าน</Select.Option>
                  </Select>
                );
              }}
            />
            <Button
              className="bg-pv border-pv text-white"
              onClick={() => {
                setLoading2(true);
                downloadLegacyQuizUserRewardListsExcel({
                  take: -1,
                  page: 1,
                  quizRewardId: params.rewardId,
                  memberCode: watch('memberCode'),
                  memberType: watch('memberType'),
                  isPartner: watch('isPartner'),
                  isMax: watch('isMax'),
                });
              }}
              loading={loading2}
            >
              <FaDownload />
              ดาวน์โหลด Excel
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={legacyQuizUserRewardLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
            onRow={(record, rowIndex) => {}}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default LegacyQuizRewardReportPage;
