import React from "react";
import SelectOption from "../../pos/components/SelectOption";

export const warehouseOption = [
  { value: "", text: "ทั้งหมด" },
  { value: "00", text: "คลังดี" },
  { value: "01", text: "คลังเสีย" },
  { value: "02", text: "คลังคัดแยก" },
  //{ value: "03", text: "คลังหาย" },
  //{ value: "04", text: "คลังยืม" },
  { value: "05", text: "คลังคืน" },
  //{ value: "06", text: "รอตรวจสอบ" }
];

const SelectWarehouse = ({ ...props }) => {
  let warehouseOptions = [
    { value: "", text: "ทั้งหมด" },
    { value: "00", text: "คลังดี" },
    { value: "01", text: "คลังเสีย" },
    { value: "02", text: "คลังคัดแยก" },
   // { value: "03", text: "คลังหาย" },
   // { value: "04", text: "คลังยืม" },
    { value: "05", text: "คลังคืน" },
   // { value: "06", text: "รอตรวจสอบ" }
  ];

  return <SelectOption className="w-100" options={warehouseOptions} {...props} />;
};

export default SelectWarehouse;
