import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const ptRegisterPaperQueryDefaultValues = {
  memberFirstName: '',
  memberLastName: '',
  ptFirstName: '',
  ptLastName: '',
};

export const ptRegisterPaperQueryFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      memberFirstName: yup.string(),
      memberLastName: yup.string(),
      ptFirstName: yup.string(),
      ptLastName: yup.string(),
    })
  );
};
