/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormGroup from './FormGroup'
import Calculate from './Calculation'
import PersonalQualities from './PersonalQualities'

import Image from '../../../../../assets/img/legacy-trip/maldives/maldives.jpg'
import { useHttp } from '../../../../../hooks/http';
import { Loading } from '../../../../util/Loading';
import { AlertError } from '../../../../util/Alert';
import { URL_API } from '../../../../../utils';
import { FormatNumber } from './ShareFunction';

export default (props) => {

    const [dataTrip, setData] = useState({

        partner_code: '',
        partner_name: '',
        partner_name_spouse: '',
        hi_pin_201910: '',
        trip_cycle: '',
        trip_calculate_type: '',
        calculation: {
            calculation_point_of_month: [],
            point_start_trip: 0,
            point_target_1: 0,
            point_target_2: 0,
            pro_trip: 0,
            pro_point_maldives_of_month: [],
            total_tarvel_scroe: 0,
            target_scroe_belgium_netherlands: 0

        },
        personal_qualification: {
            gender: 'M',
            current_score: 0,
            target_score: 20000000,
            personal_qualification_point_of_month: [],
            total_trip_point: 0,
            grand_trip_point: 0,
            assessment: 0,

        }

    });
    const member_code = props.match.params.code;

    const [loading, res, error] = useHttp({
        method: 'get',
        url: `${URL_API}/system/trip/maldives/${member_code}`,
    });

    const mql = window.matchMedia(`(min-width: 800px)`);
    const pc = mql.matches;

    useEffect(() => {
        //componentDidMount
        document.body.classList.add('BG-White');


        //componentWillUnmount
        return () => {
            document.body.classList.remove('BG-White');
        }
    }, []);

    useEffect(() => {
        try {
            if (res) {
                console.log(res)
                const {
                    gender,
                    partner_code,
                    partner_name,
                    partner_name_spouse,
                    hi_pin_201910,
                    trip_cycle,
                    trip_calculate_type,
                    current_pv_201904,
                    current_pv_201905,
                    current_pv_201906,
                    current_pv_201907,
                    current_pv_201908,
                    current_pv_201909,
                    current_pv_last_total,
                    w_201904,
                    w_201905,
                    w_201906,
                    w_201907,
                    w_201908,
                    w_201909,
                    w_last_total,
                    point_start_trip,
                    point_target_1,
                    point_target_2,
                    point_start_trip_110, //110%คะแนนฐาน ทริปเบ-เน
                    sum_total_last_trip, //รวมคะแนนท่องเที่ยว+โปรโมชั่นที่ทำได้ ทริปเบ-เน
                    pro_trip,
                    current_pv_201910,
                    current_pv_201911,
                    current_pv_201912,
                    current_pv_202001,
                    current_pv_202002,
                    current_pv_202003,
                    current_pv_total,
                    pro_current_pv, //Pro.รักษายอด
                    w_201910,
                    w_201911,
                    w_201912,
                    w_202001,
                    w_202002,
                    w_202003,
                    point_trip_201910,
                    point_trip_201911,
                    point_trip_201912,
                    point_trip_202001,
                    point_trip_202002,
                    point_trip_202003,
                    total_trip_point,
                    grand_trip_point
                } = res.data;
                setData({
                    partner_code,
                    partner_name,
                    partner_name_spouse,
                    hi_pin_201910,
                    trip_cycle,
                    trip_calculate_type,
                    calculation: {
                        calculation_point_of_month: [
                            // ['ยอดส่วนตัว', current_pv_201904, current_pv_201905, current_pv_201906, current_pv_201907, current_pv_201908, current_pv_201909, current_pv_last_total],
                            ['คะแนนทีมอ่อน', w_201904, w_201905, w_201906, w_201907, w_201908, w_201909]
                        ],
                        point_start_trip,
                        point_target_1,
                        point_target_2,
                        pro_trip,
                        // ไม่มีข้อมูล
                        pro_point_maldives_of_month: [
                            ['รักษายอดส่วนตัว', '', '', '', '', '', '', ''],
                            ['คะแนนทีมอ่อน', '', '', '', '', '', ''],
                            ['คะแนนท่องเที่ยว', '', '', '', '', '', ''],
                            ['โปรโมชั่น', '', '', '', '', '', ''],
                        ],
                        total_tarvel_scroe: '',
                        target_scroe_belgium_netherlands: ''

                    },
                    personal_qualification: {
                        gender,
                        current_score: total_trip_point,
                        target_score: 20000000,
                        personal_qualification_point_of_month: [
                            ['ยอดส่วนตัว', current_pv_201910, current_pv_201911, current_pv_201912, current_pv_202001, current_pv_202002, current_pv_202003, current_pv_total],
                            ['คะแนนท่องเที่ยว*', point_trip_201910, point_trip_201911, point_trip_201912, point_trip_202001, point_trip_202002, point_trip_202003, total_trip_point],
                            ['คะแนนทีมอ่อน', w_201910, w_201911, w_201912, w_202001, w_202002, w_202003],

                            (false) ? ['โปรโมชั่น (ถ้ามี)', 0, 0, 0, 0, 0, 0] : []
                        ],
                        total_trip_point,
                        grand_trip_point,
                        assessment: 'ผ่าน/ไม่ผ่าน',

                    }

                });
            }
        } catch (error) {
            AlertError({ text: 'ไม่พบข้อมูลผู้ใช้งาน' })
        }

    }, [res]);

    if (error) AlertError({ text: error });




    const {
        partner_code,
        partner_name,
        partner_name_spouse,
        hi_pin_201910,
        trip_cycle,
        trip_calculate_type,
        calculation,
        personal_qualification,
    } = dataTrip;
    return (
        <Loading loading={loading}>
            <div className="maldives-trip">

                <img src={Image} alt="Avatar" className="w-100 mb-3" />

                <Row>
                    <Col className="d-flex justify-content-center mb-3">
                        <b style={{ fontSize: 18, color: '#b57e10' }}>ระยะเวลาทำคุณสมบัติ 6 เดือน ตั้งแต่วันที่ 1 ตุลาคม 2562 - 31 มีนาคม 2536</b>
                    </Col>
                </Row>
                <Row>
                    <Col sx={12} md={4} >
                        <FormGroup label="รหัสนักธุรกิจ" value={partner_code} comment={<p>*รหัสคู่ ต้องจดรหัสร่วมก่อนวันที่ 25 ก.พ.63</p>} />
                    </Col>
                    <Col sx={12} md={4} >
                        <FormGroup label="ชื่อผู้สมัครหลัก" value={partner_name} />
                    </Col>
                    <Col sx={12} md={4} >
                        {partner_name_spouse.trim() !== '' &&
                            <FormGroup label="ชื่อผู้สมัครร่วม" value={partner_name_spouse} />
                        }

                    </Col>
                </Row>

                <hr className="maldives-trip-line" />

                <FormGroup
                    label="ตำแหน่งสูงสุดก่อนเดือน ตุลาคม 2562 (Hi-Pin)"
                    value={hi_pin_201910}
                    labelCol="col-md-5 col-xs-12"
                    divCol="col-md-7 col-xs-12"
                />
                <FormGroup
                    label="เคยผ่านทริปสั้นของเลกาซีมาแล้ว (ครั้ง)"
                    value={FormatNumber(trip_cycle)}
                    labelCol="col-md-5 col-xs-12"
                    divCol="col-md-7 col-xs-12"

                />

                <FormGroup
                    label="ต้องทำคุณสมบัติตามเงื่อนไขแบบ"
                    value={trip_calculate_type}
                    labelCol="col-md-5 col-xs-12"
                    divCol="col-md-7 col-xs-12"
                    width={(pc) ? 400 : '100%'}
                    detail={<>
                        <span>*แบบ 1. คนใหม่ = คนที่ไม่เคยผ่านทริปสั้นกับเลกาซีมาก่อน</span><br />
                        <span>*แบบ 2. คนทั่วไป</span><br />
                        <span>*แบบ 3. ผู้นำ RD Q.3 ขึ้นไป (Hi-Pin ก่อนเดือน ต.ค.62) ที่มีคะแนนเฉลี่ยเดือน เม.ย.62-ก.ย.62 มากกว่า 150,000 คะแนน</span><br />
                    </>}
                    icon
                />

                <Row>
                    <Col>
                        <div id="accordion" className="accordion">
                            {calculation &&
                                <Calculate data={calculation} />
                            }
                            {personal_qualification &&
                                <PersonalQualities data={personal_qualification} />
                            }
                            {/* <Calculate data={calculation} />
                            <PersonalQualities data={personal_qualification} /> */}
                        </div>
                    </Col>
                </Row>

                <hr className="maldives-trip-line" />
                <p>*หมายเหตุ : *กรุณาศึกษารายละเอียดเงื่อนไข/คุณสมบัติ การเก็บคะแนนเป้าหมายของทริปนี้ตามประกาศ</p>
                <p> 1) ค่าสถานะที่แสดงในรายงานนี้ จะไม่ได้บ่งบอกถึงการผ่าน/ไม่ผ่านคุณสมบัติของแต่ละเดือน และไม่รวมถึงคะแนนโปรโมชั่นที่จะได้รับ ทั้งนี้ ผลของคะแนนรวม/คุณสมบัติที่ทำได้รวม จะแสดงได้ต่อเมื่อสิ้นสุดการเก็บคุณสมบัติครบแล้ว 5 เดือน เท่านั้น</p>
                <p> 2) เดือนที่รักษายอดส่วนตัว 1000 คะแนน คะแนนทีมอ่อนที่ได้แต่ละเดือน = คะแนนท่องเที่ยวของเดือนนั้นๆ</p>
                <p> 3) สำหรับผู้นำกลุ่มที่ 3.แบบนับสายเลือด จะยังไม่แสดงผลการผ่านทริป เนื่องจากต้องส่งรายชื่อสายเลือดเพื่อการตรวจสอบ</p>

            </div>
        </Loading>
    )
}

