/* eslint-disable no-unused-vars */
import { Col, notification, Row, Table, Typography, typography } from "antd";
import Title from "antd/lib/typography/Title";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { AlertError } from "../../../../util/Alert";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const { Text } = typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-remaining-point`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        partnerCode: state.filter.query,
        ...state.filter,
      },
      initialLoad: false,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const exportDataData = data ? [data?.data] : [];

  const exportToExcel = () => {
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานคะแนนคงเหลือ"],
        [
          `รหัสสมาชิก: ${state.filter.query}`,
          "",
          `ชื่อสมาชิก: ${data?.data[0]?.partner_name}`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [],
        [
          "คะแนนรักษายอด (เดือนปัจจุบัน)",
          "PV Hold คงเหลือ",
          "วันที่หมดอายุการแจง",
          "RP พร้อมใช้",
          "RP รอโอน",
          "วันที่หมดอายุการแจง",
          "RP จองแลก",
          "RA",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            {
              v: numeral(n.current_pv).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v: numeral(n.pv_hold).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v: n.pv_expire_date,
              s: { alignment: { horizontal: "center" } },
            },

            {
              v: numeral(n.rp_topup).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v: numeral(n.rp_hold).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v: n.rp_expire_date,
              s: { alignment: { horizontal: "center" } },
            },

            {
              v: numeral(n.rp_reserve).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
            {
              v: numeral(n.current_ra).format("0,0.00"),
              s: { alignment: { horizontal: "right" } },
            },
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
      ];

      var wscols = [
        { wch: 35 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 10 },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานคะแนนคงเหลือ");
      XLSX.writeFile(wb, "รายงานคะแนนคงเหลือ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานคะแนนคงเหลือ</PanelHeader>
        <PanelBody>
          <FilterList /*exportToExcel={""}*/
            withAll={user.branch_count > 1}
            allowDisableBranch
            reload={() => {
              state.filter.query === ""
                ? AlertError({
                    title: "ข้อมูลไม่ถูกต้อง",
                    text: "กรุณาระบุรหัสสมาชิก",
                  })
                : reload();
            }}
            placeholder="ระบุรหัสสมาชิก"
            showReportSpan={10}
            inputSpan={6}
            topicSpan={6}
            Topic=""
            showDatePicker={false}
            exportToExcel={exportToExcel}
          />
          <Row>
            <Text strong style={{ fontSize: "14px" }}>
              ชื่อสมาชิก : {data?.data[0]?.partner_name}
            </Text>
          </Row>
          <Table
            className="mt-4"
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.data ? dataSource.data.length : 50,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            {/* <Table.Column title="ลำดับ" dataIndex="row_id" align="center" /> */}
            <Table.Column
              title="คะแนนรักษายอด (เดือนปัจจุบัน)"
              dataIndex="current_pv"
              align="right"
              render={(text) =>
                text === null ? "-" : numeral(text).format("0,0.00")
              }
            />
            <Table.Column
              title="PV Hold คงเหลือ"
              dataIndex="pv_hold"
              align="right"
              render={(text) =>
                text === null ? "-" : numeral(text).format("0,0.00")
              }
            />
            <Table.Column
              title="วันที่หมดอายุการแจง"
              dataIndex="pv_expire_date"
              align="center"
            />
            <Table.Column
              title="RP พร้อมใช้"
              dataIndex="rp_topup"
              align="right"
              render={(text) =>
                text === null ? "-" : numeral(text).format("0,0.00")
              }
            />
            <Table.Column
              title="RP รอโอน"
              dataIndex="rp_hold"
              align="right"
              render={(text) =>
                text === null ? "-" : numeral(text).format("0,0.00")
              }
            />
            <Table.Column
              title="วันที่หมดอายุการแจง"
              dataIndex="rp_expire_date"
              align="center"
            />
            <Table.Column
              title="RP จองแลก"
              dataIndex="rp_reserve"
              align="right"
              render={(text) =>
                text === null ? "-" : numeral(text).format("0,0.00")
              }
            />
            <Table.Column
              title="RA"
              dataIndex="current_ra"
              align="right"
              render={(text) =>
                text === null ? "-" : numeral(text).format("0,0.00")
              }
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
