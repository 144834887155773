/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { classFormDefaultValues } from '../dto/class';
import classConstants from '../../constants/classConstants';
import { MdDelete } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import ImageUpload from '../upload/ImageUpload';

const ClassForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [newDate, setNewDate] = useState(new Date());

  const { fields, append, remove } = useFieldArray({
    name: 'suggestion',
    control,
  });

  const { execute: getClassById } = useAsync(fitStudioService.getClassById, {
    onSuccess: (data) => {
      reset({
        ...data,
        rewardId: data.reward.id,
        formType: 'edit',
      });
    },
  });

  const { data: rewardLists } = fitStudioService.useQueryGetAllReward({
    take: -1,
    page: 1,
    endDate: newDate,
  });

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getClassById(watch('id'));
    } else {
      reset(classFormDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={classConstants.category.width}
                  height={classConstants.category.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อคลาสเรียน <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="subTitle"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อรอง <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ชื่อรอง"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="subTitle"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำบรรยาย <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยาย"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="period"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ระยะเวลา <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ระยะเวลา"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="period"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="attribute_title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  หัวข้อคุณสมบัติ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="แคลอรี่เผาผลาญ"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="attribute_title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="attribute_image"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>
                  ภาพคุณสมบัติ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={classConstants.category.width}
                  height={classConstants.category.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="attribute_value"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  ปริมาณคุณสมบัติ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Input value={value} onChange={onChange} placeholder="300" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="attribute_value"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="attribute_unit"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  หน่วยคุณสมบัติ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Input value={value} onChange={onChange} placeholder="Kcal" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="attribute_unit"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="rewardId"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={5}>
                <Typography.Text>
                  เหรียญรางวัล <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={19}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {rewardLists?.data &&
                    rewardLists.data.length > 0 &&
                    rewardLists.data.map((item) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="rewardId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Row className="align-items-center  mb-2">
        <Col span={4}>
          <Typography.Text>
            เหมาะสำหรับ <span style={{ color: 'red' }}>*</span>
          </Typography.Text>
        </Col>
        <Col span={20}>
          <FaPlus
            className="mr-2"
            onClick={() => {
              append('');
            }}
          />
        </Col>
      </Row>
      {fields.map((item, index) => {
        return (
          <Row key={index} className="align-items-center  mb-2">
            <Col span={24}>
              <Controller
                name={`suggestion.${index}.description`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input
                          value={value}
                          onChange={onChange}
                          placeholder="ผู้ที่มีความกังวล เรื่องของสุขภาพหัวใจ"
                        />
                        <MdDelete
                          size={20}
                          className="ml-2"
                          onClick={() => {
                            remove(index);
                            reset(getValues());
                          }}
                        />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name={`suggestion.${index}.description`}
                        render={({ message }) => (
                          <Typography.Text className="mb-2 text-danger">
                            {message}
                          </Typography.Text>
                        )}
                      />
                    </div>
                  );
                }}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default ClassForm;
