/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaPen, FaPlus } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import fitLegacyServices from '../../../../services/fit-legacy.services';
import LegacyNotificationModal from '../components/modal/LegacyNotificationModal';
import {
  legacyNotificationCode,
  legacyNotificationFormDefaultValues,
  legacyNotificationFormSchema,
  legacyNotificationLinkType,
  legacyNotificationSendFormDefaultValues,
  legacyNotificationSendFormSchema,
} from '../components/dto/legacy-notification.dto';
import LegacyNotificationSendModal from '../components/modal/LegacyNotificationSendModal';

dayjs.locale('th');

const pageSize = 10;

const LegacyNotificationPage = () => {
  const [legacyNotificationFormOpen, setLegacyNotificationFormOpen] =
    useState(false);
  const [legacyNotificationSendFormOpen, setLegacyNotificationSendFormOpen] =
    useState(false);
  const {
    data: legacyPopUpLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();

  const legacyNotificationForm = useForm({
    defaultValues: legacyNotificationFormDefaultValues,
    resolver: legacyNotificationFormSchema(),
  });

  const legacyNotificationSendForm = useForm({
    defaultValues: legacyNotificationSendFormDefaultValues,
    resolver: legacyNotificationSendFormSchema(),
  });

  const _HandleLegacyNotificationFormOpen = (formType) => {
    legacyNotificationForm.setValue('formType', formType);
    setLegacyNotificationFormOpen(true);
  };

  const _HandleLegacyNotificationFormClose = () => {
    legacyNotificationForm.reset(legacyNotificationFormDefaultValues);
    setLegacyNotificationFormOpen(false);
  };

  const _HandleLegacyNotificationSendFormOpen = (formType) => {
    setLegacyNotificationSendFormOpen(true);
  };

  const _HandleLegacyNotificationSendFormClose = () => {
    legacyNotificationSendForm.reset(legacyNotificationSendFormDefaultValues);
    setLegacyNotificationSendFormOpen(false);
  };

  fitLegacyServices.useQueryGetLegacyNotificationLists(
    { take: pageSize, page },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyNotification } =
    fitLegacyServices.useMutationDeleteLegacyNotification(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-notification'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnail_img_url',
      key: 'thumbnail_img_url',
      render: (_, record) => (
        <div
          style={{
            width: 50,
            height: 50,
            borderRadius: 10,
          }}
        >
          {record.thumbnail_img_url && (
            <img
              alt={record.title}
              src={record.thumbnail_img_url}
              style={{
                width: 50,
                height: 50,
                borderRadius: 10,
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'คำบรรยาย',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => <span>{record.description}</span>,
    },
    {
      title: 'คำบรรยายเสริม',
      dataIndex: 'sub_description',
      key: 'sub_description',
      render: (_, record) => <span>{record.sub_description}</span>,
    },
    {
      title: 'ประเภทการแจ้งเตือน',
      dataIndex: 'notification_code',
      key: 'notification_code',
      render: (_, record) => (
        <span>
          {
            legacyNotificationCode.find(
              (item) => item.value === record.notification_code
            ).label
          }
        </span>
      ),
    },
    {
      title: 'ประเภท Action',
      dataIndex: 'link_type',
      key: 'link_type',
      render: (_, record) => (
        <span>
          {
            legacyNotificationLinkType.find(
              (item) => item.value === record.link_type
            ).label
          }
        </span>
      ),
    },
    {
      title: 'ลิงก์',
      dataIndex: 'link',
      key: 'link',
      render: (_, record) => <span>{record.link}</span>,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyNotificationSendForm.setValue('record', record);
              _HandleLegacyNotificationSendFormOpen();
            }}
          >
            <FiSend />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyNotificationForm.setValue('id', record.id);
              _HandleLegacyNotificationFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyNotification({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Legacy Notification</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <div style={{ flex: 1 }} />
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleLegacyNotificationFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่ม Legacy Notifcation
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={legacyPopUpLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
        />
      </PanelBody>
      <FormProvider {...legacyNotificationForm}>
        <LegacyNotificationModal
          open={legacyNotificationFormOpen}
          handleClose={_HandleLegacyNotificationFormClose}
        />
      </FormProvider>
      <FormProvider {...legacyNotificationSendForm}>
        <LegacyNotificationSendModal
          open={legacyNotificationSendFormOpen}
          handleClose={_HandleLegacyNotificationSendFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default LegacyNotificationPage;
