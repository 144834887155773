/* eslint-disable no-unused-vars */
import { _ } from "../../utils";

export const initRequisitionError = {
  requisition_type: null,
  warehouse_code: null,
  requisition_class: null,
  requisition_date: null,
  product: null,
  reason: null,
};
