/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Popconfirm, Progress, Spin, Typography, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineFile,
  AiOutlineUpload,
} from "react-icons/ai";
import { FaEye, FaTimes } from "react-icons/fa";
import { MdPhotoCamera } from "react-icons/md";
import styles from "../scss/module/upload.module.scss";
import { axios, getToken, URL_API } from "../utils";
import {
  beforeUploadFile,
  bytesToSize,
  resCatchModal,
} from "../views/util/helper";
import CameraModal from "./CameraModal";
import ImagePreviewModal from "./ImagePreviewModal";

const { Text } = Typography;

const UploadFile = ({
  file = {
    id: null,
    azure_url: null,
    file_type: null,
    file_name: null,
    file_size: 0,
    temp: true,
  },
  hasError = false,
  acceptImage = true,
  acceptPDF = false,
  acceptVideo = false,
  camera = false,
  disabled = false,
  onChange = () => null,
  onDelete = () => null,
  containerName = "operations",
}) => {
  const { id, azure_url, file_type, file_name, file_size, temp } = file;
  const [upLoadProgress, setUpLoadProgress] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fileAccept, setFileAccept] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    return () => {
      source.cancel("unmounted");
    };
  }, []);

  useEffect(() => {
    let types = [];
    if (acceptImage) types = [...types, "image/jpeg", "image/png"];
    if (acceptPDF) types = [...types, "application/pdf"];
    if (acceptVideo) types = [...types, "video/mp4"];
    setFileAccept(types);
  }, [acceptImage, acceptPDF, acceptVideo]);

  const handleCloseCamera = useCallback(() => setShowCamera(false), []);
  const handleClosePreview = useCallback(() => setShowPreview(false), []);

  const beforeUpload = useCallback(
    (file) => beforeUploadFile(file, { acceptImage, acceptPDF, acceptVideo }),
    [acceptImage, acceptPDF, acceptVideo]
  );

  const handleUpload = useCallback(
    (e) => {
      setShowCamera(false);
      setLoading(true);
      const data = new FormData();
      data.append("file", e.file);
      data.append("container_name", containerName);
      axios({
        method: "post",
        url: `${URL_API}/file/upload`,
        headers: { Authorization: "Bearer " + getToken() },
        data,
        cancelToken: source.token,
        onUploadProgress: (e) =>
          setUpLoadProgress(~~((e.loaded * 100) / e.total)),
      })
        .then((res) => onChange({ ...res.data.data, temp: true }))
        .catch((e) => resCatchModal(e))
        .finally(() => {
          setSource(axios.CancelToken.source());
          setLoading(false);
        });
    },
    [source.token]
  );

  const handleDelete = useCallback(() => {
    if (!temp) return onDelete();
    setDeleting(true);
    axios({
      method: "post",
      url: `${URL_API}/file/del`,
      headers: { Authorization: "Bearer " + getToken() },
      data: { id },
      cancelToken: source.token,
    })
      .then(() => onDelete())
      .catch((e) => resCatchModal(e))
      .finally(() => {
        setSource(axios.CancelToken.source());
        setDeleting(false);
      });
  }, [id, temp, source.token]);

  return (
    <div
      className={`${styles[`upload-file`]} ${
        disabled ? styles.disabled : hasError ? styles.error : ""
      }`}
    >
      {file_size && !deleting ? (
        <>
          <Col
            span={4}
            xl={3}
            className="d-flex justify-content-center align-items-center"
          >
            <AiOutlineFile
              className={`${styles.icon} ${temp ? "" : styles.commit}`}
            />
          </Col>
          <Col
            span={16}
            xl={18}
            className="d-flex justify-content-center flex-column"
          >
            <div className={styles.overflow}>
              <Text className={styles["file-name"]}>{file_name}</Text>
            </div>
            <Text className={styles["file-size"]}>
              {bytesToSize(file_size)}
            </Text>
          </Col>
          <Col
            span={4}
            xl={3}
            className="d-flex justify-content-center align-items-center"
          >
            <FaEye
              className={`${styles.btn} mr-2`}
              onClick={() => {
                !~file_type.indexOf("pdf")
                  ? setShowPreview(true)
                  : window.open(`${URL_API}/file/https-stream/${id}`, "_blank");
              }}
            />
            {!disabled && (
              <Popconfirm
                placement="topRight"
                title={`ต้องการลบรายการนี้หรือไม่`}
                onConfirm={handleDelete}
                okText="ลบ"
                cancelText="ไม่ลบ"
              >
                <AiOutlineDelete
                  className={`${styles.btn} ${disabled ? styles.disabled : ""}`}
                />
              </Popconfirm>
            )}
          </Col>
        </>
      ) : file_size && deleting ? (
        <>
          <Col
            span={4}
            xl={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Spin size="small" className="mr-2" />
          </Col>
          <Col span={16} xl={18}>
            <Text>deleting...</Text>
          </Col>
          <Col
            span={4}
            xl={3}
            className="d-flex justify-content-center align-items-center"
          >
            <FaTimes
              className={styles.btn}
              onClick={() => {
                source.cancel("cancel");
                setSource(axios.CancelToken.source());
              }}
            />
          </Col>
        </>
      ) : loading ? (
        <>
          <Col
            span={20}
            xl={21}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Progress
              size="small"
              percent={upLoadProgress}
              status="active"
              strokeColor="#97d700"
              showInfo={false}
            />
            <Text>{upLoadProgress}%</Text>
          </Col>
          <Col
            span={4}
            xl={3}
            className="d-flex justify-content-center align-items-center"
          >
            <FaTimes
              className={styles.btn}
              onClick={() => {
                source.cancel("cancel");
                setSource(axios.CancelToken.source());
              }}
            />
          </Col>
        </>
      ) : (
        <>
          <Upload
            disabled={disabled}
            accept={fileAccept}
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={handleUpload}
            className="w-100 h-100 cursor-pointer pos-document-upload-btn"
          >
            <div className={styles["block-upload"]}>
              <Col
                span={4}
                xl={3}
                className="d-flex justify-content-center align-items-center"
              >
                <AiOutlineUpload className={styles.icon} />
              </Col>
              <Col span={16} xl={18} className="d-flex align-items-center">
                <Text>อัปโหลด</Text>
              </Col>
            </div>
          </Upload>
          {!disabled && acceptImage && camera && !file_size && (
            <Col
              span={4}
              xl={3}
              className="d-flex justify-content-center align-items-center"
            >
              <MdPhotoCamera
                className={`${styles.btn} ${disabled ? styles.disabled : ""}`}
                onClick={() => setShowCamera(true)}
              />
            </Col>
          )}
        </>
      )}
      {acceptImage && (
        <>
          {camera && (
            <CameraModal
              visible={showCamera}
              allowChangeCamera
              onSubmit={handleUpload}
              onCancel={handleCloseCamera}
            />
          )}
          <ImagePreviewModal
            azureUrl={showPreview ? azure_url : null}
            fileType={file_type}
            onCancel={handleClosePreview}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(UploadFile);
