import { LockOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { URL_API, getToken, getUser } from "../../../../utils";
import { AlertError } from "../../../util/Alert.js";
export default function TierAdminModal({
  isModalOpen = false,
  dataModal,
  handleOk,
  handleCancel,
}) {
  // console.log(dataModal);
  // console.log(handleShowPreview())
  const inputRef = useRef(null);
  const [password, setPassword] = useState("");
  const user = getUser();
  const [error_password, setErrorPassword] = useState(false);
  const handleModalOk = () => {
    if (password === "") {
      setErrorPassword(true);
    } else {
      // Leg@cy100
      let result = null;
      axios({
        method: "POST",
        baseURL: URL_API,
        url: `/landing`,
        headers: { Authorization: "Bearer " + getToken() },
        data: { password },
      })
        .then(async (res) => {
          result = res.data;
          if (result.status === 400) {
            await AlertError({ title: result.message, text: "" });
          } else {
            handleOk();
            const url = `${process.env.REACT_APP_MEMBER_URL}/landing/?user_id=${user.id}&member_code=${dataModal.member_code}&member_type=${dataModal.member_type}&token=${result.ViewToken}`;
            window.open(url, "_blank");
          }
        })
        .catch(async (err) => {
          await AlertError({ title: result.message, text: err });
        });
      setErrorPassword(false);
    }
  };
  const handleModalCancel = () => {
    handleCancel();
  };

  useEffect(() => {
    setPassword("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalOpen, inputRef]);

  return (
    <Modal
      title="Login"
      visible={isModalOpen}
      onCancel={handleModalCancel}
      onOk={handleModalOk}
      okText="ยืนยัน"
      footer={[
        <Button
          style={{
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleModalOk}
        >
          Login
        </Button>,
      ]}
    >
      <Input.Password
        size="large"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ border: error_password ? "1px solid #ff5b57" : "" }}
        prefix={<LockOutlined />}
        ref={inputRef}
        autoFocus
        onKeyUp={(e) => {
          if (e.key === "Enter") handleModalOk();
        }}
      />
      <span style={{ fontSize: 11, color: "#ff5b57" }} className="ml-1 mt-1">
        {error_password === true ? "กรุณากรอกข้อมูล" : ""}
      </span>
    </Modal>
  );
}
