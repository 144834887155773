import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";
import { _ } from "../../../../utils";

const initPayment = {
  payment_date: null,
  payment_type: null,
  payment_type_name: null,
  bank: null,
  bank_name: null,
  ref_no: null,
  price: 0,
  commit: false,
  reserve_no: null,
};

export const initState = {
  loading: false,
  search: {
    query: null,
    page: 1,
    limit: 10,
    status: "",
    type: "",
    date: null,
    branch_code: "",
  },
  billModal: {
    visible: false,
    id: null,
    is_used: null,
    division_id: null,
    reserve_order_no: null,
    reserve_no: null,
    partner_code: null,
    status: null,
    disabled: true,
    rp_hold: 0,
    rp_topup: 0,
    cancel_modal_visible: false,
  },
  historyModal: {
    document_no: null,
    limit: 10,
  },
  payment: {
    itemKeys: [],
    itemByKey: {},
  },
  user: {
    user_id: null,
    permission_status: {},
  },
  kBankCustomerId: "",
  paymentGateWay: {
    debug: null,
    merchant_id: null,
    merchant_id_installment: null,
    merchant_name: null,
    public_key: null,
  },
};

export const reducer = createReducer(initState, {
  SET_GLOBAL_STATE: (state, { payload: { name, value } }) => {
    if (_.isObject(state[name])) {
      state[name] = {
        ...state[name],
        ...value,
      };
    } else {
      state[name] = value;
    }
  },
  CLEAR_GLOBAL_STATE: (state, { payload }) => {
    state[payload] = _.cloneDeep(initState[payload]);
  },
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  LOAD_PAYMENT: (state, { payload }) => {
    let itemKeys = [];
    let itemByKey = {};
    _.forEach(payload, (item) => {
      const key = _.uniqueId("payment-");
      itemKeys = [...itemKeys, key];
      itemByKey = {
        ...itemByKey,
        [key]: {
          ...item,
          commit: true,
          key,
        },
      };
    });
    state.payment.itemKeys = itemKeys;
    state.payment.itemByKey = itemByKey;
  },
  ADD_PAYMENT: (state, { payload }) => {
    const key = _.uniqueId("payment-");
    state.payment.itemKeys = [...state.payment.itemKeys, key];
    state.payment.itemByKey = {
      ...state.payment.itemByKey,
      [key]: {
        ..._.cloneDeep(initPayment),
        ...payload,
        key,
      },
    };
  },
  SET_PAYMENT: (state, { payload: { key, ...data } }) => {
    state.payment.itemByKey[key] = {
      ...state.payment.itemByKey[key],
      ...data,
    };
  },
});

const PreOrderContext = createContext();
export default PreOrderContext;
