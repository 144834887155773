import { Col, Row, Typography } from "antd";
import React, { memo } from "react";
import { _, moment } from "../../../../utils";

const { Title } = Typography;

const WarningExpireDate = ({
  status,
  warnExpireDate,
  registerRenewEndDate,
}) => {
  if (
    warnExpireDate === null ||
    !_.includes(
      ["active", "pending", "waiting", "expired"],
      status.toLowerCase()
    )
  )
    return null;

  return (
    <>
      {warnExpireDate <= 30 && warnExpireDate >= 0 ? (
        <Row type="flex" align="middle" className="mb-4">
          <Col md={{ span: 14, offset: 10 }}>
            <Title level={4} className="m-0 font-weight-normal text-nn">
              ({ warnExpireDate == 0 ? (`วันนี้ หมดอายุสมาชิก`):(`อีก ${warnExpireDate} วัน จะหมดอายุสมาชิก`)})
            </Title>
          </Col>
        </Row>
      ) : (
        (warnExpireDate < 0 && registerRenewEndDate) && (
          <Row type="flex" align="middle" className="mb-4">
            <Col md={{ span: 14, offset: 10 }}>
              <Title level={4} className="m-0 font-weight-normal text-nn">
                {/* ({`เหลือเวลาต่ออายุสมาชิกอีก ${90 + warnExpireDate} วัน`}) */}
                (
                {`ต่ออายุสมาชิกได้วันสุดท้าย ${moment(
                  registerRenewEndDate
                ).format("DD/MM/YYYY")}`}
                )
              </Title>
            </Col>
          </Row>
        )
      )}
    </>
  );
  // return (
  //   <>
  //     {warnExpireDate !== null && warnExpireDate <= 30 && warnExpireDate > 0 && (
  //       <Row type="flex" align="middle" className="mb-4">
  //         <Col md={{ span: 14, offset: 10 }}>
  //           <Title level={4} className="m-0 font-weight-normal text-nn">
  //             ({`อีก ${warnExpireDate + 1} วัน จะหมดอายุสมาชิก`})
  //           </Title>
  //         </Col>
  //       </Row>
  //     )}
  //     {warnExpireDate !== null && warnExpireDate <= 30 && warnExpireDate === 0 && (
  //       <Row type="flex" align="middle" className="mb-4">
  //         <Col md={{ span: 14, offset: 10 }}>
  //           <Title level={4} className="m-0 font-weight-normal text-nn">
  //             (สมาชิกจะหมดอายุวันนี้)
  //           </Title>
  //         </Col>
  //       </Row>
  //     )}
  //     {status &&
  //       status.toLowerCase() === "expired" &&
  //       warnExpireDate !== null &&
  //       warnExpireDate < 0 && (
  //         <Row type="flex" align="middle" className="mb-4">
  //           <Col md={{ span: 14, offset: 10 }}>
  //             <Title level={4} className="m-0 font-weight-normal text-nn">
  //               ({`เหลือเวลาต่ออายุสมาชิกอีก ${91 + warnExpireDate} วัน`})
  //             </Title>
  //           </Col>
  //         </Row>
  //       )}
  //   </>
  // );
};

export default memo(WarningExpireDate);
