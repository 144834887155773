/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { AlertConfirm } from '../../../util/Alert';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import {
  EventCalendarAnnounceStatus,
  eventCalendarAnnounceConstants,
  eventCalendarAnnounceFormDefaultValues,
  eventCalendarAnnounceFormSchema,
} from './components/dto/event-calendar-announce';
import useModalHook from '../../../util/useModalHook';
import EventCalendarAnnounceModal from './components/modal/EventCalendarAnnounceModal';
import eventCalendarAnnounceServices from '../../../../services/event-calendar-announce.services';

const EventCalendarAnnounce = () => {
  const {
    data: eventCalendarAnnounceLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  eventCalendarAnnounceServices.useQueryGetAllEventCalendarAnnounces(
    {
      take: eventCalendarAnnounceConstants.tableSize,
      page,
    },
    (data) => {
      setTotal(data.meta.total);
      setData(data.data);
    }
  );

  const { mutate: deleteEventCalendarAnnounce } =
    eventCalendarAnnounceServices.useMutationDeleteEventCalendarAnnounce(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-all-event-calendar-announces'],
        refetchType: 'all',
      });
    });
  const { mutate: updateEventCalendarAnnounceStatus } =
    eventCalendarAnnounceServices.useMutationUpdateEventCalendarAnnounceStatus(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-all-event-calendar-announces'],
          refetchType: 'all',
        });
      }
    );

  const eventCalendarAnnounceForm = useForm({
    defaultValues: eventCalendarAnnounceFormDefaultValues,
    resolver: eventCalendarAnnounceFormSchema(),
  });

  const {
    open: eventCalendarAnnounceFormOpen,
    handleOpen: _HandleEventCalendarAnnounceFormOpen,
    handleClose: _HandleEventCalendarAnnounceFormClose,
  } = useModalHook({
    insideHandleClose: () => {
      eventCalendarAnnounceForm.reset(eventCalendarAnnounceFormDefaultValues);
    },
  });

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrl}
          src={record.thumbnailImageUrl}
          alt={record.thumbnailImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'หัวข้อ',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'คำบรรยาย',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <section dangerouslySetInnerHTML={{ __html: record.description }} />
      ),
    },
    {
      title: 'วันประกาศ',
      dataIndex: 'announceDate',
      key: 'announceDate',
      align: 'center',
      render: (_, record) => (
        <p>{moment(record.announceDate).format('DD/MMM/YYYY')}</p>
      ),
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <span>
          {record.status === EventCalendarAnnounceStatus[0] ? 'เปิด' : 'ปิด'}
        </span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.status === EventCalendarAnnounceStatus[0] ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการมองเห็น');
                if (confirm) {
                  updateEventCalendarAnnounceStatus({
                    eventCalendarAnnounceId: record.id,
                    status: EventCalendarAnnounceStatus[1],
                  });
                }
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการมองเห็น');
                if (confirm) {
                  updateEventCalendarAnnounceStatus({
                    eventCalendarAnnounceId: record.id,
                    status: EventCalendarAnnounceStatus[0],
                  });
                }
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              eventCalendarAnnounceForm.setValue('id', record.id);
              eventCalendarAnnounceForm.setValue('formType', 'edit');
              _HandleEventCalendarAnnounceFormOpen();
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบงานอีเวนท์');
              if (confirm) {
                deleteEventCalendarAnnounce({
                  eventCalendarAnnounceId: record.id,
                });
              }
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <Panel>
      <PanelHeader>ประกาศปฏิทินงานอีเวนท์</PanelHeader>
      <PanelBody>
        <div className="d-flex justify-content-end mb-2">
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              eventCalendarAnnounceForm.setValue('formType', 'create');
              _HandleEventCalendarAnnounceFormOpen();
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มประกาศงานอีเวนท์
          </Button>
        </div>
        <Table
          scroll={{ x: true }}
          onRow={(record, rowIndex) => {
            return {
              //   onClick: (event) => {
              //     history.push(`classcategory/${record.id}`);
              //   }, // click row
            };
          }}
          columns={columns}
          dataSource={eventCalendarAnnounceLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize: eventCalendarAnnounceConstants.tableSize,
            total: total,
          }}
        />
      </PanelBody>
      <FormProvider {...eventCalendarAnnounceForm}>
        <EventCalendarAnnounceModal
          open={eventCalendarAnnounceFormOpen}
          handleClose={_HandleEventCalendarAnnounceFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default EventCalendarAnnounce;
