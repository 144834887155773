import { notification, Select } from "antd";
import React, { forwardRef } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";

const SelectNationality = (props, ref) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/ethics/nationality`,
      token: true,
    },
    []
  );

  if (error) {
    notification.error({
      description: error,
      placement: "bottomRight",
    });
  }
  return (
    <>
      <Select 
        loading={loading} 
        {...props}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>  
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
          || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data &&
          data.data &&
          data.data.map((n, i) => (
            <Select.Option key={i} value={n.code}>
              {n.name}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};
export default forwardRef(SelectNationality);
