/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row
} from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../../../components/TextEditor";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";

function NewsFlashNews({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");
  const [contentTextEditor, setContentTextEditor] = useState();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
          content: contentTextEditor,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path = "/manage-website/news/flash-news/update")
              : (url_path = "/manage-website/news/flash-news");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/news/flash-news",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);
        if (res.data.data) {
          setContentTextEditor(res.data.data.content);
        }

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>Flash News</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Row className="mb-3">
              <p>Content :</p>
              <TextEditor
                authToken={getToken()}
                value={contentTextEditor}
                onInit={(evt, event) => {}}
                onEditorChange={(e) => {
                  setContentTextEditor(e);
                }}
                onUploadSuccess={(json) => {
                  console.log(json);
                }}
                postBody={{ container_name: "web-content" }}
                urlUpload={`${URL_API}/file/upload`}
              />
            </Row>

            <Row className="mb-3">
              <Form.Item label="Target Link :">
                {getFieldDecorator("target_link", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ Target Link" }],
                })(<Input />)}
              </Form.Item>
            </Row>

            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Target Tab :">
                  {getFieldDecorator("target_tab", {
                    initialValue: "",
                  })(
                    <Radio.Group>
                      <Radio value={"new_tab"}>New Tab</Radio>
                      <Radio value={"stay_tab"}>Stay Tab</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("news-flash-news")(NewsFlashNews);
