import React from "react";
import { Switch, Route } from "react-router-dom";

//page
import VoucherList from "./voucher-list";
import VoucherManage from "./voucher-manage";
import Error404 from "../../../Error404";

export default ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={VoucherList} />
    <Route path={`${match.path}/:id`} component={VoucherManage} />

    <Route component={Error404} />
  </Switch>
);
