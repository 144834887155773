/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../../../components/TextEditor";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";
import mwEcosysConstants from "../../utils/constants/mwEcosysConstants";
import reactImageSize from "react-image-size";
import { dummyRequest } from "../../components/dummyRequest";
import useUploadFile from "../../components/useUploadFile";
import { DivStyles, ImgStyles } from "../../components/mwStyles";

function EcoCard({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");
  const [fileDelete, setFileDelete] = useState([]);

  const [fileListDesktop1, setFileListDesktop1] = useState([]);
  const [fileListDesktop2, setFileListDesktop2] = useState([]);
  const [fileListDesktop3, setFileListDesktop3] = useState([]);
  const [fileListDesktop4, setFileListDesktop4] = useState([]);

  const [fileListMobile1, setFileListMobile1] = useState([]);
  const [fileListMobile2, setFileListMobile2] = useState([]);
  const [fileListMobile3, setFileListMobile3] = useState([]);
  const [fileListMobile4, setFileListMobile4] = useState([]);

  const desktopWidth = mwEcosysConstants.eco_card_page.desktop_img.width;
  const desktopHeight = mwEcosysConstants.eco_card_page.desktop_img.height;
  const mobileWidth = mwEcosysConstants.eco_card_page.mobile_img.width;
  const mobileHeight = mwEcosysConstants.eco_card_page.mobile_img.height;

  let { onUpload, onRemoveUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (fileListDesktop1[0]?.file) {
        formData.append(
          "desktop_img1",
          fileListDesktop1[0]?.file?.originFileObj
        );
      }

      if (fileListMobile1[0]?.file) {
        formData.append("mobile_img1", fileListMobile1[0]?.file?.originFileObj);
      }

      if (fileListDesktop2[0]?.file) {
        formData.append(
          "desktop_img2",
          fileListDesktop2[0]?.file?.originFileObj
        );
      }

      if (fileListMobile2[0]?.file) {
        formData.append("mobile_img2", fileListMobile2[0]?.file?.originFileObj);
      }

      if (fileListDesktop3[0]?.file) {
        formData.append(
          "desktop_img3",
          fileListDesktop3[0]?.file?.originFileObj
        );
      }

      if (fileListMobile3[0]?.file) {
        formData.append("mobile_img3", fileListMobile3[0]?.file?.originFileObj);
      }

      if (fileListDesktop4[0]?.file) {
        formData.append(
          "desktop_img4",
          fileListDesktop4[0]?.file?.originFileObj
        );
      }

      if (fileListMobile4[0]?.file) {
        formData.append("mobile_img4", fileListMobile4[0]?.file?.originFileObj);
      }

      // for (let i in fileListDesktop1) {
      //   if (values.desktop_img1?.fileList) {
      //     console.log("i:", i);

      //     formData.append(
      //       "desktop_img1",
      //       values.desktop_img1?.fileList[i].originFileObj
      //     );
      //   }
      // }

      // for (let i in fileListDesktop2) {
      //   if (values.desktop_img2?.fileList) {
      //     formData.append(
      //       "desktop_img2",
      //       values.desktop_img2?.fileList[i].originFileObj
      //     );
      //   }
      // }

      // for (let i in fileListDesktop3) {
      //   if (values.desktop_img3?.fileList) {
      //     formData.append(
      //       "desktop_img3",
      //       values.desktop_img3?.fileList[i].originFileObj
      //     );
      //   }
      // }

      // for (let i in fileListDesktop4) {
      //   if (values.desktop_img4?.fileList) {
      //     formData.append(
      //       "desktop_img4",
      //       values.desktop_img4?.fileList[i].originFileObj
      //     );
      //   }
      // }

      // for (let i in fileListMobile1) {
      //   if (values.mobile_img1?.fileList) {
      //     console.log("i:", i);

      //     formData.append(
      //       "mobile_img1",
      //       values.mobile_img1?.fileList[i].originFileObj
      //     );
      //   }
      // }
      // for (let i in fileListMobile2) {
      //   if (values.mobile_img2?.fileList) {
      //     formData.append(
      //       "mobile_img2",
      //       values.mobile_img2?.fileList[i].originFileObj
      //     );
      //   }
      // }
      // for (let i in fileListMobile3) {
      //   if (values.mobile_img3?.fileList) {
      //     formData.append(
      //       "mobile_img3",
      //       values.mobile_img3?.fileList[i].originFileObj
      //     );
      //   }
      // }
      // for (let i in fileListMobile4) {
      //   if (values.mobile_img4?.fileList) {
      //     formData.append(
      //       "mobile_img4",
      //       values.mobile_img4?.fileList[i].originFileObj
      //     );
      //   }
      // }
      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, [
            "desktop_img",
            "mobile_img",
            "desktop_img1",
            "mobile_img1",
            "desktop_img2",
            "mobile_img2",
            "desktop_img3",
            "mobile_img3",
            "desktop_img4",
            "mobile_img4",
          ]),
          file_delete: fileDelete,
        })
      );

      console.log('formData ', ...formData)
      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path = "/manage-website/ecosystem/card/update")
              : (url_path = "/manage-website/ecosystem/card");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop1 = (e) => {
    setFileListDesktop1([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop2 = (e) => {
    setFileListDesktop2([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop3 = (e) => {
    setFileListDesktop3([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop4 = (e) => {
    setFileListDesktop4([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleRemoveMobile1 = (e) => {
    setFileListMobile1([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveMobile2 = (e) => {
    setFileListMobile2([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveMobile3 = (e) => {
    setFileListMobile3([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveMobile4 = (e) => {
    setFileListMobile4([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleUploadDesktop1 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop1(isFile);
    }
  };

  const handleUploadDesktop2 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop2(isFile);
    }
  };

  const handleUploadDesktop3 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop3(isFile);
    }
  };

  const handleUploadDesktop4 = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop4(isFile);
    }
  };

  const handleUploadMobile1 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile1(isFile);
    }
  };

  const handleUploadMobile2 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile2(isFile);
    }
  };

  const handleUploadMobile3 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile3(isFile);
    }
  };

  const handleUploadMobile4 = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile4(isFile);
    }
  };

  // const handleChangeUploadDesktop1 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));
  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.desktop_img.width &&
  //         height == mwEcosysConstants.eco_card_page.desktop_img.height
  //       ) {
  //         setFileListDesktop1([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  // const handleChangeUploadDesktop2 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));
  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.desktop_img.width &&
  //         height == mwEcosysConstants.eco_card_page.desktop_img.height
  //       ) {
  //         setFileListDesktop2([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };
  // const handleChangeUploadDesktop3 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));
  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.desktop_img.width &&
  //         height == mwEcosysConstants.eco_card_page.desktop_img.height
  //       ) {
  //         setFileListDesktop3([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };
  // const handleChangeUploadDesktop4 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));
  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.desktop_img.width &&
  //         height == mwEcosysConstants.eco_card_page.desktop_img.height
  //       ) {
  //         setFileListDesktop4([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  // const handleChangeUploadMobile1 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));

  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.mobile_img.width &&
  //         height == mwEcosysConstants.eco_card_page.mobile_img.height
  //       ) {
  //         setFileListMobile1([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  // const handleChangeUploadMobile2 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));

  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.mobile_img.width &&
  //         height == mwEcosysConstants.eco_card_page.mobile_img.height
  //       ) {
  //         setFileListMobile2([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  // const handleChangeUploadMobile3 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));

  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.mobile_img.width &&
  //         height == mwEcosysConstants.eco_card_page.mobile_img.height
  //       ) {
  //         setFileListMobile3([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  // const handleChangeUploadMobile4 = async (e) => {
  //   console.log("onchange ", e);
  //   let file = e.file.originFileObj;
  //   if (file) {
  //     let img_url = "";
  //     if (e.file.size / 1024 / 1024 < 3) {
  //       img_url = await fileToBase64(file);
  //     } else {
  //       img_url = await resizeFile(file);
  //       file = await base64toFile(img_url, file.name);
  //     }
  //     // dispatch(ADD_MEDIA({ img_url, file }));

  //     try {
  //       const { width, height } = await reactImageSize(img_url);
  //       console.log(width, height);
  //       if (
  //         width == mwEcosysConstants.eco_card_page.mobile_img.width &&
  //         height == mwEcosysConstants.eco_card_page.mobile_img.height
  //       ) {
  //         setFileListMobile4([
  //           {
  //             uid: file.uid,
  //             status: "done",
  //             name: file.name,
  //             url: img_url,
  //           },
  //         ]);
  //       } else {
  //         Modal.error({
  //           title: "ขนาดรูปภาพไม่ถูกต้อง",
  //           content: `ขนาดรูปที่อัพโหลด ${width} x ${height} px`,
  //           okText: "ปิด",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/card",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);

        for (let i = 0; i < data.files.length; i++) {
          let type = data.files[i].document_type;
          let isName = data.files[i].link_target;
          let isFile = [
            {
              uid: data.files[i].id,
              status: "done",
              name: data.files[i].file_name,
              url: data.files[i].azure_url,
            },
          ];

          if (type == "34" && isName == "desktop_img1") {
            setFileListDesktop1(isFile);
          } else if (type == "35" && isName == "mobile_img1") {
            setFileListMobile1(isFile);
          } else if (type == "34" && isName == "desktop_img2") {
            setFileListDesktop2(isFile);
          } else if (type == "35" && isName == "mobile_img2") {
            setFileListMobile2(isFile);
          } else if (type == "34" && isName == "desktop_img3") {
            setFileListDesktop3(isFile);
          } else if (type == "35" && isName == "mobile_img3") {
            setFileListMobile3(isFile);
          } else if (type == "34" && isName == "desktop_img4") {
            setFileListDesktop4(isFile);
          } else if (type == "35" && isName == "mobile_img4") {
            setFileListMobile4(isFile);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>ECO Card</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Divider />
            <h4>Card 1</h4>
            <Row>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop1.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop1[0].url}
                          alt="desktop_img1"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile1.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile1[0].url}
                        alt="mobile_img1"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Desktop = ${mwEcosysConstants.eco_card_page.desktop_img.width} x ${mwEcosysConstants.eco_card_page.desktop_img.height}`}
                  >
                    {getFieldDecorator("desktop_img1", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop1}
                        customRequest={dummyRequest}
                        onRemove={handleRemoveDesktop1}
                        onChange={handleUploadDesktop1}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Mobile = ${mwEcosysConstants.eco_card_page.mobile_img.width} x ${mwEcosysConstants.eco_card_page.mobile_img.height}`}
                  >
                    {getFieldDecorator("mobile_img1", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile1}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveMobile1}
                        onChange={handleUploadMobile1}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>

            <Divider />
            <h4>Card 2</h4>

            <Row>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop2.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop2[0].url}
                          alt="desktop_img2"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile2.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile2[0].url}
                        alt="mobile_img2"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Desktop = ${mwEcosysConstants.eco_card_page.desktop_img.width} x ${mwEcosysConstants.eco_card_page.desktop_img.height}`}
                  >
                    {getFieldDecorator("desktop_img2", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop2}
                        customRequest={dummyRequest}
                        onRemove={handleRemoveDesktop2}
                        onChange={handleUploadDesktop2}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Mobile = ${mwEcosysConstants.eco_card_page.mobile_img.width} x ${mwEcosysConstants.eco_card_page.mobile_img.height}`}
                  >
                    {getFieldDecorator("mobile_img2", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile2}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveMobile2}
                        onChange={handleUploadMobile2}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>

            <Divider />
            <h4>Card 3</h4>

            <Row>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop3.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop3[0].url}
                          alt="desktop_img3"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile3.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile3[0].url}
                        alt="mobile_img3"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Desktop = ${mwEcosysConstants.eco_card_page.desktop_img.width} x ${mwEcosysConstants.eco_card_page.desktop_img.height}`}
                  >
                    {getFieldDecorator("desktop_img3", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop3}
                        customRequest={dummyRequest}
                        onRemove={handleRemoveDesktop3}
                        onChange={handleUploadDesktop3}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Mobile = ${mwEcosysConstants.eco_card_page.mobile_img.width} x ${mwEcosysConstants.eco_card_page.mobile_img.height}`}
                  >
                    {getFieldDecorator("mobile_img3", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile3}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveMobile3}
                        onChange={handleUploadMobile3}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>
            <Divider />
            <h4>Card 4</h4>

            <Row>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop4.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop4[0].url}
                          alt="desktop_img4"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile4.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile4[0].url}
                        alt="mobile_img4"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Desktop = ${mwEcosysConstants.eco_card_page.desktop_img.width} x ${mwEcosysConstants.eco_card_page.desktop_img.height}`}
                  >
                    {getFieldDecorator("desktop_img4", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop4}
                        customRequest={dummyRequest}
                        onRemove={handleRemoveDesktop4}
                        onChange={handleUploadDesktop4}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col md={12} className="mb-2">
                  <Form.Item
                    label={`Mobile = ${mwEcosysConstants.eco_card_page.mobile_img.width} x ${mwEcosysConstants.eco_card_page.mobile_img.height}`}
                  >
                    {getFieldDecorator("mobile_img4", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile4}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveMobile4}
                        onChange={handleUploadMobile4}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-card")(EcoCard);
