import React from 'react';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Grid, Typography, Upload } from 'antdV4';
import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { useBreakpoint } = Grid;

const ImageUpload = ({
  value,
  onChange,
  width,
  height,
  errors,
  notRequired,
}) => {
  const [loading, setLoading] = useState(false);
  const { execute: uploadFile } = useAsync(fitStudioService.uploadFile, {
    onSuccess: (data) => {
      onChange(data.resourceUrl);
      setLoading(false);
    },
  });
  const screens = useBreakpoint();

  return (
    <Upload.Dragger
      name="file"
      multiple={false}
      onChange={(e) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('image', e.file);
        uploadFile(formData);
      }}
      beforeUpload={() => false}
      showUploadList={false}
    >
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <div>
            <Spin indicator={antIcon} />
          </div>
        ) : value ? (
          <img
            src={value}
            alt={value}
            style={{
              width: !!screens.md ? '50%' : '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        ) : (
          <div>
            <p className="ant-upload-drag-icon"></p>

            <p className="ant-upload-text">
              คลิก หรือ ดรอปไฟล์รูปภาพ
              {width && height && `ขนาด ${width} x ${height} px`}
              {!notRequired && <span style={{ color: 'red' }}>*</span>}
            </p>
            <p className="ant-upload-text" style={{ color: 'red' }}>
              *ชื่อไฟล์ห้ามมีอักขระพิเศษ และภาษาอังกฤษเท่านั้น*
            </p>
            {notRequired && <p className="ant-upload-hint">ไม่จำเป็น</p>}
            <ErrorMessage
              errors={errors}
              name="thumbnailImageUrl"
              render={({ message }) => (
                <Typography.Text className="mb-2 text-danger">
                  {message}
                </Typography.Text>
              )}
            />
          </div>
        )}
      </div>
    </Upload.Dragger>
  );
};

export default ImageUpload;
