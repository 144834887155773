import { Alert, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import PrintButton from "../../../components/PrintButton";
import { useHttp } from "../../../hooks/http";
import { moment, URL_API } from "../../../utils";
import Table from "./components/Table";

const Complete = ({ criteria }) => {
  const limit = 10;
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, data, error] = useHttp(
    {
      // url: `${URL_API}/pos/delivery/transaction_logistic/complete`,
      url: `${URL_API}/pos/delivery/complete`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        ...criteria,
        date_from: criteria.date_from
          ? moment(criteria.date_from).format("YYYY-MM-DD")
          : null,
        date_to: criteria.date_to
          ? moment(criteria.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page, criteria]
  );
  useEffect(() => {
    setPage(1);
  }, [criteria]);
  useEffect(() => {
    let newData = [];
    let total = 0;
    if (data && data.data) {
      newData = data.data.map((n) => ({ ...n, key: n.id }));
      total = data.meta.pagination.total;
    }
    setDataSource(newData);
    setTotalPage(total);
  }, [data]);

  const columns = [
    {
      // title: "เลขที่ใบจัด",
      // key: "prepare_no",
      // dataIndex: "prepare_no",
      title: "เลขที่บิล",
      key: "document_no",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      // title: "วันที่ทำรายการ",
      // key: "time",
      // dataIndex: "create_date",
      title: "วันที่สั่งซื้อ",
      key: "document_date",
      dataIndex: "document_date",
      render: (text) => (
        <p className="m-0 text-center">
          {text == null ? "" : moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "รหัสสมาชิก",
      // key: "buyer_code",
      // dataIndex: "buyer_code",
      key: "partner_code",
      dataIndex: "partner_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ชื่อสมาชิก",
      // key: "buyer_name",
      // dataIndex: "buyer_name",
      key: "partner_name",
      dataIndex: "partner_name",
    },
    {
      title: "ที่อยู่",
      key: "address",
      render: (text, record) => (
        <p className="m-0 text-left">{`${record.ship_address_no} ${record.ship_sub_district} ${record.ship_district} ${record.ship_post_code}`}</p>
      ),
    },
    {
      title: "จังหวัด",
      key: "ship_province",
      dataIndex: "ship_province",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "แหล่งที่มา",
      // key: "origin",
      // dataIndex: "origin",
      key: "bill_location",
      dataIndex: "bill_location",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <p className="m-0 d-flex justify-content-end align-items-center">
          <PrintButton record={record} reportName={record.report_name} />
        </p>
      ),
    },
  ];

  if (error) {
    return <Alert type="error" message={error} />;
  }

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        className="mb-3"
      />
      {totalPage === 0 || totalPage <= limit ? null : (
        <Pagination
          current={page}
          total={totalPage}
          className="text-center"
          onChange={(e) => setPage(e)}
        />
      )}
    </>
  );
};

export default Complete;
