/* eslint-disable no-unused-vars */
import numeral from "numeral";
import React from "react";
import { connect } from "react-redux";
// import { _ } from "../../../../utils";
import { getSummaryTotalPayment } from "./logic";

const PaymentSummary = (props) => (
  <div className="row mb-4">
    <div className="col">
      <h2 className="m-0 text-white">{`ยอดรวม ${props.total_bill} บิล`}</h2>
    </div>
    <div className="col">
      <h2 className="m-0 text-right text-white">
        {numeral(props.summary_total).format("0,0.00")}
      </h2>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const { orders } = state.pos.operation;
  const { summary_total } = getSummaryTotalPayment(orders);
  return {
    summary_total,
    total_bill: orders.itemKeys.length,
  };
};

export default connect(mapStateToProps)(PaymentSummary);
