import React from 'react'

const BillTab = ({ list = [], active, onClick }) => {
    const style = {
        padding: '5px 20px 5px'
    }

    return (
        <ul className="tabs">
            {
                list.map((n, i) => (
                    <li key={i} className={`tab-item ${i === active ? 'active' : ""}`} onClick={() => onClick(i)} style={style}>{n.partner_code}</li>
                ))
            }
        </ul>
    )
}

export default BillTab
