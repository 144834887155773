import React, { useContext } from "react";
import { Row, Table, Typography } from "antd";
import TravelPointContext from "../contexts/travel-point-context";
import moment from "moment";

const { Title } = Typography;
const TravelPointTable = ({ loading, dataSource }) => {
  const { state, dispatch } = useContext(TravelPointContext);
  //console.log(state.page)
  return (
    <>
      <Row className="mt-3 mb-2">
        <Title level={4}>
          {" "}
          {state?.redeem?.partner_name !== "" ? state.redeem.partner_name : ""}
        </Title>
      </Row>
      <Table
        //style={{ marginTop: "3px" }}
        dataSource={dataSource}
        rowKey="row_id"
        bordered
        loading={loading}
        scroll={{ x: "max-content" }}
        pagination={{
          current: state.page,
          pageSize: 50,
          total: dataSource?.length !== 0 ? dataSource?.length : 0,
          size: "default",
          onChange: (currPage) =>
            dispatch({ type: "setPage", payload: currPage }),
        }}
      >
        <Table.Column
          title="#"
          rowKey="row_id"
          dataIndex="row_id"
          //   render={(text) => (text === "total" ? "รวมทั้งสาขา" : text)}
        />
        <Table.Column
          title="วันที่ดำเนินการ"
          rowKey="created"
          dataIndex="created"
          render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
        />
        <Table.Column
          title="ประเภท"
          rowKey="order_type"
          dataIndex="order_type"
          render={(text) => (text === "Bill" ? "ซื้อสินค้า" : "แลกคะแนน")}
        />
        <Table.Column
          title="เลขที่ใบเสร็จ / Packages"
          rowKey="document_no"
          dataIndex="document_no"
          //   render={(text) => (text === "total" ? "รวมทั้งสาขา" : text)}
        />
        <Table.Column
          title="Description"
          rowKey="description"
          dataIndex="description"
          //   render={(text) => (text === "total" ? "รวมทั้งสาขา" : text)}
        />
        <Table.Column
          title="คะแนน TP"
          rowKey="tp"
          dataIndex="tp"
          render={(text) => {
            return (
              <p
                style={{
                  color: text < 0 ? "#ED212D" : "#97D700",
                  marginBottom : 0
                }}
              >
                {text}
              </p>
            );
          }}
        />
      </Table>
    </>
  );
};
export default TravelPointTable;
