import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const digitalProductProjectDefaultValues = {
  thumbnailImageUrl: '',
  title: '',
  description: '',
  subdescription: '',
  startSubmitingDate: moment(),
  endSubmitingDate: moment(),
  startVotingDate: moment(),
  endVotingDate: moment(),
  display: true,
  width: 0,
  height: 0,
  extension: '',
  fileSize: 0,
  policy: '',
  policyImageUrl: '',
  requestOrderNote: '',
};

export const digitalProductProjectFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      thumbnailImageUrl: yup.string().required('กรุณาเลือกรูปภาพ'),
      title: yup.string().required('กรุณากรอกข้อมูล'),
      description: yup.string().defined(),
      subdescription: yup.string().defined(),
      startSubmitingDate: yup.date().defined(),
      endSubmitingDate: yup.date().defined(),
      startVotingDate: yup.date().defined(),
      endVotingDate: yup.date().defined(),
      display: yup.boolean().defined(),
      width: yup.number().required(),
      height: yup.number().required(),
      extension: yup.string().required('กรุณากรอกข้อมูล'),
      fileSize: yup.number().required(),
      policy: yup.string().defined(),
      policyImageUrl: yup.string().defined(),
      requestOrderNote: yup.string().defined(),
    })
  );
};

export const digitalProductDefaultValues = {
  name: '',
  description: '',
  memberCode: '',
  memberType: 'Distributor',
  isPartner: false,
  image: '',
  requestOrder: false,
  phoneNumber: '',
};

export const digitalProductFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      name: yup.string().required(),
      description: yup.string().defined(),
      memberCode: yup.string().required(),
      memberType: yup.string().required(),
      isPartner: yup.boolean().defined(),
      image: yup.string().required(),
      requestOrder: yup.boolean().defined(),
      phoneNumber: yup.string().defined(),
    })
  );
};
