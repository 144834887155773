/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, message, Modal, Row, Spin, Tabs } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import UserContext, {
  initState,
  reducer,
} from "../../../../../contexts/user.context";
import { getCancelToken } from "../../../../../hooks/http2";
import { axios, getToken, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BranchList from "./components/BranchList";
import FormUser from "./components/FormUser";
import ProductTypeList from "./components/ProductTypeList";
import RoleList from "./components/RoleList";

const Detail = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [formRef, setFormRef] = useState(null);
  const [state, dispatch] = useReducer(reducer, initState);

  let source = getCancelToken();

  const handleClickSave = () => {
    formRef.validateFieldsAndScroll((err, value) => {
      if (!err) {
        Modal.confirm({
          title: "ยืนยันการทำรายการ",
          content: "ต้องการบันทึกข้อมูลหรือไม่?",
          okText: "บันทึก",
          cancelText: "ยกเลิก",

          onOk() {
            return new Promise((resolve, reject) => {
              source = getCancelToken();

              axios({
                method: "post",
                baseURL: URL_API,
                url: "/setting/permission/user/" + match.params.id,
                headers: {
                  Authorization: "Bearer " + getToken(),
                },
                data: {
                  ...value,
                  roles: state.form.roles,
                  branch: state.form.branch,
                  product_type: state.form.product_type,
                },
                cancelToken: source.token,
              })
                .then((res) => {
                  resolve(res.data);
                })
                .catch((err) => {
                  reject(
                    err && err.response
                      ? err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                      : err.message
                  );
                });
            })
              .then((value) => {
                Modal.success({
                  title: "สำเร็จ",
                  content: "บันทึกข้อมูลเรียบร้อย",
                  okText: "ปิด",
                  onOk() {
                    history.push("/setting/permission/user");
                  },
                });
              })
              .catch((reason) => {
                Modal.error({
                  title: "ผิดพลาด",
                  content: reason,
                  okText: "ปิด",
                });
              });
          },
        });
      }
    });
  };

  useEffect(() => {
    if (match.params.id && match.params.id !== "0") {
      dispatch({ type: "SET_LOADING", payload: true });
      source = getCancelToken();

      axios({
        baseURL: URL_API,
        url: "/setting/permission/user/" + match.params.id,
        headers: { Authorization: "Bearer " + getToken() },
        cancelToken: source.token,
      })
        .then((res) => {
          dispatch({ type: "SET_LOADING", payload: false });
          dispatch({
            type: "SET_FORM_VALUE",
            payload: {
              ...res.data.data,
              department_id: res.data.data?.department_id || "",
              division_id: res.data.data?.division_id || "",
            },
          });
        })
        .catch((err) => {
          dispatch({ type: "SET_LOADING", payload: false });
          message.error(
            err && err.response
              ? err.response.data.message
                ? err.response.data.message
                : err.response.data
              : err.message
          );
        });
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>แก้ไขข้อมูลผู้ใช้งาน</PanelHeader>
        <PanelBody>
          <Spin spinning={state.loading}>
            <Row gutter={16}>
              <Col md={12}>
                <FormUser ref={setFormRef} />
                <Row gutter={16} className="mt-3">
                  <Col md={{ span: 6, offset: 10 }}>
                    <Button
                      type="danger"
                      block
                      onClick={() => history.goBack()}
                    >
                      กลับ
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button type="primary" block onClick={handleClickSave}>
                      บันทึก
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Tabs type="card">
                  <Tabs.TabPane tab="บทบาท" key="role">
                    <RoleList />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="สาขา" key="branch">
                    <BranchList />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="ประเภทสินค้า" key="product_type">
                    <ProductTypeList />
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Spin>
        </PanelBody>
      </Panel>
    </UserContext.Provider>
  );
};

export default Detail;
