import { createSlice } from "@reduxjs/toolkit";
import { _ } from "../../../utils/index";

const initState = {
  search: {
    query: "",
    branch: "",
    from_branch: "",
    start_date: null,
    end_date: null,
    page: 1,
    limit: 10,
    offset: 0
  },
  form: {
    id: 0,
    from_branch_code: "",
    branch_name: "",
    create_date: null,
    create_by: "",
    list: {
      itemKeys: [],
      itemByKey: {},
    },
    status: "",
  },
};

const storeQrTransferSlice = createSlice({
  name: "transfer",
  initialState: initState,
  reducers: {
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    CLEAR_FORM: (state, { payload }) => {
      state.form = {
        ...initState.form,
        ...payload,
      };
    },
    LOAD_FORM: (state, { payload }) => {
      const { list = [], ...data } = payload;

      let loadItemKeys = [];
      let loadItemByKey = {};

      _.forEach(list, (n) => {
        let loadKey = _.uniqueId("qr-");
        loadItemKeys.push(loadKey);
        loadItemByKey[loadKey] = n;
      });

      state.form = {
        ...initState.form,
        ...data,
        list: {
          itemKeys: loadItemKeys,
          itemByKey: loadItemByKey,
        },
      };
    },
    SET_FORM: (state, { payload }) => {
      state.form = {
        ...state.form,
        ...payload,
      };
    },
    SELECT_QR: (state, { payload }) => {
      let selectQrKey = _.uniqueId("qr-");

      state.form.list.itemKeys = [...state.form.list.itemKeys, selectQrKey];
      state.form.list.itemByKey[selectQrKey] = {
        ..._.omit(payload, ["id"]),
      };
    },
    DELETE_FROM_LIST:(state, { payload}) =>{
      let delListItemKeys = [...state.form.list.itemKeys];
      let delListItemByKey = { ...state.form.list.itemByKey };

      if (delListItemByKey[payload].id == null) {
        delListItemKeys = _.filter(delListItemKeys, (n) => n !== payload);
        delListItemByKey = _.omit(delListItemByKey, [payload]);
        
      } else {
        delete delListItemByKey[payload]
        delListItemKeys = _.filter(delListItemKeys, (n) => n !== payload);
      }
      state.form.list.itemKeys = delListItemKeys;
      state.form.list.itemByKey = delListItemByKey;
    },
    CLEAR_FROM_LIST:(state,{payload}) =>{
      state.form.list.itemKeys = []
      state.form.list.itemByKey = {}
    }
  },
});

export const actionStoreQrTransfer = storeQrTransferSlice.actions;

export const searchSelector = (state) => state.storeQR.transfer.search;
export const formSelector = (state) => state.storeQR.transfer.form;

const StoreQrTransferReducer = storeQrTransferSlice.reducer;

export default StoreQrTransferReducer;
