import React, { useState } from "react";
import HonorModal from "./HonorModal";
import ItemList from "./ItemList";

const initialHonor = {
  visible: false,
  type: null,
};

const honorList = [
  {
    title: "Honor Code",
    type: "honor_code",
  },
  {
    title: "Highest Pin",
    type: "highest_pin",
  },
  {
    title: "Current Pin",
    type: "current_pin",
  },
];

const Index = () => {
  const [showHonor, setShowHonor] = useState(initialHonor);
  return (
    <>
      {honorList.map((n, i) => (
        <ItemList
          key={i}
          onShowHonor={() =>
            setShowHonor({ visible: true, ...n })
          }
          {...n}
        />
      ))}
      <HonorModal {...showHonor} onCancel={() => setShowHonor(initialHonor)} />
    </>
  );
};
export default Index;
