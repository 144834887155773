import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import digitalProductServices from '../../../../../services/digital-product.services';
import { AlertConfirm, AlertError } from '../../../../util/Alert';
import RejectDigitalProductModal from './RejectDigitalProductModal';

const DigitalProductModal = ({
  open,
  handleClose,
  digitalProductId,
  width,
  height,
}) => {
  const queryClient = useQueryClient();
  const [rejectModal, setRejectModal] = useState(false);

  const { data } = digitalProductServices.useQueryGetDigitalProductById({
    digitalProductId,
  });

  const { mutate: updateDigitalProductStatus } =
    digitalProductServices.useMutationUpdateDigitalProductStatus(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-digital-product-by-id'],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: ['get-digital-products'],
          refetchType: 'all',
        });
        handleClose();
      },
      () => {
        AlertError({
          title: 'ไม่สามารถดำเนินการได้',
          text: 'กรุณาลองใหม่หรือครั้ง',
        });
        handleClose();
      }
    );

  const _HandleSubmit = async () => {
    const confirm = await AlertConfirm('ต้องการยอมรับผลงานใช่หรือไม่');
    if (confirm && digitalProductId) {
      updateDigitalProductStatus({
        id: digitalProductId,
        status: 'submited',
      });
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'waiting_submit':
        return 'รอตรวจสอบผลงาน';
      case 'submited':
        return 'อนุมัติ';
      case 'rejected':
        return 'ปฏิเสธ';
      default:
        return '';
    }
  };

  return (
    <Modal
      title={'รายละเอียดผลงาน'}
      visible={open}
      onCancel={handleClose}
      {...(data && data.status === 'waiting_submit'
        ? {
            footer: [
              <Button
                key="back"
                onClick={() => {
                  setRejectModal(true);
                }}
              >
                ปฏิเสธ
              </Button>,
              <Button key="submit" type="primary" onClick={_HandleSubmit}>
                ยอมรับ
              </Button>,
            ],
          }
        : {
            footer: [
              <Button key="back" onClick={handleClose}>
                ปิด
              </Button>,
            ],
          })}
      // footer={[
      //   <Button
      //     key="back"
      //     onClick={() => {
      //       setRejectModal(true);
      //     }}
      //   >
      //     ปฏิเสธ
      //   </Button>,
      //   <Button key="submit" type="primary" onClick={_HandleSubmit}>
      //     ยอมรับ
      //   </Button>,
      // ]}
      width={'50vw'}
    >
      <RejectDigitalProductModal
        open={rejectModal}
        handleClose={() => {
          setRejectModal(false);
        }}
        handleClose2={() => {
          setRejectModal(false);
          handleClose();
        }}
        digitalProductId={digitalProductId}
      />
      <div>
        {data && (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                alt={data.name}
                src={data.image}
                style={{
                  width: width / 2,
                  height: height / 2,
                }}
              />
            </div>
            <div style={{ display: 'flex', marginTop: 20 }}>
              <div style={{ flex: 0.3 }}>
                <Typography>รหัสนักธุรกิจ :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>{data.memberCode}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.3 }}>
                <Typography>ชื่อ - นามสกุล :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>
                  {data?.creater?.firstName + ' ' + data?.creater?.lastName}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.3 }}>
                <Typography>องค์กร :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>{data?.creater?.organizationName}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.3 }}>
                <Typography>ชื่อผลงาน :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>{data.name}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.3 }}>
                <Typography>คำบรรยาย :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>{data.description}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.3 }}>
                <Typography>สถานะ :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>{renderStatus(data.status)}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.3 }}>
                <Typography>การโหวต :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>{data.voteQuantity}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.3 }}>
                <Typography>เหตุผลปฏิเสธ :</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography>{data.rejectedReason}</Typography>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DigitalProductModal;
