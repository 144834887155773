import React, { forwardRef } from "react";
import SelectOption from "../../../../../../components/SelectOption";
import { useApi } from "../../../../../../hooks/http2";
import { URL_API, _ } from "../../../../../../utils";
const SelectTaxRefund = (props) => {
  const list = useApi(
    {
      url: `${URL_API}/system/master/tax_refund`,
      token: true,
    },
    []
  );
  return (
    <SelectOption
    loading={list.loading}
    data={_.map(list.fetch?.data || [], (n) => ({
      value: parseInt(n.code),
      text: n.name,
    }))}
    {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectTaxRefund forwardRef={ref} {...props} />;
});
