import { Modal } from "antd";
import React from "react";
import { URL_API } from "../../../../../utils";

const PreviewFile = (props) => {
  return (
    <>
      <Modal zIndex={2001} {...props}>
        <img
          src={`${URL_API}/file/${props.imageId}`}
          style={{ width: "100%" }}
          alt="preview"
        />
      </Modal>
    </>
  );
};
export default PreviewFile;
