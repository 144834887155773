import { Col, Typography } from "antd";
import React from "react";
import ImgError from "../../../../../../components/ImgError";
import { _ } from "../../../../../../utils";

const { Text } = Typography;

const Voucher = ({ data }) => (
  <Col span={24} className="d-flex align-items-center">
    <ImgError
      // src={`${URL_API}/pos/master/image/compress/${data.media_id}?res_file=1`}
      src={data.azure_url}
      alt={_.uniqueId("voucher-")}
      height="60"
      className="border rounded-lg"
    />
    <div className="ml-3">
      <Text>{`${data.name} `}</Text>
      {/* <Text>{`บัตรกำนัล${data.name} `}</Text> */}
      {data.keep && <Text className="text-reds">(ใช้งานได้ครั้งถัดไป)</Text>}
    </div>
  </Col>
);

export default Voucher;
