import { Divider } from "antd";
import React from "react";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import FilterList from "./components/FilterList";
import ModalRoleName from "./components/ModalRoleName";
import TableRole from "./components/TableRole";

const List = () => {
  return (
    <>
      <Panel>
        <PanelHeader>สิทธิ์การใช้งาน</PanelHeader>
        <PanelBody>
          <FilterList />
          <Divider />
          <TableRole />
        </PanelBody>
      </Panel>
      <ModalRoleName />
    </>
  );
};

export default List;
