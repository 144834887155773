import { createSlice } from "@reduxjs/toolkit";
const initState = {
  bill: {
    buyer_id: null,
    buyer_code: null,
    buyer_mobile: null,
    buyer_name: null,
    buyer_type: null,
    buyer_id_card: null,
    document_date: null,
    document_no: null,
    order_type: null,
    receipt_branch_name: null,
    req_receive_text: null,
    req_receive_type_text: null,
    ship_address: null,
    ship_mobile: null,
    ship_to: null,
  },
  billProduct: {
    itemKeys: [],
    itemByKey: {},
  },
  partner: null,
  document: {
    id: null,
    id_del: null,
    file_type: null,
    file_name: null,
    file_size: null,
    azure_url: null,
    temp: null,
  },
  billModal: {
    visible: false,
    query: null,
    page: 1,
    limit: 10,
  },
  fetch: {
    loading: false,
    error: null,
  },
};

const creditnoteSlice = createSlice({
  name: "creditNote",
  initialState: initState,
  reducers: {
    SET_SEARCH_BILL: (state, { payload }) => {
      state.billModal = { ...state.billModal, ...payload };
    },
    SET_OPEN_MODAL_BILL: (state, { payload }) => {
      state.billModal.visible = true;
    },
    SET_CLOSE_MODAL_BILL: (state) => {
      state.billModal = initState.billModal;
    },
    SET_PARTNER: (state, { payload }) => {
      state.partner = {
        ...payload,
      };
    },
    CLEAR_PARTNER: (state, { payload }) => {
      state.partner = null;
      state.bill = initState.bill;
      state.billProduct = initState.billProduct;
    },
    SET_DOCUMENT: (state, { payload }) => {
      state.document = payload;
    },
    CLEAR_DOCUMENT: (state, { payload }) => {
      state.document = initState.document;
    },
    SELECT_BILL: (state, { payload }) => {
      state.bill = { ...state.bill, ...payload.record };
      state.billProduct.itemKeys = payload.bill_item;
      state.fetch = initState.fetch;
      state.billModal = initState.billModal;
    },
    CLEAR: (state) => {
      state.bill = initState.bill;
      state.billProduct = initState.billProduct;
      state.partner = initState.partner;
      state.document = initState.document;
      state.billModal = initState.billModal;
      state.fetch = initState.fetch;
    },
    SET_LOAD: (state) => {
      state.fetch.loading = true;
    },
  },
});

export const actionCreditNote = creditnoteSlice.actions;

const CreditNoteReducer = creditnoteSlice.reducer;

export default CreditNoteReducer;
