import { Col, Row, Typography, Tooltip, Button, Switch } from "antd";
import moment from "moment";
import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  actionProduct,
  formSelector,
  orderSourceSelector,
} from "../../../../../../../redux/slices/product";

const type = "pos";

const ShopSource = () => {
  const dispatch = useDispatch();
  const { order_source } = useSelector(formSelector);
  const orderSource = useSelector(orderSourceSelector);

  const { SELECT_ORDER_SOURCE, OPEN_MODAL } = actionProduct;
  
  const active = order_source.includes(type);
  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col span={24} className="d-flex justify-content-between">
          <Typography.Text>Legacy Shop</Typography.Text>
          <div>
            <Tooltip title={`${active ? "กำหนดสาขา" : ""}`}>
              <Button
                size="small"
                shape="circle"
                type="primary"
                className={`${active ? "bg-pv border-pv" : ""} mr-3`}
                disabled={!active}
                onClick={() => {
                  dispatch(
                    OPEN_MODAL({
                      visible: true,
                      type: "pos",
                      title: "Legacy Shop",
                      modal: "branch",
                    })
                  );
                }}
              >
                <FaPencilAlt />
              </Button>
            </Tooltip>
            <Switch
              checked={active}
              onClick={(checked) =>
                dispatch(SELECT_ORDER_SOURCE({ type, checked }))
              }
            />
          </div>
        </Col>
      </Row>
      {active &&
        orderSource[type].itemKeys.map((key) => (
          <Row gutter={16} key={key} className="mb-2">
            <Col span={8} offset={1}>
              <Typography.Text>
                {orderSource[type].itemByKey[key].name}
              </Typography.Text>
            </Col>
            <Col span={15}>
              {orderSource[type].itemByKey[key].start_date && (
                <Typography.Text className="mr-2">
                  {moment(orderSource[type].itemByKey[key].start_date).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </Typography.Text>
              )}
              {orderSource[type].itemByKey[key].end_date && (
                <>
                  <Typography.Text className="mr-2">-</Typography.Text>
                  <Typography.Text>
                    {moment(orderSource[type].itemByKey[key].end_date).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </Typography.Text>
                </>
              )}
            </Col>
          </Row>
        ))}
    </>
  );
};

export default ShopSource;
