import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { classFormDefaultValues } from '../dto/class';
import ClassForm from '../form/ClassForm';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';

const ClassModal = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit, reset } = useFormContext();
  const queryClient = useQueryClient();

  const { execute: createClass } = useAsync(fitStudioService.createClass, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-class-by-class-category-id'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มคลาสเรียนสำเร็จ');
      reset(classFormDefaultValues);
      handleClose();
    },
  });

  const { execute: editClass } = useAsync(fitStudioService.editClass, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-class-by-class-category-id'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขคลาสเรียนสำเร็จ');
      reset(classFormDefaultValues);
      handleClose();
    },
  });

  const _HandleSubmit = handleSubmit((data) => {
    const classData = {
      categoryId: params.classCategoryId,
      rewardId: data.rewardId,
      title: data.title,
      subTitle: data.subTitle,
      thumbnailImageUrl: data.thumbnailImageUrl,
      description: data.description,
      period: data.period,
      attribute_title: data.attribute_title,
      attribute_image: data.attribute_image,
      attribute_value: data.attribute_value,
      attribute_unit: data.attribute_unit,
      suggestion: data.suggestion,
    };
    if (watch('formType') === 'create') {
      createClass(classData);
    } else if (watch('formType') === 'edit') {
      editClass({ id: data.id, data: classData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create' ? 'สร้างคลาสเรียน' : 'แก้ไขคลาสเรียน'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <ClassForm />
    </Modal>
  );
};

export default ClassModal;
