/* eslint-disable no-unused-vars */
import { Col, Input, Row, Table } from "antd";
import moment from "moment";
import React, { useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import FilterList from "../../components/FilterList";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [nationality, setNationality] = useState("");
  const [team, setTeam] = useState("");
  const limit = 20;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-payment`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        status: status,
        nationality: nationality,
        team: team,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page, state.filter]
  );

  let newData = data ? data : [];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานคะแนนเลกาซี่่รีวอร์ดคงเหลือ</PanelHeader>

        <PanelBody>
          <FilterList
            /*exportToExcel={""}*/ placeholder="รหัสนักธุรกิจ"
            reload={reload}
          />
          <Row>
            <Col span={6} className="p-r-10">
              <Input
                placeholder="สถานะ"
                className="w-100 p-r-5 "
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              />
            </Col>
            <Col span={6} className="p-r-10">
              <Input
                placeholder="สัญชาติ"
                className="w-100 p-r-5 "
                onChange={(e) => {
                  setNationality(e.target.value);
                }}
              />
            </Col>
            <Col span={6} className="p-r-10">
              <Input
                placeholder="ทีม"
                className="w-100 p-r-5 "
                onChange={(e) => {
                  setTeam(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={newData.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              // total: newData.newData.length ,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="NO." dataIndex="" align="center" />
            <Table.Column title="รหัสนักธุรกิจ" dataIndex="" align="center" />
            <Table.Column title="ชื่อนักธุรกิจ" dataIndex="" align="right" />
            <Table.Column title="สัญชาติ" dataIndex="" align="right" />
            <Table.Column title="Team" dataIndex="" align="right" />
            <Table.Column title="สถานะ" dataIndex="" align="right" />
            <Table.Column title="RP พร้อมใช้รวม" dataIndex="" align="right" />
            <Table.Column
              title="RP พร้อมใช้ที่ใช้แลก"
              dataIndex=""
              align="right"
            />
            <Table.Column
              title="RP พร้อมใช้คงเหลือ"
              dataIndex=""
              align="right"
            />
            <Table.Column title="RP พร้อมโอนรวม" dataIndex="" align="right" />
            <Table.Column
              title="RP พร้อมโอนที่โอนไป"
              dataIndex=""
              align="right"
            />
            <Table.Column
              title="RP พร้อมโอนคงเหลือ"
              dataIndex=""
              align="right"
            />
            <Table.Column title="RP คงเหลือ" dataIndex="" align="right" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
