import { createSlice } from "@reduxjs/toolkit";
const initState = {
  active: "",
  search: {
    query: "",
    page: 1,
    limit: 10,
    date_from: null,
    date_to: null,
  },
  modal: {
    visible: null,
    data: null,
  },
};

const blacklistSlice = createSlice({
  name: "blacklist",
  initialState: initState,
  reducers: {
    SET_ACTIVE: (state, { payload }) => {
      state.active = payload;
      state.search = {
        ...initState.search,
      };
    },
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    SET_LOAD_DETAIL_FAIL: (state, { payload }) => {
      state.detail.loading = false;
    },
    SET_OPEN_MODAL: (state, { payload }) => {
      state.modal.data = payload ? payload : null;
      state.modal.visible = true;
    },
    SET_CLOSE_MODAL: (state) => {
      state.modal.data = null;
      state.modal.visible = false;
    },
  },
});

export const actionBlackList = blacklistSlice.actions;

export const activeSelector = (state) => state.blacklist.active;
export const searchSelector = (state) => state.blacklist.search;
export const modalSelector = (state) => state.blacklist.modal;

const BlackListReducer = blacklistSlice.reducer;

export default BlackListReducer;
