import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { useParams } from 'react-router';
import BodyFocusVideoForm from '../form/BodyFocusVideoForm';
import { videoFormDefaultValues } from '../dto/myfocus';

const BodyFocusVideoModal = ({ open, handleClose }) => {
  const params = useParams();
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createSubTag } = useAsync(
    fitStudioService.createBodyFocusVideo,
    {
      onSuccess: () => {
        AlertSuccess('เพิ่มวิดีโอสำเร็จ');
        reset(videoFormDefaultValues);
        handleClose();
      },
    }
  );

  const { execute: editSubTag } = useAsync(
    fitStudioService.editBodyFocusVideo,
    {
      onSuccess: () => {
        AlertSuccess('แก้ไขวิดีโอสำเร็จ');
        reset(videoFormDefaultValues);
        handleClose();
      },
    }
  );

  const _HandleSubmit = handleSubmit((data) => {
    const videoData = {
      title: data.title,
      thumbnailImageUrl: data.thumbnailImageUrl,
      description: data.description,
      subTagId: params.subTagId,
      videoUrl: data.videoUrl,
      gender: data.gender,
    };
    if (watch('formType') === 'create') {
      createSubTag(videoData);
    } else if (watch('formType') === 'edit') {
      editSubTag({ id: data.id, data: videoData });
    }
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'สร้างวิดีโอ' : 'แก้ไขวิดีโอ'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <BodyFocusVideoForm />
    </Modal>
  );
};

export default BodyFocusVideoModal;
