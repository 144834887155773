/* eslint-disable no-unused-vars */
import { Input, notification } from "antd";
import numeral from "numeral";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { _ } from "../../../../utils";
import { Creators } from "../ducks/operation-form";
import { getSummaryTotalPayment } from "./logic";
import PaymentAdd from "./PaymentAdd";
import PaymentDetail from "./PaymentDetail";
import PaymentSummary from "./PaymentSummary";
import Preview from "./Preview";
import Printform from "./Printform";

const Summary = ({ match, ...props }) => {
  const [openModal, setOpenModal] = useState(false);
  const type_params = match.params.type;
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );
  // const isRedeemReserve = _.includes(["reserve", "pre-redeem"], type_params);

  const handleClickSubmit = () => {
    if (type_params === "sale" && props.payment_total < props.balance_total)
      return notification.warning({
        message: "คำเตือน",
        description: "กรุณาตรวจสอบยอดชำระเงิน",
        placement: "bottomRight",
      });
    if (
      _.includes(["redeem", "pre-redeem"], type_params) &&
      (props.rp_topup || 0) < props.balance_total
    )
      return notification.warning({
        message: "คำเตือน",
        description: "คะแนนของคุณไม่พอ",
        placement: "bottomRight",
      });
    setOpenModal(true);
  };

  return (
    <>
      <div className="pos-payment-summary bg-gray-80 px-5 py-3">
        <div className="w-100">
          <PaymentSummary />
          {isNormal ? (
            <>
              <PaymentAdd />
              <PaymentDetail />
              <Printform match={match} />
            </>
          ) : (
            <>
              <div className="pb-5">
                <div className="row mb-4">
                  <div className="col">
                    <h5 className="m-0 text-white">คะแนนพร้อมใช้</h5>
                  </div>
                  <div className="col">
                    <h5 className="m-0 text-right text-white">
                      {numeral(props.rp_topup).format("0,0.00")}
                    </h5>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <h5 className="m-0 text-white">คะแนนพร้อมโอน</h5>
                  </div>
                  <div className="col">
                    <h5 className="m-0 text-right text-white">
                      {numeral(props.rp_hold).format("0,0.00")}
                    </h5>
                  </div>
                </div>
                <Printform match={match} />
                {/* {isRedeemReserve && (
                <div className="row mb-4">
                  <div className="col">
                    <h5 className="m-0 text-white">จำนวนคะแนน</h5>
                  </div>
                  <div className="col">
                    <Input
                      allowClear
                      onChange={(e) => {
                        const value = +_.replace(e.target.value, /[^\d]+/, "");
                        if (!_.isNaN(value) && value <= props.balance_total) {
                          props.setPaymentRedeem(value);
                        }
                      }}
                    />
                  </div>
                </div>
              )} */}
              </div>
            </>
          )}

          <div className="row">
            <div className="col d-flex justify-content-around">
              <button
                type="button"
                className="pos-btn pos-btn-lg bg-reds text-white text-nowrap"
                onClick={() => {
                  props.changeStep(2);
                  props.cancelPayment();
                  props.history.replace(`/pos/${type_params}/order`);
                }}
              >
                ย้อนกลับ
              </button>
              <button
                type="button"
                className="pos-btn pos-btn-lg bg-pv text-white text-nowrap"
                onClick={handleClickSubmit}
              >
                {_.includes(["subscription", "reserve"], type_params)
                  ? "จอง"
                  : _.includes(["pre-sale", "pre-redeem"], type_params)
                  ? "สั่ง"
                  : "ยืนยัน"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Preview
        visible={openModal}
        type={type_params}
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { orders, payment } = state.pos.operation;
  const { summary_total, payment_total, voucher_total } =
    getSummaryTotalPayment(orders, payment);
  return {
    ...state.pos.data.redeem_point,
    partner_code: orders.itemByKey[orders.itemKeys[0]]?.partner_code,
    payment_total,
    balance_total: summary_total - voucher_total,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    changeStep: Creators.changeStep,
    cancelPayment: Creators.cancelPayment,
    addPayment: Creators.addPayment,
    setPaymentRedeem: Creators.setPaymentRedeem,
  })(Summary)
);
