import { Button, Typography } from 'antd';
import React from 'react'
import { MdArrowBack } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { Panel, PanelBody, PanelFooter, PanelHeader } from '../../../util/panel';
import styles from '../../../../scss/module/legal.module.scss';
import PageMenuTab from './PageMenuTab';

const LegalPanel = ({ children, history }) => {
    const handleClickBack = () => {
        history.push({pathname: '/legal'});
    }
    return (
        <Panel className="bg-body">
            <PanelHeader>จรรยาบรรณ</PanelHeader>
            <PanelBody>
                <div className={styles.legal}>
                    <PageMenuTab />
                    {children}
                </div>
            </PanelBody>
            <PanelFooter className="bg-body text-center">
                <Button className="bg-reds" onClick={handleClickBack}>
                    <MdArrowBack className="text-white" />
                    <Typography.Text className="text-white ml-2">กลับหน้าหลัก</Typography.Text>
                </Button>
            </PanelFooter>
        </Panel>
    )
}

export default withRouter(LegalPanel)