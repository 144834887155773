import { Col, Row, Switch } from "antd";
import React, { useContext } from "react";
import { PromotionContext } from "../reducers";

const SwitchCondition = ({ title, name, ...props }) => {
  const { state, dispatch } = useContext(PromotionContext);
  return (
    <Row gutter={16}>
      <Col
        md={24}
        className="d-flex justify-content-between align-items-center"
      >
        <label className="mb-0">{title}</label>
        <Switch
          {...props}
          checked={!!state.data[name]}
          onClick={(checked) =>
            dispatch({
              type: "SET_SWITCH_CHECK",
              payload: { name, checked },
            })
          }
        />
      </Col>
    </Row>
  );
};

export default SwitchCondition;
