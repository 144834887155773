/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: {
    register: {
      id: null,
      sponsor_code: null,
      sponsor_name: null,
      sponsor_mobile: null,
      up_line_code: null,
      up_line_first_name: null,
      up_line_sur_name: null,
      up_line_mobile: null,
      up_line_chart_type: null,
      partner_code: null,
      member_type: null,
      register_date: null,
      expire_date: null,
      prefix_th: null,
      first_name_th: null,
      sur_name_th: null,
      prefix_en: null,
      first_name_en: null,
      sur_name_en: null,
      birth_date: null,
      age: null,
      id_card: null,
      nationality: null,
      gender: null,
      phone: null,
      mobile: null,
      email: null,
      line_id: null,
      facebook: null,
      img: null,
      company_type: null,
      company_name: null,
      company_name_en: null,
      company_tax: null,
      status: null,
      resign_date : null,
      register_new_date : null
    },
    address: {
      id_card: [],
      tax: [],
      current: [],
      delivery: [],
    },
    bank: {
      person: [],
      company: [],
    },
  },
  document: {
    id: null,
    partner_code: null,
    page: null,
    search: {
      query: null,
      type: null,
      status: "Active",
    },
  },
  search: {
    page: 1,
    limit: 10,
    member_type: "distributor",
    status: "",
    query: "",
  },
  member_type : {
    type : ""
  }
};
const legalSlice = createSlice({
  name: "legal",
  initialState,
  reducers: {
    SET_REGISTER: (state, { payload }) => {
      state.profile = {
        ...state.profile,
        register: {
          ...state.profile.register,
          ...payload,
        },
      };
    },
    SET_ADDRESS: (state, { payload }) => {
      state.profile = {
        ...state.profile,
        address: {
          ...state.profile.address,
          ...payload,
        },
      };
    },
    SET_BANK: (state, { payload }) => {
      state.profile = {
        ...state.profile,
        bank: {
          ...state.profile.bank,
          ...payload,
        },
      };
    },
    CLEAR_PROFILE: (state) => {
      state.profile = {
        ...initialState.profile,
      };
    },
    SET_DOCUMENT: (state, { payload }) => {
      state.document = {
        ...state.document,
        ...payload,
      };
    },
    CLEAR_DOCUMENT: (state) => {
      state.document = {
        ...initialState.document,
      };
    },
    SET_SEARCH: (state, { payload }) => {
      state.search = {
        ...state.search,
        ...payload,
      };
    },
    CLEAR_SEARCH: (state) => {
      state.search = {
        ...initialState.search,
      };
    },
    SET_MEMBER_TYPE: (state, { payload }) => {
      state.member_type = {
        ...state.member_type,
        ...payload,
      };
    },
  },
});

export const registerSelector = (state) => state.legal.profile.register;
export const addressSelector = (state) => state.legal.profile.address;
export const bankSelector = (state) => state.legal.profile.bank;
export const documentSelector = (state) => state.legal.document;
export const searchSelector = (state) => state.legal.search;
export const typeSelector = (state) => state.legal.member_type;

export const actionLegal = legalSlice.actions;

const LegalReducer = legalSlice.reducer;

export default LegalReducer;
