import { Button, Col, Row } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { actionMWBanner } from "../../../../../redux/slices/manage-website/banner.slice";
import SelectPage from "../../components/select/SelectPage";

const Criteria = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.mw.banner, shallowEqual);

  const { SET_PAGE } = actionMWBanner;

  return (
    <Row gutter={16} className="align-items-center mb-3 d-flex">
      <Col md={2} className="text-right">
        <span>หน้าจอ:</span>
      </Col>
      <Col md={6}>
        <SelectPage
          withAll
          className="w-100"
          value={state.page}
          onChange={(e) => dispatch(SET_PAGE(e))}
        />
      </Col>
      <Col md={16} className="text-right">
        <Button
          type="primary"
          ghost
          onClick={() => history.push(`${match.url}/0`)}
        >
          เพิ่มใหม่
        </Button>
      </Col>
    </Row>
  );
};

export default Criteria;
