/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, Typography } from "antd";
import React from "react";
import ImgError from "../../../../../../components/ImgError";
import { _ } from "../../../../../../utils";

const { Text } = Typography;

const Item = ({ data }) => (
  <Col span={24} className="d-flex align-items-center">
    <ImgError
      src={data.azure_url}
      alt={_.uniqueId("product-")}
      className="width-60 height-60 border rounded-lg object-fit-cover"
    />
    <div className="ml-3">
      <Text>{`${data.product_name_local} ${data.qty} ชิ้น `}</Text>
      {data.banned ? (
        <Text className="text-red">(banned)</Text>
      ) : (
        data.sold_out && <Text className="text-red">(sold out)</Text>
      )}
    </div>
  </Col>
);

export default Item;
