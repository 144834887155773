export const objVipMember = {

    id: 0,
    partner_code: '',
    approve_id_card: false,
    approve_document: false,

    register_date: '',
    expire_date: '',

    is_company: null,
    company_name_th: '',
    company_name_en: '',
    company_type: '',
    company_tax: '',
    company_register_vat: 1,

    prefix: '',
    gender: '',
    first_name_th: '',
    first_name_en: '',
    sur_name_th: '',
    sur_name_en: '',
    nationality: 'TH',
    origin: '',
    id_card: '',
    passport_id: "",
    passport_expire_date: "",
    member_type: '',
    phone: '',
    mobile: '',
    email: '',
    birth_date: '',
    line_id: '',
    facebook: '',
    phone_code: '',

    sponsor: {
        first_name: '',
        sur_name: '',
        sponsor_code: '',
        mobile: '',
    },
    idAddress: {
        sub_district_id: null,
        district_id: null,
        province_id: null,
        address: '',
        sub_district: '',
        district: '',
        province: '',
        post_code: '',
    },
    taxAddress: {
        sub_district_id: null,
        district_id: null,
        province_id: null,
        address: '',
        sub_district: '',
        district: '',
        province: '',
        post_code: '',
    },
    currentAddress: {
        sub_district_id: null,
        district_id: null,
        province_id: null,
        address: '',
        sub_district: '',
        district: '',
        province: '',
        post_code: '',
    },
    idAddressUpdate: {
        sub_district_id: null,
        district_id: null,
        province_id: null,
        address: '',
        sub_district: '',
        district: '',
        province: '',
        post_code: '',
    },
    taxAddressUpdate: {
        sub_district_id: null,
        district_id: null,
        province_id: null,
        address: '',
        sub_district: '',
        district: '',
        province: '',
        post_code: '',
    },
    currentAddressUpdate: {
        sub_district_id: null,
        district_id: null,
        province_id: null,
        address: '',
        sub_district: '',
        district: '',
        province: '',
        post_code: '',
    },
}