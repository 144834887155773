import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios, getToken, URL_API } from "../../utils";

export const getUserPermission = createAsyncThunk(
  "get/global/user/permission",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axios({
        url: `${URL_API}/global/user/permission`,
        headers: { Authorization: "Bearer " + getToken() },
        params,
      });
      return res.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
