import { Col, Radio, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ValidateError from "../../../../../components/ValidateError";
import { actionClaim } from "../../../../../redux/slices/claim";

const { Text } = Typography;

const { setFormValue } = actionClaim;

const SelectReqReceiptType = ({ disabled }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => {
    const {
      receiptType: { req_receipt_type },
    } = state.claim;
    return disabled
      ? req_receipt_type === "Branch"
        ? "รับที่สาขา"
        : req_receipt_type === "Transport" && "จัดส่ง"
      : req_receipt_type;
  });
  const req_receipt_type_error = useSelector(
    (state) => state.claim.error.req_receipt_type_error
  );

  return (
    <Row gutter={[8, 8]} type="flex" className="align-items-center mb-1">
      <Col span={6} className="text-right">
        <Text strong>ประเภทการรับของ</Text>
      </Col>
      <Col span={18}>
        {disabled ? (
          <Text>{value}</Text>
        ) : (
          <>
            <Radio.Group
              value={value}
              onChange={(e) =>
                dispatch(
                  setFormValue({
                    name: "receiptType",
                    value: {
                      req_receipt_type: e.target.value,
                      receipt_branch_code: null,
                      receipt_branch_name: null,
                      ship_to: null,
                      ship_mobile: null,
                      ship_name: null,
                      ship_to_id: null,
                      ship_address_no: null,
                      ship_sub_district: null,
                      ship_district: null,
                      ship_province: null,
                      ship_post_code: null,
                      ship_country: null,
                    },
                    error_name: [
                      "req_receipt_type_error",
                      "receipt_branch_code",
                      "ship_to_id",
                    ],
                  })
                )
              }
            >
              <Radio value="Branch">รับที่สาขา</Radio>
              <Radio value="Transport">จัดส่ง</Radio>
            </Radio.Group>
            <ValidateError error={req_receipt_type_error} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default memo(SelectReqReceiptType);
