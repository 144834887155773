/* eslint-disable eqeqeq */
import { Button, Col, DatePicker, Input, Row, Table, notification } from "antd";
import numeral from "numeral";
import React, { useEffect } from "react";
import { FaCogs, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { useApi } from "../../../../../hooks/http2";
import {
  actionStoreQrBalance,
  activeSelector,
  searchSelector,
} from "../../../../../redux/slices/store-qr/balance";
import { getUser, moment, URL_API } from "../../../../../utils";
import SelectBranch from "../../components/SelectBranch";
import * as XLSX from "xlsx-js-style";
const BalanceLot = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const active = useSelector(activeSelector);
  const search = useSelector(searchSelector);

  const { SET_SEARCH } = actionStoreQrBalance;

  const list = useApi(
    {
      url: `${URL_API}/store-qr/balance/${active}`,
      token: true,
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
        start_date: search.start_date
          ? new Date(moment(search.start_date).format("YYYY-MM-DD"))
          : null,
        end_date: search.end_date
          ? new Date(moment(search.end_date).format("YYYY-MM-DD"))
          : null,
      },
    },
    [search]
  );

  const dataExcel = useApi(
    {
      url: `${URL_API}/store-qr/balance/${active}`,
      token: true,
      params: {
        ...search,
        offset: 0,
        limit: 0,
        start_date: search.start_date
          ? new Date(moment(search.start_date).format("YYYY-MM-DD"))
          : null,
        end_date: search.end_date
          ? new Date(moment(search.end_date).format("YYYY-MM-DD"))
          : null,
      },
    },
    [search]
  );
  const list_branch = useApi(
    {
      baseURL: URL_API,
      url: `/boss/inventory/all-branch`
    }
  )

const exportToExcel = () => {
    if (dataExcel.fetch.data.length != 0) {

      const select_branch = list_branch.fetch?.data.all_branch.find(e => e.code == search.branch)

      let excelData = [
        ["รายงาน", "รายงานการจัดเซ็ตคงเหลือ-รายการย่อย (รายงานนี้แสดงรายการย้อยที่เป็น Active เท่านั้น ฉบับร่างจะไม่แสดง )", "","","","วันที่ดึงข้อมูล", `${moment(new Date()).format("DD/MM/YYYY HH:mm")}`],
        [],
        [
          "แสดงเฉพาะรายการ:",`${search.query}`, "",
          "สาขา :",` ${search.branch == "" ? "ทั้งหมด" : select_branch.name}`,
        ],
        [
          `วันที่ผลิต: `,
          `${search.start_date ? moment(search.start_date).format("DD/MM/YYYY") : "-"}`,
          ``,
          `วันที่หมดอายุ :`,
          ` ${search.end_date ? moment(search.end_date).format("DD/MM/YYYY") : "-"}`
        ],
        [],
        [{ v: "ลำดับ", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "รหัสสินค้า", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "รายการสินค้า", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "Lot.", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "วันที่ผลิต", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "วันหมดอายุ", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "อายุสินค้าคงเหลือ (วัน)", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "จำนวนคงเหลือ (ชิ้น)", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "สาขา", t: "s", s: { alignment: { horizontal: "center" } } },
        ],
      ];

      dataExcel.fetch.data.forEach((n) => {
        excelData = [...excelData,
        [{ v: n.row_id, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.product_code, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.product_name, t: "s", s: { alignment: { horizontal: "left" } } },
        { v: n.lot, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: moment(n.production_date).format("DD/MM/YYYY"), t: "s", s: { alignment: { horizontal: "center" } } },
        { v: moment(n.expiration_date).format("DD/MM/YYYY"), t: "s", s: { alignment: { horizontal: "center" } } },
        { v: moment(n.expiration_date ).diff(moment(new Date()),"days") < 0 ?
              0:moment(n.expiration_date ).diff(moment(new Date()),"days"), 
          t: "s", 
          s: { alignment: { horizontal: "center" } } 
        },
        { v: n.balance, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.branch_name, t: "s", s: { alignment: { horizontal: "center" } } },
        ]];
      });

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 2, c: 1 }, e: { r: 2, c: 2 } },
      ];

      var wscols = [{ wch: 20 }, { wch: 15 }, { wch: 35 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 15 },];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ข้อมูลจัดเซ็ตคงเหลือ-รายการย่อย");
      XLSX.writeFile(wb, `ข้อมูลจัดเซ็ตคงเหลือ-รายการย่อย-.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  useEffect(() => {
    let user = getUser();

    dispatch(SET_SEARCH({ branch: user?.branch_code, page: 1 }));
  }, [dispatch, SET_SEARCH]);

  return (
    <>
      <Row gutter={16} className="mb-3">
        <Col md={6}>
          <Input.Search
            placeholder="รหัสสินค้า, ชื่อสินค้า, Lot., QR code ย่อย"
            onSearch={(value) =>
              dispatch(SET_SEARCH({ query: value, page: 1 }))
            }
            allowClear
          />
        </Col>
        <Col md={5}>
          <SelectBranch
            className="w-100"
            withAll
            allText="สาขาทั้งหมด"
            placeholder="สาขา"
            value={search.branch}
            onChange={(value) =>
              dispatch(SET_SEARCH({ branch: value, page: 1 }))
            }
          />
        </Col>
        <Col md={5}>
        <DatePicker
              className="w-100"
              placeholder="หมดอายุเริ่มต้น"
              format="DD/MM/YYYY"
              value={search.start_date}
              onChange={(value) => dispatch(SET_SEARCH({ start_date: value, page: 1 }))}
            />
        </Col>
        <Col md={5}>
          <DatePicker
              className="w-100"
              placeholder="หมดอายุสิ้นสุด"
              format="DD/MM/YYYY"
              value={search.end_date}
              onChange={(value) => dispatch(SET_SEARCH({ end_date: value, page: 1 }))}
            />
        </Col>
        <Col md={3}>
          <Button
              type="primary"
              icon="file-excel"
              style={{width:"100%"}}
              onClick={() => exportToExcel()}
            >
              Export Excel
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        loading={list.loading}
        dataSource={list.fetch?.data || []}
        pagination={{
          size: "default",
          current: search.page,
          pageSize: search.limit,
          total: list.fetch?.total || 0,
          showLessItems: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
          onChange: (p) => dispatch(SET_SEARCH({ page: p })),
        }}
        rowKey="row_id"
      >
        <Table.Column title="No." dataIndex="row_id" align="right" />
        <Table.Column
          title="รหัสสินค้า"
          dataIndex="product_code"
          align="center"
        />
        <Table.Column title="รายการสินค้า" dataIndex="product_name" />
        <Table.Column title="Lot." dataIndex="lot" align="center" />
        <Table.Column
          title="วันผลิตสินค้า"
          dataIndex="production_date"
          align="center"
          render={(text) => moment(text).format("DD/MM/YYYY")}
        />
        <Table.Column
          title="วันหมดอายุสินค้า"
          dataIndex="expiration_date"
          align="center"
          render={(text) => moment(text).format("DD/MM/YYYY")}
        />
        <Table.Column
          title="อายุสินค้าคงเหลือ (วัน)"
          align="right"
          render={(text, record) => {
            let exp = moment(record.expiration_date);
            let now = moment();

            let duration = moment.duration(exp.diff(now));
            let days = duration.asDays();
            
            return numeral(days<0? 0:days).format("0,0");
          }}
        />
        <Table.Column
          title="จำนวนคงเหลือ (ชิ้น)"
          dataIndex="balance"
          align="right"
          render={(text) => numeral(text).format()}
        />
        <Table.Column title="สาขา" dataIndex="branch_name" align="center" />
        <Table.Column
          title={<FaCogs />}
          align="center"
          render={(text, record) => (
            <Button
              type="primary"
              size="small"
              shape="circle-outline"
              onClick={() =>
                history.push(
                  `${match.path}/${record.id}/${active}/${record.branch_code}`
                )
              }
            >
              <FaEye />
            </Button>
          )}
        />
      </Table>
    </>
  );
};

export default BalanceLot;
