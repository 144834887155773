/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, Fragment } from "react";
import { Row, Col, Typography, Divider } from "antd";
import { _ } from "../../../../../utils";
import BillContext from "../../reducer";
import numeral from "numeral";
import BomDetail from "../../../pos/components/BomDetail";

const { Text } = Typography;

const num1 = (e) => numeral(e).format("0,0");
const num2 = (e) => numeral(e).format("0,0.00");

const ItemTable = () => {
  const {
    state: {
      billItem: { itemKeys, itemByKey },
      itemColumn,
    },
  } = useContext(BillContext);
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    const result = _.reduce(
      itemKeys,
      (res, key) => res + itemByKey[key].amount,
      0
    );
    setTotalAmount(result);
  }, [itemKeys, itemByKey]);
  return (
    <>
      <Row gutter={[8, 8]} className="bg-gray-60 my-2">
        <Col span={24} className="py-2 px-2">
          <Text className="text-white" strong>
            รายการสินค้า
          </Text>
        </Col>
      </Row>
      <table className="w-100">
        <thead>
          <tr>
            {_.map(itemColumn, (n, i) => (
              <td key={i} className="text-center">
                {n}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {_.map(itemKeys, (key, i) => (
            <Fragment key={key}>
              <tr>
                <td width="4%" className="px-2 text-center">
                  {i + 1}
                </td>
                <td width="10%" className="px-2 text-center">
                  {itemByKey[key].product_code}
                </td>
                <td className="px-2 align-items-center text-overflow width-400">
                  {itemByKey[key].product_name}
                </td>
                <td width="12%" className="px-2 text-center">
                  {num2(itemByKey[key].unit_price)}
                </td>
                <td width="10%" className="px-2 text-center">
                  {num1(itemByKey[key].qty)}
                </td>
                <td width="12%" className="px-2 text-center">
                  {num1(itemByKey[key].pv)}
                </td>
                <td width="15%" className="px-2 text-center">
                  {num2(itemByKey[key].amount)}
                </td>
              </tr>
              {itemByKey[key].bom.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <BomDetail
                      list={itemByKey[key].bom}
                      qty={itemByKey[key].qty}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td
              className="px-2 text-center py-2 border-gray-60"
              style={{ borderTop: "1px solid" }}
            >
              <Text strong>รวมทั้งหมด</Text>
            </td>
            <td
              className="px-2 text-center py-2 border-gray-60"
              style={{ borderTop: "1px solid" }}
            >
              {num2(totalAmount)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ItemTable;
