/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import { Typography, Input } from "antd";
import numeral from "numeral";

const TableItems = ({
  dataSource = [],
  delivery_amount = 0,
  total_amount = 0,
  remark_data = [],
  discount_data = [],
  reserve_order_no = "",
  setNewRemark,
  newRemark,
  partner_type = "Distributor",
}) => {
  // const [remark, setRemark] = useState('');
  const total_discount = useMemo(
    () => discount_data.reduce((sum, item) => sum + item.discount_amount, 0),
    [discount_data]
  );

  const summary_total = useMemo(
    () => total_amount + delivery_amount - total_discount,
    [total_amount, delivery_amount, total_discount]
  );

  const handleChangeRemark = (e) => {
    const { value } = e.target;
    let remark_filter = [...newRemark];
    const index = remark_filter.findIndex(
      (item) => item.reserve_order_no === reserve_order_no
    );
    ~index && remark_filter.splice(index, 1);
    if (value) {
      remark_filter = [
        ...remark_filter,
        {
          reserve_order_no,
          remark: value,
        },
      ];
    }
    setNewRemark(remark_filter);
  };
  return (
    <table className="w-100" style={{ fontSize: "12px" }}>
      <thead>
        <tr className="text-gray-60">
          <th className="font-weight-normal"></th>
          <th className="font-weight-normal">ชื่อ</th>
          <th className="font-weight-normal text-center">ราคา/หน่วย</th>
          <th className="font-weight-normal text-center">จำนวน</th>
          <th className="font-weight-normal text-center">ราคา</th>
          {partner_type.toLowerCase() == "distributor" ? (
            <>
              <th className="font-weight-normal text-center">PV</th>
              <th className="font-weight-normal text-center">RP</th>
              <th className="font-weight-normal text-center">EP</th>
              <th className="font-weight-normal text-center">TP</th>
            </>
          ) : (
            <th className="font-weight-normal text-center">RP</th>
          )}
        </tr>
      </thead>
      <tbody>
        {dataSource.map((n, i) => (
          <tr key={i} className="text-dark">
            <td className="text-center">{i + 1}</td>
            <td>{`${n.product_code} ${n.product_name_local}`}</td>
            <td className="text-center">
              {numeral(n.unit_price).format("0,0.00")}
            </td>
            <td className="text-center">{numeral(n.qty).format("0,0")}</td>
            <td className="text-center">
              {numeral(
                (parseFloat(n.qty) || 0) * (parseFloat(n.unit_price) || 0)
              ).format("0,0.00")}
            </td>
            {partner_type.toLowerCase() == "distributor" ? (
              <>
                <td className="text-center">
                  {numeral(n.pv * n.qty).format("0,0.00")}
                </td>
                <td className="text-center">
                  {numeral(n.rp * n.qty).format("0,0.00")}
                </td>
                <td className="text-center">
                  {numeral(n.ep * n.qty).format("0,0.00")}
                </td>
                <td className="text-center">
                  {numeral(n.tp * n.qty).format("0,0.00")}
                </td>
              </>
            ) : (
              <td className="text-center">
                {numeral(n.rp * n.qty).format("0,0.00")}
              </td>
            )}
          </tr>
        ))}
        <tr className="text-dark">
          <td colSpan={2}></td>
          <td className="text-right border-top">
            <strong>ค่าจัดส่ง</strong>
          </td>
          <td className="border-top"></td>
          <td className="border-top text-center">
            {numeral(delivery_amount).format("0,0.00")}
          </td>
          <td colSpan={5} className="border-top"></td>
        </tr>
        <tr className="text-dark">
          <td colSpan={2}></td>
          <td className="text-right">
            <strong>รวมราคาสินค้า</strong>
          </td>
          <td></td>
          <td className="text-center">
            {numeral(total_amount).format("0,0.00")}
          </td>
          <td colSpan={5}></td>
        </tr>
        <tr className="text-dark">
          <td colSpan={2}></td>
          <td className="text-right">
            <strong>รวมส่วนลด</strong>
          </td>
          <td></td>
          <td className="text-center">
            {total_discount !== 0 && "-"}
            {numeral(total_discount).format("0,0.00")}
          </td>
          <td colSpan={5}></td>
        </tr>
        <tr className="text-dark">
          <td colSpan={2}>
            <p className="mb-0 d-flex align-items-center">
              <Typography.Text strong className="mr-2">
                หมายเหตุ
              </Typography.Text>
              <Input
                allowClear
                size="small"
                value={
                  newRemark.find((n) => n.reserve_order_no === reserve_order_no)
                    ?.remark
                }
                onChange={handleChangeRemark}
              />
            </p>
          </td>
          <td className="text-right">
            <strong>รวมทั้งหมด</strong>
          </td>
          <td></td>
          <td className="text-center">
            {numeral(summary_total).format("0,0.00")}
          </td>
          {partner_type.toLowerCase() == "distributor" ? (
            <>
              <td className="text-center">
                {numeral(
                  dataSource.reduce(
                    (sum, item) => (sum += item.pv * item.qty),
                    0
                  )
                ).format("0,0.00")}
              </td>
              <td className="text-center">
                {numeral(
                  dataSource.reduce(
                    (sum, item) => (sum += item.rp * item.qty),
                    0
                  )
                ).format("0,0.00")}
              </td>
              <td className="text-center">
                {numeral(
                  dataSource.reduce(
                    (sum, item) => (sum += item.ep * item.qty),
                    0
                  )
                ).format("0,0.00")}
              </td>

              <td className="text-center">
                {numeral(
                  dataSource.reduce(
                    (sum, item) => (sum += item.tp * item.qty),
                    0
                  )
                ).format("0,0.00")}
              </td>
            </>
          ) : (
            <td className="text-center">
              {numeral(
                dataSource.reduce((sum, item) => (sum += item.rp * item.qty), 0)
              ).format("0,0.00")}
            </td>
          )}
        </tr>
        {remark_data.map((n, i) => (
          <tr className="text-dark" key={i}>
            <td colSpan={2}>
              <p className="mb-0 d-flex align-items-center">
                <Typography.Text strong className="mr-2">
                  หมายเหตุ
                </Typography.Text>
                <Typography.Text strong className="mr-2">
                  {n.remark}
                </Typography.Text>
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableItems;
