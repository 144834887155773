/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Input, Switch, Typography } from 'antdV4';
import { Row, Col, DatePicker, Button } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import lobServices from '../../../../../services/lob.services';
import { FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import fitUserServices from '../../../../../services/fit-user.services';

const LodForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { data } = fitUserServices.useQueryGetUserByMemberCode(
    {
      memberCode:
        watch('memberCode') && watch('memberCode').length === 9
          ? watch('memberCode')
          : undefined,
    },
    (data) => {}
  );

  useEffect(() => {
    if (data) {
      if (watch('isPartner')) {
        if (data.partner) {
          if (data.partner.FirstNameTh && !watch('firstName')) {
            setValue('firstName', data.partner.FirstNameTh);
          }
          if (data.partner.SurNameTh && !watch('lastName')) {
            setValue('lastName', data.partner.SurNameTh);
          }
        } else {
          setValue('firstName', '');
          setValue('lastName', '');
        }
      } else {
        if (data.FirstNameTh && !watch('firstName')) {
          setValue('firstName', data.FirstNameTh);
        }
        if (data.SurNameTh && !watch('lastName')) {
          setValue('lastName', data.SurNameTh);
        }
      }
    }
  }, [data, watch('isPartner')]);

  lobServices.useQueryGetLobById({ lobId: watch('id') }, (data) => {
    const shopee = data.links.find((item) => {
      return item.platform === 'Shopee';
    });
    const lazada = data.links.find((item) => {
      return item.platform === 'Lazada';
    });
    const tiktok = data.links.find((item) => {
      return item.platform === 'Tiktok';
    });

    const other = data.links.filter((item) => {
      return !['Shopee', 'Lazada', 'Tiktok'].includes(item.platform);
    });
    const links = [
      {
        platform: 'Shopee',
        url: shopee?.url || '',
      },
      {
        platform: 'Lazada',
        url: lazada?.url || '',
      },
      {
        platform: 'Tiktok',
        url: tiktok?.url || '',
      },
      ...other,
    ];

    reset({
      ...data,
      expiredDate: moment(data.expiredDate),
      links,
      id: data.id,
      formType: 'edit',
    });
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'links',
  });

  return (
    <div>
      <Controller
        name="memberCode"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>รหัสนักธุรกิจ</Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัสนักธุรกิจ"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="memberCode"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="isPartner"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>รหัสร่วม</Typography.Text>
              </Col>
              <Col span={20}>
                <Switch checked={value} onChange={onChange} />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ชื่อ</Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>นามสกุล</Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="นามสกุล"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="lobNo"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>รหัส LOD</Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="รหัส LOD"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="lobNo"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="expiredDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันหมดอายุ LOD <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Row className="align-items-center  mb-2">
        <Col span={4}>
          <Typography.Text>ชื่อร้าน</Typography.Text>
        </Col>
      </Row>
      {fields.map((item, index) => {
        return (
          <Row key={item.id} className="align-items-center  mb-2">
            <Col span={4}>
              <Controller
                name={`links.${index}.platform`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="platform"
                    />
                  );
                }}
              />
            </Col>
            <Col span={18}>
              <Controller
                name={`links.${index}.url`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="ชื่อร้าน"
                    />
                  );
                }}
              />
            </Col>
            {!['Shopee', 'Lazada', 'Tiktok'].includes(item.platform) && (
              <Col span={2}>
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <MdDelete />
                </Button>
              </Col>
            )}
          </Row>
        );
      })}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            append({
              platform: '',
              url: '',
            });
          }}
        >
          <FaPlus />
        </Button>
      </div>
    </div>
  );
};

export default LodForm;
