import React, { forwardRef } from "react";
import SelectOption from "../../../../../../components/SelectOption";
import { useApi } from "../../../../../../hooks/http2";
import { URL_API, _ } from "../../../../../../utils";

const SelectOracleSubCategory = ({ category_id, ...props }) => {
  const list = useApi(
    {
      url: `${URL_API}/setting/master/oracle-sub-category`,
      token: true,
      params: { category_id },
    },
    [category_id]
  );

  return (
    <SelectOption
      loading={list.loading}
      data={_.map(list.fetch?.data || [], (n) => ({
        value: n.id?.toString(),
        text: `${n.name_local}`,
      }))}
      withAll
      allText={
        (list.fetch?.data || []).length > 0
          ? "เลือก Sub Category"
          : "-- ไม่มีข้อมูล --"
      }
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectOracleSubCategory forwardRef={ref} {...props} />;
});
