/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row, Typography } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import { _ } from "../../../../../utils";
import ConsiderRemark from "./ConsiderRemark";
import HistoryConsider from "./HistoryConsider";
import UserConsider from "./UserConsider";
import UserSave from "./UserSave";

const { Text } = Typography;

const { clearCreate } = actionRequisition;

const Index = ({ isPreview }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const documentStatus = useSelector(
    (state) => state.requisition.documentDetail.status
  );
  const { process: process_params, document_no } = match.params;
  const isConsider =
    document_no && document_no !== "0" && documentStatus !== "return";

  const handleBack = useCallback(() => {
    const back_url = `/${_.split(match.path, "/")[1]}/${process_params}`;
    dispatch(clearCreate());
    history.push({ pathname: back_url });
  }, []);

  const handleClickBack = () => {
    if (isPreview) return handleBack();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1">
            ย้อนกลับ
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk: handleBack,
    });
  };

  return (
    <>
      <HistoryConsider />
      {!isPreview && isConsider && <ConsiderRemark />}
      <Row gutter={[8, 8]}>
        <Col span={24} className="my-3 text-center">
          <Button
            type="primary"
            ghost
            className="width-100 mr-3"
            onClick={handleClickBack}
          >
            ย้อนกลับ
          </Button>
          {!isPreview && (
            <>
              {isConsider ? (
                <UserConsider handleBack={handleBack} />
              ) : (
                <UserSave handleBack={handleBack} />
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Index;
