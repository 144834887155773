import { Table } from "antd";
import React from "react";

export default function TableImportTicketFile({ data }) {
  return (
    <Table
      size="small"
      bordered
      scroll={{
        x: "max-content",
      }}
      dataSource={data || []}
      rowKey={(record, index) => record.id || index}
    >
      <Table.Column
        title="partner_code"
        key="partner_code"
        render={(data) => data.partner_code}
      />
      <Table.Column
        title="total"
        key="total"
        align="right"
        render={(data) => data.total}
      />
    </Table>
  );
}