/* eslint-disable no-unused-vars */
import { Col, Form, Input, Row, Typography } from "antd";
import React, { useContext } from "react";
import { VoucherContext } from "../../reducers";
import { Editor } from "react-draft-wysiwyg";

const Detail = ({ getFieldDecorator }) => {
  const { state, dispatch } = useContext(VoucherContext);

  return (
    <>
      <Row gutter={16}>
        <Col md={12}>
          <label className="mb-0">รหัสบัตรกำนัล</label>
          <Form.Item className="mb-0">
            {getFieldDecorator(
              "code",
              {}
            )(
              <Input
                autoComplete="off"
                placeholder="รหัสบัตรกำนัล"
                disabled={true}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="mb-0">ชื่อบัตรกำนัล</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุชื่อบัตรกำนัล",
                },
              ],
            })(<Input autoComplete="off" placeholder="ชื่อบัตรกำนัล" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <label className="mb-0">รายละเอียดบัตรกำนัล</label>
          <Editor
            editorClassName="border"
            editorState={state.data.description}
            onEditorStateChange={(e) =>
              dispatch({ type: "SET_DATA", payload: { description: e } })
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Detail;
