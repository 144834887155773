import { Alert, Button, Modal, notification } from "antd";
import numeral from "numeral";
import React, { useContext, useEffect, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API, _, axios, getToken, moment } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import ModalCancel from "../components/ModalCancel";
import ModalReserve from "../components/ModalReserve";
import Table from "../components/Table";
import ModalContext from "../contexts/modal-context";

const limit = 10;
const Confirm = ({ criteria, setActiveTab }) => {
  const {
    state: { reserveModal, cancelModel },
    dispatch: modalDispatch,
  } = useContext(ModalContext);
  const { showReserve } = reserveModal;
  const { showCancel } = cancelModel;

  const [activeId, setActiveId] = useState(null);
  const [hasStock, setHasStock] = useState(false);
  //const [showReserve, setShowReserve] = useState(false)
  //const [showCancel, setShowCancel] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/operation/reserve`,
      params: {
        status: "waiting for confirm",
        limit,
        offset: limit * (page - 1),
        ...criteria,
        date_from: criteria.date_from
        ? moment(criteria.date_from).format("YYYY-MM-DD")
        : null,
        date_to: criteria.date_to
        ? moment(criteria.date_to).format("YYYY-MM-DD")
        : null,
      },
    },
    [criteria, page]
  );

  useEffect(() => {
    setPage(1);
  }, [criteria]);

  const columns = [
    {
      title: "เลขที่ชุดจอง",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "วันที่",
      dataIndex: "document_date",
      render: (text) => (
        <p className="m-0 text-left">
          {moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "รหัสนักธุรกิจ",
      dataIndex: "buyer_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ผู้ดำเนินการ",
      dataIndex: "buyer_name",
      render: (text) => <p className="m-0">{text}</p>,
    },
    {
      title: "ผู้ทำรายการจอง",
      dataIndex: "cashier_name",
      render: (text) => <p className="m-0">{text}</p>,
    },
    {
      title: "ผู้แจ้งชำระเงิน",
      dataIndex: "confirm_by",
      render: (text) => <p className="m-0">{text}</p>,
    },
    {
      title: "วันที่แจ้งชำระเงิน",
      dataIndex: "confirm_date",
      render: (text) => (
        <p className="m-0">{moment(text).format("DD/MM/YYYY HH:mm")}</p>
      ),
    },
    // {
    //   title: "มีสินค้าในคลัง",
    //   dataIndex: "has_stock",
    //   render: (text) => (
    //     <p className="m-0 text-center">
    //       {text ? (
    //         <FaCheck className="text-pv" />
    //       ) : (
    //         <FaTimes className="text-reds" />
    //       )}
    //     </p>
    //   ),
    // },
    {
      title: "ยอดรวม",
      dataIndex: "total_price",
      render: (text) => (
        <p className="m-0 text-right">{numeral(text).format("0,0.00")}</p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <p className="m-0 text-right">
          <Button
            size="small"
            className="bg-pv text-white border-pv mr-2"
            onClick={() => {
              modalDispatch({
                type: "setReserveModal",
                payload: { showReserve: true },
              });
              setActiveId(record.id);
              setHasStock(record.has_stock);
            }}
          >
            <small>ยืนยันการจ่ายเงิน</small>
          </Button>
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (data?.data.record) {
      setDataSource(data?.data.record);
    }
  }, [data]);

  const handleCloseModalReserve = () => {
    modalDispatch({ type: "setReserveModal", payload: { showReserve: false } });
    setActiveId(null);
  };

  const returnWaitTab = () => {
    let source = axios.CancelToken.source();
    const { document_no } = _.find(dataSource, (n) => n.id === activeId);
    Modal.confirm({
      title: "ยืนยันการส่งกลับ ยืนยันเงินอีกครั้ง",
      content: `เลขที่ชุดจอง ${document_no} ?`,
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/pos/operation/reserve/${activeId}/return`,
            data: {
              id: data.id,
              process: "delete",
            },
            headers: { Authorization: "Bearer " + getToken() },
            cancelToken: source.token,
          })
            .then(() => {
              handleCloseModalReserve();
              setActiveTab("wait");
              notification.success({
                message: "Success",
                description: "บันทึกรายการเรียบร้อย",
                placement: "topRight",
              });
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  if (error) return <Alert type="error" message={error} />;

  return (
    <>
      <Table
        page={page}
        setPage={setPage}
        columns={columns}
        total={data?.data?.total}
        loading={loading}
        dataSource={dataSource}
      />

      <ModalReserve
        visible={showReserve}
        onCancel={handleCloseModalReserve}
        clickCancel={() => {
          modalDispatch({
            type: "setReserveModal",
            payload: { showReserve: false },
          });
          modalDispatch({
            type: "setCancelModal",
            payload: { showCancel: true },
          });
        }}
        returnWaitTab={returnWaitTab}
        id={activeId}
        reload={reload}
        hasStock={hasStock}
      />

      <ModalCancel
        visible={showCancel}
        onCancel={() => {
          modalDispatch({
            type: "setCancelModal",
            payload: { showCancel: false },
          });
          setActiveId(null);
        }}
        id={activeId}
        reload={reload}
      />
    </>
  );
};

export default Confirm;
