import React, { forwardRef } from "react";
import SelectOption from "../../../../../../components/SelectOption";
import { useApi } from "../../../../../../hooks/http2";
import { URL_API, _ } from "../../../../../../utils";

const SelectItemType = (props) => {
  const list = useApi(
    {
      url: `${URL_API}/system/master/item_type`,
      token: true,
    },
    []
  );

  return (
    <SelectOption
      loading={list.loading}
      data={_.map(list.fetch?.data || [], (n) => ({
        value: n.code?.toString(),
        text: n.name,
      }))}
      withAll
      allText="เลือกรูปแบบสินค้า"
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectItemType forwardRef={ref} {...props} />;
});
