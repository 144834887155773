/* eslint-disable no-unused-vars */
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";

const { Option } = Select;

const { setGlobalState } = actionClaim;

const SelectStatus = () => {
  const { process: process_params } = useParams();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState('')
  const status = useSelector((state) => state.claim.searchDocument.status);

  useEffect(() => {
    let result = [];
    switch (process_params) {
      case "wait":
        result = [
          {
            name: "รอติดต่อเคลม",
            value: "waiting",
          },
          {
            name: "ฉบับร่าง",
            value: "draft",
          },
        ];
        break;
      case "pending":
        result = [
          {
            name: "ผู้จัดการสาขา",
            value: "wait-branch",
          },
          {
            name: "Manager",
            value: "wait-manager",
          },
        ];
        break;
      case "result":
        result = [
          {
            name: "อนุมัติ",
            value: "approve",
          },
          {
            name: "ไม่อนุมัติ",
            value: "reject",
          },
        ];
        break;
      case "history":
        result = [
          {
            name: "อนุมัติ",
            value: "approve",
          },
          {
            name: "ไม่อนุมัติ",
            value: "reject",
          },
          {
            name: "ยกเลิก",
            value: "cancel",
          },
        ];
        break;
      default:
        break;
    }
    setList(result);
  }, [process_params]);

  return (
    <Select
      className="w-100"
      dropdownStyle={{ zIndex: 1080 }}
      value={selected}
      onChange={(status) => {
        let value = { status, consider_status: null, page: 1 };
        setSelected(status)
        if (_.includes(["approve", "reject"], status)) {
          value = { ...value, status: "complete", consider_status: status };
        }
        dispatch(
          setGlobalState({
            name: "searchDocument",
            value,
          })
        );
      }}
    >
      <Option value="">ทั้งหมด</Option>
      {_.map(list, (n) => (
        <Option value={n.value} key={n.value}>
          {n.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectStatus;
