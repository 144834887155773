import { Modal } from "antd";
import React, { useState } from "react";
import img from "../assets/img/pos/no-img.jpg";
import { URL_API } from "../utils";

const ImagePreview = ({ id, src, visible, onCancel }) => {
  const [errImg, setErrImg] = useState(null);
  return (
    <Modal
      zIndex={1081}
      footer={false}
      width={800}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <div className="w-100 d-flex justify-content-center my-5">
        <img
          src={errImg ? errImg : id ? `${URL_API}/pos/claim/image/${id}` : src}
          className="w-100 h-100 object-fit-cover"
          alt="promotion"
          onError={() => setErrImg(img)}
        />
      </div>
    </Modal>
  );
};

export default React.memo(ImagePreview);
