import { Divider, Switch, Table, Typography, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import { URL_API, axios, getToken } from "../../../../utils";
import _ from "lodash";

const dataSource = [
  {
    size: "large",
    system: "member",
    method: "bill",
    system_title: "Inteface",
    module_title: "Bill Interface",
  },
  {
    size: "large",
    system: "all",
    method: "all",
    system_title: "การใช้งาน",
    module_title: "การใช้งานโมดูล",
  },
  {
    size: "default",
    system: "member",
    method: "shop",
    system_title: "เว็บไซต์",
    module_title: "การซื้อสินค้า",
  },
  {
    size: "default",
    system: "mobile",
    method: "shop",
    system_title: "Mobile App",
    module_title: "การซื้อสินค้า",
  },
  {
    size: "default",
    system: "member",
    method: "binary",
    system_title: "เว็บไซต์/Mobile App",
    module_title: "การแสดงข้อมูลผังองค์กร",
  },
  {
    size: "default",
    system: "member",
    method: "givepoint",
    system_title: "เว็บไซต์/Mobile App",
    module_title: "การแจงคะแนน PV",
  },
  {
    size: "default",
    system: "member",
    method: "register",
    system_title: "เว็บไซต์/Mobile App",
    module_title: "การสมัครลงผัง",
  },
  {
    size: "default",
    system: "member",
    method: "login",
    system_title: "เว็บไซต์/Mobile App",
    module_title: "การเข้าสู่ระบบ",
  },
];

const SystemStatus = () => {
  const [status, setStatus] = useState([]);

  const loadDataCallback = useCallback(async () => {
    try {
      const res = await axios.get(`/setting/system-status/all-status`, {
        baseURL: URL_API,
        headers: { Authorization: "Bearer " + getToken() },
      });
      setStatus(res.data?.data || []);
    } catch (error) {
      message.error(error?.message);
    }
  }, []);

  const handleChangeState = async (system, method, state) => {
    try {
      console.log(system, method, state);
      await axios.post(
        `/setting/system-status/state`,
        { system, method, state },
        {
          baseURL: URL_API,
          headers: { Authorization: "Bearer " + getToken() },
        }
      );

      message.success("ปรับสถานะเรียบร้อย");

      loadDataCallback();
    } catch (error) {
      message.error(error?.message);
    }
  };

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  return (
    <Panel>
      <PanelHeader>สถานะระบบ</PanelHeader>
      <PanelBody>
        <Divider orientation="left">สถานะการเปิดใช้งานระบบ</Divider>
        <Table
          bordered
          size="small"
          dataSource={_.map(dataSource, (n, i) => ({ ...n, key: i }))}
          pagination={false}
          className="w-50"
        >
          <Table.Column
            title="ระบบ"
            align="center"
            dataIndex="system_title"
            render={(text, { size }) => (
              <Typography.Paragraph
                className={`mb-0 text-left ${size !== "large" && "pl-4"}`}
                style={{ fontSize: size === "large" ? "18px" : "14px" }}
              >
                {text}
              </Typography.Paragraph>
            )}
          />
          <Table.Column
            title="โมดูล"
            align="center"
            dataIndex="module_title"
            render={(text, { size }) => (
              <Typography.Paragraph
                className={`mb-0 ${size !== "large" && "pl-4 text-left"}`}
                style={{ fontSize: size === "large" ? "18px" : "14px" }}
              >
                {text}
              </Typography.Paragraph>
            )}
          />
          <Table.Column
            title="สถานะ"
            align="center"
            render={(text, { method, system }) => (
              <Switch
                checked={
                  method === "all" && system === "all"
                    ? !_.some(
                        _.filter(
                          status,
                          (n) =>
                            ["member", "mobile"].includes(
                              n.module?.toLowerCase()
                            ) && n.method?.toLowerCase() !== "bill"
                        ),
                        (n) => !n.state
                      )
                    : _.find(
                        status,
                        (n) =>
                          method === n.method?.toLowerCase() &&
                          system === n.module?.toLowerCase()
                      )?.state
                }
                onChange={(checked) =>
                  handleChangeState(system, method, checked)
                }
              />
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default SystemStatus;
