/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  InputNumber,
  Row,
  Typography,
  DatePicker,
} from "antd";
import React, { useReducer, useState, useEffect } from "react";
import TabUnderLine from "../../../components/TabUnderLine";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import Cancel from "./cancel";
import Complete from "./complete";
import Confirm from "./confirm";
import ModalContext, * as visibles from "./contexts/modal-context";
import Waiting from "./waiting";
import moment from "moment";
const Model = {
  receive_type: "",
  branch: "",
  bank: "",
  balance_start: null,
  balance_end: null,
  query: "",
  date_from: null,
  date_to: null,
};
const listTab = [
  { title: "รอ/แจ้งการชำระเงิน", value: "wait" },
  { title: "ยืนยันการชำระเงิน", value: "confirm" },
  { title: "ตรวจสอบเสร็จสิ้น", value: "complete" },
  { title: "รายการยกเลิก", value: "cancel" },
];
const Index = () => {
  const [state, dispatch] = useReducer(visibles.reducer, visibles.initState);
  const [activeTab, setActiveTab] = useState("wait");
  const [criteria, setCriteria] = useState(Model);
  const [sendCriteria, setSendCriteria] = useState(Model);

  useEffect(() => {
    if (activeTab === "complete" || activeTab === "cancel" ) {
      let _model = {
        ...sendCriteria,
        date_from: moment(),
        date_to: moment(),
      };
      setCriteria(_model);
      setSendCriteria(_model);
    } else {
      setCriteria(Model);
      setSendCriteria(Model);
    }
  }, [activeTab]);
  // const handleChangeSearch = (e) => {
  //     setCriteria({ ...criteria, query: e.target.value })
  // }

  return (
    <Panel>
      <PanelHeader>ข้อมูลการจอง</PanelHeader>
      <PanelBody>
        <Row className="mb-4 mt-3">
          <Col span={20} offset={2}>
            <TabUnderLine
              active={activeTab}
              list={listTab}
              onClick={(e) => setActiveTab(e)}
            />
          </Col>
        </Row>
        {/* <Row gutter={8} className="d-flex align-items-center mb-2">
          <Col span={2}>
            <Typography.Paragraph
              strong
              className="text-nowrap text-center m-0"
            >
              วิธีรับของ
            </Typography.Paragraph>
          </Col>
          <Col span={4}>
            <Select
              size="small"
              className="w-100"
              style={{ fontSize: "12px" }}
              dropdownStyle={{ zIndex: "1200", fontSize: "12px" }}
              onChange={(value) =>
                setCriteria({ ...criteria, receive_type: value })
              }
            >
              <Select.Option value="">ทั้งหมด</Select.Option>
              <Select.Option value="Branch">รับที่สาขา</Select.Option>
              <Select.Option value="Transport">จัดส่ง</Select.Option>
            </Select>
          </Col>
          <Col span={2}>
            <Typography.Paragraph
              strong
              className="text-nowrap text-center m-0"
            >
              สาขา
            </Typography.Paragraph>
          </Col>
          <Col span={4} className="d-flex align-items-center">
            <SelectBranch
              withAll
              size="small"
              className="w-100"
              style={{ fontSize: "12px" }}
              dropdownStyle={{ zIndex: "1200", fontSize: "12px" }}
              onChange={(value) => setCriteria({ ...criteria, branch: value })}
            />
          </Col>
          <Col span={2}>
            <Typography.Paragraph
              strong
              className="text-nowrap text-center m-0"
            >
              ธนาคาร
            </Typography.Paragraph>
          </Col>
          <Col span={4} className="d-flex align-items-center">
            <SelectBank
              withAll
              size="small"
              className="w-100"
              style={{ fontSize: "12px" }}
              dropdownStyle={{ zIndex: "1200", fontSize: "12px" }}
              onChange={(value) => setCriteria({ ...criteria, bank: value })}
            />
          </Col>
          <Col span={2}>
            <Typography.Paragraph
              strong
              className="text-nowrap text-center m-0"
            >
              ยอดเงิน
            </Typography.Paragraph>
          </Col>
          <Col span={4} className="d-flex align-items-center">
            <Input
              size="small"
              className="w-50"
              onChange={(e) =>
                setCriteria({
                  ...criteria,
                  balance_start: parseInt(e.target.value || 0),
                })
              }
            />
            <Typography.Text strong className="mx-2 text-nowrap">
              -
            </Typography.Text>
            <Input
              size="small"
              className="w-50"
              onChange={(e) =>
                setCriteria({
                  ...criteria,
                  balance_end: parseInt(e.target.value || 0),
                })
              }
            />
          </Col>
        </Row> */}
        <Row gutter={8} className="mb-3">
          <Col span={6} className="d-flex align-items-center">
            <Typography.Text strong className="mx-2 text-nowrap">
              ยอดรวม
            </Typography.Text>
            <InputNumber
              size="small"
              className="w-50"
              value={criteria.balance_start}
              onChange={(e) =>
                setCriteria({ ...criteria, balance_start: parseInt(e || 0) })
              }
              onFocus={(e) => e.target.select()}
            />
            <Typography.Text strong className="mx-2 text-nowrap">
              -
            </Typography.Text>
            <InputNumber
              size="small"
              className="w-50"
              value={criteria.balance_end}
              onChange={(e) =>
                setCriteria({ ...criteria, balance_end: parseInt(e || 0) })
              }
              onFocus={(e) => e.target.select()}
            />
          </Col>
          { (activeTab === "complete" || activeTab === "cancel") && (
              <Col span={6} className="d-flex align-items-center">
                <DatePicker.RangePicker
                  size="small"
                  className="w-100"
                  value={[criteria?.date_from, criteria?.date_to]}
                  placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                  format="DD/MM/YYYY"
                  onChange={(e) => {
                    setCriteria({
                      ...criteria,
                      date_from: e[0] || null,
                      date_to: e[1] || null,
                    });
                  }}
                />
              </Col>
            )}
          <Col span={10}>
            <Input
              allowClear
              size="small"
              placeholder={`${
                activeTab === "complete" ? "เลขที่บิล/" : ""
              }เลขที่ชุดจอง/เลขที่ใบจอง/รหัสนักธุรกิจ/ผู้ดำเนินการ/ผู้ทำรายการจอง`}
              onChange={(e) =>
                setCriteria({ ...criteria, query: e.target.value })
              }
              onKeyUp={(e) => e.key === "Enter" && setSendCriteria(criteria)}
            />
          </Col>
          <Col span={2}>
            <Button
              size="small"
              block
              className="bg-gray-60 text-white border-gray-60"
              style={{ fontSize: "12px" }}
              onClick={() => setSendCriteria(criteria)}
            >
              ค้นหา
            </Button>
          </Col>
        </Row>
        <ModalContext.Provider value={{ state, dispatch }}>
          {activeTab === "cancel" ? (
            <Cancel criteria={sendCriteria} />
          ) : activeTab === "confirm" ? (
            <Confirm criteria={sendCriteria} setActiveTab={setActiveTab} />
          ) : activeTab === "complete" ? (
            <Complete criteria={sendCriteria} />
          ) : (
            <Waiting criteria={sendCriteria} />
          )}
        </ModalContext.Provider>
      </PanelBody>
    </Panel>
  );
};

export default Index;
