/* eslint-disable no-unused-vars */
import { Button, List, Modal } from "antd";
import React from "react";
import { FaUnlockAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { memberSelector,marriedSeleector } from "../../../../redux/slices/member";
import { URL_API, axios, getToken, getUser } from "../../../../utils";
import { AlertError, AlertSuccess } from "../../../util/Alert.js";
export default function ListDetail() {
  const match = useRouteMatch();
  const history = useHistory();
  const member = useSelector(memberSelector);
  const married = useSelector(marriedSeleector);
  const user = getUser();
 
  function unlockUser() {
    Modal.confirm({
      title: "ปลดล็อกการเข้าสู่ระบบให้สมาชิก",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: handleOkUnlock,
    });
  }

  function unlockMaried() {
    Modal.confirm({
      title: "ปลดล็อกการเข้าสู่ระบบให้ผู้สมัครร่วม",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: handleOkUnlockMaried,
    });
  }

  const handleOkUnlock = () => {
    axios({
      method: "POST",
      baseURL: URL_API,
      url: `member/unlock/${match.params.id}`,
      headers: { Authorization: "Bearer " + getToken() },
      params: {
        member_type: match.params.member_type,
      },
    })
      .then((res) => {
        AlertSuccess(res.data.message);
        history.push(match.url);
      })
      .catch((err) => {
        AlertError({ title: "เกิดข้อผิดพลาด", text: err });
      });
  };

  const handleOkUnlockMaried = () => {
    axios({
      method: "POST",
      baseURL: URL_API,
      url: `member/unlockMaried/${married?.id}`,
      headers: { Authorization: "Bearer " + getToken() },
      // params: {
      //   member_type: match.params.member_type,
      // },
    })
      .then((res) => {
        AlertSuccess(res.data.message);
        history.push(match.url);
      })
      .catch((err) => {
        AlertError({ title: "เกิดข้อผิดพลาด", text: err });
      });
  };

  function activateUser() {
    Modal.confirm({
      title: "ปลดล็อกการเข้าสู่ระบบให้สมาชิก",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: handleOkActivate,
    });
  }
  const handleOkActivate = () => {
    axios({
      method: "POST",
      baseURL: URL_API,
      url: `member/activate/${match.params.id}`,
      headers: { Authorization: "Bearer " + getToken() },
      params: {
        member_type: match.params.member_type,
      },
      data: {
        member_code: member.member_code,
        user_id: user.id,
      },
    })
      .then((res) => {
        AlertSuccess(res.data.message);
        history.push(match.url);
      })
      .catch((err) => {
        AlertError({ title: "เกิดข้อผิดพลาด", text: err });
      });
  };
  return (
    <>
      <List size="small" bordered className="mt-3 mb-3">
        <List.Item>
          <p className="w-100 mb-0">
            <strong>{member.member_code}</strong>
          </p>
        </List.Item>
        <List.Item>
          <p className="w-100 mb-0">
            {member.id && member.first_name_th + " " + member.sur_name_th}
          </p>
        </List.Item>
        <List.Item>
          <p className="w-100 mb-0">
            {match.params.member_type === "distributor"
              ? "นักธุรกิจ"
              : "VIP Member"}
          </p>
        </List.Item>
      </List>
      {member.signin_lock && (
        <Button className="mb-3" type="danger" shape="round" onClick={unlockUser}>
          <FaUnlockAlt className="ml-1" /> &nbsp; ปลดล็อคผู้สมัครหลัก
        </Button>
      )}
      {married?.signin_lock && (
        <Button  type="danger" shape="round" onClick={unlockMaried}>
          <FaUnlockAlt className="ml-1" /> &nbsp; ปลดล็อคผู้สมัครร่วม
        </Button>
      )}
      {member.is_delete_account && (
        <Button shape="round" onClick={activateUser}>
          <FaUnlockAlt className="ml-1" /> &nbsp; Activate
        </Button>
      )}
    </>
  );
}
