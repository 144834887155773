/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import {
  base64toFile,
  fileToBase64,
  resizeFile,
} from "../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import { AiOutlineUpload } from "react-icons/ai";

function OurProducts({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");

  const [fileListDesktop1, setFileListDesktop1] = useState([]);
  const [fileListDesktop2, setFileListDesktop2] = useState([]);
  const [fileListDesktop3, setFileListDesktop3] = useState([]);
  const [fileListDesktop4, setFileListDesktop4] = useState([]);
  const [fileListDesktop5, setFileListDesktop5] = useState([]);
  const [fileListDesktop6, setFileListDesktop6] = useState([]);

  const [fileDelete, setFileDelete] = useState([]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      formData.append("desktop_img1", values.desktop_img1?.file?.originFileObj);
      formData.append("desktop_img2", values.desktop_img2?.file?.originFileObj);
      formData.append("desktop_img3", values.desktop_img3?.file?.originFileObj);
      formData.append("desktop_img4", values.desktop_img4?.file?.originFileObj);
      formData.append("desktop_img5", values.desktop_img5?.file?.originFileObj);
      formData.append("desktop_img6", values.desktop_img6?.file?.originFileObj);

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, [
            "desktop_img1",
            "desktop_img2",
            "desktop_img3",
            "desktop_img4",
            "desktop_img5",
            "desktop_img6",
          ]),
          file_delete: fileDelete,
          title: JSON.stringify({
            title1: values.title1,
            title2: values.title2,
            title3: values.title3,
            title4: values.title4,
            title5: values.title5,
            title6: values.title6,
          }),
          target_link: JSON.stringify({
            target_link1: values.target_link1,
            target_link2: values.target_link2,
            target_link3: values.target_link3,
            target_link4: values.target_link4,
            target_link5: values.target_link5,
            target_link6: values.target_link6,
          }),
          target_tab: JSON.stringify({
            target_tab1: values.target_tab1,
            target_tab2: values.target_tab2,
            target_tab3: values.target_tab3,
            target_tab4: values.target_tab4,
            target_tab5: values.target_tab5,
            target_tab6: values.target_tab6,
          }),
        })
      );

      console.log(formData);

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path = "/manage-website/home/our-products/update")
              : (url_path = "/manage-website/home/our-products");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop1 = (e) => {
    setFileListDesktop1([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop2 = (e) => {
    setFileListDesktop2([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop3 = (e) => {
    setFileListDesktop3([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop4 = (e) => {
    setFileListDesktop4([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop5 = (e) => {
    setFileListDesktop5([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveDesktop6 = (e) => {
    setFileListDesktop6([]);
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleChangeUploadDesktop1 = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));
      if (fileListDesktop1.length < 1) {
        setFileListDesktop1([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      } else {
        setFileListDesktop1([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      }
    }
  };

  const handleChangeUploadDesktop2 = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));
      if (fileListDesktop2.length < 1) {
        setFileListDesktop2([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      } else {
        setFileListDesktop2([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      }
    }
  };

  const handleChangeUploadDesktop3 = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));
      if (fileListDesktop3.length < 1) {
        setFileListDesktop3([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      } else {
        setFileListDesktop3([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      }
    }
  };

  const handleChangeUploadDesktop4 = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));
      if (fileListDesktop4.length < 1) {
        setFileListDesktop4([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      } else {
        setFileListDesktop4([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      }
    }
  };

  const handleChangeUploadDesktop5 = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));
      if (fileListDesktop5.length < 1) {
        setFileListDesktop5([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      } else {
        setFileListDesktop5([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      }
    }
  };

  const handleChangeUploadDesktop6 = async (e) => {
    console.log("onchange ", e);
    let file = e.file.originFileObj;
    if (file) {
      let img_url = "";
      if (e.file.size / 1024 / 1024 < 3) {
        img_url = await fileToBase64(file);
      } else {
        img_url = await resizeFile(file);
        file = await base64toFile(img_url, file.name);
      }
      // dispatch(ADD_MEDIA({ img_url, file }));
      if (fileListDesktop6.length < 1) {
        setFileListDesktop6([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      } else {
        setFileListDesktop4([
          {
            uid: file.uid,
            status: "done",
            name: file.name,
            url: img_url,
          },
        ]);
      }
    }
  };
  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/home/our-products",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);
        console.log(data);

        let { loadDesktopImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            if (data.files[i].link_target == "desktop_img1") {
              setFileListDesktop1(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img2") {
              setFileListDesktop2(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img3") {
              setFileListDesktop3(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img4") {
              setFileListDesktop4(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img5") {
              setFileListDesktop5(loadDesktopImg);
            } else if (data.files[i].link_target == "desktop_img6") {
              setFileListDesktop6(loadDesktopImg);
            }
          }
        }

        let loadTitle = JSON.parse(data.title);
        let loadTargetLink = JSON.parse(data.target_link);
        let loadTargetAction = JSON.parse(data.target_tab);

        form.setFieldsValue({
          ...loadTitle,
          ...loadTargetLink,
          ...loadTargetAction,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const itemStyles = {
    width: "100%",
    height: "100%",
    border: "1px solid grey",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#FAFAFA",
    fontSize: "16pt",
  };

  const templateLayout = () => {
    return (
      <Row className="mb-5">
        <h5>รูปแบบ layout ( 6 ช่อง )</h5>
        <div
          style={{
            width: "400px",
            height: "auto",
            border: "1px solid grey",
            display: "grid",
            gridTemplate: "100px 100px/ 1fr 1fr 1fr",
          }}
        >
          <div style={itemStyles}>1</div>
          <div style={itemStyles}>2</div>
          <div style={itemStyles}>3</div>
          <div style={itemStyles}>4</div>
          <div style={itemStyles}>5</div>
          <div style={itemStyles}>6</div>
        </div>
      </Row>
    );
  };

  const { getFieldDecorator } = form;
  return (
    <div>
      <Panel>
        <PanelHeader>ผลิตภัณฑ์ของเรา</PanelHeader>
        <PanelBody>
          <div>{templateLayout()}</div>
          <Row>
            <Col md={12}>
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="ช่องที่ 1"
                // extra={<a href="#">More</a>}
              >
                <Row>
                  <Col>
                    <Form.Item label="Title">
                      {getFieldDecorator("title1", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12}>
                    <Form.Item label="Target Link">
                      {getFieldDecorator("target_link1", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Action">
                      {getFieldDecorator("target_tab1", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-2">
                    <Form.Item label="เพิ่มรูปภาพ">
                      {getFieldDecorator("desktop_img1", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop1}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveDesktop1}
                          onChange={handleChangeUploadDesktop1}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload Image
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="ช่องที่ 2"
                // extra={<a href="#">More</a>}
              >
                <Row>
                  <Col>
                    <Form.Item label="Title">
                      {getFieldDecorator("title2", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12}>
                    <Form.Item label="Target Link">
                      {getFieldDecorator("target_link2", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Action">
                      {getFieldDecorator("target_tab2", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-2">
                    <Form.Item label="เพิ่มรูปภาพ">
                      {getFieldDecorator("desktop_img2", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop2}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveDesktop2}
                          onChange={handleChangeUploadDesktop2}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload Image
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="ช่องที่ 3"
                // extra={<a href="#">More</a>}
              >
                <Row>
                  <Col>
                    <Form.Item label="Title">
                      {getFieldDecorator("title3", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12}>
                    <Form.Item label="Target Link">
                      {getFieldDecorator("target_link3", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Action">
                      {getFieldDecorator("target_tab3", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-2">
                    <Form.Item label="เพิ่มรูปภาพ">
                      {getFieldDecorator("desktop_img3", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop3}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveDesktop3}
                          onChange={handleChangeUploadDesktop3}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload Image
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="ช่องที่ 4"
                // extra={<a href="#">More</a>}
              >
                <Row>
                  <Col>
                    <Form.Item label="Title">
                      {getFieldDecorator("title4", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12}>
                    <Form.Item label="Target Link">
                      {getFieldDecorator("target_link4", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Action">
                      {getFieldDecorator("target_tab4", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-2">
                    <Form.Item label="เพิ่มรูปภาพ">
                      {getFieldDecorator("desktop_img4", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop4}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveDesktop4}
                          onChange={handleChangeUploadDesktop4}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload Image
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="ช่องที่ 5"
                // extra={<a href="#">More</a>}
              >
                <Row>
                  <Col>
                    <Form.Item label="Title">
                      {getFieldDecorator("title5", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12}>
                    <Form.Item label="Target Link">
                      {getFieldDecorator("target_link5", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Action">
                      {getFieldDecorator("target_tab5", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-2">
                    <Form.Item label="เพิ่มรูปภาพ">
                      {getFieldDecorator("desktop_img5", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop5}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveDesktop5}
                          onChange={handleChangeUploadDesktop5}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload Image
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="ช่องที่ 6"
                // extra={<a href="#">More</a>}
              >
                <Row>
                  <Col>
                    <Form.Item label="Title">
                      {getFieldDecorator("title6", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12}>
                    <Form.Item label="Target Link">
                      {getFieldDecorator("target_link6", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Action">
                      {getFieldDecorator("target_tab6", {
                        initialValue: "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-2">
                    <Form.Item label="เพิ่มรูปภาพ">
                      {getFieldDecorator("desktop_img6", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          accept="image/*"
                          multiple={false}
                          fileList={fileListDesktop6}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveDesktop6}
                          onChange={handleChangeUploadDesktop6}
                          listType="picture"
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload Image
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Button
              type="primary"
              onClick={() => {
                handleClickSubmit();
              }}
            >
              {loadValue != (null || "") ? "Update" : "Save"}
            </Button>
          </Row>
        </PanelBody>
      </Panel>
    </div>
  );
}
export default Form.create("home-our-products")(OurProducts);
