/* eslint-disable eqeqeq */
import React from "react";
import { Select, Tag, List } from "antd";
import { _ } from "../../../../utils";
import styles from "../../../../scss/module/pos.module.scss";
import { FaSearch, FaTimes } from "react-icons/fa";

const { Option } = Select;

const SelectSearch = ({ selectId, ...props }) => {
  return (
    <div className={styles["select-search"]}>
      <Select
        id={selectId || "selectId"}
        className="w-100"
        showSearch
        filterOption={false}
        showArrow={false}
        notFoundContent={null}
        tabIndex={1}
        dropdownStyle={{ zIndex: 1080 }}
        {..._.omit(props, ["loadList", "onClick"])}
      >
        {props.loadList?.map((n, i) => (
          <Option key={i}>
            <List.Item.Meta
              title={
                <>
                  {`(${n.code}) ${n.first_name_th} ${n.sur_name_th}`}
                  {n.text_suffix !== "" && (
                    <Tag className="ml-2" color="magenta">
                      {n.text_suffix}
                    </Tag>
                  )}
                  {n.is_freeze != 0 && (
                    <Tag className="ml-2" color="blue">
                      รหัส Freeze
                    </Tag>
                  )}
                </>
              }
              description={n.email}
            />
          </Option>
        ))}
      </Select>
      {props.loading ? (
        <FaTimes
          className={`${styles["btn-cancel"]} ${
            props.size === "small" ? styles.small : ""
          }`}
          onClick={() => props.onClickCancel()}
        />
      ) : (
        <FaSearch
          className={`${styles["btn-search"]} ${
            props.size === "small" ? styles.small : ""
          }`}
          onClick={() => props.onClickSearch()}
        />
      )}
    </div>
  );
};

export default SelectSearch;
