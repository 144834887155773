import { Checkbox, Col, Row, Table } from "antd";
import React, { useState } from "react";
import { useApi } from "../../../../hooks/http2";
import { axios, getToken, URL_API, _ } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import SelectDepartment from "./user/components/SelectDepartments";
import SelectDivision from "./user/components/SelectDivision";

const PermissionProductRequest = () => {
  const [divisionId, setDivisionId] = useState("");
  const [departmentId, setDepartmentId] = useState("");

  let columns = useApi(
    {
      baseURL: URL_API,
      url: "/setting/permission/feild-form/product-request",
      token: true,
      params: {
        department_id: departmentId,
      },
    },
    [departmentId]
  );

  let rows = useApi(
    {
      baseURL: URL_API,
      url: "/setting/permission/field-visible",
      token: true,
      params: {
        department_id: departmentId,
      },
    },
    [departmentId]
  );

  const handleClickCheckbox = (e, division_id, category_id, field_form_id) => {
    console.log(e.target.checked, division_id, category_id, field_form_id);
    axios({
      method: "post",
      baseURL: URL_API,
      url: "/setting/permission/field-visible",
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        division_id,
        category_id,
        field_form_id,
        type: e.target.checked ? "add" : "del",
      },
    })
      .then((res) => {
        console.log(res);
        rows.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Panel>
      <PanelHeader>ตั้งค่าสิทธิการแสดงรายละเอียดขอสินค้า</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col md={6}>
            <SelectDivision
              className="w-100"
              value={divisionId}
              onChange={(e) => {
                setDivisionId(e);
                setDepartmentId("");
              }}
              allText="-- กรุณาเลือกฝ่าย --"
            />
          </Col>
          <Col md={6}>
            <SelectDepartment
              className="w-100"
              value={departmentId}
              onChange={(e) => setDepartmentId(e)}
              allText="-- กรุณาเลือกแผนก --"
              division_id={divisionId}
            />
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          pagination={false}
          scroll={{ x: "max-content" }}
          loading={columns.loading || rows.loading}
          className="rotate-header"
          dataSource={rows.fetch?.data || []}
        >
          <Table.Column
            title="แผนก"
            fixed="left"
            dataIndex="department_name"
            className="text-nowrap"
          />
          <Table.Column
            title="หมวดสินค้า"
            fixed="left"
            dataIndex="category_name"
            className="text-nowrap"
          />
          {_.map(columns?.fetch?.data || [], (n) => (
            <Table.Column
              key={n.id}
              title={
                <span style={{ transform: "rotate(-90deg)" }}>
                  {n.name_local}
                </span>
              }
              align="center"
              render={(text, record) => {
                return (
                  <Checkbox
                    checked={record[n.code] != null}
                    onClick={(e) =>
                      handleClickCheckbox(
                        e,
                        record.department_id,
                        record.category_id,
                        n.id
                      )
                    }
                  />
                );
              }}
            />
          ))}
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default PermissionProductRequest;
