/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Spin,
  Table,
  typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { AlertError } from "../../../../util/Alert";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const { Text } = typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentLink, setDocumentLink] = useState();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-document-history`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        partnerCode: state.filter.query,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  console.log(data);

  const exportDataData = data ? [data?.data] : [];

  const exportToExcel = () => {
    if (exportDataData.length !== 0) {
      let excelData = [
        ["Document History"],
        [
          `รหัสสมาชิก : ${state.filter.query}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [],
        [
          "ลำดับ",
          "ID",
          "Name",
          "Doc Typy (Filter-Doc Type)",
          "Document Name",
          "File",
          "Date",
          "ผู้ดำเนินการ",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            n.row_id,
            n.partner_code,
            n.first_name_th + " " + n.sur_name_th,
            n.document_type,
            n.file_name,
            n.azure_url,
            n.update_date === null
              ? "-"
              : moment(n.update_date).format("DD/MM/YYYY"),
            n.update_by,
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Document History");
      XLSX.writeFile(wb, "Document History.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  const showModal = (document_link) => {
    setDocumentLink(document_link);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>Document History</PanelHeader>
        <PanelBody>
          <FilterList /*exportToExcel={""}*/
            allowDisableBranch
            reload={() => {
              state.filter.query === ""
                ? AlertError({
                    title: "ข้อมูลไม่ถูกต้อง",
                    text: "กรุณาระบุรหัสสมาชิก",
                  })
                : reload();
            }}
            placeholder="ระบุรหัสสมาชิก"
            showReportSpan={10}
            inputSpan={6}
            topicSpan={6}
            Topic={`ชื่อสมาชิก : `}
            showDatePicker={false}
            exportToExcel={exportToExcel}
          />
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.data ? dataSource.data.length : 50,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            {/* <Table.Column title="ลำดับ" dataIndex="row_id" align="center" /> */}
            <Table.Column title="ID" dataIndex="partner_code" align="center" />
            <Table.Column
              title="Name"
              align="left"
              render={(_, record) =>
                record.first_name_th + " " + record.sur_name_th
              }
            />
            <Table.Column
              title="Doc Typy (Filter-Doc Type)"
              dataIndex="document_type"
              align="left"
            />
            <Table.Column
              title="Document Name"
              dataIndex="file_name"
              align="left"
            />
            <Table.Column
              title="File"
              dataIndex="azure_url"
              align="center"
              render={(text) =>
                text === null ? (
                  <Button disabled>File</Button>
                ) : (
                  <Button onClick={() => showModal(text)}>File</Button>
                )
              }
            />
            <Table.Column
              title="Date"
              dataIndex="update_date"
              align="center"
              render={(text) =>
                text === null ? "-" : moment(text).format("DD/MM/YYYY")
              }
            />
            <Table.Column
              title="ผู้ดำเนินการ"
              dataIndex="update_by"
              align="center"
            />
          </Table>

          <Modal
            title="เอกสาร"
            width={800}
            visible={isModalVisible}
            cancelText="ปิด"
            okButtonProps={{ style: { display: "none" } }}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "760px",
              }}
            >
              <img
                src={documentLink}
                alt=""
                style={{ objectFit: "cover", maxWidth: "750px" }}
              />
            </Row>
          </Modal>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
