/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Col, Input, Row, Select, message, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useEventMutate } from "../../../../../contexts/events.context";
import SelectEvent from "./SelectEvent";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import xlsx from "xlsx";
import moment from "moment";
import _ from "lodash";
import { eventRequest } from "../../../../../utils/useAxios";
import { useHistory } from 'react-router-dom';

const CriteriaEvent = ({ form }) => {
  const {
    state: { search, exporting, setStorage },
    dispatch,
  } = useEventMutate();

  const [storageObj, setStorageObj] = useState({})
  const { getFieldDecorator, validateFields } = form;
  const history = useHistory();
  const previousPath = history.location.state ? history.location.state.from : '';

  const handleChange = (name, value) => {
    dispatch({
      type: "SET_SEARCH",
      payload: {
        [name]: value,
        page: 1,
      },
    });
    handleSetLocalStorageWithExpiration(name, value)
  };

  const exportExcel = async () => {
    try {
      dispatch({ type: "SET_EXPORTING", payload: true });
      const res = await eventRequest.get("/events/export", {
        params: {
          search: search.query,
          eventId: search.event_id,
          eventType: search.event_type,
          ticketType: search.ticket_type,
          status: search.status,
        },
      });

      const data = res.data?.items || [];

      if (data.length === 0) {
        throw new Error("ไม่พบข้อมูล");
      }
      const wb = xlsx.utils.book_new();
      const dataWS = xlsx.utils.json_to_sheet(
        _.map(data, (n, i) => ({
          ลำดับ: i + 1,
          ชื่องาน: n?.EventName,
          รูปแบบโปรเจ็กต์: n?.EventType,
          PrefixSerial: n?.PrefixSerial,
          ประเภทบัตรงาน: n?.TicketType === 'FREE' ? 'ไม่มีค่าใช้จ่าย' : 'บัตรมีราคา',
          ระยะเวลาการจัดงาน: n?.EventDateType === 'ONE_DAY' ? 'วันเดียว' : 'มากกว่า 1 วัน',
          วันที่เริ่มงาน: moment(n?.StartDate).format("DD/MM/YYYY HH:mm"),
          วันที่สิ้นสุดงาน: moment(n?.EndDate).format("DD/MM/YYYY HH:mm"),
          ระยะเวลาการลงทะเบียน: n?.RegDateType === 'ONE_DAY' ? 'วันเดียว' : 'มากกว่า 1 วัน',
          วันที่เริ่มการลงทะเบียน: moment(n?.RegStartDate).format("DD/MM/YYYY HH:mm"),
          วันที่สิ้นสุดการลงทะเบียน: moment(n?.RegEndDate).format("DD/MM/YYYY HH:mm"),
          วันที่เริ่มแสดงผลรายงาน: moment(n?.ReportStartDate).format("DD/MM/YYYY HH:mm"),
          วันที่สิ้นสุดแสดงผลรายงาน: moment(n?.ReportEndDate).format("DD/MM/YYYY HH:mm"),
          สถานะการลงทะเบียน: new Date(n?.RegStartDate) <= new Date() && new Date(n?.RegEndDate) >= new Date() ? 'เปิดลงทะเบียน' : 'ปิดลงทะเบียน',
          สถานะ: n?.Status,
          ชื่อผู้แก้ไข: n?.UpdatedByName ? n?.UpdatedByName : n?.CreatedByName,
          วันที่แก้ไขล่าสุด: moment(n?.UpdatedAt).format("DD/MM/YYYY HH:mm")
        })),
        {
          raw: false,
        }
      );

      xlsx.utils.book_append_sheet(wb, dataWS, "event");

      xlsx.writeFile(
        wb,
        "EVENT_" + moment().format("YYYYMMDD_HHmm") + ".xlsx"
      );
      dispatch({ type: "SET_EXPORTING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_EXPORTING", payload: false });
      message.error(error.message);
    }
  };

  const handleSetLocalStorageWithExpiration = (key, value) => {
    const dataToStore = {
      key: 'event_' + key,
      value,
      expiration: new Date().getTime() + 24 * 60 * 60 * 1000 // 24 hours in milliseconds
    };
    localStorage.setItem(dataToStore.key, JSON.stringify(dataToStore));
  };

  const handleGetLocalStorage = (key) => {
    const storedData = JSON.parse(localStorage.getItem('event_' + key));
    if (storedData) {
      const currentTime = new Date().getTime();
      if (currentTime < storedData.expiration) {
        return {
          [key]: storedData.value
        }
      }
    }
    return {}
  };

  useEffect(() => {
    if (!previousPath || previousPath !== 'event') {
      for (const key in localStorage) {
        if (key.startsWith('event_')) {
          localStorage.removeItem(key);
        }
      }
    }

    const keys = ['event_id', 'event_type', 'ticket_type', 'query']
    let obj = {};
    for (const key of keys) {
      const getStorage = handleGetLocalStorage(key);
      if (Object.keys(getStorage).length > 0) {
        obj = {
          ...obj,
          ...getStorage,
        };
      }
    }
    setStorageObj(obj)
    dispatch({
      type: 'SET_SEARCH',
      payload: {
        ...obj,
        page: 1,
      },
    });
  }, []);
  
  useEffect(() => {
    if (form && Object.keys(storageObj).length > 0 && setStorage) {
      form.setFieldsValue(storageObj);
    }
  }, [storageObj, setStorage]);

  return (
    <>
      <Form>
        <Row gutter={16} className="mb-3">
          <Col md={5}>
            <SelectEvent
              className="w-100"
              placeholder="งาน"
              value={search.event_id}
              onSelect={(e) => handleChange("event_id", e)}
              withAll
              allText="งานทั้งหมด"
            />
          </Col>
          {/* <Col md={5}>
          <Select
            className="w-100"
            placeholder="สถานะ"
            value={search.status}
            onSelect={(e) => handleChange("status", e)}
          >
            <Select.Option value="">สถานะทั้งหมด</Select.Option>
            <Select.Option value={'ACTIVE'}>ACTIVE</Select.Option>
            <Select.Option value={'DELETED'}>DELETED</Select.Option>
          </Select>
        </Col> */}
          <Col md={5}>
            <Select
              className="w-100"
              placeholder="รูปแบบโปรเจ็กต์"
              value={search.event_type}
              onSelect={(e) => handleChange("event_type", e)}
            >
              <Select.Option value="">รูปแบบโปรเจ็กต์ทั้งหมด</Select.Option>
              <Select.Option value={'ONLINE'}>งานออนไลน์</Select.Option>
              <Select.Option value={'OFFLINE'}>งานออฟไลน์</Select.Option>
            </Select>
          </Col>
          <Col md={5}>
            <Select
              className="w-100"
              placeholder="ประเภทบัตรงาน"
              value={search.ticket_type}
              onSelect={(e) => handleChange("ticket_type", e)}
            >
              <Select.Option value="">ประเภทบัตรงานทั้งหมด</Select.Option>
              <Select.Option value={'NOT_FREE'}>บัตรมีราคา</Select.Option>
              <Select.Option value={'FREE'}>ไม่มีค่าใช้จ่าย</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={16} className="mb-3">
          <Col md={16}>
            <Form.Item>
              {getFieldDecorator("serial", {
              })(
                <Input.Search
                  placeholder="ชื่องาน, สถานที่จัดงาน, ลิงก์รับชมงาน, Prefix Serial"
                  allowClear
                  onSearch={(e) => handleChange("query", e || "")}
                />
              )}
            </Form.Item>
            {/* <Input.Search
              placeholder="ชื่องาน, สถานที่จัดงาน, ลิงก์รับชมงาน, Prefix Serial"
              allowClear
              value={search.query}
              onSearch={(e) => handleChange("query", e || "")}
            /> */}
          </Col>
          <Col md={4}>
            <Button type="primary" block onClick={exportExcel}>
              Export Excel
            </Button>
          </Col>
          <Col md={4}>
            <Link to={`/events/create-event`}>
              <Button type="primary" block>
                <FaPlus className="mr-2" />
                Create Event
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Form.create()(CriteriaEvent);