/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadFile from "../../../../../components/UploadFile";
import ValidateError from "../../../../../components/ValidateError";
import { actionClaim } from "../../../../../redux/slices/claim";

const { Text } = Typography;

const { setFormValue, delAuthority } = actionClaim;

const AuthorityDocument = ({ disabled }) => {
  const dispatch = useDispatch();
  const authority = useSelector((state) => state.claim.authority);
  const authority_error = useSelector((state) => state.claim.error.authority);

  const handleSelectAuthDoc = useCallback(
    (value) =>
      dispatch(
        setFormValue({
          name: "authority",
          value,
          error_name: "authority",
        })
      ),
    []
  );

  const handleDeleteAuthDoc = useCallback(() => {
    dispatch(delAuthority());
  }, []);

  return (
    <Row gutter={[8, 8]} className="my-3">
      <Col span={6} className="text-right">
        <Text strong>เอกสารใบมอบอำนาจ</Text>
      </Col>
      <Col span={18}>
        <UploadFile
          camera
          disabled={disabled}
          acceptPDF
          file={authority}
          hasError={!!authority_error}
          onChange={handleSelectAuthDoc}
          onDelete={handleDeleteAuthDoc}
        />
        <ValidateError error={authority_error} />
      </Col>
    </Row>
  );
};

export default memo(AuthorityDocument);
