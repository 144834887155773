import { Col, Popconfirm, Row, Typography } from "antd";
import React, { memo, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { _ } from "../../utils";
import { RiDeleteBin5Line } from "react-icons/ri";

const { Text } = Typography;

const BomDetail = ({ list, disabled, dataProduct, handleDelete }) => {
  const [expand, setExpand] = useState(true);
  return (
    <div className="ml-5">
      <Text className="cursor-pointer" onClick={() => setExpand(!expand)}>
        ประกอบด้วย
        {expand ? (
          <FaAngleUp className="ml-2" />
        ) : (
          <FaAngleDown className="ml-2" />
        )}
      </Text>
      {expand &&
        _.map(list, (n, i) => (
          <Row key={i}>
            <Col span={1}>
              <Text>{i + 1}</Text>
            </Col>
            <Col span={15} className="d-flex flex-column">
              <Text>{n.ref_product_name}</Text>
            </Col>
            <Col span={5} className="text-center">
              <Text>
                {/* {n?.qr_running ||
                  (dataProduct?.bom?.qr &&
                    (_.isObject(dataProduct?.bom?.qr[i])
                      ? dataProduct?.bom?.qr[i]?.qr_running || "No Running"
                      : dataProduct?.bom?.qr[i])) ||
                  "-"} */}
                  {(_.isObject(n?.qr_running)
                  ? n?.qr_running?.qr_running || "No Running"
                  : n.qr_running) || ""}
                {/* {n.barcode ? (n.qr_running ? n.qr_running : "No Running") : ""} */}
              </Text>
            </Col>
            <Col span={3} className="text-center">
              {!disabled && n.qr_running && (
                <Popconfirm
                  placement="right"
                  title={`ต้องการลบรายการนี้หรือไม่`}
                  onConfirm={() =>
                    handleDelete(n.qr_running, n.line_number, n.ref_product_code)
                  }
                  okText="ลบ"
                  cancelText="ไม่ลบ"
                  zIndex={1080}
                >
                  <Text>
                    <RiDeleteBin5Line size={20} className="cursor-pointer" />
                  </Text>
                </Popconfirm>
              )}
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default memo(BomDetail);
