/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select, DatePicker } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import {
  EventCalendarStatus,
  eventCalendarConstants,
} from '../dto/event-calendar.dto';
import ImageUpload from '../../../../fit-studio/components/upload/ImageUpload';
import moment from 'moment';
import eventCalendarServices from '../../../../../../services/event-calendar.services';

const EventCalendarForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  eventCalendarServices.useQueryGetEventCalendarById(
    { eventCalendarId: watch('id') },
    (data) => {
      reset({
        ...data,
        formType: 'edit',
        startDate: moment(data.startDate),
        endDate: moment(data.endDate),
      });
    }
  );

  return (
    <div>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={eventCalendarConstants.thumbnailImage.width}
                  height={eventCalendarConstants.thumbnailImage.height}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่องานอีเวนท์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ชื่องานอีเวนท์"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำบรรยาย <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={4}
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยาย"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่เริ่มต้น <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  className="w-100"
                  placeholder="วันที่เริ่มต้น"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="startDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="endDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันที่สิ้นสุด <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="endDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="googleMapUrl"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>Google Map Url</Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="url" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="googleMapUrl"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="linkUrl"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ลิงค์</Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="url" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="linkUrl"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />

      <Controller
        name="status"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>การมองเห็น</Typography.Text>
              </Col>
              <Col span={20}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {EventCalendarStatus.map((item) => {
                    return (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
};

export default EventCalendarForm;
