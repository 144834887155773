/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { AlertConfirm, AlertSuccess } from '../../../util/Alert';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import {
  fitnessBranchFormDefaultValues,
  fitnessTrainerFormDefaultValues,
} from '../components/dto/fitnessbranch';
import FitnessBranchForm from '../components/form/FitnessBranchForm';
import TrainerModal from '../components/modal/TrainerModal';

const FitnessBranchPage = () => {
  const fitnessBranchForm = useForm({
    defaultValues: fitnessBranchFormDefaultValues,
  });

  const { data: fitnessBranchLists } = useAsync(
    fitStudioService.getFitnessBranchFromMasterBranch,
    {
      immediate: true,
    }
  );

  const _OnFitnessBranchChange = (value) => {
    fitnessBranchForm.setValue('branchMasterId', value);
  };

  const { execute: getFitnessBranchFromMasterBranchId } = useAsync(
    fitStudioService.getFitnessBranchFromMasterBranchId,
    {
      onSuccess: (data) => {
        fitnessBranchForm.reset(data);
      },
    }
  );

  useEffect(() => {
    if (fitnessBranchForm.watch('branchMasterId')) {
      getFitnessBranchFromMasterBranchId(
        fitnessBranchForm.watch('branchMasterId')
      );
    }
  }, [fitnessBranchForm.watch('branchMasterId')]);

  const {
    execute: getFitnessTrainersByFitnessBranchId,
    data: fitnessTrainerLists,
  } = useAsync(fitStudioService.getFitnessTrainersByFitnessBranchId, {});

  const { execute: deleteTrainer } = useAsync(fitStudioService.deleteTrainer, {
    onSuccess: () => {
      getFitnessTrainersByFitnessBranchId(fitnessBranchForm.watch('id'));
      AlertSuccess('ลบครูผู้สอนสำเร็จ');
    },
  });

  useEffect(() => {
    if (fitnessBranchForm.watch('id')) {
      getFitnessTrainersByFitnessBranchId(fitnessBranchForm.watch('id'));
    }
  }, [fitnessBranchForm.watch('id')]);

  const [trainerFormOpen, setTrainerFormOpen] = useState(false);
  const trainerForm = useForm({
    defaultValues: fitnessTrainerFormDefaultValues,
  });

  const _HandleTrainerFormOpen = (formType) => {
    trainerForm.setValue('formType', formType);
    setTrainerFormOpen(true);
  };

  const _HandleTrainerFormClose = () => {
    getFitnessTrainersByFitnessBranchId(fitnessBranchForm.watch('id'));
    setTrainerFormOpen(false);
  };

  const columns = [
    {
      title: 'รูปภาพโปรไฟล์',
      dataIndex: 'profileImageUrl',
      key: 'profileImageUrl',
      render: (_, record) => (
        <img
          key={record.profileImageUrl}
          src={record.profileImageUrl}
          alt={record.profileImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 25,
          }}
        />
      ),
      width: 150,
    },
    {
      title: 'ชื่อแสดง',
      dataIndex: 'displayName',
      key: 'displayName',
      width: 150,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 150,
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 150,
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              trainerForm.setValue('id', record.id);
              _HandleTrainerFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบครูผู้สอน');
              if (confirm) deleteTrainer(record.id);
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>
        สาขาฟิตเนส
        <Select
          placeholder="กรุณาเลือกสาขา"
          onChange={_OnFitnessBranchChange}
          style={{ width: 200, marginLeft: 20 }}
        >
          {fitnessBranchLists &&
            fitnessBranchLists.length > 0 &&
            fitnessBranchLists.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.Name}
                </Select.Option>
              );
            })}
        </Select>
      </PanelHeader>
      <PanelBody>
        <div style={{ marginBottom: 10 }}>
          {fitnessBranchForm.watch('branchMasterId') !== null &&
            fitnessBranchForm.watch('branchMaster.id') !== null &&
            !fitnessBranchForm.watch('id') && (
              <Alert
                message="ยังไม่มีข้อมูลในระบบ กรุณาอัปเดทข้อมูล"
                type="error"
              />
            )}
        </div>
        {fitnessBranchForm.watch('branchMasterId') && (
          <FormProvider {...fitnessBranchForm}>
            <FitnessBranchForm />
          </FormProvider>
        )}
        {fitnessBranchForm.watch('id') && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingBottom: 10,
              }}
            >
              <Button
                className="bg-pv border-pv text-white"
                onClick={() => {
                  _HandleTrainerFormOpen('create');
                }}
              >
                <FaPlus className="mr-2" />
                เพิ่มครูผู้สอน
              </Button>
            </div>

            <Table
              columns={columns}
              dataSource={
                fitnessTrainerLists?.data &&
                fitnessTrainerLists?.data.length > 0
                  ? fitnessTrainerLists.data
                  : []
              }
              style={{ backgroundColor: '#fff' }}
              rowKey="id"
              pagination={false}
            />
          </>
        )}
      </PanelBody>
      <FormProvider {...trainerForm}>
        <TrainerModal
          open={trainerFormOpen}
          handleClose={_HandleTrainerFormClose}
          branchId={fitnessBranchForm.watch('id')}
        />
      </FormProvider>
    </Panel>
  );
};

export default FitnessBranchPage;
