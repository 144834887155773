/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Empty, Modal, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { withRouter } from "react-router";
import Preview from "../../../../components/Preview";
import { useHttp } from "../../../../hooks/http";
import { axios, URL_API, _ } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import Bill from "./Bill";
import BillSummary from "./BillSummary";
import BillTab from "./BillTab";

const ModalReserve = ({
  history,
  match,
  visible,
  onCancel,
  clickCancel,
  id,
  reload,
  returnWaitTab,
  hasStock,
  reserveId,
  toMainMenu,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [dataSource, setDataSource] = useState(null);
  const [previewData, setPreviewData] = useState({});
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/operation/reserve/${id === null ? 0 : id}`,
    },
    [id]
  );
  const [newRemark, setNewRemark] = useState([]);

  useEffect(() => {
    let newData = null;
    if (data && data.data) {
      newData = data.data;
      newData.orders.push({
        partner_code: "สรุปบิล",
      });
    }
    setActiveTab(0);
    setDataSource(newData);
  }, [data]);

  useEffect(() => {
    if (data && reserveId) {
      let content = null;
      if (
        data.data === undefined ||
        !_.includes(["subscription", "reserve"], data.data.reserve_type)
      ) {
        content = `ไม่พบข้อมูลรายการจองรอชำระ id: ${id}`;
      } else if (data.data.document_status.toLowerCase() !== "pending") {
        content = `เอกสารมีสถานะ ${data.data.document_status}`;
      }
      if (content) {
        history.replace({ pathname: `/${_.split(match.path, "/")[1]}` });
        Modal.error({
          title: "ไม่สามารถดำเนินการได้",
          content,
          zIndex: 1080,
        });
      }
    }
  }, [data, reserveId]);

  useEffect(() => {
    if (dataSource?.orders?.length && reserveId) {
      setActiveTab(dataSource.orders.length - 1);
    }
  }, [dataSource?.orders?.length, reserveId]);

  const handlePreview = () => {
    setLoadingPreview(true);
    setShowPreview(true);
    let source = axios.CancelToken.source();
    axios({
      url: `${URL_API}/pos/operation/reserve/${data.data.id}`,
      cancelToken: source.token,
    })
      .then((res) => {
        setPreviewData(res.data.data);
      })
      .catch((err) => {
        resCatch(err);
      })
      .finally(() => setLoadingPreview(false));
  };

  const handleClose = () => onCancel();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={handleClose}
      maskClosable={false}
      footer={false}
      zIndex={1080}
      width={1000}
      closeIcon={<FaTimes size={10} />}
      bodyStyle={{ paddingTop: "50px" }}
    >
      {loading ? (
        <Skeleton active />
      ) : error ? (
        <Alert type="error" message={error} showIcon />
      ) : !dataSource ? (
        <Empty />
      ) : (
        <>
          <Row
            type="flex"
            className="justify-content-between align-items-center"
            gutter={8}
          >
            <Col className="px-0">
              <small>
                <BillTab
                  list={dataSource.orders}
                  active={activeTab}
                  onClick={(e) => setActiveTab(e)}
                />
              </small>
            </Col>
            <Col className="d-flex">
              <small>
                <Typography.Text
                  strong
                  className="text-dark ml-3"
                >{`เลขที่ชุดจอง ${dataSource.document_no}`}</Typography.Text>
                <Typography.Text strong className="text-dark ml-3 mr-2">
                  ผู้ดำเนินการ
                </Typography.Text>
                <Typography.Text className="text-dark">
                  {dataSource.buyer_name}
                </Typography.Text>
                <Typography.Text strong className="text-dark ml-3 mr-2">
                  เบอร์โทร
                </Typography.Text>
                <Typography.Text className="text-dark">
                  {dataSource.buyer_mobile}
                </Typography.Text>
              </small>
            </Col>
          </Row>

          {activeTab + 1 === dataSource.orders.length ? (
            <BillSummary
              clickCancel={clickCancel}
              orders={dataSource.orders}
              id={id}
              status={dataSource.document_status}
              cashierName={dataSource.cashier_name}
              reload={reload}
              returnWaitTab={returnWaitTab}
              newRemark={newRemark}
              documentNo={dataSource.document_no}
              reserveType={dataSource.reserve_type}
              buyerCode={dataSource.buyer_code}
              buyerType={dataSource.buyer_type}
              hasStock={hasStock}
              handlePreview={handlePreview}
              toMainMenu={toMainMenu}
              reserveId={reserveId}
            />
          ) : (
            <Bill
              clickCancel={clickCancel}
              object={{ ...dataSource.orders[activeTab] }}
              onClickNext={() => setActiveTab(activeTab + 1)}
              status={dataSource.document_status}
              returnWaitTab={returnWaitTab}
              setNewRemark={setNewRemark}
              newRemark={newRemark}
              reserveType={dataSource.reserve_type}
              handlePreview={handlePreview}
            />
          )}
        </>
      )}
      <Preview
        visible={showPreview}
        data={previewData}
        onCancel={() => setShowPreview(false)}
        loading={loadingPreview}
      />
    </Modal>
  );
};

export default withRouter(ModalReserve);
