export const objMember = {
  main_partner_code: "",
  id: 0,
  partner_code: "",
  approve_id_card: false,
  approve_book: false,
  approve_document: false,

  register_date: "",
  expire_date: "",

  // sub_district_id: null,
  // district_id: null,
  // province_id: null,
  // address: '',
  // sub_district: '',
  // district: '',
  // province: '',
  // post_code: '',

  is_company: null,
  company_name_th: "",
  company_name_en: "",
  company_type: "",
  company_tax: "",
  company_register_vat: 0,

  prefix: "",
  gender: "",
  first_name_th: "",
  first_name_en: "",
  sur_name_th: "",
  sur_name_en: "",
  nationality: "TH",
  origin: "",
  id_card: "",
  member_type: "",
  phone: "",
  mobile: "",
  email: "",
  passport_id: "",
  passport_expire_date: "",
  birth_date: "",
  line_id: "",
  facebook: "",
  image_profile: "",
  //mobiles: [],
  phone_code: "",

  remark: "",
  manual_active: false,

  married: {
    id_married: 0,
    is_company_married: null,
    prefix_married: "",
    gender_married: "",
    first_name_th_married: "",
    first_name_en_married: "",
    sur_name_th_married: "",
    sur_name_en_married: "",
    nationality_married: "TH",
    origin_married: "",
    id_card_married: "",
    member_type_married: "",
    mobile_married: "",
    phone_married: "",
    email_married: "",
    birth_date_married: "",
    line_id_married: "",
    facebook_married: "",
    ref_partner_code: "",
    partner_code_married: "",
    register_date_married: "",
    expire_date_married: "",
    passport_id_married: "",
    passport_expire_date_married: "",
    phone_code_married: "",
  },
  sponser: {
    first_name: "",
    sur_name: "",
    sponsor_code: "",
    mobile: "",
  },
  upline: {
    first_name: "",
    sur_name: "",
    up_line_code: "",
    mobile: "",
    chart_type: "",
  },
  idAddress: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  taxAddress: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  idAddressMarried: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  idAddressMarriedUpdate: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  idAddressUpdate: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  taxAddressUpdate: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  currentAddress: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  currentAddressUpdate: {
    sub_district_id: null,
    district_id: null,
    province_id: null,
    address: "",
    sub_district: "",
    district: "",
    province: "",
    post_code: "",
  },
  personBank: {
    bank_code: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_branch: "",
    bank_eft_type: "",
  },
  personBankUpdate: {
    bank_code: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_branch: "",
    bank_eft_type: "",
  },
  CompanyBank: {
    bank_code: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_branch: "",
    bank_eft_type: "",
  },
  CompanyBankUpdate: {
    bank_code: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_branch: "",
    bank_eft_type: "",
  },
};
