/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Button,
  Col, Form,
  Input,
  Modal,
  notification, Row
} from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../../../components/TextEditor";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";

function HeadOffice({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");

  const [contentTextEditorAddress, setContentTextEditorAddress] = useState("");
  const [contentTextEditorDate, setContentTextEditorDate] = useState("");

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img"]),
          content: JSON.stringify({
            content_address: contentTextEditorAddress,
            content_date: contentTextEditorDate,
          }),
          description: JSON.stringify({
            phone: values.phone,
            facebook: values.facebook,
            instargram: values.instargram,
            line: values.line,
            youtube: values.youtube,
          }),
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path = "/manage-website/contact-legacy/head-office/update")
              : (url_path = "/manage-website/contact-legacy/head-office");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/contact-legacy/head-office",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);

        let loadContent = JSON.parse(data.content);
        let loadSocial = JSON.parse(data.detail);

        if (loadContent) {
          setContentTextEditorAddress(loadContent.content_address);
          setContentTextEditorDate(loadContent.content_date);
        }
        
        form.setFieldsValue({
          ...loadSocial,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>Head Office</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Row gutter={24}>
              <Col md={12}>
                <Row className="mb-3">
                  <h5>ที่อยู่:</h5>
                  <TextEditor
                    authToken={getToken()}
                    value={contentTextEditorAddress}
                    onInit={(evt, event) => {}}
                    onEditorChange={(e) => {
                      setContentTextEditorAddress(e);
                    }}
                    onUploadSuccess={(json) => {
                      console.log(json);
                    }}
                    postBody={{ container_name: "web-content" }}
                    urlUpload={`${URL_API}/file/upload`}
                  />
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mb-3 ">
                  <h5>วันเวลาทำการ:</h5>
                  <TextEditor
                    authToken={getToken()}
                    value={contentTextEditorDate}
                    onInit={(evt, event) => {}}
                    onEditorChange={(e) => {
                      setContentTextEditorDate(e);
                    }}
                    onUploadSuccess={(json) => {
                      console.log(json);
                    }}
                    postBody={{ container_name: "web-content" }}
                    urlUpload={`${URL_API}/file/upload`}
                  />
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Item label="เบอร์โทร :">
                  {getFieldDecorator("phone", {
                    initialValue: "",
                    rules: [{ required: true, message: "กรุณาระบุ เบอร์โทร" }],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Item label="LINK LINE :">
                  {getFieldDecorator("line", {
                    initialValue: "",
                    rules: [{ required: true, message: "กรุณาระบุ LINK LINE" }],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Item label="LINK Instargram:">
                  {getFieldDecorator("instargram", {
                    initialValue: "",
                    rules: [
                      { required: true, message: "กรุณาระบุ LINK Instargram" },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Item label="LINK FACEBOOK :">
                  {getFieldDecorator("facebook", {
                    initialValue: "",
                    rules: [
                      { required: true, message: "กรุณาระบุ LINK FACEBOOK" },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Item label="LINK YOUTUBE :">
                  {getFieldDecorator("youtube", {
                    initialValue: "",
                    rules: [
                      { required: true, message: "กรุณาระบุ LINK YOUTUBE" },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("contact-legacy-head-office")(HeadOffice);
