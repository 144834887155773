/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import React, { useContext, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useHttp } from "../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../utils";
import { resCatchModal } from "../../../util/helper";
import ModalContext from "../contexts/modal-context";

const { Option } = Select;

const ModalCancel = ({
  visible,
  onCancel,
  id = 0,
  reload,
  reserveId,
  toMainMenu,
  form,
}) => {
  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    resetFields,
  } = form;
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/global/master/all-type`,
      params: { process_type: "reserve_remark" },
      token: true,
    },
    []
  );

  useEffect(() => {
    if ((data?.data, visible))
      setFieldsValue({ remark: data.data[0].code, description: "" });
  }, [data?.data, visible]);

  const handleSubmit = () =>
    validateFieldsAndScroll((err, res) => {
      if (err) return;
      const source = axios.CancelToken.source();
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "คุณต้องการยกเลิกการจองหรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/pos/operation/reserve/cancel`,
              headers: { Authorization: "Bearer " + getToken() },
              data: {
                id,
                ...res,
              },
              cancelToken: source.token,
            })
              .then(() => {
                resolve();
                resetFields();
                notification.success({
                  message: "Success",
                  description: "บันทึกรายการเรียบร้อย",
                });
                if (reserveId && toMainMenu) return toMainMenu();
                modalDispatch({
                  type: "setCancelModal",
                  payload: { showCancel: false },
                });
                reload();
              })
              .catch((e) => reject(e));
          }).catch((e) => {
            resCatchModal(e);
          });
        },
        onCancel() {
          source.cancel("cancel");
        },
      });
    });

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={false}
      closeIcon={<FaTimes size={10} />}
      bodyStyle={{ paddingTop: "50px" }}
      destroyOnClose={true}
      zIndex={1080}
      width={600}
    >
      {loading ? (
        <Skeleton active />
      ) : error ? (
        <Alert type="error" message={error} showIcon />
      ) : (
        <>
          <Typography.Title level={3} className="text-center mb-4">
            ยกเลิกการจอง
          </Typography.Title>
          <Form name="cancel" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
            <Row gutter={[8, 8]} className="d-flex align-items-center">
              <Col span={24}>
                <Form.Item className="d-flex mb-0" label="หมายเหตุ">
                  {getFieldDecorator("remark", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาเลือกหมายเหตุ",
                      },
                    ],
                  })(
                    <Select
                      className="w-100"
                      dropdownStyle={{ zIndex: 1080 }}
                      dropdownMatchSelectWidth={false}
                    >
                      {data?.data.map((n) => (
                        <Option key={n.code} value={n.code}>
                          {n.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item className="d-flex mb-0" label="รายละเอียด">
                  {getFieldDecorator("description", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณากรอกรายละเอียด",
                      },
                    ],
                  })(
                    <Input.TextArea
                      autoSize={{ minRows: 5, maxRows: 9 }}
                      maxLength={500}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={8} className="my-3">
            <Col span={6} offset={9} className="text-center">
              <Button
                className="bg-pv border-pv text-white"
                block
                onClick={handleSubmit}
              >
                ตกลง
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default Form.create()(ModalCancel);
