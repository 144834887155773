/* eslint-disable no-unused-vars */
import { Button, Col, Row, Switch, List, message } from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import moment from "moment";
import ReactDragListView from "react-drag-listview";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../../redux/slices/marketing/product";
import { FaPlus } from "react-icons/fa";
import useAsync from "../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../services/manage-website.service";

export default function RankList({ history, match }) {
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;

  const [seq, setSeq] = useState([]);
  const [data, setData] = useState([]);
  const headData = [
    {
      no: "No.",
      title: "ชื่อตำแหน่ง",
      type: "Type",
      status: "Status",
      update: "Update Date",
    },
  ];

  const { execute: updateRankSeqById } = useAsync(
    manageWebsiteService.updateRankSeqById,
    {
      onSuccess: async () => {
        await loadContent();
      },
    }
  );

  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    setData(items);
    for (let i = 0; i < items.length; i++) {
      let x = i + 1;
      let myId = _.toNumber(items[i].id);
      updateRankSeqById({ myId, x });
    }
    setTimeout(() => {
      message.success({ content: "Saved!", duration: 0.8 });
    }, 180);
  };

  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/award/rank",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/award/rank/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "active" : "inactive",
      },
    })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/award/rank",
    })
      .then((res) => {
        let loadData = res.data?.data;
        console.log(loadData);
        setData(loadData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <Panel>
        <PanelHeader>ประกาศเกียรติคุณ RANK</PanelHeader>
        <PanelBody>
          <Row type="flex" justify="end" gutter={16} className="mb-4">
            <Col>
              <Button
                type="primary"
                onClick={() => history.push(`${match.path}/add`)}
              >
                <FaPlus className="mr-2" />
                เพิ่มข้อมูล
              </Button>
            </Col>
          </Row>
          <Row>
            <List
              size="small"
              bordered
              dataSource={headData}
              renderItem={(item) => {
                const draggble = item.title !== "XXX";
                return (
                  <List.Item className={draggble ? "draggble" : ""}>
                    <List.Item.Meta title={item.no} />
                    <List.Item.Meta title={item.title} />
                    <List.Item.Meta title={item.type} />
                    <List.Item.Meta title={item.status} />
                    <List.Item.Meta title={item.update} />
                  </List.Item>
                );
              }}
            ></List>
          </Row>
          <Row>
            <ReactDragListView
              nodeSelector=".ant-list-item.draggble"
              onDragEnd={onDragEnd}
            >
              <List
                style={{ cursor: "grab" }}
                size="small"
                bordered
                dataSource={data}
                pagination={{
                  size: "default",
                  showLessItems: true,
                  showTotal: (total, range) =>
                    `${range[0]} - ${range[1]} of ${total}`,
                  total: list?.fetch?.total || 0,
                  current: search.page,
                  pageSize: search.limit,
                  onChange: (page) => dispatch(SET_SEARCH({ page: page })),
                }}
                renderItem={(item) => {
                  const draggble = item.title !== "XXX";
                  return (
                    <List.Item className={draggble ? "draggble" : ""}>
                      <List.Item.Meta title={item.row_id} />
                      <List.Item.Meta title={item.title} />
                      <List.Item.Meta title={item.content_type} />
                      <List.Item.Meta
                        title={
                          <Switch
                            defaultChecked={item.status === "active"}
                            onClick={(checked) =>
                              handleChangeStatus(checked, item.id)
                            }
                          />
                        }
                      />
                      <List.Item.Meta
                        title={moment(item.update_date).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      />
                    </List.Item>
                  );
                }}
              />
            </ReactDragListView>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
