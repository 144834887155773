/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, DatePicker, Switch } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import digitalProductServices from '../../../../../services/digital-product.services';
import ImageUpload from '../../../fit-studio/components/upload/ImageUpload';

const DigitalProductProjectForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  digitalProductServices.useQueryGetDigitalProductProjectById(
    { digitalProductProjectId: watch('id') },
    (data) => {
      reset({
        id: watch('id'),
        formType: watch('formType'),
        ...data,
        startSubmitingDate: moment(data.startSubmitingDate),
        endSubmitingDate: moment(data.endSubmitingDate),
        startVotingDate: moment(data.startVotingDate),
        endVotingDate: moment(data.endVotingDate),
      });
    }
  );

  return (
    <div>
      <Controller
        name="thumbnailImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={700}
                  height={400}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่องาน <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={2}
                  value={value}
                  onChange={onChange}
                  placeholder="ชื่องาน"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>คำบรรยาย</Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={4}
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยาย"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="subdescription"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>คำบรรยายเพิ่มเติม</Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={4}
                  value={value}
                  onChange={onChange}
                  placeholder="คำบรรยายเพิ่มเติม"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="subdescription"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="width"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ความกว้าง <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="1500px" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="width"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="height"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ความสูง <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="1500px" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="height"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="policy"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>เงื่อนไขการส่งผลงาน</Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={4}
                  value={value}
                  onChange={onChange}
                  placeholder="เงื่อนไขการส่งผลงาน"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="policy"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="policyImageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={960}
                  height={1200}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="extension"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  นามสกุลไฟล์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ex JPG,PNG"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="extension"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="startSubmitingDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันเริ่มส่งผลงาน<span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="startSubmitingDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="endSubmitingDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันสิ้นสุดการส่งผลงาน<span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="endSubmitingDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="startVotingDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันเริ่มโหวต<span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="startSubmitingDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="endVotingDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  วันสิ้นสุดการโหวต<span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="endVotingDate"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="display"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>การแสดง</Typography.Text>
              </Col>
              <Col span={20}>
                <Switch checked={value} onChange={onChange} />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="requestOrderNote"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>ข้อมูลประสงค์ผลิตเป็นสินค้า</Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  rows={4}
                  value={value}
                  onChange={onChange}
                  placeholder="ex. ราคา 390 บาท Redeem 7,450 (2 PV) ต่อ 1 ชิ้น
                  หมายเหตุ : ได้รับสินค้า 20-25 วัน หลังจากชำระสินค้าครบตามจำนวน"
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="requestOrderNote"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default DigitalProductProjectForm;
