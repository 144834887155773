/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon, Popconfirm } from "antd";
import numeral from "numeral";
import React, { useState } from "react";
import { connect } from "react-redux";
import DiscountPopUp from "../components/DiscountPopUp";
import { Creators } from "../ducks/operation-form";
import _ from "lodash";
import { getSummaryTotalPayment } from "../payment/logic";

const BillDiscount = (props) => {
  const [showDiscount, setShowDiscount] = useState(false);
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td colSpan="2" className="px-4">
              <button
                type="button"
                className="pos-btn bg-reds text-white text-nowrap border-0 d-flex align-items-center"
                style={{ width: "120px" }}
                onClick={() => setShowDiscount(true)}
              >
                <Icon type="plus-circle" className="mr-3" /> ส่วนลด
              </button>
            </td>
          </tr>
          {props.discounts.map((n, i) => (
            <tr className="" key={`discount-${i}`}>
              <td className="w-100 pr-2 pl-4">
                {n.discount_name} (
                {n.collect_point === 0 ? "ไม่ได้รับคะแนน" : "ได้รับคะแนนปกติ"})
              </td>
              <td className="text-nowrap px-2 text-right">
                {numeral(n.discount_amount).format("0,0.00")}
              </td>
              <td className="text-nowrap pl-2 pr-4 text-center text-dark">
                {!n.auto_add && (
                  <Popconfirm
                    placement="top"
                    title={`ต้องการลบรายการนี้หรือไม่`}
                    onConfirm={() => {
                      props.delDiscount(n.discount_type, props.activeKey);
                      props.clearPaymentIgnoreActiveKey();
                    }}
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                  >
                    <Icon
                      type="delete"
                      style={{ fontSize: "20px" }}
                      className="cursor-pointer"
                    />
                  </Popconfirm>
                )}
              </td>
            </tr>
          ))}
          {_.map(props.coupons, (n, i) => {
            return (
              <React.Fragment key={i}>
                <tr className={`text-${n.used ? "" : "reds"}`}>
                  <td className="w-100 pr-2 pl-4">
                    {n.code} {n.name}
                    {n.collect_point === 0 && "(ไม่ได้รับคะแนน)"}
                  </td>
                  <td className="text-nowrap px-2 text-right">
                    {n.used
                      ? n.type === "2"
                        ? `${n.amount_used} %`
                        : numeral(n.amount_used).format("0,0.00")
                      : "ไม่ได้ใช้งาน"}
                  </td>
                  <td className="text-nowrap pl-2 pr-4 text-center text-dark">
                    <Popconfirm
                      placement="top"
                      title={`ต้องการลบรายการนี้หรือไม่`}
                      onConfirm={() => {
                        props.delVoucher(n.id, props.activeKey);
                        props.clearPaymentIgnoreActiveKey();
                      }}
                      okText="ลบ"
                      cancelText="ไม่ลบ"
                    >
                      <a>
                        <Icon type="delete" style={{ fontSize: "20px" }} />
                      </a>
                    </Popconfirm>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <DiscountPopUp
        visible={showDiscount}
        handleClose={() => setShowDiscount(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  let { orders, payment } = state.pos.operation;
  let activeKey = payment.activeKey || orders.itemKeys[0];

  const vouchers =
    _.map(
      orders.itemByKey[activeKey]?.voucher.itemKeys,
      (voucher_key) =>
        orders.itemByKey[activeKey].voucher.itemByKey[voucher_key]
    ) || [];

  const voucher_list_order =
    _.find(
      getSummaryTotalPayment(orders)?.summary_list,
      (order) => order.key === activeKey
    )?.voucher_list_order || [];
  vouchers.forEach((item) => {
    item.used = voucher_list_order.some((e) => e.id === item.id) ? 1 : 0;
    item.amount_used = voucher_list_order.some((e) => e.id === item.id)
      ? voucher_list_order.find((e) => e.id === item.id).amount
      : 0;
  });
  return {
    discounts:
      orders.itemByKey[activeKey]?.discount.itemKeys.map(
        (discount_key) =>
          orders.itemByKey[activeKey].discount.itemByKey[discount_key]
      ) || [],
    coupons: _.filter(
      vouchers,
      (n) => n.voucher_type?.toLowerCase() === "coupon"
    ),
    activeKey,
  };
};

export default connect(mapStateToProps, {
  delDiscount: Creators.delDiscount,
  delVoucher: Creators.delVoucher,
  clearPaymentIgnoreActiveKey: Creators.clearPaymentIgnoreActiveKey,
})(BillDiscount);
