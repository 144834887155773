/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { useCallback, useContext, useState } from "react";
import ImagePreviewModal from "../../../../../../components/ImagePreviewModal";
import UploadFileList from "../../../../../../components/UploadFileList";
import BlockItem from "../../../../../../components/UploadFileList/BlockItem";
import { PromotionContext } from "../reducers";

const initialPreview = {
  azure_url: null,
  file_type: null,
};

const ImgUpload = () => {
  const {
    state: { media },
    dispatch,
  } = useContext(PromotionContext);
  const [previewData, setPreviewData] = useState(initialPreview);

  const handleChangeUpload = useCallback(
    (payload) => dispatch({ type: "ADD_MEDIA", payload }),
    []
  );
  const handleDeleteUpload = useCallback(
    (payload) =>
      dispatch({
        type: "DEL_MEDIA",
        payload,
      }),
    []
  );
  const handleChangeDefault = useCallback(
    (payload) => dispatch({ type: "SET_MEDIA_DEFAULT", payload }),
    []
  );
  const handlePreview = useCallback((e) => setPreviewData(e), []);
  const handleCancel = useCallback(() => setPreviewData(initialPreview), []);

  return (
    <>
      {media.itemDefault && (
        <Row gutter={[8, 8]} className="mb-3">
          <Col span={4} md={3} xxl={2}>
            <Typography.Text>รูปตั้งต้น</Typography.Text>
          </Col>
          <Col span={20} md={21} xxl={22}>
            <BlockItem
              itemKey={media.itemDefault.key}
              dataSource={media.itemDefault}
              onPreview={handlePreview}
              onDelete={handleDeleteUpload}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[8, 8]} className="mb-3">
        <Col span={4} md={3} xxl={2}>
          <Typography.Text className="text-nowrap">รูปทั้งหมด</Typography.Text>
        </Col>
        <Col span={20} md={21} xxl={22}>
          <UploadFileList
            withCamera
            withDefault
            files={media}
            onChange={handleChangeUpload}
            onDelete={handleDeleteUpload}
            onChangeDefault={handleChangeDefault}
          />
        </Col>
      </Row>
      <ImagePreviewModal
        azureUrl={previewData.azure_url}
        fileType={previewData.file_type}
        onCancel={handleCancel}
      />
    </>
  );
};

export default ImgUpload;
