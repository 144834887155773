import { _ } from "../../utils";

export const getMediaDocumentType = (media) =>
  _.reduce(
    media,
    (res, n) => {
      const { document_type, ...data } = n;
      const key = _.uniqueId("document-img-");
      if (document_type === "24")
        return {
          ...res,
          transferKeys: [...res.transferKeys, key],
          transferByKey: { ...res.transferByKey, [key]: { ...data } },
        };
      return {
        ...res,
        receiveKeys: [...res.receiveKeys, key],
        receiveByKey: { ...res.receiveByKey, [key]: { ...data } },
      };
    },
    {
      transferKeys: [],
      transferByKey: {},
      receiveKeys: [],
      receiveByKey: {},
    }
  );

export const getProductProperty = (e) =>
  e === "transfer" ? "product" : "productAdd";

export const orderProduct = (itemKeys, itemByKey) =>
  _.map(
    _.orderBy(
      _.map(itemKeys, (key) => itemByKey[key]),
      ["product_code", "line_number"],
      ["asc", "asc"]
    ),
    (n) => n.key
  );
