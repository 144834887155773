/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";
import SelectOption from "../../pos/components/SelectOption";
import _ from "lodash";

export default function SelectCategory({ category = "", withAll, ...props }) {
  const [filterData, setFilterData] = useState("");

  const [loading, data, error, reload] = useHttp({
    url: `${URL_API}/boss/report-component/${category}`,
    token: true,
  },[category]);

  useEffect(() => {
    setFilterData(data ? data.data : []);
  }, [data]);

  const filterOptions = [
    _.map(filterData, (data) => {
      return { value: data.value, text: data.text };
    }),
  ];

  return (
    <SelectOption options={filterOptions[0]} withAll={withAll} {...props} />
  );
}
