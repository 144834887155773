import React, { useEffect } from "react";
import { Modal, Button, Table, message } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { axios } from "../../../../../utils/useAxios";
import { useStateValue } from "../StateContext";

const ModalDetail = ({ visible, onCancel, id }) => {
  const { state, dispatch } = useStateValue();

  const fetchDetailData = async () => {
    if (!id) return;

    try {
      dispatch({ type: "SET_LOADING", payload: true });

      const response = await axios.get(`/legacy-verse/import/file/history/${id}`);

      dispatch({
        type: "SET_FILE_INFO",
        payload: {
          file_name: response?.data?.file_name || "",
          sheet_name: response?.data?.sheet_name || "",
          total_record: response?.data?.total_record || 0,
          status_success: response?.data?.status_success || 0,
          status_fail: response?.data?.status_fail || 0,
          event_name: response?.data?.event_name || "",
          description: response?.data?.description || "",
        },
      });

      dispatch({
        type: "SET_DETAIL_DATA",
        payload: response?.data?.data || [],
      });

      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      message.error("ไม่สามารถโหลดรายละเอียดได้");
    }
  };

  useEffect(() => {
    if (visible) {
      fetchDetailData();
    }
  }, [visible, id]);

  return (
    <Modal
      title="รายละเอียดไฟล์"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="close" onClick={onCancel}>
          ปิด
        </Button>,
      ]}
      width={700}
    >
      <div className="mb-4">
        <p>
          <strong>ไฟล์:</strong> {state.data.file_info?.file_name}
        </p>
        <p>
          <strong>ชีท:</strong> {state.data.file_info?.sheet_name}
        </p>
        <p>
          <strong>ชื่อกิจกรรม:</strong> {state.data.file_info?.event_name}
        </p>
        <p>
          <strong>รายละเอียด:</strong> {state.data.file_info?.description}
        </p>
      </div>

      <Table
        dataSource={state.data.detail_data || []}
        bordered
        pagination={{ pageSize: 5 }}
        rowKey="id"
      >
        <Table.Column title="ลำดับ" dataIndex="id" align="center" />
        <Table.Column title="ประเภท" dataIndex="reward_type" />
        <Table.Column title="รหัสนักธุรกิจ" dataIndex="partner_code" />
        <Table.Column title="จำนวน" dataIndex="qty" align="center" />
        <Table.Column
          title="สถานะ"
          dataIndex="status"
          align="center"
          render={(status) =>
            status === "success" ? (
              <CheckCircleOutlined style={{ color: "green" }} />
            ) : (
              <CloseCircleOutlined style={{ color: "red" }} />
            )
          }
        />
      </Table>

      <div className="mt-4">
        <p className="text-success">
          นำเข้าสำเร็จ: {state.data.file_info?.status_success} รายการ
        </p>
        <p className="text-danger">
          นำเข้าไม่สำเร็จ: {state.data.file_info?.status_fail} รายการ
        </p>
      </div>
    </Modal>
  );
};

export default ModalDetail;
