/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Alert, Cascader } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";

const SelectCategory = ({ typeParams = "", withAll = true, ...props }) => {
  const [options, setOptions] = useState([]);
  const [_loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/sub_category`,
      params: { type_params: typeParams },
    },
    [typeParams]
  );

  useEffect(() => {
    if (data) {
      const values = data?.data || [];
      setOptions(withAll ? values : _.filter(values, (n) => n.value != 0));
    }
  }, [data]);

  if (error) return <Alert type="error" message={error} showIcon />;

  return <Cascader options={options} changeOnSelect {...props} />;
};

export default SelectCategory;
