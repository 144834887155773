/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, notification, Skeleton, Typography } from "antd";
import React, { useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import TableAddressForm from "../../components/TableAddressForm";
import AddressForm from "./AddressForm";
import AddressHistory from "./AddressHistory";
import CurrenAddressForm from "./CurrenAddressForm";
import TexAddressForm from "./TexAddressForm";

const MemberAddress = ({
  handleChange,
  Member,
  setMember,
  errors,
  id,
  loadingAddress,
}) => {
  const list = [
    { title: "ที่อยู่ตามบัตรประชาชน", value: "AddressByIDcard" },
    { title: "ที่อยู่สำหรับออกใบกำกับภาษี", value: "texAddress" },
    { title: "ที่อยู่ปัจจุบัน", value: "CurrentAddress" },
    { title: "ที่อยู่จัดส่ง", value: "DeliveryAddress" },
  ];
  const [activeTab, setActiveTab] = useState("AddressByIDcard");
  const [show, setShow] = useState(false);
  const [loadingAllAddress, dataAllAddress, errorAllAddress] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/vip-member/${id}/all-address`,
    token: true,
  });

  if (errorAllAddress) {
    notification.error({
      description: errorAllAddress,
      placement: "bottomRight",
    });
  }

  return (
    <>
      <ul className="custom-tabs">
        <div className="d-flex">
          {list.map((n, i) => (
            <li
              key={i}
              className={`${activeTab === n.value ? "active" : ""} mr-4`}
              onClick={() => setActiveTab(n.value)}
            >
              <Typography.Title level={4}>
                <a style={{ fontSize: "13px" }}>{n.title}</a>
              </Typography.Title>
            </li>
          ))}
        </div>
        <div className="d-flex">
          <Button
            size="small"
            className="m-1 d-flex align-items-center"
            icon="history"
            shape="round"
            onClick={() => setShow(true)}
          >
            History
          </Button>
        </div>
      </ul>

      {/* <Tabs active={activeTab} onClick={e => setActiveTab(e)} list={list} /> */}
      <div className="container shadow-sm p-15 mb-4 bg-white rounded">
        {activeTab === "AddressByIDcard" ? (
          <>
            {loadingAddress ? (
              <Skeleton active />
            ) : (
              <AddressForm
                Member={Member}
                errors={errors}
                setMember={setMember}
                handleChange={handleChange}
              />
            )}
          </>
        ) : activeTab === "texAddress" ? (
          <TexAddressForm
            Member={Member}
            errors={errors}
            setMember={setMember}
            handleChange={handleChange}
          />
        ) : activeTab === "CurrentAddress" ? (
          // <TableAddressForm
          //     loading={loadingAllAddress}
          //     data={dataAllAddress?.data.filter((i) => i.address_type === 2)}
          // />
          <CurrenAddressForm
            Member={Member}
            errors={errors}
            setMember={setMember}
            handleChange={handleChange}
          />
        ) : (
          <TableAddressForm
            loading={loadingAllAddress}
            data={dataAllAddress?.data.filter((i) => i.address_type === 3)}
          />
        )}
      </div>
      {Member?.partner_code && (
        <AddressHistory
          show={show}
          onCancel={() => setShow(false)}
          code={Member.partner_code}
        />
      )}
    </>
  );
};
export default MemberAddress;
