import { notification } from "antd";
import React, { useRef } from "react";
import { axios } from "../../../../../utils/useAxios";
import { resCatch } from "../../../../util/helper";
import PreviewModal from "../../components/PreviewModal";
import { useCommissionMutate } from "../contexts/commission.context";
import Criteria from "./criteria";
import LogTable from "./log.table";
import Summary from "./summary";

const MainPage = () => {
  const {
    state: { period, preview },
    dispatch,
  } = useCommissionMutate();

  const summaryRef = useRef(null);

  const transfer = () => {
    dispatch({ type: "SET_LOADING", payload: true });
    axios
      .post(`/interface/commission`, null, {
        params: { period },
      })
      .then((res) => {
        if (summaryRef.current) summaryRef.current.reload();
        notification.success({
          message: `สำเร็จ`,
          description: "โอนข้อมูลเรียบร้อย",
        });
      })
      .catch((err) => {
        if (summaryRef.current) summaryRef.current.reload();
        resCatch(err);
      });

    if (summaryRef.current) summaryRef.current.reload();
  };

  return (
    <>
      <Criteria onTransfer={transfer} />
      <Summary ref={summaryRef} />
      <LogTable />
      <PreviewModal
        textMenu="คอมมิชชั่น"
        preview={preview}
        setPreview={() => dispatch({ type: "HIDE_PREVIEW" })}
      />
    </>
  );
};

export default MainPage;
