/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Typography } from 'antd'

const Tabs = ({ active, onClick }) => {
    const list = [
        { title: 'รอการจัดส่ง', value: 'wait' },
        { title: 'ยืนยันการจัดส่ง', value: 'confirm' },
        { title: 'จัดส่งเรียบร้อย', value: 'complete' },
    ]

    return (
        <ul className="full-tabs">
            {
                list.map((n, i) => (
                    <li key={i} className={`${active === n.value ? 'active' : ''}`} onClick={() => onClick(n.value)}>
                        <Typography.Title level={4}><a>{n.title}</a></Typography.Title>
                    </li>
                ))
            }
        </ul>
    )
}

export default Tabs
