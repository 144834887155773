/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import fitLegacyServices from '../../../../services/fit-legacy.services';
import { useHistory } from 'react-router';
import {
  legacyRewardEventFormDefaultValues,
  legacyRewardEventFormSchema,
} from '../components/dto/legacy-reward-event.dto';
import LegacyRewardEventModal from '../components/modal/LegacyRewardEventModal';
import { AiOutlineQrcode } from 'react-icons/ai';
import EventQrCodeModal from '../components/modal/EventQrCodeModal';

dayjs.locale('th');

const pageSize = 10;

const LegacyRewardEventPage = () => {
  const [legacyRewardEventFormOpen, setLegacyRewardEventFormOpen] =
    useState(false);
  const [event, setEvent] = useState(undefined);
  const [eventQrcodeOpen, setEventQrcodeOpen] = useState(false);
  const {
    data: legacyRewardEventLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();

  const _HandleEventQrcodeOpen = () => {
    setEventQrcodeOpen(true);
  };

  const _HandleEventQrcodeClose = () => {
    setEventQrcodeOpen(false);
  };

  const legacyRewardEventForm = useForm({
    defaultValues: legacyRewardEventFormDefaultValues,
    resolver: legacyRewardEventFormSchema(),
  });

  const _HandleLegacyRewardEventFormOpen = (formType) => {
    legacyRewardEventForm.setValue('formType', formType);
    setLegacyRewardEventFormOpen(true);
  };

  const _HandleLegacyRewardEventFormClose = () => {
    legacyRewardEventForm.reset(legacyRewardEventFormDefaultValues);
    setLegacyRewardEventFormOpen(false);
  };

  fitLegacyServices.useQueryGetLegacyRewardEventLists(
    { take: pageSize, page },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyRewardEvent } =
    fitLegacyServices.useMutationDeleteLegacyRewardEvent(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-reward-event'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <div
          style={{
            width: 75 / 2,
            height: 100 / 2,
          }}
        >
          {record.imageUrl && (
            <img
              alt={record.title}
              src={record.imageUrl}
              style={{
                width: 75 / 2,
                height: 100 / 2,
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'ชื่องาน',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'คำบรรยาย',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => <span>{record.description}</span>,
      width: 400,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              setEvent(record);
              _HandleEventQrcodeOpen();
            }}
          >
            <AiOutlineQrcode />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyRewardEventForm.setValue('id', record.id);
              _HandleLegacyRewardEventFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyRewardEvent({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Legacy Reward Event</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <div style={{ flex: 1 }} />
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleLegacyRewardEventFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่ม Legacy Reward Event
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={legacyRewardEventLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(
                  `/notification-setting/legacy-reward-event/${record.id}`
                );
              },
            };
          }}
        />
      </PanelBody>
      <FormProvider {...legacyRewardEventForm}>
        <LegacyRewardEventModal
          open={legacyRewardEventFormOpen}
          handleClose={_HandleLegacyRewardEventFormClose}
        />
      </FormProvider>
      <EventQrCodeModal
        open={eventQrcodeOpen}
        handleClose={_HandleEventQrcodeClose}
        event={event}
      />
    </Panel>
  );
};

export default LegacyRewardEventPage;
