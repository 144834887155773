import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const legacyPrizesType = [
  {
    label: 'ของรางวัล',
    value: 'gift',
  },
];

export const legacyPrizesFormDefaultValues = {
  title: '',
  imageUrl: '',
  type: '',
  active: false,
  startDate: moment(),
  endDate: moment(),
};

export const legacyPrizesFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      title: yup.string().required('กรุณากรอกข้อมูล'),
      imageUrl: yup.string().required('กรุณากรอกข้อมูล'),
      type: yup
        .mixed()
        .oneOf(Object.values(legacyPrizesType.map((item) => item.value)))
        .required('กรุณากรอกข้อมูล'),
      active: yup.boolean().defined('กรุณากรอกข้อมูล'),
      startDate: yup.date().required('กรุณากรอกข้อมูล'),
      endDate: yup.date().required('กรุณากรอกข้อมูล'),
    })
  );
};

export const legacyPrizesItemType = [
  {
    label: 'แสดงทุกคน',
    value: 'all',
  },
  {
    label: 'แสดงเฉพาะบุคคล',
    value: 'group',
  },
  {
    label: 'แสดงเฉพาะนักธุรกิจ',
    value: 'Distributor',
  },
  {
    label: 'แสดงเฉพาะ VIP MEMBER',
    value: 'Member',
  },
];

export const legacyPrizesItemCallBackUrlType = [
  {
    label: 'ไม่มี Action',
    value: 'no_action',
  },
];

export const legacyPrizesItemFormDefaultValues = {
  title: '',
  imageUrl: '',
  type: '',
  active: true,
  callBackUrlType: '',
  callBackUrl: '',
  startDate: moment(),
  endDate: moment(),
};

export const legacyPrizesItemFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      title: yup.string().required('กรุณากรอกข้อมูล'),
      imageUrl: yup.string().required('กรุณากรอกข้อมูล'),
      type: yup
        .mixed()
        .oneOf(Object.values(legacyPrizesItemType.map((item) => item.value)))
        .required('กรุณากรอกข้อมูล'),
      active: yup.boolean().defined(),
      callBackUrlType: yup.string().defined(),
      callBackUrl: yup.string().defined(),
      startDate: yup.date().required('กรุณากรอกข้อมูล'),
      endDate: yup.date().required('กรุณากรอกข้อมูล'),
    })
  );
};
