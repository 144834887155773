import { Select } from "antd";
import React, { forwardRef } from "react";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";

const SelectAddressMaster = (
  { type, province_id = 0, district_id = 0, ...props },
  ref
) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/address`,
      params: {
        type,
        province_id,
        district_id,
      },
    },
    [province_id, district_id]
  );

  if (error) return <div>{error}</div>;

  return (
    <Select
      loading={loading}
      disabled={loading}
      dropdownMatchSelectWidth={false}
      showSearch
      filterOption={(input, option) =>
        option.props.children.indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {data && data.data
        ? data.data.map((n, i) => (
            <Select.Option key={i} value={n.id} source={n}>
              {n.name}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

export default forwardRef(SelectAddressMaster);
