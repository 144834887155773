/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ControlPanel from '../components/ControlPanel';
import { Row, Col, Table, Alert } from 'antd';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import _ from 'lodash';
import { useHttp } from '../../../../hooks/http';
import { URL_API } from '../../../../utils';
export default () => {
    const months = [
        { key: 1, short_name: 'ม.ค', long_name: 'มกราคม' },
        { key: 2, short_name: 'ก.พ.', long_name: 'กุมภาพันธ์' },
        { key: 3, short_name: 'มี.ค.', long_name: 'มีนาคม' },
        { key: 4, short_name: 'เม.ย.', long_name: 'เมษายน' },
        { key: 5, short_name: 'พ.ค.', long_name: 'พฤษภาคม' },
        { key: 6, short_name: 'มิ.ย.', long_name: 'มิถุนายน' },
        { key: 7, short_name: 'ก.ค.', long_name: 'กรกฎาคม' },
        { key: 8, short_name: 'ส.ค.', long_name: 'สิงหาคม' },
        { key: 9, short_name: 'ก.ย.', long_name: 'กันยายน' },
        { key: 10, short_name: 'ต.ค.', long_name: 'ตุลาคม' },
        { key: 11, short_name: 'พ.ย.', long_name: 'พฤศจิกายน' },
        { key: 12, short_name: 'ธ.ค.', long_name: 'ธันวาคม' }
    ];
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [selectKey, setSelectKey] = useState("");
    const [lineChart, setLineChart] = useState({
        title: '',
        series: []
    });
    const period_year = 2019;
    const [pieChart, setPieChart] = useState({
        title: '',
        labels: [],
        series: []
    });
    const [loading, data, error] = useHttp({
        method: 'post',
        url: `${URL_API}/boss/reports/team/sale`,
        data: { period_year, round_down: 3 }
    }, [period_year]);


    const breadcrumb = [
        { link: '/', title: 'หน้าหลัก' },
        { link: null, title: 'รายงานยอดขายรายทีม' }
    ];

    const teamList = [
        'Clover'
        , 'Clover Think'
        , 'Dreams'
        , 'Ever'
        , 'Givers'
        , 'Life'
        , 'Mind'
        , 'Real'
        , 'Together'
        , 'Victors'
        , 'Zeeds'
    ];

    const pieChartOpt = {
        options: {
            chart: {
                height: 200,
                type: 'pie'
            },
            dataLabels: {
                // formatter: (val, chartData) => {
                //     let teamName = chartData.w.config.labels[chartData.seriesIndex];
                //     let salesValue = chartData.w.config.series[chartData.seriesIndex];
                //     return `${teamName}: ฿ ${numeral(salesValue).format('0,0')} (${numeral(val / 100).format('0 %')})`;
                // },
                dropShadow: {
                    enabled: true,
                    top: 1,
                    left: 1,
                    blur: 1,
                    opacity: 0.45
                }
            },
            colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#00D9E9', '#FF66C3', '#fb5597', '#f59c1a', '#348fe2', '#00acac', '#8753de'],
            labels: pieChart.labels, //teamList.map((n, i) => `${n}(฿${numeral(series[i]).format('0,0')})`),
            title: {
                text: pieChart.title,
                align: 'center'
            }
        },
    };

    const lineChartOpt = {
        options: {
            chart: {
                type: 'line',
                height: 350,
                shadow: {
                    enabled: true,
                    color: '#2d353c',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 1
                },
                toolbar: {
                    show: false
                }
            },
            title: {
                text: lineChart.title,
                align: 'center'
            },
            dataLabels: {
                enabled: true,
                formatter: val => numeral(val).format('0,0')
            },
            stroke: {
                curve: 'straight',
                width: 3
            },
            markers: {
                size: 4
            },
            xaxis: {
                categories: months.map(n => n.short_name),
                axisBorder: {
                    show: true,
                    color: 'rgba(182, 194, 201, 0.5)',
                    height: 1,
                    width: '100%',
                    offsetX: 0,
                    offsetY: -1
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#b6c2c9',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                }
            },
            yaxis: {
                min: 0,
                //     max: 40
            },
            legend: {
                show: true,
                position: 'top',
                offsetY: -10,
                horizontalAlign: 'right',
                floating: true,
            }
        }
    };

    const handleChangeMonth = month => {
        setPieChart({
            title: month.long_name,
            labels: dataSource.filter(n => n.team_name.toLowerCase() !== 'total').map(n => (`${n.team_name}(฿${numeral(n[month.key]).format('0,0')})`)),
            series: dataSource.filter(n => n.team_name.toLowerCase() !== 'total').map(n => n[month.key]),
        })
    }

    const handleClickRow = record => {
        setSelectKey(record.key);

        let sortable = [];
        for (var key in _.omit(record, ['key', 'team_id', 'team_name', 'grand_total'])) {
            if (record.hasOwnProperty(key)) {
                sortable.push([key, record[key]]);
            }
        }

        setLineChart({
            title: record.team_name,
            series: [{ name: record.team_name, data: sortable.map(n => n[1]) }]
        });
    }

    const grandTotal = record => {
        let sum = 0;
        for (let key in _.omit(record, ['key', 'team_id', 'team_name'])) {
            if (record.hasOwnProperty(key)) {
                sum += parseFloat(record[key]);
            }
        }
        return sum;
    }

    useEffect(() => {
        let newData = [];

        if (data) {
            newData = data.data.map(n => ({ ...n, key: n.team_id }));

            let total = {};
            months.forEach(m => total[m.key] = _.reduce(newData, (sum, n) => sum + n[m.key], 0));

            newData.push({
                key: _.uniqueId('team-'),
                team_name: 'Total',
                ...total
            })

            handleClickRow(newData[0]);
        }

        setDataSource(newData);
    }, [data]);

    useEffect(() => {
        let newColumns = [];
        newColumns.push({
            title: 'Team',
            dataIndex: 'team_name',
            key: 'team_name',
            className: 'text-center text-nowrap',
            render: text => <p className="m-0 text-left">{text}</p>
        });

        months.forEach(n => newColumns.push({
            title: n.short_name,
            dataIndex: n.key,
            key: n.key,
            className: 'text-center text-nowrap',
            render: text => <p className="m-0 text-right">{text === 0 ? '-' : numeral(text).format('0,0')}</p>,
            onHeaderCell: () => {
                return {
                    onClick: () => {
                        handleChangeMonth(n);
                    }
                }
            }
        }));

        newColumns.push({
            title: 'Grand Total',
            key: 'grand_total',
            className: 'text-center text-nowrap',
            render: (text, record) => <p className="m-0 text-right">{numeral(grandTotal(record)).format('0,0')}</p>
        });

        setColumns(newColumns);

        handleChangeMonth(months[0]);
    }, [dataSource]);

    if (error) {
        return <Alert
            message="Error:"
            description={error}
            type="error"
        />
    }

    return (
        <Layout
            breadcrumb={breadcrumb}
            titlePage="รายงานยอดขายรายทีม"
            subTitlePage=""
        >
            <ControlPanel titlePanel="รายงานยอดขายรายทีม">
                <Row>
                    <Col md={12}><Chart type="line" options={lineChartOpt.options} series={lineChart.series} /></Col>
                    <Col md={12}><Chart type="pie" options={pieChartOpt.options} series={pieChart.series} /></Col>
                </Row>
                <Table
                    bordered
                    size="small"
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    onRow={(record) => ({ onClick: () => { handleClickRow(record) } })}
                    rowClassName={record => record.key === selectKey ? 'table-warning' : ''}
                    pagination={false}
                    scroll={{ x: true }}
                />
            </ControlPanel>
        </Layout>
    )
}
