/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../utils";
import { AlertConfirm, AlertError, AlertSuccess } from "../../../util/Alert";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import LayoutInfo from "../components/LayoutInfo";
import { objMember } from "../distributor-state";
import Members from "./components/Member";
import MemberAddress from "./components/MemberAddress";
import MemberBookBank from "./components/MemberBookBank";
import MemberFile from "./components/MemberFile";
import MemberRemark from "./components/MemberRemark";
import DistributorContext, * as distributor from "./contexts/distributor-context";

const Index = (props) => {
  const [state, dispatch] = useReducer(
    distributor.reducer,
    distributor.initialState
  );
  const { ActiveTabs } = state;
  const id = Number(props.match.params.id) || 0;
  const [Member, setMember] = useState(objMember);
  const [errors, setErrors] = useState({});

  const [loadingMember, dataMember, errorMember] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/ethics/${id}`,
    token: true,
  });

  const [loadingAddress, dataAddress, errorAddress] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/ethics/${id}/address`,
    token: true,
  });

  const [loadingBank, dataBank, errorBank] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/ethics/${id}/bank`,
    token: true,
  });

  const [loadingImage, dataImage, errorImage, reloadImge] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/ethics/${id}/image`,
    token: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["first_name_en", "sur_name_en", "passport_id"].includes(name)) {
      setMember({
        ...Member,
        [name]: value.toUpperCase(),
      });
    } else {
      setMember({ ...Member, [name]: value });
    }
  };
  const handleChangeAddress = (e, group) => {
    const { name, value } = e.target;
    setMember({
      ...Member,
      [group]: {
        ...Member[group],
        [name]: value,
      },
      [`${group}Update`]: {
        ...Member[group],
        [name]: value,
      },
    });
  };
  const handleChangeBank = (e, group) => {
    const { name, value } = e.target;
    setMember({
      ...Member,
      [group]: {
        ...Member[group],
        [name]: value,
      },
      [`${group}Update`]: {
        ...Member[group],
        [name]: value,
      },
    });
  };
  const handleChangeMarried = (e) => {
    const { name, value } = e.target;
    if (
      [
        "first_name_en_married",
        "sur_name_en_married",
        "passport_id_married",
      ].includes(name)
    ) {
      setMember({
        ...Member,
        married: {
          ...Member.married,
          [name]: value.toUpperCase(),
        },
      });
    } else {
      setMember({ ...Member, married: { ...Member.married, [name]: value } });
    }
  };

  const sendSubmit = async () => {
    const invalid = await validation();
    if (invalid) return;
    const confirm = await AlertConfirm();
    console.log("Submitted", Member);
    if (confirm) {
      await axios({
        method: "post",
        url: `${URL_API}/pos/ethics/${id}`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          member: Member,
        },
      })
        .then((res) => {
          const success = AlertSuccess("แก้ไขสำเร็จ");
          if (success) props.history.goBack();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        })
        .catch((err) => {
          console.log(err.response.data);
          Modal.warning({
            title: "คำเตือน",
            content: `${err.response.data.message}`,
            okText: "ปิด",
          });
        });
    }
  };

  useEffect(() => {
    setMember({
      ...Member,
      main_partner_code: dataMember?.data.main_partner_code,
      //----------address--------------------
      idAddress: {
        address: dataAddress?.data?.id_address?.address,
        sub_district: dataAddress?.data?.id_address?.sub_district,
        district: dataAddress?.data?.id_address?.district,
        province: dataAddress?.data?.id_address?.province,
        post_code: dataAddress?.data?.id_address?.post_code,
        sub_district_id: dataAddress?.data?.id_address?.sub_district_id,
        district_id: dataAddress?.data?.id_address?.district_id,
        province_id: dataAddress?.data?.id_address?.province_id,
      },
      idAddressMarried: {
        address: dataAddress?.data?.id_address_married?.address,
        sub_district: dataAddress?.data?.id_address_married?.sub_district,
        district: dataAddress?.data?.id_address_married?.district,
        province: dataAddress?.data?.id_address_married?.province,
        post_code: dataAddress?.data?.id_address_married?.post_code,
        sub_district_id: dataAddress?.data?.id_address_married?.sub_district_id,
        district_id: dataAddress?.data?.id_address_married?.district_id,
        province_id: dataAddress?.data?.id_address_married?.province_id,
      },
      taxAddress: {
        address: dataAddress?.data?.tax_address?.address,
        sub_district: dataAddress?.data?.tax_address?.sub_district,
        district: dataAddress?.data?.tax_address?.district,
        province: dataAddress?.data?.tax_address?.province,
        post_code: dataAddress?.data?.tax_address?.post_code,
        sub_district_id: dataAddress?.data?.tax_address?.sub_district_id,
        district_id: dataAddress?.data?.tax_address?.district_id,
        province_id: dataAddress?.data?.tax_address?.province_id,
      },
      currentAddress: {
        address: dataAddress?.data?.current_address?.address,
        sub_district: dataAddress?.data?.current_address?.sub_district,
        district: dataAddress?.data?.current_address?.district,
        province: dataAddress?.data?.current_address?.province,
        post_code: dataAddress?.data?.current_address?.post_code,
        sub_district_id: dataAddress?.data?.current_address?.sub_district_id,
        district_id: dataAddress?.data?.current_address?.district_id,
        province_id: dataAddress?.data?.current_address?.province_id,
      },

      //-------------bank------------------------
      personBank: {
        bank_code: dataBank?.data?.person_bank.bank_code,
        bank_name: dataBank?.data?.person_bank.bank_name,
        bank_account_name: dataBank?.data?.person_bank.account_name,
        bank_account_number: dataBank?.data?.person_bank.account_number,
        bank_branch: dataBank?.data?.person_bank.bank_branch,
        bank_eft_type: dataBank?.data?.person_bank.eft_type,
      },

      CompanyBank: {
        bank_code: dataBank?.data?.company_bank.bank_code,
        bank_name: dataBank?.data?.company_bank.bank_name,
        bank_account_name: dataBank?.data?.company_bank.account_name,
        bank_account_number: dataBank?.data?.company_bank.account_number,
        bank_branch: dataBank?.data?.company_bank.bank_branch,
        bank_eft_type: dataBank?.data?.company_bank.eft_type,
      },

      //---------------Company------------------
      is_company: dataMember?.data.is_company,
      company_name_th: dataMember?.data.company_name,
      company_name_en: dataMember?.data.company_name_en,
      company_type: dataMember?.data.company_type,
      company_tax: dataMember?.data.company_tax,
      company_register_vat: dataMember?.data.company_register_vat,

      //----------register and expire date--------------------
      register_date: dataMember?.data.register_date,
      expire_date: dataMember?.data.expire_date,

      //---------data member----------------------------
      id: dataMember?.data.id,
      partner_code: dataMember?.data.partner_code,
      prefix: dataMember?.data.prefix,
      first_name_th: dataMember?.data.first_name_th,
      //    first_name_en: dataMember?.data.first_name_en,
      sur_name_th: dataMember?.data.sur_name_th,
      //    sur_name_en: dataMember?.data.sur_name_en,
      nationality: dataMember?.data.nationality,
      origin: dataMember?.data.origin,
      id_card: dataMember?.data.id_card,
      member_type: dataMember?.data.member_type,
      mobile: dataMember?.data.mobile,
      email: dataMember?.data.email,
      birth_date: dataMember?.data.birth_date,
      gender: dataMember?.data.gender,
      phone: dataMember?.data.phone,
      line_id: dataMember?.data.line_id,
      facebook: dataMember?.data.facebook,
      first_name_en:
        dataMember?.data.nationality === "TH"
          ? dataMember?.data.first_name_en
          : dataMember?.data.first_name_en.toUpperCase(),
      sur_name_en:
        dataMember?.data.nationality === "TH"
          ? dataMember?.data.sur_name_en
          : dataMember?.data.sur_name_en.toUpperCase(),
      mobiles: dataMember?.data.mobiles,
      passport_id: dataMember?.data.passport_id,
      passport_expire_date: dataMember?.data.passport_expire_date,
      phone_code: dataMember?.data.phone_code,
      verified_email: dataMember?.data.verified_email,
      verified_mobile: dataMember?.data.verified_mobile,
      mobile_prefix: dataMember?.data?.mobile_prefix,

      married: {
        id_married: dataMember?.data.id_married,
        gender_married: dataMember?.data.gender_married,
        first_name_th_married: dataMember?.data.first_name_married_th,
        first_name_en_married: dataMember?.data.first_name_married_en,
        sur_name_th_married: dataMember?.data.sur_name_married_th,
        sur_name_en_married: dataMember?.data.sur_name_married_en,
        nationality_married: dataMember?.data.nationality_married,
        origin_married: dataMember?.data.origin_married,
        id_card_married: dataMember?.data.id_card_married,
        member_type_married: dataMember?.data.partner_type_married,
        mobile_married: dataMember?.data.mobile_married,
        email_married: dataMember?.data.email_married,
        birth_date_married: dataMember?.data.birth_date_married,
        prefix_married: dataMember?.data.prefix_married,
        phone_married: dataMember?.data.phone_married,
        is_company_married: dataMember?.data.is_company_married,
        line_id_married: dataMember?.data.line_id_married,
        facebook_married: dataMember?.data.facebook_married,
        ref_partner_code: dataMember?.data.ref_partner_code,
        partner_code_married: dataMember?.data.partner_code_married,
        mobile_marrieds: dataMember?.data.mobile_marrieds,
        passport_id_married: dataMember?.data.passport_id_married,
        passport_expire_date_married:
          dataMember?.data.passport_expire_date_married,
        phone_code_married: dataMember?.data.phone_code_married,
      },
      sponser: {
        first_name: dataMember?.data.first_name_sponsor,
        sur_name: dataMember?.data.sur_name_sponsor,
        sponsor_code: dataMember?.data.sponsor_code,
        mobile: dataMember?.data.mobile_sponsor,
      },
      upline: {
        first_name: dataMember?.data.first_name_up_line,
        sur_name: dataMember?.data.sur_name_up_line,
        up_line_code: dataMember?.data.up_line_code,
        mobile: dataMember?.data.mobile_up_line,
        chart_type: dataMember?.data.chart_type,
      },
    });
    if (errorMember) AlertError({ text: errorMember });
    if (errorAddress) AlertError({ text: errorAddress });
    if (errorBank) AlertError({ text: errorBank });
    if (errorImage) AlertError({ text: errorImage });
  }, [dataMember, errorMember, dataAddress, errorAddress, dataBank, errorBank]);

  const checkID = (id) => {
    let sum = 0;
    if (id?.length !== 13) {
      return false;
    }
    for (let i = 0; i < 12; i++) {
      sum += parseFloat(id.charAt(i)) * (13 - i);
    }
    if ((11 - (sum % 11)) % 10 !== parseFloat(id.charAt(12))) {
      return false;
    } else {
      return true;
    }
  };

  const validation = async () => {
    let err = {};
    const notEmpty = [
      "first_name_th",
      "sur_name_th",
      // 'mobile',
    ];
    const notEmptyMarried = [
      // // "first_name_th_married",
      // // "sur_name_th_married",
      // // 'mobile_married',
      // //"prefix_married",
      // //"gender_married",
      // //"first_name_en_married",
      // //"sur_name_en_married",
      // "nationality_married",
      // //"id_card_married",
      // // 'phone_married',
      // //"email_married",
      // //"birth_date_married",
    ];

    //check main input
    for (let name in Member) {
      if (notEmpty.includes(name)) {
        if (Member[name] === "" || Member[name] == null) {
          err[name] = " *";
        }
      }
    }

    //check mobile
    // if (!Member.mobiles || !Member.mobiles.length) {
    //   err.mobiles = " *";
    // }

    //check married input

    // for (let name in Member.married) {
    //   if (notEmptyMarried.includes(name)) {
    //     if (Member.married[name] == null || Member.married[name] === "") {
    //       //not thing
    //     } else {

    //       count_validate_married = count_validate_married - 1;
    //       // if (
    //       //   Member.married["first_name_th_married"] == null ||
    //       //   Member.married["first_name_th_married"] === ""
    //       // ) {
    //       //   err["first_name_th_married"] = " *";
    //       // }
    //       // if (
    //       //   Member.married["sur_name_th_married"] == null ||
    //       //   Member.married["sur_name_th_married"] === ""
    //       // ) {
    //       //   err["sur_name_th_married"] = " *";
    //       // }
    //       // if (Member.married['mobile_married'] == null || Member.married['mobile_married'] === "") {
    //       //     err['mobile_married'] = ' *';
    //       // }
    //       // if (
    //       //   Member.married["mobile_marrieds"] == null ||
    //       //   Member.married["mobile_marrieds"] === ""
    //       // ) {
    //       //   err["mobile_marrieds"] = " *";
    //       // }
    //       if (Member.married.id_card_married?.length > 0) {
    //         if (Member?.married.nationality_married === "TH") {
    //           if (!checkID(Member.married.id_card_married)) {
    //             err["id_card_married"] = "*เลขบัตรไม่ถูกต้อง";
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    let validate_married = [
      // // "first_name_th_married",
      // // "sur_name_th_married",
      // "mobile_married",
      // "prefix_married",
      // // "gender_married",
      // "first_name_en_married",
      // "sur_name_en_married",
      // "nationality_married",
      // // "id_card_married",
      // // "phone_married",
      // // "email_married",
      // "birth_date_married",
    ];

    let count_validate_married = notEmptyMarried.length;
    for (let name in Member.married) {
      if (notEmptyMarried.includes(name)) {
        if (Member.married[name] != null || Member.married[name] !== "") {
          count_validate_married = count_validate_married - 1;
          console.log(Member.married[name]);
          if (name === "nationality_married") {
            if (Member.married[name] === "TH") {
              validate_married = [
                ...validate_married,
                "first_name_th_married",
                "sur_name_th_married",
                "gender_married",
                "id_card_married",
                // "phone_married",
                // "email_married",
              ];
            } else {
              validate_married = [...validate_married, "passport_id_married"];
            }
          }
        }
      }
    }

    if (count_validate_married <= 0) {
      for (let name in Member.married) {
        if (validate_married.includes(name)) {
          if (Member.married[name] === "" || Member.married[name] == null) {
            err[name] = " *";
          }
        }
      }
    }

    if (Member.married.id_card_married?.length > 0) {
      if (Member?.married.nationality_married === "TH") {
        if (!checkID(Member.married.id_card_married)) {
          err["id_card_married"] = "*เลขบัตรไม่ถูกต้อง";
        }
      }
    }

    if (Member.married.passport_id_married?.length > 0) {
      if (Member?.married.nationality_married === "TH") {
        if (!checkID(Member.passport_id_married)) {
          err["passport_id_married"] = "*เลขพาสปอร์ตไม่ถูกต้อง";
        }
      }
    }

    if (Member.married.passport_id_married?.length > 0) {
      if (Member?.married.nationality_married === "TH") {
        if (
          Member.married.id_card_married === Member.married.passport_id_married
        ) {
          err["passport_id_married"] = "*เลขซ้ำกับบัตรประชาชน";
        }
      }
    }

    if (Member.married.passport_id_married?.length > 0) {
      if (Member?.married.nationality_married !== "TH") {
        if (!checkID(Member.passport_id_married)) {
          err["passport_id_married"] = "*เลขพาสปอร์ตไม่ถูกต้อง";
        }
      }
    }

    //address group
    if (
      Member.address?.length > 0 ||
      Member.province_id != null ||
      Member.district_id != null ||
      Member.sub_district_id != null
    ) {
      if (Member.address === "" || Member.address === undefined)
        err["address"] = " *";
      if (Member.province_id == null) err["province"] = " *";
      if (Member.district_id == null) err["district"] = " *";
      if (Member.sub_district_id == null) err["sub_district"] = " *";
    }

    //bank group
    if (
      Member.bank_account_name?.length > 0 ||
      Member.bank_account_number?.length > 0 ||
      Member.bank_branch?.length > 0 ||
      Member.bank_name != null
    ) {
      if (Member.bank_name === "" || Member.bank_name == null)
        err["bank_name"] = " *";
      if (Member.bank_account_name === "" || Member.bank_account_name == null)
        err["bank_account_name"] = " *";
      if (
        Member.bank_account_number === "" ||
        Member.bank_account_number == null
      )
        err["bank_account_number"] = " *";
      if (Member.bank_branch === "" || Member.bank_branch == null)
        err["bank_branch"] = " *";
    }

    // console.log("idcard",Member.id_card);
    //     //check id card format
    //     if (Member.id_card?.length > 0) {
    //       if (Member?.nationality === "TH") {
    //         if (checkID(Member.id_card)) {
    //           err["id_card"] = "*เลขบัตรไม่ถูกต้อง";
    //         }
    //       }
    //     }

    if (Member.id_card?.length > 0) {
      console.log("Member.id_card", Member.id_card);
      if (Member?.nationality === "TH") {
        if (!checkID(Member.id_card)) {
          err["id_card"] = "*เลขบัตรไม่ถูกต้อง";
        }
      } else {
        if (Member.passport_id === "" || Member.passport_id == null) {
          err["passport_id"] = "*เลขพาสปอร์ตไม่ถูกต้อง";
        }
      }
    }
    //check passport_id format
    if (Member.passport_id?.length > 0) {
      if (Member?.nationality === "TH") {
        // if (!checkID(Member.passport_id)) {
        //   err["passport_id"] = "*เลขพาสปอร์ตไม่ถูกต้อง";
        // } else
        if (Member.passport_id === Member.id_card) {
          err["passport_id"] = "*เลขซ้ำกับเลขประจำตัวประชาชน";
        }
      }
    }

    //check gender and prefix for add married
    if (Member.gender === Member.married.gender_married) {
      err["gender_married"] = " *";
    }
    if (Member.prefix != null && Member.married.prefix_married != null) {
      if (Member.prefix === Member.married.prefix_married) {
        err["prefix_married"] = " *";
      } else {
        if (
          (Member.prefix === "Miss" &&
            Member.married.prefix_married === "Mrs") ||
          (Member.prefix === "Mrs" && Member.married.prefix_married === "Miss")
        ) {
          err["prefix_married"] = " *";
        }
      }
    }
    //group company
    if (Member?.is_company === 1) {
      if (Member?.company_name_th === "" || Member?.company_name_th == null) {
        err["company_name_th"] = " *";
      }
      if (Member?.company_register_vat == null) {
        err["company_register_vat"] = " *";
      }
      if (Member?.company_type === "" || Member?.company_type == null) {
        err["company_type"] = " *";
      }
      if (Member?.company_register_vat === 0) {
        if (Member?.company_tax === "" || Member?.company_tax == null) {
          err["company_tax"] = " *";
        }
      }
    }
    setErrors(err);

    //for check error in main and married
    for (let name in Member) {
      if (err[name]) {
        const error = await AlertError({ title: "กรุณากรอกข้อมูลให้ครบ" });
        if (error) return true;
      }
    }
    for (let name in Member.married) {
      if (err[name]) {
        const error = await AlertError({
          title: "กรุณาตรวจสอบข้อมูลผู้สมัครร่วม",
        });
        if (error) return true;
      }
    }
    return false;
  };

  return (
    <DistributorContext.Provider value={{ state, dispatch }}>
      <Panel className="bg-transparent">
        <PanelHeader>
          <div className="d-flex justify-content-between">
            ตรวจเอกสาร{" "}
            <span className="pr-2">
              สถานะ: {dataMember?.data.name_status}{" "}
            </span>
          </div>
        </PanelHeader>
        <PanelBody>
          <Row gutter={20}>
            <Col md={12}>
              <LayoutInfo
                content={
                  <Members
                    loadingMember={loadingMember}
                    Member={Member}
                    errors={errors}
                    setMember={setMember}
                    handleChange={handleChange}
                    handleChangeMarried={handleChangeMarried}
                  />
                }
              />
              <LayoutInfo
                content={
                  ["buyerInfo", "marriedBuyer"].includes(
                    ActiveTabs.active_tab_name
                  ) && (
                    <MemberAddress
                      loadingAddress={loadingAddress}
                      id={id}
                      setMember={setMember}
                      Member={Member}
                      errors={errors}
                      handleChange={handleChangeAddress}
                      ActiveTabs={ActiveTabs.active_tab_name}
                    />
                  )
                }
              />
              <LayoutInfo
                content={
                  ["buyerInfo"].includes(ActiveTabs.active_tab_name) && (
                    <MemberBookBank
                      loadingBank={loadingBank}
                      Member={Member}
                      errors={errors}
                      handleChange={handleChangeBank}
                      setMember={setMember}
                    />
                  )
                }
              />
            </Col>
            <Col md={12}>
              <LayoutInfo
                content={
                  <MemberFile
                    loadingImage={loadingImage}
                    id={id}
                    dataImage={dataImage}
                    reloadImge={reloadImge}
                  />
                }
              />

              <LayoutInfo
                content={
                  <MemberRemark
                    ref_id={id}
                    handleChange={(e) => setMember({ ...Member, remark: e })}
                  />
                }
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button type="primary" className="m-2" onClick={sendSubmit}>
              บันทึก
            </Button>
            <Button
              type="danger"
              className="m-2"
              onClick={() => props.history.goBack()}
            >
              ยกเลิก
            </Button>
          </div>
        </PanelBody>
      </Panel>
    </DistributorContext.Provider>
  );
};
export default Index;
