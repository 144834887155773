import { Button, InputNumber, Popconfirm, Table } from "antd";
import React, { useContext } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { PromotionContext } from "../../reducers";

const Amount = ({
  type,
  dataSource,
  dataKey,
  disabledAdd,
  disabledChange,
  refSourceOption,
}) => {
  const { dispatch } = useContext(PromotionContext);
  return (
    <Table size="small" dataSource={dataSource} rowKey="key" pagination={false}>
      <Table.Column
        key="key"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">ลำดับ</div>}
        width={50}
        align="right"
        render={(_text, _record, index) => index + 1}
      />
      <Table.Column
        key="title"
        dataIndex="title"
        className="position-relative"
        title={
          <div className="ant-table-absolute-title-center">ประเภทจำนวน</div>
        }
      />
      <Table.Column
        key="qty"
        dataIndex="qty"
        className="position-relative"
        title={<div className="ant-table-absolute-title-center">จำนวน</div>}
        width={150}
        align="right"
        render={(text, record) =>
          record.item_type === "percent" ? (
            <InputNumber
              size="small"
              value={text}
              min={1}
              max={100}
              onFocus={(e) => e.target.select()}
              disabled={disabledChange}
              onChange={(value) =>
                dispatch({
                  type: "SET_PROMOTION_DATA_ITEM_VALUE",
                  payload: {
                    type,
                    data_key: dataKey,
                    item_key: record.key,
                    qty: value,
                  },
                })
              }
            />
          ) : (
            <InputNumber
              size="small"
              value={text}
              min={record.min || 1}
              onFocus={(e) => e.target.select()}
              disabled={disabledChange}
              onChange={(value) =>
                dispatch({
                  type: "SET_PROMOTION_DATA_ITEM_VALUE",
                  payload: {
                    type,
                    data_key: dataKey,
                    item_key: record.key,
                    qty: value,
                  },
                })
              }
            />
          )
        }
      />
      <Table.Column
        key="action"
        title={
          <Button
            type="primary"
            ghost
            size="small"
            disabled={disabledAdd}
            onClick={() =>
              dispatch({
                type: "SET_MODAL_VISIBLE",
                payload: {
                  name: "amount",
                  visible: true,
                  active_by: type,
                  data_key: dataKey,
                  ref_source_option: refSourceOption || null,
                },
              })
            }
          >
            <FaPlus />
          </Button>
        }
        align="center"
        width={60}
        render={(record) => (
          <Popconfirm
            title="ต้องการลบรายการนี้หรือไม่"
            disabled={record.disabled_del}
            onConfirm={() =>
              dispatch({
                type: "DEL_PROMOTION_DATA_ITEM",
                payload: {
                  type,
                  data_key: dataKey,
                  item_key: record.key,
                },
              })
            }
            okText="ลบ"
            cancelText="ไม่ลบ"
          >
            <Button
              type="danger"
              ghost
              size="small"
              disabled={record.disabled_del}
            >
              <FaTrashAlt />
            </Button>
          </Popconfirm>
        )}
      />
    </Table>
  );
};

export default Amount;
