/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, DatePicker, Modal, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { useApi } from "../../../../../../../hooks/http2";
import {
  actionProduct,
  modalSelector,
  orderSourceSelector,
} from "../../../../../../../redux/slices/product";
import { URL_API, _ } from "../../../../../../../utils";
import {
  disabledEndDateSplit,
  disabledEndTimeSplit,
} from "../../../../../../util/helper";

const BranchModal = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const {
    branch: { visible, title, type },
  } = useSelector(modalSelector);
  const orderSource = useSelector(orderSourceSelector);
  const {
    ADD_ORDER_SOURCE_ALL,
    SET_ORDER_SOURCE_ALL,
    CLOSE_MODAL,
    SET_ORDER_SOURCE,
    ADD_ORDER_SOURCE,
  } = actionProduct;

  const [dataSource, setDataSource] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const branch = useApi(
    {
      baseURL: URL_API,
      url: `/pos/master/branch`,
      params: {
        show_pos: 1,
      },
    },
    []
  );

  useEffect(() => {
    if (match?.params?.id && branch?.fetch?.data) {
      dispatch(
        ADD_ORDER_SOURCE_ALL({
          type: "pos",
          selected: true,
          data: branch?.fetch.data,
        })
      );
    }
  }, [branch?.fetch?.data, match?.params?.id]);

  useEffect(() => {
    if (branch?.fetch?.data && orderSource[type]) {
      const branch_select = _.map(
        orderSource[type].itemKeys,
        (n) => orderSource[type].itemByKey[n]
      );
      const data_filter = _.reduce(
        branch?.fetch?.data,
        (r, branch) => {
          const find = _.find(branch_select, (n) => n.code === branch.code);
          return [
            ...r,
            find ? { ...branch, ...find, checked: true } : { ...branch },
          ];
        },
        []
      );
      setDataSource(data_filter);
    }
  }, [branch?.fetch?.data, orderSource[type], type]);

  if (branch?.error) return <Alert type="error" message={branch?.error} />;

  const handleSetAll = () => {
    dispatch(
      SET_ORDER_SOURCE_ALL({ type, start_date: startDate, end_date: endDate })
    );
  };

  return (
    <Modal
      visible={visible}
      title={`เลือกสาขา (${title})`}
      onCancel={() => dispatch(CLOSE_MODAL({ modal: "branch" }))}
      footer={false}
      width="800px"
      zIndex={1080}
      destroyOnClose={true}
    >
      <Row gutter={16} className="mb-3">
        <Col span={9}>
          <DatePicker
            className="w-100"
            placeholder="วันที่เริ่มต้น"
            format="DD/MM/YYYY HH:mm:ss"
            showTime={true}
            defaultPickerValue={moment("00:00:00", "HH:mm:ss")}
            value={startDate}
            onChange={(e) => {
              setStartDate(e);
              setEndDate(null);
            }}
          />
        </Col>
        <Col span={9}>
          <DatePicker
            className="w-100"
            placeholder="วันที่สิ้นสุด"
            format="DD/MM/YYYY HH:mm:ss"
            showTime={true}
            defaultPickerValue={moment("23:59:59", "HH:mm:ss")}
            value={endDate}
            disabledDate={(current) => disabledEndDateSplit(current, startDate)}
            disabledTime={(current) => disabledEndTimeSplit(current, startDate)}
            onChange={(e) => {
              const start = moment(startDate).add(1, "s");
              if (+moment(start).format("DD") === +moment(e).format("DD")) {
                const current_hour = +moment(e).format("HH");
                const start_hour = +moment(start).format("HH");
                if (
                  current_hour === start_hour &&
                  current_hour !== +moment(endDate).format("HH")
                ) {
                  e = moment(e).set({
                    m: +moment(start).format("mm"),
                    s: +moment(start).format("ss"),
                  });
                }
              }
              setEndDate(e);
            }}
          />
        </Col>
        <Col span={6} className="text-right">
          <Button type="primary" onClick={handleSetAll}>
            กำหนดวันที่ทั้งหมด
          </Button>
        </Col>
      </Row>
      <Table
        bordered
        rowKey="code"
        loading={branch.loading}
        size="small"
        dataSource={dataSource}
        pagination={false}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: _.map(
            _.filter(dataSource, (n) => n.checked),
            (n) => n.code
          ),
          onSelect: (value) => {
            dispatch(
              ADD_ORDER_SOURCE({ type, code: value.code, name: value.name })
            );
          },
          onSelectAll: (selected, selected_row) => {
            console.log(selected);
            console.log(selected_row);
            dispatch(
              ADD_ORDER_SOURCE_ALL({ type, selected, data: selected_row })
            );
          },
        }}
      >
        <Table.Column
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">สาขา</div>}
          key="name"
          dataIndex="name"
        />
        <Table.Column
          title="วันที่เริ่มต้น"
          key="start_date"
          dataIndex="start_date"
          align="center"
          render={(text, record) => (
            <DatePicker
              value={text}
              disabled={!record.checked}
              size="small"
              className="w-100"
              placeholder="วันที่เริ่มต้น"
              format="DD/MM/YYYY HH:mm:ss"
              defaultPickerValue={moment("00:00:00", "HH:mm:ss")}
              showTime={true}
              onChange={(e) =>
                dispatch(
                  SET_ORDER_SOURCE({
                    type,
                    branch_key: record.branch_key,
                    start_date: e,
                    end_date: null,
                  })
                )
              }
            />
          )}
        />
        <Table.Column
          title="วันที่สิ้นสุด"
          key="end_date"
          dataIndex="end_date"
          align="center"
          render={(text, { checked, start_date, end_date, branch_key }) => (
            <DatePicker
              value={text}
              disabled={!checked}
              size="small"
              className="w-100"
              placeholder="วันที่สิ้นสุด"
              format="DD/MM/YYYY HH:mm:ss"
              showTime={true}
              defaultPickerValue={moment("23:59:59", "HH:mm:ss")}
              disabledDate={(current) =>
                disabledEndDateSplit(current, start_date)
              }
              disabledTime={(current) =>
                disabledEndTimeSplit(current, start_date)
              }
              onChange={(e) => {
                const start = moment(start_date).add(1, "s");
                if (+moment(start).format("DD") === +moment(e).format("DD")) {
                  const current_hour = +moment(e).format("HH");
                  const start_hour = +moment(start).format("HH");
                  if (
                    current_hour === start_hour &&
                    current_hour !== +moment(end_date).format("HH")
                  ) {
                    e = moment(e).set({
                      m: +moment(start).format("mm"),
                      s: +moment(start).format("ss"),
                    });
                  }
                }
                dispatch(
                  SET_ORDER_SOURCE({
                    type,
                    branch_key,
                    end_date: e,
                  })
                );
              }}
            />
          )}
        />
      </Table>
    </Modal>
  );
};

export default BranchModal;
