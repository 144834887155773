import React from "react";
import SelectOption from "../../../../components/SelectOption";
import { useApi } from "../../../../hooks/http2";
import { URL_API, _ } from "../../../../utils";

const SelectBranch = (props) => {
  const list = useApi(
    {
      url: `${URL_API}/pos/master/branch`,
      token: true,
    },
    []
  );

  return (
    <SelectOption
      loading={list.loading}
      data={_.map(list.fetch?.data || [], (n) => ({
        value: n.code,
        text: n.name,
      }))}
      {...props}
    />
  );
};

export default SelectBranch;
