import { Descriptions } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { sponsorSelector } from "../../../../redux/slices/member";

export default function SponsorPanel() {
  const sponsor = useSelector(sponsorSelector);
  return (
    <Descriptions size="small" bordered>
      <Descriptions.Item label="รหัสสมาชิก" span={3}>
        {sponsor.sponsor_code === "" ? "-" : sponsor.sponsor_code}
      </Descriptions.Item>
      <Descriptions.Item label="ชื่อสมาชิก" span={2}>
        {sponsor.sponsor_name === "" ? "-" : sponsor.sponsor_name}
      </Descriptions.Item>
      <Descriptions.Item label="โทรศัพท์">
        {sponsor.sponsor_mobile === "" ? "-" : sponsor.sponsor_mobile}
      </Descriptions.Item>
    </Descriptions>
  );
}
