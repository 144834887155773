import { Button, Col, Form, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API } from "../../../../../../utils";

import TextEditor from "../../../../../../components/TextEditor";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";

function HF_Intro({ history, form, ...props }) {
  const [textEditorTitle, setTextEditorTitle] = useState("");
  const [textEditorContent, setTextEditorContent] = useState("");

  const handleClickSubmit = () => {
    Modal.confirm({
      title: "ยืนยัน",
      content: "ต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            baseURL: URL_API,
            url: "/manage-website/ecosystem/healthy-food/hf-intro",
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              title: textEditorTitle,
              content: textEditorContent,
            },
          })
            .then((res) => {
              console.log(res);
              resolve("success");
            })
            .catch((err) => {
              console.log(err);
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกเรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.go(0);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/healthy-food/hf-intro",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        console.log(data)
        setTextEditorTitle(data.title)
        setTextEditorContent(data.content)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <Panel>
        <PanelHeader>Intro Healthy Food</PanelHeader>
        <PanelBody>
          <Row className="mb-3">
            <Row gutter={24} md={24}>
              <Col className="mb-5">
                <h5>title</h5>
                <TextEditor
                  selector="textarea"
                  plugins="textcolor "
                  toolbar="forecolor"
                  authToken={getToken()}
                  value={textEditorTitle}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setTextEditorTitle(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-5">
                <h5>content</h5>
                <TextEditor
                  selector="textarea"
                  plugins="textcolor "
                  toolbar="forecolor"
                  authToken={getToken()}
                  value={textEditorContent}
                  onInit={(evt, event) => {}}
                  onEditorChange={(e) => {
                    setTextEditorContent(e);
                  }}
                  onUploadSuccess={(json) => {
                    console.log(json);
                  }}
                  postBody={{ container_name: "web-content" }}
                  urlUpload={`${URL_API}/file/upload`}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Button type="primary" onClick={() => handleClickSubmit()}>
              SAVE
            </Button>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("ecosystem-hf-intro")(HF_Intro);
