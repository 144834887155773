import { Alert, Button, Col, DatePicker, Row, Select, Table, Tooltip } from 'antd'
import React, { useState } from 'react';
import { useHttp } from '../../../../hooks/http';
import { moment, URL_API } from '../../../../utils';
import { useQuery } from '../../../../hooks/dom';
import { FaEye } from 'react-icons/fa';
import BillPreview from '../../pos/components/BillPreview';
import { getNumeral } from '../../../../utils/helper';
import { useSelector } from 'react-redux';
import { typeSelector } from '../../../../redux/slices/legal';
const { Option } = Select;

const initSearch = {
  order_type: "",
  receive_type: "",
  date_from: moment(),
  date_to: moment()
}

const limit = 10;

const SaleHistory = () => {
  const query = useQuery();
  const id = query.get('id');
  const [search, setSearch] = useState(initSearch);
  const [page, setPage] = useState(1);
  const [preview, setPreview] = useState(false);
  const [billSelectId, setBillSelectId] = useState(0);
  const member_type = useSelector(typeSelector)

  const {
    order_type,
    receive_type,
    date_to,
    date_from
  } = search;

  const [loadingInfo, dataInfo, errorInfo] = useHttp({
    url: `${URL_API}/pos/master/info`,
    params: {
      method: 'sale_id',
      limit,
      offset: limit * (page - 1),
      id,
      order_type,
      receive_type,
      member_type : member_type.type,
      date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : null,
      date_to: date_to ? moment(date_to).format('YYYY-MM-DD') : null
    }
  }, [page, search]);

  const [loadingBranch, dataBranch, errorBranch] = useHttp({
    url: `${URL_API}/pos/master/branch`,
  }, []);

  const handleChangeDate = (e) => {
    if (e.length > 0) {
      setSearch(prevSearch => {
        return {
          ...prevSearch,
          date_from: e[0],
          date_to: e[1]
        }
      });
      setPage(1)
    } else {
      setSearch(prevSearch => {
        return {
          ...prevSearch,
          date_from: null,
          date_to: null
        }
      });
      setPage(1)
    }
  }

  if (errorInfo || errorBranch) return <Alert type="error" message={errorInfo || errorBranch} />

  return (
    <>
      <Row gutter={16}>
        <Col span={24} md={8} lg={6} xxl={4}>
          <Select
            allowClear
            name="order_type"
            className="w-100"
            placeholder="ประเภทการสั่งซื้อ"
            value={order_type}
            onChange={value => {
              setSearch(prevSearch => {
                return {
                  ...prevSearch,
                  order_type: value
                }
                
              });
              setPage(1)
            }
          }
          >
            <Option value="">ทั้งหมด</Option>
            <Option value="Regular">Regular</Option>
            <Option value="Hold">Hold</Option>
          </Select>
        </Col>
        <Col span={24} md={8} lg={6} xxl={4}>
          <Select
            allowClear
            name="receive_type"
            className="w-100"
            placeholder="ช่องทางการสั่งซื้อ"
            loading={loadingBranch}
            value={receive_type}
            onChange={value => {
              setSearch(prevSearch => {
                return {
                  ...prevSearch,
                  receive_type: value
                }
              });
              setPage(1)
            }}
          >
            <Option value="">ทั้งหมด</Option>
            {
              dataBranch?.data.map(n => (
                <Option key={n.code} value={n.code}>{n.name}</Option>
              ))
            }
            <Option value='transport'>จัดส่ง</Option>
          </Select>
        </Col>
        <Col span={24} md={8} lg={{ offset: 4, span: 8 }} xxl={{ offset: 10, span: 6 }} className="text-right">
          <DatePicker.RangePicker
            className="w-100 text-center"
            value={[date_from, date_to]}
            onChange={handleChangeDate}
            format="DD/MM/YYYY"
            placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
          >
          </DatePicker.RangePicker>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={dataInfo?.data || []}
            bordered
            loading={loadingInfo}
            size="small"
            rowKey="row_id"
            scroll={{ x: 'max-content' }}
            className="bg-white mt-3 mb-3"
            pagination={{
              current: page,
              pageSize: limit,
              total: dataInfo?.total || 0,
              showSizeChanger: false,
              showLessItems: true,
              //hideOnSinglePage: true,
              size: "default",
              showTotal: (total, range) =>
              `${getNumeral(range[0])} - ${getNumeral(range[1])} of ${getNumeral(
                total
              )}`,
              onChange: currPage => setPage(currPage)
            }}
          >
            <Table.Column align='center' title='เลขที่บิล' key='document_no' dataIndex='document_no' />
            <Table.Column align='center' title='วันที่สั่งซื้อ' key='document_date' dataIndex='document_date' render={data => (
              moment(data).format('DD/MM/YYYY')
            )} />
            <Table.Column align='center' title='ประเภทการสั่งซื้อ' key='order_type' dataIndex='order_type' />
            <Table.Column align='center' title='ช่องทางการสั่งซื้อ' key='req_receipt_type_name' dataIndex='req_receipt_type_name' />
            <Table.Column align='center' title='pv' key='pv' dataIndex='pv' />
            <Table.Column align='center' title='ยอดรวม' key='grand_total_amount' dataIndex='grand_total_amount' />
            <Table.Column align='center' title='' key='action' render={({id}) => (
              <Tooltip title="ดูรายละเอียด">
                <Button
                  type="link"
                  size="small"
                  className="text-dark"
                  onClick={() => {
                    setBillSelectId(id);
                    setPreview(true);
                  }}
                >
                  <FaEye />
                </Button>
              </Tooltip>
            )} />
          </Table>
        </Col>
      </Row>

      <BillPreview
        id={billSelectId}
        preview={preview}
        setPreview={setPreview}
      />
    </>
  )
}

export default SaleHistory
