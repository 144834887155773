/* eslint-disable no-unused-vars */
import { Col, notification, Row, Table, typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { AlertError } from "../../../../util/Alert";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import { generationsLevelOptions } from "../../components/generationLevelOptions";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const { Text } = typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);

  const [partnerStatusFilter, setPartnerStatusFilter] = useState("");
  const [generationsLevel, setGenerationsLevel] = useState("");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-recommend`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        partnerCode: state.filter.query,
        status: partnerStatusFilter,
        levelId: generationsLevel,
        ...state.filter,
      },
      initialLoad: false,
    },
    []
  );

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-filter`,
      token: true,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);

  const exportDataData = data ? [data?.data] : [];

  const exportToExcel = () => {
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานทีมงานแนะนำตรง"],
        [
          `รหัสสมาชิก / ชื่อสมาชิก : ${state.filter.query}`,
          "",
          "",
          `สถานะ : ${
            partnerStatusFilter === "" ? "ทั่งหมด" : partnerStatusFilter
          }`,
          "",
          `ชั้น : ${
            generationsLevel === ""
              ? "ทั่งหมด"
              : generationsLevelOptions[_.toNumber(generationsLevel)].text
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [],
        [
          "ลำดับที่",
          "ชั้น",
          "รหัส",
          "ชื่อ",
          "วันที่สมัคร",
          "เบอร์โทร",
          "E-mail",
          "Line",
          "ที่อยู่ปัจจุบัน",
          "ตำแหน่ง",
          "รหัสผู้แนะนำ",
          "ชื่อผู้แนะนำ",
          "เบอร์โทร",
          "Line",
          "สถานะ",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            { v: n.level_name, s: { alignment: { horizontal: "center" } } },
            n.partner_code,
            n.first_name_th + " " + n.sur_name_th,
            moment(n.register_date).format("DD/MM/YYYY"),
            n.phone_code,
            n.email,
            n.line_id,
            n.address,
            { v: n.honor_code, s: { alignment: { horizontal: "center" } } },
            n.sponsor_code,
            n.sponsor_first_name_th === null
              ? "-"
              : n.sponsor_first_name_th + " " + n.sponsor_sur_name_th,
            n.sponser_phone_code,
            n.sponser_line_id,
            { v: n.status, s: { alignment: { horizontal: "center" } } },
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 9 } },
        { s: { r: 1, c: 10 }, e: { r: 1, c: 12 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 25 },
        { wch: 10 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;
      ws["I4"].s = centerStyles;
      ws["J4"].s = centerStyles;
      ws["K4"].s = centerStyles;
      ws["L4"].s = centerStyles;
      ws["M4"].s = centerStyles;
      ws["N4"].s = centerStyles;
      ws["O4"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานทีมงานแนะนำตรง");
      XLSX.writeFile(wb, "รายงานทีมงานแนะนำตรง.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  const partnerStatusOptions = [
    ..._.map(filterDataSource.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานทีมงานแนะนำตรง</PanelHeader>
        <PanelBody>
          <FilterList /*exportToExcel={""}*/
            withAll={user.branch_count > 1}
            allowDisableBranch
            reload={() => {
              state.filter.query === ""
                ? AlertError({
                    title: "ข้อมูลไม่ถูกต้อง",
                    text: "กรุณาระบุรหัสสมาชิก",
                  })
                : reload();
            }}
            placeholder="กรุณาระบุรหัสสมาชิก"
            showReportSpan={10}
            inputSpan={6}
            topicSpan={6}
            Topic={`ชื่อสมาชิก : `}
            showDatePicker={false}
            exportToExcel={exportToExcel}
          />
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>สถานะ</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                placeholder="สถานะ"
                defaultValue={partnerStatusFilter}
                options={partnerStatusOptions}
                onChange={(e) => {
                  setPartnerStatusFilter(e);
                }}
              />
            </Col>

            <Col>
              <Text strong>ชั้น</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                placeholder="ชั้น"
                defaultValue={generationsLevel}
                options={generationsLevelOptions}
                onChange={(e) => {
                  setGenerationsLevel(e);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.data ? dataSource.data.length : 50,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column title="ชั้น" dataIndex="level_name" align="center" />
            <Table.Column
              title="รหัส"
              dataIndex="partner_code"
              align="center"
            />
            <Table.Column
              title="ชื่อ"
              align="left"
              render={(_, record) =>
                record.first_name_th + " " + record.sur_name_th
              }
            />
            <Table.Column
              title="เบอร์โทร"
              dataIndex="phone_code"
              align="left"
            />
            <Table.Column
              title="วันที่สมัคร"
              dataIndex="register_date"
              align="left"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column title="E-mail" dataIndex="email" align="left" />
            <Table.Column title="Line" dataIndex="line_id" align="left" />
            <Table.Column
              title="ที่อยู่ปัจจุบัน"
              dataIndex="address"
              align="left"
            />
            <Table.Column
              title="ตำแหน่ง"
              dataIndex="honor_code"
              align="center"
            />
            <Table.Column
              title="รหัสผู้แนะนำ"
              dataIndex="sponsor_code"
              align="center"
            />
            <Table.Column
              title="ชื่อผู้แนะนำ"
              align="left"
              render={(_, record) =>
                record.sponsor_first_name_th === null
                  ? "-"
                  : record.sponsor_first_name_th +
                    " " +
                    record.sponsor_sur_name_th
              }
            />
            <Table.Column
              title="เบอร์โทร"
              dataIndex="sponser_phone_code"
              align="center"
            />
            <Table.Column
              title="Line"
              dataIndex="sponser_line_id"
              align="left"
            />
            <Table.Column title="สถานะ" dataIndex="status" align="center" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
