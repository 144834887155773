/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineSelect } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";
import { numInt } from "../../../../util/helper/map-data";
import BomDetail from "../../../pos/components/BomDetail";
import ClaimBomDetail from "../ClaimBomDetail";

const columns = [
  "",
  "รหัสสินค้า",
  "ชื่อสินค้า",
  "ราคา/หน่วย",
  "คะแนน/หน่วย",
  "จำนวน",
  "เงินรวม",
  "คะแนนรวม",
  "จำนวนที่เคลมได้",
  "จำนวนที่เคลม",
  "เคลมเงินรวม",
  "",
];

const { setGlobalState } = actionClaim;

const BillItemTable = () => {
  const dispatch = useDispatch();
  const { itemKeys, itemByKey } = useSelector(
    (state) => state.claim.billProduct
  );

  const [totalQty, setTotalQty] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  // ราคาเคลมรวม = ราคาสินค้าหลัก + ราคาสินค้าย่อย (รวมกันทั้งหมด)
  const [sumClaimPrice, setSumClaimPrice] = useState(0);
  let allClaimPrice = [0];
  useEffect(() => {
    setSumClaimPrice(_.sum(allClaimPrice));
  }, [allClaimPrice]);

  //------หาว่ารายการสินค้าไหนบ้างที่เป็น bom (product key) ----::
  const find_bom = itemKeys.map((n, i) =>
    itemByKey[n]?.bom?.length > 0 ? itemKeys[i] : null
  );

  //---------------ฟังก์ชั่นหาจำนวน cliam_qty ของรายการ bom ::
  const mapData = (bomKeys, bomByKey, bomList) => {
    const _bom = _.map(bomKeys, (key) => bomByKey[key]?.bom);
    let _redux = []; // bom data array
    _.forEach(_bom, (bm) => {
      // (bm = bom) - map bom item to array
      _.forEach(bm.itemKeys, (bomItemKey) => {
        // push bom data to array
        _redux.push(bm.itemByKey[bomItemKey]);
      });
    });

    let _list = _.map(bomList, (ls) => {
      // (ls = list) - filter bom match current item
      let _filter = _.filter(
        _redux,
        (m) =>
          m.ref_product_code === ls.ref_product_code &&
          m.product_code === ls.product_code
      );
      // sum qty
      let claim_qty = _.reduce(
        _filter,
        (sum, m) => (sum += m.claim_item?.itemKeys?.length || 0),
        0
      );
      return {
        ...ls,
        claim_qty: _.toNumber(claim_qty),
      };
    });
    return _list;
  };

  useEffect(() => {
    let total_price = 0;
    let total_qty = 0;
    const data = _.map(itemKeys, (key) => {
      const n = itemByKey[key];
      const claim_qty = n.claim_item.itemKeys.length;
      const claim_item_price = getClaimItemPrice(n);
      total_price += claim_item_price;
      total_qty += claim_qty;
      return { ...n, key, claim_item_price };
    });
    setDataSource(data);
    setTotalQty(total_qty);
    setTotalPrice(total_price);
  }, [itemKeys, itemByKey]);

  return (
    <table className="w-100">
      <thead>
        <tr>
          {_.map(columns, (n, i) => (
            <td key={i} className="text-center">
              {n}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {_.map(dataSource, (n, i) => {
          let canEdit = [];
          if (n?.bill_type !== undefined && n?.bill_type === "draft") {
            // disable button :: draft
            if (n.bom.length > 0) {
              n.bom.map((x) => {
                canEdit.push(numInt(x.balance) <= 0 ? false : true);
              });
            } else {
              canEdit.push(numInt(n.balance) <= 0 ? false : true);
            }
          } else {
            // disable button :: save
            if (n.bom.length > 0) {
              n.bom.map((x) => {
                canEdit.push(
                  numInt(x.qty_balance || x.balance) <= 0 ? false : true
                ); // qty_balance : สำหรับหน้าหลังบันทึก | balance : สำหรับหน้ายื่นเคลม
              });
            } else {
              canEdit.push(
                numInt(n.balance || n.qty_balance) <= 0 ? false : true
              ); // balance : สำหรับหน้าหลังบันทึก | qty_balance : สำหรับหน้ายื่นเคลม
            }
          }

          let editable_bom = _.includes(canEdit, true); // if have true some value => can edit

          const claim_qty = n.claim_item.itemKeys.length;
          // หาจำนวนที่เคลมของ bom ในแต่ละรอบ
          let _bomDetails = mapData(
            itemByKey[n.key]?.claim_item?.itemKeys,
            itemByKey[n.key]?.claim_item?.itemByKey,
            itemByKey[n.key]?.bom
          );
          // เช็คจำนวนรายการหลัก จากรายการย่อย
          let main_cliam_qty = 0;
          if (_bomDetails?.length > 0) {
            // เช็คจำนวนรายการหลัก จากรายการย่อยที่เลือกเคลม
            main_cliam_qty = _.min(
              _bomDetails.map((n) => _.floor(n.claim_qty / n.qty))
            );
            // หาราคาเคลมรวมทั้งหมดของรายการย่อย ( bom )
            let bom_cliam_price = _.sum(
              _bomDetails.map(
                (n) => (n.claim_qty - main_cliam_qty * n.qty) * n.claim_price
              )
            );
            allClaimPrice.push(bom_cliam_price);
          }

          // รวมราคาสินค้าหลักไปเก็บใน allClaimPrice
          if (n.bom?.length > 0) {
            allClaimPrice.push(n.claim_price * main_cliam_qty);
          } else {
            allClaimPrice.push(n.claim_price * claim_qty);
          }

          // หาจำนวนสินค้าย่อยที่ใช้งานไปแล้ว (จำนวนที่เคลมไปแล้ว)
          let bom_used = [];
          if (n.sub_item_used?.length > 0) {
            // หาจาก main prodcut_code
            bom_used = _.map(
              // หาสินค้าย่อยจาก product_code สินค้าหลัก
              _.filter(
                _bomDetails,
                (sub) => n.product_code === sub.ref_product_code
              ),
              (y) =>
                // รวมจำนวนสินค้าย่อยที่เคลมไปแล้วจาก prodcut_code เดียวกัน
                _.sumBy(
                  // หารายการสินค้าย่อยในแต่ละรอบที่เคลมไปแล้ว
                  _.filter(
                    n.sub_item_used,
                    (used) => y.product_code === used.product_code
                  ),
                  (sum) => sum.qty
                )
            );
          }

          return (
            <React.Fragment key={i}>
              <tr key={i}>
                <td>{i + 1}</td>
                <td className="text-center">{n.product_code}</td>
                <td>
                  {n.product_name}
                  {/* {item.bom && <BomDetail list={item.bom} qty={item.qty} />} */}
                </td>
                <td className="text-center">{numInt(n.unit_price)}</td>
                <td className="text-center">{numInt(n.pv)}</td>
                <td className="text-center">{numInt(n.qty)}</td>
                <td className="text-center">{numInt(n.unit_price * n.qty)}</td>
                <td className="text-center">{numInt(n.pv * n.qty)}</td>
                <td className="text-center">
                  {_bomDetails?.length > 0
                    ? "--"
                    : n?.bill_type !== undefined && n?.bill_type === "draft"
                      ? numInt(n.balance)
                      : numInt(n.qty_balance) > 0
                        ? numInt(n.qty_balance)
                        : numInt(n.balance)}
                </td>
                <td className="text-center text-bolder">
                  {_bomDetails?.length > 0
                    ? numInt(main_cliam_qty)
                    : numInt(claim_qty)}
                </td>
                {/* <td className="text-center text-bolder">{numInt(claim_qty)}</td> */}
                <td className="text-center">
                  {numInt(
                    _bomDetails?.length > 0
                      ? n.claim_price * main_cliam_qty
                      : n.claim_item_price
                  )}
                </td>
                {/* <td className="text-center">{numInt(n.claim_item_price)}</td> */}
                <td className="text-center">
                  <Button
                    // disabled={(n.qty_balance || 0) <= 0}
                    disabled={!editable_bom}
                    size="small"
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      const { key } = n;
                      dispatch(
                        setGlobalState({
                          name: "productModal",
                          value: {
                            visible: true,
                            product_key: key,
                          },
                        })
                      );
                    }}
                  >
                    <AiOutlineSelect />
                  </Button>
                </td>
              </tr>
              {n.bom && n.bom.length > 0 && (
                <ClaimBomDetail
                  list={_bomDetails}
                  qty_balance={n.qty_balance}
                  main_cliam_qty={main_cliam_qty}
                  main_qty={n.qty}
                  bom_used={bom_used}
                // list={n.bom}
                // is_bom={find_bom}
                // bom_index={i}
                />
              )}
            </React.Fragment>
          );
        })}
        <tr>
          {_.map(columns, (_, i) => i < columns.length - 4 && <td key={i} />)}
          <td className="text-center py-3">
            <strong>รวม</strong>
          </td>
          <td className="text-center py-3">
            {/* <strong>{numInt(totalQty)}</strong> */}
          </td>
          <td className="text-center py-3">
            {/* <strong>{numInt(totalPrice)}</strong> */}
            <strong>{numInt(sumClaimPrice)}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const getClaimItemPrice = (data) => {
  const {
    claim_item: { itemKeys, itemByKey },
    bom,
    claim_price,
  } = data;

  const claim_bom_price = _.reduce(
    itemKeys,
    (res, item_key) => {
      const item = itemByKey[item_key];
      if (!item.bom.itemKeys.length) return res;
      return res + getClaimItemPriceBom(item.bom, bom);
    },
    0
  );
  return claim_price * itemKeys.length;
  // return claim_bom_price || claim_price * itemKeys.length;
};

const getClaimItemPriceBom = ({ itemKeys, itemByKey }, bom) =>
  _.reduce(
    itemKeys,
    (res, sub_key) => {
      const { claim_item, product_code } = itemByKey[sub_key];
      const claim_qty = claim_item.itemKeys.length;
      const claim_price =
        _.find(bom, (n) => n.product_code === product_code)?.claim_price || 0;
      return res + claim_price * claim_qty;
    },
    0
  );

export default BillItemTable;
