/* eslint-disable react-hooks/exhaustive-deps */
import { Col, DatePicker, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "../../../../hooks/dom";
import styles from "../../../../scss/module/legal.module.scss";
import { moment } from "../../../../utils";
import LegalPanel from "../components/LegalPanel";

const initSearch = {
  date_from: moment(),
  date_to: moment(),
};
const limit = 10;
const Index = ({ history }) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(initSearch);
  const query = useQuery();
  const id = query.get("id");
  useEffect(() => {
    !+id && history.push("/legal");
  }, [id]);
  return (
    <LegalPanel>
      <div className={styles.commission}>
        <Row>
          <Col span={24} className="text-right">
            <DatePicker.RangePicker
              value={[search.date_from, search.date_to]}
              onChange={(e) => {
                if (e.length > 0) {
                  setSearch({
                    date_from: e[0],
                    date_to: e[1],
                  });
                } else {
                  setSearch({
                    date_from: null,
                    date_to: null,
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              dataSource={[]}
              bordered
              // loading={loading}
              size="small"
              rowKey="row_id"
              scroll={{ x: "max-content" }}
              className="pt-3 pb-3"
              pagination={{
                current: page,
                pageSize: limit,
                // total: data?.total || 0,
                showSizeChanger: false,
                showLessItems: true,
                hideOnSinglePage: true,
                size: "default",
                onChange: (currPage) => setPage(currPage),
              }}
            >
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="#"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="จาก"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="ถึง"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="รอบการจ่าย"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="ประเภท"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="ตำแหน่ง"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="โบนัสค่าแนะนำ"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="โบนัสค่าบริหารทีม"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="โบนัสค่าแมชชิ่ง"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="โบนัสส่วนบุคคล"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="ส่วนต่างเงินคืน"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="โบนัสพิเศษ"
              />
              <Table.Column
                align="center"
                key="row_id"
                dataIndex="row_id"
                title="โบนัสรวมก่อนหักึ้่ใช้จ่าย"
              />
            </Table>
          </Col>
        </Row>
      </div>
    </LegalPanel>
  );
};

export default Index;
