import { Typography } from "antd";
import React, { memo, useContext, useMemo } from "react";
import QrButton from "../../../../../components/QrButton";
import { _ } from "../../../../../utils";
import { numDec, numInt } from "../../../../util/helper/map-data";
import BomDetail from "../../../pos/components/BomDetail";
import ArrangeContext from "../../reducer";
import InputNumberWithButton from "./InputNumberWithButton";

const { Text } = Typography;

const BillItem = ({ isCancel }) => {
  const {
    state: {
      bill,
      items: { itemKeys, itemByKey },
    },
    dispatch,
  } = useContext(ArrangeContext);

  const total = useMemo(() => {
    return _.reduce(
      _.map(itemKeys, (n) => itemByKey[n]),
      (prev, n) => {
        return {
          qty: (n.qty || 0) + (prev?.qty || 0),
          qty_arrange: (n.qty_arrange || 0) + (prev?.qty_arrange || 0),
          qty_balance: (n.qty_balance || 0) + (prev?.qty_balance || 0),
          qty_delivery: (n.qty_delivery || 0) + (prev?.qty_delivery || 0),
        };
      },
      {
        qty: 0,
        qty_arrange: 0,
        qty_balance: 0,
        qty_delivery: 0,
      }
    );
  }, [itemKeys, itemByKey]);

  return (
    <table className="w-100 my-2 font-size-12">
      <thead>
        <tr>
          <th></th>
          <th className="font-weight-light text-center text-gray-60">ชื่อ</th>
          <th className="font-weight-light text-center text-gray-60">
            {bill.sales_type === "redeem" ? "คะแนน" : "ราคา"}/หน่วย
          </th>
          <th className="font-weight-light text-center text-gray-60">
            {bill.sales_type === "redeem" ? "คะแนน" : "ราคา"}
          </th>
          <th className="font-weight-light text-center text-gray-60">จำนวน</th>
          <th className="font-weight-light text-center text-gray-60">
            จำนวนค้างจ่าย
          </th>
          <th className="font-weight-light text-center text-gray-60">
            จำนวนที่จ่าย
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {_.map(itemKeys, (key, i) => {
          const n = itemByKey[key];
          const price =
            bill.sales_type === "redeem" ? n.redeem_point_item : n.unit_price;
          return (
            <React.Fragment key={key}>
              <tr className="text-dark">
                <td className="pl-3">{i + 1}</td>
                <td>{`${n.product_code} - ${n.product_name}`}</td>
                <td className="text-center">{numDec(price)}</td>
                <td className="text-center">{numDec(price * n.qty)}</td>
                <td className="text-center">{numInt(n.qty)}</td>
                <td className="text-center">{numInt(n.qty_balance)}</td>
                <td className="text-center">
                  {isCancel ? (
                    0
                  ) : (
                    <InputNumberWithButton
                      value={n.qty_delivery}
                      min={0}
                      max={n.qty_balance}
                      onChange={(e) =>
                        dispatch({
                          type: "setDeliveryQty",
                          payload: {
                            key,
                            value: e,
                          },
                        })
                      }
                    />
                  )}
                </td>
                <td className="text-center pr-3">
                  <QrButton
                    qrWarning={n.qr_warning}
                    onClick={() =>
                      dispatch({
                        type: "setState",
                        name: "qrProductKeySelect",
                        payload: key,
                      })
                    }
                  />
                </td>
              </tr>
              {n.bom.length > 0 && n.product_type_set !== "Assembly" && (
                <tr>
                  <td></td>
                  <td>
                    <BomDetail list={n.bom} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
        <tr>
          <td colSpan={3}></td>
          <td className="text-center">
            <Text strong className="text-dark">
              รวม
            </Text>
          </td>
          <td className="text-center">
            <Text strong className="text-dark">
              {total.qty}
            </Text>
          </td>
          <td className="text-center">
            <Text strong className="text-dark">
              {total.qty_balance}
            </Text>
          </td>
          <td className="text-center">
            <Text strong className="text-dark">
              {bill.total_qty_delivery}
            </Text>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default memo(BillItem);
