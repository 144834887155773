/* eslint-disable jsx-a11y/alt-text */
import { Modal } from "antd";
import React from "react";
import { URL_API } from "../../../../../utils";

const PreviewFile = (props) => {
  return (
    <>
      <Modal zIndex={2005} {...props}>
        <img
          src={`${URL_API}/file/${props.imageId}`}
          style={{ width: "100%" }}
        ></img>
      </Modal>
    </>
  );
};
export default PreviewFile;
