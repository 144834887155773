import { Button, Col, Row, Typography } from "antd";
import React from "react";
import TableItems from "./TableItems";

const Bill = ({
  clickCancel,
  object,
  onClickNext,
  status = "",
  returnWaitTab,
  setNewRemark,
  newRemark,
  handlePreview,
}) => {
  const isConfirm = status.toLowerCase() === "waiting for confirm";

  return (
    <>
      <Row gutter={8} className="bg-gray-60 py-1" style={{ fontSize: "16px" }}>
        <Col className="pl-4">
          <Typography.Text className="text-white">
            ตรวจสอบรายการจอง
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={8} className="bg-gray-40 py-1" style={{ fontSize: "14px" }}>
        <Col className="pl-4">
          <Typography.Text
            className="text-dark"
            strong
          >{`${object.reserve_order_no} - ${object.partner_code} ${object.partner_name}`}</Typography.Text>
        </Col>
      </Row>
      <Row gutter={8} className="mt-2 mb-3" style={{ fontSize: "11px" }}>
        <Col span={2} className="pl-4 text-nowrap">
          <Typography.Text className="text-dark" strong>
            ชื่อผู้รับ
          </Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text className="text-dark">
            {object.ship_to || object.partner_name}
          </Typography.Text>
        </Col>
        <Col span={2} className="text-nowrap">
          <Typography.Text className="text-dark" strong>
            เบอร์โทรผู้รับ
          </Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text className="text-dark">
            {object.ship_mobile || object.partner_mobile}
          </Typography.Text>
        </Col>
        <Col span={3} className="text-nowrap">
          <Typography.Text className="text-dark" strong>
            ประเภทการรับของ
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text className="text-dark">
            {object.receive_type.toLowerCase() === "branch" && object.transport_code == null
              ? "รับที่สาขา"
              : "จัดส่ง"}
          </Typography.Text>
        </Col>
        <Col span={2} className="text-nowrap">
          <Typography.Text className="text-dark" strong>
            {object.receive_type.toLowerCase() === "branch" && object.transport_code == null
              ? "สาขาที่รับ"
              : "ที่อยู่จัดส่ง"}
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text className="text-dark">
            {object.receive_type.toLowerCase() === "branch" && object.transport_code == null
              ? object.branch_name_receive
              : `${object.ship_address_no} ${object.ship_sub_district} ${object.ship_district} ${object.ship_province} ${object.ship_post_code}`}
          </Typography.Text>
        </Col>
      </Row>
      <Row
        gutter={8}
        className="bg-gray-60 py-1 mb-3"
        style={{ fontSize: "16px" }}
      >
        <Col className="pl-4">
          <Typography.Text className="text-white">รายการสินค้า</Typography.Text>
        </Col>
      </Row>

      <TableItems
        dataSource={object.items}
        delivery_amount={object.delivery_amount}
        total_amount={object.total_amount}
        remark_data={object.remark}
        discount_data={object.discount}
        reserve_order_no={object.reserve_order_no}
        partner_type={object.partner_type}
        newRemark={newRemark}
        setNewRemark={setNewRemark}
      />

      <Row
        gutter={8}
        className="mt-5 d-flex align-items-center justify-content-center"
      >
        {isConfirm && (
          <Col span={4}>
            <Button
              size="small"
              block
              className={`text-white ${
                isConfirm ? "bg-rp border-rp" : "bg-gray"
              }`}
              disabled={!isConfirm}
              onClick={isConfirm ? returnWaitTab : undefined}
            >
              <small>{isConfirm ? "ส่งกลับยืนยันเงิน" : "แก้ไขการจอง"}</small>
            </Button>
          </Col>
        )}
        <Col span={4}>
          <Button
            size="small"
            className="text-white bg-tp border-tp"
            block
            onClick={handlePreview}
          >
            <small>รายละเอียด</small>
          </Button>
        </Col>
        <Col span={4}>
          <Button
            size="small"
            className="text-white bg-reds border-reds"
            block
            onClick={clickCancel}
          >
            <small>ยกเลิกการจอง</small>
          </Button>
        </Col>
        <Col span={4}>
          <Button
            size="small"
            className="text-white bg-pv border-pv"
            block
            onClick={onClickNext}
          >
            <small>ถัดไป</small>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Bill;
