import React from "react";
import { Switch, Route } from "react-router-dom";

import Error404 from "../../Error404";

import TransferForm from "./TransferForm";
import TransferList from "./TransferList";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} children={<TransferList />} />
      <Route exact path={`${match.path}/:id/:type?`} children={<TransferForm />} />
      <Route component={Error404} />
    </Switch>
  );
};
