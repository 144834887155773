import { Alert, Button, Col, Input, Modal, Row, Table } from "antd";
import numeral from "numeral";
import React from "react";
import { FaCogs } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHttp } from "../../../../../hooks/http";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import { URL_API, _ } from "../../../../../utils";

const { Column } = Table;

const { clearGlobalState, setGlobalState, addProduct } = actionRequisition;

const AddProductModal = () => {
  const dispatch = useDispatch();
  const { visible, limit, page, query } = useSelector(
    (state) => state.requisition.addProductModal
  );
  const warehouse_code = useSelector(
    (state) => state.requisition.documentDetail.warehouse_code
  );
  const disabledProductCode = useSelector((state) => {
    const { itemKeys, itemByKey } = state.requisition.product;
    return _.join(
      _.reduce(
        itemKeys,
        (res, key) => {
          return [...res, itemByKey[key].product_code];
        },
        []
      ),
      ","
    );
  });

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/requisition/product`,
      params: {
        limit,
        offset: (page - 1) * limit,
        query,
        warehouse_code,
        disabledProductCode,
      },
      token: true,
    },
    [query, page, warehouse_code, disabledProductCode]
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Modal
      title="เพิ่มสินค้า"
      visible={visible}
      footer={false}
      zIndex={1080}
      width={1000}
      destroyOnClose={true}
      onCancel={() => dispatch(clearGlobalState("addProductModal"))}
    >
      <Row className="mb-3" type="flex">
        <Col span={16}>
          <Input.Search
            allowClear
            placeholder="รหัสสินค้า, ชื่อสินค้า, SKU, Barcode"
            value={query}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "addProductModal",
                  value: { query: e.target.value, page: 1 },
                })
              )
            }
          />
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={data?.data || []}
        rowKey="row_id"
        pagination={{
          current: page,
          pageSize: limit,
          total: data?.total || 0,
          showLessItems: true,
          size: "small",
          onChange: (page) =>
            dispatch(
              setGlobalState({
                name: "addProductModal",
                value: { page },
              })
            ),
        }}
      >
        <Column
          width={60}
          title="ลำดับ"
          key="row_id"
          dataIndex="row_id"
          align="center"
        />
        <Column
          width={100}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">รหัสสินค้า</div>
          }
          key="product_code"
          dataIndex="product_code"
        />
        <Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ชื่อสินค้า</div>
          }
          key="product_name"
          dataIndex="product_name"
        />
        <Column
          width={100}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">จำนวนคงเหลือ</div>
          }
          key="stock_balance"
          dataIndex="stock_balance"
          align="right"
          render={(text) => numeral(text).format("0,0")}
        />
        <Column
          width={80}
          title={<FaCogs />}
          align="center"
          key="action"
          render={(record) => (
            <Button
              type="primary"
              size="small"
              onClick={() => dispatch(addProduct(record))}
            >
              เพิ่ม
            </Button>
          )}
        />
      </Table>
    </Modal>
  );
};

export default AddProductModal;
