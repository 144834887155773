/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { createReducer } from "@reduxjs/toolkit";
import { Button, Col, Input, Modal, Row, Table } from "antd";
import moment from "moment";
import React, { useReducer, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../hooks/http2";
import { actionEthics } from "../../../../../redux/slices/ethics";
import { URL_API } from "../../../../../utils";

const initState = {
  search: {
    limit: 10,
    query: "",
    page: 1,
    address_type: "",
  },
};

const reducer = createReducer(initState, {
  CLEAR: (state) => {
    state.search = {
      ...initState.search,
    };
  },
  SET_VALUE: (state, { payload }) => {
    state.search = { ...state.search, ...payload };
  },
});

export default function MemberHistoryModal() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.ethics.modal, shallowEqual);
  const { id, type, married } = useSelector(
    (state) => state.ethics.form,
    shallowEqual
  );

  const active = useSelector(
    (state) => state.ethics.form.active_tab.info,
    shallowEqual
  );

  const { CLOSE_MODAL } = actionEthics;
  const [query, setQuery] = useState("");
  const [state, inDispatch] = useReducer(reducer, initState);

  const profile = useApi(
    {
      baseURL: URL_API,
      url: `/ethics/history/${type == "distributor"
        ? active == "member"
          ? "partner"
          : "married"
        : "member"
        }`,
      token: true,
      params: {
        ...state.search,
        id: id,
        type,
        offset: (state.search.page - 1) * state.search.limit,
      },
    },
    [id, type, state.search, active]
  );
  
  const closeModal = () => {
    dispatch(CLOSE_MODAL());
  };

  return (
    <Modal
      visible={modal.name === "profile"}
      onCancel={closeModal}
      title="ประวัติผู้สมัคร"
      width={1000}
      destroyOnClose={true}
      footer={[
        <Button type="default" key={"close"} onClick={closeModal}>
          ปิด
        </Button>,
      ]}
    >
      <Row gutter={16} className="mb-3">
        <Col md={18}>
          <Input.Search
            size="small"
            className="font-size-12"
            placeholder="ชื่อ, นามสกุล, บัตรประชาชน, พาสปอร์ต, เบอร์โทร, อีเมล"
            allowClear
            value={query}
            onChange={(e) => setQuery(e.target.value || "")}
            onSearch={(e) =>
              inDispatch({
                type: "SET_VALUE",
                payload: { page: 1, query: e || "" },
              })
            }
          />
        </Col>
      </Row>
      <Table
        size="small"
        loading={profile.loading}
        dataSource={
          profile.fetch?.data.map((item, index) => ({
            ...item,
            key: index + 1,
          })) || []
        }
        rowKey="row_id"
        scroll={{ x: true }}
        pagination={{
          size: "default",
          showLessItems: true,
          total: profile.fetch?.total || 0,
          pageSize: state.search.limit,
          current: state.search.page,
          onChange: (page) =>
            inDispatch({ type: "SET_VALUE", payload: { page } }),
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
        }}
      >
        <Table.Column
          title="ลำดับ"
          className="font-size-12"
          dataIndex="row_id"
          align="center"
        />
        <Table.Column
          title="คำนำหน้า"
          className="font-size-12"
          dataIndex="prefix_th"
          align="center"
        />
        <Table.Column
          title="ชื่อ"
          className="font-size-12"
          dataIndex="first_name_th"
        />
        <Table.Column
          title="นามสกุล"
          className="font-size-12"
          dataIndex="sur_name_th"
        />
        <Table.Column
          title="Prefix Name"
          className="font-size-12"
          dataIndex="prefix"
        />
        <Table.Column
          title="First Name"
          className="font-size-12"
          dataIndex="first_name_en"
        />
        <Table.Column
          title="Last Name"
          className="font-size-12"
          dataIndex="sur_name_en"
        />
        <Table.Column
          title="วันเกิด"
          className="font-size-12"
          dataIndex="birth_date"
          align="center"
          render={(text) => (text ? moment(text).format("DD/MM/YYYY") : "-")}
        />
        <Table.Column
          title="สัญชาติ"
          className="font-size-12"
          dataIndex="nationality_name"
        />
        <Table.Column
          title="เลขบัตรประชาชน"
          className="font-size-12"
          dataIndex="id_card"
        />
        <Table.Column
          title="วันหมดอายุ"
          className="font-size-12"
          dataIndex="id_card_expire_date"
          render={(text) => (text ? moment(text).format("DD/MM/YYYY") : "-")}
        />
        <Table.Column
          title="เลขพาสปอร์ต"
          className="font-size-12"
          dataIndex="passport_id"
        />
        <Table.Column
          title="วันหมดอายุ"
          className="font-size-12"
          dataIndex="passport_expire_date"
          render={(text) => (text ? moment(text).format("DD/MM/YYYY") : "-")}
        />
        <Table.Column
          title="เพศ"
          className="font-size-12"
          dataIndex="gender"
          render={(text) => (text === "F" ? "หญิง" : "ชาย")}
        />
        <Table.Column
          title="โทรศัพท์"
          className="font-size-12"
          dataIndex="mobile"
        />
        <Table.Column
          title="อีเมล"
          className="font-size-12"
          dataIndex="email"
        />
        <Table.Column
          title="Facebook"
          className="font-size-12"
          dataIndex="facebook"
        />
        <Table.Column
          title="Line"
          className="font-size-12"
          dataIndex="line_id"
        />
        {active === "member" && (<Table.Column
          title="สถานะ"
          dataIndex="status_name"
          align="center"
          className="f-s-12"
        />)
        }
        {active === "member" && (<Table.Column
          title="คำนวณภาษี"
          className="font-size-12"
          dataIndex="company_register_vat"
          render={(v) => v === 0 ? "ไม่ใช่" : "ใช่"}
        />)
        }
        {active === "member" && (
          <Table.Column
            title="ประเภทธุรกิจ"
            className="font-size-12"
            dataIndex="company_type_text"
            render={(company) =>
              company === "" || company == null ? "บุคคลธรรมดา" : company
            }
          />
        )}
        {active === "member" && (
          <Table.Column
            title="ชื่อนิติบุคคล(ไทย)"
            className="font-size-12"
            dataIndex="company_name"
          />
        )}
        {active === "member" && (
          <Table.Column
            title="ชื่อนิติบุคคล(อังกฤษ)"
            className="font-size-12"
            dataIndex="company_name_en"
          />
        )}
        {active === "member" && (
          <Table.Column
            title="เลขประจำตัวผู้เสียภาษี"
            className="font-size-12"
            dataIndex="company_tax"
          />
        )}
        {active === "married" && (
          <Table.Column
            title="สถานะเอกสาร"
            className="font-size-12"
            dataIndex="status"
            render={(text) => (text == "Active" ? "ผ่าน" : "ไม่ผ่าน")}
          />
        )}
        {active === "married" && (
          <Table.Column
            title="สถานะ"
            className="font-size-12"
            dataIndex="status"
            render={(text) => (text == "In-Active" ? "ยกเลิก" : "ปกติ")}
          />
        )}
        <Table.Column
          title="วันแก้ไข"
          className="font-size-12"
          dataIndex="modify_date"
          render={(text) =>
            text ? moment(text).format("DD/MM/YYYY HH:mm") : "-"
          }
        />
        <Table.Column
          title="แก้ไขโดย"
          className="font-size-12"
          dataIndex="modify_by"
        />
            <Table.Column
          title="เลขประจำตัว/วันหมดอายุ"
          className="font-size-12"
          dataIndex="not_require_id_card"
          render={(text) => (text ? "ไม่ระบุ" : "ระบบุ")}

        />
      </Table>
    </Modal>
  );
}
