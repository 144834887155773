import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";

export const RoleContext = createContext();

export const initState = {
  searching: false,
  saving: false,
  filter: {
    page: 1,
    limit: 10,
    query: "",
  },
  modal: {
    visible: false,
    title: "",
    data: null,
  },
};

export const reducer = createReducer(initState, {
  SET_SEARCHING: (state, { payload }) => {
    state.searching = payload;
  },
  SET_FILTER_VALUE: (state, { payload }) => {
    state.filter = { ...state.filter, ...payload };
  },
  SET_PAGE: (state, { payload }) => {
    state.filter.page = payload;
  },
  SET_MODAL: (state, { payload }) => {
    state.modal = { ...state.modal, ...payload };
  },
  SET_SAVING: (state, { payload }) => {
    state.saving = payload;
  },
});

export default RoleContext;
