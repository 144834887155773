import { Button, Col, Modal, Row, Table, Typography } from "antd";
import numeral from "numeral";
import React, { memo, useContext } from "react";
import { FaCheck, FaFileDownload, FaTimes } from "react-icons/fa";
import XLSX from "xlsx";
import { moment, _ } from "../../../../../utils";
import ReceiveContext from "../reducer";

const { Text } = Typography;
const { Column } = Table;

const ResultModal = () => {
  const {
    state: {
      result: { page, limit, success_qty, failed_qty, inserted },
    },
    dispatch,
  } = useContext(ReceiveContext);

  const handleClose = () =>
    dispatch({
      type: "clearGlobalState",
      payload: "result",
    });

  return (
    <Modal
      visible={!!inserted}
      title="ผลการนำเข้าข้อมูล"
      footer={false}
      width={1000}
      zIndex={1080}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleClose}
    >
      {failed_qty > 0 && (
        <Row gutter={[8, 8]} className="mb-2">
          <Col span={24} className="text-right">
            <Button
              type="danger"
              onClick={() => {
                const failed_data = _.filter(
                  inserted,
                  (n) => n.status !== "success"
                );
                const ws = XLSX.utils.json_to_sheet(failed_data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "item-failed");
                XLSX.writeFile(
                  wb,
                  `item-failed${moment().format("YYYY-MM-DD_HH-mm-ss")}.xlsx`
                );
              }}
            >
              <FaFileDownload className="mr-2" />
              Export Item Failed
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={[8, 8]} className="mb-2">
        <Col span={12} className="d-flex">
          <div
            className={
              "rounded-circle width-20 height-20 d-flex justify-content-center align-items-center bg-pv mr-2"
            }
            size="small"
          >
            <FaCheck className="text-white" />
          </div>
          <Text>นำเข้าสำเร็จ:</Text>
          <Text className="mx-2 text-pv" strong>
            {numeral(success_qty).format("0,0")}
          </Text>
          <Text>รายการ</Text>
        </Col>
        <Col span={12} className="d-flex">
          <div
            className={
              "rounded-circle width-20 height-20 d-flex justify-content-center align-items-center bg-nn mr-2"
            }
            size="small"
          >
            <FaTimes className="text-white" />
          </div>
          <Text>นำเข้าไม่สำเร็จ:</Text>
          <Text className="mx-2 text-nn" strong>
            {numeral(failed_qty).format("0,0")}
          </Text>
          <Text>รายการ</Text>
        </Col>
      </Row>

      <Table
        bordered
        dataSource={inserted}
        size="small"
        rowKey="internal_id"
        scroll={{ x: "max-content" }}
        pagination={{
          current: page,
          pageSize: limit,
          total: inserted?.length || 0,
          showLessItems: true,
          hideOnSinglePage: true,
          onChange: (page) =>
            dispatch({
              type: "setGlobalState",
              payload: {
                name: "result",
                value: {
                  page,
                },
              },
            }),
        }}
      >
        <Column
          width={100}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">Internal Id</div>
          }
          key="internal_id"
          dataIndex="internal_id"
        />
        <Column
          width={150}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">Item Code</div>
          }
          key="product_id"
          dataIndex="product_id"
        />
        <Column
          width={200}
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">คลัง</div>}
          key="branch_name"
          dataIndex="branch_name"
        />
        <Column
          width={150}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">รหัสสินค้า</div>
          }
          key="product_code"
          dataIndex="product_code"
        />
        <Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ชื่อสินค้า</div>
          }
          key="product_name"
          dataIndex="product_name"
          render={(text) => (
            <div className="min-width-100 max-width-600">{text}</div>
          )}
        />
        <Column
          width={100}
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">จำนวน</div>}
          key="quantity"
          dataIndex="quantity"
          render={(text) => numeral(text).format("0,0")}
          align="right"
        />
        <Column
          width={120}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">สถานะนำเข้า</div>
          }
          key="status"
          dataIndex="status"
          render={(text) => (
            <div className="d-flex">
              <div
                className={`rounded-circle width-20 height-20 d-flex justify-content-center align-items-center ${
                  text === "success" ? "bg-pv" : "bg-nn"
                }`}
                size="small"
              >
                {text === "success" ? (
                  <FaCheck className="text-white" />
                ) : (
                  <FaTimes className="text-white" />
                )}
              </div>
              <Text className="ml-2">
                {text === "success" ? "สำเร็จ" : "ไม่สำเร็จ"}
              </Text>
            </div>
          )}
        />
        <Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">Error message</div>
          }
          key="error_message"
          dataIndex="error_message"
          render={(text) => (
            <div className="min-width-100 max-width-800">{text}</div>
          )}
        />
      </Table>
    </Modal>
  );
};

export default memo(ResultModal);
