import { Tabs } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../hooks/http2";
import {
  actionMember,
  addressActiveSelector,
} from "../../../../redux/slices/member";
import { URL_API, _ } from "../../../../utils";
import ListAddress from "./ListAddress";

export default function AddressPanel() {
  const dispatch = useDispatch();
  const active = useSelector(addressActiveSelector);
  const { SET_ADDRESS_ACTIVE } = actionMember;

  const address_type = useApi(
    {
      baseURL: URL_API,
      url: `/global/master/all-type`,
      token: true,
      params: { process_type: "address_type" },
    },
    []
  );
  return (
    <Tabs
      size="small"
      tabPosition="left"
      activeKey={active}
      onChange={(e) => dispatch(SET_ADDRESS_ACTIVE(e))}
    >
      {_.map(address_type.fetch?.data || [],
        // _.filter(address_type.fetch?.data || []),
        (n) => (
          <Tabs.TabPane tab={n.name} key={n.code}>
            <ListAddress />
          </Tabs.TabPane>
        )
      )}
    </Tabs>
  );
}
