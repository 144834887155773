import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  Button,
  Col,
  Row,
  message,
  Typography,
  Upload,
  Icon,
  Spin,
} from "antd";
import moment from "moment";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { createReducer } from "@reduxjs/toolkit";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import { URL_API, axios, getToken } from "../../../../utils";

const initState = {
  loading: false,
  uploading: false,
  data: {
    fileId: null,
    fileName: null,
    newFileName: null,
    fileOBJ: null,
    fileUpload: null,
    errorOver5mb: false,
    error: false,
    errorPDF: false,
    modifyName: null,
    modifyDate: null,
    modifyDepartment: null,
    fileType: null,
    fileCode: null,
  },
};

const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_UPLOADING: (state, { payload }) => {
    state.uploading = payload;
  },
  SET_FILE_NAME: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        fileName: payload,
      },
    };
  },
  SET_FILE_ID: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        fileId: payload,
      },
    };
  },
  SET_FILE_UPLOAD: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        fileUpload: payload,
      },
    };
  },
  SET_FILE_OBJ: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        fileOBJ: payload || null,
      },
    };
  },
  SET_ERROR_5MB: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        errorOver5mb: payload,
      },
    };
  },
  SET_ERROR: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        error: payload,
      },
    };
  },
  SET_ERROR_PDF: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        errorPDF: payload,
      },
    };
  },
  SET_MODIFY_INFO: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        fileType: payload.fileType,
        fileCode: payload.fileCode,
        modifyName: payload.name,
        modifyDate: moment(payload.date).format("DD/MM/YYYY HH:mm"),
        modifyDepartment: payload.department,
      },
    };
  },
  SET_NEW_FILE_NAME: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        newFileName: payload,
      },
    };
  },
});

const FileContent = ({ history, match }) => {
  const [widthSize, setWidthSize] = useState(window.innerWidth <= 980);
  const [state, dispatch] = useReducer(reducer, initState);

  const { loading, uploading, data } = state;
  const {
    fileId,
    newFileName,
    fileName,
    fileOBJ,
    fileUpload,
    errorOver5mb,
    error,
    errorPDF,
    modifyName,
    modifyDate,
    modifyDepartment,
    fileType,
    fileCode,
  } = data;

  const props = {
    name: "file",
    accept: ".pdf",
    showUploadList: false,
    beforeUpload: (file) => {
      dispatch({
        type: "SET_UPLOADING",
        payload: true,
      });
      dispatch({
        type: "SET_NEW_FILE_NAME",
        payload: null,
      });
      dispatch({
        type: "SET_ERROR_PDF",
        payload: false,
      });
      dispatch({
        type: "SET_ERROR_5MB",
        payload: false,
      });
      dispatch({
        type: "SET_ERROR",
        payload: false,
      });

      // check file type
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        dispatch({
          type: "SET_ERROR_PDF",
          payload: true,
        });
        return false;
      }

      // Convert size to MB
      let isSizeValid = file.size / 1024 / 1024 <= 5;
      if (fileCode === "e-catalog" || fileCode === "privilege") {
        isSizeValid = file.size / 1024 / 1024 <= 256;
      }

      if (!isSizeValid) {
        dispatch({
          type: "SET_ERROR_5MB",
          payload: true,
        });
        return false;
      }
      handleUploadFile(file);
    },
  };

  const handleUploadFile = useCallback(async (fileOBJ) => {
    dispatch({
      type: "SET_ERROR",
      payload: false,
    });
    dispatch({
      type: "SET_UPLOADING",
      payload: true,
    });
    const formData = new FormData();
    formData.append("file", fileOBJ); // transfer file to binary
    formData.append("container_name", "forms"); // container's name

    try {
      const res = await axios.post(`/file/upload`, formData, {
        baseURL: URL_API,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      });
      dispatch({
        type: "SET_FILE_UPLOAD",
        payload: res?.data || null,
      });
      dispatch({
        type: "SET_UPLOADING",
        payload: false,
      });
      dispatch({
        type: "SET_ERROR_5MB",
        payload: false,
      });
      dispatch({
        type: "SET_ERROR",
        payload: false,
      });
      dispatch({
        type: "SET_NEW_FILE_NAME",
        payload: fileOBJ?.name || null,
      });
      dispatch({
        type: "SET_FILE_OBJ",
        payload: fileOBJ || null,
      });
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: true,
      });
      dispatch({
        type: "SET_UPLOADING",
        payload: false,
      });
      message.error(error.response?.data?.message || error.message);
    }
  }, []);

  const handleSubmit = useCallback(async (fileId, fileUpload) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    const payload = {
      file_id: fileUpload?.data?.id ? [fileUpload?.data?.id] : [],
    };
    try {
      await axios.post(`/manage-website/manage-upload/${fileId}`, payload, {
        baseURL: URL_API,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      });
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      message.success("บันทึกสำเร็จ!");
      history.push(`/manage-website/manage-file`);
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: true,
      });
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      message.error(error.response?.data?.message || error.message);
    }
  }, []);

  const handleCancel = () => {
    dispatch({
      type: "SET_NEW_FILE_NAME",
      payload: null,
    });
    dispatch({
      type: "SET_FILE_OBJ",
      payload: null,
    });
    history.push(`/manage-website/manage-file`);
  };

  const loadDataCallback = useCallback(async (id) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    try {
      const res = await axios.get(`/manage-website/manage-upload/${id}`, {
        baseURL: URL_API,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      });

      if (res?.data) {
        dispatch({
          type: "SET_FILE_NAME",
          payload: res.data.files[0]?.file_name || null,
        });
        dispatch({
          type: "SET_MODIFY_INFO",
          payload: {
            fileType: res.data.name,
            fileCode: res.data.code,
            name: res.data.update_by || res.data.create_by,
            date: res.data.update_date || res.data.create_date,
            department: res.data.files[0]?.department_name || null,
          },
        });
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      message.error(error.message);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidthSize(window.innerWidth <= 980);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (match?.params?.id) {
      dispatch({
        type: "SET_FILE_ID",
        payload: match.params.id,
      });
      loadDataCallback(match.params.id);
    }
  }, [match.params.id]);

  return (
    <Panel>
      <PanelHeader>จัดการไฟล์อัปโหลด</PanelHeader>
      <PanelBody>
        <Typography style={{ fontWeight: 700, fontSize: "14px" }}>
          {fileType}
        </Typography>
        <Row
          style={{
            padding: "24px 12px",
          }}
        >
          <Col span={24}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Col>
                <Typography
                  style={{
                    padding: "10px 12px 10px 8px",
                    width: "114px",
                    textAlign: "right",
                    fontSize: "14px",
                  }}
                >
                  ไฟล์ที่ใช้งานอยู่ :{" "}
                </Typography>
              </Col>
              <Col>
                <div
                  style={{
                    padding: "12px 8px 12px 8px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "8px",
                  }}
                >
                  <AiOutlineFilePdf style={{ color: "#5A5555" }} />
                  {fileName ? (
                    <Typography style={{ fontSize: "14px" }}>
                      {fileName}
                    </Typography>
                  ) : (
                    <Typography style={{ fontSize: "14px", color: "#CCCBCB" }}>
                      ไม่พบไฟล์ที่ใช้งานอยู่
                    </Typography>
                  )}
                </div>
                <Typography style={{ color: "#CCCBCB", fontSize: "14px" }}>
                  วันที่ทำรายการ : {modifyDate || "dd/mm/yyyy hh:mm"}
                </Typography>
                <Typography style={{ color: "#CCCBCB", fontSize: "14px" }}>
                  ผู้ดำเนินการ :{" "}
                  {modifyName
                    ? `${modifyName} ${
                        modifyDepartment ? `(${modifyDepartment})` : ""
                      }`
                    : "ชื่อจริงสต๊าฟ นามสกุล (แผนก)"}
                </Typography>
              </Col>
            </div>

            <Row
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "12px",
              }}
            >
              <Col>
                <Typography
                  style={{
                    padding: "10px 12px 10px 8px",
                    width: "114px",
                    textAlign: "right",
                    fontSize: "14px",
                  }}
                >
                  ไฟล์ใหม่ :{" "}
                </Typography>
              </Col>
              <Col span={20}>
                <Upload {...props}>
                  <Button
                    type="dashed"
                    style={{
                      width: widthSize ? "100%" : "449px",
                      borderColor:
                        errorPDF || errorOver5mb || error
                          ? "#BA1223"
                          : "#97D700",
                      borderRadius: "18px",
                      minHeight: "33px",
                      height: "fit-content",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "8px, 12px",
                      }}
                    >
                      <Typography
                        style={{
                          marginRight: widthSize ? "8px" : 0,
                          color: newFileName ? "#5A5555" : "#CCCBCB",
                          fontSize: "14px",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "100%",
                          textAlign: "left",
                        }}
                      >
                        {newFileName || "อัปโหลดไฟล์ใหม่ที่นี่"}
                      </Typography>
                      <Icon type="upload" style={{ color: "#5A5555" }} />
                    </div>
                  </Button>
                </Upload>
                {errorPDF ? (
                  <div
                    style={{
                      color: "#840D19",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "12px",
                      marginTop: "7.5px",
                    }}
                  >
                    <AiOutlineCloseCircle />
                    <Typography style={{ color: "#840D19", fontSize: "14px" }}>
                      อัปโหลดไม่สำเร็จ ประเภทของไฟล์ไม่ใช่ .pdf
                    </Typography>
                  </div>
                ) : errorOver5mb ? (
                  <div
                    style={{
                      color: "#840D19",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "12px",
                      marginTop: "7.5px",
                    }}
                  >
                    <AiOutlineCloseCircle />
                    <Typography style={{ color: "#840D19", fontSize: "14px" }}>
                      อัปโหลดไม่สำเร็จ ขนาดไฟล์เกิน{" "}
                      {fileCode === "e-catalog" || fileCode === "privilege"
                        ? "256"
                        : "5"}{" "}
                      mb.
                    </Typography>
                  </div>
                ) : uploading ? (
                  <div
                    style={{
                      color: "#CCCBCB",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "12px",
                      marginTop: "7.5px",
                    }}
                  >
                    <Spin className="custom-spin" size="small" />
                    <Typography style={{ color: "#CCCBCB", fontSize: "14px" }}>
                      กำลังอัปโหลดไฟล์...
                    </Typography>
                  </div>
                ) : error ? (
                  <div
                    style={{
                      color: "#840D19",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "12px",
                      marginTop: "7.5px",
                    }}
                  >
                    <AiOutlineCloseCircle />
                    <Typography style={{ color: "#840D19", fontSize: "14px" }}>
                      ตรวจพบปัญหา กรุณาบันทึกหน้าจอนี้ส่งแจ้งแผนกไอที
                    </Typography>
                  </div>
                ) : newFileName && fileOBJ && !errorOver5mb && !error ? (
                  <div
                    style={{
                      color: "#15B097",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "12px",
                      marginTop: "7.5px",
                    }}
                  >
                    <AiOutlineCheckCircle />
                    <Typography style={{ color: "#15B097", fontSize: "14px" }}>
                      อัปโหลดสำเร็จ
                    </Typography>
                  </div>
                ) : (
                  <Typography style={{ color: "#CCCBCB", fontSize: "14px" }}>
                    *ไฟล์ประเภท .pdf ไม่เกิน{" "}
                    {fileCode === "e-catalog" || fileCode === "privilege"
                      ? "256"
                      : "5"}{" "}
                    mb.
                  </Typography>
                )}
              </Col>
            </Row>
            <div
              style={{
                marginTop: "24px",
              }}
            >
              {newFileName &&
                fileOBJ &&
                !errorPDF &&
                !uploading &&
                !loading &&
                !errorOver5mb &&
                !error && (
                  <Typography
                    style={{
                      textAlign: "center",
                      color: "#BA1223",
                      marginTop: "8px",
                      marginBottom: "20px",
                      fontSize: "14px",
                    }}
                  >
                    *การกด ‘บันทึก’
                    จะเป็นการยืนยันการใช้งานไฟล์ใหม่แทนที่ไฟล์ปัจจุบัน
                    โดยจะแสดงผลที่หน้าเว็บไซต์ทันที
                    กรุณาตรวจสอบความถูกต้องก่อนทำรายการ
                  </Typography>
                )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "24px",
                }}
              >
                <Button
                  type="primary"
                  disabled={
                    newFileName &&
                    fileOBJ &&
                    !errorPDF &&
                    !uploading &&
                    !loading &&
                    !errorOver5mb &&
                    !error
                      ? false
                      : true
                  }
                  onClick={() => handleSubmit(fileId, fileUpload)}
                  style={{
                    width: "143px",
                    borderRadius: "18px",
                  }}
                >
                  บันทึก
                </Button>
                <Button
                  type="default"
                  onClick={() => handleCancel()}
                  style={{
                    width: "143px",
                    borderRadius: "18px",
                    border: "1px solid #840D19",
                    color: "#840D19",
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </PanelBody>
    </Panel>
  );
};

export default FileContent;
