import { Button, Popconfirm } from "antd";
import numeral from "numeral";
import React, { memo, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";

const columns = ["", "รหัส", "การได้รับคะแนนเมื่อใช้บัตรกำนัล", "มูลค่า", ""];

const num = (e) => numeral(e).format("0,0");

const { delClaimOutVoucher } = actionClaim;

const VoucherTable = ({ disabled }) => {
  const dispatch = useDispatch();
  const { itemKeys, itemByKey } = useSelector(
    (state) => state.claim.claimOutVoucher
  );
  const [totalAmount, setTotalAmount] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    let amount = 0;
    const data = _.map(itemKeys, (key) => {
      const { value } = itemByKey[key];
      amount += value;
      return { ...itemByKey[key], key };
    });
    setDataSource(data);
    setTotalAmount(amount);
  }, [itemKeys, itemByKey]);

  return (
    <table className="w-100">
      <thead>
        <tr>
          {_.map(columns, (n, i) => (
            <td key={i} className="text-center">
              {n}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {_.map(dataSource, (n, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td className="text-center">{n.code}</td>
            <td className="text-center">
              {!!n.collect_point ? "ได้รับคะแนน" : "ไม่ได้รับคะแนน"}
            </td>
            <td className="text-center">{num(n.value)}</td>
            <td className="text-center">
              {!disabled && (
                <Popconfirm
                  title="ต้องการลบรายการนี้หรือไม่"
                  disabled={n.status === 0}
                  okText="ลบ"
                  cancelText="ไม่ลบ"
                  placement="topRight"
                  zIndex={1080}
                  onConfirm={() => dispatch(delClaimOutVoucher(n.key))}
                >
                  <Button
                    size="small"
                    shape="circle"
                    type="danger"
                    disabled={n.status === 0}
                  >
                    <FaTrashAlt size={10} />
                  </Button>
                </Popconfirm>
              )}
            </td>
          </tr>
        ))}
        <tr>
          {_.map(columns, (_, i) => i < columns.length - 3 && <td key={i} />)}
          <td className="text-center pb-3 pt-3">
            <strong>รวมมูลค่า</strong>
          </td>
          <td className="text-center pb-3 pt-3">
            <strong>{num(totalAmount)}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default memo(VoucherTable);
