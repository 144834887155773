/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Button, Col, DatePicker, Input, Row, Table, Typography } from "antd";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { FaCogs, FaEye, FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { useApi } from "../../../../hooks/http2";
import {
  actionStoreQrTransfer,
  searchSelector,
} from "../../../../redux/slices/store-qr/transfer";
import { getUser, moment, URL_API } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import SelectBranch from "../components/SelectBranch";

const TransferList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [branch, setBranch] = useState("");
  const dispatch = useDispatch();

  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionStoreQrTransfer;

  const list = useApi(
    {
      url: `${URL_API}/store-qr/transfer`,
      token: true,
      params: {
        ...search,
        start_date: search.start_date
          ? new Date(moment(search.start_date).format("YYYY-MM-DD"))
          : null,
        end_date: search.end_date
          ? new Date(moment(search.end_date).format("YYYY-MM-DD"))
          : null,
        offset:(search.limit * search.page) - search.limit
      },
    },
    [search]
  );

  useEffect(() => {
    let user = getUser();
    dispatch(SET_SEARCH({ branch: user.branch_code }))
    setBranch(user.branch_code);
  }, [match.path]);

  return (
    <Panel>
      <PanelHeader>โอนย้ายสินค้าจัดเซ็ต</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="d-flex align-items-center mb-2">
          <Col md={8}>
            <Input.Search
              placeholder="รหัสสินค้า, ชื่อสินค้า, Lot., QR Code หลัก, QR Code ย่อย"
              defaultValue={search.query}
              onSearch={(e) => dispatch(SET_SEARCH({ query: e }))}
              allowClear
            />
          </Col>
          <Col md={4} className="text-right">
            <Typography.Text>วันที่ทำการรับ:</Typography.Text>
          </Col>
          <Col md={4}>
            <DatePicker className="w-100"
              format="DD/MM/YYYY"
              placeholder = "วันที่ทำการรับเริ่มต้น"
              value={search.start_date}
              onChange={(e) => dispatch(SET_SEARCH({ start_date: e}))}
            />
          </Col>
          <Col md={4}>
            <DatePicker className="w-100" 
              format="DD/MM/YYYY"
              placeholder = "วันที่ทำการรับสิ้นสุด"
              value={search.end_date}
              onChange={(e) => dispatch(SET_SEARCH({ end_date: e }))}
            />
            
          </Col>
          <Col md={4} className="text-right">
            <Button
              type="primary"
              onClick={() => history.push(`${match.path}/0`)}
            >
              รับสินค้าชุดเซ็ต
            </Button>
          </Col>
        </Row>
        <Row gutter={16} className="d-flex align-items-center mb-3">
          <Col md={4} className="text-right">
            <Typography.Text>สาขาที่รับ:</Typography.Text>
          </Col>
          <Col md={4}>
            <SelectBranch
              className="w-100"
              withAll
              allText="ทั้งหมด"
              value={search.branch}
              onChange={(e) => dispatch(SET_SEARCH({ branch: e }))}
            />
          </Col>
          <Col md={4} className="text-right">
            <Typography.Text>รับจากสาขา:</Typography.Text>
          </Col>
          <Col md={4}>
            <SelectBranch
              className="w-100"
              withAll
              allText="ทั้งหมด"
              value={search.from_branch}
              onChange={(e) => dispatch(SET_SEARCH({ from_branch: e }))}
            />
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          dataSource={list.fetch?.data || []}
          rowKey="id"
          pagination={{
            size: "default",
            total: list.fetch?.total || 0,
            pageSize: search.limit,
            current: search.page,
            showLessItems: true,
            showTotal: (total, range) =>
              `${range[0]} - ${range[1]} of ${total}`,
            onChange: (p) => dispatch(SET_SEARCH({ page: p})),
          }}
        >
          <Table.Column
            title="No."
            key="no"
            render={(text, record, index) => index + 1}
          />
          <Table.Column
            title="วันที่ทำการรับ"
            dataIndex="create_date"
            align="center"
            render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
          />
          <Table.Column
            title="สาขาที่รับสินค้าเซ็ต"
            dataIndex="branch_name"
            align="center"
          />
          <Table.Column
            title="รับจากสาขา"
            dataIndex="from_branch_name"
            align="center"
          />
          <Table.Column
            title="จำนวน (เซ็ต)"
            align="right"
            dataIndex="qty"
            render={(text) => numeral(text).format()}
          />
          <Table.Column
            title="ผู้ดำเนินการ"
            dataIndex="create_by"
            align="center"
          />
          <Table.Column
            title="สถานะ"
            dataIndex="status"
            align="center"
          />
          <Table.Column
            title={<FaCogs />}
            align="right"
            render={(text, record) => (
              <>
                {(record.status == "draft" && record.branch_code == branch) && (
                <Button
                  type="default"
                  size="small"
                  shape="circle-outline"
                  className="mr-2"
                  onClick={() => history.push(`${match.path}/${record.id}`)}
                >
                  <FaPencilAlt />
                </Button>
                )}

                <Button
                  type="primary"
                  size="small"
                  shape="circle-outline"
                  onClick={() =>
                    history.push(`${match.path}/${record.id}/view`)
                  }
                >
                  <FaEye />
                </Button>
              </>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default TransferList;
