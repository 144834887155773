import { Button, Col, Input, message, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { FaCog, FaCogs, FaTrash } from "react-icons/fa";
import { axios, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";

const initState = {
  id_th: null,
  id_en: null,
  code: "",
  name_th: "",
  name_en: "",
};

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [dataForm, setDataForm] = useState(initState);
  const [search, setSearch] = useState("");

  const getData = (search) => {
    setSearch(search);

    axios({
      method: "get",
      url: `${URL_API}/setting/master/prefix`,
      params: { search },
    })
      .then((res) => {
        setDataSource(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          err && err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.response.data
            : err.message
        );
      });
  };

  const handleClickSave = (data) => {
    if (data.name_th && data.name_en && data.code) {
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ยกเลิก",
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/setting/master/prefix`,
              data: {
                ...data,
              },
            })
              .then((res) => {
                resolve(res.data);
              })
              .catch((err) => {
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then((value) => {
              setShowModal(false);
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกข้อมูลเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  getData(search);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    } else {
      message.warning("กรุณากรอกข้อมูลรหัสคำนำหน้าและคำนำหน้า");
    }
  };

  const handleClickDelete = (data) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการลบข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/setting/master/prefix/delete`,
            data: {
              ...data,
            },
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            setShowModal(false);
            Modal.success({
              title: "สำเร็จ",
              content: "ลบข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                getData(search);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    getData(search);
  }, [search]);

  return (
    <Panel>
      <PanelHeader>คำนำหน้าชื่อ</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col md={8}>
            <Input.Search
              placeholder="ค้นหา"
              onChange={(e) => setSearch(e.target.value)}
              onSearch={() => getData(search)}
            />
          </Col>
          <Col md={16} className="d-flex justify-content-end">
            <Button
              type="primary"
              onClick={() => {
                setShowModal(true);
                setDataForm(initState);
              }}
            >
              เพิ่มคำนำหน้า
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          rowKey="id_th"
          loading={loading}
          dataSource={dataSource ? dataSource : []}
          pagination={false}
        >
          <Table.Column title="รหัสคำนำหน้า" dataIndex="code_th" />
          <Table.Column title="คำนำหน้า (ภาษาไทย)" dataIndex="name_th" />
          <Table.Column title="คำนำหน้า (ภาษาอังกฤษ)" dataIndex="name_en" />
          <Table.Column
            align="center"
            title={<FaCogs />}
            render={({ id_th, id_en, code_th, name_th, name_en }) => (
              <>
                <Button
                  size="small"
                  shape="circle-outline"
                  type="primary"
                  className="mr-2"
                  onClick={() => {
                    setShowModal(true);
                    setDataForm({
                      ...dataForm,
                      id_th,
                      id_en,
                      code: code_th,
                      name_th,
                      name_en,
                    });
                  }}
                >
                  <FaCog />
                </Button>

                <Button
                  size="small"
                  shape="circle-outline"
                  type="danger"
                  onClick={() => {
                    setDataForm({
                      ...dataForm,
                      id_th,
                      id_en,
                      code: code_th,
                      name_th,
                      name_en,
                    });
                    handleClickDelete(dataForm);
                  }}
                >
                  <FaTrash />
                </Button>
              </>
            )}
          />
        </Table>

        <Modal
          title={dataForm.id_th ? "แก้ไขคำนำหน้า" : "เพิ่มคำนำหน้า"}
          visible={showModal}
          onCancel={() => setShowModal(false)}
          onOk={() => handleClickSave(dataForm)}
          okText="บันทึก"
          cancelText="ยกเลิก"
        >
          <Row gutter={[8, 16]}>
            <Col>
              <label>รหัสคำนำหน้า</label>
              <Input
                name="code"
                value={dataForm.code}
                onChange={(e) =>
                  setDataForm({ ...dataForm, code: e.target.value })
                }
              />
            </Col>
            <Col>
              <label>คำนำหน้า (ภาษาไทย)</label>
              <Input
                name="name th"
                value={dataForm.name_th}
                onChange={(e) =>
                  setDataForm({ ...dataForm, name_th: e.target.value })
                }
              />
            </Col>
            <Col>
              <label>คำนำหน้า (ภาษาอังกฤษ)</label>
              <Input
                name="name en"
                value={dataForm.name_en}
                onChange={(e) =>
                  setDataForm({ ...dataForm, name_en: e.target.value })
                }
              />
            </Col>
          </Row>
        </Modal>
      </PanelBody>
    </Panel>
  );
};

export default Index;
