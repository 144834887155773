import { createSlice } from "@reduxjs/toolkit";

const initState = {
  search: {
    query: "",
    branch: "",
    start_date: null,
    end_date: null,
    page: 1,
    limit: 10,
    req_receipt_type: "",
  },
};

const storeQrBillSlice = createSlice({
  name: "bill",
  initialState: initState,
  reducers: {
    SET_ACTIVE: (state, { payload }) => {
      state.active = payload;
      state.search = {
        ...initState.search,
      };
    },
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
  },
});

export const actionStoreQrBill = storeQrBillSlice.actions;

export const searchSelector = (state) => state.storeQR.bill.search;

const StoreQrBillReducer = storeQrBillSlice.reducer;

export default StoreQrBillReducer;
