/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Table,
  Input,
  DatePicker,
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import * as XLSX from "xlsx-js-style";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { ImPriceTag } from "react-icons/im";
import { useHttp } from "../../../../../hooks/http";
import { axios, getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import FilterList from "../../components/FilterList";
import { BsSearch } from "react-icons/bs";
import SaleSummaryContext, {
  reducer,
} from "../../contexts/summary-context";
import _ from "lodash";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectAllType from "../../../../../components/SelectAllType";
const { Search } = Input;

const Index = () => {
  const user = getUser();
  const toDay = moment().format("DD/MM/YYYY");
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: user ? user?.branch_code : "",
      branch_name: user ? user?.branch_name : "",
      query: "",
      page: 1,
      limit: 50,
      buyer_type: "",
    },
    summaryModal: {
      visible: false,
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [totalVoucher, setTotalVoucher] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 20;
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  const [openModalVocher, setOpenModalVoucher] = useState(false);
  const [modalVoucherData, setModalVoucherData] = useState([]);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-payment`,
      token: true,
      params: {
        ...state.filter,
        limit: limit,
        offset: limit * (page - 1),
        userId: user.id,
        branch: state.filter.branch,
        query: state.filter.query,
        // ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  function checkPaymentType(text) {
    if (text === "CC") {
      return "Credit Card";
    } else if (text === "DC") {
      return "Debit Card";
    } else if (text === "TP") {
      return "Transfer Payment";
    } else if (text === "INS") {
      return "Installment";
    } else if (text === "QR Payment" || text === "QR") {
      return "QR Payment";
    } else if (text === "QRCR") {
      return "QR Credit";
    } else if (text === "RC") {
      return "Rabbit Card";
    } else if (text === "LP") {
      return "Line Pay";
    } else if (text === "redeem" || text === "RP") {
      return "มูลค่า Redeem";
    } else if (text === "Voucher") {
      return "บัตรกำนัล";
    }
    return text;
  }

  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานการรับชำระเงิน"],
        [
          `วันที่เปิดบิล : จาก ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `สาขา : ${
            state.filter.branch === "" ? "ทั้งหมด" : state.filter.branch_name
          } `,
          `วันที่ดึงข้อมูล: ${moment().format("DD/MM/YYYY , HH:mm")}`,
          "",
          "",
          `ประเภทผู้ซื้อ : ${
            state.filter.buyer_type === "" ? "ทั้งหมด" : state.filter.buyer_type
          } `,
        ],
        [`ข้อมูลที่ใช้ค้นหา : ${state.filter.query} `],
        [
          "No.",
          "วันที่",
          "สาขา",
          "ผู้ทำรายการ",
          "เลขอ้างอิงชำระเงิน",
          "เลขอ้างอิง",
          "เลขที่ใบเสร็จรับเงิน",
          "รหัสนักธุรกิจ",
          "ชื่อสมาชิก",
          "ประเภทผู้ซื้อ",
          "บัตรกำนัล",
          //"ส่วนต่างบัตรกำนัล",
          "ส่วนลด",
          "ประเภทส่วนลด",
          "หมายเหตุ",
          "ข้อมูลเพิ่มเติม",
          "จำนวนเงินรวมต่อบิล",
          "Cash",
          "QR Credit",
          "QR (Omise)",
          "QR Payment",
          "Transfer Payment",
          "Debit Card",
          "Credit Card",
          "Credit (Omise)",
          "Installment",
          "Rabbit Card",
          "Line Pay",
          "Other",
          "จำนวนเงินรวมชุด",
          "RP แลก",
          "สถานะ",
        ],
      ];

      const newDataObj = data?.data;
      // const newDataObj = data?.data?.map((n) => {
      //   const voucherList = dataSource?.voucherList;
      //   let voucher = _.filter(
      //     voucherList,
      //     (v) =>
      //       v.document_group === n.document_group
      //   );
      //   let value_voucher = _.sum(voucher?.map((s) => s.value)); // only one row
      //   return n.row_id === "รวมจำนวนเงินทั้งหมด"
      //     ? { ...n, voucher_amount: totalVoucher }
      //     : { ...n, voucher_amount: value_voucher };
      // });

      if (newDataObj) {
        (newDataObj || []).forEach((data) => {
          excelData = [
            ...excelData,
            [
              { v: data.row_id, s: { alignment: { horizontal: "center" } } },
              {
                v:
                  data.document_date === " "
                    ? ""
                    : data.document_date === null
                    ? ""
                    : moment(data.document_date).format("DD/MM/YYYY HH:mm"),
              },
              { v: data.branch_name !== null ? data.branch_name : "" },
              { v: data.cashier_name !== null ? data.cashier_name : "" },
              { v: data.document_group !== null ? data.document_group : "" },
              { v: data.ref_document_no !== null ? data.ref_document_no : "" },
              { v: data.document_no !== null ? data.document_no : "" },
              { v: data.partner_code !== null ? data.partner_code : "" },
              { v: data.partner_name !== null ? data.partner_name : "" },
              { v: data.partner_type !== null ? data.partner_type : "" },
              {
                v:
                  data.voucher_amount !== null || data.voucher_amount > 0
                    ? data.voucher_amount
                    : 0,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.discount_amount === null || data.discount_amount === ""
                    ? 0
                    : data.discount_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              { v: data.discount_name ? data.discount_name : "" },
              { v: data.remark ? data.remark : "" },
              { v: data.more_info ? data.more_info : "" },
              {
                v:
                  data.grand_total_amount === null ||
                  data.grand_total_amount === ""
                    ? 0
                    : data.grand_total_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.cash_amount === null || data.cash_amount === ""
                    ? 0
                    : data.cash_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.qrcr_amount === null || data.qrcr_amount === ""
                    ? 0
                    : data.qrcr_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.qr_omise_amount === null || data.qr_omise_amount === ""
                    ? 0
                    : data.qr_omise_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.qr_amount === null || data.qr_amount === ""
                    ? 0
                    : data.qr_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.tp_amount === null || data.tp_amount === ""
                    ? 0
                    : data.tp_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.dc_amount === null || data.dc_amount === ""
                    ? 0
                    : data.dc_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.cc_amount === null || data.cc_amount === ""
                    ? 0
                    : data.cc_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.credit_omise_amount === null ||
                  data.credit_omise_amount === ""
                    ? 0
                    : data.credit_omise_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.ins_amount === null || data.ins_amount === ""
                    ? 0
                    : data.ins_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.rc_amount === null || data.rc_amount === ""
                    ? 0
                    : data.rc_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.lp_amount === null || data.lp_amount === ""
                    ? 0
                    : data.lp_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.other_amount === null || data.other_amount === ""
                    ? 0
                    : data.other_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.t_amount === null || data.t_amount === ""
                    ? 0
                    : data.t_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v:
                  data.total_redeem === null || data.total_redeem === ""
                    ? 0
                    : data.total_redeem,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v: data?.document_status === null ? "" : data.document_status,
                s: { alignment: { horizontal: "right" } },
              },
              // { v: numeral(data.rp_amount).format("0,0.00") },

              // {v: n.amount === "none" ? "" : numeral(n.amount).format("0,0.00"), s: { alignment: { horizontal: "right" } } },
            ],
          ];
        });
      }

      excelData = [
        ...excelData,
        [""],
        ["รวมจำนวนเงินแบ่งตามประเภท"],
        ["ประเภทการชำระเงิน", "ธนาคาร", "จำนวน"],
      ];
      (data?.dataTotalPayment || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            checkPaymentType(n.pay_in_type),
            //n.pay_in_type === "CC" ? "Credit Card" : n.pay_in_type,
            n.bank_name === null ? "" : n.bank_name,
            {
              v:
                n.total_amount === null || n.total_amount === ""
                  ? 0
                  : n.total_amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 29 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 6 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 6 } },
      ];

      var wscols = [
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      // var wsrows = [
      //   { hpx: 32 }, // "pixels"
      //   { hpx: 20 }, // "pixels"
      //   { hpx: 20 }, // "pixels"
      //   { hpx: 24 }, // "pixels"
      // ];

      // const centerStyles = {
      //   alignment: {
      //     vertical: "center",
      //     horizontal: "center",
      //   },
      //   border: {
      //     top: { style: "thin", color: { rgb: "000000" } },
      //     bottom: { style: "thin", color: { rgb: "000000" } },
      //     left: { style: "thin", color: { rgb: "000000" } },
      //     right: { style: "thin", color: { rgb: "000000" } },
      //   },
      //   fill: { fgColor: { rgb: "E9E9E9" } },
      // };

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      // const ws = XLSX.utils.json_to_sheet(excelData); //------old version

      ws["!cols"] = wscols;
      //ws["!rows"] = wsrows;
      ws["!merges"] = merge;

      // ws["A1"].s = {
      //   font: { sz: 20 },
      //   alignment: {
      //     vertical: "center",
      //     horizontal: "center",
      //   },
      //   fill: { fgColor: { rgb: "E9E9E9" } },
      // };

      // ws["A4"].s = centerStyles;
      // ws["B4"].s = centerStyles;
      // ws["C4"].s = centerStyles;
      // ws["D4"].s = centerStyles;
      // ws["E4"].s = centerStyles;
      // ws["F4"].s = centerStyles;
      // ws["G4"].s = centerStyles;
      // ws["H4"].s = centerStyles;
      // ws["I4"].s = centerStyles;
      // ws["J4"].s = centerStyles;
      // ws["K4"].s = centerStyles;
      // ws["L4"].s = centerStyles;
      // ws["M4"].s = centerStyles;
      // ws["N4"].s = centerStyles;
      // ws["O4"].s = centerStyles;
      // ws["P4"].s = centerStyles;
      // ws["Q4"].s = centerStyles;
      // ws["R4"].s = centerStyles;
      // ws["S4"].s = centerStyles;
      // ws["T4"].s = centerStyles;
      // ws["U4"].s = centerStyles;
      // ws["V4"].s = centerStyles;
      // ws["W4"].s = centerStyles;
      // ws["X4"].s = centerStyles;
      // ws["Y4"].s = centerStyles;
      // ws["Z4"].s = centerStyles;
      // ws["AA4"].s = centerStyles;
      // ws["AB4"].s = centerStyles;
      //ws["AC4"].s = centerStyles;

      // function formatColumn(worksheet, col, fmt) {
      //   const range = XLSX.utils.decode_range(worksheet['!ref'])
      //   // note: range.s.r + 1 skips the header row
      //   for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      //     const ref = XLSX.utils.encode_cell({ r: row, c: col })
      //     if (worksheet[ref] && worksheet[ref].t === 'n') {
      //       worksheet[ref].z = fmt
      //     }
      //   }
      // }
      // const currency = '$0.00'
      // for (let col of [10,11,12]) {
      //   formatColumn(ws, col, currency)
      // }

      XLSX.utils.book_append_sheet(wb, ws, "รายงานการรับชำระเงิน");
      XLSX.writeFile(wb, "รายงานการรับชำระเงิน.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  useEffect(() => {
    setDataSource(data ? data : []);
    if (data) {
      let total_voucher = _.sum(data?.voucherList?.map((n) => n.value));
      setTotalVoucher(total_voucher);
    }
  }, [data]);

  const showCcData = (docGroup, payInType) => {
    const showData = data?.ccData.filter(
      (data) =>
        data.document_group === docGroup && payInType === data.pay_in_type
    );

    setPreview(true);
    setPreviewData(showData);
  };

  const closeModal = () => {
    setPreview(false);
    setTimeout(() => {
      setPreviewData([]);
    }, 300);
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานการรับชำระเงิน</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center align-items-sm-center p-b-10"
          >
            <Col span={10} className="pr-4">
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, ชื่อสมาชิก, เลขที่ใบเสร็จ, เลขที่อ้างอิงชำระเงิน, เลขอ้างอิง, ชื่อพนักงาน, ยอดเงิน"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                //onSearch={reload}
                //onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={1}>
              <Text className="text-right d-block" strong>
                วันที่เปิดบิล
              </Text>
            </Col>

            <Col span={6}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>

            <Col span={7}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center align-items-sm-center p-b-10"
          >
            <Col>
              <Text strong>สาขา</Text>
            </Col>
            <Col span={3}>
              <SelectBranch
                userLogin
                allowDisableBranch
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100"
                withAll={user.branch_count > 1}
                onChange={(value, option) => {
                  dispatch({
                    type: "setFilter",
                    payload: {
                      branch: value, // ค่าที่เลือก
                      branch_name: option?.props.children || "ทั้งหมด", // ชื่อสาขาจาก children
                    },
                  });
                }}
              />
            </Col>
            <Col>
              <Text className="text-right d-block pl-3" strong>
                ประเภทผู้ซื้อ
              </Text>
            </Col>
            <Col span={6}>
              <SelectAllType
                withAll
                process_type="buyer_type"
                labelIndex="code"
                defaultValue={[""]}
                placeholder="เลือกประเภทผู้ซื้อ"
                className="w-100 p-r-5"
                mode="multiple"
                addCustomer
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { buyer_type: e },
                  });
                }}
              />
            </Col>
          </Row>

          {/* <Row>
            <Col span={11}>
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, ชื่อสมาชิก, เลขที่ใบเสร็จ, เลขที่อ้างอิงชำระเงิน, เลขอ้างอิง, ชื่อพนักงาน, ยอดเงิน"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                //onSearch={reload}
                //onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={7}>
              <Row>
                <Col span={6} className="mb-2 pr-2">
                  <Text
                    className="text-center d-block"
                    strong
                    style={{ lineHeight: "32px" }}
                  >
                    วันที่เปิดบิล
                  </Text>
                </Col>
                <Col span={18} className="mb-2">
                  <DatePicker.RangePicker
                    className="w-100"
                    placeholder={[toDay, toDay]}
                    format="DD/MM/YYYY"
                    onChange={(e) =>
                      dispatch({
                        type: "setFilter",
                        payload: {
                          date_from: e[0] || moment(),
                          date_to: e[1] || moment(),
                        },
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row gutter={8} className="ml-4">
                <Col span={12} className="mb-2">
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                    block
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col span={12} className="mb-2">
                  <Button
                    type="primary"
                    icon="file-excel"
                    onClick={exportToExcel}
                    block
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <Row>
                <Col xxl={4} xl={6} lg={8} className="mb-2 pr-2">
                  <Text
                    className="text-center d-block"
                    strong
                    style={{ lineHeight: "32px" }}
                  >
                    สาขา
                  </Text>
                </Col>
                <Col xxl={20} xl={18} lg={16} className="mb-2">
                  <SelectBranch
                    withAll
                    userLogin={true}
                    allowDisableBranch
                    defaultValue={user.branch_code}
                    placeholder="เลือกสาขา"
                    className="w-100 p-r-5"
                    onChange={(e, v) => {
                      dispatch({
                        type: "setFilter",
                        payload: { branch: e, branch_name: v.props.children },
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="pl-2">
                <Col className="mb-2 pr-2">
                  <Text
                    className="text-center d-block"
                    strong
                    style={{ lineHeight: "32px" }}
                  >
                    ประเภทผู้ซื้อ
                  </Text>
                </Col>
                <Col span={6} className="mb-2">
                  <SelectAllType
                    withAll
                    process_type="buyer_type"
                    labelIndex="code"
                    defaultValue={[""]}
                    placeholder="เลือกประเภทผู้ซื้อ"
                    className="w-100 p-r-5"
                    mode="multiple"
                    addCustomer
                    onChange={(e) => {
                      dispatch({
                        type: "setFilter",
                        payload: { buyer_type: e },
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}

          <Table
            className="mt-4"
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource?.data || []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              size: "default",
              onChange: (currPage) => {
                setPage(currPage);
              },
            }}
          >
            <Table.Column title="No." dataIndex="row_id" align="center" />
            <Table.Column
              title="วันที่"
              align="center"
              dataIndex="document_date"
              render={(text) => {
                if (text === " " || text === null) {
                  return "";
                } else {
                  return moment(text).format("DD/MM/YYYY HH:mm");
                }
              }}
            />
            <Table.Column title="สาขา" dataIndex="branch_name" align="center" />
            <Table.Column
              title="ผู้ทำรายการ"
              dataIndex="cashier_name"
              align="center"
              render={(text) => {
                return <Text style={{ textAlign: "left" }}>{text}</Text>;
              }}
            />
            <Table.Column
              title="เลขอ้างอิงชำระเงิน"
              dataIndex="document_group"
              align="center"
            />
            <Table.Column
              title="เลขอ้างอิง"
              dataIndex="ref_document_no"
              align="center"
            />
            <Table.Column
              title="เลขที่ใบเสร็จรับเงิน"
              dataIndex="document_no"
              align="center"
            />
            <Table.Column
              title="รหัสนักธุรกิจ"
              dataIndex="partner_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสมาชิก"
              dataIndex="partner_name"
              align="left"
            />
            <Table.Column title="ประเภทผู้ซื้อ" dataIndex="partner_type" />
            <Table.Column
              title="บัตรกำนัล"
              rowKey="row_id"
              dataIndex="voucher_amount"
              align="right"
              render={(text, record) => {
                const { row_id, document_group, document_no } = record;
                const voucherList = dataSource?.voucherList;
                const voucher = _.filter(
                  voucherList,
                  (n) => n.document_no === document_no
                );

                if (row_id !== "รวมจำนวนเงินทั้งหมด") {
                  if (text > 0) {
                    return (
                      <div className="flex items-center">
                        <span>{numeral(text).format("0,0.00")}</span>
                        <span
                          className="ml-2 cursor-pointer p-2"
                          onClick={() => {
                            setModalVoucherData(voucher || []);
                            setOpenModalVoucher(true);
                          }}
                        >
                          <ImPriceTag />
                        </span>
                      </div>
                    );
                  } else {
                    return " - ";
                  }
                } else {
                  if (text > 0) {
                    return (
                      <div className="flex items-center">
                        <span>{numeral(text).format("0,0.00")}</span>
                        <span
                          className="ml-2 cursor-pointer p-2"
                          onClick={() => {
                            setModalVoucherData(voucherList || []);
                            setOpenModalVoucher(true);
                          }}
                        >
                          <ImPriceTag />
                        </span>
                      </div>
                    );
                  }
                  return numeral(totalVoucher).format("0,0.00");
                }
              }}
            />

            <Table.Column
              title="ส่วนลด"
              dataIndex="discount_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="ประเภทส่วนลด"
              dataIndex="discount_name"
              align="right"
            />
            <Table.Column title="หมายเหตุ" dataIndex="remark" align="right" />
            <Table.Column
              title="ข้อมูลเพิ่มเติม"
              dataIndex="more_info"
              align="right"
            />

            <Table.Column
              title="จำนวนเงินรวมต่อบิล(บาท)"
              dataIndex="grand_total_amount"
              // dataIndex="total_amount"
              align="right"
              render={(text, record) => {
                if (text === "") {
                  return " - ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Cash"
              dataIndex="cash_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="QR Credit"
              dataIndex="qrcr_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "QRCR");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="QR (Omise)"
              dataIndex="qr_omise_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "QR (Omise)");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="QR Payment"
              dataIndex="qr_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "QR");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Transfer Payment"
              dataIndex="tp_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Debit Card"
              dataIndex="dc_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "DC");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Credit Card"
              dataIndex="cc_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "CC");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Credit (Omise)	"
              dataIndex="credit_omise_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "Credit (Omise)");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Installment"
              dataIndex="ins_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "INS");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Rabbit Card"
              dataIndex="rc_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "RC");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Line Pay"
              dataIndex="lp_amount"
              align="right"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "LP");
                      }}
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="Other"
              dataIndex="other_amount"
              align="center"
              render={(text, rec) => {
                if (text === "") {
                  return " ";
                } else if (text !== 0 && rec.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  return (
                    <Text
                      onClick={() => {
                        showCcData(rec.document_group, "Other");
                      }}
                      style={{ textAlign: "right" }} // Align data to right
                    >
                      {numeral(text).format("0,0.00")} <ImPriceTag />{" "}
                    </Text>
                  );
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="จำนวนเงินรวมชุด"
              dataIndex="t_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            <Table.Column
              title="RP แลก"
              dataIndex="total_redeem"
              // dataIndex="rp_amount"
              align="right"
              render={(text) => {
                if (text === "") {
                  return " ";
                }
                return numeral(text).format("0,0.00");
              }}
            />
            {/* <Table.Column
              title="เลขที่บัตรกำนัล"
              align="center"
              render={(text, record) => {
                const { document_group, document_no, partner_code } = record
                const voucherList = dataSource?.voucherList
                const voucher = _.filter(voucherList, (n) => n.document_group === document_group && n.document_no === document_no && n.partner_code === partner_code)
                if (record.row_id !== "รวมจำนวนเงินทั้งหมด") {
                  if (voucher.length > 0) {
                    return (
                      <>
                        <Button onClick={
                          () => {
                            setModalVoucherData(voucher || [])
                            setOpenModalVoucher(true)
                          }
                        }>
                          <div className="flex justify-center items-center">
                            <BsSearch className='mr-2' />
                            แสดงรายการ
                          </div>
                        </Button>
                      </>
                    )
                  } else {
                    return " - "
                  }
                } else {
                  return " - "
                }
              }
              }
            /> */}

            <Table.Column
              title="สถานะ"
              dataIndex="document_status"
              fixed="right"
              align="center"
            />
          </Table>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data?.dataTotalPayment || []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={false}
            // pagination={{
            //   current: 1,
            //   pageSize: data?.dataTotalPayment?.length,
            //   total: data?.dataTotalPayment?.length,
            //   showLessItems: false,
            //   size: "default",
            // }}
          >
            <Table.ColumnGroup title="รวมจำนวนเงินแบ่งตามประเภท" align="left">
              <Table.Column
                title="ประเภทการชำระเงิน"
                dataIndex="pay_in_type"
                align="right"
                render={(text) => checkPaymentType(text)}
              />
              <Table.Column
                title="ธนาคาร"
                dataIndex="bank_name"
                align="right"
                render={(_, record) => {
                  if (
                    record.pay_in_type === "Cash" &&
                    record.bank_name === null
                  ) {
                    return " - ";
                  } else if (
                    record.pay_in_type === "CC" &&
                    record.bank_name === null
                  ) {
                    return "* K-Payment";
                  } else if (record.bank_name === null) {
                    return " - ";
                  } else {
                    return record.bank_name;
                  }
                }}
              />
              <Table.Column
                title="จำนวน"
                dataIndex="total_amount"
                align="right"
                render={(text, record) => {
                  return numeral(text).format("0,0.00");

                  // if (record.pay_in_type === "Voucher") {
                  //   return numeral(totalVoucher).format("0,0.00");
                  //   // if (record.total_amount > 0) {
                  //   //   return numeral(text).format("0,0.00");
                  //   // } else {
                  //   //   let total_voucher = _.sum(dataSource?.voucherList?.map(n => n.value));
                  //   //   return numeral(total_voucher).format("0,0.00");
                  //   // }
                  // } else {
                  //   return numeral(text).format("0,0.00");
                  // }
                }}
              />
            </Table.ColumnGroup>
          </Table>
        </PanelBody>
      </Panel>

      <Modal
        title="รายการบัตรเครดิต"
        visible={preview}
        footer={[]}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <Table dataSource={previewData} pagination={false} rowKey="row_id">
          <Table.Column title="ธนาคาร" dataIndex="bank_name" />
          <Table.Column
            title="เลขบัตร"
            dataIndex="account_number"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
          <Table.Column
            title="จำนวน"
            dataIndex="amount"
            render={(text) => {
              return numeral(text).format("0,0.00") + " บาท";
            }}
          />
        </Table>
      </Modal>

      <Modal
        title="รายการบัตรกำนัล"
        visible={openModalVocher}
        footer={[]}
        onOk={() => setOpenModalVoucher(false)}
        onCancel={() => setOpenModalVoucher(false)}
      >
        <Table
          dataSource={modalVoucherData}
          pagination={false}
          bordered
          rowKey="row_id"
        >
          <Table.Column
            title="รหัสบัตรกำนัล"
            dataIndex="ref_code"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
          <Table.Column
            title="ชื่อบัตรกำนัล"
            dataIndex="name"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
          <Table.Column
            title="มูลค่า"
            dataIndex="amount"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
        </Table>
      </Modal>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
