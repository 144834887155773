import React, { forwardRef } from "react";
import { useApi } from "../../../../hooks/http2";
import { URL_API } from "../../../../utils";
import SelectOption from "../../../../components/SelectOption";
import _ from "lodash";

const SelectDocumentHistory =  ({
  member_id = "",
  labelIndex = "name",
  ...props
}) => {

    const list = useApi(
        {
          url: `${URL_API}/ethics/history/documentlist`,
          method : 'GET',
          token: true,
          params : {
            id : member_id
        }
        },
        [member_id]
      );
  return (
    <SelectOption
      loading={list.loading}
      data={_.map(
        _.filter(list.fetch?.data || []),
        (n) => ({
          value: n.code?.toString(),
          text: n[labelIndex],
        })
      )}
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectDocumentHistory forwardRef={ref} {...props} />;
});
