import React, { forwardRef, useEffect, useState } from "react";
import { Select, Typography } from "antd";
import { useHttp } from "../hooks/http";
import { URL_API, _ } from "../utils";

const SelectBank = (
  {
    className = "",
    withAll = false,
    withIcon = false,
    withCashCard = true,
    ...props
  },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/bank`,
    },
    []
  );

  useEffect(() => {
    if (data?.data) {
      let newData = [...data?.data];
      if (!withCashCard) {
        newData = _.filter(newData, (n) => n.code !== "-1");
      }
      setDataSource(newData);
    }
  }, [data, withCashCard]);

  if (error) return <div>{error}</div>;

  return (
    <Select
      loading={loading}
      className={`w-100 ${className}`}
      dropdownMatchSelectWidth={false}
      onDropdownVisibleChange={(open) => setOpen(open)}
      {...props}
    >
      {withAll ? <Select.Option value="">ทั้งหมด</Select.Option> : null}
      {(dataSource || []).map((n, i) => (
        <Select.Option
          key={i}
          value={n.code}
          source={n}
          className="d-flex align-items-center"
        >
          {withIcon && open && (
            <div
              style={{
                minWidth: "40px",
                width: "40px",
                height: "40px",
                marginRight: "8px",
                backgroundColor: `#${n.color ? n.color : "fff"}`,
                padding: "8px",
                borderRadius: "50%",
              }}
            >
              <img
                className="w-100 h-100 object-fit-cover"
                src={`${URL_API}/public/${
                  n.file_name
                    ? `banks-logo/${n.file_name}.svg`
                    : "images/no-img.jpg"
                }`}
                alt="bank-icon"
              />
            </div>
          )}
          <Typography.Text>{n.name_th}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default forwardRef(SelectBank);
