import { Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import { filterOptionSearch } from "../../../../util/helper/map-data";
import SelectWarehouse from "../SelectWarehouse";
import Layout from "./Layout";

const { setFormValue } = actionRequisition;

const WarehouseCode = ({ disabled }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => {
    const { warehouse_code, warehouse_name } = state.requisition.documentDetail;
    return disabled ? warehouse_name : warehouse_code;
  });
  const warehouse_code_error = useSelector(
    (state) => state.requisition.error.warehouse_code
  );

  return (
    <Layout title="คลังสินค้า :" error={warehouse_code_error}>
      {disabled ? (
        <Typography.Text>{value}</Typography.Text>
      ) : (
        <SelectWarehouse
          allowClear
          showSearch
          placeholder="เลือกคลังสินค้า"
          className="w-100"
          filterOption={filterOptionSearch}
          value={value ?? undefined}
          dropdownMatchSelectWidth={false}
          onChange={(warehouse_code, option) =>
            dispatch(
              setFormValue({
                name: "documentDetail",
                value: {
                  warehouse_code,
                  warehouse_name: option?.props.children || null,
                  branch_code: option?.props.source.branch_code || null,
                  branch_name: option?.props.source.branch_name || null,
                },
                error_name: "warehouse_code",
              })
            )
          }
        />
      )}
    </Layout>
  );
};

export default memo(WarehouseCode);
