import { Select } from "antd";
import React, { memo } from "react";
import { _ } from "../../../../../utils";
import { filterOptionSearch } from "../../../../util/helper/map-data";

const SelectColumn = ({ data, value, onChange, loading }) => (
  <Select
    size="small"
    allowClear
    showSearch
    loading={loading}
    className="w-100"
    dropdownStyle={{ zIndex: 1080 }}
    dropdownMatchSelectWidth={false}
    filterOption={filterOptionSearch}
    value={value}
    onChange={onChange}
  >
    {_.map(data, (n) => (
      <Select.Option key={n.code} value={n.code} source={n}>
        {n.name}
      </Select.Option>
    ))}
  </Select>
);

export default memo(SelectColumn);
