/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, notification, Row, Spin, Typography } from "antd";
import numeral from "numeral";
import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  _,
  axios,
  getToken,
  getUserObj,
  MEMBER_API,
  URL_API,
} from "../../../../utils";
import { resCatch } from "../../../util/helper";
import ModalContext from "../contexts/modal-context";
import PaymentItem from "./PaymentItem";

const init_state = {
  itemKeys: [],
  itemByKey: {},
};

const _model = (key) => {
  return {
    key,
    payment_date: null,
    payment_type: null,
    payment_type_name: null,
    bank: null,
    bank_name: null,
    ref_no: null,
    price: 0,
    commit: false,
    reserve_no: null,
  };
};

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "addItem":
//       let newItemKeys = [...state.itemKeys];
//       let newItemByKey = { ...state.itemByKey };
//       let key = _.uniqueId("payment-");

//       newItemKeys.push(key);
//       newItemByKey[key] = {
//         ..._model(key),
//         ...action.payload,
//       };

//       return {
//         ...state,
//         itemKeys: newItemKeys,
//         itemByKey: newItemByKey,
//       };

//     case "setItemValue":
//       return {
//         ...state,
//         itemByKey: {
//           ...state.itemByKey,
//           [action.payload.key]: {
//             ...state.itemByKey[action.payload.key],
//             [action.payload.name]: action.payload.value,
//           },
//         },
//       };

//     case "loadItem":
//       newItemKeys = [...state.itemKeys];
//       newItemByKey = { ...state.itemByKey };
//       _.forEach(action.payload, (item) => {
//         key = _.uniqueId("payment-");
//         newItemKeys = [...newItemKeys, key];
//         newItemByKey = {
//           ...newItemByKey,
//           [key]: {
//             key,
//             commit: true,
//             ...item,
//           },
//         };
//       });
//       return {
//         ...state,
//         itemKeys: newItemKeys,
//         itemByKey: newItemByKey,
//       };

//     case "clear":
//       return {
//         ..._.cloneDeep(init_state),
//       };

//     default:
//       return state;
//   }
// };
const reducer = (state, action) => {
  switch (action.type) {
    case "addItem":
      // ประกาศใหม่ภายใน case นี้
      let newItemKeys = [...state.itemKeys]; 
      let newItemByKey = { ...state.itemByKey };
      let key = _.uniqueId("payment-");

      newItemKeys.push(key);
      newItemByKey[key] = {
        ..._model(key),
        ...action.payload,
      };

      return {
        ...state,
        itemKeys: newItemKeys,
        itemByKey: newItemByKey,
      };

    case "setItemValue":
      return {
        ...state,
        itemByKey: {
          ...state.itemByKey,
          [action.payload.key]: {
            ...state.itemByKey[action.payload.key],
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case "loadItem":
      // ประกาศใหม่ภายใน case นี้
      let newItemKeysLoad = [...state.itemKeys]; 
      let newItemByKeyLoad = { ...state.itemByKey };
      _.forEach(action.payload, (item) => {
        let key = _.uniqueId("payment-");
        newItemKeysLoad.push(key);
        newItemByKeyLoad[key] = {
          key,
          commit: true,
          ...item,
        };
      });
      return {
        ...state,
        itemKeys: newItemKeysLoad,
        itemByKey: newItemByKeyLoad,
      };

    case "clear":
      return {
        ..._.cloneDeep(init_state),
      };

    default:
      return state;
  }
};


const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const initPoint = { rp_hold: 0, rp_topup: 0 };

const BillSummary = ({
  clickCancel,
  orders = [],
  id = 0,
  status = "",
  cashierName,
  reload,
  returnWaitTab,
  newRemark = [],
  documentNo = "",
  reserveType,
  handlePreview,
  hasStock,
  buyerCode,
  buyerType,
  toMainMenu,
  reserveId,
}) => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const list = orders.slice(0, orders.length - 1);
  const [state, dispatch] = useReducer(reducer, init_state);
  const [point, setPoint] = useState(initPoint);
  const [loading, setLoading] = useState(false);
  const [paymentGateWay, setPaymentGateWay] = useState(null);
  const [kBankCustomerId, setKBankCustomerId] = useState("");
  const all_point = point.rp_topup + point.rp_hold;
  const total_amount = _.reduce(
    list,
    (sum, n) => sum + numeral(n.grand_total_amount).value(),
    0
  );
  const reserveRedeem = _.includes(["reserve", "pre-redeem"], reserveType);
  const total_payment = _.reduce(
    state.itemByKey,
    (sum, n) => sum + numeral(n.price).value(),
    0
  );
  let find_distributor = list?.find(
    (element) => element.partner_type.toLowerCase() == "distributor"
  );
  const isConfirm = status.toLowerCase() === "waiting for confirm";

  const max_now_amount =
    numeral(total_amount).value() - numeral(total_payment).value();

  const sendPayment = async () => {
    if (_.some(state.itemKeys, (key) => !state.itemByKey[key].commit)) {
      notification.warning({
        message: "กรุณาตรวจสอบรายการชำระเงิน",
        description: "กรุณาทำการบันทึกรับเงิน",
      });
    } else if (max_now_amount) {
      notification.warning({
        message: "กรุณาตรวจสอบยอดรับชำระ",
        description: `ยอดรับชำระคงเหลือ ${numeral(max_now_amount).format(
          "0,0.00"
        )} บาท`,
      });
    } else {
      let source = CancelToken.source();
      Modal.confirm({
        title: "ยืนยันบันทึกข้อมูล",
        content: `เลขที่ชุดจอง ${documentNo} ?`,
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/pos/operation/reserve/${id}/send`,
              data: {
                remark: newRemark,
              },
              headers:
                getToken() == null
                  ? null
                  : { Authorization: "Bearer " + getToken() },
              cancelToken: source.token,
            })
              .then(() => {
                resolve();
                if (reserveId && toMainMenu) return toMainMenu();
                modalDispatch({
                  type: "setReserveModal",
                  payload: { showReserve: false },
                });
                reload();
                notification.success({
                  message: "Success",
                  description: "บันทึกรายการเรียบร้อย",
                  placement: "topRight",
                });
              })
              .catch((e) => {
                reject(e);
              });
          }).catch((e) => {
            Modal.error({
              title: "ผิดพลาด",
              content: e.response?.data?.message || e.message,
              okText: "ตกลง",
              zIndex: 1080,
            });
          });
        },
        onCancel() {
          source.cancel("Operation canceled by the user.");
        },
      });
    }
  };

  const confirmPayment = async () => {
    if (max_now_amount) {
      notification.warning({
        message: "กรุณาตรวจสอบยอดรับชำระ",
        description: `ยอดรับชำระคงเหลือ ${numeral(max_now_amount).format(
          "0,0.00"
        )} บาท`,
      });
    } else {
      const user = getUserObj();
      let source = CancelToken.source();
      Modal.confirm({
        title: "ยืนยันอนุมัติ",
        content: `เลขที่ชุดจอง ${documentNo}?`,
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/pos/operation/reserve/${id}/confirm`,
              data: {
                remark: newRemark,
                bill_branch_code: user.branch_code,
                bill_branch_name: user.branch_name,
              },
              headers: { Authorization: "Bearer " + getToken() },
              cancelToken: source.token,
            })
              .then((res) => {
                resolve();
                if (reserveId && toMainMenu) return toMainMenu();
                modalDispatch({
                  type: "setReserveModal",
                  payload: { showReserve: false },
                });
                reload();
                notification.success({
                  message: "Success",
                  description: "บันทึกรายการเรียบร้อย",
                  placement: "topRight",
                });

                // const bill_list = res.data.data || [];
                // for (let i = 0; i < bill_list.length; i++) {
                //   const bill = bill_list[i];

                //   const url = `${REPORT_API}/receipt?document_no=${bill.document_no}&group_type=2`;
                //   window.open(url, "_blank");
                // }
              })
              .catch((e) => {
                reject(e);
              });
          }).catch((e) => {
            Modal.error({
              title: "ผิดพลาด",
              content: e.response?.data?.message || e.message,
              okText: "ตกลง",
              zIndex: 1080,
            });
          });
        },
        onCancel() {
          source.cancel("Operation canceled by the user.");
        },
      });
    }
  };

  useEffect(() => {
    axios({ url: `${MEMBER_API}/kbank/config` })
      .then((res) => setPaymentGateWay(res.data))
      .catch((e) => resCatch(e));
  }, []);

  useEffect(() => {
    axios({
      url: `${URL_API}/pos/master/member/customer-id`,
      params: { partner_code: buyerCode, partner_type: buyerType },
    })
      .then((res) => res.data.data && setKBankCustomerId(res.data.data))
      .catch((e) => resCatch(e));
  }, [buyerCode, buyerType]);

  useEffect(() => {
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  const loadData = () => {
    const reserve_no = list[0].reserve_no;
    setLoading(true);
    axios({
      baseURL: URL_API,
      url: "/pos/operation/reserve/payment",
      params: {
        reserve_no,
      },
    })
      .then((res) => {
        let data = res.data.data;
        if (data) {
          dispatch({ type: "loadItem", payload: data });
        }
      })
      .catch((err) => {
        resCatch(err);
      })
      .finally(() => {
        setLoading(false);
      });
    reserveRedeem &&
      axios({
        baseURL: URL_API,
        url: "/pos/master/member/point",
        params: {
          partner_code: orders[0].partner_code,
        },
      })
        .then((res) => {
          const { data } = res.data;
          if (data) {
            const { rp_hold, rp_topup } = data;
            setPoint({ rp_hold, rp_topup });
          }
        })
        .catch((err) => {
          resCatch(err);
        });
  };

  useEffect(() => {
    const reserve_no = list[0]?.reserve_no;
    reserve_no && loadData();
  }, [list[0]?.reserve_no, status]);

  const reloadPayment = () => {
    dispatch({ type: "clear" });
    loadData();
  };

  const handleAddPayment = () => {
    const is_not_commit_all = _.some(
      state.itemKeys,
      (key) => !state.itemByKey[key].commit
    );
    if (max_now_amount === 0) {
      notification.warning({
        message: "ไม่สามารถเพิ่มวิธีชำระเงินได้",
        description: "จำนวนเงินที่ชำระครบแล้ว",
      });
    } else if (is_not_commit_all) {
      notification.warning({
        message: "ไม่สามารถเพิ่มวิธีชำระเงินได้",
        description: "กรุณาทำรายการบันทึกรับเงิน",
      });
    } else {
      let payload = { reserve_no: orders[0].reserve_no };
      if (reserveRedeem) {
        payload = {
          ...payload,
          payment_type: "redeem",
          payment_type_name: "RP",
        };
      }
      dispatch({
        type: "addItem",
        payload,
      });
    }
  };

  return (
    <>
      <Row gutter={8} className="bg-gray-60 py-1" style={{ fontSize: "16px" }}>
        <Col className="pl-4">
          <Typography.Text className="text-white">รายการบิล</Typography.Text>
        </Col>
      </Row>

      <table className="w-100 my-2" style={{ fontSize: "12px" }}>
        <thead>
          <tr className="text-dark">
            <th></th>
            <th className="text-center">รหัสนักธุรกิจ</th>
            <th className="text-center">ชื่อ</th>
            <th className="text-center">ประเภทการรับของ</th>
            <th className="text-center">ยอดรวม</th>
            {find_distributor ? (
              <>
                <th className="text-center">PV</th>
                <th className="text-center">RP</th>
                <th className="text-center">EP</th>
                <th className="text-center">TP</th>
              </>
            ) : (
              <th className="text-center">RP</th>
            )}
          </tr>
        </thead>
        <tbody>
          {list.map((n, i) => (
            <tr key={i}>
              <td className="text-center">{i + 1}</td>
              <td>{n.partner_code}</td>
              <td>{n.partner_name}</td>
              <td className="text-center">
                {n.receive_type.toLowerCase() === "branch" &&
                n.transport_code == null
                  ? "รับที่สาขา"
                  : "จัดส่ง"}
              </td>
              <td className="text-center">
                {numeral(n.grand_total_amount).format("0,0.00")}
              </td>
              {find_distributor ? (
                <>
                  <td className="text-center">
                    {n.partner_type.toLowerCase() != "member" ? numeral(n.total_point_1).format("0,0.00") : numeral(0).format("0,0.00") }
                  </td>
                  <td className="text-center">
                    {numeral(n.total_point_2).format("0,0.00")}
                  </td>
                  <td className="text-center">
                  {n.partner_type.toLowerCase() != "member" ? numeral(n.total_point_9).format("0,0.00") : numeral(0).format("0,0.00") }
                  </td>
                  <td className="text-center">
                  {n.partner_type.toLowerCase() != "member" ? numeral(n.total_point_7).format("0,0.00") : numeral(0).format("0,0.00") }
                  </td>
                </>
              ) : (
                <td className="text-center">
                  {numeral(n.total_point_2).format("0,0.00")}
                </td>
              )}
            </tr>
          ))}
          {list.length > 0 ? (
            <tr>
              <td colSpan={4}></td>
              <td className="text-center font-weight-bold text-dark">
                {numeral(total_amount).format("0,0.00")}
              </td>
              {find_distributor ? (
                <>
                  <td className="text-center font-weight-bold text-dark">
                    {numeral(
                      list.reduce((sum, item) => sum + (item.partner_type.toLowerCase() != "member" ? item.total_point_1 : 0)  , 0)
                    ).format("0,0.00")}
                  </td>
                  <td className="text-center font-weight-bold text-dark">
                    {numeral(
                      list.reduce((sum, item) => sum + item.total_point_2, 0)
                    ).format("0,0.00")}
                  </td>
                  <td className="text-center font-weight-bold text-dark">
                    {numeral(
                      list.reduce((sum, item) => sum + (item.partner_type.toLowerCase() != 'member' ? item.total_point_9 : 0), 0)
                    ).format("0,0.00")}
                  </td>
                  <td className="text-center font-weight-bold text-dark">
                    {numeral(
                      list.reduce((sum, item) => sum + (item.partner_type.toLowerCase() != 'member' ? item.total_point_7 : 0), 0)
                    ).format("0,0.00")}
                  </td>
                </>
              ) : (
                <td className="text-center font-weight-bold text-dark">
                  {numeral(
                    list.reduce((sum, item) => sum + item.total_point_2, 0)
                  ).format("0,0.00")}
                </td>
              )}
            </tr>
          ) : null}
        </tbody>
      </table>

      <Spin spinning={loading}>
        {reserveRedeem && (
          <>
            <Row className="mt-3">
              <Col span={4} offset={16}>
                <Typography.Text
                  strong
                  className="text-dark"
                  style={{ fontSize: "14px" }}
                >
                  คะแนนพร้อมใช้
                </Typography.Text>
              </Col>
              <Col span={4} className="text-right">
                <Typography.Text
                  className="text-dark"
                  style={{ fontSize: "14px" }}
                >
                  {numeral(point.rp_topup).format("0,0.00")}
                </Typography.Text>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col span={4} offset={16}>
                <Typography.Text
                  strong
                  className="text-dark"
                  style={{ fontSize: "14px" }}
                >
                  คะแนนพร้อมโอน
                </Typography.Text>
              </Col>
              <Col span={4} className="text-right">
                <Typography.Text
                  className="text-dark"
                  style={{ fontSize: "14px" }}
                >
                  {numeral(point.rp_hold).format("0,0.00")}
                </Typography.Text>
              </Col>
            </Row>
          </>
        )}

        <Row gutter={8} className="bg-pv py-1" style={{ fontSize: "16px" }}>
          <Col className="px-4">
            <Typography.Text className="text-white">
              {reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน"}
            </Typography.Text>
            {!isConfirm && (
              <Button
                type="ghost"
                size="small"
                className="float-right border-white text-white font-weight-normal"
                onClick={handleAddPayment}
              >
                <small>Add Payment</small>
              </Button>
            )}
          </Col>
        </Row>

        <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
          <Col className="pl-4 text-right" span={21}>
            <Typography.Text strong className="text-dark mr-3">
              รวมทุกบิล
            </Typography.Text>
          </Col>
          <Col className="pr-4 text-right" span={3}>
            <Typography.Text strong className="text-dark">
              {numeral(total_amount).format("0,0.00")}
            </Typography.Text>
          </Col>
        </Row>

        <Row gutter={8} className="px-4 mb-2" style={{ fontSize: "14px" }}>
          <Col span={5} className="text-center">
            <Typography.Text strong>วันที่และเวลาชำระ</Typography.Text>
          </Col>

          <Col span={3} className="text-center">
            <Typography.Text strong>ประเภทการชำระ</Typography.Text>
          </Col>
          <Col span={5} className="text-center">
            <Typography.Text strong>ธนาคาร</Typography.Text>
          </Col>
          <Col span={4} className="text-center">
            <Typography.Text strong>เลขที่อ้างอิง</Typography.Text>
          </Col>

          <Col span={3} className="text-center">
            <Typography.Text strong>จำนวน</Typography.Text>
          </Col>
        </Row>
        {state.itemKeys.map((n, i) => (
          <PaymentItem
            id={id}
            key={i}
            data={state.itemByKey[n]}
            status={status}
            maxNowAmount={max_now_amount}
            reloadPayment={reloadPayment}
            reserveRedeem={reserveRedeem}
            allPoint={all_point}
            documentNo={documentNo}
            buyerCode={buyerCode}
            buyerType={buyerType}
            paymentGateWay={paymentGateWay}
            kBankCustomerId={kBankCustomerId}
            onChange={(e) => {
              dispatch({
                type: "setItemValue",
                payload: e,
              });
            }}
          />
        ))}
      </Spin>

      <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
        <Col className="pl-4 text-right" span={21}>
          <Typography.Text strong className="text-dark mr-3">
            คงเหลือ
          </Typography.Text>
        </Col>
        <Col className="pr-4 text-right" span={3}>
          {!isConfirm ? (
            <Typography.Text strong className="text-dark">
              {numeral(max_now_amount).format("0,0.00")}
            </Typography.Text>
          ) : (
            <Typography.Text strong className="text-dark">
              {numeral(total_amount - total_payment).format("0,0.00")}
            </Typography.Text>
          )}
        </Col>
      </Row>

      <Row gutter={8} className="my-2" style={{ fontSize: "16px" }}>
        <Col className="pl-4 text-right" span={21}>
          <Typography.Text strong className="text-dark mr-3">
            {reserveRedeem ? "รวมคะแนนที่หัก" : "รวมเงินที่รับชำระ"}
          </Typography.Text>
        </Col>
        <Col className="pr-4 text-right" span={3}>
          <Typography.Text strong className="text-dark">
            {numeral(total_payment).format("0,0.00")}
          </Typography.Text>
        </Col>
      </Row>

      {/* <Row gutter={8} className="my-2">
                <Col className="pl-4 d-flex align-items-center" span={14}>
                    <Typography.Text strong className="mr-2 text-dark">หมายเหตุ</Typography.Text>
                    <Input
                        size="small"
                        value={state.remark}
                        onChange={e => dispatch({ type: 'setRemark', payload: e.target.value })}
                    />
                </Col>
            </Row> */}

      <Row gutter={8} className="my-2">
        <Col className="pl-4">
          <Typography.Text
            strong
            className="mr-2 text-dark"
          >{`ผู้ทำรายการ ${cashierName}`}</Typography.Text>
        </Col>
        {!hasStock && (
          <Col className="text-right">
            <Typography.Text className="text-reds">
              หมายเหตุ : มีสินค้าบางรายการไม่มีในคลังสินค้า
            </Typography.Text>
          </Col>
        )}
      </Row>

      <Row
        gutter={8}
        className="mt-5 d-flex align-items-center justify-content-center"
      >
        {isConfirm && (
          <Col span={4}>
            <Button
              size="small"
              block
              className={`text-white ${
                isConfirm ? "bg-rp border-rp" : "bg-gray"
              }`}
              disabled={!isConfirm}
              onClick={isConfirm ? returnWaitTab : undefined}
            >
              <small>{isConfirm ? "ส่งกลับยืนยันเงิน" : "แก้ไขการจอง"}</small>
            </Button>
          </Col>
        )}

        <Col span={4}>
          <Button
            size="small"
            className="text-white bg-tp border-tp"
            block
            onClick={handlePreview}
          >
            <small>รายละเอียด</small>
          </Button>
        </Col>
        <Col span={4}>
          <Button
            size="small"
            className="text-white bg-reds border-reds"
            block
            onClick={clickCancel}
          >
            <small>ยกเลิกการจอง</small>
          </Button>
        </Col>
        <Col span={4}>
          <Button
            size="small"
            className={`text-white ${
              hasStock ? "bg-pv border-pv" : "bg-gray-60"
            }`}
            block
            disabled={!hasStock}
            onClick={isConfirm ? confirmPayment : sendPayment}
          >
            <small>{isConfirm ? "อนุมัติ" : "บันทึก"}</small>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BillSummary;
