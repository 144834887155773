import { useMutation, useQuery } from '@tanstack/react-query';
import { eventRequest } from '../utils/useAxios';

const eventCalendarAnnounceServices = {
  useQueryGetAllEventCalendarAnnounces({ take, page }, onSuccess) {
    return useQuery(
      ['get-all-event-calendar-announces', { take, page }],
      async ({ queryKey }) => {
        try {
          const res = await eventRequest.get(`/event-calendar-announce`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetEventCalendarAnnounceById({ eventCalendarAnnounceId }, onSuccess) {
    return useQuery(
      ['get-event-calendar-announce-by-id', { eventCalendarAnnounceId }],
      async ({ queryKey }) => {
        try {
          const res = await eventRequest.get(
            `/event-calendar-announce/${queryKey[1].eventCalendarAnnounceId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!eventCalendarAnnounceId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useMutationCreateEventCalendarAnnounce(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await eventRequest.post(`/event-calendar-announce`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateEventCalendarAnnounce(onSuccess, onError) {
    return useMutation(
      async ({ eventCalendarAnnounceId, data }) => {
        try {
          const res = await eventRequest.patch(
            `/event-calendar-announce/${eventCalendarAnnounceId}`,
            data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateEventCalendarAnnounceStatus(onSuccess, onError) {
    return useMutation(
      async ({ eventCalendarAnnounceId, status }) => {
        try {
          const res = await eventRequest.patch(
            `/event-calendar-announce/${eventCalendarAnnounceId}/status`,
            {
              status,
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteEventCalendarAnnounce(onSuccess, onError) {
    return useMutation(
      async ({ eventCalendarAnnounceId }) => {
        try {
          const res = await eventRequest.delete(
            `/event-calendar-announce/${eventCalendarAnnounceId}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default eventCalendarAnnounceServices;
