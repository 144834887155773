/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Row,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { FaInfoCircle, FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import SelectAllType from "../../../../../components/SelectAllType";
import { useApi } from "../../../../../hooks/http2";
import {
  actionProduct,
  bomSelector,
  formSelector,
  mediaSelector,
  orderSourceSelector,
} from "../../../../../redux/slices/product";
import {
  axios,
  getToken,
  getUser,
  moment,
  URL_API,
  _,
} from "../../../../../utils/index";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectCategoryLevel from "../../../pos/components/SelectSubCategory";
import SelectBrand from "../product-request/components/SelectBrand";
import SelectCategory from "../product-request/components/SelectCategory";
import SelectColor from "../product-request/components/SelectColor";
import SelectDeliveryType from "../product-request/components/SelectDeliveryType";
import SelectItemType from "../product-request/components/SelectItemType";
import SelectOracleCategory from "../product-request/components/SelectOracleCategory";
import SelectOracleGroup from "../product-request/components/SelectOracleGroup";
import SelectOracleSubCategory from "../product-request/components/SelectOracleSubCategory";
import SelectPackaging from "../product-request/components/SelectPackaging";
import SelectProductCategory from "../product-request/components/SelectProductCategory";
import SelectShape from "../product-request/components/SelectShape";
import SelectSize from "../product-request/components/SelectSize";
import SelectSmell from "../product-request/components/SelectSmell";
import SelectSubCategory from "../product-request/components/SelectSubCategory";
import SelectTaste from "../product-request/components/SelectTaste";
import SelectVat from "../product-request/components/SelectVat";
import SelectVatRefund from "../product-request/components/SelectVatRefund";
import SelectTaxRefund from "../product-request/components/SelectTaxRefund";
import ImgUpload from "./components/ImgUpload";
import OrderSource from "./components/OrderSource";
import SelectProductDistributionType from "./components/SelectProductDistributionType";

const orderSourceItemMapping = (data, key) =>
  _.map(data.itemKeys, (n) => {
    const { start_date, end_date, code } = data.itemByKey[n];
    return {
      order_source_key: key,
      code,
      start_date: start_date ? moment(start_date).toDate() : null,
      end_date: end_date ? moment(end_date).toDate() : null,
    };
  });

const ProductForm = (props) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  const data = useSelector(formSelector);
  const bom = useSelector(bomSelector);
  const orderSource = useSelector(orderSourceSelector);
  const media = useSelector(mediaSelector);
  const {
    CLEAR_FORM,
    SET_FORM,
    LOAD_FORM,
    ADD_CATEGORY,
    DEL_CATEGORY,
    SET_CATEGORY,
  } = actionProduct;

  const fieldVisible = useApi(
    {
      baseURL: URL_API,
      url: `/setting/permission/field-visible/product-request`,
      token: true,
      params: {
        department_id: data?.department_id,
        category_id: data?.category,
      },
    },
    [data?.division_id, data?.category]
  );

  useEffect(() => {
    let _user = getUser();
    setUser(_user);
    dispatch(CLEAR_FORM());
    if (match.params.id !== "0") {
      loadDetail(match.params.id);
    }
  }, [match.params.id]);

  const loadDetail = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: `/system/products/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        dispatch(LOAD_FORM(res.data.data));
        props.form.setFieldsValue({
          ...res.data?.data,
        });
      })
      .catch((err) => {});
  };

  const handleClickSubmit = () => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      Modal.confirm({
        title: "ยืนยัน",
        content: "คุณต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let media_default;
            let formData = new FormData();

            _.forEach(
              _.filter(media.itemKeys, (n) => media.itemByKey[n].file),
              (n) => {
                const media_key = `media-key-${n}`;
                formData.append(`media-key-${n}`, media.itemByKey[n].file);
                if (media.itemByKey[n].is_default) {
                  media_default = {
                    media_key,
                  };
                }
              }
            );

            if (!media_default) {
              const find_default = _.find(
                _.map(media.itemKeys, (n) => media.itemByKey[n]),
                (n) => n.is_default
              );

              if (find_default) {
                media_default = {
                  id: find_default?.id,
                };
              }
            }

            let obj = {
              ...data,
              ...values,
              product_description_local: data.product_description_local
                ? draftToHtml(
                    convertToRaw(
                      data.product_description_local.getCurrentContent()
                    )
                  )
                : "",
              // event_date: _.map(values.event_date, (n) =>
              //   moment(n).format("YYYY-MM-DD")
              // )?.join(","),
              event_date:
                values.event_start && values.event_end
                  ? moment(values.event_start) + "," + moment(values.event_end)
                  : "",
              media_default,
              media_temp: [],
              media_delete: [...media.delete],
              bom: _.map(bom.itemKeys, (n) => bom.itemByKey[n]),
              order_source_item: _.reduce(
                _.map(Object.keys(orderSource), (n) =>
                  orderSourceItemMapping(orderSource[n], n)
                ),
                (r, list) => [...r, ...list],
                []
              ),
              category_lv: _.filter(
                _.map(
                  data.category_lv?.itemKeys || [],
                  (n) => data.category_lv?.itemByKey[n]?.list || []
                ),
                (n) => !(_.isArray(n) && n.length === 0)
              ),
            };

            formData.append("data", JSON.stringify(obj));

            axios({
              method: "post",
              baseURL: URL_API,
              url: `/system/products/${match.params.id}`,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                resolve("success");
              })
              .catch((err) => {
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.push(`/setting/master/product`);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const uploadCallback = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(file);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("container_name", "products");

        const { data } = await axios.post(`/file/upload`, formData, {
          baseURL: URL_API,
          headers: { Authorization: "Bearer " + getToken() },
        });

        resolve({ data: { link: data.data?.azure_url } });
      } catch (error) {
        reject(error);
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <Panel>
      <PanelHeader>ข้อมูลสินค้า</PanelHeader>
      <PanelBody>
        <Spin spinning={fieldVisible.loading}>
          <Form layout="vertical">
            {/* Breadcrumb */}
            <Row gutter={16} className="mb-2">
              <Col md={12}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/">หน้าหลัก</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/setting/master/product">สินค้า</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>รายละเอียดสินค้า</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col md={12} className="text-right">
                <Button
                  type="primary"
                  onClick={() => handleClickSubmit("send")}
                >
                  บันทึก
                </Button>
              </Col>

              {/* <Col md={12} className="text-right">
                  {match.params.id !== "0" && data?.show_approve === 1 ? (
                    <>
                      <Button
                        type="primary"
                        className="mr-2"
                        onClick={() => handleClickApprove(true)}
                      >
                        อนุมัติ
                      </Button>
                      <Button
                        type="danger"
                        onClick={() => handleClickApprove(false)}
                      >
                        ส่งกลับแก้ไข
                      </Button>
                    </>
                  ) : match.params.id === "0" || data?.show_save === 1 ? (
                    <>
                      <Button
                        type="dashed"
                        className="mr-3"
                        onClick={() => handleClickSubmit("draft")}
                      >
                        เอกสารร่าง
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleClickSubmit("send")}
                      >
                        บันทึก
                      </Button>
                    </>
                  ) : null}
                  {data?.show_cancel === 1 ? (
                    <Button
                      type="danger"
                      className="ml-3"
                      onClick={() => handleClickSubmit("cancel")}
                    >
                      ยกเลิกเอกสาร
                    </Button>
                  ) : null}
                </Col>
               */}
            </Row>

            <Row gutter={16}>
              {/* Left Panel */}
              <Col md={16}>
                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item label="รหัสสินค้า">
                      {getFieldDecorator("product_code", {
                        initialValue: data?.product_code,
                      })(<Input disabled />)}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="Oracle SKU">
                      {getFieldDecorator("backend_sku_code", {
                        initialValue: data?.backend_sku_code,
                      })(<Input disabled />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item label="Category">
                      {getFieldDecorator("oracle_category", {
                        initialValue: data?.oracle_category,
                        onChange: (value) =>
                          dispatch(
                            SET_FORM({
                              oracle_category: value,
                              oracle_sub_category: "",
                              oracle_group: "",
                            })
                          ),
                      })(<SelectOracleCategory className="w-100" disabled />)}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="Sub Category">
                      {getFieldDecorator("oracle_sub_category", {
                        initialValue: data?.oracle_sub_category,
                        onChange: (value) =>
                          dispatch(
                            SET_FORM({
                              oracle_sub_category: value,
                              oracle_group: "",
                            })
                          ),
                      })(
                        <SelectOracleSubCategory
                          category_id={data?.oracle_category}
                          className="w-100"
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="Group">
                      {getFieldDecorator("oracle_group", {
                        initialValue: data?.oracle_group,
                      })(
                        <SelectOracleGroup
                          className="w-100"
                          sub_category_id={data?.oracle_sub_category}
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item label="กลุ่มสินค้าหลัก">
                      {getFieldDecorator("category", {
                        initialValue: data?.category,
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุกลุ่มสินค้าหลัก",
                          },
                        ],
                        onChange: (value) => {
                          dispatch(SET_FORM({ category: value }));
                          props.form.setFieldsValue({ sub_category: "" });
                        },
                      })(
                        <SelectCategory
                          division_id={data?.department_id}
                          className="w-100"
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="กลุ่มสินค้าย่อย">
                      {getFieldDecorator("sub_category", {
                        initialValue: data?.sub_category,
                        rules: [
                          {
                            required: true,
                            message: "กรุณาระบุกลุ่มสินค้าย่อย",
                          },
                        ],
                      })(
                        <SelectSubCategory
                          className="w-100"
                          group_id={data?.category}
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="เงื่อนไขระบบ">
                      {getFieldDecorator("product_type", {
                        initialValue: data?.product_type,
                        rules: [
                          { required: true, message: "กรุณาระบุเงื่อนไขระบบ" },
                        ],
                        onChange: (value) =>
                          dispatch(SET_FORM({ product_type: value })),
                      })(
                        <SelectAllType
                          process_type="product_type"
                          className="w-100"
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item label="รูปแบบการขาย">
                      {getFieldDecorator("distribution_type", {
                        initialValue: data?.distribution_type,

                        onChange: (value) =>
                          dispatch(SET_FORM({ distribution_type: value })),
                      })(
                        <SelectProductDistributionType
                          placeholder="เลือกรูปแบบการขาย"
                          className="w-100"
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="รูปแบบสินค้า">
                      {getFieldDecorator("product_type_set", {
                        initialValue: data?.product_type_set,
                      })(<SelectItemType className="w-100" disabled />)}
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="ลิมิตจำนวนชิ้น/บิล">
                      {getFieldDecorator("max_qty_per_bill", {
                        initialValue: data?.max_qty_per_bill,
                        rules: [
                          { type: "number", message: "กรุณากรอกเป็นจำนวนเต็ม" },
                        ],
                      })(
                        <InputNumber
                          min={0}
                          formatter={(value) => (value === "" ? "" : value)}
                          parser={(value) => {
                            if (value === "") return ""; // ถ้ากดลบหมด ให้เป็นค่าว่าง
                            const parsed = Math.floor(Number(value));
                            return parsed >= 0 ? parsed : "";
                          }}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col md={8}>
                    <Form.Item label="Product Class">
                      {getFieldDecorator("product_class", {
                        initialValue: data?.product_class,
                      })(<SelectProductClass className="w-100" disabled />)}
                    </Form.Item>
                  </Col> */}
                </Row>

                {/* Category Level */}
                <div>
                  <Divider>หมวดหมู่สินค้าสำหรับขาย</Divider>
                  <Table
                    size="small"
                    pagination={false}
                    showHeader={false}
                    dataSource={_.map(
                      data?.category_lv?.itemKeys || [],
                      (n) => ({
                        key: n,
                        ...data?.category_lv?.itemByKey[n],
                      })
                    )}
                  >
                    <Table.Column
                      dataIndex="list"
                      render={(text, { key }) => (
                        <SelectCategoryLevel
                          className="w-100"
                          placeholder="เลือกหมวดหมู่สินค้า"
                          defaultValue={text}
                          onChange={(value) =>
                            dispatch(SET_CATEGORY({ key, value }))
                          }
                        />
                      )}
                    />
                    <Table.Column
                      dataIndex="key"
                      align="center"
                      render={(text) => (
                        <>
                          <Button
                            size="small"
                            shape="circle"
                            type="primary"
                            onClick={() => dispatch(ADD_CATEGORY())}
                          >
                            <FaPlus />
                          </Button>
                          {(data?.category_lv?.itemKeys || []).length > 1 && (
                            <Button
                              size="small"
                              shape="circle"
                              className="ml-2"
                              type="danger"
                              onClick={() => dispatch(DEL_CATEGORY(text))}
                            >
                              <FaMinus />
                            </Button>
                          )}
                        </>
                      )}
                    />
                  </Table>
                </div>
                {/* <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item label="หมวดหมู่สินค้า">
                      {getFieldDecorator("category_lv", {
                        initialValue: data?.category_lv,
                      })(
                        <SelectCategoryLevel
                          className="w-100"
                          placeholder="เลือกหมวดหมู่สินค้า"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row> */}

                {/* BOM */}
                {/* {["PT08", "C05", "PT02"].includes(data?.product_type) && ( */}
                {["Kit/Package", "Assembly"].includes(
                  data?.product_type_set
                ) && (
                  <div>
                    <Divider>สินค้าชุดเซ็ต</Divider>

                    <Row gutter={16} type="flex" className="align-items-center">
                      <Col md={12}>
                        <h4>รายการสินค้าประกอบในเซ็ต</h4>
                      </Col>
                    </Row>
                    <Table
                      size="small"
                      bordered
                      dataSource={bom?.itemKeys.map((n) => ({
                        ...bom?.itemByKey[n],
                        key: n,
                      }))}
                      pagination={false}
                    >
                      <Table.Column
                        title="ลำดับ"
                        key="no"
                        align="right"
                        render={(text, record, index) => index + 1}
                      />
                      <Table.Column title="รหัสสินค้า" dataIndex="item_code" />
                      <Table.Column
                        title="ชื่อสินค้า"
                        dataIndex="product_name_local"
                      />

                      <Table.Column
                        title="จำนวน"
                        align="right"
                        dataIndex="qty"
                      />
                    </Table>
                  </div>
                )}

                {/* Product info */}
                <div>
                  <Divider>ข้อมูลสินค้า</Divider>
                  <Row gutter={16}>
                    {(fieldVisible.fetch?.data || []).includes(
                      "product_name_local"
                    ) && (
                      <Col md={12}>
                        <Form.Item label="ชื่อสินค้า (TH)">
                          {getFieldDecorator("product_name_local", {
                            initialValue: data?.product_name_local,
                            rules: [
                              {
                                required: true,
                                message: "กรุณาระบุชื่อสินค้า (TH)",
                              },
                            ],
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {(fieldVisible.fetch?.data || []).includes(
                      "product_name_en"
                    ) && (
                      <Col md={12}>
                        <Form.Item label="ชื่อสินค้า (EN)">
                          {getFieldDecorator("product_name_en", {
                            initialValue: data?.product_name_en,
                            rules: [
                              {
                                required: true,
                                message: "กรุณาระบุชื่อสินค้า (EN)",
                              },
                            ],
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {(fieldVisible.fetch?.data || []).includes("vat") && (
                      <Col md={12}>
                        <Form.Item label="การคำนวณภาษี">
                          {getFieldDecorator("tax_schedule", {
                            initialValue: data?.tax_schedule,
                            rules: [
                              {
                                required: true,
                                message: "กรุณาระบุการคำนวณภาษี",
                              },
                            ],
                          })(
                            <SelectVat
                              className="w-100"
                              withAll
                              allText="เลือกการคำนวณภาษี"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  {(fieldVisible.fetch?.data || []).includes("tagline") && (
                    <Row gutter={16}>
                      <Col md={24}>
                        <Form.Item label="คำโปรย">
                          {getFieldDecorator("product_tagline_local", {
                            initialValue: data?.product_tagline_local,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {(fieldVisible.fetch?.data || []).includes("description") && (
                    <Row gutter={16}>
                      <Col md={24}>
                        <Form.Item label="รายละเอียดสินค้า">
                          <Editor
                            toolbar={{
                              image: {
                                urlEnabled: true,
                                uploadEnabled: true,
                                uploadCallback: uploadCallback,
                                previewImage: true,
                              },
                            }}
                            editorClassName="border"
                            editorState={data?.product_description_local}
                            onEditorStateChange={(e) =>
                              dispatch(
                                SET_FORM({
                                  product_description_local: e,
                                })
                              )
                            }

                            // disabled={
                            //   (match.params.id === "0" ||
                            //     data?.show_save === 1) === false
                            // }
                            // readOnly={
                            //   (match.params.id === "0" ||
                            //     data?.show_save === 1) === false
                            // }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Row gutter={16}>
                    {(fieldVisible.fetch?.data || []).includes("how_to") && (
                      <Col md={8}>
                        <Form.Item label="วิธีการทาน / วิธีการใช้">
                          {getFieldDecorator("product_manual_local", {
                            initialValue: data?.product_manual_local,
                          })(
                            <Input.TextArea
                              rows={4}
                              // disabled={
                              //   //match.params.id === "0" ||
                              //   // (data?.show_save === 1) ===
                              //   false
                              // }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {(fieldVisible.fetch?.data || []).includes(
                      "ingredient"
                    ) && (
                      <Col md={8}>
                        <Form.Item label="ส่วนประกอบ">
                          {getFieldDecorator("ingredient", {
                            initialValue: data?.ingredient,
                          })(
                            <Input.TextArea
                              rows={4}
                              // disabled={
                              //   (match.params.id === "0" ||
                              //     data?.show_save === 1) === false
                              // }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {(fieldVisible.fetch?.data || []).includes("caution") && (
                      <Col md={8}>
                        <Form.Item label="คำเตือน">
                          {getFieldDecorator("caution", {
                            initialValue: data?.caution,
                          })(
                            <Input.TextArea
                              rows={4}
                              // disabled={
                              //   (match.params.id === "0" ||
                              //     data?.show_save === 1) === false
                              // }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {/* {(fieldVisible.fetch?.data || []).includes("storage") && (
                    <Col md={12}>
                      <Form.Item label="การจัดเก็บ">
                        {getFieldDecorator("storage", {
                          initialValue: data?.storage,
                        })(
                          <Input.TextArea
                            rows={4}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )} */}
                  </Row>

                  {(fieldVisible.fetch?.data || []).includes("manual") && (
                    <Row gutter={16}>
                      <Col md={24}>
                        <Form.Item label="คู่มือ (รองรับ PDF, JPEG, PNG)">
                          {getFieldDecorator("manual", {
                            initialValue: data?.selling_age,
                          })(
                            <Upload
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            >
                              <Button type="dashed">เลือกไฟล์</Button>
                            </Upload>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Row gutter={4}>
                    {(fieldVisible.fetch?.data || []).includes("barcode") && (
                      <>
                        <Col md={4}>
                          <Form.Item label="บาร์โค้ด">
                            {getFieldDecorator("barcode_type", {
                              initialValue: data?.barcode_type,
                              onChange: (e) =>
                                dispatch(SET_FORM({ barcode_type: e })),
                            })(
                              <SelectAllType
                                process_type="barcode_type"
                                className="w-100"
                                withAll
                                allText="ไม่ระบุ"
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col md={8}>
                          <Form.Item label="กำหนดบาร์โค้ด">
                            {getFieldDecorator("bar_code", {
                              initialValue: data?.bar_code,
                            })(
                              <Input
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("fda") && (
                      <Col md={4}>
                        <Form.Item label="เลขที่ใบรับจดแจ้ง/เลขสารบบอาหาร">
                          {getFieldDecorator("fda", {
                            initialValue: data?.fda,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("halal") && (
                      <Col md={4}>
                        <Form.Item label="หมายเลขฮาลาล">
                          {getFieldDecorator("halal", {
                            initialValue: data?.halal,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "product_category"
                    ) && (
                      <Col md={4}>
                        <Form.Item label="หมวดสินค้า">
                          {getFieldDecorator("product_category", {
                            initialValue: data?.product_category,
                          })(
                            <SelectProductCategory
                              className="w-100"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("unit_type") && (
                      <Col md={4}>
                        <Form.Item label="หน่วยของสินค้า">
                          {getFieldDecorator("unit_type", {
                            initialValue: data?.unit_type,
                          })(
                            <SelectAllType
                              process_type="product_unit_type"
                              className="w-100"
                              withAll
                              allText="เลือกหน่วยสินค้า"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("aging") && (
                      <Col md={4}>
                        <Form.Item label="อายุสินค้า (วัน)">
                          {getFieldDecorator("shelef_life", {
                            initialValue: data?.shelef_life,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "selling_age"
                    ) && (
                      <Col md={6}>
                        <Form.Item
                          label={
                            <span>
                              อายุสินค้าที่ห้ามจำหน่าย (วัน){" "}
                              <Tooltip
                                placement="top"
                                title={
                                  <p>
                                    <strong>
                                      <u>คำอธิบายเพิ่มเติม</u>
                                    </strong>
                                    <br />
                                    อายุสินค้าที่ห้ามจำหน่าย คือ
                                    การเก็บสินค้าออกจากชั้นวางเมื่อมีอายุคงเหลือตามที่กำหนด
                                    ตัวอย่างเช่น สินค้า Core Produt
                                    ที่จะหมดอายุในอีก 90 วัน
                                    ต้องเก็บออกจากชั้นวางขาย
                                    ห้ามมีสินค้าเหลือเวลาอายุสินค้าตามที่กำหนด
                                    วางขายหน้า Shelf โดยเด็ดขาด
                                  </p>
                                }
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </span>
                          }
                        >
                          {getFieldDecorator("selling_age", {
                            initialValue: data?.selling_age,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step={1}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("storage") && (
                      <Col md={4}>
                        <Form.Item label="อุณหภูมิการจัดเก็บ">
                          {getFieldDecorator("storage", {
                            initialValue: data?.storage,
                          })(
                            <SelectAllType
                              process_type="product_temperature"
                              className="w-100"
                              withAll
                              allText="เลือกอุณหภูมิการจัดเก็บ"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("brand") && (
                      <Col md={4}>
                        <Form.Item label="แบรนด์">
                          {getFieldDecorator("brand", {
                            initialValue: data?.brand,
                          })(
                            <SelectBrand
                              className="w-100"
                              withAll
                              allText="เลือกแบรนด์"
                              division_id={data?.department_id}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("series") && (
                      <Col md={4}>
                        <Form.Item label="รุ่นสินค้า">
                          {getFieldDecorator("product_series", {
                            initialValue: data?.product_series,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("year") && (
                      <Col md={4}>
                        <Form.Item label="รุ่นปีที่ผลิต">
                          {getFieldDecorator("product_year", {
                            initialValue: data?.product_year,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("link") && (
                      <Col md={4}>
                        <Form.Item label="ลิงค์ที่เกี่ยวข้อง">
                          {getFieldDecorator("link", {
                            initialValue: data?.link,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("warranty") && (
                      <Col md={4}>
                        <Form.Item label="การรับประกันสินค้า">
                          {getFieldDecorator("warranty", {
                            initialValue: data?.warranty,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {(fieldVisible.fetch?.data || []).includes("size") && (
                      <Col md={4}>
                        <Form.Item label="Size">
                          {getFieldDecorator("size", {
                            initialValue: data?.size,
                          })(
                            <SelectSize
                              className="w-100"
                              withAll
                              allText="เลือก Size"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("chest") && (
                      <Col md={4}>
                        <Form.Item label="รอบอก">
                          {getFieldDecorator("product_chest", {
                            initialValue: data?.product_chest,
                          })(
                            <Input
                            // disabled={
                            //   // (data?.show_save === 1) === false
                            //   // (match.params.id === "0" ||
                            //   //   data?.show_save === 1) === false
                            // }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("shoulder") && (
                      <Col md={4}>
                        <Form.Item label="ไหล่">
                          {getFieldDecorator("product_shoulder", {
                            initialValue: data?.product_shoulder,
                          })(
                            <Input
                            // disabled={
                            //   (match.params.id === "0" ||
                            //     data?.show_save === 1) === false
                            // }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("waist") && (
                      <Col md={4}>
                        <Form.Item label="เอว">
                          {getFieldDecorator("product_waist", {
                            initialValue: data?.product_waist,
                          })(
                            <Input
                            // disabled={
                            //   (match.params.id === "0" ||
                            //     data?.show_save === 1) === false
                            // }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("hip") && (
                      <Col md={4}>
                        <Form.Item label="สะโพก">
                          {getFieldDecorator("product_hip", {
                            initialValue: data?.product_hip,
                          })(
                            <Input
                            // disabled={
                            //   (match.params.id === "0" ||
                            //     data?.show_save === 1) === false
                            // }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("volume") && (
                      <Col md={4}>
                        <Form.Item label="ปริมาณ">
                          {getFieldDecorator("volume", {
                            initialValue: data?.volume,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("width") && (
                      <Col md={4}>
                        <Form.Item label="กว้าง">
                          {getFieldDecorator("product_width", {
                            initialValue: data?.product_width,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("length") && (
                      <Col md={4}>
                        <Form.Item label="ยาว">
                          {getFieldDecorator("product_length_1", {
                            initialValue: data?.product_length_1,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("height") && (
                      <Col md={4}>
                        <Form.Item label="สูง">
                          {getFieldDecorator("product_height", {
                            initialValue: data?.product_height,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("dept") && (
                      <Col md={4}>
                        <Form.Item label="ลึก">
                          {getFieldDecorator("product_depth", {
                            initialValue: data?.product_depth,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("weight") && (
                      <Col md={4}>
                        <Form.Item label="น้ำหนัก">
                          {getFieldDecorator("size_weight", {
                            initialValue: data?.size_weight,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("material") && (
                      <Col md={4}>
                        <Form.Item label="วัสดุ">
                          {getFieldDecorator("material", {
                            initialValue: data?.material,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("color") && (
                      <Col md={4}>
                        <Form.Item label="สี">
                          {getFieldDecorator("color", {
                            initialValue: data?.color,
                          })(
                            <SelectColor
                              className="w-100"
                              withAll
                              allText="เลือกสี"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("taste") && (
                      <Col md={4}>
                        <Form.Item label="รสชาติ">
                          {getFieldDecorator("taste", {
                            initialValue: data?.taste,
                          })(
                            <SelectTaste
                              className="w-100"
                              withAll
                              allText="เลือกรสชาติ"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("smell") && (
                      <Col md={4}>
                        <Form.Item label="กลิ่น">
                          {getFieldDecorator("smell", {
                            initialValue: data?.smell,
                          })(
                            <SelectSmell
                              className="w-100"
                              withAll
                              allText="เลือกกลิ่น"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("shape") && (
                      <Col md={4}>
                        <Form.Item label="รูปทรง">
                          {getFieldDecorator("product_shape", {
                            initialValue: data?.product_shape,
                          })(
                            <SelectShape
                              className="w-100"
                              withAll
                              allText="เลือกรูปทรง"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("package") && (
                      <Col md={4}>
                        <Form.Item label="รูปแบบบรรจุภัณฑ์">
                          {getFieldDecorator("packaging", {
                            initialValue: data?.packaging,
                          })(
                            <SelectPackaging
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "event_date"
                    ) && (
                      <>
                        <Col md={4}>
                          <Form.Item label="เริ่มต้นวันที่จัดงาน">
                            {getFieldDecorator("event_start", {
                              initialValue: data?.event_start,
                              rules: [
                                {
                                  required:
                                    data?.event_end != null &&
                                    data?.event_end !== undefined
                                      ? true
                                      : false,
                                  message: "กรุณาระบุ เริ่มต้นวันที่จัดงาน",
                                },
                              ],
                            })(
                              <DatePicker
                                className="w-100"
                                format="DD/MM/YYYY"
                                placeholder="เริ่มต้น"
                                disabledDate={(current) =>
                                  current &&
                                  data?.event_end &&
                                  current > data?.event_end
                                }
                                onChange={(e) =>
                                  dispatch(SET_FORM({ event_start: e }))
                                }
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item label="สิ้นสุดวันที่จัดงาน">
                            {getFieldDecorator("event_end", {
                              initialValue: data?.event_end,
                              rules: [
                                {
                                  required:
                                    data?.event_start != null &&
                                    data?.event_start !== undefined
                                      ? true
                                      : false,
                                  message: "กรุณาระบุ สิ้นสุดวันที่จัดงาน",
                                },
                              ],
                            })(
                              <DatePicker
                                className="w-100"
                                format="DD/MM/YYYY"
                                placeholder="สิ้นสุด"
                                disabledDate={(current) =>
                                  current &&
                                  data?.event_start &&
                                  current < data?.event_start
                                }
                                onChange={(e) =>
                                  dispatch(SET_FORM({ event_end: e }))
                                }
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("energy") && (
                      <Col md={4}>
                        <Form.Item label="พลังงาน">
                          {getFieldDecorator("energy", {
                            initialValue: data?.energy,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("fat") && (
                      <Col md={4}>
                        <Form.Item label="ไขมัน">
                          {getFieldDecorator("fat", {
                            initialValue: data?.fat,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("sugar") && (
                      <Col md={4}>
                        <Form.Item label="น้ำตาล">
                          {getFieldDecorator("sugar", {
                            initialValue: data?.sugar,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("sodium") && (
                      <Col md={4}>
                        <Form.Item label="โซเดียม">
                          {getFieldDecorator("sodium", {
                            initialValue: data?.sodium,
                          })(
                            <Input
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "unit_per_package"
                    ) && (
                      <Col md={6}>
                        <Form.Item label="จำนวนบรรจุต่อ 1 ลัง">
                          {getFieldDecorator("unit_per_package", {
                            initialValue: data?.unit_per_package,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step={1}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "package_width"
                    ) && (
                      <Col md={6}>
                        <Form.Item label="ความกว้างกล่องลังบรรจุ (ซม.)">
                          {getFieldDecorator("package_width", {
                            initialValue: data?.package_width,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step={0.01}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "package_length"
                    ) && (
                      <Col md={6}>
                        <Form.Item label="ความยาวกล่องลังบรรจุ (ซม.)">
                          {getFieldDecorator("package_length", {
                            initialValue: data?.package_length,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step={0.01}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "package_height"
                    ) && (
                      <Col md={6}>
                        <Form.Item label="ความสูงกล่องลังบรรจุ (ซม.)">
                          {getFieldDecorator("package_height", {
                            initialValue: data?.package_height,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step={0.01}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes(
                      "package_weight"
                    ) && (
                      <Col md={6}>
                        <Form.Item label="น้ำหนักสินค้ารวมกล่องลังบรรจุ (กรัม)">
                          {getFieldDecorator("package_weight", {
                            initialValue: data?.package_weight,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step={0.01}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {(fieldVisible.fetch?.data || []).includes("overlay") && (
                      <Col md={6}>
                        <Form.Item label="สินค้าวางซ้อนได้สูงสุดกี่ลัง">
                          {getFieldDecorator("overlay", {
                            initialValue: data?.overlay,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step={1}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </div>

                {/* cube  */}
                <div>
                  <Divider>
                    ปริมาตรของสินค้า
                    <br />
                    <small>(ใช้ในการคำนวณเรื่องการจัดส่ง)</small>
                  </Divider>

                  <Row gutter={16}>
                    <Col md={6}>
                      <Form.Item label="กว้าง (ซม.)">
                        {getFieldDecorator("size_width", {
                          initialValue: data?.size_width,
                        })(
                          <InputNumber
                            className="w-100"
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item label="ยาว (ซม.)">
                        {getFieldDecorator("size_length", {
                          initialValue: data?.size_length,
                        })(
                          <InputNumber
                            className="w-100"
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item label="สูง (ซม.)">
                        {getFieldDecorator("size_height", {
                          initialValue: data?.size_height,
                        })(
                          <InputNumber
                            className="w-100"
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item label="น้ำหนัก (กรัม)">
                        {getFieldDecorator("weight", {
                          initialValue: data?.weight,
                        })(
                          <InputNumber
                            className="w-100"
                            step="0.01"
                            min={0}
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={6}>
                      <Form.Item label="การคำนวณค่าจัดส่ง">
                        {getFieldDecorator("delivery_type", {
                          initialValue: data?.delivery_type,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาเลือกการคำนวณค่าจัดส่ง",
                            },
                          ],
                          onChange: (value) =>
                            dispatch(SET_FORM({ delivery_type: value })),
                        })(
                          <SelectDeliveryType
                            className="w-100"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {data?.delivery_type === "other" && (
                      <Col md={6}>
                        <Form.Item label="ค่าจัดส่ง">
                          {getFieldDecorator("shipping_rate", {
                            initialValue: data?.shipping_rate,
                          })(
                            <InputNumber
                              className="w-100"
                              step="0.01"
                              min={0}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </div>

                {/* Point */}
                {["Sale", "Service", "Other"].includes(
                  data?.distribution_type
                ) && (
                  <div>
                    <Divider>ข้อมูลคะแนน</Divider>

                    <Typography.Text strong>
                      คะแนนที่นักธุรกิจและVIP Member จะได้รับเมื่อซื้อสินค้า
                      <Popover
                        content={
                          <p className="mb-0">
                            - กรณีนักธุรกิจสั่งซื้อ จะได้รับ คะแนน PV , CV , RP
                            และ RA (ถ้ามี) <br />- กรณี VIP Member สั่งซื้อ
                            จะได้รับคะแนน RP เท่านั้น
                          </p>
                        }
                      >
                        <FaInfoCircle className="ml-2 text-info" />
                      </Popover>
                    </Typography.Text>
                    <Row gutter={16}>
                      <Col md={4}>
                        <Form.Item label="PV">
                          {getFieldDecorator("point_01", {
                            initialValue: data?.point_01,
                            onChange: (value) => {
                              dispatch(
                                SET_FORM({ point_01: value, point_03: value })
                              );
                              props.form.setFieldsValue({ point_03: value });
                            },
                          })(
                            <InputNumber
                              className={`w-100 ${
                                parseFloat(data?.point_01) === 0
                                  ? "bg-danger text-white"
                                  : ""
                              }`}
                              step="0.01"
                              min={0}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      {/* <Col md={6}>
                        <Form.Item label="CV">
                          {getFieldDecorator("point_03", {
                            initialValue: data?.point_03,
                            onChange: (value) => {
                              dispatch(SET_FORM({ point_03: value }));
                            },
                          })(
                            <InputNumber
                              className={`w-100 ${
                                parseFloat(data?.point_03) === 0
                                  ? "bg-danger text-white"
                                  : ""
                              }`}
                              step="0.01"
                              min={0}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col> */}
                      <Col md={4}>
                        <Form.Item label="RP">
                          {getFieldDecorator("point_02", {
                            initialValue: data?.point_02,
                            onChange: (value) => {
                              dispatch(SET_FORM({ point_02: value }));
                            },
                          })(
                            <InputNumber
                              className={`w-100 ${
                                parseFloat(data?.point_02) === 0
                                  ? "bg-danger text-white"
                                  : ""
                              }`}
                              step="0.01"
                              min={0}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item label="RA">
                          {getFieldDecorator("point_06", {
                            initialValue: data?.point_06,
                            onChange: (value) => {
                              dispatch(SET_FORM({ point_06: value }));
                            },
                          })(
                            <InputNumber
                              className={`w-100 ${
                                parseFloat(data?.point_06) === 0
                                  ? "bg-danger text-white"
                                  : ""
                              }`}
                              step="0.01"
                              min={0}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item label="BP">
                          {getFieldDecorator("point_05", {
                            initialValue: data?.point_05,
                            onChange: (value) => {
                              dispatch(SET_FORM({ point_05: value }));
                            },
                          })(
                            <InputNumber
                              className={`w-100 ${
                                parseFloat(data?.point_05) === 0
                                  ? "bg-danger text-white"
                                  : ""
                              }`}
                              step="0.01"
                              min={0}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      {["PT08", "PT10"].includes(data?.product_type) && (
                        <Col md={4}>
                          <Form.Item label="TP">
                            {getFieldDecorator("point_07", {
                              initialValue: data?.point_07,
                              onChange: (value) => {
                                dispatch(SET_FORM({ point_07: value }));
                              },
                            })(
                              <InputNumber
                                className={`w-100 ${
                                  parseFloat(data?.point_07) === 0
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                                step="0.01"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      )}
                      <Col md={4}>
                        <Form.Item label="EP">
                          {getFieldDecorator("point_09", {
                            initialValue: data?.point_09,
                            onChange: (value) => {
                              dispatch(SET_FORM({ point_09: value }));
                            },
                          })(
                            <InputNumber
                              className={`w-100 ${
                                parseFloat(data?.point_09) === 0
                                  ? "bg-danger text-white"
                                  : ""
                              }`}
                              step="0.01"
                              min={0}
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    {!["PT08", "PT10"].includes(data?.product_type) && (
                      <>
                        <Typography.Text strong>
                          คะแนนพิเศษที่นักธุรกิจผู้แนะนำจะได้รับจากการสั่งซื้อของ
                          VIP Member
                          <Popover
                            content={
                              <p className="mb-0">
                                กรณีที่ VIP Member มีการสั่งซื้อสินค้า
                                นักธุรกิจที่เป็นผู้แนะนำของ VIP Member ท่านนั้น
                                <br />
                                จะได้รับคะแนน PV และ CV ตามที่กำหนด
                                โดยคะแนนจะเข้าระบบให้ผู้แนะนำเป็นคะแนน Hold
                                <br />
                                ภายใน 7 วันหลังจากวันที่ VIP Member
                                มีการสั่งซื้อสินค้า
                              </p>
                            }
                          >
                            <FaInfoCircle className="ml-2 text-info" />
                          </Popover>
                        </Typography.Text>
                        <Row gutter={16}>
                          <Col md={6}>
                            <Form.Item label="PV">
                              {getFieldDecorator("r_point_01", {
                                initialValue: data?.r_point_01,
                                onChange: (value) => {
                                  dispatch(
                                    SET_FORM({
                                      r_point_01: value,
                                      r_point_03: value,
                                    })
                                  );
                                  props.form.setFieldsValue({
                                    r_point_03: value,
                                  });
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.r_point_01) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                          {/* <Col md={6}>
                            <Form.Item label="CV">
                              {getFieldDecorator("r_point_03", {
                                initialValue: data?.r_point_03,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ r_point_03: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.r_point_03) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col> */}
                          {/* <Col md={6}>
                            <Form.Item label="RP">
                              {getFieldDecorator("r_point_02", {
                                initialValue: data?.r_point_02,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ r_point_02: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.r_point_02) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col> */}
                          <Col md={6}>
                            <Form.Item label="RA">
                              {getFieldDecorator("r_point_06", {
                                initialValue: data?.r_point_06,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ r_point_06: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.r_point_06) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item label="BP">
                              {getFieldDecorator("r_point_05", {
                                initialValue: data?.r_point_05,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ r_point_05: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.r_point_05) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item label="EP">
                              {getFieldDecorator("r_point_09", {
                                initialValue: data?.r_point_09,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ r_point_09: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.r_point_09) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                    {data?.product_type === "PT05" && (
                      <>
                        <Typography.Text strong>
                          คะแนนพิเศษที่นักธุรกิจ(ผู้แนะนำ)จะได้รับ เมื่อ VIP
                          Member ซื้อสินค้า Dealer Product แบบเดี่ยว
                          <Popover
                            content={
                              <p className="mb-0">
                                VIP Member จะไม่สามารถสั่งซื้อสินค้ากลุ่ม Dealer
                                Product แบบเป็นแพคได้
                                <br />
                                สินค้า Dealer Product
                                แบบแพคสงวนสิทธิ์จำหน่ายให้กับนักธุรกิจเท่านั้น
                                <br />
                                ดังนั้นเมื่อ VIP Member มีการสั่งซื้อสินค้ากลุ่ม
                                Dealer Product แบบกล่องเดี่ยว
                                <br />
                                นักธุรกิจที่เป็นผู้แนะนำของ VIP Member ท่านนั้น
                                จะได้รับคะแนน RP
                                ที่คำนวณออกมาเป็นยอดส่วนต่างจากการซื้อแบบแพค
                                <br />
                                แต่นักธุรกิจที่เป็นผู้แนะนำต้องมีประวัติการสั่งซื้อสินค้า
                                Dealer Product แบบแพคด้วย
                                ถึงจะได้รับคะแนนในส่วนนี้
                                <br />
                                ซึ่งคะแนน RP จะได้รับ ภายใน 7 วัน
                                <br />
                                หลังจากวันที่ VIP Member มีการสั่งซื้อสินค้า
                                โดยเข้าเป็นคะแนน RP พร้อมโอน
                              </p>
                            }
                          >
                            <FaInfoCircle className="ml-2 text-info" />
                          </Popover>
                        </Typography.Text>
                        <Row gutter={16}>
                          <Col md={6}>
                            <Form.Item label="PV">
                              {getFieldDecorator("d_point_01", {
                                initialValue: data?.d_point_01,
                                onChange: (value) => {
                                  dispatch(
                                    SET_FORM({
                                      d_point_01: value,
                                      d_point_03: value,
                                    })
                                  );
                                  props.form.setFieldsValue({
                                    d_point_03: value,
                                  });
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.d_point_01) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                          {/* <Col md={6}>
                            <Form.Item label="CV">
                              {getFieldDecorator("d_point_03", {
                                initialValue: data?.d_point_03,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ d_point_03: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.d_point_03) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                />
                              )}
                            </Form.Item>
                          </Col> */}
                          <Col md={6}>
                            <Form.Item label="RP">
                              {getFieldDecorator("d_point_02", {
                                initialValue: data?.d_point_02,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ d_point_02: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.d_point_02) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item label="EP">
                              {getFieldDecorator("d_point_09", {
                                initialValue: data?.d_point_09,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ d_point_09: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.d_point_09) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                          {/* <Col md={6}>
                            <Form.Item label="CD">
                              {getFieldDecorator("d_point_08", {
                                initialValue: data?.d_point_08,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ d_point_08: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.d_point_08) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col> */}
                          {/* <Col md={6}>
                            <Form.Item label="RA">
                              {getFieldDecorator("d_point_06", {
                                initialValue: data?.d_point_06,
                                onChange: (value) => {
                                  dispatch(SET_FORM({ d_point_06: value }));
                                },
                              })(
                                <InputNumber
                                  className={`w-100 ${
                                    parseFloat(data?.d_point_06) === 0
                                      ? "bg-danger text-white"
                                      : ""
                                  }`}
                                  step="0.01"
                                  min={0}
                                  disabled={
                                    (match.params.id === "0" ||
                                      data?.show_save === 1) === false
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col> */}
                        </Row>
                      </>
                    )}

                    {["PT08", "PT10"].includes(data?.product_type) && (
                      <Row gutter={16}>
                        <Col md={8}>
                          <Form.Item label="จำนวนครั้งการแจง PV (ครั้ง)">
                            {getFieldDecorator("split_pv", {
                              initialValue: data?.split_pv,
                              rules: [
                                {
                                  message: "กรุณาระบุราคาสำหรับขายปลีก",
                                  min: 1,
                                  type: "number",
                                },
                              ],
                            })(
                              <InputNumber
                                className="w-100"
                                min={0}
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}

                {/* Price */}
                <div>
                  <Divider>
                    ข้อมูลราคา
                    <br />
                    <small>
                      {["PT08", "C05", "PT02"].includes(data?.product_type)
                        ? "(สินค้าชุดเซ็ต)"
                        : "(ราคาแบบสินค้าเดี่ยว)"}
                    </small>
                  </Divider>

                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item
                        label={`${
                          ["PT08", "C05", "PT02"].includes(data?.product_type)
                            ? "ราคาโปรโมชั่นสำหรับนักธุรกิจ"
                            : "ราคาสำหรับนักธุรกิจ"
                        } (บาท)`}
                        help={
                          ["PT08", "C05", "PT02"].includes(data?.product_type)
                            ? `ราคารวมปกติ ${numeral(
                                _.reduce(
                                  bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                  (sum, n) =>
                                    (sum +=
                                      (n?.distributor_price || 0) *
                                      (n?.qty || 0)),
                                  0
                                )
                              ).format("0,0.00")} บาท`
                            : false
                        }
                      >
                        {getFieldDecorator("distributor_price", {
                          initialValue: data?.distributor_price,
                          rules: [
                            {
                              message: `กรุณาระบุ${
                                ["PT08", "C05", "PT02"].includes(
                                  data?.product_type
                                )
                                  ? "ราคาโปรโมชั่นสำหรับนักธุรกิจ"
                                  : "ราคาสำหรับนักธุรกิจ"
                              }`,
                              min: 0,
                              type: "number",
                            },
                          ],
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {!["PT08", "C05", "PT02"].includes(data?.product_type) && (
                      <Col md={8}>
                        <Form.Item
                          label="ราคาสำหรับเคลม (บาท)"
                          help={
                            ["PT08", "C05", "PT02"].includes(data?.product_type)
                              ? `ราคารวมปกติ ${numeral(
                                  _.reduce(
                                    bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                    (sum, n) =>
                                      (sum +=
                                        (n?.claim_price || 0) * (n?.qty || 0)),
                                    0
                                  )
                                ).format("0,0.00")} บาท`
                              : false
                          }
                        >
                          {getFieldDecorator("claim_price", {
                            initialValue: data?.claim_price,
                            rules: [
                              {
                                message: "กรุณาระบุราคาสำหรับเคลม",
                                min: 0,
                                type: "number",
                              },
                            ],
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    <Col md={8}>
                      <Form.Item
                        label="ราคาสำหรับขายปลีก (บาท)"
                        help={
                          ["PT08", "C05", "PT02"].includes(data?.product_type)
                            ? `ราคารวมปกติ ${numeral(
                                _.reduce(
                                  bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                  (sum, n) =>
                                    (sum +=
                                      (n?.retail_price || 0) * (n?.qty || 0)),
                                  0
                                )
                              ).format("0,0.00")} บาท`
                            : false
                        }
                      >
                        {getFieldDecorator("retail_price", {
                          initialValue: data?.retail_price,
                          rules: [
                            {
                              message: "กรุณาระบุราคาสำหรับขายปลีก",
                              min: 0,
                              type: "number",
                            },
                          ],
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                            help
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    {!["PT08"].includes(data?.product_type) &&
                      data?.product_type !== "" && (
                        <Col md={8}>
                          <Form.Item
                            label={`${
                              ["PT08", "C05", "PT02"].includes(
                                data?.product_type
                              )
                                ? "ราคาโปรโมชั่นสำหรับ VIP Member"
                                : "ราคาสำหรับ VIP Member"
                            } (บาท)`}
                            help={
                              ["PT08", "C05", "PT02"].includes(
                                data?.product_type
                              )
                                ? `ราคารวมปกติ ${numeral(
                                    _.reduce(
                                      bom?.itemKeys.map(
                                        (n) => bom?.itemByKey[n]
                                      ),
                                      (sum, n) =>
                                        (sum +=
                                          (n?.member_price || 0) *
                                          (n?.qty || 0)),
                                      0
                                    )
                                  ).format("0,0.00")} บาท`
                                : false
                            }
                          >
                            {getFieldDecorator("member_price", {
                              initialValue: data?.member_price,
                              rules: [
                                {
                                  message: `กรุณาระบุ${
                                    ["PT08", "C05", "PT02"].includes(
                                      data?.product_type
                                    )
                                      ? "ราคาโปรโมชั่นสำหรับ VIP Member"
                                      : "ราคาสำหรับ VIP Member"
                                  }`,
                                  min: 0,
                                  type: "number",
                                },
                              ],
                            })(
                              <InputNumber
                                className="w-100"
                                min={0}
                                step="0.01"
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      )}
                    <Col md={8}>
                      <Form.Item
                        label={`${
                          ["PT08", "C05", "PT02"].includes(data?.product_type)
                            ? "ราคาควบคุมชุดโปรโมชั่น"
                            : "ราคาควบคุม"
                        } (บาท)`}
                        help={
                          ["PT08", "C05", "PT02"].includes(data?.product_type)
                            ? `ราคารวมปกติ ${numeral(
                                _.reduce(
                                  bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                  (sum, n) =>
                                    (sum +=
                                      (n?.control_price || 0) * (n?.qty || 0)),
                                  0
                                )
                              ).format("0,0.00")} บาท`
                            : false
                        }
                      >
                        {getFieldDecorator("control_price", {
                          initialValue: data?.control_price,
                          rules: [
                            {
                              message: `กรุณาระบุ${
                                ["PT08", "C05", "PT02"].includes(
                                  data?.product_type
                                )
                                  ? "ราคาควบคุมชุดโปรโมชั่น"
                                  : "ราคาควบคุม"
                              } `,
                              min: 0,
                              type: "number",
                            },
                          ],
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item
                        label={`${
                          ["PT08", "C05", "PT02"].includes(data?.product_type)
                            ? "ราคาโปรโมชั่นสำหรับพนักงาน"
                            : "ราคาสำหรับพนักงาน"
                        } (บาท)`}
                        help={
                          ["PT08", "C05", "PT02"].includes(data?.product_type)
                            ? `ราคารวมปกติ ${numeral(
                                _.reduce(
                                  bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                  (sum, n) =>
                                    (sum +=
                                      (n?.staff_price || 0) * (n?.qty || 0)),
                                  0
                                )
                              ).format("0,0.00")} บาท`
                            : false
                        }
                      >
                        {getFieldDecorator("staff_price", {
                          initialValue: data?.staff_price,
                          rules: [
                            {
                              message: `กรุณาระบุ${
                                ["PT08", "C05", "PT02"].includes(
                                  data?.product_type
                                )
                                  ? "ราคาโปรโมชั่นสำหรับพนักงาน"
                                  : "ราคาสำหรับพนักงาน"
                              } `,
                              min: 0,
                              type: "number",
                            },
                          ],
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item
                        label="ราคาแนะนำ (บาท)"
                        help={
                          ["PT08", "C05", "PT02", "PT06"].includes(
                            data?.product_type
                          )
                            ? `ราคารวมปกติ ${numeral(
                                _.reduce(
                                  bom?.itemKeys.map((n) => bom?.itemByKey[n]),
                                  (sum, n) =>
                                    (sum +=
                                      (n?.cost_price || 0) * (n?.qty || 0)),
                                  0
                                )
                              ).format("0,0.00")} บาท`
                            : false
                        }
                      >
                        {getFieldDecorator("customer_price", {
                          initialValue: data?.customer_price,
                        })(
                          <InputNumber
                            className="w-100"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {((match.params.id !== "0" &&
                      parseFloat(user?.rank) >= 6) ||
                      match.params.id === "0" ||
                      user?.id === data?.create_by_id) && (
                      <>
                        <Col md={8}>
                          <Form.Item
                            label="ราคาต้นทุนไม่รวมภาษี (บาท)"
                            help={
                              ["PT08", "C05", "PT02", "PT06"].includes(
                                data?.product_type
                              )
                                ? `ราคารวมปกติ ${numeral(
                                    _.reduce(
                                      bom?.itemKeys.map(
                                        (n) => bom?.itemByKey[n]
                                      ),
                                      (sum, n) =>
                                        (sum +=
                                          (n?.cost_price || 0) * (n?.qty || 0)),
                                      0
                                    )
                                  ).format("0,0.00")} บาท`
                                : false
                            }
                          >
                            {getFieldDecorator("cost_price", {
                              initialValue: data?.cost_price,
                            })(
                              <InputNumber
                                className="w-100"
                                min={0}
                                step="0.01"
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col md={8}>
                          <Form.Item
                            label="ราคาต้นทุนรวมภาษี (บาท)"
                            help={
                              ["PT08", "C05", "PT02", "PT06"].includes(
                                data?.product_type
                              )
                                ? `ราคารวมปกติ ${numeral(
                                    _.reduce(
                                      bom?.itemKeys.map(
                                        (n) => bom?.itemByKey[n]
                                      ),
                                      (sum, n) =>
                                        (sum +=
                                          (n?.cost_price_inc_vat || 0) *
                                          (n?.qty || 0)),
                                      0
                                    )
                                  ).format("0,0.00")} บาท`
                                : false
                            }
                          >
                            {getFieldDecorator("cost_price_inc_vat", {
                              initialValue: data?.cost_price_inc_vat,
                            })(
                              <InputNumber
                                className="w-100"
                                min={0}
                                step="0.01"
                                disabled={
                                  (match.params.id === "0" ||
                                    data?.show_save === 1) === false
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>

                <div>
                  <Divider>ส่วนต่างเงินคืน</Divider>
                  <Row gutter={16}>
                    <Col md={8}>
                      {data?.product_type == "PT05" ? (
                        <Form.Item label="เงินคืนที่นักธุรกิจจะได้รับ จากการซื้อในรหัสตนเอง (บาท)">
                          {getFieldDecorator("d_point_08", {
                            initialValue: data?.d_point_08,
                            onChange: (value) => {
                              dispatch(SET_FORM({ d_point_08: value }));
                            },
                          })(
                            <InputNumber
                              className="w-100 text-dark"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      ) : (
                        <Form.Item label="เงินคืนที่นักธุรกิจจะได้รับ จากการซื้อในรหัสตนเอง (บาท)">
                          {getFieldDecorator("point_04", {
                            initialValue: data?.point_04,
                            onChange: (value) => {
                              dispatch(SET_FORM({ point_04: value }));
                            },
                          })(
                            <InputNumber
                              className="w-100 text-dark"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      )}
                    </Col>
                    <Col md={8}>
                      {/* <Form.Item label="% ค่าธรรมเนียม ค่าดำเนินการ" >
                      {getFieldDecorator("tax_rate", {
                        initialValue: data?.tax_rate,
                        onChange: (value) =>{
                          dispatch(SET_FORM({ tax_rate: value }));
                        },
                      })(
                      <SelectTaxRefund
                        className="w-100 text-dark"
                          disabled={
                          (match.params.id === "0" ||
                            data?.show_save === 1) === false
                        }
                      />
                      
                      )}
                    </Form.Item> */}
                      <Form.Item label="เงินคืนที่นักธุรกิจผู้แนะนำจะได้รับจากการซื้อของ VIP Member (บาท)">
                        {getFieldDecorator("r_point_10", {
                          initialValue: data?.r_point_10,
                          onChange: (value) => {
                            dispatch(SET_FORM({ r_point_10: value }));
                          },
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      {/* <Form.Item label="% ภาษีมูลค่าเพิ่ม" >
                      {getFieldDecorator("vat_rate", {
                        initialValue: data?.vat_rate,
                      })(
                      <SelectVatRefund
                        className="w-100 text-dark"
                        disabled={
                          (match.params.id === "0" ||
                            data?.show_save === 1) === false
                        }
                      />
                      
                      )}
                    </Form.Item> */}
                      <Form.Item label="เงินคืนที่นักธุรกิจจะได้รับ จากลูกค้าที่ซื้อผ่านลิงก์ร้านค้า (บาท)">
                        {getFieldDecorator("r_point_11", {
                          initialValue: data?.r_point_11,
                          onChange: (value) => {
                            dispatch(SET_FORM({ r_point_11: value }));
                          },
                        })(
                          <InputNumber
                            className="w-100 text-dark"
                            min={0}
                            step="0.01"
                            disabled={
                              (match.params.id === "0" ||
                                data?.show_save === 1) === false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                {/* Redeem */}
                {["Redeem"].includes(data?.distribution_type) && (
                  <div>
                    <Divider>ข้อมูลคะแนนที่ใช้แลก</Divider>
                    <Typography.Text strong>
                      กรณีแลกคะแนนทั้งหมด
                    </Typography.Text>
                    <Row gutter={16}>
                      <Col md={8}>
                        <Form.Item label="คะแนนที่ใช้แลกสำหรับ VIP Member (RP)">
                          {getFieldDecorator("member_redeem", {
                            initialValue: data?.member_redeem,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item label="คะแนนที่ใช้แลกสำหรับนักธุรกิจ (RP)">
                          {getFieldDecorator("distributor_redeem", {
                            initialValue: data?.distributor_redeem,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <Typography.Text strong>
                      กรณีแลกคะแนน + เงิน
                    </Typography.Text>
                    <Row gutter={16}>
                      <Col md={24}>
                        <Typography.Text className="d-block">
                          สำหรับ VIP Member
                        </Typography.Text>
                      </Col>
                      <Col md={8}>
                        <Form.Item label="คะแนน (RP)">
                          {getFieldDecorator("member_redeem_point", {
                            initialValue: data?.member_redeem_point,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item label="เงิน (บาท)">
                          {getFieldDecorator("member_redeem_price", {
                            initialValue: data?.member_redeem_price,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col md={24}>
                        <Typography.Text className="d-block">
                          สำหรับนักธุรกิจ
                        </Typography.Text>
                      </Col>
                      <Col md={8}>
                        <Form.Item label="คะแนน (RP)">
                          {getFieldDecorator("distributor_redeem_point", {
                            initialValue: data?.distributor_redeem_point,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item label="เงิน (บาท)">
                          {getFieldDecorator("distributor_redeem_price", {
                            initialValue: data?.distributor_redeem_price,
                          })(
                            <InputNumber
                              className="w-100"
                              min={0}
                              step="0.01"
                              disabled={
                                (match.params.id === "0" ||
                                  data?.show_save === 1) === false
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row> */}
                  </div>
                )}

                {/* Upload */}
                <div>
                  <Divider className="py-3">อัปโหลดรูปและวิดีโอ</Divider>

                  <Row gutter={16} className="mb-2">
                    <Col md={24}>
                      <ImgUpload />
                    </Col>
                  </Row>
                </div>
              </Col>
              {/* Right Panel */}
              <Col md={8}>
                <Divider>ประเภทผู้ซื้อ</Divider>
                <Row gutter={16} className="mb-2">
                  <Col className="d-flex justify-content-between">
                    <Typography.Text>สินค้าขายสำหรับนักธุรกิจ</Typography.Text>
                    <Switch
                      checked={data?.distributor_sale === 1}
                      onClick={(checked) =>
                        dispatch(
                          SET_FORM({ distributor_sale: checked ? 1 : 0 })
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-2">
                  <Col className="d-flex justify-content-between">
                    <Typography.Text>
                      สินค้าขายสำหรับ VIP Member
                    </Typography.Text>
                    <Switch
                      checked={data?.member_sale === 1}
                      onClick={(checked) =>
                        dispatch(SET_FORM({ member_sale: checked ? 1 : 0 }))
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-2">
                  <Col className="d-flex justify-content-between">
                    <Typography.Text>สินค้าขายสำหรับพนักงาน</Typography.Text>
                    <Switch
                      checked={data?.staff_sale === 1}
                      onClick={(checked) =>
                        dispatch(SET_FORM({ staff_sale: checked ? 1 : 0 }))
                      }
                    />
                  </Col>
                </Row>

                <Divider>ช่องทางการสั่งซื้อ</Divider>
                <OrderSource />
                {/* <Row gutter={16} className="mb-2">
                  <Col className="d-flex justify-content-between">
                    <Typography.Text>Legacy Shop</Typography.Text>
                    <div>
                      <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        className="mr-2"
                      >
                        <FaPencilAlt />
                      </Button>
                      <Switch />
                    </div>
                  </Col>
                </Row> */}

                {/* <Divider>สินค้า Pre-Order</Divider>
                <Row gutter={16} className="mb-2">
                  <Col className="d-flex justify-content-between">
                    <Typography.Text>Pre-Order</Typography.Text>
                    <Switch
                      checked={data?.is_pre_order === 1}
                      onClick={(checked) =>
                        dispatch(SET_FORM({ is_pre_order: checked ? 1 : 0 }))
                      }
                    />
                  </Col>
                </Row> */}

                <Divider>สถานะการใช้งาน</Divider>
                <Row gutter={16} className="mb-2">
                  <Col className="d-flex justify-content-between">
                    <Typography.Text>สถานะ</Typography.Text>
                    <Switch
                      checked={data?.status?.toLowerCase() === "active"}
                      onClick={(checked) =>
                        dispatch(
                          SET_FORM({ status: checked ? "Active" : "In-Active" })
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </PanelBody>
    </Panel>
  );
};

export default Form.create("product")(ProductForm);
