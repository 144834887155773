/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Popconfirm, Progress, Spin, Upload } from "antd";
import React, { memo, useCallback, useContext, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { MdDelete, MdPhotoCamera } from "react-icons/md";
import { RiFileUploadLine } from "react-icons/ri";
import styles from "../../scss/module/claim.module.scss";
import { axios, getToken, URL_API } from "../../utils";
import { beforeUploadFile, resCatchModal } from "../../views/util/helper";
import CameraModal from "../CameraModal";
import UploadContext from "./reducer";

const AddModal = ({ camera, containerName, handleSubmit }) => {
  const { state, dispatch } = useContext(UploadContext);
  const {
    file,
    fileOption,
    upLoadProgress,
    showAdd,
    showCamera,
    loading,
    deleting,
  } = state;
  const [source, setSource] = useState(axios.CancelToken.source());

  const beforeUpload = (file) => beforeUploadFile(file, fileOption);

  const handleUpload = useCallback(
    (e) => {
      dispatch({
        type: "setStateMulti",
        payload: { showCamera: false, loading: true },
      });
      const data = new FormData();
      data.append("file", e.file);
      data.append("container_name", containerName);
      axios({
        method: "post",
        url: `${URL_API}/file/upload`,
        headers: { Authorization: "Bearer " + getToken() },
        data,
        cancelToken: source.token,
        onUploadProgress: (e) =>
          dispatch({
            type: "setState",
            name: "upLoadProgress",
            payload: ~~((e.loaded * 100) / e.total),
          }),
      })
        .then((res) =>
          dispatch({
            type: "setState",
            name: "file",
            payload: { ...res.data.data, temp: true },
          })
        )
        .catch((e) => resCatchModal(e))
        .finally(() => {
          setSource(axios.CancelToken.source());
          dispatch({
            type: "setStateMulti",
            payload: { loading: false, upLoadProgress: 0 },
          });
        });
    },
    [source.token, containerName]
  );

  const handleDelete = () => {
    dispatch({
      type: "setState",
      name: "deleting",
      payload: true,
    });
    axios({
      method: "post",
      url: `${URL_API}/file/del`,
      headers: { Authorization: "Bearer " + getToken() },
      data: { id: file.id },
      cancelToken: source.token,
    })
      .then(() => dispatch({ type: "clearGlobalState", payload: "file" }))
      .catch((e) => resCatchModal(e))
      .finally(() => {
        setSource(axios.CancelToken.source());
        dispatch({
          type: "setState",
          name: "deleting",
          payload: false,
        });
      });
  };

  const handleCloseCamera = useCallback(
    () => dispatch({ type: "setState", name: "showCamera", payload: false }),
    []
  );

  return (
    <Modal
      destroyOnClose
      visible={showAdd}
      closable={!loading}
      maskClosable={false}
      footer={false}
      zIndex={1080}
      width={700}
      onCancel={() => {
        if (!file.id)
          return dispatch({
            type: "clear",
          });
        Modal.confirm({
          title: "ยินยันทำรายการ",
          content: "คุณต้องการยกเลิกอัปโหลดหรือไม่",
          okText: "ตกลง",
          cancelText: "ยกเลิก",
          zIndex: 1080,
          onOk() {
            dispatch({
              type: "clear",
            });
          },
        });
      }}
    >
      <div className={styles["auth-doc"]}>
        {file.id && !deleting ? (
          <div className={`${styles.block} ${styles.img}`}>
            <img
              className="w-100 h-100 object-fit-cover"
              src={file.azure_url}
              alt="authority-doc"
            />
            <div className={`${styles.loading} ${styles.delete}`}>
              <Popconfirm
                placement="topRight"
                title="ต้องการลบรายการนี้หรือไม่"
                onConfirm={handleDelete}
                okText="ลบ"
                cancelText="ไม่ลบ"
                zIndex={1080}
              >
                <MdDelete className={styles.icon} />
              </Popconfirm>
            </div>
          </div>
        ) : file.id && deleting ? (
          <div className={`${styles.block} ${styles.btn}`}>
            <Spin spinning />
            <div className="mt-2">deleting...</div>
            <div className={styles.add}>
              <FaTimes
                className={styles.icon}
                onClick={() => {
                  source.cancel("cancel");
                  setSource(axios.CancelToken.source());
                }}
              />
            </div>
          </div>
        ) : loading ? (
          <div className={`${styles.block} ${styles.btn}`}>
            <Progress
              type="circle"
              percent={upLoadProgress}
              strokeColor="#97d700"
            />
            <div className={styles.add}>
              <FaTimes
                className={styles.icon}
                onClick={() => {
                  source.cancel("cancel");
                  setSource(axios.CancelToken.source());
                }}
              />
            </div>
          </div>
        ) : (
          <div className={`${styles.block} ${styles.btn}`}>
            <AiOutlineUpload className="mb-2" />
            <div>อัปโหลด</div>
            <div className={styles.add}>
              <Upload
                name="claim-img"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={handleUpload}
              >
                <RiFileUploadLine className={styles.icon} />
              </Upload>
              {camera && (
                <MdPhotoCamera
                  className={styles.icon}
                  onClick={() =>
                    dispatch({
                      type: "setState",
                      name: "showCamera",
                      payload: true,
                    })
                  }
                />
              )}
            </div>
          </div>
        )}
        <div className="w-100 d-flex justify-content-center pt-3">
          <Button
            type="primary"
            disabled={!file.id}
            onClick={() => handleSubmit(file)}
          >
            ตกลง
          </Button>
        </div>
      </div>
      {fileOption.acceptImage && camera && (
        <CameraModal
          visible={showCamera}
          allowChangeCamera
          onSubmit={handleUpload}
          onCancel={handleCloseCamera}
        />
      )}
    </Modal>
  );
};

export default memo(AddModal);
