import { Col, DatePicker, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../../redux/slices/claim";
import { moment } from "../../../../../../utils";

const { setClaimItemValue } = actionClaim;

const SelectDate = ({ type, disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const date = useSelector(
    (state) =>
      state.claim.billProduct.itemByKey[product_key]?.claim_item.itemByKey[
      state.claim.billProduct.itemByKey[product_key].claim_item.activeKey
      ][type]
  );
  const title = type === "mfg_date" ? "วันผลิต" : "วันหมดอายุ";

  const selectedDate = useSelector(
    (state) =>
      state.claim.billProduct.itemByKey[product_key]?.claim_item.itemByKey[
      state.claim.billProduct.itemByKey[product_key].claim_item.activeKey
      ]
  );

  const disabledExp = (current) => {
    return current && current < moment(selectedDate?.mfg_date).endOf("day");
  };

  const disabledMfg = (current) => {
    // return current && current > moment(selectedDate?.exp_date).endOf("day");
    let select_date = selectedDate?.exp_date;
    let isExp = current && current > moment(select_date).endOf("day");
    let isNow = current && current > moment().endOf("day");
    return select_date === null ? isNow : isExp ? isExp : isNow;
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={6} className="text-right">
        <Typography.Text>{title}</Typography.Text>
      </Col>
      <Col span={18}>
        <DatePicker
          format="DD/MM/YYYY"
          placeholder={`เลือก${title}`}
          className="w-100"
          value={date && moment(date)}
          disabledDate={type === "exp_date" ? disabledExp : disabledMfg}
          disabled={disabled}
          onChange={(e) => dispatch(setClaimItemValue({ [type]: e }))}
          size="small"
        />
      </Col>
    </Row>
  );
};

export default memo(SelectDate);
