/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Select, message, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useEventMutate } from "../../../../../contexts/events.context";
import SelectEvent from "./SelectEvent";
import SelectHonor from "./SelectHonor";
import SelectTeam from "./SelectTeam";
import { eventRequest } from "../../../../../utils/useAxios";
import xlsx from "xlsx";
import moment from "moment";
import _, { orderBy } from "lodash";
import { getStringLiteral } from "../../../../../utils/helper";
import { useHistory } from "react-router-dom";
import ImportTicketModal from "./ImportTicketModal";
import ImportRegisterModal from "./ImportRegisterModal";

const CriteriaTicket = ({ form }) => {
  const {
    state: { search, exporting, setStorage },
    dispatch,
  } = useEventMutate();

  const [storageObj, setStorageObj] = useState({});
  const [openImportTicketModal, setOpenImportTicketModal] = useState(false);
  const [openImportRegModal, setOpenImportRegModal] = useState(false);

  const { getFieldDecorator, validateFields } = form;
  const history = useHistory();
  const previousPath = history.location.state
    ? history.location.state.from
    : "";

  const handleChange = (name, value) => {
    dispatch({
      type: "SET_SEARCH",
      payload: {
        [name]: value,
        page: 1,
      },
    });

    handleSetLocalStorageWithExpiration(name, value);
  };

  const exportExcel = async () => {
    try {
      dispatch({ type: "SET_EXPORTING", payload: true });
      const res = await eventRequest.get("/tickets-event/export", {
        params: {
          search: search.query,
          eventId: search.event_id,
          status: search.status,
          honor_code: search.honor_code,
          team_name: search.team_name,
          serial: search.serial,
          member_code: search.member_code,
        },
      });

      const data = res.data?.items || [];

      if (data.length === 0) {
        throw new Error("ไม่พบข้อมูล");
      }

      const wb = xlsx.utils.book_new();
      const dataWS = xlsx.utils.json_to_sheet(
        _.map(data, (n, i) => ({
          ลำดับ: i + 1,
          เลขที่บิล: n?.DocumentNo,
          วันที่บิล:
            n?.DocumentedAt &&
            moment(n.DocumentedAt).format("DD/MM/YYYY HH:mm"),
          รหัสสินค้า: n?.ProductCode,
          รหัสนักธุรกิจ_ผู้สั่งซื้อ: n?.OrderedBy,
          ชื่อนักธุรกิจ_ผู้สั่งซื้อ: n?.OrderedByName,

          ชื่องาน: n?.Event?.EventName,
          รหัสบัตรงาน: n?.Serial,
          รหัสผู้ใช้บัตร: n?.RegisterEvent?.MemberCode, // !n?.RegisterEvent?.RefName &&
          ชื่อผู้ใช้บัตร: getStringLiteral(
            n?.RegisterEvent?.FirstName,
            n?.RegisterEvent?.LastName
          ),
          บัตรประชาชนผู้ใช้บัตร: n?.RegisterEvent?.PersonalNo,
          เบอร์โทรศัพท์ผู้ใช้บัตร: n?.RegisterEvent?.PhoneNo,
          ตำแหน่ง: n?.RegisterEvent?.HonorCode,
          องค์กร: n?.RegisterEvent?.TeamName,
          รหัสผู้แนะนำ: n?.RegisterEvent?.RefCode,
          // n?.RegisterEvent?.RefName && n?.RegisterEvent?.MemberCode,
          ชื่อผู้แนะนำ: n?.RegisterEvent?.RefName,
          วันที่ลงทะเบียน:
            n?.RegisterEventId &&
            moment(n?.RegisterEvent.CreatedAt).format("DD/MM/YYYY HH:mm"),
          วันที่เข้าร่วมงาน:
            n?.RegisterEvent?.UsedAt &&
            moment(n?.RegisterEvent?.UsedAt).format("DD/MM/YYYY HH:mm"),
          สถานะ: n?.UsedStatus ? "ลงทะเบียนแล้ว" : "ว่าง",
          หมายเหตุ: n?.Remark,
          ลิงก์: `${process.env.REACT_APP_EVENT_URL}/register?serial_no=${n?.Serial}&event_id=${n?.EventId}`,
        })),
        {
          raw: false,
        }
      );

      xlsx.utils.book_append_sheet(wb, dataWS, "ticket");

      xlsx.writeFile(
        wb,
        "TICKET_" + moment().format("YYYYMMDD_HHmm") + ".xlsx"
      );
      dispatch({ type: "SET_EXPORTING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_EXPORTING", payload: false });
      message.error(error.message);
    }
  };

  const exportTemplateTicketExcel = async () => {
    try {
      dispatch({ type: "SET_EXPORTING", payload: true });

      const wb = xlsx.utils.book_new();
      const dataWS = xlsx.utils.json_to_sheet(
        [{ partner_code: "", total: "" }],
        {
          header: ["partner_code", "total"],
          skipHeader: false,
        }
      );

      xlsx.utils.book_append_sheet(wb, dataWS, "ticket");

      xlsx.writeFile(wb, "ticket_template.xlsx");

      dispatch({ type: "SET_EXPORTING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_EXPORTING", payload: false });
      message.error(error.message);
    }
  };
  const handleSetLocalStorageWithExpiration = (key, value) => {
    const dataToStore = {
      key: "ticket_" + key,
      value,
      expiration: new Date().getTime() + 24 * 60 * 60 * 1000, // 24 hours in milliseconds
    };
    localStorage.setItem(dataToStore.key, JSON.stringify(dataToStore));
  };

  const handleGetLocalStorage = (key) => {
    const storedData = JSON.parse(localStorage.getItem("ticket_" + key));
    if (storedData) {
      const currentTime = new Date().getTime();
      if (currentTime < storedData.expiration) {
        return {
          [key]: storedData.value,
        };
      }
    }
    return {};
  };

  useEffect(() => {
    if (!previousPath || previousPath !== "/events/edit-register") {
      for (const key in localStorage) {
        if (key.startsWith("ticket_")) {
          localStorage.removeItem(key);
        }
      }
    }

    const keys = [
      "event_id",
      "status",
      "honor_code",
      "team_name",
      "serial",
      "member_code",
      "query",
    ];
    let obj = {};
    for (const key of keys) {
      const getStorage = handleGetLocalStorage(key);
      if (Object.keys(getStorage).length > 0) {
        obj = {
          ...obj,
          ...getStorage,
        };
      }
    }
    setStorageObj(obj);
    dispatch({
      type: "SET_SEARCH",
      payload: {
        ...obj,
        page: 1,
      },
    });
  }, []);

  const handleCloseImportTicket = () => {
    setOpenImportTicketModal(false);
  };

  const handleImportTicket = () => {
    setOpenImportTicketModal(true);
  };

  const handleCloseImportReg = () => {
    setOpenImportRegModal(false);
  };

  const handleImportReg = () => {
    setOpenImportRegModal(true);
  };

  useEffect(() => {
    if (form && Object.keys(storageObj).length > 0 && setStorage) {
      form.setFieldsValue(storageObj);
    }
  }, [storageObj, setStorage]);

  return (
    <>
      <Form>
        <ImportTicketModal
          open={openImportTicketModal}
          onCancel={handleCloseImportTicket}
          setOpenImportTicketModal={setOpenImportTicketModal}
        />
        <ImportRegisterModal
          open={openImportRegModal}
          onCancel={handleCloseImportReg}
          setOpenImportRegModal={setOpenImportRegModal}
        />

        <Row gutter={16} className="mb-3">
          <Col md={5}>
            <SelectEvent
              className="w-100"
              placeholder="งาน"
              value={search.event_id}
              onSelect={(e) => handleChange("event_id", e)}
              withAll
              allText="งานทั้งหมด"
            />
          </Col>
          <Col md={5}>
            <Select
              className="w-100"
              placeholder="สถานะ"
              value={search.status}
              onSelect={(e) => handleChange("status", e)}
            >
              <Select.Option value="">สถานะทั้งหมด</Select.Option>
              <Select.Option value={0}>ว่าง</Select.Option>
              <Select.Option value={1}>ลงทะเบียนแล้ว</Select.Option>
            </Select>
          </Col>
          <Col md={5}>
            <SelectHonor
              className="w-100"
              placeholder="ตำแหน่ง"
              value={search.honor_code}
              onSelect={(e) => handleChange("honor_code", e)}
              withAll
              allText="ตำแหน่งทั้งหมด"
            />
          </Col>
          <Col md={5}>
            <SelectTeam
              className="w-100"
              placeholder="องค์กร"
              value={search.team_name}
              onSelect={(e) => handleChange("team_name", e)}
              withAll
              allText="องค์กรทั้งหมด"
            />
          </Col>
        </Row>
        <Row gutter={16} className="mb-3">
          <Col md={3}>
            <Form.Item>
              {getFieldDecorator(
                "serial",
                {}
              )(
                <Input.Search
                  placeholder="รหัสบัตรงาน"
                  allowClear
                  onSearch={(e) => handleChange("serial", e || "")}
                />
              )}
            </Form.Item>
            {/* <Input.Search
              placeholder="รหัสบัตรงาน"
              allowClear
              value={search.serial}
              onSearch={(e) => handleChange("serial", e || "")}
            /> */}
          </Col>
          <Col md={3}>
            <Form.Item>
              {getFieldDecorator(
                "member_code",
                {}
              )(
                <Input.Search
                  placeholder="รหัสสมาชิก"
                  allowClear
                  onSearch={(e) => handleChange("member_code", e || "")}
                />
              )}
            </Form.Item>
            {/* <Input.Search
              placeholder="รหัสสมาชิก"
              allowClear
              value={search.member_code}
              onSearch={(e) => handleChange("member_code", e || "")}
            /> */}
          </Col>
          <Col md={14}>
            <Form.Item>
              {getFieldDecorator(
                "query",
                {}
              )(
                <Input.Search
                  placeholder="รหัสบัตรงาน, รหัสสมาชิก, เลขบัตรประชาชน, เบอร์โทรศัพท์"
                  allowClear
                  onSearch={(e) => handleChange("query", e || "")}
                />
              )}
            </Form.Item>
            {/* <Input.Search
              placeholder="รหัสบัตรงาน, รหัสสมาชิก, เลขบัตรประชาชน, เบอร์โทรศัพท์"
              allowClear
              defaultValue={search.query}
              onSearch={(e) => handleChange("query", e || "")}
            // onChange={(e) => handleChange("query", e.target.value || "")}
            /> */}
          </Col>
          <Col md={4}>
            <Button
              type="primary"
              block
              onClick={exportExcel}
              loading={exporting}
            >
              Export Excel
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16} className="mb-3">
          <Col md={12}>
            <Button
              type="dashed"
              block
              onClick={exportTemplateTicketExcel}
              loading={exporting}
              style={{
                width: "fit-content",
              }}
            >
              Template สำหรับสร้าง Ticket
            </Button>
          </Col>
          <Col md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "16px",
              }}
            >
              <Button
                type="dashed"
                block
                onClick={handleImportTicket}
                style={{
                  width: "fit-content",
                }}
              >
                นำเข้าข้อมูลสำหรับสร้าง Ticket
              </Button>
              <Button
                block
                onClick={handleImportReg}
                style={{
                  width: "fit-content",
                  border: "1px solid #1890ff",
                  color: "#1890ff",
                }}
              >
                นำเข้าข้อมูลการลงทะเบียน
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Form.create()(CriteriaTicket);
