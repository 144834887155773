import React, { useState } from "react";
import { Row, Col, Button, notification } from "antd";
import { resCatch } from "../views/util/helper";
import { moment } from "../utils";
import DomToImage from "dom-to-image";

const { ClipboardItem } = window;

const Capture = ({ previewRef }) => {
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [loadingCapture, setLoadingCapture] = useState(false);
  const handlePrint = async () => {
    const preview = previewRef.current;
    setLoadingPrint(true);
    try {
      const img = await DomToImage.toPng(preview);
      let a = document.createElement("a");
      a.download = `${moment().format("YYYY-MM-DD-HH-mm-ss")}.png`;
      a.href = img;
      a.click();
    } catch (e) {
      resCatch(e);
    }
    setLoadingPrint(false);
  };
  const handleCapture = async () => {
    const preview = previewRef.current;
    setLoadingCapture(true);
    try {
      const blob = await DomToImage.toBlob(preview);
      navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
      notification.success({ message: "คัดลอกไปยังคลิปบอร์ดแล้ว" });
    } catch (e) {
      resCatch(e);
    }
    setLoadingCapture(false);
  };
  return (
    <Row gutter={8} className="mt-3">
      <Col span={24} className="d-flex justify-content-center">
        <Button
          className="bg-pv border-pv text-white mr-3"
          loading={loadingPrint}
          onClick={handlePrint}
        >
          พิมพ์ใบจอง
        </Button>
        <Button
          className="bg-tp border-tp text-white"
          loading={loadingCapture}
          onClick={handleCapture}
        >
          แคปหน้าจอ
        </Button>
      </Col>
    </Row>
  );
};

export default Capture;
