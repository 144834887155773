import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Register from './monthly-register';
import Sales from './monthly-sales';
import Branch from './branch-sales';
import Reborn from './reborn-sales';
import DP from './dp-sales';
import Test from './test';

import Error404 from '../Error404';

export default ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.path}/monthly_register`} component={Register} />
            <Route path={`${match.path}/monthly_sales`} component={Sales} />
            <Route path={`${match.path}/branch_sales`} component={Branch} />
            <Route path={`${match.path}/reborn_sales`} component={Reborn} />
            <Route path={`${match.path}/dp_sales`} component={DP} />
            <Route path={`${match.path}/test`} component={Test} />
            {/* <Route path={`${match.path}/dp_sales`} component={DP} /> */}
            <Route component={Error404} />
        </Switch>
    )
}
