import { Col, Form, Row, Switch } from "antd";
import React from "react";

const UsageStatus = ({ getFieldDecorator }) => (
  <Row gutter={16}>
    <Col md={24} className="d-flex justify-content-between">
      <label className="mb-0">ใช้งาน</label>
      <Form.Item className="mb-0">
        {getFieldDecorator("status", {
          valuePropName: "checked",
          initialValue: false,
        })(<Switch />)}
      </Form.Item>
    </Col>
  </Row>
);

export default UsageStatus;
