import React from "react";
import BillItems from "./BillItems";
import { Divider } from "antd";
import BillVoucher from "./BillVoucher";
import BillFooter from "./BillFooter";
import BillDiscount from "./BillDiscount";
import { withRouter } from "react-router";

const BillDetail = ({ match }) => {
  const { type } = match.params;
  const isNormal = ["sale", "pre-sale", "subscription"].includes(type);
  return (
    <div className="pos-payment-bill-detail py-2">
      <BillItems isNormal={isNormal} />

      <div className="px-4">
        <Divider className="my-2 px-4" />
      </div>

      {isNormal && (
        <>
          <BillVoucher />

          <div className="px-4">
            <Divider className="my-2" />
          </div>

          <BillDiscount />

          <div className="px-4">
            <Divider className="my-2" />
          </div>
        </>
      )}

      <BillFooter isNormal={isNormal} />
    </div>
  );
};

export default withRouter(BillDetail);
