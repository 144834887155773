/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Row,
  Table,
  Switch,
  List,
  Modal,
  message,
  Spin,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApi } from "../../../../../../hooks/http2";
import {
  actionMKTProduct,
  searchSelector,
} from "../../../../../../redux/slices/marketing/product";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { FaPlus, FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import ReactDragListView from "react-drag-listview";
import useAsync from "../../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../../services/manage-website.service";
import {
  AlertConfirm,
  AlertError,
  AlertSuccess,
} from "../../../../../util/Alert";

export default function HF_NutritionList({ history, match }) {
  const [seq, setSeq] = useState([]);
  const [data, setData] = useState([]);
  const headData = [
    {
      no: "No.",
      menu: "Menu",
      status: "Public Status",
      start: "Date Start",
      end: "Date End",
      update: "Update Date",
      manage: "Manage",
    },
  ];

  const notFoundImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBTEghTW2XOwmp-PbaaC76eKvC7jH61oZfDQ&usqp=CAU";

  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { SET_SEARCH } = actionMKTProduct;
  const list = useApi(
    {
      baseURL: URL_API,
      url: "/manage-website/ecosystem/healthy-food/hf-nutrition",
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
      token: true,
    },
    [search]
  );

  const { execute: deleteHF_NutritionById } = useAsync(
    manageWebsiteService.deleteHF_NutritionById,
    {
      onSuccess: async () => {
        await AlertSuccess("ลบข้อมูลสำเร็จ");
        loadContent();
      },
      onFailed: () => {
        AlertError();
      },
    }
  );

  const { execute: updateNutritionSeqById } = useAsync(
    manageWebsiteService.updateNutritionSeqById,
    {
      onSuccess: async () => {
        await loadContent();
      },
    }
  );

  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    setData(items);
    for (let i = 0; i < items.length; i++) {
      let x = i + 1;
      let myId = _.toNumber(items[i].id);
      updateNutritionSeqById({ myId, x });
    }
    setTimeout(() => {
      message.success({ content: "Saved!", duration: 0.8 });
    }, 180);
  };

  const handleChangeStatus = (checked, id) => {
    axios({
      method: "post",
      baseURL: URL_API,
      url: `/manage-website/ecosystem/healthy-food/hf-nutrition/${id}/status`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        status: checked ? "active" : "inactive",
      },
    })
      .then(() => {
        loadContent();
        setTimeout(() => {
          message.success({ content: "Saved!", duration: 0.45 });
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/ecosystem/healthy-food/hf-nutrition",
      params: {
        filter: 1,
      },
    })
      .then((res) => {
        let loadData = res.data?.data;
        setData(loadData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <Panel>
        <PanelHeader>โภชนาการ</PanelHeader>
        <PanelBody>
          <Row type="flex" justify="end" gutter={16} className="mb-4">
            <Col>
              <Button
                type="primary"
                onClick={() => history.push(`${match.path}/add`)}
              >
                <FaPlus className="mr-2" /> เพิ่มข้อมูล
              </Button>
            </Col>
          </Row>
          <Row>
            <List
              size="small"
              bordered
              dataSource={headData}
              renderItem={(item) => {
                const draggble = item.title !== "XXX";
                return (
                  <List.Item className={draggble ? "draggble" : ""}>
                    <List.Item.Meta title={item.no} />
                    <List.Item.Meta title={item.menu} />
                    <List.Item.Meta title={item.status} />
                    <List.Item.Meta title={item.start} />
                    <List.Item.Meta title={item.end} />
                    <List.Item.Meta title={item.update} />
                    <List.Item.Meta title={item.manage} />
                  </List.Item>
                );
              }}
            ></List>
          </Row>

          <Row>
            <ReactDragListView
              nodeSelector=".ant-list-item.draggble"
              onDragEnd={onDragEnd}
            >
              <List
                style={{ cursor: "grab" }}
                size="small"
                bordered
                dataSource={data}
                pagination={{
                  size: "default",
                  showLessItems: true,
                  showTotal: (total, range) =>
                    `${range[0]} - ${range[1]} of ${total}`,
                  total: list?.fetch?.total || 0,
                  current: search.page,
                  pageSize: search.limit,
                  onChange: (page) => dispatch(SET_SEARCH({ page: page })),
                }}
                renderItem={(item) => {
                  const draggble = item.title !== "XXX";
                  return (
                    <List.Item
                      // actions={["x"]}
                      className={draggble ? "draggble" : ""}
                    >
                      <List.Item.Meta
                        title={item.row_id}
                        // description={<TestModal />}
                      />
                      <List.Item.Meta title={item.title} />
                      <List.Item.Meta
                        title={
                          <Switch
                            checked={item.status === "active" ? true : false}
                            onClick={(checked) =>
                              handleChangeStatus(checked, item.id)
                            }
                          />
                        }
                      />
                      <List.Item.Meta
                        title={moment(item.start_date).format("DD/MMM/YYYY")}
                      />
                      <List.Item.Meta
                        title={moment(item.end_date).format("DD/MMM/YYYY")}
                      />
                      <List.Item.Meta
                        title={moment(item.update_date).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      />
                      <List.Item.Meta
                        title={
                          <Row>
                            <Link to={`${match.path}/${item.id}`}>
                              <Button type="primary">
                                <FaPencilAlt />
                              </Button>
                            </Link>
                            <Button
                              className="btn-danger ml-1"
                              onClick={async () => {
                                const confirm = await AlertConfirm("ลบข้อมูล");
                                if (confirm) {
                                  deleteHF_NutritionById(item.id);
                                }
                              }}
                            >
                              <FaRegTrashAlt />
                            </Button>
                          </Row>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </ReactDragListView>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
