import React from "react";
import { Switch, Route } from "react-router-dom";
import Error404 from "../../Error404";

import BS_AwDealer from "./bs-aw-dealer/BS_AwDealer";
import BS_AwVip from "./bs-aw-vip/BS_AwVip";
import BS_ButtonVip from "./bs-button-vip/BS_ButtonVip";
import BS_DetailDealer from "./bs-detail-dealer/BS_DetailDealer";
import BS_DetailVip from "./bs-detail-vip/BS_DetailVip";
import BS_HeaderText from "./bs-header-text/BS_HeaderText";
import BS_TitleDealer from "./bs-title-dealer/BS_TitleDealer";
import BS_TitleVip from "./bs-title-vip/BS_TitleVip";


export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/bs-aw-dealer`} exact component={BS_AwDealer} />
      <Route path={`${match.path}/bs-aw-vip`} exact component={BS_AwVip} />
      <Route path={`${match.path}/bs-button-vip`} exact component={BS_ButtonVip} />
      <Route path={`${match.path}/bs-detail-dealer`} exact component={BS_DetailDealer} />
      <Route path={`${match.path}/bs-detail-vip`} exact component={BS_DetailVip} />
      <Route path={`${match.path}/bs-header-text`} exact component={BS_HeaderText} />
      <Route path={`${match.path}/bs-title-dealer`} exact component={BS_TitleDealer} />
      <Route path={`${match.path}/bs-title-vip`} exact component={BS_TitleVip} />

      <Route component={Error404} />
    </Switch>
  );
};
