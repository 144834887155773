/* eslint-disable no-unused-vars */
import { Button, Empty, Modal, Table } from "antd";
import React from "react";
import { useApi } from "../../../../../hooks/http2";
import { displayTotal } from "../../../../../utils/helper";
import moment from "moment";
import { FaCog, FaEdit, FaTrash } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { URL_API, axios, getToken } from "../../../../../utils";
import { actionMWBannerProduct } from "../../../../../redux/slices/manage-website/banner-product-category.slice";

const TableBanner = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { current, page, limit } = useSelector(
    (state) => state.mw.banner_product_category,
    shallowEqual
  );

  const { SET_CURRENT } = actionMWBannerProduct;
  
  const list = useApi(
    {
      url: `/manage-website/banner-product-category`,
      token: true,
      params: { page, offset: (current - 1) * limit, limit },
    },
    [current, page]
  );


  return (
    <Table
      size="small"
      bordered
      loading={list.loading}
      rowKey="id"
      dataSource={list.fetch?.data || []}
      pagination={{
        current: current,
        pageSize: limit,
        size: "default",
        total: list.fetch?.total || 0,
        showLessItems: true,
        showTotal: displayTotal,
        onChange: (p) => dispatch(SET_CURRENT(p)),
      }}
      scroll={{ x: "max-content" }}
      className="table-text-top"
    >
      <Table.Column title="#" dataIndex="row_id" align="right" />
      <Table.Column
        title="หมวดหมู่"
        dataIndex="name"
        align="center"
      />
      <Table.Column
        title="Website"
        dataIndex="online"
        align="center"
        render={(src) =>
          src ? (
            <img
              src={src}
              alt="online"
              style={{ maxWidth: "150px", maxHeight: "100px" }}
            />
          ) : (
            <Empty />
          )
        }
      />
      <Table.Column
        title="Mobile"
        dataIndex="mobile"
        align="center"
        render={(src) =>
          src ? (
            <img
              src={src}
              alt="mobile"
              style={{ maxWidth: "150px", maxHeight: "100px" }}
            />
          ) : (
            <Empty />
          )
        }
      />
      <Table.Column
        title="แก้ไขล่าสุด"
        dataIndex="update_date"
        align="center"
        render={(text) => text && moment(text).format("DD/MM/YYYY HH:mm")}
      />
      <Table.Column
        title={<FaCog />}
        dataIndex="id"
        align="center"
        render={(id) => (
          <>
            <Button
              size="small"
              type="primary"
              ghost
              className="mr-2"
              onClick={() => {
                history.push(`${match.url}/${id}/${page}`);
              }}
            >
              <FaEdit />
            </Button>
          </>
        )}
      />
    </Table>
  );
};

export default TableBanner;
