/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPen, FaPlus } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import {
  classScheduleFormDefaultValues,
  classScheduleFormSchema,
} from '../components/dto/class';
import ClassScheduleModal from '../components/modal/ClassScheduleModal';
import ClassScheduleQrCodeModal from '../components/modal/ClassScheduleQrCodeModal';
import classConstants from '../constants/classConstants';
import { Table } from 'antd';
import { Button } from 'antdV4';
import moment from 'moment';
import { AlertConfirm } from '../../../util/Alert';
import { MdDelete } from 'react-icons/md';
import { AiOutlineQrcode } from 'react-icons/ai';
import fitClassServices from '../../../../services/fit-class.services';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';

const ClassSchedulePage = () => {
  const params = useParams();
  const history = useHistory();
  const [classScheduleFormOpen, setClassScheduleFormOpen] = useState(false);
  const [classSchedule, setClassSchedule] = useState({});
  const [classScheduleQrcodeOpen, setClassScheduleQrcodeOpen] = useState(false);
  const {
    data: classScheduleLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();

  const classScheduleForm = useForm({
    defaultValues: classScheduleFormDefaultValues,
    resolver: classScheduleFormSchema(),
  });

  const _HandleClassScheduleFormOpen = (formType) => {
    classScheduleForm.setValue('formType', formType);
    setClassScheduleFormOpen(true);
  };

  const _HandleClassScheduleFormClose = () => {
    setClassScheduleFormOpen(false);
  };

  const _HandleClassScheduleQrcodeOpen = () => {
    setClassScheduleQrcodeOpen(true);
  };

  const _HandleClassScheduleQrcodeClose = () => {
    setClassScheduleQrcodeOpen(false);
  };

  const { data: categoryData } = fitClassServices.useQueryGetClassCategoryById({
    classCategoryId: params.classCategoryId,
  });

  const { data: classData } = fitClassServices.useQueryGetClassById({
    classId: params.classId,
  });

  const { execute: deleteClassSchedule } = useAsync(
    fitStudioService.deleteClassSchedule,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['get-class-schedule-by-class-id'],
          refetchType: 'all',
        });
      },
    }
  );

  fitClassServices.useQueryGetClassScheduleByClassId(
    {
      classId: params.classId,
      page: page,
      take: classConstants.tableSize2,
    },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const columns = [
    // {
    //   title: 'รูปภาพ',
    //   dataIndex: 'imageUrl',
    //   key: 'imageUrl',
    //   render: (_, record) => (
    //     <img
    //       key={record.imageUrl}
    //       src={record.imageUrl}
    //       alt={record.imageUrl}
    //       style={{
    //         width: 50,
    //         height: 50,
    //         objectFit: 'cover',
    //         borderRadius: 10,
    //       }}
    //     />
    //   ),
    //   width: 150,
    // },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      key: 'branch',
      align: 'center',
      render: (_, record) => (
        <span>{record?.fitnessBranch?.branchMaster?.Name}</span>
      ),
    },
    {
      title: 'จำนวนสูงสุด',
      dataIndex: 'max',
      key: 'max',
      align: 'center',
    },
    {
      title: 'ยอดจอง',
      dataIndex: 'booked',
      key: 'booked',
      align: 'center',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            {record.status === 'available' && 'ว่าง'}
            {record.status === 'full' && 'เต็ม'}
            {record.status === 'cancaled' && 'ยกเลิก'}
            {record.status === 'done' && 'เสร็จสิ้น'}
            {record.status === 'processing' && 'กำลังเรียน'}
            {record.status === 'deleted' && 'ถูกลบ'}
          </div>
        );
      },
    },
    {
      title: 'เริ่ม',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, record) => (
        <div>
          <span>{moment(record.start_date).format('DD/MMM/YYYY')}</span>
          <br />
          <span>{moment(record.start_date).format('HH:mm')}</span>
        </div>
      ),
      align: 'center',
    },
    {
      title: 'สิ้นสุด',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, record) => (
        <div>
          <span>{moment(record.end_date).format('DD/MMM/YYYY')}</span>
          <br />
          <span>{moment(record.end_date).format('HH:mm')}</span>
        </div>
      ),
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {!['done', 'processing'].includes(record.status) && (
            <>
              <div
                style={{ cursor: 'pointer', marginLeft: 10 }}
                onClick={() => {
                  classScheduleForm.setValue('id', record.id);
                  _HandleClassScheduleFormOpen('edit');
                }}
              >
                <FaPen />
              </div>
              <div
                style={{ cursor: 'pointer', marginLeft: 10 }}
                onClick={async () => {
                  const confirm = await AlertConfirm('ลบตารางเรียน');
                  if (confirm) deleteClassSchedule({ id: record.id });
                }}
              >
                <MdDelete />
              </div>
            </>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              setClassSchedule(record);
              _HandleClassScheduleQrcodeOpen();
            }}
          >
            <AiOutlineQrcode />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{categoryData?.title}/</h3>
        <h3>{classData?.title}</h3>
      </div>
      <Panel>
        <PanelHeader>ตารางเรียน</PanelHeader>
        <PanelBody>
          <div className="d-flex justify-content-end">
            <Button
              className="bg-pv border-pv text-white mb-2"
              onClick={() => {
                _HandleClassScheduleFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่มตารางเรียน
            </Button>
          </div>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={classScheduleLists}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(
                    `/fit-studio/classcategory/${params.classCategoryId}/class/${params.classId}/classschedule/${record.id}`
                  );
                },
              };
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize: classConstants.tableSize2,
              total: total,
            }}
          />
        </PanelBody>
        <FormProvider {...classScheduleForm}>
          <ClassScheduleModal
            open={classScheduleFormOpen}
            handleClose={_HandleClassScheduleFormClose}
          />
        </FormProvider>
        <ClassScheduleQrCodeModal
          open={classScheduleQrcodeOpen}
          handleClose={_HandleClassScheduleQrcodeClose}
          classSchedule={classSchedule}
        />
      </Panel>
    </>
  );
};

export default ClassSchedulePage;
