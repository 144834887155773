import { Skeleton } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../../../hooks/dom";
import { useApi } from "../../../../../hooks/http2";
import {
  actionLegal,
  registerSelector
} from "../../../../../redux/slices/legal";
import styles from "../../../../../scss/module/legal.module.scss";
import { URL_API } from "../../../../../utils";
import LegalPanel from "../../components/LegalPanel";
import ProfileMenu from "../components/ProfileMenu";
import MainData from "./MainData";
import ManageStatus from "./ManageStatus";
import RegisterRecord from "./RegisterRecord";

const Index = ({ history }) => {
  const query = useQuery();
  const id = query.get("id");
  const type = query.get("membertype");
  const register = useSelector(registerSelector);
  const dispatch = useDispatch();
  const { SET_REGISTER } = actionLegal;

  const [loading, setLoading] = useState(true);
  const [sponsor, setSponsor] = useState([]);
  const [upLine, setUpLine] = useState([]);
  const [company, setCompany] = useState([]);
  const [source] = useState(Axios.CancelToken.source());

  useEffect(() => {
    return () => source.cancel("cancel by user (unmounted)");
  }, [source]);

  useEffect(() => {
    if (register.id !== null) {
      const {
        is_company,
        sponsor_code,
        sponsor_first_name,
        sponsor_sur_name,
        sponsor_mobile,
        up_line_code,
        up_line_first_name,
        up_line_sur_name,
        up_line_mobile,
        up_line_chart_type,
        company_type,
        company_name,
        company_name_en,
        company_tax,
      } = register;
      setSponsor([
        {
          name: "รหัสนักธุรกิจ",
          value: sponsor_code,
        },
        {
          name: "ชื่อ",
          value: sponsor_first_name,
        },
        {
          name: "นามสกุล",
          value: sponsor_sur_name,
        },
        {
          name: "เบอร์โทรศัพท์",
          value: sponsor_mobile,
        },
      ]);
      setUpLine([
        {
          name: "รหัสนักธุรกิจ",
          value: up_line_code,
        },
        {
          name: "ชื่อ",
          value: up_line_first_name,
        },
        {
          name: "นามสกุล",
          value: up_line_sur_name,
        },
        {
          name: "เบอร์โทรศัพท์",
          value: up_line_mobile,
        },
        {
          name: "ทีมงานด้าน",
          value: up_line_chart_type,
        },
      ]);
      setCompany([
        {
          name: "ประเภทธุรกิจ",
          value: is_company === 1 ? company_type : "",
        },
        {
          name: "ชื่อนิติบุคล (ภาษาไทย)",
          value: is_company === 1 ? company_name : "",
        },
        {
          name: "ชื่อนิติบุคล (ภาษาอังกฤษ)",
          value: is_company === 1 ? company_name_en : "",
        },
        {
          name: "เลขประจำตัวผู้เสียภาษี",
          value: is_company === 1 ? company_tax : "",
        },
      ]);
    }
  }, [register]);

  const { fetch } = useApi(
    {
      url: `${URL_API}/legal/profile/register`,
      token: true,
      params: {
        id,
        member_type: type,
      },
      cancelToken: source.token,
    },
    []
  );

  useEffect(() => {
    if (fetch != null) {
      dispatch(SET_REGISTER(fetch.data[0]));
      setLoading(false);
    }
  }, [fetch, dispatch, SET_REGISTER]);

  // useEffect(() => {
  //   if (register.id === null && id !== null) {
  //     setLoading(true);
  //     axios({
  //       url: `${URL_API}/legal/profile/register`,
  //       params: {
  //         id,
  //         member_type : type
  //       },
  //       cancelToken: source.token
  //     }).then((res) => {
  //       const data = res.data.data[0];
  //       if (data) {
  //         dispatch(SET_REGISTER(data));
  //       } else {
  //         Modal.warning({
  //           title: "ไม่พบข้อมูล",
  //           content: `นักธุรกิจ id : ${id}`,
  //           okText: "ตกลง",
  //           onOk: () => {
  //             history.push('/legal');
  //           },
  //         });
  //       }
  //       setLoading(false);
  //     }).catch((err) => {
  //       if (err.message !== 'cancel by user (unmounted)') {
  //         notification.error({
  //           message: "ตรวจพบสิ่งผิดพลาด",
  //           description:
  //             err.response && err.response.data
  //               ? err.response.data.message
  //               : err.message,
  //           placement: "topRight",
  //         });
  //         setLoading(false);
  //       }
  //     });
  //   }
  // }, [source, register, id, dispatch, SET_REGISTER, history,type]);

  console.log(company)
  return (
    <LegalPanel>
      <div className={styles.profile}>
        <ProfileMenu />
        {loading ? (
          <Skeleton active className={styles.skeleton} />
        ) : (
          <>
            <ManageStatus
              profile_status={register?.status}
              expire_date={register?.expire_date}
              resign_date={register?.resign_date}
              register_new_date={register?.register_new_date}
            />
            <RegisterRecord title="ผู้แนะนำ" data={sponsor} divider />
            {type === "distributor" && (
              <RegisterRecord title="อัพไลน์" data={upLine} divider />
            )}
            <MainData data={register} />
            <RegisterRecord title="นิติบุคคล" data={company} />
          </>
        )}
      </div>
    </LegalPanel>
  );
};

export default Index;
