/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Select } from "antd";
import React, { memo } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, _ } from "../../../../../utils";

const SelectClaimVoucher = ({ value, onChange }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/claim/voucher`,
      token: true,
    },
    []
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select
      allowClear
      className="w-100"
      placeholder="เลือกบัตรกำนัล"
      loading={loading}
      dropdownStyle={{ zIndex: 1080 }}
      dropdownMatchSelectWidth={false}
      value={value}
      onChange={onChange}
    >
      {_.map(data?.data, (n) => (
        <Select.Option key={n.voucher_id} value={n.voucher_id} source={n}>
          {n.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default memo(SelectClaimVoucher);
