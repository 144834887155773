import { createReducer } from "@reduxjs/toolkit";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import React from "react";

export const initState = {
  loading: false,
  data: {
    id: 0,
    description: null,
    province_id: undefined,
    district_id: undefined,
    sub_district_id: undefined,
  },
};

export const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_LOAD_SUCCESS: (state, { payload }) => {
    const { id, province_id, district_id, sub_district_id, description } =
      payload;
    const description_content_bock = description
      ? htmlToDraft(description)
      : null;
    state.data = {
      id,
      description: description_content_bock
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              description_content_bock.contentBlocks
            )
          )
        : null,
      province_id,
      district_id,
      sub_district_id,
    };
    state.loading = false;
  },
  SET_DATA: (state, { payload }) => {
    state.data = {
      ...state.data,
      ...payload,
    };
  },
  SET_CONTACT: (state, { payload: { contact_key, ...data } }) => {
    state.contact.itemByKey[contact_key] = {
      ...state.contact.itemByKey[contact_key],
      ...data,
    };
  },
});

export const SupplierContext = React.createContext();
