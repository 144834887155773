/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import { useHistory } from 'react-router';
import {
  legacyPrizesItemFormDefaultValues,
  legacyPrizesItemFormSchema,
} from '../components/dto/legacy-prizes.dto';
import fitPrizesServices from '../../../../services/fit-prizes.services';
import LegacyPrizesItemModal from '../components/modal/LegacyPrizesItemModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { FiArrowLeft } from 'react-icons/fi';

dayjs.locale('th');

const pageSize = 10;

const LegacyPrizesItemPage = () => {
  const params = useParams();
  const [legacyPrizesItemFormOpen, setLegacyPrizesItemFormOpen] =
    useState(false);
  const {
    data: legacyPrizesItemLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();

  const legacyPrizesItemForm = useForm({
    defaultValues: legacyPrizesItemFormDefaultValues,
    resolver: legacyPrizesItemFormSchema(),
  });

  const _HandleLegacyPrizesItemFormOpen = (formType) => {
    legacyPrizesItemForm.setValue('formType', formType);
    setLegacyPrizesItemFormOpen(true);
  };

  const _HandleLegacyPrizesItemFormClose = () => {
    legacyPrizesItemForm.reset(legacyPrizesItemFormDefaultValues);
    setLegacyPrizesItemFormOpen(false);
  };

  fitPrizesServices.useQueryGetLegacyPrizesItemLists(
    { take: pageSize, page, giftEventId: params.giftEventId },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyPrizesItem } =
    fitPrizesServices.useMutationDeleteLegacyPrizesItem(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-prizes-item'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyPrizesItemActive } =
    fitPrizesServices.useMutationUpdateLegacyPrizesItemActive(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-prizes-item'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <div>
          <img
            alt={record.title}
            src={record.imageUrl}
            style={{
              width: 75 / 2,
              height: 100 / 2,
            }}
          />
        </div>
      ),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <span>
          {record?.type === 'all' && 'แสดงทุกคน'}
          {record?.type === 'group' && 'แสดงเฉพาะกลุ่ม'}
          {record?.type === 'Distributor' && 'แสดงเฉพาะนักธุรกิจ'}
          {record?.type === 'Member' && 'แสดงเฉพาะ VIP MEMBER'}
        </span>
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => <span>{record?.active ? 'แสดง' : 'ไม่แสดง'}</span>,
    },
    {
      title: 'วันที่เริ่ม',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => (
        <span>{dayjs(record.startDate).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.startDate).diff(dayjs(b.startDate), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record) => (
        <span>{dayjs(record.endDate).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.endDate).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => <span>{record.active ? 'เปิด' : 'ปิด'}</span>,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.active ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการแสดง Pop up');
                if (confirm)
                  updateLegacyPrizesItemActive({
                    id: record.id,
                    active: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการแสดง Pop up');
                if (confirm)
                  updateLegacyPrizesItemActive({ id: record.id, active: true });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyPrizesItemForm.setValue('id', record.id);
              _HandleLegacyPrizesItemFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyPrizesItem({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
      </div>
      <Panel>
        <PanelHeader>Legacy Prizes Item</PanelHeader>
        <PanelBody>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="mb-2"
          >
            <div style={{ flex: 1 }} />
            <Button
              className="bg-pv border-pv text-white"
              onClick={() => {
                _HandleLegacyPrizesItemFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่ม Legacy Prizes Item
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={legacyPrizesItemLists}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize,
              total,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(
                    `/notification-setting/legacy-prizes/${params.giftEventId}/item/${record.id}`
                  );
                },
              };
            }}
          />
        </PanelBody>
        <FormProvider {...legacyPrizesItemForm}>
          <LegacyPrizesItemModal
            open={legacyPrizesItemFormOpen}
            handleClose={_HandleLegacyPrizesItemFormClose}
          />
        </FormProvider>
      </Panel>
    </>
  );
};

export default LegacyPrizesItemPage;
