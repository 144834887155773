import { createReducer } from "@reduxjs/toolkit";

export const initState = {
  loading: false,
  data: {
    type: "",
  },
};

export const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_TYPE: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        type: payload,
      },
    };
  },
});
