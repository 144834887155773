import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { UpdateAuth } from "../../redux/actions/auth-action";
import { LoadingScreen } from "../util/Loading";

import axios from "axios";
import { URL_API, setUser, setToken } from "../../utils";
import { AlertError } from "../util/Alert.js";
import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Select,
  Typography,
} from "antd";

class Login extends Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      background: "/assets/img/login-bg/login-bg-17.jpg",
      username: "",
      password: "",
      loading: false,
      errors: {},
      showSelectBranch: false,
      branchList: [],
      branch: undefined,
      userData: null,
    };
  }
  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
    this.authenticate(this.props.auth);
    document.body.classList.add("BG-Login");
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
    document.body.classList.remove("BG-Login");
  }
  componentDidUpdate() {
    this.authenticate(this.props.auth);
  }
  authenticate = (auth) => {
    if (auth) this.props.history.replace("/dashboard");
  };
  _login = async (data) => {
    let result = null;
    await axios({
      method: "POST",
      baseURL: URL_API,
      url: `/system/users/login`,
      data: data,
    })
      .then(async (res) => {
        result = res.data;
        const { status, data } = res.data;
        if (status === 200) {
          result = data;
        }
        await this.setState({ loading: false });
      })
      .catch(async (err) => {
        const { error, error_dev } = err.response.data;
        await this.setState({ loading: false });
        await AlertError({ title: error.message, text: error_dev.message });
      });

    return result;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    let { errors } = this.state;
    delete errors[name];
    this.setState({ [name]: value, errors });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    const textInvalid = "กรุณากรอกข้อมูล";
    let errors = {};

    if (username === "") errors.username = textInvalid;
    if (password === "") errors.password = textInvalid;

    this.setState({ errors });
    if (errors.username || errors.password) return;

    const login = {
      username,
      password,
    };

    this.setState({ loading: true });

    const result = await this._login(login);

    if (result) {
      const { branch, ...userData } = result;
      if (branch.length === 1) {
        await setToken(userData.token);
        await setUser(userData);
        await this.props.UpdateAuth(userData);
      } else {
        this.setState({ showSelectBranch: true, branchList: branch, userData });
      }
    }
  };

  render() {
    const { username, password, loading, errors } = this.state;
    return (
      <>
        <LoadingScreen loading={loading}>
          <div className="login-custom">
            <div className="login login-v2">
              <div className="login-panel">
                <div className="login-header">
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: -40 }}
                  >
                    <span className="btn login-logo">
                      <i className="fa fa-user" />
                    </span>
                  </div>
                  <div className="mt-3 mb-3 d-flex justify-content-center">
                    <span className="d-flex login-title">User Login</span>
                  </div>
                </div>
                <div className="login-content">
                  <form
                    className="margin-bottom-0"
                    onSubmit={this.handleSubmit}
                  >
                    <Input
                      type="text"
                      name="username"
                      value={username}
                      onChange={this.handleChange}
                      placeholder="Username"
                      icon="fa fa-user"
                      validate={errors.username}
                    />
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      placeholder="Password"
                      icon="ion-md-key"
                      iconSize={18}
                      validate={errors.password}
                    />

                    <div className="login-buttons">
                      <button
                        type="submit"
                        className="btn btn-success btn-block btn-lg"
                      >
                        LOGIN
                      </button>
                    </div>
                    <div className="m-t-20 d-flex justify-content-center">
                      <span>
                        Forget{" "}
                        <Link
                          to="#"
                          style={{ color: "#348fe2" }}
                          onClick={async () => {
                            await AlertError({
                              title: "ลืมรหัสผ่าน?",
                              text: "กรุณาติดต่อเจ้าหน้าที่ฝ่าย IT",
                            });
                          }}
                        >
                          Password
                        </Link>{" "}
                        ?
                      </span>
                    </div>
                  </form>
                </div>
              </div>

              {/* <div className="d-flex justify-content-center mt-3">
								<img
									alt="LOGO-LEGACY"
									src="https://www.legacy.co.th/wp-content/uploads/2015/09/logo_w_120px.png"
									width={192}
								/>
							</div> */}
            </div>
          </div>
        </LoadingScreen>
        <SelectBranchModal
          visible={this.state.showSelectBranch}
          value={this.state.branch}
          branch={this.state.branchList}
          onSelect={(e) => {
            this.setState({ branch: e });
          }}
          onSubmit={() => {
            const { branch, userData, branchList } = this.state;
            if (!branch) {
              return notification.warn({
                message: "คำเตือน",
                description: "กรุณาเลือกสาขา",
              });
            }
            const data = {
              ...userData,
              branch_code: branch,
              branch_name: branchList.find((n) => n.branch_code === branch)
                .branch_name,
            };
            this.setState({ showSelectBranch: false });
            setToken(data.token);
            setUser(data);
            this.props.UpdateAuth(data);
          }}
        />
      </>
    );
  }
}

const Input = (props) => {
  const {
    type = "text",
    icon = "fa fa-user",
    iconSize,
    name,
    value,
    validate,
    onChange,
    placeholder,
  } = props;
  return (
    <div className="m-b-5 ">
      <div
        className={`custom-input-login d-flex align-items-center ${
          validate ? "is-invalid" : ""
        }`}
      >
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className={`form-control form-control-lg`}
          style={{ backgroundColor: "transparent", borderColor: "transparent" }}
          placeholder={placeholder}
        />
        <i
          className={`${icon} icon pr-3 pl-3`}
          style={{ fontSize: iconSize }}
        ></i>
      </div>
      <span style={{ fontSize: 11, color: "#ff5b57" }} className="ml-1">
        {validate}
      </span>
    </div>
  );
};

const SelectBranchModal = ({ visible, branch, value, onSelect, onSubmit }) => (
  <Modal
    visible={visible}
    footer={false}
    destroyOnClose={true}
    closable={false}
    maskClosable={false}
  >
    <Row gutter={16} className="mb-3">
      <Col span={24} className="text-center">
        <Typography.Text strong>เลือกสาขา เข้าสู่ระบบ</Typography.Text>
      </Col>
    </Row>
    <Row gutter={16} className="mb-3">
      <Col span={24}>
        <Select
          value={value}
          placeholder="เลือกสาขา"
          className="w-100"
          onChange={onSelect}
          //   defaultValue={branch[0]?.branch_code}
        >
          {branch.map((n, i) => (
            <Select.Option key={i} value={n.branch_code}>
              <Typography.Text>{n.branch_name}</Typography.Text>
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={24} className="text-center">
        <Button className="bg-pv border-pv text-white" onClick={onSubmit}>
          ตกลง
        </Button>
      </Col>
    </Row>
  </Modal>
);

const mapStateToProps = (state) => ({
  auth: state.auth.authenticate,
});

export default withRouter(connect(mapStateToProps, { UpdateAuth })(Login));
