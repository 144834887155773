/* eslint-disable no-unused-vars */
import { Col, notification, Row, Table, typography } from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { ImPriceTag } from "react-icons/im";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const { Text } = typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [billSelectedId, setBillSelectedId] = useState(null);
  const [preview, setPreview] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [orderTypeFilter, setOrderTypeFilter] = useState("");
  const [cancelTypeFilter, setCancelTypeFilter] = useState("");
  const [receiveTypeFilter, setReceiveTypeFilter] = useState("");
  const [orderTypeFilterText, setOrderTypeFilterText] = useState("");
  const [cancelTypeFilterText, setCancelTypeFilterText] = useState("");
  const [receiveTypeFilterText, setReceiveTypeFilterText] = useState("");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-receipt-cancel`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        orderTypeFilter: orderTypeFilter,
        cancelTypeFilter: cancelTypeFilter,
        receiveTypeFilter: receiveTypeFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-receipt-cancel-filter`,
      token: true,
    },
    []
  );
  useEffect(() => {
    setFilterDataSource(filterData ? filterData : []);
  }, [filterData]);

  const [exportLoading, exportData, exportError, exportReload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-receipt-cancel`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        orderTypeFilter: orderTypeFilter,
        cancelTypeFilter: cancelTypeFilter,
        receiveTypeFilter: receiveTypeFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportDataData = exportData ? [exportData.data] : [];

  const exportToExcel = () => {
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานยกเลิกใบเสร็จ"],
        [
          `สาขา ${
            state.filter.branch === "" ? "ทั้งหมด" : state.filter.branch_name
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
        ],
        [
          `ประเภทการซื้อ ${
            orderTypeFilter === "" ? "ทั้งหมด" : orderTypeFilterText
          }`,
          "",
          `ประเภทการยกเลิก ${
            cancelTypeFilter === "" ? "ทั้งหมด" : cancelTypeFilterText
          }`,
          "",
          `ประเภทการรับสินค้า ${
            receiveTypeFilter === "" ? "ทั้งหมด" : receiveTypeFilterText
          }`,
        ],
        [],
        [
          "ลำดับที่",
          "เลขที่ใบเสร็จ",
          "วันที่ใบเสร็จ",
          "เลขที่บิลยกเลิก",
          "วันที่ยกเลิก",
          "รหัสสมาชิก",
          "ชื่อสมาชิก",
          "ประเภทการซื้อ",
          "ยอดเงิน",
          "พนักงานเปิดบิล",
          "ประเภทการยกเลิก",
          "เหตุผลการยกเลิก",
          "การรับสินค้า",
          "พนักงานผู้ขอยกเลิก",
          "ผู้อนุมัติยกเลิก",
          //"ดูรายละเอียด",
        ],
      ];

      exportData.data.forEach((n) => {
        excelData = [
          ...excelData,
          [
            n.row_id,
            n.document_no,
            moment(n.document_date).format("DD/MM/YYYY"),
            n.cn_document_no,
            moment(n.cancel_date).format("DD/MM/YYYY"),
            n.partner_code,
            n.partner_name,
            n.order_type,
            //n.grand_total_amount,
            {
              v: n.grand_total_amount,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            n.cashier_name,
            n.cancel_type,
            n.reason,
            // n.req_receipt_type === "Transport"
            //   ? "จัดส่ง"
            //   : `สาขา ${n.bill_location}`,
            n.receipt_branch_name,
            n.request_by_name,
            n.cancel_by,
          ],
        ];
      });

      const merge = [
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 3 } },
        { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานยกเลิกใบเสร็จ");
      XLSX.writeFile(wb, "รายงานยกเลิกใบเสร็จ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  const handlePreview = (id) => {
    setBillSelectedId(id);
    setPreview(true);
  };

  const orderTypeOptions = [
    ..._.map(filterDataSource.dataOrderType, (type) => {
      return { value: type.order_type, text: type.order_type };
    }),
  ];

  const cancelTypeOptions = [
    ..._.map(filterDataSource.dataCancelType, (type) => {
      return { value: type.code, text: type.cancel_type };
    }),
  ];

  const receiveTypeOptions = [
    ..._.map(filterDataSource.dataReceiveType, (type) => {
      return { value: type.branch_code, text: type.branch_name };
    }),
  ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานยกเลิกใบเสร็จ</PanelHeader>
        <PanelBody>
          <FilterList
            withAll
            //allowDisableBranch
            reload={() => {
              reload();
              exportReload();
            }}
            placeholder="รหัสสมาชิก ,ชื่อสมาชิก ,เลขที่ใบเสร็จ ,เลขที่การจอง ,ชื่อพนักงาน ,ยอดเงิน"
            // showReportSpan={5}
            // inputSpan={9}
            Topic={"วันที่ยกเลิก"}
            showBranch={true}
            exportToExcel={exportToExcel}
          />
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} align="center">
              <Text strong>ประเภทการซื้อ</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                options={orderTypeOptions}
                defaultValue={orderTypeFilter}
                className="w-100"
                onChange={(e, v) => {
                  setOrderTypeFilterText(v.props.children);
                  setOrderTypeFilter(e);
                }}
              />
            </Col>

            <Col span={2} align="middle">
              <Text strong>ประเภทการยกเลิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                options={cancelTypeOptions}
                defaultValue={cancelTypeFilter}
                className="w-100"
                onChange={(e, v) => {
                  setCancelTypeFilterText(v.props.children);
                  setCancelTypeFilter(e);
                }}
              />
            </Col>

            <Col span={2} align="middle">
              <Text strong>การรับสินค้า</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                options={receiveTypeOptions}
                defaultValue={receiveTypeFilter}
                className="w-100"
                onChange={(e, v) => {
                  setReceiveTypeFilterText(v.props.children);
                  setReceiveTypeFilter(e);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับที่" dataIndex="row_id" align="center" />
            <Table.Column
              title="เลขที่ใบเสร็จ"
              dataIndex="document_no"
              align="center"
            />
            <Table.Column
              title="วันที่ใบเสร็จ"
              align="right"
              render={({ document_date }) => (
                <>{moment(document_date).format("DD/MM/YYYY")}</>
              )}
            />
            <Table.Column
              title="เลขที่บิลยกเลิก"
              dataIndex="cn_document_no"
              align="center"
            />
            <Table.Column
              title="วันที่ยกเลิก"
              align="right"
              render={({ cancel_date }) => (
                <>{moment(cancel_date).format("DD/MM/YYYY")}</>
              )}
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสมาชิก"
              dataIndex="partner_name"
              align="center"
            />
            <Table.Column
              title="ประเภทการซื้อ"
              dataIndex="order_type"
              align="left"
            />
            <Table.Column
              title="ยอดเงิน"
              dataIndex="grand_total_amount"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="พนักงานเปิดบิล"
              dataIndex="cashier_name"
              align="right"
            />
            <Table.Column
              title="ประเภทการยกเลิก"
              dataIndex="cancel_type"
              align="right"
            />
            <Table.Column
              title="เหตุผลการยกเลิก"
              dataIndex="reason"
              align="right"
            />
            {/* <Table.Column
              title="การรับสินค้า"
              dataIndex="req_receipt_type"
              align="right"
              render={(text, { bill_location }) => {
                if (text === "Transport") {
                  return "จัดส่ง";
                } else if (text === "Branch") {
                  return "สาขา" + bill_location;
                }
              }}
            /> */}
            <Table.Column
              title="การรับสินค้า"
              dataIndex="receipt_branch_name"
              align="right"
              render={(text) => {
                if (text === "จัดส่ง") {
                  return "จัดส่ง";
                } else  {
                  return "สาขา" + text;
                }
              }}
            />
            <Table.Column
              title="พนักงานผู้ขอยกเลิก"
              dataIndex="request_by_name"
              align="right"
            />
            <Table.Column
              title="ผู้อนุมัติยกเลิก"
              dataIndex="cancel_by"
              align="right"
            />
            <Table.Column
              title="ดูรายละเอียด"
              align="left"
              render={(data) => (
                <Text
                  onClick={() => {
                    handlePreview(data.id);
                  }}
                >
                  {data.product_count} รายการ <ImPriceTag />
                </Text>
              )}
            />
          </Table>
        </PanelBody>
      </Panel>

      <BillPreview
        id={billSelectedId}
        preview={preview}
        setPreview={setPreview}
      />
    </SaleSummaryContext.Provider>
  );
};

export default Index;
