/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Button,
  Col, Form,
  Input, Modal,
  notification,
  Radio,
  Row,
  Upload
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import { beforeUploadImg } from "../../../../../util/helper";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import { dummyRequest } from "../../../components/dummyRequest";
import { DivStyles, ImgStyles } from "../../../components/mwStyles";
import useUploadFile from "../../../components/useUploadFile";

function Card({ history, match, form, ...props }) {
  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);

  const [loadValue, setLoadValue] = useState("");
  const [selectLink, setSelectLink] = useState("no");

  const desktopWidth = 299;
  const desktopHeight = 250;
  const mobileWidth = 248;
  const mobileHeight = 200;

  let { onUpload, onRemoveUpload } = useUploadFile();

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      if (fileListMobile[0]?.file) {
        formData.append("mobile_img", fileListMobile[0]?.file?.originFileObj);
      }

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img"]),
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",
        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/home/ecosystem/card-ecosystem/" +
                match.params.id +
                "/update";
            } else {
              actionPathUrl = "/manage-website/home/ecosystem/card-ecosystem";
            }

            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = async (e) => {
    setFileListDesktop([]);
    let isDelete = await onRemoveUpload(e, ...fileDelete);
    setFileDelete(isDelete);
  };

  const handleRemoveMobile = async (e) => {
    setFileListMobile([]);
    let isDelete = await onRemoveUpload(e, ...fileDelete);
    setFileDelete(isDelete);
  };

  const handleUploadDesktop = async (e) => {
    let isFile = await onUpload(e, desktopWidth, desktopHeight);
    if (isFile) {
      setFileListDesktop(isFile);
    }
  };

  const handleUploadMobile = async (e) => {
    let isFile = await onUpload(e, mobileWidth, mobileHeight);
    if (isFile) {
      setFileListMobile(isFile);
    }
  };

  const loadContent = async () => {
    await axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/home/ecosystem/card-ecosystem/" + match.params.id,
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        console.log(data);
        setSelectLink(data.select_link);
        let { loadDesktopImg, loadMobileImg } = data;

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListMobile(loadMobileImg);
          }
        }

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (match.params.id !== "add") {
      loadContent(match.params.id);
    }
  }, [match.params.id]);

  const { getFieldDecorator } = form;

  return (
    <Panel>
      <PanelHeader></PanelHeader>
      <PanelBody>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col md={12}>
              {fileListDesktop.length != 0 ? (
                <Row>
                  <div style={DivStyles}>
                    <img
                      src={fileListDesktop[0].url}
                      alt="desktop_img"
                      style={ImgStyles}
                    />
                  </div>
                </Row>
              ) : (
                ""
              )}
            </Col>
            <Col md={12}>
              {fileListMobile.length != 0 ? (
                <div style={DivStyles}>
                  <img
                    src={fileListMobile[0].url}
                    alt="mobile_img"
                    style={ImgStyles}
                  />
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item
                label={`ขนาดสำหรับ Desktop ${desktopWidth} x ${desktopHeight} px`}
                name="desktop_img"
              >
                {getFieldDecorator("desktop_img", {
                  initialValue: null,
                })(
                  <Upload.Dragger
                    accept="image/*"
                    multiple={false}
                    fileList={fileListDesktop || []}
                    customRequest={dummyRequest}
                    beforeUpload={beforeUploadImg}
                    onChange={handleUploadDesktop}
                    onRemove={handleRemoveDesktop}
                    listType="picture"
                  >
                    <AiOutlineUpload className="mr-3" />
                    เพิ่มรูปภาพ
                  </Upload.Dragger>
                )}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label={`ขนาดสำหรับ Mobile ${mobileWidth} x ${mobileHeight} px`}>
                {getFieldDecorator("mobile_img", {
                  initialValue: null,
                })(
                  <Upload.Dragger
                    accept="image/*"
                    multiple={false}
                    fileList={fileListMobile || []}
                    customRequest={dummyRequest}
                    beforeUpload={beforeUploadImg}
                    onChange={handleUploadMobile}
                    onRemove={handleRemoveMobile}
                    listType="picture"
                  >
                    <AiOutlineUpload className="mr-3" />
                    Upload
                  </Upload.Dragger>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item label={`title`}>
              {getFieldDecorator(`title`, {
                initialValue: "",
                rules: [{ required: true, message: "กรุณาระบุ title" }],
              })(<Input />)}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label={`content `}>
              {getFieldDecorator(`content`, {
                initialValue: "",
                rules: [{ required: true, message: "กรุณาระบุ content" }],
              })(<TextArea />)}
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Select Link :">
                {getFieldDecorator("select_link", {
                  initialValue: selectLink,
                })(
                  <Radio.Group
                    onChange={(e) => {
                      setSelectLink(e.target.value);
                    }}
                  >
                    <Radio value={"no"}>ไม่ลิงค์</Radio>
                    <Radio value={"yes"}>ลิงค์</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>

          {selectLink === "yes" ? (
            <Row>
              <Form.Item label="target link :">
                {getFieldDecorator("target_link", {
                  initialValue: loadValue.target_link,
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุ target link",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Row>
          ) : (
            ""
          )}
          <Row gutter={16}>
            <Col md={24}>
              <Button
                className="bg-nn border-nn text-white mr-3"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={() => handleClickSubmit()}>
                {match.params.id !== "add" ? "Update" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}

export default Form.create("home-ecosystem-card")(Card);
