/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import fitStudioService from '../../../../../services/fit-studio.service';
import useAsync from '../../../../../utils/useAsync';
import { Input, Typography, Upload } from 'antdV4';
import { Select, Row, Col } from 'antd';
import bannerConstants from '../../constants/bannerConstants';
import { ErrorMessage } from '@hookform/error-message';
import { DatePicker } from 'antd';
import moment from 'moment';
import { registerPaperUpdateDefaultValues } from '../dto/register-paper';

const RegisterPaperForm = () => {
  const {
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { execute: getRegisterPaperById } = useAsync(
    fitStudioService.getRegisterPaperById,
    {
      onSuccess: (data) => {
        reset({
          formType: 'edit',
          id: data.id,
          startDate: data.startDate
            ? moment(data.startDate)
            : moment(new Date()),
          endDate: data.endDate
            ? moment(data.endDate)
            : moment(new Date()).add('M', 3),
        });
      },
    }
  );

  useEffect(() => {
    if (watch('formType') === 'edit') {
      getRegisterPaperById(watch('id'));
    } else {
      reset(registerPaperUpdateDefaultValues);
    }
  }, [watch('formType'), watch('id')]);

  return (
    <div>
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>วันที่เริ่มต้น</Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่เริ่มต้น"
                  allowClear={false}
                  value={value}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="endDate"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>วันที่สิ้นสุด</Typography.Text>
              </Col>
              <Col span={20}>
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="วันที่สิ้นสุด"
                  value={value}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    onChange(moment(date));
                  }}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default RegisterPaperForm;
