import React from 'react';
import { moment } from 'fullcalendar';
import { Row, Col } from 'antd';
import _ from 'lodash';

const Criteria = ({ custType, type, onChangeType, onChangeYear, year, ...props }) => {
    const renderSelectOption = () => {
        const now = moment().format("YYYY");
        let options = [];
        for (let index = now; index > 2013; index--) {
            options.push(<option key={`option-${index}`} value={index}>{index}</option>);
        }
        return options;
    }
    return (
        <Row type="flex" justify="space-between" className="mb-2">
            <Col>
                <select className="form-control" value={year} onChange={onChangeYear} style={{ width: '150px' }}>
                    {renderSelectOption()}
                </select>
            </Col>
            <Col className="radio radio-css radio-inline">
                {
                    custType.map((n, index) => (
                        <React.Fragment key={_.uniqueId('fragment-')}>
                            <input
                                type="radio"
                                name="custType"
                                value={n.value}
                                id={`custType-${index}`}
                                checked={type === n.value}
                                onChange={onChangeType}
                            />
                            <label htmlFor={`custType-${index}`} className="mr-2">{n.text}</label>
                        </React.Fragment>
                    ))
                }
            </Col>
        </Row>
    )
}

export default Criteria;
