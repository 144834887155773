import { Col, DatePicker, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../../redux/slices/claim";
import { moment } from "../../../../../../utils";

const { setClaimItemValueBom } = actionClaim;

const SelectDate = ({ type, disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const product_bom_key = useSelector(
    (state) => state.claim.productBomModal.product_bom_key
  );
  const date = useSelector((state) => {
    if (!product_key || !product_bom_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    const { activeKey: activeKeyBom, itemByKey: itemByKeyBom } =
      itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;
    return itemByKeyBom[activeKeyBom][type];
  });
  const title = type === "mfg_date" ? "วันผลิต" : "วันหมดอายุ";

  const selectedDate = useSelector(
    (state) => {
      if (!product_key || !product_bom_key) return null;
      const { activeKey, itemByKey } =
        state.claim.billProduct.itemByKey[product_key].claim_item;
      const { activeKey: activeKeyBom, itemByKey: itemByKeyBom } =
        itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;
      return itemByKeyBom[activeKeyBom];
    }
  );

  const disabledExp = (current) => {
    return current && current < moment(selectedDate?.mfg_date).endOf("day");
  };

  const disabledMfg = (current) => {
    let select_date = selectedDate?.exp_date;
    let isExp = current && current > moment(select_date).endOf("day");
    let isNow = current && current > moment().endOf("day");
    return select_date === null ? isNow : isExp ? isExp : isNow;
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={6} className="text-right">
        <Typography.Text>{title}</Typography.Text>
      </Col>
      <Col span={18}>
        <DatePicker
          size="small"
          format="DD/MM/YYYY"
          placeholder={`เลือก${title}`}
          className="w-100"
          disabled={disabled}
          disabledDate={type === "exp_date" ? disabledExp : disabledMfg}
          value={date && moment(date)}
          onChange={(e) => dispatch(setClaimItemValueBom({ [type]: e }))}
        />
      </Col>
    </Row>
  );
};

export default memo(SelectDate);
