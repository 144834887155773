import { Col, Form, Row, Switch } from "antd";
import React from "react";

const CollectPoint = ({ getFieldDecorator }) => (
  <Row gutter={16}>
    <Col md={24} className="d-flex justify-content-between">
      <label className="mb-0">ได้รับคะแนน</label>
      <Form.Item className="mb-0">
        {getFieldDecorator("collect_point", {
          valuePropName: "checked",
          initialValue: false,
        })(<Switch />)}
      </Form.Item>
    </Col>
  </Row>
);

export default CollectPoint;
