import { Typography } from "antd";
import React from "react";
import ImgError from "../../../../../../components/ImgError";
import { _ } from "../../../../../../utils";

const { Text } = Typography;

const Item = ({ data }) => (
  <div className="d-flex align-items-center mb-2 ">
    <ImgError
      src={data.azure_url}
      alt={_.uniqueId("product-")}
      className="width-60 height-60 border rounded-lg object-fit-cover mr-2"
    />
    <Text>{`${data.product_name_local} ${data.source_amount} ชิ้น `}</Text>
    {data.banned ? (
      <Text className="text-red ml-2">(banned)</Text>
    ) : (
      data.sold_out && <Text className="text-red ml-2">(sold out)</Text>
    )}
  </div>
);

export default Item;
