/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import RegisterPaperForm from '../form/RegisterPaperForm';
import { registerPaperUpdateDefaultValues } from '../dto/register-paper';

const RegisterPaperModal = ({ open, handleClose, refetch }) => {
  const { watch, handleSubmit, reset } = useFormContext();

  const { data: updatedRes, execute: updateRegisterPaper } = useAsync(
    fitStudioService.updateRegisterPaper,
    {
      onSuccess: () => {
        AlertSuccess('อัปเดทใบสมัครสำเร็จ');
        reset(registerPaperUpdateDefaultValues);
        handleClose();
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [updatedRes]);

  const _HandleSubmit = handleSubmit((data) => {
    const registerPaperData = {
      type: data.type,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
    };
    if (watch('formType') === 'edit') {
      updateRegisterPaper({ id: data.id, data: registerPaperData });
    }
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'สร้างใบสมัคร' : 'อัปเดทใบสมัคร'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <RegisterPaperForm />
    </Modal>
  );
};

export default RegisterPaperModal;
