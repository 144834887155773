import { Col, Input, Row, Typography, Divider } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionRequisition } from "../../../../../redux/slices/requisition";

const { setGlobalState } = actionRequisition;

const ConsiderRemark = () => {
  const dispatch = useDispatch();
  const consider_remark = useSelector(
    (state) => state.requisition.consider.remark
  );
  return (
    <>
      <Divider />
      <Row gutter={[8, 8]}>
        <Col span={4} md={2} className="text-right">
          <Typography.Text strong>รายละเอียดการพิจารณา</Typography.Text>
        </Col>
        <Col span={20} md={22} xxl={10}>
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 4 }}
            maxLength={500}
            value={consider_remark}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "consider",
                  value: { remark: e.target.value },
                })
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ConsiderRemark;
