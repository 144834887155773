import { createSlice } from "@reduxjs/toolkit";

const initState = {
  active: "set",
  search: {
    query: "",
    branch: "",
    start_date: null,
    end_date: null,
    page: 1,
    limit: 10,
  },
};

const storeQrBalanceSlice = createSlice({
  name: "balance",
  initialState: initState,
  reducers: {
    SET_ACTIVE: (state, { payload }) => {
      state.active = payload.type;
      state.search = {
        ...initState.search,
        branch : payload.branch
      };
    },
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
  },
});

export const actionStoreQrBalance = storeQrBalanceSlice.actions;

export const activeSelector = (state) => state.storeQR.balance.active;
export const searchSelector = (state) => state.storeQR.balance.search;

const StoreQrBalanceReducer = storeQrBalanceSlice.reducer;

export default StoreQrBalanceReducer;
