/* eslint-disable no-unused-vars */
import { Button, InputNumber, Popconfirm, Spin, Typography } from "antd";
import numeral from "numeral";
import React, { Fragment, memo, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";
import { numInt } from "../../../../util/helper/map-data";
import ClaimBomDetail from "../ClaimBomDetail";
import QrCodeButton from "../QrCodeButton";
import BomDetail from "./BomDetail";

const columns = [
  "",
  "รหัสสินค้า",
  "ชื่อสินค้า",
  // "ราคา/หน่วย",
  // "คะแนน/หน่วย",
  "",
  "จำนวน",
  // "เงินรวม",
  // "คะแนนรวม",
  "",
];

const num = (e) => numeral(e).format("0,0");

const { delClaimOutProduct, setClaimOutProduct, setClaimOutProductBom } =
  actionClaim;

const ProductTable = ({ disabled }) => {
  const dispatch = useDispatch();
  const { process: process_params } = useParams();
  const [totalQty, setTotalQty] = useState(0);
  const [totalPoint, setTotalPoint] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const { itemKeys, itemByKey } = useSelector(
    (state) => state.claim.claimOutProduct
  );
  const loadingStock = useSelector(
    (state) => state.claim.fetch.stock_balance.loading
  );

  useEffect(() => {
    let price = 0;
    let point = 0;
    let total_qty = 0;
    const data = _.map(itemKeys, (key) => {
      const { claim_price, qty, point_01 } = itemByKey[key];
      price += claim_price * qty;
      point += point_01 * qty;
      total_qty += qty;
      return { ...itemByKey[key], key };
    });
    setDataSource(data);
    setTotalQty(total_qty);
    setTotalPrice(price);
    setTotalPoint(point);
  }, [itemKeys, itemByKey]);

  let sum_main_product = _.sum(
    _.map(dataSource, (n, i) =>
      n.bom?.length > 0 ? 0 : n.stock_balance > n.qty ? n.qty : n.stock_balance
    )
  );

  let sum_bom_product = _.sum(
    _.map(dataSource, (n, i) =>
      n.bom?.length > 0
        ? _.sum(
          _.map(n.bom, (x) =>
            x.stock_balance > x.claim_qty ? x.claim_qty : x.stock_balance
          )
        )
        : 0
    )
  );

  return (
    <table className="w-100">
      <thead>
        <tr>
          {_.map(columns, (n, i) => (
            <td key={i} className="text-center">
              {n}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {_.map(dataSource, (n, i) => (
          <Fragment key={i}>
            <tr>
              <td>{i + 1}</td>
              <td className="text-center">{n.product_code}</td>
              <td>
                <Typography.Text className="mr-2">
                  {n.product_name}
                </Typography.Text>
                {loadingStock ? (
                  <Spin spinning size="small" />
                ) : (
                  n.stock_balance <= 0 && (
                    <Typography.Text className="text-reds">
                      (sold out)
                    </Typography.Text>
                  )
                )}
                {/* <BomDetail list={n.bom} qty={n.qty} /> */}
              </td>
              {/* <td className="text-center">{num(n.claim_price)}</td>
            <td className="text-center">{num(n.point_01)}</td> */}
              <td></td>
              <td className="text-center">
                {disabled ? (
                  n.bom?.length > 0 ? (
                    ""
                  ) : (
                    num(n.qty)
                  )
                ) : n.bom?.length > 0 ? (
                  ""
                ) : (
                  <InputNumber
                    value={n.stock_balance > n.qty ? n.qty : n.stock_balance}
                    size="small"
                    // min={n.min_qty}
                    min={0}
                    max={n.stock_balance}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                      dispatch(
                        setClaimOutProduct({
                          key: n.key,
                          value: { qty: e ?? n.min_qty },
                        })
                      )
                    }
                  />
                )}
              </td>
              <td>
                <Typography.Text className="text-reds">
                  {n.stock_balance < n.qty
                    ? n.stock_balance > 0
                      ? n.bom.length <= 0 && `เหลือ ${n.stock_balance} ชิ้น`
                      : ""
                    : ""}
                </Typography.Text>
              </td>
              {/* <td className="text-center">{num(n.claim_price * n.qty)}</td>
            <td className="text-center">{num(n.point_01 * n.qty)}</td>
            <td className="text-center">
              {_.includes(["result", "history"], process_params) ? (
                <QrCodeButton record={n} />
              ) : (
                !disabled && (
                  <Popconfirm
                    title="ต้องการลบรายการนี้หรือไม่"
                    disabled={n.status === 0}
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                    placement="topRight"
                    zIndex={1080}
                    onConfirm={() => dispatch(delClaimOutProduct(n.key))}
                  >
                    <Button
                      size="small"
                      shape="circle"
                      type="danger"
                      disabled={n.status === 0}
                    >
                      <FaTrashAlt size={10} />
                    </Button>
                  </Popconfirm>
                )
              )}
            </td> */}
            </tr>
            {/* ----------show bom detail (new) --------- */}
            {n.bom.length > 0 &&
              n.bom?.map((m, j) => (
                <>
                  <tr key={j}>
                    <td></td>
                    <td style={{ opacity: "0.6" }} className="text-center">
                      {m.product_code}
                    </td>
                    <td>
                      <Typography.Text className="mr-2 opacity-6">
                        {`${m.product_name} (${m.qty})`}
                      </Typography.Text>
                      {loadingStock ? (
                        <Spin spinning size="small" />
                      ) : (
                        m.stock_balance <= 0 && (
                          <Typography.Text className="text-reds">
                            (sold out)
                          </Typography.Text>
                        )
                      )}
                    </td>
                    <td></td>
                    <td
                      style={{ opacity: disabled ? "0.6" : "1" }}
                      className="text-center"
                    >
                      {disabled ? (
                        num(m.claim_qty)
                      ) : (
                        <InputNumber
                          value={
                            m.stock_balance > m.claim_qty
                              ? m.claim_qty
                              : m.stock_balance
                          }
                          size="small"
                          // min={n.min_qty}
                          min={0}
                          max={m.stock_balance}
                          onFocus={(e) => e.target.select()}
                          onChange={(e) =>
                            dispatch(
                              setClaimOutProductBom({
                                key: n.key,
                                index: j,
                                value: { claim_qty: e ?? m.min_qty },
                              })
                            )
                          }
                        />
                      )}
                    </td>
                    {/* <td>
                      <Typography.Text className="text-reds">
                        {m.stock_balance < m.claim_qty
                          ? m.stock_balance > 0
                            ? `เหลือ ${m.stock_balance} ชิ้น`
                            : ""
                          : ""}
                      </Typography.Text>
                    </td> */}
                  </tr>
                </>
              ))}
          </Fragment>
        ))}
        <tr>
          {_.map(columns, (_, i) => i < columns.length - 5 && <td key={i} />)}
          <td className="text-center py-3"></td>
          <td className="text-center py-3"></td>
          <td className="text-center py-3">
            {/* <strong>รวมเงินคืน</strong> */}
            <strong>รวม</strong>
          </td>
          <td className="text-center py-3">
            <strong>{num(sum_main_product + sum_bom_product)}</strong>
            {/* <strong>{num(totalQty)}</strong> */}
          </td>

          {/* <td className="text-center py-3">
            <strong>{num(totalPrice)}</strong>
          </td>
          <td className="text-center py-3">
            <strong>{num(totalPoint)}</strong>
          </td> */}
        </tr>
      </tbody>
    </table>
  );
};

export default memo(ProductTable);
