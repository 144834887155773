/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  Button,
  Col,
  Row,
  Typography,
  Input,
  Form,
  Select,
  DatePicker,
  Radio,
  Icon,
  Modal,
  TimePicker,
  message,
  Upload,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { Panel, PanelBody, PanelHeader } from "./panel";
import { dummyRequest } from "../../../manage-website/components/dummyRequest";
import { CustomTiTle } from "./TitleStyle";
import useUploadFile from "./useUploadFile";
import { axios, getUser } from "../../../../../utils";
import { useHistory } from "react-router-dom";
import ModalProduct from "./ModalProduct";
import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { eventRequest } from "../../../../../utils/useAxios";
import moment from "moment/moment";
import { FaCogs, FaTrash } from "react-icons/fa";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const initState = {
  loading: false,
  data: {
    id: 0,
    code: "",
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    order_type: ["hold", "regular"],
    order_source: ["pos", "online", "mobile"],
    status: "active",
    condition: { itemKeys: [], itemByKey: {} },
    receive: { itemKeys: [], itemByKey: {} },
  },
  modal: {
    product: {
      visible: false,
      active_by: null,
    },
  },
};

const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_MODAL_VISIBLE: (state, { payload: { name, visible, active_by } }) => {
    state.modal[name].visible = visible;
    state.modal[name].active_by = visible ? active_by : null;
  },
  SELECT_ITEM: (state, { payload }) => {
    let selItemKeys = [...state.data[state.modal.product.active_by].itemKeys];
    let selItemByKey = {
      ...state.data[state.modal.product.active_by].itemByKey,
    };

    selItemKeys.push(payload.id);
    selItemByKey = { ...selItemByKey, [payload.id]: payload };

    state.data[state.modal.product.active_by].itemKeys = selItemKeys;
    state.data[state.modal.product.active_by].itemByKey = selItemByKey;

    state.modal.product.visible = false;
    state.modal.product.active_by = null;
  },
  SELECT_ORDER_TYPE: (state, { payload: { type, checked } }) => {
    let newOrderType = [...state.data.order_type];

    if (checked) {
      newOrderType.push(type);
    } else {
      newOrderType = _.filter(newOrderType, (n) => n !== type);
    }

    state.data.order_type = newOrderType;
  },
  SELECT_ORDER_SOURCE: (state, { payload: { type, checked } }) => {
    let newOrderSource = [...state.data.order_source];

    if (checked) {
      newOrderSource.push(type);
    } else {
      newOrderSource = _.filter(newOrderSource, (n) => n !== type);
    }

    state.data.order_source = newOrderSource;
  },
  SET_ITEM_VALUE: (state, { payload: { type, key, name, value } }) => {
    state.data[type].itemByKey[key][name] = value;
  },
});

const radioStyle = {
  display: "block",
  height: "auto",
  maxHeight: "70px",
  lineHeight: "30px",
};

const EventInfo = ({ form }) => {
  const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue } =
    form;
  const history = useHistory();

  const [eventDateType, setEventDateType] = useState("");
  const [ticketType, setTicketType] = useState("");
  const [regDateType, setRegDateType] = useState("");

  const [eventType, setEventType] = useState("");
  const [cardType, setCardType] = useState("e-ticket");
  const [linkype, setLinkType] = useState("true");
  const [fileListBanner, setFileListBanner] = useState([]);
  const [fileListThumbnail, setFileListThumbnail] = useState([]);
  const [fileDelete, setFileDelete] = useState([]);
  const [ticketQty, setTicketQty] = useState(0);
  const [eventDate, setEventDate] = useState([]);
  const [eventOneDate, setEventOneDate] = useState("");
  const [eventDateStart, setEventDateStart] = useState("");
  const [eventDateEnd, setEventDateEnd] = useState("");
  const [regDate, setRegDate] = useState([]);
  const [regOneDate, setRegOneDate] = useState("");
  const [regDateStart, setRegDateStart] = useState("");
  const [regDateEnd, setRegDateEnd] = useState("");
  const [eventBannerDate, setEventBannerDate] = useState([]);

  const [bannerFile, setBannerFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);

  const [state, dispatch] = useReducer(reducer, initState);
  const [selectSKU, setSelectSKU] = useState([]);
  const [errorSKU, setErrorSKU] = useState("");
  const [errorThumbnail, setErrorThumbnail] = useState("");
  const [errorBanner, setErrorBanner] = useState("");

  const [eventData, setEventData] = useState(null);
  const [bannerId, setBannerId] = useState(null);
  const [thumbnailId, setThumbnailId] = useState(null);

  const [qrCodeStatus, setQRCodeStatus] = useState(false);

  let { onUpload } = useUploadFile();

  const bannerWidth = 1200;
  const bannerHeight = 300;

  const thumbnailWidth = 1200;
  const thumbnailHeight = 1200;

  const loadDataCallback = useCallback(async () => {
    try {
      const queryString = window.location;
      const pathname = queryString.pathname.split("/");
      const eventId = pathname[3];

      dispatch({ type: "SET_LOADING", payload: true });
      const data = await eventRequest.get(`/events/${eventId}`, {});

      setEventData(data.data.data);
      let oldEvent = data.data.data;
      if (oldEvent) {
        if (oldEvent.ItemEvent?.length) {
          setSelectSKU(oldEvent.ItemEvent);
        }
        if (oldEvent.File.length) {
          for (const file of oldEvent.File) {
            let isFile = [
              {
                uid: file.Id,
                name: file.FileName,
                url: file.AzureUrl,
              },
            ];
            if (file.DocumentType === "Banner") {
              setFileListBanner(isFile);
              form.setFieldsValue({
                banner_file: file,
              });
            } else {
              setFileListThumbnail(isFile);
              form.setFieldsValue({
                thumbnail_file: file,
              });
            }
          }
        }

        if (oldEvent.ReportStartDate) {
          form.setFieldsValue({
            event_banner_date: [
              moment(oldEvent.ReportStartDate),
              moment(oldEvent.ReportEndDate),
            ],
          });
        }
        setTicketType(oldEvent.TicketType);
        setEventType(oldEvent.EventType);
        setEventDateType(oldEvent.EventDateType);
        setRegDateType(oldEvent.RegDateType);
        setEventBannerDate([oldEvent.ReportStartDate, oldEvent.ReportEndDate]);
        setEventDate([oldEvent.StartDate, oldEvent.EndDate]);
        setRegDate([oldEvent.RegStartDate, oldEvent.RegEndDate]);
        setBannerId(oldEvent.File[0]?.Id);
        setThumbnailId(oldEvent.File[1]?.Id);
        setCardType(oldEvent.CardType);
        setLinkType(oldEvent.LinkStatus ? "true" : "false");
        if (oldEvent.EventDateType === "ONE_DAY") {
          setEventOneDate(oldEvent.StartDate.split("T")[0]);
          setEventDateStart(oldEvent.StartDate.split("T")[1]);
          setEventDateEnd(oldEvent.EndDate.split("T")[1]);
        }
        if (oldEvent.RegDateType === "ONE_DAY") {
          setRegOneDate(oldEvent.RegStartDate.split("T")[0]);
          setRegDateStart(oldEvent.RegStartDate.split("T")[1]);
          setRegDateEnd(oldEvent.RegEndDate.split("T")[1]);
        }
        form.setFieldsValue({
          event_name: oldEvent.EventName,
          event_type: oldEvent.EventType,
          prefix_serial: oldEvent.PrefixSerial,
          ticket_type: oldEvent.TicketType,
          event_date_type: oldEvent.EventDateType,
          reg_date_type: oldEvent.RegDateType,
          condition: oldEvent.Condition,
          link_status: oldEvent.LinkStatus ? "true" : "false",
        });

        if (
          oldEvent.EventType === "ONLINE" &&
          oldEvent.LinkStatus &&
          oldEvent.Location
        ) {
          form.setFieldsValue({
            link: oldEvent.Location,
          });
        } else {
          form.setFieldsValue({
            place: oldEvent.Location,
            card_type: oldEvent.CardType,
            ticket_qty: oldEvent.TicketEvent.length,
            password: oldEvent.Password,
          });
          setTicketQty(oldEvent.TicketEvent.length);
        }
        if (oldEvent.SerialQRCodeType === "assign") {
          setQRCodeStatus(false);
          // form.setFieldsValue({
          //   qr_switch: false
          // })
        } else {
          setQRCodeStatus(true);
          // form.setFieldsValue({
          //   qr_switch: true
          // })
        }
        if (oldEvent.EventDateType === "ONE_DAY") {
          form.setFieldsValue({
            event_one_date: moment(oldEvent.StartDate),
            start_date: moment(oldEvent.StartDate),
            end_date: moment(oldEvent.EndDate),
          });
        } else {
          form.setFieldsValue({
            event_date: [moment(oldEvent.StartDate), moment(oldEvent.EndDate)],
          });
        }

        if (oldEvent.RegDateType === "ONE_DAY") {
          form.setFieldsValue({
            reg_one_date: moment(oldEvent.RegStartDate),
            reg_start_date: moment(oldEvent.RegStartDate),
            reg_end_date: moment(oldEvent.RegEndDate),
          });
        } else {
          form.setFieldsValue({
            reg_date: [
              moment(oldEvent.RegStartDate),
              moment(oldEvent.RegEndDate),
            ],
          });
        }
      }
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      message.error(error.message);
    }
  }, [dispatch]);

  const handleUploadBanner = async (e) => {
    let isFile = await onUpload(e, bannerWidth, bannerHeight);
    if (isFile) {
      setFileListBanner(isFile);
      const file = isFile[0].file.originFileObj;
      const fd = new FormData();
      fd.append("image", file);
      const banner = await uploadFile(fd);
      setBannerFile(banner);
    }
  };
  const handleRemoveBanner = (e) => {
    setFileListBanner([]);
    // form.setFieldsValue({
    //   banner_file: null
    // })
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const handleUploadThumbnail = async (e) => {
    let isFile = await onUpload(e, thumbnailWidth, thumbnailHeight);
    if (isFile) {
      setFileListThumbnail(isFile);
      const file = isFile[0].file.originFileObj;
      const fd = new FormData();
      fd.append("image", file);
      const banner = await uploadFile(fd);
      setThumbnailFile(banner);
    }
  };

  const handleRemoveThumbnail = (e) => {
    setFileListThumbnail([]);
    // form.setFieldsValue({
    //   thumbnail_file: null
    // })
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };

  const renameFile = (file) => {
    let data = {
      fieldName: file.fieldname,
      originalName: file.originalname,
      fileType: file.mimetype,
      size: file.size,
      azureBlobName: file.filename,
      azureUrl: file.azureUrl,
    };
    return data;
  };
  const onFinish = (values) => {
    const queryString = window.location;
    const pathname = queryString.pathname.split("/");
    const eventId = pathname[3];

    let banner = null;
    let thumbnail = null;
    const user = getUser();
    if (bannerFile) {
      banner = renameFile(bannerFile);
      banner.fileId = bannerId;
    }
    if (thumbnailFile) {
      thumbnail = renameFile(thumbnailFile);
      thumbnail.fileId = thumbnailId;
    }
    let data = {
      eventId,
      eventName: values.event_name,
      eventType: values.event_type,
      eventDateType: values.event_date_type,
      reportStartDate: eventBannerDate[0],
      reportEndDate: eventBannerDate[1],
      regDateType: values.reg_date_type,
      regStartDate: regDate[0],
      regEndDate: regDate[1],
      location: values.link ? values.link : values.place,
      updatedBy: user.user_code,
      updatedByName: user.name,
      condition: values.condition,
      prefixSerial: values.prefix_serial,
      ticketType: values.ticket_type,
      linkStatus: values.link_status === "true" ? true : false,
      ticketQty:
        parseInt(values.ticket_qty) !== parseInt(ticketQty)
          ? parseInt(values.ticket_qty)
          : 0,
    };
    if (banner) data.bannerFile = banner;
    if (thumbnail) data.thumnailFile = thumbnail;

    // data.bannerFile = banner
    // data.thumnailFile = thumbnail
    // if (eventData.PrefixSerial !== values.prefix_serial) {
    //   data.prefixSerial = values.prefix_serial
    // }
    // if (eventData.TicketType !== values.ticket_type) data.ticketType = values.ticket_type
    if (selectSKU.length) {
      let items = [];
      for (const item of selectSKU) {
        let prod = {
          itemId: item.Id,
          prodId: item.id ? item.id : item.ProdId,
          backendSKUCode: item.backend_sku_code
            ? item.backend_sku_code
            : item.BackendSKUCode,
          barCode: item.bar_code ? item.bar_code : item.BarCode,
          productCode: item.product_code ? item.product_code : item.ProductCode,
          productNameEN: item.product_name_en
            ? item.product_name_en
            : item.ProductNameEN,
          productNameLocal: item.product_name_local
            ? item.product_name_local
            : item.ProductNameLocal,
          codeQty: item.CodeQTY
            ? parseInt(item.CodeQTY)
            : parseInt(item.code_qty) || 1,
        };
        items.push(prod);
      }
      data.items = items;
    } else {
      data.items = [];
    }

    if (values.event_date_type === "ONE_DAY") {
      data.startDate = eventOneDate + "T" + eventDateStart;
      data.endDate = eventOneDate + "T" + eventDateEnd;
    } else {
      data.startDate = eventDate[0];
      data.endDate = eventDate[1];
    }

    if (values.reg_date_type === "ONE_DAY" && regDateStart && regDateEnd) {
      data.regStartDate = regOneDate + "T" + regDateStart;
      data.regEndDate = regOneDate + "T" + regDateEnd;
    } else {
      data.regStartDate = regDate[0];
      data.regEndDate = regDate[1];
    }

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการแก้ไขงานอีเวนท์นี้หรือไม่?",
      okText: "ยืนยัน",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "patch",
            baseURL: process.env.REACT_APP_EVENT_URL_API,
            url: "events",
            data,
          })
            .then((res) => {
              console.log(res);
              resolve("success");
            })
            .catch((err) => {
              console.log(err);
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "แก้ไขงานอีเวนท์เรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.push("/events/event-list", { from: "event" });
                // window.location.href = `/events/event-list`
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        baseURL: process.env.REACT_APP_EVENT_URL_API,
        url: "azure/image",
        data: file,
      })
        .then((res) => {
          console.log(res);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(
            err && err.response
              ? err.response.data.message
                ? err.response.data.message
                : err.response.data
              : err.message
          );
        });
    }).catch((reason) => {
      Modal.error({
        title: "ผิดพลาด",
        content: reason,
        okText: "ปิด",
      });
    });
  };

  const selectEventDate = (value) => {
    setEventDateType(value);
  };

  const selectTicketType = (value) => {
    setTicketType(value);
  };

  const selectRegDate = (value) => {
    setRegDateType(value);
  };
  const onSelectEventType = (e) => {
    setEventType(e.target.value);
  };

  const handleEventDate = (value, dateString) => {
    setEventDate(dateString);
  };

  const handleEventBannerDate = (value, dateString) => {
    setEventBannerDate(dateString);
  };

  const handleEventOneDate = (value, dateString) => {
    setEventOneDate(dateString);
  };

  const handleEventDateStart = (value, dateString) => {
    setEventDateStart(dateString);
  };

  const handleEventDateEnd = (value, dateString) => {
    setEventDateEnd(dateString);
  };

  const handleRegDate = (value, dateString) => {
    setRegDate(dateString);
  };

  const handleRegOneDate = (value, dateString) => {
    setRegOneDate(dateString);
  };

  const handleRegDateStart = (value, dateString) => {
    setRegDateStart(dateString);
  };

  const handleRegDateEnd = (value, dateString) => {
    setRegDateEnd(dateString);
  };

  const handleSelectItem = (item) => {
    const result = selectSKU.filter(
      (prod) =>
        prod.product_code === item.product_code ||
        prod.ProductCode === item.product_code
    );
    if (!result.length) {
      setSelectSKU((selectSKU) => [...selectSKU, item]);
    }
  };

  const handleDeleteSKU = (d, i) => {
    setSelectSKU((selectSKU) => selectSKU.filter((_, index) => index !== i));
  };

  const onSelectCardType = (e) => {
    setCardType(e.target.value);
  };

  const handleSwitch = (check) => {
    setQRCodeStatus(!qrCodeStatus);
  };

  const handleQtyChange = (id, value) => {
    const updatedSKU = selectSKU.map((sku) => {
      if (sku.id === id) {
        return { ...sku, code_qty: value };
      } else if (sku.Id === id) {
        return { ...sku, CodeQTY: value };
      }
      return sku;
    });

    setSelectSKU(updatedSKU);
    setFieldsValue({ [`qty_${id}`]: value });
  };

  useEffect(() => {
    if (selectSKU.length) {
      setErrorSKU("");
    }
  }, [selectSKU]);

  useEffect(() => {
    if (fileListBanner.length) {
      setErrorBanner("");
    }
  }, [fileListBanner]);

  useEffect(() => {
    if (fileListThumbnail.length) {
      setErrorThumbnail("");
    }
  }, [fileListThumbnail]);

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);
  console.log("selectSKU ", selectSKU);
  return (
    <>
      <ModalProduct
        visible={state.modal.product.visible}
        onSelect={(item) => handleSelectItem(item)}
        onCancel={() =>
          dispatch({
            type: "SET_MODAL_VISIBLE",
            payload: {
              name: "product",
              visible: false,
              active_by: null,
            },
          })
        }
      />

      <Form onSubmit={onFinish}>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: "28px",
            lineHeight: "42px",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          แก้ไขงานอีเวนท์
        </Typography>
        <Panel>
          <PanelHeader>รายละเอียดโปรเจ็กต์</PanelHeader>
          <PanelBody>
            <Row gutter={[48, 16]} className="mb-3">
              <Col lg={12}>
                <CustomTiTle title={"ชื่อโปรเจ็กต์"} />
                <Form.Item>
                  {getFieldDecorator("event_name", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาระบุชื่อโปรเจ็กต์",
                      },
                    ],
                  })(<Input placeholder="ระบุชื่อโปรเจ็กต์" allowClear />)}
                </Form.Item>

                <CustomTiTle title={"รูปแบบโปรเจ็กต์"} />
                <Form.Item style={{ maxHeight: "140px" }}>
                  {getFieldDecorator("event_type", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาเลือกรูปแบบโปรเจ็กต์",
                      },
                    ],
                  })(
                    <Radio.Group
                      onChange={onSelectEventType}
                      style={{ width: "101%" }}
                      disabled
                    >
                      <Radio style={radioStyle} value={"ONLINE"}>
                        งานออนไลน์ / E-Ticket <br />
                        {eventType === "ONLINE" && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              width: "100%",
                              paddingLeft: "20px",
                            }}
                          >
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator("link_status", {
                                initialValue: "true",
                              })(
                                <Select style={{ minWidth: "140px" }} disabled>
                                  <Select.Option value={"true"}>
                                    มีลิงก์รับชมงาน
                                  </Select.Option>
                                  <Select.Option value={"false"}>
                                    ไม่มีลิงก์รับชมงาน
                                  </Select.Option>
                                </Select>
                              )}
                            </Form.Item>
                            {linkype === "true" && (
                              <Form.Item
                                style={{
                                  width: "100%",
                                  marginBottom: 0,
                                  paddingLeft: "4px",
                                }}
                              >
                                {getFieldDecorator("link", {
                                  initialValue:
                                    "http://channel.legacy.co.th/auth/login",
                                  rules: [
                                    {
                                      required: true,
                                      message: "กรุณาระบุ Url",
                                    },
                                  ],
                                })(
                                  <Input
                                    placeholder="วาง Url ที่นี่"
                                    allowClear
                                  />
                                )}
                              </Form.Item>
                            )}
                          </div>
                        )}
                        {/* {eventType === 'ONLINE' &&
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingLeft: '20px' }}>
                            <Typography style={{
                              fontSize: '14px',
                              paddingBottom: '25px',
                              paddingRight: '4px',
                              color: '#FF5B57'
                            }}>*</Typography>
                            <Typography style={{
                              fontSize: '14px',
                              paddingBottom: '25px',
                              paddingRight: '4px',
                              color: '#1D2226',
                            }}>ลิงก์รับชมงาน :</Typography>
                            <Form.Item style={{ width: '100%' }}>
                              {getFieldDecorator("link", {
                                rules: [{
                                  required: true,
                                  message: "กรุณาระบุ Url",
                                }],
                              })(
                                <Input placeholder="วาง Url ที่นี่" allowClear />
                              )}
                            </Form.Item>
                          </div>
                        } */}
                      </Radio>
                      <Radio style={radioStyle} value={"OFFLINE"}>
                        งานออฟไลน์ <br />
                        {eventType === "OFFLINE" && (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              width: "100%",
                              paddingLeft: "20px",
                              minHeight: "40px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                paddingBottom: "25px",
                                paddingRight: "4px",
                                color: "#FF5B57",
                              }}
                            >
                              *
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                paddingBottom: "25px",
                                paddingRight: "4px",
                                color: "#1D2226",
                              }}
                            >
                              สถานที่จัดงาน :
                            </Typography>
                            <Form.Item style={{ width: "100%" }}>
                              {getFieldDecorator("place", {
                                rules: [
                                  {
                                    required: true,
                                    message: "กรุณาระบุสถานที่จัดงาน",
                                  },
                                ],
                              })(<Input placeholder="ระบุ" allowClear />)}
                            </Form.Item>
                          </div>
                        )}
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                {eventType === "OFFLINE" && (
                  <>
                    <CustomTiTle title={"รูปแบบบัตรที่จำหน่าย"} />
                    <Form.Item>
                      {getFieldDecorator("card_type", {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "กรุณาเลือกรูปแบบบัตรที่จำหน่าย",
                          },
                        ],
                      })(
                        <Radio.Group
                          onChange={onSelectCardType}
                          style={{
                            padding: 0,
                            marginBottom: "-200px",
                            width: "101%",
                          }}
                          disabled
                        >
                          <Radio style={radioStyle} value={"e-ticket"}>
                            E-Ticket <br />
                          </Radio>
                          <Radio style={radioStyle} value={"physical-ticket"}>
                            บัตรกระดาษ <br />
                            {cardType === "physical-ticket" && (
                              <>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    width: "100%",
                                    paddingLeft: "20px",
                                    minHeight: "20px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "4px",
                                      paddingRight: "4px",
                                      color: "#FF5B57",
                                    }}
                                  >
                                    *
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "4px",
                                      paddingRight: "4px",
                                      color: "#1D2226",
                                    }}
                                  >
                                    จำนวนบัตรทั้งหมด
                                  </Typography>
                                  <Form.Item
                                    style={{
                                      width: "100%",
                                      marginBottom: 0,
                                      marginLeft: "8px",
                                    }}
                                  >
                                    {getFieldDecorator("ticket_qty", {
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            "กรุณาระบุจำนวนบัตรที่จำหน่าย",
                                        },
                                        {
                                          pattern: /^\d*$/,
                                          message:
                                            "กรุณาระบุเป็นจำนวนเต็มบวกเท่านั้น",
                                        },
                                        {
                                          validator: (
                                            rule,
                                            value,
                                            callback
                                          ) => {
                                            if (parseInt(value) < ticketQty) {
                                              callback(
                                                `ไม่สามารถระบุต่ำกว่า ${ticketQty}`
                                              );
                                              return;
                                            } else {
                                              callback();
                                              return;
                                            }
                                          },
                                        },
                                      ],
                                    })(
                                      <Input
                                        placeholder="ระบุจำนวนบัตรที่จำหน่าย"
                                        allowClear
                                        type="number"
                                        min={0}
                                        // disabled
                                      />
                                    )}
                                  </Form.Item>
                                </div>
                                <br />
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    width: "100%",
                                    paddingLeft: "20px",
                                    minHeight: "20px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      paddingBottom: "25px",
                                      paddingRight: "4px",
                                      color: "#FF5B57",
                                    }}
                                  >
                                    *
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      paddingBottom: "25px",
                                      paddingRight: "4px",
                                      color: "#1D2226",
                                    }}
                                  >
                                    รหัสผ่านเข้าร่วมงาน
                                  </Typography>
                                  <Form.Item
                                    style={{ width: "100%", marginLeft: "2px" }}
                                  >
                                    {getFieldDecorator("password", {
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            "กรุณาระบุรหัสผ่านสำหรับผู้ตรวจบัตร",
                                        },
                                      ],
                                    })(
                                      <Input
                                        placeholder="ระบุรหัสผ่านสำหรับผู้ตรวจบัตร"
                                        allowClear
                                      />
                                    )}
                                  </Form.Item>
                                </div>
                              </>
                            )}
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </>
                )}

                <CustomTiTle title={"ประเภทบัตรงาน"} />
                <Form.Item>
                  {getFieldDecorator("ticket_type", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาเลือกประเภทบัตรงาน",
                      },
                    ],
                  })(
                    <Select
                      placeholder="-เลือกประเภท-"
                      onChange={selectTicketType}
                    >
                      <Select.Option value="NOT_FREE">บัตรมีราคา</Select.Option>
                      <Select.Option value="FREE">
                        ไม่มีค่าใช้จ่าย
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>

                {(eventType === "ONLINE" ||
                  (eventType === "OFFLINE" && cardType === "e-ticket")) && (
                  <>
                    <CustomTiTle title={"ข้อมูล SKU"} />
                    <Row>
                      <Button
                        type="primary"
                        style={{
                          padding: "6px 12px",
                          borderRadius: "6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                        onClick={() =>
                          dispatch({
                            type: "SET_MODAL_VISIBLE",
                            payload: {
                              name: "product",
                              visible: true,
                              active_by: null,
                            },
                          })
                        }
                      >
                        <Icon type="plus-circle" />
                        เพิ่ม SKU
                      </Button>
                    </Row>

                    {
                      selectSKU.length !== 0 && (
                        <Table
                          size="small"
                          bordered
                          scroll={{
                            x: "max-content",
                          }}
                          dataSource={selectSKU || []}
                          rowKey={(record) => record.Id || record.id}
                          pagination={{
                            pageSize: 5,
                          }}
                          style={{
                            marginTop: "4px",
                          }}
                        >
                          <Table.Column
                            title="รหัสสินค้า"
                            key="product_code"
                            align="center"
                            render={(text, record) =>
                              record.ProductCode || record.product_code
                            }
                          />
                          <Table.Column
                            title="รายการ"
                            key="product_name_local"
                            align="center"
                            render={(text, record) =>
                              record.ProductNameLocal ||
                              record.product_name_local
                            }
                          />
                          <Table.Column
                            title="การซื้อ"
                            key="purchase_qty"
                            align="center"
                            render={() => {
                              return 1;
                            }}
                          />
                          <Table.Column
                            title="จำนวน code ที่ได้รับ"
                            key="code_qty"
                            align="center"
                            width={150}
                            render={(text, record) => {
                              console.log("record ", record.id, record.Id);
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Form.Item
                                    style={{ width: "80%", marginBottom: 0 }}
                                  >
                                    {getFieldDecorator(
                                      `qty_${record.Id || record.id}`,
                                      {
                                        initialValue: record.CodeQTY
                                          ? record.CodeQTY
                                          : record.code_qty || 1,
                                        rules: [
                                          {
                                            required: true,
                                            message: "กรุณาระบุจำนวน code",
                                          },
                                          {
                                            pattern: /^\d*$/,
                                            message:
                                              "กรุณาระบุเป็นจำนวนเต็มบวกเท่านั้น",
                                          },
                                        ],
                                      }
                                    )(
                                      <Input
                                        placeholder="1"
                                        type="number"
                                        min={1}
                                        onChange={(e) =>
                                          handleQtyChange(
                                            record.Id || record.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}
                                  </Form.Item>
                                </div>
                              );
                            }}
                          />
                          <Table.Column
                            title={<FaCogs />}
                            key="action"
                            align="center"
                            render={(text, rec, index) => {
                              return (
                                <Tooltip title="ลบรายการ">
                                  <Button
                                    size="small"
                                    type="danger"
                                    ghost
                                    onClick={() => handleDeleteSKU(rec, index)}
                                  >
                                    <FaTrash />
                                  </Button>
                                </Tooltip>
                              );
                            }}
                          />
                        </Table>
                      )
                      // selectSKU.map((d, index) => (
                      //   <li key={index}>
                      //     <Row
                      //       style={{
                      //         display: "flex",
                      //         alignItems: "center",
                      //         marginBottom: "8px",
                      //         marginTop: "4px",
                      //       }}
                      //     >
                      //       <Icon
                      //         type="close-circle"
                      //         theme="filled"
                      //         style={{
                      //           color: "#FF5B57",
                      //           paddingRight: "8px",
                      //           fontSize: "150%",
                      //         }}
                      //         onClick={() => handleDeleteSKU(d, index)}
                      //       />
                      //       <Typography style={{}}>
                      //         {d.ProductCode ? d.ProductCode : d.product_code}{" "}
                      //         {d.ProductNameLocal
                      //           ? d.ProductNameLocal
                      //           : d.product_name_local}
                      //       </Typography>
                      //     </Row>
                      //   </li>
                      // ))
                    }

                    {errorSKU && (
                      <Typography style={{ color: "#f5222d" }}>
                        {errorSKU}
                      </Typography>
                    )}
                  </>
                )}

                {/* {(ticketType === 'NOT_FREE' && cardType === 'physical-ticket' && eventType === 'OFFLINE') &&
                  <>
                    <Row>
                      <Col span={20}>
                        <CustomTiTle title={'QR Code แบบไม่ระบุเลขบัตร'} color={qrCodeStatus ? 'lime' : ''} notReq={true} />
                      </Col>
                      <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Switch checked={qrCodeStatus} onChange={handleSwitch} />
                      </Col>
                    </Row>
                  </>
                } */}
              </Col>
              <Col lg={12}>
                <CustomTiTle title={"Prefix Serial"} />
                <Form.Item>
                  {getFieldDecorator("prefix_serial", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาระบุ Prefix Serial",
                      },
                    ],
                  })(
                    <Input
                      placeholder="ระบุ Prefix Serial (ไม่เกิน 2 ตัวอักษร)"
                      maxLength={2}
                      allowClear
                      disabled
                    />
                  )}
                </Form.Item>

                <CustomTiTle title={"ระยะเวลาการจัดงาน"} />
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("event_date_type", {
                    initialValue: "ONE_DAY",
                    rules: [
                      {
                        required: true,
                        message: "กรุณาเลือกระยะเวลาการจัดงาน",
                      },
                    ],
                  })(
                    <Select onChange={selectEventDate}>
                      <Select.Option value="ONE_DAY">
                        เลือกวันเดียว
                      </Select.Option>
                      <Select.Option value="MORE_THAN_ONE_DAY">
                        เลือกมากกว่า 1 วัน
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>

                {eventDateType === "ONE_DAY" ? (
                  <>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("event_one_date", {
                        rules: [
                          {
                            // type: 'object',
                            required: true,
                            message: "กรุณาเลือกวัน",
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder="เลือกวัน"
                          style={{ width: "100%" }}
                          onChange={handleEventOneDate}
                        />
                      )}
                    </Form.Item>

                    <Row>
                      <Col span={12}>
                        <Form.Item>
                          {getFieldDecorator("start_date", {
                            rules: [
                              {
                                // type: 'object',
                                required: true,
                                message: "กรุณาเลือกเวลาเริ่มต้น",
                              },
                            ],
                          })(
                            <TimePicker
                              placeholder="เลือกเวลาเริ่มต้น"
                              style={{ width: "100%" }}
                              onChange={handleEventDateStart}
                              format="HH:mm"
                              showTime={{ format: "HH:mm" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item>
                          {getFieldDecorator("end_date", {
                            rules: [
                              {
                                // type: 'object',
                                required: true,
                                message: "กรุณาเลือกเวลาสิ้นสุด",
                              },
                            ],
                          })(
                            <TimePicker
                              placeholder="เลือกเวลาสิ้นสุด"
                              style={{ width: "100%" }}
                              onChange={handleEventDateEnd}
                              format="HH:mm"
                              showTime={{ format: "HH:mm" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Form.Item>
                    {getFieldDecorator("event_date", {
                      rules: [
                        {
                          type: "array",
                          required: true,
                          message: "กรุณาเลือกวันที่และเวลา",
                        },
                      ],
                    })(
                      <RangePicker
                        placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                        separator="-"
                        showTime={{ format: "HH:mm" }}
                        format="YYYY-MM-DD HH:mm"
                        style={{ width: "100%" }}
                        onChange={handleEventDate}
                      />
                    )}
                  </Form.Item>
                )}

                <CustomTiTle title={"ระยะเวลาการลงทะเบียน"} />
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("reg_date_type", {
                    initialValue: "ONE_DAY",
                    rules: [
                      {
                        required: true,
                        message: "กรุณาเลือกระยะเวลาการลงทะเบียน",
                      },
                    ],
                  })(
                    <Select onChange={selectRegDate}>
                      <Select.Option value="ONE_DAY">
                        เลือกวันเดียว
                      </Select.Option>
                      <Select.Option value="MORE_THAN_ONE_DAY">
                        เลือกมากกว่า 1 วัน
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>

                {regDateType === "ONE_DAY" ? (
                  <>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("reg_one_date", {
                        rules: [
                          {
                            // type: 'object',
                            required: true,
                            message: "กรุณาเลือกวัน",
                          },
                        ],
                      })(
                        <DatePicker
                          placeholder="เลือกวัน"
                          style={{ width: "100%" }}
                          onChange={handleRegOneDate}
                        />
                      )}
                    </Form.Item>

                    <Row>
                      <Col span={12}>
                        <Form.Item>
                          {getFieldDecorator("reg_start_date", {
                            rules: [
                              {
                                // type: 'object',
                                required: true,
                                message: "กรุณาเลือกเวลาเริ่มต้น",
                              },
                            ],
                          })(
                            <TimePicker
                              placeholder="เลือกเวลาเริ่มต้น"
                              style={{ width: "100%" }}
                              onChange={handleRegDateStart}
                              format="HH:mm"
                              showTime={{ format: "HH:mm" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item>
                          {getFieldDecorator("reg_end_date", {
                            rules: [
                              {
                                // type: 'object',
                                required: true,
                                message: "กรุณาเลือกเวลาสิ้นสุด",
                              },
                            ],
                          })(
                            <TimePicker
                              placeholder="เลือกเวลาสิ้นสุด"
                              style={{ width: "100%" }}
                              onChange={handleRegDateEnd}
                              format="HH:mm"
                              showTime={{ format: "HH:mm" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Form.Item>
                    {getFieldDecorator("reg_date", {
                      rules: [
                        {
                          type: "array",
                          required: true,
                          message: "กรุณาเลือกวันที่และเวลา",
                        },
                      ],
                    })(
                      <RangePicker
                        placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                        separator="-"
                        showTime={{ format: "HH:mm" }}
                        format="YYYY-MM-DD HH:mm"
                        style={{ width: "100%" }}
                        onChange={handleRegDate}
                      />
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
          </PanelBody>
        </Panel>

        <Panel>
          <PanelHeader>ข้อมูลรายงานแสดงหน้าผังองค์กรนักธุรกิจ</PanelHeader>
          <PanelBody>
            <Row gutter={[48, 16]} className="mb-3">
              <Col xl={12}>
                <CustomTiTle
                  title={"อัปโหลด Banner รายงาน"}
                  desc={`(ประเภทไฟล์ .jpg, .png | Dimension : 1200 x 300 px | Size : ไม่เกิน 1 mb)`}
                />
                <Form.Item>
                  {getFieldDecorator("banner_file", {
                    // rules: [{
                    //   required: true,
                    //   message: "กรุณาอัปโหลด Banner รายงาน",
                    // }],
                  })(
                    <>
                      <Row type="flex" justify="center" align="middle">
                        <Col>
                          {fileListBanner.length !== 0 ? (
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <img
                                  src={fileListBanner[0]?.url}
                                  alt="banner_file"
                                  style={{
                                    marginTop: "28px",
                                    marginBottom: "12px",
                                    width: "420px",
                                    height: "105px",
                                  }}
                                />
                              </div>
                            </Row>
                          ) : (
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "28px",
                                  marginBottom: "12px",
                                  width: "420px",
                                  height: "105px",
                                  backgroundColor: "#D9D9D9",
                                  border: "1px dashed #97D700",
                                }}
                              />
                            </Row>
                          )}
                        </Col>
                      </Row>

                      <Row type="flex" justify="center" align="middle">
                        <Upload
                          maxCount={1}
                          multiple={false}
                          fileList={fileListBanner}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveBanner}
                          onChange={handleUploadBanner}
                          listType="picture"
                        >
                          <Button
                            style={{
                              color: "#97D700",
                              border: "1px solid #97D700",
                              borderRadius: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "420px",
                            }}
                          >
                            <Icon type="upload" />
                            อัปโหลดไฟล์
                          </Button>
                        </Upload>
                      </Row>

                      {errorBanner && (
                        <Typography style={{ color: "#f5222d" }}>
                          {errorBanner}
                        </Typography>
                      )}
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col xl={12}>
                <CustomTiTle title={"ระยะเวลาแสดงผลรายงาน"} />
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("event_banner_date", {
                    rules: [
                      {
                        type: "array",
                        required: true,
                        message: "กรุณาเลือกวันที่และเวลา",
                      },
                    ],
                  })(
                    <RangePicker
                      placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                      separator="-"
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      style={{ width: "100%" }}
                      onChange={handleEventBannerDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </PanelBody>
        </Panel>

        <Panel>
          <PanelHeader>ข้อมูลบัตรงานฝั่งสมาชิก</PanelHeader>
          <PanelBody>
            <Row gutter={[48, 16]} className="mb-3">
              <Col xl={12}>
                <CustomTiTle
                  title={"อัปโหลด Thumbnail หน้าบัตร"}
                  desc={`(ประเภทไฟล์ .jpg, .png | Dimension : 1200 x 1200 px | Size : ไม่เกิน 1 mb)`}
                />
                <Form.Item>
                  {getFieldDecorator("thumbnail_file", {
                    // rules: [{
                    //   required: true,
                    //   message: "กรุณาอัปโหลด Thumbnail หน้าบัตร",
                    // }],
                  })(
                    <>
                      <Row type="flex" justify="center" align="middle">
                        <Col>
                          {fileListThumbnail.length !== 0 ? (
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <img
                                  src={fileListThumbnail[0]?.url}
                                  alt="thumbnail_file"
                                  style={{
                                    marginTop: "28px",
                                    marginBottom: "12px",
                                    width: "200px",
                                    height: "200px",
                                  }}
                                />
                              </div>
                            </Row>
                          ) : (
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "28px",
                                  marginBottom: "12px",
                                  width: "200px",
                                  height: "200px",
                                  backgroundColor: "#D9D9D9",
                                  border: "1px dashed #97D700",
                                }}
                              />
                            </Row>
                          )}
                        </Col>
                      </Row>

                      <Row type="flex" justify="center" align="middle">
                        <Upload
                          maxCount={1}
                          multiple={false}
                          fileList={fileListThumbnail}
                          customRequest={dummyRequest}
                          onRemove={handleRemoveThumbnail}
                          onChange={handleUploadThumbnail}
                          listType="picture"
                        >
                          <Button
                            style={{
                              color: "#97D700",
                              border: "1px solid #97D700",
                              borderRadius: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "420px",
                            }}
                          >
                            <Icon type="upload" />
                            อัปโหลดไฟล์
                          </Button>
                        </Upload>
                      </Row>

                      {errorThumbnail && (
                        <Typography style={{ color: "#f5222d" }}>
                          {errorThumbnail}
                        </Typography>
                      )}
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col xl={12}>
                <CustomTiTle
                  notReq={true}
                  title={"เงื่อนไขการใช้บัตร"}
                  desc={`(แสดงผลที่หน้าบัตรบนเว็บและแอพพลิเคชันของสมาชิก)`}
                />
                <Form.Item style={{ marginTop: "24px" }}>
                  {getFieldDecorator("condition")(
                    <TextArea placeholder="พิมพ์เงื่อนไขที่นี่" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </PanelBody>
        </Panel>

        <Form.Item>
          <Row gutter={[24, 16]} type="flex" justify="center">
            <Col>
              <Button
                type="danger"
                ghost
                onClick={() => {
                  history.push("/events/event-list", { from: "event" });
                }}
                style={{
                  width: "140px",
                  height: "47px",
                  padding: "10px 35px",
                  borderRadius: "12px",
                }}
              >
                ยกเลิก
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                onClick={() => {
                  validateFields((err, values) => {
                    // if (values.ticket_type === 'NOT_FREE' && !selectSKU.length) {
                    //   setErrorSKU('กรุณาเพิ่ม SKU')
                    // }
                    if (cardType === "e-ticket" && !selectSKU.length) {
                      setErrorSKU("กรุณาเพิ่ม SKU");
                    }

                    if (!fileListBanner.length) {
                      setErrorBanner("กรุณาอัปโหลด Banner รายงาน");
                    }

                    if (!fileListThumbnail.length) {
                      setErrorThumbnail("กรุณาอัปโหลด Thumbnail หน้าบัตร");
                    }

                    if (!err || (cardType === "e-ticket" && selectSKU.length)) {
                      onFinish(values);
                    }
                  });
                }}
                style={{
                  width: "140px",
                  height: "47px",
                  padding: "10px 35px",
                  borderRadius: "12px",
                }}
              >
                บันทึก
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create("event")(EventInfo);
