import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Row,
  Spin,
  Typography,
} from "antd";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import { moment, _ } from "../../../../../utils";
import BillModal from "../BillModal";
import ProductBomModal from "../ProductBomModal";
import ProductModal from "../ProductModal";
import BillItemTable from "./BillItemTable";

const { Text } = Typography;

const { setGlobalState } = actionClaim;

const list = [
  {
    title: "วันที่ออกใบเสร็จ",
    dataIndex: "document_date",
    is_date: true,
  },
  {
    title: "ประเภทการสั่งซื้อ",
    dataIndex: "order_type",
  },
  {
    title: "ผู้ดำเนินการ",
    dataIndex: "buyer_name",
  },
  {
    title: "เบอร์โทรผู้ดำเนินการ",
    dataIndex: "buyer_mobile",
  },
  {
    title: "ใบเสร็จ",
    dataIndex: "req_receipt_text",
  },
  {
    title: "ประเภทการรับของ",
    dataIndex: "req_receipt_type_text",
  },
  {
    title: "ชื่อผู้รับ",
    dataIndex: "ship_to",
  },
  {
    title: "เบอร์โทรผู้รับ",
    dataIndex: "ship_mobile",
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const isPreview = useSelector((state) => state.claim.isPreview);
  const { document_no, req_receipt_type, ...bill } = useSelector(
    (state) => state.claim.bill
  );
  const claim_type = useSelector(
    (state) => state.claim.documentDetail.claim_type
  );
  const partner_code = useSelector((state) => state.claim.partner.code);
  const { loading, error } = useSelector(
    (state) => state.claim.fetch.bill_select
  );
  const isBill = useSelector((state) =>
    _.includes([null, "draft", "waiting"], state.claim.documentDetail.status)
  );
  const is_transfer = bill.req_receipt_type_text === "จัดส่ง";
  const disable_select_bill = !claim_type || !partner_code;

  const doc_status = useSelector((state) => state.claim.documentDetail.status)

  return (
    <>
      <Row className="bg-gray-60">
        <Col
          span={24}
          className="d-flex justify-content-between align-items-center px-2 py-2"
        >
          <Text strong style={{ fontSize: "1.5em" }} className="text-white">
            เลขที่ใบเสร็จ : {document_no}
          </Text>
          {!isPreview && isBill && (
            <Button
              disabled={doc_status === 'draft' ? true : disable_select_bill}
              type="primary"
              className="width-150"
              onClick={() =>
                dispatch(
                  setGlobalState({
                    name: "billModal",
                    value: { visible: true },
                  })
                )
              }
            >
              <FaPlus className="mr-2" />
              เลือกใบเสร็จ
            </Button>
          )}
        </Col>
      </Row>
      <Row className="bg-gray-20 mb-3">
        <Col span={24} className="px-4 py-2">
          {!!error ? (
            <Alert type="error" showIcon message={error} />
          ) : loading ? (
            <Spin spinning>
              <div className="height-100" />
            </Spin>
          ) : !document_no ? (
            <Empty className="py-3" />
          ) : (
            <>
              <Row gutter={8} type="flex">
                {_.map(list, (n) => (
                  <React.Fragment key={n.dataIndex}>
                    <Col span={4} xl={3} className="text-right">
                      <Text strong>{n.title} :</Text>
                    </Col>
                    <Col span={8} xl={3}>
                      <Text>
                        {n.is_date && !!bill[n.dataIndex]
                          ? moment(bill[n.dataIndex]).format("DD/MM/YYYY HH:mm")
                          : bill[n.dataIndex]}
                      </Text>
                    </Col>
                  </React.Fragment>
                ))}
                <Col span={4} xl={3} className="text-right">
                  <Text strong>
                    {is_transfer ? "ที่อยู่จัดส่ง" : "สาขาที่รับ"} :
                  </Text>
                </Col>
                <Col span={20}>
                  <Text>
                    {is_transfer ? bill.ship_address : bill.receipt_branch_name}
                  </Text>
                </Col>
              </Row>
              <Divider />
              <BillItemTable />
            </>
          )}
        </Col>
      </Row>
      {!disable_select_bill && <BillModal />}
      <ProductModal />
      <ProductBomModal />
    </>
  );
};

export default Index;
