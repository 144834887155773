import React from "react";
import { Route, Switch } from "react-router-dom";

// routes
import BannerContent from "./BannerContent";
import BannerList from "./BannerList";

// error page
import Error404 from "../../Error404";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}`} exact component={BannerList} />
      <Route path={`${match.path}/:id/:type`} exact component={BannerContent} />
      <Route component={Error404} />
    </Switch>
  );
};
