/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useRef, useEffect } from 'react';

const useAsync = (
  promise,
  { immediate = false, args, onSuccess, onFailed, onFinal, onFinish }
) => {
  const [data, setValue] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState('idle');

  const reset = useCallback(() => {
    setStatus('idle');
    setValue(null);
    setError(null);
  }, [promise]);

  const _savedPromiseFunction = useRef(promise);

  const _setLoadState = useCallback((status) => {
    setStatus(status);
  }, []);

  const execute = useCallback(
    (args) => {
      _setLoadState('loading');
      setValue(undefined);

      // Note: IE(old) browser not support async/await feature you can use only promise/then
      _savedPromiseFunction
        .current(args)
        .then((res) => {
          if (onSuccess) {
            onSuccess(res);
          }
          _setLoadState('successed');
          setValue(res);
          return res;
        })
        .then((res) => {
          if (onFinish) {
            onFinish(res);
          }
          return res;
        })
        .catch((error) => {
          if (onFailed) {
            onFailed(error.message);
          }
          _setLoadState('failed');
          setError(error.message);
          setValue(null);
        })
        .finally(() => {
          if (onFinal) {
            onFinal();
          }
        });
    },
    [_setLoadState]
  );

  useEffect(() => {
    if (immediate) {
      if (args) {
        execute(args);
      } else {
        execute();
      }
    }
  }, [execute, immediate]);

  // return { execute, status: 'loading', data, error, reset };
  return { execute, status, data, error, reset };
};

export default useAsync;
