import { Button, Empty, Modal, Table, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../../../hooks/http2";
import { displayTotal } from "../../../../../utils/helper";
import moment from "moment";
import { FaArrowDown, FaArrowUp, FaCog, FaEdit, FaTrash } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionMWBanner } from "../../../../../redux/slices/manage-website/banner.slice";
import { URL_API, axios, getToken } from "../../../../../utils";

const TableBanner = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { current, page, limit } = useSelector(
    (state) => state.mw.banner,
    shallowEqual
  );
  const { SET_CURRENT } = actionMWBanner;
  const [banners, setBanners] = useState([])

  const list = useApi(
    {
      url: `/manage-website/banner`,
      token: true,
      params: { page, offset: (current - 1) * limit, limit },
    },
    [current, page]
  );

  const handleSort = async (data) => {
    try {
      await axios.post(`/manage-website/banner/sort`, { data }, {
        baseURL: URL_API,
        headers: { Authorization: "Bearer " + getToken() },
      });
      setBanners(data);
      list.reload();
    } catch (error) {
      console.log(error.response?.data?.message || error.message);
    }
  };

  const handleDel = (id) => {
    Modal.confirm({
      title: "คุณต้องการลบข้อมูลหรือไม่",
      content: "หากลบจะไม่สามารถกู้คืนข้อมูลได้",
      okText: "ลบ",
      okButtonProps: { type: "danger" },
      cancelText: "ปิด",
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            const res = await axios.delete(`/manage-website/banner/${id}`, {
              baseURL: URL_API,
              headers: { Authorization: "Bearer " + getToken() },
            });
            resolve(res);
          } catch (error) {
            reject(error.response?.data?.message || error.message);
          }
        })
          .then((res) => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                if (current !== 1) {
                  dispatch(SET_CURRENT(1));
                } else {
                  list.reload();
                }
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handelSwap = async (index1, index2) => {
    if (index1 >= 0 && index1 < banners.length && index2 >= 0 && index2 < banners.length) {
      // Create a copy of the array
      const newArray = [...banners];

      // Swap elements in the copied array
      const temp = newArray[index1];
      newArray[index1] = newArray[index2];
      newArray[index2] = temp;

      // Swap row_id values
      const tempRowId = newArray[index1].row_id;
      newArray[index1].row_id = newArray[index2].row_id;
      newArray[index2].row_id = tempRowId;

      await handleSort(newArray)
    } else {
      console.error("Invalid indices for swapping.");
    }
  };

  useEffect(() => {
    if (list?.fetch?.data && Array.isArray(list.fetch.data) && banners.length === 0) {
      setBanners([...list.fetch.data]);
    }
  }, [list, banners]);

  useEffect(() => {
    if (list?.fetch) {
      setBanners([...(list?.fetch?.data || [])]);
    }
  }, [list.fetch]);

  return (
    <Table
      size="small"
      bordered
      loading={list?.loading}
      rowKey="id"
      // rowKey={(record) => `${record.id}`}
      dataSource={banners || []}
      // dataSource={list?.fetch?.data || []}
      pagination={{
        current: current,
        pageSize: limit,
        size: "default",
        total: list?.fetch?.total || 0,
        showLessItems: true,
        showTotal: displayTotal,
        onChange: (p) => dispatch(SET_CURRENT(p)),
      }}
      scroll={{ x: "max-content" }}
      className="table-text-top"
    >
      <Table.Column
        title="#"
        dataIndex="row_id"
        align="center"
        render={(id, record, index) => (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {record.row_id}
            <Row>
              <Col span={24}>
                <FaArrowUp onClick={() => handelSwap(index, index - 1)} disabled={index === 0} style={{
                  cursor: index === 0 ? 'not-allowed' : 'pointer',
                  opacity: index === 0 ? 0.5 : 1
                }} />
              </Col>
              <Col span={24}>
                <FaArrowDown onClick={() => handelSwap(index, index + 1)} disabled={index === banners.length - 1} style={{
                  cursor: index === banners.length - 1 ? 'not-allowed' : 'pointer',
                  opacity: index === banners.length - 1 ? 0.5 : 1,
                }}
                />
              </Col>
            </Row>
          </div>
        )}
      />
      <Table.Column
        title="แสดงผล"
        dataIndex="page_name"
        align="center"
        render={(text, { show_online, show_mobile }) => {
          return (
            <table>
              <tbody>
                <tr>
                  <td style={{width: '45%'}}>หน้าจอ:</td>
                  <td>{text}</td>
                </tr>
                <tr>
                  <td>Website:</td>
                  <td>
                    <span className={show_online ? "text-pv" : "text-danger"}>
                      {show_online ? "แสดง" : "ไม่แสดง"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Mobile:</td>
                  <td>
                    <span className={show_mobile ? "text-pv" : "text-danger"}>
                      {show_mobile ? "แสดง" : "ไม่แสดง"}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        }}
      />
      <Table.Column
        title="Website"
        dataIndex="online"
        align="center"
        render={(src) =>
          src ? (
            <img
              src={src}
              alt="online"
              style={{ maxWidth: "150px", maxHeight: "100px" }}
            />
          ) : (
            <Empty />
          )
        }
      />
      <Table.Column
        title="Mobile"
        dataIndex="mobile"
        align="center"
        render={(src) =>
          src ? (
            <img
              src={src}
              alt="mobile"
              style={{ maxWidth: "150px", maxHeight: "100px" }}
            />
          ) : (
            <Empty />
          )
        }
      />
      <Table.Column
        title="วันที่"
        render={(text, { start_date, end_date, status }) => {
          return (
            <table>
              <tbody>
                <tr>
                  <td>สถานะ:</td>
                  <td>
                    <span
                      className={
                        status === "active" ? "text-pv" : "text-danger"
                      }
                    >
                      {status === "active" ? "Active" : "In Active"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>เริ่มต้น:</td>
                  <td>
                    {start_date
                      ? moment(start_date).format("DD/MM/YYYY HH:mm:ss")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>สิ้นสุด:</td>
                  <td>
                    {end_date
                      ? moment(end_date).format("DD/MM/YYYY HH:mm:ss")
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          );
        }}
      />
      <Table.Column
        title="แก้ไขล่าสุด"
        dataIndex="modify_date"
        align="center"
        render={(text) => text && moment(text).format("DD/MM/YYYY HH:mm")}
      />
      <Table.Column
        title={<FaCog />}
        dataIndex="id"
        align="center"
        render={(id) => (
          <>
            <Button
              size="small"
              type="primary"
              ghost
              className="mr-2"
              onClick={() => {
                history.push(`${match.url}/${id}`);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              size="small"
              type="danger"
              ghost
              onClick={() => handleDel(id)}
            >
              <FaTrash />
            </Button>
          </>
        )}
      />
    </Table>
  );
};

export default TableBanner;