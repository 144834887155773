import { Button, Col, Row, Switch, Typography } from "antd";
import numeral from "numeral";
import React from "react";
import { AiOutlineSelect } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";

const { Text } = Typography;

const columns = [
  "",
  "รหัสสินค้า",
  "ชื่อสินค้า",
  "จำนวนสินค้า",
  "จำนวนที่เคลมได้",
  "จำนวนที่เคลม",
  "",
];

const { setGlobalState, changeIsAllBom } = actionClaim;

const num = (e) => numeral(e).format("0,0");

const BomTable = ({ disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const is_all_bom = useSelector((state) => {
    if (!product_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    const { bom } = itemByKey[activeKey];
    return _.every(
      bom.itemKeys,
      (key) =>
        bom.itemByKey[key].qty_balance ===
        bom.itemByKey[key].claim_item.itemKeys.length
    );
  });
  const { itemKeys, itemByKey } = useSelector((state) => {
    if (!product_key) return {};
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom;
  });
  const product_name = useSelector(
    (state) => state.claim.billProduct.itemByKey[product_key]?.product_name
  );
  const is_no_bom = _.every(
    itemKeys,
    (key) => itemByKey[key].claim_item.itemKeys.length === 0
  );

  return (
    <>
      <div className="bg-gray-20 pb-2 px-2">
        <Row gutter={16} className="bg-gray-60 mb-2">
          <Col span={14} className="py-2 px-2">
            <Text className="text-white">{product_name}</Text>
          </Col>
          <Col span={10} className="py-2 px-2 text-right">
            <Text className="text-white mr-3">เลือกทั้งหมด</Text>
            <Switch
              checked={is_all_bom}
              disabled={disabled}
              onChange={(e) => dispatch(changeIsAllBom(e))}
            />
          </Col>
        </Row>
        <table className="w-100">
          <thead>
            <tr>
              {_.map(columns, (n, i) => (
                <td key={i} className="text-center">
                  {n}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(
              _.map(itemKeys, (key) => ({ ...itemByKey[key], key })),
              (n, i) => (
                <tr key={i}>
                  {/* <td>{n.line_number}</td> */}
                  <td></td>
                  <td className="px-2 text-center">{n.product_code}</td>
                  <td className="px-2">{n.product_name}</td>
                  <td className="px-2 text-center">{num(n.qty)}</td>
                  <td className="px-2 text-center">{num(n.qty_balance)}</td>
                  <td className="px-2 text-center">
                    {num(n.claim_item.itemKeys.length)}
                  </td>
                  <td className="px-2 text-center">
                    <Button
                      // disabled={is_all_bom}
                      type="primary"
                      size="small"
                      shape="circle"
                      onClick={() =>
                        dispatch(
                          setGlobalState({
                            name: "productBomModal",
                            value: { visible: true, product_bom_key: n.key },
                          })
                        )
                      }
                    >
                      <AiOutlineSelect />
                    </Button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {product_key && is_no_bom && (
        <Text className="text-nn py-1">
          *กรุณาเลือกสินค้าย่อยอย่างน้อย 1 รายการ
        </Text>
      )}
    </>
  );
};

export default BomTable;
