import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const legacyPopUpFormDefaultValues = {
  imageUrl: '',
  title: '',
  type: 'all',
  callBackUrlType: 'no_action',
  callBackUrl: '',
  startDate: moment(),
  endDate: moment(),
};

export const legacyPopUpType = [
  {
    label: 'แสดงทุกคน',
    value: 'all',
  },
  {
    label: 'แสดงเฉพาะบุคคล',
    value: 'group',
  },
  {
    label: 'แสดงเฉพาะนักธุรกิจ',
    value: 'Distributor',
  },
  {
    label: 'แสดงเฉพาะ VIP MEMBER',
    value: 'Member',
  },
];

export const legacyPopUpCallBackUrlType = [
  {
    label: 'ไม่มี Action',
    value: 'no_action',
  },
  {
    label: 'ลิงก์เว็บไซต์',
    value: 'link',
  },
  {
    label: 'เปิดหน้าสแกน QR Code',
    value: 'qr_scan',
  },
];

export const legacyPopUpFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      imageUrl: yup.string().required('กรุณากรอกข้อมูล'),
      title: yup.string().required('กรุณากรอกข้อมูล'),
      type: yup
        .mixed()
        .oneOf(Object.values(['all', 'group', 'Distributor', 'Member']))
        .required('กรุณากรอกข้อมูล'),
      callBackUrlType: yup
        .mixed()
        .oneOf(Object.values(['no_action', 'link', 'qr_scan']))
        .required('กรุณากรอกข้อมูล'),
      callBackUrl: yup.string().defined('กรุณากรอกข้อมูล'),
      startDate: yup.date().required('กรุณากรอกข้อมูล'),
      endDate: yup.date().required('กรุณากรอกข้อมูล'),
    })
  );
};
