/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import UploadFileList from "../../../../../../components/UploadFileList";
import { _ } from "../../../../../../utils";
import { PromotionContext } from "../reducers";

const initialFile = {
  itemKeys: [],
  itemByKey: {},
};

const UploadImgData = ({ type, dataKey }) => {
  const {
    state: {
      [type]: { itemByKey },
    },
    dispatch,
  } = useContext(PromotionContext);
  const [file, setFile] = useState(initialFile);
  const { media } = itemByKey[dataKey];
  const img_type_text = type === "condition" ? "เงื่อนไข" : "สิ่งที่ได้รับ";

  useEffect(() => {
    if (media) {
      const media_key = _.uniqueId(`${type}-${dataKey}-`);
      setFile({
        itemKeys: [media_key],
        itemByKey: { [media_key]: media },
      });
    } else {
      setFile(initialFile);
    }
  }, [media]);

  const handleChangeUpload = useCallback(
    (media) =>
      dispatch({
        type: "SET_PROMOTION_DATA_MEDIA",
        payload: {
          type,
          data_key: dataKey,
          media,
        },
      }),
    [type, dataKey]
  );
  const handleDeleteUpload = useCallback(
    () =>
      dispatch({
        type: "DEL_PROMOTION_DATA_MEDIA",
        payload: { type, data_key: dataKey },
      }),
    []
  );

  return (
    <Row gutter={16}>
      <Col span={4} md={3}>
        <Typography.Text>รูป{img_type_text}</Typography.Text>
      </Col>
      <Col span={20} md={21}>
        <UploadFileList
          limit={1}
          withCamera
          files={file}
          onChange={handleChangeUpload}
          onDelete={handleDeleteUpload}
        />
      </Col>
    </Row>
  );
};

export default UploadImgData;
