/* eslint-disable no-unused-vars */
import { Col, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../../../hooks/http2";
import { URL_API, _ } from "../../../../../utils";
const SelectBranch = () => {
  const [branchCode, setBranchCode] = useState([]);
  const getBranch = useApi(
    {
      url: `${URL_API}/pos/master/branch`,
      token: true,
    },
    []
  );

  let branch = _.orderBy(getBranch?.fetch?.data, ["code"], ["asc"]);

  return (
    <Row gutter={[8, 8]} type="flex" className="mb-2">
      <Col span={6} className="text-right">
        <Typography.Text strong className="text-nowrap">
          สาขารับของคืน
        </Typography.Text>
      </Col>
      <Col span={18}>
        <Select size="small" className="w-100" placeholder="สาขารับของคืน">
          {branch.length &&
            branch.map((n, i) => (
              <Select.Option key={n.code} value={n.code}>
                {n.name}
              </Select.Option>
            ))}
        </Select>
      </Col>
    </Row>
  );
};
export default SelectBranch;
