/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import { Radio } from "antd";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionEthics, activeSelector } from "../../../../redux/slices/ethics";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import DistributorTable from "./components/DistributorTable";
import VIPmemberTable from "./components/VIPmemberTable";

const Index = () => {
  const dispatch = useDispatch();
  const active = useSelector(activeSelector);
  const { SET_ACTIVE } = actionEthics;

  // const history = useHistory();
  // const [memberType, setMemberType] = useState("1");
  // const [query, setQuery] = useState({
  //   search: "",
  //   member_type: "1",
  //   status: "Active",
  //   display_type: "waiting",
  //   page: 1,
  //   limit: 10,
  //   // ...qs.parse(history.location.search), //clone to url
  //   // page: qs.parse(history.location.search)?.page
  //   //   ? parseInt(qs.parse(history.location.search).page)
  //   //   : 1,
  //   // limit: qs.parse(history.location.search)?.limit
  //   //   ? parseInt(qs.parse(history.location.search).limit)
  //   //   : 10,
  // });

  // useEffect(() => {
  //   if ("?" + qs.stringify(query) !== history.location.search) {
  //     history.push({ search: qs.stringify(query) });

  //   }
  // }, [query]);

  // useEffect(() => {
  //   if (memberType !== query.member_type) {
  //     setQuery({ ...query, search: "" });
  //   }
  //   console.log({ memberType });
  // }, [memberType]);

  return (
    <>
      <Panel>
        <PanelHeader>ตรวจเอกสาร</PanelHeader>
        <PanelBody>
          <Radio.Group
            className="p-5 mb-2"
            value={active}
            onChange={(e) => {
              dispatch(SET_ACTIVE(e.target.value));
              // setQuery({
              //   ...query,
              //   page: 1,
              //   member_type: e.target.value,
              // });
              // setMemberType(e.target.value);
            }}
          >
            <Radio value="1">นักธุรกิจ</Radio>
            <Radio value="2">VIP Member</Radio>
          </Radio.Group>
          {active === "1" ? <DistributorTable /> : <VIPmemberTable />}
        </PanelBody>
      </Panel>
    </>
  );
};
export default Index;
