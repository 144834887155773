/* eslint-disable no-unused-vars */
import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";
import { _, moment } from "../../../../utils";

export const initState = {
  billHeader: [
    {
      title: "เลขที่ใบเสร็จ",
      value: "document_no",
    },
    {
      title: "วันที่ใบเสร็จ",
      value: "document_date_text",
    },
    {
      title: "แหล่งที่มา",
      value: "sale_source_text",
    },
    {
      title: "ชื่อเจ้าหน้าที่",
      value: "cashier_name",
    },
    {
      title: "ชื่อผู้สั่งซื้อ",
      value: "partner_name",
    },
    {
      title: "รหัสผู้สั่งซื้อ",
      value: "partner_code",
    },
    {
      title: "ประเภทการสั่งซื้อ",
      value: "order_type",
    },
    {
      title: "คะแนน PV รวม",
      value: "pv",
    },
    {
      title: "สถานะการแจง PV",
      value: "clarify_text",
    },
  ],
  listTab: [
    {
      title: "คำขอยกเลิก",
      value: "request",
    },
    {
      title: "ดำเนินการ",
      value: "pending",
    },
    {
      title: "ผลการยกเลิก",
      value: "result",
    },
  ],
  itemColumn: [
    "",
    "รหัสสินค้า",
    "ชื่อสินค้า",
    "ราคา/หน่วย",
    "จำนวน",
    "PV",
    "ราคา",
  ],
  acceptText: {
    daily:
      "แจ้งให้นักธุรกิจทราบแล้วว่า หากมีการแจงคะแนนไปแล้ว คะแนนที่แจงไปจะเป็นโมฆะ และหากเปิดบิลใหม่แล้วท่านนักธุรกิจจะต้องทำการแจงคะแนนใหม่อีกครั้ง",
    cycle:
      "แจ้งให้นักธุรกิจรับทราบแล้วว่า กรณีที่ใบเสร็จนี้มีการยกเลิกบิลข้ามรอบคำนวณ นักธุรกิจจะได้รับเป็นบัตรกำนัลสำหรับนำไปซื้อสินค้า แต่ใบเสร็จใหม่ที่ใช้บัตรกำนัลนี้ จะไม่ได้รับคะแนน PV ทั้งบิล",
  },
  searchDocument: {
    start_date: null,
    end_date: null,
    branch_code: null,
    query: null,
    page: 1,
    limit: 10,
  },
  billDetail: {
    id: null,
    row_id: null,
    document_no: null,
    document_date: null,
    document_date_text: null,
    sale_source: null,
    sale_source_text: null,
    cashier_name: null,
    partner_name: null,
    partner_code: null,
    order_type: null,
    pv: null,
    interface_pv: null,
    interface_pv_text: null,
    status: null,
    status_text: null,
    cancel_document_no: null,
    is_preview: false,
    is_daily: false,
    is_consider: false,
  },
  billPreview: {
    id: null,
  },
  billItem: {
    itemKeys: [],
    itemByKey: {},
  },
  content: {
    cancel_type: null,
    reason: null,
    member_contact: null,
    contact_detail: null,
    is_accept: false,
    cancel_type_text: null,
    member_contact_text: null,
    request_date: null,
    request_by_name: null,
    forward_date: null,
    forward_by_name: null,
    consider_date: null,
    consider_by_name: null,
  },
  info: {
    loading_submit: false,
    consider_remark: null,
    send_remark: null,
  },
  user: {
    user_id: null,
    permission_status: {},
  },
};

export const reducer = createReducer(initState, {
  setGlobalState: (state, { payload: { name, value } }) => {
    state[name] = {
      ...state[name],
      ...value,
    };
  },
  clearGlobalState: (state, { payload }) => {
    state[payload] = _.cloneDeep(initState[payload]);
  },
  clearCreate: (state) => {
    state.billDetail = { ...initState.billDetail };
    state.content = { ...initState.content };
    state.billItem = _.cloneDeep(initState.billItem);
  },
  setBillSelect: (state, { payload }) => {
    const { item, content, sale_source, sale_source_text } = payload;
    let itemKeys = [];
    let itemByKey = {};
    _.forEach(item, (n) => {
      const key = _.uniqueId("item-");
      itemKeys = [...itemKeys, key];
      itemByKey = {
        ...itemByKey,
        [key]: {
          ...n,
        },
      };
    });
    if (content) {
      const { consider_remark, send_remark, ...content_data } = content;
      const { history_status } = payload;
      const request = _.find(
        history_status,
        (n) => n.status_type === "wait-branch"
      );
      const forward = _.find(
        history_status,
        (n) => n.status_type === "wait-operation"
      );
      const consider = _.find(history_status, (n) =>
        _.includes(["approve", "reject"], n.status_type)
      );
      state.content = {
        ...content_data,
        request_date: request?.create_date || null,
        request_by_name: request?.user_name || null,
        forward_date: forward?.create_date || null,
        forward_by_name: forward?.user_name || null,
        consider_date: consider?.create_date || null,
        consider_by_name: consider?.user_name || null,
      };
      state.info = {
        ...state.info,
        consider_remark,
        send_remark,
      };
    } else {
      state.content = _.cloneDeep(initState.content);
    }
    state.billDetail = {
      ...state.billDetail,
      sale_source,
      sale_source_text,
    };
    state.billItem.itemKeys = itemKeys;
    state.billItem.itemByKey = itemByKey;
  },
});

const BillContext = createContext();
export default BillContext;
