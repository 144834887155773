//component เขียนไว้สำหรับหน้า สร้างใบโอนย้ายสินค้า 
//มีการแกชื่อสาขา จัดส่ง เป็น คลังบางนา

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SelectOption from "./SelectOption";

import _, { findIndex } from "lodash";
import { useHttp } from "../../../../../hooks/http";
import { getUserObj, URL_API } from "../../../../../utils";
import { fixBranch } from "../../../../../config/fix-value";

export const GetBranchUser = () => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/branch/user`,
      params: {
        id: getUserObj().id,
      },
    },
    []
  );
  return data;
};

const SelectBranch = ({
  callBackData,
  userLogin,
  onlyPrefix,
  allowDisableBranch,
  disableOnlineBranch,
  withAll,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/branch${userLogin ? "/user" : ""}`,
      params: {
        id: getUserObj().id,
      },
    },
    [userLogin]
  );

  useEffect(() => {
    let newData = [];
    if (data && data.data.length > 0) {
      let dataFilter = _.cloneDeep(data.data);
      if (!allowDisableBranch) {
        dataFilter = _.filter(
          dataFilter,
          (n) => !_.includes(fixBranch.disabled, n.code)
        );
      }
      if (disableOnlineBranch) {
        dataFilter = _.filter(
          dataFilter,
          (n) => !_.includes(fixBranch.online, n.code)
        );
      }
      if (onlyPrefix) {
        dataFilter = _.filter(
          dataFilter,
          (n) => !_.includes(fixBranch.no_prefix, n.code)
        );
      }
      if (withAll) {
        newData = [{ value: "", text: "ทั้งหมด" }];
      }
      newData = [
        ...newData,
        ..._.map(dataFilter, (n) => ({ value: n.code, text: n.name })),
      ];
      const index = newData.findIndex(obj => obj.value ==='300')
      if(index > -1){
        newData[index].text = 'คลังบางนา'
      }
      
      callBackData && callBackData(dataFilter);
    }
    setOptions(newData);
  }, [data, disableOnlineBranch, onlyPrefix, withAll, allowDisableBranch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <div>{error}</div>;
  }

  return <SelectOption loading={loading} options={options} {...props} />;
};

export default SelectBranch;

//---------------------------------Function => return all value
export const ValueAllBranch = () => {
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/master/branch`,
      token: true,
    },
    []
  );
  return data?.data;
};
