import React from 'react';
import { Modal } from 'antd';
import RegisterPapersForm from '../form/RegisterPapersForm';

const RegisterPapersModal = ({ open, handleClose, handleSubmit }) => {
  return (
    <Modal
      title="อัปเดทใบสมัคร"
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={handleSubmit}
      width={'50vw'}
    >
      <RegisterPapersForm />
    </Modal>
  );
};

export default RegisterPapersModal;
