/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import React, { useEffect } from "react";
import { FaCogs, FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHttp } from "../../../../../../hooks/http";
import {
  actionPromotionList,
  searchSelector,
} from "../../../../../../redux/slices/promotion-list";
import { axios, getToken, moment, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import ImageItem from "../../components/ImageItem";

const limit = 10;
const PromotionList = ({ match, history }) => {
  const reduxDispatch = useDispatch();
  const { setSearch, clear } = actionPromotionList;
  const search = useSelector(searchSelector);
  const { query, page, status, type } = search;
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/system/promotion`,
      token: true,
      params: {
        ...search,
        limit,
        offset: (page - 1) * limit,
      },
    },
    [search]
  );

  useEffect(() => {
    return () =>
      !_.split(history.location.pathname, "/setting/master/promotion/")[1] &&
      reduxDispatch(clear());
  }, []);

  if (error) {
    notification.error({
      message: error,
      placement: "bottomRight",
    });
  }

  const handleChangeStatus = (value, id, name) => {
    const source = axios.CancelToken.source();
    const action_text = value ? "เปิด" : "ปิด";
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: `คุณต้องการ${action_text}ใช้งานโปรโมชัน ${name} หรือไม่`,
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            baseURL: URL_API,
            url: `/setting/master/change-status`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              id,
              status: value ? "active" : "inactive",
              type: "promotion",
            },
            cancelToken: source.token,
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: `${action_text}การใช้งานโปรโมชันเรียบร้อย`,
              });
              reload();
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {
        source.cancel();
      },
    });
  };

  return (
    <Panel>
      <PanelHeader>โปรโมชัน</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col md={17}>
            <Input.Search
              allowClear
              placeholder="รหัสโปรโมชัน, ชื่อโปรโมชัน"
              value={query}
              onChange={(e) =>
                reduxDispatch(setSearch({ query: e.target.value, page: 1 }))
              }
            />
          </Col>
          <Col md={3}>
            <Select
              className="w-100"
              value={status}
              onChange={(value) =>
                reduxDispatch(setSearch({ status: value, page: 1 }))
              }
            >
              <Select.Option value="active">ใช้งาน</Select.Option>
              <Select.Option value="inactive">ไม่ใช้งาน</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            {/* <Select
              className="w-100"
              value={type}
              onChange={(value) =>
                reduxDispatch(setSearch({ type: value, page: 1 }))
              }
            >
              <Select.Option value="giveaway">โปรโมชันของแถม</Select.Option>
              <Select.Option value="amount">โปรโมชันราคาขาย</Select.Option>
              <Select.Option value="bill">โปรโมชันยอดบิล</Select.Option>
            </Select> */}
          </Col>
        </Row>
        <Row gutter={16} className="mb-2">
          <Col>
            <Link to={`${match.path}/${type}/0`}>
              <Button type="primary">เพิ่มโปรโมชัน</Button>
            </Link>
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={data ? data.data : []}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            total: data?.total,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showLessItems: true,
            onChange: (currPage) =>
              reduxDispatch(setSearch({ page: currPage })),
          }}
        >
          <Table.Column
            key="row_id"
            dataIndex="row_id"
            title="#"
            align="center"
          />
          <Table.Column
            key="azure_url"
            dataIndex="azure_url"
            title="รูป"
            align="center"
            width={150}
            render={(text) => <ImageItem azureUrl={text} />}
          />
          <Table.Column title="รหัส" dataIndex="code" align="center" />
          <Table.Column
            key="name"
            dataIndex="name"
            className="position-relative min-width-150 max-width-600"
            title={
              <div className="ant-table-absolute-title-center">โปรโมชัน</div>
            }
          />
          <Table.Column
            key="type"
            dataIndex="type"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">ประเภท</div>
            }
            render={(text) =>
              text?.toLowerCase() === "giveaway"
                ? "โปรโมชันของแถม"
                : text?.toLowerCase() === "amount"
                ? "โปรโมชันราคาขาย"
                : text?.toLowerCase() === "bill"
                ? "โปรโมชันยอดบิล"
                : "-"
            }
          />
          <Table.Column
            key="start_date"
            dataIndex="start_date"
            title="วันที่เริ่มต้น"
            align="center"
            render={(text) =>
              text ? moment(text).format("DD/MM/YYYY HH:mm:ss") : text
            }
          />
          <Table.Column
            key="end_date"
            dataIndex="end_date"
            title="วันที่สิ้นสุด"
            align="center"
            render={(text) =>
              text ? moment(text).format("DD/MM/YYYY HH:mm:ss") : "ตลอดไป"
            }
          />
          <Table.Column
            key="status"
            dataIndex="status"
            title="สถานะ"
            align="center"
            render={(text, { id, name }) => (
              <Switch
                checked={text?.toLowerCase() === "active"}
                onChange={(e) => handleChangeStatus(e, id, name)}
              />
            )}
          />
          <Table.Column
            key="action"
            title={<FaCogs />}
            align="center"
            render={({ id, type }) => (
              <Link to={`${match.path}/${type}/${id}`}>
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-pv border-pv"
                >
                  <FaPencilAlt />
                </Button>
              </Link>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};
export default PromotionList;
