/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Statistic,
  Table,
  Typography,
} from "antd";
import numeral from "numeral";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { FaCogs, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import * as XLSX from "xlsx-js-style";
import {
  actionStoreQrLot,
  formSelector,
} from "../../../../redux/slices/store-qr/lot";
import {
  _,
  axios,
  getToken,
  getUser,
  moment,
  URL_API,
} from "../../../../utils/index";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import ModalProduct from "../components/ModalProduct";

const LotForm = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const form = useSelector(formSelector);
  const dispatch = useDispatch();

  const [view, setView] = useState(true);
  const [branch, setBranch] = useState("");
  const [dataBranch, setDataBranch] = useState("");
  const [qr, setQr] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const { SET_FORM, ADD_FORM_LIST, LOAD_FORM, DEL_FORM_LIST, CLEAR_FORM } =
    actionStoreQrLot;

  const exportToExcel = () => {
    if (form.list.length !== 0) {
      let excelData = [
        [
          "ข้อมูล Lot",
          "",
          `วันที่ ${moment(new Date()).format("DD/MM/YYYY HH:mm")}`,
        ],
        [
          `รหัสสินค้า ${form.product_code}`,
          `ชื่อสินค้า  ${form.product_name}`,
          `จำนวนรวม  ${form.list.itemKeys.length}`,
        ],
        [
          `Lot. ${form.lot}`,
          `วันที่ผลิต  ${
            form.production_date
              ? moment(form.production_date).format("DD/MM/YYYY")
              : "-"
          }`,
          `วันที่หมดอายุ  ${
            form.expiration_date
              ? moment(form.expiration_date).format("DD/MM/YYYY")
              : "-"
          }`,
        ],
        [],
        [
          { v: "No.", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "QR Code", t: "s", s: { alignment: { horizontal: "center" } } },
          { v: "Running", t: "s", s: { alignment: { horizontal: "center" } } },
        ],
      ];
      let exportData = _.filter(
        _.map(form.list.itemKeys, (n) => ({
          ...form.list.itemByKey[n],
          key: n,
        })),
        (n) => n.id !== 0
      );

      let i = 1;
      exportData.forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: i++, t: "s", s: { alignment: { horizontal: "center" } } },
            n.qr_code,
            {
              v: n.running,
              t: "s",
              s: { alignment: { horizontal: "center" } },
            },
          ],
        ];
      });

      // const merge = [
      //   { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
      // ];

      var wscols = [
        { wch: 15 },
        { wch: 45 },
        { wch: 25, t: "s", s: { alignment: { horizontal: "center" } } },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      //ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ข้อมูล Lot.");
      XLSX.writeFile(wb, `ข้อมูลLot_${form.lot}.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  const handleClickSubmit = (type) => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      if (
        _.filter(
          form.list.itemKeys,
          (n) =>
            form.list.itemByKey[n] !== null &&
            form.list.itemByKey[n] !== 0 &&
            form.list.itemByKey.id !== 0
        ).length === 0
      ) {
        return Modal.warning({
          title: "กรุณาตรวจสอบรายการ QR Code",
          content: "กรุณากรอกข้อมูลอย่างน้อย 1 รายการ",
          okText: "ปิด",
        });
      }

      axios({
        method: "get",
        baseURL: URL_API,
        url: `store-qr/lot${
          match.params.id === "0" ? "" : `/${match.params.id}`
        }`,
        headers: { Authorization: "Bearer " + getToken() },
      }).then((res) => {
        // let user = getUser();
        // if (user.branch_code != res.data.data.branch_code) {
        //   return Modal.warning({
        //     title: "ไม่สามารถบันทึกเอกสารร่างได้",
        //     content: "กรุณาตรวจสอบสาขาที่ดำเนินการ",
        //     okText: "ปิด",
        //   });
        // } else {
        Modal.confirm({
          title: "ยืนยัน",
          content:
            type === "send"
              ? "คุณต้องการบันทึกข้อมูลหรือไม่"
              : "คุณต้องการบันทึกเอกสารร่างหรือไม่",
          okText: "บันทึก",
          cancelText: "ปิด",
          onOk() {
            return new Promise((resolve, reject) => {
              let user = getUser();

              let data = {
                ...form,
                ...values,
                expiration_date: moment(values.expiration_date).format(
                  "YYYY-MM-DD"
                ),
                production_date: moment(values.production_date).format(
                  "YYYY-MM-DD"
                ),
                qty: form.list.itemKeys.length,
                list: _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]),
                status: type,
                branch_code: user?.branch_code || "",
                branch_name: user?.branch_name || "",
              };

              axios({
                method: "post",
                baseURL: URL_API,
                url: `store-qr/lot${
                  match.params.id === "0" ? "" : `/${match.params.id}`
                }`,
                headers: { Authorization: "Bearer " + getToken() },
                data,
              })
                .then((res) => {
                  resolve("success");
                })
                .catch((err) => {
                  reject(
                    err && err.response
                      ? err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                      : err.message
                  );
                });
            })
              .then(() => {
                Modal.success({
                  title: "สำเร็จ",
                  content: "บันทึกเรียบร้อย",
                  okText: "ปิด",
                  onOk() {
                    history.push(`/store-qr/lot`);
                  },
                });
              })
              .catch((reason) => {
                Modal.error({
                  title: "ผิดพลาด",
                  content: reason,
                  okText: "ปิด",
                });
              });
          },
        });
        // }
      });
    });
  };

  const handleEnterQR = async (e) => {
    if (e.keyCode === 13) {
      let params = qs.parseUrl(qr);
      if (
        !qr?.toLowerCase().includes("https://www.legacy.co.th/th/home/?lgcsku")
      ) {
        notification.warning({
          message: "กรุณาตรวจสอบ QR CODE",
          description: "ฟอร์แมท QR Code นี้ไม่ถูกต้อง",
        });
        return;
      }
      await axios({
        method: "get",
        baseURL: URL_API,
        url: `store-qr/lot/check-qr-code`,
        headers: { Authorization: "Bearer " + getToken() },
        params: { qr_code: qr, product_code : form.product_code },
      }).then((res) => {
        if (res.data.data.length > 0) {
          notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "QR Code นี้เคยถูกสแกน หรือ ถูกนำไปจัดเซ็ตแล้ว หรือ ถูกขายไปแล้ว",
          });
        } else {
          if (
            params.query.LgcSKU === form.product_code ||
            params.query.LgcSKU === form.bar_code
          ) {
            let find_dup = _.find(
              _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]),
              (n) => n.qr_code === qr && (params.query?.LgcSN || "") !== ""
            );
            
            if (find_dup && process.env.REACT_APP_WHEY_PROTEIN_SKU != form.product_code) {
              notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: "QR Code นี้เคยถูกสแกนแล้ว",
              });
            } else {
              dispatch(
                ADD_FORM_LIST({
                  qr_code: qr,
                  running: params.query?.LgcSN || "",
                  useable: true,
                })
              );
            }
          } else {
            notification.warning({
              message: "กรุณาตรวจสอบรายการ",
              description: "QR Code นี้ไม่ตรงกับรหัสสินค้าที่เลือก",
            });
          }
        }
      });

      setQr("");
    }
  };

  const loadData = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: `store-qr/lot/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        dispatch(LOAD_FORM(res.data.data));
        if (props.form && !view) {
          props.form.setFieldsValue({
            ...res.data?.data,
            production_date: res.data?.data?.production_date
              ? moment(res.data?.data?.production_date)
              : null,
            expiration_date: res.data?.data?.expiration_date
              ? moment(res.data?.data?.expiration_date)
              : null,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleDelitem = async (keyItem) => {
  //   let qrCode = form.list.itemByKey[keyItem].qr_code;
  //   let lotId = form.lot;
  //   // await axios({
  //   //   method: "get",
  //   //   baseURL: URL_API,
  //   //   url: `store-qr/lot/qr-code`,
  //   //   headers: { Authorization: "Bearer " + getToken() },
  //   //   params: { qr_code: qrCode }
  //   await axios({
  //     method: "get",
  //     baseURL: URL_API,
  //     url: `store-qr/set/set-detail`,
  //     headers: { Authorization: "Bearer " + getToken() },
  //     params: { qr_code: qrCode, lot: lotId },
  //   }).then((res) => {

  //     if (res.data.data) {
  //       notification.warning({
  //         message: "ไม่สามารถลบสินค้านี้ได้",
  //         description: "QR Code นี้ได้ถูกนำไปจัดในเซ็ตสินค้าแล้ว",
  //       });
  //       return;
  //     } else {
  //       dispatch(DEL_FORM_LIST(keyItem));
  //     }
  //   });
  // };
  const handelHiddenDelItem = (item) => {
    let isHidden = false;
  if(form.product_code !== process.env.REACT_APP_WHEY_PROTEIN_SKU){
    if(item.on_tracking_qr === 1){
      return true
    }
    if(item.in_set === 1){
      return true
    }
    if (form?.status === "active" && item.in_set === 1) {
      isHidden = true;
    } else if (
      form?.status === "active" &&
      item.in_set === 0 &&
      item.useable === false
    ) {
      isHidden = true;
    } else if (
      form?.status === "active" &&
      item.in_set === 0 &&
      item.useable === true
    ) {
      isHidden = false;
    }
  }
    
    return isHidden;
  };

  useEffect(() => {
    if (match.params.id !== "0") {
      loadData(match.params.id);
    } else {
      dispatch(CLEAR_FORM());

      // if (props.form && !view) {
      //   props.form.resetFields();
      // }
    }
  }, [match.params.id]);

  useEffect(() => {
    setView(match.params.type === "view");
    let user = getUser();
    setBranch(user.branch_code);
  }, [match.params.type]);

  const { getFieldDecorator } = props.form;

  return (
    <>
      <Panel>
        <PanelHeader>เก็บข้อมูล Lot.</PanelHeader>
        <PanelBody>
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col md={16}>
                <Row gutter={8}>
                  <Col md={3} className="ant-col ant-form-item-label">
                    <Typography.Text>รหัสสินค้า:</Typography.Text>
                  </Col>
                  <Col md={5}>
                    {view ||
                    form.status === "active" ||
                    form.status === "draft" ? (
                      <Form.Item className="mb-0">
                        <Typography.Text>{form?.product_code}</Typography.Text>
                      </Form.Item>
                    ) : (
                      <Form.Item className="mb-0">
                        {getFieldDecorator("product_code", {
                          initialValue: form?.product_code,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุรหัสสินค้า",
                            },
                          ],
                        })(
                          <Input
                            disabled
                            suffix={
                              <Button
                                type="link"
                                className="px-0"
                                onClick={() => setModalShow(true)}
                              >
                                <FaPlus />
                              </Button>
                            }
                          />
                        )}
                      </Form.Item>
                    )}
                  </Col>
                  <Col md={3} className="ant-col ant-form-item-label">
                    <Typography.Text>ชื่อสินค้า:</Typography.Text>
                  </Col>
                  <Col md={13}>
                    {view ||
                    form?.status === "active" ||
                    form.status === "draft" ? (
                      <Form.Item className="mb-0">
                        <Typography.Text>{form?.product_name}</Typography.Text>
                      </Form.Item>
                    ) : (
                      <Form.Item className="mb-0">
                        {getFieldDecorator("product_name", {
                          initialValue: form?.product_name,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุชื่อสินค้า",
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col md={3} className="ant-col ant-form-item-label">
                    <Typography.Text>Lot.:</Typography.Text>
                  </Col>
                  <Col md={5}>
                    {view ||
                    (form.branch_code != branch && match.params.id != "0") ? (
                      <Form.Item className="mb-0">
                        <Typography.Text>{form?.lot}</Typography.Text>
                      </Form.Item>
                    ) : (
                      <Form.Item className="mb-0">
                        {getFieldDecorator("lot", {
                          initialValue: form?.lot,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุ Lot.",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    )}
                  </Col>
                  <Col md={3} className="ant-col ant-form-item-label">
                    <Typography.Text>วันที่ผลิต:</Typography.Text>
                  </Col>
                  <Col md={5}>
                    {view ||
                    (form.branch_code != branch && match.params.id != "0") ? (
                      <Form.Item className="mb-0">
                        <Typography.Text>
                          {form?.production_date
                            ? moment(form?.production_date).format("DD/MM/YYYY")
                            : "-"}
                        </Typography.Text>
                      </Form.Item>
                    ) : (
                      <Form.Item className="mb-0">
                        {getFieldDecorator("production_date", {
                          initialValue: form?.production_date,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุวันที่ผลิต",
                            },
                          ],
                        })(
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            placeholder="วันที่ผลิต"
                            disabledDate={(d) => !d || d.isAfter(new Date())}
                          />
                        )}
                      </Form.Item>
                    )}
                  </Col>
                  <Col md={3} className="ant-col ant-form-item-label">
                    <Typography.Text>วันที่หมดอายุ:</Typography.Text>
                  </Col>
                  <Col md={5}>
                    {view ||
                    (form.branch_code != branch && match.params.id != "0") ? (
                      <Form.Item className="mb-0">
                        <Typography.Text>
                          {form.expiration_date
                            ? moment(form.expiration_date).format("DD/MM/YYYY")
                            : "-"}
                        </Typography.Text>
                      </Form.Item>
                    ) : (
                      <Form.Item className="mb-0">
                        {getFieldDecorator("expiration_date", {
                          initialValue: form?.expiration_date,
                          rules: [
                            {
                              required: true,
                              message: "กรุณาระบุวันที่หมดอายุ",
                            },
                          ],
                        })(
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            placeholder="วันที่หมดอายุ"
                            disabledDate={(d) => !d || d.isBefore(new Date())}
                          />
                        )}
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={3} className="text-center">
                <Statistic
                  title="จำนวนรวม"
                  formatter={(value) => numeral(value).format()}
                  value={
                    _.filter(
                      _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]),
                      (n) => n.qr_code !== ""
                    ).length
                  }
                />
              </Col>
              <Col md={5} className="text-right">
                {view ? (
                  <>
                    {form.branch_code === branch && (
                      <Button
                        type="dashed"
                        className="mr-2"
                        onClick={() =>
                          history.push(`/store-qr/lot/${match.params.id}`)
                        }
                      >
                        แก้ไข
                      </Button>
                    )}

                    <Button
                      type="primary"
                      icon="file-excel"
                      onClick={() => exportToExcel()}
                    >
                      Export Excel
                    </Button>
                  </>
                ) : (
                  <>
                    {(form.status === "draft" || match.params.id === "0") && (
                      <Button
                        type="dashed"
                        className="mr-2"
                        onClick={() => handleClickSubmit("draft")}
                      >
                        เอกสารร่าง
                      </Button>
                    )}
                    {(form.branch_code === branch ||
                      match.params.id === "0") && (
                      <Button
                        type="primary"
                        onClick={() => handleClickSubmit("send")}
                      >
                        บันทึก
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Divider />

            <Row gutter={16} className="d-flex align-items-center mb-2">
              {!view && (
                <Col md={12}>
                  {(form.branch_code === branch || match.params.id === "0") && (
                    <Input
                      className="w-100"
                      placeholder="ป้อน QR Code"
                      value={qr.replace(/\s+/g, "")}
                      onChange={(e) =>
                        setQr(e.target.value.replace(/\s+/g, ""))
                      }
                      onKeyUp={handleEnterQR}
                    />
                  )}
                </Col>
              )}
            </Row>
            <Table
              size="small"
              bordered
              dataSource={_.filter(
                _.map(form.list.itemKeys, (n) => ({
                  ...form.list.itemByKey[n],
                  key: n,
                })),
                (n) => n.id !== 0
              )}
              pagination={false}
            >
              <Table.Column
                title="No."
                align="center"
                key="no"
                render={(text, record, index) => index + 1}
              />
              <Table.Column title="QR Code" dataIndex="qr_code" />
              <Table.Column
                title="Running"
                dataIndex="running"
                align="center"
                render={(text) => (text === "" ? "No Running" : text)}
              />
              {((!view && branch === form.branch_code) ||
                match.params.id === "0") && (
                <Table.Column
                  title={<FaCogs />}
                  align="center"
                  key="action"
                  render={(text, record) => (
                    <Button
                      size="small"
                      type="danger"
                      hidden={handelHiddenDelItem(record)}
                      shape="circle-outline"
                      onClick={() => dispatch(DEL_FORM_LIST(record.key))}
                      //onClick={() => handleDelitem(record.key)}
                    >
                      <FaTrashAlt />
                    </Button>
                  )}
                />
              )}
            </Table>
          </Form>
        </PanelBody>
      </Panel>

      <ModalProduct
        visible={modalShow}
        for_bom="y"
        onCancel={() => setModalShow(false)}
        onSelect={(e) => {
          dispatch(
            SET_FORM({
              product_code: e.product_code,
              product_name: e.product_name_en,
              bar_code: e.bar_code,
            })
          );

          props.form.setFieldsValue({
            product_code: e.product_code,
            product_name: e.product_name_en,
          });

          setModalShow(false);
        }}
      />
    </>
  );
};

export default Form.create("store-qr-lot")(LotForm);
