/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import {
  DownloadOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Button, Col, Empty, Modal, Row } from "antd";
import React, { useState } from "react";
import noImg from "../../../../../assets/img/pos/no-img.jpg";
//import { FaTrash } from 'react-icons/fa'
import { axios, getToken, URL_API } from "../../../../../utils";
import Comment from "./Comment";
import Remark from "./Remark";

const ShowImge = ({
  src,
  filter,
  id_image,
  reloadImge,
  setActiveTab,
  activeTab,
  setImg,
  member_id,
}) => {
  const [scale, setScale] = useState(1);
  const [showRemark, setShowRemark] = useState(false);
  const [remark, setRemark] = useState("");

  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState("");

  const sendApprove = async () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        method: "post",
        url: `${URL_API}/pos/ethics/approve`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          //id: filter[0]?.id,
          id: filter[0]?.id ? filter[0]?.id : id_image,
          comment: comment,
          member_id,
        },
      })
        .then((res) => {
          resolve();
          Modal.success({
            title: "สำเร็จ",
            content: "ทำรายการเรียบร้อย",
          });
          reloadImge();
          setActiveTab(activeTab);
          setShowComment(false);
          setImg([]);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    }).catch((e) => {
      Modal.error({
        title: "ผิดพลาด",
        content: e.message,
        okText: "ตกลง",
      });
    });
  };
  const sendReject = async () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        method: "post",
        url: `${URL_API}/pos/ethics/reject`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          id: filter[0]?.id ? filter[0]?.id : id_image,
          remark: remark,
          member_id,
        },
      })
        .then((res) => {
          resolve();
          Modal.success({
            title: "สำเร็จ",
            content: "ทำรายการเรียบร้อย",
          });
          reloadImge();
          setActiveTab(activeTab);
          setShowRemark(false);
          setImg([]);
        })
        .catch((err) => {
          reject(err);
        });
    }).catch((e) => {
      Modal.error({
        title: "ผิดพลาด",
        content: e.message,
        okText: "ตกลง",
      });
    });
  };
  // console.log("showC",showComment);
  // console.log("showR",showRemark);
  return (
    <>
      {src ? (
        <>
          <Row>
            <Col
              style={{
                borderRadius: "10px",
                width: "90%",
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <img src={src} className="w-100" />
            </Col>
            <Col>
              <Row className="d-flex flex-wrap justify-content-center">
                <Col>
                  <Button
                    size="small"
                    className="m-1 d-flex align-items-center"
                    shape="round"
                    type="primary"
                    ghost
                    onClick={() => setShowComment(true)}
                  >
                    Approve
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="small"
                    className="m-1 d-flex align-items-center"
                    shape="round"
                    type="danger"
                    ghost
                    onClick={() => setShowRemark(true)}
                  >
                    Reject
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : filter?.length > 0 && filter !== undefined && filter !== null ? (
        <>
          <Row>
            <Col>
              {/* <div className="d-flex justify-content-center w-100" style={{ position: 'absolute', marginTop: '40px', zIndex: '1' }}> */}
              <div className="d-flex justify-content-center w-100">
                <span className="m-5">
                  <a onClick={() => setScale(scale * 2)}>
                    <ZoomInOutlined />
                  </a>
                </span>
                <span className="m-5">
                  <a onClick={() => setScale(scale / 2)}>
                    <ZoomOutOutlined />
                  </a>
                </span>
                <span className="m-5">
                  <a onClick={() => setScale(1)}>
                    <UndoOutlined />
                  </a>
                </span>
                <span className="m-5">
                  <a
                    className="text-black-50"
                    href={`${URL_API}/file/${
                      filter && filter[0].id && filter[0]?.id
                    }`}
                  >
                    <DownloadOutlined />
                  </a>
                </span>
              </div>
            </Col>
            <Col
              className="overflow-hidden pb-4"
              style={{
                borderRadius: "10px",
                width: "90%",
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <img
                // src={`${URL_API}/system/files/${filter && filter[0].id && filter[0]?.id}`}
                src={filter[0]?.azure_url || noImg}
                className="w-100"
                style={{
                  top: "0",
                  left: "0",
                  transition: "0.4s",
                  transform: `scale(${scale})`,
                }}
              />
            </Col>
            <Col>
              <Row
                className="d-flex flex-wrap justify-content-center"
                gutter={4}
              >
                {(filter[0].document_status === "" ||
                  filter[0].document_status === null) && (
                  <Col>
                    <Button
                      size="small"
                      className="m-1 d-flex align-items-center"
                      shape="round"
                      type="primary"
                      ghost
                      onClick={() => setShowComment(true)}
                    >
                      Approve
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button
                    size="small"
                    className="m-1 d-flex align-items-center"
                    shape="round"
                    type="danger"
                    ghost
                    onClick={() => setShowRemark(true)}
                  >
                    Reject
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <Empty />
      )}

      <Remark
        show={showRemark}
        onCancel={() => setShowRemark(false)}
        handleChange={(e) => setRemark(e.target.value)}
        onOk={() => sendReject()}
      />
      <Comment
        show={showComment}
        onCancel={() => setShowComment(false)}
        handleChange={(e) => setComment(e.target.value)}
        onOk={() => sendApprove()}
      />
    </>
  );
};
export default ShowImge;
