import { Button, Modal, notification, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import { axios, getToken, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";

const { Text } = Typography;

const { setState } = actionRequisition;

const UserConsider = ({ handleBack }) => {
  const dispatch = useDispatch();
  const document_no = useSelector(
    (state) => state.requisition.documentDetail.document_no
  );
  const document_status = useSelector(
    (state) => state.requisition.documentDetail.status
  );
  const consider_remark = useSelector(
    (state) => state.requisition.consider.remark ?? ""
  );

  const handleSubmit = (consider_status) => {
    let data_text, text_style;
    switch (consider_status) {
      case "return":
        data_text = "ส่งกลับแก้ไข";
        text_style = "text-rp";
        break;
      case "reject":
        data_text = "ไม่อนุมัติ";
        text_style = "text-nn";
        break;
      case "approve":
        data_text = "อนุมัติ";
        text_style = "text-pv";

        break;
      default:
        return;
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className={`mx-1 ${text_style}`}>
            {data_text}
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(setState({ name: "loadingSubmit", value: true }));
        const data = {
          document_no,
          document_status,
          consider_status,
          consider_remark,
        };
        axios({
          method: "post",
          url: `${URL_API}/requisition/consider`,
          headers: { Authorization: "Bearer " + getToken() },
          data,
        })
          .then(() => {
            handleBack();
            notification.success({ message: "บันทึกข้อมูลแล้ว" });
          })
          .catch((e) => resCatchModal(e))
          .finally(() =>
            dispatch(setState({ name: "loadingSubmit", value: false }))
          );
      },
    });
  };

  return (
    <>
      <Button
        className="width-100 bg-rp border-rp text-white"
        onClick={() => handleSubmit("return")}
      >
        ส่งกลับแก้ไข
      </Button>
      <Button
        className="width-100 bg-nn border-nn text-white mx-3"
        onClick={() => handleSubmit("reject")}
      >
        ไม่อนุมัติ
      </Button>
      <Button
        type="primary"
        className="width-100"
        onClick={() => handleSubmit("approve")}
      >
        อนุมัติ
      </Button>
    </>
  );
};

export default memo(UserConsider);
