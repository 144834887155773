/* eslint-disable react-hooks/exhaustive-deps */
import { message, Modal, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import BranchContext from "../../../../../../contexts/branch.context";
import { getCancelToken } from "../../../../../../hooks/http2";
import { axios, URL_API } from "../../../../../../utils";
import FormBranch from "./FormBranch";

const ModalBranch = () => {
    const { state, dispatch } = useContext(BranchContext);

    const [formRef, setFormRef] = useState(null);

    let source = getCancelToken();

    const handleCancel = () => {
        dispatch({ type: "SET_CANCEL" });
    };

    const handleOk = () => {
        formRef.validateFieldsAndScroll((err, values) => {
            if (!err) {
                Modal.confirm({
                    title: "ยืนยันการทำรายการ",
                    content: "คุณต้องการบันทึกข้อมูลหรือไม่",
                    cancelText: "ยกเลิก",
                    okText: "บันทึก",
                    onOk() {
                        return new Promise((resolve, reject) => {
                            source = getCancelToken();

                            axios({
                                method: "post",
                                baseURL: URL_API,
                                url:
                                    "/setting/master/branch/" + state.form.code,
                                data: {
                                    ...state.form,
                                    ...values,
                                },
                                cancelToken: source.token,
                            })
                                .then((res) => {
                                    resolve(res.data);
                                })
                                .catch((err) => {
                                    reject(
                                        err && err.response
                                            ? err.response.data.message
                                                ? err.response.data.message
                                                : err.response.data
                                            : err.message
                                    );
                                });
                        })
                            .then((value) => {
                                Modal.success({
                                    title: "สำเร็จ",
                                    content: "บันทึกข้อมูลเรียบร้อย",
                                    okText: "ตกลง",
                                    onOk() {
                                        dispatch({
                                            type: "SET_RELOAD",
                                            payload: true,
                                        });
                                        dispatch({ type: "SET_CANCEL" });
                                    },
                                });
                            })
                            .catch((reason) => {
                                Modal.error({
                                    title: "ผิดพลาด",
                                    content: reason,
                                    okText: "ปิด",
                                });
                            });
                    },
                });
            }
        });
    };

    useEffect(() => {
        if (state.form.code !== "") {
            dispatch({ type: "SET_LOADING", payload: true });
            source = getCancelToken();

            axios({
                baseURL: URL_API,
                url: "/setting/master/branch/" + state.form.code,
            })
                .then((res) => {
                    dispatch({ type: "SET_LOADING", payload: false });
                    dispatch({
                        type: "SET_FORM_VALUE",
                        payload: res.data.data,
                    });
                })
                .catch((err) => {
                    dispatch({ type: "SET_LOADING", payload: false });
                    message.error(
                        err && err.response
                            ? err.response.data.message
                                ? err.response.data.message
                                : err.response.data
                            : err.message
                    );
                });
        }
        return () => {
            source.cancel("Operation canceled by the user.");
        };
    }, [state.form.code]);

    return (
        <Modal
            visible={state.modal.visible}
            title="แก้ไขข้อมูล"
            okText="บันทึก"
            cancelText="ยกเลิก"
            onCancel={handleCancel}
            onOk={handleOk}
            maskClosable={false}
            destroyOnClose
        >
            <Spin spinning={state.loading}>
                <FormBranch ref={setFormRef} />
            </Spin>
        </Modal>
    );
};

export default ModalBranch;
