import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import EventCalendarForm from '../form/EventCalendarForm';
import eventCalendarServices from '../../../../../../services/event-calendar.services';
import { eventCalendarFormDefaultValues } from '../dto/event-calendar.dto';
import { AlertSuccess } from '../../../../../util/Alert';

const EventCalendarModal = ({ open, handleClose }) => {
  const { watch, handleSubmit, reset } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createEventCalendar } =
    eventCalendarServices.useMutationCreateEventCalendar(() => {
      AlertSuccess('เพิ่มปฏิทินงานอีเวนท์สำเร็จ');
      onSuccess();
    });

  const { mutate: updateEventCalendar } =
    eventCalendarServices.useMutationUpdateEventCalendar(() => {
      AlertSuccess('แก้ไขปฏิทินงานอีเวนท์สำเร็จ');
      onSuccess();
    });

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['get-all-event-calendars'],
      refetchType: 'all',
    });
    reset(eventCalendarFormDefaultValues);
    handleClose();
  };

  const _HandleSubmit = handleSubmit((data) => {
    const eventCalendarData = {
      title: data.title,
      description: data.description,
      thumbnailImageUrl: data.thumbnailImageUrl,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      googleMapUrl: data.googleMapUrl,
      linkUrl: data.linkUrl,
      status: data.status,
    };
    if (watch('formType') === 'create') {
      createEventCalendar(eventCalendarData);
    } else if (watch('formType') === 'edit') {
      updateEventCalendar({
        eventCalendarId: data.id,
        data: eventCalendarData,
      });
    }
  });

  const _OnCancel = () => {
    reset(eventCalendarFormDefaultValues);
    handleClose();
  };

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'สร้างปฏิทินงานอีเวนท์'
          : 'แก้ไขปฏิทินงานอีเวนท์'
      }
      visible={open}
      onCancel={_OnCancel}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <EventCalendarForm />
    </Modal>
  );
};

export default EventCalendarModal;
