/* eslint-disable no-unused-vars */
import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { moment, URL_API } from '../../../../../utils';
import RegisterRecord from './RegisterRecord';
import styles from '../../../../../scss/module/legal.module.scss';
import img from '../../../../../assets/img/pos/user-default.png';
const title_color = {
    color: "#40a5a0"
}
const MainData = ({ data }) => {
    const [dataSource, setDataSource] = useState([]);
    const {
        id,
        passport_id,
        partner_code,
        member_code,
        member_type,
        register_date,
        expire_date,
        prefix_th,
        first_name_th,
        sur_name_th,
        prefix_en,
        first_name_en,
        sur_name_en,
        file_path,
        azure_url
    } = data;
    useEffect(() => {
        if (data.id !== null) {
            const {
                birth_date,
                age,
                id_card,
                passport_id,
                nationality,
                gender,
                phone,
                mobile,
                email,
                line_id,
                facebook,
            } = data;
            setDataSource([
                [
                    {
                        name: 'วันเกิด',
                        value: moment(birth_date).format('DD/MM/YYYY')
                    },
                    {
                        name: 'อายุ',
                        value: age
                    },
                    {
                        name: 'หมายเลขบัตรประชาชน',
                        value: id_card
                    },
                    {
                        name: 'หมายเลขพาสปอร์ด',
                        value: passport_id
                    },
                    {
                        name: 'สัญชาติ',
                        value: nationality
                    },
                    {
                        name: 'เพศ',
                        value: gender
                    },
                    {
                        name: 'โทรศัพท์',
                        value: phone
                    },
                    {
                        name: 'มือถือ',
                        value: mobile
                    },
                ],
                [
                    {
                        name: 'อีเมล',
                        value: email
                    },
                    {
                        name: 'Line ID',
                        value: line_id
                    },
                    {
                        name: 'Facebook',
                        value: facebook
                    },
                ],
            ])
        }
    }, [data]);
    return (
        <div className={styles['main-data']}>
            <Row className="pb-3 pl-3 pt-3 pr-3">
                <Col span={24}>
                    <Typography.Text strong>ข้อมูลนักธุรกิจ (ผู้สมัครหลัก)</Typography.Text>
                </Col>
            </Row>
            <Row className="pl-3 pt-3 pr-3">
                <Col span={12} lg={6} xl={3}>
                    <div className="width-100 height-100 overflow-hidden rounded-circle shadow">
                        {/* <img src={file_path ? `${URL_API}/pos/master/image/profile?ref_id=${member_id}&ref_type=${type}` : img} alt="profile" className="w-100 h-100 object-fit-cover" /> */}
                        <img src={azure_url ? azure_url : img} alt="profile" className="w-100 h-100 object-fit-cover" />
                    </div>
                </Col>
                <Col span={12} lg={6} xl={3} className="d-flex flex-column justify-content-around height-100">
                    <Row>
                        <div className="d-flex flex-column ">
                            <Typography.Text style={title_color}>รหัสนักธุรกิจ</Typography.Text>
                            <Typography.Text>{member_type?.toLowerCase() === 'member' ? member_code : partner_code}</Typography.Text>
                        </div>
                    </Row>
                    <Row>
                        <div className="d-flex flex-column">
                            <Typography.Text style={title_color}>คำนำหน้า</Typography.Text>
                            <Typography.Text>{prefix_th}</Typography.Text>
                        </div>
                    </Row>
                </Col>
                <Col span={12} lg={6} xl={3} className="d-flex flex-column justify-content-around height-100">
                    <Row>
                        <div className="d-flex flex-column ">
                            <Typography.Text style={title_color}>ประเภทสมาชิก</Typography.Text>
                            <Typography.Text>{member_type}</Typography.Text>
                        </div>
                    </Row>
                    <Row>
                        <div className="d-flex flex-column">
                            <Typography.Text style={title_color}>ชื่อ</Typography.Text>
                            <Typography.Text>{first_name_th}</Typography.Text>
                        </div>
                    </Row>
                </Col>
                <Col span={12} lg={6} xl={3} className="d-flex flex-column justify-content-around height-100">
                    <Row>
                        <div className="d-flex flex-column">
                            <Typography.Text style={title_color}>วันที่สมัคร</Typography.Text>
                            <Typography.Text>{moment(register_date).format('DD/MM/YYYY')}</Typography.Text>
                        </div>
                    </Row>
                    <Row>
                        <div className="d-flex flex-column">
                            <Typography.Text style={title_color}>นามสกุล</Typography.Text>
                            <Typography.Text>{sur_name_th}</Typography.Text>
                        </div>
                    </Row>
                </Col>
                <Col span={12} lg={6} xl={3} className="d-flex flex-column justify-content-around height-100">
                    <Row>
                        <div className="d-flex flex-column ">
                            <Typography.Text style={title_color}>วันที่หมดอายุ</Typography.Text>
                            <Typography.Text>{moment(expire_date).format('DD/MM/YYYY')}</Typography.Text>
                        </div>
                    </Row>
                    <Row>
                        <div className="d-flex flex-column">
                            <Typography.Text style={title_color}>Prefix Name</Typography.Text>
                            <Typography.Text>{prefix_en}</Typography.Text>
                        </div>
                    </Row>
                </Col>
                <Col span={12} lg={6} xl={3} className="d-flex flex-column justify-content-around height-100">
                    <Row className="h-25 mt-1" />
                    <Row>
                        <div className="d-flex flex-column pt-2 ">
                            <Typography.Text style={title_color}>First Name</Typography.Text>
                            <Typography.Text>{first_name_en}</Typography.Text>
                        </div>
                    </Row>
                </Col>
                <Col span={12} lg={6} xl={3} className="d-flex flex-column justify-content-around height-100">
                    <Row className="h-25 mt-1" />
                    <Row>
                        <div className="d-flex flex-column pt-2">
                            <Typography.Text style={title_color}>Last Name</Typography.Text>
                            <Typography.Text>{sur_name_en}</Typography.Text>
                        </div>
                    </Row>
                </Col>
            </Row>
            <RegisterRecord data={dataSource[0]} />
            <RegisterRecord data={dataSource[1]} />
        </div>
    )
}

export default MainData
