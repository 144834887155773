import { Col, Divider, Form, Row, Select } from "antd";
import React, { useContext } from "react";
import { _ } from "../../../../../../../utils";
import {
  isProAmount,
  isProItem,
} from "../../../../../pos/components/RecPromotion/logic/validate";
import { PromotionContext } from "../../reducers";
import PromotionData from "../PromotionData";
import SwitchCondition from "../SwitchCondition";

const NormalProcess = ({ form: { getFieldDecorator, getFieldsValue } }) => {
  const { dispatch } = useContext(PromotionContext);
  const { condition_type, receive_type } = getFieldsValue();
  return (
    <>
      {isProItem(condition_type) ? (
        <PromotionData
          type="condition"
          itemType="item"
          qtyTextSuffix={condition_type === "item" ? " (ชิ้น) " : " (บาท) "}
        />
      ) : (
        isProAmount(condition_type) && (
          <PromotionData type="condition" itemType="amount" />
        )
      )}
      <Divider className="py-3">สิ่งที่ได้รับ</Divider>
      <Row gutter={16} className="mb-3">
        <Col span={24}>
          <label className="mb-0">สิ่งที่ได้รับ</label>
          <Row gutter={[8, 8]} type="flex" className="align-items-center">
            <Col span={12} xl={16} xxl={18}>
              <Form.Item className="mb-0">
                {getFieldDecorator("receive_type", {
                  onChange: (value) =>
                    dispatch({
                      type: "CHANGE_CONDITION_RECEIVE_TYPE",
                      payload: { type: "receive", value },
                    }),
                  rules: [
                    {
                      required: true,
                      message: "กรุณาเลือกประเภทสิ่งที่ได้รับ",
                    },
                  ],
                })(
                  <Select className="width-200" placeholder="เลือกประเภท">
                    <Select.Option value="item">สินค้า</Select.Option>
                    <Select.Option value="amount">คะแนน/ส่วนลด</Select.Option>
                    <Select.Option value="voucher">บัตรกำนัล</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            {receive_type === "item" && (
              <Col span={12} xl={8} xxl={6}>
                <SwitchCondition
                  name="collect_point"
                  title="การได้รับคะแนนของแถม"
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {_.includes(["item", "amount", "voucher"], receive_type) && (
        <PromotionData type="receive" itemType={receive_type} />
      )}
    </>
  );
};

export default NormalProcess;
