import { Button, Col, Input, Row } from "antd";
import React, { useContext } from "react";
import RoleContext from "../../../../../../contexts/role.context";

const FilterList = () => {
  const { dispatch } = useContext(RoleContext);
  return (
    <Row gutter={16}>
      <Col md={21}>
        <Input.Search
          placeholder="ชื่อสิทธิ์การใช้งาน"
          onSearch={(e) =>
            dispatch({
              type: "SET_FILTER_VALUE",
              payload: { query: e, page: 1 },
            })
          }
          allowClear
        />
      </Col>
      <Col md={3}>
        <Button
          type="primary"
          block
          onClick={() =>
            dispatch({
              type: "SET_MODAL",
              payload: {
                visible: true,
                title: "เพิ่มสิทธิ์การใช้งานใหม่",
                data: null,
              },
            })
          }
        >
          เพิ่มสิทธิ์
        </Button>
      </Col>
    </Row>
  );
};

export default FilterList;
