import { Table } from "antd";
import _ from "lodash";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export default function TableDeliveryAddress() {
  const address = useSelector(
    (state) => state.ethics.form.address,
    shallowEqual
  );

  return (
    <Table
      size="small"
      dataSource={_.filter(address, (n) => n.address_type === 3)}
      pagination={false}
      scroll={{ x: "max-content" }}
      rowKey="id"
    >
      <Table.Column
        className="text-nowrap font-size-12"
        title="ชื่อผู้รับ"
        dataIndex="ship_to"
      />
      <Table.Column
        className="text-nowrap font-size-12"
        title="เบอร์โทร"
        dataIndex="ship_to_mobile"
        align="center"
      />
      <Table.Column
        className="text-nowrap font-size-12"
        title="ที่อยู่"
        dataIndex="address"
      />
      <Table.Column
        className="text-nowrap font-size-12"
        title="ตำบล/แขวง"
        dataIndex="sub_district"
        align="center"
      />
      <Table.Column
        className="text-nowrap font-size-12"
        title="อำเภอ/เขต"
        dataIndex="district"
        align="center"
      />
      <Table.Column
        className="text-nowrap font-size-12"
        title="จังหวัด"
        dataIndex="province"
        align="center"
      />
      <Table.Column
        className="text-nowrap font-size-12"
        title="รหัสไปรษณีย์"
        dataIndex="post_code"
        align="center"
      />
    </Table>
  );
}
