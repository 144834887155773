import React, { useEffect, useState } from 'react';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Tabs, Table } from 'antd';
import { FaEye, FaPen, FaEyeSlash, FaPlus } from 'react-icons/fa';
import { Button } from 'antdV4/lib/radio';
import BannerModal from '../components/modal/BannerModal';
import { FormProvider, useForm } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';
import { AlertSuccess, AlertConfirm, AlertError } from '../../../util/Alert';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import {
  bannerFormDefaultValues,
  bannerFormSchema,
} from '../components/dto/banner';

const BannerPage = () => {
  const [type, setType] = useState('home');
  const { execute: getBannerByType, data: bannerLists } = useAsync(
    fitStudioService.getBannerByType,
    {}
  );

  const { execute: deleteBanner } = useAsync(fitStudioService.deleteBanner, {
    onSuccess: (data) => {
      if (data === 'succeeded') {
        AlertSuccess('ลบแบนเนอร์สำเร็จ');

        getBannerByType({
          type,
        });
      } else {
        AlertError();
      }
    },
    onFailed: () => {
      AlertError();
    },
  });

  const { execute: setBannerEnable } = useAsync(
    fitStudioService.setBannerEnable,
    {
      onSuccess: (data) => {
        AlertSuccess('แก้ไขแบนเนอร์สำเร็จ');

        getBannerByType({
          type,
        });
      },
      onFailed: () => {
        AlertError();
      },
    }
  );

  const [bannerFormOpen, setBannerFormOpen] = useState(false);
  const bannerForm = useForm({
    defaultValues: bannerFormDefaultValues,
    resolver: bannerFormSchema(),
  });

  useEffect(() => {
    if (!bannerFormOpen) {
      getBannerByType({
        type,
      });
    }
  }, [type, bannerFormOpen, getBannerByType]);

  const _HandleBannerFormOpen = (formType) => {
    bannerForm.setValue('formType', formType);
    setBannerFormOpen(true);
  };

  const _HandleBannerFormClose = () => {
    setBannerFormOpen(false);
  };

  const _OnTabChange = (key) => {
    setType(key);
  };

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <img
          key={record.imageUrl}
          src={record.imageUrl}
          alt={record.imageUrl}
          style={{ width: 428 / 4, height: 642 / 4, objectFit: 'cover' }}
        />
      ),
      width: 428 / 4,
    },
    {
      title: 'ประเภท Link',
      key: 'callBackUrlType',
      dataIndex: 'callBackUrlType',
      width: 130,
      align: 'center',
    },
    {
      title: 'Link',
      key: 'callBackUrl',
      dataIndex: 'callBackUrl',
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {record.enable ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการมองเห็นแบนเนอร์');
                if (confirm)
                  setBannerEnable({ id: record.id, isEnable: false });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการมองเห็นแบนเนอร์');
                if (confirm) setBannerEnable({ id: record.id, isEnable: true });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              bannerForm.setValue('id', record.id);
              _HandleBannerFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบแบนเนอร์');
              if (confirm) deleteBanner(record.id);
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <Panel>
      <PanelHeader>แบนเนอร์</PanelHeader>
      <PanelBody>
        <Tabs
          defaultActiveKey="home"
          onChange={_OnTabChange}
          style={{ backgroundColor: '#fff' }}
          tabBarExtraContent={
            <Button
              className="bg-pv border-pv text-white"
              onClick={() => {
                _HandleBannerFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่ม Banner
            </Button>
          }
        >
          <Tabs.TabPane tab="Home Banner" key="home">
            <Table
              columns={columns}
              dataSource={
                bannerLists?.data && bannerLists.data.length > 0
                  ? bannerLists.data
                  : []
              }
              style={{ backgroundColor: '#fff' }}
              rowKey="id"
              pagination={{ pageSize: 5 }}
            />
          </Tabs.TabPane>
        </Tabs>
        <FormProvider {...bannerForm}>
          <BannerModal
            open={bannerFormOpen}
            handleClose={_HandleBannerFormClose}
          />
        </FormProvider>
      </PanelBody>
    </Panel>
  );
};

export default BannerPage;
