import { Empty } from 'antd';
import React from 'react';
import styles from '../../../../scss/module/legal.module.scss';

const DocumentPreview = ({ src }) => {
    return (
        <div className={styles.preview}>
            <div className={styles.img}>
                {
                    src ?
                        <img className="w-100 h-100 object-fit-cover" src={src} alt="document" />
                        : <Empty />
                }
            </div>
        </div>
    )
}

export default DocumentPreview
