import { Col, Input, Row, Typography } from "antd";
import React, { memo, useRef } from "react";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

const HeaderDetail = ({ disabled }) => {
  const searchRef = useRef(null);
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const product_bom_key = useSelector(
    (state) => state.claim.productBomModal.product_bom_key
  );
  const product_code = useSelector((state) => {
    if (!product_key || !product_bom_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom.itemByKey[product_bom_key].product_code;
  });
  const product_name = useSelector((state) => {
    if (!product_key || !product_bom_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom.itemByKey[product_bom_key].product_name;
  });
  const qty_balance = useSelector((state) => {
    if (!product_key || !product_bom_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom.itemByKey[product_bom_key].qty_balance;
  });
  const qty_claim = useSelector((state) => {
    if (!product_key || !product_bom_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item
      .itemKeys.length;
  });

  const handleSearch = (code) => {
    if (!code) return;
    searchRef.current.input.state.value = "";
    searchRef.current.focus();
  };

  return (
    <>
      <Row gutter={[8, 8]} type="flex">
        <Col span={24}>
          <Title level={4} className="text-center mb-3">
            รายละเอียดสินค้าย่อย
          </Title>
        </Col>
      </Row>
      <Row
        gutter={[8, 8]}
        type="flex"
        className="mb-3 align-items-center height-40"
      >
        <Col span={disabled ? 24 : 10} className="d-flex">
          <Text className="text-overflow w-100" strong>
            {product_code} - {product_name}
          </Text>
        </Col>
        {!disabled && (
          <>
            <Col span={3} className="text-right">
              <Text className="text-nowrap">Qr Tracking</Text>
            </Col>
            <Col span={11}>
              <Input.Search
                ref={searchRef}
                size="small"
                allowClear
                placeholder="Start typing or scan Qr code..."
                onSearch={handleSearch}
                disabled={qty_balance <= qty_claim}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default memo(HeaderDetail);
