import React, { forwardRef } from "react";
import SelectOption from "../../../../../../components/SelectOption";
import { useApi } from "../../../../../../hooks/http2";
import { URL_API, _ } from "../../../../../../utils";

const SelectSize = (props) => {
  const list = useApi(
    {
      url: `${URL_API}/system/master/product_size`,
      token: true,
    },
    []
  );

  return (
    <SelectOption
      loading={list.loading}
      data={_.map(list.fetch?.data || [], (n) => ({
        value: n.code,
        text: n.name,
      }))}
      withAll
      allText={
        (list.fetch?.data || []).length > 0 ? "เลือก Size" : "-- ไม่มีข้อมูล --"
      }
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectSize forwardRef={ref} {...props} />;
});
