import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const EventCalendarAnnounceStatus = ['display', 'hidden'];

export const eventCalendarAnnounceFormDefaultValues = {
  formType: 'create',
  id: '',
  status: 'display',
  announcedDate: moment(new Date()),
  title: '',
  description: '',
  thumbnailImageUrl: '',
};

export const eventCalendarAnnounceFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      status: yup.mixed().oneOf(EventCalendarAnnounceStatus).required(),
      announcedDate: yup.string().required(),
      title: yup.string().required(),
      description: yup.string().required(),
      thumbnailImageUrl: yup.string().required(),
    })
  );
};

export const eventCalendarAnnounceConstants = {
  tableSize: 20,
  thumbnailImage: {
    width: 600,
    height: 600,
  },
};
