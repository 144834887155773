import { Button, Col, Input, Modal, Row, Table, Tag } from "antd";
import React, { useState } from "react";
import { useApi } from "../../../../../../hooks/http2";
import { URL_API } from "../../../../../../utils";

const ModalCloneProduct = ({ visible, onCancel, onSelect, ...props }) => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const list = useApi(
    {
      baseURL: URL_API,
      url: "/setting/master/product-request/clone",
      token: true,
      params: {
        limit,
        offset: (page - 1) * limit,
        query: search,
      },
    },
    [page, search]
  );

  return (
    <Modal
      title="เลือกเอกสาร"
      visible={visible}
      width={800}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      {...props}
    >
      <Row gutter={16} className="mb-2">
        <Col md={24}>
          <Input.Search
            placeholder="ชื่อสินค้า"
            onSearch={(value) => {
              setSearch(value);
              if (page !== 1) {
                setPage(1);
              }
            }}
            allowClear
          />
        </Col>
      </Row>
      <Table
        loading={list.loading}
        size="small"
        bordered
        dataSource={list.fetch?.data || []}
        rowKey="id"
        pagination={{
          size: "default",
          total: list.fetch?.total || 0,
          showLessItems: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
          current: page,
          onChange: (p) => setPage(p),
        }}
      >
        <Table.Column
          title="รหัสสินค้า"
          dataIndex="product_code"
          align="center"
        />
        <Table.Column
          title="ชื่อสินค้า"
          dataIndex="product_name_local"
          width="50%"
        />
        <Table.Column
          title="รูปแบบการขาย"
          dataIndex="distribution_type_name"
          align="center"
        />
        <Table.Column
          title="ที่มา"
          dataIndex="source"
          align="center"
          render={(text) =>
            text === "product" ? (
              <Tag color="green">สินค้า</Tag>
            ) : (
              <Tag color="orange">ขอสินค้า</Tag>
            )
          }
        />
        <Table.Column
          align="center"
          render={(text, record) => {
            return (
              <Button
                size="small"
                type="primary"
                ghost
                onClick={() => {
                  onSelect(record?.id, record.source);
                }}
              >
                เลือก
              </Button>
            );
          }}
        />
      </Table>
    </Modal>
  );
};

export default ModalCloneProduct;
