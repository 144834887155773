/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import {
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Radio,
  notification,
  Upload,
  Modal,
} from "antd";
import UploadFile from "../../../../../components/UploadFile";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { AiOutlineUpload } from "react-icons/ai";
import {
  base64toFile,
  beforeUploadImg,
  fileToBase64,
  resizeFile,
} from "../../../../util/helper";
import reactImageSize from "react-image-size";
import { DivStyles, ImgStyles } from "../../components/mwStyles";
import useUploadFile from "../../components/useUploadFile";

function VTR({ history, form, ...props }) {
  const [selectUploadVideo, setSelectUploadVideo] = useState("normal_video");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileList, setSelectedFileList] = useState([]);

  const [fileListDesktop, setFileListDesktop] = useState([]);
  const [fileListMobile, setFileListMobile] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [loadValue, setLoadValue] = useState("");

  const [fileDelete, setFileDelete] = useState([]);

  let { onUpload, onRemoveUpload } = useUploadFile();

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = (info) => {
    console.log("this info :", info);
    switch (info.file.status) {
      case "uploading":
        setSelectedFileList([info.file]);
        break;
      case "done":
        setSelectedFile(info.file);
        setSelectedFileList([info.file]);
        break;
      default:
        // error or removed
        setSelectedFile(null);
        setSelectedFileList([]);
    }
  };

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      // for (let i in fileListDesktop) {
      //   if (values.desktop_img?.fileList) {
      //     console.log("i:", i);

      //     formData.append(
      //       "desktop_img",
      //       values.desktop_img?.fileList[i].originFileObj
      //     );
      //   }
      // }
      // for (let i in fileListMobile) {
      //   if (values.mobile_img?.fileList) {
      //     console.log("i:", i);

      //     formData.append(
      //       "mobile_img",
      //       values.mobile_img?.fileList[i].originFileObj
      //     );
      //   }
      // }

      if (fileListDesktop[0]?.file) {
        formData.append("desktop_img", fileListDesktop[0]?.file?.originFileObj);
      }

      if (fileListMobile[0]?.file) {
        formData.append("mobile_img", fileListMobile[0]?.file?.originFileObj);
      }

      // if (values.desktop_img?.file?.originFileObj)
      //   formData.append("desktop_img", values.desktop_img?.file?.originFileObj);
      // if (values.mobile_img?.file?.originFileObj)
      //   formData.append("mobile_img", values.mobile_img?.file?.originFileObj);

      if (selectedFileList[0]?.file) {
        formData.append(
          "normal_video",
          selectedFileList[0]?.file?.originFileObj
        );
      }

      // if (values.normal_video?.file?.originFileObj)
      //   formData.append(
      //     "normal_video",
      //     values.normal_video?.file?.originFileObj
      //   );

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img", "normal_video"]),
          file_delete: fileDelete,
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != ""
              ? (url_path = "/manage-website/home/vtr/update")
              : (url_path = "/manage-website/home/vtr");
            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const handleRemoveDesktop = (e) => {
    setFileListDesktop([]);

    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveMobile = (e) => {
    setFileListMobile([]);

    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };
  const handleRemoveVdo = (e) => {
    if (e.uid?.toString()?.indexOf("rc-upload") === -1) {
      setFileDelete([...fileDelete, e.uid]);
    }
  };


  const handleUploadDesktop = async (e) => {
    let isFile = await onUpload(e, 1244, 583);
    if (isFile) {
      setFileListDesktop(isFile);
    }
  };

  const handleUploadMobile = async (e) => {
    let isFile = await onUpload(e, 334, 230);
    if (isFile) {
      setFileListMobile(isFile);
    }
  };


  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/home/vtr",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        setLoadValue(data);

        let { loadDesktopImg, loadMobileImg, loadVTR } = data;

        setSelectUploadVideo(data.select_upload);

        for (let i in data.files) {
          if (data.files[i].document_type == "34") {
            loadDesktopImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListDesktop(loadDesktopImg);
          } else if (data.files[i].document_type == "35") {
            loadMobileImg = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setFileListMobile(loadMobileImg);
          } else if (data.files[i].document_type == "36") {
            loadVTR = [
              {
                uid: data.files[i].id,
                status: "done",
                name: data.files[i].file_name,
                url: data.files[i].azure_url,
              },
            ];
            setSelectedFileList(loadVTR);
          }
        }

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>VTR</PanelHeader>
        <PanelBody>
          <Form layout="vertical">
            <Row className="mb-3">
              <Form.Item label="Cover Title :">
                {getFieldDecorator("title", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ Cover Title" }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="VTR Title :">
                {getFieldDecorator("vdo_title", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ VTR Title" }],
                })(<Input />)}
              </Form.Item>
            </Row>
            <Row>
              <Row gutter={24}>
                <Col md={12}>
                  {fileListDesktop.length != 0 ? (
                    <Row>
                      <div style={DivStyles}>
                        <img
                          src={fileListDesktop[0].url}
                          alt="desktop_img"
                          style={ImgStyles}
                        />
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12}>
                  {fileListMobile.length != 0 ? (
                    <div style={DivStyles}>
                      <img
                        src={fileListMobile[0].url}
                        alt="mobile_img"
                        style={ImgStyles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row gutter={24} className="mb-2">
                <Col md={12} className="mb-2">
                  <Form.Item label="Desktop: 1244 x 583 px " name="desktop_img">
                    {getFieldDecorator("desktop_img", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListDesktop}
                        customRequest={dummyRequest}
                        onPreview={handlePreview}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveDesktop}
                        onChange={handleUploadDesktop}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                  <Modal visible={previewVisible} onCancel={handleCancel}>
                    <br></br>
                    <br></br>
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Col>
                <Col md={12} className="mb-2">
                  <Form.Item label="Mobile: 334 x 230 px  " name="mobile_img">
                    {getFieldDecorator("mobile_img", {
                      initialValue: null,
                    })(
                      <Upload.Dragger
                        accept="image/*"
                        multiple={false}
                        fileList={fileListMobile}
                        customRequest={dummyRequest}
                        onPreview={handlePreview}
                        beforeUpload={beforeUploadImg}
                        onRemove={handleRemoveMobile}
                        onChange={handleUploadMobile}
                        listType="picture"
                      >
                        <AiOutlineUpload className="mr-3" />
                        Upload
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <br></br>
                    <br></br>
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Col>
              </Row>
            </Row>
            <Row>
              <Form.Item label="Upload Video">
                {getFieldDecorator("select_upload", {
                  initialValue: "",
                })(
                  <Radio.Group
                    onChange={(e) => {
                      setSelectUploadVideo(e.target.value);
                    }}
                  >
                    <Radio value={"normal_video"}>Normal Video</Radio>
                    <Radio value={"youtube_video"}>Youtube Video</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              {selectUploadVideo === "normal_video" ? (
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Item name="normal_video">
                      {getFieldDecorator("normal_video", {
                        initialValue: null,
                      })(
                        <Upload.Dragger
                          fileList={selectedFileList}
                          customRequest={dummyRequest}
                          accept="video/*"
                          multiple={false}
                          onChange={onChange}
                          listType="picture"
                          onRemove={handleRemoveVdo}
                        >
                          <AiOutlineUpload className="mr-3" />
                          Upload Video
                        </Upload.Dragger>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Form.Item label="URL Youtube :">
                    {getFieldDecorator("youtube_path", {
                      initialValue: loadValue.youtube_path,
                      rules: [
                        { required: true, message: "กรุณาระบุ URL Youtube" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Row>
              )}
            </Row>
            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != "" ? "Update" : "Save"}
              </Button>
            </Row>
          </Form>
        </PanelBody>
      </Panel>
    </>
  );
}

export default Form.create("home-vtr")(VTR);
