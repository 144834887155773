/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
//import { Tabs } from 'antd'
import { Button, notification, Skeleton, Typography } from "antd";
import React, { useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { URL_API } from "../../../../../utils";
import TableAddressForm from "../../components/TableAddressForm";
import AddressByIDCardForm from "./AddressByIDCardForm";
import AddressHistory from "./AddressHistory";
import CurrentAddressForm from "./CurrentAddressForm";
import MarriedAddessForm from "./MarriedAddessForm";
import TexAddressForm from "./TexAdreessForm";

const BuyerAdressInfo = ({
  handleChange,
  Member,
  setMember,
  errors,
  id,
  loadingAddress,
  ActiveTabs,
}) => {
  const list = [
    { title: "ที่อยู่ตามบัตรประชาชน", value: "addressByIDcard" },
    { title: "ที่อยู่สำหรับออกใบกำกับภาษี", value: "texAddress" },
    { title: "ที่อยู่ปัจจุบัน", value: "currentAddress" },
    { title: "ที่อยู่จัดส่ง", value: "deliveryAddress" },
  ];
  const listMarried = [
    { title: "ที่อยู่ตามบัตรประชาชน (รหัสร่วม)", value: "addressByIDcard" },
  ];
  const [activeTabMarried, setActiveTabMarried] = useState("addressByIDcard");
  const [activeTab, setActiveTab] = useState("addressByIDcard");
  const [show, setShow] = useState(false);

  const [loadingAllAddress, dataAllAddress, errorAllAddress] = useHttp({
    method: "GET",
    url: `${URL_API}/pos/ethics/${id}/all-address`,
    token: true,
  });

  if (errorAllAddress) {
    notification.error({
      description: errorAllAddress,
      placement: "bottomRight",
    });
  }

  return (
    <>
      {ActiveTabs === "buyerInfo" ? (
        <>
          <ul className="custom-tabs">
            <div className="d-flex">
              {list.map((n, i) => (
                <li
                  key={i}
                  className={`${activeTab === n.value ? "active" : ""} mr-4`}
                  onClick={() => setActiveTab(n.value)}
                >
                  <Typography.Title level={4}>
                    <a style={{ fontSize: "13px" }}>{n.title}</a>
                  </Typography.Title>
                </li>
              ))}
            </div>
            <div className="d-flex">
              <Button
                size="small"
                className="m-1 d-flex align-items-center"
                icon="history"
                shape="round"
                onClick={() => setShow(true)}
              >
                History
              </Button>
            </div>
          </ul>
          <div className="container shadow-sm p-15 mb-4 bg-white rounded">
            {activeTab === "addressByIDcard" ? (
              <>
                {loadingAddress ? (
                  <Skeleton active />
                ) : (
                  <AddressByIDCardForm
                    setMember={setMember}
                    Member={Member}
                    errors={errors}
                    handleChange={handleChange}
                  />
                )}
              </>
            ) : activeTab === "texAddress" ? (
              <TexAddressForm
                setMember={setMember}
                Member={Member}
                errors={errors}
                handleChange={handleChange}
              />
            ) : activeTab === "currentAddress" ? (
              <CurrentAddressForm
                setMember={setMember}
                Member={Member}
                errors={errors}
                handleChange={handleChange}
              />
            ) : (
              <TableAddressForm
                loading={loadingAllAddress}
                data={dataAllAddress?.data.filter(
                  (i) => i.address_type === 3 && i.status === "1"
                )}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <ul className="custom-tabs">
            <div className="d-flex">
              {listMarried.map((n, i) => (
                <li
                  key={i}
                  className={`${
                    activeTabMarried === n.value ? "active" : ""
                  } mr-4`}
                  onClick={() => setActiveTabMarried(n.value)}
                >
                  <Typography.Title level={4}>
                    <a style={{ fontSize: "13px" }}>{n.title}</a>
                  </Typography.Title>
                </li>
              ))}
            </div>
            <div className="d-flex">
              <Button
                size="small"
                className="m-1 d-flex align-items-center"
                icon="history"
                shape="round"
                onClick={() => setShow(true)}
              >
                History
              </Button>
            </div>
          </ul>
          <div className="container shadow-sm p-15 mb-4 bg-white rounded">
            <MarriedAddessForm
              setMember={setMember}
              Member={Member}
              errors={errors}
              handleChange={handleChange}
            />
          </div>
        </>
      )}

      {Member?.partner_code && (
        <AddressHistory
          show={show}
          onCancel={() => setShow(false)}
          code={Member.partner_code}
        />
      )}
    </>
  );
};
export default BuyerAdressInfo;
