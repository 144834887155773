import { Row, Col, Form, Input } from "antd";
import React, { useContext } from "react";
import { Editor } from "react-draft-wysiwyg";
import { SupplierContext } from "../../reducer";
import SelectAddressMaster from "../../../../../../../components/SelectAddressMaster";

const Supplier = ({ getFieldDecorator, setFieldsValue }) => {
  const { state, dispatch } = useContext(SupplierContext);
  return (
    <>
      <Row gutter={16}>
        <Col md={12}>
          <label className="mb-0">รหัส</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("code")(
              <Input autoComplete="off" placeholder="รหัสผู้ผลิต" disabled />
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="mb-0">ชื่อ</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุชื่อผู้ผลิต",
                },
              ],
            })(<Input autoComplete="off" placeholder="ชื่อผู้ผลิต" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <label className="mb-0">หมายเลขจดทะเบียน</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("register_no")(
              <Input autoComplete="off" placeholder="หมายเลขจดทะเบียน" />
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="mb-0">หมายเลขภาษี</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("tax_no")(
              <Input autoComplete="off" placeholder="หมายเลขภาษี" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col md={24}>
          <label className="mb-0">รายละเอียดผู้ผลิต</label>
          <Editor
            editorClassName="border"
            editorState={state.data.description}
            onEditorStateChange={(e) =>
              dispatch({ type: "SET_DATA", payload: { description: e } })
            }
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <label className="mb-0">ที่อยู่</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("address_no", {
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุที่อยู่",
                },
              ],
            })(<Input autoComplete="off" placeholder="ที่อยู่" />)}
          </Form.Item>
        </Col>
        <Col md={12}></Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <label className="mb-0">จังหวัด</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("province_id", {
              onChange: (e, option) => {
                setFieldsValue({
                  district_id: undefined,
                  sub_district_id: undefined,
                  post_code: undefined,
                });
                dispatch({
                  type: "SET_DATA",
                  payload: {
                    province_id: e,
                    province: option.props.source.name,
                    district_id: null,
                    district: null,
                    sub_district_id: null,
                    sub_district: null,
                    post_code: null,
                  },
                });
              },
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกจังหวัด",
                },
              ],
            })(
              <SelectAddressMaster
                placeholder="เลือกจังหวัด"
                type="province"
                className="w-100 f-s-12"
                showSearch
                filterOption={(input, option) =>
                  option.props.children.indexOf(input.toLowerCase()) >= 0
                }
              />
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="mb-0">อำเภอ/เขต</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("district_id", {
              onChange: (e, option) => {
                setFieldsValue({
                  sub_district_id: undefined,
                  post_code: undefined,
                });
                dispatch({
                  type: "SET_DATA",
                  payload: {
                    district_id: e,
                    district: option.props.source.name,
                    sub_district_id: null,
                    sub_district: null,
                    post_code: null,
                  },
                });
              },
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกอำเภอ/เขต",
                },
              ],
            })(
              <SelectAddressMaster
                placeholder="เลือกอำเภอ/เขต"
                type="district"
                className="w-100 f-s-12"
                showSearch
                filterOption={(input, option) =>
                  option.props.children.indexOf(input.toLowerCase()) >= 0
                }
                province_id={state.data.province_id}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <label className="mb-0">ตำบล/แขวง</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("sub_district_id", {
              onChange: (e, option) => {
                setFieldsValue({
                  post_code: option.props.source.post_code,
                });
                dispatch({
                  type: "SET_DATA",
                  payload: {
                    sub_district_id: e,
                    sub_district: option.props.source.name,
                  },
                });
              },
              rules: [
                {
                  required: true,
                  message: "กรุณาเลือกตำบล/แขวง",
                },
              ],
            })(
              <SelectAddressMaster
                placeholder="เลือกตำบล/แขวง"
                type="subdistrict"
                className="w-100 f-s-12"
                showSearch
                filterOption={(input, option) =>
                  option.props.children.indexOf(input.toLowerCase()) >= 0
                }
                district_id={state.data.district_id}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="mb-0">รหัสไปรษณีย์</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("post_code", {
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุรหัสไปรษณีย์ (เลือกตำบล/แขวง)",
                },
              ],
            })(
              <Input placeholder="รหัสไปรษณีย์" className="f-s-12" disabled />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <label className="mb-0">เบอร์โทรศัพท์</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("phone")(
              <Input autoComplete="off" placeholder="เบอร์โทรศัพท์" />
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="mb-0">แฟกซ์</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("fax")(
              <Input autoComplete="off" placeholder="แฟกซ์" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <label className="mb-0">อีเมล</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("email")(
              <Input autoComplete="off" placeholder="อีเมล" />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Supplier;
