/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ControlPanel from '../components/ControlPanel';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { Row, Col, Table, Divider, Select, Alert } from 'antd';
import Criteria from './Criteria';
import { useHttp } from '../../../../hooks/http';
import { moment } from 'fullcalendar';
import xlsx from 'xlsx';
import { URL_API } from '../../../../utils';

export default () => {
    // const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#00D9E9', '#FF66C3'];
    const months = [
        { key: 1, short_name: 'ม.ค', long_name: 'มกราคม' },
        { key: 2, short_name: 'ก.พ.', long_name: 'กุมภาพันธ์' },
        { key: 3, short_name: 'มี.ค.', long_name: 'มีนาคม' },
        { key: 4, short_name: 'เม.ย.', long_name: 'เมษายน' },
        { key: 5, short_name: 'พ.ค.', long_name: 'พฤษภาคม' },
        { key: 6, short_name: 'มิ.ย.', long_name: 'มิถุนายน' },
        { key: 7, short_name: 'ก.ค.', long_name: 'กรกฎาคม' },
        { key: 8, short_name: 'ส.ค.', long_name: 'สิงหาคม' },
        { key: 9, short_name: 'ก.ย.', long_name: 'กันยายน' },
        { key: 10, short_name: 'ต.ค.', long_name: 'ตุลาคม' },
        { key: 11, short_name: 'พ.ย.', long_name: 'พฤศจิกายน' },
        { key: 12, short_name: 'ธ.ค.', long_name: 'ธันวาคม' }
    ];
    const custType = [
        { value: 'New', text: 'สมัครใหม่' },
        { value: 'BO', text: 'BO' },
        { value: 'MBO', text: 'MBO' },
        { value: 'Resign', text: 'ลาออก' }
    ];
    const omit = ['new', 'month', 'total', 'key', 'type', 'title', 'period_month'];
    const [year, setYear] = useState(moment().format("YYYY"));
    const [type, setType] = useState("New");
    const [team, setTeam] = useState(undefined);
    const [columns, setColumns] = useState([]);
    const [options, setOptions] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [filterDataSource, setFilterDataSource] = useState([]);
    const [selectKey, setSelectKey] = useState(null);
    const [teamChart, setTeamChart] = useState({
        show: false,
        title: '',
        categories: [],
        series: []
    });
    const [monthChart, setMonthChart] = useState({
        show: false,
        title: '',
        categories: [],
        series: []
    });

    const [loading, data, error] = useHttp({
        method: 'post',
        url: `${URL_API}/boss/reports/team/register`,
        data: { period_year: year }
    }, [year]);

    const handleChangeType = () => {
        let newFilter = _.filter(dataSource, n => n.type === type);
        setFilterDataSource(newFilter);
    }

    const breadcrumb = [
        { link: '/', title: 'หน้าหลัก' },
        { link: null, title: 'รายงานการสมัครรายทีม' },
        { link: null, title: 'รายงานการสมัครรายทีม -1' }
    ];

    const monthChartOpt = {
        options: {
            chart: {
                height: 350,
                type: 'bar',
                // background: '#373d3f',
                // foreColor: '#fff'
            },
            title: {
                text: monthChart.title,
                align: 'center'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            // colors: ['#2d353c', '#8753de', '#b6c2c9'],
            xaxis: {
                categories: monthChart.categories,
                axisBorder: {
                    show: true,
                    color: 'rgba(182, 194, 201, 0.5)',
                    height: 1,
                    width: '100%',
                    offsetX: 0,
                    offsetY: -1
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#b6c2c9',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                }
            },
            fill: {
                opacity: 1
            }
        },
        series: monthChart.series
    }

    const teamChartOpt = {
        options: {
            chart: {
                height: 350,
                type: 'bar',
                // background: '#373d3f',
                // foreColor: '#fff'
            },
            title: {
                text: teamChart.title,
                align: 'center'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            // colors: ['#2d353c', '#8753de', '#b6c2c9'],
            xaxis: {
                categories: teamChart.categories,
                axisBorder: {
                    show: true,
                    color: 'rgba(182, 194, 201, 0.5)',
                    height: 1,
                    width: '100%',
                    offsetX: 0,
                    offsetY: -1
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#b6c2c9',
                    height: 6,
                    offsetX: 0,
                    offsetY: 0
                }
            },
            fill: {
                opacity: 1
            }
        },
        series: teamChart.series
    }

    const handleChangeTeam = team => {
        setTeam(team);
        setTeamChart({
            show: true,
            title: team,
            categories: months.map(n => n.long_name),
            series: custType.map(c => {
                return {
                    name: c.text,
                    data: months.map(m => {
                        const getRecord = dataSource.find(n => n.period_month === m.key && n.type.toString() === c.value.toString());
                        return getRecord ? getRecord[team] : null
                    })
                }
            })
        });
    }

    const handleClickRow = record => {
        setSelectKey(record.key);
        var sortable = [];
        for (var key in _.omit(record, omit)) {
            if (record.hasOwnProperty(key)) {
                sortable.push([key, record[key]]);
            }
        }
        sortable.sort(function (a, b) {
            return b[1] - a[1];
        });

        setMonthChart({
            show: true,
            title: months.find(n => n.key === record.period_month).long_name,
            categories: sortable.map(n => n[0]),
            series: [{ name: record.title, data: sortable.map(n => n[1]) }]
        })
    }

    useEffect(() => {
        if (filterDataSource.length > 0 && columns.length > 0) {
            if (filterDataSource.length > 0) {
                // console.log(filterDataSource, columns);
                // let ws = {};

                // let row = 1;
                // let colName = 'A1';
                // console.log(_.reduce(_.values(_.omit(filterDataSource[0], [...omit, 'month', 'total'])), (sum, s) => {
                //     return sum + s;
                // }, 0));
                // filterDataSource.forEach(n => {
                //     let col = 0;
                //     if (row === 1) {
                //         columns.forEach(m => {
                //             colName = `${String.fromCharCode(97 + col).toUpperCase()}${row}`;
                //             ws[colName] = { t: 's', v: m.title };
                //             col++;
                //         });

                //         row++;
                //         col = 0;
                //     }

                //     columns.forEach(m => {
                //         colName = `${String.fromCharCode(97 + col).toUpperCase()}${row}`;
                //         if (m.key === 'total') {
                //             ws[colName] = {
                //                 t: 'n',
                //                 v: _.reduce(_.values(_.omit(n, [...omit, 'month', 'total'])), (sum, s) => {
                //                     return sum + s;
                //                 }, 0),
                //                 f: `SUM(B${row}:${String.fromCharCode(97 + col - 1).toUpperCase()}${row})`
                //             };
                //         } else {
                //             ws[colName] = { t: isNaN(n[m.dataIndex]) ? 's' : 'n', v: n[m.dataIndex] };
                //         }
                //         col++;
                //     });

                //     row++;
                // })

                // ws["!ref"] = `A1:${colName}`;

                // //  ws = {
                // //     "!ref": "A1:A3",
                // //     A1: { t:'n', v:1 },
                // //     A2: { t:'n', v:2 },
                // //     A3: { t:'n', v:3, f:'A1+A2' }
                // //   }
                // // ws = xlsx.utils.json_to_sheet(filterDataSource);
                // console.log(ws);
                // let wb = xlsx.utils.book_new();
                // xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
                // xlsx.writeFile(wb, 'Export.xlsx');
            }


            // let ws = xlsx.utils.json_to_sheet(data.data.map(n => ({ ...n, month: months.find(m => m.key === n.period_month).short_name })));
            //     let wb = xlsx.utils.book_new();
            //     xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
            //     console.log(ws);

            //     xlsx.writeFile(wb, 'Export.xlsx');
        }
    }, [filterDataSource, columns]);

    useEffect(() => {
        handleChangeType("New");
        setMonthChart({
            ...monthChart,
            show: false
        });
        if (dataSource.length > 0) {
            let newData = dataSource;
            let newColumns = [];
            if (newData.length > 0) {
                newColumns.push({
                    title: 'Month',
                    className: 'text-center',
                    dataIndex: 'month',
                    key: 'month',
                    render: (text) => <p className="text-center m-0">{text}</p>
                });

                const column = _.keys(_.omit(newData[0], omit));
                setOptions(column);
                handleChangeTeam(column[0]);
                column.map(n => {
                    return newColumns.push({
                        title: n,
                        className: 'text-center text-capitalize',
                        dataIndex: n,
                        key: n,
                        render: (text) => <p className="text-right m-0">{text}</p>,
                        onHeaderCell: () => {
                            return {
                                onClick: () => {
                                    handleChangeTeam(n);
                                }
                            }
                        }
                    });
                });

                newColumns.push({
                    title: 'Total',
                    className: 'text-center',
                    key: 'total',
                    render: (text, record) => (
                        <p className="text-right m-0">
                            {
                                _.reduce(_.values(_.pick(record, column)), (sum, n) => {
                                    return sum + n;
                                }, 0)
                            }
                        </p>
                    )
                })
            }
            setColumns(newColumns);

            handleClickRow(filterDataSource[0] || dataSource[0]);
        }
    }, [dataSource, type])

    useEffect(() => {
        let newData = [];
        if (data) {
            newData = data.data.map(n => ({ ...n, month: months.find(m => m.key === n.period_month).short_name, key: _.uniqueId('tr-') }));
            setTeamChart({
                ...teamChart,
                show: false
            });
            setTeam(null);
            setDataSource(newData);
        }
    }, [data]);

    if (error) {
        return <Alert
            message="Error:"
            description={error}
            type="error"
            showIcon
        />
    }

    return (
        <Layout
            breadcrumb={breadcrumb}
            titlePage="รายงานการสมัครรายทีม"
            subTitlePage="...."
        >
            <ControlPanel titlePanel="ตารางแสดงรายงานการสมัครรายทีม">
                <Criteria custType={custType} type={type} onChangeType={e => setType(e.target.value)} onChangeYear={e => setYear(e.target.value)} />
                <Row gutter={8}>
                    <Col md={12}>
                        {
                            monthChart.show ?
                                <>
                                    <Divider orientation="left">
                                        <Select
                                            className="w-100"
                                            value={year}
                                        >
                                            <Select.Option value={year}>{year}</Select.Option>
                                        </Select>
                                    </Divider>
                                    <Chart type="bar" options={monthChartOpt.options} series={monthChartOpt.series} />
                                </> : null
                        }
                    </Col>
                    <Col md={12}>

                        {
                            teamChart.show ?
                                <>
                                    <Divider orientation="left">
                                        <Select
                                            placeholder="Select Team"
                                            showSearch
                                            allowClear
                                            className="w-100"
                                            value={team}
                                            dropdownMatchSelectWidth={false}
                                            onChange={handleChangeTeam}>
                                            {
                                                options.map((n, index) => <Select.Option key={`option-${index}`} value={n}><span className="text-capitalize">{n}</span></Select.Option>)
                                            }
                                        </Select>
                                    </Divider>
                                    <Chart type="bar" options={teamChartOpt.options} series={teamChartOpt.series} />
                                </> : null
                        }
                    </Col>
                </Row>
                <Table
                    bordered
                    size="small"
                    loading={loading}
                    columns={columns}
                    dataSource={filterDataSource}
                    pagination={false}
                    onRow={(record) => ({ onClick: () => { handleClickRow(record) } })}
                    rowClassName={record => record.key === selectKey ? 'table-warning' : ''}
                />
            </ControlPanel>
        </Layout>
    )
}
