import { Select } from "antd";
import React, { forwardRef } from "react";
import { shallowEqual, useSelector } from "react-redux";

const SelectUserDocument = ({ onUpload = false, ...props }) => {
  const { document_list } = useSelector(
    (state) => state.ethics.form.upload,
    shallowEqual
  );
  return (
    <>
      <Select
        showSearch
        loading={props.loading}
        loadOptions={props.loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        dropdownMatchSelectWidth={false}
        {...props}
      >
        {onUpload
          ? document_list?.unupload?.map((n, i) => (
              <Select.Option key={i} value={n.code}>
                {n.name}
              </Select.Option>
            ))
          : document_list?.uploaded?.map((n, i) => (
              <Select.Option
                style={{
                  color: n.document_status === "approve" ? "#97D700" : "",
                }}
                key={i}
                value={n.code}
              >
                {n.name}
              </Select.Option>
            ))}
      </Select>
    </>
  );
};
export default forwardRef(SelectUserDocument);
