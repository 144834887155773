import React, { useEffect } from 'react';
import { useQuery } from '../../../../hooks/dom';
import styles from '../../../../scss/module/legal.module.scss';
import LegalPanel from '../components/LegalPanel';
import SaleHistory from './SaleHistory';

const Index = ({history}) => {
  const query = useQuery();
    const id = query.get('id');
    useEffect(() => {
        !+id && history.push('/legal');
    }, [id, history]);
    return (
        <LegalPanel>
            <div className={styles.history}>
              <SaleHistory />
            </div>
        </LegalPanel>
    )
}

export default Index
