/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, notification, Row, Tabs, Typography } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadFileList from "../../../../../components/UploadFileList";
import { actionClaim } from "../../../../../redux/slices/claim";
import { _ } from "../../../../../utils";
import HeaderDetail from "./HeaderDetail";
import ProductDetail from "./ProductDetail";
import ProductHeader from "./ProductHeader";
import ProductUsedBy from "./ProductUsedBy";

const {
  clearGlobalState,
  addClaimItemBom,
  delClaimItemBom,
  setClaimItemActiveBom,
  addClaimItemMediaBom,
  delClaimItemMediaBom,
} = actionClaim;

const Index = () => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const { visible, product_bom_key } = useSelector(
    (state) => state.claim.productBomModal
  );
  const { activeKey, itemKeys, itemByKey } = useSelector((state) => {
    if (!product_key || !product_bom_key) return {};
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;
  });
  const qty_balance = useSelector((state) => {
    if (!product_key || !product_bom_key) return 0;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    return itemByKey[activeKey].bom.itemByKey[product_bom_key].qty_balance;
  });
  const disabled = useSelector(
    (state) =>
      state.claim.isPreview ||
      !_.includes([null, "draft", "waiting"], state.claim.documentDetail.status)
  );

  const handleEditTab = (e, process) => {
    if (process === "add") {
      if (itemKeys.length >= qty_balance) {
        return notification.warning({
          message: "ไม่สามารถเพิ่มจำนวนสินค้าได้",
          description: `จำนวนที่เคลมได้สูงสูด ${qty_balance} ชิ้น`,
        });
      }
      dispatch(addClaimItemBom());
    } else {
      const index = _.findIndex(itemKeys, (n) => n === e);
      Modal.confirm({
        title: "ยืนยันทำรายการ",
        content: `ยกเลิกเคลมสินค้า ชิ้นที่ - ${index + 1} ?`,
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          dispatch(delClaimItemBom(e));
        },
      });
    }
  };

  const handleClickTab = (key) => dispatch(setClaimItemActiveBom(key));

  const handleClose = () => dispatch(clearGlobalState("productBomModal"));

  const handleChangeUpload = useCallback(
    (value) => dispatch(addClaimItemMediaBom(value)),
    []
  );
  const handleDeleteUpload = useCallback(
    (value) => dispatch(delClaimItemMediaBom(value)),
    []
  );

  return (
    <Modal
      className="modal-with-tab"
      visible={visible}
      footer={false}
      width={1000}
      zIndex={1080}
      destroyOnClose={true}
      maskClosable={false}
      closable={false}
    >
      <HeaderDetail />
      <Tabs
        activeKey={activeKey}
        type={disabled ? "card" : "editable-card"}
        onEdit={handleEditTab}
        onTabClick={handleClickTab}
      >
        {_.map(itemKeys, (key, i) => (
          <Tabs.TabPane
            closable
            key={key}
            tab={`ชิ้นที่ - ${i + 1}`}
            className="px-3"
          >
            <ProductHeader disabled={disabled}/>

            <ProductUsedBy
              code={itemByKey[key].used_by_code}
              name={itemByKey[key].used_by_name}
              mobile={itemByKey[key].used_by_mobile}
              disabled={disabled}
            />

            <ProductDetail disabled={disabled}/>

            {/* <UploadFileMedia
              allowMp4={true}
              labelSpan={3}
              labelMd={3}
              labelXxl={3}
              label="แนบรูปหรือวิดีโอ"
              media={itemByKey[key].media}
              onChange={handleChangeUpload}
              onDelete={handleDeleteUpload}
              onLoaded={handleLoadedUpload}
              onSubmitCamera={handleSubmitCameraUpload}
            /> */}

            <Row gutter={[8, 8]} className="mb-3">
              <Col span={3}>
                <Typography.Text className="text-nowrap">
                  แนบรูปหรือวิดีโอ
                </Typography.Text>
              </Col>
              <Col span={21}>
                <UploadFileList
                  withCamera
                  acceptVideo
                  files={itemByKey[key].media}
                  onChange={handleChangeUpload}
                  onDelete={handleDeleteUpload}
                  disabled={disabled}
                />
              </Col>
            </Row>

            {/* {process === "check" ? (
              <>
                <PreviousDetail
                  collapse={true}
                  data={prevData}
                  claimType={newForm.claim_type}
                />
                <Divider />
                <FormProduct
                // ref={n => productRef[key] = n}
                // wrappedComponentRef={n => {
                //    console.log(n);
                //    productRef[key] = n
                // }}
                // ref={n => {
                //    productRef.current[key] = n
                // }}
                // ref={n => productRef2.current[index] = n}
                // ref={productRef2.current[index]}
                />
              </>
            ) : process === "new" ? (
              <>
                <Row
                  gutter={16}
                  type="flex"
                  className="mb-3 align-items-center"
                >
                  <Col span={24} className="text-right">
                    <Button
                      className="bg-pv text-white border-pv"
                      onClick={handleAddQtyProductWithCopy}
                    >
                      <FaRegCopy className="mr-2" />
                      <Typography.Text className="text-white">
                        เพิ่มจำนวนเคลม (คัดลอก)
                      </Typography.Text>
                    </Button>
                  </Col>
                </Row>
                <FormProduct
                // ref={n => productRef[key] = n}
                // wrappedComponentRef={n => {
                //    console.log(n);
                //    productRef[key] = n
                // }}
                // ref={n => {
                //    productRef.current[key] = n
                // }}
                // ref={n => productRef2.current[index] = n}
                // ref={productRef2.current[index]}
                />
              </>
            ) : (
              <>
                <PreviousDetail
                  collapse={false}
                  data={prevData}
                  claimType={newForm.claim_type}
                />
              </>
            )}
            <Divider /> */}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Row gutter={[16, 16]} className="my-3">
        <Col span={24} className="text-center">
          <Button type="primary" onClick={handleClose}>
            ตกลง
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Index;
