/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button, Card, Tabs } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";
import FormBank from "../Form/FormBank";

function BankTabs({ ...props }, ref) {
  const dispatch = useDispatch();
  const active = useSelector(
    (state) => state.ethics.form.active_tab.bank,
    shallowEqual
  );

  const { member, is_company, bank } = useSelector(
    (state) => state.ethics.form,
    shallowEqual
  );


  const { OPEN_MODAL, SET_ACTIVE_TAB, DELETE_FORM_BANK } = actionEthics;

  const personRef = useRef();
  const companyRef = useRef();


  const submit = async (form) => {
    try {
      switch (form) {
        case "person":
          return (
            personRef.current &&
            (await personRef.current.validateFieldsAndScroll())
          );
        case "company":
          return (
            companyRef.current &&
            (await companyRef.current.validateFieldsAndScroll())
          );
        default:
          return false;
      }
    } catch (error) {
      return error;
    }
  };
  useImperativeHandle(ref, () => ({
    submit: async () => {
      if (is_company) {
        const validate_person_bank = await submit("person");
        const validate_company_bank = await submit("company");
        return {
          person: validate_person_bank,
          company: validate_company_bank,
        };
      } else {
        const validate_person_bank = await submit("person");
        return {
          person: validate_person_bank,
        };
      }
    },
  }));

  useEffect(() => {
    dispatch(SET_ACTIVE_TAB({ key: "bank", value: "Person" }));
    
    if (
      member?.company_name == null ||
      member?.company_name == undefined ||
      member?.company_name == ""
    ) {
      dispatch(SET_ACTIVE_TAB({ key: "bank", value: "Person" }));
      dispatch(DELETE_FORM_BANK({ eft_type: "Company" }));
    }
  }, [is_company, member?.company_name]);

  return (
    <Card
      bordered={false}
      size="small"
      className="mb-3"
      style={{ boxShadow: "0px 2px 4px #ccc" }}
    >
      <Tabs
        size="small"
        // activeKey={active}
        // onChange={(e) => dispatch(SET_ACTIVE_TAB({ key: "bank", value: e }))}
        tabBarGutter={0}
        tabBarExtraContent={
          <Button
            size="small"
            type="link"
            onClick={() => dispatch(OPEN_MODAL({ name: "bank" }))}
          >
            History
          </Button>
        }
      >
        {is_company === 1 &&
        member?.company_name != null &&
        member?.company_name != undefined &&
        member?.company_name != "" ? (
          <Tabs.TabPane tab="บัญชีนิติบุคคล" key="Company"
          >
            <FormBank ref={companyRef} eft_type="Company" />
          </Tabs.TabPane>
        ) : (
          <Tabs.TabPane tab="บัญชีบุลคลธรรมดา" key="Person">
            <FormBank ref={personRef} eft_type="Person" />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Card>
  );
}
export default forwardRef(BankTabs);
