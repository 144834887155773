import { Button, Col, Input, Row, Table, Tooltip,Tag } from "antd";
import numeral from "numeral";
import React from "react";
import { FaBarcode, FaCogs, FaEye, FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import noImg from "../../../../../assets/img/pos/no-img.jpg";
import { useApi } from "../../../../../hooks/http2";
import {
  actionProduct,
  searchSelector,
} from "../../../../../redux/slices/product";
import { URL_API, getUser } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectSubCategory from "../../../pos/components/SelectSubCategory";
import SelectProductType from "./components/SelectProductType";
import SelectStatus from "./components/SelectStatus";

const ProductList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const user = getUser();
  const { SET_SEARCH } = actionProduct;
  let list = useApi(
    {
      baseURL: URL_API,
      url: `/system/products`,
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
        page_type: "product",
      },
      token: true,
    },
    [search]
  );
  const handleCategory = async (arr) => {
    let lastItem;
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        lastItem = arr[i];
      }
    }

    dispatch(
      SET_SEARCH({
        category: lastItem || 0,
        page: 1,
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>รายการสินค้า</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-3">
          <Col md={5}>
            <Input.Search
              //   value={search?.search || ""}
              defaultValue={search?.search || ""}
              onSearch={(value) =>
                dispatch(
                  SET_SEARCH({
                    search: value,
                    page: 1,
                  })
                )
              }
              onBlur={(e) =>
                dispatch(
                  SET_SEARCH({
                    search: e.target.value,
                    page: 1,
                  })
                )
              }
              allowClear
              placeholder="SKU, รหัสสินค้า, ชื่อสินค้า"
            />
          </Col>
          <Col md={5}>
            <SelectSubCategory
              className="w-100"
              placeholder="เลือกหมวดหมู่สินค้า"
              onChange={(e) => handleCategory(e)}
              changeOnSelect
              defaultValue={[search.category]}
            />
          </Col>
          <Col md={3}>
            <SelectProductType
              className="w-100"
              value={search.distributionType}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    distributionType: e,
                    page: 1,
                  })
                )
              }
              allowClear
            />
          </Col>
          <Col md={3}>
            <SelectStatus
              className="search-100"
              value={search.status}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    status: e,
                    page: 1,
                  })
                )
              }
            />
          </Col>
          <Col md={8}>
            <Row gutter={8} type="flex" justify="end" className="mb-2">
              {/* <Col>
                <Link to={`${match.path}/0`}>
                  <Button type="primary" block className="bg-pv border-pv">
                    เพิ่มสินค้า
                  </Button>
                </Link>
              </Col> */}
              {/* <Col>
                <Upload
                  accept=".xlsx,.xls"
                  customRequest={dummyRequest}
                  beforeUpload={handleImportExcel}
                  showUploadList={false}
                >
                  <Tooltip title="นำเข้าสินค้า">
                    <Button
                      type="primary"
                      className="mr-2 d-flex align-items-center"
                    >
                      <Icon type="import" />
                      Import Excel
                    </Button>
                  </Tooltip>
                </Upload>
              </Col> */}
              {/* <Col>
                                <Upload
                                    beforeUpload={UploadDirectory}
                                    directory
                                    customRequest={dummyRequest}
                                    showUploadList={false}>
                                    <Tooltip title="นำเข้ารูปสินค้า">
                                        <Button type="primary" className="d-flex align-items-center"><Icon type="upload" />อัพโหลดรูปสินค้า</Button>
                                    </Tooltip>
                                </Upload>
                            </Col> */}
            </Row>
          </Col>
        </Row>

        <Table
          bordered
          size="small"
          rowKey="id"
          loading={list.loading}
          dataSource={list.fetch?.data || []}
          pagination={{
            current: search.page,
            pageSize: search.limit,
            total: list.fetch?.total || 0,
            showLessItems: true,
            size: "default",
            onChange: (page) =>
              dispatch(
                SET_SEARCH({
                  page,
                })
              ),
            showTotal: (total, range) =>
              `${range[0]} - ${range[1]} of ${total}`,
          }}
        >
          <Table.Column title="#" dataIndex="row_id" align="right" />
          <Table.Column
            title="รูปสินค้า"
            render={({ file_name, file_path, azure_url }) => (
              <div className="text-center">
                <img
                  // src={`${URL_API}/upload${file_path}`}
                  src={azure_url || noImg}
                  width={70}
                  alt="รูปสินค้า"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = noImg;
                  }}
                />
              </div>
            )}
          />
          <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
          <Table.Column title="ชื่อสินค้า" dataIndex="product_name_en" />
          <Table.Column title="SKU" dataIndex="backend_sku_code" />
          <Table.Column title="PV" dataIndex="point_01" align="right" />
          <Table.Column title="RP" dataIndex="point_02" align="right" />
          {/* <Table.Column title="CV" dataIndex="point_03" align="right" /> */}
          <Table.Column
            title="ราคาปลีก"
            dataIndex="retail_price"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="ราคานักธุรกิจ"
            dataIndex="distributor_price"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="ราคา VIP Member"
            dataIndex="member_price"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column
            title="น้ำหนัก"
            dataIndex="weight"
            align="right"
            render={(text) => numeral(text).format("0,0.00")}
          />
          <Table.Column title="สถานะ" dataIndex="status_name" align="center" />
          <Table.Column
            title="ช่องทาง"
            align="center"
            className="text-nowrap"
            render={({ shop, online,mobile }) => (
              <div>
                {shop !== 0 && (
                  <Tooltip title="Legacy Shop" >
                    <Tag>S</Tag> 
                   </Tooltip>
                )}
                {online !== 0 && (
                  <Tooltip title="Shopping Online" >
                    <Tag>O</Tag>
                  </Tooltip>
                )}
                {mobile !== 0 && (
                  <Tooltip title="Mobile" >
                   <Tag>M</Tag>
                  </Tooltip>
                )}
             </div>
            )}
          />

          <Table.Column
            title={<FaCogs />}
            align="center"
            className="text-nowrap"
            render={({ id, department_id, bar_code }) => (
              <>
                 {user?.department_id === department_id && (
                  <Button
                    size="small"
                    shape="circle-outline"
                    type="dashed"
                    className="mr-2"
                    onClick={() => {
                      history.push(
                        `/setting/master/product-request/0?product_id=${id}`
                      );
                    }}
                  >
                    
                    <FaPencilAlt />
                  </Button>
                )}

                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  // className="bg-pv border-pv"
                  onClick={() => {
                    history.push(`${match.path}/${id}`);
                  }}
                >
                  
                  <FaEye />
                </Button>

                {bar_code !== "" && (
                  <Link to={`${match.url}/barcode/${bar_code}`} target="_blank">
                    <Tooltip title="บาร์โค้ด">
                      <Button
                        size="small"
                        shape="circle"
                        type="default"
                        className="ml-2 bg-rp border-rp text-white"
                      >
                        <FaBarcode />
                      </Button>
                    </Tooltip>
                  </Link>
                )}
              </>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default ProductList;
