import { Button, Col, Row, Table, Typography } from "antd";
import numeral from "numeral";
import React, { useContext } from "react";
import { FaCogs, FaEye } from "react-icons/fa";
import PrintButton from "../../../../components/PrintButton";
import { moment, _ } from "../../../../utils";
import BillContext from "../reducer";
import { displayTotal } from "../../../../utils/helper";

const { Column } = Table;

const DocumentTable = ({ dataSource, total, loading, processParams }) => {
  const {
    state: {
      searchDocument: { page, limit },
      user: { permission_status },
    },
    dispatch,
  } = useContext(BillContext);

  const handleClickRecord = (record, isPreview) => {
    const {
      status,
      document_date,
      point_pending_balance,
      order_type,
      ship_status,
      req_receipt_type_text,
    } = record;
    const is_daily = document_date
      ? moment(document_date).format("DD") === moment().format("DD")
      : false;

    // const is_not_confirm_pack = ship_status == 'waiting-every';
    const is_not_clarify = point_pending_balance === 0;
    const is_regular = order_type.toLowerCase() === "regular";

    //ยังไม่แจงยอด(Hold) หรือ Regular
    //ภายในวัน 
    const is_consider =
      status === "wait-operation" ||
      (status === "wait-branch" &&
        is_daily &&
        // is_not_confirm_pack &&
        (is_not_clarify || is_regular));

    dispatch({
      type: "setGlobalState",
      payload: {
        name: "billDetail",
        value: {
          ...record,
          is_preview: !!isPreview,
          is_daily,
          is_consider,
          document_date_text: document_date
            ? moment(document_date).format("DD/MM/YYYY HH:mm")
            : null,
        },
      },
    });
  };

  const renderEdit = (record) => {
    const { status } = record;
    if (status === null) {
      return (
        <Button
          size="small"
          className="bg-nn border-nn text-white mr-2"
          onClick={() => handleClickRecord(record)}
        >
          ส่งคำขอยกเลิก
        </Button>
      );
    }
    if (!permission_status[status]) return;
    if (processParams === "pending") {
      return (
        <Button
          size="small"
          className="bg-tp border-tp text-white mr-2"
          onClick={() => handleClickRecord(record)}
        >
          ดำเนินการ
        </Button>
      );
    }
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={dataSource}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            size: "default",
            showTotal: displayTotal,
            total,
            showLessItems: true,
            hideOnSinglePage: true,
            onChange: (page) =>
              dispatch({
                type: "setGlobalState",
                payload: {
                  name: "searchDocument",
                  value: { page },
                },
              }),
          }}
        >
          <Column
            width={150}
            title="เลขที่ใบเสร็จ"
            key="document_no"
            dataIndex="document_no"
            align="center"
          />
          <Column
            width={100}
            title="รหัสสมาชิก"
            key="partner_code"
            dataIndex="partner_code"
            align="center"
          />
          <Column
            width={dataSource.length === 0 ? 150 : undefined}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">ชื่อสมาชิก</div>
            }
            key="partner_name"
            dataIndex="partner_name"
            render={(text) => <div className="min-width-100">{text}</div>}
          />
          {processParams === "request" && (
            <Column
              width={dataSource.length === 0 ? 150 : undefined}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ชื่อผู้ขาย
                </div>
              }
              key="cashier_name"
              dataIndex="cashier_name"
              render={(text) => <div className="min-width-100">{text}</div>}
            />
          )}
          <Column
            width={140}
            title="วันที่ใบเสร็จ"
            key="document_date"
            dataIndex="document_date"
            align="center"
            render={(text) => text && moment(text).format("DD/MM/YYYY HH:mm")}
          />
          <Column
            width={120}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                ประเภทการสั่งซื้อ
              </div>
            }
            key="order_type"
            dataIndex="order_type"
          />
          <Column
            width={100}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">สถานะการแจง</div>
            }
            key="clarify_text"
            dataIndex="clarify_text"
          />
          {_.includes(["request", "pending"], processParams) && (
            <Column
              width={100}
              className="position-relative"
              title={<div className="ant-table-absolute-title-center">PV</div>}
              key="pv"
              dataIndex="pv"
              align="right"
              render={(text) => numeral(text).format("0,0")}
            />
          )}
          {processParams === "request" && (
            <Column
              width={120}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  สถานะใบเสร็จ
                </div>
              }
              key="status_text"
              dataIndex="status_text"
            />
          )}
          {_.includes(["pending", "result"], processParams) && (
            <Column
              width={dataSource.length === 0 ? 150 : undefined}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ผู้ขอยกเลิก
                </div>
              }
              key="request_by"
              dataIndex="request_by"
              render={(text) => <div className="min-width-100">{text}</div>}
            />
          )}
          {_.includes(["pending", "result"], processParams) && (
            <Column
              width={150}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">สาขาผู้ขอยกเลิก</div>
              }
              key="bill_location"
              dataIndex="bill_location"
            />
          )}
          <Column
            width={160}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                การรับสินค้า
              </div>
            }
            key="req_receipt_type_text"
            dataIndex="req_receipt_type_text"
          />
          {_.includes(["pending", "result"], processParams) && (
            <Column
              width={140}
              title="วันที่ขอยกเลิก"
              key="request_date"
              dataIndex="request_date"
              align="center"
              render={(text) => text && moment(text).format("DD/MM/YYYY HH:mm")}
            />
          )}
          {processParams === "result" && (
            <Column
              width={dataSource.length === 0 ? 150 : undefined}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ผู้พิจารณา
                </div>
              }
              key="consider_by"
              dataIndex="consider_by"
              render={(text) => <div className="min-width-100">{text}</div>}
            />
          )}
          {processParams === "result" && (
            <Column
              width={140}
              title="วันที่พิจารณา"
              key="consider_date"
              dataIndex="consider_date"
              align="center"
              render={(text) => text && moment(text).format("DD/MM/YYYY HH:mm")}
            />
          )}
          {processParams === "result" && (
            <Column
              width={110}
              className="position-relative"
              title={
                <div className="ant-table-absolute-title-center">
                  ผลการพิจารณา
                </div>
              }
              key="status_text"
              dataIndex="status_text"
            />
          )}
          <Column
            width={
              processParams === "request"
                ? 200
                : processParams === "pending"
                ? 150
                : 80
            }
            className="position-relative"
            fixed="right"
            title={
              <div className="ant-table-absolute-title-center">
                <FaCogs />
              </div>
            }
            key="action"
            render={(_text, record) => (
              <Row>
                <Col span={24} className="text-right">
                  {renderEdit(record)}
                  <Button
                    shape="circle"
                    size="small"
                    type="primary"
                    className="mr-2"
                    onClick={() =>
                      processParams === "request"
                        ? dispatch({
                            type: "setGlobalState",
                            payload: {
                              name: "billPreview",
                              value: {
                                id: record.id,
                              },
                            },
                          })
                        : handleClickRecord(record, true)
                    }
                  >
                    <FaEye />
                  </Button>
                  {processParams !== "pending" && (
                    <PrintButton record={record} />
                  )}
                </Col>
                {processParams === "pending" && (
                  <Col span={24} className="text-center">
                    <Typography.Text className="font-size-10">
                      {record.status === "wait-branch"
                        ? "(รอผู้จัดการสาขาพิจารณา)"
                        : "(รอ Operation พิจารณา)"}
                    </Typography.Text>
                  </Col>
                )}
              </Row>
            )}
          />
        </Table>
      </Col>
    </Row>
  );
};

export default DocumentTable;
