import React from "react";
import { _ } from "../../../../../../../utils";
import PromotionData from "../PromotionData";

const StepProcess = ({ form: { getFieldsValue } }) => {
  const { condition_type, receive_type } = getFieldsValue();
  return _.includes(["item", "item-amount"], condition_type) ? (
    <PromotionData
      type="condition"
      itemType="item"
      receiveType={receive_type}
      qtyTextSuffix={condition_type === "item" ? " (ชิ้น) " : " (บาท) "}
    />
  ) : (
    _.includes(["amount", "every-amount"], condition_type) && (
      <PromotionData
        type="condition"
        itemType="amount"
        receiveType={receive_type}
      />
    )
  );
};
export default StepProcess;
