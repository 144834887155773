/* eslint-disable eqeqeq */
import { Card, Carousel, Empty, Descriptions, Badge } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  documentsSelector,
  documentActiveSelector,
} from "../../../../redux/slices/member";
export default function DocumentFile() {
  const document = useSelector(documentsSelector);
  const active = useSelector(documentActiveSelector);
  const [source, setSource] = useState(null);
  useEffect(() => {
    setSource(_.filter(document || [], (n) => n.document_type === active));
  }, [document, active]);
  return (
    <>
      {source ? (
        <>
          <Carousel
            arrows
            style={{ backgroundColor: "#858585", padding: "25px" }}
          >
            {source.map((image, index) => (
              <Card
                key={index}
                cover={
                  <img
                    style={{ backgroundColor: "#ffffff" }}
                    src={image?.azure_url}
                    alt="document-file"
                    className="w-75 d-block my-3 mx-auto"
                  />
                }
              ></Card>
            ))}
          </Carousel>
          {source.map((image, index) => (
            <Descriptions size="small" bordered>
              <Descriptions.Item label="สถานะเอกสาร" span={3}>
              {image.document_status == null ? (
                  <Badge status="warning" text="อยู่ระหว่างตรวจสอบ" />
                ) : image.document_status == 'approve' ? (
                  <Badge status="success" text="ผ่าน" />
                ) : (
                  <Badge status="error" text="ไม่ผ่าน" />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="หมายเหตุ" span={3}>
               {image.remark}
              </Descriptions.Item>
            </Descriptions>
          ))}
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}
