import { notification } from "antd";
import {
  fixProductType,
  fixProductTypeSet,
} from "../../../../../config/fix-value";
import { _ } from "../../../../../utils";

export const getDataCheckStock = (orders) => {
  const { activeKey, itemKeys, itemByKey } = orders;
  const all_items = _.reduce(
    itemKeys,
    (res, order_key) => {
      const res_item = _.reduce(
        itemByKey[order_key].items.itemKeys,
        (res, item_key) => {
          if (itemByKey[order_key].items.itemByKey[item_key]) {
            const { product_code, qty } =
              itemByKey[order_key].items.itemByKey[item_key];
            return {
              ...res,
              [product_code]: {
                qty: (res[product_code]?.qty || 0) + qty,
              },
            };
          } else {
            return {
              ...res,
            };
          }
        },
        res
      );
      const res_item_promotion = _.reduce(
        itemByKey[order_key].promotions.promotion_list,
        (res, item) => {
          const { product_code, qty } = item;
          return {
            ...res,
            [product_code]: {
              qty: (res[product_code]?.qty || 0) + qty,
            },
          };
        },
        res_item
      );
      return res_item_promotion;
    },
    {}
  );
  return {
    all_items,
    allow_item: itemByKey[activeKey]?.allow_item,
    allow_renew: itemByKey[activeKey]?.allow_renew,
  };
};

function warning(description) {
  notification.warning({
    message: "ไม่สามารถดำเนินการได้",
    description,
  });
}

export function checkFreeAddedDisableItem(state, payload) {
  // in this case source is already in used qty so don't check will used
  const { free, add_qty } = payload;
  if (free[0].source_key !== "item") return false;
  const { all_items, allow_item, allow_renew } = getDataCheckStock(
    state.orders
  );
  const banned = _.some(
    free,
    (n) =>
      (n.product_type !== fixProductType.renew && allow_item === false) ||
      (n.product_type === fixProductType.renew && allow_renew === false)
  );
  if (banned) {
    warning("นักธุกิจไม่สามารถซื้อสินค้าบางรายการ");
    return true;
  }
  const sold_out = _.some(free, (n) => {
    let sold_out = false;
    if (
      !_.includes(
        [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
        n.product_type_set
      )
    ) {
      sold_out = !n.stock_balance;
      if (!sold_out) {
        const used_qty = all_items[n.product_code]?.qty || 0;
        sold_out = n.stock_balance < add_qty + used_qty;
      }
    }
    return sold_out;
  });
  if (sold_out) {
    warning("ไม่มีสินค้าที่เลือกบางรายการ (sold out)");
    return true;
  }
  return false;
}

export function checkItemAddedDisableItem(state, payload) {
  const { all_items, allow_item, allow_renew } = getDataCheckStock(
    state.orders
  );
  const banned =
    (payload.product_type !== fixProductType.renew && allow_item === false) ||
    (payload.product_type === fixProductType.renew && allow_renew === false);
  if (banned) {
    warning("นักธุกิจไม่สามารถซื้อสินค้าที่เลือกได้");
    return true;
  }
  let sold_out = false;
  if (
    payload.check_stock &&
    !_.includes(
      [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
      payload.product_type_set
    )
  ) {
    sold_out = !payload.stock_balance;
    if (!sold_out) {
      const used_qty = all_items[payload.product_code]?.qty || 0;
      sold_out = payload.stock_balance < 1 + used_qty;
    }
  }
  if (sold_out) {
    warning("ไม่มีสินค้าที่เลือก (sold out)");
    return true;
  }
  return false;
}
