import { Col, Form, Row, Input } from "antd";
import React from "react";

const Contact = ({ form: { getFieldDecorator }, data }) => {
  return (
    <Form>
      <Row>
        <Col md={24}>
          <label className="mb-0">ชื่อ</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("name", {
              initialValue: data.name,
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุชื่อ",
                },
              ],
            })(<Input autoComplete="off" placeholder="ชื่อ" />)}
          </Form.Item>
        </Col>
        <Col md={24}>
          <label className="mb-0">อีเมล</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("email", {
              initialValue: data.email,
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุอีเมล",
                },
              ],
            })(<Input autoComplete="off" placeholder="อีเมล" />)}
          </Form.Item>
        </Col>
        <Col md={24}>
          <label className="mb-0">เบอร์โทรศัพท์</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("phone", {
              initialValue: data.phone,
            })(<Input autoComplete="off" placeholder="เบอร์โทรศัพท์" />)}
          </Form.Item>
        </Col>
        <Col md={24}>
          <label className="mb-0">เบอร์มือถือ</label>
          <Form.Item className="mb-0">
            {getFieldDecorator("mobile", {
              initialValue: data.mobile,
              rules: [
                {
                  required: true,
                  message: "กรุณาระบุเบอร์มือถือ",
                },
              ],
            })(<Input autoComplete="off" placeholder="เบอร์มือถือ" />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create()(Contact);
