/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Modal, Pagination, notification } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaEye, FaCodepen } from "react-icons/fa";
import PrintButton from "../../../components/PrintButton";
import { useHttp } from "../../../hooks/http";
import { getUserObj, URL_API, _, axios, getToken } from "../../../utils";
import BillPreview from "../pos/components/BillPreview";
import PreviewModal from "./components/PreviewModal";
import Table from "./components/Table";
import ArrangeContext from "./reducer";
import { printform } from "../../../utils/printforms";
import { resCatchModal } from "../../util/helper";

const Arrange = ({ status = "" }) => {
  const [preview, setPreview] = useState(false);
  const [billSelectedId, setBillSelectedId] = useState(null);
  const current_date = moment().startOf("day");
  const user = getUserObj();
  const {
    state: {
      bill,
      search: {
        page,
        limit,
        bill_branch_code,
        source,
        date_from,
        date_to,
        query,
      },
    },
    dispatch,
  } = useContext(ArrangeContext);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/arrange`,
      token: true,
      params: {
        query,
        limit,
        source,
        status,
        bill_branch_code,
        offset: limit * (page - 1),
        date_from: date_from && moment(date_from).toDate(),
        date_to: date_to && moment(date_to).toDate(),
        branch_code: user.branch_code,
      },
    },
    [
      limit,
      query,
      status,
      page,
      date_from,
      date_to,
      source,
      bill_branch_code,
      user.branch_code,
      user.branch_name,
    ]
  );
  let columns = [
    {
      title: "",
      key: "drop_down",
    },
    {
      title: "เลขที่ใบจัด",
      key: "document_no",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "เลขที่บิล",
      key: "ref_document_no",
      dataIndex: "ref_document_no",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: status === "pending" ? "วันที่จัดสินค้า" : "วันที่จ่ายสินค้า",
      key: status === "pending" ? "document_date" : "complete_date",
      dataIndex: status === "pending" ? "document_date" : "complete_date",
      render: (text) => (
        <p className="m-0 text-left">
          {text && moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "วันที่สั่งซื้อ",
      key: "ref_document_date",
      dataIndex: "ref_document_date",
      render: (text) => (
        <p className="m-0 text-left">
          {text && moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "พนักงานจัดสินค้า",
      key: "create_by",
      dataIndex: "create_by",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "พนักงานจ่ายสินค้า",
      key: "staff",
      dataIndex: "staff",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ผู้ดำเนินการ",
      key: "buyer_name",
      dataIndex: "buyer_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ผู้สั่งซื้อ",
      key: "partner_name",
      dataIndex: "partner_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "แหล่งที่มา",
      key: "bill_branch_name",
      dataIndex: "bill_branch_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "จำนวน",
      key: "qty",
      dataIndex: "qty",
      render: (text) => <p className="m-0 text-right">{text}</p>,
    },
    {
      title: "จำนวนที่จ่าย",
      key: "qty_delivery",
      dataIndex: "qty_delivery",
      render: (text) => <p className="m-0 text-right">{text}</p>,
    },
    {
      title: "จำนวนค้างจ่าย",
      key: "qty_remain",
      dataIndex: "qty_remain",
      render: (text) => <p className="m-0 text-right">{text}</p>,
    },
    {
      title: "จำนวนวันค้างจ่าย",
      key: "date_remain",
      render: (text, { ref_document_date }) => {
        const date_remain = moment(ref_document_date);
        const day_leave = current_date.diff(date_remain, "days");
        return <p className="m-0 text-right">{day_leave} </p>;
      },
    },
    {
      title: "สถานะใบเสร็จ",
      key: "document_status_text",
      render: (_text, { document_status_text }) => (
        <p className="m-0 text-center">{document_status_text}</p>
      ),
    },
    {
      title: "ประเภท",
      key: "sales_type",
      dataIndex: "sales_type",
      render: (text) => (
        <p className="m-0 text-center">
          {text === "sale"
            ? "ขาย"
            : text === "redeem"
            ? "แลก"
            : text === "requisition"
            ? "ใบเบิกสินค้า"
            : text === "claim"
            ? "เคลม"
            : `${text}`}
        </p>
      ),
    },
    {
      title: "รับสินค้า",
      key: "receipt_type",
      render: (text, record) => (
        <p className="m-0 text-center">
          {record?.receipt_branch_code === record?.transport_code &&
          record?.document_type === "bill"
            ? "จัดส่ง"
            : "รับที่สาขา"}
        </p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_text, record) => (
        <p className="m-0 d-flex justify-content-end align-items-center">
          {record?.document_type === "bill" &&
            record?.receipt_branch_code === record?.transport_code && (
              <Button
                shape="circle"
                size="small"
                className="mr-2 text-white"
                style={{ backgroundColor: "#026E9C", borderColor: "#026E9C" }}
                onClick={() => {
                  printform("delivery", record);
                }}
              >
                <FaCodepen />
              </Button>
            )}
          {record?.document_type === "bill" && (
            <Button
              shape="circle"
              size="small"
              type="primary"
              className="mr-2"
              disabled={record?.document_type !== "bill"}
              onClick={() => {
                setBillSelectedId(record.bill_id);
                setPreview(true);
              }}
            >
              <FaEye />
            </Button>
          )}
          {/* <PrintButton
            record={
              record.document_type === "bill"
                ? { ...record, document_no: record.ref_document_no }
                : {}
            }
          /> */}
          <PrintButton
            record={{ ...record, document_no: record.ref_document_no }}
            reportName={
              record?.document_type === "requisition"
                ? "requisition"
                : "receipt"
            }
          />
          {
            !(
              record?.receipt_branch_code == record?.transport_code &&
              record?.document_type === "bill"
            ) && (
              <Button
                size="small"
                type="primary"
                className="ml-3"
                style={{
                  backgroundColor:
                    record.arrange_cancel_status === 2 ? "#ccc" : "#FF7F27",
                  border: "none",
                }}
                disabled={record.arrange_cancel_status === 2}
                onClick={() =>
                  dispatch({
                    type: "setState",
                    name: "previewSummaryDocumentNo",
                    payload: record.document_no,
                  })
                }
              >
                {status === "complete" ? "รายละเอียด" : "พร้อมจ่าย"}
              </Button>
            )}
          {current_date.diff(moment(record.ref_document_date), "days") >= 45 &&
            status == "pending" && (
              <Button
                size="small"
                type="danger"
                className="ml-2"
                disabled={record.arrange_cancel_status === 2}
                onClick={() => ReturnToWarehouse(record)}
              >
                ส่งสินค้า 45 วัน กลับคลัง
              </Button>
            )}
        </p>
      ),
    },
  ];
  if (status === "pending") {
    columns = _.filter(columns, (n) => n.key !== "staff");
  }
  if (status === "complete") {
    columns = _.filter(columns, (n) => n.key !== "date_remain");
  }
  const ReturnToWarehouse = (record) => {
    let { document_type, document_no } = record;
    let source_cancel = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการส่งกลับคลังสินค้าหรือไม่?",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/arrange/${document_type}/to-warehouse`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              document_no,
            },
          })
            .then(() => {
              resolve();
              reload();
              notification.success({ message: "ส่งกลับคลังสินค้าสำเร็จ" });
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source_cancel.cancel("cancel");
      },
    });
  };
  useEffect(() => {
    if (
      bill.document_no &&
      !_.includes(["pending", "to-warehouse", "complete"], bill.status)
    ) {
      dispatch({ type: "clearCreate" });
      Modal.error({
        title: "ไม่สามารถดำเนินการได้",
        content: "สถานะเอกสารไม่ถูกต้อง",
        zIndex: 1080,
      });
    }
  }, [bill.document_no, bill.status]);

  if (error) return <Alert type="error" showIcon message={error} />;
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.data || []}
      />

      <Pagination
        pageSize={limit}
        current={page}
        total={data?.total || 0}
        className="text-center mt-3"
        hideOnSinglePage={true}
        showLessItems
        onChange={(page) =>
          dispatch({
            type: "setGlobalState",
            name: "search",
            payload: { page },
          })
        }
      />

      <PreviewModal
        withQrModal
        withAuthority
        reload={reload}
        confimPermission={data?.role || user.department_id == 1}
        hideAddAuthority={status === "complete"}
      />

      <BillPreview
        id={billSelectedId}
        preview={preview}
        setPreview={setPreview}
      />
    </>
  );
};

export default Arrange;
