import React from "react";
import { Switch, Route } from "react-router-dom";

import Error404 from "../../Error404";

import SetList from "./SetList";
import SetForm from "./SetForm";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} children={<SetList />} />
      <Route exact path={`${match.path}/:id/:type?`} children={<SetForm />} />
      <Route component={Error404} />
    </Switch>
  );
};
