/* eslint-disable no-unused-vars */
import { Button, Col, Form, Row, Typography, Popconfirm } from "antd";
import React, { forwardRef } from "react";
import { FaTrash } from "react-icons/fa";
import UploadPreview from "./UploadPreview";

const FormBanner = ({ form, handleDelete }, ref) => {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.file;
  };

  const { getFieldDecorator, getFieldsValue } = form;

  return (
    <Form colon={false}>
      <Row gutter={{ xs: 16, md: 32 }}>
        <Col md={24}>
            <Row type="flex" justify="space-between" className="mt-3 mb-1">
              <Col span={8}>
                <Typography.Text>Website: 2750 x 738 px</Typography.Text>
              </Col>
          {/* {getFieldsValue().online_banner && (
              <Col span={8} align="right">
                <Popconfirm
                  title="ต้องการลบรูปหรือไม่"
                  okText="ยืนยัน"
                  cancelText="ยกเลิก"
                  onConfirm={() => handleDelete("web")}
                >
                  <Button type="danger" ghost>
                    <FaTrash />
                  </Button>
                </Popconfirm>
              </Col>
          )} */}
            </Row>
          <Form.Item>
            {getFieldDecorator("online_banner", {
              valuePropName: "file",
              getValueFromEvent: normFile,
            })(<UploadPreview />)}
          </Form.Item>
            <Row type="flex" justify="space-between" className="mt-3 mb-1">
              <Col span={8}>
                <Typography.Text>Mobile: 1200 x 401 px</Typography.Text>
              </Col>
          {/* {getFieldsValue().mobile_banner && (
              <Col span={8} align="right">
                <Popconfirm
                  title="ต้องการลบรูปหรือไม่"
                  okText="ยืนยัน"
                  cancelText="ยกเลิก"
                  onConfirm={() => handleDelete("mobile")}
                >
                  <Button type="danger" ghost>
                    <FaTrash />
                  </Button>
                </Popconfirm>
              </Col>
          )} */}
            </Row>
          <Form.Item>
            {getFieldDecorator("mobile_banner", {
              valuePropName: "file",
              getValueFromEvent: normFile,
            })(<UploadPreview />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create("banner")(forwardRef(FormBanner));
