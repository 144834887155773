/* eslint-disable no-unused-vars */
import { Col, Input, Row, Typography, Checkbox } from "antd";
import React from "react";
import { _ } from "../../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { numInt } from "../../../../util/helper/map-data";
const columns = [
  "",
  "รหัสสินค้า",
  "ชื่อสินค้า",
  "ราคา/หน่วย",
  "คะแนน/หน่วย",
  "จำนวน",
  "เงินรวม",
  "คะแนนรวม",
  "",
];
const BillItemTable = () => {
  const bill_item = useSelector((state) => state.creditNote.billProduct);
  function onChange(checkedValues) {
  }
  return (
    <table className="w-100">
      <thead>
        <tr>
          {_.map(columns, (n, i) => (
            <td key={i} className="text-center">
              {n}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {bill_item.itemKeys?.map((n, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td className="text-center">{n.product_code}</td>
            <td>{n.product_name}</td>
            <td className="text-center">{numInt(n.unit_price)}</td>
            <td className="text-center">{numInt(n.pv)}</td>
            <td className="text-center">{numInt(n.qty)}</td>
            <td className="text-center">{numInt(n.unit_price * n.qty)}</td>
            <td className="text-center">{numInt(n.pv * n.qty)}</td>
            <td>
              <Checkbox value={n.id} onChange={onChange}></Checkbox>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default BillItemTable;
