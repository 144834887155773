/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionMenu } from "../redux/actions/menu-action";
import { getUserObj, _ } from "../utils";

const defaultRoute = ["/", "/public", "/dashboard"];
const fixCustomPath = ["/setting/master/product-request"];
const fixLevelCustomPath = {
  claim: 1,
  pos: 1,
  requisition: 1,
  "transfer-receive": 2,
};

const validateMenu = (menus, item_path_list, level) =>
  _.some(menus, (n) => {
    if (_.includes(fixCustomPath, n.path)) return false;
    if (n.children) return validateMenu(n.children, item_path_list, level + 1);
    return _.every(
      _.slice(_.drop(_.split(n.path, "/")), 0, level),
      (menu_path, i) => menu_path === item_path_list[i]
    );
  });

const validate = (menus, current_path) => {
  const item_path_list = _.drop(_.split(current_path, "/"));
  if (_.includes(fixCustomPath, current_path))
    return _.some(menus, (n) => n.path === current_path);
  else if (fixLevelCustomPath[item_path_list[0]]) {
    const dynamic_length = fixLevelCustomPath[item_path_list[0]];
    return _.some(menus, (n) => {
      if (n.path === "/") return false;
      return _.every(
        _.slice(_.drop(_.split(n.path, "/")), 0, dynamic_length),
        (menu_path, i) => menu_path === item_path_list[i]
      );
    });
  }
  return validateMenu(menus, item_path_list, 1);
};

export const useMunuGuard = () => {
  const dispatch = useDispatch();
  const user = getUserObj();
  const history = useHistory();
  const user_id = useSelector((state) => state.menu.user_id);
  const list = useSelector((state) => state.menu.list);
  const menus = useSelector((state) => state.menu.menus);
  const curret_path = window.location.pathname;

  useEffect(() => {
    if (!user.user_id) return;
    if (user.user_id !== user_id) {
      if (curret_path !== "/") dispatch(actionMenu.loadMenu());
    } else if (user_id !== null) {
      if (_.includes(defaultRoute, curret_path)) {
        dispatch(actionMenu.setPermission(true));
        return;
      }

      const is_menu_path = validate(list, curret_path);
      if (!is_menu_path) {
        dispatch(actionMenu.setPermission(true));
        return;
      }

      const permission = validate(menus, curret_path);
      dispatch(actionMenu.setPermission(permission));
      if (permission) return;

      history.replace({ pathname: "/dashboard" });
      Modal.error({
        title: "Unauthorized",
        content: "ผู้ใช้งานไม่มีสิทธิเข้าถึงเมนูนี้",
        okText: "ตกลง",
        zIndex: 1080,
      });
    }
  }, [curret_path, user.user_id, user_id]);
};
