import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  TimePicker,
  Typography,
} from "antd";
import { range } from "lodash-es";
import numeral from "numeral";
import React, { useContext, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import SelectBank from "../../../../../../components/SelectBank";
import SelectPaymentType from "../../../../../../components/SelectPaymentType";
import TextValidation from "../../../../../../components/TextValidation";
import { axios, getToken, moment, URL_API, _ } from "../../../../../../utils";
import { resCatchModal } from "../../../../../util/helper";
import PreOrderContext from "../../../reducer";
import CreditCardPayment from "../../../../../../components/CreditCardPayment";
import UploadBtn from "../../../../reserve/components/PaymentItem/UploadBtn";

const { Text } = Typography;

const disabledDateFn = (current) => current && current > moment();
const disabledHoursFn = () => range(+moment().format("HH") + 1, 24);
const disabledMinutesFn = () => range(+moment().format("mm") + 1, 60);

const Item = ({
  data,
  disabled,
  reserveRedeem,
  reloadPayment,
  balance,
  buyerCode,
  buyerType,
}) => {
  const {
    state: {
      kBankCustomerId,
      paymentGateWay,
      billModal: {
        id,
        reserve_no,
        reserve_order_no,
        partner_code,
        status,
        rp_topup,
      },
    },
    dispatch,
  } = useContext(PreOrderContext);
  const [errors, setErrors] = useState({});
  const [disabledMinutes, setDisabledMinutes] = useState(true);
  const [disabledHours, setDisabledHours] = useState(true);
  const isDeposit = data.deposit === 1;

  const handleChangeDate = (key, value) => {
    dispatch({ type: "SET_PAYMENT", payload: { key, payment_date: value } });
    const date_res = moment(value).format("DD") === moment().format("DD");
    setDisabledMinutes(date_res);
    setDisabledHours(date_res);
  };

  const handleChangeTime = (e) => {
    const { key } = data;
    if (e) {
      const hour_res = moment(e).format("HH") === moment().format("HH");
      setDisabledMinutes(disabledHours && hour_res);
      const payment_date = moment(data.payment_date).format("YYYY-MM-DD");
      const payment_time = moment(e).format("HH:mm");
      const value = `${payment_date} ${payment_time}`;
      dispatch({ type: "SET_PAYMENT", payload: { key, payment_date: value } });
    } else {
      dispatch({ type: "SET_PAYMENT", payload: { key, payment_date: e } });
    }
  };

  const unCommit = () => {
    Modal.confirm({
      title: "ยืนยันแก้ไขข้อมูล",
      content: reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch({
          type: "SET_PAYMENT",
          payload: {
            key: data.key,
            commit: false,
          },
        });
      },
    });
  };

  const validation = () => {
    let err = {};
    let notEmpty = ["payment_date", "payment_type", "price"];
    if (!reserveRedeem) {
      notEmpty = [...notEmpty, "bank"];
    }
    for (let name in data) {
      if (notEmpty.includes(name)) {
        if (data.payment_type !== "Cash") {
          if (data[name] === null || data[name] === 0) {
            err[name] = "*กรุณากรอกข้อมูล";
          }
        } else if (name !== "bank") {
          if (data[name] === null || data[name] === 0) {
            err[name] = "*กรุณากรอกข้อมูล";
          }
        }
        if (name === "price") {
          if (balance < 0) {
            err[name] = `*จำนวน${reserveRedeem ? "คะแนน" : "เงิน"}ไม่ถูกต้อง`;
          }
        }
      }
    }
    if (reserveRedeem) {
      if (data.price > rp_topup && !err.price) {
        err.price = "*จำนวนคะแนนไม่พอ";
      }
    }
    setErrors(err);
    for (let name in data) {
      if (err[name]) {
        return true;
      }
    }
    return false;
  };

  const commit = () => {
    const invalid = validation();
    if (!invalid) {
      let source = axios.CancelToken.source();
      Modal.confirm({
        title: "ยืนยันบันทึกข้อมูล",
        content: reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        zIndex: 1080,
        onOk() {
          return new Promise((resolve, reject) => {
            axios({
              method: "post",
              url: `${URL_API}/pos/operation/reserve/payment`,
              data: {
                id: data.id,
                process: data.id ? "update" : "create",
                bank: data.bank,
                payment_date: data.payment_date,
                payment_type: data.payment_type,
                price: data.price,
                ref_no: data.ref_no,
                reserve_no,
                reward_id: data.reward_id,
              },
              headers: { Authorization: "Bearer " + getToken() },
              cancelToken: source.token,
            })
              .then(() => {
                dispatch({
                  type: "SET_PAYMENT",
                  payload: { key: data.key, commit: true },
                });
                reloadPayment();
                notification.success({
                  message: "Success",
                  description: "บันทึกรายการเรียบร้อย",
                  placement: "topRight",
                });
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
          }).catch((e) => {
            resCatchModal(e);
          });
        },
        onCancel() {
          source.cancel("cancel");
        },
      });
    }
  };

  const deletePayment = () => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันลบข้อมูล",
      content: reserveRedeem ? "รายการหักคะแนน" : "รายการชำระเงิน",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/pos/operation/reserve/payment`,
            data: {
              id: data.id,
              process: "delete",
              payment_type: data.payment_type,
              reward_id: data.reward_id,
            },
            headers: { Authorization: "Bearer " + getToken() },
            cancelToken: source.token,
          })
            .then(() => {
              reloadPayment();
              notification.success({
                message: "Success",
                description: "บันทึกรายการเรียบร้อย",
                placement: "topRight",
              });
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };
  return (
    <Row gutter={8}>
      <Col span={3} className="font-size-12 text-center">
        {disabled || data.commit ? (
          <Text className="mr-2">
            {moment(data.payment_date).format("DD/MM/YYYY")}
          </Text>
        ) : (
          <DatePicker
            size="small"
            format="DD/MM/YYYY"
            className="w-100"
            placeholder=""
            popupStyle={{ zIndex: "1200", fontSize: "12px" }}
            value={data.payment_date ? moment(data.payment_date) : ""}
            onChange={(e) => handleChangeDate(data.key, e)}
            style={{ fontSize: "12px" }}
            showToday={false}
            disabledDate={disabledDateFn}
          />
        )}
        <TextValidation validate={errors.payment_date} />
      </Col>
      <Col span={2}>
        {disabled || data.commit ? (
          <Text>{moment(data.payment_date).format("HH:mm")}</Text>
        ) : (
          <TimePicker
            size="small"
            format="HH:mm"
            className="w-100"
            placeholder=""
            popupStyle={{ zIndex: "1200", fontSize: "12px" }}
            value={data.payment_date ? moment(data.payment_date) : ""}
            disabled={!data.payment_date}
            onChange={handleChangeTime}
            style={{ fontSize: "12px" }}
            disabledHours={disabledHours ? disabledHoursFn : undefined}
            disabledMinutes={disabledMinutes ? disabledMinutesFn : undefined}
          />
        )}
      </Col>
      <Col span={3} className="font-size-12 text-center">
        {disabled || data.commit || reserveRedeem ? (
          <Text> {data.payment_type_name || "-"}</Text>
        ) : (
          <SelectPaymentType
            size="small"
            style={{ fontSize: "12px" }}
            dropdownStyle={{ zIndex: "1200", fontSize: "12px" }}
            value={data.payment_type}
            onChange={(e, option) =>
              dispatch({
                type: "SET_PAYMENT",
                payload: {
                  key: data.key,
                  payment_type: e,
                  payment_type_name: option.props.children,
                },
              })
            }
          />
        )}
        <TextValidation validate={errors.payment_type} />
      </Col>
      <Col span={5} className="font-size-12 text-center">
        {disabled || data.commit || reserveRedeem ? (
          <Text> {data.bank_name || "-"}</Text>
        ) : (
          <SelectBank
            showSearch
            disabled={data.payment_type === "Cash"}
            withCashCard={false}
            size="small"
            dropdownStyle={{ zIndex: "1200", fontSize: "12px" }}
            value={data.bank_name}
            withIcon={true}
            onChange={(e, option) =>
              dispatch({
                type: "SET_PAYMENT",
                payload: {
                  key: data.key,
                  bank: e,
                  bank_name: option.props.source.name_th,
                },
              })
            }
            style={{ fontSize: "12px" }}
            filterOption={(input, option) =>
              option.props.source.name_th.indexOf(input.toLowerCase()) >= 0
            }
          />
        )}
        <TextValidation validate={errors.bank} />
      </Col>
      <Col span={4} className="font-size-12 text-center">
        {disabled || data.commit || reserveRedeem ? (
          <Text> {data.ref_no || "-"}</Text>
        ) : (
          <Input
            size="small"
            className="font-size-12"
            disabled={data.payment_type === "Cash"}
            value={data.ref_no}
            onChange={(e) =>
              dispatch({
                type: "SET_PAYMENT",
                payload: {
                  key: data.key,
                  ref_no: e.target.value,
                },
              })
            }
          />
        )}
      </Col>
      <Col span={4} className="font-size-12 text-center">
        {disabled || data.commit ? (
          <Text>{numeral(data.price).format("0,0.00")}</Text>
        ) : (
          <InputNumber
            size="small"
            min={0}
            step={1}
            className="w-100 font-size-12"
            value={data.price}
            onChange={(e) =>
              dispatch({
                type: "SET_PAYMENT",
                payload: {
                  key: data.key,
                  price: e,
                },
              })
            }
            onFocus={(e) => e.target.select()}
            onKeyUp={(e) => e.key === "Enter" && commit()}
            formatter={(value) => numeral(value).format("0,0")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        )}
        <TextValidation validate={errors.price} />
      </Col>
      <Col span={3} className="text-right">
        {!disabled ? (
          <>
            {!!data.ref_payment_code ? (
              <FaCheck size={16} className="text-pv" />
            ) : data.commit ? (
              <>
                {_.includes(["CC", "DC", "INS"], data.payment_type) ? (
                  <CreditCardPayment
                    documentNo={reserve_no}
                    buyerCode={buyerCode}
                    buyerType={buyerType}
                    price={data.price}
                    paymentType={data.payment_type}
                    kBankCustomerId={kBankCustomerId}
                    paymentGateWay={paymentGateWay}
                    redirectUrl={`${window.location.origin}/pre-order/payment-${data.id}-id-reserve_no-${reserve_no}-ern-reserve_order_no-${reserve_order_no}-eron-partner_code-${partner_code}-epc-status-${status}-es`}
                    paymentUrl={`${window.location.origin}/pre-order/reserve_no-${reserve_no}-ern-reserve_order_no-${reserve_order_no}-eron-partner_code-${partner_code}-epc-status-${status}-es?reserve-id=${id}`}
                  />
                ) : (
                  _.includes(
                    [
                      "TP",
                      "QRCR",
                      "QR",
                      "LP",
                      "RC",
                      "Other",
                      "QR (Omise)",
                      "Credit (Omise)",
                    ],
                    data.payment_type
                  ) && <UploadBtn refId={data.id} />
                )}
                <MdEdit
                  size={16}
                  className={`${
                    isDeposit ? "cursor-no-drop text-gray-50" : "cursor-pointer"
                  } mr-2`}
                  onClick={!isDeposit ? unCommit : undefined}
                />
                <MdDelete
                  size={16}
                  className={
                    isDeposit ? "cursor-no-drop text-gray-50" : "cursor-pointer"
                  }
                  onClick={!isDeposit ? deletePayment : undefined}
                />
              </>
            ) : (
              <>
                <Button type="primary" size="small" onClick={commit}>
                  <small>บันทึก</small>
                </Button>
                <Button
                  size="small"
                  className="bg-nn text-nn border-nn text-white ml-2"
                  onClick={reloadPayment}
                >
                  <small>ยกเลิก</small>
                </Button>
              </>
            )}
          </>
        ) : (
          _.includes(
            [
              "TP",
              "QRCR",
              "QR",
              "LP",
              "RC",
              "Other",
              "QR (Omise)",
              "Credit (Omise)",
            ],
            data.payment_type
          ) && (
            <UploadBtn
              refId={data.id}
              disabled={disabled}
              hideOnDisable={true}
            />
          )
        )}
      </Col>
    </Row>
  );
};

export default Item;
