import { useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const fitStudioService = {
  async getBannerByType({ type }) {
    try {
      const res = await fitStudioRequest.get('/banner', {
        params: {
          type,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getBannerById(id) {
    try {
      const res = await fitStudioRequest.get(`/banner/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createBanner(data) {
    try {
      const res = await fitStudioRequest.post(`/banner`, data);
      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editBanner({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(`/banner/${id}`, data);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteBanner(id) {
    try {
      const res = await fitStudioRequest.delete(`/banner/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async setBannerEnable({ id, isEnable }) {
    try {
      const res = await fitStudioRequest.patch(`/banner/${id}/enable`, {
        isEnable,
      });
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getAuthscreenById(id) {
    try {
      const res = await fitStudioRequest.get(`/authscreen/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editAuthscreenById({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(`/authscreen/${id}`, data);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getAllNewsCategory() {
    try {
      const res = await fitStudioRequest.get(`/news-category`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getNewsCategoryById(id) {
    try {
      const res = await fitStudioRequest.get(`/news-category/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createNewsCategory(data) {
    try {
      const res = await fitStudioRequest.post(`/news-category`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editNewsCategory({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(`/news-category/${id}`, data);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getNewsByNewsCategoryId({ newsCategoryId, page, take }) {
    try {
      const res = await fitStudioRequest.get(`/news`, {
        params: {
          newsCategoryId,
          page,
          take,
          order: 'DESC',
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async setNewsCategoryEnable({ id, isEnable }) {
    try {
      const res = await fitStudioRequest.patch(`/news-category/${id}/enable`, {
        isEnable,
      });

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getNewsById(id) {
    try {
      const res = await fitStudioRequest.get(`/news/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async setNewsEnable({ id, isEnable }) {
    try {
      const res = await fitStudioRequest.patch(`/news/${id}/enable`, {
        isEnable,
      });

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createNews(data) {
    try {
      const res = await fitStudioRequest.post(`/news`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editNews({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(`/news/${id}`, data);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteNews(id) {
    try {
      const res = await fitStudioRequest.delete(`/news/${id}`);

      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getFitnessBranchFromMasterBranch() {
    try {
      const res = await fitStudioRequest.get(
        `/fitness-branch/fitness-branch-from-branch-master`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getAllFitnessBranch() {
    try {
      const res = await fitStudioRequest.get(`/fitness-branch`, {
        params: {
          skip: 0,
          take: -1,
        },
      });

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getFitnessBranchFromMasterBranchId(id) {
    try {
      const res = await fitStudioRequest.get(
        `/fitness-branch/branch-master/${id}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async insertFitnessBranch(data) {
    try {
      const res = await fitStudioRequest.post(`/fitness-branch`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getFitnessTrainersByFitnessBranchId(id) {
    try {
      const res = await fitStudioRequest.get(
        `fitness-branch/${id}/fitness-trainer`
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getFitnessTrainerById(id) {
    try {
      const res = await fitStudioRequest.get(`fitness-trainer/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createTrainer({ branchId, data }) {
    try {
      const res = await fitStudioRequest.post(
        `/fitness-branch/${branchId}/fitness-trainer`,
        data
      );
      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editTrainer({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(`fitness-trainer/${id}`, data);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteTrainer(id) {
    try {
      const res = await fitStudioRequest.delete(`fitness-trainer/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getAllClassCategory() {
    try {
      const res = await fitStudioRequest.get(`/fitness-class-category`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getClassCategoryById(id) {
    try {
      const res = await fitStudioRequest.get(`/fitness-class-category/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createClassCategory(data) {
    try {
      const res = await fitStudioRequest.post(`/fitness-class-category`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editClassCategory({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-class-category/${id}`,
        data
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async setClassCategoryEnable({ id, isEnable }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-class-category/${id}/enable`,
        {
          isEnable,
        }
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteClassCategory({ id }) {
    try {
      const res = await fitStudioRequest.delete(
        `/fitness-class-category/${id}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getAllReward() {
    try {
      const res = await fitStudioRequest.get(`/fitness-reward`, {
        params: {
          page: 1,
          take: -1,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  useQueryGetAllReward({ take, page, endDate }, onSuccess) {
    return useQuery(
      ['get-all-rewards', { take, page, endDate }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/fitness-reward`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              ...(queryKey[1].endDate && { endDate: queryKey[1].endDate }),
            },
          });

          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  async getRewardById(id) {
    try {
      const res = await fitStudioRequest.get(`/fitness-reward/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createReward(data) {
    try {
      const res = await fitStudioRequest.post(`/fitness-reward`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editReward({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(`/fitness-reward/${id}`, data);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteReward({ id }) {
    try {
      console.log(id);
      const res = await fitStudioRequest.delete(`/fitness-reward/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getAllClass() {
    try {
      const res = await fitStudioRequest.get(`/fitness-class`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getClassByCategoryId({ categoryId, skip, take }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-class`, {
        params: {
          categoryId,
          skip,
          take,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getClassById(id) {
    try {
      const res = await fitStudioRequest.get(`/fitness-class/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createClass(data) {
    try {
      const res = await fitStudioRequest.post(`/fitness-class`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editClass({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(`/fitness-class/${id}`, data);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async setClassEnable({ id, isEnable }) {
    try {
      const res = await fitStudioRequest.patch(`/fitness-class/${id}/enable`, {
        isEnable,
      });

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteClass({ id }) {
    try {
      const res = await fitStudioRequest.delete(`/fitness-class/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getClassScheduleByClassId({ classId, page, take }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-class-schedule`, {
        params: {
          classId,
          page,
          take,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getClassScheduleById(id) {
    try {
      const res = await fitStudioRequest.get(`/fitness-class-schedule/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createClassSchedule(data) {
    try {
      const res = await fitStudioRequest.post(`/fitness-class-schedule`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editClassSchedule({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-class-schedule/${id}`,
        data
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteClassSchedule({ id }) {
    try {
      const res = await fitStudioRequest.delete(
        `/fitness-class-Schedule/${id}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async uploadFile(formData) {
    try {
      if (formData) {
        const res = await fitStudioRequest.post(`/azure/image`, formData);

        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async uploadDoc(formData) {
    try {
      if (formData) {
        const res = await fitStudioRequest.post(`/azure/doc`, formData);

        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getClassReservationByClassScheduleId({ classScheduleId, page, take }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-class-reservation`, {
        params: {
          classScheduleId,
          page,
          take,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async downloadFitnessClassReservationExcelByFitnessClassReservationId(
    classScheduleId
  ) {
    try {
      const res = await fitStudioRequest.get(
        `/fitness-class-reservation/download-excel/${classScheduleId}`,
        {
          responseType: 'blob',
        }
      );

      if (res.status === 200) {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'class-reservation.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async bookFitnessClassReservationByAdmin(data) {
    try {
      const res = await fitStudioRequest.post(
        `/fitness-class-reservation/book-class-reservation-by-admin`,
        data
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getUsers({ query, page, take }) {
    try {
      const res = await fitStudioRequest.get(`/users`, {
        params: {
          ...query,
          page,
          take,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteUser({ userId }) {
    try {
      const res = await fitStudioRequest.delete(
        `/users/admin-delete-user/${userId}`
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async undeleteUser({ userId }) {
    try {
      const res = await fitStudioRequest.delete(
        `/users/admin-undelete-user/${userId}`
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async changeUserPassword({ userId, password }) {
    try {
      const res = await fitStudioRequest.patch(`/users/admin/change-password`, {
        userId,
        password,
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getRegisterPapers({ query, page, take }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-register-paper`, {
        params: {
          ...query,
          page,
          take,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async updateRegisterPaper({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-register-paper/${id}`,
        data
      );
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async updateRegisterPaperNote({ id, note }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-register-paper/${id}/note`,
        {
          note,
        }
      );
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteRegisterPaper({ id }) {
    try {
      const res = await fitStudioRequest.delete(
        `/fitness-register-paper/${id}`
      );
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getRegisterPaperById(id) {
    try {
      const res = await fitStudioRequest.get(`/fitness-register-paper/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async downloadFitnessRegisterPaperById(id) {
    try {
      const res = await fitStudioRequest.get(
        `${
          process.env.REACT_APP_FIT_FORM_DOWNLOAD_URL_API ||
          'https://dev-rebornfitform.legacy.co.th'
        }/api/download/${id}`,
        {
          responseType: 'blob',
        }
      );

      if (res.status === 200) {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'register-paper.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async downloadFitnessRegisterPaperExcelByFilter({ query, page, take }) {
    try {
      const res = await fitStudioRequest.get(
        `/fitness-register-paper/download-excel/by-filter`,
        {
          params: {
            ...query,
            page,
            take,
          },
          responseType: 'blob',
        }
      );

      if (res.status === 200) {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'register-papers.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getTrainerRegisterPapers({ query, page, take }) {
    try {
      const res = await fitStudioRequest.get(`/trainer-register-paper`, {
        params: {
          ...query,
          page,
          take,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteTrainerRegisterPaper({ id }) {
    try {
      const res = await fitStudioRequest.delete(
        `/trainer-register-paper/${id}`
      );
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getTrainerRegisterPaperById(id) {
    try {
      const res = await fitStudioRequest.get(`/trainer-register-paper/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async downloadTrainerRegisterPaperById(id) {
    try {
      const res = await fitStudioRequest.get(
        `${
          process.env
            .REACT_APP_FIT_FORM_DOWNLOAD_URL_APIFIT_FORM_DOWNLOAD_URL_API ||
          'https://dev-rebornfitform.legacy.co.th'
        }/api/trainer_download/${id}`,
        {
          responseType: 'blob',
        }
      );

      if (res.status === 200) {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'register-paper.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async downloadTrainerRegisterPaperExcelByFilter({ query, page, take }) {
    try {
      const res = await fitStudioRequest.get(
        `/trainer-register-paper/download-excel/by-filter`,
        {
          params: {
            ...query,
            page,
            take,
          },
          responseType: 'blob',
        }
      );

      if (res.status === 200) {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'trainer-register-papers.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getPTRegisterPapers({ query, page, take }) {
    try {
      const res = await fitStudioRequest.get(`/pt-register-paper`, {
        params: {
          ...query,
          page,
          take,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deletePTRegisterPaper({ id }) {
    try {
      const res = await fitStudioRequest.delete(`/pt-register-paper/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getPTRegisterPaperById(id) {
    try {
      const res = await fitStudioRequest.get(`/pt-register-paper/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async downloadPTRegisterPaperById(id) {
    try {
      const res = await fitStudioRequest.get(
        `${
          process.env.REACT_APP_FIT_FORM_DOWNLOAD_URL_API ||
          'https://dev-rebornfitform.legacy.co.th'
        }/api/pt_download/${id}`,
        {
          responseType: 'blob',
        }
      );

      if (res.status === 200) {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'pt-register-paper.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async downloadPTRegisterPaperExcelByFilter({ query, page, take }) {
    try {
      const res = await fitStudioRequest.get(
        `/pt-register-paper/download-excel/by-filter`,
        {
          params: {
            ...query,
            page,
            take,
          },
          responseType: 'blob',
        }
      );

      if (res.status === 200) {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'register-papers.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getAllBodyFocusTag() {
    try {
      const res = await fitStudioRequest.get(`/fitness-user-video-tag`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getBodyFocusTagById({ tagId }) {
    try {
      const res = await fitStudioRequest.get(
        `/fitness-user-video-tag/${tagId}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getBodyFocusSubTagByTagId({ tagId }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-user-video-sub-tag`, {
        params: {
          tagId,
        },
      });

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getBodyFocusSubTagById({ subTagId }) {
    try {
      const res = await fitStudioRequest.get(
        `/fitness-user-video-sub-tag/${subTagId}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createSubTag(data) {
    try {
      const res = await fitStudioRequest.post(
        `/fitness-user-video-sub-tag`,
        data
      );

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editSubTag({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-user-video-sub-tag/${id}`,
        data
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteBodyFocusSubTagById({ subTagId }) {
    try {
      const res = await fitStudioRequest.delete(
        `/fitness-user-video-sub-tag/${subTagId}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getBodyFocusVideoBySubTagId({ subTagId, take, page }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-user-video`, {
        params: {
          subTagId,
          take,
          page,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getBodyFocusVideoById({ videoId }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-user-video/${videoId}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createBodyFocusVideo(data) {
    try {
      const res = await fitStudioRequest.post(`/fitness-user-video`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editBodyFocusVideo({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-user-video/${id}`,
        data
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteBodyFocusVideoById({ videoId }) {
    try {
      const res = await fitStudioRequest.delete(
        `/fitness-user-video/${videoId}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getPublicPlaylist({ page, take }) {
    try {
      const res = await fitStudioRequest.get(`/fitness-public-playlist`, {
        params: {
          take,
          page,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async getPublicPlaylistById(id) {
    try {
      const res = await fitStudioRequest.get(`/fitness-public-playlist/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async createPublicPlaylist(data) {
    try {
      const res = await fitStudioRequest.post(`/fitness-public-playlist`, data);

      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editPublicPlaylist({ id, data }) {
    try {
      const res = await fitStudioRequest.patch(
        `/fitness-public-playlist/${id}`,
        data
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deletePublicPlaylistById({ id }) {
    try {
      const res = await fitStudioRequest.delete(
        `/fitness-public-playlist/${id}`
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};

export default fitStudioService;
