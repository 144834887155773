/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Empty, notification, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";
import SelectBranch from "../../../pos/components/SelectBranch";
import AddProductModal from "./AddProductModal";
import SelectAddress from "../SelectAddress";
import ProductTable from "./ProductTable";
import ReceiptBranch from "./ReceiptBranch";
import ReceiptTransport from "./ReceiptTransport";
import SelectReqReceiptType from "./SelectReqReceiptType";
import { _ } from "../../../../../utils";
import { getStockBalance } from "../../../../../redux/thunk/claim";
import ValidateError from "../../../../../components/ValidateError";

const { Text } = Typography;

const { setGlobalState, setFormValue } = actionClaim;

const Index = () => {
  const dispatch = useDispatch();
  const req_receipt_type = useSelector(
    (state) => state.claim.receiptType.req_receipt_type
  );
  const receipt_branch_code = useSelector(
    (state) => state.claim.receiptType.receipt_branch_code
  );
  const noReceipt = useSelector((state) => {
    const { req_receipt_type, receipt_branch_code, ship_to } =
      state.claim.receiptType;
    if (!_.includes(["Branch", "Transport"], req_receipt_type)) return true;
    if (req_receipt_type === "Branch") {
      return !receipt_branch_code;
    }
    return !ship_to;
  });
  const { id: partnerId, type: partnerType } = useSelector(
    (state) => state.claim.partner
  );

  const isEmpty = useSelector(
    (state) => state.claim.claimOutProduct.itemKeys.length <= 0
  );

  const disabledProductCode = useSelector((state) => {
    const { itemKeys, itemByKey } = state.claim.claimOutProduct;
    return _.join(
      _.reduce(
        itemKeys,
        (res, key) => {
          return [...res, itemByKey[key].product_code];
        },
        []
      ),
      ","
    );
  });
  const locationValue = useSelector((state) => {
    const {
      isPreview,
      documentDetail: { status },
      receiptType: {
        req_receipt_type,
        receipt_branch_name,
        receipt_branch_code,
        ship_name,
        ship_to_id,
      },
    } = state.claim;
    if (!req_receipt_type) return;
    const disabled = isPreview || status !== "wait-manager";
    if (disabled) {
      if (req_receipt_type === "Branch") return receipt_branch_name;
      return ship_name;
    }
    if (req_receipt_type === "Branch") return receipt_branch_code;
    return ship_to_id;
  });
  const branch_or_address_error = useSelector((state) => {
    const { receipt_branch_code, ship_to_id } = state.claim.error;
    return receipt_branch_code || ship_to_id;
  });
  const loadingStock = useSelector(
    (state) => state.claim.fetch.stock_balance.loading
  );
  const disableAdd = useSelector(
    (state) =>
      state.claim.isPreview ||
      state.claim.documentDetail.status !== "wait-manager"
  );
  const disableReceipt = useSelector(
    (state) =>
      state.claim.isPreview ||
      !_.includes(
        ["wait-branch", "wait-manager"],
        state.claim.documentDetail.status
      )
  );

  useEffect(() => {
    if (req_receipt_type === "Transport" || !!receipt_branch_code)
      dispatch(
        getStockBalance({
          ids: disabledProductCode,
          req_receipt_type,
          receipt_branch_code,
        })
      );
  }, [req_receipt_type, receipt_branch_code]);

  return (
    <>
      <Row className="bg-gray-60">
        <Col
          span={24}
          className="d-flex justify-content-between align-items-center px-2 py-2"
        >
          <Text strong style={{ fontSize: "1.5em" }} className="text-white">
            สินค้าที่ได้รับ
          </Text>
          {!disableAdd && (
            <>
              <Button
                disabled={noReceipt}
                type="primary"
                className="width-150"
                onClick={() =>
                  dispatch(
                    setGlobalState({
                      name: "addProductModal",
                      value: { visible: true },
                    })
                  )
                }
              >
                <FaPlus className="mr-2" />
                เพิ่มสินค้า
              </Button>
              <AddProductModal />
            </>
          )}
        </Col>
      </Row>
      <Row className="bg-gray-20 mb-3">
        <Col span={24} className="px-4 py-2">
          {isEmpty ? (
            <Empty className="py-3" />
          ) : (
            <>
              <Row gutter={[8, 8]} type="flex">
                <Col
                  span={24}
                  xl={12}
                  xxl={{ span: 10, offset: 1 }}
                  className="d-flex flex-column"
                >
                  <SelectReqReceiptType disabled={disableReceipt} />
                  {!!req_receipt_type && (
                    <Row
                      gutter={[8, 8]}
                      type="flex"
                      className="align-items-center"
                    >
                      <Col span={6} className="text-right">
                        <Text strong className="text-nowrap">
                          {req_receipt_type === "Branch"
                            ? "สาขาที่รับ"
                            : "ที่อยู่"}
                        </Text>
                      </Col>
                      <Col span={18}>
                        {disableReceipt ? (
                          <Text>{locationValue}</Text>
                        ) : (
                          <>
                            <div
                              className={branch_or_address_error && "has-error"}
                            >
                              {req_receipt_type === "Branch" ? (
                                <SelectBranch
                                  loading={loadingStock}
                                  disabled={loadingStock}
                                  size="small"
                                  className="w-100"
                                  placeholder="เลือกสาขา"
                                  value={locationValue ?? undefined}
                                  disableOnlineBranch
                                  onChange={(value, option) =>
                                    dispatch(
                                      setFormValue({
                                        name: "receiptType",
                                        value: {
                                          receipt_branch_code: value,
                                          receipt_branch_name:
                                            option.props.children,
                                        },
                                        error_name: [
                                          "receipt_branch_code",
                                          "ship_to_id",
                                        ],
                                      })
                                    )
                                  }
                                />
                              ) : (
                                <SelectAddress
                                  size="small"
                                  allowOther={true}
                                  loading={loadingStock}
                                  disabled={loadingStock}
                                  memberId={partnerId}
                                  memberType={partnerType}
                                  //country='ไทย'
                                  value={locationValue ?? undefined}
                                  onChange={(value, option) => {
                                    if (option.props.source.post_code === "")
                                      return notification.warning({
                                        message: "กรุณาเลือกที่อยู่ภายในประเทศ",
                                      })
                                    dispatch(
                                      setFormValue({
                                        name: "receiptType",
                                        value: {
                                          ship_to: option.props.source.ship_to,
                                          ship_mobile:
                                            option.props.source.ship_to_mobile,
                                          ship_name: option.props.children,
                                          ship_to_id: value,
                                          ship_address_no:
                                            option.props.source.address,
                                          ship_sub_district:
                                            option.props.source.sub_district,
                                          ship_district:
                                            option.props.source.district,
                                          ship_province:
                                            option.props.source.province,
                                          ship_post_code:
                                            option.props.source.post_code,
                                          ship_country:
                                            option.props.source.country,
                                        },
                                        error_name: [
                                          "receipt_branch_code",
                                          "ship_to_id",
                                        ],
                                      })
                                    )
                                  }

                                  }
                                  onAddAddress={(value) =>
                                    dispatch(
                                      setFormValue({
                                        name: "receiptType",
                                        value,
                                        error_name: [
                                          "receipt_branch_code",
                                          "ship_to_id",
                                        ],
                                      })
                                    )
                                  }
                                />
                              )}
                            </div>
                            <ValidateError error={branch_or_address_error} />
                          </>
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col span={24} xl={12} xxl={{ span: 10, offset: 1 }}>
                  {req_receipt_type === "Branch" ? (
                    <ReceiptBranch disabled={disableReceipt} />
                  ) : (
                    <ReceiptTransport />
                  )}
                </Col>
              </Row>
              <Divider />
              <ProductTable disabled={disableAdd} />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Index;
