import {
  fixProductType,
  fixProductTypeSet,
} from "../../../../../../config/fix-value";
import { _ } from "../../../../../../utils";

export const getBannedSoldOut = (curr, allItems, allowItem, allowRenew) => {
  // in this case source is already in used qty so don't check will used
  const banned =
    (curr.product_type !== fixProductType.renew && allowItem === false) ||
    (curr.product_type === fixProductType.renew && allowRenew === false);
  let sold_out = false;
  if (
    !banned &&
    !_.includes(
      [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
      curr.product_type_set
    )
  ) {
    sold_out = !curr.stock_balance;
    if (!sold_out) {
      const used_qty = allItems[curr.product_code]?.qty || 0;
      sold_out = curr.stock_balance < curr.qty + used_qty;
    }
  }
  return {
    banned,
    sold_out,
  };
};

export const splitItemBannedSoldOut = (e) =>
  _.reduce(
    e,
    (res, n) => {
      const result = _.reduce(
        n,
        (res, m) => {
          if (m.banned) {
            const found = _.some(
              res.banned,
              (o) => m.product_code === o.product_code
            );
            if (found) return res;
            return {
              ...res,
              banned: [...res.banned, m],
            };
          } else if (m.sold_out) {
            const found = _.some(
              res.sold_out,
              (o) => m.product_code === o.product_code
            );
            if (found) return res;
            return {
              ...res,
              sold_out: [...res.sold_out, m],
            };
          }
          return res;
        },
        res
      );
      return result;
    },
    {
      banned: [],
      sold_out: [],
    }
  );
