/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, notification } from "antd";
import React, { useCallback, useEffect, useReducer } from "react";
import XLSX from "xlsx";
import HistoryModal from "../../../components/HistoryModal";
import { useQuery } from "../../../hooks/dom";
import { useHttp } from "../../../hooks/http";
import { axios, getToken, moment, URL_API, _ } from "../../../utils";
import { resCatchModal } from "../../util/helper";
import { LoadingFullScreen } from "../../util/Loading";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import BillModal from "./components/BillModal";
import DocumentTable from "./components/DocumentTable";
import Search from "./components/Search";
import PreOrderContext, * as pre_order from "./reducer";

const mapExcelData = (data, property) =>
  _.map(data, (item) =>
    _.reduce(
      property,
      (res, n) =>
        n.key === "document_date"
          ? { ...res, [n.name]: moment(item[n.key]).format("DD/MM/YYYY HH:mm") }
          : { ...res, [n.name]: item[n.key] },
      {}
    )
  );

const mapAoaExcel = (data, props) => {
  let res = [];
  _.map(data, (n) => {
    let curr = [];
    _.forEach(props, (m) => {
      curr.push(
        m.key === "document_date"
          ? moment(n[m.key]).format("DD/MM/YYYY HH:mm")
          : n[m.key]
      );
    });

    res.push(curr);
  });

  return res;
};

const Index = ({ history, match }) => {
  const { data_params } = match.params;
  const query = useQuery();
  const [state, dispatch] = useReducer(pre_order.reducer, pre_order.initState);
  const {
    search: {
      query: search_query,
      page,
      limit,
      status,
      type,
      date,
      branch_code,
      branch_name,
      type_name,
      status_name,
    },
    historyModal,
    loading,
  } = state;
  const paymentId = _.split(
    _.split(window.location.pathname, "payment-")[1],
    "-id"
  )[0];
  const reserveId = query.get("reserve-id");
  const paymentCode = query.get("payment_code");

  const responseData = useHttp(
    {
      url: `${URL_API}/pre-order/list`,
      params: {
        type,
        query: search_query,
        status,
        date: date ? moment(date).format("YYYY-MM-DD") : "",
        limit: 10,
        offset: (page - 1) * limit,
        branch_code,
      },
      token: true,
    },
    [type, search_query, status, date, page, branch_code]
  );
  const [_loading, data, _error, reload] = responseData;

  useEffect(() => {
    if (reserveId && !_.isNaN(+reserveId)) {
      // display modal by id after update result or redirect from KPayment (cancel)
      const reserve_no = _.split(
        _.split(window.location.pathname, "reserve_no-")[1],
        "-ern"
      )[0];
      const reserve_order_no = _.split(
        _.split(window.location.pathname, "reserve_order_no-")[1],
        "-eron"
      )[0];
      const partner_code = _.split(
        _.split(window.location.pathname, "partner_code-")[1],
        "-epc"
      )[0];
      const status = _.split(
        _.split(window.location.pathname, "status-")[1],
        "-es"
      )[0];
      dispatch({
        type: "SET_GLOBAL_STATE",
        payload: {
          name: "billModal",
          value: {
            visible: true,
            id: reserveId,
            reserve_no,
            reserve_order_no,
            partner_code,
            status,
            disabled: false,
          },
        },
      });
    }
  }, [reserveId]);

  useEffect(() => {
    if (paymentId && paymentCode && !_.isNaN(+paymentId)) {
      // redirect from KPayment (success) then update result
      axios({
        method: "post",
        url: `${URL_API}/pos/operation/reserve/k-payment`,
        data: { payment_code: paymentCode, payment_id: paymentId },
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          if (res.data.error) {
            history.replace({ pathname: "/pre-order" });
            return Modal.error({
              title: "ไม่สามารถดำเนินการได้",
              content: res.data.error,
              zIndex: 1080,
            });
          }
          history.replace({
            pathname: `/pre-order/${data_params}`,
            search: `?reserve-id=${res.data.data.id}`,
          });
          notification.success({
            message: "ชำระเงินด้วยบัตรเครดิตเรียบร้อย",
            description: `payment code : ${paymentCode}`,
          });
        })
        .catch((e) => resCatchModal(e));
    }
  }, [paymentId, paymentCode]);

  useEffect(() => {
    if (data?.data.user) {
      dispatch({
        type: "SET_GLOBAL_STATE",
        payload: { name: "user", value: data.data.user },
      });
    }
  }, [data?.data.user]);

  const onComplete = () => {
    dispatch({ type: "CLEAR_GLOBAL_STATE", payload: "billModal" });
    reload();
  };

  const handleExportExcel = () => {
    const property_data = [
      {
        name: "เลขการจอง",
        key: "reserve_order_no",
        wch: 16,
      },
      {
        name: "ช่องทาง",
        key: "bill_branch_name",
        wch: 24,
      },
      {
        name: "วันที่ดำเนินการ",
        key: "document_date",
        wch: 16,
      },
      {
        name: "รหัสนักธุรกิจ",
        key: "buyer_code",
        wch: 12,
      },
      {
        name: "ชื่อผู้ดำเนินการ",
        key: "buyer_name",
        wch: 40,
      },
      {
        name: "ประเภท",
        key: "reserve_type_text",
        wch: 10,
      },
      {
        name: "รหัสสินค้า",
        key: "product_code",
        wch: 14,
      },
      {
        name: "SKU",
        key: "sku",
        wch: 14,
      },
      {
        name: "ชื่อสินค้า",
        key: "product_name_local",
        wch: 50,
      },
      {
        name: "จำนวน",
        key: "qty",
        wch: 10,
      },
      {
        name: "พนักงานรับเรื่อง",
        key: "cashier_name",
        wch: 40,
      },
      {
        name: "สถานะ",
        key: "status_text",
        wch: 16,
      },
    ];

    let source = [
      [
        "ค้นหาเฉพาะรายการที่ระบุ",
        search_query || "-",
        "",
        "",
        "",
        "",
        "วันที่ออกรายงาน",
        moment().format("DD/MM/YYYY HH:mm"),
      ],
      [
        "สาขา",
        branch_name || "ทั้งหมด",
        "",
        "ประเภท",
        type_name || "ทั้งหมด",
        "",
        "สภานะ",
        status_name || "ทั้งหมด",
        "",
        "วันที่ค้นหา",
        date ? moment(date).format("DD/MM/YYYY") : "-",
      ],
      [],
      [
        "เลขการจอง",
        "ช่องทาง",
        "วันที่ดำเนินการ",
        "รหัสนักธุรกิจ",
        "ชื่อผู้ดำเนินการ",
        "ประเภท",
        "รหัสสินค้า",
        "SKU",
        "ชื่อสินค้า",
        "จำนวน",
        "พนักงานรับเรื่อง",
        "สถานะ",
      ],
    ];

    const mapData = mapAoaExcel(
      _.map(data.data.record, (r) => ({
        ...r,
        product_code: r.item.product_code,
        sku: r.item.sku,
        product_name_local: r.item.product_name_local,
        qty: r.item.qty,
      })),
      property_data
    );

    _.forEach(mapData, (n) => {
      source.push(n);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(source);

    ws["!cols"] = _.map(property_data, ({ wch }) => ({ wch }));
    XLSX.utils.book_append_sheet(wb, ws, "pre-order");
    XLSX.writeFile(
      wb,
      `pre-order-${moment().format("YYYY-MM-DD_HH-mm-ss")}.xlsx`
    );
  };

  const onClickExport = () => {
    Modal.confirm({
      title: "ยืนยันทำรายการ",
      content: "Export Excel",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        handleExportExcel();
      },
    });
  };

  const handleCloseHistory = useCallback(
    () =>
      dispatch({
        type: "CLEAR_GLOBAL_STATE",
        payload: "historyModal",
      }),
    []
  );

  return (
    <PreOrderContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>Pre Order</PanelHeader>
        <PanelBody>
          <LoadingFullScreen loading={loading} text={`กำลังบันทึกข้อมูล...`} />
          <Search onClickExport={onClickExport} />
          <DocumentTable responseData={responseData} />
          <BillModal onComplete={onComplete} />
          <HistoryModal
            documentType="pre-order"
            processType="pre-order"
            limit={historyModal.limit}
            documentNo={historyModal.document_no}
            onCancel={handleCloseHistory}
          />
        </PanelBody>
      </Panel>
    </PreOrderContext.Provider>
  );
};

export default Index;
