import { Button, Modal, notification, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { actionClaim } from "../../../../../redux/slices/claim";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";

const { Text } = Typography;

const { setState } = actionClaim;

const UserComplete = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  // const noQrRunningItemKey = useSelector((state) => {
  //   const { itemKeys, itemByKey } = state.claim.claimOutProduct;
  //   return _.filter(itemKeys, (key) => {
  //     const { qr, qty, bom } = itemByKey[key];
  //     return (
  //       qty !== qr.length ||
  //       _.some(bom, (sub_item) => sub_item.qty !== sub_item.qr.length)
  //     );
  //   });
  // });
  const claimOutProduct = useSelector((state) => {
    const { itemKeys, itemByKey } = state.claim.claimOutProduct;
    return _.map(itemKeys, (key) => itemByKey[key]);
  });
  const document_status = useSelector(
    (state) => state.claim.documentDetail.status
  );
  const document_no = useSelector(
    (state) => state.claim.documentDetail.document_no
  );
  const remark = useSelector((state) => state.claim.remark.complete);
  const handleSubmit = () => {
    // if (noQrRunningItemKey)
    //   return notification.warning({
    //     message: "ไม่สามารถดำเนินการการได้",
    //     description: "กรุณาสแกน Qr Code สินค้าให้ครบถ้วน",
    //   });
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1 text-pv">
            ยืนยันรับทราบ
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        dispatch(setState({ name: "loadingSubmit", value: true }));
        axios({
          method: "post",
          url: `${URL_API}/claim/complete`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            status: "complete",
            document_no,
            document_status,
            // remark: remark?.trim() || null,
            // remark:remark,
            remark: remark?.remark === null ? null : remark,
            products: claimOutProduct,
          },
        })
          .then(() => {
            notification.success({
              message: "บันทึกยืนยันรับทราบแล้ว",
            });
            history.push({
              pathname: `/${_.split(match.path, "/")[1]}/${
                match.params.process
              }`,
            });
          })
          .catch((e) => resCatchModal(e))
          .finally(() =>
            dispatch(setState({ name: "loadingSubmit", value: false }))
          );
      },
    });
  };

  return (
    <Button type="primary" className="width-100" onClick={handleSubmit}>
      รับทราบ
    </Button>
  );
};

export default UserComplete;
