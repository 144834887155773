import { createSlice } from "@reduxjs/toolkit";
import { _ } from "lodash"

const default_product = {
    product_code: "",
    product_name: "",
    qty: 0,
    price: 0,
}
// state
const initialState = {
    proGiveAway: {
        promotion_code: '',
        promotion_name: '',
        forever: false,
        start_date: null,
        end_date: null,
        payment: [],
        status: [],
        join_pro: false,
        distribution: [],
        honor: [],
        member: [],
        member_point: '',
        member_position: '',
        hold: true,
        regular: true,
        redeem_next: false,
        source_list: {
            itemKeys: [],
            itemByKey: {},
        }, //เงื่อนไข้การซื้อ
        free_list: [], //สิ่งที่จะได้รับ
    },
    proPrice: {
        detail: {
            promotion_code: '',
            promotion_name: '',
        },
        date: {
            forever: 0,
            start_date: null,
            end_date: null,
        },
        payment: [],
        status: [],
        join_pro: 0,
        distribution: [],
        box_set_a: {
            honor: [],
            member: [],
            member_point: 0,
        },
        box_set_b: {
            order_type: [],
            redeem_next: 0,
        },
        source_list: [], //เงื่อนไข้การซื้อ
        free_list: [], //สิ่งที่จะได้รับ
    },
    proBill: {
        detail: {
            promotion_code: '',
            promotion_name: '',
        },
        date: {
            forever: 0,
            start_date: null,
            end_date: null,
        },
        payment: [],
        status: [],
        join_pro: 0,
        distribution: [],
        box_set_a: {
            honor: [],
            member: [],
            member_point: 0,
        },
        box_set_b: {
            order_type: [],
            redeem_next: 0,
        },
        source_list: [], //เงื่อนไข้การซื้อ
        free_list: [], //สิ่งที่จะได้รับ
    },
}

// slice
const promotionSlice = createSlice({
    name: 'promotion',
    initialState,
    reducers: {
        SET_PRO_GIVE_AWAY: (state, action) => { state.proGiveAway = { ...state.proGiveAway, ...action.payload } },
        SET_PRO_PRICE: (state, action) => { state.proPrice = { ...state.proPrice, ...action.payload } },
        SET_PRO_BILL: (state, action) => { state.proBill = { ...state.proBill, ...action.payload } },

        ADD_PRODUCT_SOURCE: (state, { payload }) => {
            let newProductKeys = [...state.proGiveAway.source_list.itemKeys];
            let newProductByKey = { ...state.proGiveAway.source_list.itemByKey };
            const product_key = `${payload.product_code}`

            newProductKeys.push(product_key);

            newProductByKey = {
                ...newProductByKey,
                [product_key]: {
                    ...default_product,
                    ...payload,
                },
            };

            state.proGiveAway.source_list = {
                ...state.proGiveAway.source_list,
                itemKeys: [...newProductKeys],
                itemByKey: { ...newProductByKey }
            }
        },
        DEL_PRODUCT_SOURCE: (state, { payload }) => {
            state.proGiveAway.source_list = {
                ...state.proGiveAway.source_list,
                itemKeys: _.filter(state.proGiveAway.source_list.itemKeys, (n) => n !== payload.product_code),
                itemByKey: _.omit(state.proGiveAway.source_list.itemByKey, [payload.product_code]),
            }
        },
    }
});

// selector
export const proGiveAwaySelector = (state) => state.promotion.proGiveAway;
export const proPriceSelector = (state) => state.promotion.proPrice;
export const proBillSelector = (state) => state.promotion.proBill;


// action
export const actionPromotion = promotionSlice.actions;

// reducer
const PromotionReducer = promotionSlice.reducer;
export default PromotionReducer;
