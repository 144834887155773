import { createSlice } from "@reduxjs/toolkit";
import { _, moment } from "../../../utils/index";

const initState = {
  search: {
    query: "",
    branch: "",
    start_date: null,
    end_date: null,
    page: 1,
    limit: 10,
  },
  list: {
    itemKeys: [],
    itemByKey: {},
  },
  form: {
    id: 0,
    product_code: "",
    product_name_local: "",
    bar_code: "",
    qty: 0,
    lot: "",
    production_date: null,
    expiration_date: null,
    list: {
      itemKeys: [],
      itemByKey: {},
    },
  },
};

const storeQrLotSlice = createSlice({
  name: "lot",
  initialState: initState,
  reducers: {
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    SET_FORM: (state, { payload }) => {
      state.form = { ...state.form, ...payload };
    },
    CLEAR_FORM: (state) => {
      state.form = initState.form;
    },
    LOAD_FORM: (state, { payload }) => {
      let loadItemKeys = [];
      let loadItemByKey = {};
      _.forEach(payload.list, (n) => {
        let loadKey = _.uniqueId("qr-");
        loadItemKeys.push(loadKey);
        loadItemByKey[loadKey] = n;
      });

      state.form = {
        ...initState.form,
        ...payload,
        expiration_date: payload.expiration_date
          ? moment(payload.expiration_date)
          : null,
        production_date: payload.production_date
          ? moment(payload.production_date)
          : null,
        list: {
          itemKeys: loadItemKeys,
          itemByKey: loadItemByKey,
        },
      };
    },
    ADD_FORM_LIST: (state, { payload }) => {
      let newListKey = _.uniqueId("qr-");

      let newListItemKeys = [...state.form.list.itemKeys];
      let newListItemByKey = { ...state.form.list.itemByKey };

      newListItemKeys.push(newListKey);
      newListItemByKey[newListKey] = payload;

      state.form.list.itemKeys = newListItemKeys;
      state.form.list.itemByKey = newListItemByKey;
    },
    DEL_FORM_LIST: (state, { payload }) => {
      let delListItemKeys = [...state.form.list.itemKeys];
      let delListItemByKey = { ...state.form.list.itemByKey };

      if (delListItemByKey[payload].id == null) {
        delListItemKeys = _.filter(delListItemKeys, (n) => n !== payload);
        delListItemByKey = _.omit(delListItemByKey, [payload]);
        
      } else {
        delete delListItemByKey[payload]
        delListItemKeys = _.filter(delListItemKeys, (n) => n !== payload);
      }
      state.form.list.itemKeys = delListItemKeys;
      state.form.list.itemByKey = delListItemByKey;
    },
  },
});

export const actionStoreQrLot = storeQrLotSlice.actions;

export const searchSelector = (state) => state.storeQR.lot.search;
export const formSelector = (state) => state.storeQR.lot.form;

const StoreQrLotReducer = storeQrLotSlice.reducer;

export default StoreQrLotReducer;
