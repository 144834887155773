import { Col, Empty, Input, notification, Row, Spin, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import styles from "../../../../../scss/module/promotion.module.scss";
import { axios, URL_API, _ } from "../../../../../utils";
import { getDataCheckStock } from "../../ducks/logic/data-check-stock";
import ModalControl from "../ModalControl";
import PromotionDisableItemPopup from "../PromotionDisableItemPopup";
import PromotionDetailModal from "./PromotionDetailModal";
import PromotionItem from "./PromotionItem";

const initDisabledItem = {
  banned: [],
  sold_out: [],
};

const limit = 12;
const Index = ({ visible, onCancel, onSelect, ...props }) => {
  const scrollRef = useRef(null);
  const [promotion, setPromotion] = useState([]);
  const [query, setQuery] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sourceDisableItem, setSourceDisableItem] = useState(initDisabledItem);
  const [freeDisableItem, setFreeDisableItem] = useState(initDisabledItem);
  const [promotionDetail, setPromotionDetail] = useState({});

  const handleSearch = (search) => {
    scrollRef.current.pageLoaded = 0;
    setQuery(search?.trim() || "");
    setInitialLoad(true);
    setHasMore(true);
    setPromotion([]);
  };

  useEffect(() => {
    if (query) {
      scrollRef.current.pageLoaded = 0;
    }
  }, [query]);

  const loadPromotion = async (page) => {
    setInitialLoad(false);
    setLoading(true);
    try {
      let p = page - 1;
      if (!p || p < 0) p = 0;
      let response = await axios({
        url: `${URL_API}/pos/master/promotion/list`,
        params: {
          limit,
          offset: p * limit,
          query,
          order_source: "pos",
          partner_code: props.order.partner_code,
          order_type: props.order.order_type,
          req_receipt_type: props.order.req_receipt_type,
          receipt_branch_code: props.order.receipt_branch_code,
        },
      });

      let fetch = [...promotion];
      fetch = [...fetch, ...response.data.data];
      setPromotion(fetch);
      setHasMore(fetch.length < response.data.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notification.error({
        message: "ตรวจพบสิ่งผิดพลาด",
        description:
          err.response && err.response.data
            ? err.response.data.message
            : err.message,
      });
    }
  };

  const handleViewDisableItem = (source, free) => {
    setSourceDisableItem(source);
    setFreeDisableItem(free);
  };

  const handleCloseDisableItem = useCallback(() => {
    setSourceDisableItem(initDisabledItem);
    setFreeDisableItem(initDisabledItem);
  }, []);

  const handleClosePreviewPromotionDetail = useCallback(
    () => setPromotionDetail({}),
    []
  );

  return (
    <ModalControl
      visible={visible}
      onCancel={onCancel}
      footerLine={false}
      width={900}
    >
      <Row className="mb-3">
        <Col span={24}>
          <Typography.Title level={3} className="text-center">
            รายการโปรโมชัน
          </Typography.Title>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col span={24}>
          <Input.Search
            allowClear
            placeholder="รหัสโปรโมชัน/ชื่อโปรโมชัน"
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Row className="mb-3 height-600 overflow-y-scroll">
        <InfiniteScroll
          pageStart={0}
          className="w-100"
          initialLoad={initialLoad}
          loadMore={loadPromotion}
          hasMore={!loading && hasMore}
          useWindow={false}
          loader={
            <Col
              span={8}
              key={_.uniqueId("spin-")}
              className="d-flex justify-content-center align-items-center w-100 height-300"
            >
              <Spin spinning />
            </Col>
          }
          ref={scrollRef}
        >
          {promotion.map((n, i) => (
            <Col span={8} key={i} className={styles["promotion-list"]}>
              <PromotionItem
                data={n}
                onSelect={onSelect}
                onPreview={setPromotionDetail}
                allItems={props.all_items}
                allowItem={props.allow_item}
                allowRenew={props.allow_renew}
                onViewDisableItem={handleViewDisableItem}
              />
            </Col>
          ))}
        </InfiniteScroll>
        {hasMore === false && promotion.length === 0 && (
          <Empty className="my-5" />
        )}
      </Row>
      <PromotionDisableItemPopup
        freeDisableItem={freeDisableItem}
        sourceDisableItem={sourceDisableItem}
        onCancel={handleCloseDisableItem}
      />
      <PromotionDetailModal
        dataSource={promotionDetail}
        onCancel={handleClosePreviewPromotionDetail}
      />
    </ModalControl>
  );
};

function mapStateToProps(state) {
  const { activeKey, itemByKey } = state.pos.operation.orders;
  const dataCheckStock = getDataCheckStock(state.pos.operation.orders);
  return {
    ...dataCheckStock,
    order: itemByKey[activeKey],
  };
}

export default connect(mapStateToProps, null)(Index);
