import { Checkbox, Form } from "antd";
import React from "react";

const VoucherPlatform = ({ getFieldDecorator }) => {
  return (
    <>
      <Form.Item name="shopping" valuePropName="checked">
        {getFieldDecorator("shopping", {
          valuePropName: "checked",
          initialValue: false,
        })(<Checkbox>Website / Mobile App</Checkbox>)}
      </Form.Item>
      <Form.Item name="pos" valuePropName="checked">
        {getFieldDecorator("pos", {
          valuePropName: "checked",
          initialValue: false,
        })(<Checkbox>POS</Checkbox>)}
      </Form.Item>
    </>
  );
};

export default VoucherPlatform;
