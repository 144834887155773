/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Modal, notification, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { axios, getToken, URL_API, _ } from '../../../../../utils';
import { Panel, PanelBody, PanelHeader } from '../../../../util/panel';

function BS_HeaderText({ history, form, ...props }) {
  
    const [loadValue, setLoadValue] = useState("");
  
    const handleClickSubmit = () => {
      form.validateFieldsAndScroll((err, values) => {
        if (err) {
          return notification.warning({
            message: "กรุณาตรวจสอบรายการ",
            description: "กรุณากรอกข้อมูลให้ครบถ้วน",
          });
        }
  
        console.log({ values });
  
        let formData = new FormData();
        formData.append("data", JSON.stringify(_.omit(values)));
  
        Modal.confirm({
          title: "ยืนยัน",
          content: "ต้องการบันทึกข้อมูลหรือไม่",
          okText: "บันทึก",
          cancelText: "ปิด",
          onOk() {
            return new Promise((resolve, reject) => {
              axios({
                method: "post",
                baseURL: URL_API,
                url: "/manage-website/benefits-and-subscriptions/bs-header-text",
                headers: { Authorization: "Bearer " + getToken() },
                data: formData,
              })
                .then((res) => {
                  console.log(res);
                  resolve("success");
                })
                .catch((err) => {
                  console.log(err);
                  reject(
                    err && err.response
                      ? err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                      : err.message
                  );
                });
            })
              .then(() => {
                Modal.success({
                  title: "สำเร็จ",
                  content: "บันทึกเรียบร้อย",
                  okText: "ปิด",
                  onOk() {
                    history.go(0);
                  },
                });
              })
              .catch((reason) => {
                Modal.error({
                  title: "ผิดพลาด",
                  content: reason,
                  okText: "ปิด",
                });
              });
          },
        });
      });
    };
  
    const loadContent = () => {
      axios({
        method: "get",
        baseURL: URL_API,
        url: "/manage-website/benefits-and-subscriptions/bs-header-text",
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          console.log(res);
          var data = res.data?.data;
          setLoadValue(data);
  
          form.setFieldsValue({
            ...data
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      loadContent();
    }, []);
  
    const { getFieldDecorator } = form;
  
    return (
      <>
        <Panel>
          <PanelHeader>Header Text</PanelHeader>
          <PanelBody>
            <Form layout="vertical">
              <Row>
                <Form.Item label="Content">
                  {getFieldDecorator("content", {
                    initialValue: "",
                    rules: [{ required: true, message: "กรุณาระบุ content" }],
                  })(<TextArea/>)}
                </Form.Item>
              </Row>
              <Row>
                <Button type="primary" onClick={() => handleClickSubmit()}>
                  {loadValue != (null||"") ? "Update" : "Save"}
                </Button>
              </Row>
            </Form>
          </PanelBody>
        </Panel>
      </>
    );
  }
  export default Form.create("bs-header-text")(BS_HeaderText);
  
