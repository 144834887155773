/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Spin,
  Statistic,
  Table,
  Typography,
  notification,
} from "antd";
import numeral from "numeral";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { FaCogs, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
  actionStoreQrSet,
  formSelector,
} from "../../../../redux/slices/store-qr/set";
import {
  URL_API,
  _,
  axios,
  getToken,
  getUser,
  moment,
} from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import ModalProduct from "../components/ModalProduct";

const SetForm = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const form = useSelector(formSelector);
  const dispatch = useDispatch();

  const [view, setView] = useState(true);
  const [branch, setBranch] = useState("");
  const [mainQr, setMainQr] = useState("");
  const [qr, setQr] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isChange, setIsChange] = useState(false);
  const {
    SET_FORM,
    SELECT_PRODUCT,
    SELECT_QR,
    CLEAR_QR,
    CLEAR_FORM,
    LOAD_FORM,
  } = actionStoreQrSet;

  const handleSelectProduct = (e) => {
    dispatch(CLEAR_FORM());
    axios({
      method: "get",
      baseURL: URL_API,
      url: `store-qr/set/product`,
      headers: { Authorization: "Bearer " + getToken() },
      params: { product_code: e.product_code },
    })
      .then((res) => {
        let data = res.data.data;
        dispatch(
          SELECT_PRODUCT({
            product_code: data?.product_code,
            product_name: data?.product_name_en,
            bar_code: data?.bar_code,
            bom: data?.bom || [],
          })
        );

        setModalShow(false);
      })
      .catch((err) => {
        Modal.error({
          title: "ผิดพลาด",
          content:
            err && err.response
              ? err.response.data.message
                ? err.response.data.message
                : err.response.data
              : err.message,
          cancelText: "ปิด",
        });
        setModalShow(false);
      });
  };

  const handleEnterMainQR = (e) => {
    if (e.keyCode === 13) {
      if (!mainQr?.toLowerCase().includes("www.legacy.co.th/th/home/?lgcsku")) {
        notification.warning({
          message: "กรุณาตรวจสอบ QR CODE หลัก",
          description: "ข้อมูล QR Code หลัก ไม่ถูกต้อง",
        });
        dispatch(
          SET_FORM({
            main_qr: "",
            main_running: "",
          })
        );
        return;
      }

      let params = qs.parseUrl(mainQr?.toLowerCase());
      const formated_main_qr = `${params.url}?LgcSKU=${params.query?.lgcsku}&LgcLot=${params.query?.lgclot}&LgcStart=${params.query?.lgcstart}&LgcExpire=${params.query?.lgcexpire}&LgcSN=${params.query?.lgcsn}`;

      axios({
        method: "get",
        baseURL: URL_API,
        url: `store-qr/set/main-qr-code`,
        headers: { Authorization: "Bearer " + getToken() },
        params: { main_qr_code: formated_main_qr },
      })
        .then((res) => {
          const dup_mainQr = res.data.data;
          if (dup_mainQr) {
            notification.warning({
              message: "กรุณาตรวจสอบรายการ",
              description: "QR Code นี้ได้ถูกจัดเซ็ดไปแล้ว",
            });
          } else {
            axios({
              method: "get",
              baseURL: URL_API,
              url: `store-qr/main-qr/qr-code`,
              headers: { Authorization: "Bearer " + getToken() },
              params: { main_qr_code: formated_main_qr },
            })
              .then((res) => {
                dispatch(
                  SET_FORM({
                    main_qr: formated_main_qr,
                    main_running: params.query?.lgcsn || "",
                  })
                );
              })
              .catch((err) => {
                Modal.error({
                  title: "ผิดพลาด",
                  content:
                    err && err.response
                      ? err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                      : err.message,
                  cancelText: "ปิด",
                });
              });
          }
        })
        .catch((err) => {
          Modal.error({
            title: "ผิดพลาด",
            content:
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message,
            cancelText: "ปิด",
          });
        });

      setMainQr("");
    }
  };

  const handleEnterQR = (e) => {
    if (e.keyCode === 13) {
      axios({
        method: "get",
        baseURL: URL_API,
        url: `store-qr/lot/qr-code`,
        headers: { Authorization: "Bearer " + getToken() },
        params: { qr_code: qr },
      })
        .then((res) => {
          if (res.data.data) {
            let user = getUser();

            if (res.data.data.status.toLowerCase() === "draft") {
              notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: "QR Code นี้ถูกบันทึกในเอกสารร่าง",
              });
              return;
            }
            if (res.data.data.useable == 0) {
              notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: "QR Code ไม่สามารถใช้งานได้",
              });
              return;
            }
            //check สาขาที่การเพิ่มข้อมูล
            if (user.branch_code === res.data.data.branch_code) {
              let params = qs.parseUrl(qr);
              let data = res.data.data;

              let list = _.map(
                form.list.itemKeys,
                (n) => form.list.itemByKey[n]
              );

              if (_.find(list, (n) => n.product_code === data.product_code)) {
                let find_dup = _.find(
                  list,
                  (n) => n.qr_code === qr && (params.query?.LgcSN || "") !== ""
                );
                if (find_dup) {
                  notification.warning({
                    message: "กรุณาตรวจสอบรายการ",
                    description: "QR Code นี้เคยถูกสแกนแล้ว",
                  });
                } else {
                  axios({
                    method: "get",
                    baseURL: URL_API,
                    url: `/store-qr/set/set-detail`,
                    headers: { Authorization: "Bearer " + getToken() },
                    params: {
                      lot: data.lot,
                      qr_code: data.qr_code,
                    },
                  })
                    .then((res) => {
                      const dup_setDetail = res.data.data;

                      if (dup_setDetail) {
                        notification.warning({
                          message: "กรุณาตรวจสอบรายการ",
                          description: `QR Code ถูกนำไปจัดเซ็ตแล้ว`,
                        });
                      } else {
                        if (
                          _.find(
                            list,
                            (n) =>
                              n.product_code === data.product_code &&
                              n.qr_code === ""
                          )
                        ) {
                          dispatch(SELECT_QR(data));
                        } else {
                          notification.warning({
                            message: "กรุณาตรวจสอบรายการ",
                            description: `รหัสสินค้า ${data.product_code} ถูกสแกนครบแล้ว`,
                          });
                        }
                      }
                    })
                    .catch((err) => {
                      Modal.error({
                        title: "ผิดพลาด",
                        content:
                          err && err.response
                            ? err.response.data.message
                              ? err.response.data.message
                              : err.response.data
                            : err.message,
                        cancelText: "ปิด",
                      });
                    });
                }
              } else {
                notification.warning({
                  message: "กรุณาตรวจสอบรายการ",
                  description: "QR Code นี้ไม่ตรงกับรหัสสินค้าที่เลือก",
                });
              }
            } else {
              notification.warning({
                message: "กรุณาตรวจสอบรายการ",
                description: "QR Code นี้อยู่ในสาขาอื่น",
              });
            }
          } else {
            Modal.warning({
              title: "ผิดพลาด",
              content:
                "ไม่พบ QR Code นี้ในฐานข้อมูล หรือ QR Code นี้เคยถูกสแกนแล้ว",
              cancelText: "ปิด",
            });
          }

          setQr("");
        })
        .catch((err) => {
          Modal.error({
            title: "ผิดพลาด",
            content:
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message,
            cancelText: "ปิด",
          });
        });
    }
  };

  const handleOnSubmit = (status) => {
    if (form.product_code === "" || form.main_qr === "") {
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณากรอกข้อมูลรหัสสินค้า และ QR Code หลัก",
      });
    }

    if (
      _.find(
        _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]),
        (n) => n.qr_code === ""
      ) &&
      status === "active"
    ) {
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
    }

    if (
      _.filter(
        _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]),
        (n) => n.qr_code !== ""
      ).length == 0 &&
      status === "draft"
    ) {
      return notification.warning({
        message: "กรุณาตรวจสอบรายการ",
        description: "กรุณากรอกข้อมูลอย่างน้อย 1 รายการ",
      });
    }

    Modal.confirm({
      title: "ยืนยัน",
      content:
        status === "active"
          ? "คุณต้องการบันทึกข้อมูลหรือไม่"
          : "คุณต้องการบันทึกข้อมูลเอกสารร่างหรือไม่",
      okText: "บันทึก",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          let user = getUser();
          let data = {
            ...form,
            list: _.map(form.list.itemKeys, (n) => form.list.itemByKey[n]),
            status,
            branch_code: user.branch_code,
            branch_name: user.branch_name,
          };

          axios({
            method: "post",
            baseURL: URL_API,
            url: `store-qr/set${
              match.params.id === "0" ? "" : `/${match.params.id}`
            }`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
          })
            .then((res) => {
              resolve("success");
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกเรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.push(`/store-qr/set`);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const loadData = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: `store-qr/set/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        dispatch(LOAD_FORM(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (match.params.id !== "0") {
      loadData(match.params.id);
    } else {
      dispatch(CLEAR_FORM());
      setLoading(false);
    }

    let user = getUser();
    setView(match.params.type == "view");
    setBranch(user.branch_code);
  }, [match.params.id, match.params.type]);

  // const handleEdit = () => {

  //   history.push(`/store-qr/set/${match.params.id}/edit`)

  // }

  const handleDelete = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: `store-qr/set/tracking-qr/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    }).then((res) => {
      if (res.data.data) {
        notification.warning({
          message: "ไม่สามารถลบชุดเซ็ตนี้ได้",
          description: "ชุดเซ็ตนี้ได้ถูกสแกนขายแล้ว",
        });
        return;
      } else {
        Modal.confirm({
          title: "ยืนยันการลบข้อมูล",
          content: "คุณต้องการที่จะลบข้อมูลชุดเซ็ตนี้หรือไม่",
          okText: "ลบข้อมูล",
          cancelText: "ปิด",
          onOk() {
            return new Promise((resolve, reject) => {
              axios({
                method: "delete",
                baseURL: URL_API,
                url: `store-qr/set/${id}`,
                headers: { Authorization: "Bearer " + getToken() },
              })
                .then((res) => {
                  if (res.data.status === 202) {
                    reject(
                      "ไม่สามารถลบได้เนื่องจากเซ็ตสินค้านี้ได้ถูกโอนไปยังสาขาอื่นแล้ว"
                    );
                  } else {
                    resolve("success");
                  }
                })
                .catch((err) => {
                  reject(
                    err && err.response
                      ? err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                      : err.message
                  );
                });
            })
              .then(() => {
                Modal.success({
                  title: "สำเร็จ",
                  content: "ลบเรียบร้อย",
                  okText: "ปิด",
                  onOk() {
                    history.push(`/store-qr/set`);
                  },
                });
              })
              .catch((reason) => {
                Modal.error({
                  title: "ผิดพลาด",
                  content: reason,
                  okText: "ปิด",
                });
              });
          },
        });
      }
    });
  };
  return (
    <>
      <Panel>
        <PanelHeader>จัดเซ็ตสินค้า</PanelHeader>
        <PanelBody>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "24px 0px",
              }}
            >
              <Spin tip="Loading" size="small" />
            </div>
          ) : (
            <>
              <Row gutter={16} className="d-flex align-items-start">
                <Col md={16}>
                  <Row
                    gutter={8}
                    className="d-flex align-items-top-center mb-2"
                  >
                    <Col md={4} className="text-right">
                      <Typography.Text>รหัสสินค้า:</Typography.Text>
                    </Col>
                    <Col md={8}>
                      {view || form.status == "active" ? (
                        <Typography.Text>{form.product_code}</Typography.Text>
                      ) : (
                        <Input
                          className="w-100"
                          value={form.product_code}
                          disabled
                          suffix={
                            <Button
                              type="link"
                              className="px-0"
                              onClick={() => setModalShow(true)}
                            >
                              <FaPlus />
                            </Button>
                          }
                        />
                      )}
                    </Col>
                    <Col md={4} className="text-right">
                      <Typography.Text>ชื่อสินค้า:</Typography.Text>
                    </Col>
                    <Col md={8}>
                      {view || form.status == "active" ? (
                        <Typography.Text>{form.product_name}</Typography.Text>
                      ) : (
                        <Input
                          className="w-100"
                          value={form.product_name}
                          disabled
                        />
                      )}
                    </Col>
                  </Row>
                  <Row
                    gutter={8}
                    className="d-flex align-items-top-center mb-2"
                  >
                    <Col md={4} className="text-right">
                      <Typography.Text>QR Code หลัก:</Typography.Text>
                    </Col>
                    <Col md={8}>
                      {/* {!view  (
                    <Input
                      className="w-100"
                      value={mainQr}
                      onChange={(e) => setMainQr(e.target.value)}
                      onKeyUp={handleEnterMainQR}
                    />
                  )} */}
                      {view || form.status == "active" ? (
                        <Typography.Text style={{ wordWrap: "break-word" }}>
                          {form.main_qr}
                        </Typography.Text>
                      ) : (
                        <Input
                          className="w-100"
                          value={mainQr}
                          onChange={(e) =>
                            setMainQr(e.target.value.split(" ").join(""))
                          }
                          onKeyUp={handleEnterMainQR}
                          allowClear
                        />
                      )}
                    </Col>
                    <Col md={4} className="text-right">
                      {(form.main_qr || "") !== "" && (
                        <Typography.Text>Running:</Typography.Text>
                      )}
                    </Col>
                    <Col md={8}>
                      {(form.main_qr || "") !== "" && (
                        <Typography.Text>
                          {form.main_running === ""
                            ? "No Running"
                            : form.main_running}
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={4} className="text-center">
                  {(form.main_qr || "") !== "" && (
                    <QRCode value={form.main_qr} />
                  )}
                </Col>
                <Col md={4} className="text-right">
                  <>
                    {form.branch_code === branch && !view && (
                      <Button
                        type="danger"
                        className="mr-2"
                        onClick={() => handleDelete(match.params.id)}
                      >
                        ลบข้อมูล
                      </Button>
                    )}

                    {/* {form.branch_code === branch && view && (
                    <Button
                      type="primary"
                      ghost
                      onClick={() => handleEdit()}
                    >
                      แก้ไข
                    </Button>
                  )} */}
                  </>
                </Col>
              </Row>

              <Divider />
              <Row gutter={8} className="d-flex align-items-center mb-2">
                <Col md={10}>
                  {((form.branch_code === branch &&
                    match.params.type == "edit" &&
                    form.status == "draft") ||
                    match.params.id == 0 ||
                    (match.params.type == "edit" && form.status != "active") ||
                    (form.branch_code === branch &&
                      match.params.type == "edit" &&
                      form.status == "active" &&
                      form.useable)) && (
                    <Input
                      className="w-100"
                      placeholder="ป้อน QR Code สินค้าชุดย่อย"
                      value={qr}
                      onChange={(e) =>
                        setQr(e.target.value.split(" ").join(""))
                      }
                      onKeyUp={handleEnterQR}
                    />
                  )}
                </Col>
                <Col md={4} className="text-center">
                  {form.product_code !== "" && (
                    <Statistic
                      title="จำนวนรวมทั้งหมด"
                      formatter={(value) => numeral(value).format()}
                      value={
                        _.filter(
                          _.map(
                            form.list.itemKeys,
                            (n) => form.list.itemByKey[n]
                          ),
                          (n) => n.qr_code !== ""
                        ).length
                      }
                      suffix={`/ ${form.list.itemKeys.length} รายการ`}
                    />
                  )}
                </Col>
              </Row>
              <Table
                size="small"
                bordered
                dataSource={_.filter(
                  _.map(form.list.itemKeys, (n) => ({
                    ...form.list.itemByKey[n],
                    key: n,
                  })),
                  (n) => n.id !== 0
                )}
                pagination={false}
              >
                <Table.Column
                  title="No."
                  key="no"
                  render={(text, record, index) => index + 1}
                />
                <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
                <Table.Column
                  title="รายการสินค้าในเซ็ต"
                  dataIndex="product_name"
                />
                <Table.Column
                  title="Running"
                  dataIndex="running"
                  align="center"
                />
                <Table.Column title="Lot." dataIndex="lot" />
                <Table.Column
                  title="วันที่ผลิตสินค้า"
                  dataIndex="production_date"
                  align="center"
                  render={(text) =>
                    text ? moment(text).format("DD/MM/YYYY") : ""
                  }
                />
                <Table.Column
                  title="วันหมดอายุสินค้า"
                  dataIndex="expiration_date"
                  align="center"
                  render={(text) =>
                    text ? moment(text).format("DD/MM/YYYY") : ""
                  }
                />
                {form.useable && !view && (
                  <Table.Column
                    title={<FaCogs />}
                    align="center"
                    key="action"
                    render={(text, record) => (
                      <Button
                        size="small"
                        type="danger"
                        shape="circle-outline"
                        onClick={() => {
                          dispatch(CLEAR_QR(record.key));
                          setIsChange(true);
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    )}
                  />
                )}

                {form.status != "active" && !view && (
                  <Table.Column
                    title={<FaCogs />}
                    align="center"
                    render={(text, record) =>
                      ((form.branch_code === branch &&
                        !view &&
                        !form.on_transfer &&
                        record.qr_code !== "") ||
                        (record.qr_code !== "" && match.params.id === "0")) && (
                        <Button
                          size="small"
                          type="danger"
                          shape="circle-outline"
                          onClick={() => dispatch(CLEAR_QR(record.key))}
                        >
                          <FaTrashAlt />
                        </Button>
                      )
                    }
                  />
                )}
              </Table>
              {!view && (
                <Row className="my-3">
                  <Col className="text-center">
                    {form.status != "active" && (
                      <Button
                        type="dashed"
                        className="mr-2"
                        onClick={() => dispatch(CLEAR_FORM())}
                      >
                        ล้างข้อมูล
                      </Button>
                    )}
                    {form.status != "active" && (
                      <Button
                        type="dashed"
                        className="mr-2"
                        onClick={() => handleOnSubmit("draft")}
                      >
                        บันทึกร่าง
                      </Button>
                    )}
                    {form.status != "active" && (
                      <Button
                        type="primary"
                        onClick={() => handleOnSubmit("active")}
                      >
                        บันทึก
                      </Button>
                    )}
                    {form.status == "active" && form.useable && isChange && (
                      <Button
                        type="primary"
                        onClick={() => handleOnSubmit("active")}
                      >
                        บันทึก
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </PanelBody>
      </Panel>

      <ModalProduct
        productTypeSet="Kit/Package"
        visible={modalShow}
        onCancel={() => setModalShow(false)}
        onSelect={handleSelectProduct}
      />
    </>
  );
};

export default SetForm;
