import React, { createContext, useReducer, useContext } from "react";

const initialState = {
  loading: false,
  data: {
    date: "",
    event_name: [],
    coin_list: [],
    file_info: {}, // ✅ เก็บข้อมูลไฟล์
    detail_data: [], // ✅ เก็บข้อมูลรายละเอียดไฟล์
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };

    case "SET_DATE":
      return { ...state, data: { ...state.data, date: action.payload } };

    case "SET_EVENT_NAME":
      return {
        ...state,
        data: { ...state.data, event_name: action.payload },
      };

    case "SET_COIN_LIST":
      return {
        ...state,
        data: { ...state.data, coin_list: action.payload },
      };

    case "SET_FILE_INFO":
      return {
        ...state,
        data: { ...state.data, file_info: action.payload },
      };

    case "SET_DETAIL_DATA":
      return {
        ...state,
        data: { ...state.data, detail_data: action.payload },
      };

    default:
      return state;
  }
};




const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>;
};

export const useStateValue = () => useContext(StateContext);
