import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const classCategoryFormDefaultValues = {
  id: '',
  formType: 'create',
  title: '',
  thumbnailImageUrl: undefined,
};

export const classCategoryFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่ชื่อหมวดหมู่'),
    })
  );
};

export const classSubCategoryFormDefaultValues = {
  id: '',
  formType: 'create',
  title: '',
  thumbnailImageUrl: undefined,
  categoryId: '',
};

export const classSubCategoryFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่ชื่อหมวดหมู่'),
    })
  );
};

export const classFormDefaultValues = {
  id: '',
  formType: 'create',
  subCategoryId: '',
  rewardId: '',
  title: '',
  subTitle: '',
  thumbnailImageUrl: '',
  description: '',
  period: '',
  attribute_title: '',
  attribute_image: '',
  attribute_value: '',
  attribute_unit: '',
  suggestion: [],
};

export const classFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่ชื่อหมวดหมู่'),
      rewardId: yup.string().required('กรุณาใส่เลือกเหรียญรางวัล'),
      description: yup.string().required('กรุณาใส่คำบรรยาย'),
      period: yup.string().required('กรุณาใส่ระยะเวลา'),
      attribute_title: yup.string().required('กรุณาใส่ชื่อคุณสมบัติ'),
      attribute_image: yup.string().required('กรุณาใส่รูปภาพคุณสมบัติ'),
      attribute_value: yup.string().required('กรุณาใส่ค่าคุณสมบัติ'),
      attribute_unit: yup.string().required('กรุณาใส่หน่วยคุณสมบัติ'),
      suggestion: yup.array(
        yup.object().shape({
          description: yup.string(),
        })
      ),
    })
  );
};

export const classScheduleFormDefaultValues = {
  id: '',
  formType: 'create',
  trainerId: '',
  title: '',
  imageUrl: '',
  description: '',
  max: 10,
  start_date: moment(new Date()),
  end_date: moment(new Date()).add('d', 3),
  branchId: '',
};

export const classScheduleFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      trainerId: yup.string().required('กรุณาใส่เลือกเทรนเนอร์'),
      title: yup.string().nullable(),
      imageUrl: yup.string().nullable(),
      description: yup.string().nullable(),
      max: yup.number().min(1).required('กรุณาใส่จำนวนสูงสุด'),
      start_date: yup.string().required('กรุณาใส่เลือกวันและเวลาเริ่ม'),
      end_date: yup.string().required('กรุณาใส่เลือกวันและเวลาจบ'),
    })
  );
};
