/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Upload,
  Icon,
  message,
  notification,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";
import { URL_API, getToken } from "../../../../../utils";
import SelectUserDocument from "../SelectUserDocument";

export default function UploadDocumentModal() {
  const dispatch = useDispatch();
  const { id, type } = useSelector((state) => state.ethics.form, shallowEqual);
  const [documentCode, setDocumentCode] = useState(null);
  const { document_upload, reload } = useSelector(
    (state) => state.ethics.form.upload,
    shallowEqual
  );
  const modal = useSelector((state) => state.ethics.modal, shallowEqual);
  const { CLOSE_MODAL, SET_FORM_UPLOAD } = actionEthics;

  const closeModal = () => {
    dispatch(CLOSE_MODAL());
  };
  const { Dragger } = Upload;

  const props = {
    name: "file",
    accept: ".jpg,.png",
    multiple: false,
    showUploadList: false,
    action: `${URL_API}/pos/${
      type === "member" ? "vip-member" : "ethics"
    }/${id}/image/?type=${documentCode}`,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (status === "done") {
        notification.success({
          message: "เรียบร้อย",
          description: "อัพโหลดเสร็จเรียบร้อย",
        });
        closeModal();
        dispatch(SET_FORM_UPLOAD({ active: documentCode }));
        setDocumentCode(null);
      } else if (status === "error") {
        notification.error({
          message: "ไม่สามารถอัพโหลดเอกสารได้",
          description: "ไม่สามารถอัพโหลดไฟล์ได้",
        });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        notification.error({
          message: "ไม่สามารถอัพโหลดเอกสารได้",
          description: "อัพโหลดเฉพาะไฟล์ .jpg หรือ .png เท่านั้น",
        });
      }
      const haveDocumentType = documentCode != undefined;
      if (!haveDocumentType) {
        notification.error({
          message: "ไม่สามารถอัพโหลดเอกสารได้",
          description: "กรุณาเลือกประเภทเอกสารก่อนที่จะทำการอัพโหลด",
        });
      }

      return isJpgOrPng && haveDocumentType;
    },
  };
  return (
    <>
      <Modal
        visible={modal.name === "document"}
        onCancel={closeModal}
        title="อัพโหลดเอกสาร"
        width={800}
        destroyOnClose
        footer={[
          <Button type="default" key={"close"} onClick={closeModal}>
            ปิด
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col md={12}>
            <SelectUserDocument
              size="small"
              className="w-100"
              placeholder={`เลือกประเภทเอกสาร`}
              onUpload
              value={documentCode}
              onChange={(value) =>
                //dispatch(SET_FORM_UPLOAD({ document_upload: value }))
                setDocumentCode(value)
              }
            />
          </Col>
        </Row>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            คลิก หรือ ลากไฟล์วางในกรอบ เพื่อทำการอัพโหลดเอกสาร
          </p>
        </Dragger>
      </Modal>
    </>
  );
}
