import { Button, Table } from "antd";
import React from "react";
import { FaCog, FaPencilAlt } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { actionEthics } from "../../../../../redux/slices/ethics";
import {
  getMoment,
  getNumeral,
  getStringLiteral,
} from "../../../../../utils/helper";
import ShowFileIcon from "../ShowFileIcon";

export default function TableDistributor() {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { search, list } = useSelector((state) => state.ethics, shallowEqual);
  const { SET_SEARCH } = actionEthics;

  return (
    <Table
      size="small"
      loading={list.loading}
      dataSource={list.data || []}
      rowKey="row_id"
      rowClassName="f-s-12"
      pagination={{
        size: "default",
        current: search.page,
        pageSize: search.limit,
        total: list.total || 0,
        showLessItems: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${getNumeral(range[0])} - ${getNumeral(range[1])} of ${getNumeral(
            total
          )}`,
        pageSizeOptions: ["10", "25", "50", "100"],
        onChange: (current, pageSize) =>
          dispatch(
            SET_SEARCH({
              page: current,
              limit: pageSize,
            })
          ),
        onShowSizeChange: (current, size) =>
          dispatch(
            SET_SEARCH({
              page: 1,
              limit: size,
            })
          ),
      }}
    >
      <Table.Column
        title="รหัสสมาชิก"
        dataIndex="partner_code"
        align="center"
        className="f-s-12"
      />
      <Table.Column
        title="ชื่อสมาชิก"
        className="f-s-12"
        render={(text, { first_name_th, sur_name_th }) =>
          getStringLiteral(first_name_th, sur_name_th)
        }
      />
      <Table.Column
        title="รหัสผู้แนะนำ"
        dataIndex="sponsor_code"
        align="center"
        className="f-s-12"
      />
      <Table.Column
        title="ชื่อผู้แนะนำ"
        dataIndex="sponsor_name"
        className="f-s-12"
      />
      <Table.Column
        title="สถานะ"
        dataIndex="status_name"
        align="center"
        className="f-s-12"
      />
      <Table.Column
        title="เวลาที่สมัคร"
        dataIndex="register_date"
        align="center"
        className="f-s-12"
        render={(text) => getMoment(text)}
      />
      <Table.Column
        title="เอกสาร"
        dataIndex="file"
        className="f-s-12"
        render={(files) => <ShowFileIcon files={files} />}
      />
      <Table.Column
        title={<FaCog />}
        dataIndex="id"
        align="center"
        className="f-s-12"
        render={(id) => (
          <Button
            size="small"
            shape="circle"
            type="primary"
            className="bg-pv border-pv f-s-12"
            onClick={() =>
              history.push(`${match.path}/${id}/${search.member_type}`)
            }
          >
            <FaPencilAlt />
          </Button>
        )}
      />
    </Table>
  );
}
