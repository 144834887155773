/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ErrorMessage } from '@hookform/error-message';
import { Button, DatePicker, Typography, Select, Input, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FaPen, FaSearch } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import fitStudioService from '../../../../services/fit-studio.service';
import visitFitStudioPaperServices from '../../../../services/visit-fit-studio-paper.services';
import useAsync from '../../../../utils/useAsync';
import usePaginationHook from '../../../../utils/usePagination';
import { AlertConfirm, AlertError } from '../../../util/Alert';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import useModalHook from '../../../util/useModalHook';
import {
  visitFitStudioPaperQueryDefaultValues,
  visitFitStudioPaperQueryFormSchema,
  VisitorFormTypeDefaultValues,
  VisitorFormTypeSchema,
} from '../components/dto/visti-fit-studio-paper';
import VisitFitStudioPaperModal from '../components/modal/VisitFitStudioPaperModal';
import visitFitStudioPaperConstants from '../constants/visitFitStudioPaperConstants';

function VistiFitStudioPage() {
  const { page, setPage, total, setTotal, data, setData } = usePaginationHook();
  const visitFitStudioPaperForm = useForm({
    defaultValues: VisitorFormTypeDefaultValues,
    resolver: VisitorFormTypeSchema(),
  });
  const {
    open: visitFitStudioPaperFormOpen,
    handleOpen: _HandleVisitFitStudioPaperFormOpen,
    handleClose: _HandleVisitFitStudioPaperFormClose,
  } = useModalHook();

  const columns = [
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      title: 'สาขา',
      dataIndex: 'fitnessBranch',
      key: 'fitnessBranch',
      render: (_, record) => (
        <span>{record.fitnessBranch.branchMaster.Name}</span>
      ),
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'ประเภท',
      dataIndex: 'formType',
      key: 'formType',
      render: (_, record) => (
        <span>
          {record.formType === 'visit' && 'เยี่ยมชม'}
          {record.formType === 'experiment' && 'ทดลองใช้บริการ'}
        </span>
      ),
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'บัตรประชาชน/passport',
      dataIndex: 'idCard',
      key: 'idCard',
    },
    {
      title: 'รหัสสมาชิก',
      dataIndex: 'legacyPartnerCode',
      key: 'legacyPartnerCode',
    },
    {
      title: 'สถานะสมาชิก',
      dataIndex: 'memberStatus',
      key: 'memberStatus',
      render: (_, record) => (
        <span>
          {record.memberStatus === 'legacy_member' && 'เป็นสมาชิกเลกาซี'}
          {record.memberStatus === 'not_legacy_member_have_consultant' &&
            'ไม่ใช่สมาชิกเลกาซี แบบมีผู้แนะนำ'}
          {record.memberStatus === 'not_legacy_member_no_consultant' &&
            'ไม่ใช่สมาชิกเลกาซี แบบไม่มีผู้แนะนำ'}
        </span>
      ),
    },
    {
      title: 'รหัสผู้แนะนำ',
      dataIndex: 'legacyConsultantPartnerCode',
      key: 'legacyConsultantPartnerCode',
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              visitFitStudioPaperForm.setValue('id', record.id);
              _HandleVisitFitStudioPaperFormOpen();
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบผู้ใช้งาน');
              if (confirm) {
                deleteVisitFitStudioPaper({ visitFitStudioPaperId: record.id });
              }
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  const { mutate: deleteVisitFitStudioPaper } =
    visitFitStudioPaperServices.useMutationDeleteVisitFitStudioPaper(
      (data) => {}
    );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: visitFitStudioPaperQueryDefaultValues,
    resolver: visitFitStudioPaperQueryFormSchema(),
  });

  const { refetch: getVisitFitStudioPapers } =
    visitFitStudioPaperServices.useQueryGetVisitFitStudioPapers(
      {
        page,
        take: visitFitStudioPaperConstants.tableSize,
        formType: watch('formType'),
        memberStatus: watch('memberStatus'),
        firstName: watch('firstName'),
        lastName: watch('lastName'),
        phoneNumber: watch('phoneNumber'),
        idCard: watch('idCard'),
        legacyPartnerCode: watch('legacyPartnerCode'),
        legacyConsultantPartnerCode: watch('legacyConsultantPartnerCode'),
        from: watch('from'),
        to: watch('to'),
        fitnessBranchId: watch('fitnessBranchId'),
      },
      (data) => {
        setTotal(data.meta.total);
        setData(data.data);
      }
    );

  const _HandleSubmit = handleSubmit((data) => {
    if (
      !data.formType &&
      !data.memberStatus &&
      !data.firstName &&
      !data.lastName &&
      !data.phoneNumber &&
      !data.idCard &&
      !data.legacyPartnerCode &&
      !data.legacyConsultantPartnerCode &&
      !data.from &&
      !data.to &&
      !data.fitnessBranchId
    ) {
      AlertError({
        title: 'ไม่สามารถค้นหาได้',
        text: 'กรุณากรอกข้อมูลอย่างใดอย่างหนึ่ง',
      });
    } else {
      getVisitFitStudioPapers();
    }
  });

  const _HandleCancel = () => {
    reset(visitFitStudioPaperQueryDefaultValues);

    getVisitFitStudioPapers();
  };

  const { mutate: downloadisitFitStudioPaper } =
    visitFitStudioPaperServices.useMutationDownloadisitFitStudioPaper();

  const { data: branchLists } = useAsync(fitStudioService.getAllFitnessBranch, {
    immediate: true,
  });

  return (
    <Panel>
      <PanelHeader>เยี่ยมชม/ทดลองเล่น</PanelHeader>
      <PanelBody>
        <div>
          <Typography.Text>ค้นหาด้วย</Typography.Text>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <Controller
              name="idCard"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="รหัสบัตรประชาชน"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="ชื่อ"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="นามสกุล"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="เบอร์โทรศัพท์"
                    className="mr-2"
                  />
                );
              }}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <div
              style={{
                flex: 0.5,
                display: 'flex',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Controller
                name="legacyPartnerCode"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="รหัสสมาชิก"
                      className="mr-2"
                      style={{ flex: 0.5 }}
                    />
                  );
                }}
              />
              <Controller
                name="legacyConsultantPartnerCode"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="รหัสผู้แนะนำ"
                      className="mr-2"
                      style={{ flex: 0.5 }}
                    />
                  );
                }}
              />
            </div>
            <div style={{ flex: 0.5 }} />
          </div>
          <Typography.Text>ตัวกรอง</Typography.Text>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              marginBottom: 10,
              alignItems: 'flex-end',
            }}
          >
            <Controller
              name="formType"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    style={{ flex: 1, marginRight: 10 }}
                    onChange={onChange}
                    placeholder="ประเภท"
                  >
                    <Select.Option value="visit">เยี่ยมชมสาขา</Select.Option>
                    <Select.Option value="experiment">ทดลองเล่น</Select.Option>
                  </Select>
                );
              }}
            />
            <Controller
              name="fitnessBranchId"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    style={{ flex: 1, marginRight: 10 }}
                    onChange={onChange}
                    placeholder="สาขา"
                  >
                    {branchLists &&
                      branchLists.length > 0 &&
                      branchLists.map((item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.branchMaster.Name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                );
              }}
            />
            <Controller
              name="memberStatus"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    style={{ flex: 1, marginRight: 10 }}
                    onChange={onChange}
                    placeholder="สถานะสมาชิก"
                  >
                    <Select.Option value="legacy_member">
                      เป็นสมาชิกเลกาซี
                    </Select.Option>
                    <Select.Option value="not_legacy_member_have_consultant">
                      ไม่ใช่สมาชิกเลกาซี แบบมีผู้แนะนำ
                    </Select.Option>
                    <Select.Option value="not_legacy_member_no_consultant">
                      ไม่ใช่สมาชิกเลกาซี แบบไม่มีผู้แนะนำ
                    </Select.Option>
                  </Select>
                );
              }}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <div
              style={{
                marginRight: 10,
                flex: 0.5,
              }}
            >
              <Typography.Text>วันที่ลงทะเบียน</Typography.Text>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  name="from"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div style={{ flex: 1 }} className="mr-2">
                        <DatePicker
                          format="DD/MM/YYYY"
                          className="w-100"
                          placeholder="วันที่เริ่มต้น"
                          allowClear={false}
                          value={value}
                          onChange={(date, dateString) => {
                            onChange(moment(date));
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <Typography.Text>ถึง</Typography.Text>
                <Controller
                  name="to"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div style={{ flex: 1 }} className="ml-2">
                        <DatePicker
                          format="DD/MM/YYYY"
                          className="w-100"
                          placeholder="วันที่สิ้นสุด"
                          value={value}
                          allowClear={false}
                          onChange={(date, dateString) => {
                            onChange(moment(date));
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 0.5 }} />
          </div>
          <ErrorMessage
            errors={errors}
            name="searchError"
            render={({ message }) => (
              <Typography.Text className="mb-2 text-danger">
                {message}
              </Typography.Text>
            )}
          />
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              marginBottom: 10,
              justifyContent: 'flex-end',
            }}
          >
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={_HandleSubmit}
            >
              <FaSearch className="mr-2" />
              ค้นหา
            </Button>
            <Button
              className="bg-pv border-pv text-white"
              onClick={_HandleCancel}
            >
              ยกเลิก
            </Button>
          </div>
          <div className="d-flex" style={{ marginBottom: 10 }}>
            <Button
              type="primary"
              className="text-white d-flex align-items-center"
              icon="file-excel"
              onClick={() => {
                downloadisitFitStudioPaper({
                  page,
                  take: visitFitStudioPaperConstants.tableSize,
                  formType: watch('formType'),
                  memberStatus: watch('memberStatus'),
                  firstName: watch('firstName'),
                  lastName: watch('lastName'),
                  phoneNumber: watch('phoneNumber'),
                  idCard: watch('idCard'),
                  legacyPartnerCode: watch('legacyPartnerCode'),
                  legacyConsultantPartnerCode: watch(
                    'legacyConsultantPartnerCode'
                  ),
                  from: watch('from'),
                  to: watch('to'),
                });
              }}
            >
              Export Excel
            </Button>
          </div>
        </div>

        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          onChange={({ current }) => {
            setPage(current);
          }}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          pagination={{
            pageSize: visitFitStudioPaperConstants.tableSize,
            total: total,
          }}
        />
        <FormProvider {...visitFitStudioPaperForm}>
          <VisitFitStudioPaperModal
            open={visitFitStudioPaperFormOpen}
            handleClose={_HandleVisitFitStudioPaperFormClose}
          />
        </FormProvider>
      </PanelBody>
    </Panel>
  );
}

export default VistiFitStudioPage;
