/* eslint-disable react-hooks/exhaustive-deps */
import { Col, notification, Popconfirm, Spin } from "antd";
import { default as Axios, default as axios } from "axios";
import React, { useEffect, useState } from "react";
import { FaCheck, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import img from "../../../../../../../assets/img/pos/no-img.jpg";
import { actionProduct } from "../../../../../../../redux/slices/product";
import styles from "../../../../../../../scss/module/claim.module.scss";
import { URL_API, _ } from "../../../../../../../utils";

const BlockItem = ({ data, onPreview, onSetDefault }) => {
  const [source] = useState(Axios.CancelToken.source());
      const dispatch = useDispatch();
  const { SET_MEDIA, DEL_MEDIA } = actionProduct;

  useEffect(() => {
    return () => source.cancel("unmounted");
  }, []);

  useEffect(() => {
    if (data.id && !data.img_url) {
      axios({
        url: `${URL_API}/system/products/image/${data.id}`,
        cancelToken: source.token,
      })
        .then((res) => {
          dispatch(
            SET_MEDIA({
              media_key: data.media_key,
              img_url: res.data.data ? res.data.data : img,
            })
          );
        })
        .catch((err) => {
          err.message !== "unmounted" &&
            notification.error({
              message: "ตรวจพบสิ่งผิดพลาด",
              description:
                err.response && err.response.data
                  ? err.response.data.message
                  : err.message,
            });
        });
    }
  }, [data.id, data.img_url, data.media_key, source.token]);

  return (
    <Col
      span={24}
      className={`${styles.block} ${styles.img} ${
        data.temp ? "" : styles.commit
      }`}
    >
      {data.img_url ? (
        <>
          <img
            className="object-fit-cover w-100 h-100"
            src={data.img_url}
            alt={_.uniqueId("promotion-")}
          />
          <div className={`${styles.loading} ${styles.delete}`}>
            {data.img_url !== img && (
              <FaEye className={styles.icon} onClick={onPreview} />
            )}

            {!data.is_default && (
              <FaCheck className={styles.icon} onClick={onSetDefault} />
            )}
            <Popconfirm
              placement="topRight"
              title={`ต้องการลบรายการนี้หรือไม่`}
              onConfirm={() =>
                dispatch(DEL_MEDIA({ media_key: data.media_key }))
              }
              okText="ลบ"
              cancelText="ไม่ลบ"
              zIndex={1080}
            >
              <MdDelete className={styles.icon} />
            </Popconfirm>
          </div>
        </>
      ) : (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}
    </Col>
  );
};

export default BlockItem;
