import { Col, Row } from 'antd';
import React from 'react';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import ModelBodyFocusForm from '../components/form/ModelBodyFocusForm';
import bodyFocusConstants from '../constants/bodyFocusConstants';

const BodyFoucModelPage = () => {
  const { execute: getMaleById, data: maleBodyFocusData } = useAsync(
    fitStudioService.getAuthscreenById,
    {
      immediate: true,
      args: '2',
    }
  );

  const { execute: getFemaleById, data: femaleBodyFocusData } = useAsync(
    fitStudioService.getAuthscreenById,
    {
      immediate: true,
      args: '3',
    }
  );

  return (
    <Panel>
      <PanelHeader>โมเดล</PanelHeader>
      <PanelBody>
        <Row>
          <Col span={12}>
            {maleBodyFocusData && (
              <img
                src={maleBodyFocusData.imageUrl}
                alt={maleBodyFocusData.imageUrl}
                style={{
                  width: bodyFocusConstants.width,
                  height: bodyFocusConstants.height,
                  objectFit: 'cover',
                }}
              />
            )}
          </Col>
          <Col span={12}>
            <ModelBodyFocusForm getAuthscreenById={getMaleById} modelId={2} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {femaleBodyFocusData && (
              <img
                src={femaleBodyFocusData.imageUrl}
                alt={femaleBodyFocusData.imageUrl}
                style={{
                  width: bodyFocusConstants.width,
                  height: bodyFocusConstants.height,
                  objectFit: 'cover',
                }}
              />
            )}
          </Col>
          <Col span={12}>
            <ModelBodyFocusForm getAuthscreenById={getFemaleById} modelId={3} />
          </Col>
        </Row>
      </PanelBody>
    </Panel>
  );
};

export default BodyFoucModelPage;
