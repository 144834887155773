/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Table } from 'antd';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { FormProvider, useForm } from 'react-hook-form';
import 'dayjs/locale/th';
import { useHistory } from 'react-router';
import fitQuizServices from '../../../../services/fit-quiz.services';
import LegacyQuizRewardModal from '../components/modal/LegacyQuizRewardModal';
import {
  legacyQuizRewardFormDefaultValues,
  legacyQuizRewardFormSchema,
} from '../components/dto/legacy-quiz-form.dto';

dayjs.locale('th');

const pageSize = 10;

const LegacyQuizRewardPage = () => {
  const [legacyQuizRewardFormOpen, setLegacyQuizRewardFormOpen] =
    useState(false);
  const {
    data: legacyQuizRewardLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();
  const history = useHistory();

  const legacyQuizRewardForm = useForm({
    defaultValues: legacyQuizRewardFormDefaultValues,
    resolver: legacyQuizRewardFormSchema(),
  });

  const _HandleLegacyQuizRewardFormOpen = (formType) => {
    legacyQuizRewardForm.setValue('formType', formType);
    setLegacyQuizRewardFormOpen(true);
  };

  const _HandleLegacyQuizRewardFormClose = () => {
    legacyQuizRewardForm.reset(legacyQuizRewardFormDefaultValues);
    setLegacyQuizRewardFormOpen(false);
  };

  fitQuizServices.useQueryGetLegacyQuizRewardLists(
    { take: pageSize, page, onlyActive: false },
    (data) => {
      setData(data.data);
      setTotal(data.meta.total);
    }
  );

  const { mutate: deleteLegacyQuizReward } =
    fitQuizServices.useMutationDeleteLegacyQuizReward(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-reward'],
        refetchType: 'all',
      });
    });

  const { mutate: updateLegacyQuizRewardDisplay } =
    fitQuizServices.useMutationUpdateLegacyQuizRewardDisplay(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-reward'],
        refetchType: 'all',
      });
    });

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'รูปภาพเหรียญทอง',
      dataIndex: 'frontImageUrl',
      key: 'frontImageUrl',
      render: (_, record) => (
        <div>
          <img
            alt={record.title}
            src={record.frontImageUrl}
            style={{
              width: 100,
              height: 100,
            }}
          />
        </div>
      ),
    },
    {
      title: 'รูปภาพเหรียญเงิน',
      dataIndex: 'greyFrontImageUrl',
      key: 'greyFrontImageUrl',
      render: (_, record) => (
        <div>
          <img
            alt={record.title}
            src={record.greyFrontImageUrl}
            style={{
              width: 100,
              height: 100,
            }}
          />
        </div>
      ),
    },
    {
      title: 'ผลิตภัณฑ์',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'คะแนนเป้าหมาย',
      dataIndex: 'max',
      key: 'max',
      render: (_, record) => <span>{record.max}</span>,
    },
    {
      title: 'วันที่เริ่ม',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => (
        <span>{dayjs(record.startDate).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.startDate).diff(dayjs(b.startDate), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record) => (
        <span>{dayjs(record.endDate).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.endDate).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'อัปเดตเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{dayjs(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      sorter: (a, b) =>
        dayjs(a.updatedAt).diff(dayjs(b.updatedAt), 'D') > 0 ? 1 : -1,
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => <span>{record.display ? 'เปิด' : 'ปิด'}</span>,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.display ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการแสดงเหรียญรางวัล');
                if (confirm)
                  updateLegacyQuizRewardDisplay({
                    id: record.id,
                    display: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการแสดงเหรียญรางวัล');
                if (confirm)
                  updateLegacyQuizRewardDisplay({
                    id: record.id,
                    display: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              legacyQuizRewardForm.setValue('id', record.id);
              _HandleLegacyQuizRewardFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteLegacyQuizReward({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Legacy Quiz Reward</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <div style={{ flex: 1 }} />
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleLegacyQuizRewardFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่มเหรียญรางวัล
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={legacyQuizRewardLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/questionaire/quiz/quiz-reward/${record.id}`);
              },
            };
          }}
        />
      </PanelBody>
      <FormProvider {...legacyQuizRewardForm}>
        <LegacyQuizRewardModal
          open={legacyQuizRewardFormOpen}
          handleClose={_HandleLegacyQuizRewardFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default LegacyQuizRewardPage;
