import { _ } from "../../../../../utils";
import { orderCategoryItem } from "../../components/RecPromotion/logic";
import { isProItem } from "../../components/RecPromotion/logic/validate";

function calculateItemList(itemKeys, itemByKey) {
  // only phase one don't check promotion type
  // const promotionKey = _.filter(
  //   itemKeys,
  //   (n) => itemByKey[n].promotion_type !== "amount"
  // );
  const promotionKey = itemKeys;
  let res = [];
  _.forEach(promotionKey, (promotion_id) => {
    const promotion = itemByKey[promotion_id];
    _.forEach(promotion.source, (option) => {
      const option_qty = _.reduce(
        _.filter(
          promotion.option.itemKeys,
          (option_key) => _.split(option_key, "-")[0] === option.source_option
        ),
        (total_qty, option_key) =>
          total_qty + promotion.option.itemByKey[option_key].qty,
        0
      );
      _.forEach(option.data, (item) => {
        let res_item = [];
        if (!item.source_key) {
          // promotion list case
          res_item = [
            {
              item_key: item.product_code,
              qty: item.qty * option_qty,
              condition_type: promotion.condition_type,
            },
          ];
        } else if (item.source_key === "category") {
          const with_amount_only =
            promotion.condition_type === "item-amount" ? { promotion_id } : {};
          const items = orderCategoryItem(item.items);
          let item_qty = 0;
          item_qty = item.qty;
          let qty = item_qty * option_qty;
          for (let item_index = 0; item_index < items.length; item_index++) {
            const n = items[item_index];
            if (n.qty <= 0) continue;
            const curr_qty = n.qty - qty;
            if (curr_qty < 0) {
              res_item = [
                ...res_item,
                {
                  ...with_amount_only,
                  item_key: n.product_code,
                  qty: n.qty,
                  condition_type: promotion.condition_type,
                },
              ];
              qty -= n.qty;
            } else {
              res_item = [
                ...res_item,
                {
                  ...with_amount_only,
                  item_key: n.product_code,
                  qty,
                  condition_type: promotion.condition_type,
                },
              ];
              break;
            }
          }
        } else if (item.source_key === "item") {
          const with_amount_only =
            promotion.condition_type === "item-amount" ? { promotion_id } : {};
          res_item = [
            {
              ...with_amount_only,
              item_key: item.source_value,
              qty: item.qty * option_qty,
              condition_type: promotion.condition_type,
            },
          ];
        } else {
          res_item = [
            {
              item_key: item.source_key,
              qty: item.qty * option_qty,
              condition_type: promotion.condition_type,
              promotion_id,
            },
          ];
        }

        _.forEach(res_item, (item) => {
          const item_index = isProItem(promotion.condition_type)
            ? _.findIndex(
                res,
                (n) =>
                  n.condition_type === item.condition_type &&
                  n.item_key === item.item_key
              )
            : -1;
          if (~item_index) {
            res[item_index].qty += item.qty;
          } else {
            res = [...res, item];
          }
        });
      });
    });
  });
  return res;
}

function calculatePromotionList(itemKeys, itemByKey) {
  return _.reduce(
    itemKeys,
    (pro, promotion_id) => [
      ...pro,
      ..._.reduce(
        itemByKey[promotion_id].free,
        (free, option) => {
          const option_qty = _.reduce(
            _.filter(
              itemByKey[promotion_id].option.itemKeys,
              (option_key) =>
                _.split(option_key, "-")[1] === option.source_option
            ),
            (option_qty, option_key) =>
              option_qty +
              itemByKey[promotion_id].option.itemByKey[option_key].qty,
            0
          );
          return [
            ...free,
            ..._.map(option.data, (item) => ({
              ...item,
              source_option: option.source_option,
              qty: item.qty * option_qty,
            })),
          ];
        },
        []
      ),
    ],
    []
  );
}

export function addPromotions(state, payload) {
  // promotions
  const {
    order_key,
    condition_source_option,
    free_source_option,
    free,
    source,
    add_qty,
    promotion_type,
    condition_type,
    order_limit,
  } = payload;
  const { promotion_id } = free[0];
  let itemKeys = state.orders.itemByKey[order_key].promotions.itemKeys;
  let itemByKey = state.orders.itemByKey[order_key].promotions.itemByKey;
  if (_.some(itemKeys, (pro_key) => pro_key === promotion_id)) {
    let option_key = _.find(
      itemByKey[promotion_id].option.itemKeys,
      (option) => {
        const option_key_list = _.split(option, "-");
        return (
          option_key_list[0] === condition_source_option &&
          option_key_list[1] === free_source_option
        );
      }
    );
    if (option_key) {
      // add option quantity
      itemByKey[promotion_id].option.itemByKey[option_key].qty += add_qty;
    } else {
      // add option
      const option_key = `${condition_source_option}-${free_source_option}`;
      const source_index = _.findIndex(
        itemByKey[promotion_id].option.itemKeys,
        (option_key) => _.split(option_key, "-")[0] === condition_source_option
      );
      const free_index = _.findIndex(
        itemByKey[promotion_id].option.itemKeys,
        (option_key) => _.split(option_key, "-")[1] === free_source_option
      );
      if (!~source_index) {
        itemByKey[promotion_id].source = [
          ...itemByKey[promotion_id].source,
          { source_option: condition_source_option, data: source },
        ];
      }
      if (!~free_index) {
        itemByKey[promotion_id].free = [
          ...itemByKey[promotion_id].free,
          { source_option: free_source_option, data: free },
        ];
      }
      itemByKey[promotion_id].option = {
        itemKeys: [...itemByKey[promotion_id].option.itemKeys, option_key],
        itemByKey: {
          ...itemByKey[promotion_id].option.itemByKey,
          [option_key]: {
            key: option_key,
            qty: add_qty,
          },
        },
      };
    }
  } else {
    // add promotion
    const option_key = `${condition_source_option}-${free_source_option}`;
    itemKeys = [...itemKeys, promotion_id];
    itemByKey = {
      ...itemByKey,
      [promotion_id]: {
        promotion_type,
        condition_type,
        order_limit,
        source: [{ source_option: condition_source_option, data: source }],
        free: [{ source_option: free_source_option, data: free }],
        option: {
          itemKeys: [option_key],
          itemByKey: {
            [option_key]: {
              key: option_key,
              qty: add_qty,
            },
          },
        },
      },
    };
  }

  return {
    itemKeys,
    itemByKey,
    promotion_list: calculatePromotionList(itemKeys, itemByKey),
    item_list: calculateItemList(itemKeys, itemByKey),
  };
}

export function delOrderPromotionItem(state, payload) {
  const { promotion_id, source_option } = payload;
  const order_key = state.orders.activeKey;
  let { itemKeys, itemByKey, promotion_list } =
    state.orders.itemByKey[order_key].promotions;
  promotion_list = _.filter(
    promotion_list,
    (item) =>
      item.promotion_id !== promotion_id ||
      (item.promotion_id === promotion_id &&
        item.source_option !== source_option)
  );
  const free_value = _.filter(
    itemByKey[promotion_id].free,
    (free) => free.source_option !== source_option
  );
  if (free_value.length === 0) {
    itemKeys = _.filter(itemKeys, (pro_key) => pro_key !== promotion_id);
    itemByKey = _.omit(itemByKey, [promotion_id]);
    return {
      itemKeys,
      itemByKey,
      promotion_list,
      item_list: calculateItemList(itemKeys, itemByKey),
    };
  }
  const { del_option, not_del_option } = _.reduce(
    itemByKey[promotion_id].option.itemKeys,
    (res, option_key) => {
      const is_del_key = _.split(option_key, "-")[1] === source_option;
      return {
        del_option: is_del_key
          ? [...res.del_option, option_key]
          : res.del_option,
        not_del_option: is_del_key
          ? res.not_del_option
          : [...res.not_del_option, option_key],
      };
    },
    {
      del_option: [],
      not_del_option: [],
    }
  );
  const del_source = _.reduce(
    del_option,
    (res, del_option_key) => {
      const select_source_option = _.split(del_option_key, "-")[0];
      const is_other_used = _.some(
        not_del_option,
        (not_del_option_key) =>
          select_source_option[0] === _.split(not_del_option_key, "-")[0]
      );
      return is_other_used ? res : [...res, select_source_option];
    },
    []
  );
  itemByKey[promotion_id] = {
    ...itemByKey[promotion_id],
    free: free_value,
    source: _.filter(
      itemByKey[promotion_id].source,
      (source) => !_.includes(del_source, source.source_option)
    ),
    option: {
      itemKeys: _.filter(
        itemByKey[promotion_id].option.itemKeys,
        (option_key) => _.split(option_key, "-")[1] !== source_option
      ),
      itemByKey: _.omit(itemByKey[promotion_id].option.itemByKey, del_option),
    },
  };
  return {
    itemKeys,
    itemByKey,
    promotion_list,
    item_list: calculateItemList(itemKeys, itemByKey),
  };
}

export function delOrderPromotionFromItem(state, key) {
  const order_key = state.orders.activeKey;
  let { itemKeys, itemByKey } = state.orders.itemByKey[order_key].promotions;
  const { product_code } =
    state.orders.itemByKey[order_key].items.itemByKey[key];
  const { pro_balance_key, pro_delete_key } = _.reduce(
    itemKeys,
    (res, pro_key) => {
      // only phase one don't check promotion type
      const is_del =
        itemByKey[pro_key].condition_type !== "item" ||
        _.some(itemByKey[pro_key].source, (sou) =>
          _.some(
            sou.data,
            (item) =>
              item.product_code === product_code ||
              _.some(item.items, (item) => item.product_code === product_code)
          )
        );
      return {
        pro_balance_key: is_del
          ? res.pro_balance_key
          : [...res.pro_balance_key, pro_key],
        pro_delete_key: is_del
          ? [...res.pro_delete_key, pro_key]
          : res.pro_delete_key,
      };
    },
    {
      pro_balance_key: [],
      pro_delete_key: [],
    }
  );
  itemKeys = pro_balance_key;
  itemByKey = _.omit(itemByKey, pro_delete_key);
  return {
    itemKeys,
    itemByKey,
    promotion_list: calculatePromotionList(itemKeys, itemByKey),
    item_list: calculateItemList(itemKeys, itemByKey),
  };
}

export function setOrderPromotionItemValue(state, payload) {
  const { promotion_id, source_option, product_code, data } = payload;
  const order_key = state.orders.activeKey;
  let { itemKeys, itemByKey } = state.orders.itemByKey[order_key].promotions;
  const free_index = _.findIndex(
    itemByKey[promotion_id].free,
    (free) => free.source_option === source_option
  );
  const data_index = _.findIndex(
    itemByKey[promotion_id].free[free_index].data,
    (data) => data.product_code === product_code
  );
  itemByKey[promotion_id].free[free_index].data[data_index] = {
    ...itemByKey[promotion_id].free[free_index].data[data_index],
    ...data,
  };
  return {
    ...state.orders.itemByKey[order_key].promotions,
    itemByKey,
    promotion_list: calculatePromotionList(itemKeys, itemByKey),
  };
}

export function removeItemForPromotionAmount(state, payload) {
  const { order_key, source } = payload;
  let { itemKeys, itemByKey } = state.orders.itemByKey[order_key].items;
  _.forEach(source, (item) => {
    const key = _.find(
      itemKeys,
      (n) => itemByKey[n].product_code === item.product_code
    );
    if (itemByKey[key].qty === 1) {
      itemKeys = _.filter(itemKeys, (n) => n !== key);
      itemByKey = _.omit(itemByKey, [key]);
    } else {
      itemByKey[key].qty -= 1;
    }
  });
  return { itemKeys, itemByKey };
}

// only phase one
export function isQRBomOverError(state, payload) {
  const index = _.findIndex(
    state.orders.itemByKey[state.orders.activeKey].items.itemByKey[
      payload.item_key
    ].bom,
    (n) => n.ref_product_code === payload.product_code
  );
  if (!~index) return true;
  const item_qty =
    state.orders.itemByKey[state.orders.activeKey].items.itemByKey[
      payload.item_key
    ].qty;
  const sub_item =
    state.orders.itemByKey[state.orders.activeKey].items.itemByKey[
      payload.item_key
    ].bom[index];
  const qr = [...(sub_item.qr || []), payload.qr];
  return qr.length > item_qty * sub_item.qty;
}

export function getPromotionOrderLimit(promotion) {
  const { itemKeys, itemByKey } = promotion;
  const result = _.reduce(
    itemKeys,
    (pro, promotion_id) => {
      if (itemByKey[promotion_id].order_limit !== 1) return pro;
      const qty = _.reduce(
        itemByKey[promotion_id].option.itemKeys,
        (res, option_key) =>
          res + itemByKey[promotion_id].option.itemByKey[option_key].qty,
        0
      );
      return [...pro, { promotion_id, qty }];
    },
    []
  );
  return result;
}
