import { Row, Typography, Input, Col } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionRequisition } from "../../../../redux/slices/requisition";

const { setGlobalState } = actionRequisition;

const Remark = ({ disabled }) => {
  const dispatch = useDispatch();
  const remark = useSelector(
    (state) => state.requisition.documentDetail.remark
  );

  return (
    <Row gutter={[8, 8]}>
      <Col span={4} md={2} xxl={1} className="text-right">
        <Typography.Text strong>หมายเหตุ</Typography.Text>
      </Col>
      <Col span={20} md={22} xxl={11}>
        {disabled ? (
          <Typography.Text>{remark}</Typography.Text>
        ) : (
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 4 }}
            maxLength={500}
            value={remark}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "documentDetail",
                  value: { remark: e.target.value },
                })
              )
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default memo(Remark);
