/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import fitLegacyServices from '../../../../../services/fit-legacy.services';
import { Switch } from 'antd';

const LegacyRewardEventListForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  fitLegacyServices.useQueryGetLegacyRewardEventListById(
    { legacyRewardEventListId: watch('id') },
    (data) => {
      reset({
        ...data,
        formType: 'edit',
      });
    }
  );

  return (
    <div>
      <Controller
        name="isJoinEvent"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>การลงทะเบียน</Typography.Text>
              </Col>
              <Col span={20}>
                <Switch checked={value} onChange={onChange} />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="isRewardRight"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>สิทธิ์รับรางวัล</Typography.Text>
              </Col>
              <Col span={20}>
                <Switch checked={value} onChange={onChange} />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="rewardTitle"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>รางวัล</Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="รางวัล" />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="isGetReward"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>การรับรางวัล</Typography.Text>
              </Col>
              <Col span={20}>
                <Switch checked={value} onChange={onChange} />
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
};

export default LegacyRewardEventListForm;
