/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Col, Row, Button, Table, Modal, message, Select, Popconfirm,Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaCog, FaCogs, FaTrash } from "react-icons/fa";

import { axios, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from '../../../../util/panel';
import ModalProductCategory from './components/ModalProductCategory';
import ModalProduct from './components/ModalProduct'
const initState = {
    row_id: null,
    id: null,
    code: '',
    name: '',
    status: 'active',
};
const result=[]
const Index = () => {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [dataSource, setDataSource] = useState(null)
    const [dataForm, setDataForm] = useState(initState)
    const [search, setSearch] = useState('')
    const [showPromotionList, setShowPromotionList] = useState(false);
    const [showModalProduct, setShowModalProduct] = useState(false)
    const [code, setCode] = useState('')
    const [addtonull,setAddtonull]=useState('')
    //const [codetoadd, setCodetoadd] = useState('')
    //const [linenumber, setLineNumber] = useState('')
    const [status, setstatus] = useState('active')
    const [statusofselect,setStatusofselect] =useState('active')
    const [lastdata,setLastdata]=useState(null)
    const [product, setProduct] = useState([])
    const [name, setName] = useState('')
    const [Id, setId] = useState('')
    const [confirmdelete,setConfirmdelete]=useState(false)
    const [confirmdelete2,setConfirmdelete2]=useState(false)
    const [confirmadd,setConfirmAdd]=useState(false)
    const [confirmadd2,setConfirmAdd2]=useState(false)
    const [page2,setPage2]=useState(false)
    //const [lengthofdatasource, setLengthofDataSOurce] = useState('')

    const getData = (search,status) => {
        setSearch(search)
         setstatus(status)
        axios({
            method: "get",
            url: `${URL_API}/setting/master/promotion-category`,
            params: { search,status},
        })
            .then((res) => {
                setDataSource(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                message.error(
                    err && err.response
                        ? err.response.data.message
                            ? err.response.data.message
                            : err.response.data
                        : err.message
                );
            });
    };


    const handleClickSave = (data) => {
        if (data.name && data.code && data.status) {
            Modal.confirm({
                title: "ยืนยันการทำรายการ",
                content: "ต้องการบันทึกข้อมูลหรือไม่",
                okText: "บันทึก",
                cancelText: "ยกเลิก",
                onOk() {
                    return new Promise((resolve, reject) => {
                        axios({
                            method: "post",
                            url: `${URL_API}/setting/master/promotion-category`,
                            data: {
                                ...data,
                            },
                        })
                            .then((res) => {
                                resolve(res.data);
                            })
                            .catch((err) => {
                                reject(
                                    err && err.response
                                        ? err.response.data.message
                                            ? err.response.data.message
                                            : err.response.data
                                        : err.message
                                );
                            });
                    })
                        .then((value) => {
                            setShowModal(false)
                            setShowModal2(false)
                            Modal.success({
                                title: "สำเร็จ",
                                content: "บันทึกข้อมูลเรียบร้อย",
                                okText: "ปิด",
                                onOk () {
                                    setConfirmdelete2(!confirmdelete2)
                                    setConfirmAdd2(!confirmadd2)
                                    getData(search,status);
                                    setPage2(!page2)
                                    //
                                    console.log("บันทึกจริง")
                                    
                                    setProduct([])
                                },
                            });
                        })
                        .catch((reason) => {
                            Modal.error({
                                title: "ผิดพลาด",
                                content: reason,
                                okText: "ปิด",
                            });
                        });
                },
            });
        } else {
            message.warning("กรุณากรอกข้อมูลรหัสและชื่อหมวดหมู่โปรโมชัน");
        }

    };

    const handleClickDelete = (data) => {
        Modal.confirm({
            title: "ยืนยันการทำรายการ",
            content: "ต้องการลบข้อมูลหรือไม่",
            okText: "บันทึก",
            cancelText: "ยกเลิก",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios({
                        method: "post",
                        url: `${URL_API}/setting/master/promotion-category/delete`,
                        data: {
                            ...data,
                        },
                    })
                        .then((res) => {
                            console.log("res.data_fromdelete", res.data)
                            resolve(res.data);
                        })
                        .catch((err) => {
                            reject(
                                err && err.response
                                    ? err.response.data.message
                                        ? err.response.data.message
                                        : err.response.data
                                    : err.message
                            );
                        });
                })
                    .then((value) => {
                        setShowModal(false)
                        Modal.success({
                            title: "สำเร็จ",
                            content: "ลบข้อมูลเรียบร้อย",
                            okText: "ปิด",
                            onOk() {
                                getData(search);
                            },
                        });
                    })
                    .catch((reason) => {
                        Modal.error({
                            title: "ผิดพลาด",
                            content: reason,
                            okText: "ปิด",
                        });
                    });
            },
        });
    }
    const { Option } = Select;


    useEffect(() => {
        getData(search,status);
    }, [search,status]);
    



    //lastdata&&console.log("lastdata",lastdata)
    dataSource&&console.log("dataSource in index",dataSource)
    dataSource&&console.log("dataSourceproduct in index",product)
    console.log("confirmdelete",confirmdelete)
    console.log("dataForm",dataForm)
    //console.log(status);
    // dataSource&&console.log("codetoadd",lengthofdatasource);
    // dataSource&&console.log("last",lengthofdatasource[lengthofdatasource.length-1]+1);
    // console.log(result)

    function handleChange(value) {
        const statusofselect = value;
        setStatusofselect(...statusofselect)
        console.log("Selectedstatus",statusofselect)
      }


    return (
        <Panel>
            <PanelHeader>
                หมวดหมู่โปรโมชัน
            </PanelHeader>
            <PanelBody>
                <Row gutter={16} className="mb-2">
                    <Col md={8}>
                        <Input.Search placeholder="ค้นหารหัสหมวดหมู่, ชื่อหมวดหมู่" allowClear onChange={(e) => setSearch(e.target.value?.trim() || "")} onSearch={() => getData(search)} />
                    </Col>
                    <Col md={4}>
            <Select defaultValue="active" mt={2} style={{ width: 120 }} onChange={(e) =>{console.log(e);setstatus(e);handleChange(e)}}>
                                    <Option value="active">ใช้งาน</Option>
                                    <Option value="inactive">ไม่ใช้งาน</Option>
            </Select>
          </Col>
                    <Col md={12} className="d-flex justify-content-end">
                        <Button type="primary" onClick={() => {
                            setShowModal2(true)
                            setDataForm(initState)
                            setShowPromotionList(true)

                            const result = dataSource.map(function (data) {
                                return data.row_id
                              })

                            setLastdata(result[result.length-1]+1)//ตำแหน่งของ category ที่จะเพิ่ม
                        }}
                        >
                            เพิ่มหมวดหมู่โปรโมชัน
                        </Button>
                    </Col>
                </Row>
                <Table
                    bordered
                    size="small"
                    loading={loading}
                    rowKey="row_id"
                    dataSource={dataSource ? dataSource : []}
                    pagination={{
                        hideOnSinglePage: true,
                    }}

                >
                    <Table.Column title="ลำดับ" dataIndex="row_number" />
                    <Table.Column className="hide" title="ลำดับ" dataIndex="row_id" />
                    <Table.Column title="รหัสหมวดหมู่" dataIndex="code" />
                    <Table.Column title="ชื่อหมวดหมู่" dataIndex="name" />
                    <Table.Column title="สถานะ" dataIndex="status"  render={ (_text, _record, index) =>(<Typography.Text className="mr-2">{_text === "active" ? "ใช้งาน" : "ไม่ใช้งาน"}</Typography.Text>)} />
                    <Table.Column
                        align="center"
                        title={<FaCogs />}
                        render={({ row_id, code, name, status, product }) =>
                        (
                            <>
                                <Button
                                    size="small"
                                    shape="circle-outline"
                                    type="primary"
                                    className="mr-2"
                                    onClick={() => {
                                        setShowModal(true)
                                        setShowPromotionList(true)
                                        setDataForm({ ...dataForm, row_id, code, name, status })
                                        setId(row_id)
                                        setCode(code)
                                        setProduct(product)
                                        setName(name)
                                    }}
                                >
                                    <FaCog />
                                </Button>
                                {/* <Button
                                    size="small"
                                    shape="circle-outline"
                                    type="danger"
                                    className="mr-3"
                                    onClick={() => {
                                        handleClickDelete({ ...dataForm, id: row_id, code, name, status })
                                    }}
                                >
                                    <FaTrash />
                                </Button> */}
                            </>

                        )}
                    />
                </Table>



                <Modal
                    title={'แก้ไขหมวดหมู่โปรโมชัน'}
                    visible={showModal}
                    onCancel={() => { setShowModal(false); setShowPromotionList(false); setDataForm(initState); setProduct([]);setConfirmdelete(false);setConfirmdelete2(!confirmdelete2);/*setConfirmAdd(false);setConfirmAdd2(!confirmadd2)*/;dataForm.code='';console.log("ยกเลิก");/*setConfirmAdd(!confirmadd)*/}}
                    onOk={() =>{handleClickSave(dataForm);setConfirmdelete(true);setConfirmdelete2(!confirmdelete2);/*setConfirmAdd(true);setConfirmAdd2(!confirmadd2)*/;console.log("บันทึก")}}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    width={800}
                >
                    <Row gutter={[8, 16]}>
                    <Col ml={2}>
                        <Col ml={2}>
                            <label>รหัสหมวดหมู่</label>
                            <Input name='code' value={dataForm.code} disabled onChange={(e) => setDataForm({ ...dataForm, code: e.target.value })} onInput={(e) => setCode(e.target.value)} />
                        </Col>
                        </Col>
                        <Col ml={2}>
                        <Col ml={2}>
                            <label>ชื่อหมวดหมู่</label>
                            <Input name="name" value={dataForm.name} onChange={(e) => setDataForm({ ...dataForm, name: e.target.value })} onInput={(e) => setName(e.target.value)} />
                        </Col>
                        </Col>
                        <Col ml={0}>
                        <Row gutter={[8,16] }><label style={{paddingLeft: "9px"}}>สถานะ</label></Row>
                            <Col md={16}>
                                <Select value={dataForm.status==='inactive'?'inactive':'active'} mt={2} style={{ width: 120 }} onChange={(e) => {setDataForm({ ...dataForm, status: e });console.log(e);}}>
                                    <Select.Option value='active'>ใช้งาน</Select.Option>
                                    <Select.Option value='inactive'>ไม่ใช้งาน</Select.Option>
                                </Select>
                            </Col>
                            <Col md={8} mt={3} className="d-flex justify-content-end">
                                <Button type="primary" onClick={() => {
                                    setShowModalProduct(true)
                                }}>
                                    เพิ่มสินค้า
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                    <ModalProductCategory onCancel={() => {setShowPromotionList(false)}}
                        
                        visible={showPromotionList}
                        codecategory={dataForm.code}
                        productcategory={product}
                        statesetcode={setCode}
                        namecategory={name}
                        idfromindex={Id}
                        productvisible={showModalProduct}
                        setproductvisible={setShowModalProduct}
                        stategetdata={getData}
                        statesetproduct={setProduct}
                        searchindex={search}
                        statusindex={status}
                        confirmdelete={confirmdelete}
                        stateconfirmdelete={setConfirmdelete}
                        confirmdelete2={confirmdelete2}
                        stateconfirmdelete2={setConfirmdelete2}
                        page2={page2}
                        confirmadd={confirmadd}
                        statesetconfirmadd={setConfirmAdd}
                        confirmadd2={confirmadd2}
                        setConfirmAdd2={setConfirmAdd2}
                    />
                </Modal>
                <Modal
                    title={'เพิ่มหมวดหมู่โปรโมชัน'}
                    visible={showModal2}
                    onCancel={() => { setShowModal2(false); setShowPromotionList(false); setDataForm(initState); setProduct([]);setConfirmdelete(false);setConfirmdelete2(!confirmdelete2);dataForm.code='';console.log("ยกเลิก") }}
                    onOk={() => {handleClickSave(dataForm);setConfirmdelete(true);setConfirmdelete2(!confirmdelete2);console.log("บันทึก")}}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    width={800}
                >
                    <Row gutter={[8, 16]}>
                        <Col>
                            <label>รหัสหมวดหมู่</label>
                            <Input name='code' value={dataForm.code} onChange={(e) => setDataForm({ ...dataForm, code: e.target.value })} onInput={(e) => setCode(e.target.value)} />
                        </Col>
                        <Col>
                            <label>ชื่อหมวดหมู่</label>
                            <Input name="name" value={dataForm.name} onChange={(e) => setDataForm({ ...dataForm, name: e.target.value })} onInput={(e) => setName(e.target.value)} />
                        </Col>
                        <Col>
                        <Row gutter={[8,16] }><label style={{paddingLeft: "9px"}}>สถานะ</label></Row>
                            <Col md={8}>
                                <Select value={dataForm.status==='inactive'?'inactive':'active'} mt={2} style={{ width: 120 }} onChange={(e) => {setDataForm({ ...dataForm, status: e })}}>
                                    <Select.Option value='active'>ใช้งาน</Select.Option>
                                    <Select.Option value='inactive'>ไม่ใช้งาน</Select.Option>
                                </Select>
                            </Col>
                            <Col md={16} className="d-flex justify-content-end">
                                    <Button type="primary" onClick={() => {
                                        (dataForm.code && dataForm.name)? 
                                        setShowModalProduct(true):Modal.error({
                                            content: "กรุณาใส่รหัสหมวดหมู่และชื่อหมวดหมู่",
                                            okText: "ปิด",
                                        }); setId(lastdata)
                                    }}>
                                        เพิ่มสินค้า
                                    </Button>
                            </Col> 
                        </Col>
                    </Row>
                     <ModalProductCategory onCancel={() => {setShowPromotionList(false)}}
                        visible={showPromotionList}
                        codecategory={dataForm.code}
                        statesetcode={setCode}
                        productcategory={product}
                        namecategory={name}
                        idfromindex={Id}
                        productvisible={showModalProduct}
                        setproductvisible={setShowModalProduct}
                        //
                        stategetdata={getData}
                        statesetproduct={setProduct}
                        searchindex={search}
                        statusindex={status}
                        confirmdelete={confirmdelete}
                        stateconfirmdelete={setConfirmdelete}
                        confirmdelete2={confirmdelete2}
                        stateconfirmdelete2={setConfirmdelete2}
                        page2={page2}
                        
                    />
                </Modal>
            </PanelBody>
        </Panel>

    )
};

export default Index

