import { Modal, Upload } from "antd";
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { onUploadFile } from '../../hooks/onUploadFile';

const fileAccept = "image/* , video/* , application/*"
const UploadMultipleFile = ({
    accept = fileAccept,
    disabled = false,
    files = {}, // load file itemByKey / itemKeys
    onChange = () => { }, // callback after response from api
    onRemove = () => { } // remove by key in itemBykey
}) => {
    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState()
    const [previewOpen, setPreviewOpen] = useState(false)

    useEffect(() => {
        const { itemKeys = [], itemByKey = {} } = files;
        let loadFileList = _.map(itemKeys, (key) => {
            let item = itemByKey[key]
            return {
                id: item?.id,
                name: item.file_name,
                uid: item.id,
                status: 'done',
                url: item?.azure_url,
                media: item
            }
        })
        setFileList(loadFileList)
    }, [files])

    return (
        <>
            <Upload
                accept={accept}
                disabled={disabled}
                fileList={fileList}
                listType="picture-card"
                multiple
                customRequest={({ file }) => onUploadFile({ file, setFileList, onChange })}
                onPreview={(file) => {
                    setPreviewImage(file)
                    setPreviewOpen(true)
                }}
                onRemove={(file) => {
                    let key = _.find(files?.itemByKey, { id: file?.id })?.key;
                    Modal.confirm({
                        title: 'ยืนยันการทำรายการ',
                        content: 'ต้องการลบรายการนี้หรือไม่ ?',
                        okText: 'ตกลง',
                        cancelText: 'ยกเลิก',
                        onOk: () => {
                            let update = _.filter(fileList, (n) => n?.id !== file?.id)
                            onRemove(key)
                            setFileList(update)
                        }
                    });
                }}
            >
                + อัพโหลด
            </Upload>
            <Modal
                zIndex={1081}
                footer={false}
                visible={previewOpen}
                onCancel={() => {
                    setPreviewOpen(false)
                }}>

                <img
                    src={previewImage?.url}
                    alt={`${previewImage?.name || "preview-img"}`}
                    className="mt-4"
                    style={{
                        width: '100%',
                    }}
                />
            </Modal>
        </>
    )
}

export default UploadMultipleFile
