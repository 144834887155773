/* eslint-disable no-unused-vars */
import { Breadcrumb } from "antd";
import React, { useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import TravelPointContext, {
  initState,
  reducer,
} from "./contexts/travel-point-context";
import TravelPointFilter from "./components/TravelPointFilter";
import TravelPointRedeem from "./components/TravelPointRedeem";
import TravelPointCurrent from "./components/TravelPointCurrent";
import TravelPointTable from "./components/TravelPointTable";
import { Col, Row } from "antd";
import { URL_API, getUser } from "../../../utils";
import { useHttp } from "../../../hooks/http";

const TravelPoint = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const user = getUser();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/tp/travel-point/partner-point`,
      token: true,
      params: {
        userId: user.id,
        ...state.filter,
      },
      initialLoad: false,
    },
    []
  );

  useEffect(() => {
    if (data?.partner.length > 0) {
      dispatch({
        type: "setRedeem",
        payload: {
          partner_code: data?.partner[0]?.partner_code,
          partner_name:
            "คุณ" +
            data?.partner[0]?.first_name_th +
            " " +
            data?.partner[0]?.sur_name_th,
          tp: "",
          remark: "",
          trip_package: "",
        },
      });

      dispatch({
        type: "setPoint",
        payload: {
          total_tp: data?.balance_tp[0]?.tp_total,
          redeem_tp: data?.balance_tp[0]?.tp_use_redeem,
          balance_tp: data?.balance_tp[0]?.tp_remain,
        },
      });
    } else {
      dispatch({
        type: "setRedeem",
        payload: {
          partner_code: "",
          partner_name: "",
          tp: "",
          remark: "",
          trip_package: "",
        },
      });

      dispatch({
        type: "setPoint",
        payload: {
          total_tp: "",
          redeem_tp: "",
          balance_tp: "",
        },
      });
    }
  }, [data]);

  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item>
          <Link to="/">หน้าหลัก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Travel Point</Breadcrumb.Item>
      </Breadcrumb>
      <TravelPointContext.Provider value={{ state, dispatch }}>
        <Panel>
          <PanelHeader>Travel Point</PanelHeader>
          <PanelBody>
            <Row
              type="flex"
              justify="space-around"
              //className="align-items-center align-items-center"
            >
              <Col
                span={6}
                style={{
                  border: "1px dashed rgb(217,217,217)",
                  borderRadius: "6px",
                }}
              >
                <TravelPointFilter
                  reload={() => {
                    dispatch({
                      type: "setPage",
                      payload: 1,
                    });
                    reload();
                  }}
                />
              </Col>
              <Col
                span={11}
                style={{
                  border: "1px dashed rgb(217,217,217)",
                  borderRadius: "6px",
                }}
              >
                <TravelPointRedeem 
                reload={() => {
                    dispatch({
                      type: "setPage",
                      payload: 1,
                    });
                    reload();
                  }} />
              </Col>
              <Col span={6}>
                <TravelPointCurrent />
              </Col>
            </Row>
            <Row>
              <TravelPointTable
                loading={loading}
                dataSource={data?.travel_point}
              />
            </Row>
          </PanelBody>
        </Panel>
      </TravelPointContext.Provider>
    </>
  );
};
export default TravelPoint;
