/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import XLSX from "xlsx";
import { useHttp } from "../../../../../hooks/http";
import manageWebsiteService from "../../../../../services/manage-website.service";
import { getUser, URL_API, _ } from "../../../../../utils";
import useAsync from "../../../../../utils/useAsync";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectCategory from "../../components/SelectCategory";
// import Summary from '../components/Summary';
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";

const Index = () => {
  const { Text } = Typography;
  const user = getUser();
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: user ? [user.branch_code] : [""],
      query: "",
      page: 1,
      limit: 10,
      category: "",
      subCategory: "",
      group: "",
    },
    summaryModal: {
      visible: false,
    },
  };
  const [checkBranch, setCheckBranch] = useState([]);
  const [state, dispatch] = useReducer(reducer, initState);
  const [branchGroup, setBranchGroup] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 50;
  const toDay = moment().format("DD/MM/YYYY");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-product-branch`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
        category: state.filter.category,
        subCategory: state.filter.subCategory,
        group: state.filter.group,
      },
      initialLoad: false,
    },
    []
  );
  let newData = data ? data : [];

  useEffect(() => {
    let selected_branch = _.map(
      _.map(state.filter.branch, (i) =>
        _.find(checkBranch, (o) => o.code === i)
      ),
      (n) => (n?.name !== undefined ? n?.name : "ทั้งหมด")
    );

    setSelectedBranch(selected_branch);

    //----------------------หารายการของแต่ละสาขา
    const find_key = _.map(
      _.keys(
        _.omit(data?.data[0], [
          "sub_category",
          "category",
          "groups",
          "product_name",
          "product_code",
          "row_id",
          "total_qty",
          "type",
        ])
      ),
      (name) => name
    );

    // //----------------------ตัดคำด้านหลัง 3 รอบ ["_pv","_qty","_amount"]
    const cut_qty = find_key.map((n) => n.split("_qty", 1));

    //----------------------หา uniq สาขา
    const uniq_branch = _.uniq(cut_qty.map((n) => n[0]));

    setBranchGroup(uniq_branch);
  }, [data, state.filter.branch, checkBranch]);

  const { execute: getAllBranch } = useAsync(
    manageWebsiteService.getAllBranch,
    {
      immediate: true,
      onSuccess: (data) => {
        setCheckBranch(data);
      },
    }
  );

  const exportToExcel = () => {
    let title_table = [
      "ลำดับ",
      "รหัสสินค้า",
      "ชื่อสินค้า",
      "Sub Category",
      "รวมทั้งหมด",
    ];
    _.forEach(branchGroup, (branch) => {
      title_table.push(
        branch === "total"
          ? "รวมทั้งหมด"
          : branch
              .toLowerCase()
              .replace(/_/g, " ")
              .replace(/(?: |\b)(\w)/g, function (key, p1) {
                return key.toUpperCase();
              })
      );
    });

    const exportDataData = newData ? newData.data : [];
    if (exportDataData.length !== 0) {
      let excelData = [
        ["รายงานจ่ายสินค้าตามสาขา"],
        [
          `สาขา ${
            state.filter.branch === ""
              ? "ทั่งหมด"
              : selectedBranch.map((name) => name)
          }`,
          "",
          `วันที่  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
        ],
        title_table,
      ];

      exportDataData.forEach((data) => {
        if (data.row_id == exportDataData.length) {
          data = { ...data, row_id: "รวม" };
        }
        let row_data = [
          { v: data.row_id, s: { alignment: { horizontal: "center" } } },
          { v: data.product_code ? data.product_code : "" },
          { v: data.product_name ? data.product_name : "" },
          { v: data.sub_category ? data.sub_category : "" },
          {
            v: data.total_qty,
            t: "n",
            s: {
              numFmt: "#,##0",
              alignment: { horizontal: "right" },
            },
          },
        ];
        _.forEach(Object.keys(data), (branch) => {
          if (
            branch != "row_id" &&
            branch != "product_code" &&
            branch != "product_name" &&
            branch != "sub_category" &&
            branch != "category" &&
            branch != "groups" &&
            branch != "total_qty"
          ) {
            row_data.push({
              v: data[branch],
              t: "n",
              s: {
                numFmt: "#,##0",
                alignment: { horizontal: "right" },
              },
            });
          }
        });

        excelData = [...excelData, row_data];
      });

      let merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
      ];
      const wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 40 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานจ่ายสินค้าตามสาขา");
      XLSX.writeFile(wb, "รายงานจ่ายสินค้าตามสาขา.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  //const columns = [{"title" : "test", "dataIndex" : "xx"}]
  useEffect(() => {
    let newColumns = [
      {
        title: "ลำดับ",
        dataIndex: "row_id",
        width: 50,
        align: "center",
        fixed: "left",
      },
      {
        title: "รหัส",
        dataIndex: "product_code",
        width: 80,
        align: "center",
        fixed: "left",
      },
      {
        title: "ชื่อสินค้า",
        dataIndex: "product_name",
        fixed: "left",
        width: 300,
      },
      {
        title: "Sub Category",
        dataIndex: "sub_category",
        align: "left",
      },
      {
        title: "Total",
        dataIndex: "total_qty",
        align: "left",
        render: (value) => (value === 0 ? " " : numeral(value).format("0,0")),
      },
    ];
    _.forEach(branchGroup, (branch, i) => {
      newColumns.push({
        title: branch
          .toLowerCase()
          .replace(/_/g, " ")
          .replace(/(?: |\b)(\w)/g, function (key, p1) {
            return key.toUpperCase();
          }),
        dataIndex: `${branch}_qty`,
        align: "right",
        render: (value) => (value === 0 ? " " : numeral(value).format("0,0")),
      });
    });

    setColumns(newColumns);
  }, [branchGroup]);
  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานจ่ายสินค้าตามสาขา</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={8} className=" p-r-15">
              <Input
                placeholder="รหัสสินค้า, ชื่อสินค้า"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  });
                }}
              />
            </Col>

            <Col span={1} align="center">
              <Text strong>วันที่</Text>
            </Col>
            <Col span={8} className=" p-r-15">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from:
                        moment(e[0]).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                      date_to:
                        moment(e[1]).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                    },
                  })
                }
              />
            </Col>
            <Col span={6}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={1} align="center">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={7}>
              <SelectBranch
                mode="multiple"
                userLogin
                allowDisableBranch
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100 "
                withAll={user.branch_count > 1}
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>

            <Col span={2} align="center">
              <Text strong>Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                category="category"
                defaultValue=""
                withAll
                className="w-100"
                onChange={(e) => {
                  dispatch({ type: "setFilter", payload: { category: e } });
                }}
              />
            </Col>
            <Col span={2} align="center">
              <Text strong>Sub Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                category="subcategory"
                defaultValue=""
                withAll
                className="w-100"
                onChange={(e) => {
                  dispatch({ type: "setFilter", payload: { subCategory: e } });
                }}
              />
            </Col>
            <Col span={2} align="center">
              <Text strong>Group</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                category="group"
                defaultValue=""
                withAll
                className="w-100"
                onChange={(e) => {
                  dispatch({ type: "setFilter", payload: { group: e } });
                }}
              />
            </Col>
          </Row>
          {/* <Table
            bordered
            size="small"
            loading={loading}
            dataSource={newData.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: newData.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="row_id"
              width={50}
              align="center"
              fixed="left"
            />
            <Table.Column
              title="รหัส"
              dataIndex="product_code"
              width={80}
              align="center"
              fixed="left"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name"
              fixed="left"
              width={300}
            />
            <Table.Column
              title="Sub Category"
              dataIndex="sub_category"
              align="left"
            />
            {_.map(branchGroup, (branch, i) => {
              return (
                <Table.colum
                  key={i}
                  title={branch
                    .toLowerCase()
                    .replace(/_/g, " ")
                    .replace(/(?: |\b)(\w)/g, function (key, p1) {
                      return key.toUpperCase();
                    })}
                  dataIndex={`${branch}_qty`}
                  align="right"
                />
              );
            })}
          </Table> */}
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={error ? [] : data?.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: newData.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
            columns={columns}
          />
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
