import { ErrorMessage } from '@hookform/error-message';
import { Checkbox, Col, Input, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import ImageUpload from '../../../fit-studio/components/upload/ImageUpload';
import { MdDelete } from 'react-icons/md';

const AnswerCard = ({
  item,
  index,
  control,
  errors,
  remove,
  setValue,
  fields,
}) => {
  const [view, setView] = useState(false);

  useEffect(() => {
    if (item.iconImageUrl) {
      setView(true);
    }
  }, [item.iconImageUrl]);

  return (
    <div key={item.id}>
      <Controller
        name={`answers.${index}.answer`}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำตอบ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={14}>
                <Input.TextArea
                  value={value}
                  onChange={onChange}
                  placeholder="คำตอบ"
                  rows={2}
                />
              </Col>
              <Col span={5}>
                <Controller
                  name={`answers.${index}.isCorrect`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          checked={value}
                          onChange={(value) => {
                            if (value) {
                              for (let i = 0; i < fields.length; i++) {
                                setValue(`answers.${i}.isCorrect`, false);
                              }
                            }
                            onChange(value);
                          }}
                          style={{ marginLeft: 5, marginRight: 5 }}
                        />
                        <Typography.Text>คำตอบที่ถูกต้อง</Typography.Text>
                      </div>
                    );
                  }}
                />
              </Col>
              <Col span={1}>
                <div
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <MdDelete />
                </div>
              </Col>
              <ErrorMessage
                errors={errors}
                name={`answers.${index}.answer`}
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name={`answers.${index}.iconImageUrl`}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}></Col>
              <Col span={20}>
                <div style={{ display: 'flex' }}>
                  <Checkbox
                    checked={view}
                    onChange={(value) => {
                      if (!value.target.checked) {
                        onChange('');
                      }
                      setView(value.target.checked);
                    }}
                    style={{ marginRight: 5 }}
                  />
                  <Typography.Text>ภาพคำตอบ</Typography.Text>
                </div>
                {view && (
                  <ImageUpload
                    value={value}
                    onChange={onChange}
                    width={100}
                    height={100}
                    errors={errors}
                    notRequired
                  />
                )}
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
};

export default AnswerCard;
