/* eslint-disable no-unused-vars */
import { Button, Col, Input, Modal, notification, Pagination, Row, Typography } from 'antd'
import Table from '../../components/Table'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useHttp } from '../../../../../hooks/http'
import { URL_API } from '../../../../../utils'
import SelectBank from '../../../../../components/SelectBank'

const Model = {
    query: "",
    bank: "",
    bank_name: undefined
}
const BookBankHistory = ({ show, ...props }) => {

    const [dataSource, setDataSource] = useState([])
    const [page, setPage] = useState(1);
    const limit = 5;
    const [totalPage, setTotalPage] = useState(0)
    const [criteria, setCriteria] = useState(Model)

    const [loading, data, error, reload] = useHttp({
        method: 'GET',
        url: `${URL_API}/pos/ethics/bookbank-history`,
        params: {
            code: props.code,
            limit,
            offset: (page - 1) * limit,
            ...criteria,
            bank: (criteria.bank ? criteria.bank : ""),
        },
        token: true
    }, [page, criteria]);

    if (error) {
        notification.error({
            description: error,
            placement: "bottomRight"
        });
    }

    const columns = [
        {
            title: 'ลำดับ',
            key: 'index',
            dataIndex: 'row_id',
            render: text => <p className="m-0 text-center">{text}</p>
        },
        {
            title: 'ธนาคาร',
            key: 'bank',
            dataIndex: 'bank_name',
            render: text => <p className="m-0 text-center">{text}</p>
        },
        {
            title: 'ชื่อบัญชี',
            key: 'name',
            dataIndex: 'account_name',
            render: text => <p className="m-0 text-center">{text}</p>
        },
        {
            title: 'เลขที่บัญชี',
            key: 'number',
            dataIndex: 'account_number',
            render: text => <p className="m-0 text-center">{text}</p>
        },
        {
            title: 'สาขา',
            key: 'brach',
            dataIndex: 'bank_branch',
            render: text => <p className="m-0 text-center">{text}</p>
        },
        {
            title: 'วันที่แก้ไข',
            key: 'modifydate',
            dataIndex: 'create_date',
            render: text => <p className="m-0 text-center">{moment(text).format("DD/MM/YYYY hh:mm")}</p>
        },
        {
            title: 'ประเภทบัญชี',
            key: 'eft_type',
            dataIndex: 'eft_type',
            render: text => <p className="m-0 text-center">{text === 'Person'? "บุคคลทั่วไป":"นิติบุคคล"}</p>
        },

    ]

    useEffect(() => {
        let newData = [];
        let total = 0;
        if (data?.data && data?.data) {
            newData = data?.data.map(n => ({ ...n, key: n.id }))
            total = data.total
        }
        setDataSource(newData)
        setTotalPage(total)
    }, [data])
    return (
        <>
            <Modal
                width={700}
                style={{ top: 20 }}
                visible={show}
                zIndex={2000}
                {...props}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="ปิด"
            >
                <Typography.Title level={4}>ประวัติบัญชีธนาคาร</Typography.Title>
                <Row gutter={8}>
                    <Col md={7}>
                        <Input
                            size="small"
                            placeholder="ชื่อบัญชี,เลขที่บัญชี"
                            className="f-s-12"
                            onChange={e => setCriteria({ ...criteria, query: e.target.value })}
                        />
                    </Col>
                    <Col md={7}>
                        <SelectBank
                            showSearch
                            size="small"
                            placeholder="ธนาคาร"
                            className="w-100 f-s-12"
                            dropdownClassName="w-25"
                            allowClear
                            dropdownStyle={{ zIndex: '2010' }}
                            withIcon={true}
                            filterOption={(input, option) =>
                              option.props.source.name_th.indexOf(input.toLowerCase()) >=
                              0
                            }
                            value={criteria.bank_name}
                            onChange={(value, option) => setCriteria({ ...criteria, bank: value, bank_name: option?.props.source.name_th })}
                        />

                    </Col>
                    <Col md={4}>
                        <Button type="primary" ghost size="small" icon="search" onClick={reload} />
                    </Col>
                </Row>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data?.data}
                    className="mt-2 f-s-12"
                />
                {
                    totalPage === 0 ?
                        null
                        :
                        <Pagination size="small" pageSize={limit} current={page} total={data && data.total ? data.total : 0} className="text-center" onChange={e => setPage(e)} />
                }
            </Modal>
        </>
    )
}
export default BookBankHistory