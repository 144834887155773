/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button, Form,
  Input,
  Modal,
  notification,
  Row
} from "antd";
import React, { useEffect } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";

function NewsCategory({ history, match, form, ...props }) {
  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img", "mobile_img"]),
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let actionPathUrl;

            if (match.params.id !== "add") {
              actionPathUrl =
                "/manage-website/news/category/" + match.params.id + "/update";
            } else {
              actionPathUrl = "/manage-website/news/category";
            }

            axios({
              method: "post",
              baseURL: URL_API,
              url: actionPathUrl,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.goBack();
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const loadContent = (id) => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/news/category/" + match.params.id,
    })
      .then((res) => {
        let data = res.data?.data;

        form.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (match.params.id !== "add") {
      loadContent(match.params.id);
    }
  }, [match.params.id]);

  const { getFieldDecorator } = form;
  return (
    <>
      <Panel>
        <PanelHeader>Category</PanelHeader>
        <PanelBody>
          <Row>
            <Row>
              <Form.Item label="Category Name :">
                {getFieldDecorator("title", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุ Title",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                SAVE
              </Button>
            </Row>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("news-category")(NewsCategory);
