/* eslint-disable no-unused-vars */
import { Button, Col, Input, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useContext } from "react";
import TravelPointContext from "../contexts/travel-point-context";


const TravelPointFilter = ({reload})=> {
const { state, dispatch } = useContext(TravelPointContext);
  return (
    <>
      <Row
        type="flex"
        align="middle"
        className="align-items-center pt-4 pl-3 align-items-center"
      >
        <Col span={5} type="flex" align="end" className="pr-3">
          <Text strong>ค้นหา :</Text>
        </Col>
        <Col span={17} type="flex">
          <Input.Search allowClear placeholder="รหัสสมาชิก" 
          onChange={(e) => {
            dispatch({
              type: "setFilter",
              payload: { partner_code: e.target.value },
            });
          }}
          onSearch={reload}
          />
        </Col>
      </Row>
      <Row
        type="flex"
        align="middle"
        //justify="end"
        className="align-items-center pt-3 pl-3 pb-3 align-items-center"
      >
        <Col span={22} align="end">
          <Button
            type="primary"
            onClick={reload}
          >
            แสดงรายงาน
          </Button>
        </Col>
      </Row>
    </>
  );
}
export default TravelPointFilter;
