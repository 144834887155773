import React from "react";
import "../../../../scss/pos.scss";
import PosSidebar from "./PosSidebar";
import PosTopMenu from "./PosTopMenu";

const PosLayout = ({ children }) => (
  <div className="pos-main d-flex">
    <PosSidebar />
    <div className="w-100" style={{ overflowX: "auto" }}>
      <PosTopMenu />
      {children}
    </div>
  </div>
);

export default PosLayout;
