import { Card, Tabs } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionEthics } from "../../../../../redux/slices/ethics";
import RemarkPanel from "../Panel/RemarkPanel";

export default function RemarkTabs() {
  const dispatch = useDispatch();
  const active = useSelector(
    (state) => state.ethics.form.active_tab.remark,
    shallowEqual
  );
  const { SET_ACTIVE_TAB } = actionEthics;

  return (
    <Card
      bordered={false}
      size="small"
      className="mb-3"
      style={{ boxShadow: "0px 2px 4px #ccc" }}
    >
      <Tabs
        size="small"
        activeKey={active}
        onChange={(e) => dispatch(SET_ACTIVE_TAB({ key: "remark", value: e }))}
        tabBarGutter={0}
      >
        <Tabs.TabPane tab="หมายเหตุ" key="remark">
          <RemarkPanel />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}
