/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const FullTabs = ({ list = [], active = "", onClick, ...props }) => {
    return (
        <ul className="pos-fulltabs">
            {
                list.map(n => <li className={n.value === active ? `active` : ``} key={n.value} onClick={() => onClick(n.value)}><a>{n.title}</a></li>)
            }
        </ul>
    )
}

export default FullTabs;
