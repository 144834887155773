import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { _ } from "../../../../../utils";
import Detail from "./Detail";
import InputRemark from "./InputRemark";
import Result from "./Result";

const Index = () => {
  const { process: process_params } = useParams();
  const document_status = useSelector(
    (state) => state.claim.documentDetail.status
  );
  const isPreview = useSelector((state) => state.claim.isPreview);

  if (!_.includes(["pending", "result", "history"], process_params))
    return null;

  return (
    <>
      {process_params === "pending" ? (
        document_status === "wait-manager" && (
          <>
            <Detail type="branch" />
            {!isPreview && <InputRemark type="manager" />}
          </>
        )
      ) : process_params === "result" ? (
        <>
          <Result />
          <Detail type="branch" />
          <Detail type="manager" />
          {!isPreview && <InputRemark type="complete" />}
        </>
      ) : document_status === "cancel" ? (
        <>
          <Result />
          <Detail type="branch" />
          <Detail type="cancel" />
        </>
      ) : (
        <>
          <Result />
          <Detail type="branch" />
          <Detail type="manager" />
          <Detail type="complete" />
        </>
      )}
    </>
  );
};

export default Index;
