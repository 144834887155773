/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import ImageUpload from '../../../fit-studio/components/upload/ImageUpload';
import fitQuizServices from '../../../../../services/fit-quiz.services';

const LegacyQuizCategoryProductForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useFormContext();
  const [rewardLists, setRewardLists] = useState([]);

  fitQuizServices.useQueryGetLegacyQuizRewardLists(
    { take: -1, page: 1, onlyActive: false },
    (data) => {
      setRewardLists(data.data);
    }
  );

  fitQuizServices.useQueryGetLegacyQuizCategoryProductById(
    { quizCategoryProductId: watch('id') },
    (data) => {
      reset({
        ...data,
        rewardId: data.reward.id,
        formType: 'edit',
      });
    }
  );

  return (
    <div>
      <Controller
        name="imageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={24}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={200}
                  height={200}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  ชื่อผลิตภัณฑ์ <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input value={value} onChange={onChange} placeholder="ชื่อ" />
              </Col>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="rewardId"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={40}>
                <Typography.Text>
                  เลือกเหรียญรางวัล <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Select
                  value={value}
                  style={{ width: '100%' }}
                  onChange={onChange}
                >
                  {rewardLists &&
                    rewardLists.length > 0 &&
                    rewardLists.map((item) => {
                      return (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          style={{
                            ...(item.products.length > 0 && {
                              color: '#dadddd',
                            }),
                          }}
                        >
                          {item.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <ErrorMessage
                errors={errors}
                name="rewardId"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
    </div>
  );
};

export default LegacyQuizCategoryProductForm;
