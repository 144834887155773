import React from "react";
import { Route, Switch } from "react-router-dom";

import LotRoute from "./lot/routes";
import SetRoute from "./set/routes";
import TransferRoute from "./transfer/routes";
import BalanceRoute from "./balance/routes";
import BillRoute from "./bill/routes";

import Error404 from "../Error404";

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/lot`} component={LotRoute} />
    <Route path={`${match.path}/set`} component={SetRoute} />
    <Route path={`${match.path}/transfer`} component={TransferRoute} />
    <Route path={`${match.path}/balance`} component={BalanceRoute} />
    <Route path={`${match.path}/bill`} component={BillRoute} />
    <Route component={Error404} />
  </Switch>
);
