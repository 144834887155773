import { combineReducers } from "redux";

// reducers
import auth from "./auth-reducer";
import menu from "./menu-reducer";
import pos from "../../views/pages/pos/ducks";
import promotion from "../slices/promotion";
import transfer from "../slices/transfer";
import legal from "../slices/legal";
import inventoryCount from "../slices/inventory-count";
import requisition from "../slices/requisition";
import promotionList from "../slices/promotion-list";
import voucher from "../slices/voucher";
import settingMaster from "../slices/setting-master";
import productRequest from "../slices/product-request";
import product from "../slices/product";
import claim from "../slices/claim";
import storeQR from "../slices/store-qr";
import user from "../slices/user";
import marketing from "../slices/marketing";
import ethics from "../slices/ethics";
import member from "../slices/member";
import mw from "../slices/manage-website";
import blacklist from "../slices/blacklist"
import creditNote from "../slices/credit-note"
// import user from "./user-reducer";
// import adjust from "../slices/adjust";
// import vourcher from "../slices/vourcher";

export default combineReducers({
  auth,
  user,
  menu,
  pos,
  promotion,
  transfer,
  legal,
  voucher,
  inventoryCount,
  requisition,
  promotionList,
  settingMaster,
  productRequest,
  product,
  claim,
  storeQR,
  marketing,
  ethics,
  member,
  mw,
  blacklist,
  creditNote
  // adjust,
  // vourcher,
});
