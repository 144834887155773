import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { classFormDefaultValues } from '../dto/class';
import fitUserServices from '../../../../../services/fit-user.services';
import UserForm from '../form/UserForm';

const UserModal = ({ open, handleClose }) => {
  const { handleSubmit, reset } = useFormContext();

  const { mutate: updateUser } = fitUserServices.useMutationUpdateUser(() => {
    AlertSuccess('แก้ไขผูู้ใช้งานสำเร็จ');
    reset(classFormDefaultValues);
    handleClose();
  });

  const _HandleSubmit = handleSubmit((data) => {
    const userData = {
      nameTitle: data.nameTitle,
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: new Date(data.birthDate),
    };
    updateUser({ userId: data.id, data: userData });
  });

  return (
    <Modal
      title="แก้ไขผูู้ใช้งาน"
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <UserForm />
    </Modal>
  );
};

export default UserModal;
