import React from "react";
import { Switch, Route } from "react-router-dom";

// Pages
import Index from "./index";
import Result from "./result";

// Not found
import NotFound from "./not-found";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/:token`} component={Index} />
      <Route path={`${match.path}/:token/result`} component={Result} />

      <Route component={NotFound} />
    </Switch>
  );
};
