import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import DigitalProductProjectForm from '../form/DigitalProductProjectForm';
import { AlertError, AlertSuccess } from '../../../../util/Alert';
import digitalProductServices from '../../../../../services/digital-product.services';
import moment from 'moment';

const DigitalProductProjectModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createDigitalProductProject } =
    digitalProductServices.useMutationCreateDigitalProductProject(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-digital-product-projects'],
          refetchType: 'all',
        });
        AlertSuccess('เพิ่มข้อมูลสำเร็จ');
        handleClose();
      },
      (err) => {
        AlertError({ text: err });
      }
    );

  const { mutate: editDigitalProductProject } =
    digitalProductServices.useMutationUpdateDigitalProductProject(
      () => {
        queryClient.invalidateQueries({
          queryKey: ['get-digital-product-projects'],
          refetchType: 'all',
        });
        AlertSuccess('แก้ไขข้อมูลสำเร็จ');
        handleClose();
      },
      (err) => {
        AlertError({ text: err });
      }
    );

  const _HandleSubmit = handleSubmit((data) => {
    const data2 = {
      thumbnailImageUrl: data.thumbnailImageUrl,
      title: data.title,
      description: data.description,
      subdescription: data.subdescription,
      startSubmitingDate: moment(data.startSubmitingDate)
        .startOf('day')
        .toDate(),
      endSubmitingDate: moment(data.endSubmitingDate).endOf('day').toDate(),
      startVotingDate: moment(data.startVotingDate).startOf('day').toDate(),
      endVotingDate: moment(data.endVotingDate).endOf('day').toDate(),
      display: data.display,
      width: data.width,
      height: data.height,
      extension: data.extension,
      fileSize: data.fileSize,
      policy: data.policy,
      policyImageUrl: data.policyImageUrl,
      requestOrderNote: data.requestOrderNote,
    };

    if (watch('formType') === 'create') {
      createDigitalProductProject(data2);
    } else if (watch('formType') === 'edit') {
      editDigitalProductProject({ id: data.id, data: data2 });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่ม Digital Project'
          : 'แก้ Digital Project'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <DigitalProductProjectForm />
    </Modal>
  );
};

export default DigitalProductProjectModal;
