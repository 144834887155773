/* eslint-disable no-unused-vars */
import { Button, Table, Tooltip, Alert } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineSelect } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHttp } from "../../../hooks/http";
import { actionLegal, searchSelector, typeSelector } from "../../../redux/slices/legal";
import { URL_API } from "../../../utils";
import { withRouter } from "react-router-dom";

const MemberTable = ({ match, history }) => {
  const search = useSelector(searchSelector);
  const member_type = useSelector(typeSelector);
  const dispatch = useDispatch();
  const { SET_SEARCH, SET_MEMBER_TYPE } = actionLegal;

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/legal/master/member`,
      token : true,
      params: {
        limit: search.limit,
        offset: (search.page - 1) * search.limit,
        ...search,
      },
    },
    [search]
  );

  const handleSelect = (record) => {
    const { id } = record;
    dispatch(SET_MEMBER_TYPE({ type : 'member'}));
    history.push(`${match.path}/profile/register?membertype=member&id=${id} `);
  };

  return (
    <>
      {error && <Alert type="error" message={error} />}
      <Table
        dataSource={data?.data || []}
        bordered
        loading={loading}
        size="small"
        rowKey="row_id"
        scroll={{ x: "max-content" }}
        className="pt-3 pb-3"
        pagination={{
          current: search.page,
          pageSize: search.limit,
          total: data?.total || 0,
          showSizeChanger: false,
          showLessItems: true,
          hideOnSinglePage: true,
          size: "default",
          onChange: (currPage) => dispatch(SET_SEARCH({ page: currPage })),
        }}
      // onRow={(record) => {
      //     return {
      //         onClick: () => history.push(`${match.path}/profile/register?id=${record.id}`)
      //     };
      // }}
      >
        <Table.Column
          key="member_code"
          dataIndex="member_code"
          title="รหัสสมาชิก"
          align="center"
        />
        <Table.Column
          key="member_name"
          dataIndex="member_name"
          title="ชื่อ-นามสกุล"
          align="center"
          render={(data) => <div className="text-left">{data}</div>}
        // render={(text, { first_name_th, sur_name_th }) => (
        //   <div className="text-left">
        //     {getStringLiteral(first_name_th, sur_name_th)}
        //   </div>
        // )}
        />
        <Table.Column
          key="register_date"
          dataIndex="register_date"
          title="วันที่สมัคร"
          align="center"
          render={(data) => (data ? moment(data).format("DD/MM/YYYY") : "-")}
        />
        <Table.Column
          key="expire_date"
          dataIndex="expire_date"
          title="วันที่หมดอายุ"
          align="center"
          render={(data) => (data ? moment(data).format("DD/MM/YYYY") : "-")}
        />
        <Table.Column
          key="status_text"
          dataIndex="status_text"
          title="สถานะ"
          align="center"
        />
        <Table.Column
          key="action"
          title=""
          align="center"
          render={(record) => (
            <Tooltip title="เลือก">
              <Button
                size="small"
                shape="circle"
                type="primary"
                className="bg-pv border-pv"
                onClick={() => handleSelect(record)}
              >
                <AiOutlineSelect />
              </Button>
            </Tooltip>
          )}
        />
      </Table>
    </>
  );
}
export default withRouter(MemberTable);
