import { Modal, Spin } from "antd";
import propTypes from "prop-types";
import React, { useRef, useState } from "react";
import { axios, getToken, URL_API } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import PanelOTP from "./PanelOTP";

function ModalOTP(props) {
  const { visible, data, onOk, onCancel, duration = 15 } = props;
  const panel = useRef();
  const [loadingVerify, setLoadingVerify] = useState(false);


  async function memberVerifyOTP() {
    setLoadingVerify(true);
    try {
      await axios({
        method: "post",
        url: `${URL_API}/pos/verify-otp`,
        headers: { Authorization: "Bearer " + getToken() },
        data,
      });
      Modal.success({
        title: "สำเร็จ",
        content: "บันทึกข้อมูลเรียบร้อย",
        okText: "ตกลง",
        zIndex: 1080,
        onOk,
      });
    } catch (e) {
      resCatchModal(e);
    }
    setLoadingVerify(false);
  }

  return (
    <Modal
      visible={visible}
      width={400}
      onCancel={onCancel}
      footer={false}
      maskClosable={false}
      zIndex={1080}
    >
      <Spin spinning={loadingVerify}>
        <PanelOTP
          duration={duration}
          visible={visible}
          ref={panel}
          mobile={data.mobile}
          email={data.email}
          onSuccess={memberVerifyOTP}
        />
      </Spin>
    </Modal>
  );
}

ModalOTP.propTypes = {
  visible: propTypes.bool,
  data: propTypes.object,
  onOk: propTypes.func,
  onCancel: propTypes.func,
};

export default ModalOTP;
