import { Table, Input, message, Select, Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useStateValue } from "../StateContext";
import { axios } from "../../../../../utils/useAxios";
import ModalHistory from "./ModalHistory";

const TableFile = () => {
  const { Search } = Input;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const { state, dispatch } = useStateValue();
  const { loading, data } = state;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(state.data.event_name?.[0]?.Id ?? "");
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);

  const loadDataCallback = useCallback(async () => {
    dispatch({ type: "SET_LOADING", payload: true });

    try {
      const res = await axios.get("/legacy-verse/import/import-coin", {
        params: {
          search: searchTerm,
          event_id: selectedEvent,
        },
      });

      dispatch({ type: "SET_COIN_LIST", payload: res?.data?.coin_list || [] });
      dispatch({ type: "SET_EVENT_NAME", payload: res?.data?.event_name_list || [] });
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      message.error(error.message);
    }
  }, [searchTerm, selectedEvent]);

  useEffect(() => {
    loadDataCallback();
  }, [searchTerm, selectedEvent]);


  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleOpenHistoryModal = () => {
    setIsHistoryModalVisible(true);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <Search
          placeholder="Search"
          onSearch={handleSearch}
          style={{ width: 255 }}
          className="mt-2 mr-2"
        />

        <Button type="primary" className="ml-2" onClick={handleOpenHistoryModal}>
          History
        </Button>
      </div>

      <Table
        size="small"
        bordered
        loading={loading}
        rowKey="id"
        dataSource={data?.coin_list || []}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          onChange: (page) => setCurrentPage(page),
        }}
        scroll={{ x: "max-content" }}
        className="table-text-top mt-2"
      >
        <Table.Column
          title="ลำดับ"
          align="center"
          render={(_id, _record, index) => (
            <div className="text-center">{(currentPage - 1) * pageSize + index + 1}</div>
          )}
        />
        <Table.Column title="ชื่อกิจกรรม" dataIndex="title" align="center" />
        <Table.Column title="รหัสสมาชิก" dataIndex="partner_code" align="center" />
        <Table.Column title="ชื่อ" dataIndex="first_name_th" align="center" />
        <Table.Column title="สกุล" dataIndex="sur_name_th" align="center" />
        <Table.Column title="ประเภท" dataIndex="type_married" align="center" />
        <Table.Column title="เหรียญรางวัล" dataIndex="amount" align="center" />
        <Table.Column
          title="วันที่นำข้อมูลเข้า"
          dataIndex="buy_date"
          align="center"
          render={(_, record) => <div>{dayjs(record.buy_date).format("DD-MM-YYYY HH:mm:ss")}</div>}

        />
      </Table>

      <ModalHistory
        visible={isHistoryModalVisible}
        onCancel={() => setIsHistoryModalVisible(false)}
      />
    </div>
  );
};

export default TableFile;
