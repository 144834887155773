/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Alert,
  Checkbox,
  Col,
  Empty,
  Row,
  Spin,
  Typography,
  notification,
  Tag,
  Divider,
  Tabs,
} from "antd";
import numeral from "numeral";
import { Space } from "antdV4";
import { displayName } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ImgError from "../../../../components/ImgError";
import { useHttp } from "../../../../hooks/http";
import { URL_API, _, axios } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { Creators } from "../ducks/operation-form";
import ModalControl from "./ModalControl";
import moment from "moment";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { getSummaryTotalPayment } from "../payment/logic";
import { fixDisCount } from "../../../../config/fix-value";
const System = ({
  onSelect,
  partnerCode,
  partnerType,
  voucherUsed,
  productId,
  gategoryGroup,
  type,
}) => {
  const [selection, setSelection] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [categoryToggle, setCategoryToggle] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/voucher`,
      params: {
        ref_code: partnerCode,
        ref_type: partnerType,
        type: type,
      },
    },
    [partnerCode, partnerType, type]
  );

  useEffect(() => {
    if (data) {
      let data_filter = data.data;
      voucherUsed.forEach((n) => {
        data_filter = [...data_filter.filter((item) => item.id !== n.id)];
      });
      setVoucher(data_filter);
    }
  }, [data, voucherUsed]);

  const handleOnCheck = (checked, record) => {
    let newSelection = [];
    if (selection.map((n) => n.id).includes(record.id)) {
      newSelection = selection.filter((n) => n.id !== record.id);
    } else {
      newSelection = [...selection, record];
    }
    setSelection(newSelection);
    onSelect(newSelection);
  };
  const disabledVoucher = (item) => {
    let disable = true;
    for (let i = 0; i < item.length; i++) {
      if (item[i].ref_type === "category") {
        if (_.includes(gategoryGroup, item[i].ref_id)) {
          return false;
        }
      }
      if (item[i].ref_type === "product") {
        if (_.includes(productId, item[i].ref_id)) {
          return false;
        }
      }
    }
    return disable;
  };
  const getRowClassName = (voucher) => {
    if (voucher.custom !== null) {
      return {
        class_bg:
          disabledVoucher(voucher.custom) == true
            ? "bg-gray-20 border-gray-60"
            : "",
      };
    } else {
      return {
        class_bg: "",
      };
    }
  };
  const _handleCategory = (id) => {
    const duplicate = _.includes(categoryToggle, id);
    if (duplicate) {
      setCategoryToggle(_.filter(categoryToggle, (n) => n != id));
    } else {
      setCategoryToggle([...categoryToggle, id]);
    }
  };
  if (error) return <Alert type="error" message={error} />;

  return (
    <Spin spinning={loading}>
      <div className="w-100" style={{ maxHeight: "500px", overflow: "auto" }}>
        {voucher.length != 0 ? (
          voucher.map((n, i) => (
            <div
              className={getRowClassName(n).class_bg}
              style={{
                borderColor: "#97d700",
                borderWidth: "0.7px",
                borderStyle: "solid",
                padding: "5px",
                margin: "7px",
                borderRadius: "12px",
              }}
            >
              <Row align="left" type="flex">
                {type == "Unexpired" && (
                <Col
                  span={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ witdh: "100%" }}
                  >
                    {n.custom !== null ? (
                      <Checkbox
                        disabled={disabledVoucher(n.custom)}
                        checked={selection.map((m) => m.id).includes(n.id)}
                        onClick={(e, a) => handleOnCheck(e.target.checked, n)}
                      />
                    ) : (
                      <Checkbox
                        checked={selection.map((m) => m.id).includes(n.id)}
                        onClick={(e, a) => handleOnCheck(e.target.checked, n)}
                      />
                    )}
                  </div>
                </Col>
                )}
                <Col span={23} className="d-flex">
                  <Space direction="vertical" className="mr-3">
                    <ImgError
                      src={`${n.azure_url}`}
                      height={75}
                      className="pb-9"
                    />
                    <span style={{ fontSize: "12px" }}>Code : {n.code}</span>
                  </Space>
                  <Space direction="vertical">
                    <div>
                      <Typography.Text strong style={{ fontSize: "16px" }}>
                        {n.name}{" "}
                      </Typography.Text>
                      <Typography.Text style={{ fontSize: "14px" }}>
                        {n.type_text} {n.value} บาท
                      </Typography.Text>
                    </div>
                    <div className="mt-2">
                      <Typography.Text style={{ fontSize: "14px" }}>
                        {n.end_date != null
                          ? `ใช้ได้ถึงวันที่ :  ${moment(n.end_date).format(
                              "DD/MM/YYYY"
                            )}`
                          : "ไม่มีวันหมดอายุ"}
                      </Typography.Text>
                    </div>
                    <div className="mt-2">
                      {n.collect_point == 1 ? (
                        <Tag color="green" style={{ fontSize: "14px" }}>
                          ได้รับคะแนน
                        </Tag>
                      ) : (
                        <Tag color="red" style={{ fontSize: "14px" }}>
                          ไม่ได้รับคะแนน
                        </Tag>
                      )}
                      {n.category_type == "custom" && (
                        <>
                          <Tag color="gold" style={{ fontSize: "14px" }}>
                            เฉพาะรายการสินค้า
                          </Tag>

                          <Typography.Text
                            type="primary"
                            onClick={() => _handleCategory(n.id)}
                            style={{
                              fontSize: "14px",
                              color: "#97d700",
                              cursor: "pointer",
                            }}
                          >
                            เงื่อนไข{" "}
                            {_.includes(categoryToggle, n.id) ? (
                              <FaAngleUp />
                            ) : (
                              <FaAngleDown />
                            )}
                          </Typography.Text>
                        </>
                      )}
                      {type == "Expired" &&(
                      <Tag  style={{ fontSize: "14px" }}>
                          หมดอายุ
                      </Tag>
                      )}
                    </div>
                    
                  </Space>
                </Col>
                {_.includes(categoryToggle, n.id) && (
                  <Col offset={1} span={23}>
                    <Divider className="my-2" />
                    <Typography.Text strong style={{ fontSize: "18px" }}>
                      สินค้าที่ร่วมรายการ
                    </Typography.Text>
                    {n?.custom?.map((item, j) => (
                      <p style={{ fontSize: "14px" }} className="my-0">
                        - {item.name}
                      </p>
                    ))}
                  </Col>
                )}
              </Row>
            </div>
          ))
        ) : (
          <Empty className="mt-2"/>
        )}
      </div>
    </Spin>
  );
};

const VoucherPopUp = ({ visible = false, onCancel, onOk, ...props }) => {
  const [voucherSelect, setVoucherSelect] = useState([]);
  const [activeTabs, setActiveTabs] = useState("Unexpired");
  const isNormal = _.includes(
    ["sale", "subscription", "pre-sale"],
    props?.match.params?.type
  );
  const tabs_style = {
    padding: "8px 30px 5px",
    fontWeight: "bold",
    zIndex: 2,
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };
  const list_tabs = [
    { name: "ที่ยังใช้ได้", en_name: "Unexpired" },
    { name: "ที่หมดอายุแล้ว", en_name: "Expired" },
  ];
  let { voucher_total, coupon_total, discount_total } = props;
  let total_discount = voucher_total + coupon_total + discount_total || 0;
  const handleFetchDelivery = async () => {
    if (!isNormal) return;
    if (!props.province_name) return props.setBillValue({ total_delivery: 0 });
    let res = null;
    props.setLoading({ delivery: true });
    try {
      res = await axios({
        url: `${URL_API}/pos/operation/delivery`,
        method: "post",
        data: {
          items: props.order_item,
          province_name: props.province_name,
          partner_type: props.partner_type,
          collect_point: props.collect_point,
          provider: props.req_receipt_type === "Lowcost" ? "300" : undefined,
          balance_total: props.summary_total_order - total_discount || 0,
        },
        // cancelToken: source.token,
      });
      props.setBillValue({
        total_delivery: res.data.data.total_delivery,
        balance_total_order: props.summary_total_order - total_discount || 0,
      });
    } catch (e) {
      resCatch(e);
    }
    props.setLoading({ delivery: false });
  };
  const handleClickOk = () => {
    // if(props.voucher_delivery >= props.total_delivery){
    //   return notification.warning({
    //     message: "ไม่สามารถใช้ส่วนลดได้",
    //     description: "เนื่องจากมีการใช้ส่วนลดค่าจัดส่งครบแล้ว",
    //   });
    // }

    props.addVoucher(
      _.orderBy(voucherSelect, [(item) => _.isNull(item.custom)], ["asc"])
    );
    props.clearPaymentIgnoreActiveKey();
    onOk();
  };

  useEffect(() => {
    setVoucherSelect([]);
  }, [visible]);

  useEffect(() => {
    if (props.req_receipt_type !== "Branch") {
      handleFetchDelivery();
    }
  }, [props.collect_point, voucher_total, coupon_total, discount_total]);

  return (
    <ModalControl
      visible={visible}
      footerLine={false}
      destroyOnClose={true}
      width={800}
      onCancel={onCancel}
    >
      <Typography.Title level={3} className="text-center">
        บัตรกำนัล
      </Typography.Title>
      <div>
        <div
          className=""
          style={{ display: "flex", borderBottom: "1px solid #ebedf0" }}
        >
          {list_tabs.map((n, i) => {
            return (
              <div
                style={{
                  ...tabs_style,
                  backgroundColor:
                    n.en_name === activeTabs ? "#838383" : "#e3e3e3",
                  color: n.en_name === activeTabs ? "#fff" : "#838383",
                }}
                onClick={() => setActiveTabs(n.en_name)}
              >
                {n.name}
              </div>
            );
          })}
        </div>
        <System
          onSelect={setVoucherSelect}
          // memberId={props.member_id}
          type={activeTabs}
          gategoryGroup={props.gategory_group}
          productId={props.productId}
          voucherUsed={props.vouchers}
          partnerCode={props.partner_code}
          partnerType={props.partner_type}
        />
      </div>
      <Row className="mt-5">
        <Col className="text-center">
          <button
            type="button"
            className="pos-btn pos-btn bg-pv text-white text-nowrap"
            style={{ minWidth: "100px" }}
            onClick={handleClickOk}
          >
            ตกลง
          </button>
        </Col>
      </Row>
    </ModalControl>
  );
};

const mapStateToProps = (state) => {
  let { orders, payment } = state.pos.operation;
  let activeKey = payment.activeKey || orders.itemKeys[0];
  let order_item = _.map(
    orders.itemByKey[activeKey]?.items.itemKeys,
    (key) => orders.itemByKey[activeKey]?.items.itemByKey[key]
  );
  let productId = _.map(
    orders.itemByKey[activeKey]?.items.itemKeys,
    (key) => orders.itemByKey[activeKey]?.items.itemByKey[key].product_id || []
  );
  const { summary_list } = getSummaryTotalPayment(orders);
  let vouchers = [];
  let gategory = [];
  let { total } = _.find(summary_list, (order) => order.key === activeKey) || 0;
  orders.itemKeys.forEach((order_key) => {
    orders.itemByKey[order_key].voucher.itemKeys.forEach((voucher_key) => {
      vouchers = [
        ...vouchers,
        { ...orders.itemByKey[order_key].voucher.itemByKey[voucher_key] },
      ];
    });
  });
  if (orders.itemKeys.length != 0) {
    orders.itemByKey[activeKey].items.itemKeys.forEach((gategory_key) => {
      gategory = [
        ...gategory,
        ...(orders.itemByKey[activeKey].items.itemByKey[gategory_key]
          .category_parent || []),
      ];
    });
  }
  const gategory_group = _.uniq(gategory);
  return {
    vouchers,
    productId,
    gategory_group,
    voucher_total: orders.itemByKey[activeKey]
      ? _.reduce(
          orders.itemByKey[activeKey].voucher.itemByKey,
          (sum, voucher) => {
            if (voucher.type !== "3" && voucher.voucher_type == "voucher") {
              return sum + voucher.value;
            }
            return sum;
          },
          0
        )
      : 0,

    coupon_total: orders.itemByKey[activeKey]
      ? _.reduce(
          orders.itemByKey[activeKey].voucher.itemByKey,
          (sum, voucher) => {
            if (voucher.type !== "3" && voucher.voucher_type == "coupon") {
              return sum + voucher.value;
            }
            return sum;
          },
          0
        )
      : 0,
    discount_total: orders.itemByKey[activeKey]
      ? _.reduce(
          orders.itemByKey[activeKey].discount.itemByKey,
          (sum, discount) => {
            if (discount.discount_type !== fixDisCount.delivery) {
              return sum + discount.discount_amount;
            }
            return sum;
          },
          0
        )
      : 0,
    member_id: orders.itemByKey[activeKey]?.member_id,
    partner_code: orders.itemByKey[activeKey]?.partner_code,
    province_name: orders.itemByKey[activeKey]?.ship_province,
    partner_type: orders.itemByKey[activeKey]?.partner_type,
    collect_point: orders.itemByKey[activeKey]?.collect_point,
    order_item: order_item,
    req_receipt_type: orders.itemByKey[activeKey]?.req_receipt_type,
    summary_total_order: total || 0,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setBillValue: Creators.setBillValue,
    setLoading: Creators.setLoading,
    addVoucher: Creators.addVoucher,
    clearPaymentIgnoreActiveKey: Creators.clearPaymentIgnoreActiveKey,
  })(VoucherPopUp)
);
