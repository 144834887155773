/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, Input, Modal, Row, Table, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { FaCogs } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHttp } from "../../../../hooks/http";
import { actionCreditNote } from "../../../../redux/slices/credit-note";
import { URL_API, getToken, moment } from "../../../../utils";
import BillPreview from "../../pos/components/BillPreview";

const {
  SET_CLOSE_MODAL_BILL,
  SET_SEARCH_BILL,
  SELECT_BILL,
  SET_LOAD,
  CLEAR_LOAD,
} = actionCreditNote;

const { Column } = Table;

const BillModal = () => {
  const dispatch = useDispatch();
  const partner_code = useSelector((state) => state.creditNote.partner);
  const { visible, query, page, limit } = useSelector(
    (state) => state.creditNote.billModal
  );
  const [billSelectId, setBillSelectId] = useState(null);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/credit-note/bill`,
      params: {
        limit,
        offset: (page - 1) * limit,
        query,
        code: partner_code?.code,
      },
      token: true,
    },
    [query, page, partner_code]
  );
  const setPreview = useCallback(() => setBillSelectId(null), []);
  const hiddleClickBill = async (id) => {
     dispatch(SET_LOAD());
    const result = await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/credit-note/bill/${id}`,
      headers: { Authorization: "Bearer " + getToken() },
    });
    if (result.data.status == 200) {
      dispatch(SELECT_BILL(result.data.data));
    }
  };
  return (
    <Modal
      footer={false}
      width={1000}
      visible={visible}
      destroyOnClose={true}
      onCancel={() => dispatch(SET_CLOSE_MODAL_BILL())}
      zIndex={1080}
    >
      <Typography.Title level={4} className="text-center">
        ใบเสร็จ
      </Typography.Title>
      <Row gutter={[16, 16]} className="mb-3">
        <Col span={12} offset={6}>
          <Input.Search
            allowClear
            onSearch={(value) =>
              page === 1 && query === value
                ? reload()
                : dispatch(SET_SEARCH_BILL({ page: 1, query: value }))
            }
            placeholder="เลขที่ใบเสร็จ/ผู้ดำเนินการ"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {!!error ? (
            <Alert type="error" showIcon message={error} />
          ) : (
            <Table
              size="small"
              bordered={true}
              loading={loading}
              dataSource={data?.data?.record || []}
              className="mb-3"
              pagination={{
                current: page,
                pageSize: limit,
                total: data?.data.total || 0,
                hideOnSinglePage: true,
                showLessItems: true,
                className: "text-center",
                onChange: (page) => dispatch(SET_SEARCH_BILL({ page: page })),
              }}
            >
              <Column
                width={140}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">
                    เลขที่ใบเสร็จ
                  </div>
                }
                key="document_no"
                dataIndex="document_no"
              />
              <Column
                width={130}
                title="วันที่ออก"
                key="document_date"
                dataIndex="document_date"
                align="center"
                render={(text) => moment(text).format("DD/MM/YYYY HH:mm")}
              />
              <Column
                width={100}
                title="รหัสสมาชิก"
                key="partner_code"
                dataIndex="partner_code"
                align="center"
              />
              <Column
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center min-width-100">
                    ชื่อ
                  </div>
                }
                key="partner_name"
                dataIndex="partner_name"
              />
              <Column
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center min-width-100">
                    ผู้ดำเนินการ
                  </div>
                }
                key="buyer_name"
                dataIndex="buyer_name"
              />
              <Column
                width={80}
                className="position-relative"
                title={
                  <div className="ant-table-absolute-title-center">ราคารวม</div>
                }
                align="right"
                key="grand_total_amount"
                dataIndex="grand_total_amount"
              />
              <Column
                width={50}
                title=""
                key="view"
                align="center"
                render={(_text, { id }) => (
                  <Button
                    size="small"
                    type="link"
                    icon="eye"
                    onClick={() => setBillSelectId(id)}
                  />
                )}
              />
              <Column
                width={60}
                title={<FaCogs />}
                key="action"
                align="center"
                render={(_text, { id }) => (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      hiddleClickBill(id);
                    }}
                  >
                    เลือก
                  </Button>
                )}
              />
            </Table>
          )}
        </Col>
      </Row>
      <BillPreview
        id={billSelectId}
        preview={!!billSelectId}
        setPreview={setPreview}
      />
    </Modal>
  );
};

export default BillModal;
