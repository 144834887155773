import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { actionLegal } from "../../../redux/slices/legal";
import Error404 from "../Error404";
import Commission from "./commission";
import Document from "./document";
import History from "./history";
import Legal from "./index";
import ProfileRoute from "./profile/routes";

export default ({ match }) => {
  const dispatch = useDispatch();
  const { CLEAR_DOCUMENT } = actionLegal;
  useEffect(() => {
    return () => {
      if (window.location.pathname.split("/")[1] !== "legal") {
        dispatch(CLEAR_DOCUMENT());
      }
    };
  }, [dispatch, CLEAR_DOCUMENT]);
  return (
    <Switch>
      <Route exact path={match.path} component={Legal} />
      {/* <Route exact path={`${match.path}/profile`}>
                <Redirect to={match.path} />
            </Route> */}
      {/* <Route path={`${match.path}/:menu/:id`} component={Profile} /> */}
      <Route path={`${match.path}/profile`} component={ProfileRoute} />
      <Route path={`${match.path}/commission`} component={Commission} />
      <Route path={`${match.path}/document`} component={Document} />
      <Route path={`${match.path}/history`} component={History} />
      <Route component={Error404} />
    </Switch>
  );
};
