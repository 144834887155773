import { Col, Input, Row, Typography } from "antd";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../redux/slices/claim";

const getTitle = (type) => {
  switch (type) {
    case "manager":
      return " Manager";
    case "complete":
      return "ผลพิจารณา";
    default:
      return;
  }
};

const { setGlobalState } = actionClaim;

const InputRemark = ({ type }) => {
  const dispatch = useDispatch();
  const remark = useSelector((state) => state.claim.remark[type].remark);
  const [addRemark, setAddRemark] = useState(remark);

  return (
    <Row gutter={[8, 8]} type="flex">
      <Col span={4} xxl={2}>
        <Typography.Text strong>หมายเหตุ{getTitle(type)}</Typography.Text>
      </Col>
      <Col span={20} xxl={18}>
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          maxLength={500}
          // value={remark}
          value={addRemark}
          onChange={(e) => {
            setAddRemark(e.target.value)
            dispatch(
              setGlobalState({
                name: "remark",
                value: { [type]: e.target.value },
              })
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default memo(InputRemark);
