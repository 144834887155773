/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";
import SelectOption from "../../pos/components/SelectOption";

const SelectTeam = ({
  withAll = false,
  withNoTeam = false,
  placeholder = "",
  // allTeam = false,
  ...props
}) => {
  const [dataSource, setDataSource] = useState([]);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/report-component/filter-team`,
      token: true,
      params: {},
    },
    []
  );
  useEffect(() => {
    setDataSource(data ? data.data : []);
  }, [data]);
  let teamOptions =[]
  teamOptions = [
    ..._.map(dataSource, (data) => {
      return { value: data.team, text: data.team };
    }),
  ];
  if(withNoTeam){
    teamOptions.push({value: 'noteam', text: 'ไม่มีองค์กร'})
  }
  return (
    <SelectOption
      withAll={withAll}
      className="w-100"
      placeholder={placeholder}
      options={teamOptions}
      {...props}
    />
  );
};

export default SelectTeam;
