import { Col, Modal, Row, Table, Typography } from "antd";
import numeral from "numeral";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionInventoryCount } from "../../../../redux/slices/inventory-count";
import { _ } from "../../../../utils";

const { Column } = Table;
const { Text } = Typography;

const ModalDifference = () => {
  const dispatch = useDispatch();
  const { clearModalDifference, setGlobalState } = actionInventoryCount;
  const { visible, warehouse_name, page, limit } = useSelector(
    (state) => state.inventoryCount.modalDifference
  );
  const { itemKeys, itemByKey } = useSelector(
    (state) => state.inventoryCount[state.inventoryCount.warehouse.activeKey]
  );
  const tabKey = useSelector(
    (state) => state.inventoryCount.warehouse.activeKey
  );
  const conditionTabKey = _.includes(["distribute", "broken"], tabKey);

  const dataSource = useMemo(() => {
    const value = _.reduce(
      _.filter(itemKeys, (key) => !!itemByKey[key].difference),
      (res, key) => {
        if (itemByKey[key].difference !== 0) {
          res = [...res, itemByKey[key]];
        }
        if (conditionTabKey) {
          res = res.filter((e) => e.product_type_set !== "Kit/Package");
        }
        return res;
      },
      []
    );
    return value;
  }, [tabKey, itemKeys]);

  return (
    <Modal
      visible={visible}
      title={`สรุปผลต่างสินค้า${warehouse_name}`}
      onCancel={() => dispatch(clearModalDifference())}
      footer={false}
      width={1000}
      zIndex={1080}
      destroyOnClose={true}
    >
      <Table
        bordered
        size="small"
        dataSource={dataSource}
        rowKey="row_id"
        className="ant-table-pagination-center"
        pagination={{
          current: page,
          pageSize: limit,
          showSizeChanger: false,
          showLessItems: true,
          hideOnSinglePage: true,
          onChange: (page) =>
            dispatch(
              setGlobalState({
                name: "modalDifference",
                value: { page },
              })
            ),
          total: dataSource.length,
        }}
      >
        <Column
          className="position-relative"
          width={100}
          title="รหัสสินค้า"
          key="product_code"
          dataIndex="product_code"
          align="center"
        />
        <Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ชื่อสินค้า</div>
          }
          key="product_name"
          dataIndex="product_name"
        />
        <Column
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">ระบบ</div>}
          key="total_warehouse"
          dataIndex="total_warehouse"
          align="right"
          width={80}
          render={(text) => <Text>{numeral(text).format()}</Text>}
        />
        <Column
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">นับจริง</div>}
          key="total_all_count"
          dataIndex="total_all_count"
          align="right"
          width={80}
          render={(text) => <Text>{numeral(text).format()}</Text>}
        />
        <Column
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">ผลต่าง</div>}
          key="difference"
          dataIndex="difference"
          align="right"
          width={80}
          render={(text) => (
            <Text
              className={`${
                text > 0 ? "text-tp" : text < 0 ? "text-reds" : ""
              }`}
            >
              {text && numeral(text).format()}
            </Text>
          )}
        />
        <Column
          className="position-relative"
          title="หมายเหตุ"
          key="remark"
          dataIndex="remark"
          align="center"
          width={200}
          // render={(text) => <Text>{text && numeral(text).format()}</Text>}
        />
      </Table>
      <Row>
        <Col span={24} className="text-center"></Col>
      </Row>
    </Modal>
  );
};

export default ModalDifference;
