/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import TablePoint from './TablePoint'
import FormGroup from './FormGroup'
import TargetScore from './TargetScore'
import { FormatNumber } from './ShareFunction';


const PersonalQualities = ({ data }) => {
	const [collapse, setCollapse] = useState(true);
	const [dataTable, setDataTable] = useState({ columns: [], rows: [] });

	const {
		gender,
		current_score,
		target_score,
		personal_qualification_point_of_month,
		total_trip_point,
		grand_trip_point,
		assessment
	} = data;

	const createDataTable = (data) => {
		let dataTable = {
			columns: [
				{
					title: '',
				},
				{
					title: 'ตุลาคม 62'
				},
				{
					title: 'พฤศจิกายน 62'
				},
				{
					title: 'ธันวาคม 62'
				},
				{
					title: 'มกราคม 63'
				},
				{
					title: 'กุมภาพันธ์ 63'
				},
				{
					title: 'มีนาคม 63'
				},
				{
					title: 'สะสมรวม'
				}
			],
			rows: []
		}

		data.forEach(item => {
			if (item.length !== 0) {
				const row = {
					c1: <span >{item[0]}</span>,
					c2: <><input value={FormatNumber(item[1])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
					c3: <><input value={FormatNumber(item[2])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
					c4: <><input value={FormatNumber(item[3])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
					c5: <><input value={FormatNumber(item[4])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
					c6: <><input value={FormatNumber(item[5])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
					c7: <><input value={FormatNumber(item[6])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
					c8: (item[7] !== undefined) ? <><input value={FormatNumber(item[7])} readOnly className="input-maldives form-control form-control-sm text-center" /></> : ''
				};
				dataTable.rows.push(row);
			}

		});
		setDataTable(dataTable);
	};

	useEffect(() => {
		createDataTable(personal_qualification_point_of_month);

		return () => {

		}
	}, []);
	return (
		<div className="maldives-trip-taps">
			<Card className="bg-dark text-white mt-2" >
				<CardHeader className={'card-header bg-dark-darker text-white pointer-cursor ' + (!collapse ? 'collapsed ' : '')} onClick={() => setCollapse(!collapse)}>
					<span style={{ fontSize: 18 }}>รายงานสถานะคุณสมบัติส่วนตัว</span>
					{(collapse) ?
						<i className="fas fa-caret-down pull-right" style={{ fontSize: 20 }} />
						:
						<i className="fas fa-caret-right pull-right" style={{ fontSize: 20 }} />
					}
				</CardHeader>
				<Collapse isOpen={collapse}>
					<CardBody className="text-black">

						<TargetScore gender={gender} maxscore={target_score} score={current_score} />

						<TablePoint data={dataTable} />

						<FormGroup
							label="คะแนนท่องเที่ยวที่ทำได้สะสม"
							value={FormatNumber(total_trip_point)}
							labelCol="col-md-5 col-xs-12"
							divCol="col-md-7 col-xs-12"
						/>
						<FormGroup
							label="คะแนนโปรโมชั่นรักษายอดส่วนตัว ที่ได้"
							value={''}
							labelCol="col-md-5 col-xs-12"
							divCol="col-md-7 col-xs-12"
							detail={<>
                                <span>*ทำคุณสมบัติครบตามเงื่อนไข : รักษายอด 1,000+ PV ทุกเดือนตลอด 6 เดือน หรือ รักษายอดส่วนตัวสะสมทั้ง 6 เดือน >12,000 PV</span>
                            </>}
                            icon
						/>
						<FormGroup
							label="รวมคะแนนทริปมัลดีฟส์ 2020 + โปรโมชั่นรักษายอดส่วนตัว"
							value={FormatNumber(grand_trip_point)}
							labelCol="col-md-5 col-xs-12"
							divCol="col-md-7 col-xs-12"
						/>
						<FormGroup
							label="สรุปผล"
							value={''}
							labelCol="col-md-5 col-xs-12"
							divCol="col-md-7 col-xs-12"
						/>
					</CardBody>
				</Collapse>
			</Card>
		</div>
	)
}
export default PersonalQualities;