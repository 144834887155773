import React from 'react';
import { Button, Modal, Table } from 'antd';
import moment from 'moment';

const LegacyQuizUserModal = ({ open, handleClose, quizUser }) => {
  const columns = [
    {
      title: 'เหรียญรางวัล',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.reward.title}</span>,
    },
    {
      title: 'วันที่ครบ',
      dataIndex: 'maxDate',
      key: 'maxDate',
      render: (_, record) => (
        <span>
          {record.maxDate && moment(record.maxDate).format('DD MMM YY')}
        </span>
      ),
      align: 'center',
    },
    {
      title: 'คะแนนที่ได้',
      dataIndex: 'max',
      key: 'max',
      render: (_, record) => {
        return (
          <span>
            {record.timeCount}/{record.reward.max}
          </span>
        );
      },
      align: 'center',
    },
  ];
  return (
    <Modal
      title="ประวัติเหรียญรางวัล"
      visible={open}
      width={'60vw'}
      onCancel={handleClose}
      footer={[
        <Button key="send_all" type="primary" onClick={handleClose}>
          ตกลง
        </Button>,
      ]}
    >
      <div>
        <Table
          columns={columns}
          dataSource={quizUser.userRewards.filter((item) => item.isMax)}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {}}
          pagination={{
            pageSize: 10,
            total: quizUser.userRewards.length,
          }}
        />
      </div>
    </Modal>
  );
};

export default LegacyQuizUserModal;
