import { Col, Empty, Row, Typography } from "antd";
import parse from "html-react-parser";
import React, { memo } from "react";
import ImgError from "../../../../../../components/ImgError";

const Promotion = ({ dataSource }) => {
  const { name, azure_url, description } = dataSource;
  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Text>{name}</Typography.Text>
        </Col>
      </Row>
      <Row type="flex" className="mt-3 align-items-center">
        <Col span={6} className="d-flex align-items-center border-right">
          <ImgError src={azure_url} alt="promotion" className="w-100" />
        </Col>
        <Col span={18} className="px-3">
          <div>{description ? parse(description) : <Empty />}</div>
        </Col>
      </Row>
    </>
  );
};

export default memo(Promotion);
