import { Button, Col, Input, Modal, Row, Table } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../../../hooks/http2";
import {
  actionProductRequest,
  modalSelector,
} from "../../../../../../redux/slices/product-request";
import { URL_API } from "../../../../../../utils";

const ModalProduct = () => {
  const dispatch = useDispatch();
  const modal = useSelector(modalSelector);
  const { SET_MODAL_VALUE, ADD_BOM } = actionProductRequest;
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  // const [loading, data, error, reload] = useHttp(
  //   {
  //     url: `${URL_API}/system/products`,
  //     token: true,
  //     params: {
  //       limit,
  //       offset: (query.page - 1) * limit,
  //       search,
  //       // category: query.category,
  //       category,
  //       status: query.status,
  //       distributionType: query.distributionType,
  //     },
  //   },
  //   [query.page, search, category, query.status, query.distributionType]
  // );

  const products = useApi(
    {
      baseURL: URL_API,
      url: "/system/products",
      token: true,
      params: {
        limit,
        offset: (page - 1) * limit,
        search,
        for_bom: "y",
        service: 0,
      },
    },
    [limit, page]
  );

  return (
    <Modal
      title="เลือกสินค้าย่อย"
      {...modal}
      onCancel={() => dispatch(SET_MODAL_VALUE({ visible: false }))}
      footer={null}
    >
      <Row gutter={16} className="mb-2">
        <Col md={24}>
          <Input.Search
            placeholder="รหัสสินค้า, ชื่อสินค้า"
            onChange={(e) => setSearch(e.target.value)}
            onSearch={() => {
              if (page === 1) {
                products.reload();
              } else {
                setPage(1);
              }
            }}
          />
        </Col>
      </Row>
      <Table
        loading={products.loading}
        size="small"
        bordered
        dataSource={products.fetch?.data || []}
        rowKey="product_code"
        pagination={{
          size: "default",
          total: products.fetch?.total || 0,
          showLessItems: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
          current: page,
          onChange: (p) => setPage(p),
        }}
      >
        <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
        <Table.Column title="ชื่อสินค้า" dataIndex="product_name_local" />
        <Table.Column
          align="center"
          render={(text, record) => {
            return (
              <Button
                size="small"
                type="primary"
                ghost
                onClick={() => {
                  dispatch(
                    ADD_BOM({
                      ...record,
                      item_code: record.product_code,
                      product_name_local: record.product_name_local,
                      qty: 1,
                      point_01: record.point_01,
                    })
                  );
                  // dispatch(SET_MODAL_VALUE({ visible: false }));
                }}
              >
                เลือก
              </Button>
            );
          }}
        />
      </Table>
    </Modal>
  );
};

export default ModalProduct;
