/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography, Select, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaDownload, FaPen, FaSearch } from 'react-icons/fa';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { AlertConfirm, AlertSuccess } from '../../../util/Alert';
import { Input, Table } from 'antdV4';
import { DatePicker } from 'antd';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';
import registerPaperConstants from '../constants/registerPaperConstants';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  registerPaperQueryDefaultValues,
  registerPaperQueryFormSchema,
  registerPapersUpdateDefaultValues,
  registerPapersUpdateFormSchema,
  registerPaperUpdateDefaultValues,
  registerPaperUpdateFormSchema,
} from '../components/dto/register-paper';
import RegisterPaperModal from '../components/modal/RegisterPaperModal';
import 'moment/locale/th';
import RegisterPapersModal from '../components/modal/RegisterPapersModal';
import DownloadModal from '../components/modal/DownloadModal';
import RegisterPaperNote from '../components/form/RegisterPaperNote';
moment.locale('th');

const RegisterPaperPage = () => {
  const [page, setPage] = useState(0);
  const [isOnSearch, setIsOnSearch] = useState(false);
  const [registerPaperFormOpen, setRegisterPaperFormOpen] = useState(false);
  const [registerPapersFormOpen, setRegisterPapersFormOpen] = useState(false);
  const [registerPaperDownloadOpen, setRegisterPaperDownloadOpen] =
    useState(false);
  const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);

  const _HandleRegisterPaperDownloadOpen = () => {
    setRegisterPaperDownloadOpen(true);
  };

  const _HandleRegisterPaperDownloadClose = () => {
    setRegisterPaperDownloadOpen(false);
  };

  const { execute: downloadFitnessRegisterPaperById } = useAsync(
    fitStudioService.downloadFitnessRegisterPaperById,
    {}
  );

  const columns = [
    {
      title: 'RFS Username',
      dataIndex: 'fitNo',
      key: 'fitNo',
    },
    {
      title: 'RFS Password',
      dataIndex: 'password',
      key: 'password',
      render: (_, record) => <span>{record?.user?.password}</span>,
    },
    {
      title: 'รหัสสมาชิก',
      dataIndex: 'legacyPartnerCode',
      key: 'legacyPartnerCode',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'รหัสบัตรประชาชน',
      dataIndex: 'idCard',
      key: 'idCard',
      render: (_, record) => <span>{record.idCard}</span>,
    },
    {
      title: 'ประเภทฟอร์ม',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <span>
          {record.type === 'child' && 'เด็ก'}
          {record.type === 'normal' && 'ผู้ใหญ่'}
        </span>
      ),
    },
    {
      title: 'สาขา',
      dataIndex: 'fitnessBranch',
      key: 'fitnessBranch',
      render: (_, record) => (
        <span>{record.fitnessBranch.branchMaster.Name}</span>
      ),
    },
    {
      title: 'ประเภทสมาชิก',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <span>
          {record.legacyPartnerType === 'Guest' && 'แขก'}
          {record.legacyPartnerType === 'Member' && 'VIP Member'}
          {record.legacyPartnerType === 'Distributor' && 'นักธุรกิจเลกาซี'}
        </span>
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'activeStatus',
      key: 'activeStatus',
      render: (_, record) => <span>{record.activeStatus}</span>,
    },
    {
      title: 'เริ่ม',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>
          {record.startDate
            ? moment(record.startDate).format('DD/MMM/YYYY')
            : ''}
        </span>
      ),
    },
    {
      title: 'สิ้นสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>
          {record.endDate ? moment(record.endDate).format('DD/MMM/YYYY') : ''}
        </span>
      ),
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'note',
      key: 'note',
      render: (_, record) => {
        return (
          <RegisterPaperNote paper={record} getPapers={_RefetchRegisterPaper} />
        );
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              registerPaperForm.setValue('id', record.id);
              _HandleRegisterPaperDownloadOpen();
            }}
          >
            <FaDownload />
          </div>
          {record.activeStatus !== 'end' && (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                registerPaperForm.setValue('id', record.id);
                _HandleRegisterPaperFormOpen('edit');
              }}
            >
              <FaPen />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบใบสมัคร');
              if (confirm) deleteRegisterPapers({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  const { execute: downloadFitnessRegisterPaperExcelByFilter } = useAsync(
    fitStudioService.downloadFitnessRegisterPaperExcelByFilter,
    {
      onSuccess: () => {
        setTimeout(() => {
          setExcelDownloadLoading(false);
        }, 3000);
      },
    }
  );

  const { execute: getRegisterPapers, data: registerPaperLists } = useAsync(
    fitStudioService.getRegisterPapers,
    {}
  );

  const { data: deletedRes, execute: deleteRegisterPapers } = useAsync(
    fitStudioService.deleteRegisterPaper,
    {}
  );

  useEffect(() => {
    _RefetchRegisterPaper();
  }, [page, deletedRes]);

  const { data: branchLists } = useAsync(fitStudioService.getAllFitnessBranch, {
    immediate: true,
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: registerPaperQueryDefaultValues,
    resolver: registerPaperQueryFormSchema(),
  });

  const _HandleSubmit = handleSubmit((data) => {
    setIsOnSearch(true);
    getRegisterPapers({
      query: {
        ...(data.idCard && { idCard: data.idCard }),
        ...(data.legacyPartnerCode && {
          legacyPartnerCode: data.legacyPartnerCode,
        }),
        ...(data.fitnessBranchId && {
          fitnessBranchId: data.fitnessBranchId,
        }),
        ...(data.updatedDateFrom &&
          data.updatedDateTo && {
            updatedDateFrom: new Date(data.updatedDateFrom),
            updatedDateTo: new Date(data.updatedDateTo),
          }),
        ...(data.startDateFrom &&
          data.startDateTo && {
            startDateFrom: new Date(data.startDateFrom),
            startDateTo: new Date(data.startDateTo),
          }),
        ...(data.endDateFrom &&
          data.endDateTo && {
            endDateFrom: new Date(data.endDateFrom),
            endDateTo: new Date(data.endDateTo),
          }),
        ...(data.activeStatus && { activeStatus: data.activeStatus }),
        ...(data.fitNo && { fitNo: data.fitNo }),
      },
      take: -1,
      page: 1,
    });
  });

  const _HandleExportExcel = handleSubmit((data) => {
    setExcelDownloadLoading(true);
    if (isOnSearch) {
      downloadFitnessRegisterPaperExcelByFilter({
        query: {
          ...(data.idCard && { idCard: data.idCard }),
          ...(data.legacyPartnerCode && {
            legacyPartnerCode: data.legacyPartnerCode,
          }),
          ...(data.fitnessBranchId && {
            fitnessBranchId: data.fitnessBranchId,
          }),
          ...(data.updatedDateFrom &&
            data.updatedDateTo && {
              updatedDateFrom: new Date(data.updatedDateFrom),
              updatedDateTo: new Date(data.updatedDateTo),
            }),
          ...(data.startDateFrom &&
            data.startDateTo && {
              startDateFrom: new Date(data.startDateFrom),
              startDateTo: new Date(data.startDateTo),
            }),
          ...(data.endDateFrom &&
            data.endDateTo && {
              endDateFrom: new Date(data.endDateFrom),
              endDateTo: new Date(data.endDateTo),
            }),
          ...(data.activeStatus && { activeStatus: data.activeStatus }),
          ...(data.fitNo && { fitNo: data.fitNo }),
        },
        take: -1,
        page: 1,
      });
    } else {
      downloadFitnessRegisterPaperExcelByFilter({
        page: page + 1,
        take: registerPaperConstants.tableSize,
      });
    }
  });

  const registerPaperForm = useForm({
    defaultValues: registerPaperUpdateDefaultValues,
    resolver: registerPaperUpdateFormSchema(),
  });

  const registerPapersForm = useForm({
    defaultValues: registerPapersUpdateDefaultValues,
    resolver: registerPapersUpdateFormSchema(),
  });

  const _HandleRegisterPaperFormOpen = (formType) => {
    registerPaperForm.setValue('formType', formType);
    setRegisterPaperFormOpen(true);
  };

  const _HandleRegisterPaperFormClose = () => {
    registerPaperForm.reset(registerPaperUpdateDefaultValues);
    setRegisterPaperFormOpen(false);
  };

  const _HandleRegisterPapersFormOpen = () => {
    setRegisterPapersFormOpen(true);
  };

  const _HandleRegisterPapersFormClose = () => {
    registerPapersForm.reset(registerPapersUpdateDefaultValues);
    setRegisterPapersFormOpen(false);
  };

  const _HandleCancel = () => {
    reset(registerPaperQueryDefaultValues);
    setIsOnSearch(false);
    _RefetchRegisterPaper();
  };

  const { execute: updateRegisterPaper } = useAsync(
    fitStudioService.updateRegisterPaper,
    {}
  );

  const _HandleUpdateRegisterPapersSubmit = registerPapersForm.handleSubmit(
    async (data) => {
      if (registerPaperLists?.data.length > 0) {
        await Promise.all(
          registerPaperLists?.data.map((item) => {
            if (item.endDate) {
              const registerPaperData = {
                id: item.id,
                data: {
                  startDate: item.startDate,
                  endDate: new Date(
                    new Date(item.endDate).setDate(
                      new Date(item.endDate).getDate() + data.endDate
                    )
                  ),
                },
              };

              updateRegisterPaper(registerPaperData);
            }
          })
        );
      }

      setTimeout(() => {
        _RefetchRegisterPaper();
        AlertSuccess('อัพเดทใบสมัครทั้งหมดสำเร็จ');
        _HandleRegisterPapersFormClose();
      }, 2000);
    }
  );

  const _RefetchRegisterPaper = () => {
    if (isOnSearch) {
      _HandleSubmit();
    } else {
      getRegisterPapers({
        page: page + 1,
        take: registerPaperConstants.tableSize,
      });
    }
  };

  return (
    <Panel>
      <PanelHeader>ใบสมัคร</PanelHeader>
      <PanelBody>
        <div
          style={{
            marginBottom: 10,
            border: '1px solid #f2f3f4',
            borderRadius: 5,
            padding: 10,
          }}
        >
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <Controller
              name="legacyPartnerCode"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="รหัสสมาชิก"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="fitNo"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="RFS Username"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="idCard"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="รหัสบัตรประชาชน"
                    className="mr-2"
                  />
                );
              }}
            />
            <Controller
              name="fitnessBranchId"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    style={{ width: '100%' }}
                    onChange={onChange}
                    placeholder="สาขา"
                  >
                    {branchLists &&
                      branchLists.length > 0 &&
                      branchLists.map((item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.branchMaster.Name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                );
              }}
            />
          </div>

          <Row>
            <Col span={12}>
              <div
                style={{
                  marginTop: 10,
                  marginRight: 5,
                }}
              >
                <Typography.Text>วันที่เริ่ม package</Typography.Text>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="startDateFrom"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div style={{ flex: 1 }} className="mr-2">
                          <DatePicker
                            format="DD/MM/YYYY"
                            className="w-100"
                            placeholder="วันที่เริ่มต้น"
                            allowClear={false}
                            value={value}
                            onChange={(date, dateString) => {
                              onChange(moment(date));
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  <Typography.Text>ถึง</Typography.Text>
                  <Controller
                    name="startDateTo"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div style={{ flex: 1 }} className="ml-2">
                          <DatePicker
                            format="DD/MM/YYYY"
                            className="w-100"
                            placeholder="วันที่สิ้นสุด"
                            value={value}
                            allowClear={false}
                            onChange={(date, dateString) => {
                              onChange(moment(date));
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  marginTop: 10,
                }}
              >
                <Typography.Text>วันที่สิ้นสุด package</Typography.Text>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="endDateFrom"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div style={{ flex: 1 }} className="mr-2">
                          <DatePicker
                            format="DD/MM/YYYY"
                            className="w-100"
                            placeholder="วันที่เริ่มต้น"
                            allowClear={false}
                            value={value}
                            onChange={(date, dateString) => {
                              onChange(moment(date));
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  <Typography.Text>ถึง</Typography.Text>
                  <Controller
                    name="endDateTo"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div style={{ flex: 1 }} className="ml-2">
                          <DatePicker
                            format="DD/MM/YYYY"
                            className="w-100"
                            placeholder="วันที่สิ้นสุด"
                            value={value}
                            allowClear={false}
                            onChange={(date, dateString) => {
                              onChange(moment(date));
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  marginRight: 5,
                }}
              >
                <Typography.Text>วันที่อัพเดท</Typography.Text>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="updatedDateFrom"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div style={{ flex: 1 }} className="mr-2">
                          <DatePicker
                            format="DD/MM/YYYY"
                            className="w-100"
                            placeholder="วันที่เริ่มต้น"
                            allowClear={false}
                            value={value}
                            onChange={(date, dateString) => {
                              onChange(moment(date));
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  <Typography.Text>ถึง</Typography.Text>
                  <Controller
                    name="updatedDateTo"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div style={{ flex: 1 }} className="ml-2">
                          <DatePicker
                            format="DD/MM/YYYY"
                            className="w-100"
                            placeholder="วันที่สิ้นสุด"
                            value={value}
                            allowClear={false}
                            onChange={(date, dateString) => {
                              onChange(moment(date));
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Typography.Text>สถานะ</Typography.Text>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="activeStatus"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div style={{ flex: 1 }}>
                          <Select
                            value={value}
                            style={{ width: '100%' }}
                            onChange={onChange}
                          >
                            {['active', 'freeze', 'inactive', 'end'].map(
                              (item) => {
                                return (
                                  <Select.Option key={item} value={item}>
                                    {item}
                                  </Select.Option>
                                );
                              }
                            )}
                          </Select>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div
            className="mb-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              className="bg-pv border-pv text-white mr-2"
              onClick={_HandleSubmit}
            >
              <FaSearch className="mr-2" />
              ค้นหา
            </Button>
            <Button
              className="bg-pv border-pv text-white"
              onClick={_HandleCancel}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
        <Row className="mb-2">
          <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={_HandleRegisterPapersFormOpen}
          >
            อัพเดทวันสิ้นสุด
          </Button>
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              if (
                registerPaperLists?.data &&
                registerPaperLists?.data.length > 0
              ) {
                setExcelDownloadLoading(true);
                _HandleExportExcel();
              }
            }}
            loading={excelDownloadLoading}
            disabled={
              registerPaperLists && registerPaperLists.data.length > 0
                ? false
                : true
            }
          >
            Export Excel
          </Button>
        </Row>
        {isOnSearch ? (
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={
              registerPaperLists?.data && registerPaperLists?.data.length > 0
                ? registerPaperLists.data
                : []
            }
            onChange={({ current }) => {
              setPage(current - 1);
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={{
              pageSize: registerPaperConstants.tableSize,
              total: registerPaperLists?.meta?.total || 0,
            }}
          />
        ) : (
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={
              registerPaperLists?.data && registerPaperLists?.data.length > 0
                ? registerPaperLists.data
                : []
            }
            onChange={({ current }) => {
              setPage(current - 1);
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={{
              pageSize: registerPaperConstants.tableSize,
              total: registerPaperLists?.meta?.total || 0,
            }}
          />
        )}
      </PanelBody>
      <FormProvider {...registerPaperForm}>
        <RegisterPaperModal
          refetch={_RefetchRegisterPaper}
          open={registerPaperFormOpen}
          handleClose={_HandleRegisterPaperFormClose}
        />
      </FormProvider>
      <FormProvider {...registerPapersForm}>
        <RegisterPapersModal
          open={registerPapersFormOpen}
          handleClose={_HandleRegisterPapersFormClose}
          handleSubmit={_HandleUpdateRegisterPapersSubmit}
        />
      </FormProvider>
      <DownloadModal
        open={registerPaperDownloadOpen}
        handleClose={_HandleRegisterPaperDownloadClose}
        title="คุณต้องการดาวน์โหลดเอกสารใบสมัครใช่หรือไม่"
        handleDownload={(id) => {
          downloadFitnessRegisterPaperById(id);
        }}
        registerPaperId={registerPaperForm.watch('id')}
      />
    </Panel>
  );
};

export default RegisterPaperPage;
