import React, { forwardRef } from "react";
import SelectOption from "../../../../../components/SelectOption";
import { useApi } from "../../../../../hooks/http2";
import _ from "lodash";

const SelectPage = (props) => {
  const list = useApi(
    {
      url: `/manage-website/page`,
      token: true,
    },
    []
  );

  return (
    <SelectOption
      data={_.map(list.fetch || [], (n) => ({
        value: n.code,
        text: n.name,
      }))}
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectPage forwardRef={ref} {...props} />;
});
