import React, { memo } from "react";
import { Row, Col, Typography } from "antd";
import numeral from "numeral";
import { _ } from "../utils";

const { Text } = Typography;

const RenderItem = memo(({ data, no, isStaff, partner_type }) => (
  <Row gutter={8} className="mt-1" style={{ fontSize: "10.5px" }}>
    <Col span={1} className="text-center">
      <Text className="text-dark">{no}</Text>
    </Col>
    <Col span={isStaff ? 14 : 9}>
      <Text className="text-dark">{`${data.product_code} ${data.product_name_local}`}</Text>
    </Col>
    <Col span={3} className="text-center">
      <Text className="text-dark">
        {numeral(data.unit_price).format("0,0.00")}
      </Text>
    </Col>
    <Col span={1} className="text-center">
      <Text className="text-dark">{numeral(data.qty).format("0,0")}</Text>
    </Col>
    <Col span={3} className="text-center">
      <Text className="text-dark">
        {numeral(data.unit_price * data.qty).format("0,0.00")}
      </Text>
    </Col>
    {!isStaff &&
      (partner_type === "Distributor" ? (
        <>
          <Col span={2} className="text-center ">
            <Text className="text-dark text-nowrap">
              {numeral(data.pv * data.qty).format("0,0.00")}
            </Text>
          </Col>
          <Col span={2} className="text-center ">
            <Text className="text-dark text-nowrap">
              {numeral(data.rp * data.qty).format("0,0.00")}
            </Text>
          </Col>
          <Col span={2} className="text-center">
            <Text className="text-dark">
              {numeral(data.ep * data.qty).format("0,0.00")}
            </Text>
          </Col>
          <Col span={1} className="text-center">
            <Text className="text-dark">
              {numeral(data.tp * data.qty).format("0,0.00")}
            </Text>
          </Col>
        </>
      ) : (
        <>
          <Col span={2} className="text-center "></Col>
          <Col span={2} className="text-center ">
            <Text className="text-dark text-nowrap">
              {numeral(data.rp * data.qty).format("0,0.00")}
            </Text>
          </Col>
        </>
      ))}
  </Row>
));

const ItemBillPreview = ({
  list = [],
  deliveryAmount,
  remark = [],
  totalDiscount = 0,
  isStaff = false,
  partner_type = "Distributor",
}) => {
  return (
    <>
      <Row gutter={8} className="mt-2 font-size-12">
        <Col span={isStaff ? 14 : 9} offset={1}>
          <Text>รายการ</Text>
        </Col>
        <Col span={3} className="text-center">
          <Text>ราคา/หน่วย</Text>
        </Col>
        <Col span={1} className="text-center text-nowrap">
          <Text>จำนวน</Text>
        </Col>
        <Col span={3} className="text-center">
          <Text>ราคา</Text>
        </Col>
        {!isStaff &&
          (partner_type === "Distributor" ? (
            <>
              <Col span={2} className="text-center">
                <Text>PV</Text>
              </Col>
              <Col span={2} className="text-center">
                <Text>RP</Text>
              </Col>
              <Col span={2} className="text-center">
                <Text>EP</Text>
              </Col>
              <Col span={1} className="text-center">
                <Text>TP</Text>
              </Col>
            </>
          ) : (
            <>
              <Col span={2} className="text-center"></Col>
              <Col span={2} className="text-center">
                <Text>RP</Text>
              </Col>
            </>
          ))}
      </Row>

      {list.map((n, i) => (
        <RenderItem
          key={i}
          data={n}
          no={i + 1}
          isStaff={isStaff}
          partner_type={partner_type}
        />
      ))}

      <Row className="mt-2 font-size-12">
        <Col
          span={3}
          offset={isStaff ? 14 : 9}
          className="border-top text-right pt-1"
        >
          <Text strong className="text-dark">
            ค่าจัดส่ง
          </Text>
        </Col>
        <Col span={2} className="border-top pt-1"></Col>
        <Col span={3} className="text-center border-top pt-1">
          <Text className="text-dark">
            {numeral(deliveryAmount).format("0,0.00")}
          </Text>
        </Col>
        <Col span={isStaff ? 3 : 7} className="border-top pt-1"></Col>
      </Row>
      <Row className="font-size-12">
        <Col span={3} offset={isStaff ? 14 : 9} className="text-right">
          <Text strong className="text-dark">
            รวมราคาสินค้า
          </Text>
        </Col>
        <Col span={3} offset={2} className="text-center">
          <Text className="text-dark">
            {numeral(
              _.reduce(
                list,
                (sum, n) =>
                  (sum =
                    parseFloat(sum) +
                    parseFloat(n.unit_price) * parseFloat(n.qty)),
                0
              )
            ).format("0,0.00")}
          </Text>
        </Col>
      </Row>
      <Row className="font-size-12">
        <Col span={3} offset={isStaff ? 14 : 9} className="text-right">
          <Text strong className="text-dark">
            รวมส่วนลด
          </Text>
        </Col>
        <Col span={3} offset={2} className="text-center">
          <Text className="text-dark">
            {totalDiscount !== 0 && "-"}
            {numeral(totalDiscount).format("0,0.00")}
          </Text>
        </Col>
      </Row>
      <Row className="font-size-12">
        <Col span={3} offset={isStaff ? 14 : 9} className="text-right">
          <Text strong className="text-dark">
            รวมทั้งหมด
          </Text>
        </Col>
        <Col span={3} offset={2} className="text-center">
          <Text className="text-dark">
            {numeral(
              _.reduce(
                list,
                (sum, n) =>
                  (sum =
                    parseFloat(sum) +
                    parseFloat(n.unit_price) * parseFloat(n.qty)),
                0
              ) +
                numeral(deliveryAmount).value() -
                numeral(totalDiscount).value()
            ).format("0,0.00")}
          </Text>
        </Col>
        {!isStaff &&
          (partner_type === "Distributor" ? (
            <>
              <Col span={2} className="text-center">
                <Text className="text-dark">
                  {numeral(
                    _.reduce(
                      list,
                      (sum, n) =>
                        (sum =
                          parseFloat(sum) +
                          parseFloat(n.pv) * parseFloat(n.qty)),
                      0
                    )
                  ).format("0,0.00")}
                </Text>
              </Col>
              <Col span={2} className="text-center">
                <Text className="text-dark">
                  {numeral(
                    _.reduce(
                      list,
                      (sum, n) =>
                        (sum =
                          parseFloat(sum) +
                          parseFloat(n.rp) * parseFloat(n.qty)),
                      0
                    )
                  ).format("0,0.00")}
                </Text>
              </Col>
              <Col span={2} className="text-center">
                <Text className="text-dark">
                  {numeral(
                    _.reduce(
                      list,
                      (sum, n) =>
                        (sum =
                          parseFloat(sum) +
                          parseFloat(n.ep) * parseFloat(n.qty)),
                      0
                    )
                  ).format("0,0.00")}
                </Text>
              </Col>
              <Col span={1} className="text-center">
                <Text className="text-dark">
                  {numeral(
                    _.reduce(
                      list,
                      (sum, n) =>
                        (sum =
                          parseFloat(sum) +
                          parseFloat(n.tp) * parseFloat(n.qty)),
                      0
                    )
                  ).format("0,0.00")}
                </Text>
              </Col>
            </>
          ) : (
            <>
              <Col span={2} className="text-center"></Col>
              <Col span={2} className="text-center">
                <Text className="text-dark">
                  {numeral(
                    _.reduce(
                      list,
                      (sum, n) =>
                        (sum =
                          parseFloat(sum) +
                          parseFloat(n.rp) * parseFloat(n.qty)),
                      0
                    )
                  ).format("0,0.00")}
                </Text>
              </Col>
            </>
          ))}
      </Row>
      {remark.map((n, i) => (
        <Row
          key={i}
          className={`font-size-12 ${i === remark.length - 1 ? "mb-2" : ""}`}
        >
          <Col span={24}>
            <Text className="mr-2" strong>
              หมายเหตุ
            </Text>
            <Text>{n.remark}</Text>
          </Col>
        </Row>
      ))}
    </>
  );
};
export default memo(ItemBillPreview);
