/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, Row, Typography } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadFileList from "../../../../components/UploadFileList";
import UploadMultipleFile from "../../../../components/UploadMultipleFile";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { _ } from "../../../../utils";

const { Text } = Typography;

const { addMedia, delMedia, setGlobalState } = actionTransfer;

const ManageFooter = ({ isPreview, isPending, isTransfer }) => {
  const dispatch = useDispatch();
  const mediaTransfer = useSelector((state) => state.transfer.mediaTransfer);
  const mediaReceive = useSelector((state) => state.transfer.mediaReceive);
  const remark = useSelector((state) => state.transfer.remark);
  const { remark: newRemark } = useSelector((state) => state.transfer.document);

  const handleChangeImg = useCallback(
    (value) => {
      dispatch(
        addMedia({ name: isTransfer ? "mediaTransfer" : "mediaReceive", value })
      )
    },
    []
  );
  const handleDeleteImg = useCallback(
    (value) => {
      dispatch(
        delMedia({ name: isTransfer ? "mediaTransfer" : "mediaReceive", value })
      )
    },
    []
  );

  return (
    <>
      {isTransfer ? (
        <Row gutter={16}>
          <Col span={4} md={3} xxl={2}>
            <Text>แนปรูปภาพ</Text>
          </Col>
          <Col span={20} md={21} xxl={22}>
            <UploadFileList
              disabled={isPreview || isPending}
              files={mediaTransfer}
              onChange={handleChangeImg}
              onDelete={handleDeleteImg}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row gutter={16}>
            <Col span={4} md={3} xxl={2}>
              <Text>รูปภาพโอนย้าย</Text>
            </Col>
            <Col span={20} md={21} xxl={22}>
              <UploadMultipleFile disabled={true} files={mediaTransfer} />
              {/* <UploadFileList disabled={true} files={mediaTransfer} /> */}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4} md={3} xxl={2}>
              <Text>รูปภาพรับเข้า</Text>
            </Col>
            <Col span={20} md={21} xxl={22}>
              <UploadMultipleFile disabled={isPreview} accept='image/*' files={mediaReceive} onChange={handleChangeImg} onRemove={handleDeleteImg} />
              {/* <UploadFileList
                disabled={isPreview}
                files={mediaReceive}
                onChange={handleChangeImg}
                onDelete={handleDeleteImg}
              /> */}
            </Col>
          </Row>
        </>
      )}

      <Row gutter={16} className="mb-2">
        <Col span={4} md={3} xxl={2}>
          <Text>หมายเหตุ</Text>
        </Col>
        <Col span={20} md={21} xxl={16}>
          {_.map(remark.itemKeys, (key) => (
            <Row key={key} className="ml-2">
              <Col span={24}>
                <Text>{remark.itemByKey[key].remark}</Text>
              </Col>
            </Row>
          ))}
          {!isPreview && (
            <Input.TextArea
              className="ml-2 mt-2"
              autoSize={{ minRows: 2, maxRows: 4 }}
              maxLength={500}
              value={newRemark}
              disabled={isPreview}
              onChange={(e) =>
                dispatch(
                  setGlobalState({
                    name: "document",
                    value: { remark: e.target.value },
                  })
                )
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ManageFooter;
