import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import visitFitStudioPaperServices from '../../../../../services/visit-fit-studio-paper.services';
import VisitFitStudioPaperForm from '../form/VisitFitStudioPaperForm';
import {  AlertSuccess } from '../../../../util/Alert';
import { VisitorFormTypeDefaultValues } from '../dto/visti-fit-studio-paper';

const VisitFitStudioPaperModal = ({ open, handleClose }) => {
  const {
    handleSubmit,
    watch,
    reset,
  } = useFormContext();

  visitFitStudioPaperServices.useQueryGetVisitFitStudioPaperById(
    {
      visitFitStudioPaperId: watch('id'),
    },
    (data) => {
      const knowFrom = data.knowFrom.split(',');
      reset({
        id: data.id,
        formType: data.formType,
        memberStatus: data.memberStatus,
        branchId: data.fitnessBranch.id,
        idCard: data.idCard,
        legacyPartnerCode: data.legacyPartnerCode,
        legacyConsultantPartnerCode: data.legacyConsultantPartnerCode,
        nameTitle: data.nameTitle,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        knowFrom,
        knowFromOther: data.knowFromOther,
        language: data.language,
        time: data.time,
        receivedTime: data.receivedTime,
      });
    }
  );

  const { mutate: updateVisitFitStudioPaper } =
    visitFitStudioPaperServices.useMutationUpdateVisitFitStudioPaper(
      (data) => {
        AlertSuccess('แก้ไขใบเยี่ยมชม/ทดลองใช้บริการสำเร็จ');
        reset(VisitorFormTypeDefaultValues);
        handleClose();
      }
    );

  const _HandleSubmit = handleSubmit(async(data) => {


      const knowFrom = data.knowFrom.join();
  
      const visitFitStudioPaperData = {
        id: data.id,
        formType: data.formType,
        memberStatus: data.memberStatus,
        branchId: data.branchId,
        idCard: data.idCard,
        legacyPartnerCode: data.legacyPartnerCode,
        legacyConsultantPartnerCode: data.legacyConsultantPartnerCode,
        nameTitle: data.nameTitle,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        knowFrom,
        knowFromOther: data.knowFromOther,
        language: data.language,
        time: data.time,
        receivedTime: data.receivedTime,
      };
  
      updateVisitFitStudioPaper({ id: data.id, data: visitFitStudioPaperData });
    
  });

  return (
    <Modal
      title={'แก้ไขใบเยี่ยมชม/ทดลองเล่น'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <VisitFitStudioPaperForm />
    </Modal>
  );
};

export default VisitFitStudioPaperModal;
