import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import useAsync from '../../../../../utils/useAsync';
import fitStudioService from '../../../../../services/fit-studio.service';
import { AlertSuccess } from '../../../../util/Alert';
import { rewardFormDefaultValues } from '../dto/reward';
import RewardForm from '../form/RewardForm';

const RewardModal = ({ open, handleClose }) => {
  const { watch, handleSubmit, reset } = useFormContext();

  const { execute: createReward } = useAsync(fitStudioService.createReward, {
    onSuccess: () => {
      AlertSuccess('เพิ่มเหรียญรางวัลสำเร็จ');
      reset(rewardFormDefaultValues);
      handleClose();
    },
  });

  const { execute: editReward } = useAsync(fitStudioService.editReward, {
    onSuccess: () => {
      AlertSuccess('แก้ไขเหรียญรางวัลสำเร็จ');
      reset(rewardFormDefaultValues);
      handleClose();
    },
  });

  const _HandleSubmit = handleSubmit((data) => {
    const rewardData = {
      title: data.title,
      frontImageUrl: data.frontImageUrl,
      backImageUrl: data.backImageUrl,
      greyFrontImageUrl: data.greyFrontImageUrl,
      greyBackImageUrl: data.greyBackImageUrl,
      description: data.description,
      max: data.max,
      start_date: new Date(data.start_date),
      end_date: new Date(data.end_date),
    };
    if (watch('formType') === 'create') {
      createReward(rewardData);
    } else if (watch('formType') === 'edit') {
      editReward({ id: data.id, data: rewardData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'สร้างเหรียญรางวัล'
          : 'แก้ไขเหรียญรางวัล'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <RewardForm />
    </Modal>
  );
};

export default RewardModal;
