import React from "react";
import BillDetail from "./BillDetail";
import BillHeader from "./BillHeader";
import BillMoreInfo from "./BillMoreInfo";

const Bill = (props) => {
  return (
    <div className="pos-payment-bill bg-gray-20">
      <BillHeader />
      <BillDetail />
      <BillMoreInfo />
    </div>
  );
};

export default Bill;
