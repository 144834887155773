import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import LegacyNotificationSendForm from '../form/LegacyNotificationSendForm ';
import fitLegacyServices from '../../../../../services/fit-legacy.services';
import moment from 'moment';

const LegacyNotificationSendModal = ({ open, handleClose }) => {
  const { handleSubmit, watch } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);

  const { mutate: sendLegacyNotification } =
    fitLegacyServices.useMutationSendLegacyNotification((data) => {
      setTimeout(() => {
        setLoading(false);
        setLoading3(false);
        setLoading4(false);
      }, 600);
    });

  const _HandleSubmit = handleSubmit((data) => {
    setLoading(true);
    sendLegacyNotification({
      ...data.record,
      effective_date: moment(data.effective_date).toDate(),
      receive_users: [],
      send_all: 'All',
    });
  });

  const _HandleSubmit3 = handleSubmit((data) => {
    setLoading3(true);
    sendLegacyNotification({
      ...data.record,
      effective_date: moment(data.effective_date).toDate(),
      receive_users: [],
      send_all: 'Distributor',
    });
  });

  const _HandleSubmit4 = handleSubmit((data) => {
    setLoading4(true);
    sendLegacyNotification({
      ...data.record,
      effective_date: moment(data.effective_date).toDate(),
      receive_users: [],
      send_all: 'Member',
    });
  });

  return (
    <Modal
      title="ส่ง Legacy Notification"
      visible={open}
      onCancel={handleClose}
      width={800}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          ยกเลิก
        </Button>,
        <Button
          key="send_all"
          type="primary"
          loading={loading}
          onClick={_HandleSubmit}
        >
          ส่งทุกคน
        </Button>,
        <Button
          key="send_distributor"
          type="primary"
          loading={loading3}
          onClick={_HandleSubmit3}
        >
          ส่งหานักธุรกิจ
        </Button>,
        <Button
          key="send_member"
          type="primary"
          loading={loading4}
          onClick={_HandleSubmit4}
        >
          ส่งหา VIP Member
        </Button>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          border: '1px solid #000',
          borderRadius: 10,
          padding: 5,
        }}
      >
        <div style={{ marginRight: 10 }}>
          {watch('record.thumbnail_img_url') && (
            <img
              alt={watch('record.title')}
              src={watch('record.thumbnail_img_url')}
              style={{
                width: 50,
                height: 50,
                borderRadius: 10,
              }}
            />
          )}
        </div>
        <div>
          <h2 style={{ fontWeight: 600, fontSize: 16 }}>
            {watch('record.title')}
          </h2>
          <p>{watch('record.description')}</p>
          <p>{watch('record.sub_description')}</p>
        </div>
      </div>
      <LegacyNotificationSendForm />
    </Modal>
  );
};

export default LegacyNotificationSendModal;
