/* eslint-disable no-useless-computed-key */
import { createSlice } from "@reduxjs/toolkit";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { moment, _ } from "../../utils";

function orderSourceByKey(data, initialData, key) {
  let itemKeys = [...initialData.itemKeys];
  let itemByKey = { ...initialData.itemByKey };
  if (key === "pos") {
    _.forEach(
      _.filter(data, (n) => n.order_source_key === key),
      (n) => {
        const branch_key = _.uniqueId("branch-");
        itemKeys = [...itemKeys, branch_key];
        itemByKey = {
          ...itemByKey,
          [branch_key]: {
            code: n.code,
            name: n.name,
            start_date: n.start_date ? moment(n.start_date) : n.start_date,
            end_date: n.end_date ? moment(n.end_date) : n.end_date,
            branch_key,
          },
        };
      }
    );
  } else {
    const n = _.find(data, (n) => n.order_source_key === key);
    if (n) {
      const branch_key = itemKeys[0];
      itemByKey = {
        [branch_key]: {
          code: n.code,
          start_date: n.start_date ? moment(n.start_date) : n.start_date,
          end_date: n.end_date ? moment(n.end_date) : n.end_date,
          branch_key,
        },
      };
    }
  }
  return { itemKeys, itemByKey };
}

const initState = {
  search: {
    search: "",
    limit: 10,
    page: 1,
    status: "Active",
    distributionType: "Sale",
    category: 0,
  },
  form: {
    id: 0,
    order_source: ["pos", "online", "mobile"],
    department_id: "",
    division_id: "",
    product_code: "",
    backend_sku_code: "",
    product_name_local: "",
    product_name_en: "",
    product_title_local: "",
    product_title_en: "",
    product_tagline_local: "",
    product_tagline_en: "",
    product_manual_local: "",
    product_manual_en: "",
    product_description_local: "",
    product_description_en: "",
    product_type: "",
    product_group: "",
    product_type_set: "",
    product_class: "",
    logistic_type: "",
    size_length: 0,
    size_width: 0,
    size_height: 0,
    size_weight: "",
    weight: 0,
    color: "",
    taste: "",
    smell: "",
    temperature: "",
    category: "",
    item_reborn: 0,
    retail_price: 0,
    member_price: 0,
    distributor_price: 0,
    staff_price: 0,
    member_redeem: 0,
    distributor_redeem: 0,
    member_sale: 0,
    distributor_sale: 0,
    staff_sale: 0,
    distribution: 0,
    quantity: 0,
    unit: "",
    fda: "",
    bar_code: "",
    point_01: 0,
    point_02: 0,
    point_03: 0,
    point_04: 0,
    point_05: 0,
    point_06: 0,
    point_07: 0,
    point_08: 0,
    point_09: 0,
    point_10: 0,
    r_point_01: 0,
    r_point_02: 0,
    r_point_03: 0,
    r_point_04: 0,
    r_point_05: 0,
    r_point_06: 0,
    r_point_07: 0,
    r_point_08: 0,
    r_point_09: 0,
    r_point_10: 0,
    d_point_01: 0,
    d_point_02: 0,
    d_point_03: 0,
    d_point_04: 0,
    d_point_05: 0,
    d_point_06: 0,
    d_point_07: 0,
    d_point_08: 0,
    d_point_09: 0,
    d_point_10: 0,
    main_dealer_code: "",
    point_01_qty: 0,
    point_02_qty: 0,
    point_03_qty: 0,
    point_04_qty: 0,
    point_05_qty: 0,
    point_06_qty: 0,
    point_07_qty: 0,
    point_08_qty: 0,
    point_09_qty: 0,
    point_10_qty: 0,
    point_01_rate: 0,
    point_02_rate: 0,
    include_vat: 0,
    start_date: null,
    end_date: null,
    status: "",
    vat: 0,
    distribution_type: "",
    set_type: "",
    is_pre_order: 0,
    sale_description: null,
    tax_schedule: "",
    unit_type: null,
    sale_unit: null,
    department: null,
    sub_category: "",
    groups: null,
    for_redeem: null,
    use_interface: null,
    size: "",
    delivery_type: "",
    point_time_01: null,
    point_time_02: null,
    point_time_03: null,
    point_time_04: null,
    point_time_05: null,
    point_time_06: null,
    r_point_time_01: null,
    r_point_time_02: null,
    r_point_time_03: null,
    r_point_time_04: null,
    r_point_time_05: null,
    r_point_time_06: null,
    redeem_point_time: null,
    split_pv: 0,
    shelef_life: null,
    shipping_rate: 0,
    remark: null,
    old_code: null,
    claim_price: 0,
    special_price: null,
    member_redeem_point: 0,
    distributor_redeem_point: 0,
    member_redeem_price: 0,
    distributor_redeem_price: 0,
    control_price: 0,
    product_chest: "",
    product_shoulder: "",
    product_waist: "",
    product_hip: "",
    product_width: "",
    product_height: "",
    product_length_1: "",
    product_length_2: "",
    product_depth: "",
    excel_name: null,
    flow_status: null,
    halal: "",
    volume: "",
    material: "",
    cost_price: 0,
    cost_price_inc_vat: 0,
    brand: "",
    selling_age: 0,
    unit_per_package: 0,
    package_width: 0,
    package_length: 0,
    package_height: 0,
    package_wieght: 0,
    overlay: 0,
    ingredient: "",
    caution: "",
    storage: "",
    aging: "",
    product_series: "",
    product_year: "",
    link: "",
    warranty: "",
    product_shape: "",
    packaging: "",
    event_date: [],
    event_start: null,
    event_end: null,
    energy: "",
    fat: "",
    sugar: "",
    sodium: "",
    show_approve: 0,
    show_cancel: 0,
    show_save: 0,
    oracle_category: "",
    oracle_sub_category: "",
    oracle_group: "",
    category_lv: {
      itemKeys: [],
      itemByKey: {},
    },
  },
  media: {
    delete: [],
    itemKeys: [],
    itemByKey: {},
  },
  bom: {
    visible: false,
    itemKeys: [],
    itemByKey: {},
  },
  orderSource: {
    pos: {
      itemKeys: [],
      itemByKey: {},
    },
    online: {
      itemKeys: ["branch-1"],
      itemByKey: {
        ["branch-1"]: {
          branch_key: "branch-1",
          code: null,
          start_date: null,
          end_date: null,
        },
      },
    },
    mobile: {
      itemKeys: ["branch-1"],
      itemByKey: {
        ["branch-1"]: {
          branch_key: "branch-1",
          code: null,
          start_date: null,
          end_date: null,
        },
      },
    },
  },
  modal: {
    branch: {
      visible: false,
      type: null,
      title: "",
    },
  },
};

const productSlice = createSlice({
  name: "product",
  initialState: initState,
  reducers: {
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    CLEAR_FORM: (state) => {
      let categoryKey = _.uniqueId("category-");
      state.form = {
        ...initState.form,
        category_lv: {
          itemKeys: [categoryKey],
          itemByKey: {
            [categoryKey]: [],
          },
        },
      };
      state.media = initState.media;
      state.bom = initState.bom;
    },
    LOAD_FORM: (state, { payload }) => {
      console.log(payload);
      const {
        media,
        media_id,
        bom,
        history = [],
        order_source_item,
        category_lv = [],
        ...data
      } = payload;
      let cate_lv = category_lv;
      if (cate_lv.length === 0) {
        cate_lv = [[]];
      }

      const description_content_bock = data.product_description_local
        ? htmlToDraft(data.product_description_local)
        : null;

      const event_date =
        data.event_date != null && data.event_date !== ""
          ? data.event_date.split(",")
          : [];

      let categoryItemKeys = [];
      let categoryItemByKey = {};
      _.forEach(cate_lv, (n) => {
        const category_key = _.uniqueId("category-");
        categoryItemKeys = [...categoryItemKeys, category_key];
        categoryItemByKey = { ...categoryItemByKey, [category_key]: n };
      });

      state.form = {
        ...initState.form,
        ...data,
        product_description_local: description_content_bock
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(
                description_content_bock.contentBlocks
              )
            )
          : null,
        event_start: event_date[0] ? moment(event_date[0]) : null,
        event_end: event_date[1] ? moment(event_date[1]) : null,
        category_lv: {
          itemKeys: categoryItemKeys,
          itemByKey: categoryItemByKey,
        },
        // event_date:
        //   data.event_date != null && data.event_date !== ""
        //     ? _.map(data.event_date.split(","), (n) => moment(n))
        //     : [],
      };

      let mediaItemKeys = [];
      let mediaItemByKey = {};
      _.forEach(media, (n, i) => {
        const media_key = _.uniqueId(`media-`);
        mediaItemKeys = [...mediaItemKeys, media_key];
        mediaItemByKey = {
          ...mediaItemByKey,
          [media_key]: {
            ...n,
            is_default: n.is_default, //media_id,
            // index_img: n.index_img ? n.index_img : i + 1,
            media_key,
          },
        };
      });
      state.media = {
        ...state.media,
        itemKeys: mediaItemKeys,
        itemByKey: mediaItemByKey,
      };

      let bomItemKeys = [];
      let bomItemByKey = {};
      _.forEach(bom, (n) => {
        const bom_key = _.uniqueId(`bom-`);
        bomItemKeys = [...bomItemKeys, bom_key];
        bomItemByKey = {
          ...bomItemByKey,
          [bom_key]: {
            ...n,
          },
        };
      });
      state.bom = {
        visible: bomItemKeys.length > 0,
        itemKeys: bomItemKeys,
        itemByKey: bomItemByKey,
      };
      state.history = history || [];
      state.orderSource = {
        ...state.orderSource,
        ...orderSourceItemMapping(order_source_item, initState.orderSource),
      };
    },
    SET_FORM: (state, { payload }) => {
      state.form = { ...state.form, ...payload };
    },
    SELECT_ORDER_TYPE: (state, { payload: { type, checked } }) => {
      let newOrderType = [...state.form.order_type];

      if (checked) {
        newOrderType.push(type);
      } else {
        newOrderType = _.filter(newOrderType, (n) => n !== type);
      }

      state.form.order_type = newOrderType;
    },
    SELECT_ORDER_SOURCE: (state, { payload: { type, checked } }) => {
      let newOrderSource = [...state.form.order_source];

      if (checked) {
        newOrderSource.push(type);
      } else {
        newOrderSource = _.filter(newOrderSource, (n) => n !== type);
      }

      state.form.order_source = newOrderSource;
    },

    ADD_ORDER_SOURCE: (state, { payload: { type, code, ...data } }) => {
      const index = _.findIndex(
        state.orderSource[type].itemKeys,
        (n) => state.orderSource[type].itemByKey[n].code === code
      );
      if (~index) {
        const branch_key = state.orderSource[type].itemKeys[index];
        state.orderSource[type].itemKeys = _.filter(
          state.orderSource[type].itemKeys,
          (n) => state.orderSource[type].itemByKey[n].code !== code
        );
        state.orderSource[type].itemByKey = _.omit(
          state.orderSource[type].itemByKey,
          [branch_key]
        );
      } else {
        const branch_key = _.uniqueId("branch-");
        state.orderSource[type].itemKeys = [
          ...state.orderSource[type].itemKeys,
          branch_key,
        ];
        state.orderSource[type].itemByKey = {
          ...state.orderSource[type].itemByKey,
          [branch_key]: {
            ...data,
            code,
            state_date: null,
            end_date: null,
            branch_key,
          },
        };
      }
    },
    SET_ORDER_SOURCE: (state, { payload: { type, branch_key, ...data } }) => {
      state.orderSource[type].itemByKey = {
        ...state.orderSource[type].itemByKey,
        [branch_key]: {
          ...state.orderSource[type].itemByKey[branch_key],
          ...data,
        },
      };
    },
    SET_ORDER_SOURCE_ALL: (state, { payload: { type, ...data } }) => {
      let newItemByKey = { ...state.orderSource[type].itemByKey };
      _.forEach(state.orderSource[type].itemKeys, (n) => {
        newItemByKey = {
          ...newItemByKey,
          [n]: {
            ...newItemByKey[n],
            ...data,
          },
        };
      });
      state.orderSource[type].itemByKey = newItemByKey;
    },
    ADD_ORDER_SOURCE_ALL: (state, { payload: { type, selected, data } }) => {
      let newItemKey = [];
      let newItemByKey = {};
      if (selected) {
        _.forEach(data, (n) => {
          const branch_key = _.uniqueId("branch-");
          newItemKey = [...newItemKey, branch_key];
          newItemByKey = {
            ...newItemByKey,
            [branch_key]: {
              start_date: null,
              end_date: null,
              ...n,
              branch_key,
            },
          };
        });
      }
      state.orderSource[type].itemKeys = newItemKey;
      state.orderSource[type].itemByKey = newItemByKey;
    },
    OPEN_MODAL: (state, { payload: { modal, ...payload } }) => {
      state.modal[modal] = {
        ...state.modal[modal],
        ...payload,
      };
    },
    CLOSE_MODAL: (state, { payload: { modal } }) => {
      state.modal[modal] = {
        ...initState.modal[modal],
      };
    },
    ADD_MEDIA: (state, { payload }) => {
      const media_key = _.uniqueId("media-");
      state.media.itemKeys = [...state.media.itemKeys, media_key];
      state.media.itemByKey = {
        ...state.media.itemByKey,
        [media_key]: {
          ...payload,
          id: null,
          is_default: state.media.itemKeys.length === 1,
          media_key,
        },
      };
    },
    SET_MEDIA: (state, { payload: { media_key, ...data } }) => {
      state.media.itemByKey = {
        ...state.media.itemByKey,
        [media_key]: {
          ...state.media.itemByKey[media_key],
          ...data,
        },
      };
    },
    DEL_MEDIA: (state, { payload: { media_key } }) => {
      const { id } = state.media.itemByKey[media_key];
      if (id) {
        state.media.delete = [...state.media.delete, id];
      }
      state.media.itemKeys = _.filter(
        state.media.itemKeys,
        (n) => n !== media_key
      );
      state.media.itemByKey = {
        ..._.omit(state.media.itemByKey, [media_key]),
      };
    },
    SET_MEDIA_DEFAULT: (state, { payload: { media_key } }) => {
      const { index_img } = state.media.itemByKey[media_key];
      console.log(index_img);
      state.media.itemByKey = _.reduce(
        state.media.itemKeys,
        (r, n) => {
          return {
            ...r,
            [n]: {
              ...state.media.itemByKey[n],
              is_default: state.media.itemByKey[n].media_key === media_key,
            },
          };
        },
        {}
      );
      // const other_key = _.filter(
      //   state.media.itemKeys,
      //   (n) => state.media.itemByKey[n].index_img < index_img
      // );
      // console.log(other_key);
      // state.media.itemByKey[media_key].index_img = 1;
      // for (let i = 0; i < other_key.length; i++) {
      //   const key = other_key[i];
      //   state.media.itemByKey[key].index_img += 1;
      // }
    },
    ADD_CATEGORY: (state) => {
      let addCategoryItemKeys = [...state.form.category_lv.itemKeys];
      let addCategoryItemByKey = { ...state.form.category_lv.itemByKey };

      let addCategoryKey = _.uniqueId("category-");

      addCategoryItemKeys.push(addCategoryKey);
      addCategoryItemByKey[addCategoryKey] = { list: [] };

      state.form.category_lv = {
        itemKeys: addCategoryItemKeys,
        itemByKey: addCategoryItemByKey,
      };
    },
    DEL_CATEGORY: (state, { payload }) => {
      let delCategoryItemKeys = [...state.form.category_lv.itemKeys];
      let delCategoryItemByKey = { ...state.form.category_lv.itemByKey };

      delCategoryItemKeys = _.filter(delCategoryItemKeys, (n) => n !== payload);
      delCategoryItemByKey = _.omit(delCategoryItemByKey, [payload]);

      state.form.category_lv = {
        itemKeys: delCategoryItemKeys,
        itemByKey: delCategoryItemByKey,
      };
    },
    SET_CATEGORY: (state, { payload: { key, value } }) => {
      state.form.category_lv.itemByKey[key] = { list: value };
    },
  },
});

export function orderSourceItemMapping(data, initial) {
  return {
    pos: orderSourceByKey(data, initial.pos, "pos"),
    online: orderSourceByKey(data, initial.online, "online"),
    mobile: orderSourceByKey(data, initial.mobile, "mobile"),
  };
}

export const searchSelector = (state) => state.product.search;
export const formSelector = (state) => state.product.form;
export const bomSelector = (state) => state.product.bom;
export const orderSourceSelector = (state) => state.product.orderSource;
export const modalSelector = (state) => state.product.modal;
export const mediaSelector = (state) => state.product.media;

export const actionProduct = productSlice.actions;

const ProductReducer = productSlice.reducer;

export default ProductReducer;
