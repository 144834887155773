import { Modal } from "antd";
import _ from "lodash";
import React from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fixDisCount } from "../../../../config/fix-value";
const CheckCashCard = () => {
  let { orders } = useSelector((state) => state.pos.operation);
  let activeKey = orders?.activeKey;
  let product_items = orders?.itemByKey[activeKey];
  let is_cash_card = _.some(
    product_items?.items?.itemByKey,
    (n) => n.product_code == fixDisCount.cash_card
  );
  useMemo(() => {
    if (is_cash_card) {
      Modal.warning({
        title: "เงื่อนไขการจำหน่ายบัตร Cash Card",
        content: (
          <div >
            <ul className="ml-0 pl-0">
              <li>รหัสผู้สั่งซื้อต้องเป็นนักธุรกิจชาวต่างชาติเท่านั้น</li>
              <li>
                รหัสผู้สั่งซื้อต้องส่งเอกสารสำเนาพาสปอร์ต ในระบบเรียบร้อยแล้ว
              </li>
              <li>
                รหัสผู้สั่งซื้อต้องส่งเอกสารสำเนาบัตรเลขประจำตัวผู้เสียภาษีอากร ในระบบเรียบร้อยแล้ว
              </li>
            </ul>
            กรุณาตรวจสอบเงื่อนไขดังกล่าวให้ครบถ้วน ก่อนทำการเปิดจำหน่ายบัตร Cash Card
          </div>
        ),
          
      });
    }
  }, [is_cash_card]);
};

export default CheckCashCard;
