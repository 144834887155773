/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Select, message, Divider, Modal } from "antd";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useEventMutate } from "../../../../../contexts/events.context";
import * as XLSX from 'xlsx';
import { eventRequest } from "../../../../../utils/useAxios";
import { AutoComplete } from "antdV4";
import TableImportTicketFile from "./TableImportTicketFile";
import { axios } from "../../../../../utils";

const { Option } = AutoComplete;

const ImportTicketModal = forwardRef(({ form, open, onCancel, setOpenImportTicketModal }) => {
  const {
    state: { search, exporting, setStorage },
    dispatch,
  } = useEventMutate();
  const fileInputRef = useRef(null);
  const { getFieldDecorator, validateFields, resetFields } = form;
  const [ticketData, setTicketData] = useState([])
  const [eventList, setEventList] = useState([])

  const handleClick = () => {
    // Trigger the click event on the file input
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Use FileReader to read the file
    const reader = new FileReader();
    reader.onload = (e) => {
      // Parse the Excel file using xlsx
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      // Assuming there is only one sheet in the Excel file
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert worksheet to JSON
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Extract the header row
      const headers = data[0];

      // Map the rest of the rows to objects
      const resultArray = data.slice(1).map(row => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });
      setTicketData(resultArray);
    };

    // Read the file as binary data
    reader.readAsBinaryString(selectedFile);

    // Reset the input value to null to allow selecting the same file again
    event.target.value = null;
  };

  const loadEventDataCallback = useCallback(async () => {
    setEventList([])
    try {
      const data = await eventRequest.get(`/events/active`, {});
      let list = []
      if (data) {
        for (const event of data.data.data) {
          const info = {
            eventId: event.Id,
            eventName: event.EventName
          }
          list.push(info)
        }
      }
      setEventList(list)
    } catch (error) {
      message.error(error.message);
    }
  }, [dispatch]);

  const onFinish = async (values) => {
    const data = {
      eventId: values.event_id,
      ticketData
    }
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการนำเข้าข้อมูลสำหรับสร้าง Ticket หรือไม่?",
      okText: "ยืนยัน",
      cancelText: "ปิด",
      async onOk() {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_EVENT_URL_API}/tickets-event/import`,
            data
          );
              
          Modal.success({
            title: "สำเร็จ",
            content: "นำเข้าข้อมูล Ticket สำเร็จ",
            okText: "ปิด",
            onOk() {
              Modal.destroyAll();
              setOpenImportTicketModal(false);
              window.location.reload();
            },
          });
        } catch (error) {    
          Modal.error({
            title: "ผิดพลาด",
            content: error?.response?.data?.message || error?.response?.data || error?.message,
            okText: "ปิด",
          });
        }
      },
    });
  }

  const handleModalCancel = () => {
    resetFields();
    setTicketData([])
    onCancel();
  };

  useEffect(() => {
    loadEventDataCallback()
  }, [loadEventDataCallback]);

  useEffect(() => {
    resetFields();
    setTicketData([])
  }, [])

  return (
    <>
      {/* <Form onSubmit={onFinish}> */}
        <Modal
          title="นำเข้าข้อมูลสำหรับสร้าง Ticket"
          visible={open}
          okText="นำเข้าข้อมูล"
          cancelText="ปิด"
          onCancel={handleModalCancel}
          onOk={() => {
            validateFields((err, values) => {
              if (!err) {
                onFinish(values);
              }
            });
          }}
        >
          <Row>
            <Col md={5}>
              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e)}
                />
                <Button onClick={handleClick}>เลือกไฟล์</Button>
              </div>
            </Col>
            <Col md={19} style={{ marginTop: '-4px' }}>
              {ticketData.length !== 0 && (
                <Form.Item>
                  {getFieldDecorator("event_id", {
                    rules: [{ required: true, message: 'กรุณาเลือกงาน' }],
                  })(
                    <Select
                      className="w-100"
                      showSearch
                      placeholder="เลือกงาน"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {eventList.length &&
                        eventList.map((event, index) => (
                          <Option key={index} value={event.eventId}>
                            {event.eventName}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
          {ticketData.length !== 0 &&
            <TableImportTicketFile data={ticketData} />
          }
        </Modal>
      {/* </Form> */}
    </>
  );
})

export default Form.create()(ImportTicketModal);