import React from "react";
import { Switch, Route } from "react-router-dom";

import BalanceList from "./BalanceList";
import BalanceDetail from "./BalanceDetail";

import Error404 from "../../Error404";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} children={<BalanceList />} />
      <Route
        exact
        path={`${match.path}/:id/:type/:branch_code`}
        children={<BalanceDetail />}
      />
      <Route component={Error404} />
    </Switch>
  );
};
