import React from "react";
import { Route, Switch } from "react-router-dom";

import Error404 from "../Error404";
import Distributor from "./distributor";
import MemberList from "./member-list";
import VIPmember from "./vip-member";

import EthicsInfo from "./EthicsInfo";
import EthicsList from "./EthicsList";
import { EthicsMutateProvider } from "../../../contexts/ethics.context";

export default ({ match }) => {
  return (
    <EthicsMutateProvider>
      <Switch>
        <Route exact path={match.path} component={EthicsList} />
        <Route exact path={`${match.path}/old`} component={MemberList} />
        <Route path={`${match.path}/vipmember/:id`} component={VIPmember} />
        <Route exact path={`${match.path}/:id`} component={Distributor} />
        <Route path={`${match.path}/:id/:type`} component={EthicsInfo} />

        <Route component={Error404} />
      </Switch>
    </EthicsMutateProvider>
  );
};
