const rewardConstants = {
  image: {
    width: 900,
    height: 900,
    ratio: '1:1',
  },
  tableSize: 10,
};

export default rewardConstants;
