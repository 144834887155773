/* eslint-disable react-hooks/exhaustive-deps */
import { Row } from "antd";
import React from "react";
import { _ } from "../../../../../../utils";
import Amount from "./Amount";
import Category from "./Category";
import Item from "./Item";

const Index = ({ list = [], conditionType, disabledAddItem, allItems }) => (
  <Row gutter={[16, 16]}>
    {_.map(list, (n, i) =>
      n.source_key === "item" ? (
        <Item
          allItems={allItems}
          key={i}
          data={n}
          conditionType={conditionType}
          disabledAddItem={disabledAddItem}
        />
      ) : n.source_key === "category" ? (
        <Category
          allItems={allItems}
          key={i}
          data={n}
          conditionType={conditionType}
          disabledAddItem={disabledAddItem}
        />
      ) : (
        <Amount key={i} data={n} conditionType={conditionType} />
      )
    )}
  </Row>
);

export default Index;
