/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Table,
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { ImPriceTag } from "react-icons/im";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectOption from "../../../pos/components/SelectOption";
import SaleSummaryContext, {
  //initState,
  reducer,
} from "../../contexts/summary-context";
import _ from "lodash";
import SelectAllType from "../../../../../components/SelectAllType";
import SelectBranch from "../../../pos/components/SelectBranch";

const reciptTypeOption = [
  { value: "sale", text: "ขาย" },
  { value: "redeem", text: "แลก" },
];

const buyTypeOption = [
  { value: "Hold", text: "Hold" },
  { value: "Regular", text: "Regular" },
];

const Index = () => {
  const toDay = moment().format("DD/MM/YYYY");
  const user = getUser();
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: user ? user?.branch_code : "",
      branch_name: user ? user?.branch_name : "",
      query: "",
      page: 1,
      limit: 10,
      buyer_type: [""],
    },
    summaryModal: {
      visible: false,
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [dataPayment, setDataPayment] = useState([]);
  const [page, setPage] = useState(1);
  const [billSelectedId, setBillSelectedId] = useState(null);
  const [preview, setPreview] = useState(false);
  const limit = 20;
  const [buyTypeFilter, setBuyTypeFilter] = useState("");
  const [receiptStatusFilter, setReceiptStatusFilter] = useState("Normal");
  const [receiptTypeFilter, setReceiptTypeFilter] = useState("");
  const [modalVoucherData, setModalVoucherData] = useState([]);
  const [openModalVocher, setOpenModalVoucher] = useState(false);
  const [totalVoucher, setTotalVoucher] = useState(0);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-branch`,
      token: true,
      params: {
        ...state.filter,
        limit: 0,
        offset: 0,
        userId: user.id,
        buyTypeFilter: buyTypeFilter,
        receiptStatusFilter: receiptStatusFilter,
        receiptTypeFilter: receiptTypeFilter,
        branch: state.filter.branch,
        // ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    []
  );

  useEffect(() => {
    setDataSource(data?.data ? data.data : []);
    if (data?.voucherList) {
      let total_voucher = _.sum(data.voucherList?.map((n) => n.value));
      setTotalVoucher(total_voucher);
    }
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [state.filter, buyTypeFilter, receiptStatusFilter, receiptTypeFilter]);

  const exportToExcel = async () => {
    try {
      const exportDataData = data?.data ? data?.data : [];

      if (exportDataData?.length !== 0) {
        let excelData = [
          ["รายงานยอดขายตามสาขา"],
          [
            `สาขา ${
              state.filter.branch === "" ? "ทั้งหมด" : state.filter.branch_name
            }`,
            `วันที่เปิดบิล  ${moment(state.filter.date_from).format(
              "DD/MM/YYYY"
            )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
            "",
            `วันที่ดึงข้อมูล: ${moment().format("LLL")} น.`,
            "",
            "",
            `ประเภทผู้ซื้อ : ${
              state.filter.buyer_type === ""
                ? "ทั้งหมด"
                : state.filter.buyer_type
            }`,
            "",
            `ประเภทการซื้อ : ${
              buyTypeFilter === "" ? "ทั้งหมด" : buyTypeFilter
            }`,
            "",
            `ประเภทใบเสร็จ : ${
              receiptTypeFilter === ""
                ? "ทั้งหมด"
                : receiptTypeFilter === "redeem"
                ? "แลก"
                : "ขาย"
            }`,
            "",
            `สถานะใบเสร็จ : ${
              receiptStatusFilter === "Normal" ? "ปกติ" : "ยกเลิก"
            }`,
            "",
          ],
          [""],
          [
            "ลำดับ",
            "เลขที่ใบเสร็จ",
            "วันที่ใบเสร็จ",
            "ผู้ทำรายการ",
            "รหัสสาขา",
            "สาขา",
            "รหัสสมาชิก",
            "ชื่อสมาชิก",
            "ประเภทผู้ซื้อ",
            "ประเภทการซื้อ",
            "ประเภทใบเสร็จ",
            "ยอดเงิน",
            "VAT",
            "ยอดเงินก่อน VAT",
            "ส่วนลด",
            "บัตรกำนัล",
            "จำนวนเงิน",
            "PV ที่ซื้อ",
            "EP ที่ซื้อ",
            "RP ที่ซื้อ",
            "RP ที่แลก",
            "เลขที่ชุดชำระเงิน",
            "องค์กร",
            "รหัสผู้แนะนำ",
            "ชื่อผู้แนะนำ",
            "สถานะ",
          ],
        ];

        exportDataData.forEach((n) => {
          excelData = [
            ...excelData,
            [
              { v: n.row_id, s: { alignment: { horizontal: "center" } } },
              { v: n.document_no, s: { alignment: { horizontal: "center" } } },
              {
                v:
                  n.row_id === "รวมจำนวนเงินทั้งหมด"
                    ? ""
                    : moment(n.document_date).format("DD/MM/YYYY HH:mm"),
                s: { alignment: { horizontal: "center" } },
              },
              { v: n.cashier_name, s: { alignment: { horizontal: "center" } } },
              { v: n.branch_code, s: { alignment: { horizontal: "center" } } },
              {
                v: n.bill_location,
                s: { alignment: { horizontal: "center" } },
              },
              { v: n.partner_code, s: { alignment: { horizontal: "center" } } },
              { v: n.partner_name, s: { alignment: { horizontal: "center" } } },
              { v: n.partner_type, s: { alignment: { horizontal: "center" } } },
              { v: n.order_type, s: { alignment: { horizontal: "center" } } },
              { v: n.sales_type, s: { alignment: { horizontal: "center" } } },
              {
                v: n.total_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v: n.vat_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v: n.total_amount_inc_vat,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v: n.discount_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v: n.voucher_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v: n.grand_total_amount,
                t: "n",
                s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
              },
              {
                v: n.pv,
                t: "n",
                s: { numFmt: "#,##0", alignment: { horizontal: "right" } },
              },
              {
                v: n.ep,
                t: "n",
                s: { numFmt: "#,##0", alignment: { horizontal: "right" } },
              },
              {
                v: n.rp,
                t: "n",
                s: { numFmt: "#,##0", alignment: { horizontal: "right" } },
              },
              {
                v: n.total_redeem,
                t: "n",
                s: { numFmt: "#,##0", alignment: { horizontal: "right" } },
              },
              n.document_group,
              n.team_name,
              n.sponsor_code,
              n.sponsor_name,
              n.document_status,
            ],
          ];
        });

        excelData = [
          ...excelData,
          [""],
          ["รวมจำนวนเงินแบ่งตามประเภท"],
          ["ประเภทการชำระเงิน", "ธนาคาร", "จำนวน"],
        ];
        (data?.paymentData || []).forEach((n) => {
          excelData = [
            ...excelData,
            [
              n.pay_in_type_text,
              n.name_th,
              {
                v: n.total_amount,
                t: "n",
                s: {
                  numFmt: "#,##0.00",
                  alignment: {
                    horizontal: "right",
                  },
                },
              },
            ],
          ];
        });

        const merge = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
          { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
          { s: { r: 1, c: 3 }, e: { r: 1, c: 5 } },
          { s: { r: 1, c: 6 }, e: { r: 1, c: 7 } },
          { s: { r: 1, c: 8 }, e: { r: 1, c: 9 } },
          { s: { r: 1, c: 10 }, e: { r: 1, c: 12 } },
        ];

        var wscols = [
          { wch: 25 },
          { wch: 25 },
          { wch: 15 },
          { wch: 15 },
          { wch: 12 },
          { wch: 15 },
          { wch: 15 },
          { wch: 25 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
          { wch: 25 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ];


        const ws = XLSX.utils.aoa_to_sheet(excelData);
        ws["!cols"] = wscols;
        //ws["!rows"] = wsrows;
        ws["!merges"] = merge;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "รายงานยอดขายตามสาขา");
        XLSX.writeFile(wb, "รายงานยอดขายตามสาขา.xlsx");
      } else {
        notification.warning({
          message: "ไม่มีข้อมูล",
          description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
          top: 60,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handlePreview = (id) => {
    setBillSelectedId(id);
    setPreview(true);
  };

  const handleBuyTypeChange = (e) => {
    setBuyTypeFilter(e);
  };

  const handleReceiptChange = (e) => {
    setReceiptStatusFilter(e.target.value);
  };

  const handleReceiptTypeChange = (e) => {
    setReceiptTypeFilter(e);
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานยอดขายตามสาขา</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center align-items-sm-center p-b-10"
          >
            <Col span={1}>
              <Text className="text-right d-block" strong>
                วันที่เปิดบิล
              </Text>
            </Col>

            <Col span={4}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
            <Col span={1} align="center">
              <Text className="text-right d-block" strong>
                สาขา
              </Text>
            </Col>
            <Col span={3}>
              <SelectBranch
                userLogin
                allowDisableBranch
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100"
                withAll={user.branch_count > 1}
                onChange={(value, option) => {
                  dispatch({
                    type: "setFilter",
                    payload: {
                      branch: value, // ค่าที่เลือก
                      branch_name: option?.props.children || "ทั้งหมด", // ชื่อสาขาจาก children
                    },
                  });
                }}
              />
            </Col>
            <Col span={2}>
              <Text className="text-right d-block" strong>
                ประเภทผู้ซื้อ
              </Text>
            </Col>
            <Col span={6}>
              <SelectAllType
                withAll
                process_type="buyer_type"
                labelIndex="code"
                defaultValue={[""]}
                placeholder="เลือกประเภทผู้ซื้อ"
                className="w-100 p-r-5"
                mode="multiple"
                addCustomer
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { buyer_type: e },
                  });
                }}
              />
            </Col>

            <Col span={7}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center align-items-sm-center p-b-10"
          >
            <Col className="pr-3">
              <Text className="text-right d-block" strong>
                ประเภทการซื้อ
              </Text>
            </Col>
            <Col span={2} align="left">
              <SelectOption
                className="w-100"
                withAll
                options={buyTypeOption}
                defaultValue={buyTypeFilter}
                onChange={handleBuyTypeChange}
              />
            </Col>

            <Col span={2}>
              <Text className="text-right d-block" strong>
                ประเภทใบเสร็จ
              </Text>
            </Col>
            <Col span={2} align="left">
              <SelectOption
                className="w-100"
                withAll
                options={reciptTypeOption}
                defaultValue={receiptTypeFilter}
                onChange={handleReceiptTypeChange}
              />
            </Col>

            <Col span={2}>
              <Text className="text-right d-block" strong>
                สถานะใบเสร็จ
              </Text>
            </Col>
            <Col span={3}>
              <Radio.Group
                onChange={handleReceiptChange}
                value={receiptStatusFilter}
              >
                <Radio value="Normal">ปกติ</Radio>
                <Radio value="Cancel">ยกเลิก</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            //dataSource={data?.data || []}
            dataSource={_.map(data?.data || [], (n) => ({
              ...n,
              key: _.uniqueId("data-"),
            }))}
            //rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับที่" dataIndex="row_id" align="center" />
            <Table.Column
              title="เลขที่ใบเสร็จ"
              render={(_, record) => {
                if (record?.id === data.fetch?.total) {
                  return " ";
                } else {
                  if (
                    record?.document_no === " " ||
                    record?.document_no === null
                  ) {
                    return " ";
                  } else {
                    return (
                      <Text
                        onClick={() => {
                          handlePreview(record?.id);
                        }}
                      >
                        {record?.document_no} <ImPriceTag />
                      </Text>
                    );
                  }
                }
              }}
            />
            {/* <Table.Column title="เลขที่สั่งซื้อออนไลน์" dataIndex="" /> */}
            <Table.Column
              title="วันที่ใบเสร็จ"
              dataIndex="document_date"
              render={(text) => {
                if (text === " ") {
                  return "";
                } else {
                  return moment(text).format("DD/MM/YYYY HH:mm");
                }
              }}
            />
            <Table.Column title="ผู้ทำรายการ" dataIndex="cashier_name" />
            <Table.Column
              title="รหัสสาขา"
              dataIndex="branch_code"
              align="center"
            />
            <Table.Column title="สาขา" dataIndex="bill_location" />
            <Table.Column title="รหัสสมาชิก" dataIndex="partner_code" />
            <Table.Column title="ชื่อสมาชิก" dataIndex="partner_name" />
            <Table.Column title="ประเภทผู้ซื้อ" dataIndex="partner_type" />
            <Table.Column
              title="ประเภทการซื้อ"
              dataIndex="order_type"
              align="center"
            />
            <Table.Column
              title="ประเภทใบเสร็จ"
              dataIndex="sales_type"
              align="center"
              render={(text) => {
                if (text === "sale") return "ขาย";
                else if (text === "redeem") return "แลก";
                else return text;
              }}
            />
            <Table.Column
              title="ยอดเงิน"
              dataIndex="total_amount"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="VAT ต่อบิล"
              dataIndex="vat_amount"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="ยอดเงินก่อน VAT"
              dataIndex="total_amount_inc_vat"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="ส่วนลด"
              dataIndex="discount_amount"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="บัตรกำนัลต่อบิล"
              dataIndex="voucher_amount"
              // render={(text) => {
              //   if (text === "") {
              //     return "";
              //   }
              //   return numeral(text).format("0,0.00");
              // }}
              render={(text, record) => {
                const { row_id, document_no, voucher_amount } = record;
                const voucherList = data?.voucherList;
                const voucher = _.filter(
                  voucherList,
                  (n) => n.document_no === document_no
                );
                const value_voucher = _.sum(voucher.map((v) => v.value)); // only one row
                if (row_id !== "รวมจำนวนเงินทั้งหมด") {
                  if (voucher_amount > 0) {
                    return (
                      <div className="flex items-center">
                        <span>{numeral(voucher_amount).format("0,0.00")}</span>
                        <span
                          className="ml-2 cursor-pointer p-2"
                          onClick={() => {
                            setModalVoucherData(voucher || []);
                            setOpenModalVoucher(true);
                          }}
                        >
                          <ImPriceTag />
                        </span>
                      </div>
                    );
                  } else {
                    return " - ";
                  }
                } else {
                  if (voucher_amount > 0) {
                    return (
                      <div className="flex items-center">
                        <span>{numeral(voucher_amount).format("0,0.00")}</span>
                        <span
                          className="ml-2 cursor-pointer p-2"
                          onClick={() => {
                            setModalVoucherData(voucherList || []);
                            setOpenModalVoucher(true);
                          }}
                        >
                          <ImPriceTag />
                        </span>
                      </div>
                    );
                  }
                  return numeral(voucher_amount).format("0,0.00");
                }
              }}
              align="right"
            />
            {/* <Table.Column
              title="ส่วนต่างบัตรกำนัลต่อบิล"
              dataIndex="voucher_dif"
              render={(text) => {
                if (text === undefined) {
                  return "-";
                } else {
                  return numeral(text).format("0,0.00");
                }
              }}
              align="right"
            /> */}

            {/* <Table.Column
              title="ส่วนต่างบัตรกำนัลต่อบิล"
              dataIndex="voucher_dif"
              render={(text, record) => {
                const voucherAmount = parseFloat(record.voucher_amount || 0);
                const grandTotalAmount = parseFloat(
                  record.grand_total_amount || 0
                );
                const discountAmount = parseFloat(
                  record.discount_amount || 0
                );
                if (voucherAmount <= grandTotalAmount) {
                  if(voucherAmount > 0){
                    return numeral(grandTotalAmount - voucherAmount).format("0,0.00");
                  }else{
                    return "-";
                  }
                } else {
                  const voucherDiff = voucherAmount - (grandTotalAmount-discountAmount);
                  return numeral(voucherDiff).format("0,0.00");
                }

              }}
              align="right"
            /> */}

            <Table.Column
              title="จำนวนเงิน"
              dataIndex="grand_total_amount"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="PV ที่ซื้อต่อบิล"
              dataIndex="pv"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="EP ที่ซื้อต่อบิล"
              dataIndex="ep"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="RP ที่ซื้อต่อบิล"
              dataIndex="rp"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="RP ที่แลกต่อบิล"
              dataIndex="total_redeem"
              render={(text) => {
                if (text === "") {
                  return "";
                }
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="เลขที่ชุดชำระเงิน"
              dataIndex="document_group"
              render={(text, record) => {
                if (record.document_status === "Cancel") {
                  return "";
                }
                return text;
              }}
            />
            {/* <Table.Column
              title="เลขที่บัตรกำนัล"
              dataIndex="voucher_code"
              align="center"
              render={(text) => {
                if (text === undefined || text === null) {
                  return "-";
                }
                return text;
              }}
            /> */}

            <Table.Column title="องค์กร" dataIndex="team_name" align="right" />
            <Table.Column
              title="รหัสผู้แนะนำ"
              dataIndex="sponsor_code"
              align="right"
            />
            <Table.Column
              title="ชื่อผู้แนะนำ"
              dataIndex="sponsor_name"
              align="right"
            />
            <Table.Column
              title="สถานะ"
              dataIndex="document_status"
              fixed="right"
            />
          </Table>

          <Table
            size="small"
            loading={loading}
            //dataSource={data?.paymentData}
            dataSource={_.map(data?.paymentData || [], (n) => ({
              ...n,
              key: _.uniqueId("payment-"),
            }))}
            //rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={false}
            // pagination={{
            //   current: 1,
            //   pageSize: data?.paymentData.length,
            //   total: data?.paymentData.length,
            //   showLessItems: false,
            //   size: "default",
            //   onChange: (currPage) => setPage(currPage),
            // }}
          >
            <Table.Column
              title="รวมจำนวนเงินแบ่งตามประเภท"
              dataIndex="row_id"
              align="left"
              render={(text) => {
                return (text = "");
              }}
            />
            <Table.Column
              title=""
              dataIndex="pay_in_type_text"
              align="right"
              render={(text) => (text === "RP" ? "มูลค่า Redeem" : text)}
              // render={(text) => {
              //   if (text === "CC") {
              //     return "Credit Card";
              //   } else if (text === "INS") {
              //     return "Installment";
              //   } else if (text === "RC") {
              //     return "Rabbit Point";
              //   } else if (text === "INS") {
              //     return "Installment";
              //   } else if (text === "DC") {
              //     return "Debit Card";
              //   } else if (text === "TP") {
              //     return "Transfer Point";
              //   }
              //   return text;
              // }}
            />
            <Table.Column
              title=""
              width={450}
              dataIndex="name_th"
              align="right"
            />
            <Table.Column
              title=""
              dataIndex="total_amount"
              align="right"
              width={250}
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
            />
          </Table>
        </PanelBody>
      </Panel>
      <BillPreview
        id={billSelectedId}
        preview={preview}
        setPreview={setPreview}
      />
      <Modal
        title="รายการบัตรกำนัล"
        visible={openModalVocher}
        footer={[]}
        onOk={() => setOpenModalVoucher(false)}
        onCancel={() => setOpenModalVoucher(false)}
      >
        <Table
          dataSource={modalVoucherData}
          pagination={false}
          bordered
          rowKey="row_id"
        >
          <Table.Column
            title="รหัสบัตรกำนัล"
            dataIndex="code"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
          <Table.Column
            title="ชื่อบัตรกำนัล"
            dataIndex="name"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
          <Table.Column
            title="มูลค่า"
            dataIndex="value"
            render={(text) => {
              if (text === null) {
                return "-";
              } else return text;
            }}
          />
        </Table>
      </Modal>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
