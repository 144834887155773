/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getUserObj, moment, _ } from "../../../../../utils";
import BranchConsider from "./BranchConsider";
import ManagerConsider from "./ManagerConsider";
import UserComplete from "./UserComplete";
import UserSave from "./UserSave";

const { Text } = Typography;

const ClaimFooter = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const user = getUserObj();
  const isPreview = useSelector((state) => state.claim.isPreview);
  const documentDetail = useSelector((state) => state.claim.documentDetail);

  const handleBack = () => {
    const back_url = `/${_.split(match.path, "/")[1]}/${match.params.process}`;
    history.push({ pathname: back_url });
  };

  const handleClickBack = () => {
    if (isPreview) return handleBack();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: (
        <div>
          <Text>คุณต้องการ</Text>
          <Text underline className="mx-1">
            ย้อนกลับ
          </Text>
          <Text>หรือไม่</Text>
        </div>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk: handleBack,
    });
  };

  return (
    <>
      <Row gutter={[8, 8]} className="my-3">
        <Col span={24} className="d-flex justify-content-between">
          <Text strong>เจ้าหน้าที่ : {user.name}</Text>
          <Text strong>วันที่ : {moment().format("DD/MM/YYYY")}</Text>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="my-3 text-center">
        <Button
          type="primary"
          ghost
          className="width-100 mr-3"
          onClick={handleClickBack}
        >
          ย้อนกลับ
        </Button>
        {!isPreview && (
          <>
            {_.includes([null, "draft", "waiting"], documentDetail.status) ? (
              <UserSave />
            ) : documentDetail.status === "wait-branch" ? (
              <BranchConsider />
            ) : documentDetail.status === "wait-manager" ? (
              <ManagerConsider />
            ) : (
              !!documentDetail.consider_status && <UserComplete />
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default ClaimFooter;
