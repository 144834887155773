/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Input, InputNumber, Typography } from "antd";
import { Table } from "antdV4";
import numeral from "numeral";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../../../hooks/dom";
import { actionInventoryCount } from "../../../../../redux/slices/inventory-count";
import { _ } from "../../../../../utils";
import { enterFocusNext } from "../../../../util/helper";

const { Text } = Typography;
const { Column } = Table;

const { setWarehouseItemValue, setSearchWarehouse, setExcelData } =
  actionInventoryCount;

const type = "primary_set";
const PrimarySet = ({ dataSource }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { itemByKey } = useSelector((state) => state.inventoryCount[type]);
  const { status: warehouse_status } = useSelector(
    (state) => state.inventoryCount.warehouse.itemByKey[type]
  );

  const is_preview = query.get("preview") === "1";
  const disabledDraft = warehouse_status === "pending" || is_preview;

  const handleChangeCount = (name, value, key, prev) => {
    if (value === null || _.isNaN(+value)) return;
    value = value || 0;
    const record = itemByKey[key];
    let count = _.filter(
      ["shelf", "storage_shelf", "warehouse"],
      (n) => n !== name
    );
    const main = _.find(
      dataSource,
      (n) => n.is_first && n.product_id === record.product_id
    );
    const total_count =
      _.reduce(count, (res, property) => res + record[property], 0) + value;
    const other_total_count = _.reduce(
      _.filter(
        dataSource,
        (n) => n.product_id === record.product_id && n.key !== key
      ),
      (res, n) => res + n.total_count,
      0
    );
    const difference = other_total_count + total_count - main.total_warehouse;
    dispatch(
      setWarehouseItemValue({
        type,
        key,
        name,
        prev,
        [name]: value,
        total_count,
        difference,
      })
    );
  };

  const handleChangeLot = (name, value, key) => {
    dispatch(
      setWarehouseItemValue({
        type,
        key,
        [name]: value,
      })
    );
  };

  useEffect(() => {
    dispatch(setExcelData(dataSource));
  }, [dataSource]);

  return (
    <Table
      bordered
      size="small"
      dataSource={dataSource}
      rowKey="product_id"
      className="ant-table-pagination-center table-very-small with-input"
      scroll={{ x: "max-content" }}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize: 50,
      }}
      // pagination={{
      //   current: page,
      //   pageSize: limit,
      //   showSizeChanger: false,
      //   showLessItems: true,
      //   hideOnSinglePage: true,
      //   total: dataSource.length,
      //   onChange: (page) =>
      //     dispatch(
      //       setSearchWarehouse({
      //         page,
      //       })
      //     ),
      // }}
    >
      <Column
        width={100}
        className="position-relative"
        title="รหัสสินค้า"
        key="product_code"
        dataIndex="product_code"
        align="center"
      />
      <Column
        width={300}
        className="position-relative max-width-600"
        title={
          <div className="ant-table-absolute-title-center">ชื่อสินค้า</div>
        }
        key="product_name"
        dataIndex="product_name"
      />
      <Column
        width={100}
        className="px-1"
        title="หน้าร้าน"
        key="shelf"
        dataIndex="shelf"
        align="center"
        render={(text, { key }) => (
          <InputNumber
            size="small"
            disabled={disabledDraft}
            min={0}
            value={text}
            onFocus={(e) => e.target.select()}
            onKeyUp={enterFocusNext}
            onChange={(value) => handleChangeCount("shelf", value, key, text)}
          />
        )}
      />
      <Column
        width={100}
        className="px-1"
        title="ชั้นพัก"
        key="storage_shelf"
        dataIndex="storage_shelf"
        align="center"
        render={(text, { key }) => (
          <InputNumber
            size="small"
            disabled={disabledDraft}
            min={0}
            value={text}
            onFocus={(e) => e.target.select()}
            onKeyUp={enterFocusNext}
            onChange={(value) =>
              handleChangeCount("storage_shelf", value, key, text)
            }
          />
        )}
      />
      <Column
        width={100}
        className="px-1"
        title="ในคลัง"
        key="warehouse"
        dataIndex="warehouse"
        align="center"
        render={(text, { key }) => (
          <InputNumber
            size="small"
            disabled={disabledDraft}
            min={0}
            value={text}
            onFocus={(e) => e.target.select()}
            onKeyUp={enterFocusNext}
            onChange={(value) =>
              handleChangeCount("warehouse", value, key, text)
            }
          />
        )}
      />
      <Column
        width={100}
        className="position-relative px-1"
        title={<div className="ant-table-absolute-title-center">ยอดรวม</div>}
        key="total_count"
        dataIndex="total_count"
        align="right"
        render={(text) => <Text strong>{numeral(text).format()}</Text>}
      />
      <Column
        className="px-1"
        title="หมายเหตุ"
        key="remark"
        dataIndex="remark"
        align="center"
        width={150}
        render={(text, { key }) => (
          <Input
            size="small"
            autoComplete="off"
            disabled={disabledDraft}
            value={text}
            maxLength={500}
            onKeyUp={enterFocusNext}
            onChange={(e) => handleChangeLot("remark", e.target.value, key)}
          />
        )}
      />
    </Table>
  );
};

export default PrimarySet;
