import { TOKEN_UPDATE } from '../actions/auth-action'
import { getUser, _ } from '../../utils';
const user = getUser();
const initialState = {
    authenticate: user ? user.token : null,
    ..._.omit(user, ['token'])
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case TOKEN_UPDATE:
            return {
                ...state,
                authenticate: payload ? payload.token : payload,
                ..._.omit(payload, ['token'])
            }

        default:
            return state
    }
}
