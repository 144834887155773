import { Col, message, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useStateValue } from "../StateContext";
import { axios, getToken, URL_API } from "../../../../../utils";
import "../styles.css";

const Criteria = () => {
  const { state, dispatch } = useStateValue();

  const [filter, setFilter] = useState([]);

  const loadDataCallback = useCallback(async () => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    try {
      const res = await axios.get("/manage-website/manage-upload", {
        baseURL: URL_API,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        params: {
          type: "",
        },
      });
      const codes = res.data.file_upload_list.map((item) => ({
        code: item.code,
        name: item.name,
      }));

      // Add the new item at the beginning of the array
      codes.unshift({ code: "", name: "ทั้งหมด" });
      setFilter(codes);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      message.error(error.message);
    }
  }, []);

  const handleChange = (value) => {
    dispatch({
      type: "SET_TYPE",
      payload: value,
    });
  };

  useEffect(() => {
    loadDataCallback();
  }, []);

  return (
    <Row gutter={16} className="align-items-center mb-3 d-flex">
      <Col md={2} className="text-right">
        <span style={{ fontSize: "14px" }}>ประเภท:</span>
      </Col>
      <Col md={6}>
        <Select
          defaultValue=""
          className="custom-select"
          style={{
            width: "100%",
            border: "none",
            padding: 0,
            height: "33px",
            fontSize: "14px",
          }}
          onChange={(value) => handleChange(value)}
        >
          {filter.map((n, index) => {
            return (
              <Select.Option key={index} value={n.code}>
                {n.name}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
    </Row>
  );
};

export default Criteria;
