import { Alert, Col, Row } from "antd";
import React from "react";
import { useEventMutate } from "../../../../../contexts/events.context";
import numeral from "numeral";

export default function SummaryTicket() {
  const {
    state: {
      fetch: { count },
    },
  } = useEventMutate();

  return (
    <Row gutter={32} className="mb-3">
      <Col md={4}>
        <Alert
          type="info"
          message={`จำนวนบัตรทั้งหมด : ${numeral(count.total || 0).format()}`}
        />
      </Col>
      <Col md={4}>
        <Alert
          type="success"
          message={`ลงทะเบียนแล้ว : ${numeral(count.registered || 0).format()}`}
        />
      </Col>
      <Col md={4}>
        <Alert
          type="error"
          message={`คงเหลือ/ยังไม่ลงทะเบียน : ${numeral(
            count.available || 0
          ).format()}`}
        />
      </Col>
      <Col md={4}>
        <Alert type="warning" message={`เข้างาน : ${numeral(count.used || 0).format()}`} />
      </Col>
      <Col md={4}>
        <Alert
          type="info"
          message={`คนใหม่เข้างาน : ${numeral(count.newBieUsed || 0).format()}`}
        />
      </Col>
      <Col md={4}>
        <Alert
          type="success"
          message={`คนใหม่ลงทะเบียน : ${numeral(count.newBieRegistered || 0).format()}`}
        />
      </Col>
    </Row>
  );
}
