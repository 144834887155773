import numeral from "numeral";
import { _, moment } from "../../../utils";

export const mapItemKeyToArray = ({ itemKeys, itemByKey }) =>
  _.map(itemKeys, (key) => ({ ...itemByKey[key], key }));

export const mapDataToItemKey = (data, prefix) =>
  _.reduce(
    data,
    (res, n) => {
      const key = _.uniqueId(prefix);
      return {
        itemKeys: [...res.itemKeys, key],
        itemByKey: { ...res.itemByKey, [key]: { ...n } },
      };
    },
    {
      itemKeys: [],
      itemByKey: {},
    }
  );

export const numInt = (e) => numeral(e).format("0,0");
export const numDec = (e) => numeral(e).format("0,0.00");

export const filterOptionSearch = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const isBirthDate = (date) => {
  const current_date = moment().format("DD/MM");
  const leap_date = "29/02";
  let birth_date = date && moment(date).format("DD/MM");
  if (birth_date === leap_date) birth_date = "28/02";
  return birth_date === current_date;
};
