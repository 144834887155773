import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { EDITOR_API_KEY, URL_API, _ } from "../utils";

export default function TextEditor({
  onInit,
  height = 500,
  onUploadSuccess,
  authToken,
  urlUpload = `${URL_API}/file/upload/upload-text-editor`,
  postBody = {},
  onEditorChange,
  ...props
}) {
  function example_image_upload_handler(blobInfo, success, failure, progress) {
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", urlUpload);
    if (authToken) xhr.setRequestHeader("Authorization", "Bearer " + authToken);

    xhr.upload.onprogress = function (e) {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = function () {
      var json;

      if (xhr.status === 403) {
        failure("HTTP Error: " + xhr.status, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure("HTTP Error: " + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);

      if (!json || typeof json.data.azure_url != "string") {
        failure("Invalid JSON: " + xhr.responseText);
        return;
      }

      success(json.data.azure_url);
      if (onUploadSuccess) {
        onUploadSuccess(json.data);
      }
    };

    xhr.onerror = function () {
      failure(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());
    _.forEach(postBody, (value, name) => {
      formData.append(name, value);
    });

    xhr.send(formData);
  }

  return (
    <Editor
      onInit={onInit}
      apiKey={EDITOR_API_KEY}
      init={{
        height: height,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help | image code link",

        images_upload_handler: example_image_upload_handler,

        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}

      onEditorChange={onEditorChange}
      {...props}
    />
  );
}
