import { Button, Col, Modal, Row, message } from "antd";
import React, { useCallback, useEffect, useRef } from "react";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import FormBanner from "./components/FormBanner";
import { URL_API, axios, getToken } from "../../../../utils";
import moment from "moment";
import _ from "lodash";

const BannerContent = ({ history, match }) => {
  const formRef = useRef(null);

  const loadDataCallback = useCallback(async (id) => {
    try {
      const res = await axios.get(`/manage-website/banner/${id}`, {
        baseURL: URL_API,
        headers: { Authorization: "Bearer " + getToken() },
      });

      const data = res.data;

      const show = [];
      if (data.show_online) show.push("online");
      if (data.show_mobile) show.push("mobile");

      const values = {
        page: data.page,
        show,
        online_banner: _.find(data.files, (n) => n.document_type === "online"),
        mobile_banner: _.find(data.files, (n) => n.document_type === "mobile"),
        start_date: data.start_date ? moment(data.start_date) : null,
        end_date: data.end_date ? moment(data.end_date) : null,
        status: data.status === "active",
      };

      formRef.current.setFieldsValue({
        ...values,
      });
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    }
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.validateFieldsAndScroll((err, values) => {
        if (err) return;
        send(values);
      });
    }
  };

  const send = (values) => {
    Modal.confirm({
      type: "warning",
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกข้อมูลหรือไม่?",
      okText: "บันทึก",
      cancelText: "ปิด",
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            const payload = {
              ...values,
              show_online: values.show.includes("online"),
              show_mobile: values.show.includes("mobile"),
              start_date: values.start_date
                ? moment(moment(values.start_date).format("YYYY-MM-DD HH:mm:ss"))
                : null,
              end_date: values.end_date
                ? moment(moment(values.end_date).format("YYYY-MM-DD HH:mm:ss"))
                : null,
              status: values.status ? "active" : "inactive",
              online_banner: values.online_banner?.response,
              mobile_banner: values.mobile_banner?.response,
            };
            let url = `/manage-website/banner`;
            if (match.params.id !== "0") url += `/${match.params.id}`;
            const res = await axios.post(url, payload, {
              baseURL: URL_API,
              headers: { Authorization: "Bearer " + getToken() },
            });
            resolve(res.data);
          } catch (error) {
            reject(error.response?.data?.message || error.message);
          }
        })
          .then((res) => {
            Modal.success({
              type: "success",
              title: "สำเร็จ",
              content: "บันทึกดข้อมูลเรียบร่้อย",
              okText: "ปิด",
              onOk() {
                history.push(`/manage-website/banner`);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              type: "error",
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    if (loadDataCallback && match.params.id !== "0") {
      loadDataCallback(match.params.id);
    } else {
      formRef.current.setFieldsValue({
        start_date: moment().startOf("month"),
        end_date: moment().endOf("month"),
      });
    }
  }, [match.params.id, loadDataCallback]);

  return (
    <Panel>
      <PanelHeader>รายละเอียดแบนเนอร์</PanelHeader>
      <PanelBody>
        <Row gutter={16}>
          <Col md={24} className="text-right">
            <Button type="primary" className="mr-2" onClick={handleSubmit}>
              บันทึก
            </Button>
            <Button
              type="default"
              onClick={() => history.push(`/manage-website/banner`)}
            >
              ยกเลิก
            </Button>
          </Col>
        </Row>
        <FormBanner ref={formRef} />
      </PanelBody>
    </Panel>
  );
};

export default BannerContent;
