import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { RiFileHistoryLine, RiFileUploadLine } from 'react-icons/ri';
import { Link, withRouter } from 'react-router-dom';
import { useQuery } from '../../../../hooks/dom';
import styles from '../../../../scss/module/legal.module.scss';

const PageMenuTab = ({ match }) => {
    const query = useQuery();
    const id = query.get('id');
    const type = query.get('membertype')
    const { path } = match;
    const [menu, setMenu] = useState('');
    useEffect(() => {
        setMenu(path.split('/')[2]);
    }, [path]);
    return (
        <Row className={styles['page-menu-tab']}>
            <Link to={`/legal/profile/register?membertype=${type}&id=${id}`}>
                <Col span={8} className={`${styles['col']} ${menu === 'profile' ? styles.active : ''}`}>
                    <FaUserAlt className="mr-2" />
                    <Typography.Text>โปรไฟล์</Typography.Text>
                </Col>
            </Link>
            {/* <Link to={`/legal/commission?id=${id}`}>
                <Col span={6} className={`${styles['col']} ${menu === 'commission' ? styles.active : ''}`} >
                    <GiReceiveMoney className="mr-2" />
                    <Typography.Text>คอมมิสชั่น</Typography.Text>
                </Col>
            </Link> */}
            <Link to={`/legal/document?membertype=${type}&id=${id}`}>
                <Col span={8} className={`${styles['col']} ${menu === 'document' ? styles.active : ''}`}>
                    <RiFileUploadLine className="mr-2" />
                    <Typography.Text>เอกสารที่อัปโหลด</Typography.Text>
                </Col>
            </Link>
            <Link to={`/legal/history?membertype=${type}&id=${id}`}>
            <Col span={8} className={`${styles['col']} ${styles['last']} ${menu === 'history' ? styles.active : ''}`}>
                <RiFileHistoryLine className="mr-2" />
                <Typography.Text>ประวัติสั่งซื้อ</Typography.Text>
            </Col>
            </Link>
        </Row>
    )
}

export default withRouter(PageMenuTab)