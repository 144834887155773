import React from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../../../Error404";

import AwEcosys from "./aw_ecosys/AwEcosys";
import Card from "./card_ecosys/Card";
import CardList from "./card_ecosys/CardList";
import IntroEcosys from "./intro_ecosys/IntroEcosys";

export default ({ match }) => {
  console.log(match.path)
  return (
    <Switch>
      <Route path={`${match.path}/aw-ecosystem`} exact component={AwEcosys} />
      <Route path={`${match.path}/card-ecosystem`} exact component={CardList} />
      <Route path={`${match.path}/card-ecosystem/:id`} exact component={Card} />
      <Route path={`${match.path}/intro-ecosystem`} exact component={IntroEcosys} />

      <Route component={Error404} />
    </Switch>
  );
};
