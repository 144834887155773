/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification, Tooltip } from "antd";
import numeral from "numeral";
import React, { useMemo, useState } from "react";
import { FaEye } from "react-icons/fa";
import { connect } from "react-redux";
import noImg from "../../../../assets/img/pos/no-img.jpg";
import {
  fixProductType,
  fixProductTypeSet,
} from "../../../../config/fix-value";
import { _ } from "../../../../utils";

const ItemCard = ({ source, onClick, onSelect, checkStock, ...props }) => {
  const [errImg, setErrImg] = useState(null);
  const banned =
    (source.product_type !== fixProductType.renew &&
      props.allow_item === false) ||
    (source.product_type === fixProductType.renew &&
      props.allow_renew === false);
  const sold_out =
    checkStock &&
    !banned &&
    !_.includes(
      [fixProductTypeSet.none_inventory, fixProductTypeSet.service],
      source.product_type_set
    )
      ? !source.stock_balance || source.stock_balance < 0
      : false;
  // const banned_ralc =
  // (source.product_type == "PT09" && props.is_married && source.remark_ralc == "Double")

  //     (source.product_code == "PT09" && (
  //   (props.ralc?.remark == "Double" && props.ralc?.level >= source.level_ralc) ||
  //   (props.ralc?.remark != "Double" && (
  //     props.ralc?.level > source.level_ralc ||
  //     (props.ralc?.level >= source.level_ralc && source.remark_ralc != "Double")
  //   ))
  // ))
  const handleDisableRalc = (source) => {
    // return false
    if (!source.course_ralc) return false;
    const { remark_ralc, level_ralc } = source;
    const { is_married, ralc } = props;
    return (
      (!is_married && remark_ralc === "Double") ||
      (ralc?.remark === "Double" && ralc?.level >= level_ralc) ||
      (ralc?.remark !== "Double" &&
        (ralc?.level > level_ralc ||
          (ralc?.level >= level_ralc && remark_ralc !== "Double")))
    );
  };
  const BookedCourseRalc = (source) => {
    // return false
    if (!source.course_ralc) return false;
    const { remark_ralc, level_ralc } = source;
    const { is_married, booked_ralc } = props;
    return (
      (!is_married && remark_ralc === "Double") ||
      (booked_ralc?.remark === "Double" && booked_ralc?.level >= level_ralc) ||
      (booked_ralc?.remark !== "Double" &&
        (booked_ralc?.level > level_ralc ||
          (booked_ralc?.level >= level_ralc && remark_ralc !== "Double")))
    );
  };
  const banned_ralc = handleDisableRalc(source);
  const banned_booked = BookedCourseRalc(source);
  let message;
  if (banned) {
    if (
      source.product_type !== fixProductType.renew &&
      props.allow_item === false
    ) {
      message = `นักธุกิจไม่สามารถซื้อ ${source.product_name_local} ได้`;
    } else {
      message = "นักธุกิจไม่สามารถซื้อใบต่ออายุได้";
    }
  } else if (sold_out) {
    message = "ไม่มีสินค้าใน stock";
  } else if (banned_ralc) {
    message = "ไม่สามารถซื้อสินค้านี้ได้";
  } else if (banned_booked) {
    message = "ไม่สามารถซื้อสินค้านี้ได้เนื่องจากมีการจองไว้อยู่";
  } else {
    message = "";
  }
  const warn_text = message;
  // banned ? source.product_type !== fixProductType.renew && props.allow_item === false
  //     ? `นักธุกิจไม่สามารถซื้อ ${source.product_name_local} ได้`
  //     : "นักธุกิจไม่สามารถซื้อใบต่ออายุได้"
  //   : sold_out
  //   ? "ไม่มีสินค้าใน stock"
  //   : "";

  const is_redeem = useMemo(() => {
    return source.distribution_type?.toLowerCase() === "redeem";
  }, [source.distribution_type]);

  return (
    <Tooltip title={source.product_name_en} placement="bottomRight">
      <div className="item-card">
        <div
          className="cursor-pointer"
          onClick={() => !warn_text && onSelect(source)}
        >
          <p className={`item-info m-0 ${warn_text ? "item-disabled" : ""}`}>
            <span className="text-white bg-gray-60 px-2 mb-1 rounded text-truncate">
              {source.product_code}
            </span>
            <span className="text-white bg-reds px-2 mb-2 rounded">
              {`${numeral(source.unit_price).format("0,0")} ${
                is_redeem ? "RP" : ""
              }`}
            </span>
          </p>
          {!!warn_text && (
            <div
              className="item-sold-out"
              onClick={() =>
                notification.warning({
                  message: "ไม่สามารถเลือกสินค้านี้ได้",
                  description: warn_text,
                })
              }
            >
              {banned || banned_ralc || banned_booked ? (
                <span>BANNED</span>
              ) : (
                <span>SOLD OUT</span>
              )}
            </div>
          )}
          <img
            src={errImg || `${source.azure_url}`}
            className={`${warn_text ? "item-disabled" : ""}`}
            alt="รูปสินค้า"
            onError={() => setErrImg(noImg)}
          />
        </div>
        <a
          className={`item-view-detail  ${warn_text ? "item-disabled" : ""}`}
          onClick={() => onClick(source)}
        >
          <span className="d-flex justify-content-center align-items-center bg-pv text-white rounded-circle p-md-1 ml-2">
            <FaEye />
          </span>
        </a>
      </div>
    </Tooltip>
  );
};

const mapStateToProps = (state) => {
  const orders = state.pos.operation.orders;
  const { activeKey, itemByKey } = orders;
  return {
    allow_item: itemByKey[activeKey]?.allow_item,
    allow_renew: itemByKey[activeKey]?.allow_renew,
    is_married: itemByKey[activeKey]?.is_married,
    ralc: itemByKey[activeKey]?.ralc?.ralc,
    booked_ralc: itemByKey[activeKey]?.ralc?.booked_ralc,
  };
};

export default connect(mapStateToProps, null)(React.memo(ItemCard));
