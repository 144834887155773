import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: {
    query: "",
    page: 1,
    status: "active",
    type: "giveaway",
  },
};

const promotionListSlice = createSlice({
  name: "promotionList",
  initialState,
  reducers: {
    setSearch: (state, { payload }) => {
      state.search = {
        ...state.search,
        ...payload,
      };
    },
    clear: (state) => {
      state.search = initialState.search;
    },
  },
});

// selector
export const searchSelector = (state) => state.promotionList.search;

// action
export const actionPromotionList = promotionListSlice.actions;

// reducer
export default promotionListSlice.reducer;
