/* eslint-disable no-unused-vars */
import { Col, notification, Row, Table, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { FaCheck } from "react-icons/fa";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import numeral from "numeral";
const { Text } = Typography;

const user = getUser();

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState("");

  let limit = 20;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-profile`,
      token: true,
      params: {
        lim: limit,
        offset: limit * (page - 1),
        userId: user.id,
        memberTypeFilter: memberTypeFilter,
        partnerStatusFilter: partnerStatusFilter,
        teamFilter: teamFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page]
  );

  // console.log(data?.data);
  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-filter`,
      token: true,
    },
    []
  );

  // const headerStyles = {
  //   border: {
  //     top: { style: "thin", color: { rgb: "000000" } },
  //     bottom: { style: "thin", color: { rgb: "000000" } },
  //     left: { style: "thin", color: { rgb: "000000" } },
  //     right: { style: "thin", color: { rgb: "000000" } },
  //   },
  //   fill: { fgColor: { rgb: "E9E9E9" } },
  // };

  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานข้อมูลนักธุรกิจ - เต็มรูปแบบ"],
        [
          `ประเภทสมาชิก : ${
            memberTypeFilter === "" ? "ทั้งหมด" : memberTypeFilter
          }`,
          "",
          "",
          `สถานะสมาชิก : ${
            partnerStatusFilter === "" ? "ทั้งหมด" : partnerStatusFilter
          }`,
          "",
          `Team : ${teamFilter === "" ? "ทั้งหมด" : teamFilter}`,
          `วันที่สมัคร : ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY เวลา HH:mm:ss")}`,
        ],
        [""],
        [
          "ลำดับที่",
          "ประเภทสมาชิก",
          //"Location Base",
          "Team",
          "ข้อมูลผู้สมัครหลัก",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ข้อมูลผู้สมัครร่วม",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ชื่อผู้แนะนำ",
          "",
          "",
          "",
          "เอกสารการสมัคร",
          "",
          "",
          "สถานะ",
        ],
        [
          "",
          "",
          "",
          "ชื่อ - นามสกุล",
          "เลขบัตรประชาชน",
          "Passport",
          "วัน/เดือน/ปี เกิด",
          "อายุ",
          "เพศ",
          "สัญชาติ",
          "เบอร์โทร",
          "วันที่สมัครสมาชิก",
          "วันที่หมดอายุสมาชิก",
          "ชื่อ - นามสกุล",
          "เลขบัตรประชาชน",
          "Passport",
          "วัน/เดือน/ปี เกิด",
          "อายุ",
          "เพศ",
          "สัญชาติ",
          "เบอร์โทร",
          "วันที่สมัครสมาชิก",
          "วันที่ลาออก",
          "รหัสสมาชิก",
          "ชื่อสมาชิก",
          "เบอร์โทรศัพท์",
          "ตำแหน่ง",
          "ใบสมัคร",
          "สำเนาบัตรประชาชน",
          "สำเนาสมุดบัญชีธนาคาร",
          "",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.member_type,
            //n.location_base,
            n.team_name,
            n.full_name,
            n.id_card,
            n.passport_id,
            n.birth_date === null
              ? ""
              : moment(n.birth_date).format("DD/MM/YYYY"),
            n.age,
            n.gender === "F" ? "หญิง" : n.gender === "M" ? "ชาย" : n.gender,
            //n.origin,
            n.location_base,
            n.mobile,
            moment(n.register_date).format("DD/MM/YYYY"),
            n.expire_date == null
              ? ""
              : moment(n.expire_date).format("DD/MM/YYYY"),
            n.join_full_name,
            n.join_id_card,
            n.join_passport,
            n.join_birth_date === null
              ? ""
              : moment(n.join_birth_date).format("DD/MM/YYYY"),
            n.join_age,
            "", // join_gender ไม่มี table ใน db
            //n.location_base,
            n.join_origin,
            "", // join_mobile ไม่มี table ใน db
            n.second_register === null
              ? ""
              : moment(n.second_register).format("DD/MM/YYYY"),
            n.join_resign === null ? "": moment(n.join_resign).format("DD/MM/YYYY"), // join_resign_date ไม่มี table ใน db
            n.sponser_code,
            n.sponser_name,
            n.sponser_mobile,
            n.sponser_honor,
            {
              v: n.approve_document === "1" ? `✓ ( ${moment(n.approve_document_date).format("DD/MM/YYYY HH:mm")})` : "",
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.approve_id_card === "1" ? `✓ ( ${moment(n.approve_id_card_date).format("DD/MM/YYYY HH:mm")})` : "",
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.approve_book === "1" ? `✓ ( ${moment(n.approve_book_date).format("DD/MM/YYYY HH:mm")})` : "",
              s: { alignment: { horizontal: "center" } },
            },
            n.status,
            // {v: n.amount === "none" ? "" : numeral(n.amount).format("0,0.00"), s: { alignment: { horizontal: "right" } } },
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 7 } },
        { s: { r: 1, c: 8 }, e: { r: 1, c: 10 } },
        // manage : header table
         { s: { r: 3, c: 3 }, e: { r: 3, c: 12 } },
         { s: { r: 3, c: 13 }, e: { r: 3, c: 22 } },
         { s: { r: 3, c: 23 }, e: { r: 3, c: 26 } },
         { s: { r: 3, c: 27 }, e: { r: 3, c: 29 } },

         { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
         { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
         { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
       // { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },
         { s: { r: 3, c: 30 }, e: { r: 4, c: 30 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
      ];

      var wsrows = [
        { hpx: 16 }, // "pixels"
        { hpx: 16 }, // "pixels"
        { hpx: 16 }, // "pixels"
        { hpx: 24 }, // "pixels"
        { hpx: 24 }, // "pixels"
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        // fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      // const ws = XLSX.utils.json_to_sheet(excelData); //------old version

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["N4"].s = centerStyles;
      ws["X4"].s = centerStyles;
      ws["AE4"].s = centerStyles;
      ws["AB4"].s = centerStyles;

      ws["E4"].s = centerStyles;
      ws["O4"].s = centerStyles;
      ws["Y4"].s = centerStyles;
      ws["AC4"].s = centerStyles;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานข้อมูลนักธุรกิจเต็มรูปแบบ");
      XLSX.writeFile(wb, "รายงานข้อมูลนักธุรกิจเต็มรูปแบบ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  useEffect(() => {
    setDataSource(data ? data : []);
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);

  const memberTypeOptions = [
    { value: "Distributor", text: "นักธุรกิจ" },
    { value: "Member", text: "VIP MEMBER" },
  ];

  const partnerStatusOptions = [
    ..._.map(filterDataSource.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานข้อมูลนักธุรกิจ - เต็มรูปแบบ</PanelHeader>
        <PanelBody>
          <FilterList
            placeholder="รหัสสมาชิก, ชื่อสมาชิก, ชื่อผู้สมัครร่วม"
            showBranch={false}
            //showReportSpan={10}
            showReportPush={6}
            allowDisableBranch
            Topic={"วันที่สมัคร"}
            topicAlign={"center"}
            reload={() => {
              setPage(1)
              reload();
            }}
            exportToExcel={exportToExcel}
          />

          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>สถานะสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={partnerStatusFilter}
                options={partnerStatusOptions}
                onChange={(e) => {
                  setPartnerStatusFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>Team</Text>
            </Col>
            <Col span={4}>
              <SelectTeam
                withAll
                withNoTeam
                defaultValue={teamFilter}
                onChange={(e) => {
                  setTeamFilter(e);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data?.data || []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              showLessItems: false,
              total: data?.total,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="ประเภทสมาชิก"
              dataIndex="member_type"
              align="center"
              render={(text) => {
                if (text === "Distributor") {
                  return "นักธุรกิจ";
                } else if (text === "Member") {
                  return "VIP MEMBER";
                } else {
                  return text;
                }
              }}
            />
            {/* <Table.Column
              title="Location Base"
              dataIndex="location_base"
              align="center"
            /> */}
            <Table.Column title="Team" dataIndex="team_name" align="center" />
            <Table.ColumnGroup title="ข้อมูลผู้สมัครหลัก">
              <Table.Column
                title="รหัสสมาชิก"
                dataIndex="partner_code"
                align="center"
              />
              <Table.Column
                title="ชื่อ-สกุล"
                dataIndex="full_name"
                align="left"
              />
              <Table.Column
                title="เลขบัตรประชาชน"
                dataIndex="id_card"
                align="center"
              />
              <Table.Column
                title="Passport"
                dataIndex="passport_id"
                align="center"
              />
              <Table.Column
                title="วัน/เดือน/ปี เกิด"
                dataIndex="birth_date"
                align="center"
                render={(text) => {
                  if (text === null || text === "") {
                    return "";
                  } else {
                    return moment(text).format("DD/MM/YYYY");
                  }
                }}
              />
              <Table.Column title="อายุ" dataIndex="age" align="center" />
              <Table.Column title="เพศ" dataIndex="gender" align="center" />
              <Table.Column
                title="สัญชาติ"
                dataIndex="location_base"
                align="center"
              />
              <Table.Column
                title="เบอร์โทร"
                dataIndex="mobile"
                align="center"
              />
              <Table.Column
                title="วันที่สมัครสมาชิก"
                dataIndex="register_date"
                align="center"
                render={(text) => {
                  if (text === null || text === "") {
                    return "";
                  } else {
                    return moment(text).format("DD/MM/YYYY");
                  }
                }}
              />
              <Table.Column
                title="วันที่หมดอายุสมาชิก"
                dataIndex="expire_date"
                align="center"
                render={(text) => {
                  if (text === null || text === "") {
                    return "";
                  } else {
                    return moment(text).format("DD/MM/YYYY");
                  }
                }}
              />
            </Table.ColumnGroup>
            <Table.ColumnGroup title="ข้อมูลผู้สมัครร่วม">
              <Table.Column
                title="รหัสสมาชิก"
                dataIndex="join_partner_code"
                align="center"
              />
              <Table.Column
                title="ชื่อ-สกุล"
                dataIndex="join_full_name"
                align="left"
              />
              <Table.Column
                title="เลขบัตรประชาชน"
                dataIndex="join_id_card"
                align="center"
              />
              <Table.Column
                title="Passport"
                dataIndex="join_passport"
                align="center"
              />
              <Table.Column
                title="วัน/เดือน/ปี เกิด"
                dataIndex="join_birth_date"
                align="center"
                render={(text) => {
                  if (text === null || text === "") {
                    return "";
                  } else {
                    return moment(text).format("DD/MM/YYYY");
                  }
                }}
              />
              <Table.Column title="อายุ" dataIndex="join_age" align="center" />
              <Table.Column
                title="สัญชาติ"
                dataIndex="join_origin"
                //dataIndex="location_base"
                align="center"
              />
              <Table.Column
                title="เบอร์โทร"
                dataIndex="join_mobile"
                align="center"
              />
              <Table.Column
                title="วันที่สมัคร"
                dataIndex="second_register"
                align="center"
                render={(text) => {
                  if (text === null || text === "") {
                    return "";
                  } else {
                    return moment(text).format("DD/MM/YYYY");
                  }
                }}
              />

              <Table.Column
                title="วันที่ลาออก"
                dataIndex="join_resign"
                align="center"
                render={(text,record) => {
                  if(text){
                    return moment(text).format("DD/MM/YYYY")
                  }else{
                    return '';
                  }
                }}
              />
            </Table.ColumnGroup>
            <Table.ColumnGroup title="ข้อมูลผู้แนะนำ">
              <Table.Column
                title="รหัสสมาชิก"
                dataIndex="sponsor_code"
                align="center"
              />
              <Table.Column
                title="ชื่อสมาชิก"
                dataIndex="sponsor_name"
                align="left"
              />
              <Table.Column
                title="เบอร์โทรศัพท์"
                dataIndex="sponsor_mobile"
                align="center"
              />
              <Table.Column
                title="ตำแหน่ง"
                dataIndex="sponsor_honor"
                align="center"
              />
            </Table.ColumnGroup>
            <Table.ColumnGroup title="เอกสารการสมัคร">
              <Table.Column
                title="ใบสมัคร"
                dataIndex="approve_document"
                align="center"
                render={(text,record) => {
                  if (text === "0") {
                    return "-";
                  } else if (text === "1") {
                    return <div><FaCheck />  ({moment(record.approve_document_date).format("DD/MM/YYYY HH:mm")})</div>  ;
                  } else {
                    return "-";
                  }
                }}
              />
              <Table.Column
                title="สำเนาบัตรประชาชน"
                dataIndex="approve_id_card"
                align="center"
                render={(text,record) => {
                  if (text === "0") {
                    return "-";
                  } else if (text === "1") {
                    return <div><FaCheck />  ({moment(record.approve_id_card_date).format("DD/MM/YYYY HH:mm")})</div>;
                  } else {
                    return "-";
                  }
                }}
              />
              <Table.Column
                title="สำเนาสมุดบัญชีธนาคาร"
                dataIndex="approve_book"
                align="center"
                render={(text, record) => {
                  if (text === "0") {
                    return "-";
                  } else if (text === "1") {
                    return <div><FaCheck />  ({moment(record.approve_book_date).format("DD/MM/YYYY HH:mm")})</div>;
                  } else {
                    return "-";
                  }
                }}
              />
            </Table.ColumnGroup>
            <Table.Column title="สถานะ" dataIndex="status" align="center" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
