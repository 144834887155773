import { createSlice } from "@reduxjs/toolkit";

const initState = {
  search: {
    page: 1,
    query: "",
    limit: 10,
    category: 0,
  },
  content: {
    tab: "th",
    mkt_content_local: "",
    mkt_content_en: "",
  },
};

const MKTProductSlice = createSlice({
  name: "product",
  initialState: initState,
  reducers: {
    SET_SEARCH: (state, { payload }) => {
      state.search = {
        ...state.search,
        ...payload,
      };
    },
    SET_CONTENT: (state, { payload }) => {
      state.content = {
        ...state.content,
        ...payload,
      };
    },
    LOAD_CONTENT: (state, { payload }) => {
      state.content = {
        ...initState.content,
        ...payload,
      };
    },
  },
});

export const actionMKTProduct = MKTProductSlice.actions;

export const searchSelector = (state) => state.marketing.product.search;
export const contentSelector = (state) => state.marketing.product.content;

const MKTProductReducer = MKTProductSlice.reducer;

export default MKTProductReducer;
