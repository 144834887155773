import { Col, Input, Modal, notification, Row, Typography } from "antd";
import React, { memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fixAcceptPartner } from "../../../../../config/fix-value";
import { actionClaim } from "../../../../../redux/slices/claim";
import { axios, URL_API, _ } from "../../../../../utils";
import { resCatch } from "../../../../util/helper";
import SelectSearch from "../../../pos/components/SelectSearch";

const { Text } = Typography;

const { setGlobalState } = actionClaim;

const ReceiptBranch = ({ disabled }) => {
  const dispatch = useDispatch();
  const shipMobileRef = useRef(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const ship_to = useSelector((state) => state.claim.documentDetail.ship_to);
  const ship_mobile = useSelector(
    (state) => state.claim.documentDetail.ship_mobile
  );

  const setShipValue = (value) =>
    dispatch(
      setGlobalState({
        name: "documentDetail",
        value,
      })
    );

  const handleClickSearch = () => {
    setLoading(true);
    axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        query: ship_to?.trim(),
      },
      cancelToken: source.token,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            let data = res.data.data[0];
            const status = data.status?.toLowerCase();
            if (!_.includes(fixAcceptPartner.claim_partner, status)) {
              // permission = false
              Modal.warning({
                title: "ไม่สามารถดำเนินการได้",
                content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                okText: "ตกลง",
              });
              setShipValue({
                ship_to: null,
                ship_mobile: null,
              });
            } else {
              // permission true
              setShipValue({
                ship_to: `${data.first_name_th} ${data.sur_name_th}`,
                ship_mobile: data.mobile,
              });
              document.getElementById("ship_to_select_id").click();
              shipMobileRef.current.focus();
            }
          } else {
            setList(res.data.data);
            document.getElementById("ship_to_select_id").click();
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "topRight",
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        resCatch(e);
      });
  };

  return (
    <Row gutter={[8, 8]} type="flex" className="align-items-center">
      <Col span={6} className="text-right">
        <Text strong>ผู้รับ</Text>
      </Col>
      <Col span={18}>
        {disabled ? (
          <Text>{ship_to}</Text>
        ) : (
          <SelectSearch
            size="small"
            selectId="ship_to_select_id"
            placeholder="รหัสสมาชิก, เลขบัตรประชาชน, ชื่อ, อีเมล, เบอร์โทร"
            loadList={list}
            loading={loading}
            disabled={loading}
            value={ship_to ?? undefined}
            onClickSearch={() => ship_to && handleClickSearch()}
            onClickCancel={() => {
              source.cancel("cancel");
              setSource(axios.CancelToken.source());
            }}
            onChange={() => shipMobileRef.current.focus()}
            onInputKeyDown={(e) => {
              const key = e.key;
              if (key === "Enter" && list.length === 0 && ship_to) {
                handleClickSearch();
              } else if (key === "Backspace" || key === "Delete") {
                setList([]);
                dispatch(
                  setGlobalState({
                    name: "documentDetail",
                    value: {
                      ship_to: null,
                      ship_mobile: null,
                    },
                  })
                );
              }
            }}
            onSearch={(e) => {
              if (e) {
                dispatch(
                  setGlobalState({
                    name: "documentDetail",
                    value: {
                      ship_to: e.trim(),
                      ship_mobile: null,
                    },
                  })
                );
                setList([]);
              }
            }}
            onSelect={(i) => {
              const data = list[+i];
              const status = data.status?.toLowerCase();
              if (!_.includes(fixAcceptPartner.claim_partner, status)) {
                // permission = false
                Modal.warning({
                  title: "ไม่สามารถดำเนินการได้",
                  content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
                  okText: "ตกลง",
                });
                dispatch(
                  setGlobalState({
                    name: "documentDetail",
                    value: {
                      ship_to: null,
                      ship_mobile: null,
                    },
                  })
                );
              } else {
                // permission true
                dispatch(
                  setGlobalState({
                    name: "documentDetail",
                    value: {
                      ship_to: `${data.first_name_th} ${data.sur_name_th}`,
                      ship_mobile: data.mobile,
                    },
                  })
                );
              }
            }}
          />
        )}
      </Col>
      <Col span={6} className="text-right">
        <Text strong>เบอร์โทรผู้รับ</Text>
      </Col>
      <Col span={18}>
        {disabled ? (
          <Text>{ship_mobile}</Text>
        ) : (
          <Input
            ref={shipMobileRef}
            placeholder="เบอร์โทรผู้รับ"
            size="small"
            name="ship_mobile"
            autoComplete="off"
            maxLength={10}
            value={ship_mobile}
            onChange={(e) =>
              setShipValue({
                ship_mobile: e.target.value.replace(/[^\d]+/g, ""),
              })
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default memo(ReceiptBranch);
