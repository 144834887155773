/* eslint-disable eqeqeq */
import { Button, Col, Input, notification, Row, Table } from "antd";
import numeral from "numeral";
import React, { useEffect } from "react";
import { FaCogs, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import * as XLSX from "xlsx-js-style";
import { useApi } from "../../../../../hooks/http2";
import {
  actionStoreQrBalance,
  activeSelector,
  searchSelector
} from "../../../../../redux/slices/store-qr/balance";
import { getUser, moment, URL_API } from "../../../../../utils";
import SelectBranch from "../../components/SelectBranch";

const BalanceSet = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const active = useSelector(activeSelector);
  const search = useSelector(searchSelector);

  const { SET_SEARCH } = actionStoreQrBalance;

  const list = useApi(
    {
      url: `${URL_API}/store-qr/balance/${active}`,
      token: true,
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
      },
    },
    [search]
  );

  const dataExcel = useApi(
    {
      url: `${URL_API}/store-qr/balance/${active}`,
      token: true,
      params: {
        ...search,
        offset: 0,
        limit:0
      },
    },
    [search]
  );

  useEffect(() => {
    let user = getUser();
    dispatch(SET_SEARCH({query:"", branch: user?.branch_code, page: 1 }));
  }, [dispatch, SET_SEARCH, match.url,]);

  const list_branch = useApi(
    {
      baseURL: URL_API,
      url: `/boss/inventory/all-branch`
    }
  )
  const exportToExcel = () => {
    if (dataExcel.fetch.data.length != 0) {

      const select_branch = list_branch.fetch?.data.all_branch.find(e => e.code == search.branch)

      let excelData = [
        ["รายงาน", "สินค้าจัดเซ็ตคงเหลือ-ชุดเซ็ต(รายงานแสดงรายการชุดเซ็ตที่เป็น Active เท่านั้น ฉบับร่างจะไม่แสดง)", "","","วันที่ดึงข้อมูล", `${moment(new Date()).format("DD/MM/YYYY HH:mm")}`],
        [],
        [
          "แสดงเฉพาะรายการ:",`${search.query}`, "",
          "สาขา :",` ${search.branch == "" ? "ทั้งหมด" : select_branch.name}`,
        ],
        [],
        [{ v: "ลำดับ.", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "รหัสสินค้า", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "ชื่อชุดเซ็ต", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "จำนวนคงเหลือ (เซ็ต)", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "สาขา", t: "s", s: { alignment: { horizontal: "center" } } },
        ],
      ];

      dataExcel.fetch.data.forEach((n) => {
        excelData = [...excelData,
        [{ v: n.row_id, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.product_code, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.product_name, t: "s", s: { alignment: { horizontal: "left" } } },
        { v: n.balance, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.branch_name, t: "s", s: { alignment: { horizontal: "center" } } },
        ]];
      });

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
        { s: { r: 2, c: 1 }, e: { r: 2, c: 2 } },
      ];

      var wscols = [{ wch: 20 }, { wch: 15 }, { wch: 35 }, { wch: 15 }, { wch: 15 }];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ข้อมูลจัดเซ็ตคงเหลือ-ชุดเซ็ต");
      XLSX.writeFile(wb, `ข้อมูลจัดเซ็ตคงเหลือ-ชุดเซ็ต-.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <>
      <Row gutter={16} className="mb-3">
        <Col md={10}>
          <Input.Search
            placeholder="รหัสสินค้า, ชื่อสินค้า, Lot., QR Code หลัก, QR Code ย่อย"
            allowClear
            onSearch={(value) =>
              dispatch(SET_SEARCH({ query: value, page: 1 }))
            }
            defaultValue=""
          />
        </Col>
        <Col md={5}>
          <SelectBranch
            className="w-100"
            withAll
            allText="สาขาทั้งหมด"
            placeholder="สาขา"
            value={search.branch}
            onChange={(value) =>
              dispatch(SET_SEARCH({ branch: value, page: 1 }))
            }
          />
        </Col>

        <Col md={3} offset={6}>
          <Button
              type="primary"
              icon="file-excel"
              style={{width:"100%"}}
              onClick={() => exportToExcel()}
            >
              Export Excel
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        loading={list.loading}
        dataSource={list.fetch?.data.map((item, index) => ({ ...item, key: index + 1 })) || []}
        pagination={{
          size: "default",
          current: search.page,
          pageSize: search.limit,
          total: list.fetch?.total || 0,
          showLessItems: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
          onChange: (p) => dispatch(SET_SEARCH({ page: p })),
        }}
        rowKey="row_id"
      >
        <Table.Column title="No." dataIndex="row_id" align="right" />
        <Table.Column
          title="รหัสสินค้า"
          dataIndex="product_code"
          align="center"
        />
        <Table.Column title="ชื่อชุดเซ็ต" dataIndex="product_name" />
        <Table.Column
          title="จำนวนคงเหลือ (เซ็ต)"
          dataIndex="balance"
          align="right"
          render={(text) => numeral(text).format()}
        />
        <Table.Column title="สาขา" dataIndex="branch_name" align="center" />
        <Table.Column
          title={<FaCogs />}
          align="center"
          render={(text, record) => (
            <Button
              type="primary"
              size="small"
              shape="circle-outline"
              onClick={() =>
                history.push(
                  `${match.path}/${record.product_code}/${active}/${record.branch_code}`
                )
              }
            >
              <FaEye />
            </Button>
          )}
        />
      </Table>
    </>
  );
};

export default BalanceSet;
