import React from 'react'

const InputNumber = ({ style = "", ...props }) => {
    return (
        <input
            type="text"
            className="form-control form-control-sm text-center p-0 mx-2"
            style={{ width: '3ch', ...style }}
            {...props}
        />
    )
}

export default InputNumber;
