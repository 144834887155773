import React from "react";
import { Switch, Route } from "react-router-dom";
import Error404 from "../../Error404";

import AwardList from "./award/AwardList";
import Award from "./award/Award";
import RankList from "./rank/RankList";
import Rank from "./rank/Rank";

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/award`} exact component={AwardList} />
      <Route path={`${match.path}/award/:id`} exact component={Award} />
      <Route path={`${match.path}/rank`} exact component={RankList} />
      <Route path={`${match.path}/rank/:id`} exact component={Rank} />
     

      <Route component={Error404} />
    </Switch>
  );
};
