import { Empty, Skeleton } from "antd";
import React, { useContext } from "react";
import { FaUserAlt } from "react-icons/fa";
import CardInfo from "../CardInfo";
import InfoContext from "./reducer";

const ProfileCard = () => {
  const { state } = useContext(InfoContext);

  if (state.loading) return <Skeleton active />;

  if (state[`${state.memberType}Profile`]?.length === 0)
    return <Empty className="mb-5" />;

  return (
    <CardInfo
      data={state[`${state.memberType}Profile`]}
      icon={<FaUserAlt />}
      className="mb-5"
    />
  );
};

export default ProfileCard;
