import { Divider } from "antd";
import React, { useReducer } from "react";
import UserContext, {
  initState,
  reducer,
} from "../../../../../contexts/user.context";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import FilterList from "./components/FilterList";
import TableUser from "./components/TableUser";

const List = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายชื่อผู้ใช้งาน</PanelHeader>
        <PanelBody>
          <FilterList />
          <Divider />
          <TableUser />
        </PanelBody>
      </Panel>
    </UserContext.Provider>
  );
};

export default List;
