/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Input, Typography } from 'antdV4';
import { Row, Col, Switch, Checkbox, Button } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import ImageUpload from '../../../fit-studio/components/upload/ImageUpload';
import fitQuizServices from '../../../../../services/fit-quiz.services';
import { MdDelete } from 'react-icons/md';
import AnswerCard from './AnswerCard';

const LegacyQuizCategoryProductQuestionForm = () => {
  const {
    watch,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  });

  fitQuizServices.useQueryGetLegacyQuizCategoryProductQuestionById(
    { quizCategoryProductQuestionId: watch('id') },
    (data) => {
      reset({
        ...data,
        formType: 'edit',
      });
    }
  );

  return (
    <div>
      <Controller
        name="question"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Row className="align-items-center  mb-2">
              <Col span={4}>
                <Typography.Text>
                  คำถาม <span style={{ color: 'red' }}>*</span>
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  value={value}
                  onChange={onChange}
                  placeholder="คำถาม"
                  rows={3}
                />
              </Col>
              <ErrorMessage
                errors={errors}
                name="question"
                render={({ message }) => (
                  <Typography.Text className="mb-2 text-danger">
                    {message}
                  </Typography.Text>
                )}
              />
            </Row>
          );
        }}
      />
      <Controller
        name="imageUrl"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Row className="align-items-center mb-2">
              <Col span={4}>
                <Typography.Text>ภาพประกอบคำถาม</Typography.Text>
              </Col>
              <Col span={20}>
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  width={200}
                  height={200}
                  errors={errors}
                />
              </Col>
            </Row>
          );
        }}
      />
      {fields.map((item, index) => {
        return (
          <AnswerCard
            key={item.id}
            item={item}
            index={index}
            control={control}
            errors={errors}
            remove={remove}
            setValue={setValue}
            fields={fields}
          />
        );
      })}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            append({
              answer: '',
              iconImageUrl: '',
              isCorrect: false,
            });
          }}
        >
          เพิ่มคำตอบ
        </Button>
      </div>
    </div>
  );
};

export default LegacyQuizCategoryProductQuestionForm;
