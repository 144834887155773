import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const authscreenDefaultValues = {
  imageUrl: '',
};

export const authscreenSchema = () => {
  return yupResolver(
    yup.object().shape({
      imageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
    })
  );
};
