/* eslint-disable no-unused-vars */
import { Col, DatePicker, notification, Row, Table, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
// import XLSX from "xlsx";
import * as XLSX from "xlsx-js-style";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import SelectYear from "../../components/SelectYear";
import SelectMonth from "../../components/SelectMonth";
import SelectDayRange from "../../components/SelectDay";
import SelectDay from "../../components/SelectDay";
const { Text } = Typography;

const user = getUser();

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [memberTypeFilter, setMemberTypeFilter] = useState("Distributor");
  const [showDisplayFilter, setShowDisplayFilter] = useState("");
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("Active");
  const [teamFilter, setTeamFilter] = useState("Clover");

  const [year, setYear] = useState(moment().format("YYYY"));
  const [toYear, setToYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MMMM", "LLLL"));
  const [toMonth, setToMonth] = useState(moment().format("MMMM", "LLLL"));
  const [day, setDay] = useState("");
  const [toDay, setToDay] = useState("");
  let limit = 20;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-add-company`,
      token: true,
      params: {
        lim: limit * page,
        offset: limit * (page - 1),
        userId: user.id,
        partnerStatusFilter: partnerStatusFilter,
        teamFilter: teamFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-filter`,
      token: true,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);

  const partnerStatusOptions = [
    ..._.map(filterData?.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  const showDisplayOptions = [
    { value: "summary", text: "ข้อมูลรวมถึงปัจจุบัน" },
    { value: "yearly", text: "รูปแบบรายปี" },
    { value: "monthly", text: "รูปแบบรายเดือน" },
    { value: "daily", text: "รูปแบบรายวัน" },
  ];

  const exportToExcel = () => {
    // exportDataData.length !== 0
    if (true) {
      let excelData = [
        ["รายงานสถานะสมาชิก"],
        [
          `สาขา ${
            state.filter.branch === "" ? "ทั่งหมด" : state.filter.branch
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [
          "สถานะ",
        ],
      ];

      //   exportData.data.forEach((n) => {
      //     excelData = [
      //       ...excelData,
      //       [
      //         n.row_id,
      //         n.document_no,
      //         moment(n.document_date).format("DD/MM/YYYY"),
      //         moment(n.cancel_date).format("DD/MM/YYYY"),
      //         n.partner_code,
      //         n.partner_name,
      //         n.order_type,
      //         n.grand_total_amount,
      //         n.cashier_name,
      //         n.cancel_type,
      //         n.reason,
      //         n.req_receipt_type,
      //         n.request_by_name,
      //         n.cancel_by,
      //       ],
      //     ];
      //   });

      const merge = [
        { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 3 } },
        { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      const ws = XLSX.utils.json_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานสถานะสมาชิก");
      XLSX.writeFile(wb, "รายงานสถานะสมาชิก.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสถานะสมาชิก</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>รูปแบบการแสดงผล</Text>
            </Col>
            <Col span={6}>
              <SelectOption
                className="w-100"
                placeholder="เลือกรูปแบบการแสดงผล"
                options={showDisplayOptions}
                onChange={(e) => {
                  setShowDisplayFilter(e);
                }}
              />
            </Col>
            <Col md={12}>
              <FilterList
                showSearchBox={false}
                showBranch={false}
                showReportSpan={20}
                Topic={""}
                showDatePicker={false}
                reload={() => {
                  reload();
                }}
                exportToExcel={exportToExcel}
              />
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            {showDisplayFilter !== "" ? (
              <>
                <Col>
                  <Text strong>ประเภทสมาชิก</Text>
                </Col>
                <Col span={5}>
                  <SelectOption
                    className="w-100"
                    placeholder="เลือกประเภทสมาชิก"
                    // defaultValue={partnerStatusFilter}
                    // options={partnerStatusOptions}
                    onChange={(e) => {
                      //   setPartnerStatusFilter(e);
                    }}
                  />
                </Col>
              </>
            ) : (
              ""
            )}

            {showDisplayFilter === "yearly" ||
            showDisplayFilter === "monthly" ||
            showDisplayFilter === "daily" ? (
              <>
                <Col>
                  <Text strong>ปี</Text>
                </Col>
                <Col span={3}>
                  <SelectYear
                    className="w-100"
                    yearFrom={2014}
                    value={year}
                    onChange={(e) => {
                      setYear(e);
                    }}
                  />
                </Col>
                {showDisplayFilter === "yearly" ? (
                  <>
                    <Col>
                      <Text strong></Text>
                    </Col>
                    <Col span={3}>
                      <SelectYear
                        className="w-100"
                        yearFrom={2014}
                        value={toYear}
                        onChange={(e) => {
                          setToYear(e);
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {showDisplayFilter === "monthly" ||
            showDisplayFilter === "daily" ? (
              <>
                <Col>
                  <Text strong>เดือน</Text>
                </Col>
                <Col span={3}>
                  <SelectMonth
                    onChange={(e) => {
                      setMonth(moment(e).format("MMMM", "LLLL"));
                    }}
                  />
                </Col>
                {showDisplayFilter === "monthly" ? (
                  <>
                    <Col>
                      <Text strong>ถึง</Text>
                    </Col>
                    <Col span={3}>
                      <SelectMonth
                        onChange={(e) => {
                          setToMonth(e);
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {showDisplayFilter === "daily" ? (
              <>
                <Col>
                  <Text strong>ช่วงวัน</Text>
                </Col>
                <Col span={3}>
                  <SelectDay
                    placeholder="วันที่"
                    onChange={(e) => {
                      setDay(e);
                    }}
                  />
                </Col>
                <Col>
                  <Text strong>ถึง</Text>
                </Col>
                <Col span={3}>
                  <SelectDay
                    placeholder="ถึง"
                    onChange={(e) => {
                      setToDay(e);
                    }}
                  />
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="สถานะ" dataIndex="status" align="center" />

            {showDisplayFilter === "summary" ? (
              <Table.Column
                title="รวมทั้งองค์กร"
                dataIndex="summary"
                align="center"
              />
            ) : (
              ""
            )}

            {showDisplayFilter === "yearly" ? (
              <Table.ColumnGroup title={`ข้อมูลปี ${year} ถึง ${toYear}`}>
                <Table.Column
                  title="2017"
                  dataIndex="year_2017"
                  align="center"
                />
                <Table.Column
                  title="2018"
                  dataIndex="year_2018"
                  align="center"
                />
                <Table.Column
                  title="2019"
                  dataIndex="year_2019"
                  align="center"
                />
                <Table.Column
                  title="2020"
                  dataIndex="year_2020"
                  align="center"
                />
                <Table.Column
                  title="2021"
                  dataIndex="year_2021"
                  align="center"
                />
                <Table.Column
                  title="2022"
                  dataIndex="year_2022"
                  align="center"
                />
              </Table.ColumnGroup>
            ) : (
              ""
            )}

            {showDisplayFilter === "monthly" ? (
              <Table.ColumnGroup title={`ข้อมูลประจำปี ${year}`}>
                <Table.Column
                  title="มกราคม"
                  dataIndex="month_01"
                  align="center"
                />
                <Table.Column
                  title="กุมภาพันธ์"
                  dataIndex="month_02"
                  align="center"
                />
                <Table.Column
                  title="มีนาคม"
                  dataIndex="month_03"
                  align="center"
                />
                <Table.Column
                  title="เมษายน"
                  dataIndex="month_04"
                  align="center"
                />
                <Table.Column
                  title="พฤษภาคม"
                  dataIndex="month_05"
                  align="center"
                />
                <Table.Column
                  title="มิถุนายน"
                  dataIndex="month_06"
                  align="center"
                />

                <Table.Column
                  title="กรกฎาคม"
                  dataIndex="month_07"
                  align="center"
                />
                <Table.Column
                  title="สิงหาคม"
                  dataIndex="month_08"
                  align="center"
                />
                <Table.Column
                  title="กันยายน"
                  dataIndex="month_09"
                  align="center"
                />
                <Table.Column
                  title="ตุลาคม"
                  dataIndex="month_10"
                  align="center"
                />
                <Table.Column
                  title="พฤศจิกายน"
                  dataIndex="month_11"
                  align="center"
                />
                <Table.Column
                  title="ธันวาคม"
                  dataIndex="month_12"
                  align="center"
                />
              </Table.ColumnGroup>
            ) : (
              ""
            )}

            {showDisplayFilter === "daily" ? (
              <Table.ColumnGroup title={`ข้อมูลเดือน ${month} ปี ${year}`}>
                <Table.Column title="1" dataIndex="day_01" align="center" />
                <Table.Column title="2" dataIndex="day_02" align="center" />
                <Table.Column title="3" dataIndex="day_03" align="center" />
                <Table.Column title="4" dataIndex="day_04" align="center" />
                <Table.Column title="5" dataIndex="day_05" align="center" />
                <Table.Column title="6" dataIndex="day_06" align="center" />
                <Table.Column title="7" dataIndex="day_07" align="center" />
                <Table.Column title="8" dataIndex="day_08" align="center" />
                <Table.Column title="9" dataIndex="day_09" align="center" />
                <Table.Column title="10" dataIndex="day_10" align="center" />
                <Table.Column title="11" dataIndex="day_11" align="center" />
                <Table.Column title="12" dataIndex="day_12" align="center" />
                <Table.Column title="13" dataIndex="day_13" align="center" />
                <Table.Column title="14" dataIndex="day_14" align="center" />
                <Table.Column title="15" dataIndex="day_15" align="center" />
                <Table.Column title="16" dataIndex="day_16" align="center" />
                <Table.Column title="17" dataIndex="day_17" align="center" />
                <Table.Column title="18" dataIndex="day_18" align="center" />
                <Table.Column title="19" dataIndex="day_19" align="center" />
                <Table.Column title="20" dataIndex="day_20" align="center" />
                <Table.Column title="21" dataIndex="day_21" align="center" />
                <Table.Column title="22" dataIndex="day_22" align="center" />
                <Table.Column title="13" dataIndex="day_23" align="center" />
                <Table.Column title="24" dataIndex="day_24" align="center" />
                <Table.Column title="25" dataIndex="day_25" align="center" />
                <Table.Column title="26" dataIndex="day_26" align="center" />
                <Table.Column title="27" dataIndex="day_27" align="center" />
                <Table.Column title="28" dataIndex="day_28" align="center" />
                <Table.Column title="29" dataIndex="day_29" align="center" />
                <Table.Column title="30" dataIndex="day_30" align="center" />
                <Table.Column title="31" dataIndex="day_31" align="center" />
              </Table.ColumnGroup>
            ) : (
              ""
            )}
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
