import React, { useEffect, useState } from "react";
// import { Select } from "antd";
import { useHttp } from "../hooks/http";
import { URL_API } from "../utils";
import _ from "lodash";
import SelectOption from "../views/pages/pos/components/SelectOption";
import { useApi } from "../hooks/http2";

const SelectSource = ({ className = "", withAll, ...props }) => {
  const [options, setOptions] = useState([]);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/source`,
    },
    []
  );

  const branch = useApi(
    {
      url: `${URL_API}/pos/master/branch`,
      token: true,
    },
    []
  );
  const getBranch = branch?.fetch?.data;

  useEffect(() => {
    let newData = [];
    if (data && data?.data?.length > 0) {
      let dataFilter = _.cloneDeep(data.data);
      let branchFilter = _.cloneDeep(getBranch);
      if (withAll) {
        newData = [{ value: "", text: "ทั้งหมด" }];
      }
      newData = [
        ...newData,
        ..._.map(branchFilter, (n) => ({ value: n.code, text: n.name })),
        ..._.map(dataFilter, (n) => ({ value: n.code, text: n.name })),
      ];
    }
    setOptions(newData);
  }, [data, withAll, getBranch]);

  if (error) return <div>{error}</div>;

  return (
    <SelectOption
      loading={loading}
      options={options}
      className={`w-100 ${className}`}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ zIndex: 1080 }}
      {...props}
    />
    // <Select
    //   loading={loading}
    //   className={`w-100 ${className}`}
    //   dropdownMatchSelectWidth={false}
    //   dropdownStyle={{ zIndex: 1080 }}
    //   {...props}
    // >
    //   {data && data.data
    //     ? data.data.map((n, i) => (
    //         <Select.Option key={i} value={n.code}>
    //           {n.name}
    //         </Select.Option>
    //       ))
    //     : null}
    // </Select>
  );
};

export default SelectSource;
