import axios from 'axios';

const timeout = 1000 * 120;

const fitStudioRequest = axios.create({
  baseURL: process.env.REACT_APP_FIT_STUDIO_URL_API || 'http://localhost:5001',
  // baseURL: 'http://localhost:5001',
  // baseURL: 'https://fitstudio-api.legacy.co.th',
  timeout,
});

export default fitStudioRequest;
