import { DatePicker, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import { moment } from "../../../../../utils";
import Layout from "./Layout";

const { setFormValue } = actionRequisition;

const RequisitionDate = ({ disabled }) => {
  const dispatch = useDispatch();
  const requisition_date = useSelector(
    (state) => state.requisition.documentDetail.requisition_date
  );
  const requisition_date_error = useSelector(
    (state) => state.requisition.error.requisition_date
  );

  return (
    <Layout title="วันที่ต้องการสินค้า :" error={requisition_date_error}>
      {disabled ? (
        <Typography.Text>
          {moment(requisition_date).format("DD/MM/YYYY")}
        </Typography.Text>
      ) : (
        <DatePicker
          format="DD/MM/YYYY"
          className="w-100"
          placeholder="วันที่ต้องการสินค้า"
          value={requisition_date && moment(requisition_date)}
          onChange={(requisition_date) =>
            dispatch(
              setFormValue({
                name: "documentDetail",
                value: {
                  requisition_date,
                },
                error_name: "requisition_date",
              })
            )
          }
        />
      )}
    </Layout>
  );
};

export default memo(RequisitionDate);
