/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row, Steps, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { _ } from "../../../../../utils";
import { getDataCheckStock } from "../../ducks/logic/data-check-stock";
import { Creators } from "../../ducks/operation-form";
import ModalControl from "../ModalControl";
import PosInputNumber from "../PosInputNumber";
import CardCondition from "./CardCondition";
import CardPromotion from "./CardPromotion";
import { calculateQuantity } from "./logic";
import PromotionDetailModal from "./PromotionDetailModal";
import { splitItemBannedSoldOut } from "./logic/data-check-stock";
import PromotionDisableItemPopup from "../PromotionDisableItemPopup";

const { Step } = Steps;
const { Title } = Typography;
const stepStyle = {
  boxShadow: "0px -1px 0 0 #e8e8e8 inset",
};

const initPromotion = { source: [], free: [], current: 0, condition: {} };

const initProDetail = {
  pro_id: 0,
  name: null,
  description: null,
  azure_url: null,
};

const initDisabledItem = {
  banned: [],
  sold_out: [],
};

const Index = ({
  onCancel,
  dataSource: { visible = false, data = [], title = "" },
  match,
  ...props
}) => {
  const [promotionDetail, setPromotionDetail] = useState(initProDetail);
  const [promotion, setPromotion] = useState(initPromotion);
  const [maxQty, setMaxQty] = useState(0);
  const [qty, setQty] = useState(1);
  const [freeDisableItem, setFreeDisableItem] = useState(initDisabledItem);
  const { current, id, source, source_index, free_index } = promotion;
  let { free } = promotion;

  // comment for test promotion only
  useEffect(() => {
    if (maxQty) setQty(maxQty);
  }, [maxQty]);

  useEffect(() => {
    const type = promotion.condition.type;
    if (type) {
      const res_max_qty = calculateQuantity({
        type,
        source: _.cloneDeep(source),
      });
      if (promotion.condition.order_limit !== 1) {
        setMaxQty(res_max_qty);
      } else {
        const order_limit_qty = promotion.condition.order_limit_qty;
        if (res_max_qty <= order_limit_qty) setMaxQty(res_max_qty);
        else setMaxQty(order_limit_qty);
      }
    }
  }, [
    source,
    promotion.condition.type,
    promotion.condition.order_limit,
    promotion.condition.order_limit_qty,
  ]);

  useEffect(() => {
    if (data && id) {
      setPromotion((prevState) => {
        let currState = _.cloneDeep(prevState);
        currState.condition.source = _.find(
          data,
          (pro) => pro.id === id
        ).condition.source;

        return currState;
      });
    }
  }, [data]);

  const handleChangeStep = (value) => {
    let pass = true;
    switch (value) {
      case 1:
        pass = !!id;
        break;
      case 2:
        pass = !!source.length;
        break;
      case 3:
        pass = !!free.length;
        break;
      default:
        break;
    }
    pass && setPromotion((prevState) => ({ ...prevState, current: value }));
  };

  const handleSelectPromotion = useCallback(
    (value) => {
      setPromotion((prevState) => ({
        ..._.omit(prevState, ["source_index", "free_index"]),
        ...data[value],
        source: [],
        free: [],
        current: 1,
      }));
    },
    [data]
  );

  const handleSelectCondition = useCallback((value, list_index) => {
    setPromotion((prevState) => ({
      ..._.omit(prevState, ["free_index"]),
      source_index: list_index,
      source: value,
      current: 2,
    }));
  }, []);

  const handleSelectFree = useCallback((value, list_index) => {
    setPromotion((prevState) => ({
      ...prevState,
      free_index: list_index,
      free: value,
      current: 3,
    }));
  }, []);

  const handleClose = () => {
    setPromotion(initPromotion);
    onCancel();
  };

  const handleClickPlus = () => setQty((prevQty) => ++prevQty);
  const handleClickMinus = () => setQty((prevQty) => --prevQty);
  const handleChangeQty = (e) => {
    let value = +e.target.value;
    if (value && value >= 1 && value <= maxQty) {
      setQty(value);
    }
  };

  const onSelectFree = () => {
    const is_coordinate = !_.find(free, (data) => data.coordinate === 0);
    const is_collect_point = !_.find(free, (data) => data.collect_point === 0);
    if (is_coordinate) {
      !is_collect_point && props.setBillValue({ collect_point: false });
      handleAddPromotion();
    } else {
      Modal.confirm({
        title: "ยืนยันการทำรายการ",
        content: "โปรโมชันที่เลือกไม่สามารถใช้งานร่วมกับโปรโมชันอื่นได้",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk() {
          props.delOrderPromotionAll();
          !is_collect_point && props.setBillValue({ collect_point: false });
          handleAddPromotion();
        },
      });
    }
  };

  const handleAddPromotion = async () => {
    const condition_source_option = source[0].source_option;
    const free_source_option = free[0].source_option;
    let max_price = 0;

    // calculate max price of condition source
    if (_.some(free, (n) => _.includes(["price", "percent"], n.source_key))) {
      let order_item = _.cloneDeep(props.order_item);
      for (let source_index = 0; source_index < source.length; source_index++) {
        const {
          source_key,
          // , qty
        } = source[source_index];

        if (source_key === "item") {
          const { product_code } = source[source_index];
          const order_item_index = _.findIndex(
            order_item,
            (ele) => ele.product_code === product_code
          );

          max_price +=
            order_item[order_item_index].unit_price *
            order_item[order_item_index].qty; // * qty;
          order_item.splice(order_item_index, 1);
        } else if (source_key === "category") {
          const { items } = source[source_index];
          for (let item_index = 0; item_index < items.length; item_index++) {
            if (items[item_index].qty > 0) {
              const order_item_index = _.findIndex(
                order_item,
                (ele) => ele.product_code === items[item_index].product_code
              );
              max_price +=
                order_item[order_item_index].unit_price *
                order_item[order_item_index].qty; // * qty;
              order_item.splice(order_item_index, 1);
            }
          }
        } else if (source_key === "price") {
          max_price += source[source_index].qty;
        }
      }
    }

    // map free source
    let max_price_temp = max_price;
    let free_value = _.reduce(
      free,
      (res, data) => {
        let price = 0;
        if (_.includes(["price", "percent"], data.source_key)) {
          if (max_price_temp > 0) {
            const value =
              data.source_key === "price"
                ? data.qty
                : (data.qty / 100) * max_price_temp;
            if (max_price_temp - value >= 0) {
              price = value;
              max_price_temp -= value;
            } else {
              price = max_price_temp;
              max_price_temp = 0;
            }
          }
        }
        return [
          ...res,
          data.source_key === "item"
            ? {
                product_id: data.product_id,
                product_code: data.product_code,
                product_name: data.product_name_local,
                product_type: data.product_type,
                product_type_set: data.product_type_set,
                bom: data.bom || [],
                sku: data.backend_sku_code,
                weight: data.weight,
                promotion_id: data.promotion_id,
                qty: data.qty,
                unit_price: data.unit_price,
                cv: data.point_03,
                pv: data.point_01,
                rp: data.point_02,
                tp: data.point_04,
                sp: data.point_05,
                source_key: data.source_key,
                qty_rate: data.point_01_rate,
                stock_balance: data.stock_balance,
                promotion_item_type: "item",
              }
            : data.source_key === "voucher"
            ? {
                product_code: data.source_key,
                product_name: `${promotion.name} : ${data.name} `, //`${promotion.name} : บัตรกำนัล${data.name} `
                promotion_id: data.promotion_id,
                qty: 1,
                id: data.id,
                code: data.code,
                name: data.name,
                type: data.type,
                value: data.value,
                keep: data.keep,
                source_key: data.source_key,
                coordinate: data.coordinate,
                collect_point: data.collect_point,
                promotion_item_type: "voucher",
              }
            : data.source_key === "price"
            ? {
                product_code: data.source_key,
                product_name: `${promotion.name} : ส่วนลดจำนวน ${data.qty} บาท`,
                promotion_id: data.promotion_id,
                qty: 1,
                price,
                source_key: data.source_key,
                promotion_item_type: "amount",
              }
            : data.source_key === "percent"
            ? {
                product_code: data.source_key,
                product_name: `${promotion.name} : ส่วนลดจำนวน ${data.qty} เปอร์เซ็นต์`,
                promotion_id: data.promotion_id,
                qty: 1,
                price,
                source_key: data.source_key,
                promotion_item_type: "amount",
              }
            : {
                product_code: data.source_key,
                product_name: `${
                  promotion.name
                } : คะแนน ${data.source_key.toUpperCase()} จำนวน ${
                  data.qty
                } คะแนน`,
                promotion_id: data.promotion_id,
                qty: 1,
                [data.source_key]: +data.qty,
                source_key: data.source_key,
                promotion_item_type: "amount",
              },
        ];
      },
      []
    );

    // add promotion
    props.addOrderPromotion({
      promotion_type: promotion.type,
      order_key: promotion.order_key,
      condition_type: promotion.condition.type,
      condition_source_option,
      free_source_option,
      source: promotion.source,
      free: free_value,
      add_qty: qty,
      order_limit: promotion.condition.order_limit,
    });

    // reset state
    setPromotion(initPromotion);
    setQty(1);
    onCancel();
  };

  const handleClosePreviewPromotionDetail = useCallback(
    () => setPromotionDetail(initProDetail),
    []
  );

  const handleViewDisableItem = useCallback(
    (value) => {
      const {
        condition: { free },
      } = data[value];
      setFreeDisableItem(splitItemBannedSoldOut(free));
    },
    [data]
  );

  const handleCloseDisableItem = useCallback(
    () => setFreeDisableItem(initDisabledItem),
    []
  );

  return (
    <ModalControl
      visible={visible}
      onCancel={handleClose}
      footerLine={false}
      width={900}
    >
      <Title level={3} className="text-center mb-3">
        โปรโมชัน{title}
      </Title>
      <Row>
        <Col span={24}>
          <Steps
            style={stepStyle}
            type="navigation"
            size="small"
            current={current}
            onChange={handleChangeStep}
          >
            <Step
              status={current === 0 ? "process" : !!id ? "finish" : "wait"}
              title="เลือกโปรโมชัน"
            />
            <Step
              status={
                current === 1 ? "process" : !!source.length ? "finish" : "wait"
              }
              title="เลือกเงื่อนไข"
            />
            <Step
              status={
                current === 2 ? "process" : !!free.length ? "finish" : "wait"
              }
              title="เลือกสิ่งที่ได้รับ"
            />
            <Step
              status={current === 3 ? "process" : "wait"}
              title="เลือกจำนวน"
            />
          </Steps>
        </Col>
      </Row>
      <Row gutter={[32, 32]} className="height-600 overflow-y-scroll">
        {promotion.current === 0 ? (
          <>
            {_.map(data, (pro, pro_index) => {
              const isBanned =
                _.some(pro.condition.source, (source) =>
                  _.some(source, (n) => n.banned)
                ) ||
                _.some(pro.condition.free, (source) =>
                  _.some(source, (n) => n.banned)
                );
              const isSoldOut = isBanned
                ? false
                : _.some(pro.condition.source, (source) =>
                    _.some(source, (n) => n.sold_out)
                  ) ||
                  _.some(pro.condition.free, (source) =>
                    _.some(source, (n) => n.sold_out)
                  );
              return (
                <Col span={8} key={`pro-card-${pro_index}`}>
                  <CardPromotion
                    isBanned={isBanned}
                    isSoldOut={isSoldOut}
                    proIndex={pro_index}
                    active={pro.id === promotion.id}
                    proId={pro.id}
                    azureUrl={pro.azure_url}
                    name={pro.name}
                    description={pro.description}
                    onSelect={handleSelectPromotion}
                    onPreview={setPromotionDetail}
                    onViewDisableItem={handleViewDisableItem}
                  />
                </Col>
              );
            })}
            <PromotionDetailModal
              dataSource={promotionDetail}
              onCancel={handleClosePreviewPromotionDetail}
            />
          </>
        ) : promotion.current === 1 ? (
          <>
            {_.map(promotion.condition.source, (list, list_index) => {
              return (
                <Col span={24} key={`con-card-${list_index}`}>
                  <CardCondition
                    allItems={props.all_items}
                    active={source_index === list_index}
                    list={list}
                    listIndex={list_index}
                    azureUrl={promotion.source_media[list_index]}
                    conditionType={promotion.condition.type}
                    onSelect={handleSelectCondition}
                  />
                </Col>
              );
            })}
          </>
        ) : promotion.current === 2 ? (
          <>
            {_.map(promotion.condition.free, (list, list_index) => (
              <Col span={24} key={`free-card-${list_index}`}>
                <CardCondition
                  active={free_index === list_index}
                  list={list}
                  listIndex={list_index}
                  azureUrl={promotion.free_media[list_index]}
                  onSelect={handleSelectFree}
                />
              </Col>
            ))}
          </>
        ) : (
          <>
            <Col span={24} className="d-flex flex-column">
              <Typography.Title level={4}>เลือกจำนวน</Typography.Title>
              <div className="w-100 d-flex align-items-center justify-content-center mb-3">
                <PosInputNumber
                  value={qty}
                  disabledPlus={qty === maxQty}
                  disabledMinus={qty === 1}
                  handleChangeQty={handleChangeQty}
                  handleClickPlus={handleClickPlus}
                  handleClickMinus={handleClickMinus}
                />
                <Typography.Text className="mx-3">/ {maxQty}</Typography.Text>
                <Button type="dashed" onClick={onSelectFree}>
                  เลือก
                </Button>
              </div>
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>เงื่อนไข</Typography.Title>
              <CardCondition
                allItems={props.all_items}
                disabledAddItem
                list={source}
                conditionType={promotion.condition.type}
                azureUrl={
                  promotion.source_media[
                    _.indexOf(promotion.condition.source, source)
                  ]
                }
                // azureUrl={promotion.source_media[source[0].source_option]}
              />
            </Col>
            <Col span={24} className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <Typography.Title level={4}>
                  สิ่งที่ได้รับ
                  <Typography.Text className="text-reds ml-3" strong>{`${
                    promotion.type === "giveaway" ? "แถมฟรี" : "ราคาพิเศษ"
                  }`}</Typography.Text>
                </Typography.Title>
              </div>
              <CardCondition
                list={free}
                azureUrl={promotion.free_media[free[0].source_option]}
              />
            </Col>
          </>
        )}
      </Row>
      <PromotionDisableItemPopup
        freeDisableItem={freeDisableItem}
        sourceDisableItem={initDisabledItem}
        onCancel={handleCloseDisableItem}
      />
    </ModalControl>
  );
};

const mapStateToProps = (state) => {
  const {
    orders: { activeKey, itemByKey },
  } = state.pos.operation;
  const { all_items } = getDataCheckStock(state.pos.operation.orders);
  return {
    all_items,
    order_item: _.reduce(
      itemByKey[activeKey]?.items.itemKeys,
      (res, order_key) => [
        ...res,
        itemByKey[activeKey].items.itemByKey[order_key],
      ],
      []
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setBillValue: Creators.setBillValue,
    addOrderPromotion: Creators.addOrderPromotion,
    delOrderPromotionAll: Creators.delOrderPromotionAll,
  })(Index)
);
