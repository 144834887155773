import { Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectMasterType from "../../../../../components/SelectMasterType";
import { actionRequisition } from "../../../../../redux/slices/requisition";
import Layout from "./Layout";

const { setFormValue } = actionRequisition;

const RequisitionType = ({ disabled }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => {
    const { requisition_type, requisition_type_name } =
      state.requisition.documentDetail;
    return disabled ? requisition_type_name : requisition_type;
  });
  const requisition_type_error = useSelector(
    (state) => state.requisition.error.requisition_type
  );

  return (
    <Layout title="ประเภทการเบิก :" error={requisition_type_error}>
      {disabled ? (
        <Typography.Text>{value}</Typography.Text>
      ) : (
        <SelectMasterType
          processType="requisition_type"
          placeholder="เลือกประเภทการเบิก"
          value={value ?? undefined}
          dropdownMatchSelectWidth={false}
          onChange={(requisition_type, option) =>
            dispatch(
              setFormValue({
                name: "documentDetail",
                value: {
                  requisition_type,
                  requisition_type_name: option.props.children,
                },
                error_name: "requisition_type",
              })
            )
          }
        />
      )}
    </Layout>
  );
};

export default memo(RequisitionType);
