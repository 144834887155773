import React from "react";
import { Alert, Select } from "antd";
import { useHttp } from "../../../../../hooks/http";
import { URL_API, _ } from "../../../../../utils";

const SelectReason = ({ className, withAll, notIn, value, ...props }) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/transfer-receive/master`,
      params: {
        process_type: "transfer-reason",
      },
      token: true,
    },
    []
  );

  if (data && notIn) {
    _.remove(data.data, (n) => _.some(notIn, (m) => m === n.code));
  }

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select
      loading={loading}
      className={`w-100 ${className || ""}`}
      value={loading ? undefined : value}
      {...props}
    >
      {withAll && <Select.Option value="">ทั้งหมด</Select.Option>}
      {data &&
        _.map(data.data, (n) => (
          <Select.Option key={n.code} value={n.code}>
            {n.name}
          </Select.Option>
        ))}
    </Select>
  );
};

export default SelectReason;
