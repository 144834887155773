/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import styles from "../../scss/module/upload.module.scss";
import { _ } from "../../utils";
import BlockItem from "./BlockItem";
import ImagePreviewModal from "../ImagePreviewModal";
import UploadBtn from "./UploadBtn";
import { Typography } from "antd";
import { AiOutlineUpload } from "react-icons/ai";

const initialPreview = {
  azure_url: null,
  file_type: null,
};

const Index = ({
  files = {},
  limit = null,
  limitFileSize = null,
  acceptImage = true,
  acceptPDF = false,
  acceptVideo = false,
  withCamera = false,
  withDefault = false,
  disabled = false,
  hideOnDisable = false,
  size = "default",
  onChange = () => null,
  onDelete = () => null,
  onChangeDefault = () => null,
  containerName = "operations",
  showUpload = true,
  limitWidth = null,
  limitHeight = null,
}) => {
  const { itemKeys = [], itemByKey = {} } = files;
  const [previewData, setPreviewData] = useState(initialPreview);
  const sizeStyle =
    styles[`upload-file-list${size === "small" ? "-small" : ""}`];

  const handleCancel = useCallback(() => setPreviewData(initialPreview), []);
  const handlePreview = useCallback((e) => setPreviewData(e), []);

  return (
    <div className={sizeStyle}>
      {showUpload &&
        (disabled && hideOnDisable ? null : disabled ||
          limit === itemKeys.length ? (
          <div className={`${styles.block} ${styles.btn} ${styles.disabled}`}>
            <AiOutlineUpload className={styles["icon-in"]} />
            {size !== "small" && (
              <Typography.Text className="mt-2">อัปโหลด</Typography.Text>
            )}
          </div>
        ) : (
          <UploadBtn
            size={size}
            limitHeight={limitHeight}
            limitWidth={limitWidth}
            limitFileSize={limitFileSize}
            withCamera={withCamera}
            acceptImage={acceptImage}
            acceptPDF={acceptPDF}
            acceptVideo={acceptVideo}
            containerName={containerName}
            onChange={onChange}
          />
        ))}
      {_.map(itemKeys, (key) => (
        <BlockItem
          key={key}
          itemKey={key}
          size={size}
          dataSource={itemByKey[key]}
          disabled={disabled}
          onPreview={handlePreview}
          onDelete={onDelete}
          withDefault={withDefault}
          onChangeDefault={onChangeDefault}
        />
      ))}
      <ImagePreviewModal
        azureUrl={previewData.azure_url}
        fileType={previewData.file_type}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default React.memo(Index);
