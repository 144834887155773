/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { _, moment } from "../../utils";

const initState = {
  search: {
    query: "",
    limit: 20,
    page: 1,
    active_tab: "draft",
    distribution_type: "",
    product_type_set: "",
    start_date: null,
    end_date: null,
    department_id: "",
  },
  form: {
    id: 0,
    department_id: "",
    division_id: "",
    product_code: "",
    backend_sku_code: "",
    product_name_local: "",
    product_name_en: "",
    product_title_local: "",
    product_title_en: "",
    product_tagline_local: "",
    product_tagline_en: "",
    product_manual_local: "",
    product_manual_en: "",
    product_description_local: "",
    product_description_en: "",
    product_type: "",
    product_group: "",
    product_type_set: "",
    product_class: "",
    logistic_type: "",
    size_length: 0,
    size_width: 0,
    size_height: 0,
    size_weight: "",
    weight: "",
    color: "",
    taste: "",
    smell: "",
    temperature: "",
    category: "",
    item_reborn: 0,
    retail_price: 0,
    member_price: 0,
    distributor_price: 0,
    staff_price: 0,
    member_redeem: 0,
    distributor_redeem: 0,
    member_sale: 0,
    distributor_sale: 0,
    staff_sale: 0,
    distribution: 0,
    quantity: 0,
    unit: "",
    fda: "",
    barcode_type: "",
    bar_code: "",
    point_01: 0,
    point_02: 0,
    point_03: 0,
    point_04: 0,
    point_05: 0,
    point_06: 0,
    point_07: 0,
    point_08: 0,
    point_09: 0,
    point_10: 0,
    point_11: 0,
    r_point_01: 0,
    r_point_02: 0,
    r_point_03: 0,
    r_point_04: 0,
    r_point_05: 0,
    r_point_06: 0,
    r_point_07: 0,
    r_point_08: 0,
    r_point_09: 0,
    r_point_10: 0,
    r_point_11: 0,
    d_point_01: 0,
    d_point_02: 0,
    d_point_03: 0,
    d_point_04: 0,
    d_point_05: 0,
    d_point_06: 0,
    d_point_07: 0,
    d_point_08: 0,
    d_point_09: 0,
    d_point_10: 0,
    d_point_11: 0,
    main_dealer_code: "",
    point_01_qty: 0,
    point_02_qty: 0,
    point_03_qty: 0,
    point_04_qty: 0,
    point_05_qty: 0,
    point_06_qty: 0,
    point_07_qty: 0,
    point_08_qty: 0,
    point_09_qty: 0,
    point_10_qty: 0,
    point_01_rate: 0,
    point_02_rate: 0,
    include_vat: 0,
    start_date: null,
    end_date: null,
    status: "",
    vat: 0,
    distribution_type: "",
    set_type: "",
    is_pre_order: 0,
    sale_description: null,
    tax_schedule: "S07-P07", // 7%
    unit_type: null,
    sale_unit: null,
    department: null,
    sub_category: "",
    groups: null,
    for_redeem: null,
    use_interface: null,
    size: "",
    delivery_type: "",
    point_time_01: null,
    point_time_02: null,
    point_time_03: null,
    point_time_04: null,
    point_time_05: null,
    point_time_06: null,
    r_point_time_01: null,
    r_point_time_02: null,
    r_point_time_03: null,
    r_point_time_04: null,
    r_point_time_05: null,
    r_point_time_06: null,
    redeem_point_time: null,
    split_pv: 0,
    shelef_life: null,
    shipping_rate: 0,
    remark: "",
    old_code: null,
    claim_price: 0,
    special_price: null,
    member_redeem_point: 0,
    distributor_redeem_point: 0,
    member_redeem_price: 0,
    distributor_redeem_price: 0,
    control_price: 0,
    product_chest: "",
    product_shoulder: "",
    product_waist: "",
    product_hip: "",
    product_width: "",
    product_height: "",
    product_length_1: "",
    product_length_2: "",
    product_depth: "",
    excel_name: null,
    flow_status: null,
    halal: "",
    volume: "",
    material: "",
    cost_price: 0,
    cost_price_inc_vat: 0,
    brand: "",
    selling_age: 0,
    unit_per_package: 0,
    package_width: 0,
    package_length: 0,
    package_height: 0,
    package_wieght: 0,
    overlay: 0,
    ingredient: "",
    caution: "",
    storage: "",
    aging: "",
    product_series: "",
    product_year: "",
    link: "",
    warranty: "",
    product_shape: "",
    packaging: "",
    event_date: [],
    event_start: null,
    event_end: null,
    energy: "",
    fat: "",
    sugar: "",
    sodium: "",
    show_approve: 0,
    show_cancel: 0,
    show_save: 0,
    oracle_category: "",
    oracle_sub_category: "",
    oracle_group: "",
    condition_type: "",
    customer_price: 0,
    clone_from: "",
    clone_from_id: "",
    vat_rate: 0,
    tax_rate: 0,
  },
  list: {
    selectedRowKeys: [],
  },
  media: {
    delete: [],
    itemKeys: [],
    itemByKey: {},
  },
  bom: {
    visible: false,
    itemKeys: [],
    itemByKey: {},
  },
  history: [],
  modal: {
    visible: false,
  },
};

const st = {
  file: null,
  size: null,
  name: null,
};

let state = {
  itemKeys: ["media-0", "mdeia-1"],
  itemByKey: {
    "media-0": { file: "xxxxx", size: 200, name: "Name1" },
    "media-1": { file: "xxxxx", size: 200, name: "Name1" },
  },
};

state.itemKeys.forEach((n) => state.itemByKey[n]);

const productRequestSlice = createSlice({
  name: "product-request",
  initialState: initState,
  reducers: {
    SET_SEARCH: (state, { payload }) => {
      state.search = { ...state.search, ...payload };
    },
    CLEAR_FORM: (state) => {
      state.form = initState.form;
      state.media = initState.media;
      state.bom = initState.bom;
    },
    LOAD_FORM: (state, { payload }) => {
      const { media, media_id, bom, history = [], ...data } = payload;

      const description_content_bock = data.product_description_local
        ? htmlToDraft(data.product_description_local)
        : null;

      const event_date =
        data.event_date != null && data.event_date !== ""
          ? data.event_date.split(",")
          : [];

      state.form = {
        ...initState.form,
        ...data,
        product_description_local: description_content_bock
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(
                description_content_bock.contentBlocks
              )
            )
          : null,
        event_start: event_date[0] ? moment(event_date[0]) : null,
        event_end: event_date[1] ? moment(event_date[1]) : null,
        // event_date:
        //   data.event_date != null && data.event_date !== ""
        //     ? _.map(data.event_date.split(","), (n) => moment(n))
        //     : [],
      };

      let mediaItemKeys = [];
      let mediaItemByKey = {};
      _.forEach(media, (n) => {
        const media_key = _.uniqueId(`media-`);
        mediaItemKeys = [...mediaItemKeys, media_key];
        mediaItemByKey = {
          ...mediaItemByKey,
          [media_key]: {
            ...n,
            is_default: n.is_default, //media_id,
            media_key,
          },
        };
      });
      state.media = {
        ...state.media,
        itemKeys: mediaItemKeys,
        itemByKey: mediaItemByKey,
      };

      let bomItemKeys = [];
      let bomItemByKey = {};
      _.forEach(bom, (n) => {
        const bom_key = _.uniqueId(`bom-`);
        bomItemKeys = [...bomItemKeys, bom_key];
        bomItemByKey = {
          ...bomItemByKey,
          [bom_key]: {
            ...n,
          },
        };
      });
      state.bom = {
        visible: bomItemKeys.length > 0,
        itemKeys: bomItemKeys,
        itemByKey: bomItemByKey,
      };
      state.history = history || [];
    },
    SET_FORM: (state, { payload }) => {
      state.form = { ...state.form, ...payload };
    },
    SELECT_ROW_KEYS: (state, { payload }) => {
      state.list.selectedRowKeys = payload;
    },
    ADD_MEDIA: (state, { payload }) => {
      const media_key = _.uniqueId("media-");
      state.media.itemKeys = [...state.media.itemKeys, media_key];
      state.media.itemByKey = {
        ...state.media.itemByKey,
        [media_key]: {
          ...payload,
          id: null,
          is_default: state.media.itemKeys.length === 1,
          media_key,
        },
      };
    },
    SET_MEDIA: (state, { payload: { media_key, ...data } }) => {
      state.media.itemByKey = {
        ...state.media.itemByKey,
        [media_key]: {
          ...state.media.itemByKey[media_key],
          ...data,
        },
      };
    },
    DEL_MEDIA: (state, { payload: { media_key } }) => {
      const { id } = state.media.itemByKey[media_key];
      if (id) {
        state.media.delete = [...state.media.delete, id];
      }
      state.media.itemKeys = _.filter(
        state.media.itemKeys,
        (n) => n !== media_key
      );
      state.media.itemByKey = {
        ..._.omit(state.media.itemByKey, [media_key]),
      };
    },
    SET_MEDIA_DEFAULT: (state, { payload: { media_key } }) => {
      state.media.itemByKey = _.reduce(
        state.media.itemKeys,
        (r, n) => {
          return {
            ...r,
            [n]: {
              ...state.media.itemByKey[n],
              is_default: state.media.itemByKey[n].media_key === media_key,
            },
          };
        },
        {}
      );
    },
    SET_BOM_VISIBLE: (state, { payload }) => {
      state.bom.visible = payload;
      state.bom.itemKeys = [];
      state.bom.itemByKey = {};
    },
    ADD_BOM: (state, { payload }) => {
      if (
        _.find(
          _.map(state.bom.itemKeys, (n) => state.bom.itemByKey[n]),
          (m) => m.item_code === payload.item_code
        )
      ) {
        return notification.warning({
          placement: "topRight",
          message: `ในรายการสินค้าเซ็ต มีรหัสสินค้า ${payload.item_code} แล้ว`,
        });
      }
      let bom_key = _.uniqueId("bom-");
      state.bom.itemKeys = [...state.bom.itemKeys, bom_key];
      state.bom.itemByKey = {
        ...state.bom.itemByKey,
        [bom_key]: {
          ...payload,
        },
      };
    },
    SET_BOM_QTY: (state, { payload: { key, qty } }) => {
      state.bom.itemByKey[key].qty = parseInt(qty) || 0;
    },
    DEL_BOM: (state, { payload }) => {
      state.bom.itemKeys = _.filter(state.bom.itemKeys, (n) => n !== payload);
      state.bom.itemByKey = { ..._.omit(state.bom.itemByKey, [payload]) };
    },
    SET_MODAL_VALUE: (state, { payload }) => {
      state.modal = {
        ...state.modal,
        ...payload,
      };
    },
    CLEAR_BOM: (state) => {
      state.bom = { ...initState.bom };
    },
  },
});

export const searchSelector = (state) => state.productRequest.search;
export const formSelector = (state) => state.productRequest.form;
export const listCheckSelector = (state) =>
  state.productRequest.list.selectedRowKeys;
export const mediaSelector = (state) => state.productRequest.media;
export const bomSelector = (state) => state.productRequest.bom;
export const historySelector = (state) => state.productRequest.history;
export const modalSelector = (state) => state.productRequest.modal;

export const actionProductRequest = productRequestSlice.actions;

const ProductRequestReducer = productRequestSlice.reducer;

export default ProductRequestReducer;
