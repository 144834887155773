import React, { useState } from "react";
import { Modal, Upload, Button, Table, Select, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { useStateValue } from "../StateContext";

const { Option } = Select;

const ModalImport = ({ visible, onCancel, onSave }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fileName, setFileName] = useState(""); 
  const [sheetName, setSheetName] = useState(""); 
  const { state, dispatch  ,loading} = useStateValue();
  const eventList = state.data.event_name || [];

  const handleUpload = (file) => {
    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));

    if (!validExtensions.includes(fileExtension)) {
      message.error("กรุณาอัปโหลดไฟล์ Excel (.xlsx หรือ .xls) เท่านั้น");
      return false;
    }

    dispatch({ type: "SET_LOADING", payload: true });
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryData = e.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      if (sheetData.length > 0) {
        const generatedColumns = Object.keys(sheetData[0]).map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
          sheet_name: key,
          align: "center",
        }));
        setColumns(generatedColumns);
      }
      setSheetName(sheetName);
      setFileName(file.name);
      setData(sheetData);
      dispatch({ type: "SET_LOADING", payload: false });
      message.success("อัปโหลดและแปลงไฟล์สำเร็จ!");
    };
    reader.onerror = () => {
      dispatch({ type: "SET_LOADING", payload: false });
      message.error("เกิดข้อผิดพลาดในการอ่านไฟล์");
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const handUploadSave = async () => {
    if (!selectedOption) {
      message.error("กรุณาเลือก Event ก่อนกดยืนยัน");
      return;
    }
    if (data.length === 0) {
      message.error("กรุณาอัปโหลดไฟล์ก่อนกดยืนยัน");
      return;
    }
  
    dispatch({ type: "SET_LOADING", payload: true });
  
    try {
      await onSave({
        selectedEvent: selectedOption,
        fileData: data,
        fileName: fileName,
        sheetName: sheetName,
      });
  
      message.success("อัปโหลดไฟล์สำเร็จ!");
      setData([]); 
    } catch (error) {
      message.error("อัปโหลดไฟล์ล้มเหลว");
    } finally {
      dispatch({ type: "SET_LOADING", payload: false }); 
    }
  };
  

  return (
<Modal
  title="อัปโหลดไฟล์ Excel"
  visible={visible}
  onCancel={!state.loading ? onCancel : undefined} 
  footer={[
    <Button key="cancel" onClick={onCancel} disabled={state.loading}>
      ปิด
    </Button>,
    <Button key="confirm" type="primary" onClick={handUploadSave} loading={state.loading} disabled={state.loading}>
      ยืนยัน
    </Button>,
  ]}
  width={900}
>

      <div className="mb-4">
        <p className="font-semibold">เลือกไฟล์ Excel</p>
        <Upload beforeUpload={handleUpload} showUploadList={false}>
          <Button
            type="dashed"
            icon={<UploadOutlined />}
            loading={loading}
            className="w-full mt-2"
          >
            อัปโหลดไฟล์
          </Button>
        </Upload>
      </div>

      <div className="mb-4">
        <p className="font-semibold text-gray-800">เลือก Event</p>
        <Select
          placeholder="ค้นหา Event"
          className="w-full mt-2"
          onChange={(value) => setSelectedOption(value)}
          showSearch
          defaultValue="default"
        >
          <Option key="0" value="default" disabled>
            กรุณาเลือก Event
          </Option>

          {eventList?.length > 0 ? (
            eventList?.map((event) => (
              <Option key={event.Id} value={event.Id}>
                {event.Name}
              </Option>
            ))
          ) : (
            <Option key="no-event" value="no-event" disabled>
              ไม่มี Event ให้เลือก
            </Option>
          )}
        </Select>
      </div>

      {data?.length > 0 && (
  <div className="mt-4">
    <p className="font-semibold">ข้อมูลที่อัปโหลด</p>
    <Table
      dataSource={data}
      columns={columns}
      bordered
      pagination={{ pageSize: 5 }}
      className="mt-2"
      scroll={{ x: "max-content" }} 
    />
  </div>
)}

    </Modal>
  );
};

export default ModalImport;
