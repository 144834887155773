import { Col, Typography } from "antd";
import React, { memo } from "react";
import ValidateError from "../../../../../components/ValidateError";

const Layout = ({ title, size, error, children }) => (
  <>
    <Col span={2} className="text-right">
      <Typography.Text strong>{title}</Typography.Text>
    </Col>
    <Col span={size === "large" ? 10 : 4}>
      <div className={error && "has-error"}>{children}</div>
      <ValidateError error={error} />
    </Col>
  </>
);

export default memo(Layout);
