/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, notification, Row, Typography } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fixAcceptPartner } from "../../../../../config/fix-value";
import { actionClaim } from "../../../../../redux/slices/claim";
import { axios, URL_API, _ } from "../../../../../utils";
import { resCatch } from "../../../../util/helper";
import SelectSearch from "../../../pos/components/SelectSearch";

const { Text } = Typography;

const { setClaimItemValue } = actionClaim;

const ProductUsedBy = ({ code, name, mobile, disabled }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadList, setLoadList] = useState([]);
  const [source, setSource] = useState(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel("unmounted");
    };
  }, []);

  const clearUseBy = () =>
    dispatch(
      setClaimItemValue({
        used_by_code: null,
        used_by_name: null,
        used_by_mobile: null,
      })
    );

  const setUsedBy = (data) => {
    const status = data.status?.toLowerCase();
    setLoadList([]);
    if (!_.includes(fixAcceptPartner.cliam_used_by, status)) {
      Modal.warning({
        title: "ไม่สามารถดำเนินการได้",
        content: `รหัสนักธุรกิจ ${data.code} มีสถานะ ${data.text_status}`,
        okText: "ตกลง",
        zIndex: 1080,
      });
      clearUseBy();
    } else {
      dispatch(
        setClaimItemValue({
          used_by_code: data.code,
          used_by_name: `${data.first_name_th} ${data.sur_name_th}`,
          used_by_mobile: data.mobile,
        })
      );
    }
  };

  const handleGetUsedBy = () => {
    setLoading(true);
    axios({
      url: `${URL_API}/pos/master/member`,
      params: {
        type: "Member",
        query: code.trim(),
      },
      cancelToken: source.token,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.data && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            setUsedBy(res.data.data[0]);
          } else {
            setLoadList(res.data.data);
            document.getElementById("useBy").click();
          }
        } else {
          notification.warning({
            message: "ไม่พบข้อมูล",
            description: "กรุณาตรวจสอบรหัสใหม่อีกครั้ง",
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        resCatch(err);
      });
  };

  const handleSearch = (e) => {
    if (e) {
      setLoadList([]);
      dispatch(
        setClaimItemValue({
          used_by_code: e.trim(),
        })
      );
    }
  };

  const handleInputKeyDown = (e) => {
    let key = e.key;
    if (key === "Enter" && loadList.length === 0 && !!code) {
      handleGetUsedBy();
    } else if (key === "Backspace" || key === "Delete") {
      setLoadList([]);
      clearUseBy();
    }
  };

  const handleClickCancel = () => {
    source.cancel("cancel search");
    setSource(axios.CancelToken.source());
  };

  const handleClickSearch = () => !!code && handleGetUsedBy();

  const handleSelect = (i) => setUsedBy(loadList[+i]);

  return (
    <>
      <Row gutter={[8, 8]} type="flex" className="my-3 align-items-center">
        <Col span={3} className="text-right">
          <Text>รหัสผู้ใช้สินค้า</Text>
        </Col>
        <Col span={21}>
          <SelectSearch
            size="small"
            selectId="useBy"
            placeholder="รหัสสมาชิก, เลขบัตรประชาชน, ชื่อ, อีเมล , เบอร์โทร"
            value={code || undefined}
            loadList={loadList}
            loading={loading}
            disabled={disabled}
            onSearch={handleSearch}
            onInputKeyDown={handleInputKeyDown}
            onClickCancel={handleClickCancel}
            onClickSearch={handleClickSearch}
            onSelect={handleSelect}
          />
        </Col>
      </Row>
      {name && (
        <Row gutter={16} type="flex" className="mb-3 align-items-center">
          <Col span={3} className="text-right">
            <Text>ผู้ใช้สินค้า</Text>
          </Col>
          <Col span={9}>
            <Text>{name}</Text>
          </Col>
          <Col span={3} className="text-right">
            <Text>เบอร์โทร</Text>
          </Col>
          <Col span={9}>
            <Text>{mobile}</Text>
          </Col>
        </Row>
      )}
    </>
  );
};

export default memo(ProductUsedBy);
