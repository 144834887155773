import React, { memo, useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import numeral from "numeral";
import { _ } from "../utils";

const { Text } = Typography;

const SummaryPreview = ({ data, isNormal, setSummaryTotal }) => {
  let find_distributor = data?.find(
    (element) => element.partner_type.toLowerCase() == "distributor"
  );
  const [total, setTotal] = useState({});
  let totalDiscount = data?.reduce(
    (sum, item) => sum + (item.totalDiscount - item.delivery_amount),
    0
  );
  useEffect(() => {
    if (isNormal) {
      const total = _.reduce(
        data,
        (res, n) => {
          const items = _.reduce(
            n.items,
            (res, n) => ({
              pv: res.pv + n.pv * n.qty,
              rp: res.rp + n.rp * n.qty,
              rtb: res.rtb + n.rtb * n.qty,
              cv: res.cv + n.cv * n.qty,
              bp: res.bp + n.bp * n.qty,
              ra: res.ra + n.ra * n.qty,
              tp: res.tp + n.tp * n.qty,
              ep: res.ep + n.ep * n.qty,
              unit_price: res.unit_price + n.unit_price * n.qty,
            }),
            {
              pv: 0,
              rp: 0,
              rtb: 0,
              cv: 0,
              bp: 0,
              ra: 0,
              tp: 0,
              ep: 0,
              unit_price: 0,
            }
          );
          return {
            pv: res.pv + (n.partner_type.toLowerCase() == "distributor" ? items.pv :0 ),
            rp: res.rp + items.rp,
            rtb: res.rtb + items.rtb,
            cv: res.cv + items.cv,
            bp: res.bp + items.bp,
            ra: res.ra + items.ra,
            tp: res.tp + (n.partner_type.toLowerCase() == "distributor" ? items.tp :0 ),
            ep: res.ep + (n.partner_type.toLowerCase() == "distributor" ? items.ep :0 ),
            amount: res.amount + items.unit_price,
          };
        },
        {
          pv: 0,
          rp: 0,
          rtb: 0,
          cv: 0,
          bp: 0,
          ra: 0,
          tp: 0,
          ep: 0,
          amount: 0,
        }
      );
      setTotal(total);
    } else {
      const amount = _.reduce(
        data,
        (res, n) =>
          res + _.reduce(n.items, (res, n) => res + n.unit_price * n.qty, 0),
        0
      );
      setTotal({ amount });
    }
  }, [data, isNormal]);

  return (
    <>
      {isNormal && (
        <Row gutter={8} className="font-size-12">
          <Col>
            <Row className="bg-pv-light py-1">
              <Col span={3} offset={9} className="text-right">
                <Text strong className="text-dark">
                  คะแนนรวม
                </Text>
              </Col>
              {find_distributor ? (
                <>
                  <Col span={2} offset={5} className="text-center">
                    <Text strong className="text-dark">
                      {numeral(total.pv).format("0,0.00")}
                    </Text>
                  </Col>
                  <Col span={2} className="text-center">
                    <Text strong className="text-dark">
                      {numeral(total.rp).format("0,0.00")}
                    </Text>
                  </Col>
                  <Col span={2} className="text-center">
                    <Text strong className="text-dark">
                      {numeral(total.ep).format("0,0.00")}
                    </Text>
                  </Col>
                  <Col span={1} className="text-center">
                    <Text strong className="text-dark">
                      {numeral(total.tp).format("0,0.00")}
                    </Text>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={2} offset={5} className="text-center"/>
                  <Col span={2} className="text-center">
                    <Text strong className="text-dark">
                      {numeral(total.rp).format("0,0.00")}
                    </Text>
                  </Col>
                </>
              )}

              {/* <Col span={1} className="text-center">
                <Text strong className="text-dark">
                  {numeral(total.cv).format("0,0")}
                </Text>
              </Col>
              <Col span={1} className="text-center">
                <Text strong className="text-dark">
                  {numeral(total.sp).format("0,0")}
                </Text>
              </Col> */}
            </Row>
          </Col>
        </Row>
      )}
      <Row gutter={8}>
        <Col>
          <Row className="bg-pv py-1">
            <Col span={3} offset={isNormal ? 9 : 14} className="text-right">
              <Text strong className="text-dark">
                จำนวน{isNormal ? "เงิน" : " RP "}รวม
              </Text>
            </Col>
            <Col span={3} offset={2} className="text-center">
              <Text strong className="text-dark">
                {numeral(total.amount - totalDiscount).format("0,0.00")}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default memo(SummaryPreview);
