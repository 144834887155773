import { Col, Input, Row, Typography } from "antd";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaim } from "../../../../../../redux/slices/claim";

const { setClaimItemValueBom } = actionClaim;

const InputDescription = ({ disabled }) => {
  const dispatch = useDispatch();
  const product_key = useSelector(
    (state) => state.claim.productModal.product_key
  );
  const product_bom_key = useSelector(
    (state) => state.claim.productBomModal.product_bom_key
  );
  const description = useSelector((state) => {
    if (!product_key || !product_bom_key) return null;
    const { activeKey, itemByKey } =
      state.claim.billProduct.itemByKey[product_key].claim_item;
    const { activeKey: activeKeyBom, itemByKey: itemByKeyBom } =
      itemByKey[activeKey].bom.itemByKey[product_bom_key].claim_item;
    return itemByKeyBom[activeKeyBom].description;
  });

  return (
    <Row gutter={[8, 8]}>
      <Col span={3} className="text-right">
        <Typography.Text>รายละเอียด</Typography.Text>
      </Col>
      <Col span={21}>
        <Input.TextArea
          value={description}
          maxLength={500}
          placeholder="รายละเอียดการเคลมสินค้า"
          autoSize={{ minRows: 4, maxRows: 4 }}
          disabled={disabled}
          onChange={(e) =>
            dispatch(setClaimItemValueBom({ description: e.target.value }))
          }
        />
      </Col>
    </Row>
  );
};

export default memo(InputDescription);
