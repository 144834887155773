/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const KPaymentButton = forwardRef(
  (
    {
      debug = false,
      id = "form-kbank-checkout",
      action = "",
      orderId = "",
      amount = "0.00",
      currency = "THB",
      paymentMethods = "card",
      merchantName = "MY SHOP",
      merchantId = "",
      publicKey = "",
      customerId = "",
      smartPayId = "",
      campaignId = "",
      term = "",
      onLoaded,
      onError,
      onClose,
      hidden = false,
    },
    ref
  ) => {
    const refDiv = useRef();
    const [error, setError] = useState(null);

    useImperativeHandle(ref, () => ({
      setAction,
      setProps,
      show: (order_id) => {
        window.KPayment.setOrderId(order_id || "");
        window.KPayment.show();
      },
    }));

    const setAction = (value) => {
      if (refDiv.current) {
        const form = refDiv.current.getElementsByTagName("FORM");
        if (form.length > 0) {
          form[0].setAttribute("action", value);
        }
      }
    };

    const setProps = (values) => {
      window.KPayment.setOrderId(values.orderId);
      window.KPayment.setAmount(values.amount);
      window.KPayment.setMid(values.merchantId);
      window.KPayment.setPublickey(values.publicKey);
      window.KPayment.setPaymentMethods(values.paymentMethods);
      window.KPayment.setSmartpayId(values.smartPayId);
      window.KPayment.setTerm(values.term);
      window.KPayment.setCustomerId(values.customerId);
    };

    const init = () => {
      if (refDiv.current == null) {
        return;
      }

      const exist = document.getElementById(id);

      if (exist) {
        return;
      }

      if (id.length == 0) {
        setError("property id is null");
        return;
      }

      const url_prod = `https://kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js`;
      const url_dev = `https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js`;

      let form = document.createElement("form");
      form.id = id;
      form.method = "POST";
      form.action = action;

      // script
      let script = document.createElement("script");
      script.src = debug ? url_dev : url_prod;

      script.setAttribute("data-apikey", publicKey);
      script.setAttribute("data-amount", amount);
      script.setAttribute("data-currency", currency);
      script.setAttribute("data-payment-methods", paymentMethods);
      script.setAttribute("data-name", merchantName);
      script.setAttribute("data-mid", merchantId);

      // remember
      if (customerId.length > 0) {
        script.setAttribute("data-customer-id", customerId);
      }

      // installment term
      if (smartPayId.length > 0) {
        script.setAttribute("data-smartpay-id", smartPayId);
        script.setAttribute("data-term", term);
      }

      script.type = "text/javascript";
      script.async = true;
      script.onload = () => {
        window.KPayment.create();
        window.KPayment.onClose(() => {
          if (onClose) {
            onClose();
          }
        });
        if (onLoaded) {
          onLoaded();
        }
      };

      if (!window.KPayment) {
        form.appendChild(script);
      }
      refDiv.current.appendChild(form);
    };

    useEffect(() => {
      init();
    }, []);

    useEffect(() => {
      if (error && onError) {
        onError(error);
      }
    }, [error]);

    useEffect(() => {
      setAction(action);
    }, [action]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setAmount(amount);
      }
    }, [amount]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setPaymentMethods(paymentMethods);
      }
    }, [paymentMethods]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setOrderId(orderId);
      }
    }, [orderId]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setName(merchantName);
      }
    }, [merchantName]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setMid(merchantId);
      }
    }, [merchantId]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setPublickey(publicKey);
      }
    }, [publicKey]);

    // useEffect(() => {
    //   if (window.KPayment) {
    //     window.KPayment.setSavecard(merchantId);
    //   }
    // }, [customerId]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setCustomerId(customerId);
      }
    }, [customerId]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setSmartpayId(smartPayId);
      }
    }, [smartPayId]);

    useEffect(() => {
      if (window.KPayment) {
        window.KPayment.setTerm(term);
      }
    }, [term]);

    useEffect(() => {
      return () => {
        // console.log(window.KPayment.onClose());
      };
    }, []);

    return (
      <div className={hidden ? "hidden" : ""} ref={refDiv}>
        {error}
      </div>
    );
  }
);

export default KPaymentButton;
