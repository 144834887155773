import React from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import { _ } from "../../../../utils";

const BillItems = (props) => {
  const pay_with = props.isNormal ? "ราคา" : "คะแนน";

  const renderItems = () => {
    let arr = [];
    props.items.forEach((n, index) =>
      arr.push(
        <tr key={index}>
          <td className=" pr-2 pl-4">{`${n.product_code} ${n.product_name}`}</td>
          <td className="text-right px-2">
            {!props.is_staff &&
              props.isNormal &&
              !props.is_vip_member &&
              numeral(props.collect_point && n.pv ? n.pv : 0).format("0,0.00")}
          </td>
          <td className="text-right px-2">
            {numeral(n.unit_price || 0).format("0,0.00")}
          </td>
          <td className="text-right px-2">
            {numeral(n.qty || 0).format("0,0")}
          </td>
          <td className="text-right pl-2 pr-4">
            {numeral((n.unit_price || 0) * (n.qty || 0)).format("0,0.00")}
          </td>
        </tr>
      )
    );

    return arr;
  };

  const total_amount = _.reduce(
    props.items,
    (sum, n) => {
      return sum + (n.unit_price || 0) * (n.qty || 0);
    },
    0
  );

  return (
    <table>
      <thead>
        <tr className="text-gray-60">
          <th className="text-center w-100 pr-2 pl-4 font-weight-normal">
            รายการสินค้า
          </th>
          <th className="text-nowrap text-center px-2 font-weight-normal">
            {!props.is_staff && props.isNormal && !props.is_vip_member && "PV"}
          </th>
          <th className="text-nowrap text-center px-2 font-weight-normal">
            {pay_with}/หน่วย
          </th>
          <th className="text-nowrap text-center px-2 font-weight-normal">
            จำนวน
          </th>
          <th className="text-nowrap text-center pl-2 pr-4 font-weight-normal">
            {pay_with}
          </th>
        </tr>
      </thead>
      <tbody>{renderItems()}</tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className=" pr-2 pl-4">
            <strong>{pay_with}รวม</strong>
          </td>
          <td className="text-right pl-2 pr-4">
            <strong>{numeral(total_amount).format("0,0.00")}</strong>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

const mapStateToProps = (state) => {
  const { orders, payment, buyer_type } = state.pos.operation;
  const activeKey = payment.activeKey || orders.itemKeys[0];
  const activeObj = orders.itemByKey[activeKey];

  return {
    items: activeObj
      ? [
          ..._.map(
            activeObj.items.itemKeys,
            (n) => activeObj.items.itemByKey[n]
          ),
          ...(activeObj?.promotions.promotion_list || []),
        ]
      : [],
    collect_point: activeObj?.collect_point || false,
    is_staff: buyer_type?.toLowerCase() === "staff",
    is_vip_member:
      orders.itemByKey[activeKey]?.partner_type?.toLowerCase() === "member",
  };
};

export default connect(mapStateToProps)(BillItems);
