import { Table } from "antd";
import React from "react";
import moment from "moment";
import { useApi } from "../../../../../hooks/http2";
import { shallowEqual, useSelector } from "react-redux";
import { URL_API } from "../../../../../utils";

export default function TableBookBankHistory() {
  const { id, type } = useSelector((state) => state.ethics.form, shallowEqual);
  const bankHistory = useApi(
    {
      baseURL: URL_API,
      url: `/ethics/history/bank`,
      token: true,
      params: {
        id: id,
        type: type,
        offset: 0,
        limit: 0,
      },
    },
    [id, type]
  );

  return (
    <Table
      size="small"
      loading={bankHistory.loading}
      dataSource={
        bankHistory.fetch?.data.map((item, index) => ({
          ...item,
          key: index + 1,
        })) || []
      }
      rowKey="row_id"
      pagination={false}
    >
      <Table.Column
        title="ลำดับ"
        className="font-size-12"
        dataIndex="row_id"
        align="center"
      />
      <Table.Column
        title="ธนาคาร"
        className="font-size-12"
        dataIndex="bank_name"
        //align="center"
      />
      <Table.Column
        title="ชื่อบัญชี"
        className="font-size-12"
        dataIndex="account_name"
        //align="center"
      />
      <Table.Column
        title="เลขที่บัญชี"
        className="font-size-12"
        dataIndex="account_number"
        //align="center"
      />
      <Table.Column
        title="สาขา"
        className="font-size-12"
        dataIndex="branch_name"
        //align="center"
      />
      <Table.Column
        title="วันที่แก้ไข"
        className="font-size-12"
        dataIndex="create_date"
        render={(text) =>
          text ? moment(text).format("DD/MM/YYYY HH:mm") : "-"
        }
        //align="center"
      />
      <Table.Column
        title="ประเภทบัญชี"
        className="font-size-12"
        dataIndex="eft_type"
        render={(text) =>
          text === "Person" ? "บัญชีบุลคลธรรมดา" : "บัญชีนิติบุคคล"
        }
        //align="center"
      />
    </Table>
  );
}
