import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TabUnderLine from "../../../../components/TabUnderLine";
import {
  actionStoreQrBalance,
  activeSelector,
} from "../../../../redux/slices/store-qr/balance";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import { getUser} from "../../../../utils";
import BalanceLot from "./components/BalanceLot";
import BalanceSet from "./components/BalanceSet";

const tabList = [
  {
    value: "set",
    title: "ตรวจสอบชุดเซ็ต",
  },
  {
    value: "lot",
    title: "ตรวจสอบรายการย่อย",
  },
];

const BalanceList = () => {
  const dispatch = useDispatch();
  const active = useSelector(activeSelector);
  const user = getUser();
  const { SET_ACTIVE } = actionStoreQrBalance;
  
  return (
    <Panel>
      <PanelHeader>สินค้าจัดเซ็ตคงเหลือ</PanelHeader>
      <PanelBody>
        <Row className="mb-4 mt-3">
          <Col span={18} offset={3}>
            <TabUnderLine
              list={tabList}
              active={active}
              onClick={(e) => dispatch(SET_ACTIVE({type:e,branch:user.branch_code}))}
            />
          </Col>
        </Row>

        {active === "set" ? <BalanceSet /> : <BalanceLot />}
      </PanelBody>
    </Panel>
  );
};

export default BalanceList;
