import { Button, Col, Row } from "antd";
import React, { memo } from "react";
import { FaRegCopy } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { actionClaim } from "../../../../../../redux/slices/claim";
import QrBarcode from "./QrBarcode";

const { copyClaimItemBom } = actionClaim;

const Index = ({ disabled }) => {
  const dispatch = useDispatch();
  return (
    <Row gutter={[8, 8]} type="flex" className="align-items-center">
      <Col span={disabled ? 12 : 9}>
        <QrBarcode type="qr_running" />
      </Col>
      <Col span={disabled ? 12 : 9}>
        <QrBarcode type="barcode" />
      </Col>
      {!disabled && (
        <Col span={6} className="text-right">
          <Button type="primary" onClick={() => dispatch(copyClaimItemBom())}>
            <FaRegCopy className="mr-2" />
            เพิ่มจำนวนเคลม (คัดลอก)
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default memo(Index);
