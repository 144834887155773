import { Col, Input, Row, Typography } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Creators } from "../ducks/operation-form";

const BillMoreInfo = ({ more_info, setBillValue, activeKey }) => {
  return (
    <Row gutter={16} className="mx-3 my-3">
      <Col span={24} className="d-flex">
        <Typography.Text className="mr-3 text-nowrap">
          ข้อมูลเพิ่มเติม
        </Typography.Text>
        <Input
          size="small"
          value={more_info}
          onChange={(e) =>
            setBillValue({ more_info: e.target.value, activeKey })
          }
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  let { itemKeys, itemByKey } = state.pos.operation.orders;
  let { activeKey } = state.pos.operation.payment;

  const key = activeKey || itemKeys[0];

  return {
    more_info: itemByKey[key]?.more_info,
    activeKey: key,
  };
};

export default connect(mapStateToProps, {
  setBillValue: Creators.setBillValue,
})(BillMoreInfo);
