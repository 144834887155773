/* eslint-disable no-useless-computed-key */
import { createReducer } from "@reduxjs/toolkit";
import React from "react";
import { _, moment } from "../../../../../../utils";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import {
  changeConditionReceiveType,
  honorSourceItemMapping,
  orderSourceItemMapping,
} from "./logic";

export const initState = {
  isTemplate: false,
  loading: false,
  data: {
    id: 0,
    order_type: ["hold", "regular"],
    order_source: ["pos", "online", "mobile"],
    status: 0,
    collect_point: 0,
    birth_date: 0,
    description: null,
    member_age: 0,
    member_start: null,
    member_end: null,
    member_day: 0,
    member_month: 0,
    member_year: 0,
    is_step: 0,
    is_extend: 0,
    condition_type: null,
    receive_type: null,
    order_limit: 0,
    order_limit_qty: 1,
    collect: 0,
    collect_start: null,
    collect_end: null,
    distributor_sale: 0,
    member_sale: 0,
    staff_sale: 0,
  },
  modal: {
    product: {
      visible: false,
      active_by: null,
      data_key: null,
      ref_source_option: null,
    },
    voucher: {
      visible: false,
      active_by: null,
      data_key: null,
      ref_source_option: null,
    },
    amount: {
      visible: false,
      active_by: null,
      data_key: null,
      ref_source_option: null,
    },
    img_preview: {
      id: null,
      src: null,
      visible: false,
    },
    add_data: {
      visible: false,
      type: null,
      data_key: null,
    },
  },
  orderSource: {
    pos: {
      itemKeys: [],
      itemByKey: {},
    },
    online: {
      itemKeys: ["branch-1"],
      itemByKey: {
        ["branch-1"]: {
          branch_key: "branch-1",
          code: null,
          start_date: null,
          end_date: null,
        },
      },
    },
    mobile: {
      itemKeys: ["branch-1"],
      itemByKey: {
        ["branch-1"]: {
          branch_key: "branch-1",
          code: null,
          start_date: null,
          end_date: null,
        },
      },
    },
  },
  honorSource: {
    honor_code: {
      itemKeys: [],
      itemByKey: {},
    },
    highest_pin: {
      itemKeys: [],
      itemByKey: {},
    },
    current_pin: {
      itemKeys: [],
      itemByKey: {},
    },
  },
  media: {
    delete: [],
    itemDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  condition: {
    mediaDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  receive: {
    mediaDel: [],
    itemKeys: [],
    itemByKey: {},
  },
  error: {
    item: [],
    rule: {
      condition: "ต้องมีเงื่อนไขการซื้อ อย่างน้อย 1 เงื่อนไข",
      condition_item: "ทุกเงื่อนไขการซื้อ ต้องมีรายการอย่างน้อย 1 รายการ",
      receive: "ต้องมีสิ่งที่ได้รับ อย่างน้อย 1 รายการ",
      receive_item: "ทุกสิ่งที่ได้รับ ต้องมีรายการอย่างน้อย 1 รายการ",
    },
  },
};

export const reducer = createReducer(initState, {
  SET_LOADING: (state, { payload }) => {
    state.loading = payload;
  },
  SET_LOAD_SUCCESS: (state, { payload }) => {
    const {
      condition,
      receive,
      order_source_item,
      honor_source_item,
      description,
      status,
      media,
      media_id,
      condition_media,
      receive_media,
      ...data
    } = payload;

    const description_content_bock = description
      ? htmlToDraft(description)
      : null;
    state.loading = false;
    state.data = {
      ...state.data,
      ...data,
      member_start: data.member_start
        ? moment(data.member_start)
        : data.member_start,
      member_end: data.member_end ? moment(data.member_end) : data.member_end,
      collect_start: data.collect_start
        ? moment(data.collect_start)
        : data.collect_start,
      collect_end: data.collect_end
        ? moment(data.collect_end)
        : data.collect_end,
      status: status === "active" ? 1 : 0,
      description: description_content_bock
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              description_content_bock.contentBlocks
            )
          )
        : null,
    };
    if (data.type === "giveaway") {
      let conditionItemKeys = [];
      let conditionItemByKey = {};
      _.forEach(condition, (item, condition_index) => {
        const condition_key = _.uniqueId("condition-");
        let item_key = [];
        let item_data = {};
        let setting_data = {};
        // if (data.is_extend === 1 && condition_index === 0) {
        //   setting_data = {
        //     disabled_del: true,
        //   };
        // }
        _.forEach(item, (curr) => {
          const key = _.uniqueId(`${curr.item_type}-`);
          if (data.is_extend === 1 && condition_index === 1) {
            setting_data = {};
            const parent_item_index = _.findIndex(
              condition[0],
              (parent) =>
                parent.item_type === curr.item_type &&
                parent.product_code === curr.product_code
            );
            if (~parent_item_index) {
              setting_data = {
                disabled_del: true,
                min: condition[0][parent_item_index].qty,
              };
            }
          }
          item_key = [...item_key, key];
          item_data = {
            ...item_data,
            [key]: {
              ...curr,
              ...setting_data,
              key,
            },
          };
        });
        const condition_media_index = _.findIndex(
          condition_media,
          (media) => media.ref_code === item[0].source_option
        );
        conditionItemKeys = [...conditionItemKeys, condition_key];
        conditionItemByKey = {
          ...conditionItemByKey,
          [condition_key]: {
            itemKeys: item_key,
            itemByKey: item_data,
            media: ~condition_media_index
              ? {
                  ...condition_media[condition_media_index],
                }
              : null,
          },
        };
      });
      state.condition = {
        mediaDel: [],
        itemKeys: [...conditionItemKeys],
        itemByKey: { ...conditionItemByKey },
      };
    }
    let receiveItemKeys = [];
    let receiveItemByKey = {};
    _.forEach(receive, (item) => {
      const receive_key = _.uniqueId("receive-");
      let item_key = [];
      let item_data = {};
      _.forEach(item, (curr) => {
        let setting_data = {};
        const key = _.uniqueId(`${curr.item_type}-`);
        if (data.is_extend === 1 && curr.ref_source_option === "1") {
          const parent_receive = _.filter(
            receive,
            (rec) => rec[0].ref_source_option === "0"
          );
          let parent_item_index = -1;
          const parent_receive_index = _.findIndex(parent_receive, (parent) => {
            parent_item_index = _.findIndex(
              parent,
              (item) =>
                item.item_type === curr.item_type &&
                parent.product_code === curr.product_code
            );
            return ~parent_item_index;
          });
          if (~parent_receive_index) {
            setting_data = {
              disabled_del: true,
              min: receive[parent_item_index][parent_item_index].qty,
            };
          }
        }
        item_key = [...item_key, key];
        item_data = {
          ...item_data,
          [key]: {
            ...curr,
            ...setting_data,
            key,
          },
        };
      });
      const receive_media_index = _.findIndex(
        receive_media,
        (media) => media.ref_code === item[0].source_option
      );
      receiveItemKeys = [...receiveItemKeys, receive_key];
      receiveItemByKey = {
        ...receiveItemByKey,
        [receive_key]: {
          itemKeys: item_key,
          itemByKey: item_data,
          media: ~receive_media_index
            ? {
                ...receive_media[receive_media_index],
              }
            : null,
          refSourceOption: item_data[item_key[0]].ref_source_option,
        },
      };
    });
    state.receive = {
      mediaDel: [],
      itemKeys: receiveItemKeys,
      itemByKey: receiveItemByKey,
    };
    state.orderSource = {
      ...state.orderSource,
      ...orderSourceItemMapping(order_source_item, initState.orderSource),
    };
    state.honorSource = {
      ...state.honorSource,
      ...honorSourceItemMapping(honor_source_item),
    };
    let mediaItemKeys = [];
    let mediaItemByKey = {};
    _.forEach(media, (n) => {
      const media_key = _.uniqueId(`promotion-`);
      mediaItemKeys = [...mediaItemKeys, media_key];
      mediaItemByKey = {
        ...mediaItemByKey,
        [media_key]: {
          ...n,
          is_default: n.id === media_id,
          media_key,
        },
      };
    });
    state.media = {
      ...state.media,
      itemKeys: mediaItemKeys,
      itemByKey: mediaItemByKey,
    };
  },
  SET_MODAL_VISIBLE: (state, { payload: { name, ...data } }) => {
    state.modal[name] = {
      ...state.modal[name],
      ...data,
    };
  },
  ADD_PROMOTION_DATA_ITEM: (state, { payload: { modal_name, ...payload } }) => {
    const { active_by, data_key, ref_source_option } = state.modal[modal_name];
    let select_key = null;

    switch (modal_name) {
      case "product":
        select_key = _.find(
          state[active_by].itemByKey[data_key].itemKeys,
          (n) =>
            state[active_by].itemByKey[data_key].itemByKey[n].item_type ===
            "category"
              ? state[active_by].itemByKey[data_key].itemByKey[n].code ===
                payload.code
              : state[active_by].itemByKey[data_key].itemByKey[n]
                  .product_code === payload.product_code
        );
        break;
      case "amount":
        select_key = _.find(
          state[active_by].itemByKey[data_key].itemKeys,
          (key) =>
            state[active_by].itemByKey[data_key].itemByKey[key].item_type ===
            payload.item_type
        );
        break;
      case "voucher":
        select_key = _.find(
          state[active_by].itemByKey[data_key].itemKeys,
          (key) =>
            state[active_by].itemByKey[data_key].itemByKey[key].id ===
            payload.id
        );
        break;
      default:
        return;
    }

    if (select_key) {
      state[active_by].itemByKey[data_key].itemByKey[select_key].qty += 1;
    } else {
      const key = _.uniqueId(`${modal_name}-`);
      state[active_by].itemByKey[data_key].itemKeys = [
        ...state[active_by].itemByKey[data_key].itemKeys,
        key,
      ];
      state[active_by].itemByKey[data_key].itemByKey = {
        ...state[active_by].itemByKey[data_key].itemByKey,
        [key]: ref_source_option
          ? {
              ...payload,
              key,
              qty: 1,
              ref_source_option,
            }
          : {
              ..._.omit(payload, ["visible"]),
              key,
              qty: 1,
            },
      };
      if (ref_source_option) {
        state[active_by].itemByKey[data_key].refSourceOption =
          ref_source_option;
      }
    }
  },
  SELECT_ORDER_TYPE: (state, { payload: { type, checked } }) => {
    let newOrderType = [...state.data.order_type];

    if (checked) {
      newOrderType.push(type);
    } else {
      newOrderType = _.filter(newOrderType, (n) => n !== type);
    }

    state.data.order_type = newOrderType;
  },
  SELECT_ORDER_SOURCE: (state, { payload: { type, checked } }) => {
    let newOrderSource = [...state.data.order_source];

    if (checked) {
      newOrderSource.push(type);
    } else {
      newOrderSource = _.filter(newOrderSource, (n) => n !== type);
    }

    state.data.order_source = newOrderSource;
  },
  SET_DATA: (state, { payload }) => {
    state.data = {
      ...state.data,
      ...payload,
    };
  },
  SET_SWITCH_CHECK: (state, { payload: { name, checked } }) => {
    state.data[name] = checked ? 1 : 0;
  },
  CHANGE_CONDITION_OPTION: (state, { payload: { checked, name } }) => {
    state.data[name] = checked ? 1 : 0;
    if (name === "is_step" && !checked) {
      state.data.is_extend = 0;
    }
    state.condition = {
      ...state.condition,
      ...changeConditionReceiveType(state, "condition"),
    };
    state.receive = {
      ...state.receive,
      ...changeConditionReceiveType(state, "receive"),
    };
    state.error.item = [];
  },
  CHANGE_CONDITION_RECEIVE_TYPE: (state, { payload: { type, value } }) => {
    if (type === "condition") {
      if (state.data.is_step === 1) {
        state.receive = {
          ...state.receive,
          ...changeConditionReceiveType(state, "receive"),
        };
      }
      state.data.condition_type = value;
    } else {
      state.data.receive_type = value;
    }
    state[type] = {
      ...state[type],
      ...changeConditionReceiveType(state, type),
    };
    state.error.item = _.filter(
      state.error.item,
      (n) => !_.includes([`${type}`, `${type}_item`], n)
    );
  },
  SET_PROMOTION_DATA_ITEM_VALUE: (
    state,
    { payload: { type, data_key, item_key, ...data } }
  ) => {
    state[type].itemByKey[data_key].itemByKey[item_key] = {
      ...state[type].itemByKey[data_key].itemByKey[item_key],
      ...data,
    };
  },
  SET_PROMOTION_DATA_MEDIA: (state, { payload: { type, data_key, media } }) => {
    state[type].itemByKey[data_key].media = {
      ...state[type].itemByKey[data_key].media,
      ...media,
    };
  },
  DEL_PROMOTION_DATA_MEDIA: (state, { payload: { type, data_key } }) => {
    const { temp, id } = state[type].itemByKey[data_key].media;
    if (!temp) {
      state[type].mediaDel = [...state[type].mediaDel, id];
    }
    state[type].itemByKey[data_key].media = null;
  },
  DEL_PROMOTION_DATA_ITEM: (
    state,
    { payload: { type, data_key, item_key } }
  ) => {
    state[type].itemByKey[data_key].itemByKey = _.omit(
      state[type].itemByKey[data_key].itemByKey,
      [item_key]
    );
    state[type].itemByKey[data_key].itemKeys = _.filter(
      state[type].itemByKey[data_key].itemKeys,
      (n) => n !== item_key
    );
  },
  ADD_PROMOTION_DATA: (state, { payload: { type, refSourceOption } }) => {
    const data_key = _.uniqueId(`${type}-`);
    let data = { itemKeys: [], itemByKey: {} };
    if (
      state.data.is_extend === 1 &&
      type === "condition" &&
      state.condition.itemKeys.length === 1
    ) {
      const data_parent =
        state.condition.itemByKey[state.condition.itemKeys[0]];
      const modal_name = _.split(
        data_parent.itemByKey[data_parent.itemKeys[0]].key,
        "-"
      )[0];
      data = {
        ..._.reduce(
          data_parent.itemKeys,
          (item_res, item_parent_key) => {
            const item_condition_key = _.uniqueId(`${modal_name}-`);
            return {
              itemKeys: [...(item_res.itemKeys || []), item_condition_key],
              itemByKey: {
                ...(item_res.itemByKey || {}),
                [item_condition_key]: {
                  ...data_parent.itemByKey[item_parent_key],
                  min: data_parent.itemByKey[item_parent_key].qty,
                  key: item_condition_key,
                  disabled_del: true,
                },
              },
            };
          },
          {}
        ),
      };
      const receive_data = _.reduce(
        _.filter(
          state.receive.itemKeys,
          (data_key) =>
            state.receive.itemByKey[data_key].refSourceOption === "0"
        ),
        (res, parent_key) => {
          const receive_key = _.uniqueId("receive-");
          return {
            itemKeys: [...(res.itemKeys || []), receive_key],
            itemByKey: {
              ...(res.itemByKey || {}),
              [receive_key]: {
                ..._.reduce(
                  state.receive.itemByKey[parent_key].itemKeys,
                  (item_res, item_parent_key) => {
                    const item_receive_key = _.uniqueId(
                      `${
                        _.split(
                          state.receive.itemByKey[parent_key].itemByKey[
                            item_parent_key
                          ].key,
                          "-"
                        )[0]
                      }-`
                    );
                    return {
                      itemKeys: [
                        ...(item_res.itemKeys || []),
                        item_receive_key,
                      ],
                      itemByKey: {
                        ...(item_res.itemByKey || {}),
                        [item_receive_key]: {
                          ...state.receive.itemByKey[parent_key].itemByKey[
                            item_parent_key
                          ],
                          min: state.receive.itemByKey[parent_key].itemByKey[
                            item_parent_key
                          ].qty,
                          key: item_receive_key,
                          disabled_del: true,
                          ref_source_option: "1",
                        },
                      },
                    };
                  },
                  {}
                ),
                media: null,
                refSourceOption: "1",
              },
            },
          };
        },
        {}
      );
      state.receive.itemKeys = [
        ...state.receive.itemKeys,
        ...receive_data.itemKeys,
      ];
      state.receive.itemByKey = {
        ...state.receive.itemByKey,
        ...receive_data.itemByKey,
      };
    }
    state[type].itemKeys = [...state[type].itemKeys, data_key];
    state[type].itemByKey = {
      ...state[type].itemByKey,
      [data_key]: {
        ...data,
        media: null,
        refSourceOption,
      },
    };

    // const step_key = _.uniqueId(`step-`);
    // state[type].itemKeys = [...state[type].itemKeys, data_key];
    // state[type].itemByKey = {
    //   ...state[type].itemByKey,
    //   [data_key]: {
    //     itemKeys: [step_key],
    //     itemByKey: {
    //       [step_key]: {
    //         itemKeys: [],
    //         itemByKey: {},
    //       },
    //     },
    //     media: {},
    //   },
    // };
    // state.modal.add_data = {
    //   visible: true,
    //   type,
    //   data_key,
    // };
  },
  ADD_ORDER_SOURCE: (state, { payload: { type, code, ...data } }) => {
    const index = _.findIndex(
      state.orderSource[type].itemKeys,
      (n) => state.orderSource[type].itemByKey[n].code === code
    );
    if (~index) {
      const branch_key = state.orderSource[type].itemKeys[index];
      state.orderSource[type].itemKeys = _.filter(
        state.orderSource[type].itemKeys,
        (n) => state.orderSource[type].itemByKey[n].code !== code
      );
      state.orderSource[type].itemByKey = _.omit(
        state.orderSource[type].itemByKey,
        [branch_key]
      );
    } else {
      const branch_key = _.uniqueId("branch-");
      state.orderSource[type].itemKeys = [
        ...state.orderSource[type].itemKeys,
        branch_key,
      ];
      state.orderSource[type].itemByKey = {
        ...state.orderSource[type].itemByKey,
        [branch_key]: {
          ...data,
          code,
          state_date: null,
          end_date: null,
          branch_key,
        },
      };
    }
  },
  SET_ORDER_SOURCE: (state, { payload: { type, branch_key, ...data } }) => {
    state.orderSource[type].itemByKey = {
      ...state.orderSource[type].itemByKey,
      [branch_key]: {
        ...state.orderSource[type].itemByKey[branch_key],
        ...data,
      },
    };
  },
  SET_ORDER_SOURCE_ALL: (state, { payload: { type, ...data } }) => {
    let newItemByKey = { ...state.orderSource[type].itemByKey };
    _.forEach(state.orderSource[type].itemKeys, (n) => {
      newItemByKey = {
        ...newItemByKey,
        [n]: {
          ...newItemByKey[n],
          ...data,
        },
      };
    });
    state.orderSource[type].itemByKey = newItemByKey;
  },
  ADD_ORDER_SOURCE_ALL: (state, { payload: { type, selected, data } }) => {
    let newItemKey = [];
    let newItemByKey = {};
    if (selected) {
      _.forEach(data, (n) => {
        const branch_key = _.uniqueId("branch-");
        newItemKey = [...newItemKey, branch_key];
        newItemByKey = {
          ...newItemByKey,
          [branch_key]: {
            start_date: null,
            end_date: null,
            ...n,
            branch_key,
          },
        };
      });
    }
    state.orderSource[type].itemKeys = newItemKey;
    state.orderSource[type].itemByKey = newItemByKey;
  },
  ADD_HONOR_SOURCE: (state, { payload: { type, honor_code, honor_name } }) => {
    const index = _.findIndex(
      state.honorSource[type].itemKeys,
      (n) => state.honorSource[type].itemByKey[n].honor_code === honor_code
    );
    if (~index) {
      const honor_key = state.honorSource[type].itemKeys[index];
      state.honorSource[type].itemKeys = _.filter(
        state.honorSource[type].itemKeys,
        (n) => state.honorSource[type].itemByKey[n].honor_code !== honor_code
      );
      state.honorSource[type].itemByKey = _.omit(
        state.honorSource[type].itemByKey,
        [honor_key]
      );
    } else {
      const honor_key = _.uniqueId("honor-");
      state.honorSource[type].itemKeys = [
        ...state.honorSource[type].itemKeys,
        honor_key,
      ];
      state.honorSource[type].itemByKey = {
        ...state.honorSource[type].itemByKey,
        [honor_key]: {
          honor_code,
          honor_name,
          honor_key,
        },
      };
    }
  },
  DEFAULT_HONOR_SOURCE_ALL: (state, { payload }) => {
    const result = _.reduce(
      payload,
      (res, n) => {
        const honor_key = _.uniqueId("honor-");
        return {
          itemKeys: [...res.itemKeys, honor_key],
          itemByKey: {
            ...res.itemByKey,
            [honor_key]: {
              honor_code: n.honor_code,
              honor_name: n.honor_name,
              honor_key,
            },
          },
        };
      },
      {
        itemKeys: [],
        itemByKey: {},
      }
    );
    state.honorSource.honor_code = result;
    state.honorSource.highest_pin = result;
    state.honorSource.current_pin = result;
  },
  ADD_HONOR_SOURCE_ALL: (state, { payload: { type, selected, data } }) => {
    let newItemKey = [];
    let newItemByKey = {};
    if (selected) {
      _.forEach(data, (n) => {
        const honor_key = _.uniqueId("honor-");
        newItemKey = [...newItemKey, honor_key];
        newItemByKey = {
          ...newItemByKey,
          [honor_key]: {
            honor_code: n.honor_code,
            honor_name: n.honor_name,
            honor_key,
          },
        };
      });
    }
    state.honorSource[type].itemKeys = newItemKey;
    state.honorSource[type].itemByKey = newItemByKey;
  },
  ADD_MEDIA: (state, { payload }) => {
    const key = _.uniqueId("promotion-");
    state.media.itemKeys = [...state.media.itemKeys, key];
    const option =
      state.media.itemKeys.length === 1 ? { is_default: true } : {};
    state.media.itemByKey = {
      ...state.media.itemByKey,
      [key]: { ...payload, ...option },
    };
  },
  DEL_MEDIA: (state, { payload }) => {
    const { id, temp, is_default } = state.media.itemByKey[payload];
    if (!temp) state.media.itemDel = [...state.media.itemDel, id];
    state.media.itemKeys = _.filter(state.media.itemKeys, (n) => n !== payload);
    if (is_default && state.media.itemKeys.length > 0) {
      state.media.itemByKey[state.media.itemKeys[0]].is_default = true;
    }
    state.media.itemByKey = {
      ..._.omit(state.media.itemByKey, [payload]),
    };
  },
  SET_TEMPLATE: (state) => {
    state.isTemplate = true;
  },
  SET_MEDIA_DEFAULT: (state, { payload }) => {
    state.media.itemByKey = _.reduce(
      state.media.itemKeys,
      (r, n) => {
        return {
          ...r,
          [n]: {
            ...state.media.itemByKey[n],
            is_default: n === payload,
          },
        };
      },
      {}
    );
  },
  DEL_PROMOTION_ITEM: (state, { payload: { type, data_key } }) => {
    if (state.data.is_step === 1 && type === "condition") {
      const index = _.findIndex(
        state.condition.itemKeys,
        (n) => n === data_key
      );
      const receive_key_deleted = _.filter(
        state.receive.itemKeys,
        (data_key) =>
          state.receive.itemByKey[data_key].refSourceOption === index.toString()
      );
      state.receive.mediaDel = [
        ...state.receive.mediaDel,
        ..._.reduce(
          receive_key_deleted,
          (res, receive_key) =>
            state.receive.itemByKey[receive_key].media.temp
              ? res
              : [...res, state.receive.itemByKey[receive_key].media.id],
          []
        ),
      ];
      state.receive.itemKeys = _.filter(
        state.receive.itemKeys,
        (data_key) =>
          state.receive.itemByKey[data_key].refSourceOption !== index.toString()
      );
      state.receive.itemByKey = _.omit(
        state.receive.itemByKey,
        receive_key_deleted
      );
    }
    let new_item_media_del = [...state[type].mediaDel];
    if (
      state[type].itemByKey[data_key].media &&
      !state[type].itemByKey[data_key].media.temp
    ) {
      new_item_media_del = [
        ...new_item_media_del,
        state[type].itemByKey[data_key].media.id,
      ];
    }
    state[type].itemKeys = _.filter(
      state[type].itemKeys,
      (n) => n !== data_key
    );
    state[type].itemByKey = _.omit(state[type].itemByKey, [data_key]);
    state[type].mediaDel = new_item_media_del;
  },
  SET_DEFAULT_DURATION: (state, { payload: { type, value } }) => {
    state.orderSource = {
      ...state.orderSource,
      pos: {
        ...state.orderSource.pos,
        itemByKey: _.reduce(
          state.orderSource.pos.itemKeys,
          (r, n) => ({
            ...r,
            [n]: { ...state.orderSource.pos.itemByKey[n], [type]: value },
          }),
          {}
        ),
      },
      online: {
        ...state.orderSource.online,
        itemByKey: {
          ...state.orderSource.online.itemByKey,
          ["branch-1"]: {
            ...state.orderSource.online.itemByKey["branch-1"],
            [type]: value,
          },
        },
      },
      mobile: {
        ...state.orderSource.mobile,
        itemByKey: {
          ...state.orderSource.mobile.itemByKey,
          ["branch-1"]: {
            ...state.orderSource.mobile.itemByKey["branch-1"],
            [type]: value,
          },
        },
      },
    };
  },
  SET_ERROR_ITEM: (state, { payload }) => {
    state.error = {
      ...state.error,
      item: [...payload],
    };
  },
});

export const PromotionContext = React.createContext();
