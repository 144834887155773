import React from "react";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import Criteria from "./components/Criteria";
import TableFile from "./components/TableFile";
import { StateProvider } from "./StateContext";

const FileList = ({ history, match }) => {
  return (
    <StateProvider>
      <Panel>
        <PanelHeader>จัดการไฟล์อัปโหลด</PanelHeader>
        <PanelBody>
          <Criteria />
          <TableFile />
        </PanelBody>
      </Panel>
    </StateProvider>
  );
};

export default FileList;
