import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Input, Typography } from "antd";
//import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from "react";

const Remark = ({ handleChange, show, onOk, ...props }) => {
  const { TextArea } = Input;
  const [remark, setRemak] = useState("");
  const [err, setErr] = useState(false);

  const handleChangeInput = (e) => {
    setRemak(e.target.value);
    handleChange(e);
    setErr(false);
  };

  const handleClickOk = () => {
    if (remark.trim() === "") {
      setErr(true);
    } else {
      onOk();
    }
  };

  return (
    <>
      <Modal
        okText="ไม่อนุมัติ"
        cancelText="ยกเลิก"
        width={500}
        style={{ top: 20 }}
        visible={show}
        zIndex={2000}
        onOk={handleClickOk}
        okType="danger"
        {...props}
      >
        <QuestionCircleOutlined
          className="pr-2"
          style={{ color: "#faad14", fontSize: "22px", float: "left" }}
        />
        <Typography.Title style={{ fontSize: "16px", fontWeight: "500" }}>
          ยืนยันไม่อนุมัติเอกสาร
        </Typography.Title>
        <label>หมายเหตุ</label>
        <TextArea
          name="remark"
          size="small"
          rows={4}
          onChange={handleChangeInput}
        />
        {err && <span className="text-danger">กรุณาระบุเหตุผล</span>}
        {/* <Icon type="question-circle" /> */}
      </Modal>
    </>
  );
};
export default Remark;
