import { message, Select } from "antd";
import React, { forwardRef } from "react";
import { useApi } from "../../../../../../hooks/http2";

const SelectPosition = ({
    withAll = false,
    allValue = "",
    allText = "ทั้งหมด",
    ...props
}) => {
    const { loading, fetch, error } = useApi(
        {
            url: "/setting/master/position",
        },
        []
    );

    if (error) {
        message.error(error);
    }

    return (
        <Select {...props} loading={loading}>
            {withAll && (
                <Select.Option value={allValue}>{allText}</Select.Option>
            )}
            {fetch?.data &&
                fetch?.data?.map((n, i) => (
                    <Select.Option value={n.code} key={i}>
                        {n.name}
                    </Select.Option>
                ))}
        </Select>
    );
};

export default forwardRef((props, ref) => {
    return <SelectPosition {...props} forwardRef={ref} />;
});
