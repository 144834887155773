import { createSlice } from "@reduxjs/toolkit";
import { _ } from "../../utils";
import { getThunkError } from "../../views/util/helper";
import { initFetch } from "../logic/initial-value";
import { getUserPermission } from "../thunk/user";

const initialState = {
  permission: null,
  fetch: {
    permission: { ...initFetch },
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: { ...initialState },
  reducers: {
    clearPermission: (state) => {
      state.permission = null;
      state.fetch = _.cloneDeep(initialState.fetch);
    },
  },
  extraReducers: {
    [getUserPermission.pending]: (state) => {
      state.fetch.permission = {
        ...initFetch,
        loading: true,
      };
    },
    [getUserPermission.fulfilled]: (state, { payload }) => {
      state.permission = payload;
      state.fetch.permission = { ...initFetch };
    },
    [getUserPermission.rejected]: (state, { payload }) => {
      state.fetch.permission = {
        ...initFetch,
        error: getThunkError(payload),
      };
    },
  },
});

export const actionUser = userSlice.actions;

const RequisitionReducer = userSlice.reducer;
export default RequisitionReducer;
