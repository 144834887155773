/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import { Button, Input, Table } from 'antd';
import { FaEye, FaEyeSlash, FaPen, FaPlus, FaSearch } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { AlertConfirm } from '../../../util/Alert';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  digitalProductProjectDefaultValues,
  digitalProductProjectFormSchema,
} from '../components/dto/digital-product.dto';
import lobServices from '../../../../services/lob.services';
import 'dayjs/locale/th';
import DigitalProductProjectModal from '../components/modal/DigitalProductProjectModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import digitalProductServices from '../../../../services/digital-product.services';

dayjs.locale('th');

const pageSize = 30;

const DigitalProductProjectPage = () => {
  const history = useHistory();
  const [digitalProductProjectFormOpen, setDigitalProductProjectFormOpen] =
    useState(false);
  const {
    data: lobLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();

  const digitalProductProjectForm = useForm({
    defaultValues: digitalProductProjectDefaultValues,
    resolver: digitalProductProjectFormSchema(),
  });

  const _HandleDigitalProductProjectFormOpen = (formType) => {
    digitalProductProjectForm.setValue('formType', formType);
    setDigitalProductProjectFormOpen(true);
  };

  const _HandleDigitalProductProjectFormClose = () => {
    digitalProductProjectForm.reset(digitalProductProjectDefaultValues);
    setDigitalProductProjectFormOpen(false);
  };

  const { control, watch } = useForm({
    defaultValues: {
      searchText: '',
    },
  });

  const { refetch: getDigitalProductProjects } =
    digitalProductServices.useQueryGetDigitalProductProjects(
      { take: -1, page: 1, searchText: watch('searchText') },
      (data) => {
        setData(data.data);
        setTotal(data.meta.total);
      }
    );

  const _HandleSubmit = () => {
    getDigitalProductProjects();
  };

  const { mutate: deleteDigitalProductProject } =
    digitalProductServices.useMutationDeleteDigitalProductProject(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-digital-product-projects'],
        refetchType: 'all',
      });
    });

  const { mutate: updateDigitalProductProjectDisplay } =
    digitalProductServices.useMutationUpdateDigitalProductProjectDisplay(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-digital-product-projects'],
        refetchType: 'all',
      });
    });

  const renderStatus = (status) => {
    switch (status) {
      case 'opening':
        return 'โปรโมทงาน';
      case 'submiting':
        return 'เปิดรับผลงาน';
      case 'submited':
        return 'ปิดรับผลงาน';
      case 'voting':
        return 'เปิดการโหวต';
      case 'voted':
        return 'ปิดการโหวต';
      case 'finished':
        return 'สิ้นสุดงาน';
      default:
        return '';
    }
  };

  const columns = [
    {
      title: 'ProjectID',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <span>{record.id}</span>,
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => (
        <img
          src={record.thumbnailImageUrl}
          style={{
            width: 700 / 4,
            height: 400 / 4,
            objectFit: 'contain',
          }}
          alt={record.title}
        />
      ),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <span>{record.title}</span>,
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <span>{renderStatus(record?.status)}</span>,
    },
    {
      title: 'การแสดง',
      dataIndex: 'display',
      key: 'display',
      render: (_, record) => <span>{record.display ? 'แสดง' : 'ไม่แสดง'}</span>,
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{dayjs(record.createdAt).format('DD/MMM/BBBB')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.display ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการมองเห็นคลาสเรียน');
                if (confirm)
                  updateDigitalProductProjectDisplay({
                    id: record.id,
                    display: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการมองเห็นคลาสเรียน');
                if (confirm)
                  updateDigitalProductProjectDisplay({
                    id: record.id,
                    display: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              digitalProductProjectForm.setValue('id', record.id);
              _HandleDigitalProductProjectFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข้อมูล');
              if (confirm) deleteDigitalProductProject({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Panel>
      <PanelHeader>Digital Product</PanelHeader>
      <PanelBody>
        <div style={{ display: 'flex', alignItems: 'center' }} className="mb-2">
          <Controller
            name="searchText"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="ค้นหาจากชื่อกิจกรรม"
                  className="mr-2"
                />
              );
            }}
          />
          {/* <Button
            className="bg-pv border-pv text-white mr-2"
            onClick={_HandleSubmit}
          >
            <FaSearch className="mr-2" />
            ค้นหา
          </Button> */}
          <Button
            className="bg-pv border-pv text-white"
            onClick={() => {
              _HandleDigitalProductProjectFormOpen('create');
            }}
          >
            <FaPlus className="mr-2" />
            เพิ่ม Digital Project
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={lobLists}
          style={{ backgroundColor: '#fff' }}
          rowKey="id"
          onChange={({ current }) => {
            setPage(current);
          }}
          pagination={{
            pageSize,
            total,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/digital-product/${record.id}`);
              },
            };
          }}
        />
      </PanelBody>
      <FormProvider {...digitalProductProjectForm}>
        <DigitalProductProjectModal
          open={digitalProductProjectFormOpen}
          handleClose={_HandleDigitalProductProjectFormClose}
        />
      </FormProvider>
    </Panel>
  );
};

export default DigitalProductProjectPage;
