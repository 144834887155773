export const notFoundImg =
  "https://maxler.com/local/templates/maxler/assets/img/not-found.png";

const mwHomeConstants = {
  eco_aw_page: {
    desktop_img: {
      amount_img: 1,
      width: 2000,
      height: 650,
    },
    mobile_img: {
      amount_img: 1,
      width: 375,
      height: 375,
    },
  },

  benefits_page: {
    desktop_img: {
      width: 675,
      height: 440,
    },
    mobile_img: {
      width: 334,
      height: 334,
    },
  },
};
export default mwHomeConstants;
