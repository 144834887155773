/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import * as XLSX from "xlsx-js-style";
import SelectProduct from "../../components/SelectProduct";
import SelectReason from "../../../transfer-receive/components/ManageHeader/SelectReason";
const { Option } = Select;

const Index = () => {
  const user = getUser();

  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: [user ? user.branch_code : ""],
      query: "",
      page: 1,
      limit: 20,
    },
    summaryModal: {
      visible: false,
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [productFilter, setProductFilter] = useState([]);

  const [fromWhFilter, setFromWhFilter] = useState(
    `${getUser().branch_code}-00`
  );
  const [toWhFilter, setToWhFilter] = useState("");

  const [fromWhFilterText, setFromWhFilterText] = useState(
    `${getUser().branch_name} - ของดี`
  );
  const [toWhFilterText, setToWhFilterText] = useState("ทั้งหมด");
  const [reason, setReason] = useState("");
  // const [other_reason, setOtherReason] = useState("");
  // const [bill_ref_document_no, setBillRefDocumentNo] = useState("");
  // const [detail, setDetail] = useState("");

  const toDay = moment().format("DD/MM/YYYY");
  const limit = 50;

  const reduceProductFilter = _.join(
    _.reduce(productFilter, (res, key) => [...res, key], []),
    ","
  );

  //const [filterWh, setFilterWh] = useState(`${getUser().branch_code}-00`)

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-inventory-transfer`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        productCodeFilter: reduceProductFilter,
        fromWhFilter: fromWhFilter,
        toWhFilter: toWhFilter,
        reason: reason,
        // otherReason : other_reason,
        // billRefDocumentNo : bill_ref_document_no,
        // detail : detail,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/inventory-transfer-filter`,
      token: true,
      params: {
        userId: user.id,
      },
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);

  const productOptions = [
    ..._.map(filterDataSource.dataProduct, (product) => {
      return { value: product.product_code, text: product.product_name_en };
    }),
  ];

  const whOptions = [
    ..._.map(filterDataSource.dataWhCode, (wh) => {
      return { value: wh.warehouse_code, text: wh.location };
    }),
  ];

  const exportToExcel = () => {
    let title_table = [
      "ลำดับ",
      "เลขที่ใบโอนย้าย",
      "วันที่เอกสาร",
      "รหัสสินค้า",
      "ชื่อสินค้า",
      "จำนวน (ชิ้น)",
      "โอนจากคลัง",
      "ผู้ทำการโอน",
      "คลังที่รับโอน",
      "ผู้ทำการรับ",
      "เหตุผลการโอน",
      "รายละเอียดการโอน",
      "หมายเหตุ",
      "สถานะ",
    ];

    let productName = _.map(productFilter, (code) => {
      let data = _.find(
        filterDataSource.dataProduct,
        (p) => code === p.product_code
      );
      return data?.product_name_en;
    });

    let reduceProductName = _.join(productName, ",");

    const exportData = data?.data ? data?.data : [];
    if (exportData.length !== 0) {
      let excelData = [
        ["รายงานการโอนย้ายสินค้า"],
        [
          `วันที่  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `รายการสินค้า  ${productFilter ? reduceProductName : ""}`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
        ],
        [
          `โอนจากคลัง ${fromWhFilter ? fromWhFilterText : "ทั้งหมด"}`,
          "",
          "",
          `คลังที่รับโอน ${toWhFilter ? toWhFilterText : "ทั้งหมด"}`,
          "",
        ],
        title_table,
      ];

      exportData.forEach((n) => {
        let row_data = [
          { v: n.row_id, s: { alignment: { horizontal: "center" } } },
          { v: n.document_no ? n.document_no : "" },
          {
            v: n.document_date
              ? moment(n.document_date).format("DD/MM/YYYY")
              : "",
          },
          { v: n.product_code ? n.product_code : "" },
          { v: n.product_name ? n.product_name : "" },
          {
            v: n.qty ? n.qty : "-",
            s: { alignment: { horizontal: "center" } },
          },
          { v: n.from_wh_name ? n.from_wh_name : "" },
          { v: n.transfer_user ? n.transfer_user : "" },
          { v: n.to_wh_name ? n.to_wh_name : "" },
          { v: n.receive_user ? n.receive_user : "" },
          { v: n.reason ? n.reason : "" },
          { v: n.detail ? n.detail : "" },
          { v: n.other_reason ? n.other_reason : "" },
          { v: n.status ? n.status : "" },
        ];

        excelData = [...excelData, row_data];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
        { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
      ];
      const wscols = [
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 35 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
      ];
      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานการโอนย้ายสินค้า");
      XLSX.writeFile(wb, "รายงานการโอนย้ายสินค้า.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานโอนย้ายสินค้า</PanelHeader>
        <PanelBody>
          <Row
            gutter={3}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} className="text-right pr-3 ">
              <Text strong>ช่วงวันที่ขาย</Text>
            </Col>

            <Col span={4} className="pr-4">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>

            {/*<Col span={2} align="middle">
              <Text strong>รายการสินค้า</Text>
            </Col>
            <Col span={6}>
               <Select
                className="w-100"
                mode="multiple"
                placeholder="ชื่อสินค้า"
                onChange={(e) => {
                  setProductFilter(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productOptions.map((product, i) => {
                  return (
                    <Option key={i.toString()} value={product.value}>
                      {product.text}
                    </Option>
                  );
                })}
              </Select> 
               </Col>*/}
            <Col span={2} className="text-right pr-3 ">
              <Text strong>รายการสินค้า</Text>
            </Col>
            <Col span={6}>
              <SelectProduct
                //showSearch
                mode="multiple"
                defaultValue={productFilter}
                onChange={(e) => {
                  setProductFilter(e);
                }}
              />
            </Col>
            <Col span={9}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={3}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={2} className="text-right pr-3 ">
              <Text strong>โอนจากคลัง</Text>
            </Col>

            <Col span={4} className="pr-4">
              <SelectOption
                withAll
                className="w-100"
                defaultValue={`${user.branch_code}-00`}
                options={whOptions}
                onChange={(e, v) => {
                  setFromWhFilter(e);
                  setFromWhFilterText(v.props.children);
                }}
              />
            </Col>

            <Col span={2} className="text-right pr-3 ">
              <Text strong>คลังที่รับโอน</Text>
            </Col>

            <Col span={4} className="pr-4">
              <SelectOption
                withAll
                className="w-100"
                defaultValue={""}
                options={whOptions}
                onChange={(e, v) => {
                  setToWhFilter(e);
                  setToWhFilterText(v.props.children);
                }}
              />
            </Col>

            <Col span={2} className="text-right pr-3">
              <Text strong>เหตุผลการโอน</Text>
            </Col>
            <Col span={4}>
              <SelectReason
                withAll
                value={reason}
                onChange={(e) => {
                  // setDetail("")
                  // setBillRefDocumentNo("")
                  // setOtherReason("")
                  setReason(e);
                }}
              />
            </Col>
          </Row>
          {/* <Row
            gutter={3}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pt-2"
          >
            <Col span={2} className="text-right pr-3">
              <Text strong>ระบุรายละเอียด</Text>
            </Col>
            <Col span={10}>
              <Input
                autoComplete="off"
                value={detail}
                maxLength={500}
                onChange={(e) => setDetail(e.target.value)}
              />
            </Col>
          </Row>
          {reason === "00" ? (
            <Row gutter={[8, 8]} type="flex" className="align-items-center pt-2 pb-3">
              <Col span={2} className="text-right pr-3 ">
                <Text strong>เลขที่บิล</Text>
              </Col>
              <Col span={8}>
                <Input
                  autoComplete="off"
                  value={bill_ref_document_no}
                  maxLength={50}
                  onChange={(e) => setBillRefDocumentNo(e.target.value)}
                />
              </Col>
            </Row>
          ) : (
            _.includes(["03", "04"], reason) && (
              <Row gutter={[8, 8]} type="flex" className="align-items-center pt-2 pb-3">
                <Col span={2} className="text-right pr-3">
                  <Text strong>ระบุ</Text>
                </Col>
                <Col span={8}>
                  <Input
                    autoComplete="off"
                    value={other_reason}
                    maxLength={200}
                    onChange={(e) => {
                      setOtherReason(e.target.value)}}
                  />
                </Col>
              </Row>
            )
          )} */}

          <Table
            className="pb-5"
            bordered
            size="small"
            loading={loading}
            dataSource={data ? data.data : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.data.length,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="เลขที่ใบโอนย้าย"
              dataIndex="document_no"
              align="center"
            />
            <Table.Column
              title="วันที่เอกสาร"
              dataIndex="document_date"
              align="center"
              render={(text) => moment(text).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              align="center"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name"
              align="left"
            />
            <Table.Column
              title="จำนวน (ชิ้น)"
              dataIndex="qty"
              align="center"
              render={(text) => numeral(text).format("0,0")}
            />
            <Table.Column
              title="โอนจากคลัง"
              dataIndex="from_wh_name"
              align="center"
            />
            <Table.Column
              title="ผู้ทำการโอน"
              dataIndex="transfer_user"
              align="center"
            />
            <Table.Column
              title="คลังที่รับโอน"
              dataIndex="to_wh_name"
              align="center"
            />
            <Table.Column
              title="ผู้ทำการรับ"
              dataIndex="receive_user"
              align="center"
            />
            <Table.Column
              title="เหตุผลการโอน"
              dataIndex="reason"
              align="center"
            />
            <Table.Column
              title="รายละเอียดการโอน"
              dataIndex="detail"
              align="center"
            />
            <Table.Column
              title="หมายเหตุ"
              dataIndex="other_reason"
              align="center"
            />
            <Table.Column title="สถานะ" dataIndex="status" align="center" />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
