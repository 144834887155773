import { Breadcrumb, Button, Card, Col, Form, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import TextEditor from "../../../../components/TextEditor";
import {
  actionMKTProduct,
  contentSelector,
} from "../../../../redux/slices/marketing/product";
import { axios, getToken, URL_API } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";

const tabList = [
  {
    tab: "TH",
    key: "th",
  },
  {
    tab: "EN",
    key: "en",
  },
];

export default function MarkettingProductContent() {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector(contentSelector);

  const { SET_CONTENT, LOAD_CONTENT } = actionMKTProduct;

  const handleClickSubmit = () => {
    Modal.confirm({
      title: "ยืนยัน",
      content: "ต้องการบันทึกข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          
          axios({
            method: "post",
            baseURL: URL_API,
            url: `/marketing/product/${match.params.id}`,
            headers: { Authorization: "Bearer " + getToken() },
            data: data,
          })
            .then((res) => {
              resolve("success");
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกเรียบร้อย",
              okText: "ปิด",
              onOk() {
                history.push(`/marketing/product`);
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    const loadData = (id) => {
      axios({
        method: "get",
        baseURL: URL_API,
        url: `/marketing/product/${id}`,
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          dispatch(LOAD_CONTENT(res.data.data));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    loadData(match.params.id);
  }, [match.params.id, dispatch, LOAD_CONTENT]);

  return (
    <Panel>
      <PanelHeader>รายละเอียดสินค้า</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col md={12}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">หน้าหลัก</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/marketing">การตลาด</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/marketing/product">สินค้า</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>รายละเอียดสินค้า</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={10} offset={7}>
            <Card
              tabList={tabList}
              activeTabKey={data?.tab}
              tabBarExtraContent={
                <Button type="primary" onClick={handleClickSubmit}>
                  บันทึก
                </Button>
              }
              onTabChange={(key) => dispatch(SET_CONTENT({ tab: key }))}
            >
              {data?.tab === "th" ? (
                <Form.Item label="เนื้อหา (TH)">
                  <TextEditor
                    authToken={getToken()}
                    height={200}
                    value={data?.mkt_content_local || ""}
                    onInit={(evt, event) => {}}
                    onEditorChange={(e) => {
                      dispatch(SET_CONTENT({ mkt_content_local: e }));
                    }}
                    onUploadSuccess={(json) => {
                      console.log(json);
                      // setUploadImageId((prevData) => {
                      //   return [
                      //     ...prevData,
                      //     { id: json.id, url: json.azure_url },
                      //   ];
                      // });
                    }}
                    postBody={{ container_name: "operations" }}
                    urlUpload={`${URL_API}/file/upload`}
                  />
                </Form.Item>
              ) : (
                <Form.Item label="เนื้อหา (EN)">
                  <TextEditor
                    authToken={getToken()}
                    height={200}
                    value={data?.mkt_content_en || ""}
                    onInit={(evt, event) => {}}
                    onEditorChange={(e) => {
                      dispatch(SET_CONTENT({ mkt_content_en: e }));
                    }}
                    onUploadSuccess={(json) => {
                      console.log(json);
                      // setUploadImageId((prevData) => {
                      //   return [
                      //     ...prevData,
                      //     { id: json.id, url: json.azure_url },
                      //   ];
                      // });
                    }}
                    postBody={{ container_name: "operations" }}
                    urlUpload={`${URL_API}/file/upload`}
                  />
                </Form.Item>
              )}
            </Card>
          </Col>
        </Row>
      </PanelBody>
    </Panel>
    
  );
}
