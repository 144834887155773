/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Row, Col, Button, Table, notification, Input } from "antd";
import moment from "moment";
import Search from "antd/lib/input/Search";
import Text from "antd/lib/typography/Text";
import SelectCategory from "../../components/SelectCategory";
import SelectPeriod from "../../components/SelectPeriod";
import numeral from "numeral";

const Index = () => {
  let limit = 20;
  const user = getUser();
  const initState = {
    filter: {
      date_from: moment(),
      date_to: moment(),
      branch: [user ? user.branch_code : ""],
      query: "",
      category: "",
      subCategory: "",
      group: "",
      page: 1,
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const [period, setPeriod] = useState(` , `);
  const [page, setPage] = useState(1);
  const [categoryText, setCategoryText] = useState("ทั้งหมด");
  const [subCategoryText, setSubCategorytext] = useState("ทั้งหมด");
  const [groupText, setGroupText] = useState("ทั้งหมด");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-pv-detail`,
      token: true,
      params: {
        limit: limit,
        offset: limit * (page - 1),
        userId: user.id,
        ...state.filter,
        periodFilter: period,
      },
    },
    []
  );
  const exportToExcel = () => {
    if (data?.data.length > 0) {
      const _period = period.split(",");

      let excelData = [
        ["รายงานรายละเอียด PV"],
        [
          `ค้นหา : ${state.filter.query}`,
          "",
          "",
          "",
          "",
          `รอบการจ่าย : ${moment(_period[0]).format("MMM YYYY")} (${
            _period[1]
          })`,
          "",
          "",

          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY เวลา HH:mm")}`,
        ],
        [
          `Category : ${categoryText}`,
          "",
          "",
          `SubCategory : ${subCategoryText}`,
          "",
          "",
          `Group : ${groupText}`,
          "",
          "",
        ],
        [""],
        [
          "ลำดับ",
          "รอบการรับแจง",
          "รหัสผู้รับแจง",
          "ชื่อผู้รับแจง",
          "เลขที่การแจง",
          "ประเภท",
          "รหัสสินค้า",
          "ชื่อสินค้า",
          "จำนวน",
          "ราคาต่อชิ้น",
          "PV ต่อชิ้น",
          "รวม PV",
          "สถานะการแจง",
          "เลขที่บิล",
          "วันที่บิล",
          "รหัสเจ้าของบิล",
          "ชื่อเจ้าของบิล",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            { v: n.period, s: { alignment: { horizontal: "center" } } },
            { v: n.partner_code_to, s: { alignment: { horizontal: "left" } } },
            { v: n.partner_name_to, s: { alignment: { horizontal: "left" } } },
            { v: n.transaction_no, s: { alignment: { horizontal: "left" } } },
            {
              v: n.order_type === "Regular" ? "ซื้อเข้ายอด" : "รับแจงมา",
              s: { alignment: { horizontal: "left" } },
            },
            { v: n.product_code, s: { alignment: { horizontal: "left" } } },
            { v: n.product_name_en, s: { alignment: { horizontal: "left" } } },
            {
              v: n.qty,
              t: "n",
              s: {
                numFmt: "#,##0",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.unit_price === null ? 0 : n.unit_price,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.pv_item === null ? 0 : n.pv_item,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            {
              v: n.pv === null ? 0 : n.pv,
              t: "n",
              s: {
                numFmt: "#,##0.00",
                alignment: { horizontal: "right" },
              },
            },
            { v: n.status, s: { alignment: { horizontal: "left" } } },
            { v: n.document_no, s: { alignment: { horizontal: "left" } } },
            {
              v: moment(n.document_date).format("DD/MM/YYYY"),
              s: { alignment: { horizontal: "center" } },
            },
            {
              v: n.partner_code_from === null ? "" : n.partner_code_from ,
              s: { alignment: { horizontal: "left" } },
            },
            {
              v: n.partner_name_from === null ? "" : n.partner_name_from,
              s: { alignment: { horizontal: "left" } },
            },
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 7 } },
        { s: { r: 1, c: 8 }, e: { r: 1, c: 12 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
        { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
        { s: { r: 2, c: 6 }, e: { r: 2, c: 8 } },
      ];
      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
      ];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(excelData);

      ws["!merges"] = merge;
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, `รายงานรายละเอียด PV.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายละเอียด PV</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={10}>
              <Input
                className="w-100 "
                placeholder="รหัสผู้รับแจง, ชื่อ-นามสกุล, โทรศัพท์, เลขบัตรประชาชน, เลขที่ใบเสร็จ, รหัสสินค้า, ชื่อสินค้า, เลขนิติบุคคล, email"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                // onSearch={reload}
                // onPressEnter={reload}
                allowClear
              />
            </Col>
            <Col span={2} align="right">
              <Text strong>รอบการจ่าย</Text>
            </Col>
            <Col span={4}>
              <SelectPeriod
                className="w-100"
                value={period}
                range={12} //ข้อมูลย้อนหลัง (เดือน)
                onChange={(e) => setPeriod(e)}
              />
            </Col>
            <Col span={8}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={1} align="middle">
              <Text strong>Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                withAll
                category="category"
                className="w-100"
                value={state.filter.category}
                onChange={(e, v) => {
                  dispatch({
                    type: "setFilter",
                    payload: { category: e, subCategory: "", group: "" },
                  });
                  setCategoryText(v.props.children);
                  setSubCategorytext("ทั้งหมด");
                  setGroupText("ทั้งหมด");
                }}
              />
            </Col>

            <Col span={2} align="right">
              <Text strong>Sub Category</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                withAll
                category={`subcategory?CategoryId=${state.filter.category}`}
                className="w-100"
                value={state.filter.subCategory}
                onChange={(e, v) => {
                  dispatch({
                    type: "setFilter",
                    payload: { subCategory: e, group: "" },
                  });
                  setSubCategorytext(v.props.children);
                  setGroupText("ทั้งหมด");
                }}
              />
            </Col>

            <Col span={1} align="right">
              <Text strong>Group</Text>
            </Col>
            <Col span={3}>
              <SelectCategory
                className="w-100"
                category={`group?SubCategoryId=${state.filter.subCategory}`}
                withAll
                value={state.filter.group}
                onChange={(e, v) => {
                  dispatch({
                    type: "setFilter",
                    payload: { group: e },
                  });
                  setGroupText(v.props.children);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={error ? [] :data?.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              showLessItems: false,
              total: data?.data.length,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="รอบการรับแจง"
              dataIndex="period"
              align="left"
            />
            <Table.Column
              title="รหัสผู้รับแจง"
              dataIndex="partner_code_to"
              align="left"
            />
            <Table.Column
              title="ชื่อผู้รับแจง"
              dataIndex="partner_name_to"
              align="left"
            />
            <Table.Column
              title="เลขที่การแจง"
              dataIndex="transaction_no"
              align="left"
            />
            <Table.Column
              title="ประเภท"
              dataIndex="order_type"
              render={(text) => {
                if (text === "Regular") {
                  return "ซื้อเข้ายอด";
                } else if (text === "transfer") {
                  return "รับแจงมา";
                } else {
                  return "-";
                }
              }}
              align="left"
            />
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name_en"
              align="left"
            />
            <Table.Column
              title="จำนวน"
              dataIndex="qty"
              align="left"
              render={(n) => numeral(n).format("0,0")}
            />
            <Table.Column
              title="ราคาต่อชิ้น"
              dataIndex="unit_price"
              align="left"
              render={(n) => numeral(n).format("0,0.00")}
            />
            <Table.Column
              title="PV ต่อชิ้น"
              dataIndex="pv_item"
              align="left"
              render={(n) => numeral(n).format("0,0.00")}
            />
            <Table.Column
              title="PV รวม"
              dataIndex="pv"
              align="left"
              render={(n) => numeral(n).format("0,0.00")}
            />
            <Table.Column title="สถานะการแจง" dataIndex="status" align="left" />
            <Table.Column
              title="เลขที่บิล"
              dataIndex="document_no"
              align="left"
            />
            <Table.Column
              title="วันที่บิล"
              dataIndex="document_date"
              align="left"
              render={(n) => moment(n).format("YYYY/MM/DD")}
            />
            <Table.Column
              title="รหัสเจ้าของบิล"
              dataIndex="partner_code_from"
              align="left"
            />
            <Table.Column
              title="เจ้าของบิล"
              dataIndex="partner_name_from"
              align="left"
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
