/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Empty,
  Input,
  notification,
  Row,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import XLSX from "xlsx";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SelectCategory from "../../components/SelectCategory";
import SelectMonth from "../../components/SelectMonth";
import SelectYear from "../../components/SelectYear";
// import Summary from '../components/Summary';
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import { axios } from "../../../../../utils/useAxios";
import _ from "lodash";

const { Text } = Typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [group, setGroup] = useState("");
  const [page, setPage] = useState(1);
  const [allBranch, setAllBranch] = useState([]);
  const [yearFilter, setYearFilter] = useState(moment().format("YYYY"));
  const [monthFilter, setMonthFilter] = useState([moment().format("MM")]);

  const limit = 50;
  const user = getUser();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-product-second`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        category: category,
        subCategory: subCategory,
        group: group,
        monthFilter: monthFilter,
        yearFilter: yearFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );
  let newData = data ? data : [];

  const [exportLoading, exportData, exportError, exportReload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-product-second`,
      token: true,
      params: {
        //limit,
        //offset: limit * (page - 1),
        userId: user.id,
        category: category,
        subCategory: subCategory,
        group: group,
        monthFilter: monthFilter,
        yearFilter: yearFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  async function loadBranch() {
    try {
      const response = await axios.get(`${URL_API}/boss/inventory/all-branch`);
      let branch = response.data?.data?.all_branch;
      setAllBranch(branch);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadBranch();
  }, []);

  const exportDataData = exportData ? exportData.data : [];

  const exportToExcel = () => {
    let _branch = _.find(
      allBranch,
      (b) => b.code === state.filter.branch
    )?.name;

    if (exportDataData.length !== 0) {
      let row_month = [
        "ลำดับ",
        "รหัสสินค้า",
        "ชื่อสินค้า",
        "Category",
        "SubCategory",
        "Groups",
      ];
      monthFilter.sort().forEach((month, index) => {
        row_month.push(
          moment()
            .month(parseInt(month) - 1)
            .year(yearFilter)
            .format("MMMM YYYY")
        );
        row_month.push("");
        row_month.push("");
      });
      let row_header = ["", "", "", "", "", ""];
      monthFilter.sort().forEach((month) => {
        row_header.push("สินค้าเดี่ยว");
        row_header.push("สินค้าชุดย่อย");
        row_header.push("รวม");
      });

      let excelData = [
        ["รายงานตามรหัสสินค้า (ตามเดือน)"],
        [
          `สาขา ${state.filter.branch === "" ? "ทั้งหมด" : _branch}`,
          "",
          `วันที่  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
        ],
        row_month,
        row_header,
      ];

      function numFormat(data) {
        return {
          v: data,
          t: "n",
          s: {
            numFmt: "#,##0.00",
            alignment: { horizontal: "right" },
          },
        };
      }

      function reduceData(data) {
        let exportData = [];
        if (_.includes(monthFilter, "01")) {
          exportData.push(
            data.jan_single > 0 ? numFormat(data.jan_single) : ""
          );
          exportData.push(data.jan_bom > 0 ? numFormat(data.jan_bom) : "");
          exportData.push(data.jan_total > 0 ? numFormat(data.jan_total) : "");
        }
        if (_.includes(monthFilter, "02")) {
          exportData.push(
            data.feb_single > 0 ? numFormat(data.feb_single) : ""
          );
          exportData.push(data.feb_bom > 0 ? numFormat(data.feb_bom) : "");
          exportData.push(data.feb_total > 0 ? numFormat(data.feb_total) : "");
        }
        if (_.includes(monthFilter, "03")) {
          exportData.push(
            data.mar_single > 0 ? numFormat(data.mar_single) : ""
          );
          exportData.push(data.mar_bom > 0 ? numFormat(data.mar_bom) : "");
          exportData.push(data.mar_total > 0 ? numFormat(data.mar_total) : "");
        }
        if (_.includes(monthFilter, "04")) {
          exportData.push(
            data.apr_single > 0 ? numFormat(data.apr_single) : ""
          );
          exportData.push(data.apr_bom > 0 ? numFormat(data.apr_bom) : "");
          exportData.push(data.apr_total > 0 ? numFormat(data.apr_total) : "");
        }
        if (_.includes(monthFilter, "05")) {
          exportData.push(
            data.may_single > 0 ? numFormat(data.may_single) : ""
          );
          exportData.push(data.may_bom > 0 ? numFormat(data.may_bom) : "");
          exportData.push(data.may_total > 0 ? numFormat(data.may_total) : "");
        }
        if (_.includes(monthFilter, "06")) {
          exportData.push(
            data.jun_single > 0 ? numFormat(data.jun_single) : ""
          );
          exportData.push(data.jun_bom > 0 ? numFormat(data.jun_bom) : "");
          exportData.push(data.jun_total > 0 ? numFormat(data.jun_total) : "");
        }
        if (_.includes(monthFilter, "07")) {
          exportData.push(
            data.jul_single > 0 ? numFormat(data.jul_single) : ""
          );
          exportData.push(data.jul_bom > 0 ? numFormat(data.jul_bom) : "");
          exportData.push(data.jul_total > 0 ? numFormat(data.jul_total) : "");
        }
        if (_.includes(monthFilter, "08")) {
          exportData.push(
            data.aug_single > 0 ? numFormat(data.aug_single) : ""
          );
          exportData.push(data.aug_bom > 0 ? numFormat(data.aug_bom) : "");
          exportData.push(data.aug_total > 0 ? numFormat(data.aug_total) : "");
        }
        if (_.includes(monthFilter, "09")) {
          exportData.push(
            data.sep_single > 0 ? numFormat(data.sep_single) : ""
          );
          exportData.push(data.sep_bom > 0 ? numFormat(data.sep_bom) : "");
          exportData.push(data.sep_total > 0 ? numFormat(data.sep_total) : "");
        }
        if (_.includes(monthFilter, "10")) {
          exportData.push(
            data.oct_single > 0 ? numFormat(data.oct_single) : ""
          );
          exportData.push(data.oct_bom > 0 ? numFormat(data.oct_bom) : "");
          exportData.push(data.oct_total > 0 ? numFormat(data.oct_total) : "");
        }
        if (_.includes(monthFilter, "11")) {
          exportData.push(
            data.nov_single > 0 ? numFormat(data.nov_single) : ""
          );
          exportData.push(data.nov_bom > 0 ? numFormat(data.nov_bom) : "");
          exportData.push(data.nov_total > 0 ? numFormat(data.nov_total) : "");
        }
        if (_.includes(monthFilter, "12")) {
          exportData.push(
            data.dec_single > 0 ? numFormat(data.dec_single) : ""
          );
          exportData.push(data.dec_bom > 0 ? numFormat(data.dec_bom) : "");
          exportData.push(data.dec_total > 0 ? numFormat(data.dec_total) : "");
        }

        return exportData;
      }

      function mergeCellByMonth(row, start_col) {
        let mergeData = [];
        monthFilter.forEach(() => {
          mergeData.push({
            s: { r: row, c: start_col },
            e: { r: row, c: start_col + 2 },
          });
          start_col += 3;
        });

        return mergeData;
      }

      exportDataData.forEach((data) => {
        excelData = [
          ...excelData,
          [
            exportDataData.length === data.row_id ? "" : data.row_id,
            data.product_code,
            data.product_name,
            data.category,
            data.sub_category,
            data.groups,
            ...reduceData(data),
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 6 } },
        ...mergeCellByMonth(2, 6),
      ];

      const wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานตามรหัสสินค้า (ตามเดือน)");
      XLSX.writeFile(wb, "รายงานตามรหัสสินค้า (ตามเดือน).xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานตามรหัสสินค้า (ตามเดือน)</PanelHeader>
        <PanelBody>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col span={1} align="center">
              <Text strong>ค้นหา</Text>
            </Col>
            <Col span={6}>
              <Input
                placeholder="รหัสสินค้า, ชื่อสินค้า"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  });
                }}
              />
            </Col>
            <Col span={1} align="center">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={6}>
              <SelectBranch
                userLogin={true}
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100"
                withAll={user.branch_count > 1}
                allowDisableBranch
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
            <Col span={10}>
              <Row gutter={8} type="flex" justify="end">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      reload();
                      setPage(1);
                      exportReload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            justify="start"
            className=" p-b-15 align-items-sm-center"
          >
            <Col span={1} align="center">
              <Text strong>ปี</Text>
            </Col>
            <Col span={3}>
              <SelectYear
                className="w-100"
                defaultValue={moment().format("YYYY")}
                onChange={(e) => setYearFilter(e)}
              />
            </Col>

            <Col span={1} align="center">
              <Text strong>เดือน</Text>
            </Col>
            <Col span={7}>
              <SelectMonth
                className="w-100"
                mode="multiple"
                onChange={(e) => {
                  setMonthFilter(e);
                }}
              />
            </Col>
          </Row>
          <Row
            gutter={8}
            type="flex"
            align="middle"
            justify="start"
            className=" p-b-15 align-items-sm-center"
          >
            <Col span={2} align="center">
              <Text strong>Category </Text>
            </Col>
            <Col span={3} align="left">
              <SelectCategory
                category="category"
                className="w-100"
                defaultValue=""
                withAll
                onChange={(value) => {
                  setCategory(value);
                }}
              />
            </Col>
            <Col span={2} align="center">
              <Text strong>Sub Category</Text>
            </Col>
            <Col span={3} align="left">
              <SelectCategory
                category="subcategory"
                className="w-100"
                defaultValue=""
                withAll
                onChange={(value) => {
                  setSubCategory(value);
                }}
              />
            </Col>
            <Col span={1} align="center">
              <Text strong>Group</Text>
            </Col>
            <Col span={3} align="left">
              <SelectCategory
                category="group"
                className="w-100"
                defaultValue=""
                withAll
                onChange={(value) => {
                  setGroup(value);
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={newData.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: newData.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="row_id"
              align="left"
              render={(value, source) => {
                if (source.product_code === "รวมทั้งหมด") {
                  return "";
                } else {
                  return value;
                }
              }}
            />
            <Table.Column title="รหัส" dataIndex="product_code" align="left" />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name"
              align="left"
            />
            <Table.Column title="Category" dataIndex="category" align="left" />
            <Table.Column
              title="SubCategory"
              dataIndex="sub_category"
              align="left"
            />
            <Table.Column title="Groups" dataIndex="groups" align="left" />
            {(monthFilter.includes("01") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(0).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="jan_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="jan_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="jan_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("02") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(1).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="feb_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="feb_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="feb_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("03") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(2).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="mar_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="mar_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="mar_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("04") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(3).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="apr_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="apr_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="apr_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("05") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(4).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="may_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="may_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="may_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("06") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(5).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="jun_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="jun_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="jun_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("07") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(6).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="jul_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="jul_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="jul_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("08") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(7).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="aug_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="aug_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="aug_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("09") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(8).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="sep_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="sep_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="sep_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("10") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(9).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="oct_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="oct_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="oct_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("11") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(10).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="nov_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="nov_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="nov_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}{" "}
            {(monthFilter.includes("12") || monthFilter.includes("")) && (
              <Table.ColumnGroup
                title={moment().month(11).format(`MMMM`) + " " + yearFilter}
              >
                <Table.Column
                  title="สินค้าเดี่ยว"
                  dataIndex="dec_single"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="สินค้าชุดย่อย"
                  dataIndex="dec_bom"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
                <Table.Column
                  title="รวม"
                  dataIndex="dec_total"
                  align="right"
                  render={(text) => {
                    if (text == null || text === 0) {
                      return "";
                    }
                    return numeral(text).format("0,0");
                  }}
                />
              </Table.ColumnGroup>
            )}
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
