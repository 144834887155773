import React from 'react';
import { Button, Modal, Row } from 'antd';
import { Col } from 'antdV4';

const LegacyNotificationPartnerDisplayModal = ({
  open,
  handleClose,
  partnerList,
}) => {
  return (
    <Modal
      title="รายการรหัสนักธุรกิจ"
      visible={open}
      onOk={handleClose}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          ปิด
        </Button>,
      ]}
      width={'50vw'}
    >
      <Row>
        {partnerList &&
          partnerList.length > 0 &&
          partnerList.map((item) => {
            return <Col span={3}>{item}</Col>;
          })}
      </Row>
    </Modal>
  );
};

export default LegacyNotificationPartnerDisplayModal;
