/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Button, Col, DatePicker, Input, notification, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { FaCogs, FaEye, FaPenAlt, FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import * as XLSX from "xlsx-js-style";
import { useApi } from "../../../../hooks/http2";
import {
  actionStoreQrSet,
  searchSelector
} from "../../../../redux/slices/store-qr/set";
import { getUser, moment, URL_API } from "../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import SelectBranch from "../components/SelectBranch";

const SetList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const [branch, setBranch] = useState("");

  const { SET_SEARCH } = actionStoreQrSet;

  const list_branch = useApi(
    {
      baseURL: URL_API,
      url: `/boss/inventory/all-branch`
    }
  )
  const list = useApi(
    {
      url: `${URL_API}/store-qr/set`,
      token: true,
      params: {
        ...search,
        offset: (search.page - 1) * search.limit,
        start_date: search.start_date
          ? new Date(moment(search.start_date).format("YYYY-MM-DD"))
          : null,
        end_date: search.end_date
          ? new Date(moment(search.end_date).format("YYYY-MM-DD"))
          : null,
      },
    },[search]
  );
  const AllDataSet = []
  // const AllDataSet = useApi(
  //   {
  //     url: `${URL_API}/store-qr/set`,
  //     token: true,
  //     params: {
  //       ...search,
  //       offset: 0,
  //       limit: 0,
  //       start_date: search.start_date
  //         ? new Date(moment(search.start_date).format("YYYY-MM-DD"))
  //         : null,
  //       end_date: search.end_date
  //         ? new Date(moment(search.end_date).format("YYYY-MM-DD"))
  //         : null,
  //     },
  //   }, [search]

  // );
  useEffect(() => {
    let user = getUser();
    dispatch(SET_SEARCH({ branch: user?.branch_code, page: 1 }));
    setBranch(user.branch_code);
  }, [dispatch, SET_SEARCH]);

  const exportToExcel = () => {
    console.log(list?.fetch)
    if (list?.fetch?.allData.length != 0) {

      const select_branch = list_branch.fetch?.data.all_branch.find(e => e.code == search.branch)

      let excelData = [
        ["รายงาน :", "การจัดเซ็ตสินค้า","", `วันที่ : ${moment(new Date()).format("DD/MM/YYYY HH:mm")}`],
        [
          `สาขา :`, `${search.branch == "" ? "ทั้งหมด" : select_branch.name}`,
          "",
          `รหัสสินค้า/ชื่อสินค้า/Lot./QR Code ย่อย :`,`${search.query}`,
        ],
        [
          `ช่วงสินค้าหมดอายุ จากวันที่ : `,
          `${search.start_date ? moment(search.start_date).format("DD/MM/YYYY") : "-"}`,
          `ช่วงสินค้าหมดอายุ ถึงวันที่ :`,
          ` ${search.end_date ? moment(search.end_date).format("DD/MM/YYYY") : "-"}`
        ],
        [],
        [{ v: "No.", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "รหัสสินค้า", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "ชื่อชุดเซ็ต", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "Running หลัก", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "วันหมดอายุสินค้าเร็วสุด", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "สาขา", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "ผู้เก็บข้อมูล", t: "s", s: { alignment: { horizontal: "center" } } },
        { v: "วันที่เก็บข้อมูล", t: "s", s: { alignment: { horizontal: "center" } } },
        ],
      ];
      let i = 1
      const data = list.fetch.allData
      data.forEach((n) => {
        excelData = [...excelData,
        [{ v: i++, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.product_code, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.product_name, t: "s", s: { alignment: { horizontal: "left" } } },
        { v: n.main_running, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.next_product_expiration, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.branch_name, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: n.update_by, t: "s", s: { alignment: { horizontal: "center" } } },
        { v: moment(n.create_date).format("DD/MM/YYYY"), t: "s", s: { alignment: { horizontal: "center" } } },
        ]];
      });

      // const merge = [
      //   { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
      // ];

      var wscols = [{ wch: 20 }, { wch: 15 }, { wch: 35 }, { wch: 20 }, { wch: 35 }, { wch: 15 }, { wch: 20 }, { wch: 20 },];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      //ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ข้อมูลเซ็ตสินค้า");
      XLSX.writeFile(wb, `ข้อมูลเซ็ตสินค้า.xlsx`);
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  return (
    <Panel>
      <PanelHeader>จัดเซ็ตสินค้า</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-3">
          <Col md={6}>
            <Input.Search
              placeholder="รหัสสินค้า, ชื่อสินค้า, Lot, QR Code ย่อย"
              defaultValue={search.query}
              onSearch={(value) => dispatch(SET_SEARCH({ query: value, page: 1 }))}
              allowClear
            />
          </Col>
          <Col md={6}>
            <SelectBranch
              className="w-100"
              withAll
              allText="สาขาทั้งหมด"
              placeholder="สาขา"
              value={search.branch}
              onChange={(value) =>
                dispatch(SET_SEARCH({ branch: value, page: 1 }))
              }
            />
          </Col>
          <Col md={4} push={8}>
            <Button
              type="primary"
              block
              onClick={() => history.push(`${match.path}/0`)}
            >
              เพิ่มข้อมูล
            </Button>
          </Col>
        </Row>
        <Row gutter={16} className="mb-3">
          <Col md={6}>
            <DatePicker
              className="w-100"
              placeholder="หมดอายุเริ่มต้น"
              format="DD/MM/YYYY"
              value={search.start_date}
              onChange={(value) => dispatch(SET_SEARCH({ start_date: value, page: 1 }))}
            />
          </Col>
          <Col md={6}>
            <DatePicker
              className="w-100"
              placeholder="หมดอายุสิ้นสุด"
              format="DD/MM/YYYY"
              value={search.end_date}
              onChange={(value) => dispatch(SET_SEARCH({ end_date: value, page: 1 }))}
            />
          </Col>
          <Col md={4} push={8}>
            <Button
              type="primary"
              icon="file-excel"
              style={{width:"100%"}}
              onClick={() => exportToExcel()}
            >
              Export Excel
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          loading={list.loading}
          dataSource={list.fetch?.data || []}
          pagination={{
            size: "default",
            current: search.page,
            pageSize: search.limit,
            total: list.fetch?.total || 0,
            showLessItems: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
            onChange: (p) => dispatch(SET_SEARCH({ page: p })),
          }}
          rowKey="row_id"
        >
          <Table.Column
            title="No."
            align="right"
            key="no"
            //render={(text, record, index) => index + 1}
            dataIndex="row_id"
          />
          <Table.Column
            title="รหัสสินค้า"
            align="center"
            dataIndex="product_code"
          />
          <Table.Column title="ชื่อชุดเซ็ต" dataIndex="product_name" />
          <Table.Column
            title="Running หลัก"
            dataIndex="main_running"
            align="center"
          />
          <Table.Column title="วันหมดอายุสินค้าเร็วที่สุด"
            dataIndex="next_product_expiration"
            align="center"
          />
          <Table.Column title="สาขา"
            dataIndex="branch_name"
            align="center" />
          <Table.Column
            title="ผู้เก็บข้อมูล"
            dataIndex="create_by"
            align="center"
          />
          <Table.Column
            title="วันที่เก็บข้อมูล"
            align="center"
            dataIndex="document_date"
            render={(text) => moment(text).format("DD/MM/YYYY")}
          />
           <Table.Column
            title="สถานะ"
            align="center"
            dataIndex="status"
          />
          <Table.Column
            title="พร้อมใช้"
            align="center"
            dataIndex="useable"
            render={(text,record) => 
              (record.useable == true ? 
                <FaRegCheckCircle className="fa-lg" color="#97d700"/>
                :<FaRegTimesCircle className="fa-lg" color="red"/> 
              )}
          />
          <Table.Column
            title={<FaCogs />}
            align="right"
            render={(text, record) => (
              <>
                {((record.branch_code == branch && record.useable) || 
                  (record.branch_code == branch && record.useable == false && record.status == 'draft')
                  )
                && 
                (
                  <Button
                    type="default"
                    size="small"
                    shape="circle-outline"
                    className="mr-2"
                    onClick={() => history.push(`${match.path}/${record.id}/edit`)}
                  >
                    <FaPenAlt />
                  </Button>
                )}

                <Button
                  type="primary"
                  size="small"
                  shape="circle-outline"
                  onClick={() =>
                    history.push(`${match.path}/${record.id}/view`)
                  }
                >
                  <FaEye />
                </Button>
              </>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default SetList;
