import React, { useEffect, useState } from "react";
import styles from '../../../../../scss/module/legal.module.scss';
import { useQuery } from "../../../../../hooks/dom";
import { useApi } from "../../../../../hooks/http2";
import { URL_API } from "../../../../../utils";
import LegalPanel from "../../components/LegalPanel";
import ProfileMenu from "../components/ProfileMenu";
import { Skeleton } from "antd";
import MainData from "./MainData";


const Index = () =>{
    const query = useQuery();
    const id = parseInt(query.get("id"));
    const type = query.get('membertype');
    const [loading, setLoading] = useState(true);

    const married = useApi(
        {
          url: `${URL_API}/legal/profile/married`,
          token: true,
          params: {
            id,
            member_type: type,
          },
        },
        []
      );
  
      useEffect(() => {
        if (married?.fetch?.data != null) {
          //dispatch(SET_ADDRESS({ ...list?.fetch?.data}));
          setLoading(false);
        }
      }, [married.fetch]);


      return (
        <LegalPanel>
          <div className={styles.profile}>
            <ProfileMenu />
            {loading ? (
              <Skeleton active className={styles.skeleton} />
            ) : (
              <>
               <MainData data={married?.fetch?.data}></MainData>
              </>
            )}
          </div>
        </LegalPanel>
      );
}
export default Index