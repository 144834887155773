import React from "react";
import { Panel, PanelBody, PanelHeader } from "../../../util/panel";
import TableFile from "./components/TableFile";
import HandelFile from "./components/HandelFile"

import { StateProvider } from "./StateContext";

const FileList = ({ history, match }) => {
  return (
    <StateProvider>
      <Panel>
        <PanelHeader>Import Coin</PanelHeader>
        <PanelBody>
          <HandelFile/>
          <TableFile />
        </PanelBody>
      </Panel>
    </StateProvider>
  );
};

export default FileList;
