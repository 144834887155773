import React, { useState, useEffect } from 'react'
import SelectOption from './SelectOption'
import { useHttp } from '../../../../hooks/http';
import { URL_API } from '../../../../utils';

const SelectPayIn = (props) => {
    const [options, setOptions] = useState([]);
    const [loading, data, error] = useHttp({
        url: `${URL_API}/pos/master/payin`
    })

    useEffect(() => {
        let newData = [];
        if (data && data.data) {
            newData = data.data.map(n => ({ value: n.code, text: n.name }))
        }
        setOptions(newData);
    }, [data])

    if (error) {
        return <div>{error}</div>
    }

    return (
        <SelectOption
            loading={loading}
            options={options}
            {...props}
        />
    )
}

export default SelectPayIn;
