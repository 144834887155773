/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  message,
  Row,
  Select,
  Skeleton,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import { getToken, URL_API } from "../../../../../utils";
import ShowFile from "./ShowFile";
import UploadHistory from "./UploadHistory";

const MemberFile = ({ id, dataImage, loadingImage, reloadImge }) => {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [img, setImg] = useState([]);

  const onChangePicture = async (file) => {
    if (file.file.status !== "uploading") {
      //console.log(file.file, file.fileList);
    }
    if (file.file.status === "done") {
      if (!file.file.url && !file.file.preview) {
        file.preview = await getBase64(file.file.originFileObj);
      }
      setImg([
        ...img,
        {
          files: file.preview,
          type: activeTab,
          id_img: file?.file?.response?.id,
        },
      ]);
    } else if (file.file.status === "error") {
      message.error(`${file.file.name} file upload failed.`);
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 12;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const previewImage = img.filter((i) => i.type === activeTab); //image when upload
  const getImage = dataImage?.data.filter((i) => i.document_type === activeTab); //image get for db

  return (
    <>
      <ul className="full-tabs" style={{ justifyContent: "space-between" }}>
        <li className="active mr-4">
          <Typography.Title level={4}>
            <a style={{ fontSize: "13px" }}>อัพโหลดเอกสาร</a>
          </Typography.Title>
        </li>
        <Button
          size="small"
          className="m-1 d-flex align-items-center"
          icon="history"
          shape="round"
          onClick={() => setShow(true)}
        >
          History
        </Button>
      </ul>
      <div className="container shadow-sm p-15 mb-4 bg-white rounded">
        {loadingImage ? (
          <Skeleton active />
        ) : (
          <Row>
            <Col className="d-flex justify-content-between m-3">
              <Select
                defaultValue={activeTab}
                size="small"
                className="w-25"
                onChange={(value) => setActiveTab(value)}
              >
                <Select.Option value="1">ใบสมัคร</Select.Option>
                <Select.Option value="2">สำเนาบัตรประชาชน</Select.Option>
              </Select>
              {dataImage?.data.filter((i) => i.document_type === activeTab)
                .length <= 0 && (
                <Upload
                  showUploadList={false}
                  name="file"
                  action={`${URL_API}/pos/vip-member/${id}/image?type=${activeTab}`}
                  accept=".jpg,.png"
                  headers={{
                    Authorization: "Bearer " + getToken(),
                  }}
                  onChange={onChangePicture}
                  beforeUpload={beforeUpload}
                >
                  <Button
                    type="primary"
                    size="small"
                    className="m-1 d-flex align-items-center"
                    icon="upload"
                    shape="round"
                  >
                    Upload
                  </Button>
                </Upload>
              )}
            </Col>
            <Col>
              <Row>
                <Col className="text-center">
                  {dataImage?.data.filter(
                    (i) => i.document_type === activeTab
                  ) && (
                    <ShowFile
                      setImg={setImg} //clear baes64 for uploading
                      src={previewImage[0]?.files} //image for preview
                      id_img={previewImage[0]?.id_img} //send id image for preview
                      filter={getImage} //get image for table to show previwe
                      reloadImge={reloadImge} //reload image data agine
                      activeTab={activeTab} //when finished reloading, stay on same page
                      setActiveTab={setActiveTab} //when finished reloading, stay on same page
                      member_id={id}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      <UploadHistory
        id={id}
        show={show}
        onCancel={() => setShow(false)}
        reloadImge={reloadImge}
      />
    </>
  );
};
export default MemberFile;
