/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PanelSource from './PanelSource'
import { useSelector, useDispatch } from 'react-redux'
import { proGiveAwaySelector } from '../../../../../../redux/slices/promotion'

export default function PromotionSource() {

    const dispatch = useDispatch()
    const product_list = useSelector(proGiveAwaySelector)
    const [dataSource, setDataSource] = useState([])
    const items = product_list.source_list.itemByKey

    useEffect(() => {
        let data = []
        for (const property in items) {
            data = [...data, { ...items[property] }]
        }
        setDataSource(data)
    }, [items])
    console.log(dataSource);

    return (
        <>
            <table className="table table-bordered mb-5">
                <thead>
                    <tr style={{ backgroundColor: '#bcbcbc' }}>
                        <th colSpan={6} className="text-center">รายการสินค้าสำหรับเลือกภายหลัง</th>
                    </tr>
                    <tr className="text-center">
                        <th width="5%">ลำดับ</th>
                        <th width="20%">รหัสสินค้า</th>
                        <th>ชื่อสินค้า</th>
                        <th>ราคา</th>
                        <th>จำนวน</th>
                        <th width="10%"><i className="fa fa-cogs" /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <td colSpan={6}>-- ไม่มีรายการสินค้า --</td>
                    </tr>
                </tbody>
            </table>
            <div className="p-10">
                {
                    dataSource.map((item, i) => (
                        <PanelSource key={i} data={item} index={i}/>
                    ))
                }
            </div>

        </>
    )
}
