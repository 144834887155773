import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../../util/panel";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import ModalDefault from "../../../../util/ModalDefault";
import { AlertConfirm, AlertSuccess, AlertError } from "../../../../util/Alert";
import { actionMenu } from "../../../../../redux/actions/menu-action";
import { connect } from "react-redux";
import ButtonSave from "../../../../util/button/ButtonSave";
import styled from "styled-components";
import Checkbox from "../../../../util/input-validation/Checkbox";
import Textbox from "../../../../util/input-validation/Textbox";
import { URL_API, getToken } from "../../../../../utils";
const dataIcon = [
  { name: "fas fa-address-book" },
  { name: "fas fa-address-card" },
  { name: "fas fa-adjust" },
  { name: "fa fa-cogs" },
  { name: "far fa-address-card" },
  { name: "fas fa-truck" },
  { name: "fas fa-file-invoice" },
  { name: "far fa-file-alt" },
  { name: "fas fa-balance-scale" },
  { name: "fas fa-exchange-alt" },
  { name: "fas fa-arrow-down" },
  { name: "fas fa-th-large" },
  { name: "fas fa-sliders-h" },
  { name: "fas fa-percent" },
  { name: "fas fa-warehouse" },
  { name: "fas fa-paste" },
  { name: "fas fa-archive" },
  { name: "fas fa-cart-arrow-down" },
  { name: "fas fa-newspaper" },
  { name: "fas fa-running" },
  { name: "fab fa-sistrix" }
];
const WrapTable = styled.div`
  width: 100%;
  .tableFixHead {
    overflow-y: auto;
    height: 180px;
    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  // th, td { padding: 8px 16px; }
  th {
    background: white;
  }
`;
const WrapModuleList = styled.div`
  .nav-add {
    span {
      &:hover {
        color: #00acac;
      }
    }
    .active {
      color: #00acac;
    }
  }
  .nav-edit {
    span {
      &:hover {
        color: #00acac;
      }
    }
    .active {
      color: #00acac;
    }
  }
  .row-nav {
    &:hover {
      border: 1px solid #f1f1f1;
      border-radius: 3px;
    }
  }
  .row-nav-active {
    border: 1px solid #f1f1f1;
    border-radius: 3px;
  }
`;

export class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Master Data
      masterModules: [],
      masterRoles: [],

      // check
      status: "",
      selectIcon: false,
      activeMenu: null,

      // data
      module: {
        id: 0,
        code: "",
        name: "",
        parent_id: 0,
        seq: 0,
        icon: "",
        path: "",
        roles: [],
        orders: [],
      },
      errors: {},
    };
  }
  componentDidMount() {
    this._modules();
    this._roles(0, 100);
  }
  _modules = async () => {
    axios({
      method: "GET",
      baseURL: URL_API,
      url: "/system/modules/menus",
      data: null,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then(async (res) => {
        const { data } = res.data;
        const masterModules = data;
        this.setState({ masterModules });
      })
      .catch((err) => { });
  };
  _module = async (id) => {
    let module = null;
    await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/system/modules/${id}`,
      data: null,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then(async (res) => {
        module = res.data.data;
      })
      .catch((err) => { });
    return module;
  };
  _roles = async (offset, limit) => {
    await axios({
      method: "GET",
      baseURL: URL_API,
      url: "/system/roles?offset=" + offset + "&limit=" + limit,
      data: this.state,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        this.setState({ masterRoles: res.data.data });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };
  _rolesByparent_id = async (parent_id) => {
    let result = [];

    await axios({
      method: "GET",
      baseURL: URL_API,
      url: `/system/modules/seq?parent_id=${parent_id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        console.log("res", res);
        result = res.data.data;
      })
      .catch((err) => {
        console.log("err", err.response);
      });
    return result;
  };
  _menus = async () => {
    // โหลด เมนูใหม่เมื่อมีการบันทึก (update sidebar-nav)
    this.props.loadMenu();
  };
  _save = async (data, id = 0) => {
    let result = false;
    await axios({
      method: "POST",
      baseURL: URL_API,
      url: `/system/modules/${id !== 0 ? id : ""}`, // 0 = new , !0 = update
      data: data,
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then(async (res) => {
        result = true;
        // await AlertSuccess();
        // await this._menus();

        // if (id === 0) {  // NEW
        //     await this.actionModule('NEW', data.parent_id, data.id); // อัพเดทข้อมูลใหม่ (โหลด Sequenc ใหม่)
        //     this.setState((state, props) => {
        //         const module = {
        //             ...state.module,
        //             id: 0,
        //             name: '',
        //             code: '',
        //             // parent_id: 0,
        //             icon: '',
        //             // seq: 0,
        //             roles: []
        //         }
        //         return {
        //             module,
        //         }
        //     })
        // } else { // UPDATE
        //     await this.resetData();
        // }
        // await this._modules();
      })
      .catch((err) => {
        console.log("save error", err);
        AlertError({ title: "บันทึกข้อมูลไม่สำเร็จ", text: err });
      });

    return result;
  };
  _delete = async (id, name) => {
    const confirm = await AlertConfirm(
      "ยืนยันการลบข้อมูล",
      `คุณต้องการลบข้อมูล ${name} หรือไม่ ?`
    );
    if (confirm) {
      await axios({
        method: "POST",
        baseURL: URL_API,
        url: `/system/modules/${id}/delete`,
        data: null,
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then(async (res) => {
          console.log("delete", res);
          await AlertSuccess();
          await this.resetData();
          await this._modules();
          await this._menus();
        })
        .catch((err) => {
          console.log("delete err", err);
        });
    }
  };

  selectRole = async (e) => {
    const { value } = e.target;
    const { module, masterRoles } = this.state;
    const { roles } = module;
    let newData = roles;
    if (value === "All") {
      // เลือกทั้งหมด
      const checkAll = roles.length === masterRoles.length;
      newData = [];
      if (!checkAll) {
        // ถ้าไม่เลือกทั้งหมด  ให้เลือกทั้งหมด
        await masterRoles.forEach((item, i) => {
          newData.push(item.id);
        });
      }
    } else {
      const isData = roles.includes(Number(value));
      if (isData) {
        newData = await roles.filter((id) => {
          return id !== Number(value);
        }); // ลบข้อมูล
      } else {
        newData.push(Number(value));
      }
    }
    await this.setState((state, props) => {
      const module = { ...state.module, roles: newData };
      return {
        module,
      };
    });
  };
  actionModule = async (status, parent_id, id) => {
    this.setState({ status, activeMenu: id });

    if (status === "NEW") {
      const orders = await this._rolesByparent_id(parent_id);
      console.log("parent", parent_id, "orders", orders);
      this.setState((state, props) => {
        const module = {
          ...state.module,
          id: 0,
          name: "",
          code: "",
          parent_id: parent_id,
          icon: "",
          seq: orders[orders.length - 1],
          roles: [],
          orders,
        };
        return {
          module,
        };
      });
    } else {
      // UPDATE
      const result = await this._module(id);
      if (result) {
        this.setState((state, props) => {
          const module = {
            ...state.module,
            id: result.id || 0,
            name: result.name || "",
            code: result.code || "",
            parent_id: result.parent_id || 0,
            icon: result.icon || "",
            seq: result.seq || 0,
            roles: result.roles || [],
            orders: result.orders || [],
          };
          return {
            module,
          };
        });
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { errors } = this.state;
    // clear error
    delete errors[name];
    this.setState((state, props) => {
      const module = { ...state.module, [name]: value };
      return {
        module,
        errors,
      };
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const { module, status, errors } = this.state;
    const { id, code, name, parent_id, icon, seq, roles } = module;
    const textInvalid = "กรุณากรอกข้อมูล";
    const data = {
      code,
      name,
      parent_id,
      icon,
      seq,
      roles,
    };

    // เช็คค่าว่าง
    for (let name in data) {
      if (data[name] === "") errors[name] = textInvalid;
    }

    this.setState({ errors });
    for (let name in data) {
      if (name === "code" || name === "name") {
        if (errors[name]) return;
      }
    }

    const title = "ยืนยันการบันทึกข้อมูล";
    const text = `คุณต้องการบันทึกข้อมูล ${data.name} หรือไม่ ?`;
    const confirm = await AlertConfirm(title, text);
    if (confirm) {
      let result = false;
      if (status === "NEW") {
        result = await this._save(data);
      } else {
        // UPDATE
        result = await this._save(data, id);
      }

      if (result) {
        await AlertSuccess();
        await this._menus();

        if (id === 0) {
          // NEW
          await this.actionModule("NEW", data.parent_id, data.id); // อัพเดทข้อมูลใหม่ (โหลด Sequenc ใหม่)
          this.setState((state, props) => {
            const module = {
              ...state.module,
              id: 0,
              name: "",
              code: "",
              icon: "",
              roles: [],
            };
            return {
              module,
            };
          });
        } else {
          // UPDATE
          await this.resetData();
        }
        await this._modules();
      }
    }
  };
  resetData = () => {
    this.setState((state, props) => {
      const module = {
        ...state.module,
        id: 0,
        name: "",
        code: "",
        parent_id: 0,
        icon: "",
        seq: 0,
        roles: [],
        orders: [],
      };
      return {
        module,
        status: "",
        selectIcon: false,
        activeMenu: null,
      };
    });
  };
  render() {
    const {
      selectIcon,
      masterModules,
      masterRoles,
      status,
      activeMenu,
      module,
      errors,
    } = this.state;
    const { id, name, code, parent_id, icon, seq, roles, orders } = module;
    const selectModule =
      id === 0 && parent_id === 0 && status === "NEW" && activeMenu === 0;
    const showIcon =
      (id !== 0 && parent_id === 0 && status === "UPDATE") || // แก้ไข menu lv.1
      selectModule; // new module lv.0
    return (
      <>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Active Page</li>
        </ol>
        <h1 className="page-header">Module </h1>

        <div className="row">
          <div className="col-4">
            <Panel>
              <PanelHeader noButton={true}>Module</PanelHeader>
              <PanelBody>
                <PerfectScrollbar
                  style={{ height: 520 }}
                  options={{ suppressScrollX: true }}
                >
                  <WrapModuleList>
                    <ul
                      style={{ listStyleType: "none", paddingInlineStart: 0 }}
                    >
                      <li>
                        <div
                          className={`${selectModule ? "row-nav-active" : "row-nav"
                            } nav-add mr-1"`}
                        >
                          <span
                            className={`${activeMenu === 0 && status === "NEW"
                                ? "active"
                                : ""
                              } btn btn-sm w-100 text-left`}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.actionModule("NEW", 0, 0)}
                          >
                            Module
                          </span>
                        </div>

                        <ul style={{ listStyleType: "none" }}>
                          {masterModules.map((item, i) => {
                            return (
                              <ModuleList
                                key={i}
                                data={item}
                                activeMenu={activeMenu}
                                status={status}
                                handleClick={(type, parent_id, id) => {
                                  this.actionModule(type, parent_id, id);
                                }}
                              />
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </WrapModuleList>
                </PerfectScrollbar>
              </PanelBody>
            </Panel>
          </div>
          <div className="col-8">
            <Panel>
              <PanelHeader noButton={true}>
                {status === "NEW" ? "เพิ่มข้อมูล" : "อัพเดทข้อมูล"}
              </PanelHeader>
              <PanelBody>
                <div style={{ minHeight: 520, maxHeight: 520 }}>
                  <form
                    onSubmit={this.handleSubmit}
                    className="needs-validation"
                  >
                    <div className="row" style={{ minHeight: 95 }}>
                      {showIcon ? ( // แสดงไอค่อน * เลือก module, update main_menu
                        <div className="form-group col-4">
                          <label>Icon</label>
                          <div className="fa-3x">
                            <span
                              onClick={() =>
                                this.setState({ selectIcon: true })
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className={icon !== "" ? icon : "fas fa-upload"}
                              />
                            </span>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-8"></div>
                    </div>

                    <div className="row">
                      <div className="form-group col">
                        <label>Process</label>
                        <Textbox
                          name="code"
                          value={code}
                          validate={errors.code}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group col">
                        <label>ชื่อเมนู</label>
                        <Textbox
                          name="name"
                          value={name}
                          validate={errors.name}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col">
                        <label>Sequence</label>
                        <select
                          name="seq"
                          onChange={this.handleChange}
                          value={seq}
                          className="form-control form-control-sm"
                        >
                          {orders.map((item, i) => {
                            return (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <TableRole
                      masterRoles={masterRoles}
                      roles={roles}
                      handleChange={(e) => this.selectRole(e)}
                    />

                    <div className="row mt-3">
                      <div className="col text-right mt-2 mb-1">
                        {status === "UPDATE" ? ( // ไม่ใช่ค่าเริ่มต้น และ ไม่ใช่ตอนเลือก module
                          <button
                            type="button"
                            className="btn btn-sm btn-danger mr-1"
                            onClick={() => this._delete(id, name)}
                          >
                            <i className="fa fa-trash-alt" />
                          </button>
                        ) : null}
                        {status !== "" ? <ButtonSave type="submit" /> : null}
                      </div>
                    </div>
                  </form>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>

        <ModalDefault
          title="Icon"
          show={selectIcon}
          close={() => {
            this.setState({ selectIcon: false });
          }}
          content={
            <>
              <PerfectScrollbar
                style={{ height: "250px" }}
                options={{ suppressScrollX: true }}
              >
                <div className="row">
                  {dataIcon.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="col-2 d-flax justify-content-center mt-2"
                      >
                        <button
                          className="btn btn-sm btn-default"
                          onClick={() => {
                            this.setState((state, props) => {
                              const module = {
                                ...state.module,
                                icon: item.name,
                              };
                              return {
                                module,
                                selectIcon: false,
                              };
                            });
                          }}
                        >
                          <i className={item.name} style={{ fontSize: 16 }} />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </PerfectScrollbar>
            </>
          }
        />
      </>
    );
  }
}

const TableRole = (props) => {
  const { title = "Role", masterRoles, roles, handleChange } = props;
  return (
    <>
      <div className="row mt-1 mb-1">
        <div className="col">{title}</div>
        {/* <div className="col align-items-end">
                    <InputSearch />
                </div> */}
      </div>
      <div className="row">
        <WrapTable>
          <div className="table-responsive col tableFixHead">
            <table className="table table-td-valign-middle table-th-valign-middle m-b-0">
              <thead>
                <tr>
                  <th className="with-checkbox ">
                    <Checkbox
                      name="roles"
                      value="All"
                      id="RoleCheckAll"
                      onChange={(e) => handleChange(e)}
                      checked={roles.length === masterRoles.length}
                    />
                  </th>
                  <th>ชื่อ</th>
                  <th>จำนวนผู้ใช้งาน</th>
                </tr>
              </thead>
              <tbody>
                {masterRoles.map((item, i) => {
                  const { id, name, users } = item;
                  const iScheck = roles.includes(id);

                  return (
                    <tr key={i}>
                      <td className="with-checkbox" width="10%">
                        <Checkbox
                          name="roles"
                          value={id}
                          id={`RoleCheck${id}`}
                          onChange={(e) => handleChange(e)}
                          checked={iScheck}
                        />
                      </td>
                      <td>{name}</td>
                      <td>{users}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </WrapTable>
      </div>
    </>
  );
};
class ModuleList extends Component {
  action = (type, parent_id, id) => {
    this.props.handleClick(type, parent_id, id);
  };
  render() {
    const { data, status, activeMenu, handleClick } = this.props;
    const { id, children, title, parent_id, icon } = data;

    var _update = (
      <span
        onClick={() => {
          handleClick("UPDATE", parent_id, id);
        }}
        style={{ cursor: "pointer" }}
        className={`${activeMenu === id && status === "UPDATE" ? "active" : ""
          } pull-right text-right btn btn-sm `}
      >
        <i className="fas fa-pencil-alt" />
      </span>
    );

    var _title = (
      <span
        style={{ cursor: "pointer", fontSize: 12, fontWeight: "normal" }}
        className={`${activeMenu === id && status === "NEW" ? "active" : ""
          } btn btn-sm w-100 text-left d-flex align-items-center`}
        onClick={() => {
          handleClick("NEW", id, id);
        }}
      >
        {icon !== "" ? (
          <i style={{ fontSize: 14 }} className={`${icon} mr-1`} />
        ) : (
          <span
            style={{
              width: 6,
              height: 6,
              border: "1px solid",
              borderRadius: 4,
            }}
            className="mr-1"
          />
        )}
        {title}
      </span>
    );
    return (
      <>
        <li style={{ listStyleType: "none" }}>
          <div
            className={`${id === activeMenu ? "row-nav-active" : "row-nav"
              }  d-flex row mr-1 ${title === "สินค้า" ? "" : ""}`}
          >
            <div style={{ width: "90%" }} className="nav-add">
              {_title}
            </div>
            <div style={{ width: "10%" }} className="nav-edit">
              {" "}
              {id === activeMenu ? _update : null}
            </div>
          </div>

          {children && (
            <ul className={``}>
              {children &&
                children.map((submenu, i) => (
                  <ModuleList
                    handleClick={(type, parent_id, id) => {
                      this.action(type, parent_id, id);
                    }}
                    key={i}
                    data={submenu}
                    activeMenu={activeMenu}
                    status={status}
                  />
                ))}
            </ul>
          )}
        </li>
      </>
    );
  }
}

export default connect(null, { loadMenu: actionMenu.loadMenu })(Module);
