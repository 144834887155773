/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Table
} from "antd";
import React, { useEffect } from "react";
import { FaCogs, FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHttp } from "../../../../../../hooks/http";
import {
  searchSelector,
  settingMasterAction
} from "../../../../../../redux/slices/setting-master";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";

const limit = 10;

const Index = ({ match, history }) => {
  const reduxDispatch = useDispatch();
  const { setSearch, clear } = settingMasterAction;
  const search = useSelector(searchSelector);
  const { query, page, status } = search;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/setting/master/supplier`,
      token: true,
      params: {
        query: query,
        limit,
        offset: (page - 1) * limit,
        status,
      },
    },
    [page, status, query]
  );

  useEffect(() => {
    return () =>
      !_.split(history.location.pathname, "/setting/master/voucher/")[1] &&
      reduxDispatch(clear());
  }, []);

  if (error) return <Alert type="error" message={error} />;

  const handleChangeStatus = (value, id, name) => {
    const source = axios.CancelToken.source();
    const action_text = value ? "เปิด" : "ปิด";
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: `คุณต้องการ${action_text}ใช้งานผู้ผลิต ${name} หรือไม่`,
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            baseURL: URL_API,
            url: `/setting/master/change-status`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              id,
              status: value ? "active" : "inactive",
              type: "supplier",
            },
            cancelToken: source.token,
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: `${action_text}การใช้งานผู้ผลิตเรียบร้อย`,
              });
              reload();
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {
        source.cancel();
      },
    });
  };

  return (
    <Panel>
      <PanelHeader>ผู้ผลิต</PanelHeader>
      <PanelBody>
        <Row gutter={16} className="mb-2">
          <Col md={20}>
            <Input.Search
              allowClear
              placeholder="รหัส/ชื่อผู้ผลิต/หมายเลขจดทะเบียน/หมายเลขภาษี/ที่อยู่"
              onChange={(e) =>
                reduxDispatch(setSearch({ query: e.target.value, page: 1 }))
              }
            />
          </Col>
          <Col md={4}>
            <Select
              className="w-100"
              value={status}
              onChange={(value) => {
                reduxDispatch(setSearch({ status: value, page: 1 }));
              }}
            >
              <Select.Option value="active">ใช้งาน</Select.Option>
              <Select.Option value="inactive">ไม่ใช้งาน</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={16} className="mb-2">
          <Col>
            <Link to={`${match.path}/0`}>
              <Button type="primary">เพิ่มผู้ผลิต</Button>
            </Link>
          </Col>
        </Row>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={data ? data.data : []}
          rowKey="row_id"
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: limit,
            total: data?.total,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showLessItems: true,
            onChange: (currPage) =>
              reduxDispatch(setSearch({ page: currPage })),
          }}
        >
          <Table.Column
            key="row_id"
            dataIndex="row_id"
            title="#"
            align="center"
            width={80}
          />
          <Table.Column
            key="code"
            title="รหัส"
            dataIndex="code"
            align="center"
            width={100}
          />
          <Table.Column
            key="name"
            dataIndex="name"
            className="position-relative min-width-150 max-width-600"
            title={<div className="ant-table-absolute-title-center">ชื่อ</div>}
          />
          <Table.Column
            key="register_no"
            dataIndex="register_no"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">
                หมายเลขจดทะเบียน
              </div>
            }
            width={150}
          />
          <Table.Column
            key="tax_no"
            dataIndex="tax_no"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">หมายเลขภาษี</div>
            }
            width={150}
          />
          <Table.Column
            key="sub_district"
            dataIndex="sub_district"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">ตำบล/แขวง</div>
            }
            width={150}
          />
          <Table.Column
            key="district"
            dataIndex="district"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">อำเภอ/เขต</div>
            }
            width={150}
          />
          <Table.Column
            key="province"
            dataIndex="province"
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">จังหวัด</div>
            }
            width={150}
          />

          <Table.Column
            key="status"
            dataIndex="status"
            title="สถานะ"
            align="center"
            width={100}
            render={(text, { id, name }) => (
              <Switch
                checked={text?.toLowerCase() === "active"}
                onChange={(e) => handleChangeStatus(e, id, name)}
              />
            )}
          />
          <Table.Column
            key="action"
            title={<FaCogs />}
            align="center"
            width={80}
            render={({ id }) => (
              <Link to={`${match.path}/${id}`}>
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  className="bg-pv border-pv"
                >
                  <FaPencilAlt />
                </Button>
              </Link>
            )}
          />
        </Table>
      </PanelBody>
    </Panel>
  );
};
export default Index;
