import { createReducer } from "@reduxjs/toolkit";
import { createContext } from "react";


export const initialState = {
    ActiveTabs: {
        active_tab_name: 'buyerInfo'
    },
}
export const reducer = createReducer(initialState, {
    SET_ACTIVE_TAB: (state, action) => { 
        state.ActiveTabs = { 
            ...state.ActiveTabs, 
            ...action.payload 
        } 
    },
    CLEAR_STATE: (state) => {
        state.ActiveTabs = {
            ...initialState.ActiveTabs
        }
    }
})

export const DistributorContext = createContext();

export default DistributorContext;