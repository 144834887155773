import { notification, Select } from 'antd';
import React from 'react'
import { useHttp } from '../../../../../../hooks/http';
import { URL_API } from '../../../../../../utils';

const SelectStatus = (props) => {
    const [loading, data, error] = useHttp({
        url: `${URL_API}/system/products/status`,
        token: true,
    }, []);

    if (error) {
        notification.error({
            description: error,
            placement: "bottomRight"
        });
    }

    return (
        <Select
            loading={loading}
            placeholder="สถานะ"
            {...props}
        >
            {data && data.data && data.data.map((n, i) => <Select.Option key={i} value={n.code}>{n.name}</Select.Option>)}
        </Select>
    )
}

export default SelectStatus;
