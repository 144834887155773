import { Modal } from "antd";
import React from "react";
import { useEthicsMutate } from "../../../../../contexts/ethics.context";

export default function PreviewDocumentModal() {
  const { state, dispatch } = useEthicsMutate();
  return (
    <Modal
      visible={state.modal.name === "document-icon"}
      onCancel={() => dispatch({ type: "HIDE_MODAL" })}
      footer={false}
      title="Preview"
    >
      <img src={state.modal.props.src} alt="Preview" className="w-100" />
    </Modal>
  );
}
