/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "antd";
import React, { useEffect } from "react";
import { useHttp } from "../../../../../../hooks/http";
import { URL_API } from "../../../../../../utils";

const SelectAddressMaster = ({
  type,
  province_id = 0,
  district_id = 0,
  reloadselect,
  ...props
}) => {
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/master/address`,
      params: {
        type,
        province_id,
        district_id,
      },
    },
    [province_id, district_id]
  );

  useEffect(() => {
    data && props.callfront(data.data);
  }, [data]);

  useEffect(() => {
    reload();
  }, [reloadselect]);
  if (error) return <div>{error}</div>;

  return (
    <Select loading={loading} dropdownMatchSelectWidth={false} {...props}>
      {data && data.data
        ? data.data.map((n, i) => (
            <Select.Option key={i} value={n.id} source={n}>
              {n.name}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

export default SelectAddressMaster;
