import React, { useContext } from "react";
import { Card, Typography } from "antd";
import InfoContext from "./reducer";
import moment from "moment";
import numeral from "numeral";

const { Text } = Typography;

const MemberSummaryCard = () => {
  const { state } = useContext(InfoContext);
  return (
    <Card
      bordered={false}
      style={{
        width: "100%",
        borderRadius: 10,
        backgroundColor: "#e6f7ff",
        textAlign: "center",
        marginTop: 15,
      }}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          backgroundColor: "#bae7ff",
          padding: "10px 16px",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <Text strong>
          ยอดสั่งซื้อสะสมช่วง :{" "}
          {moment(state.memberInfo[0]?.expire_date, 'DD/MM/YYYY')
                .subtract(1, 'year')  // ลบ 1 ปี
                .add(1, 'day')        // เพิ่ม 1 วัน
                .format('DD/MM/YYYY')}{" "}
          -{" "}{state.memberInfo[0]?.expire_date}
        </Text>
      </div>
      <div
        style={{
          padding: "10px 0",
          backgroundColor: "#f0f5ff",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Text level={2} style={{ margin: 0, fontSize: "18px" }}>
          {numeral(state.memberInfo[0]?.bill_total_amount).format("0,0.00")} บาท
        </Text>
      </div>
    </Card>
  );
};

export default MemberSummaryCard;
