import { Col, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectAllType from "../../../components/SelectAllType";
import { actionLegal, searchSelector } from "../../../redux/slices/legal";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import Member from "./Member";
import Partner from "./Partner";

const Index = () => {
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const { CLEAR_PROFILE, SET_SEARCH, CLEAR_SEARCH } = actionLegal;

  const handleChange = (value) => {

    dispatch(
      SET_SEARCH({
        query: value,
        page: 1,
      })
    );
  };

  useEffect(() => {
    dispatch(CLEAR_PROFILE());
    //dispatch(CLEAR_SEARCH());
  }, [dispatch, CLEAR_PROFILE, CLEAR_SEARCH]);

  return (
    <Panel>
      <PanelHeader>จรรยาบรรณ</PanelHeader>
      <PanelBody>
        {/* <LegalHeader title="จรรยาบรรณ"/> */}
        <Row gutter={16}>
          <Col span={24} md={12} xxl={16}>
            <Input.Search
              allowClear
              name="query"
              value={search.query}
              autoComplete="off"
              placeholder="ค้นหา รหัส, ชื่อ-นามสกุล นักธุรกิจ..."
              onChange={(e) => handleChange(e.target.value)}
            />
          </Col>
          <Col span={24} md={6} xxl={4}>
            {/* {
              <Select
              className="w-100"
              allowClear
              value={search.display_type}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    display_type: e,
                  })
                )
              }
            >
              <Select.Option value="">ทั้งหมด</Select.Option>
              <Select.Option value="waiting">รอตรวจสอบ</Select.Option>
            </Select>
            }
            <Select
              allowClear
              name="type"
              value={search.status ? search.status : undefined}
              className="w-100"
              placeholder="เลือกประเภทรายชื่อ"
              onChange={(value) => handleChange("status", value)}
            >
              <Select.Option value="expired">รายชื่อที่หมดอายุ</Select.Option>
            </Select> */}
            <SelectAllType
              className="w-100"
              process_type={
                search.member_type === "distributor"
                  ? "partner_status_local"
                  : "member_status_local"
              }
              withAll
              allText="สถานะทั้งหมด"
              //allowClear
              value={search.status}
              onChange={(e) =>
                dispatch(
                  SET_SEARCH({
                    status: e,
                    page: 1
                  })
                )
              }
            />
          </Col>
          <Col span={24} md={6} xxl={4}>
            <Select
              //allowClear
              // name="status"
              value={search.member_type}
              // loading={loadingStatus}
              className="w-100"
              //defaultValue={"distributor"}
              // placeholder="เลือกสถานะนักธุรกิจ"
              onChange={(value) =>
                dispatch(
                  SET_SEARCH({
                    member_type: value,
                    page: 1,
                    status: "",
                    query: "",
                  })
                )
              }
            >
              <Select.Option value="distributor">นักธุรกิจ</Select.Option>
              <Select.Option value="member">VIP MEMBER</Select.Option>
            </Select>
          </Col>
        </Row>

        {search.member_type === "distributor" ? <Partner /> : <Member />}
      </PanelBody>
    </Panel>
  );
};

export default Index;
