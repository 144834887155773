/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from 'antd';
import { Button } from 'antdV4';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { AlertConfirm } from '../../../util/Alert';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import {
  classFormDefaultValues,
  classFormSchema,
} from '../components/dto/class';
import ClassModal from '../components/modal/ClassModal';
import moment from 'moment';
import classConstants from '../constants/classConstants';
import { MdDelete } from 'react-icons/md';
import fitClassServices from '../../../../services/fit-class.services';
import usePaginationHook from '../../../../utils/usePagination';
import { useQueryClient } from '@tanstack/react-query';

const ClassPage = () => {
  const params = useParams();
  const history = useHistory();
  const [classFormOpen, setClassFormOpen] = useState(false);
  const {
    data: classLists,
    setData,
    page,
    setPage,
    total,
    setTotal,
  } = usePaginationHook();
  const queryClient = useQueryClient();

  const classForm = useForm({
    defaultValues: classFormDefaultValues,
    resolver: classFormSchema(),
  });

  const _HandleClassFormOpen = (formType) => {
    classForm.setValue('formType', formType);
    setClassFormOpen(true);
  };

  const _HandleClassFormClose = () => {
    setClassFormOpen(false);
  };

  const { data: categoryData } = fitClassServices.useQueryGetClassCategoryById({
    classCategoryId: params.classCategoryId,
  });

  fitClassServices.useQueryGetClassByClassCategoryId(
    {
      classCategoryId: params.classCategoryId,
      page: page,
      take: classConstants.tableSize,
    },
    (data) => {
      setTotal(data.meta.total);
      setData(data.data);
    }
  );

  const { execute: setClassEnable } = useAsync(
    fitStudioService.setClassEnable,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ['get-class-by-class-category-id'],
          refetchType: 'all',
        });
      },
    }
  );

  const { execute: deleteClass } = useAsync(fitStudioService.deleteClass, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['get-class-by-class-category-id'],
        refetchType: 'all',
      });
    },
  });

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrl}
          src={record.thumbnailImageUrl}
          alt={record.thumbnailImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: 'ชื่อคลาส',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'การมองเห็น',
      dataIndex: 'enable',
      key: 'enable',
      render: (_, record) => {
        return <div>{record.enable ? <>เปิด</> : <>ปิด</>}</div>;
      },
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record.enable ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการมองเห็นคลาสเรียน');
                if (confirm)
                  setClassEnable({
                    id: record.id,
                    isEnable: false,
                  });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการมองเห็นคลาสเรียน');
                if (confirm)
                  setClassEnable({
                    id: record.id,
                    isEnable: true,
                  });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              classForm.setValue('id', record.id);
              _HandleClassFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบคลาสเรียน');
              if (confirm) deleteClass({ id: record.id });
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3
          onClick={() => {
            history.goBack();
          }}
          className="mr-2"
          style={{ cursor: 'pointer' }}
        >
          <FiArrowLeft />
        </h3>
        <h3>{categoryData?.title}</h3>
      </div>
      <Panel>
        <PanelHeader>คลาสเรียน</PanelHeader>
        <PanelBody>
          <div className="d-flex justify-content-end">
            <Button
              className="bg-pv border-pv text-white mb-2"
              onClick={() => {
                _HandleClassFormOpen('create');
              }}
            >
              <FaPlus className="mr-2" />
              เพิ่มคลาสเรียน
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={classLists}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(
                    `/fit-studio/classcategory/${params.classCategoryId}/class/${record.id}`
                  );
                },
              };
            }}
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            onChange={({ current }) => {
              setPage(current);
            }}
            pagination={{
              pageSize: classConstants.tableSize,
              total: total,
            }}
          />
        </PanelBody>
        <FormProvider {...classForm}>
          <ClassModal
            open={classFormOpen}
            handleClose={_HandleClassFormClose}
          />
        </FormProvider>
      </Panel>
    </>
  );
};

export default ClassPage;
