import { QuestionCircleOutlined } from '@ant-design/icons'
import { Modal, Input, Typography } from 'antd'
//import TextArea from 'antd/lib/input/TextArea'
import React from 'react'

const Comment = ({ handleChange, show, ...props }) => {
    const { TextArea } = Input
    return (
        <>
            <Modal
                okText='อนุมัติ'
                cancelText='ยกเลิก'
                width={500}
                visible={show}
                zIndex={2000}
                {...props}
            >
                <QuestionCircleOutlined className="pr-2" style={{ color: '#faad14', fontSize: '22px', float: 'left', }} />
                <Typography.Title style={{fontSize: '16px', fontWeight: '500'}}>ยืนยันอนุมัติเอกสาร</Typography.Title>
                <label>Comment</label>
                <TextArea
                    name="remark"
                    size="small"
                    rows={4}
                    onChange={handleChange}
                />
                {/* <Icon type="question-circle" /> */}
            </Modal>
        </>
    )
}
export default Comment