import { Modal } from "antd";
import axios from "axios";
import React, { useRef } from "react";
import { useDispatch ,useSelector} from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  actionBlackList,
  modalSelector,
} from "../../../../redux/slices/blacklist";
import { URL_API, getToken } from "../../../../utils";
import { AlertError, AlertSuccess } from "../../../util/Alert.js";
import FormBlackList from "./FormBlackList";
export default function ModalBlackList({ visible }) {
  const blacklistRef = useRef(null);
  const dispatch = useDispatch();
  const { SET_CLOSE_MODAL } = actionBlackList;
  const match = useRouteMatch();
  const history = useHistory();
  const modal = useSelector(modalSelector);
  const handleModalCancel = () => {
    dispatch(SET_CLOSE_MODAL());
  };
  const handleFinish = (values) => {
    blacklistRef.current.validateFields(async (err) => {
      if (!err) {
        const data = blacklistRef.current.getFieldsValue();
        const params = modal.data ? modal.data.Id : null
        const result = 
        await axios({
          method: "POST",
          baseURL: URL_API,
          url: `/blacklist/add-edit-blacklist`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {data,params},
        });
        if(result.data.status === 200){
          dispatch(SET_CLOSE_MODAL());
          AlertSuccess(result.data.message);
          history.push(match.url);
        }else{
          AlertError({ title: "เกิดข้อผิดพลาด", text: result.data.message });
        }
      }
    });
  };
  return (
    <Modal
      title={modal.data ? "แก้ไข BlackList" : "เพิ่ม BlackList"}
      visible={visible}
      onOk={handleFinish}
      onCancel={handleModalCancel}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
    >
      <FormBlackList ref={blacklistRef} />
    </Modal>
  );
}
