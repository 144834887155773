/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  Divider,
  Empty,
  Input,
  Modal,
  Row,
  Table,
  Typography,
  notification,
} from "antd";
import React, { memo, useContext, useState } from "react";
import { URL_API, axios, getToken, moment } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import ArrangeContext from "../../reducer";

const FollowDocument = ({ reload }) => {
  const [remark, setRemark] = useState("");
  const {
    state: {
      bill: { document_no, follow_document },
    },
    dispatch,
  } = useContext(ArrangeContext);
  let columns = [
    {
      title: "#",
      dataIndex: "",
      key: "",
      width: 50,
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 550,
    },
    {
      title: "ผู้เขียน",
      dataIndex: "create_by",
      key: "create_by",
    },
    {
      title: "วันที่",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record) => (
        <span>{moment(text).format("DD/MM/YYYY")}</span>
      ),
    },
  ];
  const sendSubmit = () => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        if (remark === "") {
          notification.error({
            message: "ไม่สามารถดำเนินการได้",
            description: "กรุณาระบุรายละเอียดการติดตาม",
          });
          return;
        }
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            url: `${URL_API}/arrange/follow-document`,
            headers: { Authorization: "Bearer " + getToken() },
            data: {
              document_no,
              remark,
            },
          })
            .then(() => {
              resolve();
              reload();
              dispatch({ type: "clearCreate" });
              notification.success({ message: "บันทึกข้อมูลสำเร็จ" });
            })
            .catch((e) => reject(e));
        }).catch((e) => {
          resCatchModal(e);
        });
      },
      onCancel() {
        source.cancel("cancel");
      },
    });
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col
          span={24}
          className="bg-gray-60 px-3 d-flex align-items-center justify-content-between "
        >
          <Typography.Paragraph className="mb-0 text-white">
            รายละเอียดการติดตาม
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col span={24} className="d-flex">
          <Table
            size="small"
            bordered
            className="w-100"
            dataSource={follow_document}
            columns={columns}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
      <Row className="pt-3">
        <Typography.Paragraph className="mb-0 text-black">
          ระบุรายละเอียดการติดตาม
        </Typography.Paragraph>
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 4 }}
          onChange={(e) => setRemark(e.target.value)}
        />
      </Row>
          <Divider />
          <Row>
            <Col className="text-center">
              <Button type="primary" onClick={sendSubmit}>
                บันทึก
              </Button>
            </Col>
          </Row>
    </>
  );
};

export default memo(FollowDocument);
