import { Alert, Button, Card, Col, Row } from "antd";
import numeral from "numeral";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import { axios } from "../../../../../utils/useAxios";
import { resCatch } from "../../../../util/helper";
import { useCommissionMutate } from "../contexts/commission.context";

const Summary = (props, ref) => {
  const {
    state: {
      period,
      progress: { loading, pending, inprogress, success, error },
    },
    dispatch,
  } = useCommissionMutate();

  useImperativeHandle(ref, () => ({
    reload: () => getProgressCallback(),
  }));

  const getProgressCallback = useCallback(async () => {
    try {
      dispatch({ type: "LOAD_PROGRESS" });
      const { data } = await axios.get(`/interface/commission/progress`, {
        params: { period },
      });
      dispatch({ type: "LOAD_PROGRESS_SUCCESS", payload: data });
    } catch (err) {
      dispatch({ type: "LOAD_PROGRESS_FAIL" });
      resCatch(err);
    }
  }, [period, dispatch]);

  useEffect(() => {
    getProgressCallback();
  }, [getProgressCallback]);

  return (
    <Card
      size="small"
      title={`Period: ${period}`}
      extra={
        <Button
          size="small"
          type="link"
          loading={loading}
          onClick={getProgressCallback}
        >
          รีเฟรชข้อมูล
        </Button>
      }
      className="mb-3"
      loading={loading}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} md={6}>
          <Alert
            type="info"
            message="รอดำเนินการ"
            description={`${numeral(pending).format()} รายการ`}
          />
        </Col>
        <Col span={24} md={6}>
          <Alert
            type="warning"
            message="อยู่ระหว่างดำเนินการ"
            description={`${numeral(inprogress).format()} รายการ`}
          />
        </Col>
        <Col span={24} md={6}>
          <Alert
            type="success"
            message="สำเร็จ"
            description={`${numeral(success).format()} รายการ`}
          />
        </Col>
        <Col span={24} md={6}>
          <Alert
            type="error"
            message="ไม่สำเร็จ"
            description={`${numeral(error).format()} รายการ`}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default forwardRef(Summary);
