/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";
import SelectOption from "../../pos/components/SelectOption";
import _ from "lodash";

export default function SelectShipStatus({ withAll, ...props }) {
  const [filterData, setFilterData] = useState("");

  const [loading, data, error, reload] = useHttp({
    url: `${URL_API}/boss/report-component/ship-status`,
    token: true,
  });

  useEffect(() => {
    setFilterData(data ? data.data : []);
  }, [data]);

  const filterOptions = [
    _.map(filterData, (data) => {
      return { value: data.value, text: data.text };
    }),
  ];

  return (
    <SelectOption options={filterOptions[0]} withAll={withAll} {...props} />
  );
}

//---------------------------------Function => return all value
export const ValueShipStatus = (value) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/report-component/ship-status`,
      token: true,
    },
    []
  );
  useEffect(() => {
    setDataSource(data ? data.data : []);
  }, [data, value]);

  let findData = _.find(data?.data, (n) => n.value === value);
  let textData = findData?.text;
  return textData;
};
