/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";

const { Option } = Select;

const user = getUser();

const initState = {
  filter: {
    date_from: moment(),
    date_to: moment(),
    branch: [user ? user.branch_code : ""],
    query: "",
    page: 1,
    limit: 10,
  },
  summaryModal: {
    visible: false,
  },
};

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [createDateFilter, setCreateDateFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const toDay = moment().format("DD/MM/YYYY");
  const limit = 50;
  const branchFilter = _.join(
    _.reduce(state.filter.branch, (res, key) => [...res, key], []),
    ","
  );
  const reduceProductFilter = _.join(
    _.reduce(productFilter, (res, key) => [...res, key], []),
    ","
  );

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-movement-product`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        productCodeFilter: productFilter,
        createDateFilter: createDateFilter,
        branchFilter: branchFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
    },
    [page, state.filter, createDateFilter, productFilter]
  );
  const handleProduct = () => {
    console.log(dataSource)
    if (productFilter == null || productFilter == "") {
      notification.warning({
        message: "ไม่สามารถแสดงรายการได้",
        description: "กรุณาเลือกสินค้าอย่างน้อย 1 รายการ",
      });
    } else {
      reload();
    }
  };

  //export excel
  const exportToExcel = () => {};
  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const productOptions = [
    ..._.map(dataSource.dataProduct, (product) => {
      return { value: product.product_code, text: product.product_name_local };
    }),
  ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานความเคลื่อนไหวตามรายการ</PanelHeader>
        <PanelBody>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col>
              <Text strong>รายการสินค้า</Text>
            </Col>
            <Col span={8} className="ml-2">
              <Select
                className="w-100"
                mode="multiple"
                placeholder="ชื่อสินค้า"
                onChange={(e) => {
                  setProductFilter(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {productOptions.map((product, i) => {
                  return (
                    <Option key={i.toString()} value={product.value}>
                      {product.text}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={10}>
              <Row gutter={8} type="flex" justify="end">
                <Col>
                  <Button type="primary" onClick={handleProduct}>
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={3}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center"
          >
            <Col>
              <Text strong>วันที่สต๊อกตั้งต้น</Text>
            </Col>
            <Col span={6} className="pr-4 ml-2">
              <DatePicker
                className="w-100"
                format="YYYY-MM-DD"
                defaultValue={moment()}
                onChange={(e) => {
                  if (e === null) {
                    return setCreateDateFilter("");
                  }
                  return setCreateDateFilter(moment(e).format("YYYY-MM-DD"));
                }}
              />
            </Col>
            <Col>
              <Text strong>ช่วงวันที่ขาย</Text>
            </Col>
            <Col span={8} className="ml-2">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from: e[0] || moment(),
                      date_to: e[1] || moment(),
                    },
                  })
                }
              />
            </Col>
          </Row>

          <Row
            gutter={3}
            type="flex"
            align="middle"
            className=" p-b-15 align-items-sm-center"
          >
            <Col span={1}>
              <Text strong>สาขา</Text>
            </Col>
            <Col span={8}>
              <SelectBranch
                allowClear
                mode="multiple"
                withAll={user.branch_count > 1}
                userLogin={true}
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100 "
                allowDisableBranch
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
          </Row>
          {dataSource.dataTable
            ? dataSource.dataTable.map((product) => {
                let keyObj = Object.keys(product).toString();

                return (
                  <Table
                    className="pb-5"
                    bordered
                    size="small"
                    dataSource={product[keyObj]}
                    rowKey={product[keyObj]}
                    scroll={{ x: "max-content" }}
                    pagination={false}
                  >
                    <Table.ColumnGroup
                      title={
                        data
                          ? `${product[keyObj][0].product_code} ` +
                            `${product[keyObj][0].product_name_en}`
                          : " "
                      }
                      align="left"
                    >
                      <Table.Column
                        title="สาขา"
                        dataIndex="branch_name"
                        align="left"
                      />
                      <Table.Column
                        title={product[keyObj][0].remark}
                        dataIndex="bf"
                        align="right"
                        render={(text) => {
                          return numeral(text).format("0,0");
                        }}
                      />
                      <Table.Column
                        title="ระหว่างโอน"
                        dataIndex=""
                        align="right"
                        render={() => {}}
                      />
                      <Table.Column
                        title="โอนเข้า"
                        dataIndex="receive_qty"
                        align="right"
                        render={(text) => {
                          return numeral(text).format("0,0");
                        }}
                      />
                      <Table.Column
                        title="โอนออก"
                        dataIndex="transfer_qty"
                        align="right"
                        render={(text) => {
                          return numeral(text).format("0,0");
                        }}
                      />
                      <Table.Column
                        title="เบิก"
                        dataIndex="-"
                        align="right"
                        render={() => {
                          return "No Data";
                        }}
                      />
                      <Table.Column
                        title="ยอดขายวันที่"
                        dataIndex="sell_qty"
                        align="right"
                        render={(text) => {
                          return numeral(text).format("0,0");
                        }}
                      />
                      <Table.Column
                        title="ยอดจอง"
                        dataIndex="reserve_qty"
                        align="right"
                        render={(text) => {
                          return numeral(text).format("0,0");
                        }}
                      />
                      <Table.Column
                        title="คงเหลือ"
                        dataIndex="total"
                        align="right"
                        render={(text) => {
                          return numeral(text).format("0,0");
                        }}
                      />
                    </Table.ColumnGroup>
                  </Table>
                );
              })
            : []}
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
