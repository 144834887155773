/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
    Button,
    Col,
    Input,
    message,
    Modal,
    Row,
    Table,
    Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { FaCogs, FaTrash } from "react-icons/fa";
import { useHttp } from "../../../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../../../utils";

const initStateBranch = {
  id: null,
  bankcode: null,
  code: "",
  name: "",
};
const initState = [
  {
    data: [
      {
        id: null,
        bankcode: null,
        code: null,
        name: "",
      },
    ],
  },
];
let limit = 10;
const Tablebranchadd = ({
  code,
  lastdata,
  confirm,
  changeadd,
  codebank,
  form,
}) => {
  const [searchEdit, setSearchEdit] = useState("");
  const [dataFormtouse, setDataFormtouse] = useState([]);
  const [bankcodeindex, setBankcodeIndex] = useState(code);
  const [pageEdit, setPageEdit] = useState(1);
  const [showModalAddBranch, setShowModalAddBranch] = useState(false);
  const [dataFormAddBranch, setDataFormAddBranch] = useState(initStateBranch);
  const [dataSource, setDataSource] = useState([]);
  const [loading, data, error, reload] = useHttp(
    {
      method: "get",
      url: `${URL_API}/setting/master/bankbranch`,
      token: true,
      params: {
        limit,
        offset: (pageEdit - 1) * limit,
        search: searchEdit,
        bankcode: bankcodeindex,
      },
    },
    [pageEdit, bankcodeindex, searchEdit]
  );

  const [loading1, data1, error1, reload1] = useHttp(
    {
      method: "get",
      url: `${URL_API}/setting/master/bankall`,
      token: true,
    },
    []
  );

  const handleClickAdd = (data) => {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < dataSource.length; i++) {
        console.log("dataindeli", dataSource[i]);
        await axios({
          method: "post",
          headers: { Authorization: "Bearer " + getToken() },
          url: `${URL_API}/setting/master/bankaddbranch`,
          data: {
            id: dataSource[i]?.id,
            bank_code: dataSource[i].bankcode,
            branch_code: dataSource[i].code,
            bank_name: dataSource[i].name,
          },
        })
          .then((res) => {
            console.log("res.data_fromadd", res.data);
          })
          .catch((err) => {
            reject(
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message
            );
          });
      }
      resolve();
      console.log("บันทึกจริง");
    });
  };

  useEffect(() => {
    handleClickAdd();
    console.log("บันทึกใน add", confirm);
  }, [confirm]);
  useEffect(() => {
    setDataSource([]);
  }, [changeadd]);

  useEffect(() => {
    if (pageEdit !== 1) {
      setPageEdit(1);
      reload();
    }
  }, [bankcodeindex]);

  useEffect(() => {
    setBankcodeIndex(code);
    setSearchEdit(searchEdit);
    reload();
  }, [code, searchEdit]);

  useEffect(() => {
    console.log("dataFormtouse", dataFormtouse);
    setDataSource([...dataSource, ...dataFormtouse]);
    setDataFormtouse([]);
    setDataFormAddBranch([]);
  }, [showModalAddBranch]);

  const onAddbranch = () => {
    const sampled = [...dataSource];
    setDataFormtouse([
      ...dataFormtouse,
      {
        ...dataFormAddBranch,
        bankcode: form.code,
      },
    ]);
    setShowModalAddBranch(false);
  };
  const onDelete = (data) => {
    const sample = dataSource.filter((n) => n.code !== data);
    setDataSource(sample);
    console.log("sample", data);
    console.log("sample", sample);
  };
  form && console.log("form.code)", form.code);
  // data && console.log("datatable", data);
  // data && console.log("datatable1", data1);
  // data && console.log("datasource", dataSource)
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col md={10}></Col>
        <Col className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={() => {
              // (form.code===null)?
              // message.warning('กรุณากรอกรหัสธนาคาร'):setShowModalAddBranch(true);
              if (form.code === null) {
                message.warning("กรุณากรอกรหัสธนาคาร");
              } else {
                setShowModalAddBranch(true);
              }
            }}
          >
            เพิ่มสาขา
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        rowKey="bankcode"
        loading={loading}
        dataSource={dataSource}
        pagination={{
          current: pageEdit,
          pageSize: limit,
          total: dataSource?.total || 0,
          hideOnSinglePage: true,
          showSizeChanger: false,
          showLessItems: true,
          onChange: (currPage) => setPageEdit(currPage),
        }}
      >
        <Table.Column
          title="ลำดับ"
          key="index"
          render={(_text, _record, index) => (
            <Typography.Text>
              {index + 1 + (pageEdit - 1) * limit}
            </Typography.Text>
          )}
        />
        <Table.Column title="รหัสสาขา" dataIndex="code" align="center" />
        <Table.Column
          className="position-relative"
          title="ชื่อสาขา"
          dataIndex="name"
        />
        <Table.Column
          title={<FaCogs />}
          align="center"
          render={({ code, name }) => (
            <>
              <Button
                size="small"
                shape="circle-outline"
                type="danger"
                onClick={() => {
                  onDelete(code);
                }}
              >
                <FaTrash />
              </Button>
            </>
          )}
        />
      </Table>
      <Modal
        title={"เพิ่มสาขา"}
        visible={showModalAddBranch}
        onCancel={() => {
          setShowModalAddBranch(false);
          setDataFormAddBranch(initStateBranch);
        }}
        width={400}
        footer={false}
        zIndex={1080}
      >
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Row>
              <Col>
                <label>ชื่อสาขา</label>
                <Input
                  value={dataFormAddBranch.name}
                  onChange={(e) =>
                    setDataFormAddBranch({
                      ...dataFormAddBranch,
                      name: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>รหัสสาขา</label>
                <Input
                  type="number"
                  value={dataFormAddBranch.code}
                  onChange={(e) =>
                    setDataFormAddBranch({
                      ...dataFormAddBranch,
                      code: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="p-5" gutter={8} type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() => {
                onAddbranch(dataFormAddBranch);
              }}
            >
              บันทึก
            </Button>
          </Col>
          <Col>
            <Button
              type="danger"
              onClick={() => {
                setShowModalAddBranch(false);
                setDataFormAddBranch(initStateBranch);
              }}
            >
              ยกเลิก
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Tablebranchadd;
