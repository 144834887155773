import React from "react";
import SelectOption from "../../../../../components/SelectOption";
import { useApi } from "../../../../../hooks/http2";
import _ from "lodash";

export default function SelectEvent({ ...porps }) {
  const events = useApi(
    {
      baseURL: process.env.REACT_APP_EVENT_URL_API,
      url: `/events/active`,
    },
    []
  );

  return (
    <SelectOption
      loading={events.loading}
      {...porps}
      data={_.map(events.fetch?.data || [], (n) => ({
        ...n,
        value: n.Id,
        text: n.EventName,
      }))}
    />
  );
}
