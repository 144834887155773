/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Table,
  Typography,
  notification,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SaleSummaryContext, { reducer } from "../../contexts/summary-context";
import SelectBranch from "../../../pos/components/SelectBranch";
import useAsync from "../../../../../utils/useAsync";
import manageWebsiteService from "../../../../../services/manage-website.service";
import numeral from "numeral";
import * as XLSX from "xlsx-js-style";
import SelectWarehouse from "../../components/SelectWarehouse";

const { Text } = Typography;
const user = getUser();

const initState = {
  filter: {
    date_from: moment(),
    date_to: moment(),
    branch: [user ? user.branch_code : ""],
    warehouse : [''],
    query: "",
    page: 1,
    limit: 10,
  },
  summaryModal: {
    visible: false,
  },
};

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [inventoryFilter, setInventoryFilter] = useState("");
  const [branchGroup, setBranchGroup] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [checkBranch, setCheckBranch] = useState([]);
  const [columns, setColumns] = useState([]);
  const limit = 50;
  const toDay = moment().format("DD/MM/YYYY");

  const branchFilter = _.join(
    _.reduce(state.filter.branch, (res, key) => [...res, key], []),
    ","
  );
  const reduceInventoryFilter = _.join(
    _.reduce(inventoryFilter, (res, key) => [...res, key], []),
    ","
  );

  useEffect(() => {
    let selected_branch = _.map(
      _.map(state.filter.branch, (i) =>
        _.find(checkBranch, (o) => o.code === i)
      ),
      (n) => (n?.name !== undefined ? n?.name : "ทั้งหมด")
    );

    setSelectedBranch(selected_branch);
  }, [state.filter.branch, checkBranch]);

  const { execute: getAllBranch } = useAsync(
    manageWebsiteService.getAllBranch,
    {
      immediate: true,
      onSuccess: (data) => {
        setCheckBranch(data);
      },
    }
  );
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/inventory/stock-lot-period`,
      token: true,
      params: {
        userId: user.id,
        ...state.filter,
        //branchFilter : branchFilter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportToExcel = () => {
    let title_table = [
      "ลำดับ",
      "วันที่เก็บข้อมูล",
      "รหัสสินค้า",
      "ชื่อสินค้า",
      "ยอดคงเหลือทั้งหมด",
      "Lot สินค้า",
      "วันที่ผลิต",
      "วันที่หมดอายุ",
    ];

    _.map(
      _.keys(
        _.omit((data?.data || [])[0], [
          "row_id",
          "product_code",
          "product_name",
          "document_date",
          "create_date",
          "expiration_date",
          "lot",
          "total",
          "sub_category"
        ])
      ),
      (n) => {
        title_table.push(
          n
            .toLowerCase()
            .replace(/_qty/g, "")
            .replace(/(?: |\b)(\w)/g, function (key, p1) {
              return key.toUpperCase();
            })
        );
      }
    );
    title_table.push("อายุวันคงเหลือ");
    title_table.push("วันที่นำออกจาก Shelf");
    title_table.push("สถานะ");

    const exportData = data?.data ? data?.data : [];
    if (exportData.length !== 0) {
      let excelData = [
        ["รายงาน Lot.ประจำรอบ"],
        [
          `รหัสสินค้า, ชื่อสินค้า  ${state.filter.query}`,
          "",
          "",
          `วันที่  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , HH:mm")}`,
        ],
        [
          `สาขา ${
            state.filter.branch === ""
              ? "ทั่งหมด"
              : selectedBranch.map((name) => name)
          }`,
          "",
          "",
          "",
        ],
        title_table,
      ];

      exportData.forEach((n) => {
        let row_data = [
          { v: n.row_id, s: { alignment: { horizontal: "center" } } },
          {
            v: n.document_date
              ? moment(n.document_date).format("DD/MM/YYYY")
              : "",
            s: { alignment: { horizontal: "center" } },
          },
          { v: n.product_code ? n.product_code : "" },
          { v: n.product_name ? n.product_name : "" },
          {
            v: n.total > 0 ? n.total : 0,
            t: "n",
            s: { numFmt: "#,##0", alignment: { horizontal: "right" } },
          },
          {
            v: n.lot === "null" || n.lot == null ? "-" : n.lot,
            s: { alignment: { horizontal: "left" } },
          },
          {
            v: n.create_date ? moment(n.create_date).format("DD/MM/YYYY") : "",
            s: { alignment: { horizontal: "center" } },
          },
          {
            v: n.expiration_date
              ? moment(n.expiration_date).format("DD/MM/YYYY")
              : "",
            s: { alignment: { horizontal: "center" } },
          },
        ];
        _.forEach(Object.keys(n), (branch) => {
          if (
            branch !== "row_id" &&
            branch !== "product_code" &&
            branch !== "product_name" &&
            branch !== "total" &&
            branch !== "document_date" &&
            branch !== "create_date" &&
            branch !== "expiration_date" &&
            branch !== "lot" &&
            branch !== "sub_category"
          ) {
            row_data.push({
              v: n[branch] > 0 ? n[branch] : 0,
              t: "n",
              s: { numFmt: "#,##0", alignment: { horizontal: "right" } },
            });
          }
        });
        row_data.push({
          v: n.expiration_date
            ? moment(n.expiration_date).diff(moment(), "days") + 1 
            : "-",
          s: { numFmt: "#,##0",alignment: { horizontal: "center" } },
        });
        row_data.push({
          v:
            n.sub_category === "6" || n.sub_category === 6
              ? n.expiration_date
                ? moment(n.expiration_date)
                    .subtract(60, "d")
                    .format("DD-MMM-YY")
                : "-"
              : n.expiration_date
              ? moment(n.expiration_date).subtract(90, "d").format("DD-MMM-YY")
              : "-",
          s: { alignment: { horizontal: "center" } },
        });
        row_data.push(
          cellColor(
            n.expiration_date
              ? moment(n.expiration_date).diff(moment(), "days") + 1
              : "-",
            n.sub_category
          )
        );

        excelData = [...excelData, row_data];
      });

      function cellColor(daysDiff, subCategory) {
        if (subCategory === "6" || subCategory == 6) {
          if (daysDiff > 100) {
            return {
              v: "จำหน่ายปกติ",
              s: {
                alignment: { horizontal: "center" },
                fill: { fgColor: { rgb: "92D050" } }
              },
              t: "s"
            };
          } else if (daysDiff > 60 && daysDiff <= 100) {
            return {
              v: "ทำโปรโมชั่น",
              s: {
                alignment: { horizontal: "center" },
                fill: { fgColor: { rgb: "FFFF00" } }
              },
              t: "s"
            };
          } else if (daysDiff <= 60) {
            return {
              v: "เก็บสินค้าห้ามจำหน่าย",
              s: {
                alignment: { horizontal: "center" },
                fill: { fgColor: { rgb: "FF0000" } }
                
              },
              t: "s"
            };
          } else {
            return {
              v: "-",
              s: {
                alignment: { horizontal: "center" },
              },
              t: "s"
            };
          }
        } else {
          if (daysDiff >= 180) {
            return {
              v: "จำหน่ายปกติ",
              s: {
                alignment: { horizontal: "center" },
                fill: { fgColor: { rgb: "92D050" } }
              },
              t: "s"
            };
          } else if (daysDiff > 90 && daysDiff < 180) {
            return {
              v: "เตรียมเก็บสินค้า",
              s: {
                alignment: { horizontal: "center" },
                fill: { fgColor: { rgb: "FFFF00" } }
              },
              t: "s"
            };
          } else if (daysDiff <= 90) {
            return {
              v: "เก็บสินค้าห้ามจำหน่าย",
              s: {
                alignment: { horizontal: "center" },
                fill: { fgColor: { rgb: "FF0000" } }
              },
              t: "s"
            };
          } else {
            return {
              v: "-",
              s: {
                alignment: { horizontal: "center" },
              },
              t: "s"
            };
          }
        }
      }

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
        { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
      ];
      const wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 35 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงาน_Lot_ประจำรอบ");
      XLSX.writeFile(wb, "รายงาน_Lot_ประจำรอบ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  useEffect(() => {
    let selected_branch = _.map(
      _.map(state.filter.branch, (i) =>
        _.find(checkBranch, (o) => o.code === i)
      ),
      (n) => (n?.name !== undefined ? n?.name : "ทั้งหมด")
    );

    setSelectedBranch(selected_branch);

    //----------------------หารายการของแต่ละสาขา
    const find_key = _.map(
      _.keys(
        _.omit(data?.data[0], [
          "row_id",
          "product_code",
          "product_name",
          "document_date",
          "create_date",
          "expiration_date",
          "lot",
          "total",
          "sub_category"
        ])
      ),
      (name) => name
    );

    // //----------------------ตัดคำด้านหลัง 3 รอบ ["_pv","_qty","_amount"]
    const cut_qty = find_key.map((n) => n.split("_qty", 1));

    //----------------------หา uniq สาขา
    const uniq_branch = _.uniq(cut_qty.map((n) => n[0]));

    setBranchGroup(uniq_branch);
  }, [data, state.filter.branch, checkBranch]);
  useEffect(() => {
    let newColumns = [
      {
        title: "ลำดับ",
        dataIndex: "row_id",
        align: "center",
        // render: (text) => {
        //   return moment(text).format("DD/MM/YYYY");
        // },
      },
      {
        title: "วันที่เก็บข้อมูล",
        dataIndex: "document_date",
        align: "center",
        render: (text) => {
          return moment(text).format("DD/MM/YYYY");
        },
      },
      {
        title: "รหัส",
        dataIndex: "product_code",
        align: "center",
      },
      {
        title: "ชื่อสินค้า",
        dataIndex: "product_name",
        align: "center",
      },
      {
        title: "ยอดคงเหลือทั้งหมด",
        dataIndex: "total",
        align: "center",
      },
      {
        title: "Lot สินค้า",
        dataIndex: "lot",
        align: "center",
        render: (text) => {
          return text == null || text === "null" ? "-" : text;
        },
      },
      {
        title: "วันผลิต",
        dataIndex: "create_date",
        align: "center",
        render: (text) => {
          return text ? moment(text).format("DD/MM/YYYY") : "-";
        },
      },
      {
        title: "วันหมดอายุ",
        dataIndex: "expiration_date",
        align: "center",
        render: (text) => {
          return text ? moment(text).format("DD/MM/YYYY") : "-";
        },
      },
    ];
    _.forEach(branchGroup, (branch, i) => {
      newColumns.push({
        title: branch
          .toLowerCase()
          .replace(/_/g, " ")
          .replace(/(?: |\b)(\w)/g, function (key, p1) {
            return key.toUpperCase();
          }),
        dataIndex: `${branch}_qty`,
        align: "right",
        render: (value) => (value === 0 ? "-" : numeral(value).format("0,0")),
      });
    });
    newColumns.push(
      {
        title: "อายุวันคงเหลือ",
        //dataIndex: "expiration_date",
        align: "center",
        render: (text, record) => {
          return record.expiration_date
            ? moment(record.expiration_date).diff(moment(), "days") + 1
            : "-";
        },
      },
      {
        title: "วันที่นำออกจาก Shelf",
        //dataIndex: "expiration_date",
        align: "center",
        render: (text, record) => {
          if (record.sub_category === "6" || record.sub_category === 6) {
            return record.expiration_date
              ? moment(record.expiration_date)
                  .subtract(60, "d")
                  .format("DD-MMM-YY")
              : "-";
          } else {
            return record.expiration_date
              ? moment(record.expiration_date)
                  .subtract(90, "d")
                  .format("DD-MMM-YY")
              : "-";
          }
        },
      },
      {
        title: "สถานะ",
        //dataIndex: "expiration_date",
        align: "center",
        render: (text, record) => {
          let daysDiff = record.expiration_date
            ? moment(record.expiration_date).diff(moment(), "days") + 1
            : "-";
          //console.log(daysDiff)
          if (record.sub_category === "6" || record.sub_category === 6) {
            if (daysDiff > 100) {
              return {
                props: {
                  style: { background: "#92D050" },
                },
                children: <div>จำหน่ายปกติ</div>,
              };
            } else if (daysDiff > 60 && daysDiff <= 100) {
              return {
                props: {
                  style: { background: "#FFFF00" },
                },
                children: <div>ทำโปรโมชั่น</div>,
              };
            } else if (daysDiff <= 60) {
              return {
                props: {
                  style: { background: "#FF0000" },
                },
                children: <div>เก็บสินค้าห้ามจำหน่าย</div>,
              };
            } else {
              return {
                props: {
                  //style: { background: "#FF0000" }
                },
                children: <div>-</div>,
              };
            }
          } else {
            if (daysDiff >= 180) {
              return {
                props: {
                  style: { background: "#92D050" },
                },
                children: <div>จำหน่ายปกติ</div>,
              };
            } else if (daysDiff > 90 && daysDiff < 180) {
              return {
                props: {
                  style: { background: "#FFFF00" },
                },
                children: <div>เตรียมเก็บสินค้า</div>,
              };
            } else if (daysDiff <= 90) {
              return {
                props: {
                  style: { background: "#FF0000" },
                },
                children: <div>เก็บสินค้าห้ามจำหน่าย</div>,
              };
            } else {
              return {
                props: {
                  //style: { background: "#FF0000" }
                },
                children: <div>-</div>,
              };
            }
          }
          //return record.expiration_date ? moment(record.expiration_date).diff(moment(), 'days'): '-';
        },
      }
    );

    setColumns(newColumns);
  }, [branchGroup]);

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงาน Lot.ประจำรอบ</PanelHeader>
        <PanelBody>
          <Row gutter={8} type="flex" className="mb-4 mt-2 align-items-center">
            <Col span={8} className=" p-r-15">
              <Input
                placeholder="รหัสสินค้า, ชื่อสินค้า"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  });
                }}
              />
            </Col>
            <Col span={1} align="center">
              <Text strong>วันที่</Text>
            </Col>
            <Col span={6} className=" p-r-15">
              <DatePicker.RangePicker
                className="w-100"
                placeholder={[toDay, toDay]}
                format="DD/MM/YYYY"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: {
                      date_from:
                        moment(e[0]).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                      date_to:
                        moment(e[1]).format("YYYYMMDD") ||
                        moment().format("YYYYMMDD"),
                    },
                  })
                }
              />
            </Col>
            <Col span={9}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={8} type="flex" className="mb-4 mt-2 align-items-center">
            <Col span={1} align="center">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={7}>
              <SelectBranch
                mode="multiple"
                userLogin
                allowDisableBranch
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100 "
                withAll={user.branch_count > 1}
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
            <Col span={1} align="center">
              <Text strong>คลัง</Text>
            </Col>
            <Col span={6}>
              <SelectWarehouse
                mode="multiple"
                //withAll
                defaultValue = {[""]}
                placeholder="เลือกคลัง"
                className="w-100 "
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { warehouse: e },
                  });
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={data ? data.data : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data ? data.data.length : 0,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
            columns={columns}
          />
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};
export default Index;
