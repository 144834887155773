/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Alert,Typography, Button, Col, Input, Row, Table, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../../../../hooks/http";
import { axios, getToken, URL_API } from "../../../../../../utils";
import { FaCog, FaCogs, FaTrash } from "react-icons/fa";
import ModalProduct from "./ModalProduct";
const initState = {
  row_id: null,
  id: null,
  code: '',
  name: '',
  status: 'active',
};
const originproduct = {
  id: null,
  code: '',
  name: '',
  row_number: null,
};

const limit = 10;
const ModalProductCategory = ({ visible, onCancel, codecategory, productcategory,
  state, namecategory, idfromindex, productvisible, setproductvisible,
  stategetdata, statesetproduct, searchindex, confirmdelete, stateconfirmdelete, confirmdelete2,
  stateconfirmdelete2,page2,statusindex,confirmadd,statesetconfirmadd,setConfirmAdd2,confirmadd2
}) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [codecategorys, setCodecategorys] = useState(codecategory)
  const [dataSource, setDataSource] = useState(productcategory);
  const [productfirstcome, setProductFirstcome] = ([productcategory])
  const [dataForm, setDataForm] = useState(initState)
  const [showModal, setShowModal] = useState(false)
  const [showModalProduct, setShowModalProduct] = useState(false)
  const [productdel, setProductdel] = useState([])
  const [value, setValue] = useState('');
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [productorigin, setProductOrigin] = useState(originproduct)
  const [addfrommodalproduct, setAddfrommodalproduct] = useState(false)
  const [sampledelinpc, setSampledelinpc] = useState([])
  const [activeadd, setActiveadd] = useState(false)
  //const [confirmadd2, setConfirmAdd2] = useState(false)
  const [loading, data, error, reload] = useHttp(
    {
      method: "get",
      url: `${URL_API}/system/products/category`,
      params: {
        limit,
        offset: (page - 1) * limit,
        search
      },
      token: true,
    },
    [page, codecategorys, productcategory]
  );


  useEffect(() => {
    if (page !== 1) {
      setPage(1);
      //setSearch(codecategory);
    } else {
      reload();
    }
  }, [search, codecategorys]);
  useEffect(() => {
    if (confirmdelete) {
      console.log("Test")
      handleClickDelete()
      
      setProductdel([])
      //เมื่อมีการกดบันทึกจากหน้า index จะทำการ delete จริง
      console.log("confirmdelete", confirmdelete)
      reload();
    } else {
      setProductdel([])
      console.log("productdel fromefffect", productdel)
    }
  }, [confirmdelete2]);
useEffect(()=>{
  if(codecategory===" "){
  statesetproduct([])}
},[])
useEffect(()=>{
  if((page>=productfirstcome.length/10) && (page!==1)){setPage(page-1)}
},[productdel])  


useEffect(()=>{
  if(!visible){
   setPage(1)
  }
  if(page2){
   setPage(1)
  }
},
[visible])

useEffect(()=>{
    setPage(1)
},
[page2])

  //step 1 กดเฉยๆยังไม่ delete จริงๆ
  const handleClickDeletefirst = (data) => {
    console.log("dataindel", data)
    console.log("productdel", productdel);
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการลบข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        const sampled = [...productfirstcome]
        console.log("productcategory in del before", sampled);
        const sampledel = sampled.filter((item) =>
          item.row_id !== data.row_id
        )
        const productformdel = sampled.find((item) =>
          item.row_id === data.row_id
        )
        console.log("productformdel", productformdel)
        setProductdel([...productdel, productformdel])
        //ด้านบนเอาไว้ลบจริง ด้านล่างลบในตารางเฉยๆ
        console.log("sampledel", sampledel);
        console.log("sampledelproductdel", productdel);
        console.log("sampledel+row", sampledel)
        setSampledelinpc(sampledel)
        stategetdata(searchindex,statusindex);
        statesetproduct(sampledel);
        
      }
    })
  }

  const handleClickDelete = (data) => {
    console.log("dataindel136", productdel)
    const todel = productfirstcome.map((item)=>
                item.product_code
    )
    const preparetodelete = productdel.filter((item) =>
      typeof item.product_code != todel
    )
    console.log("dataindel43", preparetodelete)
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i <= preparetodelete.length; i++) {
        console.log("dataindeli", preparetodelete[i])
        await axios({
          method: "post",
          headers: { Authorization: "Bearer " + getToken() },
          url: `${URL_API}/setting/master/promotioncategory/delete`,
          data: {
            product_id: preparetodelete[i]?.row_id,
            category_id: idfromindex
          },

        }).then((res) => {
          console.log("res.data_fromdelete", res.data)
        })
          .catch((err) => {
            reject(
              err && err.response
                ? err.response.data.message
                  ? err.response.data.message
                  : err.response.data
                : err.message
            );
          })
      } resolve()
      ;stateconfirmdelete(false);setActiveadd(!activeadd);
      /*setConfirmAdd2(true)*/;console.log("delete success");
    },
    )
  }
  const handleClickEdit = (data) => {
    console.log('data', data)
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "ต้องการแก้ไขข้อมูลหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          axios({
            method: "post",
            headers: { Authorization: "Bearer " + getToken() },
            url: `${URL_API}/setting/master/promotioncategory/updateproduct`,
            data: {
              ...data
            },
          })
            .then((res) => {

              console.log("res.data_fromedit", res.data)
              resolve(res.data);
              reload()
            })
            .catch((err) => {
              reject(
                err && err.response
                  ? err.response.data.message
                    ? err.response.data.message
                    : err.response.data
                  : err.message
              );
            });
        })
          .then((value) => {
            setShowModal(false)
            Modal.success({
              title: "สำเร็จ",
              content: "แก้ไขข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                stategetdata(searchindex);
                reload()
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "มีสินค้านี้อยู่แล้ว",
              content: "มีสินค้านี้อยู่แล้ว",
              okText: "ปิด",
            });
          });
      },
    });
  }
  const getData = (data) => {
    stategetdata(searchindex);
    statesetproduct(productfirstcome)
  }


  useEffect(() => {

  }, [])
  /*useEffect(()=>{
    if(addfrommodalproduct)
    statesetproduct(sampledelinpc);
  },[addfrommodalproduct])*/
  // console.log("codecategory in category", codecategory)
  //console.log("productdel",productdel);
  console.log("productfirstcomeindataSource", dataSource)
  console.log("productfirstcome", productfirstcome)
  console.log("page", page)
  return (

    <Col
      className={visible ? ('show') : ('hide')}
      onCancel={() => {
        //setPage(1)
        setSearch();
        onCancel(
        );
      }}
      width="900px"
    >

      {error ? (
        <Alert type="error" />
      ) : (

        <Table
          bordered size="small"
          loading={loading}
          dataSource={productfirstcome}
          rowKey="row_id"
          pagination={{
            hideOnSinglePage: true,
            current: page,
            size: "default",
            pageSize: limit,
            showSizeChanger: false,
            showLessItems: true,
            onChange: (curr) => setPage(curr),
            total: data?.total || 0,
          }}
        >
          <Table.Column title="ลำดับ" key = "index"
          render={(_text, _record, index) => (
            <Typography.Text>{(index + 1)+((page-1)*limit)}</Typography.Text>
          )}/>
          <Table.Column className="hide" dataIndex="row_number"/>
          <Table.Column className="hide" title="ลำดับ" dataIndex="row_id" />
          <Table.Column title="รหัสสินค้า" dataIndex="product_code" />
          <Table.Column title="ชื่อสินค้า" dataIndex="product_name_local" />
          <Table.Column
            align="center"
            title={<FaCogs />}
            render={({ row_id, row_number, product_code, product_name_local, product,_text, _record,index  }) =>
            (
              <>
                {/*<Button
                  size="small"
                  shape="circle-outline"
                  type="primary"
                  className="mr-2"
                  onClick={() => {
                    setShowModal(true)
                    setDataForm({ ...dataForm, id: row_id, code: product_code, name: product_name_local })
                    setProductOrigin({ id: row_id, code: product_code, name: product_name_local })



                  }}
                > 
                  <FaCog />
                </Button>*/}
                <Button
                  size="small"
                  shape="circle-outline"
                  type="danger"
                  onClick={() => {
                    handleClickDeletefirst({ row_id, row_number, _text, _record,index })
                  }}
                >
                  <FaTrash />
                </Button>
              </>
            )}
          />
        </Table>)}
      <Modal
        title={'แก้ไขสินค้า'}
        visible={showModal}
        onCancel={() => { setShowModal(false); setProductOrigin(originproduct) }}
        onOk={() => { handleClickEdit(productorigin) }}
        okText="บันทึก"
        cancelText="ยกเลิก"
        width={800}
      >
        <Row gutter={[8, 16]}>
          <Col>
            <label>รหัสสินค้า</label>
            <Input name='code' value={productorigin.code} onChange={(e) => setProductOrigin({ ...productorigin, code: e.target.value })} onInput={(e) => setCode(e.target.value)} />
          </Col>
          <Col>
            <label>ชื่อสินค้า</label>
            <Input name="name" value={productorigin.name} onChange={(e) => setProductOrigin({ ...productorigin, name: e.target.value })} onInput={(e) => setName(e.target.value)} />
          </Col>
        </Row>
      </Modal>
      <ModalProduct onCancel={() => { setproductvisible(false); reload(); stategetdata(searchindex,statusindex);setAddfrommodalproduct(!addfrommodalproduct) }}
        visible={productvisible}
        idfromindex={idfromindex}
        linenumber={productfirstcome.length+1}
        statesetproduct={statesetproduct}
        searchindex={searchindex}
        productcategory={productfirstcome}
        setProductfirstcome={setProductFirstcome}
        setAddfrommodalproduct={setAddfrommodalproduct}
        addfrommodalproduct={addfrommodalproduct}
        stategetdatafrommodalpc={getData}
        confirmdelete={confirmdelete}
        confirmdelete2={confirmdelete2}
        confirmadd={confirmadd}
        statesetconfirmadd={statesetconfirmadd}
        confirmadd2={confirmadd2}
        statesetconfirmadd2={setConfirmAdd2}
        activeadd={activeadd}

      />
    </Col>
  );
};

export default ModalProductCategory;