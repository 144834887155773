/* eslint-disable no-unused-vars */
import { Col, Row } from 'antd';
import React from 'react';
import { Element } from 'react-scroll';
const LayoutBox = (props) => {
    const { id, title, content, componentTopRight } = props
    return (
        <>
            <Element
                name={id}
                style={{ paddingRight: '5px' }}
            >
                <div className="container shadow-sm p-15 mb-4 bg-white rounded">
                    <div className="pl-2 pr-2" style={{ borderLeft: '5px solid #97D700' }}>
                        <Row type="flex">
                            <Col md={20}>
                                <div style={{ fontSize: 18,fontStyle:'bold' }}>
                                    {title}
                                </div>
                            </Col>
                            {componentTopRight &&
                                <Col md={4} style={{textAlign: 'end'}}>
                                    {componentTopRight}
                                </Col>
                            }
                        </Row>
                    </div>
                    <div className="pl-2 pr-2 mt-3">
                        {content}
                    </div>
                </div>
            </Element>

        </>
    )
}
export default LayoutBox;