/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, Row, Skeleton, Typography, Divider } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QrButton from "../../../../../components/QrButton";
import QrModal from "../../../../../components/QrModal";
import TabUnderLine from "../../../../../components/TabUnderLine";
import { URL_API, _, axios, getToken } from "../../../../../utils";
import { resCatchModal } from "../../../../util/helper";
import { numDec, numInt } from "../../../../util/helper/map-data";
import ArrangeContext from "../../reducer";
import BillCanceledButton from "../BillCanceledButton";
import BillHeader from "../BillHeader";
import BillHeaderInfo from "../BillHeaderInfo";
import AuthorityDocument from "./AuthorityDocument";
import ConfirmButton from "./ConfirmButton";
import SummaryButton from "./SummaryButton";
import FollowDocument from "./FollowDocument";
const listTab = [
  {
    title: "รายการสินค้า",
    value: "items",
  },
  {
    title: "เอกสารมอบอำนาจ",
    value: "authority",
  },
  {
    title: "การติดตาม",
    value: "follow",
  },
];

const PreviewModal = ({
  reload,
  withQrModal = false,
  withAuthority = false,
  hideAddAuthority = false,
  confimPermission = false,
}) => {
  const { process: process_params } = useParams();
  const [activeList, setActiveList] = useState("items");
  const [loading, setLoading] = useState(false);
  const {
    state: {
      tagReload,
      qrProductKeySelect,
      previewSummary,
      previewSummaryDocumentNo,
      bill,
      items: { itemKeys, itemByKey },
    },
    dispatch,
  } = useContext(ArrangeContext);
  const is_cancel = bill.arrange_cancel_status === 1;

  const onCancel = () =>
    dispatch({
      type: "clearPreviewSummary",
    });

  useEffect(() => {
    if (previewSummaryDocumentNo) {
      dispatch({ type: "setState", name: "previewSummary", payload: true });
      setLoading(true);
      axios({
        url: `${URL_API}/arrange/${previewSummaryDocumentNo}`,
        headers: { Authorization: "Bearer " + getToken() },
      })
        .then((res) => {
          const { data } = res.data;
          if (!data) {
            onCancel();
            Modal.error({
              title: "ไม่สามารถดำเนินการได้",
              content: "ไม่พบข้อมูลบิล",
              zIndex: 1080,
            });
          }
          dispatch({ type: "loadData", payload: data });
        })
        .catch((e) => {
          onCancel();
          resCatchModal(e);
        })
        .finally(() => setLoading(false));
    }
  }, [previewSummaryDocumentNo, tagReload]);

  const qrCloseModal = () =>
    dispatch({
      type: "setState",
      name: "qrProductKeySelect",
      payload: null,
    });

  const handleClickProcess = useCallback((e) => setActiveList(e), []);

  return (
    <>
      <Modal
        visible={previewSummary}
        onCancel={onCancel}
        destroyOnClose={true}
        maskClosable={false}
        footer={false}
        zIndex={1080}
        width={900}
        bodyStyle={{ paddingTop: "50px" }}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <BillHeaderInfo />

            {withAuthority && (
              <Row className="my-3">
                <Col span={18} offset={3}>
                  <TabUnderLine
                    active={activeList}
                    list={listTab}
                    onClick={handleClickProcess}
                  />
                </Col>
              </Row>
            )}

            {activeList === "items" ? (
              <>
                <BillHeader />
                <table className="w-100 my-2" style={{ fontSize: "12px" }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th className="font-weight-light text-center text-gray-60">
                        ชื่อ
                      </th>
                      <th className="font-weight-light text-center text-gray-60">
                        {bill.sales_type === "redeem" ? "คะแนน" : "ราคา"}/หน่วย
                      </th>
                      <th className="font-weight-light text-center text-gray-60">
                        {bill.sales_type === "redeem" ? "คะแนน" : "ราคา"}
                      </th>
                      <th className="font-weight-light text-center text-gray-60">
                        จำนวนที่จ่าย
                      </th>
                      <th />
                      {withQrModal && <th />}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(
                      _.filter(
                        itemKeys,
                        (key) => itemByKey[key].qty_delivery > 0
                      ),
                      (key, i) => {
                        const n = itemByKey[key];
                        const price =
                          bill.sales_type === "redeem"
                            ? n.redeem_point_item
                            : n.unit_price;
                        return (
                          <tr key={n.product_code} className="text-dark">
                            <td className="pl-3">{i + 1}</td>
                            <td>{`${n.product_code} - ${n.product_name}`}</td>
                            <td className="text-center">{numDec(price)}</td>
                            <td className="text-center">
                              {numDec(price * n.qty_delivery)}
                            </td>
                            <td className="text-center">
                              <strong className="text-pv">
                                {numInt(n.qty_delivery)}
                              </strong>
                            </td>
                            {withQrModal && (
                              <td>
                                <QrButton
                                  qrWarning={n.qr_warning}
                                  onClick={() =>
                                    dispatch({
                                      type: "setState",
                                      name: "qrProductKeySelect",
                                      payload: key,
                                    })
                                  }
                                />
                              </td>
                            )}
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td colSpan={4} className="text-right">
                        <strong className="text-dark">รวม</strong>
                      </td>
                      <td className="text-center">
                        <strong className="text-pv">
                          {bill.total_qty_delivery}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Divider className="mt-2 mb-2" />
                <Row>
                  <Col>
                    <Typography.Text strong className="mr-1 text-dark">
                      หมายเหตุการจัดสินค้า :
                    </Typography.Text>
                    <Typography.Text className="text-dark">
                      {" "}
                      {bill?.remark}
                    </Typography.Text>
                  </Col>
                </Row>
              </>
            ) : activeList === "authority" ? (
              <AuthorityDocument hideAddAuthority={hideAddAuthority} />
            ) : (
              <FollowDocument reload={reload} />
            )}

            {is_cancel ? (
              <BillCanceledButton
                reload={reload}
                onCancel={onCancel}
                type="preview"
              />
            ) : process_params === "wait" ? (
              <SummaryButton reload={reload} />
            ) : (
              (process_params === "pending" ||
                (process_params === "to-warehouse" && confimPermission)) &&
              (activeList !== "follow" ? <ConfirmButton reload={reload} /> : "")
            )}
          </>
        )}
      </Modal>
      {withQrModal && (
        <QrModal
          disabled
          product={
            qrProductKeySelect && {
              ...itemByKey[qrProductKeySelect],
              qty: itemByKey[qrProductKeySelect].qty_delivery,
              key: qrProductKeySelect,
            }
          }
          partnerType={bill.partner_type}
          salesType={bill.sales_type}
          onCancel={qrCloseModal}
        />
      )}
    </>
  );
};

export default PreviewModal;
