import { Modal, Typography } from 'antd';
import React, { useState } from 'react';

const DownloadModal = ({
  open,
  handleClose,
  registerPaperId,
  title,
  handleDownload,
}) => {
  const [loading, setLoading] = useState(false);

  const _HandleSubmit = () => {
    setLoading(true);
    handleDownload(registerPaperId);
    setTimeout(() => {
      setLoading(false);
      handleClose();
    }, 8000);
  };

  return (
    <Modal
      title="ดาวน์โหลดเอกสาร"
      visible={open}
      onCancel={handleClose}
      okText="ดาวน์โหลด"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      confirmLoading={loading}
      getContainer={false}
    >
      <Typography.Text>{title}</Typography.Text>
    </Modal>
  );
};

export default DownloadModal;
