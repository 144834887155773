import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const newsCategoryFormDefaultValues = {
  id: '',
  formType: 'create',
  title: '',
  thumbnailImageUrl: undefined,
};

export const newsCategoryFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่ชื่อหมวดหมู่'),
    })
  );
};

export const newsFormDefaultValues = {
  id: '',
  formType: 'create',
  title: '',
  thumbnailImageUrl: undefined,
  subTitle: '',
  categoryId: '',
  newsContentBoxes: [
    {
      id: '',
      title: '',
      description: '',
      imageUrl: '',
      imageDescription: '',
      imagePosition: 'left',
    },
  ],
};

export const newsFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      thumbnailImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      title: yup.string().required('กรุณาใส่หัวข้อ'),
      subTitle: yup.string().required('กรุณาใส่หัวข้อรอง'),
      newsContentBoxes: yup
        .array()
        .min(1, 'กรุณาเพิ่มเนื่อหาอย่างน้อย 1 กล่อง'),
    })
  );
};
