import { Col, Row, Typography } from "antd";
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { _ } from "../../../../../utils";

const { Text } = Typography;

const BomItem = ({ list }) => {
  const [expand, setExpand] = useState(true);
  return (
    <div className="ml-5">
      <Text className="cursor-pointer" onClick={() => setExpand(!expand)}>
        ประกอบด้วย
        {expand ? (
          <FaAngleUp className="ml-2" />
        ) : (
          <FaAngleDown className="ml-2" />
        )}
      </Text>
      {expand &&
        _.map(list, (n, i) => (
          <Row key={n.product_code}>
            <Col span={1}>
              <Text>{i + 1}</Text>
            </Col>
            <Col span={10} className="d-flex flex-column">
              <Text>{n.product_name}</Text>
            </Col>
            <Col span={5} className="text-center">
              <Text>{n.qr_running ? n.barcode : ""}</Text>
            </Col>
            <Col span={5} className="text-center">
              <Text>{n.qr_running}</Text>
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default BomItem;
