import React, { Component, useMemo } from 'react'
import styled from 'styled-components'
import { Spin, } from 'antd';
import Loadingscreen from 'react-loading-screen'

export class Loading extends Component {
    render() {
        const {
            loading = false,
            text = "Loading...",
            size = "default",
            color = "#32a932",
        } = this.props;

        const WrapLoading = styled.div`
            .ant-spin-text{
                color: ${color};
            }
            .ant-spin-dot-item{
                background-color: ${color};
            }
        `
        return (
            <>
                <WrapLoading>
                    <Spin
                        spinning={loading}
                        tip={text}
                        size={size}
                    >
                        {this.props.children}
                    </Spin>
                </WrapLoading>
            </>
        )
    }
}
export class LoadingScreen extends Component {
    render() {
        const {
            loading = false,
            bgColor = 'linear-gradient(to bottom,rgba(0,0,0,.45) 0%,rgba(0,0,0,.9))',
            spinnerColor = "white",
            textColor = '#f1f1f1',
            logoSrc = "https://www.legacy.co.th/frontend-assets/images/LOGOW.svg",
            text = 'กำลังเข้าสู่ระบบ...',
        } = this.props;
        return (
            <>
                <Loadingscreen
                    loading={loading}
                    bgColor={bgColor}
                    spinnerColor={spinnerColor}
                    textColor={textColor}
                    logoSrc={logoSrc}
                    text={text}
                >
                    {this.props.children}
                </Loadingscreen>
            </>
        )
    }
}


export const LoadingFullScreen = React.memo((props) => {
    const {
        loading = false,
        bgColor = 'linear-gradient(to bottom,rgba(0,0,0,.45) 0%,rgba(0,0,0,.9))',
        logoSrc = "https://www.legacy.co.th/frontend-assets/images/LOGOW.svg",
        spinnerColor = "#ffffff",
        imgWidth = 300,
        text = 'Loading...',
        textColor = '#ffffff',
        textSize = 30
    } = props;

    const WrapLoading = useMemo(() => (
        styled.div`
            .ant-spin-text{
                color: ${spinnerColor};
            }
            .ant-spin-dot-item{
                background-color: ${spinnerColor};
            }
        `
    ), [spinnerColor])


    const BlockStyle = useMemo(() => {
        return {
            background: bgColor,
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: '1500',
            left: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none'
        }
    }, [bgColor])

    return (
        <div>
            {
                loading ? (
                    <div style={BlockStyle}>
                        <img src={logoSrc} alt="Logo" style={{ width: `${imgWidth}px` }} />
                        <div style={{ padding: '16px', marginTop: '16px' }}>
                            <WrapLoading>
                                <Spin size="large" />
                            </WrapLoading>
                        </div>
                        <div>
                            <span style={{ color: textColor, fontSize: `${textSize}px` }}>{text}</span>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
})