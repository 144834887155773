import {
  Button,
  Col,
  DatePicker,
  Input,
  Pagination,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { FaEye, FaPrint } from "react-icons/fa";
import { URL_API, axios, getUserObj, moment } from "../../../../utils";
import { printform } from "../../../../utils/printforms";
import { resCatch } from "../../../util/helper";
import BillPreview from "./BillPreview";
import ModalControl from "./ModalControl";
import Radio from "./Radio";
import SelectBranch from "./SelectBranch";

const TableBill = ({ dataSource = [], columns = [], className = "" }) => {
  const renderData = () => {
    let tr = [];
    dataSource.forEach((n, i) => {
      tr.push(
        <tr className="bg-transparent" key={i}>
          {columns.map((m, j) => (
            <td className="border-0 align-middle" key={j}>
              {m.render ? m.render(n[m.dataIndex], n, i) : n[m.dataIndex]}
            </td>
          ))}
        </tr>
      );
    });

    return tr;
  };

  return (
    <table className={`table table-sm m-0 ${className}`}>
      <thead>
        <tr className="bg-gray-60 text-white text-center">
          {columns.map((n, i) => (
            <th className={`font-weight-normal ${n.className}`} key={i}>
              {n.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{renderData()}</tbody>
    </table>
  );
};

const limit = 10;
const BillPopup = ({ visible, onCancel }) => {
  const init = {
    query: "",
    date_from: moment(),
    date_to: moment(),
    bill_status: "normal",
    branch_code: getUserObj().branch_code,
  };
  const [search, setSearch] = useState(init);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [preview, setPreview] = useState(false);
  const [billSelectId, setBillSelectId] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "เลขที่ใบเสร็จ",
      className: "text-nowrap px-0",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "เลขที่ใบจอง",
      className: "text-nowrap px-0",
      dataIndex: "ref_document_no",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "วันที่",
      className: "text-nowrap px-0",
      dataIndex: "document_date",
      render: (text) => (
        <p className="m-0 text-left">
          {moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "รหัสสมาชิก",
      className: "text-nowrap px-0",
      dataIndex: "partner_code",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "ชื่อ",
      className: "text-nowrap px-0",
      dataIndex: "partner_name",
    },
    {
      title: "ประเภทใบเสร็จ",
      className: "text-nowrap px-0",
      dataIndex: "sales_type",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "สถานะ",
      className: "text-nowrap px-0",
      dataIndex: "document_status",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "สถานะการจัดส่ง",
      className: "text-nowrap px-0",
      render: (_text, { ship_status }) => (
        <p className="m-0 text-left">{ship_status}</p>
      ),
    },
    {
      title: "ประเภทการรับของ",
      className: "text-nowrap px-0",
      dataIndex: "req_receipt_type",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "พนักงานเปิดบิล",
      className: "text-nowrap px-0",
      dataIndex: "cashier_name",
    },
    {
      title: "ประเภทการซื้อ",
      className: "text-nowrap px-0",
      dataIndex: "order_type",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    // {
    //     title: 'หมายเหตุ',
    //     className: 'text-nowrap px-0',
    //     dataIndex: 'remark'
    // },
    {
      title: "",
      className: "text-nowrap px-0",
      render: (text, record) => (
        <p className="m-0 text-center text-nowrap">
          <Tooltip title="ดูรายละเอียด">
            <Button
              type="link"
              size="small"
              className="text-dark"
              onClick={() => {
                setBillSelectId(record.id);
                setPreview(true);
              }}
            >
              <FaEye />
            </Button>
          </Tooltip>
          <Tooltip title="พิมพ์">
            <Button
              type="link"
              size="small"
              className="text-dark"
              onClick={() => {
                // handlePrint(record)
                // receipt(record?.ref_document_id);
                printform("receipt", record);
              }}
            >
              <FaPrint />
            </Button>
          </Tooltip>
        </p>
      ),
    },
  ];

  const handleOnChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
    setPage(1);
    setTotal(0);
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
      axios({
        url: `${URL_API}/pos/operation/search/bill`,
        params: {
          ...search,
          limit,
          offset: (page - 1) * limit,
          date_from: search.date_from ? new Date(search.date_from) : null,
          date_to: search.date_to ? new Date(search.date_to) : null,
        },
      })
        .then((res) => {
          const { record, total } = res.data.data;
          setDataSource(record.map((n) => ({ ...n, key: n.id })));
          setTotal(total);
        })
        .catch((err) => resCatch(err))
        .finally(() => setLoading(false));
    }
  }, [visible, search, page]);

  return (
    <ModalControl
      visible={visible}
      width={1200}
      destroyOnClose={true}
      footerLine={false}
      onCancel={() => {
        setSearch(init);
        setDataSource([]);
        setTotal(0);
        setPage(1);
        onCancel();
      }}
    >
      <Typography.Title level={4} className="text-center mb-4">
        ข้อมูลใบเสร็จ
      </Typography.Title>
      <Row gutter={16} type="flex" className="mb-3">
        <Col span={18} className="d-flex align-items-center">
          <Input.Search
            allowClear
            name="query"
            placeholder="เลขที่ใบเสร็จ/เลขที่ใบจอง/เลขที่ชุดใบเสร็จ/เลขที่ชุดใบจอง/รหัส/ชื่อสมาชิก"
            value={search.query}
            onChange={handleOnChange}
            autoComplete="off"
          />
          <DatePicker.RangePicker
            className="ml-2"
            value={[search.date_from, search.date_to]}
            onChange={(e) => {
              if (e.length > 0) {
                setSearch({
                  ...search,
                  date_from: e[0],
                  date_to: e[1],
                });
              } else {
                setSearch({
                  ...search,
                  date_from: null,
                  date_to: null,
                });
              }
            }}
            format="DD/MM/YYYY"
            placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
          />
          <SelectBranch
            className="ml-2"
            userLogin={true}
            placeholder="เลือกสาขา"
            value={search.branch_code}
            onChange={(value) => {
              setSearch({
                ...search,
                branch_code: value,
              });
            }}
          />
        </Col>
        <Col span={6} className="d-flex align-items-center">
          <Typography.Text strong className="text-nowrap text-dark mr-3">
            สถานะใบเสร็จ
          </Typography.Text>
          <Radio
            id="normal"
            name="bill_status"
            spanChild="ปกติ"
            value="normal"
            checked={search.bill_status === "normal"}
            onChange={handleOnChange}
          />
          <Radio
            id="cancel"
            name="bill_status"
            spanChild="ยกเลิก"
            value="cancel"
            checked={search.bill_status === "cancel"}
            onChange={handleOnChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Spin spinning={loading}>
            <TableBill columns={columns} dataSource={dataSource} />
            <Pagination
              total={total}
              current={page}
              onChange={(e) => setPage(e)}
              className="text-center mt-4"
            />
          </Spin>
        </Col>
      </Row>

      <BillPreview
        id={billSelectId}
        preview={preview}
        setPreview={setPreview}
      />
    </ModalControl>
  );
};

export default React.memo(BillPopup);
