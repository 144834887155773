/* eslint-disable no-unused-vars */
import { Col, Input, Radio, Row } from "antd";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Creators } from "../ducks/operation-form";
import { _ } from "../../../../utils";

const Printform = ({ match }) => {
  const printform = useSelector(
    (state) => state.pos.operation.printform,
    shallowEqual
  );

  const dispatch = useDispatch();
  const type_params = match.params.type;
  const isNormal = _.includes(
    ["sale", "pre-sale", "subscription"],
    type_params
  );

  return (
    <div className="mb-3">
      <h3 className="mb-2 text-white">พิมพ์ใบเสร็จ</h3>
      <Row className="my-3 d-flex justify-content-between align-items-center">
        <Col
          span={12}
          className="d-flex justify-content-between align-items-center"
        >
          <strong className="text-white">คิวที่</strong>
        </Col>
        <Col
          span={12}
          className="d-flex justify-content-between align-items-center"
        >
          <Input
            onChange={(e) =>
              dispatch(Creators.changePrintform({ queue: e.target.value }))
            }
          />
        </Col>
      </Row>
      <Radio.Group
        className="text-white"
        onChange={(e) =>
          dispatch(
            Creators.changePrintform({
              form: e.target.value,
              // , queue: ""
            })
          )
        }
        value={printform.form}
      >
        <Radio className="text-white d-bloc mb-2" value="receipt">
          <strong>ใบเสร็จ A5</strong>
        </Radio>
        {isNormal ? (
          <Radio className="text-white d-block mb-2" value="another8">
          <strong>ใบเสร็จ Another8</strong>
        </Radio>
        ) : (
          <Radio className="text-white d-block mb-2" value="redeem">
            <strong>ใบเสร็จ Another8</strong>
          </Radio>
        )}
        {isNormal && (
          <Radio className="text-white d-block mb-2" value="customer">
            <strong>ใบเสร็จลูกค้า</strong>
          </Radio>
        )}
      </Radio.Group>
    </div>
  );
};

export default Printform;
