import React, { memo, useState } from "react";
import img from "../../../../../assets/img/pos/no-img.jpg";
import styles from "../../../../../scss/module/claim.module.scss";
import { _ } from "../../../../../utils";

const ImageItem = ({ azureUrl }) => {
  const [errImg, setErrImg] = useState(null);

  return (
    <div className={`${styles["new-upload"]} d-flex justify-content-center`}>
      <div className={`${styles.block} ${styles.img} ${styles.commit}`}>
        {azureUrl ? (
          <>
            <img
              className="object-fit-cover w-100 h-100"
              src={errImg || azureUrl}
              onError={() => setErrImg(img)}
              alt={_.uniqueId("img-")}
            />
          </>
        ) : (
          <>
            <img
              className="object-fit-cover w-100 h-100"
              src={img}
              alt={_.uniqueId("no-img-")}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(ImageItem);
