import legacyRequest from "../views/pages/manage-website/utils/request";

const manageWebsiteService = {
  async updateEcosystemRPHowtouse(myData) {
    try {
      console.log(myData);
      const res = await legacyRequest.post(myData.url_path, myData.formData);
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  // async uploadFile(formData) {
  //   try {
  //     if (formData) {
  //       const res = await legacyRequest.post(`/azure/image`, formData);

  //       return res.data.data;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     throw new Error(error.response.data.message);
  //   }
  // },

  async getHomeBannerById(id) {
    try {
      const res = await legacyRequest.get(`/manage-website/home/banner/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async getAllBranch() {
    try {
      const res = await legacyRequest.get(`/boss/inventory/all-branch`);
      if (res.status === 200) {
        return res.data.data.all_branch;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },


  async deleteHomeBannerById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/home/banner/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteHomeBenefitsById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/home/benefits/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteAwardById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/award/award/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteFAQById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(`/manage-website/faq/${id}/delete`);
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteNewsById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/news/news/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteNewsCategoryById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/news/category/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteNewsTagById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/news/tag/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async deleteHF_DetailById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/ecosystem/healthy-food/hf-detail/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteHF_NutritionById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/ecosystem/healthy-food/hf-nutrition/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteReviewIconById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/ecosystem/reward-point/rp-review/icon/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async deleteReviewReviewById(id) {
    try {
      console.log(id);
      const res = await legacyRequest.post(
        `/manage-website/ecosystem/reward-point/rp-review/review/${id}/delete`
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async getHomeBannerAll() {
    try {
      const res = await legacyRequest.get(`/manage-website/home/banner`);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async getHomeCardEcosysAll() {
    try {
      const res = await legacyRequest.get(
        `/manage-website/home/ecosystem/card-ecosystem`
      );
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async createHomeBanner(data) {
    try {
      console.log(data);
      const res = await legacyRequest.post(`/manage-website/home/banner`, data);
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async editHomeBanner({ id, data }) {
    try {
      console.log(id, data);
      const res = await legacyRequest.post(
        `/manage-website/home/banner/${id}/update`,
        data
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateHomeBannerStatusById({ record, checked }) {
    try {
      console.log(record.id, checked);
      const res = await legacyRequest.post(
        `/manage-website/home/banner/${record.id}/status`,
        { status: checked ? "active" : "inactive" }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateReviewIconSeqById({ myId, x }) {
    try {
      const res = await legacyRequest.post(
        `/manage-website/ecosystem/reward-point/rp-review/icon/${myId}/seq`,
        { seq: x }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateReviewReviewSeqById({ myId, x }) {
    try {
      const res = await legacyRequest.post(
        `/manage-website/ecosystem/reward-point/rp-review/review/${myId}/seq`,
        { seq: x }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateBestSellerSeqById({ myId, x }) {
    try {
      const res = await legacyRequest.post(
        `/manage-website/home/best-seller/${myId}/seq`,
        { seq: x }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateNutritionSeqById({ myId, x }) {
    try {
      const res = await legacyRequest.post(
        `/manage-website/ecosystem/healthy-food/hf-nutrition/${myId}/seq`,
        { seq: x }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateRankSeqById({ myId, x }) {
    try {
      const res = await legacyRequest.post(
        `/manage-website/award/rank/${myId}/seq`,
        { seq: x }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateNewsPinListSeqById({ myId, x }) {
    try {
      const res = await legacyRequest.post(
        `/manage-website/news/news/${myId}/seq`,
        { seq: x }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async getBestSellerAll() {
    try {
      const res = await legacyRequest.get(
        "/manage-website/home/best-seller-list"
      );
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },


  async getBestSellerBySelected() {
    try {
      const res = await legacyRequest.get(
        "/manage-website/home/best-seller/selected"
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async updateBestSellerCheckBox(data) {
    try {
      const res = await legacyRequest.post(
        `/manage-website/home/best-seller`,
        data
      );
      if (res.status === 201) {
        return res.data.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};

export default manageWebsiteService;
