/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LegacyPopUpPage from './pages/LegacyPopUpPage';
import LegacyPopUpPartnerListPage from './pages/LegacyPopUpPartnerListPage';
import LegacyNotificationPage from './pages/LegacyNotificationPage';
import LegacyRewardEventPage from './pages/LegacyRewardEventPage';
import LegacyRewardEventListPage from './pages/LegacyRewardEventListPage';
import LegacyPrizesPage from './pages/LegacyPrizesPage';
import LegacyPrizesItemPage from './pages/LegacyPrizesItemPage';
import LegacyPrizesItemPartnerListPage from './pages/LegacyPrizeItemPartnerListPage';

const queryClient = new QueryClient();

export default ({ match }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route
          path={`${match.path}/legacy-pop-up`}
          exact
          children={<LegacyPopUpPage />}
        />
        <Route
          path={`${match.path}/legacy-pop-up/:legacyPopUpId`}
          exact
          children={<LegacyPopUpPartnerListPage />}
        />
        <Route
          path={`${match.path}/legacy-notification`}
          exact
          children={<LegacyNotificationPage />}
        />
        <Route
          path={`${match.path}/legacy-reward-event`}
          exact
          children={<LegacyRewardEventPage />}
        />
        <Route
          path={`${match.path}/legacy-reward-event/:legacyRewardEventId`}
          exact
          children={<LegacyRewardEventListPage />}
        />
        <Route
          path={`${match.path}/legacy-prizes`}
          exact
          children={<LegacyPrizesPage />}
        />
        <Route
          path={`${match.path}/legacy-prizes/:giftEventId`}
          exact
          children={<LegacyPrizesItemPage />}
        />
        <Route
          path={`${match.path}/legacy-prizes/:giftEventId/item/:giftEventItemId`}
          exact
          children={<LegacyPrizesItemPartnerListPage />}
        />
      </Switch>
    </QueryClientProvider>
  );
};
