/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Select } from "antd";
import React, { useEffect } from "react";
import { useHttp } from "../hooks/http";
import { URL_API, _ } from "../utils";

const SelectPeriod = ({
  className = "",
  callbackData,
  withAll = false,
  ...props
}) => {
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/period`,
    },
    []
  );

  useEffect(() => {
    if (data?.data) {
      callbackData && callbackData(data.data);
    }
  }, [data?.data]);

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Select
      loading={loading}
      className={`w-100 ${className}`}
      dropdownStyle={{ zIndex: 1080 }}
      {...props}
    >
      {withAll ? <Select.Option value="">ทั้งหมด</Select.Option> : null}
      {_.map(data?.data, (n, i) => (
        <Select.Option key={i} value={n.period}>
          {n.period}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectPeriod;
