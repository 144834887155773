/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axios, URL_API } from "../../../../utils";
import { resCatch } from "../../../util/helper";
import { Creators } from "../ducks/operation-form";

const PickAndPay = () => {
  const dispatch = useDispatch();
  const [source, setSource] = useState(null);
  const { bill_branch_code, receipt_branch_code } = useSelector((state) => {
    const {
      orders: { branch, activeKey, itemByKey },
    } = state.pos.operation;

    return {
      bill_branch_code: branch.code,
      receipt_branch_code: activeKey
        ? itemByKey[activeKey].receipt_branch_code
        : null,
    };
  });

  const setBillValue = (e) => dispatch(Creators.setBillValue(e));
  const setLoading = (e) => dispatch(Creators.setLoading({ pick_and_pay: e }));

  const handleFetchPickAndPay = async () => {
    let res = null;
    setLoading(true);
    try {
      res = await axios({
        url: `${URL_API}/pos/master/check/pick_and_pay`,
        params: {
          code: receipt_branch_code,
        },
        cancelToken: source.token,
      });
      const isPickAndPay = !!res.data.data?.result;
      const ship_status = isPickAndPay ? "3" : "0";
      const allow_qr = receipt_branch_code === bill_branch_code || isPickAndPay ? true : false;
      setBillValue({
        ship_status,
        allow_qr,
      });
    } catch (e) {
      resCatch(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (receipt_branch_code) {
      source && source.cancel("cancel");
      setSource(axios.CancelToken.source());
    }
  }, [receipt_branch_code, bill_branch_code]);

  useEffect(() => {
    source && handleFetchPickAndPay();
  }, [source]);
};

export default PickAndPay;
