/* eslint-disable eqeqeq */
import { Button, Card, Tabs } from "antd";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { actionEthics } from "../../../../../redux/slices/ethics";
import FormMember from "../Form/FormMember";
import SponsorPanel from "../Panel/SponsorPanel";
import UplinePanel from "../Panel/UplinePanel";

function InfoTabs({ ...props }, ref) {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const active_info = useSelector(
    (state) => state.ethics.form.active_tab.info,
    shallowEqual
  );
  const { married} = useSelector((state) => state.ethics.form, shallowEqual);
  const { 
    OPEN_MODAL, 
    SET_ACTIVE_TAB,
  } = actionEthics;

  const memberRef = useRef();
  const marriedRef = useRef();

  const submit = async (form) => {
    try {
      switch (form) {
        case "member":
          return (
            memberRef.current &&
            (await memberRef.current.validateFieldsAndScroll())
          );
        case "married":
          return (
            marriedRef.current &&
            (await marriedRef.current.validateFieldsAndScroll())
          );
        default:
          return false;
      }
    } catch (error) {
      return error;
    }
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      if (married) {
        const validate_member = await submit("member");
        const validate_married = await submit("married");
        return {
          member: validate_member,
          married: validate_married,
        };
      } else {
        const validate_member = await submit("member");
        return {
          member: validate_member,
        };
      }
    },
  }));

  return (
    <Card
      bordered={false}
      size="small"
      className="mb-3"
      style={{ boxShadow: "0px 2px 4px #ccc" }}
    >
      <Tabs
        size="small"
        activeKey={active_info}
        onChange={(e) => dispatch(SET_ACTIVE_TAB({ key: "info", value: e }))}
        tabBarGutter={0}
        tabBarExtraContent={ active_info === 'member' || active_info === 'married' ?
          <Button
            size="small"
            type="link"
            onClick={() => dispatch(OPEN_MODAL({ name: "profile" }))}
          >
            History
          </Button> : ''
        }
      >
        <Tabs.TabPane tab="ผู้สมัครหลัก" key="member" forceRender={true}>
          <FormMember ref={memberRef} data_type="member" />
        </Tabs.TabPane>
        {match.params.type === "distributor" && (
          <Tabs.TabPane
            tab={
              <span
                style={
                  married
                    ? married.status == "Active"
                      ? { color: "#97d700" }
                      : married.status != "Active"
                      ? { color: "#8d8d8d" }
                      : { color: "#D2D2D2" }
                    : { color: "#D2D2D2" }
                }
              >
                ผู้สมัครร่วม
              </span>
            }
            key="married"
            forceRender={true}
            // disabled={married == null}
          >
            <FormMember ref={marriedRef} data_type="married" />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="ผู้แนะนำ" key="sponsor">
          <SponsorPanel />
        </Tabs.TabPane>
        {match.params.type === "distributor" && (
          <Tabs.TabPane tab="อัพไลน์" key="upline">
            <UplinePanel />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Card>
  );
}

export default forwardRef(InfoTabs);
