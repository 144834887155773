import { Alert, Button, Pagination } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { FaEye, FaCodepen } from "react-icons/fa";
import PrintButton from "../../../components/PrintButton";
import { useHttp } from "../../../hooks/http";
import { getUserObj, URL_API } from "../../../utils";
import BillPreview from "../pos/components/BillPreview";
import BillModal from "./components/BillModal";
import PreviewModal from "./components/PreviewModal";
import Table from "./components/Table";
import ArrangeContext from "./reducer";
import { printform } from "../../../utils/printforms";

const Waiting = () => {
  const user = getUserObj();
  const {
    state: {
      search: { page, limit, bill_branch_code, date_from, date_to, query },
    },
    dispatch,
  } = useContext(ArrangeContext);
  const [preview, setPreview] = useState(false);
  const [billSelectedId, setBillSelectedId] = useState(null);
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/arrange/bill`,
      token: true,
      params: {
        limit,
        query,
        bill_branch_code,
        offset: limit * (page - 1),
        date_from: date_from && moment(date_from).toDate(),
        date_to: date_to && moment(date_to).toDate(),
        branch_code: user.branch_code,
      },
    },
    [
      limit,
      page,
      query,
      date_from,
      date_to,
      bill_branch_code,
      user.branch_code,
      user.branch_name,
    ]
  );

  const columns = [
    {
      title: "เลขที่บิล",
      key: "document_no",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "วันที่สั่งซื้อ",
      key: "document_date",
      dataIndex: "document_date",
      render: (text) => (
        <p className="m-0 text-left">
          {text == null ? "" : moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "ผู้ดำเนินการ",
      key: "buyer_name",
      dataIndex: "buyer_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ผู้สั่งซื้อ",
      key: "partner_name",
      dataIndex: "partner_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "แหล่งที่มา",
      key: "bill_branch_name",
      dataIndex: "bill_branch_name",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "สถานะใบเสร็จ",
      key: "document_status_text",
      dataIndex: "document_status_text",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "ประเภท",
      key: "sales_type",
      dataIndex: "sales_type",
      render: (text) => (
        <p className="m-0 text-center">
          {text === "sale"
            ? "ขาย"
            : text === "redeem"
            ? "แลก"
            : text === "requisition"
            ? "ใบเบิกสินค้า"
            : text === "claim"
            ? "เคลม"
            : `${text}`}
        </p>
      ),
    },
    {
      title: "รับสินค้า",
      key: "receipt_type",
      render: (text, record) => (
        <p className="m-0 text-center">
          {(record?.receipt_branch_code === record?.transport_code) && record?.document_type === "bill"
            ? "จัดส่ง"
            : "รับที่สาขา"}
        </p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_text, record) => (
        <p className="m-0 d-flex justify-content-end align-items-center">
           {record?.document_type === "bill" && (record?.receipt_branch_code === record?.transport_code) && (
            <Button
              shape="circle"
              size="small"
              className="mr-2 text-white"
              style={{ backgroundColor: "#026E9C", borderColor: "#026E9C" }}
              onClick={() => {
                printform("delivery", record);
              }}
            >
              <FaCodepen />
            </Button>
          )}
          {record?.document_type === "bill" && (
            <Button
              shape="circle"
              size="small"
              type="primary"
              className="mr-2"
              disabled={record?.document_type !== "bill"}
              onClick={() => {
                setBillSelectedId(record.id);
                setPreview(true);
              }}
            >
              <FaEye />
            </Button>
          )}
          <PrintButton
            record={record}
            reportName={
              record?.document_type === "requisition"
                ? "stock_tracking"
                : "receipt"
            }
          />
          <Button
            size="small"
            type="primary"
            className="ml-3"
            onClick={() =>
              dispatch({
                type: "setGlobalState",
                name: "selected",
                payload: { key: record.key, type: record.document_type },
              })
            }
          >
            เตรียมของ
          </Button>
        </p>
      ),
    },
  ];

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.data || []}
      />

      <Pagination
        pageSize={limit}
        current={page}
        total={data?.total || 0}
        className="text-center mt-3"
        hideOnSinglePage={true}
        onChange={(page) =>
          dispatch({
            type: "setGlobalState",
            name: "search",
            payload: { page },
          })
        }
      />

      <BillModal reload={reload} />

      <PreviewModal reload={reload} />

      <BillPreview
        id={billSelectedId}
        preview={preview}
        setPreview={setPreview}
      />
    </>
  );
};

export default Waiting;
