import React, { memo, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const PromotionDetail = ({ list }) => {
  const [expand, setExpand] = useState(true);
  return (
    <>
      <p className="mb-0 mt-2 pl-3">
        <span
          className="cursor-pointer"
          onClick={() => setExpand((prevState) => !prevState)}
        >
          รายการโปรโมชัน {expand ? <FaAngleUp /> : <FaAngleDown />}
        </span>
      </p>
      {expand &&
        list.map((n, i) => (
          <p className="m-0 pl-4" key={i}>
            {`${i + 1}.  ${n.name}`}
          </p>
        ))}
    </>
  );
};

export default memo(PromotionDetail);
