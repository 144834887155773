import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const visitFitStudioPaperQueryDefaultValues = {
  formType: undefined,
  memberStatus: undefined,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  idCard: '',
  legacyPartnerCode: '',
  legacyConsultantPartnerCode: '',
  from: '',
  to: '',
  fitnessBranchId: undefined,
};

export const visitFitStudioPaperQueryFormSchema = () => {
  return yupResolver(
    yup
      .object()
      .shape(Object.assign({}))
      .test('selectedOnce', 'Error', function (value) {
        const { createError } = this;
        if (
          value.formType ||
          value.memberStatus ||
          value.firstName ||
          value.lastName ||
          value.phoneNumber ||
          value.idCard ||
          value.legacyPartnerCode ||
          value.legacyConsultantPartnerCode ||
          value.from ||
          value.to ||
          value.fitnessBranchId
        ) {
          return true;
        } else {
          return createError({
            path: 'searchError',
            message: 'กรุณาใส่ข้อมูลอย่างใดอย่างหนึ่ง',
          });
        }
      })
  );
};

export const VisitFitStudioFormType = ['visit', 'experiment'];

export const VisitFitStudioMemberStatus = [
  'legacy_member',
  'not_legacy_member_have_consultant',
  'not_legacy_member_no_consultant',
];

export const VisitorFormTypeDefaultValues = {
  formType: VisitFitStudioFormType[0],
  memberStatus: VisitFitStudioMemberStatus[0],
  branchId: '',
  idCard: '',
  legacyPartnerCode: '',
  legacyConsultantPartnerCode: '',
  nameTitle: 'mr',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  knowFrom: [],
  knowFromOther: '',
};

export const VisitorFormTypeSchema = () => {
  return yupResolver(
    yup.object().shape({
      formType: yup.string().required(),
      memberStatus: yup.string().required(),
      idCard: yup.string().required(),
      legacyPartnerCode: yup.string().when('memberStatus', {
        is: (value) => value === VisitFitStudioMemberStatus.legacy_member,
        then: yup.string().min(9).max(9).required(),
      }),
      legacyConsultantPartnerCode: yup.string().when('memberStatus', {
        is: (value) =>
          value ===
          VisitFitStudioMemberStatus.not_legacy_member_have_consultant,
        then: yup.string().min(9).max(9).required(),
      }),
      nameTitle: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phoneNumber: yup.string().required(),
      knowFrom: yup.array().max(2),
      knowFromOther: yup.string().when('knowFrom', {
        is: (value) => value.includes('other'),
        then: yup.string().required(),
      }),
    })
  );
};
