/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Typography,
} from "antd";
import { Pagination, Row, Table } from "antdV4";
import numeral from "numeral";
import React, { useEffect, useMemo, useState } from "react";
import { FaCogs, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useQuery } from "../../../../../hooks/dom";
import { actionInventoryCount } from "../../../../../redux/slices/inventory-count";
import { _, moment } from "../../../../../utils";
import { enterFocusNext } from "../../../../util/helper";

const { Text } = Typography;
const { Column } = Table;

const {
  setWarehouseItemValue,
  addWarehouseItemLot,
  delWarehouseItemLot,
  setSearchWarehouse,
  setExcelData,
} = actionInventoryCount;

const type = "primary";
const Primary = ({ match, dataSource }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const type_params = match.params.type;
  const { itemByKey } = useSelector((state) => state.inventoryCount[type]);
  const { status: warehouse_status } = useSelector(
    (state) => state.inventoryCount.warehouse.itemByKey[type]
  );

  const is_preview = query.get("preview") === "1";
  const disabledDraft = warehouse_status === "pending" || is_preview;
  const isCycle = type_params === "cycle";

  const limit = 50;
  const [page, setPage] = useState(1);

  const handleChangeCount = (name, value, key) => {
    if (value === null || _.isNaN(+value)) return;
    value = value || 0;
    const record = itemByKey[key];
    let count = _.filter(
      ["shelf", "storage_shelf", "warehouse"],
      (n) => n !== name
    );
    const main = _.find(
      dataSource,
      (n) => n.is_first && n.product_id === record.product_id
    );
    const total_count =
      _.reduce(count, (res, property) => res + record[property], 0) + value;
    const other_total_count = _.reduce(
      _.filter(
        dataSource,
        (n) => n.product_id === record.product_id && n.key !== key
      ),
      (res, n) => res + n.total_count,
      0
    );
    const total_all_count = other_total_count + total_count;
    const difference = total_all_count - main.total_warehouse;
    dispatch(
      setWarehouseItemValue({
        type,
        key: main.key,
        difference,
        total_all_count,
      })
    );
    dispatch(setWarehouseItemValue({ type, key, [name]: value, total_count }));
  };

  const handleChangeLot = (name, value, key) => {
    dispatch(
      setWarehouseItemValue({
        type,
        key,
        [name]: value,
      })
    );
  };

  const data_without_kitpack = useMemo(() => {
    return _.filter(dataSource, (n) => n.product_type_set !== "Kit/Package");
  }, [dataSource]);

  function paginateData(pageNumber, pageSize) {
    let renderData = [];
    let mainData = [];
    const data = data_without_kitpack;
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const mainItems = _.filter(data, { is_first: 1 });
    for (let i in mainItems) {
      const main = mainItems[i];
      if (i >= startIndex && i < endIndex) {
        mainData.push(main);
      }
    }
    for (let i in mainData) {
      const item = mainData[i];
      renderData.push(item);
      const subItems = _.filter(data, {
        is_first: 0,
        product_code: item?.product_code,
      });
      for (let j in subItems) {
        const sub = subItems[j];
        renderData.push(sub);
      }
    }
    return renderData;
  }
  const paginatedData = paginateData(page, limit);
  useEffect(() => {
    dispatch(setExcelData(data_without_kitpack));
  }, [dataSource]);
  return (
    <>
      <Table
        bordered
        size="small"
        dataSource={paginatedData}
        rowKey="key"
        className="ant-table-pagination-center table-very-small with-input"
        scroll={{ x: "max-content" }}
        pagination={false}
      >
        {/* <Column
          title={<div style={{ fontSize: "10px" }}>No.</div>}
          width={18}
          render={(text, record, index) => (page - 1) * limit + (index + 1)}
        /> */}
        <Column
          width={50}
          className="position-relative"
          title={<div style={{ fontSize: "10px" }}>รหัสสินค้า</div>}
          key="product_code"
          dataIndex="product_code"
          align="center"
          render={(text, { is_first }) => !!is_first && <Text>{text}</Text>}
        />
        <Column
          width={172}
          className="position-relative"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center max-width-600"
            >
              ชื่อสินค้า
            </div>
          }
          key="product_name"
          dataIndex="product_name"
          render={(text, { is_first }) => !!is_first && <Text>{text}</Text>}
        />
        <Column
          width={60}
          className="position-relative"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center"
            >
              คลังดี
            </div>
          }
          key="main"
          dataIndex="main"
          align="right"
          render={(text, { is_first }) =>
            !!is_first && <Text>{numeral(text).format()}</Text>
          }
        />
        {/* :: // #923 - ตัด arrange (ค้างจ่าย) ออก :: */}
        {/* <Column
            width={60}
            className="position-relative"
            title={
              <div className="ant-table-absolute-title-center">ค้างจ่าย</div>
            }
            key="arrange"
            dataIndex="arrange"
            align="right"
            render={(text, { is_first }) =>
              !!is_first && <Text>{numeral(text).format()}</Text>
            }
          /> */}
        <Column
          width={62}
          className="position-relative"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center"
            >
              ยอดจอง
            </div>
          }
          key="reserve"
          dataIndex="reserve"
          align="right"
          render={(text, { is_first }) =>
            !!is_first && <Text>{numeral(text).format()}</Text>
          }
        />
        <Column
          width={80}
          className="position-relative"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center"
            >
              ยอดเบิกรออนุมัติ
            </div>
          }
          key="requisition"
          dataIndex="requisition"
          align="right"
          render={(text, { is_first }) =>
            !!is_first && <Text>{numeral(text).format()}</Text>
          }
        />
        <Column
          width={58}
          className="position-relative"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center"
            >
              รวม
            </div>
          }
          key="total_warehouse"
          dataIndex="total_warehouse"
          align="right"
          render={(text, { is_first }) =>
            !!is_first && <Text>{numeral(text).format()}</Text>
          }
        />
        {!is_preview && isCycle && !disabledDraft && (
          <Column
            title={<FaCogs size={12} />}
            align="center"
            width={54}
            key="action"
            render={(_text, { is_first, is_default, key, product_code }) =>
              is_first ? (
                <Popconfirm
                  title={`ต้องการเพิ่ม Lot รหัสสินค้า ${product_code} หรือไม่`}
                  onConfirm={() => dispatch(addWarehouseItemLot({ type, key }))}
                  okText="ตกลง"
                  cancelText="ยกเลิก"
                  placement="top"
                  zIndex={1080}
                >
                  <Button size="small" ghost type="primary">
                    <FaPlus size={10} />
                  </Button>
                </Popconfirm>
              ) : (
                !is_default && (
                  <Popconfirm
                    title="ต้องการลบรายการนี้หรือไม่"
                    onConfirm={() =>
                      dispatch(delWarehouseItemLot({ type, key }))
                    }
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                    placement="topRight"
                    zIndex={1080}
                  >
                    <Button size="small" ghost type="danger">
                      <FaTrashAlt size={10} />
                    </Button>
                  </Popconfirm>
                )
              )
            }
          />
        )}
        <Column
          width={70}
          className="px-1"
          title={<div style={{ fontSize: "10px" }}>หน้าร้าน</div>}
          key="shelf"
          dataIndex="shelf"
          align="right"
          render={(text, { key, is_primary_set }) =>
            is_primary_set ? (
              text
            ) : (
              <InputNumber
                size="small"
                disabled={disabledDraft}
                min={0}
                value={text}
                defaultValue={0}
                onFocus={(e) => e.target.select()}
                onKeyUp={enterFocusNext}
                onChange={(value) => handleChangeCount("shelf", value, key)}
              />
            )
          }
        />
        <Column
          width={70}
          className="px-1"
          title={<div style={{ fontSize: "10px" }}>ชั้นพัก</div>}
          key="storage_shelf"
          dataIndex="storage_shelf"
          align="right"
          render={(text, { key, is_primary_set }) =>
            is_primary_set ? (
              text
            ) : (
              <InputNumber
                size="small"
                disabled={disabledDraft}
                min={0}
                value={text}
                onFocus={(e) => e.target.select()}
                onKeyUp={enterFocusNext}
                onChange={(value) =>
                  handleChangeCount("storage_shelf", value, key)
                }
              />
            )
          }
        />
        <Column
          width={70}
          className="px-1"
          title={<div style={{ fontSize: "10px" }}>ในคลัง</div>}
          key="warehouse"
          dataIndex="warehouse"
          align="right"
          render={(text, { key, is_primary_set }) =>
            is_primary_set ? (
              text
            ) : (
              <InputNumber
                size="small"
                disabled={disabledDraft}
                className="text-right"
                min={0}
                value={text}
                onKeyUp={enterFocusNext}
                onFocus={(e) => e.target.select()}
                onChange={(value) => handleChangeCount("warehouse", value, key)}
              />
            )
          }
        />
        <Column
          width={58}
          className="position-relative px-1"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center"
            >
              รวม
            </div>
          }
          key="total_count"
          dataIndex="total_count"
          align="right"
          render={(text) => <Text strong>{numeral(text).format()}</Text>}
        />
        <Column
          width={60}
          className="position-relative px-1"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center"
            >
              ยอดรวม
            </div>
          }
          key="total_all_count"
          dataIndex="total_all_count"
          align="right"
          render={(text, record) => {
            const obj = {
              children: <Text strong>{numeral(text).format()}</Text>,
              props: {},
            };
            if (record.is_first) {
              obj.props.rowSpan = _.filter(
                dataSource,
                (n) => n.product_id === record.product_id
              ).length;
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }}
        />
        <Column
          width={60}
          className="position-relative px-1"
          title={
            <div
              style={{ fontSize: "10px" }}
              className="ant-table-absolute-title-center"
            >
              ผลต่าง
            </div>
          }
          key="difference"
          dataIndex="difference"
          align="right"
          render={(text, record) => {
            const obj = {
              children: (
                <Text
                  strong
                  className={`${
                    text > 0 ? "text-tp" : text < 0 ? "text-reds" : ""
                  }`}
                >
                  {numeral(text).format()}
                </Text>
              ),
              props: {},
            };
            if (record.is_first) {
              obj.props.rowSpan = _.filter(
                dataSource,
                (n) => n.product_id === record.product_id
              ).length;
            } else {
              obj.props.rowSpan = 0;
            }
            return obj;
          }}
        />
        {isCycle && (
          <Column
            width={72}
            className="px-1"
            title={<div style={{ fontSize: "10px" }}>Lot.</div>}
            key="lot"
            dataIndex="lot"
            align="right"
            render={(text, { key, is_primary_set }) =>
              is_primary_set ? (
                text
              ) : (
                <Input
                  size="small"
                  autoComplete="off"
                  disabled={disabledDraft}
                  value={text}
                  maxLength={100}
                  onKeyUp={enterFocusNext}
                  onChange={(e) => handleChangeLot("lot", e.target.value, key)}
                />
              )
            }
          />
        )}
        {isCycle && (
          <Column
            width={92}
            className="px-1"
            title={<div style={{ fontSize: "10px" }}>วันผลิต</div>}
            key="mfg_date"
            dataIndex="mfg_date"
            align="right"
            render={(text, { key, is_primary_set }) =>
              is_primary_set ? (
                text
              ) : (
                <DatePicker
                  size="small"
                  placeholder=""
                  format="DD/MM/YYYY"
                  disabled={disabledDraft}
                  value={text ? moment(text) : null}
                  onChange={(value) => {
                    handleChangeLot("mfg_date", value, key);
                  }}
                />
              )
            }
          />
        )}
        {isCycle && (
          <Column
            width={92}
            className="px-1"
            title={<div style={{ fontSize: "10px" }}>วันหมดอายุ</div>}
            key="exp_date"
            dataIndex="exp_date"
            align="right"
            render={(text, { key, is_primary_set }) =>
              is_primary_set ? (
                text
              ) : (
                <DatePicker
                  size="small"
                  placeholder=""
                  format="DD/MM/YYYY"
                  disabled={disabledDraft || is_primary_set === 1}
                  value={text ? moment(text) : null}
                  onChange={(value) => handleChangeLot("exp_date", value, key)}
                />
              )
            }
          />
        )}
        <Column
          className="px-1"
          title={<div style={{ fontSize: "10px" }}>หมายเหตุ</div>}
          key="remark"
          dataIndex="remark"
          align="center"
          width={80}
          render={(text, { key, is_primary_set }) =>
            is_primary_set ? (
              text
            ) : (
              <Input
                size="small"
                autoComplete="off"
                disabled={disabledDraft}
                value={text}
                maxLength={500}
                onKeyUp={enterFocusNext}
                onChange={(e) => handleChangeLot("remark", e.target.value, key)}
              />
            )
          }
        />
      </Table>
      <Row className="w-100 my-4 d-flex justify-content-center">
        <Pagination
          current={page}
          pageSize={limit}
          total={data_without_kitpack.length}
          hideOnSinglePage
          showSizeChanger={false}
          onChange={(page) => {
            setPage(page);
          }}
        />
      </Row>
    </>
  );
};

export default withRouter(Primary);
