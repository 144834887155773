/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
  Table,
} from "antd";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API, axios } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
// import Summary from '../components/Summary';
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import SelectOption from "../../../pos/components/SelectOption";

const { Search } = Input;

const Index = () => {
  const user = getUser();
  const [page, setPage] = useState(1);
  const limit = 50;
  const toDay = moment().format("DD/MM/YYYY");

  const [state, dispatch] = useReducer(reducer, initState);

  //filter
  const [memberTypeFilter, setMemberTypeFilter] = useState("");
  const [voucherTypeFilter, setVoucherTypeFilter] = useState("");
  const [discountTypeFilter, setDiscountTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [usedDateFrom, setUsedDateFrom] = useState("");
  const [usedDateTo, setUsedDateTo] = useState("");
  const [voucherDateFrom, setVoucherDateFrom] = useState("");
  const [voucherDateTo, setVoucherDateTo] = useState("");

  const memberTypeOptions = [
    { value: "Distributor", text: "นักธุรกิจ" },
    { value: "Member", text: "VIP MEMBER" },
  ];
  const voucherTypeOptions = [
    { value: "voucher", text: "VOUCHER" },
    { value: "coupon", text: "COUPON" },
  ];
  const discountTypeOptions = [
    { value: 1, text: "ส่วนลดเงินสด" },
    { value: 2, text: "ส่วนลดเปอร์เซ็น" },
    { value: 3, text: "ส่วนลดค่าจัดส่ง" },
  ];
  const statusOptions = [
    { value: 1, text: "ยังไม่ใช้งาน" },
    { value: 2, text: "ถูกใช้งานไปแล้ว" },
  ];

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/account/voucher`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        ...state.filter,
        used_date_from: usedDateFrom
          ? moment(usedDateFrom).format("YYYY-MM-DD")
          : null,
        used_date_to: usedDateTo
          ? moment(usedDateTo).format("YYYY-MM-DD")
          : null,
        voucher_date_from: voucherDateFrom
          ? moment(voucherDateFrom).format("YYYY-MM-DD")
          : null,
        voucher_date_to: voucherDateTo
          ? moment(voucherDateTo).format("YYYY-MM-DD")
          : null,
        member_type: memberTypeFilter,
        voucher_type: voucherTypeFilter,
        discount_type: discountTypeFilter,
        status: statusFilter,
      },
      initialLoad: false,
    },
    []
  );

  const exportToExcel = () => {
    let check = data != null ? (data?.data?.length > 0 ? true : false) : false;
    if (check) {
      let excelData = [
        ["รายงานบัตรกำนัล"],
        [""],
        [
          `ค้นหา : `,
          "",
          "",
          `วันที่ได้รับบัตร : จาก ${moment(voucherDateFrom).format(
            "L"
          )} ถึง ${moment(voucherDateTo).format("L")}`,
          "",
          "",
          `วันที่ใช้บัตร : จาก ${moment(usedDateFrom).format(
            "L"
          )} ถึง ${moment(usedDateTo).format("L")}`,
          "",
          "",
          
          `วันที่ดึงข้อมูล : ${moment().format("LLLL")}`,
        ],
        [
          `ประเภทสมาชิก : ${
            memberTypeFilter === ""
              ? "ทั้งหมด"
              : memberTypeOptions.find((e) => e.value === memberTypeFilter)
                  ?.text
          }`,
          "",
          "",

          `ประเภทบัตรกำนัล : ${
            voucherTypeFilter === ""
              ? "ทั้งหมด"
              : voucherTypeOptions.find((e) => e.value === voucherTypeFilter)
                  ?.text
          }`,
          "",
          "",

          `ประเภทส่วนลด : ${
            discountTypeFilter === ""
              ? "ทั้งหมด"
              : discountTypeOptions.find((e) => e.value === discountTypeFilter)
                  ?.text
          }`,
          "",
          "",

          `สถานะ : ${
            statusFilter === ""
              ? "ทั้งหมด"
              : statusOptions.find((e) => e.value === statusFilter)?.text
          }`,
          "",
          "",
        ],
        [""],
        [
          "ลำดับ",
          "ประเภทสมาชิก",
          "รหัสสมาชิก",
          "ชื่อ",
          "นามสกุล",
          "ประเภทบัตรกำนัล",
          "รหัสบัตรกำนัล",
          "ชื่อบัตรกำนัล",
          "ประเภทบัตรส่วนลด",
          "มูลค่าบัตรกำนัล",
          "การได้รับคะแนน",
          "เวลาเริ่มต้น",
          "เวลาสิ้นสุด",
          "วันที่ได้รับบัตรกำนัล",
          "วันที่ใช้บัตรกำนัล",
          "เลขที่ใบเสร็จ",
          "มูลค่าบัตรกำนัลที่ใช้",
          "สถานะ",
        ],
      ];

      (data?.data || []).forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.partner_type,
            n.partner_code,
            n.first_name_th,
            n.sur_name_th,
            n.voucher_type,
            n.voucher_code,
            n.voucher_name,
            n.discount_type_text,
            {
              v:
                n.voucher_value === "" || n.voucher_value === null
                  ? 0
                  : n.voucher_value,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            n.collect_point === 1 ? "ได้รับคะแนน" : "ไม่ได้รับคะแนน",
            {
              v: n.start_date ? moment(n.start_date).format("LL") : "",
            },
            {
              v: n.end_date
                ? moment(n.end_date).format("LL")
                : "ไม่มีวันหมดอายุ",
            },
            {
              v: n.voucher_date
                ? moment(n.voucher_date).format("LL")
                : "",
            },
            {
              v: n.document_date
                ? moment(n.document_date).format("LL")
                : "",
            },
            n.ref_document_no,
            {
              v:
                n.voucher_used_value === "" || n.voucher_used_value === null
                  ? 0
                  : n.voucher_used_value,
              t: "n",
              s: { numFmt: "#,##0.00", alignment: { horizontal: "right" } },
            },
            n.status === 1
              ? "ยังไม่ได้ใช้งาน"
              : n.status === 2
              ? "ถูกใช้งานไปแล้ว"
              : "",
          ],
        ];
      });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
        { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
        { s: { r: 2, c: 6 }, e: { r: 2, c: 8 } },
        { s: { r: 2, c: 9 }, e: { r: 2, c: 11 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 2 } },
        { s: { r: 3, c: 3 }, e: { r: 3, c: 5 } },
        { s: { r: 3, c: 6 }, e: { r: 3, c: 8 } },
        { s: { r: 3, c: 9 }, e: { r: 3, c: 10 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 20 },
        { wch: 40 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(excelData);

      ws["!cols"] = wscols;

      ws["!merges"] = merge;

      XLSX.utils.book_append_sheet(wb, ws, "รายงานบัตรกำนัล");
      XLSX.writeFile(wb, "รายงานบัตรกำนัล.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานบัตรกำนัล</PanelHeader>
        <PanelBody>
          <Row className="mb-2">
            <Col span={6}>
              <Search
                className="w-100 "
                placeholder="รหัสสมาชิก, ชื่อสมาชิก, รหัสบัตรกำนัล, ชื่อบัตรกำนัล"
                onChange={(e) =>
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  })
                }
                // onSearch={reload}
                // onPressEnter={reload}
                // allowClear
              />
            </Col>
            <Col span={2} className="mb-2">
              <Text
                className="text-center d-block mr-3"
                strong
                style={{ lineHeight: "32px", float: "right" }}
              >
                วันที่ใช้บัตร
              </Text>
            </Col>
            <Col span={4}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="DD/MM/YYYY"
                onChange={(e) => {
                  setUsedDateFrom(e[0] || "");
                  setUsedDateTo(e[1] || "");
                }}
              />
            </Col>
            <Col span={2} className="mb-2">
              <Text
                className="text-center d-block mr-3"
                strong
                style={{ lineHeight: "32px", float: "right" }}
              >
                วันที่ได้รับบัตร
              </Text>
            </Col>
            <Col span={4}>
              <DatePicker.RangePicker
                className="w-100"
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="DD/MM/YYYY"
                onChange={(e) => {
                  setVoucherDateFrom(e[0] || "");
                  setVoucherDateTo(e[1] || "");
                }}
              />
            </Col>
            <Col span={6}>
              <Row gutter={8} className="ml-4">
                <Col span={12} className="mb-2">
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                    block
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col span={12} className="mb-2">
                  <Button
                    type="primary"
                    icon="file-excel"
                    onClick={exportToExcel}
                    block
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>ประเภทสมาชิก</Text>
            </Col>
            <Col span={3}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={memberTypeFilter}
                options={memberTypeOptions}
                onChange={(e) => {
                  setMemberTypeFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>ประเภทบัตรกำนัล</Text>
            </Col>
            <Col span={3}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={voucherTypeFilter}
                options={voucherTypeOptions}
                onChange={(e) => {
                  setVoucherTypeFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>ประเภทส่วนลด</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={discountTypeFilter}
                options={discountTypeOptions}
                onChange={(e) => {
                  setDiscountTypeFilter(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>สถานะ</Text>
            </Col>
            <Col span={3}>
              <SelectOption
                withAll
                className="w-100"
                defaultValue={statusFilter}
                options={statusOptions}
                onChange={(e) => {
                  setStatusFilter(e);
                }}
              />
            </Col>
          </Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={!error ? (data ? data.data : []) : []}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: !error ? (data ? data.data.length : 0) : 0,
              showLessItems: false,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="row_id"
              key="index"
              align="center"
            />
            <Table.Column
              title="ประเภทสมาชิก"
              dataIndex="partner_type"
              render={(e) => {
                return e ? (e === "Member" ? "VIP MEMBER" : "นักธุรกิจ") : "";
              }}
              align="left"
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="left"
            />
            <Table.Column title="ชื่อ" dataIndex="first_name_th" align="left" />
            <Table.Column
              title="นามสกุล"
              dataIndex="sur_name_th"
              align="left"
            />
            <Table.Column
              title="ประเภทบัตรกำนัล"
              dataIndex="voucher_type"
              render={(e) => {
                return e ? (e === "voucher" ? "VOUCHER" : "COUPON") : "";
              }}
              align="center"
            />
            <Table.Column
              title="รหัสบัตรกำนัล"
              dataIndex="voucher_code"
              align="left"
            />
            <Table.Column
              title="ชื่อบัตรกำนัล"
              dataIndex="voucher_name"
              align="left"
            />
            <Table.Column
              title="ประเภทบัตรส่วนลด"
              dataIndex="discount_type_text"
              align="left"
            />
            <Table.Column
              title="มูลค่าบัตรกำนัล"
              dataIndex="voucher_value"
              render={(e) => {
                return numeral(e).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="การได้รับคะแนน"
              dataIndex="collect_point"
              render={(e) => {
                return e === 1 ? "มีคะแนน" : "ไม่มีคะแนน";
              }}
              align="center"
            />
            <Table.Column
              title="เวลาเริ่มต้น"
              dataIndex="start_date"
              render={(e) => {
                return e ? moment(e).format("LL") : "";
              }}
              align="left"
            />
            <Table.Column
              title="เวลาสิ้นสุด"
              dataIndex="end_date"
              render={(e) => {
                return e ? moment(e).format("LL") : "ไม่มีวันหมดอายุ";
              }}
              align="left"
            />
            <Table.Column
              title="วันที่ได้รับบัตรกำนัล"
              dataIndex="voucher_date"
              render={(e) => {
                return e ? moment(e).format("LL") : "";
              }}
              align="left"
            />
            <Table.Column
              title="วันที่ใช้บัตรกำนัล"
              dataIndex="document_date"
              render={(e) => {
                return e ? moment(e).format("LL") : "";
              }}
              align="left"
            />
            <Table.Column
              title="เลขที่ใบเสร็จ"
              dataIndex="ref_document_no"
              align="left"
            />
            <Table.Column
              title="มูลค่าบัตรกำนัลที่ใช้"
              dataIndex="voucher_used_value"
              render={(e) => {
                return numeral(e).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="สถานะ"
              dataIndex="status"
              render={(e) => {
                if (e === 1) {
                  return "ยังไม่ได้ใช้งาน";
                } else if (e === 2) {
                  return "ถูกใช้งานไปแล้ว";
                } else {
                  return "";
                }
              }}
              align="center"
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
