/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Icon, Popconfirm } from "antd";
import numeral from "numeral";
import VoucherPopup from "../components/VoucherPopup";
import { Creators } from "../ducks/operation-form";
import _ from "lodash";
import { getSummaryTotalPayment } from "../payment/logic";
import { useHttp } from "../../../../hooks/http";
import { URL_API } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const BillVoucher = (props) => {
  const [showVoucher, setShowVoucher] = useState(false);
  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/master/voucher`,
      params: {
        ref_code: props.partnerCode,
        ref_type: props.partnerType,
      },
    },
    [props.partnerCode, props.partnerType]
  );

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td colSpan="2" className="px-4">
              {data?.data?.length > 0 ? (
                <button
                  type="button"
                  className="pos-btn bg-reds text-white text-nowrap border-0 d-flex align-items-center"
                  style={{ width: "120px",position: "relative" }}
                  onClick={() => setShowVoucher(true)}
                >
                  <Icon type="plus-circle" className="mr-3" /> บัตรกำนัล
                  <div style={{ position: "absolute", top: "-15px", right: "-15px"  }}>
                    <div style={styles.circle}>
                      <FontAwesomeIcon icon={faBell} style={{ color: "red" }} />
                    </div>
                  </div>
                </button>
              ) : (
                <button
                  type="button"
                  className="pos-btn bg-reds text-white text-nowrap border-0 d-flex align-items-center"
                  style={{ width: "120px" }}
                  onClick={() => setShowVoucher(true)}
                >
                  <Icon type="plus-circle" className="mr-3" /> บัตรกำนัล
                </button>
              )}
            </td>
          </tr>
          {props.vouchers.map((n, i) => (
            <React.Fragment key={i}>
              <tr className={`text-${n.used ? "" : "reds"}`}>
                <td className="w-100 pr-2 pl-4">
                  {n.code} {n.name}{" "}
                  {n.collect_point === 0 && "(ไม่ได้รับคะแนน)"}
                </td>
                <td className="text-nowrap px-2 text-right">
                  {n.used
                    ? n.type === "2"
                      ? `${n.amount_used} %`
                      : numeral(n.amount_used).format("0,0.00")
                    : "ไม่ได้ใช้งาน"}
                </td>
                <td className="text-nowrap pl-2 pr-4 text-center text-dark">
                  <Popconfirm
                    placement="top"
                    title={`ต้องการลบรายการนี้หรือไม่`}
                    onConfirm={() => {
                      props.delVoucher(n.id, props.activeKey);
                      props.clearPaymentIgnoreActiveKey();
                    }}
                    okText="ลบ"
                    cancelText="ไม่ลบ"
                  >
                    <a>
                      <Icon type="delete" style={{ fontSize: "20px" }} />
                    </a>
                  </Popconfirm>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <VoucherPopup
        visible={showVoucher}
        onCancel={() => setShowVoucher(false)}
        onOk={() => setShowVoucher(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    orders: { itemByKey, itemKeys },
    payment,
    orders,
  } = state.pos.operation;
  const activeKey = payment.activeKey || itemKeys[0];

  const vouchers =
    _.map(
      itemByKey[activeKey]?.voucher.itemKeys,
      (voucher_key) => itemByKey[activeKey].voucher.itemByKey[voucher_key]
    ) || [];
  const voucher_list_order =
    _.find(
      getSummaryTotalPayment(state.pos?.operation?.orders)?.summary_list,
      (order) => order.key === activeKey
    )?.voucher_list_order || [];
  vouchers.forEach((item) => {
    item.used = voucher_list_order.some((e) => e.id === item.id) ? 1 : 0;
    item.amount_used = voucher_list_order.some((e) => e.id === item.id)
      ? voucher_list_order.find((e) => e.id === item.id).amount
      : 0;
  });

  const partner = orders.itemByKey[activeKey];

  return {
    vouchers: _.filter(
      vouchers,
      (n) => n.voucher_type?.toLowerCase() === "voucher"
    ),
    activeKey,
    partnerCode: partner?.partner_code,
    partnerType: partner?.partner_type,
  };
};
const styles = {
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25px', 
    height: '25px',
    backgroundColor: 'yellow',  
    borderRadius: '50%',  
  },
};

export default connect(mapStateToProps, {
  delVoucher: Creators.delVoucher,
  clearPaymentIgnoreActiveKey: Creators.clearPaymentIgnoreActiveKey,
})(BillVoucher);
