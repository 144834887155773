/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import TablePoint from './TablePoint'
import FormGroup from './FormGroup'
import PointMaldives from './PointMaldives'
import { Row, Col } from 'react-bootstrap'
import { FormatNumber } from './ShareFunction';


const Calculation = ({ data }) => {
    const [collapse, setCollapse] = useState(true);
    const [dataTable, setDataTable] = useState({ columns: [], rows: [] });

    const {
        calculation_point_of_month,
        point_start_trip,
        point_target_1,
        point_target_2,
        pro_trip,

        pro_point_maldives_of_month,
        total_tarvel_scroe,
        target_scroe_belgium_netherlands

    } = data;

    const createDataTable = (data) => {
        let dataTable = {
            columns: [
                {
                    title: '',
                },
                {
                    title: 'เมษายน 62'
                },
                {
                    title: 'พฤษภาคม 62'
                },
                {
                    title: 'มิถุนายน 62'
                },
                {
                    title: 'กรกฎาคม 62'
                },
                {
                    title: 'สิงหาคม 62'
                },
                {
                    title: 'กันยายน 62'
                },
                // {
                //     title: 'สะสมรวม'
                // }
            ],
            rows: []
        }

        data.forEach(item => {
            const row = {
                c1: <span >{item[0]}</span>,
                c2: <><input value={FormatNumber(item[1])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
                c3: <><input value={FormatNumber(item[2])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
                c4: <><input value={FormatNumber(item[3])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
                c5: <><input value={FormatNumber(item[4])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
                c6: <><input value={FormatNumber(item[5])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
                c7: <><input value={FormatNumber(item[6])} readOnly className="input-maldives form-control form-control-sm text-center" /></>,
                // c8: (item[7] !== undefined) ? <><input value={FormatNumber(item[7])} readOnly className="input-maldives form-control form-control-sm text-center" /></> : ''
            };
            dataTable.rows.push(row);


        });

        setDataTable(dataTable);
    }


    useEffect(() => {
        createDataTable(calculation_point_of_month);
        return () => {

        }
    }, []);



    return (
        <div className="maldives-trip-taps">
            <Card className="bg-dark text-white mt-2" >
                <CardHeader className={'card-header bg-dark-darker text-white pointer-cursor ' + (!collapse ? 'collapsed ' : '')} onClick={() => setCollapse(!collapse)}>
                    <span style={{ fontSize: 18 }}>@การคิดคำนวณเป้าหมาย</span>
                    {(collapse) ?
                        <i className="fas fa-caret-down pull-right" style={{ fontSize: 20 }} />
                        :
                        <i className="fas fa-caret-right pull-right" style={{ fontSize: 20 }} />
                    }
                </CardHeader>
                <Collapse isOpen={collapse}>
                    <CardBody>

                        <TablePoint data={dataTable} />


                        <FormGroup
                            label="คะแนนฐานตั้งตั้น (ผลรวมคะแนนทีมอ่อน เดือน เม.ย. - ก.ย. 62)"
                            value={FormatNumber(point_start_trip)}
                            labelCol="col-md-6 col-xs-12"
                            divCol="col-md-6 col-xs-12"
                            detail={<span>*ผลลัพธ์ที่ได้คะแนนฐานตั้งต้น ถ้าน้อยกว่า 220,000 ปัดขึ้นเป็น 220,000 คะแนน</span>}
                            icon
                        />

                        <FormGroup
                            label="คะแนนเป้าหมาย กรณี รหัสเดี่ยว/รหัสคู่ 1 สิทธิ์"
                            value={FormatNumber(point_target_1)}
                            labelCol="col-md-6 col-xs-12"
                            divCol="col-md-6 col-xs-12"
                            detail={<>
                                <span>*กรณี รหัสเดี่ยว/รหัสคู่ 1 สิทธิ์ คะแนนฐานตั้งต้น + 30%</span><br />
                                <span>*คนใหม่ คะแนนเป้าหมาย 1 สิทธิ์ = 220,000 คะแนน</span>
                            </>}
                            icon
                        />
                        <FormGroup
                            label="คะแนนเป้าหมาย กรณี  รหัสรหัสคู่ 2 สิทธิ์"
                            value={FormatNumber(point_target_2)}
                            labelCol="col-md-6 col-xs-12"
                            divCol="col-md-6 col-xs-12"
                            detail={<>
                                <span>*กรณี รหัสคุ่ 2 สิทธิ์ คะแนนฐานตั้งต้น + 60%</span><br />
                                <span>*คนใหม่ คะแนนเป้าหมายรหัสคู่ 2 สิทธิ์ = 330,000 คะแนน</span>
                            </>}
                            icon
                        />
                        <FormGroup
                            label="10* ของคะแนนเป้าหมายที่ 1"
                            value={''}
                            labelCol="col-md-6 col-xs-12"
                            divCol="col-md-6 col-xs-12"
                            detail={<>
                                <span>*คะแนนโปรโมชั่นรักษายอดส่วนตัว หากทำคุณสมบัติครบตามเงื่อนไข</span>
                            </>}
                            icon
                        />
                        {/* <FormGroup
                            label="โปรคะแนนก้นถุงมัลดีฟส์ (จากทริปเบลเยี่ยม-เนเธอร์แลนด์)"
                            value={pro_trip}
                            labelCol="col-md-6 col-xs-12"
                            divCol="col-md-6 col-xs-12"
                            detail={<>
                                <span>*รักษายอด 1,000 PV ทุกเดือน หรือ ยอดส่วนตัวรวม >12,000 PV ระหว่าง เม.ย.-ก.ย.62</span><br />
                                <span>**ไม่ผ่านทริป Belgium-Netherland</span><br />
                                <span>***ส่วนเกินคะแนนท่องเที่ยวรวมโปรโมชั่นทั้งหมด > 110% ของคะแนนฐาน = โปรคะแนนก้นถุงมัลดีฟส์ (สูงสุดไม่เกิน 10%)</span>
                            </>}
                            icon
                        /> */}

                        {/* <PointMaldives
                            data={{
                                pro_point_maldives_of_month,
                                total_tarvel_scroe,
                                target_scroe_belgium_netherlands
                            }}
                        /> */}

                    </CardBody>
                </Collapse>
            </Card>
        </div>
    )
}

export default Calculation;




