/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import {
  Button,
  Col,
  Input,
  notification,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import { FaCog, FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import SelectAllType from "../../../../../components/SelectAllType";
import { useHttp } from "../../../../../hooks/http";
import {
  actionEthics,
  searchSelector,
} from "../../../../../redux/slices/ethics";
import { URL_API } from "../../../../../utils";
import IconFile from "./IconFile";
import PreviewFile from "./PreviewFile";

const DistributorTable = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useSelector(searchSelector);

  const { SET_SEARCH } = actionEthics;

  // const limit = 10;
  const [imageId, setImageId] = useState(null);
  const [preview, setPreview] = useState(false);

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/pos/ethics`,
      token: true,
      params: {
        limit: query.limit,
        offset: query.limit * (query.page - 1),
        query: query.search,
        // query:  querys,
        status: query.status,
        display_type: query.display_type,
      },
    },
    [query]
  );

  console.log({ query });

  const statusText = (status_name = "", status = "", file = []) => {
    let showStatus = status_name;
    if (status?.toLowerCase() === "pending") {
      let Document = file?.find((n) => n.document_type === "1");
      let IDCard = file?.find((n) => n.document_type === "2");
      let BookBank = file?.find((n) => n.document_type === "3");
      if (!Document && !IDCard && !BookBank) {
        showStatus = "รอเอกสารหลัก";
      } else if (!Document && !IDCard) {
        showStatus = "รอใบสมัคร+สำเนาบัตรประชาชน";
      } else if (!IDCard) {
        showStatus = "รอสำเนาบัตรประชาชน";
      }
      console.log("showStatus", showStatus);
    }
    return showStatus;
  };

  if (error) {
    notification.error({
      message: error,
      placement: "bottomRight",
    });
  }

  return (
    <>
      <Row gutter={16} className="mb-3">
        <Col md={5}>
          <Select
            className="w-100"
            allowClear
            value={query.display_type}
            onChange={(e) =>
              dispatch(
                SET_SEARCH({
                  display_type: e,
                })
              )
            }
          >
            <Select.Option value="">ทั้งหมด</Select.Option>
            <Select.Option value="waiting">รอตรวจสอบ</Select.Option>
          </Select>
        </Col>
        <Col md={5}>
          <SelectAllType
            className="w-100"
            process_type="partner_status_local"
            withAll
            allText="ทั้งหมด"
            allowClear
            value={query.status}
            onChange={(e) =>
              dispatch(
                SET_SEARCH({
                  status: e,
                })
              )
            }
          />
          {/* <SelectPartnerStatus
            className="w-100"
            allowClear
            value={query.status}
            onChange={(e) =>
              setQuery({
                ...query,
                status: e,
              })
            }
          /> */}
        </Col>
        <Col md={14}>
          <Input.Search
            allowClear
            placeholder="รหัสสมาชิก, เลขบัตรประชาชน, ชื่อสมาชิก, เบอร์โทร, E-mail"
            defaultValue={query.search}
            onSearch={(e) => {
              dispatch(
                SET_SEARCH({
                  search: e,
                  page: 1,
                })
              );
            }}
          />
        </Col>
      </Row>
      <Table
        size="small"
        rowKey="id"
        rowClassName="f-s-12 "
        loading={loading}
        dataSource={data ? data.data : []}
        pagination={{
          current: parseInt(query.page),
          pageSize: query.limit,
          total: data ? data.total : 0,
          showLessItems: true,
          size: "default",
          pageSizeOptions: ["10", "25", "50", "100"],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${numeral(range[0]).format()} - ${numeral(
              range[1]
            ).format()} of ${numeral(total).format()}`,
          onChange: (currPage, pageSize) =>
            dispatch(
              SET_SEARCH({
                page: currPage,
                limit: pageSize,
              })
            ),
          onShowSizeChange: (current, size) =>
            dispatch(
              SET_SEARCH({
                page: 1,
                limit: size,
              })
            ),
        }}
      >
        <Table.Column
          title="รหัสสมาชิก"
          dataIndex="partner_code"
          className="f-s-12"
        />
        <Table.Column
          title="ชื่อสมาชิก"
          className="f-s-12"
          render={({ first_name_th, sur_name_th }) => (
            <p className="m-0">{`${first_name_th} ${sur_name_th}`}</p>
          )}
        />
        <Table.Column
          title="รหัสผู้แนะนำ"
          dataIndex="sponsor_code"
          align="center"
          className="f-s-12"
        />
        <Table.Column
          title="ชื่อผู้แนะนำ"
          dataIndex="sponsor_name"
          className="f-s-12"
        />
        <Table.Column
          title="สถานะ"
          align="center"
          className="f-s-12"
          render={({ status, status_name, file }) => {
            return statusText(status_name, status, file);
          }}
        />
        <Table.Column
          title="เวลาที่สมัคร"
          align="center"
          className="f-s-12"
          render={({ register_date }) => (
            <p className="m-0">{moment(register_date).format("DD/MM/YYYY")}</p>
          )}
        />
        <Table.Column
          title="เอกสาร"
          className="f-s-12"
          render={({ file }) => (
            <>
              <IconFile
                file={file}
                setImageId={setImageId}
                setPreview={setPreview}
              />
            </>
          )}
        />
        <Table.Column
          title={<FaCog />}
          align="center"
          className="f-s-12"
          render={({ id }) => (
            <Tooltip title="แก้ไข">
              {/* <Link to={`${match.path}/${id}`}> */}
              <Button
                size="small"
                shape="circle"
                type="primary"
                className="bg-pv border-pv"
                onClick={() => history.push(`${match.path}/${id}`)}
              >
                <FaPencilAlt />
              </Button>
              {/* </Link> */}
            </Tooltip>
          )}
        />
      </Table>
      <PreviewFile
        visible={preview}
        footer={null}
        onCancel={() => setPreview(false)}
        imageId={imageId}
      />
    </>
  );
};
export default DistributorTable;
