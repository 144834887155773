import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import Axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
// import _ from "lodash";
import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { getToken, getUser, URL_API } from "../../utils";
import { AlertSuccess, AlertError } from "../util/Alert";
import { Panel, PanelBody, PanelHeader } from "../util/panel";


const { Text } = Typography;

const resetPasswordSchema = yup.object().shape({
  is_old_password: yup.string().required("กรุณาระบุข้อมูลในช่อง"),
  is_new_password: yup.string().required("กรุณาระบุข้อมูลในช่อง"),
  is_confirm_password: yup
    .string()
    .oneOf([yup.ref("is_new_password"), null], "รหัสผ่านไม่ตรงกัน")
    .required("กรุณาระบุข้อมูลในช่อง"),
});

const LayoutInfoPassword = ({ title, detail }) => {
  return (
    <Row className="my-2">
      <Col xs={8} sm={5} md={4} lg={3} className="text-right mr-2">
        <Text strong>{title}</Text>
      </Col>
      <Col xs={12} sm={10} md={8} lg={6}>
        <Text>{detail}</Text>
      </Col>
    </Row>
  );
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 8,
    },
    sm: {
      span: 5,
    },
    md: {
      span: 5,
    },
    lg: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 14,
    },
    sm: {
      span: 12,
    },
    md: {
      span: 10,
    },
    lg: {
      span: 6,
    },
  },
};

export const ResetPassword = () => {
  const user = getUser();
  const onSubmit = async (values, actions) => {
    Axios({
      method: "post",
      baseURL: URL_API,
      url: `/reset-password`,
      headers: { Authorization: "Bearer " + getToken() },
      data: {
        id: user?.id,
        old_password: values.is_old_password,
        new_password: values.is_new_password,
      },
    })
      .then((res) => {
        let update_password = res?.data?.data?.update_password;
        if (update_password) {
          AlertSuccess("เปลี่ยนรหัสผ่านเรียบร้อย!");
          actions.resetForm();
        } else {
          AlertError({ title: "รหัสผ่านเดิมไม่ถูกต้อง" });
        }
      })
      .catch((err) => {
        AlertError({ title: `${err}` });
      });
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      is_old_password: "",
      is_new_password: "",
      is_confirm_password: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit,
  });

  return (
    <Panel>
      <PanelHeader>เปลี่ยนรหัสผ่าน</PanelHeader>
      <PanelBody className="pb-6">
        <Row
          className="mb-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaUserAlt className="mr-2" />
          <Text strong>ข้อมูลบัญชีผู้ใช้</Text>
        </Row>
        <Divider />
        <Row>
          <Col>
            <LayoutInfoPassword
              title={`รหัสสมาชิก :`}
              detail={user?.user_code}
            />
            <LayoutInfoPassword title={`ชื่อผู้ใช้ :`} detail={user?.name} />
            <LayoutInfoPassword title={`E-mail :`} detail={user?.email} />
            <LayoutInfoPassword title={`ตำแหน่ง :`} detail={user?.position} />
            <LayoutInfoPassword title={`แผนก :`} detail={user?.division_name} />
            <LayoutInfoPassword
              title={`รหัสสาขา :`}
              detail={user?.branch_code}
            />
            <LayoutInfoPassword
              title={`ชื่อสาขา :`}
              detail={user?.branch_name}
            />
          </Col>
        </Row>
        <Divider />
        <Row
          className="mb-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <RiLockPasswordFill className="mr-2" />
          <Typography.Text strong>เปลี่ยนรหัสผ่าน</Typography.Text>
        </Row>

        <Row>
          <Form
            name="basic"
            onSubmit={handleSubmit}
            {...formItemLayout}
            autoComplete="off"
          >
            <Form.Item
              label="รหัสผ่านเดิม"
              name="is_old_password"
              validateStatus={
                errors.is_old_password && touched.is_old_password ? "error" : ""
              }
              help={
                errors.is_old_password &&
                touched.is_old_password &&
                `${errors.is_old_password}`
              }
              style={{ marginBottom: "0px" }}
            >
              <Input.Password
                id="is_old_password"
                value={values.is_old_password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={`รหัสผ่านเดิม`}
              />
            </Form.Item>
            <Form.Item
              label="รหัสผ่านใหม่"
              name="is_new_password"
              validateStatus={
                errors.is_new_password && touched.is_new_password ? "error" : ""
              }
              help={
                errors.is_new_password &&
                touched.is_new_password &&
                `${errors.is_new_password}`
              }
              style={{ marginBottom: "0px" }}
            >
              <Input.Password
                id="is_new_password"
                value={values.is_new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={`รหัสผ่านใหม่`}
              />
            </Form.Item>
            <Form.Item
              label="ยืนยันรหัสผ่าน"
              name="is_confirm_password"
              validateStatus={
                errors.is_confirm_password && touched.is_confirm_password
                  ? "error"
                  : ""
              }
              help={
                errors.is_confirm_password &&
                touched.is_confirm_password &&
                `${errors.is_confirm_password}`
              }
              style={{ marginBottom: "0px" }}
            >
              <Input.Password
                id="is_confirm_password"
                value={values.is_confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={`ยืนยันรหัสผ่าน`}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                xs: { span: 14, push: 8 },
                sm: { span: 12, push: 5 },
                md: { span: 10, push: 5 },
                lg: { span: 6, push: 4 },
              }}
            >
              <Button
                disabled={isSubmitting}
                type="primary"
                block
                htmlType="submit"
              >
                เปลี่ยนรหัสผ่าน
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </PanelBody>
    </Panel>
  );
};
