import React from "react";
import { Bar } from "react-chartjs-2";

export const BarChart = ({ chartData }) => {
  return (
    <Bar
      data={chartData}
      options={{
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }}
    />
  );
};
