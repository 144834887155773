import React from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../../../Error404";
import Detail from "./Detail";
import List from "./List";

export default ({ match }) => {
    return (
        <Switch>
            <Route path={match.path} exact component={List} />
            <Route path={`${match.path}/:id`} component={Detail} />
            <Route component={Error404} />
        </Switch>
    );
};
