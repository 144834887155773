/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Alert,
  Button,
  Col,
  Input,
  notification,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineSelect } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useHttp } from "../../../hooks/http";
import { actionLegal, documentSelector, searchSelector, typeSelector } from "../../../redux/slices/legal";
import { moment, URL_API } from "../../../utils";


const limit = 10;
const PartnerTable = ({ match, history }) => {
  //const [page, setPage] = useState(1);
  const [getData, setGetData] = useState(false);
  //const [status, setStatus] = useState([]);
  //const [loadingStatus, setLoadingStatus] = useState(true);
  const document = useSelector(documentSelector);
  const dispatch = useDispatch();
  const { SET_SEARCH, SET_DOCUMENT, SET_MEMBER_TYPE } = actionLegal;
  const search = useSelector(searchSelector);
  const type = useSelector(typeSelector);

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  // useEffect(() => {
  //   if (document.page) {
  //     setPage(document.page);
  //     //setSearch(document.search);
  //   }
  // }, [document.page, document.search]);

  // send request after search change 2 sec. 
  useEffect(() => {
    async function sendRequest() {
      await delay(1000);
      setGetData(!getData)
    }
    sendRequest();
  }, [search]);

  // useEffect(() => {
  //   axios({
  //     url: `${URL_API}/legal/master/partner/status`,
  //   })
  //     .then((res) => {
  //       setStatus(res.data.data);
  //     })
  //     .catch((err) => {
  //       notification.error({
  //         message: "ตรวจพบสิ่งผิดพลาด",
  //         description:
  //           err.response && err.response.data
  //             ? err.response.data.message
  //             : err.message,
  //         placement: "topRight",
  //       });
  //     })
  //     .finally(() => {
  //       setLoadingStatus(false);
  //     });
  // }, []);

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/legal/master/partner`,
      token : true,
      params: {
        limit: search.limit,
        offset: (search.page - 1) * search.limit,
        ...search,
      },
    },
    [getData]
  );

  const handleSelect = (record) => {
    const { id, partner_code } = record;
    dispatch(SET_DOCUMENT({ id, partner_code, page: search.page, search }));
    dispatch(SET_MEMBER_TYPE({ type : 'distributor'}));
    history.push(`${match.path}/profile/register?membertype=distributor&id=${id}`);
  };

  return (
    <>

      {error && <Alert type="error" message={error} />}
      <Table
        dataSource={data?.data || []}
        bordered
        loading={loading}
        size="small"
        rowKey="row_id"
        scroll={{ x: "max-content" }}
        className="pt-3 pb-3"
        pagination={{
          current: search.page,
          pageSize: search.limit,
          total: data?.total || 0,
          showSizeChanger: false,
          showLessItems: true,
          hideOnSinglePage: true,
          size: "default",
          onChange: (currPage) => dispatch(SET_SEARCH({ page: currPage })),
        }}
      // onRow={(record) => {
      //     return {
      //         onClick: () => history.push(`${match.path}/profile/register?id=${record.id}`)
      //     };
      // }}
      >
        <Table.Column
          key="partner_code"
          dataIndex="partner_code"
          title="รหัสนักธุรกิจ"
          align="center"
        />
        <Table.Column
          key="partner_name"
          dataIndex="partner_name"
          title="ชื่อ-นามสกุล"
          align="center"
          render={(data) => <div className="text-left">{data}</div>}
        />
        <Table.Column
          key="honor_name"
          dataIndex="honor_name"
          title="ตำแหน่ง"
          align="center"
        />
        <Table.Column
          key="register_date"
          dataIndex="register_date"
          title="วันที่สมัคร"
          align="center"
          render={(data) => moment(data).format("DD/MM/YYYY")}
        />
        <Table.Column
          key="expire_date"
          dataIndex="expire_date"
          title="วันที่หมดอายุ"
          align="center"
          render={(data) => moment(data).format("DD/MM/YYYY")}
        />
        <Table.Column
          key="status_text"
          dataIndex="status_text"
          title="สถานะ"
          align="center"
        />
        <Table.Column
          key="action"
          title=""
          align="center"
          render={(record) => (
            <Tooltip title="เลือก">
              <Button
                size="small"
                shape="circle"
                type="primary"
                className="bg-pv border-pv"
                onClick={() => handleSelect(record)}
              >
                <AiOutlineSelect />
              </Button>
            </Tooltip>
          )}
        />
      </Table>
    </>
  );
};

export default withRouter(PartnerTable);
