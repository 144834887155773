import { useMutation, useQuery } from '@tanstack/react-query';
import fitStudioRequest from '../utils/request';

const lobServices = {
  useQueryGetLob(
    { take, page, searchText, memberCode, expiredDate, lobNo, StatusText },
    onSuccess
  ) {
    return useQuery(
      [
        'get-lobs',
        { take, page, searchText, memberCode, expiredDate, lobNo, StatusText },
      ],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(`/api/lob`, {
            params: {
              take: queryKey[1].take,
              page: queryKey[1].page,
              ...(queryKey[1].searchText && {
                searchText: queryKey[1].searchText,
              }),
              ...(queryKey[1].memberCode && {
                memberCode: queryKey[1].memberCode,
              }),
              ...(queryKey[1].expiredDate && {
                expiredDate: queryKey[1].expiredDate,
              }),
              ...(queryKey[1].lobNo && {
                lobNo: queryKey[1].lobNo,
              }),
              ...(queryKey[1].StatusText && {
                StatusText: queryKey[1].StatusText,
              }),
            },
          });

          return res.data;
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
      }
    );
  },
  useQueryGetLobById({ lobId }, onSuccess) {
    return useQuery(
      ['get-lob-by-id', { lobId }],
      async ({ queryKey }) => {
        try {
          const res = await fitStudioRequest.get(
            `/api/lob/${queryKey[1].lobId}`
          );

          if (res.status === 200) {
            return res.data.data;
          }
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      },
      {
        onSuccess,
        enabled: !!lobId,
        refetchOnWindowFocus: false,
      }
    );
  },
  useMutationGetLobByLobNo(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.get(
            `/api/lob/lobNo/${data.lobNo}`
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCreateLob(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.post(`/api/lob`, data);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateLob(onSuccess, onError) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.patch(
            `/api/lob/${data.id}`,
            data.data
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteLob(onSuccess) {
    return useMutation(
      async (data) => {
        try {
          const res = await fitStudioRequest.delete(`/api/lob/${data.id}`);

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      {
        onSuccess,
      }
    );
  },
  useMutationUploadLodExcel(onSuccess) {
    return useMutation({
      mutationFn: async (data) => {
        try {
          const res = await fitStudioRequest.post(
            `/api/lob/upload-excel`,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
  useMutationDownloadLodListsExcel(onSuccess) {
    return useMutation({
      mutationFn: async (data) => {
        try {
          const res = await fitStudioRequest.get(
            `/api/lob/download-excel/by-filter`,
            {
              params: {
                ...(data.searchText && {
                  searchText: data.searchText,
                }),
                page: data.page,
                take: data.take,
              },
              responseType: 'blob',
            }
          );

          if (res.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'lod-list.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
          }

          return res.data.data;
        } catch (error) {
          throw error.response.data.message;
        }
      },
      onSuccess,
    });
  },
};

export default lobServices;
