/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadFileList from "../../../../components/UploadFileList";
import { actionRequisition } from "../../../../redux/slices/requisition";

const { addFile, delFile } = actionRequisition;

const UploadFileImage = ({ disabled }) => {
  const dispatch = useDispatch();
  const file = useSelector((state) => state.requisition.file);

  const handleChangeImg = useCallback((value) => dispatch(addFile(value)), []);
  const handleDeleteImg = useCallback((value) => dispatch(delFile(value)), []);

  return (
    <Row gutter={[8, 8]}>
      <Col span={4} md={2} xxl={1} className="text-right">
        <Typography.Text strong>แนบรูป</Typography.Text>
      </Col>
      <Col span={20} md={10} xxl={6}>
        <UploadFileList
          withCamera
          disabled={disabled}
          files={file}
          onChange={handleChangeImg}
          onDelete={handleDeleteImg}
        />
      </Col>
    </Row>
  );
};

export default memo(UploadFileImage);
