import React from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import fitQuizServices from '../../../../../services/fit-quiz.services';
import LegacyQuizRewardForm from '../form/LegacyQuizRewardForm';

const LegacyQuizRewardModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();

  const { mutate: createLegacyQuizReward } =
    fitQuizServices.useMutationCreateLegacyQuizReward(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-reward'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    });

  const { mutate: editLegacyQuizReward } =
    fitQuizServices.useMutationUpdateLegacyQuizReward(() => {
      queryClient.invalidateQueries({
        queryKey: ['get-legacy-quiz-reward'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    });

  const _HandleSubmit = handleSubmit((data) => {
    const legayQuizRewardData = {
      title: data.title,
      description: data.description,
      frontImageUrl: data.frontImageUrl,
      greyFrontImageUrl: data.greyFrontImageUrl,
      max: data.max,
      display: data.display,
      startDate: moment(data.startDate).toDate(),
      endDate: moment(data.endDate).toDate(),
    };

    if (watch('formType') === 'create') {
      createLegacyQuizReward(legayQuizRewardData);
    } else if (watch('formType') === 'edit') {
      editLegacyQuizReward({ id: data.id, data: legayQuizRewardData });
    }
  });

  return (
    <Modal
      title={
        watch('formType') === 'create'
          ? 'เพิ่มเหรียญรางวัล'
          : 'แก้ไขเหรียญรางวัล'
      }
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LegacyQuizRewardForm />
    </Modal>
  );
};

export default LegacyQuizRewardModal;
