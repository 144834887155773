/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import numeral from "numeral";
import { useHttp } from "../../../../hooks/http";
import { Alert, Button, notification } from "antd";
import { URL_API, moment, axios } from "../../../../utils";
import { FaEye } from "react-icons/fa";
import Preview from "../../../../components/Preview";
import { resCatch } from "../../../util/helper";

const limit = 10;
const Cancel = ({ criteria }) => {
  const [dataSource, setDataSource] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [page, setPage] = useState(1);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/pos/operation/reserve`,
      params: {
        status: "cancel",
        limit,
        offset: limit * (page - 1),
        ...criteria,
        date_from: criteria.date_from
        ? moment(criteria.date_from).format("YYYY-MM-DD")
        : null,
        date_to: criteria.date_to
        ? moment(criteria.date_to).format("YYYY-MM-DD")
        : null,
      },
    },
    [criteria, page]
  );

  useEffect(() => {
    setPage(1);
  }, [criteria]);

  const loadData = async (id = 0) => {
    setLoadingPreview(true);
    await axios({
      url: `${URL_API}/pos/operation/reserve/${id}`,
    })
      .then((res) => {
        setPreviewData(res.data.data);
      })
      .catch((err) => {
        resCatch(err);
      })
      .finally(() => setLoadingPreview(false));
  };

  const columns = [
    {
      title: "เลขที่ชุดจอง",
      dataIndex: "document_no",
      render: (text) => <p className="m-0 text-center">{text}</p>,
    },
    {
      title: "วันที่",
      dataIndex: "document_date",
      render: (text) => (
        <p className="m-0 text-left">
          {moment(text).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
    {
      title: "รหัสนักธุรกิจ",
      dataIndex: "buyer_code",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ผู้ดำเนินการ",
      dataIndex: "buyer_name",
      render: (text) => <p className="m-0">{text}</p>,
    },
    {
      title: "ผู้ทำรายการจอง",
      dataIndex: "cashier_name",
      render: (text) => <p className="m-0">{text}</p>,
    },
    {
      title: "ผู้ยกเลิก",
      dataIndex: "cancel_by",
      render: (text) => <p className="m-0">{text}</p>,
    },
    {
      title: "วันที่ยกเลิก",
      dataIndex: "cancel_date",
      render: (text) => (
        <p className="m-0">{moment(text).format("DD/MM/YYYY HH:mm")}</p>
      ),
    },
    {
      title: "หมายเหตุ",
      dataIndex: "text_remark",
      render: (text) => <p className="m-0 text-left">{text}</p>,
    },
    {
      title: "ยอดรวม",
      dataIndex: "total_price",
      render: (text) => (
        <p className="m-0 text-right">{numeral(text).format("0,0.00")}</p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_text, record) => (
        <p className="m-0 d-flex align-items-center justify-content-end">
          <Button
            size="small"
            className="bg-pv text-white border-pv rounded-circle d-flex p-4"
            onClick={() => {
              loadData(record.id);
              setShowPreview(true);
            }}
          >
            <FaEye />
          </Button>
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (data?.data.record) {
      setDataSource(data?.data.record);
    }
  }, [data]);

  if (error) return <Alert type="error" message={error} />;

  return (
    <>
      <Table
        page={page}
        setPage={setPage}
        total={data?.data?.total}
        columns={columns}
        loading={loading}
        dataSource={dataSource}
      />
      <Preview
        visible={showPreview}
        data={previewData}
        loading={loadingPreview}
        onCancel={() => setShowPreview(false)}
      />
    </>
  );
};

export default Cancel;
