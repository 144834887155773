import React from "react";
import { connect } from "react-redux";
import SelectOption from "../components/SelectOption";

const SelectBuyerType = ({ loading, data, ...props }) => (
  <SelectOption
    loading={loading}
    options={data}
    {...props}
    value={data.length > 0 ? props.value : undefined}
  />
);

const mapStateToProps = (state) => state.pos.data.buyer_type;

export default connect(mapStateToProps, null)(React.memo(SelectBuyerType));
