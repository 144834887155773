/* eslint-disable no-unused-vars */
import { Col, notification, Row, Table, Typography } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useReducer, useState } from "react";
import XLSX from "xlsx";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectBranch from "../../../pos/components/SelectBranch";
import FilterList from "../../components/FilterList";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const { Text } = Typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-dept-reduction`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );
  let newData = data ? data.data : [];
  const [exportLoading, exportData, exportError, exportReload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-dept-reduction`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportToExcel = () => {
    if (exportData.data.length !== 0) {
      let excelData = [
        ["รายงานใบลดหนี้", { alignRight: true }],
        [
          `สาขา ${
            state.filter.branch === "" ? "ทั่งหมด" : state.filter.branch
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [
          "วันที่เอกสาร",
          "เลขที่อ้างอิง(เลขที่ใบเสร็จเก่า)",
          "เลขที่ใบรับคืนสินค้า",
          "รหัสนักธุรกิจ",
          "ชื่อนักธุรกิจ",
          "ขื่อพนักงานขาย",
          "รหัสสินค้า",
          "ชื่อสินค้า",
          "จำนวนสินค้า",
          "ราคาขายต่อหน่วยขาย",
          "มูลค่า",
          "มูลค่าสินค้า(ท้ายบิล)",
        ],
      ];
      exportData.data.forEach((n) => {
        excelData = [
          ...excelData,
          [
            n.document_date,
            n.ref_document_no,
            n.document_no,
            n.partner_code,
            n.partner_name,
            n.create_by,
            n.product_code,
            n.product_name,
            n.qty,
            numeral(n.unit_price).format("0,0.00"),
            numeral(n.amount).format("0,0.00"),
            numeral(n.last_price).format("0,0.00"),
          ],
        ];
      });

      const merge = [
        { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 3 } },
        { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
      ];

      var wscols = [
        { wch: 10 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 40 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.json_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานใบลดหนี้");
      XLSX.writeFile(wb, "รายงานใบลดหนี้.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานใบลดหนี้</PanelHeader>
        <PanelBody>
          <FilterList
            placeholder="รหัสสมาชิก,ชื่อสมาชิก,เลขที่ใบเสร็จ,เลขที่การจอง,เลขที่ใบรับคืน,ชื่อพนักงาน,รหัสสินค้า,ชื่อสินค้า"
            showReportSpan={8}
            inputSpan={8}
            reload={() => {
              reload();
              exportReload();
            }}
            Topic={"วันที่เอกสาร"}
            topicSpan={2}
            withAll={user.branch_count > 1}
            exportToExcel={exportToExcel}
            topicAlign={"center"}
          />
          <Row gutter={10} type="flex" className="align-items-center  mb-4">
            <Col span={1} align="center">
              <Text strong>สาขา</Text>
            </Col>
            <Col span={6}>
              <SelectBranch
                userLogin={true}
                allowDisableBranch
                defaultValue={user.branch_code}
                placeholder="เลือกสาขา"
                className="w-100"
                withAll
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { branch: e },
                  });
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={newData}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.length,
              showLessItems: true,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="#" dataIndex="row_id" align="center" />
            <Table.Column
              title="วันที่เอกสาร"
              render={({ document_date }) => (
                <>{moment(document_date).format("DD/MM/YYYY")}</>
              )}
            />
            <Table.Column
              title="เลขที่อ้างอิง(เลขที่ใบเสร็จเก่า)"
              dataIndex="ref_document_no"
              align="center"
            />
            <Table.Column
              title="เลขที่ใบรับคืนสินค้า"
              dataIndex="document_no"
              align="center"
            />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="partner_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสมาชิก"
              dataIndex="partner_name"
              align="left"
            />
            <Table.Column
              title="ชื่อพนักงานขาย"
              dataIndex="create_by"
              align="left"
            />
            <Table.Column
              title="รหัสสินค้า"
              dataIndex="product_code"
              align="left"
            />
            <Table.Column
              title="ชื่อสินค้า"
              dataIndex="product_name"
              align="left"
            />
            <Table.Column title="จำนวนสินค้า" dataIndex="qty" align="center" />
            <Table.Column
              title="ราคาขายต่อหน่วย"
              dataIndex="unit_price"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="มูลค่า"
              dataIndex="amount"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
            <Table.Column
              title="มูลค่าสินค้า(ท้ายบิล)"
              dataIndex="last_price"
              render={(text) => {
                return numeral(text).format("0,0.00");
              }}
              align="right"
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
