/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Table,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import * as XLSX from "xlsx-js-style";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";
import { HonorFilter, SelectHonor } from "../../components/SelectHonor";
import SelectDay from "../../components/SelectDay";
import SelectMonth from "../../components/SelectMonth";
const { Text } = Typography;

const user = getUser();

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("Active");
  const [honorFilterFrom, setHonorFilterFrom] = useState("ONM");
  const [honorFilterTo, setHonorFilterTo] = useState("ONM");

  const [dayFrom, setDayFrom] = useState("");
  const [dayTo, setDayTo] = useState("");
  const [MonthFrom, setMonthFrom] = useState("");
  const [MonthFromText, setMonthFromText] = useState("ทั้งหมด");
  const [MonthTo, setMonthTo] = useState("");
  const [MonthToText, setMonthToText] = useState("ทั้งหมด");

  const honorIndex = HonorFilter();

  let limit = 20;

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-birthday`,
      token: true,
      params: {
        limit: limit,
        offset: limit * (page - 1),
        userId: user.id,
        hornorIndexFrom: honorFilterFrom === "ONM" ? 1 : honorFilterFrom,
        hornorIndexTo: honorFilterTo === "ONM" ? 1 : honorFilterTo,
        status: partnerStatusFilter,
        partnerCode: state.filter.query,
        dayFrom : dayFrom,
        dayTo: dayTo,
        MonthFrom:MonthFrom,
        MonthTo: MonthTo,

      },
      initialLoad: false,
    },
    []
  );

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/partner/partner-filter`,
      token: true,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
    setFilterDataSource(filterData ? filterData : []);
  }, [data, filterData]);

  const partnerStatusOptions = [
    ..._.map(filterData?.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  const exportDataData = data ? [data?.data] : [];

  const exportToExcel = () => {
    // exportDataData.length !== 0
    if (true) {
      let excelData = [
        ["รายงานวันเกิดนักธุรกิจ"],
        [
          `ช่วงตำแหน่ง : ${
            honorIndex[(honorFilterFrom === "ONM" ? 1 : honorFilterFrom) - 1]
              .honor_code
          } ถึง ${
            honorIndex[(honorFilterTo === "ONM" ? 1 : honorFilterTo) - 1]
              .honor_code
          }`,
          "",
          `สถานะสมาชิก : ${
            partnerStatusFilter === "" ? "ทั้งหมด" : partnerStatusFilter
          } `,
          "",
          `ช่วงวันเกิด : วันที่ ${dayFrom} ${MonthFromText} ถึง ${dayTo} ${MonthToText} `,
          "",
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [],
        [
          "ลำดับที่",
          "Code",
          "Name",
          "สถานะรหัส",
          "ตำแหน่ง",
          "Mobile",
          "สถานะ",
          "Birthday",
          "HBD",
        ],
      ];

      exportDataData[0].forEach((n) => {
        excelData = [
          ...excelData,
          [
            { v: n.row_id, s: { alignment: { horizontal: "center" } } },
            n.partner_code,
            n.first_name_th + " " + n.sur_name_th,
            n.status_id,
            n.honor_code,
            n.phone_code,
            n.status_text,
            moment(n.birth_date).format("DD/MM/YYYY"),
            moment(n.birth_date).format("DD - MMMM"),
          ],
        ];
      });

      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 7 } },
      ];
      var wscols = [
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
        fill: { fgColor: { rgb: "E9E9E9" } },
      };

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      ws["A4"].s = centerStyles;
      ws["B4"].s = centerStyles;
      ws["C4"].s = centerStyles;
      ws["D4"].s = centerStyles;
      ws["E4"].s = centerStyles;
      ws["F4"].s = centerStyles;
      ws["G4"].s = centerStyles;
      ws["H4"].s = centerStyles;
      ws["I4"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานวันเกิดนักธุรกิจ");
      XLSX.writeFile(wb, "รายงานวันเกิดนักธุรกิจ.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานวันเกิดนักธุรกิจ</PanelHeader>
        <PanelBody>
          <Row gutter={8} type="flex" className="mb-4 mt-2 align-items-center">
            <Col span={6} className=" p-r-15">
              <Input
                placeholder="พิมพ์ค้นหา / รหัสสมาชิก"
                onChange={(e) => {
                  dispatch({
                    type: "setFilter",
                    payload: { query: e.target.value },
                  });
                }}
              />
            </Col>
            <Col className="ml-3">
              <Text strong>ช่วงวันเกิด</Text>
            </Col>
            <Col span={2}>
              <SelectDay
               placeholder="เลือกวัน" 
               onChange={(e) => {setDayFrom(e);}} />
            </Col>
            <Col span={3}>
              <SelectMonth 
              placeholder="เลือกเดือน" 
              onChange={(e,v) => {
                setMonthFrom(e);
                setMonthFromText(v.props.children);
                }}/>
            </Col>

            <Col>
              <Text strong>ถึง</Text>
            </Col>
            <Col span={2}>
              <SelectDay placeholder="เลือกวัน" onChange={(e) => {setDayTo(e);}}  />
            </Col>
            <Col span={3}>
              <SelectMonth 
              placeholder="เลือกเดือน" 
              onChange={(e,v) => {
              setMonthTo(e);
              setMonthToText(v.props.children);
              }}/>
            </Col>

            <Col span={6}>
              <Row gutter={8} type="flex" justify="end" className="mb-2 mt-2">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPage(1);
                      reload();
                    }}
                  >
                    แสดงรายงาน
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    className="text-white d-flex align-items-center"
                    icon="file-excel"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center align-items-sm-center "
          >
            <Col>
              <Text strong>ช่วงตำแหน่ง</Text>
            </Col>
            <Col span={4}>
              <SelectHonor
                defaultValue={honorFilterFrom}
                onChange={(e) => {
                  setHonorFilterFrom(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>ถึง</Text>
            </Col>
            <Col span={4}>
              <SelectHonor
                defaultValue={honorFilterTo}
                onChange={(e) => {
                  setHonorFilterTo(e);
                }}
              />
            </Col>
            <Col>
              <Text strong>สถานะสมาชิก</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                className="w-100"
                withAll
                defaultValue={partnerStatusFilter}
                options={partnerStatusOptions}
                onChange={(e) => {
                  setPartnerStatusFilter(e);
                }}
              />
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          ></Row>

          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              total: data?.total,
              current: page,
              pageSize: limit,
              size: "default",
              onChange: (currPage) => {
                setPage(currPage);
              },
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="Code"
              dataIndex="partner_code"
              align="center"
            />

            <Table.Column
              title="Name"
              align="left"
              render={(_, record) =>
                record.first_name_th + " " + record.sur_name_th
              }
            />
            <Table.Column
              title="สถานะรหัส"
              dataIndex="status_id"
              align="left"
            />
            <Table.Column title="ตำแหน่ง" dataIndex="honor_code" align="left" />
            <Table.Column title="Mobile" dataIndex="phone_code" align="left" />
            <Table.Column title="สถานะ" dataIndex="status_text" align="left" />
            <Table.Column
              title="Birthday"
              dataIndex="birth_date"
              align="left"
              render={(text) => moment(text).format("YYYY/MM/DD")}
            />
            <Table.Column
              title="HBD"
              align="left"
              render={(_, record) =>
                moment(record.birth_date).format("DD - MMMM")
              }
            />
          </Table>
        </PanelBody>
      </Panel>
    </SaleSummaryContext.Provider>
  );
};

export default Index;
