/* eslint-disable no-unused-vars */
import { Col, notification, Row, Table, typography } from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useReducer, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import * as XLSX from "xlsx-js-style";
import { useHttp } from "../../../../../hooks/http";
import { getUser, URL_API } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";
import BillPreview from "../../../pos/components/BillPreview";
import SelectOption from "../../../pos/components/SelectOption";
import FilterList from "../../components/FilterList";
import SelectTeam from "../../components/SelectTeam";
import SaleSummaryContext, {
  initState,
  reducer,
} from "../../contexts/summary-context";

const { Text } = typography;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [dataSource, setDataSource] = useState([]);
  const [billSelectedId, setBillSelectedId] = useState(null);
  const [preview, setPreview] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 50;
  const user = getUser();

  //Filter
  const [filterDataSource, setFilterDataSource] = useState([]);
  const [orderTypeFilter, setOrderTypeFilter] = useState("");
  const [cancelTypeFilter, setCancelTypeFilter] = useState("");
  const [receiveTypeFilter, setReceiveTypeFilter] = useState("");

  const [memberTypeFilter, setMemberTypeFilter] = useState("Distributor");
  const [partnerStatusFilter, setPartnerStatusFilter] = useState("Active");
  const [teamFilter, setTeamFilter] = useState("Clover");

  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-receipt-cancel`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        orderTypeFilter: orderTypeFilter,
        cancelTypeFilter: cancelTypeFilter,
        receiveTypeFilter: receiveTypeFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  useEffect(() => {
    setDataSource(data ? data : []);
  }, [data]);

  const [filterLoading, filterData, filterError, filterReload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-receipt-cancel-filter`,
      token: true,
    },
    []
  );
  useEffect(() => {
    setFilterDataSource(filterData ? filterData : []);
  }, [filterData]);

  const [exportLoading, exportData, exportError, exportReload] = useHttp(
    {
      url: `${URL_API}/boss/sales/summary-receipt-cancel`,
      token: true,
      params: {
        limit,
        offset: limit * (page - 1),
        userId: user.id,
        orderTypeFilter: orderTypeFilter,
        cancelTypeFilter: cancelTypeFilter,
        receiveTypeFilter: receiveTypeFilter,
        ...state.filter,
        date_from: state.filter.date_from
          ? moment(state.filter.date_from).format("YYYY-MM-DD")
          : null,
        date_to: state.filter.date_to
          ? moment(state.filter.date_to).format("YYYY-MM-DD")
          : null,
      },
      initialLoad: false,
    },
    []
  );

  const exportDataData = exportData ? [exportData.data] : [];

  const exportToExcel = () => {
    // exportDataData.length !== 0
    if (true) {
      let excelData = [
        ["รายงานสลับชื่อผู้สมัครประกัน"],
        [
          `สาขา ${
            state.filter.branch === "" ? "ทั่งหมด" : state.filter.branch
          }`,
          "",
          `วัน  ${moment(state.filter.date_from).format(
            "DD/MM/YYYY"
          )} ถึง  ${moment(state.filter.date_to).format("DD/MM/YYYY")}`,
          "",
          "",
          `วันที่ดึงข้อมูล ${moment().format("DD/MM/YYYY , h:mm:ss")}`,
        ],
        [""],
        [
          // { v: "ลำดับที่", s: { alignment: { vertical: "center" } } },
          "ลำดับที่",
          "วันที่สมัคร",
          "วันหมดอายุ",
          "Team",
          "รหัสสมาชิก",
          "ข้อมูลผู้สมัครหลัก",
          "",
          "",
          "",
          "",
          "ข้อมูลผู้สมัครร่วม",
          "",
          "",
          "",
          "",
          "การดำเนินการ",
          "สถานะ",
          "วันที่ดำเนินการ",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "ชื่อ - นามสกุล",
          "เลขบัตรประชาชน",
          "วัน/เดือน/ปี เกิด",
          "อายุ",
          "สัญชาติ",
          "ชื่อ - นามสกุล",
          "เลขบัตรประชาชน",
          "วัน/เดือน/ปี เกิด",
          "อายุ",
          "สัญชาติ",
          "",
          "",
          "",
        ],
      ];

      //   exportData.data.forEach((n) => {
      //     excelData = [
      //       ...excelData,
      //       [
      //         n.row_id,
      //         n.document_no,
      //         moment(n.document_date).format("DD/MM/YYYY"),
      //         moment(n.cancel_date).format("DD/MM/YYYY"),
      //         n.partner_code,
      //         n.partner_name,
      //         n.order_type,
      //         n.grand_total_amount,
      //         n.cashier_name,
      //         n.cancel_type,
      //         n.reason,
      //         n.req_receipt_type,
      //         n.request_by_name,
      //         n.cancel_by,
      //       ],
      //     ];
      //   });

      const merge = [
        // ex. line 1 : selecet row 1 , column 0 ==> merge to ==> row 1 , column 5;
        // top 4 line : manage information sheet
        { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 4 } },
        { s: { r: 2, c: 5 }, e: { r: 2, c: 6 } },
        // manage : header table
        { s: { r: 4, c: 5 }, e: { r: 4, c: 9 } },
        { s: { r: 4, c: 10 }, e: { r: 4, c: 14 } },

        { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
        { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
        { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
        { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
        { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },

        { s: { r: 4, c: 15 }, e: { r: 5, c: 15 } },
        { s: { r: 4, c: 16 }, e: { r: 5, c: 16 } },
        { s: { r: 4, c: 17 }, e: { r: 5, c: 17 } },
      ];

      // word space in column [of index]
      var wscols = [
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
      ];

      const ws = XLSX.utils.json_to_sheet(excelData);
      ws["!cols"] = wscols;
      ws["!merges"] = merge;

      const centerStyles = {
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
      ws["F5"].s = centerStyles;
      ws["K5"].s = centerStyles;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "รายงานสลับชื่อผู้สมัครประกัน");
      XLSX.writeFile(wb, "รายงานสลับชื่อผู้สมัครประกัน.xlsx");
    } else {
      notification.warning({
        message: "ไม่มีข้อมูล",
        description: "ไม่มีข้อมูลสำหรับสร้างไฟล์ excel กรุณาตรวจสอบข้อมูล",
        top: 60,
      });
    }
  };
  const handlePreview = (id) => {
    setBillSelectedId(id);
    setPreview(true);
  };

  const memberTypeOptions = [
    { value: "Distributor", text: "นักธุรกิจ" },
    { value: "Member", text: "VIP MEMBER" },
  ];

  const partnerStatusOptions = [
    ..._.map(filterDataSource.dataPartnerStatus, (data) => {
      return { value: data.status, text: data.name };
    }),
  ];

  return (
    <SaleSummaryContext.Provider value={{ state, dispatch }}>
      <Panel>
        <PanelHeader>รายงานสลับชื่อผู้สมัครประกัน</PanelHeader>
        <PanelBody>
          <FilterList /*exportToExcel={""}*/
            withAll={user.branch_count > 1}
            allowDisableBranch
            reload={() => {
              reload();
              exportReload();
            }}
            placeholder="รหัสสมาชิก ,ชื่อสมาชิก"
            showReportSpan={10}
            inputSpan={6}
            topicSpan={2}
            Topic={"วันที่ดำเนินการ"}
            exportToExcel={exportToExcel}
          />
          <Row
            gutter={12}
            type="flex"
            align="middle"
            className="align-items-center p-b-15 align-items-sm-center pl-2"
          >
            <Col>
              <Text strong>การดำเนินการ</Text>
            </Col>
            <Col span={4}>
              <SelectOption
                className="w-100"
                placeholder="การดำเนินการ"
                // defaultValue={memberTypeFilter}
                // options={memberTypeOptions}
                onChange={(e) => {
                  //   setMemberTypeFilter(e);
                }}
              />
            </Col>

            {memberTypeFilter === "Distributor" && (
              <>
                <Col>
                  <Text strong>Team</Text>
                </Col>
                <Col span={4}>
                  <SelectTeam
                    defaultValue={teamFilter}
                    onChange={(e) => {
                      setTeamFilter(e);
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={dataSource.data}
            rowKey="row_id"
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: dataSource.data ? dataSource.data.length : 50,
              size: "default",
              onChange: (currPage) => setPage(currPage),
            }}
          >
            <Table.Column title="ลำดับ" dataIndex="row_id" align="center" />
            <Table.Column
              title="วันที่สมัคร"
              dataIndex="register_date"
              align="center"
            />
            <Table.Column
              title="วันหมดอายุ"
              dataIndex="expire_date"
              align="left"
            />
            <Table.Column title="Team" dataIndex="team" align="center" />
            <Table.Column
              title="รหัสสมาชิก"
              dataIndex="member_code"
              align="left"
            />
            <Table.ColumnGroup title="ข้อมูลผู้สมัครหลัก">
              <Table.Column
                title="ชื่อ-สกุล"
                dataIndex="full_name"
                align="left"
              />
              <Table.Column
                title="เลขบัตรประชาชน"
                dataIndex="id_card"
                align="center"
              />
              <Table.Column
                title="วัน/เดือน/ปี เกิด"
                dataIndex="birth_date"
                align="center"
                render={(text) => moment(text).format("DD/MM/YYYY")}
              />
              <Table.Column title="อายุ" dataIndex="age" align="center" />
              <Table.Column title="สัญชาติ" dataIndex="origin" align="center" />
            </Table.ColumnGroup>
            <Table.ColumnGroup title="ข้อมูลผู้สมัครร่วม">
              <Table.Column
                title="ชื่อ-สกุล"
                dataIndex="join_full_name"
                align="left"
              />
              <Table.Column
                title="เลขบัตรประชาชน"
                dataIndex="join_id_card"
                align="center"
              />
              <Table.Column
                title="วัน/เดือน/ปี เกิด"
                dataIndex="join_birth_date"
                align="center"
                render={(text) => {
                  if (text === null) {
                    return "";
                  }
                  return moment(text).format("DD/MM/YYYY");
                }}
              />
              <Table.Column title="อายุ" dataIndex="join_age" align="center" />
              <Table.Column
                title="สัญชาติ"
                dataIndex="join_origin"
                align="center"
              />
            </Table.ColumnGroup>
            <Table.Column
              title="การดำเนินการ"
              dataIndex="operations"
              align="center"
            />
            <Table.Column title="สถานะ" dataIndex="status" align="center" />
            <Table.Column
              title="วันที่ดำเนินการ"
              dataIndex="operation_date"
              align="center"
            />
          </Table>
        </PanelBody>
      </Panel>

      <BillPreview
        id={billSelectedId}
        preview={preview}
        setPreview={setPreview}
      />
    </SaleSummaryContext.Provider>
  );
};

export default Index;
