/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Spin,
  Typography
} from "antd";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useReducer, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { axios, getToken, URL_API, _ } from "../../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../../util/panel";
import UsageStatus from "../../components/UsageStatus";
import { initState, reducer, SupplierContext } from "../reducer";
import Contact from "./components/Contact";
import Supplier from "./components/Supplier";

const Index = ({
  match: { params },
  history,
  form: { getFieldDecorator, setFieldsValue, validateFields },
}) => {
  const [contact, setContact] = useState([]);
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    if (Number.isNaN(+params.id)) {
      history.push("/setting/master/voucher");
    } else if (params.id !== "0") {
      loadData();
    }
  }, []);

  const loadData = () => {
    dispatch({ type: "SET_LOADING", payload: true });
    axios({
      method: "get",
      baseURL: URL_API,
      url: `/setting/master/supplier/${params.id}`,
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        const { contact, ...data } = res.data.data;
        dispatch({ type: "SET_LOAD_SUCCESS", payload: data });
        setFieldsValue({
          ...data,
        });
        let contact_temp = [];
        _.forEach(contact, (item) => {
          contact_temp = [
            ...contact_temp,
            {
              key: _.uniqueId("contact-"),
              contact_ref: React.createRef(),
              data: item,
            },
          ];
        });
        setContact(contact_temp);
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING", payload: false });
        message.error(err.message);
      });
  };

  const handleClickSave = () => {
    let pass = true;
    let data = {};
    validateFields((err, values) => {
      if (err) {
        pass = false;
      } else {
        data = { ...values, contact: [] };
      }
    });
    _.forEach(contact, (item) => {
      item.contact_ref.current.validateFields((err, values) => {
        if (err && pass) {
          pass = false;
        } else {
          data = { ...data, contact: [...data.contact, { ...values }] };
        }
      });
    });
    if (pass) {
      onFinish(data);
    }
  };

  const onFinish = (values) => {
    let source = axios.CancelToken.source();
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกรายการหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      zIndex: 1080,
      onOk() {
        return new Promise((resolve, reject) => {
          let data = {
            ..._.omit(values, ["code"]),
            description: state.data.description
              ? draftToHtml(
                  convertToRaw(state.data.description.getCurrentContent())
                )
              : null,
            status: values.status ? "active" : "inactive",
          };
          console.log(data);
          axios({
            method: "post",
            baseURL: URL_API,
            url: `/setting/master/supplier/${
              params.id === "0" ? "" : `${params.id}`
            }`,
            headers: { Authorization: "Bearer " + getToken() },
            data,
            cancelToken: source.token,
          })
            .then(() => {
              notification.success({
                message: "Success",
                description: "บันทึกข้อมูลผู้ผลิตเรียบร้อย",
              });
              resolve();
              history.push("/setting/master/supplier");
            })
            .catch((err) => {
              reject(err);
            });
        }).catch((e) => {
          Modal.error({
            title: "ผิดพลาด",
            content: e.response?.data?.message || e.message,
            okText: "ตกลง",
            zIndex: 1080,
          });
        });
      },
      onCancel() {
        source.cancel();
      },
    });
  };

  const handleDeleteContact = (contact_key) => {
    setContact((prevState) =>
      _.filter(prevState, (n) => n.key !== contact_key)
    );
  };

  return (
    <SupplierContext.Provider value={{ state, dispatch }}>
      <Spin spinning={state.loading}>
        <Panel>
          <PanelHeader>บัตรกำนัล</PanelHeader>
          <PanelBody>
            <Row gutter={16} className="mb-3">
              <Col span={24} className="text-right">
                <Button
                  type="primary"
                  className="mr-4"
                  onClick={handleClickSave}
                >
                  บันทึก
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    history.push("/setting/master/supplier");
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col md={16}>
                <Form layout="vertical">
                  <Supplier
                    getFieldDecorator={getFieldDecorator}
                    setFieldsValue={setFieldsValue}
                  />
                </Form>
              </Col>
              <Col md={8}>
                <Divider>สถานะการใช้งาน</Divider>
                <UsageStatus getFieldDecorator={getFieldDecorator} />
                <Divider>ผู้ติดต่อ</Divider>
                {_.map(contact, (item, index) => (
                  <div
                    key={item.key}
                    className="border rounded-lg d-flex flex-column mb-4 px-3 py-3"
                  >
                    <Row className="mb-3">
                      <Col span={24} className="d-flex justify-content-between">
                        <Typography.Text>ผู้ติดต่อ{index + 1}</Typography.Text>
                        <Popconfirm
                          title="ต้องการลบรายการนี้หรือไม่"
                          placement="topRight"
                          onConfirm={() => handleDeleteContact(item.key)}
                          okText="ลบ"
                          cancelText="ไม่ลบ"
                        >
                          <Button type="danger" ghost size="small">
                            <FaTimes />
                          </Button>
                        </Popconfirm>
                      </Col>
                    </Row>
                    <Contact ref={item.contact_ref} data={item.data} />
                  </div>
                ))}
                {contact.length < 3 && (
                  <div className="w-100 d-flex justify-content-center align-items-center height-200">
                    <Button
                      type="primary"
                      ghost
                      onClick={() =>
                        setContact((prevState) => [
                          ...prevState,
                          {
                            key: _.uniqueId("contact-"),
                            data: {},
                            contact_ref: React.createRef(),
                          },
                        ])
                      }
                    >
                      เพิ่มผู้ติดต่อ
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </PanelBody>
        </Panel>
      </Spin>
    </SupplierContext.Provider>
  );
};

export default Form.create()(Index);
