import React, { forwardRef } from "react";
import SelectOption from "../../../../../../components/SelectOption";
import { useApi } from "../../../../../../hooks/http2";
import { URL_API, _ } from "../../../../../../utils";

const SelectVat = (props) => {
  // const list = [
  //   {
  //     name: "Non Output Vat",
  //     code: "SNV",
  //   },
  //   {
  //     name: "เสียภาษีมูลค่าเพิ่ม 0%",
  //     code: "S00",
  //   },
  //   {
  //     name: "เสียภาษีมูลค่าเพิ่ม 7%",
  //     code: "S07",
  //   },
  //   {
  //     name: "เสียภาษีมูลค่าเพิ่ม10%",
  //     code: "S10",
  //   },
  //   {
  //     name: "สินค้ายกเว้นภาษี",
  //     code: "EXM",
  //   },
  //   {
  //     name: "ภาษีซื้อยังไม่ถึงกำหนด",
  //     code: "SS07",
  //   },
  // ];
  const list = useApi(
    {
      url: `${URL_API}/system/master/tax_schedule`,
      token: true,
    },
    []
  );

  return (
    <SelectOption
      loading={list.loading}
      data={_.map(list.fetch?.data || [], (n) => ({
        value: n.code,
        text: n.name,
      }))}
      {...props}
    />
  );
};

export default forwardRef((props, ref) => {
  return <SelectVat forwardRef={ref} {...props} />;
});
