import moment from 'moment';
import * as yup from 'yup';
const { yupResolver } = require('@hookform/resolvers/yup');

export const rewardFormDefaultValues = {
  id: '',
  formType: 'create',
  frontImageUrl: undefined,
  backImageUrl: undefined,
  greyFrontImageUrl: undefined,
  greyBackImageUrl: undefined,
  title: '',
  description: '',
  max: 0,
  start_date: moment(new Date()),
  end_date: moment(new Date()).add('d', 3),
};

export const rewardFormSchema = () => {
  return yupResolver(
    yup.object().shape({
      id: yup.string().nullable(),
      title: yup.string().required('กรุณาใส่ชื่อหมวดหมู่'),
      frontImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      backImageUrl: yup.string().required('กรุณาอัพโหลดรูปภาพ'),
      description: yup.string().required('กรุณาใส่คำบรรยาย'),
      max: yup.number().required('กรุณาใส่จำนวนสูงสุด'),
      start_date: yup.date().required('กรุณาใส่วันเริ่มกิจกรรม'),
      end_date: yup.date().required('กรุณาใส่สิ้นสุดกิจกรรม'),
    })
  );
};
