import React from "react";
import { EventMutateProvider } from "../../../../contexts/events.context";
import EventInfo from "./components/EventInfo";

const CreateEvent = () => {
  return (
    <EventMutateProvider>
      <EventInfo />
    </EventMutateProvider>
  );
};
export default CreateEvent;
