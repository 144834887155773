import React, { useMemo,memo } from "react";
import { useSelector } from "react-redux";
import Other from "./Other";
import Primary from "./Primary";
import PrimarySet from "./PrimarySet";
import { _ } from "../../../../../utils";
import { Row } from "antd";

const Index = () => {
  const warehouse_type = useSelector(
    (state) => state.inventoryCount.warehouse.activeKey
  );
  const {
    itemKeys,
    itemByKey,
    search: { query, status, oracle_category, oracle_sub_category },
  } = useSelector((state) => state.inventoryCount[warehouse_type]);

  const dataSourceAll = useMemo(() => {
    return _.map(itemKeys, (key) => itemByKey[key]);
  }, [itemKeys, itemByKey]);

  const dataFilter = useMemo(() => {
    return _.filter(dataSourceAll, (n) =>
      n?.status
        ? (!status || status === n.status) &&
          (!oracle_category ||
            oracle_category.toString() === n.oracle_category) &&
          (!oracle_sub_category ||
            oracle_sub_category.toString() === n.oracle_sub_category) &&
          (!query ||
            (n.product_code &&
              ~n.product_code.toLowerCase().indexOf(query.toLowerCase())) ||
            (n.product_name &&
              ~n.product_name.toLowerCase().indexOf(query.toLowerCase())))
        : true
    );
  }, [dataSourceAll, status, oracle_category, query, oracle_sub_category]);

  // main product_code
  const sourceKey = useMemo(() => {
    return _.uniq(
      _.map(
        _.filter(dataFilter, (n) => n?.status),
        (x) => x.product_code
      )
    );
  }, [dataFilter]);

  // filter main_product & shelf data
  const dataSource = useMemo(() => {
    return dataFilter.filter((n) =>
      n?.status ? true : _.includes(sourceKey, n.product_code)
    );
  }, [dataFilter, sourceKey]);

  return (
    <Row>
      {warehouse_type === "primary_set" ? (
        <PrimarySet dataSource={dataSource} />
      ) : warehouse_type === "primary" ? (
        <Primary dataSource={dataSource} />
      ) : (
        <Other dataSource={dataSource} />
      )}
    </Row>
  );
};

export default memo(Index);