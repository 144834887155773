import { Col, Row, Switch } from "antd";
import React, { useContext } from "react";
import { PromotionContext } from "../reducers";

const OrderType = () => {
  const { state, dispatch } = useContext(PromotionContext);
  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col md={24} className="d-flex justify-content-between">
          <label className="mb-0">Hold</label>
          <Switch
            checked={state.data.order_type.includes("hold")}
            onClick={(checked) =>
              dispatch({
                type: "SELECT_ORDER_TYPE",
                payload: { type: "hold", checked },
              })
            }
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} className="d-flex justify-content-between">
          <label className="mb-0">Regular</label>
          <Switch
            checked={state.data.order_type.includes("regular")}
            onClick={(checked) =>
              dispatch({
                type: "SELECT_ORDER_TYPE",
                payload: { type: "regular", checked },
              })
            }
          />
        </Col>
      </Row>
    </>
  );
};
export default OrderType;
