import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Register from './register';
import Address from './address';
import Bank from './bank';
import Married from './maried';
import Error404 from '../../Error404';
import { useQuery } from '../../../../hooks/dom';

export default ({ match, history }) => {

    const query = useQuery();
    const id = query.get('id');
    useEffect(() => {
        !+id && history.push('/legal');
    }, [id, history]);
    return (
        <Switch>
            <Route exact path={`${match.path}/register`} component={Register} />
            <Route exact path={`${match.path}/address`} component={Address} />
            <Route exact path={`${match.path}/bank`} component={Bank} />
            <Route exact path={`${match.path}/married`} component={Married} />  
            <Route component={Error404} />
        </Switch>
    )
}