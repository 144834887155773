import React, { useEffect, useState } from "react";
import noImg from "../assets/img/pos/no-img.jpg";

const ImgError = ({ src, ...props }) => {
  const [imgSrc, setImgSrc] = useState(noImg);
  const onError = () => {
    setImgSrc(noImg);
  };

  useEffect(() => {
    if (src) setImgSrc(src);
    else onError();
  }, [src]);
  return <img src={imgSrc} alt="img" {...props} onError={() => onError()} />;
};

export default ImgError;
