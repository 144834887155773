/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Modal, Row } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HistoryModal from "../../../components/HistoryModal";
import TabUnderLine from "../../../components/TabUnderLine";
import { useHttp } from "../../../hooks/http";
import { actionClaim } from "../../../redux/slices/claim";
import { URL_API, _, moment } from "../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../util/panel";
import CancelModal from "./components/CancelModal";
import DocumentTable from "./components/DocumentTable";
import SearchDocument from "./components/SearchDocument";
import { getConsiderPermission } from "../../../redux/thunk/claim";
import { LoadingFullScreen } from "../../util/Loading";

const tabList = [
  { title: "รอดำเนินการ", value: "wait" },
  { title: "รอพิจารณา", value: "pending" },
  { title: "ผลพิจารณา", value: "result" },
  { title: "ประวัติการเคลม", value: "history" },
];

const { clearGlobalState, clear } = actionClaim;

const Index = ({ match, history }) => {
  const { process: process_params } = match.params;
  const dispatch = useDispatch();
  const {
    page,
    limit,
    query,
    status,
    consider_status,
    start_date,
    end_date,
    user_branch_code,
    branch_code,
    claim_type,
  } = useSelector((state) => state.claim.searchDocument);
  const loadingSubmit = useSelector((state) => state.claim.loadingSubmit);
  const historyModal = useSelector((state) => state.claim.historyModal);
  const consider_permission_loading = useSelector(
    (state) => state.claim.fetch.consider_permission.loading
  );
  const [loading, data, error, reload] = useHttp(
    {
      url: `${URL_API}/claim`,
      params: {
        query,
        limit,
        offset: (page - 1) * limit,
        start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : "",
        end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : "",
        status,
        consider_status,
        user_branch_code,
        branch_code,
        claim_type,
        process_params,
      },
      token: true,
    },
    [
      page,
      query,
      status,
      consider_status,
      start_date,
      end_date,
      user_branch_code,
      branch_code,
      claim_type,
    ]
  );

  useEffect(() => {
    dispatch(getConsiderPermission());
  }, []);

  useEffect(() => {
    return () => {
      const arr = _.split(window.location.pathname, "/");
      const path = arr[1];
      const process_type = arr[2];
      if (path !== "claim" || process_type !== process_params)
        dispatch(clear());
    };
  }, []);

  useEffect(() => {
    if (!_.includes(["wait", "pending", "result", "history"], process_params)) {
      dispatch(clear());
      history.push({
        pathname: "wait",
      });
      Modal.error({
        title: "ไม่สามารถดำเนินการได้",
        content: "URL ไม่ถูกต้อง",
        zIndex: 1080,
      });
    }
  }, [process_params]);

  const handleClickProcess = useCallback((pathname) => {
    dispatch(clearGlobalState("searchDocument"));
    history.push({ pathname });
  }, []);

  const handleCloseHistory = useCallback(
    () => dispatch(clearGlobalState("historyModal")),
    []
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Panel>
      <PanelHeader>เคลมสินค้า</PanelHeader>
      <PanelBody>
        <LoadingFullScreen
          loading={loadingSubmit}
          text={`กำลังบันทึกข้อมูล...`}
        />
        <Row className="mb-4 mt-3">
          <Col span={18} offset={3}>
            <TabUnderLine
              active={process_params}
              list={tabList}
              onClick={handleClickProcess}
            />
          </Col>
        </Row>
        <SearchDocument />
        <DocumentTable
          data={data?.data || []}
          total={data?.total || 0}
          loading={loading || consider_permission_loading}
        />
        <CancelModal reload={reload} />
        <HistoryModal
          documentType="claim"
          processType="claim_status"
          limit={historyModal.limit}
          documentNo={historyModal.document_no}
          onCancel={handleCloseHistory}
        />
      </PanelBody>
    </Panel>
  );
};

export default Index;
