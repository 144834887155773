/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button, Form, Input, Modal, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { axios, getToken, URL_API, _ } from "../../../../../utils";
import { Panel, PanelBody, PanelHeader } from "../../../../util/panel";

function BS_ButtonVip({ history, form, ...props }) {
  const [loadValue, setLoadValue] = useState("");

  const handleClickSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return notification.warning({
          message: "กรุณาตรวจสอบรายการ",
          description: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      }

      console.log({ values });

      let formData = new FormData();

      formData.append(
        "data",
        JSON.stringify({
          ..._.omit(values, ["desktop_img"]),
        })
      );

      Modal.confirm({
        title: "ยืนยัน",
        content: "ต้องการบันทึกข้อมูลหรือไม่",
        okText: "บันทึก",
        cancelText: "ปิด",

        onOk() {
          return new Promise((resolve, reject) => {
            let url_path;
            loadValue != (null || "")
              ? (url_path =
                  "/manage-website/benefits-and-subscriptions/bs-button-vip/update")
              : (url_path =
                  "/manage-website/benefits-and-subscriptions/bs-button-vip");

            axios({
              method: "post",
              baseURL: URL_API,
              url: url_path,
              headers: { Authorization: "Bearer " + getToken() },
              data: formData,
            })
              .then((res) => {
                console.log(res);
                resolve("success");
              })
              .catch((err) => {
                console.log(err);
                reject(
                  err && err.response
                    ? err.response.data.message
                      ? err.response.data.message
                      : err.response.data
                    : err.message
                );
              });
          })
            .then(() => {
              Modal.success({
                title: "สำเร็จ",
                content: "บันทึกเรียบร้อย",
                okText: "ปิด",
                onOk() {
                  history.go(0);
                },
              });
            })
            .catch((reason) => {
              Modal.error({
                title: "ผิดพลาด",
                content: reason,
                okText: "ปิด",
              });
            });
        },
      });
    });
  };

  const loadContent = () => {
    axios({
      method: "get",
      baseURL: URL_API,
      url: "/manage-website/benefits-and-subscriptions/bs-button-vip",
      headers: { Authorization: "Bearer " + getToken() },
    })
      .then((res) => {
        console.log(res);
        let data = res.data?.data;
        let cta = res.data?.data.cta;
        setLoadValue(data);
        console.log(data);
        console.log(cta);

        form.setFieldsValue({
          ...data,
          cta_name: cta.name,
          cta_link: cta.link,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  const { getFieldDecorator } = form;

  return (
    <>
      <Panel>
        <PanelHeader>Button VIP</PanelHeader>
        <PanelBody>
          <Form.Item layout="vertical">
            <Row className="mb-3">
              <Form.Item label="Button Name ( CTA ) :">
                {getFieldDecorator("cta_name", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุ Button Name ( CTA )",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="CTA Link :">
                {getFieldDecorator("cta_link", {
                  initialValue: "",
                  rules: [{ required: true, message: "กรุณาระบุ CTA Link" }],
                })(<Input />)}
              </Form.Item>
            </Row>

            <Row className="mt-3 mb-3">
              <Button
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
              >
                {loadValue != (null || "") ? "Update" : "Save"}
              </Button>
            </Row>
          </Form.Item>
        </PanelBody>
      </Panel>
    </>
  );
}
export default Form.create("bs-button-vip")(BS_ButtonVip);
