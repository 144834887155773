import { Alert, Button, Col, Input, Modal, Row, Table } from "antd";
import React from "react";
import { FaCogs } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHttp } from "../../../../hooks/http";
import { getProductProperty } from "../../../../redux/logic/transfer";
import { actionTransfer } from "../../../../redux/slices/transfer";
import { URL_API, _ } from "../../../../utils";

const { Column } = Table;

const { addProduct, clearGlobalState, setGlobalState } = actionTransfer;

const ProductModal = ({ ...props }) => {
  const dispatch = useDispatch();
  const { type: type_params } = useParams();
  const { visible, limit, page, query } = useSelector(
    (state) => state.transfer.productModal
  );
  const { from_branch_code, from_wh_code } = useSelector(
    (state) => state.transfer.document
  );
  const property = getProductProperty(type_params);
  const disabledProductCode = useSelector((state) => {
    const { itemKeys, itemByKey } = state.transfer[property];
    return _.join(
      _.reduce(
        itemKeys,
        (res, key) =>
          itemByKey[key].line_number === 1
            ? [...res, itemByKey[key].product_code]
            : res,
        []
      ),
      ","
    );
  });

  const [loading, data, error] = useHttp(
    {
      url: `${URL_API}/transfer-receive/master/product`,
      params: {
        limit,
        offset: (page - 1) * limit,
        query,
        branch_code: from_branch_code,
        warehouse_code: from_wh_code,
        disabledProductCode,
      },
      token: true,
    },
    [query, page, from_branch_code, from_wh_code, disabledProductCode]
  );

  if (error) return <Alert type="error" showIcon message={error} />;

  return (
    <Modal
      visible={visible}
      footer={false}
      zIndex={1080}
      width={1000}
      destroyOnClose={true}
      onCancel={() => dispatch(clearGlobalState("productModal"))}
      {...props}
    >
      <Row className="mb-3" type="flex">
        <Col md={10}>
          <Input.Search
            placeholder="รหัสสินค้า, ชื่อสินค้า, SKU"
            value={query}
            onChange={(e) =>
              dispatch(
                setGlobalState({
                  name: "productModal",
                  value: { query: e.target.value, page: 1 },
                })
              )
            }
          />
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={data?.data || []}
        rowKey="row_id"
        pagination={{
          current: page,
          pageSize: limit,
          total: data?.total || 0,
          showLessItems: true,
          size: "small",
          onChange: (page) =>
            dispatch(
              setGlobalState({
                name: "productModal",
                value: { page },
              })
            ),
        }}
      >
        <Column
          width={60}
          title="ลำดับ"
          key="row_id"
          dataIndex="row_id"
          align="center"
        />
        <Column
          width={100}
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">รหัสสินค้า</div>
          }
          key="product_code"
          dataIndex="product_code"
        />
        <Column
          className="position-relative"
          title={
            <div className="ant-table-absolute-title-center">ชื่อสินค้า</div>
          }
          key="product_name"
          dataIndex="product_name"
        />
        <Column
          width={100}
          className="position-relative"
          title={<div className="ant-table-absolute-title-center">จำนวน</div>}
          key="balance"
          dataIndex="balance"
          align="right"
        />
        <Column
          width={100}
          title={<FaCogs />}
          align="center"
          key="action"
          render={({
            product_id,
            product_code,
            sku,
            product_name,
            product_name_en,
            balance,
          }) => (
            <Button
              type="primary"
              size="small"
              onClick={() =>
                dispatch(
                  addProduct({
                    property,
                    product_id,
                    product_code,
                    sku,
                    product_name,
                    product_name_en,
                    balance,
                  })
                )
              }
            >
              เพิ่ม
            </Button>
          )}
        />
      </Table>
    </Modal>
  );
};

export default ProductModal;
