import { Col, Input, Radio, Row } from "antd";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectAllType from "../../../../../components/SelectAllType";
import SelectOption from "../../../../../components/SelectOption";
import { actionEthics } from "../../../../../redux/slices/ethics";

const display_type = {
  distributor: [
    {
      value: "",
      text: "ทั้งหมด",
    },
    {
      value: "waiting",
      text: "รอตรวจสอบ",
    },
  ],
  member: [
    {
      value: "",
      text: "ทั้งหมด",
    },
    {
      value: "waiting",
      text: "รอตรวจสอบ",
    },
    {
      value: "sponsor",
      text: "ไม่มีผู้แนะนำ",
    },
  ],
};

export default function SearchPanel() {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.ethics.search, shallowEqual);
  const { CLEAR_LIST, SET_MEMBER_TYPE, SET_SEARCH } = actionEthics;

  const [query, setQuery] = useState("");

  return (
    <>
      <Row className="mb-2" gutter={16}>
        <Col>
          <Radio.Group
            value={search.member_type}
            onChange={(e) => {
              dispatch(CLEAR_LIST());
              dispatch(SET_MEMBER_TYPE(e.target.value));

              setQuery("");
            }}
          >
            <Radio value="distributor">นักธุรกิจ</Radio>
            <Radio value="member">VIP Member</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row className="mb-3" gutter={16}>
        <Col md={6}>
          <SelectOption
            className="w-100"
            data={display_type[search.member_type]}
            value={search.display_type}
            onChange={(e) =>
              dispatch(
                SET_SEARCH({
                  display_type: e,
                  page: 1,
                })
              )
            }
          />
        </Col>
        <Col md={6}>
          <SelectAllType
            className="w-100"
            process_type={
              search.member_type === "distributor"
                ? "partner_status_local"
                : "member_status_local"
            }
            value={search.status}
            withAll
            allText="ทั้งหมด"
            onChange={(e) =>
              dispatch(
                SET_SEARCH({
                  status: e,
                  page: 1,
                })
              )
            }
          />
        </Col>
        <Col md={12}>
          <Input.Search
            placeholder="รหัสสมาชิก, เลขบัตรประชาชน, ชื่อสมาชิก, เบอร์โทร, E-mail"
            value={query}
            onChange={(e) => setQuery(e.target.value || "")}
            onSearch={(e) =>
              dispatch(
                SET_SEARCH({
                  query: e || "",
                  page: 1,
                })
              )
            }
            allowClear
          />
        </Col>
      </Row>
    </>
  );
}
