import React from "react";
import SelectOption from "../../../../../components/SelectOption";
import { useApi } from "../../../../../hooks/http2";
import _ from "lodash";

export default function SelectTeam({ ...porps }) {
  const teams = useApi(
    {
      baseURL: process.env.REACT_APP_EVENT_URL_API,
      url: `/members-master/master/team`,
    },
    []
  );

  return (
    <SelectOption
      loading={teams.loading}
      {...porps}
      data={_.map(teams.fetch?.items || [], (n) => ({
        ...n,
        value: n.TeamName,
        text: n.TeamName,
      }))}
    />
  );
}
