import React, { useState } from 'react';
import { Modal } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AlertConfirm, AlertError, AlertSuccess } from '../../../../util/Alert';
import { useQueryClient } from '@tanstack/react-query';
import lobServices from '../../../../../services/lob.services';
import LodForm from '../form/LodForm';
import moment from 'moment';

const LodModal = ({ open, handleClose }) => {
  const { watch, handleSubmit } = useFormContext();
  const queryClient = useQueryClient();
  const [lobData, setLobData] = useState();
  const [id, setId] = useState();

  const { mutate: getLobByLobNo } = lobServices.useMutationGetLobByLobNo(
    async (data) => {
      if (!!data) {
        if (watch('formType') === 'create') {
          const confirm = await AlertConfirm(
            'มีรหัส LOD นี้อื่นอยู่ในระบบแล้ว'
          );
          if (confirm) {
            createLob(lobData);
          }
        } else if (watch('formType') === 'edit') {
          if (data.id !== id) {
            const confirm = await AlertConfirm(
              'มีรหัส LOD นี้อื่นอยู่ในระบบแล้ว'
            );
            if (confirm) {
              editLob({ id: id, data: lobData });
            }
          } else {
            editLob({ id: id, data: lobData });
          }
        }
      } else {
        if (watch('formType') === 'create') {
          createLob(lobData);
        } else if (watch('formType') === 'edit') {
          editLob({ id: id, data: lobData });
        }
      }
    }
  );

  const { mutate: createLob } = lobServices.useMutationCreateLob(
    () => {
      queryClient.invalidateQueries({
        queryKey: ['get-lobs'],
        refetchType: 'all',
      });
      AlertSuccess('เพิ่มข้อมูลสำเร็จ');
      handleClose();
    },
    (err) => {
      AlertError({ text: err });
    }
  );

  const { mutate: editLob } = lobServices.useMutationUpdateLob(
    () => {
      queryClient.invalidateQueries({
        queryKey: ['get-lobs'],
        refetchType: 'all',
      });
      AlertSuccess('แก้ไขข้อมูลสำเร็จ');
      handleClose();
    },
    (err) => {
      AlertError({ text: err });
    }
  );

  const _HandleSubmit = handleSubmit(async (data) => {
    const lobData = {
      memberCode: data.memberCode,
      firstName: data.firstName,
      lastName: data.lastName,
      lobNo: data.lobNo,
      storeName: data.storeName,
      expiredDate: moment(data.expiredDate).toDate(),
      links: data.links,
      isPartner: data.isPartner,
    };
    if (watch('formType') === 'edit') {
      setId(data.id);
    }
    setLobData(lobData);
    getLobByLobNo({ lobNo: data.lobNo });
  });

  return (
    <Modal
      title={watch('formType') === 'create' ? 'เพิ่ม LOD' : 'แก้ LOD'}
      visible={open}
      onCancel={handleClose}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={_HandleSubmit}
      width={'50vw'}
    >
      <LodForm />
    </Modal>
  );
};

export default LodModal;
