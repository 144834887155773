/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash, FaPen, FaPlus } from 'react-icons/fa';
import fitStudioService from '../../../../services/fit-studio.service';
import useAsync from '../../../../utils/useAsync';
import { Panel, PanelBody, PanelHeader } from '../../../util/panel';
import NewsCategoryModal from '../components/modal/NewsCategoryModal';
import { AlertConfirm } from '../../../util/Alert';
import { Table } from 'antdV4';
import { MdDelete } from 'react-icons/md';
import NewsModal from '../components/modal/NewsModal';
import {
  newsCategoryFormDefaultValues,
  newsCategoryFormSchema,
  newsFormDefaultValues,
  newsFormSchema,
} from '../components/dto/news';
import newsConstants from '../constants/newsConstants';
import moment from 'moment';

const NewsPage = () => {
  const [newsCategoryFormOpen, setNewsCategoryFormOpen] = useState(false);
  const [newsFormOpen, setNewsFormOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [categoryId, setCategoryId] = useState('');

  const newsCategoryForm = useForm({
    defaultValues: newsCategoryFormDefaultValues,
    resolver: newsCategoryFormSchema(),
  });

  const _HandleNewsCategoryFormOpen = (formType) => {
    newsCategoryForm.setValue('formType', formType);
    setNewsCategoryFormOpen(true);
  };

  const _HandleNewsCategoryFormClose = () => {
    newsCategoryForm.reset(newsCategoryFormDefaultValues);
    setNewsCategoryFormOpen(false);
  };

  const newsForm = useForm({
    defaultValues: newsFormDefaultValues,
    resolver: newsFormSchema(),
  });

  const _HandleNewsFormOpen = (formType) => {
    newsForm.setValue('formType', formType);
    setNewsFormOpen(true);
  };

  const _HandleNewsFormClose = () => {
    newsForm.reset(newsFormDefaultValues);
    setNewsFormOpen(false);
  };

  const { execute: getAllNewsCategory, data: newsCategoryLists } = useAsync(
    fitStudioService.getAllNewsCategory,
    {
      immediate: true,
      onSuccess: (data) => {
        setCategoryId(data[0].id);
      },
    }
  );

  const { execute: setNewsCategoryEnable } = useAsync(
    fitStudioService.setNewsCategoryEnable,
    {
      onSuccess: (data) => {
        getAllNewsCategory();
      },
    }
  );

  const _OnTabChange = (id) => {
    setCategoryId(id);
  };

  const columns = [
    {
      title: 'รูปภาพ',
      dataIndex: 'thumbnailImageUrl',
      key: 'thumbnailImageUrl',
      render: (_, record) => (
        <img
          key={record.thumbnailImageUrl}
          src={record.thumbnailImageUrl}
          alt={record.thumbnailImageUrl}
          style={{
            width: 50,
            height: 50,
            objectFit: 'cover',
            borderRadius: 10,
          }}
        />
      ),
      width: 50,
    },
    {
      title: 'หัวข้อ',
      dataIndex: 'title',
      key: 'title',
      width: 200,
    },
    {
      title: 'หัวข้อรอง',
      dataIndex: 'subTitle',
      key: 'subTitle',
      width: 200,
    },
    {
      title: 'สร้างเมื่อ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MMM/YYYY')}</span>
      ),
      width: 200,
    },
    {
      title: 'อัปเดทเมื่อ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => (
        <span>{moment(record.updatedAt).format('DD/MMM/YYYY')}</span>
      ),
      width: 200,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {record.enable ? (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('ปิดการมองเห็นข่าวสาร');
                if (confirm) setNewsEnable({ id: record.id, isEnable: false });
              }}
            >
              <FaEye />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={async () => {
                const confirm = await AlertConfirm('เปิดการมองเห็นข่าวสาร');
                if (confirm) setNewsEnable({ id: record.id, isEnable: true });
              }}
            >
              <FaEyeSlash />
            </div>
          )}
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => {
              newsForm.setValue('id', record.id);
              _HandleNewsFormOpen('edit');
            }}
          >
            <FaPen />
          </div>
          <div
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={async () => {
              const confirm = await AlertConfirm('ลบข่าวสาร');
              if (confirm) deleteNews(record.id);
            }}
          >
            <MdDelete />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  useEffect(() => {
    if (!newsCategoryFormOpen) {
      getAllNewsCategory();
    }
  }, [newsCategoryFormOpen]);

  const { execute: getNewsByNewsCategoryId, data: newsLists } = useAsync(
    fitStudioService.getNewsByNewsCategoryId,
    {}
  );

  const { data: test, execute: setNewsEnable } = useAsync(
    fitStudioService.setNewsEnable,
    {}
  );

  const { data: test2, execute: deleteNews } = useAsync(
    fitStudioService.deleteNews,
    {}
  );

  useEffect(() => {
    if (!newsFormOpen && categoryId) {
      getNewsByNewsCategoryId({
        newsCategoryId: categoryId,
        page: page + 1,
        take: newsConstants.tableSize,
      });
    }
  }, [categoryId, page, newsFormOpen, test, test2]);

  return (
    <Panel>
      <PanelHeader>ข่าวสาร</PanelHeader>
      <PanelBody>
        {newsCategoryLists && newsCategoryLists.length > 0 && (
          <Tabs
            defaultActiveKey="home"
            onChange={_OnTabChange}
            style={{ backgroundColor: '#fff' }}
            tabBarExtraContent={
              <Button
                className="bg-pv border-pv text-white"
                onClick={() => {
                  _HandleNewsCategoryFormOpen('create');
                }}
              >
                <FaPlus className="mr-2" />
                เพิ่มหมวดหมู่
              </Button>
            }
          >
            {newsCategoryLists.map((item) => {
              return (
                <Tabs.TabPane
                  key={item.id}
                  tab={
                    <div style={{ display: 'flex' }}>
                      {item.title}
                      <div
                        style={{
                          cursor: 'pointer',
                          marginLeft: 20,
                          marginRight: 10,
                        }}
                        onClick={() => {
                          newsCategoryForm.setValue('id', item.id);
                          _HandleNewsCategoryFormOpen('edit');
                        }}
                      >
                        <FaPen />
                      </div>
                      {item.enable ? (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={async () => {
                            const confirm = await AlertConfirm(
                              'ปิดการมองเห็นหมวดหมู่'
                            );
                            if (confirm)
                              setNewsCategoryEnable({
                                id: item.id,
                                isEnable: false,
                              });
                          }}
                        >
                          <FaEye />
                        </div>
                      ) : (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={async () => {
                            const confirm = await AlertConfirm(
                              'เปิดการมองเห็นหมวดหมู่'
                            );
                            if (confirm)
                              setNewsCategoryEnable({
                                id: item.id,
                                isEnable: true,
                              });
                          }}
                        >
                          <FaEyeSlash />
                        </div>
                      )}
                    </div>
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingBottom: 10,
                    }}
                  >
                    <Button
                      className="bg-pv border-pv text-white"
                      onClick={() => {
                        _HandleNewsFormOpen('create');
                      }}
                    >
                      <FaPlus className="mr-2" />
                      เพิ่มข่าวสาร
                    </Button>
                  </div>
                  <Table
                    scroll={{ x: true }}
                    columns={columns}
                    dataSource={
                      newsLists?.data && newsLists?.data.length > 0
                        ? newsLists.data
                        : []
                    }
                    onChange={({ current }) => {
                      setPage(current - 1);
                    }}
                    style={{ backgroundColor: '#fff' }}
                    rowKey="id"
                    pagination={{
                      pageSize: newsConstants.tableSize,
                      total: newsLists?.meta?.total || 0,
                    }}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        )}
      </PanelBody>
      <FormProvider {...newsCategoryForm}>
        <NewsCategoryModal
          open={newsCategoryFormOpen}
          handleClose={_HandleNewsCategoryFormClose}
        />
      </FormProvider>
      <FormProvider {...newsForm}>
        <NewsModal
          open={newsFormOpen}
          handleClose={_HandleNewsFormClose}
          categoryId={categoryId}
        />
      </FormProvider>
    </Panel>
  );
};

export default NewsPage;
