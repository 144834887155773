/* eslint-disable no-unused-vars */
import { Button, Col, Empty, Input, Modal, Pagination, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHttp } from '../../../../../../hooks/http';
import { URL_API } from '../../../../../../utils';

export default function MemberList({ ...props }) {
    const [page, setPage] = useState(1);
    const limit = 10;
    const [query, setQuery] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [loading, data, error] = useHttp({
        url: `${URL_API}/pos/claim/member`,
        params: {
            limit,
            offset: (page - 1) * limit,
            query
        }
    }, [page, query]);

    useEffect(() => {
        let newData = [];
        let total = 0;
        if (data && data.data) {
            newData = data.data.map(n => ({ ...n, key: n.id }))
            total = data.total
        }
        setDataSource(newData)
        setTotalPage(total)
    }, [data])
    return (
        <>
            <Modal
                {...props}
            >
                <Row gutter={16} className="mb-4">
                    <Col md={10}>
                        <Input.Search value={query} onChange={e => setQuery(e.target.value)} placeholder="ชื่อ-นามสกุล/รหัสสมาชิก/อีเมล/เลขบัตรประชาชน/เบอร์โทร/อื่นๆ" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table className="w-100">
                            <thead>
                                <tr style={{ backgroundColor: '#bcbcbc' }}>
                                    <th colSpan={11} className="text-center">รายชื่อสมาชิก</th>
                                </tr>
                                <tr className="text-center">
                                    <th>รหัสสมาชิก</th>
                                    <th>ชื่อสมาชิก</th>
                                    <th>อีเมล</th>
                                    <th>รหัสบัตรประชาชน</th>
                                    <th>เบอร์โทร</th>
                                    <th width="5%"><i className="fa fa-cogs" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading === true ?
                                        <tr className="text-center">
                                            <td colSpan={9}><Skeleton active/></td>
                                        </tr>
                                        :
                                        dataSource.length === 0 ?
                                            <tr className="text-center">
                                                <td colSpan={9}><Empty /></td>
                                            </tr>
                                            :
                                            dataSource.map((n, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{n.code}</td>
                                                        <td>{`${n.first_name_th} ${n.sur_name_th}`}</td>
                                                        <td>{n.email}</td>
                                                        <td>{n.id_card}</td>
                                                        <td>{n.mobile}</td>
                                                        <td>
                                                            <Button type="primary">เลือก</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                }
                            </tbody>
                        </table>
                        {
                            totalPage === 0 ?
                                null
                                :
                                <Pagination current={page} total={totalPage} className="text-center" onChange={e => setPage(e)} />
                        }
                    </Col>
                </Row>
            </Modal>

        </>
    )
}
