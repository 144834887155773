import React, { useState } from "react";
import { Table, Button, Upload, message, Modal } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { axios } from "../../../../../utils/useAxios";
import { useStateValue } from "../StateContext";
import dayjs from "dayjs";
import ModalEvent from "./ModalEvent"; 
import ModalImport from "./ModalImport";

const HandelFile = () => {
  const { state, dispatch } = useStateValue();
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  const handleOpenImportModal = () => {
    setIsImportModalVisible(true);
  };

  const handleCloseImportModal = () => {
    setIsImportModalVisible(false);
  };


  const handleConfirm = async (data) => {
    try {
      const response = await axios.post("/legacy-verse/import/import-coin", { data: data });
      if (response.status === 200) {
        message.success("ยืนยันการอัปโหลดข้อมูลเรียบร้อย!");

        const res = await axios.get("/legacy-verse/import/import-coin", {
          params: {
            search: '',
          },
        });

        dispatch({ type: "SET_LOADING", payload: true });
        dispatch({ type: "SET_COIN_LIST", payload: res?.data?.coin_list || [] });

        setIsImportModalVisible(false);
        dispatch({ type: "SET_LOADING", payload: false });

      } else {
        message.error("การส่งข้อมูลล้มเหลว");
      }
    } catch (error) {
      message.error("เกิดข้อผิดพลาดในการส่งข้อมูล");
    }
  };

  const exportTemplateToExcel = () => {
    const templateData = [
      {
        "partner_code": "",
        "title": "",
        "description": "",
        "coin": "",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(templateData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    XLSX.writeFile(workbook, `coin_template_${dayjs().format("YYYYMMDD")}.xlsx`);
    message.success("ส่งออก Template สำเร็จ!");
  };


  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleSaveEvent = async ({ name, description }) => {
    if (!name.trim()) {
      message.error("กรุณากรอกชื่อ Event");
      return;
    }

    try {
      const response = await axios.post("/legacy-verse/import/event", { name, description });

      if (response.status === 200) {
        message.success("บันทึก Event สำเร็จ!");

        dispatch({ type: "SET_LOADING", payload: true });
        try {
          const res = await axios.get("/legacy-verse/import/coin",
            {
              params: {
                search: '',
              },
            });

          dispatch({ type: "SET_EVENT_NAME", payload: res?.data?.event_name_list || [] });
          dispatch({ type: "SET_LOADING", payload: false });
        } catch (error) {
          dispatch({ type: "SET_LOADING", payload: false });
          message.error("โหลดข้อมูล Event ไม่สำเร็จ");
        }
      }
    } catch (error) {
      console.error("บันทึกข้อมูลล้มเหลว:", error);
      Modal.error({
        title: "บันทึกไม่สำเร็จ",
        content: error.response?.data?.error || "เกิดข้อผิดพลาด",
      });
    } finally {
      setIsModalVisible(false);
    }
  };



  return (
    <div>
      <Button
        type="dashed"
        icon={<UploadOutlined />}
        onClick={handleOpenImportModal}
        className="hover:bg-blue-500 hover:text-white text-blue-500"
      >
        อัปโหลดไฟล์ Excel
      </Button>

      {data.length > 0 && (
        <Table
          dataSource={data}
          // columns={columns}
          bordered
          pagination={{ pageSize: 5 }}
          className="shadow-md border rounded-md mt-2"
          rowClassName="hover:bg-gray-100"
        />
      )}

      {/* {uploadSuccess && (
        <div className="flex justify-center mt-2">
          <Button
            type="primary"
            onClick={handleConfirm}
            className="bg-green-500 hover:bg-green-600 text-white"
          >
            Confirm
          </Button>
        </div>
      )} */}

      <Button
        type="primary"
        className="ml-2 mt-4"
        onClick={handleOpenModal}
      >
        เพิ่ม Event
      </Button>

      <Button
        type="primary"
        className="ml-2 mt-4"
        onClick={exportTemplateToExcel}
      >
        Export Template
      </Button>

      <ModalEvent
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSaveEvent}
      />

      <ModalImport
        visible={isImportModalVisible}
        onCancel={handleCloseImportModal}
        onSave={handleConfirm}
      />
    </div>
  );
};

export default HandelFile;
